// eslint-disable-next-line
 /* eslint-disable */
 
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { createApp, eventsHub } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/_index.scss'
import halleyxComponents from 'halleyx-ui-framework'
import 'halleyx-ui-framework/dist/es/index.css'
import VNetworkGraph from 'v-network-graph'
import 'v-network-graph/lib/style.css'
import VueCookies from 'vue-cookies'
import IdleVue from 'idle-vue'
import customPlugin from './plugin/customPlugin'
import ElementPlus from 'element-plus'
// import VueGoogleMaps from '@fawmi/vue-google-maps'
  
const app = createApp(App)
app
  .use(ElementPlus)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAP_KEY, // Replace with your actual API key
      libraries: 'places', // Load the places library
    },
  })
  .use(customPlugin)
  .use(VueCookies)
  .use(store)
  .use(VNetworkGraph)
  .use(halleyxComponents)
  .use(router)
  .use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    // idleTime: 3000,
    idleTime: 1800000,
    startAtIdle: false
  })
  .mount('#app')
