<template>
  <!-- //alert -->
  <hlx-alert-notification :notify="notification" :auto-close="true" />
  
  <!-- main head-->
  <section style="display: flex; flex-direction: row">
    <!-- left -->
    <section style="width: 89px">
      <div
        style="margin: 16px 1px 1px 19px; display: flex; cursor: pointer"
        @click="backToQuoteAutomationTable"
      >
        <span style="font-size: 20px"
          ><i class="icon-arrow-left-regular"></i
        ></span>
        <span style="padding: 2px 0px 0px 10px; font-size: 15px">Back</span>
      </div>
    </section>

    <!-- right -->
    <section style="width: 100%">
      <div
        class="util-bar"
        style="
          display: flex;
          justify-content: space-between;
          margin: 3px 15px 15px 15px;
          height: 70px;
        "
      >
        <span class="switch-container">
          <div class="common_single_header_selfservice" style="height: 46px">
            <div class="head_selfservice" @click="showContiditionfunction">
              Approval
            </div>
            <div class="body_selfservice">Configure your quote workflow</div>
          </div>
        </span>

        <span
          style="
            display: flex;
            gap: 16px;
            justify-content: space-between;
            align-items: center;
          "
        >
          <span>
            <hlx-button class="secondary sm" @click="backToQuoteAutomationTable">
              Cancel</hlx-button
            >
          </span>
          <span>
            <hlx-button class="primary sm" @click="createNewRule">
              Save</hlx-button
            >
          </span>
        </span>
      </div>

      <!-- section2 -->
      <section class="defaultApprovalS2">
        <div class="defaultApprovalD1">
          <span class="defaultApprovalSp1">
            <hlx-input
              v-model:value="quoteWorkFlow.ruleName"
              :required="false"
              :pre-val="quoteWorkFlow.ruleName"
              label-value="Rule name"
              :label-animation="true"
              type="text"
              :clearable="false"
            />
          </span>
          <span class="defaultApprovalSp2"
            ><hlx-multi-select
              :key="componentKey"
              :pre-value="quoteWorkFlow.tagPreValue"
              :options="tagOptions"
              :placeholder="'Tag'"
              :label-animation="true"
              :prop-value="'name'"
              :label="'name'"
              :disabled="false"
              :filter-select="false"
              :tooltipposition="'left'"
              @add-data="addTagList"
              @selected-array="selectedArray"
            ></hlx-multi-select
          ></span>
          <span class="defaultApprovalSp3">
            <span
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
              >Enable rule</span
            ><span>
              <hlx-input
                v-model:value="quoteWorkFlow.enableRule"
                :checked="quoteWorkFlow?.enableRule"
                :type="'switch'"
              />
            </span>
          </span>
        </div>
        <div class="defaultApprovalD2">
          <hlx-input
            v-model:value="quoteWorkFlow.description"
            :pre-val="quoteWorkFlow?.description"
            type="textarea"
            :label-value="'Description'"
            :required="false"
            :label-animation="true"
            :display-error="true"
            :custom-error="customnameerror"
            :rows="7"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
        </div>
      </section>

      <!-- single line -->
      <div class="defaultApprovalS3"></div>

      <!-- rule loop start -->
      <div
        v-for="(item, index) in quoteWorkFlow?.ruleSection"
        :key="index"
        class="seperate_container_of_select_approval"
      >
        <div class="condition_remove_div">
          <!-- rule head -->
          <div v-if="index === 0" class="defaultApprovalS5">If</div>
          <div v-else class="defaultApprovalS5">Else if</div>
        </div>

        <!-- rule body -->
        <!-- grey layout start -->
        <div style="border: 1px solid #d8d8d8; border-radius: 11px">
          <section class="defaultApprovalS6">
            <div class="div1">
              <!-- condition -->
              <div
                v-for="(singleCondition, conditionIndex) in item?.condition"
                :key="conditionIndex"
                class="subDiv"
              >
                <div class="subSubDiv-1">
                  <div
                    v-if="conditionIndex == 0"
                    style="
                      font-size: 14px;
                      font-weight: 400;
                      margin: 0px 0px;
                      width: 122px;
                      padding: 0px 16px 0px 20px;
                      display: flex;
                      justify-content: center;
                    "
                  >
                    When
                  </div>
                  <span v-else style="width: 122px; padding: 0px 16px 0px 20px">
                    <hlx-select
                      v-model:value="
                        quoteWorkFlow.ruleSection[index].condition[
                          conditionIndex
                        ].operationPreValue
                      "
                      :required="false"
                      :options="operation"
                      :pre-value="
                        quoteWorkFlow.ruleSection[index].condition[
                          conditionIndex
                        ].operationPreValue
                      "
                      :placeholder-value="''"
                      :inline-search="true"
                      :label-animation="true"
                      :label="'value'"
                      :prop-value="'value'"
                      :clearable="false"
                      @selected-value="
                        emitedRuleOperation($event, conditionIndex, index)
                      "
                    >
                    </hlx-select>
                  </span>
                </div>
                <div class="subSubDiv1">
                  <span style="width: 100%">
                    <!-- one -->
                    <hlx-select
                      v-model:value="
                        quoteWorkFlow.ruleSection[index].condition[
                          conditionIndex
                        ].categoryPreValue
                      "
                      :required="false"
                      :options="CategoryList"
                      :pre-value="
                        quoteWorkFlow.ruleSection[index].condition[
                          conditionIndex
                        ].categoryPreValue
                      "
                      :placeholder-value="''"
                      :label-animation="true"
                      :label="'mainData'"
                      :prop-value="'mainData'"
                      :clearable="false"
                      @selected-value="
                        emitedRuleValue($event, conditionIndex, index)
                      "
                    ></hlx-select>
                  </span>
                </div>
                <div class="subSubDiv2">
                  <span
                    style="font-size: 14px; font-weight: 400; margin: 0px 15px"
                    >is</span
                  >

                  <span style="width: 100%">
                    <!-- two -->
                    <hlx-select
                      v-model:value="
                        quoteWorkFlow.ruleSection[index].condition[
                          conditionIndex
                        ].conditionPreValue
                      "
                      :required="false"
                      :options="
                        getConditionsByType(
                          quoteWorkFlow.ruleSection[index].condition[
                            conditionIndex
                          ].categoryType
                        )
                      "
                      :pre-value="
                        quoteWorkFlow.ruleSection[index].condition[
                          conditionIndex
                        ].conditionPreValue
                      "
                      :placeholder-value="''"
                      :label-animation="true"
                      :label="'value'"
                      :prop-value="'value'"
                      :clearable="false"
                      @selected-value="
                        emitedconditionPreValue($event, conditionIndex, index)
                      "
                    ></hlx-select>
                  </span>
                </div>
                <div class="subSubDiv3">

                  <!-- three -->                 
                  <span
                    v-if="
                      quoteWorkFlow.ruleSection[index].condition[conditionIndex]
                        .categoryType === 'Number'
                    "
                    style="width: 100%"
                  >
                    <hlx-input
                      v-model:value="
                        quoteWorkFlow.ruleSection[index].condition[
                          conditionIndex
                        ].inputPreValue
                      "
                      :pre-val="
                        quoteWorkFlow.ruleSection[index].condition[
                          conditionIndex
                        ].inputPreValue || ''
                      "
                      :type="'number'"
                      :label-animation="true"
                    />
                  </span>

                  <!-- string -->
                  <span
                    v-if="
                      quoteWorkFlow.ruleSection[index].condition[conditionIndex]
                        .categoryType === 'String'
                    "
                    style="width: 100%"
                  >
                    <hlx-input
                      v-model:value="
                        quoteWorkFlow.ruleSection[index].condition[
                          conditionIndex
                        ].inputPreValue
                      "
                      :pre-val="
                        quoteWorkFlow.ruleSection[index].condition[
                          conditionIndex
                        ].inputPreValue
                      "
                      :type="'text'"
                      :label-animation="true"
                    />
                  </span>

                  <!-- array -->
                  <span
                    v-if="
                      quoteWorkFlow.ruleSection[index].condition[conditionIndex]
                        .categoryType === 'Array1'
                    "
                    style="width: 100%"
                  >
                    <hlx-multi-select
                      :key="componentKeyMulti"
                      :pre-value="
                        quoteWorkFlow.ruleSection[index].condition[
                          conditionIndex
                        ].marketPreValue
                      "
                      :options="quoteWorkFlow.ruleSection[index].condition[
                          conditionIndex
                        ].optionRef"
                      :placeholder="''"
                      :label-animation="true"
                      :prop-value="'name'"
                      :label="'name'"
                      :disabled="false"
                      :filter-select="false"
                      :tooltipposition="'left'"
                      @selected-array="
                        marketValues($event, conditionIndex, index)
                      "
                    ></hlx-multi-select>
                  </span>
                  
                  <!-- date -->
                  <span
                    v-if="
                      quoteWorkFlow.ruleSection[index].condition[conditionIndex]
                        .categoryType === 'Date'
                    "
                    style="width: 100%"
                  >
                    <div>
                    <hlx-input
          :type="'date'"
          :label-value="''"
          :label-animation="true"
          :single-border="false"
          :date-format="'dd (short month), yyyy'"
          :clearable="false"
          icon="calendar-regular"
          icon-position="right"
          :required="false"
          :disable-weekend="false"
          :start-year="2000"
          :display-error="false"
          :end-year="9999"
          @focus-out="marketValues($event, conditionIndex, index)"
        />
      </div>
                  </span>

                  <span
                    v-if="quoteWorkFlow.ruleSection[index]?.condition?.length > 1"
                    class="defaultApprovalRuleTrashButton"
                    @click="
                      closeRuleBox(singleCondition, conditionIndex, index)
                    "
                    ><i class="icon-trash-regular"></i
                  ></span>
                  <span
                    v-else
                    class="defaultApprovalRuleTrashButton"
                    style="width: 14px"
                    ><i class="icon-trash-sdvsdv-regular"></i
                  ></span>
                </div>
              </div>
            </div>

            <!-- condition footer -->
            <div class="div2">
              <span style="margin-left: 19px; cursor: pointer">
                <span
                  style="
                    width: 11px;
                    height: 11px;
                    color: rgba(84, 189, 149, 1);
                  "
                  ><i class="icon-plus-regular"></i
                ></span>
                <span
                  style=""
                  class="defaultApprovalAddRuleButton"
                  @click="addRuleBox(singleCondition, conditionIndex, index)"
                  >{{ "Add  condition" }}</span
                >
              </span>
            </div>
          </section>

          <!-- approver head -->
          <section style="background: rgba(217, 217, 217, 0.2)">
            <div
              class="latitudelongitude"
              style="margin: 16px 0px 7px 30px; padding-top: 10px"
            >
              Approvals
            </div>

            <!-- approver sub head -->
            <div
              style="font-size: 10px; color: #999999; margin: 9px 0px 25px 32px"
            >
              {{
                "You can either configure approver levels or allow the system to auto approve"
              }}
            </div>

            <!-- switch radio -->
            <div
              class="switchForSelectApproval"
              style="margin: 12px 0px 12px 30px"
            >
              <hlx-input
                :position="'horizontal'"
                :options="quoteWorkFlow.ruleSection[index]?.selectManualAuto"
                :type="'radio-buttons'"
                @selected="emitRadio($event, index)"
              />
            </div>

            <!-- switch square -->
            <div class="defaultApprovalDIV2">
              <hlx-input
                :position="'horizontal'"
                :options="quoteWorkFlow.ruleSection[index].checkboxOptions"
                :type="'checkbox'"
                @selected="emitCheckbox($event, index)"
              />
            </div>

            <!-- add approver by -->
            <section
              v-if="quoteWorkFlow.ruleSection[index].vradio === true"
              class="defaultApprovalS4"
            >
              <!-- for showApproverNormal true -->
              <div
                v-if="
                  quoteWorkFlow.ruleSection[index].sideSelect?.toLowerCase() ===
                  'choose approvers from list'
                "
                class="defaultApprovalDIV1"
              >
                <hlx-select
                  :key="wenkey"
                  v-model:value="
                    quoteWorkFlow.ruleSection[index].selectedApprover
                  "
                  :pre-value="quoteWorkFlow.ruleSection[index].selectedApprover"
                  :slot-options="true"
                  :required="false"
                  :options="recipientContacts"
                  :placeholder-value="'Select approvers'"
                  :label-animation="false"
                  :single-border="true"
                  :label="'name'"
                  :prop-value="'name'"
                  :clearable="true"
                  :inline-search="true"
                  @key-press="searchRecipientContact"
                  @selected-value="addNewContactList($event, index)"
                >
                  <template #displayValue="value">
                    <span
                      style="
                        padding-left: 8px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      {{ value["displayLabel"]["name"] }}
                    </span>
                  </template>
                  <template #options="option">
                    <div
                      v-if="
                        option && option.optionsData && option.optionsData.name
                      "
                      style="
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        padding-left: 8px;
                        font-family: 'Opensans';
                        height: 31px !important;
                      "
                    >
                      <span style="font-size: 14px; font-weight: 400">{{
                        option.optionsData.name
                      }}</span>
                      <span style="padding-left: 10px; width: 20px"
                        ><i class="icon-buildings-alt-regular"></i
                      ></span>
                      <span
                        style="
                          font-size: 11px;
                          font-weight: 400;
                          padding-top: 3px;
                          padding-left: 8px;
                          color: #595959;
                        "
                        >{{ option.optionsData.company }}</span
                      >
                    </div>
                  </template>
                </hlx-select>

                <!-- div for drag -->
                <div
                  v-if="
                    quoteWorkFlow.ruleSection[index].checkboxOptions[0]
                      .checked === true
                  "
                  style=""
                  class="defaultApprovalDIV1subDIV2"
                >
                  <draggable
                    class="draggable-feature-values"
                    :list="quoteWorkFlow.ruleSection[index].approverList"
                    item-key="id"
                  >
                    <template #item="{ element, index: elementIndex }">
                      <div class="outerMainContainer" style="width: 349px">
                        <div style="margin: 0px 0px 4px 0px; color: #7e7e7e">
                          <i class="icon-drag-vertical-filled"></i>
                        </div>
                        <div class="draggableApprovalDefaultSpanForIcon">
                          <span>{{ elementIndex + 1 }}</span>
                        </div>
                        <div class="main1">
                          <!-- <img
                      style="height: 40px; width: 40px; object-fit: contain"
                      src="@/assets/images/Placeholder image.svg"
                      alt="product image preview"
                    /> -->
                          <hlx-file-upload
                            :key="componentkey"
                            :file-type="'.jpg,.jpeg,.png'"
                            :file-size-limit="'20mb'"
                            :uploadtype="'Circle'"
                            :style-background="
                              backgrounStyleuploadQuoteRecipient
                            "
                            :height="38"
                            :width="38"
                            :read-only="true"
                            :custom-icon="icon"
                            :type="'profile-upload'"
                            :profile-icon="'icon-user-alt-regular'"
                            :content="'Import'"
                            :name-display="element.name || ''"
                            :profile-pic-preview="''"
                          />
                        </div>
                        <div class="main2">
                          <div class="div1">
                            <span class="span1" style="font-size: 14px">{{
                              element.name
                            }}</span>
                            <span class="span2"
                              ><hlx-label class="primary sm">{{
                                element.aristocraticTitle
                              }}</hlx-label></span
                            >
                          </div>
                          <div class="div2" style="font-size: 10px">
                            {{ element.company }}
                          </div>
                        </div>
                        <div
                          style="margin-right: 7px"
                          @click="closeTheBox(element, elementIndex, index)"
                        >
                          <i class="icon-times-regular"></i>
                        </div>
                      </div>
                    </template>
                  </draggable>
                </div>

                <!-- non dragable ui -->
                <div
                  v-if="
                    quoteWorkFlow.ruleSection[index].checkboxOptions[0]
                      .checked === false
                  "
                  class="defaultApprovalDIV1subDIV2"
                >
                  <div
                    v-for="(i, indexDefalut) in quoteWorkFlow.ruleSection[index]
                      ?.approverList"
                    :key="indexDefalut"
                    class="outerMainContainer"
                    style="width: 349px"
                  >
                    <div class="main1">
                      <hlx-file-upload
                        :key="componentkey"
                        :file-type="'.jpg,.jpeg,.png'"
                        :file-size-limit="'20mb'"
                        :uploadtype="'Circle'"
                        :style-background="backgrounStyleuploadQuoteRecipient"
                        :height="38"
                        :width="38"
                        :read-only="true"
                        :custom-icon="icon"
                        :type="'profile-upload'"
                        :profile-icon="'icon-user-alt-regular'"
                        :content="'Import'"
                        :name-display="i.name || ''"
                        :profile-pic-preview="''"
                      />
                    </div>
                    <div class="main2">
                      <div class="div1">
                        <span class="span1" style="font-size: 14px">{{
                          i.name
                        }}</span>
                        <span class="span2"
                          ><hlx-label class="primary sm">{{
                            i.aristocraticTitle
                          }}</hlx-label></span
                        >
                      </div>
                      <div class="div2" style="font-size: 10px">
                        {{ i.company }}
                      </div>
                    </div>
                    <div
                      style="margin-right: 7px"
                      @click="closeTheBox(i, indexDefalut, index)"
                    >
                      <i class="icon-times-regular"></i>
                    </div>
                  </div>
                </div>
              </div>

              <!-- for showApproverNormal false -->
              <div
                v-if="
                  quoteWorkFlow.ruleSection[index].sideSelect?.toLowerCase() ===
                  'choose approver from lookup'
                "
                class="defaultApprovalDIV1new"
              >
                <!-- intelicence -->
                <!-- {{finalCodeEditor}} -->
                <div style="display: flex; flex-direction: row">
                  <span style="width: 345px">
                    <hlx-code-editor
                      :key="intelKey"
                      v-model:value="
                        quoteWorkFlow.ruleSection[index].finalCodeEditor
                      "
                      :intellisense-data="$store.state.intellisenceData"
                      :line-number="false"
                      :rows="1"
                      @selected-option="
                        intellisenceDataEmitFinal($event, index)
                      "
                    ></hlx-code-editor>
                  </span>
                  <!-- <span
                    style="
                      color: rgba(84, 189, 149, 1);
                      margin: 4px 0px 0px 10px;
                      cursor: pointer;
                    "
                    @click="addIntel(index)"
                    >Add</span
                  > -->
                </div>

                <!-- div for drag -->
                <div
                  v-if="
                    quoteWorkFlow.ruleSection[index].checkboxOptions[0]
                      .checked === true
                  "
                  style=""
                  class="defaultApprovalDIV1subDIV2"
                >
                  <draggable
                    class="draggable-feature-values"
                    :list="quoteWorkFlow.ruleSection[index].finalCodeEditorList"
                    item-key="id"
                  >
                    <template #item="{ element, index: elementIndex }">
                      <div class="outerMainContainer" style="width: 349px">
                        <div style="margin: 0px 0px 4px 0px; color: #7e7e7e">
                          <i class="icon-drag-vertical-filled"></i>
                        </div>
                        <div class="draggableApprovalDefaultSpanForIcon">
                          <span>{{ elementIndex + 1 }}</span>
                        </div>
                        <div class="main1">
                          <!-- <img
                      style="height: 40px; width: 40px; object-fit: contain"
                      src="@/assets/images/Placeholder image.svg"
                      alt="product image preview"
                    /> -->
                          <hlx-file-upload
                            :key="componentkey"
                            :file-type="'.jpg,.jpeg,.png'"
                            :file-size-limit="'20mb'"
                            :uploadtype="'Circle'"
                            :style-background="
                              backgrounStyleuploadQuoteRecipient
                            "
                            :height="38"
                            :width="38"
                            :read-only="true"
                            :custom-icon="icon"
                            :type="'profile-upload'"
                            :profile-icon="'icon-user-alt-regular'"
                            :content="'Import'"
                            :name-display="''"
                            :profile-pic-preview="''"
                          />
                        </div>
                        <div class="main2">
                          <div class="div1">
                            <span class="span1" style="font-size: 14px">{{
                              element.name
                            }}</span>
                            <!-- <span class="span2"
                          ><hlx-label class="primary sm">{{
                            element.aristocraticTitle
                          }}</hlx-label></span
                        > -->
                          </div>
                          <!-- <div class="div2" style="font-size: 10px">
                        {{ element.company }}
                      </div> -->
                        </div>
                        <div
                          style="margin-right: 7px"
                          @click="closeTheBox(element, elementIndex, index)"
                        >
                          <i class="icon-times-regular"></i>
                        </div>
                      </div>
                    </template>
                  </draggable>
                </div>

                <!-- non dragable ui -->
                <div
                  v-if="
                    quoteWorkFlow.ruleSection[index].checkboxOptions[0]
                      .checked === false
                  "
                  class="defaultApprovalDIV1subDIV2"
                >
                  <div
                    v-for="(i, indexDefalut) in quoteWorkFlow.ruleSection[index]
                      .finalCodeEditorList"
                    :key="indexDefalut"
                    class=""
                    style="margin: 15px 0px 15px 0px"
                  >
                    <div style="display: flex">
                      <span style="width: 52px">
                        <hlx-file-upload
                          :key="componentkey"
                          :file-type="'.jpg,.jpeg,.png'"
                          :file-size-limit="'20mb'"
                          :uploadtype="'Circle'"
                          :style-background="backgrounStyleuploadQuoteRecipient"
                          :height="38"
                          :width="38"
                          :read-only="true"
                          :custom-icon="icon"
                          :type="'profile-upload'"
                          :profile-icon="'icon-user-alt-regular'"
                          :content="'Import'"
                          :name-display="''"
                          :profile-pic-preview="''"
                        />
                      </span>

                      <span
                        class=""
                        style="
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          justify-content: space-between;
                          width: 230px;
                        "
                      >
                        <span class="" style="font-size: 14px">{{
                          i.name
                        }}</span>

                        <span
                          style=""
                          @click="closeTheBoxManager(i, indexDefalut, index)"
                        >
                          <i class="icon-times-regular"></i>
                        </span>

                        <!-- <span class="span2"
                      ><hlx-label class="primary sm">{{
                        i.aristocraticTitle
                      }}</hlx-label></span
                    > -->
                      </span>
                    </div>

                    <div class="" style="margin: 9px 0px 0px 0px">
                      <!-- <span class="sp1" style="">Set approval order</span> -->

                      <hlx-input
                        :position="'horizontal'"
                        :options="
                          quoteWorkFlow.ruleSection[index].finalCodeEditorList[
                            indexDefalut
                          ].checkboxOptionsNested
                        "
                        :type="'checkbox'"
                        @selected="
                          emitCheckboxNested($event, indexDefalut, index)
                        "
                      />
                    </div>

                    <!-- switch -->
                    <span
                      v-if="
                        quoteWorkFlow.ruleSection[index].finalCodeEditorList[
                          indexDefalut
                        ].checkboxOptionsNested[0].checked
                      "
                    >
                      <div
                        class=""
                        style="margin: 8px 0px 11px 45px; width: 337px"
                      >
                        <hlx-input
                          :position="'horizontal'"
                          :options="
                            quoteWorkFlow.ruleSection[index]
                              .finalCodeEditorList[indexDefalut]
                              .nestedRadioSwitchData
                          "
                          :type="'radio-buttons'"
                          @selected="
                            emitRadioNested($event, indexDefalut, index)
                          "
                        />
                      </div>

                      <!-- inner slot select -->
                      <div
                        v-if="
                          quoteWorkFlow.ruleSection[index].finalCodeEditorList[
                            indexDefalut
                          ].nestedRadioSwitchDataValue == true
                        "
                        style="margin: 0px 0px 0px 45px"
                      >
                        <div>
                          <hlx-select
                            :key="wenkey"
                            v-model:value="
                              quoteWorkFlow.ruleSection[index]
                                .finalCodeEditorList[indexDefalut]
                                .approverSecondNested
                            "
                            :pre-value="
                              quoteWorkFlow.ruleSection[index]
                                .finalCodeEditorList[indexDefalut]
                                .approverSecondNested
                            "
                            :slot-options="true"
                            :required="false"
                            :options="recipientContacts"
                            :placeholder-value="'Select approvers'"
                            :label-animation="false"
                            :single-border="true"
                            :label="'name'"
                            :prop-value="'name'"
                            :clearable="true"
                            :inline-search="true"
                            @key-press="searchRecipientContact"
                            @selected-value="
                              addNewContactListNested(
                                $event,
                                indexDefalut,
                                index
                              )
                            "
                          >
                            <template #displayValue="value">
                              <span
                                style="
                                  padding-left: 8px;
                                  display: flex;
                                  flex-direction: column;
                                  align-items: center;
                                "
                              >
                                {{ value["displayLabel"]["name"] }}
                              </span>
                            </template>
                            <template #options="option">
                              <div
                                v-if="
                                  option &&
                                  option.optionsData &&
                                  option.optionsData.name
                                "
                                style="
                                  display: flex;
                                  align-items: center;
                                  font-size: 14px;
                                  padding-left: 8px;
                                  font-family: 'Opensans';
                                  height: 31px !important;
                                "
                              >
                                <span
                                  style="font-size: 14px; font-weight: 400"
                                  >{{ option.optionsData.name }}</span
                                >
                                <span style="padding-left: 10px; width: 20px"
                                  ><i class="icon-buildings-alt-regular"></i
                                ></span>
                                <span
                                  style="
                                    font-size: 11px;
                                    font-weight: 400;
                                    padding-top: 3px;
                                    padding-left: 8px;
                                    color: #595959;
                                  "
                                  >{{ option.optionsData.company }}</span
                                >
                              </div>
                            </template>
                          </hlx-select>
                        </div>

                        <!-- list data -->
                        <div
                          v-for="(il, indexDefalutNew) in quoteWorkFlow
                            .ruleSection[index]?.finalCodeEditorList[
                            indexDefalut
                          ].approverSecondNestedList"
                          :key="indexDefalutNew"
                          class="outerMainContainer"
                          style="width: 320px"
                        >
                          <div class="main1">
                            <hlx-file-upload
                              :key="componentkey"
                              :file-type="'.jpg,.jpeg,.png'"
                              :file-size-limit="'20mb'"
                              :uploadtype="'Circle'"
                              :style-background="
                                backgrounStyleuploadQuoteRecipient
                              "
                              :height="38"
                              :width="38"
                              :read-only="true"
                              :custom-icon="icon"
                              :type="'profile-upload'"
                              :profile-icon="'icon-user-alt-regular'"
                              :content="'Import'"
                              :name-display="i.name || ''"
                              :profile-pic-preview="''"
                            />
                          </div>
                          <div class="main2">
                            <div class="div1">
                              <span class="span1" style="font-size: 14px">{{
                                il.name
                              }}</span>
                              <span class="span2"
                                ><hlx-label class="primary sm">{{
                                  il.aristocraticTitle
                                }}</hlx-label></span
                              >
                            </div>
                            <div class="div2" style="font-size: 10px">
                              {{ il.company }}
                            </div>
                          </div>
                          <div
                            style="margin-right: 7px"
                            @click="
                              closeTheBoxNested(il, indexDefalutNew, index)
                            "
                          >
                            <i class="icon-times-regular"></i>
                          </div>
                        </div>
                        <!-- list data empty -->

                        <!-- for extra space -->
                        <div
                          v-if="
                            quoteWorkFlow.ruleSection[index]
                              ?.finalCodeEditorList[indexDefalut]
                              .approverSecondNestedList.length == 0
                          "
                          style="height: 27px"
                        ></div>
                      </div>
                    </span>
                  </div>
                  <!-- loop end -->
                </div>
              </div>

              <!-- div 2 for side select -->
              <div class="defaultApprovalDIV2ree">
                <div style="display: flex">
                  <span style="width: 257px">
                    <hlx-select
                      v-model:value="
                        quoteWorkFlow.ruleSection[index].sideSelect
                      "
                      :required="false"
                      :options="chooseApprovalType"
                      :pre-value="quoteWorkFlow.ruleSection[index].sideSelect"
                      :placeholder-value="''"
                      :label-animation="true"
                      :single-border="true"
                      :label="'name'"
                      :prop-value="'value'"
                      :clearable="false"
                      @selected-value="emitedChooseApproval($event, index)"
                    ></hlx-select>
                  </span>
                </div>
              </div>

              <!-- for extra space -->
              <div style="height: 100px"></div>

              <!-- add approver by end -->
            </section>

            <!-- grey layout end -->
          </section>
        </div>

        <!-- rule footer -->
        <div
          v-if="
            quoteWorkFlow.ruleSection && quoteWorkFlow.ruleSection?.length > 1
          "
          style="
            display: flex;
            flex-direction: row-reverse;
            margin: 6px 6px 6px 0px;
          "
        >
          <span class="remove_button" @click="removeCondition(index)">
            <span
              style="
                width: 11px;
                height: 11px;
                /* color: rgba(84, 189, 149, 1) */
              "
              ><i class="icon-trash-regular"></i
            ></span>
            <span> Delete </span>
          </span>
        </div>

        <!-- loop end -->
      </div>

      <!-- condition -->
      <div
        style="
          margin: 7px 16px 25px 30px;
          display: flex;
          flex-direction: row-reverse;
        "
      >
        <span
          class="defaultApprovalAddRuleButton"
          @click="addCondition(item, index)"
        >
          <i class="icon-plus-regular"></i>
          {{ "Add rule" }}
        </span>
      </div>
    </section>

    <!-- last right -->
    <section style="width: 112px">
      <div
        v-if="100 < 1"
        style="margin: 16px 1px 1px 19px; display: flex; cursor: pointer"
        @click="backToQuoteAutomationTable"
      >
        <span style="font-size: 20px"
          ><i class="icon-arrow-left-regular"></i
        ></span>
        <span style="padding: 2px 0px 0px 10px; font-size: 15px">Back</span>
      </div>
    </section>
  </section>
  <discardComponent
    :show="enableDiscardModal"
    @stay="handleStay"
    @close="handleDiscardClose"
    @discard="handleDiscard"
  >
  </discardComponent>
</template>

<script>
import draggable from "vuedraggable";
import discardComponent from "@/components/discardComponent.vue";
import { DMNAxiosInstance, UPCAxiosInstance, MLTAxiosInstance } from "@/config/axiosConfig";
export default {
  components: {
    draggable,
    discardComponent,
  },
  data() {
    return {
      intelKey:0,
      enableDiscardModal: false,
      quoteWorkFlow: {
        ruleName: "",
        tagPreValue: [],
        enableRule: false,
        description: "",
        ruleSection: [
          {
            condition: [
              {
                operationPreValue: "AND",
                categoryPreValue: "Total contract value",
                conditionPreValue: "Greater than",
                inputPreValue: "",
                numberPreValue: "",
                marketPreValue: [],
                accountPreValue: [],
                categoryType: "Number",
                optionRef: ""
              },
            ],

            vradio: true,
            selectManualAuto: [
              {
                name: "Configure approval flow",
                checked: true,
                disabled: false,
                id: 1,
              },
              { name: "Auto approve", checked: false, disabled: false, id: 2 },
            ],
            checkboxOptions: [
              {
                name: "Set approval order",
                checked: false,
                disabled: false,
                id: 2,
              },
            ],
            showDraggableFunction: false,
            sideSelect: "Choose approvers from list",
            selectedApprover: "",
            approverList: [
              {
                name: "Ayshwariya ashok",
                aristocraticTitle: "Supervisor",
                company: "Videocon",
              },
            ],
            finalCodeEditor: "",
            finalCodeEditorList: [],
          },
        ],
      },

      nestedRadioSwitchData: [
        {
          name: "Choose approver",
          checked: true,
          disabled: false,
          id: 1,
        },
        { name: "Auto approve", checked: false, disabled: false, id: 2 },
      ],

      finalCodeEditor: "",

      finalCodeEditorList: [],

      showApproverNormal: false,

      chooseApprovalType: [
        {
          value: "Choose approver from lookup",
          name: "Choose approver from lookup",
        },
        {
          value: "Choose approvers from list",
          name: "Choose approvers from list",
        },
      ],

      checkboxOptions: [
        { name: "Set approval order", checked: false, disabled: false, id: 2 },
      ],

      checkboxOptionsNested: [
        {
          name: "If no approver found",
          checked: false,
          disabled: false,
          id: 2,
        },
      ],

      list: {
        schema: [
          { label: "quote.agreement", type: "string" },
          { label: "quote.attachment", type: "string" },
          { label: "quote.quoteDate", type: "date" },
          { label: "quote.quoteItem", type: "Array" },
        ],
        keywords: [
          { label: "if" },
          { label: "then" },
          { label: "else" },
          { label: "and" },
          { label: "or" },
          { label: "function" },
          { label: "filter" },
          { label: "reduce" },
          { label: "map" },
          { label: "Date" },
          { label: "Math" },
          { label: "slice" },
          { label: "substr" },
          { label: "concat" },
          { label: "true" },
          { label: "false" },
          { label: "API()" },
          { label: "Email()" },
        ],
        api: [],
        email: [],
        methods: {
          array: [
            {
              label: "filter",
              syntax:
                "array.filter(function(currentValue, index, arr), thisValue)",
            },
            {
              label: "reduce",
              syntax:
                "array.reduce(function(total, currentValue, currentIndex, arr), initialValue)",
            },
            {
              label: "map",
              syntax:
                "array.map(function(currentValue, index, arr), thisValue)",
            },
          ],
          date: [
            {
              label: "getDay",
              syntax: "getDay()",
            },
            {
              label: "getDate",
              syntax: "getDate()",
            },
            {
              label: "getTime",
              syntax: "getTime()",
            },
            {
              label: "getMonth",
              syntax: "getMonth()",
            },
            {
              label: "getFullYear",
              syntax: "getFullYear()",
            },
            {
              label: "setDate",
              syntax: "setDate()",
            },
            {
              label: "setTime",
              syntax: "setTime()",
            },
            {
              label: "setMonth",
              syntax: "setMonth()",
            },
            {
              label: "setFullYear",
              syntax: "setFullYear()",
            },
          ],
          integer: [
            {
              label: "random",
              syntax: "Math.random()",
            },
            {
              label: "round",
              syntax: "Math.round(x)",
            },
          ],
          string: [
            { label: "charAt", syntax: "string.charAt(index)" },
            { label: "slice", syntax: "string.slice(start, end)" },
          ],
        },
      },
      recipientLists: [],
      marketOptions: [],
      clearCurrency: 0,
      hideRemove: 0,
      notification: {},
      tagPreValue1: [],
      tagPreValue: [],
      ruleName: "",
      CategoryList: [
        {
          value: "Total discount percentage",
          name: "Total discount percentage",
          type: "Number",
        },
        {
          value: "Total discount value",
          name: "Total discount value",
          type: "Number",
        },
        {
          value: "Total contract value",
          name: "Total contract value",
          type: "Number",
        },
        {
          value: "Total margin percentage",
          name: "Total margin percentage",
          type: "Number",
        },
        { value: "Account category", name: "Account category", type: "Array" },
        { value: "Account name", name: "Account name", type: "String" },
        { value: "Market segment", name: "Market segment", type: "Array" },
      ],
      condition: [
        { value: "Is not empty", name: "Is not empty", type: ["String"] },
        { value: "Is empty", name: "Is empty", type: ["String"] },
        { value: "Contains", name: "Contains", type: ["String"] },
        {
          value: "Does not contain",
          name: "Does not contain",
          type: ["String"],
        },
        { value: "Matches regex", name: "Matches regex", type: ["String"] },
        { value: "Length > X", name: "Length > X", type: ["String"] },
        { value: "Length < X", name: "Length < X", type: ["String"] },

        { value: "Is selected", name: "Is selected", type: ["Array"] },
        { value: "Is not selected", name: "Is not selected", type: ["Array"] },
        { value: "Is equal to (X)", name: "Is equal to (X)", type: ["Array"] },
        {
          value: "Is one of (multiple values)",
          name: "Is one of (multiple values)",
          type: ["Array"],
        },

        { value: "Yes", name: "Yes", type: ["Boolean"] },
        { value: "No", name: "No", type: ["Boolean"] },

        { value: "Is null", name: "Is null", type: ["Date", "FileUpload"] },
        {
          value: "Is not null",
          name: "Is not null",
          type: ["Date", "FileUpload"],
        },
        {
          value: "Is on a specific date",
          name: "Is on a specific date",
          type: ["Date"],
        },
        {
          value: "Is before (specific date)",
          name: "Is before (specific date)",
          type: ["Date"],
        },
        {
          value: "Is after (specific date)",
          name: "Is after (specific date)",
          type: ["Date"],
        },
        {
          value: "Less than X days from today",
          name: "Less than X days from today",
          type: ["Date"],
        },
        {
          value: "Less than or equal to X days from today",
          name: "Less than or equal to X days from today",
          type: ["Date"],
        },
        {
          value: "Greater than X days from today",
          name: "Greater than X days from today",
          type: ["Date"],
        },
        {
          value: "Greater than or equal to X days from today",
          name: "Greater than or equal to X days from today",
          type: ["Date"],
        },
        {
          value: "Equal to X day(s) from today",
          name: "Equal to X day(s) from today",
          type: ["Date"],
        },
        {
          value: "Is between (X and Y days) from today",
          name: "Is between (X and Y days) from today",
          type: ["Date"],
        },
        {
          value: "Is within date range",
          name: "Is within date range",
          type: ["Date"],
        },
        {
          value: "Is within X days from today",
          name: "Is within X days from today",
          type: ["Date"],
        },

        {
          value: "Matches file type",
          name: "Matches file type",
          type: ["FileUpload"],
        },
        {
          value: "Matches file size limit",
          name: "Matches file size limit",
          type: ["FileUpload"],
        },

        {
          value: "Is greater than (X)",
          name: "Is greater than (X)",
          type: ["Number"],
        },
        {
          value: "Is greater than or equal to (X)",
          name: "Is greater than or equal to (X)",
          type: ["Number"],
        },
        {
          value: "Is less than (X)",
          name: "Is less than (X)",
          type: ["Number"],
        },
        {
          value: "Is less than or equal to (X)",
          name: "Is less than or equal to (X)",
          type: ["Number"],
        },
        {
          value: "Is not equal to (X)",
          name: "Is not equal to (X)",
          type: ["Number"],
        },
        { value: "Is equal to (X)", name: "Is equal to (X)", type: ["Number"] },
        {
          value: "Is within range (X to Y)",
          name: "Is within range (X to Y)",
          type: ["Number"],
        },
      ],
      operation: [
        { value: "OR", name: "OR" },
        { value: "AND", name: "AND" },
      ],
      approverDivision: [
        {
          selectedApprover: "",
          approverList: [
            {
              name: "Ayshwariya ashok",
              aristocraticTitle: "Supervisor",
              company: "Videocon",
            },
          ],
          selectManualAuto: [
            {
              name: "Configure approval flow",
              checked: true,
              disabled: false,
              id: 1,
            },
            { name: "Auto approve", checked: false, disabled: false, id: 2 },
          ],
          vradio: true,
          showDraggableFunction: false,
          rule: [
            {
              operationPreValue: "AND",
              ruleValuePreValue: "Total contract value",
              conditionPreValue: "Greater than",
              numberPreValue: "",
              marketPreValue: [],
              accountPreValue: [],
            },
          ],
        },
      ],
      radioOptions: [
        {
          name: "Configure approval flow",
          checked: true,
          disabled: false,
          id: 1,
        },
        { name: "Auto approve", checked: false, disabled: false, id: 2 },
      ],
      tagOptions: [],
      // tagOptions:[{name:"Price approval",value:"Price approval"},{name:"Approval process",value:"Approval process"}
      // ,{name:"Custom quote",value:"Custom quote"}
      // ,{name:"Automated pricing",value:"Automated pricing"}
      // ,{name:"Sales order",value:"Sales order"}
      // ],
      backgrounStyleuploadQuoteRecipient: {
        backgroundColor: "",
        bacgroundtTextColor: "#000",
        borderColor: "#A8A8A8",
        TextfontSize: 14,
      },
      showContidition: false,
      ruleBox: [1],
      wenkey: 0,
      wen: "",
      enableRule: true,
      recipientsList: [
        {
          name: "Deen Martin",
          role: "Executive",
          company: "Mahindra",
        },
        {
          name: "Curise Ben",
          role: "Coordinator",
          company: "Benz",
        },
        {
          name: "Tester New",
          role: "Analyst",
          company: "Mahindra",
        },
      ],
      recipientContacts: [],
      //       recipientContacts: [
      //   {
      //     "name": "Deen Martin",
      //     "role": "Executive",
      //     "company": "Mahindra"
      //   },
      //   {
      //     "name": "Curise Ben",
      //     "role": "Coordinator",
      //     "company": "Benz"
      //   },
      //   {
      //     "name": "Tester New",
      //     "role": "Analyst",
      //     "company": "Mahindra"
      //   },
      //   {
      //     "name": "John Wick",
      //     "role": "Administrator",
      //     "company": "Mahindra"
      //   },
      //   {
      //     "name": "Jon Bowie",
      //     "role": "Administrator",
      //     "company": "Benz"
      //   }
      // ],
    };
  },
  watch: {
    approverDivision: {
      async handler(val) {
        console.log("approverDivision....", val);
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    await this.$store.dispatch("initIntellisenceData");

    const list = await this.$store.state.intellisenceData;
    console.log("this.list", list);
    this.list = list;

    const schema = await UPCAxiosInstance.get(
      `/customSchema/getSchema?module=${"saleslead"}`
    );

    console.log(schema, "schema");
    let CategoryList = schema?.data?.schema;

    const filteredOverview = Object.entries(CategoryList);
    console.log("filteredOverview::))",filteredOverview);

    CategoryList = filteredOverview;

    this.CategoryList = CategoryList.map(([mainData, obj]) => ({ mainData: mainData,
      type: obj.type,
      section: obj.section,
      optionRef: obj.optionRef }));
    console.log("this.CategoryList::))",this.CategoryList);

    
    
    





    // let Arr = localStorage.getItem("SettingsAutomationQuoteApprover");
    // Arr = JSON.parse(Arr);
    // if (Arr) {
    //   Arr.forEach((item) => {
    //     if (this.$route.query.approval_id === item.id) {
    //       this.ruleName = item.name;
    //       this.enableRule = item.enableRule;
    //       this.description = item.description;
    //       //multi start
    //       // this.tagPreValue1 = item?.tags?.map(item=>item?.name)
    //       //multi end
    //       this.tagPreValue = item.tags;
    //       this.approverDivision = item.approverDivision;
    //     }
    //   });
    //   // this.$route.query.approval_id === item.id
    // } else {
    //   Arr;
    // }

    // const rootName = this.$cookies.get("rootName");
    // const url =
    //     rootName === "Halleyx"
    //       ? "mapRef/collection/market"
    //       : `mapRef/collection/market?company=${this.$cookies.get("rootName")}`;
    // const unitOfmesures = await DMNAxiosInstance.get(
    //   url
    // );
    this.marketOptions = await this.getReferanceData("market");

    let recipientListOptions = {
      company: this.company,
      type: "filter-search",
      module: "hierarchyaccounts",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {
        name: 1,
      },
      limit: 2,
      filterQuery: {
        status: { $regex: "active", $options: "i" },
        "@baseType": "customer",
      },
      searchQuery: "",
    };
    // Recipient preloads
    let recipientLists = await MLTAxiosInstance.post(
      "/util/filterData",
      recipientListOptions
    );
    console.log("🚀 ~ mounted ~ recipientLists:", recipientLists);
    this.recipientLists = recipientLists.data.data || [];

    // const tagsListurl =
    //     rootName === "Halleyx"
    //       ? "mapRef/collection/tagsList"
    //       : `mapRef/collection/tagsList?company=${this.$cookies.get("rootName")}`;

    // let tagsList = await DMNAxiosInstance.get(tagsListurl);
    let tagsRef = await this.getReferanceData("tagsList");
    this.tagOptions = tagsRef?.filter((item) => {
      return item?.module === "qa";
    });

    ///////////////////

    let recipientContactOptions = {
      company: this.$cookies.get("company"),
      type: "filter-search",
      module: "contacts",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {
        name: 1,
      },
      limit: 2,
      filterQuery: {
        // status: { $regex: 'active', $options: 'i' },
        status: {
          $in: ["Active", "Pending"],
        },
        "@baseType": "customer",
      },
      searchQuery: "",
    };
    let recipientLists1 = await MLTAxiosInstance.post(
      "/util/filterData",
      recipientContactOptions
    );
    this.recipientContacts = recipientLists1?.data?.data || [];

    // Filter recipientContacts directly by checking against approverList names
    this.recipientContacts = this.recipientContacts.filter(
      (contact) =>
        !this.approverDivision.some((itm) =>
          // console.log("11111111111222222223333333333",itm);

          itm.approverList.some((approver) => approver.name === contact.name)
        )
    );
  },
  methods: {

    discardFromCancel() {
      this.$router.back();
    },

    async handleDiscard() {
      this.$router.back();
      // if (this.tempBack) {
      //   this.$router.back();
      // } else {
      //   this.$router.push(this.$store.state.tempActiveSideBarItem.link);
      // }
      this.enableDiscardModal = !this.enableDiscardModal;
      // this.discardIt = true;
    },

    handleDiscardClose() {
      this.enableDiscardModal = false;
    },

    handleStay() {
      // //handle Stay funtionality to hide discard component
      this.enableDiscardModal = !this.enableDiscardModal;
      console.log(
        "🚀 ~ handleStay ~ this.enableDiscardModal:",
        this.enableDiscardModal
      );
    },

    getConditionsByType(type = "Number") {
      console.log("getConditionsByType::", type, this.condition);

      // Filter conditions by the provided type or default to "Number".
      return this.condition.filter((conditions) =>
        conditions.type.includes(type)
      );
    },

    intellisenceDataEmitFinal(event, index) {
      console.log("intellisenceDataEmitFinal:::", event, index);
      let obj = {
        name: event.label,
        value: event.label,
        checkboxOptionsNested: [
          {
            name: "If no approver found",
            checked: false,
            disabled: false,
            id: 2,
          },
        ],
        nestedRadioSwitchData: [
          {
            name: "Choose approver",
            checked: true,
            disabled: false,
            id: 1,
          },
          { name: "Auto approve", checked: false, disabled: false, id: 2 },
        ],
        nestedRadioSwitchDataValue: true,
        approverSecondNested: "",
        approverSecondNestedList: [],
      };

      this.quoteWorkFlow.ruleSection[index].finalCodeEditorList.push(obj);

      console.log("this.quoteWorkFlow:::)))", this.quoteWorkFlow);
      // setTimeout(() => {
      // this.quoteWorkFlow.ruleSection[index].finalCodeEditor = "";
      this.intelKey += 1;
      // }, 1000);
    }, //done

    emitRadioNested(event, indexSub, index) {
      console.log("emitRadioNested::))", event, indexSub, index);
      // nestedRadioSwitchDataValue

      this.quoteWorkFlow.ruleSection[index].finalCodeEditorList[
        indexSub
      ].nestedRadioSwitchData = this.quoteWorkFlow.ruleSection[
        index
      ].finalCodeEditorList[indexSub].nestedRadioSwitchData.map((item) => {
        return {
          ...item,
          checked: item?.name?.toLowerCase() === event?.name?.toLowerCase(), // Set `checked` true for the emitted object, false for others
        };
      });

      if (event.name === "Choose approver") {
        this.quoteWorkFlow.ruleSection[index].finalCodeEditorList[
          indexSub
        ].nestedRadioSwitchDataValue = true;
      } else {
        this.quoteWorkFlow.ruleSection[index].finalCodeEditorList[
          indexSub
        ].nestedRadioSwitchDataValue = false;
      }
    }, //done

    emitCheckboxNested(event, indexSub, index) {
      console.log("emitCheckboxNested::))", event, indexSub, index);

      this.quoteWorkFlow.ruleSection[index].finalCodeEditorList[
        indexSub
      ].checkboxOptionsNested[0].checked = event.checked;
    }, //done

    emitCheckbox(event, index) {
      console.log("emitCheckbox:::", event, index);
      this.quoteWorkFlow.ruleSection[index].showDraggableFunction =
        event.checked;
      this.quoteWorkFlow.ruleSection[index].checkboxOptions[0].checked =
        event.checked;
    }, //done

    addIntel(index) {
      let obj = {
        name: this.quoteWorkFlow.ruleSection[index].finalCodeEditor,
        value: this.quoteWorkFlow.ruleSection[index].finalCodeEditor,
        checkboxOptionsNested: [
          {
            name: "If no approver found",
            checked: false,
            disabled: false,
            id: 2,
          },
        ],
        nestedRadioSwitchData: [
          {
            name: "Choose approver",
            checked: true,
            disabled: false,
            id: 1,
          },
          { name: "Auto approve", checked: false, disabled: false, id: 2 },
        ],
        nestedRadioSwitchDataValue: true,
        approverSecondNested: "",
        approverSecondNestedList: [],
      };

      this.quoteWorkFlow.ruleSection[index].finalCodeEditorList.push(obj);

      console.log("this.quoteWorkFlow:::)))", this.quoteWorkFlow);
    }, //done
    emitedChooseApproval(event, index) {
      event, index;
      console.log("event, ruleIndex, index::))", event, index);

      this.quoteWorkFlow.ruleSection[index].sideSelect = event.value;

      if ("Choose approver from lookup" === event.value) {
        this.showApproverNormal = true;
      } else {
        this.showApproverNormal = false;
      }
    }, //done

    async searchRecipientContact(value) {
      value;
      console.log("searchRecipientContact", value);

      let recipientContactOptions = {
        company: this.$cookies.get("company"),
        type: "filter-search",
        module: "contacts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {
          name: 1,
        },
        limit: 2,
        filterQuery: {
          // status: { $regex: 'active', $options: 'i' },
          status: {
            $in: ["Active", "Pending"],
          },
          "@baseType": "customer",
        },
        searchQuery: value,
      };
      let recipientLists = await MLTAxiosInstance.post(
        "/util/filterData",
        recipientContactOptions
      );
      this.recipientContacts = recipientLists?.data?.data;
    },

    async addTagList(e) {
      let obj = {};
      obj.name = e.name;
      obj.module = "qa";
      await DMNAxiosInstance.post(`mapRef/tagsList`, obj);

      let tagOptions = await this.getReferanceData("tagsList");
      this.tagOptions = tagOptions?.filter((item) => {
        return item.module === "qa";
      });
      this.quoteWorkFlow?.tagPreValue.push(e.name);
    },

    handleRecipientChanges(event, ruleIndex, index) {
      console.log("handleRecipientChanges...", event, ruleIndex, index);
      this.approverDivision[index].rule[ruleIndex].accountPreValue = event?.map(
        (item) => item?.tradingName
      );
    },

    async searchRecipientList(value) {
      // value = this.recipientSearchPlaceHolder;
      console.log("🚀 ~ searchRecipientList ~ value:", value, this.company);
      // if(this.recipientSearchValue && this.recipientSearchValue.trim()) {
      // if (value == undefined || !this.company) return;
      // value = value.trim();
      let recipientListOptions = {
        company: this.$cookies.get("company"),
        type: "filter-search",
        module: "hierarchyaccounts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {
          name: 1,
        },
        limit: 2,
        filterQuery: {
          "@baseType": "customer",
          status: { $regex: "active", $options: "i" },
        },
        searchQuery: value,
      };
      let recipientLists = await MLTAxiosInstance.post(
        "/util/filterData",
        recipientListOptions
      );
      console.log("recipientLists...", recipientLists);
      this.recipientLists = recipientLists.data.data || [];
      // this.recipientLists = recipientLists;
      // if (this.recipientCompleteInfo) {
      //   this.recipientLists = this.recipientLists.filter(
      //     (recipient) =>
      //       recipient.tradingName != this.recipientCompleteInfo.tradingName
      //   );
      //   this.recipientLists.splice(0, 0, this.recipientCompleteInfo);
      // }
      // }
    },

    marketValues(event, ruleIndex, index) {
      console.log(event, ruleIndex, index, "marketSegment");
      if (event && event.length > 0) {
        event = event?.map((item) => item?.name);
        this.quoteWorkFlow.ruleSection[index].condition[
          ruleIndex
        ].marketPreValue = event;
      }
    },

    generateId(prefix) {
      return `${prefix}-${Math.random().toString(36).substr(2, 9)}`;
    },

    formatDeveloperString(input) {
      return input.replace(/([a-z])([A-Z])/g, "$1 $2");
    },

    async createNewRule() {
      this.notification = {
        type: "success",
        message: `Approval rule is saved successfully!`,
      };
      // let Arr = localStorage.getItem("SettingsAutomationQuoteApprover");
      // Arr = JSON.parse(Arr);
      //if edit
      // if (this.$route.query.approval_id) {
      //   const updatedArr = await Promise.all(
      //     Arr.map(async (obj) => {
      //       if (obj.id === this.$route.query.approval_id) {
      //         obj.name = this.ruleName;
      //         obj.enableRule = this.enableRule;
      //         obj.description = this.description;
      //         obj.tags = this.tagPreValue;

      //         try {
      //           const val1 = await MLTAxiosInstance.get(
      //             "partymanagement/individual/auth"
      //           );
      //           console.log("company name:", val1?.data?.company);
      //           obj.createdBy = this.formatDeveloperString(val1?.data?.company);
      //         } catch (error) {
      //           console.error("Error fetching company data:", error);
      //         }

      //         obj.approverDivision = this.approverDivision;
      //       }
      //       return obj;
      //     })
      //   );

      // if (updatedArr) {
      //   localStorage.setItem(
      //     "SettingsAutomationQuoteApprover",
      //     JSON.stringify(updatedArr)
      //   );
      //   this.notification = {
      //     type: "success",
      //     message: `Approval rule is saved successfully!`,
      //   };
      // }
      // }
      //if new
      // else {
      //   let ID = this.generateId("approval");
      //   let Arr = localStorage.getItem("SettingsAutomationQuoteApprover");
      //   Arr = JSON.parse(Arr);
      //   if (Arr) {
      //     Arr;
      //     let obj = {};
      //     obj;
      //     obj.id = ID;
      //     obj.name = this.ruleName;
      //     obj.enableRule = this.enableRule;
      //     obj.description = this.description;
      //     obj.tags = this.tagPreValue;
      //     const val1 = await MLTAxiosInstance.get(
      //       "partymanagement/individual/auth"
      //     );
      //     console.log("company name:", val1?.data?.company);
      //     obj.createdBy = this.formatDeveloperString(val1?.data?.company);
      //     obj.approverDivision = this.approverDivision;
      //     Arr.unshift(obj);
      //     // let obj1 = null;
      //     localStorage.setItem(
      //       "SettingsAutomationQuoteApprover",
      //       JSON.stringify(Arr)
      //     );
      //     console.log("old...", Arr);
      //   } else {
      //     let obj = {};
      //     obj;
      //     obj.id = ID;
      //     obj.name = this.ruleName;
      //     obj.enableRule = this.enableRule;
      //     obj.description = this.description;
      //     obj.tags = this.tagPreValue;
      //     const val1 = await MLTAxiosInstance.get(
      //       "partymanagement/individual/auth"
      //     );
      //     console.log("company name:", val1?.data?.company);
      //     obj.createdBy = this.formatDeveloperString(val1?.data?.company);
      //     obj.approverDivision = this.approverDivision;
      //     let Arr = [obj];
      //     localStorage.setItem(
      //       "SettingsAutomationQuoteApprover",
      //       JSON.stringify(Arr)
      //     );
      //     console.log("new...", Arr);
      //   }
      //   this.notification = {
      //     type: "success",
      //     message: `New Approval rule is Created!`,
      //   };

      //   setTimeout(() => {
      //     this.$router.push({
      //       name: "defaultApproval",
      //       query: {
      //         approval_id: ID,
      //       },
      //     });
      //   }, 2000);
      // }
    },

    selectedArray(val) {
      console.log("selectedArray....", val);
      this.quoteWorkFlow.tagPreValue = val.map((item) => item?.name);
    },

    emitedconditionPreValue(event, ruleIndex, index) {
      console.log("emitedconditionPreValue...", event, ruleIndex, index);
      this.quoteWorkFlow.ruleSection[index].condition[
        ruleIndex
      ].conditionPreValue = event.value;
    }, //done

    async emitedRuleValue(event, ruleIndex, index) {
      event, ruleIndex, index

      // console.log("emitedRuleValue....", event, event?.type, ruleIndex, index);
      this.quoteWorkFlow.ruleSection[index].condition[
        ruleIndex
      ].categoryPreValue = event.mainData;

      this.quoteWorkFlow.ruleSection[index].condition[
        ruleIndex
      ].categoryType = event.type;
      console.log("emitedRuleValue....))",event, ruleIndex, index);

      if (event && event?.optionRef) {
        this.quoteWorkFlow.ruleSection[index].condition[
          ruleIndex
        ].optionRef =
          event?.optionRef;

          this.quoteWorkFlow.ruleSection[index].condition[
          ruleIndex
        ].optionRef =
        await this.getReferanceData(event?.optionRef);

        console.log("await reff:::",await this.getReferanceData(event?.optionRef));
        
        this.componentKeyMulti += 1;


      }
      

      
    }, //done

    emitedRuleOperation(val, ruleIndex, index) {
      console.log("emitedregiongroup", val, ruleIndex, index);
      this.quoteWorkFlow.ruleSection[index].condition[
        ruleIndex
      ].operationPreValue = val.value;
    }, //done

    addCondition(item, index) {
      console.log("addCondition::", item, index);

      item, index;
      //////////////////////////////////////////////////////////
      // let obj =
      // {
      //   selectedApprover: "",
      //   approverList: [
      //     {
      //       name: "Ayshwariya ashok",
      //       aristocraticTitle: "Supervisor",
      //       company: "Videocon",
      //     },
      //   ],
      //   selectManualAuto: [
      //     {
      //       name: "Configure approval flow",
      //       checked: true,
      //       disabled: false,
      //       id: 1,
      //     },
      //     { name: "Auto approve", checked: false, disabled: false, id: 2 },
      //   ],
      //   vradio: true,
      //   showDraggableFunction: false,
      //   rule: [
      //     {
      //       operationPreValue: "AND",
      //       ruleValuePreValue: "Total contract value",
      //       conditionPreValue: "Greater then",
      //       numberPreValue: "",
      //       marketPreValue: [],
      //       accountPreValue: [],
      //     },
      //   ],
      // };
      let obj = {
        condition: [
          {
            operationPreValue: "AND",
            categoryPreValue: "Total contract value",
            conditionPreValue: "Greater than",
            inputPreValue: "",
            numberPreValue: "",
            marketPreValue: [],
            accountPreValue: [],
            categoryType: "Number",
                optionRef: ""
          },
        ],

        vradio: true,
        selectManualAuto: [
          {
            name: "Configure approval flow",
            checked: true,
            disabled: false,
            id: 1,
          },
          { name: "Auto approve", checked: false, disabled: false, id: 2 },
        ],
        checkboxOptions: [
          {
            name: "Set approval order",
            checked: false,
            disabled: false,
            id: 2,
          },
        ],
        showDraggableFunction: false,
        sideSelect: "Choose approvers from list",
        selectedApprover: "",
        approverList: [
          {
            name: "Ayshwariya ashok",
            aristocraticTitle: "Supervisor",
            company: "Videocon",
          },
        ],
        finalCodeEditor: "",
        finalCodeEditorList: [],
      };
      ///////////////////////////////////////////////////
      if (this.quoteWorkFlow.ruleSection.length > 0) {
        this.quoteWorkFlow.ruleSection.push(obj);
      }
    }, //done

    removeCondition(i) {
      this.quoteWorkFlow.ruleSection.splice(i, 1);
    }, //done

    emitRadio(event, index) {
      console.log("emitRadio...", event, index);

      this.quoteWorkFlow.ruleSection[index].selectManualAuto =
        this.quoteWorkFlow?.ruleSection[index]?.selectManualAuto.map((item) => {
          return {
            ...item,
            checked: item?.name?.toLowerCase() === event?.name?.toLowerCase(), // Set `checked` true for the emitted object, false for others
          };
        });

      if (event.name === "Configure approval flow") {
        this.quoteWorkFlow.ruleSection[index].vradio = true;
      } else {
        this.quoteWorkFlow.ruleSection[index].vradio = false;
      }

      // Find the corresponding radio option in vradio by matching the event's name
      // const vradioItem = this.approverDivision[index].selectManualAuto.find(
      //   (item) => item.name === event.name
      // );

      // if (vradioItem) {
      //   // Set the checked value based on the event's checked property
      //   vradioItem.checked = event.checked;

      //   // Ensure all other radios are unchecked (if it's a radio button behavior)
      //   this.approverDivision[index].selectManualAuto.forEach((item) => {
      //     if (item.name !== event.name) {
      //       item.checked = false; // Uncheck all other radio buttons
      //     }
      //   });
      // }
      console.log(
        "emitradio,,,,,",
        this.quoteWorkFlow.ruleSection[index].selectManualAuto,
        this.quoteWorkFlow.ruleSection[index].vradio
      );
    }, //done

    showContiditionfunction() {
      this.showContidition = true;
    },

    closeRuleBox(item, index, mainIndex) {
      item, index, mainIndex;
      this.quoteWorkFlow.ruleSection[mainIndex]?.condition?.splice(index, 1);
    }, //done

    addRuleBox(item, index, mainIndex) {
      item, index, mainIndex;
      let obj = {
        operationPreValue: "AND",
        categoryPreValue: "Total contract value",
        conditionPreValue: "Greater than",
        inputPreValue: "",
        numberPreValue: "",
        marketPreValue: [],
        accountPreValue: [],
        categoryType: "Number",
                optionRef: "",
      };
      this.quoteWorkFlow.ruleSection[mainIndex].condition[0];
      // this.approverDivision[mainIndex]?.rule[0]
      if (this.quoteWorkFlow.ruleSection[mainIndex].condition[0]) {
        this.quoteWorkFlow.ruleSection[mainIndex].condition.push(obj);
      }
    }, //done

    backToQuoteAutomationTable() {
      // this.$router.push("/settings/automation/quoting");
      this.enableDiscardModal = true;
    },

    addNewContactList(val, index) {
      console.log("addNewContactList...", this.recipientContacts, val, index);
      this.wenkey += 1;
      this.quoteWorkFlow.ruleSection[index].selectedApprover = "";
      if (val) {
        val.aristocraticTitle;
        val.name;
        val.company;
        const obj = {
          name: val.name,
          aristocraticTitle: val.aristocraticTitle,
          company: val.company,
        };
        let bool = false;
        this.quoteWorkFlow.ruleSection[index]?.approverList.forEach((itm) => {
          if (itm.name === val.name) {
            bool = true;
            bool;
          }
        });
        if (!bool) {
          this.quoteWorkFlow.ruleSection[index]?.approverList?.push(obj);
        }
      }
      ///////////////////////////////////////
      this.recipientContacts = this.recipientContacts.filter(
        (itm) => itm.name !== val.name
      );
    }, //done

    addNewContactListNested(val, subIndex, index) {
      val, subIndex, index;
      console.log("addNewContactList...", this.recipientContacts, val, index);
      this.wenkey += 1;
      this.quoteWorkFlow.ruleSection[index].finalCodeEditorList[
        subIndex
      ].approverSecondNested = "";
      if (val) {
        val.aristocraticTitle;
        val.name;
        val.company;
        const obj = {
          name: val.name,
          aristocraticTitle: val.aristocraticTitle,
          company: val.company,
        };
        let bool = false;
        this.quoteWorkFlow.ruleSection[index]?.finalCodeEditorList[
          subIndex
        ].approverSecondNestedList.forEach((itm) => {
          if (itm.name === val.name) {
            bool = true;
            bool;
          }
        });
        if (!bool) {
          this.quoteWorkFlow.ruleSection[index]?.finalCodeEditorList[
            subIndex
          ].approverSecondNestedList?.push(obj);
        }
      }
      ///////////////////////////////////////
      this.recipientContacts = this.recipientContacts.filter(
        (itm) => itm.name !== val.name
      );
    }, //done

    async closeTheBox(element, elementIndex, index) {
      // Ensure element is defined
      if (!element) {
        console.error("Element is undefined or null");
        return;
      }

      // Find the index of the clicked item
      const indexNew = this.quoteWorkFlow.ruleSection[
        index
      ]?.approverList.findIndex(
        (recipient) =>
          recipient.name === element.name &&
          recipient.role === element.role &&
          recipient.company === element.company
      );
      // If the item is found, remove it
      if (indexNew !== -1) {
        this.quoteWorkFlow.ruleSection[index]?.approverList.splice(indexNew, 1);
        console.log("item removed");
      } else {
        console.log(`Item not found in the list`);
      }

      //////////////////
      if (element && element?.name) {
        this.recipientContacts;
        ///////////////////

        let recipientContactOptions = {
          company: this.$cookies.get("company"),
          type: "filter-search",
          module: "contacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          sortFieldAndOrder: {
            name: 1,
          },
          limit: 2,
          filterQuery: {
            // status: { $regex: 'active', $options: 'i' },
            status: {
              $in: ["Active", "Pending"],
            },
            "@baseType": "customer",
          },
          searchQuery: "",
        };
        let recipientLists1 = await MLTAxiosInstance.post(
          "/util/filterData",
          recipientContactOptions
        );
        this.recipientContacts = recipientLists1?.data?.data || [];

        // Filter recipientContacts directly by checking against approverList names
        this.recipientContacts = this.recipientContacts.filter(
          (contact) =>
            !this.quoteWorkFlow.ruleSection.some((itm) =>
              // console.log("11111111111222222223333333333",itm);

              itm.approverList.some(
                (approver) => approver.name === contact.name
              )
            )
        );
      }
    }, //done

    async closeTheBoxNested(element, elementIndex, index) {
      // Ensure element is defined
      if (!element) {
        console.error("Element is undefined or null");
        return;
      }

      // Find the index of the clicked item
      const indexNew = this.quoteWorkFlow.ruleSection[
        index
      ]?.finalCodeEditorList[elementIndex].approverSecondNestedList.findIndex(
        (recipient) =>
          recipient.name === element.name &&
          recipient.role === element.role &&
          recipient.company === element.company
      );
      // If the item is found, remove it
      if (indexNew !== -1) {
        this.quoteWorkFlow.ruleSection[index]?.finalCodeEditorList[
          elementIndex
        ].approverSecondNestedList.splice(indexNew, 1);
        console.log("item removed");
      } else {
        console.log(`Item not found in the list`);
      }

      //////////////////
      if (element && element?.name) {
        this.recipientContacts;
        ///////////////////

        let recipientContactOptions = {
          company: this.$cookies.get("company"),
          type: "filter-search",
          module: "contacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          sortFieldAndOrder: {
            name: 1,
          },
          limit: 2,
          filterQuery: {
            // status: { $regex: 'active', $options: 'i' },
            status: {
              $in: ["Active", "Pending"],
            },
            "@baseType": "customer",
          },
          searchQuery: "",
        };
        let recipientLists1 = await MLTAxiosInstance.post(
          "/util/filterData",
          recipientContactOptions
        );
        this.recipientContacts = recipientLists1?.data?.data || [];

        // Filter recipientContacts directly by checking against approverList names
        // this.recipientContacts = this.recipientContacts.filter(
        //   (contact) =>
        //     !this.quoteWorkFlow.ruleSection.some((itm) =>
        //       // console.log("11111111111222222223333333333",itm);

        //       itm.approverList.some(
        //         (approver) => approver.name === contact.name
        //       )
        //     )
        // );
      }
    }, //done

    async closeTheBoxManager(element, elementIndex, index) {
      this.quoteWorkFlow.ruleSection[index]?.finalCodeEditorList?.splice(
        elementIndex,
        1
      );

      // // Ensure element is defined
      // if (!element) {
      //   console.error("Element is undefined or null");
      //   return;
      // }

      // // Find the index of the clicked item
      // const indexNew = this.quoteWorkFlow.ruleSection[index]?.approverList.findIndex(
      //   (recipient) =>
      //     recipient.name === element.name &&
      //     recipient.role === element.role &&
      //     recipient.company === element.company
      // );
      // // If the item is found, remove it
      // if (indexNew !== -1) {
      //   this.quoteWorkFlow.ruleSection[index]?.approverList.splice(indexNew, 1);
      //   console.log("item removed");
      // } else {
      //   console.log(`Item not found in the list`);
      // }

      // //////////////////
      // if (element && element?.name) {
      //   this.recipientContacts;
      //   ///////////////////

      //   let recipientContactOptions = {
      //     company: this.$cookies.get("company"),
      //     type: "filter-search",
      //     module: "contacts",
      //     isPaginated: true,
      //     paginatedQuery: {
      //       rowsPerPage: 10,
      //       currentPage: 1,
      //     },
      //     isNeedToBeSort: true,
      //     sortFieldAndOrder: {
      //       name: 1,
      //     },
      //     limit: 2,
      //     filterQuery: {
      //       // status: { $regex: 'active', $options: 'i' },
      //       status: {
      //         $in: ["Active", "Pending"],
      //       },
      //       "@baseType": "customer",
      //     },
      //     searchQuery: "",
      //   };
      //   let recipientLists1 = await MLTAxiosInstance.post(
      //     "/util/filterData",
      //     recipientContactOptions
      //   );
      //   this.recipientContacts = recipientLists1?.data?.data || [];

      //   // Filter recipientContacts directly by checking against approverList names
      //   this.recipientContacts = this.recipientContacts.filter(
      //     (contact) =>
      //       !this.quoteWorkFlow.ruleSection.some((itm) =>
      //         // console.log("11111111111222222223333333333",itm);

      //         itm.approverList.some(
      //           (approver) => approver.name === contact.name
      //         )
      //       )
      //   );
      // }
    }, //done
  },
};
</script>

<style lang="scss">
.remove_button {
  cursor: pointer;
  font-family: "OPENSANS";
  font-size: 14px;
  font-weight: 400;
  margin: 0px 0px 0px 0px;
  color: #fc3232;
}

.defaultApprovalS2 {
  display: flex;
  flex-direction: row;
  margin: 0px 16px 24px 30px;
  height: 150px;
  column-gap: 37px;

  .defaultApprovalD1 {
    display: flex;
    justify-content: space-between;
    margin: 0px;
    width: 100%;
    flex-direction: column;

    // .defaultApprovalSp1,
    // .defaultApprovalSp2,
    .defaultApprovalSp3 {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: center;
      span {
        font-size: 14px;
      }
      span {
      }
    }
  }

  .defaultApprovalD2 {
    width: 100%;
  }
}
.defaultApprovalS3 {
  margin: 26px 16px 0px 30px;
  border-bottom: 1px solid #d8d8d8;
}
.defaultApprovalS4 {
  display: flex;
  flex-direction: row;
  margin: 5px 16px 0px 30px;
  column-gap: 37px;
  justify-content: space-between;
  overflow: auto;
  .defaultApprovalDIV1 {
    width: 564px;
    .defaultApprovalDIV1subDIV2 {
      margin-top: 7px;
      height: auto;
      max-height: 344px;
      overflow: auto;
    }
  }
  .defaultApprovalDIV2ree {
    width: 100%;
  }
  .defaultApprovalDIV1new {
    width: 361px;
  }
}

.condition_remove_div {
  display: flex;
  flex-direction: row;
  margin: 22px 16px 19px 30px;
  justify-content: space-between;

  .defaultApprovalS5 {
    font-family: "QuickSand";
    font-size: 14px;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
  }
}

.defaultApprovalS6 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 16px 16px 0px 0px;
  // background: rgba(217, 217, 217, 0.2);
  .div1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .subDiv {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 8px 0px;
      .subSubDiv-1 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 135px;
      }
      .subSubDiv1 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      .subSubDiv2 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-right: 19px;
      }
      .subSubDiv3 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-left: 19px;
      }
    }
  }
  .div2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    height: 24px;
    width: 100%;
    margin: 0px 0px 0px -3px;
  }
}
.defaultApprovalRuleTrashButton {
  width: 10px;
  height: 10px;
  margin: 0px 34px 8px 10px;
}

.draggableApprovalDefaultSpanForIcon {
  font-size: 12px;
  font-weight: 400;
  margin: 0px 7px 5px 7px;
  color: rgba(122, 122, 122, 1);
}

.seperate_container_of_select_approval {
  // border:1px solid black;
  margin: 0px 16px 0px 30px;
}

.defaultApprovalAddRuleButton {
  color: rgba(84, 189, 149, 1);
  font-size: 14px;
  font-weight: 400;
  font-family: "OpenSans";
  margin-left: 3px;
  cursor: pointer;
}

.defaultApprovalDIV2 {
  margin: 0px 16px 0px 30px;

  // width: 100%;
  // .switchInputclass {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: end;
  //   align-items: center;
  //   margin-top: 7px;
  //   .sp1 {
  //     font-size: 14px;
  //     font-weight: 600;
  //     font-family: "QuickSand";
  //   }
  //   .sp2 {
  //     width: 12px;
  //     height: 12px;
  //     color: #6b6b6b;
  //     margin: -8px 0px 0px 3px;
  //   }
  //   .sp3 {
  //   }
  // }
}
</style>
