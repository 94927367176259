<template>
  <hlx-modal
    :modal-active="delete_modal_related_product"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_related_product = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content" style="margin-top: 4%">
        <p>
          Are you sure you want to delete <b>{{ deleteRelatedProductName }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button
        class="secondary media"
        @click="delete_modal_related_product = !delete_modal_related_product"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary media"
        style="margin-left: 16px"
        @click="deleteRelatedProd"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
  <discardComponent
    :show="showOverviewDiscardModal"
    @stay="handleStay"
    @discard="handleDiscard"
    @close="showOverviewDiscardModal = false"
  >
  </discardComponent>

  <hlx-alert-notification :notify="notification" :auto-close="true" />
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'200px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>

    <template #content>
      <div class="modals-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>

    <template #footer>
      <hlx-button class="secondary sm" @click="cancelPricing"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>

  <hlx-modal
    :modal-active="delete_price_modal"
    :height="'200px'"
    :width="'400px'"
    :modal="true"
    @close="delete_price_modal = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>

    <template #content>
      <div class="modal-content">
        <p>Are you sure you want to delete pricing?</p>
      </div>
    </template>

    <template #footer>
      <hlx-button class="secondary sm" @click="delete_price_modal = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="closedeleteModal"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>

  <hlx-modal
    :modal-active="delete_price_modal"
    :height="'200px'"
    :width="'400px'"
    :modal="true"
    @close="delete_price_modal = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>

    <template #content>
      <div class="modal-content">
        <p>Are you sure you want to delete pricing?</p>
      </div>
    </template>

    <template #footer>
      <hlx-button class="secondary sm" @click="delete_price_modal = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="closedeleteModal"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>

  <hlx-modal
    :modal-active="delete_supplier_modal"
    :height="'200px'"
    :width="'400px'"
    :modal="true"
    @close="delete_supplier_modal = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>

    <template #content>
      <div class="modal-content">
        <p>Are you sure you want to delete data?</p>
      </div>
    </template>

    <template #footer>
      <hlx-button class="secondary sm" @click="delete_supplier_modal = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteSupplierModal"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>

  <section style="display: flex; flex-direction: column; height: 100vh">
    <main
      class="decision-add-container --layout-3-way"
      :style="
        isRightLayoutOpened
          ? 'display: grid;grid-template-columns: 200px calc(100% - 600px) 300px'
          : ''
      "
      style="height: 100vh"
    >

      <!-- --------------------------------------------------------------------Left pannel-------------------------------------------------- -->
      <section
        class="cm-fields-left-panel"
        style="width: 220px; background-color: #fff; overflow: hidden"
      >
        <!-- Breadcrumb -->
        <div class="layout-breadcrumb">
          <hlx-breadcrumb :items="breadCrumbItems" @path="goTo()" />
        </div>
        <div style="margin-top: 25px">
          <leftpannelComponent
            :side-bar-data="sideBarData"
            @value-name="valueleft"
          ></leftpannelComponent>
        </div>
      </section>
      <section
        class="--layout-inner-right-portion --RL70 --upcv2rightlayout --offer"
        style="padding: 1.8em 20px; overflow: auto"
        :style="
          isRightLayoutOpened === false
            ? 'width:calc(100% - 240px)'
            : 'width:100%'
        "
      >
        <div class="responsve-layout-breadcrumb">
          <hlx-breadcrumb :items="breadCrumbItems" @path="goTo()" />
        </div>
        <div class="decision-info-right-pannel" style="overflow: hidden">
          <section class="header" style="margin-left: -7px">
            <div class="title-info">
              <!--responsive Breadcrumb -->

              <div class="responsive-cm-fields-left-panel">
                <leftpannelComponent
                  :side-bar-data="sideBarData"
                  @value-name="valueleft"
                ></leftpannelComponent>
              </div>
              <div class="profile">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.40039 16.0004C2.40039 17.7864 2.75216 19.5549 3.43563 21.2049C4.11909 22.8549 5.12086 24.3542 6.38374 25.617C7.64662 26.8799 9.14587 27.8817 10.7959 28.5652C12.4459 29.2486 14.2144 29.6004 16.0004 29.6004C17.7864 29.6004 19.5549 29.2486 21.2049 28.5652C22.8549 27.8817 24.3542 26.8799 25.617 25.617C26.8799 24.3542 27.8817 22.8549 28.5652 21.2049C29.2486 19.5549 29.6004 17.7864 29.6004 16.0004C29.6004 12.3934 28.1675 8.93423 25.617 6.38374C23.0666 3.83324 19.6073 2.40039 16.0004 2.40039C12.3934 2.40039 8.93423 3.83324 6.38374 6.38374C3.83324 8.93423 2.40039 12.3934 2.40039 16.0004ZM28.0004 16.0004C28.0004 19.183 26.7361 22.2352 24.4857 24.4857C22.2352 26.7361 19.183 28.0004 16.0004 28.0004C12.8178 28.0004 9.76555 26.7361 7.51511 24.4857C5.26467 22.2352 4.00039 19.183 4.00039 16.0004C4.00039 12.8178 5.26467 9.76555 7.51511 7.51511C9.76555 5.26467 12.8178 4.00039 16.0004 4.00039C19.183 4.00039 22.2352 5.26467 24.4857 7.51511C26.7361 9.76555 28.0004 12.8178 28.0004 16.0004Z"
                    fill="#54BD95"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.4004 16.0002C10.4004 23.0338 12.7076 28.8002 16.0004 28.8002C19.2932 28.8002 21.6004 23.0338 21.6004 16.0002C21.6004 8.96659 19.2932 3.2002 16.0004 3.2002C12.7076 3.2002 10.4004 8.96659 10.4004 16.0002ZM20.0004 16.0002C20.0004 22.2226 18.0084 27.2002 16.0004 27.2002C13.9924 27.2002 12.0004 22.2226 12.0004 16.0002C12.0004 9.77779 13.9924 4.80019 16.0004 4.80019C18.0084 4.80019 20.0004 9.77779 20.0004 16.0002Z"
                    fill="#54BD95"
                  />
                  <path
                    d="M5.97619 8.4997L7.04819 7.3125C7.21939 7.4661 7.40179 7.6165 7.59699 7.7621C9.70579 9.3429 13.0338 10.3061 16.661 10.3061C19.7538 10.3061 22.6402 9.6069 24.749 8.4021C25.2349 8.12719 25.6939 7.80739 26.1202 7.4469L27.1602 8.6629C26.6834 9.0709 26.1426 9.4469 25.5442 9.7893C23.181 11.1413 20.021 11.9061 16.661 11.9061C12.709 11.9061 9.04179 10.8453 6.63699 9.0421C6.40849 8.87101 6.18799 8.69108 5.97619 8.4997ZM5.97619 23.5205L7.04819 24.7045C7.21939 24.5509 7.40179 24.4005 7.59699 24.2565C9.70579 22.6757 13.0338 21.7125 16.661 21.7125C19.7538 21.7125 22.6402 22.4101 24.749 23.6165C25.2626 23.9109 25.7218 24.2309 26.1202 24.5701L27.1602 23.3541C26.6579 22.929 26.1169 22.5519 25.5442 22.2277C23.181 20.8773 20.021 20.1125 16.661 20.1125C12.709 20.1125 9.04179 21.1733 6.63699 22.9749C6.40339 23.1509 6.18259 23.3333 5.97619 23.5205ZM3.2002 16.8005V15.2005H28.8002V16.8005H3.2002Z"
                    fill="#54BD95"
                  />
                </svg>
              </div>
              <div class="title">
                <p class="--upcv2-header-title --staus-label">
                  <span>
                    <!-- {{ convertTextTo(payloadSpec?.name, "pascalcaseWithSpace") }} -->
                    {{ payloadSpec?.name }}    {{ editMode }}

                  </span>
                </p>
                <p
                  v-if="payloadSpec.offerType == 'Atomic'"
                  class="--upcv2-header-subtitle"
                >
                  {{ leaf(spec?.category) }}
                </p>
                <p v-else class="--upcv2-header-subtitle">
                  {{ payloadSpec?.category?.join(", ") }}
                </p>
              </div>
            </div>
            <!-- varsan commented this line  -->
            <!-- {{  payloadSpec.prodSpecCharValueUse }} -->
            <div
              v-if="leftpannel == 'Overview'"
              class="custom-button"
              style="display: flex; gap: 1rem; margin-left: auto"
            >
              <hlx-button  class="text sm" @click="goTo"
                >Cancel</hlx-button
              >
              <hlx-button
                v-if="enableButton == true && editMode == false"
                class="secondary sm"
                @click="saveSpec()"
                >Save</hlx-button
              >
              <hlx-button
                v-else
                class="secondary sm"
                :disabled="true"
                >Save</hlx-button
              >
              <hlx-button class="primary sm" @click="showPreview"
                >Preview</hlx-button
              >
            </div>
          </section>
          <!-- Overview -->
          <div v-if="leftpannel == ''">
            {{ " empty data" }}
          </div>
          <div v-if="leftpannel == 'Overview'">
            <div>
              <!-- overview  section -->
              <div class="upcv2-overview">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 17px;
                  "
                >
                  <p class="--upcv2-body-title">Overview</p>
                </div>
                <div
                  class="right-view-body offerview"
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                  "
                >
                  <section
                    class="right-view-body"
                    :style="{
                      width:
                        payloadSpec.offerType != 'Atomic'
                          ? 'calc(100% - 320px)'
                          : '70%',
                    }"
                  >
                    <div class="input-separtor">
                      <span class="input-wrapper">
                        <hlx-input
                          v-model:value="payloadSpec.name"
                          :pre-val="name"
                          :label-animation="true"
                          label-value="Offer name"
                          :disabled="ViewMode"
                          :read-only="ViewMode"
                          :readonly="ViewMode"
                          type="text"
                          required="true"
                          :clearable="true"
                          @atInput="checkName(payloadSpec.name)"
                      /></span>
                      <!-- {{productOwner.name}} -->
                      <span class="input-wrapper">
                        <hlx-select
                          v-model:value="payloadSpec.relatedParty[0].name"
                          :pre-value="payloadSpec.relatedParty[0].name"
                          :options="partyOptions"
                          :inline-search="true"
                          :disabled="ViewMode"
                          :read-only="ViewMode"
                          :readonly="ViewMode"
                          :placeholder-value="'Product contact'"
                          :prop-value="'name'"
                          :label="'name'"
                          :label-animation="true"
                          :clearable="true"
                          @selected-value="handleRelatedParty"
                        ></hlx-select>
                      </span>
                    </div>

                    <!-- status&manufacturor -->
                    <div class="input-separtor">
                      <span class="input-wrapper-withicon">
                        <hlx-multi-select
                          :pre-value="marketPreValue"
                          :options="marketOptions"
                          :placeholder="'Market'"
                          :label-animation="true"
                          :prop-value="'name'"
                          :label="'name'"
                          :disabled="ViewMode"
                          :read-only="ViewMode"
                          :readonly="ViewMode"
                          :filter-select="false"
                          @selected-array="marketValues"
                        ></hlx-multi-select>
                      </span>
                      <span class="input-wrapper-withicon">
                        <hlx-multi-select
                          :pre-value="channelPreValue"
                          :options="channelOptions"
                          :placeholder="'Channel'"
                          :label-animation="true"
                          :disabled="ViewMode"
                          :read-only="ViewMode"
                          :readonly="ViewMode"
                          :prop-value="'name'"
                          :label="'name'"
                          :filter-select="false"
                          @selected-array="channelValues"
                        ></hlx-multi-select>
                      </span>
                    </div>

                    <!-- contact&tags -->
                    <div class="input-separtor">
                      <span class="input-wrapper-withicon">
                        <div style="width: 100%">
                          <hlx-status
                            :type="'select-status'"
                            :status-option="statusList"
                            :disabled="ViewMode"
                            :read-only="ViewMode"
                            :readonly="ViewMode"
                            :prevalue="payloadSpec.lifecycleStatus"
                            @emit-select="emitStatus"
                          >
                          </hlx-status>
                        </div>
                      </span>
                      <span class="input-wrapper-withicon">
                        <hlx-multi-select
                          :key="componentkey"
                          :options="tagsOptions"
                          :pre-value="payloadSpec?.tags"
                          :placeholder="'Tags'"
                          :label-animation="true"
                          :prop-value="'name'"
                          :disabled="ViewMode"
                          :read-only="ViewMode"
                          :readonly="ViewMode"
                          :label="'name'"
                          :filter-select="false"
                          :tooltipposition="'left'"
                          @add-data="addTagList"
                          @selected-array="receivedTags"
                        ></hlx-multi-select>
                      </span>
                    </div>

                    <section class="custom-text-editor">
                      <hlx-text-editor
                        v-model:modelValue="texteditorVal"
                        :disabled="ViewMode"
                        :read-only="ViewMode"
                        :readonly="ViewMode"
                        :height="'315px'"
                        :slot-options="true"
                        @current-change="texteditorValue"
                      >
                        <template #slotValue>
                          <!-- Container for AI generator tool bars -->
                          <div id="ai-toolbar" class="ai-toolbar">
                            <!-- Regenerate icon -->
                            <i
                              v-if="AIChoicesForDescription.length > 0"
                              class="icon-arrows-repeat-regular --regenerate-icon"
                              @click="generateContentWithAI('regenerate')"
                            ></i>
                            <!-- Tool bar generator button -->
                            <hlx-button
                              v-if="isBasedOnFeature"
                              :disabled="disableAIToolBarButton"
                              class="text-info sm --generator-button"
                              @click="handleGeneratorAction"
                            >
                              <span class="--custom-button-with-icon">
                                <i class="icon-sparkle-regular"></i>
                                <span>Generate content with AI</span>
                                <i
                                  class="icon-info-circle-regular --info-icon"
                                ></i>
                              </span>
                              <!-- Context menu -->
                              <hlx-context-menu
                                v-if="payloadSpec?.offerType == 'Atomic'"
                                :top="aiContextMenuPositionTop"
                                :left="aiContextMenuPositionLeft"
                                :options="AIContextMenuOptions"
                                :show="isAIContextMenuVisible"
                                @chosen="generateContentWithAI($event)"
                              />
                              <hlx-context-menu
                                v-if="payloadSpec?.offerType == 'Bundle'"
                                :top="aiContextMenuPositionTop"
                                :left="aiContextMenuPositionLeft"
                                :options="AIContextMenuOptionsBundle"
                                :show="isAIContextMenuVisible"
                                @chosen="generateContentWithAI($event)"
                              />
                            </hlx-button>

                            <!-- Prompt toolbar -->
                            <div
                              v-if="isBasedOnPrompt"
                              class="ai-prompt-toolbar"
                            >
                              <hlx-input
                                v-model:value="prompt"
                                :label-animation="false"
                                :custom-slot-prefix="true"
                                :custom-slot-suffix="true"
                                label-value="Instruct AI to compose description"
                                @at-enter="
                                  generateContentWithAI(
                                    'generate using prompt',
                                    prompt
                                  )
                                "
                              >
                                <template #custom-slot-prefix>
                                  <i class="icon-sparkle-regular"></i>
                                </template>
                                <template #custom-slot-suffix>
                                  <i
                                    class="icon-send-right-regular"
                                    :class="{
                                      '--disable':
                                        prompt?.trim() == '' ||
                                        disableAIToolBarButton,
                                    }"
                                    @click.stop="
                                      generateContentWithAI(
                                        'generate using prompt',
                                        prompt
                                      )
                                    "
                                  ></i>
                                </template>
                              </hlx-input>

                              <span
                                id="prompt-action-menu"
                                class="prompt-action-menu"
                              >
                                <i
                                  class="icon-more-vertical-regular"
                                  @click="handleGeneratorAction('from prompt')"
                                >
                                  <!-- Context menu -->
                                  <hlx-context-menu
                                    v-if="payloadSpec?.offerType == 'Atomic'"
                                    :top="aiPromptContextMenuPositionTop"
                                    :left="aiPromptContextMenuPositionLeft"
                                    :options="AIPromptContextMenuOptions"
                                    :show="isAIPromptContextMenuVisible"
                                    @chosen="
                                      generateContentWithAI(
                                        $event,
                                        '',
                                        'from prompt'
                                      )
                                    "
                                  />
                                  <hlx-context-menu
                                    v-if="payloadSpec?.offerType == 'Bundle'"
                                    :top="aiPromptContextMenuPositionTop"
                                    :left="aiPromptContextMenuPositionLeft"
                                    :options="AIPromptContextMenuOptionsBundle"
                                    :show="isAIPromptContextMenuVisible"
                                    @chosen="
                                      generateContentWithAI(
                                        $event,
                                        '',
                                        'from prompt'
                                      )
                                    "
                                  />
                                </i>
                              </span>
                            </div>
                          </div>
                        </template>
                      </hlx-text-editor>
                    </section>
                    <!-- Feature configurator -->
                    <section
                      v-show="payloadSpec?.offerType == 'Atomic'"
                      class="feature-configurator"
                      style="display: flex; flex-direction: column; gap: 25px"
                    >
                      <div
                        class="header-section"
                        style="display: flex; flex-direction: column; gap: 7px"
                      >
                        <h2 class="--upcv2-header-title">Features</h2>
                        <h3 class="--upcv2-header-subtitle">
                          {{ feature?.description }}
                        </h3>
                      </div>
                      <div
                        class="feature-list"
                        style="display: flex; flex-direction: column; gap: 30px"
                      >
                        <div
                          v-for="(
                            feature, index
                          ) in spec.productSpecCharacteristic"
                          :key="index"
                          class="list-item"
                        >
                          <span class="feature">
                            {{ feature.name }}
                            <i
                              v-if="feature?.configurable == true"
                              class="icon-settings-regular"
                            ></i>
                          </span>
                          <!-- Configurable dropdown -->
                          <span
                            v-if="feature?.configurable == true"
                            class="feature-value"
                          >
                            <!-- {{ speedData[index]}}
                          <br/>
                                options
                                <br/>
                          {{speedOptions[index]}} -->
                            <!-- {{ speedData[index]}}
                                <br/>
                                options
                                <br/> -->
                            <!-- {{speedOptions[index]}} -->
                            <!-- {{
                                   feature.productSpecCharacteristicValue?.map(
                                  (item) => {
                                    return {
                                      ...item,
                                      displayValue:
                                        item.value + ' ' + item?.valueSuffix,
                                    };
                                  }
                                )
                                }} -->
                            <hlx-multi-select
                              :pre-value="speedData[index]"
                              :options="speedOptions[index]"
                              :placeholder="feature.name"
                              :label-animation="true"
                              :prop-value="'displayValue'"
                              :disabled="ViewMode"
                              :read-only="ViewMode"
                              :readonly="ViewMode"
                              :label="'displayValue'"
                              :filter-select="false"
                              :tooltipposition="'left'"
                              @selected-array="
                                handleFeatureConfig($event, index, feature)
                              "
                            ></hlx-multi-select>
                            <!-- :inline-search="true" -->
                          </span>

                          <!-- Single dropdown -->
                          <span v-else class="feature-value">
                            <hlx-select
                              :options="
                                feature.productSpecCharacteristicValue?.map(
                                  (item) => {
                                    return {
                                      ...item,
                                      displayValue:
                                        item.value + ' ' + item?.valueSuffix,
                                    };
                                  }
                                )
                              "
                              :placeholder-value="feature.name"
                              :pre-value="
                                prePopulateNonConfigurableFeaturePrevalue(
                                  payloadSpec.prodSpecCharValueUse,
                                  'name',
                                  feature.name,
                                  feature.productSpecCharacteristicValue
                                )
                              "
                              :label-animation="true"
                              :label="'displayValue'"
                              :disabled="ViewMode"
                              :read-only="ViewMode"
                              :readonly="ViewMode"
                              :prop-value="'displayValue'"
                              :clearable="true"
                              @custom-change="returnOptions"
                              @selected-value="
                                handleNonConfigurableFeature($event, feature)
                              "
                            >
                            </hlx-select>
                          </span>
                        </div>
                      </div>
                    </section>
                  </section>
                  <section
                    v-show="payloadSpec?.offerType == 'Atomic'"
                    class="left-view-body"
                    style="
                      display: flex;
                      flex-direction: column;
                      gap: 2%;
                      width: 30%;
                    "
                  >
                    <p class="--upcv2-body-title">Product specification</p>
                    <div
                      style="
                        border: 2px;
                        background: rgba(217, 217, 217, 0.16);
                        width: 100%;
                        padding: 1rem;
                        height: 303px;
                        border-radius: 5px;
                      "
                    >
                      <section
                        style="display: flex; flex-direction: column; gap: 30px"
                      >
                        <div>
                          <p class="--upcv2-body-title" style="font-size: 15px">
                            {{ payloadSpec.productSpecification.name }}
                          </p>
                          <p
                            class="--upcv2-header-subtitle"
                            style="font-size: 14px; margin-top: 2%"
                          >
                            {{ leaf(spec.category) }}
                          </p>
                        </div>
                        <section
                          style="
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            gap: 35px;
                          "
                        >
                          <div
                            style="
                              display: grid;
                              flex-direction: row;
                              grid-template-columns: 4fr max-content 5fr;
                              gap: 0.5rem;
                              align-items: center;
                            "
                          >
                            <p style="font-size: 14px">
                              <i
                                class="icon-buildings-alt-regular"
                                style="margin-right: 9%"
                              ></i
                              >Brand
                            </p>
                            <p>:</p>
                            <p
                              style="
                                font-size: 14px;
                                display: flex;
                                align-items: center;
                              "
                            >
                              {{ spec.brand }}
                            </p>
                          </div>
                          <div
                            style="
                              display: grid;
                              flex-direction: row;
                              grid-template-columns: 4fr max-content 5fr;
                              gap: 0.5rem;
                              align-items: center;
                            "
                          >
                            <p style="font-size: 14px">
                              <i
                                class="icon-calendar-minus-alt-regular"
                                style="margin-right: 9%"
                              ></i
                              >End of life
                            </p>
                            <p>:</p>
                            <p style="font-size: 14px">
                              {{
                                dateAndTimeFormatter(spec.validFor?.endOfLife)
                              }}
                            </p>
                          </div>

                          <div
                            style="
                              display: grid;
                              flex-direction: row;
                              grid-template-columns: 4fr max-content 5fr;
                              gap: 0.5rem;
                              align-items: center;
                            "
                          >
                            <p style="font-size: 14px">
                              <i
                                class="icon-calendar-times-alt-regular"
                                style="margin-right: 9%"
                              ></i
                              >End of support
                            </p>
                            <p>:</p>
                            <p style="font-size: 14px">
                              {{
                                dateAndTimeFormatter(spec.validFor?.endOfDate)
                              }}
                            </p>
                          </div>
                          <div>
                            <p
                              style="
                                font-size: 12px;
                                color: rgba(0, 101, 178, 1);
                                cursor: pointer;
                              "
                              @click="details(spec)"
                            >
                              View details
                            </p>
                          </div>
                        </section>
                      </section>
                    </div>
                  </section>

                  <!-- Bundle listings -->
                  <section
                    v-if="payloadSpec?.offerType == 'Bundle'"
                    class="left-view-body"
                    style="display: flex; flex-direction: column; gap: 2%"
                  >
                    <!-- width: 30%; -->
                    <!-- <p class="--upcv2-body-title">Bundle items</p> -->
                    <!-- {{offersList}} -->
                    <bundleListingComponent
                      :key="updateBundleComponent"
                      :get-price-type="propForBundleListingComponent"
                      :select-bundle-items="bundleList"
                      :component-width="'330px'"
                      :component-height="'100vh'"
                      :list-bundle-offers="offersList"
                      @selected-bundle-offers="selectedBundleOffers"
                      @removed-offer="removedOffers"
                      @selected-sku="selectedSku"
                    >
                    </bundleListingComponent>
                  </section>
                </div>
              </div>
            </div>
          </div>

          <!-- Discount -->
          <div v-if="leftpannel == 'Discounts'">
            <discountComponent
              :show-context-menu='true'
              :discount-data="discountData"
              @new-discount-data="addNewDiscountData"
              @delete-discount="deleteDiscountData"
            ></discountComponent>
          </div>

          <!-- By varsan -->
          <div v-if="leftpannel == 'Available regions'">
            <availableRegionsComponent
              :regiondata1="regionDatas"
              @regiondelete="regiondelete1"
            ></availableRegionsComponent>
          </div>

          <!-- Related products -->
          <div v-if="leftpannel == 'Recommended products'">
            <div v-show="isRecommended" class="upcv2-overview">
              <!-- Toolbar -->
              <section class="right-view-body-feature">
                <span class="spec-feature --table-tool-bar">
                  <p class="--upcv2-body-title">Recommended products</p>
                  <div
                    v-if="
                      relatedProducts.length > 0 || relatedProductsSearch !== ''
                    "
                    class="--table-tool-bar-wrapper"
                  >
                    <span class="util-items search"
                      ><hlx-search @search-key="productKey"></hlx-search
                    ></span>
                    <!-- Filter -->
                    <span class="schema-header-utils">
                      <!-- <hlx-button
                        class="secondary sm add-btn"
                        @click="showDrawerRight()"
                        ><i class="icon-filter-regular"></i
                      ></hlx-button> -->
                      <hlx-drawer
                        :show="show_right_filter"
                        :width="300"
                        :show-close-icon="true"
                        position="right"
                        :header="false"
                        :footer="false"
                        @close="closeRightFilter"
                      >
                        <template #body>
                          <div style="display: flex">
                            <!-- <div class="cross_filter" style="background-color: brown;">
      <i
                        class="icon-times-filled filter-hidden-time"
                        style="margin-right: 5px"
                        @click="closeNav()"
                      ></i
                      >
    </div> -->
                          </div>
                        </template>
                      </hlx-drawer>
                    </span>
                    <span class="util-items util-items-add"
                      ><hlx-button
                        v-if="addMode && editMode"
                        class="primary sm add-btn"
                        @click="incrementUpdateKey()"
                        ><i
                          class="icon-plus-circle-regular"
                          style="margin-right: 5px"
                        ></i
                        >Add recommended products</hlx-button
                      >
                    </span>
                  </div>
                </span>
                <section class="feature-table">
                  <!-- <div style="margin-bottom: 2%">
                    <hlx-switch
                      v-model="selected"
                      :switch-items="tabsection"
                      value-prop="name"
                      display-prop="name"
                      @change="relatedProductsHeader"
                    />
                  </div> -->

                  <section
                    v-if="relatedProducts.length > 0"
                    style="overflow-y: auto; max-height: 70vh; min-height: 70vh"
                  >
                    <hlx-table
                      :column-count="spec_char_thead.length + 2"
                      :border="['table', 'header', 'horizontal']"
                      :bold-headers="false"
                      :row-hover="true"
                      theme="grey"
                      :striped-rows="false"
                      :re-arrange-columns="re_arrange"
                      :re-arrange-heads="spec_char_thead"
                      @close-rearrange="arrange"
                      @apply-rearranged-columns="applyColumns"
                    >
                      <template #thead>
                        <hlx-table-head
                          :width="60"
                          :align="'left'"
                          style="
                            padding: 0 15px !important;
                            max-width: 60px !important;
                          "
                          :fixed="'left'"
                          >{{ "S.no" }}</hlx-table-head
                        >
                        <hlx-table-head
                          v-for="(i, index) in product_heads"
                          :key="index"
                          :prop="i.prop"
                          :sortable="i.sortable"
                          :resizable="i.resizable"
                          :width="i.width"
                          @sorting_func="sorting_Data"
                          @resizewidth="resizewidthdata"
                        >
                          <span
                            v-if="
                              [
                                'price',
                                'productOfferingPrice',
                                'pricePerUnit',
                              ].includes(i.prop)
                            "
                          >
                            {{ i.label }} ({{
                              $store?.getters?.getDefaultSettings?.currency
                                ?.unit
                            }})
                          </span>
                          <span v-else>
                            {{ i.label }}
                          </span>
                        </hlx-table-head>
                        <hlx-table-head
                          v-show="editMode === true"
                          :align="'center'"
                          :width="70"
                          :fixed="'right'"
                          ><span class="action-icon">
                            <i class="icon-settings-regular"></i> </span
                        ></hlx-table-head>
                      </template>
                      <template #tbody>
                        <tr
                          v-for="(i, index) in productPaginated"
                          id=""
                          :key="index"
                        >
                          <hlx-table-cell
                            :align="'center'"
                            style="padding: 0 15px"
                            :fixed="'left'"
                            >{{
                              serialNumberRelatedProducts(index)
                            }}</hlx-table-cell
                          >
                          <hlx-table-cell
                            v-for="(j, col_index) in product_heads"
                            :key="col_index"
                            :align="
                              j.prop?.toLowerCase() === 'updatedat'
                                ? 'left'
                                : ''
                            "
                          >
                            <div v-if="j.prop === 'name'">
                              <p
                                class="bg-default border"
                                :data-tooltip="i['description']"
                                data-tooltip-location="top"
                              >
                                {{
                                  i[j?.prop]?.charAt(0)?.toUpperCase() +
                                  i[j?.prop]?.slice(1)
                                }}
                              </p>
                            </div>
                            <div v-if="j.prop === 'category'">
                              {{ leaf(i[j.prop]) || "-" }}
                            </div>
                            <div
                              v-else-if="j.prop == 'price'"
                              class="product-price"
                            >
                              {{ formatPriceRange(i[j.prop]) }}
                              <!-- <span>{{i}}</span>
                            {{ computedProductPrice(i) }} -->
                            </div>
                            <div v-else>
                              <span v-if="i[j.prop] == null">-</span>
                              <span v-else>
                                {{ convertTextTo(val?.value, "pascalcase") }}
                              </span>
                            </div>
                          </hlx-table-cell>
                          <hlx-table-cell
                            :align="'center'"
                            :width="120"
                            :fixed="'right'"
                          >
                            <span
                              v-show="editMode === true"
                              :id="
                                'table-context' +
                                serialNumberRelatedProducts(index - 1)
                              "
                              class="action-icon"
                            >
                              <!-- <i class="icon-edit-regular"></i> &nbsp;
                      <i class="icon-copy-regular"></i> &nbsp;
                      <i class="icon-trash-regular"></i> -->
                              <!-- serialNumber(index - 1) -->
                              <i
                                style="position: relative"
                                class="icon-more-vertical-filled"
                                :class="
                                  i.context === true ? 'active-action' : ''
                                "
                                @click="
                                  contextMenu(
                                    $event,
                                    serialNumberRelatedProducts(index - 1)
                                  )
                                "
                              >
                                <!-- Line 579 changed by varsan from crudContextMenu to contextMenu -->
                                <hlx-context-menu
                                  :top="containertop"
                                  :left="containerleft"
                                  :conditionvalue="150"
                                  :conditiondown="2"
                                  :conditionup="-38"
                                  :options="computedTableAction(i)"
                                  :data="index.toString()"
                                  :show="i.context == true"
                                  @chosen="
                                    closeCrudContextMenu(
                                      $event,
                                      i,
                                      index
                                      // serialNumberRelatedProducts(index - 1)
                                    )
                                  "
                                />
                              </i>
                            </span>
                          </hlx-table-cell>
                        </tr>
                      </template>
                    </hlx-table>
                  </section>
                  <section>
                    <div
                      v-if="
                        relatedProducts.length === 0 &&
                        relatedProductsSearch !== ''
                      "
                      class="cm-accounts-right-panel-top-section-table-wrap"
                    >
                      <hlx-table
                        :column-count="spec_char_thead.length + 2"
                        :border="['table', 'header']"
                        :bold-headers="false"
                        :row-hover="true"
                        theme="grey"
                        :striped-rows="false"
                        :re-arrange-columns="re_arrange"
                        :re-arrange-heads="spec_char_thead"
                        @close-rearrange="arrange"
                        @apply-rearranged-columns="applyColumns"
                      >
                        <template #thead>
                          <hlx-table-head
                            v-for="(i, index) in product_heads"
                            :key="index"
                            :prop="i.prop"
                            :sortable="i.sortable"
                            :resizable="i.resizable"
                            :width="i.width"
                            @sorting_func="sorting_Data"
                            @resizewidth="resizewidthdata"
                          >
                            <span
                              v-if="
                                [
                                  'price',
                                  'productOfferingPrice',
                                  'pricePerUnit',
                                ].includes(i.prop)
                              "
                            >
                              {{ i.label }} ({{
                                $store?.getters?.getDefaultSettings?.currency
                                  ?.unit
                              }})
                            </span>
                            <span v-else>
                              {{ i.label }}
                            </span>
                          </hlx-table-head>
                          <hlx-table-head
                            v-show="editMode === true"
                            :width="100"
                          >
                            <span class="action-icon"
                              ><i
                                class="icon-settings-regular"
                                @click="arrange(true)"
                              ></i></span
                          ></hlx-table-head>
                        </template>
                        <template #tbody>
                          <tr>
                            <hlx-table-cell></hlx-table-cell>
                          </tr>
                          <tr>
                            <hlx-table-cell></hlx-table-cell>
                          </tr>
                          <tr>
                            <hlx-table-cell></hlx-table-cell>
                          </tr>
                          <tr>
                            <hlx-table-cell></hlx-table-cell>
                          </tr>
                          <tr>
                            <hlx-table-cell
                              :colspan="spec_char_thead.length + 2"
                              >{{ "No data" }}</hlx-table-cell
                            >
                          </tr>
                          <tr>
                            <hlx-table-cell></hlx-table-cell>
                          </tr>
                          <tr>
                            <hlx-table-cell></hlx-table-cell>
                          </tr>
                          <tr>
                            <hlx-table-cell></hlx-table-cell>
                          </tr>
                          <tr>
                            <hlx-table-cell></hlx-table-cell>
                          </tr>
                          <tr>
                            <hlx-table-cell></hlx-table-cell>
                          </tr>
                        </template>
                      </hlx-table>
                    </div>
                  </section>
                </section>
                <div v-if="relatedProducts.length > 0">
                  <hlx-pagination
                    v-model:rows-per-page="externalRowsPerPage"
                    :total="productPaginated?.length"
                    enable-rows-per-page
                    :rows-per-page-list="[10, 15, 20]"
                    :options="{
                      attributes: {
                        rowsPerPageTitle: true,
                        rowsPerPageResult: true,
                        rowsPerPageDropdown: {
                          position: {
                            top: 0,
                            right: 1,
                          },
                        },
                      },
                    }"
                    @current-page="currentPage"
                    @updated:rows-per-page="changeRowsPerPage"
                  ></hlx-pagination>
                </div>
              </section>

              <section
                v-if="
                  relatedProducts.length <= 0 && relatedProductsSearch === ''
                "
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  gap: 2px;
                  margin-top: 6%;
                "
              >
                <img
                  class="file-icon"
                  src="@/assets/images/svg/relatedProducts.svg"
                  alt=""
                />

                <span class="util-items util-items-add"
                  ><hlx-button
                    v-if="addMode && editMode"
                    class="primary sm add-btn"
                    @click.stop="incrementUpdateKey()"
                    ><i
                      class="icon-plus-circle-regular"
                      style="margin-right: 5px"
                    ></i
                    >Add Recommended products</hlx-button
                  >
                </span>
              </section>
            </div>

            <div v-show="isDependent" class="upcv2-overview">
              <dependentProductsComponent
                :time-line-data="dependentProductsData"
                :category-prevalue="dependentProductPrevalue"
                :category-data="dependentProductCategory"
                @selected-header="relatedProductsHeader"
                @add-dependent-products="addDependentProducts"
                @deleted-dependent-product="deleteDependentProduct"
                @edited-dependent-products="editedDependentProduct"
              ></dependentProductsComponent>
            </div>
          </div>

          <!-- Supplier -->
          <div v-if="leftpannel == 'Suppliers'">
            <div>
              <!-- Feature  section -->
              <div class="upcv2-overview">
                <!-- Toolbar -->
                <section class="right-view-body-feature" style="overflow: auto">
                  <span class="spec-feature --table-tool-bar">
                    <p class="--upcv2-body-title">Suppliers</p>
                    <!-- search -->
                    <!--   v-if="
                        supplierData?.length > 0 ||
                        (search == true && searchData.length > 0)
                      " -->

                    <div class="--table-tool-bar-wrapper">
                      <span
                        v-if="supplierData?.length > 0"
                        style="display: flex; align-items: center"
                      >
                        <span class="--upcv2-body-title"
                          >Default supplier:</span
                        >
                        <span style="width: 150px">
                          <hlx-select
                            :pre-value="prevalueSupplier"
                            :options="default_supplier_option"
                            :inline-search="true"
                            :border="false"
                            :placeholder-value="'Default supplier'"
                            :label-animation="false"
                            label="customName"
                            :prop-value="'customName'"
                            :clearable="false"
                            @selected-value="defaultSupplierSelect"
                          >
                          </hlx-select>
                        </span>
                      </span>
                      <span
                        v-if="supplierOriginalDataRemoveProductOwner.length > 0"
                        class="util-items search"
                        ><hlx-search
                          @search-key="searchKeySupplier"
                        ></hlx-search
                      ></span>

                      <!-- Filter -->
                      <!-- <span class="schema-header-utils">
                        <hlx-button
                          class="secondary sm add-btn"
                          @click="showDrawerRight()"
                          ><i class="icon-filter-regular"></i
                        ></hlx-button>
                      </span> -->
                      <span
                        v-show="payloadSpec?.offerType != 'Bundle'"
                        class="util-items util-items-add"
                        ><hlx-button
                          v-if="
                            supplierOriginalDataRemoveProductOwner.length > 0 &&
                            addMode &&
                            editMode
                          "
                          class="primary sm add-btn"
                          @click.stop="handleFeaturePanelOpenClose"
                          ><i
                            class="icon-plus-circle-regular"
                            style="margin-right: 5px"
                          ></i
                          >Add supplier</hlx-button
                        >
                      </span>
                    </div>
                  </span>
                  <!-- (search == true && searchData.length > 0) -->
                  <section
                    v-if="supplierData?.length > 0"
                    class="feature-table"
                    style="overflow-y: auto; max-height: 76vh; min-height: 76vh"
                  >
                    <hlx-table
                      :column-count="spec_char_thead.length + 2"
                      :border="['table', 'header', 'horizontal']"
                      :bold-headers="false"
                      :row-hover="true"
                      theme="grey"
                      :striped-rows="false"
                      :re-arrange-columns="re_arrange"
                      :re-arrange-heads="spec_char_thead"
                      @close-rearrange="arrange"
                      @apply-rearranged-columns="applyColumns"
                    >
                      <template #thead>
                        <hlx-table-head
                          :width="60"
                          :align="'left'"
                          style="
                            padding: 0 15px !important;
                            max-width: 60px !important;
                          "
                          :fixed="'left'"
                          >{{ "S.no" }}</hlx-table-head
                        >
                        <hlx-table-head
                          v-for="(i, index) in filteredSupplierhead"
                          :key="index"
                          :prop="i.prop"
                          :sortable="i.sortable"
                          :resizable="i.resizable"
                          :width="i.width"
                          @sorting_func="sorting_Data"
                          @resizewidth="resizewidthdata"
                        >
                          <span
                            v-if="
                              [
                                'price',
                                'pricePerUnit',
                                'cost',
                                'msrp',
                              ].includes(i.prop)
                            "
                          >
                            {{ i.label }} ({{
                              $store?.getters?.getDefaultSettings?.currency
                                ?.unit
                            }})
                          </span>
                          <span v-else>
                            {{ i.label }}
                          </span>
                        </hlx-table-head>
                        <hlx-table-head
                          v-if="payloadSpec?.offerType != 'Bundle'"
                          :width="60"
                          :fixed="'right'"
                          style="max-width: 60px !important"
                          ><span class="action-gear"
                            ><i class="icon-settings-regular"></i></span
                        ></hlx-table-head>
                      </template>
                      <template #tbody>
                        <tr
                          v-for="(i, index) in paginatedData"
                          id=""
                          :key="index"
                        >
                          <hlx-table-cell
                            :align="'center'"
                            style="padding: 0 15px"
                            :fixed="'left'"
                            >{{ serialNumber(index) }}</hlx-table-cell
                          >

                          <hlx-table-cell
                            v-for="(j, col_index) in filteredSupplierhead"
                            :key="col_index"
                            :align="
                              j.prop?.toLowerCase() === 'updatedat'
                                ? 'left'
                                : ''
                            "
                          >
                            <!-- Price for Intangibles in supplier -->
                            <div v-if="j.prop === 'price'">
                              {{
                                i?.tiers
                                  ? currencyFormatter(i.tiers[0].price?.value, {
                                      currency:
                                        $store?.getters?.getDefaultSettings
                                          ?.currency?.unit,
                                    })
                                  : "-"
                              }}
                            </div>
                            <div
                              v-else-if="j.prop === 'relatedParty'"
                              style="display: flex"
                            >
                              <section>
                                <hlx-file-upload
                                  :key="componentkey"
                                  :file-type="'.jpg,.jpeg,.png'"
                                  :file-size-limit="'20mb'"
                                  :uploadtype="'Circle'"
                                  :style-background="
                                    backgrounStyleuploadSupplier
                                  "
                                  :height="30"
                                  :width="30"
                                  :read-only="true"
                                  :custom-icon="icon"
                                  :type="'profile-upload'"
                                  :profile-icon="'icon-upload-regular'"
                                  :content="'Import'"
                                  :name-display="i.customName || i.name"
                                  :profile-pic-preview="i?.src"
                                />
                              </section>
                              <p
                                class="bg-default border"
                                :data-tooltip="i['description']"
                                data-tooltip-location="top"
                                style="
                                  align-items: center;
                                  display: flex;
                                  margin: 0 6px;
                                "
                              >
                                <!-- {{"Nmae"}}{{i[j.prop]}} -->
                                {{
                                  convertTextTo(
                                    i.customName || i.name,
                                    "pascalcaseWithSpace"
                                  )
                                }}
                              </p>
                              <!-- <span
                                v-if="i.isDefault"
                                style="align-items: center; display: flex"
                                ><i
                                  class="icon-check-circle-filled"
                                  style="color: #54bd95; font-size: 14px"
                                ></i>
                              </span> -->
                            </div>
                            <!-- LeadTime -->
                            <div
                              v-else-if="j.prop === 'leadtime'"
                              style="
                                display: flex;
                                flex-direction: column;
                                gap: 12px;
                              "
                            >
                              {{ i[j?.prop] || "-" }}
                            </div>
                            <!-- Stock quantity -->
                            <span v-else-if="j.label == 'Stock quantity'">
                              {{ i?.productStockLevel?.unit || "N/A" }}
                            </span>
                            <!-- SKU -->
                            <div
                              v-else-if="j.prop === 'stockInfo'"
                              style="
                                display: flex;
                                flex-direction: column;
                                gap: 12px;
                              "
                            >
                              <span v-if="j.label == 'SKU'">
                                {{ i.sku }}
                              </span>

                              <span v-else-if="j.label == 'Quantity'">
                                {{ i[j.prop].unit || "-" }}
                              </span>
                              <span v-else>{{ i[j.prop] + "As" }}</span>
                            </div>

                            <!-- Cost -->
                            <span v-if="j.label == 'Cost'">
                              <span v-if="i?.supplierCost || i?.cost">
                                {{
                                  i.supplierCost
                                    ? currencyFormatter(i?.supplierCost, {
                                        currency:
                                          $store?.getters?.getDefaultSettings
                                            ?.currency?.unit,
                                      })
                                    : currencyFormatter(i?.cost, {
                                        currency:
                                          $store?.getters?.getDefaultSettings
                                            ?.currency?.unit,
                                      }) || "-"
                                }}
                              </span>
                              <span v-else>
                                {{ "-" }}
                              </span>
                            </span>
                            <!-- stock level is mentioned above -->
                            <!-- <div
                              v-else-if="j.prop === 'productStockLevel'"
                              style="
                                display: flex;
                                flex-direction: column;
                                gap: 12px;
                              "
                            >
                              {{ i[j.prop]?.description || "-" }}
                            </div> -->
                            <div v-else-if="j.prop === 'place'">
                              {{ i[j?.prop]?.description || "-" }}
                            </div>
                            <!-- MSRP -->
                            <div
                              v-else-if="j.prop === 'msrp'"
                              style="
                                display: flex;
                                flex-direction: column;
                                gap: 12px;
                              "
                            >
                              <p
                                :key="item"
                                class="bg-default border"
                                :data-tooltip="i['description']"
                                data-tooltip-location="top"
                              >
                                {{
                                  i && i.msrp && i.msrp.value
                                    ? currencyFormatter(i.msrp.value, {
                                        currency:
                                          $store?.getters?.getDefaultSettings
                                            ?.currency?.unit,
                                      })
                                    : "-"
                                }}
                              </p>
                            </div>

                            <!-- <div v-else>
                              {{ convertTextTo(i[j?.prop], "pascalcase") }}
                            </div> -->
                          </hlx-table-cell>

                          <hlx-table-cell
                            v-show="editMode === true"
                            v-if="payloadSpec?.offerType != 'Bundle'"
                            :align="'center'"
                            :width="120"
                            :fixed="'right'"
                          >
                            <span
                              :id="'table-context' + serialNumber(index - 1)"
                              class="action-icon"
                            >
                              <i
                                style="position: relative"
                                class="icon-more-vertical-filled"
                                :class="
                                  i.context === true ? 'active-action' : ''
                                "
                                @click="
                                  supplierContextMenu(
                                    $event,
                                    serialNumber(index - 1)
                                  )
                                "
                              >
                                <!-- Line 579 changed by varsan from crudContextMenu to contextMenu -->
                                <hlx-context-menu
                                  :top="containertop"
                                  :left="containerleft"
                                  :conditionvalue="100"
                                  :conditiondown="2"
                                  :conditionup="-18"
                                  :options="SupplierTableAction(i)"
                                  :data="index.toString()"
                                  :show="i.context == true"
                                  @chosen="
                                    closeSupplierCrudContextMenu(
                                      $event,
                                      i,
                                      index
                                    )
                                  "
                                />
                              </i>
                            </span>
                          </hlx-table-cell>
                        </tr>
                      </template>
                    </hlx-table>
                  </section>
                  <section
                    v-if="
                      supplierData?.length === 0 && searchValForSupplier !== ''
                    "
                    class="feature-table"
                    style="overflow-y: auto; max-height: 76vh; min-height: 76vh"
                  >
                    <hlx-table
                      :column-count="spec_char_thead.length + 2"
                      :border="['table', 'header', 'horizontal']"
                      :bold-headers="false"
                      :row-hover="true"
                      theme="grey"
                      :striped-rows="false"
                      :re-arrange-columns="re_arrange"
                      :re-arrange-heads="spec_char_thead"
                      @close-rearrange="arrange"
                      @apply-rearranged-columns="applyColumns"
                    >
                      <template #thead>
                        <hlx-table-head
                          :width="60"
                          :align="'left'"
                          style="
                            padding: 0 15px !important;
                            max-width: 60px !important;
                          "
                          :fixed="'left'"
                          >{{ "S.no" }}</hlx-table-head
                        >
                        <hlx-table-head
                          v-for="(i, index) in filteredSupplierhead"
                          :key="index"
                          :prop="i.prop"
                          :sortable="i.sortable"
                          :resizable="i.resizable"
                          :width="i.width"
                          @sorting_func="sorting_Data"
                          @resizewidth="resizewidthdata"
                        >
                          <span
                            v-if="
                              [
                                'price',
                                'pricePerUnit',
                                'cost',
                                'msrp',
                              ].includes(i.prop)
                            "
                          >
                            {{ i.label }} ({{
                              $store?.getters?.getDefaultSettings?.currency
                                ?.unit
                            }})
                          </span>
                          <span v-else>
                            {{ i.label }}
                          </span>
                        </hlx-table-head>
                        <hlx-table-head
                          v-if="payloadSpec?.offerType != 'Bundle'"
                          :width="60"
                          :fixed="'right'"
                          style="max-width: 60px !important"
                          ><span class="action-gear"
                            ><i class="icon-settings-regular"></i></span
                        ></hlx-table-head>
                      </template>
                      <template #tbody>
                        <tr>
                          <hlx-table-cell></hlx-table-cell>
                        </tr>
                        <tr>
                          <hlx-table-cell></hlx-table-cell>
                        </tr>
                        <tr>
                          <hlx-table-cell></hlx-table-cell>
                        </tr>
                        <tr>
                          <hlx-table-cell></hlx-table-cell>
                        </tr>
                        <tr>
                          <hlx-table-cell
                            :colspan="spec_char_thead.length + 2"
                            >{{ "No data" }}</hlx-table-cell
                          >
                        </tr>
                        <tr>
                          <hlx-table-cell></hlx-table-cell>
                        </tr>
                        <tr>
                          <hlx-table-cell></hlx-table-cell>
                        </tr>
                        <tr>
                          <hlx-table-cell></hlx-table-cell>
                        </tr>
                        <tr>
                          <hlx-table-cell></hlx-table-cell>
                        </tr>
                        <tr>
                          <hlx-table-cell></hlx-table-cell>
                        </tr>
                      </template>
                    </hlx-table>
                  </section>
                </section>
              </div>
            </div>
            <div
              v-if="supplierData?.length > 0"
              class="pagination-container"
              style="padding: 1rem 0"
            >
              <hlx-pagination
                v-model:rows-per-page="externalRowsPerPage"
                :total="supplierData?.length"
                enable-rows-per-page
                :rows-per-page-list="[10, 15, 20]"
                :options="{
                  attributes: {
                    rowsPerPageTitle: true,
                    rowsPerPageResult: true,
                    rowsPerPageDropdown: {
                      position: {
                        top: 0,
                        right: 1,
                      },
                    },
                  },
                }"
                @current-page="currentPage"
                @updated:rows-per-page="changeRowsPerPage"
              ></hlx-pagination>
            </div>

            <div
              v-if="supplierData?.length == 0 && searchValForSupplier == ''"
              class="svg-continer"
              style="
                margin-top: 3.2rem;
                display: flex;
                flex-direction: column;
                gap: 1.2rem;
                align-items: center;
              "
            >
              <img
                class="file-icon"
                src="@/assets/images/svg/supplier.svg"
                alt=""
              />

              <span class="util-items util-items-add"
                ><hlx-button
                  v-if="addMode && editMode"
                  class="primary sm add-btn"
                  @click.stop="handleSupplierFeaturePanelOpenClose"
                  ><i
                    class="icon-plus-circle-regular"
                    style="margin-right: 5px"
                  ></i
                  >Add supplier</hlx-button
                >
                <!-- <hlx-context-menu
                  style="z-index: 10000"
                  :options="add_context_options"
                  :show="show_add_menu"
                  :style="{ marginTop: '5px', marginRight: '20px' }"
                  @chosen="fromAddContextMenu"
                ></hlx-context-menu> -->
              </span>
            </div>
          </div>

          <!-- Attachment -->
          <div v-if="leftpannel == 'Documents'">
            <attachmentComponent
              :supplier-status="ViewMode"
              :user-details="userDetails"
              :data="attachmentData"
              module="PS"
              @upload-data="receivedUploadData"
              @delete-index="deleteAttachmentRow"
            ></attachmentComponent>
          </div>

          <!-- media section -->
          <div v-if="leftpannel == 'Media'">
            <!-- {{ mediaThead }} -->
            <mediaComponent
              :type="'tangible'"
              :offer-type="offertypeMedia"
              :switchdata="offerPriceTypes"
              :table-header="mediaThead"
              :edit-mode="editMode"
              :basetype="basetypeover"
              :table-data="mediaData"
              :drophead="drophead1"
              :dropvalue="dropvalue1"
              @emithead="emithead1"
              @emitvalue="emitvalue1"
              @upload-data="mediaUpload"
              @delete-image="deleteImage"
              @closedata="closedata1"
              @imagedelete="imagedelete1"
            >
            </mediaComponent>
          </div>

          <!-- additional charges section -->
          <div v-if="leftpannel == 'Additional charges'">
            <additionalChargesComponent
              :show-context-menu='false'
              :additional-charge-pagination-width="'84%'"
              :editable="false"
              :table-name="230"
              :table-charges="180"
              :table-taxable="160"
              :additional-charges-data="additionalChargeData1"
              @edit-button="editAdditionalCharge"
              @delete-button="deleteAdditionalCharge"
            ></additionalChargesComponent>
          </div>

          <!-- notes section -->
          <div v-if="leftpannel == 'Notes'" class="">
            <noteComponent
              :supplier-status="ViewMode"
              :data="notesTable"
              @added-note="addNote"
              @delete-index="deleteNote"
              @edit-note="editNote"
            >
            </noteComponent>
          </div>

          <!-- orders -->
          <div v-if="leftpannel == 'Orderss'">
            <div class="upcv2-overview">
              <!-- Toolbar -->
              <section class="right-view-body-feature">
                <span class="spec-feature --table-tool-bar">
                  <p class="--upcv2-body-title">Related orders</p>
                  <div
                    v-if="offerTable?.length > 0"
                    class="--table-tool-bar-wrapper"
                  >
                    <span class="util-items search"
                      ><hlx-search
                        v-model="searchvalue"
                        @search-key="searchKey"
                      ></hlx-search
                    ></span>

                    <!-- Filter -->
                    <span class="schema-header-utils">
                      <hlx-button
                        class="secondary sm add-btn"
                        @click="showDrawerRight()"
                        ><i class="icon-filter-regular"></i
                      ></hlx-button>
                      <hlx-drawer
                        :show="show_right_filter"
                        :width="300"
                        :show-close-icon="true"
                        position="right"
                        :header="false"
                        :footer="false"
                        @close="closeRightFilter"
                      >
                        <template #body>
                          <div style="display: flex">
                            <!-- <div class="cross_filter" style="background-color: brown;">
      <i
                        class="icon-times-filled filter-hidden-time"
                        style="margin-right: 5px"
                        @click="closeNav()"
                      ></i
                      >
    </div> -->

                            <FilterComponent
                              v-if="view == 'Product offerings'"
                              :filterpanelname="filterPanelName"
                              @filtered-data="productOfferingFilter"
                            ></FilterComponent>
                          </div>
                        </template>
                      </hlx-drawer>
                    </span>
                  </div>
                </span>
              </section>

              <section
                style="display: flex; justify-content: center; margin-top: 6%"
              >
                <img
                  class="file-icon"
                  src="@/assets/images/svg/offer.svg"
                  alt=""
                />
              </section>
            </div>
          </div>
          <!-- Order tableData -->
          <div v-else-if="leftpannel == 'Orders'" class="table-wrap">
            <!-- v-if="noRecordFound === false" -->
            <!-- {{ isNoOrderFound }} -->
            <div
              v-if="!isNoOrderFound"
              class="header-utils-and-switch-container"
              style="width: 100%; display: flex; align-items: center"
            >
              <p class="--upcv2-body-title">Orders</p>
              <span
                class="table-header-utils"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  gap: 1rem;
                "
              >
                <span class="util-items --search"
                  ><hlx-search
                    v-model="orderSearchValue"
                    @search-key="searchKey"
                  ></hlx-search
                ></span>

                <span class="filter-table">
                  <!-- @click="showDrawerRight()" -->
                  <hlx-button class="secondary sm add-btn filterbutton"
                    ><i class="icon-filter-regular"></i>
                    <span v-if="filtertaglength != 0" class="filter-count">{{
                      filtertaglength
                    }}</span>
                  </hlx-button>
                  <hlx-drawer
                    :show="show_right_filter"
                    :width="300"
                    position="right"
                    :header="false"
                    :footer="false"
                    :show-close-icon="true"
                    @close="closeRightFilter"
                  >
                    <template #body>
                      <div style="display: flex">
                        <FilterComponent
                          :filterpanelname="'productSpecification'"
                          @filtered-data="productSpecificationFilter"
                        ></FilterComponent>
                      </div>
                    </template>
                  </hlx-drawer>
                </span>

                <!-- Order creation button -->
                <span
                  v-if="isNoOrderFound === false"
                  class="util-items util-items-add"
                  ><hlx-button
                    v-if="addMode && editMode"
                    class="primary sm add-btn"
                    style="display: flex; align-items: center; gap: 0.5rem"
                    ><i
                      class="icon-plus-circle-regular"
                      style="margin-right: 5px"
                    ></i
                    >Create order</hlx-button
                  >
                  <!-- @click.stop="createNewOrder" -->
                  <hlx-context-menu
                    style="z-index: 10000"
                    :options="add_context_options"
                    :show="show_add_menu"
                    :style="{ marginTop: '5px', marginRight: '20px' }"
                    @chosen="fromAddContextMenu"
                  ></hlx-context-menu>
                </span>
              </span>
            </div>
            <!-- Heading -->
            <order-table-component
              v-if="isNoOrderFound === false"
              :search-key="orderSearchValue"
              module="offer"
              :query="{
                'productOrderItem.product.productOffering.id': $route.params.id,
              }"
              @on-request="handleRequestStatus"
            ></order-table-component>

            <!-- order no data  illustration -->
            <div
              v-if="isNoOrderFound === true"
              class="new-layout-body"
              style="
                display: flex !important;
                flex-direction: column;
                align-items: center !important;
                justify-content: center;
                padding: 2%;
              "
            >
              <section style="width: 35vw">
                <img
                  style="width: 100%"
                  src="@/assets/images/svg/EmptyQuoteTableIllustration.svg"
                />
              </section>

              <span class="util-items util-items-add" style="margin-top: 2%"
                ><hlx-button
                  v-if="addMode && editMode"
                  class="primary sm add-btn"
                  style="display: flex; align-items: center; gap: 0.5rem"
                  ><i
                    class="icon-plus-circle-regular"
                    style="margin-top: 5px"
                  ></i
                  >Create order</hlx-button
                >
                <!-- @click.stop="createNewOrder" -->
                <!-- <hlx-context-menu
              style="z-index: 10000"
              :options="add_context_options"
              :show="show_add_menu"
              :style="{ marginTop: '5px', marginRight: '20px' }"
              @chosen="fromAddContextMenu"
            ></hlx-context-menu> -->
              </span>
            </div>
          </div>
          <!-- activity log -->
          <div v-if="leftpannel == 'Activity log'">
            <div>
              <activityComponent
                :activity-data="logData"
                :totalfromprop="activitytotal"
                @activityrowpage="activityrowpage1"
                @activitycurrentpage="activitycurrentpage1"
              />
            </div>
          </div>

          <!-- pricing section -->

          <div v-if="leftpannel == 'Pricing'">
            <div
              v-if="payloadSpec?.offerType == 'Atomic'"
              class="upcv2-overview"
            >
              <!-- Toolbar -->
              <section class="right-view-body-feature">
                <span class="spec-feature --table-tool-bar">
                  <p class="--upcv2-body-title">Pricing</p>

                  <div
                    v-if="pricingData?.length > 0 || searchPricing != ''"
                    class="--table-tool-bar-wrapper"
                  >
                    <span class="util-items search"
                      ><hlx-search
                        v-model="searchvalue"
                        @search-key="pricingSearchTrigger"
                      ></hlx-search
                    ></span>
                    <!-- Filter -->

                    <!-- <span class="schema-header-utils">
                      <hlx-button
                        class="secondary sm add-btn"
                        @click="showDrawerRight()"
                        ><i class="icon-filter-regular"></i
                      ></hlx-button>
                      <hlx-drawer
                        :show="show_right_filter"
                        :width="300"
                        :show-close-icon="true"
                        position="right"
                        :header="false"
                        :footer="false"
                        @close="closeRightFilter"
                      >
                        <template #body>
                          <div style="display: flex"></div>
                        </template>
                      </hlx-drawer>
                    </span> -->
                    <!-- Filter -->
                    <!-- v-if="
                        ['Recurring', 'Pay per use'].includes(selectedPrice)
                      " -->
                    <!-- <span class="schema-header-utils">
                      <hlx-button
                        class="primary sm add-btn"
                        @click="addPricingRow"
                        ><i class="icon-plus-circle-regular"></i>&nbsp;Add
                        pricing</hlx-button
                      >
                    </span> -->
                  </div>
                </span>
                <section
                  v-if="pricingData.length > 0"
                  class="feature-table"
                  style="overflow-y: auto; max-height: 72vh; min-height: 72vh"
                >
                  <div
                    style="
                      margin-bottom: 2%;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <hlx-switch
                      v-model="selectedPrice"
                      :switch-items="offerPriceTypes"
                      value-prop="name"
                      display-prop="name"
                    />
                    <div
                      style="
                        display: grid;
                        align-items: center;
                        justify-items: center;
                        grid-template-columns: max-content 150px;
                      "
                    >
                      <p class="--upcv2-body-title">Unit of measure :</p>

                      <!-- {{this.payloadSpec.unitOfMeasure = "Per billing period"}} -->
                      <!-- <p
                        v-if="['Tangible'].includes(categoryInfo?.type) && payloadSpec.unitOfMeasure == ''"
                        style="color: rgba(0, 101, 178, 1)"
                      >
                      Each
                      </p>

                      <p v-else style="color: rgba(0, 101, 178, 1)">
                        Per billing period                      </p> -->

                      <hlx-select
                        v-model="payloadSpec.unitOfMeasure"
                        :pre-value="payloadSpec.unitOfMeasure"
                        :options="dropdown_data"
                        :inline-search="true"
                        :border="false"
                        :placeholder-value="'Each'"
                        :label-animation="false"
                        label="name"
                        :prop-value="'name'"
                        @selected-value="suffixed"
                      >
                      </hlx-select>
                    </div>
                  </div>

                  <hlx-table
                    :column-count="pricingTheads.length + 2"
                    :border="['table', 'header', 'horizontal']"
                    :bold-headers="false"
                    :row-hover="true"
                    theme="grey"
                    :striped-rows="false"
                    :re-arrange-columns="re_arrange"
                    :re-arrange-heads="spec_char_thead"
                    @close-rearrange="arrange"
                    @apply-rearranged-columns="applyColumns"
                  >
                    <template #thead>
                      <hlx-table-head
                        :width="60"
                        :align="'left'"
                        style="
                          padding: 0 15px !important;
                          max-width: 60px !important;
                        "
                        :fixed="'left'"
                        >{{ "S.no" }}</hlx-table-head
                      >
                      <!-- {{computedTheads}} -->

                      <hlx-table-head
                        v-for="(i, index) in computedTheads"
                        :key="index"
                        :prop="i.prop"
                        :sortable="i.sortable"
                        :resizable="i.resizable"
                        :width="i.width"
                        @sorting_func="sorting_Data"
                        @resizewidth="resizewidthdata"
                      >
                        <span v-if="['price', 'pricePerUnit'].includes(i.prop)">
                          {{ i.label }} ({{
                            $store?.getters?.getDefaultSettings?.currency?.unit
                          }})
                        </span>
                        <span v-else>
                          {{ i.label }}
                        </span>
                      </hlx-table-head>

                      <hlx-table-head
                        v-show="editMode === true"
                        :align="'center'"
                        :width="70"
                        :fixed="'right'"
                        ><span class="action-icon">
                          <i class="icon-settings-regular"></i> </span
                      ></hlx-table-head>
                    </template>
                    <template #tbody>
                      <tr
                        v-for="(i, index) in matrixPaginated"
                        id=""
                        :key="index"
                      >
                        <hlx-table-cell
                          :align="'center'"
                          style="padding: 0 15px"
                          :fixed="'left'"
                          >{{ serialNumber(index) }}</hlx-table-cell
                        >
                        <hlx-table-cell
                          v-for="(j, col_index) in computedTheads"
                          :key="col_index"
                          :align="
                            j.prop?.toLowerCase() === 'updatedat' ? 'left' : ''
                          "
                        >
                          <div
                            v-if="j.prop === 'min'"
                            style="
                              display: flex;
                              flex-direction: column;
                              gap: 12px;
                            "
                          >
                            <p v-for="item in i.tiers" :key="item">
                              {{ item.min }}
                            </p>
                          </div>
                          <div
                            v-else-if="j.prop === 'max'"
                            style="
                              display: flex;
                              flex-direction: column;
                              gap: 12px;
                            "
                          >
                            <p
                              v-for="item in i.tiers"
                              :key="item"
                              class="bg-default border"
                              :data-tooltip="i['description']"
                              data-tooltip-location="top"
                            >
                              {{ item.max }}
                            </p>
                          </div>
                          <div
                            v-else-if="j.prop === 'price'"
                            style="
                              display: flex;
                              flex-direction: column;
                              gap: 12px;
                            "
                          >
                            <!-- v-for="item in i.tiers" -->
                            <p
                              v-for="item in i.tiers"
                              :key="item"
                              class="bg-default border"
                              :data-tooltip="i['description']"
                              data-tooltip-location="top"
                            >
                              {{
                                currencyFormatter(item?.price?.value, {
                                  currency:
                                    $store?.getters?.getDefaultSettings
                                      ?.currency?.unit,
                                })
                              }}
                              <!-- {{ "£" + item.price.value }} -->
                            </p>
                          </div>

                          <div
                            v-else-if="j.prop === 'pricePerUnit'"
                            style="
                              display: flex;
                              flex-direction: column;
                              gap: 12px;
                            "
                          >
                            <p
                              v-for="item in i?.tiers"
                              :key="item"
                              class="bg-default border"
                              :data-tooltip="i['description']"
                              data-tooltip-location="top"
                            >
                              <!-- {{ "£" + item.price.value }} -->
                              {{
                                currencyFormatter(item?.price?.value, {
                                  currency:
                                    $store?.getters?.getDefaultSettings
                                      ?.currency?.unit,
                                })
                              }}
                              /
                              {{ makeWordsSingular(i?.displayValue) }}
                            </p>
                          </div>

                          <div v-else-if="j.prop === 'commitmentLength'">
                            <!-- <p v-if="i.billingPeriod == 'Biweekly'">
                              {{  i[j.prop] }} Weeks
                            </p>
                            <p v-else-if="i.billingPeriod == 'BiMonthly'">
                              {{ i[j.prop] }} Months
                            </p>
                            <p v-else-if="i.billingPeriod == 'Quarterly'">
                              {{ i[j.prop]}} Months
                            </p>
                            <p v-else-if="i.billingPeriod == 'Annually'">
                              {{ i[j.prop] }} Years
                            </p>
                            <p v-else-if="i.billingPeriod == 'Semi Annually'">
                              {{ i[j.prop] }} Months
                            </p>
                            <p v-else>
                              {{ i[j.prop] }} {{ i["billingPeriod"] + "(s)" }}
                            </p> -->
                            <div v-if="i[j.prop] > 1">
                              {{ i[j.prop] }} {{ i?.displayValue }}
                            </div>
                            <div v-else>
                              {{ i[j.prop] }}
                              {{ makeWordsSingular(i?.displayValue) }}
                            </div>
                          </div>
                          <div v-else-if="j.prop === 'endOfCommitment'">
                            <span v-if="i[j.prop]?.autoPay == true">{{
                              "Yes"
                            }}</span>
                            <span v-else>No</span>
                          </div>
                          <div v-else-if="j.prop == 'lifecycleStatus'">
                            <hlx-status :type="i[j.prop]"></hlx-status>
                          </div>
                          <!-- validFpr pricing -->
                          <div v-else-if="j.prop === 'validFor'">
                            {{ convertdateformat(i[j.prop]?.startDate) }} -
                            {{ convertdateformat(i[j.prop]?.endDate) }}
                          </div>

                          <div v-else>
                            <!-- <span v-if="i[j.prop] == null">-</span>
                            <span v-else>
                              {{
                                i[j?.prop]?.charAt(0).toUpperCase() +
                                i[j?.prop]?.slice(1)
                              }}
                              </span
                            > -->
                            {{ i[j.prop] }}
                          </div>
                        </hlx-table-cell>
                        <hlx-table-cell
                          :align="'center'"
                          :width="120"
                          :fixed="'right'"
                        >
                          <span
                            v-show="editMode === true"
                            :id="'table-context' + index"
                            class="action-icon"
                            style="
                              display: flex;
                              flex-direction: row;
                              align-tracks: center;
                              gap: 10px;
                            "
                          >
                            <!-- <i class="icon-edit-regular"></i> &nbsp;
                      <i class="icon-copy-regular"></i> &nbsp;
                      <i class="icon-trash-regular"></i> -->
                            <!-- <i
                            style="position: relative"
                            class="icon-edit-regular"
                          >
                           </i> -->
                            <!-- <p
                                    class="bg-default border"
                                    data-tooltip="Set as default"
                                    data-tooltip-location="left"
                                  >
                           <i v-if="i?.default==true " class="icon-badge-check-regular"                              
                            style="color: #54bd95; background: var(--hlx-color-primary-lighter);"
                            @click="pricingDefaultToggle(i)"
                           ></i> 
                           <i v-else class="icon-badge-check-regular"                              
                            @click="pricingDefaultToggle(i)"
                           ></i> 
                          </p> -->
                            <i
                              class="icon-copy-regular"
                              style="color: rgba(84, 189, 149, 1)"
                              @click="addPricingRow(i)"
                            ></i>
                            <i
                              class="icon-edit-regular"
                              style="color: rgba(84, 189, 149, 1)"
                              @click="handlePricingEditToggle(i, index)"
                            ></i>

                            <i
                              class="icon-trash-regular"
                              @click="handlePricingDeleteToggle(i)"
                            ></i>
                          </span>
                        </hlx-table-cell>
                      </tr>
                    </template>
                  </hlx-table>
                </section>
                <div
                  v-if="pricingData.length === 0 && searchPricing !== ''"
                  class="cm-accounts-right-panel-top-section-table-wrap"
                >
                  <hlx-table
                    :column-count="pricingTheads.length + 2"
                    :border="['table', 'header']"
                    :bold-headers="false"
                    :row-hover="true"
                    theme="grey"
                    :striped-rows="false"
                    :re-arrange-columns="re_arrange"
                    :re-arrange-heads="spec_char_thead"
                    @close-rearrange="arrange"
                    @apply-rearranged-columns="applyColumns"
                  >
                    <template #thead>
                      <hlx-table-head :width="60" :align="'left'">{{
                        "S.no"
                      }}</hlx-table-head>
                      <hlx-table-head
                        v-for="(i, index) in computedTheads"
                        :key="index"
                        :prop="i.prop"
                        :sortable="i.sortable"
                        :resizable="i.resizable"
                        :width="i.width"
                        @sorting_func="sorting_Data"
                        @resizewidth="resizewidthdata"
                      >
                        <span v-if="['price', 'pricePerUnit'].includes(i.prop)">
                          {{ i.label }} ({{
                            $store?.getters?.getDefaultSettings?.currency?.unit
                          }})
                        </span>
                        <span v-else>
                          {{ i.label }}
                        </span>
                      </hlx-table-head>
                      <hlx-table-head v-show="editMode === true" :width="100">
                        <span class="action-icon"
                          ><i class="icon-settings-regular"></i></span
                      ></hlx-table-head>
                    </template>
                    <template #tbody>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell :colspan="pricingTheads.length + 2">{{
                          "No data"
                        }}</hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                    </template>
                  </hlx-table>
                </div>
                <div
                  v-if="pricingData.length > 0"
                  class="pagination-container"
                  style="padding: 1rem 0"
                >
                  <hlx-pagination
                    v-model:rows-per-page="externalRowsPerPage"
                    :total="computedPaginationTotalPage.length"
                    enable-rows-per-page
                    :rows-per-page-list="[10, 15, 20]"
                    :options="{
                      attributes: {
                        rowsPerPageTitle: true,
                        rowsPerPageResult: true,
                        rowsPerPageDropdown: {
                          position: {
                            top: 0,
                            right: 1,
                          },
                        },
                      },
                    }"
                    @current-page="currentPage"
                    @updated:rows-per-page="changeRowsPerPage"
                  ></hlx-pagination>
                </div>
              </section>

              <section
                v-if="pricingData.length == 0 && searchPricing === 0"
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  gap: 2px;
                  margin-top: 6%;
                "
              >
                <img
                  class="file-icon"
                  src="@/assets/images/svg/pricings.svg"
                  alt=""
                />

                <span class="util-items util-items-add"
                  ><hlx-button
                    class="primary sm add-btn"
                    v-if="addMode && editMode"
                    @click.stop=""
                    ><i
                      class="icon-plus-circle-regular"
                      style="margin-right: 5px"
                    ></i
                    >Add pricing</hlx-button
                  >
                </span>
              </section>
            </div>
            <!-- Bundle pricing -->

            <!-- single pricing bundle -->
            <div
              class="upcv2-overview"
              v-if="payloadSpec.offerType != 'Atomic'"
            >
              <section class="right-view-body-feature">
                <span class="spec-feature --table-tool-bar">
                  <p class="--upcv2-body-title">Pricing</p>
                </span>
                <section
                  v-if="pricingData.length > 0"
                  class="feature-table"
                  style="overflow-y: auto; max-height: 72vh; min-height: 72vh"
                >
                  <div
                    style="
                      margin-bottom: 2%;
                      display: flex;
                      justify-content: space-between;
                    "
                  ></div>

                  <hlx-accordion-table
                    :payload-spec="payloadSpecData"
                    :table-data="pricingData"
                    :table-header="bundlePricingTheads"
                    :componentkey="componentkey"
                    @payload-spec1="payloadSpec1"
                    @add-pricing="bundulePricingAdd"
                    @delete-pricing="bundulePricingDeleted"
                    @quantity-log="bundulePricingquantity"
                    @discount-log="bundulePricingdiscountLog"
                    @Start-date-log="bundulePricingstartDate"
                    @End-date-log="bundulePricingendDate"
                    @discount-type-log="bundulePricingdiscountType"
                    @billing-period-log="bundulePricingbillingPeriodLog"
                    @commitmentlength-log="bundulePricingCommitmentlengthLog"
                  />
                </section>
                <div
                  v-if="pricingData.length === 0 && searchPricing !== ''"
                  class="cm-accounts-right-panel-top-section-table-wrap"
                >
                  <hlx-table
                    :column-count="bundlePricingTheads.length + 2"
                    :border="['table', 'header']"
                    :bold-headers="false"
                    :row-hover="true"
                    theme="grey"
                    :striped-rows="false"
                    :re-arrange-columns="re_arrange"
                    :re-arrange-heads="spec_char_thead"
                    @close-rearrange="arrange"
                    @apply-rearranged-columns="applyColumns"
                  >
                    <template #thead>
                      <hlx-table-head :width="60" :align="'left'">{{
                        "S.no"
                      }}</hlx-table-head>
                      <hlx-table-head
                        v-for="(i, index) in bundlePricingTheads"
                        :key="index"
                        :prop="i.prop"
                        :sortable="i.sortable"
                        :resizable="i.resizable"
                        :width="i.width"
                        @sorting_func="sorting_Data"
                        @resizewidth="resizewidthdata"
                      >
                        <span
                          v-if="
                            ['price', 'pricePerUnit', 'tiers'].includes(i.prop)
                          "
                        >
                          {{ i.label }} ({{
                            $store?.getters?.getDefaultSettings?.currency?.unit
                          }})
                        </span>
                        <span v-else>
                          {{ i.label }}
                        </span>
                      </hlx-table-head>
                      <hlx-table-head v-show="editMode === true" :width="100">
                        <span class="action-icon"
                          ><i class="icon-settings-regular"></i></span
                      ></hlx-table-head>
                    </template>
                    <template #tbody>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell :colspan="pricingTheads.length + 2">{{
                          "No data"
                        }}</hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                    </template>
                  </hlx-table>
                </div>
              </section>

              <section
                v-if="pricingData.length == 0 && searchPricing === 0"
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  gap: 2px;
                  margin-top: 6%;
                "
              >
                <img
                  class="file-icon"
                  src="@/assets/images/svg/pricings.svg"
                  alt=""
                />
              </section>
            </div>
          </div>

          <!-- panel for products -->
          <div class="add-contact-drawer">
            <hlx-drawer
              :show="relatedProductPanel"
              position="right"
              :show-close-icon="true"
              width="400"
              :footer="true"
              :title="'Edit notes'"
              @close="relatedProductPanel = !relatedProductPanel"
            >
              <template #body>
                <div class="header-area-contact-drawer">
                  <b>Add Recommended products</b>
                </div>
                <div class="body-area-contact-drawer">
                  <!-- <hlx-multi-select
                    :key="componentkey"
                    :options="productOffer"
                    :placeholder="'Search product list'"
                    :label-animation="true"
                    :prop-value="'name'"
                    :label="'name'"
                    :disabled="false"
                    @selected-array="handleProduct"
                    :tooltipposition="'left'"
                  ></hlx-multi-select> -->
                  <!-- :change-filter-count="payloadSpec.relatedProduct" -->
                  <searchfilterComponent
                    :key="updateSearchAndFilterKey"
                    :module="'productoffering'"
                    :filter-search-options="mainInfo"
                    :filter-data="searchFilterData"
                    :filter-checked-data="layout"
                    :key-value-from-query-array="'label'"
                    :filter-value-object-name="'name'"
                    @searched-value="getSearchedValue"
                    @checked-value="getcheckedValue"
                    @removed-value="getRemovedValue"
                  >
                  </searchfilterComponent>
                </div>
              </template>
              <template #footer>
                <div
                  style="
                    display: flex;
                    justify-content: flex-end;
                    padding: 19px;
                    border-top: 1px solid #d8d8d8;
                  "
                >
                  <hlx-button
                    class="secondary sm"
                    @click="relatedProductPanel = !relatedProductPanel"
                    >Cancel</hlx-button
                  >
                  <hlx-button
                    v-if="editMode == false"
                    class="primary sm"
                    style="margin-left: 10px"
                    @click.self="addproducts"
                    >Add</hlx-button
                  >
                </div>
              </template>
            </hlx-drawer>
          </div>

          <!-- panel for supplier -->
          <div class="add-contact-drawer">
            <hlx-drawer
              :show="isFeaturePanelEnabled"
              position="right"
              :show-close-icon="true"
              :width="400"
              :footer="true"
              @close="closeSupplierModal"
            >
              <template #body>
                <div
                  v-show="payloadSpec?.offerType != 'Bundle'"
                  class="header-area-contact-drawer"
                >
                  <b>Add supplier</b>
                </div>
                <div class="body-area-contact-drawer">
                  <!-- <h1>Supplier de</h1> -->
                  <!-- v-model:value="searchSupplier" -->
                  <hlx-multi-select
                    :key="componentkey"
                    :options="supplier"
                    :placeholder="'Search supplier list'"
                    :label-animation="true"
                    :prop-value="'name'"
                    :label="'customName'"
                    :disabled="false"
                    :tooltipposition="'left'"
                    @input-value="searchSupplierMethod"
                    @add-data="listsupplier"
                    @selected-array="handleSupplier"
                  ></hlx-multi-select>

                  <!-- search + filter
                    @typeValue=getSupplierName -->

                  <!-- :filter-search-options="dropdownData" -->
                </div>
              </template>
              <template #footer>
                <div
                  style="
                    display: flex;
                    justify-content: flex-end;
                    padding: 19px;
                    border-top: 1px solid #d8d8d8;
                  "
                >
                  <hlx-button
                    class="secondary sm"
                    @click="isFeaturePanelEnabled = !isFeaturePanelEnabled"
                    >Cancel</hlx-button
                  >
                  <hlx-button
                    v-if="editMode == false"
                    class="primary sm"
                    :disabled="isSupplierChoosen"
                    style="margin-left: 10px"
                    @click.self="addSupplier"
                    >Add</hlx-button
                  >
                </div>
              </template>
            </hlx-drawer>
          </div>

          <!-- panel for pricing table pricing-panel -->
          <div class="add-contact-drawer">
            <hlx-drawer
              :show="ispricingEdit"
              position="right"
              width="400"
              :show-close-icon="true"
              :footer="true"
              :title="'Edit pricing'"
              @close="ispricingEdit = !ispricingEdit"
            >
              <template #body>
                <div class="header-area-contact-drawer">
                  <!-- <span
                    style="
                      display: flex;
                      width: 25px;
                      height: 25px;
                      position: fixed;
                      right: 400px;
                      background: white;
                      z-index: 1000;
                      top: 0;
                      align-items: center;
                      justify-content: center;
                      border-top-left-radius: 3px;
                      border-bottom-left-radius: 3px;
                      box-shadow: -2px 0px 6px 0px #b9b6b6;
                      cursor: pointer;
                    "
                    @click="ispricingEdit = !ispricingEdit"
                  >
                    <i class="icon-times-regular"></i>
                  </span> -->
                  <b>Edit pricing</b>
                </div>
                <section class="body-area-pricing-drawer">
                  <div
                    v-if="
                      ['Tangible'].includes(categoryInfo?.type) ||
                      (['Intangible'].includes(categoryInfo?.type) &&
                        ['rental', 'recurring', 'pay per use'].includes(
                          selectedPrice?.toLowerCase()
                        ))
                    "
                    class="pricing-sub-container --padded"
                  >
                    <!-- Configurable item block -->
                    <!-- v-if="['Tangible'].includes(categoryInfo?.type)" -->
                    <div class="configurable-items">
                      <p
                        v-for="item in Object.keys(pricingPanelData, index)"
                        :key="item"
                        :style="{
                          display: [
                            'SKU',
                            'tiers',
                            'MSRP',
                            'endOfCommitment',
                            'id',
                            'billingPeriod',
                            'commitmentLength',
                            'attachment',
                            'displayValue',
                            'displayCommitmentLength',
                            'stock',
                            'validFor',
                            'lifecycleStatus',
                            'isDefault',
                            'default',
                          ].includes(item)
                            ? 'none'
                            : 'grid',
                        }"
                        class="item"
                      >
                        <span v-if="item == 'priceType'">Price type</span>
                        <span v-else>{{ item }}</span>
                        <span>{{ pricingPanelData[item] }}</span>
                      </p>
                    </div>
                    <section
                      style="display: flex; flex-direction: column; gap: 0.7%"
                    >
                      <!-- SKU block -->
                      <div
                        v-if="['Tangible'].includes(categoryInfo?.type)"
                        class="sku"
                      >
                        <hlx-input
                          v-model:value="pricingPanelData.SKU"
                          :pre-val="pricingPanelData.SKU"
                          :label-animation="true"
                          label-value="SKU"
                          :clearable="false"
                          :display-error="true"
                          :custom-error="skucheck"
                          :custom-error-message="statusMsg"
                          required
                          @at-input="checkSKU(pricingPanelData.SKU)"
                        />
                      </div>
                      <!-- date for demo to be integrated-->
                      <div>
                        <hlx-input
                          :key="componentkey"
                          v-model:value="pricingPanelData.validFor.startDate"
                          :pre-val="pricingPanelData?.validFor?.startDate"
                          :label-animation="true"
                          :single-border="false"
                          required
                          :label-value="'Start date'"
                          :date-format="'dd (short month), yyyy'"
                          type="date"
                          :clearable="false"
                          icon="calendar-regular"
                          icon-position="right"
                          :disable-weekend="false"
                          :start-year="2000"
                          :display-error="true"
                          :custom-error="startDateBoolean"
                          :custom-error-message="startDateMsg"
                          :end-year="9999"
                          @iso-date="calenderValueStartDate"
                          @at-input="calenderValueStartDate"
                          @focus-out="focusOutStartDate"
                        />
                        <!-- @formattedDate="calenderValueStartDate" -->
                      </div>
                      <div>
                        <hlx-input
                          :key="componentkey"
                          v-model:value="pricingPanelData.validFor.endDate"
                          :pre-val="pricingPanelData?.validFor?.endDate"
                          :label-animation="true"
                          :single-border="false"
                          :label-value="'End date'"
                          :date-format="'dd (short month), yyyy'"
                          required
                          type="date"
                          :clearable="false"
                          icon="calendar-regular"
                          icon-position="right"
                          :disable-weekend="false"
                          :start-year="2000"
                          :display-error="true"
                          :custom-error="endDateBoolean"
                          :custom-error-message="endDateMsg"
                          :end-year="9999"
                          @iso-date="calenderValueEndDate"
                          @at-input="calenderValueEndDate"
                          @focus-out="focusOutEndDate"
                        />
                        <!-- @formattedDate="calenderValueEndDate" -->
                      </div>
                    </section>
                    <!-- <div
                      style="
                        width: 100%;
                        margin-top: -16px;
                        margin-bottom: 10px;
                      "
                    >
                      <hlx-status
                        :type="'select-status'"
                        :status-option="filteredstatus"
                        :prevalue="pricingPanelData.lifecycleStatus"
                        @emit-select="statusEmitter"
                      >
                      </hlx-status>
                    </div> -->

                    <!-- MSRP tangible block -->
                    <!--
                      " -->
                    <div
                      v-if="
                        !(
                          ['Intangible'].includes(categoryInfo?.type) &&
                          ['recurring', 'pay per use'].includes(
                            selectedPrice?.toLowerCase()
                          )
                        )
                      "
                      class="MSRP"
                    >
                      <hlx-input
                        v-model:value="pricingPanelData.MSRP.value"
                        :pre-val="pricingPanelData.MSRP.value"
                        :disabled="false"
                        :label-animation="true"
                        label-value="MSRP"
                        type="mixed"
                        :symbol="
                          $store?.getters?.getDefaultSettings?.currency?.symbol
                        "
                        :clearable="true"
                        name="username"
                        :suffix="true"
                      >
                        <template #suffix>
                          {{
                            $store?.getters?.getDefaultSettings?.currency?.unit
                          }}
                        </template>
                      </hlx-input>
                    </div>
                    <!-- {{['rental', 'recurring', 'pay per use'].includes(selectedPrice?.toLowerCase())}} -->
                    <!-- Billing-period for rental/reccurring -->
                    <div
                      v-if="
                        [
                          'rental',
                          'installment',
                          'recurring',
                          'pay per use',
                        ].includes(selectedPrice?.toLowerCase())
                      "
                      class="billing-period"
                    >
                      <hlx-select
                        v-model:value="pricingPanelData.billingPeriod"
                        :options="billingPeriodOptions"
                        :pre-value="pricingPanelData.billingPeriod"
                        :placeholder-value="'Billing period'"
                        :label="'name'"
                        :prop-value="'name'"
                        :label-animation="true"
                        :inline-search="true"
                        :required="true"
                        :error="show"
                        :custom-error="errorvalue"
                        @custom-error-emit-value="selecttheValueorNot"
                        @selected-value="handlePricingBillingPeriod"
                      ></hlx-select>
                    </div>
                    <!-- Commitment length -->
                    <div
                      v-if="
                        [
                          'rental',
                          'installment',
                          'recurring',
                          'pay per use',
                        ].includes(selectedPrice?.toLowerCase())
                      "
                      class="commitment-length"
                    >
                      <hlx-input
                        v-model:value="pricingPanelData.commitmentLength"
                        :pre-val="pricingPanelData.commitmentLength"
                        :disabled="false"
                        :label-animation="true"
                        label-value="Commitment length"
                        type="mixed-number"
                        :display-error="true"
                        :custom-error="isCommitmentLengthValid"
                        :custom-error-message="commitmentMessage"
                        :min-limit="1"
                        :clearable="true"
                        name="username"
                        :suffix="true"
                        @at-input="focusOutCommitmentLength"
                      >
                        <template #suffix>
                          {{
                            singular(
                              pricingPanelData.displayValue,
                              pricingPanelData.commitmentLength
                            )
                          }}
                          <!-- {{singular(pricingPanelData.displayValue,pricingPanelData.commitmentLength )}} -->
                        </template>
                      </hlx-input>
                    </div>
                    <!-- End of commitment title -->
                    <div
                      v-if="
                        ['Tangible'].includes(categoryInfo?.type) &&
                        [
                          'rental',
                          'installment',
                          'recurring',
                          'pay per use',
                        ].includes(selectedPrice?.toLowerCase())
                      "
                      class="end-of-the-commitment-title"
                    >
                      <p class="title">At the end of commitment</p>
                    </div>
                    <!-- End of commitment -->
                    <div
                      v-if="
                        ['Tangible'].includes(categoryInfo?.type) &&
                        [
                          'rental',
                          'installment',
                          'recurring',
                          'pay per use',
                        ].includes(selectedPrice?.toLowerCase())
                      "
                      class="end-of-the-commitment"
                    >
                      <hlx-input
                        v-model:value="pricingPanelData.endOfCommitment.autoPay"
                        :checked="pricingPanelData?.endOfCommitment?.autoPay"
                        label-value="Auto renew"
                        :type="'single-radio'"
                        :name="'endOfCommitment'"
                      />
                      <hlx-input
                        v-model:value="
                          pricingPanelData.endOfCommitment.expireTerm
                        "
                        :checked="pricingPanelData?.endOfCommitment?.expireTerm"
                        :type="'single-radio'"
                        :name="'endOfCommitment'"
                        label-value="Expire term"
                        icon="credit-card-convert"
                      />
                    </div>
                  </div>

                  <div class="pricing-sub-container --colored-padded">
                    <span class="min-max-price-header">
                      <span class="minimum">Min quantity</span>
                      <span class="maximum">Max quantity</span>
                      <span class="price"
                        >Price({{
                          $store?.getters?.getDefaultSettings?.currency?.unit
                        }})</span
                      >
                    </span>
                  </div>
                  <div class="pricing-sub-container --padded">
                    <div
                      v-for="(tier, tierIndex) in pricingPanelData.tiers"
                      :key="tier"
                      class="min-max-price-value-configurator"
                    >
                      <p class="value-wrapper">
                        <span>
                          <hlx-input
                            v-model:value="tier.min"
                            :pre-val="tier.min"
                            :label-animation="true"
                            :min-limit="0"
                            type="number"
                            label-value="Min"
                            :clearable="false"
                        /></span>
                        <span>
                          <hlx-input
                            v-model:value="tier.max"
                            :pre-val="tier.max"
                            :label-animation="true"
                            type="number"
                            label-value="Max"
                            :clearable="false"
                        /></span>
                        <span>
                          <hlx-input
                            v-model:value="tier.price.value"
                            :pre-val="tier.price.value"
                            :label-animation="true"
                            :min-limit="0"
                            type="number"
                            :symbol="
                              $store?.getters?.getDefaultSettings?.currency
                                ?.symbol
                            "
                            label-value="Price"
                            :clearable="false"
                          />
                        </span>
                      </p>
                      <i
                        v-if="pricingPanelData.tiers.length > 1"
                        class="icon-trash-regular"
                        @click="handleTierDelete(tierIndex)"
                      ></i>
                    </div>
                    <span
                      v-show="showTierError == true"
                      style="
                        width: 100%;
                        height: 24px;
                        color: #fe4141;
                        font-size: 12px;
                        text-align: left;
                        position: relative;
                      "
                      >Invalid tier: min should be less than max.</span
                    >
                    <!-- Tier adder -->
                    <div class="add-tier-button">
                      <p class="tier-button" @click="addTierToPricing">
                        <i class="icon-plus-regular"></i> <span>Add tier</span>
                      </p>
                      <span class="additional-config-buttons">
                        <p
                          v-if="
                            ['Tangible'].includes(categoryInfo?.type) &&
                            [
                              'rental',
                              'installment',
                              'recurring',
                              'pay per use',
                            ].includes(selectedPrice?.toLowerCase())
                          "
                          class="apply-all-button"
                        >
                          <hlx-input
                            v-model:value="applyAllCheck"
                            :checked="applyAllCheck"
                            label-value="Apply for all"
                            :type="'single-checkbox'"
                            @change="applyAll"
                          />
                          <icon class="icon-info-circle-regular"></icon>
                        </p>
                        <p
                          v-if="
                            ['recurring', 'pay per use'].includes(
                              selectedPrice?.toLowerCase()
                            )
                          "
                          style="
                            display: flex;
                            align-items: center;
                            gap: 0.5rem;
                          "
                          class="auto-renew-button"
                        >
                          <hlx-input
                            v-model:value="
                              pricingPanelData.endOfCommitment.autoPay
                            "
                            :checked="
                              pricingPanelData?.endOfCommitment?.autoPay
                            "
                            label-value="Auto renew"
                            :type="'single-checkbox'"
                          />
                          <icon class="icon-info-circle-regular"></icon>
                        </p>
                        <p
                          class="auto-renew-button"
                          style="
                            display: flex;
                            align-items: center;
                            gap: 0.2rem;
                          "
                        >
                          <!-- set as default -->

                          <hlx-input
                            :key="componentkey"
                            v-model:value="pricingPanelData.default"
                            :checked="pricingPanelData.default"
                            label-value="Set as default"
                            :type="'single-checkbox'"
                          />
                          <!-- @change="pricingDefaultToggle" -->
                          <icon class="icon-info-circle-regular"></icon>
                        </p>
                      </span>
                    </div>

                    <!-- Preview -->
                    <div class="pricing-preview">
                      <p class="--title">Preview</p>
                      <div class="preview-container">
                        <p class="column-seperator">
                          <span>Quantity</span
                          ><span style="text-align: center"
                            >Price ({{
                              $store?.getters?.getDefaultSettings?.currency
                                ?.unit
                            }})</span
                          >
                        </p>
                        <p
                          v-for="tier in pricingPanelData.tiers"
                          :key="tier"
                          class="column-seperator"
                        >
                          <span
                            >{{ tier.min }} &nbsp; - &nbsp;{{ tier.max }}</span
                          ><span style="text-align: centre">
                            {{
                              currencyFormatter(tier.price?.value, {
                                currency:
                                  $store?.getters?.getDefaultSettings?.currency
                                    ?.unit,
                              })
                            }}
                            {{
                              [
                                "onetime",
                                "one time",
                                "rental",
                                "installment",
                              ].includes(selectedPrice?.toLowerCase()) == false
                                ? " / " +
                                  " " +
                                  makeWordsSingular(
                                    pricingPanelData.displayValue
                                  )
                                : ""
                            }}
                            <!-- {{
                              [
                                "onetime",
                                "one time",
                                "rental",
                                "installment",
                              ].includes(selectedPrice?.toLowerCase()) == true
                                ? " " +
                                  $store?.getters?.getDefaultSettings?.currency
                                    ?.unit
                                : ""
                            }} -->
                          </span>
                        </p>
                      </div>
                      <span class="--info-icon-label">
                        <icon class="icon-info-circle-regular"> </icon>
                        All prices includes tax
                      </span>
                    </div>
                  </div>
                </section>
              </template>
              <template #footer>
                <div
                  style="
                    display: flex;
                    justify-content: flex-end;
                    padding: 19px;
                    border-top: 1px solid #d8d8d8;
                  "
                >
                  <hlx-button
                    class="secondary sm"
                    @click="ispricingEdit = !ispricingEdit"
                    >Cancel</hlx-button
                  >
                  <hlx-button
                    v-if="editMode == false && disableSave == false"
                    class="primary sm"
                    style="margin-left: 10px"
                    @click.self="addPricing(pricingPanelData.id)"
                    >Save</hlx-button
                  >

                  <hlx-button
                    v-if="editMode == false && disableSave == true"
                    class="primary sm"
                    :disabled="true"
                    style="margin-left: 10px"
                    >Save</hlx-button
                  >
                </div>
              </template>
            </hlx-drawer>
          </div>
        </div>
      </section>
    </main>
  </section>
</template>

<script>
import {
  DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
import bundleListingComponent from "@/components/bundleListingComponent.vue";
import axios from "axios";
// import versionHistoryListingComponent from "@/components/versionHistoryListingComponent.vue"
import discardComponent from "@/components/discardComponent.vue";
// import { DeepDiff } from "deep-diff";
import { v4 as uuidv4 } from "uuid";
import leftpannelComponent from "@/components/leftpannelComponent.vue";
import searchfilterComponent from "@/components/SearchAndFilterComponent/searchAndFilter.vue";
// import FilterComponent from "@/components/filterComponent.vue";
// import discardComponent from "@/components/discardComponent.vue";
import attachmentComponent from "@/components/CM/addAccountAttachmentsComponent.vue";
import noteComponent from "@/components/CM/addAccountNotesComponent.vue";
import activityComponent from "@/components/SM/activityLogComponent.vue";
import discountComponent from "@/components/Product/productDiscountComponent.vue";
import availableRegionsComponent from "@/components/Product/productAvailableRegion.vue";
import mediaComponent from "@/components/Product/mediaTanComponent.vue";
import additionalChargesComponent from "@/components/Product/AdditionalCharges.vue";
import dependentProductsComponent from "@/components/Product/dependentProducts.vue";
// import test from '@/components/TestComponent.vue'
// Order table
import orderTableComponent from "@/components/UPC/Order management/productOrder/orderTableComponent.vue";

import hlxAccordionTable from "@/components/Product/accordianTable.vue";

// import draggable from "vuedraggable";
// import { mapGetters } from "vuex";
export default {
  components: {
    // OverviewComponent ,
    // FilterComponent,
    // discardComponent,
    // draggable,
    hlxAccordionTable,
    bundleListingComponent,
    orderTableComponent,
    dependentProductsComponent,
    activityComponent,
    noteComponent,
    leftpannelComponent,
    attachmentComponent,
    searchfilterComponent,
    discountComponent,
    mediaComponent,
    availableRegionsComponent,
    additionalChargesComponent,
    discardComponent,
    // test
  },
  beforeRouteLeave(to, from, next) {
    if (this.discardIt) {
      //this.discardIt will handled on discard modal window discard changes emit
      next(true);
      return;
    }
    // Check for unsaved changes
    /**
     * Param 1 initial paylaod to check against param 2
     * Param 2 updated payload to check against param 1
     */
    const unsavedChanges = this.isPayloadChanged(
      this.initialData,
      this.updatedData
    ); //This isPayloadChanged method was configured in global methods so we can call it any where on vue.It's find a difference between both data and return true if any changes otherwise return false
    console.log(
      unsavedChanges,
      "unsavedChanges",
      this.initialData.name,
      this.updatedData.name
    );
    // If unsaved changes found then display discard modal
    if (unsavedChanges) {
      this.showOverviewDiscardModal = true;
    }
    // Then route as per validation
    next(!unsavedChanges);
    return;
  },
  data() {
    return {
      tempSideBar: {
        main: [
          {
            id: 1,
            name: "Overview",
            icon: "icon-presentation-regular",
            active: true,
            view: true,
          },

          {
            id: 3,
            name: "Pricing",
            icon: "icon-dollar-regular",
            active: false,
            view: true,
          },
          {
            id: 2,

            name: "Media",
            icon: "icon-money-bill-regular",
            active: false,
            view: true,
          },
          {
            id: 4,
            name: "Discounts",
            icon: "icon-badge-discount-alt-regular",
            active: false,
            view: true,
          },
          {
            id: 5,

            name: "Additional charges",
            icon: "icon-money-bill-regular",
            active: false,
            view: true,
          },
          {
            id: 6,
            name: "Recommended products",
            icon: "icon-inbox-regular",
            active: false,
            view: true,
          },
          {
            id: 7,
            name: "Available regions",
            icon: "icon-location-pin-regular",
            active: false,
            view: true,
          },
          {
            id: 8,
            name: "Suppliers",
            icon: "icon-boxes-regular",
            active: false,
            view: true,
          },
          {
            id: 9,
            name: "Orders",
            icon: "icon-menu-left-square-regular",
            active: false,
            view: true,
          },
          {
            id: 10,
            name: "Documents",
            icon: "icon-paperclip-regular",
            active: false,
            view: true,
          },

          {
            id: 11,
            name: "Notes",
            icon: "icon-menu-left-regular",
            active: false,
            view: true,
          },
          {
            id: 12,
            name: "Activity log",
            icon: "icon-time-forward-regular",
            active: false,
            view: true,
          },
        ],
      },
      permisstionData: {},
      permisstionSidebarData: {},
      oldPriceInfo: {},
      deleteSupplierArrayAfterSpecChange: [],
      fromFeatureChanged: false,
      offSupplierNotification: false,
      prevalueSupplier: "",
      default_supplier_option: [],
      deleteRelatedProductIndex: "",
      deletedRelatedProduct: "",
      deleteRelatedProductObj: "",
      deleteRelatedProductName: "",
      delete_modal_related_product: false,
      searchValForSupplier: "",
      payloadSpecData: {
        description: "",
        unitOfMeasure: "",
        lifecycleStatus: "In study",
        prodSpecCharValueUse: [],
        prodSpecCharValue: [],
        bundledProductOffering: [{ name: "" }],
        productOfferingPrice: [],
        marketSegment: [],
        channel: [],
        productSpecification: { name: "" },
        relatedParty: [{ name: "", role: "Product owner" }],
        status: "",
        name: "",
        tags: [],
        notes: [],
        attachment: [{ media: [] }],
      },
      calculateSum2: null,
      propForBundleListingComponent: "",
      supplierDiscount: [],
      updateBundleComponent: 0,
      originalBundleList: [],
      commitmentLength: 0,
      bundleTotalcheck: {
        id: uuidv4(),
        quantity: 1,
        priceType: "One time",
        totalPrice: {
          unit: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          value: 0,
        },
        tiers: [
          {
            min: 1,
            max: 99,
            price: {
              unit: this.$store?.getters?.getDefaultSettings?.currency?.unit,
              value: 0,
            },
          },
        ],
        type: "Bundle",
        MSRP: {
          unit: this.$store?.getters?.getDefaultSettings?.currency?.symbol,
          value: 0,
        },
        validFor: {
          startDate: new Date().toISOString(),
          endDate: "9999-12-31T00:00:00Z",
        },
        lifecycleStatus: "Active",
        commitmentLength: 1,
        billingPeriod: "Monthly",
        displayValue: "Months",
        endOfCommitment: {
          autoPay: true,
          expireTerm: false,
        },
      },
      showTierError: false,
      currentIndex: 0,
      startDateMsg: "",
      startDateBoolean: false,
      endDatemsg: "",
      endDateBoolean: false,
      discounttotal: 0,
      bundleAlteration: {
        discount: 0,
        discountType: {
          value: "%",
          name: "Percentage",
        },
      },
      bundleDiscountOpts: [
        {
          name: "Percentage",
          value: "%",
        },
        {
          name: "Fixed",
          value: this.$store?.getters?.getDefaultSettings?.currency?.symbol,
        },
      ],

      totalPrice: 0,
      Add_bundle_modal: false,
      bundleList: [],
      offersList: [],
      i: 0,
      handleSupplierImageLoad: false,
      showLeftVal: false,
      searchFilterCategoryData: [],
      selectedSuppliers: [],
      searchValue: "",
      disableSave: false,
      skucheck: false,
      statusMsg: "",
      bundlePricingTheads: [
        {
          sortable: false,
          resizable: false,
          width: 100,
          label: "Id",
          prop: "displayID",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 200,
          label: "Name",
          prop: "name",
          align: "left",
        },

        {
          sortable: false,
          resizable: false,
          width: 100,
          label: "SKU",
          prop: "SKU",
          align: "left",
        },

        {
          sortable: false,
          resizable: false,
          width: 150,
          label: "Unit of measure",
          prop: "unitOfMeasure",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 70,
          label: "Min",
          prop: "tiers",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 70,
          label: "Max",
          prop: "tiers",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 150,
          label: "Price",
          prop: "tiers",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 150,
          label: "Quantity",
          prop: "quantity",
          align: "left",
        },

        {
          sortable: false,
          resizable: false,
          width: 150,
          label: "Total price",
          prop: "totalPrice",
          align: "left",
        },
      ],
      filteredstatus: [],
      statusPricing: [
        {
          name: "Active",
          value: "Active",
        },
        {
          name: "Inactive",
          value: "Inactive",
        },
      ],
      // searchValue: "",
      // selectedSuppliers: [],
      dependentIndex: 0,
      tempBack: false,
      updatedData: {},
      initialData: {},
      newoff: 100,
      oldoff: 10,
      isNoOrderFound: false,
      orderSearchValue: "",
      commitmentMessage: "",
      isCommitmentLengthValid: false,
      searchPricing: "",
      searchSupplierData: [],
      texteditorVal: "",
      regionDatas: [],
      channelPreValue: [],
      marketPreValue: [],
      dropdown_data: [],
      prompt: "",
      isBasedOnFeature: true,
      isBasedOnProduct: true,
      isBasedOnPrompt: false,
      disableAIToolBarButton: false,
      AIChoicesForDescription: [],
      aiContextMenuPositionTop: 0,
      aiContextMenuPositionLeft: 0,
      AIContextMenuOptions: [
        {
          label: "Using prompt",
          icon: "icon-star-magic-regular",
          action: "prompt",
        },
        {
          label: "Based on feature",
          icon: "icon-cabinet-filing-regular",
          action: "feature",
        },
      ],
      AIContextMenuOptionsBundle: [
        {
          label: "Using prompt",
          icon: "icon-star-magic-regular",
          action: "prompt",
        },
        {
          label: "Based on product",
          icon: "icon-cabinet-filing-regular",
          action: "product",
        },
      ],
      isAIPromptContextMenuVisible: false,
      AIPromptChoicesForDescription: [],
      aiPromptContextMenuPositionTop: 0,
      aiPromptContextMenuPositionLeft: 0,
      AIPromptContextMenuOptions: [
        {
          label: "Based on feature",
          icon: "icon-cabinet-filing-regular",
          action: "feature",
        },
        {
          label: "Close",
          icon: "icon-times-regular",
          action: "close",
        },
      ],
      AIPromptContextMenuOptionsBundle: [
        {
          label: "Based on product",
          icon: "icon-cabinet-filing-regular",
          action: "product",
        },
        {
          label: "Close",
          icon: "icon-times-regular",
          action: "close",
        },
      ],
      isAIContextMenuVisible: false,
      delete_supplier_data: {},
      supplierData: [],
      supplierTableData: [],
      delete_supplier_modal: false,
      additionalChargeData1: [],
      activityrowpage: 0,
      activitycurrentpage: 0,
      activitytotal: 0,
      offerActivity: {
        type: "filter",

        module: "log",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: this.activityrowpage,
          currentPage: this.activitycurrentpage,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          category: "productOffering",
          ref: `${this.$route.params.id}`,
        },
        searchQuery: "",
      },
      offerDependentData: [],
      dependentProductsData: [],
      isDependent: false,
      isRecommended: true,
      productBaseType: "",
      isSupplierDefault: false,
      partyData: [],
      alterdata: [],
      ddhead: "",
      ddvalue: "",

      dropvalue1: [],
      // empty007:[],
      drophead1: [],
      speedOptions: {},
      speedData: {},
      mediaThead: [
        {
          sortable: false,
          resizable: false,
          // width: 150,
          label: "Image",
          prop: "attachments",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          // width: 150,
          label: "SKU",
          prop: "SKU",
          align: "left",
        },
      ],
      relatedProductTableValues: [],
      unitOfMeasurePricing: "",
      mediaData: [],
      mediaHead: [],
      delete_price_modal: false,
      deleteIndex: "",
      leftpannel: "Overview",
      matrixInput: [],
      applyAllCheck: false,
      pricingKey: [],
      supplier: [],
      // dropDownData:[
      //   {name:"Samsung F22",_id:"090980",category:"Phone",price:"$121"}
      // ],
      filterLayoutCategory: {
        // "@baseType": "supplier",
        layoutFor: "productoffering",
        filterQuery: {
          id: {
            $nin: [],
          },
        },
        layout: [
          {
            prop: "category",
            label: "Category",
            type: "Array",
            // fieldToFilter: "name"
          },
        ],
      },
      filterLayout: {
        // "@baseType": "supplier",
        layoutFor: "productoffering",
        filterQuery: {
          id: {
            $nin: [],
          },
        },
        layout: [
          {
            prop: "lifecycleStatus",
            label: "Status",
            type: "String",
            fieldToFilter: "lifecycleStatus",
          },
          {
            prop: "category",
            label: "Category",
            type: "Array",
            // fieldToFilter: "name"
          },
          {
            prop: "productOfferingPrice",
            label: "price",
            type: "Array",
            fieldToFilter: "name",
          },
        ],
      },
      orderFilterOptions: {
        type: "filter",
        module: "productorder",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          "productOrderItem.product.productOffering.id": this.$route.params.id,
        },
        searchQuery: "",
      },
      currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
      pricingPanelData: {
        default: false,
        validFor: {
          startDate: new Date().toISOString(),
          endDate: "9999-12-31T00:00:00Z",
        },
        SKU: "",
        lifecycleStatus: "Draft",
        MSRP: {
          unit: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          value: 0,
        },
        commitmentLength: 1,
        billingPeriod: "Monthly",
        displayValue: "Months",
        endOfCommitment: {
          autoPay: true,
          expireTerm: false,
        },
        tiers: [
          {
            min: 1,
            max: 99,
            price: {
              unit: this.$store?.getters?.getDefaultSettings?.currency?.unit,
              value: 0,
            },
          },
        ],
      },
      defaultTiers: {
        price: {
          unit: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          value: 0,
        },
      },
      ispricingEdit: false,
      updateSearchAndFilterKey: 0,
      layout: [
        {
          prop: "lifecycleStatus",
          label: "Status",
          type: "String",
          fieldToFilter: "lifecycleStatus",
          fieldToSearch: "lifecycleStatus",
        },
        {
          prop: "category",
          label: "Category",
          type: "Array",
          fieldToFilter: "category",
        },
        {
          prop: "productOfferingPrice",
          label: "price",
          type: "Array",
          fieldToFilter: "productOfferingPrice.name",
          fieldToSearch:
            "productOfferingPrice[0].productofferPriceAlteration[0].productprice.taxAmount.Amount",
        },
      ],
      deleteSupplier: [],
      searchFilterData: {},
      matrixPayload: {},
      addedSupplier: [],
      selected: "Recommended",
      tabsection: [{ name: "Recommended" }, { name: "Dependent products" }],
      productRecommed: [],
      mainInfo: [],
      productOffer: [],
      relatedProducts: [],
      relatedProductPanel: false,
      productOwner: {},
      logData: [],
      componentkey: 1,
      supplierList: [],
      offerPayload: {
        bundledproductOffering: [{ name: "" }],
        marketSegment: [],
        channel: [],
        prodSpecCharValueUse: [],
        productSpecification: { name: "" },
        relatedParty: [{ name: "", role: "Product owner" }],
        status: "",
        name: "",
        tags: [],
      },
      tagsOptions: [],
      marketOptions: [],
      channelOptions: [],
      userDetails: {
        name: "",
        id: "",
      },
      tagList: [],
      rfsOptions: [],
      physicalOptions: [],
      logicalOptions: [],
      partyOptions: [],
      imgPrimary: {},
      error: false,
      event: null,
      isReload: false,
      primaryNavigationButtonValue: "Stay",
      secondaryNavigationButtonValue: "Discard",
      secondaryReloadButtonValue: "Leave",
      noteTrigger: "",
      searchNotes: [],
      searchToggle: false,
      noteIndex: 0,
      editNoteVal: {},
      userName: "",
      notesTable: [],
      editMode: false,
      editModeNote: false,
      interactionPayload: {
        id: "",
        reason: "",
        note: "",
        noteusername: "",
        date: new Date(),
      },
      discardIt: false,
      showOverviewDiscardModal: false,
      initialOverviewPayload: {
        name: "",
        category: [],
        description: "",
        tags: [],
        validFor: {
          endOfLife: "",
          endOfDate: "",
        },
        relatedParty: [],
      },
      show_right_filter: false,
      Specfilterquery: {
        type: "search",
        module: "productSpecification",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {
          // "productSpecCharacteristic.productSpecCharacteristicValue.value": "",
        },
        searchQuery: "",
      },
      showAddNotePanel: false,
      searchattach: "",
      delete_modal_active: false,
      deleteobj: {},
      delete_name: "",
      deleterowindex: 0,
      attachment_table: [
        {
          sortable: false,
          resizable: false,
          width: null,
          label: "Filename",
          prop: "name",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 150,
          label: "File size",
          prop: "attachment",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 250,
          label: "Created By",
          prop: "lastUpdated",
          align: "left",
        },
      ],
      userInfo: this.$store.state.userInfo,
      modal_active: false,
      editIndex: 0,
      editRow: {},
      editButton: false,
      searchData: [],
      search: false,
      featureAdd: false,
      offerTable: [],
      attachmentTable: [],
      show_add_menu: false,
      featureObject: {},
      featureObj: [],
      supplierProfile: [],
      isNameEmpty: false,
      featureTypeOptions: [
        { name: "Dropdown", value: "Dropdown" },
        { name: "Text", value: "Text" },
        { name: "Radio button", value: "Radio button" },
        { name: "Rectangular select", value: "Rectangular select" },
        { name: "Swatch", value: "Swatch" },
      ],
      isFeaturePanelEnabled: false,
      payloadCategory: [],
      enableButton: true,
      title: "",
      treedata: {
        service: {
          icones: "icon-box-check-regular tree-icone3",

          name: "service",

          value: "service",

          data: {},
        },

        specification: {
          name: "",
        },
      },
      supplierOriginalDataRemoveProductOwner: [],
      supplierOriginalData: [],
      currPage: 1,
      rowsPerPage: 10,
      externalRowsPerPage: 10,
      totalPageNo: 0,
      SpecificationrowsPerPage: 10,
      SpecificationcurrPage: 0,
      filtertaglength: 0,
      filtertagvalue: [],
      spec_char_thead: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: false,
          prop: "relatedParty",
          label: "Name",
          resizable: false,
          width: 230,
          align: "left",
        },
        {
          name: "Lead time",
          checked: true,
          id: 1,
          disabled: false,
          prop: "leadtime",
          label: "Lead time",
          sortable: false,
          resizable: false,
          width: null,
          align: "left",
        },
        {
          name: "SKU",
          checked: true,
          id: 1,
          disabled: false,
          prop: "stockInfo",
          label: "SKU",
          sortable: false,
          resizable: false,
          width: 180,
          align: "left",
        },

        {
          name: "msrp",
          checked: true,
          id: 1,
          disabled: false,
          prop: "msrp",
          label: "MSRP",
          sortable: false,
          resizable: false,
          width: 150,
          align: "right",
        },
        {
          name: "Price",
          checked: true,
          id: 1,
          disabled: false,
          prop: "price",
          label: "Price",
          sortable: false,
          resizable: false,
          width: 150,
          align: "right",
        },
        {
          name: "cost",
          checked: true,
          id: 1,
          disabled: false,
          prop: "cost",
          label: "Cost",
          sortable: false,
          resizable: false,
          width: 100,
          align: "right",
        },
        {
          name: "quantity",
          checked: true,
          id: 1,
          disabled: false,
          prop: "productStockLevel",
          label: "Stock quantity",
          sortable: false,
          resizable: false,
          width: 120,
          align: "left",
        },
        {
          name: "Location",
          checked: true,
          id: 1,
          disabled: false,
          prop: "place",
          label: "Location",
          resizable: false,
          width: 280,
          align: "left",
        },
      ],
      product_heads: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: false,
          prop: "name",
          label: "Name",
          resizable: false,
          width: 500,
          align: "left",
        },
        {
          name: "Category",
          checked: true,
          id: 1,
          disabled: false,
          prop: "category",
          label: "Category",
          sortable: false,
          resizable: false,
          width: 500,
          align: "left",
        },
        // 350 for name and category width
        // {
        //   name: "Price",
        //   checked: true,
        //   id: 1,
        //   disabled: false,
        //   prop: "price",
        //   label: "Price per unit",
        //   sortable: false,
        //   resizable: false,
        //   width: 300,
        //   align: "left",
        // },
        // {
        //   name: "Margin",
        //   checked: true,
        //   id: 1,
        //   disabled: false,
        //   prop: "margin",
        //   label: "Margin (%)",
        //   sortable: false,
        //   resizable: false,
        //   width: 150,
        //   align: "left",
        // },
      ],
      payloadSpec: {
        description: "",
        unitOfMeasure: "",
        lifecycleStatus: "In study",
        prodSpecCharValueUse: [],
        prodSpecCharValue: [],
        bundledProductOffering: [{ name: "" }],
        productOfferingPrice: [],
        marketSegment: [],
        channel: [],
        productSpecification: { name: "" },
        relatedParty: [{ name: "", role: "Product owner" }],
        status: "",
        name: "",
        tags: [],
        notes: [],
        attachment: [{ media: [] }],
      },
      categoryOptions: [],
      unit: "",
      backgrounStyle: {
        backgroundColor: "",
        bacgroundtTextColor: "#A8A8A8",
        border: false,
        borderColor: "#A8A8A8",
        TextfontSize: 34,
      },
      backgrounStyleuploadSupplier: {
        backgroundColor: "",
        bacgroundtTextColor: "#A8A8A8",
        border: false,
        borderColor: "#fff",
        TextfontSize: 16,
      },
      backgrounStyleupload: {
        backgroundColor: "",
        bacgroundtTextColor: "#A8A8A8",
        border: false,
        borderColor: "#A8A8A8",
        TextfontSize: 16,
      },
      rawPayload: {},
      contactTableData: [],
      username: "GlobalTech Supplies",
      tagName: "Active",
      addressName: "123 Main Street Suite 456 Toronto, ON M5V 2B8, Canada",
      tablehead: "Attachment",
      view: true,
      defalt: true,
      name: "",
      description: "",
      email: "",
      phone: "",
      location: "",
      relatedProductsSearch: "",
      url: "",
      id: this.$route.params.id,
      overview: {},
      sideBarData: {},
      spec: {},
      productpayload: {},
      notification: {},
      offerTypeOptions: [
        { name: "Atomic", value: "Atomic" },
        { name: "Bundle", value: "composite" },
      ],

      statusList: [],
      paymentList: [],
      ShipingList: [],
      Manufacturer: [],
      unitOfMeasure: [],
      endOfDate: "",
      tagsList: [],
      locationApi: "https://appbackend-ghubgwxrcq-pd.a.run.app/autocomplete",
      isRightLayoutOpened: false,
      breadCrumbItems: [
        {
          label: "Product",
          link: `/UPC/product-catalog-table/offering`,
        },
        { label: this.$route.params.name, link: "" },
      ],
      customnameerror: false,

      selectedValue: "Overview",
      tabItems: [
        { name: "Overview", value: "Overview" },
        { name: "Hierarchy", value: "Hierarchy" },
        {
          name: "Contacts",
          value: "Contacts",
        },
        { name: "Interaction", value: "Interaction" },
        { name: "Rules", value: "Rules" },
        { name: "Quotes and orders", value: "Quotes and orders" },
        { name: "Approvals", value: "Approvals" },
      ],
      recievedContactData: [],
      showAddNewAdrressModal: false,
      newAddressPayload: {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      },
      additionalChargeData: [],
      discountData: [],
      tableData: [],
      locationData: [],
      ProductTabledata: {},
      disableEdit: false,
      payload: {
        name: "",
        category: "",
        description: "",
        validFor: {
          endOfDate: "",
          endOfLife: "",
        },
        manufacturer: "",
        unitOfMeasure: "",
        brand: "",
      },
      editTree: false,
      isTreePanel: false,
      tree: {
        type: "",
        value: "",
        description: "",
      },
      treeOptions: [
        {
          name: "Service specification",
          value: "Service specification",
        },
        {
          name: "Resource specification",
          value: "Resource specification",
        },
      ],
      supplierImageSource: "",
      relatedProductParams: "",
      specOptions: [],
      attachmentData: [],
      productName: "",
      pricingData: [],
      // editMode: false,
      ViewMode: false,
      DeleteMode: false,
      addMode: false,
      selectedPrice: "Onetime",
      offerPriceTypes: [],
      pricingTheads: [
        {
          sortable: false,
          resizable: false,
          width: 100,
          label: "SKU",
          prop: "SKU",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 120,
          label: "Status",
          prop: "lifecycleStatus",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 250,
          label: "Validity",
          prop: "validFor",
          align: "left",
        },

        {
          sortable: false,
          resizable: false,
          width: 70,
          label: "Min",
          prop: "min",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 70,
          label: "Max",
          prop: "max",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 150,
          label: "Billing period",
          prop: "billingPeriod",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 150,
          label: "Commitment period",
          prop: "commitmentLength",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 150,
          label: "Price",
          prop: "price",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 200,
          label: "Price per unit",
          prop: "pricePerUnit",
          align: "left",
        },

        {
          sortable: false,
          resizable: false,
          width: 100,
          label: "Auto renew",
          prop: "endOfCommitment",
          align: "left",
        },
      ],

      categoryInfo: null,
      billingPeriodOptions: [
        {
          name: "Weekly",
          value: "Weeks",
        },
        {
          name: "Biweekly",
          value: "Weeks",
        },
        {
          name: "BiMonthly",
          value: "Months",
        },
        {
          name: "Monthly",
          value: "Months",
        },
        {
          name: "Quarterly",
          value: "Months",
        },
        {
          name: "Annually",
          value: "Years",
        },
        {
          name: "Semi Annually",
          value: "Months",
        },
      ],
      supplierProductOwner: [],
      supplierMain: [],
    };
  },
  computed: {
    isSupplierChoosen() {
      return this.featureObj.length <= 0;
    },
    supplierDiscountForOffer() {
      return this.payloadSpec?.productOfferingPrice[0]
        ?.productOfferingAlteration;
    },
    computedPaginationTotalPage() {
      return this.pricingData.filter(
        (item) => item.priceType == this.selectedPrice
      );
    },
    // ...new mapGetters(["statusList"]),
    //  computedPriceTypes(){
    //     console.log("🚀 ~ computedPriceTypes ~ this.spec:", this.spec)
    //     if(!this.spec || (this.spec && !Object.prototype.hasOwnProperty.call(this.spec,'category'))) {return   []}
    //     let leafCategory = this.leaf(this.spec.category)
    //     let categoryInfo = UPCAxiosInstance.get('/catalogManagement/category/'+leafCategory)
    //     console.log("🚀 ~ computedPriceTypes ~ categoryInfo:", categoryInfo)
    //     if(categoryInfo.data) {
    //        return categoryInfo.data.pricingType?.map(type => {return {name:type}})
    //     }
    //     return   []
    //   },

    paginatedData() {
      this.removeDuplicates();
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      console.log("supplierData paginatedData", this.payloadSpec.relatedParty);
      // let suppliers = JSON.parse(JSON.stringify(this.payloadSpec.relatedParty));
      // suppliers = suppliers.filter(
      //   (item) => item.role?.toLowerCase() == "supplier"
      // );

      console.log(
        this.supplierData,
        "this.supplierDatathis.supplierData",
        this.supplierTableData.slice(start, end)
      );
      return this.supplierTableData.slice(start, end);
    },

    matrixPaginated() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      let isDefaultOne = this.pricingData.find(
        (item) => item.priceType == undefined
      );
      console.log("🚀 ~ matrixPaginated ~ isDefaultOne:", isDefaultOne);
      if (isDefaultOne) {
        return this.pricingData.slice(start, end);
      }
      console.log(
        "🚀 ~ matrixPaginated ~ this.selectedPrice?.toLowerCase():",
        this.selectedPrice?.toLowerCase()
      );
      switch (this.selectedPrice?.toLowerCase()) {
        case "onetime":
          // if(this.categoryInfo && this.categoryInfo.type == 'Tangible'){
          return this.pricingData
            .filter(
              (item) =>
                item.priceType &&
                ["one time", "onetime"].includes(item.priceType?.toLowerCase())
            )
            .slice(start, end);
        case "one time":
          // if(this.categoryInfo && this.categoryInfo.type == 'Tangible'){
          return this.pricingData
            .filter(
              (item) =>
                item.priceType &&
                ["one time", "onetime"].includes(item.priceType?.toLowerCase())
            )
            .slice(start, end);
        // }
        // return this.pricingData.filter(item => ['min','max','pricePerUnit'].includes(item.prop))
        case "rental":
          return this.pricingData
            .filter((item) => item.priceType && item.priceType == "Rental")
            .slice(start, end);
        case "installment":
          return this.pricingData
            .filter((item) => item.priceType && item.priceType == "Installment")
            .slice(start, end);

        case "recurring":
          // return this.pricingData.filter(item => ['billingPeriod','commitmentLength','pricePerUnit','autoRenew'].includes(item.prop))
          return this.pricingData
            .filter((item) => item.priceType && item.priceType == "Recurring")
            .slice(start, end);

        case "pay per use":
          // return this.pricingData.filter(item => ['billingPeriod','commitmentLength','min','max','pricePerUnit'].includes(item.prop))
          return this.pricingData
            .filter((item) => item.priceType && item.priceType == "Pay per use")
            .slice(start, end);

        default:
          return this.pricingData.slice(start, end);
      }
    },
    productPaginated() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      console.log("relatedProductTableValues ", this.relatedProductTableValues);
      return this.relatedProductTableValues
        ? this.relatedProductTableValues.slice(start, end)
        : this.relatedProductTableValues;
    },
    attachedPaginated() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.attachmentData.slice(start, end);
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.tableData.length;
    },
    filteredSupplierhead() {
      console.log(
        "this.productBaseType dowm",
        this.productBaseType?.toLowerCase()
      );
      if (this.payloadSpec?.offerType == "Atomic") {
        if (this.productBaseType?.toLowerCase() === "tangible") {
          return this.spec_char_thead.filter(
            (item) => !["price"].includes(item.prop)
          );
        } else {
          return this.spec_char_thead
            .filter(
              (item) =>
                !["msrp", "place", "productStockLevel", "stockInfo"].includes(
                  item.prop
                )
            )
            .map((item) => {
              if (["relatedParty", "cost", "price"].includes(item.prop)) {
                return {
                  ...item,
                  width: "260",
                };
              }
              console.log("item filteredSupplierhead", item);
              return item;
            });
        }
      } else {
        return this.spec_char_thead;
      }
    },
    computedTheads() {
      console.log(this.pricingTheads, "computed price type of the data");

      switch (this.selectedPrice?.toLowerCase()) {
        case "onetime":
          if (this.categoryInfo && this.categoryInfo.type == "Tangible") {
            return this.pricingTheads.filter(
              (item) =>
                ![
                  "billingPeriod",
                  "commitmentLength",
                  "pricePerUnit",
                  "endOfCommitment",
                ].includes(item.prop)
            );
          }
          return this.pricingTheads.filter(
            (item) =>
              ![
                "billingPeriod",
                "commitmentLength",
                "pricePerUnit",
                "endOfCommitment",
              ].includes(item.prop)
          );
        case "one time":
          if (this.categoryInfo && this.categoryInfo.type == "Tangible") {
            return this.pricingTheads.filter(
              (item) =>
                ![
                  "billingPeriod",
                  "commitmentLength",
                  "pricePerUnit",
                  "endOfCommitment",
                ].includes(item.prop)
            );
          }
          return this.pricingTheads.filter(
            (item) =>
              ![
                "billingPeriod",
                "commitmentLength",
                "pricePerUnit",
                "endOfCommitment",
              ].includes(item.prop)
          );

        case "rental":
          return this.pricingTheads.filter(
            (item) => !["pricePerUnit"].includes(item.prop)
          );
        case "recurring":
          return this.pricingTheads.filter(
            (item) => !["SKU", "price"].includes(item.prop)
          );
        case "pay per use":
          return this.pricingTheads.filter(
            (item) => !["SKU", "autoRenew", "price"].includes(item.prop)
          );
        case "installment":
          return this.pricingTheads.filter(
            (item) => !["SKU", "pricePerUnit"].includes(item.prop)
          );
        default:
          return this.pricingTheads;
      }
    },
  },
  watch: {
    searchValue(newVal) {
      if (newVal) {
        this.fetchSuppliers(newVal);
      } else {
        return this.supplier;
      }
    },
    supplier(newVal) {
      if (newVal) {
        console.log("supplier Watch", newVal);
        this.supplier = newVal;
      }
    },

    leftpannel: {
      async handler(val) {
        if (val == "Orders") {
          this.isNoOrderFound = await this.checkOrderAvailabilty();
        }
      },
    },
    mediaData: {
      handler(val) {
        // this.overview = val;

        console.log("mediadata is watching in overview", val);
      },
      immediate: true,
      deep: true,
    },

    pricingTheads: {
      handler(value) {
        this.pricingTheads = value;
      },
    },
    unit: {
      handler(value) {
        this.payloadSpec.unitOfMeasure = value;
      },
    },

    initialOverviewPayload: {
      handler(value) {
        console.log("🚀 ~ handler ~ value:", value);
      },
      deep: true,
      immediate: true,
    },
    spec: {
      handler(value) {
        console.log("🚀 ~ handler ~ value:", value);
        this.computedPriceTypes();
      },
      immediate: true,
      deep: true,
    },
    payload: {
      handler(val) {
        this.overview = val;
        // console.log(this.payload);
      },
      immediate: true,
      deep: true,
    },
    mainInfo: {
      handler(val) {
        this.mainInfo = val;
      },
      immediate: true,
      deep: true,
    },

    supplierData: {
      async handler(val) {
        console.log(val, "supplierData");
        // this.searchSupplierData=JSON.parse(JSON.stringify(val));
        if (!val) {
          this.supplierData = [];
          this.handleSupplierImageLoad = false;
          return;
        }
        // else if(!this.handleSupplierImageLoad){
        this.supplierTableData = JSON.parse(JSON.stringify(val));
        console.log(val, "supplierData", this.supplierTableData);
        // this.supplierData = JSON.parse(JSON.stringify(val));
        // await this.supplierTableImage(val);
        // }
      },
      immediate: true,
      deep: true,
    },
    // partyData: {
    //   handler(val) {
    //     console.log("Add supplier Watch", val);
    //     this.partyData = val;
    //     // console.log("Add supplier Watch end before", this.supplierMain);
    //     // this.supplier = this.supplierMain.filter(
    //     //   (ele) => !this.tableData.some((val) => val._id === ele._id)
    //     // );
    //     // console.log("Add supplier Watch end", this.supplierMain);
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventPageReload);
  },
  /**This hook help to remove beforeunload event which we're created during mount */
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.preventPageReload);
  },
  async mounted() {
    const value = await MLTAxiosInstance.get("partymanagement/individual/auth");

    let sampleTheadsd = await MLTAxiosInstance.get(
      `partyRole/get/${value.data.userId}`
    );

    const mergedPayload = sampleTheadsd.data.permission;

    this.permisstionData =
      mergedPayload.UPC.child["Product catalogue"].child.Offers.sections;

    this.sideBarData = this.mergeSidebarData(
      this.tempSideBar,
      this.permisstionData
    );


    let productSpec = await UPCAxiosInstance.get(
      `/catalogManagement/productOffering/${this.$route.params.id}`
    );

    this.productName = productSpec.data?.name;
    this.payloadSpec = {
      ...this.payloadSpec,
      ...productSpec.data,
      id: productSpec.data?._id,
    };
    this.regionDatas = productSpec?.data;
    const getAll = await axios.get(`https://appbackend-ghubgwxrcq-pd.a.run.app/acl/`,{
      headers: {
        'hlx-api-key': 'bbd712b6bf575dcef3a510e3e0ec8405:f2c221f17b243986ecfd4ca33f1ece8ad4f1bbec5aa48da22d262a89671eb9c3eebabc4f9e514ab65d128fee0111802d6e750f955217f2324258c4e41e492367'
      }})
      let arr1 = this.payloadSpec.relatedParty;
      const result = arr1.some(item => 
      getAll.data.includes(item.name) && item.role == "Product owner"
);
console.log("editMode is onn",result)
if(result){
  this.editMode = true;
  
}
else{
  this.editMode = false;
}
    if (this.payloadSpec?.offerType != "Atomic") {
      //send to accord data
      this.payloadSpecData = JSON.parse(JSON.stringify(this.payloadSpec));
      console.log("accord data is loaded", this.payloadSpecData);

      const bundleList = UPCAxiosInstance.post(
        `util/filterData`,
        this.Specfilterquery
      );
      this.originalBundleList = bundleList.data;
      this.bundleList = bundleList.data;
      this.offersList = this.payloadSpec?.bundledProductOffering;
      console.log(this.offersList, "this.offersList");
      if (
        productSpec?.data?.productSpecification["@baseType"]?.toLowerCase() ===
        "intangible"
      ) {
        // this.categoryOffer("recurring")
        this.propForBundleListingComponent = "recurring";
        this.productBaseType = "intangible";
      } else {
        this.propForBundleListingComponent = "Onetime";
        this.productBaseType = "tangible";
        // this.categoryOffer("Onetime")
      }
    }
    this.initialData = {
      name: productSpec.data?.name,
      description: productSpec.data?.description || "",
      lifecycleStatus: productSpec.data?.lifecycleStatus,
      tags: productSpec.data?.tags,
      marketSegment: productSpec.data?.marketSegment,
      channel: productSpec.data?.channel,
      productContact:
        productSpec.data?.relatedParty?.find(
          (item) => item.role == "Product owner"
        )?.name || "",
    };
    this.supplierDiscount = await this.supplierDiscountForOffer;
    this.texteditorVal = productSpec.data?.description || "";
    this.updatedData = {
      name: productSpec.data?.name,
      description: this.texteditorVal,
      lifecycleStatus: productSpec.data?.lifecycleStatus,
      tags: productSpec.data?.tags,
      marketSegment: productSpec.data?.marketSegment,
      channel: productSpec.data?.channel,
      productContact:
        productSpec.data?.relatedParty?.find(
          (item) => item.role == "Product owner"
        )?.name || "",
    };

    //Loading unit of measures
    // const rootName = this.$cookies.get("rootName");
    // const statuslist =
    //   rootName === "Halleyx"
    //     ? "mapRef/collection/statuslist"
    //     : `mapRef/collection/statuslist?company=${this.$cookies.get(
    //         "rootName"
    //       )}`;
    const statusListpricing = await this.getReferanceData("statuslist");
    this.filteredstatus = statusListpricing?.filter(
      (item) =>
        item.name === "Active" ||
        item.name === "Inactive" ||
        item.name === "Draft"
    );

    let leafCategory = await this.leaf(productSpec.data.category);
    let categoryInfo = await UPCAxiosInstance.get(
      "/catalogManagement/category/" + leafCategory
    );
    console.log(categoryInfo, "categoryInfo", this.payloadSpec.unitOfMeasure);
    if (categoryInfo.data) {
      this.categoryInfo = categoryInfo?.data;
      if (
        this.payloadSpec.unitOfMeasure == "" ||
        this.payloadSpec.unitOfMeasure == undefined
      ) {
        if (this.categoryInfo?.type == "Tangible") {
          this.payloadSpec.unitOfMeasure = "Each";
        } else {
          this.payloadSpec.unitOfMeasure = "Per billing period";
        }
      }
    }

    this.relatedProductParams = productSpec.data?._id;
    this.additionalChargeData1 = productSpec.data?.additionalCharges;

    this.productBaseType = await productSpec?.data?.productSpecification[
      "@baseType"
    ];

    this.dropdown_data = await this.getReferanceData("unitOfMeasure");

    if (productSpec?.data?.offerType == "Atomic") {
      let spec = await UPCAxiosInstance.get(
        `/catalogManagement/productSpecification/${productSpec.data?.productSpecification?.id}`
      );
      this.spec = await spec.data;
    }
    this.notesTable = productSpec?.data?.notes;
    this.attachmentData = productSpec?.data?.attachment;
    this.notesTable = productSpec?.data?.notes;
    this.attachmentData = productSpec?.data?.attachment;
    if (productSpec.data) {
      this.name = productSpec?.data?.name;

      this.offerPayload.marketSegment = this.payloadSpec?.marketSegment?.map(
        (val) => {
          return val?.name;
        }
      );
      this.marketPreValue = this.offerPayload.marketSegment;

      this.offerPayload.channel = this.payloadSpec.channel.map((val) => {
        return val.name;
      });

      this.channelPreValue = this.offerPayload.channel;
      this.initialOverviewPayload = JSON.parse(
        JSON.stringify({ ...this.initialOverviewPayload, ...productSpec.data })
      );
    }
    this.computedPriceTypes();
    this?.spec?.productSpecCharacteristic?.forEach((feature, index) => {
      index;
      this.speedData[index] = this.prepopulate(
        this.payloadSpec.prodSpecCharValueUse,
        "name",
        feature.name,
        feature.productSpecCharacteristicValue
      );

      this.speedOptions[index] = feature.productSpecCharacteristicValue?.map(
        (item) => {
          return {
            ...item,
            displayValue: item.value + " " + item?.valueSuffix,
          };
        }
      );
    });
    const configurableSpecValue =
      this.payloadSpec && this.payloadSpec.prodSpecCharValueUse
        ? this.payloadSpec.prodSpecCharValueUse.filter(
            (item) => item.configurable
          )
        : [];
    let isCompleteNoConfigurableCharacteristic =
      this.payloadSpec.prodSpecCharValueUse.every((item) => !item.configurable);
    if (
      configurableSpecValue.length > 0 ||
      isCompleteNoConfigurableCharacteristic
    ) {
      if (
        this.payloadSpec &&
        this.payloadSpec.prodSpecCharValue &&
        this.payloadSpec.prodSpecCharValue.length > 0
      ) {
        let matrixPayload = await this.getConfigurableOptions(
          this.payloadSpec.prodSpecCharValueUse
        );

        let keys = Object.keys(matrixPayload);
        this.pricingKey = keys;
        let priceTable = keys.map((obj) => {
          return {
            sortable: false,
            resizable: false,
            width: 150,
            label: obj,
            prop: obj,
            align: "left",
          };
        });
        this.pricingTheads = priceTable.concat(this.pricingTheads);
        this.pricingData = this.payloadSpec.prodSpecCharValue;
      } else {
        this.uploadPerumutation(this.payloadSpec.prodSpecCharValueUse);
      }
    } else {
      if (
        this.payloadSpec &&
        this.payloadSpec.prodSpecCharValue &&
        this.payloadSpec.prodSpecCharValue.length == 0
      ) {
        this.uploadPerumutation([]);
      }
    }

    await this.addIdFilterLayout(this.payloadSpec.relatedProduct);
    this.categoryData();
    this.supplierFilterData();
    this.searchFilterData = await this.supplierFilterData();

    await this.refreshSuppliersList();
    this.relatedProducts = this.payloadSpec?.relatedProduct;

    this.refreshRelatedProductTable();
    console.log("supplier filter overview 0 main ", this.searchFilterData);

    let statusList = await this.getReferanceData("statuslist");
    const statusOrder = ["Active", "Inactive", "In study", "Pending"];
    this.statusList = statusList
      .filter((item) => statusOrder.includes(item.name))
      .sort(
        (a, b) => statusOrder.indexOf(a.name) - statusOrder.indexOf(b.name)
      );

    let activitylogdata = await MLTAxiosInstance.post(
      `util/filterData`,
      this.offerActivity
    );

    this.logData = activitylogdata.data.data;
    this.activitytotal = activitylogdata.data.total;

    let media = [
      {
        align: "left",
        label: "Image",
        prop: "image",
        resizable: false,
        sortable: false,
        width: 150,
      },
    ];
    this.mediaHead = media.concat(this.pricingTheads);

    let matrixPayload = await this.getConfigurableOptions(
      this.payloadSpec.prodSpecCharValueUse
    );

    let keys = Object.keys(matrixPayload);

    let priceTable = keys.map((obj) => {
      return {
        sortable: false,
        resizable: false,
        width: 300,
        label: obj,
        prop: obj,
        align: "left",
      };
    });
    const r = this.mediaThead?.concat(priceTable);
    console.log("media head check", priceTable, this.mediaThead, r);
    this.mediaThead = r;

    if (this.mediaThead) {
      let productSpec1 = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/${this.$route.params.id}`
      );
      this.offertypeMedia = productSpec;

      if (
        productSpec.data.productSpecification["@baseType"]?.toLowerCase() ===
        "intangible"
      ) {
        // this.basetypeover = "intangible";
        // this.mediaThead = r.filter((item) => item.prop !== "SKU");
        // console.log("mmmmmmm", this.mediaThead);
      }

      this.mediaData = productSpec1.data.prodSpecCharValue;
    }

    this.drophead1 = this.payloadSpec.prodSpecCharValueUse
      .filter((item) => item.configurable && item?.name) // Only include items where configurable is true and name exists
      .map((item) => ({ name: item?.name }));
    // const channelurl =
    //   rootName === "Halleyx"
    //     ? "mapRef/collection/channel"
    //     : `mapRef/collection/channel?company=${this.$cookies.get("rootName")}`;
    // const channel = await DMNAxiosInstance.get(channelurl);
    this.channelOptions = await this.getReferanceData("channel");

    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    this.company = val.data.company;
    this.userId = val.data.userId;
    this.userDetails = {
      name: this.company,
      id: this.userInfo._id,
    };
    const partyOption = await MLTAxiosInstance.get(
      `/partymanagement/individual/listuser?company=${this.company}`
    );
    this.partyOptions = partyOption.data;
    // const marketurl =
    //   rootName === "Halleyx"
    //     ? "mapRef/collection/market"
    //     : `mapRef/collection/market?company=${this.$cookies.get("rootName")}`;
    // const unitOfmesure = await DMNAxiosInstance.get(marketurl);
    this.marketOptions = await this.getReferanceData("market");
    // const tagsListurl =
    //   rootName === "Halleyx"
    //     ? "mapRef/collection/tagsList"
    //     : `mapRef/collection/tagsList?company=${this.$cookies.get("rootName")}`;

    let tagsList = await this.getReferanceData("tagsList");
    this.tagsOptions = tagsList.filter((item) => {
      return item.module === "po";
    });
    let filterResult = await UPCAxiosInstance.post(`util/filterData`, {
      type: "search",
      module: "productOffering",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
    });
    this.productOffer = await filterResult.data.data;

    document.addEventListener("click", (event) => {
      if (!event.target.classList.contains("icon-more-vertical-filled")) {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    });
    this.applyDiscountForSupplier();
    this.showLeftVal = true;
    this.fromFeatureChanged = false;
    this.relatedProductPanel = false;

    // ACL 
          // ;
  
  },
  methods: {
   
    async defaultSupplierData(val) {
      if (val.length > 0) {
        this.default_supplier_option = val.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t?.customName === item?.customName)
        );

        // Find the item with isDefault true and assign its customName to prevalueSupplier
        const defaultSupplier = val.find((item) => item.isDefault);
        if (defaultSupplier) {
          this.prevalueSupplier = defaultSupplier?.customName;
        } else if (!defaultSupplier) {
          this.defaultSupplierSelect(this.default_supplier_option[0]);
          this.prevalueSupplier = this.default_supplier_option[0]?.customName;
          // this.default_supplier_option[0]?.isDefault = true
        }

        console.log(
          this.default_supplier_option,
          "this.default_supplier_option",
          val
        );
        console.log(this.prevalueSupplier, "this.prevalueSupplier");
      } else {
        console.log("Default supplier data is empty", val);
      }
    },
    async handleSupplierVersion2SpecChanged(val) {
      this.selectedSuppliers = [];
      if (val) {
        val.forEach((e) => {
          if (!this.selectedSuppliers.some((val) => val._id == e._id)) {
            this.selectedSuppliers.push(e);
          }
        });
      }
      // this.selectedSuppliers.length>0?val.forEach(e=> if(!this.selectedSuppliers.some((val)=>val._id == e._id)){this.selectedSuppliers.push(e))}:this.selectedSuppliers=val
      console.log(
        val,
        "supplierList intangible select",
        this.selectedSuppliers
      );
      console.log(
        "supplierList intangible B supplierList intangible B",
        this.payloadSpec
      );
      // this.supplierProfile = [];
      // this.featureObj = [];
      // console.log("handleSupplier data", val);

      // let featureObj = val;
      let fetchObj = val.map((itemA) => {
        console.log(itemA, "supplierList intangible B");
        let found = this.payloadSpec?.prodSpecCharValue.map((itemB) => {
          console.log(itemB, "supplierList intangible B");
          // console.log(
          //   "handleSupplier data itemB",
          //   this.payloadSpec?.prodSpecCharValue
          // );
          // console.log("handleSupplier data itemB", itemB);
          // itemB?.lifecycleStatus?.toLowerCase() === "active"
          if (!this.supplierTableData.some((item) => item.id == itemB.id)) {
            let trueOrFalse = this.supplierTableData.some(
              (item) => item.id == itemB.id
            );
            console.log(itemB.id, "trueOrFalse", trueOrFalse);
            let supplierEntry = {
              customName: itemA?.customName,
              name: itemA?.name,
              role: "supplier",
              externalID: itemA?._id,
              leadtime: `${itemA?.externalReference[1]?.leadTime} ${itemA?.externalReference[1]?.leadPeriod}`,
              sku: itemB.SKU,
              id: itemB.id,
              priceType: itemB.priceType,
              tiers: itemB?.tiers,
              msrp: itemB.MSRP,
              displayId:
                Math.floor(Math.random() * (9999999999 - 1000000000 + 1)) +
                1000000000,
              // quantity: itemA?.productStockLevel,
              isDefault: this.supplierTableData.length === 0 ? true : false,
            };
            console.log(
              "supplierList intangible found entarnce",
              supplierEntry
            );

            if (Array.isArray(itemA?.attachment)) {
              itemA.attachment.forEach((attachment) => {
                if (attachment?.["@type"]?.toLowerCase() === "profile") {
                  supplierEntry.attachmentName = attachment?.name;
                }
              });
            }

            return supplierEntry;
          }
        });
        return found;
      });
      this.featureObj = await fetchObj;

      // console.log("supplierList intangible", this.supplierProfile);
      console.log("supplierList intangible 89", this.featureObj);
    },

    // Add supplier when the feature is changed
    async addSupplierFeatureChanged(addValue) {
      console.log("addValue", addValue);

      if (this.categoryInfo?.type == "Tangible") {
        //get first obj pricing type
        let getPricingType = addValue[0]?.priceType;

        let filterBasedOnPricingType = addValue.filter(
          (value) => value.priceType == getPricingType
        );
        console.log(filterBasedOnPricingType, "getPricingType", getPricingType);
        console.log(this.supplierTableData, " this.supplier ", this.supplier);
        let duplicatedSupplierList = JSON.parse(JSON.stringify(this.supplier));
        let supplierToBeAddedList = duplicatedSupplierList.filter((supplier) =>
          this.supplierTableData.some(
            (tableData) => supplier.customName === tableData.customName
          )
        );
        await this.handleSupplierVersion2SpecChanged(supplierToBeAddedList);
        this.addSupplier();
        this.offSupplierNotification = true;
        this.isFeaturePanelEnabled = false;
        console.log("supplierToBeAddedList", supplierToBeAddedList);
      }
    },

    // delete supplier when the feature is changed
    async deleteSupplierFeatureChanged(deleteValue) {
      let duplicatedSupplierData = JSON.parse(
        JSON.stringify(this.supplierOriginalData)
      );
      console.log(
        deleteValue,
        "duplicatedSupplierData",
        duplicatedSupplierData
      );
      let removeSupplier = duplicatedSupplierData.filter((supplier) => {
        console.log("removeSupplier data", supplier);
        return !(
          supplier.id === deleteValue.id &&
          supplier.priceType === deleteValue.priceType
        );
      });

      let removeSupplierV2 = duplicatedSupplierData.filter((supplier) => {
        return deleteValue.some(
          (deleteItem) =>
            supplier.id === deleteItem.id &&
            supplier.priceType === deleteItem.priceType
        );
      });

      console.log(
        removeSupplierV2,
        "deleteSupplierFeatureChanged",
        removeSupplier
      );

      if (removeSupplier) {
        this.delete_supplier_data = JSON.parse(
          JSON.stringify(removeSupplierV2)
        );
        this.deleteSupplierModal();
      }
    },

    async defaultSupplierSelect(val) {
      console.log(val, "val defaultSupplierSelect");
      this.supplierOriginalData.forEach((item) => {
        // Set isDefault to true or false based on customName match with selectval
        item.isDefault = item.customName === val.customName;

        // Remove 'src' property from each object
        delete item.src;
      });

      const updateSpecWithChar = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        {
          relatedParty: this.supplierOriginalData,
          id: this.payloadSpec._id,
        }
      );
      console.log(
        this.payloadSpec.relatedParty,
        "this.payloadSpec.relatedParty",
        updateSpecWithChar
      );
    },
    //Delete modal for recommended products
    async deleteRelatedProd() {
      this.relatedProducts.splice(this.deleteRelatedProductIndex, 1); // instead of index => this.deleteRelatedProductIndex
      console.log(this.relatedProducts, "related productssss");
      this.searchData = JSON.parse(JSON.stringify(this.relatedProducts));
      this.payloadSpec.relatedProduct = this.relatedProducts;
      this.payloadSpec.id = this.payloadSpec._id;
      console.log(
        "🚀 ~ closeCrudContextMenu ~ deletedRelatedProduct:",
        this.deletedRelatedProduct
      );
      const updateSpecWithChar = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        this.payloadSpec
      );

      console.log(
        "loading media in all closeCrudContextMenu",
        updateSpecWithChar?.data?.prodSpecCharValue
      );
      console.log(updateSpecWithChar.data, "productRecommed 3.0 delete");
      await this.removeIdFilterLayout(this.deleteRelatedProductObj); // instead of i => this.deleteRelatedProductObj
      this.refreshRelatedProductTable();
      if (updateSpecWithChar.data) {
        this.notification = {
          type: "success",
          message: `Done! Your item has been removed.`,
        };
        if (this.deletedRelatedProduct) {
          const deletedRelatedProductLogPayload = {
            message: `${this.deletedRelatedProduct.name} Recommended products  has been deleted`,
            detailedAction: [],
            "@type": "Offering-overview",
            category: ["productOffering"],
            createdBy: this.$cookies.get("user"),
            user: this.$cookies.get("userName"),
            lastUpdate: new Date().toISOString(),
            ref: [this.payloadSpec?.id, this.deletedRelatedProduct._id],
            action: "DELETE",
          };
          this.initLog(deletedRelatedProductLogPayload);
        }
        this.delete_modal_related_product = false;
      }
    },
    isFreshCombination(currentObject, overallArray, dynamicKeys) {
      return overallArray.some((item) => {
        // Skip the object if it has the same id as the currentObject
        const isSameObject = item.id === currentObject.id;
        if (isSameObject) {
          console.log(
            `inside the checkSKU Skipping item with id: ${item.id} as it is the same as the current object`
          );
          return false; // Skip this iteration
        }

        // Check if SKU matches
        const skuMatches = item.SKU === currentObject.SKU;
        console.log(
          `inside the checkSKU Checking SKU match: "${item.SKU}" vs "${
            currentObject.SKU
          }" => ${skuMatches ? "Yes" : "No"}`
        );

        if (skuMatches) {
          // If SKU matches, check if all dynamic keys match
          const allKeysMatch = dynamicKeys.every((key) => {
            const currentValue = JSON.stringify(currentObject[key]);
            const itemValue = JSON.stringify(item[key]);

            console.log(`inside the checkSKU Comparing key: "${key}"`);
            console.log(
              `inside the checkSKU Current Object Value: ${currentValue}`
            );
            console.log(
              `inside the checkSKU Overall Array Value: ${itemValue}`
            );

            const match = currentValue === itemValue;
            console.log(
              `inside the checkSKU Match for key "${key}": ${
                match ? "Yes" : "No"
              }`
            );
            return match;
          });

          console.log(
            `inside the checkSKU Does this item match all dynamic keys? ${
              allKeysMatch ? "Yes" : "No"
            }`
          );

          // Return true if SKU exists but all dynamic keys do not match
          if (!allKeysMatch) {
            return true; // SKU matches but dynamic keys differ
          }
        }

        return false; // SKU does not match or all keys match
      });
    },
    // activity log for bundlue new pricing
    bundulePricingAdd() {
      const pricingLogPayload = {
        message: `New bundle pricing has been added`,
        detailedAction: [],
        "@type": "Offering-pricing",
        category: ["productOffering"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: [this.payloadSpec?.id],
        action: "ADDED",
        status: "active",
      };
      this.initLog(pricingLogPayload);
    },

    // activity log for bundlue delete pricing
    bundulePricingDeleted() {
      const pricingLogPayload = {
        message: `Bundle pricing has been removed`,
        detailedAction: [],
        "@type": "Offering-pricing",
        category: ["productOffering"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: [this.payloadSpec?.id],
        action: "Removed",
        status: "active",
      };
      this.initLog(pricingLogPayload);
    },
    bundulePricingdiscountType(initial, updated) {
      if (initial !== updated) {
        const pricingLogPayload = {
          message: `Bundle pricing has been updated`,
          detailedAction: [
            {
              action: "UPDATED",
              from: initial,
              to: updated,
              property: "Discount type",
            },
          ],
          "@type": "Offering-pricing",
          category: ["productOffering"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          ref: [this.payloadSpec?.id],
          action: "UPDATED",
          status: "active",
        };
        this.initLog(pricingLogPayload);
      }
    },
    bundulePricingbillingPeriodLog(initial, updated) {
      if (initial !== updated) {
        const pricingLogPayload = {
          message: `Bundle pricing has been updated`,
          detailedAction: [
            {
              action: "UPDATED",
              from: initial,
              to: updated,
              property: "Billing period",
            },
          ],
          "@type": "Offering-pricing",
          category: ["productOffering"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          ref: [this.payloadSpec?.id],
          action: "UPDATED",
          status: "active",
        };
        this.initLog(pricingLogPayload);
      }
    },
    bundulePricingCommitmentlengthLog(initial, updated) {
      if (initial !== updated) {
        const pricingLogPayload = {
          message: `Bundle pricing has been updated`,
          detailedAction: [
            {
              action: "UPDATED",
              from: initial,
              to: updated,
              property: "Commitment length",
            },
          ],
          "@type": "Offering-pricing",
          category: ["productOffering"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          ref: [this.payloadSpec?.id],
          action: "UPDATED",
          status: "active",
        };
        this.initLog(pricingLogPayload);
      }
    },
    bundulePricingendDate(initial, updated) {
      if (this.formatDateLog(initial) !== this.formatDateLog(updated)) {
        const pricingLogPayload = {
          message: `Bundle pricing has been updated`,
          detailedAction: [
            {
              action: "UPDATED",
              from: initial,
              to: updated,
              property: "End date",
            },
          ],
          "@type": "Offering-pricing",
          category: ["productOffering"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          ref: [this.payloadSpec?.id],
          action: "UPDATED",
          status: "active",
        };
        this.initLog(pricingLogPayload);
      }
    },
    formatDateLog(dateInput) {
      // Attempt to parse the date
      const date = new Date(dateInput);

      // Check if date is valid
      if (isNaN(date)) {
        return "Invalid date";
      }

      // Format the date as DD MMM YYYY
      const options = { day: "2-digit", month: "short", year: "numeric" };
      return date.toLocaleDateString("en-GB", options);
    },
    bundulePricingstartDate(initial, updated) {
      console.log(
        "bundulePricingstartDate...",
        initial,
        this.formatDateLog(initial),
        "------------",
        updated,
        this.formatDateLog(updated)
      );

      if (this.formatDateLog(initial) !== this.formatDateLog(updated)) {
        console.log("bundulePricingstartDate... runner");

        const pricingLogPayload = {
          message: `Bundle pricing has been updated`,
          detailedAction: [
            {
              action: "UPDATED",
              from: initial,
              to: updated,
              property: "Start date",
            },
          ],
          "@type": "Offering-pricing",
          category: ["productOffering"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          ref: [this.payloadSpec?.id],
          action: "UPDATED",
          status: "active",
        };
        this.initLog(pricingLogPayload);
      }
    },
    bundulePricingdiscountLog(initial, updated) {
      if (initial !== updated) {
        const pricingLogPayload = {
          message: `Bundle pricing has been updated`,
          detailedAction: [
            {
              action: "UPDATED",
              from: initial,
              to: updated,
              property: "Discount",
            },
          ],
          "@type": "Offering-pricing",
          category: ["productOffering"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          ref: [this.payloadSpec?.id],
          action: "UPDATED",
          status: "active",
        };
        this.initLog(pricingLogPayload);
      }
    },
    // activity log for bundlue quantity
    bundulePricingquantity(initial, updated, name) {
      console.log(initial, updated, "bundulePricingquantity");

      if (initial !== updated) {
        const pricingLogPayload = {
          message: `Bundle pricing ${name} product has been updated`,
          detailedAction: [
            {
              action: "UPDATED",
              from: initial,
              to: updated,
              property: "Quantity",
            },
          ],
          "@type": "Offering-pricing",
          category: ["productOffering"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          ref: [this.payloadSpec?.id],
          action: "UPDATED",
          status: "active",
        };
        this.initLog(pricingLogPayload);
      }
    },

    async pricingDefaultToggle(emitData) {
      let i = this.pricingPanelData;
      console.log(
        "pricingDefaultToggle",
        i,
        emitData,
        this.productBaseType?.toLowerCase()
      );
      if (this.productBaseType?.toLowerCase() == "tangible") {
        if (i.SKU) {
          this.pricingPanelData = i;

          // If the current item is already set to default, unset it
          if (this.pricingPanelData.default === false) {
            //   this.pricingPanelData.default = false;
            //   console.log(
            //     `Setting SKU ${this.pricingPanelData.SKU} of priceType ${this.pricingPanelData.priceType} to not default.`
            //   );
            // } else {
            this.pricingData.map((item) => {
              if (item.SKU === this.pricingPanelData.SKU) {
                if (item.default === true) {
                  item.default = false;
                  console.log(
                    `Setting SKU ${item.SKU} of priceType ${item.priceType} to not default.`
                  );
                }
              }
            });

            // Set the current item to default
            this.pricingPanelData.default = true;
            console.log(
              `Setting SKU ${this.pricingPanelData.SKU} of priceType ${this.pricingPanelData.priceType} to default.`
            );
          }

          console.log(this.pricingData, "this.pricingData");
          const updateOffer = await UPCAxiosInstance.patch(
            `/catalogManagement/productOffering`,
            {
              id: this.$route.params.id,
              prodSpecCharValue: this.pricingData,
            }
          );
          console.log(updateOffer, "updateOffer");

          // return this.pricingData;
        }
      } else {
        if (i.id) {
          this.pricingPanelData = i;

          this.pricingData = this.pricingData.map((item) => {
            item.default = false;
            console.log(item, "orange item", this.pricingPanelData);
            if (
              item.id === this.pricingPanelData.id &&
              item.priceType === this.pricingPanelData.priceType
            ) {
              item.default = true;
              console.log(
                `Setting SKU ${item.SKU} of priceType ${item.priceType} to not default.`
              );
            }
            return item;
          });

          // if (this.pricingPanelData.default) {
          //   this.pricingPanelData.default = false;
          //   console.log(
          //     `Setting SKU ${this.pricingPanelData.SKU} of priceType ${this.pricingPanelData.priceType} to not default.`
          //   );
          // } else {
          //   this.pricingPanelData.default = true;
          //   console.log(
          //     `Setting SKU ${this.pricingPanelData.SKU} of priceType ${this.pricingPanelData.priceType} to default.`
          //   );
          // }

          console.log(this.pricingData, "this.pricingData orange");

          // Patch the updated pricingData array
          const updateOffer = await UPCAxiosInstance.patch(
            `/catalogManagement/productOffering`,
            {
              id: this.$route.params.id,
              prodSpecCharValue: this.pricingData,
            }
          );
          console.log(updateOffer, "updateOffer");

          // return this.pricingData;
        }
      }
    },
    async applyDiscountForSupplier() {
      if (this.supplierData) {
        try {
          this.supplierData = await Promise.all(
            this.supplierData?.map(async (e) => {
              await Promise.all(
                this.supplierDiscount?.map(async (ele) => {
                  if (!e.supplierCost) {
                    if (e?.customName === ele?.customName) {
                      let payload = {
                        supplier: {
                          product: [
                            {
                              productOfferingId: this.payloadSpec.id,
                              msrp: e?.msrp?.value,
                              discount: {
                                skuDiscount: {
                                  value: ele.discount,
                                  startDate: ele.validFor.startDate,
                                  endDate: ele.validFor.endDate,
                                  active: 1,
                                },
                                categoryDiscount: 0,
                                brandDiscount: 0,
                              },
                            },
                          ],
                        },
                      };

                      try {
                        let response = await DMNAxiosInstance.post(
                          `/rule_engine/rule/execute/668690e4d5f0c403af9e5b1d`,
                          { payload: payload }
                        );

                        e.cost =
                          response?.data?.data?.supplier?.product[0]?.cost || 0;
                      } catch (error) {
                        console.error("Error applying discount:", error);
                      }
                    }
                  }
                })
              );
              return e;
            })
          );
        } catch (e) {
          console.log("Error from apply disocunt outer func", e);
        }
      } else {
        return;
      }
    },

    receivedSelectedOffers(val) {
      const normalize = (str) => str?.toLowerCase().replace(/\s+/g, "");

      const result = val.map((item) => {
        // Store the filtered relatedProduct in offerPayload
        this.payloadSpec.relatedProduct =
          this.payloadSpec.relatedProduct.concat(item.relatedProduct);

        // Extract the SKUs from relatedParty where the role is 'supplier'
        const relatedPartySKUs = item.relatedParty
          .filter((party) => normalize(party.role) == "supplier")
          .map((party) => normalize(party.sku));

        const matchedRelatedParty = item.relatedParty.filter((party) =>
          relatedPartySKUs.includes(normalize(party.sku))
        );
        this.payloadSpec.relatedParty =
          this.payloadSpec.relatedParty.concat(matchedRelatedParty);
        console.log(this.offerPayload.relatedParty, "result");
        // Return the mapped object with matched prodSpecCharValue
        return {
          _id: item._id,
          displayID: item.displayID,
          name: item.name,
          productOfferingPrice: item.productOfferingPrice,
          prodSpecCharValue: item.prodSpecCharValue.filter(
            (spec) =>
              normalize(spec.priceType) ===
              normalize(this.payloadSpec?.prodSpecCharValue?.[0]?.priceType)
          ),
        };
      });

      this.payloadSpec.bundledProductOffering =
        this.payloadSpec.bundledProductOffering.concat(result);
      return this.payloadSpec.bundledProductOffering;
      // console.log(result, "result 1.0");

      // this.aggregateSupplier(this.offerPayload.bundledProductOffering);
    },

    async aggregatePricingData(dataArray) {
      const result = dataArray.map((item) => {
        const price = item.productOfferingPrice[0] || {};
        return {
          _id: item._id,
          displayID: item.displayID,
          name: item.name,
          unitOfMeasure: item.unitOfMeasure || "Each",
          ...price,
        };
      });

      this.payloadSpec.prodSpecCharValue =
        this.payloadSpec.prodSpecCharValue.concat(result);
      this.payloadSpec.prodSpecCharValue = this.findDuplicates(
        this.payloadSpec.prodSpecCharValue
      );
      console.log(result, "result 1.0");
      return this.payloadSpec.prodSpecCharValue;
    },
    findDuplicates(array) {
      const uniqueItems = new Map();

      array.map((item) => {
        // Check if the object is empty
        if (Object.keys(item).length === 0) return;

        // Create a unique key based on _id and SKU
        const key = `${item._id}-${item.SKU}`;
        if (!uniqueItems.has(key)) {
          uniqueItems.set(key, item);
        }
      });

      return Array.from(uniqueItems.values());
    },
    async selectedBundleOffers(value) {
      console.log(value, "selectedBundleOffers activty log");

      let checkDuplicate = this.payloadSpec.bundledProductOffering;
      value = value.filter(
        (e) => !checkDuplicate.some((check) => check._id === e._id)
      );
      console.log(value, "selectedBundleOffers", this.offersList);
      this.payloadSpec.bundledProductOffering =
        await this.receivedSelectedOffers(value);
      const addOfferList = this.payloadSpec.bundledProductOffering;
      // this.payloadSpec.bundledProductOffering = this.payloadSpec.bundledProductOffering.concat(value);
      // console.log("selectedBundleOffers",this.payloadSpec.bundledProductOffering )
      // this.offersList = this.payloadSpec.bundledProductOffering
      this.offersList = addOfferList;

      this.payloadSpec.prodSpecCharValue = await this.aggregatePricingData(
        this.payloadSpec.bundledProductOffering
      );
      const selectedBundleOffers = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        this.payloadSpec
      );
      if (selectedBundleOffers.data && value.length > 0) {
        const addedProductSupplierLogPayload = {
          message: `Bundle item has been added`,
          detailedAction: value.map((value) => {
            return {
              item: value,
              action: "ADDED",
              property: "Bundle item",
            };
          }),
          action: "ADDED",
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          createdBy: this.$cookies.get("user"),
          ref: [this.payloadSpec?._id],
          "@type": "Offering-overview",
          category: ["productOffering"],
        };
        // const
        this.initLog(addedProductSupplierLogPayload);
      }
      this.refreshOffer();
      // this.bundleList = this.originalBundleList
      console.log(this.bundleList, "bundleList", this.originalBundleList);
      // this.updateBundleComponent+=1
      // this.payloadSpec.bundledProductOffering.push(value);
      // console.log(typeof(value), this.payloadSpec.bundledProductOffering.push(value),"selectedBundleOffers");
      // this.payloadSpec.prodSpecCharValue = this.payloadSpec.prodSpecCharValue.concat(value);
    },
    async selectedSku(value) {
      console.log(value, "selectedSku list 90");
      let idToRemove = value.displayID;
      this.payloadSpec.bundledProductOffering = this.reorderProdSpecCharValue(
        value,
        this.payloadSpec.bundledProductOffering
      );
      const index2 = this.payloadSpec?.prodSpecCharValue.findIndex(
        (item) => item.displayID == idToRemove
      );
      console.log(
        index2,
        "selected index sku 90",
        value,
        this.payloadSpec.prodSpecCharValue
      );
      if (index2 !== -1) {
        this.payloadSpec.prodSpecCharValue.splice(index2, 1); // Deletes the existing one
      }
      this.payloadSpec.prodSpecCharValue.push(value);

      console.log(this.payloadSpec.prodSpecCharValue, "selected sku12 90");
      const selectedSkuvalue = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        this.payloadSpec
      );
      if (selectedSkuvalue.data) {
        const mediaDeleteLogPayload = {
          message: `The SKU for the bundle item ${value?.name} has been changed to ${value?.SKU}`,
          detailedAction: [],
          "@type": "Offering-overview",
          category: ["productOffering"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          ref: [this.payloadSpec?.id],
          action: "UPDATE",
          status: "active",
        };
        this.initLog(mediaDeleteLogPayload);
      }
      this.refreshOffer();
    },
    reorderProdSpecCharValue(inputObj, productOfferingArray) {
      console.log(inputObj, productOfferingArray, "skuIndex 90");
      const product = productOfferingArray.find(
        (item) => item.name == inputObj.name
      );
      console.log(product, "skuIndex 90");
      if (product) {
        const skuIndex = product.prodSpecCharValue.findIndex(
          (charValue) => charValue.id == inputObj.id
        );
        console.log(skuIndex, "skuIndex 90");
        if (skuIndex > -1) {
          const [matchedSKU] = product.prodSpecCharValue.splice(skuIndex, 1);
          product.prodSpecCharValue.unshift(matchedSKU);
        }
      }

      return productOfferingArray;
    },

    // Call the function
    async removedOffers(value) {
      console.log(value, "removedOffers 90");
      let idToRemove = value._id;
      const index = this.payloadSpec?.bundledProductOffering.findIndex(
        (item) => item._id == idToRemove
      );
      console.log(
        index,
        "removedOffers 90",
        this.payloadSpec?.bundledProductOffering.length
      );
      if (this.payloadSpec?.bundledProductOffering.length > 1) {
        if (index !== -1) {
          this.payloadSpec?.bundledProductOffering.splice(index, 1);
          this.offersList.splice(index, 1);
        }
        const index2 = this.payloadSpec?.prodSpecCharValue.findIndex(
          (item) => item._id == idToRemove
        );

        if (index2 !== -1) {
          this.payloadSpec?.prodSpecCharValue.splice(index2, 1);
        }
        console.log(
          index,
          index2,
          this.payloadSpec.bundledProductOffering,
          this.payloadSpec?.prodSpecCharValue,
          "removedOffers"
        );

        const removedOffersValue = await UPCAxiosInstance.patch(
          `/catalogManagement/productOffering`,
          this.payloadSpec
        );
        if (removedOffersValue.data) {
          const mediaDeleteLogPayload = {
            message: `The bundle item ${value?.name} has been removed`,
            detailedAction: [],
            "@type": "Offering-overview",
            category: ["productOffering"],
            createdBy: this.$cookies.get("user"),
            user: this.$cookies.get("userName"),
            lastUpdate: new Date().toISOString(),
            ref: [this.payloadSpec?.id],
            action: "UPDATE",
            status: "active",
          };
          this.initLog(mediaDeleteLogPayload);
        }
        this.refreshOffer();
      } else {
        this.notification = {
          type: "success",
          message: `The bundle must contain at least one item.`,
        };
      }
      this.refreshOffer();
    },
    async bundleTotal() {
      // product offering price for bundle
      let total;
      if (this.discounttotal != 0) {
        total = Math.round(this.discounttotal * 100) / 100;
      } else {
        total = Math.round(this.totalPrice * 100) / 100;
      }
      this.bundleTotalcheck.priceType = "One time";
      this.bundleTotalcheck.tiers = [
        {
          min: 1,
          max: 99,
          price: {
            unit: this.$store?.getters?.getDefaultSettings?.currency?.unit,
            value: total || this.totalPrice,
          },
        },
      ];
      this.bundleTotalcheck.type = "Bundle";
      this.bundleTotalcheck.MSRP = {
        unit: this.$store?.getters?.getDefaultSettings?.currency?.symbol,
        value: this.totalPrice,
        discount: this.bundleAlteration,
      };

      this.payloadSpec.productOfferingPrice = [this.bundleTotalcheck];
      // console.log(this.payloadSpec.productOfferingPrice,"bundleTotalcheck");
      const updationPrice = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        this.payloadSpec
      );
      console.log(updationPrice.data.productOfferingPrice, "bundleTotalcheck");
    },
    mapCombination() {
      let missingAttributes = {};
      // Filter available objects based on selected price type
      let available = this.pricingData.filter(
        (item) => item.priceType === this.selectedPrice
      );

      // Define the reference object with default attributes
      let referenceObject = {
        id: "",
        validFor: {
          startDate: new Date().toISOString(),
          endDate: "9999-12-31T00:00:00Z",
        },
        SKU: "",
        lifecycleStatus: "Draft",
        MSRP: {
          unit: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          value: 0,
        },
        commitmentLength: 1,
        billingPeriod: "Monthly",
        displayValue: "Months",
        endOfCommitment: {
          autoPay: true,
          expireTerm: false,
        },
        tiers: [
          {
            min: 1,
            max: 99,
            price: {
              unit: this.$store?.getters?.getDefaultSettings?.currency?.unit,
              value: 0,
            },
          },
        ],
      };

      let updatedItems = available
        .map((item, index) => {
          if (
            item.SKU == this.pricingPanelData.SKU &&
            index != this.currentIndex
          ) {
            let hasUpdated = false;

            // Find the matching object in referenceObject
            Object.keys(item).map((key) => {
              if (!(key in referenceObject)) {
                missingAttributes[key] = item[key];
                console.log(missingAttributes, "Updated objects");
                hasUpdated = true;
              }
            });

            return hasUpdated ? missingAttributes : null;
          }
          return null;
        })
        .filter((item) => item !== null);
      console.log(updatedItems, "Updated objects 90");
      if (updatedItems.length > 0) {
        updatedItems[0].default = false;
        this.pricingPanelData = {
          ...this.pricingPanelData,
          ...updatedItems[0],
        };
        return updatedItems;
      } else {
        return this.pricingPanelData;
      }
    },
    isDateOverlap(startDate1, endDate1, startDate2, endDate2) {
      console.log(
        startDate1,
        endDate1,
        startDate2,
        endDate2,
        "extract it from the src"
      );
      return (
        (startDate1 <= endDate2 && endDate1 >= startDate2) ||
        (startDate2 <= endDate1 && endDate2 >= startDate1)
      );
    },
    checkOverlapv1() {
      // Filter the items based on the selected price type
      let filteredItems = this.pricingData.filter(
        (item) => item.priceType == this.selectedPrice
      );

      // Check for overlap in SKUs and their date ranges within the filtered items
      let overlapFound = filteredItems.some((item, index) => {
        if (
          item.SKU === this.pricingPanelData.SKU &&
          index !== this.currentIndex
        ) {
          console.log(
            index,
            this.currentIndex,
            item.priceType,
            this.selectedPrice,
            "extract it from the src"
          );

          let existingStartDate = new Date(item?.validFor?.startDate);
          let existingEndDate = new Date(item?.validFor?.endDate);

          // Check if date ranges overlap
          let overlap = this.isDateOverlap(
            new Date(this.pricingPanelData?.validFor.startDate),
            new Date(this.pricingPanelData.validFor.endDate),
            existingStartDate,
            existingEndDate
          );

          console.log(
            overlap,
            "extract it from the src",
            existingStartDate,
            existingEndDate
          );

          if (overlap) {
            console.log(
              "Date ranges overlap for the same product. Please adjust."
            );
            return true; // Exit the loop as overlap is found
          }
        }
        return false; // No overlap for this item
      });

      console.log(overlapFound, "overlapFound");

      // If overlap was found, stop further processing
      if (!this.startDateBoolean && overlapFound) {
        this.startDateBoolean = true;
        this.startDateMsg =
          "Date ranges overlap for the same product. Please adjust.";
        return; // You can handle further actions here if needed
      }

      // Continue with other logic if no overlap was found
      // this.startDateBoolean = false;
      // this.startDateMsg = "";
      this.priceValidation();

      console.log("No overlap detected. Proceeding with the process.");
    },
    checkOverlap() {
      // Filter the items based on the selected price type
      let filteredItems = this.pricingData.filter(
        (item) => item.priceType == this.selectedPrice
      );

      // Check for overlap in SKUs and their date ranges within the filtered items
      let overlapFound = filteredItems.some((item, index) => {
        if (
          item.SKU === this.pricingPanelData.SKU &&
          index !== this.currentIndex
        ) {
          console.log(
            index,
            this.currentIndex,
            item.priceType,
            this.selectedPrice,
            "Checking SKU overlap"
          );

          let existingStartDate = new Date(item?.validFor?.startDate);
          let existingEndDate = new Date(item?.validFor?.endDate);

          // Check if date ranges overlap for tangible products
          let overlap = false;
          if (this.productBaseType?.toLowerCase() == "tangible") {
            // For tangible products, check if date ranges overlap
            overlap = this.isDateOverlap(
              new Date(this.pricingPanelData?.validFor.startDate),
              new Date(this.pricingPanelData.validFor.endDate),
              existingStartDate,
              existingEndDate
            );
          } else {
            // For non-tangible products, allow multiple commitments as long as commitment periods differ
            let commitmentLengthMatches =
              item.billingPeriod === this.pricingPanelData.billingPeriod;
            console.log(
              item.billingPeriod,
              this.pricingPanelData.billingPeriod,
              "Checking commitment period overlap",
              commitmentLengthMatches
            );
            if (commitmentLengthMatches) {
              overlap = this.isDateOverlap(
                new Date(this.pricingPanelData?.validFor.startDate),
                new Date(this.pricingPanelData.validFor.endDate),
                existingStartDate,
                existingEndDate
              );
            } else {
              this.startDateBoolean = false;
              this.startDateMsg = "";
            }
          }

          console.log(
            overlap,
            "Checking date overlap",
            existingStartDate,
            existingEndDate
          );

          if (overlap) {
            console.log(
              "Date ranges overlap for the same product. Please adjust."
            );
            return true; // Exit the loop as overlap is found
          }
        }
        return false; // No overlap for this item
      });

      console.log(overlapFound, "overlapFound");

      // If overlap was found, stop further processing
      if (!this.startDateBoolean && overlapFound) {
        this.startDateBoolean = true;
        this.startDateMsg =
          "Date ranges overlap for the same product. Please adjust.";
        return; // You can handle further actions here if needed
      }

      // Continue with other logic if no overlap was found
      this.priceValidation();

      console.log("No overlap detected. Proceeding with the process.");
    },
    priceValidation() {
      console.log(
        this.productBaseType,
        "category incoming data lifecylcestatus"
      );
      if (this.productBaseType?.toLowerCase() === "tangible") {
        if (
          !this.endDateBoolean &&
          !this.startDateBoolean &&
          !this.skucheck &&
          this.pricingPanelData.SKU &&
          this.pricingPanelData.validFor.startDate &&
          this.pricingPanelData.validFor.endDate
        ) {
          console.log("yes");
          this.disableSave = false;
        } else {
          console.log("no else");

          this.disableSave = true;
        }
      } else {
        this.disableSave = false;
      }
    },

    focusOutStartDate(date) {
      console.log(date, " date incc");
      if (!date) {
        this.startDateBoolean = true;
        this.startDateMsg = "Please enter the date";
      }
      this.calenderValueStartDate(date);
      this.priceValidation();
    },
    focusOutEndDate(date) {
      if (!date) {
        this.disableSave = true;
        this.pricingPanelData.validFor.endDate = "9999-12-31T00:00:00Z";
        // this.endDateBoolean = true;
        // this.endDateMsg = "Please enter the date";
      }

      this.priceValidation();
    },
    calenderValueEndDate(data) {
      data;
      const endOfLifeDate = new Date(this.pricingPanelData.validFor.endDate)
        .toISOString()
        .split("T")[0];
      const endOfSupportDate = new Date(
        this.pricingPanelData.validFor.startDate
      )
        .toISOString()
        .split("T")[0];
      if (endOfLifeDate >= endOfSupportDate) {
        this.endDateBoolean = false;
        this.disableSave = false;

        console.log(data, "incoming data");
      } else {
        this.disableSave = true;

        this.endDateBoolean = true;
        this.endDateMsg = "End Date cannot be earlier than Start Date.";
        console.log(this.endDateMsg, "this.endDateMsg");
      }
      this.priceValidation();
      this.focusOutStartDate(this.pricingPanelData.validFor.startDate);
    },
    calenderValueStartDate(data) {
      console.log(data, "data incc");
      const endOfLifeDate = new Date(this.pricingPanelData.validFor.endDate)
        .toISOString()
        .split("T")[0];
      const endOfSupportDate = new Date(
        this.pricingPanelData.validFor.startDate
      )
        .toISOString()
        .split("T")[0];
      //  console.log(new Date(),new Date(data),"date new init",this.skucheck==false && new Date(data)<new Date() && new Date(this.pricingPanelData.validFor.endDate)<new Date())
      //   if(this.skucheck==false && new Date(data)<new Date() && new Date(this.pricingPanelData.validFor.endDate)<new Date()){

      //     this.pricingPanelData.lifecycleStatus = "Draft";

      //   }
      // else{
      //   this.pricingPanelData.lifecycleStatus = "Active";
      // }
      const startDate = this.normalizeDate(new Date(data));
      const endDate = this.normalizeDate(
        new Date(this.pricingPanelData.validFor.endDate)
      );
      const currentDate = this.normalizeDate(new Date());

      console.log(currentDate, startDate, endDate, "date validation check");

      if (this.skucheck === false) {
        if (startDate < currentDate && endDate < currentDate) {
          this.pricingPanelData.lifecycleStatus = "Inactive";
        } else if (startDate <= currentDate && endDate >= currentDate) {
          this.pricingPanelData.lifecycleStatus = "Active";
        } else if (startDate > currentDate || endDate < currentDate) {
          this.pricingPanelData.lifecycleStatus = "Draft";
        }
      } else {
        // Handle cases when skucheck is true (if necessary)
        console.log("SKU check is true, no status change.");
      }

      if (endOfSupportDate <= endOfLifeDate) {
        this.startDateBoolean = false;
        this.disableSave = false;
        this.startDateMsg = "";
        console.log(data, "incoming data lifecylcestatus if");
      } else {
        this.startDateBoolean = true;
        this.startDateMsg = "Start Date must be before or equal to End Date.";
        console.log(
          this.startDateMsg,
          this.startDateBoolean,
          "incoming data lifecylcestatus"
        );
      }
      // if (endOfSupportDate > endOfLifeDate) {
      //   this.startDateBoolean = true;
      //   this.startDateMsg = "Start Date must be before or equal to End Date.";
      //   console.log(this.startDateMsg, "this.endDateMsg");
      //   return
      // }
      this.priceValidation();
      this.checkOverlap();
    },
    normalizeDate(date) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    },
    handleDiscount1(val, index) {
      console.log("handleDiscount1...", val, index);
      this.handleDiscount(val);
    },
    handleDiscount(data) {
      this.bundleAlteration.discountType = data;
      console.log(data, "data check", this.bundleAlteration.discount);

      if (data.name == "Percentage") {
        console.log("Percentage if");
        this.totalPrice -=
          this.totalPrice * (this.bundleAlteration.discount / 100);
      } else {
        this.totalPrice -=
          this.bundleAlteration.discount ||
          this.payloadSpec?.productOfferingPrice[0]?.MSRP?.discount.discount;
      }

      this.discounttotal = this.totalPrice;

      this.bundleTotal();
    },

    commitmentValue1(val, index) {
      console.log("commitmentValue1...", val, index);
      this.commitmentValue(val);
    },

    commitmentValue(value) {
      this.bundleTotalcheck.commitmentLength = value;
      this.commitmentLength = value;

      if (this.bundleTotalcheck.billingPeriod == "Biweekly") {
        if (value % 2 != 0) {
          this.isCommitmentLengthValid = true;
          this.disableSave = true;
          this.commitmentMessage = "Commitment length should be multiple of 2";
        } else {
          this.isCommitmentLengthValid = false;
          this.disableSave = false;
          this.commitmentMessage = "";
        }
      } else if (this.bundleTotalcheck.billingPeriod == "BiMonthly") {
        if (value % 2 != 0) {
          this.isCommitmentLengthValid = true;
          this.disableSave = true;
          this.commitmentMessage = "Commitment length should be multiple of 2";
        } else {
          this.isCommitmentLengthValid = false;
          this.disableSave = false;
          this.commitmentMessage = "";
        }
      } else if (this.bundleTotalcheck.billingPeriod == "Quarterly") {
        if (value % 3 != 0) {
          this.isCommitmentLengthValid = true;
          this.disableSave = true;
          this.commitmentMessage = "Commitment length should be multiple of 3";
        } else {
          this.isCommitmentLengthValid = false;
          this.disableSave = false;
          this.commitmentMessage = "";
        }
      } else if (this.bundleTotalcheck.billingPeriod == "Semi Annually") {
        if (value % 6 != 0) {
          this.isCommitmentLengthValid = true;
          this.disableSave = true;
          this.commitmentMessage = "Commitment length should be multiple of 6";
        } else {
          this.isCommitmentLengthValid = false;
          this.disableSave = false;
          this.commitmentMessage = "";
        }
      }
      this.singular(
        this.bundleTotalcheck.displayValue,
        this.bundleTotalcheck.commitmentLength
      );
      this.bundleTotal();
    },

    async payloadSpec1(val) {
      console.log(" totalPrice...payload overview...", val);
      this.payloadSpec = JSON.parse(JSON.stringify(val));
      const updateOffer = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        val
      );
      console.log(updateOffer.data, "totalPrice updated..");
      // this.refreshOffer();
    },

    bundleTotalcheck1(val) {
      console.log("bundleTotalcheck1...", val);
    },

    processedSupplierData() {
      console.log(this.supplierData);
      if (this.supplierData.length != 0) {
        console.log("supplierTableImage run processedSupplierData if");
        // const clonedData = JSON.parse(JSON.stringify(this.supplierTableData));
        return this.supplierTableImage(this.supplierTableData);
      } else {
        console.log("supplierTableImage run processedSupplierData else");
        return [];
      }
    },
    checkSKU(sku) {
      let foundSKU = this.isFreshCombination(
        this.pricingPanelData,
        this.pricingData,
        this.pricingKey
      );
      console.log(
        this.pricingPanelData,
        "inside the checkSKU",
        this.pricingKey,
        foundSKU
      );
      let items = this.pricingData.filter(
        (item) => item.priceType == this.selectedPrice
      );
      let status = items.some(
        (item) =>
          item.SKU === sku &&
          item.priceType == this.selectedPrice &&
          items[this.currentIndex].SKU != sku &&
          item.lifecycleStatus == "Active" &&
          this.pricingPanelData.lifecycleStatus == "Active"
      );
      console.log(status, "status of state");
      if (status == true) {
        this.disableSave = true;
        // this.skucheck = true;
        // this.statusMsg = "An active SKU already exists.";
        this.pricingPanelData.lifecycleStatus = "Draft";
      } else {
        this.skucheck = false;
        this.statusMsg = "";
      }
      if (foundSKU) {
        this.disableSave = true;
        this.skucheck = true;
        this.statusMsg = "An active SKU already exists.";
      } else {
        this.skucheck = false;
        this.statusMsg = "";
        this.checkOverlap();
        this.priceValidation();
        this.calenderValueStartDate(this.pricingPanelData.validFor.startDate);
      }
    },
    checkSKUStaus(sku) {
      // let items = this.pricingData;
      let items = this.pricingData.filter(
        (item) => item.priceType == this.selectedPrice
      );

      items.some((item) => {
        console.log(
          item.SKU,
          item.priceType,
          item.lifecycleStatus,
          item.SKU === sku && item.lifecycleStatus == "Active",
          "item.SKU === sku && item.lifecycleStatus == Active"
        );
        item.SKU === sku && item.lifecycleStatus == "Active";
      });

      let status = items.some(
        (item, index) =>
          item.SKU === sku &&
          item.priceType == this.selectedPrice &&
          items[this.currentIndex] != index &&
          item.lifecycleStatus == "Active" &&
          this.pricingPanelData.lifecycleStatus == "Active"
      );
      console.log(status, "status on click");
      if (status == true) {
        this.disableSave = true;
        this.skucheck = true;
        this.statusMsg = "An active SKU already exists.";
      } else {
        this.skucheck = false;
        this.statusMsg = "";
      }
      this.checkOverlap();
      this.priceValidation();
    },
    findDefault() {
      const defaultValues = this.payloadSpec?.prodSpecCharValueUse.reduce(
        (acc, item) => {
          if (item?.configurable) {
            if (
              item?.default?.value == null &&
              item?.productSpecCharacteristicValue?.length > 0
            ) {
              const firstValue = item.productSpecCharacteristicValue[0];
              acc[item.name] =
                firstValue.value + " " + (firstValue.valueSuffix || "");
            } else {
              let value =
                item.default.value + " " + (item.default.valueSuffix || "");
              acc[item.name] = value.trim();
            }
          }
          return acc;
        },
        {}
      );

      console.log(defaultValues, "matchedObject 00.0");

      return defaultValues;
    },
    async mapdefault(data) {
      let inp1 = await this.findDefault();
      let inp2 = data;
      const matchedObject = await inp2.find((item) => {
        return Object.keys(inp1).every(
          (key) => item[key]?.trim() == inp1[key]?.trim()
        );
      });

      console.log(await matchedObject, "matchedObject 💥");
      if (matchedObject != undefined && matchedObject != null) {
        this.payloadSpec.productOfferingPrice = [matchedObject];
        await UPCAxiosInstance.patch(`/catalogManagement/productOffering`, {
          ...this.payloadSpec,
          id: this.payloadSpec._id,
        });
      } else {
        if (this.payloadSpec.prodSpecCharValue.length > 0) {
          this.payloadSpec.productOfferingPrice = [
            this.payloadSpec.prodSpecCharValue[0],
          ];
          await UPCAxiosInstance.patch(`/catalogManagement/productOffering`, {
            ...this.payloadSpec,
            id: this.payloadSpec._id,
          });
        } else {
          this.payloadSpec.productOfferingPrice = [data[0]];
          await UPCAxiosInstance.patch(`/catalogManagement/productOffering`, {
            ...this.payloadSpec,
            id: this.payloadSpec._id,
          });
        }
      }
      // this.payloadspec.productOfferingPrice.push(matchedObject);
      console.log(this.payloadSpec.productOfferingPrice, "matchedObject 💥");
    },
    statusEmitter(val) {
      console.log(
        val.name,
        "addPricing statusEmitter",
        this.pricingPanelData.SKU
      );
      this.pricingPanelData.lifecycleStatus = val.name;
      this.checkSKUStaus(this.pricingPanelData.SKU);
      // this.checkSKU(this.pricingPanelData.SKU);
    },
    async searchSupplierMethod(val) {
      this.searchValue = val;
    },

    async fetchSuppliers(val) {
      console.log("searchSupplier ", val);

      const filterData = {
        company: this.company,
        type: "search",
        module: "suppliers",
        isPaginated: true,
        lastUpdated: new Date().toJSON(),
        paginatedQuery: {
          rowsPerPage: this.rowsPerPage,
          currentPage: this.currPage,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 2,
        filterQuery: {},
        searchQuery: val,
        searchList: [{ customName: { $regex: val, $options: "i" } }],
      };

      try {
        const response = await MLTAxiosInstance.post(
          `util/filterData?company=${this.company}`,
          filterData
        );

        if (response.data && Array.isArray(response.data.data)) {
          this.supplierMain = response.data.data;
          console.log("Add supplier fetched", this.supplierMain);
          let filterSupplierData = this.supplierMain.filter((item) => {
            console.log(item.customName);
            return (
              item.customName &&
              typeof item.customName === "string" &&
              item.customName.trim() !== ""
            );
          });

          console.log("filterSupplierData", filterSupplierData);
          console.log("this.tableData", this.tableData);
          this.supplier = filterSupplierData.filter((ele) => {
            return !this.tableData.some((val) => val._id === ele._id);
          });

          console.log(
            "Add supplier fetched supplier before checked",
            this.supplier
          );
          // this.supplier = this.supplier.filter(e =>
          //     !this.selectedSuppliers.some(ele => ele.id === e.id)
          // );
          console.log(
            "Add supplier fetched supplier before checked",
            this.supplier
          );
          this.selectedSuppliers.length > 0
            ? this.selectedSuppliers.forEach((e) => this.supplier.push(e))
            : this.supplier;
          this.supplier.forEach((item, index, self) => {
            const firstIndex = self.findIndex((t) => t.id === item.id);
            if (firstIndex !== index) {
              item.checked = true;
              self[firstIndex].checked = true;
            }
          });

          this.supplier = this.supplier.filter((item, index, self) => {
            const firstIndex = self.findIndex((t) => t.id === item.id);
            return firstIndex === index;
          });

          console.log("Add supplier fetched supplier", this.supplier);
        } else {
          console.error(
            "Expected an object with a data array, but received:",
            response.data
          );
        }
      } catch (error) {
        console.error("Error fetching supplier data:", error);
      }
    },
    async addTagList(e) {
      let obj = {};
      obj.name = e.name;
      obj.module = "sm";
      await DMNAxiosInstance.post(`mapRef/tagsList`, obj);
      // const rootName = this.$cookies.get("rootName");

      // const tagsListurl =
      //   rootName === "Halleyx"
      //     ? "mapRef/collection/statuslist"
      //     : `mapRef/collection/statuslist?company=${this.$cookies.get(
      //         "rootName"
      //       )}`;

      let tagsList = await this.getReferanceData("statuslist");
      let arr = this.newSupplierPayload.tags;
      arr.push(e.name);
      this.newSupplierPayload.tags = arr;
      this.tagsList = tagsList.filter((item) => {
        return item.module === "po";
      });
    },
    receivedTags(val) {
      console.log(val, "receivedTags");
      let arr = [];
      val.filter((item) => {
        if (item.checked == true) {
          // console.log('received',item.name);
          arr.push(item.name);
        }
      });
      this.payloadSpec.tags = arr;
    },
    cancelPricing() {
      this.delete_modal_active = false;
      this.refreshOffer();
    },
    async refreshOffer() {
      let productSpec = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/${this.$route.params.id}`
      );
      this.name = productSpec.data.name;
      this.payloadSpec = {
        ...this.payloadSpec,
        ...productSpec.data,
        id: productSpec.data?._id,
      };
      this.pricingData = this.payloadSpec.prodSpecCharValue;
      // this.offersList = this.payloadSpec?.bundledProductOffering;
    },
    async checkOrderAvailabilty() {
      console.log(
        "🚀 ~ isNoOrderFound ~ isNoOrderFound:",
        "isNoOrderFound",
        this.leftpannel
      );
      if (this.leftpannel != "Orders") return false;
      const orderData = await UPCAxiosInstance.post(
        `/util/filterData`,
        this.orderFilterOptions
      );
      console.log("🚀 ~ isNoOrderFound ~ orderData:", orderData);
      if (
        orderData.data.data.length === 0 &&
        this.orderFilterOptions.paginatedQuery.currentPage === 1 &&
        this.orderSearchValue === ""
      ) {
        return true;
      }
      return false;
    },

    focusOutCommitmentLength(value) {
      if (this.pricingPanelData.billingPeriod == "Biweekly") {
        if (value % 2 != 0) {
          this.isCommitmentLengthValid = true;
          this.disableSave = true;
          this.commitmentMessage = "Commitment length should be multiple of 2";
        } else {
          this.isCommitmentLengthValid = false;
          this.disableSave = false;
        }
      } else if (this.pricingPanelData.billingPeriod == "BiMonthly") {
        if (value % 2 != 0) {
          this.isCommitmentLengthValid = true;
          this.disableSave = true;
          this.commitmentMessage = "Commitment length should be multiple of 2";
        } else {
          this.isCommitmentLengthValid = false;
          this.disableSave = false;
        }
      } else if (this.pricingPanelData.billingPeriod == "Quarterly") {
        if (value % 3 != 0) {
          this.isCommitmentLengthValid = true;
          this.disableSave = true;
          this.commitmentMessage = "Commitment length should be multiple of 3";
        } else {
          this.isCommitmentLengthValid = false;
          this.disableSave = false;
        }
      } else if (this.pricingPanelData.billingPeriod == "Semi Annually") {
        if (value % 6 != 0) {
          this.isCommitmentLengthValid = true;
          this.disableSave = true;
          this.commitmentMessage = "Commitment length should be multiple of 6";
        } else {
          this.isCommitmentLengthValid = false;
          this.disableSave = false;
        }
      }
    },
    singular(prop, valu) {
      console.log("prop singular", prop, valu);
      if (valu == 1 || valu == 0) {
        return this.makeWordsSingular(prop);
      } else {
        return prop;
      }
    },
    makeWordsSingular(value) {
      if (value == "Years") {
        return "Year";
      } else if (value == "Months") {
        return "Month";
      } else if (value == "Weeks") {
        return "Week";
      } else {
        return value;
      }
    },
    async regiondelete1(val) {
      console.log("regiondata", val?.regionname, val);

      const regionremoved = this.regionDatas.place.filter(
        (item) => item.region !== val?.regionname
      );

      console.log("regionremoved", regionremoved);

      const delete_patch = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        { place: regionremoved, id: this.payloadSpec._id }
      );
      console.log("delete_work", delete_patch, regionremoved);

      this.regionDatas.place = regionremoved;
      console.log("checking------------", this.regionDatas);

      //service

      this.regionDatas.name;

      const response = await UPCAxiosInstance.get(`/productQualification`);
      console.log(
        "response",
        response.data,
        this.payloadSpec._id,
        this.payloadSpec.name
      );

      //delete in service qualification
      response?.data?.forEach(async (element) => {
        console.log("element", element);
        if (val?.regionname === element?.place[0]?.region) {
          element.id;
          const deleteresponse = await UPCAxiosInstance.delete(
            `/productQualification/${element.id}`
          );
          console.log("deleteresponse", deleteresponse);
        }
      });
    },

    isEqualCharacteristicData(arr1, arr2) {
      if (arr1.length !== arr2.length) return false;

      // Sort both arrays by name for consistent comparison
      const sortedArr1 = arr1
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name));
      const sortedArr2 = arr2
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name));

      // Compare sorted arrays
      for (let i = 0; i < sortedArr1.length; i++) {
        if (
          sortedArr1[i].name !== sortedArr2[i].name ||
          sortedArr1[i].value !== sortedArr2[i].value
        ) {
          return false;
        }
      }

      return true;
    },
    async editedDependentProduct(val) {
      console.log("edited-dependent-products ", val);
      val = val.map((e) => {
        return {
          ...e,
          destinationCategory: JSON.stringify(e.destinationCategory),
          destinationProductOffers: JSON.stringify(e.destinationProductOffers),
          rootCategory: JSON.stringify(e.rootCategory),
          sourceCategory: JSON.stringify(e.sourceCategory),
          skuNumber: JSON.stringify(e.skuNumber),
        };
      });
      let flow = await DMNAxiosInstance.patch(
        `mapRef/updateManyCollection/productDependency`,
        { data: val }
      );
      if (flow) {
        this.refreshDependentFlow();
      }
      console.log("edited-dependent-products ", flow);
    },
    async addDependentProducts(val) {
      console.log("this.addDependentProducts", val);
      console.log(val[0]?.category);
      const transform = val.map((product) => {
        this.dependentIndex = 0;
        const transformedProduct = {
          root: this.$route.params.id,
          rootName: this.payloadSpec?.name,
          rootType: "Offer",
          rootCategory: JSON.stringify(this.payloadSpec.category),

          // source: "Dependent product 2 Case", Only applicable for offer id
          // this.dependentIndex ===0 ? "offer":
          sourceType: "category",
          source:
            this.dependentIndex >= 1
              ? this.$route.params.id
              : val[this.dependentIndex - 1]?.sourceCategory,
          sourceName:
            this.dependentIndex >= 1
              ? this.payloadSpec?.name
              : this.leaf(val[this.dependentIndex - 1]?.sourceCategory),
          sourceCategory: JSON.stringify(
            this.dependentIndex <= 0
              ? this.payloadSpec.category
              : val[this.dependentIndex - 1]?.sourceCategory
          ),

          minimumQuantity: product.minimumQuantity,
          maximumQuantity: product.maximumQuantity,
          skuNumber: JSON.stringify(product.skuNumber),
          description: product.description,
        };
        console.log(
          "val[this.dependentIndex-1].destinationName",
          val[this.dependentIndex - 1]?.product?.category
        );
        console.log(val[this.dependentIndex - 1]?.category);
        this.dependentIndex++;
        return transformedProduct;
      });
      console.log("addDependentProducts Index", this.dependentIndex);
      this.dependentProductsData = [];
      this.dependentProductsData = this.offerDependentData.concat(
        this.dependentProductsData
      );
      this.dependentProductsData = this.dependentProductsData.slice(0, 1);
      console.log("dependentProductsData two", this.dependentProductsData);
      console.log("dependentProductsData transform", transform);
      this.dependentProductsData = this.dependentProductsData.concat(transform);
      console.log("dependentProductsData", this.dependentProductsData);
      let flow = await DMNAxiosInstance.post(
        `mapRef/addMany/productDependency`,
        //   // productDependency:{
        transform
      );
      // if(flow){
      // this.refreshDependentFlow()
      // }
      console.log("flow", flow);
      console.log("this.payload dependent", this.payloadSpec);
    },
    async refreshDependentFlow() {
      this.dependentProductsData = [];
      let dependent = await DMNAxiosInstance.post(
        `/rule_engine/rule/execute/66a3552a159c4114bd9e8f78`,
        {
          payload: {
            productDependency: {
              root: this.$route.params.id,
            },
          },
        }
      );
      console.log(
        "dependent dependent",
        dependent?.data?.data?.productDependency?.dependency
      );

      let dependentProductsMounted =
        dependent?.data?.data?.productDependency?.dependency;
      console.log("dependent dependent", dependentProductsMounted.length);
      if (
        // dependentProductsMounted.length <= 0 &&
        typeof this.payloadSpec === "object" &&
        Array.isArray(this.payloadSpec.prodSpecCharValueUse)
      ) {
        try {
          {
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = this.payloadSpec?.description;
            console.log("this.payloadSpec", tempDiv);
            let textContent = tempDiv.textContent || tempDiv.innerText || "";
            if (textContent === undefined || textContent === "undefined") {
              textContent = "";
            }
            console.log("this.payloadSpec", textContent);
            this.offerDependentData.push({
              type: "dependson",
              sourceCategory: this.payloadSpec?.name,
              description: textContent.trim(),
              destinationCategory: JSON.stringify(this.payloadSpec?.category),
              minimumQuantity: this.payloadSpec.prodSpecCharValue[0]?.tiers
                ?.map((e) => e.min)
                .toString(),
              maximumQuantity: this.payloadSpec.prodSpecCharValue[0]?.tiers
                ?.map((e) => e.max)
                .toString(),

              // tangOrIntang:this.payloadSpec?.productSpecification?.["@baseType"],
              skuNumber: JSON.stringify([
                ...new Set(
                  this.payloadSpec.prodSpecCharValue
                    .map((v) => v.SKU)
                    .filter((sku) => sku !== undefined && sku !== "")
                ),
              ]),
              skuName: this.payloadSpec.prodSpecCharValue
                .map((v) => v.name)
                .filter((sku) => sku !== undefined && sku !== ""),
            });
            if (this.offerDependentData.length > 0) {
              this.dependentProductsData.push(this.offerDependentData[0]);
            }
            console.log("this.payloadSpec top", this.dependentProductsData);
            // this.dependentProductsData = this.dependentProductsData.concat(
            //   this.offerDependentData
            // );
            console.log("this.payloadSpec top 2", this.dependentProductsData);
            // this.dependentProductsData.unshift(this.offerDependentData)
            console.log("this.payloadSpec top 3", this.dependentProductsData);
            if (dependentProductsMounted.length > 0) {
              dependentProductsMounted.destinationCategory
                ? JSON.parse(dependentProductsMounted?.destinationCategory)
                : {};
              dependentProductsMounted.skuNumber
                ? JSON.parse(dependentProductsMounted?.skuNumber)
                : {};
              dependentProductsMounted.destinationProductOffers
                ? JSON.parse(dependentProductsMounted?.destinationProductOffers)
                : {};
              this.dependentProductsData = [
                ...this.dependentProductsData,
                ...dependentProductsMounted,
              ];
              console.log("dependent dependent", this.dependentProductsData);
              //     console.log("dependent dependent offerDependentData",this.offerDependentData)
              // this.dependentProductsData=this.dependentProductsData.concat(this.offerDependentData);
            }
          }
        } catch (e) {
          console.log("payloadSpec not object", e);
        }
      }
    },
    async deleteDependentProduct(val) {
      console.log("dependentProductsData two", val);
      console.log([{ _id: val._id }]);
      const response = await DMNAxiosInstance.delete(
        `/mapRef/removeMany/productDependency`,
        { data: [{ _id: val._id }] }
      );
      // this.dependentProductsData = val;
      // console.log(val, "deleteDependentProduct");
      console.log(response, "deleteDependentProduct");
      console.log(response.data, "deleteDependentProduct");
      if (response) {
        this.refreshDependentFlow();
      }
    },
    formatPriceRange(prices) {
      const values = prices.map((price) => price.value);
      let minValue = Math.min(...values) || 0;
      let maxValue = Math.max(...values) || 0;
      minValue = isNaN(minValue) || Infinity ? minValue : 0;
      maxValue = isNaN(maxValue) || Infinity ? maxValue : 0;
      // maxValue === Infinity
      console.log("currencyFormatter", maxValue);
      console.log("currencyFormatter", minValue);
      if (minValue === maxValue) {
        console.log(
          "currencyFormatter",
          this.currencyFormatter(maxValue, {
            currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          })
        );
        return this.currencyFormatter(maxValue, {
          currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
        });
      } else {
        console.log(
          "currencyFormatter",
          this.currencyFormatter(minValue, {
            currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          }) -
            this.currencyFormatter(maxValue, {
              currency:
                this.$store?.getters?.getDefaultSettings?.currency?.unit,
            })
        );
        return (
          this.currencyFormatter(minValue, {
            currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          }) +
          " - " +
          this.currencyFormatter(maxValue, {
            currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          })
        );
        // return ( (this.currencyFormatter(minValue,{currency:prices[0].unit}))-(this.currencyFormatter(maxValue,{currency:prices[0].unit})))
      }
    },
    async generateContentWithAI(type, prompt = "") {
      console.log("overview123 generateContentWithAI", type, "===", prompt);

      console.log(
        "🚀 ~ generateContentWithAI ~ type, prompt:----",
        type,
        "=====",
        prompt
      );
      // if (type && type == "Close") { by asif
      if (type === "Close") {
        if (this.payloadSpec?.offerType == "Atomic") {
          this.isBasedOnFeature = true;
        } else if (this.payloadSpec?.offerType == "Bundle") {
          this.isBasedOnProduct = true;
        }
        // this.isBasedOnFeature = true; by asif
        this.isBasedOnPrompt = false;
        return;
      }
      if (
        (prompt?.trim() == "" || this.disableAIToolBarButton) &&
        (type == "from prompt" || type == "generate using prompt")
      )
        return;
      if (type && type == "Based on feature") {
        this.disableAIToolBarButton = true;
        this.isBasedOnFeature = true;
        this.isBasedOnPrompt = false;
        this.AIChoicesForDescription = [];
      } else if (type && type == "Based on product") {
        this.disableAIToolBarButton = true;
        this.isBasedOnProduct = true;
        this.isBasedOnPrompt = false;
        this.AIChoicesForDescription = [];
      } else if (type && type == "Using prompt") {
        this.isBasedOnFeature = false;
        this.isBasedOnProduct = false;
        this.isBasedOnPrompt = true;
        this.AIChoicesForDescription = [];
        return;
      }
      if (this.AIChoicesForDescription.length && type == "regenerate") {
        // this.disableAIToolBarButton = false;
        let descriptionIndex = this.AIChoicesForDescription.findIndex(
          (item) =>
            item.message.content?.trim() == this.payloadSpec.description?.trim()
        );
        if (
          descriptionIndex != -1 &&
          descriptionIndex < this.AIChoicesForDescription.length - 1
        ) {
          this.texteditorVal =
            this.AIChoicesForDescription[descriptionIndex + 1].message.content;
          this.payloadSpec.description =
            this.AIChoicesForDescription[descriptionIndex + 1].message.content;
        } else if (
          descriptionIndex == this.AIChoicesForDescription.length - 1 ||
          descriptionIndex == -1
        ) {
          this.texteditorVal = this.AIChoicesForDescription[0].message.content;
          this.payloadSpec.description =
            this.AIChoicesForDescription[0].message.content;
        }
        return;
      }
      this.disableAIToolBarButton = true;

      // Prepare the payload
      let features = this.payloadSpec.prodSpecCharValueUse.map((item) => [
        item.name,
        item.productSpecCharacteristicValue.map(
          (val) => val.value + " " + val.valueSuffix
        ),
      ]);
      features = Object.fromEntries(features);
      features = JSON.stringify(features, null, 2);
      console.log("🚀 ~ generateContentWithAI ~ features:", features);
      // product name
      let productName = this.payloadSpec.name
        ? this.payloadSpec.name
        : this.spec.name;

      let bundleOffer = [];
      let content = null;
      if (this.payloadSpec?.offerType == "Bundle") {
        bundleOffer = this.payloadSpec?.bundledProductOffering?.map(
          (offer) => offer.name
        );

        content = `Create a detailed product offering description for PRODUCT with below BUNDLE PRODUCT, PRODUCT - ${productName}, BUNDLE PRODUCT - ${bundleOffer}`;

        console.log("overview123 type", type);
        console.log("overview123 features", features);
        console.log("overview123 bundle", bundleOffer);
        console.log("overview123 this.payloadSpec", this.payloadSpec);
      } else {
        content = `Create a detailed product offering description for PRODUCT with below FEATURES,FEATURES - ${features},PRODUCT - ${productName}`;
      }

      let chatCompletionPayload = {};

      if (type == "Based on feature") {
        chatCompletionPayload = {
          prompt: [
            {
              role: "system",
              content:
                "You are a helpfull assistant to generate description for product offering as HTML tags using product info.Be descriptive in business friendly manner or professional.Format the content based on business friendly example) PRODUCTNAME as h2 tag,and style the features keep both as on attractive business format",
            },
            {
              role: "user",
              content: content,
            },
          ],
          numberOfChoices: 10,
        };
      } else if (type == "Based on product") {
        chatCompletionPayload = {
          prompt: [
            {
              role: "system",
              content:
                "You are a helpfull assistant to generate description for product offering as HTML tags using product info.Be descriptive in business friendly manner or professional.Format the content based on business friendly example) PRODUCTNAME as h2 tag,and style the features keep both as on attractive business format",
            },
            {
              role: "user",
              content: content,
            },
          ],
          numberOfChoices: 10,
        };
      } else if (type == "generate using prompt") {
        console.log("🚀 ~ generateContentWithAI ~ prompt:", prompt);
        if (!prompt) return "Please enter a prompt";
        if (
          [
            "product",
            "offer",
            "this",
            "feature",
            "characteristic",
            "spec",
          ].some((substring) => prompt.includes(substring))
        ) {
          prompt += "PRODUCT - " + productName + ",FEATURES - " + features;
        }
        chatCompletionPayload = {
          prompt: [
            {
              role: "system",
              content:
                "You are a helpfull assistant to generate description for product offering as HTML tags using product info.Be descriptive in business friendly manner or professional.Format the content based on business friendly example) PRODUCTNAME as h2 tag,and style the features keep both as on attractive business format",
            },
            {
              role: "user",
              content: prompt,
            },
          ],
          numberOfChoices: 10,
        };
      }
      if (Object.keys(chatCompletionPayload).length == 0) return;
      let AIChatResponse = await DMNAxiosInstance.post(
        `/GenAI/chat`,
        chatCompletionPayload
      );

      this.AIChoicesForDescription = AIChatResponse.data;
      this.texteditorVal = AIChatResponse.data[0].message?.content;
      this.payloadSpec.description = AIChatResponse.data[0].message?.content;
      this.disableAIToolBarButton = false;
      console.log(
        "🚀 ~ generateContentWithAI ~ AIChatResponse:",
        AIChatResponse
      );
    },
    handleGeneratorAction(from) {
      console.log("overview123 handleGeneratorAction", from);

      if (from && from == "from prompt") {
        this.$nextTick(() => {
          this.aiPromptContextMenuPositionLeft =
            document
              .getElementById("prompt-action-menu")
              ?.getBoundingClientRect().right + 10;
          this.aiPromptContextMenuPositionTop =
            document
              .getElementById("prompt-action-menu")
              ?.getBoundingClientRect().top - 40;
          this.isAIPromptContextMenuVisible =
            !this.isAIPromptContextMenuVisible;
          console.log(
            "🚀 ~ this.$nextTick ~  document.getElementById(prompt-action-menu)?.getBoundingClientRect():",
            document
              .getElementById("prompt-action-menu")
              ?.getBoundingClientRect()
          );
        });
        return;
      }
      this.$nextTick(() => {
        this.aiContextMenuPositionLeft =
          document.querySelector(".--generator-button")?.getBoundingClientRect()
            .left + 25;
        this.aiContextMenuPositionTop =
          document.querySelector(".--generator-button")?.getBoundingClientRect()
            .top - 80;
        this.isAIContextMenuVisible = !this.isAIContextMenuVisible;
        console.log(
          "🚀 ~ this.$nextTick ~  document.querySelector(.--generator-button)?.getBoundingClientRect():",
          document.querySelector(".--generator-button")?.getBoundingClientRect()
        );
      });
    },
    async getIntangibleSupplierData(value) {
      let firstArray = [];
      const firstOccurrences = {};
      value.forEach((item) => {
        if (!firstOccurrences[item.name]) {
          firstOccurrences[item.name] = item;
        }
      });
      console.log("firstOccurrences", firstOccurrences);
      firstArray = Object.values(firstOccurrences);

      console.log("firstArray", firstArray);
      return firstArray;
    },

    async refreshPayload() {
      let productSpec = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/${this.$route.params.id}`
      );
      this.productName = productSpec.data?.name;

      this.payloadSpec = {
        ...this.payloadSpec,
        ...productSpec.data,
        id: productSpec.data?._id,
      };
    },
    async deleteSupplierModal() {
      let i = this.delete_supplier_data;
      this.delete_supplier_modal = false;
      this.fromFeatureChanged = true;
      // console.log(i,"yes supplierData")
      console.log(i, "deleteSupplierModalsupplier", Array.isArray(i));
      // if(i) return
      // this.supplierOriginalData = this.supplierOriginalData.filter(
      //   (item) => item.role?.toLowerCase() == "supplier"
      // );
      console.log(
        " this.supplierOriginalData deleteSupplierModal ",
        this.supplierOriginalData
      );
      // 7288 - 7302 newly added
      if (this.fromFeatureChanged && Array.isArray(i)) {
        console.log(this.fromFeatureChanged, "fromIf", this.fromFeatureChanged);
        this.supplierOriginalData = this.supplierOriginalData.filter(
          (value) => {
            return !i.some(
              (item) =>
                item.id === value.id && item.priceType === value.priceType
            );
          }
        );
        this.fromFeatureChanged = false;

        console.log("Updated i", i);
      } else if (!Array.isArray(i)) {
        console.log(
          this.fromFeatureChanged,
          "fromIf fromElse",
          this.fromFeatureChanged
        );
        this.supplierOriginalData = this.supplierOriginalData.filter(
          (value) => {
            return (
              i.name !== value.name ||
              i.sku !== value.sku ||
              i.id !== value.id ||
              i?.place?.description !== value?.place?.description
            );
          }
        );
        this.fromFeatureChanged = true;
      }
      console.log(
        " this.supplierOriginalData deleteSupplierModal ",
        this.supplierOriginalData
      );
      const updateRelatedParty = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        {
          id: this.payloadSpec._id,
          relatedParty: this.supplierOriginalData,
        }
      );
      console.log("updateRelatedParty 123123", updateRelatedParty, i);
      const removedProductSupplierLogPayload = {
        message: `${i.name} SKU ${i?.sku} supplier has been deleted`,
        detailedAction: [],
        "@type": "Offering-media",
        category: ["productOffering", "supplier"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: [this.payloadSpec?.id, i?.externalID],
        action: "REMOVED",
      };
      if (Object.keys(i).length === 0) {
        console.log("The object is empty.");
      } else {
        console.log("The object is not empty.");
        this.initLog(removedProductSupplierLogPayload);
      }

      console.log(
        "loading media in all deleteSupplierModal",
        updateRelatedParty?.data?.prodSpecCharValue
      );

      let productdatafull = await UPCAxiosInstance.get(
        `/productStock?company=${i.name}`
      );
      console.log(productdatafull?.data, "supplierStock 12");
      productdatafull?.data.map((e) => {
        if (
          e.stockedProduct.id === i.id &&
          e?.place?.description === i?.place?.description
        ) {
          console.log("supplierStock check ", e);
          const deleteSupplier = UPCAxiosInstance.delete(
            `/productStock/${e.id}?company=${i?.name}`
          );
          console.log("deleteSupplier from SM", deleteSupplier);
        }
      });

      if (Array.isArray(i)) {
        console.log("Updated in if condition", i);
        i.forEach(async (obj) => {
          console.log("Obj name ", obj.name);

          let productdatafull = await UPCAxiosInstance.get(
            `/productStock?company=${obj.name}`
          );
          console.log(productdatafull?.data, "supplierStock 12");

          productdatafull?.data.forEach(async (e) => {
            if (e.stockedProduct.id === obj.id) {
              console.log("supplierStock check ", e);
              const deleteSupplier = await UPCAxiosInstance.delete(
                `/productStock/${e.id}?company=${obj.name}`
              );
              console.log("deleteSupplier from SM", deleteSupplier);
            }
          });
        });
      }

      // await productdatafull?.data.forEach(async (item) => {
      //   console.log("supplierStock 12", item.stockedProduct?.id);
      //   console.log(i, "12");
      //   if (item.stockedProduct?.id == i.relatedParty?.id) {
      //     const deleteSupplier = await UPCAxiosInstance.delete(
      //       `/productStock/${item?.id}?company=${i.relatedParty?.name}`
      //     );
      //     console.log(deleteSupplier, "12 `");
      //   }
      // });

      this.refreshSupplier();
      if (updateRelatedParty && this.fromFeatureChanged) {
        this.notification = {
          type: "success",
          message: `Done! Your item has been removed.`,
        };
      }
    },
    async activitycurrentpage1(val) {
      this.activitycurrentpage = val;
      this.offerActivity.paginatedQuery.currentPage = val;
      let activitylogdata = await MLTAxiosInstance.post(
        `util/filterData`,
        this.offerActivity
      );

      this.logData = activitylogdata.data.data;
      this.activitytotal = activitylogdata.data.total;
    },
    async activityrowpage1(val) {
      this.activityrowpage = val;
      this.offerActivity.paginatedQuery.rowsPerPage = val;
      let activitylogdata = await MLTAxiosInstance.post(
        `util/filterData`,
        this.offerActivity
      );
      console.log(
        activitylogdata.data.data,
        "123 activity log response data in offer2 row"
      );

      this.logData = activitylogdata.data.data;
      this.activitytotal = activitylogdata.data.total;
    },
    findAndGetLeadTime() {
      // let leadTime =
    },
    async refreshSupplier() {
      let productSpec = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/${this.$route.params.id}`
      );
      // let supplierData = await UPCAxiosInstance.get(
      //   `/catalogManagement/productOffering/supplierStock/${this.$route.params.id}`
      // );
      // console.log("yes supplierData form query", supplierData);
      console.log(this.supplierData, "this.supplierData");
      this.supplierOriginalData = JSON.parse(
        JSON.stringify(productSpec.data.relatedParty)
      );
      this.supplierOriginalDataRemoveProductOwner =
        this.supplierOriginalData.filter((ele) => ele.id);
      this.supplierData = this.supplierOriginalData.filter(
        (item) => item.role?.toLowerCase() == "supplier"
      );
      console.log(
        " this.supplierOriginalData refreshSupplier ",
        this.supplierOriginalData
      );
      // this.supplierTableData = JSON.parse(JSON.stringify(  this.supplierData ));
      this.supplierTableData = this.supplierTableImage(this.supplierData);
      console.log(
        this.supplierTableData,
        "supplierTableImage run this.supplierData refreshSupplier ",
        this.supplierData
      );
      this.defaultSupplierData(this.supplierTableData);
      // this.searchSupplierData = await supplierData?.data[0]?.relatedParty;
      // console.log("yes supplierData", this.supplierData);
    },
    deleteDiscountData(val) {
      console.log("deleteDiscountData", val);
    },
    addNewDiscountData(val) {
      console.log("addNewDiscountData ", val);
      // this.discountData.name=val.name
      // this.discountData.type=val.type
      // this.discountData.status=val.lifecycleStatus
      // this.discountData.validity.startDate=val.validFrom.startDate
      // this.discountData.validity.EndDate=val.validFrom.EndDate

      const newDiscount = {
        id: 34345,
        name: val.name,
        type: val.type,
        lifecycleState: val.lifecycleState,
        validFor: {
          startDate: val.validFor.startDate,
          endDate: val.validFor.endDate,
        },
        discountType: {
          name: val.discountType.name,
          value: val.discountType.value,
        },
        recurringChargePeriod: val.recurringChargePeriod,
        relatedParty: val.relatedParty,
        description: val.description,
        minimumDiscountRules: val.minimumDiscountRules,
        maximumDiscountRules: val.maximumDiscountRules,
        appliesTo: val.appliesTo,
        applyTo: val.applyTo,
        isSpecified: val.isSpecified,
        extarctedType: val.extarctedType,
        specifiedAccounts: val.specifiedAccounts,
      };

      this.discountData.push(newDiscount);
    },
    relatedProductsHeader(val) {
      if (val.name === "Dependent products") {
        console.log("this.selected=Dependent products");
        // this.selected="Dependent products"
        this.isDependent = true;
        this.selected = "Recommended";
        this.isRecommended = false;
      } else {
        console.log("this.selected=Recommended");
        this.selected = "Recommended";
        this.isDependent = false;
        this.isRecommended = true;
      }
      console.log("relatedProductsHeader", val.name);
    },
    showPreview() {
      this.$router.push(
        `/UPC/product-catalog-table/offering/${this.$route.params.id}/configuration-preview`
      );
    },
    async offerRefresh() {
      let productSpec = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/${this.$route.params.id}`
      );
      this.productName = productSpec.data?.name;

      this.payloadSpec = {
        ...this.payloadSpec,
        ...productSpec.data,
        id: productSpec.data?._id,
      };
    },
    // relatedProductsHeader(val) {
    //   if (val.name === "Dependent products") {
    //     console.log("this.selected=Dependent products");
    //     // this.selected="Dependent products"
    //     this.isDependent = true;
    //     this.selected = "Recommended";
    //     this.isRecommended = false;
    //   } else {
    //     console.log("this.selected=Recommended");
    //     this.selected = "Recommended";
    //     this.isDependent = false;
    //     this.isRecommended = true;
    //   }
    //   console.log("relatedProductsHeader", val.name);
    // },
    supplierTableImage(values) {
      console.log(values, "supplierTableImage run value image");
      try {
        values?.forEach((val) => {
          if (val?.attachmentName) {
            this.supplierImageSource = "";
            UPCAxiosInstance.get(`/gcpupload/files/${val?.attachmentName}`, {
              responseType: "arraybuffer",
            })
              .then((response) => {
                const uint8Array = new Uint8Array(response.data);
                let binary = "";
                uint8Array.forEach((byte) => {
                  binary += String.fromCharCode(byte);
                });
                const base64String = window.btoa(binary);
                let profilePicUrl = `data:image/png;base64,${base64String}`;
                val.src = profilePicUrl;
              })
              .catch((error) => {
                console.error("Error fetching image:", error);
              });
          } else {
            console.log("else part in supplierTableImage");
          }
        });
      } catch (err) {
        console.error("error from supplier image", err);
      }
      //  let value = JSON.parse(JSON.stringify(values))
      //  this.supplierTableData = JSON.parse(JSON.stringify(values))
      console.log(
        values,
        "supplierTableImage run value image",
        this.supplierTableData
      );
      return values;
    },
    editAdditionalCharge(value) {
      console.log("From overview edit", value);
    },
    async deleteAdditionalCharge(value) {
      console.log(this.additionalChargeData1, "updatedArray");
      const updatedArray = this.additionalChargeData1.filter(
        (item) => item.additionalChargeId !== value.additionalChargeId
      );
      console.log(await updatedArray, "updatedArray");
      let deleteAdditionalCharge = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        {
          additionalCharges: updatedArray,
          id: this.payloadSpec._id,
        }
      );

      console.log(
        "this.additionalChargeData1",
        this.additionalChargeData1,
        deleteAdditionalCharge.data
      );

      if (deleteAdditionalCharge.status == 200) {
        this.notification = {
          type: "success",
          message: `Done! Your item has been removed.`,
        };
      }
      console.log(
        "loading media in all deleteAdditionalCharge",
        deleteAdditionalCharge?.data?.prodSpecCharValue
      );
      this.removeAppliesTo(value);
      this.refreshTable();
    },
    async removeAppliesTo(value) {
      console.log(value, "updateAdditionalCharge");
      const getAdditionalCharge = await UPCAxiosInstance.get(
        `/productCatalog/productOffering/${value._id}`
      );
      console.log("updateAdditionalCharge", getAdditionalCharge.data);
      let appliesTo = await getAdditionalCharge.data?.appliesTo.filter(
        (item) => item._id != this.$route.params.id
      );

      const updateAdditionalCharge = await UPCAxiosInstance.patch(
        `/productCatalog/productOffering`,
        { id: value._id, appliesTo: appliesTo }
      );
      console.log(
        "loading media in all removeAppliesTo",
        updateAdditionalCharge?.data?.prodSpecCharValue
      );
      console.log("updateAdditionalCharge", updateAdditionalCharge.data);
    },
    async refreshTable() {
      const addditionalData = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/${this.$route.params.id}`
      );
      this.additionalChargeData1 = await addditionalData.data
        ?.additionalCharges;
      console.log("this.additionalChargeData1", this.additionalChargeData1);
    },

    async refreshRelatedProductTable() {
      // this.relatedProductTableValues = [];

      const relatedProduct = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/relatedProducts/${this.$route.params.id}`
      );
      console.log("refreshRelatedProductTable one", relatedProduct);
      // this.relatedProductTableValues = relatedProduct.data[0].relatedProductOfferings

      // console.log("refreshRelatedProductTable one",this.$route.params.id)
      // console.log("refreshRelatedProductTable one", this.payloadSpec._id)
      // console.log("refreshRelatedProductTable two",relatedProduct.data)
      // console.log("refreshRelatedProductTable two.1",relatedProduct?.data[0]?.relatedProductOfferings)
      // // let convertToArray=[];

      const manipulateProducts =
        relatedProduct?.data[0]?.relatedProductOfferings.map((item) => {
          let relatedProduct = {
            _id: item?._id,
            name: item?.name,
            category: this.leaf(item.category),
            price:
              item?.prodSpecCharValue?.map((ele) => ele?.tiers?.[0]?.price) ||
              [],
          };
          return relatedProduct;
        });
      this.relatedProductTableValues = manipulateProducts;

      console.log(
        "refreshRelatedProductTable three",
        this.relatedProductTableValues
      );
      return this.relatedProductTableValues
        ? this.relatedProductTableValues
        : [];
    },

    emithead1(val) {
      console.log(val, "emithead1_over");
      this.ddhead = val;
      // console.log("asif fghjhk");
      this.dropvalue1 = [];
      this.payloadSpec.prodSpecCharValueUse.forEach((sub) => {
        console.log(sub, sub.name, val, "loopspec");
        if (sub.name === val) {
          console.log("matchingFound");
          console.log(
            sub.productSpecCharacteristicValue,
            "---kooooooooooooooo----"
          );
          sub.productSpecCharacteristicValue.forEach((singlearray) => {
            console.log(singlearray.value);
            this.dropvalue1.push({
              name: singlearray?.value + " " + singlearray?.valueSuffix,
            });
            console.log(this.dropvalue1);
          });
          console.log(this.dropvalue1, "this.dropvalue1");
        }
      });
    },
    emitvalue1(val) {
      console.log(val, "final_emit");
      this.ddvalue = val;
    },

    async closedata1(index, mediadata) {
      console.log(
        index,
        mediadata[index],
        "ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo"
      );
      // let closedrawer = await UPCAxiosInstance.patch(
      //   `/catalogManagement/productOffering`,
      //   { prodSpecCharValue: mediadata, id: this.payloadSpec._id }
      // );
      // console.log(closedrawer, "close_work", mediadata);
    },

    async imagedelete1(id) {
      console.log(id, "imagedelete emit in overview");
      this.mediaData.forEach((items) => {
        if (items.id === id) {
          items.attachment = [];
          console.log(items.attachment, "image is deleted in media row");
        }
      });
      const deletedMedia = this.mediaData.find((item) => item.id === id);

      //delete patcch for media
      let deletepatch = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        { prodSpecCharValue: this.mediaData, id: this.payloadSpec._id }
      );
      console.log("deletepatch for image", deletepatch);

      if (deletepatch) {
        this.notification = {
          type: "success",
          message: `media updated successfully!`,
        };
        const mediaDeleteLogPayload = {
          message: `
            ${this.payloadSpec?.name} ${
            deletedMedia.SKU ? "SKU " + deletedMedia.SKU + " images" : "Images"
          } has been deleted`,
          detailedAction: [],
          "@type": "Offering-media",
          category: ["productOffering"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          ref: [this.payloadSpec?.id],
          action: "REMOVED",
          status: "active",
        };
        this.initLog(mediaDeleteLogPayload);
      }
    },

    extractFilename(fullFilename) {
      // Split the filename based on the separator '#-#'
      const parts = fullFilename.split("---");

      if (parts.length > 1) {
        return parts[1];
      } else {
        return fullFilename;
      }
    },

    async mediaUpload(
      val,
      payload,
      deleteval,
      defaultData,
      applyall,
      index,
      id,
      combination,
      groupVal
    ) {
      console.log(
        "val===",
        val,
        "payload===",
        payload,
        "delete===",
        deleteval,
        "dafault===",
        defaultData,
        "-all-",
        applyall,
        "index===",
        index,
        "indexid===",
        id,
        "val,payload,deleteval",
        combination,
        groupVal
      );

      if (val) {
        console.log(
          val,
          "data is started to load from gcp..........................................................................."
        );
        try {
          var data = await UPCAxiosInstance.post(
            `/gcpMultiple/v2/upload/`,
            val
          );
          console.log(
            val,
            "data is started to finish from gcp..........................................................................."
          );
          console.log(data);
        } catch (error) {
          console.log("An error occurred while uploading data to GCP:", error);
        }
      }

      console.log(this.mediaData, "media data in media upload");

      //update delete
      this.mediaData.forEach((mediarow) => {
        if (mediarow.id === payload.id) {
          mediarow.attachment = payload.attachment;
          console.log(mediarow.attachment, "---row");
        }
      });
      console.log(this.mediaData, "after---------delete");

      //update new image
      this.mediaData.forEach((item) => {
        //
        if (item.id === payload.id && item.priceType === payload.priceType) {
          console.log("id checked,,,,");
          if (!item.attachment) {
            item.attachment = [];
          }

          console.log(item.attachment, "data,data");
          const singleitem = data?.data;
          // let w=0;
          // item.attachment=[];
          for (let i = 0; i < singleitem.length; i++) {
            let dataItem = singleitem[i];
            // w=w+1;
            console.log(dataItem, i, "dataitem..............123");
            const obj = {};
            obj.name = dataItem.filename;
            obj.id = dataItem.data._id;
            obj.url = dataItem.url;
            const currentTimestamp = new Date().toJSON();
            obj.lastUpdated = currentTimestamp;
            item.attachment.push(obj);
          }
          console.log(item.attachment, "single-item-attachment");

          if (defaultData.imageVal) {
            console.log(
              "-------------component have default image-------------",
              "11111111111",
              defaultData?.imageVal?.name
            );
            let a = 0;
            item.attachment.forEach((defaltItem) => {
              a = a + 1;
              console.log(
                "attachment:",
                a,
                "----",
                defaltItem?.name,
                "-------component-preffered---",
                defaultData?.imageVal?.name
              );
              if (
                this.extractFilename(defaltItem?.name) ===
                this.extractFilename(defaultData?.imageVal?.name)
              ) {
                console.log(
                  "-------------default image item found-------------"
                );
                defaltItem.preferred = true;
              } else {
                defaltItem.preferred = false;
                console.log(
                  defaltItem?.name.toLowerCase().trim(),
                  defaultData?.imageVal?.name.toLowerCase().trim(),
                  "-------------default image not found-------------",
                  this.extractFilename(defaultData?.imageVal?.name),
                  this.extractFilename(defaltItem?.name)
                );
              }
            });
          } else {
            console.log(
              "no default******************************************************************************************************************"
            );
          }
        }
      });

      //auto default
      this.mediaData.forEach((item) => {
        // if (item.id === payload.id && item.priceType === payload.priceType) {
        if (item.id === payload.id) {
          if (item.attachment) {
            console.log("attachment present");
            if (item.attachment.length > 0) {
              const hasPreferred = item.attachment.some(
                (attachment) => attachment.preferred
              );
              console.log("hasPreferred", hasPreferred);
              if (!hasPreferred) {
                item.attachment[0].preferred = true;
                console.log("auto default..........................");
              }
            }
          }
        }
      });

      console.log(this.mediaData, "before-patch-data");
      let editPatch = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        { prodSpecCharValue: this.mediaData, id: this.payloadSpec._id }
      );
      this.payloadSpec = await editPatch?.data;
      console.log(editPatch, "patch_work", this.mediaData);

      if (editPatch) {
        this.notification = {
          type: "success",
          message: `Media updated successfully!`,
        };
        let mediaLogPayload = {
          message: `
          ${this.payloadSpec?.name} ${
            payload.SKU ? "SKU - " + payload.SKU + " images" : "Images"
          } has been updated`,
          detailedAction: [],
          "@type": "Offering-media",
          category: ["productOffering"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          ref: [this.payloadSpec?.id],
          action: "UPDATE",
        };

        if (combination) {
          mediaLogPayload.detailedAction.push({
            action: "ADDED",
            item: combination,
            property: "Combination",
          });
        }
        if (groupVal) {
          mediaLogPayload.detailedAction.push({
            action: "ADDED",
            item: groupVal,
            property: "Group by",
          });
        }
        // mediaLogPayload.detailedAction = mediaLogPayload.detailedAction.concat(groupData,combinationData)
        this.initLog(mediaLogPayload);
        if (applyall) {
          console.log("apply to all is running", applyall, index);
          this.applyallfunction(
            applyall,
            index,
            id,
            payload.id,
            payload.priceType
          );
        }
      }
    },
    // async getSupplierName(val){
    //   const filterData = {
    //   company: this.company,
    //   type: "filter",
    //   module: "suppliers",
    //   isPaginated: true,
    //   lastUpdated: new Date().toJSON(),
    //   paginatedQuery: {
    //     rowsPerPage: this.rowsPerPage,
    //     currentPage: this.currPage,
    //   },
    //   isNeedToBeSort: true,
    //   sortFieldAndOrder: {},
    //   limit: 2,
    //   filterQuery: { name: {
    //       $regex: val,
    //       $options: 'i',
    //     },},
    //   searchQuery: "",

    // };

    // const access = await MLTAxiosInstance.post(
    //   `util/filterData?company=${this.company}`,
    //   filterData
    // );
    // this.supplierMain = await access.data.data;
    // console.log("Add supplier mounted 1", this.supplierMain);
    // this.supplier = this.supplierMain.filter(
    //   (ele) => !this.tableData.some((val) => val._id == ele._id)
    // );
    //   console.log("getSupplierName",val)
    //   console.log("")
    // },
    findDifferences(payload1, payload2) {
      // Extract 'name' values
      const names1 = payload1.map((item) => item.name);
      const names2 = payload2.map((item) => item.name);

      // Find added names in payload2 that are not in payload1
      const addedNames = names2.filter((name) => !names1.includes(name));

      // Find removed names in payload1 that are not in payload2
      const removedNames = names1.filter((name) => !names2.includes(name));

      return {
        addedNames,
        removedNames,
      };
    },
    async applyallfunction(val, index, id, payloadid, Type) {
      console.log(
        val,
        index,
        id,
        payloadid,
        Type,
        "112345670-09876543234567909o87654323456790987654321234567890987654323456-9876543456789098765434567898765432345678987654323456789876543234567876543235678765432378765432"
      );
      // const foundObject = this.alterdata.find(
      //   // (item) => item.id === id && item.priceType === Type
      //   (item) => item.id === id
      // );
      // console.log(foundObject,id,this.alterdata, "34d");

      const productSpec = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/${this.$route.params.id}`
      );
      console.log(productSpec.data.prodSpecCharValue);
      // productSpec.data.prodSpecCharValue

      const tabledata = productSpec?.data?.prodSpecCharValue;

      console.log("tabledata////////////////////////////////", tabledata);

      if (tabledata) {
        const foundrow = tabledata.find((item) => item.id === id);
        const attachment1 = foundrow?.attachment;
        console.log(
          "foundrow|||||||||||||||",
          foundrow,
          attachment1,
          foundrow?.attachment,
          "34d"
        );

        if (foundrow?.attachment) {
          console.log("tabledata is looping", tabledata);

          tabledata.forEach((item) => {
            let a = Object.prototype.hasOwnProperty.call(item, this.ddhead);
            console.log(a);
            if (a === true) {
              const normalize = (str) => str.replace(/\s+/g, " ").trim();
              console.log(
                normalize(item[this.ddhead].trim().toLowerCase()),
                "-vs-",
                normalize(val.trim().toLowerCase())
              );
              if (
                normalize(item[this.ddhead].trim().toLowerCase()) ===
                normalize(val.trim().toLowerCase())
              ) {
                console.log("matched---");

                if (!item.attachment) {
                  console.log("not attachment");

                  item.attachment = {};
                  item.attachment = attachment1;
                } else {
                  console.log(
                    "attachment......"
                    // datas.attachment,
                    // this.slider data
                  );
                  item.attachment = attachment1;
                }
              } else {
                console.log("no final emit match.......................");
              }
            }
          });
          let newpatch = await UPCAxiosInstance.patch(
            `/catalogManagement/productOffering`,
            { prodSpecCharValue: tabledata, id: this.payloadSpec._id }
          );
          console.log("newpatch", newpatch, tabledata);
          this.payloadSpec = await newpatch?.data;

          const productSpec1 = await UPCAxiosInstance.get(
            `/catalogManagement/productOffering/${this.$route.params.id}`
          );
          this.mediaData = productSpec1.data.prodSpecCharValue;
        }
      }
    },

    async deleteImage(val, payload) {
      console.log("val", val);

      console.log(this.editPayload, "this.editPayload");

      console.log("delete_delete", val);
      this.mediaData.forEach(async (item) => {
        if (item.id === payload.id) {
          item.attachment.splice(val.indexVal);
        }
      });

      console.log(this.mediaData, "this.mediaDatadelete");
    },

    handleRelatedParty(selectedParty) {
      console.log("🚀 ~ handleRelatedParty ~ selectedParty:", selectedParty);
      this.updatedData.productContact = selectedParty?.name;
      const avalaibalePartyIndex = this.payloadSpec.relatedParty.findIndex(
        (party) => party.id == selectedParty.id
      );
      if (avalaibalePartyIndex >= 0) {
        this.payloadSpec.relatedParty.splice(avalaibalePartyIndex, 1, {
          name: selectedParty.name,
          id: selectedParty._id,
        });
      } else {
        this.payloadSpec.relatedParty.push({
          name: selectedParty.name,
          id: selectedParty._id,
        });
      }
    },
    computedProductPrice(offer) {
      if (
        offer &&
        offer.productSpecification &&
        offer.productOfferingPrice &&
        offer.productOfferingPrice.length > 0
      ) {
        let foundedPrice = null;
        if (
          offer.productSpecification["@baseType"] &&
          offer.productSpecification["@baseType"]?.toLowerCase() == "tangible"
        ) {
          foundedPrice = offer.productOfferingPrice.find((price) =>
            ["one time", "onetime"].includes(price?.pricingType?.toLowerCase())
          );
          return this.currencyFormatter(
            foundedPrice?.productPrice.dutyFreeAmount,
            { currency: foundedPrice?.productPrice.unit }
          );
        }
        if (
          offer.productSpecification["@baseType"] &&
          offer.productSpecification["@baseType"]?.toLowerCase() == "intangible"
        ) {
          foundedPrice = offer.productOfferingPrice.find((price) =>
            ["recurring"].includes(price?.pricingType?.toLowerCase())
          );
        }
        if (foundedPrice) {
          return this.currencyFormatter(
            foundedPrice?.productPrice.dutyFreeAmount,
            { currency: foundedPrice?.productPrice.unit }
          );
        }
      }
      return this.currencyFormatter(0);
    },
    updateArrayByName(arr, nameToUpdate, newData) {
      // Check if the name exists in the array
      const index = arr.findIndex((item) => item.name === nameToUpdate);

      // If the name exists, update the object and return the entire array
      if (index !== -1) {
        arr[index] = { ...arr[index], ...newData };
        return arr;
      }

      // If the name doesn't exist, return the original array
      return arr;
    },
    async addPricingRow(i) {
      // // Example usage:
      // const obj1 = {
      //   isDefault: false,
      //   id: uuidv4(),
      //   validFor: {
      //     startDate: new Date().toISOString(),
      //     endDate: "9999-12-31T00:00:00Z",
      //   },
      //   SKU: "",
      //   lifecycleStatus: "Draft",
      //   MSRP: {
      //     unit: this.$store?.getters?.getDefaultSettings?.currency?.unit,
      //     value: 0,
      //   },
      //   commitmentLength: 1,
      //   billingPeriod: "Monthly",
      //   displayValue: "Months",
      //   endOfCommitment: {
      //     autoPay: true,
      //     expireTerm: false,
      //   },
      //   tiers: [
      //     {
      //       min: 1,
      //       max: 99,
      //       price: {
      //         unit: this.$store?.getters?.getDefaultSettings?.currency?.unit,
      //         value: 0,
      //       },
      //     },
      //   ],
      // };
      // const obj2 = {
      //   isDefault: false,
      //   id: uuidv4(),
      //   validFor: {
      //     startDate: new Date().toISOString(),
      //     endDate: "9999-12-31T00:00:00Z",
      //   },
      //   SKU: "",
      //   lifecycleStatus: "Draft",
      //   MSRP: {
      //     unit: this.$store?.getters?.getDefaultSettings?.currency?.unit,
      //     value: 0,
      //   },
      //   commitmentLength: 1,
      //   billingPeriod: "Monthly",
      //   displayValue: "Months",
      //   endOfCommitment: {
      //     autoPay: true,
      //     expireTerm: false,
      //   },
      //   tiers: [
      //     {
      //       min: 1,
      //       max: 99,
      //       price: {
      //         unit: this.$store?.getters?.getDefaultSettings?.currency?.unit,
      //         value: 0,
      //       },
      //     },
      //   ],
      // };

      // const result = this.compareObjects(obj1, obj2);
      // console.log("Combined Objects:", result.mergedObject);
      let result = JSON.parse(JSON.stringify(i));
      result.id = uuidv4();
      result.default = false;
      result.tiers = [
        {
          min: 1,
          max: 99,
          price: {
            unit: this.$store?.getters?.getDefaultSettings?.currency?.unit,
            value: 0,
          },
        },
      ];
      const newStartDate = new Date(i.validFor.endDate);
      newStartDate.setDate(newStartDate.getDate() + 1);
      result.validFor.startDate = newStartDate;
      result.validFor.endDate = "9999-12-31T00:00:00Z";
      //   if(new Date(newStartDate)<=new Date() && new Date(result.validFor.endDate)<=new Date()){

      //     result.lifecycleStatus = "Active";
      //   }
      //   else{
      //   result.lifecycleStatus = "Draft";
      // }
      const startDate = this.normalizeDate(new Date(newStartDate));
      const endDate = this.normalizeDate(new Date(result.validFor.endDate));
      const currentDate = this.normalizeDate(new Date());
      console.log(currentDate, startDate, endDate, "date validation check");

      if (startDate < currentDate && endDate < currentDate) {
        result.lifecycleStatus = "Inactive";
      } else if (startDate <= currentDate && endDate >= currentDate) {
        result.lifecycleStatus = "Active";
      } else if (startDate > currentDate || endDate < currentDate) {
        result.lifecycleStatus = "Draft";
      }
      result.default = false;
      this.notification = {
        type: "success",
        message: `Nice work! Your new pricing is now in the list!`,
      };
      // result.mergedObject.tiers = [
      //   {
      //     min: 1,
      //     max: 99,
      //     price: {
      //       unit: this.$store?.getters?.getDefaultSettings?.currency?.unit,
      //       value: 0,
      //     },
      //   },
      // ];
      // result.mergedObject.priceType = this.selectedPrice;
      this.pricingData.push(result);

      console.log(this.pricingData, "console log add pricing");
      if (this.payloadSpec?.constraint[0]?.data?.decisionId != "") {
        await DMNAxiosInstance.patch(
          `/decisionMatrics/` + this.$cookies.get("company"),
          {
            _id: this.payloadSpec?.constraint[0]?.data?.decisionId,
            matrix: this.pricingData,
            name: this.payloadSpec.name,
          }
        );
      } else {
        await DMNAxiosInstance.post(
          `/decisionMatrics/autopermute`,
          this.matrixPayload
        );
      }

      this.payloadSpec.prodSpecCharValue = await this.pricingData;
      // this.relatedProductPanel = !this.relatedProductPanel;
      let update = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        {
          ...this.payloadSpec,
          id: this.payloadSpec._id,
        }
      );
      if (update.data) {
        // this.notification = {
        //   type: "success",
        //   message: `Nice work! Your new pricing is now in the list!`,
        // };
        const pricingLogPayload = {
          message: `${this.payloadSpec?.name} ${this.selectedPrice} pricing has been cloned`,
          detailedAction: [],
          "@type": "Offering-pricing",
          category: ["productOffering"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          ref: [this.payloadSpec?.id],
          action: "ADDED",
          status: "active",
        };
        this.initLog(pricingLogPayload);
      }
    },
    texteditorValue(val) {
      console.log(val, "description");
      this.texteditorVal = val;
      this.updatedData.description = val;
    },
    async uploadPerumutation(data) {
      console.log(data, "uploadPerumutation");
      this.matrixPayload = await this.getConfigurableOptions(data);

      console.log(this.matrixPayload, "createMatrix creation successfull");

      let keys = Object.keys(this.matrixPayload);
      this.pricingKey = keys;
      let priceTable = keys.map((obj) => {
        return {
          sortable: false,
          resizable: false,
          width: 200,
          label: obj,
          prop: obj,
          align: "left",
        };
      });
      //   priceTable;
      this.pricingTheads = priceTable.concat(this.pricingTheads);
      this.pricingTheads = this.removeDuplicatesV1(this.pricingTheads, "prop");

      let constrait = await DMNAxiosInstance.post(
        `/decisionMatrics/autopermute`,
        this.matrixPayload
      );
      if (this.payloadSpec?.constraint[0]?.data?.decisionId) {
        await DMNAxiosInstance.patch(
          `/decisionMatrics/` + this.$cookies.get("company"),
          {
            _id: this.payloadSpec?.constraint[0]?.data?.decisionId,
            matrix: constrait.data,
            name: this.payloadSpec.name,
          }
        );
        // this.payloadSpec.prodSpecCharValue = await constrait.data;
        // await UPCAxiosInstance.patch(`/catalogManagement/productOffering`, {
        //   id: this.payloadSpec._id,
        //   constraint: createMatrixs.data,
        // });
        this.offerRefresh();
      } else {
        const createMatrix = await DMNAxiosInstance.post(
          `/decisionMatrics/` + this.$cookies.get("company"),
          {
            matrix: constrait.data,
            name: this.payloadSpec.name,
            createdBy: this.userId,
            status: "Active",
            description: "",

            inputMapping: [
              {
                from: {
                  var: "EIOP.EI.AccessType",
                },
                to: "Access Type",
                type: "string",
                operators: {
                  is: [],
                },
              },
            ],
            hitPolicy: "unique",
            ruleSet: [],
          }
        );
        this.payloadSpec.constraint = createMatrix.data;
        console.log(createMatrix.data, "createMatrix creation successfull");
        await UPCAxiosInstance.patch(`/catalogManagement/productOffering`, {
          id: this.payloadSpec._id,
          constraint: createMatrix.data,
        });
      }
      this.payloadSpec.prodSpecCharValue = await constrait.data;
      this.offerRefresh();

      let priceSection = constrait.data.map((permute) => {
        const id = uuidv4();
        const loopedPricing = this.offerPriceTypes.map((item) => {
          let dataObject = {
            id,
            default: false,
            validFor: {
              startDate: new Date().toISOString(),
              endDate: "9999-12-31T00:00:00Z",
            },
            priceType: item.name,
            tiers: [
              {
                min: 1,
                max: 99,
                price: {
                  unit: this.$store?.getters?.getDefaultSettings?.currency
                    ?.unit,
                  value: 0,
                },
              },
            ],

            ...permute,
          };
          if (
            ["recurring", "rental", "pay per use", "installment"].includes(
              item?.name?.toLowerCase()
            )
          ) {
            dataObject.commitmentLength = 1;
            dataObject.billingPeriod = "Monthly";
            dataObject.displayValue = "Months";
            dataObject.endOfCommitment = {
              autoPay: true,
              expireTerm: false,
            };
          }
          if (this.categoryInfo?.type == "Tangible") {
            dataObject.SKU = "";
            dataObject.MSRP = {
              unit: this.$store?.getters?.getDefaultSettings?.currency?.unit,
              value: 0,
            };
          }
          return dataObject;
        });
        console.log("🚀 ~ priceSection ~ loopedPricing:", loopedPricing);
        return loopedPricing.flat(2);
      });
      console.log(priceSection, "auto permutate");

      let prodSpecCharValue = await priceSection.flat();
      // let prodSpecCharValue = await priceSection.flat().map((obj) => {
      //   obj.id = uuidv4();
      //   // console.log(obj, "testt");
      //   return obj;
      // });
      console.log(
        "🚀 ~ prodSpecCharValue ~ prodSpecCharValue:",
        prodSpecCharValue
      );
      this.payloadSpec.prodSpecCharValue = await prodSpecCharValue;
      console.log(this.payloadSpec.prodSpecCharValue, "yes incoming variable");
      // this.payloadSpec.id = this.payloadSpec._id;
      let updateConstrait = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        { ...this.payloadSpec, id: this.payloadSpec._id }
      );
      console.log(await updateConstrait.data, "constraitttt");
      if (updateConstrait.data.prodSpecCharValue) {
        this.pricingData = await updateConstrait.data.prodSpecCharValue;
      }
      this.matrixPaginated;
    },
    addTiersToPriceType(productsArray, priceType, tiersToAdd) {
      let productArray = productsArray.map((product) => {
        if (product.priceType === priceType) {
          product.tiers = tiersToAdd;
          return product;
        } else {
          return product;
        }
      });
      return productArray;
    },

    async applyAll(val) {
      this.applyAllCheck = val.checked;
      let tiersToAdd = this.pricingPanelData.tiers;
      this.pricingData = await this.addTiersToPriceType(
        this.pricingData,
        this.selectedPrice,
        tiersToAdd
      );
      console.log(val, this.pricingData, "applyall");
      let updateConstrait = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        { ...this.payloadSpec, id: this.payloadSpec._id }
      );
      updateConstrait;
    },
    async computedPriceTypes() {
      console.log("🚀 ~ computedPriceTypes ~ this.spec:", this.payloadSpec);
      if (
        !this.payloadSpec ||
        (this.payloadSpec &&
          !Object.prototype.hasOwnProperty.call(this.payloadSpec, "category"))
      ) {
        return (this.offerPriceTypes = []);
      }
      let leafCategory = this.leaf(this.payloadSpec.category);

      console.log("🚀 ~ computedPriceTypes ~ leafCategory:", leafCategory);
      let categoryInfo = await UPCAxiosInstance.get(
        "/catalogManagement/category/" + leafCategory
      );
      console.log("🚀 ~ computedPriceTypes ~ categoryInfo:", categoryInfo);
      if (categoryInfo.data) {
        this.categoryInfo = categoryInfo.data;
        if (
          this.payloadSpec.unitOfMeasure == "" ||
          this.payloadSpec.unitOfMeasure == null
        ) {
          if (this.categoryInfo?.type == "Tangible") {
            this.payloadSpec.unitOfMeasure = "Each";
          } else {
            this.payloadSpec.unitOfMeasure = "Per billing period";
          }
        }
        this.offerPriceTypes = categoryInfo.data.pricingType?.map((type) => {
          return { name: type };
        });
        this.selectedPrice = this.offerPriceTypes[0].name;
        return;
      }
      return (this.offerPriceTypes = []);
    },
    emitStatus(val1) {
      this.updatedData.lifecycleStatus = val1["value"];
      this.payloadSpec.lifecycleStatus = val1["value"];
    },
    async suffixed(val) {
      let OverviewFeatureLog = {
        message: `${this.payloadSpec?.name} pricing Unit of measure "${val.name}" has been updated`,
        detailedAction: [],
        action: "UPDATED",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.payloadSpec?._id],
        "@type": "Pricing",
        category: ["productOffering"],
      };

      console.log("Selected unit of measure", val);
      this.unitOfMeasurePricing = val.name;
      console.log("Selected unit of measure", this.unitOfMeasurePricing);
      let UOM = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        {
          unitOfMeasure: this.unitOfMeasurePricing,
          id: this.payloadSpec._id,
        }
      );
      if (UOM.data) {
        this.initLog(OverviewFeatureLog);
      }
      this.refreshPayload();
      await this.supplierUnitModify(val.name);
    },

    async addPricing(id) {
      try {
        this.ispricingEdit = !this.ispricingEdit;
        let i = this.pricingPanelData;
        if (this.productBaseType?.toLowerCase() == "tangible") {
          if (i.SKU) {
            this.pricingPanelData = i;

            // If the current item is already set to default, unset it

            this.pricingData = this.pricingData.map((item) => {
              if (item.SKU === this.pricingPanelData.SKU) {
                item.default = false;
              }
              if (
                item.SKU === this.pricingPanelData.SKU &&
                item.priceType === this.pricingPanelData.priceType &&
                item.id === this.pricingPanelData.id
              ) {
                item.default = true;
              }
              return item;
            });
          }
        } else {
          if (i.id) {
            this.pricingPanelData = i;

            this.pricingData = this.pricingData.map((item) => {
              item.default = false;
              console.log(item, "orange item", this.pricingPanelData);
              if (
                item.id === this.pricingPanelData.id &&
                item.priceType === this.pricingPanelData.priceType
              ) {
                item.default = true;
                console.log(
                  `Setting SKU ${item.SKU} of priceType ${item.priceType} to not default.`
                );
              }
              return item;
            });
          }
        }

        // this.mapCombination();
        let tempPricingData = JSON.parse(JSON.stringify(this.pricingData));
        // console.log(tempPricingData, "tempPricingData");
        this.priceIndex = tempPricingData.findIndex(
          (item) => item.id == id && item.priceType == this.selectedPrice
        );
        // console.log(
        //   "🚀 ~ tempPricingData",
        //   this.priceIndex,
        //   this.pricingData
        // );
        let priceInfo = null;
        // oldPriceInfo = null;
        if (this.priceIndex >= 0) {
          // console.log(
          //   "🚀 ~ addPricing ~ this.pricingData[this.priceIndex]:",
          //   this.pricingData[this.priceIndex]
          // );
          // oldPriceInfo = JSON.parse(
          //   JSON.stringify({ ...this.pricingData[this.priceIndex] })
          // );
          console.log(this.pricingData, "this.pricingData", this.priceIndex);

          priceInfo = {
            ...JSON.parse(JSON.stringify(this.pricingData[this.priceIndex])),
            ...JSON.parse(JSON.stringify(this.pricingPanelData)),
            priceType: this.selectedPrice,
          };
          // console.log("🚀 ~ addPricing ~ oldPriceInfo:", oldPriceInfo);
          // console.log("🚀 ~ addPricing ~ priceInfo:", priceInfo);
          // this.pricingData[this.priceIndex] = priceInfo;
          console.log("🚀 ~ tempPricingData", tempPricingData);
          // update the pricing data where ever its match the id
          try {
            tempPricingData = tempPricingData.map((item) => {
              console.log(
                "🚀 ~ tempPricingData panel",
                this.pricingPanelData,
                item
              );

              if (item.id == id) {
                // console.log(item,this.pricingPanelData,"🚀 ~ tempPricingData");
                // item = this.pricingPanelData;
                item = {
                  ...item,
                  SKU: JSON.parse(JSON.stringify(this.pricingPanelData.SKU)),
                  MSRP: JSON.parse(JSON.stringify(this.pricingPanelData.MSRP)),
                };
                console.log(
                  this.selectedPrice,
                  "selectedPrice",
                  item.priceType
                );
                if (this.selectedPrice == item.priceType) {
                  item.validFor = JSON.parse(
                    JSON.stringify(this.pricingPanelData.validFor)
                  );
                  item.lifecycleStatus = JSON.parse(
                    JSON.stringify(
                      this.pricingPanelData.lifecycleStatus
                        ? this.pricingPanelData.lifecycleStatus
                        : "Active"
                    )
                  );
                  item = {
                    ...item,
                    ...JSON.parse(JSON.stringify(this.pricingPanelData)),
                  };
                }
              }

              return item;
            });
          } catch (err) {
            console.log("🚀 ~ addPricing ~ err:", err);
          }

          console.log("🚀 ~ tempPricingData", tempPricingData);

          this.pricingData = tempPricingData;
          this.mapdefault(this.pricingData);
          const dmn = await DMNAxiosInstance.patch(
            `/decisionMatrics/` + this.$cookies.get("company"),
            {
              _id: this.payloadSpec?.constraint[0]?.data?.decisionId,
              matrix: this.pricingData,
              name: this.payloadSpec.name,
            }
          );
          console.log(
            "matrix",
            dmn.data,
            this.pricingData,
            this.$cookies.get("company")
          );
          dmn;
          let parties = await JSON.parse(
            JSON.stringify(this.payloadSpec.relatedParty)
          );
          let updatedParties = parties.map((party) => {
            if (
              party &&
              party.role?.toLowerCase() == "supplier" &&
              party.id == this.pricingPanelData.id
            ) {
              this.supplierModify(party, this.pricingPanelData);
            }
            if (
              party &&
              party.role?.toLowerCase() == "supplier" &&
              party.id == this.pricingPanelData.id
              // && party.sku == this.pricingPanelData.SKU
            ) {
              party.msrp = this.pricingPanelData?.MSRP;
              party.sku = this.pricingPanelData?.SKU;
              // party.tiers = this.pricingPanelData?.tiers
            }

            // For intangible for products , check the pricetype & status:active id it true means change the tiers value
            if (
              party &&
              party.role?.toLowerCase() == "supplier" &&
              party.priceType == this.pricingPanelData.priceType &&
              this.productBaseType.toLowerCase() == "intangible" &&
              (this.pricingPanelData.lifecycleStatus.toLowerCase() ===
                "active" ||
                this.pricingPanelData.default === true)
            ) {
              party.tiers = this.pricingPanelData?.tiers;
            }
            console.log("addPricing ~ changeInSupplier", party);

            return party;
          });
          console.log(
            "🚀 ~ addPricing ~ changeInSupplier:",
            updatedParties,
            this.pricingPanelData
          );
          // console.log("relatedProduct changeInSupplier",changeInSupplier)
          // console.log("relatedProduct pricingPanelData",this.pricingPanelData)
          // changeInSupplier.forEach((relatedProduct) => {
          // console.log("relatedProduct outside",relatedProduct)
          //   if (relatedProduct.id === this.pricingPanelData.id) {
          //     // console.log("relatedProduct inside",relatedProduct)
          //     // relatedProduct.
          //     if (relatedProduct.msrp) {
          //       relatedProduct.msrp.value = this.pricingPanelData?.MSRP?.value;
          //       relatedProduct.sku = this.pricingPanelData?.SKU;
          //     }
          //   }
          // });
          this.payloadSpec.relatedParty = updatedParties;

          const updateSpecWithChar = await UPCAxiosInstance.patch(
            `/catalogManagement/productOffering`,
            { ...this.payloadSpec, id: this.payloadSpec._id }
          );
          // console.log(
          //   "loading media in all updateSpecWithChar",
          //   updateSpecWithChar?.data?.prodSpecCharValue
          // );
          updateSpecWithChar;
          let pricingInfo = await this.matrixPaginated[0];
          // console.log("Update pricing", pricingInfo);
          let price;
          if (pricingInfo?.MSRP?.value != 0) {
            console.log("Update pricing");
            price = pricingInfo.MSRP.value;
          } else {
            console.log("Update pricing");
            price = pricingInfo.tiers[0].price.value;
          }
          console.log(price, "pricing data");
          let offeringPrice = await this.payloadSpec?.productOfferingPrice;
          if (offeringPrice.length > 0) {
            console.log("Update pricing");
            const exists = offeringPrice.some(
              (p) => p.name == this.selectedPrice
            );

            // If it doesn't exist, add the product
            if (exists) {
              let alterationPrice = await offeringPrice.map((obj) => {
                if (obj.pricingType != this.selectedPrice) {
                  const appendPricing = {
                    id: uuidv4(),
                    name: this.selectedPrice,
                    pricingType: this.selectedPrice,
                    productPrice: {
                      dutyFreeAmount: price,
                      unit: pricingInfo.tiers[0].price.unit,
                      commitmentLength: this.pricingPanelData.commitmentLength,
                      billingPeriod: this.pricingPanelData.billingPeriod,
                      displayValue: this.pricingPanelData.displayValue,
                    },
                  };
                  this.payloadSpec.productOfferingPrice =
                    this.payloadSpec.productOfferingPrice.concat([
                      appendPricing,
                    ]);

                  console.log(appendPricing, "inside the loop   ");
                } else {
                  obj.productPrice = {
                    dutyFreeAmount: price,
                    unit: pricingInfo.tiers[0].price.unit,
                    commitmentLength: this.pricingPanelData.commitmentLength,
                    billingPeriod: this.pricingPanelData.billingPeriod,
                    displayValue: this.pricingPanelData.displayValue,
                  };
                  const check = this.updateArrayByName(
                    offeringPrice,
                    obj.priceType,
                    obj
                  );
                  console.log("pricing data 11 else", check);
                  this.payloadSpec.productOfferingPrice = check;
                }
              });
              console.log(alterationPrice, "read sucesss");
            }
          } else {
            const appendPricing = {
              id: uuidv4(),
              name: this.selectedPrice,
              pricingType: this.selectedPrice,
              productPrice: {
                dutyFreeAmount: price,
                unit: pricingInfo.tiers[0].price.unit,
                commitmentLength: this.pricingPanelData.commitmentLength,
                billingPeriod: this.pricingPanelData.billingPeriod,
                displayValue: this.pricingPanelData.displayValue,
              },
            };

            this.payloadSpec.productOfferingPrice =
              await this.payloadSpec.productOfferingPrice.concat([
                appendPricing,
              ]);
            console.log(
              this.payloadSpec.productOfferingPrice,
              "inside the loop else"
            );
          }

          console.log(
            this.payloadSpec.productOfferingPrice,
            "testtt from data"
          );
          this.payloadSpec.prodSpecCharValue = await this.pricingData;

          let updateConstrait = await UPCAxiosInstance.patch(
            `/catalogManagement/productOffering`,
            { ...this.payloadSpec, id: this.payloadSpec._id }
          );
          if (updateConstrait.data) {
            let pricingLogPayload = {
              message: `${this.payloadSpec?.name} ${
                priceInfo && priceInfo?.SKU ? "SKU " + priceInfo?.SKU : ""
              } pricing has been updated`,
              detailedAction: [],
              "@type": "Offering-pricing",
              category: ["productOffering"],
              createdBy: this.$cookies.get("user"),
              user: this.$cookies.get("userName"),
              lastUpdate: new Date().toISOString(),
              ref: [this.payloadSpec?.id],
              action: "UPDATED",
              status: "active",
            };

            console.log(this.oldPriceInfo, priceInfo, "addPricing activiy log");

            // Check SKU changes
            const tiersActiveLog = this.findDifferencePricingTire(
              this.oldPriceInfo?.tiers,
              priceInfo?.tiers
            );

            // console.log(littledatafibe,"littledatafibe");
            const endDateActiveLog = this.buildBasicLogByDifferences(
              this.oldPriceInfo?.validFor,
              priceInfo?.validFor,
              "endDate",
              "endDate"
            );
            const endOfCommitmentActiveLog = this.buildBasicLogyesOrno(
              this.oldPriceInfo?.endOfCommitment,
              priceInfo?.endOfCommitment,
              "autoPay",
              "Auto renew",
              "True",
              "False"
            );
            const expireTermActiveLog = this.buildBasicLogByDifferences(
              this.oldPriceInfo?.endOfCommitment,
              priceInfo?.endOfCommitment,
              "expireTerm",
              "Expire term"
            );

            //endOfDateActionLog
            const startDateActiveLog = this.buildBasicLogByDifferences(
              this.oldPriceInfo?.validFor,
              priceInfo?.validFor,
              "startDate",
              "startDate"
            );

            // const defaultActiveLog = this.buildBasicLogByDifferences(
            //   this.oldPriceInfo,
            //   priceInfo,
            //   "default",
            //   "Set as default"
            // );

            let defaultActiveLog = this.buildBasicLogyesOrno(
              this.oldPriceInfo,
              priceInfo,
              "default",
              "Set as default",
              "True",
              "False"
            );
            console.log(
              this.oldPriceInfo,
              priceInfo,
              defaultActiveLog,
              "check multi time defalt",
              this.oldPriceInfo.default,
              priceInfo.default
            );

            const billingPeriodActiveLog = this.buildBasicLogByDifferences(
              this.oldPriceInfo,
              priceInfo,
              "billingPeriod",
              "Billing Period"
            );
            const commitmentLengthActiveLog = this.buildBasicLogByDifferences(
              this.oldPriceInfo,
              priceInfo,
              "commitmentLength",
              "Commitment Length"
            );

            pricingLogPayload.detailedAction =
              pricingLogPayload?.detailedAction.concat(
                expireTermActiveLog,
                endOfCommitmentActiveLog,
                commitmentLengthActiveLog,
                billingPeriodActiveLog,
                startDateActiveLog,
                endDateActiveLog,
                tiersActiveLog,
                defaultActiveLog
              );
            // Check SKU changes
            if (priceInfo?.SKU !== this.oldPriceInfo?.SKU) {
              pricingLogPayload?.detailedAction.push({
                action: "UPDATED",
                item: {
                  old: this.oldPriceInfo?.SKU,
                  new: priceInfo?.SKU,
                },
                property: "SKU",
              });
            } else if (!priceInfo?.SKU && this.oldPriceInfo?.SKU) {
              pricingLogPayload?.detailedAction.push({
                action: "REMOVED",
                item: this.oldPriceInfo?.SKU,
                property: "SKU",
              });
            } else if (priceInfo?.SKU && !this.oldPriceInfo?.SKU) {
              pricingLogPayload?.detailedAction.push({
                action: "ADDED",
                item: priceInfo?.SKU,
                property: "SKU",
              });
            }
            // Check MSRP changes
            if (priceInfo?.MSRP.value !== this.oldPriceInfo?.MSRP?.value) {
              pricingLogPayload?.detailedAction?.push({
                action: "UPDATED",
                item: {
                  old: this.oldPriceInfo?.MSRP?.value,
                  new: priceInfo?.MSRP?.value,
                },
                property: "MSRP",
              });
            } else if (
              !priceInfo?.MSRP.value &&
              this.oldPriceInfo?.MSRP?.value
            ) {
              pricingLogPayload?.detailedAction?.push({
                action: "REMOVED",
                item: this.oldPriceInfo?.MSRP?.value,
                property: "MSRP",
              });
            } else if (
              priceInfo?.MSRP.value &&
              !this.oldPriceInfo?.MSRP?.value
            ) {
              pricingLogPayload?.detailedAction.push({
                action: "ADDED",
                item: priceInfo?.MSRP?.value,
                property: "MSRP",
              });
            }
            console.log(
              pricingLogPayload.detailedAction,
              "pricingLogPayload.detailedAction"
            );

            if (pricingLogPayload.detailedAction.length > 0) {
              this.initLog(pricingLogPayload);
            }
          }
          console.log("loading media in all updateConstrait", updateConstrait);
          updateConstrait;
          this.ispricingEdit = false;
          // this.pricingData;
          (this.pricingPanelData = {
            SKU: "",
            default: false,
            MSRP: {
              unit: this.$store?.getters?.getDefaultSettings?.currency?.unit,
              value: 0,
            },
            endOfCommitment: {
              autoPay: true,
              expireTerm: false,
            },
            tiers: [
              {
                min: 1,
                max: 99,
                price: {
                  unit: this.$store?.getters?.getDefaultSettings?.currency
                    ?.unit,
                  value: 0,
                },
              },
            ],

            validFor: {
              startDate: new Date().toISOString(),
              endDate: "9999-12-31T00:00:00Z",
            },
          }),
            console.log(this.pricingData, "price data");
        }
        // return;
        this.notification = {
          type: "success",
          message: `All set! Your changes have been saved successfully!`,
        };
      } catch (error) {
        console.log(error, "error");
      }
    },
    async supplierUnitModify(val) {
      const suppliers = this.payloadSpec.relatedParty.filter(
        (party) => party.role === "supplier"
      );
      const uniqueNames = Array.from(
        new Set(suppliers.map((supplier) => supplier.name))
      );

      try {
        const updatedSuppliers = uniqueNames.map(async (name) => {
          const supplierGet = await UPCAxiosInstance.get(
            `/productStock?company=${name}`
          );
          const supplierLoop = supplierGet.data.map(async (supplierPayload) => {
            supplierPayload.stockedProduct.unitOfMeasure = val;
            const supplierPatch = await UPCAxiosInstance.patch(
              `/productStock?company=${name}`,
              supplierPayload
            );
            console.log(supplierPayload, supplierPatch, "supplierPayload");
          });
          await Promise.all(supplierLoop);
        });
        await Promise.all(updatedSuppliers);
      } catch (error) {
        console.error("Error updating supplier:", error);
      }
    },
    async supplierModify(item, pricingdata) {
      console.log(
        item,
        pricingdata,
        "........item, pricingdata..............."
      );

      try {
        const tableData = await UPCAxiosInstance.get(
          `/productStock?company=${item.name}`
        );
        const updatedSuppliers = tableData.data.map(async (itemsupplier) => {
          if (itemsupplier.stockedProduct.id === item.id) {
            itemsupplier.stockedProduct = {
              ...pricingdata,
              "@baseType": itemsupplier.stockedProduct["@baseType"],
              unitOfMeasure: this.payloadSpec.unitOfMeasure,
            };

            console.log(itemsupplier, ".................");

            const returndata = await UPCAxiosInstance.patch(
              `/productStock?company=${item.name}`,
              itemsupplier
            );
            console.log(returndata, "......returndata......");
          }
        });

        // Ensure all updates are completed
        await Promise.all(updatedSuppliers);
      } catch (error) {
        console.error("Error updating supplier:", error);
      }
    },
    incrementUpdateKey() {
      this.relatedProductPanel = true;
      this.updateSearchAndFilterKey += 1;
    },

    handleTierDelete(index) {
      this.pricingPanelData.tiers.splice(index, 1);
    },
    addTierToPricing() {
      let length = this.pricingPanelData.tiers.length;

      // Check the last tier in the array
      let lastTier = this.pricingPanelData.tiers[length - 1];

      if (lastTier.min !== undefined && lastTier.max !== undefined) {
        if (lastTier.min <= lastTier.max) {
          this.showTierError = false;

          // Create a deep copy of the default tier
          let newTier = JSON.parse(JSON.stringify(this.defaultTiers));

          // Set the new tier's min to be greater than the last tier's max
          newTier.min = lastTier.max + 1;

          // Add the new tier to the tiers array
          this.pricingPanelData.tiers.push(newTier);
        } else {
          this.showTierError = true;
          console.log("Invalid tier: min should be less than max.");
        }
      }
    },
    // addTierToPricing() {
    //   let length = this.pricingPanelData.tiers.length;

    //   let check = this.pricingPanelData.tiers[length - 1];

    //   if (check.min != undefined && check.max != undefined) {
    //     this.pricingPanelData.tiers = this.pricingPanelData.tiers.concat(
    //       JSON.parse(JSON.stringify(this.defaultTiers))
    //     );
    //   }
    // },

    async handlePricingDeleteToggle(i) {
      if (i.lifecycleStatus == "Active") {
        this.notification = {
          type: "Warning",
          message: "You can't delete active pricing",
        };
      } else {
        this.deleteIndex = i.id;
        this.delete_price_modal = true;
      }
    },
    async closedeleteModal() {
      this.delete_price_modal = false;
      let id = this.deleteIndex;

      console.log(id, "ddgg");
      if (id) {
        let index = this.pricingData.findIndex(
          (item) => item.id == id && item.priceType == this.selectedPrice
        );
        console.log("🚀 ~ handlePricingDeleteToggle ~ index:", index);
        if (index >= 0) {
          let pricingInfo = this.pricingData[index];
          this.pricingData.splice(index, 1);
          this.notification = {
            type: "success",
            message: "Your item removed from the table successfully",
          };
          console.log(
            this.payloadSpec?.constraint[0]?.data?.decisionId,
            "decisionId 123"
          );
          await DMNAxiosInstance.patch(
            `/decisionMatrics/` + this.$cookies.get("company"),
            {
              _id: this.payloadSpec?.constraint[0]?.data?.decisionId,
              matrix: this.pricingData,
              name: this.payloadSpec.name,
            }
          );
          this.payloadSpec.prodSpecCharValue = this.pricingData;
          await UPCAxiosInstance.patch(`/catalogManagement/productOffering`, {
            ...this.payloadSpec,
            id: this.payloadSpec._id,
          });

          let pricingDeleteLogPayload = {
            message: `${this.payloadSpec?.name} ${
              pricingInfo && pricingInfo.SKU ? "SKU " + pricingInfo.SKU : ""
            } pricing has been deleted`,
            detailedAction: [],
            "@type": "Offering-pricing",
            category: ["productOffering"],
            createdBy: this.$cookies.get("user"),
            user: this.$cookies.get("userName"),
            lastUpdate: new Date().toISOString(),
            ref: [this.payloadSpec?.id],
            action: "REMOVED",
            status: "active",
          };
          this.initLog(pricingDeleteLogPayload);

          console.log("loading media in all delete pricing");
        }
      }
    },
    async handlePricingEditToggle(priceData, index) {
      this.relatedProductPanel = false;
      try {
        console.log(
          "🚀 ~ handlePricingEditToggle ~ priceData:",
          priceData,
          index
        );
        this.currentIndex = index;
        this.featureObject.default = this.pricingPanelData.default;

        this.componentKey += 1;
        if (
          ["rental", "recurring", "pay per use"].includes(
            this.selectedPrice?.toLowerCase()
          )
        ) {
          this.pricingPanelData.commitmentLength = 1;
          this.pricingPanelData.billingPeriod = "Month";
          this.pricingPanelData.endOfCommitment = {
            autoPay: true,
            expireTerm: false,
          };
        }

        this.pricingPanelData = {
          ...this.pricingPanelData,
          ...JSON.parse(JSON.stringify(priceData)),
        };
        this.oldPriceInfo = JSON.parse(JSON.stringify(this.pricingPanelData));
        console.log(
          priceData,
          "this.pricingPanelData",
          this.pricingPanelData,
          this.pricingData
        );

        // this.priceIndex = index;

        this.ispricingEdit = !this.isPricingEdit;
        this.skucheck = false;
        this.endDateBoolean = false;
        this.startDateBoolean = false;
        this.startDateMsg = "";
        this.endDatemsg = "";
        this.priceValidation();
      } catch (error) {
        console.log(error);
      }
    },

    handlePricingBillingPeriod(selectedPeriod) {
      if (!selectedPeriod) return;
      this.pricingPanelData.billingPeriod = selectedPeriod.name;
      this.pricingPanelData.displayValue = selectedPeriod.value;
      this.isCommitmentLengthValid = false;

      if (selectedPeriod.name == "Biweekly") {
        this.pricingPanelData.commitmentLength = 2;
      } else if (selectedPeriod.name == "BiMonthly") {
        this.pricingPanelData.commitmentLength = 2;
      } else if (selectedPeriod.name == "Quarterly") {
        this.pricingPanelData.commitmentLength = 3;
      } else if (selectedPeriod.name == "Semi Annually") {
        this.pricingPanelData.commitmentLength = 6;
      } else {
        this.pricingPanelData.commitmentLength = 1;
      }
      this.checkOverlap();
    },
    bundleBillingPeriod1(val, index) {
      console.log("bundleBillingPeriod1...", val, index);

      this.bundleBillingPeriod(val);
    },

    bundleBillingPeriod(selectedPeriod) {
      if (!selectedPeriod) return;
      this.bundleTotalcheck.billingPeriod = selectedPeriod.name;
      this.bundleTotalcheck.displayValue = selectedPeriod.value;
      this.isCommitmentLengthValid = false;

      if (selectedPeriod.name == "Biweekly") {
        this.bundleTotalcheck.commitmentLength = 2;
      } else if (selectedPeriod.name == "BiMonthly") {
        this.bundleTotalcheck.commitmentLength = 2;
      } else if (selectedPeriod.name == "Quarterly") {
        this.bundleTotalcheck.commitmentLength = 3;
      } else if (selectedPeriod.name == "Semi Annually") {
        this.bundleTotalcheck.commitmentLength = 6;
      } else {
        this.bundleTotalcheck.commitmentLength = 1;
      }
      this.bundleTotal();
    },
    async categoryData() {
      try {
        console.log("kjhfj");
        const supplierFilterRes = await UPCAxiosInstance.post(
          "util/filterLayout",
          this.filterLayoutCategory
        );
        // state.suppliersFilterData = supplierFilterRes.data;
        console.log("productRecommed 3.0 2 category", supplierFilterRes.data);

        this.searchFilterCategoryData = supplierFilterRes.data;
      } catch (error) {
        console.error(error);
      }
    },
    async supplierFilterData() {
      try {
        console.log("kjhfj");
        const supplierFilterRes = await UPCAxiosInstance.post(
          "util/filterLayout",
          this.filterLayout
        );
        // state.suppliersFilterData = supplierFilterRes.data;
        console.log("productRecommed 3.0 2", supplierFilterRes.data);

        return await supplierFilterRes.data;
      } catch (error) {
        console.error(error);
      }
    },

    async getSearchedValue(value) {
      try {
        const filterPostQuery = await UPCAxiosInstance.post(
          `util/filterData`,
          value
        );

        const responseData = filterPostQuery.data.data;
        console.log(responseData, "responseData");
        this.mainInfo = responseData
          .map(
            ({
              unitOfMeasure,
              name,
              productOfferingPrice,
              id,
              category,
              prodSpecCharValue,
              productSpecification,
            }) => {
              const productType = productSpecification["@baseType"];
              const categoryType = this.leaf(category ? category : "");
              // console.log("leaf category 1", category);
              //  const categoryType = this.leaf(category)
              // console.log(categoryType, "leaf category 2");
              // console.log("attachmentName",name,prodSpecCharValue)
              let price = "0";
              let unit;

              let attachmentName = [];
              // const productType = "Tangible";
              console.log("prodSpecCharValue", prodSpecCharValue);
              // if (productType === "Tangible" || productType === null || productType === "") {
              // .filter((e) => e?.priceType.replace(/\s/g, "").toLowerCase() === "onetime")
              console.log("Form RelaPro tangible");
              try {
                attachmentName = prodSpecCharValue
                  .map((e) => {
                    if (!e?.attachment) {
                      // console.log("Error attachment:", e);
                      return [];
                    }
                    if (!Array.isArray(e.attachment)) {
                      // console.log("Error array:", e);
                      return [];
                    }
                    return e.attachment
                      .filter((att) => att?.preferred)
                      .map((att) => att?.name);
                  })
                  .flat();
                // console.log("Form RelaPro tangible", attachmentName);
              } catch (error) {
                console.log("Error processing prodSpecCharValue", error);
              }
              console.log("Form Intangible => tangible", attachmentName);
              // }

              //         if(productType==="Intangible"){
              //           console.log("Form RelaPro Intangible ")
              //           try {
              //             attachmentName = prodSpecCharValue
              //               .filter(
              //                 (e) =>
              //                   e?.priceType.toLowerCase() ===
              //                   "recurring"
              //               )
              //               .map((e) =>
              //   e?.attachment?.filter((att) => att?.preferred)?.map((att) => att?.name) || []
              // )
              //               .flat();
              //           } catch (error) {
              //             console.log("Error processing prodSpecCharValue", error);
              //           }
              //           console.log("Form Intangible",name,attachmentName)
              //         }

              if (productOfferingPrice?.length > 0) {
                // const productPrice = productOfferingPrice[0]?.productPrice;
                const updatedProductPrice = productOfferingPrice;
                if (updatedProductPrice) {
                  let priceRange = updatedProductPrice
                    ?.map((e) => e?.tiers?.map((tier) => tier?.price))
                    .flat(Infinity);
                  price = this.getMinMax(priceRange);
                  unit = priceRange[0]?.unit;
                }

                price =
                  price !== undefined || price !== null || !isNaN(price)
                    ? price
                    : 0;

                unit = unit !== undefined || unit !== null ? unit : unit;
                console.log(unit);
                return {
                  unitOfMeasure: unitOfMeasure ? unitOfMeasure : "",
                  billingPeroid: prodSpecCharValue[0]?.billingPeriod,
                  type: productType,
                  name: name,
                  previewPrice: price,
                  _id: id,
                  category: categoryType,
                  defaultImageName:
                    attachmentName.length > 0 ? attachmentName[0] : null,
                };
              } else {
                return {
                  unitOfMeasure: unitOfMeasure,
                  billingPeroid: prodSpecCharValue[0]?.billingPeriod,
                  type: productType,
                  name: name,
                  previewPrice: this.currencyFormatter(0),
                  _id: id,
                  category: categoryType,
                  defaultImageName:
                    attachmentName.length > 0 ? attachmentName[0] : null,
                };
              }
            }
          )
          .filter(
            (product) =>
              !this.relatedProducts.find(
                (relatedProduct) => relatedProduct?._id === product?._id
              )
          );
        this.mainInfo = this.mainInfo.filter(
          (product) =>
            !this.relatedProducts.includes(product?._id) &&
            product?.name !== this?.productName
        );
        console.log(this.productName, "responseData productName");
        console.log(this.relatedProducts, "responseData this.relatedProducts");
        console.log(this.mainInfo, "responseData this.relatedProducts 1");
      } catch (error) {
        console.log("error from getsearchedvalue", error);
      }
    },
    getMinMax(arr) {
      if (!arr || arr.length === 0) {
        return this.currencyFormatter(0, {
          currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
        });
      }

      let values = arr.map((item) => item?.value);
      values = values.filter(
        (value) => value !== undefined && value !== null && !isNaN(value)
      );
      let min = Math.min(...values);
      let max = Math.max(...values);
      min = min !== undefined && min !== null && !isNaN(min) ? min : 0;
      max = max !== undefined && max !== null && !isNaN(max) ? max : 0;
      console.log(values, min, "getMinMax", max);
      return this.currencyFormatter(max, {
        currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
      });
    },
    removeDuplicates() {
      // this.tableData = this.tableData.filter((item, index, self) =>
      //   index === self.findIndex((t) => (
      //     t.id === item.id && t.name === item.name
      //   ))
      // );
      const uniqueData = [
        ...new Map(this.tableData.map((item) => [item.id, item])).values(),
      ];
      this.tableData = uniqueData;
    },

    async getcheckedValue(value) {
      this.productRecommed = value;
      console.log(this.productRecommed, "productRecommed");
      console.log("I am from Overview :", value);
    },
    getRemovedValue(val) {
      console.log("getRemovedValue", val);
    },
    async getCheckboxData() {},

    async getConfigurableOptions(specCharacteristics) {
      console.log(
        "🚀 ~ getConfigurableOptions ~ specCharacteristics:",
        specCharacteristics
      );
      const configurableOptions = {};

      specCharacteristics.forEach((characteristic) => {
        if (characteristic.configurable) {
          console.log(
            characteristic.productSpecCharacteristicValue,
            "for valueSuffix changes"
          );
          const options = characteristic.productSpecCharacteristicValue.map(
            (value) => (value.value + " " + value.valueSuffix).trim()
          );

          configurableOptions[characteristic.name] = options;
        }
        // else if(!characteristic.configurable && this.payloadSpec&& this.payloadSpec.productSpecification && this.payloadSpec.productSpecification['@baseType'] == 'Intangible') {
        //    const options = characteristic.productSpecCharacteristicValue.map(
        //     (value) =>( value.value + " " + value.valueSuffix).trim()
        //   );
        //   configurableOptions[characteristic.name] = options;

        // }
      });

      return await configurableOptions;
    },

    async findNewObject(obj1, obj2) {
      let ids1 = obj1.map((item) => item.id);
      let newObjects = obj2.filter((item) => !ids1.includes(item.id));

      // Additionally check within productSpecCharacteristicValue arrays
      if (newObjects.length === 0) {
        for (let item2 of obj2) {
          let matchingItem1 = obj1.find((item1) => item1.id === item2.id);
          if (matchingItem1) {
            let nestedValues1 = matchingItem1.productSpecCharacteristicValue;
            let nestedValues2 = item2.productSpecCharacteristicValue;
            let newNestedValues = nestedValues2.filter(
              (nv2) =>
                !nestedValues1.some(
                  (nv1) => JSON.stringify(nv1) === JSON.stringify(nv2)
                )
            );
            if (newNestedValues.length > 0) {
              newObjects.push({
                id: item2.id,
                productSpecCharacteristicValue: newNestedValues,
              });
            }
          }
        }
      }

      return newObjects;
    },
    async addproducts() {
      // console.log("supplier filter overview ", supplierFilterRes.data);
      this.componentkey += 1;
      // this.updateKey+=1;
      console.log("productRecommed", this.productRecommed);
      console.log(this.payloadSpec, "Find the related party");
      this.relatedProductPanel = false;
      this.payloadSpec.relatedProduct = this.payloadSpec.relatedProduct.concat(
        this.productRecommed
      );

      this.relatedProducts = this.payloadSpec.relatedProduct;
      console.log(this.relatedProducts, "ActivitylogrelatedProducts");
      // delete this.payloadSpec._id;
      await UPCAxiosInstance.patch(`/catalogManagement/productOffering`, {
        ...this.payloadSpec,
        id: this.payloadSpec._id,
      });
      let addedRelatedProductsLogPayload = {
        message: `${this.payloadSpec?.name} Recommended products  has been added`,
        detailedAction: this.productRecommed.map((value) => {
          return {
            item: value,
            action: "ADDED",
            property: "Recommended products ",
          };
        }),
        // {
        //   action: "ADDED",
        //   item: this.productRecommed,
        //   property: "Related Products",
        // },
        "@type": "Offering-related-products",
        category: ["productOffering"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: [this.payloadSpec?.id],
        action: "ADDED",
      };

      this.notification = {
        type: "success",
        message: `Your item is added successfully!.`,
      };
      this.initLog(addedRelatedProductsLogPayload);
      // this.filterLayout
      await this.interelatedProducts(this.relatedProducts);
      console.log(this.relatedProducts, "productRecommed 2.0");
      this.addIdFilterLayout(this.relatedProducts);
      this.refreshRelatedProductTable();
    },

    async addIdFilterLayout(relatedProducts) {
      this.filterLayout.filterQuery.id.$nin.push(this.relatedProductParams);
      console.log(this.relatedProducts, "productRecommed 3.0 whole ");
      relatedProducts.forEach((product) => {
        const id = product._id;
        console.log("id in id", id);
        if (!this.filterLayout.filterQuery.id.$nin.includes(id)) {
          this.filterLayout.filterQuery.id.$nin.push(id);
        }
      });

      console.log(this.filterLayout, "productRecommed 3.0 filterLayout");
      this.supplierFilterData();
      this.searchFilterData = await this.supplierFilterData();
    },
    async removeIdFilterLayout(val) {
      console.log("productRecommed 3.0 removed id", val);
      const id = val._id;
      const idIndex = this.filterLayout.filterQuery.id.$nin.indexOf(id);
      if (idIndex !== -1) {
        this.filterLayout.filterQuery.id.$nin.splice(idIndex, 1);
      }
      this.supplierFilterData();
      this.searchFilterData = await this.supplierFilterData();
    },

    async interelatedProducts(data) {
      const findAndUpdate = await data.map(async (value) => {
        let getValue = await UPCAxiosInstance.get(
          `/catalogManagement/productOffering/${value._id}`
        );
        console.log("relatedProducts interrelated data", data);

        let val = await getValue.data;
        console.log("relatedProducts interrelated val", val);
        let relatedProducts = await val?.relatedProduct;
        console.log("relatedProducts interrelated", relatedProducts);
        // const isProductRelated = relatedProducts.relatedProduct.some((ele) => ele._id === data._id);
        let isProductRelated = relatedProducts.some((element) => {
          console.log(element, "isProductRelated for inter", value, data);
          if (
            element?._id === this.payloadSpec?._id ||
            element?._id === this.payloadSpec?.id
          ) {
            //element?._id === data?._id
            console.log("relatedProducts interrelated if", data);
            return true;
          } else {
            return false;
          }
        });
        if (isProductRelated) {
          return;
        } else {
          console.log("relatedProducts interrelated else", relatedProducts);
          relatedProducts = await relatedProducts.concat([this.payloadSpec]);
          // val.relatedProduct = relatedProducts;

          val.id = val._id;
          delete val._id;
          val.relatedProduct = relatedProducts.map((item) => {
            const productType = item?.productSpecification?.["@baseType"];
            let attachmentName = [];
            let price = "0";
            // let unit;
            try {
              attachmentName = item?.prodSpecCharValue
                .map((e) => {
                  if (!e?.attachment) {
                    return [];
                  }
                  if (!Array.isArray(e.attachment)) {
                    return [];
                  }
                  return e.attachment
                    .filter((att) => att?.preferred)
                    .map((att) => att?.name);
                })
                .flat();
              if (item?.productOfferingPrice?.length > 0) {
                const updatedProductPrice = item?.productOfferingPrice;
                if (updatedProductPrice) {
                  let priceRange = updatedProductPrice
                    ?.map((e) => e?.tiers?.map((tier) => tier?.price))
                    .flat(Infinity);
                  price = this.getMinMax(priceRange);
                  // unit = priceRange[0]?.unit;
                }

                price =
                  price !== undefined || price !== null || !isNaN(price)
                    ? price
                    : 0;

                // unit = unit !== undefined || unit !== null ? unit : unit;
              }
            } catch (error) {
              console.log(
                "Error processing prodSpecCharValue interelated func",
                error
              );
            }
            let relatedProduct = {
              _id: item?._id,
              unitOfMeasure: item?.unitOfMeasure ? item?.unitOfMeasure : "",
              type: productType,
              defaultImageName:
                attachmentName.length > 0 ? attachmentName[0] : null,
              name: item?.name,
              category: this.leaf(item.category),
              billingPeroid: item?.prodSpecCharValue?.[0]?.billingPeriod,
              previewPrice: price,
              // price:
              //   item?.prodSpecCharValue?.map((ele) => ele?.tiers?.[0]?.price) || [],
            };
            return relatedProduct;
          });

          console.log(val, "checkk relatedparty");
          let productOffer = await UPCAxiosInstance.patch(
            `/catalogManagement/productOffering`,
            {
              ...val,
            }
          );

          let interRelatedProductsLogPayload = {
            message: `${val?.name} Recommended products  has been added`,
            detailedAction: [
              {
                action: "ADDED",
                item: this.payloadSpec,
                property: "Recommended products ",
              },
            ],
            "@type": "Offering-related-products",
            category: ["productOffering"],
            createdBy: this.$cookies.get("user"),
            user: this.$cookies.get("userName"),
            lastUpdate: new Date().toISOString(),
            ref: [val?.id],
            action: "ADDED",
          };
          this.initLog(interRelatedProductsLogPayload);
          console.log("loading media in all interelatedProducts");
          console.log(await productOffer.data, "interrelated");

          return await productOffer.data;
        }
      });

      console.log(await findAndUpdate, "found successfully");
    },
    async handleFeatureConfigV2(selectedFeatures, index, feature) {
      this.deleteSupplierArrayAfterSpecChange = [];
      /***************** */
      function findDifference(oldArray, newArray, key) {
        // Create a map from the new array for quick lookup
        const newMap = new Map(newArray.map((item) => [item[key], item]));

        // Find objects to remove (present in oldArray but not in newArray)
        const toRemove = oldArray.filter((item) => !newMap.has(item[key]));

        // Find objects to add (present in newArray but not in oldArray)
        const oldMap = new Map(oldArray.map((item) => [item[key], item]));
        const toAdd = newArray.filter((item) => !oldMap.has(item[key]));

        return { toRemove, toAdd };
      }

      console.log(
        JSON.stringify(selectedFeatures, null, 2),
        JSON.stringify(feature, null, 2),
        "Feature change"
      );
      let previousFeatureList = this.payloadSpec.prodSpecCharValueUse?.find(
        (item) => item.name == feature.name
      );
      console.log(
        this.payloadSpec.prodSpecCharValueUse,
        "previousFeatureList feature change",
        previousFeatureList
      );
      if (previousFeatureList) {
        // let differences = DeepDiff.diff(
        //   previousFeatureList.productSpecCharacteristicValue?.map(item => item.value),
        //   selectedFeatures?.map(item => item.value),
        // );
        let differences = findDifference(
          previousFeatureList.productSpecCharacteristicValue,
          selectedFeatures,
          "value"
        );
        console.log(differences, "Feature change  differences A");
        if (differences && differences.toAdd && differences.toAdd.length > 0) {
          let localProdSpecCharValueUse = this.findOneAndUpdate(
            JSON.parse(JSON.stringify(this.payloadSpec.prodSpecCharValueUse)),
            "name",
            feature.name,
            {
              ...feature,
              productSpecCharacteristicValue: differences.toAdd,
            }
          );
          console.log(
            "🚀 ~ handleFeatureConfigV2 ~ selectedFeatures: Feature change",
            selectedFeatures
          );
          let matrixPayload = await this.getConfigurableOptions(
            localProdSpecCharValueUse
          );
          console.log(matrixPayload, "Feature change matrixPayload");
          let constrait = await DMNAxiosInstance.post(
            `/decisionMatrics/autopermute`,
            matrixPayload
          );
          console.log("constrait", constrait);
          let priceSection = constrait.data.map((permute) => {
            const id = uuidv4();
            const loopedPricing = this.offerPriceTypes.map((item) => {
              console.log("🚀 ~ priceSection ~ item:", item);
              let dataObject = {
                id,
                priceType: item.name,
                tiers: [
                  {
                    min: 1,
                    max: 99,
                    price: {
                      unit: this.$store?.getters?.getDefaultSettings?.currency
                        ?.unit,
                      value: 0,
                    },
                  },
                ],
                ...permute,
              };
              if (
                ["recurring", "rental", "pay per use", "installment"].includes(
                  item?.name?.toLowerCase()
                )
              ) {
                dataObject.commitmentLength = 1;
                dataObject.billingPeriod = "Monthly";
                dataObject.displayValue = "Months";
                dataObject.endOfCommitment = {
                  autoPay: true,
                  expireTerm: false,
                };
              }
              if (this.categoryInfo?.type == "Tangible") {
                dataObject.SKU = "";
                dataObject.MSRP = {
                  unit: this.$store?.getters?.getDefaultSettings?.currency
                    ?.unit,
                  value: 0,
                };
              }
              return dataObject;
            });
            console.log(
              "🚀 ~ priceSection ~ loopedPricing:",
              loopedPricing,
              this.categoryInfo
            );
            return loopedPricing.flat(2);
          });
          console.log(priceSection, "auto permutate");
          let prodSpecCharValue = await priceSection.flat();
          console.log(
            prodSpecCharValue,
            "Feature price",
            this.payloadSpec.prodSpecCharValue
          );
          this.payloadSpec.prodSpecCharValue =
            this.payloadSpec.prodSpecCharValue.concat(prodSpecCharValue);

          // Function to add Supplier to the new features
          this.addSupplierFeatureChanged(prodSpecCharValue);
          // let newAddedprodSpecCharValue = prodSpecCharValue
          // console.log(this.payloadSpec.prodSpecCharValue, "yes incoming variable");
          // // this.payloadSpec.id = this.payloadSpec._id;
          // let updateConstrait = await UPCAxiosInstance.patch(
          //   `/catalogManagement/productOffering`,
          //   { ...this.payloadSpec, id: this.payloadSpec._id }
          // );
          // console.log(await updateConstrait.data, "constraitttt");
          // if (updateConstrait.data.prodSpecCharValue) {
          //   this.pricingData = await updateConstrait.data.prodSpecCharValue;
          // }
          // this.matrixPaginated;
        } else if (
          differences &&
          differences.toRemove &&
          differences.toRemove.length > 0
        ) {
          console.log(
            "🚀 ~ handleFeatureConfigV2 ~ this.payloadSpec.prodSpecCharValue: Feature change differences B",
            this.payloadSpec.prodSpecCharValue
          );
          let prodSpecCharValue = this.payloadSpec.prodSpecCharValue.filter(
            (item) => {
              console.log(
                "🚀 ~ handleFeatureConfigV2 ~ item: Feature change",
                item
              );
              let isValueNotPresent = differences.toRemove.some(
                (removeItem) => {
                  let valueToCompare =
                    removeItem.value + " " + removeItem.valueSuffix;
                  valueToCompare = valueToCompare.trim();
                  return valueToCompare == item[feature.name];
                }
              );
              console.log(
                "🚀 ~ handleFeatureConfigV2 ~ Feature change isValueNotPresent:",
                isValueNotPresent
              );
              if (item && isValueNotPresent) {
                this.deleteSupplierArrayAfterSpecChange.push(item);
              }

              return !isValueNotPresent;
            }
          );
          if (prodSpecCharValue.length > 0) {
            this.payloadSpec.prodSpecCharValue = prodSpecCharValue;
          }

          console.log(
            this.payloadSpec.prodSpecCharValue,
            "Feature change price"
          );
          //   this.payloadSpec.prodSpecCharValue = await prodSpecCharValue;
          // console.log(this.payloadSpec.prodSpecCharValue, "yes incoming variable");
          // this.payloadSpec.id = this.payloadSpec._id;
        }
      }
      /***************** */
      console.log(selectedFeatures, "Feature change selectedFeatures");
      this.payloadSpec.prodSpecCharValueUse = this.findOneAndUpdate(
        JSON.parse(JSON.stringify(this.payloadSpec.prodSpecCharValueUse)),
        "name",
        feature.name,
        {
          ...feature,
          productSpecCharacteristicValue: selectedFeatures,
        }
      );
      let updateConstrait = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        { ...this.payloadSpec, id: this.payloadSpec._id }
      );
      console.log(await updateConstrait.data, "constraitttt Feature change");
      if (updateConstrait.data.prodSpecCharValue) {
        this.pricingData = await updateConstrait.data.prodSpecCharValue;
        let featureValueChangeLog =
          this.buildLogBasedOnDifferencesForArrayOfObjects(
            previousFeatureList.productSpecCharacteristicValue,
            selectedFeatures,
            "value",
            "Feature"
          );
        let OverviewFeatureLog = {
          message: `${this.payloadSpec?.name} overview feature ${feature.name} has been updated`,
          detailedAction: featureValueChangeLog,
          action: "UPDATED",
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          createdBy: this.$cookies.get("user"),
          ref: [this.payloadSpec?._id],
          "@type": "Overview",
          category: ["productOffering"],
        };
        if (OverviewFeatureLog.detailedAction.length > 0) {
          this.initLog(OverviewFeatureLog);
        }
        // this.initLog(OverviewFeatureLog);
      }
      this.matrixPaginated;
      this.speedData[index] = this.prepopulate(
        this.payloadSpec.prodSpecCharValueUse,
        "name",
        feature.name,
        feature.productSpecCharacteristicValue
      );

      if (this.deleteSupplierArrayAfterSpecChange.length > 0) {
        console.log(
          this.deleteSupplierArrayAfterSpecChange.length,
          "this.deleteSupplierArrayAfterSpecChange",
          this.deleteSupplierArrayAfterSpecChange
        );
        this.deleteSupplierFeatureChanged(
          this.deleteSupplierArrayAfterSpecChange
        );
      }
      /********************** */

      // this.selectKey +=1

      // let arr = [];
      // if (selectedFeatures.length != 0) {
      //   selectedFeatures.forEach((item) => {
      //     arr.push(item.displayValue);
      //   });
      // }
      // this.speedData[index] = arr;

      // //identifying the changes in the pricing

      // let oldSpecChar = this.payloadSpec.prodSpecCharValueUse;
      // let prodSpecCharValueUse = this.findOneAndUpdate(
      //   JSON.parse(JSON.stringify(this.payloadSpec.prodSpecCharValueUse)),
      //   "name",
      //   feature.name,
      //   {
      //     ...feature,
      //     productSpecCharacteristicValue: selectedFeatures,
      //   }
      // );

      // var availableDifferences = DeepDiff.diff(oldSpecChar.productSpecCharacteristicValue?.map(item => item.value),prodSpecCharValueUse?.productSpecCharacteristicValue.map(item => item.value));
      // console.log(availableDifferences,'difference',oldSpecChar, prodSpecCharValueUse);
      // if(availableDifferences[0] != undefined && availableDifferences[0].item?.rhs != undefined){

      //   // console.log(difference,'difference',availableDifferences[0].item.rhs.value,feature.name)
      //   this.matrixPayload = await this.getConfigurableOptions(this.payloadSpec.prodSpecCharValueUse);
      //    let arr1 = []
      //   //  console.log(arr1,'difference')
      //      arr1.push(availableDifferences[0].item.rhs.value)
      //      this.matrixPayload[feature.name] = arr1

      //      const autopermutate = await DMNAxiosInstance.post(
      //         `/decisionMatrics/autopermute`,
      //         this.matrixPayload
      //       );
      //       console.log(autopermutate,';;',this.matrixPayload)
      //       let priceSection = autopermutate.data.map((permute) => {
      //   const id = uuidv4();
      //   const loopedPricing = this.offerPriceTypes.map((item) => {
      //     let dataObject = {
      //       id,
      //       priceType: item.name,
      //       tiers: [
      //         {
      //           min: 1,
      //           max: 99,
      //           price: {
      //             unit: this.$store?.getters?.getDefaultSettings?.currency
      //               ?.unit,
      //             value: 0,
      //           },
      //         },
      //       ],
      //       ...permute,
      //     };
      //     if (
      //       ["recurring", "rental", "pay per use", "installment"].includes(
      //         item?.name?.toLowerCase()
      //       )
      //     ) {
      //       dataObject.commitmentLength = 1;
      //       dataObject.billingPeriod = "Month";
      //       dataObject.endOfCommitment = {
      //         autoPay: true,
      //         expireTerm: false,
      //       };
      //     }
      //     if (this.categoryInfo?.type == "Tangible") {
      //       dataObject.SKU = "";
      //       dataObject.MSRP = {
      //         unit: this.$store?.getters?.getDefaultSettings?.currency?.unit,
      //         value: 0,
      //       };
      //     }
      //     return dataObject;
      //   });
      //   console.log("🚀 ~ priceSection ~ loopedPricing:", loopedPricing);
      //   return loopedPricing.flat(2);
      // });
      // console.log(priceSection, "auto permutate");

      // let prodSpecCharValue = await priceSection.flat();
      // this.payloadSpec.prodSpecCharValue = this.payloadSpec.prodSpecCharValue.concat(prodSpecCharValue)
      // console.log(this.payloadSpec.prodSpecCharValue, "yes incoming variable");
      // // this.payloadSpec.id = this.payloadSpec._id;
      // let updateConstrait = await UPCAxiosInstance.patch(
      //   `/catalogManagement/productOffering`,
      //   { ...this.payloadSpec, id: this.payloadSpec._id }
      // );
      // console.log(updateConstrait,'data')
      //       // this.payloadSpec.prodSpecCharValue

      //  }
      //  else{
      //   let matchCase = await availableDifferences[0].item.lhs?.value
      //   let removeArrayValues = this.payloadSpec.prodSpecCharValue.filter(item => item[feature.name] != matchCase);
      //    console.log(availableDifferences[0].item?.lhs.value,'remove difference');
      // // this.payloadSpec.id = this.payloadSpec._id;
      // this.payloadSpec.prodSpecCharValue = removeArrayValues

      // let updateConstrait = await UPCAxiosInstance.patch(
      //   `/catalogManagement/productOffering`,
      //   { ...this.payloadSpec, id: this.payloadSpec._id }
      // );
      // console.log(updateConstrait.data,'remove difference',removeArrayValues)
      //  }

      // this.matrixPayload = await this.getConfigurableOptions(this.payloadSpec.prodSpecCharValueUse);

      //   this.payloadSpec.prodSpecCharValueUse = this.findOneAndUpdate(
      //   JSON.parse(JSON.stringify(this.payloadSpec.prodSpecCharValueUse)),
      //   "name",
      //   feature.name,
      //   {
      //     ...feature,
      //     productSpecCharacteristicValue: selectedFeatures,
      //   }
      // );
      // console.log( JSON.parse(JSON.stringify(this.payloadSpec.prodSpecCharValueUse)),
      //   "name",
      //   feature.name,
      //   {
      //     ...feature,
      //     productSpecCharacteristicValue: selectedFeatures,
      //   },
      //   this.payloadSpec.prodSpecCharValueUse,
      //   'findoneandupdate1'
      // );
      // console.log(
      //   "🚀 ~ handleFeatureConfig:",
      //   this.payloadSpec.prodSpecCharValueUse
      // );
      // this.uploadPerumutation(this.payloadSpec.prodSpecCharValueUse);

      //  }
      // console.log(
      //   "🚀 ~ handleFeatureConfig ~ selectedFeatures, difference:",
      //   oldSpecChar,
      //   prodSpecCharValueUse,difference
      // );

      // console.log(
      //   "🚀 ~ handleFeatureConfig ~ selectedFeatures, feature: 1.1",
      //   this.payloadSpec.prodSpecCharValueUse
      // );
      // this.uploadPerumutation(prodSpecCharValueUse);
    },
    handleFeatureConfig(selectedFeatures, index, feature) {
      console.log(selectedFeatures, "uploadPerumutation", feature);
      this.handleFeatureConfigV2(selectedFeatures, index, feature);
      // this.uploadPerumutation(this.payloadSpec.prodSpecCharValueUse);
      // console.log(
      //   "🚀 ~ handleFeatureConfig ~ selectedFeatures, feature:",
      //   selectedFeatures,
      //   feature,
      //   this.pricingData
      // );
      // console.log(selectedFeatures,
      //   feature,
      //   this.pricingData,'handlefeatureConfigV22');
      // // this.selectKey +=1
      // let arr = [];
      // if (selectedFeatures.length != 0) {
      //   selectedFeatures.forEach((item) => {
      //     arr.push(item.displayValue);
      //   });
      // }
      // this.speedData[index] = arr;
      // console.log( JSON.parse(JSON.stringify(this.payloadSpec.prodSpecCharValueUse)),
      //   "name",
      //   feature.name,
      //   {
      //     ...feature,
      //     productSpecCharacteristicValue: selectedFeatures,
      //   },
      //   'findoneandupdate'
      // );
      // this.payloadSpec.prodSpecCharValueUse = this.findOneAndUpdate(
      //   JSON.parse(JSON.stringify(this.payloadSpec.prodSpecCharValueUse)),
      //   "name",
      //   feature.name,
      //   {
      //     ...feature,
      //     productSpecCharacteristicValue: selectedFeatures,
      //   }
      // );
      // console.log( JSON.parse(JSON.stringify(this.payloadSpec.prodSpecCharValueUse)),
      //   "name",
      //   feature.name,
      //   {
      //     ...feature,
      //     productSpecCharacteristicValue: selectedFeatures,
      //   },
      //   this.payloadSpec.prodSpecCharValueUse,
      //   'findoneandupdate1'
      // );
      // console.log(
      //   "🚀 ~ handleFeatureConfig:",
      //   this.payloadSpec.prodSpecCharValueUse
      // );
    },
    handleNonConfigurableFeature(selectedFeatures, feature) {
      this.payloadSpec.prodSpecCharValueUse = this.findOneAndUpdate(
        JSON.parse(JSON.stringify(this.payloadSpec.prodSpecCharValueUse)),
        "name",
        feature.name,

        {
          ...feature,
          productSpecCharacteristicValue: [selectedFeatures],
          default: {
            value: selectedFeatures.value,
            valueSuffix: selectedFeatures.valueSuffix,
          },
        }
      );
      console.log(
        "🚀 ~ handleNonConfigurableFeature ~ this.payloadSpec.prodSpecCharValueUse:",
        this.payloadSpec.prodSpecCharValueUse
      );
      // this.uploadPerumutation(this.payloadSpec.prodSpecCharValueUse);
    },
    prePopulateNonConfigurableFeaturePrevalue(array, key, value, displaylist) {
      console.log(
        "🚀 ~ prePopulateNonConfigurableFeaturePrevalue ~ array, key, value, displaylist:",
        array,
        key,
        value,
        displaylist
      );
      const isSpecCharValueUse = array?.find((item) => item[key] == value);
      if (isSpecCharValueUse) {
        if (isSpecCharValueUse.default && isSpecCharValueUse.default.value) {
          return (
            isSpecCharValueUse.default?.value +
            " " +
            isSpecCharValueUse.default?.valueSuffix
          );
        }
        return (
          isSpecCharValueUse.productSpecCharacteristicValue[0]?.value +
          " " +
          isSpecCharValueUse.productSpecCharacteristicValue[0]?.valueSuffix
        );
      }
      return displaylist[0]?.value + " " + displaylist[0]?.valueSuffix;
    },
    prepopulate(array, key, value, displaylist) {
      const isSpecCharValueUse = array?.find((item) => item[key] == value);
      if (isSpecCharValueUse) {
        return isSpecCharValueUse.productSpecCharacteristicValue?.map(
          (item) => item.value + " " + item.valueSuffix
        );
      }
      return displaylist?.map((item) => item.value + " " + item?.valueSuffix);
    },

    // prepopulate(array, key, value, displaylist) {
    //   console.log(this.spec.productSpecCharacteristic,this.payloadSpec,'dattta');
    //   const isSpecCharValueUse = array?.find((item) => item[key] == value);
    //   if (isSpecCharValueUse) {
    //     return isSpecCharValueUse.productSpecCharacteristicValue?.map(
    //       (item) => item.value
    //     );
    //   }
    //   return displaylist?.map((item) => item.value);
    // },
    async handleSupplier(val) {
      // Added promise all and changed if condition - varsan
      this.selectedSuppliers = [];
      if (val) {
        val.forEach((e) => {
          if (!this.selectedSuppliers.some((val) => val._id == e._id)) {
            this.selectedSuppliers.push(e);
          }
        });
      }
      // this.selectedSuppliers.length>0?val.forEach(e=> if(!this.selectedSuppliers.some((val)=>val._id == e._id)){this.selectedSuppliers.push(e))}:this.selectedSuppliers=val
      console.log(
        val,
        "supplierList intangible select",
        this.selectedSuppliers
      );
      console.log(
        "supplierList intangible B supplierList intangible B",
        this.payloadSpec
      );
      // this.supplierProfile = [];
      // this.featureObj = [];
      // console.log("handleSupplier data", val);

      // let featureObj = val;
      let fetchObj = await Promise.all(
        val.map(async (itemA) => {
          console.log(itemA, "supplierList intangible B");
          let found = await Promise.all(
            this.payloadSpec?.prodSpecCharValue.map((itemB) => {
              console.log(itemB, "supplierList intangible B");
              // console.log(
              //   "handleSupplier data itemB",
              //   this.payloadSpec?.prodSpecCharValue
              // );
              // console.log("handleSupplier data itemB", itemB);
              // itemB?.lifecycleStatus?.toLowerCase() === "active"
              // let trueOrFalse = this.supplierTableData.some(item =>{ if(item.id == itemB.id){
              //   console.log(itemB.id,"trueOrFalse",item.id,"true")
              // }
              // console.log(itemB.id,"trueOrFalse",item.id,"false")
              // })
              console.log(
                "trueOrFalseOuter",
                itemB?.lifecycleStatus?.toLowerCase()
              );
              if (itemB) {
                let supplierEntry = {
                  customName: itemA?.customName,
                  name: itemA?.name,
                  role: "supplier",
                  externalID: itemA?._id,
                  leadtime: `${itemA?.externalReference[1]?.leadTime} ${itemA?.externalReference[1]?.leadPeriod}`,
                  sku: itemB.SKU,
                  id: itemB.id,
                  priceType: itemB.priceType,
                  tiers: itemB?.tiers,
                  msrp: itemB.MSRP,
                  displayId:
                    Math.floor(Math.random() * (9999999999 - 1000000000 + 1)) +
                    1000000000,
                  // quantity: itemA?.productStockLevel,
                  isDefault: this.supplierTableData.length === 0 ? true : false,
                };
                console.log(
                  "supplierList intangible found entarnce",
                  supplierEntry
                );

                if (Array.isArray(itemA?.attachment)) {
                  itemA.attachment.forEach((attachment) => {
                    if (attachment?.["@type"]?.toLowerCase() === "profile") {
                      supplierEntry.attachmentName = attachment?.name;
                    }
                  });
                }

                return supplierEntry;
              }
            })
          );
          return found;
        })
      );

      this.featureObj = await fetchObj;

      // console.log("supplierList intangible", this.supplierProfile);
      console.log("supplierList intangible 89", this.featureObj);
    },
    handleProduct(val) {
      this.productRecommed = val;
    },

    async listsupplier(val) {
      console.log("searchSupplier listsupplier", val);
      const filterData = {
        company: this.company,
        type: "filter",
        module: "suppliers",
        isPaginated: true,
        lastUpdated: new Date().toJSON(),
        paginatedQuery: {
          rowsPerPage: this.rowsPerPage,
          currentPage: this.currPage,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 2,
        filterQuery: {},
        searchQuery: val,
      };

      const access = await MLTAxiosInstance.post(
        `util/filterData?company=${this.company}`,
        filterData
      );

      this.supplier = access.data;
      console.log("Supplier first", this.supplier);
      this.supplier = this.supplier.filter(
        (ele) => !this.tableData.includes(ele.id)
      );
      console.log("Supplier last ", this.supplier);

      console.log(access.data);
      console.log("Add supplier list", access.data);
    },
    marketValues(val) {
      console.log(val, "marketSegment");
      let arr = [];
      val.forEach((item) => {
        arr.push(item.name);
      });
      this.marketPreValue = arr;
      this.offerPayload.marketSegment = val;
      this.updatedData.marketSegment = val;
      this.payloadSpec.marketSegment = this.offerPayload.marketSegment;
    },
    channelValues(val) {
      let arr = [];
      val.forEach((item) => {
        arr.push(item.name);
      });
      this.channelPreValue = arr;
      this.offerPayload.channel = val;
      this.updatedData.channel = val;
      this.payloadSpec.channel = this.offerPayload.channel;
    },
    details(ele) {
      this.$router.push(
        `/UPC/product-catalog-table/productspecification/overview/${ele.name}/${ele._id}/false`
      );
    },
    leaf(text) {
      let lengthOfText = text?.length;
      if (typeof text === "string") {
        return text;
      }
      if (lengthOfText === 1) {
        console.log("leaf category text length final o/p ", text);
        return (text = text.toString());
      }
      if (text && lengthOfText > 1) {
        const total = text?.length;
        console.log(
          text,
          "leaf category text length final o/p",
          text[total - 1]
        );

        return text[total - 1];
      }
    },
    async deleteNote(val) {
      console.log(
        val,
        "deletednotes ----------------------------------------------------------"
      );
      const deteledNotesLogPayload = {
        message: `${this.payloadSpec?.name} note has been deleted`,
        detailedAction: [
          {
            item: this.notesTable[val].note,
            action: "REMOVED",
            property: "Note",
          },
        ],
        action: "REMOVED",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.payloadSpec?._id],
        "@type": "Offering-note",
        category: ["productOffering"],
      };
      this.payloadSpec.notes.splice(val, 1);
      await UPCAxiosInstance.patch(`/catalogManagement/productOffering`, {
        ...this.payloadSpec,
        id: this.payloadSpec._id,
      });

      this.initLog(deteledNotesLogPayload);

      let productSpec = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/${this.$route.params.id}`
      );
      console.log("loading media in all deletenote");
      this.notesTable = productSpec.data.notes;
    },
    async deleteAttachmentRow(val) {
      this.delete_name = this.attachmentData[val - 1].name;
      this.attachmentData.splice(val - 1, 1);
      let attachmentLogPayload = {
        message: `${this.payloadSpec?.name} attachment has been deleted`,
        detailedAction: [
          { item: this.delete_name, action: "REMOVED", property: "Attachment" },
        ],
        action: "REMOVED",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.payloadSpec?._id],
        "@type": "Attachment",
        category: ["productOffering"],
      };

      this.payloadSpec.attachment = await this.attachmentData;
      const deleteAttachment = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        { ...this.payloadSpec, id: this.payloadSpec._id }
      );
      this.initLog(attachmentLogPayload);
      console.log(
        "loading media in all deleteAttachmentRow",
        deleteAttachment?.data?.prodSpecCharValue
      );
      const image = await UPCAxiosInstance.delete(
        `gcpupload/files/${this.delete_name}`
      );
      if (deleteAttachment && image) {
        let productSpec = await UPCAxiosInstance.get(
          `/catalogManagement/productOffering/${this.$route.params.id}`
        );

        this.attachmentData = await productSpec.data.attachment;
        console.log(
          "loading media in all deleteAttachmentRow",
          productSpec?.data?.prodSpecCharValue
        );
      }
    },

    async receivedUploadData(val) {
      let data = await UPCAxiosInstance.post(`/gcpMultiple/v2/upload/`, val);
      console.log(data);

      let currentPayload = data?.data?.map((item) => {
        let obj = {};
        obj.name = item.filename;
        obj.size = item.data.attachment.size;
        obj.url = item.url;
        obj.id = item.data._id;
        // Get the current date and time in milliseconds
        const currentTimestamp = new Date().toJSON();

        // Format the current timestamp in the same format as '1705574420667'
        console.log(currentTimestamp);
        obj.lastUpdated = currentTimestamp;
        this.payloadSpec.attachment.unshift(obj);
        return obj;
      });
      let attachmentLogPayload = {
        message: `${this.payloadSpec?.name} attachment has been added`,
        detailedAction: [],
        action: "ADDED",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.payloadSpec?._id],
        "@type": "Attachment",
        category: ["productOffering"],
      };
      let attachmentLogData = this.buildLogBasedOnDifferencesForArrayOfObjects(
        [],
        currentPayload,
        "name",
        "attachment"
      );
      attachmentLogPayload.detailedAction = attachmentLogData;
      if (attachmentLogPayload.detailedAction.length > 0) {
        this.initLog(attachmentLogPayload);
      }

      console.log(this.payloadSpec);
      let attachment = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        { ...this.payloadSpec, id: this.payloadSpec._id }
      );
      let productSpec = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/${this.$route.params.id}`
      );
      if (attachment) {
        this.notification = {
          type: "success",
          message: `Nice work! Your new file is now in the list!`,
        };
      }
      console.log(attachment, productSpec.data.attachment, "productSpec");
      console.log(
        "loading media in all receivedUploadData",
        productSpec?.data?.prodSpecCharValue
      );

      this.attachmentData = productSpec.data.attachment;
    },
    featureName(val) {
      if (val) {
        this.featureAdd = true;
        this.editButton = false;
      } else {
        this.featureAdd = false;
      }
    },
    handleStay() {
      this.showOverviewDiscardModal = !this.showOverviewDiscardModal;
    },
    handleDiscard() {
      if (this.tempBack) {
        this.$router.back();
      } else {
        this.$router.push(this.$store.state.tempActiveSideBarItem.link);
      }
      // this.$router.push({ name: "offeringview" });
      this.showOverviewDiscardModal = !this.showOverviewDiscardModal;
      this.discardIt = true;
    },
    preventPageReload(event) {
      this.showOverviewDiscardModal = false;
      const unsavedChanges = this.isPayloadChanged(
        this.initialData,
        this.updatedData
      );
      if (!unsavedChanges) return;

      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
      return;
    },
    showDrawerRight() {
      this.show_right_filter = true;
    },
    closeRightFilter() {
      this.show_right_filter = false;
    },
    async specificationdataCalling(currentPage, rowsPerPage) {
      // ;
      if (this.filtertagvalue.length === 0 && this.searchvalue == "") {
        // var totalData = await UPCAxiosInstance.get(`catalogManagement/productSpecification`)
        // this.totalpageno = totalData.data.length
        // var paginatedTableData = await UPCAxiosInstance.get(
        //   `catalogManagement/productSpecification?pageNo=${currentPage}&size=${rowsPerPage}`
        // );
        // this.tableData = paginatedTableData.data;
        this.Specfilterquery.type = "filter";
        this.Specfilterquery["paginatedQuery"]["currentPage"] = currentPage;
        this.Specfilterquery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          this.Specfilterquery
        );

        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data.total;
        this.paginatedData;
      } else if (this.Specfilterquery.type == "filter") {
        if (this.filtertagvalue.length != 0) {
          this.Specfilterquery["paginatedQuery"]["currentPage"] = currentPage;
          this.Specfilterquery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
          const filterResultData = await UPCAxiosInstance.post(
            `util/filterData`,
            this.Specfilterquery
          );

          this.tableData = filterResultData.data.data;
          this.totalpageno = filterResultData.data.total;
          this.tableData?.map((e) => {
            // ;
            if (e.context == undefined) {
              e.context = false;
            }
          });
        } else {
          this.Specfilterquery.type = "search";
          this.Specfilterquery["paginatedQuery"]["currentPage"] = currentPage;
          this.Specfilterquery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
          const filterResultData = await UPCAxiosInstance.post(
            `util/filterData`,
            this.Specfilterquery
          );

          this.tableData = filterResultData.data.data;
          this.totalpageno = filterResultData.data.total;
          this.tableData?.map((e) => {
            // ;
            if (e.context == undefined) {
              e.context = false;
            }
          });
        }
      } else if (this.Specfilterquery.type == "search") {
        if (this.searchvalue != "") {
          this.Specfilterquery["paginatedQuery"]["currentPage"] = currentPage;
          this.Specfilterquery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
          const filterResultData = await UPCAxiosInstance.post(
            `util/filterData`,
            this.Specfilterquery
          );

          this.tableData = filterResultData.data.data;
          this.totalpageno = filterResultData.data.total;
          this.tableData?.map((e) => {
            // ;
            if (e.context == undefined) {
              e.context = false;
            }
          });
        } else {
          this.Specfilterquery.type = "filter";
          this.Specfilterquery["paginatedQuery"]["currentPage"] = currentPage;
          this.Specfilterquery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
          const filterResultData = await UPCAxiosInstance.post(
            `util/filterData`,
            this.Specfilterquery
          );

          this.tableData = filterResultData.data.data;
          this.totalpageno = filterResultData.data.total;
          this.tableData?.map((e) => {
            // ;
            if (e.context == undefined) {
              e.context = false;
            }
          });
        }
      }
    },
    async productSpecificationFilter(filterSelectedvalue, filterQuery) {
      // ;
      this.filtertagvalue = filterSelectedvalue;
      this.filtertaglength = filterSelectedvalue.length;
      if (this.filtertagvalue.length != 0) {
        // this.totalpageno = spectotalpageno
        // this.tableData = spectabledata
        this.Specfilterquery = filterQuery;
        this.Specfilterquery.type = "filter";
        if (this.searchvalue != "") {
          this.Specfilterquery["searchQuery"] = this.searchvalue;
        }
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          this.Specfilterquery
        );
        // console.log(filterResultData.data.data,'la');

        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data.total;
        // ;
      } else {
        this.SpecificationcurrPage = 1;
        this.SpecificationrowsPerPage = 10;
        this.specificationdataCalling(
          this.SpecificationcurrPage,
          this.SpecificationrowsPerPage
        );
      }
    },
    async noteDelete(index) {
      this.notesTable.splice(index, 1);
      this.searchData = JSON.parse(JSON.stringify(this.notesTable));
      this.payloadSpec.notes = this.notesTable;
      const updateSpecWithChar = await UPCAxiosInstance.patch(
        `/catalogManagement/productSpecification`,
        this.payloadSpec
      );
      console.log(
        "loading media in all noteDelete",
        updateSpecWithChar?.data?.prodSpecCharValue
      );

      if (updateSpecWithChar.data) {
        this.notification = {
          type: "success",
          message: `Done! Your item has been removed.`,
        };
      }
    },
    async editOpen(val, index) {
      this.noteIndex = index;
      this.editNoteVal = val;
      this.editMode = true;
      this.showAddNotePanel = true;
      this.interactionPayload = val;
      console.log(this.interactionPayload);
    },
    async editNote(val) {
      console.log(val, "edirnotes");
      let oldValue = JSON.parse(
        JSON.stringify(this.payloadSpec.notes[val.index])
      );
      this.payloadSpec.notes[val.index] = val.val;
      let notes = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        { ...this.payloadSpec, id: this.payloadSpec._id }
      );
      if (notes.data) {
        let notesLogPayload = {
          message: `${this.payloadSpec?.name} notes has been updated`,
          detailedAction: [],
          action: "UPDATED",
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          createdBy: this.$cookies.get("user"),
          ref: [this.payloadSpec?._id],
          "@type": "Notes",
          category: ["productOffering"],
        };

        // Checks notes changes
        if (val.val !== oldValue) {
          notesLogPayload.detailedAction.push({
            item: {
              old: oldValue,
              new: val.val,
            },
            action: "UPDATED",
            property: "Notes",
          });
        } else if (!val.val && oldValue) {
          notesLogPayload.detailedAction.push({
            item: oldValue,
            action: "REMOVED",
            property: "Notes",
          });
        } else if (val.val && !oldValue) {
          notesLogPayload.detailedAction.push({
            item: val.val,
            action: "ADDED",
            property: "Notes",
          });
        }
        this.initLog(notesLogPayload);
      }
      let productSpec = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/${this.$route.params.id}`
      );

      this.notesTable = productSpec.data.notes;

      console.log(notes, "payloadSpec");
    },
    async addNote(val) {
      console.log(val, "kfkjfvdn nfvkdnkfvdnk fbdkjnfdvkj      ");
      this.payloadSpec.notes.unshift(val);

      let notes = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        { ...this.payloadSpec, id: this.payloadSpec._id }
      );
      if (notes.data) {
        const notesLogPayload = {
          message: `${this.payloadSpec?.name} notes has been added`,
          detailedAction: [{ item: val, action: "ADDED", property: "Notes" }],
          action: "ADDED",
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          createdBy: this.$cookies.get("user"),
          ref: [this.payloadSpec?._id],
          "@type": "Notes",
          category: ["productOffering"],
        };
        this.initLog(notesLogPayload);
      }
      let productSpec = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/${this.$route.params.id}`
      );

      this.notesTable = productSpec.data.notes;
      console.log(notes, productSpec.data.notes, "productSpec.data.notes");
    },

    async attachmentSearch(key) {
      const searchData = await UPCAxiosInstance.get(
        `document/search?search=${key}`
      );
      this.attachmentData = searchData.data;
    },
    async deleteExecRow() {
      const image = await UPCAxiosInstance.delete(
        `gcpupload/files/${this.delete_name}`
      );
      if (image) {
        this.notification = {
          type: "success",
          message: `Done! Your item has been removed.`,
        };
        const table = await UPCAxiosInstance.get(`document`);
        this.attachmentData = table.data;
        this.delete_modal_active = false;
      }
    },
    attachmentTableAction(data) {
      let options = [
        {
          label: "Download",
          icon: "icon-download-regular",
        },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ];
      return options.filter((option) => {
        if (
          data &&
          data.status &&
          data.status?.toLowerCase() == "draft" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status?.toLowerCase() == "active" &&
          ["Draft", "Mark as active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status?.toLowerCase() == "inactive" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else {
          return true;
        }
      });
    },
    attachmentContextMenu($event, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 117;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.attachmentData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.attachmentData[index].context = true;
      } else {
        this.attachmentData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    async attachmentCrudContextMenu($event, ele, i) {
      if ($event?.toLowerCase() === "download") {
        const url = await UPCAxiosInstance.get(`/gcpupload/files/${ele.name}`);

        this.downloadFromGCP(url, ele.name);
      } else if ($event?.toLowerCase() === "delete") {
        this.delete_modal_active = true;
        this.deleteobj = ele;
        this.delete_name = ele.name;
        this.deleterowindex = i;
      }
    },
    formatFileSize(bytes) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    },

    async downloadFromGCP(urlFetch, name) {
      const response = await fetch(urlFetch);
      const fileData = await response.blob(); // Assuming the file is in binary format

      const blob = new Blob([fileData]);

      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = name;

      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
    getFileExtension(filename) {
      const parts = filename.split(".");
      const fileExtension = parts[parts.length - 1];
      return fileExtension;
    },

    async imgDataPrimary(pic) {
      this.imgPrimary = pic;
    },
    async refreshUpload() {
      const formData = new FormData();
      formData.append("file", this.imgPrimary.properties);
      const attachmentdata = await UPCAxiosInstance.post(
        `/gcpMultiple/upload/${this.userInfo._id}`,
        formData
      );

      this.attachmentData = await this.payloadSpec.attachment.concat(
        attachmentdata
      );
      if (attachmentdata) {
        this.modal_active = false;
        this.notification = {
          type: "success",
          message: `Nice work! Your new file is now in the list!`,
        };
      }
    },
    uploadedFile() {
      this.modal_active = true;
    },
    compareObjects(obj1, obj2) {
      // Find extra attributes in obj1
      const extraAttributes = Object.keys(obj1).reduce((acc, key) => {
        if (!obj2[key]) {
          acc[key] = obj1[key];
        }
        return acc;
      }, {});

      // Merge obj1 attributes into obj2
      const mergedObject = Object.assign({}, obj2, obj1);

      return {
        mergedObject: mergedObject,
        extraAttributes: extraAttributes,
      };
    },
    async optionChosen(val) {
      console.log(val, "eventeed");
      if (val["@type"] == "Customer Facing service") {
        let insertValue = {
          service: {
            icones: "icon-box-check-regular tree-icone3",
            name: "service",
            value: "service",
            data: {},
          },
          specification: {
            name: val?.name,
            description: val?.description,
            lifecycleStatus: val?.lifecycleStatus,
          },
          child: [],
        };
        this.treedata.child.push(insertValue);
      } else {
        let insertValue = {
          service: {
            icones: "icon-shield-bolt-regular tree-icon",
            name: "resource",
            value: "resource",
            data: {},
          },
          specification: {
            name: val?.name,
            description: val?.description,
            lifecycleStatus: val?.lifecycleStatus,
          },
          child: [],
        };
        this.treedata.child.push(insertValue);
      }
    },
    async addTreeChild() {
      await UPCAxiosInstance.post(`/tree`, {
        tree: this.treedata,
        specId: this.payloadSpec?.id,
      });
    },

    closeTree() {
      this.isTreePanel = false;
    },
    fromAddContextMenuHierarchy(val) {
      if (val == "Add") {
        this.isTreePanel = true;
      }
    },
    productKey(val) {
      this.relatedProductsSearch = val;
      if (val) {
        this.search = true;
        const result = this.searchInArray(
          val,
          this.payloadSpec?.relatedProduct
        );
        this.relatedProducts = result;
      } else {
        this.search = false;
        this.relatedProducts = this.payloadSpec?.relatedProduct;
      }
      console.log(this.relatedProducts, "this.relatedProducts");
    },
    pricingSearchTrigger(val) {
      this.searchPricing = val;
      if (val) {
        this.search = true;
        const result = this.searchInArray(val, this.pricingData);
        console.log("🚀 ~ pricingSearchTrigger ~ result:", result);
        this.pricingData = result;
      } else {
        this.search = false;
        console.log("🚀 ~ pricingSearchTrigger ~ this.search:", this.search);
        this.pricingData = this.payloadSpec?.prodSpecCharValue;
      }
    },
    // searchKeySupplier(val){
    //   this.supplierData=this.searchSupplierData
    //   console.log("supplierData",this.supplierData)
    //   console.log("searchSupplierData",this.searchSupplierData)
    //   if (val) {
    //     // this.search = true;
    //     const result = this.searchArray(val, this.searchSupplierData);
    //     this.supplierData = result;
    //   } else {
    //     // this.search = false;
    //     this.supplierData;
    //   }
    // },
    searchKeySupplier(val) {
      this.searchValForSupplier = val;

      if (val !== "") {
        let searchData = this.searchNestedObjects(
          this.supplierOriginalDataRemoveProductOwner,
          val.toLowerCase()
        );
        // console.log(searchData, this.tableData);
        this.supplierData = JSON.parse(JSON.stringify(searchData));
      } else {
        this.supplierData = JSON.parse(
          JSON.stringify(this.supplierOriginalDataRemoveProductOwner)
        );
      }
      console.log(val, this.supplierData, "gde");
    },
    searchNestedObjects(objects, key) {
      key = key.toString();
      // console.log("v from func",key)
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (k === "src") {
            // console.log("src false from")
            return false;
          }
          if (typeof v === "object" && v !== null) {
            if (Array.isArray(v)) {
              return v.some((item) => {
                // console.log("v form rec",v,typeof key , k)
                return this.searchNestedObjects([item], key).length > 0;
              });
            }
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string") {
            // console.log("v form string",v,typeof key)
            return v.toLowerCase().includes(key.toLowerCase());
          } else if (typeof v === "number") {
            // console.log("v form number",v,typeof key)
            return v.toString().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },
    //     searchArray(searchTerm, dataArray) {
    //       console.log("Spplier filter searchTerm is empty before", searchTerm, dataArray);
    //       if (searchTerm) {
    //         const searchTermLower = searchTerm?.toLowerCase();

    //         const result = dataArray.filter((obj) => {
    //           return Object.values(obj).some((value) => {
    //             if (typeof value === "string") {
    //               return value?.toLowerCase().includes(searchTermLower);
    //             }
    //             return false;
    //           });
    //         });
    //         // this.supplierData=result
    // console.log("Result",result)
    //         return result;

    //       } else {
    //         console.log("searchTerm is empty", dataArray);
    //         return dataArray;
    //       }
    //     },
    searchKey(val) {
      if (val) {
        this.search = true;
        const result = this.searchInArray(val, this.searchData);
        this.tableData = result;
      } else {
        this.search = false;
        this.tableData = this.payloadSpec?.productSpecCharacteristic;
      }
    },
    searchInArray(searchTerm, dataArray) {
      const searchTermLower = searchTerm?.toLowerCase();

      const result = dataArray.filter((obj) => {
        return Object.values(obj).some((value) => {
          if (typeof value === "string") {
            return value?.toLowerCase().includes(searchTermLower);
          }
          return false;
        });
      });

      return result;
    },

    searchTrigger(val) {
      if (val) {
        this.searchToggle = true;
        const result = this.searchNoteArray(val, this.searchNotes);
        this.notesTable = result;
      } else {
        this.searchToggle = false;
        this.notesTable = this.payloadSpec?.notes;
      }
    },
    searchNoteArray(searchTerm, dataArray) {
      const searchTermLower = searchTerm?.toLowerCase();

      const result = dataArray.filter((obj) => {
        return Object.values(obj).some((value) => {
          if (typeof value === "string") {
            return value?.toLowerCase().includes(searchTermLower);
          }
          return false;
        });
      });

      return result;
    },
    validateName(val) {
      if (val) {
        if (this.editButton == true) {
          this.disableEdit = true;
        } else {
          this.featureAdd = true;
        }
      } else {
        if (this.editButton == true) {
          this.disableEdit = false;
        } else {
          this.feautureAdd = false;
        }
      }
    },
    formatedValue(val) {
      if (val) {
        return val.map((obj) => obj.value).join(",");
      }
    },
    SupplierTableAction() {
      // console.log("SupplierTableAction", data);
      // if (data?.isDefault) {
      //   let options = [
      //     {
      //       label: "Set as Undefault",
      //       icon: "icon-file-text-edit-regular",
      //     },
      //     {
      //       label: "Delete",
      //       icon: "icon-trash-regular",
      //     },
      //   ];
      //   return options.filter((option) => {
      //     if (
      //       data &&
      //       data.status &&
      //       data.status.toLowerCase() == "draft" &&
      //       ["Draft", "Mark as In-active"].includes(option.label)
      //     ) {
      //       return false;
      //     } else if (
      //       data &&
      //       data.status &&
      //       data.status.toLowerCase() == "active" &&
      //       ["Draft", "Mark as active"].includes(option.label)
      //     ) {
      //       return false;
      //     } else if (
      //       data &&
      //       data.status &&
      //       data.status.toLowerCase() == "inactive" &&
      //       ["Draft", "Mark as In-active"].includes(option.label)
      //     ) {
      //       return false;
      //     } else {
      //       return true;
      //     }
      //   });
      // } else {
      //   let options = [
      //     {
      //       label: "Set as default",
      //       icon: "icon-file-text-edit-regular",
      //     },
      //     {
      //       label: "Delete",
      //       icon: "icon-trash-regular",
      //     },
      //   ];
      //   return options.filter((option) => {
      //     if (
      //       data &&
      //       data.status &&
      //       data.status.toLowerCase() == "draft" &&
      //       ["Draft", "Mark as In-active"].includes(option.label)
      //     ) {
      //       return false;
      //     } else if (
      //       data &&
      //       data.status &&
      //       data.status.toLowerCase() == "active" &&
      //       ["Draft", "Mark as active"].includes(option.label)
      //     ) {
      //       return false;
      //     } else if (
      //       data &&
      //       data.status &&
      //       data.status.toLowerCase() == "inactive" &&
      //       ["Draft", "Mark as In-active"].includes(option.label)
      //     ) {
      //       return false;
      //     } else {
      //       return true;
      //     }
      //   });
      // }

      return [
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ];
    },
    computedTableAction(data) {
      let options = [
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ];
      return options.filter((option) => {
        if (
          data &&
          data.status &&
          data.status?.toLowerCase() == "draft" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status?.toLowerCase() == "active" &&
          ["Draft", "Mark as active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status?.toLowerCase() == "inactive" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else {
          return true;
        }
      });
    },

    computedAction(data) {
      let options = [
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ];
      return options.filter((option) => {
        if (
          data &&
          data.status &&
          data.status?.toLowerCase() == "draft" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status?.toLowerCase() == "active" &&
          ["Draft", "Mark as active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status?.toLowerCase() == "inactive" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else {
          return true;
        }
      });
    },

    // async addSupplier() {
    //   this.componentkey += 1;
    //   let configPriceType=[];
    //   this.featureObj.forEach((ele)=>{
    //     configPriceType.push(ele.priceType)
    //     console.log("configPriceType",configPriceType)
    //     return configPriceType
    //   })
    //   if (
    //     this.productBaseType &&
    //     this.productBaseType.toLowerCase() === "tangible"
    //   ) {
    //     console.log("Add supplier handleSupplier tangible");
    //     this.featureObj = this.featureObj.filter((value) => {
    //       console.log("Add supplier handleSupplier tangible:", value);
    //       return (
    //         value.priceType?.replace(/\s/g, "").toLowerCase() === configPriceType[0].toLowerCase()
    //       );
    //     });
    //   }

    //   if (
    //     this.productBaseType &&
    //     this.productBaseType.toLowerCase() === "intangible"
    //   ) {
    //     console.log("Add supplier handleSupplier intangible");
    //     this.featureObj = this.featureObj.filter((value) => {
    //       console.log("Add supplier handleSupplier intangible:", value);
    //       return value.priceType?.toLowerCase() === configPriceType[0].toLowerCase()
    //     });
    //   }

    //   this.supplierData = await this.supplierData.concat(this.featureObj);
    //   this.isFeaturePanelEnabled = !this.isFeaturePanelEnabled;
    //   this.payloadSpec.relatedParty =
    //     await this.payloadSpec.relatedParty.concat(this.featureObj);

    //   const updateSpecWithChar = await UPCAxiosInstance.patch(
    //     `/catalogManagement/productOffering`,
    //     { ...this.payloadSpec, id: this.payloadSpec._id }
    //   );
    //   var supplierdatapro = [];
    //   let supplierProductData = this.featureObj.filter(
    //     (value, index, self) =>
    //       index ===
    //       self.findIndex((t) => t.sku === value.sku && t.name === value.name)
    //   );
    //   let baseType = { "@baseType": this.productBaseType };
    //   let unitOfMeasure = { unitOfMeasure: this.payloadSpec.unitOfMeasure };
    //   supplierProductData.forEach(async (value) => {
    //     await this.payloadSpec?.prodSpecCharValue.forEach(async (val) => {
    //       if (val.SKU === value.sku) {
    //         console.log("val.sku", val);
    //         supplierdatapro.push(val);
    //         const addSupplierStock = await UPCAxiosInstance.post(
    //           `/productStock?company=${value.name}`,
    //           {
    //             name: this.payloadSpec.name,
    //             stockedProduct: { ...val, ...unitOfMeasure, ...baseType },
    //           }
    //         );
    //         console.log("addSupplierStock", addSupplierStock);
    //       }
    //     });
    //   });
    //   if (updateSpecWithChar.data) {
    //     this.editButton = false;
    //     this.notification = {
    //       type: "success",
    //       message: `All set! Your changes have been saved successfully!`,
    //     };
    //   }
    //   if (
    //     this.productBaseType &&
    //     this.productBaseType.toLowerCase() === "tangible"
    //   ) {
    //     console.log("Add supplier handleSupplier tangible");
    //     this.partyData = this.partyData.filter((value) => {
    //       console.log("Add supplier handleSupplier tangible:", value);
    //       return (
    //         value.priceType?.replace(/\s/g, "").toLowerCase() === "onetime"
    //       );
    //     });
    //   }

    //   if (
    //     this.productBaseType &&
    //     this.productBaseType.toLowerCase() === "intangible"
    //   ) {
    //     this.supplierData = this.supplierData.filter((value) => {
    //       return value.priceType?.toLowerCase() === "recurring";
    //     });
    //   }
    //   this.featureObj = [];

    // },
    // async supplierStocks(val) {
    //   let supplierdatapro = [];
    //   let supplierProductData = val.filter(
    //     (value, index, self) =>
    //       index ===
    //       self.findIndex((t) => t.sku === value.sku && t.name === value.name)
    //   );
    //   let baseType = { "@baseType": this.productBaseType };
    //   let unitOfMeasure = { unitOfMeasure: this.payloadSpec.unitOfMeasure };
    //   supplierProductData.forEach(async (value) => {
    //     await this.payloadSpec?.prodSpecCharValue.forEach(async (val) => {
    //       if (val.id == value.id) {
    //         console.log("val.sku", val);
    //         supplierdatapro.push(val);
    //         const addSupplierStock = await UPCAxiosInstance.post(
    //           `/productStock?company=${value.name}`,
    //           {
    //             name: this.payloadSpec.name,
    //             stockedProduct: { ...val, ...unitOfMeasure, ...baseType },
    //           }
    //         );
    //         console.log("supplierData 12", addSupplierStock);
    //       }
    //     });
    //   });
    // },

    async supplierStock(val) {
      console.log("supplierList intangible incomo", val);
      // if (val.length > 0) {
      await val?.map(async (value) => {
        value.unitOfMeasure = this.payloadSpec.unitOfMeasure;
        value["@baseType"] = this.productBaseType;
        const productStock = await UPCAxiosInstance.post(
          `/productStock?company=${value?.name}`,
          {
            name: this.payloadSpec.name,
            stockedProduct: value,
            externalID: this.payloadSpec._id,
          }
        );
        console.log("supplierList intangible outcoming", productStock.data);
      });
      // } else {
      //   val.unitOfMeasure = this.payloadSpec.unitOfMeasure;
      //   val["@baseType"] = this.productBaseType;
      //   const productStock = await UPCAxiosInstance.post(
      //     `/productStock?company=${val.name}`,
      //     {
      //       name: this.payloadSpec.name,
      //       stockedProduct: val,
      //     }
      //   );
      //   console.log("supplierStock else", productStock.data);
      // }
    },
    async addSupplier() {
      console.log(this.featureObj, "supplierList intangible add supplier");
      this.featureObj = await this.featureObj.flat(Infinity);

      if (this.categoryInfo?.type == "Tangible") {
        let supplierList = [];
        supplierList = await this.featureObj.filter((value) => {
          return (
            value?.priceType?.toLowerCase() ==
            this.categoryInfo.pricingType[0].toLowerCase()
          );
        });
        console.log("supplierList yes incoming change", supplierList);
        await this.supplierStock(supplierList);

        this.featureObj = supplierList;

        // this.supplierStock(this.featureObj);
      }
      if (this.categoryInfo?.type == "Intangible") {
        let getFirstPricing = await this.featureObj.filter((value) => {
          return (
            value?.priceType?.toLowerCase() ==
            this.categoryInfo?.pricingType[0].toLowerCase()
          );
        });
        let supplierList = [];

        supplierList = await this.getIntangibleSupplierData(getFirstPricing);

        console.log("supplierList intangible", supplierList);
        await this.supplierStock(supplierList);
        this.featureObj = supplierList;
      }
      let spec = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/${this.$route.params.id}`
      );
      let supplierRelated = this.featureObj.concat(
        await spec.data.relatedParty
      );
      // await this.supplierStock(supplierList);
      console.log("supplierList intangible supplier patch123", supplierRelated);
      const updateSpecWithChar = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        { id: this.$route.params.id, relatedParty: supplierRelated }
      );
      console.log(
        "🚀 ~ detailedAction:this.featureObj.map ~ this.featureObj:",
        this.featureObj
      );
      const supplierId = this.featureObj[0]?.externalID;
      const addedProductSupplierLogPayload = {
        message: `${this.payloadSpec?.name} supplier has been added`,
        detailedAction: this.featureObj.map((value) => {
          return {
            item: value,
            action: "ADDED",
            property: "Supplier",
          };
        }),
        action: "ADDED",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.payloadSpec?._id, supplierId],
        "@type": "Offering-supplier",
        category: ["productOffering", "supplier"],
      };
      // const
      if (Object.keys(this.featureObj).length === 0) {
        console.log("The object is empty.");
      } else {
        console.log("The object is not empty.");
        this.initLog(addedProductSupplierLogPayload);
      }

      console.log(
        "supplierList intangible supplier patch",
        updateSpecWithChar?.data
      );
      // console.log(updateSpecWithChar.data, "supplierData");
      if (updateSpecWithChar.data && this.isFeaturePanelEnabled) {
        this.isFeaturePanelEnabled = !this.isFeaturePanelEnabled;
        this.refreshSupplier();
        this.notification = {
          type: "success",
          message: `Your item is added successfully!.`,
        };
      }
    },
    async refreshSuppliersList() {
      const filterData = {
        company: this.company,
        type: "filter",
        module: "suppliers",
        isPaginated: true,
        lastUpdated: new Date().toJSON(),
        paginatedQuery: {
          rowsPerPage: this.rowsPerPage,
          currentPage: this.currPage,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 2,
        filterQuery: {},
        searchQuery: "",
        searchList: [],
      };
      const access = await MLTAxiosInstance.post(
        `util/filterData?company=${this.company}`,
        filterData
      );
      this.refreshSupplier();
      console.log("Add supplier mounted", this.supplier);
      this.supplierMain = await access.data.data;
      console.log("Add supplier mounted 1", this.supplierMain);
      this.supplier = this.supplierMain.filter(
        (ele) => !this.tableData.some((val) => val._id == ele._id)
      );
    },
    handleFeaturePanelOpenClose() {
      this.componentkey += 1;
      this.isFeaturePanelEnabled = !this.isFeaturePanelEnabled;
      if (
        this.pricingPanelData.SKU != "" &&
        this.pricingPanelData.validFor.startDate != "" &&
        this.pricingPanelData.validFor.endDate != ""
      ) {
        this.disableSave = true;
      } else {
        this.disableSave = false;
      }
    },
    async handleSupplierFeaturePanelOpenClose() {
      this.selectedSuppliers = [];
      await this.refreshSuppliersList();
      this.componentkey += 1;
      this.isFeaturePanelEnabled = !this.isFeaturePanelEnabled;
    },
    closeSupplierModal() {
      this.isFeaturePanelEnabled = false;
    },

    async checkName(val) {
      if (val) {
        this.updatedData.name = val;
        this.enableButton = true;
      } else {
        this.enableButton = false;
      }
    },
    refresh() {
      this.payloadSpec.productSpecification["@baseType"] =
        this.categoryInfo?.type;
      this.payloadSpec.id = this.payloadSpec._id;
      UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        this.payloadSpec
      );
    },
    async saveSpec() {
      // Find differences between initial and updated data
      this.updatedData = {
        ...this.updatedData,
        name: this.payloadSpec?.name,
        description: this.texteditorVal,
        lifecycleStatus: this.payloadSpec?.lifecycleStatus,
        tags: this.payloadSpec?.tags,
        marketSegment: this.payloadSpec?.marketSegment,
        channel: this.payloadSpec?.channel,
      };

      // Find array object differences

      // Log payload
      let UPC_OverviewLog = {
        message: `${this.payloadSpec?.name} Overview has been updated`,
        detailedAction: [],
        "@type": "Offering-overview",
        category: ["productOffering"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: [this.payloadSpec?.id],
        action: "UPDATE",
        status: "active",
      };
      console.log("🚀 ~ saveSpec ~ UPC_OverviewLog:", UPC_OverviewLog);
      // Market differences
      let marketSegmentActionLog =
        this.buildLogBasedOnDifferencesForArrayOfObjects(
          this.initialData.marketSegment,
          this.updatedData.marketSegment,
          "name",
          "marketSegment"
        );
      console.log(
        "🚀 ~ saveSpec ~ marketSegmentActionLog:",
        marketSegmentActionLog
      );

      // Channel differences
      let channelActionLog = this.buildLogBasedOnDifferencesForArrayOfObjects(
        this.initialData.channel,
        this.updatedData.channel,
        "name",
        "channel"
      );
      console.log("🚀 ~ saveSpec ~ channelActionLog:", channelActionLog);

      // Product contact log
      let productContactActionLog = this.buildBasicLogByDifferences(
        this.initialData,
        this.updatedData,
        "productContact",
        "productContact"
      );

      // Status log
      let statusActionLog = this.buildBasicLogByDifferences(
        this.initialData,
        this.updatedData,
        "lifecycleStatus",
        "lifecycleStatus"
      );

      console.log(this.initialData.name, this.updatedData.name, "actionlog");
      // Name log
      let nameActionLog = this.buildBasicLogByDifferences(
        this.initialData,
        this.updatedData,
        "name",
        "name"
      );
      // Description log
      let descriptionActionLog = this.buildBasicLogByDifferences(
        this.initialData,
        this.updatedData,
        "description",
        "description"
      );
      //tags
      let tagsActionLog = this.findArrayOfStringDifferences(
        this.initialData.tags,
        this.updatedData.tags,
        "tags"
      );
      console.log(tagsActionLog, "tagsActionLog");
      console.log(descriptionActionLog, "descriptionActionLog");

      UPC_OverviewLog.detailedAction = UPC_OverviewLog.detailedAction.concat(
        marketSegmentActionLog,
        channelActionLog,
        productContactActionLog,
        statusActionLog,
        nameActionLog,
        descriptionActionLog,
        tagsActionLog
      );
      console.log(
        "🚀 ~ saveSpec ~ differences:actionLog",
        UPC_OverviewLog.detailedAction
      );
      // let a = 1;
      // if (a) return;
      this.initialData = JSON.parse(JSON.stringify(this.updatedData));

      // this.initialData = {
      //   name: this.payloadSpec.data?.name,
      //   description: this.payloadSpec.data?.description,
      //   lifecycleStatus: this.payloadSpec.data?.lifecycleStatus,
      //   tags: this.payloadSpec.data?.tags,
      //   marketSegment: this.payloadSpec.data?.marketSegment,
      //   channel: this.payloadSpec.data?.channel,
      // };
      // this.uploadPerumutation(this.payloadSpec.prodSpecCharValueUse);
      this.payloadSpec.description = this.texteditorVal;
      // console.log(this.payloadSpec.description,this.texteditorVal,"error in uploading")
      // console.log(this.payloadSpec,'description',this.texteditorVal)
      this.payloadSpec.productSpecification["@baseType"] =
        this.categoryInfo?.type;
      this.payloadSpec.id = this.payloadSpec._id;
      try {
        const addSpecification = await UPCAxiosInstance.patch(
          `/catalogManagement/productOffering`,
          this.payloadSpec
        );
        console.log("loading media", addSpecification);
        if (addSpecification.data.name) {
          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };
          if (UPC_OverviewLog.detailedAction.length > 0) {
            this.initLog(UPC_OverviewLog);
          }
        } else {
          console.log("thrown error");
          this.notification = {
            type: "success",
            message: `${this.payloadSpec.name} offer name already exists`,
          };
          this.refreshOffer();
        }
      } catch (e) {
        this.notification = {
          type: "success",
          message: `${this.payloadSpec.name} offer name already exists`,
        };
        console.log(e, "loading media error");
        this.refreshOffer();
      }
    },
    zoomin() {
      this.zoom += 10;
    },
    zoomout() {
      this.zoom -= 10;
    },
    currentPage(val) {
      // if(this.company != '')
      // {
      this.currPage = val;
      if (this.filtertaglength != 0) {
        this.SpecificationcurrPage = val;
        this.specificationdataCalling(
          this.SpecificationcurrPage,
          this.SpecificationrowsPerPage
        );
      }
      // this.schemaDataCalling(this.currPage,this.rowsPerPage)
      // console.log(this.tableData);
      // }
    },
    changeRowsPerPage(count) {
      //
      // if(this.company != '')
      // {
      this.rowsPerPage = count;
      if (this.filtertaglength != 0) {
        this.SpecificationrowsPerPage = count;
        this.specificationdataCalling(
          this.SpecificationcurrPage,
          this.SpecificationrowsPerPage
        );
      }
      // this.schemaDataCalling(this.currPage,this.rowsPerPage)
      // }
    },
    async closeSupplierCrudContextMenu($event, i, index) {
      console.log($event, i, index, "supplierData triggering");
      if ($event?.toLowerCase() == "delete") {
        this.delete_supplier_data = i;
        this.delete_supplier_modal = true;
        // this.deleteSupplier.push(i);
        // this.addedSupplier = this.addedSupplier.filter(
        //   (ele) => !this.addedSupplier.some((val) => val.id == ele.id)
        // );

        // console.log(this.addedSupplier, "addedSupplier")
        console.log(
          " this.supplierOriginalData closecrud ",
          this.supplierOriginalData
        );
        this.refreshSupplier();
      } else if ($event?.toLowerCase() == "set as default") {
        // this.isSupplierDefault=true
        this.supplierData = this.supplierData.map((supplier) => {
          console.log(supplier.displayId, "===", i.displayId);
          return {
            ...supplier,
            isDefault: supplier.displayId === i.displayId ? true : false,
            context: false,
          };
        });
        this.supplierData = JSON.parse(JSON.stringify(this.supplierData));
        // this.supplierData[index].isDefault =
        //   !this.supplierData[index].isDefault;
        // this.supplierData = this.supplierProductOwner.concat(this.supplierData);
        this.payloadSpec.relatedParty = this.supplierData;
        const updateSpecWithChar = await UPCAxiosInstance.patch(
          `/catalogManagement/productOffering`,
          {
            relatedParty: this.supplierData,
            id: this.payloadSpec._id,
          }
        );
        console.log(updateSpecWithChar.data, "productRecommed 3.0 delete");
      } else if ($event?.toLowerCase() == "set as undefault") {
        this.supplierData = this.supplierData.map((supplier) => {
          console.log(supplier.displayId, "===", i.displayId);
          return {
            ...supplier,
            isDefault: supplier.displayId === i.displayId ? false : false,
            context: false,
          };
        });
        this.supplierData = JSON.parse(JSON.stringify(this.supplierData));
        // this.supplierData[index].isDefault =
        //   !this.supplierData[index].isDefault;
        // this.supplierData = this.supplierProductOwner.concat(this.supplierData);
        this.payloadSpec.relatedParty = this.supplierData;
        const updateSpecWithChar = await UPCAxiosInstance.patch(
          `/catalogManagement/productOffering`,
          {
            relatedParty: this.supplierData,
            id: this.payloadSpec._id,
          }
        );
        console.log(updateSpecWithChar.data, "productRecommed 3.0 delete");
      } else {
        i.context = false;
      }
    },
    async closeCrudContextMenu($event, i, index) {
      console.log($event, i, index, "test for context menu");
      if ($event?.toLowerCase() == "delete") {
        this.deleteRelatedProductObj = i;
        this.deleteRelatedProductIndex = index;
        this.deleteRelatedProductName = i?.name;
        this.deletedRelatedProduct = this.relatedProducts?.find(
          (ele, ind) => ind == index
        );
        this.delete_modal_related_product = true;
        i.context = false;
      } else {
        i.context = false;
      }
    },
    async productContextMenu($event, i, index) {
      if ($event?.toLowerCase() == "delete") {
        this.relatedProducts.splice(index, 1);
        this.payloadSpec.relatedProduct = this.relatedProducts;
        this.payloadSpec.id = this.payloadSpec._id;
        delete this.payloadSpec._id;
        const updateSpecWithChar = await UPCAxiosInstance.patch(
          `/catalogManagement/productOffering`,
          this.payloadSpec
        );
        console.log(
          "loading media in all productContextMenu",
          updateSpecWithChar?.data?.prodSpecCharValue
        );
        if (updateSpecWithChar.data) {
          this.notification = {
            type: "success",
            message: `Done! Your item has been removed.`,
          };
        }
        i.context = false;
      } else {
        i.context = false;
      }
    },
    convertdateformat(date1) {
      if (date1 != null) {
        let objectDate = new Date(date1);
        if (isNaN(objectDate) == false) {
          let date = objectDate.toLocaleString("default", { day: "numeric" });
          let month = objectDate.toLocaleString("default", { month: "short" });
          let year = objectDate.getFullYear();
          let dateformat = month + " " + date + ", " + year;
          return dateformat;
        }
      }
    },
    tooltipover(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis(data1, index1);
    },
    tooltipleave(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses(data1, index1);
    },
    ellipsis(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              let height = tooltip.offsetHeight;
              let top = element1.getBoundingClientRect().top - height;
              let left = element1.getBoundingClientRect().left;
              tooltip.style.display = "block";
              tooltip.style.top = top + "px";
              tooltip.style.left = left + "px";
              return true;
            }
          }
          return false;
        }
      });
    },
    crudContextMenu($event, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        if (this.mode == "view") return;
        // console.log(
        //   "🚀 ~ file: SpecificationCharacteristicTableComponent.vue:1707 ~ crudContextMenu ~ $event, index:",
        //   $event,
        //   index,
        //   this.currentCharIndex
        // );
        this.isContextOpend = true;
        this.tableData.forEach((e) => {
          if (e.context == undefined) {
            e.context = false;
          } else {
            e.context = false;
          }
        });
        if (this.tableData[index].context == true) {
          this.tableData[index].context = false;
        } else {
          this.tableData[index].context = true;
        }
      }
    },
    supplierContextMenu($event, index) {
      console.log($event, "supplierData event");
      console.log(this.supplierData, "supplierData Tabledata");
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 100;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;

        this.isContextOpend = true;

        this.supplierData?.forEach((e) => {
          if (e.context !== undefined) {
            console.log("context", e.context);
            e.context = false;
          }
        });

        this.supplierData[index].context = true;

        //
      } else {
        this.supplierData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    contextMenu($event, index) {
      console.log($event, "event");
      console.log(this.tableData, "Tabledata");
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;

        this.isContextOpend = true;
        this.relatedProductTableValues?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.relatedProductTableValues[index].context = true;

        //
      } else {
        this.relatedProductTableValues?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    ellipses(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              tooltip.style.display = "none";
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return new Intl.DateTimeFormat("en-US", options)
        .format(date)
        .replace(",", "");
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    serialNumberRelatedProducts(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    arrange(val) {
      // console.log('true / false', val);
      this.re_arrange = val;
      this.updateTheads();
    },
    applyColumns(val) {
      // console.log('apply', val);
      this.duplicateTheads = [];
      val.forEach((e) => {
        if (e.checked === true) {
          this.duplicateTheads.push(e);
        }
      });
      this.filteredSupplierhead = [...this.duplicateTheads];
    },
    goTo() {
      this.tempBack = true;
      this.$router.push("/UPC/product-catalog-table/offering");
    },
    async valueleft(val , object) {
      this.leftpannel = val;
      this.permisstionSidebarData = object
      console.log(val,object,"objectobjectobject");

      // this.leftpannel = 'Features';
      if (val == "Activity log") {
        let activitylogdata = await MLTAxiosInstance.post(
          `util/filterData`,
          this.offerActivity
        );
        console.log(activitylogdata, "activity log response data in offer2");

        this.logData = activitylogdata.data.data;
        this.activitytotal = activitylogdata.data.total;
        console.log(this.activitytotal, "total in overview2");
      } else if (val == "Discounts") {
        let discountsTable = await UPCAxiosInstance.get(
          `/catalogManagement/productOffering/disconts/${this.$route.params.id}`
        );
        this.discountData = await discountsTable.data;
      } else if (val == "Suppliers") {
        this.applyDiscountForSupplier();
        this.refreshSupplier();
      }
      // else if (val == "Pricing") {
      //   if (this.payloadSpec.offerType == "Bundle") {
      //     this.bundleTotal();
      //   }
      // }
      else if (val == "Media") {
        if (this.showLeftVal === true) {
          setTimeout(async () => {
            console.log("This message is displayed after 2 seconds");

            let productSpec = await UPCAxiosInstance.get(
              `/catalogManagement/productOffering/${this.$route.params.id}`
            );
            console.log(
              "productspecinleft",
              productSpec.data,
              productSpec.data.prodSpecCharValue.length
            );

            if (
              productSpec?.data?.productSpecification[
                "@baseType"
              ]?.toLowerCase() === "intangible"
            ) {
              this.productBaseType = "intangible";
              console.log("in left value....", this.productBaseType);
            }

            if (this.newoff > this.oldoff) {
              this.mediaData = await productSpec?.data?.prodSpecCharValue;
            }
          }, 1200);
        }

        // let matrixPayload = await this.getConfigurableOptions(
        //   this.payloadSpec.prodSpecCharValueUse
        // );

        // let keys = Object.keys(matrixPayload);
        // let priceTable = keys.map((obj) => {
        //   return {
        //     sortable: false,
        //     resizable: false,
        //     width: 200,
        //     label: obj,
        //     prop: obj,
        //     align: "left",
        //   };
        // });
        // this.mediaThead = this.mediaThead.concat(priceTable);
        // console.log("mediaThead in valueleft", this.mediaThead);
      }
    },
  },
};
</script>

<style lang="scss">
.--layout-inner-right-portion.--offer::-webkit-scrollbar {
  // /* Hide scrollbar on Chrome, Safari, and Opera */
  display: none;
}

.feature-table::-webkit-scrollbar {
  display: none;
}

.feature-value-tools {
  text-align: end;
  margin-left: auto;
  display: flex;
  align-items: center;

  & i.delete-feature:last-child {
    font-size: 15px;
    margin-top: -6%;
  }

  .feature-value-default-icon-bg {
    display: inline-flex;
    width: 1.4rem;
    height: 1.4rem;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    &.--active {
      background: var(--hlx-color-primary-lighter);

      i {
        color: #54bd95;
      }
    }
  }
}

.upload-supplier {
  .main-content-body-upload .upload-panel {
    height: 140px !important;
  }
}
</style>
