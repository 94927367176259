<template>
<hlx-alert-notification :notify="notification" :auto-close="true" />
<!-- 407px -->
<hlx-modal
      :key="modalKey"
      :modal-active="showAddNewRegionModal"
      :close-icon="true"
      :outer-layer-close-option="false"
      :height="dyheight"
      :width="'800px'"
      :modal="true"
      @close="closeAddNewRegionModal"
    >
      <template #header>
        <div>Add region</div>
      </template>
      <template #content>
        <div class="modal-content" style="display: flex; gap: 5%">
          <div
            style="
              display: flex;
              flex-direction: column;
              gap: 20px;
              width: 50%;
            "
          >
            <div style="display: flex; justify-content: space-between; gap: 10px">
              <span style="width: 200%">
                <div
                  class="decision-name-wrapper"
                  :style="{ 'margin-bottom': customnameerror ? '-7.6%' : 0 }"
                >
                  <hlx-input
                    v-model:value="regionName"
                    :required="true"
                    :pre-val="regionName"
                    label-value="Region name"
                    :label-animation="true"
                    type="text"
                    :clearable="false"
                    :display-error="true"
                    :custom-error="isExistingName"
                    :custom-error-message="isExistingNameErrorMessage"
                    @focus-out="validateNameNew"
                    @at-input="validateName"
                  ></hlx-input>
                </div>
                <div style="width: 100%">
                  <hlx-status
                    :type="'select-status'"
                    :required="true"
                    :status-option="filteredstatus"
                    :prevalue="servicestatus"
                    @emit-select="emitStatus1"
                  ></hlx-status>
                </div>
              </span>
            </div>
  
            <div class="decision-name-wrapper">
              <hlx-select
                v-model:value="type"
                :required="true"
                :pre-value="type"
                :options="dropdown_data_type"
                :placeholder-value="'Type'"
                :label-animation="true"
                :label="'value'"
                :prop-value="'value'"
                :clearable="true"
              @selected-value="getSelectedSku"
              />
            </div>
  
            <addressComponent
            :key="addressKey"
            :type="type"
              @address-data="receivedAddressData"
            ></addressComponent>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              width: 50%;
            "
          >
            <div class="decision-name-wrapper">
              <hlx-input
                v-model:value="description"
                :pre-val="description"
                type="textarea"
                :label-value="'Description'"
                :required="false"
                :label-animation="true"
                :display-error="true"
                :rows="7"
                :custom-error-message="customErrorMessage"
                @focus-out="focusOutName"
                @at-input="validateName"
              ></hlx-input>
            </div>
  
            <div class="decision-name-wrapper">
              <hlx-multi-select
                :key="modalKey"
                :pre-value="tags"
                :options="tagsList"
                prop-value="name"
                :multi-select="true"
                :placeholder="'Tags'"
                :label-animation="true"
                :clearable="true"
                :tooltipposition="'left'"
                @add-data="addTagList"
                @custom-change="receivedTags"
              ></hlx-multi-select>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <hlx-button class="secondary sm" @click="closeAddNewRegionModal">
          Cancel
        </hlx-button>
        <hlx-button
          class="primary sm"
          style="margin-left: 10px"
          :disabled="!enableAdd"
          @click="addNewRegion"
        >
          Add
        </hlx-button>
      </template>
    </hlx-modal>
  </template>
  
  <script>

  import addressComponent from "@/components/Product/ServiceQualification/dynamicAddress.vue"
// import addressComponent from "./dynamicAddress.vue";
import {
  DMNAxiosInstance,
  // MLTAxiosInstance,
  UPCAxiosInstance,
  // UPCAxiosInstance
} from "@/config/axiosConfig";

export default {
    
  components: {
    addressComponent,
    },

    props: {
      openModal: {
        type: Boolean,
      default:false
      },
    },

    emits: ["addRegion","closeModal"],
    data() {
      return {
        enableAdd:false,
        notification:"",
        dyheight:'407px',
        modalKey:1,
        characteristic: {
        "@type": "",
        street: "",
        city: "",
        country: "",
        // regio nName:'',
        default: false,
        description: "",
        postalCode: "",
        state: "",
        stateOrProvince: "",
        street1: "",
        street2: "",
          unit: "",
          lat: null,
          lng:null,
        },



        
        showAddNewRegionModal:false,
        regionName: "",
        description: "",
        type: null,
        tags: [],
        filteredstatus: [],
        servicestatus: 'Pending',
        dropdown_data_type: [
        // { value: "Full address" },
        // { value: "Street" },
        { value: "Province" },
        { value: "City" },
        { value: "Postal code" },
        // { value: "Prefix (FSA)" }
      ],
        tagsList: [],
        customnameerror: false,
        isExistingName: false,
        isExistingNameErrorMessage: "Name already exists",
      };
    },

    watch: {


      
      regionName: {
      handler(val) {
        val
          if (this.type && this.regionName && this.servicestatus) {
            this.enableAdd = true;
          } else {
            this.enableAdd = false;
        }
      },
      immediate: true,
      deep: true,
      },

      
      // type: {
      // handler(val) {
      //     val
      //     if (this.type && this.regionName && this.servicestatus) {
      //       this.enableAdd = true;
      //     } else {
      //       this.enableAdd = false;
      //   }
      // },
      // immediate: true,
      // deep: true,
      // },


      
      servicestatus: {
      handler(val) {
        val
          if (this.type && this.regionName && this.servicestatus) {
            this.enableAdd = true;
          } else {
            this.enableAdd = false;
        }
      },
      immediate: true,
      deep: true,
      },

    
      openModal: {
            async handler(val) {
            console.log(val, "addRegionsModalComp");
                console.log("addRegionsModalComp::",val);
          if (val) {
            this.showAddNewRegionModal = true;
          } else {
            this.showAddNewRegionModal = false;
                }               
            },
            immediate: true,
            deep: true,
      },

      type: {
        async handler(val) {
          console.log("type))", val);
          if (this.type && this.regionName && this.servicestatus) {
            this.enableAdd = true;
          } else {
            this.enableAdd = false;
        }


          setTimeout(() => {
            console.log("this.modalKey",this.modalKey);
            this.modalKey
          }, 2000);
          if (val === "Full address") {
            val
          } else if (val === "Street") {
            val
          } else if (val === "City") {
            val
          } else if (val === "Province") {
            val
          } else if (val === "Postal code") {
            val
          } else if (val === "Prefix (FSA)") {
            val
          } else {
            console.log("type is not matching");
            
          }
        },
        immediate: true,
        deep:true,
      }
    },

    async mounted() {

      let status = await this.getReferanceData('statuslist');
      console.log("status::", status);
      this.filteredstatus = status.filter((item) =>
        ["Active", "Inactive", "Pending"].includes(item.name)
      );



      let tagsListData =  await this.getReferanceData('tagsList')
    this.tagsList = tagsListData?.filter((item) => {
      return item.module === "sq region";
    });


      
    },
    
    methods: {

      

  getSelectedSku(val){
        val
        console.log("getSelectedSku::::", val,
          // this.addressKey
        );

        // this.addressKey += 1;
        
        // this.modalKey += 1;
        if (val.value === "Full address") {
          this.dyheight = '643px'  
        } else {
          this.dyheight = '407px'
        }

   },

      geopoint(lat,lng) { 
        console.log("geopoint::::",lat,lng);
        this.lat = lat;
      this.lng = lng;
      },
      validateName() {
        // Add name validation logic
      },
      validateNameNew() {
        // Add additional validation logic
      },
      emitStatus1(status) {
        this.servicestatus = status.value;
        console.log("status::",
          // this.addressKey,
          status.value);
        
      },
      async addTagList(e) {
        console.log("addTagList::",e);
        
      let obj = {};
      obj.name = e.name;
      obj.module = "sq region";
      await DMNAxiosInstance.post(`mapRef/tagsList`, obj);
      let tagsList = await DMNAxiosInstance.get(`mapRef/collection/tagsList`);
      let arr = this.tags;
      arr.push(e.name);
      this.tags = arr;
      this.tagsList = tagsList.data.data.filter((item) => {
        return item.module === "sq region";
      });
      // console.log(e);
      // this.tagsList.push(e);
    },
      receivedTags(e) {
        console.log("receivedTags::",e);        
        let arr = [];
      e.filter((item) => {
        if (item.checked == true) {
          arr.push(item.name);
        }
      });
        this.tags = arr;
      console.log("this.tags::",this.tags);
      
      },
      receivedAddressData(val) {
        console.log("Address data received", val);

        this.characteristic = val?.characteristic;
        this.lat = val?.characteristic?.lat;
        this.lng = val?.characteristic?.lng;
        
      },
      focusOutName() {
        // Custom focus out logic
        },
        closeAddNewRegionModal() { 
            this.regionName = '';
            this.servicestatus = '';
            this.type = '';
            this.address = '';
            this.description = '';
            this.tags = '';
            
          this.showAddNewRegionModal = false;
          this.$emit('closeModal', this.showAddNewRegionModal);
            

        },

        async addNewRegion() {
          //   let obj = {
          //       regionName: this.regionName,
          //       servicestatus: this.servicestatus,
          //       type: this.type,
          //       address: this.address,
          //       description: this.description,
          //       tag: this.tag
          //   }

          // obj;

          let createdat = { createdat: `${this.formatCurrentTime()}` };
          let updatedat = { updatedat: `${this.formatCurrentTime()}` };

          
      let latitude = { latitude: this.lat };
      let longitude = { longitude: this.lng };
      // let radius = { radius: this.radiuscircle };
      let regionName = { regionName: this.regionName };
      // let regionshape = { regionshape: this.vradio };
      // let propertytype = { propertytype: this.propertytype };
      // let addressquery = { addressquery: this.addressquery };
      let type = { type: this.type };
      // let address = { polygonpathdata: this.address };
      let description1 = { description1: this.description };
      let tags = { tags: this.tags };
      let category = { category: "parent" };
      // let radiuscircle1 = { radiuscircle1: this.radiuscircle1 };
      // let rangeName = { rangeName: this.rangeName };

      let addresssdescription = {
        description: this.characteristic?.description,
      };
      let servicestatus = { servicestatus: this.servicestatus };

      let characteristicData = {
        ...this.characteristic,
        ...latitude,
        ...longitude,
        // ...radius,
        ...regionName,
        // ...regionshape,
        // ...propertytype,
        // ...addressquery,
        ...addresssdescription,
        ...servicestatus,
        // ...polygonpathdata,
        // ...radiuscircle1,
        // ...rangeName,
        ...type,
        // ...address,
        ...tags,
        ...description1,
        ...category,
        ...updatedat,
        ...createdat,
          };



          const obj = {

        streetAddressLine: [
          {
            mediumType: "geographicAddress",
            characteristic: characteristicData,
          },
            ],
          };

          

          obj
          console.log("finally____:", obj);

          // let bools = false;
          // this.enableAdd = bools
          if (this.enableAdd) {
            // this.enableAdd = true;


               // let obj123 = false;
            if (obj) {
            setTimeout(async() => {
              let postresponse = await UPCAxiosInstance.post(
          `/geographicAddress`,
          obj
        );



        if (postresponse) {
          this.notification = {
            type: "success",
            message: `Region created successfully!`,
          };
          // this.initLog(additionalChargeLogPayload);



          
          this.showAddNewRegionModal = false;

          this.$emit('addRegion', obj);



        }
            }, 1500);
         
      }
          }
          // else {
            
          // }


       
          





          


        }
    },
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>
  