<template>
<section v-if="filterType.toLowerCase() === 'searchandfilter'">
  <!-- <section style="margin-bottom: 12px">
    <hlx-multi-select
                    :key="categoryComponentKey"
                    :options="categoryInfo"
                    :placeholder="'Choose category'"
                    :label-animation="true"
                    :prop-value="'name'"
                    :label="'value'"
                    :disabled="false"
                    @input-value="searchSupplierMethod"
                    @add-data="listCategory"
                    @selected-array="selectedCategory"
                  ></hlx-multi-select>
  </section> -->
  <section class="filter-serach-cover" :style="{borderColor:isError?'red': '#d8d8d8'}">
    <i class="icon-search-regular search-logo"></i>
    <div
    :id="'search-and-filter2' + unique"
     ref="placeholder"
      class="customize-placeholder"
    :style="{display: isPlaceholder ? 'block' : 'none' , width:'100%',color:'#a6a6a6'}"
      @click="viewDropDown" 
    >
      {{ computedPlaceholder }}
      <span v-show="isRequired == true" class="asterik">{{ "*" }}</span>
    </div>
    <!-- <span  
     > -->
    <input
    :id="'search-and-filter' + unique"
    ref="inputBox"    
    
    v-model="search"
    :style="{display: (isTag || isPlaceholder) ? 'none' : 'block' , width:'100%'}"
      class="filter-search-input-box"
      autofocus
      @click="viewDropDown"
    />
  <!-- </span> -->
    <!-- ref="tags"
    :id="'tags' + unique" -->
    <span
      v-if="!isSearch && isTag"
      class="filter-search-tag"
      :style="{ width: !isTag ? '0px' : '80%' }"
    >
      <!-- v-show="isTag" -->
      <hlx-tagcount
        :id="'tags' + unique"
        :key="update"
        :style="{ display: !isTag ? 'none' : 'block' }"
        :arrayfile="checkedByUsers"
        item-key="name"
        :type="'tag'"
        @click="handleFilterSearchTagClick"
        @tag-closed="removeTag"
      ></hlx-tagcount>
    </span>
    <section
      class="filter"
      :style="{ 'background-color': isActiveFilter ? '#DCF9EA' : 'white' }"
      :class="{ active: isActiveFilter }"
    >
      <i :id="'filter-icon' + unique"  class="icon-filter-regular filter-logo" @click="showFilter"> </i>
      <span class="filter-total-count">{{
        labelValues.length <= 0 ? "" : labelValues.length
      }}</span>
    </section>
  </section>
  <section v-if="isError" style="color:red" >{{ errorMessageFromProps }}</section>
  <overlay
    v-show="isSearch === true"
    :show="isSearch"
    :top="containertop"
    :left="containerleft"
    :width="containerwidth"
    :height="containerheight"
    :conditionvalue="250"
    :conditiondown="45"
    :conditionup="10"
    @close-overlay="closeModal"
  >
    <template #overlay>
      <section v-show="isSearch && filterSearchOptions.length > 0"  ref="dropdown" class="search-drop-down">
        <section        
          ref="activefilter"
          class="active-filter"
          :style="{ display: labelValues.length > 0 ? 'flex' : 'none' }"
        >
          <span class="active-filter-header">Active filters :</span>

          <!--Label values indicated an array of values that checked in the filter 
  
      For example Array looks like:
      [ 
      { "count": 1, "value": { "value": "Operating Temperature", "type": "String", "valueSuffix": "Each" } }, 
      { "count": 1, "value": { "value": "Mobile to TV", "id": "", "type": "string", "valueSuffix": "Each" } } 
      ]
      
      -->

          <section
            v-for="(value, index) in labelValues"
            :key="index"
            class="active-filter-tag"
          >
            <!-- {{value}}
      {{labelValues}} -->
            <hlx-tag @tag-close="removed(value)">{{
              value[filterValueObjectName] || "Others"
            }}</hlx-tag>
            <!-- <hlx-tag  @tag-close="removed(value)">{{labelValues}}</hlx-tag> -->
          </section>
        </section>
        <section class="search-checkbox">
          <!-- commented by ragavi -->
           <span v-if="showCategoryProps">
            <hlx-input
            :key="changeNum"
            :options="receivedOptions"
            :type="'checkbox'"
            :show-select-all="true"
            :custom-slot="true"
            :checkbox-title-prop="'name'"
            :checkbox-description-prop="'category'"
            :checkbox-label-prop="'price'"
            @check-box-event="checked"
          />
           </span>
           <span v-if="!showCategoryProps">
            <hlx-input
            :key="changeNum"
            :options="receivedOptions"
            :type="'checkbox'"
            :show-select-all="true"
            :custom-slot="true"
            :checkbox-title-prop="'name'"
            :checkbox-label-prop="'price'"
            @check-box-event="checked"
          />
           </span>
        
        </section>
      </section>
    </template>
  </overlay>
  <!-- <section v-show="filterSearchOptions.length===0 &&isSearch " ref="dropdown" class="search-drop-down">
  <span class="active-filter-header" style="display: flex;
    justify-content: space-around;padding:12px 0 12px 0">No Products</span>
</section> -->
<overlay
    v-show="isFilter === true"
    :show="isFilter"
    :top="containertopFilter"
    :left="containerleftFilter"
    :width="containerwidthFilter"
    :height="containerheightFilter"
    :conditionvalue="250"
    :conditiondown="45"
    :conditionup="10"
 
  >
  <template #overlay>
  <section v-show="isFilter" ref="filter" class="filter-icon">
    <section class="filter-top">
      <span class="filter-heading">Filters</span>
      <i class="icon-times-regular" @click="closeFilter"></i>
    </section>

    <div class="filter-search-accordion">
      <hlx-accordion
        v-for="(values, key) in filterCheckedData"
        :key="key"
        :accordion-data="receivedFilterData"
        :multiple="true" 
      >
        <!-- filterchecked is given by backend (layout) based on that 

layout:[
        {
        prop: "lifecycleStatus",
        label: "Status", // keyValueFromQueryArray
        type: "String",
        fieldToFilter: "lifecycleStatus",
        fieldToSearch:"lifecycleStatus"
      
      },

             -->
        <template #item-title>
          <section class="filter-title-cover">
            <section class="filter-title">
              <span v-if="values[keyValueFromQueryArray] !== 'price'">
                <!-- {{ values[keyValueFromQueryArray] || "Others" }} -->
                {{
                  convertTextTo(values[keyValueFromQueryArray], "pascalcase")
                }}

                <span class="selected-filter-count">{{
                  selectedFilterCounts[values[keyValueFromQueryArray]] || ""
                }}</span>
              </span>
              <span v-else-if="values[keyValueFromQueryArray] === 'price'">
                <span v-if="setIsPrice(true)">
                  {{
                    convertTextTo(values[keyValueFromQueryArray], "pascalcase")
                  }}
                  <span class="selected-filter-count">
                    {{
                      selectedFilterCounts[values[keyValueFromQueryArray]] || ""
                    }}
                  </span>
                </span>
              </span>
            </section>
          </section>
        </template>
        <template #item-body>
          <section v-if="values[keyValueFromQueryArray] !== 'price'">
            <section
              v-for="(item, index) in receivedFilterData[
                values[keyValueFromQueryArray]
              ]"
              :key="index"
              class="filter-content"
            >
              <!-- {{"Check"}}
          {{values[keyValueFromQueryArray] }} -->

              <p class="filter-content-header">
                <!-- {{ "Check" }} -->
                {{ item[filterValueObjectName] || "Others" }}
                <span class="filter-content-count">{{
                  item.count || "0"
                }}</span>
                <!-- <span class="filter-content-count">{{ item.count - filterCount(changeFilterCount,deepCopyFilterSearchOptions,item)}}</span> -->
                <!-- <span class="filter-content-count">
                {{ filterSearchOptions.length > 0 ? item.count : "0" }}
              </span> -->
              </p>
              <section class="filter-check-box">
                <!-- commented -->
                <!-- {{item}} -->
                <hlx-input
                  v-model:value="checkedval"
                  :checked="
                    isThisChecked(
                      item,
                      values.fieldToFilter,
                      values[keyValueFromQueryArray]
                    )
                  "
                  :type="'single-checkbox'"
                  @selected="
                    checkedDataInFilter(
                      item,
                      values.key,
                      values[keyValueFromQueryArray],
                      values.fieldToFilter
                    )
                  "
                />
              </section>
            </section>
          </section>

          <section v-if="values[keyValueFromQueryArray] === 'price'">
            <section style="display: block" class="filter-content">
              <!-- {{"Check"}}
          {{values[keyValueFromQueryArray] }} -->

              <p
                class="filter-content-header"
                style="display: contents; margin-bottom: 12px"
              >
                <hlx-min-max-slider
                  :key="updateSliderKey"
                  v-model:min-value="minProductPriceInputField"
                  v-model:max-value="maxProductPriceInputField"
                  :min="minProductPrice"
                  :max="maxProductPrice"
                  @slidervalue="sliderval"
                ></hlx-min-max-slider>
              </p>

              <section
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 12px;
                  margin-top: 12px;
                "
              >
                <span style="width: 45%">
                  <hlx-input
                    v-model:value="minProductPriceInputField"
                    :pre-val="minProductPriceInputField"
                    :label-animation="true"
                    type="number"
                    label-value="Min"
                    :clearable="false"
                  />
                </span>

                <span style="width: 45%">
                  <hlx-input
                    v-model:value="maxProductPriceInputField"
                    :pre-val="maxProductPriceInputField"
                    :label-animation="true"
                    type="number"
                    label-value="Max"
                    :clearable="false"
                  />
                </span>
              </section>
              <section
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 12px;
                "
              >
                <button
                  id="hlx-button-0009"
                  type="button"
                  name="hlx-button"
                  class="hlx-button primary sm add-btn"
                  aria-label="Button"
                  value="Hlx click :)"
                  @click="
                    sendPriceQuery(
                      minProductPriceInputField,
                      maxProductPriceInputField
                    )
                  "
                >
                  Apply
                </button>
                <button
                  id="hlx-button-0009"
                  type="button"
                  name="hlx-button"
                  class="hlx-button secondary sm add-btn filterbutton"
                  aria-label="Button"
                  value="Hlx click :)"
                  @click="resetPriceQuery()"
                >
                  Reset
                </button>
              </section>
            </section>
          </section>
        </template>
      </hlx-accordion>
    </div>
  </section>
  </template>
</overlay>
</section>
<section v-if="filterType.toLowerCase() === 'category'">
  <hlx-multi-select
  :key="categoryComponentkey"
   :pre-value="marketPreValue"
                    :options="receivedOptions"
                    :placeholder="'Choose category'"
                    :label-animation="true"
                    :prop-value="'name'"
                   :label="'name'"
                          :disabled="false"
                          :filter-select="false"
                    @selected-array="selectedCategory"
                  ></hlx-multi-select>
</section>
</template>

<script>
import { MLTAxiosInstance } from "@/config/axiosConfig";
import overlay from "@/components/overlayComponent.vue";
export default {
  name: "SearchfilterComponent",
  components: {
    overlay,
  },
  // In props module represent name of the flow.E.g: module:ProductOffering,
  // module is one of the keyvalue that we emit while typing in searchbox.

  //In filterSearchOptions:Array of value need to be in checkbox

  props: {
    // changeFilterCount:{
    //   type:Array,
    //   default:()=>{
    //     return []
    //   }
    // },
    // categoryData:{
    //   type:Array,
    //   default:()=>{
    //     return []
    //   }
    // },
    categoryData:{
      type:Array,
      default:()=>{
        return []
      }
    },
    type:{
      type: String,
      default: "searchandfilter",
    },
    showCategory:{
      type: Boolean,
      default: true
    },
    preValue:{
      type: String,
      default: "",
    },
    update: {
      type: Number,
      default: 0,
    },
    errorMessage:{
      type:String,
      default:"Select a proper product"
    },
    showError:{
      type:Boolean,
      default:false,
    },
    module: {
      // prop fo module type : in backend query
      type: String,
      default: "ProductOffering",
    },
    filterSearchOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    filterData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    filterCheckedData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    dropdownWidth:{
      type: String,
      default:"362"
    },
    placeholder:{
     type: String,
    default:"Search catalogue"
    },
    preValueItems:{
      type:Array,
      default:()=>{
        return []
      }
    },
    keyValueFromQueryArray: {
      //   {
      // key:"Multi device feature",
      // fieldtoFilter:"multidevicefeature.mediumtype"
      //     },

      type: String,
      default: () => {
        return "key";
      },
    },
    filterValues: {
      // Environment: [ // Environment represents the filter headervalue
      //     {
      //       count: 1,
      //       value: {  //filterValueObjectName props
      //         value:
      //           "Operating Temperature", // Represents the filtervalues props inside the Environment
      //         type: "String",
      //         valueSuffix: "Each",
      //       },
      //     },
      //   ],
      type: String,
      default: () => {
        return "value";
      },
    },
    required:{
      type:Boolean,
      default:false
    },
    filterValueObjectName: {
      type: String,
      default: () => {
        return "value";
      },
    },
  },
  emits: ["searched-value", "checked-value","removed-value","selected-category"],
  data() {
    return {
      // categoryComponentKey:0,
      // categoryInfo:[],
      categoryComponentkey:0,
      categoryOptions:[],
      filterType:"",
      preValueList:[],
      showCategoryProps:"",
      errorMessageFromProps:"",
      isError:false,
      isRequired:false,
      isPlaceholder:true,
      placeholderProps:"",
      unique: Math.floor(Math.random() * 1000 + 1),
      updateSliderKey: 0,
      updateKey: 0,
      containertop: 0,
      containerleft: 0,
      containerwidth: this.dropdownWidth,
      containerheight: 382,
      containertopFilter:0,
    containerleftFilter:0,
    containerwidthFilter:"",
    containerheightFilter:382,
      minProductPriceInputField: 0,
      maxProductPriceInputField: this.maxProductPrice,
      minProductPrice: 0,
      maxProductPrice: "",
      isPrice: false,
      companyName: "",
      isOrExists: 0,
      isValueExists: 0,
      changeNum: 0,
      relatedProductPanel: false,
      selectedFilterCounts: {},
      checkedval: {},
      labelValues: [],
      deepCopyFilterSearchOptions: [],
      eventValues: [],
      search: "",
      selectoptioncontainer: false,
      queryType: [],
      isSearch: false,
      isTag: false,
      receivedOptions: [],
      checkedByUsers: [],
      isFilter: false,
      isActiveFilter: false,
      receivedFilterData: {},
      sendMin: 0,
      sendMax: 0,
      filterQueryFormat: {
        // type: this.queryTypeMethod(this.queryType),
        type: "filter-search",
        module: this.module,
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 15,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,

        filterQuery: {},
        searchQuery: "",
        company: this.$cookies.get("company"),
      },
    };
  },

  computed: {
    //   filteredOptions() {
    //     if (!this.search.trim()) {
    //       return this.receivedOptions;
    //     }
    //     const searchedValue = this.search.toLowerCase();
    //     return this.searchedCheckBox(searchedValue);
    //   },
    computedPlaceholder(){
      if(this.isPlaceholder){
       return this.placeholderFunc(this.placeholderProps)
      }
      else{
        return this.placeholderProps
      }
    }
  },
  watch: {
    categoryData:{
      handler(val){
        this.categoryOptions=val
        console.log(this.categoryOptions,"categoryOptions",val)
      },
      immediate: true,
      deep: true,
    },
    type:{
      handler(val){
        this.filterType=val
      },
      immediate: true,
      deep: true,
    },
    preValueItems:{
      handler(val){
        
        val = val.map(item => {
  item.checked = true;
  return item;
});
        this.preValueList=val
        this.checkedByUsers=this.preValueList
        console.log(  this.checkedByUsers,val,"insied the componet preval",this.receivedOptions);
      },
      immediate: true,
      deep: true,
    },
    preValue:{
      handler(val){
        this.search=val
      },
      immediate: true,
      deep: true,
    },
    // categoryData:{
    //   handler(val){
    //     console.log("categoryData",val.Category)
    //     this.categoryInfo = val?.Category.map(ele => {
    //     return {
    //       ...ele,
    //       value: `${ele.name} - ${ele.count}`
    //     };
    //   });
    //   this.categoryComponentKey+=1;
    //     console.log("categoryData categoryInfo",this.categoryInfo)
    //   }
    // },
    showCategory:{
      handler(val){
        this.showCategoryProps=val
      },
      immediate: true,
      deep: true,
    },
    showError:{
      handler(val){
        this.isError=val
      }
    },
    errorMessage:{
      handler(val){
        this.errorMessageFromProps=val
      }
    },
    required:{
      handler(val){
        console.log("required ",val)
        val === true?this.isRequired=true:this.isRequired=false
      },
      immediate: true,
      deep: true,
    },
    placeholder:{
      handler(val){
        
        this.placeholderProps=val
      },
      immediate: true,
      deep: true,
    },
    filterSearchOptions: {
      // check the array if the array elements is already checked, so marking it as checked=true
      handler(val) {
        if(val && this.filterType.toLowerCase() !=="category")
      {
        if (val) {
          val.forEach((e) => {
            if (!("checked" in e)) {
              e.checked = false;
            }
            if (!("disabled" in e)) {
              e.disabled = false;
            }
            if (!e.category || String(e.category).trim() === "") {
              e.category = "-";
            }
          });
        }

        // While searching selected value remains as selected
        val.forEach((product) => {
          if (this.checkedByUsers.some((user) => user.name === product.name)) {
            product.checked = true;
          }
        });
        val.forEach((product) => {
          if (product.checked === true) {
            // Check if the product already exists in checkedByUsers
            const existingProduct = this.checkedByUsers.find(
              (item) => item._id === product._id
            );
            if (!existingProduct) {
              this.checkedByUsers.push(product);
            }
          }
        });

        console.log(this.filterType,"value", val);

        this.receivedOptions = JSON.parse(JSON.stringify(val));
        
        if (this.receivedOptions) {
          this.getNumericPrice(this.receivedOptions);
        }
        // this.receivedOptions= this.receivedOptions.concat(this.preValueList)
        this.preValueList = this.preValueList.map(item => {
          item.checked = true;
          return item;
        }); 


          this.preValueList.forEach((checkedItem) => {
            const matchingItem = this.receivedOptions.find(
              (item) => item._id === checkedItem._id
            );
            if (matchingItem) {
              matchingItem.checked = true;
            } else {
              checkedItem.checked = true;
              this.receivedOptions.unshift(checkedItem); 
            }
          });
                  console.log(this.preValueList,"watching :", this.receivedOptions);
        // }
        // else{
        //     console.log("I am from filterSearchOptions..")
        // }
      }
      else if(val && this.filterType.toLowerCase() ==="category"){
        this.receivedOptions=val
      }
      else{

        console.log("receivedOptions",val)
      }
      },
      immediate: true,
      deep: true,
    },

    filterData: {
      handler(val) {
        if (val) {
          console.log("From filter data watch");
          this.receivedFilterData = JSON.parse(JSON.stringify(val));
          console.log(this.receivedFilterData);
          // receivedFilterData is an object of arrays

          console.log("I am duplicate filter ", this.receivedFilterData);
        } else {
          console.log("I am from filter data..");
        }
      },
      immediate: true,
      deep: true,
    },
    search: {
      handler(val) {
        if (val || val === "") {
          this.searchedCheckBox(val);
          console.log("Hi...");
        } else {
          console.log("I am from searchedCheckBox..");
        }
      },
      immediate: true,
      deep: true,
    },
    isSearch: {
  handler(val) {
    try {
      this.$nextTick(() => {
        if (this.$refs.inputBox) {
          if (val) {
            this.$refs.inputBox.focus();
          } else {
            this.$refs.inputBox.blur();
          }
        } else {
          console.log("inputBox ref is undefined");
        }
      });
    } catch (e) {
      console.log(e, "error from isSearch watch searchandfilter");
    }
  },
  immediate: true,
  deep: true,
},

    checkedByUsers: {
      handler(newValue) {
        this.$emit("checked-value", newValue);
        if (!this.isSearch && (this.isTag = newValue.length > 0)) {
          this.isTag = true;
          this.isPlaceholder=false
        }
      },
      immediate: true,
      deep: true,
    },
      labelValues:{
        handler(newValue){
          console.log(newValue,"Event from watch", this.receivedOptions)
    // this.isThisChecked(newValue)
//     if(newValue.length>0){
//       this.receivedOptions = this.receivedOptions.map(offer => {
//   return {
//     ...offer,
//     checked: true
//   };
// });
// console.log("if",this.receivedOptions)
//     }
//     else{
//       console.log("else",this.receivedOptions)
//       return this.receivedOptions
//     }
        },
        immediate: true,
        deep: true
      }
    // isTag:{
    //   handler(value){
    //     if(value){
    //       this.isSearch=true
    //     }
    //     else{
    //       this.isSearch=false
    //     }
    //   },
    //   immediate: true,
    //   deep: true
    // }
    // queryType: {
    //   handler(newVal) {
    //     if (newVal) {
    //       console.log("queryType has changed:", newVal);
    //       this.queryTypeMethod(this.queryType);
    //     }
    //     else{
    //         console.log("I am from queryType has changed..")
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },

  async mounted() {
    // this.filterSearchOptions.forEach((option) => {
    //   option.checked = false;
    //   console.log("Mounted :",option);
    // });
    (this.maxProductPriceInputField = this.maxProductPrice),
      (this.deepCopyFilterSearchOptions = JSON.parse(
        JSON.stringify(this.filterSearchOptions)
      ));

    const val = await MLTAxiosInstance.get(`partymanagement/individual/auth`);
    this.companyName = await val.data.company;
    console.log("this.company :", val.data, this.companyName);
    this.$emit("searched-value", this.filterQueryFormat);

    console.log("from filter query mounted :", this.filterQueryFormat);
    console.log("send");
    console.log(`Component ${this.update} clicked`);
    // const filterSearchTag = document.querySelector(".filter-search-tag");
    // if (filterSearchTag) {
    //   filterSearchTag.addEventListener(
    //     "click",
    //     this.handleFilterSearchTagClick
    //   );
    // }
    this.resetPriceQuery();
    this.getNumericPrice(this.filterSearchOptions);
//     this.categoryComponentKey+=1;
//     this.categoryInfo = this.categoryData?.Category?.map(ele => {
//   return {
//     ...ele,
//     value: `${ele.name} - ${ele.count}`
//   };
// }) || [];

    // console.log("categoryData mounted", this.categoryInfo);
  },

  methods: {
    selectedCategory(val){
      console.log("selectedCategory",val)
      this.$emit("checked-value",val)
      // this.$emit("selected-category",val)
    },
//     listCategory(val){
//       console.log("listCategory",val)
//     },
//     selectedCategory(val){
//     let checked=[]
// console.log("selectedCategory",val)
// val.length!=0?this.checkedval=true:this.checkedval=false
// if( this.labelValues.length >= 1){
// checked = val.filter(ele => {
//   return this.labelValues.every(e => e.name != ele.name);
// });
// console.log(checked,"selectedCategory if")
// }
// else{
// checked=val
// console.log(checked,"selectedCategory else")
// }


// this.checkedDataInFilter(checked[0],undefined,'Category','category')
//     },
    placeholderFunc(val){
      console.log("placeholderFunc",val)
      return val
    },
    //   filterCount(changeFilterCount,item,checkedName) {
    //     console.log("yes match success 0.001 ",checkedName)
    //     console.log("yes match success 0.01 ",changeFilterCount)
    //     console.log("yes match success 0.1 ",item)
    //     if (changeFilterCount && changeFilterCount.length > 0) {
    //     // Use Array.prototype.some() to check if any item matches the condition
    //     changeFilterCount.forEach(e => {
    //     console.log("yes match success 1:", e);
    //     if (e.name === item.name) {
    //       console.log("yes got it")
    //       console.log("yes match success 2:", item);
    //       return 1; // Return 1 if the condition is met
    //     }
    //   });
    //   console.log("yes match success 3:", item);
    //   return 0;
    //   } else {
    //     console.log("yes match success 4",item)
    //     return 0;
    //   }
    // },
    resetPriceQuery() {
      this.updateSliderKey++;
      this.getNumericPrice(this.receivedOptions);
      this.minProductPriceInputField = this.minProductPrice;
      (this.maxProductPriceInputField = this.maxProductPrice),
        // let PriceFilterkeyname = "price";

        (this.labelValues = this.labelValues.filter(
          (label) => !(label.filterkeyname === "price")
        ));
      this.isFilter = !this.isFilter;
      //       this.filterQueryFormat.filterQuery["productOfferingPrice.productPrice.dutyFreeAmount"]. $lte="",
      // this.filterQueryFormat.filterQuery["productOfferingPrice.productPrice.dutyFreeAmount"].$gte="",
      delete this.filterQueryFormat.filterQuery[
        "productOfferingPrice.productPrice.dutyFreeAmount"
      ];
      this.$emit("searched-value", this.filterQueryFormat);
      this.isFilter = !this.isFilter;
      console.log("filterQueryFormat price", this.filterQueryFormat);
    },
    sliderval(min, max) {
      console.log("silder", min, max);

      this.minProductPriceInputField =
        min !== 0 ? min : this.minProductPriceInputField;
      this.maxProductPriceInputField =
        max !== 0 ? max : this.maxProductPriceInputField;

      // Update the min and max values for sending the price query
      this.sendMin = this.minProductPriceInputField;
      this.sendMax = this.maxProductPriceInputField;
      console.log(
        "slider v-model",
        this.minProductPriceInputField,
        this.maxProductPriceInputField
      );
      console.log("silder", this.sendMin, this.sendMax);
    },
    sendPriceQuery(productMinPriceInputField, productMaxPriceInputField) {
      let minMaxPrice = {
        "productOfferingPrice.productPrice.dutyFreeAmount": {
          $lte: Number(productMaxPriceInputField),
          $gte: Number(productMinPriceInputField),
        },
      };
      this.filterQueryFormat.filterQuery = {
        ...this.filterQueryFormat.filterQuery,
        ...minMaxPrice,
      };
      console.log("filterQueryFormat price", this.filterQueryFormat);
      //       this.filterQueryFormat.filterQuery["productOfferingPrice.name"]. $lte=productMaxPriceInputField,
      // this.filterQueryFormat.filterQuery["productOfferingPrice.name"].$gte==productMinPriceInputField,
      this.isFilter = !this.isFilter;
      this.$emit("searched-value", this.filterQueryFormat);
      let pushIntoLabelValues = {
        count: 1,
        name: ` ${this.$store?.getters?.getDefaultSettings?.currency?.symbol}${productMinPriceInputField}-${this.$store?.getters?.getDefaultSettings?.currency?.symbol}${productMaxPriceInputField}`,
        filterkeyname: "price",
        filtername: "productOfferingPrice.name",
      };
      this.labelValues.push(pushIntoLabelValues);
      console.log("Event labelValues", this.labelValues);
      console.log("filterQueryFormat price", this.filterQueryFormat);
    },
    getNumericPrice(receivedOptions) {
      console.log(receivedOptions, "receivedOptions");

      if (receivedOptions) {
        const prices = receivedOptions?.map((item) => {
          // console.log(typeof item.price,"priceString")
          // console.log(item.price,"priceString")
          const priceString = String(item.price);
          // console.log(typeof priceString,"priceString")
          const numericValue = priceString.replace(/[^\d.]/g, "");
          return Math.round(parseFloat(numericValue).toFixed(2));
        });
        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);
        // console.log("Min price",minPrice,maxPrice)
        this.minProductPrice = minPrice;
        this.maxProductPrice = maxPrice;
        // return { minPrice, maxPrice };
      }
    },
    setIsPrice(val) {
      this.isPrice = val;
      return true;
    },
    getCheckboxSubTitleProp() {
      // Check if _id is available in receivedOptions
      if (
        this.receivedOptions &&
        this.receivedOptions.some((option) => option.category.trim() !== "")
      ) {
        return "category";
      } else {
        return "'-'";
      }
    },

    removeTag(value) {
      console.log("removed ", value);
      console.log("removed name ", value.name);

      const index = this.checkedByUsers.findIndex(
        (product) => product.name === value.name
      );
      // const indexForReceivedOptions=this.receivedOptions.findIndex(product=>product.name===value.name)
      if (index !== -1) {
        console.log("Index :", index);

        this.checkedByUsers[index].checked = false;
        this.checked(this.checkedByUsers);
        console.log("this.checkedByUsers ", this.checkedByUsers);
      }
      // this.changeNum++;
      this.$emit("checked-value", this.checkedByUsers);
      // this.$emit("removed-value",value)
      // if(index!==-1){
      //   this.receivedOptions[indexForReceivedOptions].checked=false
      // }
      // this.checkedByUsers=this.checkedByUsers.filter(product=>product.name!==value.name)

      console.log("remove checked", this.checkedByUsers);
      console.log("receivedOptions ", this.receivedOptions);
    },
    closeModal(e) {
              document.addEventListener("click", this.isSearchOutside);
      console.log(e)
      // this.isSearch=false
      if(this.isSearch){
      this.$nextTick(() => {
            this.$refs.inputBox.focus();
          });
        }
        else
        {
          
      this.$nextTick(() => {
            this.$refs.inputBox.blur();
          });
        
        }
      console.log("Close modal");
    },
    handleFilterSearchTagClick(event) {
      // this.isSearch = true;
      // this.isTag = false;
      console.log("viewDropDown", event);
      console.log(`Component ${this.update} clicked`, event);
      console.log("handleFilterSearchTagClick", event);
      // this.$refs.inputBox.focus();
      const isIconClick = event.target.classList.contains("icon-times-regular");
      console.log("isIconClick", isIconClick);
      if (isIconClick) {
        this.isSearch = false;

        this.isTag = true;
        this.isPlaceholder=false
        console.log(`Component ${this.update} clicked`, event);
        if(this.checkedByUsers.length===0){
          this.receivedOptions =  this.receivedOptions.map(obj => {
    if (obj.checked) {
        obj.checked = false;
        obj.disabled=false
    }
    return obj;
});
        //   document.removeEventListener("click", this.isFilterOutside);
        // this.containerleft = document
        //   .querySelector("#tags" + this.unique)
        //   .getBoundingClientRect().left -36;
        // this.containertop = document
        //   .querySelector("#tags" + this.unique)
        //   .getBoundingClientRect().top;
        // console.log("Component else ");
        this.isActiveFilter = false;
        this.isFilter = false;
        this.isTag = false;
        // this.isSearch = true;
        this.isPlaceholder=true
// document.addEventListener("click", this.handledataevent);
      }
      } 
   
      else {
        document.removeEventListener("click", this.isFilterOutside);
        this.containerleft = document
          .querySelector("#tags" + this.unique)
          .getBoundingClientRect().left -36;
        this.containertop = document
          .querySelector("#tags" + this.unique)
          .getBoundingClientRect().top;
        console.log("Component else ");
        this.isActiveFilter = false;
        this.isFilter = false;
        this.isTag = false;
        this.isSearch = true;
      // this.$nextTick(() => {
      //       this.$refs.inputBox.focus();
      //     });
        // document.addEventListener("click", this.handledataevent);
//         if(this.checkedByUsers.length===0){
// this.isTag=false;
// this.isSearch=false;
// // document.addEventListener("click", this.handledataevent);
//       }
      }
    },
    handledataevent(event) {
      if (this.isSearch) {
        if (!this.$refs.dropdown.contains(event.target)) {

          console.log("inside the if condistion" );
       this.isSearch = false;
       document.removeEventListener("click", this.handledataevent);
        }
        
      }
      else{
        this.isSearch = true;
      }
    },
    //     handleClickOutside(event){
    // // console.log("this.$refs.tags",this.$refs.tagvountrefvalue.contains(event.target),",this.$refs.tagvountrefvalue",event,"event............",event.target.classList.contains("filter-search-tag"),"event.target.classList.contains...",this.$refs.dropdown,"this.$refs.dropdown..........",this.$refs.dropdown.contains(event.target),"this.$refs.dropdown.contains(event.target)...................");

    //       // console.log("handleClickOutside",this.$refs.dropdown,this.$refs.dropdown.contains(event.target));
    //   console.log(this.$refs.tags);
    //       if ((this.$refs.tags) &&  !this.$refs.dropdown.contains(event.target)) {
    //             this.isSearch = false;
    //             document.removeEventListener("click", this.handleClickOutside);
    //           }
    //           else{
    //             this.isSearch = true;
    //           }
    //     },
    queryTypeMethod(queryType) {
      if (queryType) {
        if (queryType.length > 0) {
          console.log("I am query type lenght :", queryType.length);
          return queryType.join("-");
        } else {
          console.log("I am from query method :", queryType);
          return queryType.toString();
        }
      } else {
        console.log("queryType: false");
        return (this.filterQueryFormat.type = "filter");
      }
    },

    checkLabelValuesLenghtForQuery() {
      if (this.labelValues.length > 0) {
        if (this.queryType.includes("filter")) {
          this.queryType;
        } else {
          this.queryType.push("filter");
        }
      }
      if (this.labelValues.length <= 0) {
        if (this.queryType.includes("filter")) {
          this.queryType = this.queryType.filter((item) => item !== "filter");
          console.log("This all removed from ", this.queryType);
          this.queryType;
        }
      }
    },
    // commented by ragavi
    closeFilter() {
      this.isFilter = !this.isFilter;
      this.isActiveFilter = !this.isActiveFilter;
      //   this.isTag=true
      this.isTag = this.checkedByUsers.length > 0;
    },

    async removed(data) {
      if (data.filterkeyname === "price") {
        console.log("labelValues Data removed from tag :", data);
        console.log("labelValues", data.name);
        console.log("labelValues key", data.filterkeyname);
        console.log("labelValues key", this.labelValues);
        this.labelValues = this.labelValues.filter(
          (label) =>
            !(
              label.name === data.name &&
              label.filterkeyname === data.filterkeyname
            )
        );
        this.resetPriceQuery();
        console.log("labelValues Data removed from tag :", data);
        console.log("labelValues", data.name);
        console.log("labelValues key", data.filterkeyname);
        console.log("labelValues key", this.labelValues);
        this.isFilter = false;
        return;
      } else {
        let event = undefined;
        console.log("Event Values", this.eventValues);
        console.log("I am clicked");
        console.log("labelValues Data removed from tag :", data);
        console.log("labelValues", data.name);
        console.log("labelValues key", data.filterkeyname);
        // const orginalFilterKeyName=data. filterkeyname
        // \\console.log("Data removed from tag :", data.value.value);
        this.labelValues = this.labelValues.filter(
          (label) =>
            !(
              label.name === data.name &&
              label.filterkeyname === data.filterkeyname
            )
        );

        console.log("labelValues deleted");
        console.log(this.labelValues, "labelvalues");

        await this.isCheckedInFilter(data, data.filtername);

        // Object.keys(this.filterQueryFormat.filterQuery).forEach((filtername) => {
        //   let filterArray =
        //     this.filterQueryFormat.filterQuery[filtername] &&
        //     this.filterQueryFormat.filterQuery[filtername]["$in"];
        //   console.log("fname ", filtername);
        //   console.log("get");
        //   console.log(filterArray);
        //   if (filterArray) {
        //     console.log("set");
        //     const indexToRemove = filterArray.indexOf(data.name);
        //     // let indexToRemoveFilterKeyName=data. filterkeyname
        //     // if(orginalFilterKeyName === indexToRemoveFilterKeyName ){
        //     // const indexToRemove = filterArray.indexOf(data.value.value);
        //     if (indexToRemove !== -1) {
        //       console.log("ready");
        //       filterArray.splice(indexToRemove, 1);
        //       if (filterArray.length === 0) {
        //         delete this.filterQueryFormat.filterQuery[filtername];
        //       }
        //     }
        //   }
        //   // }

        // });

        Object.keys(this.receivedFilterData).forEach((element) => {
          const removedDataExists = this.receivedFilterData[element].some(
            (item) => {
              return item.name === data.name;
            }
          );

          if (removedDataExists && this.selectedFilterCounts[element]) {
            this.selectedFilterCounts[element]--;
          }
        });
        console.log(event);
        // await this.checkedDataInFilter(data, event, data.filterkeyname, data.filtername)
        console.log("Lenght of labelValues fromtag :", this.labelValues.length);
        this.checkLabelValuesLenghtForQuery();
        console.log(this.filterQueryFormat);
        console.log("Array after removed :", this.labelValues);
        console.log("I am filters data props :", this.filterCheckedData);
        this.$emit("searched-value", this.filterQueryFormat);
      }
    },
    //   removed(data)
    //     {
    //       console.log("this.checkedval top",this.checkedval)
    //       console.log("Event Values", this.labelValues);
    // // this.checkedval =false
    //       console.log("I am clicked");
    //       console.log("Data removed from tag :", data);
    //       // \\console.log("Data removed from tag :", data.value.value);
    //              this.labelValues = this.labelValues.filter(
    //     (label) => label.name != data.name

    // );
    // console.log("this.checkedval top",this.checkedval)
    //       console.log(this.labelValues, "labelvalues");

    //       Object.keys(this.filterQueryFormat.filterQuery).forEach((filtername) => {
    //         let filterArray =
    //           this.filterQueryFormat.filterQuery[filtername] &&
    //           this.filterQueryFormat.filterQuery[filtername]["$in"];
    //         console.log("fname ", filtername);
    //         console.log("get");
    //         console.log(filterArray);
    //         if (filterArray) {
    //           console.log("set");
    //           const indexToRemove = filterArray.indexOf(data.name);
    //           // const indexToRemove = filterArray.indexOf(data.value.value);
    //           if (indexToRemove !== -1) {
    //             console.log("ready");
    //             filterArray.splice(indexToRemove, 1);
    //             if (filterArray.length === 0) {
    //               delete this.filterQueryFormat.filterQuery[filtername];
    //             }
    //           }
    //         }

    //       });

    //       Object.keys(this.receivedFilterData).forEach(element => {

    //         const removedDataExists = this.receivedFilterData[element].some(item => {
    //         return item.name === data.name;
    //         });

    //         if (removedDataExists && this.selectedFilterCounts[element]) {
    //         this.selectedFilterCounts[element]--;
    //         }
    //         });

    //       console.log("Lenght of labelValues fromtag :", this.labelValues.length);
    //       this.checkLabelValuesLenghtForQuery();
    //       console.log(this.filterQueryFormat);
    //       console.log("Event Values end", this.labelValues);
    //       console.log("Array after removed :", this.labelValues);
    //       console.log("I am filters data props :", this.filterCheckedData);
    //       this.$emit("searched-value", this.filterQueryFormat);
    //     },

    async checkedDataInFilter(val, event, filterkeyname, filtername) {
      console.log("Event top", val);
      if (this.checkedval === true) {
        // if(!this.labelValues.find)
        let addFilterKeyNameVal = { ...val, filterkeyname, filtername };
        // addFilterKeyNameVal = {...addFilterKeyNameVal,filtername}
        this.labelValues.push(addFilterKeyNameVal);

        this.eventValues.push(event);
        console.log("Event labelValues", this.labelValues);
        console.log("Event labelValues addfilterkeyname", addFilterKeyNameVal);
        console.log("Event ", event);
        console.log("Event val", val);
        console.log("Event ", event);
        console.log("Event filtername ", filtername);
        console.log("Event filterkeyname ", filterkeyname);
        console.log("Event eventvalues ", this.eventValues);

        if (!this.selectedFilterCounts[filterkeyname]) {
          this.selectedFilterCounts[filterkeyname] = 1;
          console.log("Whole object :", this.selectedFilterCounts);
          console.log(
            "slected filtercount :",
            this.selectedFilterCounts[filterkeyname]
          );
          console.log(
            "number of selected :",
            this.selectedFilterCounts[filterkeyname]
          );
        } else {
          this.selectedFilterCounts[filterkeyname]++;
          console.log(
            "number of selected :",
            this.selectedFilterCounts[filterkeyname]
          );
        }
      } else {
        let addFilterKeyNameVal = { ...val, filterkeyname, filtername };
        // const getIndex=(addFilterKeyNameVal)=>this.labelValues.includes(addFilterKeyNameVal)

        console.log("Event labelValues addfilterkeyname", addFilterKeyNameVal);
        console.log("Event labelValues", this.labelValues);
        console.log("Event ", event);
        console.log("Event val", val);
        console.log("Event ", event);
        console.log("Event filtername ", filtername);
        console.log("Event filterkeyname ", filterkeyname);
        console.log("Event eventvalues ", this.eventValues);
        console.log("Event val if", val);
        console.log("Event name if", filtername);
        // const index = this.labelValues.findIndex(val=>{val === addFilterKeyNameVal;
        //   console.log("Event addFilterKeyNameVal",val)
        // });
        const index = this.labelValues.findIndex(
          (label) =>
            label.name === addFilterKeyNameVal.name &&
            addFilterKeyNameVal.filterkeyname === label.filterkeyname
        );
        console.log("Event index 1", index);
        const indexOfEvent = this.eventValues.indexOf(event);
        console.log("Event index 2", indexOfEvent);
        if (this.selectedFilterCounts[filterkeyname]) {
          console.log(
            "number of selected :",
            this.selectedFilterCounts[filterkeyname]
          );
          this.selectedFilterCounts[filterkeyname]--;
        }

        console.log(index);
        if (index !== -1) {
          this.labelValues.splice(index, 1);
          console.log("Event val if", val);
          console.log("Event name if", filtername);
          this.isCheckedInFilter(val, filtername);
        }
        if (indexOfEvent !== -1) {
          this.eventValues.splice(indexOfEvent, 1);
        }
      }
      await this.filterQueryProcess(val, event, filterkeyname, filtername);
      this.checkLabelValuesLenghtForQuery();

      console.log("Querytype ", this.queryType);

      console.log("Event I am val", val);
      console.log("I am event", event);
      console.log("I am checkedval", this.checkedval);
      console.log("--------*-------*---------");
      console.log("Header data in filter :", this.eventValues);
      console.log("Checked data in filter :", this.labelValues);
      console.log("--------*-------*---------");

      // this.labelValues.forEach((values, e) => {
      //   console.log("for each :", e);
      //   console.log("for each val :", values.value.value);
      //   console.log("Value :", filtername);
      // });

      // console.log("Value from labelValues array :",this.labelValues[1].value);

      this.$emit("searched-value", this.filterQueryFormat);
      console.log("I am last query :", this.filterQueryFormat);
      val = {};
    },

    filterQueryProcess(val, event, filterkeyname, filtername) {
      if (filtername in this.filterQueryFormat.filterQuery) {
        console.log("I am already exits");

        if (this.checkedval === true) {
          if (
            this.checkedval === true &&
            val.name &&
            this.isOrExists === 0 &&
            this.isValueExists == 0
          ) {
            console.log("new 1");
            // const inArray=val.name?[val.name]:[null,undefined]
            // const inArray=val.name?[val.name]:[val.name]
            this.filterQueryFormat.filterQuery[filtername] = {
              $in: [val.name],
            };
            this.isValueExists++;
          }
          console.log(this.isOrExists, "deepFilterOuery1");

          if (val.name && this.isOrExists === 0) {
            this.filterQueryFormat.filterQuery[filtername]["$in"].push(
              val.name
            );
            console.log("already 1");
          } else if (
            this.isOrExists === 0 &&
            (val.name === "others" || val.name === undefined) &&
            this.isValueExists == 0
          ) {
            console.log("already 2");
            this.filterQueryFormat.filterQuery[filtername] = { $exists: false };
            this.isOrExists++;
          } else if (this.isOrExists >= 1) {
            console.log("already 3");
            let deepFilterOuery = JSON.parse(
              JSON.stringify(this.filterQueryFormat.filterQuery)
            );
            this.filterQueryFormat.filterQuery = { $or: [deepFilterOuery] };
            const newQuery = {};
            this.isOrExists = 1;
            if (val.name) {
              console.log("already 3 defined");
              newQuery[filtername] = { $in: [val.name] };
              // this.isOrExists++;
              this.filterQueryFormat.filterQuery["$or"].push(newQuery);
              console.log(
                "Filtername created and value pushed into $in array of",
                filtername
              );
            }
            if (
              val.name === "others" ||
              val.name === undefined ||
              val.name === null
            ) {
              console.log("already 3 null");
              // this.filterQueryFormat.filterQuery[filtername] = {"$exists": false};
              // let deepFilterOuery = JSON.parse(JSON.stringify(this.filterQueryFormat.filterQuery));
              //         // let deepFilterOuery2= this.filterQueryFormat.filterQuery[filtername] = {"$exists": false};
              //         this.filterQueryFormat.filterQuery={$or:[(deepFilterOuery)]}
              this.filterQueryFormat.filterQuery["$or"][filtername] = {
                $exists: false,
              };
              console.log(deepFilterOuery, "deepFilterOuery3");
              // this.isOrExists++;
            }
            // this.filterQueryFormat.filterQuery["$or"][filtername].push(val.name)
            console.log(deepFilterOuery, "deepFilterOuery2");
          } else if (this.isOrExists === 1 && this.isValueExists > 0) {
            console.log("already 4");
            let deepFilterOuery = JSON.parse(
              JSON.stringify(this.filterQueryFormat.filterQuery)
            );
            this.filterQueryFormat.filterQuery = { $or: [deepFilterOuery] };
            console.log(deepFilterOuery, "deepFilterOuery3");
          } else if (this.isOrExists === 0 && this.isValueExists > 0) {
            console.log("already 5");
            // this.filterQueryFormat.filterQuery[filtername] = {"$exists": false}
            let deepFilterOuery = JSON.parse(
              JSON.stringify(this.filterQueryFormat.filterQuery)
            );
            console.log(deepFilterOuery, "deepFilterOuery");
            // let deepFilterOuery2= this.filterQueryFormat.filterQuery[filtername] = {"$exists": false};
            console.log(deepFilterOuery.length, "deepFilterOuery length");
            // for (const value in deepFilterOuery){
            //   // this.filterQueryFormat.filterQuery={$or:[(deepFilterOuery)]}
            //   console.log(value,deepFilterOuery,"deepFilterOuery value")
            // }
            this.filterQueryFormat.filterQuery = { $or: [deepFilterOuery] };
            this.isOrExists++;
            this.filterQueryFormat.filterQuery["$or"].push({
              [filtername]: { $exists: false },
            });

            console.log(deepFilterOuery, "deepFilterOuery3");
            this.isOrExists++;
          }

          console.log(this.filterQueryFormat, " FilterQueryformat");
        }
      } else if (this.checkedval === true) {
        console.log("I am not exits");
        if (this.checkedval === true && val.name && this.isOrExists === 0) {
          console.log("new 1");
          // const inArray=val.name?[val.name]:[null,undefined]
          // const inArray=val.name?[val.name]:[val.name]
          this.filterQueryFormat.filterQuery[filtername] = {
            $in: [val.name],
          };
          this.isValueExists++;
        } else if (
          this.checkedval === true &&
          Object.keys(this.filterQueryFormat.filterQuery).length === 0 &&
          (val.name === "others" || val.name === undefined || val.name === null)
        ) {
          console.log("new 2");
          // this.filterQueryFormat.filterQuery[filtername] = { $exists: false };
          this.filterQueryFormat.filterQuery[filtername] = [];
          this.isOrExists += 1;
          console.log(this.isOrExists, "deepFilterOuery4");
        } else if (
          this.checkedval === true &&
          Object.keys(this.filterQueryFormat.filterQuery).length === 1 &&
          (val.name === "others" ||
            val.name === undefined ||
            val.name === null) &&
          "productOfferingPrice.productPrice.dutyFreeAmount" in
            this.filterQueryFormat.filterQuery
        ) {
          console.log("new 2.1");
          // this.filterQueryFormat.filterQuery[filtername] = { $exists: false };
          this.filterQueryFormat.filterQuery[filtername] = [];
          this.isOrExists += 1;
          console.log(this.isOrExists, "deepFilterOuery4");
        } else if (
          this.checkedval === true &&
          (this.isOrExists >= 1 || this.isValueExists > 0)
        ) {
          console.log("Pushing value into existing $in array within $or");
          console.log(filtername, val.name);

          if (this.filterQueryFormat.filterQuery["$or"]) {
            for (const query of this.filterQueryFormat.filterQuery["$or"]) {
              //filtername exists
              if (query[filtername]) {
                console.log(query, "if");

                if (query[filtername]["$in"] && val.name) {
                  query[filtername]["$in"].push(val.name);
                  console.log("Value pushed into $in array of", filtername);
                  return;
                }
                // else if(query[filtername]["$in"] && (val.name===undefined || val.name==="Others")){
                //   // console.log(query,"query")
                //   console.log(val.name,"$or or $in")
                //   this.filterQueryFormat.filterQuery["$or"][filtername] = {"$exists": false};
                //   return
                // }
              }
            }
          } else {
            console.log("Creating $or array");
            let deepFilterOuery = JSON.parse(
              JSON.stringify(this.filterQueryFormat.filterQuery)
            );
            // this.isOrExists++;
            this.filterQueryFormat.filterQuery = { $or: [deepFilterOuery] };
            //   const newQuery = {};
            //   if(val.name===null ||val.name===undefined || val.name==="others"){
            //   console.log("Filtername created and value pushed into $in array of", filtername);
            //   newQuery[filtername] = {"$exists": false};
            // this.filterQueryFormat.filterQuery["$or"].push(newQuery);
            // this.isOrExists+=1;
            // }
          }

          console.log(
            "Creating filtername and pushing value into $in array within $or"
          );

          // Create a new filter
          const newQuery = {};
          if (val.name) {
            newQuery[filtername] = { $in: [val.name] };
            // this.isOrExists+=1;
            this.filterQueryFormat.filterQuery["$or"].push(newQuery);
            console.log(
              "Filtername created and value pushed into $in array of",
              filtername
            );
          } else if (
            val.name === null ||
            val.name === undefined ||
            val.name === "others"
          ) {
            console.log(
              "Filtername created and value pushed into $in array of",
              filtername
            );
            newQuery[filtername] = { $exists: false };
            this.filterQueryFormat.filterQuery["$or"].push(newQuery);
            this.isOrExists++;
          }
        }

        // else if(this.isOrExists>=1){
        //   console.log("new 3")
        //   this.filterQueryFormat.filterQuery[filtername] = {"$exists": false};
        //           this.isOrExists+=1;
        //             let deepFilterOuery = JSON.parse(JSON.stringify(this.filterQueryFormat.filterQuery));
        //             this.filterQueryFormat.filterQuery={$or:[(deepFilterOuery)]}
        //             console.log(deepFilterOuery,"deepFilterOuery3")
        //           }

        console.log(this.isOrExists, "isOrExists while adding");
        console.log("Filter name:", filtername);
        console.log(
          "Keys in filterQuery:",
          Object.values(this.filterQueryFormat.filterQuery)
        );
      }
    },

    isCheckedInFilter(val, filtername) {
      console.log(val, "filterarray val.name1");
      console.log(filtername, "filterarray val.name2");
      console.log(val.name, "filterarray val.name3");
      // let filterArray2= this.filterQueryFormat.filterQuery["$or"][filtername];
      if ("$or" in this.filterQueryFormat.filterQuery) {
        console.log("remove $or 0");
        console.log(this.isOrExists, "isOrExists");
        console.log(filtername, "remove $or 0");
        console.log(val, "filterarray val.name1");
        console.log(filtername, "filterarray val.name2");
        console.log(val.name, "filterarray val.name3");
        const orArray = this.filterQueryFormat.filterQuery["$or"];
        let existsFalsePresent = false;
        orArray.forEach((orCondition) => {
          if (filtername in orCondition) {
            const existsFalse = orArray.some((obj) => {
              return Object.values(obj).some((value) => {
                return typeof value === "object" && value["$exists"] === false;
              });
            });
            console.log(orArray.length, "remove $or lenght");
            console.log(filtername, "filterarray val.name2");
            console.log(orCondition, "filterarray orCondition");
            let filterArray = orCondition[filtername]["$in"];
            if (
              filterArray &&
              filterArray.length > 0 &&
              (val.name !== null ||
                val.name !== undefined ||
                val.name !== "Others")
            ) {
              const indexToRemove = filterArray.indexOf(val.name);
              if (indexToRemove !== -1) {
                filterArray.splice(indexToRemove, 1);
                if (filterArray.length === 0) {
                  // If all values are removed, delete the filter condition
                  console.log("remove $or 1");
                  orArray.splice(orArray.indexOf(orCondition), 1);
                  this.isOrExists--;
                }
                if (
                  val.name === undefined ||
                  val.name === null ||
                  val.name === "Others"
                ) {
                  console.log("remove $or 4");
                  if (
                    "$exists" in orCondition[filtername] &&
                    orCondition[filtername]["$exists"] === false
                  ) {
                    // Remove the query if "$exists:false" is present
                    console.log("remove $or 5");
                    orArray.splice(orArray.indexOf(orCondition), 1);
                    existsFalsePresent = true;
                    this.isOrExists--;
                  }
                }
              }
            } else {
              console.log(this.isOrExists, "isOrExists");
              console.log("remove $or 3");
              // If val.name is undefined, null, or "Others", check for "$exists:false"
              if (
                val.name === undefined ||
                val.name === null ||
                val.name === "Others"
              ) {
                console.log("remove $or 4");
                if (
                  "$exists" in orCondition[filtername] &&
                  orCondition[filtername]["$exists"] === false
                ) {
                  // Remove the query if "$exists:false" is present
                  console.log(orArray.length, "remove $or 5 length ");
                  console.log("remove $or 5");
                  orArray.splice(orArray.indexOf(orCondition), 1);
                  existsFalsePresent = true;
                  this.isOrExists--;
                  const hasExistsFalse = orArray.some((orCondition) =>
                    Object.values(orCondition).some(
                      (value) =>
                        typeof value === "object" && value["$exists"] === false
                    )
                  );
                  if (!hasExistsFalse) {
                    console.log("remove $or exists");
                    this.filterQueryFormat.filterQuery = Object.assign(
                      {},
                      ...orArray
                    );
                    this.isOrExists = 0;
                  }
                  console.log(
                    existsFalsePresent,
                    "remove $or exists present or not"
                  );
                  console.log(orArray.length, "remove $or 5 length ");
                  //                 if (orArray.length === 0 && !existsFalsePresent) {
                  //     console.log("remove $or 6")
                  //       delete this.filterQueryFormat.filterQuery["$or"];
                  //       this.filterQueryFormat.filterQuery = Object.assign({}, ...orArray);
                  //       this.isOrExists=0;
                  // }
                }
              }
            }

            if (orArray.length === 1 && this.isOrExists == 0) {
              console.log("remove $or 6.1");
              delete this.filterQueryFormat.filterQuery["$or"];
              this.filterQueryFormat.filterQuery = Object.assign(
                {},
                ...orArray
              );
              this.isOrExists = 0;
            }
            if (orArray.length === 0) {
              console.log("remove $or 6.2");
              delete this.filterQueryFormat.filterQuery["$or"];
              this.isOrExists = 0;
              // this.filterQueryFormat.filterQuery;
            }
            if (!existsFalse) {
              console.log("remove $or 6.3");
              delete this.filterQueryFormat.filterQuery["$or"];
              this.filterQueryFormat.filterQuery = Object.assign(
                {},
                ...orArray
              );
              this.isOrExists = 0;
            }
          }
        });

        // If all filter conditions under $or are removed, delete $or
        // if (orArray.length === 0 && !existsFalsePresent) {
        //   console.log("remove $or 6")
        //     delete this.filterQueryFormat.filterQuery["$or"];
        //     this.filterQueryFormat.filterQuery = Object.assign({}, ...orArray);
        // }
      } else if (filtername in this.filterQueryFormat.filterQuery) {
        // console.log(this.isOrExists,"isOrExists")
        let filterArray = this.filterQueryFormat.filterQuery[filtername]["$in"];

        // console.log(filterArray, " filteraray");

        // console.log("Index in fqf :", filterArray.indexOf(val.value.value));

        //  filterArray=filterArray.filter(item=>item!==val.value.value)
        //  console.log("NEW filter array :",filterArray);

        // const indexToRemove = filterArray.indexOf(val.value.value);

        if (
          filterArray &&
          filterArray.length > 0 &&
          (val.name !== undefined || val.name !== "Others")
        ) {
          const indexToRemove = filterArray.indexOf(val.name);
          console.log(indexToRemove);
          if (indexToRemove !== -1) {
            filterArray.splice(indexToRemove, 1);
            if (filterArray.length === 0) {
              delete this.filterQueryFormat.filterQuery[filtername];
            }
          }
        }

        // console.log("i am filtequery ", val.value.value);
        // console.log(this.isOrExists,"isOrExists ending")
        // console.log("i am filtequery", this.filterQueryFormat);
      } else {
        console.log("nothing from isCheckedFilter that is removing ");
      }
    },

    isThisChecked(value, filtername, filterkeyname) {
      const updatedValue = { ...value, filterkeyname, filtername };
      // console.log("Event value in ischecked",updatedValue,this.labelValues.includes(value))
      // return this.labelValues.includes(updatedValue);
      return this.labelValues.some((label) =>
        this.areObjectsEqual(label, updatedValue)
      );
    },
    areObjectsEqual(obj1, obj2) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      // console.log("keys 1",keys1)
      // console.log("keys 2",keys2)
      if (keys1.length !== keys2.length) {
        return false;
      }

      for (const key of keys1) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }

      return true;
    },
    viewDropDown(e) {
      this.isPlaceholder=false
      console.log("viewDropDown", e);
      this.isActiveFilter = false;
      this.isFilter = false;
      this.isTag = false;
      this.selectoptioncontainer = true;
      // console.log("from view drop down");
      if (this.isSearch) {
        console.log("from view drop down if");
        // this.$refs.inputBox.blur();
        this.isTag = this.checkedByUsers.length > 0;
        return (this.isSearch = false);
      }
      // this.isSearch = !this.isSearch;
      if (!this.isSearch && !this.isPlaceholder) {
        this.containerleft = document
          .querySelector("#search-and-filter2" + this.unique)
          .getBoundingClientRect().left -36;
        this.containertop = document
          .querySelector("#search-and-filter2" + this.unique)
          .getBoundingClientRect().top;
        // this.containerwidth = document
        //   .querySelector("#search-and-filter" + this.unique)
        //   .getBoundingClientRect().width;
        console.log("from view drop down if !!!!");
        this.isSearch = true;
        this.checkArray();
        document.addEventListener("click", this.isSearchOutside);
      }
      if (!this.isSearch) {
        this.containerleft = document
          .querySelector("#search-and-filter" + this.unique)
          .getBoundingClientRect().left -36;
        this.containertop = document
          .querySelector("#search-and-filter" + this.unique)
          .getBoundingClientRect().top;
        // this.containerwidth = document
        //   .querySelector("#search-and-filter" + this.unique)
        //   .getBoundingClientRect().width;
        console.log("from view drop down if !!!!");
        this.isSearch = true;
        this.checkArray();
        document.addEventListener("click", this.isSearchOutside);
      }
    },
    updateChecked(arr1, arr2) {
      const checkedIds = new Set(arr1.map((obj) => obj._id));

      arr2.forEach((obj2) => {
        if (checkedIds.has(obj2._id)) {
          obj2.checked = true;
        } else {
          obj2.checked = false;
        }
      });

      return arr2;
    },
    checkArray() {
      // console.log("received1 :",this.receivedOptions)
      // console.log("checkedByMe1 :",this.checkedByUsers)

      // this.receivedOptions.forEach(option => {
      //   if (this.checkedByUsers.some(checkedName => checkedName === option.name)) {
      //       option.checked = true;
      //   } else {
      //       option.checked = false;
      //   }
      // });

      // let x = this.checkedByUsers.forEach(obj1 => {
      //     const matchingObj = this.receivedOptions.find(obj2 => obj2._id === obj1._id);
      //     if (matchingObj) {
      //         matchingObj.checked = obj1.checked;
      //     }
      // });
      const x = this.updateChecked(this.checkedByUsers, this.receivedOptions);
      this.changeNum += 1;
      this.receivedOptions = JSON.parse(JSON.stringify(x));
      // console.log('new arr',x);

      //  console.log("received2 :",this.receivedOptions)
      //   console.log("checkedByMe2 :",this.checkedByUsers)
    },
    searchedCheckBox(searchedValue) {
      console.log("I am searchedValaue :", searchedValue.trim().length > 0);

      if (searchedValue.length > 0) {
        if (this.queryType.includes("search")) {
          this.queryType;
        } else {
          this.queryType.push("search");
        }
      }
      if (searchedValue.length <= 0) {
        if (this.queryType.includes("search")) {
          this.queryType = this.queryType.filter((item) => item !== "search");
          console.log("This all removed from ", this.queryType);
          this.queryType;
        }
      }
      // console.log("Querytype ", this.queryType);

      this.filterQueryFormat.searchQuery = searchedValue;

      // console.log(this.filterQueryFormat);
      this.$emit("searched-value", this.filterQueryFormat);
      return this.filterQueryFormat;
    },
    checked(value) {
      //  this.receivedOptions=value;
        // console.log("Checked :",value);
      console.log("Checked value top :", value);
      //   this.checkedByUsers = [];
      value.forEach((e) => {
        if (e.name) {
          console.log("Name :", e.name);
        }
      });

      value.forEach((element) => {
        console.log("hi");
        console.log(element, "element");
        console.log(value, "element value");
        if (
          element.checked === true &&
          !this.checkedByUsers.some((e) => e.name === element.name)
        ) {
          this.checkedByUsers.push(element);
        } else if (
          element.checked === false &&
          this.checkedByUsers.some((e) => e.name === element.name)
        ) {
          //   let index = this.checkedByUsers.findIndex(e => e.name === element.name);
          //   this.checkedByUsers.splice(index, 1);
          this.checkedByUsers = this.checkedByUsers.filter(
            (e) => e.name !== element.name
          );
          console.log("I am unchecked ", element);
          this.$emit("removed-value",element)
        }
        // this.$emit("checked-value", this.checkedByUsers);
        console.log("I am checked :", this.checkedByUsers);
      });
    },
    showFilter() {
      console.log("From showFilter");
      if (this.isFilter && this.isActiveFilter) {
        console.log("From showFilter 1");
        this.isFilter = false;
        return (this.isActiveFilter = false);
      }
      if (this.isActiveFilter) {
        console.log("From showFilter 2");
        this.isActiveFilter = false;
      }
      this.isTag = this.checkedByUsers.length > 0;
      this.isFilter = this.isActiveFilter;

      if (!this.isFilter) {
        console.log("From showFilter 3");
        this.containerleftFilter = document
          .querySelector("#filter-icon" + this.unique)
          .getBoundingClientRect().left -200;
        this.containertopFilter = document
          .querySelector("#filter-icon" + this.unique)
          .getBoundingClientRect().top-8;
        this.isFilter = true;
        this.isActiveFilter = true;
        this.isSearch = false;
        document.addEventListener("click", this.isFilterOutside);
      }
    },
    closeDropDown() {
      this.isSearch = false;
      //   this.isTag=true
      this.isTag = this.checkedByUsers.length > 0;
      this.$refs.inputBox.blur();
    },

    isSearchOutside(event) {
      console.log("isSearchOutside viewDropDown", event);
      console.log("isSearchOutside viewDropDown", event.target);
      console.log("isSearchOutside viewDropDown", event.target);
      // In this function used refs and contains
      //   if (!this.$refs.dropdown || !this.$refs.inputBox) return;
      if (!this.$refs.dropdown) return;
      // if (!this.$refs.tags) return;
      if (
        !this.$refs.dropdown.contains(event.target) &&
        // !this.$refs.tags.contains(event.target) &&
        !this.$refs.activefilter.contains(event.target) &&
        event.target !== this.$refs.inputBox &&
        event.target !== "span.content" &&
        event.target !== this.$refs.placeholder 
      ) {
        const isClickOnTag =
          event.target.classList.contains("active-filter-tag");
        const isClickOnCloseIcon =
          event.target.classList.contains("icon-times-regular");
        console.log("isSearchOutside 1st if", event.target);
        if (!isClickOnTag && !isClickOnCloseIcon) {
          this.isSearch = false;
          //   this.isTag=true
          console.log("isSearchOutside 2st if", event.target);
          this.isTag = this.checkedByUsers.length > 0;
          document.removeEventListener("click", this.isSearchOutside);

        }
        if(this.checkedByUsers.length <=0){
          this.isPlaceholder=true
        }
      }
    },
    isFilterOutside(event) {
      // Check if the click event is outside of the filter area
      if (
        this.$refs.filter &&
        !this.$refs.filter.contains(event.target) &&
        !event.target.classList.contains("filter-logo")
      ) {
        // Toggle off the filter if clicked outside
        this.isFilter = false;
        this.isActiveFilter = false;
        // this.isTag=true
        this.isTag = this.checkedByUsers.length > 0;
        // Remove the event listener once the filter is closed
        document.removeEventListener("click", this.isFilterOutside);
      }
    },
  },
};
</script>
