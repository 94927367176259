<template>
    <div style="display:flex; margin: 20px; flex-direction: column;">
        <div style="margin-bottom: 10px"><b>Billing</b></div>
        <span style="font-size: 12px;">Easily track your invoices, payment history, and current billing status.</span>
        <placeholderTableComponent :module="'Billing2'"></placeholderTableComponent>
    </div>
</template>

<script>
import placeholderTableComponent from './placeholderTableComponent.vue';

export default {
 components: {
    placeholderTableComponent
 }
}
</script>