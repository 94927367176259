<template>
  <!-- {{ newConPayload }} -->
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-alert-notification
    :show-alert="alertmsg"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-drawer
    :show="show_right_filter"
    :width="300"
    position="right"
    :header="false"
    :footer="false"
    :show-close-icon="true"
    @close="closeRightFilter"
  >
    <template #body>
      <div style="display: flex">
        <FilterComponent
          :label-value=filterLang
          :filterpanelname="'contacts'"
          @filtered-data="accountsFilter"
        ></FilterComponent>
      </div>
    </template>
  </hlx-drawer>
  <hlx-modal
    :modal-active="showAddNewContactModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'570px'"
    :width="'800px'"
    :modal="true"
    @close="showAddNewContactModal = false"
  >
    <template #header>
      <div>
        <!-- Add contact -->
        <span v-if="staticData">{{ staticData?.addContact?.label[loginUserDetails]?.label }}</span>
      </div>
    </template>
    <template #content>
      <div class="modal-content" style="display: flex; gap: 5%">
        <!--left side-->
        <div
          style="
            display: flex;
            flex-direction: column;
            /* justify-content: space-between; */
            width: 50%;
          "
        >
          <div style="display: flex; justify-content: space-between; gap: 10px">
            <!-- <span
              style="
                height: 93px;
                display: flex;
                width: 93px;
                align-items: center;
                justify-content: center;
                min-width: 93px;
                border: 1px solid #d8d8d8;
                border-radius: 5px;
                font-size: 70px;
                color: #d8d8d8;
              "
            >
              <i class="icon-buildings-alt-regular"></i>
            </span> -->
            <hlx-file-upload
              :file-type="'.jpg,.jpeg,.png'"
              :file-size-limit="'20mb'"
              :uploadtype="'Rectangle'"
              :style-background="backgrounStyle"
              :height="100"
              :width="100"
              :read-only="false"
              :custom-icon="icon"
              :type="'profile-upload'"
              :name-display="newConPayload.name"
              :content="'Import'"
              @native-data="profileUpload"
            />
            <span style="width: 200%">
              <div class="decision-name-wrapper" style="margin-bottom: 20px">
                <!--first name-->
                <hlx-input
                  :key="componentkey"
                  v-model:value="newConPayload.firstName"
                  :pre-val="newConPayload.firstName"
                  :label-value="'First name'"
                  :label-animation="true"
                  :required="true"
                  :display-error="customnameerror"
                  :custom-error="customnameerror"
                  :custom-error-message="'Please fill the field'"
                  @focus-out="focusOutName"
                  @at-input="validateName"
                ></hlx-input>
              </div>

              <!--Last name-->
              <div class="decision-name-wrapper" style="margin-bottom: 20px">
                <hlx-input
                  :key="componentkey"
                  v-model:value="newConPayload.lastName"
                  :pre-val="newConPayload.lastName"
                  :label-value="'Last name'"
                  :label-animation="true"
                  :required="false"
                  :display-error="customnameerror"
                  :custom-error="customnameerror"
                  :custom-error-message="'Please fill the field'"
                  @focus-out="focusOutName"
                  @at-input="validateName"
                ></hlx-input>
              </div>
            </span>
          </div>

          <!--Address-->
          <addressComponent
            :key="addressKey"
            @address-data="receivedAddressData"
          ></addressComponent>

          <!--status-->
          <div class="decision-name-wrapper" style="margin-top: 20px">
            <!-- <hlx-select
              v-model="newConPayload.status"
              :pre-value="newConPayload.status"
              :options="statusList"
              prop-value="name"
              :inline-search="true"
              :search-image-tag="false"
              :placeholder-value="'Status'"
              :label-animation="true"
              :clearable="true"
              @search-value="newValFromDropDown($event, 'status')"
            ></hlx-select>  -->

            <div style="width: 100%">
              <hlx-status
                :type="'select-status'"
                :status-option="statusList"
                :prevalue="newConPayload.status"
                @emit-select="emitStatus1"
              >
              </hlx-status>
            </div>
          </div>
        </div>

        <!--right side-->
        <div
          style="
            display: flex;
            flex-direction: column;
            /* gap: 20px; */
            /* justify-content: space-between; */
            width: 50%;
          "
        >
          <!--organistion-->
          <!-- <div class="decision-name-wrapper" style="margin-bottom: 20px">
        <hlx-select
            :key="addressKey"
            v-model="selectedHierarchyAccount"
            :options="organizationList"
             :prop-value="'name'"
             :label="'name'"
            :required="false"
            :inline-search="true"
            :placeholder-value="'Choose an organisation'"
            :label-animation="true"
            :clearable="true"
            :footer="false"
            @selected-value="selectedHierarchyAccountDetails"
          ></hlx-select>
          </div> -->

          <!--Role-->
          <div class="decision-name-wrapper" style="margin-bottom: 20px">
            <hlx-select
              :key="addressKey"
              v-model:value="newConPayload.role"
              :pre-value="newConPayload.role"
              :options="designationList"
              :inline-search="true"
              :required="true"
              :prop-value="'name'"
              :label="'name'"
              :placeholder-value="'Designation'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>

          <!---prefered medium-->
          <div class="decision-name-wrapper" style="margin-bottom: 20px">
            <hlx-select
              :key="componentkey"
              v-model:value="newConPayload.preferred"
              :pre-value="newConPayload.preferred"
              :options="preferredMediumList"
              :inline-search="true"
              prop-value="value"
              :placeholder-value="'Preferred contact medium'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>

          <!--email-->
          <div class="decision-name-wrapper">
            <hlx-input
              :key="componentkey"
              v-model:value="
                newConPayload.contactMedium[0].characteristic.emailAddress
              "
              :pre-val="
                newConPayload.contactMedium[0].characteristic.emailAddress
              "
              type="email"
              :label-value="'Email id'"
              :required="true"
              :label-animation="true"
              :display-error="true"
              :custom-error="isExistingEmail"
              :custom-error-message="isExistingEmailErrorMessage"
              @focus-out="validateEmailNew"
              @at-input="validateName"
            ></hlx-input>
          </div>

          <!--Phone number-->
          <div class="decision-name-wrapper">
            <hlx-input
              :key="componentkey"
              v-model:value="
                newConPayload.contactMedium[1].characteristic.phoneNumber
              "
              :pre-val="
                newConPayload.contactMedium[1].characteristic.phoneNumber.value
              "
              type="phonenumber"
              :country-code="
                newConPayload.contactMedium[1].characteristic.phoneNumber
                  .country
              "
              :label-value="'Phone no.'"
              :label-animation="true"
              :display-error="true"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>

          <!--Linked id-->
          <div class="decision-name-wrapper" style="margin-bottom: 20px">
            <hlx-input
              :key="componentkey"
              v-model:value="newConPayload.externalReference[0].name"
              :pre-val="newConPayload.externalReference[0].name"
              :label-value="'Linkedin id'"
              :label-animation="true"
              :required="false"
              :display-error="customnameerror"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>

          <!--Tags-->
          <div class="decision-name-wrapper">
            <hlx-multi-select
              :key="componentkey"
              v-model="newConPayload.tags"
              :pre-value="newConPayload.tags"
              :options="tagsList"
              prop-value="name"
              :multi-select="true"
              :placeholder="'Tags'"
              :label-animation="true"
              :clearable="true"
              :tooltipposition="'left'"
              @add-data="addTagList"
              @selected-array="receivedTags"
            ></hlx-multi-select>
          </div>

          <!--Notes-->
          <!-- <div class="decision-name-wrapper">
          <hlx-input
          v-model:value="newConPayload.notes"
          :label-animation="true"
          label-value="Notes"
          type="textarea"
          :clearable="true"
        />
        </div> -->
        </div>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="closeAddNewContactModal"
        >
        <!-- Cancel -->
        <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
      <hlx-button
        :disabled="!canAdd"
        class="primary sm"
        style="margin-left: 10px"
        @click="addNewContact"
        >
        <!-- Add -->
        <span v-if="staticData">{{ staticData?.add?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
    </template>
  </hlx-modal>

  <section class="cm-accounts-landing-layout">
    <section class="cm-accounts-right-panel-container">
      <div class="cm-accounts-right-panel-top-section">
        <div class="cm-accounts-right-panel-top-section-title">
          <!-- Residential customers -->
          <span v-if="staticData">{{ staticData?.cmResedentialCustomerTitle?.label[loginUserDetails]?.label }}</span>
        </div>
        <div class="cm-accounts-right-panel-top-section-description">
          <!-- Streamline your business relationships with straight forward contact
          management. -->
          <span v-if="staticData">{{ staticData?.cmResedentialCustomerSubtitle?.label[loginUserDetails]?.label }}</span>
        </div>
        <div class="cm-accounts-right-panel-top-section-utility-container">
          <span class="search-container">
            <hlx-search 
            :label-value=staticData?.search?.label[loginUserDetails]?.label
            @search-key="searchKey"></hlx-search>
          </span>
          <span class="filter-table">
            <hlx-button class="secondary sm add-btn" @click="showDrawerRight">
              <i class="icon-filter-regular"></i>
              <span v-if="filtertaglength != 0" class="filter-count">{{
                filtertaglength
              }}</span>
            </hlx-button>
          </span>
          <hlx-button v-if="permisstionData.add " class="primary sm" @click="addNewAccount">
            <i class="icon-plus-circle-regular"></i>
             <!-- New contact -->
             <span v-if="staticData">{{ staticData?.newContact?.label[loginUserDetails]?.label }}</span>
          </hlx-button>
        </div>
        <div
          v-if="tableData.length > 0"
          class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
        >
          <hlx-table
            :column-count="accounts_table_theads.length + 2"
            :border="['table', 'header', 'horizontal']"
            :row-hover="true"
            theme="grey"
            :re-arrange-columns="toggleRearrangeModal"
            :re-arrange-heads="accounts_table_theads_cc"
            @close-rearrange="toggleRearrangeModal = false"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
              <!-- <hlx-table-head
                :width="60"
                :align="'center'"
                style="padding: 0 15px"
                :fixed="'left'"
                >{{ "S.no" }}</hlx-table-head
              > -->
              <hlx-table-head
                v-for="(i, index) in accounts_table_theads"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
                  @new-resize-width="resizedWidth"
              >
                {{ i.label }}</hlx-table-head
              >
              <hlx-table-head
              v-if="permisstionData.edit || permisstionData.delete"
                v-show="editMode == true"
                :align="'center'"
                :width="70"
                :fixed="'right'"
                  @new-resize-width="resizedWidth"
                ><span class="action-icon">
                  <i
                    class="icon-settings-regular"
                    @click="reAssignOrgTableHeader()"
                  ></i> </span
              ></hlx-table-head>
            </template>
            <template #tbody>
              <tr v-for="(i, index) in paginatedData" id="" :key="index">
                <!-- <hlx-table-cell
                  :align="'center'"
                  :fixed="'left'"
                >
                  {{ serialNumber(index) }}
                </hlx-table-cell> -->
                <hlx-table-cell
                  v-for="(j, col_index) in accounts_table_theads"
                  :key="col_index"
                  :align="j.align"
                  :fixed="j.fixed"
                >
                  <div
                    v-if="
                      j.prop.toLowerCase() === 'address' && i['contactMedium']
                    "
                    :id="j.prop + index"
                    style="
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    :style="
                      j.width === null
                        ? {
                            width: '250px',
                          }
                        : ''
                    "
                    @mouseover="
                      tooltipover(
                        j.prop + index,
                        'tooltip-text' + j.prop + index
                      )
                    "
                    @mouseleave="
                      tooltipleave(
                        j.prop + index,
                        'tooltip-text' + j.prop + index
                      )
                    "
                  >
                    <span v-if="i['contactMedium'][2]">
                      <span
                        v-if="
                          i['contactMedium'][2].characteristic?.description !=
                          ''
                        "
                      >
                        {{ i["contactMedium"][2].characteristic?.description }}
                        <p
                          id="tooltip-text"
                          :class="'tooltip-text' + j.prop + index"
                        >
                          {{
                            i["contactMedium"][2].characteristic?.description
                          }}
                        </p>
                      </span>
                      <span v-else>
                        {{ "-" }}
                      </span>
                    </span>
                  </div>
                  <div
                    v-else-if="j.prop.toLowerCase() === 'name'"
                    @click="
                      $router.push(
                        '/CM/residential-customers/view/' +
                          i[j.prop] +
                          '/' +
                          i['_id'] +
                          '/view/contacts?company=' +
                          i['company']
                      )
                    "
                  >
                    <span class="link-name">
                      {{i['firstName'] + ' ' + i['lastName'] }}
                    </span>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'lastupdated'">
                    <span>
                      {{ dateAndTimeFormatter(i["lastUpdate"]) }}
                    </span>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'status'">
                    <span v-if="i[j.prop] != ''">
                      <div>
                        <hlx-status :type="i[j.prop]"></hlx-status>
                      </div>
                    </span>
                    <span v-else>
                      {{ "-" }}
                    </span>
                  </div>
                  <div
                    v-else-if="
                      j.prop.toLowerCase() === 'contactmedium' &&
                      i['contactMedium']
                    "
                    style="margin: 0.5rem 0"
                  >
                    <div
                      v-if="i['contactMedium'][0]"
                      style="
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        margin-bottom: 4px;
                      "
                    >
                      <i class="icon-envelope-regular"></i>
                      <span>
                        {{ i["contactMedium"][0].characteristic?.emailAddress }}
                      </span>
                    </div>
                    <div
                      v-if="
                        i['contactMedium'][1] && i['contactMedium'][1] != ''
                      "
                      style="display: flex; align-items: center; gap: 0.5rem"
                    >
                      <i
                        v-if="
                          i['contactMedium'][1].characteristic?.phoneNumber
                            .value
                        "
                        class="icon-phone-regular"
                      ></i>
                      <span>
                        {{
                          i["contactMedium"][1].characteristic?.phoneNumber
                            .value
                        }}
                      </span>
                    </div>
                  </div>
                  <div v-else>{{ i[j.prop] }}</div>
                </hlx-table-cell>
                <hlx-table-cell
                v-if="permisstionData.edit || permisstionData.delete"
                  v-show="editMode === true"
                  :align="'center'"
                  :width="120"
                  :fixed="'right'"
                >
                  <span :id="'table-context' + index" class="action-icon">
                    <i
                      style="position: relative"
                      class="icon-more-vertical-filled"
                      :class="i.context === true ? 'active-action' : ''"
                      @click="crudContextMenu($event, index)"
                    >
                      <hlx-context-menu
                        :top="containertop"
                        :left="containerleft - 20"
                        :conditionvalue="150"
                        :conditiondown="2"
                        :conditionup="-38"
                        :options="computedTableAction(i)"
                        :data="index.toString()"
                        :show="i.context == true"
                        @chosen="closeCrudContextMenu($event, i, index)"
                      />
                    </i>
                  </span>
                </hlx-table-cell>
              </tr>
            </template>
          </hlx-table>
        </div>
        <div
          v-if="tableData.length === 0"
          class="cm-accounts-right-panel-top-section-table-wrap"
        >
          <hlx-table
            :column-count="accounts_table_theads.length + 2"
            :border="['table', 'header']"
            :bold-headers="false"
            :row-hover="true"
            theme="grey"
            :striped-rows="false"
            :re-arrange-columns="toggleRearrangeModal"
            :re-arrange-heads="accounts_table_theads_cc"
            @close-rearrange="toggleRearrangeModal = false"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
              <hlx-table-head :width="60" :align="'left'"   @new-resize-width="resizedWidth">{{
                "S.no"
              }}</hlx-table-head>
              <hlx-table-head
                v-for="(i, index) in accounts_table_theads"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                style="position: sticky; top: 0px"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
                  @new-resize-width="resizedWidth"
              >
                {{ i.label }}
              </hlx-table-head>
              <hlx-table-head v-if="permisstionData.edit || permisstionData.delete" :width="100"   @new-resize-width="resizedWidth">
                <span class="action-icon"
                  ><i
                    class="icon-settings-regular"
                    @click="arrange(true)"
                  ></i></span
              ></hlx-table-head>
            </template>
            <template #tbody>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell :colspan="accounts_table_theads.length + 2">{{
                  "No data"
                }}</hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
            </template>
          </hlx-table>
        </div>
      </div>
      <div class="cm-accounts-right-panel-pagination-container">
        <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
          :label-value=paginationLang
          :total="totalpageno"
          rows-per-page
          enable-rows-per-page
          :rows-per-page-list="[10, 15, 20]"
          :options="{
            attributes: {
              rowsPerPageTitle: true,
              rowsPerPageResult: true,
              rowsPerPageDropdown: {
                position: {
                  top: 0,
                  right: 1,
                },
              },
            },
          }"
          @current-page="currentPage"
          @updated:rows-per-page="changeRowsPerPage"
        ></hlx-pagination>
      </div>
    </section>
  </section>
</template>

<script>
import {
  MLTAxiosInstance,
  DMNAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
import addressComponent from "../../../components/CM/autocompleteComponent.vue";
import FilterComponent from "../../../components/filterComponent.vue";
// import { mapGetters } from "vuex";

export default {
  components: {
    FilterComponent,
    addressComponent,
  },
  data() {
    return {
      permisstionData: {},

      loginUserDetails: {},
      staticData:null,
     tableHeaderOrg:[],
      tableHeaderOrgcc:[],
       isExistingName: false,
      isExistingNameErrorMessage: '',
      isExistingEmailErrorMessage: '',
      isExistingEmail: false,
      refreshSelect: 0,
      notification: {},
      tagsList: [],
      alertmsg: "",
      totalpageno: 0,
      show_right_filter: false,
      filtertaglength: 0,
      componentkey: 0,
      rolee: "",
      aristocraticTitle:"",
      canAdd: false,
      searchvalue: "",
      filtertagvalue: [],
      company: "",
      accountsquery: {
        type: "filter",
        module: "contacts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {
          "@baseType": "residential-customer",
        },
        searchQuery: "",
        company: "",
      },
      designationList: [],
      statusList: [],
      preferredMediumList: [],
      addressKey: 0,
      selectedHierarchyAccount: "",
      showAddNewContactModal: false,
      newConPayload: {
        name: "",
        birthDate: "",
        firstName: "",
        lastName: "",
        title: "",
        tradingName: "",
        companyName: "",
        status: "Active",
        preferred: "",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                valid: true,
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              timezone: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        relatedParty: [],
        tags: [],
        role: "",
        aristocraticTitle:"",
        type: "individual",
        "@baseType": "residential-customer",
        notes: [],
        attachment: [],
        password: "",
        email: "",
      },
      newAccPayload: {
        name: "",
        tradingName: "",
        status: "",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            emailAddress: "",
          },
          {
            mediumType: "phone",
            phoneNumber: {
              value: "",
              valid: true,
              country: {
                name: this.$store.getters.defaultSetting?.country,
                short: this.$store.getters.defaultSetting?.cca2,
                flags: this.$store.getters.defaultSetting?.flags?.svg,
              },
            },
          },
          {
            mediumType: "fax",
            faxNumber: "",
          },
          {
            mediumType: "postalAddress",
            city: "",
            country: "",
            postCode: "",
            stateOrProvince: "",
            street1: "",
            street2: "",
            description: "",
            "@type": "",
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        relatedParty: [
          {
            role: "director",
            partyOrPartyRole: {
              id: "42",
              name: "Feroz Khan",
              "@referredType": "Individual",
            },
          },
        ],
        tags: ["Active"],
        aristocraticTitle:"",
        role: "Root admin",
        type: "company",
        password: "",
        email: "",
        organizationParentRelationship: {},
        rootName: "",
      },
      externalRowsPerPage: 10,
      tableData: [],
      // tableData: [
      //   {
      //     contactId: "H511",
      //     name: "Feroz khan",
      //     accountName: "Hitachi enterprises",
      //     status: "Active",
      //     contactMedium: {
      //       email: "ferozkhanryn@gmail.com",
      //       ph: "6383157595",
      //     },
      //     role: "Marketting manager",
      //     lastLogIn: "17 Dec, 2023",
      //   },
      //   {
      //     contactId: "H511",
      //     name: "Feroz khan",
      //     accountName: "Hitachi enterprises",
      //     status: "Active",
      //     contactMedium: {
      //       email: "ferozkhanryn@gmail.com",
      //       ph: "6383157595",
      //     },
      //     role: "Marketting manager",
      //     lastLogIn: "17 Dec, 2023",
      //   },
      // ],
      accounts_table_theads: [
        {
          name:"ID",
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: 100,
          label: "ID",
          prop: "displayID",
          align: "left",
        },
        {
          name:"Name",
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: 200,
          label: "Name",
          prop: "name",
          align: "left",
        },
        {
          name:"Address",
          checked:true,
          disabled:true,
          sortable: false,
          resizable: true,
          width: null,
          label: "Address",
          prop: "address",
          align: "left",
        },
        {
          name:"Status",
          checked:true,
          sortable: false,
          resizable: true,
          width: 200,
          label: "Status",
          prop: "status",
          align: "left",
        },
        {
          name:"Contact medium",
          checked:true,
          sortable: false,
          resizable: true,
          width: 250,
          label: "Contact medium",
          prop: "contactMedium",
          align: "left",
        },
        {
          name:"Last updated",
          checked:true,
          sortable: false,
          resizable: true,
          width: 200,
          label: "Last updated",
          prop: "lastUpdated",
          align: "left",
        },
      ],
      accounts_table_theads_cc: [
      {
          name:"ID",
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: 100,
          label: "ID",
          prop: "displayID",
          align: "left",
        },
        {
          name:"Name",
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: 200,
          label: "Name",
          prop: "name",
          align: "left",
        },
        {
          name:"Address",
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: null,
          label: "Address",
          prop: "address",
          align: "left",
        },
        {
          name:"Status",
          checked:true,
          sortable: false,
          resizable: true,
          width: 200,
          label: "Status",
          prop: "status",
          align: "left",
        },
        {
          name:"Contact medium",
          checked:true,
          sortable: false,
          resizable: true,
          width: 250,
          label: "Contact medium",
          prop: "contactMedium",
          align: "left",
        },
        {
          name:"Last updated",
          checked:true,
          sortable: false,
          resizable: true,
          width: 200,
          label: "Last updated",
          prop: "lastUpdated",
          align: "left",
        },
      ],
      duplicateTheads: [],
      toggleRearrangeModal: false,
      currPage: 1,
      rowsPerPage: 10,
      editMode: true,
      filterPanelName: "productSpecification",
      organizationList: [],
    };
  },
  computed: {
    filterLang() { 
      if (this.staticData) {      
      return {
          Filters : this.staticData?.comFilters?.label[this.loginUserDetails]?.label,
          Filters_Applied: this.staticData?.comFiltersApplied?.label[this.loginUserDetails]?.label,
          Clear_All: this.staticData?.comClearAll?.label[this.loginUserDetails]?.label,
          selected: this.staticData?.comSelected?.label[this.loginUserDetails]?.label         
        }
      } else {
        return {
          Filters : "Filters",
          Filters_Applied: "Filters Applied",
          Clear_All: "Clear All",
          selected: "selected",
         
        }
      }
    },
    
    paginationLang() { 
      if (this.staticData) {      
      return {
          Rows_per_page : this.staticData?.comRowPerPage?.label[this.loginUserDetails]?.label,
          Showing: this.staticData?.comShowing?.label[this.loginUserDetails]?.label,
          of: this.staticData?.comOf?.label[this.loginUserDetails]?.label,
          results: this.staticData?.comResult?.label[this.loginUserDetails]?.label,
          Go_to: this.staticData?.comGoTo?.label[this.loginUserDetails]?.label,
        }
      } else {
        return {
          Rows_per_page : "Rows per page",
          Showing: "Showing",
          of: "of",
          results: "results",
          Go_to: "Go to",
        }
      }
    },
    
    paginatedData() {
      return this.tableData;
    },
  },
  watch: {
    "$store.getters.defaultSetting": {
      handler(val) {
        this.newConPayload.contactMedium[1].characteristic.phoneNumber.country.name
        = val?.country
        this.newConPayload.contactMedium[1].characteristic.phoneNumber.country.short
        = val?.cc2
        this.newConPayload.contactMedium[1].characteristic.phoneNumber.country.flags
        = val?.flags?.svg
        console.log(val, 'defaultSetting');
      },
      immediate: true,
      deep:true
    },
    selectedHierarchyAccount: {
      handler(v) {
        this.selectedHierarchyContactFunc(v);
      },
      immediate: true,
      deep: true,
    },
    newConPayload: {
      handler(val) {
        console.log(val.contactMedium[0].characteristic.emailAddress, "pop");
        val["@baseType"] = "residential-customer";
        let name = val.firstName + " " + val.lastName;
        val["familyName"] = name;
        val["givenName"] = name;
        val["name"] = name;

        // val.companyName = val.name;
        // val.tradingName = val.name;
        val.email = val.contactMedium[0].characteristic.emailAddress;
        val.parentCompany = this.company;
        // val.rootName = this.company;
        // val.contactMedium[3].characteristic['@type'] = this.locationType
        // console.log('val before watch', this.executivesList);
        // let accExec = this.executivesList.filter((item) => {
        //   return item.name === val.accountExecutive ? item : undefined;
        // });
        // val.relatedParty.splice(0, 1);
        // val.relatedParty.push(accExec[0]);
        // console.log('val after watch', val.relatedParty);
        for (let i = 0; i < val.contactMedium.length; i++) {
          if (val.contactMedium[i].mediumType === val.preferred) {
            val.contactMedium[i].preferred = true;
          } else {
            val.contactMedium[i].preferred = false;
          }
        }
        if (
          //check the mandatory field
          val.firstName != "" &&
          this.validateEmail(
            val.contactMedium[0].characteristic.emailAddress
          ) &&
          val.role != ""  &&
          this.validatePhoneNumber(val.contactMedium[1].characteristic.phoneNumber)
        ) {
          this.canAdd = true;
        } else {
          this.canAdd = false;
        }
        console.log(val, "payload");
        // val.relatedParty.filter((item) => {
        //   if (item.role === 'Account executive') {
        //     this.accExecParty = item.name;
        //   }
        // });
        // console.log(this.payload);
      },
      immediate: true,
      deep: true,
    },
    tableData: {
      handler() {},
      immediate: true,
      deep: true,
    },
  },
  async mounted() {

    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");

let sampleTheadsd = await MLTAxiosInstance.get(
  `partyRole/get/${val.data.userId}`
);

const mergedPayload = sampleTheadsd.data.permission;

console.log(mergedPayload,"mergedPayloadmergedPayload");
 
this.permisstionData =
  mergedPayload.CM.child["Residential Customers"];
    await this.$store.dispatch("loginTenantDetails");
    await this.$store.dispatch("loginUserDetails");
    // loginUserDetails
    // await this.$store.dispatch("loginTenantDetails");
    // let r = await this.$store.getters.loginTenantDetails;
    console.log("loginTenantDetails resrfsef:", this.$store.getters.loginTenantDetails);
    console.log("loginUserDetails resrfsef:", this.$store.getters.loginUserDetails);

    this.loginUserDetails = this.$store?.getters?.loginUserDetails?.languageAbility[0]?.code;

    // this.loginUserDetails.languageAbility[0].name
    // this.loginUserDetails.languageAbility[0].code

    try {
      let organisationInfo = await UPCAxiosInstance.get('/multi-language');
      this.staticData = organisationInfo?.data?.filter(itm => itm && itm.modules)?.[0]?.modules;
      console.log("this.staticData:",organisationInfo , this.staticData);
      
    }
    catch (error) {
      console.error("error::", error)
    }



    // Get table header
    let contactTableHeads = await MLTAxiosInstance.get(`/settings`);

if (contactTableHeads?.data?.entityRef?.["accountResidentialCustomers"]) {
  let accountTableHeadModal = contactTableHeads.data.entityRef["accountResidentialCustomers"];
  let accountTableHeadsOrg = contactTableHeads.data.entityRef["accountResidentialCustomers"].filter(ele => ele.checked);
  console.log(accountTableHeadsOrg,"accountTableHeadModal",accountTableHeadModal)

  if (
    accountTableHeadModal.length === this.accounts_table_theads_cc.length 
// &&
//     accountTableHeadModal.every((item, index) => item.name === this.accounts_table_theads_cc[index].name)
  ) {
    console.log(accountTableHeadsOrg,"accountTableHeadModal dup",accountTableHeadModal)
    this.accounts_table_theads_cc = JSON.parse(JSON.stringify(accountTableHeadModal));
      this.accounts_table_theads = JSON.parse(JSON.stringify(accountTableHeadsOrg));
      this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.accounts_table_theads_cc ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.accounts_table_theads))
  }
}
    // partymanagement/individual/account/signup
    // const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    this.company = val.data.company;
    this.selectedHierarchyContactFunc("");
    this.contactsDataCalling(this.currPage, this.rowsPerPage);

    // let data = await MLTAxiosInstance.get(
    //   `/partymanagement/organisation/inlineSearch?q=&size=5`
    // );
    // this.organizationList = data.data;
    // let preferredList = await DMNAxiosInstance.get(
    //   `mapRef/collection/cmAccountPreferredContactMedium`
    // );
    let preferedData = await this.getReferanceData('cmAccountPreferredContactMedium')
    this.preferredMediumList =  preferedData.filter((item) => {
      return item.name.toLowerCase() != "fax";
    });
    // let statusList = await DMNAxiosInstance.get(`mapRef/collection/statuslist`);
    this.statusList =  await this.getReferanceData('statuslist');
    console.log(this.preferredMediumList);
    // let designationList = await DMNAxiosInstance.get(
    //   `mapRef/collection/cmContactsDesignation`
    // );
    this.designationList =  await this.getReferanceData('cmContactsDesignation');
    // let tagsList = await DMNAxiosInstance.get(`mapRef/collection/tagsList`);
    let taglistData = await this.getReferanceData('tagsList')
    this.tagsList = taglistData.filter((item) => {
      return item.module === "cm"; 
    });
  },
  methods: {
    resizedWidth(val){
      console.log(val,"resizedWidth",val)

      const updateWidth = (array) => {
    let item = array.find(i => i?.prop === val?.prop);
    if (item) item.width = val.width;
  };

  updateWidth(this.accounts_table_theads);
  updateWidth(this.accounts_table_theads_cc);
console.log(this.accounts_table_theads,"resizedWidth loop",this.accounts_table_theads_cc)
this.applyColumns(this.accounts_table_theads_cc)
    },
    validatePhoneNumber(val) {
      return val.valid
    },
    async profileUpload(event) {
      console.log(event);
      let selectedFiles = event.target.files[0];
      let formData = new FormData();

      // Append all selected files to FormData object
      formData.append("files", selectedFiles);
      let data = await UPCAxiosInstance.post(
        `/gcpMultiple/v2/upload/`,
        formData
      );
      console.log(data);

      let obj = {};
      obj.name = data.data[0].filename;
      obj.size = data.data[0].data.attachment.size;
      obj.url = data.data[0].url;
      obj.id = data.data[0].data._id;
      obj["@type"] = "profile";
      // Get the current date and time in milliseconds
      const currentTimestamp = new Date().toJSON();

      // Format the current timestamp in the same format as '1705574420667'
      console.log(currentTimestamp);
      obj.lastUpdated = currentTimestamp;
      this.newConPayload.attachment = [];
      this.newConPayload.attachment.push(obj);

      console.log(this.newConPayload);
    },
    tooltipover(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis(data1, index1);
    },
    tooltipleave(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses(data1, index1);
    },
    ellipsis(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              let height = tooltip.offsetHeight;
              let top = element1.getBoundingClientRect().top - height;
              let left = element1.getBoundingClientRect().left;
              tooltip.style.display = "block";
              tooltip.style.top = top + "px";
              tooltip.style.left = left + "px";
              return true;
            }
          }
          return false;
        }
      });
    },
    ellipses(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              tooltip.style.display = "none";
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },
    sanitizeDatabaseName(databaseName) {
      // Replace dots with an underscore or any other symbol you want
      const sanitized = databaseName?.replace(/\./g, "_");

      // Validate the constraints for MongoDB database names
      const validDatabaseName = sanitized?.replace(/[^a-zA-Z0-9_]/g, "");

      return validDatabaseName;
    },
    async addTagList(e) {
      let obj = {};
      obj.name = e.name;
      obj.module = "cm";
      await DMNAxiosInstance.post(`mapRef/tagsList`, obj);
      let tagsListData = await this.getReferanceData('tagsList')
      this.tagsList =  tagsListData.filter((item) => {
        return item.module === "cm";
      });
      // console.log(e);
      // this.tagsList.push(e);
    },
    receivedTags(e) {
      console.log(
        "received",
        e,
        this.newConPayload.tags.filter((item) => item !== e[0]?.name)
      );
      // this.newAccPayload.tags = [];
      // e.forEach((ele) => {
      //   if (ele.checked === true) {
      //     this.newAccPayload.tags.push(ele.name);
      //   } else {
      //     this.newAccPayload.tags = this.newAccPayload.tags.filter(
      //       (item) => item !== ele.name
      //     );
      // //   }
      // });
      this.newConPayload.tags = e.reduce((tags, ele) => {
        if (ele.checked) {
          tags.push(ele.name);
        } else {
          tags = tags.filter((item) => item !== ele.name);
        }
        return tags;
      }, []);
    },
    emitStatus1(val1) {
      this.newConPayload.status = val1["value"];
    },
    async accountsFilter(filterSelectedvalue, filterQuery) {
      // ;
      this.filtertagvalue = filterSelectedvalue;
      this.filtertaglength = filterSelectedvalue.length;
      filterQuery.company = this.company;
      if (this.filtertagvalue.length != 0) {
        // this.totalpageno = spectotalpageno
        // this.tableData = spectabledata
        this.accountsquery = filterQuery;
        this.accountsquery.type = "filter";
        if (this.searchvalue != "") {
          this.accountsquery["searchQuery"] = this.searchvalue;
        }
        const filterResultData = await MLTAxiosInstance.post(
          `util/filterData`,
          this.accountsquery
        );
        // console.log(filterResultData.data.data,'la');

        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data.total;
        // ;
      } else {
        this.currPage = 1;
        this.rowsPerPage = 10;
        this.contactsDataCalling(this.currPage, this.rowsPerPage);
      }
    },
    showDrawerRight() {
      this.show_right_filter = true;
    },
    closeRightFilter() {
      this.show_right_filter = false;
    },
    validateEmail(val) {
      const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (val.length > 0) {
        if (!reg.test(val)) {
          return false; // URL is invalid
        } else {
          return true;
        }
      }
    },
    validateEmailNew() {
      console.log('hleo');
      this.isExistingEmail = false
      this.isExistingEmailErrorMessage = ''
    },
    async addNewContact() {
      console.log(this.newConPayload);
      this.canAdd = false;
      // this.newConPayload["role"] = this.rolee;
      let payload = JSON.parse(JSON.stringify(this.newConPayload));
      // if (payload.contactMedium[2].characteristic.description !== '') {
      //   let billingAddress = JSON.parse(
      //     JSON.stringify(payload.contactMedium[2])
      //   );
      //   let siteAddress = JSON.parse(JSON.stringify(payload.contactMedium[2]));
      //   billingAddress.mediumType = 'billingLocation';
      //   billingAddress.characteristic.phone =
      //     payload.contactMedium[1].characteristic;
      //   billingAddress.characteristic.email =
      //     payload.contactMedium[0].characteristic;
      //   billingAddress.characteristic.locationName = payload.name;
      //   billingAddress.characteristic.default = true;

      //   siteAddress.mediumType = 'siteLocation';
      //   siteAddress.characteristic.phone =
      //     payload.contactMedium[1].characteristic;
      //   siteAddress.characteristic.email =
      //     payload.contactMedium[0].characteristic;
      //   siteAddress.characteristic.locationName = payload.name;
      //   siteAddress.characteristic.default = true;

      //   payload.contactMedium.push(billingAddress);
      //   payload.contactMedium.push(siteAddress);
      // }
      // if (this.selectedHierarchyAccount === '') {
      payload.tradingName = this.company;
      payload.companyName = this.company;
      payload.company = this.company;
      // }
      payload.lastUpdate = new Date();
      let result
      this.isExistingName = false;
          this.isExistingNameErrorMessage = "";
          this.isExistingEmail = false;
          this.isExistingEmailErrorMessage = "";
      try {
        result = await MLTAxiosInstance.post(
          `/partymanagement/individual/account/signup`,
          payload
        );
      } catch (error) {
        console.log(error,error.response.data.message.includes('db already exists with different case'), result);
        if(error.response.data.message.includes('db already exists with different case')) {
          this.isExistingName = true;
          this.isExistingNameErrorMessage = "Account already exists with different case";
          return;
        } else if(error.response.data.message.includes('This email was already registered')) {
          this.isExistingEmail = true;
          this.isExistingEmailErrorMessage = "This email is already in use";
          return;
        } else {
          this.isExistingName = false;
          this.isExistingNameErrorMessage = "";
          this.isExistingEmail = false;
          this.isExistingEmailErrorMessage = "";
      }
      return
      }
      if (result.data.message == 'This email was already registered') {
        this.isExistingEmail = true;
        this.isExistingEmailErrorMessage = "This email is already registered";
        return;
      }
      console.log('new result', result);
      
      this.addressKey += 1;

      if (result.data.tradingName !== "") {
        this.notification = {
          type: "success",
          message: `Nice work! Your new organization "${this.newConPayload.name}" is now in the list!`,
        };
      }
      this.closeAddNewContactModal();
      this.contactsDataCalling(this.currPage, this.rowsPerPage);
    },
    closeAddNewAccountModal() {
      this.addressKey += 1;
      let x = {
        name: "",
        tradingName: "",
        parentCompany: "",
        status: "Active",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                valid: true,
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              address1: "",
              default: true,
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        relatedParty: [],
        tags: ["Active"],
        aristocraticTitle:"",
        role: "Root admin",
        type: "company",
        password: "",
        email: "",
        organizationParentRelationship: {},
        rootName: "",
      };
      this.accExecParty = "";
      this.modalKey += 1;
      this.newAccPayload = JSON.parse(JSON.stringify(x));
      this.showAddNewAccountModal = false;
    },
    async contactsDataCalling(currentPage, rowsPerPage) {
      const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
      this.company = val.data.company;
      this.accountsquery["company"] = this.company;

      if (this.filtertagvalue.length === 0 && this.searchvalue == "") {
        this.accountsquery.type = "filter";
        this.accountsquery["paginatedQuery"]["currentPage"] = currentPage;
        this.accountsquery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
        const filterResultData = await MLTAxiosInstance.post(
          `util/filterData`,
          this.accountsquery
        );
        console.log(
          filterResultData,
          "specfiltereddata",
          this.accountsquery,
          "alala",
          filterResultData.data.total
        );
        if (
          filterResultData.data.data != undefined &&
          filterResultData.data.total != 0
        ) {
          this.tableData = filterResultData.data.data;
          this.totalpageno = filterResultData.data.total;
          this.paginatedData;
        }
      } else if (this.accountsquery.type == "filter") {
        if (this.filtertagvalue.length != 0) {
          this.accountsquery["paginatedQuery"]["currentPage"] = currentPage;
          this.accountsquery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
          const filterResultData = await MLTAxiosInstance.post(
            `util/filterData`,
            this.accountsquery
          );
          console.log(filterResultData, "specfiltereddata", this.accountsquery);
          this.tableData = filterResultData.data.data;
          this.totalpageno = filterResultData.data.total;
          this.tableData?.map((e) => {
            // console.log(e);
            if (e.context == undefined) {
              e.context = false;
            }
          });
        } else {
          this.accountsquery.type = "search";
          this.accountsquery["paginatedQuery"]["currentPage"] = currentPage;
          this.accountsquery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
          const filterResultData = await MLTAxiosInstance.post(
            `util/filterData`,
            this.accountsquery
          );
          console.log(filterResultData, "offeringfiltereddata");
          this.tableData = filterResultData.data.data;
          this.totalpageno = filterResultData.data.total;
          this.tableData?.map((e) => {
            // console.log(e);
            if (e.context == undefined) {
              e.context = false;
            }
          });
        }
      } else if (this.accountsquery.type == "search") {
        if (this.searchvalue != "") {
          this.accountsquery["paginatedQuery"]["currentPage"] = currentPage;
          this.accountsquery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
          const filterResultData = await MLTAxiosInstance.post(
            `util/filterData`,
            this.accountsquery
          );
          console.log(
            filterResultData,
            "offeringfiltereddata",
            this.accountsquery
          );
          this.tableData = filterResultData.data.data;
          this.totalpageno = filterResultData.data.total;
          this.tableData?.map((e) => {
            // console.log(e);
            if (e.context == undefined) {
              e.context = false;
            }
          });
        } else {
          this.accountsquery.type = "filter";
          this.accountsquery["paginatedQuery"]["currentPage"] = currentPage;
          this.accountsquery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
          const filterResultData = await MLTAxiosInstance.post(
            `util/filterData`,
            this.accountsquery
          );
          console.log(filterResultData, "offeringfiltereddata");
          this.tableData = filterResultData.data.data;
          this.totalpageno = filterResultData.data.total;
          this.tableData?.map((e) => {
            // console.log(e);
            if (e.context == undefined) {
              e.context = false;
            }
          });
        }
      }
    },
    async searchKey(val) {
      this.searchvalue = val;
      this.accountsquery["company"] = this.company;
      this.accountsquery["type"] = "search";
      this.accountsquery["searchQuery"] = this.searchvalue;
      this.currPage = 1;
      this.rowsPerPage = 10;
      this.contactsDataCalling(this.currPage, this.rowsPerPage);
      // this.searchvalue = val;
      // if (this.searchvalue && this.searchvalue != '') {
      //   this.tableData = this.searchtabledata.filter((item) => {
      //     if (
      //       item.name != undefined &&
      //       item.description != undefined &&
      //       item.status != undefined &&
      //       this.searchvalue != undefined
      //     ) {
      //       return (
      //         item.name
      //           .toLowerCase()
      //           .toString()
      //           .includes(this.searchvalue.toLowerCase()) ||
      //         item.description
      //           .toLowerCase()
      //           .toString()
      //           .includes(this.searchvalue.toLowerCase()) ||
      //         item.status
      //           .toLowerCase()
      //           .toString()
      //           .includes(this.searchvalue.toLowerCase())
      //       );
      //     }
      //   });
      //   this.duplicatetabledata = this.tableData;
      // } else {
      //   this.tableData = this.searchtabledata;
      //   this.duplicatetabledata = this.searchtabledata;
      // }
    },
    currentPage(val) {
      if (this.company != "") {
        this.currPage = val;
        this.contactsDataCalling(this.currPage, this.rowsPerPage);
        console.log(this.tableData, "lal");
      }
    },
    changeRowsPerPage(count) {
      //
      if (this.company != "") {
        this.rowsPerPage = count;
        this.contactsDataCalling(this.currPage, this.rowsPerPage);
      }
    },
    closeAddNewContactModal() {
      this.addressKey += 1;
      let x = {
        name: "",
        tradingName: "",
        parentCompany: "",
        status: "Active",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                valid: true,
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              address1: "",
              default: true,
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        relatedParty: [
          {
            role: "director",
            partyOrPartyRole: {
              id: "42",
              name: "Feroz Khan",
              "@referredType": "Individual",
            },
          },
        ],
        tags: ["Active"],
        aristocraticTitle:"",
        role: "Root admin",
        type: "company",
        password: "",
        email: "",
        organizationParentRelationship: {},
        rootName: "",
      };
      this.modalKey += 1;
      this.newConPayload = JSON.parse(JSON.stringify(x));
      this.showAddNewContactModal = false;
    },
    receivedAddressData(val) {
      console.log(val);
      if (val.characteristic.country !== "") {
        this.newConPayload.contactMedium[1].characteristic.phoneNumber
        .country.name = val.characteristic.country
        this.newConPayload.contactMedium[1].characteristic.phoneNumber
        .country.flags = ""
        this.newConPayload.contactMedium[1].characteristic.phoneNumber
        .country.short = ""
      }
      let address1 = JSON.parse(JSON.stringify(val));
      // let address2 = JSON.parse(JSON.stringify(val))
      this.newConPayload.contactMedium[2] = JSON.parse(
        JSON.stringify(address1)
      );
      // address2.mediumType = 'billingLocation'
      // this.newAccPayload.contactMedium.push(address2);
    },
    selectedHierarchyAccountDetails(val) {
      val;
      this.newConPayload.tradingName = this.selectedHierarchyAccount;
      this.newConPayload.company = this.selectedHierarchyAccount;
      this.newConPayload.companyName = this.selectedHierarchyAccount;
    },
    async selectedHierarchyContactFunc(val) {
      const f = {
        company: this.company,
        type: "filter-search",
        module: "hierarchyaccounts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 5,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 2,
        filterQuery: {
          "@baseType": {
            $ne: "supplier",
          },
        },
        searchQuery: val,
      };
      let data = await MLTAxiosInstance.post(`util/filterData`, f);
      console.log(data);
      this.organizationList = data.data.data;
    },
    addNewAccount() {
      this.selectedHierarchyContactFunc("");
      this.newConPayload["aristocraticTitle"] = "";
      this.selectedHierarchyAccount = "";
      this.addressKey += 1;
      this.refreshSelect += 1;
      this.componentkey += 1;
      this.showAddNewContactModal = true;

      // this.$router.push("/CM/accounts/add");
    },
    crudContextMenu($event, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 159;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    async closeCrudContextMenu($event, ele, index) {
      index;
      if ($event.toLowerCase() === "edit") {
        this.$router.push(
          `/CM/contacts/view/${ele.name}/${ele._id}/edit/contacts?company=${ele.company}`
        );
      } else if ($event.toLowerCase() == "mark as in-active") {
        console.log(ele, "sdfghjk");
        let i = {
          id: ele._id,
          status: "Inactive",
        };
        const contacts = await MLTAxiosInstance.patch(
          `/partymanagement/individual/?company=${this.company}`,
          i
        );
        console.log(contacts, "lllalsldddddddddddddddddddddddjsddslk");
        this.contactsDataCalling(this.currPage, this.rowsPerPage);
        if (contacts.data.email) {
          this.alertmsg = "success";
          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };
        } else {
          this.alertmsg = "danger";
          this.notification = {
            type: "danger",
            message: `Data could not be inserted.`,
          };
        }
        this.contactsDataCalling(this.currPage, this.rowsPerPage);
      } else if ($event.toLowerCase() == "mark as active") {
        let i = {
          id: ele._id,
          status: "Active",
        };
        const contacts = await MLTAxiosInstance.patch(
          `/partymanagement/individual/?company=${this.company}`,
          i
        );
        if (contacts.data.email) {
          this.alertmsg = "success";
          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };
        } else {
          this.alertmsg = "danger";
          this.notification = {
            type: "danger",
            message: `Data could not be inserted.`,
          };
        }
        this.contactsDataCalling(this.currPage, this.rowsPerPage);
      }
    },
    computedTableAction(data) {
  // Destructure permission and status for clarity
  const { permisstionData, staticData, loginUserDetails } = this;
  const status = data.status?.toLowerCase(); // Ensure case-insensitivity

  // Utility function for display label
  const getDisplayLabel = (key, fallback) =>
    staticData?.[key]?.label?.[loginUserDetails]?.label || fallback;

  // Define base options based on permissions
  let options = [];

  if (permisstionData.delete && permisstionData.edit) {
    options = [
      {
        label: "Edit",
        display: getDisplayLabel("edit", "Edit"),
        icon: "icon-file-text-edit-regular",
      },
      {
        label: "Impersonate",
        display: getDisplayLabel("impersonate", "Impersonate"),
        icon: "icon-user-change-regular",
      },
    ];

    // Only add "Mark as In-active" and "Mark as Active" if status allows
    if (status === "active") {
      options.push({
        label: "Mark as In-active",
        display: getDisplayLabel("markAsInactive", "Mark as In-active"),
        icon: "icon-times-square-regular",
      });
    } else if (status === "inactive") {
      options.push({
        label: "Mark as Active",
        display: getDisplayLabel("markAsActive", "Mark as Active"),
        icon: "icon-check-square-regular",
      });
    }
  } else if (permisstionData.delete) {
    if (status === "active") {
      options.push({
        label: "Mark as In-active",
        icon: "icon-times-square-regular",
      });
    } else if (status === "inactive") {
      options.push({
        label: "Mark as Active",
        icon: "icon-check-square-regular",
      });
    }
  } else {
    options = [
      {
        label: "Edit",
        icon: "icon-file-text-edit-regular",
      },
      {
        label: "Impersonate",
        icon: "icon-user-change-regular",
      },
    ];
  }

  // Additional conditions for "pending" status
  if (status === "pending") {
    return [
    {
        label: "Impersonate",
        display: getDisplayLabel("impersonate", "Impersonate"),
        icon: "icon-user-change-regular",
      },
      {
        label: "Edit",
        icon: "icon-file-text-edit-regular",
      },
    ];
  }

  return options;
},
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    reAssignOrgTableHeader(){
              // If there is change in the table header then ressign to the Orginal data
              if((this.accounts_table_theads_cc != this.tableHeaderOrgcc) && this.tableHeaderOrgcc.length > 0)
    {
      this.accounts_table_theads_cc = JSON.parse(JSON.stringify(this.tableHeaderOrgcc))
    }
    if((this.accounts_table_theads != this.tableHeaderOrg) && this.tableHeaderOrg.length > 0)
    {
      this.accounts_table_theads = JSON.parse(JSON.stringify(this.tableHeaderOrg))
    }
      this.toggleRearrangeModal=true
    },
    arrangeColumns(val) {
      this.toggleRearrangeModal = val;
      // this.updateTheads();
    },
    updateTheads() {
      this.accounts_table_theads_cc = this.accounts_table_theads_cc.map(
        (thead) => {
          // Check if the current thead has a matching 'prop' in duplicateTheads
          const isMatching = this.accounts_table_theads.some(
            (duplicateThead) => duplicateThead.prop === thead.prop
          );

          // Set the 'checked' property based on whether it's matching
          return { ...thead, checked: isMatching };
        }
      );
    },
   async applyColumns(val) {

      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
let concatedEntity = sampleTheads?.data?.entityRef || {};
concatedEntity.accountResidentialCustomers = val;

let reArrangeTableHeadPayload = { entityRef: concatedEntity };
 
const changeTableHeader = await MLTAxiosInstance.patch(`/settings`, reArrangeTableHeadPayload);
console.log(changeTableHeader, "reArrangeTableHeadPayload", reArrangeTableHeadPayload);

let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
console.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
this.accounts_table_theads_cc = entityRef["accountResidentialCustomers"]
this.accounts_table_theads = this.accounts_table_theads_cc.filter(ele=>ele.checked)
this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.accounts_table_theads_cc ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.accounts_table_theads))
    },
  },
};
</script>
