<template>
<template-builder></template-builder>
</template>

<script>
import TemplateBuilder from '../../components/TemplateBuilder/builderComponent.vue'
export default
{
   components:{
    TemplateBuilder
   }
}
</script>