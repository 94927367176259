<template>
  <div>
    <div class="selfserve_dashboard_name">
      <div class="selfserve_welcome_customer">Welcome John doe !</div>
      <div class="selfserve_welcome_quotes">Nice to have you here!</div>
    </div>

    <div class="selfserve_dashboard_main">
      <!-- <div></div> -->
      <div class="selfserve_dashboard_item1">
        <div class="small_toggle">
            <div><b>Autorenew</b></div>
            <div>
                <hlx-input :checked="false" :type="'switch'"/>
            </div>
        </div>
        <div class="left">
            <div class="item1">Current balance</div>
            <div class="item2">Next due on  Apr 12 , 2024</div>
            <div class="item3">£ 35.00</div>
            <div class="item4">Balance due</div>
            <div class="item5">
                <hlx-button class="primary">£ Make payment</hlx-button>
                <hlx-button class="secondary-success"><b>Latest invoice</b></hlx-button>
            </div>
        </div>
        <div class="right">
            <img
          src="@/assets/images/png/rafiki.png"
          style="width: 320px"
        />
        </div>
      </div>
      <div>
        <hlx-card
          :seperator="false"
          :card-title="false"
          :card-footer="false"
          :width="420"
          :height="265"
          :active="true"
        >
          <template #card-body>
            <div class="mainpage_item2">
              <div class="items2_Header">
                <div class="myservice">My Service</div>
                <div><hlx-button class="secondary-success">Change Plan</hlx-button></div>
              </div>
              <div class="item2_context">
                <div class="fibre_name">HomeFiber Ultra 900</div>
                <div class="fibre_id">User id :B15846ybdhd</div>
              </div>
              <div class="footbox">
                <div class="footbox1">
                    <div class="item1">Upto</div>
                    <div class="item2">900 Mbps</div>
                    <div class="item3">Download</div>
                </div>
                <div class="footbox2">
                    <div class="item1">Upto</div>
                    <div class="item2">900 Mbps</div>
                    <div class="item1">Upload</div>
                </div>
                <div class="footbox3">  
                    <div class="item1">Monthly usage</div>
                    <div class="item2">Unlimited</div>
                    <div class="item3"></div>
                </div>
              </div>
            </div>
          </template>
        </hlx-card>
      </div>
      <div>
        <hlx-card
          :seperator="false"
          :card-title="false"
          :card-footer="false"
          :width="420"
          :height="351"
          :active="true"
        >
          <template #card-body>
            <div class="mainpage_item3">
                <div class="item1">Usage</div>
                <div class="item2">April 10, 2024 to May 09, 2024</div>
                <div class="item3">
                    <div class="text_content">Total allowance</div>
                    <div class="infinity_symbol">
                        <img
          src="@/assets/images/png/Vector.png"
          style="width: 48px"
        />
                    </div>
                </div>
            </div>
          </template>
        </hlx-card>
      </div>
      <div>
        <hlx-card
          :seperator="false"
          :card-title="false"
          :card-footer="false"
          :width="420"
          :height="351"
          :active="true"
        >
          <template #card-body>
            <div class="mainpage_item4">
                <div class="item_head">FAQ</div>
                <div class="item_list"><span class="msg_icon"><i class="icon-receipt-text-regular"></i></span>Our router and it’s feature</div>
                <div class="item_list"><span class="msg_icon"><i class="icon-receipt-text-regular"></i></span>How fast is our broadband</div>
                <div class="item_list"><span class="msg_icon"><i class="icon-receipt-text-regular"></i></span>Full speed even at peak times</div>
                <div class="item_list"><span class="msg_icon"><i class="icon-receipt-text-regular"></i></span>Using your own router or mesh</div>
                <div class="item_list"><span class="msg_icon"><i class="icon-receipt-text-regular"></i></span>Static IPs and port forwarding</div>
                <div class="item_list"><span class="msg_icon"><i class="icon-receipt-text-regular"></i></span>WIFI extenders, mesh system, boosters</div>
            </div>
          </template>
        </hlx-card>
      </div>
      <div>
        <hlx-card
          :seperator="false"
          :card-title="false"
          :card-footer="false"
          :width="420"
          :height="351"
          :active="true"
        >
          <template #card-body>
            <div class="mainpage_item5">
                <div class="header">Tickets</div>
                <div class="plus_icon"><i class="icon-plus-regular"></i></div>
                <div class="smallcard">
                    <div>
                        <hlx-card
          :seperator="false"
          :card-title="false"
          :card-footer="false"
          :width="390"
          :height="74"
          :active="false"
        >
          <template #card-body>
            <div class="smallcard_item">
                <div class="status_header"><div class="status_head">Billing Dispute Resolution <span class="code_box"># 983741</span></div><div class="date">Apr 14, 2024</div></div>
                <div class="status_body"><hlx-status :type="'custom'" :point-color="'#02B55C'" :status-value="'Open'" /></div>
            </div>
          </template>
        </hlx-card>
                    </div>
                    <div>
                         <hlx-card
          :seperator="false"
          :card-title="false"
          :card-footer="false"
          :width="390"
          :height="74"
          :active="false"
        >
          <template #card-body>
            <div class="smallcard_item">
                <div class="status_header"><div class="status_head">Technical Support Request <span class="code_box"># 983741</span></div><div class="date">Apr 08, 2024</div></div>
                <div class="status_body"><hlx-status :type="'custom'" :point-color="'#DD2025'" :status-value="'Closed'" /></div>
            </div>          </template>
        </hlx-card>
                    </div>
                    <div>
                        <hlx-card
          :seperator="false"
          :card-title="false"
          :card-footer="false"
          :width="390"
          :height="74"
          :active="false"
        >
          <template #card-body>
            <div class="smallcard_item">
                <div class="status_header"><div class="status_head">Network Performance Inquiry <span class="code_box"># 983741</span></div><div class="date">Apr 02, 2024</div></div>
                <div class="status_body"><hlx-status :type="'custom'" :point-color="'#DD2025'" :status-value="'Closed'" /></div>
            </div>
          </template>
        </hlx-card>
                    </div>
                </div>
            </div>
          </template>
        </hlx-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      card_data: [
        {
          card_title: "Card title 1",
          card_body:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias, provident. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias, provident. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias, provident.",
          card_footer: "Footer of the card",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@mixin flexcolumn{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
@mixin flexrow{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
@mixin greyquicksand{
    font-family: "Quicksand";
    font-size: 18px;
    font-weight: 600;

}
@mixin smallblue{
            font-weight: 400;
            font-size: 12px;
            color: rgba(124, 143, 172, 1);
        }
@mixin itemhead{
        font-weight: 700;
        font-size: 23px;
        font-family: "Quicksand";
}
@mixin itembody{
        font-weight: 400;
        font-size: 14px;
        font-family: "OpenSans";
}
@mixin lighttext{
    font-family: "OpenSans";
    font-size: 10px;
    font-weight: 400;
}
.selfserve_dashboard_name {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 11px;
  margin-left: 19px;
  height: 65px;
  width: 300px;
  background: none;
  justify-content: space-between;
  .selfserve_welcome_customer {
    font-family: "quicksand";
    font-size: 29px;
    font-weight: 600;
  }
  .selfserve_welcome_quotes {
    font-size: 14px;
    font-weight: 400;
  }
}
.selfserve_dashboard_main {
  position: relative;
  background: none;
  border: 1px solid none;
  max-width: 1333px;
  min-width: 700px;
  height: 680px;
  margin: 23px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: auto;
  .selfserve_dashboard_item1 {
    width: 65%;
    background: rgba(84, 189, 149, 0.07);
    height: 264px;
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    .left{
        width: 305px;
        height:215px;
        background: none;
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: space-between;
        .item1{
            font-weight: 700;
            font-size: 23px;
            font-family: "quicksand";
        }
        .item2{
            font-weight: 400;
            font-size: 14px;
            color: rgba(124, 143, 172, 1);
        }
        .item3{
            font-weight: 600;
            font-size: 21px;
        }
        .item4{
            font-weight: 400;
            font-size: 14px;
            color: rgba(124, 143, 172, 1);
            font-family: "OpenSans";
        }
        .item5{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
    .right{
        margin-right: 52px;
    }
    .small_toggle{
        position: absolute;
        right: 5px;
        bottom: 8px;
        width: 147px;
        height: 27px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-family: "quicksand";
        font-weight: 500;
        font-size: 14px;
    }
  }
}
.mainpage_item2 {
    position:relative;
    width: 371px;
    height: 279px;
    background: none;
    display: flex;
    flex-direction: column;
    margin: 20px;
    gap: 20px;
    .items2_Header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        background: none;
        width: 371px;
        .myservice{
            color: rgba(141, 141, 141, 1);
            font-family: "Quicksand";
            font-size: 18px;
            font-weight: 600;
        }
    }
    .item2_context{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: left;
        position: relative;
        background: none;
        gap: 6px;
        .fibre_name{
            font-family: "OpenSans";
            font-size: 18px;
            font-weight: 600;
        }
        .fibre_id{
            font-family: "OpenSans";
            font-size: 14px;
            font-weight: 500;
        }
    }
    .footbox{
        background: none;
        width: 100%;
        height: 101px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .footbox1{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: left;
        width: 92px;
        border-right: 1px solid rgba(223, 229, 239, 1);
        .item1{
            @include smallblue;
        }
        .item2{
            font-weight:600;
            font-size:14px;
        }
        .item3{
            @include smallblue;
        }
    }
    .footbox2{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: left;
        width: 124px;
        border-right: 1px solid rgba(223, 229, 239, 1);
        .item1{
            @include smallblue;
        }
        .item2{
            font-weight:600;
            font-size:14px;
        }
        .item3{
            @include smallblue;
        }
    }
    .footbox3{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: left;
        width: 124px;
        .item1{
            @include smallblue;
        }
        .item2{
            font-weight:600;
            font-size:14px;
        }
        .item3{
            @include smallblue;
            height: 25px;
            background: none;
            width: 10px;
        }
    }
}
.mainpage_item3 {
    margin: 20px;
    @include flexcolumn;
    align-items: center;
    gap: 20px;
    .item1{
        @include itemhead;
    }
    .item2{
        font-size: 12px;
        font-weight: 400;
        font-family: "opensans";
    }
    .item3{
        width: 215px;
        height: 215px;
        border-radius: 108px;
        border: 11px solid rgb(84, 189, 149);
        @include flexcolumn;
        justify-content: space-evenly;
        align-items: center;
        .text_content{
            font-family: "quicksand";
            font-size: 19px;
            font-weight: 400;
            margin-top: 26px;
        }
        .infinity_symbol{
            margin-bottom: 35px;
        }
    }
    
}
.mainpage_item4 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    position: relative;
    height: 225px;
    width: 371px;
    margin: 20px;
    gap: 20px;
    .item_head{
        @include itemhead;
        padding-left: 10px;
    }
    .item_list{
        @include itembody;
        .msg_icon{
            padding: 1px 10px;
        }
    }
}
.mainpage_item5 {
    margin: 14px;
    @include flexcolumn;
    align-items: center;
    gap: 10px;
    .header{
        @include itemhead;
        position: absolute;
        top: 10px;
        left: 10px;
    }
    .plus_icon{
        position: absolute;
        right: 10px;
        top: 10px;
        color: rgba(84, 189, 149, 1);
        font-size: 28px;
    }
    .smallcard{
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 46px;
        justify-content: space-between;
        gap: 15px;
        .smallcard_item{
            @include flexcolumn;
            position:relative;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: left;
            // margin: 13px;
            .status_header{
                    @include flexrow;
                    justify-content: space-between;
                    margin: 0px 15px;
                    margin-top: 9px;
                    .status_head{
                        @include itembody;
                        .code_box{
                            @include lighttext;
                            color: rgba(166, 166, 166, 1);
                        }
                    }
                    .date{
                        font-family: "opensans";
                        font-weight: 400;
                        font-size: 12px;                        
                    }
                }
            
            .status_body{
                margin: 0px 11px;
            }
        }
    }
}
</style>
