<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <hlx-alert-notification :notify="notification" :auto-close="true" />

  <div v-if="type == 'tangible'">
    <hlx-modal
      style="z-index: 999999999"
      :modal-active="delete_modal_active"
      :height="'220px'"
      :width="'400px'"
      :modal="true"
      @close="delete_modal_active = false"
    >
      <template #header>
        <div style="margin-left: 0px">Delete data</div>
      </template>
      <template #content>
        <div class="modal-content">
          <p>
            Are you sure you want to delete <b>{{ delete_name }}</b
            >?
          </p>
        </div>
      </template>
      <template #footer>
        <hlx-button class="secondary media" @click="delete_modal_active = false"
          >Cancel</hlx-button
        >
        <hlx-button
          class="primary media"
          style="margin-left: 16px"
          @click="deleteExecRow"
          >Delete</hlx-button
        >
      </template>
    </hlx-modal>
    <!-- hlx-drawer -->
    <hlx-drawer
      :key="componentkey"
      :title="'upload drawer'"
      :show="show_upload_drawer"
      :width="427"
      position="right"
      :footer="true"
      :show-close-icon="true"
      @close="closeuploaddrawer"
    >
      <template #body>
        <div
          class="upload_image_header"
          style="padding: 12px; border-bottom: 1px solid #d8d8d8"
        >
          
          <span><b style="font-size: 16px">Add Media</b></span>
        </div>
        <div class="upload_image_body" style="height: 635px">
          <div v-if="offertypeAtomic">
          <div v-if="sortedConfigbox?.length >0">
          <div class="product-detail-media" style="margin: 16px">
            <div
              v-for="item in sortedConfigbox"
              :key="item"
              class="value-data-media"
            >
              <span class="value-data-media_left">{{ item.name }}</span>
              <span class="value-data-media_right" style="text-align: end"
                ><span>
                  {{ item.value }}
                </span>
              </span>
            </div>
            </div>
            
          </div>
        </div>
          <div class="uploader_div" style="margin: 16px">
            <hlx-file-upload
              :key="componentkey"
              :type="'slide-upload'"
              :width="'100px'"
              :height="'100px'"
              :position="'horizontal'"
              :slider-options="previewImage"
              :preffered-image="preffedImage"
              @native-data="dataevent"
              @default-image="defaltImg"
              @delete-image="deleteImage"
            />
          </div>
          
        <div v-if="basetype1 === 'tangible'" v-show="offertypeAtomic">
          <div class="default_checkbox_div" style="margin: 16px; height: 34px">
            <hlx-input
              v-model:value="checkboxChecked"
              label-value="Apply this image to all combinations."
              :type="'single-checkbox'"
              :checked="checkboxChecked"
              @change="configureMode"
            />
          </div>
          <div 
            v-show="checkboxChecked"
            class="select_below_uploder"
            style="
              width: 187px;
              height: 96px;
              margin: 16px;
              display: flex;
              justify-content: space-between;
              flex-direction: column;
            "
          >
            <hlx-select
              :key="componentkey"
              style="width: 201%"
              :options="drophead"
              :placeholder-value="'Group by'"
              :label-animation="true"
              :label="'name'"
              :prop-value="'name'"
              @selected-value="emithead"
            ></hlx-select>
            <hlx-select
              :key="componentkey"
              :options="dropvalue1"
              :placeholder-value="'Select combination'"
              :label-animation="true"
              :label="'name'"
              :prop-value="'name'"
              @selected-value="emitvalue"
            ></hlx-select>
          </div>
        </div>
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 9px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary media sm" @click="closeuploaddrawer"
            >Cancel</hlx-button
          >
          <hlx-button
            v-if="editMode == false"
            class="primary media sm"
            style="margin-left: 10px"
            :disabled="!enableButton"
            @click="addImage"
            >Save</hlx-button
          >
          <hlx-button
            v-else
            class="primary media sm"
            style="margin-left: 10px"
            @click="editNote"
            >Save</hlx-button
          >
        </div>
      </template>
    </hlx-drawer>

    <section v-if="copyOfMediaTableData.length > 0 || searchvalue !==''" >
      <section class="media-search-row">

        <span class="--upcv2-body-title" style="margin-bottom: 0px !important">
          <h4>Media</h4>
        </span>
        <span class="util-items search">
          <hlx-search
            
            @search-key="pricingSearchTrigger"
          />
        </span>
      </section>

      <section class="media-search-row">

      </section>
      <!-- table -->
      <section v-if=" copyOfMediaTableData.length > 0">
        <hlx-table
          :key="componentkey"
          :column-count="newhead.length"
          :border="['table', 'header', 'horizontal']"
          :bold-headers="false"
          :row-hover="true"
          theme="grey"
          :striped-rows="false"
        >
          <template #thead>
            <hlx-table-head
              :width="50"
              :min-width="50"
              :align="'center'"
              style="padding: 0 15px; min-width: 60px; max-width: 60px"
              :fixed="'left'"
            >
              {{ "S.no" }}</hlx-table-head
            >

            <hlx-table-head
              v-for="(header, index) in newhead"
              :key="index"
              :width="header.width"
              :sortable="header.sortable"
              :resizable="header.resizable"
              ><span
                v-if="header.label === 'Image'"
                style="padding-left: 15px"
                >{{ header.label }}</span
              >
              <span v-else>{{ header.label }}</span></hlx-table-head
            >
            <hlx-table-head
              :width="50"
              :fixed="'right'"
              style="max-width: 60px !important"
              ><span class="action-icon">
                <i class="icon-settings-regular"></i></span
            ></hlx-table-head>
          </template>

          <template #tbody>
            <tr
              v-for="(i, index) in tableDataOnSelectedRows"
              id=""
              :key="index"
            >
              <hlx-table-cell :align="'center'" :fixed="'left'">
                {{ serialNumberAlter(index) }}
              </hlx-table-cell>
              <hlx-table-cell
                v-for="(j, col_index) in newhead"
                :key="col_index"
                :align="j.align"
                :fixed="j.fixed"
              >
                <div v-if="j.prop === 'attachments'">
                  
                  <div
                    style="
                      padding-top: 5px;
                      padding-bottom: 5px;
                      padding-left: 10px;
                      cursor: pointer;
                      position: relative;
                      
                    "
                    @click="enablemedia? openuploaddrawers(i, index) : null"
                  >
                    <span
                      ><hlx-file-upload
                        :key="componentkey"
                        :file-type="'.jpg,.jpeg,.png'"
                        :file-size-limit="'20mb'"
                        :uploadtype="'Rectangle'"
                        :style-background="backgrounStyleupload"
                        :height="50"
                        :width="50"
                        :read-only="true"
                        :custom-icon="icon"
                        :type="'profile-upload'"
                        :profile-icon="'icon-upload-regular'"
                        :content="'Import'"
                        :profile-pic-preview="i?.src"
                        @fileData="profiledata"
                    /></span>
                  </div>
                </div>


                <div v-if="j?.prop === 'All SKU'">
                  <div
style="display: inline-block;width: 300px;padding: 0px 0px 0px 0px;"
    >{{ allskudata }}</div>
                </div>


                
                <div v-if="j.prop === 'min'">
                  <p v-for="item in i.tiers" :key="item">
                    {{ item.min }}
                  </p>
                </div>
                <div v-if="j.prop === 'max'">
                  <p v-for="item in i.tiers" :key="item">
                    {{ item.max }}
                  </p>
                </div>
                <div v-else-if="j.prop === 'commitmentLength'">
                  {{ i[j.prop] }} {{ i["billingPeriod"] + "s" }}
                </div>
                <div v-else-if="j.prop === 'endOfCommitment'">
                  <span v-if="i[j.prop]?.autoPay == true">{{ "Yes" }}</span>
                  <span v-else>No</span>
                </div>
                <div
                  v-else-if="j.prop === 'price'"
                  style="display: flex; flex-direction: column; gap: 12px"
                >
                  
                  <p
                    :key="item"
                    class="bg-default border"
                    :data-tooltip="i['description']"
                    data-tooltip-location="top"
                  >
                    {{
                      currencyFormatter(i["MSRP"]?.value, {
                        currency: i["MSRP"]?.unit,
                      })
                    }}
                    
                  </p>
                </div>
                <div
                  v-else-if="j.prop === 'pricePerUnit'"
                  style="display: flex; flex-direction: column; gap: 12px"
                >
                  <p
                    v-for="item in i?.tiers"
                    :key="item"
                    class="bg-default border"
                    :data-tooltip="i['description']"
                    data-tooltip-location="top"
                  >
                    
                    {{
                      currencyFormatter(item?.price?.value, {
                        currency: item?.price?.unit,
                      })
                    }}
                    /

                    {{ i["billingPeriod"] }}
                  </p>
                </div>
                <div v-else>
                  {{ i[j.prop] }}
                </div>
              </hlx-table-cell>
              <hlx-table-cell :align="'center'" :width="120" :fixed="'right'">
                <span :id="'table-context' + index" class="action-icon">
                  <i
                    style="position: relative"
                    class="icon-more-vertical-filled"
                    :class="i.context === true ? 'active-action' : ''"
                    @click="crudContextMenu($event, index)"
                  >
                    <hlx-context-menu
                      :top="containertop"
                      :left="containerleft"
                      :conditionvalue="150"
                      :conditiondown="2"
                      :conditionup="-38"
                      :options="computedTableAction(i)"
                      :data="index.toString()"
                      :show="i.context == true"
                      @chosen="closeCrudContextMenu($event, i, index)"
                    />
                  </i>
                </span>
              </hlx-table-cell>
            </tr>
          </template>
        </hlx-table>

        <div style="height: 90px"></div>

      </section>
    </section>




<section>   <div
      v-if="copyOfMediaTableData.length === 0 && searchvalue !== ''"
      class="cm-accounts-right-panel-top-section-table-wrap"
    >
      <hlx-table
       
          :column-count="newhead.length"
          :border="['table', 'header']"
          :bold-headers="false"
          :row-hover="true"
          theme="grey"
          :striped-rows="false"
      >
        <template #thead>
          <hlx-table-head :width="60" :align="'left'">{{
            "S.no"
          }}</hlx-table-head>
          <hlx-table-head
         v-for="(header, index) in newhead"
              :key="index"
              :width="header.width"
              :sortable="header.sortable"
              :resizable="header.resizable"
              ><span
                v-if="header.label === 'Image'"
                style="padding-left: 15px"
                >{{ header.label }}</span
              >
              <span v-else>{{ header.label }}</span></hlx-table-head>
          <hlx-table-head v-show="supplierStatus == false" :width="100">
            <span class="action-icon"
              ><i
                class="icon-settings-regular"
                @click="arrange(true)"
              ></i></span
          ></hlx-table-head>
        </template>
        <template #tbody>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell :colspan="newhead.length + 2">{{
              "No data"
            }}</hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
    </div></section>
    <!-- new section -->

    <!-- table -->
    <!-- without tabledata -->
    <section
      v-if="tableData.length <= 0 && searchvalue === 0"
      style="
        margin-top: 3.2rem;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        align-items: center;
      "
    >
      <img
        src="@/assets/images/addAccountAttachments.svg"
        alt=""
        style="width: 500px"
      />

      <span class="util-items util-items-add">
        <hlx-button class="primary media add-btn" @click="openuploaddrawer1"
          ><i class="icon-plus-circle-regular" style="margin-right: 5px"></i>Add
          Image upload</hlx-button
        >
      </span>
    </section>



            <!-- Pagination -->
            <!-- <div style="display: flex;height: 100px;width: 100%;background: greenyellow;bottom: 80px;right: 0;position: absolute;"><div
style="
    height: 100%;
    border: 1px solid blue;
    width: 308px;
"></div><div
style="
    width: calc(100%-308px);
    height: 100%;
    border: 1px red solid;
"></div></div> -->

            <div
          v-if="copyOfMediaTableData.length > 0"
          class="media-pagination-container"
          style="height: 71px; z-index: 273"
        >
          <span class="row1"></span>
          <span class="row2" style="z-index: 1">
            <hlx-pagination
              v-model:rows-per-page="externalRowsPerPage"
              style="z-index: 1"
              :total="copyOfMediaTableData.length"
              enable-rows-per-page
              :rows-per-page-list="[10, 15, 20]"
              :options="{
                attributes: {
                  rowsPerPageTitle: true,
                  rowsPerPageResult: true,
                  rowsPerPageDropdown: {
                    position: {
                      top: 0,
                      right: 1,
                    },
                  },
                },
              }"
              @current-page="currentPage"
              @updated:rows-per-page="changeRowsPerPage"
            ></hlx-pagination
          ></span>
        </div>





  </div>

  <!-- <div v-if="ty pe == 'inta ngible'">
    <section class="me dia-search-row">
      <span class="hea der" style="margin-bottom: 0px !important">
        <h4>Me dia</h4>
      </span>
    </section>
    <section>
      <div
        class="uploader_div"
        style="padding-left: 200px; padding-right: 200px; padding-top: 28px"
      >
        <hlx-file-uplo ad
          :typ e="'slide-upload'"
          :width="'100px'"
          :height="'100px'"
          :position="'horizontal'"
          @native-data="dat aevennt"
          @default-image="defal tImg"
          @deleteImage="delete Image"
        />
      </div>
    </section>
  </div> -->
</template>

<script>
import {
  // DMNAxiosInstance,
  // MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
export default {
  props: {
    switchdata: {
      type: Array,
      required: true,
      default: () => [],
    },
    offerType: {
      type: Object
    },
    tableHeader: {
      type: Array,
      required: true,
      default: () => [],
    },
    tableData: {
      type: Array,
      required: true,
      default: () => [],
    },
    drophead: {
      type: Array,
      required: true,
      default: () => [],
    },
    dropvalue: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  emits: [
    "upload-data",
    "emithead",
    "emitvalue",
    
    "closedata",
    "imagedelete",
  ],
  data() {
    return {
      Initalpayload:{},
      combinationVal:'',
      GroupbyVal :'',
      allskudatas:'fdvadv asdcasdcad sdcsdcsDC sdcDSC DSCsc',
      allskudata:'',
      bundleheader:[
          {
          sortable: false,
          resizable: false,
          // width: 150,
          label: "Image",
          prop: "attachments",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          // width: 150,
          label: "SKU",
          prop: "All SKU",
          align: "left",
        },
          ],
      type:'tangible',
      offertypeAtomic:true,
      oldData:[],
      basetype1:'tangible',
      // basetype2:'tangible',

    enableButton: false,
    singleclick:0,
    enablemedia:true,
    mediaclick:0,
      newhead:[
      {
          sortable: false,
          resizable: false,
          // width: 150,
          label: "Image",
          prop: "attachments",
          align: "left",
        },
      ],
      backgrounStyleupload: {
        backgroundColor: "",
        bacgroundtTextColor: "grey",
        border: false,
        borderColor: "grey",
        TextfontSize: 16,
      },
      notification: {},
      propdata1: "",
      chosenvalue: "",
      fulltabledata: [],
      selectedSwitch: "",
      switchOptions: [
      ],
      uniquedata:[],
      searchvalue:"",
      onetimedata: [],
      rentaldata: [],
      configbox: {},
      index: "",
      indexid: "",
      applyall: "",
      dropvalue1: [],
      showDiv: true,
      componentkey: 0,
      previewImage: [],
      slider: [],
      form_image: [],
      editPayload: {},
      counts : false,
      checkboxChecked: false,
      editMode: false,
      show_upload_drawer: false,
      selectedButton: 2,
      updateComponentKey: 0,
      isModal: false,
      isNewDiscount: true,
      isContratualDiscount: false,
      isFooterDiscount: false,
      isDiscountIcons: true,
      isCustomerDiscount: false,
      isCardBasedDiscount: false,
      isProductDiscount: false,
      isPaymentMethodBasedDiscount: false,
      isRoleBasedDiscount: false,
      isRecurringDiscount: false,
      currPage: 1,
      rowsPerPage: 10,
      externalRowsPerPage: 10,
      preffedImage: "",
      offeringcurrPage: 1,
      offeringrowsPerPage: 10,
      delete_modal_active: false,
      deleterowindex: 0,
      delete_name: "",
      deleteobj: "",
      mediaTable: [],
      copyOfMediaTableData: [],
      copyOfMediaTableData1: [],
      deleteData: {},
      defaultData: {},
      deletecontion: false,
    };
  },
  computed: {
    tableDataOnSelectedRows() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.copyOfMediaTableData.slice(start, end);
    },

    tableDataOnSelectedRows1() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.copyOfMediaTableData1.slice(start, end);
    },
    sortedConfigbox() {
      // Create a copy of the configbox array
      const configboxCopy = [...this.configbox];
      // Sort the array with SKU first
      configboxCopy.sort((a, b) => {
        if (a.name.toLowerCase() === "sku") return -1;
        if (b.name.toLowerCase() === "sku") return 1;
        return 0;
      });
      console.log("--------------------11111111", configboxCopy);
      return configboxCopy;
    },
  },
  watch: {
    type: {
      handler(value) {
        console.log("type...media",value );
        

      },
      deep: true,
      immediate: true,
    },
    offerType:{
      handler(val){
        console.log("offerType...media",val);   
        if(val){
          if(val.data){
            if(val.data.offerType){
              if(val.data.offerType.toLowerCase() === "bundle"){
                this.offertypeAtomic = false;
                //table header
                if ((this.basetype1 === 'tangible')) {
                  this.newhead = this.bundleheader;
                } else {
                  this.newhead = [
      {
          sortable: false,
          resizable: false,
          // width: 150,
          label: "Image",
          prop: "attachments",
          align: "left",
        },
      ]
                }
                //for load all sku data
            
              console.log("val.data?.prodSpecCharValue",val.data?.prodSpecCharValue);
              // this.allskudata = '';

              if(this.selectedSwitch){
              const newset = val?.data?.prodSpecCharValue.filter(item=>{
                if(this.selectedSwitch?.toLowerCase() === item?.priceType?.toLowerCase()){
                  return true
                }else{
                  return false
                }
              });
              const newset1 = newset?.map(itm => itm?.SKU );
              this.allskudata = newset1?.join(', ');
              console.log("newset123...switch true",val?.data?.prodSpecCharValue,newset,newset1,this.selectedSwitch,this.allskudata);
            }
            
            else{
              const newset3 = val?.data?.prodSpecCharValue?.map(itm => itm?.SKU )?.join(', ');
              this.allskudata = newset3;
              console.log("newset123...","---switch false---",val?.data?.prodSpecCharValue,newset3,this.selectedSwitch,this.allskudata);
            }

// console.log("earrfgbea",this.selectedSwitch, );


            
              }else{
                this.offertypeAtomic = true;
              }
            }

            
          }
        }     
      },
      deep: true,
      immediate: true,
    },
    switchdata: {
      handler(value) {
        console.log( "switchdata...media",value,"----",this.tableHeader);
        this.dataPropty = value;
        if(value){
          value?.forEach((item)=>{
          
            if(item?.name?.toLowerCase()?.includes('recurring') || item?.name?.toLowerCase()?.includes('pay per use')){
              console.log("sdfg......");
              this.basetype1 = 'intangible'

              this.newhead = this.tableHeader?.filter(item => item?.prop.toLowerCase() !== 'sku');
console.log("switchdata...media",this.newhead);
            }else{
              this.basetype1 = 'tangible'

              this.newhead = this.tableHeader;
console.log(this.newhead);

            }
            
          })
          
        }
        
        this.switchOptions = value;
        this.selectedSwitch = value[0]?.name;
        
      },
      deep: true,
      immediate: true,
    },
    basetype1: {
      handler(value) {
        console.log(value, "basetype1");

      },
      deep: true,
      immediate: true,
    },

    newhead:{
      handler(val){
        console.log("newhead",val);
      },
      deep:true,
      immediate:true
    },
    
    enableButton: {
      handler(value) {
        console.log(value, "wertyq enableButton");
        this.enableButton = value;

        

      },
      deep: true,
      immediate: true,
    },
    
    singleclick: {
      handler(value) {
        console.log(value, "wertyq singleclick");
        this.singleclick = value;

        

      },
      deep: true,
      immediate: true,
    },
    
    mediaclick: {
      handler(value) {
        console.log(value, "mediaclick");
        this.mediaclick = value;

        

      },
      deep: true,
      immediate: true,
    },


    
    enablemedia: {
      handler(value) {
        console.log(value, "enablemedia");
        this.enablemedia = value;

        

      },
      deep: true,
      immediate: true,
    },
    
    tableHeader: {
      handler(value) {
        console.log("tableHeader...media",value,"----",this.basetype1);

        // if(value?.length>2){
          //atomic
          if(this.offertypeAtomic){

            if(value?.length>2){
          if(this.basetype1 === 'intangible'){

            this.newhead = value?.filter(item => item?.prop.toLowerCase() == "attachments");
console.log(this.newhead);

          }else if(this.basetype1 === 'tangible'){

              this.newhead = value;
console.log(this.newhead);

          }
        }
        }
        //bundle
          else {
          if(this.basetype1 === 'tangible'){
            this.newhead = this.bundleheader;
          }else{
            this.newhead = [
      {
          sortable: false,
          resizable: false,
          // width: 150,
          label: "Image",
          prop: "attachments",
          align: "left",
        },
      ]
          }          
        }


        // }
        
      },
      deep: true,
      immediate: true,
    },
    selectedSwitch: {
      handler(value) {
        this.componentkey += 1;
        this.closeuploaddrawer();
        console.log("newset123...selectedSwitch...media",value);
        
        console.log(
          value,
          "--------------selectedswitch is watching--------------"
        );
        // console.log(this.dropvalue1);
        const normalize = (str) =>
          str ? str.replace(/\s+/g, "").toLowerCase().trim() : "";
        console.log(normalize(value), "7777777777777777777777777777777777777");
        console.log(
          // normalize(value),
          "0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000"
        );
        if (normalize(value) === "onetime") {
          this.mediaTable = this.fulltabledata.filter(
            (item) => normalize(item.priceType) === "onetime"
          );
        } else if (normalize(value) === "rental") {
          this.mediaTable = this.fulltabledata.filter(
            (item) => normalize(item.priceType) === "rental"
          );
        } else if (normalize(value) === "installment") {
          this.mediaTable = this.fulltabledata.filter(
            (item) => normalize(item.priceType) === "installment"
          );
        } else if (normalize(value) === "recurring") {
          this.mediaTable = this.fulltabledata.filter(
            (item) => normalize(item.priceType) === "recurring"
          );
        } else if (normalize(value) === "payperuse") {
          this.mediaTable = this.fulltabledata.filter(
            (item) => normalize(item.priceType) === "payperuse"
          );
        }else{
          this.mediaTable = this.fulltabledata;
        }

        this.copyOfMediaTableData = JSON.parse(JSON.stringify(this.mediaTable));
        console.log("qwertyjhgfds",this.tableHeader);


        console.log("this.base type1",this.basetype1);
        
        //atomic
        if(this.offertypeAtomic){
          if(this.basetype1 === 'intangible'){
          console.log("'---------'",);

        if(this.copyOfMediaTableData){
          if(this.copyOfMediaTableData?.length>1){
            
            this.copyOfMediaTableData.length = 1;
            console.log("single row switch",this.copyOfMediaTableData);
          }
        }
      }else{
        console.log("not single row switch");
        
      }
        }
        //bundle
        else{
          if(this.copyOfMediaTableData){
            if(this.copyOfMediaTableData?.length>1){
              this.copyOfMediaTableData.length = 1;
            console.log("single row switch",this.copyOfMediaTableData);
            }
          }
        }
        


        console.log(
          "single section for watching.............",
          value,
          "is",
          this.copyOfMediaTableData
        );
        this.copyOfMediaTableData.forEach((singlemedia) => {
          if (singlemedia?.attachment) {
            singlemedia?.attachment.forEach((atarray) => {
              if (atarray.preferred === true) {
                UPCAxiosInstance.get(`/gcpupload/files/${atarray?.name}`, {
                  responseType: "arraybuffer",
                }).then((response) => {
                  console.log(response.data);
                  const uint8Array = new Uint8Array(response.data);
                  // console.log(uint8Array);
                  let binary = "";
                  uint8Array.forEach((byte) => {
                    binary += String.fromCharCode(byte);
                  });
                  const base64String = window.btoa(binary);
                  let profilePicUrl = `data:image/png;base64,${base64String}`;

                  singlemedia.src = profilePicUrl;
                });
              }
            });
          }
        });
        console.log("this.copyOfMediaTableData...new...inswitch",this.copyOfMediaTableData);
      },
      deep: true,
      immediate: true,
    },
    dropvalue: {
      handler(value) {
        this.dropvalue1 = value;
        console.log("dropvalue...media",value);
        console.log(this.dropvalue1);
      },
      deep: true,
      immediate: true,
    },

    copyOfMediaTableData: {
      handler(value) {
        
        console.log(value);
        console.log("watching copyOfMediaTableData",value);
      },
      deep: true,
      immediate: true,
    },
    editPayload: {
      handler(value) {
        
        console.log("editpayload is watching-----", value);
        
        const newarray = [];
        const propertiesToRemove = [
          "commitmentLength",
          "billingPeriod",
          "id",
          "_id",
          "priceType",
          "previewImagae",
          "src",
          "endOfCommitment",
          "attachment",
          "pricingType",
          "MSRP",
          "tiers",
          "context",
          "displayValue",
          'stock',
          'validFor',
          'lifecycleStatus',
          'totalPrice',
          'Inline Remote',
          'isDefault',
          'default'
        ];

        for (let key in value) {
          console.log(key + ": " + value[key]);
          newarray.push({ name: key, value: value[key] });
          
        }
        const filteredArray = newarray.filter(
          (obj) => !propertiesToRemove.includes(obj.name)
        );

        this.configbox = filteredArray;
        this.configbox = this.configbox.filter(item => item.value !== "");
        console.log(
          this.configbox,
          "------------------------------------------------------------"
        );

        console.log();

        
      },
      deep: true,
      immediate: true,
    },
    checkboxChecked: {
      handler(value) {
        
        console.log(value);
        if (value === false) {
          this.applyall = "";
        }
        
      },
      deep: true,
      immediate: true,
    },
    tableData: {
      handler(value) {
        this.componentkey += 1;
        this.closeuploaddrawer();
        
        this.fulltabledata = value;
        
        console.log( "tableData...media",value,"----"," is watching in media", this.fulltabledata);
        
        const normalize = (str) =>
          str ? str.replace(/\s+/g, "").toLowerCase().trim() : "";
        console.log(
          
          "1111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111"
        );
        if (normalize(this.selectedSwitch) === "onetime") {
          this.mediaTable = this.fulltabledata.filter(
            (item) => normalize(item.priceType) === "onetime"
          );
        } else if (normalize(this.selectedSwitch) === "rental") {
          this.mediaTable = this.fulltabledata.filter(
            (item) => normalize(item.priceType) === "rental"
          );
        } else if (normalize(this.selectedSwitch) === "installment") {
          this.mediaTable = this.fulltabledata.filter(
            (item) => normalize(item.priceType) === "installment"
          );
        } else if (normalize(this.selectedSwitch) === "recurring") {
          this.mediaTable = this.fulltabledata.filter(
            (item) => normalize(item.priceType) === "recurring"
          );
        } else if (normalize(this.selectedSwitch) === "pay per use") {
          this.mediaTable = this.fulltabledata.filter(
            (item) => normalize(item.priceType) === "pay per use"
          );
        }else{
          this.mediaTable = this.fulltabledata;
        }

        this.copyOfMediaTableData = JSON.parse(JSON.stringify(this.mediaTable));
        console.log(this.selectedSwitch,"qwertyjhgfds",this.tableHeader,this.mediaTable,this.fulltabledata);



        console.log("this.base type1",this.basetype1);
        
        //atomic
        if(this.offertypeAtomic){
        if(this.basetype1 === 'intangible'){
          console.log("this.base type1 === 'intangible'");
          
        if(this.copyOfMediaTableData){
          if(this.copyOfMediaTableData?.length>1){
            
            this.copyOfMediaTableData.length = 1;
            console.log("single row table",this.copyOfMediaTableData);
          }
        }
      }else{
        console.log("not single row table");
        
      }
    }
    //bundle
    else{
          if(this.copyOfMediaTableData){
            if(this.copyOfMediaTableData?.length>1){
              this.copyOfMediaTableData.length = 1;
            console.log("single row switch",this.copyOfMediaTableData);
            }
          }
        }



        console.log(
          "single section for mount.............",
          this.selectedSwitch,
          "is",
          this.copyOfMediaTableData
        );
        this.copyOfMediaTableData.forEach((singlemedia) => {
          if (singlemedia?.attachment) {
            singlemedia?.attachment.forEach((atarray) => {
              if (atarray.preferred === true) {
                UPCAxiosInstance.get(`/gcpupload/files/${atarray?.name}`, {
                  responseType: "arraybuffer",
                }).then((response) => {
                  console.log(response.data);
                  const uint8Array = new Uint8Array(response.data);
                  
                  let binary = "";
                  uint8Array.forEach((byte) => {
                    binary += String.fromCharCode(byte);
                  });
                  const base64String = window.btoa(binary);
                  let profilePicUrl = `data:image/png;base64,${base64String}`;

                  singlemedia.src = profilePicUrl;
                });
              }
            });
          }
        });
        
        
        console.log("this.copyOfMediaTableData...new...intabledata",this.oldData,this.copyOfMediaTableData);
        this.oldData = this.copyOfMediaTableData;
        console.log("this.oldData",this.oldData);

        console.log("watching tabledata in component", value);
      },
      deep: true,
      immediate: true,
    },
    drophead: {
      handler(value) {
        console.log( "drophead...media",value);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    
  },
  methods: {
    pricingSearchTrigger(val) {
      this.searchvalue =val
      console.log("onsearch value", val);
      if (val) {
        
        const result = this.searchInArray(val, this.copyOfMediaTableData);
        console.log("🚀 ~ mediaSearchTrigger ~ result:", result);
        this.copyOfMediaTableData = result;
      } else {
        
        console.log("🚀 ~ mediaSearchTrigger ~ this.search:");
        this.copyOfMediaTableData = this.oldData;
      }
    },

    searchInArray(searchTerm, dataArray) {
      const searchTermLower = searchTerm?.toLowerCase();

      const result = dataArray.filter((obj) => {
        return Object.values(obj).some((value) => {
          if (typeof value === "string") {
            return value?.toLowerCase().includes(searchTermLower);
          }
          return false;
        });
      });

      return result;
    },

    deleteExecRow() {
      this.deleteobj.attachment = [];

      
      this.delete_modal_active = false;
      if (this.deleteobj.attachment.length === 0) {
        console.log("delete all images is working...............");
        this.$emit("imagedelete", this.deleteobj.id);
      }

      this.closeuploaddrawer();
    },
    crudContextMenu1() {
      console.log("done");
    },
    chosenSwitch(val) {
      console.log(
        val,
        "val++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++"
      );
      this.chosenvalue = val.name;
      
    },
    
    emithead(val) {
      this.GroupbyVal =val.name
      this.$emit("emithead", val.name);
      console.log(val.name, "emithead");
      
      // console.log(val, "emitheadasif");
    },
    emitvalue(val) {
      this.combinationVal=val.name
      this.$emit("emitvalue", val.name);
      console.log(val.name, "emitvalue");
      if (!isNaN(parseFloat(val.name))) {
        console.log("it is number");
        this.applyall = val.name + "";
      } else {
        console.log("it is not a number");
        this.applyall = val.name;
      }

      console.log(this.applyall);
    },
    defaltImg(val) {
      console.log(val, "defaltImg");
      this.defaultData = val;
    },
    async deleteImage(val) {
      if (val) {
        this.counts = true;
        this.deletecontion = true;

        
        

        if (
          this.editPayload.attachment &&
          this.editPayload.attachment.length > val.indexVal
        ) {
          console.log("Deleting at index:", val.indexVal);
          this.editPayload.attachment.splice(val.indexVal, 1);
        } else {
          console.log(
            val.indexVal,
            this.editPayload?.attachment?.length,
            "donebro"
          );
          const findlength =
            val.indexVal - this.editPayload?.attachment?.length;
          this.form_image.splice(findlength, 1);
          console.log(this.form_image, "findlength");
          console.log(
            "Index out of bounds:",
            val.indexVal,
            "Length of attachment array:",
            this.editPayload?.attachment?.length,
            "findlength",
            findlength
          );
        }
        console.log(this.editPayload, "payloaddelete");
      }
    },
    dataevent(val) {
      const emitdata = val.target.files;
      console.log("addey farhan", emitdata, "--i--", val);

      if (emitdata.length > 0) {




for(let j=0;j<emitdata.length;j++){
  let single = emitdata[j];
 
  const uniqueName = this.generateUniqueNumber().toString() + "---" + single.name.substring(0, 80);
  
     single = new File([single], uniqueName, { type: single.type });
     this.uniquedata.push(single);
  
}

console.log("qwefb",this.uniquedata);

console.log("form log start ..",this.form_image);
        for (let i = 0; i < this.uniquedata.length; i++) {
          let singleData = this.uniquedata[i];

          
          if (!singleData.type.startsWith("image/")) {
            console.log("addey singleData",singleData);
            continue;
          }

          
          let isDuplicate = this.editPayload?.attachment?.some(
            (item) => item.name === singleData.name
          );
          console.log(isDuplicate);
          if (isDuplicate) {
             
            console.log("addey Duplicate found:", singleData.name);
                  
        const uniqueName = this.generateUniqueNumber().toString() + "---" + singleData.name;
        
        
        singleData = new File([singleData], uniqueName, { type: singleData.type });

          }

          this.form_image.push(singleData);
          console.log("form log on ..",this.form_image);
        }
        this.uniquedata = [];

  console.log("Updated form_image after removing duplicates by name:", this.form_image);


        console.log("addey usman", this.form_image);
      }
    },
     generateUniqueNumber() {
  return Date.now() + Math.floor(Math.random() * 10000);
}

,
    addImage() {
      this.counts = false;
      this.enableButton = false;
      
      const formdata = new FormData();
      
      console.log(this.form_image, "findlength");

      for (let i = 0; this.form_image.length > i; i++) {
        formdata.append("file[]", this.form_image[i]);
      }
      console.log("formdataasif", formdata);
      console.log(
        "111111111111111111111111111111111111111111111111111111111111111111111111111",
        formdata,
        this.deleteData,
        this.editPayload.attachment,
        this.defaultData,
        this.index,
        this.indexid
      );
      this.$emit(
        "upload-data",
        formdata,
        this.editPayload,
        this.deleteData,
        this.defaultData,
        this.applyall,
        this.index,
        this.indexid,
        this.combinationVal,
        this.GroupbyVal,
        this.Initalpayload
      );

      setTimeout(() => {
        this.closeuploaddrawer();
      }, 1000);
      


      
    },
    deletefunction() {
      
    },

    closeuploaddrawer() {
      if(this.counts){
        this.counts = false;
        
      this.copyOfMediaTableData = JSON.parse(JSON.stringify(this.mediaTable));
      this.copyOfMediaTableData.forEach((singlemedia) => {
          if (singlemedia?.attachment) {
            singlemedia?.attachment.forEach((atarray) => {
              if (atarray.preferred === true) {
                UPCAxiosInstance.get(`/gcpupload/files/${atarray?.name}`, {
                  responseType: "arraybuffer",
                }).then((response) => {
                  console.log(response.data);
                  const uint8Array = new Uint8Array(response.data);
                  // console.log(uint8Array);
                  let binary = "";
                  uint8Array.forEach((byte) => {
                    binary += String.fromCharCode(byte);
                  });
                  const base64String = window.btoa(binary);
                  let profilePicUrl = `data:image/png;base64,${base64String}`;

                  singlemedia.src = profilePicUrl;
                });
              }
            });
          }
        });
      
      console.log("this.copyOfMediaTableData",this.copyOfMediaTableData);
    }

      
      
      this.singleclick = 0;
      
      this.mediaclick = 0;

      this.componentkey += 1;
      this.editMode = false;
      this.form_image = [];
      this.uniquedata = [];
      this.previewImage = [];
      this.defaultData = {};
     
      (this.slider = []), (this.deleteData = {});
      this.$emit("closedata", this.index, this.mediaTable);
      this.enablemedia = true;
      //
      this.show_upload_drawer = false;
    },
    async bufferToDataURL(buffer) {
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = "";
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },

    async convertImg(val) {
      const url = await UPCAxiosInstance.get(`/gcpupload/files/${val?.name}`, {
        responseType: "arraybuffer",
      });
      console.log(await this.bufferToDataURL(url.data));
      
      return await this.bufferToDataURL(url.data);
    },
    async openuploaddrawers(i, index) {

      

      this.enableButton = false;
      
      this.enablemedia = false;


      this.editPayload = i;
      this.index = index;

      this.indexid = this.editPayload.id;
      console.log(index, "index", this.indexid);
      
      console.log(this.editPayload, index, "editPayload");
      setTimeout(() => {
        this.show_upload_drawer = true;  
      }, 500);
      
      this.checkboxChecked = false;
      if (this.editPayload.attachment) {
        let c = 0;
        for (let j = 0; j < i.attachment.length; j++) {
          const item = i.attachment[j];
          const url = await UPCAxiosInstance.get(
            `/gcpupload/files/${item?.name}`,
            { responseType: "arraybuffer" }
          );
          console.log("url", url.data);
          const obj = {
            data: await this.bufferToDataURL(url.data),
            name: item?.name,
          };
          
          this.previewImage.push(obj);
          
          c = c + 1;
          // console.log(item,"itemlog");
          if (item.preferred === true) {
            console.log(item, "preffedImage");
            this.preffedImage = item.name;
          }

          console.log("c:", c, "single", this.previewImage, this.editPayload);
        }
        console.log(
          "this.previewImage",
          this.previewImage,
          
          this.editPayload,
          "10101010101010101010101010101010101010101"
        );

        
      }
      
      this.enableButton = true;
    },
    serialNumberAlter(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },

    currentPage(val) {
      this.currPage = val;
    },
    changeRowsPerPage(count) {
      this.rowsPerPage = count;
    },
    selectButton(buttonIndex) {
      this.selectedButton = buttonIndex;
      this.isNewDiscount = buttonIndex === 2;
      this.isContratualDiscount = buttonIndex === 1;
    },

    goToPreviousDrawer() {
      this.updateComponentKey += 1;
      this.isDiscountIcons = true;
      this.isProductDiscount = false;
      this.isCardBasedDiscount = false;
      this.isCustomerDiscount = false;
      this.isRoleBasedDiscount = false;
      this.isRecurringDiscount = false;
      this.isPaymentMethodBasedDiscount = false;
    },
    closeCrudContextMenu($event, ele, i) {
      if ($event.toLowerCase() === "edit") {
        const payload = JSON.parse(
          JSON.stringify(this.copyOfMediaTableData[i])
        );
        this.Initalpayload = JSON.parse(
          JSON.stringify(this.copyOfMediaTableData[i])
        );

        console.log(this.Initalpayload.attachment);
        
        this.openuploaddrawers(payload, i);
        
        console.log(payload, i);
      } else if ($event.toLowerCase() === "delete") {
        this.delete_modal_active = true;
        this.deleteobj = ele;
        this.delete_name = ele.name;
        this.deleterowindex = this.serialNumber(i);
      }
    },
    crudContextMenu($event, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.copyOfMediaTableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
        this.copyOfMediaTableData[index].context = true;
      } else {
        this.copyOfMediaTableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    closeModal() {
      this.isModal = false;
    },
    serialNumber(index) {
      return (this.offeringcurrPage - 1) * this.offeringrowsPerPage + index + 1;
    },

    computedTableAction(data) {
      let options = [
        {
          label: "Edit",
          icon: "icon-file-text-edit-regular",
        },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ];
      return options.filter((option) => {
        if (
          data &&
          data.status &&
          data.status.toLowerCase() == "draft" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "active" &&
          ["Draft", "Mark as active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "inactive" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else {
          return true;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.media-search-row {
  display: flex;
  width: 100%;
  gap: 0.875rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.media-pagination-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  background-color: none;
  display: flex;
  
  .row1 {
    display: inline-block;
    width: 427px;
    position: relative;
    background: none;
    height: 61px;

  }
  @media (max-width: 768px) {
  .row1 {
    width: 97px;
  }
}
  .row2 {
    display: flex;
    width: 100%;
    background: white;
    height: 74px;
    justify-content: center;
    align-items: center;
  }
}
.product-detail-media {
  background: rgba(217, 217, 217, 0.2);
  padding: 5px;
  border-radius: 6px;
  font-size: 14px;
  margin-bottom: 20px;
  .value-data-media {
    display: flex;
    padding: 12px;
    .value-data-media_left {
      width: 102%;
    }
    .value-data-media_right {
      width: 80%;
    }
  }
}
</style>
