<template>
  <!-- Link modal -->
  <hlx-modal
      :text-editor="modal_active"
      :height="'216px'"
      :width="'400px'"
      :modal="true"
      @close="modal_active = false"
    >
      <template #header>
        <div class="texteditor-head-title">Link</div>
      </template>
      <template #content>
        <div class="modal-content">
          <button class="url-link">
            <hlx-input id="link-input" v-model:value="url" type="text" class="link-input" />
          </button>
        </div>
      </template>
      <template #footer>
        <button class="link-save-button" @click="link">Save</button>
      </template>
    </hlx-modal>
            <!-- Dynamic Table Modal -->
            <hlx-modal
            :modal-active="isModalOpen"
            :height="'456px'"
            :width="'545px'"
            :modal="true"
            @close="isModalOpen = false"
          >
            <template #header>
              <h2 style="font-size: 16px">Create Table</h2>
            </template>
            <template #content>
              <div style="padding: 10px">
                <div class="modal-content123">
                  <div
                    style="display: flex; align-items: center; padding: 10px"
                  >
                    <label for="rows" style="width: 30%">Rows:</label>
                    <span style="width: 70%">
                      <hlx-input
                        id="rows"
                        v-model:value="rowsArray"
                        :pre-val="rowsArray"
                        type="number"
                        @focus-out="rowArrayfocus"
                      />
                    </span>
                  </div>
                  <div
                    style="display: flex; align-items: center; padding: 10px"
                  >
                    <label for="columns" style="width: 30%">Columns:</label>
                    <span style="width: 70%">
                      <hlx-input
                        id="columns"
                        v-model:value="columnsArray"
                        :pre-val="columnsArray"
                        type="number"
                        @focus-out="columnsArrayfocus"
                      />
                    </span>
                  </div>
                </div>
                <div style="display: flex; align-items: center; padding: 10px">
                  <label for="headerJson" style="width: 30%">Header:</label>
                  <span style="width: 70%">
                    <hlx-input
                      id="headerJson"
                      v-model:value="headerJson"
                      :pre-val="headerJson"
                      type="textarea"
                      placeholder='Enter JSON (e.g., [{"label":"Name","prop":"name"},{"label":"Account","prop":"account"}])'
                      rows="5"
                    ></hlx-input>
                  </span>
                </div>
                <div style="display: flex; align-items: center; padding: 10px">
                  <label for="dataVariable" style="width: 30%"
                    >Data define:</label
                  >
                  <span style="width: 70%">
                    <hlx-input
                      id="columns"
                      v-model:value="dataVariable"
                      :pre-val ="dataVariable"
                      style="width: 70%"
                      type="text"
                    />
                  </span>
                </div>
              </div>
            </template>
            <template #footer>
              <hlx-button class="primary sm" @click="applyHeadersAndCreateTable"
                >Save</hlx-button
              >
            </template>
          </hlx-modal>

          <!-- Static table modal -->
          <hlx-modal
            :modal-active="ismodal"
            :height="'265px'"
            :width="'330px'"
            :modal="true"
            @close="ismodal = false"
          >
            <template #header>
              <h2 style="font-size: 16px">Create Table</h2>
            </template>
            <template #content>
              <div class="modal-content123" style="padding: 10px">
                <div style="display: flex; align-items: center; padding: 10px">
                  <label for="rows" style="width: 30%">Rows:</label>
                  <span style="width: 70%">
                    <hlx-input
                      id="rows"
                      v-model:value="tableRows"
                      :pre-val="tableRows"
                      type="number"
                      @focus-out="rowArrayfocus"
                    />
                  </span>
                </div>
                <div style="display: flex; align-items: center; padding: 10px">
                  <label for="columns" style="width: 30%">Columns:</label>
                  <span style="width: 70%">
                    <hlx-input
                      id="columns"
                      v-model:value="tableColumns"
                      :pre-val="tableColumns"
                      type="number"
                      @focus-out="columnsArrayfocus"
                    />
                  </span>
                </div>
              </div>
            </template>
            <template #footer>
              <hlx-button class="primary sm" @click="applyTable"
                >Save</hlx-button
              >
            </template>
          </hlx-modal>

          <!-- Dynamic content -->
          <hlx-modal
            :modal-active="drawerOpen"
            :height="'226px'"
            :width="'480px'"
            :modal="true"
            @close="drawerOpen = false"
          >
            <template #header>
              <div>Type dynamic variable</div>
            </template>
            <template #content>
              <div style="padding: 10px">
                <hlx-input
                  v-model:value="dynamicVariable"
                  :label-value="'Type dynamic variable (e.g., obj.name)'"
                />
              </div>
            </template>
            <template #footer>
              <hlx-button class="primary sm" @click="applyDynamicVariable"
                >Apply</hlx-button
              >
            </template>
          </hlx-modal>


  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <section class="emailContainer">
    <section class="left-side-panel-email-container">
      <div class="back-breadcrumb1">
        <hlx-breadcrumb :items="items" :separator="separator" @path="pathTo" />
      </div>

      <div class="value-container">
        <hlx-input
          v-model:value="templateName"
          :pre-val="templateName"
          :label-animation="true"
          label-value="Name"
          type="text"
          :clearable="true"
        >
        </hlx-input>
      </div>
      <!-- <div class="value-container">
        <hlx-status
                :type="'select-status'"
                :status-option="filteredstatus"
                :prevalue="templateStatus"
                
                @emit-select="emitStatus1"
              >
              </hlx-status>
      </div> -->
      <div class="value-container">
        <hlx-input
                :key="ticketComponetD"
                v-model:value="templatedescription"
          :pre-val="templatedescription"

                :label-animation="true"
                label-value="Description"
                type="textarea"
                :rows="5"
                :clearable="true"
              ></hlx-input>
      </div>
      <div class="value-container">
        <!-- <hlx-input
          v-model:value="tagValue"
          :label-animation="true"
          label-value="Tag"
          type="text"
          :clearable="true"
        >
        </hlx-input> -->

        <hlx-multi-select
                  :key="tagcomponetkey"
                  :pre-value="tagValue"
                  :options="tagsList"
                  prop-value="name"
                  :multi-select="true"
                  :placeholder="'Tags'"
                  :disabled="defalt"
                  :tooltipposition="'left'"
                  :label-animation="true"
                  :clearable="true"
                  @add-data="addTagList"
                  @selected-array="receivedTags"
                ></hlx-multi-select>
      </div>
     

      <div class="value-container-heading">
        <b>Text & media</b>
      </div>

      <!-- <div class="drag-elements" @click="addOuterContainer">
        <p class="drag-elements-name">Heading</p>
      </div> -->

      <div
        draggable="true"
        class="drag-elements"
        @dragstart="handleButtonDragStart('newEditable')"
      >
        <p class="drag-elements-name">Text</p>
      </div>

      <div
        draggable="true"
        class="drag-elements"
        @dragstart="handleButtonDragStart('newUpload')"
      >
        <p class="drag-elements-name">Image</p>
      </div>

      <div class="value-container-heading">
        <b>Table & Dynamic content</b>
      </div>

      <div
        class="drag-elements"
        draggable="true"
        style="margin-left: 10px"
        @dragstart="handleButtonDragStart('newTable')"
      >
        <p class="drag-elements-name">Table</p>
      </div>

      <div
        class="drag-elements"
        draggable="true"
        style="margin-left: 10px"
        @dragstart="handleButtonDragStart('dynamicTable')"
      >
        <p class="drag-elements-name">Dynamic table</p>
      </div>

      <button
        class="drag-elements"
        style="
          margin-left: 10px;
          display: flex;
          height: 36px;
          justify-content: flex-start;
          color: #a6a6a6;
          align-items: center;
          padding-left: 6px;
          font-family: openSans;
        "
        @click="openDynamicContent"
      >
        Dynamic text
      </button>

      <div class="value-container-heading">
        <b>Layouts</b>
      </div>

      <div
        class="drag-elements"
        draggable="true"
        style="margin-left: 10px"
        @dragstart="handleButtonDragStart('layout1')"
      >
        <p class="drag-elements-name">Layout 1</p>
      </div>

      <div
        class="drag-elements"
        draggable="true"
        style="margin-left: 10px"
        @dragstart="handleButtonDragStart('layout2')"
      >
        <p class="drag-elements-name">Layout 2</p>
      </div>
    </section>
    <section class="right-side-panel-email-container">
      <div class="right-side-panel-email-container-header">
        <section class="left-side-header">
          <p class="title">Create your template</p>
          <p class="sub-title">
            Just drag and drop element from your email template.
          </p>
        </section>

        <section class="right-side-header">
          <div class="right-side-header-language">
            <!-- <hlx-button class="primary" @click="previewfunc">preview</hlx-button> -->
            <p class="title">Language:</p>
            <span class="dropdown">
              <hlx-select
                :options="languageOptions"
                :single-border="true"
                :label="'name'"
                :prop-value="'name'"
              ></hlx-select>
            </span>
          </div>
          <div style="margin: 0 10px">
            <hlx-button class="primary sm" @click="saveTemplate"
              >Save</hlx-button
            >
          </div>
          <span
            class="divMinus2"
            :style="{ color: '#54bd95' }"
            style="font-size: 32px; margin: 0 10px"
            data-tooltip="Preview"
            data-tooltip-location="left"
            @click="previewfunc"
            ><i class="icon-eye-filled"></i
          ></span>
        </section>
      </div>
      <div class="right-side-panel-email-container-drag-area">
          <div id="content" :class="'content' + unique">
            <div class="icon-container">
              <button
                id="undo"
                class="functionality icon1"
                @click="executeCommand('undo')"
              >
                <i class="icon-arrow-back-regular"></i>
              </button>
              <button
                id="redo"
                class="functionality icon1"
                @click="executeCommand('redo')"
              >
                <i class="icon-arrow-forward-regular"></i>
              </button>

              <span class="font-name-functionality icon1">
                <hlx-select
                  :options="fontName"
                  :label-animation="false"
                  :text-editor="true"
                  @custom-change="selectFontName"
                ></hlx-select>
              </span>
              <span class="font-size-functionality icon1">
                <hlx-select
                  :options="fontSize"
                  :label-animation="false"
                  :text-editor="true"
                  @custom-change="selectFontSize"
                ></hlx-select>
              </span>

              <span class="highligtfunctionality icon1">
                <hlx-color-palette
                  @custom-change="highlightValue"
                ></hlx-color-palette>
              </span>
              <span class="colorfunctionality icon1">
                <hlx-color-palette
                  :highlight-color="true"
                  @custom-change="colorValue"
                ></hlx-color-palette>
              </span>

              <span class="colorfunctionality icon1">
                <HlxSpecialCharacters
                @custom-character="specialCharacter"
                >
                </HlxSpecialCharacters>
              </span>

              <button
                id="b"
                class="functionality icon1"
                @click="executeCommand('bold')"
              >
                B
              </button>
              <button
                id="i"
                class="functionality icon1"
                @click="executeCommand('italic')"
              >
                I
              </button>
              <button
                id="u"
                class="functionality icon1"
                style="text-decoration: underline"
                @click="executeCommand('underline')"
              >
                U
              </button>
              <button
                id="s"
                class="functionality icon1"
                style="text-decoration: line-through"
                @click="executeCommand('strikethrough')"
              >
                S
              </button>

              <button
                id="ul"
                class="functionality icon1"
                @click="executeCommand('insertUnorderedList')"
              >
                <i class="icon-list-regular"></i>
              </button>
              <button
                id="ol"
                class="functionality icon1"
                @click="executeCommand('insertOrderedList')"
              >
                <i class="icon-list-filled"></i>
              </button>

              <button
                id="justifyLeft"
                class="functionality icon1"
                @click="executeCommand('justifyLeft')"
              >
                <i class="icon-menu-left-alt-regular"></i>
              </button>
              <button
                id="justifyCenter"
                class="functionality icon1"
                @click="executeCommand('justifyCenter')"
              >
                <i class="icon-menu-regular"></i>
              </button>
              <button
                id="justifyRight"
                class="functionality icon1"
                @click="executeCommand('justifyRight')"
              >
                <i class="icon-menu-right-alt-regular"></i>
              </button>
              <button
                id="justifyFull"
                class="functionality icon1"
                @click="executeCommand('justifyFull')"
              >
                <i class="icon-menu-regular"></i>
              </button>

              <button
                id="sup"
                class="functionality icon1"
                @click="executeCommand('superscript')"
              >
                X<sup>2</sup>
              </button>
              <button
                id="sub"
                class="functionality icon1"
                @click="executeCommand('subscript')"
              >
                X<sub>2</sub>
              </button>

              <button id="link" class="functionality icon" @click="createLink">
                <i class="icon-link-alt-regular"></i>
              </button>

              <!-- <div :id="'context-menu'+unique" class="context-menu">
       <button class="context-menu-button"><i :id="'icon-more-vertical-filled'+unique" class="icon-more-vertical-filled" @click="displayContextmenu('icon-more-vertical-filled'+unique)"></i></button>
       <div v-if="showContextmenu" :id="'display-context-menu'+unique" class="display-context-menu" :style="{ top:containertop+'px', left:(containerleft-200) +'px'}">
         <span v-for="(i,index) in contextmenuOptions"  :key="index"  class="context-menu-value-container">
             <span class="context-menu-each-value" :style="'display:flex;align-items;center'" @click="applyValues(i)">
               <i v-if="i?.icon" :class="i?.icon" style="width:30px;cursor:pointer"></i>
               <p v-else-if="i?.id == 'bold'" :class="i.checked == true ? 'context-select-value' : ''" style="width:30px;font-weight:bold;cursor:pointer">B</p>
               <p v-else-if="i?.id == 'italic'" :class="i.checked == true ? 'context-select-value' : ''" style="width:30px;font-style: italic;cursor:pointer">I</p>
               <p v-else-if="i?.id == 'underline'" :class="i.checked == true ? 'context-select-value' : ''" style="width:30px;text-decoration:underline;cursor:pointer">U</p>
               <p v-else-if="i?.id == 'strikethrough'" :class="i.checked == true ? 'context-select-value' : ''" style="width:30px;text-decoration: line-through;cursor:pointer">S</p>
               <p v-else-if="i?.id == 'superscript'" :class="i.checked == true ? 'context-select-value' : ''" style="width:30px;cursor:pointer"> X<sup>2</sup></p>
               <p v-else-if="i?.id == 'subscript'" :class="i.checked == true ? 'context-select-value' : ''" style="width:30px;cursor:pointer"> X<sub>2</sub></p>

               <p style="padding-left:8px;cursor:pointer">{{i?.value}}</p>
             </span>
         </span>
       </div>
   </div> -->
            </div>
            <span
              v-if="close == true"
              style="
                display: flex;
                width: 45px;
                height: 35px;
                background: white;
                align-items: center;
                text-align: right;
                justify-content: center;
                cursor: pointer;
              "
              ><i class="icon-times-regular" @click="closeIcon"></i
            ></span>
          </div>
          <!-- Display each outer container with a drop area -->
          <section
            class="outer-container1"
            style="flex:1"
            @dragover.prevent
            @drop.stop
            @dragover="
              (item) => {
                console.log(item, 'itemm');
              }
            "
            @dragenter="handleContainerOver(containerIndex)"
            @drop="handleContainerDrop"
            @click="focusContainer(containerIndex)"
          >
            <draggable
              v-model="containerV1"
              ::disabled="isResizing"
              :group="{ name: 'itemsG' }"
              :item-key="id"
              @start="draggableonDragStart"
            >
              <template #item="{ element: container, index: containerIndex }">
                <div
                :id="'focusElements'+containerIndex"
                 class="drag-area" style="padding: 10px;position:relative; margin: 10px 10px;border:1px solid #ddd;cursor:move;"
                     @focus="handleFocus(containerIndex)"
                    
                 >
                <span
:id="'edit-delete-placeholder' + containerIndex"  class="edit-delete-placeholder" style="position: absolute;left:300px;background: #001723;color:white;left: -27px;
    height: 30px;width:27px;    justify-content: center;
    align-items: center;">
                  <span>
                    <i class="icon-trash-regular" @click="deleteElements(container)"></i>
                  </span>
                </span>
                  <!-- Upload -->
                  <div
                    v-if="container.type == 'newUpload'"
                    :key="'editable-' + index + '-' + containerIndex"
                    contenteditable="true"
                    style="padding: 10px"
                    @focus="handleFocus(containerIndex)"
                    
                  >
                    <span v-if="container['showUploadButton'] == true">
                      <input
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        @change="
                          handleImageUpload($event, containerIndex)
                        "
                      />
                    </span>
                    <img
                      v-if="container.imageUrl"
                      :src="container.imageUrl"
                      alt="Uploaded Image"
                      style="max-width: 20%"
                    />
                  </div>

                  <!-- Layout1 -->
                  <div 
                    v-if="container.type == 'layout1'"
                    :key="'editable-' + index + '-' + containerIndex"
                    class="resizable-layout"
                    @focus="handleFocus(containerIndex)"
                    
                  >
                    <!-- First contenteditable -->
                    <div
                     v-if="container.uploads[0] == true || container.imageUrl[0] != null"
                      class="contenteditable layout1"
                      contenteditable="true"
                      @dragover.prevent
                      @drop="
                        handleDropInside(
                          'layout1',
                          containerIndex,
                          0,
                          $event
                        )
                      "
                    @focus="handleFocus(containerIndex)"
                    
                    >
                      <!-- {{ container.editable[0] }} -->
                      <!-- Upload button if present -->
                      <!-- {{ container.uploads }} -->
                      <div  v-if="container.uploads[0] == true">
                        <input
                          type="file"
                          @change="
                            handleImageUpload1(
                              'layout1',
                              containerIndex,
                              0,
                              $event
                            )
                          "
                        />
                      </div>
                      <img
                        v-if="container.imageUrl[0]"
                        :src="container.imageUrl[0]"
                        alt="Uploaded Image"
                        style="max-width: 35%"
                      />
                    </div>

                    <div
                     v-if="container.uploads[0] == false && container.imageUrl[0] == null"
                      class="contenteditable layout1"
                      contenteditable="true"
                      :innerHTML="container.editable[0]"
                      @dragover.prevent
                      @input="
                        firstContenteditabledata(
                          containerIndex,
                          0,
                          $event
                        )
                      "
                      @drop="
                        handleDropInside('layout1', containerIndex, 0,$event)
                      "
                     @focus="handleFocus(containerIndex)"
                    
                    >
                      <!-- {{ container.editable[0] }} -->

                 
                    </div>

                    <!-- Separator -->
                    <div
                      class="separator"
                      draggable="false"
                      :style="{ display: preview == false ? 'block' : 'none' }"
                      @mousedown="startResize"
                    ></div>

                    <!-- Second contenteditable -->
                    <div
                     v-if="container.uploads[1] == true || container.imageUrl[1] != null"
                      class="contenteditable layout1"
                      contenteditable="true"
                      @dragover.prevent
                      @drop="
                        handleDropInside(
                          'layout1',
                          containerIndex,
                          1,
                          $event
                        )
                      "
                    @focus="handleFocus(containerIndex)"
                    
                    >
                      <!-- {{ container.editable[0] }} -->
                      <!-- Upload button if present -->
                      <!-- {{ container.uploads }} -->
                      <div  v-if="container.uploads[1] == true">
                        <input
                          type="file"
                          @change="
                            handleImageUpload1(
                              'layout1',
                              containerIndex,
                              1,
                              $event
                            )
                          "
                        />
                      </div>
                      <img
                        v-if="container.imageUrl[1]"
                        :src="container.imageUrl[1]"
                        alt="Uploaded Image"
                        style="max-width: 35%"
                      />
                    </div>

                    <div
                     v-if="container.uploads[1] == false && container.imageUrl[1] == null"
                      class="contenteditable layout1"
                      contenteditable="true"
                      :innerHTML="container.editable[1]"
                      @dragover.prevent
                      @input="
                        firstContenteditabledata(
                          containerIndex,
                          1,
                          $event
                        )
                      "
                      @drop="
                        handleDropInside('layout1', containerIndex, 1,$event)
                      "
                     @focus="handleFocus(containerIndex)"
                    
                    >
                      <!-- {{ container.editable[1] }} -->

                 
                    </div>
                  </div>

                  <!-- Editable -->
                  <div
                    v-if="container.type == 'newEditable'"
                    :ref="'contentEditable' + index"
                    :class="'contentEditable' + index"
                    :contenteditable="container.isEditable"
                    :innerHTML="container.content"
                    style="
                      min-height: 50px;cursor:auto;
                    "
                    @mouseup="insertPlaceholder"
                    @keyup="insertPlaceholder"
                   
                    @input="inputType(containerIndex, $event)" 
                    @focus="handleFocus(containerIndex)"
                    
                    >
                    <!-- {{ container }} -->
                    <!-- @blur="saveChanges(containerIndex, index)" -->
                    <!-- <input type="file" @change="handleImageUpload($event, containerIndex, index)" /> -->
                    <!-- {{ container.content }} -->
                    <!-- Display upload button only if the container has one -->
                    <!-- <input type="file"> -->
                    <div v-if="container.showUploadButton == true">
                      <input type="file" />
                    </div>
                    <img
                      v-if="container.imageUrl"
                      :src="container.imageUrl"
                      alt="Uploaded Image"
                      style="max-width: 20%"
                    />
                  </div>

                  <!-- Layout2 -->
                  <div 
                    v-if="container.type == 'layout2'"
                    :key="'editable-' + index + '-' + containerIndex"
                    class="resizable-layout"
                    @focus="handleFocus(containerIndex)"
                    
                  >
                    <!-- First contenteditable -->
                    <div
                     v-if="container.uploads[0] == true || container.imageUrl[0] != null"
                      class="contenteditable layout1"
                      contenteditable="true"
                      @dragover.prevent
                      @drop="
                        handleDropInside(
                          'layout1',
                          containerIndex,
                          0,
                          $event
                        )
                      "
                    @focus="handleFocus(containerIndex)"
                    
                    >
                      <!-- {{ container.editable[0] }} -->
                      <!-- Upload button if present -->
                      <!-- {{ container.uploads }} -->
                      <div  v-if="container.uploads[0] == true">
                        <input
                          type="file"
                          @change="
                            handleImageUpload1(
                              'layout1',
                              containerIndex,
                              0,
                              $event
                            )
                          "
                        />
                      </div>
                      <img
                        v-if="container.imageUrl[0]"
                        :src="container.imageUrl[0]"
                        alt="Uploaded Image"
                        style="max-width: 35%"
                      />
                    </div>

                    <div
                     v-if="container.uploads[0] == false && container.imageUrl[0] == null"
                      class="contenteditable layout1"
                      contenteditable="true"
                      :innerHTML="container.editable[0]"
                      @dragover.prevent
                      @input="
                        firstContenteditabledata(
                          containerIndex,
                          0,
                          $event
                        )
                      "
                      @drop="
                        handleDropInside('layout1', containerIndex, 0,$event)
                      "
                     @focus="handleFocus(containerIndex)"
                    
                    >
                      <!-- {{ container.editable[0] }} -->

                 
                    </div>

                    <!-- Separator -->
                    <div
                      class="separator"
                      :style="{ display: preview == false ? 'block' : 'none' }"
                      @mousedown="startResize"
                    ></div>

                    <!-- Second contenteditable -->
                    <div
                     v-if="container.uploads[1] == true || container.imageUrl[1] != null"
                      class="contenteditable layout1"
                      contenteditable="true"
                      @dragover.prevent
                      @drop="
                        handleDropInside(
                          'layout1',
                          containerIndex,
                          1,
                          $event
                        )
                      "
                    @focus="handleFocus(containerIndex)"
                    
                    >
                      <!-- {{ container.editable[0] }} -->
                      <!-- Upload button if present -->
                      <!-- {{ container.uploads }} -->
                      <div  v-if="container.uploads[1] == true">
                        <input
                          type="file"
                          @change="
                            handleImageUpload1(
                              'layout1',
                              containerIndex,
                              1,
                              $event
                            )
                          "
                        />
                      </div>
                      <img
                        v-if="container.imageUrl[1]"
                        :src="container.imageUrl[1]"
                        alt="Uploaded Image"
                        style="max-width: 35%"
                      />
                    </div>

                    <div
                     v-if="container.uploads[1] == false && container.imageUrl[1] == null"
                      class="contenteditable layout1"
                      contenteditable="true"
                      :innerHTML="container.editable[1]"
                      @dragover.prevent
                      @input="
                        firstContenteditabledata(
                          containerIndex,
                          1,
                          $event
                        )
                      "
                      @drop="
                        handleDropInside('layout1', containerIndex, 1,$event)
                      "
                     @focus="handleFocus(containerIndex)"
                    
                    >
                      <!-- {{ container.editable[1] }} -->

                 
                    </div>

                    <!-- Separator -->
                    <div
                      class="separator"
                      :style="{ display: preview == false ? 'block' : 'none' }"
                      @mousedown="startResize"
                    ></div>

                    <!-- Third contenteditable -->
                    <div
                     v-if="container.uploads[2] == true || container.imageUrl[2] != null"
                      class="contenteditable layout1"
                      contenteditable="true"
                      @dragover.prevent
                      @drop="
                        handleDropInside(
                          'layout2',
                          containerIndex,
                          2,
                          $event
                        )
                      "
                    @focus="handleFocus(containerIndex)"
                    
                    >
                      <!-- {{ container.editable[0] }} -->
                      <!-- Upload button if present -->
                      <!-- {{ container.uploads }} -->
                      <div  v-if="container.uploads[2] == true">
                        <input
                          type="file"
                          @change="
                            handleImageUpload1(
                              'layout2',
                              containerIndex,
                              2,
                              $event
                            )
                          "
                        />
                      </div>
                      <img
                        v-if="container.imageUrl[2]"
                        :src="container.imageUrl[2]"
                        alt="Uploaded Image"
                        style="max-width: 35%"
                      />
                    </div>

                    <div
                     v-if="container.uploads[2] == false && container.imageUrl[2] == null"
                      class="contenteditable layout1"
                      contenteditable="true"
                      :innerHTML="container.editable[2]"
                      @dragover.prevent
                      @input="
                        firstContenteditabledata(
                          containerIndex,
                          2,
                          $event
                        )
                      "
                      @drop="
                        handleDropInside('layout2', containerIndex, 2,$event)
                      "
                     @focus="handleFocus(containerIndex)"
                    
                    >
                      <!-- {{ container.editable[1] }} -->

                 
                    </div>
                  </div>

                 <!-- Dynamic Table -->
                  <div v-if="container.type == 'dynamicTable'">
                    <table
                      border="1"
                      class="single-border-table"
                      style="margin-top: 10px; width: 100%;"
                    >
                      <thead>
                        <tr>
                          <th v-for="header in headers" :key="header.prop" style="padding:8px">
                            {{ header.label }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(row, rowIndex) in rowsArray"
                          :key="rowIndex"
                        >
                          <td
                            v-for="(_, colIndex) in columnsArray"
                            :key="colIndex"
                            style="padding:8px"
                            contenteditable="true"
                            class="editable-cell"
                            @input="updateTableData(rowIndex, colIndex, $event)"
                             @focus="handleFocus(containerIndex)"
                            
                          >
                            {{
                              tableData[rowIndex]?.[headers[colIndex]?.prop] ||
                              ""
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- <table
            class="single-border-table"
            border="1"
            style="margin-top: 10px; width: 100%; cursor: move;"
            draggable="true"
            @dragstart="handleTableDragStart(containerIndex, tableIndex)"
            @dragover.prevent
            @drop="handleTableDrop(containerIndex, tableIndex)"
          >
            <tr v-for="(row, rowIndex) in table.rows" :key="'row-' + rowIndex">
              <td
                v-for="(column, colIndex) in row"
                :key="'col-' + colIndex"
                style="padding: 8px; text-align: center; cursor: pointer;"
                :contenteditable="true"
                @click="editTableCell(containerIndex, tableIndex, rowIndex, colIndex)"
                @mouseup="insertPlaceholder"
                @keyup="insertPlaceholder"
                @blur="saveTableCell(containerIndex, tableIndex, rowIndex, colIndex)"
              >
                {{ column }}
              </td>
            </tr>
          </table> -->
                  </div>

                  <!-- staticTable -->
                  <div v-if="container.type == 'newTable'">
                    <table
                    :id="'single-border-table'+containerIndex"
                      class="single-border-table"
                      border="1"
                      style="margin-top: 10px; width: 100%; cursor: move"
                    >
                      <tr
                        v-for="(row, rowIndex) in container.rows"
                        :key="'row-' + rowIndex"
                      >
                        <td
                          v-for="(column, colIndex) in row"
                          :key="'col-' + colIndex"
                          :class="'col-' + containerIndex+ rowIndex + colIndex"
                          :style="{
                            background:
                              container?.columns[rowIndex] != ''
                                ? container?.columns[rowIndex]
                                : 'white',
                                textAlign:container?.alignments[rowIndex][colIndex] != ''? container?.alignments[rowIndex][colIndex]+'!important' : 'center',
                                color:container?.textColors[rowIndex][colIndex] != ''? container?.textColors[rowIndex][colIndex]+'!important' : 'black',
                          }"
                          style="
                            padding: 8px;cursor: pointer;
                          "
                          :contenteditable="true"
                          @mouseup="insertPlaceholder"
                          @keyup="insertPlaceholder"
                          @focus="setLastFocusedClass(containerIndex,rowIndex, colIndex,$event)"
                          @blur="staticDatavalue(containerIndex, rowIndex, colIndex,$event)"
                        >
                          {{ column }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </template>
            </draggable>
          </section>
      </div>
    </section>
  </section>
  <div
    v-show ="preview == true"
    style="
      width: 100%;
      height: 100%;
      padding: 10px;
      background-color: white;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 100000000;
      display: flex;
      flex-direction: column;
    "
  >
    <div
      class="preview"
      style="
        display: flex;
        align-items: center;
        background-color: rgba(231, 231, 231, 0.65);
        flex-direction: column;
        height:100%
      "
    >
      <div
        style="
          width: 100%;
          background-color: white;
          padding: 0 20px;
          height: 60px;
          display: flex;
          align-content: space-between;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div>
          <hlx-breadcrumb @path="pathToPreview" />
        </div>
        <div>{{ templateName }}</div>
        <div><!--  for language --></div>
      </div>
      <div class="preview-container" ></div>
    </div>
  </div>
</template>

<script>
import { UPCAxiosInstance,DMNAxiosInstance } from "@/config/axiosConfig";
// import { timePanelSharedProps } from 'element-plus/es/components/time-picker/src/props/shared';
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      selectionRange:null,
      containerleft:0,
        containertop:0,
        startContainer:null,
        startOffset:0,
        endContainer:null,
        endOffset:0,
      url:'',
      modal_active:false,
      isResizing:false,
      containerV1: [],
      duplicateContainerV1:[],
      tagsList: [],
      lastFocusedtableHeader: {},
      notification: {},
      tagValue: [],
      templateStatus: "Draft",
      templateName: "",
      templatedescription: "",
      ismodal: false,
      // [{"label":"Name","prop":"name"},{"label":"Account","prop":"account"}]
      headerJson: ``, // Default header JSON
      // tableDataInput
      dataVariable: "", // Default variable name for table data
      headers: [], // Parsed header array
      tableData: [], // Holds table data
      rowsArray: [], // Array to create rows
      columnsArray: [], // Array to create columns
      rows: null, // Default number of rows
      columns: null, // Default number of columns
      tableDataInput: [
        { name: "Alex", account: "Savings" },
        { name: "Taylor", account: "Checking" },
        { name: "Jordan", account: "Business" }, // Additional row
      ], // Example table
      obj: { name: "Alex", account: "savings" },
      preview: false,
      drawerOpen: false, // Controls drawer visibility
      dynamicVariable: "", // Stores typed dynamic variable (e.g., obj.name)
      savedRange: null, // Stores cursor position
      languageOptions: [
        {
          name: "English",
          checked: true,
        },
      ],
      overValue: null,
      items: [{ label: "Back", link: "/", icon: "icon-arrow-left-regular" }],
      dynamicVariables: {}, // Store dynamic variables and their values,
      isDrawerVisible: false,
      lastCursorPosition: null,
      draggingType: null,
      resizing: false,
      startX: 0,
      startWidth: 0,
      activeSeparator: null,
      currentUploadTarget: null,
      duplicateContainers: [
        {
          upload: [],
          editableContainers: [],
          layout1: [],
          layout2: [],
          staticTable: [],
          tables: [], // Change to allow multiple tables in the container
        },
      ],
      containers: [
        {
          upload: [],
          editableContainers: [],
          layout1: [],
          layout2: [],
          staticTable: [],
          tables: [], // Change to allow multiple tables in the container
        },
      ], // Array for outer containers
      draggedItem: null, // Object to store details of dragged item
      lastFocusedContainer: null, // To track the last focused container for table creation
      isModalOpen: false, // To control modal visibility
      tableRows: null, // Default number of rows
      tableColumns: null, // Default number of columns
      fontName: [
        { name: "Times new roman", checked: false, value: "Times New Roman" },
        { name: "Arial", checked: true, value: "Arial" },
        { name: "Cursive", checked: false, value: "Cursive" },
        { name: "Opensans", checked: false, value: "OpenSans" },
        { name: "Quicksand", checked: false, value: "QuickSand" },
        { name: "Pacifico", checked: false, value: "Pacifico" },
        { name: "Ostrichsans", checked: false, value: "OstrichSans" },
        { name: "Josefinsans", checked: false, value: "JosefinSans" },
        { name: "BellSlim", checked: false, value: "BellSlim" },
      ],
      fontSize: [
        { name: "1", checked: false, value: "1" },
        { name: "2", checked: true, value: "2" },
        { name: "3", checked: false, value: "3" },
        { name: "4", checked: false, value: "4" },
        { name: "5", checked: false, value: "5" },
        { name: "6", checked: false, value: "6" },
        { name: "7", checked: false, value: "7" },
        { name: "8", checked: false, value: "8" },
        { name: "9", checked: false, value: "9" },
        { name: "10", checked: false, value: "10" },
      ],
      colorvalue: "",
      highlightvalue: "",
      filteredstatus: [],
      statusList: [],

    };
  },
  watch: {
    statusList: {
      handler(val) {
        console.log(val, "statuswatching000000000000");
        const filteredstatuslist = this.statusList.filter(
          (item) =>
            item.name === "Active" ||
            item.name === "Inactive" ||
            item.name === "Draft"
        );
        this.filteredstatus = filteredstatuslist;
        console.log("filtered11111111111111111", this.filteredstatus);
      },
      immediate: true,
      deep: true,
    },
    containers: {
      handler(val) {
        console.log(val, "datttttaaaa");
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    if (this.$route.query.template) {
      console.log(this.$route.query.template, "popop");
      const data = await UPCAxiosInstance.get(
        `emailTemplate/${this.$route.query.template}`
      );
      console.log(data.data, "templatedata");
      this.containerV1 = JSON.parse(JSON.stringify(data.data.layout));
      this.duplicateContainerV1 = JSON.parse(JSON.stringify(data.data.layout));
      this.templateName = data.data.template;
       this.tagValue = data.data.tag
      this.templateStatus = data.data.status
      this.templatedescription = data.data.description

      this.statusList =  await this.getReferanceData('statuslist');
    const rootName = this.$cookies.get("rootName");

      const tagsListurl =
      rootName === "Halleyx"
        ? "mapRef/collection/tagsList"
        : `mapRef/collection/tagsList?company=${this.$cookies.get("rootName")}`;

    let tagsList = await DMNAxiosInstance.get(tagsListurl);
    this.tagsList = tagsList.data.data.filter((item) => {
      return item.module === "emailTemplate";
    });
    }
  },
  methods: {
            link(){
     const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(this.selectionRange);

      // Use execCommand to insert a hyperlink
      document.execCommand('createLink', false, this.url);

      // Clear the modal input and selection
      this.url = '';
      this.selectionRange = null;
            this.modal_active = false;

            },
    deleteElements(obj){
      console.log(this.duplicateContainerV1,'lol',obj)
      this.containerV1.forEach((element,index)=>{
           if(element.id == obj.id){
            //  this.containerV1.splice(index, 1);
             this.duplicateContainerV1.splice(index,1)

           }
      })
      this.containerV1 = JSON.parse(JSON.stringify(this.duplicateContainerV1))
        console.log(this.containerV1,'lolafter')

    },
    handleFocus(index){
      document.querySelectorAll('.drag-area').forEach((element)=>{
        element.style.borderColor = '#ddd'
      })
      document.getElementById('focusElements'+index).style.borderColor = 'rgb(0, 23, 35)'
      document.querySelectorAll('.edit-delete-placeholder').forEach((element)=>{
        element.style.display = 'none'
      })
      document.getElementById('edit-delete-placeholder'+index).style.display='flex'
    },
    handleBlur(index,event){
      console.log(event,'blur')
      console.log('blur',event.target.className)
      if(event.target.className == 'icon-trash-regular'){
        console.log('jls') 
        this.containerV1.splice(index, 1);
        this.duplicateContainerV1.splice(index,1)

      }else{
        console.log('oeo')
        document.getElementById('focusElements'+index).style.borderColor = '#ddd'
        document.querySelectorAll('.edit-delete-placeholder').forEach((element)=>{
          element.style.display = 'none'
        })
      }
    },
    draggableonDragStart(){
       this.containerV1 = this.duplicateContainerV1
       console.log(this.containerV1,this.duplicateContainerV1,'lold')
    },
    specialCharacter(value){
      console.log(value,'specialCharacters')
      let val = value.match(/\d+/)[0]
      const unicodeNumber = val; // Numeric Unicode for ©
      const character = String.fromCharCode(unicodeNumber);
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        range.deleteContents(); // Optional: removes any selected text
        range.insertNode(document.createTextNode(character));
        // Move the caret to the end of the inserted character
        range.setStartAfter(range.endContainer);
        range.setEndAfter(range.endContainer);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    },
    receivedTags(e) {
      let arr = [];
      e.filter((item) => {
        if (item.checked == true) {
          arr.push(item.name);
        }
      });
      this.tagValue = arr;
      console.log(this.tagValue, "this.tagValue");
    },
    async addTagList(e) {
      let obj = {};
      obj.name = e.name;
      obj.module = "emailTemplate";
      await DMNAxiosInstance.post(`mapRef/tagsList`, obj);
      let tagsList = await this.getReferanceData("tagsList");
      let arr = this.tagValue;
      arr.push(e.name);
      this.tagValue = arr;
      this.tagsList = tagsList.filter((item) => {
        return item.module === "emailTemplate";
      });
    },
    pathToPreview() {
      document.querySelectorAll(".drag-area").forEach((element)=>{
           element.style.border='1px solid #ddd'
        })
        const editableElements = document.querySelectorAll(
        '[contenteditable="false"]'
      );

      // Update each element
      editableElements.forEach((element) => {
        console.log(element);
        element.setAttribute("contenteditable", "true"); // Disable editing
        // element.style.border = "none"; // Remove border
      });
      const element1 = document.querySelector(".preview-attach");
      element1.remove();  
      this.preview = false;

    },
    rowArrayfocus(val) {
      // if(event >40){
      // val=40
      // }
      console.log(val, "rowArrayfocus", this.rowArray);
    },
    columnsArrayfocus(val) {
      // if(event >20){
      // val=20
      // }
      console.log(val, "columnsArrayfocus", this.columnsArray);
    },
    createLink() {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        this.selectionRange = selection.getRangeAt(0);
      }
      this.modal_active = true
      // const url = prompt("Enter the URL:");
      // if (url) {
      //   document.execCommand("createLink", false, url);
      // }
    },
    removeLink() {
      document.execCommand("unlink", false, null);
    },
    setLastFocusedClass(containerIndex, rowIndex, colIndex,event) {
      this.lastFocusedtableHeader = {
        containerIndex,
        rowIndex,
        colIndex,
        event
      };
      this.handleFocus(containerIndex)
      // console.log(className,event.target.className,'testing')
    },
    borderPreview() {
      return {
        border: this.preview != true ? "1px solid rgb(204, 204, 204)" : "none",
      };
    },
    getCursorPosition(element) {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      return preCaretRange.toString().length;
    },

    setCursorPosition(element, position) {
      const range = document.createRange();
      const selection = window.getSelection();
      range.setStart(element.firstChild, position);
      range.setEnd(element.firstChild, position);
      selection.removeAllRanges();
      selection.addRange(range);
    },
    contentAttrs(container, editable) {
      // This will set the initial content value and prevent resetting
      return {
        innerText:
          this.containers[container].editableContainers[editable].content, // Bind initial content properly
      };
    },
    inputType(container,  event) {
      const contentElement = event.target;
      const updatedContent = contentElement.innerHTML;
      this.duplicateContainerV1[container].content =
        updatedContent;
      console.log(
        event.target.innerText,
        this.containerV1,
        "dokmslk",
        this.duplicateContainerV1
      );
    },
    staticDatavalue(container,rowIndex, colIndex, event) {
      const contentElement = event.target;
      const updatedContent = contentElement.innerText;
      // this.duplicateContainers[container].staticTable[tableIndex].rows[
      //   rowIndex
      // ][colIndex] = updatedContent;
      this.duplicateContainerV1[container].rows[
        rowIndex
      ][colIndex] = updatedContent;
      console.log(
        event.target.innerText,
        this.containerV1,
        "dokmslk",
        this.duplicateContainerV1
      );
      this.handleBlur(container,event)
    },
    pathTo() {
      this.$router.push(`/settings/emailTemplate`);
    },
    emitStatus1(val) {
      this.templateStatus = val.name;
      console.log(this.payload, "this.payload");
    },
    //Save Template
    async saveTemplate() {
      if (this.$route.query.template) {
        try {
          let obj = {
            id: this.$route.query.template,
            template: this.templateName,
            tag: this.tagValue,
            layout: this.duplicateContainerV1,
          };
          const value = await UPCAxiosInstance.patch("emailTemplate", obj);
          this.notification = {
            type: "success",
            message: `Nice work! Your template is updated!`,
          };

          console.log(value, "testing");
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          let obj = {
            template: this.templateName,
            tag: this.tagValue,
            layout: this.duplicateContainerV1,
            status:this.templateStatus,
            description:this.templatedescription


          };
          const value = await UPCAxiosInstance.post("emailTemplate", obj);
          this.notification = {
            type: "success",
            message: `Nice work! Your template is saved!`,
          };

          console.log(value, "testing");
        } catch (error) {
          console.log(error);
        }
      }
    },
    firstContenteditabledata(
      containerIndex,
      uploadIndex,
      event
    ) {
      const contentElement = event.target;
      const updatedContent = contentElement.innerHTML;
      this.duplicateContainerV1[containerIndex].editable[
        uploadIndex
      ] = updatedContent;
      console.log(
        event.target.innerText,
        this.containerV1,
        "dokmslk",
        this.duplicateContainerV1
      );
    },
    // Handle drop inside specific contenteditable areas
    handleDropInside(layout, containerIndex,uploadIndex, event) {
      event
      // console.log("ada", "dropinside", event.target.className);
      if (layout === "layout1") {
        this.containerV1[containerIndex].uploads[uploadIndex] = true
        this.duplicateContainerV1[containerIndex].uploads[uploadIndex] = true
        console.log(this.containerV1,this.duplicateContainerV1,'ldk')
        // this.containers[containerIndex]["layout1"][layoutIndex].uploads[
        //   uploadIndex
        // ] = true;
        // this.duplicateContainers[containerIndex]["layout1"][
        //   layoutIndex
        // ].uploads[uploadIndex] = true;
        // this.draggingType = null;
      }
      else if (layout === "layout2") {
        this.containerV1[containerIndex].uploads[uploadIndex] = true
        this.duplicateContainerV1[containerIndex].uploads[uploadIndex] = true
        console.log(this.containerV1,this.duplicateContainerV1,'ldk')
        // this.containers[containerIndex]["layout1"][layoutIndex].uploads[
        //   uploadIndex
        // ] = true;
        // this.duplicateContainers[containerIndex]["layout1"][
        //   layoutIndex
        // ].uploads[uploadIndex] = true;
        // this.draggingType = null;
      }
      // } else if (layout === "layout2") {
      //   this.containers[containerIndex]["layout2"][layoutIndex].uploads[
      //     uploadIndex
      //   ] = true;
      //   this.duplicateContainers[containerIndex]["layout2"][
      //     layoutIndex
      //   ].uploads[uploadIndex] = true;
      //   this.draggingType = null;
      // }
    },
    renderTableBody() {
      try {
        // Use the data variable name to populate the tableData array
        const data = this[this.dataVariable];

        // Ensure the tableData array has the correct number of rows
        if (data && Array.isArray(data)) {
          // Adjust rowsArray to match the number of rows in the data
          this.rowsArray = Array.from({
            length: Math.max(this.rows, data.length),
          });

          // Ensure each row object is initialized
          this.tableData = this.rowsArray.map((_, rowIndex) => {
            const rowData = data[rowIndex] || {};
            return rowData;
          });

          // Adjust columnsArray to match the number of columns in the header
          this.columnsArray = Array.from({
            length: Math.max(this.columns, this.headers.length),
          });
        }
      } catch (error) {
        alert("Error rendering table body. Please check your inputs.");
      }
    },
    updateTableData(rowIndex, colIndex, event) {
      // Dynamically update the tableData object when a cell is edited
      const key = this.headers[colIndex]?.prop;
      if (!key) return;

      // Ensure the tableData array has a row object
      if (!this.tableData[rowIndex]) {
        this.tableData[rowIndex] = {};
      }

      // Update the value directly in the array
      this.tableData[rowIndex][key] = event.target.innerText.trim();
    },
    applyHeadersAndCreateTable() {
      try {
        // Parse the header JSON
        this.headers = JSON.parse(this.headerJson);

        // Generate empty rows and columns
        this.rowsArray = Array.from({ length: this.rows });
        this.columnsArray = Array.from({ length: this.columns });

        // Initialize tableData with empty rows
        this.tableData = this.rowsArray.map(() => ({}));
        this.containerV1.push({
          tables:this.tableData,
          type:'dynamicTable'
        })
        this.duplicateContainerV1.push({
          tables:this.tableData,
          type:'dynamicTable'
        })
        this.containers[0].tables.push(this.tableData);
        this.duplicateContainers[0].tables.push(this.tableData);

        this.isModalOpen = false;
      } catch (error) {
        this.rowsArray = Array.from({ length: this.rows });
        this.columnsArray = Array.from({ length: this.columns });

        // Initialize tableData with empty rows
        this.tableData = this.rowsArray.map(() => ({}));
        alert("Invalid JSON format. Please correct it.");
      }
    },
    previewfunc() {
      this.preview = true;
      this.renderTableBody();
      this.allocateValues();
      // value;
    },
    openDynamicContent() {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        this.savedRange = selection.getRangeAt(0);
        console.log(this.savedRange, "pokdao");
        this.drawerOpen = true;
      }
    },
    applyDynamicVariable() {
      console.log(this.dynamicVariable, this.savedRange, "lolojk");
      if (this.savedRange && this.dynamicVariable) {
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(this.savedRange);
        console.log(this.savedRange, "poddp", this.dynamicVariable);

        const placeholder = `{{ ${this.dynamicVariable} }}`;
        const textNode = document.createTextNode(placeholder);
        this.savedRange.insertNode(textNode);

        this.savedRange.setStartAfter(textNode);
        this.savedRange.collapse(true);
        selection.removeAllRanges();
        selection.addRange(this.savedRange);
      }
      this.drawerOpen = false;
      this.dynamicVariable = "";
      this.savedRange = null;
    },
    allocateValues() {
      // Query all contenteditable elements, including table cells
      const contenteditables = document.querySelectorAll(
        '[contenteditable="true"]'
      );

      // Iterate over each contenteditable element
      contenteditables.forEach((el) => {
        let content = el.innerHTML;

        // Replace placeholders with actual values
        content = content.replace(
          /\{\{\s*([a-zA-Z0-9_.]+)\s*\}\}/g,
          (match, expression) => {
            // Split expression by dot notation to access nested properties
            const keys = expression.split(".");
            let value = this;

            // Traverse through keys to get the value
            for (let key of keys) {
              if (value && key in value) {
                value = value[key];
              } else {
                return match; // Keep the placeholder if any key is undefined
              }
            }

            return value !== undefined ? value : match;
          }
        );

        // Update the element's content
        el.innerHTML = content;
      });

      const editableElements = document.querySelectorAll(
        '[contenteditable="true"]'
      );

      // Update each element
      editableElements.forEach((element) => {
        console.log(element);
        element.setAttribute("contenteditable", "false"); // Disable editing
        // element.style.border = "none"; // Remove border
      });

      this.$nextTick(()=>{
        let value = document.querySelector(".outer-container1").innerHTML;
        console.log(value)
        const tempContainer = document.createElement("div");
        tempContainer.classList.add('preview-attach')
        tempContainer.innerHTML = value;
        document.querySelector(".preview-container").appendChild(tempContainer);
        document.querySelectorAll(".drag-area").forEach((element)=>{
           element.style.border='none'
        })
        document.querySelectorAll(".edit-delete-placeholder").forEach((element)=>{
          element.style.display='none'
        })
        value
      })
      // document.querySelector(".preview").children[0].children[0].children[0].style.padding = '10px'
    },
    handleContainerOver(containerIndex) {
      console.log(containerIndex, "over");
      this.overValue = containerIndex;
    },
    selectFontName(val) {
      var a = "";
      val.forEach((e) => {
        if (e.checked === true) {
          a = e.name;
        }
      });
      document.execCommand("fontName", false, a);
    },
    selectFontSize(val) {
      var a = "";
      val.forEach((e) => {
        if (e.checked === true) {
          a = e.name;
        }
      });
      document.execCommand("fontSize", false, a);
    },
    colorValue(value) {
      this.colorvalue = value;
      if(document.getElementById('single-border-table'+this.lastFocusedtableHeader.containerIndex) && document.querySelector('.'+ this.lastFocusedtableHeader.event.target.className)){
      if(document.getElementById('single-border-table'+this.lastFocusedtableHeader.containerIndex).contains(document.querySelector('.'+ this.lastFocusedtableHeader.event.target.className))){
          this.containerV1[this.lastFocusedtableHeader.containerIndex].textColors[this.lastFocusedtableHeader.rowIndex][this.lastFocusedtableHeader.colIndex] = value
          this.duplicateContainerV1[this.lastFocusedtableHeader.containerIndex].textColors[this.lastFocusedtableHeader.rowIndex][this.lastFocusedtableHeader.colIndex] = value
        }
      } 
      console.log(this.containerV1,'testinggg',document.getElementById('single-border-table'+this.lastFocusedtableHeader.containerIndex).contains(document.querySelector('.'+ this.lastFocusedtableHeader.event.target.className)))
      document.execCommand("foreColor", false, this.colorvalue);
    },
    highlightValue(value) {
      this.highlightvalue = value;
      console.log(value, this.lastFocusedtableHeader, "kd",this.containerV1,this.containerV1[this.lastFocusedtableHeader.containerIndex],this.containerV1[this.lastFocusedtableHeader.containerIndex].columns[this.lastFocusedtableHeader.rowIndex]);
      this.containerV1[this.lastFocusedtableHeader.containerIndex].columns[this.lastFocusedtableHeader.rowIndex] = this.highlightvalue;
      this.duplicateContainerV1[this.lastFocusedtableHeader.containerIndex].columns[this.lastFocusedtableHeader.rowIndex] = this.highlightvalue;
      console.log(this.containers, "kd");
      // document.querySelectorAll('.'+this.lastFocusedtableHeader).forEach((item)=>)
      // document.execCommand("backColor", false, this.highlightvalue);
    },
    executeCommand(command) {
      console.log(this.lastFocusedContainer, "jkdrjdfl");
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);

      console.log("jkdrjdfl", command, document);
      if(document.getElementById('single-border-table'+this.lastFocusedtableHeader.containerIndex) && document.querySelector('.'+ this.lastFocusedtableHeader.event.target.className)){
      if(command == 'justifyLeft'){
        if(document.getElementById('single-border-table'+this.lastFocusedtableHeader.containerIndex).contains(document.querySelector('.'+ this.lastFocusedtableHeader.event.target.className))){
          this.containerV1[this.lastFocusedtableHeader.containerIndex].alignments[this.lastFocusedtableHeader.rowIndex][this.lastFocusedtableHeader.colIndex] = 'left'
          this.duplicateContainerV1[this.lastFocusedtableHeader.containerIndex].alignments[this.lastFocusedtableHeader.rowIndex][this.lastFocusedtableHeader.colIndex] = 'left'
        }
      }else if(command == 'justifyCenter'){
        this.containerV1[this.lastFocusedtableHeader.containerIndex].alignments[this.lastFocusedtableHeader.rowIndex][this.lastFocusedtableHeader.colIndex] = 'center'
        this.duplicateContainerV1[this.lastFocusedtableHeader.containerIndex].alignments[this.lastFocusedtableHeader.rowIndex][this.lastFocusedtableHeader.colIndex] = 'center'
      }else if(command == 'justifyRight'){
        // console.log()
        this.containerV1[this.lastFocusedtableHeader.containerIndex].alignments[this.lastFocusedtableHeader.rowIndex][this.lastFocusedtableHeader.colIndex] = 'right'
        this.duplicateContainerV1[this.lastFocusedtableHeader.containerIndex].alignments[this.lastFocusedtableHeader.rowIndex][this.lastFocusedtableHeader.colIndex] = 'right'

      }else if(command == 'justifyFull'){
        console.log()
        this.containerV1[this.lastFocusedtableHeader.containerIndex].alignments[this.lastFocusedtableHeader.rowIndex][this.lastFocusedtableHeader.colIndex] = 'justify'
        this.duplicateContainerV1[this.lastFocusedtableHeader.containerIndex].alignments[this.lastFocusedtableHeader.rowIndex][this.lastFocusedtableHeader.colIndex] = 'justify'

      }
      }
      document.execCommand(command, false, null);
      selection.removeAllRanges();
      selection.addRange(range);
    },
    // Add a new outer container
    addOuterContainer() {
      this.containers.push({
        editableContainers: [],
        layout1: [],
        layout2: [],
        staticTable: [],
        tables: [], // Change to allow multiple tables in the container
      });
    },
    // Set the dragged item as a new contenteditable area, table, or upload button
    handleButtonDragStart(type) {
      this.draggedItem = { type };
      console.log("typw", type);
    },
    // Handle drag start for existing items (contenteditable, table, upload)
    handleDragStart(containerIndex, itemType, itemIndex) {
      this.draggedItem = {
        containerIndex,
        itemType,
        itemIndex,
      };
      console.log(
        this.draggedItem,
        "dragged",
        containerIndex,
        itemType,
        itemIndex
      );
    },
    // Handle drop event in the outer container
    handleContainerDrop(event) {
      event.stopPropagation();

      const { type=undefined } = this.draggedItem || {};
      if(!type) return
      console.log(type, "eventt", this.draggedItem);

      if (type === "newEditable") {
        // Add a new contenteditable area to the target container
        this.containerV1.push({
          id: Math.floor(Math.random() * 10000),

          content: "Click to edit this text.",
          isEditable: true,
          showUploadButton: false,
          imageUrl: null,
          type,
        });
        this.duplicateContainerV1.push({
          id: Math.floor(Math.random() * 10000),

          content: "Click to edit this text.",
          isEditable: true,
          showUploadButton: false,
          imageUrl: null,
          type,
        });
      } else if (type === "newTable") {
        // Add a new table to the target container
        this.ismodal = true;
        // this.addTableToContainer(targetContainerIndex);
      } else if (type === "dynamicTable") {
        // Add a new table to the target container
        this.isModalOpen = true;
        // this.addTableToContainer(targetContainerIndex);
      } else if (type === "newUpload") {
        // Add a new upload button inside the contenteditable area
        if (
          event.target.className != "contenteditable layout1" &&
          event.target.className != "contenteditable layout2"
        ) {
          console.log("odee");
          this.containerV1.push({
            content: "",
            id: Math.floor(Math.random() * 10000),

            isEditable: true,
            showUploadButton: true,
            imageUrl: null,
            type,
          });
          this.duplicateContainerV1.push({
            content: "",
            id: Math.floor(Math.random() * 10000),

            isEditable: true,
            showUploadButton: true,
            imageUrl: null,
            type,
          });
          // this.duplicateContainers[targetContainerIndex].upload.push({
          //   content: "",
          //   isEditable: true,
          //   showUploadButton: true,
          //   imageUrl: null,
          // });
        }
      }
      // else if (itemType === "editable") {
      //   // Move existing contenteditable item to the target container
      //   const editableContainer = this.containers[
      //     containerIndex
      //   ].editableContainers.splice(itemIndex, 1)[0];
      //   this.containers[targetContainerIndex].editableContainers.push(
      //     editableContainer
      //   );
      //   this.duplicateContainers[targetContainerIndex].editableContainers.push(
      //     editableContainer
      //   );
      // }
      //  else if (itemType === "table") {
      //   // Move existing table to the target container
      //   const table = this.containers[containerIndex].tables.splice(
      //     itemIndex,
      //     1
      //   )[0];
      //   this.containers[targetContainerIndex].tables.push(table);
      //   this.duplicateContainers[targetContainerIndex].tables.push(table);
      // }
      else if (type == "layout1") {
        console.log("container", this.containers);
        this.containerV1.push({
          type: "layout1",
          id: Math.floor(Math.random() * 10000),
          editable: ["", ""],
          uploads: [false, false],
          imageUrl: [null, null],
          showUploadButton: [true, true],
        });
        this.duplicateContainerV1.push({
          type: "layout1",
          id: Math.floor(Math.random() * 10000),
          editable: ["", ""],
          uploads: [false, false],
          imageUrl: [null, null],
          showUploadButton: [true, true],
        });
        // this.duplicateContainers[targetContainerIndex].layout1.push({
        //   type: "layout1",
        //   editable: ["", ""],
        //   uploads: [false, false],
        //   imageUrl: [null, null],
        //   showUploadButton: [true, true],
        // });
      }
      else if (type == "layout2") {
        console.log("container", this.containers);
        this.containerV1.push({
          type: "layout2",
          id: Math.floor(Math.random() * 10000),
          editable: ["", "", ""],
          uploads: [false, false, false],
          imageUrl: [null, null, null],
          showUploadButton: [true, true, true],
        });
        this.duplicateContainerV1.push({
          type: "layout2",
          id: Math.floor(Math.random() * 10000),
          editable: ["", "", ""],
          uploads: [false, false, false],
          imageUrl: [null, null, null],
          showUploadButton: [true, true, true],
        });
        // this.containers[targetContainerIndex].layout2.push({
        //   type: "layout2",
        //   editable: ["", "", ""],
        //   uploads: [false, false, false],
        //   imageUrl: [null, null, null],
        //   showUploadButton: [true, true, true],
        // });
        // this.duplicateContainers[targetContainerIndex].layout2.push({
        //   type: "layout2",
        //   editable: ["", "", ""],
        //   uploads: [false, false, false],
        //   imageUrl: [null, null, null],
        //   showUploadButton: [true, true, true],
        // });
      }

      // Reset dragged item after drop
      this.draggedItem = null;
      console.log(
        //   type,
        //   "ode",
        //   this.draggedItem,
        //   // targetContainerIndex,
        //   // event.target.className,
        JSON.stringify(this.containerV1, null, 2)
      );
    },
    // Toggle contenteditable mode for a contenteditable area
    // toggleEditable(containerIndex, index) {
    //   this.containers[containerIndex].editableContainers[
    //     index
    //   ].isEditable = true;
    //   this.duplicateContainers[containerIndex].editableContainers[
    //     index
    //   ].isEditable = true;
    // },
    // Save changes and disable contenteditable mode
    saveChanges(containerIndex, index) {
      this.containers[containerIndex].editableContainers[
        index
      ].isEditable = true;
      this.duplicateContainers[containerIndex].editableContainers[
        index
      ].isEditable = true;
    },
    // Focus on a container when clicked
    focusContainer(containerIndex) {
      this.lastFocusedContainer = containerIndex;
    },
    // Handle image upload for contenteditable area
    handleImageUpload(event, containerIndex) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = () => {
        this.containerV1[containerIndex].imageUrl = reader.result;
        this.containerV1[containerIndex].showUploadButton = false;
        this.duplicateContainerV1[containerIndex].imageUrl =
          reader.result;
        this.duplicateContainerV1[containerIndex].showUploadButton = false;
      };
      reader.readAsDataURL(file);
    },

    // Handle image upload for contenteditable area
    handleImageUpload1(
      layout,
      containerIndex,
      uploadIndex,
      event
    ) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = () => {
        // this.containers[containerIndex]['layout1'][layoutindex].imageUrl[index] = reader.result
        // this.containers[containerIndex]['layout1'][layoutindex].imageUrl[index]  = false;
        this.containerV1[containerIndex].imageUrl[
          uploadIndex
        ] = reader.result;
        this.containerV1[containerIndex].uploads[
          uploadIndex
        ] = false;
        this.duplicateContainerV1[containerIndex].imageUrl[
          uploadIndex
        ] = reader.result;
        this.duplicateContainerV1[containerIndex].uploads[
          uploadIndex
        ] = false;

        // containerIndex.imageUrl[index] = reader.result;
      };
      reader.readAsDataURL(file);
    },
    // Handle clicking on Table button to open modal
    openTableModal() {
      this.isModalOpen = true;
    },
    // Close the modal window
    closeModal() {
      this.isModalOpen = false;
      this.columnsArray = "";
      this.rowsArray = "";
    },
    // Apply the table configuration from the modal inputs
    applyTable() {
      // Add the new table to the appropriate container
      console.log('posj')
      if (this.overValue !== null) {
        this.addTableToContainer();
        // this.addTableToContainer(this.lastFocusedContainer);
      } else {
        this.addTableToContainer(); // Add to the first container if none is focused
      }


    },
    // Method to add a new table to a container
    addTableToContainer() {
      // containerIndex
      console.log(this.tableRows,this.tableColumns,'testt')
      const rows = [];
      const columns = [];
      const colors = []
      const textColors = []
      const fontSizes = []
      const alignments = []
      for (let i = 0; i < this.tableRows; i++) {
        const row = [];
        const textColor = []
        const fontSize = []
        const alignment = []
        columns.push(" ");
        colors.push(" ")
        for (let j = 0; j < this.tableColumns; j++) {
          row.push(" ");
          textColor.push('')
          fontSize.push('')
          alignment.push('')
        }
        rows.push(row);
        textColors.push(textColor)
        fontSizes.push(fontSize)
        alignments.push(alignment)
      }
      const newTable = { rows, editableCell: null, columns ,colors, type:'newTable', id: Math.floor(Math.random() * 10000), textColors, fontSizes, alignments  };
      console.log(newTable,'newTAblr')
      this.containerV1.push(newTable);
      this.duplicateContainerV1.push(newTable);
      // this.duplicateContainers[containerIndex].staticTable.push(newTable);
      console.log("value", this.containerV1);
      this.ismodal = false;
      this.tableRows = "";
      this.tableColumns = "";
    },
    // Edit table cell when clicked
    editTableCell(containerIndex, tableIndex, rowIndex, colIndex) {
      this.containers[containerIndex].staticTable[
        tableIndex
      ].editableCell = `${rowIndex}-${colIndex}`;
      this.duplicateContainers[containerIndex].staticTable[
        tableIndex
      ].editableCell = `${rowIndex}-${colIndex}`;
    },
    // Save table cell changes
    saveTableCell(containerIndex, tableIndex, rowIndex, colIndex) {
      rowIndex;
      colIndex;
      this.containers[containerIndex].staticTable[tableIndex].editableCell =
        null;
      this.duplicateContainers[containerIndex].staticTable[
        tableIndex
      ].editableCell = null;
    },
    // Handle dragging a table
    handleTableDragStart(containerIndex, tableIndex, value) {
      this.draggedItem = { type: value, containerIndex, tableIndex };
    },
    // Handle table drop inside the container
    handleTableDrop(containerIndex, tableIndex) {
      tableIndex;
      const {
        type,
        containerIndex: sourceContainerIndex,
        tableIndex: sourceTableIndex,
      } = this.draggedItem;

      if (type === "dynamicTable") {
        const table = this.containers[sourceContainerIndex].tables.splice(
          sourceTableIndex,
          1
        )[0];
        this.containers[containerIndex].tables.push(table);
        this.duplicateContainers[containerIndex].tables.push(table);
      } else if (type === "table") {
        const table = this.containers[sourceContainerIndex].staticTable.splice(
          sourceTableIndex,
          1
        )[0];
        this.containers[containerIndex].staticTable.push(table);
        this.duplicateContainers[containerIndex].staticTable.push(table);
      }

      this.draggedItem = null;
    },
    // Start resizing
    startResize(event) {
      this.isResizing = true
      this.resizing = true;
      this.startX = event.clientX;
      this.activeSeparator = event.target;
      const parent = event.target.parentElement;
      this.startWidth = parent.children[0].offsetWidth;

      document.addEventListener("mousemove", this.resize);
      document.addEventListener("mouseup", this.stopResize);
    },

    // Handle resizing
    resize(event) {
      if (!this.resizing) return;

      const delta = event.clientX - this.startX;
      const parent = this.activeSeparator.parentElement;
      const leftPane = parent.children[0];
      const rightPane = parent.children[2];

      leftPane.style.flex = `0 0 ${this.startWidth + delta}px`;
      rightPane.style.flex = `1`;
    },

    // Stop resizing
    stopResize() {
      this.isResizing = false
      this.resizing = false;
      document.removeEventListener("mousemove", this.resize);
      document.removeEventListener("mouseup", this.stopResize);
    },
  },
};
</script>

<style lang="scss">
.preview {
  overflow-y: hidden; /* Enable vertical scrolling */

  .preview-container {
    width: 55%;
    background-color: white;
    overflow-y: auto; /* Enable vertical scrolling */
    scrollbar-width: none;
    margin: 30px 0px;
  }

  .preview-container::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Edge */
  }
}
/* Add a single border around the table */
.single-border-table {
  border-collapse: collapse; /* Merge cell borders */
  width: 100%; /* Optional: to fit table width */
}

/* Add a single border around the table */
.single-border-table,
.single-border-table th,
.single-border-table td {
  border: 1px solid #d4d4d4; /* Single outer border */
}

/* Remove inner cell borders */
.single-border-table td {
  background: white;
  /* border-top: none;
  border-bottom: none;
  border-right: none;
  border-left: none; */
}

.resizable-layout {
  display: flex;
  min-height: 150px;
  border: 1px solid #ccc;
  // margin-bottom: 10px;
}

.separator {
  width: 5px;
  cursor: ew-resize;
  background-color: #ddd;
}

.contenteditable {
  flex: 1;
  padding: 10px;
  // border: 1px solid #ddd;
  overflow: none;
  position: relative;
}
</style>
