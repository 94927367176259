<template>
  <hlx-alert-notification
    :show-alert="isNotify"
    :notify="notification"
    :auto-close="true"
  />
  <main class="--layout-2-way">
    <section class="--layout-inner-left-portion --LL30">
      <!-- Breadcrumb -->
      <div class="layout-breadcrumb">
        <hlx-breadcrumb :items="breadCrumbItems" @path="pathTo" />
      </div>
      <div class="decision-info --info-left-panel">
        <!-- Title -->
        <span class="decision-title -info-title">View decision</span>

        <!-- decision name -->
        <div class="decision-name-wrapper">
          <hlx-input
            v-model:value="decisionPayload.name"
            read-only
            :pre-val="decisionPayload.name"
            :label-value="'Name'"
            :label-animation="true"
            :required="true"
            :display-error="isCustomNameError"
            :custom-error="isCustomNameError"
            :custom-error-message="'Please fill the field'"
          ></hlx-input>
        </div>

        <!-- decision description -->
        <div class="decision-description-wrapper">
          <hlx-input
          v-model:value="decisionPayload.description"
          read-only
            :pre-val="decisionPayload.description"
            :label-animation="true"
            label-value="Description"
            type="textarea"
            :clearable="true"
          />
        </div>

        <!-- decision ruleset -->
        <div class="decision-ruleset-wrapper">
          <hlx-multi-select
            :options="ruleSetData"
            :pre-value="ruleSetPreValue"
            :disabled="true"
            :placeholder="'Tag'"
            :prop-value="'_id'"
            :label="'name'"
            @add-data="addRuleset"
            @custom-change="selectValue"
          ></hlx-multi-select>
        </div>

        <div class="decision-status-wrapper">
          <hlx-select
            v-model:value="decisionPayload.status"
            :options="statusOptions"
            :pre-value="decisionPayload.status"
            disabled
            :placeholder-value="'Status'"
            :label-animation="true"
            :clearable="true"
          ></hlx-select>
        </div>
        <div class="decision-atomic-wrapper">
          <div style="display: flex; justify-content: space-between">
            <span style="display: flex; align-items: center"
              ><span style="margin-right: 8px">Atomic</span>
              <span
                data-tooltip="Atomic rules execute independently, excluded from chained execution"
                data-tooltip-location="right"
              >
                <i
                  class="icon-info-circle-regular"
                  style="color: #54bd95"
                ></i> </span
            ></span>
            <span>
              <hlx-input
              v-model:value="decisionPayload.isAtomic"
              disabled
                :pre-val="decisionPayload.isAtomic"
                :checked="false"
                :type="'switch'"
            /></span>
          </div>
        </div>
     
      </div>
    </section>
    <section class="decision-view --layout-inner-right-portion --RL70">
      <div class="panel-layout-body">
        <div class="design-area">
          <!-- {{this.tableData}} -->
          <p class="--right-panel-title --morgin-bottom">Column mapping</p>

          <span class="grid-local-new-decision-right manual-decision">
        
  <input-col-map-modal
    :show-input-col-map="shwInpMap"
    :read-only="true"
    :pre-populate="tableHeadDetails"
    :index="inputIndex"
    :drop-down-data="intellisensePayload"
    @save="saveInputThead"
    @close="shwInpMap = false"
    @delete="deleteInputThead"
  >
  </input-col-map-modal>
  <output-col-map-modal
    :show-output-col-map="shwOutMap"
    :read-only="true"
    :pre-populate="tableHeadDetails"
    :index="outputIndex"
    :drop-down-data="intellisensePayload"
    @save="saveOutputThead"
    @close="shwOutMap = false"
    @delete="deleteOutputhead"
  >
  </output-col-map-modal>
  <div class="example-body" style="display: flex;">
    <div :id="'grid-table'" :class="'read-only grid-table-component'">
      <hlx-table
        :column-count="theads.input.length + theads.output.length"
        :border="['table', 'header', 'vertical', 'horizontal']"
        theme="grey"
      >
        <template #thead>
          <hlx-table-head
            v-for="(i, index) in theads.input"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            :width="185"
            style="height: 60px !important"
            :style="i.type === 'input' ? { background: '#fff' } : {}"
            @dragenter="columnenter(serialNumber(index))"
            @dragend="columnend('input')"
            @mouseover="columnover(index)"
            @mouseleave="columnleave(index)"
          >
            <span
              :id="'add-input-column-left-' + index"
              class="add-column-left"
              @mouseenter="showIcon(index, 'left', 'input-')"
              @mouseleave="hideIcon(index, 'left', 'input-')"
            >
              <span
                :id="'add-input-column-icon-left-' + index"
                class="add-column-icon"
              >
                <i
                  class="icon-plus-regular"
                  @click="addInputColumn(index, 'left')"
                ></i>
              </span>
            </span>
            <span v-if="i.type === 'input'" class="thead-container">
              <span class="input-column-name"
                ><span class="column-name">{{ i.labelName }}</span>
                <span class="data-type">{{ i.dataType }}</span></span
              >
              <span class="column-drag-container">
                <span
                  :id="'columndrag' + index"
                  class="columndrag"
                  draggable="true"
                  @dragstart="columnstart(serialNumber(index))"
                >
                  <i class="icon-more-vertical-filled rotate"></i>
                </span>
              </span>
              <span
                class="drag-icon-vertical"
                @click="openTheadMappingModal('input', i)"
                ><i class="icon-more-vertical-filled"></i
              ></span>
            </span>
            <span
              :id="'add-input-column-right-' + index"
              class="add-column-right"
              @mouseenter="showIcon(index, 'right', 'input-')"
              @mouseleave="hideIcon(index, 'right', 'input-')"
            >
              <span
                :id="'add-input-column-icon-right-' + index"
                class="add-column-icon"
              >
                <i
                  class="icon-plus-regular"
                  @click="addInputColumn(index, 'right')"
                ></i>
              </span>
            </span>
          </hlx-table-head>
          <hlx-table-head
            v-for="(i, index) in theads.output"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            :width="185"
            style="height: 60px !important"
            :style="i.type === 'input' ? { background: '#fff' } : {}"
            @dragenter="columnenter(serialNumber(index))"
            @dragend="columnend('output')"
            @mouseover="columnoutputover(index)"
            @mouseleave="columnoutputleave(index)"
          >
            <span v-if="i.type === 'output'" class="thead-container">
              <span class="output-column-name"
                ><span class="column-name">{{ i.labelName }}</span>
                <span class="data-type">{{ i.dataType }}</span></span
              >

              <span class="column-drag-container">
                <span
                  :id="'columndragoutput' + index"
                  class="columndrag"
                  draggable="true"
                  @dragstart="columnstart(serialNumber(index))"
                >
                  <i class="icon-more-vertical-filled rotate"></i>
                </span>
              </span>
              <span
                style="cursor: pointer"
                @click="openTheadMappingModal('output', i)"
                ><i
                  class="icon-more-vertical-filled"
                  style="padding-top: 3px"
                ></i
              ></span>
            </span>
            <span
              :id="'add-output-column-right-' + index"
              class="add-column-right"
              @mouseenter="showIcon(index, 'right', 'output-')"
              @mouseleave="hideIcon(index, 'right', 'output-')"
            >
              <span
                :id="'add-output-column-icon-right-' + index"
                class="add-column-icon"
              >
                <i
                  class="icon-plus-regular"
                  @click="addOutputColumn(index, 'right', $event)"
                ></i>
              </span>
            </span>
          </hlx-table-head>
        </template>
        <template #tbody>
          <div class="grid-read-only-overlay"></div>

          <tr
            v-for="(i, index) in tBody"
            id="index"
            :key="index"
            @mouseover="rowdraggable(index)"
            @mouseout="outrowdraggable"
          >
            <hlx-table-cell
              v-for="(j, col_index) in headvalue"
              :key="col_index"
              @dragenter="enter(serialNumber(index))"
            >
              <span
                v-if="col_index == 0"
                :id="'rowdraggable-icon' + index"
                class="rowdraggable-icon"
                draggable="true"
                @dragstart="start(serialNumber(index))"
                @dragenter="enter(serialNumber(index))"
                @dragend="end()"
              >
                <span class="row-drag-icon-container">
                  <i class="icon-more-vertical-filled"></i>
                </span>
              </span>
              <div v-if="j.type === 'input'" class="row-cell">
                <span class="input-row-dropdown-container">
                  <dropdown
                    v-model:value="i[j.prop].operators"
                    v-model="i[j.prop].operators"
                    :options="op_data"
                    :placeholder-value="''"
                    :label="'name'"
                    :prop-value="'value'"
                    :label-animation="false"
                    :inline-search="true"
                    :footer="false"
                    :border="false"
                    @inline-value="pushOperators($event, j.prop)"
                  ></dropdown>
                </span>
                <span class="input-row-input-container">
                  <span
                    v-if="
                      j.source.toLowerCase() === 'manual' &&
                      j.dataType.toLowerCase() === 'string'
                    "
                    class="manual-string"
                  >
                    <input
                      v-model="i[j.prop].value"
                      class="input-text"
                      type="text"
                      @mouseleave="blurInput($event)"
                      @mouseover="focusInput($event)"
                      @focus="focusInput($event)"
                      @blur="blurInput($event)"
                    />
                  </span>
                  <span
                    v-if="
                      j.source.toLowerCase() === 'manual' &&
                      (j.dataType.toLowerCase() === 'number' ||
                        j.dataType.toLowerCase() === 'integer') &&
                      i[j.prop].operators !== 'between'
                    "
                    class="manual-number"
                  >
                    <input
                      v-model="i[j.prop].value"
                      class="input-number"
                      type="number"
                      @mouseleave="blurInput($event)"
                      @mouseover="focusInput($event)"
                      @focus="focusInput($event)"
                      @blur="blurInput($event)"
                    />
                  </span>
                  <span
                    v-if="
                      j.source.toLowerCase() === 'manual' &&
                      (j.dataType.toLowerCase() === 'number' ||
                        j.dataType.toLowerCase() === 'integer') &&
                      i[j.prop].operators === 'between'
                    "
                    class="manual-between"
                  >
                    <span class="left-between">
                      <input
                        v-model="i[j.prop].value1"
                        class="left-between-input"
                        type="number"
                        @mouseleave="blurInput($event)"
                        @mouseover="focusInput($event)"
                        @focus="focusInput($event, 'bw')"
                        @blur="blurInput($event, 'bw')"
                      />
                    </span>
                    <div class="between-seperator"></div>
                    <span class="right-between">
                      <input
                        v-model="i[j.prop].value2"
                        class="right-between-input"
                        type="number"
                        @mouseleave="blurInput($event)"
                        @mouseover="focusInput($event)"
                        @focus="focusInput($event, 'bw')"
                        @blur="blurInput($event, 'bw')"
                      />
                    </span>
                  </span>
                  <span
                    v-if="
                      j.source.toLowerCase() === 'manual' &&
                      j.dataType.toLowerCase() === 'boolean'
                    "
                    class="manual-boolean"
                  >
                    <dropdown
                      v-model:value="i[j.prop].value"
                      v-model="i[j.prop].value"
                      :options="[
                        { name: 'true', value: true },
                        { name: 'false', value: false },
                      ]"
                      :placeholder-value="''"
                      :label="'name'"
                      :prop-value="'value'"
                      :label-animation="false"
                      :inline-search="true"
                      :footer="false"
                      :border="false"
                    />
                  </span>
                  <span v-if="j.source.toLowerCase() === 'reference data'" class="manual-boolean">
                    <dropdown
                      v-model:value="i[j.prop].value"
                      v-model="i[j.prop].value"
                      :options="refData[j.prop]"
                      :placeholder-value="''"
                      :label="'name'"
                      :prop-value="'name'"
                      :label-animation="false"
                      :inline-search="true"
                      :footer="false"
                      :border="false"
                    />
                  </span>
                </span>
              </div>
              <div v-if="j.type === 'output'" class="row-cell">
                <span class="output-cell-container">
                  <span
                    v-if="
                      j.source.toLowerCase() === 'manual' &&
                      j.dataType.toLowerCase() === 'string'
                    "
                    class="output-manual-string"
                  >
                    <input
                      v-model="i[j.prop].value"
                      class="string-input"
                      type="text"
                      @mouseleave="blurInput($event)"
                      @mouseover="focusInput($event)"
                      @focus="focusInput($event)"
                      @blur="blurInput($event)"
                    />
                  </span>
                  <span
                    v-if="
                      j.source.toLowerCase() === 'manual' &&
                      (j.dataType.toLowerCase() === 'number' ||
                        j.dataType.toLowerCase() === 'integer')
                    "
                    class="output-manual-number"
                  >
                    <input
                      v-model="i[j.prop].value"
                      class="number-output"
                      type="number"
                      @mouseleave="blurInput($event)"
                      @mouseover="focusInput($event)"
                      @focus="focusInput($event)"
                      @blur="blurInput($event)"
                    />
                  </span>
                  <span
                    v-if="
                      j.source.toLowerCase() === 'manual' &&
                      j.dataType.toLowerCase() === 'boolean'
                    "
                    class="output-manual-boolean"
                  >
                    <dropdown
                      v-model:value="i[j.prop].value"
                      :options="[
                        { name: 'true', value: true },
                        { name: 'false', value: false },
                      ]"
                      :placeholder-value="''"
                      :label="'name'"
                      :prop-value="'value'"
                      :label-animation="false"
                      :inline-search="true"
                      :footer="false"
                      :border="false"
                    />
                  </span>
                  <span
                    v-if="j.source.toLowerCase() === 'reference data'"
                    class="output-ref"
                  >
                    <dropdown
                      v-model:value="i[j.prop].value"
                      v-model="i[j.prop].value"
                      :options="refData[j.prop]"
                      :placeholder-value="''"
                      :label="'name'"
                      :prop-value="'value'"
                      :label-animation="false"
                      :inline-search="true"
                      :footer="false"
                      :border="false"
                    />
                  </span>
                  <span
                    v-if="j.source.toLowerCase() === 'computed'"
                    class="output-computed"
                  >
                    <!-- {{ j.dataType }}
                    {{ j.source }} -->
                    {{ "f(x)" }}
                  </span>
                </span>
              </div>
            </hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
    </div>
   </div>

          </span>
        </div>
      </div>
    </section>
  </main>
  <div
             class="layout-footer"
             style="
               border-top: 1px solid #d8d8d8;
               padding-right: 40px;
               display: flex;
                 align-items: center;
                 height: 10vh;
               margin-right: -16px;
               margin-left: -16px;
             "
           >
             <div
               class="go-back"
               style="
                 width: 100%;
                 display: flex;
                 align-items: center;
                 justify-content: flex-end;
               "
             >
               <hlx-button
                 class="primary sm"
                 @click="goToEditDecisions()"
                 >Edit</hlx-button
               >
               <!-- <hlx-button
                 class="primary sm"
                 style="margin-left: 20px"
                 @click="saveAPIData"
                 >Edit</hlx-button
               > -->
             </div>
           </div>
</template>

<script>
//   import { DMNAxiosInstance } from "@/config/axiosConfig";
import dropdown from "@/components/Grid/DropdowncontainerComponent.vue";

import inputColMapModal from "@/components/Grid/inputColumnMappingModal.vue";
import outputColMapModal from "@/components/Grid/outputColumnMappingModal.vue";
import { DMNAxiosInstance } from "@/config/axiosConfig";

export default {
  components: {
    inputColMapModal,
    outputColMapModal,
    dropdown
  },
  data() {
    return {
      //utilities
      isNotify:false,
      notification:{},
      breadCrumbItems: [
        {
          label: "Rule studio",
          link: `/DMN/business_rules`
        },
        { label: "View decision", link: "/rules/list" }
      ],
      statusOptions:[   
         {value: "draft",
           name: "Draft",
           checked: false,
           disabled: false,
           id: 1,
         },
         {value: "inactive",
           name: "In-active",
           checked: false,
           disabled: false,
           id: 2,
         },
         {value: "active",
           name: "Active",
           checked: false,
           disabled: false,
           id: 2,
         },],
      ruleSetPreValue:[],
      ruleSetData: [],
      finalRuleSetData:[],
      isCustomNameError: false,
      customNameErrorMessage: "",
      //payload
      decisionPayload: {
        name: "",
        description: "",
        decisions: [],
        ruleId: "",
        ruleSet: [],
        status: "draft",
        hitPolicy: "unique",
        inputMapping: [],
        outputMapping: []
      },
      refData: {},
      readOnly:true,
      prevalue: "==",
      columndragstart: null,
      columndragend: null,
      gridData: {},
    intellisensePayload: {},
    intellisensePayload1: {},
    op_data: [
        {
          type: "number",
          name: "greater than",
          value: ">",
          checked: false,
          disabled: false
        },
        {
          type: "number",
          name: "less than",
          value: "<",
          checked: false,
          disabled: false
        },
        {
          type: "number",
          name: "greater than equal to",
          value: ">=",
          checked: false,
          disabled: false
        },
        {
          type: "number",
          name: "less than equal to",
          value: "<=",
          checked: false,
          disabled: false
        },
        {
          type: "number",
          name: "equal to",
          value: "==",
          checked: false,
          disabled: false
        },
        {
          type: "number",
          name: "not equal to",
          value: "!=",
          checked: false,
          disabled: false
        },
        {
          type: "boolean",
          name: "equal to",
          value: "==",
          checked: false,
          disabled: false
        },
        {
          type: "boolean",
          name: "not equal to",
          value: "!=",
          checked: false,
          disabled: false
        },
        {
          type: "number",
          name: "between",
          value: "between",
          checked: false,
          disabled: false
        },
        {
          type: "string",
          name: "is",
          value: "is",
          checked: false,
          disabled: false
        },
        {
          type: "string",
          name: "contains",
          value: "contains",
          checked: false,
          disabled: false
        },
        {
          type: "string",
          name: "isNot",
          value: "isNot",
          checked: false,
          disabled: false
        },
        {
          type: "string",
          name: "isNull",
          value: "isNull",
          checked: false,
          disabled: false
        },
        {
          type: "string",
          name: "isNotNull",
          value: "isNotNull",
          checked: false,
          disabled: false
        },
        {
          type: "string",
          name: "startsWith",
          value: "startsWith",
          checked: false,
          disabled: false
        },
        {
          type: "string",
          name: "endsWith",
          value: "endsWith",
          checked: false,
          disabled: false
        }
      ],
      inputIndex: null,
      outputIndex: null,
      tableHeadDetails: {},
      shwOutMap: false,
      shwInpMap: false,
      theads: {
        input: [
          {
            id: 1,
            objectMapping: {
              label: "",
              type: ""
            },
            dataType: "Data type",
            type: "input",
            operators: {},
            labelName: "Input label 1",
            source: "Manual",
            mode: "Manual",
            from: "",
            to: ""
          }
        ],
        output: [
          {
            id: 1,
            objectMapping: {
              label: "",
              type: ""
            },
            dataType: "Data type",
            type: "output",
            labelName: "Output label 1",
            source: "Manual",
            mode: "Manual",
            from: "",
            to: "",
            expression: ""
          }
        ]
      },
      tBody: [
        {
          "Input label 1": {
            value: "",
            value1: "",
            value2: "",
            operators: "",
            betweenValues: []
          },
          "Output label 1": {
            value: "",
            expression: ""
          }
        },
        {
          "Input label 1": {
            value: "",
            value1: "",
            value2: "",
            operators: "",
            betweenValues: []
          },
          "Output label 1": {
            value: "",
            expression: ""
          }
        }
      ],
      headvalue: [
        {
          prop: "Input label 1",
          type: "input",
          dataType: "String",
          source: "manual"
        },
        {
          prop: "Output label 1",
          type: "output",
          dataType: "String",
          source: "manual"
        }
      ],
      tableData: [
        {
          name: "vijay",
          cartoon: "pikachu",
          status: "Active",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false
        },
        {
          name: "Brock",
          cartoon: "charizard",
          status: "Inactive",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false
        },
        {
          name: "Misty",
          cartoon: "ninja",
          status: "Inactive",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false
        },
        {
          name: "May",
          cartoon: "frookie",
          status: "Active",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false
        },
        {
          name: "Feroz",
          cartoon: "pikachu",
          status: "Active",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false
        },
        {
          name: "Ganesh",
          cartoon: "charizard",
          status: "Inactive",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false
        },
        {
          name: "Mathan",
          cartoon: "ninja",
          status: "Inactive",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false
        },
        {
          name: "Pooja",
          cartoon: "frookie",
          status: "Active",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false
        },
        {
          name: "Raags",
          cartoon: "pikachu",
          status: "Active",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false
        },
        {
          name: "Gopi",
          cartoon: "charizard",
          status: "Inactive",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false
        },
        {
          name: "Sudhar",
          cartoon: "ninja",
          status: "Inactive",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false
        },
        {
          name: "Thanos",
          cartoon: "frookie",
          status: "Active",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false
        },
        {
          name: "Kang",
          cartoon: "frookie",
          status: "Active",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false
        },
        {
          name: "MODOK",
          cartoon: "frookie",
          status: "Active",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false
        },
        {
          name: "Loki",
          cartoon: "charizard",
          status: "Active",
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false
        }
      ],
      currPage: 1,
      rowsPerPage: 5,
      startdata: {},
      enddata: {},
      rowdragstart: null,
      rowdragend: null,
      timeoutId: null
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
    paginatedData1() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData1.slice(start, end);
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.tableData.length;
    },
  },
  watch: {
    tBody: {
      handler(val) {
        // this.bindExpression(this.theads.output, val);
        // this.processTBody(val);
        this.handleTBodyAndTheads(val, this.theads.output);
      },
      immediate: true,
      deep: true
    },
    theads: {
      handler(val) {
        // this.bindExpression(val.output, this.tBody);
        // this.processTBody(this.tBody);
        this.handleTBodyAndTheads(this.tBody, val.output);
      },
      immediate: true,
      deep: true
    },
    headvalue: {
      handler(Val) {
        Val;
      },
      immediate: true,
      deep: true
    }
  },
  async mounted(){
    if(this.$store.state.intellisenceData && Object.keys(this.$store.state.intellisenceData).length==0) {
        // await this.$store.dispatch('initIntellisenceData');
      }
      this.intellisensePayload = this.$store.state.intellisenceData
      console.log("this.intellisensePayload",this.intellisensePayload)
    if(this.$store.state.ruleSetData && this.$store.state.ruleSetData.length==0) {
        await this.$store.dispatch('initRuleSetData');
      }
      this.ruleSetData = this.$store.getters.ruleSetData
      // this.ruleSetData
      // Get decision table
      let decisionRes = await DMNAxiosInstance.get('rule_engine/decision_table/'+this.$route.params.id)
      console.log("decisionRes",decisionRes);
      if(decisionRes.data && decisionRes.data.ok == 0) {
        console.error(decisionRes.data.message)
        return
      }
      decisionRes = decisionRes.data.data
      this.ruleSetPreValue = decisionRes.ruleSet
      this.decisionPayload = decisionRes
      const gridData ={
        decisions:decisionRes.decisions,
        inputMapping:decisionRes.inputMapping,
        outputMapping:decisionRes.outputMapping,
      } ;
       console.log("gridData",gridData)
    this.destructureGridData(gridData);

    // Prepare deicison reference data
    this.headvalue.map(async head => {
      if (head.source === 'Reference data' && head.type.toLowerCase() == 'input') {
        let referneceDataRes = await DMNAxiosInstance.get('mapRef/reference_data/'+head.from)
        // if(referneceDataRes.data && referneceDataRes.data.data) {
            
        //     // this.refData[data.labelName] = referneceDataRes.data.data.map(item => {})
        // }
        this.refData[head.labelName] = referneceDataRes.data.data || []
      }
    })
  },
  methods: {
    goToEditDecisions(){
      this.$router.push({name:'decision-edit-v1',params:{
        decisionId:this.$route.params.id
      }})
    },
    computedLabel(data){
        if(data) {
            // console.log("🚀 ~ file: DecisionAddViewV1.vue:1657 ~ computedLabel ~ data:", data)
            // console.log("🚀 ~ file: DecisionAddViewV1.vue:1659 ~ computedLabel ~ Object.keys(data)[0]:", Object.keys(data)[0])
            return Object.keys(data)[0]
        }
    },

    // @updateDecision
    async updateDecision(){
        if(this.gridData.decisions && this.gridData.inputMapping && this.gridData.outputMapping) {
            let {decisions, inputMapping, outputMapping} = this.gridData
            this.decisionPayload = {...this.decisionPayload,decisions,inputMapping,outputMapping}
            let addedDecisionRes = await DMNAxiosInstance.patch('rule_engine/decision_table/v1/'+this.$route.params.decisionId,this.decisionPayload)
            if(addedDecisionRes.data.ok == 0) {
                this.notification = { type: "error", message: addedDecisionRes.data.message };
                this.isNotify = true
            } 
            this.notification = { type: "success", message: "Decisions added successfully" };
            this.isNotify = true
            setTimeout(() => {
                this.$router.push({name:'business-rules'})
            }, 800);
        }
    },
    async addRuleset(val1, val2) {
      let obj = {};
      obj.name = val1.name;
      let ruleSetDataRes = await DMNAxiosInstance.post(`/rule_engine/rule_set/add`, obj)
      if(ruleSetDataRes.data.ok ==0) return
      let addedData = ruleSetDataRes.data.data
      addedData.checked = true;
      addedData.disabled = false;
      let adddataoptions = [];
      adddataoptions = val2;
      adddataoptions.push(addedData);
      this.ruleSetData = adddataoptions;
    },
    // Handle ruleset action
    selectValue(val) {
        if(val && Array.isArray(val)){
            this.decisionPayload.ruleSet = val.map(item => {
                if(item.checked) {
                    return item._id
                }
            }).filter(item => item) ;
        }
    },

    pathTo() {
      this.$router.push({name:'business-rules',query:{Value:'Decision tables'}});
    },
    destructureGridData(payload) {
      this.theads.input = payload.inputMapping;
      this.theads.output = payload.outputMapping;
      this.tBody = payload.decisions;
      const concatenatedArray = [
        ...payload.inputMapping,
        ...payload.outputMapping
      ];

      // Add a new key named "prop" to each object and assign the value from "labelName" to "prop"
      const modifiedArray = concatenatedArray.map((item) => ({
        ...item,
        prop: item.labelName
      }));

      // Assign the modified array to the headvalue variable
      this.headvalue = modifiedArray;
    },
    pushOperators(e, val) {
      this.theads.input.filter((item) => {
        item.labelName === val ? (item.operators[e] = []) : "";
      });
    },
    rowadddata(index) {
      const firstObject = this.tBody[0];
      const duplicatedObject = JSON.parse(JSON.stringify(firstObject)); // Deep copy of the first object

      // Set all non-array values to ""
      for (const key in duplicatedObject) {
        for (const innerKey in duplicatedObject[key]) {
          if (!Array.isArray(duplicatedObject[key][innerKey])) {
            duplicatedObject[key][innerKey] = "";
          }
        }
      }
      this.tBody.splice(index, 0, duplicatedObject);
      // this.tBody.push(duplicatedObject)
    },
    deleterowdata(index) {
      this.tBody.splice(index, 1);
    },
    focusInput($event, bw) {
      if (!bw) {
        $event.target.parentElement.style.border = "1px solid #54bd95";
      } else {
        $event.target.parentElement.style.border = "1px solid #54bd95";
      }
    },
    blurInput($event, bw) {
      const active = $event.target === document.activeElement;
      if (!active) {
        if (!bw) {
          $event.target.parentElement.style.border = "1px solid transparent";
        } else {
          $event.target.parentElement.style.border = "1px solid transparent";
        }
      }
    },
    handleTBodyAndTheads(tBodyVal, theadsVal) {
      if (this.timeoutId === null) {
        this.timeoutId = setTimeout(() => {
          // Perform your logic here
          this.bindExpression(theadsVal, tBodyVal);
          this.processTBody(tBodyVal);

          // Reset the timeoutId after the execution
          this.timeoutId = null;
        }, 0);
      }
    },
    bindExpression(output, tbody) {
      for (const item of output) {
        const labelName = item.labelName;
        const expression = item.expression;

        for (const tbodyItem of tbody) {
          if (tbodyItem[labelName]) {
            tbodyItem[labelName].expression = expression;
            break;
          }
        }
      }
    },
    processTBody(originalPayload) {
      const payloadCopy = JSON.parse(JSON.stringify(originalPayload)); // Create a deep copy of the original payload

      // payloadCopy.forEach((item) => {
      //   const arr = Object.keys(item);
      //   arr.forEach((e) => {
      //     if (item[e].operators === "between") {
      //       const value = Number(item[e].value);
      //       const value1 = Number(item[e].value1);

      //       if (!isNaN(value) && !isNaN(value1)) {
      //         // Convert the values to numbers before sorting in descending order
      //         const sortedValues = [value, value1].sort((a, b) => b - a);

      //         item[e].betweenValues = sortedValues;
      //       }
      //     }
      //   });
      // });
      this.gridData["decisions"] = payloadCopy;
      this.gridData["inputMapping"] = this.theads.input;
      this.gridData["outputMapping"] = this.theads.output;
    },
    findHighestNumberLabel(payload) {
      let highestNumber = 0;
      // let highestNumberLabel = null;

      payload.forEach((item) => {
        const label = item.labelName;
        const numberMatchArr = label.split(" ");
        // Regular expression to extract numbers from the end of the label
        const numberMatch = Number(numberMatchArr[numberMatchArr.length - 1]);
        if (numberMatch) {
          const number = parseInt(numberMatch, 10);
          if (number > highestNumber) {
            highestNumber = number;
            // highestNumberLabel = label;
          }
        }
      });

      return Number(highestNumber);
    },
    rowdraggable(index) {
      document.querySelectorAll('.rowdraggable-icon').forEach((e) => {
        e.style.display = 'none';
      });
      document.querySelector("#rowdraggable-icon" + index).style.display =
        "flex";
      const icon = document.getElementById("rowdraggable-icon" + index);
      const parent = icon.parentElement;
      // console.log(parent,'parent');
      const { left } = parent.getBoundingClientRect();
      const iconLeft =  left;
      // console.log(iconLeft);
      this.rowdragposition = iconLeft - 8
    },
    outrowdraggable() {
      document.querySelectorAll(".rowdraggable-icon").forEach((e) => {
        e.style.display = "none";
      });
    },
    deleteInputThead(val) {
      const index = this.theads.input.findIndex((item) => item.id === val.id);
      this.theads.input.splice(index, 1);
      let inputColumnItems = this.headvalue.filter(
        (item) => item.type === "input"
      );
      let outputColumnItems = this.headvalue.filter(
        (item) => item.type === "output"
      );
      const index2 = inputColumnItems.findIndex(
        (item) => item.prop === val.labelName
      );
      inputColumnItems.splice(index2, 1);
      this.headvalue = inputColumnItems.concat(outputColumnItems);

      this.tBody.forEach((e) => {
        delete e[val.labelName];
      });
    },
    deleteOutputhead(val) {
      const index = this.theads.output.findIndex((item) => item.id === val.id);
      this.theads.output.splice(index, 1);
      let inputColumnItems = this.headvalue.filter(
        (item) => item.type === "input"
      );
      let outputColumnItems = this.headvalue.filter(
        (item) => item.type === "output"
      );
      const index2 = outputColumnItems.findIndex(
        (item) => item.prop === val.labelName
      );
      outputColumnItems.splice(index2, 1);
      this.headvalue = inputColumnItems.concat(outputColumnItems);

      this.tBody.forEach((e) => {
        delete e[val.labelName];
      });
    },
    addInputColumn(index, pos) {
      const idNumber = this.findHighestNumberLabel(this.theads.input) + 1;
      let inputColumnItems = this.headvalue.filter(
        (item) => item.type === "input"
      );
      let outputColumnItems = this.headvalue.filter(
        (item) => item.type === "output"
      );
      // console.log(inputColumnItems, outputColumnItems);
      const newItem = {
        id: Math.floor(Math.random() * 100000),
        objectMapping: {
          label: "",
          type: ""
        },
        dataType: "Data type",
        type: "input",
        operators: {},
        labelName: "Input label " + idNumber,
        source: "Manual",
        mode: "Manual",
        from: "",
        to: ""
      };
      const headProp = {
        prop: "Input label " + idNumber,
        type: "input",
        dataType: "String",
        source: "manual"
      };
      const tbodyItem = {
        value: "",
        value1: "",
        value2: "",
        operators: "",
        betweenValues: []
      };
      if (pos === "left") {
        this.theads.input.splice(index, 0, newItem);
        inputColumnItems.splice(index, 0, headProp);
        this.headvalue = inputColumnItems.concat(outputColumnItems);
      } else if (pos === "right") {
        this.theads.input.splice(index + 1, 0, newItem);
        inputColumnItems.splice(index + 1, 0, headProp);
        this.headvalue = inputColumnItems.concat(outputColumnItems);
      } else {
        // Handle invalid direction here if needed
        console.error('Invalid direction provided. Use "left" or "right".');
      }
      // console.log(index);

      const keyName = "Input label " + idNumber;
      // this.headvalue.push(headProp);

      this.tBody.forEach((e) => {
        e[keyName] = tbodyItem;
      });
      this.hideIcon(index, pos, "input-");
    },
    addOutputColumn(index, pos, $event) {
      const idNumber = this.findHighestNumberLabel(this.theads.output) + 1;
      let inputColumnItems = this.headvalue.filter(
        (item) => item.type === "input"
      );
      let outputColumnItems = this.headvalue.filter(
        (item) => item.type === "output"
      );
      if ($event.target.parentElement.id === "add-output-column-icon-right-0") {
        $event.target.parentElement.parentElement.parentElement.parentElement.style.borderTopRightRadius =
          "0px";
      }
      const newItem = {
        id: Math.floor(Math.random() * 100000),
        objectMapping: {
          label: "",
          type: ""
        },
        dataType: "Data type",
        type: "output",
        labelName: "Output label " + idNumber,
        source: "Manual",
        mode: "Manual",
        from: "",
        to: "",
        expression: ""
      };
      const headProp = {
        prop: "Output label " + idNumber,
        type: "output",
        dataType: "String",
        source: "manual"
      };
      const tbodyItem = {
        value: "",
        value1: "",
        value2: "",
        operators: "",
        betweenValues: []
      };
      if (pos === "left") {
        this.theads.output.splice(index, 0, newItem);
        outputColumnItems.splice(index, 0, headProp);
        this.headvalue = inputColumnItems.concat(outputColumnItems);
      } else if (pos === "right") {
        this.theads.output.splice(index + 1, 0, newItem);
        outputColumnItems.splice(index + 1, 0, headProp);
        this.headvalue = inputColumnItems.concat(outputColumnItems);
      } else {
        // Handle invalid direction here if needed
        console.error('Invalid direction provided. Use "left" or "right".');
      }

      const keyName = "Output label " + idNumber;
      this.tBody.forEach((e) => {
        e[keyName] = tbodyItem;
      });
      this.hideIcon(index, pos, "output-");
    },
    showIcon(index, pos, type) {
      let a =1
      if(a==1) return
      const icon = document.querySelector(
        "#add-" + type + "column-icon-" + pos + "-" + index
      );
      icon.style.display = "flex";
      const parent = icon.parentElement;
      // Get the position of the parent add-column div
      const { top, right } = parent.getBoundingClientRect();

      // Calculate the top and right positions for the icon
      const iconTop = top - icon.offsetHeight;
      const iconRight = window.innerWidth - right;
      // console.log(iconTop, iconRight);
      icon.style.top = iconTop + "px";
      if (pos === "right") {
        icon.style.right = iconRight - 10 + "px";
      } else {
        icon.style.right = iconRight - 4 + "px";
      }
    },
    columnover(index) {
      if(this.readOnly) return
      const icon = document.getElementById("columndrag" + index);
      icon.style.display = "flex";
      const parent = icon.parentElement;
      // Get the position of the parent add-column div
      const { top, right } = parent.getBoundingClientRect();

      // Calculate the top and right positions for the icon
      const iconTop = top - icon.offsetHeight;
      const iconRight = window.innerWidth - right;
      // console.log(iconTop, iconRight);
      icon.style.top = iconTop + 7 + "px";
      // if (pos === "right") {
      icon.style.right = iconRight + 7 + "px";
      // } else {
      // icon.style.right = iconRight - 4 + "px";
      // }
    },
    columnleave(index) {
      const icon = document.getElementById("columndrag" + index);
      icon.style.display = "none";
    },
    columnoutputover(index) {

      if(this.readOnly) return
      const icon = document.getElementById("columndragoutput" + index);
      icon.style.display = "flex";
      const parent = icon.parentElement;
      // Get the position of the parent add-column div
      const { top, right } = parent.getBoundingClientRect();

      // Calculate the top and right positions for the icon
      const iconTop = top - icon.offsetHeight;
      const iconRight = window.innerWidth - right;
      // console.log(iconTop, iconRight);
      icon.style.top = iconTop + 7 + "px";
      // if (pos === "right") {
      icon.style.right = iconRight + 7 + "px";
      // } else {
      // icon.style.right = iconRight - 4 + "px";
      // }
    },
    columnoutputleave(index) {
      const icon = document.getElementById("columndragoutput" + index);
      icon.style.display = "none";
    },

    hideIcon(index, pos, type) {
      let a = 1
      if(a == 1) return 
      const icon = document.querySelector(
        "#add-" + type + "column-icon-" + pos + "-" + index
      );
      icon.style.display = "none";
    },
    handleIconClick() {
      // Your logic when the icon is clicked goes here
      // console.log("Icon clicked!");
    },
    saveOutputThead(data) {
      // console.log(data);
      let beforeChange = this.theads.output.map((item) => {
        return item.id === data.id ? item.labelName : "no data";
      });
      let newVal = "";
      for (let index = 0; index < beforeChange.length; index++) {
        const element = beforeChange[index];
        if (element !== "no data") {
          newVal = element;
        }
      }
      beforeChange = newVal;
      const afterchange = data.labelName;
      // console.log(beforeChange, afterchange);
      this.replaceValuesFunc(
        beforeChange,
        afterchange,
        data.dataType,
        data.source,
        this.headvalue,
        this.tBody
      );
      const index = this.theads.output.findIndex((item) => item.id === data.id);
      // console.log(index);
      if (index !== -1) {
        this.theads.output = this.theads.output.map((item) => {
          return item.id === data.id
            ? JSON.parse(JSON.stringify(data))
            : JSON.parse(JSON.stringify(item));
        });
      }

      if (data.source === 'Reference data') {
        this.refData[data.labelName] = [{name: data.labelName, value: data.labelName}]
      }
    },
    async saveInputThead(data) {
      // console.log(data, 'IP DATA ADD');
      let beforeChange = this.theads.input.map((item) => {
        return item.id === data.id ? item.labelName : "no data";
      });
      let newVal = "";
      for (let index = 0; index < beforeChange.length; index++) {
        const element = beforeChange[index];
        if (element !== "no data") {
          newVal = element;
        }
      }
      beforeChange = newVal;
      const afterchange = data.labelName;
      // console.log(beforeChange, afterchange);
      this.replaceValuesFunc(
        beforeChange,
        afterchange,
        data.dataType,
        data.source,
        this.headvalue,
        this.tBody
      );
      const index = this.theads.input.findIndex((item) => item.id === data.id);
      // console.log(index);
      if (index !== -1) {
        this.theads.input = this.theads.input.map((item) => {
          return item.id === data.id
            ? JSON.parse(JSON.stringify(data))
            : JSON.parse(JSON.stringify(item));
        });
      }

      if (data.source === 'Reference data') {
        let referneceDataRes = await DMNAxiosInstance.get('mapRef/reference_data/'+data.from)
        // if(referneceDataRes.data && referneceDataRes.data.data) {
            
        //     // this.refData[data.labelName] = referneceDataRes.data.data.map(item => {})
        // }
        this.refData[data.labelName] = referneceDataRes.data.data || []
      }
    },
    replaceValuesFunc(
      beforeChange,
      afterChange,
      dataType,
      source,
      array1,
      array2
    ) {
      // console.log(beforeChange, afterChange, array1, array2);
      const index = array1.findIndex((item) => item.prop === beforeChange);
      if (index !== -1) {
        array1[index].prop = afterChange;
        array1[index].dataType = dataType;
        array1[index].source = source;
      }
      array2.forEach((obj) => {
        if (beforeChange in obj) {
          obj[afterChange] = JSON.parse(JSON.stringify(obj[beforeChange]));
          if (afterChange !== beforeChange) {
            delete obj[beforeChange];
          }
        }
      });
      // console.log(index, array1, array2);
    },
    openTheadMappingModal(type, val) {
      // console.log(type);
      if (type === "input") {
        const index = this.theads.input.length - 1;
        this.inputIndex = index;
        this.shwInpMap = true;
        this.tableHeadDetails = JSON.parse(JSON.stringify(val));
      } else if (type === "output") {
        const index = this.theads.output.length - 1;
        this.outputIndex = index;
        this.shwOutMap = true;
        this.tableHeadDetails = JSON.parse(JSON.stringify(val));
      }
    },
    columnstart(index) {
    //   console.log(index, "start");
      this.columndragstart = index;
    },
    columnenter(index) {
    //   console.log(index, "enter");
      this.columndragend = index;
    },
    columnend(value) {
      if (value === "input") {
        let input = [...this.theads.input];
        const elementToMove = input.splice(this.columndragstart - 1, 1)[0];
        input.splice(this.columndragend - 1, 0, elementToMove);
        this.theads.input = input;

        let inputColumnItems = this.headvalue.filter(
          (item) => item.type === "input"
        );
        // console.log(inputColumnItems);
        let outputColumnItems = this.headvalue.filter(
          (item) => item.type === "output"
        );

        const elementToMove1 = inputColumnItems.splice(
          this.columndragstart - 1,
          1
        )[0];
        inputColumnItems.splice(this.columndragend - 1, 0, elementToMove1);
        this.headvalue = inputColumnItems.concat(outputColumnItems);
        // console.log(this.headvalue);
      } else {
        let output = [...this.theads.output];
        const elementToMove = output.splice(this.columndragstart - 1, 1)[0];
        output.splice(this.columndragend - 1, 0, elementToMove);
        this.theads.output = output;

        let inputColumnItems = this.headvalue.filter(
          (item) => item.type === "input"
        );
        // console.log(inputColumnItems);
        let outputColumnItems = this.headvalue.filter(
          (item) => item.type === "output"
        );

        const elementToMove1 = outputColumnItems.splice(
          this.columndragstart - 1,
          1
        )[0];
        outputColumnItems.splice(this.columndragend - 1, 0, elementToMove1);
        this.headvalue = inputColumnItems.concat(outputColumnItems);
        // console.log(this.headvalue);
      }
    },
    start(index) {
      this.rowdragstart = index - 1;
      // this.startindex = index - 1;
    },
    enter(index) {
      this.rowdragend = index - 1;
      // this.endindex = index - 1;
    },
    end() {
      const elementToMove1 = this.tBody.splice(this.rowdragstart, 1)[0];
      this.tBody.splice(this.rowdragend, 0, elementToMove1);
    },
    applyColumns(val) {
      this.headvalue = [];
      val.forEach((e) => {
        if (e.checked === true) {
          this.headvalue.push(e);
        }
      });
    },
    arrange(val) {
      // console.log('arrange', val)
      this.re_arrange = val;
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    currentPage(val) {
      this.currPage = val;
    },
    changeRowsPerPage(count) {
      // console.log(count)
      this.rowsPerPage = count;
    },
    getDate(format) {
      const dateObj = new Date();
      const year = dateObj.getFullYear();
      const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
      const date = ("0" + dateObj.getDate()).slice(-2);

      if (format === "number") {
        return `${year}-${month}-${date}`;
      } else if (format === "string") {
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ];
        const monthName = monthNames[dateObj.getMonth()];
        return `${monthName} ${date}, ${year}`;
      } else {
        return "Invalid argument. Please enter 'number' or 'string'.";
      }
    }
  }
};
</script>

<style></style>
