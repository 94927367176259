<template>
    <div class="app-layout">
      <section class="right-panel">
        <hlx-alert-notification
          :notify="notification"
          :auto-close="true"
        />
        <div class="layout-basic-no-breadcrumb data-table-view">
          <div class="layout-header">
            <span class="title-container"
              ><span class="data">API</span> <i class="icon-clock"></i>
  
              <span class="subtitle">
                Manage API tasks
              </span></span
            >
            <span class="utils">
              <hlx-search @search-key="search"></hlx-search>
              <hlx-button
                class="secondary sm"
                style="display: flex"
              >
                <i
                  class="icon-filter-regular"
                  style="margin: 2px 4px 0 0"
                ></i>
                <span>Filter</span>
              </hlx-button>
              <span>
                <hlx-button
                  class="primary sm add-btn"
                  style="display: flex"
                  @click.stop="showAddContextMenu()"
                >
                  <i
                    class="icon-plus-circle-regular"
                    style="margin: 2px 4px 0 0"
                  ></i>
                  <span>Add API</span></hlx-button
                >
    
              </span>
            </span>
          </div>
          <div
            class="layout-body"
            style="margin-top: 20px !important"
          >
            <hlx-modal
              :modal-active="delete_modal_active"
              :height="'220px'"
              :width="'400px'"
              :modal="true"
              @close="delete_modal_active = false"
            >
              <template #header>
                <div style="margin-left: 0px">Delete data</div>
              </template>
              <template #content>
                <div class="modal-content">
                  <p>
                    Are you sure you want to delete <b>{{ delete_name }}</b
                    >?
                  </p>
                </div>
              </template>
              <template #footer>
                <hlx-button
                  class="secondary sm"
                  @click="delete_modal_active = false"
                  >Cancel</hlx-button
                >
                <hlx-button
                  class="primary sm"
                  style="margin-left: 16px"
                  @click="deleteExecRow"
                  >Delete</hlx-button
                >
              </template>
            </hlx-modal>
            <hlx-table
              v-if="tableData.length > 0"
              :column-count="theads.length + 2"
              :border="['table', 'header', 'horizontal']"
              :bold-headers="false"
              :row-hover="true"
              theme="grey"
              :striped-rows="false"
              :re-arrange-columns="re_arrange"
              :re-arrange-heads="theads"
              :height="paginatedData.length * 40 + 42"
              @close-rearrange="arrange"
              @apply-rearranged-columns="applyColumns"
            >
              <template #thead>
                <hlx-table-head
                  :width="60"
                  :align="'left'"
                  style="padding: 0 15px !important"
                  :fixed="'left'"
                  >{{ 'S.no' }}</hlx-table-head
                >
                <hlx-table-head
                  v-for="(i, index) in duplicateTheads"
                  :key="index"
                  :prop="i.prop"
                  :sortable="i.sortable"
                  :resizable="i.resizable"
                  style="position: sticky; top: 0px"
                  :width="i.width !== undefined ? i.width : ''"
                  :align="i.align !== undefined ? i.align : ''"
                  @sorting_func="sorting_Data"
                >
                  {{ i.label }}
                </hlx-table-head>
                <hlx-table-head
                  v-show="editMode == true"
                  :width="60"
                  :fixed="'right'"
                  style="max-width: 60px"
                >
                  <span class="action-icon"
                    ><i
                      class="icon-settings-regular"
                      @click="arrange(true)"
                    ></i></span
                ></hlx-table-head>
              </template>
              <template #tbody>
                <tr
                  v-for="(i, index) in paginatedData"
                  id=""
                  :key="index"
                >
                  <hlx-table-cell
                    :align="'center'"
                    :fixed="'left'"
                    >{{ serialNumber(index) }}</hlx-table-cell
                  >
                  <hlx-table-cell
                    v-for="(j, col_index) in duplicateTheads"
                    :key="col_index"
                    :align="j.align"
                  >
                    <div
                      v-if="j.prop === 'name'"
                      class="link-name"
                      style="
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                      :style="{
                        width: '150px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }"
                      @click="gotoViewPage(i)"
                    >
                      {{ i[j.prop] }}
                    </div>
                    <div v-else-if="j.prop === 'updatedAt'">
                      {{ formatDate(i[j.prop]) }}
                    </div>
                    <div
                      v-else-if="j.prop.toLowerCase() === 'description'"
                      style="
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                      :style="
                        j.width === null
                          ? {
                              width: '170px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }
                          : ''
                      "
                    >
                      {{ i[j.prop] }}
                    </div>
                    <div v-else>
                      {{ i[j.prop] }}
                    </div>
                  </hlx-table-cell>
                  <hlx-table-cell
                    v-show="editMode == true"
                    :align="'center'"
                    :width="60"
                    style="max-width: 60px"
                    :fixed="'right'"
                  >
                    <span class="action-icon">
                      <i
                        style="position: relative"
                        class="icon-more-vertical-filled"
                        :class="i.context === true ? 'active-action' : ''"
                        @click="crudContextMenu($event, serialNumber(index - 1))"
                      >
                        <span
                          v-if="index < rowsPerPage / 2"
                          style="position: absolute"
                        >
                          <hlx-context-menu
                            style="
                              z-index: 9000;
                              margin-right: 83px !important;
                              margin-top: -10px;
                              box-shadow: 0 0 20px 0 #d8d8d8;
                            "
                            :options="crudOptions"
                            :data="index.toString()"
                            :show="i.context == true"
                            @chosen="closeCrudContextMenu($event, i)"
                          />
                        </span>
                        <span
                          v-else
                          style="position: absolute"
                        >
                          <hlx-context-menu
                            style="
                              z-index: 9000;
                              margin-right: 83px !important;
                              margin-top: -128px;
                              box-shadow: 0 0 20px 0 #d8d8d8;
                            "
                            :options="crudOptions"
                            :data="index.toString()"
                            :show="i.context == true"
                            @chosen="closeCrudContextMenu($event, i)"
                          />
                        </span>
                      </i>
                    </span>
                  </hlx-table-cell>
                </tr>
              </template>
            </hlx-table>
            <hlx-table
              v-else
              :column-count="theads.length + 2"
              :border="['table', 'header']"
              :bold-headers="false"
              theme="grey"
              :striped-rows="false"
              :re-arrange-columns="re_arrange"
              :re-arrange-heads="theads"
              :height="10 * 40 + 42"
              @close-rearrange="arrange"
              @apply-rearranged-columns="applyColumns"
            >
              <template #thead>
                <hlx-table-head
                  :width="60"
                  :align="'left'"
                  >{{ 'S.no' }}</hlx-table-head
                >
                <hlx-table-head
                  v-for="(i, index) in duplicateTheads"
                  :key="index"
                  :prop="i.prop"
                  :sortable="i.sortable"
                  :resizable="i.resizable"
                  style="position: sticky; top: 0px"
                  :width="i.width !== undefined ? i.width : ''"
                  :align="i.align !== undefined ? i.align : ''"
                  @sorting_func="sorting_Data"
                >
                  {{ i.label }}
                </hlx-table-head>
                <hlx-table-head :width="100">
                  <span class="action-icon"
                    ><i
                      class="icon-settings-regular"
                      @click="arrange(true)"
                    ></i></span
                ></hlx-table-head>
              </template>
              <template #tbody>
                <tr>
                  <hlx-table-cell
                    :colspan="theads.length + 2"
                  ></hlx-table-cell>
                </tr>
                <tr>
                  <hlx-table-cell
                    :colspan="theads.length + 2"
                  ></hlx-table-cell>
                </tr>
                <tr>
                  <hlx-table-cell
                    :colspan="theads.length + 2"
                  ></hlx-table-cell>
                </tr>
                <tr>
                  <hlx-table-cell
                    :colspan="theads.length + 2"
                  ></hlx-table-cell>
                </tr>
                <tr>
                  <hlx-table-cell :colspan="theads.length + 2">{{
                    'No data'
                  }}</hlx-table-cell>
                </tr>
              </template>
            </hlx-table>
          </div>
          <div class="layout-footer">
            <div class="pagination-container">
              <hlx-pagination
              v-model:rows-per-page="externalRowsPerPage"
                :total="totalRows"
                rows-per-page
                enable-rows-per-page
                :rows-per-page-list="[10, 15]"
                :options="{
                  attributes: {
                    rowsPerPageTitle: true,
                    rowsPerPageResult: true,
                    rowsPerPageDropdown: {
                      position: {
                        top: -70,
                        right: 0,
                      },
                    },
                  },
                }"
                @current-page="currentPage"
                @updated:rows-per-page="changeRowsPerPage"
              ></hlx-pagination>
            </div>
          </div>
        </div>
      </section>
    </div>
  </template>
  <script>
  // import axios from "axios";
  import { DMNAxiosInstance } from '@/config/axiosConfig';
  
  export default {
    data() {
      return {
        externalRowsPerPage:10,
        editPermission: [],
        viewPermission: [],
        token: localStorage.token,
        editMode: true,
        options: [],
        notification: {},
        delete_modal_active: false,
        addContextOptions: [
          {
            label: 'Manual',
            value: 'manual',
            icon: 'icon-code-square-regular',
          },
          {
            label: 'API',
            value: 'api',
            icon: 'icon-globe-regular',
          },
          {
            label: 'Upload',
            value: 'upload',
            icon: 'icon-file-upload-regular',
          },
        ],
        crudOptions: [
          {
            label: 'Edit',
            value: 'edit',
            icon: 'icon-edit-regular',
          },
          {
              label: 'View',
              value: 'view',
              icon: 'icon-eye-regular',
            },
            {
              label: 'Delete',
              value: 'delete',
              icon: 'icon-trash-regular',
            },
        ],
        re_arrange: false,
        currPage: 1,
        rowsPerPage: 10,
        show_add_menu: false,
        show_crud_menu: false,
        theads: [
          {
            name: 'Name',
            checked: true,
            id: 1,
            disabled: false,
            prop: 'name',
            label: 'Name',
            sortable: true,
            resizable: false,
            width: 150,
            align: 'left',
          },
          {
            name: 'Description',
            checked: true,
            id: 2,
            disabled: false,
            prop: 'description',
            label: 'Description',
            sortable: true,
            resizable: false,
            width: null,
            align: 'left',
          },
          {
            name: 'Last modified',
            checked: true,
            id: 6,
            disabled: false,
            prop: 'updatedAt',
            label: 'Last modified',
            sortable: true,
            resizable: false,
            width: 180,
            align: 'left',
          },
        ],
        add_context_options: [
          {
            label: 'Manual',
            icon: 'icon-add-file',
          },
          {
            label: 'API',
            icon: 'icon-www',
          },
          {
            label: 'Upload',
            icon: 'icon-upload',
          },
        ],
        crud_options: [
          {
            label: 'Execute',
            icon: 'icon-double-gear',
          },
          {
            label: 'Edit',
            icon: 'icon-pen',
          },
          {
            label: 'delete',
            icon: 'icon-trash-filled',
          },
        ],
        tableData: [],
        duplicateTableData: [],
        duplicateTheads: [],
        searchTerm: '',
        delete_id: '',
        delete_name: '',
        showAlert: false,
        alertMessage: 'Row deleted successfully!',
        alertType: 'Success',
        searchvalue: '',
        logs: [],
      };
    },
    computed: {
      paginatedData() {
        const start = (this.currPage - 1) * this.rowsPerPage;
        const end = start + this.rowsPerPage;
        return this.tableData.slice(start, end);
      },
      showingFrom() {
        return (this.currPage - 1) * this.rowsPerPage + 1;
      },
      showingTo() {
        const lastItem = this.showingFrom + this.rowsPerPage - 1;
        return lastItem > this.totalRows ? this.totalRows : lastItem;
      },
      totalRows() {
        return this.tableData.length;
      },
      searchData() {
        return this.data_list.filter((item) =>
          item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      },
    },
    async mounted() {
      // let token = {
      //   headers: {
      //     Authorization: "Bearer  " + this.token,
      //   }
      // };
      // const val = await MLTAxiosInstance.get(`/partymanagement/individual/auth`);
      // this.company = val.data.company;
      // this.userId = val.data.company;
      // // 
      // const access = await MLTAxiosInstance.get(
      //   `/partyRole/get/${val.data.userId}`
      // );
  
      // let arr = access.data;
      // this.editPermission = [];
      // // 
      // if (arr.permission.User_settings.edit == false) {
      //   this.editPermission.push('setting');
      // }
      // if (arr.permission.User_settings.view == false) {
      //   this.viewPermission.push('setting');
      // }
      // if (arr.permission.Schema.edit == false) {
      //   this.editPermission.push('schema');
      // }
      // if (arr.permission.Schema.view == false) {
      //   this.viewPermission.push('Schema');
      // }
      // if (arr.permission.Test_data.view == false) {
      //   this.viewPermission.push('Database', 'Execute');
      // }
      // if (arr.permission.Test_data.edit == false) {
      //   this.editPermission.push('testdata', 'execute');
      // }
      // if (arr.permission.Rules.view == false) {
      //   this.viewPermission.push('Business rule');
      // }
      // if (arr.permission.Rules.edit == false) {
      //   this.editPermission.push('Business rule');
      // }
      // if (this.editPermission.includes('execute') == true) {
      //   // 
      //   //   this.editPermission.includes(""),
      //   //   this.editMode,
      //   //   this.editPermission,
      //   //   "done"
      //   // );
      //   this.editMode = false;
      // }
  
      // this.$store.commit('token', this.$route.params.token);
      this.duplicateTheads = JSON.parse(JSON.stringify(this.theads));
      await this.getAllExecData();
    },
    methods: {
      search(val) {
        // 
        this.searchvalue = val;
        if (this.searchvalue && this.searchvalue != '') {
          this.tableData = this.duplicateTableData.filter((item) => {
            return (
              item.name.toString().toLowerCase().includes(this.searchvalue) ||
              item.description
                .toLowerCase()
                .toString()
                .includes(this.searchvalue.toLowerCase()) ||
              item.schemaName
                .toLowerCase()
                .toString()
                .includes(this.searchvalue.toLowerCase()) ||
              item.updatedAt.toString().includes(this.searchvalue.toLowerCase())
            );
          });
        } else {
          this.tableData = this.duplicateTableData;
        }
      },
      formatDate(dateString) {
        const date = new Date(dateString);
        const options = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        };
        return new Intl.DateTimeFormat('en-US', options)
          .format(date)
          .replace(',', '');
      },
      navTo(active) {
        this.$store.commit('token', this.$route.params.token);
        // 
        if (active.name == 'Home') {
          this.navHome();
        } else if (active.name == 'logout') {
          this.logout();
        } else if (active.name == 'settings') {
          this.setting();
        } else if (active.name == 'Schema') {
          this.schema();
        } else if (active.name == 'Business rule') {
          this.ruled();
        } else if (active.name === 'Database') {
          this.execute();
        } else if (active.name == 'Execute') {
          this.exe();
        } else if (active.name == 'Help') {
          this.help();
        } else {
          this.audit();
          // 
        }
      },
      audit() {
        this.$router.push(`/DMN/audit`);
      },
      help() {
        window.open('http://documentation.halleyx.com');
      },
      ruled() {
        this.$router.push(`/DMN/business_rules`);
      },
      exe() {
        this.$router.push(`/DMN/rule_validation`);
      },
      execute() {
        this.$router.push(`/DMN/reference_data`);
      },
      schema() {
        this.$router.push(`/DMN/schemas`);
      },
  
      logout() {
        this.$router.push('/');
      },
      setting() {
        this.$router.push(`/settings`);
      },
      async navHome() {
        this.$router.push(`/DMN/dashboard`);
      },
  
      async deleteExecRow() {
        this.delete_modal_active = false;
        await DMNAxiosInstance.delete(
          `/api/${this.delete_id}`
        )
          .then(() => {
            this.showAlert = true;
            this.getAllExecData();
            if (this.paginatedData.length === 1) {
              this.currPage = this.currPage - 1;
            }
            setTimeout(() => {
              this.showAlert = false;
            }, 3000);
          })
          .catch((error) => {
            error;
            // 
            this.showAlert = true;
            this.alertMessage = 'Cannot delete row. Try again!';
            this.alertType = 'danger';
            setTimeout(() => {
              this.showAlert = false;
            }, 3000);
          });
      },
      async getAllExecData() {
        this.logs = [];
        await DMNAxiosInstance.get(
          `/api`
        ).then((res) => {
          this.logs = res.data.data;
          // 
        });
        await DMNAxiosInstance.get(`/api/`)
          .then((response) => {
            // Handle the response here
            // this.schemaOptions = response.data
            this.tableData = response.data.data;
  
            
            this.tableData = this.tableData.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );
            this.tableData.forEach((e, index) => {
              e['index'] = index;
              e.context = false;
            });
  
            // let result = [];
            // result = this.tableData.map((obj1) => {
            //   const matchedData = this.logs.filter(
            //     (obj2) => obj2.id === obj1._id
            //   );
            //   const execCount = matchedData.length;
            //   const x =
            //     matchedData.reduce((sum, obj) => sum + obj.executedDuration, 0) /
            //       matchedData.length || 0;
            //   const averageExecDuration = x.toFixed(2) + " ms";
            //   return { ...obj1, matchedData, averageExecDuration, execCount };
            // });
  
            // this.tableData = result;
  
            this.duplicateTableData = JSON.parse(JSON.stringify(this.tableData));
          })
          .catch((error) => {
            // Handle the error here
            console.error(error);
          });
  
        document.addEventListener('click', (event) => {
          if (!event.target.classList.contains('add-btn')) {
            this.show_add_menu = false;
          }
          if (!event.target.classList.contains('icon-more-vertical-filled')) {
            // const elements = document.querySelectorAll("[class*=crud]");
            // for (const element of elements) {
            //   element.style.display = "none";
            // }
  
            if (!event.target.classList.contains('icon-more-vertical-filled')) {
              this.tableData.forEach((e) => {
                if (e.context !== undefined) {
                  e.context = false;
                }
              });
            }
          }
        });
      },
      crudContextMenu($event, index) {
        // 
        // this.tableData[index]);
        if ($event.target.className === 'icon-more-vertical-filled') {
          this.tableData.forEach((e) => {
            if (e.context !== undefined) {
              e.context = false;
            }
          });
          this.tableData[index].context = true;
          // 
        } else {
          this.tableData.forEach((e) => {
            if (e.context !== undefined) {
              e.context = false;
            }
          });
        }
      },
      serialNumber(index) {
        return (this.currPage - 1) * this.rowsPerPage + index + 1;
      },
      searchKey(val) {
        this.searchTerm = val;
      },
      gotoDataExecuteView(id) {
        this.$router.push({
          name: 'execute-data',
          query: { id: id },
        });
      },
      showAddContextMenu() {
        this.$router.push({
          name: 'add-api',
        });
        // this.show_add_menu = !this.show_add_menu;
      },
      showListCrud(index) {
        if (document.querySelector('.crud' + index).style.display === 'block') {
          document.querySelector('.crud' + index).style.display = 'none';
        } else {
          const elements = document.querySelectorAll('[class*=crud]');
          for (const element of elements) {
            element.style.display = 'none';
          }
          document.querySelector('.crud' + index).style.display = 'block';
        }
      },
      fromCrudContextMenu($event, val) {
        if ($event === 'Execute') {
          this.gotoDataExecuteView(val);
        }
      },
      fromAddContextMenu(val) {
        if (val === 'Manual') {
          this.$router.push({
            name: 'execute-add-manual',
            query: {},
          });
        } else if (val === 'API') {
          this.$router.push({
            name: 'execute-add-api',
            query: {},
          });
        } else if (val === 'Upload') {
          this.$router.push({
            name: 'execute-add-upload',
            query: {},
          });
        }
      },
      applyColumns(val) {
        this.duplicateTheads = [];
        val.forEach((e) => {
          if (e.checked === true) {
            this.duplicateTheads.push(e);
          }
        });
      },
      arrange(val) {
        // 
        this.re_arrange = val;
      },
      currentPage(val) {
        this.currPage = val;
      },
      changeRowsPerPage(count) {
        // 
        this.rowsPerPage = count;
      },
      sorting_Data(data, data1) {
        // 
        if (data === 'icon-angle-up-small-filled sorting-icon') {
          const x = [...this.tableData].sort(function (a, b) {
            // 
            if (typeof a === 'object') {
              // 
              if (typeof a[data1] === 'string') {
                return String(b[data1])
                  .toLowerCase()
                  .localeCompare(String(a[data1]).toLowerCase());
              } else if (typeof a[data1] === 'number') {
                return b[data1] - a[data1];
              }
            } else if (typeof a === 'string') {
              return String(b)
                .toLowerCase()
                .localeCompare(String(a).toLowerCase());
            } else if (typeof b === 'number') {
              return b - a;
            } else {
              return 0;
            }
            return 0;
          });
          this.tableData = x;
        } else if (data === 'icon-angle-down-small-filled sorting-icon') {
          const x = [...this.tableData].sort(function (a, b) {
            if (typeof a === 'object') {
              if (typeof a[data1] === 'string') {
                return String(a[data1])
                  .toLowerCase()
                  .localeCompare(String(b[data1]).toLowerCase());
              } else if (typeof a[data1] === 'number') {
                return a[data1] - b[data1];
              }
            } else if (typeof a === 'string') {
              return String(a)
                .toLowerCase()
                .localeCompare(String(b).toLowerCase());
            } else if (typeof a === 'number') {
              return a - b;
            } else {
              return 0;
            }
            return 0;
          });
          this.tableData = x;
        } else if (data === 'icon-angle-up-small-filled') {
          this.tableData = this.duplicateTableData;
        } else if (data === 'icon-angle-down-small-filled') {
          this.tableData = this.duplicateTableData;
        }
      },
      Contextmenu(index) {
        if (event.target.className === 'icon-more-vertical-filled') {
          this.tableData[index].context.show = true;
        } else {
          // this.clicked = false;
          this.tableData.forEach((e) => {
            if (e.context !== undefined) {
              e.context.show = false;
            }
          });
        }
        // // 
      },
      closeAddContextMenu(data1, data2) {
        const indexvalue = parseInt(data2);
        indexvalue;
        // 
        this.fromAddContextMenu(data1);
      },
      closeCrudContextMenu($event, i) {
        if ($event === 'Edit') {
          this.$router.push({
            name: 'edit-api',
            params: {id: i._id },
          });
        } else if ($event === 'Delete') {
          // this.$router.push({
          //   name: "execute-add-api",
          // });
          // // 
          this.delete_id = i._id;
          this.delete_name = i.name;
          this.delete_modal_active = true;
        }  else if ($event === 'View') {
          this.$router.push({
            name: 'view-api',
            query: { mode: i.inputMode, id: i._id },
          });
        }
      },
      gotoViewPage(i) {
        this.$router.push({
          name: 'view-execute-data',
          query: { mode: i.inputMode, id: i._id },
        });
      },
    },
  };
  </script>
  
  <style></style>
  