<template>
  <div
    class="activelog_page_supplier"
    style="padding-right: 10px; margin-bottom: 10px; overflow: auto"
  >
    <div class="header-add-supplier">
      <div class="tablehead-add-supplier">
        <!-- Activity log -->
        <span v-if="staticData">{{ staticData?.cmAccountLeftPanelActivityLog?.label[loginUserDetails]?.label }}</span>
      </div>
      <div
        v-if="activityData?.length > 0 || searchactivity != ''"
        class="search-add-supplier"
      >
        <span>
          <hlx-search
          v-if="staticData"          
          :label-value=staticData?.search?.label[loginUserDetails]?.label
          @search-key="activitysearch"></hlx-search></span>
      </div>
    </div>

    <section
      v-if="activityData?.length > 0 || searchactivity != ''"
      class="section_accordtion_supplier"
    >
      <div class="notepad_supplier_accordion">
        <div class="header_activelog">
          <span
            class="Actions"
            :style="{
              width: module === 'leftPanal' ? '435px' : '392px',
            }"
            >
            <!-- Actions -->
            <span v-if="staticData">{{ staticData?.add?.label[loginUserDetails]?.label }}</span>
            </span
          >
          <span
            class="Made"
            :style="{
              width: module === 'leftPanal' ? '430px' : '392px',
            }"
            >
            <!-- Updated by -->
            <span v-if="staticData">{{ staticData?.add?.label[loginUserDetails]?.label }}</span>
            </span
          >

          <span class="Last" style="width: 290px">
            <!-- Last updated -->
            <span v-if="staticData">{{ staticData?.add?.label[loginUserDetails]?.label }}</span>
          </span>
        </div>
        <hlx-accordion
          v-for="(item, index) in activityDataPaginated"
          :key="index"
          :multiple="false"
          :header-border="true"
          :direction="'left'"
          :class="{ 'hide-arrow_activtylog': item.detailedAction.length === 0 }"
        >
          <template #item-title>
            <div class="header_activelog_inside">
              <span
                v-if="item.detailedAction.length === 0"
                class="whiteDapa"
              ></span>
              <span class="Actions" style="width: 392px">{{
                item.message.charAt(0).toUpperCase() + item.message.slice(1)
              }}</span>
              <span class="made_profile" style="width: 392px">
                <!-- <span :style="avatarStyle"> {{ initials }} </span> -->

                <span class="Made"> {{ item.user }}</span></span
              >
              <span class="Last" style="width: 290px">{{
                formatDate(item.lastUpdate)
              }}</span>
            </div>
          </template>
          <template
            v-if="staticData &&
              item.detailedAction.length > 0 &&
              !(
                item.detailedAction.length === 1 &&
                item.detailedAction[0].path?.includes('updatedAt')
              )
            "
            #item-body
          >
            <div class="body_activelog_inside">
              <span class="Actions" style="width: 392px">
                <!-- Field -->
                <span v-if="staticData">{{ staticData?.field?.label[loginUserDetails]?.label }}</span>
              </span>
              <span class="Made" style="width: 392px">
                <!-- Change from -->
                <span v-if="staticData">{{ staticData?.changeFrom?.label[loginUserDetails]?.label }}</span>
              </span>
              <span class="Last" style="width: 290px">
                <!-- Change to -->
                <span v-if="staticData">{{ staticData?.changeTo?.label[loginUserDetails]?.label }}</span>
              </span>
            </div>
            <span
              v-for="(items, indexs) in item.detailedAction"
              :key="indexs"
              class="activitylog_loop"
            >
              <div
                v-if="!items?.path?.includes('updatedAt')"
                class="body_activelog_inside_detail"
              >
                <span style="width: 392px">{{
                  items?.property?.charAt(0)?.toUpperCase() +
                  items?.property?.slice(1)
                }}</span>

                <span
                  :id="'lhsData' + indexs + index"
                  class="ellipsis_activityLog"
                  style="width: 280px"
                  @mouseover="
                    tooltipover(
                      'lhsData' + indexs + index,
                      'tooltip-text' + 'lhsData' + indexs + index
                    )
                  "
                  @mouseleave="
                    tooltipleave(
                      'lhsData' + indexs + index,
                      'tooltip-text' + 'lhsData' + indexs + index
                    )
                  "
                >
               

                {{
  isDate(lhsData(items))
    ? dateTimeFormatter(lhsData(items))
    : (lhsData(items) === 0 ? "0" : lhsData(items) || "-")
}}
                  <p
                    id="tooltip-text"
                    :class="'tooltip-text' + 'lhsData' + indexs + index"
                  >
                    {{
                      lhsData(items) !== "" && lhsData(items) !== undefined
                        ? lhsData(items)
                        : "-"
                    }}
                  </p>
                </span>
                <span style="width: 116px">
                  <img
                    class="file-icon"
                    src="@/assets/svg/activitylogArrow.svg"
                    alt=""
                  />
                </span>

                <span
                  :id="'rhsData' + indexs + index"
                  class="ellipsis_activityLog"
                  style="width: 290px"
                  @mouseover="
                    tooltipover(
                      'rhsData' + indexs + index,
                      'tooltip-text' + 'rhsData' + indexs + index
                    )
                  "
                  @mouseleave="
                    tooltipleave(
                      'rhsData' + indexs + index,
                      'tooltip-text' + 'rhsData' + indexs + index
                    )
                  "
                >
                  {{
                    isDate(rhsData(items))
                      ? dateTimeFormatter(rhsData(items))
                      : rhsData(items) || "-"
                  }}
                  <p
                    id="tooltip-text"
                    :class="'tooltip-text' + 'rhsData' + indexs + index"
                  >
                    {{
                      rhsData(items) !== "" && rhsData(items) !== undefined
                        ? rhsData(items)
                        : "-"
                    }}
                  </p>
                </span>
              </div>
            </span>
          </template>
        </hlx-accordion>
      </div>
      <!-- <div style="height: 90px"></div> -->
    </section>

    <div
      v-if="activityData?.length > 0 || searchactivity != ''"
      class="activirylog_pagination"
    >
      <hlx-pagination
        v-model:rows-per-page="externalRowsPerPage"
        :label-value=paginationLang
        :total="totalfromprop"
        enable-rows-per-page
        :rows-per-page-list="[10, 15, 20]"
        :options="{
          attributes: {
            rowsPerPageTitle: true,
            rowsPerPageResult: true,
            rowsPerPageDropdown: {
              position: {
                top: 0,
                right: 1,
              },
            },
          },
        }"
        @current-page="currentPage"
        @updated:rows-per-page="changeRowsPerPage"
      ></hlx-pagination>
    </div>
  </div>
  <div v-if="activityData?.length === 0 && searchactivity === ''">
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
      "
    >
      <img
        height="390px"
        src="@/assets/images/supplierActivityLog.svg"
        alt=""
      />
    </div>
  </div>
</template>
<script>

import {
  UPCAxiosInstance,
  // MLTAxiosInstance

} from "@/config/axiosConfig";
export default {
  props: {
    activityData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    mode: {
      type: String,
      default: "edit",
    },
    module: {
      type: String,
      default: "",
    },
    totalfromprop: {
      type: Number,
      default: 0,
    },
  },
  emits: ["activitycurrentpage", "activityrowpage"],
  data() {
    return {
      loginUserDetails: {},
      staticData:null,
      activityDataPaginated: [],
      tableData: [],
      searchactivity: "",
      currPage: 1,
      rowsPerPage: 10,
      externalRowsPerPage: 10,
      excludedColors: ["#000000", "#FFFFFF", "#FF0000"],
      randomColor: "",
      overview: false,
    };
  },
  computed: {
    
    paginationLang() { 
      if (this.staticData) {      
      return {
          Rows_per_page : this.staticData?.comRowPerPage?.label[this.loginUserDetails]?.label,
          Showing: this.staticData?.comShowing?.label[this.loginUserDetails]?.label,
          of: this.staticData?.comOf?.label[this.loginUserDetails]?.label,
          results: this.staticData?.comResult?.label[this.loginUserDetails]?.label,
          Go_to: this.staticData?.comGoTo?.label[this.loginUserDetails]?.label,
        }
      } else {
        return {
          Rows_per_page : "Rows per page",
          Showing: "Showing",
          of: "of",
          results: "results",
          Go_to: "Go to",
        }
      }
    },
  },
  watch: {
    activityData: {
      handler(val) {
        //console.log(val, "lastUpdate");
        this.activityDataPaginated = val;
        this.tableData = JSON.parse(JSON.stringify(val));
      },
      immediate: true,
      deep: true,
    },
    data: {
      handler(val) {
        this.payload = val;
        //console.log(val, "product value");

        this.activityDataPaginated.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
      },
      immediate: true,
      deep: true,
    },
  },

  async mounted() {
    await this.$store.dispatch("loginTenantDetails");
    await this.$store.dispatch("loginUserDetails");
    // loginUserDetails
    // await this.$store.dispatch("loginTenantDetails");
    // let r = await this.$store.getters.loginTenantDetails;
    console.log("loginTenantDetails resrfsef:", this.$store.getters.loginTenantDetails);
    console.log("loginUserDetails resrfsef:", this.$store.getters.loginUserDetails);

    this.loginUserDetails = this.$store?.getters?.loginUserDetails?.languageAbility[0]?.code;

    // this.loginUserDetails.languageAbility[0].name
    // this.loginUserDetails.languageAbility[0].code

    try {
      let organisationInfo = await UPCAxiosInstance.get('/multi-language');
      this.staticData = organisationInfo?.data?.filter(itm => itm && itm.modules)?.[0]?.modules;
      console.log("this.staticData:",organisationInfo , this.staticData);
      
    }
    catch (error) {
      console.error("error::", error)
    }
  },
  methods: {
    rhsData(rhs) {
      console.log(rhs.action, "act123 rhsaction");
      if (rhs.action != "REMOVED") {
        if (rhs?.item?.valueSuffix) {
          const valueWithSuffix = `${rhs?.item?.value} ${rhs?.item?.valueSuffix}`;
          return this.capitalizeFirstLetter(valueWithSuffix);
        }
        if (rhs?.item?.name) {
          const nameValue = rhs.item.name;
          return this.capitalizeFirstLetter(nameValue);
        } else if (rhs?.item?.note) {
          const noteValue = rhs.item.note;
          return this.capitalizeFirstLetter(noteValue);
        } else if (rhs?.item?.new?.note) {
          const newNoteValue = rhs?.item?.new?.note;
          return this.capitalizeFirstLetter(newNoteValue);
        } else if (rhs.to) {
          const toValue = this.stripHtml(rhs.to);
          return this.capitalizeFirstLetter(toValue);
        } else if (rhs?.item?.new) {
          return this.capitalizeFirstLetter(rhs?.item?.new);
        } else if (rhs?.item?.value) {
          return this.capitalizeFirstLetter(rhs?.item?.value);
        } else if (rhs?.name) {
          return this.capitalizeFirstLetter(rhs?.name);
        } else if (rhs?.item) {
          return this.capitalizeFirstLetter(rhs?.item);
        }
      }
    },
    stripHtml(html) {
      let div = document.createElement("div");
      div.innerHTML = html;
      return div.textContent || div.innerText || "";
    },
    capitalizeFirstLetter(str) {
      if (typeof str !== "string" || str.length === 0) {
        return str;
      }
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    lhsData(lhs) {
      console.log(lhs.action, "act123 lhsaction");

      if (
        // lhs.action === "ADDED" ||
        lhs.action === "UPDATED" ||
        lhs.action === "REMOVED" ||
        lhs.action === "DELETED" ||
        lhs.action === "UPDATE"
      ) {
        if (lhs?.item?.old?.note) {
          const noteValue = lhs.item.old.note;
          console.log(noteValue, "lhs?.item?.old?.note");
          return this.capitalizeFirstLetter(noteValue);
        }
        // Return the 'old' value if it exists
        if (lhs?.item?.old !== undefined && lhs?.item?.old !== null) {
          const oldValue = lhs?.item?.old;
          console.log(oldValue, "lhs?.item?.old");
          console.log(this.capitalizeFirstLetter(oldValue), "lhs?.item?.old");
          return this.capitalizeFirstLetter(oldValue);
        }

        // Return the note from 'old' if it exists

        // Return 'from' if it exists
        if (lhs.from !== undefined && lhs.from !== null) {
          const fromValue = this.stripHtml(lhs.from);
          console.log(fromValue, "lhs.from");
          return this.capitalizeFirstLetter(fromValue);
        }

        // Return formatted value and valueSuffix if they exist
        if (
          lhs?.item?.valueSuffix !== undefined &&
          lhs?.item?.valueSuffix !== null
        ) {
          const formattedValue = `${lhs?.item?.value} ${lhs?.item?.valueSuffix}`;
          console.log(
            formattedValue,
            "`${lhs?.item?.value} ${lhs?.item?.valueSuffix}`"
          );
          return this.capitalizeFirstLetter(formattedValue);
        }

        // Return 'to' if it exists
        if (lhs.to !== undefined && lhs.to !== null) {
          const toValue = this.stripHtml(lhs.to);
          console.log(toValue, "lhs.to");
          return this.capitalizeFirstLetter(toValue);
        }

        if (lhs?.item?.old !== undefined) {
          return this.capitalizeFirstLetter(lhs?.item?.old);
        }
        if (lhs.name !== undefined) {
          return this.capitalizeFirstLetter(lhs.name);
        }
        if (lhs?.item?.new !== undefined) {
          return this.capitalizeFirstLetter(lhs?.item?.new);
        }
        if (lhs.item?.name !== undefined) {
          return this.capitalizeFirstLetter(lhs.item?.name);
        }
        if (lhs?.item) {
          const itemValue = lhs?.item;
          return this.capitalizeFirstLetter(itemValue);
        }
      }
    },
    tooltipover(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis(data1, index1);
    },
    tooltipleave(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses(data1, index1);
    },
    ellipsis(data, index) {
      console.log(data, index);
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              let height = tooltip.offsetHeight;
              let top = element1.getBoundingClientRect().top - height;
              let left = element1.getBoundingClientRect().left;
              tooltip.style.display = "block";
              tooltip.style.top = top + "px";
              tooltip.style.left = left + "px";
              return true;
            }
          }
          return false;
        }
      });
    },
    ellipses(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              tooltip.style.display = "none";
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },
    pathDetails(val) {
      if (val.item) {
        //console.log(val.item, "pathDetails //console.log");
      }
    },
    headermessage(path) {
      path.forEach((change) => {
        //console.log(change.path[0]);
        if (
          change.path[0] === "category" ||
          change.path[0] === "validFor" ||
          change.path[0] === "endOfDate" ||
          change.path[0] === "endOfLife" ||
          change.path[0] === "brand" ||
          change.path[0] === "description" ||
          change.path[0] === "name"
        ) {
          //console.log("overview");
          return "overview";
        } else if (change.path[0] === "productSpecCharacteristic") {
          //console.log("productSpecCharacteristic");
          return "Features";
        } else if (change.path[0] === "serviceResourceMapping") {
          //console.log("serviceResourceMapping");
          return "Service";
        } else if (change.path[0] === "attachment") {
          //console.log("attachment");
          return "Documents";
        } else if (change.path[0] === "notes") {
          //console.log("notes");
          return "Notes";
        }
      });
      // const overview = ["category", "validFor", "endOfDate", "endOfLife", "brand", "description", "name"];
      // const Features = ["productSpecCharacteristic"];
      // const Service = ["serviceResourceMapping"];
      // const Documents = ["attachment"];
      // const Notes = ["notes"];

      // // Initialize empty arrays for each category
      // const categorizedChanges = {
      //   overview: [],
      //   Features: [],
      //   Service: [],
      //   Documents: [],
      //   Notes: []
      // };

      // // Process each change
      // path.forEach(change => {
      //   const path = change.path[0]; // Assuming path is an array with at least one element

      //   // Categorize based on the first path element
      //   if (overview.includes(path)) {
      //     categorizedChanges.overview.push(change);
      //   } else if (Features.includes(path)) {
      //     categorizedChanges.Features.push(change);
      //   } else if (Service.includes(path)) {
      //     categorizedChanges.Service.push(change);
      //   } else if (Documents.includes(path)) {
      //     categorizedChanges.Documents.push(change);
      //   } else if (Notes.includes(path)) {
      //     categorizedChanges.Notes.push(change);
      //   }
      // });

      // // Logging the categorized changes
      // //console.log('Overview:', categorizedChanges.overview);
      // //console.log('Features:', categorizedChanges.Features);
      // //console.log('Service:', categorizedChanges.Service);
      // //console.log('Documents:', categorizedChanges.Documents);
      // //console.log('Notes:', categorizedChanges.Notes);
    },
    // headermessage(path) {

    //   const overview = [
    //     "category",
    //     "validFor",
    //     "endOfDate",
    //     "endOfLife",
    //     "brand",
    //     "description",
    //     "name",
    //   ];

    //   path.map((item) => {
    //     this.overview = item.filter((value) => overview.includes(value));
    //   });

    //   //console.log(path, "categorycategory");
    // },

    getLabel() {
      if (this.areArraysEqual(this.array, this.array2)) {
        return "Features";
      } else if (this.areArraysEqual(this.array, this.array3)) {
        return "Features";
      } else if (this.areArraysEqual(this.array, this.array4)) {
        return "Service and resource";
      } else if (this.areArraysEqual(this.array, this.array5)) {
        return "Related offers";
      } else if (this.areArraysEqual(this.array, this.array6)) {
        return "Documents";
      } else if (this.areArraysEqual(this.array, this.array7)) {
        return "Notes";
      } else {
        return "No match";
      }
    },
    areArraysEqual(array1, array2) {
      if (array1.length !== array2.length) {
        return false;
      }
      for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) {
          return false;
        }
      }
      return true;
    },
    activitysearch(val) {
      if (val !== "") {
        let searchData = this.searchNestedObjects(
          this.tableData,
          val.toLowerCase()
        );
        this.activityDataPaginated = JSON.parse(JSON.stringify(searchData));
      } else {
        this.activityDataPaginated = JSON.parse(JSON.stringify(this.tableData));
      }
    },

    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            // Recursively search nested objects
            console.log(
              this.searchNestedObjects([v], key).length > 0,
              "object if"
            );

            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            // Convert date strings to formatted versions for comparison
            if (Date.parse(v)) {
              const formattedDate = this.formatDate(v).toLowerCase();
              console.log("dateformate", formattedDate);
              console.log("dateformate", formattedDate.includes(key));

              return formattedDate.includes(key);
            }
            // Normal string comparison
            console.log(v.toLowerCase().includes(key), "normal");

            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },

    // Helper function to format the date string as in UI
    formatDate(isoString) {
      const date = new Date(isoString);
      const options = {
        year: "numeric",
        month: "short", // 'short' gives abbreviated month names (e.g., Sep)
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true, // Enables AM/PM format
      };
      return new Intl.DateTimeFormat("en-US", options).format(date);
    },
    searchInArray(searchTerm, dataArray) {
      const searchTermLower = searchTerm.toLowerCase();

      if (searchTermLower !== "") {
        const result = dataArray.filter((obj) => {
          return Object.values(obj).some((value) => {
            if (typeof value === "string") {
              return value.toLowerCase().includes(searchTermLower);
            }
            return false;
          });
        });

        return result;
      }
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },

    currentPage(val) {
      this.currPage = val;
      this.$emit("activitycurrentpage", val);
    },
    changeRowsPerPage(count) {
      this.rowsPerPage = count;
      this.$emit("activityrowpage", count);
    },
    isDate(value) {
      // Return true if the value matches "9999-12-31T00:00:00Z" exactly
      if (value === "9999-12-31T00:00:00Z") {
        return true;
      }

      const date = new Date(value);
      return (
        !isNaN(date.getTime()) &&
        typeof value === "string" &&
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z$/.test(value)
      );
    },
    dateTimeFormatter(value) {
      const date = new Date(value);
      const options = { month: "short", day: "numeric", year: "numeric" };
      return date.toLocaleDateString("en-US", options); // Example formatting
    }, // Output: Feb 20,2024 07:19AM
  },
};
</script>

<style lang="scss">
.ellipsis_activityLog {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.center-align-activityLog {
  padding-left: 46px;
}
.activitylog_drawer_componet {
  .section_accordtion_supplier {
    height: 86vh !important;
  }
  .activirylog_pagination {
    position: fixed;
    width: 62%;
    margin: -11px 0px;
    background: white;
    height: 100%;
  }
}
.activelog_page_supplier::-webkit-scrollbar {
  display: none;
}
.activirylog_pagination {
  position: fixed;
  width: 75%;
  margin: -11px 0px;
  background: white;
  height: 100%;
}
.activelog_page_supplier {
  .notepad_supplier_accordion {
    .accordion-item .accordion-content {
      // height: 40px !important;
      flex-direction: column;
      padding: 0 !important;
      background-color: white;
      border: none;
    }
    .activitylog_loop {
      border-bottom: 1px solid #d8d8d8;
    }
    .activitylog_loop:last-child {
      border-bottom: none;
    }
    .body_activelog_inside_detail {
      height: 40px;
      padding: 6px 16px 6px 43px;
      border-bottom: 1px solid #d8d8d8;
      display: flex;
      align-items: center;
    }
    .body_activelog_inside_detail:last-child {
      border-bottom: none;
    }
    .body_activelog_inside {
      display: flex;
      // justify-content: space-between;
      font-weight: 100;
      width: 100%;
      border-top: 1px solid #d8d8d8;
      padding: 6px 16px 6px 43px;
      background-color: #d8d8d833;
    }
  }

  .header_activelog_inside {
    .whiteBox_activirylog_hidden {
      width: 20px;
      height: 20px;
      background: white;
      position: absolute;
      /* top: 5px; */
    }
    .whiteDapa {
      width: 20px;
      height: 20px;
      background: white;
      position: absolute;
      margin-left: -29px;
    }
    // border:1px solid brown;
    display: flex;
    position: relative;
    // justify-content: space-between;
    align-items: center;
    font-weight: 100;
    // .Actions {
    //   width: 50%;
    // }
    .made_profile {
      // width: 50%;
      gap: 16px;
      display: flex;
      align-items: center;
      .profile_pic_activityLog {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid #d3d3d3;
      }

      // .Made {
      // }
    }
    // .Last {
    //   width: 30%;
    // }
  }
  .header_activelog {
    height: 40px;
    background-color: rgba(216, 216, 216, 0.2);
    border: 1px solid #d8d8d8;
    border-radius: 6px 6px 0px 0px;
    padding: 6px 16px 6px 42px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
  }
  .section_accordtion_supplier {
    height: 78vh;
  }
  .notepad_supplier_accordion {
    display: flex;
    flex-direction: column;
    gap: 0px !important;
    width: 100%;

    border-bottom: 0;

    .accordion-item {
      border-top: none !important;
      border: 1px solid #d8d8d8;
      border-radius: 0px !important;
      .hide-arrow_activtylog {
        .accordion-header i {
          visibility: hidden !important;
        }
      }
      .accordion-header {
        height: 60px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        border-bottom: 0 !important ;
        justify-content: space-between;
        align-items: center;
        color: var(--hlx-text-color-primary);
        cursor: pointer;
        gap: 10px;
      }
    }
    .noter_fullhead_supplier_accordion {
      padding: 6px;
    }
    .noter_head_supplier_accordion {
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      gap: 4px;

      .note_username_supplier {
        font-weight: 600;
        .note_username_supplier_color {
          color: #0065b2;
        }
      }
      .note_date_time_supplier {
        margin-left: auto;
        font-weight: 400 !important;
        .note_date_time_supplier_value {
          margin-right: 10px;
        }
      }
    }
  }
  .supplier-delete-action {
    color: var(--hlx-color-error) !important;
    position: absolute;
    top: 154px;
    right: 22px;
    font-size: 1.3em;
  }

  .header-add-supplier {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    .tablehead-add-supplier {
      font-weight: bold;
    }
    .search-add-supplier {
      display: flex;
      gap: 10px;
    }
  }
  .contacts-username-supplier {
    display: flex;
    align-items: center;
    .right-head-supplier {
      display: flex;
      flex-direction: column;
    }
    .head-supplier-left {
      display: flex;
      justify-content: center;
      width: 44px;
      align-items: center;
      font-size: 45px;
      margin-right: 10px;
    }
    .head-supplier-right {
      display: flex;
      font-size: 14px;
      font-weight: bold;
      .head-supplier-name {
        margin-right: 10px;
      }
    }
    .head-supplier-address {
      font-size: 10px;
      color: #929191;
      margin-top: 3px;
    }
  }
}
</style>
