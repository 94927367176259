<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Inactive user</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to Inactive user <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>

    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Inactive</hlx-button
      >
    </template>
  </hlx-modal>
  <div>
    <hlx-modal
      class="upc"
      :modal-active="modal_active_role"
      :height="'450px'"
      :width="'500px'"
      :modal="true"
      @close="closerolesmodel"
    >
      <template #header>
        <div v-if="editMode === false" style="margin-left: 0px">
          Invite User
        </div>
        <div v-if="editMode === true" style="margin-left: 0px">Invite User</div>
      </template>
      <template #content>
        <div
          class="product-detail-supplier"
          style="width: 450px; margin: 15px auto"
        >
          <div class="value-data-supplier">
            <span class="value-data-supplier_left">Role id :</span>
            <span class="value-data-supplier_right">{{
              payload.displayID
            }}</span>
          </div>

          <div class="value-data-supplier">
            <span class="value-data-supplier_left">Role name : </span>
            <span class="value-data-supplier_right">
              <span>{{ payload.role }} </span>

              <span> </span>
            </span>
          </div>
        </div>
        <div style="padding: 0 23px">
          <div class="input-section-v2">
            <hlx-input
              :key="ticketComponetf"
              v-model:value="userPayload.firstName"
              :pre-val="userPayload.firstName"
              :label-animation="true"
              label-value="First name"
              :required="true"
              type="text"
              :clearable="true"
            />
            <div style="width: 100%; margin-top: 20px">
              <div class="decision-name-wrapper">
                <!-- {{payload.tags}} -->
                <hlx-input
                  :key="ticketComponetl"
                  v-model:value="userPayload.lastName"
                  :pre-val="userPayload.lastName"
                  :label-animation="true"
                  label-value="Last name"
                  :required="true"
                  type="text"
                  :clearable="true"
                />
              </div>
            </div>

            <div style="width: 100%; margin-top: 20px">
              <hlx-input
                :key="ticketComponete"
                v-model:value="userPayload.email"
                :pre-val="userPayload.email"
                :label-animation="true"
                label-value="Email id"
                :required="true"
                :display-error="true"
                :custom-error="isEmailEmpty"
                :custom-error-message="'This email was already registered'"
                type="text"
                :clearable="true"
              />
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <hlx-button class="secondary sm" @click="closerolesmodel"
          >Cancel</hlx-button
        >
        <hlx-button
          v-if="editMode === false"
          class="primary sm"
          style="margin-left: 16px"
          :disabled="
            userPayload.firstName != '' &&
            userPayload.lastName != '' &&
            userPayload.email != '' &&
            validateEmail(userPayload.email)
              ? false
              : true
          "
          @click="addNewContact()"
          >Invite</hlx-button
        >
        <hlx-button
          v-if="editMode === true"
          class="primary sm"
          style="margin-left: 16px"
          :disabled="
            userPayload.firstName != '' &&
            userPayload.lastName != '' &&
            userPayload.email != '' &&
            validateEmail(userPayload.email)
              ? false
              : true
          "
          @click="editContactUser()"
          >Save</hlx-button
        >
      </template>
    </hlx-modal>
  </div>
  <div class="role">
    <!-- <section class="left-sidebar">
      <sidebar
        :nav-items="nav_items"
        :config-navs="config_nav_items"
        :lang="'en-US'"
        collapse
        @nav-to="navTo"
        @go-to-home="goToHome"
      ></sidebar>
    </section> -->
    <section class="right-content">
      <div style="padding: 18px 0 0 10px; width: 8%">
        <hlx-breadcrumb :auto-route="true" @path="goTo()" />
      </div>
      <div style="display: flex; flex-direction: column; padding: 35px 0 0 0">
        <div class="title-container" style="gap: 63%">
          <p class="header" style="width: 100%; font-family: 'QuickSand'">
            <b>Roles and permissions</b>
          </p>
        </div>
        <div
          style="
            padding: 0.5rem 1.5rem 0rem;
            width: 88.5vw;
            margin-left: 2%;
            display: flex;
            gap: 70%;
            justify-content: space-between;
          "
        >
          <hlx-switch
            v-model="selectedValue"
            tab-view
            :switch-items="groupValues"
            value-prop="value"
            display-prop="name"
            @change="handleCurrentChange"
          />
          <div
            class="go-back"
            style="width: 100%; display: flex; align-items: center"
          >
            <hlx-button class="secondary sm" @click="cancelEditrole"
              >Cancel</hlx-button
            >
            <!-- {{ editsupplierbutton }} -->
            <hlx-button
              class="primary sm"
              style="margin-left: 20px"
              @click="editRole"
              >Save</hlx-button
            >
          </div>
        </div>
        <div v-if="selectedValue === 'role'">
          <div class="main-container">
            <div style="width: 100%">
              <!-- <div style="width: 100%; margin-top: 20px"> -->
              <!-- <hlx-input
                  v-model:value="payload.displayID"
                  :pre-val="payload.displayID"
                  type="text"
                  :label-value="'Role id'"
                  :disabled="true"
                  :label-animation="true"
                  :required="false"
                />
              </div> -->
              <div style="width: 100%; margin-top: 20px">
                <hlx-input
                  v-model:value="payload.role"
                  :pre-val="payload.role"
                  type="text"
                  :label-value="'Role name'"
                  :display-error="true"
              :custom-error="isRoleName"
              :custom-error-message="'Role already exists'"
                  :label-animation="true"
                  :required="false"
                />
              </div>
              <div style="width: 100%;">
                <div class="decision-name-wrapper">
                  <!-- {{payload.tags}} -->
                  <hlx-multi-select
                    :key="tagcomponetkey"
                    :pre-value="payload.tags"
                    :options="tagsList"
                    prop-value="name"
                    :multi-select="true"
                    :placeholder="'Tags'"
                    :disabled="defalt"
                    :tooltipposition="'left'"
                    :label-animation="true"
                    :clearable="true"
                    @add-data="addTagList"
                    @selected-array="receivedTags"
                  ></hlx-multi-select>
                </div>
              </div>
            </div>

            <div style="width: 100%; margin-top: 20px">
              <hlx-input
                :key="ticketComponetD"
                v-model:value="payload.description"
                :pre-val="payload.description"
                :label-animation="true"
                label-value="Description"
                type="textarea"
                :rows="4"
                :clearable="true"
              ></hlx-input>
            </div>
          </div>
          <hr style="margin: 15px 42px; width: 90%" />
          <section>
            <section>
              <div
                class="new-layout-body catalog-v2"
                style="
                  width: 94%;
                  font-size: var(--base-font-size);
                  overflow: auto;
                  display: flex;
                  flex-direction: column;
                  padding: 0rem 1.5rem 0 1.4rem;
                  margin: 0 0 0.5rem 22px;
                "
              >
                <!-- search and filter  -->
                <div
                  v-if="tableData?.length > 0 || searchvalue !== ''"
                  class="header-utils-and-switch-container"
                  style="width: 100%; margin-bottom: 17px !important"
                >
                  <span style="width: 100%"
                    ><span class="schema-header-title">Users</span>
                    <span style="font-size: 12px; font-weight: 300">
                      Manage and Organize Users</span
                    ></span
                  >
                  <span class="table-header-utils">
                    <span class="util-items --search"></span>

                    <!-- <span class="filter-table">
                    <hlx-button
                      class="secondary sm add-btn"
                      @click="showDrawerRight"
                    >
                      <i class="icon-filter-regular"></i>
                      <span v-if="filtertaglength != 0" class="filter-count">{{
                        filtertaglength
                      }}</span>
                    </hlx-button>
                  </span> -->

                    <span
                      id="add-product-offer"
                      class="util-items util-items-add"
                      ><hlx-button
                        class="secondary-success sm add-btn"
                        @click="newRoleModule"
                        ><i
                          class="icon-user-plus-alt-regular"
                          style="margin-right: 5px"
                        ></i
                        >Invite user</hlx-button
                      >
                      <hlx-context-menu
                        :top="containertop1"
                        :left="containerleft1"
                        :conditionvalue="150"
                        :conditiondown="2"
                        :conditionup="-38"
                        style="z-index: 10000"
                        :options="add_context_options"
                        :show="show_add_offer"
                        :style="{ marginTop: '5px', marginRight: '20px' }"
                        @chosen="chooseTicket"
                      ></hlx-context-menu>
                    </span>
                  </span>
                </div>

                <!-- image -->
                <section
                  v-if="tableData?.length === 0 && searchvalue == ''"
                  style="
                    margin-top: 1.2rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1.2rem;
                    align-items: center;
                  "
                >
                  <span>
                    <img
                      src="@/assets/svg/TicketTemplateSVG.svg"
                      alt=""
                      style="width: 500px; height: 500px"
                    />
                  </span>

                  <span
                    class="util-items util-items-add"
                    @click="openuploaddrawer"
                  >
                    <hlx-button
                      class="primary media add-btn"
                      @click="newRoleModule"
                      ><i
                        class="icon-plus-circle-regular"
                        style="margin-right: 5px"
                      ></i
                      >Create role</hlx-button
                    >
                  </span>
                </section>

                <!-- TableData -->
                <!-- {{ paginatedData }} -->
                <div
                  v-if="tableData.length > 0"
                  style="height: 450px"
                  class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
                >
                  <hlx-table
                    :column-count="accounts_table_theads.length + 2"
                    :border="['table', 'header', 'horizontal']"
                    :row-hover="true"
                    theme="grey"
                    :re-arrange-columns="re_arrange"
                    :re-arrange-heads="accounts_table_theads_cc"
                    @close-rearrange="re_arrange = false"
                    @apply-rearranged-columns="applyColumns"
                  >
                    <template #thead>
                      <hlx-table-head
                        :width="60"
                        :align="'center'"
                        style="padding: 0 15px"
                        :fixed="'left'"
                        @new-resize-width="resizedWidth"
                        >{{ "S.no" }}</hlx-table-head
                      >
                      <hlx-table-head
                        v-for="(i, index) in accounts_table_theads"
                        :key="index"
                        :prop="i.prop"
                        :sortable="i.sortable"
                        :resizable="i.resizable"
                        :width="i.width !== undefined ? i.width : ''"
                        :align="i.align !== undefined ? i.align : ''"
                        @sorting_func="sorting_Data"
                        @new-resize-width="resizedWidth"
                      >
                        {{ i.label }}</hlx-table-head
                      >
                      <hlx-table-head
                        v-show="supplierStatus == false"
                        :align="'center'"
                        :width="70"
                        :fixed="'right'"
                        @new-resize-width="resizedWidth"
                        ><span class="action-icon">
                          <i
                            class="icon-settings-regular"
                            @click="reAssignOrgTableHeader()"
                          ></i> </span
                      ></hlx-table-head>
                    </template>
                    <template #tbody>
                      <tr
                        v-for="(i, index) in paginatedData"
                        id=""
                        :key="index"
                      >
                        <hlx-table-cell :align="'center'" :fixed="'left'">
                          {{ serialNumber(index) }}
                        </hlx-table-cell>
                        <hlx-table-cell
                          v-for="(j, col_index) in accounts_table_theads"
                          :key="col_index"
                          :align="j.align"
                          :fixed="j.fixed"
                        >
                          <div
                            v-if="j.prop.toLowerCase() === 'displayid'"
                            :id="j.prop + index"
                            class="link-name"
                            @click="viewPage(i)"
                          >
                            {{ i[j.prop] }}
                          </div>

                          <!-- <div
                                  v-else-if="
                                    j.prop?.toLowerCase() === 'displayid'
                                  "
                                >
                                  {{ i[j.prop] }}
                                </div> -->
                          <div v-if="j.prop?.toLowerCase() === 'name'">
                            {{ i[j.prop] }}
                          </div>
                          <div
                            v-else-if="j.prop?.trim() == 'status'"
                            class="user-status"
                          >
                            <hlx-status :type="i[j.prop]" />
                          </div>
                          <div v-if="j.prop.toLowerCase() === 'email'">
                            {{ i[j.prop] }}
                          </div>

                          <!-- <div v-else>{{ i[j.prop] }}</div> -->
                        </hlx-table-cell>
                        <hlx-table-cell
                          v-show="supplierStatus == false"
                          :align="'center'"
                          :width="120"
                          :fixed="'right'"
                        >
                          <span
                            :id="'table-context' + index"
                            class="action-icon"
                          >
                            <i
                              style="position: relative"
                              class="icon-more-vertical-filled"
                              :class="i.context === true ? 'active-action' : ''"
                              @click="crudContextMenu($event, i, index)"
                            >
                              <hlx-context-menu
                                :top="containertop"
                                :left="containerleft"
                                :conditionvalue="150"
                                :conditiondown="2"
                                :conditionup="-38"
                                :options="computedTableAction(i)"
                                :data="index.toString()"
                                :show="i.context == true"
                                @chosen="closeCrudContextMenu($event, i, index)"
                              />
                            </i>
                          </span>
                        </hlx-table-cell>
                      </tr>
                    </template>
                  </hlx-table>
                </div>

                <div
                  v-if="tableData.length === 0 && searchvalue !== ''"
                  class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
                >
                  <hlx-table
                    :column-count="accounts_table_theads.length + 2"
                    :border="['table', 'header']"
                    :row-hover="true"
                    theme="grey"
                    :re-arrange-columns="re_arrange"
                    :re-arrange-heads="accounts_table_theads_cc"
                    @close-rearrange="re_arrange = false"
                    @apply-rearranged-columns="applyColumns"
                  >
                    <template #thead>
                      <hlx-table-head
                        :width="60"
                        :align="'center'"
                        style="padding: 0 15px"
                        :fixed="'left'"
                        >{{ "S.no" }}</hlx-table-head
                      >
                      <hlx-table-head
                        v-for="(i, index) in accounts_table_theads"
                        :key="index"
                        :prop="i.prop"
                        :sortable="i.sortable"
                        :resizable="i.resizable"
                        :width="i.width !== undefined ? i.width : ''"
                        :align="i.align !== undefined ? i.align : ''"
                        @sorting_func="sorting_Data"
                      >
                        {{ i.label }}</hlx-table-head
                      >
                      <hlx-table-head
                        v-show="supplierStatus == false"
                        :align="'center'"
                        :width="70"
                        :fixed="'right'"
                        ><span class="action-icon">
                          <i
                            class="icon-settings-regular"
                            @click="reAssignOrgTableHeader()"
                          ></i> </span
                      ></hlx-table-head>
                    </template>
                    <template #tbody>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell
                          :colspan="accounts_table_theads.length + 2"
                          >{{ "No data" }}</hlx-table-cell
                        >
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                      <tr>
                        <hlx-table-cell></hlx-table-cell>
                      </tr>
                    </template>
                  </hlx-table>
                </div>
              </div>
            </section>

            <div
              v-if="tableData?.length > 0"
              class="pagination-container"
              style="padding: 1%; margin-left: 38px"
            >
              <hlx-pagination
                v-model:rows-per-page="externalRowsPerPage"
                :total="tableData?.length"
                rows-per-page
                enable-rows-per-page
                :rows-per-page-list="[10, 15, 20]"
                :options="{
                  attributes: {
                    rowsPerPageTitle: true,
                    rowsPerPageResult: true,
                    rowsPerPageDropdown: {
                      position: {
                        top: 0,
                        right: 1,
                      },
                    },
                  },
                }"
                @current-page="currentPage"
                @updated:rows-per-page="changeRowsPerPage"
              ></hlx-pagination>
            </div>
          </section>
        </div>

        <div v-if="selectedValue === 'permission'" style="width: 106%">
          <hlxRoleAccordtion
            :data="sections"
            @permission-value="emitedValue"
          ></hlxRoleAccordtion>
        </div>
      </div>
    </section>
  </div>

  <discardComponent
    :show="showSupplierDiscardModal"
    @stay="handleStay"
    @discard="handleDiscard"
    @close="showSupplierDiscardModal = false"
  />
</template>

<script>
// import banner from "../components/bannerComponent.vue";
import axios from "axios";
import discardComponent from "@/components/discardComponent.vue";
import hlxRoleAccordtion from "@/components/roleAccordtion.vue";
import {
  DMNAxiosInstance,
  MLTAxiosInstance,
  // UPCAxiosInstance,
} from "@/config/axiosConfig";
export default {
  name: "AddRoleView",
  components: { hlxRoleAccordtion, discardComponent },
  beforeRouteLeave(to, from, next) {
    if (this.discardIt) {
      //this.discardIt will handled on discard modal window discard changes emit
      next(true);
      return;
    }
    // Check for unsaved changes
    /**
     * Param 1 initial paylaod to check against param 2
     * Param 2 updated payload to check against param 1
     */
    const unsavedChanges = this.isPayloadChanged(
      this.initialPayload,
      this.payload
    ); //This isPayloadChanged method was configured in global methods so we can call it any where on vue.It's find a difference between both data and return true if any changes otherwise return false
    console.log(unsavedChanges, "unsavedChanges");
    // If unsaved changes found then display discard modal
    if (unsavedChanges) {
      this.showSupplierDiscardModal = true;
    }
    // Then route as per validation
    next(!unsavedChanges);
    return;
  },
  data() {
    return {
      notification: {},
      roleList: [],
      fullcontent: [],
      userPayloadFull: {},
      supplierStatus: false,
      delete_modal_active: false,
      isRoleName: false,
      delete_name: "",
      deleteobj: {},
      showSupplierDiscardModal: false,

      userFilterOptions: {
        type: "filter",
        company: this.$cookies.get("company"),
        module: "hierarchycontacts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          "@baseType": "organisation",
        },
        searchQuery: "",
      },
      initialPayload: {},
      tagsList: [],
      isEmailEmpty: false,
      groupValues: [
        {
          name: "Role",
          value: "role",
        },
        {
          name: "Permission",
          value: "permission",
        },
      ],
      selectedValue: "role",

      tableData: [],

      accounts_table_theads_cc: [
        {
          sortable: false,
          resizable: true,
          width: 300,
          disabled: true,
          checked: true,
          name: "ID",
          label: "ID",
          prop: "id",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: null,
          checked: true,
          disabled: true,
          name: "Name",
          label: "Name",
          prop: "Name",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          disabled: false,
          width: 200,
          checked: true,
          name: "Status",
          label: "Status",
          prop: "Status",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          disabled: false,
          width: 200,
          checked: true,
          name: "Email",
          label: "Email",
          prop: "Email",
          align: "left",
        },
      ],
      accounts_table_theads: [
        {
          sortable: false,
          resizable: true,
          width: 200,
          disabled: true,
          checked: true,
          name: "ID",
          label: "ID",
          prop: "displayID",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 200,
          checked: true,
          disabled: true,
          name: "Name",
          label: "Name",
          prop: "name",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          disabled: false,
          width: 200,
          checked: true,
          name: "Status",
          label: "Status",
          prop: "status",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          disabled: false,
          width: 200,
          checked: true,
          name: "Email",
          label: "Email",
          prop: "email",
          align: "left",
        },
      ],
      instanceAddress: {
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
        },
      },
      payload: {
        role: "",
        tags: [],
        description: "",
        id: 0,
      },
      userPayload: {
        firstName: "",
        lastName: "",
        email: "",
        id: 0,
      },
      hierarchyData: [],
      role: "",
      impersonite: false,
      ruleEdit: [{ name: "", checked: false, disabled: false }],
      ruleView: [{ name: "", checked: true, disabled: false }],
      schemaEdit: [{ name: "", checked: false, disabled: false }],
      schemaView: [{ name: "", checked: true, disabled: false }],
      testEdit: [{ name: "", checked: false, disabled: false }],
      testView: [{ name: "", checked: true, disabled: false }],
      settingsEdit: [{ name: "", checked: false, disabled: false }],
      settingsView: [{ name: "", checked: true, disabled: false }],

      crumbs: [{ label: "Settings" }, { label: "Roles and permissions" }],
      impersonitor: "",
      fet: process.env.VUE_APP_HOST_PROTOCOL + "://" + process.env.VUE_APP_HOST,
      token: this.$route.params.token,
      company: "",
      modal_active_role: false,
      currPage: 1,
      rowsPerPage: 10,
      editMode: false,
      sections: [],
      discardIt: false,
      tempBack: false,

      rootParent: "",
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.tableData.length;
    },
  },
  async mounted() {
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");

    this.company = await val.data.company;
    let sampleTheads = await MLTAxiosInstance.get(
      `/partyRole/${this.$route.query.id}`
    );
    let organisationInfo = await MLTAxiosInstance.get(
      `/partymanagement/organisation/${this.$cookies.get(
        "user"
      )}?company=${this.$cookies.get("company")}`
    );

    this.rootParent = organisationInfo.data.rootName;
    console.log(this.rootParent, "this.rootParent");

    console.log(sampleTheads.data, "bvfff");
    this.payload = sampleTheads.data;
    this.sections = sampleTheads.data.permission;
    // this.payload.role = sampleTheads.data.role;
    // this.payload.tags = sampleTheads?.data?.permission.externalReference?.tags;
    // this.payload.description =
    // sampleTheads?.data?.permission?.externalReference?.description;
    // this.payload.id = sampleTheads.data._id;

    this.initialPayload = JSON.parse(JSON.stringify(sampleTheads.data));
    console.log(this.payload, "this.payload~ ");
    const rootName = this.$cookies.get("rootName");
    const tagsListurl =
      rootName === "Halleyx"
        ? "mapRef/collection/tagsList"
        : `mapRef/collection/tagsList?company=${this.$cookies.get("rootName")}`;

    let tagsList = await DMNAxiosInstance.get(tagsListurl);
    this.tagsList = tagsList.data.data.filter((item) => {
      return item.module === "role";
    });

    const instance = await MLTAxiosInstance.get(
      `/partymanagement/organisation/${val.data.userId}?company=${val.data.company}`
    );

    this.instanceData = instance.data;
    this.instanceAddress = this.instanceData.contactMedium[3];
    console.log(this.instanceData, this.instanceAddress, "this.instanceData");
    this.recallTable();
    // this.payload.role = sampleTheads.data.name
  },
  methods: {
    async deleteExecRow() {
      console.log(this.deleteobj, "sdfghjk");
      this.delete_modal_active = false;
      let i = {
        id: this.deleteobj._id,
        status: "Inactive",
      };
      const contacts = await MLTAxiosInstance.patch(
        `/partymanagement/individual/?company=${this.deleteobj.company}`,
        i
      );
      console.log(contacts, "lllalsldddddddddddddddddddddddjsddslk");
      // this.contactsDataCalling(this.currPage, this.rowsPerPage);
      this.recallTable();

      if (contacts.data.email) {
        this.alertmsg = "success";
        this.notification = {
          type: "success",
          message: `All set! Your changes have been saved successfully!`,
        };
      } else {
        this.alertmsg = "danger";
        this.notification = {
          type: "danger",
          message: `Data could not be inserted.`,
        };
      }
      // this.contactsDataCalling(this.currPage, this.rowsPerPage);
      this.recallTable();
    },
    goTo() {
      this.tempBack = true;
      // // this.$router.back();
      // this.$router.push("/SM/supplier");
    },
    // Function to merge sections based on `name`
    handleStay() {
      this.showSupplierDiscardModal = !this.showSupplierDiscardModal;
      this.tempBack = false;
    },
    handleDiscard() {
      //Set tempBack = true when Back is clicked from breadcrumbs
      if (this.tempBack) {
        this.$router.back();
      } else {
        this.$router.push(this.$store.state.tempActiveSideBarItem.link);
      }

      this.showSupplierDiscardModal = !this.showSupplierDiscardModal;

      this.discardIt = true;
    },
    preventPageReload(event) {
      this.showSupplierDiscardModal = false;
      const unsavedChanges = this.isPayloadChanged(
        this.initialPayload,
        this.payload
      );
      if (!unsavedChanges) return;

      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
      return;
    },
    emitedValue(val) {
      this.sections = val;
      this.payload.permission = val;
      console.log(val, "this.sections emitedValue", this.sections);
    },
    viewPage(i) {
      console.log(i, "modal_active_role");
      (this.editMode = true), (this.modal_active_role = true);
      this.userPayloadFull = JSON.parse(JSON.stringify(i));
      this.userPayload.firstName = JSON.parse(JSON.stringify(i.firstName));
      this.userPayload.lastName = JSON.parse(JSON.stringify(i.lastName));
      this.userPayload.email = JSON.parse(JSON.stringify(i.email));
      this.userPayload.id = JSON.parse(JSON.stringify(i._id));
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    async getSingleUser(i) {
      let sampleTheads = await MLTAxiosInstance.get(
        `/partymanagement/individual/${i}`
      );
      this.inviteUser(sampleTheads.data);
    },
    async inviteUser(i) {
      console.log(i, "mail send request");
      if (i != undefined) {
        this.notification = {
          type: "success",
          message: `Great job! Your invitation for ${i.name} has been sent successfully!`,
        };

        this.sendEmail(i);
      }
    },
    async sendEmail(i) {
      console.log(i, "sendEmail");
      const prod = process.env.VUE_APP_BASE_URL;
      prod;
      const dev = "http://localhost:8080";
      dev;

      let vbnm = 2;
      let tokenPayload = {
        user: {
          email: i.email,
          company: i.company,
        },
        validTo: vbnm + "h",
        additionalFields: {
          supplierLogin: true,
        },
      };

      let generatedToken = await MLTAxiosInstance.post(
        "/partymanagement/individual/generateToken",
        tokenPayload
      );
      generatedToken = generatedToken.data;

      console.log(generatedToken, "mail send sucessfully");
      let mail = {
        to: [i.email],
        subject: `${i.company} user Invitation`,
        template: `
      <!--
 -->

<!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <!--[if gte mso 9]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="format-detection" content="date=no" />
    <meta name="format-detection" content="address=no" />
    <meta name="format-detection" content="telephone=no" />
    <meta name="x-apple-disable-message-reformatting" />
    <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900" rel="stylesheet">
    <link href='https://fonts.googleapis.com/css?family=Open+Sans' rel='stylesheet'>
    <link href='https://fonts.googleapis.com/css?family=Quicksand&display=swap' rel='stylesheet'>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <style type="text/css" media="screen">
        body {
            padding: 0 !important;
            margin: 0 !important;
            display: flex !important;
            justify-content: center !important;
            min-width: 100% !important;
            width: 100% !important;
            background: #f5f5f5;
            -webkit-text-size-adjust: none;
        }
        .mobile-shell {
            width: 100%;
        }
        .td {
            width: 100%;
        }
        .wrapper {
            width: 100%;
            table-layout: fixed;
        }
        .p35-15 {
            padding: 35px 15px;
        }
        .headline {
            text-align: center;
        }
        .hero-title-1 {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            padding-top: 15px;
            line-height: 28px;
            text-align: center;
            color: #191A15;
        }
        .hero-content {
            padding-top: 25px;
            padding-left: 100px;
            padding-right: 100px;
            color: black;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 25px;
            font-weight: normal;
            text-align: center;
            letter-spacing: .01em;
        }
        .btn {
            text-align: center;
            padding-top: 25px;
        }
        .btn a {
            background-color: #54BD95;
            border-radius: 6px;
            color: #ffffff;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            text-align: center;
            text-decoration: none;
            width: 218.18px;
            height: 38px;
            -webkit-text-size-adjust: none;
            padding: 10px 12px;
            line-height: 15px;
        }
        .disclaimer2 {
            padding: 40px 77px;
            color: #FFFFFF;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 20px;
            font-weight: normal;
            text-align: center;
            letter-spacing: .01em;
        }
        .footer-link a {
            color: #FFFFFF;
            font-size: 14px;
        }
        .address {
            padding-left: 17px;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            color: #FFFFFF;
            margin-top: 15px;
            margin-bottom: 0px;
            letter-spacing: .01em;
            line-height: 20px;
        }
        .social-icons {
            float: right;
            margin-right: -50px;
            padding-top: 20px;
        }
        .social-icons a {
            padding-left: 5px;
            padding-right: 5px;
            color: #FFFFFF;
        }
    </style>
</head>

<body class="body" style="
 display: grid;
    justify-items: center;
    background-color: #f5f5f5;
">
    <!-- MOBILE PREHEADER PUSH ANY CONTENT AFTER THE PREHEADER FROM SHOWING UP INSERT UNDER BODY TAG -->
    <table width="612px" border="0" cellspacing="0" cellpadding="0" bgcolor="#f5f5f5 " style="margin: 0 auto">
        <tr>
            <td align="center" valign="top">
                <table border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
                    <tr>
                        <td class="td">
                            <table border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                    <td  height: 501px;">
                                        <!-- Header -->
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                                            <tr>
                                                <td>
                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" class="wrapper">
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="10" cellspacing="0" width="77%" class="wrapper" bgcolor="#f5f5f5">
                                                                    <tr>
                                                                        <!-- Add logo here if needed -->
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" class="wrapper" bgcolor="#ffffff">
                                                                    <tr>
                                                                        <!-- Add logo here if needed -->
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- END Header -->
                                        <!-- Start Content -->
                                        <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" height="501" cellpadding="0">
                                            <tr>
                                                <td bgcolor="#FFFFFF" class="p35-15">
                                                    <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" cellpadding="0">
                                                        <!-- headline -->
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="headline">
                                                                <img style="width: 15%; object-fit: contain; clip-path: circle();" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThtteDflN13M27CDlAPdC7L_fNJ4Eus3fEFBkI8JKxFLmcms3TsIv_EUcvuyjVo46v1k4&usqp=CAU" alt="">
                                                            </td>
                                                        </tr>
                                                        <!-- #headline -->
                                                        <!-- hero-title-1 -->
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="hero-title-1">
                                                                ${
                                                                  this.company
                                                                }<br>invited you
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="hero-content">
                                                                You have been invited by ${
                                                                  this.company
                                                                }  to join  <a href="mailto:contact@halleyx.com" style="text-decoration: none; color:#54BD95">${
          i.company
        }</a>. To get started, accept the invitation below. 
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="btn">
                                                                <a href=${prod}/supplierinvite/${
          i.company 
        }/${i._id}/${this.$cookies.get(
          "token"
        )}?login=Userinvite class="mob-btn" target="_blank">Accept invitation</a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#FFFFFF"  class="hero-content"  style="color:#a6a6a6">
                                                                Hiccups might happen. Your feedback and requests are very welcomed and encouraged at <a href="mailto:contact@halleyx.com" style="color:#54BD95">contact@halleyx.com</a>. We can't wait to see what you create with ${
                                                                  this.company
                                                                }.
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- HERO Images-->
                                    </td>
                                </tr>
                            </table>
                            <!-- END Content -->
                            <!-- Footer -->
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" style="padding: 0 15px;" bgcolor="#ffffff">
                                <tr>
                                    <td bgcolor="#161C2B" class="disclaimer2">
                                        <span class="footer-link"><a href="https://halleyx.com">T&C</a><a style="padding-left:40px; padding-right: 40px;" href="https://halleyx.com">Privacy</a><a href="https://halleyx.com">Cookies</a></span><br/>
                                        <br/>
                                        © ${this.currentYear} ${
          this.company
        }. Copyright and rights reserved
                                        <p class="address">${
                                          this?.instanceAddress?.characteristic
                                            ?.description
                                        }</p>
                                        <span style="float:left; margin-left: -60px; padding-top:20px;"><img width="100" src=""/></span>
                                        <span class="social-icons"><a href="https://www.linkedin.com/company/halleyx/"><i class="fa fa-linkedin fa-lg"></i></a>
                                            <a href="https://halleyx.com/"><i class="fa fa-facebook fa-lg"></i></a>
                                            <a href="https://www.instagram.com/halleyx_inc/"><i class="fa fa-instagram fa-lg"></i></a></span>
                                    </td>
                                </tr>
                            </table>
                            <!-- END Footer -->
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
    <img src="images?q=tbn:ANd9GcRYx7vG1ermb_muIP457oVO48JixkaC0RvhAA&usqp=CAU" width="1" height="1">
</body>

</html>`,
      };
      // const sendEmail =
      await MLTAxiosInstance.post(`/email`, mail);
    },
    crudContextMenu($event, i, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        if (this.computedTableAction(i)[0]?.label === "Mark as Active") {
          this.containerleft =
            document
              ?.querySelector("#table-context" + index)
              ?.parentElement.getBoundingClientRect()?.left - 185;
        } else if (
          this.computedTableAction(i)[1]?.label === "Mark as In-active"
        ) {
          this.containerleft =
            document
              ?.querySelector("#table-context" + index)
              ?.parentElement.getBoundingClientRect()?.left - 160;
        } else {
          this.containerleft =
            document
              .querySelector("#table-context" + index)
              .parentElement.getBoundingClientRect().left - 140;
        }
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    async closeCrudContextMenu($event, ele, i) {
      console.log("🚀 ~ closeCrudContextMenu ~ i:", i);
      console.log("🚀 ~ closeCrudContextMenu ~ ele:", ele);
      // //
      this.editUser = ele;
      this.listView = ele;
      this.editUser.role =
        ele.role && ele.role.length > 0 ? ele.role[0]?.name : "";
      if ($event.toLowerCase() === "edit") {
        this.editView = true;
        this.modal_active_user = true;
        this.viewPage(ele);
      } else if ($event.toLowerCase() == "mark as in-active") {
        this.delete_modal_active = true;
        this.deleteobj = ele;
        this.delete_name = ele.name;
        this.deleterowindex = i;
      } else if ($event.toLowerCase() == "mark as active") {
        let i = {
          id: ele._id,
          status: "Active",
        };
        const contacts = await MLTAxiosInstance.patch(
          `/partymanagement/individual/?company=${ele.company}`,
          i
        );
        if (contacts.data.email) {
          this.alertmsg = "success";
          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };
        } else {
          this.alertmsg = "danger";
          this.notification = {
            type: "danger",
            message: `Data could not be inserted.`,
          };
        }
        // this.contactsDataCalling(this.currPage, this.rowsPerPage);
        this.recallTable();
      } else if ($event.toLowerCase() === "resend invite") {
        console.log(i);
        this.inviteUser(ele);
      }
      // else if ($event.toLowerCase() == "delete") {
      //   this.delete_modal_active = true;
      //   this.deleteobj = ele;
      //   this.delete_name = ele.description;
      //   this.deleterowindex = i;
      //   // await axios.delete("http://localhost:5700/quotemanagement/" + ele._id, {
      //   //   headers: {
      //   //     Authorization: this.$cookies.get("token"),
      //   //   },
      //   // });
      // }
    },
    computedTableAction(data) {
      if (data.status && data.status.toLowerCase() == "active") {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            label: "Mark as In-active",
            icon: "icon-times-square-regular",
          },
        ];
      } else if (data.status && data.status.toLowerCase() == "pending") {
        return [
          {
            label: "Resend invite",
            icon: "icon-send-right-regular",
          },
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
        ];
      } else if (data.status && data.status.toLowerCase() == "inactive") {
        return [
          {
            label: "Mark as Active",
            icon: "icon-check-square-regular",
          },
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }
      // return options.filter((option) => {
      //   if (
      //     data &&
      //     data.status &&
      //     data.status.toLowerCase() == "active" &&
      //     ["Mark as Active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else if (
      //     data &&
      //   //  data.status &&
      //     data.status.toLowerCase() == "inactive" &&
      //     ["Mark as In-active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else if (
      //     data &&
      //     data.status &&
      //     data.status.toLowerCase() == "pending" &&
      //     ["Mark as In-active", "Mark as Active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // });
    },
    async addNewContact() {
      const newConPayload = {
        name: `${this.userPayload.firstName} ${this.userPayload.lastName}`,
        birthDate: "",
        firstName: `${this.userPayload.firstName}`,
        lastName: `${this.userPayload.lastName}`,
        title: "",
        tradingName: this.company,
        companyName: this.company,
        status: "Pending",
        preferred: "",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: `${this.userPayload.email}`,
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                valid: true,
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          this.instanceAddress,
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        relatedParty: [],
        tags: [],
        aristocraticTitle: "",
        role: this.payload.role,
        type: "individual",
        "@baseType": "organisation",
        notes: [],
        attachment: [],
        password: "",
        email: this.userPayload.email,
      };
      newConPayload.parentCompany = this.rootParent;
      newConPayload[
        "familyName"
      ] = `${this.userPayload.firstName} ${this.userPayload.lastName}`;
      newConPayload[
        "givenName"
      ] = `${this.userPayload.firstName} ${this.userPayload.lastName}`;
      newConPayload[
        "name"
      ] = `${this.userPayload.firstName} ${this.userPayload.lastName}`;
      console.log(newConPayload, "newConPayload");

      let payload = JSON.parse(JSON.stringify(newConPayload));

      // payload.relatedParty.push({role:payload.aristocraticTitle,organization:payload.company ,"@type":"multiorganization"})

      payload.tradingName = this.company;
      payload.companyName = this.company;
      payload.company = this.company;

      payload.lastUpdate = new Date();
      let result;
      this.isExistingName = false;
      this.isExistingNameErrorMessage = "";
      this.isExistingEmail = false;
      this.isExistingEmailErrorMessage = "";

      try {
        let result = await MLTAxiosInstance.post(
          `/partymanagement/individual/account/signup`,
          payload
        );
        console.log(result, "result");
        console.log(result.data.message, "result.data.tradingName");
        if (result.data.userId) {
          this.getSingleUser(result.data.userId);

          // this.notification = {
          //   type: "success",
          //   message: `Nice work! Your new user "${newConPayload.name}" is now in the list!`,
          // };
          this.closeAddNewContactModal();
          this.recallTable();
        } else {
          // this.notification = {
          //   type: "warning",
          //   message: `This email was already registered`,
          // };
          this.isEmailEmpty = true;
        }
      } catch (error) {
        if (error.response) {
          console.log("Error k data:", error.response.data);
          console.log("Error status:", error.response.status);
          console.log("Error headers:", error.response.headers);
        } else if (error.request) {
          this.isEmailEmpty = true;
          // this.notification = {
          //   type: "warning",
          //   message: `This email was already registered`,
          // };
          console.log("Error request:", error.request);
        } else {
          console.log("Error message:", error.message);
        }
        this.isEmailEmpty = true;
        // this.notification = {
        //   type: "warning",
        //   message: `This email was already registered`,
        // };
        console.log("Error config:", error.config);
      }
      this.addressKey += 1;

      if (result?.data) {
        // this.notification = {
        //   type: "success",
        //   message: `Nice work! Your new user "${newConPayload.name}" is now in the list!`,
        // };
      }

      // this.contactsDataCalling(this.currPage, this.rowsPerPage);
    },
    async recallTable() {
      this.userFilterOptions.company = this.$cookies.get("company");
      this.userFilterOptions.filterQuery = {
        "@baseType": "organisation",
        company: this.$cookies.get("company"),
        "role.name": this.payload.role,
      };

      const filterResultData = await MLTAxiosInstance.post(
        `util/filterData?company=${this.$cookies.get("company")}`,
        this.userFilterOptions
      );

      this.tableData = filterResultData.data.data;
      this.totalPageNo = filterResultData.data.total;
    },
    async recallTableVal() {
      this.userFilterOptions.company = this.$cookies.get("company");
      this.userFilterOptions.filterQuery = {
        "@baseType": "organisation",
        company: this.$cookies.get("company"),
        "role.name": this.initialPayload.role,
      };
      this.userFilterOptions.paginatedQuery.rowsPerPage = 100;

      const filterResultData = await MLTAxiosInstance.post(
        `util/filterData?company=${this.$cookies.get("company")}`,
        this.userFilterOptions
      );

      this.fullcontent = filterResultData.data.data;

      if (this.initialPayload.role !== this.payload.role) {
        const output = this.fullcontent.map((user) => ({
          relatedParty: [
            {
              id: user._id,
            },
          ],
          name: this.payload.role,
        }));

        await MLTAxiosInstance.patch(`partyRole/bulkwrites/v4`, output);
      }
      // this.totalPageNo = filterResultData.data.total;
    },
    closeAddNewContactModal() {
      this.editView = false;
      this.modal_active_role = false;

      this.addressPreVal = {
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
        },
      };
      let x = {
        name: "",
        birthDate: "",
        firstName: "",
        lastName: "",
        title: "",
        tradingName: "",
        companyName: "",
        status: "Pending",
        preferred: "",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                valid: true,
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              timezone: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        relatedParty: [],
        tags: [],
        aristocraticTitle: "",
        role: "",
        type: "individual",
        "@baseType": "organisation",
        notes: [],
        attachment: [],
        password: "",
        email: "",
      };
      this.modalKey += 1;
      this.newConPayload = JSON.parse(JSON.stringify(x));
      this.modal_active_user = false;
    },
    validateEmail(val) {
      const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (val.length > 0) {
        if (!reg.test(val)) {
          return false; // URL is invalid
        } else {
          return true;
        }
      }
    },
    cancelEditrole() {
      this.payload = JSON.parse(JSON.stringify(this.initialPayload));
    },
    async editRole() {
      const sampleTheads = {};

      sampleTheads.role = this.payload.role;

      sampleTheads.tags = this.payload.tags;
      sampleTheads.description = this.payload.description;
      sampleTheads.id = this.payload._id;
      sampleTheads.permission = this.sections;
      sampleTheads.lastUpdated = new Date().toJSON();
      sampleTheads.createdBy = this.$cookies.get("userName");
      console.log(sampleTheads, "this.sections", this.payload);

      try {
        const rolesAndpermistionVar = await MLTAxiosInstance.patch(
          `/partyRole`,
          sampleTheads
        );
        if (this.payload.role !== this.initialPayload.role) {
          await this.recallTableVal();
        }
        if (rolesAndpermistionVar.data) {
          this.notification = {
            type: "success",
            message: "All set! Your changes have been saved successfully!",
          };
          this.isRoleName = false;
        }
        this.initialPayload = JSON.parse(JSON.stringify(this.payload));
      } catch (error) {
        if (error.response?.data?.code === 11000) {
          this.isRoleName = true;
        } else {
          this.isRoleName = true;
        }
      }
    },
    receivedTags(e) {
      let arr = [];
      e.filter((item) => {
        if (item.checked == true) {
          arr.push(item.name);
        }
      });
      this.payload.tags = arr;
      console.log(this.payload.tags, "this.payload.tags");
    },
    async addTagList(e) {
      let obj = {};
      obj.name = e.name;
      obj.module = "role";
      await DMNAxiosInstance.post(`mapRef/tagsList`, obj);
      let tagsList = await this.getReferanceData("tagsList");
      let arr = this.payload.tags;
      arr.push(e.name);
      this.payload.tags = arr;
      this.tagsList = tagsList.filter((item) => {
        return item.module === "role";
      });
    },

    handleCurrentChange(changedData) {
      this.selectedValue = changedData.value;
      console.log(
        "🚀 ~ file: ButtonGroupView.vue:354 ~ handleCurrentChange ~ changedData:",
        changedData
      );
    },
    newRoleModule() {
      // this.payload = {
      //   name: "",
      //   tags: [],
      //   description: "",
      // };
      // this.tagcomponetkey += 1;
      console.log(this.tagsList, "tagsList");

      // this.ticketComponetD += 1;
      // this.ticketComponetI += 1;
      this.modal_active_role = true;
      // this.tagcomponetkey += 1;

      // console.log(
      //   (this.payload = {
      //     name: "",
      //     tags: [],
      //     description: "",
      //   }),
      //   this.payload.tags
      // );
    },
    async editContactUser() {
      if (
        this.userPayloadFull.contactMedium[0].characteristic.emailAddress !==
        this.userPayload.email
      ) {
        this.userPayloadFull.status = "Pending";
      }
      this.userPayloadFull.contactMedium[0].characteristic.emailAddress =
        this.userPayload.email;
      this.userPayloadFull.email = this.userPayload.email;

      this.userPayloadFull.firstName = this.userPayload.firstName;
      this.userPayloadFull.lastName = this.userPayload.lastName;
      this.userPayloadFull.id = this.userPayload.id;
      this.userPayloadFull.email = this.userPayload.email;
      this.userPayloadFull.parentCompany = this.rootParent;
      this.userPayloadFull[
        "familyName"
      ] = `${this.userPayload.firstName} ${this.userPayload.lastName}`;
      this.userPayloadFull[
        "givenName"
      ] = `${this.userPayload.firstName} ${this.userPayload.lastName}`;
      this.userPayloadFull[
        "name"
      ] = `${this.userPayload.firstName} ${this.userPayload.lastName}`;

      this.userPayloadFull.tradingName = this.company;
      this.userPayloadFull.companyName = this.company;
      this.userPayloadFull.company = this.company;
      delete this.userPayloadFull._id;

      try {
        let result = await MLTAxiosInstance.patch(
          `/partymanagement/individual?company=${this.$cookies.get("company")}`,
          this.userPayloadFull
        );

        console.log(result, "result");
        console.log(result.data.message, "result.data.tradingName");
        if (result.data.userId) {
          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };
          this.recallTable();
          this.closerolesmodel();
        } else {
          // this.notification = {
          //   type: "warning",
          //   message: `This email was already registered`,
          // };
          this.isEmailEmpty = true;
        }
      } catch (error) {
        if (error.response) {
          console.log("Error k data:", error.response.data);
          console.log("Error status:", error.response.status);
          console.log("Error headers:", error.response.headers);
        } else if (error.request) {
          this.isEmailEmpty = true;
          // this.notification = {
          //   type: "warning",
          //   message: `This email was already registered`,
          // };
          console.log("Error request:", error.request);
        } else {
          console.log("Error message:", error.message);
        }
        this.isEmailEmpty = true;
        // this.notification = {
        //   type: "warning",
        //   message: `This email was already registered`,
        // };
        console.log("Error config:", error.config);
      }
      // console.log("noNotif", notif);
    },
    async closerolesmodel() {
      console.log(this.tagsList, "tagsList");
      this.modal_active_role = false;
      this.isEmailEmpty = false;
      this.editMode = false;
      this.userPayload = {
        firstName: "",
        lastName: "",
        email: "",
        id: 0,
      };
    },
    async addRole() {
      let add = {
        role: this.role,
        permission: {
          Schema: {
            edit: this.schemaEdit[0].checked,
            view: this.schemaView[0].checked,
          },
          Rules: {
            edit: this.ruleEdit[0].checked,
            view: this.ruleView[0].checked,
          },
          Test_data: {
            edit: this.testEdit[0].checked,
            view: this.testView[0].checked,
          },
          User_settings: {
            edit: this.settingsEdit[0].checked,
            view: this.settingsView[0].checked,
          },
        },
      };
      // let token = {
      //   headers: {
      //     Authorization: 'Bearer  ' + this.token,
      //   },
      // };

      // const role =
      // await axios.post(
      //   this.fet + `/partyRole/${this.company}`,
      //   add,
      //   token
      // );

      const access = await MLTAxiosInstance.post(
        `/partyRole/?company=${this.company}`,
        add
      );
      console.log(access, "add roles and permisttion");

      this.$router.push(`/roles`);
    },
    async exitView() {
      let update = {
        id: this.userId,
        impersonation: false,
        impersinater: "",
      };
      // const result =
      await axios.patch(this.fet + `/partymanagement/individual`, update);

      let token = {
        token: this.impersonitor,
      };
      axios
        .post(this.fet + `/partymanagement/individual/auth`, token)
        .then((val) => {
          let company = val.data.company;
          if (company != "") {
            // let c_name = null;
            // if (company == 'Halleyx') {
            //   c_name = '';
            // } else {
            //   c_name = company + '.';

            // }
            // location.replace(window.location.protocol+"//"+`${c_name}`+window.location.hostname+':'+window.location.port +`/DMN/dashboard/${this.impersonitor}`)

            window.location.href =
              window.location.protocol +
              "//" +
              window.location.hostname +
              ":" +
              window.location.port +
              `/DMN/dashboard/${this.impersonitor}`;
          }
        });
    },
  },
};
</script>
