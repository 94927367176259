<template>
  <div style="padding: 30px">
    <div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
        "
      >
        <div style="display: flex; align-items: center; gap: 16px">
          <span style="">
            <hlx-file-upload
              :file-type="'.jpg,.jpeg,.png'"
              :file-size-limit="'20mb'"
              :uploadtype="'Rectangle'"
              :style-background="backgrounStyleupload"
              :height="50"
              :width="50"
              :read-only="true"
              :custom-icon="icon"
              :type="'profile-upload'"
              :name-display="payloadContact.customName"
              :content="'Import'"
              :profile-pic-preview="profilePicUrl"
            />
          </span>

          <div style="display: flex; flex-direction: column; gap: 8px">
            <div style="display: flex; align-items: center; gap: 8px">
              <span style="font-weight: bold">{{
                payloadContact.customName
              }}</span>
              <hlx-label
                v-if="
                  payloadContact?.status?.length > 0 &&
                  payloadContact.status === 'Active'
                "
                class="primary sm"
                >{{ payloadContact.status }}</hlx-label
              >
              <hlx-label
                v-if="
                  payloadContact?.status?.length > 0 &&
                  payloadContact.status === 'Inactive'
                "
                class="error sm"
                >{{ payloadContact.status }}</hlx-label
              >
            </div>
            <div style="color: #a6a6a6; font-size: 12px; width: max-content">
              {{ payloadContact.contactMedium[3].characteristic.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="fulltotal === undefined"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
        height: 80vh;
      "
    >
      <img src="@/assets/images/supplierContact.svg" alt="" />
    </div>
    <div v-if="fulltotal > 0">
      <b> {{ "Contacts" }}</b>
      <div
        class="cm-accounts-right-panel-top-section-util-container"
        style="
          display: flex;
          justify-content: space-between;
          gap: 1rem;
          margin-bottom: 12px;
          margin-top: 20px;
        "
      >
        <div style="display: flex; align-items: center; font-weight: bold">
          <hlx-switch
            v-model="selectedSwitch"
            :switch-items="switchOptions"
            value-prop="value"
            display-prop="name"
            @change="chosenSwitch"
          />
        </div>
        <div>
          <div style="display: flex; gap: 16px">
            <span class="search-container" style="width: 250px">
              <hlx-search
                v-model="searchvalue"
                @search-key="contactSearch(searchvalue)"
              ></hlx-search>
            </span>
          </div>
        </div>
      </div>
    </div>
    <section v-if="switchvalue === 'all' || switchvalue === ''">
      <div v-if="fulltotal > 0">
        <div
          v-if="fulltotal > 0"
          class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
        >
          <hlx-table
            :column-count="accounts_table_theads.length + 2"
            :border="['table', 'header', 'horizontal']"
            :row-hover="true"
            theme="grey"
            :re-arrange-columns="re_arrange"
            :re-arrange-heads="accounts_table_theads_cc"
            @close-rearrange="arrangeColumns"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
              <hlx-table-head
                :width="60"
                :align="'center'"
                style="padding: 0 15px"
                :fixed="'left'"
                >{{ "S.no" }}</hlx-table-head
              >
              <hlx-table-head
                v-for="(i, index) in accounts_table_theads"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
              >
                {{ i.label }}</hlx-table-head
              >
              <!-- <hlx-table-head
              v-show="mode === 'edit'"
              :align="'center'"
              :width="70"
              :fixed="'right'"
              ><span class="action-icon">
                <i
                  class="icon-settings-regular"
                  style="cursor: default"
                ></i> </span
            ></hlx-table-head> -->
              <!-- @click="arrange(true)" -->
            </template>
            <template #tbody>
              <tr v-for="(i, index) in paginatedData" id="" :key="index">
                <hlx-table-cell :align="'center'" :fixed="'left'">
                  {{ serialNumber(index) }}
                </hlx-table-cell>
                <hlx-table-cell
                  v-for="(j, col_index) in accounts_table_theads"
                  :key="col_index"
                  :align="j.align"
                  :fixed="j.fixed"
                >
                  <div
                    v-if="j.prop.toLowerCase() === 'firstname'"
                    :id="j.prop + index"
                  >
                    <span>{{ i[j.prop] + " " + i["lastName"] }}</span>
                  </div>

                  <div
                    v-else-if="j.prop.toLowerCase() === 'id'"
                    :id="j.prop + index"
                    @click="goToContacts(i)"
                  >
                    <span
                      class="link-name"
                      style="color: #2879b7; cursor: pointer"
                    >
                      {{ i[j.prop] }}
                    </span>
                  </div>

                  <div
                    v-else-if="j.prop.toLowerCase() === 'address'"
                    :id="j.prop + index"
                    style="
                      margin: 10px 0;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    :style="{
                      width: j.width + 'px',
                    }"
                    @mouseover="
                      tooltipover(
                        j.prop + index,
                        'tooltip-text' + j.prop + index
                      )
                    "
                    @mouseleave="
                      tooltipleave(
                        j.prop + index,
                        'tooltip-text' + j.prop + index
                      )
                    "
                  >
                    {{ i["contactMedium"][2]?.characteristic?.description }}
                  </div>

                  <div v-else-if="j.prop.toLowerCase() === 'status'">
                    <span v-if="i[j.prop] != ''">
                      <div>
                        <hlx-status :type="i[j.prop]"></hlx-status>
                      </div>
                    </span>
                  </div>
                  <div
                    v-else-if="j.prop.toLowerCase() === 'contactmedium'"
                    style="margin: 0.5rem 0"
                  >
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        margin-bottom: 4px;
                      "
                    >
                      <i class="icon-envelope-regular"></i>
                      {{ i[j.prop][0]?.characteristic?.emailAddress }}
                    </div>
                    <div
                      style="display: flex; align-items: center; gap: 0.5rem"
                    >
                      <i class="icon-phone-regular"></i>
                      {{ i[j.prop][1]?.characteristic?.phoneNumber.value }}
                    </div>
                  </div>
                  <div v-else>{{ i[j.prop] }}</div>
                </hlx-table-cell>
                <!-- <hlx-table-cell
                v-show="mode === 'edit'"
                :align="'center'"
                :width="120"
                :fixed="'right'"
              >
                <span :id="'table-context' + index" class="action-icon">
                  <i
                    style="position: relative"
                    class="icon-more-vertical-filled"
                    :class="i.context === true ? 'active-action' : ''"
                    @click="crudContextMenu($event, i, index)"
                  >
                    <hlx-context-menu
                      :top="containertop"
                      :left="containerleft"
                      :conditionvalue="150"
                      :conditiondown="2"
                      :conditionup="-38"
                      :options="computedTableAction(i)"
                      :data="index.toString()"
                      :show="i.context == true"
                      @chosen="closeCrudContextMenu($event, i, index)"
                    />
                  </i>
                </span>
              </hlx-table-cell> -->
              </tr>
            </template>
          </hlx-table>
        </div>
      </div>
    </section>
    <section v-if="switchvalue === 'active'">
      <div
        v-if="Activetotal === undefined"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 40px;
          height: 80vh;
        "
      >
        <img src="@/assets/images/supplierActiveContact.svg" alt="" />
      </div>

      <div v-if="Activetotal > 0">
        <div
          v-if="fulltotal > 0"
          class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
        >
          <hlx-table
            :column-count="accounts_table_theads.length + 2"
            :border="['table', 'header', 'horizontal']"
            :row-hover="true"
            theme="grey"
            :re-arrange-columns="re_arrange"
            :re-arrange-heads="accounts_table_theads_cc"
            @close-rearrange="arrangeColumns"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
              <hlx-table-head
                :width="60"
                :align="'center'"
                style="padding: 0 15px"
                :fixed="'left'"
                >{{ "S.no" }}</hlx-table-head
              >
              <hlx-table-head
                v-for="(i, index) in accounts_table_theads"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
              >
                {{ i.label }}</hlx-table-head
              >
              <!--  <hlx-table-head
              v-show="mode === 'edit'"
              :align="'center'"
              :width="70"
              :fixed="'right'"
              ><span class="action-icon">
                <i
                  class="icon-settings-regular"
                  style="cursor: default"
                ></i> </span
            ></hlx-table-head> -->
              <!-- @click="arrange(true)" -->
            </template>
            <template #tbody>
              <tr v-for="(i, index) in paginatedData" id="" :key="index">
                <hlx-table-cell :align="'center'" :fixed="'left'">
                  {{ serialNumber(index) }}
                </hlx-table-cell>
                <hlx-table-cell
                  v-for="(j, col_index) in accounts_table_theads"
                  :key="col_index"
                  :align="j.align"
                  :fixed="j.fixed"
                >
                  <div
                    v-if="j.prop.toLowerCase() === 'firstname'"
                    :id="j.prop + index"
                  >
                    <span>{{ i[j.prop] + " " + i["lastName"] }}</span>
                  </div>

                  <div
                    v-else-if="j.prop.toLowerCase() === 'id'"
                    :id="j.prop + index"
                    @click="goToContacts(i)"
                  >
                    <span
                      class="link-name"
                      style="color: #2879b7; cursor: pointer"
                    >
                      {{ i[j.prop] }}
                    </span>
                  </div>

                  <div
                    v-else-if="j.prop.toLowerCase() === 'address'"
                    :id="j.prop + index"
                    style="
                      margin: 10px 0;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    :style="{
                      width: j.width + 'px',
                    }"
                    @mouseover="
                      tooltipover(
                        j.prop + index,
                        'tooltip-text' + j.prop + index
                      )
                    "
                    @mouseleave="
                      tooltipleave(
                        j.prop + index,
                        'tooltip-text' + j.prop + index
                      )
                    "
                  >
                    {{ i["contactMedium"][2]?.characteristic?.description }}
                    <!-- <p id="tooltip-text" :class="'tooltip-text' + j.prop + index">
            {{ i["contactMedium"][3]["location"] }}
          </p> -->
                  </div>

                  <div v-else-if="j.prop.toLowerCase() === 'status'">
                    <span v-if="i[j.prop] != ''">
                      <div>
                        <hlx-status :type="i[j.prop]"></hlx-status>
                      </div>
                    </span>
                  </div>
                  <div
                    v-else-if="j.prop.toLowerCase() === 'contactmedium'"
                    style="margin: 0.5rem 0"
                  >
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        margin-bottom: 4px;
                      "
                    >
                      <i class="icon-envelope-regular"></i>
                      {{ i[j.prop][0]?.characteristic?.emailAddress }}
                    </div>
                    <div
                      style="display: flex; align-items: center; gap: 0.5rem"
                    >
                      <i class="icon-phone-regular"></i>
                      {{ i[j.prop][1]?.characteristic?.phoneNumber?.value }}
                    </div>
                  </div>
                  <div v-else>{{ i[j.prop] }}</div>
                </hlx-table-cell>
                <!-- <hlx-table-cell
                v-show="mode === 'edit'"
                :align="'center'"
                :width="120"
                :fixed="'right'"
              >
                <span :id="'table-context' + index" class="action-icon">
                  <i
                    style="position: relative"
                    class="icon-more-vertical-filled"
                    :class="i.context === true ? 'active-action' : ''"
                    @click="crudContextMenu($event, i, index)"
                  >
                    <hlx-context-menu
                      :top="containertop"
                      :left="containerleft"
                      :conditionvalue="150"
                      :conditiondown="2"
                      :conditionup="-38"
                      :options="computedTableAction(i)"
                      :data="index.toString()"
                      :show="i.context == true"
                      @chosen="closeCrudContextMenu($event, i, index)"
                    />
                  </i>
                </span>
              </hlx-table-cell> -->
              </tr>
            </template>
          </hlx-table>
        </div>
      </div>
    </section>
    <section v-if="switchvalue === 'inactive'">
      <div
        v-if="Inactivetotal === undefined"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 40px;
          height: 80vh;
        "
      >
        <img src="@/assets/images/supplierInactiveContact.svg" alt="" />
      </div>

      <div v-if="Inactivetotal > 0">
        <div
          v-if="fulltotal > 0"
          class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
        >
          <hlx-table
            :column-count="accounts_table_theads.length + 2"
            :border="['table', 'header', 'horizontal']"
            :row-hover="true"
            theme="grey"
            :re-arrange-columns="re_arrange"
            :re-arrange-heads="accounts_table_theads_cc"
            @close-rearrange="arrangeColumns"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
              <hlx-table-head
                :width="60"
                :align="'center'"
                style="padding: 0 15px"
                :fixed="'left'"
                >{{ "S.no" }}</hlx-table-head
              >
              <hlx-table-head
                v-for="(i, index) in accounts_table_theads"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
              >
                {{ i.label }}</hlx-table-head
              >
              <!-- <hlx-table-head
              v-show="mode === 'edit'"
              :align="'center'"
              :width="70"
              :fixed="'right'"
              ><span class="action-icon">
                <i
                  class="icon-settings-regular"
                  style="cursor: default"
                ></i> </span
            ></hlx-table-head> -->
              <!-- @click="arrange(true)" -->
            </template>
            <template #tbody>
              <tr v-for="(i, index) in paginatedData" id="" :key="index">
                <hlx-table-cell :align="'center'" :fixed="'left'">
                  {{ serialNumber(index) }}
                </hlx-table-cell>
                <hlx-table-cell
                  v-for="(j, col_index) in accounts_table_theads"
                  :key="col_index"
                  :align="j.align"
                  :fixed="j.fixed"
                >
                  <div
                    v-if="j.prop.toLowerCase() === 'firstname'"
                    :id="j.prop + index"
                  >
                    <span>{{ i[j.prop] + " " + i["lastName"] }}</span>
                  </div>

                  <div
                    v-else-if="j.prop.toLowerCase() === 'id'"
                    :id="j.prop + index"
                    @click="goToContacts(i)"
                  >
                    <span
                      class="link-name"
                      style="color: #2879b7; cursor: pointer"
                    >
                      {{ i[j.prop] }}
                    </span>
                  </div>

                  <div
                    v-else-if="j.prop.toLowerCase() === 'address'"
                    :id="j.prop + index"
                    style="
                      margin: 10px 0;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    :style="{
                      width: j.width + 'px',
                    }"
                    @mouseover="
                      tooltipover(
                        j.prop + index,
                        'tooltip-text' + j.prop + index
                      )
                    "
                    @mouseleave="
                      tooltipleave(
                        j.prop + index,
                        'tooltip-text' + j.prop + index
                      )
                    "
                  >
                    {{ i["contactMedium"][2]?.characteristic?.description }}
                    <!-- <p id="tooltip-text" :class="'tooltip-text' + j.prop + index">
            {{ i["contactMedium"][3]["location"] }}
          </p> -->
                  </div>

                  <div v-else-if="j.prop.toLowerCase() === 'status'">
                    <span v-if="i[j.prop] != ''">
                      <div>
                        <hlx-status :type="i[j.prop]"></hlx-status>
                      </div>
                    </span>
                  </div>
                  <div
                    v-else-if="j.prop.toLowerCase() === 'contactmedium'"
                    style="margin: 0.5rem 0"
                  >
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        margin-bottom: 4px;
                      "
                    >
                      <i class="icon-envelope-regular"></i>
                      {{ i[j.prop][0]?.characteristic?.emailAddress }}
                    </div>
                    <div
                      style="display: flex; align-items: center; gap: 0.5rem"
                    >
                      <i class="icon-phone-regular"></i>
                      {{ i[j.prop][1]?.characteristic?.phoneNumber?.value }}
                    </div>
                  </div>
                  <div v-else>{{ i[j.prop] }}</div>
                </hlx-table-cell>
                <!-- <hlx-table-cell
                v-show="mode === 'edit'"
                :align="'center'"
                :width="120"
                :fixed="'right'"
              >
                <span :id="'table-context' + index" class="action-icon">
                  <i
                    style="position: relative"
                    class="icon-more-vertical-filled"
                    :class="i.context === true ? 'active-action' : ''"
                    @click="crudContextMenu($event, i, index)"
                  >
                    <hlx-context-menu
                      :top="containertop"
                      :left="containerleft"
                      :conditionvalue="150"
                      :conditiondown="2"
                      :conditionup="-38"
                      :options="computedTableAction(i)"
                      :data="index.toString()"
                      :show="i.context == true"
                      @chosen="closeCrudContextMenu($event, i, index)"
                    />
                  </i>
                </span>
              </hlx-table-cell> -->
              </tr>
            </template>
          </hlx-table>
        </div>
      </div>
    </section>
    <section v-if="switchvalue === 'pending'">
      <div
        v-if="pendingtotal === undefined"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 40px;
          height: 80vh;
        "
      >
        <img src="@/assets/images/supplierPendingContact.svg" alt="" />
      </div>

      <div v-if="pendingtotal > 0">
        <div class="cm-accounts-right-panel-top-section-table-wrap table-wrap">
          <hlx-table
            :column-count="accounts_table_theads.length + 2"
            :border="['table', 'header', 'horizontal']"
            :row-hover="true"
            theme="grey"
            :re-arrange-columns="re_arrange"
            :re-arrange-heads="accounts_table_theads_cc"
            @close-rearrange="arrangeColumns"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
              <hlx-table-head
                :width="60"
                :align="'center'"
                style="padding: 0 15px"
                :fixed="'left'"
                >{{ "S.no" }}</hlx-table-head
              >
              <hlx-table-head
                v-for="(i, index) in accounts_table_theads"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
              >
                {{ i.label }}</hlx-table-head
              >
              <!-- <hlx-table-head
              v-show="mode === 'edit'"
              :align="'center'"
              :width="70"
              :fixed="'right'"
              ><span class="action-icon">
                <i
                  class="icon-settings-regular"
                  style="cursor: default"
                ></i> </span
            ></hlx-table-head> -->
              <!-- @click="arrange(true)" -->
            </template>
            <template #tbody>
              <tr v-for="(i, index) in paginatedData" id="" :key="index">
                <hlx-table-cell :align="'center'" :fixed="'left'">
                  {{ serialNumber(index) }}
                </hlx-table-cell>
                <hlx-table-cell
                  v-for="(j, col_index) in accounts_table_theads"
                  :key="col_index"
                  :align="j.align"
                  :fixed="j.fixed"
                >
                  <div
                    v-if="j.prop.toLowerCase() === 'firstname'"
                    :id="j.prop + index"
                  >
                    <span>{{ i[j.prop] + " " + i["lastName"] }}</span>
                  </div>

                  <div
                    v-else-if="j.prop.toLowerCase() === 'id'"
                    :id="j.prop + index"
                    @click="goToContacts(i)"
                  >
                    <span
                      class="link-name"
                      style="color: #2879b7; cursor: pointer"
                    >
                      {{ i[j.prop] }}
                    </span>
                  </div>

                  <div
                    v-else-if="j.prop.toLowerCase() === 'address'"
                    :id="j.prop + index"
                    style="
                      margin: 10px 0;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    :style="{
                      width: j.width + 'px',
                    }"
                    @mouseover="
                      tooltipover(
                        j.prop + index,
                        'tooltip-text' + j.prop + index
                      )
                    "
                    @mouseleave="
                      tooltipleave(
                        j.prop + index,
                        'tooltip-text' + j.prop + index
                      )
                    "
                  >
                    {{ i["contactMedium"][2]?.characteristic?.description }}
                    <!-- <p id="tooltip-text" :class="'tooltip-text' + j.prop + index">
            {{ i["contactMedium"][3]["location"] }}
          </p> -->
                  </div>

                  <div v-else-if="j.prop.toLowerCase() === 'status'">
                    <span v-if="i[j.prop] != ''">
                      <div>
                        <hlx-status :type="i[j.prop]"></hlx-status>
                      </div>
                    </span>
                  </div>
                  <div
                    v-else-if="j.prop.toLowerCase() === 'contactmedium'"
                    style="margin: 0.5rem 0"
                  >
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        margin-bottom: 4px;
                      "
                    >
                      <i class="icon-envelope-regular"></i>
                      {{ i[j.prop][0]?.characteristic?.emailAddress }}
                    </div>
                    <div
                      style="display: flex; align-items: center; gap: 0.5rem"
                    >
                      <i class="icon-phone-regular"></i>
                      {{ i[j.prop][1]?.characteristic?.phoneNumber?.value }}
                    </div>
                  </div>
                  <div v-else>{{ i[j.prop] }}</div>
                </hlx-table-cell>
                <!-- <hlx-table-cell
                v-show="mode === 'edit'"
                :align="'center'"
                :width="120"
                :fixed="'right'"
              >
                <span :id="'table-context' + index" class="action-icon">
                  <i
                    style="position: relative"
                    class="icon-more-vertical-filled"
                    :class="i.context === true ? 'active-action' : ''"
                    @click="crudContextMenu($event, i, index)"
                  >
                    <hlx-context-menu
                      :top="containertop"
                      :left="containerleft"
                      :conditionvalue="150"
                      :conditiondown="2"
                      :conditionup="-38"
                      :options="computedTableAction(i)"
                      :data="index.toString()"
                      :show="i.context == true"
                      @chosen="closeCrudContextMenu($event, i, index)"
                    />
                  </i>
                </span>
              </hlx-table-cell> -->
              </tr>
            </template>
          </hlx-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  MLTAxiosInstance,
  UPCAxiosInstance,
  DMNAxiosInstance,
} from "@/config/axiosConfig";
// import addressComponent from "@/components/CM/autocompleteComponent.vue";

export default {
  components: {
    // addressComponent,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // datacontact: {
    //   type: Array,
    //   default: () => {
    //     return [];
    //   },
    // },
    mode: {
      type: String,
      default: "edit",
    },
  },
  emits: ["contact-data", "new-contact", "edit-contact", "delete-contact"],
  data() {
    return {
      profilePicUrl: "",
      emailid: "",
      backgrounStyleupload: {
        backgroundColor: "",
        bacgroundtTextColor: "#A8A8A8",
        border: false,
        borderColor: "#A8A8A8",
        TextfontSize: 16,
      },
      notification: {},
      disableContact: true,
      selectedSwitch: "all",
      switchOptions: [
        {
          name: "All",
          value: "all",
        },
        {
          name: "Active",
          value: "active",
        },
        {
          name: "Pending",
          value: "pending",
        },
        {
          name: "Inactive",
          value: "inactive",
        },
      ],
      headpayload: {},
      contactStatus: "",
      showViewContactModal: false,
      showAddNewAdrressModal: false,
      delete_name: "",
      delete_index: "",
      delete_modal_active: false,
      dobKey: 0,
      isTitleEmpty: false,
      isFirstNameEmpty: false,
      isLastNameEmpty: false,
      isEmailEmpty: false,
      isAddButtonDisabled: false,
      tableData: [],
      showContactModal: false,
      showEditContactModal: false,
      payloadContact: {
        attachment: [],
        name: "",
        role: "",
        password: "",
        email: "",
        phone: "",
        tags: [],
        notes: [],
        companyName: "",
        tradingName: "",
        status: "",
        type: "",
        "@baseType": "",
        paymentTerms: [
          {
            name: "",
            id: "",
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: "Canada",
                  flags: "https://flagcdn.com/ca.svg",
                  short: "CA",
                  dial_code: "+1",
                },
                value: "",
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              "@type": "",
            },
          },
        ],
      },
      payload: {
        familyName: "",
        givenName: "",
        rootName: "",
        name: "",
        firstName: "",
        lastName: "",
        tradingName: "",
        companyName: `${this.company}`,
        "@baseType": "supplier",
        status: "Pending",
        lastUpdated: new Date().toJSON(),
        preferred: "",
        aristocraticTitle: "",
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: "Canada",
                  flags: "https://flagcdn.com/ca.svg",
                  short: "CA",
                  dial_code: "+1",
                },
                value: "",
              },
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              timezone: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        role: "supplier",
        type: "individual",
        notes: "",
        password: "",
        email: "",
        parentCompany: this.company,
      },
      editPayload: {
        familyName: "",
        givenName: "",
        name: "",
        firstName: "",
        lastName: "",
        tradingName: "",
        companyName: `${this.company}`,
        status: "Pending",
        lastUpdated: new Date().toJSON(),
        preferred: "",
        "@baseType": "supplier",
        aristocraticTitle: "",
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: "Canada",
                  flags: "https://flagcdn.com/ca.svg",
                  short: "CA",
                  dial_code: "+1",
                },
                value: "",
              },
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              timezone: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        role: "supplier",
        type: "individual",
        notes: "",
        password: "",
        email: "",
        parentCompany: this.company,
      },
      rows: 5,
      titleList: [
        { name: "Mr.", value: "Mr." },
        { name: "Mrs.", value: "Mrs." },
      ],
      designationList: [],
      statusList: [
        { name: "Pending" },
        { name: "Active" },
        { name: "Inactive" },
      ],
      preferredList: [
        { name: "Email id", value: "Email" },
        { name: "Phone number", value: "phoneNumber" },
      ],
      key: 0,
      accounts_table_theads: [
        // {
        //   sortable: false,
        //   resizable: false,
        //   width: 100,
        //   label: "ID",
        //   prop: "id",
        //   align: "left",
        // },
        {
          sortable: false,
          resizable: false,
          width: 150,
          label: "Name",
          prop: "firstName",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 100,
          label: "Status",
          prop: "status",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: null,
          label: "Contact medium",
          prop: "contactMedium",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: null,
          label: "Location",
          prop: "address",
          align: "left",
        },
      ],
      switchvalue: "",
      accounts_table_theads_cc: [],
      duplicateTheads: [],
      re_arrange: false,
      currPage: 1,
      rowsPerPage: 10,
      editMode: true,
      copyOfContactTableData: [],
      locationApi: "https://appbackend-ghubgwxrcq-pd.a.run.app/autocomplete",
      locationData: [],
      newAddressPayload: {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      },
      generatedName: this.generateRandomString(20),
      activeTable: 0,
      inactiveTable: 0,
      pendingTable: 0,
      showNewAddressOption: false,
      fulltotal: 0,
      pendingtotal: 0,
      Activetotal: 0,
      Inactivetotal: 0,
      searchvalue: "",
    };
  },
  computed: {
    paginatedData() {
      return this.copyOfContactTableData;
    },
  },
  watch: {
    showEditContactModal: {
      handler(val) {
        console.log(val, "showEditContactModal");
      },
      immediate: true,
      deep: true,
    },
    switchvalue: {
      handler(val) {
        if (val === "active") {
          this.EditPrevalueStatus = "Active";
        }
        if (val === "inactive") {
          this.EditPrevalueStatus = "Inactive";
        }
        if (val === "pending") {
          this.EditPrevalueStatus = "Pending";
        }
      },
      immediate: true,
      deep: true,
    },
    data: {
      handler(val) {
        this.headpayload = val;
        console.log(val, "product value");
      },
      immediate: true,
      deep: true,
    },
    // datacontact: {
    //   handler(val) {
    //     if (val.length > 0) {
    //       this.tableData = val;
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    payload: {
      handler(val) {
        console.log(val, this.company);

        val.parentCompany = this.company;
        val["@baseType"] = "supplier";
        val.companyName = this.company;
        val.tradingName = this.company;
        val.familyName = this.company;
        val.givenName = this.company;

        val.email = val.contactMedium[0].characteristic.emailAddress;
        val.rootName = this.company;
        if (val.title !== "") {
          this.isTitleEmpty = false;
        }
        if (val.firstName !== "") {
          this.isFirstNameEmpty = false;
        }
        if (val.lastName !== "") {
          this.isLastNameEmpty = false;
        }
        if (val.email !== "") {
          this.isEmailEmpty = false;
        }
        for (let i = 0; i < val.contactMedium.length; i++) {
          if (val.contactMedium[i].mediumType === val.preferred) {
            val.contactMedium[i].preferred = true;
          } else {
            val.contactMedium[i].preferred = false;
          }
        }
      },
      immediate: true,
      deep: true,
    },
    editPayload: {
      handler(val) {
        val.id = val._id;
        val.parentCompany = this.company;
        val["@baseType"] = "supplier";
        val.companyName = this.company;
        val.tradingName = this.company;
        val.familyName = this.company;
        val.givenName = this.company;

        val.email = val.contactMedium[0].characteristic.emailAddress;
        if (val.title !== "") {
          this.isTitleEmpty = false;
        }
        if (val.firstName !== "") {
          this.isFirstNameEmpty = false;
        }
        if (val.lastName !== "") {
          this.isLastNameEmpty = false;
        }
        if (val.email !== "") {
          this.isEmailEmpty = false;
        }
        if (val.contactMedium !== undefined) {
          val.email = val?.contactMedium[0].characteristic?.emailAddress;

          for (let i = 0; i < val.contactMedium.length; i++) {
            if (val.contactMedium[i].mediumType === val.preferred) {
              val.contactMedium[i].preferred = true;
            } else {
              val.contactMedium[i].preferred = false;
            }
          }
        }
      },
      immediate: true,
      deep: true,
    },
    tableData: {
      handler(val) {
        this.copyOfContactTableData = JSON.parse(JSON.stringify(val));
        console.log(this.copyOfContactTableData);
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    const val1 = await MLTAxiosInstance.get(`partymanagement/individual/auth`);
    this.company = val1.data.company;
    this.userId = val1.data.userId;
    const access = await MLTAxiosInstance.get(
      `partyRole/get/${val1.data.userId}`
    );

    const payload = await MLTAxiosInstance.get(
      `partymanagement/organisation/${this.userId}?company=${this.company}`
    );
    this.payloadContact = payload.data;
    console.log(access, val1.data.company, val1.data.company, val1, "<>");
    const profileName = this.payloadContact.attachment.filter((item) => {
      return item["@type"] !== undefined && item["@type"] === "profile"
        ? item
        : false;
    });
    console.log(profileName[0]?.name);
    this.profilePicName = profileName[0]?.name;
    const url = await UPCAxiosInstance.get(
      `/gcpupload/files/${profileName[0]?.name}`,
      { responseType: "arraybuffer" }
    );
    // console.log(new Uint8Array([url.data,"url incoming"]))
    this.rawProfileUrlData = url.data;
    this.profilePicUrl = await this.bufferToDataURL(url.data);
    const fulltableData = {
      company: `${this.company}`,
      type: "filter",
      module: "hierarchycontacts",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      lastUpdated: new Date().toJSON(),
      sortFieldAndOrder: {},
      limit: 2,
      filterQuery: {},
      searchQuery: "Active",
    };
    const fulltabledata = await MLTAxiosInstance.post(
      `/util/filterData?company=${this.company}`,
      fulltableData
    );

    this.fulltotal = fulltabledata.data.total;
    this.tableData = fulltabledata.data.data;
    const pendingtableData = {
      company: `${this.company}`,
      type: "search",
      module: "hierarchycontacts",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      lastUpdated: new Date().toJSON(),
      sortFieldAndOrder: {},
      limit: 2,
      filterQuery: {},
      searchQuery: "Pending",
    };
    const pendingtabledata = await MLTAxiosInstance.post(
      `/util/filterData?company=${this.company}`,
      pendingtableData
    );

    this.pendingtotal = pendingtabledata.data.total;

    const ActivetableData = {
      company: `${this.company}`,
      type: "filter-search",
      module: "hierarchycontacts",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      lastUpdated: new Date().toJSON(),
      sortFieldAndOrder: {},
      limit: 2,
      filterQuery: { status: "Active" },
      searchQuery: "",
    };
    const Activetabledata = await MLTAxiosInstance.post(
      `/util/filterData?company=${this.company}`,
      ActivetableData
    );

    this.Activetotal = Activetabledata.data.total;

    const InactivetableData = {
      company: `${this.company}`,
      type: "filter-search",
      module: "hierarchycontacts",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      lastUpdated: new Date().toJSON(),
      sortFieldAndOrder: {},
      limit: 2,
      filterQuery: { status: "Inactive" },
      searchQuery: "",
    };
    const Inactivetabledata = await MLTAxiosInstance.post(
      `/util/filterData?company=${this.company}`,
      InactivetableData
    );

    this.Inactivetotal = Inactivetabledata.data.total;

    console.log(
      this.Inactivetotal,
      this.pendingtotal,
      this.Activetotal,
      this.fulltotal,
      "toatal value"
    );
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");

    this.company = val.data.company;
    this.userId = val.data.userId;
    this.payload.parentCompany = val.data.company;
    console.log(val.data.company);
    this.$emit("contact-data", this.tableData);
    console.log(
      this.getHalleyxTokenSupplierLogin(),
      "console.log(this.getHalleyxTokenSupplierLogin());"
    );
  },
  methods: {
    async bufferToDataURL(buffer) {
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = "";
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },
    receivedAddressData(val) {
      console.log(val);
      let address1 = JSON.parse(JSON.stringify(val));
      this.payload.contactMedium[2] = JSON.parse(JSON.stringify(address1));
    },
    receivedAddressDataedit(val) {
      console.log(val);
      let address1 = JSON.parse(JSON.stringify(val));
      this.editPayload.contactMedium[2] = JSON.parse(JSON.stringify(address1));
    },
    focusOutEmail(val) {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (val.length >= 1) {
        if (!emailRegex.test(val)) {
          this.isEmailEmpty = true;
        } else {
          this.isEmailEmpty = false;
        }
      }
    },
    generateRandomString(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    getHalleyxTokenSupplierLogin() {
      return this.generatedName;
    },
    async editContactRow() {
      if (this.editPayload.firstName === "") {
        this.isFirstNameEmpty = true;
      }
      if (this.editPayload.lastName === "") {
        this.isLastNameEmpty = true;
      }
      if (this.editPayload.contactMedium[0].emailAddress === "") {
        this.isEmailEmpty = true;
      }
      if (
        !this.isFirstNameEmpty &&
        !this.isLastNameEmpty &&
        !this.isEmailEmpty
      ) {
        this.editPayload.name =
          this.editPayload.title +
          "" +
          this.editPayload.firstName +
          " " +
          this.editPayload.lastName;
        let result = await MLTAxiosInstance.patch(
          `/partymanagement/individual?company=${this.company}`,
          this.editPayload
        );
        result;
        this.notification = {
          type: "success",
          message: `All set! Your changes have been saved successfully!`,
        };

        this.tableData[this.editPayload.index] = JSON.parse(
          JSON.stringify(this.editPayload)
        );
        const x = {
          familyName: "",
          givenName: "",
          name: "",
          firstName: "",
          lastName: "",
          tradingName: "",
          companyName: `${this.company}`,
          status: "Pending",
          lastUpdated: new Date().toJSON(),
          preferred: "",
          "@baseType": "supplier",
          aristocraticTitle: "",
          contactMedium: [
            {
              preferred: true,
              mediumType: "email",
              characteristic: {
                emailAddress: "",
              },
            },
            {
              mediumType: "phone",
              characteristic: {
                phoneNumber: {
                  country: {
                    name: "Canada",
                    flags: "https://flagcdn.com/ca.svg",
                    short: "CA",
                    dial_code: "+1",
                  },
                  value: "",
                },
              },
            },
            {
              mediumType: "postalAddress",
              characteristic: {
                city: "",
                country: "",
                postCode: "",
                stateOrProvince: "",
                street1: "",
                street2: "",
                timezone: "",
                description: "",
                "@type": "",
              },
            },
          ],
          externalReference: [
            {
              name: "",
              externalIdentifierType: "linkedIn",
            },
          ],
          role: "supplier",
          type: "individual",
          notes: "",
          password: "",
          email: "",
          parentCompany: this.company,
        };
        this.editPayload = JSON.parse(JSON.stringify(x));
        this.isFirstNameEmpty = false;
        this.isLastNameEmpty = false;
        this.isEmailEmpty = false;
        this.refershtable();
        this.closeEditContactModal();
      }
      this.refershtable();
      this.closeEditContactModal();
    },
    async addContactRow() {
      if (this.payload.firstName === "") {
        this.isFirstNameEmpty = true;
      }
      if (this.payload.lastName === "") {
        this.isLastNameEmpty = true;
      }
      if (this.payload.contactMedium[0].emailAddress === "") {
        this.isEmailEmpty = true;
      }
      if (
        // !this.isTitleEmpty &&
        !this.isFirstNameEmpty &&
        !this.isLastNameEmpty &&
        !this.isEmailEmpty
      ) {
        this.payload.name =
          this.payload.title +
          "" +
          this.payload.firstName +
          " " +
          this.payload.lastName;
        let result = await MLTAxiosInstance.post(
          `/partymanagement/individual/account/signup`,
          this.payload
        );
        console.log(result, "result");
        // console.log(result.data.message, "result.data.tradingName");
        this.emailid = result.data._id;
        console.log(this.emailid, "this.emailid");
        this.alertmsg = "success";
        this.notification = {
          type: "success",
          message: `Nice work! Your new contact "${this.payload.tradingName}" is now in the list!`,
        };

        this.isEmailEmpty = false;
        this.isLastNameEmpty = false;
        this.isEmailEmpty = false;
        this.inviteUser(result.data);
        this.closeContactModal();
        this.refershtable();
        this.showContactModal = false;
      }
      this.closeContactModal();
      this.refershtable(), console.log(this.payload, "payload value");
    },
    closeContactModal() {
      let x = {
        familyName: "",
        givenName: "",
        name: "",
        firstName: "",
        lastName: "",
        tradingName: "",
        companyName: `${this.company}`,
        status: "Pending",
        lastUpdated: new Date().toJSON(),
        preferred: "",
        "@baseType": "supplier",
        aristocraticTitle: "",
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: "Canada",
                  flags: "https://flagcdn.com/ca.svg",
                  short: "CA",
                  dial_code: "+1",
                },
                value: "",
              },
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              timezone: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        role: "supplier",
        type: "individual",
        notes: "",
        password: "",
        email: "",
        parentCompany: this.company,
      };
      x;
      this.payload = JSON.parse(JSON.stringify(x));
      this.isEmailEmpty = false;
      this.isLastNameEmpty = false;
      this.isFirstNameEmpty = false;
      this.showContactModal = false;
    },
    async refershtable() {
      const fulltableData = {
        company: `${this.company}`,
        type: "filter",
        module: "hierarchycontacts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        lastUpdated: new Date().toJSON(),
        sortFieldAndOrder: {},
        limit: 2,
        filterQuery: {},
        searchQuery: "Active",
      };
      const fulltabledata = await MLTAxiosInstance.post(
        `/util/filterData?company=${this.company}`,
        fulltableData
      );

      this.fulltotal = fulltabledata.data.total;

      if (
        this.switchvalue === "all" ||
        this.switchvalue === "" ||
        this.selectedSwitch == "all"
      ) {
        const fulltableData = {
          company: `${this.company}`,
          type: "filter",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          lastUpdated: new Date().toJSON(),
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: {},
          searchQuery: "Active",
        };
        const fulltabledata = await MLTAxiosInstance.post(
          `/util/filterData?company=${this.company}`,
          fulltableData
        );

        this.tableData = fulltabledata.data.data;
      }
      if (this.switchvalue === "pending") {
        const pendingtableData = {
          company: `${this.company}`,
          type: "search",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          lastUpdated: new Date().toJSON(),
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: {},
          searchQuery: "Pending",
        };
        const pendingtabledata = await MLTAxiosInstance.post(
          `/util/filterData?company=${this.company}`,
          pendingtableData
        );
        this.tableData = pendingtabledata.data.data;
        this.pendingtotal = pendingtabledata.data.total;
      }
      if (this.switchvalue === "active") {
        const ActivetableData = {
          company: `${this.company}`,
          type: "filter-search",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          lastUpdated: new Date().toJSON(),
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: { status: "Active" },
          searchQuery: "",
        };
        const Activetabledata = await MLTAxiosInstance.post(
          `/util/filterData?company=${this.company}`,
          ActivetableData
        );
        this.tableData = Activetabledata.data.data;
        this.Activetotal = Activetabledata.data.total;
      }
      if (this.switchvalue === "inactive") {
        const InactivetableData = {
          company: `${this.company}`,
          type: "filter-search",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          lastUpdated: new Date().toJSON(),
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: { status: "Inactive" },
          searchQuery: "",
        };
        const Inactivetabledata = await MLTAxiosInstance.post(
          `/util/filterData?company=${this.company}`,
          InactivetableData
        );
        this.tableData = Inactivetabledata.data.data;
        this.Inactivetotal = Inactivetabledata.data.total;
      }
    },
    async chosenSwitch(val) {
      console.log(val, "switch contact");
      this.switchvalue = val.value;
      if (
        this.switchvalue === "all" ||
        this.switchvalue === "all" ||
        this.switchvalue === "" ||
        this.selectedSwitch == "all"
      ) {
        const fulltableData = {
          company: `${this.company}`,
          type: "filter",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },

          isNeedToBeSort: true,
          lastUpdated: new Date().toJSON(),
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: {},
          searchQuery: "Active",
        };
        const fulltabledata = await MLTAxiosInstance.post(
          `/util/filterData?company=${this.company}`,
          fulltableData
        );

        this.tableData = fulltabledata.data.data;
        this.fulltotal = fulltabledata.data.total;

        console.log(this.tableData, this.fulltotal);
      }
      if (this.switchvalue === "pending") {
        const pendingtableData = {
          company: `${this.company}`,
          type: "search",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          lastUpdated: new Date().toJSON(),
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: {},
          searchQuery: "Pending",
        };
        const pendingtabledata = await MLTAxiosInstance.post(
          `/util/filterData?company=${this.company}`,
          pendingtableData
        );
        this.tableData = pendingtabledata.data.data;
        this.pendingtotal = pendingtabledata.data.total;
        console.log(this.tableData, this.pendingtotal);
      }
      if (this.switchvalue === "active") {
        const ActivetableData = {
          company: `${this.company}`,
          type: "filter-search",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          lastUpdated: new Date().toJSON(),
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: { status: "Active" },
          searchQuery: "",
        };
        const Activetabledata = await MLTAxiosInstance.post(
          `/util/filterData?company=${this.company}`,
          ActivetableData
        );
        this.tableData = Activetabledata.data.data;
        this.Activetotal = Activetabledata.data.total;
        console.log(this.tableData, this.Activetotal);
      }
      if (this.switchvalue === "inactive") {
        const InactivetableData = {
          company: `${this.company}`,
          type: "filter-search",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          lastUpdated: new Date().toJSON(),
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: { status: "Inactive" },
          searchQuery: "",
        };
        const Inactivetabledata = await MLTAxiosInstance.post(
          `/util/filterData?company=${this.company}`,
          InactivetableData
        );
        this.tableData = Inactivetabledata.data.data;
        this.Inactivetotal = Inactivetabledata.data.total;
        console.log(this.tableData, this.Inactivetotal);
      }
    },

    // goToContacts(i) {
    //   this.editPayload = JSON.parse(JSON.stringify(i));
    //   this.editPayload.index = i;
    //   this.showEditContactModal = true;
    // },
    tooltipover(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis(data1, index1);
    },
    tooltipleave(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses(data1, index1);
    },
    ellipsis(data, index) {
      console.log(data, index);
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              let height = tooltip.offsetHeight;
              let top = element1.getBoundingClientRect().top - height;
              let left = element1.getBoundingClientRect().left;
              tooltip.style.display = "block";
              tooltip.style.top = top + "px";
              tooltip.style.left = left + "px";
              return true;
            }
          }
          return false;
        }
      });
    },
    ellipses(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              tooltip.style.display = "none";
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },

    closeAddNewAdrressModal() {
      this.showAddNewAdrressModal = false;
      this.showContactModal = true;
    },
    // async getLocations() {
    //   var input = this.payload.contactMedium[2].location;
    //   if (this.showEditContactModal) {
    //     input = this.editPayload.contactMedium[2].location;
    //   }
    //   let result = [];

    //   try {
    //     // eslint-disable-next-line vue/no-async-in-computed-properties
    //     const response = await UPCAxiosInstance.get(
    //       `/suggestionlist/?input=${input}`
    //     ); // console.log(response);
    //     result = response.data;
    //   } catch (error) {
    //     console.error("Error fetching autocomplete data:", error.message);
    //   }
    //   this.locationData = result;
    //   if (this.locationData.length === 0 && input !== "") {
    //     this.showNewAddressOption = true;
    //   } else {
    //     this.showNewAddressOption = false;
    //   }
    //   return result;
    // },
    // async getEditLocations() {
    //   const input = this.editPayload.contactMedium[2].location;
    //   let result = [];

    //   try {
    //     // eslint-disable-next-line vue/no-async-in-computed-properties
    //     const response = await UPCAxiosInstance.get(
    //       `/suggestionlist/?input=${input}`
    //     ); // console.log(response);
    //     result = response.data;
    //   } catch (error) {
    //     console.error("Error fetching autocomplete data:", error.message);
    //   }
    //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //   this.locationData = result;
    //   if (this.locationData.length === 0 && input !== "") {
    //     this.showNewAddressOption = true;
    //   } else {
    //     this.showNewAddressOption = false;
    //   }
    //   return result;
    // },
    async addNewAddress() {
      this.newAddressPayload.description =
        this.newAddressPayload.streetNumber +
        ", " +
        this.newAddressPayload.streetName +
        ", " +
        this.newAddressPayload.city +
        ", " +
        this.newAddressPayload.state +
        ", " +
        this.newAddressPayload.country +
        ", " +
        this.newAddressPayload.postalCode +
        ".";
      // console.log(this.newAddressPayload);
      let newAddress = await DMNAxiosInstance.post(
        `mapRef/geoAddress`,
        this.newAddressPayload
      );
      if (newAddress.data.ok === 1) {
        // console.log(this.newAddressPayload.description);
      }
      this.payload.contactMedium[2].location =
        this.newAddressPayload.description;
      this.showAddNewAdrressModal = false;
      this.showContactModal = true;
      const x = {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      };
      this.newAddressPayload = JSON.parse(JSON.stringify(x));
    },
    contactSearch(val) {
      if (val !== "") {
        let searchData = this.searchNestedObjects(
          this.tableData,
          val.toLowerCase()
        );
        // console.log(searchData, this.tableData);
        this.copyOfContactTableData = JSON.parse(JSON.stringify(searchData));
      } else {
        this.copyOfContactTableData = JSON.parse(
          JSON.stringify(this.tableData)
        );
      }
    },

    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            // Recursively search nested objects
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },
    deleteContactRow() {
      let row = this.tableData[this.delete_index];
      if (row.status === "Active") {
        row.status = "Inactive";
      } else {
        row.status = "Active";
      }
      this.$emit("edit-contact", row);
      // this.tableData.splice(this.delete_index, 1);
      this.$emit("contact-data", this.tableData);
      this.delete_modal_active = false;
    },
    crudContextMenu($event, i, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        if (this.computedTableAction(i)[1].label === "Mark as Active") {
          this.containerleft =
            document
              .querySelector("#table-context" + index)
              .parentElement.getBoundingClientRect().left - 144;
        } else {
          this.containerleft =
            document
              .querySelector("#table-context" + index)
              .parentElement.getBoundingClientRect().left - 160;
        }
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    async closeCrudContextMenu($event, ele, i) {
      if ($event.toLowerCase() === "edit") {
        // this.$router.push(`/CM/accounts/edit/${ele._id}`);
        console.log(this.tableData[i], "this.tableData[i]");
        this.editPayload = JSON.parse(JSON.stringify(this.tableData[i]));
        this.editPayload.index = i;
        this.showEditContactModal = true;
      } else if ($event.toLowerCase() == "mark as in-active") {
        let i = {
          id: ele._id,
          status: "Inactive",
        };
        const addSupplier = await MLTAxiosInstance.patch(
          `/partymanagement/individual?company=${this.company}`,
          i
        );
        this.refershtable();
        if (addSupplier.data.email) {
          this.alertmsg = "success";
          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };
        } else {
          this.alertmsg = "danger";
          this.notification = {
            type: "danger",
            message: `Data could not be inserted.`,
          };
        }

        console.log(addSupplier);
        this.refershtable();
        addSupplier;
      } else if ($event.toLowerCase() == "mark as active") {
        let i = {
          id: ele._id,
          status: "Active",
        };
        const addSupplier = await MLTAxiosInstance.patch(
          `/partymanagement/individual?company=${this.company}`,
          i
        );
        if (addSupplier.data.email) {
          this.alertmsg = "success";
          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };
        } else {
          this.alertmsg = "danger";
          this.notification = {
            type: "danger",
            message: `Data could not be inserted.`,
          };
        }
        console.log(addSupplier);
        this.refershtable();
        addSupplier;
      } else if ($event.toLowerCase() === "send invite") {
        console.log(i);
        this.inviteUser(ele);
      }
    },
    async inviteUser(i) {
      console.log(i, "mail send request");
      if (i != undefined) {
        this.notification = {
          type: "success",
          message: "User invitation email sent",
        };
        // let listUsers = await MLTAxiosInstance.get(
        //   `partymanagement/individual/listuser/${this.company}?company=${this.company}`
        // );
        // this.list = await listUsers.data;
        // this.count = await listUsers.data.length;
        this.sendEmail(i);
      }
    },
    async sendEmail(i) {
      console.log(i, "mail send sucessfully");
      let mail = {
        to: [i.email],
        subject: `${i.company} user Invitation`,
        template: `
      <!--
 -->

<!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <!--[if gte mso 9]>  <xml>   <o:OfficeDocumentSettings>   <o:AllowPNG/>   <o:PixelsPerInch>96</o:PixelsPerInch>   </o:OfficeDocumentSettings>  </xml>  <![endif]-->
    <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="format-detection" content="date=no" />
    <meta name="format-detection" content="address=no" />
    <meta name="format-detection" content="telephone=no" />
    <meta name="x-apple-disable-message-reformatting" />
    <style type="text/css" media="screen">
    <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900" rel="stylesheet">
    <link href='https://fonts.googleapis.com/css?family=Open Sans' rel='stylesheet'>
    <link href='https://fonts.googleapis.com/css?family=Quicksand&display=swap' rel='stylesheet'>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
</style>
</head>

<body class="body"
    style="padding:0 !important; margin:0 !important; display:block !important; min-width:100% !important; width:100% !important; background:#f5f5f5; -webkit-text-size-adjust:none;">
    <!-- MOBILE PREHEADER PUSH ANY CONTENT AFTER THE PREHEADER FROM SHOWING UP INSERT UNDER BODY TAG -->





    <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#f5f5f5">
        <tr>
            <td align="center" valign="top">
                <table  border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
                    <tr>
                        <td class="td" >
                            <table  border="0" cellspacing="0" cellpadding="0" >
                                <tr>
                                    <td style="width: 612px;
                                    height: 501px;">
                                        <!-- Header -->
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                            bgcolor="#ffffff">
                                            <tr>
                                                <td>
                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                        class="wrapper">
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="10" cellspacing="0"
                                                                    width="100%" class="wrapper" bgcolor="#f5f5f5">
                                                                    <tr>
                                                                        <!-- <td width="104"><a href="https://halleyx.com"><img src="https://halleyx.com/img/logo-dark.9e286c66.png" height="50" style="display:block; border:none;padding-top: 20px;" alt="logo"></a></td> -->




                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="0" cellspacing="0"
                                                                    style="border:none;border-collapse:collapse;"
                                                                    width="100%" class="wrapper" bgcolor="#ffffff">
                                                                    <tr>
                                                                        <td width="104"><a href="https://halleyx.com"><img src="https://halleyx.com/img/halleyx-logo-line-black.05c516d1.svg" height="50" style="display:block; border:none;padding-top: 20px; padding-left: 30px;" alt="logo"></a></td>




                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- END Header -->
                                        <!-- Start Content -->
                                        <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" height="501"
                                            cellpadding="0">
                                            <tr>
                                                <td bgcolor="#FFFFFF" class="p35-15" >
                                                    <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0"
                                                        cellpadding="0">

                                                        <!-- headline -->
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="headline"
                                                                style="text-align:center;">
                                                                <img style="width: 15%;
                                                                object-fit: contain;
                                                                clip-path: circle();
                                                                " src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThtteDflN13M27CDlAPdC7L_fNJ4Eus3fEFBkI8JKxFLmcms3TsIv_EUcvuyjVo46v1k4&usqp=CAU" alt="" srcset="">
                                                            </td>
                                                        </tr>
                                                        <!-- #headline -->

                                                        <!--  hero-title-1 -->
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="hero-title-1"
                                                                style="font-family: 'Quicksand';
                                                                font-style: normal;
                                                                font-weight: 600;
                                                                font-size: 22px;
                                                                padding-top:15px;
                                                                line-height: 28px;
                                                                text-align: center;
                                                                color: #191A15;">
                                                            ${this.name}
                                                            <br>
invited you

                                                        </td>

                                                        </tr>

                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="hero-title-1"
                                                                style="padding-top:25px;padding-left:100px;padding-right:100px;color:black;  font-family: Open Sans; font-size:14px; line-height:25px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                                You have been invited by
                                                                <!-- <a href="mailto:someone@example.com" style="text-decoration: none;color:#54BD95">${this.name}</a> -->
                                                                <a href="mailto:someone@example.com" style="text-decoration: none;color:#54BD95">${this.name}</a>

                                                                to join ${this.company}. To get started, accept the invitation below.
 </td>

                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="hero-title-1" valign="top"
                                                            style="color:black; font-family: Open Sans;padding-top: 15px;  font-size:14px; line-height:25px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                            <div class="btn" style="text-align:center;padding-top: 25px;  ">
                                                                <!--[if mso]>                                                                     <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://www.fitbit.com/campaign/shop/versa?color=black&utm_source=ET&utm_medium=EM&utm_campaign=20191117_PreBFV2_US_FM_M_SU_SW_32_NA&utm_content=HeroCTALink" style="font-family: Arial, Helvetica, sans-serif;height:36px;v-text-anchor:middle;width:150px;" arcsize="152%" stroke="f" fillcolor="#002A3A">                                                                     <w:anchorlock/>                                                                     <center>                                                                     <![endif]-->
                                                                <a href='https://upc-halleyx-ghubgwxrcq-uc.a.run.app/supplierinvite/${this.company}/${i._id}'
                                                                    class="mob-btn" target="_blank"
                                                                    style="background-color:#54BD95;border-radius:6px;color:#ffffff;font-family:Open Sans;font-size:14px; text-align:center;text-decoration:none;width: 218.18px;
                                                                    height: 38px;-webkit-text-size-adjust:none; padding: 10px 12px; line-height: 15px;">Accept invitation
                                                                    </a>
                                                                <!--[if mso]>                                                                     </center>                                                                     </v:roundrect>                                                                     <![endif]-->
                                                            </div> </td>
                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="hero-title-1"
                                                                style="padding-top:40px;padding-left:80px;padding-right:80px;font-family: 'Quicksand';
                                                                font-style: normal;
                                                                font-weight: 400;
                                                                font-size: 14px;
                                                                line-height: 18px;
                                                                text-align: center;
                                                                color: #A6A6A6;">
                                                                Hiccups might happen. Your feedback and requests are very welcomed and  encouraged at

                                                                We can't wait to see what you create with ${this.company}.
                                                                <!-- <a href="mailto:someone@example.com" style="text-decoration: none;color:#54BD95"></a> -->
                                                                <a href="mailto:contact@halleyx.com" style="text-decoration: none;color:#54BD95">${this.email}</a>
                                                                to join ${this.company}. To get started, accept the invitation below.
 </td>

                                                        </tr>


                                                </td>
                                            </tr>
                                            <!-- END HERO -->
                                        </table>
                                        <!-- HERO Images-->

                                    </td>
                                </tr>
                            </table>
                            <!-- END Content -->
                            <!--Features-->
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                    <td>
                                        <!--Start Content-->
                                        <!--Desktop RTBs-->

                                        <!--End Desktop RTBs-->
                                        <!--Mobile RTBs-->
                                        <!--[if !mso]><!-->

                                        <!--End Mobile RTBs-->
                                        <!--<![endif]-->
                                        <!--END Features-->
                                        <!-- Footer -->
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0" style="padding: 0 15px;"
                                            bgcolor="#ffffff">
                                            <tr>

                                                <td bgcolor="#161C2B" class="disclaimer2"
                                                    style="padding: 40px 77px 40px 77px;color:#FFFFFF; font-family: Open Sans; font-size:14px; line-height:20px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                    <span><a href="https://halleyx.com" style="color: #FFFFFF;font-size: 14px;"><u>T&C</u><u style="padding-left:40px;padding-right: 40px;">Privacy</u><u>Cookies</u></a></span><br/>
                                                    <br/>
                                                    © 2023 Halleyx Inc. Copyright and rights reserved                                                    <p
                                                        style="padding-left: 17px !important; font-family:Open Sans; font-size: 14px; color: #FFFFFF;margin-top: 15px;  margin-bottom: 0px; letter-spacing: .01em;line-height: 20px;">
                                                        18 King St E #1400, Toronto, ON M5C 1C4

                                                    </p>
                                                    <span style="float:left;margin-left: -60px;padding-top:20px;"><img width="100" src="https://halleyx.com/img/halleyx-logo-line-white.c09fdc43.svg"/></span>
                                                    <span style="float: right;margin-right:-50px;padding-top:20px;color: #FFFFFF;"><a href="https://www.linkedin.com/company/halleyx/"><i class="fa fa-linkedin fa-lg" style="color: #ffffff;" ></i></a>
                                                        <a href="https://halleyx.com/" style="padding-left:5px;padding-right: 5px;"><i class="fa fa-facebook fa-lg" style="color: #ffffff;"></i>
                                                        </a>
                                                        <a href="https://www.instagram.com/halleyx_inc/"><i class="fa fa-instagram fa-lg" style="color: #ffffff;"></i></a></span>
                                                </td>

                                            </tr>
                                        </table>
                                        <!-- Footer -->
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                            bgcolor="#ffffff">
                                            <tr>
                                                <td>
                                                    <style>
                                                        .nowrap {
                                                            word-break: keep-all;
                                                            -moz-hyphens: none;
                                                            -ms-hyphens: none;
                                                            -webkit-hyphens: none;
                                                            hyphens: none;
                                                        }

                                                        a[x-apple-data-detectors] {
                                                            color: inherit !important;
                                                            text-decoration: none !important;
                                                            font-size: inherit !important;
                                                            font-family: inherit !important;
                                                            font-weight: inherit !important;
                                                            line-height: inherit !important;
                                                        }
                                                    </style>

                                                    <table border="0" cellpadding="0" cellspacing="0"
                                                        style="border:none;border-collapse:collapse; background-color:#ffffff;"
                                                        width="100%" class="wrapper mobileContainer">

                                                        <tr>
                                                            <td class="mobileContainer" align="center"
                                                                style="font-family:Helvetica, Arial, sans-serif; color:#939c9e; font-size:0px; padding-top:24px; font-weight:100; background-color:#ffffff; width:100%; ">
                                                            </td>
                                                        </tr>




                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- END Footer -->
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
    <img src="images?q=tbn:ANd9GcRYx7vG1ermb_muIP457oVO48JixkaC0RvhAA&usqp=CAU"
        width="1" height="1"></custom>
</body>

</html>`,
      };
      // const sendEmail =
      await MLTAxiosInstance.post(`/email`, mail);
    },
    computedTableAction(data) {
      let options = [
        {
          label: "Send invite",
          icon: "icon-send-right-regular",
        },
        {
          label: "Edit",
          icon: "icon-file-text-edit-regular",
        },
        {
          label: "Mark as In-active",
          icon: "icon-trash-regular",
        },
        {
          label: "Mark as Active",
          icon: "icon-trash-regular",
        },
      ];
      return options.filter((option) => {
        if (
          data &&
          data.status &&
          data.status.toLowerCase() == "active" &&
          ["Mark as Active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "inactive" &&
          ["Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "pending" &&
          ["Mark as In-active", "Mark as Active"].includes(option.label)
        ) {
          return false;
        } else {
          return true;
        }
      });
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    arrange(val) {
      this.re_arrange = val;
      this.updateTheads();
    },
    arrangeColumns(val) {
      this.re_arrange = val;
      this.updateTheads();
    },
    updateTheads() {
      this.accounts_table_theads_cc = this.accounts_table_theads_cc.map(
        (thead) => {
          // Check if the current thead has a matching 'prop' in duplicateTheads
          const isMatching = this.accounts_table_theads.some(
            (duplicateThead) => duplicateThead.prop === thead.prop
          );

          // Set the 'checked' property based on whether it's matching
          return { ...thead, checked: isMatching };
        }
      );
    },
    applyColumns(val) {
      this.duplicateTheads = [];
      val.forEach((e) => {
        if (e.checked === true) {
          this.duplicateTheads.push(e);
        }
      });
      this.accounts_table_theads = [...this.duplicateTheads];
    },

    showNewContactModal() {
      const x = {
        familyName: "",
        givenName: "",
        name: "",
        firstName: "",
        lastName: "",
        tradingName: "",
        companyName: `${this.company}`,
        status: "Pending",
        lastUpdated: new Date().toJSON(),
        preferred: "",
        "@baseType": "supplier",
        aristocraticTitle: "",
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: "Canada",
                  flags: "https://flagcdn.com/ca.svg",
                  short: "CA",
                  dial_code: "+1",
                },
                value: "",
              },
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              timezone: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        role: "supplier",
        type: "individual",
        notes: "",
        password: "",
        email: "",
        parentCompany: this.company,
      };
      this.payload = JSON.parse(JSON.stringify(x));
      this.isFirstNameEmpty = false;
      this.isLastNameEmpty = false;
      this.isEmailEmpty = false;

      this.showContactModal = true;
      this.key += 1;
    },

    closeEditContactModal() {
      this.key += 1;
      this.showViewContactModal = false;
      const x = {
        familyName: "",
        givenName: "",
        name: "",
        firstName: "",
        lastName: "",
        tradingName: "",
        companyName: `${this.company}`,
        status: "Pending",
        lastUpdated: new Date().toJSON(),
        preferred: "",
        "@baseType": "supplier",
        aristocraticTitle: "",
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: "Canada",
                  flags: "https://flagcdn.com/ca.svg",
                  short: "CA",
                  dial_code: "+1",
                },
                value: "",
              },
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              timezone: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        role: "supplier",
        type: "individual",
        notes: "",
        password: "",
        email: "",
        parentCompany: this.company,
      };
      this.editPayload = JSON.parse(JSON.stringify(x));
      this.showEditContactModal = false;
    },
  },
};
</script>
