<template>
  <section class = "confirm">
    <div class="logo"><img src="https://halleyx.com/img/halleyx-logo-line-black.05c516d1.svg"/></div>
        <hlx-modal
        :modal-active = "modal_active2"
        :close-icon="false"
        :height="'400px'"
        :width="'550px'"
        :header-content="true"
      >
        <template #header>
          <div>Set password</div>
        </template>
        <template #content>
          <div class="modal-content">
            <div class="wrapper">
            <div class="form-input-wrapper">
        <p class="input-header">Create password</p>
        <div class="form-input">
            <hlx-input
            v-model:value="password"
          :label_animation="true"
          type="password"
          :password_condition="true"
          label_value="Password"
          :clearable="false"
        />

        </div>
      </div>
      <div class="form-input-wrapper">
        <p class="input-header">Confirm password</p>
        <div>
        <div class="form-input">
            <hlx-input
            v-model:value="conf_password"
          :label_animation="true"
          type="confirmpassword"
          :password_condition="true"
          label_value="Confirm password"
          :clearable="false"
        />
        </div>
      </div>
    
    </div>
    <div class="button-block">
        <hlx-button class="change primary" @click="login()">Continue</hlx-button
        >
        </div>
    </div>
</div>
          </template>
          </hlx-modal>
  </section>
</template>

<script>
import axios from 'axios';
export default {
   name: "InviteUser", 
   data() {
    return {
        token: this.$route.params.token,
        modal_active2:true,
        company:'',
        userId:'',
        password:'',
        conf_password:'',
        fet: process.env.VUE_APP_HOST_PROTOCOL + "://" + process.env.VUE_APP_HOST
    }
   },
   async mounted(){
    let token = {
    headers: {
      Authorization: 'Bearer ' + this.token,
      },
    }; 
    
    
    let individualRes = await axios.get(this.fet +
      `/partymanagement/individual/auth`, token
    );
    
    this.company = individualRes.data.company;
    this.userId = individualRes.data.userId;                                                                                                                                                                          
   },
   methods:{
   async login () {
    let update = {
        id: this.userId,
        password:this.password
      };
      let token = {
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      };
    // const updated =
     await axios.patch(
        this.fet + `/partymanagement/individual`,
        update,
        token
      );
      
      this.$router.push('/')
   }
   }
}
</script>

<style>

</style>