<template>
  <div class="app-layout">
    <section class="right-panel">
      <section class="layout-basic custom-variable">
        <div class="layout-breadcrumb">
          <hlx-breadcrumb
            :items="items"
            @path="pathTo"
          />
        </div>
        <div class="layout-header">
          <span class="title">Add custom variable</span>
        </div>
        <div class="layout-body">
          <div class="grid">
            <span class="titles"
              >Create variable
              <span class="info">Define your custom variable.</span></span
            >
            <span class="api-data">
              <hlx-input
                v-model:value="add_payload.name"
                :label-animation="false"
                :display-error="namecustomerror"
                :custom-error="namecustomerror"
                :custom-error-message="'Please fill the field'"
                :label-value="'Name'"
              ></hlx-input>
            </span>
          </div>
          <div class="grid">
            <span class="titles">
              Data type
              <span class="info">Assign data type.</span>
            </span>
            <span class="api-data-content">
              <hlx-select
                v-model:value="add_payload.dataType"
                :options="dropdown_data"
                :placeholder-value="''"
                :label-animation="false"
              ></hlx-select>
            </span>
          </div>
          <!-- <hlx-divider :position="'horizontal'" :space="'20px'"></hlx-divider> -->

          <hlx-divider
            :position="'horizontal'"
            space="20px"
          ></hlx-divider>
          <div class="grid">
            <span class="titles"
              >Variable assignment<span class="info"
                >Select the variable assignment type.</span
              ></span
            >
            <span class="api-data-content">
              <div class="method-url">
                <span class="method">
                  <hlx-switch
                    :switch-items="auth_switch_items"
                    @chosen="authSelected"
                  />
                </span>
              </div>
            </span>
          </div>
          <!-- <div class="grid">
            <span class="titles">
              Data type
              <span class="info"></span>
            </span>
            <span class="api-data-content">
              <hlx-select
                :options="this.dropdown_data"
                :placeholder_value="''"
                :labelanimation="false"
                v-model:value="this.add_payload.dataType"
              ></hlx-select>
            </span>
          </div> -->
          <div
            v-if="expression == true"
            class="grid"
          >
            <span class="titles">Expression <span class="info"></span></span>
            <span class="api-data-content-text">
              <hlx-code-editor
                v-model:value="add_payload.expression"
                :intellisense-data="list"
                :beautify="true"
                :line-number="true"
                :rows="15"
                :display-error="codecustomerror"
                :custom-error="codecustomerror"
                :custom-error-message="'Please fill the field'"
              ></hlx-code-editor>
            </span>
          </div>
          <div
            v-if="expression == false"
            class="grid"
          >
            <span class="titles">Value<span class="info"></span></span>
            <span class="api-data-content">
              <div class="headers">
                <div
                  v-for="(header, index) in array_val"
                  :key="index"
                  class="header-object"
                  style="display: flex; width: 100%; align-items: center"
                >
                  <span
                    class="inputvalue"
                    style="width: 100%"
                    ><hlx-input
                      :pre-val="array_val[index].value"
                      @at-input="headerKey($event, index, 'value')"
                    ></hlx-input
                  ></span>
                  <span v-if="add_payload.dataType == 'Array'"
                    ><i
                      class="icon-plus-circle-filled"
                      @click="addHeaderRow"
                    ></i
                  ></span>
                  <span
                    v-if="add_payload.dataType == 'Array'"
                    class="delete-header"
                    ><i
                      v-if="array_val.length > 1"
                      class="icon-trash-filled"
                      @click="deleteHeaderRow(index)"
                    >
                    </i
                  ></span>
                </div>
              </div>
            </span>
          </div>
        </div>
        <span
          class="layout-footer"
          style="
            border-top: 1px solid #d8d8d8;
            padding-right: 40px;
            margin-right: -16px;
            margin-left: -16px;
          "
        >
          <span
            class="go-back"
            style="
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            <hlx-button
              class="secondary sm"
              @click="$router.back()"
              >Cancel</hlx-button
            >
            <hlx-button
              class="primary sm"
              style="margin-left: 20px"
              @click="saveAPIData"
              >Add</hlx-button
            >
          </span>
        </span>
      </section>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      codecustomerror: false,
      namecustomerror: false,
      type: 'Expression',
      items: [
        { label: 'Schemas', link: `/schemas/list ` },
        { label: 'Custom variable', link: '/schemas/custom-variable/add' },
      ],
      array_val: [{ key: '', value: '' }],
      expression: false,
      apiBody: '',
      response_status: '200 OK',
      response_time: '91ms',
      dropdown_data: [
        { name: 'String', value: 'String', checked: true },
        { name: 'Array', value: 'Array', checked: false },
        { name: 'Boolean', value: 'Boolean', checked: false },
        { name: 'Date', value: 'Date', checked: false },
        { name: 'Object', value: 'Object', checked: false },
        { name: 'Number', value: 'Number', checked: false },
      ],
      add_payload: {
        name: '',
        dataType: '',
        type: '',
        expression: '',
        value: [],
      },
      auth_switch_items: [
        { name: 'Value', checked: true },
        { name: 'Expression' },
      ],
      method_options: [
        {
          name: 'GET',
          checked: false,
          id: 1,
        },
        {
          name: 'POST',
          checked: false,
          id: 2,
        },
      ],
      list: {
        schema: [
          { label: 'quote.agreement', type: 'string' },
          { label: 'quote.attachment', type: 'string' },
          { label: 'quote.quoteDate', type: 'date' },
          { label: 'quote.quoteItem', type: 'Array' },
        ],
        keywords: [
          { label: 'if' },
          { label: 'then' },
          { label: 'else' },
          { label: 'and' },
          { label: 'or' },
          { label: 'function' },
          { label: 'filter' },
          { label: 'reduce' },
          { label: 'map' },
          { label: 'Date' },
          { label: 'Math' },
          { label: 'slice' },
          { label: 'substr' },
          { label: 'concat' },
          { label: 'true' },
          { label: 'false' },
        ],
        methods: {
          array: [
            {
              label: 'filter',
              syntax:
                'array.filter(function(currentValue, index, arr), thisValue)',
            },
            {
              label: 'reduce',
              syntax:
                'array.reduce(function(total, currentValue, currentIndex, arr), initialValue)',
            },
            {
              label: 'map',
              syntax:
                'array.map(function(currentValue, index, arr), thisValue)',
            },
          ],
          date: [
            {
              label: 'getDay',
              syntax: 'getDay()',
            },
            {
              label: 'getDate',
              syntax: 'getDate()',
            },
            {
              label: 'getTime',
              syntax: 'getTime()',
            },
            {
              label: 'getMonth',
              syntax: 'getMonth()',
            },
            {
              label: 'getFullYear',
              syntax: 'getFullYear()',
            },
            {
              label: 'setDate',
              syntax: 'setDate()',
            },
            {
              label: 'setTime',
              syntax: 'setTime()',
            },
            {
              label: 'setMonth',
              syntax: 'setMonth()',
            },
            {
              label: 'setFullYear',
              syntax: 'setFullYear()',
            },
          ],
          integer: [
            {
              label: 'random',
              syntax: 'Math.random()',
            },
            {
              label: 'round',
              syntax: 'Math.round(x)',
            },
          ],
          string: [
            { label: 'charAt', syntax: 'string.charAt(index)' },
            { label: 'slice', syntax: 'string.slice(start, end)' },
          ],
        },
      },
    };
  },
  watch: {
    add_payload: {
      // handler(val) {
        
      // },
    },
  },
  mounted() {
    this.$store.commit('token', this.$route.params.token);
    // this.api_payload.type=this.type
  },
  methods: {
    async saveAPIData() {
      if (this.add_payload.expression != '' && this.add_payload.name != '') {
        this.add_payload.type = this.type;
        // const add = 
        await axios.post(
          `https://dmnbackend-ghubgwxrcq-uc.a.run.app/customVariable/add`,
          this.add_payload
        );
        
        // this.$router.push()
        this.$router.push(`/DMN/schemas`);

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/schemas `;
      } else {
        
        if (this.add_payload.name == '') {
          this.codecustomerror = false;
          this.namecustomerror = true;
        }
        if (this.add_payload.expression === '') {
          this.codecustomerror = true;
          this.namecustomerror = false;
        }
        if (this.add_payload.expression == '' && this.add_payload.name == '') {
          this.codecustomerror = true;
          this.namecustomerror = true;
        }
      }
    },
    navTo(active) {
      this.$store.commit('token', this.$route.params.token);
      
      if (active.name == 'Home') {
        this.navHome();
      } else if (active.name == 'logout') {
        this.logout();
      } else if (active.name == 'settings') {
        this.setting();
      } else if (active.name == 'Schema') {
        this.schema();
      } else if (active.name == 'Business rule') {
        this.ruled();
      } else if (active.name === 'Database') {
        this.execute();
      } else if (active.name == 'Help') {
        this.help();
      } else if (active.name == 'Execute') {
        this.exe();
      } else {
        this.audit();
        // 
      }
    },
    help() {
      window.open('https://documentation.halleyx.com');
    },
    ruled() {
      this.$router.push(`/DMN/business_rules`);
    },
    exe() {
      this.$router.push(`/DMN/rule_validation`);
    },
    execute() {
      this.$router.push(`/DMN/reference_data`);
    },
    schema() {
      this.$router.push(`/DMN/schemas`);
    },

    logout() {
      this.$router.push('/');
    },
    setting() {
      this.$router.push(`/settings`);
    },
    audit() {
      this.$router.push(`/DMN/audit`);
    },
    async navHome() {
      this.$router.push(`/DMN/dashboard`);
    },
    pathTo() {
      
      // if (val.label === 'Schemas') {
        this.$router.push(`/DMN/schemas`);

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/schemas `;

        // this.$router.push({ path: `/DMN/schemas` })
      // }
    },
    // selectValue(val) {
      
    // },

    paramKey(e, index, attr) {
      if (attr === 'key') {
        this.add_payload.params[index].key = e;
      }
      if (attr === 'value') {
        this.add_payload.params[index].value = e;
      }
    },
    addParamRow() {
      const obj = {};
      obj.key = '';
      obj.value = '';
      obj.index = '';
      this.add_payload.params.push(obj);
    },
    deleteParamRow(index) {
      this.add_payload.params.splice(index, 1);
    },
    headerKey(e, index) {
      
      let add = e;
      let i = index;
      if (i != index) {
        this.api_payload.value.push(add);
        
      }

      // if (attr === "key") {
      //   this.add_payload.headers[index].key = e;
      // }
      // if (attr === "value") {
      //   this.add_payload.headers[index].value = e;
      // }
    },
    addHeaderRow() {
      const obj = {};
      obj.value = '';
      obj.index = '';
      this.array_val.push(obj);
    },
    deleteHeaderRow(index) {
      this.array_val.splice(index, 1);
    },
    authSelected(val) {
      
      this.type = val.name;
      if (val.name == 'Value') {
        this.expression = false;
      } else {
        this.expression = true;
      }
    },
    bodyPayload(val) {
      this.apiBody = val;
    },
    sendAPIData() {
      this.add_payload.data = this.apiBody;
      
    },
  },
};
</script>
