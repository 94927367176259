<template>
  {{ currentTask }}
  BPMN UI test <br />
  <br />
  <hlx-select
    :options="processModalOptions"
    prop-value="name"
    :placeholder-value="'Select a process model'"
    :label-animation="true"
    :clearable="true"
    @selected-value="selectedProcess"
  >
  </hlx-select>
  <br />
  <hlx-input
    v-model:value="offer.name"
    label-value="Offering name"
    :label-animation="true"
    :display-error="true"
    :custom-error="showError"
    :custom-error-message="showErrorMessage"
  />
  <br />
  <hlx-button
    class="primary"
    @click="createNewOffer"
    >Create new offer</hlx-button
  >
  <br />
  <br />
  Offer status: {{ visibleButtons[0]?.offerState }}
  <br />
  <br />
  <hlx-input
    v-if="offerDetails?.data?.userInputs?.value?.field1 !== undefined"
    v-model:value="offerDetails.data.userInputs.value.field1"
    :pre-val="offerDetails.data.userInputs.value.field1"
    label-value="Field 1"
    :label-animation="true"
  />
  <br />
  <hlx-input
    v-if="offerDetails?.data?.userInputs?.value?.price !== undefined"
    v-model:value="offerDetails.data.userInputs.value.price"
    :pre-val="offerDetails.data.userInputs.value.price"
    label-value="Price"
    type="number"
    :label-animation="true"
  />
  <br />
  <div
    v-for="button in visibleButtons"
    :key="button"
  >
    <hlx-button
      v-if="button.button !== ''"
      class="primary"
      @click="invokeFunction(button.action, button.button)"
    >
      {{ button.button }}
    </hlx-button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      userId: '65d5bbe609f6e7cde0dcd555',
      showError: false,
      showErrorMessage: '',
      processModalOptions: [
        {
          name: 'Price approval process',
          processId: '65db8c933cdff7ab585ae771',
        },
      ],
      offer: {
        data: {},
        name: '',
        processId: '',
      },
      offerDetails: {
        data: {
          userInputs: {
            id: '',
            value: {
              field1: '',
              price: '',
            },
          },
        },
        processInstanceId: '',
      },
      visibleButtons: [],
      currentTask: {},
    };
  },
  watch: {
    offer: {
      handler() {
        this.showError = false;
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    this.offerDetails.processInstanceId = '65db8e813cdff7ab585ae7fc';
    if (this.offerDetails.processInstanceId !== '') {
      this.userId = this.$route.params.id;
      const response = await axios.get(
        `http://localhost:5070/bpmnEngine/getProcessInstanceById/${this.offerDetails.processInstanceId}`
      );
      console.log(response);
      this.currentTask = response.data.currentTask;
      this.offerDetails.data.userInputs.id = response.data.currentTask.id;
      this.offerDetails.processInstanceId = `${this.offerDetails.processInstanceId}`;
      this.visibleButtons = response.data.currentTask.actionItems.filter(
        (item) => {
          return item.assignedTo === this.userId;
        }
      );
      this.offerDetails.data.userInputs.value =
        response.data.instance?.processVariables.saveOffering.userInputs.value;
    }
  },
  methods: {
    async setAsObsolete() {
      this.offerDetails.data.userInputs.value.task = 'obsolete';
      this.offerDetails.data.task = 'obsolete';
      const offer = JSON.parse(JSON.stringify(this.offerDetails));
      console.log(offer);
      const response = await axios.post(
        'http://localhost:5070/bpmnEngine/executeProcess',
        offer
      );
      console.log(response);
      this.currentTask = response.data.currentTask;
      this.offerDetails.data.userInputs.id = response.data.currentTask.id;
      this.visibleButtons = response.data.currentTask.actionItems.filter(
        (item) => {
          return item.assignedTo === this.userId;
        }
      );
    },
    async setAsObsolete2() {
      this.offerDetails.data.userInputs.value.task = 'obsolete';
      this.offerDetails.data.task = 'obsolete';
      const offer = JSON.parse(JSON.stringify(this.offerDetails));
      console.log(offer);
      const response = await axios.post(
        'http://localhost:5070/bpmnEngine/executeProcess',
        offer
      );
      console.log(response);
      this.currentTask = response.data.currentTask;
      this.offerDetails.data.userInputs.id = response.data.currentTask.id;
      this.visibleButtons = response.data.currentTask.actionItems.filter(
        (item) => {
          return item.assignedTo === this.userId;
        }
      );
    },
    async setAsRetired() {
      this.offerDetails.data.userInputs.value.task = 'retired';
      this.offerDetails.data.task = 'retired';
      const offer = JSON.parse(JSON.stringify(this.offerDetails));
      console.log(offer);
      const response = await axios.post(
        'http://localhost:5070/bpmnEngine/executeProcess',
        offer
      );
      console.log(response);
      this.currentTask = response.data.currentTask;
      this.offerDetails.data.userInputs.id = response.data.currentTask.id;
      this.visibleButtons = response.data.currentTask.actionItems.filter(
        (item) => {
          return item.assignedTo === this.userId;
        }
      );
    },
    async updateAproverStatus(params) {
      console.log(params, this.offerDetails.data.userInputs.id);
      if (params === 'reject') {
        this.offerDetails.data.userInputs.value.approverStatus = 'Rejected';
        this.offerDetails.data.approverStatus = 'Rejected';
      } else {
        this.offerDetails.data.userInputs.value.approverStatus = 'Approved';
        this.offerDetails.data.approverStatus = 'Approved';
      }
      const offer = JSON.parse(JSON.stringify(this.offerDetails));
      console.log(offer);
      const response = await axios.post(
        'http://localhost:5070/bpmnEngine/executeProcess',
        offer
      );
      console.log(response);
      this.currentTask = response.data?.currentTask;
      this.offerDetails.data.userInputs.id = response.data?.currentTask?.id;
      this.visibleButtons = response.data?.currentTask?.actionItems.filter(
        (item) => {
          return item.assignedTo === this.userId;
        }
      );
    },
    selectedProcess(val) {
      this.offer.processId = val.processId;
    },
    async setAsActive() {
      const offer = JSON.parse(JSON.stringify(this.offerDetails));
      console.log('save', offer);

      const response = await axios.post(
        'http://localhost:5070/bpmnEngine/executeProcess',
        offer
      );
      console.log(response);
      this.currentTask = response.data.currentTask;
      this.offerDetails.data.userInputs.id = response.data.currentTask.id;
      this.visibleButtons = response.data.currentTask.actionItems.filter(
        (item) => {
          return item.assignedTo === this.userId;
        }
      );
    },
    async setAsActive2() {
      const offer = JSON.parse(JSON.stringify(this.offerDetails));
      console.log('save', offer);

      const response = await axios.post(
        'http://localhost:5070/bpmnEngine/executeProcess',
        offer
      );
      console.log(response);
      this.currentTask = response.data.currentTask;
      this.offerDetails.data.userInputs.id = response.data.currentTask.id;
      this.visibleButtons = response.data.currentTask.actionItems.filter(
        (item) => {
          return item.assignedTo === this.userId;
        }
      );
    },
    async createNewOffer() {
      const offer = JSON.parse(JSON.stringify(this.offer));

      const response = await axios.post(
        'http://localhost:5070/bpmnEngine/executeProcess',
        offer
      );
      console.log(response);
      this.currentTask = response.data.currentTask;
      console.log(response.data.processInstanceId);
      if (response.data._id === undefined) {
        if (response.data.includes('duplicate')) {
          this.showError = true;
          this.showErrorMessage = 'Name already exists';
        }
      } else {
        this.offerDetails.data.userInputs.id = response.data.currentTask.id;
        this.offerDetails.processInstanceId = response.data.processInstanceId;
        this.visibleButtons = response.data.currentTask.actionItems.filter(
          (item) => {
            return item.assignedTo === this.userId;
          }
        );
        console.log(this.visibleButtons);
      }
    },
    async sendEmail() {
      console.log('save');
      const offer = JSON.parse(JSON.stringify(this.offerDetails));

      const response = await axios.post(
        'http://localhost:5070/bpmnEngine/executeProcess',
        offer
      );
      console.log(response);
      this.currentTask = response.data.currentTask;
      this.offerDetails.data.userInputs.id = response.data.currentTask.id;
      this.visibleButtons = response.data.currentTask.actionItems.filter(
        (item) => {
          return item.assignedTo === this.userId;
        }
      );
    },
    async saveOffer() {
      console.log('save');
      const offer = JSON.parse(JSON.stringify(this.offerDetails));

      const response = await axios.post(
        'http://localhost:5070/bpmnEngine/executeProcess',
        offer
      );
      console.log(response);
      this.currentTask = response.data.currentTask;
      this.offerDetails.data.userInputs.id = response.data.currentTask.id;
      this.visibleButtons = response.data.currentTask.actionItems.filter(
        (item) => {
          return item.assignedTo === this.userId;
        }
      );
    },
    invokeFunction(callToAction, params) {
      // Check if the method exists before calling it
      if (typeof this[callToAction] === 'function') {
        this[callToAction](params.toLowerCase());
      } else {
        console.error(`Method ${callToAction} not found.`);
      }
    },
  },
};
</script>
