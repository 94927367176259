<template>
  <div v-if="loaderApi === false">
    <chatTicketVue
      :view="chatappview"
      :data="chatappData"
      @close-chat="closeChat"
    ></chatTicketVue>
  </div>
</template>

<script>
import {
  // DMNAxiosInstance,
  UPCAxiosInstance,
  //   MLTAxiosInstance,
} from "@/config/axiosConfig";
import chatTicketVue from "@/components/Ticket/chatTicket.vue";

export default {
  components: {
    chatTicketVue,
  },
  data() {
    return {
      userPrompt: "",
      excludeFields: ["Status", "Priority level", "Subject", "Ticket category"],
      displayChat: false,
      chatappData: {},
      messages: [],
      loaderApi: true,
    };
  },
  async mounted() {
    const asignee = await UPCAxiosInstance.get(
      `troubleTicket/${this.$route.query.id}`
    );
     if (asignee) {
      this.loaderApi = false;
    }
    this.chatappData = asignee.data;
    console.log(asignee, "asignee");

    
  },
};
</script>

<style></style>
