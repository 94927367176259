<template>
   <hlx-alert-notification
    :show-alert="notify"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-modal
      :modal-active="showAlert"
      :close-icon="true"
      :outer-layer-close-option="false"
      :height="'210px'"
      :width="'400px'"
      :modal="true"
      @close="
        canLeave = false;
        showAlert = false;
      "
    >
      <template #header>
        <div>Unsaved changes</div>
      </template>
      <template #content>
        <div class="modal-content">
          Do you want to save your changes before navigating away?
        </div>
      </template>
      <template #footer>
        <hlx-button
          class="secondary sm"
          @click="discardAllChanges"
          >Discard changes</hlx-button
        >
        <hlx-button
          class="primary sm"
          style="margin-left: 10px"
          @click="
            canLeave = false;
            showAlert = false;
            saveManualData();
          "
          >Save</hlx-button
        >
      </template>
    </hlx-modal>
  <main class="--layout-2-way">
    <section class="--layout-inner-left-portion --LL30">
      <!-- Breadcrumb -->
      <div class="layout-breadcrumb">
        <hlx-breadcrumb :items="breadcrumb_items" @path="pathTo" />
      </div>
      <div class="rule-validation-info --info-left-panel">
        <!-- Title -->
        <span class="rule-validation-title -info-title"
          >Add test data
        </span>

        <!-- rule-validation name -->
        <div class="rule-validation-name-wrapper">
          <hlx-input
                v-model:value="add_payload[0].name"
                label-value="Name"
                :label-animation="true"
                :required="true"
                :display-error="nameError.error"
                :custom-error="nameError.error"
                :custom-error-message="nameError.message"
                @focus-out="focusOutInput"
              ></hlx-input
              >
        </div>

        <!-- rule-validation description -->
        <div class="rule-validation-description-wrapper">
          <hlx-input
                v-model:value="add_payload[0].description"
                :label-animation="true"
                label-value="Description"
                type="textarea"
                :clearable="true"
              />
        </div>

        <!-- rule-validation selection -->
        <div class="rule-validation-description-wrapper">
          <hlx-select
                :options="schemaOptions"
                :placeholder-value="'Schema'"
                :label-animation="true"
                :clearable="true"
                :required="true"
                :custom-error="errorvalue"
                @custom-change="chooseSchema"
                @custom-error-emit-value="checkval1"
              ></hlx-select>
        </div>
      </div>
      </section>
 <!-- Right panel -->
 <section class="schema-view --layout-inner-right-portion --RL70">
  <div class="panel-layout-body">
    <div class="design-area">
          <!-- {{this.tableData}} -->
          <p class="--right-panel-title --morgin-bottom">Payload
            
            <span class="--subtitle">Select a schema to add payload</span>
          </p>
          <span>
              <span
                class="manual-data-content"
                :style="
                  contentError === true ? 'border: 1px solid #fe4141' : ''
                "
              >
                <hlx-object-viewer
                  :json-data="selectedSchema"
                  :show="true"
                  :type="'add-data-from-schema'"
                  @updated-json="recievedJson($event)"
                />
              </span>
              <span
                v-if="contentError"
                style="color: #fe4141; font-size: 12px"
              >
                {{ contentErrorMessage }}</span
              >
            </span>
          <!-- * upload -->
        </div>
  </div>
  </section>
  </main>
  <div class="--layout-footer">
    <hlx-button
              class="secondary sm"
              @click="cancelApiAdd"
              >Cancel</hlx-button
            >
            <hlx-button
              class="primary sm"
              @click="saveManualData"
              >Add</hlx-button
            >
  </div>

</template>

<script>
// import axios from 'axios';
import { DMNAxiosInstance } from '@/config/axiosConfig';

export default {
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChanges) {
      this.showAlert = true; // Replace with your condition to show the dialog
      if (this.showAlert) {
        if (this.canLeave) {
          next();
        } else {
          next(false);
        }
      } else {
        next();
      }
    } else {
      next();
    }

    // 
    //   'unsaved changes :',
    //   this.unsavedChanges,
    //   'show alert dialog :',
    //   this.showAlert,
    //   'can leave :',
    //   this.canLeave,
    //   'add payload :',
    //   this.add_payload[0].name,
    //   this.add_payload[0].schemaName,
    //   this.add_payload[0].data
    // );
  },
  data() {
    return {
      unsavedChanges: false,
      showAlert: false,
      canLeave: false,
      notification: {},
      notify:false,
      nav_items: [
        {
          name: 'Home',
          label: 'Home',
          icon: 'icon-grid-web-7-regular',
          description_en: 'Home',
          description_fn: '*Home',
        },
        {
          name: 'Business rule',
          label: 'Business rule ',
          icon: 'icon-code-regular',
          description_en: 'Residential business market ',
          description_fr: '*Residential business market ',
        },
        {
          name: 'Schema',
          label: 'Schema',
          icon: 'icon-desktop-code-regular',
          description_en: 'Small business markets',
          description_fr: '*Small business markets',
        },
        {
          name: 'Database',
          label: 'Database',
          icon: 'icon-database-regular',
          description_en: 'Small business markets',
          description_fr: '*Small business markets',
        },

        {
          name: 'Execute',
          label: 'Execute',
          icon: 'icon-file-settings-regular',
          description_en: 'Small business markets',
          description_fr: '*Small business markets',
          active: true,
        },
        {
          name: 'Audit',
          label: 'Audit',
          icon: 'icon-notebook-regular',
          description_en: 'Audit log',
          description_fr: '*Audit log',
        },
        {
          name: 'Help',
          label: 'Help',
          icon: 'icon-question-circle-regular',
          description_en: 'Setting',
          description_fr: '*Setting',
          separator: true,
        },
        {
          name: 'settings',
          label: 'settings',
          icon: 'icon-settings-regular',
          description_en: 'Setting',
          description_fr: '*Setting',
        },
        {
          name: 'logout',
          label: 'Logout',
          icon: 'icon-log-out-regular',
          description_en: 'Logout',
          description_fr: '*Logout',
        },
      ],
      breadcrumb_items: [
        { label: 'Rule validation', link: '/rule_validation' },
        { label: 'Add test data', link: '' },
      ],
      add_payload: [
        {
          name: '',
          schemaName: '',
          description: '',
          inputMode: 'manual',
          data: [],
          createdBy: '639af00f9f515f57074584ef',
        },
      ],
      schemaOptions: [],
      selectedSchema: {},
      errorvalue: '',
      nameError: {
        error: false,
        message: 'Please fill this field',
      },
      contentError: false,
      contentErrorMessage: 'Data cannot be empty',
    };
  },
  watch: {
    add_payload: {
      handler(val) {
        if (
          val[0].name !== '' ||
          val[0].schemaName !== '' ||
          val[0].data.length !== 0
        ) {
          this.unsavedChanges = true;
        } else {
          this.unsavedChanges = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.$store.commit('token', this.$route.params.token);
    // const id = "your-id"; // Replace with your actual id
    // const name = "your-name"; // Replace with your actual name

    DMNAxiosInstance.get(`/rule_engine/schema`)
      .then((response) => {
        // Handle the response here
        this.schemaOptions = response.data.data;
        this.schemaOptions.forEach((e) => {
          e.value = e.name;
        });
        this.schemaOptions.sort((a, b) => a.name.localeCompare(b.name));
        // 
      })
      .catch((error) => {
        // Handle the error here
        console.error(error);
      });
  },
  methods: {
    discardAllChanges() {
  const activeSideBarItem = this.$store.getters.getTempActiveSideBarItem;
  // console.log(activeSideBarItem);
  // if (activeSideBarItem !== '') {
  //   if (activeSideBarItem == 'logout') {
  //     this.logout();
  //   } else if (activeSideBarItem == 'Dashboard') {
  //     this.$router.push(`/DMN/dashboard`);
  //     this.$store.commit('setTempActiveSideBarItem', '');
  //   } else if (activeSideBarItem == 'Schema') {
  //     this.$router.push(`/DMN/schemas`);
  //     this.$store.commit('setTempActiveSideBarItem', '');
  //   } else if (activeSideBarItem == 'Help') {
  //     window.open('https://halleyx-documentation-ghubgwxrcq-uc.a.run.app');
  //   } else if (activeSideBarItem == 'Rule studio') {
  //     this.$router.push({
  //       name: 'business-rules',
  //       query: { Value: 'All' },
  //     });
  //     this.$store.commit('setTempActiveSideBarItem', '');
  //   } else if (activeSideBarItem === 'Database') {
  //     this.$router.push(`/DMN/reference_data`);
  //     this.$store.commit('setTempActiveSideBarItem', '');
  //   } else if (activeSideBarItem == 'Execute') {
  //     this.$router.push(`/DMN/rule_validation`);
  //     this.$store.commit('setTempActiveSideBarItem', '');
  //   } else if (activeSideBarItem == 'setting') {
  //     this.$router.push(`/settings`);
  //     this.$store.commit('setTempActiveSideBarItem', '');
  //   } else if (activeSideBarItem == 'Audit') {
  //     this.$router.push(`/DMN/audit`);
  //     this.$store.commit('setTempActiveSideBarItem', '');
  //   } else {
  //     console.log('else', activeSideBarItem);
  //   }
  // } 
  if (activeSideBarItem !== '') {
    if (activeSideBarItem === 'UPC') {
      this.$router.push(`/UPC/dashboard`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Product') {
      this.$router.push(`/UPC/product-catalog-table/product`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Service') {
      this.$router.push(`/UPC/product-catalog-table/service`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Resource') {
      this.$router.push(`/UPC/product-catalog-table/resource`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'DMN') {
      this.$router.push(`/DMN/dashboard`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Rule studio') {
      this.$router.push(`/DMN/rule_studio`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Rule validation') {
      this.$router.push(`/DMN/rule_validation`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Audit log') {
      this.$router.push(`/DMN/audit`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Schema') {
      this.$router.push(`/schemas`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Data management') {
      this.$router.push(`/reference_data`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Help') {
      window.open(`https://halleyx-documentation-ghubgwxrcq-uc.a.run.app`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Settings') {
      this.$router.push(`/settings`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Log out') {
      this.$router.push(`/`);
      this.$store.commit('setTempActiveSideBarItem', '');
    }
  } else {
    // console.log('empty');
    this.$router.push(`/UPC/product-catalog-table/product`);  //replace this route with your page route
  }
  this.unsavedChanges = false;
  this.canLeave = true;
  this.showAlert = false;
},
    focusOutInput(val) {
      if (val !== '') {
        this.nameError.error = false;
      }
    },
    checkval1(data1) {
      if (data1 === '') {
        this.errorvalue = 'Select a valid schema';
      } else {
        this.errorvalue = '';
      }
    },
    // navTo(active) {
    //   this.$store.commit('token', this.$route.params.token);
    //   // 
    //   if (active.name == 'Home') {
    //     this.navHome();
    //   } else if (active.name == 'logout') {
    //     this.logout();
    //   } else if (active.name == 'settings') {
    //     this.setting();
    //   } else if (active.name == 'Schema') {
    //     this.schema();
    //   } else if (active.name == 'Business rule') {
    //     this.ruled();
    //   } else if (active.name === 'Database') {
    //     this.execute();
    //   } else if (active.name == 'Help') {
    //     this.help();
    //   } else if (active.name == 'Execute') {
    //     this.exe();
    //   } else {
    //     this.audit();
    //     // 
    //   }
    // },
    audit() {
      this.$router.push(`/DMN/audit`);
    },
    help() {
      window.open('https://documentation.halleyx.com');
    },
    exe() {
      this.$router.push(`/DMN/rule_validation`);
    },
    ruled() {
      this.$router.push(`/DMN/business_rules`);
    },
    execute() {
      this.$router.push(`/DMN/reference_data`);
    },
    schema() {},

    // logout() {
    //   this.$router.push('/')

    // },
    setting() {
      this.$router.push(`/settings`);
    },
    async navHome() {
      this.$router.push(`/DMN/dashboard`);
    },
    recievedJson($event) {
      this.add_payload[0].data = $event;
      if (this.add_payload[0].data.length !== 0) {
        this.contentError = false;
      }
    },
    chooseSchema(schema) {
      let selectedSchema = schema.filter((obj) => obj.checked === true);
      selectedSchema = JSON.parse(JSON.stringify(selectedSchema))[0];
      const schemaName = selectedSchema.name;
      this.add_payload[0]['schemaName'] = schemaName;
      const obj = {};
      obj[schemaName] = this.addListItems(selectedSchema.schema);
      this.selectedSchema = obj;
      // 
    },
    addListItems(obj) {
      for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          if (obj[key].type === 'Array') {
            obj[key].list_items = [];
            const newObj = {};
            newObj.type = 'Object';
            for (const items in obj[key]) {
              // 
              if (
                items !== 'list_items' &&
                items !== 'type' &&
                items !== 'href' &&
                items !== 'ref'
              ) {
                newObj[items] = obj[key][items];
                delete obj[key][items];
              }
            }
            obj[key].list_items.push(newObj);
            // 
          }
          this.addListItems(obj[key]);
        }
      }

      return this.generateJSONFromSchema(obj);
    },
    generateJSONFromSchema(schema) {
      const result = {};

      for (const key in schema) {
        if (Object.hasOwnProperty.call(schema, key)) {
          const value = schema[key];
          const valueType = value.type;

          switch (valueType) {
            case 'String':
              result[key] = '(empty)';
              break;
            case 'Date':
              result[key] = '(empty)';
              break;
            case 'Boolean':
              result[key] = 'false';
              break;
            case 'Integer':
              result[key] = 0;
              break;
            case 'Number':
              result[key] = 0;
              break;
            case 'Object':
              result[key] = this.generateJSONFromSchema(value);
              break;
            case 'Array':
              if (value.list_items) {
                const arrayLength = value.list_items.length;
                const arrayResult = [];

                for (let i = 0; i < arrayLength; i++) {
                  arrayResult.push(
                    this.generateJSONFromSchema(value.list_items[i])
                  );
                }

                result[key] = arrayResult;
              } else {
                result[key] = [];
              }
              break;
            default:
              break;
          }
        }
      }

      return result;
    },
    pathTo(val) {
      val;
      // 
      this.$router.back();
    },
    cancelApiAdd() {
      if (!this.unsavedChanges) {
        this.add_payload[0] = {
          name: '',
          schemaName: '',
          inputMode: 'manual',
          description: '',
          data: [],
          createdBy: '639af00f9f515f57074584ef',
        };
        this.$router.back();
      } else {
        this.showAlert = true;
      }
    },
    saveManualData() {
      if (
        this.add_payload[0].name !== '' &&
        this.add_payload[0].schemaName !== '' &&
        this.add_payload[0].data.length !== 0
      ) {
        DMNAxiosInstance.post(
          `/rule_engine/execution_data/add`,
          this.add_payload
        )
          .then((response) => {
            // Handle the response here
            if (response.data.message === undefined) {
              
              this.notification = {
        type: "success",
        message: "Data added sucessfully"
      };
      this.notify = true
      setTimeout(() => {
        this.notify = false;
        
        // this.showAlert = true;
              this.discardAllChanges();
            }, 6000);
            } 
            // else if (response.data.message.includes('duplicate')) {
              
            // } else if (
            //   response.data.message.includes('Path `name` is required')
            // ) {
              
            // }

          })
          .catch((error) => {
            // Handle the error here
            console.error(error);
            
          });
      }

      if (this.add_payload[0].schemaName === '') {
        this.checkval1('');
        this.$store.commit('setTempActiveSideBarItem', '');
      }
      if (this.add_payload[0].name === '') {
        this.nameError.error = true;
        this.$store.commit('setTempActiveSideBarItem', '');
      }
      if (this.add_payload[0].data.length === 0) {
        this.contentError = true;
        this.$store.commit('setTempActiveSideBarItem', '');
      }
    },
  },
};
</script>
