<template>
  <hlx-alert-notification :notify="notification" :auto-close="true" />
  <div>

  </div>




  <section style="display: flex; flex-direction: column; height: 100vh">
    <main
      class="decision-add-container --layout-3-way"
      :style="
        isRightLayoutOpened
          ? 'display: grid;grid-template-columns: 200px calc(100% - 600px) 300px'
          : ''
      "
      style="height: 100vh"
    >




  <section
        class="cm-fields-left-panel responsiveSM"
        style="width: 220px; background-color: #fff; overflow: hidden"
      >
        <!-- Breadcrumb -->
        <div class="layout-breadcrumb">
          <!-- :items="getBreadcrumbs" -->
          <hlx-breadcrumb
            @path="goTo()"
            />
            <!-- @click -->
        </div>
        <div style="margin-top: 25px">
          <leftpannelComponent
            :side-bar-data="sideBarData"
            @value-name="valueleft"
          ></leftpannelComponent>
        </div>
      </section>




      <section
        class="--layout-inner-right-portion --RL70 responsiveSM-right-portion"
        style="overflow: hidden"
        :style="'width:100%'"
      >
        <div class="responsive-breadcrumbs-sm">
          <hlx-breadcrumb :items="getBreadcrumbs" @path="goTo()" />
        </div>
        <div>
          <div v-if="leftpannel == 'Overview' || leftpannel == ''">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
              "
            >
              <div style="display: flex; align-items: center; gap: 16px">
                <span class="leaftpanelForresponsive-sm">
                  <leftpannelComponent
                    :side-bar-data="sideBarData"
                    @value-name="valueleft"
                  ></leftpannelComponent>
                </span>
                <!-- <span class="responsive-span-sm"
                  ><i
                    class="icon-menu-regular responsive-menuicon-sm"
                    @click="show_left = true"
                  ></i
                ></span> -->
                <span style="">
                  <hlx-file-upload
                    :file-type="'.jpg,.jpeg,.png'"
                    :file-size-limit="'20mb'"
                    :uploadtype="'Rectangle'"
                    :style-background="backgrounStyleupload"
                    :height="50"
                    :width="50"
                    :read-only="defalt"
                    :custom-icon="icon"
                    :type="'profile-upload-preview'"
                    :name-display="payload?.characteristic?.regionName"
                    :content="'Import'"
                    :profile-pic-preview="profilePicUrl"
                  />
                </span>

                <div style="display: flex; flex-direction: column; gap: 8px">
                  <div style="display: flex; align-items: center; gap: 8px">
                    <span style="font-weight: bold">
                      {{ payload?.characteristic?.regionName }}

                    </span>
                    <hlx-label
                      v-if="
                        payload?.characteristic?.servicestatus && payload?.characteristic?.servicestatus === 'Active'
                      "
                      class="primary sm"
                      >{{
  payload?.characteristic?.servicestatus
  
}}</hlx-label
                    >
                    <hlx-label
                      v-if="
                        payload?.characteristic?.servicestatus &&
                        payload?.characteristic?.servicestatus === 'Inactive'
                      "
                      class="error sm"
                      >{{
  payload?.characteristic?.servicestatus
}}</hlx-label
                    >
                    <hlx-label
                      v-if="
                        payload?.characteristic?.servicestatus &&
                        payload?.characteristic?.servicestatus === 'Pending'
                      "
                      class="warning sm"
                      >{{
  payload?.characteristic?.servicestatus
}}</hlx-label
                    >
                  </div>
                  <div class="address-content-sm">
                   <span v-if="payload?.characteristic?.type === 'Postal code'" >{{ payload?.characteristic?.postalCode }}</span>
                   <span v-else >
                    {{ payload?.characteristic?.description }}
                   </span>
                  </div>
                </div>
              </div>

              <div
                v-if="view && selectedValue.toLowerCase() === 'overview'"
                class="go-back"
                style="
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                "
              >
                <hlx-button
                  class="primary sm"
                  style="margin-left: 20px"
                  @click="
                    view = false"
                  >Edit</hlx-button
                >
              </div>
              <div
                v-if="!view && selectedValue.toLowerCase() === 'overview'"
                class="go-back"
                style="
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                "
              >
                <hlx-button class="secondary sm"
                @click="goTo()"  
                >Cancel</hlx-button
                >
                <!-- {{ editsupplierbutton }} -->
                <hlx-button
                  class="primary sm"
                  style="margin-left: 20px"
              @click="patchRegion()"
                  >Save</hlx-button
                >
            
              </div>
            </div>
          </div>

          <div
            v-if="leftpannel != 'Overview' || leftpannel != ''"
            :style="{
              display:
                leftpannel !== '' && leftpannel !== 'Overview'
                  ? 'block'
                  : 'none',
            }"
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
              "
            >
            
              <div style="display: flex; align-items: center; gap: 16px">
                <span class="leaftpanelForresponsive-sm">
                  <leftpannelComponent
                    :side-bar-data="sideBarData"
                    @value-name="valueleft"
                  ></leftpannelComponent>
                </span>
                <!-- <span class="responsive-span-sm" @click="show_left = true"
                  ><i class="icon-menu-regular responsive-menuicon-sm"></i
                ></span> -->
                     <span style="">
                <hlx-file-upload
                  :key="profileComponentKey"
                  :file-type="'.jpg,.jpeg,.png'"
                  :file-size-limit="'20mb'"
                  :uploadtype="'Rectangle'"
                  :style-background="backgrounStyleupload"
                  :height="50"
                  :width="50"
                  :read-only="defalt"
                  :custom-icon="icon"
                  :type="'profile-upload-preview'"
                  :name-display="payload?.characteristic?.regionName"
                  :content="'Import'"
                  :profile-pic-preview="profilePicUrl"
                />
              </span>

                <div style="display: flex; flex-direction: column; gap: 8px">
                  <div style="display: flex; align-items: center; gap: 8px">
                    <span style="font-weight: bold">{{
                      payload?.characteristic?.regionName
                    }}</span>
                    <hlx-label
                      v-if="
                        payload?.characteristic?.servicestatus && payload?.characteristic?.servicestatus === 'Active'
                      "
                      class="primary sm"
                      >{{ payload?.characteristic?.servicestatus }}</hlx-label
                    >
                    <hlx-label
                      v-if="
                        payload?.characteristic?.servicestatus &&
                        payload?.characteristic?.servicestatus === 'Inactive'
                      "
                      class="error sm"
                      >{{ paylpayloadpayload?.characteristic?.servicestatus }}</hlx-label
                    >
                    <hlx-label
                      v-if="
                        payload?.characteristic?.servicestatus &&
                        payload?.characteristic?.servicestatus === 'Pending'
                      "
                      class="warning sm"
                      >{{
  payload?.characteristic?.servicestatus
}}</hlx-label
                    >
                  </div>
                  <div class="address-content-sm">
                    <span v-if="payload?.characteristic?.type === 'Postal code'" >{{ payload?.characteristic?.postalCode }}</span>
                   <span v-else >
                    {{ payload?.characteristic?.description }}
                   </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="decision-info-right-pannel" style="overflow: hidden">
          <div v-if="leftpannel == 'Overview' || leftpannel == ''">
            <div style="display: flex; gap: 5%; padding-top: 22px">
              <!-- <h1>asif</h1> -->
              <div
            style="
              display: flex;
              flex-direction: column;
              gap: 20px;
              width: 50%;
            "
          >
            <div style="display: flex; justify-content: space-between; gap: 10px">
              <span style="width: 200%">
                <div
                  class="decision-name-wrapper"
                  :style="{ 'margin-bottom': customnameerror ? '-7.6%' : 0 }"
                >

              

                  <hlx-input
                    v-model:value="payload.characteristic.regionName"
                    :required="true"
                    :pre-val="payload?.characteristic?.regionName"
                    label-value="Region name"
                    :label-animation="true"
                    type="text"
                    :clearable="false"
                    :display-error="true"
                    :disabled="view"
                    :custom-error="isExistingName"
                    :custom-error-message="isExistingNameErrorMessage"
                    @focus-out="validateNameNew"
                    @at-input="validateName"
                  ></hlx-input>
                </div>
                <div style="width: 100%">
                  <hlx-status
                    :type="'select-status'"
                    :required="true"
                    :readonly="view"
                    :status-option="filteredstatus"
                    :prevalue="payload?.characteristic?.servicestatus"
                    @emit-select="emitStatus1"
                  ></hlx-status>
                </div>
              </span>
            </div>
  
            <div class="decision-name-wrapper">
              <hlx-select
                v-model:value="payload.characteristic.type"
                :required="true"
                :pre-value="payload?.characteristic?.type"
                :options="dropdown_data_type"
                :placeholder-value="'Type'"
                :label-animation="true"
                :label="'value'"
                :prop-value="'value'"
                :disabled="view"
                :clearable="true"
              @selected-value="getSelectedSku"
              />
            </div>
            <!-- nul -->
  

            
            <!-- :new-coordinate="newCoordinate" -->
            <addressComponent
            :key="addressKey"
            :type="payload.characteristic.type"
            :view="view"
                :pre-val="addressPreVal"
              @address-data="receivedAddressData"
              ></addressComponent>
              <!-- @geopoint="geopoint" -->
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              width: 50%;
            "
          >
            <div class="decision-name-wrapper">
              <hlx-input
                v-model:value="payload.characteristic.description1"
                :pre-val="payload.characteristic.description1"
                type="textarea"
                :label-value="'Description'"
                    :disabled="view"
                :required="false"
                :label-animation="true"
                :display-error="true"
                :rows="7"
                :custom-error-message="customErrorMessage"
                @focus-out="focusOutName"
                @at-input="validateName"
              ></hlx-input>
            </div>
  
            <div class="decision-name-wrapper">
              <hlx-multi-select
                :key="modalKey"
                :pre-value="payload.characteristic.tags"
                :options="tagsList"
                prop-value="name"
                :multi-select="true"
                :placeholder="'Tags'"
                :label-animation="true"
                :disabled="view"
                :clearable="true"
                :tooltipposition="'left'"
                @add-data="addTagList"
                @custom-change="receivedTags"
              ></hlx-multi-select>
            </div>
          </div>
            </div>
          </div>
        </div>
        <div v-if="leftpannel == 'Exclude region'">
          <excludedRegion
          :parentinfo="propParentInfo"
          ></excludedRegion>
        </div>
       
        <div v-if="leftpannel == 'Activity log'">
          <ActivityLogComponent
            :activity-data="logData"
            :totalfromprop="activitytotal"
            @activityrowpage="activityrowpage1"
            @activitycurrentpage="activitycurrentpage1"
          ></ActivityLogComponent>
        </div>
       
      </section>


    </main>
    </section>


    
  <discardComponent
    :show="enableDiscardModal"
    @stay="handleStay"
    @close="handleDiscardClose"
    @discard="handleDiscard"
  >
  </discardComponent>






</template>

<script>

import excludedRegion from "@/components/Product/ServiceQualification/excludedRegion.vue"

import leftpannelComponent from "@/components/leftpannelComponent.vue";

import addressComponent from "@/components/Product/ServiceQualification/dynamicAddress.vue"

import discardComponent from "@/components/discardComponent.vue";

import {
  DMNAxiosInstance,
  // MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";

export default {

    components: {
    leftpannelComponent,
    addressComponent,
    excludedRegion,
    discardComponent
        
    },
    data() {
      return {

        addressKey: 1,

        
      enableDiscardModal: false,

        addressPreVal:{
          mediumType: 'postalAddress',
          characteristic: {
          city: '',
          unit: '',
          country: '',
          state: '',
          postalCode: '',
          stateOrProvince: '',
          street1: '',
          street2: '',
          description: '',
          street: '',
              default: true,
              postalCodePrefix:'',
            '@type': '',
            lat: null,
            lng: null,          
          }
        },

        
        filteredstatus: [],

        view: true,
        
        selectedValue: "Overview",

        propParentInfo: null,

        payload:
        {
    "mediumType": "",
    "characteristic": {
        "city": "",
        "unit": "",
        "country": "",
        "state": "",
        "postalCode": "",
        "stateOrProvince": "",
        "street1": "",
        "street2": "",
        "description": "",
        "street": "",
        "default": null,
        "@type": "",
        "postalCodePrefix": "",
        "lat": null,
        "lng": null,
        "regionName": "",
        "servicestatus": "",
        "type": "",
        "tags": [],
        "description1": "",
        "category": "",
        "updatedat": "",
        "createdat": ""
    }
}

            ,

            
            sideBarData:{
    "main": [
        {
            "id": 1,
            "name": "Overview",
            "icon": "icon-presentation-regular",
            "active": true,
            "view": true,
            "edit": true,
            "delete": true,
            "add": true,
            "all": true
        },
        {
            "id": 2,
            "name": "Exclude region",
            "icon": "icon-location-pin-times-regular",
            "active": false,
            "view": true,
            "edit": true,
            "delete": true,
            "add": true,
            "all": true
                    },
//                     {
//     "id": 8,
//     "name": "Activity log",
//     "icon": "icon-time-forward-regular",
//     "active": false,
//     "view": true,
//     "edit": true,
//     "delete": true,
//     "add": true,
//     "all": true
// }
    ]
            },

            leftpannel:"Overview",

            dropdown_data_type: [
        // { value: "Full address" },
        // { value: "Street" },
        { value: "City" },
        { value: "Province" },
        { value: "Postal code" },
        // { value: "Prefix (FSA)" }
        ],





        newCoordinate: {},

        
        tagsList: [],

        notification: "",
        
        initialData: {
        characteristic: {
          regionName: null,
          servicestatus: null,
          description1: null,
          tags: null,
          city: null,
          unit: null,
          state: null,
          postalCode: null,
          stateOrProvince: null,
          street1: null,
          street2: null,
          description: null,
          street: null,
          default: null,
          postalCodePrefix: null,
          '@type': null,
          lat: null,
          lng: null,
        },
      },

        


        }
    },
    computed: {
        getBreadcrumbs() {
      return [
        {
          label: "serviceQualification2",
          link: `/servicequalification`,
        }
      ];
    },
  },

  async mounted() { 
    let response = await UPCAxiosInstance.get(
  `/geographicAddress/${this.$route.query.id}`
    );

    this.payload = response.data?.streetAddressLine[0];

this.initialData.characteristic.regionName = response.data?.streetAddressLine[0]?.characteristic?.regionName;
this.initialData.characteristic.servicestatus = response.data?.streetAddressLine[0]?.characteristic?.servicestatus;
this.initialData.characteristic.description1 = response.data?.streetAddressLine[0]?.characteristic?.description1;
this.initialData.characteristic.tags = response.data?.streetAddressLine[0]?.characteristic?.tags;
this.initialData.characteristic.type = response.data?.streetAddressLine[0]?.characteristic?.type;

let remss = {
    city: response.data?.streetAddressLine[0]?.characteristic?.city,
    unit: response.data?.streetAddressLine[0]?.characteristic?.unit,
    state: response.data?.streetAddressLine[0]?.characteristic?.state,
    postalCode: response.data?.streetAddressLine[0]?.characteristic?.postalCode,
    stateOrProvince: response.data?.streetAddressLine[0]?.characteristic?.stateOrProvince,
    street1: response.data?.streetAddressLine[0]?.characteristic?.street1,
    street2: response.data?.streetAddressLine[0]?.characteristic?.street2,
    description: response.data?.streetAddressLine[0]?.characteristic?.description,
    street: response.data?.streetAddressLine[0]?.characteristic?.street,
    default: response.data?.streetAddressLine[0]?.characteristic?.default,
    postalCodePrefix: response.data?.streetAddressLine[0]?.characteristic?.postalCodePrefix,
    '@type': response.data?.streetAddressLine[0]?.characteristic?.['@type'],
    lat: response.data?.streetAddressLine[0]?.characteristic?.lat,
    lng: response.data?.streetAddressLine[0]?.characteristic?.lng,
    };

    this.initialData.characteristic = {  ...this.initialData.characteristic, ...remss   }




    // // supply country detail as prop for exclusion
    // let propParentInfo = {
    //   type: "" || this.payload.characteristic.type.toLowerCase(),
    //   address: "" || await this.getAddressValue(this.payload.characteristic.type, this.payload ),
    // }

    
     // supply country detail as prop for exclusion
     let propParentInfo = {
      type:  this.payload.characteristic.type.toLowerCase() || '',
      address:  await this.getAddressValue(this.payload.characteristic.type, this.payload ) || '',
            }




    this.propParentInfo = propParentInfo
    console.log("this.parentInfo:::", this.propParentInfo)

    // let eswf = await this.getProvinceShortForm("quebec");
    // console.log("getProvinceShortForm::",eswf);
    


    // if (response.data.streetAddressLine[0]) {
    //   this.payload.characteristic.description =
    //     this.payload.characteristic.description;  
    // }
    
    let response1 = response.data.streetAddressLine[0];
    
    console.log("response1::",response1);



    const keysToExtract = [
    "city",
    "unit",
    "country",
    "state",
    "postalCode",
    "stateOrProvince",
    "street1",
    "street2",
    "description",
    "street",
    "default",
    "@type",
      "postalCodePrefix",
      'lat',
      'lng',    
];

const filteredData = Object.fromEntries(
    keysToExtract.map(key => [key, response1?.characteristic[key]])
    );

    let objAddress = {
      mediumType: 'postalAddress',
      characteristic: filteredData
    }
    


    this.addressPreVal = objAddress;
    console.log("addressPreVal::",this.addressPreVal);

    


    // let lat = response1.characteristic.lat;
    // let lng = response1.characteristic.lng;

    // this.newCoordinate = {
    //   lat: lat,
    //   lng:lng
    // }
    


    let status = await this.getReferanceData('statuslist');
      console.log("status::", status);
      this.filteredstatus = status.filter((item) =>
        ["Active", "Inactive", "Pending"].includes(item.name)
    );


    let tags = response.data.streetAddressLine[0].characteristic?.tags;
    console.log("tags:::1", tags);
    this.payload.characteristic.tags = tags;
    
    // if (tags) {
    //   this.payload.characteristic.tags = tags.map(item => ({ name: item }));
    // }



    let tagsListData =  await this.getReferanceData('tagsList')
    this.tagsList = tagsListData?.filter((item) => {
      return item.module === "sq region";
    });






  //   this.$nextTick(() => {
  // this.loadGoogleMapsScript().then(() => {
  //      // Initialize Google Maps components here
  //      const autocompleteService = new window.google.maps.places.AutocompleteService();
  //   console.log('AutocompleteService Loaded from autoaddress:', autocompleteService);

  //   this.enableWatch = true;

  //    });
  //   });



    
  },


  methods: {
    arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false; // If arrays have different lengths, they are not equal
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false; // If any element doesn't match, the arrays are not equal
  }
  return true; // Arrays are equal if all elements match
    }
    ,


    isData1PresentInData2(data1, data2) {
  for (const key in data1) {
    // Check if the key exists in data2 and if the values match
    if (Object.prototype.hasOwnProperty.call(data1, key)) {
      if (Array.isArray(data1[key])) {
        // Handle array comparison
        if (!Array.isArray(data2[key]) || !this.arraysEqual(data1[key], data2[key])) {
          return true; // Return true if arrays are not equal
        }
      } else {
        // Handle regular key comparison
        if (data2[key] !== data1[key]) {
          return true; // Return true if data1's value doesn't match or is missing in data2
        }
      }
    }
  }
  return false; // All data from data1 is present in data2 and matches
},




    async handleDiscard() {
      this.$router.back();
      // if (this.tempBack) {
      //   this.$router.back();
      // } else {
      //   this.$router.push(this.$store.state.tempActiveSideBarItem.link);
      // }
      this.enableDiscardModal = !this.enableDiscardModal;
      // this.discardIt = true;
    },

    handleDiscardClose() {
      this.enableDiscardModal = false;
    },

    handleStay() {
      // //handle Stay funtionality to hide discard component
      this.enableDiscardModal = !this.enableDiscardModal;
      console.log(
        "🚀 ~ handleStay ~ this.enableDiscardModal:",
        this.enableDiscardModal
      );
    },



    async getAddressValue(type, data2) {
    const mapping = {
        "Full address": "description",
        "Street": "street",
        "City": "city",
        "Province": "state",
        "Postal code": "postalCode",
        "Prefix (FSA)": "postalCodePrefix"
    };

    // Find the corresponding key in data2.characteristic
    const key = mapping[type];
    const value = key ? data2.characteristic[key] : null;

    // If the type is "Province", add the short form logic
    if (type === "Province" && value) {
        const shortForm = await this.getProvinceShortForm(value);
        return { long: value.toLowerCase(), short: shortForm.toLowerCase() };
    }

    // For other types, just return the value
    return value.toLowerCase();
},



    

    removeFirstComma(input) {
      return input.replace(",", "");
    },


    async getProvinceShortForm(provinceName) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(provinceName)}&key=${process.env.VUE_APP_GOOGLE_MAP_KEY}`;

    try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.status === "OK") {
            // Find the administrative area level 1 (province/state)
            const provinceComponent = data.results[0].address_components.find(component =>
                component.types.includes("administrative_area_level_1")
            );

            if (provinceComponent) {
                const shortForm = provinceComponent.short_name; // e.g., "QC" for Quebec
                return shortForm;
            }
        }

        console.error("Province not found or invalid response");
        return null;
    } catch (error) {
        console.error("Error fetching from Google Maps API:", error);
        return null;
    }
},


    async patchRegion() { 

      setTimeout(async() => {
        


        let nem = {
        streetAddressLine:[this.payload]
      }
      
      let response = await UPCAxiosInstance.patch(
            `/geographicAddress`,
            {
              id: this.$route.query.id,
              ...nem,
            }
          );

          console.log("response:patch:::",response);


          if (response) {
            this.notification = {
              type: "success",
              message: `Region details updated successfully!`,
            };

            this.view = true;
          }
      



      }, 1100);

          
    },





    async addTagList(e) {
      console.log("addTagList::", e);

      let obj = {};
      obj.name = e.name;
      obj.module = "sq region";
      await DMNAxiosInstance.post(`mapRef/tagsList`, obj);
      let tagsList = await DMNAxiosInstance.get(`mapRef/collection/tagsList`);
      let arr = this.payload.characteristic.tags;
      arr.push(e.name);
      this.payload.characteristic.tags = arr;
      this.tagsList = tagsList.data.data.filter((item) => {
        return item.module === "sq region";
      });
      // console.log(e);
      // this.tagsList.push(e);
    },




    receivedTags(e) {
      console.log("receivedTags::", e);
      let arr = [];
      e.filter((item) => {
        if (item.checked == true) {
          arr.push(item.name);
        }
      });
      this.payload.characteristic.tags = arr;
      console.log("this.tags::", this.payload.characteristic.tags);

    },



    receivedAddressData(val) {
      console.log("receivedAddressData::", val, this.payload);
      // this.payload = val;

      let obj = {
        city: val.characteristic.city,
          unit: val.characteristic.unit,
            country: val.characteristic.country,
              state: val.characteristic.state,
                postalCode: val.characteristic.postalCode,
                  stateOrProvince: val.characteristic.stateOrProvince,
                    street1: val.characteristic.street1,
                      street2: val.characteristic.street2,
                        description: val.characteristic.description,
                          street: val.characteristic.street,
              default: val.characteristic.default,
    postalCodePrefix: val.characteristic.postalCodePrefix,
    '@type': val.characteristic['@type'],
  }

      obj;

      // this.payload.characteristic = { ...obj };

      console.log("this.payload.characteristic:::", this.payload, obj);
      
      this.payload.characteristic = { ...this.payload.characteristic, ...obj };

      console.log("recccc:::",this.payload);
      
      





    },

    
  getSelectedSku(val){
        val
      console.log("getSelectedSku::::", val)


      this.payload.characteristic.type = val.value;

      let addressPreee = {
        mediumType: 'postalAddress',
        characteristic: {
          city: '',
          unit: '',
          country: '',
          state: '',
          postalCode: '',
          stateOrProvince: '',
          street1: '',
          street2: '',
          description: '',
          street: '',
          default: true,
          postalCodePrefix: '',
          '@type': '',
          lat: null,
          lng: null,
        }
      }; 
      this.addressPreVal = addressPreee;
      this.addressKey += 1;

   },



    
    emitStatus1(status) {
        this.payload.characteristic.servicestatus = status?.value;
        console.log("status::",
          // this.addressKey,
          status.value);
        
      },



        goTo() {
    //   this.$router.back();
          // this.$router.push("/servicequalification");
          if (this.leftpannel == 'Overview') {
            // this.enableDiscardModal = true
            this.compareInitWithFinal();            
          } else {
            this.$router.back();
          // this.$router.push("/servicequalification");            
          }

    },



    compareInitWithFinal() {
      this.initialData;
      // this.enableDiscardModal
       let boold = this.isData1PresentInData2(this.initialData.characteristic, this.payload.characteristic);
      
      console.log("compareInitWithFinal:", this.initialData.characteristic, this.payload.characteristic, boold);

      if (boold) {
        this.enableDiscardModal = true;
      } else {
        this.$router.back();
      }
      




    },
     
     






        async valueleft(val) {
            this.leftpannel = val;
          console.log("valueleft::", val);   
          if (val.toLowerCase() === "overview") {



            let response = await UPCAxiosInstance.get(
  `/geographicAddress/${this.$route.query.id}`
    );
    this.payload = response.data.streetAddressLine[0];


     // supply country detail as prop for exclusion
     let propParentInfo = {
      type:  this.payload.characteristic.type.toLowerCase() || '',
      address:  await this.getAddressValue(this.payload.characteristic.type, this.payload ) || '',
            }

            
    this.propParentInfo = propParentInfo
    console.log("this.parentInfo:::", this.propParentInfo)




    //         if (response.data.streetAddressLine[0]) {
    //   this.payload.characteristic.description =
    //     this.payload.characteristic.description;  
    // }





         }         
         }
    }

}
</script>

<style>

</style>