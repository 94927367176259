<template>
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">
        <!-- Delete location -->
        <span v-if="staticData">{{ staticData?.deleteLocation?.label[loginUserDetails]?.label }}</span>
      </div>
    </template>
    <template #content>
      <div class="modal-content">
        <p v-if="deleteMessage">
          <b>{{ deleteName + "," }}</b>
          <!-- {{
            " is set to default. Are you sure you want to delete this location?"
          }} -->
          <span v-if="staticData">{{ staticData?.cmLocationModalMessage1?.label[loginUserDetails]?.label }}</span>
        </p>
        <p v-if="!deleteMessage">
          <!-- {{ "Are you sure you want to delete the location "
          }} -->
          <span v-if="staticData">{{ staticData?.cmLocationModalMessage2?.label[loginUserDetails]?.label }}</span>
          <b>{{ deleteName + "?" }}</b>
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >
        <!-- Cancel -->
        <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteAddress"
        >
        <!-- Delete -->
        <span v-if="staticData">{{ staticData?.delete?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
    </template>
  </hlx-modal>
  <div
    class="util-bar"
    style="display: flex; justify-content: space-between; margin: 20px 0 0 0"
  >
    <span class="switch-container">
      <hlx-switch
      v-if="staticData"
        v-model="selectedSwitch"
        :switch-items="switchOptions"
        value-prop="value"
        display-prop="label"
        @change="chosenSwitch"
      />
    </span>
    <span style="display: flex; gap: 16px">
      <hlx-search 
      :label-value=staticData?.search?.label[loginUserDetails]?.label
      @search-key="cardSearch"></hlx-search>
      <hlx-button class="primary sm" @click="openDrawerFunc"
        >
        <!-- Add location -->
        <span v-if="staticData">{{ staticData?.addLocation?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
    </span>
  </div>
  <div class="new-cards-container" style="padding: 20px 0px 60px">
    <hlx-card
      v-for="(card, index) in card_data"
      :key="index"
      :hover="false"
      :seperator="false"
      :card-title="false"
      :card-footer="false"
      :padding="true"
    >
      <template #card-body>
        <div
          class="location-card"
          style="padding: 15px !important; position: relative"
        >
          <span
            v-if="card.characteristic.default === true"
            style="
              position: absolute;
              top: 0;
              right: 0;
              background: #54bd9529;
              padding: 4px;
              font-size: 14px !important;
              display: flex;
              gap: 4px;
              align-items: center;
              justify-content: center;
              border-top-right-radius: 5px;
              border-bottom-left-radius: 5px;
            "
          >
            <i
              class="icon-check-circle-filled"
              style="color: #54bd95; font-size: 14px"
            ></i>
            <span style="font-size: 14px">
              <!-- Default -->
              <span v-if="staticData">{{ staticData?.default?.label[loginUserDetails]?.label }}</span>
            </span>
          </span>
          <span
            class="card-tools"
            style="
              display: none;
              position: absolute;
              bottom: 0px;
              right: 0px;
              padding: 8px;
              font-size: 16px !important;
              gap: 8px;
              align-items: center;
              justify-content: center;
            "
          >
            <div data-tooltip="Edit" data-tooltip-location="bottom">
              <i
                class="icon-edit-regular"
                style="color: #54bd95"
                @click="editCard(card, index)"
              ></i>
            </div>
            <div v-show=" card_data.length>1" data-tooltip="Delete" data-tooltip-location="bottom">
              <i
                class="icon-trash-regular"
                @click="deleteCard(card, index)"
              ></i>
            </div>
            <div data-tooltip="Mark as default" data-tooltip-location="bottom">
              <i
                v-if="!card.characteristic.default"
                class="icon-receipt-regular"
                style="color: #0065b2"
                @click="setDefault(card, index)"
              ></i>
            </div>
          </span>
          <div
            class="title"
            style="width: 100%; margin-bottom: 10px; font-size: 16px !important"
            :style="
              card.characteristic.default ? { 'padding-right': '80px' } : {}
            "
          >
            <b
              style="
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              >{{ card.characteristic.locationName }}</b
            >
          </div>
          <div class="body1">
            {{
              `${
                card.characteristic.address1 !== ""
                  ? card.characteristic.address1
                  : "-"
              }`
            }}
          </div>
          <div class="body1">
            {{
              `${
                card.characteristic.unit !== "" ? card.characteristic.unit : "-"
              }`
            }}
          </div>
          <div class="body1">
            {{
              `${
                card.characteristic.state !== ""
                  ? card.characteristic.state
                  : "-"
              }`
            }}
          </div>
          <div class="body1">
            {{
              `${
                card.characteristic.country !== ""
                  ? card.characteristic.country
                  : "-"
              }`
            }}
          </div>
          <div class="body1">
            {{
              `Postal code: ${
                card.characteristic.postalCode !== ""
                  ? card.characteristic.postalCode
                  : "-"
              }`
            }}
          </div>
          <div
            class="body1"
            style="
              display: flex;
              gap: 8px;
              align-items: center;
              margin-top: 8px;
            "
          >
            <i class="icon-envelope-regular"></i>
            <span
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              >{{
                `${
                  card.characteristic?.email?.emailAddress !== ""
                    ? card.characteristic?.email?.emailAddress
                    : "-"
                }`
              }}</span
            >
          </div>
          <div
            class="body1"
            style="display: flex; gap: 8px; align-items: center"
          >
            <i class="icon-phone-regular"></i>
            <span
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              >{{
                `${
                  card.characteristic?.phone?.phoneNumber?.value !== ""
                    ? card.characteristic?.phone?.phoneNumber?.value
                    : "-"
                }`
              }}</span
            >
          </div>
        </div>
      </template>
    </hlx-card>
  </div>

  <hlx-drawer
    :show="openDrawer"
    position="right"
    width="400"
    :footer="true"
    :title="'Add attachment'"
    :show-close-icon="true"
    @close="openDrawer = false"
  >
    <template #body>
      <div
        class="header-area-contact-drawer"
        style="
          border-bottom: 1px solid #d4d4d4;
          padding-bottom: 5px;
          padding: 4px 0 13px 0;
        "
      >
        <b v-if="editMode">
          <!-- Edit Location -->
          <span v-if="staticData">{{ staticData?.editLocation?.label[loginUserDetails]?.label }}</span>
        </b>
        <b v-if="!editMode">
          <!-- Add Location -->
          <span v-if="staticData">{{ staticData?.addLocation?.label[loginUserDetails]?.label }}</span>
        </b>
      </div>
      <div class="body-area-contact-drawer" style="margin-top: 10px">
        <span style="margin: 0 0 10px 0; display: flex"> 
          <!-- Location type -->
          <span v-if="staticData">{{ staticData?.locationType?.label[loginUserDetails]?.label }}</span>
        </span>
        <span style="display: flex; margin-bottom: 16px">
          <hlx-input
            :key="phoneNumberComponentReload"
            type="radio-buttons"
            position="horizontal"
            :options="radioOptionsComp"
            @selected="radioChange"
          >
          </hlx-input>
        </span>
        <hlx-input
          v-model:value="payload.characteristic.locationName"
          :pre-val="payload.characteristic.locationName"
          :label-animation="true"
          label-value="Location name"
          required="true"
          :display-error="true"
        ></hlx-input>
        <addressComponent
          :required="true"
          :pre-val="addressPreVal"
          @address-data="addressData"
        ></addressComponent>
        <br />
        <div class="decision-name-wrapper">
          <hlx-input
          v-if="staticData"
            v-model:value="payload.characteristic.email.emailAddress"
               :default-mail-error="staticData?.pleaseEnterValidUrl?.label[loginUserDetails]?.label"
            :pre-val="payload.characteristic.email.emailAddress"
            type="email"
            :label-value="'Email id'"
            :required="false"
            :label-animation="true"
            :display-error="true"
            :custom-error-message="staticData?.emailAlreadyUse?.label[loginUserDetails]?.label || 'This email is already in use'"
          ></hlx-input>
        </div>
        <div class="decision-name-wrapper">
          <hlx-input
          v-if="staticData"
          :key="phoneNumberComponentReload"
          v-model:value="payload.characteristic.phone.phoneNumber"
          :default-number-error="staticData?.pleaseEnterValidNumber?.label[loginUserDetails]?.label"
            :pre-val="payload.characteristic.phone.phoneNumber.value"
            type="phonenumber"
            :country-code="payload.characteristic.phone.phoneNumber.country"
            :label-value="'Phone no.'"
            :label-animation="true"
            :display-error="true"
            :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
          ></hlx-input>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        style="
          display: flex;
          justify-content: flex-end;
          padding: 10px;
          border-top: 1px solid #d8d8d8;
        "
      >
        <hlx-button class="secondary sm" @click="closeDrawer"
          >
          <!-- Cancel -->
          <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
          </hlx-button
        >
        <hlx-button
          v-if="!editMode"
          :disabled="
            payload.characteristic.locationName !== '' &&
            payload.characteristic.phone.phoneNumber.valid === true &&
            validateEmail(payload.characteristic.email.emailAddress) &&
            validateAddress(payload)
              ? false
              : true
          "
          class="primary sm"
          style="margin-left: 10px"
          @click="emitNewAddress"
          >
          <!-- Add -->
          <span v-if="staticData">{{ staticData?.add?.label[loginUserDetails]?.label }}</span>
          </hlx-button
        >
        <hlx-button
          v-if="editMode"
          class="primary sm"
          :disabled="
            payload.characteristic.locationName !== '' &&
            payload.characteristic.phone.phoneNumber.valid !== false &&
            validateEmail(payload.characteristic.email.emailAddress) &&
            validateAddress(payload)
              ? false
              : true
          "
          style="margin-left: 10px"
          @click="editAddressEmit"
          >
          <!-- Save -->
          <span v-if="staticData">{{ staticData?.save?.label[loginUserDetails]?.label }}</span>
          </hlx-button
        >
      </div>
    </template>
  </hlx-drawer>
</template>

<script>
import addressComponent from "../CM/autocompleteComponent.vue";
import {
  // MLTAxiosInstance,
  // DMNAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
export default {
  components: {
    addressComponent,
  },
  props: {
    accountStatus: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    module: {
      type: String,
      default: "",
    },
  },
  emits: ["new-address", "update-address", "delete-index", 'initialPayload'],
  data() {
    return {
      loginUserDetails: {},
      staticData:null,
      loclength:0,
      phoneNumberComponentReload: 1,
      canAdd: false,
      deleteMessage: false,
      deleteName: "",
      delete_modal_active: false,
      deleteIndex: 0,
      editIndex: 0,
      editMode: false,
      payload: {
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
          phone: {
            mediumType: "phone",
            phoneNumber: {
              country: {
                name: this.$store.getters.defaultSetting?.country,
                short: this.$store.getters.defaultSetting?.cca2,
                flags: this.$store.getters.defaultSetting?.flags?.svg,
              },
              value: "",
              valid: true,
            },
            preferred: false,
          },
          email: {
            preferred: false,
            mediumType: "email",
            emailAddress: "",
          },
          locationName: "",
        },
      },
      addressPreVal: {
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
        },
      },
      radioOptions: [
        { name: "Site location", checked: false, disabled: false, id: 1 },
        { name: "Billing location", checked: false, disabled: false, id: 2 },
      ],
      openDrawer: false,
      selectedSwitch: "site",
      switchOptions: [
        {
          name: "Site location",
          value: "site",
        },
        {
          name: "Billing location",
          value: "billing",
        },
      ],
      card_data: [],
    };
  },
  computed: {
    radioOptionsComp() {
      let arr = [];
      if (this.selectedSwitch === "site") {
        arr = [
          {
            name: this.staticData?.siteLocation?.label[this.loginUserDetails]?.label || "Site location",
            value: "siteLocation",
            checked: true,
            disabled: false,
            id: 1,
          },
          {
            name: this.staticData?.billingLocation?.label[this.loginUserDetails]?.label ||  "Billing location",
            value: "billingLocation",
            checked: false,
            disabled: false,
            id: 2,
          },
        ];
      } else {
        arr = [
          {
            name: this.staticData?.siteLocation?.label[this.loginUserDetails]?.label || "Site location",
            value: "siteLocation",
            checked: false,
            disabled: false,
            id: 1,
          },
          {
            name: this.staticData?.billingLocation?.label[this.loginUserDetails]?.label || "Billing location",
            value: "billingLocation",
            checked: true,
            disabled: false,
            id: 2,
          },
        ];
      }
      return arr;
    },
  },
  watch: {
    
    staticData: {
      handler(val) {
        val
        if (val) {
          val
          this.switchOptions =
            [
        {
          name: "Site location",
                value: "site",
          label: this.staticData?.siteLocation?.label[this.loginUserDetails]?.label
        },
        {
          name: "Billing location",
          value: "billing",
          label: this.staticData?.billingLocation?.label[this.loginUserDetails]?.label
        },
      ]
         
        }
      },
      immediate: true,
      deep: true,
    },
    data: {
      handler(val) {
        this.card_data = JSON.parse(
          JSON.stringify(
            val.filter((item) => {
              return (
                item.mediumType !== "fax" &&
                item.mediumType !== "phone" &&
                item.mediumType !== "email"
              );
            })
          )
        );
        if (this.selectedSwitch === "site") {
          this.card_data = this.card_data.filter((item) => {
            return item.mediumType === "siteLocation" ? item : false;
          });
        } else {
          this.card_data = this.card_data.filter((item) => {
            return item.mediumType === "billingLocation" ? item : false;
          });
        }
        console.log(this.card_data, "add data");
        const defaultIndex = this.card_data.findIndex(
          (item) => item.characteristic.default === true
        );

        if (defaultIndex !== -1) {
          const [defaultItem] = this.card_data.splice(defaultIndex, 1);
          this.card_data.unshift(defaultItem);
        }

        this.loclength= val.length
      },
      immediate: true,
      deep: true,
    },
    selectedSwitch: {
      handler(val) {
        if (val === "site") {
          this.payload.mediumType = "siteLocation";
          this.addressPreVal.mediumType = "siteLocation";
          this.card_data = JSON.parse(
            JSON.stringify(
              this.data.filter((item) => {
                return (
                  item.mediumType !== "fax" &&
                  item.mediumType !== "phone" &&
                  item.mediumType !== "email" &&
                  item.mediumType === "siteLocation"
                );
              })
            )
          );
        } else {
          this.payload.mediumType = "billingLocation";
          this.addressPreVal.mediumType = "billingLocation";
          this.card_data = JSON.parse(
            JSON.stringify(
              this.data.filter((item) => {
                return (
                  item.mediumType !== "fax" &&
                  item.mediumType !== "phone" &&
                  item.mediumType !== "email" &&
                  item.mediumType === "billingLocation"
                );
              })
            )
          );
        }
      },
      immediate: true,
    },
    payload: {
      handler(val) {
        console.log(val.characteristic.phone, ".characteristic.phone");
        if (
          val.characteristic.phone.phoneNumber.valid &&
          val.characteristic.locationName !== ""
        ) {
          this.canAdd = true;
        } else {
          this.canAdd = false;
        }
      },
    },
  },
  async mounted() {
    await this.$store.dispatch("loginTenantDetails");
    await this.$store.dispatch("loginUserDetails");
    // loginUserDetails
    // await this.$store.dispatch("loginTenantDetails");
    // let r = await this.$store.getters.loginTenantDetails;
    console.log("loginTenantDetails resrfsef:", this.$store.getters.loginTenantDetails);
    console.log("loginUserDetails resrfsef:", this.$store.getters.loginUserDetails);

    this.loginUserDetails = this.$store?.getters?.loginUserDetails?.languageAbility[0]?.code;

    // this.loginUserDetails.languageAbility[0].name
    // this.loginUserDetails.languageAbility[0].code

    try {
      let organisationInfo = await UPCAxiosInstance.get('/multi-language');
      this.staticData = organisationInfo?.data?.filter(itm => itm && itm.modules)?.[0]?.modules;
      console.log("this.staticData:",organisationInfo , this.staticData);
      
    }
    catch (error) {
      console.error("error::", error)
    }


  },
  methods: {
    closeDrawer() {
      this.openDrawer = false;
      const x = {
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
          phone: {
            mediumType: "phone",
            phoneNumber: {
              country: {
                name: this.$store.getters.defaultSetting?.country,
                short: this.$store.getters.defaultSetting?.cca2,
                flags: this.$store.getters.defaultSetting?.flags?.svg,
              },
              value: "",
              valid: true,
            },
            preferred: false,
          },
          email: {
            preferred: false,
            mediumType: "email",
            emailAddress: "",
          },
          locationName: "",
        },
      };
      this.payload = JSON.parse(JSON.stringify(x));
      this.phoneNumberComponentReload += 1;
    },
    validateAddress(val) {
      console.log(val);
      if (val.characteristic.address1 !== "") {
        return true;
      } else {
        return false;
      }
    },
    validateEmail(val) {
      const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (val.length > 0) {
        if (!reg.test(val)) {
          return false; // URL is invalid
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    deleteAddress() {
      this.$emit("delete-index", this.deleteIndex);
      this.delete_modal_active = false;
    },
    deleteCard(card) {
      console.log(card);
      this.deleteIndex = this.data.findIndex(
        (obj) =>
          obj.characteristic.locationName ===
            card.characteristic.locationName &&
          obj.mediumType === card.mediumType
      );
      this.deleteName = card.characteristic.locationName;
      if (card.characteristic.default === true) {
        this.deleteMessage = true;
      } else {
        this.deleteMessage = false;
      }
      this.delete_modal_active = true;
      // this.$emit('delete-index', this.deleteIndex)
    },
    editAddressEmit() {
      this.$emit("update-address", {
        index: this.editIndex,
        data: this.payload,
      });
      this.openDrawer = false;
    },
    editCard(card, index) {
      console.log(card, index);
      this.$emit('initialPayload',card )
      this.payload = JSON.parse(JSON.stringify(card));
      this.addressPreVal = JSON.parse(JSON.stringify(card));
      this.editIndex = this.data.findIndex(
        (obj) =>
          obj.characteristic.locationName ===
            this.payload.characteristic.locationName &&
          obj.mediumType === this.payload.mediumType
      );
      this.openDrawer = true;
      this.editMode = true;
      this.phoneNumberComponentReload += 1;
    },
    setDefault(card) {
      const index = this.data.findIndex(
        (obj) =>
          obj.characteristic.locationName ===
            card.characteristic.locationName &&
          obj.mediumType === card.mediumType
      );
      let swi =
        this.selectedSwitch === "site" ? "siteLocation" : "billingLocation";
      this.data.filter((item) => {
        if (item.mediumType === swi) {
          item.characteristic.default = false;
        }
      });
      const item = this.data[index];
      item.characteristic.default = true;
      this.$emit("update-address", { index: index, data: item });
    },
    filteredData(val) {
      const searchValue = val.toLowerCase();
      const filterNested = (obj) => {
        for (let key in obj) {
          const value = obj[key];
          if (typeof value === "object") {
            if (filterNested(value)) return true;
          } else if (
            typeof value === "string" &&
            value.toLowerCase().includes(searchValue)
          ) {
            return true;
          }
        }
        return false;
      };

      return this.data.filter((obj) => filterNested(obj.characteristic));
    },
    cardSearch(val) {
      console.log(val);
      if (val !== "") {
        // let d = JSON.parse(
        //   JSON.stringify(
        //     this.data.filter((item) => {
        //       return item.mediumType !== 'fax' &&
        //         item.mediumType !== 'phone' &&
        //         item.mediumType !== 'email' &&
        //         (item.mediumType === this.selectedSwitch) === 'site'
        //         ? 'siteLocation'
        //         : 'billingLocation';
        //     })
        //   )
        // );
        // console.log(d);
        // this.card_data = this.data.filter((obj) => {
        //   return Object.values(obj).some((value) => {
        //     if (typeof value === 'string') {
        //       return value.toLowerCase().includes(val);
        //     }
        //     return false;
        //   });
        // });
        this.card_data = this.filteredData(val);
        console.log(this.data, this.card_data);
        this.card_data = this.card_data.filter((item) => {
          return (
            item.mediumType !== "fax" &&
            item.mediumType !== "phone" &&
            item.mediumType !== "email" &&
            item.mediumType !== "postalAddress"
          );
        });
        if (this.selectedSwitch === "site") {
          this.card_data = this.card_data.filter((item) => {
            return item.mediumType === "siteLocation" ? item : false;
          });
        } else {
          this.card_data = this.card_data.filter((item) => {
            return item.mediumType === "billingLocation" ? item : false;
          });
        }
        console.log(this.card_data);
      } else {
        this.card_data = JSON.parse(
          JSON.stringify(
            this.data.filter((item) => {
              return (
                item.mediumType !== "fax" &&
                item.mediumType !== "phone" &&
                item.mediumType !== "email"
              );
            })
          )
        );
        this.card_data = this.card_data.filter((item) => {
          return (
            item.mediumType !== "fax" &&
            item.mediumType !== "phone" &&
            item.mediumType !== "email" &&
            item.mediumType !== "postalAddress"
          );
        });
        if (this.selectedSwitch === "site") {
          this.card_data = this.card_data.filter((item) => {
            return item.mediumType === "siteLocation" ? item : false;
          });
        } else {
          this.card_data = this.card_data.filter((item) => {
            return item.mediumType === "billingLocation" ? item : false;
          });
        }
      }
    },
    emitNewAddress() {
      if (this.selectedSwitch === "site") {
        // this.payload.mediumType = "siteLocation";
        this.card_data = this.card_data.filter((item) => {
          return item.mediumType === "siteLocation" ? item : false;
        });
      } else {
        // this.payload.mediumType = "billingLocation";
        this.card_data = this.card_data.filter((item) => {
          return item.mediumType === "billingLocation" ? item : false;
        });
      }
      if (this.selectedSwitch === "site" && this.payload.mediumType == '') {
        this.payload.mediumType = "siteLocation";
        this.card_data = this.card_data.filter((item) => {
          return item.mediumType === "siteLocation" ? item : false;
        });
      } else if (this.selectedSwitch === "billing" && this.payload.mediumType == ''){
        this.payload.mediumType = "billingLocation";
        this.card_data = this.card_data.filter((item) => {
          return item.mediumType === "billingLocation" ? item : false;
        });
      }
      if (this.card_data.length === 0) {
        this.payload.characteristic.default = true;
      }
      this.payload.preferred = false;
      console.log(this.selectedSwitch);
      console.log(this.payload);
      this.$emit("new-address", this.payload);
      this.openDrawer = false;
    },
    addressData(val) {
      console.log(val, 'addressData');
      let address = {};
      let type = JSON.parse(JSON.stringify(this.payload.mediumType))
      address = JSON.parse(JSON.stringify(val));
      address.characteristic.phone = JSON.parse(
        JSON.stringify(this.payload.characteristic.phone)
      );
      address.characteristic.email = JSON.parse(
        JSON.stringify(this.payload.characteristic.email)
      );
      address.characteristic.locationName = JSON.parse(
        JSON.stringify(this.payload.characteristic.locationName)
      );
      this.payload = JSON.parse(JSON.stringify(address));
      this.payload.mediumType = JSON.parse(JSON.stringify(type));
    },
    radioChange(val) {
      this.payload.mediumType = val.value;
      console.log(val, this.payload);
    },
    openDrawerFunc() {
      const x = {
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
          phone: {
            mediumType: "phone",
            phoneNumber: {
              country: {
                name: this.$store.getters.defaultSetting?.country,
                short: this.$store.getters.defaultSetting?.cca2,
                flags: this.$store.getters.defaultSetting?.flags?.svg,
              },
              value: "",
              valid: true,
            },
            preferred: false,
          },
          email: {
            preferred: false,
            mediumType: "email",
            emailAddress: "",
          },
          locationName: "",
        },
      };
      this.payload = JSON.parse(JSON.stringify(x));
      const y = {
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
        },
      };
      this.addressPreVal = JSON.parse(JSON.stringify(y));
      this.editMode = false;
      this.openDrawer = true;
    },
    chosenSwitch(val) {
      console.log(val);
    },
  },
};
</script>

<style lang="scss">
.location-card {
  border: 1px solid #d8d8d8 !important;
  border-radius: 5px;
  font-size: 14px !important;
}

.location-card:hover {
  border: 1px solid #54bd95 !important;
  border-radius: 5px;
  .card-tools {
    display: flex !important;
  }
}

.new-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-gap: 30px;
  .card {
    border: none !important;
  }
}
</style>
