<!-- eslint-disable vue/no-template-shadow -->
<template>
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'200px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <div class="add-feature-drawer">
    <hlx-drawer
      :show="isModal"
      position="right"
      width="400"
      :footer="true"
      :show-close-icon="true"
      @close="cancelAddfiled"
    >
      <template #body>
        <div>
          <div
            class="header-area-contact-drawer"
            style="
              border-bottom: 1px solid #d4d4d4;
              padding-bottom: 5px;
              padding: 13px;
            "
          >
            <b>Preview ticket</b>
          </div>

          <div
            :key="updateKey"
            class="ticket-drawer-res"
            style="
              display: flex;
              padding: 18px 13px;
              flex-direction: column;
              gap: 18px;
            "
          >
            <span
              v-for="(addFieldsObject, index) in labelFieldsValue"
              :key="index"
            >
              <!-- Dropdown preview -->
              <div
                v-if="addFieldsObject['@type'] == 'Dropdown'"
                class="feature-value-dropdown-preview"
              >
                <span v-if="addFieldsObject.name === 'Requester'">
                  <hlx-select
                    :key="componentkey1R"
                    v-model:value="previewPayload[addFieldsObject.name]"
                    :pre-value="
                      preValFunc(
                        previewPayload[addFieldsObject.name],
                        addFieldsObject
                      )
                    "
                    :options="contactPayload"
                    :placeholder-value="addFieldsObject.name"
                    :label-animation="true"
                    :inline-search="true"
                    :required="addFieldsObject.mandatory"
                    :clearable="true"
                    :label="'name'"
                    :prop-value="'name'"
                    @selected-value="RelatedPArtyData"
                  ></hlx-select>
                </span>
                <span v-else-if="addFieldsObject.name === 'Assigned to'">
                  <hlx-select
                    v-model:value="previewPayload[addFieldsObject.name]"
                    :pre-value="
                      preValFunc(
                        previewPayload[addFieldsObject.name],
                        addFieldsObject
                      )
                    "
                    :options="assignedToo"
                    :placeholder-value="addFieldsObject.name"
                    :label="'name'"
                    :prop-value="'name'"
                    :label-animation="true"
                    :search-image-tag="true"
                    :sub-title="'company'"
                    :tag-value="'newRole'"
                    :required="addFieldsObject.mandatory"
                    :image-link="'link'"
                    :inline-search="true"
                    @selected-value="AssignedData"
                  ></hlx-select>
                </span>
                <span v-else-if="addFieldsObject.name === 'Ticket category'">
                  <hlx-select
                    :key="componentkey1"
                    v-model:value="previewPayload[addFieldsObject.name]"
                    :pre-value="previewPayload[addFieldsObject.name]"
                    :options="addFieldsObject.characteristicSpecification"
                    :placeholder-value="addFieldsObject.name"
                    :label-animation="true"
                    :inline-search="true"
                    :required="addFieldsObject.mandatory"
                    :clearable="true"
                    :label="'value'"
                    :prop-value="'value'"
                    @selected-value="TicketcategoryData"
                  ></hlx-select>
                </span>
                <span v-else>
                  <hlx-select
                    :key="componentkey1"
                    v-model:value="previewPayload[addFieldsObject.name]"
                    :pre-value="previewPayload[addFieldsObject.name]"
                    :options="addFieldsObject.characteristicSpecification"
                    :placeholder-value="addFieldsObject.name"
                    :label-animation="true"
                    :required="addFieldsObject.mandatory"
                    :clearable="true"
                    :label="'value'"
                    :prop-value="'value'"
                  ></hlx-select>
                </span>
              </div>

              <!-- Radio Button -->
              <div v-if="addFieldsObject['@type'] == 'Radio button'">
                <div v-if="addFieldsObject['@type'] == 'Radio button'">
                  <b>
                    {{ addFieldsObject.name
                    }}<sup
                      v-if="addFieldsObject.mandatory"
                      style="color: #ff5050"
                      >*</sup
                    ></b
                  >
                  <br />
                </div>
                <br />
                <div
                  v-if="addFieldsObject['@type'] == 'Radio button'"
                  class="feature-value-radio-preview"
                >
                  <hlx-input
                    v-model:value="previewPayload[addFieldsObject.name]"
                    :pre-val="previewPayload[addFieldsObject.name]"
                    :required="addFieldsObject.mandatory"
                    :options="addFieldsObject.characteristicSpecification"
                    :position="'horizontal'"
                    :prop-value="'value'"
                    :label="'value'"
                    :type="'border-radio-buttons'"
                  />
                </div>
              </div>
              <!--------- Date ----->
              <div
                v-if="addFieldsObject['@type'] == 'Date'"
                class="feature-value-radio-preview"
                :style="[
                  addFieldsObject['@type'] == 'Date'
                    ? 'display:block !important'
                    : 'display:flex !important',
                ]"
              >
                <hlx-input
                  v-model:value="previewPayload[addFieldsObject.name]"
                  :pre-val="previewPayload[addFieldsObject.name]"
                  :required="addFieldsObject.mandatory"
                  :date-format="'dd (short month), yyyy'"
                  :label-value="addFieldsObject.name"
                  :label-animation="true"
                  type="date"
                  icon="calendar-regular"
                  icon-position="right"
                />
              </div>
              <!-- Single line Text -->
              <div
                v-if="addFieldsObject['@type'] == 'Single line text'"
                class="feature-value-radio-preview"
                :style="[
                  addFieldsObject['@type'] == 'Single line text'
                    ? 'display:block !important'
                    : 'display:flex !important',
                ]"
              >
                <span
                  v-if="previewPayload[addFieldsObject.name] === 'Email id'"
                >
                  <hlx-input
                    v-model:value="previewPayload[addFieldsObject.name]"
                    :pre-val="previewPayload[addFieldsObject.name]"
                    :required="addFieldsObject.mandatory"
                    type="email"
                    :label-animation="true"
                    :label-value="addFieldsObject.name"
                    :display-error="true"
                    :custom-error="isEmailEmpty"
                    :custom-error-message="'Enter a valid email.(eg: user@example.com)'"
                    @at-input="
                      validateEmail(
                        previewPayload[addFieldsObject.name],
                        addFieldsObject.name
                      )
                    "
                  />
                </span>
                <span v-else>
                  <hlx-input
                    v-model:value="previewPayload[addFieldsObject.name]"
                    :pre-val="previewPayload[addFieldsObject.name]"
                    :required="addFieldsObject.mandatory"
                    :type="'text'"
                    :label-animation="true"
                    :label-value="addFieldsObject.name"
                  />
                </span>
              </div>

              <!-- Multi line Text -->
              <div
                v-if="addFieldsObject['@type'] == 'Multi line text'"
                class="feature-value-radio-preview"
                :style="[
                  addFieldsObject['@type'] == 'Multi line text'
                    ? 'display:block !important'
                    : 'display:flex !important',
                ]"
              >
                <hlx-input
                  v-model:value="previewPayload[addFieldsObject.name]"
                  :pre-val="previewPayload[addFieldsObject.name]"
                  :type="'textarea'"
                  :required="addFieldsObject.mandatory"
                  :label-animation="true"
                  :label-value="addFieldsObject.name"
                />
              </div>

              <!-- Multi select -->

              <div
                v-if="addFieldsObject['@type'] == 'Multi select'"
                class="feature-value-radio-preview"
              >
                <hlx-multi-select
                  :key="componentKeyM"
                  :required="addFieldsObject.mandatory"
                  :pre-value="previewPayload[addFieldsObject.name]"
                  :options="addFieldsObject.characteristicSpecification"
                  :tooltipposition="'left'"
                  :placeholder="addFieldsObject.name"
                  :label-animation="true"
                  :prop-value="'value'"
                  :label="'value'"
                  :disabled="false"
                  @selected-array="selectedValue($event, addFieldsObject.name)"
                ></hlx-multi-select>
              </div>

              <!-- Number -->
              <div
                v-if="addFieldsObject['@type'] == 'Number'"
                class="feature-value-radio-preview"
                :style="[
                  addFieldsObject['@type'] == 'Number'
                    ? 'display:block !important'
                    : 'display:flex !important',
                ]"
              >
                <hlx-input
                  v-model:value="previewPayload[addFieldsObject.name]"
                  :pre-val="previewPayload[addFieldsObject.name]"
                  :required="addFieldsObject.mandatory"
                  :type="'number'"
                  :label-animation="true"
                  :label-value="addFieldsObject.name"
                />
              </div>

              <!-- Decimal -->
              <div
                v-if="addFieldsObject['@type'] == 'Decimal'"
                class="feature-value-radio-preview"
                :style="[
                  addFieldsObject['@type'] == 'Decimal'
                    ? 'display:block !important'
                    : 'display:flex !important',
                ]"
              >
                <hlx-input
                  :type="'number'"
                  :step="0.1"
                  :label-animation="true"
                  :label-value="addFieldsObject.name"
                  :required="addFieldsObject.mandatory"
                />
              </div>

              <!-- Address -->

              <div
                v-if="addFieldsObject['@type'] == 'Address'"
                class="feature-value-radio-preview"
              >
                <!-- {{previewPayload[addFieldsObject.name]}} -->
                <addressComponent
                  :key="addresscomponetKey"
                  :view="false"
                  :required="addFieldsObject.mandatory"
                ></addressComponent>
              </div>
              <!-- Attachment -->
              <div
                v-if="addFieldsObject['@type'] == 'Attachment'"
                class="feature-value-attachment-preview"
              >
                <attachment
                  :key="attachmnetComponetKey"
                  :required="addFieldsObject.mandatory"
                ></attachment>
                <!-- :pre-val=" payload?.tro ubleTicketSpecification[0][addFieldsObject?.name]" -->
              </div>
            </span>
          </div>
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 16px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="cancelAddfiled"
            >Cancel</hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 10px"
            @click="cancelAddfiled"
            >Add</hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>
  <div class="add-feature-drawer">
    <hlx-drawer
      :show="drawer"
      position="right"
      :width="400"
      :show-close-icon="true"
      :footer="true"
      :header="true"
      @close="cancelAddfiled()"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          <b v-if="editButton == false">Add field</b>
          <b v-else>Edit field</b>
        </div>
        <!-- Feature body -->
        <section class="body-area-feature-drawer">
          <!-- Label name field-->
          <div class="input-wrapper">
            <hlx-input
              v-model:value="addFieldsObject.name"
              type="text"
              :pre-val="addFieldsObject.name"
              :label-value="'Label name'"
              :disabled="configurableValue"
              :read-only="configurableValue"
              :label-animation="true"
              :required="true"
              :custom-error-message="'Please fill the field'"
            ></hlx-input>
          </div>

          <!-- description section-->

          <div class="input-wrapper">
            <hlx-input
              v-model:value="addFieldsObject.description"
              :pre-val="addFieldsObject.description"
              :label-value="'Description'"
              :label-animation="true"
              type="textarea"
              :rows="4"
              :display-error="false"
            ></hlx-input>
          </div>

          <!-- Display type -->
          <div class="input-wrapper">
            <hlx-select
              :key="componentkeyselectf"
              v-model:value="addFieldsObject['@type']"
              :pre-value="addFieldsObject['@type']"
              :options="typeOptions"
              :placeholder-value="'Input type'"
              :disabled="configurableValue"
              :required="true"
              :label-animation="true"
              :clearable="true"
              @custom-change="handleFieldsType"
            ></hlx-select>
          </div>

          <!--Mandatory-->
          <div class="input-wrapper">
            <span style="display: flex; align-items: center">
              <hlx-input
                v-model:value="addFieldsObject.mandatory"
                :checked="addFieldsObject.mandatory"
                :disabled="configurableValue"
                :read-only="configurableValue"
                label-value="Mandatory"
                :type="'single-checkbox'"
                @change="configureMode"
              />
              <p
                class="bg-default border"
                data-tooltip="This field must be completed for successful submission"
                data-tooltip-location="bottom"
                style="width: 100px"
              >
                <i
                  class="icon-info-circle-regular"
                  style="font-size: 12px; padding: 2px 0px 0px 5px"
                ></i>
              </p>
            </span>
          </div>

          <!-- options value -->
          <div
            v-if="
              addFieldsObject['@type'] == 'Dropdown' ||
              addFieldsObject['@type'] == 'Radio button' ||
              addFieldsObject['@type'] == 'Multi select'
            "
            class="input-wrapper"
          >
            <section style="display: flex; flex-direction: column">
              <draggable
                class="draggable-feature-values"
                :list="addFieldsObject.characteristicSpecification"
                item-key="id"
              >
                <template #item="{ element, index }">
                  <span
                    class="add-value"
                    style="
                      display: flex;
                      align-items: center;
                      width: 100%;
                      gap: 5px;
                      margin-bottom: 15px;
                    "
                  >
                    <i class="icon-drag-vertical-filled"></i>
                    <span style="width: 100%">
                      <hlx-input
                        v-model:value="
                          addFieldsObject.characteristicSpecification[index]
                            .value
                        "
                        :pre-val="element.value"
                        :label-animation="false"
                        type="text"
                        :clearable="true"
                      />
                    </span>
                    <i
                      class="icon-badge-check-regular"
                      :class="
                        element.default == true ? 'selectedDefaultValue' : ' '
                      "
                      @click="enabledefaultValue(index)"
                    ></i>
                    <i
                      v-if="
                        addFieldsObject.characteristicSpecification?.length > 1
                      "
                      class="icon-trash-regular"
                      @click="deleterowValue(index)"
                    ></i>
                  </span>
                </template>
              </draggable>
              <span
                style="
                  display: flex;
                  color: #54bd95;
                  align-items: center;
                  cursor: pointer;
                  margin-top: 5px;
                "
                @click="addRowVal()"
              >
                <i style="margin-right: 5px" class="icon-plus-regular"></i>
                <p>Add value</p>
              </span>
            </section>
          </div>

          <!-- Preview -->
          <section class="feature-preview-area">
            <p class="--title-primary --bold feature-area-title">Preview</p>

            <!-- Dropdown preview -->
            <div
              v-if="addFieldsObject['@type'] == 'Dropdown'"
              class="feature-value-dropdown-preview"
            >
              <hlx-select
                :key="componentkeyselect"
                :options="addFieldsObject.characteristicSpecification"
                :placeholder-value="addFieldsObject.name"
                :label-animation="true"
                :required="addFieldsObject.mandatory"
                :clearable="true"
                :label="'value'"
                :prop-value="'value'"
                :pre-value="preValFunc()"
              ></hlx-select>
            </div>
            <!----------- date ---------->
            <div
              v-if="addFieldsObject['@type'] == 'Date'"
              class="feature-value-radio-preview"
              :style="[
                addFieldsObject['@type'] == 'Date'
                  ? 'display:block !important'
                  : 'display:flex !important',
              ]"
            >
              <hlx-input
                :key="componentkey"
                :date-format="'dd (short month), yyyy'"
                :label-value="addFieldsObject.name"
                :required="addFieldsObject.mandatory"
                :label-animation="true"
                type="date"
                icon="calendar-regular"
                icon-position="right"
              ></hlx-input>
            </div>
            <!-- Radio Button -->
            <div v-if="addFieldsObject['@type'] == 'Radio button'">
              <div v-if="addFieldsObject['@type'] == 'Radio button'">
                <b>
                  {{ addFieldsObject.name
                  }}<sup v-if="addFieldsObject.mandatory" style="color: #ff5050"
                    >*</sup
                  ></b
                >
                <br />
              </div>
              <br />
              <div
                v-if="addFieldsObject['@type'] == 'Radio button'"
                class="feature-value-radio-preview"
              >
                <hlx-input
                  :options="addFieldsObject.characteristicSpecification"
                  :position="'horizontal'"
                  :pre-val="preValFunc()"
                  :required="addFieldsObject.mandatory"
                  :prop-value="'value'"
                  :label="'value'"
                  :type="'border-radio-buttons'"
                />
              </div>
            </div>
            <!-- Single line Text -->
            <div
              v-if="addFieldsObject['@type'] == 'Single line text'"
              class="feature-value-radio-preview"
              :style="[
                addFieldsObject['@type'] == 'Single line text'
                  ? 'display:block !important'
                  : 'display:flex !important',
              ]"
            >
              <hlx-input
                :type="'text'"
                :label-animation="true"
                :required="addFieldsObject.mandatory"
                :label-value="addFieldsObject.name"
              />
            </div>

            <!-- Multi line Text -->
            <div
              v-if="addFieldsObject['@type'] == 'Multi line text'"
              class="feature-value-radio-preview"
              :style="[
                addFieldsObject['@type'] == 'Multi line text'
                  ? 'display:block !important'
                  : 'display:flex !important',
              ]"
            >
              <hlx-input
                :type="'textarea'"
                :label-animation="true"
                :required="addFieldsObject.mandatory"
                :label-value="addFieldsObject.name"
              />
            </div>

            <!-- Multi select -->
            <div
              v-if="addFieldsObject['@type'] == 'Multi select'"
              class="feature-value-radio-preview"
            >
              <hlx-multi-select
                :key="componentkeym1"
                :pre-value="preValFunction()"
                :options="addFieldsObject.characteristicSpecification"
                :tooltipposition="'left'"
                :required="addFieldsObject.mandatory"
                :placeholder="addFieldsObject.name"
                :label-animation="true"
                :prop-value="'value'"
                :label="'value'"
                :disabled="false"
                @selected-array="selectedValue"
              ></hlx-multi-select>
            </div>

            <!-- Attachment -->
            <div
              v-if="addFieldsObject['@type'] == 'Attachment'"
              class="feature-value-attachment-preview"
            >
              <attachment
                :key="attachmnetComponetKey"
                :required="addFieldsObject.mandatory"
              ></attachment>
              <!-- :pre-val=" payload?.tro ubleTicketSpecification[0][addFieldsObject?.name]" -->
            </div>
            <!-- Number -->
            <div
              v-if="addFieldsObject['@type'] == 'Number'"
              class="feature-value-radio-preview"
              :style="[
                addFieldsObject['@type'] == 'Number'
                  ? 'display:block !important'
                  : 'display:flex !important',
              ]"
            >
              <hlx-input
                :type="'number'"
                :label-animation="true"
                :label-value="addFieldsObject.name"
                :required="addFieldsObject.mandatory"
              />
            </div>

            <!-- Decimal -->
            <div
              v-if="addFieldsObject['@type'] == 'Decimal'"
              class="feature-value-radio-preview"
              :style="[
                addFieldsObject['@type'] == 'Decimal'
                  ? 'display:block !important'
                  : 'display:flex !important',
              ]"
            >
              <hlx-input
                :type="'number'"
                :step="0.1"
                :label-animation="true"
                :label-value="addFieldsObject.name"
                :required="addFieldsObject.mandatory"
              />
            </div>

            <!-- Address -->
            <div
              v-if="addFieldsObject['@type'] == 'Address'"
              class="feature-value-radio-preview"
            >
              <addressComponent
                :key="addresscomponetKey"
                :view="false"
                :required="addFieldsObject.mandatory"
              ></addressComponent>
            </div>
          </section>
        </section>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="cancelAddfiled()"
            >Cancel</hlx-button
          >
          <hlx-button
            v-if="editButton != true"
            class="primary sm"
            style="margin-left: 10px"
            :disabled="
              addFieldsObject.name && addFieldsObject['@type'] ? false : true
            "
            @click="emitAddFields"
            >Add</hlx-button
          >
          <hlx-button
            v-if="editButton == true"
            class="primary sm"
            style="margin-left: 10px"
            :disabled="
              addFieldsObject.name && addFieldsObject['@type'] ? false : true
            "
            @click="emitEditFields"
            >Save</hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>

  <div>
    <hlx-modal
      class="upc"
      :modal-active="add_model"
      :height="'403px'"
      :width="'500px'"
      :modal="true"
      @close="add_model = false"
    >
      <template #header>
        <div style="margin-left: 0px">Edit template</div>
      </template>
      <template #content>
        <div class="modal-content">
          <div class="input-section-v2">
            <hlx-input
              v-model:value="editPayload.name"
              :pre-val="editPayload.name"
              :label-animation="true"
              :required="true"
              label-value="Name"
              type="text"
              :clearable="true"
            />
            <div style="width: 100%; margin-top: 20px">
              <hlx-status
                :type="'select-status'"
                :status-option="filteredstatus"
                :prevalue="editPayload.lifecycleState"
                @emit-select="emitStatus"
              >
              </hlx-status>
            </div>
            <div style="width: 100%; margin-top: 20px">
              <hlx-input
                v-model:value="editPayload.description"
                :pre-val="editPayload.description"
                :label-animation="true"
                label-value="Description"
                type="textarea"
                :clearable="true"
              ></hlx-input>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <hlx-button class="secondary sm" @click="add_model = false"
          >Cancel</hlx-button
        >
        <hlx-button
          class="primary sm"
          style="margin-left: 16px"
          :disabled="editPayload.name !== '' ? false : true"
          @click="editTemplateTicket()"
          >Save</hlx-button
        >
      </template>
    </hlx-modal>
  </div>
  <section
    v-if="loader === true"
    style="display: flex; flex-direction: column; height: 100vh"
  >
    <main
      class="decision-add-container --layout-3-way"
      :style="
        isRightLayoutOpened
          ? 'display: grid;grid-template-columns: 200px calc(100% - 600px) 300px'
          : ''
      "
      style="height: 100vh"
    >
      <!-- --------------------------------------------------------------------Left pannel-------------------------------------------------- -->
      <section
        class="cm-fields-left-panel overflow_scroll_invisble"
        style="width: 150px; background-color: #fff"
      >
        <!-- Breadcrumb -->
        <div class="layout-breadcrumb" style="margin-top: 12px">
          <hlx-breadcrumb :items="breadCrumbItems" :auto-route="true" />
          <!-- <hlx-breadcrumb :items="breadCrumbItems" @path="goTo()" /> -->
        </div>
        <div style="margin-top: 25px"></div>
      </section>
      <section
        class="--layout-inner-right-portion --RL70 --upcv2rightlayout overflow_scroll_invisble"
        style="padding: 1.8em 20px"
        :style="
          isRightLayoutOpened === false
            ? 'width:calc(100% - 240px)'
            : 'width:100%'
        "
      >
        <div class="decision-info-right-pannel overflow_scroll_invisble">
          <div>
            <div>
              <!-- overview  section -->
              <div class="upcv2-overview">
                <section class="header" style="margin-bottom: 30px">
                  <div class="title-info">
                    <div class="title">
                      <p class="--upcv2-header-title --staus-label">
                        <span> Ticket template </span>
                      </p>
                      <p class="--upcv2-header-subtitle" style="color: #000000">
                        Create fields tailored to your specific needs
                      </p>
                    </div>
                  </div>
                  <div
                    v-if="viewMode == 'false'"
                    class="custom-button"
                    style="display: flex; gap: 12px"
                  >
                    <hlx-button class="primary sm" @click="viewMode = 'true'"
                      >Edit</hlx-button
                    >
                  </div>
                  <div
                    v-else
                    class="custom-button"
                    style="display: flex; gap: 12px"
                  >
                    <!-- <hlx-button class="secondary-success sm">Preview</hlx-button>
            <hlx-button
                class="primary sm add-btn"
                ><i
                  class="icon-plus-circle-regular"
                  style="margin-right: 5px"
                ></i
                >Add Field</hlx-button
              > -->
                  </div>
                </section>
                <section class="right-view-body overflowScroll_all">
                  <section
                    class="ticket-body"
                    style="display: flex; flex-direction: column"
                  >
                    <section class="ticket-header">
                      <span
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          margin-right: 30px;
                        "
                      >
                        <div class="title">
                          <p
                            class="--upcv2-header-title --staus-label"
                            style="
                              display: flex;
                              gap: 0.5rem;
                              align-items: flex-start;
                              flex-direction: column;
                            "
                          >
                            <span style="display: flex">
                              <span>{{ payload.name }} </span>
                              <span style="margin-left: 10px"
                                ><hlx-status
                                  :type="payload.lifecycleState"
                                ></hlx-status
                              ></span>
                              <span>
                                <i
                                  class="icon-edit-filled"
                                  @click="editTemplate(payload)"
                                ></i>
                              </span>
                            </span>
                            <span
                              style="
                                color: #a6a6a6;
                                font-size: 11px;
                                font-weight: 400;
                              "
                              >{{ payload.description }}</span
                            >
                            <span
                              style="
                                display: flex;
                                align-items: center;
                                gap: 10px;
                              "
                            >
                              <span>Ticket category : </span>
                              <span style="width: 300px">
                                <div>
                                  <hlx-select
                                    :key="componentkeyselect"
                                    :single-border="true"
                                    :inline-search="true"
                                    :options="categoryType"
                                    :label="'value'"
                                    :prop-value="'value'"
                                    :placeholder-value="'Category'"
                                    :required="false"
                                    :label-animation="false"
                                    :clearable="false"
                                    :pre-value="payload?.category[0]?.name"
                                    @custom-change="handleFieldsType"
                                    @selected-value="categoryData"
                                  ></hlx-select>
                                </div>
                              </span>
                            </span>
                          </p>
                        </div>
                        <span style="display: flex">
                          <span class="ticketserach">
                            <hlx-search
                              v-model="searchvalue"
                              @search-key="TicketSearch"
                            ></hlx-search
                          ></span>
                          <hlx-button
                            class="secondary-success sm"
                            @click="previewCall"
                            >Preview</hlx-button
                          >
                          <hlx-button
                            class="secondary-success sm"
                            style="margin-left: 10px"
                            @click="openAddfiled"
                          >
                            <i
                              class="icon-plus-circle-regular"
                              style="margin-right: 5px"
                            ></i
                            >Add field</hlx-button
                          >

                          <hlx-button
                            class="primary sm add-btn"
                            style="margin-left: 10px"
                            :disabled="
                              payload?.category.length > 0 ? false : true
                            "
                            @click="publichtheTemplate"
                            ><i
                              class="icon-send-right-regular"
                              style="margin-right: 5px"
                            ></i
                            >Publish</hlx-button
                          >
                        </span>
                      </span>
                    </section>
                    <section class="content" style="margin-top: 25px">
                      <section class="drag-drop-container">
                        <draggable
                          class="draggable-fields-value"
                          :list="fieldsSort"
                          item-key="name"
                          @change="getComponentData()"
                        >
                          <template #item="{ element, index }">
                            <div class="draggable-field-each-container">
                              <span
                                class="draggable-field-each-container-left-section"
                              >
                                <i
                                  class="icon-drag-vertical-filled drag-icon"
                                ></i>
                                <p
                                  class="draggable-field-each-container-field-name"
                                  @click="editFieldsdrawer(index, isEnableDelete(element))"
                                >
                                  {{ element.name }}
                                  <sup
                                    v-if="element.mandatory === true"
                                    class="mandatory-filed-ticket"
                                    >*</sup
                                  >
                                </p>
                                <hlx-label class="primary sm">{{
                                  element["@type"]
                                }}</hlx-label>

                                <i
                                  v-if="isEnableDelete(element) == false"
                                  class="icon-check-circle-filled ticket-circle-color"
                                ></i>
                              </span>

                              <span
                                class="draggable-field-each-container-right-section"
                              >
                                <i
                                  class="icon-edit-filled"
                                  @click="
                                    editFieldsdrawer(
                                      index,
                                      isEnableDelete(element),
                                      element
                                    )
                                  "
                                ></i>
                                <i
                                  v-if="isEnableDelete(element) == true"
                                  class="icon-trash-regular"
                                  @click="deleteFields(payload.fields, index)"
                                ></i>
                              </span>
                            </div>
                          </template>
                        </draggable>
                      </section>
                    </section>
                  </section>
                </section>
              </div>
            </div>
          </div>
        </div>
        <!-- Feature -->
      </section>
    </main>
  </section>
</template>

<script>
import attachment from "@/components/singleEmailComponent.vue";

import {
  // DMNAxiosInstance,
  UPCAxiosInstance,
  //   MLTAxiosInstance,
} from "@/config/axiosConfig";
import draggable from "vuedraggable";
import addressComponent from "@/components/CM/autocompleteComponent.vue";

export default {
  components: {
    attachment,
    draggable,
    addressComponent,
  },
  data() {
    return {
      templateFilterQuery: {
        type: "filter",
        module: "tickettemplate",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: this.rowsPerPage,
          currentPage: this.currPage,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {},
        searchQuery: "",
        company: this.$cookies.get("userName"),
      },
      editPayload: {},
      statusList: [],
      tableData: [],
      filteredstatus: [],
      componentkeyselectf: 0,
      componentkeym1: 0,
      editIndex: 0,
      editButton: false,
      editFieldIndex: 0,
      configurableValue: false,
      delete_modal_active: false,
      deleteobj: {},
      delete_name: "",
      deleterowindex: 0,
      typeOptions: [
        { name: "Dropdown", value: "Dropdown" },
        { name: "Radio button", value: "Radio button" },
        { name: "Single line text", value: "Single line text" },
        { name: "Multi line text", value: "Multi line text" },
        { name: "Attachment", value: "Attachment" },
        { name: "Multi select", value: "Multi select" },
        { name: "Number", value: "Number" },
        { name: "Address", value: "Address" },
        { name: "Date", value: "Date" },
      ],
      componentkeyselectr: 0,
      componentkeyselecta: 0,
      componentkeyselectt: 0,
      componentkeyselects: 0,
      isModal: false,
      drawer: false,
      labelFieldsValue: [],
      notification: [],
      ticketSpec: [],
      addFieldsObject: {
        priority: 0,
        name: "",
        description: "",
        mandatory: false,
        "@type": "",
        configurable: false,
        characteristicSpecification: [{ value: "", default: false }],
      },
      loader: false,
      add_model: false,
      componentKeyM: 0,
      attachmnetComponetKey: 0,
      addresscomponetKey: 0,
      previewPayload: {},
      searchvalue: "",
      payload: {},
      fieldsSort: {},
      copyfieldsSort: {},
      categoryType: [],
      defaultLabelFields: [
        // { name: "Ticket category", type: "Dropdown", configurable: true },
        { name: "Requester", type: "Dropdown", configurable: true },
        { name: "Ticket category", type: "Dropdown", configurable: true },
        {
          name: "Ticket subject",
          type: "Single line text",
          configurable: true,
        },
        { name: "Email id", type: "Single line text", configurable: true },
        { name: "Subject", type: "Single line text", configurable: true },
        { name: "Priority level", type: "Dropdown", configurable: true },
        { name: "Status", type: "Dropdown", configurable: true },
        { name: "Assigned to", type: "Dropdown", configurable: true },
        { name: "Tags", type: "Checkbox", configurable: true },
        { name: "Description", type: "Multi line text", configurable: true },
        { name: "Attachment", type: "Attachment", configurable: true },
      ],
    };
  },

  watch: {
    statusList: {
      handler(val) {
        console.log(val, "statuswatching000000000000");
        const filteredstatuslist = this.statusList.filter(
          (item) =>
            item.name === "Active" ||
            item.name === "Inactive" ||
            item.name === "Draft"
        );
        this.filteredstatus = filteredstatuslist;
        console.log("filtered11111111111111111", this.filteredstatus);
      },
      immediate: true,
      deep: true,
    },
    addFieldsObject: {
      handler(val) {
        console.log("addFieldsObject..........", val);
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    const asignee = await UPCAxiosInstance.get(
      `templateTicket/${this.$route.query.id}`
    );
    this.payload = asignee.data;
    if (asignee.data) {
      this.loader = true;
    }
    this.fieldsSort = asignee.data.fields;
    this.fieldsSort?.sort((a, b) => a.priority - b.priority);

    this.copyfieldsSort = asignee.data.fields;
    this.copyfieldsSort?.sort((a, b) => a.priority - b.priority);

    const response = await UPCAxiosInstance.get(`/ticketSpec`);

    this.ticketSpec = response.data;
    this.labelFieldsValue = response.data;
    this.labelFieldsValue?.sort((a, b) => a.priority - b.priority);
    this.refreshTable();
    console.log(this.payload, " this.payload", this.categoryType);
    // const rootName = this.$cookies.get("rootName");
    // const statuslisturl =
    //     rootName === "Halleyx"
    //       ? "mapRef/collection/statuslist"
    //       : `mapRef/collection/statuslist?company=${this.$cookies.get("rootName")}`;

    let statusList =  await this.getReferanceData('statuslist');
    this.statusList = statusList.data.data;
    this.categoryType = this.filterCategoryType(
      this.ticketSpec,
      this.tableData,
      { value: this.payload.category[0].name }
    );
  },

  methods: {
    TicketSearch(val) {
      if (val) {
        this.searchToggle = true;
        const result = this.searchNoteArray(val.toLowerCase());
        this.fieldsSort = result;
      } else {
        this.searchToggle = false;
        this.fieldsSort = JSON.parse(JSON.stringify(this.copyfieldsSort));
      }
    },
    searchNoteArray(val) {
      if (val !== "") {
        const result = this.copyfieldsSort.filter((obj) => {
          return Object.values(obj).some((value) => {
            if (typeof value === "string") {
              return value.toLowerCase().includes(val);
            }
            return false;
          });
        });

        return result;
      } else {
        this.fieldsSort = JSON.parse(JSON.stringify(this.copyfieldsSort));
      }
    },
    filterCategoryType(ticketSpec, tableData, currentData) {
      // Find the "Ticket category" in ticketSpec
      console.log(ticketSpec, tableData, "filterCategoryType");

      const ticketCategorySpec = ticketSpec.find(
        (item) => item.name === "Ticket category"
      );

      // Extract characteristicSpecification from the found item
      const ticketService =
        ticketCategorySpec?.characteristicSpecification || [];

      // Extract current ticket categories from tableData
      const selectedCategories = tableData
        .filter((item) => item.lifecycleState === "Active") // Filter active items
        .map((item) => item.category[0]?.name || "");
      // Filter ticketService to exclude already selected categories
      const categoryType = ticketService.filter(
        (option) => !selectedCategories.includes(option.value)
      );
      if (currentData) {
        categoryType.unshift(currentData);
      }
      return categoryType;
    },
    async refreshTable() {
      const response = await UPCAxiosInstance.post(
        `util/filterData`,
        this.templateFilterQuery
      );
      // const response = await UPCAxiosInstance.get(`/templateTicket`);

      this.tableData = response.data.data;
      this.totalvalue = response.data.total;
    },
    // Helper function to sort fields by priority
    sortFieldsByPriority(fields) {
      return fields?.sort((a, b) => a.priority - b.priority);
    },
    createTicketSpecification(labelFieldsValue, ticketCategory, payload) {
      console.log(
        labelFieldsValue,
        ticketCategory,
        "createTicketSpecification",
        payload
      );

      // Ensure labelFieldsValue is an array and payload is an object
      if (!labelFieldsValue || !Array.isArray(labelFieldsValue)) {
        console.error("labelFieldsValue is not an array or is undefined");
        return {};
      }

      if (!payload || typeof payload !== "object") {
        console.error("payload is not an object or is undefined");
        return {};
      }

      let obj = {};

      labelFieldsValue.forEach((item) => {
        // Check if payload has a pre-existing value for the current item
        let preValue = payload[item.name] || "";

        if (item["@type"] === "Dropdown" || item["@type"] === "Radio button") {
          let found = false;
          item.characteristicSpecification.forEach((itemch) => {
            if (itemch.default === true) {
              obj[item.name] = preValue !== "" ? preValue : itemch.value;
              found = true;
            }
          });
          if (!found) {
            obj[item.name] = preValue !== "" ? preValue : "";
          }
        } else if (item["@type"] === "Multi select") {
          let value = [];
          item.characteristicSpecification.forEach((itemch) => {
            if (itemch.default === true) {
              value.push(itemch.value);
            }
          });
          obj[item.name] =
            preValue !== "" ? preValue : value.length > 0 ? value : [];
        } else {
          // If type is not Dropdown, Radio button, or Multi select, use pre-existing value if available
          obj[item.name] = preValue !== "" ? preValue : "";
        }
      });

      // Ensure TicketCategory is set
      obj["Ticket category"] = ticketCategory || "Unknown Category";

      // Ensure "Assigned to" is set safely
      obj["Assigned to"] = this.instanceData?.name || "Unassigned";

      return obj;
    },
    // General function for updating ticket data via API
    async updateTicketData(
      data,
      successMessage = "Changes saved successfully!"
    ) {
      try {
        const response = await UPCAxiosInstance.patch(`/templateTicket`, data);
        this.payload = response.data;
        this.fieldsSort = this.sortFieldsByPriority(response.data.fields);
        this.copyfieldsSort = [...this.fieldsSort];
        this.searchvalue = "";
        this.notification = {
          type: "success",
          message: successMessage,
        };
      } catch (error) {
        console.error("Error updating ticket data:", error.message);
        this.notification = {
          type: "error",
          message: `Error updating ticket: ${error.message}`,
        };
      }
    },

    openAddField() {
      this.drawer = true;
      this.componentkeyselectf += 1;
      this.editButton = false;
      this.addFieldsObject = {
        priority: 0,
        name: "",
        description: "",
        mandatory: false,
        "@type": "",
        configurable: false,
        characteristicSpecification: [{ value: "", default: false }],
      };
    },

    async emitAddFields() {
      this.drawer = false;
      this.addFieldsObject.priority = this.payload.fields.length + 1;
      let exists = this.fieldsSort.some(
        (ticket) => ticket.name === this.addFieldsObject.name
      );

      // If the ticket doesn't exist, push it to the array
      if (!exists) {
        this.fieldsSort.push(this.addFieldsObject);
        const dataSt = {
          id: this.payload._id,
          fields: this.fieldsSort,
          lifecycleState: "Draft",
        };
        await this.updateTicketData(
          dataSt,
          "Nice work! Your new ticket is now in the list!"
        );
      } else {
        this.notification = {
          type: "warning",
          message: `Ticket field ${this.addFieldsObject.name} already exists`,
        };
      }
      // this.fieldsSort.push(this.addFieldsObject);

      this.cancelAddfiled();
    },
    previewCall() {
      this.previewPayload = this.createTicketSpecification(
        this.payload.fields,
        this.payload?.category[0]?.name
      );
      this.labelFieldsValue = this.payload.fields;
      console.log(this.previewPayload, "this.previewPayload previewCall");

      this.isModal = true;
    },
    async getComponentData() {
      console.log("getComponentData");
      for (const [index, drag] of this.fieldsSort.entries()) {
        drag.priority = index;
      }
      const dataSt = {
        id: this.payload._id,
        fields: this.fieldsSort,
      };
      await this.updateTicketData(dataSt);
    },

    async categoryData(val) {
      if (!this.payload.category) this.payload.category = [{}];
      this.payload.category[0].name = val.value;
      const dataSt = {
        id: this.payload._id,
        category: this.payload.category,
      };
      await this.updateTicketData(dataSt, "Category updated successfully!");
      await this.refreshTable();

      this.categoryType = await this.filterCategoryType(
        this.ticketSpec,
        this.tableData,
        { value: this.payload.category[0].name }
      );
    },

    async TicketcategoryData(val) {
      let found = false;
      let payloadData = JSON.parse(
        JSON.stringify(this.payload.troubleTicketSpecification[0])
      );

      this.templatePayload.some((item) => {
        if (found) return true;
        if (
          val.value === item.category[0]?.name &&
          item.lifecycleState === "Active"
        ) {
          this.labelFieldsValue = item.fields;
          this.labelFieldsValue.sort((a, b) => a.priority - b.priority);

          found = true;
          try {
            this.initializeTroubleTicketSpecification();
            this.payload.troubleTicketSpecification[0] =
              this.createTicketSpecification(
                this.labelFieldsValue,
                val.value,
                payloadData
              );
            console.log("troubleTicketSpecification", this.payload);
          } catch (error) {
            console.error("Error fetching autocomplete data:", error);
          }
          return true;
        } else if (val.value !== item.category[0]?.name) {
          this.labelFieldsValue.sort((a, b) => a.priority - b.priority);
          try {
            this.initializeTroubleTicketSpecification();
            this.payload.troubleTicketSpecification[0] =
              this.createTicketSpecification(
                this.labelFieldsValue,
                val.value,
                payloadData
              );
            console.log("troubleTicketSpecification", this.payload);
          } catch (error) {
            console.error("Error fetching autocomplete data:", error);
          }
        }
        return false;
      });
    },

    isEnableDelete(element) {
      let count = this.defaultLabelFields.filter(
        (item) => item.name === element.name
      ).length;
      return element.ticketFieldMandatory ? false : count !== 1;
    },

    editTemplate(i) {
      this.add_model = true;
      this.editPayload = JSON.parse(JSON.stringify(i));
    },

    editFieldsDrawer(index, mandatory) {
      this.editFieldIndex = index;
      this.configurableValue = !mandatory;
      this.addFieldsObject = JSON.parse(
        JSON.stringify(this.payload.fields[index])
      );
      this.editButton = true;
      this.drawer = true;

      if (!this.addFieldsObject.characteristicSpecification) {
        this.addFieldsObject.characteristicSpecification = [
          { value: "", default: false },
        ];
      }
    },

    async deleteFields(val, index) {
      this.delete_modal_active = true;
      this.deleteobj = val;
      this.delete_name = val[index].name;
      this.deleterowindex = index;
    },

    async deleteExecRow() {
      this.delete_modal_active = false;
      this.deleteobj.splice(this.deleterowindex, 1);
      const dataSt = {
        id: this.payload._id,
        fields: this.deleteobj,
        lifecycleState: "Draft",
      };
      await this.updateTicketData(
        dataSt,
        `Done ${this.delete_name} has been removed.`
      );
    },

    addRowVal() {
      let valueObject = { value: "", default: false };
      let length = this.addFieldsObject.characteristicSpecification.length;
      if (this.addFieldsObject.characteristicSpecification[length - 1].value) {
        this.addFieldsObject.characteristicSpecification.push(valueObject);
      }
    },

    deleteRowValue(index) {
      if (this.addFieldsObject["@type"] === "Dropdown") {
        this.componentkey1 += 1;
      }
      this.addFieldsObject.characteristicSpecification.splice(index, 1);
    },

    enableDefaultValue(index) {
      this.addFieldsObject.characteristicSpecification[index].default =
        !this.addFieldsObject.characteristicSpecification[index].default;
      if (
        this.addFieldsObject.characteristicSpecification[this.previousIndex] &&
        index !== this.previousIndex
      ) {
        this.addFieldsObject.characteristicSpecification[
          this.previousIndex
        ].default = false;
      }
      this.previousIndex = index;
      this.componentkeym1 += 1;

      if (this.preValFunction().length === 0) {
        let arr = this.addFieldsObject.characteristicSpecification.map((i) => ({
          value: i.value,
        }));
        this.addFieldsObject.characteristicSpecification = arr;
      }

      console.log(
        this.addFieldsObject.characteristicSpecification,
        this.preValFunction(),
        "characteristicSpecification data"
      );
    },

    preValFunction() {
      let value = [];
      let count = 0;
      this.addFieldsObject.characteristicSpecification.forEach((item) => {
        if (item.default) {
          count = 1;
          value.push(item.value);
        }
      });
      return count === 0 ? [] : value;
    },

    async emitEditFields() {
      this.drawer = false;
      this.payload.fields[this.editFieldIndex] = this.addFieldsObject;
      const dataSt = {
        id: this.payload._id,
        fields: this.payload.fields,
        lifecycleState: "Draft",
      };

      await this.updateTicketData(
        dataSt,
        "All set! Your changes have been saved successfully!"
      );
      this.cancelAddfiled();
    },

    async publishTemplate() {
      this.payload.lifecycleState = "Active";
      const dataSt = {
        id: this.payload._id,
        lifecycleState: "Active",
      };
      await this.updateTicketData(
        dataSt,
        "All set! Your template has been published successfully!"
      );
    },

    async editTemplateTicket() {
      this.editPayload.fields = this.ticketSpec;
      this.editPayload.id = this.editPayload._id;
      delete this.editPayload._id;
      if (this.editPayload.lifecycleState === "Active") {
        const currentCategory = this.tableData.find(
          (item) => item.category[0]?.name === this.payload.category[0]?.name
        );

        // Check if the category is already active
        if (currentCategory && currentCategory.lifecycleState === "Active") {
          this.notification = {
            type: "warning",
            message: `The ${this.payload.category[0]?.name} category is already active!`,
          };
          this.cancelModel();

          return; // Stop the function here if already active
        }

        await this.updateTicketData(
          this.editPayload,
          "All set! Your changes have been saved successfully!"
        );
      } else {
        await this.updateTicketData(
          this.editPayload,
          "All set! Your changes have been saved successfully!"
        );
        this.cancelModel();
      }
    },
    openAddfiled() {
      this.drawer = true;
      this.componentkeyselectf += 1;
      this.editButton = false;
      this.addFieldsObject = {
        priority: 0,
        name: "",
        description: "",
        mandatory: false,
        "@type": "",
        configurable: false,
        characteristicSpecification: [{ value: "", default: false }],
      };
    },
    // async emitaddFields() {
    //   this.drawer = false;
    //   this.addFieldsObject.priority = this.payload.fields.length + 1;
    //   this.fieldsSort.push(this.addFieldsObject);
    //   const dataSt = {
    //     id: this.payload._id,
    //     fields: this.fieldsSort,
    //     lifecycleState: "Draft",
    //   };
    //   try {
    //     // eslint-disable-next-line vue/no-async-in-computed-properties
    //     const response = await UPCAxiosInstance.patch(
    //       `/templateTicket`,
    //       dataSt
    //     );
    //     this.fieldsSort = response.data.fields;
    //     this.fieldsSort?.sort((a, b) => a.priority - b.priority);
    //     this.copyfieldsSort = response.data.fields;
    //     this.copyfieldsSort?.sort((a, b) => a.priority - b.priority);
    //     console.log(response.data, "data");
    //     this.payload = response.data;
    //     if (response) {
    //       this.notification = {
    //         type: "success",
    //         message: `Nice work! Your new ticket is now in the list!`,
    //       };
    //     }
    //     // this.labelFieldsValue = response.data;
    //   } catch (error) {
    //     console.error("Error fetching autocomplete data:", error.message);
    //   }
    //   this.payload?.fields?.sort((a, b) => a.priority - b.priority);
    //   this.searchvalue = "";
    //   this.cancelAddfiled();
    // },
    // async getComponentData() {
    //   console.log("getComponentData");
    //   const mappingdata = this.fieldsSort.map(async (drag, index) => {
    //     drag.priority = index;
    //     return
    //   });
    //   mappingdata;
    //   console.log(mappingdata,"mappingdata");

    //   this.payload.fields = this.fieldsSort;
    //   const dataSt = {
    //     id: this.payload._id,
    //     fields: this.fieldsSort,
    //   };
    //   try {
    //     // eslint-disable-next-line vue/no-async-in-computed-properties
    //     const response = await UPCAxiosInstance.patch(
    //       `/templateTicket`,
    //       dataSt
    //     );
    //     console.log(response.data, "data");
    //     // this.labelFieldsValue = response.data;
    //   } catch (error) {
    //     console.error("Error fetching autocomplete data:", error.message);
    //   }
    //   this.searchvalue=""
    //   console.log(this.labelFieldsValue, this.fieldsSort, "mappingdata");
    // },
    // async categoryData(val) {
    //   if (!this.payload.category) {
    //     this.payload.category = [];
    //   }
    //   if (!this.payload.category[0]) {
    //     this.payload.category[0] = {};
    //   }
    //   this.payload.category[0].name = val.value;
    //   const dataSt = {
    //     id: this.payload._id,
    //     category: this.payload.category,
    //   };
    //   try {
    //     // eslint-disable-next-line vue/no-async-in-computed-properties
    //     const response = await UPCAxiosInstance.patch(
    //       `/templateTicket`,
    //       dataSt
    //     );
    //     console.log(response.data, "data");
    //     this.payload = response.data;
    //     this.payload?.fields?.sort((a, b) => a.priority - b.priority);
    //     this.fieldsSort = response.data.fields;
    // this.fieldsSort?.sort((a, b) => a.priority - b.priority);
    // this.copyfieldsSort = response.data.fields;
    // this.copyfieldsSort?.sort((a, b) => a.priority - b.priority);
    //     // this.labelFieldsValue = response.data;
    //     this.searchvalue=""
    //   } catch (error) {
    //     console.error("Error fetching autocomplete data:", error.message);
    //   }
    //   console.log(
    //     val.value,
    //     "categoryData",
    //     this.payload,
    //     "this.payload.category[0].name"
    //   );
    // },
    // TicketcategoryData(val) {
    //   console.log(val, "TicketcategoryData");
    //   if (val.value === this.payload?.category[0]?.name) {
    //     this.labelFieldsValue = this.payload.fields;
    //     console.log(this.labelFieldsValue, "this.labelFieldsValue");
    //   } else {
    //     this.labelFieldsValue = this.ticketSpec;
    //   }
    // },
    //     isEnableDelete(element) {
    //   let count = this.defaultLabelFields.filter(item => item.name === element.name).length;

    //   // Check if ticketFieldMandatory is true
    //   if (element.ticketFieldMandatory) {
    //     return false;
    //   }

    //   // If count is 1, deletion is not enabled
    //   return count !== 1;
    // }
    // ,
    //     editTemplate(i) {
    //       this.add_model = true;
    //       this.editPayload = JSON.parse(JSON.stringify(i));
    //     },
    editFieldsdrawer(index, mandatory) {
      console.log("index, mandatory", index, mandatory);

      this.editFieldIndex = index;
      if (mandatory === false) {
        this.configurableValue = true;
      } else {
        this.configurableValue = false;
      }
      //   console.log(mandatory, "mandatorymandatory",this.payload.fields[index],this.payload,this.payload.fields);
      this.addFieldsObject = JSON.parse(
        JSON.stringify(this.payload.fields[index])
      );
      this.editButton = true;
      this.drawer = true;
      //   console.log(
      //     this.addFieldsObject.characteristicSpecification,
      //     "objectVala"
      //   );
      if (this.addFieldsObject.characteristicSpecification == undefined) {
        let valueObject = [
          {
            value: "",
            default: false,
          },
        ];
        this.addFieldsObject["characteristicSpecification"] = valueObject;
      }
      //   console.log(this.addFieldsObject, "objectVala");
      this.editIndex = index;
    },
    // async deleteFields(val, index) {
    //   this.delete_modal_active = true;
    //   this.deleteobj = val;
    //   this.delete_name = val[index].name;
    //   this.deleterowindex = index;
    // },
    // async deleteExecRow() {
    //   this.delete_modal_active = false;
    //   //   let i = this.deleteobj;
    //   //    console.log(val, index, "deleteFields");
    //   this.deleteobj.splice(this.deleterowindex, 1);
    //   console.log(this.deleteobj, "splicesplice");
    //   this.fieldsSort = this.deleteobj;
    //   const dataSt = {
    //     id: this.payload._id,
    //     fields: this.deleteobj,
    //     lifecycleState: "Draft",
    //   };
    //   try {
    //     // eslint-disable-next-line vue/no-async-in-computed-properties
    //     const response = await UPCAxiosInstance.patch(
    //       `/templateTicket`,
    //       dataSt
    //     );
    //     console.log(response.data, "data");
    //     this.payload = response.data;
    //     // this.labelFieldsValue = response.data;
    //     this.searchvalue=""
    //     if (response.data) {
    //       this.notification = {
    //         type: "success",
    //         message: `Done ${this.delete_name} has been removed.`,
    //       };
    //     }
    //   } catch (error) {
    //     console.error("Error fetching autocomplete data:", error.message);
    //   }
    // },
    // addRowVal() {
    //   let valueObject = {
    //     value: "",
    //     default: false,
    //   };

    //   let length = this.addFieldsObject.characteristicSpecification.length;
    //   console.log(
    //     length,
    //     "text",
    //     this.addFieldsObject.characteristicSpecification[length - 1]
    //   );
    //   if (this.addFieldsObject.characteristicSpecification[length - 1].value) {
    //     this.addFieldsObject.characteristicSpecification.push(valueObject);
    //   }
    // },
    deleterowValue(index) {
      if (this.addFieldsObject["@type"] == "Dropdown") {
        this.componentkey1 += 1;
      }
      this.addFieldsObject.characteristicSpecification.splice(index, 1);
    },
    enabledefaultValue(index) {
      this.addFieldsObject.characteristicSpecification[index].default =
        !this.addFieldsObject.characteristicSpecification[index].default;
      if (
        this.addFieldsObject.characteristicSpecification[this.previousIndex] &&
        index != this.previousIndex
      ) {
        this.addFieldsObject.characteristicSpecification[
          this.previousIndex
        ].default = false;
      }
      this.previousIndex = index;
      this.componentkeym1 += 1;
      let prevaluemul = this.preValFunction();
      if (prevaluemul.length === 0) {
        let arr = this.addFieldsObject.characteristicSpecification.map((i) => {
          return { value: i.value };
        });

        this.addFieldsObject.characteristicSpecification = [];
        this.addFieldsObject.characteristicSpecification = arr;
      }
      console.log(
        this.addFieldsObject.characteristicSpecification,
        this.preValFunction(),
        "characteristicSpecification data"
      );
    },

    async emiteditFields() {
      this.drawer = false;
      this.payload.fields[this.editFieldIndex] = this.addFieldsObject;
      console.log(this.payload, this.addFieldsObject, "edit payload");
      const dataSt = {
        id: this.payload._id,
        fields: this.payload.fields,
        lifecycleState: "Draft",
      };
      try {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        const response = await UPCAxiosInstance.patch(
          `/templateTicket`,
          dataSt
        );
        console.log(response.data, "data");
        this.payload = response.data;
        this.fieldsSort = response.data.fields;
        this.fieldsSort?.sort((a, b) => a.priority - b.priority);
        this.copyfieldsSort = response.data.fields;
        this.copyfieldsSort?.sort((a, b) => a.priority - b.priority);
        // this.labelFieldsValue = response.data;
        this.searchvalue = "";
      } catch (error) {
        console.error("Error fetching autocomplete data:", error.message);
      }
      // this.payload?.fields?.sort((a, b) => a.priority - b.priority);
      // this.configurableValue = false;
      // this.addFieldsObject["id"] = this.addFieldsObject._id;
      // delete this.addFieldsObject._id;
      // console.log(this.addFieldsObject, "data");
      // try {

      //   const response = await UPCAxiosInstance.patch(
      //     `/templateTicket`,
      //     this.addFieldsObject
      //   );
      //   console.log(response.data, "data");
      //   // this.labelFieldsValue = response.data;
      // } catch (error) {
      //   console.error("Error fetching autocomplete data:", error.message);
      // }
      this.notification = {
        type: "success",
        message: `All set! Your changes have been saved successfully!`,
      };

      // this.labelFieldsValue[this.editIndex] = this.addFieldsObject
      this.drawer = false;
      this.cancelAddfiled();
    },
    preValFunc() {
      let value = "";
      this.addFieldsObject.characteristicSpecification.forEach((item) => {
        if (item.default == true) {
          value = item.value;
        }
      });

      return value;
    },
    // preValFunction() {
    //   let value = [];
    //   let count = 0;
    //   this.addFieldsObject.characteristicSpecification.forEach((item) => {
    //     if (item.default == true) {
    //       count = 1;
    //       value.push(item.value);
    //       // value= item.value
    //     }
    //   });
    //   if (count == 0) {
    //     value = [];
    //   }

    //   return value;
    // },
    emitStatus(val) {
      this.editPayload.lifecycleState = val.name;
      console.log(this.payload, "this.payload");
    },
    async publichtheTemplate() {
      // Find the category in tableData
      const currentCategory = this.tableData.find(
        (item) => item.category[0]?.name === this.payload.category[0]?.name
      );

      // Check if the category is already active
      if (currentCategory && currentCategory.lifecycleState === "Active") {
        this.notification = {
          type: "warning",
          message: `The ${this.payload.category[0]?.name} category is already active!`,
        };
        return; // Stop the function here if already active
      }

      // If not active, proceed with publishing
      this.payload.lifecycleState = "Active";
      const dataSt = {
        id: this.payload._id,
        lifecycleState: "Active",
      };

      try {
        const response = await UPCAxiosInstance.patch(
          `/templateTicket`,
          dataSt
        );

        console.log(response.data, "data");
        this.searchvalue = "";
        // this.payload = response.data;
        this.notification = {
          type: "success",
          message: `All set! Your template has been published successfully!`,
        };
      } catch (error) {
        console.error("Error publishing the template:", error.message);
        this.notification = {
          type: "error",
          message: `Failed to publish the template.`,
        };
      }
    },
    //     async editTemplateTicket() {
    //       this.editPayload.fields = this.ticketSpec;
    //       console.log(this.payload, "this.payload");
    //       this.editPayload.id = this.editPayload._id;
    //       delete this.editPayload._id;
    //       const response = await UPCAxiosInstance.patch(
    //         `/templateTicket`,
    //         this.editPayload
    //       );
    // this.searchvalue=""
    //       this.payload = response.data;
    //       this.fieldsSort = response.data.fields;
    //     this.fieldsSort?.sort((a, b) => a.priority - b.priority);
    //     this.copyfieldsSort = response.data.fields;
    //     this.copyfieldsSort?.sort((a, b) => a.priority - b.priority);

    //       if (response.data) {
    //         this.notification = {
    //           type: "success",
    //           message: `All set! Your changes have been saved successfully!`,
    //         };
    //       }
    //       console.log(response, "vresponse");
    //       this.cancelModel();

    //       // this.add_model = false;
    //       // this.addFields = true;
    //     },
    cancelModel() {
      this.add_model = false;
    },
    cancelAddfiled() {
      this.addFieldsObject = {
        priority: 0,
        name: "",
        description: "",
        mandatory: false,
        "@type": "",
        configurable: false,
        characteristicSpecification: [{ value: "", default: false }],
      };
      this.drawer = false;
      this.editButton = false;
      this.isModal = false;
      this.configurableValue = false;
      this.componentkeyselectr += 1;
      this.componentkeyselecta += 1;
      this.componentkeyselectt += 1;
      this.componentkeyselects += 1;
      this.componentKeyM += 1;
      this.attachmnetComponetKey += 1;
      this.addresscomponetKey += 1;

      //   this.componentkeyselect += 1;
    },
  },
};
</script>

<style lang="scss">
.overflow_scroll_invisble {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.overflow_scroll_invisble::-webkit-scrollbar {
  display: none;
}
</style>
