<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div class="add-schema-modal">

    <main class="--layout-2-way">
      <!-- Left panel -->
      <section class="--layout-inner-left-portion --LL30">
        <!-- Breadcrumb -->
        <div class="layout-breadcrumb">
          <hlx-breadcrumb :items="items" @path="pathTo" />
        </div>
        <div class="schema-info --info-left-panel">
          <!-- Title -->
          <span class="schema-title -info-title">Add Schema
            <!-- <p class="schema-subtitle -info-sub-title">
            Name your schema and provide a description.
          </p> -->
          </span>

          <!-- schema name -->
          <div class="schema-name-wrapper">
            <hlx-input
v-model:value="name" :label-value="'Name'" :label-animation="true" :required="true"
              :display-error="namecustomerror || isSchemaExists" :custom-error="namecustomerror || isSchemaExists"
              :custom-error-message="isSchemaExists ? 'Schema name already exists' : 'Please fill the field'"
              @at-input="checkIfSchemaExists"></hlx-input>
          </div>

          <!-- schema description -->
          <div class="schema-description-wrapper">
            <hlx-input
v-model:value="description" :pre-val="description" :label-animation="true"
              label-value="Description" type="textarea" :clearable="true" />
          </div>
        </div>
      </section>
      <!-- Right panel -->
      <section class="schema-view --layout-inner-right-portion --RL70" style="padding: 2.3em 43px">
        <div class="panel-layout-body">
          <div class="source-area grid">
            <span class="--right-panel-title">Type</span>
            <hlx-switch
v-model="selectedValue" :switch-items="switch_items" value-prop="value" display-prop="name"
              @change="chosen" />
          </div>
          <div class="design-area">
            <!-- {{this.tableData}} -->
            <p class="--right-panel-title --morgin-bottom">Model</p>

            <div v-if="manual === true" class="schema-table-view">
              <div class="schema-heads">
                <span
v-for="(i, index) in theads" :key="index" class="attribute-headers"
                  :class="{ '--center': i.label == 'Required' }">
                  {{ i.label }}
                </span>
              </div>

              <div v-for="(i, index) in tableData" :key="index" class="schema-wrap-main">
                <section class="schema-wrap">
                  <div class="schema-cover">
                    <i v-show="contextHlx != index" class="icon-angle-right-regular" @click="toggleIcon(index, i)"></i>
                    <i v-show="contextHlx == index" class="icon-angle-down-regular" @click="toggleUp()"></i>

                    <!-- style="margin-right: 10px" -->

                    <section class="schema-row-body-wrap">
                      <div
v-for="(j, col_index) in theads" :key="col_index"
                        :class="{ '--center': j.prop == 'required' }">
                        <div v-if="j.prop === 'schema'">
                          <hlx-input
v-model:value="i[j.prop]" :label-animation="false" :pre-val="i[j.prop]" type="text"
                            :clearable="false" />
                        </div>
                        <span v-if="j.prop === 'type'">
                          <hlx-select
v-model:value="i[j.prop]"
:pre-value="i[j.prop]"
 :options="data1" :placeholder-value="''"
                            :label-animation="true" :inline-search="true" :name="'name'" :value="'value'"
                            @custom-change="checkval($event, index)"></hlx-select>
                        </span>
                        <!-- <span v-if="j.prop === 'list'"> <hlx-input :checked="i[j.prop]" :type="'single-checkbox'" v-model:value="i[j.prop]" /> </span> -->
                        <span v-if="j.prop === 'required'">
                          <hlx-input v-model:value="i[j.prop]" :checked="i[j.prop]" :type="'single-checkbox'" />
                        </span>
                        <span v-if="j.prop === 'reference'">
                          <!-- {{"i.reference.refConfig.name"+i.reference.refConfig.name}} -->
                          <hlx-select
v-if="i['type'] === 'Array' || i['type'] === 'Object'"
                            v-model:value="i['reference']['refConfig']['name']" :pre-value="i['reference']['refConfig']['name']" :options="allref" :label="'name'"
                            :prop-value="'name'" :width-container="'250'" :placeholder-value="'Reference'"
                            :label-animation="false" :inline-search="true" :footer="true"
                            :footer-icon="'icon-plus-circle-regular'" :footer-content="'Add schema'"
                            @selected-value="optionSelected($event, index)" @footer-click="addSchema(index)">
                          </hlx-select>
                          <!-- <hlx-button
                                      class="link"
                                      v-if="i['type'] === 'Array'"
                                      style="position: relative"
                                      @click="showAddContextMenu"
                                      >{{ i.reference }}
                                      <hlx-context-menu
                                        :options="this.options"
                                        @chosen="chosed($event, index)"
                                        :show="this.show_add_menu"
                                    /></hlx-button> -->
                          <span v-else class="na">NA</span>
                        </span>
                      </div>
                    </section>
                  </div>
                  <div v-show="contextHlx == index" class="reference-mapping-section">
                    <hlx-table
:column-count="headers" :border="['table', 'header', 'vertical', 'horizontal']"
                      :bold-headers="false" :row-hover="false" theme="grey" :striped-rows="false"
                      :re-arrange-columns="re_arrange" :re-arrange-heads="theads" @close-rearrange="arrange"
                      @apply-rearranged-columns="applyColumns">
                      <template #thead>
                        <hlx-table-head :width="60">{{ "S.no" }}</hlx-table-head>
                        <hlx-table-head
v-for="(i, index) in Theaders" :key="index" :prop="i.prop"
                          :sortable="i.sortable" :resizable="i.resizable" :width="185" @sorting-func="sorting_Data"
                          @resizewidth="resizewidthdata">
                          {{ i.label }}
                        </hlx-table-head>
                        <hlx-table-head :align="'center'" :width="70" :fixed="'right'"><span class="action-icon"><i
                              class="icon-settings-regular" @click="arrange(true)"></i></span></hlx-table-head>
                      </template>
                      <template #tbody>
                        <tr v-for="(i, index) in paginatedData" id="" :key="index">
                          <hlx-table-cell>{{
            serialNumber(index)
          }}</hlx-table-cell>
                          <hlx-table-cell v-for="(j, col_index) in Theaders" :key="col_index" :align="j.align">
                            <section class="label-container" style="display: flex; align-items: center; gap: 3px">
                              <!-- {{j.align}} -->
                              <!-- {{j.type}} -->
                              <div v-if="j.type === 'String'" style="width: 100%; cursor: pointer; width: 150px">
                                {{
            i[j.prop] != undefined && i[j.prop] != undefined
              ? i[j.prop]
              : "-"
          }}
                              </div>
                              <div v-else-if="j.type === 'Array'" class="link-name">
                                {{ i[j.prop].length }}
                              </div>
                              <div v-else-if="j.type === 'Number'" style="width: 100%; cursor: pointer; width: 150px">
                                {{
            i[j.prop] != undefined && i[j.prop] != undefined
              ? i[j.prop]
              : "-"
          }}
                              </div>
                              <div
v-else-if="j.type === 'Object'" class="link-name"
                                style="width: 100%; cursor: pointer; width: 150px">
                                {{ j.label }}
                              </div>
                              <div v-else-if="j.type === 'Date'" style="width: 100%; cursor: pointer; width: 150px">
                                {{
            i[j.prop] != undefined && i[j.prop] != undefined
              ? i[j.prop]
              : "-"

          }}
                              </div>
                              <div v-else-if="j.type === 'Boolean'" style="width: 100%; cursor: pointer; width: 150px">
                                {{
            i[j.prop] != undefined && i[j.prop] != undefined
              ? i[j.prop]
              : "-"
          }}
                              </div>
                              <div v-else style="width: 100%; cursor: pointer; width: 150px">
                                {{
              i[j.prop] != undefined && i[j.prop] != undefined
                ? i[j.prop]
                : "-"
            }}
                              </div>
                              <span
v-show="col_index == 0 &&
            JSON.stringify(datadefault.name) ==
            JSON.stringify(i)
            "><hlx-label class="primary sm">Default</hlx-label></span>
                            </section>
                          </hlx-table-cell>
                          <hlx-table-cell>
                            <i class="icon-more-vertical-regular" @click.self="crudContextmenu(index)">
                              <hlx-context-menu
:options="crudoptions" :show="contextIndex == index"
                                @chosen="closeCrudContextMenu($event, i, index)" /></i>
                          </hlx-table-cell>
                        </tr>
                      </template>
                    </hlx-table>

                    <div class="pagination-container">
                      <hlx-pagination
v-model:rows-per-page="externalRowsPerPage" :pager-count="10" rows-per-page
                        enable-rows-per-page :rows-per-page-list="[5, 10, 15]" :total="refTable.length"
                        @current-page="currentPage" @updated:rows-per-page="changeRowsPerPage"></hlx-pagination>
                    </div>
                  </div>
                </section>
                <span v-if="!show_view" class="manual-add-delete">
                  <i
  v-if="(i['type'] === 'Array' || i['type'] === 'Object')"
  class="icon-settings-regular"
  :style="{ marginRight: '10px', fontSize: '20px', cursor: 'pointer', color: ShowSettingIcon[index] ? 'black' : 'lightgray' }"
  @click="ShowSettingIcon[index] ? refer(index) : null">
</i>

                  <i
class="icon-plus-circle-filled" style="margin-right: 10px; font-size: 20px"
                    @click="addschemabuilder"></i>
                  <i
v-if="tableData.length > 1" class="icon-trash-regular" style="font-size: 20px"
                    @click="deleteschemabuilder(index)"></i>
                </span>
              </div>

              <!-- * upload -->
              <div v-if="upload === true" class="grid-local-new-schema-right-container">
                <hlx-file-upload
:file-type="'.xlsx,.png,.json'" :file-size-limit="'20mb'" :custom-icon="icon"
                  :type="'regular'" @file-data="handleUploadedSchema" />
              </div>
              <!-- upload * -->
              <span v-if="api == true" class="api-data-content">
                <div class="method-url">
                  <span class="method">
                    <hlx-select :options="method_options"></hlx-select>
                  </span>
                  <span class="url">
                    <hlx-input type="text" :label-value="'Enter request URL'"></hlx-input>
                  </span>
                  <span class="schedule">
                    <div class="choose-schedule">
                      <i class="icon-clock-regular"></i>
                    </div>
                  </span>
                </div>
              </span>
            </div>
            <!-- * upload -->
            <div v-if="upload === true" class="grid-local-new-schema-right-container">
              <hlx-file-upload
:file-type="'.xlsx,.png,.json'" :file-size-limit="'20mb'" :custom-icon="icon"
                :type="'regular'" @file-data="handleUploadedSchema" />
            </div>
          </div>
          <div v-if="api == true" class="grid">
            <span class="titles">URL parameters <span class="info"></span></span>
            <span class="api-data-content">
              <div class="params">
                <div v-for="(param, index) in add_payload.params" :key="index" class="param-object">
                  <span class="key">
                    <hlx-input
:label-value="'Key'" :pre-val="add_payload.params[index].key"
                      @at-input="paramKey($event, index, 'key')"></hlx-input>
                  </span>
                  <span class="value"><hlx-input
:label-value="'Value'" :pre-val="add_payload.params[index].value"
                      @at-input="paramKey($event, index, 'value')"></hlx-input></span>
                  <span><i class="icon-plus-circle-filled" @click="addParamRow"></i></span>
                  <span class="delete-param"><i
v-if="add_payload.params.length > 1" class="icon-trash-filled"
                      @click="deleteParamRow(index)"></i></span>
                  <span class="delete-param"><i
v-if="add_payload.params.length > 1" class="icon-trash-regular"
                      @click="deleteParamRow(index)"></i></span>
                </div>
              </div>
            </span>
          </div>
          <div v-if="api == true" class="grid">
            <span class="titles">Headers <span class="info"></span></span>
            <span class="api-data-content">
              <div class="headers">
                <div v-for="(header, index) in add_payload.headers" :key="index" class="header-object">
                  <span class="key">
                    <hlx-input
:label-value="'Key'" :pre-val="add_payload.headers[index].key"
                      @at-input="headerKey($event, index, 'key')"></hlx-input>
                  </span>
                  <span class="value"><hlx-input
:label-value="'Value'" :pre-val="add_payload.headers[index].value"
                      @at-input="headerKey($event, index, 'value')"></hlx-input></span>
                  <span><i class="icon-plus-circle-filled" @click="addHeaderRow"></i></span>
                  <span class="delete-header"><i
v-if="add_payload.headers.length > 1" class="icon-trash-filled"
                      @click="deleteHeaderRow(index)"></i></span>
                </div>
              </div>
            </span>
          </div>
          <div v-if="api == true" class="grid">
            <span class="titles">Authorization <span class="info"></span></span>
            <span class="api-data-content-auth">
              <div class="auth">
                <div class="auth-object">
                  <hlx-switch :type="'regular'" :switch-items="auth_switch_items" @chosen="authSelected" />
                </div>
              </div>
              <div v-if="add_payload.auth === 'Basic auth'" class="auth-cred">
                <div class="auth-cred-items">
                  <span class="key"><hlx-input
v-model:value="add_payload.credential.username"
                      :label-value="'Username'"></hlx-input></span>
                  <span class="value"><hlx-input
v-model:value="add_payload.credential.password"
                      :label-value="'Password'"></hlx-input></span>
                </div>
              </div>
              <div v-if="add_payload.auth === 'Bearer token'" class="auth-cred">
                <div class="auth-cred-items">
                  <span class="token"><hlx-input
v-model:value="add_payload.credential.token"
                      :label-value="'Token'"></hlx-input></span>
                </div>
              </div>
              <div v-if="add_payload.auth === 'API key'" class="auth-cred">
                <div class="auth-cred-items">
                  <span class="api-key"><hlx-input
v-model:value="add_payload.credential.apikey"
                      :label-value="'API key'"></hlx-input></span>
                </div>
              </div>
            </span>
          </div>
          <div v-if="api == true" class="divider">
            <hr />
          </div>
          <div v-if="api == true" class="grid">
            <span class="titles">Request body
              <span class="info">Define data in JSON format</span></span>
            <span class="api-data-content-text">
              <hlx-code-editor :line-number="true" :beautify="true" @code-changes="bodyPayload"></hlx-code-editor>
              <div class="send-api">
                <hlx-button class="primary sm" @click="sendAPIData">Send</hlx-button>
              </div>
            </span>
          </div>
          <div v-if="api == true && response == true" class="grid">
            <span class="titles">Response <span class="info"></span></span>
            <span v-if="api == true && response == true" class="api-data-content-text">
              <div class="status">
                <span class="title">Status: </span>
                <span class="value">{{ response_status }}</span>
                <span class="title">Time: </span>
                <span class="value">{{ response_time }}</span>
              </div>
              <div class="response-result"></div>
            </span>
          </div>
        </div>
      </section>
    </main>
    <!-- @Footer -->
    <div class="--layout-footer">
      <hlx-button class="secondary-success sm cancel-button" @click="cancel()">Cancel</hlx-button>
      <hlx-button class="primary sm" :disabled="!name || isSchemaExists" @click="saveSchema()">Add</hlx-button>
    </div>
    <div class="reference-list">
      <hlx-modal :modal-active="addrefer" :height="'290px !important'" :width="'320px !important'" :header-content="true" :close-icon="false">
        <template #header>
          <div>Reference name - {{ schemaName }}</div>
        </template>
        <template #content>
          <div class="modal-content">
            <div class="modal-area">
              <div class="label-1">
                <span class="right"><hlx-multi-select
:options="namedRef" :pre-value="refconfigdata"
                    :placeholder="'Properties'" :label-animation="true" :label="'name'" :prop-value="'value'"
                    :disabled="false" @selected-array="emitdata"></hlx-multi-select></span>
              </div>
              <div class="classbutton1">
                <hlx-button class="primary sm" @click="saveRefer">Save</hlx-button>
              </div>
              <div class="classbutton1">
                <hlx-button class="secondary sm" @click="cancelRefer">Cancel</hlx-button>
              </div>
            </div>
          </div>
        </template>
      </hlx-modal>
    </div>
    <div class="reference-list1">
      <hlx-modal
:modal-active="addrefer1" :height="'290px'" :width="'320px'" :header-content="true"
        :close-icon="false">
        <template #header>
          <!-- <p>sss</p> -->
          <div>Reference name - {{ schemaName }}</div>
        </template>
        <template #content>
          <div class="modal-content">
            <div class="modal-area">
              <div class="label-1">
                <span class="right"><hlx-multi-select
:options="namedRef" :pre-value="refconfigdata1"
                    :placeholder="'Properties'" :label-animation="true" :label="'name'" :prop-value="'value'"
                    :disabled="false" @selected-array="emitsubdata"></hlx-multi-select></span>
              </div>
              <div class="classbutton1">
                <hlx-button class="primary sm" @click="savesubRefer">Save</hlx-button>
              </div>
              <div class="classbutton1">
                <hlx-button class="secondary sm" @click="cancelsubRefer">Cancel</hlx-button>
              </div>
            </div>
          </div>
        </template>
      </hlx-modal>
    </div>

    <!-- Additional components -->
    <hlx-modal
:modal-active="referenceSchema" :width="'1100px'" :height="'600px'" :modal="true"
      @close="referenceSchema = false">
      <template #header>
        <div>Add Schema</div>
      </template>
      <template #content>
        <div class="modal-content">
          <main class="--layout-2-way">
            <!-- Left panel -->
            <section class="--layout-inner-left-portion --LL30">
              <!-- Breadcrumb -->
              <!-- <div class="layout-breadcrumb">
        <hlx-breadcrumb :items="items" @path="pathTo" />
      </div> -->
              <div class="schema-info --info-left-panel">
                <!-- Title -->
                <span class="schema-title -info-title">Add Schema
                  <!-- <p class="schema-subtitle -info-sub-title">
            Name your schema and provide a description.
          </p> -->
                </span>

                <!-- schema name -->
                <div class="schema-name-wrapper">
                  <hlx-input
v-model:value="name1" :pre-val="name1" :label-value="'Name'" :label-animation="true"
                    :required="true" :display-error="namecustomerror" :custom-error="namecustomerror"
                    :custom-error-message="'Please fill the field'"></hlx-input>
                </div>

                <!-- schema description -->
                <div class="schema-description-wrapper">
                  <hlx-input
v-model:value="description1" :pre-val="description1" :label-animation="true"
                    label-value="Description" type="textarea" :clearable="true" />
                </div>
              </div>
            </section>
            <!-- Right panel -->
            <section class="schema-view --layout-inner-right-portion --RL70">
              <div class="panel-layout-body">
                <div class="source-area grid">
                  <span class="--right-panel-title">Type</span>
                  <hlx-switch
v-model="selectedValue" :switch-items="switch_items1" value-prop="value"
                    display-prop="name" @change="chosen" />
                </div>
                <div class="design-area">
                  <!-- {{this.tableData}} -->
                  <p class="--right-panel-title --morgin-bottom">Model</p>

                  <span class="grid-local-new-schema-right manual-schema-view">
                    <div v-if="manual === true && sidebaropen === true" class="grid-local-new-schema-right-container">
                      <div class="grid-local-new-schema-right-container-head">
                        <span
v-for="(i, index) in theads" :key="index" class="schema-view-manual-heads"
                          :class="'--' + i.label">
                          <span v-if="i.prop === 'schema'">
                            {{ i.label }}
                          </span>
                          <span v-if="i.prop === 'type'">{{
            i.label
          }}</span>
                          <span v-if="i.prop === 'required'" class="--required">{{
            i.label
          }}</span>
                          <span v-if="i.prop === 'reference'">{{
            i.label
          }}</span>
                          <span v-if="i.prop === 'dependency_tree'">{{ i.label }}</span>
                        </span>
                      </div>
                      <div class="grid-local-new-schema-right-container-content --view-schema">
                        <span v-for="(i, index) in tableData1" :key="index" class="schema-view-manual-body">
                          <!-- style="margin-right: 10px" -->
                          <span
v-for="(j, col_index) in theads" :key="col_index"
                            :class="{ '--required': j.prop == 'required' }">
                            <span>
                              <div v-if="j.prop === 'schema'">
                                <hlx-input
v-model:value="i[j.prop]" :label-animation="false" :pre-val="i[j.prop]"
                                  type="text" :clearable="false" />
                              </div>
                              <span v-if="j.prop === 'type'">
                                <hlx-select
v-model:value="i[j.prop]" :pre-value="i[j.prop]" :options="data1" :placeholder-value="''"
                                  :label-animation="true" :inline-search="true" :name="'name'" :value="'value'"
                                  @custom-change="checkval1($event, index)"></hlx-select>
                              </span>
                              <!-- <span v-if="j.prop === 'list'"> <hlx-input :checked="i[j.prop]" :type="'single-checkbox'" v-model:value="i[j.prop]" /> </span> -->
                              <span v-if="j.prop === 'required'">
                                <hlx-input v-model:value="i[j.prop]" :checked="i[j.prop]" :type="'single-checkbox'" />
                              </span>
                              <span v-if="j.prop.toLowerCase() === 'reference'">
                                <hlx-select
v-if="i['type'] === 'Array' || i['type'] === 'Object'"
                                  v-model:value="i['reference']['refConfig']['name']" :pre-value="i['reference']['refConfig']['name']" :options="allref" :label="'name'"
                                  :prop-value="'name'" :placeholder-value="'Reference'" :width-container="'250'"
                                  :label-animation="false" :inline-search="true"
                                  @selected-value="optionSelected1($event, index)">
                                </hlx-select>
                                <!-- <hlx-select
                          v-if="i['type'] === 'Array' || i['type'] === 'Object'"
                          :options="allref"
                          :placeholder-value="'Reference'"
                          :pre-value="i['reference']['refConfig']['name']"
                          :width-container="'250'"
                          :label-animation="false"
                          :inline-search="false"
                          :footer="false"
                          @selected-value="optionSelected1($event, index)"
                        > -->

                                <!-- </hlx-select> -->
                                <!-- <hlx-button
                              class="link"
                              v-if="i['type'] === 'Array'"
                              style="position: relative"
                              @click="showAddContextMenu"
                              >{{ i.reference }}
                              <hlx-context-menu
                                :options="this.options"
                                @chosen="chosed($event, index)"
                                :show="this.show_add_menu"
                            /></hlx-button> -->
                                <span v-else class="na">NA</span>
                              </span>

                            </span>
                          </span>

                          <span v-if="!show_view" class="manual-add-delete">
                            <div>
                              <i
v-if="i['type'] === 'Array' || i['type'] === 'Object'" class="icon-settings-regular"
                                style="margin-right: 10px; font-size: 20px; cursor: pointer"
                                @click="subrefer(index)"></i>
                            </div>
                            <div>
                              <i
class="icon-plus-circle-filled" style="margin-right: 10px; font-size: 20px"
                                @click="addsubschemabuilder"></i>
                            </div>
                            <div>
                              <i
v-if="tableData1.length > 1" class="icon-trash-regular" style="font-size: 20px"
                                @click="deletesubschemabuilder(index)"></i>
                            </div>
                          </span>
                        </span>
                      </div>
                    </div>

                    <!-- * upload -->
                    <div v-if="upload === true" class="grid-local-new-schema-right-container">
                      <!-- <hlx-file-upload
                :file-type="'.xlsx,.png,.json'"
                :file-size-limit="'20mb'"
                :custom-icon="icon"
                :type="'regular'"
                @file-data="handleUploadedSchema"
              /> -->
                    </div>
                    <!-- upload * -->
                    <span v-if="api == true" class="api-data-content">
                      <div class="method-url">
                        <span class="method">
                          <hlx-select :options="method_options"></hlx-select>
                        </span>
                        <span class="url">
                          <hlx-input type="text" :label-value="'Enter request URL'"></hlx-input>
                        </span>
                        <span class="schedule">
                          <div class="choose-schedule">
                            <i class="icon-clock-regular"></i>
                          </div>
                        </span>
                      </div>
                    </span>
                  </span>
                  <!-- * upload -->
                </div>
                <div v-if="api == true" class="grid">
                  <span class="titles">URL parameters <span class="info"></span></span>
                  <span class="api-data-content">
                    <div class="params">
                      <div v-for="(param, index) in add_payload.params" :key="index" class="param-object">
                        <span class="key">
                          <hlx-input
:label-value="'Key'" :pre-val="add_payload.params[index].key"
                            @at-input="paramKey($event, index, 'key')"></hlx-input>
                        </span>
                        <span class="value"><hlx-input
:label-value="'Value'" :pre-val="add_payload.params[index].value"
                            @at-input="paramKey($event, index, 'value')"></hlx-input></span>
                        <span><i class="icon-plus-circle-filled" @click="addParamRow"></i></span>
                        <span class="delete-param"><i
v-if="add_payload.params.length > 1" class="icon-trash-filled"
                            @click="deleteParamRow(index)"></i></span>
                        <span class="delete-param"><i
v-if="add_payload.params.length > 1" class="icon-trash-regular"
                            @click="deleteParamRow(index)"></i></span>
                      </div>
                    </div>
                  </span>
                </div>
                <div v-if="api == true" class="grid">
                  <span class="titles">Headers <span class="info"></span></span>
                  <span class="api-data-content">
                    <div class="headers">
                      <div v-for="(header, index) in add_payload.headers" :key="index" class="header-object">
                        <span class="key">
                          <hlx-input
:label-value="'Key'" :pre-val="add_payload.headers[index].key"
                            @at-input="headerKey($event, index, 'key')"></hlx-input>
                        </span>
                        <span class="value"><hlx-input
:label-value="'Value'"
                            :pre-val="add_payload.headers[index].value"
                            @at-input="headerKey($event, index, 'value')"></hlx-input></span>
                        <span><i class="icon-plus-circle-filled" @click="addHeaderRow"></i></span>
                        <span class="delete-header"><i
v-if="add_payload.headers.length > 1" class="icon-trash-filled"
                            @click="deleteHeaderRow(index)"></i></span>
                      </div>
                    </div>
                  </span>
                </div>
                <div v-if="api == true" class="grid">
                  <span class="titles">Authorization <span class="info"></span></span>
                  <span class="api-data-content-auth">
                    <div class="auth">
                      <div class="auth-object">
                        <hlx-switch :type="'regular'" :switch-items="auth_switch_items" @chosen="authSelected" />
                      </div>
                    </div>
                    <div v-if="add_payload.auth === 'Basic auth'" class="auth-cred">
                      <div class="auth-cred-items">
                        <span class="key"><hlx-input
v-model:value="add_payload.credential.username"
                            :label-value="'Username'"></hlx-input></span>
                        <span class="value"><hlx-input
v-model:value="add_payload.credential.password"
                            :label-value="'Password'"></hlx-input></span>
                      </div>
                    </div>
                    <div v-if="add_payload.auth === 'Bearer token'" class="auth-cred">
                      <div class="auth-cred-items">
                        <span class="token"><hlx-input
v-model:value="add_payload.credential.token"
                            :label-value="'Token'"></hlx-input></span>
                      </div>
                    </div>
                    <div v-if="add_payload.auth === 'API key'" class="auth-cred">
                      <div class="auth-cred-items">
                        <span class="api-key"><hlx-input
v-model:value="add_payload.credential.apikey"
                            :label-value="'API key'"></hlx-input></span>
                      </div>
                    </div>
                  </span>
                </div>
                <div v-if="api == true" class="divider">
                  <hr />
                </div>
                <div v-if="api == true" class="grid">
                  <span class="titles">Request body
                    <span class="info">Define data in JSON format</span></span>
                  <span class="api-data-content-text">
                    <hlx-code-editor :line-number="true" :beautify="true" @code-changes="bodyPayload"></hlx-code-editor>
                    <div class="send-api">
                      <hlx-button class="primary sm" @click="sendAPIData">Send</hlx-button>
                    </div>
                  </span>
                </div>
                <div v-if="api == true && response == true" class="grid">
                  <span class="titles">Response <span class="info"></span></span>
                  <span v-if="api == true && response == true" class="api-data-content-text">
                    <div class="status">
                      <span class="title">Status: </span>
                      <span class="value">{{ response_status }}</span>
                      <span class="title">Time: </span>
                      <span class="value">{{ response_time }}</span>
                    </div>
                    <div class="response-result"></div>
                  </span>
                </div>
              </div>


            </section>

          </main>
        </div>
      </template>
      <template #footer>
        <hlx-button class="primary sm" @click="saveReferSchema()">Add</hlx-button>
      </template>
    </hlx-modal>
  </div>
  <hlx-modal
:modal-active="deletedialog" :height="'200px'" :width="'450px'" :modal="true"
    @close="deletedialog = false">
    <template #header>
      <div>Delete</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete the <b>{{ deletename }}?</b>
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary-success sm" @click="deletedialog = false">Cancel</hlx-button>
      <hlx-button class="primary sm deletedialogok" @click="deleteokmodal">Delete</hlx-button>
    </template>
  </hlx-modal>
  <hlx-modal
:modal-active="modal_active1" :height="'350px'" :width="'270px'" :modal="true"
    @close="modal_active1 = false">
    <template #header>
      <div>Edit data</div>
    </template>
    <template #content>
      <div class="modal-content1">
        <div class="add-data-modal" style="gap: 12px; margin-top: 10px">
          <span v-for="(i, index) in headers" :key="index" class="add-data-model-container">
            <!-- <span
                  v-if="
                    i.type === 'Number' ||
                    i.type === 'String' ||
                    i.type === 'Date' ||
                    i.type === 'Boolean'
                  "
                  class="add-data-model-container-left"
                  >                  <span v-if="i.required === true">
                    {{i.label.charAt(0).toUpperCase() + i.label.slice(1) }} <span style="color:#ff5050">*</span>
                    </span>
                    <span v-else>
                    {{ i.label.charAt(0).toUpperCase() + i.label.slice(1)}}
                    </span></span
                > -->

            <span v-if="i.type === 'String'" class="add-data-model-container-right">
              <!-- {{this.adddataobj[i.prop]}} -->
              <hlx-input
v-model:value="editdataobj[i.prop]" :label-animation="true" type="text"
                :pre-val="editdataobj[i.prop]" :label-value="i.label.charAt(0).toUpperCase() + i.label.slice(1)
            " :display-error="errorvalidation[i.prop]" :custom-error="errorvalidation[i.prop]"
                :custom-error-message="'Please fill the field'" /></span>
            <span v-if="i.type === 'Number'" class="add-data-model-container-right">
              <!-- {{i.prop}} -->
              <hlx-input
v-model:value="editdataobj[i.prop]" :label-animation="false" type="number" label-value=""
                :clearable="false" :pre-val="editdataobj[i.prop]" :display-error="errorvalidation[i.prop]"
                :custom-error="errorvalidation[i.prop]" :custom-error-message="'Please fill the field'" />
            </span>
            <span v-if="i.type === 'Date'" class="add-data-model-container-right">
              <hlx-input
v-model:value="editdataobj[i.prop]" :label-animation="false" :label-value="'DD/MM/YYYY'"
                type="text" :pre-val="editdataobj[i.prop]" :display-error="errorvalidation[i.prop]"
                :custom-error="errorvalidation[i.prop]" :custom-error-message="'Please fill the field'" />
            </span>
            <span v-if="i.type === 'Boolean'" class="add-data-model-container-right-radio">
              <hlx-input :options="options3" :type="'radio-buttons'" />
            </span>
          </span>
        </div>
      </div>
    </template>
    <template #footer>
      <span class="footer-button" style="gap: 15px; width: 100%">
        <hlx-button
class="secondary-success sm clear-button" style="width: 100%"
          @click="clearsavedata">Cancel</hlx-button>
        <hlx-button class="primary sm" style="width: 100%" @click="saveeditdata">Edit</hlx-button>
      </span>
    </template>
  </hlx-modal>
  <hlx-modal
:modal-active="modal_active" :height="'350px'" :width="'270px'" :modal="true"
    @close="modal_active = false">
    <template #header>
      <div>Add data</div>
    </template>
    <template #content>
      <div class="modal-content1">
        <div class="add-data-modal" style="gap: 12px; margin-top: 10px">
          <span v-for="(i, index) in headers" :key="index" class="add-data-model-container">
            <!-- <span
                  v-if="
                    i.type === 'Number' ||
                    i.type === 'String' ||
                    i.type === 'Date' ||
                    i.type === 'Boolean'
                  "
                  class="add-data-model-container-left"
                  >                  <span v-if="i.required === true">
                    {{i.label.charAt(0).toUpperCase() + i.label.slice(1) }} <span style="color:#ff5050">*</span>
                    </span>
                    <span v-else>
                    {{ i.label.charAt(0).toUpperCase() + i.label.slice(1)}}
                    </span></span
                > -->

            <span v-if="i.type === 'String'" class="add-data-model-container-right">
              <!-- {{this.adddataobj[i.prop]}} -->
              <hlx-input
v-model:value="adddataobj[i.prop]" :label-animation="true" type="text"
                :pre-val="adddataobj[i.prop]" :label-value="i.label.charAt(0).toUpperCase() + i.label.slice(1)
            " :display-error="errorvalidation[i.prop]" :custom-error="errorvalidation[i.prop]"
                :custom-error-message="'Please fill the field'" /></span>
            <span v-if="i.type === 'Number'" class="add-data-model-container-right">
              <!-- {{i.prop}} -->
              <hlx-input
v-model:value="adddataobj[i.prop]" :label-animation="false" type="number" label-value=""
                :clearable="false" :pre-val="adddataobj[i.prop]" :display-error="errorvalidation[i.prop]"
                :custom-error="errorvalidation[i.prop]" :custom-error-message="'Please fill the field'" />
            </span>
            <span v-if="i.type === 'Date'" class="add-data-model-container-right">
              <hlx-input
v-model:value="adddataobj[i.prop]" :label-animation="false" :label-value="'DD/MM/YYYY'"
                type="text" :pre-val="adddataobj[i.prop]" :display-error="errorvalidation[i.prop]"
                :custom-error="errorvalidation[i.prop]" :custom-error-message="'Please fill the field'" />
            </span>
            <span v-if="i.type === 'Boolean'" class="add-data-model-container-right-radio">
              <hlx-input :options="options3" :type="'radio-buttons'" />
            </span>
          </span>
        </div>
      </div>
    </template>
    <template #footer>
      <span class="footer-button" style="gap: 15px; width: 100%">
        <hlx-button
class="secondary-success sm clear-button" style="width: 100%"
          @click="clearsavedata">Cancel</hlx-button>
        <hlx-button class="primary sm" style="width: 100%" @click="saveadddata">Add</hlx-button>
      </span>
    </template>
  </hlx-modal>

  <discardComponent :show="showAlert" @stay="stayFunction"  @close="showAlert = false" @discard="discardAllChanges"></discardComponent>
  <hlx-alert-notification :show-alert="success" :notify="notification" :auto-close="true" />

</template>

<script>
import { DMNAxiosInstance, MLTAxiosInstance } from "@/config/axiosConfig";
// import axios from "axios";
import discardComponent from '../../components/discardComponent.vue';
import deepdiff from "deep-diff";


export default {
  name: "LocalView",
  components: {
    discardComponent
  },
  beforeRouteLeave(to, from, next) {
    console.log(this.unsavedChanges);
    if (this.unsavedChanges) {
      console.log(this.unsavedChanges);
      this.showAlert = true;
      if (this.showAlert) {
        if (this.canLeave) {
          next();
        } else {
          next(false);
        }
      } else {
        next();
      }
    } else {
      next();
    }
  },
  data() {
    return {
      languages:[
    { code: "ab", name: "Abkhazian" },
    { code: "ace", name: "Achinese" },
    { code: "ach", name: "Acoli" },
    { code: "af", name: "Afrikaans" },
    { code: "ak", name: "Akan" },
    { code: "alz", name: "Alur" },
    { code: "am", name: "Amharic" },
    { code: "ar", name: "Arabic" },
    { code: "as", name: "Assamese" },
    { code: "awa", name: "Awadhi" },
    { code: "ay", name: "Aymara" },
    { code: "az", name: "Azerbaijani" },
    { code: "ba", name: "Bashkir" },
    { code: "ban", name: "Balinese" },
    { code: "bbc", name: "Batak Toba" },
    { code: "be", name: "Belarusian" },
    { code: "bem", name: "Bemba" },
    { code: "bew", name: "Betawi" },
    { code: "bg", name: "Bulgarian" },
    { code: "bho", name: "Bhojpuri" },
    { code: "bik", name: "Bikol" },
    { code: "bm", name: "Bambara" },
    { code: "bn", name: "Bengali" },
    { code: "br", name: "Breton" },
    { code: "bs", name: "Bosnian" },
    { code: "bts", name: "Batak Simalungun" },
    { code: "btx", name: "Batak Karo" },
    { code: "bua", name: "Buryat" },
    { code: "ca", name: "Catalan" },
    { code: "ceb", name: "Cebuano" },
    { code: "cgg", name: "Chiga" },
    { code: "chm", name: "Mari" },
    { code: "ckb", name: "Sorani Kurdish" },
    { code: "cnh", name: "Chin" },
    { code: "co", name: "Corsican" },
    { code: "crh", name: "Crimean Tatar" },
    { code: "crs", name: "Seselwa Creole French" },
    { code: "cs", name: "Czech" },
    { code: "cv", name: "Chuvash" },
    { code: "cy", name: "Welsh" },
    { code: "da", name: "Danish" },
    { code: "de", name: "German" },
    { code: "din", name: "Dinka" },
    { code: "doi", name: "Dogri" },
    { code: "dov", name: "Dombe" },
    { code: "dv", name: "Dhivehi" },
    { code: "dz", name: "Dzongkha" },
    { code: "ee", name: "Ewe" },
    { code: "el", name: "Greek" },
    { code: "en", name: "English" },
    { code: "eo", name: "Esperanto" },
    { code: "es", name: "Spanish" },
    { code: "et", name: "Estonian" },
    { code: "eu", name: "Basque" },
    { code: "fa", name: "Persian" },
    { code: "ff", name: "Fula" },
    { code: "fi", name: "Finnish" },
    { code: "fj", name: "Fijian" },
    { code: "fr", name: "French" },
    { code: "fy", name: "Western Frisian" },
    { code: "ga", name: "Irish" },
    { code: "gaa", name: "Ga" },
    { code: "gd", name: "Scottish Gaelic" },
    { code: "gl", name: "Galician" },
    { code: "gn", name: "Guarani" },
    { code: "gom", name: "Konkani" },
    { code: "gu", name: "Gujarati" },
    { code: "ha", name: "Hausa" },
    { code: "haw", name: "Hawaiian" },
    { code: "he", name: "Hebrew" },
    { code: "hi", name: "Hindi" },
    { code: "hil", name: "Hiligaynon" },
    { code: "hmn", name: "Hmong" },
    { code: "hr", name: "Croatian" },
    { code: "hrx", name: "Hunsrik" },
    { code: "ht", name: "Haitian Creole" },
    { code: "hu", name: "Hungarian" },
    { code: "hy", name: "Armenian" },
    { code: "id", name: "Indonesian" },
    { code: "ig", name: "Igbo" },
    { code: "ilo", name: "Ilocano" },
    { code: "is", name: "Icelandic" },
    { code: "it", name: "Italian" },
    { code: "iw", name: "Hebrew (traditional code 'iw')" },
    { code: "ja", name: "Japanese" },
    { code: "jv", name: "Javanese" },
    { code: "jw", name: "Javanese (different script)" },
    { code: "ka", name: "Georgian" },
    { code: "kk", name: "Kazakh" },
    { code: "km", name: "Khmer" },
    { code: "kn", name: "Kannada" },
    { code: "ko", name: "Korean" },
    { code: "kri", name: "Krio" },
    { code: "ktu", name: "Kanuri" },
    { code: "ku", name: "Kurdish" },
    { code: "ky", name: "Kyrgyz" },
    { code: "la", name: "Latin" },
    { code: "lb", name: "Luxembourgish" },
    { code: "lg", name: "Luganda" },
    { code: "li", name: "Limburgish" },
    { code: "lij", name: "Ligurian" },
    { code: "lmo", name: "Lombard" },
    { code: "ln", name: "Lingala" },
    { code: "lo", name: "Lao" },
    { code: "lt", name: "Lithuanian" },
    { code: "ltg", name: "Latgalian" },
    { code: "luo", name: "Luo" },
    { code: "lus", name: "Mizo" },
    { code: "lv", name: "Latvian" },
    { code: "mai", name: "Maithili" },
    { code: "mak", name: "Makassarese" },
    { code: "mg", name: "Malagasy" },
    { code: "mi", name: "Maori" },
    { code: "min", name: "Minangkabau" },
    { code: "mk", name: "Macedonian" },
    { code: "ml", name: "Malayalam" },
    { code: "mn", name: "Mongolian" },
    { code: "mni-Mtei", name: "Manipuri (Meitei Mayek)" },
    { code: "mr", name: "Marathi" },
    { code: "ms", name: "Malay" },
    { code: "ms-Arab", name: "Malay (Arabic script)" },
    { code: "mt", name: "Maltese" },
    { code: "my", name: "Burmese" },
    { code: "ne", name: "Nepali" },
    { code: "new", name: "Newari" },
    { code: "nl", name: "Dutch" },
    { code: "no", name: "Norwegian" },
    { code: "nr", name: "Ndebele" },
    { code: "nso", name: "Northern Sotho" },
    { code: "nus", name: "Nuer" },
    { code: "ny", name: "Nyanja" },
    { code: "oc", name: "Occitan" },
    { code: "om", name: "Oromo" },
    { code: "or", name: "Odia" },
    { code: "pa", name: "Punjabi" },
    { code: "pa-Arab", name: "Punjabi (Arabic script)" },
    { code: "pag", name: "Pangasinan" },
    { code: "pam", name: "Pampangan" },
    { code: "pap", name: "Papiamento" },
    { code: "pl", name: "Polish" },
    { code: "ps", name: "Pashto" },
    { code: "pt", name: "Portuguese" },
    { code: "qu", name: "Quechua" },
    { code: "rn", name: "Rundi" },
    { code: "ro", name: "Romanian" },
    { code: "rom", name: "Romani" },
    { code: "ru", name: "Russian" },
    { code: "rw", name: "Kinyarwanda" },
    { code: "sa", name: "Sanskrit" },
    { code: "sc", name: "Sicilian" },
    { code: "sd", name: "Sindhi" },
    { code: "sg", name: "Sango" },
    { code: "sh", name: "Shan" },
    { code: "si", name: "Sinhala" },
    { code: "sk", name: "Slovak" },
    { code: "sl", name: "Slovenian" },
    { code: "sm", name: "Samoan" },
    { code: "sn", name: "Shona" },
    { code: "so", name: "Somali" },
    { code: "sq", name: "Albanian" },
    { code: "sr", name: "Serbian" },
    { code: "ss", name: "Swati" },
    { code: "st", name: "Southern Sotho" },
    { code: "su", name: "Sundanese" },
    { code: "sv", name: "Swedish" },
    { code: "sw", name: "Swahili" },
    { code: "szl", name: "Silesian" },
    { code: "ta", name: "Tamil" },
    { code: "te", name: "Telugu" },
    { code: "tet", name: "Tetum" },
    { code: "tg", name: "Tajik" },
    { code: "th", name: "Thai" },
    { code: "ti", name: "Tigrinya" },
    { code: "tk", name: "Turkmen" },
    { code: "tl", name: "Tagalog" },
    { code: "tn", name: "Tswana" },
    { code: "tr", name: "Turkish" },
    { code: "ts", name: "Tsonga" },
    { code: "tt", name: "Tatar" },
    { code: "ug", name: "Uyghur" },
    { code: "uk", name: "Ukrainian" },
    { code: "ur", name: "Urdu" },
    { code: "uz", name: "Uzbek" },
    { code: "vi", name: "Vietnamese" },
    { code: "xh", name: "Xhosa" },
    { code: "yi", name: "Yiddish" },
    { code: "yo", name: "Yoruba" },
    { code: "yua", name: "Yucatec Maya" },
    { code: "yue", name: "Yue Chinese (Cantonese)" },
    { code: "zh", name: "Chinese (generic)" },
    { code: "zh-Hans", name: "Chinese (Simplified)" },
    { code: "zh-Hant", name: "Chinese (Traditional)" },
    { code: "zu", name: "Zulu" }
],
      ShowSettingIcon:[],
      isSchemaExists: false,
      name: '',
      description: '',
      externalRowsPerPage: 10,
      company: this.$cookies.get("company"),
      deletedialog: false,
      deletename: "",
      deletedataindex: "",
      editdataobj: {},
      referencedataid: "",
      adddataobj: {},
      errorvalidation: {},
      modal_active1: false,
      modal_active: false,
      crudoptions: [
        {
          label: "Add",
          icon: "icon-plus-regular"
        },
        {
          label: "Mark as default",
          icon: "icon-file-text-star-filled"
        },
        {
          label: "Edit",
          icon: "icon-edit-regular"
        },
        {
          label: "Delete",
          icon: "icon-trash-regular"
        }
      ],
      refTable: [],
      refconfigdata: [],
      refconfigdata1: [],
      settingsrefernceindex: 0,
      name1: "",
      description1: "",
      referenceSchema: false,
      myObj: {},
      unsavedChanges: false,
      showAlert: false,
      canLeave: false,
      schemaName: "",
      nameSchema: "",
      prevalue: "",
      namecustomerror: false,
      errormessage: "",
      ref: "",
      show_add_menu: false,
      notification: {},
      success: false,
      success_msg: "",
      response: false,
      auth_switch_items: [
        { name: "No auth", value: "No auth" },
        { name: "Basic auth", value: "Basic auth" },
        { name: "Bearer token", value: "Bearer token" },
        { name: "API key", value: "API key" }
      ],
      selectedValue1: { name: "No auth", value: "No auth" },
      response_status: "200 OK",
      response_time: "91ms",
      add_payload: {
        name: "",
        description: "",
        method: "",
        url: "",
        data: [],
        schedule: "",
        params: [{ key: "", value: "" }],
        headers: [{ key: "", value: "" }],
        auth: "",
        credential: {}
      },
      method_options: [
        {
          name: "GET",
          checked: true,
          id: 1
        },
        {
          name: "POST",
          checked: false,
          id: 2
        }
      ],
      upload: false,
      manual: true,
      api: false,
      formData: {},
      items: [{ label: "Schema" }, { label: "Add schema" }],
      data1: [
        { name: "String", value: "String", checked: true },
        { name: "Array", value: "Array", checked: false },
        { name: "Boolean", value: "Boolean", checked: false },
        { name: "Date", value: "Date", checked: false },
        { name: "Object", value: "Object", checked: false },
        { name: "Number", value: "Number", checked: false }
      ],
      switch_items: [
        { name: "Manual", value: "Manual" },
        // { name: "API" },
        { name: "Upload", value: "Upload" }
      ],
      switch_items1: [
        { name: "Manual", value: "Manual" }
        // { name: "API" },
        // { name: "Upload", value: "Upload" }
      ],
      selectedValue: "Manual",
      tableData: [
        {
          schema: "",
          type: "String",
          required: false,
          reference: {
            isRef: false,
            refConfig: {
              name: "",
              fields: []
            }
          }
        }
      ],
      mountedpayload: [
        {
          schema: "",
          type: "String",
          required: false,
          reference: {
            isRef: false,
            refConfig: {
              name: "",
              fields: []
            }
          }
        }
      ],
      tableData1: [],
      theads: [
        {
          name: "Attribute",
          checked: true,
          id: 1,
          disabled: false,
          prop: "schema",
          label: "Attribute",
          sortable: false,
          resizable: false
          // width:250
        },
        {
          name: "Type",
          checked: true,
          id: 1,
          disabled: false,
          prop: "type",
          label: "Type",
          sortable: false,
          resizable: false
          // width:150
        },
        {
          name: "Required",
          checked: true,
          id: 1,
          disabled: false,
          prop: "required",
          label: "Required",
          sortable: false,
          resizable: false
          // width:70
        },
        {
          name: "Reference",
          checked: true,
          id: 1,
          disabled: false,
          prop: "reference",
          label: "Reference",
          sortable: false,
          resizable: false
          // width:70
        }
      ],
      duplicateTheads: [],
      addschemaindex: 0,
      sidebaropen: true,
      sidebarclose: false,
      allref: [],
      selectedOption: false,
      addrefer: false,
      addrefer1: false,
      namedRef: [],
      value: [],
      addschema: {},
      newSchema: true,
      signed: false,
      dataChange: false,
      preReference: [],
      currPage: 1,
      rowsPerPage: 5,
      headervalue: [],
      Theaders: [],
      headers: [],
      crudshow: false,
      contextIndex: -1,
      contextHlx: -1,
      referencedataname: "",
      listVal: "",
      count: 0,
      datadefault: {}
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      console.log(this.refTable, ">?");
      return this.refTable.slice(start, end);
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.refTable.length;
    }
  },
  watch: {
    description: {
      handler(val) {
        let duplicate_name = ''
        val
        var differences = deepdiff(this.description, duplicate_name);
        console.log(differences, 'oa');
        if (differences != undefined) {
          this.unsavedChanges = true;
        } else {
          this.unsavedChanges = false;
        }
      },
      deep: true,
      immediate: true
    },
    name: {
      handler(val) {
        val
        let duplicate_name = ''
        var differences = deepdiff(this.name, duplicate_name);
        console.log(differences, 'oa');
        if (differences != undefined) {
          this.unsavedChanges = true;
        } else {
          this.unsavedChanges = false;
        }
      },
      deep: true,
      immediate: true
    },
    tableData: {
      handler(val) {
        val
        var differences = deepdiff(this.myPayload, this.mountedPayload);
        console.log(differences, 'kdk');
        if (differences != undefined) {
          this.unsavedChanges = true;
        } else {
          this.unsavedChanges = false;
        }
      },
      deep: true,
      immediate: true
    }
  },

  async mounted() {
    const val = await MLTAxiosInstance.get(`partymanagement/individual/auth`);
    this.company = val.data.company;
    this.userId = val.data.company;
    document.addEventListener("click", (e) => {
      //
      if (e.target.className == "toggler") {
        if (e.target.children[0].className === "icon-angle-left-regular") {
          this.sidebaropen = false;
          //
        } else if (
          e.target.children[0].className === "icon-angle-right-regular"
        ) {
          this.sidebaropen = true;
          //
        }
      } else if (
        e.target.className === "icon-angle-left-regular" &&
        e.target.parentElement.className == "toggler"
      ) {
        this.sidebaropen = false;
        //
      } else if (
        e.target.className === "icon-angle-right-regular" &&
        e.target.parentElement.className == "toggler"
      ) {
        this.sidebaropen = true;
        //
      }
    });
    this.$store.commit("token", this.$route.params.token);
    let filterOption = {
      company: this.company,
      "type": "search",
      "module": "schema",
      "collection": "",
      "isPaginated": false,
      "paginatedQuery": {
        "rowsPerPage": 10,
        "currentPage": 1
      },
      "isNeedToBeSort": false,
      "sortFieldAndOrder": { name: 1 },
      "limit": 1000,
      "filterQuery": {},
      "searchQuery": ""
    }
    const allschema = await DMNAxiosInstance.post(`/util/filterData`, filterOption);
    // let allSch = await allschema.data.data.data.map((val) => {
    //   return { ...val, label: val.name, value: val.name, name: val.name };
    // });
    // allSch.sort((a, b) => a.name.localeCompare(b.name));

    let allSch = await allschema.data.data.data
    this.allref = allSch;
    //
    console.log(allSch, "🦋", allschema.data);
    this.options = await allSch;
    // this.options.forEach((e) => {
    //   if (e.name != '') {
    //     e.value = e.name;
    //   }
    // });
  },
  methods: {
    generateLanguageList(params) {
  const result = Object.entries(params)
    .map(([, name]) => {  // Use an empty slot to ignore the first value
      const language = this.languages.find(
        (lang) => lang.name.toLowerCase() === name.toLowerCase()
      );

      // If language is found, return its code and name
      return language ? { code: language.code, name: language.name } : null;
    })
    .filter((item) => item !== null);

  return result;
},
    checkEqual(schema, lang) {
  // Iterate through keys in the `lang` object
  for (let key in lang) {
    // Ensure that `schema` is defined and check if the key exists in `schema`
    if (!schema || !(key in schema)) {
      return false;
    }
  }
  // If all keys in `lang` are in `schema`, return true
  return true;
},
    async langColAddInSchema(obj) {obj
      let obj1 = obj
      //to get language
      let responses24 = await MLTAxiosInstance.get(
      `/partymanagement/organisation/${this.$cookies.get(
        "user"
      )}?company=${this.$cookies.get("company")}`
    );

      // let lang = responses24?.data?.languages;

      // let lang = this.generateLanguageList(responses24?.data?.languageAbility)

      console.log("changed lang code", responses24?.data?.languageAbility);
      
      
      const results = responses24?.data?.languageAbility?.reduce((acc, lang) => {
acc[lang?.name?.toLowerCase()] = { "type": "String", "required": false };
  return acc;
      }, {});

      let langSchema = results;

      console.log("olikumjnyhgbvf",langSchema,obj1)

      let bools = this.checkEqual(obj1[0]?.schema, langSchema)

      console.log("defrgthyjthgrfed....", bools);
      if(!bools){

        console.log("hgbvfbthgvfc2222",langSchema, obj1)
      let newSchem = { ...langSchema, ...obj1[0]?.schema }

      obj1[0].schema = newSchem

      console.log("hgbvfbthgvfc2222 1234567890-=",obj1, newSchem);

      // obj1.reekla = newSchem

      return obj1
    }else{
      return null
    }

      // return obj1
      
    },
    async checkIfSchemaExists() {
      let isExistResponse = await DMNAxiosInstance.get(`/rule_engine/schema/is_exist?name=${this.name}`)
      console.log("🚀 ~ checkIfSchemaExists ~ isExistResponse:", isExistResponse)
      if (isExistResponse.data) {
        this.isSchemaExists = isExistResponse.data.data
      }
    },
    stayFunction() {
      this.canLeave = false;
      this.showAlert = false;
    },
    deletedata(index) {
      this.deletedataindex = index;

      this.deletename = this.refTable[index].name;
      this.referencedataid = this.refTable[index]._id;
      this.deletedialog = true;
    },
    async deleteokmodal() {
      await DMNAxiosInstance.delete(
        `mapRef/remove/${this.referencedataname}/${this.referencedataid}`
      ).then(() => {
        //
        this.refTable.splice(this.deletedataindex, 1);
      });
      this.duplicate_table_data = this.refTable;
      this.deletenotification = true;
      this.deletedialog = false;
      this.success = true;
      this.success_msg = "Data deleted successfully";
      this.notification = { type: "success", message: this.success_msg };
    },
    async saveeditdata() {
      await DMNAxiosInstance.patch(
        `mapRef/updateCollection/${this.referencedataname}/${this.referencedataid}`,
        this.editdataobj
      ).then((res) => {
        //
        this.refTable[this.editmodalindex] = res.data.data;
      });
      this.duplicate_table_data = this.refTable;
      this.editnotification = true;
      this.modal_active1 = false;
      this.crudshow = false;
    },
    editdata(data, index) {
      this.referencedataid = this.refTable[index]._id;
      // this.editdataobj = {}
      // this.editdataobj = JSON.parse(JSON.stringify(this.editdataobj))
      //
      this.headers.forEach((e) => {
        if (["Array", "Object", "Boolean"].includes(e.type) == false) {
          this.editdataobj[e.prop] = data[e.prop];
          // this.editdataobj = data
          // this.editmodaldata = data
        }
      });
      //
      this.modal_active1 = true;
      this.editmodalindex = index;
    },
    async saveadddata() {
      var count = 0;
      var count1 = 0;
      this.headers.forEach((e) => {
        if (e.required == true) {
          count1 = count1 + 1;
        }
      });
      for (let i = 0; i < this.headers.length; i++) {
        //
        if (this.headers[i].required == true) {
          if (this.adddataobj[this.headers[i].prop] == "") {
            this.errorvalidation[this.headers[i].prop] = true;
          } else {
            this.errorvalidation[this.headers[i].prop] = false;
            count++;
          }
        }
      }

      if (count == count1) {
        await DMNAxiosInstance.post(
          `mapRef/${this.referencedataname}`,
          this.adddataobj
        ).then((res) => {
          this.refTable.push(res.data.data);
        });
        this.modal_active = false;
        this.duplicate_table_data = this.refTable;
        this.headers.forEach((e) => {
          if (["Array", "Object", "Boolean"].includes(e.type) == false) {
            delete this.adddataobj[e.prop];
            // this.adddataobj[e.prop] = ''
          }
        });
        //
        // this.adddataobj = {}
        this.addnotification = true;
        this.modal_active = false;
      }
    },
    toggleUp() {
      this.contextHlx = -1;
    },
    async toggleIcon(i, content) {
      if (content.reference.isRef == true) {
        if (i == this.contextHlx) {
          this.contextHlx = -1;
        } else {
          this.contextHlx = i;
          console.log(content, "toggle");
          let val = content.reference.refConfig.name;
          this.listVal = val;
          const getDefault = await DMNAxiosInstance.get(
            `/default/${this.company}/${val}`
          );
          if (getDefault.data && getDefault.data[0]) {
            this.datadefault = getDefault.data[0];
          }
          console.log(getDefault.data, "defaultt");
          this.count = await getDefault.data.length;
          console.log(val, content);
          let res = await DMNAxiosInstance.get(`mapRef/getbyname/${val}`);
          console.log(schemaRes);

          if (res.data.ok == 0) {
            this.headers = [];
          } else {
            this.headers = Object.keys(res.data.data).map((e) => {
              //
              // console.log(schemaRes.data.data[e]);
              let obj = {};
              obj.name = e;
              obj.prop = e;
              obj.label = e;
              obj.required = res.data.data[e].required;
              obj.ref = res.data.data[e].refConfig;
              obj.type = res.data.data[e]?.type;
              obj.sortable = true;
              obj.checked = true;
              obj.disabled = false;
              obj.align = "left";
              return obj;
            });
            this.Theaders = this.headers;
            // console.log(this.duplicateTheads,'pop');
          }
          this.referencedataname = val;
          let schemaRes = await DMNAxiosInstance.get(
            `mapRef/collection/${val}`
          );
          this.refTable = schemaRes.data.data;
          this.Theaders.forEach((e) => {
            if (["Array", "Object", "Boolean"].includes(e.type) == false) {
              this.adddataobj[e.prop] = "";
              this.errorvalidation[e.prop] = false;
            }
          });
        }
      }
    },
    closeCrudContextMenu($event, i, index) {
      this.crudshow = false;
      this.contextIndex = -1;
      console.log($event, i);
      if ($event == "Add") {
        this.modal_active = true;
      } else if ($event == "Edit") {
        // this.modal_active1=true
        this.editdataobj = i;
        this.editdata(i, index);
        this.crudshow = false;
      } else if ($event == "Delete") {
        // this.modal_active1=true
        this.deletedata(index);
        this.crudshow = false;
      } else if ($event == "Mark as default") {
        // this.modal_active1=true

        this.default(i);
        this.crudshow = false;
      } else {
        console.log("else");
      }
    },
    async default(i) {
      console.log(i);
      let source = {
        sourcename: this.listVal,
        name: i
      };
      if (this.count == 0) {
        console.log(source, "sourceee", this.company);
        const add = await DMNAxiosInstance.post(
          `/default/${this.company}`,
          source
        );
        console.log(add.data, "added");
        this.datadefault = add.data;
      } else {
        const add = await DMNAxiosInstance.delete(
          `/default/${this.company}/${this.listVal}`
        );
        console.log(add.data, "deleted");
        const added = await DMNAxiosInstance.post(
          `/default/${this.company}`,
          source
        );
        console.log(added.data, "added");
        this.datadefault = added.data;
      }
    },
    crudContextmenu(i) {
      console.log("setting clicked ");
      this.contextIndex = i;
      this.crudshow = true;
    },
    addSchema(index) {
      this.name1 = "";
      this.description1 = "";
      (this.tableData1 = [
        {
          schema: "",
          type: "String",
          required: false,
          reference: {
            isRef: false,
            refConfig: {
              name: "",
              fields: []
            }
          }
        }
      ]),
        (this.referenceSchema = true);
      this.addschemaindex = index;
    },
    cancel() {
      this.myObj = {
        name: this.name,
        description: this.description,
        schema: this.tableData[0].schema,
        type: this.tableData[0].type
      };
      if (this.name || this.description || (this.tableData.length > 0 && (this.tableData[0].schema))) {
  this.showAlert = true;
} else {
  this.$router.back();
}


      // this.path('Schemas')
    },
    discardAllChanges() {
      const activeSideBarItem = this.$store.getters.getTempActiveSideBarItem;
      // console.log(activeSideBarItem);
      // if (activeSideBarItem !== '') {
      //   if (activeSideBarItem == 'logout') {
      //     this.logout();
      //   } else if (activeSideBarItem == 'Dashboard') {
      //     this.$router.push(`/DMN/dashboard`);
      //     this.$store.commit('setTempActiveSideBarItem', '');
      //   } else if (activeSideBarItem == 'Schema') {
      //     this.$router.push(`/DMN/schemas`);
      //     this.$store.commit('setTempActiveSideBarItem', '');
      //   } else if (activeSideBarItem == 'Help') {
      //     window.open('https://halleyx-documentation-ghubgwxrcq-uc.a.run.app');
      //   } else if (activeSideBarItem == 'Rule studio') {
      //     this.$router.push({
      //       name: 'business-rules',
      //       query: { Value: 'All' },
      //     });
      //     this.$store.commit('setTempActiveSideBarItem', '');
      //   } else if (activeSideBarItem === 'Database') {
      //     this.$router.push(`/DMN/reference_data`);
      //     this.$store.commit('setTempActiveSideBarItem', '');
      //   } else if (activeSideBarItem == 'Execute') {
      //     this.$router.push(`/DMN/rule_validation`);
      //     this.$store.commit('setTempActiveSideBarItem', '');
      //   } else if (activeSideBarItem == 'setting') {
      //     this.$router.push(`/settings`);
      //     this.$store.commit('setTempActiveSideBarItem', '');
      //   } else if (activeSideBarItem == 'Audit') {
      //     this.$router.push(`/DMN/audit`);
      //     this.$store.commit('setTempActiveSideBarItem', '');
      //   } else {
      //     console.log('else', activeSideBarItem);
      //   }
      // } 
      if (activeSideBarItem !== '') {
        if (activeSideBarItem === 'UPC') {
          this.$router.push(`/UPC/dashboard`);
          this.$store.commit('setTempActiveSideBarItem', '');
        } else if (activeSideBarItem === 'Product') {
          this.$router.push(`/UPC/product-catalog-table/product`);
          this.$store.commit('setTempActiveSideBarItem', '');
        } else if (activeSideBarItem === 'Service') {
          this.$router.push(`/UPC/product-catalog-table/service`);
          this.$store.commit('setTempActiveSideBarItem', '');
        } else if (activeSideBarItem === 'Resource') {
          this.$router.push(`/UPC/product-catalog-table/resource`);
          this.$store.commit('setTempActiveSideBarItem', '');
        } else if (activeSideBarItem === 'DMN') {
          this.$router.push(`/DMN/dashboard`);
          this.$store.commit('setTempActiveSideBarItem', '');
        } else if (activeSideBarItem === 'Rule studio') {
          this.$router.push(`/DMN/rule_studio`);
          this.$store.commit('setTempActiveSideBarItem', '');
        } else if (activeSideBarItem === 'Rule validation') {
          this.$router.push(`/DMN/rule_validation`);
          this.$store.commit('setTempActiveSideBarItem', '');
        } else if (activeSideBarItem === 'Audit log') {
          this.$router.push(`/DMN/audit`);
          this.$store.commit('setTempActiveSideBarItem', '');
        } else if (activeSideBarItem === 'Schema') {
          this.$router.push(`/schemas`);
          this.$store.commit('setTempActiveSideBarItem', '');
        } else if (activeSideBarItem === 'Data management') {
          this.$router.push(`/reference_data`);
          this.$store.commit('setTempActiveSideBarItem', '');
        } else if (activeSideBarItem === 'Help') {
          window.open(`https://halleyx-documentation-ghubgwxrcq-uc.a.run.app`);
          this.$store.commit('setTempActiveSideBarItem', '');
        } else if (activeSideBarItem === 'Settings') {
          this.$router.push(`/settings`);
          this.$store.commit('setTempActiveSideBarItem', '');
        } else if (activeSideBarItem === 'Log out') {
          this.$router.push(`/`);
          this.$store.commit('setTempActiveSideBarItem', '');
        }
      } else {
        // console.log('empty');
        this.$router.push(`/schemas`);    //replace this route with your page route
      }
      this.unsavedChanges = false;
      this.canLeave = true;
      this.showAlert = false;
    },
    emitdata(val) {
      console.log(val);
      let arr = [];
      val.forEach((e) => {
        arr.push(e.value);
      });
      this.value = arr;
      this.dataChange = true;
      this.refconfigdata = this.value;
      this.tableData[this.settingsrefernceindex].reference.refConfig.fields =
        this.value;
    },
    emitsubdata(val) {
      console.log(val);
      let arr = [];
      val.forEach((e) => {
        arr.push(e.value);
      });
      this.value = arr;
      this.dataChange = true;
      this.refconfigdata1 = this.value;
      this.tableData1[this.settingsrefernceindex].reference.refConfig.fields =
        this.value;
    },
    async saveRefer() {
      if (this.dataChange == false) {
        this.tableData[this.settingsrefernceindex].reference.refConfig.fields =
          this.preReference;
      }
      this.addrefer = false;
    },
    async savesubRefer() {
      if (this.dataChange == false) {
        this.tableData1[this.settingsrefernceindex].reference.refConfig.fields =
          this.preReference;
      }
      this.addrefer1 = false;
    },
    // selecteddata
    async refer(index) {
      try{
      this.settingsrefernceindex = index;
      this.dataChange = false;
      console.log(
        "🚀 ~ file: LocalSchemaAddView.vue:814 ~ refer ~ val:",
        index,
        this.tableData[index]?.schema
      );
      let val = this.tableData[index]?.reference?.refConfig?.name;
      this.schemaName = this.tableData[index]?.reference?.refConfig?.name;
      this.refconfigdata = this.tableData[index]?.reference?.refConfig?.fields;
      this.preReference = this.tableData[index]?.reference?.refConfig?.fields;
      const allschema = await DMNAxiosInstance.get(`rule_engine/schema/${val}`);
      let name = await allschema?.data?.data?.schema;
      let key = Object.keys(name);
      let sch = key.map((val) => {
        //
        return { name: val, value: val };
      });
      this.namedRef = sch;
      this.addrefer = true;
    }
    catch(e){
      console.log("Error from LocalSchemaAddView",e)
    }
    },
    async subrefer(index) {
      this.settingsrefernceindex = index;
      this.dataChange = false;
      let val = this.tableData1[index].reference.refConfig.name;
      this.schemaName = this.tableData1[index].reference.refConfig.name;
      this.refconfigdata1 = this.tableData1[index].reference.refConfig.fields;
      this.preReference = this.tableData1[index].reference.refConfig.fields;
      const allschema = await DMNAxiosInstance.get(`rule_engine/schema/${val}`);
      let name = await allschema.data.data.schema;
      let key = Object.keys(name);
      let sch = key.map((val) => {
        //
        return { name: val, value: val };
      });
      this.namedRef = sch;
      this.addrefer1 = true;
    },
    cancelRefer() {
      this.tableData[this.settingsrefernceindex].reference.refConfig.fields =
        this.preReference;
      this.addrefer = false;
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    cancelsubRefer() {
      this.tableData1[this.settingsrefernceindex].reference.refConfig.fields =
        this.preReference;
      this.addrefer1 = false;
    },
    async optionSelected($event, i) {
      console.log(i,$event)
      this.ShowSettingIcon[i] = true; 
      this.contextHlx = -1;
      let val = $event.name;
      console.log(val, i, "emited");
      this.tableData = this.tableData.map((data, index) => {
        if (index == i) {
          data.reference.isRef = true;
          data.reference.refConfig.name = val;
        }
        return data;
      });
      console.log(this.tableData, "🎉");
      // console.log("🚀 ~ file: LocalSchemaAddView.vue:818 ~ optionSelected ~ val, i:", val, i)
      let res = await DMNAxiosInstance.get(`mapRef/getbyname/${val}`);
      console.log(schemaRes);

      if (res.data.ok == 0) {
        this.headers = [];
      } else {
        this.headers = Object.keys(res.data.data).map((e) => {
          //
          // console.log(schemaRes.data.data[e]);
          let obj = {};
          obj.name = e;
          obj.prop = e;
          obj.label = e;
          obj.required = res.data.data[e].required;
          obj.ref = res.data.data[e].refConfig;
          obj.type = res.data.data[e]?.type;
          obj.sortable = true;
          obj.checked = true;
          obj.disabled = false;
          obj.align = "left";
          return obj;
        });
        this.Theaders = this.headers;
        // console.log(this.duplicateTheads,'pop');
      }
      let schemaRes = await DMNAxiosInstance.get(`mapRef/collection/${val}`);
      this.refTable = schemaRes.data.data;
      this.selectedOption = true;
    },
    applyColumns(val) {
      // //
      this.Theaders = [];
      val.forEach((e) => {
        if (e.checked === true) {
          // this.headvalue.push(e);
          this.tTheaders.push(e);
        }
      });
    },
    changeRowsPerPage(count) {
      this.rowsPerPage = count;
    },
    currentPage(val) {
      this.currPage = val;
    },
    arrange(val) {
      // //
      this.re_arrange = val;
    },
    async optionSelected1($event, i) {
      console.log($event, i, "❤️");
      let val = $event.name;
      this.tableData1.forEach((data, index) => {
        if (index == i) {
          data.reference.isRef = true;
          data.reference.refConfig.name = val;
        }
      });
      this.selectedOption = true;
      console.log(this.tableData1, "data1");
    },
    async handleUploadedSchema(val) {
      console.log(val, "iiii");
      if (val.properties.type === "application/json") {
        // let a;
        //
        const formData = new FormData();
        formData.append("file", val.properties);
        //
        this.formData = formData;
      } else {
        this.notification = { type: "danger", message: 'Upload failed. Check again!' };
      }
      // if (val.properties.type === "text/csv") {
      //   const formData = new FormData();
      //   formData.append("file", val.properties);
      //
      //   this.formData = formData;

      //   // filereader.readAsText(val.properties);
      //   // filereader.onload = async (e) => {
      //   //   const content = e.target.result;
      //   //   const jsonData = await csv().fromString(content);

      //   //   // Use the converted JSON data as needed
      //   //
      //   // };
      // } else if (val.properties.type === "application/json") {
      //   let a;
      //
      //   const formData = new FormData();
      //   formData.append("file", val.properties);
      //
      //   this.formData = formData;
      // } else {
      //   //           const formData = new FormData();
      //   // formData.append("name", val.properties);
      //   //
      //   const formData = new FormData();
      //   formData.append("file", val.properties);
      //

      //   //                await axios
      //   // .post(`http://localhost:5000/upload`,formData).then((res)=>{
      //   //
      //   //     // this.tableData.push(res.data.data)
      //   //   })

      //   // filereader.readAsBinaryString(val.properties);
      //   // this.importdata = [];
      //   // filereader.onload = (event) => {
      //   //   let data = event.target.result;
      //   //   let workbook = read(data, { type: "binary" });
      //   //   //
      //   //   workbook.SheetNames.forEach((sheet) => {
      //   //     let rowObject = XLSX.utils.sheet_to_row_object_array(
      //   //       workbook.Sheets[sheet]
      //   //     );
      //   //     rowObject.forEach((e) => {
      //   //       this.importdata.push(e);
      //   //     });

      //   //
      //   //   });

      //   //
      //   // };
      //   this.importbutton = true;
      //   // setTimeout(() => {
      //   //
      //   // }, 250);
      //   this.importDataModal = true;
      //   this.formData = formData;
      // }
    },

    showAddContextMenu() {
      this.show_add_menu = !this.show_add_menu;
    },
    chosed(val, i) {
      //
      this.ref = val;
      this.tableData[i].reference = val.toLowerCase();
      this.tableData[i].default = null;

      //
    },

    async saveReferSchema() {
      if (this.name1 != undefined) {
        if (/^[^-]*$/.test(this.name1) && !this.isSchemaExists) {
          let check = this.tableData1.map((val) => {
            //
            // let ref
            // if(val.ref=)

            let sc;
            5;
            if (val.reference.isRef != false) {
              sc = {
                [val.schema]: {
                  type: val.type,
                  required: val.required,
                  isRef: val.reference.isRef,
                  refConfig: val.reference.refConfig
                }
              };

              return sc;
            } else {
              sc = {
                [val.schema]: {
                  type: val.type,
                  required: val.required
                }
              };
              return sc;
            }
          });

          let finalObj = {};
          check = await check.map((val) => {
            return Object.assign(finalObj, val);
          });
          let checked = check[0];

          this.addschema = {
            name: this.name1,
            type: "manual",
            description: this.description1,
            schema: checked
          };
          let arr = [];
          this.myObj = {
            name: this.name,
            description: this.description,
            schema: this.tableData1[0].schema,
            type: this.tableData1[0].type
          };

          arr.push(this.addschema);

          const add = await DMNAxiosInstance.post(
            `rule_engine/schema/add`,
            arr
          );

          this.success = true;
          this.success_msg = "Schema added successfully";
          this.notification = { type: "success", message: this.success_msg };
          // this.discardAllChanges()
          if (add.data) {
            // const allschema = await DMNAxiosInstance.get(`rule_engine/schema/`);
            // let allSch = await allschema.data.data.map((val) => {
            //   return { ...val, label: val.name, value: val.name };
            // });
            // console.log(allSch, "👋");
            // this.allref = allSch;
            let filterOption = {
              company: this.company,
              "type": "search",
              "module": "schema",
              "collection": "",
              "isPaginated": false,
              "paginatedQuery": {
                "rowsPerPage": 10,
                "currentPage": 1
              },
              "isNeedToBeSort": false,
              "sortFieldAndOrder": { name: 1 },
              "limit": 1000,
              "filterQuery": {},
              "searchQuery": ""
            }
            const allschema = await DMNAxiosInstance.post(`/util/filterData`, filterOption);
            // let allSch = await allschema.data.data.data.map((val) => {
            //   return { ...val, label: val.name, value: val.name, name: val.name };
            // });
            // allSch.sort((a, b) => a.name.localeCompare(b.name));

            let allSch = await allschema.data.data.data
            this.allref = allSch;
            this.tableData[this.addschemaindex].reference.isRef = true;
            this.tableData[this.addschemaindex].reference.refConfig.name =
              this.name1;
            console.log(this.tableData);
            // console.log(add,"done & dusted",this.tableData);
            // this.tableData[this.addschemaindex].isRef = true

            // this.tableData[this.addschemaindex].reference.refConfig.name = this.name1
            // console.log(this.tableData);

            // this.tableData[this.addschemaindex]
            this.referenceSchema = false;
            // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/schemas/local `;
            //
          }
        } else {
          this.namecustomerror = true;
          this.errormessage = "Please give the correct schema name";
        }
      } else {
        this.namecustomerror = true;
        this.errormessage = "Please fill the field";
      }

      // const intellisenceRes = await DMNAxiosInstance.get(
      //   'rule_engine/schema/intellisence'
      // );
      // let data = intellisenceRes.data.data;
      // this.$store.commit('intellisenceData', data)
    },
    checkval(val, index) {
      console.log("checkval",val, index);
      val.forEach((e) => {
        if (e.checked == true) {
          if (e.value == "Array" || e.value == "Object") {
            this.tableData[index].reference.isRef = true;
          } else {
            this.tableData[index].reference.isRef = false;
          }
        }
      });
      console.log(this.tableData, "checkval table");
    },
    checkval1(val, index) {
      console.log("checkval1",val, index);
      val.forEach((e) => {
        if (e.checked == true) {
          if (e.value == "Array" || e.value == "Object") {
            this.tableData1[index].reference.isRef = true;
          } else {
            this.tableData1[index].reference.isRef = false;
          }
        }
      });
      console.log(this.tableData1, "checkval1 table");
    },

    async saveSchema() {
      console.log("this.formData...",this.formData);
      if (this.upload == true) {
        this.formData.append("type", "upload");
        this.formData.append("name", this.name);
        this.formData.append("description", this.description);

console.log("this.formData...",this.formData);

        let addedSchema = await DMNAxiosInstance.post(
          `rule_engine/schema/upload`,
          this.formData
        );
        console.log(addedSchema.data, "💕");
        if (addedSchema.data.ok == 0) {
          console.error(addedSchema.data.message);
          return;
        }
        addedSchema = addedSchema.data;
        this.discardAllChanges();
        this.$router.push(`/schemas`);
        this.success = true;

        return;
      }
      if (this.name != undefined) {
        if (/^[^-]*$/.test(this.name)) {
          let check = this.tableData.map((val) => {
            //
            // let ref
            // if(val.ref=)
            console.log("test");
            let sc;
            5;
            if (val.reference.isRef != false) {
              console.log(val);
              sc = {
                [val.schema]: {
                  type: val.type,
                  required: val.required,
                  isRef: val.reference.isRef,
                  refConfig: val.reference.refConfig
                }
              };

              return sc;
            } else {
              sc = {
                [val.schema]: {
                  type: val.type,
                  required: val.required
                }
              };
              return sc;
            }
          });

          let finalObj = {};
          check = await check.map((val) => {
            return Object.assign(finalObj, val);
          });
          let checked = check[0];

          this.addschema = {
            name: this.name,
            type: "manual",
            description: this.description,
            schema: checked
          };
          let arr = [];
          this.myObj = {
            name: this.name,
            description: this.description,
            schema: this.tableData[0].schema,
            type: this.tableData[0].type
          };

          arr.push(this.addschema);
          console.log(arr, "lll");
          this.notification = await {
          type: "success",
          message: "Schema added sucssefully"
          }
          console.log("qqqqqqqqqq", arr);

          let payArr = await this.langColAddInSchema(arr);
          // let payArr

          console.log("iiiiiiiiiiiii", payArr);
          
        
          const add = await DMNAxiosInstance.post(
            `rule_engine/schema/add`,
            payArr
          );
         
          // this.success = true;
          // this.success_msg = "Schema added successfully";
          // this.notification = { type: "success", message: this.success_msg };
          this.discardAllChanges();
          if (add.data) {
            this.$router.push(`/schemas`);
          }
        } else {
          this.namecustomerror = true;
          this.errormessage = "Please give the correct schema name";
        }
      } else {
        this.namecustomerror = true;
        this.errormessage = "Please fill the field";
      }

      const intellisenceRes = await DMNAxiosInstance.get(
        "rule_engine/schema/intellisence"
      );
      let data = intellisenceRes.data.data;
      this.$store.commit("intellisenceData", data);
    },
    chosen(val) {
      if (val?.name === "Upload") {
        this.manual = false;
        this.api = false;
        this.upload = true;
      } else if (val?.name === "Manual") {
        this.manual = true;
        this.upload = false;
        this.api = false;
      } else if (val?.name === "API") {
        this.manual = false;
        this.upload = false;
        this.api = true;
      }
    },
    pathTo() {
      // if (val.label === "Schema") {
        this.$router.push(`/schemas`);

        // this.$router.push({ path: `/DMN/schemas` })
        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/schemas `;
      // }
    },
    path(val) {
      if (val === "Schema") {
        this.$router.push(`/schemas`);

        // this.$router.push({ path: `/DMN/schemas` })
        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/schemas `;
      }
    },
    authSelected(val) {
      this.add_payload.auth = val;
    },
    addParamRow() {
      const obj = {};
      obj.key = "";
      obj.value = "";
      obj.index = "";
      this.add_payload.params.push(obj);
    },
    deleteParamRow(index) {
      this.add_payload.params.splice(index, 1);
    },
    addHeaderRow() {
      const obj = {};
      obj.key = "";
      obj.value = "";
      obj.index = "";
      this.add_payload.headers.push(obj);
    },
    deleteHeaderRow(index) {
      this.add_payload.headers.splice(index, 1);
    },
    addschemabuilder() {
      let obj = {
        schema: "",
        type: "String",
        required: false,
        reference: {
          isRef: false,
          refConfig: {
            name: "",
            fields: []
          }
        }
      };
      // obj.schema = "";
      // obj.type = "String";
      // obj.required = false;
      // obj.list = false;

      this.tableData.push(obj);
      console.log(this.tableData);
    },
    deleteschemabuilder(index) {
      this.tableData.splice(index, 1);
    },
    addsubschemabuilder() {
      let obj = {
        schema: "",
        type: "String",
        required: false,
        reference: {
          isRef: false,
          refConfig: {
            name: "",
            fields: []
          }
        }
      };

      this.tableData1.push(obj);
    },
    deletesubschemabuilder(index) {
      this.tableData1.splice(index, 1);
    },
    showreference(val, index) {
      if (val.name === "Array") {
        this.tableData[index].list = true;
      }
    }
  }
};
</script>

<style></style>
