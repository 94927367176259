<template>
 <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />

  <discardComponent
    :show="showOverviewDiscardModal" 
    @close="showOverviewDiscardModal = false"
    @stay="handleStay"
    @discard="handleDiscard">
  </discardComponent>

  <!-- Previe container -->
  <div v-if="preview == true" class="email-preview-app">
    <span style="display:flex;justify-content: flex-end;background: #f4f4f4;font-size:28px;color: #aeaeae;padding: 10px;">
        <i class="icon-times-regular" @click="preview=false"></i>
    </span>
    <div class="preview-container">
      <iframe
        class="email-preview"
        :srcdoc="htmlContent"
        sandbox="allow-same-origin allow-scripts"
      ></iframe>
    </div>
    </div>

      <!-- Modal window -->
      <hlx-modal
        :modal-active="modal_open"
        :height="'240px'"
        :width="'450px'"
        :modal="true"
        @close="modal_open = false"
      >
        <template #header>
          <div>Test email</div>
        </template>
        <template #content>
          <div class="modal-body">
          <!-- Template Name -->
           <span            
            id="templateName"
           >
          <hlx-input
            v-model:value="testemail"
            :pre-val="testemail"
            :label-animation="true"
            :required="true"
            :display-error="true"
            type="email"
            :label-value="'Email'"
          />
        </span>

        </div>
        </template>
        <template #footer>
          <div class="modal-footer12">
          <hlx-button class="secondary sm" @click="modal_open = false">Cancel</hlx-button>
          <hlx-button v-if="testemail == ''" class="primary sm" :disabled="true" @click="testEmailfunc"
            >Send</hlx-button
          >
          <hlx-button v-else class="primary sm" @click="testEmailfunc"
            >Send</hlx-button
          >
        </div>
        </template>
      </hlx-modal>


    <div v-if="preview == false" class="email-template-editor"> 
      <!-- Header -->
      <div class="editor-header">
        <span class="back-breadcrumb">
        <hlx-breadcrumb :items="items" :separator="separator" @path="pathTo" />
        </span>
        <div class="header-container">
        <div class="header-text">
          <h2>Email templates</h2>
          <p>Custom template for your need</p>
        </div>
        <div class="html-header-icon">
            <span style="display:flex;justify-content: center;align-items: center;">
                <p style="color:#757575;font-size: 12px;">Language:</p>
                <hlx-select
                :pre-value="languagePreval"
                :options="languageOptions"
                :single-border="true"
                :label="'name'"
                :prop-value="'name'"
                @selected-value="switchLanguage"
              ></hlx-select>            </span>
            <hlx-button class="secondary sm" @click="OpentestEmailFunc">Test email</hlx-button>
            <hlx-button v-if="templateName == ''" class="primary sm" :disabled="true" 
            >Save</hlx-button
          > 
          <hlx-button v-else class="primary sm" @click="addTemplate"
            >Save</hlx-button
          >
            <i
class="icon-eye-filled" style="color: #aeaeae;
    font-size: 32px;" @click="previewFunc"></i>
        </div>
        </div>
      </div>
  
      <!-- Content -->
      <div class="editor-content">
        <!-- Sidebar -->
        <div class="editor-sidebar">
          <div class="form-group">
            <hlx-input
            v-model:value="payload.name"
            :pre-val="payload.name"
            :label-animation="true"
            :required="true"
            type="text"
            :label-value="'Template name'"
          />
            <!-- <label class="form-label">Template name <span class="required">*</span></label> -->
            <!-- <input
              class="form-input"
              type="text"
              v-model="templateName"
              placeholder="Enter template name"
            /> -->
          </div>
          <div class="form-group">
            <!-- <label class="form-label">Description</label>
            <textarea
              class="form-textarea"
              v-model="description"
              placeholder="Enter description"
            ></textarea> -->
            <hlx-input
          v-model:value="description"
          :pre-val="description"
          type="textarea"
          :label-animation="true"
          :required="false"
          :label-value="'Description'"
          ></hlx-input>
          </div>
          <!-- <div class="template-actions">
            <button
              class="template-button"
              :class="{ active: selectedTab === 'builder' }"
              @click="selectedTab = 'builder'"
            >
              Template builder
            </button>
            <button
              class="template-button"
              :class="{ active: selectedTab === 'editor' }"
              @click="selectedTab = 'editor'"
            >
              Code editor
            </button>
          </div> -->
        </div>
  
        <!-- Main Editor -->
        <div class="editor-main">
          <div class="status-bar">
            <span class="data-intellisense">
                <!-- <hlx-select
                :placeholder-value="'Add variable'"
                :options="intellisenseList"
                :label="'name'"
                :prop-value="'value'"
                >
                </hlx-select> -->
            </span>
          </div>
          <hlx-code-editor
          :key="componentkey"
          v-model:value="templateCode"
          :pre-value="templateCode"
          :rows="22"
        :email-template="true"
        :intellisense-data="intellisenseList"
        :line-number="true"
        @code-changes="assignValue"
      ></hlx-code-editor>
          <!-- <textarea
          v-model="templateCode"
          class="code-editor"
          placeholder="Write your HTML code here..."
        ></textarea> -->
        </div>
      </div>
  
      <!-- Footer --> 
      <div class="editor-footer">
        <!-- <button class="save-button" @click="saveTemplate">Save</button>
      <button class="test-button">Test email template</button> -->
      </div>
    </div>
  </template>
<script>
import {  MLTAxiosInstance ,UPCAxiosInstance } from "@/config/axiosConfig";
// import Handlebars from "handlebars";
import discardComponent from '@/components/discardComponent.vue';


import Mustache from "mustache";


export default {
    components: {
    	discardComponent,
  },
  beforeRouteLeave(to, from, next) {
  if(this.discardIt) { //this.discardIt will handled on discard modal window discard changes emit
    next(true)
    return
  }
	// Check for unsaved changes
	/**
	 * Param 1 initial paylaod to check against param 2
	 * Param 2 updated payload to check against param 1
	 */
  console.log(this.dupliactePayload,this.payload,'ldfhlslk')
const unsavedChanges = this.isPayloadChanged(this.dupliactePayload,this.payload) //This isPayloadChanged method was configured in global methods so we can call it any where on vue.It's find a difference between both data and return true if any changes otherwise return false

// If unsaved changes found then display discard modal
if(unsavedChanges){
  this.showOverviewDiscardModal = true
}
// Then route as per validation
next(!unsavedChanges)
return
},
//   beforeRouteLeave(to, from, next) {
//     // Compile the template with dynamic data
//     const template = Handlebars.compile(this.templateCode);
//     this.emailHtml = template(this.emailData);
//     console.log(this.emailHtml,'oojoewoidjwoi')

//     // Send the email or store it before navigating
//     // this.sendEmail(this.emailHtml);

//     // Proceed with navigation
//     next();
//   },
  data() {
    return {
        discardIt:false,
        tempBack:false,
        showOverviewDiscardModal:false,  
        componentkey:0,
        payload:{},
        dupliactePayload:{},
        duplicatelanguageOptions: [],
        languageOptions: [],
        preview:false,
        htmlContent:'',
        notification:{},
        list: {
        schema: [
          { label: 'quote.agreement', type: 'string' },
          { label: 'quote.attachment', type: 'string' },
          { label: 'quote.quoteDate', type: 'date' },
          { label: 'quote.quoteItem', type: 'Array' },
        ],
        api: [ { label: 'API()' }],
email: [ { label: 'Email()' }],
        keywords: [
          { label: 'if' },
          { label: 'then' },
          { label: 'else' },
          { label: 'and' },
          { label: 'or' },
          { label: 'function' },
          { label: 'filter' },
          { label: 'reduce' },
          { label: 'map' },
          { label: 'Date' },
          { label: 'Math' },
          { label: 'slice' },
          { label: 'substr' },
          { label: 'concat' },
          { label: 'true' },
          { label: 'false' },
        ],
        methods: {
          array: [
            {
              label: "filter",
              syntax:
                "array.filter(function(currentValue, index, arr), thisValue)"
            },
            {
              label: "reduce",
              syntax:
                "array.reduce(function(total, currentValue, currentIndex, arr), initialValue)"
            },
            {
              label: "map",
              syntax: "array.map(function(currentValue, index, arr), thisValue)"
            }
          ],
          date: [
            {
              label: "getDay",
              syntax: "getDay()"
            },
            {
              label: "getDate",
              syntax: "getDate()"
            },
            {
              label: "getTime",
              syntax: "getTime()"
            },
            {
              label: "getMonth",
              syntax: "getMonth()"
            },
            {
              label: "getFullYear",
              syntax: "getFullYear()"
            },
            {
              label: "setDate",
              syntax: "setDate()"
            },
            {
              label: "setTime",
              syntax: "setTime()"
            },
            {
              label: "setMonth",
              syntax: "setMonth()"
            },
            {
              label: "setFullYear",
              syntax: "setFullYear()"
            }
          ],
          integer: [
            {
              label: "random",
              syntax: "Math.random()"
            },
            {
              label: "round",
              syntax: "Math.round(x)"
            }
          ],
          string: [
            { label: "charAt", syntax: "string.charAt(index)" },
            { label: "slice", syntax: "string.slice(start, end)" }
          ]
        }
      },
        intellisenseList:{
        schema:[
        {
            "label": "data.saleslead.estimatedRevenue",
            "value": "data.saleslead.estimatedRevenue",
            "type": "string"
        },
        {
            "label": "recipientName",
            "value": "recipientName",
            "type": "string"
        },
        {
            "label": "data.saleslead.firstOccupancyDate",
            "value": "data.saleslead.firstOccupancyDate",
            "type": "Date"
        },
        {
            "label": "data.saleslead.href",
            "value": "data.saleslead.href",
            "type": "String"
        },
        {
            "label": "data.saleslead.id",
            "value": "data.saleslead.id",
            "type": "String"
        },
        {
            "label": "data.saleslead.lastlabel",
            "value": "data.saleslead.lastlabel",
            "type": "string"
        },
        {
            "label": "data.saleslead.lastUpdate",
            "value": "data.saleslead.lastUpdate",
            "type": "Date"
        },
        {
            "label": "data.saleslead.leadOwner",
            "value": "data.saleslead.leadOwner",
            "type": "string"
        },
        {
            "label": "data.saleslead.leadSource",
            "value": "data.saleslead.leadSource",
            "type": "string"
        },
        {
            "label": "data.saleslead.marketSegment",
            "value": "data.saleslead.marketSegment",
            "type": "string"
        },
        {
            "label": "data.saleslead.marketingCampaign",
            "value": "data.saleslead.marketingCampaign",
            "type": "string"
        },
        {
            "label": "data.saleslead.label",
            "value": "data.saleslead.label",
            "type": "String"
        },
        {
            "label": "data.saleslead.networkId",
            "value": "data.saleslead.networkId",
            "type": "String"
        },
        {
            "label": "data.saleslead.numberOfSites",
            "value": "data.saleslead.numberOfSites",
            "type": "number"
        },
        {
            "label": "data.saleslead.numberOfUnits",
            "value": "data.saleslead.numberOfUnits",
            "type": "number"
        },
        {
            "label": "data.saleslead.phone",
            "value": "data.saleslead.phone",
            "type": "String"
        },
        {
            "label": "data.saleslead.phoneNumber",
            "value": "data.saleslead.phoneNumber",
            "type": "String"
        },
        {
            "label": "data.saleslead.preferredLanguage",
            "value": "data.saleslead.preferredLanguage",
            "type": "String"
        },
        {
            "label": "data.saleslead.priority",
            "value": "data.saleslead.priority",
            "type": "String"
        },
        {
            "label": "data.saleslead.productOffering",
            "value": "data.saleslead.productOffering",
            "type": "string"
        },
        {
            "label": "data.saleslead.productSpecification",
            "value": "data.saleslead.productSpecification",
            "type": "string"
        },
        {
            "label": "data.saleslead.rating",
            "value": "data.saleslead.rating",
            "type": "String"
        },
        {
            "label": "data.saleslead.referredDate",
            "value": "data.saleslead.referredDate",
            "type": "Date"
        },
        {
            "label": "data.saleslead.role",
            "value": "data.saleslead.role",
            "type": "String"
        },
        {
            "label": "data.saleslead.salesOpportunity",
            "value": "data.saleslead.salesOpportunity",
            "type": "string"
        },
        {
            "label": "data.saleslead.sdfcId",
            "value": "data.saleslead.sdfcId",
            "type": "String"
        },
        {
            "label": "data.saleslead.siteAddress",
            "value": "data.saleslead.siteAddress",
            "type": "String"
        },
        {
            "label": "data.saleslead.sitelabel",
            "value": "data.saleslead.sitelabel",
            "type": "String"
        },
        {
            "label": "data.saleslead.status",
            "value": "data.saleslead.status",
            "type": "String"
        },
        {
            "label": "data.saleslead.statusChangeDate",
            "value": "data.saleslead.statusChangeDate",
            "type": "Date"
        },
        {
            "label": "data.saleslead.statusChangeReason",
            "value": "data.saleslead.statusChangeReason",
            "type": "String"
        },
        {
            "label": "data.saleslead.title",
            "value": "data.saleslead.title",
            "type": "String"
        },
        {
            "label": "data.saleslead.undefined",
            "value": "data.saleslead.undefined",
            "type": "String"
        },
        {
            "label": "data.saleslead.validFor",
            "value": "data.saleslead.validFor",
            "type": "string"
        },
        {
            "label": "data.saleslead.websiteUrl",
            "value": "data.saleslead.websiteUrl",
            "type": "String"
        }
    ],
    api: [ { label: 'API()' }],
email: [ { label: 'Email()' }],
        keywords: [
          { label: 'if' },
          { label: 'then' },
          { label: 'else' },
          { label: 'and' },
          { label: 'or' },
          { label: 'function' },
          { label: 'filter' },
          { label: 'reduce' },
          { label: 'map' },
          { label: 'Date' },
          { label: 'Math' },
          { label: 'slice' },
          { label: 'substr' },
          { label: 'concat' },
          { label: 'true' },
          { label: 'false' },
        ],
        methods: {
          array: [
            {
              label: "filter",
              syntax:
                "array.filter(function(currentValue, index, arr), thisValue)"
            },
            {
              label: "reduce",
              syntax:
                "array.reduce(function(total, currentValue, currentIndex, arr), initialValue)"
            },
            {
              label: "map",
              syntax: "array.map(function(currentValue, index, arr), thisValue)"
            }
          ],
          date: [
            {
              label: "getDay",
              syntax: "getDay()"
            },
            {
              label: "getDate",
              syntax: "getDate()"
            },
            {
              label: "getTime",
              syntax: "getTime()"
            },
            {
              label: "getMonth",
              syntax: "getMonth()"
            },
            {
              label: "getFullYear",
              syntax: "getFullYear()"
            },
            {
              label: "setDate",
              syntax: "setDate()"
            },
            {
              label: "setTime",
              syntax: "setTime()"
            },
            {
              label: "setMonth",
              syntax: "setMonth()"
            },
            {
              label: "setFullYear",
              syntax: "setFullYear()"
            }
          ],
          integer: [
            {
              label: "random",
              syntax: "Math.random()"
            },
            {
              label: "round",
              syntax: "Math.round(x)"
            }
          ],
          string: [
            { label: "charAt", syntax: "string.charAt(index)" },
            { label: "slice", syntax: "string.slice(start, end)" }
          ]
        }
},
      modal_open:false,
      testemail:'',
      items: [{ label: "Back", link: "/", icon: "icon-arrow-left-regular" }],
      templateName: "",
      description: "",
      templateCode: ``,

emailData: {
  recipientName: "John Doe",
  orderId: "FE-230914000-00",
  installationLink: "#",
  supportEmail: "atelier.ftth@bell.ca",
  supportPhone: "1-877-247-5888",
  siteInfo: [
    {
      siteName: "AMICA ERIN MILLS",
      units: 3,
      address: "150E Craig Henry Nepean, ON, K2G 4M6",
    },
    {
      siteName: "Amica Unionville",
      units: 3,
      address: "123 Maple Avenue, Toronto, ON, M5G 1X2",
    },
  ],
  equipmentShipped: [
    { name: "Fiber 450", quantity: 1, tracking: "1345567688", courier: "FedEx" },
    { name: "Fiber 500", quantity: 1, tracking: "1433546656", courier: "FedEx" },
    { name: "P3000", quantity: 13, tracking: "1433546656", courier: "FedEx" },
  ],
},
      emailHtml: "",
      languagePreval:'',
      selectedTab: "editor", // Default to Code Editor tab
    };
  },
  watch:{
    duplicatelanguageOptions:{
      handler(val){
        if(val.length != 0){
          console.log(val,'kjsd')
        var capitalizeLanguages = val.map(item => ({
  ...item,
  name: item.name.charAt(0).toUpperCase() + item.name.slice(1),
}));
this.languageOptions = capitalizeLanguages
        }
      },
      immediate:true,
      deep:true,
    }
  },
  async mounted(){
    await this.$store.dispatch("loginTenantDetails");
    this.duplicatelanguageOptions = this.$store?.getters?.loginTenantDetails?.languageAbility
    
    // this.languageOptions.forEach((item)=>{
    //   item.lang = item.la
    // })
    if(this.languageOptions.length != 0){
        // this.languagePreval =this.languageOptions[0].name
    }
    // console.log(this.$store?.getters?.loginTenantDetails?.languageAbility,'data')

    const data = await UPCAxiosInstance.get(
        `emailTemplate/${this.$route.query.id}`
      );
      console.log(data.data,'ddksdadk')
      this.templateName = data.data.name;
      this.description = data.data.description
      this.languagePreval = data.data.templateValue[0].lang
      this.templateCode = data.data.templateValue[0].templateString
      this.payload = JSON.parse(JSON.stringify(data.data))
      this.dupliactePayload = JSON.parse(JSON.stringify(data.data))
  },
  methods: {
    assignValue(value){
      console.log(value,'skj',this.payload)
      this.payload.templateValue.forEach((item)=>{
        if(item.lang == this.languagePreval){
          item.templateString = value.trim()
        }
      })
      
    },
    handleStay() {
      // //handle Stay funtionality to hide discard component
      this.showOverviewDiscardModal = !this.showOverviewDiscardModal;
      this.discardIt = false;
    },
    handleDiscard() {
      //Handle discard functionalities like hide discard component,set discardIt as true, and push the routes where ever you want.
      // if (this.tempBack) {
      console.log("handleDiscard")
      this.$router.push({
        name: 'emailTemplateView'
      });
      this.showOverviewDiscardModal = !this.showOverviewDiscardModal;
      this.discardIt = true;
      // } else {
      //   this.$router.push(this.$store.state.tempActiveSideBarItem.link);
      // }
      // this.showOverviewDiscardModal = !this.showOverviewDiscardModal;
      // this.discardIt = true;
    },
    preventPageReload(event) {
      this.showOverviewDiscardModal = false;
      const unsavedChanges = this.isPayloadChanged(
        this.initialData,
        this.updatedData
      );
      if (!unsavedChanges) return;

      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
      return;
    },
  switchLanguage(val){
    console.log(val,'language',this.templateCode)
    this.languagePreval= val.name
    this.payload.templateValue.forEach((item,index)=>{
                if(item.lang == val.name){
                     this.componentkey += 1
                     this.templateCode = this.payload.templateValue[index].templateString 
                }
            })
            console.log(this.templateCode,'after')
  },
    async addTemplate(){
        if (this.$route.query.id) {
        try {
            this.payload.id = this.$route.query.id
            this.payload.name = this.templateName
            this.payload.description = this.description
            this.payload.templateValue.forEach((item,index)=>{
                if(item.lang == this.languagePreval){
                    this.payload.templateValue[index].templateString = this.templateCode
                }
            })

          const value = await UPCAxiosInstance.patch("emailTemplate", this.payload);
          this.notification = {
            type: "success",
            message: `Nice work! Your template is updated!`,
          };

          console.log(value, "testing");
        } catch (error) {
          console.log(error);
        }
      } 
    },
    pathTo() {
        this.tempBack = true;
        this.$router.push(`/settings/emailTemplate`);

        // this.showOverviewDiscardModal = true
    },
    // back(){
    //     this.$router.push(`/settings/emailTemplate`);

    // },
    OpentestEmailFunc()
    {
        console.log(this.templateCode,'code')
        let code = this.templateCode
        this.modal_open = true
        // const filteredSites = this.emailData.sites.filter(site => site.units > 2); // Filter sites with more than 2 units

    // Update the emailData object with filtered sites
    // const filteredEmailData = { ...this.emailData, sites: filteredSites };

    // Compile the template with dynamic data
    // const template = Handlebars.compile(this.templateCode);
    // this.emailHtml = template(this.emailData); 
    const templateData = Mustache.render(code, this.emailData)
    this.emailHtml = templateData 

    console.log(this.emailHtml,'oojoewoidjwoi')
    },
    async testEmailfunc(){
        let quoteInvitationEmailContent = this.emailHtml
        let arr = []
        arr.push(this.testemail)
        let mail = {
            to: arr,
            template:quoteInvitationEmailContent
        }
        const headers = {
              Authorization: "Bearer " + this.$cookies.get("token"),
            };
     const result = await MLTAxiosInstance.post(`/email`, mail, { headers });
      if(result.status == 200){
        this.notification = {
            type: "success",
            message: `Nice work! Your template is sent to test email!`,
          };
          this.modal_open = false
          this.testemail = ''
      }


    },
    saveTemplate() {
      alert("Template saved successfully!");
    },
    previewFunc(){
        this.preview = true 
        console.log(this.templateCode,'template')
        let code = this.templateCode
         this.removeMustacheBlocks(code)
    },
    removeMustacheBlocks(content) {
      const  code = content.replace(/{{#.*?}}/g, '')
      const value = code.replace(/{{\/.*?}}/g, '')
      // console.log(code,'codeee')
      this.htmlContent = value
    }
  },
};
</script>
<style scoped>
/* General Layout */
.email-template-editor {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* font-family: Arial, sans-serif; */
  background-color: #f9f9f9;
}

/* Header */
.editor-header {
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
}

.back-breadcrumb{
   width:300px;
   padding: 15px;
   border-right:1px solid #D4D4D4;
}

.back-button {
  background: none;
  border: none;
  font-size: 14px;
  color: #4caf50;
  cursor: pointer;
}

.header-text h2 {
  margin: 0;
  font-family: Quicksand;
  font-size: 18px;
}

.header-text p {
  margin: 0;
  font-size: 12px;
  color: #666;
}

/* Content */
.editor-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

/* Sidebar */
.editor-sidebar {
  width: 300px;
  background-color: #ffffff;
  border-right: 1px solid #D4D4D4;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-label {
  font-size: 14px;
  font-weight: bold;
}

.required {
  color: red;
}

.form-input,
.form-textarea {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  width: 100%;
}

.form-textarea {
  resize: none;
  height: 100px;
}

.template-actions {
  display: flex;
  gap: 10px;
}

.template-button {
  flex: 1;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  color: #333;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
}

.template-button.active {
  background-color: #4caf50;
  color: #fff;
}

.header-container{
    display:flex;
    justify-content: space-between;
    width:77%;
}
.html-header-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
    padding-right:10px;
}

/* Main Editor */
.editor-main {
  flex: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.status-bar {
  display: flex;
  /* flex-direction: ; */
  justify-content: flex-end;
  align-items: center;
}

.data-intellisense{
    width:280px
}

.status-label {
  font-size: 14px;
}

.status-indicator {
  font-weight: bold;
  color: #4caf50;
}

.code-editor {
  flex: 1;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: monospace;
  font-size: 14px;
  resize: none;
  overflow: auto;
}

/* Footer */
.editor-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 15px;
  background-color: #ffffff;
  border-top: 1px solid #ddd;
}

.save-button,
.test-button {
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-button {
  background-color: #4caf50;
}

.test-button {
  background-color: #007bff;
}

/* Responsive Design */
@media (max-width: 768px) {
  .editor-content {
    flex-direction: column;
  }

  .editor-sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ddd;
  }

  .editor-main {
    width: 100%;
  }
}
</style>
  