<template>
    <section class="parent-signature-wrapper">
    <input
    v-model="inputName"
    style="border-bottom:1px solid black"
    placeholder="Type here"
    class="parent-signature"
    @input="emitSign"
    >
    <section style=" display: flex;
      align-items: center;">
    <span style="width:54px">Font: </span>
    <hlx-input v-model:value="SignaturefontSize" min-limit="10"  max-limit="21" :pre-val="SignaturefontSize" type="number"/>
  </section>
  </section>
    <span class="signature-component-input">
    <!-- <hlx-input
        v-model:value="inputName"
     :pre-val="inputName"
     :single-border="true"
    /> -->
  </span>
  <section class="signature-wrapper">
      <span :style="{ ...getDivStyle(1), padding: '12px', cursor:'pointer' }" 
        @click="selectDiv(1, inputName, fontStyleOne, SignaturefontSize)">
      <div class="input-span" 
           :style="{
               fontFamily: fontStyleOne, 
               fontSize: SignaturefontSize + 'px', 
               borderBottom: '1px solid black', 
               whiteSpace: 'nowrap', 
               overflow: 'hidden', 
               textOverflow: 'ellipsis', 
               width: '220px'
           }">
          {{ inputName }}
      </div>
  </span>
  
  <span :style="{ ...getDivStyle(2), padding: '12px' ,cursor:'pointer'}"  @click="selectDiv(2,inputName,fontStyleTwo,SignaturefontSize)">
  <div class="input-span" :style="{fontFamily:fontStyleTwo,fontSize:SignaturefontSize+'px',borderBottom:'1px solid black',  whiteSpace: 'nowrap', 
               overflow: 'hidden', 
               textOverflow: 'ellipsis', 
               width: '220px'}">
          {{ inputName }}
      </div>
  </span>
  <span :style="{ ...getDivStyle(3), padding: '12px' ,cursor:'pointer'}"  @click="selectDiv(3,inputName,fontStyleThree,SignaturefontSize)">
  <div class="input-span" :style="{fontFamily:fontStyleThree,fontSize:SignaturefontSize+'px',borderBottom:'1px solid black',  whiteSpace: 'nowrap', 
               overflow: 'hidden', 
               textOverflow: 'ellipsis', 
               width: '220px'}">
          {{ inputName }}
      </div>
  </span>
  <span :style="{ ...getDivStyle(4), padding: '12px' ,cursor:'pointer'}"  @click="selectDiv(4,inputName,fontStyleFour,SignaturefontSize)">
  <div class="input-span" :style="{fontFamily:fontStyleFour,fontSize:SignaturefontSize+'px',borderBottom:'1px solid black',  whiteSpace: 'nowrap', 
               overflow: 'hidden', 
               textOverflow: 'ellipsis', 
               width: '220px'}">
          {{ inputName }}
      </div>
  </span>
  </section>
  </template>
  
  <script>
  // import HlxInput from "../components/InputComponent.vue";
  export default {
      components:{
          // HlxInput
      },
  props:{
  inputVal:{
      type:String,
      default:"Signature"
  },
  },
      emits:["typed-signature","typed-signature-font"],
  data(){
      return{
          inputName:"",
          fontStyleOne:'Courier New',
          fontStyleTwo:'Impact',
          fontStyleThree:"cursive",
          fontStyleFour:'monospace',
          SignaturefontSize:14,
          selectedDiv: null
      }
  },
  watch:{
      inputVal:{
          handler(val){
              this.inputName=val
          },
          immediate: true,
          deep: true,
      },
      signatureFontName:{
        handler(val){
            val
            //   this.inputName=val
          },
          immediate: true,
          deep: true,
      },
      SignaturefontSize:{
          handler(val){
              this.SignaturefontSize=val
          },
          immediate: true,
          deep: true,
      } 
  },
  mounted(){
  // this.loadCanvas()
  this.disable()
  },
  methods:{
    emitSign(){
        this.$emit("typed-signature",this.inputName)
    },
      selectDiv(index,signVal,signFontName) {
          console.log(signVal,signFontName,"typed-signature")
      this.$emit("typed-signature",this.inputName)
      this.$emit("typed-signature-font",signFontName)
        this.selectedDiv = index;
      },
      getDivStyle(index) {
        const style={
          // color: index === 1 ? 'red' : index === 2 ? 'blue' : index === 3 ? 'green' : 'purple',
          // fontFamily: this[`fontStyle${index}`],
          // fontSize: this.SignaturefontSize + 'px',
          // borderRadius:index === this.selectedDiv 
          borderTopLeftRadius:index === this.selectedDiv ? '4px':"",
          borderTopRightRadius: index === this.selectedDiv ?'4px':"",
          border: index === this.selectedDiv ? '2px solid #54BD95' : 'none'
      };
    
      return style
      },
  disable(){
  const inputBox = document.querySelectorAll('.input-span')
  inputBox.forEach(input => {
        input.disabled = true;
      });
  }
  }
  }
  // for (let i=0;i<parentClass.length;i++){
  //     document.getElementsByClassName('input-span').disabled=true
  // }
  </script>
  
  <style lang="scss">
  .parent-signature-wrapper{
      width: -webkit-fill-available;
      // max-width: 300px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .parent-signature{
      border: none;
      padding: 24px;
      text-align: center;
      padding-bottom: 0px;
      }
  }
  .signature-wrapper span:hover{
      cursor:pointer
  }
  .signature-wrapper{
     
      display: grid;
    grid-template-columns: 1fr 1fr; 
     grid-template-rows: 100px 100px;
    grid-gap: 10px;
    padding: 10px;
    align-items: center;
      justify-items: center;
      span{
          width: -webkit-fill-available;
      // max-width: 286px;
      text-align: center;
      }
  }
  </style>