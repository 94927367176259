<template>
  <div class="config">
    <h4>Matrix mock</h4>
    <!-- <div>
      <OfferingSpecCharacteristic
        :offer-type="'Composite'"
        :company="'developerHalleyx'"
        mode="add"
        @updated:config="offer"
      ></OfferingSpecCharacteristic>
    </div> -->
    <div class="section-2">

      <h4>configure</h4>
      <br />
       <div class="decision-name-wrapper" style="width:25%">
          <hlx-input
            v-model:value="category"
            :label-value="'category'"
            :label-animation="true"
            :display-error="isCustomNameError"
            :custom-error="isCustomNameError"
            @at-enter	="inputChange(category)"
          ></hlx-input>
        </div>
        <br/>
      <div class="container1">
        <div>
          <hlx-input
            v-model:value="key1"
            :label-animation="false"
            :pre-val="key1"
            type="text"
            :clearable="false"
          />
        </div>
        <!-- <div>
          <hlx-input
            :label-animation="false"
            v-model:value="this.value1"
            :pre-value="this.value1"
            type="text"
            @at-input="check(this.value1)"
            :clearable="false"
          />
        </div> -->
        <div :key="componentkey">
          <hlx-select
            v-model-value="value1"
            :options="dropdown_data"
            :pre-value="value1"
            :prop-value="'name'"
            :label="'name'"
            :clearable="true"
            @selected-value="check($event, value1)"
          ></hlx-select>
        </div>
      </div>
      <br />
      <div class="container1">
        <div>
          <hlx-input
            v-model:value="key2"
            :label-animation="false"
            :pre-val="key2"
            type="text"
            :clearable="false"
          />
        </div>
        <div :key="componentkey1">
          <hlx-select
            :options="dropdown_data1"
            :pre-value="value2"
            :prop-value="'name'"
            :label="'name'"
            :clearable="true"
            @selected-value="change($event, value2)"
          ></hlx-select>
        </div>
      </div>
      <br />
      <div class="container1">
        <div>
          <hlx-input
            v-model:value="key3"
            :label-animation="false"
            :pre-val="key3"
            type="text"
            :clearable="false"
          />
        </div>
        <div :key="componentkey2">
          <hlx-select
            v-model-value="value3"
            :options="dropdown_data2"
            :pre-value="value3"
            :prop-value="'name'"
            :label="'name'"
            :clearable="true"
            @selected-value="atlast($event, value3)"
          ></hlx-select>
        </div>
      </div>

    </div>
    <br/>
    <div style="display:flex;gap:2%">

      <hlx-button class="primary sm" style="width:10%" @click="reset()">Reset</hlx-button>

    </div>
  </div>
</template>

<script>
// import OfferingSpecCharacteristic from "@/components/UPC/bundleOffering/OfferingSpecCharacteristicComponent.vue";
import { DMNAxiosInstance } from "@/config/axiosConfig";

export default {
  components: {
    // OfferingSpecCharacteristic,
  },
  data() {
    return {
      payload: {
        name: "",
        description: "",
        lifecycleStatus: "Active",
        offerType: "composite",
        category: [],
        prodSpecCharValueUse: [],
        market: [],
        productSpecification: {},
        validFor: {
          startDateTime: "",
          endDateTime: "",
        },
        validFrom: "",
        validTill: "",
        channel: [],
        place: "",
        relatedParty: [],
        tags: [],
        specification: {},
        // prodSpecCharValueUse: [],
        pictures: [],
      },
      category:"",
      obj: {},
      combinedObject: [],
      configed: {},
      key1: "memory",
      key2: "storage",
      key3: "color",
      value1: 0,
      value2: 0,
      value3: "",
      dropdown_data1: [],
      dropdown_data2: [],
      decision: {},
      componentkey:0,
      componentkey1:0,
       componentkey2:0,
       basedCategory:'',
      dropdown_data: [{ name: 4 }, { name: 6 }, { name: 8 }, { name: 12 }],
    };
  },
  methods: {
    async inputChange(val){
      const getData = await DMNAxiosInstance.get(`decisionMatrics/categorizing/developerHalleyx/${val}`)
      console.log(getData.data,"dataa")
      this.basedCategory = getData.data.ruleId

    },
    async reset(){
      this.value1=0
      this.value2=0
      this.value3=""
      this.componentkey2+=1
      this.componentkey1+=1
      this.componentkey+=1
    },
    async check($event, val) {
      let value = $event.name;
      this.value1=value
      //  this.value2 =0
       this.componentkey += 1
       this.componentkey1 += 1
      // this.value3=0
      console.log(val);
      let key = this.key1;
      let decision = { [key]: [value] };
      this.decision = {};
      const values = await DMNAxiosInstance.post(
        `rule_engine/rule/execute/${this.basedCategory}`,
        { payload: { decision } }
      );
      console.log(values,"from 1")
      let matrix = await values.data.data.decisionMatrix;
      console.log(await matrix, "tested");
      this.dropdown_data1 = await matrix.storage;
      this.dropdown_data2 = await matrix.color;
        await this.checkConfigure("value1")
    },
    async checkConfigure(val){
       switch(val){
        case "value1":
          var object1={"name":this.value2}
          var object2={"name":this.value3}
          console.log(object2,this.value3,"testt")
          var includesObject = this.dropdown_data1.some(obj => JSON.stringify(obj) === JSON.stringify(object1));
          var includesObject1 = this.dropdown_data2.some(obj => JSON.stringify(obj) === JSON.stringify(object2));
          if(includesObject==false){
                  this.componentkey1+=1

          }
          if(includesObject1==false){
                  this.componentkey2+=1

          }
          break;
        case "value2":
          var object3={"name":this.value1}
          var object4={"name":this.value3}
          var includesObject3 = this.dropdown_data.some(obj => JSON.stringify(obj) === JSON.stringify(object3));
          var includesObject4 = this.dropdown_data2.some(obj => JSON.stringify(obj) === JSON.stringify(object4));
             if(includesObject3==false){
                  this.componentkey+=1

          }
          if(includesObject4==false){
                  this.componentkey2+=1

          }
          break;
        case "value3":
            var object5={"name":this.value2}
          var object6={"name":this.value1}
          var includesObject5 = this.dropdown_data1.some(obj => JSON.stringify(obj) === JSON.stringify(object5));
          var includesObject6 = this.dropdown_data.some(obj => JSON.stringify(obj) === JSON.stringify(object6));
             if(includesObject5==false){
                  this.componentkey1+=1

          }
          if(includesObject6==false){
                  this.componentkey+=1

          }
          break;
        
       }
      console.log(this.dropdown_data1,this.dropdown_data2,this.dropdown_data,"🍃")
      // let config = {[this.key1]:[this.value1],[this.key2]:[this.value2],[this.key3]:[this.value3]}
    },
    async change($event, val) {
      let value = $event.name;
      console.log(value, val, "testtt");
      this.value2 = value
      let key = this.key2;
      // this.value3=0
             this.componentkey1 += 1

      let decision = {
        [this.key1]: [parseInt(this.value1)],
        [key]: [value],
      };
      const values = await DMNAxiosInstance.post(
        `rule_engine/rule/execute/${this.basedCategory}`,
        { payload: { decision } }
      );
      let matrix = await values.data.data.decisionMatrix;
      console.log(await matrix, decision, "tested");
      this.dropdown_data2 = await matrix.color;
              await this.checkConfigure("value2")

    },
    async atlast($event,val){
      console.log(val,"this.value3")
      this.value3 =await $event.name
    },
    async offer(value, key) {
      if (value.length != 0 && key != undefined) {
        console.log(value, value[0].value, "test34");
        // Original key-value pair
        this.obj = { [key]: value[0].value };
        this.combinedObject.push(this.obj);
        if (this.combinedObject.length != 0) {
          const objectFromArrayOfObjects = Object.assign(
            {},
            ...this.combinedObject
          );
          this.configed = objectFromArrayOfObjects;
        }
      }
              await this.checkConfigure("value3")

    },
  },
};
</script>

<style lang="scss">
.config {
  width: 90%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  gap: 2%;
  height: 40%;
  .section-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3%;
    .container1 {
      display: flex;
      gap: 3%;
    }
  }
}
</style>