<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <div style="padding: 30px">
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
      "
    >
      <div style="display: flex; align-items: center; gap: 16px">
        <span style="">
          <hlx-file-upload
            :file-type="'.jpg,.jpeg,.png'"
            :file-size-limit="'20mb'"
            :uploadtype="'Rectangle'"
            :style-background="backgrounStyleupload"
            :height="50"
            :width="50"
            :read-only="defalt"
            :custom-icon="icon"
            :type="'profile-upload-preview'"
            :name-display="payload.customName"
            :content="'Import'"
            :profile-pic-preview="profilePicUrl"
          />
        </span>

        <div style="display: flex; flex-direction: column; gap: 8px">
          <div style="display: flex; align-items: center; gap: 8px">
            <span style="font-weight: bold">{{ payload.customName }}</span>
            <hlx-label
              v-if="payload.status.length > 0 && payload.status === 'Active'"
              class="primary sm"
              >{{ payload.status }}</hlx-label
            >
            <hlx-label
              v-if="payload.status.length > 0 && payload.status === 'Inactive'"
              class="error sm"
              >{{ payload.status }}</hlx-label
            >
          </div>
          <div style="color: #a6a6a6; font-size: 12px; width: max-content">
            {{ payload.contactMedium[3].characteristic.description }}
          </div>
        </div>
      </div>
      <div
        v-if="view"
        class="go-back"
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <hlx-button
          class="primary sm"
          style="margin-left: 20px"
          @click="view = false"
          >Edit</hlx-button
        >
      </div>
      <div
        v-if="!view"
        class="go-back"
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <hlx-button class="secondary sm" @click="cancelEditsupplier"
          >Cancel</hlx-button
        >

        <hlx-button
          class="primary sm"
          style="margin-left: 20px"
          @click="addNewSupplier"
          >Save</hlx-button
        >
      </div>
    </div>
    <div class="decision-info-right-pannel" style="overflow: hidden">
      <div style="display: flex; gap: 5%; padding-top: 22px">
        <div style="display: flex; flex-direction: column; width: 50%">
          <div style="display: flex; justify-content: space-between; gap: 10px">
            <span style="">
              <hlx-file-upload
                :key="profileComponentKey"
                :file-type="'.jpg,.jpeg,.png'"
                :file-size-limit="'20mb'"
                :uploadtype="'Rectangle'"
                :style-background="backgrounStyle"
                :height="100"
                :width="100"
                :read-only="view"
                :custom-icon="icon"
                :type="'profile-upload'"
                :name-display="payload.customName"
                :content="'Import'"
                :profile-pic-preview="profilePicUrl"
                @native-data="profileUpload"
                @delete-image="deleteProfilePicture"
              />
            </span>
            <span style="width: 100%">
              <div
                class="decision-name-wrapper"
                :style="{
                  'margin-bottom': customnameerror ? '-7.6%' : 0,
                }"
              >
                <hlx-input
                  v-model:value="payload.customName"
                  :pre-val="payload.customName"
                  :label-value="'Name'"
                  :label-animation="true"
                  :required="true"
                  :disabled="defalt"
                  :display-error="true"
                  :custom-error="isFirstNameEmpty"
                  :custom-error-message="'Please fill the field'"
                  @focus-out="focusOutName"
                  @at-input="validateName"
                ></hlx-input>
              </div>
              <!-- <div class="decision-name-wrapper"> -->
              <hlx-select
                :key="key"
                :options="statusList"
                :pre-value="payload.status"
                :disabled="defalt"
                :label="'name'"
                :prop-value="'name'"
                :placeholder-value="'Status'"
                :label-animation="true"
                :clearable="true"
                @selected-value="statusFunc"
              ></hlx-select>
              <!-- </div> -->
            </span>
          </div>
          <!-- <div
                  class="decision-name-wrapper"
                  style="margin: 15px 0 29px 0"
                >
                  <hlx-input
                    v-model:value="payload.description"
                    :pre-val="payload.description"
                    type="textarea"
                    :label-value="'Description'"
                    :read-only="view"
                    :label-animation="true"
                    :required="false"
                    :display-error="false"
                    :rows="5"
                    @focus-out="focusOutName"
                  ></hlx-input>
                </div> -->

          <div class="decision-name-wrapper" style="margin-top: 25px">
            <addressComponent
              :pre-val="payload.contactMedium[3]"
              :view="view"
              @address-data="receivedAddressData"
            ></addressComponent>
          </div>
          <div
            class="decision-name-wrapper"
            style="margin-bottom: 37px; margin-top: 25px"
          >
            <hlx-select
              :key="key"
              v-model:value="payload.paymentTerms[0].name"
              :pre-value="payload.paymentTerms[0].name"
              :disabled="defalt"
              :options="paymentList"
              prop-value="name"
              :placeholder-value="'Payment terms'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
        </div>
        <div style="display: flex; flex-direction: column; width: 50%">
          <div class="decision-name-wrapper" style="margin: 0 0 30.5px 0">
            <hlx-input
              v-model:value="payload.description"
              :pre-val="payload.description"
              type="textarea"
              :label-value="'Description'"
              :read-only="view"
              :label-animation="true"
              :required="false"
              :display-error="false"
              :rows="7"
              @focus-out="focusOutName"
            ></hlx-input>
          </div>
          <div class="decision-name-wrapper">
            <hlx-input
              v-model:value="
                payload.contactMedium[0].characteristic.emailAddress
              "
              :pre-val="payload.contactMedium[0].characteristic.emailAddress"
              :label-value="'Email id'"
              :label-animation="true"
              type="email"
              :read-only="defalt"
              :required="true"
              :display-error="true"
              :custom-error="isEmailEmpty"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
          <div class="decision-name-wrapper">
            <hlx-input
              v-model:value="
                payload.contactMedium[1].characteristic.phoneNumber
              "
              :pre-val="
                payload.contactMedium[1].characteristic.phoneNumber.value
              "
              :country-code="
                payload.contactMedium[1].characteristic.phoneNumber.country
              "
              type="phonenumber"
              :read-only="view"
              :label-value="'Phone no.'"
              :label-animation="true"
              :display-error="true"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
          <div class="decision-name-wrapper" style="margin-bottom: 5px">
            <hlx-input
              v-model:value="payload.externalReference[0].name"
              :pre-val="payload.externalReference[0].name"
              :label-value="'Website URL'"
              :label-animation="true"
              type="text"
              :prefix="true"
              :read-only="view"
              :display-error="true"
              :custom-error="isUrlEmpty"
              :custom-error-message="'Please enter a valid URL in the format: www.abc.com'"
              @focus-out="websiteRegex"
              @at-input="validateName"
            >
            </hlx-input>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;

              height: 160px;
            "
          >
            <!-- <div
                    class="decision-name-wrapper"
                    style="margin-bottom: 37px"
                  >
                    <hlx-select
                      :key="key"
                      v-model:value="payload.paymentTerms[0].name"
                      :pre-value="payload.paymentTerms[0].name"
                      :disabled="view"
                      :options="paymentList"
                      prop-value="name"
                      :placeholder-value="'Payment terms'"
                      :label-animation="true"
                      :clearable="true"
                    ></hlx-select>
                  </div> -->

            <div class="decision-name-wrapper">
              <hlx-multi-select
                :pre-value="payload.tags"
                :options="tagsList"
                prop-value="name"
                :multi-select="true"
                :placeholder="'Tags'"
                :disabled="defalt"
                :label-animation="true"
                :clearable="true"
                @add-data="addTagList"
                @selected-array="receivedTags"
              ></hlx-multi-select>
            </div>
          </div>
          <!-- <uploadFileComponent></uploadFileComponent> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  MLTAxiosInstance,
  UPCAxiosInstance,
  // DMNAxiosInstance,
} from "@/config/axiosConfig";
import addressComponent from "@/components/CM/autocompleteComponent.vue";
export default {
  components: {
    addressComponent,
  },
  data() {
    return {
      newProfileData: null,
      newProfileformData: null,
      rawProfileUrlData: null,
      profilePicName: "",
      newProfileAdded: false,
      deleteProfilePic: false,
      profilePicUrl: "",
      profileComponentKey: 0,
      company: "",
      userId: "",
      showSupplierDiscardModal: false,
      noteData: [],
      attachmentData: [],
      userDetails: {
        name: this.company,
        id: this.userId,
      },
      showNewAddressOption: false,
      backgrounStyle: {
        backgroundColor: "",
        bacgroundtTextColor: "#A8A8A8",
        border: false,
        borderColor: "#A8A8A8",
        TextfontSize: 34,
      },
      backgrounStyleupload: {
        backgroundColor: "",
        bacgroundtTextColor: "#A8A8A8",
        border: false,
        borderColor: "#A8A8A8",
        TextfontSize: 16,
      },
      rawPayload: {},
      contactTableData: [],
      username: "GlobalTech Supplies",
      tagName: "Active",
      addressName: "123 Main Street Suite 456 Toronto, ON M5V 2B8, Canada",
      tablehead: "Attachment",
      view: true,
      defalt: true,
      name: "",
      description: "",
      email: "",
      phone: "",
      location: "",
      url: "",
      id: this.userId,
      overview: {},

      productpayload: {},
      notification: {},
      locationTypeList: [
        {
          name: "Headquarters",
        },
        {
          name: "Warehouse",
        },
      ],
      employeeCountList: [
        {
          name: "1 - 10",
        },
        {
          name: "10 - 50",
        },
        {
          name: "50 - 100",
        },
        {
          name: ">100",
        },
      ],
      preferredMediumList: [
        {
          name: "Email",
          value: "email",
        },
        {
          name: "Phone number",
          value: "phone",
        },
        {
          name: "Fax",
          value: "fax",
        },
      ],
      industryTypeList: [
        {
          name: "Corporate/Management",
        },
        {
          name: "Logistics/Supply Chain",
        },
        {
          name: "Manufacturing",
        },
        {
          name: "Information Technology",
        },
        {
          name: "Retail",
        },
      ],
      statusList: [],
      paymentList: [],
      ShipingList: [],
      tagsList: [],
      locationApi: "https://appbackend-ghubgwxrcq-pd.a.run.app/autocomplete",
      isRightLayoutOpened: false,
      breadCrumbItems: [
        {
          label: "Supplier",
          link: `/SM/supplier`,
        },
        { label: this.company, link: "" },
      ],
      customnameerror: false,

      contactPayload: {
        name: "",
        tradingName: "",
        companyName: "",
        status: "",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            emailAddress: "",
          },
          {
            mediumType: "phone",
            phoneNumber: {
              country: {
                name: this.$store.getters.defaultSetting?.country,
                short: this.$store.getters.defaultSetting?.cca2,
                flags: this.$store.getters.defaultSetting?.flags?.svg,
              },
              value: "",
            },
          },
          {
            mediumType: "fax",
            faxNumber: "",
          },
          {
            mediumType: "postalAddress",
            city: "",
            country: "",
            postCode: "",
            stateOrProvince: "",
            street1: "",
            street2: "",
            description: "",
            "@type": "",
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        relatedParty: [
          {
            role: "director",
            partyOrPartyRole: {
              id: "42",
              name: "Feroz Khan",
              "@referredType": "Individual",
            },
          },
        ],
        tags: ["Active"],
        role: "Root admin",
        type: "individual",
        password: "",
        email: "",
        userId: "",
        company: "",
      },
      selectedValue: "Overview",
      tabItems: [
        { name: "Overview", value: "Overview" },
        { name: "Hierarchy", value: "Hierarchy" },
        {
          name: "Contacts",
          value: "Contacts",
        },
        { name: "Interaction", value: "Interaction" },
        { name: "Rules", value: "Rules" },
        { name: "Quotes and orders", value: "Quotes and orders" },
        { name: "Approvals", value: "Approvals" },
      ],
      recievedContactData: [],
      showAddNewAdrressModal: false,
      newAddressPayload: {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      },
      locationData: [],

      ProductTabledata: {},
      leftpannel: "",
      initialSupplierPayload: {
        attachment: [],
        name: "",
        role: "",
        password: "",
        email: "",
        phone: "",
        tags: [],
        notes: [],
        companyName: "",
        tradingName: "",
        status: "",
        type: "",
        "@baseType": "",
        paymentTerms: [
          {
            name: "",
            id: "",
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              "@type": "",
            },
          },
        ],
      },
      payload: {
        attachment: [],
        name: "",
        role: "",
        password: "",
        email: "",
        phone: "",
        tags: [],
        notes: [],
        companyName: "",
        tradingName: "",
        status: "",
        type: "",
        "@baseType": "",
        paymentTerms: [
          {
            name: "",
            id: "",
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              "@type": "",
            },
          },
        ],
      },
      discardIt: false,
    };
  },
  async mounted() {
    const val = await MLTAxiosInstance.get(`partymanagement/individual/auth`);
    this.company = val.data.company;
    this.userId = val.data.userId;
    const access = await MLTAxiosInstance.get(
      `partyRole/get/${val.data.userId}`
    );

    const payload = await MLTAxiosInstance.get(
      `partymanagement/organisation/${this.userId}?company=${this.company}`
    );
    this.payload = payload.data;
    this.rawPayload = JSON.parse(JSON.stringify(this.payload));
    const profileName = this.payload.attachment.filter((item) => {
      return item["@type"] !== undefined && item["@type"] === "profile"
        ? item
        : false;
    });
    console.log(profileName[0]?.name);
    this.profilePicName = profileName[0]?.name;
    const url = await UPCAxiosInstance.get(
      `/gcpupload/files/${profileName[0]?.name}`,
      { responseType: "arraybuffer" }
    );
    // console.log(new Uint8Array([url.data,"url incoming"]))
    this.rawProfileUrlData = url.data;
    this.profilePicUrl = await this.bufferToDataURL(url.data);
    // let paymentList = await DMNAxiosInstance.get(
    //   `mapRef/collection/paymentTerms`
    // );
    this.paymentList =  await this.getReferanceData('paymentTerms');
    // let statusList = await DMNAxiosInstance.get(`mapRef/collection/statuslist`);
    this.statusList =  await this.getReferanceData('statuslist');
    console.log(this.payload);
    console.log(access, val.data.company, val.data.company, val, "<>");
  },
  methods: {
    async deleteProfilePicture() {
      console.log(this.payload.attachment, this.profilePicName, "ppp");
      this.deleteProfilePic = true;
    },
    async profileUpload(event) {
      console.log(event);
      this.newProfileAdded = true;
      this.newProfileData = event.target.files[0];
      this.newProfileformData = new FormData();

      // Append all selected files to FormData object
      this.newProfileformData.append("files", this.newProfileData);
    },
    async bufferToDataURL(buffer) {
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = "";
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },
    cancelEditsupplier() {
      this.view = true;
      this.profileComponentKey += 1;
      this.payload = JSON.parse(JSON.stringify(this.rawPayload));
      this.addressPreVal = this.payload.contactMedium[3];
      this.bufferToDataURL(this.rawProfileUrlData);
      this.newProfileAdded = false;
      this.deleteProfilePic = false;
      this.addressPreVal = this.payload.contactMedium[3];
    },
    receivedAddressData(val) {
      console.log(val);
      let address1 = JSON.parse(JSON.stringify(val));
      // let address2 = JSON.parse(JSON.stringify(val))
      this.payload.contactMedium[3] = JSON.parse(JSON.stringify(address1));
      console.log(this.payload.contactMedium[3]);
      // address2.mediumType = 'billingLocation'
      // this.newAccPayload.contactMedium.push(address2);
    },
    async addNewSupplier() {
      if (this.newProfileAdded) {
        let data = await UPCAxiosInstance.post(
          `/gcpMultiple/v2/upload/`,
          this.newProfileformData
        );
        console.log(data);

        let obj = {};
        obj.name = data.data[0].filename;
        obj.size = data.data[0].data.attachment.size;
        obj.url = data.data[0].url;
        obj.id = data.data[0].data._id;
        obj["@type"] = "profile";
        // Get the current date and time in milliseconds
        const currentTimestamp = new Date().toJSON();

        // Format the current timestamp in the same format as '1705574420667'
        console.log(currentTimestamp);
        obj.lastUpdated = currentTimestamp;
        // Find the index of the 'profile' object
        let index = this.payload.attachment.findIndex(
          (obj) => obj["@type"] === "profile"
        );

        this.profilePicName = obj.name;
        const url = await UPCAxiosInstance.get(`/gcpupload/files/${obj.name}`, {
          responseType: "arraybuffer",
        });
        this.rawProfileUrlData = url.data;
        this.profilePicUrl = await this.bufferToDataURL(url.data);

        // Replace the 'profile' object with the new object if found
        if (index !== -1) {
          this.payload.attachment[index] = obj;
        } else {
          this.payload.attachment.push(obj);
        }
      }
      if (this.deleteProfilePic === true && this.newProfileAdded === false) {
        await UPCAxiosInstance.delete(
          `/gcpupload/files/${this.profilePicName}`
        );
        let index = this.payload.attachment.findIndex(
          (obj) => obj["@type"] === "profile"
        );
        if (index !== -1) {
          this.payload.attachment.splice(index, 1);
          this.rawProfileUrlData = null;
          this.profilePicUrl = "";
          this.profilePicName = "";
        }
      }
      this.newProfileAdded = false;
      this.payload.id = this.payload._id;
      delete this.payload._id;
      const addSupplier = await MLTAxiosInstance.patch(
        `/partymanagement/organisation?company=${this.company}`,
        this.payload
      );
      this.rawPayload = JSON.parse(JSON.stringify(addSupplier.data));
      console.log(addSupplier, "test");
      if (addSupplier.data.tradingName) {
        this.notification = {
          type: "success",
          message: "All set! Your changes have been saved successfully!",
        };
        this.view = true;
      }
      const access = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.userId}?company=${this.company}`
      );
      this.payload = await access.data;
      this.profileComponentKey += 1;
    },
    editsupplier() {
      this.view = false;
    },
  },
};
</script>

<style></style>
