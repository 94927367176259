<template>
  <discardComponent
    :show="showAlert"
    @stay="stayFunction"
    @discard="discardAllChanges"
  ></discardComponent>
  <hlx-alert-notification :notify="notification" :auto-close="true" />
  <section style="display: flex; flex-direction: column">
    <main class="decision-add-container --layout-2-way">
      <!-- Left pannel -->
      <section class="--layout-inner-left-portion --LL30">
        <!-- Breadcrumb -->
        <div class="layout-breadcrumb">
          <hlx-breadcrumb :items="breadCrumbItems" @path="pathTo('Expression')" />
        </div>
        <div class="decision-info-left-pannel">
          <!-- Title -->
          <span class="title decision-title section-title"
            >Edit expression</span
          >

          <!-- Decision name -->
          <div class="decision-name-wrapper">
            <!-- <p>aa</p> -->
            <hlx-input
              v-model:value="name"
              :pre-val="name"
              :label-value="'Name'"
              :label-animation="true"
              :required="true"
              :display-error="customnameerror"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
            ></hlx-input>
          </div>

          <!-- Decision description -->
          <div class="decision-description-wrapper">
            <hlx-input
              v-model:value="description"
              :pre-val="description"
              :label-animation="true"
              label-value="Description"
              type="textarea"
              :clearable="true"
            />
          </div>

          <!-- Decision ruleset -->
          <div class="decision-ruleset-wrapper">
            <hlx-multi-select
              :options="ruleSetData"
              :pre-value="ruleSetPreValue"
              :placeholder="'Tag'"
              :prop-value="'_id'"
              :label="'name'"
              @add-data="addRuleset"
              @custom-change="selectValue"
            ></hlx-multi-select>
          </div>
          <div class="decision-ruleset-wrapper">
            <!-- <hlx-select
              v-model:value="statusVal"
              :pre-value="statusVal"
              :options="statusOptions"
              :placeholder-value="'Status'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select> -->
            {{"statusVal" +statusVal}}
            <hlx-status
                    :type="'select-status'"
                    :status-option="statusData"
                    :prevalue="statusVal.charAt(0).toUpperCase() + statusVal.slice(1)"
                    @emit-select="emitStatus"
                  >
                  </hlx-status>
          </div>
          <div class="decision-ruleset-wrapper">
            <div style="display: flex; justify-content: space-between">
              <span style="display: flex; align-items: center"
                ><span style="margin-right: 8px">Atomic</span>
                <span
                  data-tooltip="Atomic rules execute independently, excluded from chained execution"
                  data-tooltip-location="right"
                >
                  <i
                    class="icon-info-circle-regular"
                    style="color: #54bd95"
                  ></i> </span
              ></span>
              <span>
                <hlx-input
                  v-model:value="isAtomic"
                  :checked="isAtomic"
                  :type="'switch'"
              /></span>
            </div>
          </div>
        </div>
      </section>

      <!-- Right pannel -->
      <section class="--layout-inner-right-portion --RL70">
        <div class="decision-info-right-pannel">
          <div class="switch-parent-container" style="margin-left: auto">
            <!-- <hlx-switch
                 v-if="showSwitch"
                 v-model="selectedSwitch"
                   :switch-items="switch_items"
                   value-prop="name"
         display-prop="name"
                   @change="chosen"
                 /> -->
          </div>

          <div>
            <div class="content" style="margin: 2rem">
              <div class="section-header" style="margin-top: 16px">
                <span class="section-title">Expression</span>
              </div>

              <div
                class="action-set"
                :style="
                  builderData.type == 'assign'
                    ? 'align-items: start'
                    : 'align-items: flex-start'
                "
              >
                <span style="width: 13%">
                  <hlx-select
                    v-model:value="builderData.type"
                    :pre-value="builderData.type"
                    :options="actionOptions"
                    :placeholder-value="''"
                    :label-animation="true"
                    :key-test="index"
                    :clearable="true"
                    @custom-change="chooseAction($event, builderData)"
                  ></hlx-select>
                </span>
                <span
                  :style="
                    builderData.type == 'assign'
                      ? 'width:82%; margin-right: 0.5rem'
                      : 'width:83%'
                  "
                >
                  <div v-if="builderData.type == 'assign'">
                    <div class="condition-set">
                      <span class="lhs">
                        <!-- <hlx-input
                 v-model:value="builderData.config.lhs"
                 :pre-val="builderData.config.lhs"
                 :label-value="''"
                 :label-animation="true"
               ></hlx-input> -->
                        <hlx-code-editor
                          v-model:value="builderData.config.lhs"
                          :pre-value="builderData.config.lhs"
                          :intellisense-data="$store.state.intellisenceData"
                          :line-number="false"
                          :rows="1"
                        ></hlx-code-editor>
                      </span>
                      <span class="op">
                        <!-- <hlx-input
                 v-model:value="builderData.config.op"
                 :pre-val="builderData.config.op"
                 :label-value="''"
                 :label-animation="true"
               ></hlx-input> -->
                        <div
                          style="
                            height: 38px;
                            font-size: 14px;
                            width: 100%;
                            border: 1px solid #d8d8d8;
                            border-radius: 4px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #565533;
                          "
                        >
                          <span>equals</span>
                        </div>
                      </span>
                    </div>
                    <div class="condition-set">
                      <span class="rhs">
                        <!-- <hlx-input
                 v-model:value="builderData.config.rhs"
                 :pre-val="builderData.config.rhs"
                 :label-value="''"
                 :label-animation="true"
               ></hlx-input> -->
                        <!-- <hlx-multi-select
      :options="lhs_data"
      :placeholder="''"
      :label-animation="true"
      :prop-value="'value'"
      :label="'name'"
      :disabled="false"
      :filter-select="false"
      :pre-value="builderData.config.rhsArr"
      @add-data="expressionAdd"
      @selected-array = "selectedOption($event,builderData.config,'rhs')"
      @visual-rule-data = "selectedOption($event,builderData.config,'rhs')"
      @selected-value="selectedVal"
    ></hlx-multi-select> -->
                        <hlx-code-editor
                          v-model:value="builderData.config.rhs"
                          :pre-value="builderData.config.rhs"
                          :intellisense-data="$store.state.intellisenceData"
                          :line-number="false"
                          :rows="3"
                        ></hlx-code-editor>
                      </span>
                    </div>
                    <!-- <span class="actions">
               <span 
                     ><i
                       class="icon-plus-circle-regular"
                      
                       @click="addAction()"
                     ></i
                   ></span>
              
                   <span v-show="builderData.actions.length>1" class="delete-header" style="margin-left: 1rem">
                     <i
                       class="icon-trash-regular"
 
                       @click="deleteAction(index)"
                     ></i>
                   </span>
             </span> -->
                  </div>
                  <div
                    v-if="builderData.type == 'compute'"
                    style="margin-left: 1rem"
                  >
                    <hlx-code-editor
                      v-model:value="builderData.config.expression"
                      :pre-value="builderData.config.expression"
                      :intellisense-data="$store.state.intellisenceData"
                      :beautify="false"
                      :line-number="true"
                      :rows="12"
                      @code-changes="content"
                    ></hlx-code-editor>
                    <!-- <span class="actions">
               <span 
                     ><i
                       class="icon-plus-circle-regular"
                      
                       @click="addAction()"
                     ></i
                   ></span> -->

                    <!-- <span v-show="builderData.actions.length>1" class="delete-header" style="margin-left: 1rem">
                     <i
                       class="icon-trash-regular"
 
                       @click="deleteAction(index)"
                     ></i>
                   </span>
             </span> -->
                  </div>
                </span>
              </div>
            </div>
            <div
              v-show="content_type.toLowerCase() == 'code'"
              style="margin: 2rem"
              class="content"
            >
              <hlx-code-editor
                v-model:value="rule"
                :pre-value="rule"
                :intellisense-data="$store.state.intellisenceData"
                :beautify="true"
                :line-number="true"
                :rows="8"
                @code-changes="content"
              ></hlx-code-editor>
            </div>
          </div>
        </div>
      </section>
    </main>

    <div
      class="layout-footer"
      style="
        border-top: 1px solid #d8d8d8;
        padding-right: 40px;
        display: flex;
        align-items: center;
        height: 10vh;
        margin-right: -16px;
        margin-left: -16px;
      "
    >
      <div
        class="go-back"
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <hlx-button class="secondary sm" @click="cancelApiAdd">Back</hlx-button>
        <hlx-button
          class="primary sm"
          style="margin-left: 20px"
          @click="saveAPIData"
          >Save</hlx-button
        >
      </div>
    </div>
  </section>
</template>

<script>
// import axios from "axios";
import { DMNAxiosInstance } from "@/config/axiosConfig";
import discardComponent from "../../components/discardComponent.vue";
import deepdiff from "deep-diff";

export default {
  components: {
    discardComponent,
  },
  beforeRouteLeave(to, from, next) {
    console.log(this.unsavedChanges,"this.unsavedChanges");
    if (this.unsavedChanges) {
      // console.log(this.unsavedChanges);
      this.showAlert = true;
      if (this.showAlert) {
        if (this.canLeave) {
          next();
        } else {
          next(false);
        }
      } else {
        next();
      }
    } else {
      next();
    }
  },
  data() {
    return {
      statusData: [
          {
          name: "Draft",
          value: "Draft",
          color: "#2879b7",
        },
        {
          name: "Active",
          value: "Active",
          color: "#007247",
        },
      ],
      duplicaterule: "",
      duplicatename: "",
      duplicatedescription: "",
      duplicatebuilderData: { type: "assign", config: {} },
      duplicateisAtomic: false,
      duplicateexecutionByPass: true,
      duplicaterulesetarray: [],
      duplicatestatusVal: "draft",
      duplicatebuildMode: "Visual",
      unsavedChanges: false,
      showAlert: false,
      canLeave: false,
      notification: {},
      errMsg: [],
      errState: false,
      statusVal: "Draft",
      buildMode: "Visual",
      if: "",
      then: "",
      else: "",
      showRes: false,
      showPreview: false,
      auth: "No auth",
      isElse: false,
      auth_switch_items: [
        { name: "No auth" },
        { name: "Basic auth" },
        { name: "Bearer token" },
        { name: "API key" },
      ],
      multiSelectOptions: [
        { name: "Php", value: "Php", id: 1 },
        { name: "VueJs", value: "VueJs", id: 2 },
      ],
      ruleset_option: [],
      statusOptions: [
        {
          value: "draft",
          name: "Draft",
          checked: false,
          disabled: false,
          id: 1,
        },
        {
          value: "active",
          name: "Active",
          checked: false,
          disabled: false,
          id: 2,
        },
      ],
      actionOptions: [
        {
          value: "assign",
          name: "Assign",
          checked: true,
          disabled: false,
          id: 1,
        },
        {
          value: "compute",
          name: "Compute",
          checked: false,
          disabled: false,
          id: 2,
        },
      ],
      conditionOptions: [
        {
          value: "and",
          name: "AND",
          checked: true,
          disabled: false,
          id: 1,
        },
        {
          value: "or",
          name: "OR",
          checked: false,
          disabled: false,
          id: 2,
        },
      ],
      methodOptions: [
        {
          value: "get",
          name: "GET",
          checked: true,
          disabled: false,
          id: 1,
        },
        {
          value: "post",
          name: "POST",
          checked: false,
          disabled: false,
          id: 2,
        },
        {
          value: "patch",
          name: "PATCH",
          checked: false,
          disabled: false,
          id: 3,
        },
        {
          value: "delete",
          name: "DELETE",
          checked: false,
          disabled: false,
          id: 4,
        },
      ],
      conditionOptionsRadio: [
        {
          value: "and",
          name: "Match ALL",
          checked: true,
          disabled: false,
          id: 1,
        },
        {
          value: "or",
          name: "Match ANY",
          checked: false,
          disabled: false,
          id: 2,
        },
      ],
      dropdown_data: [
        { name: "Php", value: "Php", id: 1 },

        { name: "VueJs", value: "VueJs", id: 2 },
      ],
      builderData: { type: "assign", config: {} },
      isApi: false,
      isEmail: false,
      scale: 1,
      isAdd: false,
      isEdit: false,
      isAtomic: false,
      executionByPass: true,
      // statusOptions: [
      //   { name: 'Draft', checked: true, disabled: false, id: 121 },
      //   { name: 'Active', checked: false, disabled: false, id: 1 },
      // ],
      status: {},
      modal_active1: false,
      customnameerror: false,
      customeditorerror: "",
      showSwitch: true,
      startX: 0,
      startY: 0,
      translateX: 0,
      translateY: 0,

      minScale: 0.2,
      maxScale: 4,
      else_icon: "icon-plus-circle-regular",
      content_type: "",
      selectedSwitch: "Visual",
      contentExp: "uuu",
      name: "",
      description: "",
      schemas: [],
      success: false,
      success_msg: "Rule updated successfully",
      warning: false,
      warning_msg: "",
      breadCrumbItems: [{ label: "Expression", link: "/rules/list" }],
      ruleset: [],
      switch_items: [{ name: "Visual" }, { name: "Code" }],
      visualExp: "",
      iff: "",
      thenn: "",
      elsee: "",
      lhs_data: [
        //  { name: 'a', value: 'a', checked: false, disabled: false },
        //  { name: 'b', value: 'b', checked: false, disabled: false },
        //  { name: 'c', value: 'c', checked: false, disabled: false },
      ],
      rhs_data: [
        //  { name: 10, value: 10, checked: false, disabled: false },
        //  { name: 20, value: 20, checked: false, disabled: false },
        //  { name: 30, value: 30, checked: false, disabled: false },
      ],
      op_data: [
        // { name: "assign", value: "=", checked: false, disabled: false },
        { name: "equals", value: "==", checked: false, disabled: false },
        {
          name: "greater than or equals",
          value: ">=",
          checked: false,
          disabled: false,
        },
        {
          name: "lesser than or equals",
          value: "<=",
          checked: false,
          disabled: false,
        },
        { name: "greater than", value: ">", checked: false, disabled: false },
        { name: "lesser than", value: "<", checked: false, disabled: false },
        { name: "not equals", value: "!=", checked: false, disabled: false },
      ],
      op_data1: [
        { name: "assign", value: "=", checked: false, disabled: false },
        // { name: "equals", value: "==", checked: false, disabled: false },
        // {
        //   name: "greater than or equals",
        //   value: ">=",
        //   checked: false,
        //   disabled: false,
        // },
        // {
        //   name: "lesser than or equals",
        //   value: "<=",
        //   checked: false,
        //   disabled: false,
        // },
        // { name: "greater than", value: ">", checked: false, disabled: false },
        // { name: "lesser than", value: "<", checked: false, disabled: false },
        // { name: "not equals", value: "!=", checked: false, disabled: false },
      ],
      list: {
        schema: [
          { label: "quote.agreement", type: "string" },
          { label: "quote.attachment", type: "string" },
          { label: "quote.quoteDate", type: "date" },
          { label: "quote.quoteItem", type: "Array" },
        ],
        keywords: [
          { label: "if" },
          { label: "then" },
          { label: "else" },
          { label: "and" },
          { label: "or" },
          { label: "function" },
          { label: "filter" },
          { label: "reduce" },
          { label: "map" },
          { label: "Date" },
          { label: "Math" },
          { label: "slice" },
          { label: "substr" },
          { label: "concat" },
          { label: "true" },
          { label: "false" },
          { label: "API()" },
          { label: "Email()" },
        ],
        api: [],
        email: [],
        methods: {
          array: [
            {
              label: "filter",
              syntax:
                "array.filter(function(currentValue, index, arr), thisValue)",
            },
            {
              label: "reduce",
              syntax:
                "array.reduce(function(total, currentValue, currentIndex, arr), initialValue)",
            },
            {
              label: "map",
              syntax:
                "array.map(function(currentValue, index, arr), thisValue)",
            },
          ],
          date: [
            {
              label: "getDay",
              syntax: "getDay()",
            },
            {
              label: "getDate",
              syntax: "getDate()",
            },
            {
              label: "getTime",
              syntax: "getTime()",
            },
            {
              label: "getMonth",
              syntax: "getMonth()",
            },
            {
              label: "getFullYear",
              syntax: "getFullYear()",
            },
            {
              label: "setDate",
              syntax: "setDate()",
            },
            {
              label: "setTime",
              syntax: "setTime()",
            },
            {
              label: "setMonth",
              syntax: "setMonth()",
            },
            {
              label: "setFullYear",
              syntax: "setFullYear()",
            },
          ],
          integer: [
            {
              label: "random",
              syntax: "Math.random()",
            },
            {
              label: "round",
              syntax: "Math.round(x)",
            },
          ],
          string: [
            { label: "charAt", syntax: "string.charAt(index)" },
            { label: "slice", syntax: "string.slice(start, end)" },
          ],
        },
      },
      id: "tenant1",
      name1: "myself",
      rulesetarray: [],
      //   if_obj: {
      //   level1: {
      //     level_conjunction: "and",
      //     groups: [
      //       {
      //         group_conjunction: "and",
      //         group: [
      //           {
      //             inner_group_conjunction: "and",
      //             conditions: [{ id: 221, lhs: "a", op: ">", rhs: 10 }],
      //           }
      //         ],
      //       },
      //       {
      //         group_conjunction: "and",
      //         group: [
      //           {
      //             inner_group_conjunction: "and",
      //             conditions: [{ id: 142, lhs: "b", op: "<", rhs: 20 }],
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //   level2: {
      //     level_conjunction: "and",
      //     groups: [
      //       {
      //         group_conjunction: "and",
      //         group: [
      //           {
      //             inner_group_conjunction: "and",
      //             conditions: [{ id: 646, lhs: "c", op: "=", rhs: 30 }],
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //   level3: {
      //     level_conjunction: "and",
      //     groups: [
      //       {
      //         group_conjunction: "and",
      //         group: [
      //           {
      //             inner_group_conjunction: "and",
      //             conditions: [{ id: 113, lhs: "a", op: ">", rhs: 10 }],
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //   level4: { level_conjunction: "and", groups: [] },
      //   level5: { level_conjunction: "and", groups: [] },
      // },
      if_obj: {},
      then_obj: {},
      else_obj: {},
      flagger: true,
      apis: [],
      apiPayload: {},
      editApiPayload: {},
      apiName: "",
      editApiName: "",
      logs: [],
      ruleSetData: [],
      ruleSetPreValue: [],
    };
  },
  computed: {
    computedStatusOption() {
      return this.statusOptions;
    },
  },
  watch: {
    builderData: {
      handler(val) {
        val;
        // console.log(this.unsavedChanges);
        console.log(this.builderData, this.duplicatebuilderData);
        var differences = deepdiff(this.builderData, this.duplicatebuilderData);
        if (differences != undefined) {
          this.unsavedChanges = true;
        } else {
          this.unsavedChanges = false;
        }
      },
      deep: true,
      immediate: true,
    },
    name: {
      handler(val) {
        val;
        var differences = deepdiff(this.name, this.duplicatename);
        if (differences != undefined) {
          this.unsavedChanges = true;
        } else {
          this.unsavedChanges = false;
        }
      },
      deep: true,
      immediate: true,
    },
    description: {
      handler(val) {
        val;
        var differences = deepdiff(this.description, this.duplicatedescription);
        if (differences != undefined) {
          this.unsavedChanges = true;
        } else {
          this.unsavedChanges = false;
        }
      },
      deep: true,
      immediate: true,
    },
    executionByPass: {
      handler(val) {
        val;
        var differences = deepdiff(
          this.executionByPass,
          this.duplicateexecutionByPass
        );
        if (differences != undefined) {
          this.unsavedChanges = true;
        } else {
          this.unsavedChanges = false;
        }
      },
      deep: true,
      immediate: true,
    },
    isAtomic: {
      handler(val) {
        val;
        var differences = deepdiff(this.isAtomic, this.duplicateisAtomic);
        if (differences != undefined) {
          this.unsavedChanges = true;
        } else {
          this.unsavedChanges = false;
        }
      },
      deep: true,
      immediate: true,
    },
    statusVal: {
      handler(val) {
        val;
        var differences = deepdiff(this.statusVal, this.duplicatestatusVal);
        if (differences != undefined) {
          this.unsavedChanges = true;
        } else {
          this.unsavedChanges = false;
        }
      },
      deep: true,
      immediate: true,
    },
    buildMode: {
      handler(val) {
        val;
        var differences = deepdiff(this.buildMode, this.duplicatebuildMode);
        if (differences != undefined) {
          this.unsavedChanges = true;
        } else {
          this.unsavedChanges = false;
        }
      },
      deep: true,
      immediate: true,
    },
    rule: {
      handler(val) {
        val;
        var differences = deepdiff(this.rule, this.duplicaterule);
        if (differences != undefined) {
          this.unsavedChanges = true;
        } else {
          this.unsavedChanges = false;
        }
      },
      deep: true,
      immediate: true,
    },
    rulesetarray: {
      handler(val) {
        val;
        var differences = deepdiff(
          this.rulesetarray,
          this.duplicaterulesetarray
        );
        if (differences != undefined) {
          this.unsavedChanges = true;
        } else {
          this.unsavedChanges = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    await this.$store.dispatch("initIntellisenceData");
    await this.$store.dispatch("initRuleSetData");
    this.$store.commit("token", this.$route.params.token);
    if (
      this.$store.state.ruleSetData &&
      this.$store.state.ruleSetData.length == 0
    ) {
      await this.$store.dispatch("initRuleSetData");
    }
    this.ruleSetData = this.$store.getters.ruleSetData;
    //       await DMNAxiosInstance.get(
    //    `rule_engine/rule_set`
    //    // {
    //    //   auth: {
    //    //     username: "testuser@test.com",
    //    //     password: " ",
    //    //   },
    //    // }
    //  ).then((res) => {
    //    // console.log(res);
    //    this.ruleset_data = res.data.data;
    //  });
    this.selectedSwitch = this.switch_items[0].name;
    this.content_type = this.switch_items[0].name;

    await DMNAxiosInstance.get(
      `rule_engine/rule/${this.$route.params.id}`
    ).then((res) => {
      let editData = res.data.data;
      let obj = {};
      obj["label"] = `${editData.name}`;
      obj["link"] = "";
      this.breadCrumbItems.push(obj);
      this.rule_data = res.data.data;
      console.log(this.rule_data, "data");
      this.ruleSetPreValue = this.rule_data.ruleSet;
      this.name = this.rule_data.name;
      this.description = this.rule_data.description;
      this.statusVal = this.rule_data.status;
      this.executionByPass = this.rule_data.executionByPass;
      this.isAtomic = this.rule_data.isAtomic;
      this.buildMode = this.rule_data?.buildMode;
      this.builderData = this.rule_data.visualBuilderPayload;
      this.rule = this.rule_data.ruleScript;

      this.duplicatename = this.rule_data.name;
      this.duplicatebuilderData = this.rule_data.visualBuilderPayload;
      this.duplicatedescription = this.rule_data.description;
      this.duplicatestatusVal = this.rule_data.status;
      this.duplicateexecutionByPass = this.rule_data.executionByPass;
      this.duplicateisAtomic = this.rule_data.isAtomic;
      this.duplicatebuildMode = this.rule_data.buildMode;
      this.duplicaterulesetarray = this.rule_data.ruleSet;
      this.duplicaterule = this.rule_data.ruleScript;
    });
    this.duplicatebuilderData = JSON.parse(
      JSON.stringify([this.duplicatebuilderData])
    );
  },
  methods: {
    emitStatus(val){
      this.statusVal=val.name
    },
    stayFunction() {
      this.canLeave = false;
      this.showAlert = false;
    },
    discardAllChanges() {
      const activeSideBarItem = this.$store.getters.getTempActiveSideBarItem;
      // console.log(activeSideBarItem);
      // if (activeSideBarItem !== '') {
      //   if (activeSideBarItem == 'logout') {
      //     this.logout();
      //   } else if (activeSideBarItem == 'Dashboard') {
      //     this.$router.push(`/DMN/dashboard`);
      //     this.$store.commit('setTempActiveSideBarItem', '');
      //   } else if (activeSideBarItem == 'Schema') {
      //     this.$router.push(`/DMN/schemas`);
      //     this.$store.commit('setTempActiveSideBarItem', '');
      //   } else if (activeSideBarItem == 'Help') {
      //     window.open('https://halleyx-documentation-ghubgwxrcq-uc.a.run.app');
      //   } else if (activeSideBarItem == 'Rule studio') {
      //     this.$router.push({
      //       name: 'business-rules',
      //       query: { Value: 'All' },
      //     });
      //     this.$store.commit('setTempActiveSideBarItem', '');
      //   } else if (activeSideBarItem === 'Database') {
      //     this.$router.push(`/DMN/reference_data`);
      //     this.$store.commit('setTempActiveSideBarItem', '');
      //   } else if (activeSideBarItem == 'Execute') {
      //     this.$router.push(`/DMN/rule_validation`);
      //     this.$store.commit('setTempActiveSideBarItem', '');
      //   } else if (activeSideBarItem == 'setting') {
      //     this.$router.push(`/settings`);
      //     this.$store.commit('setTempActiveSideBarItem', '');
      //   } else if (activeSideBarItem == 'Audit') {
      //     this.$router.push(`/DMN/audit`);
      //     this.$store.commit('setTempActiveSideBarItem', '');
      //   } else {
      //     console.log('else', activeSideBarItem);
      //   }
      // }
      if (activeSideBarItem !== "") {
        if (activeSideBarItem === "UPC") {
          this.$router.push(`/UPC/dashboard`);
          this.$store.commit("setTempActiveSideBarItem", "");
        } else if (activeSideBarItem === "Product") {
          this.$router.push(`/UPC/product-catalog-table/product`);
          this.$store.commit("setTempActiveSideBarItem", "");
        } else if (activeSideBarItem === "Service") {
          this.$router.push(`/UPC/product-catalog-table/service`);
          this.$store.commit("setTempActiveSideBarItem", "");
        } else if (activeSideBarItem === "Resource") {
          this.$router.push(`/UPC/product-catalog-table/resource`);
          this.$store.commit("setTempActiveSideBarItem", "");
        } else if (activeSideBarItem === "DMN") {
          this.$router.push(`/DMN/dashboard`);
          this.$store.commit("setTempActiveSideBarItem", "");
        } else if (activeSideBarItem === "Rule studio") {
          this.$router.push(`/DMN/rule_studio`);
          this.$store.commit("setTempActiveSideBarItem", "");
        } else if (activeSideBarItem === "Rule validation") {
          this.$router.push(`/DMN/rule_validation`);
          this.$store.commit("setTempActiveSideBarItem", "");
        } else if (activeSideBarItem === "Audit log") {
          this.$router.push(`/DMN/audit`);
          this.$store.commit("setTempActiveSideBarItem", "");
        } else if (activeSideBarItem === "Schema") {
          this.$router.push(`/schemas`);
          this.$store.commit("setTempActiveSideBarItem", "");
        } else if (activeSideBarItem === "Data management") {
          this.$router.push(`/reference_data`);
          this.$store.commit("setTempActiveSideBarItem", "");
        } else if (activeSideBarItem === "Help") {
          window.open(`https://halleyx-documentation-ghubgwxrcq-uc.a.run.app`);
          this.$store.commit("setTempActiveSideBarItem", "");
        } else if (activeSideBarItem === "Settings") {
          this.$router.push(`/settings`);
          this.$store.commit("setTempActiveSideBarItem", "");
        } else if (activeSideBarItem === "Log out") {
          this.$router.push(`/`);
          this.$store.commit("setTempActiveSideBarItem", "");
        }
      } else {
        this.back();
        // console.log('empty');
        // this.$router.push(`/DMN/business_rules`);  //replace this route with your page route
      }
      this.unsavedChanges = false;
      this.canLeave = true;
      this.showAlert = false;
    },

    elseCheck() {
      this.isElse = !this.isElse;
    },
    previewEmail(html) {
      this.showPreview = true;
      const htmlToInject = html;
      const myDiv = document.getElementById("myDiv");
      myDiv.innerHTML = htmlToInject;
    },
    testAPI() {
      this.showRes = !this.showRes;
    },
    chooseAction(val, action) {
      val;

      switch (action.type) {
        case "assign":
          // action.config.lhs = ""
          // action.config.op = ""
          // action.config.rhs = ""
          break;
        case "compute":
          // action.config.expression = ""
          break;
        case "api":
          action.config.headers = [{ key: "", value: "" }];
          action.config.params = [{ key: "", value: "" }];
          action.config.auth_type = "No auth";
          break;
      }
    },
    chooseElse(val, action) {
      val;

      switch (action.type) {
        case "assign":
          // action.config.lhs = ""
          // action.config.op = ""
          // action.config.rhs = ""
          break;
        case "compute":
          // action.config.expression = ""
          break;
        case "api":
          action.config.headers = [{ key: "", value: "" }];
          action.config.params = [{ key: "", value: "" }];
          action.config.auth_type = "No auth";
          break;
      }
    },
    addGroup() {
      let group = {
        conditions: [{ lhs: "", op: "", rhs: "" }],
        conjunction: "and",
        outerConjunction: "and",
      };
      this.builderData.group.push(group);
    },
    addAction() {
      let group = { type: "assign", config: {} };
      this.builderData.actions.push(group);
    },
    addCondition(group) {
      let conditionSet = { lhs: "", op: "", rhs: "" };
      this.builderData.group[group].conditions.push(conditionSet);
      //
    },
    deleteAction(index) {
      this.builderData.actions.splice(index, 1);
    },
    addElse() {
      let group = { type: "assign", config: {} };
      this.builderData.alternates.push(group);
    },
    deleteElse(index) {
      this.builderData.alternates.splice(index, 1);
    },
    deleteCondition(group, condition) {
      this.builderData.group[group].conditions.splice(condition, 1);
      if (this.builderData.group[group].conditions.length <= 0) {
        this.builderData.group.splice(group, 1);
      }
    },
    addHeader(action) {
      let grp = { key: "", value: "" };
      action.config.headers.push(grp);
      //
    },
    deleteHeader(action, index) {
      action.config.headers.splice(index, 1);
    },
    addParam(action) {
      let grp = { key: "", value: "" };
      action.config.params.push(grp);
      //
    },
    deleteParam(action, index) {
      action.config.params.splice(index, 1);
    },
    flagg(val) {
      this.flagger = val;
    },
    addCalc(val) {
      this.isAdd = val;
    },
    editCalc(val) {
      this.isEdit = val;
    },
    payload(val) {
      this.apiPayload = val;
      if (Object.keys(this.apiPayload).includes("name")) {
        this.apiName = this.apiPayload.name;
      }
    },

    //     editPayload(val){
    //       this.apiPayload = val
    //       if(Object.keys(this.apiPayload).includes('name')){

    // this.apiName = this.apiPayload.name
    // }
    //     },
    addToHeader() {
      this.objj = {
        key: this.add_payload.credential.apiKey,
        value: this.add_payload.credential.apiValue,
      };
      let keys = this.add_payload.headers.map((i) => i.key);
      if (keys.includes(this.add_payload.credential.apiKey)) {
        this.add_payload.headers.find(
          (i) => i.key == this.add_payload.credential.apiKey
        ).value = this.add_payload.credential.apiValue;
      } else {
        this.add_payload.headers.push(this.objj);
      }
    },
    navTo(active) {
      this.$store.commit("token", this.$route.params.token);

      if (active.name == "Home") {
        this.navHome();
      } else if (active.name == "logout") {
        this.logout();
      } else if (active.name == "settings") {
        this.setting();
      } else if (active.name == "Schema") {
        this.schema();
      } else if (active.name == "Business rule") {
        this.ruled();
      } else if (active.name === "Database") {
        this.execute();
      } else if (active.name == "Help") {
        this.help();
      } else if (active.name == "Execute") {
        this.exe();
      } else {
        this.audit();
        //
      }
    },
    audit() {
      this.$router.push(`/DMN/audit`);
    },
    ruled() {
      this.$router.push(`/DMN/business_rules`);
    },
    exe() {
      this.$router.push(`/DMN/data_execution`);
    },
    execute() {
      this.$router.push(`/DMN/reference_data`);
    },
    schema() {
      this.$router.push(`/DMN/schemas`);
    },

    // logout() {
    //   this.$router.push('/')

    // },
    setting() {
      this.$router.push(`/settings`);
    },
    async navHome() {
      this.$router.push(`/DMN/dashboard`);
    },

    scrollToEnd() {
      const scrollableDiv = document.querySelector(".layout-body");
      const lastChild = scrollableDiv.lastElementChild;
      lastChild.scrollIntoView({ behavior: "smooth" });
    },
    pathTo(val) {
      if (val === "Rule studio") {
        this.$router.push({ name: "business-rules", query: { Value: "All" } });
      } else if (val === "Expression") {
        this.$router.push({
          name: "business-rules",
          query: { Value: "Expressions" },
        });
      }
    },

    //    pathTo() {
    //   //
    //   this.$router.back();
    // },

    paramKey(e, index, attr) {
      if (attr === "key") {
        this.add_payload.params[index].key = e;
      }
      if (attr === "value") {
        this.add_payload.params[index].value = e;
      }
    },
    addParamRow() {
      const obj = {};
      obj.key = "";
      obj.value = "";
      obj.index = "";
      this.add_payload.params.push(obj);
    },
    deleteParamRow(index) {
      this.add_payload.params.splice(index, 1);
    },
    headerKey(e, index, attr) {
      if (attr === "key") {
        this.add_payload.headers[index].key = e;
      }
      if (attr === "value") {
        this.add_payload.headers[index].value = e;
      }
    },
    addHeaderRow() {
      const obj = {};
      obj.key = "";
      obj.value = "";
      obj.index = "";
      this.add_payload.headers.push(obj);
    },
    deleteHeaderRow(index) {
      this.add_payload.headers.splice(index, 1);
    },
    authSelected(val, action) {
      action.config.auth = val.name;
      //
      this.flag = false;
      switch (val) {
        case "No auth":
          action.config.auth = {};
          break;

        case "Basic auth":
          action.config.auth = {
            username: "",
            password: this.add_payload.credential.password,
          };
          break;

        case "Bearer token":
          action.config.auth = { token: "" };

          break;

        case "API key":
          //  action.config.auth = {}
          action.config.auth = { key: "", value: "" };

          // this.flag = true

          break;
      }
    },
    bodyPayload(val) {
      this.apiBody = val;
    },
    sendAPIData() {
      this.add_payload.requestBody = this.apiBody;

      this.response = true;
      setTimeout(() => {
        this.scrollToEnd();
      }, 100);
    },
    cancelApiAdd() {
      this.add_payload = {
        name: "",
        description: "",
        method: "",
        url: "",
        requestBody: {},
        schedule: "",
        params: [{ key: "", value: "" }],
        headers: [{ key: "", value: "" }],
        auth: "",
        credential: {},
      };
      this.$router.back();
    },

    async saveAPIData() {
      this.if = "";
      this.then = "";
      this.else = "";
      let rule;
      if (this.builderData.type == "assign") {
        rule = `(${
          this.builderData.config.lhs + " = " + this.builderData.config.rhs
        })`;
      } else {
        rule = this.builderData.config.expression;
      }

      this.customnameerror = false;
      if (this.name.length > 0) {
        let response = await DMNAxiosInstance.patch(
          `rule_engine/rule/${this.$route.params.id}`,
          {
            name: this.name,
            description: this.description,
            ruleScript: rule,
            visualBuilderPayload: this.builderData,
            executionByPass: this.executionByPass,
            isAtomic: this.isAtomic,
            status: this.statusVal,
            type: "expression",
            buildMode: this.buildMode,
            ruleSet: this.rulesetarray,
            //  createdBy: '639af00f9f515f57074584ef',
            _isExistBypass: false,
          }
        );
        //
        this.errMsg = [];
        if (response.data.ok == 0) {
          this.errMsg = response.data.message.map((i) => i.message);
        }
      }
      if (!this.customnameerror) {
        if (this.errMsg.length > 0) {
          this.notification = { type: "warning", message: this.errMsg[0] };
        } else {
          this.notification = {
            type: "success",
            message: "Rule updated successfully",
          };
          // this.canLeave = true;
          this.unsavedChanges = false;
          // this.showAlert = false;
          setTimeout(() => {
            this.$router.push({
              name: "business-rules",
            });
          }, 2000);
        }
      }
    },
    // async saveAPIData() {
    //   this.add_payload.requestBody = this.apiBody
    //   this.add_payload.headers = this.add_payload.headers.filter(i=>i.key.length>0)
    //   let obj = {name: this.add_payload.name, description: this.add_payload.description, apiConfig:{method: this.add_payload.method,
    //     url: this.add_payload.url,
    //     requestBody: this.add_payload.requestBody,
    //     schedule: this.add_payload.schedule,
    //     params: this.add_payload.params,
    //     headers: this.add_payload.headers,
    //     auth: this.add_payload.auth,
    //     credential: this.add_payload.credential},refreshConfig:{}}
    //     let arr = []
    //     arr.push(obj)
    //   await DMNAxiosInstance.post(`/api/add`, arr).then(
    //   (res) => {
    //
    //     setTimeout(() => {

    //         this.$router.push({
    //           name: 'list-api',
    //         });

    //       }, 3000);
    //   }
    // );
    //
    // },

    //     async testAPI(){
    //       this.add_payload.requestBody = this.apiBody;

    //       // async function makeRequest(method, url, headers, params, authorization, requestBody) {
    // try {
    //   this.add_payload.headers = this.add_payload.headers.filter(i=>i.key.length>0)
    //   let apiMethod = this.add_payload.method
    //   let apiUrl = this.add_payload.url
    //   let apiHeaders = {}
    //   if(this.add_payload.headers.length>0 && Object.values(this.add_payload.headers[this.add_payload.headers.length-1])[0].length>0){
    //     apiHeaders = this.add_payload.headers.reduce((result, item) => {
    //   result[item.key] = item.value;
    //   return result;
    // }, {});
    //   }

    //   //
    //   let apiParams = {}
    //   if(this.add_payload.params.length>0 && Object.values(this.add_payload.params[this.add_payload.params.length-1])[0].length>0){
    //     apiParams = this.add_payload.params.reduce((result, item) => {
    //   result[item.key] = item.value;
    //   return result;
    // }, {});
    //   }
    // let apiBody = this.add_payload.requestBody
    // let auth = this.auth
    // const startTime = new Date();

    //   let config = {}
    //   if(this.add_payload.auth == 'API key'){
    //     config = {
    //     method: apiMethod,
    //     url: apiUrl,
    //     headers: apiHeaders,
    //     params: apiParams,
    //     data: apiBody
    //   }
    //   }else{
    //     config = {method: apiMethod,
    //     url: apiUrl,
    //     headers: apiHeaders,
    //     params: apiParams,
    //     data: apiBody,
    //   auth}
    //   }
    //  await axios(config).then((res)=>{
    //     const endTime = new Date();
    //   this.response_time = endTime - startTime;
    //     this.apiResponse = JSON.stringify(res.data,null,2)
    //     this.response = true;
    //     this.response_status = res.status
    //   })
    //       setTimeout(() => {
    //         this.scrollToEnd();
    //       }, 100);
    // } catch (error) {
    //   console.error(error);
    // }

    //     },

    async currentWord(val) {
      //
      var pattern = /API\((.*?)\)/;

      var str = val;
      var matches = str.match(pattern);
      await DMNAxiosInstance.get(`/api`).then((res) => {
        this.apis = res.data.data.map((val) => val.name);
        let keywords = this.list.keywords.map((val) => val.label);
        this.apis.forEach((item) => {
          if (!keywords.includes(item)) {
            let obj = {};
            obj["label"] = `API(${item})`;
            this.list.keywords.push(obj);
          }
        });
      });
      await DMNAxiosInstance.get(`/email`).then((res) => {
        this.apis = res.data.data.map((val) => val.name);
        let keywords = this.list.keywords.map((val) => val.label);
        this.apis.forEach((item) => {
          if (!keywords.includes(item)) {
            let obj = {};
            obj["label"] = `Email(${item})`;
            this.list.keywords.push(obj);
          }
        });

        //
      });
      if (matches != null) {
        this.isEmail = false;
        this.isApi = true;

        await DMNAxiosInstance.get(`/api`).then((res) => {
          this.logs = res.data.data;
          this.apis = res.data.data.map((val) => val.name);
          let keywords = this.list.keywords.map((val) => val.label);
          this.apis.forEach((item) => {
            if (!keywords.includes(item)) {
              let obj = {};
              obj["label"] = `API(${item})`;
              // this.list.keywords.push(obj)
            }
          });

          //
        });
      } else {
        pattern = /Email\((.*?)\)/;
        str = val;
        matches = str.match(pattern);
        if (matches != null) {
          this.isApi = false;
          this.isEmail = true;
          await DMNAxiosInstance.get(`/email`).then((res) => {
            this.logs = res.data.data;
            this.apis = res.data.data.map((val) => val.name);
            let keywords = this.list.keywords.map((val) => val.label);
            this.apis.forEach((item) => {
              if (!keywords.includes(item)) {
                let obj = {};
                obj["label"] = `Email(${item})`;
                // this.list.keywords.push(obj)
              }
            });
          });
        }
      }
      if (matches && matches.length > 1) {
        var textBetweenParentheses = matches[1];
        if (textBetweenParentheses.length <= 0) {
          this.isAdd = true;
          this.isEdit = false;
          this.flagg = false;
        } else if (textBetweenParentheses.length > 0) {
          //
          this.isAdd = false;
          this.isEdit = true;
          this.flagg = true;

          let found = this.logs.find(
            (item) => item.name == textBetweenParentheses
          );

          this.editApiPayload = found;
        }
        //
      }
    },
    selectedOption(val, condition, part) {
      if (part == "lhs") {
        let values = val.map((i) => i.value);
        condition.lhsArr = values;
        condition[part] = val.map((obj) => obj.value).join(" ");
      } else if (part == "rhs") {
        let values = val.map((i) => i.value);
        condition.rhsArr = values;
        condition[part] = val.map((obj) => obj.value).join(" ");
      }
    },
    statusCheck(sts) {
      this.status = sts;
    },
    async addRuleset(val1, val2) {
      let obj = {};
      obj.name = val1.name;
      let ruleSetDataRes = await DMNAxiosInstance.post(
        `/rule_engine/rule_set/add`,
        obj
      );
      if (ruleSetDataRes.data.ok == 0) return;
      let addedData = ruleSetDataRes.data.data;
      addedData.checked = true;
      addedData.disabled = false;
      let adddataoptions = [];
      adddataoptions = val2;
      adddataoptions.push(addedData);
      this.ruleSetData = adddataoptions;
      this.rulesetarray = this.ruleSetData
        .map((item) => {
          if (item.checked) {
            return item._id;
          }
        })
        .filter((item) => item);
      //  console.log(this.rulesetarray);
      this.ruleSetPreValue = this.rulesetarray;
    },
    // Handle ruleset action
    selectValue(val) {
      if (val && Array.isArray(val)) {
        this.rulesetarray = val
          .map((item) => {
            if (item.checked) {
              return item._id;
            }
          })
          .filter((item) => item);
        // console.log(this.rulesetarray);
        this.ruleSetPreValue = this.rulesetarray;
      }
    },
    convertToExpression(obj) {
      let expression = "";
      let levels = ["level1", "level2", "level3", "level4", "level5"];
      for (let i = 0; i < levels.length; i++) {
        let level = levels[i];
        let levelObj = obj[level];
        if (!levelObj) continue;
        // expression += "(";
        for (let j = 0; j < levelObj.groups.length; j++) {
          let group = levelObj.groups[j];
          expression += "(";
          for (let k = 0; k < group.group.length; k++) {
            let conditions = group.group[k].conditions;
            for (let l = 0; l < conditions.length; l++) {
              let condition = conditions[l];
              expression += "(";
              expression += `${condition.lhs} ${condition.op} ${condition.rhs}`;
              expression += ")";
              if (l < conditions.length - 1) {
                expression += ` ${group.group[k].inner_group_conjunction} `;
              }
            }
            if (k < group.group.length - 1) {
              expression += ` ${group.group_conjunction} `;
            }
          }
          expression += ")";
          if (j < levelObj.groups.length - 1) {
            expression += ` ${group.group_conjunction} `;
          }
        }
        // expression += ")";
        if (i < levels.length - 1 && levelObj.level_conjunction) {
          expression += ` ${levelObj.level_conjunction} `;
        }
      }
      return expression;
    },
    goBack() {
      this.modal_active1 = !this.modal_active1;
    },

    help() {
      window.open("https://documentation.halleyx.com");
    },

    logout() {
      this.$router.push("/");
    },

    back() {
      // this.$router.push({ name: 'business-rules' })
      this.$router.push({ name: "business-rules", query: { Value: "All" } });

      // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules `;
    },

    preserveTreeBuilder() {
      // Save the state of the first instance in a variable
      this.preservedTreeBuilder = this.$refs["tree"];
    },
    grabAndMove() {
      const outerContainer = document.querySelector(".outer-container");

      outerContainer.addEventListener("mousedown", this.handleMouseDown);
      outerContainer.addEventListener("mousemove", this.handleMouseMove);
      outerContainer.addEventListener("mouseup", this.handleMouseUp);
    },
    showElse(icon) {
      if (icon == "icon-plus-circle-regular") {
        this.else_icon = "icon-trash-regular";
      } else if (icon == "icon-trash-regular") {
        this.else_icon = "icon-plus-circle-regular";
      }
    },
    removeTrailingOperators(expression) {
      const operatorsRegex = /(and|or)\s*$/i;
      let result = expression.trim().replace(operatorsRegex, "");
      while (operatorsRegex.test(result)) {
        result = result.replace(operatorsRegex, "");
      }
      return result.trim();
    },
    chosen(val) {
      this.content_type = val.name;
      // //
      if (val.name.toLowerCase() == "code") {
        let a = this.convertToExpression(this.if_obj);
        this.iff = this.removeTrailingOperators(a).replace(
          /\((\([^()]+\))\)/g,
          "$1"
        );
        let b = this.convertToExpression(this.then_obj);
        this.thenn = this.removeTrailingOperators(b).replace(
          /\((\([^()]+\))\)/g,
          "$1"
        );
        let c = this.convertToExpression(this.else_obj);
        this.elsee = this.removeTrailingOperators(c).replace(
          /\((\([^()]+\))\)/g,
          "$1"
        );
        this.visualExp = `if ${this.iff} then ${this.thenn}`;
        if (Object.keys(this.elsee).length > 0) {
          this.visualExp += ` else ${this.elsee}`;
        }
        // this.contentExp = this.removeTrailingOperators(this.visualExp)
      }

      this.contentExp = this.removeTrailingOperators(this.visualExp);
    },
    async saveRule() {
      if (this.name != "") {
        // if(this.name != '' && this.contentExp != '')
        //
        this.customeditorerror = false;
        this.customnameerror = false;
        this.success = false;
        this.warning = false;
        this.warning_msg = "";
        if (this.content_type.toLowerCase() == "visual") {
          //

          this.visualExp = `if ${this.iff} then ${this.thenn}`;
          // //
          if (Object.keys(this.elsee).length > 0) {
            this.visualExp += ` else ${this.elsee}`;
          }

          this.contentExp = this.removeTrailingOperators(this.visualExp);
        }
        //
        // let ruleStatus = this.status?'active':'draft';
        let ruleStatus = this.status.name?.toLowerCase();
        //
        await DMNAxiosInstance.post(
          `rule_engine/rule/add`,
          {
            name: this.name,
            ruleScript: this.contentExp,
            visualBuilderPayload: {
              if: this.if_obj,
              then: this.then_obj,
              else: this.else_obj,
            },
            executionByPass: this.executionByPass,
            isAtomic: this.isAtomic,
            status: ruleStatus,
            type: "rule",
            buildMode: this.content_type,
            ruleSet: this.rulesetarray,
            createdBy: "639af00f9f515f57074584ef",
            description: this.description,
            _isExistBypass: false,
          }
          //   // {
          //   //   auth: {
          //   //     username: "testuser@test.com",
          //   //     password: " ",
          //   //   },
          //   // }
        ).then((res) => {
          if (res.data.ok == 1) {
            this.success = true;
            this.notification = { type: "success", message: this.success_msg };

            this.warning = false;
            this.warning_msg = "";
            setTimeout(() => {
              this.$router.push({
                name: "business-rules",
                query: { Value: "All" },
              });

              // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules `;
            }, 3000);
          } else {
            // this.warning = true;
            this.success = false;
            this.warning_msg = res.data.data;
          }
        });
      } else {
        if (this.name === "") {
          this.customnameerror = true;
          this.customeditorerror = false;
        }
      }
    },
    content(val) {
      this.contentExp = val;
      this.showSwitch = false;
    },
    // selectValue(val) {
    //   // val;
    //   this.ruleset = [];
    //   val.forEach((v) => {
    //     if (v.checked == true) {
    //       this.ruleset.push(v["_id"]);
    //     //   //
    //     }
    //   });
    //
    // },
    emitIf(rule) {
      this.iff = this.removeTrailingOperators(rule);
      //
    },
    emitIfObj(val) {
      this.if_obj = val;
      //
      val;
    },
    emitThenObj(val) {
      val;
      this.then_obj = val;
    },
    emitElseObj(val) {
      this.else_obj = val;
      val;
    },
    emitThen(rule) {
      //
      if (typeof rule == "string") {
        this.thenn = this.removeTrailingOperators(rule);
      }
    },
    emitElse(rule) {
      this.elsee = this.removeTrailingOperators(rule);
    },
    async intelliData() {
      await DMNAxiosInstance.get(
        `rule_engine/schema`
        // {
        //   auth: {
        //     username: "testuser@test.com",
        //     password: " ",
        //   },
        // }
      ).then((res) => {
        //
        this.schemas = res.data.data;
      });

      // this.schemas = []
      let obj = {};
      this.schemas.forEach((s) => {
        // //
        obj[s.name] = s.schema;
        // this.schemas.push(obj)
      });
      const keyify = (obj, prefix = "") =>
        Object.keys(obj).reduce((res, el) => {
          if (el != "type" && el != "href" && el != "dataRef") {
            // //
            if (Array.isArray(obj[el])) {
              return res;
            } else if (typeof obj[el] === "object" && obj[el] !== null) {
              // //
              return [...res, ...keyify(obj[el], prefix + el + ".")];
            }
          }
          return [...res, prefix];
        }, []);

      let output = [];
      output = keyify(obj)
        .sort()
        .map((string) => string.slice(0, -1));
      output = [...new Set(output)];

      Object.byString = function (o, s) {
        s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
        s = s.replace(/^\./, ""); // strip a leading dot
        var a = s.split(".");
        for (var i = 0, n = a.length; i < n; ++i) {
          var k = a[i];
          if (k in o) {
            o = o[k];
          } else {
            return;
          }
        }
        return o;
      };

      let arr = [];
      output.forEach((o) => {
        let objj = {};
        objj["label"] = o;
        objj["type"] = Object.byString(obj, o).type;
        arr.push(objj);
      });
      this.list.schema = arr;
      //
      let arrr = [];
      output.forEach((o) => {
        let objj = {};
        objj["name"] = o;
        objj["value"] = o;
        objj["checked"] = false;
        objj["disabled"] = false;
        objj["id"] = Math.floor(Math.random() * 1000 + 1);
        objj["type"] = Object.byString(obj, o).type;
        arrr.push(objj);
      });
      this.lhs_data = arrr;
      //
    },
    handleZoomIn() {
      const innerContainer = document.querySelector(".inner-container");
      this.scale += 0.1;
      innerContainer.style.transform = `scale(${this.scale}) translate(${this.translateX}px, ${this.translateY}px)`;
    },

    handleZoomOut() {
      const innerContainer = document.querySelector(".inner-container");
      if (this.scale > this.minScale) {
        this.scale -= 0.1;
        innerContainer.style.transform = `scale(${this.scale}) translate(${this.translateX}px, ${this.translateY}px)`;
      }
    },

    handleReset() {
      const innerContainer = document.querySelector(".inner-container");
      this.scale = 1;
      this.translateX = 0;
      this.translateY = 0;
      innerContainer.style.transform = `scale(${this.scale}) translate(${this.translateX}px, ${this.translateY}px)`;
    },

    handleMouseDown(event) {
      const outerContainer = document.querySelector(".outer-container");
      if (event.target === outerContainer) return;
      this.isDragging = true;
      this.startX = event.clientX - this.translateX;
      this.startY = event.clientY - this.translateY;
      outerContainer.style.cursor = "grabbing";
    },

    handleMouseMove(event) {
      const outerContainer = document.querySelector(".outer-container");
      const innerContainer = document.querySelector(".inner-container");
      if (this.isDragging) {
        this.translateX = event.clientX - this.startX;
        this.translateY = event.clientY - this.startY;
        innerContainer.style.transform = `scale(${this.scale}) translate(${this.translateX}px, ${this.translateY}px)`;
      } else if (event.target === innerContainer) {
        outerContainer.style.cursor = "grab";
      } else {
        outerContainer.style.cursor = "default";
      }
    },

    handleMouseUp() {
      const outerContainer = document.querySelector(".outer-container");

      this.isDragging = false;
      outerContainer.style.cursor = "default";
    },
    closeModal() {
      //     var parent = document.querySelector('.modal-content');

      // parent.innerHTML = '';
      this.modal_active1 = false;
    },
    expand() {
      // this.handleReset()
      //
      this.modal_active1 = true;
      //     var parent1 = document.getElementById('outer-container');
      //   var child1 = document.getElementById('inner-container');
      //   var parent2 = document.querySelector('.modal-content');

      //   var child2 = child1.cloneNode(true);
      // //   var child2 = $(child1).clone(true, true);

      //   parent1.appendChild(child1);
      //   parent2.appendChild(child2);
      // $('.modal-content').append(child2)
    },
  },
};
</script>

<style lang="scss">
.icon-trash-regular {
  color: #ff5050;
  cursor: pointer;
}
.icon-plus-circle-regular {
  cursor: pointer;
}

.layout-footer {
  .footer-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .primary {
      margin-left: 20px;
    }
  }
}
.add-rule {
  .hlx-multi-select-container .hlx-multi-select-input-placeholder {
    color: #a6a6a6 !important;
    font-size: 14px;
  }
}
//  .my-editor {
//      margin-top: 0 !important;
//    }
.cards-container {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 3vw;
  justify-items: center;
  align-items: center;
}

@media (max-width: 1100px) {
  .cards-container {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    gap: 3vw;
    justify-items: center;
    align-items: center;
  }
}
@media (max-width: 750px) {
  .cards-container {
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    gap: 3vw;
    justify-items: center;
    align-items: center;
  }
}
.alignn {
  height: 60px;
  padding-left: 20px;
  align-items: center;
}
.fontt {
  font-weight: bold !important;
  font-family: var(--hlx-font-type-title);
  font-size: 14px;
  color: #191a15;
  display: flex;
  margin-left: 16px;
  margin-top: 16px;
  align-items: center;
  .icon-plus-circle-regular {
    color: #54bd95;
    font-size: 18px;
    margin-left: 8px;
  }
  .icon-trash-regular {
    color: #a6a6a6;
    font-size: 18px;
    margin-left: 8px;
  }
}
.inner-container {
  display: flex;
  flex-direction: column;
  position: relative !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform-origin: left top;
  overflow: auto;
  // padding: 30px;
}
.outer-container {
  // border: 1px solid #d8d8d8;
  // padding: 16px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100% !important;
  // height: 500px;
}
// .hlx-btn-switch-group{
//   justify-content: flex-end;
// }
</style>
