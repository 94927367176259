<template>
  <hlx-alert-notification :show-alert="success" :notify="notification" :auto-close="true" />

  <div class="add-schema-modal">
    <hlx-modal
:modal-active="referenceSchema" :width="'1100px'" :height="'600px'" :modal="true"
      @close="referenceSchema = false">
      <template #header>
        <div>Add Schema</div>
      </template>
      <template #content>
        <div class="modal-content">
          <main class="--layout-2-way">
            <!-- Left panel -->
            <section class="--layout-inner-left-portion --LL30">
              <!-- Breadcrumb -->
              <div class="schema-info --info-left-panel">
                <!-- Title -->
                <span class="schema-title -info-title">Add Schema
                </span>

                <!-- schema name -->
                <div class="schema-name-wrapper">
                  <hlx-input
v-model:value="name1" :pre-val="name1" :label-value="'Name'" :label-animation="true"
                    :required="true" :display-error="namecustomerror" :custom-error="namecustomerror"
                    :custom-error-message="'Please fill the field'"></hlx-input>
                </div>

                <!-- schema description -->
                <div class="schema-description-wrapper">
                  <hlx-input
v-model:value="description1" :pre-val="description1" :label-animation="true"
                    label-value="Description" type="textarea" :clearable="true" />
                </div>
              </div>
            </section>
            <!-- Right panel -->
            <section class="schema-view --layout-inner-right-portion --RL70" style="height:100%">
              <div class="panel-layout-body">
                <div class="source-area grid">
                  <span class="--right-panel-title">Type </span>
                  <hlx-switch
v-model="selectedValue" :switch-items="switch_items1" value-prop="value"
                    display-prop="name" @change="chosen" />
                </div>
                <div class="design-area">
                  <!-- {{this.tableData}} -->
                  <p class="--right-panel-title --morgin-bottom">Model</p>

                  <span class="grid-local-new-schema-right manual-schema-view">
                    <div v-if="manual === true && sidebaropen === true" class="grid-local-new-schema-right-container">
                      <div class="grid-local-new-schema-right-container-head">
                        <span
v-for="(i, index) in theads" :key="index" class="schema-view-manual-heads"
                          :class="'--' + i.label">
                          <span v-if="i.prop === 'schema'">
                            {{ i.label }}
                          </span>
                          <span v-if="i.prop === 'type'">{{
    i.label
  }}</span>
                          <span v-if="i.prop === 'required'" class="--required">{{
    i.label
  }}</span>
                          <span v-if="i.prop === 'reference'">{{
    i.label
  }}</span>
                          <span v-if="i.prop === 'dependency_tree'">{{ i.label }}</span>
                        </span>
                      </div>
                      <div class="grid-local-new-schema-right-container-content --view-schema">
                        <span v-for="(i, index) in tableData1" :key="index" class="schema-view-manual-body">
                          <!-- style="margin-right: 10px" -->
                          <span
v-for="(j, col_index) in theads" :key="col_index"
                            :class="{ '--required': j.prop == 'required' }">
                            <!-- {{ j }} -->
                            <span>
                              <div v-if="j.prop === 'schema'">
                                <hlx-input
v-model:value="i[j.prop]" :label-animation="false" :pre-val="i[j.prop]"
                                  type="text" :clearable="false" />
                              </div>
                              <span v-if="j.prop === 'type'">
                                <hlx-select
v-model:value="i[j.prop]"  :pre-value="i[j.prop]" :options="data1" :placeholder-value="''"
                                  :label-animation="true" :inline-search="true" :name="'name'" :value="'value'"
                                  @custom-change="checkval1($event, index)"></hlx-select>
                              </span>
                              <!-- <span v-if="j.prop === 'list'"> <hlx-input :checked="i[j.prop]" :type="'single-checkbox'" v-model:value="i[j.prop]" /> </span> -->
                              <span v-if="j.prop === 'required'">
                                <hlx-input v-model:value="i[j.prop]" :checked="i[j.prop]" :type="'single-checkbox'" />
                              </span>
                              <span v-if="j.prop === 'reference'">
                                <hlx-select
v-if="i['type'] === 'Array' || i['type'] === 'Object'"
                                  v-model:value="i['reference']['refConfig']['name']" :pre-value="i['reference']['refConfig']['name']" :options="allref" :label="'name'"
                                  :prop-value="'value'" :placeholder-value="'Reference'" :width-container="'250'"
                                  :label-animation="false" :inline-search="true"
                                  @selected-value="optionSelected1($event, index)">
                                </hlx-select>
                                <span v-else class="na">NA</span>
                              </span>

                            </span>
                          </span>

                          <span v-if="!show_view" class="manual-add-delete">
                            <div>
                              <i
v-if="i['type'] === 'Array' || i['type'] === 'Object'" class="icon-settings-regular"
                                style="margin-right: 10px; font-size: 20px; cursor: pointer"
                                @click="subrefer(index)"></i>
                            </div>
                            <div>
                              <i
class="icon-plus-circle-filled" style="margin-right: 10px; font-size: 20px"
                                @click="addsubschemabuilder"></i>
                            </div>
                            <div>
                              <i
v-if="tableData1.length > 1" class="icon-trash-regular" style="font-size: 20px"
                                @click="deletesubschemabuilder(index)"></i>
                            </div>
                          </span>
                        </span>
                      </div>
                    </div>

                    <!-- * upload -->
                    <div v-if="upload === true" class="grid-local-new-schema-right-container">
                      <!-- <hlx-file-upload
                :file-type="'.xlsx,.png,.json'"
                :file-size-limit="'20mb'"
                :custom-icon="icon"
                :type="'regular'"
                @file-data="handleUploadedSchema"
              /> -->
                    </div>
                    <!-- upload * -->
                    <span v-if="api == true" class="api-data-content">
                      <div class="method-url">
                        <span class="method">
                          <hlx-select :options="method_options"></hlx-select>
                        </span>
                        <span class="url">
                          <hlx-input type="text" :label-value="'Enter request URL'"></hlx-input>
                        </span>
                        <span class="schedule">
                          <div class="choose-schedule">
                            <i class="icon-clock-regular"></i>
                          </div>
                        </span>
                      </div>
                    </span>
                  </span>
                  <!-- * upload -->
                </div>
                <div v-if="api == true" class="grid">
                  <span class="titles">URL parameters <span class="info"></span></span>
                  <span class="api-data-content">
                    <div class="params">
                      <div v-for="(param, index) in add_payload.params" :key="index" class="param-object">
                        <span class="key">
                          <hlx-input
:label-value="'Key'" :pre-val="add_payload.params[index].key"
                            @at-input="paramKey($event, index, 'key')"></hlx-input>
                        </span>
                        <span class="value"><hlx-input
:label-value="'Value'" :pre-val="add_payload.params[index].value"
                            @at-input="paramKey($event, index, 'value')"></hlx-input></span>
                        <span><i class="icon-plus-circle-filled" @click="addParamRow"></i></span>
                        <span class="delete-param"><i
v-if="add_payload.params.length > 1" class="icon-trash-filled"
                            @click="deleteParamRow(index)"></i></span>
                        <span class="delete-param"><i
v-if="add_payload.params.length > 1" class="icon-trash-regular"
                            @click="deleteParamRow(index)"></i></span>
                      </div>
                    </div>
                  </span>
                </div>
                <div v-if="api == true" class="grid">
                  <span class="titles">Headers <span class="info"></span></span>
                  <span class="api-data-content">
                    <div class="headers">
                      <div v-for="(header, index) in add_payload.headers" :key="index" class="header-object">
                        <span class="key">
                          <hlx-input
:label-value="'Key'" :pre-val="add_payload.headers[index].key"
                            @at-input="headerKey($event, index, 'key')"></hlx-input>
                        </span>
                        <span class="value"><hlx-input
:label-value="'Value'"
                            :pre-val="add_payload.headers[index].value"
                            @at-input="headerKey($event, index, 'value')"></hlx-input></span>
                        <span><i class="icon-plus-circle-filled" @click="addHeaderRow"></i></span>
                        <span class="delete-header"><i
v-if="add_payload.headers.length > 1" class="icon-trash-filled"
                            @click="deleteHeaderRow(index)"></i></span>
                      </div>
                    </div>
                  </span>
                </div>
                <div v-if="api == true" class="grid">
                  <span class="titles">Authorization <span class="info"></span></span>
                  <span class="api-data-content-auth">
                    <div class="auth">
                      <div class="auth-object">
                        <hlx-switch :type="'regular'" :switch-items="auth_switch_items" @chosen="authSelected" />
                      </div>
                    </div>
                    <div v-if="add_payload.auth === 'Basic auth'" class="auth-cred">
                      <div class="auth-cred-items">
                        <span class="key"><hlx-input
v-model:value="add_payload.credential.username"
                            :label-value="'Username'"></hlx-input></span>
                        <span class="value"><hlx-input
v-model:value="add_payload.credential.password"
                            :label-value="'Password'"></hlx-input></span>
                      </div>
                    </div>
                    <div v-if="add_payload.auth === 'Bearer token'" class="auth-cred">
                      <div class="auth-cred-items">
                        <span class="token"><hlx-input
v-model:value="add_payload.credential.token"
                            :label-value="'Token'"></hlx-input></span>
                      </div>
                    </div>
                    <div v-if="add_payload.auth === 'API key'" class="auth-cred">
                      <div class="auth-cred-items">
                        <span class="api-key"><hlx-input
v-model:value="add_payload.credential.apikey"
                            :label-value="'API key'"></hlx-input></span>
                      </div>
                    </div>
                  </span>
                </div>
                <div v-if="api == true" class="divider">
                  <hr />
                </div>
                <div v-if="api == true" class="grid">
                  <span class="titles">Request body
                    <span class="info">Define data in JSON format</span></span>
                  <span class="api-data-content-text">
                    <hlx-code-editor :line-number="true" :beautify="true" @code-changes="bodyPayload"></hlx-code-editor>
                    <div class="send-api">
                      <hlx-button class="primary sm" @click="sendAPIData">Send</hlx-button>
                    </div>
                  </span>
                </div>
                <div v-if="api == true && response == true" class="grid">
                  <span class="titles">Response <span class="info"></span></span>
                  <span v-if="api == true && response == true" class="api-data-content-text">
                    <div class="status">
                      <span class="title">Status: </span>
                      <span class="value">{{ response_status }}</span>
                      <span class="title">Time: </span>
                      <span class="value">{{ response_time }}</span>
                    </div>
                    <div class="response-result"></div>
                  </span>
                </div>
              </div>


            </section>

          </main>
        </div>
      </template>
      <template #footer>
        <hlx-button class="primary sm" @click="saveReferSchema()">Add</hlx-button>
      </template>
    </hlx-modal>
  </div>

  <hlx-modal
:modal-active="deletedialog" :height="'200px'" :width="'450px'" :modal="true"
    @close="deletedialog = false">
    <template #header>
      <div>Delete</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete the <b>{{ deletename }}?</b>
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary-success sm" @click="deletedialog = false">Cancel</hlx-button>
      <hlx-button class="primary sm deletedialogok" @click="deleteokmodal">Delete</hlx-button>
    </template>
  </hlx-modal>

  <hlx-modal
:modal-active="modal_active1" :height="'350px'" :width="'270px'" :modal="true"
    @close="modal_active1 = false">
    <template #header>
      <div>Edit data</div>
    </template>
    <template #content>
      <div class="modal-content1">
        <div class="add-data-modal" style="gap:12px;margin-top:10px ;">
          <span v-for="(i, index) in headers" :key="index" class="add-data-model-container">
            <!-- <span
                  v-if="
                    i.type === 'Number' ||
                    i.type === 'String' ||
                    i.type === 'Date' ||
                    i.type === 'Boolean'
                  "
                  class="add-data-model-container-left"
                  >                  <span v-if="i.required === true">
                    {{i.label.charAt(0).toUpperCase() + i.label.slice(1) }} <span style="color:#ff5050">*</span>
                    </span>
                    <span v-else>
                    {{ i.label.charAt(0).toUpperCase() + i.label.slice(1)}}
                    </span></span
                > -->

            <span v-if="i.type === 'String'" class="add-data-model-container-right">
              <!-- {{this.adddataobj[i.prop]}} -->
              <hlx-input
v-model:value="editdataobj[i.prop]" :label-animation="true" type="text"
                :pre-val="editdataobj[i.prop]" :label-value="i.label.charAt(0).toUpperCase() + i.label.slice(1)"
                :display-error="errorvalidation[i.prop]" :custom-error="errorvalidation[i.prop]"
                :custom-error-message="'Please fill the field'" /></span>
            <span v-if="i.type === 'Number'" class="add-data-model-container-right">
              <!-- {{i.prop}} -->
              <hlx-input
v-model:value="editdataobj[i.prop]" :label-animation="false" type="number" label-value=""
                :clearable="false" :pre-val="editdataobj[i.prop]" :display-error="errorvalidation[i.prop]"
                :custom-error="errorvalidation[i.prop]" :custom-error-message="'Please fill the field'" />
            </span>
            <span v-if="i.type === 'Date'" class="add-data-model-container-right">
              <hlx-input
v-model:value="editdataobj[i.prop]" :label-animation="false" :label-value="'DD/MM/YYYY'"
                type="text" :pre-val="editdataobj[i.prop]" :display-error="errorvalidation[i.prop]"
                :custom-error="errorvalidation[i.prop]" :custom-error-message="'Please fill the field'" />
            </span>
            <span v-if="i.type === 'Boolean'" class="add-data-model-container-right-radio">
              <hlx-input :options="options3" :type="'radio-buttons'" />
            </span>
          </span>
        </div>
      </div>
    </template>
    <template #footer>
      <span class="footer-button" style="gap:15px;width:100%">
        <hlx-button
class="secondary-success sm clear-button" style="width:100%"
          @click="clearsavedata">Cancel</hlx-button>
        <hlx-button class="primary sm" style="width:100%" @click="saveeditdata">Edit</hlx-button>
      </span>
    </template>
  </hlx-modal>

  <hlx-modal
:modal-active="modal_active" :height="'350px'" :width="'270px'" :modal="true"
    @close="modal_active = false">
    <template #header>
      <div>Add data</div>
    </template>
    <template #content>
      <div class="modal-content1">
        <div class="add-data-modal" style="gap:12px;margin-top:10px ;">
          <span v-for="(i, index) in headers" :key="index" class="add-data-model-container">
            <!-- <span
                  v-if="
                    i.type === 'Number' ||
                    i.type === 'String' ||
                    i.type === 'Date' ||
                    i.type === 'Boolean'
                  "
                  class="add-data-model-container-left"
                  >                  <span v-if="i.required === true">
                    {{i.label.charAt(0).toUpperCase() + i.label.slice(1) }} <span style="color:#ff5050">*</span>
                    </span>
                    <span v-else>
                    {{ i.label.charAt(0).toUpperCase() + i.label.slice(1)}}
                    </span></span
                > -->

            <span v-if="i.type === 'String'" class="add-data-model-container-right">
              <!-- {{this.adddataobj[i.prop]}} -->
              <hlx-input
v-model:value="adddataobj[i.prop]" :label-animation="true" type="text"
                :pre-val="adddataobj[i.prop]" :label-value="i.label.charAt(0).toUpperCase() + i.label.slice(1)"
                :display-error="errorvalidation[i.prop]" :custom-error="errorvalidation[i.prop]"
                :custom-error-message="'Please fill the field'" /></span>
            <span v-if="i.type === 'Number'" class="add-data-model-container-right">
              <!-- {{i.prop}} -->
              <hlx-input
v-model:value="adddataobj[i.prop]" :label-animation="false" type="number" label-value=""
                :clearable="false" :pre-val="adddataobj[i.prop]" :display-error="errorvalidation[i.prop]"
                :custom-error="errorvalidation[i.prop]" :custom-error-message="'Please fill the field'" />
            </span>
            <span v-if="i.type === 'Date'" class="add-data-model-container-right">
              <hlx-input
v-model:value="adddataobj[i.prop]" :label-animation="false" :label-value="'DD/MM/YYYY'"
                type="text" :pre-val="adddataobj[i.prop]" :display-error="errorvalidation[i.prop]"
                :custom-error="errorvalidation[i.prop]" :custom-error-message="'Please fill the field'" />
            </span>
            <span v-if="i.type === 'Boolean'" class="add-data-model-container-right-radio">
              <hlx-input :options="options3" :type="'radio-buttons'" />
            </span>
          </span>
        </div>
      </div>
    </template>
    <template #footer>
      <span class="footer-button" style="gap:15px;width:100%">
        <hlx-button
class="secondary-success sm clear-button" style="width:100%"
          @click="clearsavedata">Cancel</hlx-button>
        <hlx-button class="primary sm" style="width:100%" @click="saveadddata">Add</hlx-button>
      </span>
    </template>
  </hlx-modal>

  <main class="--layout-2-way">
    <section class="--layout-inner-left-portion --LL30">
      <!-- Breadcrumb -->
      <div class="layout-breadcrumb">
        <hlx-breadcrumb :items="items" @path="pathTo" />
      </div>
      <div class="schema-info --info-left-panel">
        <!-- Title -->
        <span class="schema-title -info-title">Edit Schema</span>

        <!-- schema name -->
        <div class="schema-name-wrapper">
          <hlx-input
v-model:value="name" :pre-val="name" :label-value="'Name'" :label-animation="true" :required="true"
            :display-error="customnameerror" :custom-error="customnameerror"
            :custom-error-message="'Please fill the field'"></hlx-input>
        </div>

        <!-- schema description -->
        <div class="schema-description-wrapper">
          <hlx-input
v-model:value="description" :pre-val="description" :label-animation="true"
            label-value="Description" type="textarea" :clearable="true" />
        </div>
      </div>
    </section>
    <section class="schema-view --layout-inner-right-portion --RL70" style="padding:2.3em 43px;overflow: auto;">
      <div class="panel-layout-body">
        <div class="source-area grid">
          <p class="--right-panel-title">Type</p>
          <hlx-switch
v-model="selectedValue" :switch-items="switch_items" value-prop="value" display-prop="name"
            @change="chosen" />
        </div>
        <div class="design-area">
          <!-- {{this.tableData}} -->
          <p class="--right-panel-title --morgin-bottom">Model</p>

          <div v-if="manual === true" class="schema-table-view">
            <div class="schema-heads">
              <span
v-for="(i, index) in theads" :key="index" class="attribute-headers"
                :class="{ '--center': i.label == 'Required' }">
                {{ i.label }}

              </span>
            </div>
            <!-- {{tableData}} -->
            <!-- {{options}} -->
            <div v-for="(i, index) in tableData" :key="index" class="schema-wrap-main">
              <section class="schema-wrap">
                <div class="schema-cover">
                  <i v-show="contextHlx != index" class="icon-angle-right-regular" @click="toggleIcon(index, i)"></i>
                  <i v-show="contextHlx == index" class="icon-angle-down-regular" @click="toggleUp()"></i>

                  <!-- style="margin-right: 10px" -->

                  <section class="schema-row-body-wrap">
                    <div
v-for="(j, col_index) in theads" :key="col_index"
                      :class="{ '--center': j.prop == 'required' }">
                      <div v-if="j.prop === 'schema'">
                        <hlx-input
v-model:value="i[j.prop]" :label-animation="false" :pre-val="i[j.prop]" type="text"
                          :clearable="false" />
                      </div>
                      <span v-if="j.prop === 'type'">
                        <hlx-select
v-model:value="i[j.prop]" :pre-value="i[j.prop]" :options="data1" :placeholder-value="''" :label-animation="true"
                          :inline-search="true" :name="'name'" :value="'value'"
                          @custom-change="checkval($event, index)"></hlx-select>
                      </span>
                      <!-- <span v-if="j.prop === 'list'"> <hlx-input :checked="i[j.prop]" :type="'single-checkbox'" v-model:value="i[j.prop]" /> </span> -->
                      <span v-if="j.prop === 'required'" class="--center">
                        <hlx-input v-model:value="i[j.prop]" :checked="i[j.prop]" :type="'single-checkbox'" />
                      </span>
                      <span v-if="j.prop === 'reference'">
                        <!-- {{"i.reference.refConfig.name"+i?.reference?.refConfig?.name}} -->
                        <!-- v-model:value="i.reference.refConfig.name" -->
                        <hlx-select
v-if="i['type'] === 'Array' || i['type'] === 'Object'"
                          :options="options" lael-value="name" prop-value="name"
                          :placeholder-value="'Reference'" :pre-value="i?.reference?.refConfig?.name" :footer="true"
                          :label-animation="false" :inline-search="true" :footer-content="'Add schema'"  :footer-icon="'icon-plus-circle-regular'"
                          @selected-value="optionSelected($event, index)" @footer-click="addSchema(index)"></hlx-select>

                        <span v-else class="na">NA</span>
                      </span>

                    </div>
                  </section>
                </div>
                <div v-show="contextHlx == index" class="reference-mapping-section">
                  <hlx-table
:column-count="headers" :border="['table', 'header', 'vertical', 'horizontal']"
                    :bold-headers="false" :row-hover="false" theme="grey" :striped-rows="false"
                    :re-arrange-columns="re_arrange" :re-arrange-heads="theads" @close-rearrange="arrange"
                    @apply-rearranged-columns="applyColumns">
                    <template #thead>

                      <hlx-table-head :width="60" :fixed="'left'">{{ "S.no" }}</hlx-table-head>
                      <hlx-table-head
v-for="(ii, iindex) in Theaders" :key="iindex" :prop="ii.prop"
                        :sortable="ii.sortable" :resizable="ii.resizable" :width="185" @sorting-func="sorting_Data"
                        @resizewidth="resizewidthdata">
                        {{ ii.label }}
                      </hlx-table-head>
                      <hlx-table-head :align="'center'" :width="70" :fixed="'right'"><span class="action-icon"><i
                            class="icon-settings-regular" @click="arrange(true)"></i></span></hlx-table-head>
                    </template>
                    <template #tbody>
                      <tr v-for="(ii, iindex) in paginatedData" id="" :key="ii">
                        <hlx-table-cell :fixed="'left'">{{ serialNumber(iindex) }}</hlx-table-cell>
                        <hlx-table-cell v-for="(j, col_index) in Theaders" :key="col_index" :align="j.align">
                          <!-- {{j.align}} -->
                          <!-- {{j.type}} -->

                          <section class="label-container" style="display:flex; align-items: center;gap:3px">

                            <!-- {{j.align}} -->
                            <!-- {{j.type}} -->
                            <div v-if="j.type === 'String'" style="width: 100%; cursor: pointer; width: 150px">
                              {{
    ii != undefined && ii[j.prop] != undefined
      ? ii[j.prop]
      : '-'
  }}
                            </div>
                            <div v-else-if="j.type === 'Array'" class="link-name">
                              {{ ii[j.prop].length || '-' }}
                            </div>
                            <div v-else-if="j.type === 'Number'" style="width: 100%; cursor: pointer; width: 150px">
                              {{
    ii && ii[j.prop] != undefined
      ? ii[j.prop]
      : '-'
  }}
                            </div>
                            <div
v-else-if="j.type === 'Object'" class="link-name"
                              style="width: 100%; cursor: pointer; width: 150px">
                              {{ j.label  || '-'}}
                            </div>
                            <div v-else-if="j.type === 'Date'" style="width: 100%; cursor: pointer; width: 150px">
                              {{
    ii && ii[j.prop] != undefined
      ? ii[j.prop]
      : '-'
  }}
                            </div>
                            <div v-else-if="j.type === 'Boolean'" style="width: 100%; cursor: pointer; width: 150px">
                              {{
    ii && ii[j.prop] != undefined
      ? ii[j.prop]
      : '-'
  }}
                            </div>
                            <div v-else style="width: 100%; cursor: pointer; width: 150px">
                              {{
      ii && ii[j.prop] != undefined
        ? ii[j.prop]
        : '-'
    }}
                            </div>
                            <!-- <span v-show="JSON.stringify(datadefault.name)==JSON.stringify(ii)"><hlx-label class="primary sm">Default</hlx-label></span> -->

                          </section>
                        </hlx-table-cell>
                        <hlx-table-cell :fixed="'right'">
                          <span :id="'table-context' + iindex">
                            <i
style="position: relative" class="icon-more-vertical-filled"
                              :class="ii?.context === true ? 'active-action' : ''"
                              @click.self="crudContextmenu($event, iindex)">
                              <hlx-context-menu
:top="containertop" :left="containerleft" :conditionvalue="150"
                                :conditiondown="2" :conditionup="-38" :options="crudoptions"
                                :show="ii && ii?.context == true" @chosen="closeCrudContextMenu($event, ii, iindex)" />
                            </i>
                          </span>
                        </hlx-table-cell>

                      </tr>
                    </template>
                  </hlx-table>

                  <div class="pagination-container">
                    <hlx-pagination
v-model:rows-per-page="externalRowsPerPage" :pager-count="10" rows-per-page
                      enable-rows-per-page :rows-per-page-list="[5, 10, 15]" :total="refTable.length"
                      @current-page="currentPage" @updated:rows-per-page="changeRowsPerPage"></hlx-pagination>
                  </div>
                </div>
              </section>
              <span v-if="!show_view" class="manual-add-delete">
                <i
v-if="i['type'] === 'Array' || i['type'] === 'Object'" class="icon-settings-regular"
                  @click="refer(index)"></i>
                <i class="icon-plus-circle-filled" @click="addschemabuilder"></i>
                <i v-if="tableData.length > 1" class="icon-trash-regular" @click="deleteschemabuilder(index)"></i>
              </span>
            </div>
          </div>

          <!-- * upload -->
          <div v-if="upload === true" class="grid-local-new-schema-right-container">
            <!-- <hlx-file-upload
:file-type="'.xlsx,.png,.json'" :file-size-limit="'20mb'" :custom-icon="icon"
              :type="'regular'" @file-data="handleUploadedSchema" /> -->
              <hlx-file-upload
        :file-type="'.jpg,.jpeg,.png'"
        :file-size-limit="'20mb'"
        :custom-icon="icon"
        :type="'upc-regular'"
        :icon="'icon-arrow-down-to-line-filled'"
        :content="'Import'"
        @delete-image="func"
        @download-image="func1"
        @file-data="handleUploadedSchema"
      />
          </div>
          <!-- upload * -->
          <span v-if="api == true" class="api-data-content">
            <div class="method-url">
              <span class="method">
                <hlx-select :options="method_options"></hlx-select>
              </span>
              <span class="url">
                <hlx-input type="text" :label-value="'Enter request URL'"></hlx-input>
              </span>
              <span class="schedule">
                <div class="choose-schedule">
                  <i class="icon-clock-regular"></i>
                </div>
              </span>
            </div>
          </span>
          <!-- * upload -->
        </div>
        <div v-if="api == true" class="grid">
          <span class="titles">URL parameters <span class="info"></span></span>
          <span class="api-data-content">
            <div class="params">
              <div v-for="(param, index) in add_payload.params" :key="index" class="param-object">
                <span class="key">
                  <hlx-input
:label-value="'Key'" :pre-val="add_payload.params[index].key"
                    @at-input="paramKey($event, index, 'key')"></hlx-input>
                </span>
                <span class="value"><hlx-input
:label-value="'Value'" :pre-val="add_payload.params[index].value"
                    @at-input="paramKey($event, index, 'value')"></hlx-input></span>
                <span><i class="icon-plus-circle-filled" @click="addParamRow"></i></span>
                <span class="delete-param"><i
v-if="add_payload.params.length > 1" class="icon-trash-filled"
                    @click="deleteParamRow(index)"></i></span>
                <span class="delete-param"><i
v-if="add_payload.params.length > 1" class="icon-trash-regular"
                    @click="deleteParamRow(index)"></i></span>
              </div>
            </div>
          </span>
        </div>
        <div v-if="api == true" class="grid">
          <span class="titles">Headers <span class="info"></span></span>
          <span class="api-data-content">
            <div class="headers">
              <div v-for="(header, index) in add_payload.headers" :key="index" class="header-object">
                <span class="key">
                  <hlx-input
:label-value="'Key'" :pre-val="add_payload.headers[index].key"
                    @at-input="headerKey($event, index, 'key')"></hlx-input>
                </span>
                <span class="value"><hlx-input
:label-value="'Value'" :pre-val="add_payload.headers[index].value"
                    @at-input="headerKey($event, index, 'value')"></hlx-input></span>
                <span><i class="icon-plus-circle-filled" @click="addHeaderRow"></i></span>
                <span class="delete-header"><i
v-if="add_payload.headers.length > 1" class="icon-trash-filled"
                    @click="deleteHeaderRow(index)"></i></span>
              </div>
            </div>
          </span>
        </div>
        <div v-if="api == true" class="grid">
          <span class="titles">Authorization <span class="info"></span></span>
          <span class="api-data-content-auth">
            <div class="auth">
              <div class="auth-object">
                <hlx-switch :type="'regular'" :switch-items="auth_switch_items" @chosen="authSelected" />
              </div>
            </div>
            <div v-if="add_payload.auth === 'Basic auth'" class="auth-cred">
              <div class="auth-cred-items">
                <span class="key"><hlx-input
v-model:value="add_payload.credential.username"
                    :label-value="'Username'"></hlx-input></span>
                <span class="value"><hlx-input
v-model:value="add_payload.credential.password"
                    :label-value="'Password'"></hlx-input></span>
              </div>
            </div>
            <div v-if="add_payload.auth === 'Bearer token'" class="auth-cred">
              <div class="auth-cred-items">
                <span class="token"><hlx-input
v-model:value="add_payload.credential.token"
                    :label-value="'Token'"></hlx-input></span>
              </div>
            </div>
            <div v-if="add_payload.auth === 'API key'" class="auth-cred">
              <div class="auth-cred-items">
                <span class="api-key"><hlx-input
v-model:value="add_payload.credential.apikey"
                    :label-value="'API key'"></hlx-input></span>
              </div>
            </div>
          </span>
        </div>
        <div v-if="api == true" class="divider">
          <hr />
        </div>
        <div v-if="api == true" class="grid">
          <span class="titles">Request body
            <span class="info">Define data in JSON format</span></span>
          <span class="api-data-content-text">
            <hlx-code-editor :line-number="true" :beautify="true" @code-changes="bodyPayload"></hlx-code-editor>
            <div class="send-api">
              <hlx-button class="primary sm" @click="sendAPIData">Send</hlx-button>
            </div>
          </span>
        </div>
        <div v-if="api == true && response == true" class="grid">
          <span class="titles">Response <span class="info"></span></span>
          <span v-if="api == true && response == true" class="api-data-content-text">
            <div class="status">
              <span class="title">Status: </span>
              <span class="value">{{ response_status }}</span>
              <span class="title">Time: </span>
              <span class="value">{{ response_time }}</span>
            </div>
            <div class="response-result"></div>
          </span>
        </div>
      </div>
    </section>
  </main>

  <div class="--layout-footer">
    <hlx-button class="secondary-success sm cancel-button" @click="saveSchema('cancel')">Cancel</hlx-button>
    <hlx-button class="primary sm" @click="saveSchema()">Save</hlx-button>
  </div>
  <div class="reference-list">
    <hlx-modal :modal-active="addrefer" :height="'290px'" :width="'320px'" :header-content="true" :close-icon="false">
      <template #header>
        <div>{{ schemaName }}</div>
      </template>
      <template #content>
        <div class="modal-content">
          <div class="modal-area">
            <div class="label-1">
              <!-- <p class="left-label">Fields</p> -->
              <span class="right">
                <hlx-multi-select
:options="namedRef" :pre-value="refconfigdata" :placeholder="'Properties'"
                  :label-animation="true" :label="'name'" :prop-value="'value'" :disabled="false"
                  @selected-array="emitdata"></hlx-multi-select></span>
            </div>
            <div class="classbutton1">
              <hlx-button class="primary sm" @click="saveRefer">Save</hlx-button>
            </div>
            <div class="classbutton1">
              <hlx-button class="secondary sm" @click="cancelRefer">Cancel</hlx-button>
            </div>
          </div>

        </div>
      </template>

    </hlx-modal>
  </div>
  <discardComponent :show="showAlert" @stay="stayFunction"  @close="showAlert = false" @discard="discardAllChanges"></discardComponent>
</template>

<script>
import deepdiff from "deep-diff";
import { DMNAxiosInstance, MLTAxiosInstance } from "@/config/axiosConfig";
import discardComponent from '../../components/discardComponent.vue';
export default {
  components:{
    discardComponent
  },
  beforeRouteLeave(to, from, next) {
    console.log(this.unsavedChanges);
    if (this.unsavedChanges) {
      console.log(this.unsavedChanges);
      this.showAlert = true;
      if (this.showAlert) {
        if (this.canLeave) {
          next();
        } else {
          next(false);
        }
      } else {
        next();
      }
    } else {
      next();
    }
  },
  data() {
    return {
      tableData1:[],
      referenceSchema:false,
      addschema:{},
      canLeave:false,
      showAlert:false,
      unsavedChanges: false,
      externalRowsPerPage: 10,
      allref: [],
      deletedialog: false,
      deletename: '',
      deletedataindex: '',
      editdataobj: {},
      referencedataid: '',
      adddataobj: {},
      errorvalidation: {},
      modal_active1: false,
      modal_active: false,
      crudoptions: [
        {
          label: 'Add',
          icon: 'icon-plus-regular'
        },
        {
          label: 'Mark as default',
          icon: 'icon-file-text-star-filled'
        },
        {
          label: 'Edit',
          icon: 'icon-edit-regular'
        },
        {
          label: 'Delete',
          icon: 'icon-trash-regular'
        }
      ],
      refTable: [],
      newValue: {},
      signed: false,
      refernce: {},
      refconfigdata: [],
      schemaName: '',
      addrefer: false,
      value: [],
      prevalue: "",
      token: this.$route.params.token,
      editMode: true,
      editPermission: [],
      viewPermission: [],
      namecustomerror: false,
      ref: "",
      show_add_menu: false,
      notification: {},
      success: false,
      success_msg: "",
      id: "",
      response: false,
      auth_switch_items: [
        { name: "No auth", checked: true },
        { name: "Basic auth" },
        { name: "Bearer token" },
        { name: "API key" }
      ],
      response_status: "200 OK",
      response_time: "91ms",
      add_payload: {
        name: "",
        description: "",
        method: "",
        url: "",
        data: [],
        schedule: "",
        params: [{ key: "", value: "" }],
        headers: [{ key: "", value: "" }],
        auth: "",
        credential: {}
      },
      method_options: [
        {
          name: "GET",
          checked: true,
          id: 1
        },
        {
          name: "POST",
          checked: false,
          id: 2
        }
      ],
      formData: {},
      upload: false,
      manual: true,
      api: false,
      items: [{ label: "Schema", link: '/' }],
      data1: [
        { name: "String", value: "String", checked: true },
        { name: "Array", value: "Array", checked: false },
        { name: "Boolean", value: "Boolean", checked: false },
        { name: "Date", value: "Date", checked: false },
        { name: "Object", value: "Object", checked: false },
        { name: "Number", value: "Number", checked: false }
      ],
      switch_items: [
        { name: "Manual", value: "manual" },
        // { name: "API" },
        { name: "Upload", value: "upload" }
      ],
      selectedValue: "manual",
      tableData: [],
      theads: [
        {
          name: "Attribute",
          checked: true,
          id: 1,
          disabled: false,
          prop: "schema",
          label: "Attribute",
          sortable: false,
          resizable: false,
          width: 250
        },
        {
          name: "Type",
          checked: true,
          id: 1,
          disabled: false,
          prop: "type",
          label: "Type",
          sortable: false,
          resizable: false,
          width: 150
        },
        {
          name: "Required",
          checked: true,
          id: 1,
          disabled: false,
          prop: "required",
          label: "Required",
          sortable: false,
          resizable: false,
          width: 70
        },
        {
          name: "Reference",
          checked: true,
          id: 1,
          disabled: false,
          prop: "reference",
          label: "Reference",
          sortable: false,
          resizable: false,
          width: 70
        }
      ],
      name: "",
      description: "",
      tabledata2: {},
      proped: [],
      sidebaropen: true,
      namedRef: [],
      nameGet: '',
      settingsrefernceindex: 0,
      preReference: [],
      dataChange: false,
      currPage: 1,
      rowsPerPage: 5,
      headervalue: [],
      Theaders: [],
      headers: [],
      crudshow: false,
      contextIndex: -1,
      contextHlx: -1,
      referencedataname: '',
      listVal: '',
      count: 0,
      company: '',
      userId: '',
      datadefault: {},
      containerleft: 0,
      containertop: 0,
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      // console.log(this.refTable,">?",this.refTable.slice(start, end));
      return this.refTable.slice(start, end);
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.refTable.length;
    },
  },
  async mounted() {

    document.addEventListener('click', (event) => {
      if (!event.target.classList.contains('icon-more-vertical-filled')) {
        this.refTable.forEach((e) => {
          if (e && e.context !== undefined) {
            e.context = false;
          }
        });
      }
    });

    // document.addEventListener("click", (e) => {
    //   if (e.target.className == "toggler") {
    //     if (e.target.children[0].className === "icon-angle-left-regular") {
    //       this.sidebaropen = false;

    //     } else if (
    //       e.target.children[0].className === "icon-angle-right-regular"
    //     ) {
    //       this.sidebaropen = true;

    //     }
    //   } else if (
    //     e.target.className === "icon-angle-left-regular" &&
    //     e.target.parentElement.className == "toggler"
    //   ) {
    //     this.sidebaropen = false;

    //   } else if (
    //     e.target.className === "icon-angle-right-regular" &&
    //     e.target.parentElement.className == "toggler"
    //   ) {
    //     this.sidebaropen = true;
    //     // 
    //   }
    // });
    const val = await MLTAxiosInstance.get(`partymanagement/individual/auth`);
    this.company = val.data.company;
    this.userId = val.data.company;
    // const allschema = await DMNAxiosInstance.get(`rule_engine/schema`);
    // let allSch = await allschema.data.data;
    let filterOption = {
      company: this.company,
      "type": "search",
      "module": "schema",
      "collection": "",
      "isPaginated": false,
      "paginatedQuery": {
        "rowsPerPage": 10,
        "currentPage": 1
      },
      "isNeedToBeSort": false,
      "sortFieldAndOrder": { name: 1 },
      "limit": 1000,
      "filterQuery": {},
      "searchQuery": ""
    }
    const allschema = await DMNAxiosInstance.post(`/util/filterData`, filterOption);
    // let allSch = await allschema.data.data.data.map((val) => {
    //   return { ...val, label: val.name, value: val.name, name: val.name };
    // });
    // allSch.sort((a, b) => a.name.localeCompare(b.name));

    let allSch = await allschema.data.data.data
    this.allref = allSch;
    this.options = await allSch;
    this.options.forEach((e) => {
      if (e.name != "") {
        e.value = e.name;
      }
    });

    const edit = await DMNAxiosInstance.get(
      `rule_engine/schema/${this.$route.params.id}`
    );
    console.log("edit",edit)
    this.id = await edit.data.data._id;
    this.name = await edit.data.data.name;
    let obj1 = {};
    obj1.label = this.name;
    this.items.push(obj1);
    this.description = await edit.data.data.description;
    this.selectedValue = await edit.data.data.type?.toLowerCase();
    this.calculateCurrentEditType(this.selectedValue);
    let edited = edit.data.data.schema;
    let obj = Object.keys(edited);
    let values = Object.values(edited);

    const table = obj.map(async (val) => {
      const vale = values.map(async (vals) => {
        if (obj.indexOf(val) == values.indexOf(vals)) {
          let obj_vals = vals;
          obj_vals.schema = val;
          return await obj_vals;
        }
      });
      return await Promise.all(vale);
    });
    let t_data = (await Promise.all(table)).flat();
    console.log(t_data);
    t_data = t_data.filter((tdat) => {
      if (tdat != undefined) {
        console.log(tdat.anotherRef);
        if (tdat.anotherRef != undefined) {
          console.log(tdat, 'opo');
          let ref = tdat.anotherRef.refConfig
          tdat.list = false;
          tdat.reference = false;
          tdat.reference = ref.name;
          return tdat;
        } else {
          tdat.list = true;
          return tdat;
        }
      }
    });

    t_data.forEach((e) => {
      let refConfig = {}
      refConfig = e.refConfig
      let isRef = e.isRef
      e.reference = {
        isRef,
        refConfig
      }
      delete e.refConfig
      delete e.isRef
    })
    console.log(t_data);

    this.tableData = t_data;
    this.mountedSchemaPayload={}
    this.mountedSchemaPayload.name= await edit?.data?.data?.name,
    this.mountedSchemaPayload.tableData= JSON.parse(JSON.stringify(await edit?.data?.data?.schema))
    this.mountedSchemaPayload.description=await edit?.data?.data?.description
    this.mountedSchemaPayload.tableData=Object.values(this.mountedSchemaPayload.tableData).map(value => ({ ...value }));

    console.log(this.tableData,"obj1",obj1,"items",this.mountedSchemaPayload);

  },
  methods: {
    checkval1(val, index) {
      val.forEach((e) => {
        if (e.checked == true) {
          if (e.value == "Array" || e.value == "Object") {
            this.tableData1[index].reference.isRef = true;
          } else {
            this.tableData1[index].reference.isRef = false;
          }
        }
      });
    },
    addsubschemabuilder() {
      let obj = {
        schema: "",
        type: "String",
        required: false,
        reference: {
          isRef: false,
          refConfig: {
            name: "",
            fields: []
          }
        }
      };

      this.tableData1.push(obj);
    },
    deletesubschemabuilder(index) {
      this.tableData1.splice(index, 1);
    },
    discardAllChanges(){
      this.$router.push(`/schemas`); 
      this.unsavedChanges = false;
      this.canLeave = true;
      this.showAlert = false;
    },
    stayFunction() {
      this.canLeave = false;
      this.showAlert = false;
    },
    currentPage(val) {
      this.currPage = val;
    },
    changeRowsPerPage(count) {
      // 
      this.rowsPerPage = count;
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1
    },
    deletedata(index) {
      this.deletedataindex = index;

      this.deletename = this.refTable[index].name;
      this.referencedataid = this.refTable[index]._id;
      this.deletedialog = true;
    },
    async deleteokmodal() {
      await DMNAxiosInstance.delete(
        `mapRef/remove/${this.referencedataname}/${this.referencedataid}`
      ).then(() => {
        // 
        this.refTable.splice(this.deletedataindex, 1);
      });
      this.duplicate_table_data = this.refTable;
      this.deletenotification = true;
      this.deletedialog = false;
      this.success = true;
      this.success_msg = 'Data deleted successfully';
      this.notification = { type: 'success', message: this.success_msg };
    },
    async saveeditdata() {
      console.log("saveeditdata")
      await DMNAxiosInstance.patch(
        `mapRef/updateCollection/${this.referencedataname}/${this.referencedataid}`,
        this.editdataobj
      ).then((res) => {
        // 
        this.refTable[this.editmodalindex] = res.data.data;
      });
      this.duplicate_table_data = this.refTable;
      this.editnotification = true;
      this.modal_active1 = false;
      this.crudshow = false
    },
    editdata(data, index) {

      this.referencedataid = this.refTable[index]._id;
      // this.editdataobj = {}
      // this.editdataobj = JSON.parse(JSON.stringify(this.editdataobj))
      // 
      this.headers.forEach((e) => {
        if (['Array', 'Object', 'Boolean'].includes(e.type) == false) {
          this.editdataobj[e.prop] = data[e.prop];
          // this.editdataobj = data
          // this.editmodaldata = data
        }
      });
      // 
      this.modal_active1 = true;
      this.editmodalindex = index;
    },
    async saveadddata() {
      var count = 0
      var count1 = 0
      this.headers.forEach((e) => {
        if (e.required == true) {
          count1 = count1 + 1
        }
      })
      for (let i = 0; i < this.headers.length; i++) {
        // 
        if (this.headers[i].required == true) {
          if (this.adddataobj[this.headers[i].prop] == '') {

            this.errorvalidation[this.headers[i].prop] = true
          }
          else {
            this.errorvalidation[this.headers[i].prop] = false
            count++
          }
        }
      }


      if (count == count1) {
        await DMNAxiosInstance.post(
          `mapRef/${this.referencedataname}`,
          this.adddataobj
        ).then((res) => {

          this.refTable.push(res.data.data);
        });
        this.modal_active = false;
        this.duplicate_table_data = this.refTable;
        this.headers.forEach((e) => {
          if (['Array', 'Object', 'Boolean'].includes(e.type) == false) {

            delete this.adddataobj[e.prop];
            // this.adddataobj[e.prop] = ''
          }
        });
        // 
        // this.adddataobj = {}
        this.addnotification = true;
        this.modal_active = false;
      }
    },
    toggleUp() {
      this.contextHlx = -1
    },
    async toggleIcon(i, content) {
      if (content.reference.isRef == true) {
        if (i == this.contextHlx) {
          this.contextHlx = -1

        }
        else {
          this.contextHlx = i
          console.log(content, "toggle")
          let val = content.reference.refConfig.name
          this.listVal = val
          const getDefault = await DMNAxiosInstance.get(`/default/${this.company}/${val}`)
          if (getDefault.data && getDefault.data[0]) {
            this.datadefault = getDefault.data[0];
          }
          console.log(getDefault.data, "default t", this.datadefault);
          this.count = await getDefault.data.length
          console.log(val, content)
          let res = await DMNAxiosInstance.get(
            `mapRef/getbyname/${val}`
          );
          console.log(schemaRes);

          if (res.data.ok == 0) {
            this.headers = [];
          } else {
            this.headers = Object.keys(res.data.data).map((e) => {
              //  
              // console.log(schemaRes.data.data[e]);
              let obj = {};
              obj.name = e;
              obj.prop = e;
              obj.label = e;
              obj.required = res.data.data[e].required
              obj.ref = res.data.data[e].refConfig
              obj.type = res.data.data[e]?.type;
              obj.sortable = true;
              obj.checked = true;
              obj.disabled = false;
              obj.align = 'left';
              return obj;
            });
            this.Theaders = this.headers
            // console.log(this.duplicateTheads,'pop');
          }
          this.referencedataname = val
          let schemaRes = await DMNAxiosInstance.get(
            `mapRef/collection/${val}`
          );
          this.refTable = schemaRes.data.data
          this.Theaders.forEach((e) => {
            if (['Array', 'Object', 'Boolean'].includes(e.type) == false) {

              this.adddataobj[e.prop] = '';
              this.errorvalidation[e.prop] = false
            }
          })

        }
      }

    },
    closeCrudContextMenu($event, data, index) {
      this.crudshow = true
      this.contextIndex = -1

      console.log($event, this.paginatedData, index, this.paginatedData[index], "oooo");
      if ($event == "Add") {
        this.modal_active = true

      }
      else if ($event == "Edit") {
        // this.modal_active1=true
        this.editdataobj = data
        this.editdata(data, index)
        this.crudshow = false

      }
      else if ($event == "Delete") {
        // this.modal_active1=true
        this.deletedata(index)
        this.crudshow = false

      }
      else if ($event == "Mark as default") {
        // this.modal_active1=true

        this.default(data)
        this.crudshow = false
      }
      else {
        console.log("else")
      }
      this.refTable.forEach((e) => {
        e.context = false
      })

    },
    async default(i) {
      console.log(i);
      let source = {
        sourcename: this.listVal,
        name: i
      }
      if (this.count == 0) {
        console.log(source, "sourceee", this.company);
        const add = await DMNAxiosInstance.post(`/default/${this.company}`, source)
        console.log(add.data, "added");
        this.datadefault = add.data

      } else {
        const add = await DMNAxiosInstance.delete(`/default/${this.company}/${this.listVal}`)
        console.log(add.data, "deleted");
        const added = await DMNAxiosInstance.post(`/default/${this.company}`, source)
        console.log(added.data, "added");
        this.datadefault = added.data
      }
    },
    crudContextmenu($event, index) {
      console.log("🚀 ~ crudContextmenu ~ index:", index, $event.target.className)
      if ($event.target.className === "icon-more-vertical-filled") {
        this.$nextTick(() => {
          this.containerleft = document.querySelector('#table-context' + index).parentElement.getBoundingClientRect().left + 937
          // this.containerleft = document.querySelector('#table-context' + index).parentElement.getBoundingClientRect().left -  93
          console.log("🚀 ~ this.$nextTick ~ document.querySelector('#table-context' + index).parentElement.getBoundingClientRect().left:", document.querySelector('#table-context' + index).parentElement.getBoundingClientRect().left)
          this.containertop = document.querySelector('#table-context' + index).parentElement.getBoundingClientRect().top + 380
          // console.log(this.containertop,this.containerleft,document.querySelector('#table-context' + index).getBoundingClientRect());
        })
      }
      // console.log('aaaaaaldo',$event.target.className);
      if ($event.target.className === 'icon-more-vertical-filled') {
        this.refTable.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
        console.log(this.refTable, 'reefff');
        this.refTable[index].context = true;
        // 
      } else {
        this.refTable.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    addSchema(index) {
      this.name1 = ''
      this.description1 = ''
      this.tableData1 = [
        {
          schema: "",
          type: "String",
          required: false,
          reference: {
            isRef: false,
            refConfig: {
              name: "",
              fields: []
            }
          }
        }
      ],
        this.referenceSchema = true
      this.addschemaindex = index
    },
    emitdata(val) {
      console.log(val);
      let arr = []
      val.forEach((e) => {
        arr.push(e.value)
      })
      this.value = arr
      this.dataChange = true
      this.refconfigdata = this.value
      this.tableData[this.settingsrefernceindex].reference.refConfig.fields = this.value
      console.log(this.value);
    },
    async optionSelected($event, i) {
      this.contextHlx = -1
      let val = $event.name
      console.log($event,"🚀 ~ optionSelected ~ i:", i,"val",val)
      this.tableData = this.tableData.map((data, index) => {
        console.log("optionSelected",data)
        if (index == i) {
          data.reference.isRef = true
          if(data?.reference?.refConfig?.name){
          data.reference.refConfig.name = val
          }
          else{
            data.reference.refConfig={}
            data.reference.refConfig.name=val
          }
        }
        return data
      });
      console.log(this.tableData, "🎉")
      // console.log("🚀 ~ file: LocalSchemaAddView.vue:818 ~ optionSelected ~ val, i:", val, i)
      let res = await DMNAxiosInstance.get(
        `mapRef/getbyname/${val}`
      );
      console.log(schemaRes);

      if (res.data.ok == 0) {
        this.headers = [];
      } else {
        this.headers = Object.keys(res?.data?.data).map((e) => {
          //  
          // console.log(schemaRes.data.data[e]);
          let obj = {};
          obj.name = e;
          obj.prop = e;
          obj.label = e;
          obj.required = res?.data?.data[e]?.required
          obj.ref = res?.data?.data[e]?.refConfig
          obj.type = res?.data?.data[e]?.type;
          obj.sortable = true;
          obj.checked = true;
          obj.disabled = false;
          obj.align = 'left';
          return obj;
        });
        this.Theaders = this.headers
        // console.log(this.duplicateTheads,'pop');
      }
      let schemaRes = await DMNAxiosInstance.get(
        `mapRef/collection/${val}`
      );

      this.refTable = schemaRes.data.data.map((e, index) => {
        e['index'] = index;
        e.context = false
      })
      this.selectedOption = true;
    },
   async optionSelected1($event, i){
    this.contextHlx = -1
      let val = $event.name
      console.log($event,"🚀 ~ optionSelected1 ~ i:", i,"val",val)
      this.tableData1 = this.tableData1.map((data, index) => {
        if (index == i) {
          data.reference.isRef = true
          if(data?.reference?.refConfig?.name){
          data.reference.refConfig.name = val
          }
          else{
            data.reference.refConfig={}
            data.reference.refConfig.name=val
          }
        }
        return data
      });
      console.log(this.tableData1, "🎉")
      // console.log("🚀 ~ file: LocalSchemaAddView.vue:818 ~ optionSelected ~ val, i:", val, i)
      let res = await DMNAxiosInstance.get(
        `mapRef/getbyname/${val}`
      );
      console.log(schemaRes);

      if (res.data.ok == 0) {
        this.headers = [];
      } else {
        this.headers = Object.keys(res?.data?.data).map((e) => {
          //  
          // console.log(schemaRes.data.data[e]);
          let obj = {};
          obj.name = e;
          obj.prop = e;
          obj.label = e;
          obj.required = res?.data?.data[e]?.required
          obj.ref = res?.data?.data[e]?.refConfig
          obj.type = res?.data?.data[e]?.type;
          obj.sortable = true;
          obj.checked = true;
          obj.disabled = false;
          obj.align = 'left';
          return obj;
        });
        this.Theaders = this.headers
        // console.log(this.duplicateTheads,'pop');
      }
      let schemaRes = await DMNAxiosInstance.get(
        `mapRef/collection/${val}`
      );

      this.refTable = schemaRes.data.data.map((e, index) => {
        e['index'] = index;
        e.context = false
      })
      this.selectedOption = true;
   },
    calculateCurrentEditType(type) {
      switch (type) {
        case "upload":
          (this.manual = false), (this.upload = true);
          break;

        default:
          (this.manual = true), (this.upload = false);
          break;
      }
    },
    async handleUploadedSchema(val) {
      if (val.properties.type === "application/json") {
        // let a;

        const formData = new FormData();
        formData.append("file", val.properties);

        this.formData = formData;
      } else {
        // this.notification.push();
        this.notification = {
          type: "success",
          message: `Your new file is added successfully!`,
        };
      }
    },
    goToDepTreeView(val) {
      this.$router.push({
        name: "schema-dep-tree-view",
        params: { node: val }
      });
    },
    showAddContextMenu() {
      this.show_add_menu = !this.show_add_menu;
    },
    chosed(val, i) {

      this.ref = val;
      this.tableData[i].reference = val;

    },
    checkval(val, index) {
      console.log("checkval",val,index)
      val.forEach((e) => {
        if (e.checked == true) {
          if (e.value == 'Array' || e.value == 'Object') {
            this.tableData[index].reference.isRef = true
            // this.tableData[index].reference.refConfig = {}
          }
          else {
            this.tableData[index].reference.isRef = false
          }
        }
      })
    },

    async saveSchema(mode) {
      this.updatedSchemaPayload={}
      this.updatedSchemaPayload.name=this.name,
      this.updatedSchemaPayload.description=this.description,
      this.updatedSchemaPayload.tableData= JSON.parse(JSON.stringify(this.tableData))

      console.log(this.mountedSchemaPayload.tableData,"deedDiff", this.updatedSchemaPayload.tableData)
      let differences = deepdiff(this.mountedSchemaPayload,this.updatedSchemaPayload)
console.log("differences",differences)

if(mode =='cancel' && differences){
  this.showAlert=true
  console.log(mode,"differences",differences)
  return
}
else if(mode !=='cancel' && differences){
      this.notification = {
        type: "success",
        message: "Schema edited successfully"
      };

      // Save uploaded schema
      if (this.upload == true) {
        this.formData.append("type", "upload");
        this.formData.append("name", this.name);
        this.formData.append("description", this.description);

        let addedSchema = await DMNAxiosInstance.post(
          `rule_engine/schema/upload`,
          this.formData
        );

        if (addedSchema.data.ok == 0) {

          console.error(addedSchema.data.message);
          return;
        }
        addedSchema = addedSchema.data;
        this.$router.push(`/schemas`);


        return;
      }

      if (this.name != "") {
        this.namecustomerror = false;

        let check = this.tableData.map((val) => {
          let sc;
          if (val.reference.isRef != false) {
            sc = {
              [val.schema]: {
                type: val.type,
                required: val.required,
                isRef: val.reference.isRef,
                refConfig: val.reference.refConfig
              }
            };

            return sc;
          } else {
            sc = {
              [val.schema]: {
                type: val.type,
                required: val.required
              }
            };
            return sc;
          }
        });
        let finalObj = {};
        check = await check.map((val) => {
          return Object.assign(finalObj, val);
        });
        let checked = check[0];

        let addschema = {
          name: this.name,
          description: this.description,
          schema: checked,
          type: "manual"
        };
        let arr = [];
        console.log(
          finalObj,this.tableData,
          "🚀 ~ file: EditSchemaView.vue:932 ~ saveSchema ~ addschema:",
          addschema
        );
        const add = await DMNAxiosInstance.patch(
          `rule_engine/schema/${this.id}`,
          addschema
        );
        
        arr.push(this.addschema);
        console.log(add.data, "🕊️");
        if (add.data.ok == 1) {
          this.success = true;
          setTimeout(() => {
            this.$router.push(`/schemas/view/${this.id}`);
          }, 500);
        }
      }
        // 
      }
      else if(mode =='cancel' && (!differences || differences==undefined)){
        console.log(mode,"differences",differences)
        this.$router.push(`/schemas/view/${this.id}`);
        return
      }
       else {
        console.log(mode,"differences",differences)
        this.namecustomerror = true;
      }
      //   if (add.data) {
      //     // this.$router.push(`/DMN/schemas`)
      //     this.$router.push(`/DMN/schemas`);

      //     // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/schemas `;
      //   }
      // } else {
      //   this.namecustomerror = true;
      // }
    },
    chosen(val) {
      if (val?.value === "upload") {
        this.manual = false;
        this.api = false;
        this.upload = true;
      } else if (val?.value === "manual") {
        this.manual = true;
        this.upload = false;
        this.api = false;
      } else if (val?.value === "api") {
        this.manual = false;
        this.upload = false;
        this.api = true;
      }
    },
    pathTo() {
      // if (val.label === "Schema") {
        // this.$router.push(`/schemas`);
        this.updatedSchemaPayload={}
      this.updatedSchemaPayload.name=this.name,
      this.updatedSchemaPayload.description=this.description,
      this.updatedSchemaPayload.tableData= JSON.parse( JSON.stringify(this.tableData))
        let differences = deepdiff(this.mountedSchemaPayload,this.updatedSchemaPayload)
        console.log(this.updatedSchemaPayload,"differences=>",differences,this.mountedSchemaPayload)
        if(differences){
  this.showAlert=true
  return
}
else{
        this.$router.push(`/schemas`);
        return
}
        // this.$router.push({ path: `/DMN/schemas/:token` });
        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/schemas `;
      // }
    },
    path(val) {
      // 
      if (val === "Schema") {
        this.$router.push(`/schemas`);
      }
    },
    authSelected(val) {

      this.add_payload.auth = val;
    },
    addParamRow() {
      const obj = {};
      obj.key = "";
      obj.value = "";
      obj.index = "";
      this.add_payload.params.push(obj);
    },
    deleteParamRow(index) {
      this.add_payload.params.splice(index, 1);
    },
    addHeaderRow() {
      const obj = {};
      obj.key = "";
      obj.value = "";
      obj.index = "";
      this.add_payload.headers.push(obj);
    },
    deleteHeaderRow(index) {
      this.add_payload.headers.splice(index, 1);
    },
    addschemabuilder() {
      // let obj = {};
      // obj.schema = '';
      // obj.type = 'String';
      // obj.required = false;
      // obj.list = false;
      let obj = {
        schema: "",
        type: "String",
        required: false,
        reference: {
          isRef: false,
          refConfig: {
            name: "",
            fields: []
          }
        }
      };
      this.tableData.push(obj);

    },

    async refer(index) {
      this.settingsrefernceindex = index
      this.dataChange = false
      console.log(this.tableData, 'data');
      console.log("🚀 ~ file: LocalSchemaAddView.vue:814 ~ refer ~ val:", index, this.tableData[index].schema)
      let val = this.tableData[index].reference.refConfig.name
      this.schemaName = this.tableData[index].reference.refConfig.name;
      this.refconfigdata = this.tableData[index].reference.refConfig.fields
      this.preReference = this.tableData[index].reference.refConfig.fields
      console.log(this.refconfigdata, 'refconfig');
      const allschema = await DMNAxiosInstance.get(`rule_engine/schema/${val}`);
      let name = await allschema.data.data.schema;
      let key = Object.keys(name);
      let sch = key.map((val) => {
        // 
        return { name: val, value: val };
      });
      this.namedRef = sch;
      this.addrefer = true;

    },

    saveRefer() {
      if (this.dataChange == false) {
        this.tableData[this.settingsrefernceindex].reference.refConfig.fields = this.preReference
      }
      console.log(this.tableData, this.settingsrefernceindex);
      this.addrefer = false;
    },
    cancelRefer() {
      this.tableData[this.settingsrefernceindex].reference.refConfig.fields = this.preReference
      this.addrefer = false;
    },
    deleteschemabuilder(index) {
      this.tableData.splice(index, 1);
    }
  }
};
</script>

<style></style>
