<template>
 <main class="--layout-2-way">
    <section class="--layout-inner-left-portion --LL30">
      <!-- Breadcrumb -->
      
      <div class="decision-info --info-left-panel">
        <!-- Title -->
        <span class="decision-title -info-title">Add Matrix Mock</span>

        <!-- decision name -->
        <div class="decision-name-wrapper">
          <hlx-input
            v-model:value="decisionPayload.name"
            :label-value="'Name'"
            :label-animation="true"
            :required="true"
            :display-error="isCustomNameError"
            :custom-error="isCustomNameError"
            :custom-error-message="'Please fill the field'"
          ></hlx-input>
        </div>

         <!-- decision name -->
        <div class="decision-name-wrapper">
          <hlx-input
            v-model:value="decisionPayload.category"
            :label-value="'category'"
            :label-animation="true"
            :required="true"
            :display-error="isCustomNameError"
            :custom-error="isCustomNameError"
            :custom-error-message="'Please fill the field'"
          ></hlx-input>
        </div>

        <!-- decision description -->
        <!-- <div class="decision-description-wrapper">
          <hlx-input
            v-model:value="decisionPayload.description"
            :label-animation="true"
            label-value="Auto permutate"
            type="textarea"
            :clearable="true"
          />
        </div> -->

        <hlx-code-editor 
                                            v-model:value="decisionPayload.description"
                                            
                                              :line-number="false"
                                              :rows="5"
                                             read-only="false"
                                             @current-word = "currentWord"
                                             @selected-option = "selectedOption"
                                             @code-changes="selectValue"
                                            ></hlx-code-editor>

        <hlx-button class="primary sm" @click="populate(decisionPayload.description)">Auto populate</hlx-button>
        <!-- decision ruleset -->
        <!-- :pre-value="ruleSetPreValue" -->
      </div>
    </section>
     <section class="decision-view --layout-inner-right-portion --RL70">
      <div class="panel-layout-body">
        <div class="design-area">
          <!-- {{this.tableData}} -->
          <p class="--right-panel-title --morgin-bottom">Column mapping</p>

          <span class="grid-local-new-decision-right manual-decision">
  <input-col-map-modal
    :show-input-col-map="shwInpMap"
    :pre-populate="tableHeadDetails"
    :index="inputIndex"
    :drop-down-data="intellisensePayload"
    :read-only="readOnly"
    @save="saveInputThead"
    @close="shwInpMap = false"
    @delete="deleteInputThead"
  >
  </input-col-map-modal>
  <div
    class="example-body"
    style="display: flex; margin: 30px"
  >
    <div
      :id="'grid-table'"
      :class="readOnly ? 'read-only' : ''"
      class="grid-table-component"
    >
      <hlx-table
        :column-count="theads.input.length"
        :border="['table', 'header', 'vertical', 'horizontal']"
        theme="grey"
      >
        <template #thead>
          <hlx-table-head
            v-for="(i, index) in theads.input"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            :width="185"
            style="height: 60px !important"
            :style="i.type === 'input' ? { background: '#fff' } : {}"
            @dragenter="columnenter(serialNumber(index))"
            @dragend="columnend('input')"
            @mouseover="columnover(index)"
            @mouseleave="columnleave(index)"
          >
            <span
              :id="'add-input-column-left-' + index"
              class="add-column-left"
              @mouseenter="showIcon(index, 'left', 'input-')"
              @mouseleave="hideIcon(index, 'left', 'input-')"
            >
              <span
                :id="'add-input-column-icon-left-' + index"
                class="add-column-icon"
              >
                <i
                  class="icon-plus-regular"
                  @click="addInputColumn(index, 'left')"
                ></i>
              </span>
            </span>
            <span
              v-if="i.type === 'input'"
              class="thead-container"
            >
              <span class="input-column-name"
                ><span class="column-name">{{ i.labelName }}</span>
                <span class="data-type">{{ i.dataType }}</span></span
              >
              <span class="column-drag-container">
                <span
                  :id="'columndrag' + index"
                  class="columndrag"
                  draggable="true"
                  @dragstart="columnstart(serialNumber(index))"
                >
                  <i class="icon-more-vertical-filled rotate"></i>
                </span>
              </span>
              <span
                class="drag-icon-vertical"
                @click="openTheadMappingModal('input', i)"
                ><i class="icon-more-vertical-filled"></i
              ></span>
            </span>
            <span
              :id="'add-input-column-right-' + index"
              class="add-column-right"
              @mouseenter="showIcon(index, 'right', 'input-')"
              @mouseleave="hideIcon(index, 'right', 'input-')"
            >
              <span
                :id="'add-input-column-icon-right-' + index"
                class="add-column-icon"
              >
                <i
                  class="icon-plus-regular"
                  @click="addInputColumn(index, 'right')"
                ></i>
              </span>
            </span>
          </hlx-table-head>
        </template>
        <template #tbody>
          <div
            v-if="readOnly"
            class="grid-read-only-overlay"
          ></div>
          <tr
            v-for="(i, index) in tBody"
            id="index"
            :key="index"
            @mouseover="rowdraggable(index)"
            @mouseout="outrowdraggable"
          >
            <hlx-table-cell
              v-for="(j, col_index) in headvalue"
              :key="col_index"
              @dragenter="enter(serialNumber(index))"
            >
              <span
                v-if="col_index == 0"
                :id="'rowdraggable-icon' + index"
                class="rowdraggable-icon"
                draggable="true"
                :style="['left:' + rowdragposition + 'px !important']"
                @dragstart="start(serialNumber(index))"
                @dragenter="enter(serialNumber(index))"
                @dragend="end()"
              >
                <span class="row-drag-icon-container">
                  <i class="icon-more-vertical-filled"></i>
                </span>
              </span>
              <div
                v-if="j.type === 'input'"
                class="row-cell"
              >
                <!-- <span :id="'input-row-dropdown-container'+col_index+index" class="input-row-dropdown-container normal-border">
                  <dropdown
                    v-model:value="i[j.prop].operators"
                    v-model="i[j.prop].operators"
                    :options="op_data"
                    :placeholder-value="''"
                    :label="'name'"
                    :prop-value="'value'"
                    :label-animation="false"
                    :inline-search="true"
                    :footer="false"
                    :border="false"
                    @inline-value="pushOperators($event, j.prop)"
                    @focus-event="focusDropdown($event,col_index,index)"
                    @blurevent-dropdown="blurDropdown($event,col_index,index)"
                  ></dropdown>
                </span> -->
                <span
                  class="input-row-input-container"
                  style="width: 200% !important; border-left: none"
                >
                  <span
                    v-if="
                      j.source.toLowerCase() === 'manual' &&
                      j.dataType.toLowerCase() === 'date'
                    "
                    class="manual-date"
                  >
                    <hlx-input
                      v-model:value="i[j.prop].value"
                      :pre-val="i[j.prop].value"
                      :label-animation="false"
                      :date-format="'dd-mm-yyyy'"
                      type="date"
                      :clearable="false"
                      icon="calendar-regular"
                      icon-position="right"
                    />
                  </span>
                  <span
                    v-if="
                      j.source.toLowerCase() === 'manual' &&
                      j.dataType.toLowerCase() === 'string'
                    "
                    class="manual-string"
                  >
                    <input
                      v-model="i[j.prop].value"
                      class="input-text"
                      type="text"
                      @mouseleave="blurInput($event)"
                      @mouseover="focusInput($event)"
                      @focus="focusInput($event)"
                      @blur="blurInput($event)"
                    />
                  </span>
                  <span
                    v-if="
                      j.source.toLowerCase() === 'manual' &&
                      (j.dataType.toLowerCase() === 'number' ||
                        j.dataType.toLowerCase() === 'integer') &&
                      i[j.prop].operators !== 'between'
                    "
                    class="manual-number"
                  >
                    <input
                      v-model="i[j.prop].value"
                      class="input-number"
                      type="number"
                      @mouseleave="blurInput($event)"
                      @mouseover="focusInput($event)"
                      @focus="focusInput($event)"
                      @blur="blurInput($event)"
                    />
                  </span>
                  <span
                    v-if="
                      j.source.toLowerCase() === 'manual' &&
                      (j.dataType.toLowerCase() === 'number' ||
                        j.dataType.toLowerCase() === 'integer') &&
                      i[j.prop].operators === 'between'
                    "
                    class="manual-between"
                  >
                    <span class="left-between">
                      <input
                        v-model="i[j.prop].value1"
                        class="left-between-input"
                        type="number"
                        @mouseleave="blurInput($event)"
                        @mouseover="focusInput($event)"
                        @focus="focusInput($event, 'bw')"
                        @blur="blurInput($event, 'bw')"
                      />
                    </span>
                    <div class="between-seperator"></div>
                    <span class="right-between">
                      <input
                        v-model="i[j.prop].value2"
                        class="right-between-input"
                        type="number"
                        @mouseleave="blurInput($event)"
                        @mouseover="focusInput($event)"
                        @focus="focusInput($event, 'bw')"
                        @blur="blurInput($event, 'bw')"
                      />
                    </span>
                  </span>
                  <span
                    v-if="
                      j.source.toLowerCase() === 'manual' &&
                      j.dataType.toLowerCase() === 'boolean'
                    "
                    class="manual-boolean"
                    style="padding: 0px !important"
                  >
                    <dropdown
                      v-model:value="i[j.prop].value"
                      v-model="i[j.prop].value"
                      :options="[
                        { name: 'true', value: true },
                        { name: 'false', value: false },
                      ]"
                      :placeholder-value="''"
                      :label="'name'"
                      :prop-value="'value'"
                      :label-animation="false"
                      :inline-search="true"
                      :footer="false"
                      :border="false"
                    />
                  </span>
                  <span
                    v-if="
                      j.source.toLowerCase() === 'manual' &&
                      j.dataType.toLowerCase() === 'array'
                    "
                    class="manual-boolean"
                    style="padding: 0px !important"
                  >
                    <dropdown
                      v-model:value="i[j.prop].value"
                      v-model="i[j.prop].value"
                      :options="refData[j.prop]"
                      :placeholder-value="''"
                      :label="'name'"
                      :prop-value="'name'"
                      :label-animation="false"
                      :inline-search="true"
                      :footer="false"
                      :border="false"
                    />
                  </span>
                  <span
                    v-if="j.source.toLowerCase() === 'reference data'"
                    class="manual-boolean"
                    style="padding: 0px !important"
                  >
                    <dropdown
                      v-model:value="i[j.prop].value"
                      v-model="i[j.prop].value"
                      :options="refData[j.prop]"
                      :placeholder-value="''"
                      :label="'name'"
                      :prop-value="'name'"
                      :label-animation="false"
                      :inline-search="true"
                      :footer="false"
                      :border="false"
                    />
                  </span>
                  <span
                    v-if="j.source.toLowerCase() === 'computed'"
                    class="output-computed"
                  >
                    {{ 'f(x)' }}
                  </span>
                </span>
              </div>
            </hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
    </div>
    <div
      v-if="!readOnly"
      class="crud-container"
    >
      <div class="crud-container-margin">
        <span
          v-for="(item, index) in tBody"
          :key="index"
          class="crud-item"
        >
          <span
            class="add"
            @click="rowadddata(index + 1)"
          >
            <i class="icon-plus-regular"></i
          ></span>
          <span
            class="delete"
            @click="deleterowdata(index)"
            ><i
              v-if="tBody.length > 1"
              class="icon-trash-regular"
            ></i
          ></span>
        </span>
      </div>
    </div>
  </div>
</span>
        </div>
      </div>
    </section>
 </main>
  <div class="--layout-footer">
    <hlx-button
      class="primary sm"
      @click="matrixRule(headvalue,theads.input,tBody)"
      >Add</hlx-button
    >
  </div>
  <!-- {{ headvalue }}
  <br />
  <br />
  <br />
  {{ theads.input }}
  <br />
  <br />
  <br />
  {{ transformData(tBody) }} -->

  <!-- <hlx-button class="primary sm" style="width:10%; margin-left:2%" @click="matrixRule(tBody,theads.input,headvalue)">Create Matrix</hlx-button> -->
</template>

<script>
import dropdown from './DropdowncontainerComponent.vue';
import { DMNAxiosInstance } from '@/config/axiosConfig';

import inputColMapModal from './inputColumnMappingModal.vue';

export default {
  components: {
    inputColMapModal,
    dropdown,
  },
  data() {
    return {
        decisionPayload: {
        name: '',
        category:'',
        description: '',
        matrix: [],
        ruleId: '',
        ruleSet: [],
        status: 'Active',
        hitPolicy: 'unique',
        inputMapping: [],
        columnMapping: [],
        createdBy: "639af00f9f515f57074584ef"
      },
      readOnly: false,
      prevalue: '==',
      columndragstart: null,
      columndragend: null,
      gridData: {},
      intellisensePayload: [
        {
          var: 'Number',
          to: 'Number',
          type: 'number',
          operators: {
            is: [],
          },
        },
        {
          var: 'String',
          to: 'String',
          type: 'string',
          operators: {
            is: [],
          },
        },
        {
          var: 'date',
          to: 'date',
          type: 'date',
          operators: {
            is: [],
          },
          
        },
         {
          var: 'boolean',
          to: 'boolean',
          type: 'boolean',
          operators: {
            is: [],
          },
          
        }
      ],
      op_data: [
        {
          type: 'string',
          name: 'endsWith',
          value: 'endsWith',
          checked: false,
          disabled: false,
        },
      ],
      inputIndex: null,
      outputIndex: null,
      tableHeadDetails: {},
      shwOutMap: false,
      shwInpMap: false,
      theads: {
        input: [
          {
            id: 1,
            objectMapping: {
              label: '',
              type: '',
            },
            dataType: 'Data type',
            type: 'input',
            operators: {},
            labelName: 'Column 1',
            source: 'Manual',
            mode: 'Manual',
            from: '',
            to: '',
          },
          {
            id: 3,
            objectMapping: {
              label: '',
              type: '',
            },
            dataType: 'Data type',
            type: 'input',
            operators: {},
            labelName: 'Column 2',
            source: 'Manual',
            mode: 'Manual',
            from: '',
            to: '',
          },
          {
            id: 2,
            objectMapping: {
              label: '',
              type: '',
            },
            dataType: 'Data type',
            type: 'input',
            operators: {},
            labelName: 'Column 3',
            source: 'Manual',
            mode: 'Manual',
            from: '',
            to: '',
          },
        ],
      },
      tBody: [
        {
          'Column 1': {
            value: '',
            value1: '',
            value2: '',
            operators: '',
            betweenValues: [],
            expression: '',
          },
          'Column 2': {
            value: '',
            value1: '',
            value2: '',
            operators: '',
            betweenValues: [],
            expression: '',
          },
          'Column 3': {
            value: '',
            value1: '',
            value2: '',
            operators: '',
            betweenValues: [],
            expression: '',
          },
        },
      ],
      headvalue: [
        {
          prop: 'Column 1',
          type: 'input',
          dataType: 'String',
          source: 'manual',
        },
        {
          prop: 'Column 2',
          type: 'input',
          dataType: 'String',
          source: 'manual',
        },
        {
          prop: 'Column 3',
          type: 'input',
          dataType: 'String',
          source: 'manual',
        },
      ],
      tableData: [
        {
          name: 'vijay',
          cartoon: 'pikachu',
          status: 'Active',
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: 'Brock',
          cartoon: 'charizard',
          status: 'Inactive',
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: 'Misty',
          cartoon: 'ninja',
          status: 'Inactive',
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: 'May',
          cartoon: 'frookie',
          status: 'Active',
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: 'Feroz',
          cartoon: 'pikachu',
          status: 'Active',
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: 'Ganesh',
          cartoon: 'charizard',
          status: 'Inactive',
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: 'Mathan',
          cartoon: 'ninja',
          status: 'Inactive',
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: 'Pooja',
          cartoon: 'frookie',
          status: 'Active',
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: 'Raags',
          cartoon: 'pikachu',
          status: 'Active',
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: 'Gopi',
          cartoon: 'charizard',
          status: 'Inactive',
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: 'Sudhar',
          cartoon: 'ninja',
          status: 'Inactive',
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: 'Thanos',
          cartoon: 'frookie',
          status: 'Active',
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: 'Kang',
          cartoon: 'frookie',
          status: 'Active',
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: 'MODOK',
          cartoon: 'frookie',
          status: 'Active',
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
        {
          name: 'Loki',
          cartoon: 'charizard',
          status: 'Active',
          context: { show: false },
          checked: false,
          id: 1,
          disabled: false,
        },
      ],
      currPage: 1,
      rowsPerPage: 5,
      startdata: {},
      enddata: {},
      rowdragstart: null,
      rowdragend: null,
      timeoutId: null,
      refData: {},
      rowdragposition: 0,
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
    paginatedData1() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData1.slice(start, end);
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.tableData.length;
    },
  },
  watch: {
    tBody: {
      handler(val) {
        val;
      },
      immediate: true,
      deep: true,
    },
    theads: {
      handler(val) {
        val;
      },
      immediate: true,
      deep: true,
    },
    headvalue: {
      handler(Val) {
        Val;
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    // this.headvalue = [
    //   { prop: 'AccessType', type: 'input', dataType: 'date', source: 'Manual' },
    //   {
    //     prop: 'description',
    //     type: 'input',
    //     dataType: 'string',
    //     source: 'Manual',
    //   },
    //   {
    //     prop: 'AccessType3',
    //     type: 'input',
    //     dataType: 'string',
    //     source: 'Manual',
    //   },
    // ];
    // this.theads.input = [
    //   {
    //     id: 1,
    //     objectMapping: { label: 'EIOP.EI.AccessType', type: 'date' },
    //     dataType: 'date',
    //     type: 'input',
    //     operators: {},
    //     labelName: 'AccessType',
    //     source: 'Manual',
    //     mode: 'Manual',
    //     to: 'AccessType',
    //     arrayValues: '',
    //     var: 'EIOP.EI.AccessType',
    //   },
    //   {
    //     id: 3,
    //     objectMapping: { label: 'market.description', type: 'string' },
    //     dataType: 'string',
    //     type: 'input',
    //     operators: {},
    //     labelName: 'description',
    //     source: 'Manual',
    //     mode: 'Manual',
    //     to: 'description',
    //     arrayValues: '',
    //     var: 'market.description',
    //   },
    //   {
    //     id: 2,
    //     objectMapping: { label: 'market.description', type: 'string' },
    //     dataType: 'string',
    //     type: 'input',
    //     operators: {},
    //     labelName: 'AccessType3',
    //     source: 'Manual',
    //     mode: 'Manual',
    //     to: 'AccessType3',
    //     arrayValues: '',
    //     var: 'market.description',
    //   },
    // ];
    //   let p = [ { "AccessType": "2024-02-06T00:00:00.000Z", "description": "hello", "AccessType3": "hai" } ]
    // this.tBody = this.reverseTransformData(p);
  },
  methods: {
    reverseTransformData(inputData) {
      // Check if inputData is an array and not empty
      if (Array.isArray(inputData) && inputData.length > 0) {
        // Create an array to store the reversed result objects
        const reversedResultArray = [];

        // Loop through each object in the array
        inputData.forEach((dataObject) => {
          // Create a new object for reversed result
          const reversedResult = {};

          // Loop through each key in the input object
          for (const key in dataObject) {
            // Check if the property exists in the input object
            if (dataObject[key] !== undefined) {
              // Create the reversed structure
              reversedResult[key] = {
                value: dataObject[key],
                value1: '',
                value2: '',
                operators: '',
                betweenValues: [],
                expression: '',
              };
            }
          }

          // Add the reversed result object to the array
          reversedResultArray.push(reversedResult);
        });

        // Return the array of reversed result objects
        return reversedResultArray;
      } else {
        // Return an empty array if the input is not as expected
        return [];
      }
    },
    transformData(inputData) {
      // Check if inputData is an array and not empty
      if (Array.isArray(inputData) && inputData.length > 0) {
        // Create an array to store the result objects
        const resultArray = [];

        // Loop through each object in the array
        inputData.forEach((dataObject) => {
          // Extract values from each property and create a new object
          const result = {};
          for (const key in dataObject) {
            if (dataObject[key] && dataObject[key].value !== undefined) {
              result[key] = dataObject[key].value;
            }
          }

          // Add the result object to the array
          resultArray.push(result);
        });

        // Return the array of result objects
        return resultArray;
      } else {
        // Return an empty array if the input is not as expected
        return [];
      }
    },
    destructureGridData(payload) {
      this.theads.input = payload.inputMapping;
      this.theads.output = payload.outputMapping;
      this.tBody = payload.decisions;
      const concatenatedArray = [
        ...payload.inputMapping,
        ...payload.outputMapping,
      ];

      // Add a new key named "prop" to each object and assign the value from "labelName" to "prop"
      const modifiedArray = concatenatedArray.map((item) => ({
        ...item,
        prop: item.labelName,
      }));

      // Assign the modified array to the headvalue variable
      this.headvalue = modifiedArray;
    },
    pushOperators(e, val) {
      this.theads.input.filter((item) => {
        item.labelName === val ? (item.operators[e] = []) : '';
      });
    },
    rowadddata(index) {
      const firstObject = this.tBody[0];
      const duplicatedObject = JSON.parse(JSON.stringify(firstObject)); // Deep copy of the first object

      // Set all non-array values to ""
      for (const key in duplicatedObject) {
        for (const innerKey in duplicatedObject[key]) {
          if (!Array.isArray(duplicatedObject[key][innerKey])) {
            duplicatedObject[key][innerKey] = '';
          }
        }
      }
      this.tBody.splice(index, 0, duplicatedObject);
      // this.tBody.push(duplicatedObject)
    },
    deleterowdata(index) {
      this.tBody.splice(index, 1);
    },
    focusInput($event, bw) {
      if (!bw) {
        $event.target.parentElement.style.border = '1px solid #54bd95';
      } else {
        $event.target.parentElement.style.border = '1px solid #54bd95';
      }
    },
    focusDropdown($event, col_index, index) {
      document
        .getElementById('input-row-dropdown-container' + col_index + index)
        .classList.remove('normal-border');
      document
        .getElementById('input-row-dropdown-container' + col_index + index)
        .classList.add('border-style');
    },
    blurDropdown($event, col_index, index) {
      document
        .getElementById('input-row-dropdown-container' + col_index + index)
        .classList.remove('border-style');
    },
    blurInput($event, bw) {
      const active = $event.target === document.activeElement;
      if (!active) {
        if (!bw) {
          $event.target.parentElement.style.border = '1px solid transparent';
        } else {
          $event.target.parentElement.style.border = '1px solid transparent';
        }
      }
    },
    handleTBodyAndTheads(tBodyVal, theadsVal) {
      if (this.timeoutId === null) {
        this.timeoutId = setTimeout(() => {
          // Perform your logic here
          this.bindExpression(theadsVal, tBodyVal);
          this.processTBody(tBodyVal);

          // Reset the timeoutId after the execution
          this.timeoutId = null;
        }, 0);
      }
    },
    bindExpression(output, tbody) {
      for (const item of output) {
        const labelName = item.labelName;
        const expression = item.expression;

        for (const tbodyItem of tbody) {
          if (tbodyItem[labelName]) {
            tbodyItem[labelName].expression = expression;
            break;
          }
        }
      }
    },
    processTBody(originalPayload) {
      const payloadCopy = JSON.parse(JSON.stringify(originalPayload)); // Create a deep copy of the original
      this.gridData['decisions'] = payloadCopy;
      this.gridData['inputMapping'] = this.theads.input;
      this.gridData['outputMapping'] = this.theads.output;
    },
    findHighestNumberLabel(payload) {
      let highestNumber = 0;
      // let highestNumberLabel = null;

      payload.forEach((item) => {
        const label = item.labelName;
        const numberMatchArr = label.split(' ');
        // Regular expression to extract numbers from the end of the label
        const numberMatch = Number(numberMatchArr[numberMatchArr.length - 1]);
        // console.log(label, numberMatch);
        if (numberMatch) {
          const number = parseInt(numberMatch, 10);
          if (number > highestNumber) {
            highestNumber = number;
            // highestNumberLabel = label;
          }
        }
      });

      return Number(highestNumber);
    },
    rowdraggable(index) {
      document.querySelectorAll('.rowdraggable-icon').forEach((e) => {
        e.style.display = 'none';
      });
      document.querySelector('#rowdraggable-icon' + index).style.display =
        'flex';
      const icon = document.getElementById('rowdraggable-icon' + index);
      const parent = icon.parentElement;
      // console.log(parent,'parent');
      const { left } = parent.getBoundingClientRect();
      const iconLeft = left;
      // console.log(iconLeft);
      this.rowdragposition = iconLeft - 8;
    },
    outrowdraggable() {
      document.querySelectorAll('.rowdraggable-icon').forEach((e) => {
        e.style.display = 'none';
      });
    },
    deleteInputThead(val) {
      const index = this.theads.input.findIndex((item) => item.id === val.id);
      this.theads.input.splice(index, 1);
      let inputColumnItems = this.headvalue.filter(
        (item) => item.type === 'input'
      );
      let outputColumnItems = this.headvalue.filter(
        (item) => item.type === 'output'
      );
      const index2 = inputColumnItems.findIndex(
        (item) => item.prop === val.labelName
      );
      inputColumnItems.splice(index2, 1);
      this.headvalue = inputColumnItems.concat(outputColumnItems);

      this.tBody.forEach((e) => {
        delete e[val.labelName];
      });
    },
    deleteOutputhead(val) {
      const index = this.theads.output.findIndex((item) => item.id === val.id);
      this.theads.output.splice(index, 1);
      let inputColumnItems = this.headvalue.filter(
        (item) => item.type === 'input'
      );
      let outputColumnItems = this.headvalue.filter(
        (item) => item.type === 'output'
      );
      const index2 = outputColumnItems.findIndex(
        (item) => item.prop === val.labelName
      );
      outputColumnItems.splice(index2, 1);
      this.headvalue = inputColumnItems.concat(outputColumnItems);

      this.tBody.forEach((e) => {
        delete e[val.labelName];
      });
    },
    addInputColumn(index, pos) {
      const idNumber = this.findHighestNumberLabel(this.theads.input) + 1;
      let inputColumnItems = this.headvalue.filter(
        (item) => item.type === 'input'
      );
      let outputColumnItems = this.headvalue.filter(
        (item) => item.type === 'output'
      );
      // console.log(inputColumnItems, outputColumnItems);
      const newItem = {
        id: Math.floor(Math.random() * 100000),
        objectMapping: {
          label: '',
          type: '',
        },
        dataType: 'Data type',
        type: 'input',
        operators: {},
        labelName: 'Column ' + idNumber,
        source: 'Manual',
        mode: 'Manual',
        from: '',
        to: '',
      };
      const headProp = {
        prop: 'Column ' + idNumber,
        type: 'input',
        dataType: 'String',
        source: 'manual',
      };
      const tbodyItem = {
        value: '',
        value1: '',
        value2: '',
        operators: '',
        betweenValues: [],
      };
      if (pos === 'left') {
        this.theads.input.splice(index, 0, newItem);
        inputColumnItems.splice(index, 0, headProp);
        this.headvalue = inputColumnItems.concat(outputColumnItems);
      } else if (pos === 'right') {
        this.theads.input.splice(index + 1, 0, newItem);
        inputColumnItems.splice(index + 1, 0, headProp);
        this.headvalue = inputColumnItems.concat(outputColumnItems);
      } else {
        // Handle invalid direction here if needed
        // console.error('Invalid direction provided. Use "left" or "right".');
      }
      // console.log(index);

      const keyName = 'Column ' + idNumber;
      // this.headvalue.push(headProp);

      this.tBody.forEach((e) => {
        e[keyName] = tbodyItem;
      });
      this.hideIcon(index, pos, 'input-');
    },
    addOutputColumn(index, pos, $event) {
      const idNumber = this.findHighestNumberLabel(this.theads.output) + 1;
      let inputColumnItems = this.headvalue.filter(
        (item) => item.type === 'input'
      );
      let outputColumnItems = this.headvalue.filter(
        (item) => item.type === 'output'
      );
      if ($event.target.parentElement.id === 'add-output-column-icon-right-0') {
        $event.target.parentElement.parentElement.parentElement.parentElement.style.borderTopRightRadius =
          '0px';
      }
      const newItem = {
        id: Math.floor(Math.random() * 100000),
        objectMapping: {
          label: '',
          type: '',
        },
        dataType: 'Data type',
        type: 'output',
        labelName: 'Output label ' + idNumber,
        source: 'Manual',
        mode: 'Manual',
        from: '',
        to: '',
        expression: '',
      };
      const headProp = {
        prop: 'Output label ' + idNumber,
        type: 'output',
        dataType: 'String',
        source: 'manual',
      };
      const tbodyItem = {
        value: '',
        value1: '',
        value2: '',
        operators: '',
        betweenValues: [],
      };
      if (pos === 'left') {
        this.theads.output.splice(index, 0, newItem);
        outputColumnItems.splice(index, 0, headProp);
        this.headvalue = inputColumnItems.concat(outputColumnItems);
      } else if (pos === 'right') {
        this.theads.output.splice(index + 1, 0, newItem);
        outputColumnItems.splice(index + 1, 0, headProp);
        this.headvalue = inputColumnItems.concat(outputColumnItems);
      } else {
        // Handle invalid direction here if needed
        // console.error('Invalid direction provided. Use "left" or "right".');
      }

      const keyName = 'Output label ' + idNumber;
      this.tBody.forEach((e) => {
        e[keyName] = tbodyItem;
      });
      this.hideIcon(index, pos, 'output-');
    },
    showIcon(index, pos, type) {
      if (!this.readOnly) {
        const icon = document.querySelector(
          '#add-' + type + 'column-icon-' + pos + '-' + index
        );
        icon.style.display = 'flex';
        const parent = icon.parentElement;
        // Get the position of the parent add-column div
        const { top, right } = parent.getBoundingClientRect();

        // Calculate the top and right positions for the icon
        const iconTop = top - icon.offsetHeight;
        const iconRight = window.innerWidth - right;
        // console.log(iconTop, iconRight);
        icon.style.top = iconTop + 'px';
        if (pos === 'right') {
          icon.style.right = iconRight - 10 + 'px';
        } else {
          icon.style.right = iconRight - 4 + 'px';
        }
      }
    },
    columnover(index) {
      if (!this.readOnly) {
        const icon = document.getElementById('columndrag' + index);
        icon.style.display = 'flex';
        const parent = icon.parentElement;
        // Get the position of the parent add-column div
        const { top, right } = parent.getBoundingClientRect();

        // Calculate the top and right positions for the icon
        const iconTop = top - icon.offsetHeight;
        const iconRight = window.innerWidth - right;
        // console.log(iconTop, iconRight);
        icon.style.top = iconTop + 7 + 'px';
        // if (pos === "right") {
        icon.style.right = iconRight + 7 + 'px';
        // } else {
        // icon.style.right = iconRight - 4 + "px";
        // }
      }
    },
    columnleave(index) {
      const icon = document.getElementById('columndrag' + index);
      icon.style.display = 'none';
    },
    columnoutputover(index) {
      if (!this.readOnly) {
        const icon = document.getElementById('columndragoutput' + index);
        icon.style.display = 'flex';
        const parent = icon.parentElement;
        // Get the position of the parent add-column div
        const { top, right } = parent.getBoundingClientRect();

        // Calculate the top and right positions for the icon
        const iconTop = top - icon.offsetHeight;
        const iconRight = window.innerWidth - right;
        // console.log(iconTop, iconRight);
        icon.style.top = iconTop + 7 + 'px';
        // if (pos === "right") {
        icon.style.right = iconRight + 7 + 'px';
        // } else {
        // icon.style.right = iconRight - 4 + "px";
        // }
      }
    },
    columnoutputleave(index) {
      const icon = document.getElementById('columndragoutput' + index);
      icon.style.display = 'none';
    },

    hideIcon(index, pos, type) {
      if (!this.readOnly) {
        const icon = document.querySelector(
          '#add-' + type + 'column-icon-' + pos + '-' + index
        );
        icon.style.display = 'none';
      }
    },
    handleIconClick() {
      // Your logic when the icon is clicked goes here
      // console.log("Icon clicked!");
    },
    saveOutputThead(data) {
      // console.log(data);
      let beforeChange = this.theads.output.map((item) => {
        return item.id === data.id ? item.labelName : 'no data';
      });
      let newVal = '';
      for (let index = 0; index < beforeChange.length; index++) {
        const element = beforeChange[index];
        if (element !== 'no data') {
          newVal = element;
        }
      }
      beforeChange = newVal;
      const afterchange = data.labelName;
      // console.log(beforeChange, afterchange);
      this.replaceValuesFunc(
        beforeChange,
        afterchange,
        data.dataType,
        data.source,
        this.headvalue,
        this.tBody
      );
      const index = this.theads.output.findIndex((item) => item.id === data.id);
      // console.log(index);
      if (index !== -1) {
        this.theads.output = this.theads.output.map((item) => {
          return item.id === data.id
            ? JSON.parse(JSON.stringify(data))
            : JSON.parse(JSON.stringify(item));
        });
      }

      if (data.source === 'Reference data') {
        this.refData[data.labelName] = [
          { name: data.labelName, value: data.labelName },
        ];
      }
    },
    async saveInputThead(data) {
      console.log(data, 'IP DATA ADD');
      let beforeChange = this.theads.input.map((item) => {
        return item.id === data.id ? item.labelName : 'no data';
      });
      let newVal = '';
      for (let index = 0; index < beforeChange.length; index++) {
        const element = beforeChange[index];
        if (element !== 'no data') {
          newVal = element;
        }
      }
      beforeChange = newVal;
      const afterchange = data.labelName;
      // console.log(beforeChange, afterchange);
      this.replaceValuesFunc(
        beforeChange,
        afterchange,
        data.dataType,
        data.source,
        this.headvalue,
        this.tBody
      );
      const index = this.theads.input.findIndex((item) => item.id === data.id);
      // console.log(index);
      if (index !== -1) {
        this.theads.input = this.theads.input.map((item) => {
          return item.id === data.id
            ? JSON.parse(JSON.stringify(data))
            : JSON.parse(JSON.stringify(item));
        });
      }
      if (
        data.source.toLowerCase() === 'manual' &&
        data.dataType.toLowerCase() === 'array'
      ) {
        const arrayValues = data.arrayValues.split('\n');

        // Filter out empty values and trim each element
        const filteredArray = arrayValues
          .filter((value) => value.trim() !== '')
          .map((value) => ({ name: value.trim() }));
        console.log(filteredArray);
        this.refData[data.labelName] = filteredArray;
      }
      if (data.source === 'Reference data') {
        let referneceDataRes = await DMNAxiosInstance.get(
          'mapRef/reference_data/' + data.var
        );
        console.log(referneceDataRes);
        this.refData[data.labelName] = referneceDataRes.data.data || [];
      }
      if (data.source === 'Computed') {
        this.tBody.forEach((item) => {
          item[data.to]['value'] = data.expression;
        });
      }
    },
    replaceValuesFunc(
      beforeChange,
      afterChange,
      dataType,
      source,
      array1,
      array2
    ) {
      // console.log(beforeChange, afterChange, array1, array2);
      const index = array1.findIndex((item) => item.prop === beforeChange);
      if (index !== -1) {
        array1[index].prop = afterChange;
        array1[index].dataType = dataType;
        array1[index].source = source;
      }
      array2.forEach((obj) => {
        if (beforeChange in obj) {
          obj[afterChange] = JSON.parse(JSON.stringify(obj[beforeChange]));
          if (afterChange !== beforeChange) {
            delete obj[beforeChange];
          }
        }
      });
      // console.log(index, array1, array2);
    },
    openTheadMappingModal(type, val) {
      // console.log(type);
      if (type === 'input') {
        const index = this.theads.input.length - 1;
        this.inputIndex = index;
        this.shwInpMap = true;
        this.tableHeadDetails = JSON.parse(JSON.stringify(val));
      } else if (type === 'output') {
        const index = this.theads.output.length - 1;
        this.outputIndex = index;
        this.shwOutMap = true;
        this.tableHeadDetails = JSON.parse(JSON.stringify(val));
      }
    },
    columnstart(index) {
      // console.log(index, 'start');
      this.columndragstart = index;
    },
    columnenter(index) {
      // console.log(index, 'enter');
      this.columndragend = index;
    },
    columnend(value) {
      if (value === 'input') {
        let input = [...this.theads.input];
        const elementToMove = input.splice(this.columndragstart - 1, 1)[0];
        input.splice(this.columndragend - 1, 0, elementToMove);
        this.theads.input = input;

        let inputColumnItems = this.headvalue.filter(
          (item) => item.type === 'input'
        );
        // console.log(inputColumnItems);
        let outputColumnItems = this.headvalue.filter(
          (item) => item.type === 'output'
        );

        const elementToMove1 = inputColumnItems.splice(
          this.columndragstart - 1,
          1
        )[0];
        inputColumnItems.splice(this.columndragend - 1, 0, elementToMove1);
        this.headvalue = inputColumnItems.concat(outputColumnItems);
        // console.log(this.headvalue);
      } else {
        let output = [...this.theads.output];
        const elementToMove = output.splice(this.columndragstart - 1, 1)[0];
        output.splice(this.columndragend - 1, 0, elementToMove);
        this.theads.output = output;

        let inputColumnItems = this.headvalue.filter(
          (item) => item.type === 'input'
        );
        // console.log(inputColumnItems);
        let outputColumnItems = this.headvalue.filter(
          (item) => item.type === 'output'
        );

        const elementToMove1 = outputColumnItems.splice(
          this.columndragstart - 1,
          1
        )[0];
        outputColumnItems.splice(this.columndragend - 1, 0, elementToMove1);
        this.headvalue = inputColumnItems.concat(outputColumnItems);
        // console.log(this.headvalue);
      }
    },
    start(index) {
      this.rowdragstart = index - 1;
      // this.startindex = index - 1;
    },
    enter(index) {
      this.rowdragend = index - 1;
      // this.endindex = index - 1;
    },
    end() {
      const elementToMove1 = this.tBody.splice(this.rowdragstart, 1)[0];
      this.tBody.splice(this.rowdragend, 0, elementToMove1);
    },
    applyColumns(val) {
      this.headvalue = [];
      val.forEach((e) => {
        if (e.checked === true) {
          this.headvalue.push(e);
        }
      });
    },
    arrange(val) {
      // console.log('arrange', val)
      this.re_arrange = val;
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    currentPage(val) {
      this.currPage = val;
    },
    changeRowsPerPage(count) {
      // console.log(count)
      this.rowsPerPage = count;
    },
    getDate(format) {
      const dateObj = new Date();
      const year = dateObj.getFullYear();
      const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
      const date = ('0' + dateObj.getDate()).slice(-2);

      if (format === 'number') {
        return `${year}-${month}-${date}`;
      } else if (format === 'string') {
        const monthNames = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        const monthName = monthNames[dateObj.getMonth()];
        return `${monthName} ${date}, ${year}`;
      } else {
        return "Invalid argument. Please enter 'number' or 'string'.";
      }
    },
    async matrixRule(headvalue,theads,tbody){
      let columnMapping = headvalue
      let inputMapping = theads
      let matrix = this.transformData(tbody)
      this.decisionPayload.columnMapping = columnMapping
      this.decisionPayload.inputMapping = inputMapping
      this.decisionPayload.matrix=matrix
          let addMatrix = await DMNAxiosInstance.post(
          'decisionMatrics/developerHalleyx',this.decisionPayload
        );
     console.log(addMatrix,"test")
    },
    async populate(val){

      const autopermute = await DMNAxiosInstance.post('decisionMatrics/autopermute',JSON.parse(val))
      console.log(await autopermute.data,"test")


      let foundTransform = this.reverseTransformData(autopermute.data)
      this.tBody=this.tBody.concat(foundTransform)
      console.log(this.tBody,"foundOption")
    }
  },
};
</script>
