<template>
  <div style="padding: 30px">
    <div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
        "
      >
        <div style="display: flex; align-items: center; gap: 16px">
          <span style="">
            <hlx-file-upload
               :file-type="'.jpg,.jpeg,.png'"
                  :file-size-limit="'20mb'"
                  :uploadtype="'Rectangle'"
                  :style-background="backgrounStyleupload"
                  :height="50"
                  :width="50"
                  :read-only="defalt"
                  :custom-icon="icon"
                  :type="'profile-upload-preview'"
                  :name-display="payloadLocation.customName"
                  :content="'Import'"
                  :profile-pic-preview="profilePicUrl"
            />
          </span>

          <div style="display: flex; flex-direction: column; gap: 8px">
            <div style="display: flex; align-items: center; gap: 8px">
              <span style="font-weight: bold">{{ payloadLocation.customName }}</span>
              <hlx-label
                v-if="
                  payloadLocation.status.length > 0 &&
                  payloadLocation.status === 'Active'
                "
                class="primary sm"
                >{{ payloadLocation.status }}</hlx-label
              >
              <hlx-label
                v-if="
                  payloadLocation.status.length > 0 &&
                  payloadLocation.status === 'Inactive'
                "
                class="error sm"
                >{{ payloadLocation.status }}</hlx-label
              >
            </div>
            <div style="color: #a6a6a6; font-size: 12px; width: max-content">
              {{ payloadLocation.contactMedium[3].characteristic.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <locationComponent
      :data="payloadLocation.contactMedium"
      @new-address="receivedNewAddress"
      @update-address="receivedUpdateAddress"
      @delete-index="deleteAddress"
    ></locationComponent>
  </div>
</template>

<script>
// import addressComponent from "@/components/CM/autocompleteComponent.vue";
import { MLTAxiosInstance ,UPCAxiosInstance} from "@/config/axiosConfig";
import locationComponent from "@/components/SM/addSupplierLocationComponent.vue";

export default {
  components: {
    // addressComponent,
    locationComponent,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    module: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      profilePicUrl: "",
        company:"",
        userId:"",
      payloadLocation: {
        attachment: [],
        name: "",
        role: "",
        password: "",
        email: "",
        phone: "",
        tags: [],
        notes: [],
        companyName: "",
        tradingName: "",
        status: "",
        type: "",
        "@baseType": "",
        paymentTerms: [
          {
            name: "",
            id: "",
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              "@type": "",
            },
          },
        ],
      },
    };
  },

  async mounted() {
    const val = await MLTAxiosInstance.get(`partymanagement/individual/auth`);
    this.company = val.data.company;
    this.userId = val.data.userId;
    const access = await MLTAxiosInstance.get(
      `partyRole/get/${val.data.userId}`
    );

    const payload = await MLTAxiosInstance.get(
      `partymanagement/organisation/${this.userId}?company=${this.company}`
    );
    this.payloadLocation = payload.data;
    console.log(access, val.data.company, val.data.company, val, "<>");
    const profileName = this.payloadLocation.attachment.filter((item) => {
      return item["@type"] !== undefined && item["@type"] === "profile"
        ? item
        : false;
    });
    console.log(profileName[0]?.name);
    this.profilePicName = profileName[0]?.name;
    const url = await UPCAxiosInstance.get(
      `/gcpupload/files/${profileName[0]?.name}`,
      { responseType: "arraybuffer" }
    );
    // console.log(new Uint8Array([url.data,"url incoming"]))
    this.rawProfileUrlData = url.data;
    this.profilePicUrl = await this.bufferToDataURL(url.data);
  },
  methods: {
    async bufferToDataURL(buffer) {
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = "";
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },
    async deleteAddress(index) {
      console.log(this.payloadLocation.contactMedium[index]);
      let changeDefault = false;
      const type = this.payloadLocation.contactMedium[index].mediumType;
      let p = [];
      if (this.payloadLocation.contactMedium[index].characteristic.default === true) {
        changeDefault = true;
      }
      this.payloadLocation.contactMedium.splice(index, 1);
      p = this.payloadLocation.contactMedium.filter((item) => {
        return item.mediumType === type;
      });
      if (p[0] !== undefined && changeDefault === true) {
        p[0].default = true;
      }
      console.log(p[0]);
      await this.addNewSupplier();
      this.parentCompany = this.company;
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.userId}?company=${this.company}`
      );
      console.log(payload);
      let contacts = await MLTAxiosInstance.get(
        `/partymanagement/individual/listuser?company=${this.company}`
      );
      contacts.data.forEach((e) => {
        const preferredMedium = e.contactMedium.find((item) => item.preferred);
        e.preferred = preferredMedium ? preferredMedium.mediumType : null;
      });
      this.contactTableData = contacts.data;
      console.log(this.contactTableData);
      const preferredMedium = payload.data.contactMedium.find(
        (item) => item.preferred
      );
      // console.log(preferredMedium);
      this.payloadLocation = payload.data;
      this.payloadLocation.preferred = preferredMedium
        ? preferredMedium.mediumType
        : null;
      console.log(this.payloadLocation);

      console.log(p);
      this.attachmentData = this.payloadLocation.attachment;
      this.noteData = this.payloadLocation.notes;
      this.rawPayload = JSON.parse(JSON.stringify(this.payloadLocation));
    },
    async receivedUpdateAddress(val) {
      this.payloadLocation.contactMedium[val.index] = val.data;
      await this.addNewSupplier();
      this.parentCompany = this.company;
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.userId}?company=${this.company}`
      );
      console.log(payload);
      let contacts = await MLTAxiosInstance.get(
        `/partymanagement/individual/listuser?company=${this.company}`
      );
      contacts.data.forEach((e) => {
        const preferredMedium = e.contactMedium.find((item) => item.preferred);
        e.preferred = preferredMedium ? preferredMedium.mediumType : null;
      });
      this.contactTableData = contacts.data;
      console.log(this.contactTableData);
      const preferredMedium = payload.data.contactMedium.find(
        (item) => item.preferred
      );
      // console.log(preferredMedium);
      this.payloadLocation = payload.data;
      this.payloadLocation.preferred = preferredMedium
        ? preferredMedium.mediumType
        : null;
      console.log(this.payloadLocation);
      this.attachmentData = this.payloadLocation.attachment;
      this.noteData = this.payloadLocation.notes;
      this.rawPayload = JSON.parse(JSON.stringify(this.payloadLocation));
    },
    async addNewSupplier() {
      this.payloadLocation.id = this.payloadLocation._id;
      delete this.payloadLocation._id;
      const addSupplier = await MLTAxiosInstance.patch(
        `/partymanagement/organisation?company=${this.company}`,
        this.payloadLocation
      );
      console.log(addSupplier, "test");
      if (addSupplier.data.tradingName) {
        this.notification = {
          type: "success",
          message: "All set! Your changes have been saved successfully!",
        };
        this.view = true;
      }
      const access = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.userId}?company=${this.company}`
      );
      this.payloadLocation = await access.data;
      
      this.rawPayload = JSON.parse(JSON.stringify(addSupplier.data));
    },
    async receivedNewAddress(val) {
      this.payloadLocation.contactMedium.push(val);
      await this.addNewSupplier();
      this.parentCompany = this.company;
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.userId}?company=${this.company}`
      );
      console.log(payload);
      let contacts = await MLTAxiosInstance.get(
        `/partymanagement/individual/listuser?company=${this.company}`
      );
      contacts.data.forEach((e) => {
        const preferredMedium = e.contactMedium.find((item) => item.preferred);
        e.preferred = preferredMedium ? preferredMedium.mediumType : null;
      });
      this.contactTableData = contacts.data;
      console.log(this.contactTableData);
      const preferredMedium = payload.data.contactMedium.find(
        (item) => item.preferred
      );
      // console.log(preferredMedium);
      this.payloadLocation = payload.data;
      this.payloadLocation.preferred = preferredMedium
        ? preferredMedium.mediumType
        : null;
      console.log(this.payloadLocation);
      this.attachmentData = this.payloadLocation.attachment;
      this.noteData = this.payloadLocation.notes;
      this.rawPayload = JSON.parse(JSON.stringify(this.payloadLocation));
    },
  },
};
</script>
