<template>
  <hlx-alert-notification :notify="notification" auto-close />
  <section class="signin-component-container">
    <div class="signin-form">
      <h3 class="form-header">Sign in</h3>
      <div class="form-input-wrapper">
        <p class="input-header">Work email</p>
        <div class="form-input">
          <hlx-input
            v-model:value="email"
            :label-animation="false"
            label-value="john@gmail.com"
            type="email"
            :custom-error="error"
            :custom-error-message="message"
            :display-error="error"
          />
        </div>
      </div>
      <div class="form-input-wrapper">
        <p class="input-header">Password</p>
        <div class="form-input">
          <hlx-input
            v-model:value="s_password"
            :label-animation="false"
            type="password"
            :password-condition="false"
            label-value="Password"
            :clearable="false"
            :custom-error="error"
            :custom-error-message="message"
            :display-error="error"
            @at-enter="signIn()"
          />
          <!-- @at-enter="signIn" -->
          <!-- <input
            type="text"
            v-model="this.s_password"
            @keyup.prevent.enter="signIn"
            /> -->
        </div>
      </div>
      <p class="content-right" style="text-align: right; cursor: pointer">
        <a class="reference-right" @click="password()"
          ><b>Forgot password ?</b></a
        >
      </p>
      <span class="button-wrapper">
        <hlx-button class="change primary" @click="signIn()" @enter="check()"
          >Sign in</hlx-button
        >
      </span>
      <!-- <p class="content-bottom">
        Not a member yet?&nbsp;<b
          ><a class="reference-bottom" href="/signup">Sign up</a></b
        >
      </p> -->
    </div>
    <div class="reset-user">
      <hlx-modal
        :modal-active="modal_active3"
        :height="'300px'"
        :width="'380px'"
        :modal="true"
        @close="modal_active3 = false"
      >
        <template #header>
          <div id="header">Forgot password?</div>
        </template>
        <template #content>
          <div class="modal-content">
            <br />
            <p class="text" style="text-align: center">
              Enter your email ID to get a link to reset your password
            </p>
            <br />
            <div class="custom-modal">
              <hlx-input
                v-model:value="res_email"
                :label-animation="false"
                label-value="Email"
                type="email"
                :clearable="true"
              />
            </div>
          </div>
        </template>
        <template #footer>
          <hlx-button class="primary sm" @click="reset()">Send</hlx-button>
        </template>
      </hlx-modal>
    </div>
    <hlx-modal
      :modal-active="modal_active2"
      :height="'360px'"
      :width="'480px'"
      :headercontent="true"
      @close="modal_active2 = false"
    >
      <template #header>
        <div id="header">Reset password</div>
      </template>
      <template #content>
        <div class="modal-content">
          <div class="custom-modal">
            <div class="modal-form-input-wrapper">
              <p class="modal-input-header">Create password</p>
              <div class="form-input">
                <hlx-input
                  v-model="s_password"
                  :label-animation="false"
                  type="password"
                  :password-condition="false"
                  label-value="Password"
                  :clearable="false"
                />
              </div>
            </div>
            <br />
            <div class="form-input-wrapper">
              <p class="modal-input-header">Confirm password</p>
              <div class="modal-form-input">
                <hlx-input
                  v-model="s_password"
                  :label-animation="false"
                  type="password"
                  :password-condition="false"
                  label-value="Password"
                  :clearable="false"
                />
              </div>
            </div>
            <br />
            <span class="button-wrapper">
              <hlx-button
                class="primary sm"
                @click="(modal_active3 = false), mail()"
                >Reset password</hlx-button
              >
            </span>
            <br />
            <section class="model-ref">
              <i class="icon-line-arrow-left-ot"></i>&nbsp;&nbsp;&nbsp;<a
                class="model-reference"
                href="/"
                >Back to login</a
              >
            </section>
          </div>
        </div>
      </template>
    </hlx-modal>

    <div class="confirmation">
      <hlx-modal
        :modal-active="modal_active1"
        :height="'250px'"
        :width="'250px'"
        :content-only="true"
        :close-icon="false"
        :outer-layer-close-option="true"
        @close="modal_active1 = false"
      >
        <template #content>
          <div class="modal-content">
            <div>
              <p class="header"><b>Forgot password?</b></p>
            </div>
            <div class="img"><img src="../assets/images/mail.svg" /></div>
            <div><p class="foot">Password reset email sent</p></div>
          </div>
        </template>
      </hlx-modal>
    </div>
    <!-- <hlx-alert-notification
      :showAlert="this.showAlert"
      :notify="notification"
      :autoClose="true"
    /> -->
  </section>
</template>

<script>
import { MLTAxiosInstance } from "../config/axiosConfig";
export default {
  name: "SiginComponent",
  data() {
    return {
      res_email: "",
      notification: {},
      fet: process.env.VUE_APP_HOST_PROTOCOL + "://" + process.env.VUE_APP_HOST,
      modal_active3: false,
      modal_active1: false,
      email: "",
      s_password: "",
      showAlert: false,
      type: "Success",
      company: this.$cookies.get("company"),
      modal_active2: false,
      success: false,
      message: "",
      error: false,
      errors: false,
      redirectFor: "quote_approval",
      userID: null,
      client: null,
    };
  },
  mounted() {
    //
  },
  methods: {
    async reset() {
      const token = await MLTAxiosInstance.get(
        `partymanagement/individual/token/${this.res_email}`
      );

      if (token.data.token != undefined) {
        this.modal_active3 = false;
        let mail = {
          to: this.res_email,
          subject: "Halleyx forget password",
          template: `<!-- 
 -->

<!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <!--[if gte mso 9]>  <xml>   <o:OfficeDocumentSettings>   <o:AllowPNG/>   <o:PixelsPerInch>96</o:PixelsPerInch>   </o:OfficeDocumentSettings>  </xml>  <![endif]-->
    <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="format-detection" content="date=no" />
    <meta name="format-detection" content="address=no" />
    <meta name="format-detection" content="telephone=no" />
    <meta name="x-apple-disable-message-reformatting" />
    <!--[if gte mso 9]>  <style type="text/css" media="all">  sup { font-size: 100% !important; }   table, th, td, div, p, strong, span { font-family: Arial,sans-serif !important; } .intro-title-3, .hero-title-2, .hero-title-3 {font-weight:bold;} </style>  <![endif]-->
    <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900" rel="stylesheet">
    <link href='https://fonts.googleapis.com/css?family=Open Sans' rel='stylesheet'>
    <link href='https://fonts.googleapis.com/css?family=Quicksand&display=swap' rel='stylesheet'>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <style type="text/css" media="screen">
        @font-face {
            font-family: 'Proxima Nova Regular';
            src: url('https://marketing.fitbit.com/fonts/proxima-nova-regular-852046040da1526ac5ac9cdbee2e3723.woff') format('woff');
            font-weight: normal;
            font-style: normal;
        }

        @font-face {
            font-family: 'Proxima Nova Semibold';
            src: url('https://marketing.fitbit.com/fonts/proxima-nova-semibold-3163eca2ebe9b39d7aaf4ba594ce39d7.woff') format('woff');
            font-weight: normal;
            font-style: normal;
        }

        @font-face {
            font-family: 'Proxima Nova Bold';
            src: url('https://marketing.fitbit.com/fonts/proxima-nova-bold-f73f22aa93a861006ae7f54f9968fec0.woff') format('woff');
            font-weight: bold;
            font-style: normal;
        }

        @font-face {
            font-family: 'Proxima Nova Black';
            src: url('https://marketing.fitbit.com/fonts/proxima-nova-black-b68819090cb3f466a9dd7d473d9e12a8.woff') format('woff');
            font-style: normal;
        }

        @font-face {
            font-family: 'Proxima Nova Extrabold';
            src: url('https://marketing.fitbit.com/fonts/proxima-nova-xbold-5d8ad01e573a280ed04306c1bfe5ef83.woff') format('woff');
            font-style: normal;
        }

        @font-face {
            font-family: 'Proxima Nova Light';
            font-style: normal;
            font-weight: normal;
            src: url("https://marketing.fitbit.com/fonts/proxima-nova-light-25b45c331498ff377abe22554f05943d.eot") format("embedded-opentype"), url("https://marketing.fitbit.com/fonts/proxima-nova-light-b08aa39dfd53fb455693786505ed211e.woff") format("woff"), url("https://marketing.fitbit.com/fonts/proxima-nova-light-982154eacb03c17d9cf072a97208af46.ttf") format("truetype"), url("https://marketing.fitbit.com/fonts/proxima-nova-light-918e11e776f56b8c6097ae4af49461e6.svg") format("svg");
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        /* Linked Styles */
        body {
            padding: 0 !important;
            margin: 0 !important;
            display: block !important;
            min-width: 100% !important;
            width: 100% !important;
            background: #f5f5f5;
            -webkit-text-size-adjust: none
        }

        a {
            color: #002a3a;
            text-decoration: none
        }

        p {
            padding: 0;
            margin: 0
        }

        img {
            -ms-interpolation-mode: bicubic;
            /* Allow smoother rendering of resized image in Internet Explorer */
        }

        .mcnPreviewText {
            display: none !important;
        }

        .header-text a {
            color: #939c9e;
            text-decoration: underline
        }




        .btn-1 a,
        .intro-title-1 a,
        .intro-title-3 a,
        .intro-title-4 a {
            color: #ffffff;
            text-decoration: none
        }

        /* Mobile styles */
        @media only screen and (max-device-width: 414px),
        only screen and (max-width: 500px) {
            .mobile-shell {
                width: 414px !important;
                min-width: 414px !important;
            }

            .m-center {
                text-align: center !important;
            }

            .center {
                margin: 0 auto !important;
            }

            .td {
                width: 100% !important;
                min-width: 100% !important;
            }

            .m-br-15 {
                height: 15px !important;
            }

            .m-td,
            .hidden,
            .m-hide {
                display: none !important;
                width: 0 !important;
                height: 0 !important;
                font-size: 0 !important;
                line-height: 0 !important;
                min-height: 0 !important;
            }

            .m-block {
                display: block !important;
            }

            .hauto {
                height: auto !important;
            }

            .mw-30 img {
                width: 30px !important;
                height: auto !important;
            }

            .mw-200 img {
                width: 100% !important;
                height: auto !important;
            }

            .fluid-img img {
                width: 100% !important;
                max-width: 100% !important;
                height: auto !important;
            }

            .column {
                float: left !important;
                width: 100% !important;
                display: block !important;
            }

            .content-spacing {
                width: 15px !important;
            }

            /* Padding */
            .p-0 {
                padding: 0 !important;
            }

            .p-15 {
                padding: 15px !important;
            }

            .pb-5 {
                padding-bottom: 5px !important;
            }

            .pb-10 {
                padding-bottom: 10px !important;
            }

            .pb-15 {
                padding-bottom: 15px !important;
            }

            .pb-20 {
                padding-bottom: 20px !important;
            }

            .pb-25 {
                padding-bottom: 25px !important;
            }

            .pb-30 {
                padding-bottom: 30px !important;
            }

            .p0-10 {
                padding: 0 10px !important;
            }

            .p0-15 {
                padding: 0 15px !important;
            }

            .p20-15 {
                padding: 20px 15px !important;
            }

            .p25-15 {
                padding: 25px 15px !important;
            }

            .p30-15 {
                padding: 30px 15px !important;
            }

            /*   .p35-15 { padding: 35px 15px !important; }*/

            .p0-15-15 {
                padding: 0 15px 0px !important;
            }

            .p0-15-20 {
                padding: 0 15px 20px !important;
            }

            .p0-15-30 {
                padding: 0 15px 30px !important;
            }

            .p25-0-40 {
                padding: 15px 0 30px !important;
            }

            /* Intro */
            .headline {
                font-size: 32px !important;
                line-height: 39px !important;
                padding: 50px 51px 15px 51px !important;
            }

            .hero-title-1 {
                font-size: 18px !important;
                line-height: 22px !important;
                padding: 0px 51px 30px 51px !important;
            }

            .hero-title-2 {
                font-size: 36px !important;
                line-height: 42px !important;
                padding: 0px 25px 0px 25px !important;
            }

            .hero-title-3 {

                font-size: 34px !important;
                line-height: 27px !important;
            }

            .intro-title-1 {
                font-size: 32px !important;
                line-height: 34px !important;
                padding: 46px 53px 15px 53px !important;
                letter-spacing: 0.32px !important;
            }

            .intro-title-2 {
                font-size: 18px !important;
                line-height: 22px !important;
                padding: 0px 53px 0px 53px !important;
            }

            .intro-title-3 {
                font-size: 20px !important;
                line-height: 24px !important;
                padding-bottom: 30px !important;
            }

            .intro-title-4 {
                font-size: 24px !important;
                line-height: 29px !important;
                padding: 50px 53px 15px 53px !important;
                letter-spacing: 1.2px !important;
            }

            .disclaimer {
                font-size: 18px !important;
                line-height: 22px !important;
                padding: 15px 53px 15px !important;
            }

            .disclaimer2 {
                font-size: 18px !important;
                line-height: 22px !important;
                padding: 40px 51px 40px !important;
            }

            .intro-title-5 {
                font-size: 20px !important;
                line-height: 24px !important;
                padding: 0px 53px 40px 53px !important;
            }

            .intro-title-6 {
                font-size: 14px !important;
                line-height: 15px !important;
                padding: 7px 41px !important;
            }

            /*Standard*/
            .no-mobile {
                text-indent: -99999em;
                display: none !important;
            }

            .mobile-only {
                text-indent: 0 !important;
                display: block !important;
                /*width: 480px !important;*/
                width: 100% !important;
                height: auto !important;
                visibility: visible !important;
                overflow: visible !important;
                max-height: none !important;
            }

            .mobile-headline {
                padding: 45px 50px 20px 50px !important;
                font-size: 24px !important;
                line-height: 29px !important;
            }

            /* Titles */
            .title-1 {
                font-size: 15px !important;
                line-height: 19px !important;
            }

            /* Button */
            .btn-1 {
                padding: 0 !important;
            }

            .btn-1 a {
                display: inline-block;
                padding: 6px 22px !important;
            }

            /* Table */
            .table-text-1 {
                font-size: 14px !important;
                line-height: 18px !important;
            }

            .hide-for-desktop {
                display: block !important;
                max-width: 100% !important;
                max-height: 100% !important;
                visibility: visible !important;
                opacity: 1 !important;
                width: auto !important;
                height: auto !important;
            }

            .hero-mobile {
                display: block !important;
            }

            .table-title-c {
                padding: 24px !important;
            }

        }
    </style>
</head>

<body class="body"
    style="padding:0 !important; margin:0 !important; display:block !important; min-width:100% !important; width:100% !important; background:#f5f5f5; -webkit-text-size-adjust:none;">
    <!-- MOBILE PREHEADER PUSH ANY CONTENT AFTER THE PREHEADER FROM SHOWING UP INSERT UNDER BODY TAG -->





    <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#f5f5f5">
        <tr>
            <td align="center" valign="top">
                <table width="600" border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
                    <tr>
                        <td class="td"
                            style="width:600px; min-width:600px; padding-top:12px; margin:0; font-weight:normal;">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                    <td>
                                        <!-- Header -->
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                            bgcolor="#ffffff">
                                            <tr>
                                                <td>
                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                        class="wrapper">
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="10" cellspacing="0"
                                                                    width="100%" class="wrapper" bgcolor="#f5f5f5">

                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="0" cellspacing="0"
                                                                    style="border:none;border-collapse:collapse;"
                                                                    width="100%" class="wrapper" bgcolor="#ffffff">
                                                                    <tr>
                                                                        <td height="22" width="104"><a
                                                                                href="https://halleyx.com"><img
                                                                                    src="https://halleyx.com/img/halleyx-logo-line-black.42985758.svg"
                                                                                    height="35"
                                                                                    style="display:block; border:none;padding-top: 20px; padding-left: 30px;"
                                                                                    alt="logo"></a></td>




                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- END Header -->
                                        <!-- Start Content -->
                                        <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0"
                                            cellpadding="0">
                                            <tr>
                                                <td bgcolor="#FFFFFF" class="p35-15">
                                                    <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0"
                                                        cellpadding="0">

                                                        <!-- headline -->
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="headline"
                                                                style="padding-left: 30px; color:#191A15; font-family: Quicksand; font-size:22px; line-height:56px; font-weight: Bold; text-align:left; letter-spacing: .01em">
                                                                Forgot your password ?</td>
                                                        </tr>
                                                        <!-- #headline -->

                                                        <!--  hero-title-1 -->
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="hero-title-1"
                                                                style="padding-left: 30px;color:#191A15;  font-family: 'Quicksand'; font-size:16px; line-height:20px; font-weight: normal; text-align:left; letter-spacing: .01em">
                                                                That’s okay, it happens !</td>

                                                        </tr>
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" class="hero-title-1"
                                                                style="padding-left: 30px;padding-top:15px;color:#191A15;  font-family: 'Quicksand'; font-size:16px; line-height:20px; font-weight: normal; text-align:left; letter-spacing: .01em">

                                                                Click on the button to reset your password </td>

                                                        </tr>

                                                        <!-- Button -->
                                                        <tr>
                                                            <td bgcolor="#FFFFFF" align="left"
                                                                class="fallback-font btn-pb"
                                                                style="padding-left: 30px;padding-top:40px;padding-bottom: 45px">
                                                                <div class="btn" style="text-align:left;">
                                                                    <!--[if mso]>                                                                     <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://www.fitbit.com/campaign/shop/versa?color=black&utm_source=ET&utm_medium=EM&utm_campaign=20191117_PreBFV2_US_FM_M_SU_SW_32_NA&utm_content=HeroCTALink" style="font-family: Arial, Helvetica, sans-serif;height:36px;v-text-anchor:middle;width:150px;" arcsize="152%" stroke="f" fillcolor="#002A3A">                                                                     <w:anchorlock/>                                                                     <center>                                                                     <![endif]-->
                                                                    <a href="https://halleyx-dmnuat-ghubgwxrcq-uc.a.run.app/invite/${token.data.token}" class="mob-btn"
                                                                        target="_blank"
                                                                        style="background-color:#54BD95;border-radius:6px;color:#ffffff;font-family:Open Sans;font-size:14px; text-align:center;text-decoration:none;width: 218.18px;
                                                                        height: 38px;-webkit-text-size-adjust:none; padding: 10px 12px; line-height: 15px;">Reset
                                                                        password
                                                                    </a>
                                                                    <!--[if mso]>                                                                     </center>                                                                     </v:roundrect>                                                                     <![endif]-->
                                                                </div>
                                                            </td>
                                                        </tr>
                                                </td>
                                            </tr>
                                            <!-- END HERO -->
                                        </table>
                                        <!-- HERO Images-->

                                    </td>
                                </tr>
                            </table>
                            <!-- END Content -->
                            <!--Features-->
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                    <td>
                                        <!--Start Content-->
                                        <!--Desktop RTBs-->

                                        <!--End Desktop RTBs-->
                                        <!--Mobile RTBs-->
                                        <!--[if !mso]><!-->

                                        <!--End Mobile RTBs-->
                                        <!--<![endif]-->
                                        <!--END Features-->
                                        <!-- Footer -->
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                            bgcolor="#ffffff">
                                            <tr>

                                                <td bgcolor="#161C2B" class="disclaimer2"
                                                    style="padding: 40px 77px 40px 77px;color:#FFFFFF; font-family: 'Quicksand'; font-size:14px; line-height:20px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                    <span><a href="https://halleyx.com"
                                                            style="color: #FFFFFF;font-size: 14px;"><u>T&C</u><u
                                                                style="padding-left:40px;padding-right: 40px;">Privacy</u><u>Cookies</u></a></span><br />
                                                    <br />
                                                    © 2023 Halleyx Inc. Copyright and rights reserved <p
                                                        style="padding-left: 17px !important; font-family:Open Sans; font-size: 14px; color: #FFFFFF;margin-top: 15px;  margin-bottom: 0px; letter-spacing: .01em;line-height: 20px;">
                                                        18 King St E #1400, Toronto, ON M5C 1C4

                                                    </p>
                                                    <span style="float:left;margin-left: -60px;padding-top:20px;"><img width="100" src="https://halleyx.com/img/halleyx-logo-line-white.c09fdc43.svg"/></span>
                                                    <span style="float: right;margin-right:-50px;padding-top:20px;color: #FFFFFF;"><a href="https://www.linkedin.com/company/halleyx/"><i class="fa fa-linkedin fa-lg" style="color: #ffffff;" ></i></a>
                                                        <a href="https://halleyx.com/" style="padding-left:5px;padding-right: 5px;"><i class="fa fa-facebook fa-lg" style="color: #ffffff;"></i>
                                                        </a>
                                                        <a href="https://www.instagram.com/halleyx_inc/"><i class="fa fa-instagram fa-lg" style="color: #ffffff;"></i></a></span>
                                                </td>

                                            </tr>
                                        </table>
                                        <!-- Footer -->
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                            bgcolor="#ffffff">
                                            <tr>
                                                <td>
                                                    <style>
                                                        .nowrap {
                                                            word-break: keep-all;
                                                            -moz-hyphens: none;
                                                            -ms-hyphens: none;
                                                            -webkit-hyphens: none;
                                                            hyphens: none;
                                                        }

                                                        a[x-apple-data-detectors] {
                                                            color: inherit !important;
                                                            text-decoration: none !important;
                                                            font-size: inherit !important;
                                                            font-family: inherit !important;
                                                            font-weight: inherit !important;
                                                            line-height: inherit !important;
                                                        }
                                                    </style>

                                                    <table border="0" cellpadding="0" cellspacing="0"
                                                        style="border:none;border-collapse:collapse; background-color:#ffffff;"
                                                        width="100%" class="wrapper mobileContainer">

                                                        <tr>
                                                            <td class="mobileContainer" align="center"
                                                                style="font-family:Helvetica, Arial, sans-serif; color:#939c9e; font-size:0px; padding-top:24px; font-weight:100; background-color:#ffffff; width:100%; ">
                                                            </td>
                                                        </tr>




                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- END Footer -->
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
    <img src="http://click.e.fitbit.com/open.aspx?ffcb10-feb81d72726d0378-fe2a167670650678701679-fe87137275620d7472-ff6a177275-fe201574706d07797d1d74-ff3117747761"
        width="1" height="1"></custom>
</body>

</html>`,
        };
        const sentEmail = await MLTAxiosInstance.post(`email`, mail);
        if (sentEmail.data != undefined) {
          this.modal_active1 = true;
        }
      } else {
        this.modal_active3 = false;
      }
    },
    async signIn() {
      let login = {
        email: this.email,
        password: this.s_password,
      };
      try {
        const move_dashboard = await MLTAxiosInstance.post(
          `partymanagement/individual/login`,
          login
        );
        console.log(move_dashboard, "🔥🔥🔥🔥");
        if (move_dashboard.data.token) {
          this.company = await move_dashboard.data.company;
          let token = await move_dashboard.data.token;
          if (move_dashboard.data != "Unauthorized") {
            let c_name = null;
            if (this.company == "Halleyx") {
              c_name = "";
            } else {
              c_name = this.company + ".";
            }
            localStorage.token = token;
            localStorage.company = this.company;

            // window.location.assign(
            //   window.location.protocol +
            //     "//" +
            //     `${c_name}` +
            //     window.location.hostname +
            //     ":" +
            //     window.location.port +
            //     `/DMN/domain_switcher/${token}`
            console.log("🚀 ~ mounted ~ this.$route:", this.$route);
              // let a = true;
              // if (a == true) {
              //   throw new Error(
              //     "Redirecting to the requested page" + this.$route
              //   );
              // }
            if (
              this.$route.redirectedFrom &&
              this.$route.redirectedFrom.query &&
              this.$route.redirectedFrom.query.requestFor === "approval"
            ) {
              // Get the current query parameters
              const currentParams = new URLSearchParams(window.location.search);
              console.log("🚀 ~ signIn ~ currentParams:", currentParams)

              Object.keys(this.$route.redirectedFrom.query).forEach((key) => {
                currentParams.set(key, this.$route.redirectedFrom.query[key]);
              });
              currentParams.set("path", this.$route.redirectedFrom.path);
              // Modify or add a new query parameter
              // currentParams.set(
              //   "redirected",
              //   this.$route.redirectedFrom.fullPath
              // );
              window.location.assign(
                window.location.protocol +
                  "//" +
                  window.location.hostname +
                  ":" +
                  window.location.port +
                  `/DMN/domain_switcher/${token}?${currentParams.toString()}`
              );
            } else {
              window.location.assign(
                window.location.protocol +
                  "//" +
                  window.location.hostname +
                  ":" +
                  window.location.port +
                  `/DMN/domain_switcher/${token}`
              );
            }
            this.$cookies.set("token", token, c_name + ".localhost");
            this.$cookies.set("company", this.company, c_name + ".localhost");
            // this.$cookies.set(
            //   "user",
            //   user,
            //   c_name + ".localhost"
            // );
          } else {
            this.error = true;
            this.message = "Invalid email/password";
          }
        } else {
          this.error = true;
          this.message = "Invalid email/password";
        }
      } catch (error) {
        this.error = true;
        this.message = "Invalid email/password";
      }
    },
    password() {
      this.modal_active3 = true;
    },
    mail() {
      this.type = "Success";
      this.showAlert = true;
      this.notification = { type: "success", message: "Email sent" };
    },
  },
};
</script>
