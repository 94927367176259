<template>
  <section class="add-tenant">
    <section class="right-layout">
      <section class="title-1">
        <hlx-breadcrumb :items="items" @path="pathTo" />
      </section>
      <section class="header-content">
        <p class="content"><b>Create tenant</b></p>
      </section>
      <div class="main-content">
        <div class="timeline">
          <hlx-timeline
            :timeline-data="example_data"
            :view="'vertical'"
            :type="'numbered'"
          />
        </div>
        <div class="content-1">
          <div class="form-input-wrapper">
            <p class="input-header">First name</p>
            <div class="form-input">
              <hlx-input
                v-model:value="first_name"
                :label-animation="false"
                type="text"
              />
            </div>
          </div>
          <div class="form-input-wrapper">
            <p class="input-header">Last name</p>
            <div class="form-input">
              <hlx-input
                v-model:value="last_name"
                :label-animation="false"
                type="text"
              />
            </div>
          </div>
          <div class="form-input-wrapper">
            <p class="input-header">Email</p>
            <div class="form-input">
              <hlx-input
                v-model:value="email"
                :label-animation="false"
                type="email"
              />
            </div>
          </div>
          <div class="form-input-wrapper">
            <p class="input-header">Organisation</p>
            <div class="form-input">
              <hlx-input
                v-model:value="organisation"
                :label-animation="false"
                type="text"
              />
            </div>
          </div>
          <div class="form-input-wrapper">
            <p class="input-header">Sub domain</p>
            <div class="form-input">
              <hlx-input :label-animation="false" type="text" />
            </div>
          </div>
          <div class="form-input-wrapper">
            <div class="holder">
              <p class="input-header">Two factor authentication</p>
              <span class="sub-header"
                >You will be asked for a verification code when you sign
                in</span
              >
            </div>
            <div class="new-1">
              <div class="form-input-new">
                <p style="font-size: 14px">Text message</p>
                <hlx-input type="switch" checked="false" />
              </div>
              <span class="new-span">
                We'll send a verification code to ***2503 whenever we find a
                login attempt from a device or a browser that we don't
                recognize.
              </span>
            </div>
          </div>
          <div class="form-input-wrapper">
            <div class="holder">
              <p class="input-header" style="font-size: 14px; width: 100%">
                Cookies consent
              </p>
              <span class="sub-header">Enable cookies consent</span>
            </div>
            <div class="new-1">
              <div class="form-input-new">
                <p>Authentication app</p>
                <hlx-input type="switch" checked="false" />
              </div>
              <span class="new-span">
                Use an app like Google Authenticator or Duo Mobile to receive a
                login code
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-container">
        <hlx-button class="primary sm" @click="createTenant()">Next</hlx-button>
      </div>
    </section>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "AddTenant",
  data() {
    return {
      token: this.$route.params.token,
      fet: process.env.VUE_APP_HOST_PROTOCOL + "://" + process.env.VUE_APP_HOST,
      first_name: "",
      last_name: "",
      email: "",
      organisation: "",
      example_data: [
        {
          title: "User description",
          description: "",
          subtitle: "",
          active: true,
          visited: true,
        },
        {
          title: "Choose plan",
          description: "",
          subtitle: "",
          active: false,
          visited: false,
        },
        {
          title: "Payment plan",
          description: "",
          subtitle: "",
          active: false,
          visited: false,
        },
      ],
      items: [
        { label: "Settings", link: "/" },
        { label: "Create tenants", link: "/tenants" },
      ],
    };
  },
  methods: {
    async pathTo() {
      // if (val.label == "Settings") {
        this.$router.push(`/settings`);
      // }
    },

    async createTenant() {
      let name = this.first_name + " " + this.last_name;
      const user = {
        name: name,
        email: this.email,
        password: "",
        companyName: this.organisation,
      };
      // const signup =
      await axios.post(this.fet + `/partyManagement/individual/signup`, user);

      this.$router.push(`/settings/tenant`);
      // this.token = await signup.data.token;
      // this.$router.push('/planTenant')
      // this.$router.push(`/planView`);
    },
  },
};
</script>

<style></style>
