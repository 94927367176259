<template>
          <!--lead filter drawer-->
          <hlx-drawer
            :show="show_filter"
            :width="300"
            position="right"
            :header="false"
            :footer="false"
            :show-close-icon="true"
            @close="show_filter = false"
          >
            <template #body>
              <div style="display: flex">
                <FilterComponent
                  :filterpanelname="'saleslead'"
                  :company-name="companyName"
                  :query-value="query"
                  :display-data="filter"
                  :module="'saleslead'"
                  @filtered-data="supplierfilterData"
                ></FilterComponent>
              </div>
            </template>
          </hlx-drawer>

    <section class="lead-contact-modal">
        <div class="add-contact-drawer">
    <hlx-drawer
          :key="updateModalWindow"
      :show="isOpenSiteDrawer"
      position="right"
      width="400"
      :footer="true"
      :title="'Add contact'"
      :show-close-icon="true"
      @close="closeSiteModal"
    >
      <template #body>
        <div v-if="isOpenSiteDrawer && isAddSiteDrawer" class="header-area-contact-drawer">
          <b>
            <!-- Add site -->
            {{ loadStaticData('LANG-0063') }}
          </b>
        </div>
        <div v-if="isOpenSiteDrawer && isEditSiteDrawer" class="header-area-contact-drawer">
          <b>
            <!-- Edit site -->
            {{ loadStaticData('LANG-0064') }}
          </b>
        </div>
      <div class="parent-container-for-lead-contact">
        <fieldRenderer
              :key="updateFieldRenderKey"
              :schema-payload = "customFieldLeadContactSchema"
              :data-payload = "schemaProp"
              :is-add-clicked = "isAddButtonClicked"
              :is-save-clicked = "isSaveButtonClicked"
              :layout="'1-column'"
              :status-list="[
                'Open',
                'In Discussion',
                'Qualified',
                'Unqualified',
                'Converted',
                'Closed Lost',
              ]"
              :parent-class-name="'parent-container-for-lead-contact'"
              @edited-payload="patchEditedLead"
              @lookup-data="getExistedLeadData"
            ></fieldRenderer>
      </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeSiteModal"
            >
            <!-- Cancel -->
             {{ loadStaticData('LANG-0020') }}
            </hlx-button
          >
          <hlx-button
           v-if="isAddSiteDrawer && isOpenSiteDrawer"
            class="primary sm"
            style="margin-left: 10px"
            @click="addNewSite"
            >
            <!-- Add -->
             {{ loadStaticData('LANG-0010') }}
            </hlx-button
          >
          <hlx-button
           v-if="isEditSiteDrawer && isOpenSiteDrawer"
            class="primary sm"
            style="margin-left: 10px"
            @click="saveSite"
            >
            <!-- Save -->
            {{ loadStaticData('LANG-0030') }}
            </hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>
    </section>

    <!-- lead contact top -->
  <section class="lead-top">
    <span class="lead-sub-header">
      <!-- Sites -->
      {{ loadStaticData('LANG-0062') }}
    </span>
    <section class="lead-search-top">

              <span class="search-container">
                <hlx-search
                :label-value="loadStaticData('LANG-0003')"
                 @search-key="searchKey"></hlx-search>
              </span>
              <!-- <span class="filter-table">
                    <hlx-button
                      class="secondary sm add-btn"
                      @click="showDrawerRight"
                    >
                      <i class="icon-filter-regular" @click="openSiteFilter"></i>
                       <span v-if="filtertaglength != 0" class="filter-count">{{
                        filtertaglength
                      }}</span> 
                    </hlx-button>
                  </span> -->
              <hlx-button
                class="primary sm"
                @click="openAddSiteDrawer"
              >
                <i class="icon-plus-circle-regular"></i> 
                <!-- Add site -->
                {{ loadStaticData('LANG-0063') }}
              </hlx-button>
            </section>
  </section>

      <!-- Table  -->
      <section v-if="tableData.length > 0" class="lead-table" style="margin-bottom: 4rem">
        <hlx-table
            :label-value="tableModalLang"
            :column-count="siteTableHeaders.length + 2"
            :border="['table', 'header', 'horizontal']"
            :row-hover="true"
            theme="grey"
            :re-arrange-columns="toggleRearrangeModal"
            :re-arrange-heads="leadTableheadersCopy"
            @close-rearrange="toggleRearrangeModal = false"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
                <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
             @new-resize-width="resizedWidth"
            >
            <!-- {{ "S.no" }} -->
              {{ loadStaticData('LANG-0046') }}
          </hlx-table-head>
            
              <hlx-table-head
                v-for="(i, index) in siteTableHeaders"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
                 @new-resize-width="resizedWidth"
              >
              {{ i.label == 'Date' ? firstOccupancyOrOnboardingDate : i.label }}
                </hlx-table-head
              >
              <hlx-table-head
                :align="'center'"
                :width="70"
                :fixed="'right'"
                 @new-resize-width="resizedWidth"
                ><span class="action-icon">
                  <i
                  style="cursor: pointer"
                  class="icon-settings-regular"
                @click="reAssignOrgTableHeader()"
                  ></i> </span
              ></hlx-table-head>
            </template>

            <!-- table with data -->
            <template v-if="tableData?.length>0" #tbody>
              
            <tr v-for="(i, index) in paginatedData " id="" :key="index">
                <hlx-table-cell :align="'center'" :fixed="'left'">
                    {{   serialNumber(index)}}
                </hlx-table-cell>
                <hlx-table-cell
                  v-for="(j, col_index) in siteTableHeaders"
                  :key="col_index"
                  :align="j.align"
                  :fixed="j.fixed"
                >
                  <div
                    v-if="j.prop.toLowerCase() === 'name'"
                    @click="editSiteDrawer(index)"
                  >
                  <!-- @click="openEditLeadContactDrawer" -->
                    <span  class="link-name">
                      {{ i?.name || i?.siteName}}
                    </span>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'type'">
                    <span>
                      {{ i["type"] }}
                    </span>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'contactmedium'">
                    <span>
                        {{ i['contactMedium']?.[0]?.characteristic?.street || i['siteAddress']?.[0]?.characteristic?.street }}
                    </span>
                    <!-- <section style="color:#676767;font-size:10px;display:flex;margin-top:6px;gap:12px;margin-bottom:6px;">
                        <span style="display:flex">
                           <span style="display: flex">
                            {{ 'Latitude :' }}
                           </span>
                           <span>
                               {{  i['contactMedium']?.[0]?.characteristic?.latitude || i['siteAddress']?.[0]?.characteristic?.latitude || "-" }}
                           </span>
                    </span>
                    <span style="display:flex">
                           <span style="display: flex">
                            {{ 'Longitude :' }}
                           </span>
                           <span>
                               {{  i['contactMedium']?.[0]?.characteristic?.longitude || i['siteAddress']?.[0]?.characteristic?.longitude || "-" }}
                           </span>
                    </span>
                    </section> -->
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'sfdcid'">
                    <span>
                        {{ i["sfdcId"]}}
                      </span>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'date'">
                    <span>
                      <!-- {{ i["firstOccupancyDate"] ? 
                      new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "2-digit",
  year: "numeric",
}).format(new Date(i["firstOccupancyDate"]))
 : new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "2-digit",
  year: "numeric",
}).format(new Date(i["onboardingDate"]))}} -->
{{
    i["firstOccupancyDate"] && !isNaN(new Date(i["firstOccupancyDate"]).getTime())
      ? new Intl.DateTimeFormat("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        }).format(new Date(i["firstOccupancyDate"]))
      : i["onboardingDate"] && !isNaN(new Date(i["onboardingDate"]).getTime())
      ? new Intl.DateTimeFormat("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        }).format(new Date(i["onboardingDate"]))
      : "-"
  }}
                    </span>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'numberofunits'">
                      <span>
                        {{ i["numberOfUnits"]}}
                      </span>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'createdat'">
                      <span>
                        {{ dateAndTimeFormatter(i["createdAt"])}}
                      </span>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'lastupdated'">
                      <span>
                        {{ dateAndTimeFormatter(i["lastUpdate"])}}
                      </span>
                  </div>
                </hlx-table-cell>
                <hlx-table-cell
                  :align="'center'"
                  :width="120"
                  :fixed="'right'"
                >
                  <span :id="'table-context' +   serialNumber(index -1)" class="action-icon">
                    <i
                      style="position: relative"
                      class="icon-more-vertical-filled"
                      :class="i.context === true ? 'active-action' : ''"
                      @click="crudContextMenu($event,   serialNumber(index - 1))"
                    >
                      <hlx-context-menu
                        :display="'display'"
                        :top="containertop"
                        :left="containerleft - 20"
                        :conditionvalue="150"
                        :conditiondown="2"
                        :conditionup="-38"
                        :options="computedTableAction(i)"
                        :data="index.toString()"
                        :show="i.context == true"
                        @chosen="closeCrudContextMenu($event, i,   serialNumber(index-1))"
                      />
                    </i>
                  </span>
                </hlx-table-cell>
              </tr>
            </template>
          </hlx-table>
      </section>

<!-- Table for no data -->
<section v-if="( tableData.length <= 0 && searchvalue !== '')" class="lead-table">
        <hlx-table
            :label-value="tableModalLang"
            :column-count="siteTableHeaders.length + 2"
            :border="['table', 'header']"
            :row-hover="true"
            theme="grey"
            :re-arrange-columns="toggleRearrangeModal"
            :re-arrange-heads="leadTableheadersCopy"
            @close-rearrange="toggleRearrangeModal = false"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
                <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
             @new-resize-width="resizedWidth"
            >
            <!-- {{ "S.no" }} -->
              {{ loadStaticData('LANG-0046') }}
          </hlx-table-head>
            
              <hlx-table-head
                v-for="(i, index) in siteTableHeaders"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
                 @new-resize-width="resizedWidth"
              >
                {{ i.label }}</hlx-table-head
              >
              <hlx-table-head
                :align="'center'"
                :width="70"
                :fixed="'right'"
                 @new-resize-width="resizedWidth"
                ><span class="action-icon">
                  <i
                  style="cursor: pointer"
                  class="icon-settings-regular"
                @click="reAssignOrgTableHeader()"
                  ></i> </span
              ></hlx-table-head>
            </template>

                        
            <!-- table without data )-->
            <template  #tbody>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell :colspan="siteTableHeaders.length + 2">
                        <!-- {{
                        "No data"
                      }} -->
                        {{ loadStaticData('LANG-0050') }}
                      </hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
        </template>

          </hlx-table>
      </section>


      <!-- Pagination -->
      <div
         v-if="tableData.length > 0"
               class="lead-contact-pagination-container"
        :style="{ padding: '1rem 0',width:'calc(100% - 220px)'}"
      >
        <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
          :label-value=paginationLang
          :total="tableData.length"
          enable-rows-per-page
          :rows-per-page-list="[10, 15, 20]"
          :options="{
            attributes: {
              rowsPerPageTitle: true,
              rowsPerPageResult: true,
              rowsPerPageDropdown: {
                position: {
                  top: 0,
                  right: 1,
                },
              },
            },
          }"
          @current-page="currentPage"
          @updated:rows-per-page="changeRowsPerPage"
        ></hlx-pagination>
      </div>
    
    <!-- table data image - illustration -->
    <section
      v-if="tableData.length <= 0 && searchvalue == ''"
      style="
        margin-top: 3.2rem;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        align-items: center;
      "
    >
      <img
        src="@/assets/images/leadSiteNoData.svg"
        alt=""
        style="width: 400px"
      />

      <span>
          <!-- Add button -->
          <hlx-button class="primary sm" @click="openAddSiteDrawer">
            <i class="icon-plus-circle-regular"></i> 
            <!-- Add site -->
             {{ loadStaticData('LANG-0063') }}
          </hlx-button>
        </span>
    </section>


</template>

<script>
// import addressComponent from './leadAddressComponent.vue'
import fieldRenderer from "@/components/FieldRenderer.vue";
import {
  // DMNAxiosInstance,
  UPCAxiosInstance,
  MLTAxiosInstance,
} from "@/config/axiosConfig";
import FilterComponent from "@/components/filterPanelComponent.vue";
export default {
  components: {
    fieldRenderer,
    FilterComponent
    // addressComponent
  },
  props:{
    schemaForDrawer:{
      type:Object,
      default: ()=>{
        return {}
      }
    },
    siteData:{
      type:Array,
      default: ()=>{
        return []
      }
    },
    schemaForSiteModal:{
      type:Object,
      default: ()=>{
        return {}
      }
    },
    dataForSiteModal:{
      type:Object,
      default: ()=>{
        return {}
      }
    },
    isSaveClicked: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    payload:{
      type:Object,
      default: ()=>{
        return {}
      }
    }
  },
emits:["updated-payload","add-payload","delete-site"],
data(){
  // vue add data
    return{
      firstOccupancyOrOnboardingDate:'',
      customFieldLeadContactSchemaForDrawer:{},
      company:null,
      temporaryLeadSitePayload:null,
      show_filter:false,
      currPage: 1,
      rowsPerPage: 10,
      updateModalWindow:0,
      isAddSiteDrawer:false,
      isEditSiteDrawer:false,
      leadWholePayload:null,
      updateFieldRenderKey:1,
      customFieldLeadContactSchema:{},
      schemaProp:{},
      isSaveButtonClicked:false,
      dupSiteDetailsEmpty:{
      siteName:"",
          networkId:"",
          sfdcId:"",
          tags:[],
          networkManager:'',
          createdAt:'',
          lastUpdate:'',
          numberOfUnits:"",
          firstOccupancyDate:"",
          onboardingDate:'',
          marketSegment:{},
          siteAddress:[
          {
            mediumType: "postalAddress",
            characteristic: {
              "@type": "",
          street: "",
          city: "",
          country: "",
          default: false,
          description: "",
          postalCode: "",
          state: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          unit: "",
              latitude:"",
              longitude:"",
            },
          },
          ]
        },
      siteDetailsEmpty:{
      siteName:"",
          networkId:"",
          sfdcId:"",
          networkManager:'',
          tags:[],
          createdAt:'',
          lastUpdate:'',
          numberOfUnits:"",
          firstOccupancyDate:"",
          onboardingDate:'',
          marketSegment:{},
          siteAddress:[
          {
            mediumType: "postalAddress",
            characteristic: {
              "@type": "",
          street: "",
          city: "",
          country: "",
          default: false,
          description: "",
          postalCode: "",
          state: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          unit: "",
              latitude:"",
              longitude:"",
            },
          },
          ]
        },
      siteDetails:{
        siteName:"",
          networkId:"",
          networkManager:'',
          sfdcId:"",
          createdAt:'',
          lastUpdate:'',
          numberOfUnits:"",
          tags:[],
          onboardingDate:'',
          firstOccupancyDate:"",
          marketSegment:{},
          contactMedium:[
          {
            mediumType: "postalAddress",
            characteristic: {
              "@type": "",
          street: "",
          city: "",
          country: "",
          default: false,
          description: "",
          postalCode: "",
          state: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          unit: "",
              latitude:"",
              longitude:"",
            },
          },
          ]
        },
      siteTableHeaders:[
        {
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: 160,
          name:"Site name",
          label: this.loadStaticData('LANG-0065')|| "Site name",
          prop: "name",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 280,
          name:"Site addresss",
          label: this.loadStaticData("LANG-0066") || "Site addresss",
          prop: "contactMedium",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 160,
          name:"Number of units",
          label:  this.loadStaticData("LANG-0067") ||  "Number of units",
          prop: "numberOfUnits",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 160,
          name:"SFDC site id",
          label:  this.loadStaticData("LANG-0068") || "SFDC site id",
          prop: "sfdcid",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 180,
          name:"Date",
          label: "Date",
          prop: "date",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 180,
          name:"Created at",
          label: this.loadStaticData('LANG-0044') || "Created at",
          prop: "createdat",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 180,
          name:"Last updated",
          label: this.loadStaticData('LANG-0045') || "Last updated",
          prop: "lastUpdated",
          align: "left",
        },
      ],
      tableData:[],
      siteOrgData:[],
      searchvalue:"",
        isOpenSiteDrawer:false,
    }
},
  computed:{
    tableModalLang() { 
      return {
          re_arrange_columns : this.loadStaticData('LANG-0026'),
          Cancel:this.loadStaticData('LANG-0020'),
          Apply: this.loadStaticData('LANG-0027'),
          SelectAll: this.loadStaticData('LANG-0028')
        }
    },
    paginationLang() {     
      return {
          Rows_per_page : this.loadStaticData("LANG-0012"),
          Showing: this.loadStaticData("LANG-0013"),
          of: this.loadStaticData("LANG-0014"),
          results: this.loadStaticData("LANG-0015"),
          Go_to: this.loadStaticData("LANG-0016")
        }
      
    },
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
},
  watch:{
    schemaForDrawer:{
      handler(val){
        if(val){
          this.customFieldLeadContactSchemaForDrawer =  JSON.parse(JSON.stringify(val))
          console.log("this.customFieldLeadContactSchemaForDrawer",this.customFieldLeadContactSchemaForDrawer)
        }
      },
      immediate:true,
      deep:true
    },
    siteData:{
      handler(val){
        if(val){
          this.siteOrgData = JSON.parse(JSON.stringify(val))
          this.tableData =  JSON.parse(JSON.stringify(val))
          console.log("this.tableData",this.tableData)
      const firstRow = this.tableData[0];
    if (firstRow?.firstOccupancyDate) {
      this.firstOccupancyOrOnboardingDate = "First occupancy date";
    } else {
      this.firstOccupancyOrOnboardingDate = "Onboarding date";
    }
    }
      },
      immediate:true,
      deep:true
    },
    payload:{
      handler(val){
        if(val){
          console.log("this.payload",val)
          this.leadWholePayload = JSON.parse(JSON.stringify(val))
        }
      },
      immediate:true,
      deep:true
    },
    schemaForSiteModal:{
      handler(val){
        if(val){
          this.customFieldLeadContactSchema =  JSON.parse(JSON.stringify(val))
          console.log("this.customFieldLeadContactSchema",this.customFieldLeadContactSchema)
        }
      },
      immediate:true,
      deep:true
    },
    dataForSiteModal:{
      handler(val){
        if(val){
          this.schemaProp =  JSON.parse(JSON.stringify(this.siteDetailsEmpty))
          console.log("this.schemaProp",this.schemaProp,val)
        }
      },
      immediate:true,
      deep:true
    },
  },
async mounted(){
  const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
  this.company = val.data.company;
  // this.$nextTick(() => {
  // this.loadGoogleMapsScript().then(() => {
  //      // Initialize Google Maps components here
  //      const autocompleteService = new window.google.maps.places.AutocompleteService();
  //      console.log('AutocompleteService Loaded from autoaddress:', autocompleteService);
  //      this.isLeadAddressMounted = true
  //    });
  //   });
},
methods:{
  async getExistedLeadData( [ dataPayload , schemaToBeMapped , schema]){

if(dataPayload && schemaToBeMapped){
  console.log(schema,dataPayload , "getExistedLeadData" , schemaToBeMapped)

  for(let i=0;i<schemaToBeMapped.length;i++){
let schemaObjName = schemaToBeMapped[i].LHS 
let schemaValue =  schemaToBeMapped[i].RHS
let changeRhsValue = 'payload'
const firstDotIndex = schemaValue.indexOf('.'); 
console.log(schemaValue,"dataPayloaddataPayload",dataPayload)
if (firstDotIndex !== -1) {
let newRHS = changeRhsValue + schemaValue.slice(firstDotIndex);
console.log(schemaObjName, "newRHS", newRHS);
newRHS
let result =  this.evaluateRHS(newRHS,dataPayload)
console.log("remappped data",result)
// this.customFieldLeadSchema['name'].value = dataPayload.name

if(result)
{
  schema[schemaObjName].value = result
}
if(result == '' && schemaObjName == 'numberOfUnits')
{
  console.log(this.customFieldLeadContactSchema,"this.customFieldLeadContactSchema[schemaObjName].value",this.customFieldLeadContactSchema[schemaObjName])
  // schema[schemaObjName].value = ''
}
}
// let result = eval(newRHS);
// Log the result (assuming companyName is defined in the scope)
// console.log(result);
console.log(schemaObjName,"schemaValue",schema)
}

// console.log(this.customFieldLeadSchema,"schemaschema",schema)
// this.customFieldLeadSchema = { ...this.customFieldLeadSchema, ...schema };
// let removeDuplicates = {}
// Object.keys(this.customFieldLeadSchema).forEach(key => {
// removeDuplicates[key] = this.customFieldLeadSchema[key];
// });
// this.customFieldLeadSchema = JSON.parse(JSON.stringify(removeDuplicates))
// this. filteredCustomFieldSchema()

}
this.schemaProp = JSON.parse(JSON.stringify(this.temporaryLeadSitePayload))
this.customFieldLeadContactSchema = JSON.parse(JSON.stringify(schema))
console.log(this.temporaryLeadSitePayload,"this.customFieldLeadContactSchema",this.customFieldLeadContactSchema)
//   console.log("getExistedLeadData",val)
//   const duplicatedExitingLeadData = {...val}
//   let leadSourceAndOwnerDetails = duplicatedExitingLeadData.relatedParty.filter(party => party["@type"] === "leadOwner" || party["@type"] === "leadSource")

//   let leadContactAndSiteDetails = duplicatedExitingLeadData.relatedParty.filter(party => party["@type"] != "leadOwner" && party["@type"] !== "leadSource" && party["@type"] !== "site")
//   duplicatedExitingLeadData.relatedParty = leadSourceAndOwnerDetails
// this.leadPayloadEmpty = {
// name: duplicatedExitingLeadData?.name || "",
// type:duplicatedExitingLeadData?.type || "",
//   description:duplicatedExitingLeadData?.description || "",
//   dwellingtype:duplicatedExitingLeadData?.dwellingtype || "",
//   externalReference: duplicatedExitingLeadData?.externalReference || [
//     {
//       externalIdentifierType:'websiteUrl',
//        value:""
//     },
//     {
//       externalIdentifierType:'numberOfSites',
//        value:1
//     }
//   ],
//   category:duplicatedExitingLeadData?.category || {},
//   status: duplicatedExitingLeadData?.status || "Open",
//   relatedParty:duplicatedExitingLeadData?.relatedParty || [
//     {
//       '@type':'leadOwner',
//        value:""
//     },
//     {
//       '@type':'leadSource',
//       value:""
//     }
//   ],
//  },
//   this.exitedLeadContactDetails = (leadContactAndSiteDetails.filter(contact => contact['@type'] == 'individual'))[0] || 
//  console.log("exitedLeadContactDetails",this.exitedLeadContactDetails)
//  this.filteredCustomFieldSchema()
//  this.leadPayloadEmpty
//   console.log(duplicatedExitingLeadData,"duplicatedExitingLeadData",leadContactAndSiteDetails,"leadSourceAndOwnerDetails",leadSourceAndOwnerDetails,"this.leadPayloadEmpty",this.leadPayloadEmpty)

},

evaluateRHS(RHS, dataPayload) {
this.dataPayloadForRemap = dataPayload
try {
const cleanedRHS = RHS.replace(/payload/g, 'dataPayloadForRemap').replace(/\n/g, ' ').trim();
console.log(this.dataPayloadForRemap,cleanedRHS,"dataPayloadForRemap",dataPayload)
// Use Function constructor to evaluate the expression safely
// eslint-disable-next-line no-new-func
const result = new Function('dataPayloadForRemap', `return ${cleanedRHS};`)(this.dataPayloadForRemap);
return result;
} catch (error) {
console.error('Error evaluating RHS:', error);
return null;
}
},
  async saveSite(){   
    this.temporaryLeadSitePayload.context = false
    // this.isSaveButtonClicked = true
    this.temporaryLeadSitePayload.lastUpdate = new Date().toJSON()
    if (this.temporaryLeadSitePayload.siteName) {
      this.temporaryLeadSitePayload = {
        ...this.temporaryLeadSitePayload,
        name: this.temporaryLeadSitePayload.siteName
    };
    delete this.temporaryLeadSitePayload.siteName;
}
if(this.temporaryLeadSitePayload.firstOccupancyDate)
{
  console.log("firstOccupancyDatefirstOccupancyDate",this.temporaryLeadSitePayload.firstOccupancyDate = new Date(this.temporaryLeadSitePayload.firstOccupancyDate))
  this.temporaryLeadSitePayload.firstOccupancyDate = new Date(this.temporaryLeadSitePayload.firstOccupancyDate)
}
if(this.temporaryLeadSitePayload.onboardingDate)
{
  this.temporaryLeadSitePayload.onboardingDate = new Date(this.temporaryLeadSitePayload.onboardingDate)
}
if (this.temporaryLeadSitePayload.siteAddress) {
  this.temporaryLeadSitePayload = {
        ...this.temporaryLeadSitePayload,
        contactMedium: this.temporaryLeadSitePayload.siteAddress
    };
    delete this.temporaryLeadSitePayload.siteAddress;
}
const matchingObject = this.leadWholePayload.relatedParty.find(
  (party) => party.id === this.temporaryLeadSitePayload._id
);
if (matchingObject) {
  matchingObject.name = this.temporaryLeadSitePayload.name || this.temporaryLeadSitePayload.siteName;
  console.log(this.leadWholePayload.relatedParty,"Updated object:", matchingObject);
} else {
  console.log("No matching object found for id:", this.temporaryLeadSitePayload.id);
}
    this.$emit("updated-payload",this.leadWholePayload,this.temporaryLeadSitePayload)
    this.isAddSiteDrawer = false
    this.isOpenSiteDrawer=false
    this.isEditSiteDrawer = false
  },
  async addNewSite(){
    this.temporaryLeadSitePayload.createdAt = new Date().toJSON()
    this.temporaryLeadSitePayload.lastUpdate = new Date().toJSON()
    if (this.temporaryLeadSitePayload.siteName) {
      this.temporaryLeadSitePayload = {
        ...this.temporaryLeadSitePayload,
        name: this.temporaryLeadSitePayload.siteName
    };
    delete this.temporaryLeadSitePayload.siteName;
}
if (this.temporaryLeadSitePayload.siteAddress) {
  this.temporaryLeadSitePayload = {
        ...this.temporaryLeadSitePayload,
        contactMedium: this.temporaryLeadSitePayload.siteAddress
    };
    delete this.temporaryLeadSitePayload.siteAddress;
}
    this.temporaryLeadSitePayload.context = false
    const updatedVal = { ...this.temporaryLeadSitePayload, '@type': 'individual' };
    console.log("Updated lead:", updatedVal);
    this.$emit("add-payload",this.leadWholePayload,updatedVal)
    // this.isSaveButtonClicked = true;
    this.isAddSiteDrawer = false
    this.isOpenSiteDrawer=false
    this.isEditSiteDrawer = false
  },
  async supplierfilterData(filterSelectedvalue, filterQuery) {
      this.filtertagvalue = filterSelectedvalue;
      this.filtertaglength = filterSelectedvalue.length;
      filterQuery.company = this.company;
      //  filterQuery['filterQuery']['"@baseType"'] = 'supplier'
      if (this.filtertagvalue.length != 0) {
        this.filterQuery = filterQuery;
        this.filterQuery.type = "filter";
        if (this.searchvalue != "") {
          this.filterQuery["searchQuery"] = this.searchvalue;
          this.filterQuery["type"] = "filter-search";
        }
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          this.filterQuery
        );

        this.tableData = filterResultData.data.data;
        this.totalPageNo = filterResultData.data.total;
      } else {
        this.currPage = 1;
        this.rowsPerPage = 10;
        this.supplierdataCalling(this.currPage, this.rowsPerPage);
      }
    },
    async supplierdataCalling(currentPage, rowsPerPage) {
      this.filterQuery["company"] = this.company;
      this.filterQuery["paginatedQuery"]["currentPage"] = currentPage;
      this.filterQuery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
      const filterResultData = await MLTAxiosInstance.post(
        `util/filterData`,
        this.filterQuery
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        this.totalPageNo = filterResultData.data.total;
        this.paginatedData;
      }
    },
      openSiteFilter(){
        // to show filter drawer
        this.show_filter = true
      },
  loadGoogleMapsScript() {
    // const mapKey = process.env.VUE_APP_MAP_KEY;
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAP_KEY}&libraries=places`;
    script.async = true;
    document.head.appendChild(script);
  
    return new Promise((resolve) => {
      script.onload = () => {
        resolve();
      };
    });
  },
  serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
  changeRowsPerPage(count) {
     this.rowsPerPage = count;
 },
 searchKey(val) {
      this.searchvalue = val;
      if (val !== "") {
        let searchData = this.searchNestedObjects(
          this.siteOrgData,
          val.toLowerCase()
        );
        this.tableData = JSON.parse(JSON.stringify(searchData));
      } else {
        this.tableData = JSON.parse(JSON.stringify(this.siteOrgData));
      }
    },
    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },
  currentPage(val) {
     this.currPage = val;
 },
  closeSiteModal(){
    this.schemaProp = JSON.parse(JSON.stringify(this.dupSiteDetailsEmpty))
    this.customFieldLeadContactSchema = JSON.parse(JSON.stringify(this.schemaForSiteModal))
    this.updateFieldRenderKey += 1
    this.updateModalWindow += 1
    this.isOpenSiteDrawer = false
  },
  openEditLeadContactDrawer(){
    this.isAddSiteDrawer =false
    this.isEditSiteDrawer = true
    this.isOpenSiteDrawer=true
  },
  patchEditedLead(val){
//     if (val.siteName) {
//     val = {
//         ...val,
//         name: val.siteName
//     };
//     delete val.siteName;
// }
// if (val.siteAddress) {
//     val = {
//         ...val,
//         contactMedium: val.siteAddress
//     };
//     delete val.siteAddress;
// }
this.temporaryLeadSitePayload = JSON.parse(JSON.stringify(val))
    
//     console.log(this.isAddSiteDrawer,"patchEditedLead", val,"this.isEditSiteDrawer",this.isEditSiteDrawer);
//       this.isSaveButtonClicked = false;
//       if(this.isAddSiteDrawer){
//       const updatedVal = { ...val, '@type': 'individual' };
//     console.log("Updated lead:", updatedVal);
//     // if( this.leadWholePayload && updatedVal){
//     // this.leadWholePayload.relatedParty.push(updatedVal)
//     // console.log("leadWholePayload",this.leadWholePayload)
//     this.$emit("add-payload",this.leadWholePayload,updatedVal)
//   }
//   if(this.isEditSiteDrawer){
//     const matchingObject = this.leadWholePayload.relatedParty.find(
//   (party) => party.id === val._id
// );
// if (matchingObject) {
//   matchingObject.name = val.name || val.siteName;
//   console.log(this.leadWholePayload.relatedParty,"Updated object:", matchingObject);
// } else {
//   console.log("No matching object found for id:", val.id);
// }
//     this.$emit("updated-payload",this.leadWholePayload,val)
//     }
//       // this.isOpenSiteDrawer: = false
//       this.isAddSiteDrawer = false
//     this.isOpenSiteDrawer=false
//     this.isEditSiteDrawer = false
  },
    openAddSiteDrawer(){
      this.updateFieldRenderKey += 1
      this.isEditSiteDrawer = false
        this.isOpenSiteDrawer = true
        this.isAddSiteDrawer = true
        this.customFieldLeadContactSchema = JSON.parse(JSON.stringify(this.customFieldLeadContactSchemaForDrawer)) 
        this.schemaProp = JSON.parse(JSON.stringify(this.siteDetailsEmpty))
   },
    editSiteDrawer(index){
      console.log("this.tableData[index]",this.tableData[index])
    this.siteDetails =  this.tableData[index]
    // if (this.tableData[index]) {
  let editSchema = { ...this.tableData[index] };

  if (!this.tableData[index].siteAddress) {
    editSchema.siteAddress = this.tableData[index].contactMedium;
    delete editSchema.contactMedium;
  }

  if (!this.tableData[index].siteName) {
    editSchema.siteName = this.tableData[index].name;
    delete editSchema.name;
  }

  this.schemaProp = JSON.parse(JSON.stringify(editSchema));
// }
// else{
//   this.schemaProp = JSON.parse(JSON.stringify( this.tableData[index]))
// }
this.customFieldLeadContactSchema = JSON.parse(JSON.stringify(this.schemaForSiteModal))
    console.log(this.schemaProp,"this.tableData[index]", this.customFieldLeadContactSchema)
    this.isAddSiteDrawer =false
    this.isEditSiteDrawer = true
    this.isOpenSiteDrawer=true
    },
    async closeCrudContextMenu($event, ele, i) {
  
  if ($event.toLowerCase() === "edit") {
    this.editSiteDrawer(i)
    // this.openEditLeadContactDrawer
  } 
  else if ($event.toLowerCase() == "delete") {
        console.log("Delete Edit emit ", $event, "event", "ele", ele, "i", i);
        this.$emit("delete-site",ele)   
      }
},
    computedTableAction(data) {
      console.log(data)
      return [
        {
          display:this.loadStaticData('LANG-0009'),
          label: "Edit",
          icon: "icon-file-text-edit-regular",
        },
        {
          display:this.loadStaticData('LANG-0011'),
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ];
    },
    crudContextMenu($event, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 68;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
}
}
</script>

<style>

</style>