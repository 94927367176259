<template>
    <section class="layout-seperation">
        <section class="layout-left-panel">
            <div class="left-panel-container" @click="backToSettings">
              <i class="icon-arrow-left-regular"></i>
              <p>Back</p>
            </div>
        </section>
        <section class="layout-right-panel"> 
            <section class="right-panel-container">
                <section class="header">
                   <section class="header-left-section">
                       <p class="title">Tickets</p>
                       <p class="sub-title">Customize your tickets settings</p>
                   </section>
                </section>
                <section class="content">
                    <div class="card-holder">
        <div class="cards-container">
          <hlx-card
            v-for="(card, index) in card_data"
            :key="index"
            class="custom-card"
            :seperator="false"
            :card-title="false"
            :card-footer="false"
            :hover="true"
            height="210"
            :class="{
              '--disable-hover':
                card.card_name && card.card_name.toLowerCase() == 'api',
            }"
            @click="moveTo(card.route)"
          >
            <template #card-body>
              <div
                style="padding: 0 15px"
                :class="{
                  '--disable-hover':
                    card.card_name && card.card_name.toLowerCase() == 'api',
                }"
              >
                <img :src="getImgUrl(card.card_body)" :alt="card.card_body" />
                <p class="card-head">
                  <b>{{ card.card_name }}</b>
                </p> 
                <p
                  class="card-desc copy-click"
                  data-tooltip-text="Click to copy"
                  data-tooltip-text-copied="✔ Copied to clipboard"
                >
                  {{ card.card_desc }}
                </p>
              </div>
            </template>
            <template #card-footer>
              {{ card.card_footer }}
            </template>
          </hlx-card>
        </div>
      </div>     
                </section>
            </section>
        </section>
    </section>
</template>

<script>
export default{
   data(){
    return{
    card_data:[
        {
          card_body: "users.svg",
          route: "fields",
          card_name: "Ticket field",
          card_desc: "Add ticket fields",
        },
        {
          card_body: "general.svg",
          route: "template",
          card_name: "Ticket template",
          card_desc: "Configure the tickets",
        },
        ]
      }
   },
   methods:{
    backToSettings(){
        this.$router.back();
    },    
    getImgUrl(pet) {
      //
      // var images = require.context("../assets/", false, /\.svg$/);
      //
      return require("../../assets/images/" + pet);
    },
    moveTo(route) {
            this.$router.push(`/settings/ticket/${route}`);
    },
   }
}
</script>