/* eslint-disable no-use-before-define */
<template>
  <div class="payment-account">
    <section class="right-container">
      <p class="container-header"><b>Payment plan</b></p>

      <div class="body-content">
        <section class="timeline">
          <hlx-timeline
            :timeline-data="example_data"
            :view="'vertical'"
            :type="'numbered'"
          />
        </section>
        <section class="form-container">
          <form
            id="payment-form"
            ref="payment-form"
            class="stripe-payment-container"
          >
            <main class="container">
              <section class="payment-detail-area">
                <P class="payment-header"
                  >Payment method
                  <i class="icon-info-ot">
                    <p>
                      By providing your card information, you allow vp to charge
                      your card for future payments in accordance with their
                      terms.
                    </p>
                  </i>
                </P>
                <div id="payment-element">
                  <!--Stripe.js injects the Payment Element-->
                </div>
              </section>
              <section class="billing-detail-area">
                <P class="billing-header">Billing address</P>
                <div class="form-2">
                  <!-- Street number and name -->
                  <div class="form-input-wrapper">
                    <!-- <div class="form-input">
                      <p class="input-header">Street number</p>
                      <hlx-input label_value="18" type="text" />
                    </div> -->

                    <div class="form-input">
                      <p class="input-header">Street name</p>
                      <hlx-input
                        v-model:value="billing_address.street"
                        label-value="street name"
                        type="text"
                      />
                    </div>
                  </div>

                  <!-- Unit / Floor -->
                  <div class="form-input-wrapper split">
                    <div class="form-input--30">
                      <p class="input-header">Unit / Floor</p>
                      <hlx-input
                        v-model:value="billing_address.unit"
                        type="text"
                        label-value="unit or floor"
                      />
                    </div>
                    <!-- City -->
                    <!-- <div class="form-input-wrapper"> -->
                    <div class="form-input--70">
                      <p class="input-header">City</p>
                      <hlx-input
                        v-model:value="billing_address.city"
                        type="text"
                        label-value="City"
                      />
                    </div>
                    <!-- </div> -->
                  </div>

                  <!--State/Province and postal code  -->
                  <div class="form-input-wrapper split">
                    <div class="form-input--70">
                      <p class="input-header">State / Province</p>
                      <hlx-input
                        v-model:value="billing_address.state"
                        label-value="State / Province"
                        type="text"
                      />
                    </div>

                    <!-- Postal code -->
                    <div class="form-input--30">
                      <p class="input-header">Postal code</p>
                      <hlx-input
                        v-model:value="billing_address.postal_code"
                        type="text"
                        label-value="Postal / ZIP"
                      />
                    </div>
                  </div>

                  <div class="form-input-wrapper">
                    <!-- Country -->
                    <div class="form-input">
                      <p class="input-header">Country</p>
                      <!-- <hlx-input type="text" label_value="Canada" /> -->
                      <hlx-select
                        v-model:value="billing_address.country"
                        :options="dropdown_data"
                        :placeholder-value="'Country'"
                        :label-animation="false"
                        @custom-change="selectValue"
                      ></hlx-select>
                    </div>
                  </div>
                </div>
              </section>
            </main>
            <!-- <hlx-button class="primary sm"> Pay </hlx-button> -->
            <div
              id="payment-message"
              class="hidden"
            ></div>
          </form>
        </section>
      </div>
      <div class="footer-fix">
        <hlx-button
          class="secondary sm"
          @click="goToPlan"
          >Back</hlx-button
        >
        <hlx-button
          class="primary sm"
          @click.prevent="requestPayment"
        >
          Pay now</hlx-button
        >
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
/* eslint-disable no-use-before-define */

export default {
  name: 'PaymentView',
  data() {
    return {
      userId: '',
      company: '',
      token: this.$route.params.token,
      billing_address: {
        street: '',
        postal_code: '',
        country: '',
        state: '',
        city: '',
        unit: '',
      },
      nav_items: [
        {
          name: 'Home',
          label: 'Home',
          icon: 'icon-grid-web-7-regular',
          description_en: 'Home',
          description_fn: '*Home',
        },
        {
          name: 'Residential business market ',
          label: 'About',
          icon: 'icon-code-regular',
          description_en: 'Residential business market ',
          description_fr: '*Residential business market ',
        },
        {
          name: 'Schema',
          label: 'Schema',
          icon: 'icon-desktop-code-regular',
          description_en: 'Small business markets',
          description_fr: '*Small business markets',
        },
        {
          name: 'Database',
          label: 'Database',
          icon: 'icon-database-regular',
          description_en: 'Small business markets',
          description_fr: '*Small business markets',
        },
        {
          name: 'help',
          label: 'Setting',
          icon: 'icon-question-circle-regular',
          description_en: 'Setting',
          description_fr: '*Setting',
          separator: true,
        },
        {
          name: 'setting',
          label: 'settings',
          icon: 'icon-settings-regular',
          description_en: 'Setting',
          description_fr: '*Setting',
          active: true,
        },
        {
          name: 'logout',
          label: 'Logout',
          icon: 'icon-log-out-regular',
          description_en: 'Logout',
          description_fr: '*Logout',
        },
      ],
      selectValue: [{ name: 'Canada' }],
      options3: [
        { name: 'Card', checked: false, disabled: false, id: 1 },
        { name: 'Bank transfer', checked: false, disabled: false, id: 2 },
      ],
      example_data: [
        {
          title: '',
          description: '',
          subtitle: 'Complete account setup',
          active: false,
          visited: true,
        },
        {
          title: '',
          description: '',
          subtitle: 'Choose a plan',
          active: false,
          visited: true,
        },
        {
          title: '',
          description: '',
          subtitle: 'Payment information',
          active: true,
          visited: false,
        },
      ],
      dropdown_data: [
        { name: 'CA', value: 'CA', checked: false, disabled: false, id: 32 },
      ],
      fet: process.env.VUE_APP_HOST_PROTOCOL + '://' + process.env.VUE_APP_HOST,
    };
  },
  async mounted() {
    let token = {
      headers: {
        Authorization: 'Bearer  ' + this.token,
      },
    };
    
    axios
      .get(this.fet + `/partymanagement/individual/auth`, token)
      .then((val) => {
        
        this.company = val.data.company;
        this.userId = val.data.userId;
      });
    // eslint-disable-next-line
    const stripe = Stripe(
      'pk_test_51MTmSJSDGIKEgiTDA4eEdrMi6wqOTl7oTHUvXebBtHVXDz2OJ6IRwLmB6b2KphUMfx4KnbAjx8KusWWu6WSzyuBw00IEiLeluk'
    );
    document.addEventListener('DOMContentLoaded', () => {
      
    });
    this.stripe = stripe;
    /**
     * 1.Get scret to run payment element
     * 2.capture payment and address information
     * 3.After click paynow button disable the button action for user try to resubmit the form and initiate confirm card payment
     * 4.After successfull payment send request to create order API
     * */

    this.checkStatus();
    //1. Crete payment element
    let connectId = await this.decrypt();
    await this.createPaymentElement(connectId);
  },
  methods: {
    async decrypt() {
      let decryptedCode = await axios({
        method: 'POST',
        url: 'http://localhost:5151/util/decrypt',
        data: { data: this.$route.query.plan },
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      });
      // if (!decryptedCode.data) {
        
      // }
      return decryptedCode.data;
    },
    async createPaymentElement(secret = null) {
      //3.Create subscription

      const clientSecret = secret;
      if (clientSecret == null) {
        
        return;
      }
      const appearance = {
        theme: 'none',
        variables: {
          colorPrimary: '#54bd95',
          colorBackground: '#ffffff',
          colorText: '#191a15',
          colorDanger: '#ff5050',
          fontFamily: 'Open Sans',
          fontSizeBase: '15px',
          spacingUnit: '3.1px',
          borderRadius: '6px',
          // See all possible variables below
        },
        rules: {
          '.Input--invalid': {
            boxShadow:
              '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
          },
          '.Input': {
            border: '1px solid #E0E6EB',
            marginBottom: '8px',
            boxShadow:
              '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
          },
          '.Input:hover': {
            border: '1px solid rgb(84, 189, 149)',
          },
          '.Input:focus': {
            border: '1px solid #54bd95',
            outline: 'none',
          },
          '.Input::placeholder': {
            fontSize: '14px',
          },
          '.Label': {
            fontSize: '14px',
          },
        },
      };

      this.elements = this.stripe.elements({
        appearance,

        fonts: [
          {
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Open+Sans&display=swap',
          },
        ],
        clientSecret,
      });

      const paymentElementOptions = {
        layout: {
          type: 'tabs',
          defaultCollapsed: false,
        },
        terms: { card: 'never' },
        fields: {
          billingDetails: {
            address: {
              country: 'never',
            },
          },
        },
      };

      const paymentElement = this.elements.create(
        'payment',
        paymentElementOptions
      );
      paymentElement.mount('#payment-element');
    },
    async requestPayment() {
      // var paymentElement =
       this.elements.getElement('payment');


      
      let user = await this.getUser();
      const result = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: 'http://window.location.hostname/dashboard',
          receipt_email: user.email || 'pcfor62@gmail.com',
          payment_method_data: {
            billing_details: {
              address: {
                line1: this.billing_address.street,
                city: this.billing_address.city,
                country: this.billing_address.country,
                postal_code: this.billing_address.postal_code,
                state: this.billing_address.state,
              },
            },
          },
        },
        redirect: 'if_required',
      });
      const { error } = result;
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (
        (error && error.type === 'card_error') ||
        (error && error.type === 'validation_error') ||
        error
      ) {
        
        // this.showMessage(error.message);
        return;
      }
      
      // let orderRes = 
      await axios({
        method: 'POST',
        url: 'http://localhost:5151/product/order/create',
        data: {
          paymentIntentId: result.paymentIntent.id,
          user: {
            id: this.userId,
            company: this.company,
          },
        },
      });
      
    },
    async getUser() {
      let userInfo = await axios({
        method: 'POST',
        url: `http://localhost:5151/user/register?userId=${this.userId}&company=${this.company}`,
        headers: {
          Authorization: 'Bearer  ' + this.token,
        },
      });
      if (!userInfo.data) {
        console.error('something went wrong ' + userInfo);
      }
      userInfo = userInfo.data.data;
      
      return userInfo;
    },
    async setLoading(isLoading) {
      if (isLoading) {
        // Disable the button and show a spinner
        document.querySelector('#submit').disabled = true;
        document.querySelector('#spinner').classList.remove('hidden');
        document.querySelector('#button-text').classList.add('hidden');
      } else {
        document.querySelector('#submit').disabled = false;
        document.querySelector('#spinner').classList.add('hidden');
        document.querySelector('#button-text').classList.remove('hidden');
      }
    },
    async showMessage(messageText) {
      const messageContainer = document.querySelector('#payment-message');

      messageContainer.classList.remove('hidden');
      messageContainer.textContent = messageText;

      setTimeout(function () {
        messageContainer.classList.add('hidden');
        messageText.textContent = '';
      }, 4000);
    },
    async checkStatus() {
      const clientSecret = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret'
      );

      if (!clientSecret) {
        return;
      }

      const { paymentIntent } = await this.stripe.retrievePaymentIntent(
        clientSecret
      );

      switch (paymentIntent.status) {
        case 'succeeded':
          this.showMessage('Payment succeeded!');
          break;
        case 'processing':
          this.showMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          this.showMessage(
            'Your payment was not successful, please try again.'
          );
          break;
        default:
          this.showMessage('Something went wrong.');
          break;
      }
    },
    setMessage: (message) => {
      const messageDiv = document.querySelector('#messages');
      messageDiv.innerHTML += '<br>' + message;
    },
    async cancelSubsription(id) {
      try {
        let token = {
          headers: {
            Authorization: 'Bearer  ' + this.token,
          },
        };
        let subscription = await axios.delete(
          'http://localhost:5151/subscription/cancel/' + id,
          token
        );
        if (!subscription.data) {
          throw new Error(subscription);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async goToPlan() {
      this.cancelSubsription(this.$route.query.sub);
      this.$router.push('/planView');
    },
  },
};
</script>

<style>
/* Variables */

.hidden {
  display: none;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}
#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

/* Buttons and links
  background: #5469d4 !important;
button {
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}
 */

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}
</style>
