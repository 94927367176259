
// module.exports = r;
const r = {
    "_id": "671b499ff2c7172231955250",
    "name": "productoffering",
    "schema": {
        "href": {
            "type": "String",
            "system": true,
            "default": "https://tmf-upc-ghubgwxrcq-pd.a.run.app/catalogManagement/productOffering",
            "displayType": "input",
            "show": true
        },
        "displayID": {
            "type": "String",
            "system": true
        },
        "relatedProduct": {
            "type": "Array",
            "system": true
        },
        "relatedParty": {
            "type": "Array",
            "system": true,
            "displayType": "dropdown",
            "isRef": false,
            "labelEnglish": "Product contact",
            "required": true,
            "show": true,
            "section": [
                "overview"
            ],
            "tooltipEnglish": "",
            "labels": {
                "en": {
                    "label": "Product contact",
                    "tooltip": "tooltip data"
                }
            }
        },
        "notes": {
            "type": "Array",
            "system": true
        },
        "id": {
            "type": "String",
            "system": true
        },
        "lastUpdate": {
            "type": "Date",
            "system": true,
            "default": "2024-10-25T07:28:10.293Z"
        },
        "lifecycleStatus": {
            "type": "String",
            "system": true,
            "default": "In study",
            "displayType": "dropdown",
            "isRef": false,
            "labelEnglish": "Status",
            "required": true,
            "show": true,
            "section": [
                "overview"
            ],
            "tooltipEnglish": ""
        },
        "name": {
            "type": "String",
            "system": true,
            "required": true,
            "displayType": "input",
            "isRef": false,
            "labelEnglish": "Name",
            "show": true,
            "section": [
                "overview"
            ],
            "tooltipEnglish": ""
        },
        "isBundle": {
            "type": "Boolean",
            "system": true,
            "default": false
        },
        "productSpecification": {
            "type": "Mixed",
            "system": true
        },
        "constraint": {
            "type": "Array",
            "system": true
        },
        "bundledProductOffering": {
            "type": "Array",
            "system": true,
            "displayType": "label",
            "isRef": false,
            "labelEnglish": "isBundle",
            "required": true,
            "section": [
                "overview"
            ],
            "show": true,
            "tooltipEnglish": ""
        },
        "description": {
            "type": "String",
            "system": true
        },
        "price": {
            "type": "Mixed",
            "system": true
        },
        "percentage": {
            "type": "Mixed",
            "system": true
        },
        "priceType": {
            "type": "String",
            "system": true
        },
        "recurringChargePeriodType": {
            "type": "String",
            "system": true
        },
        "offerType": {
            "type": "String",
            "system": true,
            "default": "Atomic",
            "displayType": "label",
            "isRef": false,
            "labelEnglish": "Offer type",
            "required": true,
            "section": [
                "pricing"
            ],
            "show": true,
            "tooltipEnglish": ""
        },
        "isSellable": {
            "type": "Boolean",
            "system": true,
            "default": true
        },
        "validFor": {
            "type": "Mixed",
            "system": true
        },
        "version": {
            "type": "String",
            "system": true
        },
        "place": {
            "type": "Array",
            "system": true,
            "displayType": "-",
            "isRef": false,
            "labelEnglish": "Place",
            "required": true,
            "show": true,
            "section": [
                "overview"
            ],
            "tooltipEnglish": ""
        },
        "serviceLevelAgreement": {
            "type": "Mixed",
            "system": true
        },
        "channel": {
            "type": "Array",
            "system": true,
            "displayType": "dropdown",
            "isRef": false,
            "labelEnglish": "Channel",
            "required": true,
            "show": true,
            "section": [
                "overview"
            ],
            "tooltipEnglish": ""
        },
        "serviceCandidate": {
            "type": "Mixed",
            "system": true
        },
        "attachment": {
            "type": "Array",
            "system": true
        },
        "category": {
            "type": "Array",
            "system": true,
            "displayType": "label",
            "isRef": false,
            "labelEnglish": "Category",
            "required": true,
            "show": true,
            "section": [
                "overview"
            ],
            "tooltipEnglish": ""
        },
        "resourceCandidate": {
            "type": "Mixed",
            "system": true
        },
        "characteristicValueSpec": {
            "type": "Array",
            "system": true
        },
        "productOfferingTerm": {
            "type": "Array",
            "system": true
        },
        "marketSegment": {
            "type": "Array",
            "system": true,
            "displayType": "dropdown",
            "isRef": false,
            "labelEnglish": "Market",
            "required": true,
            "show": true,
            "tooltipEnglish": ""
        },
        "productOfferingPrice": {
            "type": "Array",
            "system": true
        },
        "agreement": {
            "type": "Array",
            "system": true
        },
        "prodSpecCharValueUse": {
            "type": "Array",
            "system": true,
            "displayType": "-",
            "isRef": false,
            "labelEnglish": "-",
            "required": true,
            "section": [
                "pricing"
            ],
            "show": true,
            "tooltipEnglish": ""
        },
        "prodSpecCharValue": {
            "type": "Array",
            "system": true
        },
        "additionalCharges": {
            "type": "Array",
            "system": true
        },
        "tags": {
            "type": "Array",
            "system": true,
            "description": "hello world",
            "displayType": "dropdown",
            "isRef": true,
            "labelEnglish": "hello",
            "labelOptional": "world",
            "optionRef": "referenceDataName",
            "required": true,
            "show": true,
            "tooltip": "show tooltip data"
        },
        "byod": {
            "type": "Boolean",
            "system": true,
            "default": false
        },
        "productOfferingRelationship": {
            "type": "Array",
            "system": true
        },
        "unitOfMeasure": {
            "type": "String",
            "system": true,
            "displayType": "table",
            "show": true,
            "section": [
                "Overview"
            ]
        },
        "discountResponse": {
            "type": "Mixed",
            "system": true
        },
        "@type": {
            "type": "Boolean",
            "system": true
        },
        "_id": {
            "type": "ObjectID",
            "system": true
        },
        "__v": {
            "type": "Number",
            "system": true
        },
        "fmo": {
            "type": "Boolean",
            "system": true,
            "displayType": "table",
            "isRef": true,
            "labelEnglish": "hello",
            "show": true,
            "tooltip": "show tooltip data",
            "optionRef": "referenceDataName",
            "description": "hello world",
            "labelOptional": "world",
            "required": true
        },
        "cmo": {
            "type": "Boolean",
            "displayType": "table",
            "system": false,
            "section": [
                "Overview"
            ]
        },
        "customField1": {
            "type": "Mixed",
            "system": false,
            "displayType": "dropdown",
            "isRef": true,
            "optionRef": "referenceDataName",
            "required": true,
            "description": "hello world",
            "labelEnglish": "hello",
            "labelOptional": "world",
            "tooltip": "-",
            "show": true
        },
        "field1": {
            "isRef": false,
            "required": true,
            "hasTooltip": false,
            "tooltipEnglish": "",
            "tooltipFrench": "",
            "show": true,
            "fieldName": "field1",
            "labelEnglish": "eng",
            "labelFrench": "fre",
            "displayType": "header",
            "id": 5
        },
        "dsad": {
            "type": "String",
            "system": false,
            "isRef": true,
            "required": false,
            "hasTooltip": false,
            "tooltipEnglish": "",
            "tooltipFrench": "",
            "show": true,
            "fieldName": "dsad",
            "labelEnglish": "dsad",
            "labelFrench": "sad",
            "description": "asd",
            "displayType": "dropdown",
            "optionRef": {
                "_id": "671b499ff2c7172231955250",
                "name": "productoffering",
                "schema": {
                    "href": {
                        "type": "String",
                        "system": true,
                        "default": "https://tmf-upc-ghubgwxrcq-pd.a.run.app/catalogManagement/productOffering",
                        "displayType": "input",
                        "show": true
                    },
                    "displayID": {
                        "type": "String",
                        "system": true
                    },
                    "relatedProduct": {
                        "type": "Array",
                        "system": true
                    },
                    "relatedParty": {
                        "type": "Array",
                        "system": true,
                        "displayType": "dropdown",
                        "isRef": false,
                        "labelEnglish": "Product contact",
                        "required": true,
                        "show": true
                    },
                    "notes": {
                        "type": "Array",
                        "system": true
                    },
                    "id": {
                        "type": "String",
                        "system": true
                    },
                    "lastUpdate": {
                        "type": "Date",
                        "system": true,
                        "default": "2024-10-25T07:28:10.293Z"
                    },
                    "lifecycleStatus": {
                        "type": "String",
                        "system": true,
                        "default": "In study",
                        "displayType": "dropdown",
                        "isRef": false,
                        "labelEnglish": "Status",
                        "required": true,
                        "show": true
                    },
                    "name": {
                        "type": "String",
                        "system": true,
                        "required": true,
                        "displayType": "input",
                        "isRef": false,
                        "labelEnglish": "Name",
                        "show": true
                    },
                    "isBundle": {
                        "type": "Boolean",
                        "system": true,
                        "default": false
                    },
                    "productSpecification": {
                        "type": "Mixed",
                        "system": true
                    },
                    "constraint": {
                        "type": "Array",
                        "system": true
                    },
                    "bundledProductOffering": {
                        "type": "Array",
                        "system": true
                    },
                    "description": {
                        "type": "String",
                        "system": true
                    },
                    "price": {
                        "type": "Mixed",
                        "system": true
                    },
                    "percentage": {
                        "type": "Mixed",
                        "system": true
                    },
                    "priceType": {
                        "type": "String",
                        "system": true
                    },
                    "recurringChargePeriodType": {
                        "type": "String",
                        "system": true
                    },
                    "offerType": {
                        "type": "String",
                        "system": true,
                        "default": "Atomic"
                    },
                    "isSellable": {
                        "type": "Boolean",
                        "system": true,
                        "default": true
                    },
                    "validFor": {
                        "type": "Mixed",
                        "system": true
                    },
                    "version": {
                        "type": "String",
                        "system": true
                    },
                    "place": {
                        "type": "Array",
                        "system": true,
                        "displayType": "-",
                        "isRef": false,
                        "labelEnglish": "Place",
                        "required": true,
                        "show": true
                    },
                    "serviceLevelAgreement": {
                        "type": "Mixed",
                        "system": true
                    },
                    "channel": {
                        "type": "Array",
                        "system": true,
                        "displayType": "dropdown",
                        "isRef": false,
                        "labelEnglish": "Channel",
                        "required": true,
                        "show": true
                    },
                    "serviceCandidate": {
                        "type": "Mixed",
                        "system": true
                    },
                    "attachment": {
                        "type": "Array",
                        "system": true
                    },
                    "category": {
                        "type": "Array",
                        "system": true,
                        "displayType": "label",
                        "isRef": false,
                        "labelEnglish": "-",
                        "required": true,
                        "show": true
                    },
                    "resourceCandidate": {
                        "type": "Mixed",
                        "system": true
                    },
                    "characteristicValueSpec": {
                        "type": "Array",
                        "system": true
                    },
                    "productOfferingTerm": {
                        "type": "Array",
                        "system": true
                    },
                    "marketSegment": {
                        "type": "Array",
                        "system": true,
                        "displayType": "dropdown",
                        "isRef": false,
                        "labelEnglish": "Market",
                        "required": true,
                        "show": true
                    },
                    "productOfferingPrice": {
                        "type": "Array",
                        "system": true
                    },
                    "agreement": {
                        "type": "Array",
                        "system": true
                    },
                    "prodSpecCharValueUse": {
                        "type": "Array",
                        "system": true
                    },
                    "prodSpecCharValue": {
                        "type": "Array",
                        "system": true
                    },
                    "additionalCharges": {
                        "type": "Array",
                        "system": true
                    },
                    "tags": {
                        "type": "Array",
                        "system": true,
                        "description": "hello world",
                        "displayType": "dropdown",
                        "isRef": true,
                        "labelEnglish": "hello",
                        "labelOptional": "world",
                        "optionRef": "referenceDataName",
                        "required": true,
                        "show": true,
                        "tooltip": "show tooltip data"
                    },
                    "byod": {
                        "type": "Boolean",
                        "system": true,
                        "default": false
                    },
                    "productOfferingRelationship": {
                        "type": "Array",
                        "system": true
                    },
                    "unitOfMeasure": {
                        "type": "String",
                        "system": true,
                        "displayType": "table",
                        "show": true
                    },
                    "discountResponse": {
                        "type": "Mixed",
                        "system": true
                    },
                    "@type": {
                        "type": "Boolean",
                        "system": true
                    },
                    "_id": {
                        "type": "ObjectID",
                        "system": true
                    },
                    "__v": {
                        "type": "Number",
                        "system": true
                    },
                    "fmo": {
                        "type": "Boolean",
                        "system": true,
                        "displayType": "dropdown",
                        "isRef": true,
                        "labelEnglish": "hello",
                        "show": true,
                        "tooltip": "show tooltip data",
                        "optionRef": "referenceDataName",
                        "description": "hello world",
                        "labelOptional": "world",
                        "required": true
                    },
                    "cmo": {
                        "type": "Boolean",
                        "displayType": "table",
                        "system": false
                    },
                    "customField1": {
                        "type": "Mixed",
                        "system": false,
                        "displayType": "dropdown",
                        "isRef": true,
                        "optionRef": "referenceDataName",
                        "required": true,
                        "description": "hello world",
                        "labelEnglish": "hello",
                        "labelOptional": "world",
                        "tooltip": "-",
                        "show": true
                    },
                    "field1": {
                        "type": "String",
                        "system": false,
                        "isRef": true,
                        "required": true,
                        "hasTooltip": false,
                        "tooltipEnglish": "",
                        "tooltipFrench": "",
                        "show": true,
                        "fieldName": "field1",
                        "labelEnglish": "field eng",
                        "labelFrench": "field fre",
                        "description": "field",
                        "displayType": "dropdown",
                        "optionRef": {
                            "_id": "671b499ff2c7172231955250",
                            "name": "productoffering",
                            "schema": {
                                "href": {
                                    "type": "String",
                                    "system": true,
                                    "default": "https://tmf-upc-ghubgwxrcq-pd.a.run.app/catalogManagement/productOffering",
                                    "displayType": "input",
                                    "show": true
                                },
                                "displayID": {
                                    "type": "String",
                                    "system": true
                                },
                                "relatedProduct": {
                                    "type": "Array",
                                    "system": true
                                },
                                "relatedParty": {
                                    "type": "Array",
                                    "system": true,
                                    "displayType": "dropdown",
                                    "isRef": false,
                                    "labelEnglish": "Product contact",
                                    "required": true,
                                    "show": true
                                },
                                "notes": {
                                    "type": "Array",
                                    "system": true
                                },
                                "id": {
                                    "type": "String",
                                    "system": true
                                },
                                "lastUpdate": {
                                    "type": "Date",
                                    "system": true,
                                    "default": "2024-10-25T07:28:10.293Z"
                                },
                                "lifecycleStatus": {
                                    "type": "String",
                                    "system": true,
                                    "default": "In study",
                                    "displayType": "dropdown",
                                    "isRef": false,
                                    "labelEnglish": "Status",
                                    "required": true,
                                    "show": true
                                },
                                "name": {
                                    "type": "String",
                                    "system": true,
                                    "required": true,
                                    "displayType": "input",
                                    "isRef": false,
                                    "labelEnglish": "Name",
                                    "show": true
                                },
                                "isBundle": {
                                    "type": "Boolean",
                                    "system": true,
                                    "default": false
                                },
                                "productSpecification": {
                                    "type": "Mixed",
                                    "system": true
                                },
                                "constraint": {
                                    "type": "Array",
                                    "system": true
                                },
                                "bundledProductOffering": {
                                    "type": "Array",
                                    "system": true
                                },
                                "description": {
                                    "type": "String",
                                    "system": true
                                },
                                "price": {
                                    "type": "Mixed",
                                    "system": true
                                },
                                "percentage": {
                                    "type": "Mixed",
                                    "system": true
                                },
                                "priceType": {
                                    "type": "String",
                                    "system": true
                                },
                                "recurringChargePeriodType": {
                                    "type": "String",
                                    "system": true
                                },
                                "offerType": {
                                    "type": "String",
                                    "system": true,
                                    "default": "Atomic"
                                },
                                "isSellable": {
                                    "type": "Boolean",
                                    "system": true,
                                    "default": true
                                },
                                "validFor": {
                                    "type": "Mixed",
                                    "system": true
                                },
                                "version": {
                                    "type": "String",
                                    "system": true
                                },
                                "place": {
                                    "type": "Array",
                                    "system": true,
                                    "displayType": "-",
                                    "isRef": false,
                                    "labelEnglish": "Place",
                                    "required": true,
                                    "show": true
                                },
                                "serviceLevelAgreement": {
                                    "type": "Mixed",
                                    "system": true
                                },
                                "channel": {
                                    "type": "Array",
                                    "system": true,
                                    "displayType": "dropdown",
                                    "isRef": false,
                                    "labelEnglish": "Channel",
                                    "required": true,
                                    "show": true
                                },
                                "serviceCandidate": {
                                    "type": "Mixed",
                                    "system": true
                                },
                                "attachment": {
                                    "type": "Array",
                                    "system": true
                                },
                                "category": {
                                    "type": "Array",
                                    "system": true,
                                    "displayType": "label",
                                    "isRef": false,
                                    "labelEnglish": "-",
                                    "required": true,
                                    "show": true
                                },
                                "resourceCandidate": {
                                    "type": "Mixed",
                                    "system": true
                                },
                                "characteristicValueSpec": {
                                    "type": "Array",
                                    "system": true
                                },
                                "productOfferingTerm": {
                                    "type": "Array",
                                    "system": true
                                },
                                "marketSegment": {
                                    "type": "Array",
                                    "system": true,
                                    "displayType": "dropdown",
                                    "isRef": false,
                                    "labelEnglish": "Market",
                                    "required": true,
                                    "show": true
                                },
                                "productOfferingPrice": {
                                    "type": "Array",
                                    "system": true
                                },
                                "agreement": {
                                    "type": "Array",
                                    "system": true
                                },
                                "prodSpecCharValueUse": {
                                    "type": "Array",
                                    "system": true
                                },
                                "prodSpecCharValue": {
                                    "type": "Array",
                                    "system": true
                                },
                                "additionalCharges": {
                                    "type": "Array",
                                    "system": true
                                },
                                "tags": {
                                    "type": "Array",
                                    "system": true,
                                    "description": "hello world",
                                    "displayType": "dropdown",
                                    "isRef": true,
                                    "labelEnglish": "hello",
                                    "labelOptional": "world",
                                    "optionRef": "referenceDataName",
                                    "required": true,
                                    "show": true,
                                    "tooltip": "show tooltip data"
                                },
                                "byod": {
                                    "type": "Boolean",
                                    "system": true,
                                    "default": false
                                },
                                "productOfferingRelationship": {
                                    "type": "Array",
                                    "system": true
                                },
                                "unitOfMeasure": {
                                    "type": "String",
                                    "system": true,
                                    "displayType": "table",
                                    "show": true
                                },
                                "discountResponse": {
                                    "type": "Mixed",
                                    "system": true
                                },
                                "@type": {
                                    "type": "Boolean",
                                    "system": true
                                },
                                "_id": {
                                    "type": "ObjectID",
                                    "system": true
                                },
                                "__v": {
                                    "type": "Number",
                                    "system": true
                                },
                                "fmo": {
                                    "type": "Boolean",
                                    "system": true,
                                    "displayType": "dropdown",
                                    "isRef": true,
                                    "labelEnglish": "hello",
                                    "show": true,
                                    "tooltip": "show tooltip data",
                                    "optionRef": "referenceDataName",
                                    "description": "hello world",
                                    "labelOptional": "world",
                                    "required": true
                                },
                                "cmo": {
                                    "type": "Boolean",
                                    "displayType": "table",
                                    "system": false
                                },
                                "customField1": {
                                    "type": "Mixed",
                                    "system": false,
                                    "displayType": "dropdown",
                                    "isRef": true,
                                    "optionRef": "referenceDataName",
                                    "required": true,
                                    "description": "hello world",
                                    "labelEnglish": "hello",
                                    "labelOptional": "world",
                                    "tooltip": "-",
                                    "show": true
                                }
                            },
                            "type": "manual",
                            "isReference": false,
                            "dataCount": 0,
                            "createdBy": "65d34902237a9575f79cf80e",
                            "lastModifiedBy": null,
                            "references": [],
                            "createdAt": "2024-10-25T07:32:47.396Z",
                            "updatedAt": "2024-11-11T13:23:36.870Z",
                            "__v": 0,
                            "checked": true,
                            "disabled": false
                        },
                        "id": 5
                    }
                },
                "type": "manual",
                "isReference": false,
                "dataCount": 0,
                "createdBy": "65d34902237a9575f79cf80e",
                "lastModifiedBy": null,
                "references": [],
                "createdAt": "2024-10-25T07:32:47.396Z",
                "updatedAt": "2024-11-11T16:14:14.156Z",
                "__v": 0,
                "checked": true,
                "disabled": false
            },
            "id": 5
        },
        "customField53": {
            "type": "String",
            "system": false,
            "isRef": false,
            "required": true,
            "hasTooltip": false,
            "tooltipEnglish": "",
            "tooltipFrench": "",
            "show": true,
            "fieldName": "customField53",
            "labelEnglish": "test1",
            "labelFrench": "test2",
            "displayType": "dropdown",
            "id": 5
        },
        "customfield43": {
            "type": "String",
            "system": false,
            "isRef": false,
            "required": true,
            "hasTooltip": false,
            "tooltipEnglish": "",
            "tooltipFrench": "",
            "show": true,
            "fieldName": "customfield43",
            "labelEnglish": "eng",
            "labelFrench": "fre",
            "displayType": "dropdown",
            "id": 5
        },
        "customFieldRef53": {
            "type": "String",
            "system": false,
            "isRef": false,
            "required": true,
            "hasTooltip": false,
            "tooltipEnglish": "",
            "tooltipFrench": "",
            "show": true,
            "fieldName": "customFieldRef53",
            "labelEnglish": "eng",
            "labelFrench": "fre",
            "displayType": "input",
            "id": 5
        },
        "field12": {
            "type": "String",
            "system": false,
            "isRef": false,
            "required": true,
            "hasTooltip": false,
            "tooltipEnglish": "",
            "tooltipFrench": "",
            "show": true,
            "fieldName": "field12",
            "labelEnglish": "eng",
            "labelFrench": "eng2",
            "displayType": "header",
            "id": 6
        },
        "customLangaugeField": {
            "fieldName": "customLangaugeField",
            "type": "String",
            "system": false,
            "isRef": false,
            "required": true,
            "hasTooltip": true,
            "tooltipEnglish": "",
            "tooltipFrench": "",
            "show": true,
            "labels": {
                "en": {
                    "label": "Languages",
                    "tooltip": "Tooltip - English",
                    "name": "English"
                },
                "hi": {
                    "label": "भाषा",
                    "tooltip": "टूलटिप - अंग्रेजी",
                    "name": "Hindi"
                },
                "ta": {
                    "label": "மொழி",
                    "tooltip": "உதவிக்குறிப்பு - ஆங்கிலம்",
                    "name": "Tamil"
                }
            },
            "description": "description",
            "displayType": "input",
            "id": 6,
            "labelEnglish": "Languages",
            "section": [
                "overview"
            ]
        },
        "newFieldForPricing": {
            "type": "String",
            "system": false,
            "isRef": false,
            "required": true,
            "hasTooltip": false,
            "tooltipEnglish": "",
            "tooltipFrench": "",
            "show": true,
            "labels": {
                "en": {
                    "label": "Price",
                    "tooltip": "",
                    "name": "English"
                },
                "hi": {
                    "label": " कीमत",
                    "tooltip": "",
                    "name": "Hindi"
                },
                "ta": {
                    "label": "விலை",
                    "tooltip": "",
                    "name": "Tamil"
                }
            },
            "fieldName": "newFieldForPricing",
            "displayType": "dropdown",
            "id": 1,
            "optionRef": "newFieldForPricing",
            "labelEnglish": "Price",
            "section": [
                "pricing"
            ]
        }
    },
    "type": "manual",
    "isReference": false,
    "dataCount": 0,
    "createdBy": "6737197a5182a0fa4710334d",
    "lastModifiedBy": "6737197a5182a0fa4710334d",
    "references": [],
    "createdAt": "2024-10-25T07:32:47.396Z",
    "updatedAt": "2024-11-15T16:46:28.381Z",
    "__v": 0
};

export default r;