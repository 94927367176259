<template>
  <!-- {{ userLanguages + 'hi' }} -->
  <!-- {{ $store?.getters?.loginUserDetails?.languageAbility }} -->
  <hlx-alert-notification
    :notify="notification"
    :auto-close="true"
  />
  <!-- Field delete -->
  <div class="confirm-sustomfield-delete-modal">
    <hlx-modal
      :modal-active="deleteModal"
      :height="'220px'"
      :width="'400px'"
      :modal="true"
      @close="deleteModal = false"
    >
      <template #header>
        <div>Delete field</div>
      </template>
      <template #content>
        <p
          style="
            text-align: left;
            padding-left: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
          "
        >
          Are you sure you want to delete the field<b>{{
            ' "' + deleteName + '"'
          }}</b
          >?
        </p>
      </template>
      <template #footer>
        <div class="footer-form-delte">
          <div class="button-area">
            <hlx-button
              class="secondary sm"
              @click="deleteModal = false"
              >Cancel</hlx-button
            >

            <hlx-button
              class="primary sm"
              @click="removeField(deleteIndex, deleteVal)"
              >Delete</hlx-button
            >
          </div>
        </div>
      </template>
    </hlx-modal>
  </div>
  <div class="add-contact-drawer">
    <hlx-drawer
      :key="resetComponent"
      :show="showAddFieldPanel"
      position="right"
      width="450"
      :footer="true"
      :title="'Add fields'"
      :show-close-icon="true"
      @close="closeDrawer"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          <b v-if="editMode == false"> Add Field</b>
          <b v-else> Edit Field</b>
        </div>
        <div class="body-area-contact-drawer">
          <div
            style="
              width: 100%;
              display: flex;
              justify-content: center;
              margin-bottom: 40px;
            "
          >
            <hlx-switch
              v-model="selectedDefinitionTab"
              :switch-items="definitionTabs"
              value-prop="value"
              display-prop="name"
              @change="chosenDefinitionTab"
            />
          </div>
          <div
            v-show="selectedDefinitionTab == 'Configuration'"
            class="visibility-rule-block"
          >
            <hlx-input
              v-model:value="fieldData.fieldName"
              :pre-val="fieldData.fieldName"
              :label-value="'Field name'"
              :label-animation="true"
              :rows="10"
              :read-only="editMode"
              :required="true"
              :display-error="true"
              :custom-error="isFieldNameInvalid"
              :custom-error-message="'Field name already exists'"
              @at-input="validateFieldName"
              @focus-out="blurFieldName"
            ></hlx-input>
            <hlx-input
              v-for="(languages, langIndex) in fieldData.labels"
              :key="langIndex"
              v-model:value="fieldData.labels[langIndex].label"
              :pre-val="fieldData.labels[langIndex].label"
              :label-value="`Label name - ${fieldData.labels[langIndex].name}`"
              :label-animation="true"
              :rows="10"
              :required="
                Object.keys(fieldData.labels).indexOf(langIndex) === 0
                  ? true
                  : false
              "
              :display-error="true"
              :custom-error="isLabelNameInvalid"
              :custom-error-message="'Label name already exists'"
              @at-input="validateLabelName"
            ></hlx-input>
            <!-- <br />
          <hlx-input
            :key="resetComponent"
            v-model:value="fieldData.labelFrench"
            :pre-val="fieldData.labelFrench"
            :label-value="'Label name - French'"
            :label-animation="true"
            :rows="10"
            :required="true"
            :custom-error="customnameerror"
            :custom-error-message="'-'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input> -->
            <!-- <br /> -->
            <hlx-input
              v-model:value="fieldData.description"
              :pre-val="fieldData.description"
              :label-value="'Description'"
              type="textarea"
              :label-animation="true"
              :rows="10"
              :required="false"
              :custom-error="customnameerror"
              :custom-error-message="'Label name - French'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
            <br />
            <hlx-select
              :key="resetDropdown"
              v-model:value="fieldData.displayType"
              :pre-value="fieldData.displayType"
              :placeholder-value="'Input type'"
              :options="displayTypeOptions"
              :label-animation="true"
              :required="true"
            ></hlx-select>
            <br />
            <div v-if="fieldData?.displayType?.toLowerCase() == 'user'">
              <hlx-multi-select
                :pre-value="fieldData?.others?.userRole"
                :options="userRoles"
                :label="'role'"
                :prop-value="'role'"
                :placeholder="'Role'"
                :label-animation="true"
                @selected-array="selectedRoles($event, fieldData.others)"
              >
              </hlx-multi-select>
            </div>
            <br v-if="fieldData?.displayType?.toLowerCase() == 'user'" />
            <!-- Visibility rules -->

            <div
              v-if="
                !fieldData.isRef &&
                (fieldData?.displayType?.toLowerCase() == 'dropdown' ||
                  fieldData?.displayType?.toLowerCase() == 'radio' ||
                  fieldData?.displayType?.toLowerCase() == 'checkbox' ||
                  fieldData?.displayType?.toLowerCase() == 'status' ||
                  fieldData?.displayType?.toLowerCase() == 'multiselect')
              "
              style="display: flex; flex-direction: column"
            >
              <b style="margin-bottom: 8px">Values</b>
              <span style="margin-bottom: 8px; font-size: 12px"
                >Add list of values</span
              >
              <div
                style="
                  display: flex;
                  gap: 20px;
                  justify-content: space-between;
                  margin-bottom: 20px;
                "
              >
                <span
                  style="display: flex; flex-direction: column; width: 100%"
                >
                  <hlx-input
                    v-model:value="csvValues"
                    :pre-val="csvValues"
                  ></hlx-input>
                  <span style="font-size: 12px"
                    >Seperate multiple values by using comma</span
                  >
                </span>
                <span>
                  <hlx-button
                    class="secondary sm"
                    @click="addCsvValues"
                  >
                    Add
                  </hlx-button>
                </span>
              </div>
              <div
                style="
                  margin-bottom: 20px;
                  height: 35px;
                  width: 100%;
                  background: #d8d8d8;
                  padding: 0 50px 0 20px;
                "
              >
                <span
                  style="
                    width: 50%;
                    display: flex;
                    height: 100%;
                    align-items: center;
                  "
                  >English value</span
                >
                <span></span>
              </div>
              <div
                v-for="(item, itemIndex) in newRefData"
                :key="itemIndex"
                style="
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  margin-bottom: 8px;
                "
                class="list-values"
                draggable="true"
                @dragstart="dragRefDataStart(itemIndex)"
                @dragover.prevent
                @dragleave="dragRefDataLeave(itemIndex)"
                @dragend="dragRefDataEnd(itemIndex)"
              >
                <i
                  class="icon-drag-vertical-filled"
                  style="cursor: grab"
                ></i>
                <hlx-color-palette
                  v-if="fieldData.displayType.toLowerCase() == 'status'"
                  @custom-change="colorValue($event, item)"
                ></hlx-color-palette>
                <hlx-input
                  v-model:value="item.value"
                  :pre-val="item.value"
                  :label-value="'Value - English'"
                  :label-animation="false"
                >
                </hlx-input>
                <span style="display: flex; gap: 8px">
                  <i
                    class="icon-badge-check-regular"
                    :style="
                      item.default == 'true' || item.default == true
                        ? { color: '#54bd95' }
                        : {}
                    "
                    @click="setDefaultNewRefData(itemIndex)"
                  ></i>
                  <i
                    class="icon-trash-regular"
                    @click="deleteNewRefData(itemIndex)"
                  ></i>
                </span>
              </div>
              <span
                style="
                  display: flex;
                  color: rgb(84, 189, 149);
                  align-items: center;
                  cursor: pointer;
                  margin-top: 5px;
                  margin-bottom: 8px;
                "
                @click="addNewRefData"
                ><i class="icon-plus-regular"></i> Add value
              </span>
            </div>
            <hlx-input
              v-if="
                fieldData?.displayType?.toLowerCase() == 'dropdown' ||
                fieldData?.displayType?.toLowerCase() == 'radio' ||
                fieldData?.displayType?.toLowerCase() == 'checkbox' ||
                fieldData?.displayType?.toLowerCase() == 'multiselect'
              "
              v-model:value="fieldData.isRef"
              :checked="fieldData.isRef"
              type="single-checkbox"
              :label-value="'Import reference'"
              @selected="checkIsref"
            ></hlx-input>
            <br
              v-if="
                fieldData.isRef &&
                (fieldData?.displayType?.toLowerCase() == 'dropdown' ||
                  fieldData?.displayType?.toLowerCase() == 'radio' ||
                  fieldData?.displayType?.toLowerCase() == 'checkbox' ||
                  fieldData?.displayType?.toLowerCase() == 'status' ||
                  fieldData?.displayType?.toLowerCase() == 'multiselect')
              "
            />
            <hlx-select
              v-if="
                fieldData.isRef &&
                (fieldData?.displayType?.toLowerCase() == 'dropdown' ||
                  fieldData?.displayType?.toLowerCase() == 'radio' ||
                  fieldData?.displayType?.toLowerCase() == 'checkbox' ||
                  fieldData?.displayType?.toLowerCase() == 'status' ||
                  fieldData?.displayType?.toLowerCase() == 'multiselect')
              "
              :options="referenceDataOptions"
              :pre-value="fieldData?.optionRef"
              :inline-search="true"
              :prop-value="'name'"
              :placeholder-value="'Choose a reference data'"
              @selected-value="selectedReferenceData"
            ></hlx-select>
            <br
              v-if="
                fieldData?.displayType?.toLowerCase() == 'dropdown' ||
                fieldData?.displayType?.toLowerCase() == 'radio' ||
                fieldData?.displayType?.toLowerCase() == 'status' ||
                fieldData?.displayType?.toLowerCase() == 'checkbox' ||
                fieldData?.displayType?.toLowerCase() == 'multiselect'
              "
            />
            <hlx-input
              v-model:value="fieldData.required"
              :checked="fieldData.required"
              type="single-checkbox"
              :label-value="'Mandatory'"
            ></hlx-input>
            <br />
            <hlx-input
              v-model:value="fieldData.others.readOnly"
              :checked="fieldData.others.readOnly"
              type="single-checkbox"
              :label-value="'Read only'"
            ></hlx-input>
            <br />
            <hlx-input
              v-model:value="fieldData.hasTooltip"
              :checked="fieldData.hasTooltip"
              type="single-checkbox"
              :label-value="'Tooltip description'"
            ></hlx-input>
            <br />
            <div
              v-for="(languages, langIndex) in fieldData.labels"
              :key="langIndex"
            >
              <hlx-input
                v-if="fieldData.hasTooltip"
                :key="langIndex"
                v-model:value="fieldData.labels[langIndex].tooltip"
                :pre-val="fieldData.labels[langIndex].tooltip"
                :label-value="`Tooltip description - ${fieldData.labels[langIndex].name}`"
                :label-animation="true"
                :display-error="true"
                type="textarea"
              ></hlx-input>
            </div>
            <!-- <br v-if="fieldData.hasTooltip" />
          <hlx-input
            v-if="fieldData.hasTooltip"
            :key="resetComponent"
            v-model:value="fieldData.tooltipFrench"
            type="textarea"
            :label-value="'Tooltip description - French'"
          ></hlx-input>
          <br v-if="fieldData.hasTooltip" /> -->
            <!-- Hide checkbox removed -->
            <!-- <hlx-input
            :checked="!fieldData.show"
            type="single-checkbox"
            :label-value="'Hide'"
            @selected="showHideField"
          ></hlx-input> -->
            <br />
            <div
              v-if="fieldData.others"
              style="
                display: flex;
                align-items: space-between;
                justify-content: center;
                flex-direction: column;
              "
            >
              <div v-if="fieldData?.displayType?.toLowerCase() == 'address'">
                <hlx-select
                  v-model:value="fieldData.others.addressAPI.type"
                  :pre-value="fieldData?.others?.addressAPI?.type"
                  :options="addressAPIOptions"
                  :label="'name'"
                  :prop-value="'name'"
                  :placeholder-value="'Address API'"
                  :label-animation="true"
                >
                </hlx-select>

                <br />
                <hlx-select
                  v-if="
                    fieldData?.others?.addressAPI?.type == 'Canada post API'
                  "
                  v-model:value="fieldData.others.addressAPI.authType"
                  :pre-value="fieldData?.others?.addressAPI?.authType"
                  :options="authenticationMethods"
                  :label="'name'"
                  :prop-value="'name'"
                  :placeholder-value="'Authentication method'"
                  :label-animation="true"
                >
                </hlx-select>
                <br />

                <hlx-input
                  v-if="fieldData?.others?.addressAPI?.authType == 'API Key'"
                  v-model:value="fieldData.others.addressAPI.key"
                  :pre-val="fieldData.others.addressAPI.key"
                  :label-value="'API key'"
                  :label-animation="true"
                >
                </hlx-input>
                <hlx-input
                  v-else-if="
                    fieldData?.others?.addressAPI?.authType == 'Bearer Token'
                  "
                  v-model:value="fieldData.others.addressAPI.token"
                  :pre-val="fieldData.others.addressAPI.token"
                  :label-value="'Token'"
                  :label-animation="true"
                >
                </hlx-input>
                <div
                  v-else-if="
                    fieldData?.others?.addressAPI?.authType == 'Basic Auth'
                  "
                >
                  <hlx-input
                    v-model:value="fieldData.others.addressAPI.userName"
                    :pre-val="fieldData.others.addressAPI.userName"
                    :label-value="'Username'"
                    :label-animation="true"
                  >
                  </hlx-input>
                  <br />
                  <hlx-input
                    v-model:value="fieldData.others.addressAPI.password"
                    :pre-val="fieldData.others.addressAPI.password"
                    :label-value="'Password'"
                    :label-animation="true"
                  >
                  </hlx-input>
                </div>
                <br />
              </div>
              <div v-if="fieldData?.displayType?.toLowerCase() == 'lookup'">
                <hlx-select
                  :pre-value="fieldData?.others?.lookup?.module"
                  :options="lookupModules"
                  :label="'module'"
                  :prop-value="'module'"
                  :placeholder-value="'Lookup module'"
                  :label-animation="true"
                  @selected-value="selectedLookupModule"
                >
                </hlx-select>

                <!-- <br />
              <hlx-select
                v-model:value="fieldData.others.lookup.fieldType"
                :pre-value="fieldData?.others?.lookup?.fieldType"
                :options="lookupFieldTypes"
                :label="'name'"
                :prop-value="'value'"
                :placeholder-value="'Lookup field type'"
                :label-animation="true"
              >
              </hlx-select> -->
                <br />

                <hlx-input
                  v-model:value="fieldData.others.lookup.lookupProp"
                  :pre-val="fieldData.others.lookup.lookupProp"
                  :label-value="'Lookup attribute'"
                  :label-animation="true"
                >
                </hlx-input>
                <br />
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 16px;
                  "
                >
                  <span>Data mapping</span>
                  <hlx-input
                    v-model:value="fieldData.others.lookup.isDataRemapEnabled"
                    :checked="fieldData.others.lookup.isDataRemapEnabled"
                    :type="'switch'"
                  ></hlx-input>
                </div>
              </div>
              <hlx-code-editor
                v-if="fieldData.others.lookup.isDataRemapEnabled"
                v-model:value="fieldData.others.lookup.remapData"
                :intellisense-data="intellisenseData"
                :pre-value="fieldData.others.lookup.remapData"
                :line-number="true"
              >
              </hlx-code-editor>
              <br v-if="fieldData.others.lookup.isDataRemapEnabled" />
              <div v-if="fieldData?.displayType?.toLowerCase() == 'lookup'">
                <br />
                <span
                  style="
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    margin-bottom: 20px;
                  "
                >
                  <b>Search settings</b>
                </span>
                <hr />
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    margin-top: 20px;
                    align-items: center;
                  "
                >
                  <span style="display: flex; flex-direction: column">
                    <span>Minimum Characters to Start Search</span>
                    <span style="font-size: 12px"
                      >Min: 1 character, Max: 10 characters</span
                    >
                  </span>
                  <span>
                    <hlx-input
                      v-model:value="fieldData.others.lookup.minCharToSearch"
                      :pre-val="fieldData.others.lookup.minCharToSearch"
                      type="number"
                      :min-limit="1"
                      :max-limit="10"
                    >
                    </hlx-input>
                  </span>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span style="display: flex; flex-direction: column">
                    <span>Maximum Number of Results to Display</span>
                    <span style="font-size: 12px"
                      >Min: 5 results, Max: 20 results</span
                    >
                  </span>
                  <span>
                    <hlx-input
                      v-model:value="fieldData.others.lookup.maxResultToDisplay"
                      :pre-val="fieldData.others.lookup.maxResultToDisplay"
                      type="number"
                      :min-limit="5"
                      :max-limit="20"
                    >
                    </hlx-input>
                  </span>
                </div>
              </div>
              <div
                v-if="fieldData?.displayType?.toLowerCase() == 'date'"
                style="margin-bottom: 20px; padding: 0 3px"
              >
                <span
                  style="
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    margin-bottom: 20px;
                  "
                >
                  <b>Date preferences</b>
                  <span style="font-size: 12px"
                    >Your Desired Input Options</span
                  >
                </span>
                <hlx-input
                  v-model:value="
                    fieldData.others.customDatePreference.preference
                  "
                  :pre-val="fieldData.others.customDatePreference.preference"
                  :type="'radio-buttons'"
                  :options="datePreferences"
                >
                </hlx-input>
                <br
                  v-if="
                    fieldData.others.customDatePreference.preference == 'range'
                  "
                />
                <hlx-date-picker
                  v-if="
                    fieldData.others.customDatePreference.preference == 'range'
                  "
                  :pre-value="fieldData.others.customDatePreference.range"
                  from-key="from"
                  to-key="to"
                  :label-animation="true"
                  :label-value="'Select date range'"
                  :left="550"
                  :top="160"
                  type="date"
                  icon="calendar-regular"
                  icon-position="right"
                  required="true"
                  @selected-range="customDatePreferenceRange"
                ></hlx-date-picker>
                <br />
                <span
                  style="
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    margin-bottom: 20px;
                  "
                >
                  <b>Custom date selector</b>
                  <span style="font-size: 12px"
                    >Business Day Focused Date Selection Made Simple</span
                  >
                </span>
                <hlx-input
                  v-model:value="fieldData.others.businessDatePreference"
                  :type="'checkbox'"
                  :options="businessDatePreferences"
                >
                </hlx-input>
              </div>
            </div>
          </div>
          <!-- visibility rule block  -->
          <div
            v-show="selectedDefinitionTab == 'Visibility rule'"
            class="visibility-rule-block"
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
              "
            >
              <span>Visibility rule</span>
              <hlx-input
                v-model:value="fieldData.others.isVisibilityRuleEnabled"
                :checked="fieldData.others.isVisibilityRuleEnabled"
                :type="'switch'"
              ></hlx-input>
            </div>
            <div
              style="
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: space-between;
                padding: 8px;
                border: 1px solid #d8d8d8;
                border-radius: 5px;
                margin-bottom: 20px;
              "
            >
              <div
                style="
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <span> Field name </span>
                <span>
                  {{ fieldData.fieldName }}
                </span>
              </div>
              <br />
              <div
                style="
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <span> Input type </span>
                <span>
                  {{
                    fieldData.displayType !== ''
                      ? fieldData?.displayType?.charAt(0)?.toUpperCase() +
                        fieldData?.displayType?.slice(1)
                      : ''
                  }}
                </span>
              </div>
            </div>
            <div
              v-if="fieldData.others.isVisibilityRuleEnabled"
              style="display: flex; align-items: center"
            >
              When
              <span style="width: 70px; margin: 0 20px"
                ><hlx-select
                  v-model:value="fieldData.others.visibilityRuleCondition"
                  :pre-value="'All'"
                  :prop-value="'name'"
                  :options="visibilityRuleConditionTypeOptions"
                ></hlx-select
              ></span>
              of the following matches,
            </div>
            <div v-if="fieldData.others.isVisibilityRuleEnabled">
              <span
                v-for="(ruleItem, itemIndex) in visibilityRulePayload"
                :key="itemIndex"
                style="display: block; margin: 16px 0"
              >
                <span
                  style="
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    align-items: center;
                  "
                >
                  <hlx-select
                    v-model:value="ruleItem.fieldName"
                    :options="fieldDataForDropdowns"
                    :pre-value="
                      ruleItem.fieldName !== ''
                        ? ruleItem.fieldName
                        : setFirstItemAsDefault(ruleItem)
                    "
                    :prop-value="'fieldName'"
                    label="name"
                    @selected-value="selectedFieldItem($event, ruleItem)"
                  ></hlx-select>
                  <hlx-select
                    v-model:value="ruleItem.operator"
                    :prop-value="'value'"
                    :options="getVisibilityRuleOperatorTypeOptions(ruleItem)"
                    :pre-value="
                      getVisibilityRuleOperatorTypeOptions(ruleItem).some(
                        (item) => item.value == ruleItem?.operator
                      )
                        ? ruleItem.operator
                        : (getVisibilityRuleOperatorTypeOptions(ruleItem)[0]
                            ?.value,
                          (ruleItem.operator =
                            getVisibilityRuleOperatorTypeOptions(
                              ruleItem
                            )[0]?.value),
                          (ruleItem.showType =
                            getVisibilityRuleOperatorTypeOptions(
                              ruleItem
                            )[0]?.show))
                    "
                    @selected-value="selectedOperatorObject($event, ruleItem)"
                  ></hlx-select>
                </span>
                <span
                  v-if="ruleItem.showType !== ''"
                  style="display: grid; margin-top: 20px; width: 100%"
                >
                  <hlx-multi-select
                    v-if="ruleItem.showType == 'dropdown'"
                    :prop-value="'name'"
                    placeholder-value="Values"
                    :options="ruleItem.options"
                    :pre-value="ruleItem.values"
                    :tooltipposition="'left'"
                    @selected-array="selectedValues($event, ruleItem)"
                  ></hlx-multi-select>
                  <hlx-input
                    v-else-if="ruleItem.showType == 'input'"
                    v-model:value="ruleItem.value"
                    :pre-val="ruleItem.value"
                  >
                  </hlx-input>
                  <hlx-input
                    v-else-if="ruleItem.showType == 'date'"
                    v-model:value="ruleItem.value"
                    type="date"
                    icon="calendar-regular"
                    icon-position="right"
                    :pre-val="ruleItem.value"
                  >
                  </hlx-input>
                  <hlx-input
                    v-else-if="ruleItem.showType == 'number'"
                    v-model:value="ruleItem.value"
                    type="number"
                    :pre-val="ruleItem.value"
                  >
                  </hlx-input>
                  <div
                    v-else-if="ruleItem.showType == 'double-number'"
                    style="display: flex; flex-direction: column; gap: 10px"
                  >
                    <hlx-input
                      v-model:value="ruleItem.values[0]"
                      type="number"
                      :pre-val="ruleItem.values[0]"
                    >
                    </hlx-input>
                    <hlx-input
                      v-model:value="ruleItem.values[1]"
                      type="number"
                      :pre-val="ruleItem.values[1]"
                    >
                    </hlx-input>
                  </div>
                  <div
                    v-else-if="ruleItem.showType == 'double-date'"
                    style="display: flex; flex-direction: column; gap: 10px"
                  >
                    <hlx-input
                      v-model:value="ruleItem.values[0]"
                      type="date"
                      icon="calendar-regular"
                      icon-position="right"
                      :pre-val="ruleItem.values[0]"
                    >
                    </hlx-input>
                    <hlx-input
                      v-model:value="ruleItem.values[1]"
                      type="date"
                      icon="calendar-regular"
                      icon-position="right"
                      :pre-val="ruleItem.values[1]"
                    >
                    </hlx-input>
                  </div>
                </span>
                <div
                  v-if="visibilityRulePayload.length > 1"
                  style="
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                  "
                >
                  <span
                    style="color: #fc3232; cursor: pointer"
                    @click="deleteVisibilityRuleBlock(itemIndex)"
                    ><i class="icon-trash-regular"></i> &nbsp; Delete</span
                  >
                </div>
                <br />
                <hr />
              </span>
            </div>
            <div
              v-if="fieldData.others.isVisibilityRuleEnabled"
              style="
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
              "
            >
              <span
                style="color: #54bd95; cursor: pointer"
                @click="addOneMoreVisibilityRuleBlock"
                >+ Add condition</span
              >
            </div>
            <div
              v-if="fieldData.others.isVisibilityRuleEnabled"
              style="display: flex; flex-direction: column; gap: 16px"
            >
              <span>Then,</span>
              <hlx-select
                v-model:value="fieldData.others.visibilityRuleAction"
                :prop-value="'value'"
                label="name"
                :options="visibilityRuleActionTypeOptions"
                :pre-value="fieldData.others.visibilityRuleAction"
              ></hlx-select>
            </div>
          </div>
          <!-- validation rule block  -->
          <div
            v-show="selectedDefinitionTab == 'Field validation'"
            class="visibility-rule-block"
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
              "
            >
              <span>Field validation</span>
              <hlx-input
                v-model:value="fieldData.others.isVisibilityRuleEnabled"
                :checked="fieldData.others.isVisibilityRuleEnabled"
                :type="'switch'"
              ></hlx-input>
            </div>
            <div
              style="
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: space-between;
                padding: 8px;
                border: 1px solid #d8d8d8;
                border-radius: 5px;
                margin-bottom: 20px;
              "
            >
              <div
                style="
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <span> Field name </span>
                <span>
                  {{ fieldData.fieldName }}
                </span>
              </div>
              <br />
              <div
                style="
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <span> Input type </span>
                <span>
                  {{
                    fieldData.displayType !== ''
                      ? fieldData?.displayType?.charAt(0)?.toUpperCase() +
                        fieldData?.displayType?.slice(1)
                      : ''
                  }}
                </span>
              </div>
            </div>
            <div
              v-if="fieldData.others.isVisibilityRuleEnabled"
              style="display: flex; align-items: center"
            >
              When
              <span style="width: 70px; margin: 0 20px"
                ><hlx-select
                  v-model:value="fieldData.others.visibilityRuleCondition"
                  :pre-value="'All'"
                  :prop-value="'name'"
                  :options="visibilityRuleConditionTypeOptions"
                ></hlx-select
              ></span>
              of the following matches,
            </div>
            <div v-if="fieldData.others.isVisibilityRuleEnabled">
              <span
                v-for="(ruleItem, itemIndex) in visibilityRulePayload"
                :key="itemIndex"
                style="display: block; margin: 16px 0"
              >
                <span
                  style="
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    align-items: center;
                  "
                >
                  <hlx-select
                    v-model:value="ruleItem.fieldName"
                    :options="fieldDataForDropdowns"
                    :pre-value="
                      ruleItem.fieldName !== ''
                        ? ruleItem.fieldName
                        : setFirstItemAsDefault(ruleItem)
                    "
                    :prop-value="'fieldName'"
                    label="name"
                    @selected-value="selectedFieldItem($event, ruleItem)"
                  ></hlx-select>
                  <hlx-select
                    v-model:value="ruleItem.operator"
                    :prop-value="'value'"
                    :options="getVisibilityRuleOperatorTypeOptions(ruleItem)"
                    :pre-value="
                      getVisibilityRuleOperatorTypeOptions(ruleItem).some(
                        (item) => item.value == ruleItem?.operator
                      )
                        ? ruleItem.operator
                        : (getVisibilityRuleOperatorTypeOptions(ruleItem)[0]
                            ?.value,
                          (ruleItem.operator =
                            getVisibilityRuleOperatorTypeOptions(
                              ruleItem
                            )[0]?.value),
                          (ruleItem.showType =
                            getVisibilityRuleOperatorTypeOptions(
                              ruleItem
                            )[0]?.show))
                    "
                    @selected-value="selectedOperatorObject($event, ruleItem)"
                  ></hlx-select>
                </span>
                <span
                  v-if="ruleItem.showType !== ''"
                  style="display: grid; margin-top: 20px; width: 100%"
                >
                  <hlx-multi-select
                    v-if="ruleItem.showType == 'dropdown'"
                    :prop-value="'name'"
                    placeholder-value="Values"
                    :options="ruleItem.options"
                    :pre-value="ruleItem.values"
                    :tooltipposition="'left'"
                    @selected-array="selectedValues($event, ruleItem)"
                  ></hlx-multi-select>
                  <hlx-input
                    v-else-if="ruleItem.showType == 'input'"
                    v-model:value="ruleItem.value"
                    :pre-val="ruleItem.value"
                  >
                  </hlx-input>
                  <hlx-input
                    v-else-if="ruleItem.showType == 'date'"
                    v-model:value="ruleItem.value"
                    type="date"
                    icon="calendar-regular"
                    icon-position="right"
                    :pre-val="ruleItem.value"
                  >
                  </hlx-input>
                  <hlx-input
                    v-else-if="ruleItem.showType == 'number'"
                    v-model:value="ruleItem.value"
                    type="number"
                    :pre-val="ruleItem.value"
                  >
                  </hlx-input>
                  <div
                    v-else-if="ruleItem.showType == 'double-number'"
                    style="display: flex; flex-direction: column; gap: 10px"
                  >
                    <hlx-input
                      v-model:value="ruleItem.values[0]"
                      type="number"
                      :pre-val="ruleItem.values[0]"
                    >
                    </hlx-input>
                    <hlx-input
                      v-model:value="ruleItem.values[1]"
                      type="number"
                      :pre-val="ruleItem.values[1]"
                    >
                    </hlx-input>
                  </div>
                  <div
                    v-else-if="ruleItem.showType == 'double-date'"
                    style="display: flex; flex-direction: column; gap: 10px"
                  >
                    <hlx-input
                      v-model:value="ruleItem.values[0]"
                      type="date"
                      icon="calendar-regular"
                      icon-position="right"
                      :pre-val="ruleItem.values[0]"
                    >
                    </hlx-input>
                    <hlx-input
                      v-model:value="ruleItem.values[1]"
                      type="date"
                      icon="calendar-regular"
                      icon-position="right"
                      :pre-val="ruleItem.values[1]"
                    >
                    </hlx-input>
                  </div>
                </span>
                <div
                  v-if="visibilityRulePayload.length > 1"
                  style="
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                  "
                >
                  <span
                    style="color: #fc3232; cursor: pointer"
                    @click="deleteVisibilityRuleBlock(itemIndex)"
                    ><i class="icon-trash-regular"></i> &nbsp; Delete</span
                  >
                </div>
                <br />
                <hr />
              </span>
            </div>
            <div
              v-if="fieldData.others.isVisibilityRuleEnabled"
              style="
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
              "
            >
              <span
                style="color: #54bd95; cursor: pointer"
                @click="addOneMoreVisibilityRuleBlock"
                >+ Add condition</span
              >
            </div>
            <div
              v-if="fieldData.others.isVisibilityRuleEnabled"
              style="display: flex; flex-direction: column; gap: 16px"
            >
              <span>Then,</span>
              <hlx-select
                v-model:value="fieldData.others.visibilityRuleAction"
                :prop-value="'value'"
                label="name"
                :options="visibilityRuleActionTypeOptions"
                :pre-value="fieldData.others.visibilityRuleAction"
              ></hlx-select>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button
            class="secondary sm"
            @click="closeDrawer"
            >Cancel</hlx-button
          >
          <hlx-button
            v-if="editMode == false"
            class="primary sm"
            style="margin-left: 10px"
            :disabled="!canAdd"
            @click="addCustomItem"
            >Add</hlx-button
          >
          <hlx-button
            v-else-if="editMode == true && fieldData.fieldName !== ''"
            class="primary sm"
            style="margin-left: 10px"
            @click="editCustomItem"
            >Save</hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>
  <section>
    <section style="display: flex; font-size: 14px">
      <section
        class="cm-fields-left-panel responsiveSM"
        style="background-color: transparent; overflow-y: hidden; width: 220px"
      >
        <div
          class="left-panel-container"
          style="
            display: flex;
            padding: 20px 10px;
            cursor: pointer;
            align-items: center;
          "
          @click="previousLevel"
        >
          <i
            class="icon-arrow-left-regular"
            style="display: flex; position: relative; margin-right: 8px"
          ></i>
          <p>Back</p>
        </div>
        <span style="margin-top: 25px">
          <leftPanel
            :side-bar-data="section_data"
            @value-name="selectedValueFunc"
          ></leftPanel>
        </span>
      </section>
      <section style="width: 100%">
        <div
          class="title-bar"
          style="
            display: flex;
            padding: 26px 20px 0;
            align-items: center;
            justify-content: space-between;
          "
        >
          <span style="display: flex; flex-direction: column">
            <b style="margin-bottom: 8px">{{ newCurrentCard?.name }}</b>
            <span>{{ newCurrentCard?.description }}</span>
          </span>
          <span style="display: flex; justify-content: flex-end; gap: 8px">
            <hlx-search></hlx-search>
            <hlx-button
              class="primary sm custom-field-add-btn"
              @click="openAddFieldsPanel"
              ><i class="icon-plus-circle-regular"></i>&nbsp; Add
              field</hlx-button
            >
            <hlx-context-menu
              :top="containertop1"
              :left="containerleft1"
              :conditionvalue="150"
              :conditiondown="2"
              :conditionup="-38"
              :options="contextOptions"
              :show="showContextMenu"
              @chosen="fromAddContextMenu"
              @close-context-menu="showContextMenu = false"
            ></hlx-context-menu>
          </span>
        </div>
        <div
          style="
            padding: 20px;
            height: calc(100vh - 75px);
            display: flex;
            flex-direction: column;
            overflow-y: auto;
          "
        >
          <br />
          <div class="fields-container">
            <span
              v-for="(field, fieldIndex) in getFilteredConcattedFields(false)"
              :id="'custom-field-' + fieldIndex"
              :key="fieldIndex"
              draggable="true"
              @dragstart="dragRowStart(fieldIndex)"
              @dragover.prevent
              @dragleave="dragRowLeave(fieldIndex)"
              @dragend="dragRowEnd(fieldIndex)"
              @mouseover="showControls(fieldIndex)"
              @mouseleave="hideControls(fieldIndex)"
            >
              <span class="field-item">
                <span class="field-item-child">
                  <i class="icon-drag-vertical-filled"></i>
                  <span>{{ field.labels[userLanguage]?.label }}</span>
                  <span
                    v-if="field.required"
                    style="color: #ff5050"
                    >{{ ' * ' }}</span
                  >
                  <hlx-label class="primary sm">{{
                    field.displayType.charAt(0).toUpperCase() +
                    field.displayType.slice(1)
                  }}</hlx-label>
                  <div
                    v-if="field.system"
                    data-tooltip="This is a system field."
                    data-tooltip-location="bottom"
                  >
                    <i class="icon-check-circle-filled"></i>
                  </div>
                </span>
                <span
                  :id="'show-field-controls-' + fieldIndex"
                  class="show-field-on-hover"
                >
                  <i
                    class="icon-edit-regular"
                    @click="editField(fieldIndex, false)"
                  ></i>
                  <i
                    v-if="!field.system"
                    class="icon-trash-regular"
                    @click="removeFieldModal(fieldIndex, false)"
                  ></i>
                </span>
              </span>
            </span>
          </div>
        </div>
      </section>
    </section>
  </section>
</template>

<script>
import {
  DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from '@/config/axiosConfig';

import leftPanel from '@/components/leftpannelComponent.vue';
import { toRaw } from 'vue';

export default {
  components: {
    leftPanel,
  },
  data() {
    return {
      csvValues: '',
      authenticationMethods: [
        {
          name: 'No Auth',
        },
        {
          name: 'API Key',
        },
        {
          name: 'Basic Auth',
        },
        {
          name: 'Bearer Token',
        },
      ],
      businessDatePreferences: [
        {
          name: 'Allow only business days',
          value: 'business-days-only',
          checked: false,
        },
        {
          name: "Default to today's date",
          value: 'default-today',
          checked: false,
        },
      ],
      datePreferences: [
        { name: 'Allow all days', value: 'all-days' },
        { name: 'Allow future dates only', value: 'future-dates' },
        { name: 'Allow past dates only', value: 'past-dates' },
        { name: 'Apply date range', value: 'range' },
      ],
      definitionTabs: [
        {
          name: 'Configuration',
          value: 'Configuration',
        },
        {
          name: 'Visibility rule',
          value: 'Visibility rule',
        },
        {
          name: 'Field validation',
          value: 'Field validation',
        },
      ],
      selectedDefinitionTab: 'Configuration',
      intellisenseData: {
        schema: [],
        api: [{ label: 'API()' }],
        email: [{ label: 'Email()' }],
        keywords: [
          { label: 'if' },
          { label: 'then' },
          { label: 'else' },
          { label: 'and' },
          { label: 'or' },
          { label: 'function' },
          { label: 'filter' },
          { label: 'reduce' },
          { label: 'map' },
          { label: 'Date' },
          { label: 'Math' },
          { label: 'slice' },
          { label: 'substr' },
          { label: 'concat' },
          { label: 'true' },
          { label: 'false' },
        ],
        methods: {
          array: [
            {
              label: 'filter',
              syntax:
                'array.filter(function(currentValue, index, arr), thisValue)',
            },
            {
              label: 'reduce',
              syntax:
                'array.reduce(function(total, currentValue, currentIndex, arr), initialValue)',
            },
            {
              label: 'map',
              syntax:
                'array.map(function(currentValue, index, arr), thisValue)',
            },
          ],
          date: [
            {
              label: 'getDay',
              syntax: 'getDay()',
            },
            {
              label: 'getDate',
              syntax: 'getDate()',
            },
            {
              label: 'getTime',
              syntax: 'getTime()',
            },
            {
              label: 'getMonth',
              syntax: 'getMonth()',
            },
            {
              label: 'getFullYear',
              syntax: 'getFullYear()',
            },
            {
              label: 'setDate',
              syntax: 'setDate()',
            },
            {
              label: 'setTime',
              syntax: 'setTime()',
            },
            {
              label: 'setMonth',
              syntax: 'setMonth()',
            },
            {
              label: 'setFullYear',
              syntax: 'setFullYear()',
            },
          ],
          integer: [
            {
              label: 'random',
              syntax: 'Math.random()',
            },
            {
              label: 'round',
              syntax: 'Math.round(x)',
            },
          ],
          string: [
            { label: 'charAt', syntax: 'string.charAt(index)' },
            { label: 'slice', syntax: 'string.slice(start, end)' },
          ],
        },
      },
      visibilityRuleReset: 0,
      canAdd: false,
      notification: {},
      deleteIndex: null,
      deleteVal: null,
      deleteName: null,
      deleteModal: false,
      resetDropdown: 0,
      fieldDataForDropdowns: [],
      visibilityRulePayload: [
        {
          fieldName: '',
          values: [],
          value: '',
          operator: '',
          options: [],
          displayType: '',
          optionRef: '',
          showType: '',
        },
      ],
      visibilityRuleOperatorTypeOptions: [
        {
          name: 'Is not empty',
          value: 'Is not empty',
          displayType: ['input', 'texteditor'],
          show: '',
        },
        {
          name: 'Is empty',
          value: 'Is empty',
          displayType: ['input', 'texteditor'],
          show: '',
        },
        {
          name: 'Contains',
          value: 'Contains',
          displayType: ['input'],
          show: 'input',
        },
        {
          name: 'Does not contain',
          value: 'Does not contain',
          displayType: ['input'],
          show: 'input',
        },
        {
          name: 'Matches regex',
          value: 'Matches regex',
          displayType: ['input'],
          show: 'input',
        },
        {
          name: 'Length >',
          value: 'Length >',
          displayType: ['input', 'texteditor'],
          show: 'number',
        },
        {
          name: 'Length <',
          value: 'Length <',
          displayType: ['input', 'texteditor'],
          show: 'number',
        },
        {
          name: 'Contains keyword',
          value: 'Contains keyword',
          displayType: ['textarea', 'texteditor'],
          show: 'input',
        },
        {
          name: 'Does not contain keyword',
          value: 'Does not contain keyword',
          displayType: ['textarea', 'texteditor'],
          show: 'input',
        },
        {
          name: 'Is selected',
          value: 'Is selected',
          displayType: ['dropdown', 'radio', 'multiselect'],
          show: 'dropdown',
        },
        {
          name: 'Is selected date',
          value: 'Is selected date',
          displayType: ['date'],
          show: 'date',
        },
        {
          name: 'Is not selected',
          value: 'Is not selected',
          displayType: ['dropdown', 'radio', 'multiselect'],
          show: 'dropdown',
        },
        {
          name: 'Is equal to',
          value: 'Is equal to',
          displayType: [
            'dropdown',
            'radio',
            'multiselect',
            'number',
            'formula',
          ],
          show: 'input',
        },
        {
          name: 'Is one of',
          value: 'Is one of',
          displayType: ['multiselect'],
          show: 'dropdown',
        },
        {
          name: 'Is not one of',
          value: 'Is not one of',
          displayType: ['multiselect'],
          show: 'dropdown',
        },
        {
          name: 'Is checked',
          value: 'Is checked',
          displayType: ['checkbox'],
          show: 'dropdown',
        },
        {
          name: 'Is unchecked',
          value: 'Is unchecked',
          displayType: ['checkbox'],
          show: 'dropdown',
        },
        {
          name: 'Is null',
          value: 'Is null',
          displayType: ['date', 'address'],
          show: '',
        },
        {
          name: 'Is not null',
          value: 'Is not null',
          displayType: ['date', 'address'],
          show: '',
        },
        {
          name: 'Is before',
          value: 'Is before',
          displayType: ['date'],
          show: 'date',
        },
        {
          name: 'Is after',
          value: 'Is after',
          displayType: ['date'],
          show: 'date',
        },
        {
          name: 'Is greater than',
          value: 'Is greater than',
          displayType: ['number', 'currency', 'formula'],
          show: 'number',
        },
        {
          name: 'Is greater than or equal to',
          value: 'Is greater than or equal to',
          displayType: ['number', 'currency', 'formula'],
          show: 'number',
        },
        {
          name: 'Is less than or equal to',
          value: 'Is less than or equal to',
          displayType: ['number', 'currency', 'formula'],
          show: 'number',
        },
        {
          name: 'Is not equal to',
          value: 'Is not equal to',
          displayType: ['number', 'currency', 'formula'],
          show: 'number',
        },
        {
          name: 'Is within range (X to Y)',
          value: 'Is within range (X to Y)',
          displayType: ['number', 'currency', 'formula'],
          show: 'double-number',
        },
        {
          name: 'Is greater than equal to specific date',
          value: 'Is greater than equal to specific date',
          displayType: ['date'],
          show: 'date',
        },
        {
          name: 'Greater than or equal to X days from today',
          value: 'Greater than or equal to X days from today',
          displayType: ['date'],
          show: 'number',
        },
        {
          name: 'Greater than X days from today',
          value: 'Greater than X days from today',
          displayType: ['date'],
          show: 'number',
        },
        {
          name: 'Is less than',
          value: 'Is less than',
          displayType: ['number', 'currency', 'formula'],
          show: 'number',
        },
        {
          name: 'Is less than equal to specific date',
          value: 'Is less than equal to specific date',
          displayType: ['date'],
          show: 'date',
        },
        {
          name: 'Less than or equal to X days from today',
          value: 'Less than or equal to X days from today',
          displayType: ['date'],
          show: 'number',
        },
        {
          name: 'Less than X days from today',
          value: 'Less than X days from today',
          displayType: ['date'],
          show: 'number',
        },
        {
          name: 'Equal to X day(s) from today',
          value: 'Equal to X day(s) from today',
          displayType: ['date'],
          show: 'number',
        },
        {
          name: 'Is within X day(s) from today',
          value: 'Is within X day(s) from today',
          displayType: ['date'],
          show: 'number',
        },
        {
          name: 'Is between (X and Y days) from today',
          value: 'Is between (X and Y days) from today',
          displayType: ['date'],
          show: 'double-number',
        },
        {
          name: 'Is within date range',
          value: 'Is within date range',
          displayType: ['date'],
          show: 'double-date',
        },
        {
          name: 'Is valid email',
          value: 'Is valid email',
          displayType: ['email'],
          show: '',
        },
        {
          name: 'Is not unique',
          value: 'Is not unique',
          displayType: ['email', 'address'],
          show: '',
        },
        {
          name: 'Contains domain',
          value: 'Contains domain',
          displayType: ['email'],
          show: 'input',
        },
        {
          name: 'Does not contain domain',
          value: 'Does not contain domain',
          displayType: ['email'],
          show: 'input',
        },
        {
          name: 'Is complete',
          value: 'Is complete',
          displayType: ['address'],
          show: '',
        },
        {
          name: 'Is valid URL',
          value: 'Is valid URL',
          displayType: ['url'],
          show: '',
        },
        {
          name: 'Is secure (https)',
          value: 'Is secure (https)',
          displayType: ['url'],
          show: '',
        },
        {
          name: 'Is not secure (http)',
          value: 'Is not secure (http)',
          displayType: ['url'],
          show: '',
        },
        {
          name: 'Matches domain',
          value: 'Matches domain',
          displayType: ['url'],
          show: 'input',
        },
        {
          name: 'Length <',
          value: 'Length <',
          displayType: ['url'],
          show: 'number',
        },
        {
          name: 'Is uploaded',
          value: 'Is uploaded',
          displayType: ['profile-image-upload'],
          show: '',
        },
        {
          name: 'Matches file type',
          value: 'Matches file type',
          displayType: ['profile-image-upload'],
          show: 'input',
        },
        {
          name: 'Matches file size limit',
          value: 'Matches file size limit',
          displayType: ['profile-image-upload'],
          show: 'number',
        },
      ],
      visibilityRuleConditionTypeOptions: [
        {
          name: 'All',
          value: 'All',
        },
        {
          name: 'Any',
          value: 'Any',
        },
      ],
      visibilityRuleActionTypeOptions: [
        {
          name: 'Show field',
          value: 'show',
        },
        {
          name: 'Hide field',
          value: 'hide',
        },
      ],
      userLanguage: 'en',
      lookupModules: [
        {
          module: 'Accounts',
          instance: 'MLTAxiosInstance',
          filterPayload: {
            type: 'filter-search',
            module: 'hierarchyaccounts',
            isPaginated: true,
            paginatedQuery: {
              rowsPerPage: 10,
              currentPage: 1,
            },
            isNeedToBeSort: false,
            sortFieldAndOrder: {},
            limit: 10,
            filterQuery: {
              '@baseType': 'customer',
            },
            searchQuery: '',
          },
          url: '/util/filterData',
        },
        {
          module: 'Contacts',
          instance: 'MLTAxiosInstance',
          filterPayload: {
            company: 'developerHalleyx',
            type: 'filter-search',
            module: 'hierarchyaccounts',
            isPaginated: true,
            paginatedQuery: {
              rowsPerPage: 5,
              currentPage: 1,
            },
            isNeedToBeSort: true,
            sortFieldAndOrder: {},
            limit: 2,
            filterQuery: {
              '@baseType': {
                $ne: 'supplier',
              },
            },
            searchQuery: '',
          },
          url: '/util/filterData',
        },
        {
          module: 'Residential contacts',
          instance: 'MLTAxiosInstance',
          filterPayload: {
            type: 'filter-search',
            module: 'contacts',
            isPaginated: true,
            paginatedQuery: {
              rowsPerPage: 10,
              currentPage: 1,
            },
            isNeedToBeSort: false,
            sortFieldAndOrder: {},
            limit: 10,
            filterQuery: {
              '@baseType': 'residential-customer',
            },
            searchQuery: '',
          },
          url: '/util/filterData',
        },
        {
          module: 'Sales lead',
          instance: 'MLTAxiosInstance',
          filterPayload: {
            type: 'filter-search',
            module: 'saleslead',
            isPaginated: true,
            paginatedQuery: {
              rowsPerPage: 10,
              currentPage: 1,
            },
            isNeedToBeSort: false,
            sortFieldAndOrder: {},
            limit: 10,
            filterQuery: {
              'role.name': 'Account executive',
              name: {
                $regex: '',
                $options: 'i',
              },
            },
            searchQuery: '',
          },
          url: '/util/filterData',
        },
      ],
      lookupFieldTypes: [
        {
          name: 'Input',
          value: 'input',
        },
        {
          name: 'Dropdown',
          value: 'dropdown',
        },
      ],
      addressAPIOptions: [
        {
          name: 'Google maps API',
          value: 'Google maps API',
        },
        {
          name: 'Canada post API',
          value: 'Canada post API',
        },
      ],
      userRoles: [],
      displayTypeOptions: [
        { name: 'Address', value: 'address' },
        { name: 'Checkbox', value: 'checkbox' },
        { name: 'Currency', value: 'currency' },
        { name: 'Date', value: 'date' },
        { name: 'Dropdown', value: 'dropdown' },
        { name: 'Email', value: 'email' },
        { name: 'Formula', value: 'formula' },
        { name: 'Header', value: 'header' },
        { name: 'Lookup', value: 'lookup' },
        { name: 'Multi line text', value: 'textarea' },
        { name: 'Multiselect', value: 'multiselect' },
        { name: 'Number', value: 'number' },
        { name: 'Phone number', value: 'phonenumber' },
        { name: 'Radio select', value: 'radio' },
        { name: 'Single line text', value: 'input' },
        { name: 'Status', value: 'status' },
        { name: 'Text editor', value: 'texteditor' },
        { name: 'Upload - profile picture', value: 'profile-image-upload' },
        { name: 'URL', value: 'url' },
        { name: 'User', value: 'user' },
      ],
      contextOptions: [
        {
          label: 'Choose an existing field',
          value: 'existingfield',
          icon: 'icon-plus-circle-regular',
        },
        {
          label: 'Add a new field',
          value: 'newfield',
          icon: 'icon-plus-circle-regular',
        },
      ],
      containerleft1: 0,
      containertop1: 0,
      showContextMenu: false,
      refDataKey: 0,
      dragRefDataStartIndex: null,
      dragRefDataEndIndex: null,
      newRefData: [
        {
          value: '',
          name: '',
          english: '',
          type: 'String',
          required: false,
          default: false,
        },
      ],
      editMode: false,
      fieldData: {
        isRef: false,
        required: false,
        hasTooltip: false,
        tooltipEnglish: '',
        tooltipFrench: '',
        show: true,
        labels: {
          en: {
            label: '',
            name: 'English',
            tooltip: '',
          },
        },
        others: {
          businessDatePreference: [
            {
              name: 'Allow only business days',
              value: 'business-days-only',
              checked: true,
            },
            {
              name: "Default to today's date",
              value: 'default-today',
              checked: false,
            },
          ],
          customDatePreference: {
            preference: '',
            range: { from: '', to: '' },
          },
          userRole: [],
          readOnly: false,
          lookup: {
            minCharToSearch: 1,
            maxResultToDisplay: 5,
            module: '',
            instance: '',
            fieldType: 'input',
            filterPayload: {},
            url: '',
            lookupProp: '',
            isDataRemapEnabled: false,
            remapData: '',
          },
          addressAPI: {
            authType: '',
            token: '',
            userName: '',
            password: '',
            type: 'canada post',
            key: '',
          },
          isVisibilityRuleEnabled: false,
          ruleIdReference: '',
          ruleData: '',
          isVisible: true,
          visibilityRulePayload: [
            {
              fieldName: '',
              values: [],
              value: '',
              operator: '',
              options: [],
              displayType: '',
              optionRef: '',
              showType: '',
            },
          ],
          visibilityRuleAction: 'show',
          visibilityRuleCondition: 'All',
        },
      },
      editIndex: null,
      originalFieldData: [],
      selectedTab: 'overview',
      concattedFields: [],
      section_data: {
        main: [],
      },
      systemGeneratedFieldsData: {},
      customGeneratedFieldsData: {},
      showAddFieldPanel: false,
      userLanguages: {},
    };
  },
  watch: {
    '$store?.getters?.loginTenantDetails?.languageAbility': {
      handler(val) {
        this.userLanguages = val;
      },
      immediate: true,
      deep: true,
    },
    fieldData: {
      handler(val) {
        if (!this.editMode) {
          if (
            val.fieldName !== '' &&
            val.labels[this.userLanguage] !== undefined &&
            val.labels[this.userLanguage]?.label !== '' &&
            val.displayType !== '' &&
            !this.isFieldNameInvalid &&
            !this.isLabelNameInvalid
          ) {
            this.canAdd = true;
          } else {
            this.canAdd = false;
          }
        }
      },
      immediate: true,
      deep: true,
    },
    visibilityRulePayload: {
      handler(val) {
        console.log(val, this.fieldData, 'visibilityRulePayload');
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    await this.$store.dispatch('loginTenantDetails');
    let navigationData = await UPCAxiosInstance.get('/customHierarchy');
    let data = this.findObjectByAttribute(
      navigationData.data[0].module[0].main,
      'customFieldModule',
      this.$route.params.module
    );
    //console.log(data, 'dilsha');

    this.section_data.main = data.sections;

    let refDataFilterPayload = {
      type: 'filter',
      module: 'reference',
      isPaginated: false,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: false,
      sortFieldAndOrder: {
        updatedAt: -1,
      },
      limit: 10,
      filterQuery: {},
      searchQuery: '',
      company: 'developerHalleyx',
    };
    let refData = await DMNAxiosInstance.post(
      'util/filterData',
      refDataFilterPayload
    );
    //console.log(refData, 'devRox');

    this.referenceDataOptions = refData?.data?.data?.data;

    let savedFields = await UPCAxiosInstance.get(
      `/customSchema/getSchema?module=${this.$route.params.module}`
    );
    this.originalFieldData = JSON.parse(JSON.stringify(savedFields.data));
    savedFields = savedFields.data.schema;
    this.intellisenseData.schema = savedFields;
    console.log(this.intellisenseData, 'intelll');

    this.intellisenseData.schema = Object.entries(
      this.intellisenseData.schema
    ).map(([key, value]) => ({
      label: key,
      type: value.type !== 'Mixed' ? value.type : 'Array',
    }));
    this.intellisenseData.schema.forEach((i) => {
      return i.label != '';
    });

    let currentSectionItems = await UPCAxiosInstance.get('/section');
    currentSectionItems =
      currentSectionItems.data[0].section[this.selectedTab.toLowerCase()];
    //console.log(
    //   savedFields,
    //   currentSectionItems,
    //   this.getFieldItems(currentSectionItems, savedFields),
    //   'savedFields 01'
    // );
    currentSectionItems;

    const filteredData = Object.fromEntries(
      Object.entries(savedFields).filter(
        ([, value]) =>
          value.section &&
          value.section.includes(this.selectedTab.toLowerCase())
      )
    );
    // this.systemGeneratedFieldsData = await this.getFieldItems(
    //   currentSectionItems,
    //   savedFields
    // );
    this.systemGeneratedFieldsData = filteredData;

    this.concattedFieldsArray();
    this.userLanguages?.forEach((item) => {
      this.fieldData.labels[item.code] = {
        label: '',
        tooltip: '',
        name: item.name,
      };
    });
    // Object.entries(
    //   this.userLanguages
    // )?.forEach(([code, name]) => {
    //   this.fieldData.labels[code] = {
    //     label: '',
    //     tooltip: '',
    //     name: name,
    //   };
    // });
    let roles = await MLTAxiosInstance.get('partyRole?size=1&limit=1000');
    this.userRoles = roles.data;
    this.userRoles.sort((a, b) =>
      a.role.toLowerCase() > b.role.toLowerCase()
        ? 1
        : b.role.toLowerCase() > a.role.toLowerCase()
        ? -1
        : 0
    );
    console.log(this.userRoles, 'this.userRoles');
  },
  methods: {
    colorValue(e, item) {
      item.hexCode = e
    },
    addCsvValues() {
      let arr = this.parseCSVString(this.csvValues);
      if (this.newRefData[0].value == '') {
        this.newRefData = arr;
      } else {
        this.newRefData = [...this.newRefData, ...arr];
      }
      this.csvValues = '';
    },
    parseCSVString(csvString) {
      // Trim the string and split by commas
      const values = csvString
        .split(',')
        .map((item) => item.trim())
        .filter(Boolean);

      // Transform each value into the desired object structure
      return values.map((value) => ({
        value: value,
        name: '',
        english: '',
        type: 'String',
        required: false,
        default: false,
      }));
    },
    customDatePreferenceRange(val) {
      this.fieldData.others.customDatePreference.range = val;
    },
    chosenDefinitionTab(e) {
      this.selectedDefinitionTab = e.name;
    },
    selectedLookupModule($event) {
      console.log($event, 'lookupi');
      this.fieldData.others.lookup.filterPayload = $event.filterPayload;
      this.fieldData.others.lookup.instance = $event.instance;
      this.fieldData.others.lookup.module = $event.module;
      this.fieldData.others.lookup.url = $event.url;
    },
    selectedOperatorObject(e, item) {
      item.showType = e.show;
    },
    getVisibilityRuleOperatorTypeOptions(ruleItem) {
      let arr = this.visibilityRuleOperatorTypeOptions.filter((i) => {
        return i?.displayType?.includes(ruleItem?.displayType);
      });
      console.log(
        'getVisibilityRuleOperatorTypeOptions',
        arr.some((item) => item.displayType.includes(ruleItem?.operator)),
        ruleItem.operator
      );
      if (arr.some((item) => item.displayType.includes(ruleItem?.operator))) {
        // do nothing
      } else {
        // ruleItem.operator = arr[0].value
      }
      return arr;
    },
    deleteVisibilityRuleBlock(id) {
      this.visibilityRulePayload.splice(id, 1);
    },
    async setFirstItemAsDefault(val) {
      console.log('default value called');

      val.displayType = this.fieldDataForDropdowns[0]?.displayType;

      return this.fieldDataForDropdowns[0]?.fieldName;
    },
    async selectedFieldItem(e, val) {
      console.log('selected value called');
      val.displayType = e.displayType;
      val.optionRef = e.optionRef;
      let dropdownData;
      if (
        val.displayType == 'dropdown' ||
        val.displayType == 'radio' ||
        val.displayType == 'multiselect'
      ) {
        const response = await DMNAxiosInstance.get(
          `mapRef/collection/${val.optionRef}`
        );

        dropdownData = response?.data?.data;
        val.options = dropdownData;
      }
    },
    selectedValues(e, item) {
      // console.log('visibilityRulePayload dpd',e, item, item.value);

      item.values = e.map((item) => item.name);
    },
    selectedRoles(e, i) {
      console.log('visibilityRulePayload dpd', e, i);
      const roles = e.map((item) => item.role);
      i.userRole = roles;
      return i;
    },
    getOptionsWithFieldName() {
      let arr = [];
      arr = this.getFilteredConcattedFields(false);
      arr.forEach((i) => {
        i.name = i?.labels?.en?.label;
      });
      return arr;
    },
    addOneMoreVisibilityRuleBlock() {
      this.visibilityRulePayload.push({
        fieldName: '',
        values: [],
        value: '',
        operator: '',
        options: [],
        displayType: '',
        optionRef: '',
        showType: '',
      });
    },
    blurFieldName() {
      this.fieldData.fieldName = this.toCamelCaseForMongo(
        this.fieldData.fieldName
      );
      this.validateFieldName();
    },
    validateFieldName() {
      // Convert the reactive payload to raw data
      const rawPayload = Array.isArray(this.getFilteredConcattedFields(false))
        ? this.getFilteredConcattedFields(false).map((item) => toRaw(item))
        : [];
      console.log(
        this.isFieldNameInvalid,
        this.getFilteredConcattedFields(false),
        this.fieldData.fieldName,
        'isFieldNameInvalid'
      );
      this.isFieldNameInvalid = rawPayload.some(
        (item) => item.fieldName === this.fieldData.fieldName
      );
    },
    validateLabelName() {
      // Convert the reactive payload to raw data
      const rawPayload = Array.isArray(this.getFilteredConcattedFields(false))
        ? this.getFilteredConcattedFields(false).map((item) => toRaw(item))
        : [];
      console.log(
        this.isFieldNameInvalid,
        this.getFilteredConcattedFields(false),
        this.fieldData.fieldName,
        'isFieldNameInvalid'
      );
      this.isLabelNameInvalid = rawPayload.some(
        (item) => item.labels.en.label === this.fieldData.labels.en.label
      );
    },
    toCamelCaseForMongo(input) {
      // Remove all non-alphanumeric characters except underscores
      const sanitizedInput = input?.replace(/[^a-zA-Z0-9_]/g, ' ');
      if (sanitizedInput !== undefined) {
        // Convert to camel case
        const camelCaseString = sanitizedInput
          .split(/\s+/) // Split by spaces
          .map((word, index) =>
            index === 0
              ? word.toLowerCase()
              : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join('');

        // Ensure it starts with a letter or underscore for MongoDB compatibility
        if (!/^[a-zA-Z_]/.test(camelCaseString)) {
          return `_${camelCaseString}`;
        }

        return camelCaseString;
      }
    },
    removeFieldModal(index, val) {
      val;
      let filtered = this.concattedFields.filter((item) => {
        return item.show === !val;
      });
      this.deleteIndex = index;
      this.deleteVal = val;
      this.deleteName = filtered[index]?.labels[this.userLanguage]?.label;
      this.deleteModal = true;
    },
    async removeField(index, val) {
      let filtered = this.concattedFields.filter((item) => {
        return item.show === !val;
      });
      // let obj = this.fieldData;
      let payload = {};
      payload = this.originalFieldData;
      payload.id = payload._id;
      delete payload._id;
      payload.schema[filtered[index].fieldName] = {};
      // payload.labels?[userLanguage]?.label = obj.labels.en.label
      // payload.schema[obj.fieldName].section = [];
      // payload.schema[obj.fieldName].section.push(this.selectedTab.toLowerCase());
      let patchData = await UPCAxiosInstance.patch('customSchema', payload);
      patchData;
      filtered.splice(index, 1);
      this.deleteModal = false;
      if (patchData.status == 200) {
        this.notification = {
          type: 'success',
          message: `Done! "${this.deleteName}" field is removed from the list`,
        };
      } else {
        this.notification = {
          type: 'error',
          message: `Something went wrong! try again later`,
        };
      }
      let savedFields = await UPCAxiosInstance.get(
        `/customSchema/getSchema?module=${this.$route.params.module}`
      );
      this.originalFieldData = JSON.parse(JSON.stringify(savedFields.data));
      savedFields = savedFields.data.schema;

      let currentSectionItems = await UPCAxiosInstance.get('/section');
      currentSectionItems =
        currentSectionItems.data[0].section[this.selectedTab.toLowerCase()];
      //console.log(
      //   savedFields,
      //   currentSectionItems,
      //   this.getFieldItems(currentSectionItems, savedFields),
      //   'savedFields 01'
      // );
      currentSectionItems;

      const filteredData = Object.fromEntries(
        Object.entries(savedFields).filter(
          ([, value]) =>
            value.section &&
            value.section.includes(this.selectedTab.toLowerCase())
        )
      );
      // this.systemGeneratedFieldsData = await this.getFieldItems(
      //   currentSectionItems,
      //   savedFields
      // );
      this.systemGeneratedFieldsData = filteredData;

      this.concattedFieldsArray();
    },
    concattedFieldsArray() {
      let concat = {
        ...this.systemGeneratedFieldsData,
        ...this.customGeneratedFieldsData,
      };
      //console.log('devRoz concat', concat);
      // let filteredConcat = concat
      let filteredConcat = Object.fromEntries(
        Object.entries(concat).filter(
          ([, value]) =>
            value.displayType != undefined &&
            value.displayType != '' &&
            value.displayType != '-'
        )
      );
      this.concattedFields = this.convertObjectToArray(
        JSON.parse(JSON.stringify(filteredConcat))
      );
      this.concattedFields.forEach((item, index) => {
        item.id = index;
      });
      //console.log('final rox', this.concattedFields);
    },
    convertObjectToArray(obj) {
      return Object.keys(obj).map((key) => ({
        fieldName: key,
        ...obj[key], // Spread the properties directly into the object
      }));
    },
    findObjectByAttribute(obj, key, value) {
      // Base case: If the current object has the key with the specified value
      if (obj[key] === value) {
        return obj;
      }

      // Recursive case: If obj is an object or array, search its children
      if (typeof obj === 'object' && obj !== null) {
        for (const childKey in obj) {
          const child = obj[childKey];

          // If child is an array, iterate over its elements
          if (Array.isArray(child)) {
            for (const element of child) {
              const result = this.findObjectByAttribute(element, key, value);
              if (result) {
                return result;
              }
            }
          }
          // If child is an object, recurse into it directly
          else if (typeof child === 'object') {
            const result = this.findObjectByAttribute(child, key, value);
            if (result) {
              return result;
            }
          }
        }
      }

      // Return null if the object is not found
      return null;
    },
    getFilteredConcattedFields(val) {
      let arr = [];
      let arr2 = [];
      arr = this.concattedFields.filter((item) => {
        return item.show === !val;
      });
      arr2 = JSON.parse(JSON.stringify(arr));
      arr2.forEach((i) => {
        i.name = i?.labels?.en?.label;
      });
      this.fieldDataForDropdowns = arr2;
      if (val) {
        return [];
      }
      return this.concattedFields.filter((item) => {
        return item.show === !val;
      });
    },
    fromAddContextMenu(val) {
      //val
      //console.log(val, 'val');

      if (val == 'Add a new field') {
        this.showAddFieldPanel = true;
        this.fieldData.displayType = '';
        this.resetDropdown += 1;
        this.userLanguages?.forEach((item) => {
          this.fieldData.labels[item.code] = {
            label: '',
            tooltip: '',
            name: item.name,
          };
        });
        // Object.entries(this.userLanguages)?.forEach(([code, name]) => {
        //   this.fieldData.labels[code] = {
        //     label: '',
        //     tooltip: '',
        //     name: name,
        //   };
        // });
        this.newRefData = [
          {
            value: '',
            name: '',
            english: '',
            type: 'String',
            required: false,
            default: false,
          },
        ];
      }
    },
    setDefaultNewRefData(id) {
      this.newRefData.forEach((item) => {
        item.default = false;
      });
      this.newRefData[id].default = true;
    },
    deleteNewRefData(id) {
      this.newRefData.splice(id, 1);
    },
    addNewRefData() {
      const obj = {
        value: '',
        english: '',
        type: 'String',
        required: false,
        default: false,
      };
      this.newRefData.push(JSON.parse(JSON.stringify(obj)));
    },
    checkIsref(val) {
      if (!val.checked) {
        if (this.editMode) {
          this.fieldData.optionRef = this.fieldData.fieldName;
        } else {
          this.fieldData.optionRef = '';
        }
        this.newRefData = [
          {
            value: '',
            name: '',
            english: '',
            type: 'String',
            required: false,
            default: false,
          },
        ];
      }
    },
    showHideField(val) {
      //console.log(val, 'showval');

      this.fieldData.show = !val.checked;
    },
    selectedReferenceData(val) {
      //console.log(val, 'opref');

      this.fieldData.optionRef = val?.name;
    },
    async editField(index, val) {
      let filtered = this.concattedFields.filter((item) => {
        return item.show === !val;
      });
      this.fieldData = JSON.parse(JSON.stringify(filtered[index]));
      if (this.fieldData.displayType == 'date') {
        this.businessDatePreferences =
          this.fieldData.others.businessDatePreference;
      }
      // const en = JSON.parse(JSON.stringify(this.fieldData.labels.en));
      // this.fieldData.labels = JSON.parse(JSON.stringify({}));
      // this.fieldData.labels.en = JSON.parse(JSON.stringify(en));
      if (this.fieldData.others?.visibilityRulePayload?.length > 0) {
        this.visibilityRulePayload = JSON.parse(
          JSON.stringify(this.fieldData.others?.visibilityRulePayload)
        );
      } else {
        this.visibilityRulePayload = [
          {
            fieldName: '',
            values: [],
            value: '',
            operator: '',
            options: [],
            displayType: '',
            optionRef: '',
            showType: '',
          },
        ];
      }

      console.log(
        this.fieldData.isRef,
        this.fieldData.displayType,
        'this.fieldData',
        this.fieldData
      );

      if (
        (this.fieldData?.displayType?.toLowerCase() == 'dropdown' ||
          this.fieldData?.displayType?.toLowerCase() == 'radio' ||
          this.fieldData?.displayType?.toLowerCase() == 'status' ||
          this.fieldData?.displayType?.toLowerCase() == 'multiselect') &&
        !this.fieldData.isRef
      ) {
        let filterData = {
          type: 'filter',
          module: 'referencedata',
          collection: this.fieldData.optionRef,
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 1000,
            currentPage: 1,
          },
          isNeedToBeSort: false,
          sortFieldAndOrder: {},
          limit: 1000,
          filterQuery: {},
          searchQuery: '',
          company: 'developerHalleyx',
        };
        let data = await DMNAxiosInstance.post(`/util/filterData`, filterData);
        //console.log(data, 'rasputin');

        this.newRefData = data?.data?.data?.data;
      }

      this.editMode = true;
      this.editIndex = filtered[index].id;
      this.showAddFieldPanel = true;
    },
    showControls(id) {
      document
        .getElementById('show-field-controls-' + id)
        ?.classList.add('show-controls');
    },
    hideControls(id) {
      document
        .getElementById('show-field-controls-' + id)
        ?.classList.remove('show-controls');
    },
    showControlsForHiddenFields(id) {
      document
        .getElementById('show-hidden-field-controls-' + id)
        .classList.add('show-controls');
    },
    hideControlsForHiddenFields(id) {
      document
        .getElementById('show-hidden-field-controls-' + id)
        .classList.remove('show-controls');
    },
    dragRowStart(i) {
      this.drag_start = i;
    },
    dragRowLeave(i) {
      this.drag_leave = i;
    },
    dragRowEnd() {
      this.dragging = false;
      // //console.log('devRoz swap', this.drag_start, this.drag_leave, this.concattedFields);

      const newData = this.swapObjectPositions(
        this.drag_start,
        this.drag_leave
      );
      newData;

      //console.log(newData);
    },
    dragRefDataStart(i) {
      this.dragRefDataStartIndex = i;
    },
    dragRefDataLeave(i) {
      this.dragRefDataEndIndex = i;
    },
    dragRefDataEnd() {
      const [obj1, obj2] = [
        this.newRefData[this.dragRefDataStartIndex],
        this.newRefData[this.dragRefDataEndIndex],
      ];

      // Swap the objects
      this.newRefData[this.dragRefDataStartIndex] = obj2;
      this.newRefData[this.dragRefDataEndIndex] = obj1;
    },
    swapObjectPositions(index1, index2) {
      // Destructure the objects at the given indices
      //console.log('devRoz in swap', index1, index2, this.concattedFields);

      const [obj1, obj2] = [
        this.concattedFields[index1],
        this.concattedFields[index2],
      ];

      // Swap the objects
      this.concattedFields[index1] = obj2;
      this.concattedFields[index2] = obj1;
      //console.log('devRoz after swap', index1, index2, this.concattedFields);
    },
    openAddFieldsPanel() {
      this.containerleft1 =
        document.querySelector('.custom-field-add-btn').getBoundingClientRect()
          .left - 90;
      this.containertop1 =
        document.querySelector('.custom-field-add-btn').getBoundingClientRect()
          .top + 40;
      this.showContextMenu = !this.showContextMenu;
    },
    closeDrawer() {
      this.showAddFieldPanel = false;
      this.fieldData = {
        isRef: false,
        required: false,
        hasTooltip: false,
        tooltipEnglish: '',
        tooltipFrench: '',
        show: true,
        displayType: '',
        labels: {
          en: {
            label: '',
            name: 'English',
            tooltip: '',
          },
        },
        others: {
          businessDatePreference: '',
          customDatePreference: {
            preference: '',
            range: { from: '', to: '' },
          },
          userRole: [],
          readOnly: false,
          lookup: {
            minCharToSearch: 1,
            maxResultToDisplay: 5,
            module: '',
            instance: '',
            fieldType: 'input',
            filterPayload: {},
            url: '',
            lookupProp: '',
            isDataRemapEnabled: false,
            remapData: '',
          },
          addressAPI: {
            authType: '',
            token: '',
            userName: '',
            password: '',
            type: 'canada post',
            key: '',
          },
          isVisibilityRuleEnabled: false,
          ruleIdReference: '',
          ruleData: '',
          visibilityRulePayload: [
            {
              fieldName: '',
              values: [],
              value: '',
              operator: '',
              options: [],
              displayType: '',
              optionRef: '',
              showType: '',
            },
          ],
          isVisible: true,
          visibilityRuleAction: 'show',
          visibilityRuleCondition: 'All',
        },
      };
      this.visibilityRulePayload = [
        {
          fieldName: '',
          values: [],
          value: '',
          operator: '',
          options: [],
          displayType: '',
          optionRef: '',
          showType: '',
        },
      ];
      this.userLanguages?.forEach((item) => {
        this.fieldData.labels[item.code] = {
          label: '',
          tooltip: '',
          name: item.name,
        };
      });
      // Object.entries(this.userLanguages)?.forEach(([code, name]) => {
      //   this.fieldData.labels[code] = {
      //     label: '',
      //     tooltip: '',
      //     name: name,
      //   };
      // });
      this.editMode = false;
    },
    async selectedValueFunc(val) {
      //console.log('devRoz', val);
      this.selectedTab = val?.toLowerCase();
      let savedFields = await UPCAxiosInstance.get(
        `/customSchema/getSchema?module=${this.$route.params.module}`
      );
      this.originalFieldData = JSON.parse(JSON.stringify(savedFields.data));
      savedFields = savedFields.data.schema;

      let currentSectionItems = await UPCAxiosInstance.get('/section');
      currentSectionItems =
        currentSectionItems.data[0].section[this.selectedTab.toLowerCase()];
      //console.log(
      //   savedFields,
      //   currentSectionItems,
      //   this.getFieldItems(currentSectionItems, savedFields),
      //   'savedFields 01'
      // );
      currentSectionItems;

      const filteredData = Object.fromEntries(
        Object.entries(savedFields).filter(
          ([, value]) =>
            value.section &&
            value.section.includes(this.selectedTab.toLowerCase())
        )
      );
      // this.systemGeneratedFieldsData = await this.getFieldItems(
      //   currentSectionItems,
      //   savedFields
      // );
      this.systemGeneratedFieldsData = filteredData;

      this.concattedFieldsArray();
      this.userLanguages?.forEach((item) => {
        this.fieldData.labels[item.code] = {
          label: '',
          tooltip: '',
          name: item.name,
        };
      });
      // Object.entries(
      //   this.userLanguages
      // )?.forEach(([code, name]) => {
      //   this.fieldData.labels[code] = {
      //     label: '',
      //     tooltip: '',
      //     name: name,
      //   };
      // });
      console.log();
    },
    getFieldItems(fieldsArray, payloadObject) {
      return fieldsArray?.reduce((filteredPayload, field) => {
        if (Object.prototype.hasOwnProperty.call(payloadObject, field)) {
          filteredPayload[field] = payloadObject[field];
        }
        return filteredPayload;
      }, {});
    },
    async addCustomItem() {
      let obj = this.fieldData;
      obj.required = this.fieldData.required;
      obj.id = this.concattedFields.length;

      try {
        if (
          !obj.isRef &&
          (
            obj?.displayType?.toLowerCase() == 'dropdown' ||
                obj?.displayType?.toLowerCase() == 'radio' ||
                obj?.displayType?.toLowerCase() == 'checkbox' ||
                obj?.displayType?.toLowerCase() == 'status' ||
                obj?.displayType?.toLowerCase() == 'multiselect')
        ) {
          let schema = [
            {
              name: obj.fieldName,
              type: 'manual',
              description: '',
              schema: {
                name: {
                  type: 'String',
                  required: false,
                },
                value: {
                  type: 'String',
                  required: false,
                },
                english: {
                  type: 'String',
                  required: false,
                },
                hexCode: {
                  type: 'String',
                  required: false,
                },
                default: {
                  type: 'String',
                  required: false,
                },
              },
            },
          ];
          let addSchema = await DMNAxiosInstance.post(
            'rule_engine/schema/add',
            schema
          );
          //console.log(addSchema, 'addSchema');

          this.newRefData.forEach((item) => {
            item.english = JSON.parse(JSON.stringify(item.value));
            item.name = JSON.parse(JSON.stringify(item.value));
          });
          let addData = await DMNAxiosInstance.post(
            `mapRef/addMany/${addSchema.data.data[0].name}`,
            this.newRefData
          );
          addData;
          //console.log(addData, 'addSchema');

          obj.optionRef = addSchema.data.data[0].name;
        }
      } catch (error) {
        //console.log(error);
      }

      let payload = {};
      payload = this.originalFieldData;
      payload.id = payload._id;
      delete payload._id;
      payload.schema[obj.fieldName] = obj;
      payload.schema[obj.fieldName].labels[this.userLanguage].label =
        obj.labels[this.userLanguage].label;
      payload.schema[obj.fieldName].section = [];
      payload.schema[obj.fieldName].section.push(
        this.selectedTab.toLowerCase()
      );
      let patchData = await UPCAxiosInstance.patch('customSchema', payload);
      patchData;
      console.log(patchData, 'notiii');
      if (patchData.status == 200) {
        this.notification = {
          type: 'success',
          message: `Done! New custom field is added to the list`,
        };
      } else {
        this.notification = {
          type: 'error',
          message: `Something went wrong! try again later`,
        };
      }
      this.concattedFields.push(obj);
      this.fieldData = {
        isRef: false,
        required: false,
        hasTooltip: false,
        tooltipEnglish: '',
        tooltipFrench: '',
        show: true,
        displayType: '',
        labels: {
          en: {
            label: '',
            name: 'English',
            tooltip: '',
          },
        },
        others: {
          businessDatePreference: '',
          customDatePreference: {
            preference: '',
            range: { from: '', to: '' },
          },
          userRole: [],
          readOnly: false,
          lookup: {
            minCharToSearch: 1,
            maxResultToDisplay: 5,
            module: '',
            instance: '',
            fieldType: 'input',
            filterPayload: {},
            url: '',
            lookupProp: '',
            isDataRemapEnabled: false,
            remapData: '',
          },
          addressAPI: {
            authType: '',
            token: '',
            userName: '',
            password: '',
            type: 'canada post',
            key: '',
          },
          isVisibilityRuleEnabled: false,
          ruleIdReference: '',
          ruleData: '',
          isVisible: true,
          visibilityRulePayload: [
            {
              fieldName: '',
              values: [],
              value: '',
              operator: '',
              options: [],
              displayType: '',
              optionRef: '',
              showType: '',
            },
          ],
          visibilityRuleAction: 'show',
          visibilityRuleCondition: 'All',
        },
      };
      this.userLanguages?.forEach((item) => {
        this.fieldData.labels[item.code] = {
          label: '',
          tooltip: '',
          name: item.name,
        };
      });
      // Object.entries(this.userLanguages)?.forEach(([code, name]) => {
      //   this.fieldData.labels[code] = {
      //     label: '',
      //     tooltip: '',
      //     name: name,
      //   };
      // });
      this.resetComponent += 1;
      this.closeDrawer();
      await this.updateLangSchemaByModule(this.$route.params.module);
      let savedFields = await UPCAxiosInstance.get(
        `/customSchema/getSchema?module=${this.$route.params.module}`
      );
      this.originalFieldData = JSON.parse(JSON.stringify(savedFields.data));
    },
    async editCustomItem() {
      let obj = this.fieldData;
      obj.required = this.fieldData.required;
      //console.log(
      //     this.originalFieldData,
      //     this.fieldData,
      //     this.editIndex,
      //     'devRoz editIndex'
      //   );
      this.concattedFields[this.editIndex] = JSON.parse(JSON.stringify(obj));
      let data = {};
      this.newRefData.forEach((item) => {
        console.log(item, 'ref data edited');

        item.english = JSON.parse(JSON.stringify(item.value));
        item.name = JSON.parse(JSON.stringify(item.value));
      });
      data.data = this.newRefData;
      if (
        (this.fieldData?.displayType?.toLowerCase() == 'dropdown' ||
          this.fieldData?.displayType?.toLowerCase() == 'radio' ||
          this.fieldData?.displayType?.toLowerCase() == 'multiselect') &&
        !this.fieldData.isRef &&
        data.data.length > 0 &&
        data.data[0].value !== ''
      ) {
        let filterData = {
          type: 'filter',
          module: 'referencedata',
          collection: this.fieldData.optionRef,
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 1000,
            currentPage: 1,
          },
          isNeedToBeSort: false,
          sortFieldAndOrder: {},
          limit: 1000,
          filterQuery: {},
          searchQuery: '',
          company: 'developerHalleyx',
        };
        let editRefData = await DMNAxiosInstance.post(
          `/util/filterData`,
          filterData
        );
        if (editRefData.data.message === 'Given collection is not exist') {
          try {
            if (
              !obj.isRef &&
              (obj.displayType == 'dropdown' ||
                obj.displayType == 'radio' ||
                obj.displayType == 'multiselect')
            ) {
              let schema = [
                {
                  name: obj.fieldName,
                  type: 'manual',
                  description: '',
                  schema: {
                    name: {
                      type: 'String',
                      required: false,
                    },
                    value: {
                      type: 'String',
                      required: false,
                    },
                    english: {
                      type: 'String',
                      required: false,
                    },
                    default: {
                      type: 'String',
                      required: false,
                    },
                  },
                },
              ];
              let addSchema = await DMNAxiosInstance.post(
                'rule_engine/schema/add',
                schema
              );
              //console.log(addSchema, 'addSchema');

              this.newRefData.forEach((item) => {
                console.log(item, 'ref data edited');

                item.english = JSON.parse(JSON.stringify(item.value));
                item.name = JSON.parse(JSON.stringify(item.value));
              });
              let addData = await DMNAxiosInstance.post(
                `mapRef/addMany/${addSchema.data.data[0].name}`,
                this.newRefData
              );
              addData;
              //console.log(addData, 'addSchema');

              obj.optionRef = addSchema.data.data[0].name;
            }
          } catch (error) {
            //console.log(error);
          }
        } else {
          let patchData = await DMNAxiosInstance.patch(
            `mapRef/updateManyCollection/${this.fieldData.fieldName}`,
            data
          );
          patchData;
        }
        console.log(editRefData.data.message, 'patchData without schema');
      }

      // console.log(
      //     this.originalFieldData,
      //     this.fieldData,
      //     this.editIndex,
      //     'devRoz editIndex'
      //   );
      this.fieldData.others.visibilityRulePayload = JSON.parse(
        JSON.stringify(this.visibilityRulePayload)
      );
      this.originalFieldData.schema[this.fieldData.fieldName] = JSON.parse(
        JSON.stringify(this.fieldData)
      );
      this.originalFieldData.schema[this.fieldData.fieldName].labels[
        this.userLanguage
      ].label = JSON.parse(
        JSON.stringify(this.fieldData.labels[this.userLanguage].label)
      );
      let copyOriginalFieldData = JSON.parse(
        JSON.stringify(this.originalFieldData)
      );
      copyOriginalFieldData.id = copyOriginalFieldData._id;
      let patchSchema = await UPCAxiosInstance.patch(
        'customSchema',
        copyOriginalFieldData
      );
      patchSchema;
      if (patchSchema.status == 200) {
        this.notification = {
          type: 'success',
          message: `Done! "${
            this.fieldData.labels[this.userLanguage].label
          }" field is updated successfully`,
        };
      } else {
        this.notification = {
          type: 'error',
          message: `Something went wrong! try again later`,
        };
      }
      //console.log(patchSchema, 'patchData');
      this.closeDrawer();
      this.fieldData = {
        isRef: false,
        required: false,
        hasTooltip: false,
        tooltipEnglish: '',
        tooltipFrench: '',
        show: true,
        displayType: '',
        labels: {
          en: {
            label: '',
            name: 'English',
            tooltip: '',
          },
        },
        others: {
          businessDatePreference: '',
          customDatePreference: {
            preference: '',
            range: { from: '', to: '' },
          },
          userRole: [],
          readOnly: false,
          lookup: {
            minCharToSearch: 1,
            maxResultToDisplay: 5,
            module: '',
            instance: '',
            fieldType: 'input',
            filterPayload: {},
            url: '',
            lookupProp: '',
            isDataRemapEnabled: false,
            remapData: '',
          },
          addressAPI: {
            authType: '',
            token: '',
            userName: '',
            password: '',
            type: 'canada post',
            key: '',
          },
          isVisibilityRuleEnabled: false,
          ruleIdReference: '',
          ruleData: '',
          isVisible: true,
          visibilityRulePayload: [
            {
              fieldName: '',
              values: [],
              value: '',
              operator: '',
              options: [],
              displayType: '',
              optionRef: '',
              showType: '',
            },
          ],
          visibilityRuleAction: 'show',
          visibilityRuleCondition: 'All',
        },
      };
      this.resetComponent += 1;
      await this.updateLangSchemaByModule(this.$route.params.module);
      let savedFields = await UPCAxiosInstance.get(
        `/customSchema/getSchema?module=${this.$route.params.module}`
      );
      this.originalFieldData = JSON.parse(JSON.stringify(savedFields.data));
      savedFields = savedFields.data.schema;

      let currentSectionItems = await UPCAxiosInstance.get('/section');
      currentSectionItems =
        currentSectionItems.data[0].section[this.selectedTab.toLowerCase()];
      //console.log(
      //   savedFields,
      //   currentSectionItems,
      //   this.getFieldItems(currentSectionItems, savedFields),
      //   'savedFields 01'
      // );
      currentSectionItems;

      const filteredData = Object.fromEntries(
        Object.entries(savedFields).filter(
          ([, value]) =>
            value.section &&
            value.section.includes(this.selectedTab.toLowerCase())
        )
      );
      // this.systemGeneratedFieldsData = await this.getFieldItems(
      //   currentSectionItems,
      //   savedFields
      // );
      this.systemGeneratedFieldsData = filteredData;

      this.concattedFieldsArray();
      this.closeDrawer();
    },
    previousLevel() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
.circleInCard {
  width: 70px;
  height: 70px;
  background-color: #d8edfc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0065b2;
  font-size: 30px;
}

.customFieldsCardDescription {
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.system-field {
  margin-bottom: 10px;
}

.fields-container {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  .show-field-on-hover {
    display: none;
  }
  .show-controls {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .field-item {
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon-check-circle-filled {
      color: #54bd95;
    }
  }
  .field-item-child {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    gap: 8px;
    .icon-check-circle-filled {
      color: #54bd95;
    }
  }
}

.list-values {
  .input-container {
    width: 100% !important;
  }
}
</style>
