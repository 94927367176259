<template>
  <hlx-alert-notification
    :show-alert="notify"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-modal
    :modal-active="showAlert"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'210px'"
    :width="'400px'"
    :modal="true"
    @close="
      canLeave = false;
      showAlert = false;
    "
  >
    <template #header>
      <div>Unsaved changes</div>
    </template>
    <template #content>
      <div class="modal-content">
        Do you want to save your changes before navigating away?
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="discardAllChanges"
        >Discard changes</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        @click="
          canLeave = false;
          showAlert = false;
          saveManualData();
        "
        >Save</hlx-button
      >
    </template>
  </hlx-modal>
  <main class="--layout-2-way">
    <section class="--layout-inner-left-portion --LL30">
      <!-- Breadcrumb -->
      <div class="layout-breadcrumb">
        <hlx-breadcrumb :items="breadcrumb_items" @path="pathTo" />
      </div>
      <div class="rule-validation-info --info-left-panel">
        <!-- Title -->
        <span class="rule-validation-title -info-title">Edit test data </span>

        <!-- rule-validation name -->
        <div class="rule-validation-name-wrapper">
          <hlx-input
            v-model:value="editData.name"
            label-value="Name"
            :label-animation="true"
            :pre-val="editData.name"
          ></hlx-input>
        </div>

        <!-- rule-validation description -->
        <div class="rule-validation-description-wrapper">
          <hlx-input
            v-model:value="editData.description"
            :label-animation="true"
            label-value="Description"
            type="textarea"
            :clearable="true"
            :pre-val="editData.description"
          />
        </div>

        <!-- rule-validation selection -->
        <div class="rule-validation-description-wrapper">
          <hlx-select
            :options="schemaOptions"
            :placeholder-value="'Schema'"
            :label-animation="true"
            :pre-value="editData.schemaName"
            :clearable="true"
            @custom-change="chooseSchema"
          ></hlx-select>
        </div>
      </div>
    </section>
    <!-- Right panel -->
    <section class="schema-view --layout-inner-right-portion --RL70">
      <div class="panel-layout-body">
        <div class="design-area">
          <!-- {{this.tableData}} -->
          <p class="--right-panel-title --morgin-bottom">Payload
            <span class="--subtitle">Select a schema to add payload</span>

          </p>
          <span
            v-if="$route.query.mode === 'manual'"
            class="manual-data-content"
          >
            <hlx-object-viewer
              :json-data="editData.data"
              :show="true"
              :type="'add-data-from-schema'"
              @updated-json="recievedJson($event)"
            />
          </span>

          <span v-if="$route.query.mode === 'upload'">
            <div class="grid" style="height: 40% !important">
              <span class="titles" style="height: 100px"
                >Content <br />
                <span
                  class="info"
                  style="font-size: var(--extra-small-font-size)"
                  >Define data in JSON format.</span
                ></span
              >
              <span class="upload-data-content" style="height: 105% !important">
                <hlx-file-upload
                  :file-type="'.xlsx,.png,.json'"
                  :file-size-limit="'20mb'"
                  :type="'regular'"
                  :custom-icon="'icon-eye-regular'"
                  @preview-file="previewUploadedFile"
                  @file-data="fileDataReceived"
                  @delete-file="
                    show_preview = false;
                    isMatch = false;
                    editData.data = [];
                  "
                />
                <span class="info">Maximum file size is 25 MB</span>
              </span>
            </div>
            <div
              v-show="show_preview"
              class="grid"
              style="height: 40% !important"
            >
              <span class="titles" style="height: 100px; margin-top: 6px"
                >JSON preview</span
              >
              <span
                class="upload-data-content"
                style="min-height: 100px !important"
              >
                <hlx-object-viewer
                  :json-data="uploadedJson"
                  :show="true"
                  :type="'read-only'"
                />
              </span>
            </div>
          </span>
          <!-- * upload -->
        </div>
      </div>
    </section>
  </main>
  <div class="--layout-footer">
    <hlx-button class="secondary sm" @click="cancelApiAdd">Cancel</hlx-button>
    <hlx-button class="primary sm" @click="saveEdittedManualData"
      >Save</hlx-button
    >
  </div>
</template>

<script>
import { DMNAxiosInstance } from "@/config/axiosConfig";

export default {
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChanges) {
      this.showAlert = true; // Replace with your condition to show the dialog
      if (this.showAlert) {
        if (this.canLeave) {
          next();
        } else {
          next(false);
        }
      } else {
        next();
      }
    } else {
      next();
    }

    // 
    //   'unsaved changes :',
    //   this.unsavedChanges,
    //   'show alert dialog :',
    //   this.showAlert,
    //   'can leave :',
    //   this.canLeave,
    //   'add payload :',
    //   this.add_payload[0].name,
    //   this.add_payload[0].schemaName,
    //   this.add_payload[0].data
    // );
  },
  data() {
    return {
      unsavedChanges: false,
      changeByMount: true,
      canLeave: false,
      notification: {},
      notify: false,
      token: this.$route.params.token,
      editMode: true,
      editPermission: [],
      viewPermission: [],
      breadcrumb_items: [
        { label: "Rule validation", link: "/data-execution" },
      ],
      add_payload: [
        {
          name: "",
          schemaName: "",
          description: "",
          inputMode: "manual",
          data: [],
          createdBy: "639af00f9f515f57074584ef"
        }
      ],
      schemaOptions: [],
      selectedSchema: {},
      showAlert: false,
      alertMessage: "Selected schema and uploaded JSON doesn't match!",
      alertType: "danger",
      editData: {},
      editDataDuplicate: {},
      file_name: "",
      file_data: "",
      show_preview: false,
      skeletonJson: {},
      uploadedJson: {},
      isMatch: false
    };
  },
  watch: {
    editData: {
      handler(val) {
        if (this.changeByMount) {
          this.changeByMount = false;
          return;
        }
        if (val.name !== "" || val.schemaName !== "" || val.data.length !== 0) {
          this.unsavedChanges = true;
        } else {
          this.unsavedChanges = false;
        }
      },
      deep: true
    }
  },
  async mounted() {
    // const val = await MLTAxiosInstance.get(`partymanagement/individual/auth`);
    // this.company = val.data.company;
    // this.userId = val.data.company;
    // const access = await MLTAxiosInstance.get(
    //   `partyRole/get/${val.data.company}/${val.data.userId}`
    // );

    // let arr = access.data;
    // this.editPermission = [];
    // if (arr) {
    //   if (arr.permission.User_settings.edit == false) {
    //     this.editPermission.push('setting');
    //   }
    //   if (arr.permission.User_settings.view == false) {
    //     this.viewPermission.push('setting');
    //   }
    //   if (arr.permission.Schema.edit == false) {
    //     this.editPermission.push('schema');
    //   }
    //   if (arr.permission.Schema.view == false) {
    //     this.viewPermission.push('Schema');
    //   }
    //   if (arr.permission.Test_data.view == false) {
    //     this.viewPermission.push('Database', 'Execute');
    //   }
    //   if (arr.permission.Test_data.edit == false) {
    //     this.editPermission.push('testdata');
    //   }
    //   if (arr.permission.Rules.view == false) {
    //     this.viewPermission.push('Business rule');
    //   }
    //   if (arr.permission.Rules.edit == false) {
    //     this.editPermission.push('Business rule');
    //   }
    //   if (this.editPermission.includes('Business rule') == true) {
    //     
    //       this.editPermission.includes('Business rule'),
    //       this.editMode,
    //       this.editPermission,
    //       'done'
    //     );
    //     this.editMode = false;
    //   }
    // }

    // this.$store.commit('token', this.$route.params.token);
    // const id = 'your-id'; // Replace with your actual id
    // const name = 'your-name'; // Replace with your actual name

    await DMNAxiosInstance.get(
      `/rule_engine/execution_data/${this.$route.query.id}`
    ).then((response) => {
      this.editData = response.data;
      let obj = {};
      obj["label"] = `${this.editData.name}`;
      obj["link"] = "";
      this.breadcrumb_items.push(obj);
      this.editDataDuplicate = JSON.parse(JSON.stringify(response.data));
    });

    // await DMNAxiosInstance
    //   .get(
    //     `/rule_engine/execution_data/${this.$route.query.id}`)
    //   .then((response) => {
    //     this.editData = response.data;
    //     this.editDataDuplicate = JSON.parse(JSON.stringify(response.data));
    //   });

    await DMNAxiosInstance.get(`/rule_engine/schema`)
      .then((response) => {
        // Handle the response here
        this.schemaOptions = response.data.data;
        this.schemaOptions.forEach((e) => {
          e["value"] = e.name;
        });
        this.schemaOptions.sort((a, b) => a.name.localeCompare(b.name));
      })
      .catch((error) => {
        // Handle the error here
        console.error(error);
      });
  },
  methods: {
    discardAllChanges() {
  const activeSideBarItem = this.$store.getters.getTempActiveSideBarItem;
  // console.log(activeSideBarItem);
  // if (activeSideBarItem !== '') {
  //   if (activeSideBarItem == 'logout') {
  //     this.logout();
  //   } else if (activeSideBarItem == 'Dashboard') {
  //     this.$router.push(`/DMN/dashboard`);
  //     this.$store.commit('setTempActiveSideBarItem', '');
  //   } else if (activeSideBarItem == 'Schema') {
  //     this.$router.push(`/DMN/schemas`);
  //     this.$store.commit('setTempActiveSideBarItem', '');
  //   } else if (activeSideBarItem == 'Help') {
  //     window.open('https://halleyx-documentation-ghubgwxrcq-uc.a.run.app');
  //   } else if (activeSideBarItem == 'Rule studio') {
  //     this.$router.push({
  //       name: 'business-rules',
  //       query: { Value: 'All' },
  //     });
  //     this.$store.commit('setTempActiveSideBarItem', '');
  //   } else if (activeSideBarItem === 'Database') {
  //     this.$router.push(`/DMN/reference_data`);
  //     this.$store.commit('setTempActiveSideBarItem', '');
  //   } else if (activeSideBarItem == 'Execute') {
  //     this.$router.push(`/DMN/rule_validation`);
  //     this.$store.commit('setTempActiveSideBarItem', '');
  //   } else if (activeSideBarItem == 'setting') {
  //     this.$router.push(`/settings`);
  //     this.$store.commit('setTempActiveSideBarItem', '');
  //   } else if (activeSideBarItem == 'Audit') {
  //     this.$router.push(`/DMN/audit`);
  //     this.$store.commit('setTempActiveSideBarItem', '');
  //   } else {
  //     console.log('else', activeSideBarItem);
  //   }
  // } 
  if (activeSideBarItem !== '') {
    if (activeSideBarItem === 'UPC') {
      this.$router.push(`/UPC/dashboard`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Product') {
      this.$router.push(`/UPC/product-catalog-table/product`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Service') {
      this.$router.push(`/UPC/product-catalog-table/service`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Resource') {
      this.$router.push(`/UPC/product-catalog-table/resource`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'DMN') {
      this.$router.push(`/DMN/dashboard`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Rule studio') {
      this.$router.push(`/DMN/rule_studio`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Rule validation') {
      this.$router.push(`/DMN/rule_validation`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Audit log') {
      this.$router.push(`/DMN/audit`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Schema') {
      this.$router.push(`/schemas`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Data management') {
      this.$router.push(`/reference_data`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Help') {
      window.open(`https://halleyx-documentation-ghubgwxrcq-uc.a.run.app`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Settings') {
      this.$router.push(`/settings`);
      this.$store.commit('setTempActiveSideBarItem', '');
    } else if (activeSideBarItem === 'Log out') {
      this.$router.push(`/`);
      this.$store.commit('setTempActiveSideBarItem', '');
    }
  } else {
    // console.log('empty');
    this.$router.push(`/UPC/product-catalog-table/product`);  //replace this route with your page route
  }
  this.unsavedChanges = false;
  this.canLeave = true;
  this.showAlert = false;
},
    navTo(active) {
      this.$store.commit("token", this.$route.params.token);
      // 
      if (active.name == "Home") {
        this.navHome();
      } else if (active.name == "logout") {
        this.logout();
      } else if (active.name == "settings") {
        this.setting();
      } else if (active.name == "Schema") {
        this.schema();
      } else if (active.name == "Business rule") {
        this.ruled();
      } else if (active.name === "Database") {
        this.execute();
      } else if (active.name == "Help") {
        this.help();
      } else if (active.name == "Execute") {
        this.exe();
      } else {
        this.audit();
        // 
      }
    },
    audit() {
      this.$router.push(`/DMN/audit`);
    },
    help() {
      window.open("https://documentation.halleyx.com");
    },
    ruled() {
      this.$router.push(`/DMN/business_rules`);
    },
    exe() {
      this.$router.push(`/DMN/rule_validation`);
    },
    execute() {
      this.$router.push(`/DMN/reference_data`);
    },
    schema() {
      this.$router.push(`/DMN/schemas`);
    },

    logout() {
      this.$router.push("/");
    },
    setting() {
      this.$router.push(`/settings`);
    },
    async navHome() {
      this.$router.push(`/DMN/dashboard`);
    },
    checkJsonMatch(skeletonJson, dataJson) {
      // // 
      const skeletonKeys = Object.keys(skeletonJson);
      const dataKeys = Object.keys(dataJson);

      if (skeletonKeys.length !== dataKeys.length) {
        return false;
      }

      for (let i = 0; i < skeletonKeys.length; i++) {
        const key = skeletonKeys[i];
        dataKeys.find((dataKey) => dataKey === key);
        const skeletonValue = skeletonJson[key];

        // If key is an object, recursively call this function
        if (typeof skeletonValue === "object") {
          // If key is an array, compare length
          if (Array.isArray(skeletonValue)) {
            if (
              !Array.isArray(dataJson[key]) ||
              skeletonValue.length !== dataJson[key].length
            ) {
              return false;
            }

            // Loop through each object in the array
            for (let j = 0; j < skeletonValue.length; j++) {
              const isMatch = this.checkJsonMatch(
                skeletonValue[j],
                dataJson[key][j]
              );
              if (!isMatch) {
                return false;
              }
            }
          }
          // If key is an object, recursively call this function
          else {
            const isMatch = this.checkJsonMatch(skeletonValue, dataJson[key]);
            if (!isMatch) {
              return false;
            }
          }
        }
      }

      return true;
    },
    previewUploadedFile() {
      this.show_preview = true;
      // // 
      // // 
      // // 
      setTimeout(() => {
        const scrollableDiv = document.querySelector(".exec-data-upload");
        const lastChild = scrollableDiv.lastElementChild;
        // // 
        lastChild.scrollIntoView({ behavior: "smooth" });
      }, 100);
    },
    saveUploadData() {
      if (this.isMatch) {
        // const id = 'your/-id'; // Replace with your actual id
        // const name = 'your-name'; // Replace with your actual name

        DMNAxiosInstance.patch(
          `/rule_engine/execution_data/` + this.editData._id,
          this.editData
        )
          .then((response) => {
            // Handle the response here
            // this.schemaOptions = response.data
            if (response.data.message === undefined) {
              // 
              this.$router.back();
            } else if (response.data.message.includes("duplicate")) {
              // 
            } else if (
              response.data.message.includes("Path `name` is required")
            ) {
              // 
            }
          })
          .catch((error) => {
            // Handle the error here
            console.error(error);
            // 
          });
      } else {
        // 
      }
    },
    fileDataReceived($event) {
      // // 
      this.editData.fileData = $event.data;
      this.uploadedJson = JSON.parse($event.data);
      // 
      // 

      if (
        this.uploadedJson !== {} &&
        this.uploadedJson !== undefined &&
        this.selectedSchema !== {} &&
        this.selectedSchema !== undefined
      ) {
        this.isMatch = this.checkJsonMatch(
          this.selectedSchema,
          this.uploadedJson
        );
        if (this.isMatch) {
          this.editData.data = this.uploadedJson;
          // // 
        }
      }
    },
    recievedJson($event) {
      this.editData.data = $event;
    },
    chooseSchema(schema) {
      // 
      if (this.$route.query.mode === "manual") {
        let selectedSchema = schema.filter((obj) => obj.checked === true);
        selectedSchema = JSON.parse(JSON.stringify(selectedSchema))[0];
        const schemaName = selectedSchema.name;
        // 
        this.editData.schemaName = schemaName;
        const obj = {};
        obj[schemaName] = this.addListItems(selectedSchema.schema);
        this.selectedSchema = obj;
        if (schemaName === this.editDataDuplicate.schemaName) {
          this.editData.data = JSON.parse(
            JSON.stringify(this.editDataDuplicate.data)
          );
        } else {
          this.editData.data = obj;
        }
      } else if (this.$route.query.mode === "upload") {
        let selectedSchema = schema.filter((obj) => obj.checked === true);
        selectedSchema = JSON.parse(JSON.stringify(selectedSchema))[0];
        const schemaName = selectedSchema.name;
        this.editData["schemaName"] = schemaName;
        const obj = {};
        obj[schemaName] = this.addListItems(selectedSchema.schema);
        this.selectedSchema = obj;
        if (
          this.uploadedJson !== {} &&
          this.uploadedJson !== undefined &&
          this.selectedSchema !== {} &&
          this.selectedSchema !== undefined
        ) {
          this.isMatch = this.checkJsonMatch(
            this.selectedSchema,
            this.uploadedJson
          );
          if (this.isMatch) {
            this.editData.data = this.uploadedJson;
            // 
          }
        }
      }
    },
    addListItems(obj) {
      for (const key in obj) {
        if (typeof obj[key] === "object" && obj[key] !== null) {
          if (obj[key].type === "Array") {
            obj[key].list_items = [];
            const newObj = {};
            newObj.type = "Object";
            for (const items in obj[key]) {
              // // 
              if (
                items !== "list_items" &&
                items !== "type" &&
                items !== "href" &&
                items !== "ref"
              ) {
                newObj[items] = obj[key][items];
                delete obj[key][items];
              }
            }
            obj[key].list_items.push(newObj);
            // // 
          }
          this.addListItems(obj[key]);
        }
      }

      return this.generateJSONFromSchema(obj);
    },
    generateJSONFromSchema(schema) {
      const result = {};

      for (const key in schema) {
        if (Object.hasOwnProperty.call(schema, key)) {
          const value = schema[key];
          const valueType = value.type;

          switch (valueType) {
            case "String":
              result[key] = "(empty)";
              break;
            case "Date":
              result[key] = "(empty)";
              break;
            case "Boolean":
              result[key] = "false";
              break;
            case "Integer":
              result[key] = 0;
              break;
            case "Number":
              result[key] = 0;
              break;
            case "Object":
              result[key] = this.generateJSONFromSchema(value);
              break;
            case "Array":
              if (value.list_items) {
                const arrayLength = value.list_items.length;
                const arrayResult = [];

                for (let i = 0; i < arrayLength; i++) {
                  arrayResult.push(
                    this.generateJSONFromSchema(value.list_items[i])
                  );
                }

                result[key] = arrayResult;
              } else {
                result[key] = [];
              }
              break;
            default:
              break;
          }
        }
      }

      return result;
    },
    pathTo(val) {
      // 
      val;
      this.$router.back();
    },
    cancelApiAdd() {
      this.add_payload[0] = {
        name: "",
        schemaName: "",
        inputMode: "manual",
        description: "",
        data: [],
        createdBy: "639af00f9f515f57074584ef"
      };
      this.$router.back();
    },
    saveEdittedManualData() {
      //
      // const id = 'your-id'; // Replace with your actual id
      // const name = 'your-name'; // Replace with your actual name

      DMNAxiosInstance.patch(
        `/rule_engine/execution_data/` + this.editData._id,
        this.editData
      )
        .then((response) => {
          // Handle the response here
          // this.schemaOptions = response.data
          if (response.data.message === undefined) {
            this.discardAllChanges();
            this.notify = true;
            this.alertMessage = "Data added succesfully!";
            this.alertType = "Success";
            this.notification = {
        type: "success",
        message: "Data updated successfully"
      };
            setTimeout(() => {
              this.notify = false;
              this.$router.back();
            }, 800);
          } else if (response.data.message.includes("duplicate")) {
            this.showAlert = true;
            this.alertMessage =
              "Name: " + this.add_payload[0].name + " already exists!";
            this.alertType = "danger";
            setTimeout(() => {
              this.showAlert = false;
            }, 3000);
          } else if (
            response.data.message.includes("Path `name` is required")
          ) {
            this.showAlert = true;
            this.alertMessage = "Name field cannot be empty";
            this.alertType = "danger";
            setTimeout(() => {
              this.showAlert = false;
            }, 3000);
          }
        })
        .catch((error) => {
          // Handle the error here
          console.error(error);
          this.showAlert = true;
          this.alertMessage = "Failed to save the data!";
          this.alertType = "danger";
          setTimeout(() => {
            this.showAlert = false;
          }, 3000);
        });
    },
    cancelUploadData() {
      this.$router.back();
    }
  }
};
</script>
