<template>
  <div class="modal1">
    <hlx-modal
        :modal-active="modal_active3"
        :height="'300px'"
        :width="'380px'"
        :modal="true"
        @close="modal_active3 = false"
      >
        <template #header>
          <div id="header">Forgot password?</div>
        </template>
        <template #content>
          <div class="modal-content">
            <br />
            <p class="text" style="text-align: center">
              Enter your email ID to get a link to reset your password
            </p>
            <br />
            <div class="custom-modal">
              <hlx-input
                v-model:value="res_email"
                :label-animation="false"
                label-value="Email"
                type="email"
                :clearable="true"
              />
            </div>
          </div>
        </template>
        <template #footer>
          <hlx-button class="primary sm" @click="reset()">Send</hlx-button>
        </template>
      </hlx-modal>
  </div>
  <div class="selfserve_loginpage">
    <div class="selfserve_left_container">
      <div class="hlx_black_logo">
        <img
          src="@/assets/images/logo-line-black-large.png"
          style="width: 156px"
        />
      </div>

      <div class="self_login_form">
        <h2 class="form-header">Log In</h2>
        <div style="width: 100%">
          <hlx-input
            v-model:value="email"
            :label-animation="true"
            label-value="Your email"
            type="email"
            :custom-error="error"
            :custom-error-message="message"
            :display-error="error"
          />
        </div>

        <div style="width: 100%">
          <hlx-input
            :label-animation="true"
            type="password"
            :password-condition="true"
            label-value="Password"
            :clearable="false"
            :custom-error="error"
            :custom-error-message="message"
            :display-error="error"
            @at-enter="signIn()"
          />
        </div>

        <div class="selfserve_login_forgotpassword">
          <hlx-button
            class="change primary"
            style="width: 100%"
            @click="login()"
            >Log In</hlx-button
          >
          <div class="hover_effect">
            <a class="greeny_context"  @click="password()"><b>Forgot Password?</b></a>
          </div>
        </div>
      </div>
    </div>

    <div class="selfserve_right_container">
      <div class="selfserve_right_context">
        The future belongs to those who
          <span class="greeny_context"><b>believe</b></span> in the
          <span class="greeny_context"><b>beauty of their dreams.</b></span>
      </div>
      <div class="selfserve_right_image">
        <img src="@/assets/images/png/network1.png" style="width: 679px" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      modal_active3:false,
    }
  },
  methods:{
    password(){
      this.modal_active3 = true;
    }
  }
};
</script>

<style lang="scss">
.selfserve_loginpage {
  position: relative;
  display: flex;
  .selfserve_left_container {
    background: none;
    width: 555px;
    height: 982px;
    position: relative;
    justify-content: space-around;
    display: flex;
    // align-items: center;
    .hlx_black_logo {
      position: absolute;
      top: 28px;
      left: 28px;
    }
    .self_login_form {
      font-family: "quicksand";
      position: absolute;
      background: none;
      width: 70%;
      height: 260px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      z-index: 800;
    margin-top: 272px;
    }
  }
  .selfserve_right_container {
    background: none;
    width: 60%;
    height: 982px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .selfserve_right_context {
      width: 450px;
      position: absolute;
      margin-top: 146px;
      font-size: 23px;
      font-family: "Poppins";
    }
    .selfserve_right_image {
      position: absolute;
      margin-top: 288px;
    }
  }
}
.greeny_context {
  color: rgba(84, 189, 149, 1);
}
.selfserve_login_forgotpassword {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  .hover_effect{
    cursor: pointer;
    text-align: end;
    padding-top: 5px;    
    text-decoration: none;
    color: var(--hlx-color-primary);
  }
  .hover_effect:hover{
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
