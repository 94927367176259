<template>
  <section class="right-view-body-feature">
    <span class="spec-feature --table-tool-bar">
      <p class="--upcv2-body-title">Related products</p>
      <div class="--table-tool-bar-wrapper" style="gap: 6px">
        <span class="util-items search"
          ><hlx-search
            v-model="searchvalue"
            @search-key="productKey"
          ></hlx-search
        ></span>
        <!-- Filter -->
        <!-- <span class="dependent_product_drawer" > -->
        <hlx-drawer
          :key="updateTimeLine"
          :title="'upload drawer'"
          :show="show_dependent_product_filter"
          :width="427"
          position="right"
          :footer="true"
          :show-close-icon="true"
          @close="
            show_dependent_product_filter = !show_dependent_product_filter
          "
        >
          <template #body>
            <section style="padding-bottom: 32%">
              <div
                style="
                  margin: 0 8px 0 8px;
                  padding-bottom: 15px;
                  padding-top: 3px;
                  border-bottom: solid 1px rgba(216, 216, 216, 1);
                "
              >
                <span
                  ><b
                    style="
                      font-family: OpenSans;
                      font-size: 16px;
                      font-weight: 600;
                      line-height: 21.79px;
                      text-align: center;
                      margin: 0 8px 0 8px;
                    "
                    >Create dependent products</b
                  ></span
                >
              </div>
              <section v-for="(section, index) in sections" :key="index">
               
                <section style="margin-top: 16px">
                  <!-- :pre-value="sections[index].category" -->
                   <!-- {{ sections[index].category }} -->
                   <span v-show="index!==0" style="margin-top: 16px;font-weight: 600;">
                  Another dependent products
                </span>
                <!-- :pre-value="returnSelectedK(index,sections[index].destinationCategory) -->
                <section  style="margin-top: 12px">
                  <hlx-cascader
                    :key="updateDependentProductDrawer"
                    :pre-value="preValues[index]"
                    :options="categoryOptions"
                    :children="'subCategory'"
                    :required="true"
                    :placeholder-value="'Choose category'"
                    :label-animation="true"
                    :label="'name'"
                    :prop-value="'name'"
                    @selected-value="returnSelectedObject(index,$event)"
                    @selected-array="returnSelected(index,$event)"
                  >
                  </hlx-cascader>
                </section>
                </section>
                <section style="margin-top: 16px">
                  <!-- v-model:value="sections[index]?.description"
          :pre-val="sections[index]?.description" -->
                  <hlx-input
                    v-model:value="sections[index].description"
                    :pre-val="sections[index]?.description"
                    :label-animation="true"
                    type="textarea"
                    label-value="Description"
                    :clearable="false"
                  />
                </section>
                <section
                  class="dependent_product_category"
                  style="margin-top: 16px"
                >
                <!-- {{ JSON.stringify(sections[index].productoffers)+" =>1" }} -->
                  <hlx-multi-select
                    :key="updateMultiSelect"
                    :pre-value="sections[index]?.destinationProductOffers"
                    :required="false"
                    :options="dependentProductCategoryData[index]"
                    :placeholder="'Choose product'"
                    :label-animation="true"
                    :prop-value="'value'"
                    :label="'name'"     
                    @selected-array="selectedProduct(index,$event)"
                  ></hlx-multi-select>
                </section>

                <section style="margin: 8px 0 8px 4px">
                  <h4 style="margin-top: 16px">Minimum & maximum quantity</h4>
                  <span
                    style="
                      font-family: OpenSans;
                      font-size: 10px;
                      color: #656565;
                      margin-bottom: 16px;
                      font-weight: 400;
                      line-height: 12.26px;
                    "
                    >Mapping the Quantity Control of the Cart</span
                  >
                  <span class="select-width-half-dependent">
                    <span>
                      <hlx-input
                        v-model:value="sections[index].minimumQuantity"
                        :pre-val="sections[index]?.minimumQuantity"
                        :label-animation="true"
                        label-value="Min quantity"
                        type="text"
                        :clearable="true"
                      />
                    </span>
                    <span>
                      <!-- v-model:value="sections[index].filesArray[0].max"
                      :pre-val="sections[index]?.filesArray[0]?.max" -->
                      <hlx-input
                        v-model:value="sections[index].maximumQuantity"
                        :pre-val="sections[index]?.maximumQuantity"
                        :label-animation="true"
                        label-value="Max quantity"
                        type="text"
                        :clearable="true"
                      />
                    </span>
                  </span>
                </section>
                <section>
                <span
                    style="color: #54bd95"
                    class="icon-dollar-filled"
                  ></span>
                  <span style="color: #54bd95" @click="modifyPricing(index)"
                    >Modify pricing</span
                  >
                <section
        
                :key="ind"
                  style="
                    margin: 8px 0 8px 4px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19.07px;
                  "
                >
    
                <!-- <section v-for="(e,ind) in selectedDependentProduct[index]" :key="ind"> -->
               
                  <section
                  v-if="pricingIndex.includes(index)"
                  class="dependent-products-pricing">
                <span class="recurring-table">
                  <hlx-table
                    :column-count="3"
                    :border="['table', 'header', 'horizontal']"
                    theme="grey"
                  >
                    <template #thead>
                      <hlx-table-head
                          :width="60"
                          :align="'center'"
                          :fixed="'left'"
                           >
                          {{ "S.no" }}
                        </hlx-table-head>
                        <hlx-table-head
                        :key="i"
                        :width="120"
                        :fixed="'left'"
                        >{{ "SKU" }}
                      </hlx-table-head>
                      <hlx-table-head
                        :key="i"
                        :width="120"
                        :fixed="'left'"
                        >{{ "Price" }}
                      </hlx-table-head>
                      <hlx-table-head
                        :key="i"
                        :fixed="'left'"
                        >{{ "Pricetype" }}
                      </hlx-table-head>
                    </template>
                    <template #tbody>
                      <tr
                         v-for=" (ele,ind) in sections[index].skuNumber"
                        :key="ind"
                      >
                    
                      <hlx-table-cell  :width="40" :align="'center'" :fixed="'left'">
                {{serialNumberAlter(ind)}}
              </hlx-table-cell>
                        <hlx-table-cell
                        :key="col_ind"
                          :width="40"
                          :align="'center'"
                          :fixed="'center'"
                        >
                          <div>
                            {{ele?.name }}
                          </div>
                        </hlx-table-cell>
                        <hlx-table-cell>
                          <span>
                            <span style="width: 40%">
                              <hlx-input
                                v-model:value="ele.price"
                                :pre-val="ele?.price"
                                type="number"
                                :single-border="true"
                                style="width: 28px"
                              />
                            </span>
                           
                              <!-- {{ ele?.price }} -->
                          
                          </span>
                  
                        </hlx-table-cell>
                        <hlx-table-cell
                        :key="col_ind"
                          :width="40"
                          :align="'center'"
                          :fixed="'center'"
                        >
                          <div>
                            {{ele?.priceType }}
                          </div>
                        </hlx-table-cell>
                      </tr>
                    </template>
                  </hlx-table>
                </span>
              </section>
          <!-- Pagination -->      
              <div
              
                  v-if="pricingIndex.includes(index)"
    class="dependent-pagination-container"
    style="padding: 1rem 0;
   background-color: white;
    padding: 1rem 0px 1rem 0px;
    width: -webkit-fill-available;
    padding-right: 2rem !important;"
  >
    <hlx-pagination
      v-model:rows-per-page="externalRowsPerPage"
      :total="sections[index].destinationProductOffers?.length"
      enable-rows-per-page
      :rows-per-page-list="[5, 10, 15]"
      :options="{
        attributes: {
          rowsPerPageTitle: true,
          rowsPerPageResult: true,
          rowsPerPageDropdown: {
            position: {
              top: 0,
              right: 1,
            },
          },
        },
      }"
      @current-page="currentPage"
      @updated:rows-per-page="changeRowsPerPage"
    ></hlx-pagination>
  </div>
                <!-- </section> -->
                </section>
              </section>
                <section style="margin-top: 16px">
                  <h4 style="margin: 8px 0 8px 4px">
                    When the user selects
                    <span style="color: #54bd95">{{leaf(sections[index].category)}}</span> then,
                  </h4>
                  <span>
                    <hlx-select
                      :options="dropdown_data"
                      :label="'name'"
                      :prop-value="'name'"
                      :label-animation="false"
                      :inline-search="true"
                      @selected-value="isMoreDependentProducts(index, $event)"
                    />
                  </span>
                </section>
              </section>
            </section>
          </template>

          <template #footer>
            <div
              style="
                display: flex;
                bottom: 0;
                justify-content: flex-end;
                padding: 19px;
                border-top: 1px solid rgb(216, 216, 216);
                position: fixed;
                z-index: 99;
                background-color: white;
                width: -webkit-fill-available;
              "
            >
              <hlx-button
                class="secondary sm"
                @click="
                  show_dependent_product_filter = !show_dependent_product_filter
                "
                >Cancel</hlx-button
              >
              <hlx-button
              v-if="!edit_show_dependent_product_filter"
                class="primary sm"
                style="margin-left: 10px"
                @click="addDependentProducts"
                >Add</hlx-button
              >
              <hlx-button
              v-if="edit_show_dependent_product_filter"
                class="primary sm"
                style="margin-left: 10px"
                @click="editedDependentProducts"
                >Save</hlx-button
              >
            </div>
          </template>
        </hlx-drawer>

        <span
          class="util-items util-items-add"
          style="display: flex; align-items: center"
          ><hlx-button
            class="primary sm add-btn"
            @click.stop="
              relatedProductPanel = true;
              openDependentProductDrawer();
            "
            ><i class="icon-plus-circle-regular" style="margin-right: 4px"></i
            >Create dependent product</hlx-button
          >
        </span>
        <section>
          <div style="margin-bottom: 2%"></div>
        </section>
      </div>
    </span>
    <section
      class="feature-table"
      style="overflow-y: auto; max-height: 76vh; min-height: 76vh"
    >
      <div style="margin-bottom: 2%">
        <hlx-switch
          v-model="selected"
          :switch-items="tabsection"
          value-prop="name"
          display-prop="name"
          @change="relatedProductsHeader"
        />
      </div>
      <section class="remove-icon">
        <hlx-timeline
          v-for="(i, index) in options"
          :key="index"
          :timeline-data="example_data1"
          :view="'vertical'"
          :type="'slot'"
          :options="options"
          :curr-index="index"
          :total-length="options.length"
          :data-length="options.length"
        >
          <template #icon>
            <span
              style="
                top: 13px;
                width: 12px;
                height: 12px;
                font-size: 22px;
                left: 13px;
                position: relative;
                display: block;
                border-radius: 50%;
                background: rgb(84, 189, 149);
              "
            ></span>
          </template>
          <template #first-slot>
            <section style="display: flex;align-items: baseline;">
              <p
              v-if="index===0"
                style="
                  font-family: OpenSans;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 19.07px;
                  text-align: left;
                  margin-top: 12px;
                "
              >
                {{  (leaf(i?.sourceCategory)?.charAt(0).toUpperCase() + leaf(i?.sourceCategory)?.slice(1).toLowerCase() )||"-" }}
              </p>
              <p
              v-if="index!=0"
                style="
                  font-family: OpenSans;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 19.07px;
                  text-align: left;
                  margin-top: 12px;
                "
              >
                {{  (leaf(safeJsonParse((i?.destinationCategory)))?.charAt(0).toUpperCase() + leaf(safeJsonParse((i?.destinationCategory)))?.slice(1).toLowerCase() )||"-" }}
              </p>
              <span
                v-if="!i.isDependentProduct "
                :id="'table-context' + index"
                class="action-icon"
                style="width: 20% !important; gap: 10px"
              >
                <i
                v-if="index!=0"
                  class="icon-edit-regular"
                  style="color: rgba(84, 189, 149, 1)"
                  @click="EditDependentProducts(i, index)"
                ></i>

                <i
                v-if="index!=0"
                  class="icon-trash-regular"
                  @click="deleteDependentProduct(i, index)"
                ></i>
              </span>
            </section>
          </template>
          <!-- <template #title>
          <p
            style="
              font-family: OpenSans;
              font-size: 12px;
              font-weight: 400;
              line-height: 19.26px;
              text-align: left;
              color: #504f4f;
            "
          >
            {{ i.title }}
          </p>
        </template> -->
          <template #title>
            <div class="description" style="width: 100% !important">
              <p class="description" @click="toggleExpand">
                {{ i.description }}
              </p>
              <!-- <span
        style="cursor: pointer; font-family: OpenSans; font-size: 12px; font-weight: 400;"
        @click="toggleExpand(index)"
      >
        {{ expandedIndex === index ? 'Read Less' : 'Read More' }}
      </span> -->
            </div>
          </template>
          <template #footer>
            <div

              class="container-box"
              :class="{
                expanded: expandedIndices.includes(index),
                collapsed: !expandedIndices.includes(index),
              }"
              style="
                background: #d9d9d929;
                border: none;
                border-radius: 8px;
                margin: 12px;
                width: 60%;
                margin-left: 0px;
                padding-left: 0px;
              "
            >
              <div class="min_max_details">
                <div
                  :id="'wrapper' + index"
                  ref="containerBox"
                  class="details-wrapper"
                >
                  <div
                    
                    class="object-name"
                  >
                    <section style="font-family: OpenSans; margin-bottom: 12px">
                      <span
                        style="color: black; font-size: 12px; font-weight: 400"
                        >Min quantity :
                      </span>
                      <span  style="font-size: 12px">{{ i?.minimumQuantity }}</span>
                      <!-- <span v-for="(item, col_index) in i?.filesArray"
                      :key="col_index">
                      <span style="font-size: 12px">{{
                        item.minQuantity
                      }}</span> 
                      </span>-->
                      <span
                        style="
                          border: 1px solid #a6a6a6;
                          margin: 0px 8px 0px 8px;
                          height: 4px;
                        "
                      ></span>
                      <span
                        style="color: black; font-size: 12px; font-weight: 400"
                        >Max quantity :
                      </span>
                      <span  style="font-size: 12px">{{ i?.maximumQuantity }}</span>
                      <!-- <span v-for="(item, col_index) in i?.filesArray"
                      :key="col_index">
                      <span style="font-size: 12px">{{
                        item.maxQuantity
                      }}</span>
                      </span> -->
                    </section>
                    </div>
                    <br>
                    <div>
                  
                    <section>
                      <span
                      v-if="index===0" 
                        style="
                          color: black;
                          align-items: baseline;
                          display: flex;
                          font-size: 12px;
                          font-weight: 400;
                        "
                        >
                        <span>SKU:</span>
                        <div class="sku-wrapper">
                          <div
                            v-for="(chunk, chunkIndex) in chunkedSkuItems(
                             safeJsonParse(i?.skuNumber)
                              // ||i?.productoffers
                            )"
                            :key="chunkIndex"
                            class="sku-column"
                          >
                          
                            <ul class="sku-list">
                              <li
                                v-for="(skuItem, skuIndex) in chunk"
                                :key="skuIndex"
                                class="sku-item"
                              >
                         
                                {{ chunk[skuIndex] }}
                              </li>
                            </ul>
                            <!-- <ul v-else class="sku-list">
                              <li
                                v-for="(skuItem, skuIndex) in chunk"
                                :key="skuIndex"
                                class="sku-item"
                              >
                         
                                {{ skuItem.name}}
                              </li>
                            </ul> -->
                          </div>
                        </div>
                      </span>
                      <span
                      v-if="index !=0"
                        style="
                          color: black;
                          align-items: baseline;
                          display: flex;
                          font-size: 12px;
                          font-weight: 400;
                        "
                        >
                        <span>SKU:</span>
                        <div class="sku-wrapper">
                          <div
                            v-for="(chunk, chunkIndex) in chunkedSkuItems(
                             safeJsonParse(i?.destinationProductOffers)
                              // ||i?.productoffers
                            )"
                            :key="chunkIndex"
                            class="sku-column"
                          >
                          
                            <ul  class="sku-list">
                              <li
                                v-for="(skuItem, skuIndex) in chunk"
                                :key="skuIndex"
                                class="sku-item"
                              >
                         
                                {{ skuItem.name }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </span>
                    </section>
                  </div>
                </div>
              </div>
            </div>
            <span
              style="
                cursor: pointer;
                color: #5787ac;
                font-size: 12px;
                font-weight: 400;
                margin: 12px;
                margin-left: 0px;
              "
              @click="toggleExpand(index)"
            >
              {{
                expandedIndices.includes(index)
                  ? "Hide details"
                  : "Show details"
              }}
            </span>
            <!-- <span
          @click="toggleExpand(index)"
            style="cursor: pointer;font-size: 12px; font-weight: 400;"
          >
            {{ expandedIndex === index && showFooter ? 'Hide Details' : 'Show Details' }}
            {{ expandedIndex === index ? 'Read Less' : 'Read More' }}

          </span> -->
          </template>
        </hlx-timeline>
      </section>
    </section>
  </section>
</template>

<script>
import {
    // DMNAxiosInstance,
    MLTAxiosInstance,
    UPCAxiosInstance,
} from "@/config/axiosConfig";
export default {
  props: {
    timeLineData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // categoryData: {
    //   type: Array,
    //   default: () => {
    //     return [];
    //   },
    // },
    categoryPrevalue: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ["selected-header", "add-dependent-products","deleted-dependent-product","edited-dependent-products"],
  data() {
    return {
      preValues: [],
      updateTimeLine:0,
      updateMultiSelect:0,
      updateDependentProductDrawer: 1,
      maxRows: 5,
      show_dependent_product_filter: false,
      edit_show_dependent_product_filter:false,
      showFooter: true,
      selected: "Dependent products",
      tabsection: [{ name: "Recommended" }, { name: "Dependent products" }],
      options: [],
      currPage: 1,
      rowsPerPage: 5,
      externalRowsPerPage: 5,
      dependentProductCategoryData: [],
      selectedDependentProduct:[],
      expandedIndices: [0],
      pricingIndex: [],
      isPricing: false,
      dependentProductCategoryPrevalue: [],
      categoryOptions: [],
      company:"",
      reloadSection:[],
      sections: [],
      pre_value: ["Add to cart and Add more dependent products", "Add to cart"],
      dropdown_data: [
        {
          name: "Add to cart and Add more dependent products",
          value: "Add to cart and Add more dependent products",
          id: 1,
        },
        { name: "Add to cart", value: "Add to cart", id: 2 },
      ],
    };
  },
  computed:{
    tableDataOnSelectedRows() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.selectedDependentProduct.slice(start, end);
    },
  },
  watch: {
    timeLineData: {
      handler(val) {
        this.options = val;
        if(this.options.length>1){
        this.sections = this.options.slice(1);
        console.log("editedDependentProducts", this.sections);
        }else{
             this.sections=[{}]
          }
      console.log("reloadSection",this.sections)
      this.sections = this.sections.map(option => {
      return {
        ...option,
        rootCategory: option.rootCategory ? this.safeJsonParse(option.rootCategory) : [],
        sourceCategory: option.sourceCategory ? this.safeJsonParse(option.sourceCategory) : [],
        destinationCategory: option.destinationCategory ? this.safeJsonParse(option.destinationCategory) : [],
        destinationProductOffers: option.destinationProductOffers ? this.safeJsonParse(option.destinationProductOffers) : [],
        skuNumber: option.skuNumber ? this.safeJsonParse(option.skuNumber) : [],
      };
    });
    this.preValues = this.sections.map((section, index) => 
          this.returnSelectedK(index, section.destinationCategory)
        );
        console.log("editedDependentProducts 2", this.sections);
      },
      immediate: true,
      deep: true,
    },
    // categoryData: {
    //   handler(val) {
    //     this.dependentProductCategoryData = val;
    //     console.log("dependentProductCategoryData", val);
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    dependentProductCategoryData: {
      handler(val) {
        this.dependentProductCategoryPrevalue=val.map(ele=>ele.name)
        console.log("dependentProductCategoryPrevalue", this.dependentProductCategoryPrevalue);
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    const categorydata = await UPCAxiosInstance.get(
      `catalogManagement/category/Allhierarchy`
    );
    this.selected = "Dependent products";
    this.categoryOptions = await categorydata.data?.filter((item) => item);
    // const unitOfmesure = await DMNAxiosInstance.get(
    //   `mapRef/collection/unitOfMeasure`
    // );
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    this.user = val.data;
    this.company = val.data.company;
    this.unitOfMeasure =  await this.getReferanceData('unitOfMeasure');
    this.productOfferFilterData()
  },
  // TODO UI
  methods: {
    safeJsonParse(jsonString) {
      try {
        return JSON.parse(jsonString);
      } catch (e) {
        return []
        // console.error("Error parsing JSON:", e);
         // Return an empty object on parse error
      }
    },
    serialNumberAlter(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    currentPage(val) {
      this.currPage = val;
    },
    changeRowsPerPage(count) {
      this.rowsPerPage = count;
    },
    leaf(text) {
      // console.log("leaf category text full", text);
      // console.log("leaf category text length", text?.length);
      // console.log("leaf category text typeof", typeof text);
      let lengthOfText = text?.length;
      if (typeof text === "string") {
        return text;
      }
      if (lengthOfText === 1) {
        // console.log("leaf category text length final o/p ", text);
        return (text = text.toString());
      }
      if (text && lengthOfText > 1) {
        const total = text?.length;
        return text[total - 1];
      }
    },
    selectedProduct(index,val){
      console.log("Total objects",val)
      const mapSku = val.flatMap(item => item.SKU);
const filteredSKUs = mapSku.filter(sku => sku.name);
      // let mapSku=val.map(e=>e.SKU)
      console.log("console map",mapSku)
      // mapSku=Object.assign({},...mapSku)
      console.log("console",filteredSKUs)
      this.sections[index].destinationProductOffers=val
      this.sections[index].skuNumber=filteredSKUs
this.selectedDependentProduct[index]=filteredSKUs
this.selectedDependentProduct=this.selectedDependentProduct.flat()
console.log(this.selectedDependentProduct)
console.log(this.sections)
    },
    returnSelected(index,val){
      console.log(index,"returnSelected",val)
    },
    async returnSelectedObject(index,val){
      // if(index && val){
      console.log("returnSelectedObject",index)
      console.log("returnSelectedObject",val)
      const lastString = val[val.length - 1];
      this.sections[index].category=val
      console.log("returnSelectedObject",this.sections)
      const value ={
    "type": "filter-search",
    "module": "productoffering",
    "isPaginated": true,
    "paginatedQuery": {
        "rowsPerPage": 10,
        "currentPage": 1
    },
    "isNeedToBeSort": true,
    "sortFieldAndOrder": {},
    "limit": 10,
    "filterQuery": {
        "category": {
            "$in": [
                `${lastString}`
            ]
        }
    },
    "searchQuery": "",
    "company": `${this.company}`
}
  
      try {
        const filterPostQuery = await UPCAxiosInstance.post(
          `util/filterData`,
          value
        );

        const responseData = filterPostQuery.data.data;
        console.log("category responseData top",responseData)

        this.dependentProductCategoryData[index] = responseData.map((e) => {
  let price = 0;
  let unit = "";

  if (e.productOfferingPrice?.length > 0) {
    const productPrice = e.productOfferingPrice[0]?.productPrice;
    if (productPrice) {
      price = productPrice.dutyFreeAmount || "0";
      unit = productPrice.unit || "";
    }
  }
   console.log ("category responseData",e.prodSpecCharValue)
   const sku = e.prodSpecCharValue.map(product => {
    return {
        name: product?.SKU,
        tiers: product?.tiers[0].price.value,
        priceType:product?.priceType,
        msrp:product?.MSRP,
        price: price,
    };
});


  const formattedPrice = this.currencyFormatter(price, { currency: unit });
  
  // const offerIds = this.sections[index]?.destinationProductOffers.map(e => e.id);
  const offerIds = Array.isArray(this.sections[index]?.destinationProductOffers)
  ? this.sections[index].destinationProductOffers.map(e => e.id)
  : [];
  console.log(offerIds,"offerIds")
  return {
    id:e.id,
    name: e.name,
    price: price,
    SKU:sku,
    formattedPrice:formattedPrice,
    unit:unit,
    checked: offerIds.includes(e.id),
  };
});

console.log(this.sections,"category responseData product",this.dependentProductCategoryData,"----------",this.sections[index])
      //  this.dependentProductCategoryPrevalue=this.dependentProductCategoryData.map(ele=>ele.name)
       this.dependentProductCategoryPrevalue=this.dependentProductCategoryData.map(ele=>ele.name)
console.log("category responseData product",this.dependentProductCategoryData)

      }
        catch(errorMessage){
          console.log("errorMessage",errorMessage)
        }
      // };
      // else{
      //   return {}
      // }
    },
    returnSelectedK(index,val){
      console.log("returnSelected",index,val)
      this.returnSelectedObject(index,val)
      return val
    },
 returnSelectedObjectPre(index,val){
  console.log(this.sections)
      if(index && val){
      console.log(index,val)
      const lastString = val[val.length - 1];
      this.sections[index].category=val
      console.log("returnSelectedObject",this.sections,lastString)
      const value ={
    "type": "filter-search",
    "module": "productoffering",
    "isPaginated": true,
    "paginatedQuery": {
        "rowsPerPage": 10,
        "currentPage": 1
    },
    "isNeedToBeSort": true,
    "sortFieldAndOrder": {},
    "limit": 10,
    "filterQuery": {
        "category": {
            "$in": [
                `${lastString}`
            ]
        }
    },
    "searchQuery": "",
    "company": `${this.company}`
}
console.log(value)
this.getValueDependentOffers(index,value)
    }
  },

 async getValueDependentOffers(index,val){
    try {
        const filterPostQuery = await UPCAxiosInstance.post(
          `util/filterData`,
          val
        );

        const responseData = filterPostQuery.data.data;
        console.log("category responseData top",responseData)

        this.dependentProductCategoryData[index] = responseData.map((e) => {
  let price = 0;
  let unit = "";

  if (e.productOfferingPrice?.length > 0) {
    const productPrice = e?.productOfferingPrice[0]?.productPrice;
    if (productPrice) {
      price = productPrice.dutyFreeAmount || "0";
      unit = productPrice.unit || "";
    }
  }
   console.log ("category responseData",e.prodSpecCharValue)
   const sku = e.prodSpecCharValue.map(product => {
    return {
        name: product?.SKU,
        tiers: product?.tiers[0].price.value,
        priceType:product?.priceType,
        msrp:product?.MSRP,
        price: price,
    };
});

  const offerIds = Array.isArray(this.sections[index]?.destinationProductOffers)
  ? this.sections[index].destinationProductOffers.map(e => e.id)
  : [];

  const formattedPrice = this.currencyFormatter(price, { currency: unit });

  return {
    id:e.id,
    name: e.name,
    price: price,
    SKU:sku,
    formattedPrice:formattedPrice,
    unit:unit,
    checked:offerIds.includes(e.id),
  };
});

       console.log(this.sections,"category responseData product",this.dependentProductCategoryData,"----------",this.sections[index])
       this.dependentProductCategoryPrevalue=this.dependentProductCategoryData.map(ele=>ele.name)
      }
        catch(errorMessage){
          console.log("errorMessage",errorMessage)
        }
  },

    async productOfferFilterData() {
      try {
        const loginUser = await MLTAxiosInstance.get(
          "partymanagement/individual/auth"
        );
        const obj = {
          company: loginUser.data.company,
          layoutFor: "productOffering",
          layout: [
        
            {
              prop: "category",
              label: "Category",
              type: "Array",
            },
          ],
        };
        const productofferFilterRes = await UPCAxiosInstance.post(
          `util/filterLayout`,
          obj
        );
        console.log(productofferFilterRes.data, "category data only");
        // state.productOfferingFilterData = productofferFilterRes.data;
      } catch (error) {
        console.error(error);
      }
    },
    deleteDependentProduct(i, index){
      console.log(index, "deleteDependentProduct", i);
      // this.options?.splice(index, 1);
      this.$emit("deleted-dependent-product",i)
      console.log( "deleteDependentProduct", this.options);
      console.log( "deleteDependentProduct", this.sections);
    },
    EditDependentProducts(i, index) {
      console.log("reloadSection",this.reloadSection)
      // this.sections= this.reloadSection
      console.log("reloadSection",this.options)
      this.show_dependent_product_filter=true
      this.edit_show_dependent_product_filter = true;
      console.log(index, "EditDependentProducts", i);
    },
    editedDependentProducts(i,index){
      console.log(index, "editedDependentProducts", this.sections);
      this.$emit("edited-dependent-products",this.sections)
      this.show_dependent_product_filter=false
      this.edit_show_dependent_product_filter = false;
      console.log(index, "editedDependentProducts", i);
    },
    addDependentProducts() {
      this.sections= this.reloadSection.concat(this.sections)
      console.log("this.section", this.sections);
      this.$emit("add-dependent-products", this.sections);
      this.show_dependent_product_filter=false
      this.edit_show_dependent_product_filter=false
    },
    isMoreDependentProducts(index, val) {
      console.log("isMoreDependentProducts", val, index);
      if (val.name === "Add to cart and Add more dependent products") {
        this.addSection();
      } else {
        this.removeSection(index);
      }
    },
  async openDependentProductDrawer() {
      console.log("Opened")
      // this.reloadSection=this.sections
      // console.log("reloadSection",this.reloadSection)
      console.log(this.options)
      console.log(this.options.length)
      console.log(this.sections)
      this.updateDependentProductDrawer += 1;
      this.show_dependent_product_filter = true;
      this.edit_show_dependent_product_filter = false;
      this.updateMultiSelect++;
      console.log(this.sections)
    },
    addSection() {
      this.sections.push({});
    },
    removeSection(index) {
      if (index < this.sections.length - 1) {
        this.sections.splice(index, 1);
      } else {
        return;
      }
    },
    toggleExpand(index) {
      const indexPosition = this.expandedIndices.indexOf(index);
      if (indexPosition > -1) {
        this.expandedIndices.splice(indexPosition, 1);
      } else {
        this.expandedIndices.push(index);
      }
    },
    modifyPricing(index) {
      console.log("modifyPricing",index)
      const indexPosition = this.pricingIndex.indexOf(index);
      if (indexPosition > -1) {
        this.pricingIndex.splice(indexPosition, 1);
        this.isPricing = false;
      } else {
        this.pricingIndex.push(index);
        this.isPricing = true;
      }
    },
    chunkedSkuItems(skuArray) {
      console.log("skuArray",skuArray)
      const chunked = [];
      for (let i = 0; i < skuArray?.length; i += this?.maxRows) {
        chunked.push(skuArray?.slice(i, i + this.maxRows));
      }
      console.log("chunked",chunked)
      return chunked;
    },
    relatedProductsHeader(val) {
      if (val.name === "Dependent products") {
        console.log("this.selected=Dependent products");
        this.selected = "Dependent products";
      } else {
        this.selected = "Dependent products";
        console.log("this.selected=isDependent", val);
        this.$emit("selected-header", val);
      }
    },
    overflowCountHidden(index) {
      const element = document.getElementById("overflow-count" + index);
      if (element) {
        element.style.display = "none";
      }

      const wrapper = document.getElementById("wrapper" + index);

      if (!this.options[index].showOverflowCount) {
        wrapper.classList.add("wrap");
      } else {
        wrapper.classList.remove("wrap");
      }
    },

    handleClose(val, index) {
      this.filesArray.splice(index, 1);

      this.checkOverflow();
      console.log(val, index);
    },
    prevNextPage(nav, name) {
      if (nav === "prev") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      } else if (nav === "next") {
        sessionStorage.setItem("lastname", name);
        this.$router.push({ name: name });
      }
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
    myFunction() {
      if (this.direction == "vertical") {
        this.direction = "horizontal";
      } else if (this.direction == "horizontal") {
        this.direction = "vertical";
      }
    },
    checkOverflow() {
      // if (this.filesArray.length > 0) {
      console.log(
        document.querySelectorAll(".content-wrapper"),
        "document.querySelectorAll"
      );
      document.querySelectorAll(".content-wrapper").forEach((e, index) => {
        const container = e;
        console.log(container, "container");
        if (!container) {
          return;
        }
        this.$nextTick(() => {
          const horizontalOverflow =
            container.scrollWidth > container.offsetWidth;
          const verticalOverflow =
            container.scrollHeight > container.offsetHeight;

          console.log(
            horizontalOverflow,
            verticalOverflow,
            container.scrollWidth,
            container.offsetWidth,
            container.scrollWidth > container.offsetWidth,
            container.scrollHeight > container.offsetHeight
          );

          if (horizontalOverflow || verticalOverflow) {
            const horizontalCount = horizontalOverflow
              ? Math.ceil(
                  ((container?.scrollWidth - container?.offsetWidth) /
                    container?.scrollWidth) *
                    this.options[index]?.filesArray?.length
                )
              : 0;

            const verticalCount = verticalOverflow
              ? Math.ceil(
                  ((container?.scrollHeight - container?.offsetHeight) /
                    container?.scrollHeight) *
                    this.options[index]?.filesArray?.length
                )
              : 0;
            this.options[index]["overflowCount"] = Math.max(
              horizontalCount,
              verticalCount
            );
            console.log(
              Math.max(horizontalCount, verticalCount),
              "paoaa",
              this.options
            );
            this.overflowCount = Math.max(horizontalCount, verticalCount);
          } else {
            this.overflowCount = 0;
          }
        });
        console.log(this.overflowCount, "col_index");
      });
      // }
    },
    getFileExtension(filename) {
      const parts = filename.split(".");
      const fileExtension = parts[parts.length - 1];
      return fileExtension;
    },
  },
};
</script>

<style lang="scss">
body {
  font-family: OpenSans;
}
.container-box{
  .min_max_details{
    .details-wrapper{
      display:block;
      padding-left: 12px;
    }
  }
}
.expanded {
  display: block !important;
}
.collapsed {
  display: none;
}

.sku-wrapper {
  display: flex;
}
.dependent_product_drawer {
  .icon-angle-right-regular:before {
    display: none;
  }
}

.description {
  color: #504f4f;
  font-family: OpenSans;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.26px;
  width: 80%;
  text-align: justify;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}
.description:hover {
  display: block;
  overflow: visible; /* Unset the line clamp */
}
.select-width-half-dependent {
  width: 100%;
  display: flex;
  gap: 12px;
  margin-top: 8px;
}

.sku-column {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

li::marker {
  color: rgb(84, 189, 149);
}
.sku-list {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.sku-item {
  font-size: 12px;
  list-style: disc;
  margin: 4px 0 4px 26px;
}
.dependent_product_drawer {
  .body-panel-right {
    height: 88vh !important;
    margin: 0 8px 0 8px;
  }
}
.remove-icon {
  .icon-angle-right-regular:before {
    display: none;
  }
}
</style>
