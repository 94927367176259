// eslint-disable-next-line
/* eslint-disable */
<template>
  <div>
    <div v-if="polygonBounds">
      <GMapMap
        :center="mapCenter"
        :zoom="9"
        style="width: 100%; height: 500px"
      >
        <GMapPolygon
          :paths="polygonPaths"
          :options="polygonOptions"
        />
      </GMapMap>
    </div>
    <input v-model="searchQuery" placeholder="Search for a city" @keyup.enter="searchCity" />
  </div>
</template>

<script>
// import { ref, computed } from 'vue';
import { GMapMap, GMapPolygon } from '@fawmi/vue-google-maps';

export default {
  name: 'MapComponent',
  components: {
    GMapMap,
    GMapPolygon
  },
  data() {
    return {
      mapCenter: { lat: 10, lng: 79 },
      polygonBounds: null,
      polygonOptions: {
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        clickable: false,
        editable: false,
        draggable: false,
      },
      searchQuery: '',
    };
  },
  computed: {
    polygonPaths() {
      return this.polygonBounds;
    }
  },
  methods: {
    async fetchCityBoundaries() {
      // try {
        // const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(this.searchQuery)}&key=api`);
        // const data = await response.json();
      //   if (data.results.length > 0) {
      //     console.log(data.results[0].geometry.bounds);
      //     return data.results[0].geometry.bounds;
      //   } else {
      //     throw new Error('No results found');
      //   }
      // } catch (error) {
      //   console.error('Error fetching city boundaries:', error);
      //   return null;
      // }
    },
    async fetchAndSetPolygonBounds() {
      const bounds = await this.fetchCityBoundaries();
      if (bounds) {
        this.polygonBounds = [
          { lat: bounds.northeast.lat, lng: bounds.northeast.lng },
          { lat: bounds.northeast.lat, lng: bounds.southwest.lng },
          { lat: bounds.southwest.lat, lng: bounds.southwest.lng },
          { lat: bounds.southwest.lat, lng: bounds.northeast.lng },
        ];
        // Update map center to searched place
        this.mapCenter = {
          lat: (bounds.northeast.lat + bounds.southwest.lat) / 2,
          lng: (bounds.northeast.lng + bounds.southwest.lng) / 2
        };
      }
    },
    async searchCity() {
      await this.fetchAndSetPolygonBounds();
    }
  }
}
</script>

<style>
#map {
  height: 500px;
  width: 100%;
}
</style>
