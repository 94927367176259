<template>
     <!-- lead contact top -->
     <section class="lead-top">
    <span class="lead-sub-header">
      <!-- Orders -->
       {{loadStaticData('LANG-0070')}}
    </span>
    <section v-if="tableData.length < 0" class="lead-search-top" >

              <span class="search-container">
                <hlx-search
                :label-value="loadStaticData('LANG-0003')"
                 @search-key="searchKey"></hlx-search>
              </span>
              <span class="filter-table">
                    <hlx-button
                      class="secondary sm add-btn"
                      @click="showDrawerRight"
                    >
                      <i class="icon-filter-regular"></i>
                      <!-- <span v-if="filtertaglength != 0" class="filter-count">{{
                        filtertaglength
                      }}</span> -->
                    </hlx-button>
                  </span>
              <hlx-button
                class="primary sm"
                @click="openAddSiteDrawer"
              >
                <i class="icon-plus-circle-regular"></i> 
                <!-- Add order -->
                 {{ loadStaticData('LANG-0069')}}
              </hlx-button>
            </section>
  </section>

      <!-- Table  -->
      <section v-if="tableData.length > 0" class="lead-table" style="margin-bottom: 4rem">
        <hlx-table
            :label-value="tableModalLang"
            :column-count="orderTableHeaders.length + 2"
            :border="['table', 'header', 'horizontal']"
            :row-hover="true"
            theme="grey"
            :re-arrange-columns="toggleRearrangeModal"
            :re-arrange-heads="leadTableheadersCopy"
            @close-rearrange="toggleRearrangeModal = false"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
                <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
             @new-resize-width="resizedWidth"
            >
            <!-- {{ "S.no" }} -->
              {{ loadStaticData('LANG-0046') }}
          </hlx-table-head>
            
              <hlx-table-head
                v-for="(i, index) in orderTableHeaders"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
                 @new-resize-width="resizedWidth"
              >
                {{ i.label }}</hlx-table-head
              >
              <hlx-table-head
                :align="'center'"
                :width="70"
                :fixed="'right'"
                 @new-resize-width="resizedWidth"
                ><span class="action-icon">
                  <i
                  style="cursor: pointer"
                  class="icon-settings-regular"
                @click="reAssignOrgTableHeader()"
                  ></i> </span
              ></hlx-table-head>
            </template>

            <!-- table with data -->
            <template v-if="tableData?.length>0" #tbody>
              
            <tr v-for="(i, index) in paginatedData " id="" :key="index">
                <hlx-table-cell :align="'center'" :fixed="'left'">
                    {{   serialNumber(index)}}
                </hlx-table-cell>
                <hlx-table-cell
                  v-for="(j, col_index) in orderTableHeaders"
                  :key="col_index"
                  :align="j.align"
                  :fixed="j.fixed"
                >
                  <div
                    v-if="j.prop.toLowerCase() === 'name'"
                    @click="editSiteDrawer(index)"
                  >
                    <span  class="link-name" @click="openEditLeadContactDrawer">
                      {{ i?.name || i?.siteName}}
                    </span>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'type'">
                    <span>
                      {{ i["type"] }}
                    </span>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'contactmedium'">
                    <span>
                        {{ i['contactMedium']?.[0]?.characteristic?.street || i['siteAddress']?.[0]?.characteristic?.street }}
                    </span>
                    <section style="color:#676767;font-size:10px;display:flex;margin-top:6px;gap:12px;margin-bottom:6px;">
                        <span style="display:flex">
                           <span style="display: flex">
                            {{ 'Latitude :' }}
                           </span>
                           <span>
                               {{  i['contactMedium']?.[0]?.characteristic?.latitude || i['siteAddress']?.[0]?.characteristic?.latitude || "-" }}
                           </span>
                    </span>
                    <span style="display:flex">
                           <span style="display: flex">
                            {{ 'Longitude :' }}
                           </span>
                           <span>
                               {{  i['contactMedium']?.[0]?.characteristic?.longitude || i['siteAddress']?.[0]?.characteristic?.longitude || "-" }}
                           </span>
                    </span>
                    </section>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'sfdcid'">
                    <span>
                        {{ i["sfdcId"]}}
                      </span>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'numberofunits'">
                      <span>
                        {{ i["numberOfUnits"]}}
                      </span>
                  </div>
                </hlx-table-cell>
                <hlx-table-cell
                  :align="'center'"
                  :width="120"
                  :fixed="'right'"
                >
                  <span :id="'table-context' +   serialNumber(index -1)" class="action-icon">
                    <i
                      style="position: relative"
                      class="icon-more-vertical-filled"
                      :class="i.context === true ? 'active-action' : ''"
                      @click="crudContextMenu($event,   serialNumber(index - 1))"
                    >
                      <hlx-context-menu
                        :display="'display'"
                        :top="containertop"
                        :left="containerleft - 20"
                        :conditionvalue="150"
                        :conditiondown="2"
                        :conditionup="-38"
                        :options="computedTableAction(i)"
                        :data="index.toString()"
                        :show="i.context == true"
                        @chosen="closeCrudContextMenu($event, i,   serialNumber(index-1))"
                      />
                    </i>
                  </span>
                </hlx-table-cell>
              </tr>
            </template>
          </hlx-table>
      </section>

<!-- Table for no data -->
<section v-if="( tableData.length <= 0 && searchvalue !== '')" class="lead-table">
        <hlx-table
            :label-value="tableModalLang"
            :column-count="orderTableHeaders.length + 2"
            :border="['table', 'header']"
            :row-hover="true"
            theme="grey"
            :re-arrange-columns="toggleRearrangeModal"
            :re-arrange-heads="leadTableheadersCopy"
            @close-rearrange="toggleRearrangeModal = false"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
                <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
             @new-resize-width="resizedWidth"
            >
            <!-- {{ "S.no" }} -->
              {{ loadStaticData('LANG-0046') }}
          </hlx-table-head>
            
              <hlx-table-head
                v-for="(i, index) in orderTableHeaders"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
                 @new-resize-width="resizedWidth"
              >
                {{ i.label }}</hlx-table-head
              >
              <hlx-table-head
                :align="'center'"
                :width="70"
                :fixed="'right'"
                 @new-resize-width="resizedWidth"
                ><span class="action-icon">
                  <i
                  style="cursor: pointer"
                  class="icon-settings-regular"
                @click="reAssignOrgTableHeader()"
                  ></i> </span
              ></hlx-table-head>
            </template>

                        
            <!-- table without data )-->
            <template  #tbody>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell :colspan="orderTableHeaders.length + 2">
                        <!-- {{
                        "No data"
                      }} -->
                        {{ loadStaticData('LANG-0050') }}
                      </hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
        </template>

          </hlx-table>
      </section>


      <!-- Pagination -->
      <div
         v-if="tableData.length > 0"
               class="lead-contact-pagination-container"
        :style="{ padding: '1rem 0',width:'calc(100% - 220px)'}"
      >
        <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
          :label-value=paginationLang
          :total="tableData.length"
          enable-rows-per-page
          :rows-per-page-list="[10, 15, 20]"
          :options="{
            attributes: {
              rowsPerPageTitle: true,
              rowsPerPageResult: true,
              rowsPerPageDropdown: {
                position: {
                  top: 0,
                  right: 1,
                },
              },
            },
          }"
          @current-page="currentPage"
          @updated:rows-per-page="changeRowsPerPage"
        ></hlx-pagination>
      </div>
    
    <!-- table data image - illustration -->
    <section
      v-if="tableData.length <= 0 && searchvalue == ''"
      style="
        margin-top: 3.2rem;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        align-items: center;
      "
    >
      <img
        src="@/assets/images/leadorderillustration.svg"
        alt=""
        style="width:400px"
      />

      <span>
          <!-- Add button -->
          <hlx-button class="primary sm" @click="openAddSiteDrawer">
            <i class="icon-plus-circle-regular"></i> 
            <!-- Add order -->
             {{ loadStaticData('LANG-0069') }}
          </hlx-button>
        </span>
    </section>

</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      searchvalue: '',
      orderTableHeaders: [],
    }
  },
  computed: {
    
    tableModalLang() { 
      return {
          re_arrange_columns : this.loadStaticData('LANG-0026'),
          Cancel:this.loadStaticData('LANG-0020'),
          Apply: this.loadStaticData('LANG-0027'),
          SelectAll: this.loadStaticData('LANG-0028')
        }
    },
    paginationLang() {     
      return {
          Rows_per_page : this.loadStaticData("LANG-0012"),
          Showing: this.loadStaticData("LANG-0013"),
          of: this.loadStaticData("LANG-0014"),
          results: this.loadStaticData("LANG-0015"),
          Go_to: this.loadStaticData("LANG-0016")
        }
      
    },
}
}
</script>

<style>

</style>