<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-if="isTangible === false"
    style="
      padding: 38px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-weight: bold;
      position: sticky;
      top: 0;
      z-index: 10101;
      background: white;
    "
  >
    <span
      style="
        position: absolute;
        left: 20px;
        font-weight: 100;
        font-size: 14px;
        cursor: pointer;
      "
      @click="offerId === '' ? $router.back() : $emit('close', true)"
    >
      <i class="icon-arrow-left-regular"></i> &nbsp; Back
    </span>
    <span v-if="!isDepFlow"> Choose your plan </span>
  </div>
  <div
    v-if="isTangible === true"
    style="
      height: 70px;
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-bottom: 20px;
      padding: 20px;
      position: sticky;
      top: 0px;
      background: white;
      z-index: 101010;
    "
  >
    <span
      style="cursor: pointer"
      @click="offerId === '' ? $router.back() : $emit('close', true)"
    >
      <i class="icon-arrow-left-regular"></i> &nbsp; Back
    </span>
  </div>
  <div
    v-if="isTangible === true"
    class="configuration-container"
  >
    <div>
      <div class="image-preview-container">
        <hlx-file-upload
          :type="'slide-preview-upload'"
          :options="silderData"
          :parent-height="'450'"
          :parent-width="'450'"
          :width="'100px'"
          :height="'100px'"
          :position="isMobile ? 'horizontal' : 'vertical'"
          @selected-value="selecImg"
          @hovered-value="hoverImg"
        />
      </div>
      <div
        v-if="offerId !== ''"
        style="width: 100%; display: flex; position: sticky; top: 565px"
      >
        <hlx-button
          v-if="reconfigure"
          class="secondary sm"
          style="width: 565px"
          @click="
            transformData(offer);
            emitSelectedConfig();
          "
        >
          <i class="icon-shopping-cart-regular"></i> &nbsp; Update
        </hlx-button>
        <hlx-button
          v-if="isDepFlow"
          class="primary sm"
          style="width: 565px"
          @click="nextDepFlow"
        >
          Continue
        </hlx-button>
        <hlx-button
          v-else-if="!isDepFlow && !reconfigure"
          class="secondary sm"
          style="width: 565px"
          @click="
            transformData(offer);
            emitSelectedConfig();
          "
        >
          <i class="icon-shopping-cart-regular"></i> &nbsp; Add to cart
        </hlx-button>
        <!-- <hlx-button
          class="primary sm"
          style="width: 100%"
        >
          <i class="icon-bolt-alt-regular"></i> &nbsp; Buy now
        </hlx-button> -->
      </div>
    </div>
    <div class="offer-container">
      <div style="display: flex; flex-direction: column">
        <span
          class="title"
          style="font-size: 24px"
        >
          <b v-if="offer.offerType === 'Atomic'">{{ offerDetails?.name }}</b>
          <b v-if="offer.offerType === 'Bundle'">{{ offer?.name }}</b>
        </span>
        <span style="font-size: 12px; color: #a6a6a6; margin-top: 5px">
          {{ offerDetails.category[offerDetails.category.length - 1] }}
        </span>
      </div>
      <!-- <span class="rating" style="width: 200px;">
            <hlx-rating :rating="offerDetails.rating" :read-only="true" ></hlx-rating>
        </span> -->
      <div
        style="
          display: flex;
          /* grid-template-rows: 50% 50%; */
          /* gap: 17%; */
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          margin-top: 20px;
        "
      >
        <span
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          "
        >
          <span v-if="offer.offerType === 'Atomic'"
            >Brand:
            <span style="color: #565553">{{ offerDetails?.brand }}</span></span
          >
          <span
            v-if="availableSuppliers?.length > 0"
            style="display: flex; margin-top: 20px; align-items: center"
          >
            <span>Supplier: </span>&nbsp;&nbsp;
            <span>
              <hlx-select
                :pre-value="defaultSupplierValue"
                label="customName"
                :prop-value="'customName'"
                :options="availableSuppliers"
                :single-border="true"
                :default-emit="true"
                @selected-value="supplierChanged"
              ></hlx-select
            ></span>
          </span>
        </span>
        <span style="display: flex; margin-top: 20px; align-items: center">
          <span>Pricing type: </span>&nbsp;&nbsp;
          <span>
            <hlx-select
              v-model:value="offerDetailsPricingType"
              :single-border="true"
              :pre-value="offerDetailsPricingType"
              :options="pricingTypeOptions"
            >
            </hlx-select
          ></span>
        </span>
        <span style="display: flex; margin-top: 20px; align-items: center">
          <span>Quantity: </span>&nbsp;&nbsp;
          <span style="width: 60px">
            <hlx-input
              v-model:value="productQuantity"
              type="number"
              :single-border="true"
              :pre-val="1"
              :min-limit="1"
              :max-limit="
                offer?.prodSpecCharValue[0]?.tiers[
                  offer?.prodSpecCharValue[0]?.tiers?.length - 1
                ]?.max
              "
            >
            </hlx-input
          ></span>
        </span>
      </div>
      <div
        v-if="offer.offerType === 'Atomic'"
        class="pricing"
        style="margin-top: 20px; display: flex; align-items: center; gap: 16px"
      >
        <h2
          v-show="ruleExec?.discountPrice != null || undefined"
          class="new-price"
        >
          {{
            currencyFormatter(ruleExec?.discountPrice, {
              currency: offerDetails.unit,
            })
          }}
          <span style="font-size: 14px; font-weight: 100">{{
            billingPeriod !== '' && billingPeriod !== undefined
              ? '/' + billingPeriod
              : ''
          }}</span>
        </h2>

        <h3
          v-if="oldPrice !== null"
          class="old-price"
        >
          {{
            currencyFormatter(offerDetails.price, {
              currency: offerDetails.unit,
            })
          }}
          <span style="font-size: 14px; font-weight: 100">{{
            billingPeriod !== '' && billingPeriod !== undefined
              ? '/' + billingPeriod
              : ''
          }}</span>
        </h3>
        <h2
          v-else
          class="new-price"
        >
          {{
            currencyFormatter(newPrice, {
              currency: $store?.getters?.getDefaultSettings?.currency?.unit,
            })
          }}
          <span style="font-size: 14px; font-weight: 100">{{
            billingPeriod !== '' && billingPeriod !== undefined
              ? '/' + billingPeriod
              : ''
          }}</span>
        </h2>

        <!-- discount calucation -->
        <div
          v-if="oldPrice !== null"
          style="display: flex; align-items: center; gap: 5px"
          class="card-price-discount"
        >
          <div class="pricing-details-card">
            <h5 style="margin-bottom: 10px">Price Details</h5>
            <div class="price-item">
              <span>Price</span>
              <span
                class="price"
                style="font-weight: 100"
              >
                {{
                  currencyFormatter(offerDetails.price, {
                    currency: offerDetails.unit,
                  })
                }}
                {{
                  billingPeriod !== '' && billingPeriod !== undefined
                    ? '/' + billingPeriod
                    : ''
                }}</span
              >
            </div>
            <div class="price-item">
              <span>Product Discount</span>
              <span
                class="discount"
                style="color: #02b55c"
              >
                - &nbsp;
                <p
                  v-if="
                    ruleExec?.appliedDiscount?.discountType?.name ==
                    'Percentage'
                  "
                >
                  {{ ruleExec?.appliedDiscount?.discountType?.value + '%' }}
                </p>
                <p v-else>
                  {{
                    $store?.getters?.getDefaultSettings?.currency?.symbol +
                    ruleExec?.appliedDiscount?.discountType?.value
                  }}
                </p>
              </span>
            </div>
            <hr />
            <div
              class="price-item"
              style="font-weight: bold"
            >
              <span>Selling Price</span>
              <span class="final-price">
                {{
                  currencyFormatter(ruleExec?.discountPrice, {
                    currency: offerDetails.unit,
                  })
                }}
              </span>
            </div>
          </div>
          <i
            class="icon-info-circle-regular"
            style="color: rgba(133, 133, 133, 1)"
          >
          </i>

          <p
            style="
              color: rgba(38, 94, 75, 1);
              font-weight: 900;
              font-size: 14px;
            "
          >
            <span
              v-if="
                ruleExec?.appliedDiscount?.discountType?.name == 'Percentage'
              "
            >
              {{ ruleExec?.appliedDiscount?.discountType?.value + '%  Off' }}
            </span>
            <span v-else>
              {{
                $store?.getters?.getDefaultSettings?.currency?.symbol +
                ruleExec?.appliedDiscount?.discountType?.value +
                'off'
              }}
            </span>
            <!-- {{ ruleExec?.appliedDiscount?.name }} -->
          </p>
        </div>
        <!-- <span
        v-if="offerDetails.price === 0"
        style="color: #ff5050; font-size: 14px"
        >
        This combination does not exist
      </span> -->
      </div>
      <div
        v-if="offer.offerType === 'Bundle'"
        class="pricing"
        style="margin-top: 20px; display: flex; align-items: center; gap: 16px"
      >
        <h2 class="new-price">
          {{
            currencyFormatter(getBundlePrice()?.totalPrice?.value, {
              currency: getBundlePrice()?.totalPrice?.unit,
            })
          }}
          <span
            v-if="getBundlePrice()?.priceType !== 'One time'"
            style="font-size: 14px; font-weight: 100"
            >{{
              billingPeriod !== '' && billingPeriod !== undefined
                ? '/' + billingPeriod
                : ''
            }}</span
          >
        </h2>

        <!-- discount calucation -->
        <!-- <span
        v-if="offerDetails.price === 0"
        style="color: #ff5050; font-size: 14px"
        >
        This combination does not exist
      </span> -->
      </div>

      <span style="margin-top: 4px; display: flex; color: #777777">{{
        offer.unitOfMeasure
      }}</span>
      <span style="display: flex; margin-top: 20px; align-items: center">
        <span style="color: #777777">Cost: </span>&nbsp;&nbsp;
        <span>
          {{
            currencyFormatter(selectedConfigurationCost, {
              currency: $store?.getters?.getDefaultSettings?.currency?.unit,
            })
          }}</span
        >
      </span>
      <span style="display: flex; margin-top: 20px; align-items: center">
        <span style="color: #777777">Margin: </span>&nbsp;&nbsp;
        <span> {{ selectedConfigurationMargin + '%' }}</span>
      </span>
      <hr
        v-if="offer.offerType === 'Atomic'"
        style="margin-top: 20px"
      />
      <div
        v-for="(items, index) in offer?.prodSpecCharValueUse"
        :key="index"
        :class="items?.configurable === true ? 'configurable' : ''"
        style="margin-top: 20px"
        :style="
          items?.type?.toLowerCase() === 'swatch'
            ? { display: 'flex !important' }
            : {}
        "
      >
        <span
          v-if="
            items?.configurable === true &&
            items?.type?.toLowerCase() === 'swatch'
          "
        >
          <hlx-swatch
            :options="getSwatchData(items)"
            :v-model="
              items.default?.valueSuffix !== ''
                ? items.default?.value + ' ' + items.default?.valueSuffix
                : items.default?.value
            "
            :label="'colorCode'"
            :value="'value'"
            @selected-value="handleSelectedColor($event, items)"
            @hovered-value="handlehoverColor"
          >
            <div style="margin-bottom: 10px">
              {{ items?.name }}: <b>{{ displayHeaderName }}</b>
            </div>
          </hlx-swatch>
        </span>
        <span
          v-if="
            items?.configurable === true &&
            items?.type?.toLowerCase() === 'rectangular select'
          "
          >{{ items?.name }}:
          <b v-if="items?.default?.value === null">{{
            items?.productSpecCharacteristicValue[0]?.value +
            ' ' +
            items?.productSpecCharacteristicValue[0]?.valueSuffix
          }}</b>
          <b v-else>{{
            items?.default?.value + ' ' + items?.default?.valueSuffix
          }}</b>
        </span>
        <span
          v-if="
            items?.configurable === true &&
            items?.type?.toLowerCase() === 'rectangular select'
          "
          style="margin-top: 20px; display: flex; flex-direction: column"
        >
          <hlx-rectangle-select
            :prop-value="'name'"
            :options="getOptionRectangle(items)"
            :position="'horizontal'"
            :pre-value="
              items.default?.value !== null
                ? items.default?.valueSuffix !== ''
                  ? items.default?.value + ' ' + items.default?.valueSuffix
                  : items.default?.value
                : items.productSpecCharacteristicValue[0]?.valueSuffix !== ''
                ? items.productSpecCharacteristicValue[0]?.value +
                  ' ' +
                  items.productSpecCharacteristicValue[0]?.valueSuffix
                : items.productSpecCharacteristicValue[0]?.value
            "
            :show-ellipsis="false"
            @selected-option="selected($event, items)"
            @hovered-option="hovered"
          >
          </hlx-rectangle-select>
        </span>
        <span
          v-if="
            items?.configurable === true &&
            items?.type?.toLowerCase() === 'dropdown'
          "
          >{{ items?.name }}:
          <b>{{
            items?.default +
            ' ' +
            items.productSpecCharacteristicValue[0]?.valueSuffix
          }}</b></span
        >
        <span
          v-if="
            items?.configurable === true &&
            items?.type?.toLowerCase() === 'dropdown'
          "
          style="
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            width: 200px;
          "
        >
          <hlx-select
            :prop-value="'name'"
            :options="getOptionRectangle(items)"
            :pre-value="
              items.default?.valueSuffix !== ''
                ? items.default.value + ' ' + items.default?.valueSuffix
                : items.default.value
            "
            :show-ellipsis="false"
            @selected-option="selected($event, items)"
            @hovered-option="hovered"
          >
          </hlx-select>
        </span>
      </div>
      <!-- discount section -->
      <div
        v-show="
          displayedItems.length > 0 || ruleExec.discountPrice != undefined
        "
      >
        <span
          style="
            padding: 20px 0;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #d8d8d8;
          "
          ><b>
            <!-- <i
              class="icon-percent-regular"
              style="
                font-weight: 900;
                color: rgba(84, 189, 149, 1);
                margin-left: -10%;
                margin-right: 8%;
              "
            ></i
            > -->
            {{ 'Available discounts' }}</b
          ></span
        >
        <div
          v-show="ruleExec.discountPrice != null"
          style="
            list-style: none;
            display: flex;
            font-size: 14px;
            align-items: center;
            gap: 5px;
            width: 100%;
            margin-top: 10px;
          "
        >
          <i
            class="icon-tag-regular"
            style="font-size: 14px; color: rgba(78, 116, 101, 1)"
          ></i>
          <p style="width: 18%; font-size: 14px">
            <b>{{ ruleExec?.appliedDiscount?.name }}</b>
          </p>
          <p
            style="
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            {{ ruleExec?.appliedDiscount?.description }}
          </p>
          <p style="color: rgba(84, 189, 149, 1)">
            <i class="icon-check-regular"></i>Applied
          </p>
        </div>
        <!-- <hr style="margin-top: 30px" /> -->
        <br />
        <ul
          style="line-height: 2em; margin-top: -10px"
          v-show="displayedItems.length > 0"
        >
          <li
            v-for="(item, index) in displayedItems"
            :key="index"
            style="
              list-style: none;
              display: flex;
              font-size: 14px;
              align-items: center;
              gap: 5px;
              width: 100%;
            "
          >
            <i
              class="icon-tag-regular"
              style="font-size: 14px; color: rgba(78, 116, 101, 1)"
            ></i>
            <p style="width: 18%; font-size: 14px">
              <!-- <b v-if="item.type == 'Product offering'">Product discount</b> -->
              <b>{{ item.name }}</b>
            </p>
            <p
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ item?.description }}
            </p>
          </li>
        </ul>
        <p
          v-show="displayedItems.length > 3"
          style="
            color: rgba(40, 121, 183, 1);
            cursor: pointer;
            font-size: 14px;
            margin-top: 10px;
          "
          @click="toggleShowMore"
        >
          {{ showMore ? 'Show less' : 'Show more' }}
        </p>
      </div>
      <!-- <div
        class="configuration"
        style="margin-top: 20px; display: flex; flex-direction: column"
      >
        <span
         v-if="items.type.toLowerCase() === 'rectangular select'"
          >Storage: <b>{{ initialSelectedValue }}</b></span
        >
        <span v-if="items.type.toLowerCase() === 'rectangular select'" style="margin-top: 20px">
          <hlx-rectangle-select
            :v-model="'name'"
            :options="optionRectangle"
            :position="'horizontal'"
            :pre-value="initialSelectedValue"
            :show-ellipsis="false"
            @selected-option="selected"
            @hovered-option="hovered"
          >
          </hlx-rectangle-select>
        </span>
      </div> -->
      <hr
        v-if="
          offer.offerType === 'Atomic' && offer.additionalCharges.length > 0
        "
        style="margin-top: 30px"
      />
      <div
        v-if="offer.additionalCharges.length > 0"
        class="decription"
        style="margin-top: 20px; margin-bottom: 20px"
      >
        <span
          style="
            padding: 20px 20px 20px 0;
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgb(216, 216, 216);
          "
          ><b>Additional charges</b></span
        >
        <div
          v-for="item in offer.additionalCharges"
          :key="item"
          style="display: flex; min-height: 60px; padding: 20px 0"
        >
          <span style="min-width: 30px; font-size: 20px"
            ><i
              class="icon-dollar-send-square-regular"
              style="color: #4e7465"
            ></i
          ></span>
          <div>
            <div style="display: flex; gap: 20px; margin-bottom: 10px">
              <span style="font-weight: bold">{{ item.name }}</span
              ><span
                style="margin: 1px 0; border-right: 2px solid #d8d8d8"
              ></span
              ><span>{{
                currencyFormatter(item.price, {
                  currency: offerDetails.unit,
                })
              }}</span>
            </div>
            <div>{{ item.description }}</div>
          </div>
        </div>
      </div>

      <hr
        v-if="
          (offer.offerType === 'Atomic' && offerDetails?.description !== '') ||
          (offer.offerType === 'Bundle' && offer?.description !== '')
        "
        style="margin-top: 30px"
      />
      <div
        v-if="offer.offerType === 'Atomic' && offerDetails?.description !== ''"
        class="decription"
        style="margin-top: 20px; margin-bottom: 20px"
      >
        <span><b>Description</b></span>
        <p
          style="margin-top: 20px; text-align: justify; word-wrap: break-word"
          v-html="offerDetails.description"
        ></p>
      </div>
      <div
        v-if="offer.offerType === 'Bundle' && offer?.description !== ''"
        class="decription"
        style="margin-top: 20px; margin-bottom: 20px"
      >
        <span><b>Description</b></span>
        <p
          style="margin-top: 20px; text-align: justify"
          v-html="offer.description"
        ></p>
      </div>
      <hr
        v-if="
          offer.offerType === 'Atomic' &&
          offerDetails?.specification?.length > 0
        "
        style="margin-top: 30px"
      />
      <hr
        v-if="offer.offerType === 'Bundle'"
        style="margin-top: 30px"
      />
      <div
        class="decription"
        style="
          margin-top: 20px;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
        "
      >
        <span
          v-if="offer.offerType === 'Bundle'"
          style="margin-bottom: 20px"
          ><b>Products</b></span
        >
        <bundlepreviewproductcomponent
          v-if="offer.offerType === 'Bundle'"
          :bundle-preview-offers="getBunldeItems(offer.bundledProductOffering)"
        />
      </div>
      <!-- <hr v-if="offer.offerType === 'Atomic'" style="margin-top: 30px" /> -->
      <div
        v-if="offerDetails?.specification?.length > 0"
        class="spec-box"
        style="margin-top: 40px; margin-bottom: 10px"
      >
        <span
          style="
            padding: 20px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #d8d8d8;
          "
          ><b>{{ 'Specifications' }}</b></span
        >
        <span v-if="offerDetails?.specification?.length > 0">
          <span
            v-for="item in offerDetails.specification"
            :key="item"
            style="display: flex; flex-direction: row; padding: 20px"
          >
            <span style="min-height: 20px; width: 300px">{{ item?.name }}</span>
            <span style="display: flex; flex-direction: column; gap: 5px">
              <span
                v-for="(specItem, index) in item.productSpecCharacteristicValue"
                :key="index"
              >
                <span v-if="index === 0">
                  {{
                    specItem.value +
                    (specItem?.valueSuffix !== ''
                      ? ' ' + specItem?.valueSuffix
                      : specItem?.valueSuffix)
                  }}
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <!-- <div class="spec-box" style="margin-top: 20px">
        <span style="padding: 20px; display: flex; align-items: center; border-bottom: 1px solid #d8d8d8;"><b>{{ 'Add ons' }}</b></span>
        <span style="display: flex; flex-direction: row; padding: 20px">
            
        </span>
      </div> -->
      <!-- <hlx-accordion>
        <template #item-title>
            {{ 'Documents' }}
          </template>
          <template #item-body>
            <displayDocuments :data="offer.attachment"></displayDocuments>

          </template>
      </hlx-accordion> -->
      <div
        v-if="offer?.attachment?.length > 0"
        class="spec-box"
        style="margin-top: 10px; border: none; min-height: max-content"
      >
        <span
          style="
            padding: 20px 20px 20px 0;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #d8d8d8;
          "
          ><b>{{ 'Documents' }}</b></span
        >
        <span
          style="display: flex; flex-direction: row; padding: 20px 0px 20px 0"
        >
          <displayDocuments :data="offer.attachment"></displayDocuments>
        </span>
      </div>
      <div
        v-if="offer?.relatedProduct?.length > 0"
        class="spec-box"
        style="margin-top: 20px; border: none; min-height: max-content"
      >
        <span
          style="
            padding: 20px 0;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #d8d8d8;
          "
          ><b>{{ 'Recommended products' }}</b></span
        >
        <span style="display: flex; flex-direction: row; padding: 20px 0">
          <relatedProducts :data="offer.relatedProduct"></relatedProducts>
        </span>
      </div>
    </div>
  </div>
  <div
    v-else-if="isTangible === false"
    class="configuration-container-v2"
    style="margin-top: 0px"
  >
    <div class="configuration-container-v2-child">
      <div
        class="offer-preview-card"
        style="
          border: 2px solid #d8d8d8;
          border-radius: 5px;
          padding: 20px;
          height: max-content;
        "
      >
        <span
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
          "
        >
          <span style="width: 60px">
            <hlx-file-upload
              :file-type="'.jpg,.jpeg,.png'"
              :file-size-limit="'20mb'"
              :uploadtype="'Rectangle'"
              :style-background="backgrounStyleupload"
              :height="80"
              :width="80"
              :read-only="true"
              :custom-icon="icon"
              :type="'profile-upload'"
              :name-display="nameDisplay"
              :content="'Import'"
              :profile-pic-preview="silderData[0]?.data"
            />
          </span>
          <span
            v-if="offer.offerType === 'Atomic'"
            style="color: #54bd95"
          >
            {{ offerDetails?.name }}
          </span>
          <span
            v-if="offer.offerType === 'Bundle'"
            style="color: #54bd95"
          >
            {{ offer?.name }}
          </span>
          <span style="font-size: 26px; font-weight: bold; margin-bottom: 10px">
            <span
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 5px;
              "
            >
              <!-- {{
                currencyFormatter(offerDetails.price, {
                  currency: offerDetails.unit,
                })
              }} -->
              <div
                class="pricing"
                style="
                  margin-top: 20px;
                  display: flex;
                  align-items: center;
                  gap: 16px;
                "
              >
                <h5
                  v-show="ruleExec?.discountPrice != null || undefined"
                  class="new-price"
                >
                  {{
                    currencyFormatter(ruleExec?.discountPrice, {
                      currency: offerDetails.unit,
                    })
                  }}
                  <span style="font-size: 14px; font-weight: 100">{{
                    billingPeriod !== '' && billingPeriod !== undefined
                      ? '/' + billingPeriod
                      : ''
                  }}</span>
                </h5>

                <h6
                  v-if="oldPrice !== null"
                  class="old-price"
                >
                  {{
                    currencyFormatter(offerDetails.price, {
                      currency: offerDetails.unit,
                    })
                  }}
                  <span style="font-size: 14px; font-weight: 100">{{
                    billingPeriod !== '' && billingPeriod !== undefined
                      ? '/' + billingPeriod
                      : ''
                  }}</span>
                </h6>
                <h5
                  v-else
                  class="new-price"
                >
                  <span v-if="offer.offerType === 'Atomic'">
                    {{
                      currencyFormatter(newPrice, {
                        currency: offerDetails.unit,
                      })
                    }}
                  </span>
                  <span v-if="offer.offerType === 'Bundle'">
                    {{
                      currencyFormatter(getBundlePrice()?.totalPrice?.value, {
                        currency: getBundlePrice()?.totalPrice?.unit,
                      })
                    }}
                  </span>
                  <!-- <span
                    v-if="offer.unitOfMeasure !== 'Per billing period'"
                    style="font-size: 14px; font-weight: 100"
                  >
                    {{
                      intangibleSelectedBillingPeriod !== undefined
                        ? '/ ' +
                          tempIntangibleSelectedBillingPeriod.replace('(s)', '')
                        : ''
                    }}
                  </span> -->
                </h5>

                <!-- discount calucation -->

                <!-- <span
        v-if="offerDetails.price === 0"
        style="color: #ff5050; font-size: 14px"
        >
        This combination does not exist
      </span> -->
              </div>

              <div
                v-if="oldPrice !== null"
                style="display: flex; align-items: center; gap: 5px"
                class="card-price-discount"
              >
                <div class="pricing-details-card">
                  <h5 style="margin-bottom: 10px">Price Details</h5>
                  <div class="price-item">
                    <span>Cost</span>
                    <span
                      class="price"
                      style="font-weight: 100"
                    >
                      {{
                        currencyFormatter(selectedConfigurationCost, {
                          currency: offerDetails.unit,
                        })
                      }}
                      {{
                        billingPeriod !== '' && billingPeriod !== undefined
                          ? '/' + billingPeriod
                          : ''
                      }}</span
                    >
                  </div>
                  <div class="price-item">
                    <span>Price</span>
                    <span
                      class="price"
                      style="font-weight: 100"
                    >
                      {{
                        currencyFormatter(offerDetails.price, {
                          currency: offerDetails.unit,
                        })
                      }}
                      {{
                        billingPeriod !== '' && billingPeriod !== undefined
                          ? '/' + billingPeriod
                          : ''
                      }}</span
                    >
                  </div>
                  <div class="price-item">
                    <span>Product Discount</span>
                    <span
                      class="discount"
                      style="color: #02b55c"
                    >
                      - &nbsp;
                      <p
                        v-if="
                          ruleExec?.appliedDiscount?.discountType?.name ==
                          'Percentage'
                        "
                      >
                        {{
                          ruleExec?.appliedDiscount?.discountType?.value + '%'
                        }}
                      </p>
                      <p v-else>
                        {{
                          $store?.getters?.getDefaultSettings?.currency
                            ?.symbol +
                          ruleExec?.appliedDiscount?.discountType?.value
                        }}
                      </p>
                    </span>
                  </div>
                  <hr />
                  <div
                    class="price-item"
                    style="font-weight: bold"
                  >
                    <span>Selling Price</span>
                    <span class="final-price">
                      {{
                        currencyFormatter(ruleExec?.discountPrice, {
                          currency: offerDetails.unit,
                        })
                      }}
                    </span>
                  </div>
                  <div
                    class="price-item"
                    style="font-weight: 100"
                  >
                    <span>Margin</span>
                    <span
                      class="final-price"
                      style="font-weight: 100"
                    >
                      {{ selectedConfigurationMargin + '%' }}
                    </span>
                  </div>
                </div>
                <i
                  class="icon-info-circle-regular"
                  style="color: rgba(133, 133, 133, 1)"
                >
                </i>

                <p
                  style="
                    color: rgba(38, 94, 75, 1);
                    font-weight: 900;
                    font-size: 14px;
                  "
                >
                  <span
                    v-if="
                      ruleExec?.appliedDiscount?.discountType?.name ==
                      'Percentage'
                    "
                  >
                    {{
                      ruleExec?.appliedDiscount?.discountType?.value + '%  Off'
                    }}
                  </span>
                  <span v-else>
                    {{
                      $store?.getters?.getDefaultSettings?.currency?.symbol +
                      ruleExec?.appliedDiscount?.discountType?.value +
                      'off'
                    }}
                  </span>
                </p>
              </div>

              <span
                v-if="offer.unitOfMeasure === 'Per billing period'"
                style="font-size: 14px; font-weight: 100"
              >
                {{ offer.unitOfMeasure }}
              </span>
              <span v-else style="font-size: 14px; font-weight: 100">
                {{ offer.unitOfMeasure }}
                {{
                  intangibleSelectedBillingPeriod !== undefined
                    ? " / " +
                      tempIntangibleSelectedBillingPeriod.replace("(s)", "")
                    : ""
                }}</span>
            </span>
          </span>
        </span>
        <span
          v-if="offer.offerType === 'Atomic'"
          style="display: flex; flex-direction: column"
        >
          <span style="color: #949494">Specification</span>
          <br />
          <span style="display: flex; flex-direction: column; gap: 16px">
            <span
              v-for="item in intangibleSpec"
              :key="item"
              style="display: flex; gap: 10px"
            >
              <i
                class="icon-check-filled"
                style="
                  color: rgb(84, 189, 149);
                  padding: 3px;
                  background: rgba(84, 189, 149, 0.1);
                  border-radius: 100%;
                  height: 20px !important;
                "
              ></i>
              <span
                v-if="
                  item?.default?.value !== null &&
                  item?.default !== null &&
                  item?.default !== '' &&
                  item?.default?.value !== ''
                "
                style="line-height: 1.5"
              >
                {{
                  item?.name +
                  ':' +
                  ' ' +
                  item?.default?.value +
                  ' ' +
                  item?.default?.valueSuffix
                }}
              </span>
              <span v-else>
                {{
                  item?.name +
                  ':' +
                  ' ' +
                  item?.productSpecCharacteristicValue[0]?.value +
                  ' ' +
                  item?.productSpecCharacteristicValue[0]?.valueSuffix
                }}
              </span>
            </span>
          </span>
        </span>
        <span
          v-if="offer.offerType === 'Bundle'"
          style="display: flex; flex-direction: column"
        >
          <span
            v-if="offer.offerType === 'Bundle'"
            style="margin-bottom: 20px"
            ><b>Products</b></span
          >
          <bundlepreviewproductcomponent
            v-if="offer.offerType === 'Bundle'"
            :bundle-preview-offers="
              getBunldeItems(offer.bundledProductOffering)
            "
          />
        </span>
      </div>
      <br />
      <hlx-button
        v-if="isDepFlow"
        class="primary sm"
        style="width: 100%"
        @click="nextDepFlow"
      >
        Continue
      </hlx-button>
      <hlx-button
        v-if="offerId !== '' && isDepFlow == false"
        class="secondary sm"
        style="width: 100%"
        @click="
          transformData(offer);
          emitSelectedConfig();
        "
      >
        <i class="icon-shopping-cart-regular"></i> &nbsp; Add to cart
      </hlx-button>
    </div>
    <div style="display: flex; flex-direction: column">
      <div
        class="intangible-configuration-section"
        style="
          height: max-content;
          background: #d9d9d91f;
          padding: 20px;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          gap: 30px;
        "
      >
        <span
          v-if="availableSuppliers?.length > 0"
          style="display: flex; margin-top: 20px; align-items: center"
        >
          <span>Supplier: </span>
          <span>
            <hlx-select
              :pre-value="defaultSupplierValue"
              label="customName"
              :prop-value="'customName'"
              :options="availableSuppliers"
              :single-border="true"
              :default-emit="true"
              @selected-value="supplierChanged"
            ></hlx-select
          ></span>
        </span>
        <div
          style="
            display: flex;
            margin-top: 20px;
            gap: 30px;
            align-items: center;
          "
        >
          <span style="width: 50%; text-align: right; padding-right: 10px"
            >Pricing type:
          </span>
          <span style="width: 50%; padding-right: 150px">
            <hlx-select
              v-model:value="offerDetailsPricingType"
              :pre-value="offerDetailsPricingType"
              :options="pricingTypeOptions"
            >
            </hlx-select
          ></span>
        </div>
        <div style="display: flex; align-items: center; gap: 30px">
          <span style="width: 50%; text-align: right; padding-right: 10px"
            >Billing period</span
          >
          <span style="width: 50%; padding-right: 150px">
            <hlx-select
              v-model="intangibleSelectedBillingPeriod"
              :pre-value="intangibleSelectedBillingPeriod"
              :options="intangibleBillingPeriod"
              @selected-value="selectedBllingPeriodConfig"
            ></hlx-select>
          </span>
        </div>
        <div style="display: flex; align-items: center; gap: 30px">
          <span style="width: 50%; text-align: right; padding-right: 10px"
            >Quantity</span
          >
          <span style="width: 50%; padding-right: 150px">
            <hlx-input
              v-model:value="productQuantity"
              type="number"
              :pre-val="1"
              :min-limit="1"
              :max-limit="
                offer?.prodSpecCharValue[0]?.tiers[
                  offer?.prodSpecCharValue[0]?.tiers?.length - 1
                ]?.max
              "
            >
            </hlx-input>
          </span>
        </div>
        <div style="display: flex; align-items: center; gap: 30px">
          <span style="width: 50%; text-align: right; padding-right: 10px"
            >Commitment period</span
          >
          <span style="width: 50%; padding-right: 150px">
            <hlx-select
              v-model:value="intangibleSelectedCommitmentLength"
              :pre-value="intangibleSelectedCommitmentLength"
              :options="intangiblecommitmentLength"
              @selected-value="selectedCommitmentLengthConfig"
            ></hlx-select>
          </span>
        </div>
        <div style="display: flex; align-items: center; gap: 30px">
          <span style="width: 50%; text-align: right; padding-right: 10px"
            >End of commitment</span
          >
          <span style="width: 50%; padding-right: 150px">
            <hlx-input
              type="radio-buttons"
              :options="intangibleRadioOptions"
            ></hlx-input>
          </span>
        </div>
        <div>
          <div
            v-for="(items, index) in offer?.prodSpecCharValueUse"
            :key="index"
            :class="items?.configurable === true ? 'configurable' : ''"
          >
            <span
              v-if="
                items?.configurable === true &&
                items?.type?.toLowerCase() === 'rectangular select'
              "
              >{{ items?.name }}:
              <b>{{
                items?.default?.value + ' ' + items?.default?.valueSuffix
              }}</b></span
            >
            <span
              v-if="
                items?.configurable === true &&
                items?.type?.toLowerCase() === 'rectangular select'
              "
              style="margin-top: 20px; display: flex; flex-direction: column"
            >
              <hlx-rectangle-select
                :prop-value="'name'"
                :options="getOptionRectangle(items)"
                :position="'horizontal'"
                :pre-value="
                  items.default?.valueSuffix !== ''
                    ? items.default.value + ' ' + items.default?.valueSuffix
                    : items.default.value
                "
                :show-ellipsis="false"
                @selected-option="selected($event, items)"
                @hovered-option="hovered"
              >
              </hlx-rectangle-select>
            </span>
            <br
              v-if="
                items?.configurable === true &&
                items?.type?.toLowerCase() === 'dropdown'
              "
            />
            <br
              v-if="
                items?.configurable === true &&
                items?.type?.toLowerCase() === 'dropdown'
              "
            />
            <div
              style="
                display: flex;
                align-items: center;
                width: 100%;
                gap: 30px;
                justify-content: center;
              "
            >
              <div
                v-if="
                  items?.configurable === true &&
                  items?.type?.toLowerCase() === 'dropdown'
                "
                style="width: 50%; text-align: right"
              >
                {{ items?.name }}:
              </div>
              <div
                v-if="
                  items?.configurable === true &&
                  items?.type?.toLowerCase() === 'dropdown'
                "
                style="
                  display: flex;
                  flex-direction: column;
                  width: 50%;
                  padding-right: 150px;
                "
              >
                <hlx-select
                  :prop-value="'name'"
                  :options="getOptionRectangle(items)"
                  :pre-value="
                    items.default?.valueSuffix !== ''
                      ? items.default.value + ' ' + items.default?.valueSuffix
                      : items.default.value
                  "
                  :show-ellipsis="false"
                  @selected-value="selected($event, items)"
                  @hovered-option="hovered"
                >
                </hlx-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div
        v-if="offer.offerType === 'Atomic' && offerDetails?.description !== ''"
        class="decription"
        style="margin-top: 20px; margin-bottom: 20px"
      >
        <span><b>Description</b></span>
        <p
          style="margin-top: 20px; text-align: justify"
          v-html="offerDetails.description"
        ></p>
      </div>
      <div
        v-if="offer.offerType === 'Bundle' && offer?.description !== ''"
        class="decription"
        style="margin-top: 20px; margin-bottom: 20px"
      >
        <span><b>Description</b></span>
        <p
          style="margin-top: 20px; text-align: justify"
          v-html="offer.description"
        ></p>
      </div>
      <br />
      <div
        v-if="offer.additionalCharges.length > 0"
        class="decription"
        style="margin-top: 20px; margin-bottom: 20px"
      >
        <span
          style="
            padding: 20px 20px 20px 0;
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgb(216, 216, 216);
          "
          ><b>Additional charges</b></span
        >
        <div
          v-for="item in offer.additionalCharges"
          :key="item"
          style="display: flex; min-height: 60px; padding: 20px 0"
        >
          <span style="min-width: 30px; font-size: 20px"
            ><i
              class="icon-dollar-send-square-regular"
              style="color: #4e7465"
            ></i
          ></span>
          <div>
            <div style="display: flex; gap: 20px; margin-bottom: 10px">
              <span style="font-weight: bold">{{ item.name }}</span
              ><span
                style="margin: 1px 0; border-right: 2px solid #d8d8d8"
              ></span
              ><span>{{
                currencyFormatter(item.price, {
                  currency: offerDetails.unit,
                })
              }}</span>
            </div>
            <div>{{ item.description }}</div>
          </div>
        </div>
      </div>

      <hr style="margin-top: 30px; margin-bottom: 30px" />
      <span
        v-if="offer.relatedProduct.length > 0"
        style="font-size: 16px"
      >
        <b>Recommended products</b>
      </span>
      <br />
      <relatedProducts :data="offer.relatedProduct"></relatedProducts>
    </div>
  </div>
  <!-- <div v-else>
    <img
      src="../../assets/images/Preview illustration.svg"
      alt=""
    />
  </div> -->
</template>

<script>
import {
  DMNAxiosInstance,
  // MLTAxiosInstance,
  UPCAxiosInstance,
} from '@/config/axiosConfig';

import displayDocuments from '../../components/UPC/productOffering/displayDocuments.vue';
import relatedProducts from '../../components/UPC/productOffering/relatedProductsListing.vue';
import bundlepreviewproductcomponent from '../../components/bundlePreviewProductComponent';
export default {
  components: {
    displayDocuments,
    relatedProducts,
    bundlepreviewproductcomponent,
  },
  props: {
    offerId: {
      type: String,
      default: '',
    },
    reconfigure: {
      type: Boolean,
      default: false,
    },
    isDepFlow: {
      type: Boolean,
      default: false,
    },
    reRoute: {
      type: Boolean,
      default: true,
    },
    fromCpq: {
      type: Boolean,
      default: false,
    },
    overWrittenDepPrice: {
      type: Object,
      default: () => {
        return {};
      },
    },
    reconfigureData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ['close', 'selected-configuration', 'next-dep-flow', 'for-cpq'],
  data() {
    return {
      modifiedPrice: 0,
      enableFlow: false,
      currentDepFlow: -1,
      workOrder: [],
      showChild: false,
      selectedSupplier: {},
      selectedConfigurationCost: 0,
      selectedConfigurationMsrp: 0,
      selectedConfigurationMargin: 0,
      availableSuppliers: [],
      selectedConfigurationSku: null,
      newPrice: null,
      oldPrice: null,
      isHovered: false,
      ruleExec: {},
      items: [],
      // items: [
      //   'Item 1',
      //   'Item 2',
      //   'Item 3',
      //   'Item 4',
      //   'Item 5',
      //   'Item 6',
      //   'Item 7',
      //   'Item 8',
      //   'Item 9',
      //   'Item 10',
      // ],
      showMore: false,
      initialLimit: 3,
      isMobile: window.matchMedia('(max-width: 1024px)').matches,
      tempIntangibleSelectedBillingPeriod: '',
      selectedOffer: {},
      matchingObject: {},
      intangibleSelectedCommitmentLength: '',
      intangibleSelectedBillingPeriod: '',
      intangibleBillingPeriod: [],
      intangiblecommitmentLength: [],
      billingPeriod: '',
      offerDetailsPricingType: null,
      desc: `<!-- Xiaomi Smart TV X 55 -->
<h2 style="color: #333333; font-size: 24px; font-weight: bold; margin-bottom: 15px;">Xiaomi Smart TV X 55</h2>

<!-- Screen size -->
<p><span style="color: #666666; font-weight: bold;">Screen size:</span> 55 inch</p>

<!-- Refresh rate -->
<p><span style="color: #666666; font-weight: bold;">Refresh rate:</span> 90 Hertz, 144 Hertz</p>

<!-- Color -->
<p><span style="color: #666666; font-weight: bold;">Color:</span> American Silver, Back In Black</p>

<!-- Resolution -->
<p><span style="color: #666666; font-weight: bold;">Resolution:</span> OLED 4K, AMOLED 2K</p>`,
      intangibleRadioOptions: [
        {
          name: 'Auto renew',
          value: 'Auto renew',
          checked: true,
        },
        {
          name: 'Expire term',
          value: 'Expire term',
        },
      ],
      isTangible: undefined,
      intangibleSpec: [],
      configBreadcrumbItems: [
        { label: 'Offerings', link: '' },
        { label: 'Overview', link: '' },
        { label: 'Preview', link: '' },
      ],
      offer: {},
      spec: {},
      dynamicConfigData: {},
      silderData: [],
      initialSelectedValue: '128 GB',
      optionRectangle: [
        { name: '128 GB', value: '128 GB' },
        { name: '256 GB', value: '256 GB' },
        { name: '512 GB', value: '512 GB' },
      ],
      swatchData: [
        { name: 'Teal', value: 'Teal', Color: '#02b55c' },
        { name: 'Product red', value: 'Product red', Color: '#ff5050' },
        { name: 'Yale blue', value: 'Yale blue', Color: '#2879b7' },
        { name: 'Starlight', value: 'Starlight', Color: '#f8a523' },
      ],
      preselectedColor: '',
      hoveredSwatch: null,
      headerName: null,
      pricingTypeOptions: [],
      offerDetails: {
        name: '',
        category: '',
        rating: '',
        brand: '',
        supplier: '',
        pricingType: '',
        price: '',
        description: ``,
        specification: [],
      },
      productQuantity: '1',
      configuration: {
        payload: {
          config: {},
        },
      },
      constraintId: '',
      currentConfigArray: [],
    };
  },
  computed: {
    defaultSupplierValue() {
      const defaultSupplier = this.availableSuppliers.find(
        (supplier) => supplier.isDefault === true
      );
      return defaultSupplier
        ? defaultSupplier.customName
        : this.availableSuppliers[0]?.customName;
    },
    displayHeaderName() {
      return this.hoveredSwatch ? this.hoveredSwatch?.name : this.headerName;
    },
    displayedItems() {
      return this.showMore
        ? this.items
        : this.items.slice(0, this.initialLimit);
    },
  },
  watch: {
    intangibleSelectedBillingPeriod: {
      handler(val) {
        console.log(val, 'fk09');
        this.intangiblecommitmentLength = this.commitmentLengthOptions(
          this.offer.prodSpecCharValue
        );
        this.intangibleSelectedCommitmentLength =
          this.matchingObject?.commitmentLength || 1;
        // patch work
      },
      immediate: true,
      deep: true,
    },
    preselectedColor(newVal) {
      this.headerName = newVal;
      console.log('reeee');
    },
    offer: {
      handler(val) {
        console.log(val.prodSpecCharValueUse, 'offer');
        if (val.prodSpecCharValueUse !== undefined) {
          const matchingProduct = this.getDefaultConfiguration(
            val.prodSpecCharValue,
            val.prodSpecCharValueUse
          );
          // this.offerDetails.price = matchingProduct?.MSRP?.value;
          // this.offerDetails.unit = matchingProduct?.MSRP?.unit;
          if (matchingProduct !== undefined) {
            this.getSliderOption(matchingProduct);
          }
          this.matchingObject = matchingProduct;
          console.log(matchingProduct, 'cccm2');
          this.offerDetailsPricingType = matchingProduct?.priceType;
          if (
            this.constraintId !== '' &&
            this.constraintId !== undefined &&
            this.constraintId !== null
          ) {
            this.getNewConfiguration();
          }
          console.log(this.intangibleSelectedBillingPeriod, 'sabari 2.1');
        }
      },
      immediate: true,
      deep: true,
    },
    offerDetailsPricingType: {
      handler() {
        if (
          this.constraintId !== '' &&
          this.constraintId !== undefined &&
          this.constraintId !== null
        ) {
          this.getNewConfiguration();
        }
      },
      immediate: true,
      deep: true,
    },
    productQuantity: {
      handler() {
        if (
          this.constraintId !== '' &&
          this.constraintId !== undefined &&
          this.constraintId !== null
        ) {
          this.getNewConfiguration();
        }
      },
      immediate: true,
      deep: true,
    },
    reconfigureData: {
      handler(val) {
        console.log(val, 'reconfigureData');
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.headerName = this.preselectedColor;
  },
  async mounted() {
    this.setupMediaQueryListener();
    let offer = {};
    if (this.offerId.length === 0) {
      offer = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/${this.$route.params.id}`
      );
      this.offer = offer.data;
      console.log((this.offer = offer.data), 'offer configuration data');
    } else {
      offer = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/${this.offerId}`
      );
      this.offer = offer.data;
      console.log((this.offer = offer.data), 'offer configuration data');
    }

    if (this.reconfigure) {
      this.offer.prodSpecCharValueUse = JSON.parse(
        JSON.stringify(this.reconfigureData.prodSpecCharValueUse)
      );
      console.log(this.offer, 'reconfigureData');
    }
    this.pricingTypeOptions = this.uniquePriceTypes();

    console.log(this.ruleExec, 'ruleExec data');
    this.isTangible =
      this.offer.productSpecification['@baseType'] !== 'Intangible';
    this.constraintId = this.offer?.constraint[0]?.data?._id;
    console.log(this.constraintId, 'consfk');
    let spec;
    try {
      spec = await UPCAxiosInstance.get(
        `/catalogManagement/productSpecification/name/${offer.data?.productSpecification?.name}`
      );
    } catch (error) {
      console.log(error, 'spec configuration data');
    }
    // console.log((this.spec = spec.data), 'spec configuration data');

    // Function to extract unique billing periods
    const extractBillingPeriods = (payload) => {
      const billingPeriodsSet = new Set();

      // Iterate through each object in the payload
      payload?.forEach((item) => {
        if (item.billingPeriod) {
          billingPeriodsSet.add(item.billingPeriod);
        }
      });

      // Convert Set to array and format as required
      const billingPeriodsArray = Array.from(billingPeriodsSet).map(
        (period) => ({
          name: period,
          value: period,
        })
      );

      return billingPeriodsArray;
    };

    // Call the function with your payload
    this.intangibleBillingPeriod = extractBillingPeriods(
      offer.data.prodSpecCharValue
    );

    if (offer?.data !== undefined && spec?.data !== undefined) {
      this.offerDetails = this.createNewObject(offer?.data, spec?.data);
    }

    if (this.offerDetails.prodSpecCharValueUse !== undefined) {
      const matchingProduct = this.getDefaultConfiguration(
        this.offerDetails.prodSpecCharValue,
        this.offerDetails.prodSpecCharValueUse
      );
      console.log(this.intangibleSelectedBillingPeriod, 'sabari 2.1');
      console.log('offer configuration data', matchingProduct);
      this.offerDetails.price = matchingProduct?.MSRP?.value;
      this.offerDetails.unit = matchingProduct?.MSRP?.unit;
      if (matchingProduct !== undefined) {
        this.matchingObject = matchingProduct;
        this.getSliderOption(matchingProduct);
      }
    }

    // //discount + rule
    // let discountsTable = await UPCAxiosInstance.get(
    //   `/catalogManagement/productOffering/disconts/${offer.data._id}`
    // );
    // console.log(discountsTable.data, "discounts data");
    // this.items = await discountsTable.data;

    // //execute rule

    // const ruleExec = await DMNAxiosInstance.post(
    //   `/rule_engine/rule/execute/66af5190fc4e716866f440d7`,
    //   {
    //     payload: {
    //       productOffering: {
    //         discounts: this.items,
    //         price: this.offerDetails.price,
    //         hierarchy: [
    //           "customer discount",
    //           "role discount",
    //           "product offering",
    //           "recurring discount",
    //         ],
    //       },
    //     },
    //   }
    // );
    // this.ruleExec = await ruleExec.data.data.productOffering;
    // console.log(this.ruleExec, 'exe');
    this.execDiscountRule();

    let depFlow;
    if (this.offerId.length === 0) {
      depFlow = await DMNAxiosInstance.post(
        `/rule_engine/rule/execute/6683e83ad5f0c403af9e5b08`,
        {
          payload: {
            dependentProducts: {
              _id: `${this.$route.params.id}`,
              category:
                this.offerDetails.category[
                  this.offerDetails.category.length - 1
                ],
            },
          },
        }
      );
    } else {
      depFlow = await DMNAxiosInstance.post(
        `/rule_engine/rule/execute/6683e83ad5f0c403af9e5b08`,
        {
          payload: {
            dependentProducts: {
              _id: `${this.offerId}`,
              category:
                this.offerDetails.category[
                  this.offerDetails.category.length - 1
                ],
            },
          },
        }
      );
    }
    console.log(depFlow?.data?.data?.dependentProducts?.results, 'depFlow');
    let depData;
    if (
      depFlow?.data?.data?.dependentProducts?.results?.dependencyId !==
      undefined
    ) {
      depData = await UPCAxiosInstance.get(
        `dependentFlow/${depFlow?.data?.data?.dependentProducts?.results?.dependencyId}`
      );
      console.log(depData, 'depFlow');
    }
    this.workOrder =
      depData?.data?.workOrder != undefined
        ? JSON.parse(JSON.stringify(depData?.data?.workOrder))
        : {};
    if (this.offerId.length === 0) {
      if (
        this.workOrder !== undefined &&
        this.workOrder.length > 0 &&
        depFlow.data?.data?.dependentProducts?.results?.dependencyId !=
          undefined &&
        this.reRoute == true
      ) {
        if (this.fromCpq) {
          this.$emit('for-cpq', {
            offerId: this.$route.params.id,
            dependencyId:
              depFlow.data?.data?.dependentProducts?.results?.dependencyId,
          });
        } else {
          this.$router.push(
            `/UPC/product-catalog-table/offering/configuration-dep-preview/${this.$route.params.id}/${depFlow.data?.data?.dependentProducts?.results?.dependencyId}`
          );
        }
      }
    } else if (
      depFlow.data?.data?.dependentProducts?.results?.dependencyId !=
        undefined &&
      this.reRoute == true
    ) {
      if (this.fromCpq) {
        this.$emit('for-cpq', {
          offerId: this.offerId,
          dependencyId:
            depFlow?.data?.data?.dependentProducts?.results?.dependencyId,
        });
      } else {
        this.$router.push(
          `/UPC/product-catalog-table/offering/configuration-dep-preview/${this.offerId}/${depFlow?.data?.data?.dependentProducts?.results?.dependencyId}`
        );
      }
    }
  },
  beforeUnmount() {
    this.removeMediaQueryListener();
  },
  methods: {
    getBunldeItems(arr) {
      arr.forEach((i) => {
        i.qty = 1;
        i.price = this.currencyFormatter(
          i.productOfferingPrice[0].tiers[0].price.value,
          {
            currency: i.productOfferingPrice[0].tiers[0].price.unit,
          }
        );
      });
      return arr;
    },
    getBundlePrice() {
      if (this.offer.offerType === 'Bundle') {
        let items = this.offer.productOfferingPrice.filter((item) => {
          return item.lifecycleStatus == 'Active';
        });
        return items[0];
      }
    },
    nextDepFlow() {
      this.$emit('next-dep-flow', this.transformData(this.offer));
    },
    continueDepFlow() {
      this.enableFlow = true;
      this.currentDepFlow += 1;
      this.workOrder[this.currentDepFlow];
    },
    async supplierChanged(val) {
      console.log(val, 'supplierChanged suppliercostchnages');

      let x =
        this.offer?.productOfferingPrice[0]?.productOfferingAlteration?.filter(
          (i) => {
            return i?.company == val?.name;
          }
        );
      this.selectedSupplier = val;
      if (this.offer.offerType === 'Bundle') {
        this.selectedConfigurationCost = 0;
        let bundleTotalMsrp = 0;
        for (const item of this.offer.bundledProductOffering) {
          bundleTotalMsrp += Number(item.productOfferingPrice[0].MSRP.value);
          if (
            item.productOfferingPrice[0].productOfferingAlteration !== undefined
          ) {
            const supplierRules = await DMNAxiosInstance.post(
              `/rule_engine/rule/execute/668690e4d5f0c403af9e5b1d`,
              {
                payload: {
                  supplier: {
                    product: [
                      {
                        productOfferingId: item._id,
                        msrp: Number(item.productOfferingPrice[0].MSRP.value),
                        sellingPrice: 0,
                        discount: {
                          skuDiscount: {
                            value:
                              item.productOfferingPrice[0]
                                .productOfferingAlteration[0].discount,
                            active: 1,
                            cost: val?.productStockLevel?.cost,
                            startDate:
                              item.productOfferingPrice[0]
                                .productOfferingAlteration[0].validFor
                                ?.startDate,
                            endDate:
                              item.productOfferingPrice[0]
                                .productOfferingAlteration[0].validFor?.endDate,
                          },
                          categoryDiscount: 0,
                          brandDiscount: 0,
                        },
                      },
                    ],
                  },
                },
              }
            );
            this.selectedConfigurationCost +=
              supplierRules?.data?.data?.supplier?.product[0]?.cost;
          } else if (val.supplierCost > 0) {
            this.selectedConfigurationCost += Number(val.supplierCost);
          } else {
            this.selectedConfigurationCost += Number(
              item.productOfferingPrice[0].MSRP.value
            );
          }

          const supplierRules2 = await DMNAxiosInstance.post(
            `/rule_engine/rule/execute/668690e4d5f0c403af9e5b1d`,
            {
              payload: {
                supplier: {
                  product: [
                    {
                      productOfferingId: this.offer._id,
                      msrp: bundleTotalMsrp,
                      sellingPrice: this.getBundlePrice()?.totalPrice?.value,
                      discount: {
                        skuDiscount: {
                          value: String(this.selectedConfigurationCost),
                          active: 1,
                          cost: String(this.selectedConfigurationCost),
                          startDate: '',
                          endDate: '',
                        },
                        categoryDiscount: 0,
                        brandDiscount: 0,
                      },
                    },
                  ],
                },
              },
            }
          );
          this.selectedConfigurationMargin =
            supplierRules2?.data?.data?.supplier?.product[0]?.margin?.percentage;
        }
      } else {
        this.selectedConfigurationCost =
          val?.msrp?.value ||
          this.offer?.productOfferingPrice[0]?.tiers[0]?.price?.value;
        if (this.newPrice !== null) {
          if (
            (val?.supplierCost === 0 ||
              val?.supplierCost === null ||
              val?.supplierCost === undefined) &&
            x !== undefined
          ) {
            // trigger rules
            const supplierRules = await DMNAxiosInstance.post(
              `/rule_engine/rule/execute/668690e4d5f0c403af9e5b1d`,
              {
                payload: {
                  supplier: {
                    product: [
                      {
                        productOfferingId: this.offer._id,
                        msrp:
                          val?.msrp?.value ||
                          Number(this.availableSuppliers[0]?.msrp?.value) ||
                          this.offer?.productOfferingPrice[0]?.tiers[0]?.price
                            ?.value,
                        sellingPrice: this.newPrice || 0,
                        discount: {
                          skuDiscount: {
                            value: x[0]?.discount,
                            active: 1,
                            cost: 0,
                            startDate: x[0]?.validFor?.startDate,
                            endDate: x[0]?.validFor?.endDate,
                          },
                          categoryDiscount: 0,
                          brandDiscount: 0,
                        },
                      },
                    ],
                  },
                },
              }
            );
            supplierRules;
            this.selectedConfigurationCost =
              supplierRules?.data?.data?.supplier?.product[0]?.cost;
            this.selectedConfigurationMargin =
              supplierRules?.data?.data?.supplier?.product[0]?.margin?.percentage;
          } else {
            const supplierRules = await DMNAxiosInstance.post(
              `/rule_engine/rule/execute/668690e4d5f0c403af9e5b1d`,
              {
                payload: {
                  supplier: {
                    product: [
                      {
                        productOfferingId: this.offer._id,
                        cost: val?.supplierCost,
                        msrp:
                          val?.msrp?.value ||
                          Number(this.availableSuppliers[0]?.msrp?.value) ||
                          this.offer?.productOfferingPrice[0]?.tiers[0]?.price
                            ?.value,
                        sellingPrice: this.newPrice || 0,
                        discount: {
                          skuDiscount: {
                            value: 0,
                            active: 1,
                            startDate: '',
                            endDate: '',
                          },
                          categoryDiscount: 0,
                          brandDiscount: 0,
                        },
                      },
                    ],
                  },
                },
              }
            );
            supplierRules;
            this.selectedConfigurationCost = val?.supplierCost;
            this.selectedConfigurationMargin =
              supplierRules?.data?.data?.supplier?.product[0]?.margin?.percentage;
          }
        }
      }
      console.log(
        val,
        this.selectedConfigurationSku,
        this.offer,
        x,
        this.selectedSupplier,
        this.newPrice,
        'supplierChanged'
      );
    },
    async execDiscountRule() {
      console.log(this.offerDetails.price, 'fk67');
      if (this.offerDetails.price == undefined) {
        this.offerDetails.price = this.modifiedPrice;
      }
      let discountsTable = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/disconts/${this.offer._id}`
      );
      console.log(discountsTable.data, 'discounts data');
      this.items = await discountsTable.data;

      //execute rule

      const ruleExec = await DMNAxiosInstance.post(
        `/rule_engine/rule/execute/66af5190fc4e716866f440d7`,
        {
          payload: {
            productOffering: {
              discounts: this.items,
              price: this.offerDetails.price,
              hierarchy: [
                'customer discount',
                'role discount',
                'product offering',
                'recurring discount',
              ],
            },
          },
        }
      );
      this.ruleExec = await ruleExec.data.data.productOffering;
      if (this.ruleExec.discountPrice !== undefined) {
        this.items = this.items.filter(
          (item) => item._id != this.ruleExec?.appliedDiscount?._id
        );

        this.newPrice = this.ruleExec.discountPrice;
        this.oldPrice = this.ruleExec.price;
        await this.supplierChanged(this.availableSuppliers[0]);
      } else {
        this.newPrice = this.ruleExec.price;
        this.oldPrice = null;
        await this.supplierChanged(this.availableSuppliers[0]);
      }
    },
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    handleMediaQueryChange(event) {
      this.isMobile = event.matches;
    },
    setupMediaQueryListener() {
      this.mediaQuery = window.matchMedia('(max-width: 1024px)');
      this.mediaQuery.addEventListener('change', this.handleMediaQueryChange);
      // Initial check
      this.handleMediaQueryChange(this.mediaQuery);
    },
    removeMediaQueryListener() {
      this.mediaQuery.removeEventListener(
        'change',
        this.handleMediaQueryChange
      );
    },
    selectedBllingPeriodConfig(e) {
      console.log(e, 'ganeshy');
      const obj = {};
      obj['billingPeriod'] = [e?.name];
      this.captureCurrentChange(obj);
    },
    selectedCommitmentLengthConfig(e) {
      console.log(e, 'ganeshy');
      const obj = {};
      obj['commitmentLength'] = [e?.name];
      // this.captureCurrentChange(obj);
    },
    transformData(item) {
      console.log(
        item,
        this.offerDetails,
        this.intangibleSelectedCommitmentLength,
        this.productQuantity,
        this.offer.prodSpecCharValueUse,
        'jenifar'
      );

      let so = {
        name: '',
        description: '',
        lifecycleStatus: '',
        category: '',
        productSpecification: '',
        prodSpecCharValue: '',
        _id: '',
        productOfferingPrice: '',
      };
      so = item;
      console.log('offering data ju', item);
      const lastCategory =
        so.category.length > 0 ? so.category[so.category.length - 1] : '';

      const pricing = this.getPricing(so.productOfferingPrice);
      const oneTimePrice = this.calculateOneTimePrice(pricing);
      oneTimePrice;
      let billingPeriodOptions = null;
      billingPeriodOptions = [
        ...new Set(item.prodSpecCharValue?.map((item) => item?.billingPeriod)),
      ];
      console.log(
        billingPeriodOptions?.map((value) => ({
          name: value,
          value: value,
        })) || [{ name: 'Monthly', value: 'Monthly' }],
        'billingPeriodOptions'
      );
      console.log('new card', this.offerDetails?.price);
      const [whole, fraction] = String(this.newPrice).split('.');
      this.selectedOffer = {
        offerId: item._id,
        offerType: item.offerType,
        discountResponse: {
          productOffering: this.ruleExec,
          price: this.ruleExec.price,
        },
        img_url: so?.prodSpecCharValue[0]?.attachment,
        prodSpecCharValueUse: this.offer.prodSpecCharValueUse,
        currency: this.offerDetails.unit,
        name: so?.name || '',
        description: so.description || '',
        additionalCharges: item?.additionalCharges || [],
        supplierUnitCost: this.selectedConfigurationCost,
        unitMsrp: this.selectedConfigurationMsrp,
        rebate: 0,
        unitOfMeasure: item.unitOfMeasure || 'Each',
        status: so.lifecycleStatus || '',
        section: false,
        skuid: this.matchingObject.id,
        custom: false,
        category: lastCategory || '',
        quantity: Number(this.productQuantity),
        duration: this.intangibleSelectedCommitmentLength,
        max_quantity:
          so?.prodSpecCharValue[0].tiers[
            so?.prodSpecCharValue[0].tiers.length - 1
          ].max,
        brand: so.productSpecification?.brand || '',
        billing_period: this.intangibleSelectedBillingPeriod,
        billing_period_options: billingPeriodOptions?.map((value) => ({
          name: value,
          value: value,
        })) || [{ name: 'Monthly', value: 'Monthly' }],
        id: so._id || '',
        offering_type:
          so.productSpecification?.['@baseType']?.toLowerCase() || '',
        pricing: pricing,
        prodSpecCharValue: JSON.parse(JSON.stringify(so.prodSpecCharValue)),
        price:
          // oneTimePrice
          //   ?
          {
            whole: whole || '0',
            fraction: fraction || '00',
          },
        // : { whole: "0", fraction: "00" },
        productSpecification: {
          ...item.productSpecification,
          '@type': 'ProductSpecification',
        },
        productOffering: {
          name: item?.name,
          id: item._id,
          '@type': 'ProductOffering',
          href: item.href,
        },
        productCharacteristic: JSON.parse(
          JSON.stringify(item.prodSpecCharValueUse)
        ),
      };
      console.log(this.selectedOffer, this.newPrice, 'new card');
      return this.selectedOffer;
    },
    emitSelectedConfig() {
      this.$emit('selected-configuration', this.selectedOffer);
    },
    getPricing(prices) {
      if (!prices || prices.length === 0) return [];

      const priceMap = {};
      prices.forEach((price) => {
        const { pricingType, productofferPriceAlteration } = price;
        if (
          productofferPriceAlteration &&
          productofferPriceAlteration.length > 0
        ) {
          console.log('kulla if');
          productofferPriceAlteration.forEach((alteration) => {
            if (alteration.priority === '1') {
              if (
                !priceMap[pricingType] ||
                priceMap[pricingType].priority !== '1'
              ) {
                priceMap[pricingType] = {
                  name: price?.name || '',
                  pricingType: pricingType || '',
                  amount: alteration.productPrice.taxAmount?.Amount
                    ? alteration?.productPrice?.taxAmount?.Amount?.toString()?.replace(
                        '£',
                        ''
                      )
                    : '',
                  priority: alteration?.priority || '',
                };
              }
            }
          });
        } else {
          // console.log('kulla veliya if', price.productPrice.dutyFreeAmount);
          priceMap[pricingType] = {
            name: price?.name || '',
            pricingType: pricingType || '',
            amount: price?.productPrice?.dutyFreeAmount
              ? price?.productPrice?.dutyFreeAmount
                  ?.toString()
                  ?.replace('£', '')
              : '',
            priority: price?.productPrice?.priority || '',
          };
        }
      });

      return Object.values(priceMap).map((price) => ({
        ...price,
        amount: price.amount || '',
      }));
    },

    calculateOneTimePrice(pricing) {
      console.log('pricing exe', pricing);
      const oneTimePrice = pricing.find(
        (price) =>
          price?.name === 'One time' ||
          price?.name === 'Recurring' ||
          price?.name === 'Pay per use price'
      );
      if (oneTimePrice) {
        const amount = oneTimePrice.amount;
        const [whole, fraction] = amount.split('.');
        return { whole, fraction };
      }
      return null;
    },
    uniquePriceTypes() {
      const priceTypes = this.offer?.prodSpecCharValue?.map(
        (product) => product.priceType
      );
      const uniquePriceTypes = [...new Set(priceTypes)];
      return uniquePriceTypes.map((priceType) => ({
        name: priceType,
        value: priceType,
      }));
    },
    pathTo(val) {
      console.log(val);

      this.$router.back();
      // if (val.label === 'Offerings') {
      //   this.$router.push('/UPC/product-catalog-table/offering');
      // } else if (val.label === 'Overview') {
      //   this.$router.back();
      // }
    },
    getDefaultConfiguration(payload1, payload2) {
      if (this.isTangible) {
        console.log('p1p2', payload1, payload2);
        const defaultConfig = payload2.map((spec) => {
          let defaultValueObj;
          if (spec.configurable === true) {
            defaultValueObj = spec.productSpecCharacteristicValue.find(
              (val) => {
                const defaultValueWithSuffix =
                  spec.default?.valueSuffix !== ''
                    ? spec.default.value + ' ' + spec.default?.valueSuffix
                    : spec.default.value;

                return (
                  (val?.valueSuffix !== ''
                    ? val.value + ' ' + val?.valueSuffix
                    : val.value) === defaultValueWithSuffix
                );
              }
            );
          }
          const defaultValue = defaultValueObj
            ? defaultValueObj.value +
              (defaultValueObj.valueSuffix
                ? ' ' + defaultValueObj?.valueSuffix
                : '')
            : spec.default?.valueSuffix !== ''
            ? spec.default?.value + ' ' + spec.default?.valueSuffix
            : spec.default.value;
          console.log('cccmm', defaultValue);
          if (spec.configurable === true) {
            return {
              name: spec?.name,
              default: defaultValue,
            };
          } else {
            return false;
          }
        });
        console.log(defaultConfig, 'Transformed Config:');
        // Transform the default configuration to the desired format
        const transformedConfig = {};
        defaultConfig.forEach((spec) => {
          console.log('spec check', spec);
          if (spec !== false) {
            if (spec?.default !== null && spec?.default?.value !== null) {
              transformedConfig[spec?.name] =
                typeof spec.default === 'object'
                  ? spec.default?.value
                  : spec.default; // Trimming here to ensure no extra spaces
            } else if (spec.productSpecCharacteristicValue !== undefined) {
              transformedConfig[spec?.name] =
                typeof spec.default === 'object'
                  ? spec.productSpecCharacteristicValue[0]?.value
                  : spec.productSpecCharacteristicValue[0]; // Trimming here to ensure no extra spaces
            }
          }
        });

        // Debugging log
        console.log('Payload1:', JSON.stringify(payload1, null, 2));
        console.log(
          'Transformed Config2:',
          JSON.stringify(transformedConfig, null, 2),
          transformedConfig,
          payload1
        );
        let cleanedObj = Object.fromEntries(
          Object.entries(transformedConfig).filter(
            ([key, value]) => key !== 'undefined' && value !== undefined
          )
        );
        const cleanedAndConvertedObj = Object.fromEntries(
          Object.entries(transformedConfig)
            .filter(
              ([key, value]) => key !== 'undefined' && value !== undefined
            )
            .map(([key, value]) => [
              key,
              typeof value === 'string' ? [value] : value,
            ])
        );
        cleanedObj.priceType = this.offerDetailsPricingType || 'One time';
        // this.configuration.payload.config = cleanedAndConvertedObj;
        // this.getNewConfiguration();
        // Iterate over payload1 to find the object that matches the config
        console.log('sabari log', cleanedObj);
        const matchingObject = payload1.find((item) => {
          return Object.keys(cleanedObj).every((key) => {
            // Trimming item[key] to ensure no extra spaces
            return item[key] && item[key].trim() === cleanedObj[key].trim();
          });
        });
        this.billingPeriod =
          this.offerDetailsPricingType !== 'One time'
            ? matchingObject?.billingPeriod
            : '';

        // Debugging log
        console.log('Matching Object 3:', cleanedObj);
        this.selectedConfigurationSku = matchingObject?.SKU;
        console.log(
          'Matching Object 0511:',
          JSON.stringify(matchingObject, null, 2),
          this.selectedConfigurationSku,
          this.offer,
          this.offer.relatedParty.filter((i) => {
            return (
              i.sku == this.selectedConfigurationSku && i.role == 'supplier'
            );
          }),
          cleanedAndConvertedObj
        );
        this.availableSuppliers = this.offer.relatedParty.filter((i) => {
          return i.sku == this.selectedConfigurationSku && i.role == 'supplier';
        });
        console.log(this.availableSuppliers, '668690e4d5f0c403af9e5b1d');

        return matchingObject;
      } else {
        const defaultConfig = payload2.map((spec) => {
          let defaultValueObj;
          if (spec.configurable === true) {
            defaultValueObj = spec.productSpecCharacteristicValue.find(
              (val) => {
                const defaultValueWithSuffix =
                  spec.default?.valueSuffix !== ''
                    ? spec.default?.value + ' ' + spec.default?.valueSuffix
                    : spec.default?.value;

                return (
                  (val?.valueSuffix !== ''
                    ? val?.value + ' ' + val?.valueSuffix
                    : val?.value) === defaultValueWithSuffix
                );
              }
            );
          }
          const defaultValue = defaultValueObj
            ? defaultValueObj.value +
              (defaultValueObj?.valueSuffix
                ? ' ' + defaultValueObj?.valueSuffix
                : '')
            : spec.default?.valueSuffix !== ''
            ? spec.default?.value + ' ' + spec.default?.valueSuffix
            : spec.default?.value;
          console.log('cccmm', defaultValueObj);
          console.log('p1p2', defaultValueObj);
          if (spec.configurable === true) {
            return {
              name: spec?.name,
              default: defaultValue,
            };
          } else {
            return false;
          }
        });
        console.log('sabari4', defaultConfig);
        const transformedConfig = {};
        defaultConfig.forEach((spec) => {
          console.log('spec check', spec);
          if (spec !== false) {
            if (spec?.default !== null && spec?.default?.value !== null) {
              transformedConfig[spec?.name] =
                typeof spec.default === 'object'
                  ? spec.default?.value
                  : spec.default; // Trimming here to ensure no extra spaces
            } else if (spec.productSpecCharacteristicValue !== undefined) {
              transformedConfig[spec?.name] =
                typeof spec.default === 'object'
                  ? spec.productSpecCharacteristicValue[0]?.value
                  : spec.productSpecCharacteristicValue[0]; // Trimming here to ensure no extra spaces
            }
          }
        });

        // Debugging log
        console.log('Payload1:', JSON.stringify(payload1, null, 2));
        console.log(
          'Transformed Config2:',
          JSON.stringify(transformedConfig, null, 2),
          transformedConfig,
          payload1
        );
        let cleanedObj = Object.fromEntries(
          Object.entries(transformedConfig).filter(
            ([key, value]) => key !== 'undefined' && value !== undefined
          )
        );
        const cleanedAndConvertedObj = Object.fromEntries(
          Object.entries(transformedConfig)
            .filter(
              ([key, value]) => key !== 'undefined' && value !== undefined
            )
            .map(([key, value]) => [
              key,
              typeof value === 'string' ? [value] : value,
            ])
        );
        cleanedObj.priceType = this.offerDetailsPricingType || 'Recurring';
        // this.configuration.payload.config = cleanedAndConvertedObj;
        // this.getNewConfiguration();
        // Iterate over payload1 to find the object that matches the config
        console.log('sabari 3', payload1, cleanedObj);
        const matchingObject = payload1.find((item) => {
          return Object.keys(cleanedObj).every((key) => {
            // Trimming item[key] to ensure no extra spaces
            return item[key] && item[key].trim() === cleanedObj[key].trim();
          });
        });

        this.intangibleSelectedBillingPeriod =
          JSON.parse(JSON.stringify(matchingObject?.billingPeriod)) ||
          JSON.parse(JSON.stringify('Month'));
        console.log(
          'sabari 2',
          this.intangibleSelectedBillingPeriod,
          matchingObject?.billingPeriod
        );
        //patch work
        this.intangiblecommitmentLength =
          this.commitmentLengthOptions(payload1);
        this.intangibleSelectedCommitmentLength =
          matchingObject?.commitmentLength || 1;
        //patch work

        console.log(
          payload1,
          defaultConfig,
          cleanedObj,
          cleanedAndConvertedObj,
          this.intangibleBillingPeriod,
          matchingObject,
          'payload2.prodSpecCharValueUse'
        );
        this.intangibleSpec = payload2.filter((item) => {
          return item.configurable !== true;
        });
        this.matchingObject = matchingObject;
        return matchingObject;
      }
    },
    commitmentLengthOptions(payload) {
      const billingPeriodsSet = new Set();

      // Iterate through each object in the payload
      payload?.forEach((item) => {
        if (
          item.commitmentLength &&
          item.billingPeriod === this.intangibleSelectedBillingPeriod
        ) {
          billingPeriodsSet.add(item.commitmentLength);
        }
      });
      this.tempIntangibleSelectedBillingPeriod = '';
      if (this.intangibleSelectedBillingPeriod.includes('Semi Annual')) {
        this.tempIntangibleSelectedBillingPeriod = 'semi-annual period(s)';
      } else if (this.intangibleSelectedBillingPeriod.includes('Biweek')) {
        this.tempIntangibleSelectedBillingPeriod = 'bi-weekly period(s)';
      } else if (this.intangibleSelectedBillingPeriod.includes('BiMonth')) {
        this.tempIntangibleSelectedBillingPeriod = 'bi-monthly period(s)';
      } else if (this.intangibleSelectedBillingPeriod.includes('Annual')) {
        this.tempIntangibleSelectedBillingPeriod = 'Year(s)';
      } else if (this.intangibleSelectedBillingPeriod.includes('Week')) {
        this.tempIntangibleSelectedBillingPeriod = 'Week(s)';
      } else if (this.intangibleSelectedBillingPeriod.includes('Month')) {
        this.tempIntangibleSelectedBillingPeriod = 'Month(s)';
      } else if (this.intangibleSelectedBillingPeriod.includes('Quarter')) {
        this.tempIntangibleSelectedBillingPeriod = 'Quarter(s)';
      } 
      // Convert Set to array and format as required
      const billingPeriodsArray = Array.from(billingPeriodsSet).map(
        (period) => ({
          name: period + ' ' + this.tempIntangibleSelectedBillingPeriod,
          value: period,
        })
      );
      console.log('billingPeriodsArray', billingPeriodsArray);
      return billingPeriodsArray;
    },

    getPriceForQuantity(array, number) {
      for (const item of array) {
        if (number >= item.min && number <= item.max) {
          return item.price;
        }
      }
      return null; // Return null if no matching range is found
    },
    async getNewConfiguration() {
      console.log(this.configuration,"this.configuration suppliercostchnages");
      
      try {
        if (this.constraintId !== undefined) {
          let config = await DMNAxiosInstance.post(
            `/rule_engine/rule/execute/${this.constraintId}`,
            this.configuration
          );
          if (config.data.data.decisionMatrix.length > 0) {
            const matchingProduct = this.getDefaultConfiguration(
              config.data.data.decisionMatrix,
              this.offer.prodSpecCharValueUse
            );
            console.log(this.intangibleSelectedBillingPeriod, 'sabari 2.1');
            this.matchingObject = matchingProduct;
            if (matchingProduct !== undefined) {
              let price = this.getPriceForQuantity(
                matchingProduct.tiers,
                this.productQuantity
              );
              console.log('new config new', price);
              if (price !== null) {
                this.offerDetails.price = matchingProduct?.MSRP?.value;
                this.offerDetails.unit = matchingProduct?.MSRP?.unit;
              } else {
                this.offerDetails.price = 0;
                this.offerDetails.unit = matchingProduct?.MSRP?.unit;
              }
              this.offerDetails.price = price?.value;
              this.offerDetails.unit = price?.unit;
              console.log('new matching product', matchingProduct, price);
            }
          }
          console.log(
            this.matchingObject,
            this.offerDetails,
            this.offerDetails.price,
            this.overWrittenDepPrice,
            'fk67'
          );
          if (this.overWrittenDepPrice.modifiedPricePercent !== undefined) {
            let price =
              this.offerDetails.price -
              (this.overWrittenDepPrice.modifiedPricePercent / 100) *
                this.offerDetails.price;
            this.modifiedPrice = price;
            this.offerDetails.price = price;
            console.log(
              this.offerDetails.price,
              this.offerDetails.price -
                (this.overWrittenDepPrice.modifiedPricePercent / 100) *
                  this.offerDetails.price,
              'fk67'
            );
          } else if (this.overWrittenDepPrice.modifiedPrice !== undefined) {
            let price =
              this.overWrittenDepPrice[this.matchingObject.SKU]?.value;
            console.log(price, 'fk99');
            this.modifiedPrice = price;
            this.offerDetails.price = price;
          }
        }
      } catch (error) {
        console.log(error, 'configuration error');
      }

      this.execDiscountRule();
    },
    async bufferToDataURL(buffer) {
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = '';
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },

    async convertImg(val) {
      console.log(val);
      const url = await UPCAxiosInstance.get(`/gcpupload/files/${val?.name}`, {
        responseType: 'arraybuffer',
      });
      console.log(await this.bufferToDataURL(url.data));
      //  await this.bufferToDataURL(url.data)
      const obj = {
        name: val?.name,
        data: await this.bufferToDataURL(url.data),
        preferred: val.preferred,
      };
      return obj;
    },
    async getSliderOption(val) {
      let arr = [];

      // Map each promise returned by convertImg to an array of promises
      if (val.attachment !== undefined) {
        const promises = val?.attachment?.map((e) => this.convertImg(e));
        console.log(val.attachment, promises, 'promises');
        // Use Promise.all to wait for all promises to resolve
        arr = await Promise.all(promises);
      }
      console.log(arr, 'image arr');
      this.silderData = arr;
    },
    createNewObject(payloadOne, payloadTwo) {
      return {
        name: payloadOne?.name,
        category: payloadOne.category,
        description: payloadOne.description,
        brand: payloadTwo?.brand,
        supplier: payloadOne.relatedParty.find(
          (obj) => obj.isDefault === true && obj.role === 'supplier'
        ),
        price: payloadOne.productOfferingPrice[0]?.productPrice?.dutyFreeAmount,
        unit: payloadOne.productOfferingPrice[0]?.productPrice?.unit,
        specification: payloadOne.prodSpecCharValueUse.filter((item) => {
          return item.configurable !== true;
        }),
      };
    },
    getSwatchData(obj) {
      let arr = [];
      arr = obj.productSpecCharacteristicValue;
      arr.forEach((item) => {
        item.name = item.value + ' ';
      });
      let x2;
      let x = this.offer?.prodSpecCharValueUse.find(
        (item) =>
          item.type === 'Swatch' &&
          item.configurable &&
          item.default.value !== null
      )?.default?.value;
      if (x === null || x === undefined) {
        x2 = this.offer?.prodSpecCharValueUse.find(
          (item) =>
            item.type === 'Swatch' &&
            item.configurable &&
            item.default.value === null
        );
        x2.default.value = x2.productSpecCharacteristicValue[0].value;
        x = this.offer?.prodSpecCharValueUse.find(
          (item) => item.type === 'Swatch' && item.configurable
        )?.productSpecCharacteristicValue[0]?.value;
      }

      this.preselectedColor = x;

      console.log(
        x2,
        arr,
        this.preselectedColor,
        this.offer?.prodSpecCharValueUse,
        'swatch arr2'
      );
      return arr;
    },
    getOptionRectangle(obj) {
      console.log(obj, 'getOptionRectangle');
      let arr = [];
      arr = obj.productSpecCharacteristicValue;
      arr.forEach((item) => {
        item.name =
          item?.value +
          (item?.valueSuffix !== ''
            ? ' ' + item?.valueSuffix
            : item?.valueSuffix);
      });
      // this.initialSelectedValue = arr[0].displayValue;
      console.log(arr, 'swatch arr');
      return arr;
    },
    selected(e, items) {
      console.log(e, items, 'val items 2');
      items.default = {
        value: e.value,
        valueSuffix: e.valueSuffix,
      };
      // this.initialSelectedValue = val.displayValue;
      let obj = {};
      obj[items?.name] = [e?.name];
      this.captureCurrentChange(obj);
    },
    handleSelectedColor(color, items) {
      console.log('Selected Color:', color);
      this.headerName = color?.name;
      items.default = {
        value: color?.value,
        valueSuffix: color?.valueSuffix,
      };
      this.hoveredSwatch = color;
      this.headerName.trim();

      let data = this.offer.prodSpecCharValue.find((obj) => {
        console.log(Object.values(obj), this.headerName, 'data00');
        return Object.values(obj).some((value) => {
          if (typeof value === 'string') {
            return value.trim() === this.headerName;
          }
          return false;
        });
      });
      console.log(color, items, data, 'sssd');
      // console.log(color?.name, this.initialSelectedValue, data, 'data00');
      // this.getSliderOption(data);
      let obj = {};
      obj[items?.name] = [color?.name];
      this.captureCurrentChange(obj);
    },
    captureCurrentChange(item) {
      this.currentConfigArray = this.updateArray(this.currentConfigArray, item);
      console.log(
        this.convertArrayToObject(this.currentConfigArray),
        'currentConfigArray'
      );
      this.configuration.payload.config = this.convertArrayToObject(
        this.currentConfigArray
      );
      this.getNewConfiguration();
    },
    updateArray(array, newObj) {
      // Extract key from the new object
      const newKey = Object.keys(newObj)[0];

      // Find index of the matching key in the array
      const index = array.findIndex((item) => Object.keys(item)[0] === newKey);

      if (index !== -1) {
        // If matching key is found, replace it and remove subsequent items
        array = array.slice(0, index + 1);
        array[index] = newObj;
      } else {
        // If no matching key is found, push the new object to the array
        array.push(newObj);
      }

      return array;
    },
    convertArrayToObject(array) {
      // Using JSON.parse(JSON.stringify(...)) to deeply clone the array and remove reactivity proxies
      const clonedArray = JSON.parse(JSON.stringify(array));
      return clonedArray.reduce((acc, obj) => {
        const key = Object.keys(obj)[0];
        acc[key] = obj[key];
        return acc;
      }, {});
    },
    handlehoverColor(color) {
      this.hoveredSwatch = color;
      console.log('hovered Color:', color);
    },
  },
};
</script>

<style lang="scss">
// .price-item {
//   display: flex;
//   justify-content: space-between;
//   margin-bottom: 10px;
// }
.hover-effect {
  background-color: #e0e0e0;
  transition: background-color 0.3s ease;
}

.icon-info-circle-regular {
  /* Style your icon as needed */
  font-size: 14px; /* Example size */
}

.pricing-details {
  display: none;
  position: absolute;
  top: 42%; /* Adjust as needed to position below the icon */
  left: 66%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
  width: 200px; /* Adjust width as needed */
}

.icon-info-circle-regular:hover + .pricing-details,
.pricing-details:hover {
  display: block;
}

.price-item {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 12px;
}

.price-item span {
  color: #333;
  display: flex;
}

.price {
  font-weight: bold;
  font-size: 12px;
}

.discount {
  color: red;
}

.final-price {
  color: green;
  font-weight: bold;
}
.old-price {
  text-decoration: line-through;
  color: #777;
  font-weight: 100;
}

.new-price {
  font-weight: bold;
}

@media screen and (min-width: 1024px) {
  .configuration-container {
    display: grid;
    grid-template-columns: 650px auto;
    // grid-gap: 2%;
    padding: 0 0 0 20px;
    .image-preview-container {
      // border: 1px solid #d8d8d8;
      border-radius: 8px;
      margin-bottom: 20px;
      position: sticky;
      top: 90px;
    }
    .spec-box {
      border: 1px solid #d8d8d8;
      border-radius: 8px;
      min-height: max-content;
    }
    .offer-container {
      overflow: auto;
      padding: 0 20px 0 20px;
      height: calc(100vh - 90px);
    }
  }
  .configuration-container-v2 {
    margin-top: 60px;
    display: grid;
    grid-template-columns: 27.5% 57.5%;
    padding: 20px 0 20px 20px;
    font-size: 14px;
    grid-gap: 70px;
    justify-content: center;
  }
}

@media screen and (max-width: 1024px) {
  .configuration-container {
    display: grid;
    grid-template-rows: auto auto !important;
    justify-items: center;
    grid-gap: 40px;
    height: calc(100vh - 90px);
    overflow-y: scroll;
    .offer-container {
      padding: 0 20px 0 20px;
      width: 100%;
    }
  }

  .configuration-container-v2 {
    margin-top: 60px;
    display: grid;
    grid-template-rows: auto auto;
    padding: 20px 20px 20px 20px;
    font-size: 14px;
    grid-gap: 70px;
    // justify-content: center;

    .configuration-container-v2-child {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
}
</style>
