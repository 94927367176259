<template>
  <h1>Sender view</h1>
  helowww
</template>

<script>
export default {
name:"SignerPreview"
}
</script>

<style>

</style>