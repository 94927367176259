<template>
    <div>
        <promptWindow module="quote"></promptWindow>
    </div>
</template>

<script>
import promptWindow from '@/components/GenAI/promptWindow.vue'
export default {
    name: 'QuoteAssistant',
    components: {
        promptWindow
    },
    // Your component's logic goes here
}
</script>

<style scoped>
/* Your component's styles go here */
</style>