<template>
  <div
    :id="'custom-dropdown1' + unique"
    class="custom-dropdown1"
    :style="border != false ? 'border: 1px solid #d8d8d8;' : 'border:none'"
    @click="Customoptioncontainer"
   
  >
    <input
      :id="'custom-input' + unique"
      type="text"
      class="custom-dropdown1-input"
      :value="selectvalue"
      :placeholder="placeholdervalue"
      :style="{ background: backgroundColor, color: fontColor }"
      @input="check"
    />
    <i
      class="icon-angle-down-regular"
      :style="{ background: backgroundColor, color: fontColor }"
    ></i>
    <div
      v-if="animation === true && required === false"
      class="custom-label"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ placeholderValue }}
    </div>
    <div
      v-if="animation === true && required === true"
      class="custom-label"
      :style="{ background: labelBackgroundColor, color: labelFontColor }"
    >
      {{ placeholderValue }}<span class="asterik">{{ "*" }}</span>
    </div>
    <p class="customerror">{{ customError }}</p>
    <div
v-show="customdropdowncontainer === true" :id="'custom-dropdown1-whole-container'+unique" class="custom-dropdown1-whole-container" :style="[
      'position:fixed !important',
        'top:' + top1,
        'left:' + left1,
        'width:'+ width1
      ]">
    <div
:id="'custom-option-data'+unique"
      class="customdropdown-option-container1"
    >
      <div v-if="dropdowndata.length === 0" class="no-data-option1">
        <p>No data</p>
      </div>
      <div
        v-for="(i, index) in dropdowndata"
        v-else
        :key="index"
        class="option1"
        :class="i.checked === true ? 'select-value' : ''"
        @click="customSelectoptionvalue($event,i[propValue], i[label])"
      >
        <span style="width:100%"><p class="option-value1" :style="i[style]">{{ i[label] }}</p></span>
        <i :class="i.icon" style="margin-right: 10px; font-size: 18px"></i>
      </div>
    </div>
    <div v-if="footer == true" class="fixed-footer1">
      <footer-select>
        <slot name="footer-select"></slot>
      </footer-select>
    </div>
    </div>
  </div>


</template>

<script>
// import "vue3-country-flag-icon/dist/CountryFlag.css";

export default {
  name: "HlxSelect",
  components: {
    // inputItems,
    // Search,
    // CountryFlag,
    // hlxinput,
    // hlxtag
    // HlxInput
    // Hlxbutton
  },
  inheritAttrs: false,
  // props: ['options', 'multiSelect', 'title', 'multiSelectOptions', 'search', 'placeholderValue', 'icon','preValue'],
  props: {
            border:{
      type: Boolean,
      default: true,
    },
        footer:{
      type: Boolean,
      default: false,
    },
    scrollClassname:{
       type:String,
       default:''
    },
    flagIcon:{
      type: Boolean,
      default: false,
    },
    flagValue: {
      type: Boolean,
      default: false,
    },
    widthContainer: {
      type: Number,
      default: 0,
    },
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "name",
    },
    propValue: {
      type: String,
      default: "value",
    },
    style:{
      type:String,
      default:"style"
    },
    customError: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    multiSelectInlineSearch: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    modelValue: {
      type: String,
      default: "",
    },
    backgroundColor: {
      type: String,
      default: "#ffffff",
    },
    placeholderColor: {
      type: String,
      default: "#a6a6a6",
    },
    labelFontColor: {
      type: String,
      default: "#a6a6a6",
    },
    fontColor: {
      type: String,
      default: "#565553",
    },
    labelBackgroundColor: {
      type: String,
      default: "#ffffff",
    },
    customSelect: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    multiSelectOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    preValue: {
      type: String,
      default: "",
    },
    multiSelectPreValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
    placeholderValue: {
      type: String,
      default: "Select",
    },
    multiSelect: {
      type: Boolean,
    },
    filterSelect: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    labelAnimation: {
      type: Boolean,
      default: false,
    },
    textEditor: {
      type: Boolean,
      default: false,
    },
    inlineSearch: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "customChange",
    "update:value",
    "selectedValue",
    "update:name",
    "searchValue",
    "clearableValue",
    "update:modelValue",
    "inlineValue",
    "inlineReferenceValue",
    "customErrorEmitValue",
    "focusEvent",
    "blureventDropdown"
  ],
  data() {
    return {
      width1:0,
      top1:0,
      left1:0,
      inlinevalueselect: false,
      multi_select_inline_tag_array: [],
      multi_select_inline_tag_value: 0,
      inputvalue: "",
      multi_select_inline_count_show: false,
      multi_select_inline_count_value: 0,
      multiselecttagvalue: 0,
      showselectedvalue: [],
      returndata: [],
      multiselectinlineoptions: [],
      multiselectarray: [],
      multiselectplaceholder: false,
      multiselectdropdownvalue: false,
      single_select: false,
      count: false,
      isMultiSelect: false,
      search_by: "",
      multi_select_value: [],
      count_select_value: [],
      multi_select_count_value: [],
      listValue: [],
      checkedData: [],
      value: "",
      selectall: true,
      dropdownname: false,
      placeholder: true,
      dropdownvalue: false,
      selected_icon: "",
      selectoptioncontainer: false,
      iconselect: false,
      icon_value: "",
      icon_country_flag: "undefined",
      obj: {},
      selectedvalue: [],
      title: "",
      tagvalue: 11,
      multiselecttitle: false,
      valueid: "",
      all: false,
      unique: Math.floor(Math.random() * 1000 + 1),
      disablebutton: false,
      option_value: "",
      optionsD: [],
      custom_select: false,
      custom_dropdown_option: false,
      customoption: [],
      customdropdowncontainer: false,
      selectvalue: "",
      placeholdervalue: "",
      selectcount: 0,
      //  label:false,
      //  input:true,
      //  labelinput:false,
      animation: false,
      count22: 0,
      top:45,
      left:1
    };
  },
  computed: {
    computeMultiSelect() {
      this.computeDropdownData;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.count_select_value = this.multi_select_value.length - this.tagvalue;
      return this.multi_select_value;
    },
    computeDropdownData() {
      const data = [...this.multiselectarray];
      if (this.search_by && this.search_by != "") {
        return data.filter((item) =>
          item[this.label]
            .toLowerCase()
            .toString()
            .includes(this.search_by.toLowerCase())
        );
      } else {
        return this.multiselectarray;
      }
    },
    dropdowndata() {
      const data = [...this.customoption];
      let data1 = []
      // console.log(this.selectvalue);
      if (this.selectvalue != "") {
        data1 = data.filter((e)=>{
          if(e[this.label] .toString()
            .toLowerCase()
            .includes(this.selectvalue.toLowerCase()))
            {
               return e
            }
        })
        this.switchdropdown(data1)
        // console.log(data1);
        return data1
      } else {
        this.switchdropdown(data)
        return this.customoption;
      }
    },
    multiselectsearch() {
      const data = [...this.multiselectinlineoptions];
      // console.log(this.selectvalue);
      if (this.inputvalue != "" && this.inputvalue) {
        return data.filter((item) =>
          item[this.label]
            .toString()
            .toLowerCase()
            .includes(this.inputvalue.toLowerCase())
        );
      } else {
        return this.multiselectinlineoptions;
      }
    },
  },
  watch: {
    // scrollClassname:{
    //  handler(val){
    //   if(val)
    //     console.log(val,'pop');
    //  },
    //  immediate:true,
    //  deep:true
    // },
    returndata: {
      handler(val) {
        if (val != "") {
          if (this.multiSelectInlineSearch == true) {
            this.showselectedvalue = this.returndata.filter((e) => {
              if (e.checked == true) {
                return e;
              }
            });
            // console.log(this.showselectedvalue);
            const box1 = document.querySelector(
              "#custom-multi-select" + this.unique
            );
            const observer = new ResizeObserver((e) => {
              // console.log(e[0].contentRect.width < 920);
              if (
                e[0].contentRect.width > 1000 ||
                e[0].contentRect.width < 920
              ) {
                this.multiselecttagvalue = 8;
                if (this.multiselecttagvalue > this.showselectedvalue.length) {
                  // console.log('sj');
                  this.multi_select_inline_count_show = false;
                  const tagwidth = 100;
                  document.getElementById(
                    "custom-multi-select-tag-value" + this.unique
                  ).style.width = tagwidth + "px";
                  const inputwidth =
                    e[0].contentRect.width -
                    100 * this.showselectedvalue.length -
                    30;
                  document.getElementById(
                    "custom-multi-select-input" + this.unique
                  ).style.width = inputwidth + "px";
                  this.multi_select_inline_tag_array = this.showselectedvalue;
                  if (
                    this.showselectedvalue.length >
                    this.multi_select_inline_tag_value
                  ) {
                    this.multi_select_inline_tag_value =
                      this.showselectedvalue.length;
                  }
                  // console.log(this.multi_select_inline_tag_value);
                } else {
                  // this.multi_select_inline_tag_value = this.showselectedvalue.length
                  this.multi_select_inline_count_show = true;
                  this.multi_select_inline_count_value =
                    this.showselectedvalue.length -
                    this.multiselecttagvalue +
                    1;
                  if (this.showselectedvalue.length == 8) {
                    const positionvalue =
                      this.showselectedvalue.length * 100 - 30;
                    // console.log(positionvalue)
                    document.getElementById(
                      "multi-select-inline-count-value" + this.unique
                    ).style.left = positionvalue + "px";
                    const inputwidth =
                      e[0].contentRect.width -
                      100 * this.multiselecttagvalue -
                      34;
                    document.getElementById(
                      "custom-multi-select-input" + this.unique
                    ).style.width = inputwidth + "px";
                    document.getElementById(
                      "custom-multi-select-input" + this.unique
                    ).style.marginLeft = "24px";
                    //  count++
                  }
                  // console.log('thambi',this.multi_select_inline_count_value);
                }
              }
            });
            observer.observe(box1);
          }
        }
      },
      immediate: true,
      deep: true,
    },
    multiSelectPreValue: {
      handler(val) {
        if (val != []) {
          val.forEach((e) => {
            this.multiselectarray.forEach((ele) => {
              // console.log(ele.value,e);
              if (ele[this.propValue] === e) {
                ele.checked = true;
              }
            });
          });
          let multiselectpre_valuecount = 0;
          this.multiselectarray.forEach((e) => {
            if (e.checked === true) {
              multiselectpre_valuecount++;
            }
          });
          if (multiselectpre_valuecount === this.multiselectarray.length) {
            this.all = true;
          } else {
            this.all = false;
          }
        }
        // let count = 0
        // console.log(count);
        // count++
        // document.getElementById('multiselect'+ this.unique).click()
        // document.querySelector('.whole-checkbox').children[0].children[0].nextElementSibling.classList.remove('select-all-theme')
      },
      immediate: true,
      deep: true,
    },
    modelValue: {
      handler(val) {
        // console.log(val,'op');
        if(val != '')
        {
          // console.log(val,'modelvalue');
          this.selectvalue = val;
          this.customoption.forEach((e)=>{
            if(e[this.propValue] == val)
              this.selectvalue = e[this.label]
          })
        }
        else{
          this.selectvalue = ''
        }
      },
      immediate: true,
      deep:true
    },
    customoption: {
      handler(val) {
        if (val) {
          if (this.selectvalue != "") {
            val.forEach((e) => {
              if (e[this.propValue] === this.selectvalue) {
                // console.log(val,'vale');
                e.checked = true;
                this.selectvalue = e[this.label];
                // this.placeholdervalue = this.placeholdervalue;
                this.selectcount = 1;
                if (this.labelAnimation === true) {
                  this.animation = true;
                } else {
                  this.animation = false;
                }
              } else {
                e.checked = false;
              }
            });
            // console.log(val,'check');
          }
        }
      },
      immediate: true,
      deep: true,
    },
    options: {
      handler(val) {
        if (val) {
          if (this.inlineSearch === true) {
            this.customoption = JSON.parse(JSON.stringify([...val]));
          } else if (this.single_select == true) {
            this.optionsD = JSON.parse(JSON.stringify([...val]));
            if (this.preValue != "") {
              this.optionsD.forEach((e) => {
                if (e[this.propValue] === this.preValue) {
                  e.checked = true;
                } else {
                  e.checked = false;
                }
              });
            }
            // if()
          } else if (this.multiSelectInlineSearch == true) {
            this.multiselectinlineoptions = JSON.parse(
              JSON.stringify([...val])
            );
          }
        }
      },
      immediate: true,
      deep: true,
    },
    optionsD: {
      handler(val) {
        // console.log(val, 'waaa')
        if (val) {
          if (this.single_select === true) {
            val.forEach((e) => {
              // if(e.id === undefined)
              // {
              //   e.id = 'singleselect' + this.unique + index
              // }
              if (e.checked === true) {
                this.placeholder = false;
                this.dropdownvalue = true;
                this.option_value = e[this.label];
              }
            });
          }
          if (this.textEditor === true) {
            if (val != undefined) {
              val.forEach((e, index) => {
                if (e.id === undefined) {
                  e.id = "textEditor" + this.unique + index;
                }
                if (e.checked === true) {
                  this.placeholder = false;
                  this.dropdownvalue = true;
                  this.option_value = e[this.label];
                }
              });
            }
          } else if (this.icon === true) {
            // console.log(val,'op');
            val.forEach((e) => {
              if (e.checked === true) {
                this.placeholder = false;
                this.dropdownvalue = true;
                this.option_value = e[this.label];
                // this.icon_country_flag = e.icon
                // console.log(this.icon_country_flag,'new');
              }
            });
          }
        }
        // console.log(e,'in watch');
      },
      immediate: true,
      deep: true,
      flush: "post",
    },
    icon_value: {
      handler() {
        // console.log(val);
      },
    },
    checkedData: {
      handler(val) {
        if (this.filterSelect === true) {
          // console.log('hi',val);
          if (val != []) {
            // console.log(val,'op');
            //  let count= 0
            let count1 = 0;
            val.forEach((e) => {
              if (e.checked === true) {
                //  count++
              }
              if (e.checked === false) {
                count1++;
              }
            });
            //  if(val.length === count)
            //  {
            //   //  this.multiselectdropdownvalue = false
            //     this.all = true
            //  }
            //  else{
            //    this.all = false
            //    // console.log(document.querySelector('.hlx-multi-select-input'),'lol');
            //  }
            if (val.length === count1) {
              //  this.multiselectdropdownvalue = false
              this.disablebutton = true;
            } else {
              this.disablebutton = false;
              // console.log(document.querySelector('.hlx-multi-select-input'),'lol');
            }
            this.$emit("update:value", this.checkedData);
          }
        } else {
          // console.log('hello');
          if (this.multiSelect == true) {
            if (val != []) {
              // console.log(val,'op');
              let count = 0;
              val.forEach((e) => {
                if (e.checked === true) {
                  count++;
                }
              });
              if (val.length === count) {
                //  this.multiselectdropdownvalue = false
                this.all = true;
              } else {
                this.all = false;
                // console.log(document.querySelector('.hlx-multi-select-input'),'lol');
              }
              this.$emit("customChange", val);
              this.$emit("update:value", this.checkedData);
            }
          }
        }
      },
      deep: true,
      flush: "post",
    },
    multiselectarray: {
      handler(val) {
        if (this.filterSelect === true) {
          // console.log('hi');
          if (val !== undefined) {
            // val.forEach((e,index)=>{
            //    e.id = 'multiselect' + this.unique + index
            // })
            //  console.log(val,'loljhnddhnddjkdkjsdskjd');/
            if (val != []) {
              //  let count = 0
              let count1 = 0;
              val.forEach((e) => {
                // if(e.checked === true)
                // {
                //   count++
                // }
                if (e.checked === false) {
                  count1++;
                }
              });
              //  if(val.length === count)
              //  {
              //     //  this.multiselectdropdownvalue = false
              //       this.all = true
              //  }
              //  else{
              //    this.all = false
              //  }
              if (val.length === count1) {
                //  this.multiselectdropdownvalue = false
                this.disablebutton = true;
              } else {
                this.disablebutton = false;
              }
            }
          }
        } else {
          // console.log('hello');
          if (val !== undefined) {
            //  console.log(val,'loljhnddhnddjkdkjsdskjd');/
            if (val != []) {
              let count = 0;
              val.forEach((e) => {
                if (e.checked === true) {
                  count++;
                }
              });
              if (val.length === count) {
                //  this.multiselectdropdownvalue = false
                this.all = true;
              } else {
                this.all = false;
              }
            }
          }
        }

        // this.$emit('update:value', this.checkedData)
      },
      immediate: true,
      deep: true,
      flush: "post",
    },
    tagvalue: {
      handler() {
        // console.log(val,'tagvalue');
      },
      immediate: true,
    },
    multiSelectOptions: {
      handler(val) {
        if (val) {
          if (val && val.length > 0) {
            this.multiselectarray = JSON.parse(JSON.stringify([...val]));
            if (this.multiSelectPreValue != []) {
              this.multiSelectPreValue.forEach((e) => {
                this.multiselectarray.forEach((ele) => {
                  if (ele[this.propValue] === e) {
                    ele.checked = true;
                  }
                });
              });
              let multiselectpre_valuecount = 0;
              this.multiselectarray.forEach((e) => {
                if (e.checked === true) {
                  multiselectpre_valuecount++;
                }
              });
              if (multiselectpre_valuecount === this.multiselectarray.length) {
                this.all = true;
              } else {
                this.all = false;
              }
            }
            if (document.getElementById("multiselect" + this.unique) != null) {
              document.getElementById("multiselect" + this.unique).click();
              document
                .querySelector(".whole-checkbox")
                .children[0].children[0].nextElementSibling.classList.remove(
                  "select-all-theme"
                );
            }
          }
        }
      },
      immediate: true,
      deep: true,
      flush: "post",
    },
    selectvalue: {
      handler(val) {
        //  console.log(val,'oppp');
        val
      },
      immediate: true,
      deep:true
    },
    preValue:{
      handler(val)
      {
        // console.log(val,'prevalue');
        if(val != '')
        {
          this.dropdownname = true
          this.optionsD.forEach((e)=>{
            if(e[this.propValue] === val)
            {
               e.checked = true
            }
            else{
              e.checked = false
            }
          })
                  // console.log(val,'prevalue',this.optionsD);

        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
              const icon = document.getElementById('custom-dropdown1-whole-container' + this.unique);
      const parent = icon.parentElement;
      // Get the position of the parent add-column div
      const { top, left } = parent.getBoundingClientRect();

// console.log(top,left,'pop');
      // Calculate the top and right positions for the icon
      // const iconTop = top - icon.offsetHeight;
      // const iconRight = window.innerWidth - right;
      // console.log(iconTop, iconRight);
      this.top1 = top + 45 + "px !important";
      // // if (pos === "right") {
      this.left1 = left + "px !important";
        let width = document.getElementById('custom-dropdown1' + this.unique).offsetWidth
        // console.log(width);
        this.width1 = width + 'px !important'
        document.getElementById('custom-option-data'+this.unique).style.width = width + 'px !important'

        document.addEventListener("click", (e) => {
          if(this.customdropdowncontainer == true)
          {
          const elem = document
            .getElementById("custom-dropdown1" + this.unique)
            .contains(e.target);
            // console.log(e.target);
          if (elem === false) {
            this.customdropdowncontainer = false;
            this.$emit("customErrorEmitValue", this.option_value);
            document
              .getElementById("custom-dropdown1" + this.unique)
              .children[1].classList.add("icon-angle-down-regular");
            document
              .getElementById("custom-dropdown1" + this.unique)
              .children[1].classList.remove("icon-angle-up-regular");
            document
              .getElementById("custom-dropdown1" + this.unique)
              .classList.remove("selecteddropdown");
            if (this.selectcount > 0) {
              this.selectvalue = this.placeholdervalue;
              this.placeholdervalue = "";
              // this.animation = true;
            }
            // console.log('ala');
            this.$emit('blureventDropdown',e)
          }
          }
    });

    if (this.options !== []) {
      this.options.forEach((e) => {
        if (e.checked === undefined) {
          e.checked = false;
          e.disabled = false;
        }
      });
    }
    // console.log(this.multiSelectOptions);
    if (this.labelAnimation === false) {
      this.title = "";
    } else {
      this.title = this.placeholderValue;
    }
    if (this.multiSelectInlineSearch == true) {
      this.single_select = false;
    }  else if (this.icon == true) {
      // this.icon_country_flag = 'undefined'
      this.single_select = false;
      // console.log(this.single_select,this.clearable,'after');

      this.options.forEach((e, index) => {
        if (e.id === undefined) {
          e.id = "singleselect" + this.unique + index;
        }
      });
    } else if (this.textEditor === true) {
      this.single_select = false;
      // console.log(this.single_select,this.clearable,'after');

      // console.log(this.options);
      // this.single_select = false
      // this.icon = false
      // this.multiSelect = false
      if (this.options && this.options.length > 0) {
        this.optionsD = JSON.parse(JSON.stringify([...this.options]));
      }
      this.optionsD.forEach((e, index) => {
        if (e.id === undefined) {
          e.id = "textEditor" + this.unique + index;
        }
        if (e.checked === true) {
          this.option_value = e[this.label];
        }
        // console.log('op');
      });
    } else if (this.customSelect === true) {
      this.custom_select = true;
    } else if (this.inlineSearch === true) {
      this.single_select = false;
      if (this.selectvalue === "") {
        this.placeholdervalue = this.placeholderValue;
      }
      else{
        this.selectcount = 1
      }
    } else {
      this.single_select = true;
      let count = this.options.length;
      const logic = "";
      this.options.forEach((e) => {
        // if(e.id === undefined)
        // {
        //   e.id = 'singleselect' + this.unique + index
        // }
        if (e.checked === true) {
          this.$emit("update:value", e[this.propValue]);
          this.$emit("selectedValue", e);
          count--;
        }
      });
      //  console.log(count,this.options.length);
      if (count === this.options.length) {
        if (this.preValue != "") {
          this.$emit("update:value", logic);
        }
        // console.log('kkklvjuk');
      }
    }
    this.selectall = true;

    if (this.options && this.options.length > 0) {
      if (this.inlineSearch !== true) {
        this.optionsD = JSON.parse(JSON.stringify([...this.options]));
      }
    }

    if (this.preValue !== "") {
      // console.log(this.preValue);
      this.pre_value = this.preValue;
      this.optionsD.forEach((e) => {
        if (e.checked === undefined) {
          if (this.pre_value === e[this.propValue]) {
            e.checked = true;
          } else {
            e.checked = false;
          }
        } else {
          // console.log(this.pre_value , e.value,'hello');
          if (this.pre_value === e[this.propValue]) {
            e.checked = true;
            this.placeholder = false;
            this.dropdownname = true;
            this.dropdownvalue = true;
            this.$emit("update:value", e[this.propValue]);
            this.$emit("customChange", this.optionsD);
          } else {
            e.checked = false;
          }
        }
      });
    }
          this.$nextTick(()=>{
        if(document.querySelector('#hlx-select-container'+this.unique) != null)
        {
          // console.log('aaa',this.optionsD.length);
          // if(this.optionsD.length>6)
          // {

            var element = document.querySelector('#hlx-select-container'+this.unique);
            var position = element.getBoundingClientRect();
            // var x = position.left;
            var y = position.top;
            // console.log(x,y,'pop',window.innerHeight - y);
            var innerheight = window.innerHeight - y
            if(innerheight < 250)
            {
            if(this.optionsD.length == 1)
            {
               this.top = -45
              this.left = -2
            }
            else if(this.optionsD.length == 2)
            {
               this.top = -78
              this.left = -2
            }
            else if(this.optionsD.length == 3)
            {
               this.top = -106
              this.left = -2
            }
            else if(this.optionsD.length == 4)
            {
               this.top = -137
              this.left = -2
            }
            else if(this.optionsD.length == 5)
            {
               this.top = -169
              this.left = -2
            }
            else if(this.optionsD.length == 6)
            {
               this.top = -197
              this.left = -2
            }
            else{
              this.top = -213
              this.left = -2
            }
            }
            else{
              this.top = 45
              this.left = -1
            }
          }
          // else{
          //   // console.log('aa');

          // }
        // }
      })
            this.$nextTick(()=>{
        if(document.querySelector('#custom-dropdown1' + this.unique))
        {
          var element = document.querySelector('#custom-dropdown1' + this.unique);
          var position = element.getBoundingClientRect();
          // var x = position.left;
          var y = position.top;
          // console.log(x,y,'pop',window.innerHeight - y);
          var innerheight = window.innerHeight - y
          if(innerheight < 250)
          {
            // console.log();
                  if(this.dropdowndata.length == 0)
                    {
                      this.top = -45
                      this.left = -2
                    }
                else if(this.dropdowndata.length == 1)
                    {
                      this.top = -45
                      this.left = -2
                    }
                    else if(this.dropdowndata.length == 2)
                    {
                      this.top = -78
                      this.left = -2
                    }
                    else if(this.dropdowndata.length == 3)
                    {
                      this.top = -106
                      this.left = -2
                    }
                    else if(this.dropdowndata.length == 4)
                    {
                      this.top = -137
                      this.left = -2
                    }
                    else if(this.dropdowndata.length == 5)
                    {
                      this.top = -169
                      this.left = -2
                    }
                    else if(this.dropdowndata.length == 6)
                    {
                      this.top = -197
                      this.left = -2
                    }
                    else{
                      this.top = -213
                      this.left = -2
                    }
          }
          else{
            this.top = 40
            this.left = -7
          }
        }
      })
       this.$nextTick(()=>{
        if(document.querySelector('#multiselect' + this.unique))
        {
          var element = document.querySelector('#multiselect' + this.unique);
          var position = element.getBoundingClientRect();
          // var x = position.left;
          var y = position.top;
          // console.log(x,y,'pop',window.innerHeight - y);
          var innerheight = window.innerHeight - y
          if(innerheight < 250)
          {
                  if(this.options.length == 1)
                    {
                      this.top = -45
                      this.left = 0
                    }
                    else if(this.options.length == 2)
                    {
                      this.top = -78
                      this.left = 0
                    }
                    else if(this.options.length == 3)
                    {
                      this.top = -106
                      this.left = 0
                    }
                    else if(this.options.length == 4)
                    {
                      this.top = -137
                      this.left = 0
                    }
                    else if(this.options.length == 5)
                    {
                      this.top = -169
                      this.left = 0
                    }
                    else if(this.options.length == 6)
                    {
                      this.top = -197
                      this.left = 0
                    }
                    else{
                      this.top = -230
                      this.left = 0
                    }
          }
          else{
            this.top = 45
            this.left = -1
          }
        }
      })
      // console.log('.'+this.scrollClassname,'apoa');
      this.$nextTick(()=>{
        if(this.scrollClassname != ''){
          if(document.querySelector('.'+this.scrollClassname))
          {
  
          document.querySelector('.'+this.scrollClassname).addEventListener("scroll", (event) => {
            event
            if(this.inlineSearch == true)
            {
              // console.log('abc');
              this.$nextTick(()=>{
                 if(document.querySelector('#custom-dropdown1' + this.unique)) 
              {
                var element = document.querySelector('#custom-dropdown1' + this.unique);
                var position = element.getBoundingClientRect();
                // var x = position.left;
                var y = position.top;
                // console.log('bbbb');
                // console.log(x,y,'pop',window.innerHeight - y);
                var innerheight = window.innerHeight - y
                if(innerheight < 250)
                {
                  // console.log(this.dropdowndata.length);
                    if(this.dropdowndata.length == 0)
                    {
                        this.top = -45
                        this.left = -2
                    }
                   else if(this.dropdowndata.length == 1)
                    {
                      this.top = -45
                      this.left = -2
                    }
                    else if(this.dropdowndata.length == 2)
                    {
                      this.top = -78
                      this.left = -2
                    }
                    else if(this.dropdowndata.length == 3)
                    {
                      this.top = -106
                      this.left = -2
                    }
                    else if(this.dropdowndata.length == 4)
                    {
                      this.top = -137
                      this.left = -2
                    }
                    else if(this.dropdowndata.length == 5)
                    {
                      this.top = -169
                      this.left = -2
                    }
                    else if(this.dropdowndata.length == 6)
                    {
                      this.top = -197
                      this.left = -2
                    }
                    else{
                      this.top = -213
                      this.left = -2
                    }
                }
                else{
                  this.top = 45
                  this.left = -1
                }
              }
            })
              // console.log('aaaa');
              // console.log(event);
            }
            else if(this.multiSelect == true)
            {
                        this.$nextTick(()=>{
                 if(document.querySelector('#multiselect' + this.unique)) 
              {
                var element = document.querySelector('#multiselect' + this.unique);
                var position = element.getBoundingClientRect();
                // var x = position.left;
                var y = position.top;
                // console.log('bbbb');
                // console.log(x,y,'pop',window.innerHeight - y);
                var innerheight = window.innerHeight - y
                if(innerheight < 250)
                {
                  if(this.options.length == 1)
                    {
                      this.top = -45
                      this.left = 0
                    }
                    else if(this.options.length == 2)
                    {
                      this.top = -78
                      this.left = 0
                    }
                    else if(this.options.length == 3)
                    {
                      this.top = -106
                      this.left = 0
                    }
                    else if(this.options.length == 4)
                    {
                      this.top = -137
                      this.left = 0
                    }
                    else if(this.options.length == 5)
                    {
                      this.top = -169
                      this.left = 0
                    }
                    else if(this.options.length == 6)
                    {
                      this.top = -197
                      this.left = 0
                    }
                    else{
                      this.top = -230
                      this.left = 0
                    }
                }
                else{
                  this.top = 45
                  this.left = -1
                }
              }
            })
              // console.log('aaaa');
              // console.log(event);
            }
            else{
              this.$nextTick(()=>{
             if(document.querySelector('#hlx-select-container'+this.unique)) 
             {
               var element = document.querySelector('#hlx-select-container'+this.unique);
               var position = element.getBoundingClientRect();
               // var x = position.left;
               var y = position.top;
               // console.log(x,y,'pop',window.innerHeight - y);
               var innerheight = window.innerHeight - y
               if(innerheight < 250)
               {
                    if(this.options.length == 1)
                    {
                      this.top = -45
                      this.left = -2
                    }
                    else if(this.options.length == 2)
                    {
                      this.top = -78
                      this.left = -2
                    }
                    else if(this.options.length == 3)
                    {
                      this.top = -106
                      this.left = -2
                    }
                    else if(this.options.length == 4)
                    {
                      this.top = -137
                      this.left = -2
                    }
                    else if(this.options.length == 5)
                    {
                      this.top = -169
                      this.left = -2
                    }
                    else if(this.options.length == 6)
                    {
                      this.top = -197
                      this.left = -2
                    }
                    else{
                      this.top = -213
                      this.left = -2
                    }
               }
               else{
                 this.top = 45
                 this.left = -1
               }
             }
           }) 
            }
          })
        }
        }
      })
    
    
  },
  methods: {
    focusemit()
    {
      // console.log('aa');

   
    },
    switchdropdown(data)
    {
      // console.log(data);
                  this.$nextTick(()=>{
        if(document.querySelector('#custom-dropdown1' + this.unique))
        {
          var element = document.querySelector('#custom-dropdown1' + this.unique);
          var position = element.getBoundingClientRect();
          // var x = position.left;
          var y = position.top;
          // console.log(x,y,'pop',window.innerHeight - y);
          var innerheight = window.innerHeight - y
          if(innerheight < 250)
          {
            // console.log();
                  if(data.length == 0)
                    {
                      this.top = -45
                      this.left = -2
                    }
                else if(data.length == 1)
                    {
                      this.top = -45
                      this.left = -2
                    }
                    else if(data.length == 2)
                    {
                      this.top = -78
                      this.left = -2
                    }
                    else if(data.length == 3)
                    {
                      this.top = -106
                      this.left = -2
                    }
                    else if(data.length == 4)
                    {
                      this.top = -137
                      this.left = -2
                    }
                    else if(data.length == 5)
                    {
                      this.top = -169
                      this.left = -2
                    }
                    else if(data.length == 6)
                    {
                      this.top = -197
                      this.left = -2
                    }
                    else{
                      this.top = -213
                      this.left = -2
                    }
          }
          else{
            this.top = 45
            this.left = -1
          }
        }
      })
    },
    check(event) {
      console.log(event.target.value);
      // this.dropdowndata
      this.selectvalue = event.target.value
      this.$emit("update:modelValue", event.target.value);
    },
    Customoptioncontainer(event) {
      if (this.customdropdowncontainer === false) {
          
              const icon = document.getElementById('custom-dropdown1-whole-container' + this.unique);
      const parent = icon.parentElement;
      const { top, left } = parent.getBoundingClientRect();
      this.top1 = top + 42 + "px !important";
      this.left1 = left + "px !important";
        let width = document.getElementById('custom-dropdown1' + this.unique).offsetWidth
        this.width1 = width + 'px !important'
        if (event.target.className === "icon-angle-down-regular") {
          this.customdropdowncontainer = true;
          event.target.classList.add("icon-angle-up-regular");
          event.target.classList.remove("icon-angle-down-regular");
          event.target.parentElement.classList.add("selecteddropdown");
          this.$emit('focusEvent',event)
          if (this.selectcount > 0) {
            this.placeholdervalue = this.selectvalue;
            this.selectvalue = "";
          }
        } else if (event.target.className === "custom-dropdown1") {
          this.customdropdowncontainer = true;
          document.querySelector("#custom-input" + this.unique).focus();
          event.target.children[1].classList.add("icon-angle-up-regular");
          event.target.children[1].classList.remove("icon-angle-down-regular");
          event.target.classList.add("selecteddropdown");
          this.$emit('focusEvent',event)
                    // console.log(this.selectcount,'pop');

          if (this.selectcount > 0) {
            this.placeholdervalue = this.selectvalue;
            this.selectvalue = "";
          }
        } else if (event.target.className === "custom-dropdown1-input") {
          this.customdropdowncontainer = true;
          document.querySelector("#custom-input" + this.unique).focus();
          event.target.nextElementSibling.classList.add(
            "icon-angle-up-regular"
          );
          event.target.nextElementSibling.classList.remove(
            "icon-angle-down-regular"
          );
          event.target.parentElement.classList.add("selecteddropdown");
          this.$emit('focusEvent',event)
                    // console.log(this.selectcount,'pop');

          if (this.selectcount > 0) {
            this.placeholdervalue = this.selectvalue;
            this.selectvalue = "";
          }
        }
      } else if(this.customdropdowncontainer === true) {
        document.querySelector("#custom-input" + this.unique).blur();
        if (event.target.className === "icon-angle-up-regular") {
          this.customdropdowncontainer = false;
          event.target.classList.add("icon-angle-down-regular");
          event.target.classList.remove("icon-angle-up-regular");
          event.target.parentElement.classList.remove("selecteddropdown");
          if (this.selectcount > 0) {
            this.selectvalue = this.placeholdervalue;
            this.placeholdervalue = "";
          }
        } else if (event.target.className === "custom-dropdown1") {
          this.customdropdowncontainer = false;
          // this.input = false
          // this.labelinput = true
          event.target.children[1].classList.add("icon-angle-down-regular");
          event.target.children[1].classList.remove("icon-angle-up-regular");
          event.target.classList.remove("selecteddropdown");
          if (this.selectcount > 0) {
            this.selectvalue = this.placeholdervalue;
            this.placeholdervalue = "";
          }
        } else if (event.target.className === "custom-dropdown1-input") {
          // console.log('aa',event.target);
          this.customdropdowncontainer = false;
          event.target.nextElementSibling.classList.add(
            "icon-angle-down-regular"
          );
          event.target.nextElementSibling.classList.remove(
            "icon-angle-up-regular"
          );
          event.target.parentElement.classList.remove("selecteddropdown");
          if (this.selectcount > 0) {
            this.selectvalue = this.placeholdervalue;
            this.placeholdervalue = "";
          }
        }
        // console.log(document.querySelector('.customdropdown-option-container').getBoundingClientRect());
      }
    },
    customSelectoptionvalue($event,data, data1) {
      // console.log('aaa');
              this.$emit('blureventDropdown',$event)

      // this.selectvalue = data1
      // console.log(data,data1,'plo');
      // console.log(this.selectvalue,data1);
      this.customoption.forEach((e) => {
        if (data === e[this.propValue]) {
          // console.log(data,e.value);
          e.checked = true;
          this.selectvalue = e[this.label];
          this.$emit("update:modelValue", this.selectvalue);
          this.$emit("selectedValue", e);
          // console.log(this.selectvalue,"asdasdasd")
        } else {
          e.checked = false;
        }
      });
      this.selectcount++;
      this.placeholdervalue = "";
      if (this.labelAnimation === true) {
        // console.log('ssss');
        this.animation = true;
      }
      this.$emit("customChange", this.customoption);
      this.inlinevalueselect = true;
      this.$emit("customErrorEmitValue", this.selectvalue);
      // console.log(data1,data,'okok');
      this.$emit("inlineReferenceValue", data1);
      this.$emit("inlineValue", data);
      this.$emit("update:value",data)
      // console.log(event.target,event.target.parentElement.parentElement.parentElement.children[1]);
           let elem1 = document
            .getElementById("custom-option-data" + this.unique)
            .contains(event.target);
            if(elem1 === true)
            {
              if (this.customdropdowncontainer == true) {
                this.customdropdowncontainer = false;
                document.getElementById('custom-dropdown1' + this.unique).children[1].classList.add('icon-angle-down-regular')
                document.getElementById('custom-dropdown1' + this.unique).children[1].classList.remove('icon-angle-up-regular')
                
              }
              // document.getElementById('custom-dropdown1' + this.unique).classList.remove('selecteddropdown')
            }
    },
    senddata(event) {
      if (event.key === "Enter") {
        // console.log('aa',this.selectvalue,event.target);
        this.customdropdowncontainer = false;
        event.target.nextElementSibling.classList.add(
          "icon-angle-down-regular"
        );
        event.target.nextElementSibling.classList.remove(
          "icon-angle-up-regular"
        );
        this.customoption.forEach((e) => {
          e.checked = false;
        });
        const obj = {};
        obj[this.label] = this.selectvalue;
        obj[this.propValue] = this.selectvalue;
        obj.checked = true;
        obj.icon = "icon-database-regular";
        this.customoption.push(obj);
        document.querySelector("#custom-input" + this.unique).blur();
        this.$emit("searchValue", this.customoption);
      }
    },
    removevalue() {
      // console.log(this.selectoptioncontainer,'pos');
      // this.selectoptioncontainer
      this.dropdownvalue = false;
      this.placeholder = true;
      this.option_value = "";
      this.dropdownname = false;
      this.optionsD.forEach((e) => {
        if (e.checked == true) {
          e.checked = false;
        }
      });
      this.$emit("customChange", this.optionsD);
      this.$emit("clearableValue", this.optionsD);
      if (this.selectoptioncontainer == false) {
        this.$emit("customErrorEmitValue", this.option_value);
      }

      // console.log(this.options,'poj');
    },
    SelectOption(value) {
      this.selectedvalue = [];
      this.pre_value = "";
      this.placeholder = false;
      this.dropdownvalue = true;
      this.dropdownname = true;
      this.selectoptioncontainer = false;
      if (document.getElementById("title" + this.unique)) {
        document
          .getElementById("title" + this.unique)
          .classList.remove("dropdownname-color");
      }
      if (document.getElementById("title1" + this.unique)) {
        document
          .getElementById("title1" + this.unique)
          .classList.remove("dropdownname-color");
      }
      if (
        event.target.parentElement.parentElement.parentElement.className ===
        "hlx-select-container change-color"
      ) {
        event.target.parentElement.parentElement.parentElement.classList.remove(
          "change-color"
        );
        event.target.parentElement.parentElement.previousElementSibling.children[0].classList.remove(
          "add"
        );
      }
      if (
        event.target.parentElement.parentElement.className ===
        "hlx-select-container change-color"
      ) {
        event.target.parentElement.parentElement.classList.remove(
          "change-color"
        );
        event.target.parentElement.previousElementSibling.children[0].classList.remove(
          "add"
        );
      }
      if (this.single_select == true || this.textEditor == true) {
        this.optionsD.map((e) => {
          if (e[this.propValue] === value) {
            e.checked = true;
            this.option_value = e[this.label];
            this.icon_value = e.icon;
            // console.log(this.option_value,'check');
            // this.Valueid = e.id.toString()
          } else {
            e.checked = false;
          }
        });
        // console.log("pop", this.optionsD, value);
      }
      if (this.icon === true) {
        // console.log(value,'po');
        this.optionsD.map((e) => {
          if (e[this.propValue] === value) {
            e.checked = true;
            // console.log(event.target,'dei');
            this.option_value = e[this.label];
            this.icon_country_flag = e.flag;
            // console.log(this.option_value,this.icon_country_flag);
          } else {
            e.checked = false;
          }
        });
      }
      // this.optionsD = JSON.parse(JSON.stringify([...this.optionsD]))
      let b = "";
      let c = "";
      this.optionsD.forEach((e) => {
        if (e.checked === true) {
          b = e[this.propValue];
          c = e[this.label];
          this.$emit("selectedValue", e);
          // console.log(b);
        }
      });
      // console.log(b,'op',this.optionsD);
      this.$emit("customErrorEmitValue", this.option_value);
      this.$emit("update:value", b);
      this.$emit("update:name", c);
      this.$emit("customChange", this.optionsD);
    },
    closeselect() {
      document.querySelector(".hlx-select-option-container").style.display = "";
    },
    OptionContainer(event) {
      // this.$emit('dropdownstatus','open')
      // console.log(event.target.parentElement);
      if (this.selectoptioncontainer === false) {
        if (event.target.className === "hlx-select-container") {
          // console.log(this.optionsD,'hello');
          if (
            event.target.lastElementChild.previousElementSibling.children[0]
              .className === "icon-angle-down-regular"
          ) {
            event.target.lastElementChild.previousElementSibling.children[0].classList.add(
              "add"
            );
          }
          // console.log(event.target.lastElementChild.previousElementSibling.children[0].className);/
          this.selectoptioncontainer = true;
          if (this.selectoptioncontainer == true && this.option_value != "") {
            if (document.getElementById("title1" + this.unique)) {
              document
                .getElementById("title1" + this.unique)
                .classList.add("dropdownname-color");
            }
            if (document.getElementById("title" + this.unique)) {
              document
                .getElementById("title" + this.unique)
                .classList.add("dropdownname-color");
            }
          }
          event.target.classList.add("change-color");
          // event.target.lastElementChild.previousElementSibling.children[0].classList.add('add')

          // console.log('aaa')
        } else if (
          event.target.parentElement.className === "hlx-select-container"
        ) {
          this.selectoptioncontainer = true;
          if (this.selectoptioncontainer == true && this.option_value != "") {
            if (document.getElementById("title1" + this.unique)) {
              document
                .getElementById("title1" + this.unique)
                .classList.add("dropdownname-color");
            }
            if (document.getElementById("title" + this.unique)) {
              document
                .getElementById("title" + this.unique)
                .classList.add("dropdownname-color");
            }
          }
          event.target.parentElement.classList.add("change-color");
          if (
            event.target.parentElement.lastElementChild.previousElementSibling
              .children[0].className === "icon-angle-down-regular"
          ) {
            event.target.parentElement.lastElementChild.previousElementSibling.children[0].classList.add(
              "add"
            );
          }
          // console.log('bbb');
        } else if (
          event.target.parentElement.parentElement.className ===
          "hlx-select-container"
        ) {
          // console.log(event.target.parentElement.parentElement.className);
          this.selectoptioncontainer = true;
          event.target.parentElement.parentElement.classList.add(
            "change-color"
          );
          if (event.target.className === "icon-angle-down-regular") {
            event.target.classList.add("add");
          }
          if (this.selectoptioncontainer == true && this.option_value != "") {
            if (document.getElementById("title1" + this.unique)) {
              document
                .getElementById("title1" + this.unique)
                .classList.add("dropdownname-color");
            }
            if (document.getElementById("title" + this.unique)) {
              document
                .getElementById("title" + this.unique)
                .classList.add("dropdownname-color");
            }
          }
        }
      } else if (this.selectoptioncontainer === true) {
        // console.log('op');
        // this.$emit('dropdownstatus','close')
        if (this.option_value === "") {
          // this.dropdownname = true
          this.placeholder = true;
        }
        // else{
        //   document.getElementById('title'+this.unique).classList.add('color-change')

        // }
        this.selectoptioncontainer = false;
        if (document.getElementById("title1" + this.unique)) {
          document
            .getElementById("title1" + this.unique)
            .classList.remove("dropdownname-color");
        }
        if (document.getElementById("title" + this.unique)) {
          document
            .getElementById("title" + this.unique)
            .classList.remove("dropdownname-color");
        }
        if (event.target.className === "hlx-select-container change-color") {
          event.target.classList.remove("change-color");
          // console.log(event.target, 'lol')
          event.target.lastElementChild.previousElementSibling.children[0].classList.remove(
            "add"
          );
        } else if (
          event.target.parentElement.className ===
          "hlx-select-container change-color"
        ) {
          event.target.parentElement.classList.remove("change-color");
          event.target.parentElement.lastElementChild.previousElementSibling.children[0].classList.remove(
            "add"
          );
        } else if (
          event.target.parentElement.parentElement.className ===
          "hlx-select-container change-color"
        ) {
          event.target.parentElement.parentElement.classList.remove(
            "change-color"
          );
          event.target.classList.remove("add");
        }
      }
    },
    OptionContainer1(event) {
      if (this.iconselect === false) {
        if (event.target.className === "hlx-select-container") {
          this.nplace = true;
          this.place = false;
          this.noplace = true;
          this.iconselect = true;
          event.target.classList.add("change-color");
          event.target.nextElementSibling.nextElementSibling.children[0].classList.add(
            "add"
          );
        } else if (event.target.className === "hlx-select-input-placeholder") {
          this.nplace = true;
          this.place = false;
          this.noplace = true;
          this.iconselect = true;
          event.target.parentElement.classList.add("change-color");
          event.target.nextElementSibling.children[0].classList.add("add");
        } else if (event.target.className === "icon-angle-down-regular") {
          this.nplace = true;
          this.place = false;
          this.noplace = true;
          this.iconselect = true;
          event.target.parentElement.parentElement.classList.add(
            "change-color"
          );
          event.target.classList.add("add");
        } else if (event.target.className === "hlx-select-icon") {
          this.nplace = true;
          this.place = false;
          this.noplace = true;
          this.iconselect = true;
          event.target.parentElement.classList.add("change-color");
          event.target.children[0].classList.add("add");
        }
      } else if (this.iconselect === true) {
        if (event.target.className === "hlx-select-container change-color") {
          this.nplace = false;
          this.place = true;
          this.noplace = false;
          this.iconselect = false;
          event.target.classList.remove("change-color");
          event.target.children[2].children[0].classList.remove("add");
        } else if (event.target.className === "hlx-select-input") {
          this.nplace = false;
          this.place = true;
          this.noplace = false;
          this.iconselect = false;
          event.target.parentElement.classList.remove("change-color");
          // console.log(event.target)
          event.target.nextElementSibling.children[0].classList.remove("add");
        } else if (event.target.className === "icon-angle-down-regular add") {
          this.nplace = false;
          this.place = true;
          this.noplace = false;
          this.iconselect = false;
          event.target.parentElement.parentElement.classList.remove(
            "change-color"
          );
          event.target.classList.remove("add");
        } else if (event.target.className === "hlx-select-icon") {
          this.nplace = false;
          this.place = true;
          this.noplace = false;
          this.iconselect = false;
          event.target.parentElement.classList.remove("change-color");
          event.target.children[0].classList.remove("add");
        }
      }
    },
    color() {
      if (this.isMultiSelect === true) {
        this.isMultiSelect = false;
        document
          .getElementById("multiselect" + this.unique)
          .classList.remove("add-style");
      } else if (this.isMultiSelect === false) {
        this.isMultiSelect = true;
        document
          .getElementById("multiselect" + this.unique)
          .classList.add("add-style");
      }
    },
    MultiselectOptioncontainer() {
      this.multi_select_value = this.multiselectarray
        .filter((data) => {
          if (data.checked == true) {
            this.multiselecttitle = true;
            this.multiselectplaceholder = false;
            this.multiselectdropdownvalue = true;
            return true;
          }
        })
        .map((data) => {
          // console.log(data);
          return data[this.label];
        });
      // console.log(this.multi_select_value);
      if (this.multi_select_value.length != 0) {
        for (const i in this.multi_select_value) {
          i;
          if (this.multi_select_value.length > 3) {
            this.count = true;
            this.count_select_value = this.multi_select_value.length - 3;
          } else {
            this.count = false;
          }
        }
      } else {
        this.multiselectplaceholder = true;
        this.multiselectdropdownvalue = false;
        this.multiselecttitle = false;
      }

      // console.log(this.multiSelectOptions,'vfjdx');
    },
    removemultiselectvalue(removedItem) {
      this.multi_select_value = this.multi_select_value.filter(
        (data) => data != removedItem
      );
      this.multiselectarray.filter((value) => {
        if (value[this.label] == removedItem) {
          return (value.checked = false);
        }
      });
      this.count_select_value = this.multi_select_value.length - 3;
      // console.log(this.multi_select_value,'loloool');
      if (this.multi_select_value.length == 0) {
        this.multiselectplaceholder = true;
        this.multiselectdropdownvalue = false;
        this.multiselecttitle = false;
      }
      document.querySelectorAll(".check-container").forEach((e) => {
        if (removedItem === e.innerText) {
          e.click();
        }
      });
    },
    MultiSelectOption(event) {
      if (
        event.target.parentElement.className ===
        "hlx-multi-select-option-value-container select-value"
      ) {
        event.target.parentElement.classList.remove("select-value");
      } else if (
        event.target.className ===
        "hlx-multi-select-option-value-container select-value"
      ) {
        event.target.classList.remove("select-value");
      }
      if (event.target.className === "hlx-multi-select-option-value") {
        if (this.multi_select_value.length < "2") {
          this.option_value = event.target.innerText;
          this.multi_select_value.push(this.option_value);
          this.multi_select_count_value.push(this.option_value);
        } else if (this.multi_select_value.length >= "2") {
          this.option_value = event.target.innerText;
          this.count_select_value.push(this.option_value);
          this.multi_select_count_value.push(this.option_value);
        }
        event.target.parentElement.classList.add("select-value");
      } else if (
        event.target.className === "hlx-multi-select-option-value-container"
      ) {
        if (this.multi_select_value.length < "2") {
          this.option_value = event.target.innerText;
          this.multi_select_value.push(this.option_value);
          this.multi_select_count_value.push(this.option_value);
        } else if (this.multi_select_value.length >= "2") {
          this.option_value = event.target.innerText;
          this.count_select_value.push(this.option_value);
          this.multi_select_count_value.push(this.option_value);
        }
        event.target.classList.add("select-value");
      }
    },
    search(by) {
      this.search_by = by == "" ? null : by;
    },
    emitfiltervalue() {
      this.isMultiSelect = false;
      this.all = false;

      this.$emit("customChange", this.checkedData);
    },
    emitclearvalue() {
      this.checkedData.forEach((e) => {
        e.checked = false;
      });
      this.$emit("customChange", this.checkedData);
    },
    clearIcon() {
      if (this.option_value != "") {
        document.getElementById("arrowid" + this.unique).style.display = "none";
        document.getElementById("crossid" + this.unique).style.display =
          "block";
      }
    },
    arrowIcon() {
      // console.log(event);
      if (
        document.getElementById("crossid" + this.unique).style.display ==
        "block"
      ) {
        document.getElementById("arrowid" + this.unique).style.display =
          "block";
        document.getElementById("crossid" + this.unique).style.display = "none";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
i {
  font-size: 12px;
}
</style>
