<template>
  <div class="app-layout">
    <section class="right-panel">
      <section class="layout-basic add-schema">
        <div class="layout-breadcrumb">
          <hlx-breadcrumb
            :items="items"
            @path="pathTo"
          />
        </div>
        <div class="layout-header">
          <span class="title">Schema</span>
        </div>
        <div class="layout-body">
          <div class="grid">
            <span class="titles"
              >Create new schema
              <span class="info"
                >Schema is the overall description of the database.</span
              ></span
            >
            <span class="api-data-schema">
              <hlx-input
                v-model:value="add_payload.name"
                :label-value="'Schema name'"
                :label-animation="true"
              ></hlx-input
              ><br />
              <hlx-input
                v-model:value="add_payload.description"
                :label-animation="true"
                label-value="Description"
                type="textarea"
                :clearable="true"
              />
            </span>
          </div>
          <div class="divider"><hr /></div>
          <div class="grid">
            <span class="titles"
              >Type <span class="info">Choose the type of input.</span></span
            >
            <span class="api-data-content">
              <span class="type">
                <hlx-switch
                v-model="type"
                display-prop="name"
                value-prop="value"
                :switch-items="schema_type_switch"
                @change="schemaSelected"
                />
              </span>
            </span>
          </div>

          <div class="divider"><hr /></div>
          <div class="grid">
            <span class="titles"
              >Content
              <span class="info">Define data in JSON format</span></span
            >
            <span
              v-if="type == 'Manual'"
              class="api-data-content-text"
            >
              <hlx-code-editor
                :line-number="true"
                :beautify="true"
                @code-changes="bodyPayload"
              ></hlx-code-editor>
            </span>
            <span
              v-if="type == 'Upload'"
              class="upload-data-content"
            >
              <hlx-file-upload
                :file-type="'.json'"
                :file-size-limit="'20mb'"
                :custom-icon="'icon-eye'"
                @preview-file="previewUploadedFile"
                @file-data="fileData"
              ></hlx-file-upload>
              <span class="info">Maximum file size is 25 MB</span>
            </span>

            <span
              v-if="type == 'API'"
              class="api-data-content"
            >
              <div class="method-url">
                <span class="method">
                  <hlx-select
                    v-model:value="add_payload.method"
                    :options="method_options"
                  ></hlx-select>
                </span>
                <span class="url">
                  <hlx-input
                    v-model:value="add_payload.url"
                    type="text"
                    :label-value="'Enter request URL'"
                  ></hlx-input>
                </span>
                <span class="schedule">
                  <div class="choose-schedule">
                    <i class="icon-clock-regular"></i
                    >{{ add_payload.schedule }}
                  </div>
                </span>
              </div>
            </span>
          </div>

          <div
            v-if="type == 'API'"
            class="grid"
          >
            <span class="titles"
              >URL parameters <span class="info"></span
            ></span>
            <span class="api-data-content">
              <div class="params">
                <div
                  v-for="(param, index) in add_payload.params"
                  :key="index"
                  class="param-object"
                >
                  <span class="key">
                    <hlx-input
                      :label-value="'Key'"
                      :pre-val="add_payload.params[index].key"
                      @at-input="paramKey($event, index, 'key')"
                    ></hlx-input>
                  </span>

                  <span class="value"
                    ><hlx-input
                      :label-value="'Value'"
                      :pre-val="add_payload.params[index].value"
                      @at-input="paramKey($event, index, 'value')"
                    ></hlx-input
                  ></span>
                  <span
                    ><i
                      class="icon-plus-circle-filled"
                      @click="addParamRow"
                    ></i
                  ></span>
                  <span class="delete-param"
                    ><i
                      v-if="add_payload.params.length > 1"
                      class="icon-trash-filled"
                      @click="deleteParamRow(index)"
                    ></i
                  ></span>
                </div>
              </div>
            </span>
          </div>

          <div
            v-if="type == 'API'"
            class="grid"
          >
            <span class="titles">Headers <span class="info"></span></span>
            <span class="api-data-content">
              <div class="headers">
                <div
                  v-for="(header, index) in add_payload.headers"
                  :key="index"
                  class="header-object"
                >
                  <span class="key">
                    <hlx-input
                      :label-value="'Key'"
                      :pre-val="add_payload.headers[index].key"
                      @at-input="headerKey($event, index, 'key')"
                    ></hlx-input>
                  </span>

                  <span class="value"
                    ><hlx-input
                      :label-value="'Value'"
                      :pre-val="add_payload.headers[index].value"
                      @at-input="headerKey($event, index, 'value')"
                    ></hlx-input
                  ></span>
                  <span
                    ><i
                      class="icon-plus-circle-filled"
                      @click="addHeaderRow"
                    ></i
                  ></span>
                  <span class="delete-header"
                    ><i
                      v-if="add_payload.headers.length > 1"
                      class="icon-trash-filled"
                      @click="deleteHeaderRow(index)"
                    ></i
                  ></span>
                </div>
              </div>
            </span>
          </div>

          <div
            v-if="type == 'API'"
            class="grid"
          >
            <span class="titles">Authorization <span class="info"></span></span>
            <span class="api-data-content-auth">
              <div class="auth">
                <div class="auth-object">
                  <hlx-switch
                    :type="'regular'"
                    :switch-items="auth_switch_items"
                    @chosen="authSelected"
                  />
                </div>
              </div>
              <div
                v-if="add_payload.auth === 'Basic auth'"
                class="auth-cred"
              >
                <div class="auth-cred-items">
                  <span class="key"
                    ><hlx-input
                      v-model:value="add_payload.credential.username"
                      :label-value="'Username'"
                    ></hlx-input
                  ></span>
                  <span class="value"
                    ><hlx-input
                      v-model:value="add_payload.credential.password"
                      :label-value="'Password'"
                    ></hlx-input
                  ></span>
                </div>
              </div>
              <div
                v-if="add_payload.auth === 'Bearer token'"
                class="auth-cred"
              >
                <div class="auth-cred-items">
                  <span class="token"
                    ><hlx-input
                      v-model:value="add_payload.credential.token"
                      :label-value="'Token'"
                    ></hlx-input
                  ></span>
                </div>
              </div>
              <div
                v-if="add_payload.auth === 'API key'"
                class="auth-cred"
              >
                <div class="auth-cred-items">
                  <span class="api-key"
                    ><hlx-input
                      v-model:value="add_payload.credential.apikey"
                      :label-value="'API key'"
                    ></hlx-input
                  ></span>
                </div>
              </div>
            </span>
          </div>

          <div
            v-if="type == 'API'"
            class="divider"
          >
            <hr />
          </div>
          <div
            v-if="type == 'API'"
            class="grid"
          >
            <span class="titles"
              >Request body
              <span class="info">Define data in JSON format</span></span
            >
            <span class="api-data-content-text">
              <hlx-code-editor
                :line-number="true"
                :beautify="true"
                @code-changes="bodyPayload"
              ></hlx-code-editor>
              <div class="send-api">
                <hlx-button
                  class="primary sm"
                  @click="sendAPIData"
                  >Send</hlx-button
                >
              </div>
            </span>
          </div>

          <div
            v-if="type == 'API' && response == true"
            class="grid"
          >
            <span class="titles">Response <span class="info"></span></span>
            <span
              v-if="type == 'API' && response == true"
              class="api-data-content-text"
            >
              <div class="status">
                <span class="title">Status: </span>
                <span class="value">{{ response_status }}</span>
                <span class="title">Time: </span>
                <span class="value">{{ response_time }}</span>
              </div>
              <div class="response-result"></div>
            </span>
          </div>
        </div>
        <div class="layout-footer-fixed">
          <div class="go-back">
            <hlx-button
              class="secondary sm"
              @click="cancel"
              >Cancel</hlx-button
            >
            <hlx-button
              class="primary sm"
              style="margin-left: 20px"
              @click="saveAPIData"
              >Save</hlx-button
            >
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { DMNAxiosInstance } from '@/config/axiosConfig';

export default {
  data() {
    return {
      nav_items: [
        {
          name: 'Home',
          label: 'Home',
          icon: 'icon-grid-web-7-regular',
          description_en: 'Home',
          description_fn: '*Home',
        },
        {
          name: 'Business rule',
          label: 'Business rule ',
          icon: 'icon-code-regular',
          description_en: 'Residential business market ',
          description_fr: '*Residential business market ',
        },
        {
          name: 'Schema',
          label: 'Schema',
          icon: 'icon-desktop-code-regular',
          description_en: 'Small business markets',
          description_fr: '*Small business markets',
          active: true,
        },
        {
          name: 'Database',
          label: 'Database',
          icon: 'icon-database-regular',
          description_en: 'Small business markets',
          description_fr: '*Small business markets',
        },
        {
          name: 'Execute',
          label: 'Execute',
          icon: 'icon-file-settings-regular',
          description_en: 'Small business markets',
          description_fr: '*Small business markets',
        },
        {
          name: 'Audit',
          label: 'Audit',
          icon: 'icon-notebook-regular',
          description_en: 'Audit log',
          description_fr: '*Audit log',
        },

        {
          name: 'Help',
          label: 'Help',
          icon: 'icon-question-circle-regular',
          description_en: 'Setting',
          description_fr: '*Setting',
          separator: true,
        },
        {
          name: 'settings',
          label: 'settings',
          icon: 'icon-settings-regular',
          description_en: 'Setting',
          description_fr: '*Setting',
        },
        {
          name: 'logout',
          label: 'Logout',
          icon: 'icon-log-out-regular',
          description_en: 'Logout',
          description_fr: '*Logout',
        },
      ],
      type: 'manual',
      response: false,
      items: [
        { label: 'Schemas', link: '/schemas/list' },
        { label: 'Add schema', link: '/schemas' },
      ],
      apiBody: '',
      response_status: '200 OK',
      response_time: '91ms',
      add_payload: {
        name: '',
        description: '',
        method: '',
        url: '',
        data: [],
        schedule: '',
        params: [{ key: '', value: '' }],
        headers: [{ key: '', value: '' }],
        auth: '',
        credential: {},
      },
      schema_type_switch: [
        { name: 'Manual',value:'manual', },
        // { name: "API" },
        { name: "Upload",value:'name' },
      ],
      auth_switch_items: [
        { name: 'No auth', checked: true },
        { name: 'Basic auth' },
        { name: 'Bearer token' },
        { name: 'API key' },
      ],
      method_options: [
        {
          name: 'GET',
          checked: true,
          id: 1,
        },
        {
          name: 'POST',
          checked: false,
          id: 2,
        },
      ],
      file_data: '',
    };
  },
  watch: {
    add_payload: {
    },
  },
  mounted() {
    this.$store.commit('token', this.$route.params.token);
  },
  methods: {
    navTo(active) {
      this.$store.commit('token', this.$route.params.token);
      
      if (active.name == 'Home') {
        this.navHome();
      } else if (active.name == 'logout') {
        this.logout();
      } else if (active.name == 'settings') {
        this.setting();
      } else if (active.name == 'Schema') {
        this.schema();
      } else if (active.name == 'Business rule') {
        this.ruled();
      } else if (active.name === 'Database') {
        this.execute();
      } else if (active.name == 'Help') {
        this.help();
      } else if (active.name == 'Execute') {
        this.exe();
      } else {
        this.audit();
        // 
      }
    },
    audit() {
      this.$router.push(`/DMN/audit`);
    },
    help() {
      window.open('https://documentation.halleyx.com');
    },
    ruled() {
      this.$router.push(`/DMN/business_rules`);
    },
    exe() {
      this.$router.push(`/DMN/rule_validation`);
    },
    execute() {
      this.$router.push(`/DMN/reference_data`);
    },
    schema() {
      this.$router.push(`/DMN/schemas`);
    },

    logout() {
      this.$router.push('/');
    },
    setting() {
      this.$router.push(`/settings`);
    },
    async navHome() {
      this.$router.push(`/DMN/dashboard`);
    },
    fileData(val) {
      
      this.file_data = val;
    },
    pathTo() {
        this.$router.back();
      //  this.$router.push(val.link)
      // this.$router.push(`/DMN/${val.link}`);

      //  window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/${val.link} `;
    },
    cancel() {
      
      this.$router.back();
    },
    async saveAPIData() {
      
      switch (this.type) {
        case 'manual':
          
          // let schema =this.apiBody
          // 
          await DMNAxiosInstance.post(
            `rule_engine/schema/add?id=${this.id}&name=${this.name}`,
            JSON.parse(this.apiBody)
          ).then(() => {
            
          });
          break;
        case 'api':
          break;
        case 'upload':
          //  const formData = new FormData();
          // formData.append('file', this.file_data.data);
          await DMNAxiosInstance.post(
            `rule_engine/schema/add?id=${this.id}&name=${this.name}`,
            JSON.parse(this.file_data.data)
          ).then(() => {
            
          });
          break;
      }
      
    },
    paramKey(e, index, attr) {
      if (attr === 'key') {
        this.add_payload.params[index].key = e;
      }
      if (attr === 'value') {
        this.add_payload.params[index].value = e;
      }
    },
    addParamRow() {
      const obj = {};
      obj.key = '';
      obj.value = '';
      obj.index = '';
      this.add_payload.params.push(obj);
    },
    deleteParamRow(index) {
      this.add_payload.params.splice(index, 1);
    },
    headerKey(e, index, attr) {
      if (attr === 'key') {
        this.add_payload.headers[index].key = e;
      }
      if (attr === 'value') {
        this.add_payload.headers[index].value = e;
      }
    },
    addHeaderRow() {
      const obj = {};
      obj.key = '';
      obj.value = '';
      obj.index = '';
      this.add_payload.headers.push(obj);
    },
    deleteHeaderRow(index) {
      this.add_payload.headers.splice(index, 1);
    },
    authSelected(val) {
      
      this.add_payload.auth = val;
    },
    schemaSelected(val) {
      
      this.type = val.value;
    },
    bodyPayload(val) {
      this.apiBody = val;
    },
    sendAPIData() {
      this.response = !this.response;
      this.add_payload.data = this.apiBody;
      
    },
  },
};
</script>
