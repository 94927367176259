<template>
  <div
    style="
      height: 100vh;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
    "
  >
    <img
      src="../../assets/images/ProductCatalogEmptyView.svg"
      alt="product catalog dashboard"
      style="object-fit: contain; width: 500px"
    />
  </div>
</template>
