<template>
  <div
    v-if="showInputColMap"
    class="ip-col-map-container"
  >
    <div class="ip-col-map-items">
      <span class="title">Column mapping</span>
      <span style="display: flex; flex-direction: column; gap: 4px;">
        <hlx-input
          v-model:value="payload.labelName"
          :pre-val="
            payload.labelName === 'Input label' ? '' : payload.labelName
          "
          :label-value="'Label name'"
          :label-animation="true"
          :read-only="readOnly"
          :display-error="true"
          :custom-error="showError"
          :custom-error-message="showError ? 'Column name already exists' : ''"
        ></hlx-input>
        <hlx-select
          v-model:value="payload.objectMapping.label" 
          :pre-value="payload.objectMapping.label"
          :placeholder-value="'Object mapping'"
          :options="dropDownData"
          :inline-search="true"
          :label-animation="true"
          :label="'var'"
          :prop-value="'var'"
          :disabled="readOnly"
          @selected-value="selectedObjectMappingOption"
        >
        </hlx-select>
        <br>
        <hlx-input
        v-if="payload.dataType.toLowerCase() === 'array'"
          v-model:value="payload.arrayValues"
          :pre-val="
            payload.arrayValues
          "
          type="textarea"
          :label-value="'Enter each item in a seperate line'"
          :label-animation="true"
          :read-only="readOnly"
          :display-error="true"
          :custom-error="showError"
          :custom-error-message="showError ? 'Column name already exists' : ''"
        ></hlx-input>
      </span>
      <span class="title-sm">Data source</span>
      <hlx-input
        type="radio-buttons"
        position="vertical"
        :options="dataSourceOptions"
        :disabled="readOnly"
        @selected="setSource"
      >
      </hlx-input>
      <hlx-code-editor
        v-if="payload.source === 'Computed'"
        v-model:value="payload.expression"
        :pre-value="payload.expression"
        :rows="3"
        :line-number="true"
        :read-only="readOnly"
      ></hlx-code-editor>
      <div class="footer-buttons">
        <hlx-button
          v-if="!readOnly"
          class="primary sm"
          style="width: 100% !important"
          :disabled="showError"
          @click="saveColumnData"
          >Save</hlx-button
        >
        <hlx-button
          class="secondary sm"
          style="width: 100% !important"
          @click="cancelColumnData"
          >Cancel</hlx-button
        >
        <button
          v-if="index !== 0 && !readOnly"
          class="text-error-sm"
          @click="deleteColumnData"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import HlxButton from "../ButtonComponent.vue";
// import HlxInput from "../InputComponent.vue";
// import HlxSelect from "../SelectComponent.vue";

export default {
  components: {
    // HlxButton,
    // HlxInput,
    // HlxSelect,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: null,
    },
    showInputColMap: {
      type: Boolean,
      default: false,
    },
    prePopulate: {
      type: Object,
      default: () => {},
    },
    dropDownData: {
      type: Array,
      default: () => [],
    },
    inputColumnNames: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['close', 'delete', 'save', 'cancel'],
  data() {
    return {
      payload: {
        id: '',
        objectMapping: {},
        dataType: '',
        type: '',
        operators: {},
        labelName: '',
        source: '',
        mode: '',
        from: '',
        to: '',
        arrayValues: ''
      },
      dropDownOptions: [],
      dataSourceOptions: [
        {
          name: 'Manual',
          value: 'Manual',
          checked: true,
          disabled: false,
          id: 1,
        },
        {
          name: 'Reference data',
          value: 'Reference data',
          checked: false,
          disabled: false,
          id: 2,
        },
        {
          name: 'Computed',
          value: 'Computed',
          checked: false,
          disabled: false,
          id: 3,
        },
      ],
      defaultLabel: '',
      showError: false,
    };
  },
  watch: {
    dropDownData: {
      handler(val) {
        if (val.schema) {
          // this.dropDownOptions.schema = val.schema;
        }
      },
      immediate: true,
      deep: true,
    },
    payload: {
      handler(val) {
        this.payload.to = val.labelName;
        this.showError =
          this.inputColumnNames.includes(this.payload.labelName) &&
          this.prePopulate.labelName !== this.payload.labelName;
      },
      immediate: true,
      deep: true,
    },
    prePopulate: {
      handler(val) {
        console.log(val, 'pre value from watch - input modal');
        if (Object.keys(val).length !== 0) {
          this.payload.id = JSON.parse(JSON.stringify(val.id));
          this.payload.labelName = JSON.parse(JSON.stringify(val.labelName));
          this.defaultLabel = JSON.parse(JSON.stringify(val.labelName));
          this.payload.source = JSON.parse(JSON.stringify(val.source));
          this.payload.type = JSON.parse(JSON.stringify(val.type));
          this.payload.dataType = JSON.parse(JSON.stringify(val.dataType));
          this.payload.objectMapping = JSON.parse(
            JSON.stringify(val.objectMapping)
          );
          if (val.operators) {
            this.payload.operators = JSON.parse(JSON.stringify(val.operators));
          }
          this.payload.mode = JSON.parse(JSON.stringify(val.mode));
          // this.payload.from = JSON.parse(JSON.stringify(val.var));
          this.payload.to = JSON.parse(JSON.stringify(val.to));
          this.dataSourceOptions.filter((item) => {
            item.value === JSON.parse(JSON.stringify(val.source))
              ? (item.checked = true)
              : (item.checked = false);
          });
        }
      },
    },
  },
  mounted() {
    // console.log("ip col map modal mounted");
  },
  methods: {
    setSource(val) {
      this.payload.source = val.value;
      this.payload.mode = val.value;
      // console.log(this.payload);
    },
    selectedObjectMappingOption(val) {
      const option = val;
      this.payload.objectMapping.label = option.var;
      this.payload.var = option.var;
      this.payload.objectMapping.type = option.type;
      this.payload.dataType = option.type;
      const parts = val.var.split('.');
      if (
        this.payload.labelName === '' ||
        this.payload.labelName === this.defaultLabel
      ) {
        this.payload.labelName =
          parts.length > 1 ? parts[parts.length - 1] : val.label;
      }
      this.payload.from = option.label;
      if (
        this.payload.labelName === '' ||
        this.payload.labelName === this.defaultLabel
      ) {
        this.payload.to =
          parts.length > 1 ? parts[parts.length - 1] : val.label;
      }
    },
    deleteColumnData() {
      this.$emit('delete', this.payload);
      this.$emit('close', true);
    },
    cancelColumnData() {
      this.payload.id = JSON.parse(JSON.stringify(this.prePopulate.id));
      this.payload.labelName = JSON.parse(
        JSON.stringify(this.prePopulate.labelName)
      );
      this.payload.source = JSON.parse(JSON.stringify(this.prePopulate.source));
      this.payload.type = JSON.parse(JSON.stringify(this.prePopulate.type));
      this.payload.dataType = JSON.parse(
        JSON.stringify(this.prePopulate.dataType)
      );
      this.payload.objectMapping = JSON.parse(
        JSON.stringify(this.prePopulate.objectMapping)
      );
      if (this.prePopulate.operators) {
        this.payload.operators = JSON.parse(
          JSON.stringify(this.prePopulate.operators)
        );
      }
      this.payload.mode = JSON.parse(JSON.stringify(this.prePopulate.mode));
      // this.payload.from = JSON.parse(JSON.stringify(this.prePopulate.from));
      this.payload.to = JSON.parse(JSON.stringify(this.prePopulate.to));
      this.$emit('cancel', 'cancelled');
      this.$emit('close', true);
    },
    saveColumnData() {
      console.log('save');
      this.$emit('save', this.payload);
      this.$emit('close', true);
    },
  },
};
</script>

<style lang="scss">
.ip-col-map-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: rgba(24, 24, 27, 0.2);
  align-items: center;
  justify-content: center;
  z-index: 2000;
  .ip-col-map-items {
    width: 310px;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: max-content;
    .title {
      font-weight: bold;
    }
    .title-sm {
      font-size: 14px;
      font-weight: bold;
    }
    .footer-buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: space-between;
      .text-error-sm {
        width: 100% !important;
        color: #ff5050;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      .save-cancel {
        display: flex;
        gap: 10px;
      }
    }
  }
}
</style>