<template>
  <section 
  class="bundle-listing-component-cover"
    :style="{
      maxWidth: bundleListingComponentWidth,
      height: bundleListingComponentHeight,
      minWidth: '300px',
      width: '300px'
    }"
  >
    <h2 style="font-size: 16px; margin-left: 4px; margin-bottom: 12px">
      Bundle Items
    </h2>
    <section>
      <hlx-multi-select
        :key="updatebundlemultiselect"
        :options="bundleList"
        :inline-search="true"
        :placeholder="'Choose bundle items'"
        :prop-value="''"
        :label-animation="true"
        :clearable="true"
        @selected-array="selectedBundleOffers"
      ></hlx-multi-select>
    </section>
    <section>
      <section
        v-for="(i, index) in offersList"
        :key="index"
        style="
          background: #d9d9d92e;
          position: relative;
          display: flex;
          align-items: center;
          padding: 12px;
          margin-top: 12px;
          margin-bottom: 12px;
        "
      >
        <section>
          <hlx-file-upload
            :key="componentkey"
            :file-type="'.jpg,.jpeg,.png'"
            :file-size-limit="'20mb'"
            :uploadtype="'square'"
            :style-background="backgrounStyleuploadSupplier"
            :height="56"
            :width="36"
            :read-only="true"
            :custom-icon="icon"
            :type="'profile-upload'"
            :profile-icon="'icon-upload-regular'"
            :content="'Import'"
            :name-display="i?.name ? i.name.split(' ')[0] : 'P'"
            :profile-pic-preview="i?.src"
          />
        </section>
        <section style="display: flex; padding: 12px; flex-direction: column;width: calc(100% - 30%);">
          <span
            v-if="i?.name"
            style="
              font-family: OpenSans;
              font-size: 14px;
              padding-bottom: 12px;
              font-weight: 600;
              line-height: 19.07px;
              text-align: left;
              margin-left: 4px;
            "
          >
            {{ i.name }}
          </span>
          <!-- style="width: 152px" -->
          <section v-if="SkuValues[index].length>0" > 
            <hlx-select
              :key="componentkey"
              :pre-value="SkuValues[index][0]?.name"
              :single-border="true"
              :inline-search="true"
              :options="SkuValues[index]"
              :label="'name'"
              :prop-value="'name'"
              :placeholder-value="'Choose SKU'"
              :required="false"
              :label-animation="false"
              :clearable="true"
              @custom-change="handleFieldsType"
              @selected-value="getSelectedSku(index, $event)"
            ></hlx-select>
          </section>
        </section>

        <section style="margin-left: auto;width: 30%;display:flex;justify-content: flex-end; ">
          <span style="font-size: 10px">
            <b>{{
              currencyFormatter(
                price[index] && !isNaN(price[index]) && price[index] != 0
                  ? price[index]
                  : 0,
                { currency: unit }
              )
            }}</b
            >{{ " / " }}<span style="font-size: 8px">{{ unit }}</span>
          </span>
        </section>
        <span
          style="position: absolute; color: #aaaaaa; top: 4px; right: 8px"
          @click="removedOffer(i._id, index,i)"
        >
          <i class="icon-times-regular"></i>
        </span>
      </section>
    </section>
  </section>
</template>

<script>
import { UPCAxiosInstance } from "@/config/axiosConfig";
export default {
  props: {
    listBundleOffers: {
      type: Array,
      default: () => {
        return [];
      },
    },
    getPriceType:{
      type:String,
      default:"Onetime"
    },
    componentWidth: {
      type: String,
      default: "400px",
    },
    componentHeight: {
      type: String,
      default: "100vh",
    },
    selectBundleItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ["selected-sku", "removed-offer", "selected-bundle-offers"],
  data() {
    return {
      updatebundlemultiselect:0,
      updatePrice:0,
      componentkey: 0,
      bundleList: [],
      offersList: [],
      SkuValues: [],
      specVal: [],
      price: [],
      unit: "",
      offersListWithImageSrc: [],
      bundleListingComponentWidth: "",
      bundleListingComponentHeight: "",
    };
  },
  watch: {
    listBundleOffers: {
      handler(val) {
        this.offersList = val;
        // let i=0;
        if (val) {
          console.log("listBundleOfferrs ", val);
          //  this.price = val[0]?.productOfferingPrice[0]?.tiers[0]?.price?.value;
          this.unit =
              val[0]?.productOfferingPrice?.[0]?.productPrice?.unit ||
            val[0]?.productOfferingPrice?.[0]?.tiers?.[0]?.price?.unit || this.$store?.getters?.getDefaultSettings?.currency.unit;

          this.price = val?.map((e) => {
            const productOfferingPrice = Array.isArray(e?.productOfferingPrice)
              ? e.productOfferingPrice[0]
              : e.productOfferingPrice;

            if (productOfferingPrice?.productPrice) {
              return productOfferingPrice.productPrice.dutyFreeAmount;
            } else if (productOfferingPrice?.tiers) {
              return productOfferingPrice.tiers[0]?.price?.value;
            } else {
              return 0;
            }
          });

          console.log("this.price", this.price, "this.price2");
          //this.SkuValues get offerpice,skuname from productOfferingPrice
          // this.SkuValues = val?.map((e) =>
          //   e?.productOfferingPrice.map((ele) => {
          //     console.log(ele, "SkuValues");
          //     return {
          //       _id: e._id,
          //       name: ele?.SKU || "-",
          //       offerPrice: ele?.tiers ? ele.tiers[0]?.price?.value : "-",
          //       offerunit: ele?.tiers ? ele.tiers[0]?.price?.unit : "-",
          //     };
          //   })
          // );

          //this.specVal get specPrice,specUnit from prodSpecCharValue
          this.SkuValues = val?.map((e) =>
            e?.prodSpecCharValue.filter(ele => ele?.lifecycleStatus?.toLowerCase() === "active")
            .map((ele) => {
              console.log("prodSpecCharValue bundleList",ele)
              const tierPrice = ele?.tiers?.[0]?.price;
              return {
                _id: e._id,
                name: ele.SKU || "-",
                specPrice:
                  !tierPrice || isNaN(tierPrice.value)
                    ? 0
                    : Number(tierPrice.value),
                specUnit: tierPrice?.unit,
              };
            }).filter((val) => val.name!="-")
          )
          console.log(this.specVal, "this.specVal", this.SkuValues);

          // Combining this.SkuValues and this.specVal to get both offerprice and specprice in one array of objects
        //   this.SkuValues = this.SkuValues.map((innerArray, index) => {
        //     const existingName = new Set(innerArray.map((obj) => obj.name));

        //     this.SkuValues = innerArray.map((obj, i) => {
        //       return {
        //         ...obj,
        //         specPrice: this.specVal?.[index]?.[i]?.specPrice ?? 0,
        //         specUnit: this.specVal?.[index]?.[i]?.specUnit,
        //       };
        //     });

        //     this.specVal[index].forEach((specObj) => {
        //       if (!existingName.has(specObj?.name)) {
        //         this.SkuValues.push({
        //           _id: specObj?._id,
        //           name: specObj?.name || "-",
        //           offerPrice: specObj?.specPrice,
        //           offerunit: specObj?.specUnit,
        //           specPrice: specObj?.specPrice,
        //           specUnit: specObj?.specUnit,
        //         });
        //       }
        //     });

        //     return this.SkuValues;
        //   });
        }
        console.log(
          this.SkuValues.flat(Infinity),
          "list",
          val,
          "listBundleOfferrs",
          this.SkuValues
        );
        this.getImagedata(this.offersList)
      },
      immediate: true,
      deep: true,
    },
    getPriceType:{
      handler(val){
        this.filterOffers(val)
      }
    },
    componentWidth: {
      handler(val) {
        this.bundleListingComponentWidth = val;
      },
      immediate: true,
      deep: true,
    },
    componentHeight: {
      handler(val) {
        this.bundleListingComponentHeight = val;
      },
      immediate: true,
      deep: true,
    },
    selectBundleItems: {
      handler(val) {
        this.bundleList = val;
        console.log("selectBundleItems",this.bundleList)
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    this.filterOffers(this.getPriceType)
    // console.log("bundle list image ", this.offersListWithImageSrc);
  },
  methods: {
   async filterOffers(val){
      if (val == "Onetime") {
        let offeringfilterquery = {
          type: "filter",
          module: "productOffering",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 50,
            currentPage: 1,
          },
          isNeedToBeSort: false,
          sortFieldAndOrder: {
            // "name":-1
          },
          limit: 10,
          filterQuery: {
            "productSpecification.@baseType": "Tangible",
              lifecycleStatus:"Active",
        offerType:"Atomic"
          },
          searchQuery: val,
        };
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          offeringfilterquery
        );
        this.bundleList = await filterResultData.data.data;
        this.bundleList = this.bundleList.map(ele => {
        ele.checked = this.offersList.some(obj => obj._id === ele._id);
        return ele;
        });
      } else {
        let offeringfilterquery = {
          type: "filter",
          module: "productOffering",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: false,
          sortFieldAndOrder: {
            // "name":-1
          },
          limit: 10,
          filterQuery: {
            "productSpecification.@baseType": "Intangible",
              lifecycleStatus:"Active",
        offerType:"Atomic"
          },
          searchQuery: val,
        };
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          offeringfilterquery
        );
        this.bundleList = await filterResultData.data.data;
        this.bundleList = this.bundleList.map(ele => {
        ele.checked = this.offersList.some(obj => obj._id === ele._id);
        return ele;
        });
      }
    },
    selectedBundleOffers(val) {
      // this.updatebundlemultiselect++;
      // To get selected bundle offers
      // this.offersList=this.offersList.concat(val)
      let filteredVal=val.filter(ele=>!this.offersList.some(obj=>obj._id===ele._id))
      console.log(this.offersList,"selectedBundleOffers", val,"filteredVal",filteredVal);
      this.$emit("selected-bundle-offers", filteredVal);
    },
    getSelectedSku(index, val) {
      // emit the offer payload on the selection of sku
      this.price[index] = val?.specPrice;
      console.log(
        (this.price[index] = val?.specPrice),
        index,
        "getSelectedSku",
        val
      );
      let emitSelected = this.offersList.filter((e) => e._id === val._id);
     let emitSkuSpecCharVal  =emitSelected[0]?.prodSpecCharValue.filter((e)=>e.SKU===val.name)
     emitSkuSpecCharVal = { ...emitSkuSpecCharVal[0], name: emitSelected[0]?.name ,displayID:emitSelected[0]?.displayID , _id:emitSelected[0]?._id , unitOfMeasure:emitSelected[0]?.unitOfMeasure};
      this.$emit("selected-sku",  emitSkuSpecCharVal);
      console.log(emitSkuSpecCharVal,"selected-sku", emitSelected);
    },
    removedOffer(id, index,i) {
      //  @clicking cancel button on the offer
      console.log(id, "removed-offer", index);
      // let emitSelected = this.offersList.filter((e) => (e._id != id);
      if(id && i){
        this.bundleList = this.bundleList.map(ele=>{
          if(ele._id === id){
            ele.checked=false
          }
          return ele
        })
      }
      this.$emit("removed-offer", i);
      console.log(i,"removed-offer");
    },
    getImagedata(values) {
  console.log(values, "bundle list image ");

  try {
    values?.forEach((val) => {
      if (val?.prodSpecCharValue?.length > 0 && val?.prodSpecCharValue[0]?.attachment?.length > 0) {
        const attachmentName = val.prodSpecCharValue[0].attachment[0].name;

        if (attachmentName) {
          console.log(attachmentName, "bundle list image ");
          this.supplierImageSource = "";
          UPCAxiosInstance.get(
            `/gcpupload/files/${attachmentName}`,
            {
              responseType: "arraybuffer",
            }
          )
            .then((response) => {
              const uint8Array = new Uint8Array(response.data);
              let binary = "";
              uint8Array.forEach((byte) => {
                binary += String.fromCharCode(byte);
              });
              const base64String = window.btoa(binary);
              let profilePicUrl = `data:image/png;base64,${base64String}`;
              val.src = profilePicUrl;
            })
            .catch((error) => {
              console.log("Error fetching image for:", attachmentName, error);
            });
        }
      }
      else if(val?.productOfferingPrice?.length > 0 && val?.productOfferingPrice[0]?.attachment?.length > 0){
        const attachmentName = val.productOfferingPrice[0].attachment[0].name;

        if (attachmentName) {
          console.log(attachmentName, "bundle list image ");
          this.supplierImageSource = "";
          UPCAxiosInstance.get(
            `/gcpupload/files/${attachmentName}`,
            {
              responseType: "arraybuffer",
            }
          )
            .then((response) => {
              const uint8Array = new Uint8Array(response.data);
              let binary = "";
              uint8Array.forEach((byte) => {
                binary += String.fromCharCode(byte);
              });
              const base64String = window.btoa(binary);
              let profilePicUrl = `data:image/png;base64,${base64String}`;
              val.src = profilePicUrl;
            })
            .catch((error) => {
              console.log("Error fetching image for:", attachmentName, error);
            });
        }
      }
      else {
        console.log("Invalid prodSpecCharValue or attachment for", val);
      }
    });
  } catch (err) {
    console.log("error from supplier image", err);
  }

  this.componentkey++;
  console.log(values, "bundle list image ", this.supplierTableData);
  return values;
}

  },
};
</script>

<style>
.icon-times-regular:hover {
  color: #54bd95;
  font-weight: bold;
}
</style>