<template>
  RENDERED COMPONENTS
  <br />
  <fieldRenderer :schema-payload="newSchema"></fieldRenderer>
</template>

<script>
import fieldRenderer from '@/components/FieldRenderer.vue';
import {UPCAxiosInstance} from '@/config/axiosConfig';
export default {
  components: {
    fieldRenderer,
  },
  data() {
    return {
      schema: {},
      newSchema: {},
    };
  },
  async mounted() {
    let schema =  await UPCAxiosInstance.get('customSchema/getSchema?module=saleslead');
    this.schema = schema.data.schema
    this.newSchema = Object.entries(this.schema)
      .filter(([key, value]) => (key, value.section.includes('overview')))
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
  },
  methods: {},
};
</script>
