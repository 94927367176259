export default {
        unifiedProductCatalog: {
            category: {
                title: {
                    value: 'Category',
                    changedValue: '',
                },
                subTitle: {
                        value: 'Aligning products, trends, and consumer expectations seamlessly',
                    changedValue: '',
                },
            },
            productCatalogue: {
                offer: {
                    title: {
                        value: 'Product catalogue',
                        changedValue: '',
                    },
                    subTitle: {
                        value: 'Centralized catalog for products offers.',
                        changedValue: '',
                    },
                },
                assistant: {},
                specification: {
                    title: {
                        value: 'Product catalogue',
                        changedValue: '',
                    },
                    subTitle: {
                        value: 'Centralized catalog for products and services.',
                        changedValue: '',
                    },
                },
                additionalCharges: {
                    title: {
                        value: 'Additional charges',
                        changedValue: '',
                    },
                    subTitle: {
                        value: 'Includes Any Applicable Fees and Taxes.',
                        changedValue: '',
                    },
                },
                discount: {
                    title: {
                        value: 'Discounts',
                        changedValue: '',
                    },
                    subTitle: {
                        value: 'Centralized table for discounts.',
                        changedValue: '',
                    },
                }
            },
            serviceCatalogue: {},
            resourceCatalogue: {},
        },
        customerManagement: {
            accounts: {
                title: {
                    value: 'Accounts',
                    changedValue: '',
                },
                subTitle: {
                    value: 'A unique identifier for each customer facilitating easy reference and organization.',
                    changedValue: '',
                },
            },
            contacts: {
                title: {
                    value: 'Contacts',
                    changedValue: '',
                },
                subTitle: {
                    value: 'Streamline your business relationships with straight forward contact management.',
                    changedValue: '',
                },
            },
            residentialCustomers: {
                title: {
                    value: 'Residential customers',
                    changedValue: '',
                },
                subTitle: {
                    value: 'Streamline your business relationships with straight forward contact management.',
                    changedValue: '',
                },
            },
        },
        supplierManagement: {
            title: {
                value: 'Supplier',
                changedValue: '',
            },
            subTitle: {
                value: 'Collaborative network, diverse suppliers, ensuring agility, scale, and competitive solutions.',
                changedValue: '',
            },
        },
        configurePriceQuote: {
            quotes: {
                title: {
                    value: 'CPQ',
                    changedValue: '',
                },
                subTitle: {
                    value: 'Configured pricing quotes',
                    changedValue: '',
                },
            },
            quotesAssistant: {},
        },
        orderManagement: {
            inventory: {
                title: {
                    value: 'Inventory',
                    changedValue: '',
                },
                subTitle: {
                    value: 'Efficiently track and manage your stock levels.',
                    changedValue: '',
                },
            },
            productOrders: {
                title: {
                    value: 'Product order',
                    changedValue: '',
                },
            },
            serviceOrders: {},
            resourceOrders: {},
        },
        billing: {
            title: {
                value: 'Billing',
                changedValue: '',
            },
            subTitle: {
                value: 'Easily track your invoices, payment history, and current billing status.',
                changedValue: '',
            },
        },
        ticket: {
            title: {
                value: 'Tickets',
                changedValue: '',
            },
            subTitle: {
                value: 'Centralized table for tickets.',
                changedValue: '',
            },
        },
        serviceQualification: {
            title: {
                value: 'Service qualification',
                changedValue: '',
            },
            subTitle: {
                value: 'Services are accessible at the location.',
                changedValue: '',
            },
        },
        decisionManager: {
            ruleStudio: {
                title: {
                    value: 'Rule studio',
                    changedValue: '',
                },
                subTitle: {
                    value: 'Effortlessly create, manage, and customize various types of business rules, including expressions, logical rules and decision tables.',
                    changedValue: '',
                },
            },
            ruleValidation: {
                title: {
                    value: 'Rule validation',
                    changedValue: '',
                },
                subTitle: {
                    value: 'Execute rules by supplying input values.',
                    changedValue: '',
                },
            },
            schema: {
                title: {
                    value: 'Schema',
                    changedValue: '',
                },
                subTitle: {
                    value: 'Create and manage all your schemas and variables.',
                    changedValue: '',
                },
            },
            dataManagement: {
                title: {
                    value: 'Data management',
                    changedValue: '',
                },
                subTitle: {
                    value: 'Create and manage reference data sets required for rule execution',
                    changedValue: '',
                },
            },
            auditLog: {
                title: {
                    value: 'Execution logs',
                    changedValue: '',
                },
                subTitle: {
                    value: 'Gain visibility into performance of individual rule executions for effective troubleshooting.',
                    changedValue: '',
                },
            }
        }
};