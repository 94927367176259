<template>
    <div class="app-layout">
      <section class="right-panel">
        <section class="layout-basic">
          <div class="layout-breadcrumb">
            <hlx-breadcrumb
              :items="breadcrumb_items"
              @path="pathTo"
            />
          </div>
          <div
            class="layout-header"
            style="padding-top: 0"
          >
            <span class="add-api-header">Edit API request</span>
          </div>
          <div
            class="layout-body"
            style="margin-top: 0"
          >
            <div
              class="grid"
              style="margin-top: 0"
            >
              <span class="titles">
                <!-- <div style="height: 38px; margin-bottom: 22px">Schema</div> -->
                <div style="height: 38px; margin-bottom: 22px">API name</div>
                <div>Description</div>
              </span>
              <span class="api-data-schema">
                <!-- <hlx-select
                  v-model:value="selectvalue"
                  :options="schemaOptions"
                  :placeholder-value="'Schema'"
                  :label-animation="true"
                  :clearable="true"
                  @custom-change="selectValue"
                ></hlx-select
                ><br /> -->
                <hlx-input
                  v-model:value="add_payload.name"
                  :pre-val="add_payload.name"
                  :label-animation="true"
                  :label-value="'Name'"
                ></hlx-input
                ><br />
                <hlx-input
                  v-model:value="add_payload.description"
                  :pre-val="add_payload.description"
                  :label-animation="true"
                  type="textarea"
                  :clearable="true"
                  :label-value="'Description'"
                />
              </span>
            </div>
            <div class="divider"><hr /></div>
            <div class="grid">
              <span class="titles">Method & URL </span>
              
              <span class="api-data-content">
                <div class="method-url">
                  <span class="method">
                    <hlx-select
                      v-model:value="add_payload.method"
                      :pre-value="add_payload.method"
                      :options="method_options"
                      :placeholder-value="'Method'"


        :label-animation="true"

        :label="'name'"

        :prop-value="'value'"
                    ></hlx-select>
                  </span>
                  <span class="url">
                    <hlx-input
                      v-model:value="add_payload.url"
                      :pre-val="add_payload.url"
                      type="text"
                      :label-value="'Request URL'"
                    ></hlx-input>
                  </span>
                  <!-- <span class="schedule">
                    <div class="choose-schedule">
                      <i class="icon-clock"></i>{{ add_payload.schedule }}
                    </div>
                  </span> -->
                </div>
              </span>
            </div>
            <div
              class="grid"
              style="margin-bottom: 6px"
            >
              <span class="titles"
                >URL parameters <span class="info"></span
              ></span>
              <span class="api-data-content">
                <div class="params">
                  <div
                    v-for="(param, index) in add_payload.params"
                    :key="index"
                    class="param-object"
                  >
                    <span class="key">
                      <hlx-input
                        :label-value="'Key'"
                        :pre-val="add_payload.params[index].key"
                        @at-input="paramKey($event, index, 'key')"
                      ></hlx-input>
                    </span>
  
                    <span class="value"
                      ><hlx-input
                        :label-value="'Value'"
                        :pre-val="add_payload.params[index].value"
                        @at-input="paramKey($event, index, 'value')"
                      ></hlx-input
                    ></span>
                    <span
                      ><i
                        class="icon-plus-circle-filled"
                        @click="addParamRow"
                      ></i
                    ></span>
                    <span class="delete-param"
                      ><i
                        v-if="add_payload.params.length > 1"
                        class="icon-trash-filled"
                        @click="deleteParamRow(index)"
                      ></i
                    ></span>
                  </div>
                </div>
              </span>
            </div>
  
            <div
              class="grid"
              style="margin-bottom: 6px"
            >
              <span class="titles">Headers <span class="info"></span></span>
              <span class="api-data-content">
                <div class="headers">
                  <div
                    v-for="(header, index) in add_payload.headers"
                    :key="index"
                    class="header-object"
                  >
                    <span class="key">
                      <hlx-input
                        :label-value="'Key'"
                        :pre-val="add_payload.headers[index].key"
                        @at-input="headerKey($event, index, 'key')"
                      ></hlx-input>
                    </span>
  
                    <span class="value"
                      ><hlx-input
                        :label-value="'Value'"
                        :pre-val="add_payload.headers[index].value"
                        @at-input="headerKey($event, index, 'value')"
                      ></hlx-input
                    ></span>
                    <span
                      ><i
                        class="icon-plus-circle-filled"
                        @click="addHeaderRow"
                      ></i
                    ></span>
                    <span class="delete-header"
                      ><i
                        v-if="add_payload.headers.length > 1"
                        class="icon-trash-filled"
                        @click="deleteHeaderRow(index)"
                      ></i
                    ></span>
                  </div>
                </div>
              </span>
            </div>
  
            <div class="grid">
              <span class="titles">Authorization <span class="info"></span></span>
              <span class="api-data-content-auth">
                <div class="auth">
                  <div class="auth-object">
                    <hlx-switch
                    v-model="add_payload.auth"
                    value-prop="name"
                    display-prop="name"
                      :type="'regular'"
                      :switch-items="auth_switch_items"
                  @change="authSelected"
                    />
                  </div>
                </div>
                <div
                  v-if="add_payload.auth === 'Basic auth'"
                  class="auth-cred"
                >
                  <div class="auth-cred-items">
                    <span class="key"
                      ><hlx-input
                        v-model:value="add_payload.credential.username"
                        :pre-val="add_payload.credential.username"
                        :label-value="'Username'"
                      ></hlx-input
                    ></span>
                    <span class="value"
                      ><hlx-input
                        v-model:value="add_payload.credential.password"
                        :pre-val="add_payload.credential.password"
                        :label-value="'Password'"
                      ></hlx-input
                    ></span>
                  </div>
                </div>
                <div
                  v-if="add_payload.auth === 'Bearer token'"
                  class="auth-cred"
                >
                  <div class="auth-cred-items">
                    <span class="token"
                      ><hlx-input
                        v-model:value="add_payload.credential.token"
                        :pre-val="add_payload.credential.token"
                        :label-value="'Token'"
                      ></hlx-input
                    ></span>
                  </div>
                </div>
                <div
                  v-if="add_payload.auth === 'API key'"
                  class="auth-cred"
                >
                  <div class="auth-cred-items">
                    <span class="api-key"
                      ><hlx-input
                        v-model:value="add_payload.credential.apikey"
                        :pre-val="add_payload.credential.apikey"
                        :label-value="'API key'"
                      ></hlx-input
                    ></span>
                  </div>
                </div>
              </span>
            </div>
  
            <div class="divider"><hr /></div>
            <div class="grid">
              <span class="titles"
                >Request body
                <span class="info">Define data in JSON format</span></span
              >
              <span class="api-data-content-text">
                <hlx-code-editor
                v-model:value="add_payload.requestBody"
                  :line-number="true"
                  :beautify="true"
                  :pre-value="add_payload.requestBody"
                  @code-changes="bodyPayload"
                ></hlx-code-editor>
                <div class="send-api">
                  <hlx-button
                    class="primary sm"
                    @click="testAPI()"
                    >Send</hlx-button
                  >
                </div>
              </span>
            </div>
            <div
              v-if="response === true"
              class="divider"
            >
              <hr />
            </div>
            <div
              v-if="response === true"
              class="grid"
            >
              <span class="titles">Response <span class="info"></span></span>
              <span class="api-data-content-text">
                <div
                  class="status"
                  style="font-size: 14px"
                >
                  <span class="title">Status: </span>
                  <span class="value">{{ response_status }}</span>
                  <span class="title">Time: </span>
                  <span class="value">{{ response_time }}</span>
                </div>
                <div class="response-result"><pre><code>{{ apiResponse }}</code></pre></div>
              </span>
            </div>
          </div>
          <div
            class="layout-footer"
            style="
              border-top: 1px solid #d8d8d8;
              padding-right: 40px;
              margin-right: -16px;
              margin-left: -16px;
            "
          >
            <div
              class="go-back"
              style="
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
              "
            >
              <hlx-button
                class="secondary sm"
                @click="cancelApiAdd"
                >Cancel</hlx-button
              >
              <hlx-button
                class="primary sm"
                style="margin-left: 20px"
                @click="saveAPIData"
                >Save</hlx-button
              >
            </div>
          </div>
        </section>
      </section>
    </div>
  </template>
  
  <script>
  import { DMNAxiosInstance } from '@/config/axiosConfig';
  import axios from "axios"
  export default {
    data() {
      return {
        apiResponse:"",
        nav_items: [
          {
            name: 'Home',
            label: 'Home',
            icon: 'icon-grid-web-7-regular',
            description_en: 'Home',
            description_fn: '*Home',
          },
          {
            name: 'Business rule',
            label: 'Business rule ',
            icon: 'icon-code-regular',
            description_en: 'Residential business market ',
            description_fr: '*Residential business market ',
          },
          {
            name: 'Schema',
            label: 'Schema',
            icon: 'icon-desktop-code-regular',
            description_en: 'Small business markets',
            description_fr: '*Small business markets',
          },
          {
            name: 'Database',
            label: 'Database',
            icon: 'icon-database-regular',
            description_en: 'Small business markets',
            description_fr: '*Small business markets',
          },
          {
            name: 'Execute',
            label: 'Execute',
            icon: 'icon-file-settings-regular',
            description_en: 'Small business markets',
            description_fr: '*Small business markets',
            active: true,
          },
          {
            name: 'Audit',
            label: 'Audit',
            icon: 'icon-notebook-regular',
            description_en: 'Audit log',
            description_fr: '*Audit log',
          },
  
          {
            name: 'Help',
            label: 'Help',
            icon: 'icon-question-circle-regular',
            description_en: 'Setting',
            description_fr: '*Setting',
            separator: true,
          },
          {
            name: 'settings',
            label: 'settings',
            icon: 'icon-settings-regular',
            description_en: 'Setting',
            description_fr: '*Setting',
          },
          {
            name: 'logout',
            label: 'Logout',
            icon: 'icon-log-out-regular',
            description_en: 'Logout',
            description_fr: '*Logout',
          },
        ],
        response: false,
        breadcrumb_items: [
          { label: 'API', link: '/API' },
          { label: 'Edit API', link: '' },
        ],
        apiBody: '',
        response_status: '200 OK',
        response_time: '91ms',
        add_payload: {
          name: '',
          description: '',
          method: '',
          url: '',
          requestBody: {},
          schedule: '',
          params: [{ key: '', value: '' }],
          headers: [{ key: '', value: '' }],
          auth: 'No auth',
          credential: {},
        },
        auth_switch_items: [
          { name: 'No auth'},
          { name: 'Basic auth' },
          { name: 'Bearer token' },
          { name: 'API key' },
        ],
        method_options: [
          {
            name: 'GET',
            value: 'get',
            checked: false,
          },
          {
            name: 'POST',
            value: 'post',
            checked: false
          },
          {
            name: 'PATCH',
            value: 'patch',
            checked: false
          },
          {
            name: 'DELETE',
            value: 'delete',
            checked: false
          },
        ],
        schemaOptions: [
          { name: 'quote', checked: false, disabled: false, value: 'quote' },
          { name: 'EIOP', checked: false, disabled: false, value: 'EIOP' },
        ],
      };
    },
    watch: {
      add_payload: {
       
      },
    },
    async mounted() {
      this.$store.commit('token', this.$route.params.token);
      // const textArea = document.querySelector(".prism-editor__textarea");
      // textArea.focus();
      // const handleClick = (event) => {
      //   // Check if clicked element has a class that starts with or includes "prism"
      //   const clickedElement = event.target;
      //   const elementClasses = clickedElement.classList;
      //   const hasPrismClass = Array.from(elementClasses).some(
      //     (className) =>
      //       className.startsWith("prism") || className.includes("prism")
      //   );
  
      //   // If the clicked element has a class that matches, focus on the text area
      //   if (hasPrismClass) {
      //     const textArea = document.querySelector(".prism-editor__textarea");
      //     textArea.focus();
      //   }
      // };
  
      // // Add click listener to the document
      // document.addEventListener("click", handleClick);
      await DMNAxiosInstance.get(
      `api/${this.$route.params.id}`
    ).then((res) => {
      
        this.add_payload.name = res.data.data.name
        this.add_payload.description = res.data.data.description
        this.add_payload.method = res.data.data.apiConfig.method
        this.add_payload.url =  res.data.data.apiConfig.url
        this.add_payload.headers =  res.data.data.apiConfig.headers
        this.add_payload.params =  res.data.data.apiConfig.params
    })
    },
    methods: {
      navTo(active) {
        this.$store.commit('token', this.$route.params.token);
        
        if (active.name == 'Home') {
          this.navHome();
        } else if (active.name == 'logout') {
          this.logout();
        } else if (active.name == 'settings') {
          this.setting();
        } else if (active.name == 'Schema') {
          this.schema();
        } else if (active.name == 'Business rule') {
          this.ruled();
        } else if (active.name === 'Database') {
          this.execute();
        } else if (active.name == 'Help') {
          this.help();
        } else if (active.name == 'Execute') {
          this.exe();
        } else {
          this.audit();
          // 
        }
      },
      audit() {
        this.$router.push(`/DMN/audit`);
      },
      ruled() {
        this.$router.push(`/DMN/business_rules`);
      },
      exe() {
        this.$router.push(`/DMN/data_execution`);
      },
      execute() {
        this.$router.push(`/DMN/reference_data`);
      },
      schema() {
        this.$router.push(`/DMN/schemas`);
      },
  
      // logout() {
      //   this.$router.push('/')
  
      // },
      setting() {
        this.$router.push(`/settings`);
      },
      async navHome() {
        this.$router.push(`/DMN/dashboard`);
      },
  
      scrollToEnd() {
        const scrollableDiv = document.querySelector('.layout-body');
        const lastChild = scrollableDiv.lastElementChild;
        lastChild.scrollIntoView({ behavior: 'smooth' });
      },
      pathTo() {
        
        this.$router.back();
      },
      paramKey(e, index, attr) {
        if (attr === 'key') {
          this.add_payload.params[index].key = e;
        }
        if (attr === 'value') {
          this.add_payload.params[index].value = e;
        }
      },
      addParamRow() {
        const obj = {};
        obj.key = '';
        obj.value = '';
        obj.index = '';
        this.add_payload.params.push(obj);
      },
      deleteParamRow(index) {
        this.add_payload.params.splice(index, 1);
      },
      headerKey(e, index, attr) {
        if (attr === 'key') {
          this.add_payload.headers[index].key = e;
        }
        if (attr === 'value') {
          this.add_payload.headers[index].value = e;
        }
      },
      addHeaderRow() {
        const obj = {};
        obj.key = '';
        obj.value = '';
        obj.index = '';
        this.add_payload.headers.push(obj);
      },
      deleteHeaderRow(index) {
        this.add_payload.headers.splice(index, 1);
      },
      authSelected(val) {
        this.add_payload.auth = val.name;
        // 
      },
      bodyPayload(val) {
        this.apiBody = val;
      },
      sendAPIData() {
        this.add_payload.requestBody = this.apiBody;
        
        this.response = true;
        setTimeout(() => {
          this.scrollToEnd();
        }, 100);
      },
      cancelApiAdd() {
        this.add_payload = {
          name: '',
          description: '',
          method: '',
          url: '',
          requestBody: {},
          schedule: '',
          params: [{ key: '', value: '' }],
          headers: [{ key: '', value: '' }],
          auth: '',
          credential: {},
        };
        this.$router.back();
      },
      async saveAPIData() {
        this.add_payload.requestBody = this.apiBody
        
        let obj = {name: this.add_payload.name, description: this.add_payload.description, apiConfig:{method: this.add_payload.method,
          url: this.add_payload.url,
          requestBody: this.add_payload.requestBody,
          schedule: this.add_payload.schedule,
          params: this.add_payload.params,
          headers: this.add_payload.headers,
          auth: this.add_payload.auth,
          credential: this.add_payload.credential},refreshConfig:{}}
        //   let arr = []
        //   arr.push(obj)
        await DMNAxiosInstance.patch(`/api/${this.$route.params.id}`, obj).then(
        (res) => {
          res
          // 
          setTimeout(() => {
      
              this.$router.push({
                name: 'list-api',
              });

            }, 3000);
        }
      );
        
      },

      async testAPI(){
        this.add_payload.requestBody = this.apiBody;
      
        // async function makeRequest(method, url, headers, params, authorization, requestBody) {
  try {
    let apiMethod = this.add_payload.method
    let apiUrl = this.add_payload.url
    let apiHeaders = {}
    if(this.add_payload.headers.length>0 && this.add_payload.headers[0].length>0){
      apiHeaders = this.add_payload.headers.reduce((result, item) => {
    result[item.key] = item.value;
    return result;
  }, {});
    }
    let apiParams = {}
    if(this.add_payload.params.length>0 && this.add_payload.params[0].length>0){
      apiParams = this.add_payload.params.reduce((result, item) => {
    result[item.key] = item.value;
    return result;
  }, {});
    }
  // let apiBody = this.add_payload.requestBody
  const startTime = new Date();
   await axios({
      method: apiMethod,
      url: apiUrl,
      headers: apiHeaders,
      params: apiParams,
      // data: apiBody,
      // ...(authorization && { headers: { Authorization: authorization } }),
    }).then((res)=>{
      const endTime = new Date();
    this.response_time = endTime - startTime;
      this.apiResponse = JSON.stringify(res.data,null,2)
      this.response = true;
      this.response_status = res.status
    })
        setTimeout(() => {
          this.scrollToEnd();
        }, 100);
  } catch (error) {
    console.error(error);
  }

      }
    },
  };
  </script>
  