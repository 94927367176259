<template>
  <hlx-alert-notification
    :show-alert="alertmsg"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>

  <div
    v-if="tableData.length > 0 || searchvalue !== ''"
    class="header-add-supplier"
  >
    <div class="tablehead-add-supplier">{{ tablehead }}</div>
    <div class="search-add-supplier">
      <span><hlx-search @search-key="productSearch"></hlx-search></span>
      <!-- <span
        ><hlx-button class="secondary sm"
          ><i class="icon-filter-regular"></i></hlx-button
      ></span> -->
      <span
        ><hlx-button
          v-show="supplierStatus == false"
          class="primary sm"
          @click="addproduct"
        >
          <i class="icon-plus-circle-regular"></i>
          Add product
        </hlx-button></span
      >
    </div>
  </div>
  <div>
    <div class="product_detail_supplier_modal">
      <hlx-drawer
        :show="modal_active_cascode"
        :width="400"
        position="right"
        :footer="true"
        :show-close-icon="true"
        @close="cascadCancel()"
      >
        <template #header>
          <div>Add product</div>
        </template>
        <template #body>
          <div class="header-area-contact-drawer">
            <b>Add product</b>
          </div>
          <div class="modal-content-product">
            <div>
              <div class="Cascader_product_supplier">
                <hlx-select
                  :key="selectcomponentkey"
                  :options="ProductSelectValue"
                  :label="'name'"
                  :placeholder-value="'Search product'"
                  :prop-value="'_id'"
                  :label-animation="true"
                  :inline-search="true"
                  @selected-value="setvalue"
                >
                </hlx-select>
                <div id="showTheProductDetail" class="modal-content-product">
                  <div class="product-detail-supplier">
                    <div class="value-data-supplier">
                      <span class="value-data-supplier_left">Name</span>
                      <span class="value-data-supplier_right">{{
                        productselect?.name
                      }}</span>
                    </div>

                    <div class="value-data-supplier">
                      <span class="value-data-supplier_left"
                        >Unit of measure</span
                      >
                      <span class="value-data-supplier_right">
                        <span v-if="productselect?.unitOfMeasure != undefined">
                          {{ productselect?.unitOfMeasure }}
                        </span>
                        <span v-else> {{ "-" }} </span>
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="
                      productselect?.productSpecification?.['@baseType'] !==
                      'Intangible'
                    "
                  >
                    <hlx-select
                      :key="locationkey"
                      v-model="locationpreview"
                      :options="locationSelectValue"
                      :label="'locationName'"
                      :placeholder-value="'Choose location'"
                      :prop-value="'locationName'"
                      :label-animation="true"
                      :inline-search="true"
                      @selected-value="locationsetvalue"
                      @custom-error-emit-value="checkval1"
                    >
                    </hlx-select>

                    <div
                      class="product-detail-supplier"
                      style="
                        border-radius: 0px;
                        background: rgba(217, 217, 217, 0.4);
                      "
                    >
                      <div>
                        <div
                          class="value-data-supplier"
                          style="padding: 2px 14px"
                        >
                          <span class="value-data-supplier_left">SKU</span>
                          <span class="value-data-supplier_left">MSRP</span>
                          <span class="value-data-supplier_right"
                            >Stock quantity</span
                          >
                        </div>
                      </div>
                    </div>
                    <div style="height: 362px; overflow: auto">
                      <div
                        v-for="(item, index) in filterdata"
                        :key="index"
                        style="padding: 2px 4px"
                      >
                        <span
                          v-if="item.SKU"
                          class="value-data-supplier"
                          style="
                            display: flex;
                            font-size: 14px;
                            align-items: center;
                            padding: 8px 14px;
                          "
                        >
                          <span class="value-data-supplier_left">{{
                            item.SKU
                          }}</span>
                          <span class="value-data-supplier_left">
                            {{
                              currencyFormatter(item?.MSRP?.value, {
                                currency:
                                  $store?.getters?.getDefaultSettings?.currency
                                    ?.unit,
                              })
                            }}
                          </span>

                          <span class="value-data-supplier_right">
                            <hlx-input
                              :key="index"
                              v-model:value="item.stock"
                              :pre-val="0"
                              type="number"
                              :label-animation="false"
                              :required="true"
                              :display-error="customnameerror"
                              :custom-error="customnameerror"
                              :custom-error-message="'Please fill the field'"
                            ></hlx-input>
                          </span>
                          <span
                            style="margin-left: 10px"
                            @click="deletesku(index)"
                            ><i class="icon-trash-regular"></i
                          ></span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div
            style="
              display: flex;
              justify-content: flex-end;
              margin-top: -8px;
              padding: 19px 0;
              border-top: 1px solid #d8d8d8;
            "
          >
            <hlx-button
              class="secondary-success sm"
              style="margin-right: 15px"
              @click="cascadCancel()"
              >Cancel</hlx-button
            >
            <hlx-button
              class="primary sm"
              :disabled="cascaderButton"
              @click="updateAllStocks"
              >Add</hlx-button
            >
          </div>
        </template>
      </hlx-drawer>
    </div>
    <div class="product_detail_supplier_modal">
      <hlx-drawer
        class="dreawerclass"
        :show="modal_edit_cascode"
        :width="400"
        position="right"
        :footer="true"
        :show-close-icon="true"
        @close="editcascadCancel()"
      >
        <template #header>
          <div>Edit product</div>
        </template>
        <template #body>
          <div class="header-area-contact-drawer">
            <b>Edit product</b>
          </div>
          <div class="modal-content-product">
            <div>
              <div class="Cascader_product_supplier">
                <div class="modal-content-product">
                  <div class="product-detail-supplier">
                    <div class="value-data-supplier">
                      <span class="value-data-supplier_left">Name</span>
                      <span class="value-data-supplier_right">{{
                        editPayload.name
                      }}</span>
                    </div>

                    <div
                      v-if="
                        editPayload?.stockedProduct?.['@baseType'] !==
                        'Intangible'
                      "
                      class="value-data-supplier"
                    >
                      <span class="value-data-supplier_left">SKU</span>
                      <span class="value-data-supplier_right">
                        <span
                          v-if="
                            editPayload?.stockedProduct?.SKU != undefined &&
                            editPayload?.stockedProduct?.SKU
                          "
                        >
                          {{ editPayload?.stockedProduct?.SKU }}
                        </span>

                        <span
                          v-if="
                            editPayload?.stockedProduct?.sku != undefined &&
                            editPayload?.stockedProduct?.sku
                          "
                        >
                          {{ editPayload?.stockedProduct?.sku }}
                        </span>
                      </span>
                    </div>
                    <div class="value-data-supplier">
                      <span class="value-data-supplier_left"
                        >Unit of measure</span
                      >
                      <span class="value-data-supplier_right"
                        ><span
                          v-if="
                            editPayload?.stockedProduct?.unitOfMeasure !=
                            undefined
                          "
                        >
                          {{ editPayload?.stockedProduct?.unitOfMeasure }}
                        </span>
                      </span>
                    </div>
                    <div
                      v-if="
                        editPayload?.stockedProduct?.['@baseType'] !==
                        'Intangible'
                      "
                      class="value-data-supplier"
                    >
                      <span class="value-data-supplier_left">MSRP</span>
                      <span class="value-data-supplier_right">
                        <span
                          v-if="
                            editPayload?.stockedProduct?.MSRP != undefined &&
                            editPayload?.stockedProduct?.MSRP
                          "
                        >
                          {{
                            currencyFormatter(
                              editPayload?.stockedProduct?.MSRP?.value,
                              {
                                currency:
                                  $store?.getters?.getDefaultSettings?.currency
                                    ?.unit,
                              }
                            )
                          }}
                        </span>

                        <span
                          v-if="
                            editPayload?.stockedProduct?.msrp != undefined &&
                            editPayload?.stockedProduct?.msrp
                          "
                        >
                          {{
                            currencyFormatter(
                              editPayload?.stockedProduct?.msrp?.value,
                              {
                                currency:
                                  $store?.getters?.getDefaultSettings?.currency
                                    ?.unit,
                              }
                            )
                          }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <span
                    v-if="
                      editPayload?.stockedProduct?.['@baseType'] !==
                      'Intangible'
                    "
                  >
                    <hlx-select
                      :key="locationkey"
                      :pre-value="editPayload?.place?.locationName"
                      :options="locationSelectValue"
                      :label="'locationName'"
                      :placeholder-value="'Choose location'"
                      :prop-value="'locationName'"
                      :label-animation="true"
                      :inline-search="true"
                      @selected-value="locationsetvalueedit"
                      @custom-error-emit-value="checkval1"
                    >
                    </hlx-select
                  ></span>
                  <div
                    v-if="
                      editPayload?.stockedProduct?.['@baseType'] !==
                      'Intangible'
                    "
                    class="Product_multiselect_unit"
                    style="margin-top: 20px"
                  >
                    <hlx-input
                      :pre-val="editPayload?.productStockLevel?.unit"
                      label-value="Stock quantity"
                      type="number"
                      :label-animation="true"
                      :required="true"
                      :display-error="customnameerror"
                      :custom-error="customnameerror"
                      :custom-error-message="'Please fill the field'"
                      @at-input="payloadvaledit"
                    ></hlx-input>
                  </div>
                  <div
                    class="Product_multiselect_unit"
                    style="margin-top: 20px"
                  >
                    <hlx-input
                      :key="productStockLevelkey"
                      :pre-val="editcost"
                      label-value="Cost"
                      type="number"
                      :label-animation="true"
                      :required="false"
                      :display-error="customnameerror"
                      :custom-error="customnameerror"
                      :custom-error-message="'Please fill the field'"
                      @at-input="payloadcost"
                    ></hlx-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div
            style="
              display: flex;
              justify-content: flex-end;
              padding: 34px 0 0 0px;
              border-top: 1px solid #d8d8d8;
            "
          >
            <hlx-button
              class="secondary-success sm"
              style="margin-right: 15px"
              @click="editcascadCancel()"
              >Cancel</hlx-button
            >
            <hlx-button
              class="primary sm"
              :disabled="editdisableButton"
              @click="editProductall()"
              >Save</hlx-button
            >
          </div>
        </template>
      </hlx-drawer>
    </div>

    <div
      v-if="tableData.length > 0"
      style="height: 78vh; overflow: auto"
      class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
    >
      <hlx-table
        :column-count="accounts_table_theads.length + 2"
        :border="['table', 'header', 'horizontal']"
        :row-hover="true"
        theme="grey"
        :re-arrange-columns="re_arrange"
        :re-arrange-heads="accounts_table_theads_cc"
        @close-rearrange="re_arrange = false"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
            @new-resize-width="resizedWidth"
            >{{ "S.no" }}</hlx-table-head
          >
          <hlx-table-head
            v-for="(i, index) in accounts_table_theads"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            :width="i.width !== undefined ? i.width : ''"
            :align="i.align !== undefined ? i.align : ''"
            @sorting_func="sorting_Data"
            @new-resize-width="resizedWidth"
          >
            <span v-if="['MSRP', 'Price'].includes(i?.prop)">
              {{ i.label }} ({{
                $store?.getters?.getDefaultSettings?.currency?.unit
              }})
            </span>
            <span v-else>
              {{ i.label }}
            </span></hlx-table-head
          >
          <hlx-table-head
            v-show="supplierStatus == false"
            :align="'center'"
            :width="70"
            :fixed="'right'"
            @new-resize-width="resizedWidth"
            ><span class="action-icon">
              <i
                class="icon-settings-regular"
                @click="reAssignOrgTableHeader()"
              ></i> </span
          ></hlx-table-head>
        </template>
        <template #tbody>
          <tr v-for="(i, index) in paginatedData" id="" :key="index">
            <hlx-table-cell :align="'center'" :fixed="'left'">
              {{ serialNumber(index) }}
            </hlx-table-cell>
            <hlx-table-cell
              v-for="(j, col_index) in accounts_table_theads"
              :key="col_index"
              :align="j.align"
              :fixed="j.fixed"
            >
              <div
                v-if="j.prop.toLowerCase() === '_id'"
                :id="j.prop + index"
                class="link-name"
                @click="viewPage(i)"
              >
                {{ i[j.prop] }}
              </div>
              <div
                v-else-if="j.prop.toLowerCase() === 'price'"
                style="display: flex; flex-direction: column; gap: 12px"
              >
                <!-- v-for="item in i.tiers" -->
                <p
                  v-for="item in i?.stockedProduct?.tiers"
                  :key="item"
                  class="bg-default border"
                  :data-tooltip="i['description']"
                  data-tooltip-location="top"
                >
                  {{
                    currencyFormatter(item?.price?.value, {
                      currency:
                        $store?.getters?.getDefaultSettings?.currency?.unit,
                    })
                  }}
                  <!-- {{ "£" + item.price.value }} -->
                </p>
              </div>
              <div v-else-if="j.prop.toLowerCase() === 'sku'">
                <span v-if="i?.stockedProduct?.SKU">
                  {{ i?.stockedProduct?.SKU }}
                </span>
                <span v-if="i?.stockedProduct?.sku">
                  {{ i?.stockedProduct?.sku }}
                </span>
                <span v-if="i.stockedProduct['@baseType'] === 'Intangible'">
                  {{ "NA" }}
                </span>
              </div>
              <div v-else-if="j.prop.toLowerCase() === 'msrp'">
                <span>
                  <span
                    v-if="
                      i?.stockedProduct?.MSRP &&
                      i.stockedProduct['@baseType'] !== 'Intangible'
                    "
                  >
                    {{
                      currencyFormatter(i?.stockedProduct?.MSRP?.value, {
                        currency:
                          $store?.getters?.getDefaultSettings?.currency?.unit,
                      })
                    }}
                  </span>
                  <span v-if="i?.stockedProduct?.msrp">
                    {{
                      currencyFormatter(i?.stockedProduct?.msrp?.value, {
                        currency:
                          $store?.getters?.getDefaultSettings?.currency?.unit,
                      })
                    }}
                  </span>
                  <span
                    v-if="
                      i?.stockedProduct?.tiers &&
                      i.stockedProduct['@baseType'] === 'Intangible'
                    "
                  >
                    {{ "NA" }}
                  </span>
                </span>
              </div>

              <div
                v-else-if="j.prop.toLowerCase() === 'name'"
                style="margin: 0.5rem 0"
              >
                {{ i[j.prop] }}
              </div>
              <div
                v-else-if="j.prop.toLowerCase() === 'place'"
                style="margin: 0.5rem 0"
              >
                <span v-if="i[j.prop]?.description">
                  {{ i[j.prop]?.description }}</span
                >
                <span v-else> NA</span>
              </div>
              <div
                v-else-if="j.prop.toLowerCase() === 'costunit'"
                style="margin: 0.5rem 0"
              >
                <div v-if="i?.productStockLevel?.cost > 0 && i?.costRule">
                  <span style="text-decoration: line-through">
                    {{
                      currencyFormatter(i.costRule, {
                        currency:
                          $store?.getters?.getDefaultSettings?.currency?.unit,
                      })
                    }}
                  </span>
                  <span style="margin-left: 8px">
                    {{
                      currencyFormatter(i.productStockLevel.cost, {
                        currency:
                          $store?.getters?.getDefaultSettings?.currency?.unit,
                      })
                    }}
                  </span>
                </div>
                <div v-else-if="i?.costRule > 0">
                  {{
                    currencyFormatter(i?.costRule, {
                      currency:
                        $store?.getters?.getDefaultSettings?.currency?.unit,
                    })
                  }}
                </div>
                <div v-else-if="i?.productStockLevel?.cost > 0">
                  {{
                    currencyFormatter(i?.productStockLevel?.cost, {
                      currency:
                        $store?.getters?.getDefaultSettings?.currency?.unit,
                    })
                  }}
                </div>
                <div
                  v-else-if="i?.costRule && i?.productStockLevel?.cost === 0"
                >
                  {{
                    currencyFormatter(i.costRule, {
                      currency:
                        $store?.getters?.getDefaultSettings?.currency?.unit,
                    })
                  }}
                </div>

                <div v-else>-</div>
              </div>

              <div
                v-else-if="j.prop.toLowerCase() === 'unitofmeasure'"
                style="margin: 0.5rem 0"
              >
                <div v-if="i?.stockedProduct?.unitOfMeasure">
                  {{ i?.stockedProduct?.unitOfMeasure }}
                </div>
                <div v-else>-</div>
              </div>
              <div
                v-else-if="j.prop.toLowerCase() === 'productstocklevel'"
                style="margin: 0.5rem 10px"
              >
                <div v-if="i.stockedProduct['@baseType'] !== 'Intangible'">
                  {{ i[j.prop]?.unit }}
                </div>
                <div v-else>NA</div>
              </div>
            </hlx-table-cell>
            <hlx-table-cell
              v-show="supplierStatus == false"
              :align="'center'"
              :width="120"
              :fixed="'right'"
            >
              <span
                :id="'table-context' + serialNumber(index - 1)"
                class="action-icon"
              >
                <i
                  style="position: relative"
                  class="icon-more-vertical-filled"
                  :class="i.context === true ? 'active-action' : ''"
                  @click="crudContextMenu($event, serialNumber(index - 1))"
                >
                  <hlx-context-menu
                    :top="containertop"
                    :left="containerleft"
                    :conditionvalue="150"
                    :conditiondown="2"
                    :conditionup="-38"
                    :options="computedTableAction(i)"
                    :data="index.toString()"
                    :show="i.context == true"
                    @chosen="
                      closeCrudContextMenu($event, i, serialNumber(index))
                    "
                  />
                </i>
              </span>
            </hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
    </div>
    <div
      v-if="tableData.length <= 0 && searchvalue === ''"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
        height: 70vh;
      "
    >
      <img
        class="temp-Product-illustration"
        src="@/assets/images/product.svg"
        alt="Product image"
        srcset=""
      />
      <span
        ><hlx-button
          v-show="supplierStatus == false"
          class="primary sm"
          @click="addproduct"
        >
          <i class="icon-plus-circle-regular"></i>
          Add product
        </hlx-button></span
      >
    </div>
    <div
      v-if="tableData.length === 0 && searchvalue !== ''"
      class="cm-accounts-right-panel-top-section-table-wrap"
    >
      <hlx-table
        :column-count="accounts_table_theads.length + 2"
        :border="['table', 'header']"
        :bold-headers="false"
        :row-hover="true"
        theme="grey"
        :striped-rows="false"
        :re-arrange-columns="re_arrange"
        :re-arrange-heads="accounts_table_theads_cc"
        @close-rearrange="re_arrange = false"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head
            :width="60"
            :align="'left'"
            @new-resize-width="resizedWidth"
            >{{ "S.no" }}</hlx-table-head
          >
          <hlx-table-head
            v-for="(i, index) in accounts_table_theads"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            style="position: sticky; top: 0px"
            :width="i.width !== undefined ? i.width : ''"
            :align="i.align !== undefined ? i.align : ''"
            @sorting_func="sorting_Data"
            @new-resize-width="resizedWidth"
          >
            {{ i.label }}
          </hlx-table-head>
          <hlx-table-head
            v-show="supplierStatus == false"
            :width="100"
            @new-resize-width="resizedWidth"
          >
            <span class="action-icon"
              ><i
                class="icon-settings-regular"
                @click="reAssignOrgTableHeader()"
              ></i></span
          ></hlx-table-head>
        </template>
        <template #tbody>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell :colspan="accounts_table_theads.length + 2">{{
              "No data"
            }}</hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
    </div>
  </div>
  <div v-if="tableData?.length > 10">
    <hlx-pagination
      v-model:rows-per-page="externalRowsPerPage"
      :total="tableData.length"
      rows-per-page
      enable-rows-per-page
      :rows-per-page-list="[10, 15, 20]"
      :options="{
        attributes: {
          rowsPerPageTitle: true,
          rowsPerPageResult: true,
          rowsPerPageDropdown: {
            position: {
              top: 0,
              right: 1,
            },
          },
        },
      }"
      @current-page="currentPage"
      @updated:rows-per-page="changeRowsPerPage"
    ></hlx-pagination>
  </div>
</template>
<script>
import {
  UPCAxiosInstance,
  MLTAxiosInstance,
  DMNAxiosInstance,
} from "@/config/axiosConfig";

export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },

    mode: {
      type: String,
      default: "edit",
    },
    supplierStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableHeaderOrg: [],
      tableHeaderOrgcc: [],
      editcost: 0,
      editdisableButton: false,
      editinpurvalue: 0,
      inputValues: [],
      modal_edit_cascode: false,
      payload: {},
      Specfilterquery: {
        type: "filter",
        module: "productOffering",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },

        filterQuery: {},
        searchQuery: "",
      },
      productdata: [],
      cascaderButton: true,
      totalPageNo: 0,
      SpecificationcurrPage: 1,
      SpecificationrowsPerPage: 10,
      Supplierfilterquery: {
        type: "search",
        module: "supplierManagement",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      },
      stock: 0,
      msrp: "",
      cost: "",
      code: {
        reginTime: [
          {
            region: this.regioncode,
            leadTime: "",
          },
        ],
      },
      editProduct: {},
      costvalue: 0,
      prevalue2: "",
      ProductSelectValue: [],
      delete_modal_active: false,
      producttype: "manufacture",
      Varientid: "VAR-2024-ABC123",
      priceName: "CAD",
      unitofmeasure: "SKU-A9876",
      Offeringname: "732158",
      Offeringid: "QuantumTech Pro Series Router",
      SKUvalue: "Each",
      editMode: true,
      username: "GlobalTech Supplies",
      tagName: "Active",
      addressName: "123 Main Street Suite 456 Toronto, ON M5V 2B8, Canada",
      tablehead: "Products",
      externalRowsPerPage: 10,
      taxCategoryList: [],
      tableData: [
        {
          id: "732158",
          variantname: "QuantumTech Pro 5000X",
          sku: "SKU-12345",
          name: "QuantumTech Pro Series Router",
          stockquantity: "23",
          regioncode: "5 Regions",
          unitofmeasure: "Each",
          msrp: " $4699.99",
          cad: "$800.00",
        },
        {
          id: "732158",
          variantname: "QuantumTech Pro 5000X",
          sku: "SKU-12345",
          name: "QuantumTech Pro Series Router",
          stockquantity: "23",
          regioncode: "5 Regions",
          unitofmeasure: "Each",
          msrp: " $4699.99",
          cad: "$800.00",
        },
        {
          id: "732158",
          variantname: "QuantumTech Pro 5000X",
          sku: "SKU-12345",
          name: "QuantumTech Pro Series Router",
          stockquantity: "23",
          regioncode: "5 Regions",
          unitofmeasure: "Each",
          msrp: " $4699.99",
          cad: "$800.00",
        },
      ],
      IntangibleinputValues: 0,
      locationdata: {},
      dropdown_data1: [],
      statusList: [
        { name: "Active", value: "Active" },
        { name: "Inactive", value: "Inactive" },
      ],
      Shiping: [
        { name: "Active", value: "Active" },
        { name: "Inactive", value: "Inactive" },
      ],

      editPayload: {
        name: "",
        channel: [],
        place: {},
        productStockLevel: {
          unit: 0,
        },

        marketSegment: [],
        stockedProduct: {},
      },
      accounts_table_theads: [
        {
          sortable: false,
          resizable: true,
          disabled: true,
          checked: true,
          width: 180,
          label: "Offer",
          prop: "name",
          align: "left",
          name: "Offer",
        },
        {
          sortable: false,
          resizable: true,
          disabled: true,
          checked: true,
          width: 100,
          label: "SKU",
          prop: "SKU",
          align: "left",
          name: "SKU",
        },
        {
          sortable: false,
          resizable: true,
          checked: true,
          disabled: false,
          width: 150,
          label: "Unit of measure",
          prop: "unitOfMeasure",
          align: "left",
          name: "Unit of measure",
        },
        {
          sortable: false,
          resizable: true,
          disabled: false,
          checked: true,
          width: 110,
          label: "Stock quantity",
          prop: "productStockLevel",
          align: "left",
          name: "Stock quantity",
        },
        {
          sortable: false,
          resizable: true,
          disabled: true,
          checked: true,
          width: 350,
          label: "Location",
          prop: "place",
          align: "left",
          name: "Location",
        },

        {
          sortable: false,
          resizable: true,
          disabled: true,
          width: 100,
          checked: true,
          label: `MSRP`,
          prop: "MSRP",
          align: "left",
          name: "MSRP",
        },

        {
          sortable: false,
          resizable: true,
          checked: true,
          disabled: true,
          width: 150,
          label: "Price",
          prop: "Price",
          align: "left",
          name: "Price",
        },
        {
          sortable: false,
          resizable: true,
          checked: true,
          disabled: true,
          width: 150,
          label: "Cost / Unit",
          prop: "costunit",
          align: "left",
          name: "Cost / Unit",
        },
      ],
      selectedvalueproduct: "",
      name: "",
      accounts_table_theads_cc: [
        {
          sortable: false,
          resizable: true,
          disabled: true,
          checked: true,
          width: 180,
          label: "Offer",
          prop: "name",
          align: "left",
          name: "Offer",
        },
        {
          sortable: false,
          resizable: true,
          disabled: true,
          checked: true,
          width: 100,
          label: "SKU",
          prop: "SKU",
          align: "left",
          name: "SKU",
        },
        {
          sortable: false,
          resizable: true,
          checked: true,
          disabled: false,
          width: 150,
          label: "Unit of measure",
          prop: "unitOfMeasure",
          align: "left",
          name: "Unit of measure",
        },
        {
          sortable: false,
          resizable: true,
          disabled: false,
          checked: true,
          width: 110,
          label: "Stock quantity",
          prop: "productStockLevel",
          align: "left",
          name: "Stock quantity",
        },
        {
          sortable: false,
          resizable: true,
          disabled: true,
          checked: true,
          width: 350,
          label: "Location",
          prop: "place",
          align: "left",
          name: "Location",
        },

        {
          sortable: false,
          resizable: true,
          disabled: true,
          width: 100,
          checked: true,
          label: `MSRP`,
          prop: "MSRP",
          align: "left",
          name: "MSRP",
        },

        {
          sortable: false,
          resizable: true,
          checked: true,
          disabled: true,
          width: 150,
          label: "Price",
          prop: "Price",
          align: "left",
          name: "Price",
        },
        {
          sortable: false,
          resizable: true,
          checked: true,
          disabled: true,
          width: 150,
          label: "Cost / Unit",
          prop: "costunit",
          align: "left",
          name: "Cost / Unit",
        },
      ],
      locationkey: 0,
      productStockLevelkey: 0,
      selectcomponentkey: 0,
      featureObj: [],
      searchvalue: "",
      prductfilter: [],
      editproductPayload: {},
      editPayloadId: "",
      payloadProduct: {},
      duplicateTheads: [],
      re_arrange: false,
      currPage: 1,
      rowsPerPage: 10,

      productselect: {},
      filterPanelName: "productSpecification",
      modal_active: false,
      modal_active_cascode: false,
      modal_active_edit: false,
      regioncode: "",
      deleteobj: {},
      locationSelectValue: [],
      supplierdata: {},
      categoryInfo: {},
      filterdata: [],
      productdatafull: [],
      userId: "",
      company: "",
      copyOfProductTableData: [],
      rawData: {},
      initialData: {},
      discountData: [],
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
  },

  watch: {
    editPayload: {
      handler(val) {
        console.log(val, val.length, "producttable");
      },
      immediate: true,
      deep: true,
    },
    productdata: {
      handler(val) {
        this.tableData = val;
        console.log(val, val.length, "producttable");
      },
      immediate: true,
      deep: true,
    },
    prevalue2: {
      handler(val) {
        this.selectedproductFunc(val);
      },
      immediate: true,
      deep: true,
    },
    editinpurvalue: {
      handler(val) {
        console.log(val, "editinpurvalue");
      },
      immediate: true,
      deep: true,
    },
    tableData: {
      handler(val) {
        this.copyOfProductTableData = JSON.parse(JSON.stringify(val));
      },
      immediate: true,
      deep: true,
    },
    payload: {
      handler(val) {
        console.log(val, "productselect.name");
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    // To get table header
    let sampleTheads = await MLTAxiosInstance.get(`/settings`);

    if (sampleTheads?.data?.entityRef?.["supplierProductTable"]) {
      let offerTableHeadModal =
        sampleTheads.data.entityRef["supplierProductTable"];
      let offerTableHeadOrg = sampleTheads.data.entityRef[
        "supplierProductTable"
      ].filter((ele) => ele.checked);
      console.log(
        offerTableHeadOrg,
        "offerTableHeadModal",
        offerTableHeadModal
      );

      if (
        offerTableHeadModal.length === this.accounts_table_theads_cc.length
        // &&
        // offerTableHeadModal.every((item, index) => item.name ===  this.accounts_table_theads_cc[index].name)
      ) {
        this.accounts_table_theads_cc = JSON.parse(
          JSON.stringify(offerTableHeadModal)
        );
        this.accounts_table_theads = JSON.parse(
          JSON.stringify(offerTableHeadOrg)
        );
        this.tableHeaderOrgcc = JSON.parse(
          JSON.stringify(this.accounts_table_theads_cc)
        );
        this.tableHeaderOrg = JSON.parse(
          JSON.stringify(this.accounts_table_theads)
        );
      }
    }
    const tableData = await UPCAxiosInstance.get(
      `/productStock?company=${this.$route.params.companyName}`
    );
    this.productdata = tableData.data;
    this.productdatafull = tableData.data;
    console.log("");
    const getDiscount = await UPCAxiosInstance.get(
      `/catalogManagement/productOffering/supplierView?company=${this.$route.params.companyName}`
    );
    this.discountData = getDiscount.data;
    await this.rulecall();

    console.log(
      this.discountData,
      getDiscount.data,
      "this.discountData = getDiscount.data"
    );
    const access = await MLTAxiosInstance.get(
      `partymanagement/organisation/${this.$route.params.id}?company=${this.$route.params.companyName}`
    );
    this.supplierdata = access.data;

    this.locationSelectValue = access.data.contactMedium
      .filter((item) => item.mediumType === "inventoryLocation")
      .map((item) => item.characteristic);

    console.log(this.supplierdata, this.locationSelectValue, "supplierdata");
    console.log(this.payload);

    const updateSpecWithChar = await UPCAxiosInstance.get(
      `/catalogManagement/productOffering`
    );

    this.ProductSelectValue = updateSpecWithChar?.data;
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    this.company = val.data.company;
    this.userId = val.data.userId;
    console.log(this.ProductSelectValue, "this.ProductSelectValue");
    console.log(updateSpecWithChar.data, "filterResultData");
  },
  methods: {
    resizedWidth(val) {
      // To get resized width
      console.log(val, "resizedWidth", val);

      const updateWidth = (array) => {
        let item = array.find((i) => i?.prop === val?.prop);
        if (item) item.width = val.width;
      };

      updateWidth(this.accounts_table_theads);
      updateWidth(this.accounts_table_theads_cc);
      console.log(
        this.accounts_table_theads,
        "resizedWidth loop",
        this.accounts_table_theads_cc
      );
      this.applyColumns(this.accounts_table_theads_cc);
    },
    reAssignOrgTableHeader() {
      // If there is change in the table header then ressign to the Orginal data
      if (
        this.accounts_table_theads_cc != this.tableHeaderOrgcc &&
        this.tableHeaderOrgcc.length > 0
      ) {
        this.accounts_table_theads_cc = JSON.parse(
          JSON.stringify(this.tableHeaderOrgcc)
        );
      }
      if (
        this.accounts_table_theads != this.tableHeaderOrg &&
        this.tableHeaderOrg.length > 0
      ) {
        this.accounts_table_theads = JSON.parse(
          JSON.stringify(this.tableHeaderOrg)
        );
      }
      this.re_arrange = true;
    },
    costvalues(val) {
      this.costvalue = val;
    },
    async rulecall() {
      try {
        // Fetch product data
        const tableData = await UPCAxiosInstance.get(
          `/productStock?company=${this.$route.params.companyName}`
        );
        this.productdata = tableData.data;
        this.productdatafull = tableData.data;

        console.log(this.productdata, "Fetched product data");
        console.log(this.discountData, "Discount data");

        const promises = [];

        for (const discountData of this.discountData) {
          console.log(discountData, "Processing discountData");

          for (const appliesTo of discountData.appliesTo) {
            console.log(appliesTo, "Processing appliesTo");

            for (const price of this.productdata) {
              console.log(price, "Processing price");

              if (appliesTo.name === price.name) {
                let msrpValue =
                  price.stockedProduct?.msrp?.value ||
                  price.stockedProduct?.MSRP?.value;

                const supplierPayload = {
                  supplier: {
                    product: [
                      {
                        productOfferingId: price.id,
                        msrp: msrpValue,
                        discount: {
                          skuDiscount: {
                            value: discountData.maximumDiscountRules[0].value,
                            startDate: discountData.validFor.startDate,
                            endDate: discountData.validFor.endDate,
                            active:
                              discountData.lifecycleState === "Approved"
                                ? 1
                                : 0,
                          },
                          categoryDiscount: 0,
                          brandDiscount: 0,
                        },
                      },
                    ],
                  },
                };

                console.log(supplierPayload, "supplierPayload");

                // Push the async API call to the promises array
                promises.push(
                  (async () => {
                    try {
                      const response = await DMNAxiosInstance.post(
                        `/rule_engine/rule/execute/668690e4d5f0c403af9e5b1d`,
                        { payload: supplierPayload }
                      );

                      // Update price costs with API response
                      if (price.productStockLevel?.cost > 0) {
                        price.cost = price.productStockLevel.cost;
                      } else {
                        price.cost =
                          response.data.data.supplier.product[0].cost;
                      }

                      price.costRule =
                        response.data.data.supplier.product[0].cost;

                      console.log(
                        price,
                        response.data.data.supplier.product[0].cost,
                        "supplierPayload cost updated"
                      );
                    } catch (error) {
                      console.error("Error executing rule:", error);
                    }
                  })()
                );
              }
            }
          }
        }

        // Wait for all promises to complete
        await Promise.all(promises);
      } catch (error) {
        console.error("Error during rule call process:", error);
      }
    },

    // async rulecall(priceval, nameval, priceid) {
    //   console.log("rulecallrulecall");
    //   for (const discount of this.discountData) {
    //     for (const appliesTo of discount.appliesTo) {
    //       for (const price of this.tableData) {
    //         console.log(
    //           discount,
    //           appliesTo,
    //           price,
    //           "appliesTo",
    //           priceval,
    //           nameval,
    //           priceid,
    //           "price"
    //         );

    //         if (appliesTo.name === price.name) {
    //           let msrpValue;

    //           if (price.stockedProduct?.msrp) {
    //             msrpValue = price.stockedProduct?.msrp.value;
    //           } else if (price.stockedProduct?.MSRP) {
    //             msrpValue = price.stockedProduct?.MSRP.value;
    //           }
    //           const suppliercul = {
    //             supplier: {
    //               product: [
    //                 {
    //                   productOfferingId: price.id,
    //                   price: msrpValue,
    //                   discount: {
    //                     skuDiscount: {
    //                       value: discount.maximumDiscountRules[0].value,
    //                       startDate: discount.validFor.startDate,
    //                       endDate: discount.validFor.endDate,
    //                     },
    //                     categoryDiscount: 0,
    //                     brandDiscount: 0,
    //                   },
    //                 },
    //               ],
    //             },
    //           };
    //           console.log(suppliercul, "suppliercul");
    //           if (discount.lifecycleState === "Approved") {
    //             suppliercul.supplier.product[0].discount.skuDiscount.active = 1;
    //           } else {
    //             suppliercul.supplier.product[0].discount.skuDiscount.active = 0;
    //           }
    //           try {
    //             const tableData = await DMNAxiosInstance.post(
    //               `/rule_engine/rule/execute/668690e4d5f0c403af9e5b1d`,
    //               { payload: suppliercul }
    //             );
    //             price.cost = tableData.data.data.supplier.product[0].cost;
    //             console.log(
    //               price,
    //               tableData.data.data.supplier.product[0].cost,
    //               "suppliercul"
    //             );
    //           } catch (error) {
    //             console.error("Error executing rule:", error);
    //           }
    //         }
    //       }
    //     }
    //   }
    // },
    async editProductall() {
      this.modal_edit_cascode = false;
      this.editdisableButton = true;
      console.log(this.editPayload, "editProductall");
      const addedProductSupplierLogPayload = {
        message: `${this.supplierdata.customName} product has been update`,
        detailedAction: [],
        action: "update",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id, this.editPayload.externalID],
        "@type": "supplier-supplier",
        category: ["productOffering", "supplier"],
      };
      console.log(this.initialData, this.editPayload);

      // Name log
      let nameActionLog = this.buildBasicLogByDifferences(
        this.initialData.productStockLevel,
        this.editPayload.productStockLevel,
        "cost",
        "cost"
      );
      console.log(nameActionLog, "nameActionLog");

      // Description log
      let descriptionActionLog = this.buildBasicLogByDifferences(
        this.initialData.productStockLevel,
        this.editPayload.productStockLevel,
        "unit",
        "unit"
      );
      console.log(descriptionActionLog, "descriptionActionLog");

      // Address differences
      let addressActionLog = this.buildBasicLogByDifferences(
        this.initialData.place,
        this.editPayload.place,
        "description",
        "Location"
      );
      console.log(addressActionLog, "addressActionLog");
      addedProductSupplierLogPayload.detailedAction =
        addedProductSupplierLogPayload.detailedAction.concat(
          addressActionLog,
          descriptionActionLog,
          nameActionLog
        );
      console.log(
        addedProductSupplierLogPayload,
        "addedProductSupplierLogPayload"
      );

      // this.initLog(addedProductSupplierLogPayload);
      this.editPayload.context = false;

      await UPCAxiosInstance.patch(
        `/productStock?company=${this.$route.params.companyName}`,
        this.editPayload
      );

      this.editProduct?.relatedParty.map(async (item, index) => {
        if (
          item?.name === this.supplierdata?.name &&
          item?.id === this.editPayload?.stockedProduct?.id &&
          item?.place?.description ===
            this.rawData?.relatedParty[index].place?.description
        ) {
          (item.supplierCost = this.editPayload.productStockLevel.cost),
            (item.productStockLevel = {
              unit: this.editPayload.productStockLevel.unit,
            });
          item.place = this.editPayload.place;
        }
      });

      await UPCAxiosInstance.patch(`/catalogManagement/productOffering`, {
        ...this.editProduct,
        id: this.editProduct._id,
      });
      this.notification = {
        type: "success",
        message: `Done! Your item has been updated.`,
      };
      this.productStockLevelkey += 1;
      this.initLog(addedProductSupplierLogPayload);

      await this.rulecall();
      this.editcascadCancel();
    },
    locationeditsetvalue(val) {
      this.editPayload.place = val;
    },
    locationsetvalue(val) {
      this.locationdata = val;
      console.log(this.locationdata, "locationdata", val);
    },
    locationsetvalueedit(val) {
      this.editPayload.place = val;
      console.log(this.locationdata, "locationdata", val);
    },
    async updateAllStocks() {
      this.newStockArray = [];
      this.offerStockArray = [];
      this.filterdata.map((item, index) => {
        console.log(item, "filterdatafilterdata");
        console.log(this.categoryInfo.type, "categoryInfo");

        const newStockObjectBase = {
          name: this.productselect.name,
          stockedProduct: {
            ...item,
            "@baseType": this.productselect.productSpecification["@baseType"],
            unitOfMeasure: this.productselect.unitOfMeasure,
          },
          place: this.locationdata,
          SKU: item.SKU,
          externalID: this.productselect.id,
          relatedParty: {
            role: "supplier",
            company: this.company,
            type: "productOffering",
          },
        };

        const offerObjectBase = {
          name: this.supplierdata.name,
          customName: this.supplierdata.customName,
          externalID: this.supplierdata._id,
          company: this.company,
          role: "supplier",
          sku: item.SKU,
          id: item.id,
          isDefault: false,
          tiers: item.tiers,

          leadtime: `${this.supplierdata?.externalReference[1]?.leadTime} ${this.supplierdata?.externalReference[1]?.leadPeriod}`,
          msrp: {
            value: item?.MSRP?.value,
            unit: item?.MSRP?.unit,
          },
          place: this.locationdata,
        };

        if (Array.isArray(this.supplierdata?.attachment)) {
          this.supplierdata.attachment.forEach((attachment) => {
            if (attachment?.["@type"]?.toLowerCase() === "profile") {
              offerObjectBase.attachmentName = attachment?.name;
            }
          });
        }
        if (this.categoryInfo.type === "Tangible") {
          let newStockValue = item.stock || 0;
          const newStockObject = {
            ...newStockObjectBase,
            productStockLevel: { unit: newStockValue, cost: 0 },
          };

          const offerObject = {
            ...offerObjectBase,
            productStockLevel: { unit: newStockValue, cost: 0 },
          };

          this.offerStockArray.push(offerObject);
          this.newStockArray.push(newStockObject);
        }

        if (this.categoryInfo.type === "Intangible") {
          const newStockObject = {
            ...newStockObjectBase,
            productStockLevel: { cost: 0 },
          };

          const offerObject = {
            ...offerObjectBase,
            productStockLevel: { cost: 0 },
          };
          if (index === 0) {
            this.offerStockArray.push(offerObject);
            this.newStockArray.push(newStockObject);
          }
        }
      });

      this.productselect.relatedParty = this.productselect.relatedParty.concat(
        this.offerStockArray
      );
      console.log(this.offerStockArray, " this.productselect.relatedParty");
      this.productselect.checked = false;
      const updateSpecWithChar = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        { ...this.productselect, id: this.productselect._id }
      );
      console.log(
        updateSpecWithChar,
        this.newStockArray,
        "updateSpecWithCharupdateSpecWithChar"
      );
      await this.addProductf(this.newStockArray);
      console.log(
        this.newStockArray,
        this.offerStockArray,
        this.productselect,
        updateSpecWithChar,
        "this.productselect"
      );
    },

    async addProductf(product) {
      console.log(product, "productIntagableTesting ");
      this.cascaderButton = true;
      this.modal_active_cascode = false;
      const addedProductSupplierLogPayload = {
        message: `${this.supplierdata.customName} product has been added`,
        detailedAction: product.map((value) => {
          return {
            item: value.name,
            action: "ADDED",
            property: "product",
          };
        }),
        action: "ADDED",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id, product[0].externalID],

        "@type": "supplier-supplier",
        category: ["productOffering", "supplier"],
      };
      product.map(async (item) => {
        let result = await UPCAxiosInstance.post(
          `/productStock?company=${this.$route.params.companyName}`,
          item
        );
        console.log(item, result, "item");
        this.initLog(addedProductSupplierLogPayload);

        await this.rulecall();
        this.cascadCancel();
      });
      this.notification = {
        type: "success",
        message: `Your item is added successfully!.`,
      };
    },

    productSearch(val) {
      this.searchvalue = val;
      if (val !== "") {
        let searchData = this.searchNestedObjects(
          this.productdata,
          val.toLowerCase()
        );
        this.tableData = JSON.parse(JSON.stringify(searchData));
      } else {
        this.tableData = JSON.parse(JSON.stringify(this.productdata));
      }
    },
    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },

    deletesku(index) {
      console.log(index, "filterdatadeletesku");

      this.filterdata.splice(index, 1);
      console.log(this.filterdata, "this.filterdata");
    },
    async deleteExecRow() {
      const spechdaa = this.ProductSelectValue.filter(
        (item) => this.deleteobj.name === item.name
      );

      console.log(spechdaa[0]);
      if (spechdaa[0] != undefined) {
        this.filteredPayload1 = spechdaa[0]?.relatedParty?.filter((item) => {
          return !(
            item?.name === this?.supplierdata?.name &&
            item?.id === this?.deleteobj?.stockedProduct?.id &&
            item?.place?.description === this.deleteobj.place?.description
          );
        });
        spechdaa[0].relatedParty = this.filteredPayload1;
        await UPCAxiosInstance.patch(`/catalogManagement/productOffering`, {
          ...spechdaa[0],
          id: spechdaa[0]._id,
        });
      }

      console.log(this.filteredPayload1, "this.filteredPayload1");

      var result = await UPCAxiosInstance.delete(
        `/productStock/${this.deleteobj.id}?company=${this.$route.params.companyName}`
      );
      console.log(result, "editProduct");
      let SM_OverviewLog = {
        message: `${this.deleteobj.name} SKU product has been deleted`,
        detailedAction: [],
        "@type": "Supplier-product",
        category: ["supplier", "productOffering"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: [this.$route.params.id, this.deleteobj.externalID],
        action: "UPDATE",
        status: "active",
      };
      this.initLog(SM_OverviewLog);

      this.refeshData();
      this.delete_modal_active = false;
      this.notification = {
        type: "success",
        message: `Done! Your item has been removed.`,
      };
    },
    async refeshData() {
      const tableData = await UPCAxiosInstance.get(
        `/productStock?company=${this.$route.params.companyName}`
      );
      this.productdata = tableData.data;

      console.log(this.productdata, "this.productdata ");
    },
    viewPage(val) {
      (this.editproductPayload = val), console.log(val, "edit flow");
    },
    async changeRowsPerPage(count) {
      if (this.company != "") {
        this.rowsPerPage = count;
      }
    },
    async currentPage(val) {
      if (this.company != "") {
        this.currPage = val;
      }
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    payloadval(val) {
      this.payload.minStockLevel = { unit: val };
      // this.editPayload.minStockLevel = { unit: val };
      console.log(val, this.payload, "payloadval");
    },
    payloadvaledit(val) {
      console.log(this.editPayload, "this.editPayload");

      // Ensure that productStockLevel and stockedProduct are defined
      if (!this.editPayload.productStockLevel) {
        this.editPayload.productStockLevel = {};
      }

      if (!this.editPayload.stockedProduct) {
        this.editPayload.stockedProduct = {};
      }

      // Set the productStockLevel unit
      if (this.editPayload.productStockLevel.unit) {
        this.editPayload.productStockLevel.unit = val;
      } else {
        this.editPayload.productStockLevel = { unit: val };
      }

      // Set the stockedProduct stock
      this.editPayload.stockedProduct.stock = val;
    },
    payloadcost(val) {
      try {
        // Ensure editPayload and productStockLevel exist
        if (!this.editPayload) {
          this.editPayload = {}; // Initialize editPayload if it doesn't exist
        }

        if (!this.editPayload.productStockLevel) {
          this.editPayload.productStockLevel = {}; // Initialize productStockLevel if it doesn't exist
        }

        // Assign the value to cost
        if (typeof val !== "undefined" && val !== null) {
          this.editPayload.productStockLevel.cost = val;
        } else {
          throw new Error("Invalid value for cost: Value is undefined or null");
        }
      } catch (error) {
        console.error("Error setting cost:", error.message);
        // Handle the error appropriately, e.g., show a message to the user
        this.$toast.error("Failed to update cost. Please try again.");
      }
    },
    async selectedproductFunc() {},
    leaf(text) {
      console.log("leaf category text full", text);
      console.log("leaf category text length", text?.length);
      console.log("leaf category text typeof", typeof text);
      let lengthOfText = text?.length;
      if (typeof text === "string") {
        return text;
      }
      if (lengthOfText === 1) {
        console.log("leaf category text length final o/p ", text);
        return (text = text.toString());
      }
      if (text && lengthOfText > 1) {
        const total = text?.length;
        console.log(
          text,
          "leaf category text length final o/p",
          text[total - 1]
        );

        return text[total - 1];
      }
    },
    async setvalue(val) {
      this.productselect = val;
      let leafCategory = this.leaf(val.category);
      let categoryInfo = await UPCAxiosInstance.get(
        "/catalogManagement/category/" + leafCategory
      );
      console.log("🚀 ~ computedPriceTypes ~ categoryInfo:", categoryInfo);
      if (categoryInfo.data) {
        this.categoryInfo = categoryInfo.data;
      }

      this.filterdata = this.productselect.prodSpecCharValue.filter((value) => {
        if (this.categoryInfo.type === "Tangible") {
          if (value.SKU) {
            return (
              value.priceType?.toLowerCase() ===
              this.categoryInfo.pricingType[0].toLowerCase()
            );
          }
        } else {
          return (
            value.priceType?.toLowerCase() ===
            this.categoryInfo.pricingType[0].toLowerCase()
          );
        }
      });

      console.log(this.filterdata, "filterdata");

      this.payload.name = val.name;
      this.selectedvalueproduct = val._id;
      console.log(this.selectedvalueproduct, "this.selectedvalueproduct");
      this.cascaderButton = false;
      console.log(this.payload, val);
      this.detailproduct = true;
      document.getElementById("showTheProductDetail").style.display = "block";
    },
    setvalueedit(val) {
      this.productselect = val;
      this.editPayload.name = val.name;
      console.log(this.editPayload, val);
    },

    addproduct() {
      this.modal_active_cascode = true;
      this.selectcomponentkey += 1;
    },

    cascadCancel() {
      this.modal_active_cascode = false;
      this.locationkey += 1;
      this.productStockLevelkey += 1;
      this.selectcomponentkey += 1;
      this.inputComponentkey += 1;
      this.payload = {};
      this.productselect = {};
      this.cascaderButton = true;
      document.getElementById("showTheProductDetail").style.display = "none";
    },
    editcascadCancel() {
      this.modal_edit_cascode = false;
      this.locationkey += 1;
      this.productStockLevelkey += 1;
      this.selectcomponentkey += 1;
      this.inputComponentkey += 1;
      this.editPayload = {};
      this.productselect = {};
      this.editdisableButton = false;
      document.getElementById("showTheProductDetail").style.display = "none";
    },

    async editchildContinue() {
      const payload = {
        id: this.editPayload.id,
        productStockLevel: {
          unit: this.editinpurvalue,
        },
      };
      let result = await UPCAxiosInstance.patch(
        `/productStock?company=${this.$route.params.companyName}`,
        payload
      );
      this.refeshData();
      this.editcascadCancel();
      console.log(result, payload);
    },
    crudContextMenu($event, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.map((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.map((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    async closeCrudContextMenu($event, ele, i) {
      if ($event.toLowerCase() === "edit") {
        this.modal_edit_cascode = true;
        this.editPayload = JSON.parse(JSON.stringify(ele));
        this.initialData = JSON.parse(JSON.stringify(ele));
        this.initialData.productStockLevel = {
          unit: 0,
          cost: 0,
        };
        this.initialData.place = {
          city: "",
          unit: "",
          country: "",
          state: "",
          postalCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: true,
          "@type": "",
          phone: {
            phoneNumber: {
              country: {
                name: "United Kingdom",
                short: "GB",
                flags: "https://flagcdn.com/gb.svg",
                dial_code: "+44",
              },
              value: "",
              valid: true,
            },
          },
          email: {
            emailAddress: "",
          },
          locationName: "",
          checked: true,
          disabled: false,
        };

        this.rawData = JSON.parse(JSON.stringify(ele));

        if (ele?.productStockLevel?.cost > 0) {
          this.editcost = ele?.productStockLevel?.cost;
        } else if (ele?.costRule && ele?.productStockLevel?.cost === 0) {
          this.editcost = ele?.costRule;
        } else {
          this.editcost = 0;
        }

        const qureydata = {
          type: "filter",
          module: "productOffering",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
          },
          isNeedToBeSort: false,
          sortFieldAndOrder: {
            // "name":-1
          },

          filterQuery: {
            name: { $regex: ele.name, $options: "i" },
          },
          searchQuery: "",
        };
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          qureydata
        );

        this.editProduct = filterResultData?.data?.data[0];
        this.rawData = filterResultData?.data?.data[0];
        console.log(
          $event,
          ele,
          i,
          this.editProduct,
          "edit closeCrudContextMenu"
        );
      } else if ($event.toLowerCase() == "delete") {
        this.delete_modal_active = true;
        this.deleteobj = ele;
        this.delete_name = ele.name;
        this.deleterowindex = i;
      }
    },

    computedTableAction() {
      return [
        {
          label: "Edit",
          icon: "icon-file-text-edit-regular",
        },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ];
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    arrange(val) {
      this.re_arrange = val;
      this.updateTheads();
    },
    arrangeColumns(val) {
      this.re_arrange = val;
      this.updateTheads();
    },
    updateTheads() {
      this.accounts_table_theads_cc = this.accounts_table_theads_cc.map(
        (thead) => {
          const isMatching = this.accounts_table_theads.some(
            (duplicateThead) => duplicateThead.prop === thead.prop
          );
          return { ...thead, checked: isMatching };
        }
      );
    },
    async applyColumns(val) {
      // To get rearranged header
      console.log("applyColumns", val);
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
      let concatedEntity = sampleTheads?.data?.entityRef || {};

      concatedEntity.supplierProductTable = val;

      let reArrangeTableHeadPayload = { entityRef: concatedEntity };

      const changeTableHeader = await MLTAxiosInstance.patch(
        `/settings`,
        reArrangeTableHeadPayload
      );
      console.log(
        changeTableHeader,
        "reArrangeTableHeadPayload",
        reArrangeTableHeadPayload
      );

      let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
      console.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

      let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
      this.accounts_table_theads_cc = entityRef["supplierProductTable"];
      this.accounts_table_theads = this.accounts_table_theads_cc.filter(
        (ele) => ele.checked
      );
      this.tableHeaderOrgcc = JSON.parse(
        JSON.stringify(this.accounts_table_theads_cc)
      );
      this.tableHeaderOrg = JSON.parse(
        JSON.stringify(this.accounts_table_theads)
      );
    },
  },
};
</script>
<style lang="scss">
.dreawerclass {
  right: 0px;
  width: 400px;
  display: flex;
  height: 100vh !important;
  flex-direction: column;
  justify-content: space-between;
}
.value-data-supplier {
  display: flex;
  font-size: 14px;
  padding: 14px;
  .value-data-supplier_left {
    width: 102%;
  }
  .value-data-supplier_right {
    width: 80%;
  }
}
.header_product_sku_stock {
  height: 30px;
  background: rgba(217, 217, 217, 0.4);
  margin-bottom: 20px;
  padding: 5px 16px;
}
#showTheProductDetail {
  display: none;
}
.add_delete_supplier {
  display: flex;
  align-items: center;
  margin-left: 10px;
  width: 8%;
  justify-content: space-between;
}
.regain_time {
  display: flex;
  align-items: center;
  padding: 5px 16px;
  .body_sno {
    width: 10%;
  }
  .body_region {
    width: 50%;
  }
  .body_lead {
    width: 50%;
  }
}
.head_regain_time {
  height: 50px;
  background: rgba(217, 217, 217, 0.2);
  padding: 5px 16px;
  border-radius: 6px;
  font-size: 12px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  .body_sno {
    width: 10%;
  }
  .body_region {
    width: 50%;
  }
  .body_lead {
    width: 50%;
  }
}
.product_supplier {
  margin-bottom: 20px;
  .supplier-right-model-content {
    gap: 20px;
  }
}
.Cascader_product_supplier {
  margin-top: 20px;
}
.product_detail_supplier_modal {
  .header-area-contact-drawer {
    padding: 8px 0 12px 8px;
    border-bottom: 1px solid #d3d3d3;
  }
  .sidenav-right {
    overflow: hidden;
    .body-panel-right {
      height: 87vh !important ;
    }
  }

  .toplayer_supplier {
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .text_product_supplier {
      font-weight: bold;
      margin-top: 10px;
    }
    .product_detail_supplier {
      font-size: 36px;
      display: flex;
      align-items: center;
      align-content: flex-end;
      justify-content: space-evenly;
      flex-wrap: nowrap;
      color: #54bd95;

      flex-direction: row-reverse;
      width: 70px;
      height: 90px;
      border-radius: 50%;
      background-color: #54bd9524;
    }
  }
}
.product-detail-supplier {
  background: rgba(217, 217, 217, 0.2);
  padding: 5px;
  border-radius: 6px;
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
  .value-data-supplier {
    display: flex;
    font-size: 14px;
    padding: 14px;
    .value-data-supplier_left {
      width: 102%;
    }
    .value-data-supplier_right {
      width: 80%;
    }
  }
}
.header-add-supplier {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  .tablehead-add-supplier {
    font-weight: bold;
  }
  .search-add-supplier {
    display: flex;
    gap: 10px;
  }
}
.contacts-username-supplier {
  display: flex;
  align-items: center;
  .right-head-supplier {
    display: flex;
    flex-direction: column;
  }
  .head-supplier-left {
    display: flex;
    justify-content: center;
    width: 44px;
    align-items: center;
    font-size: 45px;
    margin-right: 10px;
  }
  .head-supplier-right {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    .head-supplier-name {
      margin-right: 10px;
    }
  }
  .head-supplier-address {
    font-size: 10px;
    color: #929191;
    margin-top: 3px;
  }
}
</style>
