<template>
  <div ref="mapContainer" class="map-container123">
    <div style="
    z-index: 9;
    background: white;
    height: 2px;
    width: 100%;
    position: absolute;
    top: 8px;
    right: 0px;
    "></div>



    <div style="
    z-index: 9;
    background: white;
    height: 230px;
    width: 2px;
    position: absolute;
    top: 10px;
    left: 8px;
    "></div>


    <div style="
    z-index: 9;
    background: white;
    height: 230px;
    width: 2px;
    position: absolute;
    top: 10px;
    right: 8px;
    "></div>



    <GMapMap
      :center="mapCenter1"
      :zoom="1"
      :options="mapOptions"
      :style="{ width: '100%', height: mapHeight }"
    >
      <div v-for="(polygonPathsSub, indx) in polygonPaths1" :key="indx">
        <GMapPolygon
          v-for="(polygon, index) in polygonPathsSub.array"
          :key="index"
          :paths="polygon"
          :options="{
            fillColor:
              activePolygonIndex === `${indx}-${index}` ? '#54bd95' : '#d8d8d8',
            fillOpacity: 0.5,
            strokeWeight: 1,
            strokeColor: '#FFFFFF',
          }"
          @mouseover="handleMouseOver(indx, index, polygon,polygonPathsSub.message)"
          @mouseout="handleMouseOut"
        />
      </div>
    </GMapMap>

    <!-- Tooltip to display polygon name -->
    <div v-if="showTooltip" :style="tooltipStyles" class="tooltip_map_arrow_near123123">
      {{ hoveredPolygonName }}
    </div>
  </div>
</template>

<script>
// import { MLTAxiosInstance } from "@/config/axiosConfig";
import { GMapMap, GMapPolygon } from "@fawmi/vue-google-maps";
// import axios from "axios";

export default {
  components: {
    GMapMap,
    GMapPolygon,
  },
  props: {
    mapHeight: {
      type: String,
      default: "600px", // Set a default height
    },
    mapCenter: {
      type: Object, // Set a default height
      default: () => ({ lat: 65.9678315, lng: -101.9522398 })
    },
    
    polygonPaths: {
      type: Array,
      default: () => [], 
    },
  },
  data() {
    return {
      polygonPaths1:[],
      center: { lat: 65.9678315, lng: -101.9522398 }, // Center on a location
      mapCenter1: { lat: 65.9678315, lng: -101.9522398 }, // Center on a location
      // polygon Paths: [], // Array of polygon paths
      activePolygonIndex: null, // To track which polygon is being hovered
      hoveredPolygonName: "", // To store the name of the hovered polygon
      showTooltip: false, // Flag to show/hide the tooltip
      tooltipPosition: { top: 0, left: 0 }, // Position of the tooltip

      mapOptions: {
        styles: [
          {
            featureType: "all",
            elementType: "geometry",
            stylers: [{ color: "#FFFFFF" }], // White color for all features
          },
          {
            featureType: "all",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [{ visibility: "off" }],
          },
        ],
        mapTypeControl: false, // Hide the map type control
        fullscreenControl: false, // Hide the fullscreen control
        streetViewControl: false, // Hide the Street View Pegman
        zoomControl: false, // Hide the zoom control if needed
        scaleControl: false, // Hide the scale control if needed
        scrollwheel: false,       // Disable zoom by scroll
        // gestureHandling: 'none',  // Disable all gestures (including zoom)
      },
      countryPayload: [],
    };
  },
  computed: {
    tooltipStyles() {
      return {
        position: "fixed",
        top: `${this.tooltipPosition.top}px`,
        left: `${this.tooltipPosition.left}px`,
        backgroundColor: "white",
        padding: "5px",
        borderRadius: "3px",
        border: "1px solid #ccc",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
      };
    },
  },
  watch:{

    
    polygonPaths: {
      handler(value) {
        // console.log(value, "polygonPaths is watching");
        this.polygonPaths1 = value;

      },
      deep: true,
      immediate: true,
    },

    mapCenter: {
      handler(value) {
        console.log(value, "mapCenter is watching");
        this.mapCenter1 = value;

      },
      deep: true,
      immediate: true,
    },



  },
  async mounted() {
    // let country = this.country
    // country
    // this.polygon Paths = [];

    console.log("erdfcgvhjnm,");

    // try {
    //   let payload = await MLTAxiosInstance.get(
    //     `/countryInfo?fieldToFilter=name`
    //   );
    //   this.countryPayload = [];
    //   payload.data.forEach((item) => {
    //     if (item.name) {
    //       item.value = item.name.common;
    //       this.countryPayload.push(item);
    //     }
    //   });
    //   console.log("countryPayload...", this.countryPayload, payload);
    // } catch (error) {
    //   console.log("error...", error);
    // }

    // let w = "canada";
    // this.getCountryId(country);

    // const countryEmit = this.countryPayload.filter(
    //   (ctry) => ctry?.value.toLowerCase() === country?.toLowerCase()
    // );
    // console.log("countryEmit...", countryEmit);

    // const states = await this.countryValue(countryEmit);
    // states;

    // if (states) {
    //   states.forEach((state, ind) => {
    //     state.name;
    //     // if(state.name === )
    //     setTimeout(() => {
    //       console.log("1....");
    //       console.log("2....");

    //       this.fetchCoordinates(state.name);
    //     }, 1000 * ind);
    //   });
    // }

    this.$nextTick().then(() => {
      this.hideMapElements(); // Call method to hide map elements after DOM update
    });

    console.log("mount end", );
  },

  methods: {
    // async countryValue(val) {
    //   console.log(val, "ids");
    //   try {
    //     let payload = await MLTAxiosInstance.get(
    //       `/countryInfo/basedOnQuery?_id=${val[0]._id}`
    //     );
    //     console.log("payload...", payload);

    //     const provinceStates = payload.data[0].states;
    //     console.log("provinceStates...", provinceStates);

    //     return provinceStates;
    //   } catch (error) {
    //     console.log("error...", error);
    //     return null;
    //   }
    // },

    // async getCountryId(countryName) {
    //   try {
    //     const response = await axios.get(
    //       `https://maps.googleapis.com/maps/api/geocode/json`,
    //       {
    //         params: {
    //           address: countryName,
    //           key: process.env.VUE_APP_GOOGLE_MAP_KEY,
    //         },
    //       }
    //     );

    //     const results = response.data.results;
    //     console.log("results..",results);
        

    //     if (results.length > 0) {
    //       this.center = results[0].geometry.location;
    //       console.log("this.center...",this.center);
          
    //     }
    //   } catch (error) {
    //     console.error("Error fetching country data:", error);
    //     return "Error";
    //   }
    // },

    // Event handler for mouseover (hover)
    handleMouseOver(indx, index, polygon,message) {
      message
      this.activePolygonIndex = `${indx}-${index}`;
      this.hoveredPolygonName = message || "79"; // Assume 'name' is a field in the polygon object
      this.showTooltip = true;
      console.log("mouseOver...", this.hoveredPolygonName, this.showTooltip);

      // Get mouse position to display tooltip near the cursor
      window.addEventListener("mousemove", this.updateTooltipPosition);
    },

    // Event handler for mouseout (hover out)
    handleMouseOut() {
      this.activePolygonIndex = null;
      this.showTooltip = false;
      console.log("mouseleave...", this.hoveredPolygonName, this.showTooltip);

      // Remove the event listener when not hovering
      window.removeEventListener("mousemove", this.updateTooltipPosition);
    },

    updateTooltipPosition(event) {
      event
      console.log("updateTooltipPosition..",event,event.clientY,event.clientX);
      
      this.tooltipPosition = {
        top: event.clientY + 14, // Offset a bit below the cursor
        left: event.clientX + 14, // Offset a bit to the right of the cursor
      };
    },

    // async fetchCoordinates(query) {
    //   try {
    //     const response = await axios.get(
    //       `https://nominatim.openstreetmap.org/search.php?q=${query}&polygon_geojson=1&format=jsonv2`
    //     );
    //     const data = response.data;
    //     console.log("Fetched data:", data);
    //     this.processCoordinates(data);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // },
    // processCoordinates(data) {
    //   if (data[0]) {
    //     let arrayOfArray = [];
    //     const coordinates = data[0].geojson.coordinates;
    //     let areaType = "";

    //     if (
    //       coordinates.length === 2 &&
    //       typeof coordinates[0] === "number" &&
    //       typeof coordinates[1] === "number"
    //     ) {
    //       arrayOfArray.push([{ lat: coordinates[1], lng: coordinates[0] }]);
    //       console.log("Single coordinate:", coordinates);
    //       areaType = "Single";
    //     } else if (
    //       Array.isArray(coordinates) &&
    //       coordinates.every(
    //         (coord) => Array.isArray(coord) && coord.length === 2
    //       )
    //     ) {
    //       arrayOfArray.push(coordinates.map(([lng, lat]) => ({ lat, lng })));
    //       console.log("Array of coordinate pairs:", coordinates);
    //       areaType = "Array";
    //     } else if (
    //       Array.isArray(coordinates) &&
    //       Array.isArray(coordinates[0]) &&
    //       coordinates[0].every(
    //         (coord) => Array.isArray(coord) && coord.length === 2
    //       )
    //     ) {
    //       coordinates.forEach((nestedCoords) => {
    //         arrayOfArray.push(nestedCoords.map(([lng, lat]) => ({ lat, lng })));
    //       });
    //       console.log("Nested array of coordinate pairs:", coordinates);
    //       areaType = "Nested";
    //     } else {
    //       coordinates.forEach((nestedCoords) => {
    //         arrayOfArray.push(
    //           nestedCoords[0].map(([lng, lat]) => ({ lat, lng }))
    //         );
    //       });
    //       console.log("Multi-level nested array:", coordinates);
    //       areaType = "MultiNested";
    //     }

    //     console.log("areaType", areaType);
    //     console.log("Processed polygon paths:", arrayOfArray);
    //     this.polygon Paths1.push(arrayOfArray);
    //   }
    // },
    hideMapElements() {
      const mapContainer = this.$refs.mapContainer;

      if (mapContainer) {
        const styleElement = document.createElement("style");
        styleElement.textContent = `
            .gm-style-iw-c {
              display: none !important;
            }
            .gm-style-iw {
              display: none !important;
            }
            .gm-style-cc {
              display: none !important;
            }
            .gm-style-cc img {
              display: none !important;
            }
            .gm-style-mtc {
              display: none !important;
            }
          `;
        mapContainer.appendChild(styleElement);
      }
    },
  },
};
</script>

<style>
.map-container123 {
  width: 100%;
  height: 500px;
  /* outline:none */
  /* border:2px red solid; */
}

.map-container123:focus {
  outline:none;
}



/* #mapContainer {
  outline: none; 
}

#mapContainer:focus {
  outline: none;
} */



.tooltip_map_arrow_near123123 {
  font-size: 14px;
  color: #333;
  /* pointer-events: none;  Makes sure the tooltip doesn't interfere with hover events */
}
</style>
