<template>
  <hlx-alert-notification :notify="notification" :auto-close="true" />
 
  <section style="padding: 1rem 1.5rem 0rem 1.5rem; overflow: auto !important">
    <discountComponent
      :is-discount-editable="true"
      :discount-data="discountData"
      :permisstion-data="permisstionData"
      :discount-height="'calc(100vh - 70px)'"
      @new-discount-data="addNewDiscountData"
      @delete-discount="deleteDiscountData"
      @edit-discount-data="editDiscountData"
      @atclick-edit-button="intialdata"
    ></discountComponent>
  </section> 
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'200px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>

    <template #content>
      <div
        class="modals-content"
        style="
          display: flex;
          margin-top: 6%;
          margin-left: 4%;
          align-items: center;
        "
      >
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>

    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
</template>

<script>
import discountComponent from "@/components/Product/productDiscountComponent.vue";
import { UPCAxiosInstance ,MLTAxiosInstance} from "@/config/axiosConfig";
export default {
  components: {
    discountComponent,
  },
  data() {
    return {
      intialval: {},
      
      delete_name: "",
      delete_modal_active: false,
      deleteObject: {},
      notification: {},
      discountData: [],
      permisstionData:{},
    };
  },
  async mounted() {
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");

// this.company = await val.data.company;
let sampleTheadsd = await MLTAxiosInstance.get(
  `partyRole/get/${val.data.userId}`
);

const mergedPayload = sampleTheadsd.data.permission;
console.log(
  mergedPayload.UPC.child["Product catalogue"].child["Discounts"],
  "mergedPayload"
);
this.permisstionData =
  mergedPayload.UPC.child["Product catalogue"].child["Discounts"];
    const discountTable = await UPCAxiosInstance.get("/discount");
    this.discountData = discountTable.data;
  },
  methods: {
    intialdata(val) {
      this.intialval = val;
    },
    async addNewDiscountData(val) {
      console.log("addNewDiscountData", val);
      // this.discountData.name=val.name
      // this.discountData.type=val.type
      // this.discountData.status=val.lifecycleState
      // this.discountData.validity.startDate=val.validFrom.startDate
      // this.discountData.validity.EndDate=val.validFrom.EndDate
      console.log("newDiscount", val.applyTo);
      const newDiscount = {
        id: 34345,
        name: val.name,
        type: val.type,
        applyTo: val.applyTo,
        lifecycleState: val.lifecycleState,
        validFor: {
          startDate: val?.validFor?.startDate,
          endDate: val?.validFor?.endDate,
        },
        recurringChargePeriod: val.recurringChargePeriod,
        relatedParty: val.relatedParty,
        discountType: {
          name: val.discountType.name,
          value: val.discountType.value,
        },
        description: val.description,
        minimumDiscountRules: val.minimumDiscountRules,
        maximumDiscountRules: val.maximumDiscountRules,
        appliesTo: val.appliesTo,
        isSpecified: val.isSpecified,
        extarctedType: val.extarctedType,
        specifiedAccounts: val.specifiedAccounts,
      };
      console.log("newDiscount", newDiscount); // Log the newDiscount object to verify assignment
      console.log("newDiscount after assignment:", newDiscount.applyTo); // Log the applyTo property after assignment
      console.log("newDiscount", newDiscount.applyTo);
      const addDiscount = await UPCAxiosInstance.post("/discount", newDiscount);
      console.log("addDiscount", addDiscount.data);
      if (addDiscount.data) {
        this.notification = {
          type: "success",
          message: `Your item is added successfully!.`,
        };
        const discountAddLogPayload = {
          message: `${val.name} ${
            val.type == "Product offering"
              ? "product based"
              : val.type == "Cart based discount"
              ? "cart based"
              : val.type == "Customer discount:New discount"
              ? "customer based"
              : val.type == "Role discount"
              ? "role based"
              : val.type == "Payment discount"
              ? "payment based"
              : val.type == "Recurring discount"
              ? "recurring based"
              : ""
          } discount has been added`,
          detailedAction: [],
          "@type": "Offering-discount",
          category: ["productOffering", "discount"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          ref: val.appliesTo?.map((item) => item?._id),
          action: "ADDED",
        };
        this.initLog(discountAddLogPayload);
        this.refreshDiscountData();
      }
    },
    async deleteExecRow() {
      const deleteDiscount = await UPCAxiosInstance.delete(
        `/discount/${this.deleteObject._id}`
      );
      const deleteDiscountLogPayload = {
        message: `${this.deleteObject.name} ${
          this.deleteObject.type == "Product offering"
            ? "product based"
            : this.deleteObject.type == "Cart based discount"
            ? "cart based"
            : this.deleteObject.type == "Customer discount:New discount"
            ? "customer based"
            : this.deleteObject.type == "Role discount"
            ? "role based"
            : this.deleteObject.type == "Payment discount"
            ? "payment based"
            : this.deleteObject.type == "Recurring discount"
            ? "recurring based"
            : ""
        } discount has been deleted`,
        detailedAction: [],
        "@type": "Offering-discount",
        category: ["productOffering", "discount"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: this.deleteObject.appliesTo?.map((item) => item?._id),
        action: "DELETED",
      };
      if (deleteDiscount.status == 204) {
        this.notification = {
          type: "success",
          message: `Done! Your item has been removed.`,
        };
        this.initLog(deleteDiscountLogPayload);
        this.delete_modal_active = false;
        this.refreshDiscountData();
      }
    },
    async editDiscountData(val) {
      val.context = false
      val.id = val._id;
      delete val._id;
      const updateDiscount = await UPCAxiosInstance.patch(`/discount/`, val);
      console.log("editDiscountData", updateDiscount, val);
      let editedDiscountLogPayload = {
        message: `${val.name} ${
          val.type == "Product offering"
            ? "product based"
            : val.type == "Cart based discount"
            ? "cart based"
            : val.type == "Customer discount:New discount"
            ? "customer based"
            : val.type == "Role discount"
            ? "role based"
            : val.type == "Payment discount"
            ? "payment based"
            : val.type == "Recurring discount"
            ? "recurring based"
            : ""
        } discount has been updated`,
        detailedAction: [],
        "@type": "Offering-discount",
        category: ["productOffering", "discount"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: val.appliesTo?.map((item) => item?._id),
        action: "UPDATED",
      };
// Name log
      let nameActionLog = this.buildBasicLogByDifferences(
        this.intialval,
        val,
        "name",
        "Name"
      );
      console.log(nameActionLog, "nameActionLog");

      // Description log
      let descriptionActionLog = this.buildBasicLogByDifferences(
        this.intialval,
        val,
        "description",
        "Description"
      );
      console.log(descriptionActionLog, "descriptionActionLog");
   
   
   // Status log
      let statusActionLog = this.buildBasicLogByDifferences(
        this.intialval,
        val,
        "lifecycleState",
        "Status"
      );
      console.log(statusActionLog, "descriptionActionLog");
 
 // endDate log
      let endDateActionLog = this.buildBasicLogByDifferences(
        this.intialval.validFor,
        val.validFor,
        "endDate",
        "endDate"
      );
      console.log(endDateActionLog, "descriptionActionLog");
  
  // startDate log
      let startDateActionLog = this.buildBasicLogByDifferences(
        this.intialval.validFor,
        val.validFor,
        "startDate",
        "startDate"
      );
      console.log(startDateActionLog, "descriptionActionLog");

// Discount type log
      let DiscountActionLog = this.buildBasicLogByDifferences(
        this.intialval.discountType,
        val.discountType,
        "name",
        "Discount type"
      );
   
   console.log(DiscountActionLog, "descriptionActionLog");

// Discount type log
      let DiscountvActionLog = this.buildBasicLogByDifferences(
        this.intialval.discountType,
        val.discountType,
        "value",
        "Discount value"
      );
      console.log(DiscountvActionLog, "descriptionActionLog");

     // appliesToActionLog log
      let appliesToActionLog = this.buildLogBasedOnDifferencesForArrayOfObjects(
        this.intialval.appliesTo,
        val.appliesTo,
        "name",
        "Apply to"
      );
      console.log(appliesToActionLog, "descriptionActionLog");

editedDiscountLogPayload.detailedAction =editedDiscountLogPayload.detailedAction.concat(appliesToActionLog,DiscountvActionLog,DiscountActionLog,nameActionLog,startDateActionLog,endDateActionLog,statusActionLog,nameActionLog,descriptionActionLog)

      // find name changes
      if (val.name)
        if (updateDiscount.status == 200) {
          this.notification = {
            type: "success",
            message: `Done! Your item has been updated.`,
          };
          this.initLog(editedDiscountLogPayload);
          this.refreshDiscountData();
        }
    },
    async deleteDiscountData(val) {
      this.delete_modal_active = true;
      this.deleteObject = val;
      this.delete_name = val.name;
    },
    async refreshDiscountData() {
      const discountTable = await UPCAxiosInstance.get("/discount");
      this.discountData = discountTable.data;
    },
  },
};
</script>

<style lang="scss">
.dmnlayout .right-main-container {
  overflow-y: auto !important;
}
</style>
