<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-modal
    :modal-active="deletedialog"
    :height="'200px'"
    :width="'450px'"
    :modal="true"
    @close="deletedialog = false"
  >
    <template #header>
      <div>Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>Are you sure you want to delete the data?</p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary-success sm" @click="deletedialog = false"
        >Cancel</hlx-button
      >
      <hlx-button class="primary sm deletedialogok" @click="deleteokmodal"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
  <!-- <hlx-loader load-type="spinner" /> -->
  <div class="app-layout">
    <section class="new-right-panel">
      <div class="new-right-panel-parent-container no-filter-panel">
        <div class="new-right-panel-left-container"></div>
        <div class="new-right-panel-right-container">
          <section
            v-if="show_all_reference === true"
            class="new-layout-basic-no-breadcrumb"
          >
            <div class="new-layout-header">
              <span class="new-layout-header-container">
                <span class="schema-header-title"
                  >Data management<i class="icon-clock"></i>
                </span>
                <span class="subtitle">
                  Create and manage reference data sets required for rule
                  execution
                </span>
              </span>
            </div>
            <!-- <span class="reference-subtitle">
            Create and manage reference data sets required for rule execution. 
          </span> -->

            <div class="new-layout-body">
              <div
                class="header-utils-and-switch-container"
                style="width: 100%"
              >
                <span style="width: 70%">
                  <!-- <hlx-switch
                  :switch_items="switch_items"
                  :type="'tab'"
                  :position="'bottom'"
                  @chosen="switchmenu"
                /> -->
                  <!-- <hlx-switch
                  v-model="selectedValue"
                  :switch-items="switch_items"
                  :tab-view="true"
                  value-prop="value"
                  display-prop="name"
                  @change="handleCurrentChange"
                /> -->
                </span>
                <span class="schema-header-utils">
                  <span class="util-items search"
                    ><hlx-search
                      class="search"
                      :search_style="'style1'"
                      @search-key="search"
                  /></span>
                  <!-- <hlx-button class="secondary-success sm filter-button"
                  ><i class="icon-filter-regular" style="margin-right: 5px"></i
                  >Filter</hlx-button
                > -->
                  <!-- <hlx-button
                  class="primary sm add-button"
                  @click.stop="addbutton"
                  ><i
                    class="icon-plus-circle-regular"
                    style="margin-right: 5px"
                  ></i
                  >Add
                  <hlx-context-menu
                    :options="schemaoptions"
                    :show="clicked"
                    style="margin-top: 15px; margin-right: 20px"
                    @chosen="chooseschemaoptions"
                  />
                </hlx-button> -->
                </span>
              </div>
              <div v-if="tableData.length > 0" class="table-wrap">
                <hlx-table
                  :column-count="theads.length + 2"
                  :border="['table', 'header', 'horizontal']"
                  :bold-headers="false"
                  :row-hover="true"
                  theme="grey"
                  :striped-rows="false"
                  :re-arrange-columns="re_arrange"
                  :re-arrange-heads="theadsC"
                  @close-rearrange="re_arrange = false"
                  @apply-rearranged-columns="applyColumns"
                >
                  <template #thead>
                    <hlx-table-head
                      :width="60"
                      :align="'left'"
                      style="
                        padding: 0 15px !important;
                        max-width: 60px !important;
                      "
                      :fixed="'left'"
                      >{{ "S.no" }}</hlx-table-head
                    >
                    <!-- {{ i.prop }} -->
                    <hlx-table-head
                      v-for="(i, index) in theads"
                      :key="index"
                      :prop="i.prop"
                      :sortable="i.sortable"
                      :resizable="i.resizable"
                      :width="i.width"
                       @sorting_func="sorting_Data"
                      @new-resize-width="resizedWidth"
                    >
                      {{ i.label }}
                    </hlx-table-head>
                    <hlx-table-head
                     v-if="permisstionData.edit || permisstionData.delete"
                      v-show="editMode == true"
                      :width="60"
                      :fixed="'right'"
                      style="max-width: 60px !important"
                      @new-resize-width="resizedWidth"
                    >
                      <span
                        class="action-icon"
                        style="cursor: pointer"
                        ><i
                          class="icon-settings-regular"
                          style="cursor: pointer"
                         @click="reAssignOrgTableHeader()"
                        ></i></span
                    ></hlx-table-head>
                  </template>
                  <template #tbody>
                    <tr v-for="(i, index) in paginatedData" id="" :key="index">
                      <hlx-table-cell :fixed="'left'" :align="'center'">{{
                        serialNumber(index)
                      }}</hlx-table-cell>
                      <hlx-table-cell
                        v-for="(j, col_index) in theads"
                        :key="col_index"
                        :align="
                          j.prop.toLowerCase() === 'last_visited' ? 'left' : ''
                        "
                      >
                        <div
                          v-if="j.prop === 'name'"
                          :id="j.prop + index"
                          class="link-name"
                          style="
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          "
                          :style="{
                            width: '150px',
                          }"
                          @click="editdata(i.name)"
                          @mouseover="
                            tooltipover(
                              j.prop + index,
                              'tooltip-text' + j.prop + index
                            )
                          "
                          @mouseleave="
                            tooltipleave(
                              j.prop + index,
                              'tooltip-text' + j.prop + index
                            )
                          "
                        >
                          {{
                            i[j.prop].charAt(0).toUpperCase() +
                            i[j.prop].slice(1)
                          }}
                          <p
                            id="tooltip-text"
                            :class="'tooltip-text' + j.prop + index"
                          >
                            {{ i[j?.prop] }}
                          </p>
                        </div>
                        <!-- <div v-if="typeof(i[j.prop]) === 'string' && j.prop == 'name'" :align="'left'" style="width:100%;cursor:pointer" @click="editdata(i.name)">
              <span class="link-name">{{ i[j.prop].charAt(0).toUpperCase() + i[j.prop].slice(1)}}</span>  
              </div> -->
                        <!-- <div v-if="j.prop === 'last_visited'">
                {{formatDate(i[j.prop])}}
              </div> -->
                        <div v-else-if="j.prop.toLowerCase() == 'last_visited'">
                          {{ convertdateformat(i["updatedAt"]) }}
                          <!-- {{ formatDate(i[j.prop]) }} -->
                        </div>
                        <div
                          v-else-if="typeof i[j.prop] === 'string'"
                          :align="'left'"
                          style="width: 100%; cursor: pointer"
                        >
                          {{ i[j.prop] }}
                        </div>
                        <div
                          v-else-if="j.prop.toLowerCase() === 'description'"
                          :id="j.prop + index"
                          style="
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          "
                          :style="
                            j.width === null
                              ? {
                                  width: '170px',
                                }
                              : ''
                          "
                          @mouseover="
                            tooltipover(
                              j.prop + index,
                              'tooltip-text' + j.prop + index
                            )
                          "
                          @mouseleave="
                            tooltipleave(
                              j.prop + index,
                              'tooltip-text' + j.prop + index
                            )
                          "
                        >
                          {{ i[j.prop] }}
                          <p
                            id="tooltip-text"
                            :class="'tooltip-text' + j.prop + index"
                          >
                            {{ i[j?.prop] }}
                          </p>
                        </div>
                        <div v-else :align="'center'">
                          {{ i[j.prop] }}
                        </div>
                      </hlx-table-cell>
                      <hlx-table-cell
                       v-if="permisstionData.edit || permisstionData.delete"
                        v-show="editMode == true"
                        :align="'center'"
                        :width="60"
                        style="max-width: 60px"
                        :fixed="'right'"
                      >
                        <span :id="'table-context' + index" class="action-icon">
                          <i
                            style="position: relative"
                            class="icon-more-vertical-filled"
                            :class="i.context === true ? 'active-action' : ''"
                            @click="crudContextMenu($event, index)"
                          >
                            <hlx-context-menu
                              :top="containertop"
                              :left="containerleft"
                              :conditionvalue="150"
                              :conditiondown="2"
                              :conditionup="-38"
                              :options="contextmenu"
                              :data="index.toString()"
                              :show="i.context == true"
                              @chosen="closeCrudContextMenu($event, i, index)"
                            />
                            <!-- <span
                          v-else
                          style="position: absolute"
                        >
                          <hlx-context-menu
                            style="
                              z-index: 9000;
                              margin-right: 83px !important;
                              margin-top: -52px;
                              box-shadow: 0 0 20px 0 #d8d8d8;
                            "
                            :options="contextmenu"
                            :data="index.toString()"
                            :show="i.context == true"
                            @chosen="
                              closeCrudContextMenu(
                                $event,
                                i,
                                serialNumber(index)
                              )
                            "
                          />
                        </span> -->
                          </i>
                          <!-- <div class="custom-context-menu-container" :id="'custom-context-menu-container'+serialNumber(index)+this.unique"> -->
                          <!-- <div class="custom-context-menu-container" v-for="(i,index) in this.optionsD" :key="index"> -->
                          <!-- <i :class="this.optionsD[index].icon" id="icon-value"></i> -->
                          <!-- <p class="hlx-select-option-value">{{this.optionsD[index][this.Name]}}</p> -->
                          <!-- </div> -->
                          <!-- </div> -->
                        </span>
                      </hlx-table-cell>
                    </tr>
                  </template>
                </hlx-table>
              </div>
              <div v-else class="table-wrap">
                <hlx-table
                  :column-count="theads.length + 2"
                  :border="['table', 'header']"
                  :bold-headers="false"
                  :row-hover="true"
                  theme="grey"
                  :striped-rows="false"
                  :re-arrange-columns="re_arrange"
                  :re-arrange-heads="theadsC"
                  @close-rearrange="re_arrange = false"
                  @apply-rearranged-columns="applyColumns"
                >
                  <template #thead>
                    <hlx-table-head :width="60" :align="'left'">{{
                      "S.no"
                    }}</hlx-table-head>
                    <hlx-table-head
                      v-for="(i, index) in theads"
                      :key="index"
                      :prop="i.prop"
                      :sortable="i.sortable"
                      :resizable="i.resizable"
                      style="position: sticky; top: 0px"
                      :width="i.width !== undefined ? i.width : ''"
                      :align="i.align !== undefined ? i.align : ''"
                      @sorting_func="sorting_Data"
                    >
                      {{ i.label }}
                    </hlx-table-head>
                    <hlx-table-head :width="100">
                      <span style="cursor:pointer"
                        ><i
                          class="icon-settings-regular"
                          style="cursor: pointer"
                        @click="reAssignOrgTableHeader()"
                        ></i></span
                    ></hlx-table-head>
                  </template>
                  <template #tbody>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell :colspan="theads.length + 2">{{
                        "No data"
                      }}</hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                  </template>
                </hlx-table>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="new-layout-footer">
        <div class="pagination-container">
          <hlx-pagination
            v-model:rows-per-page="externalRowsPerPage"
            rows-per-page
            enable-rows-per-page
            :total="totalPageNo"
            :rows-per-page-list="[10, 15, 20]"
            :options="{
              attributes: {
                rowsPerPageTitle: true,
                rowsPerPageResult: true,
                rowsPerPageDropdown: {
                  position: {
                    top: 0,
                    right: 1,
                  },
                },
              },
            }"
            @current-page="currentPage"
            @updated:rows-per-page="changeRowsPerPage"
          ></hlx-pagination>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { DMNAxiosInstance, MLTAxiosInstance } from "@/config/axiosConfig";

export default {
  name: "ShowRef",

  data() {
    return {
      permisstionData:{},
      tableHeaderOrg:[],
      tableHeaderOrgcc:[],
      company: "",
      totalPageNo: 0,
      referencequery: {
        type: "filter",
        module: "reference",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          updatedAt: -1,
        },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      },
      externalRowsPerPage: 10,
      containerleft: 0,
      containertop: 0,
      editMode: true,
      editPermission: [],
      viewPermission: [],
      token: this.$route.params.token,
      duplicateTheads: [],
      deletename: "",
      allreferencepagespinner: true,
      allreferencepagetable: false,
      delete_modal_active: false,
      searchtabledata: [],
      success: false,
      success_msg: "",
      notification: {},
      searchvalue: "",
      duplicate_table_data: [],
      id: "tenant1",
      name: "myself",
      deletedialog: false,
      deleterowindex: 0,
      contextmenu: [
        {
          label: "View",
          icon: "icon-eye-regular",
        },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ],
      editflowbreadcrumb: [
        { label: "reference_data", link: "", icon: "" },
        { label: "Edit data", link: "" },
      ],
      editflowtimeline: [
        {
          title: "Data information",
          description: "Sample description",
          subtitle: "",
          active: false,
          visited: true,
        },
        {
          title: "Edit the data",
          description: "Sample description",
          subtitle: "",
          active: false,
          visited: false,
        },
      ],
      show_all_reference: true,
      manual_edit_data: false,
      theads: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: true,
          prop: "name",
          label: "Name",
          sortable: true,
          resizable: true,
          width: null,
        },
        {
          name: "Record count",
          checked: true,
          id: 3,
          disabled: true,
          prop: "dataCount",
          label: "Record count",
          sortable: true,
          resizable: true,
          width: 150,
        },
        {
          name: "Description",
          checked: true,
          id: 3,
          disabled: false,
          prop: "description",
          label: "Description",
          sortable: true,
          resizable: true,
          width: 320,
        },
        {
          name: "Last modified",
          checked: true,
          id: 3,
          disabled: false,
          prop: "last_visited",
          label: "Last modified",
          sortable: true,
          resizable: true,
          width: 220,
        },
      ],
      theadsC: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: true,
          prop: "name",
          label: "Name",
          sortable: true,
          resizable: true,
          width: null,
        },
        {
          name: "Record count",
          checked: true,
          id: 3,
          disabled: true,
          prop: "dataCount",
          label: "Record count",
          sortable: true,
          resizable: true,
          width: 150,
        },
        {
          name: "Description",
          checked: true,
          id: 3,
          disabled: false,
          prop: "description",
          label: "Description",
          sortable: true,
          resizable: true,
          width: 320,
        },
        {
          name: "Last modified",
          checked: true,
          id: 3,
          disabled: false,
          prop: "last_visited",
          label: "Last modified",
          sortable: true,
          resizable: true,
          width: 220,
        },
      ],
      tableData: [],
      currPage: 1,
      rowsPerPage: 10,
      re_arrange: false,
      headvalue: [],
    };
  },
  computed: {
    paginatedData() {
      return this.tableData;
      // const start = (this.currPage - 1) * this.rowsPerPage;
      // const end = start + this.rowsPerPage;
      // return this.tableData.slice(start, end);
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.tableData.length;
    },
  },
  async mounted() {
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");

let sampleTheadsd = await MLTAxiosInstance.get(
  `partyRole/get/${val.data.userId}`
);

const mergedPayload = sampleTheadsd.data.permission;


this.permisstionData =
mergedPayload.DMN.child["Data management"]

//     let sampleTheads = await MLTAxiosInstance.get(`/settings`);

// if (sampleTheads?.data?.entityRef?.["dataManagement"]) {
//   let offerTableHeadModal = sampleTheads.data.entityRef["dataManagement"];
//   let offerTableHeadOrg = sampleTheads.data.entityRef["dataManagement"].filter(ele => ele.checked);
//   console.log(offerTableHeadOrg,"offerTableHeadModal",offerTableHeadModal)

//   if (
//     offerTableHeadModal.length ===  this.theadsC.length 
//     // &&
//     // offerTableHeadModal.every((item, index) => item.name ===  this.theadsC[index].name)
//   ) {
//      this.theadsC = JSON.parse(JSON.stringify(offerTableHeadModal));
//        this.theads = JSON.parse(JSON.stringify(offerTableHeadOrg));
//        this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.theadsC ))
//        this.tableHeaderOrg = JSON.parse(JSON.stringify(this.theads))
//   }
// }
    // this.duplicateTheads = this.theads;
    this.headvalue = JSON.parse(JSON.stringify(this.theads));
    // const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    this.company = val.data.company;
    // const a = await DMNAxiosInstance.get(`mapRef/dashboard`)
    // console.log(a,"👻")
    //   if(res.data.ok == 0) {
    //     this.tableData = []
    //     return
    //   }
    //   this.tableData = res.data.data;
    // });
    // this.tableData = this.tableData.sort(
    //   (a, b) => new Date(b.last_visited) - new Date(a.last_visited)
    // );
    //  this.tableData = data
    document.addEventListener("click", (event) => {
      if (!event.target.classList.contains("icon-more-vertical-filled")) {
        if (!event.target.classList.contains("icon-more-vertical-filled")) {
          this.tableData.forEach((e) => {
            if (e.context !== undefined) {
              e.context = false;
            }
          });
        }
      }
    });
    // this.tableData.forEach((e)=>{
    //   e.context = false
    // })
    this.referenceDataCalling(this.currPage, this.rowsPerPage);
    this.duplicate_table_data = this.tableData;
    this.searchtabledata = this.tableData;
    //
  },
  methods: {
    computedTableAction() {
      if (this.permisstionData.delete && this.permisstionData.edit) {
        return [
        {
          label: "View",
          icon: "icon-eye-regular",
        },
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }  else if (this.permisstionData.delete ) {
        return [
          
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }  else {
        return [
        {
          label: "View",
          icon: "icon-eye-regular",
        },
        ];
      }
      // return [
      //   {
      //     label: "Edit",
      //     icon: "icon-file-text-edit-regular",
      //   },
      //   {
      //     label: "Delete",
      //     icon: "icon-trash-regular",
      //   },
      // ];
    },
    reAssignOrgTableHeader(){
            // If there is change in the table header then ressign to the Orginal data
      if((this.theadsC != this.tableHeaderOrgcc) && this.tableHeaderOrgcc.length > 0)
    {
      this.theadsC = JSON.parse(JSON.stringify(this.tableHeaderOrgcc))
    }
    if((this.theads != this.tableHeaderOrg) && this.tableHeaderOrg.length > 0)
    {
      this.theads = JSON.parse(JSON.stringify(this.tableHeaderOrg))
    }
      this.re_arrange = true
    }, 
    resizedWidth(val){
      console.log(val,"resizedWidth",val)

      const updateWidth = (array) => {
    let item = array.find(i => i?.prop === val?.prop);
    if (item) item.width = val.width;
  };

  updateWidth(this.theads);
  updateWidth(this.theadsC);
console.log(this.theads,"resizedWidth loop",this.theadsC)
this.applyColumns(this.theadsC)
    },
    sorting_function(data, data1) {
      console.log(data, data1);
      this.referencequery.type = "filter";
      if (data === "icon-angle-up-small-filled sorting-icon") {
        this.referencequery.isNeedToBeSort = true;
        let obj = {};
        obj[data1] = 1;
        this.referencequery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.referenceDataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled sorting-icon") {
        this.referencequery.isNeedToBeSort = true;
        let obj = {};
        obj[data1] = -1;
        this.referencequery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.referenceDataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-up-small-filled") {
        this.referencequery.isNeedToBeSort = false;
        let obj = {};
        this.referencequery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.referenceDataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled") {
        this.referencequery.isNeedToBeSort = false;
        let obj = {};
        this.referencequery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.referenceDataCalling(this.currPage, this.rowsPerPage);
      }
    },
    async referenceDataCalling(schemaCurrentPage, schemaRowsPerPage) {
      this.referencequery["paginatedQuery"]["currentPage"] = schemaCurrentPage;
      this.referencequery["company"] = this.company;
      this.referencequery["paginatedQuery"]["rowsPerPage"] = schemaRowsPerPage;
      let filterResultData = await DMNAxiosInstance.post(
        `util/filterData`,
        this.referencequery
      );
      console.log(
        "🚀 ~ referenceDataCalling ~ filterResultData:",
        filterResultData
      );
      // filterResultData = filterResultData.data

      if (filterResultData.data.ok == 1) {
        filterResultData = filterResultData.data;
        this.totalPageNo = filterResultData.data.total;
        this.tableData = filterResultData.data.data;
        this.duplicate_table_data = this.tableData;
      }
      console.log(this.tableData, "data");
    },
    convertdateformat(date1) {
      if (date1 != null) {
        let objectDate = new Date(date1);
        if (isNaN(objectDate) == false) {
          let date = objectDate.toLocaleString("default", { day: "numeric" });
          let month = objectDate.toLocaleString("default", { month: "short" });
          let year = objectDate.getFullYear();
          let dateformat = month + " " + date + ", " + year;
          return dateformat;
        }
      }
    },
    tooltipover(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis(data1, index1);
    },
    tooltipleave(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses(data1, index1);
    },
    ellipsis(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              let height = tooltip.offsetHeight;
              let top = element1.getBoundingClientRect().top - height;
              let left = element1.getBoundingClientRect().left;
              if (tooltip.style) {
                tooltip.style.top = top + "px";
                tooltip.style.left = left + "px";
                tooltip.style.display = "block";
              }
              return true;
            }
          }
          return false;
        }
      });
    },
    ellipses(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              if (tooltip.style) {
                tooltip.style.display = "none";
              }
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },
   async applyColumns(val) {
      console.log("applyColumns",val)
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
let concatedEntity = sampleTheads?.data?.entityRef || {};

concatedEntity.dataManagement = val;

let reArrangeTableHeadPayload = { entityRef: concatedEntity };
 
const changeTableHeader = await MLTAxiosInstance.patch(`/settings`, reArrangeTableHeadPayload);
console.log(changeTableHeader, "reArrangeTableHeadPayload", reArrangeTableHeadPayload);

let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
console.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
this.theadsC = entityRef["dataManagement"];
this.theads = this.theadsC.filter(ele => ele.checked);
this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.theadsC ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.theads))
    },
    arrange(val) {
      //
      this.re_arrange = val;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return new Intl.DateTimeFormat("en-US", options)
        .format(date)
        .replace(",", "");
    },
    navTo(active) {
      this.$store.commit("token", this.$route.params.token);

      if (active.name == "Home") {
        this.navHome();
      } else if (active.name == "logout") {
        this.logout();
      } else if (active.name == "settings") {
        this.setting();
      } else if (active.name == "Schema") {
        this.schema();
      } else if (active.name == "Business rule") {
        this.ruled();
      } else if (active.name === "Database") {
        this.execute();
      } else if (active.name == "Help") {
        this.help();
      } else if (active.name == "Execute") {
        this.exe();
      } else {
        this.audit();
        //
      }
    },
    audit() {
      this.$router.push(`/DMN/audit`);
    },
    help() {
      window.open("https://documentation.halleyx.com");
    },
    ruled() {
      this.$router.push(`/DMN/business_rules`);
    },
    exe() {
      this.$router.push(`/DMN/rule_validation`);
    },
    execute() {
      this.$router.push(`/reference_data`);
    },
    schema() {
      this.$router.push(`/schemas`);
    },

    logout() {
      this.$router.push("/");
    },
    setting() {
      this.$router.push(`/settings`);
    },
    async navHome() {
      this.$router.push(`/DMN/dashboard`);
    },
    // search(val)
    // {
    //
    //         this.searchvalue = val
    //     if (this.searchvalue && this.searchvalue != '') {
    //         this.tableData =  this.searchtabledata.filter((item)=>{
    //            return item.name.toLowerCase().toString().includes(this.searchvalue) || item.count.toString().includes(this.searchvalue) || item.description.toString().includes(this.searchvalue.toLowerCase()) || item.last_visited.toString().includes(this.searchvalue.toLowerCase())
    //          })
    //          this.duplicate_table_data = this.tableData
    //     } else {
    //       this.tableData = this.searchtabledata
    //       this.duplicate_table_data = this.searchtabledata
    //     }
    // },
    search(val) {
      this.searchvalue = val;
      if (val != "") {
        this.referencequery["type"] = "search";
      } else {
        this.referencequery["type"] = "filter";
      }
      this.referencequery["searchQuery"] = this.searchvalue;
      this.currPage = 1;
      // this.rowsPerPage = 10;
      this.referenceDataCalling(this.currPage, this.rowsPerPage);
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    currentPage(val) {
      //
      if (this.company != "") {
        this.currPage = val;
        this.referenceDataCalling(this.currPage, this.rowsPerPage);
        console.log(this.tableData);
      }
    },
    changeRowsPerPage(count) {
      if (this.company != "") {
        this.rowsPerPage = count;
        this.referenceDataCalling(this.currPage, this.rowsPerPage);
      }
    },
    editdata(referencedata) {
      // this.$router.push({ path: `/DMN/reference_data/edit/${referencedata}/` })
      this.$router.push(`/reference_data/edit/${referencedata}`);

      // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/reference_data/edit/${referencedata} `;

      this.show_all_reference = false;
      this.manual_edit_data = true;
    },
    display(ele, index) {
      //
      this.closeindex = index;

      if (ele.context === false) {
        ele.context = true;
      }
      //
    },
    crudContextMenu($event, index) {
      if (document.querySelector("#table-context" + index) != undefined) {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 94;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
      }
      if ($event.target.className === "icon-more-vertical-filled") {
        if (this.tableData[index].context != undefined) {
          if (this.tableData[index].context == false) {
            this.tableData[index].context = true;
          } else {
            this.tableData[index].context = false;
          }
          console.log(this.tableData[index].context);
        } else {
          this.tableData.forEach((e) => {
            e.context = false;
          });
          this.tableData[index].context = true;
        }
      }
    },
    closeCrudContextMenu(e, ele, index) {
      // ele.context = false;
      //  this.tableData[index].context = false
      //
      console.log(e, ele, index);
      if (e === "View") {
        console.log("aa");
        this.$router.push(`/reference_data/edit/${ele.name}`);

        // this.$router.push(`/DMN/reference_data/edit/${ele.name}`);

        // this.$router.push({ path: `/DMN/reference_data/edit/${ele.name}` })
        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/reference_data/edit/${ele.name} `;
      } else if (e === "Delete") {
        // this.tableData[index].context = false
        ele.context = false;
        this.deletename = ele.name;
        this.deletedialog = true;
        this.deleterowindex = index;
      }
    },
    async deleteokmodal() {
      setTimeout(() => {
        this.allreferencepagespinner = false;
        this.allreferencepagetable = true;
      }, 2000);
      await DMNAxiosInstance.delete(`mapRef/deleteall/${this.deletename}`);
      this.currPage = 1;
      this.referenceDataCalling(this.currPage, this.rowsPerPage);
      // await DMNAxiosInstance.get(`mapRef/dashboard`).then((res) => {

      //   this.tableData = res.data;
      // });
      // //  this.tableData = data
      // this.tableData.forEach((e) => {
      //   e.context = false;
      // });
      // this.duplicate_table_data = this.tableData;
      // this.searchtabledata = this.tableData;
      //
      // this.tableData.splice(this.deleterowindex-1,1)
      this.deletedialog = false;
    },
  },

  sorting_Data(data, data1) {
    console.log(data, data1);
    if (data === "icon-angle-up-small-filled sorting-icon") {
      this.referencequery.type = "filter";
      this.referencequery.isNeedToBeSort = true;
      console.log(data1, "data1");
      if (data1 === "name") {
        this.referencequery.sortFieldAndOrder = { name: 1 };
      } else if (data1 == "description") {
        this.referencequery.sortFieldAndOrder = { description: 1 };
      } else if (data1 == "last_visited") {
        this.referencequery.sortFieldAndOrder = { last_visited: 1 };
      }
      this.currPage = 1;
      this.referenceDataCalling(this.currPage, this.rowsPerPage);
    } else if (data === "icon-angle-down-small-filled sorting-icon") {
      this.referencequery.type = "filter";
      this.referencequery.isNeedToBeSort = true;
      if (data1 === "name") {
        this.referencequery.sortFieldAndOrder = { name: -1 };
      } else if (data1 == "description") {
        this.referencequery.sortFieldAndOrder = { description: -1 };
      } else if (data1 == "last_visited") {
        this.referencequery.sortFieldAndOrder = { last_visited: -1 };
      }
      this.currPage = 1;
      this.referenceDataCalling(this.currPage, this.rowsPerPage);
    } else if (data === "icon-angle-up-small-filled") {
      this.referencequery.type = "filter";
      this.referencequery.isNeedToBeSort = false;
      this.referencequery.sortFieldAndOrder = {};
      this.currPage = 1;
      this.referenceDataCalling(this.currPage, this.rowsPerPage);
    } else if (data === "icon-angle-down-small-filled") {
      this.referencequery.type = "filter";
      this.referencequery.isNeedToBeSort = false;
      this.referencequery.sortFieldAndOrder = {};
      this.currPage = 1;
      this.referenceDataCalling(this.currPage, this.rowsPerPage);
    }
  },
  formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    };
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  },
};
</script>

<style></style>
