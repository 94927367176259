<template>
    <div>
      <GMapMap
        :center="{ lat: lat, lng: lng }"
        :zoom="10"
        style="width: 100%; height: 500px"
      >
        <GMapMarker
          :position="{ lat: lat, lng: lng }"
          :draggable="true"
          @dragend="handleDragEnd"
        />
      </GMapMap>
  
      <div>
        <label>Latitude: <input v-model.number="lat" placeholder="latitude" @input="updatePosition" /></label>
      </div>
      <div>
        <label>Longitude: <input v-model.number="lng" placeholder="longitude" @input="updatePosition" /></label>
      </div>
  
      <div>Centre: {{ mapCenter }}</div>
      <div>Pin: {{ marker }}</div>
    </div>
  </template>
  
  <script>
  import { GMapMap, GMapMarker } from '@fawmi/vue-google-maps';
  
  export default {
    name: 'MapComponent',
    components: {
      GMapMap,
      GMapMarker,
    },
    data() {
      return {
        lat: -25.344,
        lng: 131.036,   
      };
    },
    computed: {
      mapCenter() {
        return { lat: this.lat, lng: this.lng };
      },
      marker() {
        return { position: { lat: this.lat, lng: this.lng } };
      },
    },
    methods: {
      handleDragEnd(event) {
        const newPosition = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
        this.lat = newPosition.lat;
        this.lng = newPosition.lng;
      },
      updatePosition() {
        // No additional logic needed here as v-model bindings handle it
      },
    },
  };
  </script>
  
  <style>
  #map {
    height: 500px;
    width: 100%;
  }
  </style>
  