<template>
  <section :style="{width:versionHistoryListWidth,height:versionHistoryListHeight,overflow:'auto'}">
    <h2
 style="font-size: 16px; font-family: OpenSans;margin-left: 4px;padding: 18px;border-bottom: 1px solid #D4D4D4;position:fixed;background-color:#fff;width:100%">Version history</h2>
    <section v-for="(i,index) in versionHistory" :key="index" :style="{marginTop:index==0?'68px':'0px'}" class="version-details-cover" @click="emitClicked(i,index)">
        <section class="version-details">
            <span style="font-weight: bolder;">
                {{"V" +i?.version + "-"}}
            </span>
        <span>
            {{getProperDate(i.updatedAt)}}
        </span>
        <span v-if="index===0" class="version-description">
            Current version 
{{getProperDateForRestoredInfo(versionRestoredInfo)}}
        </span>
        <section class="version-user-name-details">
            <i class="icon-user-alt-regular"></i>
            <span class="version-user-name">developerHalleyx</span>
        </section>
    </section>
    <section   v-if="index!=0" :data-tooltip="'Restore'" class="version-reload">
        <i class="icon-rotate-right-regular" @click.stop="restoreQuote(i,index,getProperDate(i.updatedAt))"></i>
    </section>
    </section>
  </section>
 

</template>

<script>
export default {
    props:{
        versionHistoryList:{
        type:Array,
        default:()=>{
            return []
        }
    },
    componentWidth:{
      type:String,
      default:'280px'
    },
    componentHeight:{
      type:String,
      default:'100vh'
    },
    },
    emit:["emit-selected-version","restore-quote"],
emits: ['emit-selected-version', 'restore-quote'],
data(){
    return{
        versionHistoryListWidth:"",
        versionHistoryListHeight:"",
        versionRestoredInfo:"",
        versionHistory:[],
    }
},
watch:{
    versionHistoryList:{
        handler(val){
            console.log("versionHistoryList",val)
            this.versionHistory=val
           this.getVersionRestoredDetsils(val)
        },
        immediate: true,
        deep: true, 
    },
    componentWidth:{
      handler(val){
            this.versionHistoryListWidth=val
        },
        immediate: true,
        deep: true,
    },
    componentHeight:{
      handler(val){
            this.versionHistoryListHeight=val
        },
        immediate: true,
        deep: true,
    },
},
methods:{
    restoreQuote(data,index,timeStamp){
        this.$emit("restore-quote",data.id,index,timeStamp)
        console.log(index,"restoreQuote is clicked")
    },
    emitClicked(val,index){
console.log(index,"emitClicked",val)
this.$emit("emit-selected-version",val)
    },
    getProperDateForRestoredInfo(val){
        if(val){
    const options = {
  month: 'short',
  day: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true
};

const formattedDate = new Date(val).toLocaleString('en-US', options);
return `- Restored from ${formattedDate}`
}
else{
    return
}
    },
getProperDate(val){
    if(val){
    const options = {
  month: 'short',
  day: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true
};

const formattedDate = new Date(val).toLocaleString('en-US', options);
return formattedDate
}
else{
    return
}
},
getVersionRestoredDetsils(val){
    if(val){
    console.log(val[0],"note",val)
    let data=val[0];
if(data){
        const note = data.note.find(item => item && item["@type"] === "RestoredFrom");
        if (note) {
            this.versionRestoredInfo = note?.value?.updatedAt;
            console.log(note, "note");
        } else {
            this.versionRestoredInfo=""
            console.log("No note found with '@type': 'Note'");
        }
    }
    }
}
},
}
</script>

<style lang="scss">
.version-details-cover{
    padding-left: 12px;
    display:flex;
    align-items:center;
    font-family: OpenSans;
    // border-left:2px solid white;
    font-size:14px;
 .version-details{
    margin-top:12px;
    display:block;
    width:200px;
    .version-description
    {
        display:block;
        color: #838383;
        font-size:12px;
        margin: 6px 12px 6px 0;
    }
    .version-user-name-details{
        margin: 6px 12px 24px 0;
        .version-user-name {
            margin-left: 12px;
        }
    }
 }
 .version-reload{
    display: none;
color:#54BD95;
 }
}
.version-details-cover:hover{
    padding-left: 10px;
    background-color: #D9D9D926;
    border-left:2px solid #54BD95;
    cursor:pointer;
        .version-reload{
            display: block;
        }
}
</style>