<template>
  <hlx-alert-notification
    :show-alert="alertmsg"
    :notify="notification"
    :auto-close="true"
  />
  <section class="importContainer">
    <section
      class="header"
      style="display: flex; justify-content: space-between"
    >
      <div class="new-layout-header">
        <span class="schema-header-title">Import lead data</span>
      </div>
      <div class="new-layout-header right">
        <div class="layout-footer" style="display: flex; align-items: center">
          <div
            class="go-back"
            style="
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            <hlx-button class="secondary sm" @click="cancelBack"
              >Back</hlx-button
            >
            <hlx-button
              class="primary sm"
              style="margin-left: 20px"
              @click="importdatamodal1"
              >Import</hlx-button
            >
          </div>
        </div>
      </div>
    </section>

    <div class="modal-content-test" style="margin-top: 24px">
      <div class="new-layout-header" style="display: flex; margin-bottom: 16px">
        <span
          class="schema-header-title"
          style="font-size: 14px; margin-bottom: 8px; margin-right: 16px"
          >Choose template</span
        >
        <span class="subtitle" style="width: 70%">
          <hlx-select
            v-model:value="template"
            :inline-search="true"
            :pre-value="template"
            :label-animation="false"
            :placeholder-value="'Template'"
            :options="templateList"
            :label="'value'"
            :prop-value="'value'"
            @selected-value="getTemplate($event)"
          >
          </hlx-select
        ></span>
      </div>

      <hlx-file-upload
        :key="importDataFileUploadKey"
        :file-type="'.xlsx, .csv'"
        :file-size-limit="'20mb'"
        :custom-icon="icon"
        :type="'regular'"
        @native-data="newEmit"
      />



      <hlx-import-progress
       v-if="isLoading && progress != 100"
    :tool-tip="true"
    :progress="progress"
    :stroke-width="20"
  />

      <div
        
        class="new-layout-header"
      >
        <div v-if="progress == 100" class="subtitle" style="font-size: 14px; margin-bottom: 8px">
          <div style="margin-top: 20px; margin-bottom: 20px">
          <span
          
            >You are importing
            {{ importData.length }} records</span
          >
          <span v-if="insights.length > 0" style="color: #fc3232"
            >, but {{ insights.length }} failed due to following errors</span
          >
        </div>
          <hlx-table
          v-if="errorTableData > 0 || insights.length > 0"
            :column-count="2"
            :border="['table', 'header', 'horizontal']"
            :bold-headers="false"
            :row-hover="true"
            theme="grey"
            :striped-rows="false"
          >
            <template #thead>
              <hlx-table-head
                v-for="(i, index) in errorTableHeaders"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :fixed="i.fixed"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
              >
                {{ i.label }}</hlx-table-head
              >

              <hlx-table-head :align="'center'" :width="70"
                ><span></span>
              </hlx-table-head>
            </template>

            <template #tbody>
              <tr v-for="(i, index) in errorTableData" id="" :key="index">
                <hlx-table-cell
                  v-for="(j, col_index) in errorTableHeaders"
                  :key="col_index"
                  :align="j.align"
                  :fixed="j.fixed"
                >
                  <div v-if="j?.prop == 'errorPosition'">
                    <span>{{ i.errorPosition }}</span>
                  </div>
                  <div v-else-if="j?.prop == 'error'">
                    <span>{{ i.error }}</span>
                  </div>
                </hlx-table-cell>
              </tr>
            </template>
          </hlx-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {  UPCAxiosInstance } from "@/config/axiosConfig";
import VueCookies from "vue-cookies";
import axios from "axios"


export default {
  data() {
    return {
      isLoading: false, // To track loading state
      apiKey:"",
      progress: 0, // Progress value
      progressInterval: null,
      notification: [],
      importData: [],
      insights: [],
      errorTableData: [],
      templateList: [],
      templateId: "",
      importDataModal: false,
      errorTableHeaders: [
        {
          name: "Error position",
          checked: true,
          id: 1,
          disabled: true,
          prop: "errorPosition",
          label: "Error position",
          sortable: false,
          resizable: false,
          width: 200,
          align: "left",
        },
        {
          name: "Error",
          checked: true,
          id: 2,
          disabled: true,
          prop: "error",
          label: "Error",
          sortable: false,
          resizable: false,
          width: 1200,
          align: "left",
        },
      ],
    };
  },
  async mounted() {
    let settings = await UPCAxiosInstance.get("/importTemplate");
    this.templateList = settings.data.filter(
      (i) => i.module.type == "saleslead"
    );
    this.templateList.map((i) => {
      i.value = i.module.name;
      i.name = i.value;
    });
    this.interval = setInterval(() => {
      if (this.progress < 75) {
        this.progress += 2; // Increment progress
      } else {
        this.progress -= 5; // Simulate slowdown
      }
    }, 200);

    // Wait for API response
    this.newEmit()
      .then(() => {
        // When the API responds, set progress to 100
        this.progress = 100;

        // Clear the interval to stop the simulation
        clearInterval(this.interval);
      })
      .catch((error) => {
        console.error("Failed to fetch API data:", error);
        // Optionally handle API error
        clearInterval(this.interval);
      });
      this.apiKey = await axios.get(
        `https://appbackend-ghubgwxrcq-pd.a.run.app/partymanagement/individual/getAPIKey`,
        {
          headers: {
            Authorization: "Bearer " + VueCookies.get("token"),
          },
        }
      );

  },
  methods: {
    beforeDestroy() {
    // Clean up interval when component is destroyed
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
    cancelBack() {
      this.$router.back();
    },
    getTemplate(val) {
      this.templateId = val._id;
    },
    async importdatamodal1() {
      await UPCAxiosInstance.post(`salesLead`, this.importData);
      this.notification = {
        type: "success",
        message:
          this.loadStaticData("LANG-0025") ||
          "Leads are imported successfully!.",
      };
      this.importDataModal = false;
      try {
        const response = await UPCAxiosInstance.get(`/salesLead`);
        this.tableData = response?.data || [];
        this.tableData = this.tableData?.filter(
          (ele) => ele?.externalReference?.length > 0 || ele.numberOfUnits
        );
        // console.log(response,"this.tableData",this.tableData)
      } catch (error) {
        console.error("Error fetching salesLead data:", error);
        this.tableData = [];
      }
    },
    async newEmit(event) {
      const file = event.target.files[0]; // Get the uploaded file from the input
      if (!file) {
        console.error("No file selected.");
        return;
      }

      const formData = new FormData();
      formData.append("file", file, file.name);
      this.isLoading = true;
      this.startProgressSimulation();
      try {
        let res = await axios.post(
          `https://dmnbackend-ghubgwxrcq-pd.a.run.app/import/map_data/?type=saleslead&id=${this.templateId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "hlx-api-key": this.apiKey.data.data
            
            },
          }
        );
        this.stopProgressSimulation();
        this.progress = 100;

        if (res.data.ok == 1) {
          this.importData = res.data.data.map((i) => i.saleslead);
          this.insights = res.data.insights;
          this.insights.map((i) => {
              let obj = {};
              obj["errorPosition"] = i.recordIndex;
              const joinedSentence = i.missingFields.reduce((sentence, item, index) => {
    const csvHeaderPart = item.csvHeader ? `, ${item.csvHeader}` : '';
    const segment = `${item.systemField}${csvHeaderPart} - ${item.message}`;
    return index === 0 ? segment : `${sentence}; ${segment}`;
}, '');

obj["error"] = joinedSentence
              this.errorTableData.push(obj);
          });
        }
      } catch (error) {
        console.error(
          "Error uploading file:",
          error.response?.data || error.message
        );
      }
    },
    openImport() {
      this.importDataModal = true;
    },
    startProgressSimulation() {
      this.progress = 0; // Reset progress
      this.progressInterval = setInterval(() => {
        if (this.progress < 75) {
          this.progress += 2; // Increment progress until 75%
        }
      }, 200);
    },
    stopProgressSimulation() {
      if (this.progressInterval) {
        clearInterval(this.progressInterval);
        this.progressInterval = null;
      }
    },
  },
};
</script>

<style lang="scss">
.to-style-modal {
  .modal-backdrop1 {
    .hlx-modal-container {
      height: 600px !important;
      overflow-y: auto;
    }
  }
}
.importContainer {
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  .header {
    .new-layout-header {
      .subtitle {
        font-family: "OpenSans";
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
}
</style>
