<template>
  <div>
    <GMapMap
      :center="mapCenter1"
      :zoom="zoom"
      style="width: 100%; height: 180px"
    >
      <GMapMarker 
      v-if="mapCenter1 && polygoncheck" 
      :position="mapCenter1"
      :draggable="true"
      @dragend="handleDragEnd( $event)"      
      />
      <GMapPolygon
        :paths="polygonPaths"
        :options="{ fillColor: '#FF0000', fillOpacity: 0.1, strokeWeight: 1, strokeColor:'#FF0000' }"
      />
      
      <!-- <GMapCircle
                    :center="mapCenter1"
                    :radius="radiuscircle"
                    :options="circleOptions"
                  /> -->
    </GMapMap>
  </div>
</template>

<script>
import { GMapMap, GMapMarker, GMapPolygon,  } from "@fawmi/vue-google-maps";


export default {
  components: {
    GMapMap,
    GMapMarker,
    GMapPolygon,
    
  },
  props: {
    radiuscircle1: {
      type: Number,
    },
    
    mapCenter: {
      type: Object,
      required: true,
      default(){
        return {lat:37.7749,lng:-122.4194}
      }
    },

    polygonpath:{
      type:Array,
      default: () => [],
    }
  },

  emits:[
    "mapCenter"
  ],

  data() {
    return {
      radiuscircle:0,
      mapCenter1: { lat: 37.7749, lng: -122.4194 }, // Default to San Francisco
      zoom: 5,
      autocomplete: null,
      isLoading: true,
      polygonPaths:[
  { lat: 6.7511, lng: 77.0650 },
  { lat: 6.7511, lng: 92.0000 },
  { lat: 37.0902, lng: 92.0000 },
  { lat: 37.0902, lng: 77.0650 }
],
      polygoncheck: true
    };
  },

  computed:{
    
    circleOptions() {
      return {
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      };
    },
  },

  watch:{
    mapCenter: {
      async handler(val) {
        console.log(val, "mapcentre watching.........");
        if(val.lat && val.lng){
        this.mapCenter1 = val;
      }
      },
      immediate: true,
      deep: true,
    },
    radiuscircle1: {
      async handler(val) {
        console.log(val, "radiuscircle watching");
        // if(val>0){
          this.radiuscircle = val;
        // }
        // if(val.lat && val.lng){
        // this.mapCenter1 = val;
      // }
      },
      immediate: true,
      deep: true,
    },
    polygonpath:{
      handler(val){
        console.log("this.polygonPaths.....",val);
        this.polygonPaths = val; 
      },
      immediate: true,
      deep: true,

    }
  },
  mounted() {
    console.log("wsedfghjm..................");
  },

  methods:{
    
    handleDragEnd(event) {
      console.log("event........",event);
      
      this.mapCenter1 = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };

      this.$emit("mapCenter",this.mapCenter1)
    },
    
  }

};
</script>