<template>
  <div>
    <hlx-modal
        :modal-active="modal_active3"
        :height="'500px'"
        :width="'580px'"
        :modal="true"
        @close="modal_active3 = false"
      >
        <template #header>
          <div id="header">Change plan</div>
        </template>
        <template #content>
          <div>asif</div>
        </template>
        <template #footer>
          <hlx-button class="secondary-success">Cancel</hlx-button>
          <hlx-button class="primary sm" @click="reset()">Change</hlx-button>
        </template>
      </hlx-modal>
    <div class="common_single_header_selfservice">
      <div class="head_selfservice">My Services</div>
      <div class="body_selfservice">
        This is your hub for managing all your subscribed plans and equipment.
      </div>
    </div>

    <div
      class="util-bar"
      style="display: flex; justify-content: space-between; margin: 20px 15px 15px 15px;" 
    >
      <span class="switch-container">
        <hlx-switch
          v-model="selectedSwitch"
          :switch-items="switchOptions"
          value-prop="value"
          display-prop="name"
          @change="chosenSwitch"
        />
      </span>
      <span style="display: flex; gap: 16px">
        <hlx-search @search-key="cardSearch"></hlx-search>
        <span class="filter-table">
          <hlx-button class="secondary sm add-btn" @click="showDrawerRight">
            <i class="icon-filter-regular"></i>
            <span v-if="filtertaglength != 0" class="filter-count">{{
              filtertaglength
            }}</span>
          </hlx-button>
        </span>
      </span>
    </div>

    <div>
      <section v-if="tableData.length > 0">
        <!-- table -->
        <section>
          <div style="padding-left: 15px; padding-right: 15px">
            <hlx-table
              :column-count="tableHeader.length"
              :border="['table', 'header', 'horizontal']"
              :bold-headers="false"
              :row-hover="true"
              theme="grey"
              :striped-rows="false"
            >
              <template #thead>
                <hlx-table-head
                  :width="50"
                  :min-width="50"
                  :align="'center'"
                  style="padding: 0 15px; min-width: 50px; max-width: 50px"
                  :fixed="'left'"
                >
                  {{ "S.no" }}</hlx-table-head
                >

                <hlx-table-head
                  v-for="(header, index) in tableHeader"
                  :key="index"
                  :width="header.width"
                  :sortable="header.sortable"
                  :resizable="header.resizable"
                  >{{ header.label }}
                </hlx-table-head>
                <hlx-table-head
                  :width="50"
                  :fixed="'right'"
                  style="max-width: 60px !important"
                  ><span class="action-icon">
                    <i class="icon-settings-regular"></i></span
                ></hlx-table-head>
              </template>

              <template #tbody>
                <tr
                  v-for="(i, index) in tableDataOnSelectedRows"
                  id=""
                  :key="index"
                >
                  <hlx-table-cell :align="'center'" :fixed="'left'">
                    {{ serialNumberAlter(index) }}
                  </hlx-table-cell>
                  <hlx-table-cell
                    v-for="(j, col_index) in tableHeader"
                    :key="col_index"
                    :align="j.align"
                    :fixed="j.fixed"
                  >
                    <div v-if="j.prop === 'id'">
                      <div
                        style="padding-top: 5px; padding-left: 0px"
                        @click="openuploaddraweredit"
                      >
                        <div
                          style="cursor: pointer; color: rgb(0, 101, 178, 1)"
                          class="blue_link"
                        >
                          {{ i[j.prop] }}
                        </div>
                      </div>
                    </div>
                    <div v-else-if="j.prop.toLowerCase() === 'name'">
                    <div style="">{{ i[j.prop] }}</div>
                    <div style="font-family: 'opensans';font-size: 10px;font-weight:400px;">
                        
                            <div class="small_text">Download speed: 900Mbps</div>
                            <div class="small_text">Upload speed: 900Mbps</div>
                            <div class="small_text">Undivmited usage</div>
                            <div class="small_text">18-month contract</div>
                        
                    </div>
                    </div>
                    <div v-else-if="j.prop.toLowerCase() === 'status'">
                      <div>
                        <hlx-status :type="i[j.prop]"></hlx-status>
                      </div>
                    </div>
                    <div v-else>
                      {{ i[j.prop] }}
                    </div>
                  </hlx-table-cell>
                  <hlx-table-cell
                    :align="'center'"
                    :width="120"
                    :fixed="'right'"
                  >
                    <span :id="'table-context' + index" class="action-icon">
                      <i
                        style="position: relative"
                        class="icon-more-vertical-filled"
                        :class="i.context === true ? 'active-action' : ''"
                        @click="crudContextMenu($event, index)"
                      >
                        <hlx-context-menu
                          :top="containertop"
                          :left="containerleft"
                          :conditionvalue="150"
                          :conditiondown="2"
                          :conditionup="-38"
                          :options="computedTableAction(i)"
                          :data="index.toString()"
                          :show="i.context == true"
                          @chosen="closeCrudContextMenu($event, i, index)"
                        />
                      </i>
                    </span>
                  </hlx-table-cell>
                </tr>
              </template>
            </hlx-table>
          </div>
        </section>
        <!-- Pagination -->
        <div
          v-if="tableData.length > 0"
          class="additional-charges-pagination-container"
          style="padding: 1rem 0"
        >
          <hlx-pagination
            v-model:rows-per-page="externalRowsPerPage"
            :total="tableData.length"
            enable-rows-per-page
            :rows-per-page-list="[10, 15, 20]"
            :options="{
              attributes: {
                rowsPerPageTitle: true,
                rowsPerPageResult: true,
                rowsPerPageDropdown: {
                  position: {
                    top: 0,
                    right: 1,
                  },
                },
              },
            }"
            @current-page="currentPage"
            @updated:rows-per-page="changeRowsPerPage"
          ></hlx-pagination>
        </div>
      </section>

      <!-- without tabledata -->
      <section
        v-if="tableData.length <= 0"
        style="
          margin-top: 3.2rem;
          display: flex;
          flex-direction: column;
          gap: 1.2rem;
          align-items: center;
        "
      >
        <img
          src="@/assets/images/addAccountAttachments.svg"
          alt=""
          style="width: 500px"
        />

        <span class="util-items util-items-add">
          <hlx-button class="primary media add-btn" @click="openuploaddrawer"
            ><i class="icon-plus-circle-regular" style="margin-right: 5px"></i
            >Add Image upload</hlx-button
          >
        </span>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filtertaglength: 0,
      selectedSwitch: "internet",
      switchOptions: [
        {
          name: "Internet",
          value: "internet",
        },
        {
          name: "Equipment",
          value: "equipment",
        },
      ],
      tableHeader: [
        {
          label: "Id",
          prop: "id",
          sortable: false,
          resizable: false,
          width: 200,
          align: "left",
        },
        {
          label: "Name",
          prop: "name",
          sortable: false,
          resizable: false,
          width: 200,
          align: "left",
        },
        {
          label: "Status",
          prop: "status",
          sortable: false,
          resizable: false, 
          width: 200,
          align: "left",
        },
        {
          label: "Price (GBP)",
          prop: "price",
          sortable: false,
          resizable: false,
          width: 200,
          align: "left",
        },
        {
          label: "Contract validity",
          prop: "validity",
          sortable: false,
          resizable: false,
          width: 200,
          align: "left",
        },
        {
          label: "Remaining contract period",
          prop: "remaining",
          sortable: false,
          resizable: false, 
          width: 200,
          align: "left",
        },
      ],
      tableData: [
        {
          id: 74937,
          name: "HomeFiber Ultra 900",
          status: "Active",
          price: "£35/month",
          validity: "Apr 02 ,2024 - Apr 02 ,2026",
          remaining: "24 months",
        },
        // {
        //   id: 2,
        //   name: "Product B",
        //   status: "Inactive",
        //   price: 15.99,
        //   validity: "6 months",
        //   remaining: 50,
        // },
        // {
        //   id: 3,
        //   name: "Product C",
        //   status: "Active",
        //   price: 20.99,
        //   validity: "2 years",
        //   remaining: 200,
        // },
      ],
      currPage: 1,
      rowsPerPage: 10,
      externalRowsPerPage: 10,
    };
  },
  computed: {
    tableDataOnSelectedRows() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
  },
  methods:{
    currentPage(val) {
      this.currPage = val;
    },
    changeRowsPerPage(count) {
      this.rowsPerPage = count;
    },
    
    serialNumberAlter(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    computedTableAction(data) {
      let options = [
        // {
        //   label: "Edit",
        //   icon: "icon-file-text-edit-regular",
        // },
        {
          label: "Change plan",
          icon: "icon-wifi-regular",
        },
      ];
      return options.filter((option) => {
        if (
          data &&
          data.status &&
          data.status.toLowerCase() == "draft" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "active" &&
          ["Draft", "Mark as active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "inactive" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else {
          return true;
        }
      });
    },
    crudContextMenu($event, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    closeCrudContextMenu($event, ele, i) {
      if ($event.toLowerCase() === "edit") {
        this.editMode = true;
        this.show_upload_drawer = true;
      } else if ($event.toLowerCase() === "delete") {
        this.delete_modal_active = true;
        this.deleteobj = ele;
        this.delete_name = ele.name;
        this.deleterowindex = this.serialNumber(i);
      }
    },
    serialNumber(index) {
      return (this.offeringcurrPage - 1) * this.offeringrowsPerPage + index + 1;
    },
  }
};
</script>

<style lang="scss">
.common_single_header_selfservice {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
  margin: 15px;
  .head_selfservice {
    font-family: "quicksand";
    font-weight: 700;
    font-size: 18px;
  }
  .body_selfservice {
    font-family: "opensans";
    font-weight: 300;
    font-size: 13px;
  }
}
.small_text{
    font-family: 'opensans';
    font-size: 10px;
    font-weight:400px;
    padding:1px;
}

</style>
