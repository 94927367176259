<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <section style="display: flex; flex-direction: column">
    <main
      class="decision-add-container --layout-3-way"
      style="overflow-y: hidden !important; height: 100vh"
      :style="
        isRightLayoutOpened
          ? 'display: grid;grid-template-columns: 300px calc(100% - 600px) 300px'
          : ''
      "
    >
      <!-- -------------------------------------------Left pannel-------------------------------------------------- -->
      <section
        class="cm-fields-left-panel responsiveSM"
        style="background-color: transparent; overflow-y: hidden; width: 220px"
      >
        <!-- Breadcrumb -->
        <div class="layout-breadcrumb">
          <hlx-breadcrumb
          :label-value=staticData?.back?.label[loginUserDetails]?.label
            v-if="view === true"
            :items="breadCrumbItemsView"
            @path="goTo()"
          />
          <hlx-breadcrumb
          :label-value=staticData?.back?.label[loginUserDetails]?.label
            v-if="view === false"
            :items="breadCrumbItems"
            @path="goTo()"
          />
        </div>
        <span style="margin-top: 25px">
          <leftPanel
            :side-bar-data="leftPanelData"
            @value-name="selectedValueFunc"
          ></leftPanel>
        </span>
      </section>

      <!-- ------------------------------------------Centre pannel------------------------------------------------- -->
      <section
        class="--layout-inner-right-portion --RL70 responsiveSM-right-portion"
        style="padding: 1.8em 35px"
        :style="
          isRightLayoutOpened === false
            ? 'width:calc(100% - 220px)'
            : 'width:100%'
        "
      >
        <div
          class="decision-info-right-pannel"
          style="overflow: hidden; height: 100%"
        >
          <div class="layout-breadcrumb expanded-side-panel">
            <hlx-breadcrumb
            :label-value=staticData?.back?.label[loginUserDetails]?.label
              v-if="view === true"
              :items="getBreadcrumbs"
              @path="goTo()"
            />
            <hlx-breadcrumb
            :label-value=staticData?.back?.label[loginUserDetails]?.label
              v-if="view === false"
              :items="getBreadcrumbs"
              @path="goTo()"
            />
          </div>
          <!-- <hlx-switch
            v-model="selectedValue"
            :switch-items="tabItems"
            :tab-view="true"
            value-prop="value"
            display-prop="name"
            @change="handleCurrentChange"
          /> -->
          <div class="body-container-center" style="margin-top: 0px">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <span class="expanded-side-panel" style="margin-right: 25px">
                <leftPanel
                  :side-bar-data="leftPanelData"
                  @value-name="selectedValueFunc"
                ></leftPanel>
              </span>
              <div style="display: flex; align-items: center; gap: 15px">
                <!-- <img :src="profilePicUrl" alt="" srcset=""> -->
                <span>
                  <hlx-file-upload
                    :key="profileComponentKey"
                    :file-type="'.jpg,.jpeg,.png'"
                    :file-size-limit="'20mb'"
                    :uploadtype="'Rectangle'"
                    :style-background="backgrounStyle"
                    :height="50"
                    :width="50"
                    :read-only="true"
                    :custom-icon="icon"
                    :type="'profile-upload'"
                    :profile-pic-preview="profilePicUrl"
                    :name-display="payload.name"
                    :content="'Import'"
                    @native-data="profileUpload"
                  />
                </span>

                <div style="display: flex; flex-direction: column; gap: 4px">
                  <div style="display: flex; align-items: center; gap: 12px">
                    <span style="font-weight: bold">{{
                      payload.firstName + " " + payload.lastName
                    }}</span>
                    <hlx-label
                      v-if="payload?.status?.length > 0"
                      class="sm"
                      :class="{
  'primary': payload.status === 'Active',
  'error': payload.status === 'Inactive',
  'warning': payload.status === 'Pending'
}"
                      >{{ payload.status }}</hlx-label
                    >
                  </div>
                  <div
                    style="color: #a6a6a6; font-size: 12px; width: max-content"
                  >
                    {{ addressPreVal?.characteristic?.description }}
                  </div>
                </div>
              </div>

              <!-- <div
                v-if="selectedValue.toLowerCase() !== 'overview'"
                style="display: flex; align-items: center; gap: 16px"
              >
                <span>
                  <hlx-file-upload
                    :key="profileComponentKey"
                    :file-type="'.jpg,.jpeg,.png'"
                    :file-size-limit="'20mb'"
                    :uploadtype="'Rectangle'"
                    :style-background="backgrounStyle"
                    :height="50"
                    :width="50"
                    :read-only="true"
                    :custom-icon="icon"
                    :type="'profile-upload'"
                    :profile-pic-preview="profilePicUrl"
                    :name-display="payload.name"
                    :content="'Import'"
                    @native-data="profileUpload"
                  />
                </span>

                <div style="display: flex; flex-direction: column; gap: 4px">
                  <div style="display: flex; align-items: center; gap: 12px">
                    <span
                      style="
                        font-weight: bold;
                        width: max-content;
                        text-wrap: nowrap;
                      "
                      >{{ payload.firstName + " " + payload.lastName }}</span
                    >
                    <hlx-label
                      v-if="payload.status.length > 0"
                      class="primary sm"
                      >{{ payload.status }}</hlx-label
                    >
                  </div>
                  <div
                    style="color: #a6a6a6; font-size: 12px; width: max-content"
                  >
                    {{ payload?.contactMedium[2]?.description }}
                  </div>
                </div>
              </div> -->
              <span
                v-if="selectedValue.toLowerCase() === 'overview'"
                style="font-weight: bold; display: flex; width: 100%"
              >
                <!-- {{ "Hi, " + payload.firstName + " " + payload.lastName }} -->
              </span>
              <div
                v-if="view && selectedValue.toLowerCase() === 'overview'"
                class="go-back"
                style="
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                "
              >
                <hlx-button
                  class="primary sm"
                  style="margin-left: 20px"
                  @click="view = false"
                  >
                  <!-- Edit -->
                  <span v-if="staticData">{{ staticData?.edit?.label[loginUserDetails]?.label }}</span>
                  </hlx-button
                >
              </div>
              <div
                v-if="!view && selectedValue.toLowerCase() === 'overview'"
                class="go-back"
                style="
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                "
              >
                <hlx-button class="secondary sm" @click="cancelEditAccount"
                  >
                  <!-- Cancel -->
                  <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
                  </hlx-button
                >
                <hlx-button
                  class="primary sm"
                  :disabled="
                    payload.firstName != '' &&
                    validateEmail(
                      payload.contactMedium[0].characteristic.emailAddress
                    ) &&
                    payload.role != '' &&
                    validatePhoneNumber(
                      payload.contactMedium[1].characteristic.phoneNumber
                    )
                      ? false
                      : true
                  "
                  style="margin-left: 20px"
                  @click="editContactData"
                  >
                  <!-- Save -->
                  <span v-if="staticData">{{ staticData?.save?.label[loginUserDetails]?.label }}</span>
                  </hlx-button
                >
              </div>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'overview'"
              class="cm-accounts-overview-component-container"
            >
              <div style="display: flex; gap: 5%; padding-top: 20px">
                <div class="decision-info-left-pannel" style="width: 47.5%">
                  <!-- <span
                  v-if="view"
                  class="title decision-title section-title"
                  style="margin-top: 0.5rem"
                  >View account</span
                >
                <span
                  v-if="!view"
                  class="title decision-title section-title"
                  style="margin-top: 0.5rem"
                  >Edit account</span
                > -->
                  <!-- <hlx-file-upload
                  :file-type="'.png,.jpeg,.jpg'"
                  :file-size-limit="'20mb'"
                  :custom-icon="icon"
                  :type="'upc-regular'"
                  @file-data="imgDataPrimary"
                /> -->
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      gap: 10px;
                    "
                  >
                    <span>
                      <hlx-file-upload
                        :key="profileComponentKey"
                        :file-type="'.jpg,.jpeg,.png'"
                        :file-size-limit="'20mb'"
                        :uploadtype="'Rectangle'"
                        :style-background="backgrounStyle"
                        :height="93"
                        :width="93"
                        :read-only="view"
                        :custom-icon="icon"
                        :profile-pic-preview="profilePicUrl"
                        :type="'profile-upload'"
                        :name-display="payload.name"
                        :content="'Import'"
                        @native-data="profileUpload"
                        @delete-image="deleteProfilePicture"
                      />
                    </span>
                    <span style="width: 100%">
                      <div
                        class="decision-name-wrapper"
                        style="margin-bottom: 14px"
                      >
                        <hlx-input
                          v-model:value="payload.firstName"
                          :pre-val="payload.firstName"
                          :label-value="'First name'"
                          :label-animation="true"
                          :required="true"
                          :read-only="view"
                          :display-error="customnameerror"
                          :custom-error="customnameerror"
                          :custom-error-message="'Please fill the field'"
                          @focus-out="focusOutName"
                          @at-input="validateName"
                        ></hlx-input>
                      </div>
                      <div
                        class="decision-name-wrapper"
                        :style="{
                          'margin-bottom': customnameerror ? '-7.6%' : 0,
                        }"
                      >
                        <hlx-input
                          v-model:value="payload.lastName"
                          :pre-val="payload.lastName"
                          :label-value="'Last name'"
                          :label-animation="true"
                          :required="false"
                          :read-only="view"
                          :display-error="customnameerror"
                          :custom-error="customnameerror"
                          :custom-error-message="'Please fill the field'"
                          @focus-out="focusOutName"
                          @at-input="validateName"
                        ></hlx-input>
                      </div>
                    </span>
                  </div>
                  <!-- {{ addressPreVal }} -->
                  <!-- {{ addressPreVal.characteristic.address1 }} -->
                  <div
                    class="decision-name-wrapper"
                    :style="{
                      'margin-bottom': customnameerror ? '-7.6%' : 0,
                    }"
                  >
                 
                      <hlx-status
                        :type="'select-status'"
                        :status-option="filteredstatus"
                        :prevalue="payload.status"
                        :readonly="view"
                        @emit-select="emitStatus1"
                      >
                      </hlx-status>
                 
                  </div>
                  <addressComponent
                    :pre-val="addressPreVal"
                    :view="view"
                    @address-data="receivedAddressData"
                  ></addressComponent>
                  <!-- <div class="decision-name-wrapper">
                    <hlx-input
                      v-model:value="payload.contactMedium[2].description"
                      :pre-val="payload.contactMedium[2].description"
                      type="location"
                      :params="locationApi"
                      :label-value="'Address'"
                      :label-animation="true"
                      :display-error="customnameerror"
                      :custom-error="customnameerror"
                      :show-new-address-option="showNewAddressOption"
                      :read-only="view"
                      :custom-error-message="'Please fill the field'"
                      :location-data-prop="locationData"
                      @focus-out="focusOutName"
                      @at-input="getLocations"
                      @add-new-location="addNewLocation"
                    ></hlx-input>
                  </div> -->

                  <!-- <div class="decision-name-wrapper">
                  <hlx-select
                    v-model="payload.partyCharacteristic[1].value"
                    :pre-value="payload.partyCharacteristic[1].value"
                    :options="industryTypeList"
                    prop-value="name"
                    :placeholder-value="'Industry'"
                    :disabled="view"
                    :label-animation="true"
                    :clearable="true"
                    :inline-search="true"
                    :search-image-tag="false"
                    @search-value="newValFromDropDown($event, 'industry')"
                  ></hlx-select>
                </div>
                <div class="decision-name-wrapper">
                  <hlx-select
                    v-model="payload.partyCharacteristic[0].value"
                    :pre-value="payload.partyCharacteristic[0].value"
                    :options="employeeCountList"
                    prop-value="name"
                    :placeholder-value="'Employees'"
                    :disabled="view"
                    :label-animation="true"
                    :clearable="true"
                    :inline-search="true"
                    :search-image-tag="false"
                    @search-value="newValFromDropDown($event, 'employeeCount')"
                  ></hlx-select>
                </div>
                <div class="decision-name-wrapper">
                  <hlx-select
                    v-model="payload.contactMedium[3]['@type']"
                    :pre-value="payload.contactMedium[3]['@type']"
                    :options="locationTypeList"
                    prop-value="name"
                    :placeholder-value="'Location type'"
                    :disabled="view"
                    :label-animation="true"
                    :clearable="true"
                    :inline-search="true"
                    :search-image-tag="false"
                    @search-value="newValFromDropDown($event, 'locationType')"
                  ></hlx-select>
                </div> -->
                </div>
                <div
                  class="decision-info-left-panel"
                  style="display: flex; flex-direction: column; width: 47.5%"
                >
                  <div
                    class="decision-name-wrapper"
                    style="margin-bottom: 1rem"
                  >
                    <!-- <hlx-select
                      v-model="payload.status"
                      :pre-value="payload.status"
                      :options="statusList"
                      :disabled="view"
                      prop-value="name"
                      :placeholder-value="'Status'"
                      :label-animation="true"
                      :clearable="true"
                      :inline-search="true"
                      :search-image-tag="false"
                      @search-value="newValFromDropDown($event, 'status')"
                    ></hlx-select> -->

                    <div style="width: 100%">
                  <hlx-input
                    v-model:value="payload.description"
                    :pre-val="payload.description"
                    type="textarea"
                    :label-value="'Description'"
                    :required="false"
                    :label-animation="true"
                    :display-error="false"
                    :custom-error="customnameerror"
                    :rows="7"
                    :read-only="view"
                    :custom-error-message="'Please fill the field'"
                    @focus-out="focusOutName"
                    @at-input="validateName"
                  ></hlx-input>
                    </div>
                  </div>
                
                  <div
                    class="decision-name-wrapper"
                    style="margin-bottom: 1rem"
                  >
                    <hlx-select
                      v-model:native="payload.preferred"
                      :pre-value="payload.preferred"
                      :options="preferredMediumList"
                      :inline-search="true"
                      :prop-value="'value'"
                      :label="'name'"
                      :placeholder-value="'Preferred contact medium'"
                      :label-animation="true"
                      :clearable="true"
                      :disabled="view"
                    ></hlx-select>
                  </div>
                  <div class="decision-name-wrapper">
                    <hlx-input
                      v-model:value="
                        payload.contactMedium[1].characteristic.phoneNumber
                      "
                      :pre-val="
                        payload?.contactMedium[1]?.characteristic?.phoneNumber
                          ?.value
                      "
                      :country-code="
                        payload?.contactMedium[1]?.characteristic?.phoneNumber
                          ?.country
                      "
                      type="phonenumber"
                      :read-only="view"
                      :label-value="'Phone no.'"
                      :label-animation="true"
                      :display-error="true"
                      :custom-error="customnameerror"
                      :custom-error-message="'Please fill the field'"
                      @focus-out="focusOutName"
                      @at-input="validateName"
                    ></hlx-input>
                  </div>
                  <div class="decision-name-wrapper">
                    <hlx-input
                      v-model:value="
                        payload.contactMedium[0].characteristic.emailAddress
                      "
                      :pre-val="
                        payload.contactMedium[0].characteristic.emailAddress
                      "
                      type="email"
                      :label-value="'Email id'"
                      :label-animation="true"
                      :read-only="view"
                      :required="true"
                      :display-error="true"
                      :custom-error="customnameerror"
                      :custom-error-message="'Please fill the field'"
                      @focus-out="validateEmailNew"
                      @at-input="validateName"
                    ></hlx-input>
                  </div>
                  <!-- <div class="decision-name-wrapper">
                  <hlx-input
                    v-model:value="payload.contactMedium[2].faxNumber"
                    :pre-val="payload.contactMedium[2].faxNumber"
                    :label-value="'Fax'"
                    :read-only="view"
                    :label-animation="true"
                    :display-error="customnameerror"
                    :custom-error="customnameerror"
                    :custom-error-message="'Please fill the field'"
                    @focus-out="focusOutName"
                    @at-input="validateName"
                  ></hlx-input>
                </div> -->
                  <div
                    class="decision-name-wrapper"
                    style="margin-bottom: 1.5rem"
                  >
                    <hlx-input
                      v-model:value="payload.externalReference[0].name"
                      :pre-val="payload.externalReference[0].name"
                      :label-value="'Linkedin id'"
                      :label-animation="true"
                      :read-only="view"
                      :display-error="customnameerror"
                      :custom-error="customnameerror"
                      :custom-error-message="'Please fill the field'"
                      @focus-out="focusOutName"
                      @at-input="validateName"
                    ></hlx-input>
                  </div>
                  <!-- <hlx-input
                v-model:value="payload.notes"
                :pre-val="payload.notes"
                :label-value="'Notes'"
                :label-animation="true"
                type="textarea"
                :read-only="view"
                :rows="9"
                :display-error="false"
              ></hlx-input> -->
                  <!-- <hlx-input
                    :key="dobKey"
                    v-model:value="payload.birthDate"
                    :pre-val="payload.birthDate"
                    :label-value="'Date of birth'"
                    :label-animation="true"
                    type="date"
                    :read-only="view"
                    icon="calendar-regular"
                    icon-position="right"
                    :display-error="true"
                  ></hlx-input> -->
                  <!-- <div class="decision-name-wrapper">
                  <hlx-select
                    v-model="payload.relatedParty[0].name"
                    :pre-value="payload.relatedParty[0].name"
                    :options="executivesList"
                    :placeholder-value="'Account executive'"
                    :label="'name'"
                    :prop-value="'name'"
                    :label-animation="true"
                    :disabled="view"
                    :search-image-tag="true"
                    :sub-title="'company'"
                    :tag-value="'role'"
                    :image-link="'link'"
                    :inline-search="true"
                    @selected-value="selectedExecutive"
                  ></hlx-select>
                </div>
                <div class="decision-name-wrapper">
                  <hlx-multi-select
                    :pre-value="payload.tags"
                    :options="tagsList"
                    prop-value="name"
                    :multi-select="true"
                    :placeholder="'Tags'"
                    :disabled="view"
                    :label-animation="true"
                    :clearable="true"
                    @add-data="addTagList"
                    @selected-array="receivedTags"
                  ></hlx-multi-select>
                </div>
                <div
                  class="custom-container"
                  style="border: 1px solid #d8d8d8; border-radius: 5px"
                >
                  <div
                    style="
                      display: flex;
                      gap: 10px;
                      padding-left: 10px;
                      height: 36px;
                      align-items: center;
                      font-size: 14px;
                    "
                  >
                    <b>{{ 'Exempt from tax' }}</b>
                    <hlx-input
                      v-model:value="payload.exemptFromTax"
                      type="switch"
                      :checked="payload.exemptFromTax"
                      :disabled="view"
                    ></hlx-input>
                  </div>
                  <div
                    v-if="payload.exemptFromTax"
                    style="padding: 10px; border-top: 1px solid #d8d8d8"
                  >
                    <hlx-input
                      type="checkbox"
                      :options="taxOptions"
                    >
                    </hlx-input>
                  </div>
                </div> -->
                </div>
              </div>
              <div
                v-for="(relatedParty, partIndex) in filteredPayload"
                :key="partIndex"
              >
                <span
                  style="
                    display: flex;
                    gap: 43px;
                    margin-top: 23px;
                    align-items: center;
                  "
                >
                  <hlx-select
                    v-model:value="payload.relatedParty[partIndex].organization"
                    :pre-value="payload.relatedParty[partIndex].organization"
                    :options="organizationList"
                    :required="true"
                    :inline-search="true"
                    :prop-value="'name'"
                    :label="'name'"
                    :disabled="view"
                    :placeholder-value="'Account'"
                    :label-animation="true"
                    :clearable="false"
                    
                  ></hlx-select>

                  <span
                    style="
                      width: 100%;
                      display: flex;
                      align-items: center;
                      gap: 14px;
                    "
                  >
                    <hlx-select
                      v-model:value="payload.relatedParty[partIndex].role"
                      :pre-value="payload.relatedParty[partIndex].role"
                      :options="designationList"
                      :required="true"
                      :inline-search="true"
                      :prop-value="'name'"
                      :label="'name'"
                      :disabled="view"
                      :placeholder-value="'Role'"
                      :label-animation="true"
                      :clearable="false"
                    ></hlx-select>
                    <i
                      class="icon-plus-circle-regular"
                      style="color: rgba(84, 189, 149, 1)"
                      @click="addRowVal(index)"
                    ></i>
                    <i
                      v-if="payload.relatedParty?.length > 1"
                      class="icon-trash-regular"
                      @click="deleterowValue(index)"
                    ></i>
                  </span>
                </span>
              </div>
              <!-- <overViewComponent></overViewComponent> -->
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'hierarchy'"
              class="cm-accounts-overview-component-container"
            >
              <hierarchyComponent
                :data="hierarchyData"
                :mode="view ? 'view' : 'edit'"
                module="CM"
                @updated-hierarchy="updatedHierarchy"
              ></hierarchyComponent>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'contacts'"
              class="cm-accounts-overview-component-container"
            >
              <contactComponent
                :data="contactTableData"
                :mode="view ? 'view' : 'edit'"
                @new-contact="contactData"
                @edit-contact="editContactData"
                @delete-contact="deleteContactData"
              ></contactComponent>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'interaction'"
              class="cm-accounts-overview-component-container"
            >
              <interactionComponent
                :data="interactionData"
                :module="'customer'"
                :default-to="
                  payload?.contactMedium[0]?.characteristic?.emailAddress
                "
                @initial-payload="initialPayloadInt"
                @delete-interaction-data="deleteinteraction"
                @new-interaction-payload="receivedInteractionPayload"
                @delete-interaction-index="deleteInteractionPayload"
                @edit-interaction="editInteraction"
              ></interactionComponent>
            </div>
            <div v-if="selectedValue.toLowerCase() === 'billing'" class="">
              <templateComponent></templateComponent>
            </div>
            <div v-if="selectedValue.toLowerCase() === 'products'" class="">
              <templateComponent :module="'Products'"></templateComponent>
            </div>
            <div v-if="selectedValue.toLowerCase() === 'contracts'" class="">
              <templateComponent :module="'Contracts'"></templateComponent>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'documents'"
              class="cm-accounts-overview-component-container"
            >
              <attachmentsComponent
                :user-details="userDetails"
                :data="attachmentData"
                module="CM"
                @upload-data="receivedUploadData"
                @delete-index="deleteAttachmentRow"
              ></attachmentsComponent>
            </div>
            <div v-if="selectedValue.toLowerCase() === 'tickets'" class="">
              <TicketsComponent
                :module="'contacts'"
                :company="$route"
                :data="payload"
              ></TicketsComponent>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'notes'"
              class="cm-accounts-overview-component-container"
            >
              <noteComponent
                :data="noteData"
                @added-note="newNote"
                @delete-index="deleteNote"
                @edit-note="editNote"
              ></noteComponent>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'rules'"
              class="cm-accounts-overview-component-container"
            >
              <!-- <img src="@/assets/images/addAccountContacts.svg" alt=""> -->
            </div>
            <div v-if="selectedValue.toLowerCase() === 'quotes and orders'">
              <quoteAndOrdersComponent
                module="contacts"
                :account-status="accountStatus"
                @create-quote="handleQuoteCreation"
              ></quoteAndOrdersComponent>
              <!-- <quoteAndOrdersComponent></quoteAndOrdersComponent> -->

              <!-- <placeholderTableComponent :module="'Quotes and Orders'"></placeholderTableComponent> -->
              <!-- <img
                src="@/assets/images/contract_illustration.svg"
                alt=""
                width="500px"
              /> -->
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'approvals'"
              class="cm-accounts-overview-component-container"
            >
              <approvalsComponent></approvalsComponent>
            </div>

            <div v-if="selectedValue.toLowerCase() === 'activity log'" class="">
              <activityLogComponent
                :activity-data="activityLogData"
                :totalfromprop="activitytotal"
                @activityrowpage="activityrowpage1"
                @activitycurrentpage="activitycurrentpage1"
              ></activityLogComponent>
            </div>
          </div>
        </div>
      </section>
      <!-- ---------------------------------------collapsible right panel------------------------------------------ -->
    </main>

    <!-- <div
      class="layout-footer"
      style="
        border-top: 1px solid #d8d8d8;
        padding-right: 40px;
        display: flex;
        align-items: center;
        height: 10vh;
        margin-right: -16px;
        margin-left: -16px;
      "
    >
      <div
        v-if="view"
        class="go-back"
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <hlx-button
          class="primary sm"
          style="margin-left: 20px"
          @click="view = false"
          >Edit</hlx-button
        >
      </div>
      <div
        v-if="!view"
        class="go-back"
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <hlx-button
          class="secondary sm"
          @click="view = true"
          >Cancel</hlx-button
        >
        <hlx-button
          class="primary sm"
          :disabled="payload.name === '' ? true : false"
          style="margin-left: 20px"
          @click="addNewAccount"
          >Save</hlx-button
        >
      </div>
    </div> -->
  </section>
  <hlx-modal
    :modal-active="showAddNewAdrressModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'400px'"
    :width="'650px'"
    :modal="true"
    @close="closeAddNewAdrressModal"
  >
    <template #header>
      <div>Add new address</div>
    </template>
    <template #content>
      <div
        class="modal-content add-contact-modal"
        style="
          padding: 20px;
          padding-bottom: 0;
          grid-template-columns: 38.5% 58.5%;
        "
      >
        <span class="left-section">
          <hlx-input
            v-model:value="newAddressPayload.streetNumber"
            :pre-val="newAddressPayload.streetNumber"
            :label-value="'Street number'"
            :label-animation="true"
            :required="true"
            :display-error="true"
            :custom-error="isFirstNameEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            v-model:value="newAddressPayload.unitNumber"
            :pre-val="newAddressPayload.unitNumber"
            :label-value="'Unit number'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isLastNameEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <div class="input-wrapper">
            <hlx-input
              :key="key"
              v-model:value="newAddressPayload.city"
              :pre-val="newAddressPayload.city"
              :options="designationList"
              :required="true"
              prop-value="name"
              :label-value="'City'"
              :placeholder-value="'City'"
              :label-animation="true"
              :clearable="true"
            ></hlx-input>
          </div>
          <div class="input-wrapper">
            <hlx-input
              v-model:value="newAddressPayload.postalCode"
              :pre-val="newAddressPayload.postalCode"
              :label-value="'Postal code'"
              :label-animation="true"
              :required="true"
              :display-error="true"
              :custom-error="isLastNameEmpty"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
        </span>
        <span class="right-section">
          <hlx-input
            v-model:value="newAddressPayload.streetName"
            :pre-val="newAddressPayload.streetName"
            :label-value="'Street name'"
            :label-animation="true"
            :required="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            v-model:value="newAddressPayload.unitName"
            :pre-val="newAddressPayload.unitName"
            :label-value="'Unit name'"
            :label-animation="true"
            :required="false"
            :display-error="true"
            :custom-error="customnameerror"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <div class="input-wrapper">
            <div style="display: flex; gap: 0.5rem">
              <hlx-input
                :key="key"
                v-model:value="newAddressPayload.state"
                :pre-val="newAddressPayload.state"
                :options="preferredList"
                :label-value="'State'"
                prop-value="value"
                :placeholder-value="'State'"
                :label-animation="true"
                :required="true"
                :clearable="true"
              ></hlx-input>
              <hlx-input
                :key="key"
                v-model:value="newAddressPayload.country"
                :pre-val="newAddressPayload.country"
                :options="preferredList"
                prop-value="value"
                :label-value="'Country'"
                :placeholder-value="'Country'"
                :required="true"
                :label-animation="true"
                :clearable="true"
              ></hlx-input>
            </div>
          </div>
          <hlx-input
            v-model:value="newAddressPayload.landmark"
            :pre-val="newAddressPayload.landmark"
            :label-value="'Nearby landmark'"
            :label-animation="true"
          ></hlx-input>
        </span>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="closeAddNewAdrressModal"
        >cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        :disabled="
          newAddressPayload.city === '' ||
          newAddressPayload.country === '' ||
          newAddressPayload.state === '' ||
          newAddressPayload.postalCode === '' ||
          newAddressPayload.streetNumber === '' ||
          newAddressPayload.streetName === ''
        "
        @click="addNewAddress"
        >Add</hlx-button
      >
    </template>
  </hlx-modal>

  <discardComponent
    :show="showOverviewDiscardModal"
    @stay="handleStay"
    @discard="handleDiscard"
    @close="showOverviewDiscardModal = false"
  >
  </discardComponent>
</template>

<script>
import {
  MLTAxiosInstance,
  DMNAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";

// import overViewComponent from '@/components/CM/addAccountOverviewComponent.vue';
import hierarchyComponent from "@/components/CM/addAccountHierarchyComponent.vue";
import contactComponent from "@/components/CM/addAccountContactComponent.vue";
import interactionComponent from "@/components/CM/addAccountInteractionComponent.vue";
import attachmentsComponent from "@/components/CM/addAccountAttachmentsComponent.vue";
// import quoteAndOrdersComponent from "@/components/CM/addAccountQuoteAndOrderComponent.vue";
import approvalsComponent from "@/components/CM/addAccountApprovalsComponent.vue";
import addressComponent from "../../../components/CM/autocompleteComponent.vue";
import noteComponent from "@/components/CM/addAccountNotesComponent.vue";
import activityLogComponent from "../../../components/SM/activityLogComponent.vue";
import templateComponent from "@/components/CM/placeholderTableComponent.vue";
import TicketsComponent from "@/components/CM/addAccountTicketsComponent.vue";

import leftPanel from "@/components/leftpannelComponent.vue";
// import placeholderTableComponent from '@/components/CM/placeholderTableComponent.vue';
import quoteAndOrdersComponent from "@/components/CM/addAccountQuoteAndOrderComponent.vue";

import discardComponent from "@/components/discardComponent.vue";

export default {
  components: {
    quoteAndOrdersComponent,
    templateComponent,
    TicketsComponent,
    // overViewComponent,
    activityLogComponent,
    hierarchyComponent,
    contactComponent,
    interactionComponent,
    // quoteAndOrdersComponent,
    approvalsComponent,
    leftPanel,
    addressComponent,
    attachmentsComponent,
    noteComponent,
    // placeholderTableComponent
    discardComponent,
  },
  beforeRouteLeave(to, from, next) {
    if (this.discardIt) {
      //this.discardIt will handled on discard modal window discard changes emit
      next(true);
      return;
    }
    // Check for unsaved changes
    /**
     * Param 1 initial paylaod to check against param 2
     * Param 2 updated payload to check against param 1
     */
    const unsavedChanges =
      this.isPayloadChanged(this.initialData, this.updatedData) ||
      this.newProfileAdded; //This isPayloadChanged method was configured in global methods so we can call it any where on vue.It's find a difference between both data and return true if any changes otherwise return false

    // If unsaved changes found then display discard modal
    if (unsavedChanges) {
      this.showOverviewDiscardModal = true;
    }
    // Then route as per validation
    next(!unsavedChanges);
    return;
  },
  data() {
    return {
      loginUserDetails: {},
      staticData:null,
      tempBack: false,
      initialData: {},
      updatedData: {},
      showOverviewDiscardModal: false,
      discardIt: false,
      activityLogData: [],
      organizationList: [],
      filteredstatus: [],
      profilePicUrl: "",
      rawProfileUrlData: null,
      profilePicName: "",
      profileComponentKey: 0,
      newProfileAdded: false,
      newProfileData: null,
      newProfileformData: null,
      deleteProfilePic: false,
      userDetails: {
        name: this.$route.params.companyName,
        id: this.$route.params.id,
      },
      attachmentData: [],
      noteData: [],
      addressPreVal: {
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
        },
      },
      rawPayload: {},
      activitytotal: 0,
      taxOptions: [
        {
          name: "CGST",
        },
        {
          name: "SGST",
        },
      ],
      exemptFromTax: false,
      notification: {},
      view: true,
      contactTableData: [],
      locationTypeList: [],
      employeeCountList: [],
      preferredMediumList: [],
      industryTypeList: [],
      statusList: [],
      tagsList: [],
      locationParams: {
        // apiKey: """",
        country: "CA",
        type: "address",
      },
      isRightLayoutOpened: false,
      breadCrumbItems: [
        {
          label: "Contacts",
          link: ``,
        },
        { label: "", link: "" },
      ],
      breadCrumbItemsView: [
        {
          label: "Contacts",
          link: ``,
        },
        { label: "", link: "" },
      ],
      customnameerror: false,
      payload: {
        name: "",
        aristocraticTitle: "",
        birthDate: "",
        firstName: "",
        lastName: "",
        title: "",
        tradingName: "",
        companyName: "",
        status: "Active",
        preferred: "",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                valid: true,
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedIn",
          },
        ],
        relatedParty: [
          {
            role: "",
            organization: "",
            "@type": "multiorganization",
          },
        ],
        tags: ["Active"],
        role: "",
        type: "individual",
        notes: [],
        password: "",
        email: "",
        attachment: [],
      },
      initialPayloadInteraction: {},

      contactPayload: {
        name: "",
        tradingName: "",
        companyName: "",
        status: "",
        aristocraticTitle: "",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            emailAddress: "",
          },
          {
            mediumType: "phone",
            phoneNumber: {
              value: "",
              valid: true,
              country: {
                name: this.$store.getters.defaultSetting?.country,
                short: this.$store.getters.defaultSetting?.cca2,
                flags: this.$store.getters.defaultSetting?.flags?.svg,
              },
            },
          },
          {
            mediumType: "fax",
            faxNumber: "",
          },
          {
            mediumType: "postalAddress",
            city: "",
            country: "",
            postCode: "",
            stateOrProvince: "",
            street1: "",
            street2: "",
            description: "",
            "@type": "",
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "linkedinId",
          },
        ],
        relatedParty: [
          {
            role: "director",
            partyOrPartyRole: {
              id: "42",
              name: "Feroz Khan",
              "@referredType": "Individual",
            },
          },
        ],
        tags: ["Active"],
        role: "",
        type: "individual",
        password: "",
        email: "",
      },
      selectedValue: "Overview",
      tabItems: [
        { name: "Overview", value: "Overview" },
        { name: "Hierarchy", value: "Hierarchy" },
        {
          name: "Contacts",
          value: "Contacts",
        },
        { name: "Interaction", value: "Interaction" },
        { name: "Rules", value: "Rules" },
        { name: "Quotes and orders", value: "Quotes and orders" },
        { name: "Approvals", value: "Approvals" },
      ],
      recievedContactData: [],
      showAddNewAdrressModal: false,
      locationData: [],
      newAddressPayload: {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      },
      showNewAddressOption: false,
      interactionData: {},
      leftPanelData: {},
      receivedInteractionData: {},
      hierarchyData: [],
      tempSideBar: {
        main: [
          {
            id: 1,
            name: "Overview",
            icon: "icon-presentation-regular",
            active: true,
            view: true,
          },
          //   {
          //     id: 2,
          //     name: 'Hierarchy',
          //     icon: 'icon-box-regular',
          //     active: false,
          //     view: true,
          //   },
          //   {
          //     id: 3,
          //     name: 'Contacts',
          //     icon: 'icon-user-regular',
          //     active: false,
          //     view: true,
          //   },
          {
            id: 2,
            name: "Interaction",
            icon: "icon-phone-office-regular",
            active: false,
            view: true,
          },
          {
            id: 5,
            name: "Products",
            icon: "icon-file-text-regular",
            active: false,
            view: true,
          },
          {
            id: 4,
            name: "Contracts",
            icon: "icon-file-regular",
            active: false,
            view: true,
          },
          {
            id: 3,
            name: "Quotes and orders",
            icon: "icon-menu-left-regular",
            active: false,
            view: true,
          },
          {
            id: 5,
            name: "Billing",
            icon: "icon-file-list-regular",
            active: false,
            view: true,
          },
          // {
          //   id: 7,
          //   name: 'Rules',
          //   icon: 'icon-desktop-code-regular',
          //   active: false,
          //   view: true,
          // },
          {
            id: 12,
            name: "Tickets",
            icon: "icon-coupon-regular",
            active: false,
            view: true,
          },
          {
            id: 6,
            name: "Documents",
            icon: "icon-paperclip-regular",
            active: false,
            view: true,
          },
          {
            id: 8,
            name: "Notes",
            icon: "icon-note-list-regular",
            active: false,
            view: true,
          },
          {
            id: 9,
            name: "Activity log",
            icon: "icon-time-forward-regular",
            active: false,
            view: true,
          },
        ],
      },
      company: "",
    };
  },
  computed: {
    filteredPayload() {
      return this.payload.relatedParty.filter(
        (item) => item["@type"] === "multiorganization"
      );
    },
  },
  watch: {
    statusList: {
      handler(val) {
        console.log(val, "statuswatching000000000000");
        const filteredstatuslist = this.statusList.filter(
          (item) => item.name === "Active" || item.name === "Inactive" || item.name === "Pending"
        );
        this.filteredstatus = filteredstatuslist;
        console.log("filtered11111111111111111", this.filteredstatus);
      },
      immediate: true,
      deep: true,
    },
    selectedValue: {
      handler(val) {
        if (val.toLowerCase() !== "overview") {
          this.view = false;
        } else {
          this.view = true;
        }
      },
      immediate: true,
      deep: true,
    },
    payload: {
      handler(val) {
        console.log(val, "payloaddata");
        for (let i = 0; i < val.contactMedium.length; i++) {
          if (val.contactMedium[i].mediumType === val.preferred) {
            val.contactMedium[i].preferred = true;
          } else {
            val.contactMedium[i].preferred = false;
          }
        }
        // val.companyName = val.name;
        // val.tradingName = val.name;
        if (val.contactMedium !== undefined) {
          val.email = val?.contactMedium[0]?.characteristic?.emailAddress;
        }
        console.log(val);
        //   let accExec = this.executivesList.filter(item => {
        //   return item.name === val.accountExecutive ? item : undefined
        // })
        // val.relatedParty.splice(0, 1)
        // val.relatedParty.push(accExec[0])
        // console.log(this.payload);
        // if (this.$route.query !== undefined) {
        //   this.breadCrumbItems[0].link = `/CM/accounts/view/${this.$route.params.name}/${this.$route.query.companyName}/edit`
        //   this.breadCrumbItemsView[0].link = `/CM/accounts/view/${this.$route.params.name}/${this.$route.query.companyName}/edit`
        // }
        this.breadCrumbItems[1].label = val.firstName + " " + val.lastName;
        this.breadCrumbItemsView[1].label = val.firstName + " " + val.lastName;
        this.updatedData = JSON.parse(JSON.stringify(val));
        if (val.aristocraticTitle) {
          console.log();
          this.selectedHierarchyContactFunc(val.aristocraticTitle);
        }
      },
      immediate: true,
      deep: true,
    },
    contactPayload: {
      handler(val) {
        val.company = JSON.parse(JSON.stringify(this.payload.tradingName));
        val.companyName = JSON.parse(JSON.stringify(this.payload.tradingName));
        val.tradingName = JSON.parse(JSON.stringify(this.payload.tradingName));
        val.email = val.contactMedium[0].emailAddress;
        // console.log(this.payload, this.contactPayload);
      },
      immediate: true,
      deep: true,
    },
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventPageReload);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.preventPageReload);
  },
  async mounted() {
    const value = await MLTAxiosInstance.get("partymanagement/individual/auth");

let sampleTheadsd = await MLTAxiosInstance.get(
  `partyRole/get/${value.data.userId}`
);

const mergedPayload = sampleTheadsd.data.permission;

this.permisstionData =
mergedPayload.CM.child.Contacts.sections;

this.leftPanelData = this.mergeSidebarData(
  this.tempSideBar,
  this.permisstionData
);
    await this.$store.dispatch("loginTenantDetails");
    await this.$store.dispatch("loginUserDetails");
    // loginUserDetails
    // await this.$store.dispatch("loginTenantDetails");
    // let r = await this.$store.getters.loginTenantDetails;
    console.log("loginTenantDetails resrfsef:", this.$store.getters.loginTenantDetails);
    console.log("loginUserDetails resrfsef:", this.$store.getters.loginUserDetails);

    this.loginUserDetails = this.$store?.getters?.loginUserDetails?.languageAbility[0]?.code;

    // this.loginUserDetails.languageAbility[0].name
    // this.loginUserDetails.languageAbility[0].code

    try {
      let organisationInfo = await UPCAxiosInstance.get('/multi-language');
      this.staticData = organisationInfo?.data?.filter(itm => itm && itm.modules)?.[0]?.modules;
      console.log("this.staticData:",organisationInfo , this.staticData);
      
    }
    catch (error) {
      console.error("error::", error)
    }




    if (this.$route.params.type === "view") {
      this.view = true;
    } else {
      this.view = false;
    }
    // console.log(this.$route.query, this.breadCrumbItems);
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    console.log(this.$route, "payload");
    this.company = val.data.company;
    let payload;
    console.log(this.$route, "this.$route");
    if (this.$route.query.company === undefined) {
      payload = await MLTAxiosInstance.get(
        `/partymanagement/individual/myinfo/${this.$route.params.id}?company=${this.$route.params.name}`
      );
    } else {
      payload = await MLTAxiosInstance.get(
        `/partymanagement/individual/myinfo/${this.$route.params.id}?company=${this.$route.query.company}`
      );
    }
    console.log(payload, "payload");
    // let contacts = await MLTAxiosInstance.get(
    //   `/partyManagement/individual/listuser/${this.$route.params.companyName}`
    // );
    // contacts.data.forEach((e) => {
    //   const preferredMedium = e.contactMedium.find((item) => item.preferred);
    //   e.preferred = preferredMedium ? preferredMedium.mediumType : null;
    // });
    // this.contactTableData = contacts.data;
    this.preferredMediumList = [
      { name: "Email", value: "email" },
      { name: "Phone number", value: "phone" },
    ];
    const preferredMedium = payload.data?.contactMedium?.find(
      (item) => item.preferred
    );
    console.log(preferredMedium);
    this.payload = payload.data;
    this.payload.preferred = preferredMedium
      ? preferredMedium.mediumType
      : null;
    console.log(this.payload);
    this.initialData = JSON.parse(JSON.stringify(this.payload));
    this.updatedData = JSON.parse(JSON.stringify(this.payload));
    this.addressPreVal = this.payload.contactMedium[2];

    const profileName = this.payload.attachment.filter((item) => {
      return item["@type"] !== undefined && item["@type"] === "profile"
        ? item
        : false;
    });
    console.log(profileName[0]?.name);
    if (profileName[0]?.name !== undefined) {
      this.profilePicName = profileName[0]?.name;
      const url = await UPCAxiosInstance.get(
        `/gcpupload/files/${profileName[0]?.name}`,
        { responseType: "arraybuffer" }
      );
      // console.log(new Uint8Array([url.data,"url incoming"]))
      this.rawProfileUrlData = url.data;
      this.profilePicUrl = await this.bufferToDataURL(url.data);
      console.log(this.profilePicUrl, url.data, "url.data");
    }

    this.rawPayload = JSON.parse(JSON.stringify(this.payload));
    this.attachmentData = payload.data.attachment;
    this.noteData = payload.data.notes;
    let interaction;
    if (this.$route.query.company === undefined) {
      interaction = await UPCAxiosInstance.get(
        `/partyInteraction/contactInteraction/${this.$route.params.id}?company=${this.$route.params.name}`
      );
      console.log(interaction, this.$route.params, "interaction");
      this.interactionData = interaction?.data;
    } else {
      interaction = await UPCAxiosInstance.get(
        `/partyInteraction/contactInteraction/${this.$route.params.id}?company=${this.$route.query.company}`
      );
      console.log(interaction, this.$route.params, "interaction");
      this.interactionData = interaction?.data;
    }
    // let hierarchy = await MLTAxiosInstance.get(
    //   `/hierarchy/${this.payload.rootName}`
    // );
    // // console.log(hierarchy);
    // this.hierarchyData.push(hierarchy.data);
    // let designationList = await DMNAxiosInstance.get(
    //   `mapRef/collection/cmContactsDesignation`
    // );
    this.designationList =  await this.getReferanceData('cmContactsDesignation');
    // this.payload.role = JSON.parse(JSON.stringify(this.payload.role.name));
    // let exec = await MLTAxiosInstance.get(
    //   `/partyManagement/individual/listuser`
    // );
    // this.executivesList = exec.data;

    // let statusList = await DMNAxiosInstance.get(`mapRef/collection/statuslist`);
    this.statusList =  await this.getReferanceData('statuslist');
    // let preferredList = await DMNAxiosInstance.get(
    //   `mapRef/collection/preferredContactMedium`
    // );

    // let industryTypeList = await DMNAxiosInstance.get(
    //   `mapRef/collection/industryType`
    // );
    // this.industryTypeList = industryTypeList.data.data;
    // let employeeCountList = await DMNAxiosInstance.get(
    //   `mapRef/collection/employeeCount`
    // );
    // this.employeeCountList = employeeCountList.data.data;
    // let locationTypeList = await DMNAxiosInstance.get(
    //   `mapRef/collection/locationType`
    // );
    // this.locationTypeList = locationTypeList.data.data;
    // let tagsList = await DMNAxiosInstance.get(
    //   `mapRef/collection/organizationTags`
    // );
    // this.tagsList = tagsList.data.data;
    this.selectedHierarchyContactFunc("");
    const logpayload = {
      type: "filter",
      module: "log",
      lastUpdated: new Date().toJSON(),
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {},
      limit: 10,
      filterQuery: {
        category: "customer-contact",
        ref: this.$route.params.id,
      },
      searchQuery: "",
    };
    const logaccess = await MLTAxiosInstance.post(
      `/util/filterData`,
      logpayload
    );
    this.activityLogData = logaccess.data.data;
    this.activitytotal = logaccess.data.total;
  },
  methods: {
    sanitizeDatabaseName(databaseName) {
      // Replace dots with an underscore or any other symbol you want
      const sanitized = databaseName?.replace(/\./g, "_");

      // Validate the constraints for MongoDB database names
      const validDatabaseName = sanitized?.replace(/[^a-zA-Z0-9_]/g, "");

      return validDatabaseName;
    },
    preventPageReload(event) {
      this.showOverviewDiscardModal = false;
      const unsavedChanges = this.isPayloadChanged(
        this.initialData,
        this.updatedData
      );
      if (!unsavedChanges) return;

      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
      return;
    },
    async selectedHierarchyContactFunc(val) {
      const f = {
        company: this.company,
        type: "filter-search",
        module: "hierarchyaccounts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 5,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 2,
        filterQuery: {
          "@baseType": {
            $ne: "supplier",
          },
        },
        searchQuery: val,
      };
      let data = await MLTAxiosInstance.post(`util/filterData`, f);
      console.log(data, "tfyih");
      this.organizationList = data.data.data;
    },
    handleStay() {
      // //handle Stay funtionality to hide discard component
      this.showOverviewDiscardModal = !this.showOverviewDiscardModal;
      this.tempBack = false;
    },
    handleDiscard() {
      //Handle discard functionalities like hide discard component,set discardIt as true, and push the routes where ever you want.
      if (this.tempBack) {
        this.$router.back();
      } else {
        this.$router.push(this.$store.state.tempActiveSideBarItem.link);
      }
      this.showOverviewDiscardModal = !this.showOverviewDiscardModal;
      this.discardIt = true;
    },
    validateEmail(val) {
      const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (val.length > 0) {
        if (!reg.test(val)) {
          return false; // URL is invalid
        } else {
          return true;
        }
      }
    },
    validateEmailNew() {
      console.log('hleo');
      this.isExistingEmail = false
      this.isExistingEmailErrorMessage = ''
    },
    validatePhoneNumber(val) {
      return val.valid;
    },
    cancelEditAccount() {
      this.view = true;
      this.profileComponentKey += 1;
      this.payload = JSON.parse(JSON.stringify(this.rawPayload));
      this.addressPreVal = this.payload.contactMedium[2];
      this.bufferToDataURL(this.rawProfileUrlData);
      this.newProfileAdded = false;
      this.deleteProfilePic = false;
    },
    async deleteProfilePicture() {
      console.log(this.payload.attachment, this.profilePicName, "ppp");
      this.deleteProfilePic = true;
    },
    async profileUpload(event) {
      console.log(event);
      this.newProfileAdded = true;
      this.newProfileData = event.target.files[0];
      this.newProfileformData = new FormData();

      // Append all selected files to FormData object
      this.newProfileformData.append("files", this.newProfileData);
    },
    async bufferToDataURL(buffer) {
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = "";
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },
    emitStatus1(val1) {
      this.payload.status = val1["value"];
    },
    async editNote(val) {
      this.payload.notes[val.index] = val.val;
      await this.editContactData("noNotif");
      // this.notification = {
      //   type: 'success',
      //   message: `All set! Your changes have been saved successfully!`,
      // };
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/individual/myinfo/${this.$route.params.id}?company=${this.$route.query.company}`
      );
      this.noteData = payload.data.notes;
      this.initialData = JSON.parse(JSON.stringify(this.payload));
      this.updatedData = JSON.parse(JSON.stringify(this.payload));
    },
    async newNote(val) {
      // console.log(val);
      this.payload.notes.push(val);
      await this.editContactData("noNotif");
      // this.notification = {
      //   type: 'success',
      //   message: `All set! Your changes have been saved successfully!`,
      // };
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/individual/myinfo/${this.$route.params.id}?company=${this.$route.query.company}`
      );
      this.noteData = payload.data.notes;
      this.initialData = JSON.parse(JSON.stringify(this.payload));
      this.updatedData = JSON.parse(JSON.stringify(this.payload));
    },
    async deleteNote(val) {
      // console.log(val);
      this.payload.notes.splice(val, 1);
      await this.editContactData("noNotif");
      // this.notification = {
      //   type: 'success',
      //   message: `All set! Your changes have been saved successfully!`,
      // };
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/individual/myinfo/${this.$route.params.id}?company=${this.$route.query.company}`
      );
      this.noteData = payload.data.notes;
      this.initialData = JSON.parse(JSON.stringify(this.payload));
      this.updatedData = JSON.parse(JSON.stringify(this.payload));
    },
    addRowVal() {
      this.selectedHierarchyContactFunc("");
      let valueObject = {
        role: "",
        organization: "",
        "@type": "multiorganization",
      };

      let length = this.payload.relatedParty.length;
      console.log(length, "text", this.payload.relatedParty[length - 1]);
      if (
        this.payload.relatedParty[length - 1].role &&
        this.payload.relatedParty[length - 1].organization
      ) {
        this.payload.relatedParty.push(valueObject);
      }
      console.log(this.payload.relatedParty, "this.payload.relatedParty");
    },
    deleterowValue(index) {
      this.payload.relatedParty.splice(index, 1);
    },
    async receivedUploadData(val) {
      let data = await UPCAxiosInstance.post(`/gcpMultiple/v2/upload/`, val);
      console.log(data);
      let currentPayload = data?.data?.forEach((item) => {
        let obj = {};
        obj.name = item.filename;
        obj.size = item.data.attachment.size;
        obj.url = item.url;
        obj.id = item.data._id;
        // Get the current date and time in milliseconds
        const currentTimestamp = new Date().toJSON();

        // Format the current timestamp in the same format as '1705574420667'
        console.log(currentTimestamp);
        obj.lastUpdated = currentTimestamp;
        this.payload.attachment.push(obj);
      });
      let attachmentLogPayload = {
        message: `${this.payload?.customName} attachment has been added`,
        detailedAction: [],
        action: "ADDED",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "Attachment",
        category: ["accounts"],
      };
      let attachmentLogData = this.buildLogBasedOnDifferencesForArrayOfObjects(
        [],
        currentPayload,
        "name",
        "attachment"
      );
      attachmentLogPayload.detailedAction = attachmentLogData;
      if (attachmentLogPayload.detailedAction.length > 0) {
        this.initLog(attachmentLogPayload);
      }
      console.log(this.payload);
      await this.editContactData("noNotif");
      this.notification = {
        type: "success",
        message: `All set! Your changes have been saved successfully!`,
      };
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/individual/myinfo/${this.$route.params.id}?company=${this.$route.query.company}`
      );
      if(payload){
            this.notification = {
          type: "success",
          message: `Nice work! Your new file is now in the list!`,
        };
      }
      this.attachmentData = payload.data.attachment;
      this.initialData = JSON.parse(JSON.stringify(this.payload));
      this.updatedData = JSON.parse(JSON.stringify(this.payload));
    },
    async deleteAttachmentRow(val) {
      console.log(val);
      this.payload.attachment.splice(val - 1, 1);
      await this.editContactData("noNotif");
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/individual/myinfo/${this.$route.params.id}?company=${this.$route.query.company}`
      );
      this.attachmentData = payload.data.attachment;
      this.initialData = JSON.parse(JSON.stringify(this.payload));
      this.updatedData = JSON.parse(JSON.stringify(this.payload));
    },
    receivedAddressData(val) {
      if (val.characteristic.country !== "") {
        this.payload.contactMedium[1].characteristic.phoneNumber
        .country.name = val.characteristic.country
        this.payload.contactMedium[1].characteristic.phoneNumber
        .country.flags = ""
        this.payload.contactMedium[1].characteristic.phoneNumber
        .country.short = ""
      }
      let address1 = JSON.parse(JSON.stringify(val));
      // let address2 = JSON.parse(JSON.stringify(val))
      this.payload.contactMedium[2] = JSON.parse(JSON.stringify(address1));
      // address2.mediumType = 'billingLocation'
      // this.newAccPayload.contactMedium.push(address2);
    },
    updatedHierarchy(val) {
      this.hierarchyData = val;
    },
    async selectedValueFunc(val) {
      this.selectedValue = val;
      if (
        this.selectedValue.toLowerCase() !== "overview" &&
        this.view === false
      ) {
        this.payload = JSON.parse(JSON.stringify(this.initialData));
      }
      if (val === "Activity Log") {
        const logpayload = {
          type: "filter",
          module: "log",
          lastUpdated: new Date().toJSON(),
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          sortFieldAndOrder: {},
          limit: 10,
          filterQuery: {
            category: "customer-contact",
            ref: this.$route.params.id,
          },
          searchQuery: "",
        };
        const logaccess = await MLTAxiosInstance.post(
          `/util/filterData`,
          logpayload
        );
        this.activityLogData = logaccess.data.data;
        this.activitytotal = logaccess.data.total;
      }
    },
    async activitycurrentpage1(val) {
      this.activitycurrentpage = val;
      const logpayload = {
        type: "filter",
        module: "log",
        lastUpdated: new Date().toJSON(),
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: this.activityrowpage,
          currentPage: val,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          category: {
            $in: ["customer-contact"],
          },
          ref: this.$route.params.id,
        },
        searchQuery: "",
      };
      const logaccess = await MLTAxiosInstance.post(
        `/util/filterData`,
        logpayload
      );
      this.activityLogData = logaccess.data.data;
      this.activitytotal = logaccess.data.total;
    },
    async activityrowpage1(val) {
      this.activityrowpage = val;
      const logpayload = {
        type: "filter",
        module: "log",
        lastUpdated: new Date().toJSON(),
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: val,
          currentPage: this.activitycurrentpage,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          category: {
            $in: ["customer-contact"],
          },
          ref: this.$route.params.id,
        },
        searchQuery: "",
      };
      const logaccess = await MLTAxiosInstance.post(
        `/util/filterData`,
        logpayload
      );
      this.activityLogData = logaccess.data.data;
      this.activitytotal = logaccess.data.total;
    },
    async newValFromDropDown(e, val) {
      let obj = {};
      obj.name = e;
      if (val === "status") {
        await DMNAxiosInstance.post(`mapRef/organizationStatus`, obj);
        // let statusList = await DMNAxiosInstance.get(
        //   `mapRef/collection/statuslist`
        // );
        this.statusList =  await this.getReferanceData('statuslist');
      } else if (val === "industry") {
        await DMNAxiosInstance.post(`mapRef/industryType`, obj);
        // let industryTypeList = await DMNAxiosInstance.get(
        //   `mapRef/collection/industryType`
        // );
        this.industryTypeList =  await this.getReferanceData('industryType');
      } else if (val === "employeeCount") {
        await DMNAxiosInstance.post(`mapRef/employeeCount`, obj);
        // let employeeCountList = await DMNAxiosInstance.get(
        //   `mapRef/collection/employeeCount`
        // );
        this.employeeCountList =  await this.getReferanceData('employeeCount');
      } else if (val === "locationType") {
        await DMNAxiosInstance.post(`mapRef/locationType`, obj);
        // let locationTypeList = await DMNAxiosInstance.get(
        //   `mapRef/collection/locationType`
        // );
        this.locationTypeList =  await this.getReferanceData('locationType');
      }
    },
    selectedExecutive(val) {
      this.payload.relatedParty[0] = val;
      // console.log( this.payload.relatedParty);
    },
    async getLocations() {
      const input = this.payload.contactMedium[3].description;
      let result = [];

      try {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        const response = await MLTAxiosInstance.get(`util/${input}`);
        // console.log(response);
        result = response.data;
      } catch (error) {
        console.error("Error fetching autocomplete data:", error.message);
      }
      // console.log(result);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.locationData = result;
      if (this.locationData.length === 0 && input !== "") {
        this.showNewAddressOption = true;
      } else {
        this.showNewAddressOption = false;
      }
      return result;
    },
    async addNewAddress() {
      this.showNewAddressOption = false;
      this.newAddressPayload.description =
        this.newAddressPayload.streetNumber +
        ", " +
        this.newAddressPayload.streetName +
        ", " +
        this.newAddressPayload.city +
        ", " +
        this.newAddressPayload.state +
        ", " +
        this.newAddressPayload.country +
        ", " +
        this.newAddressPayload.postalCode +
        ".";
      // console.log(this.newAddressPayload);
      let newAddress = await DMNAxiosInstance.post(
        `mapRef/geoAddress`,
        this.newAddressPayload
      );
      if (newAddress.data.ok === 1) {
        // console.log(this.newAddressPayload.description);
      }
      this.payload.contactMedium[3].description =
        this.newAddressPayload.description;
      this.showAddNewAdrressModal = false;
      const x = {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      };
      this.newAddressPayload = JSON.parse(JSON.stringify(x));
    },
    addNewLocation(val) {
      // console.log(val);
      val;
      this.showAddNewAdrressModal = true;
      this.showNewAddressOption = false;
    },
    closeAddNewAdrressModal() {
      this.showAddNewAdrressModal = false;
    },
    receivedTags(e) {
      console.log(
        "received",
        e,
        this.payload.tags.filter((item) => item !== e[0]?.name)
      );
      // this.payload.tags = [];
      // e.forEach((ele) => {
      //   if (ele.checked === true) {
      //     this.payload.tags.push(ele.name);
      //   } else {
      //     this.payload.tags = this.payload.tags.filter(
      //       (item) => item !== ele.name
      //     );
      // //   }
      // });
      this.payload.tags = e.reduce((tags, ele) => {
        if (ele.checked) {
          tags.push(ele.name);
        } else {
          tags = tags.filter((item) => item !== ele.name);
        }
        return tags;
      }, []);
    },
    async addTagList(e) {
      let obj = {};
      obj.name = e.name;
      await DMNAxiosInstance.post(`mapRef/organizationTags`, obj);
      // let tagsList = await DMNAxiosInstance.get(
      //   `mapRef/collection/organizationTags`
      // );
      this.tagsList =  await this.getReferanceData('organizationTags');
      // console.log(e);
      // this.tagsList.push(e);
    },
    async contactData(e) {
      // this.recievedContactData = [...val];
      console.log(e);
      e.id = e._id;
      e.familyName = e.name;
      e.givenName = e.name;
      e.email = e.contactMedium[0].emailAddress;
      e.company = JSON.parse(JSON.stringify(this.payload.tradingName));
      e.companyName = JSON.parse(JSON.stringify(this.payload.tradingName));
      e.tradingName = JSON.parse(JSON.stringify(this.payload.tradingName));
      // console.log(e);
      let result = await MLTAxiosInstance.post(
        `/partyManagement/individual/account/signup`,
        e
      );
      result;
      this.notification = {
        type: "success",
        message: `Nice work! Your new contact  "${e.name}"  is now in the list!`,
      };
      // console.log(this.recievedContactData);
    },
    cleanRelatedPartyPayload() {
      this.payload.relatedParty = this.payload.relatedParty.filter(
        (item) =>
          item["@type"] === "multiorganization" &&
          item.role.trim() !== "" &&
          item.organization.trim() !== ""
      );
    },
    async editContactData(notif) {
      if (this.newProfileAdded) {
        let data = await UPCAxiosInstance.post(
          `/gcpMultiple/v2/upload/`,
          this.newProfileformData
        );
        console.log(data);

        let obj = {};
        obj.name = data.data[0].filename;
        obj.size = data.data[0].data.attachment.size;
        obj.url = data.data[0].url;
        obj.id = data.data[0].data._id;
        obj["@type"] = "profile";
        // Get the current date and time in milliseconds
        const currentTimestamp = new Date().toJSON();

        // Format the current timestamp in the same format as '1705574420667'
        console.log(currentTimestamp);
        obj.lastUpdated = currentTimestamp;
        // Find the index of the 'profile' object
        let index = this.payload.attachment.findIndex(
          (obj) => obj["@type"] === "profile"
        );
        this.profilePicName = obj.name;
        const url = await UPCAxiosInstance.get(`/gcpupload/files/${obj.name}`, {
          responseType: "arraybuffer",
        });
        this.rawProfileUrlData = url.data;
        this.profilePicUrl = await this.bufferToDataURL(url.data);

        // Replace the 'profile' object with the new object if found
        if (index !== -1) {
          this.payload.attachment[index] = obj;
        } else {
          this.payload.attachment.push(obj);
        }
      }
      if (this.deleteProfilePic === true && this.newProfileAdded === false) {
        await UPCAxiosInstance.delete(
          `/gcpupload/files/${this.profilePicName}`
        );
        let index = this.payload.attachment.findIndex(
          (obj) => obj["@type"] === "profile"
        );
        if (index !== -1) {
          this.payload.attachment.splice(index, 1);
          this.rawProfileUrlData = null;
          this.profilePicUrl = "";
          this.profilePicName = "";
        }
      }
      this.newProfileAdded = false;
      this.deleteProfilePic = false;
      this.cleanRelatedPartyPayload();
      let e = JSON.parse(JSON.stringify(this.payload));
      // this.recievedContactData = [...val];
      let CM_ContactLog = {
        message: `${e.name} Overview has been updated`,
        detailedAction: [],
        "@type": "Accounts-overview",
        category: ["customer-contact"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: [this.$route.params.id],
        action: "UPDATE",
        status: "active",
      };

      let nameActionLog = this.buildBasicLogByDifferences(
        this.initialData,
        e,
        "firstName",
        "firstName"
      );
      console.log(nameActionLog, "nameActionLog");
      // lastName log
      let lastNameActionLog = this.buildBasicLogByDifferences(
        this.initialData,
        e,
        "lastName",
        "lastName"
      );
      console.log(lastNameActionLog, "lastNameActionLog");

      // aristocraticTitle log
      let aristocraticTitleActionLog = this.buildBasicLogByDifferences(
        this.initialData,
        e,
        "aristocraticTitle",
        "Designation"
      );

      console.log(aristocraticTitleActionLog, "aristocraticTitleActionLog");

      // statusActionLog log
      let statusActionLog = this.buildBasicLogByDifferences(
        this.initialData,
        e,
        "status",
        "status"
      );
      console.log(statusActionLog, "statusActionLog");

      // externalReference log
      let externalReferenceActionLog = this.buildBasicLogByDifferences(
        this.initialData.externalReference[0],
        e.externalReference[0],
        "name",
        "Linked in"
      );
      console.log(externalReferenceActionLog, "externalReferenceActionLog");

      // Address differences
      let addressActionLog = this.buildBasicLogByDifferences(
        this.initialData.contactMedium[2].characteristic,
        e.contactMedium[2].characteristic,
        "description",
        "Address"
      );
      console.log(addressActionLog, "addressActionLog");

      // email differences
      let emailaddressActionLog = this.buildBasicLogByDifferences(
        this.initialData.contactMedium[0].characteristic,
        e.contactMedium[0].characteristic,
        "emailAddress",
        "Email"
      );
      console.log(emailaddressActionLog, "emailaddressActionLog");

      // phoneNumber differences
      let phoneNumberActionLog = this.buildBasicLogByDifferences(
        this.initialData.contactMedium[1].characteristic.phoneNumber,
        e.contactMedium[1].characteristic.phoneNumber,
        "value",
        "phoneNumber"
      );
      console.log(phoneNumberActionLog, "phoneNumberActionLog");

      CM_ContactLog.detailedAction = CM_ContactLog.detailedAction.concat(
        lastNameActionLog,
        aristocraticTitleActionLog,
        externalReferenceActionLog,
        nameActionLog,
        statusActionLog,
        addressActionLog,
        emailaddressActionLog,
        phoneNumberActionLog
      );
      this.initLog(CM_ContactLog);
      console.log(e);
      e.id = e._id;
      delete e._id;
      let name = e.firstName + " " + e.lastName;
      e["familyName"] = name;
      e["givenName"] = name;
      e["name"] = name;
      e.email = e.contactMedium[0].characteristic.emailAddress;
      e.company = JSON.parse(JSON.stringify(this.payload.company));
      e.companyName = JSON.parse(JSON.stringify(this.payload.company));
      e.tradingName = JSON.parse(JSON.stringify(this.payload.company));
      e.parentCompany = JSON.parse(JSON.stringify(this.company));
      e.role = e.aristocraticTitle;
      e.lastUpdate - new Date();
      let result = await MLTAxiosInstance.patch(
        `/partymanagement/individual/?company=${this.payload.company}`,
        e
      );
      result;
      console.log("noNotif", notif);
      if (notif === "noNotif") {
        //
      } else {
        this.notification = {
          type: "success",
          message: `All set! Your changes have been saved successfully!`,
        };
      }
      this.view = true;
      let payload = {};
      if (this.$route.query.company === undefined) {
        payload = await MLTAxiosInstance.get(
          `/partymanagement/individual/myinfo/${this.$route.params.id}?company=${this.$route.params.name}`
        );
      } else {
        payload = await MLTAxiosInstance.get(
          `/partymanagement/individual/myinfo/${this.$route.params.id}?company=${this.$route.query.company}`
        );
      }
      console.log(payload);
      this.payload = payload.data;
      const preferredMedium = payload.data.contactMedium.find(
        (item) => item.preferred
      );
      console.log(preferredMedium);
      this.payload.preferred = preferredMedium
        ? preferredMedium.mediumType
        : null;
      this.addressPreVal = this.payload.contactMedium[2];
      this.initialData = JSON.parse(JSON.stringify(this.payload));
      this.updatedData = JSON.parse(JSON.stringify(this.payload));
      // this.notification = {
      //   type: 'success',
      //   message: `All set! Your changes have been saved successfully!`,
      // };
      // console.log(this.recievedContactData);
    },
    async addNewAccount() {
      console.log(this.payload);
      // console.log(this.recievedContactData);

      this.payload.id = this.payload._id;
      delete this.payload._id;
      let result = await MLTAxiosInstance.patch(
        `/partyManagement/organisation/${this.$route.params.companyName}`,
        this.payload
      );
      // console.log(result);
      if (result.data.tradingName) {
        this.notification = {
          type: "success",
          message: "All set! Your changes have been saved successfully!",
        };
        this.view = true;
      } else {
        // this.notification = {
        //   type: "danger",
        //   message: "Failed to add a new organization. Please try again.",
        // };
      }
      // this.recievedContactData.forEach(async (e) => {
      //   e.id = e._id;
      //   e.familyName = e.name;
      //   e.givenName = e.name;
      //   e.email = e.contactMedium[0].emailAddress;
      //   e.company = JSON.parse(JSON.stringify(this.payload.tradingName));
      //   e.companyName = JSON.parse(JSON.stringify(this.payload.tradingName));
      //   e.tradingName = JSON.parse(JSON.stringify(this.payload.tradingName));
      //   // console.log(e);
      //   let result = await MLTAxiosInstance.patch(
      //     `/partyManagement/individual/${this.$route.params.companyName}`,
      //     e
      //   );

      //   // console.log(result);
      //   if (result.data.token) {
      //     this.notification = {
      //       type: 'success',
      //       message: `New contact ${e.name} added successfully`,
      //     };
      //   } else {
      //     // this.notification = {
      //     //   type: "danger",
      //     //   message: `Failed to add contact for ${e.name}. Please try again.`,
      //     // };
      //   }
      // });
      // console.log(this.receivedInteractionData);
      // if (this.receivedInteractionData._id !== undefined) {
      //   this.receivedInteractionData.id = this.receivedInteractionData._id;
      //   let updatedInteraction = await UPCAxiosInstance.patch(
      //     `/partyInteraction/${this.$route.params.companyName}`,
      //     this.receivedInteractionData
      //   );
      //   updatedInteraction;
      //   // console.log(updatedInteraction);
      // } else {
      //   let updatedInteraction = await UPCAxiosInstance.post(
      //     `/partyInteraction/${this.$route.params.companyName}`,
      //     this.receivedInteractionData
      //   );
      //   updatedInteraction;
      //   // console.log(updatedInteraction);
      // }
      setTimeout(() => {
        // this.goTo();
      }, 2000);
    },
    goTo() {
      if (this.$route.query.companyId !== undefined) {
        this.$router.push(
          `/CM/accounts/view/${this.$route.params.name}/${this.$route.query.companyId}/edit/contacts`
        );
      } else {
        this.$router.push("/CM/contacts/");
      }
      this.tempBack = true;
    },
    async receivedInteractionPayload(val) {
      console.log(val);
      this.receivedInteractionData = JSON.parse(JSON.stringify(val));
      this.receivedInteractionData.relatedParty = [];
      this.receivedInteractionData.interactionItem[0].role = this.payload.role;
      this.receivedInteractionData.relatedParty.push(this.payload);
      let activeval = "";
      if (val.interactionItem[0]["@type"] === "note Log") {
        activeval = val.interactionItem[0].description;
      } else {
        activeval = val.interactionItem[0].reason;
      }
      const addedHierarchyLogPayload = {
        message: `${this.payload?.name} Interaction has been added`,
        detailedAction: [
          {
            item: activeval,
            action: "ADDED",
            property: val.interactionItem[0]["@type"],
          },
        ],
        action: "Added",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "Accounts-Interaction",
        category: ["customer-contact"],
      };
      this.initLog(addedHierarchyLogPayload);
      let updatedInteraction;

      if (this.$route.query.company === undefined) {
        updatedInteraction = await UPCAxiosInstance.post(
          `/partyInteraction/?company=${this.$route.params.name}`,
          this.receivedInteractionData
        );
        updatedInteraction;
      } else {
        updatedInteraction = await UPCAxiosInstance.post(
          `/partyInteraction/?company=${this.$route.query.company}`,
          this.receivedInteractionData
        );
        updatedInteraction;
      }
      this.notification = {
        type: "success",
        message: `Nice work! Your new log is now in the list!`,
      };
      let interaction;
      if (this.$route.query.company === undefined) {
        interaction = await UPCAxiosInstance.get(
          `/partyInteraction/contactInteraction/${this.$route.params.id}?company=${this.$route.params.name}`
        );
        console.log(interaction, this.$route.params, "interaction");
        this.interactionData = interaction?.data;
      } else {
        interaction = await UPCAxiosInstance.get(
          `/partyInteraction/contactInteraction/${this.$route.params.id}?company=${this.$route.query.company}`
        );
        console.log(interaction, this.$route.params, "interaction");
        this.interactionData = interaction?.data;
      }
      // if (this.receivedInteractionData._id !== undefined) {
      //   // this.receivedInteractionData.id = this.receivedInteractionData._id;
      //   // let updatedInteraction = await UPCAxiosInstance.patch(
      //   //   `/partyInteraction/?company=${this.$route.params.companyName}`,
      //   //   this.receivedInteractionData
      //   // );
      //   // updatedInteraction;
      //   // console.log(updatedInteraction);
      // } else {
      //   let updatedInteraction = await UPCAxiosInstance.post(
      //     `/partyInteraction/?company=${this.$route.params.companyName}`,
      //     this.receivedInteractionData
      //   );
      //   updatedInteraction;
      // console.log(updatedInteraction);
      // }
    },
    deleteinteraction(val) {
      console.log(val, "deleteinteraction");
      let activeval = "";
      if (val.interactionItem[0]["@type"] === "note Log") {
        activeval = val.interactionItem[0].description;
      } else {
        activeval = val.interactionItem[0].reason;
      }
      const addedHierarchyLogPayload = {
        message: `${this.payload?.name} Interaction has been deleted`,
        detailedAction: [
          {
            item: activeval,
            action: "REMOVED",
            property: val.interactionItem[0]["@type"],
          },
        ],
        action: "REMOVED",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "Accounts-Interaction",
        category: ["customer-contact"],
      };
      this.initLog(addedHierarchyLogPayload);
    },
    async deleteInteractionPayload(val) {
      console.log(val);
      // val.lastUpdate = new Date();
      let updatedInteraction;
      if (this.$route.query.company === undefined) {
        updatedInteraction = await UPCAxiosInstance.delete(
          `/partyInteraction/${val}/?company=${this.$route.params.name}`
        );
        updatedInteraction;
      } else {
        updatedInteraction = await UPCAxiosInstance.delete(
          `/partyInteraction/${val}/?company=${this.$route.query.company}`
        );
        updatedInteraction;
      }
      this.notification = {
        type: "success",
        message: `Your item has been removed from the log!`,
      };
      let interaction;
      if (this.$route.query.company === undefined) {
        interaction = await UPCAxiosInstance.get(
          `/partyInteraction/contactInteraction/${this.$route.params.id}?company=${this.$route.params.name}`
        );
        console.log(interaction, this.$route.params, "interaction");
        this.interactionData = interaction?.data;
      } else {
        interaction = await UPCAxiosInstance.get(
          `/partyInteraction/contactInteraction/${this.$route.params.id}?company=${this.$route.query.company}`
        );
        console.log(interaction, this.$route.params, "interaction");
        this.interactionData = interaction?.data;
      }
    },
    initialPayloadInt(val) {
      this.initialPayloadInteraction = val;
    },
    async editInteraction(val) {
      console.log(val);
      delete val._id;
      val.lastUpdate = new Date();
      val.interactionItem[0].role = this.payload.role;
      let updatedInteraction;
      let activeval = "";
      if (val.interactionItem[0]["@type"] === "note Log") {
        activeval = `${this.payload?.name} Interaction note has been updated`;
      } else {
        activeval = `${this.payload?.name} email Interaction has been updated`;
      }
      let CM_OverviewLog = {
        message: activeval,
        detailedAction: [],
        "@type": "Accounts-overview",
        category: ["customer-contact"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: [this.$route.params.id],
        action: "UPDATE",
        status: "active",
      };
      console.log("🚀 ~ saveSpec ~ CM_OverviewLog:", CM_OverviewLog);
      let contactActionLog = this.buildBasicLogByDifferences(
        this.initialPayloadInteraction?.interactionItem[0],
        val?.interactionItem[0],
        "contact",
        "contact"
      );
      console.log(contactActionLog, "descriptionActionLog");
      let creationDateActionLog = this.buildBasicLogByDifferences(
        this.initialPayloadInteraction?.interactionItem[0],
        val?.interactionItem[0],
        "creationDate",
        "Date"
      );
      console.log(creationDateActionLog, "descriptionActionLog");
      let directionActionLog = this.buildBasicLogByDifferences(
        this.initialPayloadInteraction?.interactionItem[0],
        val?.interactionItem[0],
        "direction",
        "direction"
      );
      console.log(directionActionLog, "descriptionActionLog");
      let reasonActionLog = this.buildBasicLogByDifferences(
        this.initialPayloadInteraction?.interactionItem[0],
        val?.interactionItem[0],
        "reason",
        "reason"
      );
      console.log(reasonActionLog, "descriptionActionLog");
      let descriptionActionLog = this.buildBasicLogByDifferences(
        this.initialPayloadInteraction?.interactionItem[0],
        val?.interactionItem[0],
        "description",
        "description"
      );
      console.log(descriptionActionLog, "descriptionActionLog");
      CM_OverviewLog.detailedAction = CM_OverviewLog.detailedAction.concat(
        descriptionActionLog,
        reasonActionLog,
        directionActionLog,
        creationDateActionLog,
        contactActionLog
      );
      this.initLog(CM_OverviewLog);
      if (this.$route.query.company === undefined) {
        updatedInteraction = await UPCAxiosInstance.patch(
          `/partyInteraction?company=${this.$route.params.name}`,
          val
        );
        updatedInteraction;
      } else {
        updatedInteraction = await UPCAxiosInstance.patch(
          `/partyInteraction?company=${this.$route.query.company}`,
          val
        );
        updatedInteraction;
      }
      this.notification = {
        type: "success",
        message: `All set! Your changes have been saved successfully!`,
      };
      let interaction;
      if (this.$route.query.company === undefined) {
        interaction = await UPCAxiosInstance.get(
          `/partyInteraction/contactInteraction/${this.$route.params.id}?company=${this.$route.params.name}`
        );
        console.log(interaction, this.$route.params, "interaction");
        this.interactionData = interaction?.data;
      } else {
        interaction = await UPCAxiosInstance.get(
          `/partyInteraction/contactInteraction/${this.$route.params.id}?company=${this.$route.query.company}`
        );
        console.log(interaction, this.$route.params, "interaction");
        this.interactionData = interaction?.data;
      }
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 770px) {
  .layout-breadcrumb.expanded-side-panel {
    margin-top: 12px !important;
  }
}
@media (min-width: 770px) {
  .expanded-side-panel {
    display: none;
  }
}
</style>
