<template>
  <div class="swatch_componet">
    <hlx-dropdown-color-picker
      :key="swatchKey"
      :prevalue="prevalue"
      :config="configdata"
      :options="dropdown_data1"
      @updated-value="selectedvalue"
      @search-api="searchApi"
      @searchalldata="searchalldata"
      @showoff-color="showoffColor"
      @edit-color="editColor"
      @deleteswatch="deleteswatch"
    />
  </div>
</template>

<script>
import { UPCAxiosInstance } from "@/config/axiosConfig";

export default {
  props: {
    prevalue: {
      type: String,
      default: "",
    },
    swatchKey: {
      type: Number,
      default: 0,
    },
  },
  emits: ["updated-value", "selectedvalue"],
  data() {
    return {
      APiCall: "",
      dropdown_data1: [],
      searchall: true,
    };
  },
  watch: {
    prevalue: {
       handler(val) {
       this.prevaluesearch(val)
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
  async  prevaluesearch(val){
 let hashData = val.includes("#");
          let hexapi = hashData ? val.substring(1) : val;
         
        try {
          const spec = await UPCAxiosInstance.get(
            `/swatch/search?search=${hexapi}`
          );
          this.dropdown_data1.push(spec.data[0]);
           console.log( this.dropdown_data1,".......... spec.data.................");
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      
    },
    selectedvalue(val) {
      this.$emit("selectedvalue", val);
      console.log(val, "valselectedvalue");
    },
    async deleteswatch(val) {
      console.log(val, "value");
      const spec = await UPCAxiosInstance.delete(`/swatch/${val}`);
      spec;
    },
    async editColor(id, obect) {
      const spec = await UPCAxiosInstance.patch(`/swatch/${id}`, obect);
      this.$emit("selectedvalue", spec.data);
      console.log(spec.data, "id,obect", "editswatch");
    },
    async showoffColor(obect) {
      const spec = await UPCAxiosInstance.post(`/swatch`, obect);
      console.log(spec.data, this.searchSelect, "addswatch");
      this.$emit("selectedvalue", spec.data);
      this.$emit("updated-value", spec.data);
      this.dropdown_data1.push(spec.data[0]);
    },

    async searchApi(val) {
      console.log(val, "search preval, and noemal ..............");
      this.APiCall = val;
      if (val != undefined) {
        try {
          const spec = await UPCAxiosInstance.get(
            `/swatch/search?search=${val}`
          );
          this.dropdown_data1 = spec.data;
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    },
    async searchalldata() {
      if (this.searchall) {
        const spec = await UPCAxiosInstance.get(`/swatch/search?search=`);
        this.dropdown_data1 = spec.data;
        console.log(this.dropdown_data1, "this.dropdown_data1");
      }
    },
  },
};
</script>

<style lang="scss">
.swatch_componet {
  .swatch_delete {
    z-index: 1000000 !important;
  }
}
</style>
