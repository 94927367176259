<template>
  <main v-for="user in activityLog" :key="user" class="activity-banner">
    <!-- style="min-width: 200px" -->
    <section class="banner-left-info">
      <img
        width="40"
        height="40"
        :src="user.imageURL"
        alt=""
        srcset=""
        style="min-width: 40px"
      />
    </section>
    <section class="banner-right-info">
      <span class="activity-user-name">{{ user.userName }}</span>
      <span class="recently-used">{{
        differenceBetweenCurrentDate(user.updatedAt)
      }}</span>
      <p class="activity-description">{{ user.action }}</p>
    </section>
  </main>
</template>
<script>
import { MLTAxiosInstance } from "@/config/axiosConfig";
export default {
  name: "ActivityLog",
  props:{
    module:{
      type: String,
      default: "UPC",
    }
  },
  data() {
    return {
      logData: [],
      dataupdated: [],
      offerActivity: {
        type: "filter",

        module: "log",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 5,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          category: "productOffering",
        },
        searchQuery: "",
      },
    };
  },
  computed: {
    activityLog() {
      return this.dataupdated;
    },
  },

  mounted() {
    if(this.module == 'CM'){
      this.offerActivity.filterQuery.category = "productOffering"
      this.activitydata();
    }else{
      this.activitydata();
    }

    console.log(this.$store.state.activityLog, "store.state.activityLog");
  },
  methods: {
    async activitydata() {
      let activitylogdata = await MLTAxiosInstance.post(
        `util/filterData`,
        this.offerActivity
      );
      this.logData = activitylogdata.data.data;

      const imageURL =
        "https://images.unsplash.com/photo-1560250097-0b93528c311a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80";

      this.dataupdated = this.logData.map((obj) => ({
        ...obj,
        action: obj.message,
        imageURL: imageURL,
      }));

      console.log(this.dataupdated,"this.dataupdated");
    },


    differenceBetweenCurrentDate(dateString) {
      // console.log("🚀 ~ file: ActivityLogComponent.vue:46 ~ differenceBetweenCurrentDate ~ dateString:", dateString)
      let currentDate = new Date();
      let givenDate = new Date(dateString);
      // console.log("🚀 ~ file: ActivityLogComponent.vue:48 ~ differenceBetweenCurrentDate ~ givenDate:", givenDate)

      //
      const findMonthDiff = (d1, d2) => {
        let months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
      };
      let diffOfYear,
        diffOfMonth,
        diffOfDays,
        diffOfHour,
        diffOfMinute,
        diffOfSeconds,
        recent;

      //find for seconds
      diffOfSeconds = Math.floor((currentDate - givenDate) / 1000);
      recent = diffOfSeconds + " seconds ago";
      //find for minutes
      if (diffOfSeconds > 59) {
        diffOfMinute = Math.floor((currentDate - givenDate) / 1000 / 60);
        recent = diffOfMinute + " minutes ago";
      }
      //find for hours
      if (diffOfMinute > 59) {
        diffOfHour = Math.floor(Math.abs(currentDate - givenDate) / 36e5);
        recent = diffOfHour + " hours ago";
      }

      //find for days
      if (diffOfHour > 24) {
        const diffTime = Math.abs(currentDate - givenDate);
        diffOfDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        recent = diffOfDays + " days ago";
      }
      //find for months
      if (diffOfDays > 365) {
        diffOfMonth = findMonthDiff(givenDate, currentDate);
        recent = diffOfMonth + " months ago";
      }
      //find for years
      if (diffOfMonth > 11) {
        var ynew = currentDate.getFullYear();
        var mnew = currentDate.getMonth();
        var dnew = currentDate.getDate();
        var yold = givenDate.getFullYear();
        var mold = givenDate.getMonth();
        var dold = givenDate.getDate();
        var diff = ynew - yold;
        if (mold > mnew) diff--;
        else {
          if (mold == mnew) {
            if (dold > dnew) diff--;
          }
        }
        diffOfYear = diff;
        recent = diffOfYear + " year ago";
      }
      // console.log(
      //   "🚀 ~ file: ActivityLogComponent.vue:49 ~ differenceBetweenCurrentDate ~ diffOfYear:",
      //   diffOfYear
      // // );
      return recent;
    },
  },
};
</script>
