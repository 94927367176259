<template>
    <div class="app-layout">
      <section class="right-panel">
        <section class="layout-basic">
          <div class="layout-breadcrumb">
            <hlx-breadcrumb
              :items="breadcrumb_items"
              @path="pathTo"
            />
          </div>
          <div
            class="layout-header"
            style="padding-top: 0"
          >
            <span class="add-api-header">Add email</span>
          </div>
          <div
            class="layout-body"
            style="margin-top: 0"
          >
            <div
              class="grid"
              style="margin-top: 0"
            >
              <span class="titles">
                <!-- <div style="height: 38px; margin-bottom: 22px">Schema</div> -->
                <div style="height: 38px; margin-bottom: 22px">Email name</div>
                <div>Description</div>
              </span>
              <span class="api-data-schema">
                <!-- <hlx-select
                  v-model:value="selectvalue"
                  :options="schemaOptions"
                  :placeholder-value="'Schema'"
                  :label-animation="true"
                  :clearable="true"
                  @custom-change="selectValue"
                ></hlx-select
                ><br /> -->
                <hlx-input
                  v-model:value="add_payload.name"
                  :label-animation="true"
                  :label-value="'Name'"
                ></hlx-input
                ><br />
                <hlx-input
                  v-model:value="add_payload.description"
                  :label-animation="true"
                  type="textarea"
                  :clearable="true"
                  :label-value="'Description'"
                />
              </span>
            </div>
            <div class="divider"><hr /></div>


            <div class="grid">
              <span class="titles">To</span>
              <span class="api-data-content">
                <div class="method-url">
  
                  <span style="width:100%">
                    <hlx-input
                      v-model:value="add_payload.to"
                      type="text"
                      :label-value="'To'"
                    ></hlx-input>
                  </span>
       
                </div>
              </span>
            </div>

            <div class="grid">
              <span class="titles">Cc</span>
              <span class="api-data-content">
                <div class="method-url">
  
                  <span style="width:100%">
                    <hlx-input
                      v-model:value="add_payload.cc"
                      type="text"
                      :label-value="'Cc'"
                    ></hlx-input>
                  </span>
       
                </div>
              </span>
            </div>

            <div class="grid">
              <span class="titles">Bcc</span>
              <span class="api-data-content">
                <div class="method-url">
  
                  <span style="width:100%">
                    <hlx-input
                      v-model:value="add_payload.bcc"
                      type="text"
                      :label-value="'Bcc'"
                    ></hlx-input>
                  </span>
       
                </div>
              </span>
            </div>
            <div class="divider"><hr /></div>

            <div class="grid">
              <span class="titles">Subject</span>
              <span class="api-data-content">
                <div class="method-url">
  
                  <span style="width:100%">
                    <hlx-input
                      v-model:value="add_payload.subject"
                      type="text"
                      :label-value="'Subject'"
                    ></hlx-input>
                  </span>
       
                </div>
              </span>
            </div>

            <div class="grid">
              <span class="titles">Mail body</span>
              <span class="api-data-content">
                <div class="method-url">
  
                  <span style="width:100%">
                    <hlx-switch
                    v-model="add_payload.auth"
                    value-prop="name"
                    display-prop="name"
                      :type="'regular'"
                      :switch-items="auth_switch_items"
                  @change="authSelected"
                    />
                  </span>
       
                </div>
              </span>
            </div>
  
            <div class="grid">
              <span class="titles"></span>
              <span class="api-data-content">
                <div class="method-url">
  
                  <span style="width:100%">
                    <hlx-code-editor
                  :line-number="true"
                  :beautify="false"
                  @code-changes="bodyPayload"
                ></hlx-code-editor>
                  </span>
       
                </div>
              </span>
            </div>
  

            <!-- <div class="divider"><hr /></div>


            <div class="grid">
              <span class="titles">Attachments</span>
              <span class="api-data-content">
                <div class="method-url">
  
                  <span style="width:100%">
                    <hlx-file-upload
                :file-type="'.xlsx,.png,.json'"
                :file-size-limit="'20mb'"
                :type="'regular'"
                :custom-icon="'icon-eye-regular'"
                @preview-file="previewUploadedFile"
                @file-data="fileDataReceived"
                @delete-file="abc"
              />
                  </span>
       
                </div>
              </span>
            </div> -->
    
          </div>
          <div
            class="layout-footer"
            style="
              border-top: 1px solid #d8d8d8;
              padding-right: 40px;
              margin-right: -16px;
              margin-left: -16px;
            "
          >
            <div
              class="go-back"
              style="
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
              "
            >
              <hlx-button
                class="secondary sm"
                @click="cancelApiAdd"
                >Cancel</hlx-button
              >
              <hlx-button
                class="primary sm"
                style="margin-left: 20px"
                @click="saveAPIData"
                >Add</hlx-button
              >
            </div>
          </div>
        </section>
      </section>
    </div>
  </template>
  
  <script>
  import { DMNAxiosInstance } from '@/config/axiosConfig';
  import axios from "axios"
  export default {
    data() {
      return {
        apiResponse:"",
        nav_items: [
          {
            name: 'Home',
            label: 'Home',
            icon: 'icon-grid-web-7-regular',
            description_en: 'Home',
            description_fn: '*Home',
          },
          {
            name: 'Business rule',
            label: 'Business rule ',
            icon: 'icon-code-regular',
            description_en: 'Residential business market ',
            description_fr: '*Residential business market ',
          },
          {
            name: 'Schema',
            label: 'Schema',
            icon: 'icon-desktop-code-regular',
            description_en: 'Small business markets',
            description_fr: '*Small business markets',
          },
          {
            name: 'Database',
            label: 'Database',
            icon: 'icon-database-regular',
            description_en: 'Small business markets',
            description_fr: '*Small business markets',
          },
          {
            name: 'Execute',
            label: 'Execute',
            icon: 'icon-file-settings-regular',
            description_en: 'Small business markets',
            description_fr: '*Small business markets',
            active: true,
          },
          {
            name: 'Audit',
            label: 'Audit',
            icon: 'icon-notebook-regular',
            description_en: 'Audit log',
            description_fr: '*Audit log',
          },
  
          {
            name: 'Help',
            label: 'Help',
            icon: 'icon-question-circle-regular',
            description_en: 'Setting',
            description_fr: '*Setting',
            separator: true,
          },
          {
            name: 'settings',
            label: 'settings',
            icon: 'icon-settings-regular',
            description_en: 'Setting',
            description_fr: '*Setting',
          },
          {
            name: 'logout',
            label: 'Logout',
            icon: 'icon-log-out-regular',
            description_en: 'Logout',
            description_fr: '*Logout',
          },
        ],
        response: false,
        breadcrumb_items: [
          { label: 'Email', link: '/email' },
          { label: 'Add email', link: '' },
        ],
        apiBody: '',
        response_status: '200 OK',
        response_time: '91ms',
        add_payload: {
          name: '',
          description: '',
          to: '',
          cc: '',
          bcc: '',
          subject: '',
          auth: 'Text',
          messageBody: '',
        },
        auth_switch_items: [
          { name: 'Text'},
          { name: 'HTML' }
        ],
        method_options: [
          {
            name: 'GET',
            value: 'get',
            checked: false,
          },
          {
            name: 'POST',
            value: 'post',
            checked: false
          },
          {
            name: 'PATCH',
            value: 'patch',
            checked: false
          },
          {
            name: 'DELETE',
            value: 'delete',
            checked: false
          },
        ],
        schemaOptions: [
          { name: 'quote', checked: false, disabled: false, value: 'quote' },
          { name: 'EIOP', checked: false, disabled: false, value: 'EIOP' },
        ],
      };
    },
    // watch: {
    //   add_payload: {
    //     handler(val) {
          
    //     },
    //   },
    // },
    mounted() {
      this.$store.commit('token', this.$route.params.token);
      // const textArea = document.querySelector(".prism-editor__textarea");
      // textArea.focus();
      // const handleClick = (event) => {
      //   // Check if clicked element has a class that starts with or includes "prism"
      //   const clickedElement = event.target;
      //   const elementClasses = clickedElement.classList;
      //   const hasPrismClass = Array.from(elementClasses).some(
      //     (className) =>
      //       className.startsWith("prism") || className.includes("prism")
      //   );
  
      //   // If the clicked element has a class that matches, focus on the text area
      //   if (hasPrismClass) {
      //     const textArea = document.querySelector(".prism-editor__textarea");
      //     textArea.focus();
      //   }
      // };
  
      // // Add click listener to the document
      // document.addEventListener("click", handleClick);
    },
    methods: {
      navTo(active) {
        this.$store.commit('token', this.$route.params.token);
        
        if (active.name == 'Home') {
          this.navHome();
        } else if (active.name == 'logout') {
          this.logout();
        } else if (active.name == 'settings') {
          this.setting();
        } else if (active.name == 'Schema') {
          this.schema();
        } else if (active.name == 'Business rule') {
          this.ruled();
        } else if (active.name === 'Database') {
          this.execute();
        } else if (active.name == 'Help') {
          this.help();
        } else if (active.name == 'Execute') {
          this.exe();
        } else {
          this.audit();
          // 
        }
      },
      audit() {
        this.$router.push(`/DMN/audit`);
      },
      ruled() {
        this.$router.push(`/DMN/business_rules`);
      },
      exe() {
        this.$router.push(`/DMN/data_execution`);
      },
      execute() {
        this.$router.push(`/DMN/reference_data`);
      },
      schema() {
        this.$router.push(`/DMN/schemas`);
      },
  
      // logout() {
      //   this.$router.push('/')
  
      // },
      setting() {
        this.$router.push(`/settings`);
      },
      async navHome() {
        this.$router.push(`/DMN/dashboard`);
      },
  
      scrollToEnd() {
        const scrollableDiv = document.querySelector('.layout-body');
        const lastChild = scrollableDiv.lastElementChild;
        lastChild.scrollIntoView({ behavior: 'smooth' });
      },
      pathTo() {
        
        this.$router.back();
      },
      paramKey(e, index, attr) {
        if (attr === 'key') {
          this.add_payload.params[index].key = e;
        }
        if (attr === 'value') {
          this.add_payload.params[index].value = e;
        }
      },
      addParamRow() {
        const obj = {};
        obj.key = '';
        obj.value = '';
        obj.index = '';
        this.add_payload.params.push(obj);
      },
      deleteParamRow(index) {
        this.add_payload.params.splice(index, 1);
      },
      headerKey(e, index, attr) {
        if (attr === 'key') {
          this.add_payload.headers[index].key = e;
        }
        if (attr === 'value') {
          this.add_payload.headers[index].value = e;
        }
      },
      addHeaderRow() {
        const obj = {};
        obj.key = '';
        obj.value = '';
        obj.index = '';
        this.add_payload.headers.push(obj);
      },
      deleteHeaderRow(index) {
        this.add_payload.headers.splice(index, 1);
      },
      authSelected(val) {
        this.add_payload.auth = val.name;
        // 
      },
      bodyPayload(val) {
        this.apiBody = val;
        this.add_payload.messageBody = this.apiBody;
      },
      sendAPIData() {
        this.add_payload.messageBody = this.apiBody;
        
        this.response = true;
        setTimeout(() => {
          this.scrollToEnd();
        }, 100);
      },
      cancelApiAdd() {
        this.add_payload = {
          name: '',
          description: '',
          to: '',
          cc: '',
          bcc: '',
          subject: '',
          auth: 'Text',
          messageBody: '',
        }
        this.$router.back();
      },
      async saveAPIData() {
        this.add_payload.messageBody = this.apiBody
        
        let obj = {name: this.add_payload.name, description: this.add_payload.description, emailConfig:{
        to: this.add_payload.to, cc: this.add_payload.cc, bcc: this.add_payload.bcc, subject:this.add_payload.subject,
      type:this.add_payload.auth, messageBody:this.add_payload.messageBody}}
          let arr = []
          arr.push(obj)
        await DMNAxiosInstance.post(`/email/add`, arr)
      //   .then(
      //   (res) => {
          
      //     // setTimeout(() => {
      
      //     //     this.$router.push({
      //     //       name: 'list-email',
      //     //     });

      //     //   }, 3000);
      //   }
      // );
        
      },

      async testAPI(){
        this.add_payload.requestBody = this.apiBody;
      
        // async function makeRequest(method, url, headers, params, authorization, requestBody) {
  try {
    let apiMethod = this.add_payload.method
    let apiUrl = this.add_payload.url
    let apiHeaders = {}
    if(this.add_payload.headers.length>0 && this.add_payload.headers[0].length>0){
      apiHeaders = this.add_payload.headers.reduce((result, item) => {
    result[item.key] = item.value;
    return result;
  }, {});
    }
    let apiParams = {}
    if(this.add_payload.params.length>0 && this.add_payload.params[0].length>0){
      apiParams = this.add_payload.params.reduce((result, item) => {
    result[item.key] = item.value;
    return result;
  }, {});
    }
  // let apiBody = this.add_payload.requestBody
  const startTime = new Date();
   await axios({
      method: apiMethod,
      url: apiUrl,
      headers: apiHeaders,
      params: apiParams,
      // data: apiBody,
      // ...(authorization && { headers: { Authorization: authorization } }),
    }).then((res)=>{
      const endTime = new Date();
    this.response_time = endTime - startTime;
      this.apiResponse = JSON.stringify(res.data,null,2)
      this.response = true;
      this.response_status = res.status
    })
        setTimeout(() => {
          this.scrollToEnd();
        }, 100);
  } catch (error) {
    console.error(error);
  }

      }
    },
  };
  </script>
  