<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <section style="display: flex; flex-direction: column">
    <main
      class="decision-add-container --layout-3-way"
      style="overflow-y: hidden !important; height: 100vh"
      :style="
        isRightLayoutOpened
          ? 'display: grid;grid-template-columns: 300px calc(100% - 600px) 300px'
          : ''
      "
    >
      <!-- -------------------------------------------Left pannel-------------------------------------------------- -->
      <section
        class="cm-fields-left-panel responsiveSM"
        style="background-color: transparent; overflow-y: hidden; width: 220px"
      >
        <!-- Breadcrumb -->
        <div class="layout-breadcrumb">
          <hlx-breadcrumb
            v-if="view === true"
            :label-value=staticData?.back?.label[loginUserDetails]?.label
            :items="getBreadcrumbs"
            @path="goTo()"
          />
          <hlx-breadcrumb
            v-if="view === false"
            :label-value=staticData?.back?.label[loginUserDetails]?.label
            :items="getBreadcrumbs"
            @path="goTo()"
          />
        </div>
        <span style="margin-top: 25px">
          <leftPanel
          v-if="staticData"
            :side-bar-data="leftPanelData"
            @value-name="selectedValueFunc"
          ></leftPanel>
        </span>
      </section>

      <!-- ------------------------------------------Centre pannel------------------------------------------------- -->
      <section
        class="--layout-inner-right-portion --RL70 responsiveSM-right-portion"
        style="padding: 1.8em 35px"
        :style="
          isRightLayoutOpened === false
            ? 'width:calc(100% - 220px)'
            : 'width:100%'
        "
      >
        <div class="decision-info-right-pannel">
          <div class="layout-breadcrumb expanded-side-panel">
            <hlx-breadcrumb
              v-if="view === true"
              :items="getBreadcrumbs"
              @path="goTo()"
            />
            <hlx-breadcrumb
              v-if="view === false"
              :items="getBreadcrumbs"
              @path="goTo()"
            />
          </div>
          <!-- <hlx-switch
            v-model="selectedValue"
            :switch-items="tabItems"
            :tab-view="true"
            value-prop="value"
            display-prop="name"
            @change="handleCurrentChange"
          /> -->
          <div class="body-container-center" style="height: 100%">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <span class="expanded-side-panel" style="margin-right: 25px">
                <leftPanel
                  :side-bar-data="leftPanelData"
                  @value-name="selectedValueFunc"
                ></leftPanel>
              </span>
              <div style="display: flex; align-items: center; gap: 15px">
                <!-- <img :src="profilePicUrl" alt="" srcset=""> -->
                <span>
                  <hlx-file-upload
                    :key="profileComponentKey"
                    :file-type="'.jpg,.jpeg,.png'"
                    :file-size-limit="'20mb'"
                    :uploadtype="'Rectangle'"
                    :style-background="backgrounStyle"
                    :height="50"
                    :width="50"
                    :read-only="true"
                    :custom-icon="icon"
                    :type="'profile-upload'"
                    :profile-pic-preview="profilePicUrl"
                    :name-display="payload.customName"
                    :content="'Import'"
                    @native-data="profileUpload"
                  />
                </span>

                <div style="display: flex; flex-direction: column; gap: 4px">
                  <div style="display: flex; align-items: center; gap: 12px">
                    <span style="font-weight: bold">{{
                      payload.customName
                    }}</span>
                    <hlx-label
                      v-if="payload?.status?.length > 0"
                      class="sm"
                      :class="payload.status === 'Active' ? 'primary' : 'error'"
                      >{{ payload.status }}</hlx-label
                    >
                  </div>
                  <div
                    style="color: #a6a6a6; font-size: 12px; width: max-content"
                  >
                    {{ payload?.contactMedium !== undefined ? payload?.contactMedium[3]?.characteristic.description : '' }}
                  </div>
                </div>
              </div>
              <!-- <span
                v-if="selectedValue.toLowerCase() === 'overview'"
                style="font-weight: bold; display: flex; width: 100%"
              >
                {{ 'Hi, ' + payload.name }}
              </span> -->
              <div
                v-if="view && selectedValue.toLowerCase() === 'overview'"
                class="go-back"
                style="
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                "
              >
                <hlx-button
                  v-show="accountStatus == false"
                  class="primary sm"
                  style="margin-left: 20px"
                  @click="view = false"
                  >
                  <!-- Edit -->
                  <span v-if="staticData">{{ staticData?.edit?.label[loginUserDetails]?.label }}</span>
                  </hlx-button
                >
              </div>
              <div
                v-if="!view && selectedValue.toLowerCase() === 'overview'"
                class="go-back"
                style="
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                "
              >
                <hlx-button class="secondary sm" @click="cancelEditAccount"
                  >
                  <!-- Cancel -->
                  <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
                  </hlx-button
                >
                <hlx-button
                  class="primary sm"
                  :disabled="
                    payload.name !== '' &&
                    validateEmail(
                      payload.contactMedium[0].characteristic.emailAddress
                    ) === true &&
                    validatePhoneNumber(
                      payload.contactMedium[1].characteristic.phoneNumber
                    ) &&
                    !isUrlEmpty
                      ? false
                      : true
                  "
                  style="margin-left: 20px"
                  @click="addNewAccount"
                  >
                  <!-- Save -->
                  <span v-if="staticData">{{ staticData?.save?.label[loginUserDetails]?.label }}</span>
                  </hlx-button
                >
              </div>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'overview'"
              class="cm-accounts-overview-component-container"
              style="display: flex; gap: 5%; padding-top: 20px"
            >
              <div
                class="decision-info-left-pannel"
                style="width: 47.5%; gap: 20px"
              >
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;
                  "
                >
                  <span>
                    <hlx-file-upload
                      :key="profileComponentKey"
                      :file-type="'.jpg,.jpeg,.png'"
                      :file-size-limit="'20mb'"
                      :uploadtype="'Rectangle'"
                      :style-background="backgrounStyle"
                      :height="93"
                      :width="93"
                      :read-only="view"
                      :custom-icon="icon"
                      :profile-pic-preview="profilePicUrl"
                      :type="'profile-upload'"
                      :name-display="payload.customName"
                      :content="'Import'"
                      @native-data="profileUpload"
                      @delete-image="deleteProfilePicture"
                    />
                  </span>
                  <span style="width: 100%">
                    <div
                      class="decision-name-wrapper"
                      :style="{
                        'margin-bottom': customnameerror ? '-7.6%' : 0,
                      }"
                      style="margin-bottom: 20px"
                    >
                      <hlx-input
                  v-if="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
                        v-model:value="payload.customName"
                        :pre-val="payload.customName"
                        :label-value="'Account name'"
                        :label-animation="true"
                        :required="true"
                        :read-only="view"
                        :display-error="customnameerror"
                        :custom-error="customnameerror"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
                        @focus-out="validateNameNew"
                        @at-input="validateName"
                      ></hlx-input>
                    </div>
                    <div class="decision-name-wrapper">
                      <!-- <hlx-select
                        v-model="payload.status"
                        :pre-value="payload.status"
                        :options="statusList"
                        :disabled="view"
                        prop-value="name"
                        :placeholder-value="'Status'"
                        :label-animation="true"
                        :clearable="true"
                        :inline-search="true"
                        :search-image-tag="false"
                        @search-value="newValFromDropDown($event, 'status')"
                      ></hlx-select> -->

                      <div style="width: 100%">
                        <hlx-status
                          :type="'select-status'"
                          :status-option="filteredstatus"
                          :prevalue="payload.status"
                          :readonly="view"
                          @emit-select="emitStatus1"
                        >
                        </hlx-status>
                      </div>
                    </div>
                  </span>
                </div>
                <!-- <div class="decision-name-wrapper">
                  <hlx-input
                    v-model:value="payload.contactMedium[3].description"
                    :pre-val="payload.contactMedium[3].description"
                    type="location"
                    :params="locationApi"
                    :label-value="'Address'"
                    :label-animation="true"
                    :display-error="customnameerror"
                    :custom-error="customnameerror"
                    :show-new-address-option="showNewAddressOption"
                    :read-only="view"
                    :custom-error-message="'Please fill the field'"
                    :location-data-prop="locationData"
                    @focus-out="focusOutName"
                    @at-input="getLocations"
                    @add-new-location="addNewLocation"
                  ></hlx-input>
                </div> -->

                <addressComponent
                  :pre-val="addressPreVal"
                  :view="view"
                  @address-data="receivedAddressData"
                ></addressComponent>
                <!-- <div
                  class="new-address-fields"
                  style="
                    display: flex;
                    gap: 12px;
                    justify-content: space-between;
                  "
                >
                  <div style="width: 50%">
                    <hlx-input
                      v-model:value="payload.city"
                      :pre-val="payload.city"
                      :label-value="'City'"
                      :label-animation="true"
                      :display-error="customnameerror"
                      :read-only="view"
                      :custom-error="customnameerror"
                      :custom-error-message="'Please fill the field'"
                      @focus-out="focusOutName"
                      @at-input="validateName"
                    />
                  </div>
                  <div style="width: 50%">
                    <hlx-input
                      v-model:value="payload.state"
                      :pre-val="payload.state"
                      :label-value="'State'"
                      :label-animation="true"
                      :display-error="customnameerror"
                      :read-only="view"
                      :custom-error="customnameerror"
                      :custom-error-message="'Please fill the field'"
                      @focus-out="focusOutName"
                      @at-input="validateName"
                    />
                  </div>
                </div>
                <div
                  class="new-address-fields"
                  style="
                    display: flex;
                    gap: 12px;
                    justify-content: space-between;
                  "
                >
                  <div style="width: 50%">
                    <hlx-input
                      v-model:value="payload.country"
                      :pre-val="payload.country"
                      :label-value="'Country'"
                      :label-animation="true"
                      :read-only="view"
                      :display-error="customnameerror"
                      :custom-error="customnameerror"
                      :custom-error-message="'Please fill the field'"
                      @focus-out="focusOutName"
                      @at-input="validateName"
                    />
                  </div>
                  <div style="width: 50%">
                    <hlx-input
                      v-model:value="payload.postalCode"
                      :pre-val="payload.postalCode"
                      :label-value="'Postal code'"
                      :label-animation="true"
                      :display-error="customnameerror"
                      :read-only="view"
                      :custom-error="customnameerror"
                      :custom-error-message="'Please fill the field'"
                      @focus-out="focusOutName"
                      @at-input="validateName"
                    />
                  </div>
                </div> -->
                <div class="decision-name-wrapper">
                  <hlx-select
                    v-model:value="payload.partyCharacteristic[1].value"
                    :pre-value="payload.partyCharacteristic[1].value"
                    :options="industryTypeList"
                    prop-value="name"
                    :placeholder-value="'Industry'"
                    :disabled="view"
                    :label-animation="true"
                    :clearable="false"
                    :inline-search="true"
                    :search-image-tag="false"
                    @search-value="newValFromDropDown($event, 'industry')"
                  ></hlx-select>
                </div>
                <div class="decision-name-wrapper">
                  <hlx-select
                    v-model:value="payload.partyCharacteristic[0].value"
                    :pre-value="payload.partyCharacteristic[0].value"
                    :options="employeeCountList"
                    prop-value="name"
                    :placeholder-value="'Employees'"
                    :disabled="view"
                    :label-animation="true"
                    :clearable="false"
                    :inline-search="true"
                    :search-image-tag="false"
                    @search-value="newValFromDropDown($event, 'employeeCount')"
                  ></hlx-select>
                </div>
                <div class="decision-name-wrapper">
                  <hlx-select
                    v-model:value="
                      payload.contactMedium[3].characteristic['@type']
                    "
                    :pre-value="
                      payload.contactMedium[3].characteristic['@type']
                    "
                    :options="locationTypeList"
                    prop-value="name"
                    :placeholder-value="'Location type'"
                    :disabled="view"
                    :label-animation="true"
                    :clearable="false"
                    :inline-search="true"
                    :search-image-tag="false"
                    @search-value="newValFromDropDown($event, 'locationType')"
                  ></hlx-select>
                </div>
                <div class="decision-name-wrapper">
                  <hlx-select
                    v-model:value="accExecParty"
                    :pre-value="accExecParty"
                    :options="executivesList"
                    :placeholder-value="'Account executive'"
                    :label="'name'"
                    :prop-value="'name'"
                    :label-animation="true"
                    :disabled="view"
                    clearable="false"
                    :search-image-tag="true"
                    :sub-title="'company'"
                    :tag-value="'newRole'"
                    :image-link="'link'"
                    :inline-search="true"
                    @selected-value="selectedExecutive"
                  ></hlx-select>
                </div>

                <div
                  class="custom-container"
                  style="border: 1px solid #d8d8d8; border-radius: 5px"
                >
                  <div
                    style="
                      display: flex;
                      gap: 10px;
                      padding-left: 10px;
                      height: 36px;
                      align-items: center;
                      font-size: 14px;
                    "
                  >
                    <b>{{ "Exempt from tax" }}</b>
                    <hlx-input
                      v-model:value="payload.exemptFromTax"
                      type="switch"
                      :checked="payload.exemptFromTax"
                      :disabled="view"
                    ></hlx-input>
                  </div>
                  <div
                    v-if="payload.exemptFromTax"
                    style="padding: 10px; border-top: 1px solid #d8d8d8"
                  >
                    <hlx-input type="checkbox" :prop-value="'taxname'" :options="taxPrevalOptions(taxOptions)" @check-box-event="exemptTax">
                    </hlx-input>
                  </div>
                </div>
              </div>
              <div
                class="decision-info-left-panel"
                style="
                  display: flex;
                  flex-direction: column;
                  /* gap: 20px; */
                  width: 47.5%;
                "
              >
                <div class="decision-name-wrapper">
                  <hlx-input
                  v-if="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
                    v-model:value="payload.description"
                    :pre-val="payload.description"
                    type="textarea"
                    :label-value="'Description'"
                    :required="false"
                    :label-animation="true"
                    :display-error="true"
                    :custom-error="customnameerror"
                    :rows="9"
                    :read-only="view"
                    :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
                    @focus-out="focusOutName"
                    @at-input="validateName"
                  ></hlx-input>
                </div>
                <div class="decision-name-wrapper" style="margin-bottom: 20px">
                  <hlx-select
                    v-model:value="payload.partyCharacteristic[2].value"
                    :inline-search="true"
                    :pre-value="payload?.partyCharacteristic[2]?.value"
                    :options="marketSegmentList"
                    prop-value="name"
                    :placeholder-value="'Market segment'"
                    :label-animation="true"
                    :disabled="view"
                    :clearable="true"
                  ></hlx-select>
                </div>
                <div class="decision-name-wrapper" style="margin-bottom: 20px">
                  <hlx-select
                    v-model:native="payload.preferred"
                    :pre-value="payload.preferred"
                    :options="preferredMediumList"
                    :prop-value="'value'"
                    :label="'name'"
                    :inline-search="true"
                    :placeholder-value="'Preferred contact medium'"
                    :label-animation="true"
                    :clearable="false"
                    :disabled="view"
                  ></hlx-select>
                </div>

                <div class="decision-name-wrapper">
                  <hlx-input
                  v-if="staticData"
                    v-model:value="
                      payload.contactMedium[0].characteristic.emailAddress
                    "
                    :default-mail-error="staticData?.pleaseEnterValidUrl?.label[loginUserDetails]?.label"
                    :pre-val="
                      payload.contactMedium[0].characteristic.emailAddress
                    "
                    type="email"
                    :label-value="'Email id'"
                    :label-animation="true"
                    :read-only="view"
                    :required="true"
                    :display-error="true"
                    :custom-error="isExistingEmail"
                    :custom-error-message="isExistingEmailErrorMessage"
                    @focus-out="validateEmailNew"
                    @at-input="validateEmailNew"
                  ></hlx-input>
                </div>
                <div class="decision-name-wrapper">
                  <hlx-input
                  v-if="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
                    v-model:value="
                      payload.contactMedium[1].characteristic.phoneNumber
                    "
                    :default-number-error="staticData?.pleaseEnterValidNumber?.label[loginUserDetails]?.label"
                    :pre-val="
                      payload.contactMedium[1].characteristic.phoneNumber.value
                    "
                    :country-code="
                      payload.contactMedium[1].characteristic.phoneNumber
                        .country
                    "
                    type="phonenumber"
                    :read-only="view"
                    :label-value="'Phone no.'"
                    :label-animation="true"
                    :display-error="true"
                    :custom-error="customnameerror"
                    :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
                    @focus-out="focusOutName"
                    @at-input="validateName"
                  ></hlx-input>
                </div>
                <div class="decision-name-wrapper">
                  <hlx-input
                  v-if="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
                    v-model:value="
                      payload.contactMedium[2].characteristic.faxNumber
                    "
                    :pre-val="payload.contactMedium[2].characteristic.faxNumber"
                    :label-value="'Fax'"
                    :read-only="view"
                    :label-animation="true"
                    :display-error="true"
                    :custom-error="customnameerror"
                    :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
                    @focus-out="focusOutName"
                    @at-input="validateName"
                  ></hlx-input>
                </div>
                <div class="decision-name-wrapper">
                  <hlx-input
                  v-if="staticData?.pleaseEnterValidUrl?.label[loginUserDetails]?.label"
                    v-model:value="payload.externalReference[0].name"
                    :pre-val="payload.externalReference[0].name"
                    :label-value="'Website URL'"
                    :label-animation="true"
                    type="text"
                    :prefix="true"
                    :read-only="view"
                    :display-error="true"
                    :custom-error="isUrlEmpty"
                    :custom-error-message="staticData?.pleaseEnterValidUrl?.label[loginUserDetails]?.label"
                    @focus-out="websiteRegex"
                    @at-input="websiteRegex"
                  >
                    <template #prefix> https:// </template>
                  </hlx-input>
                </div>

                <div class="decision-name-wrapper" style="margin-bottom: 20px">
                  <hlx-multi-select
                    :pre-value="payload.tags"
                    :options="tagsList"
                    prop-value="name"
                    :multi-select="true"
                    :placeholder="'Tags'"
                    :disabled="view"
                    :label-animation="true"
                    :clearable="true"
                    @add-data="addTagList"
                    @selected-array="receivedTags"
                  ></hlx-multi-select>
                </div>
              </div>
              <!-- <overViewComponent></overViewComponent> -->
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'hierarchy'"
              class="cm-accounts-overview-component-container"
            >
              <hierarchyComponent
                :account-status="accountStatus"
                :data="hierarchyData"
                :mode="accessAllow"
                module="CM"
                @updated-hierarchy="updatedHierarchy"
              ></hierarchyComponent>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'contacts'"
              class="cm-accounts-overview-component-container"
            >
              <contactComponent
                :account-status="accountStatus"
                :data="contactTableData"
                :mode="accessAllow"
                :full-total="fullTotal"
                :current-total="currentTotal"
                @contact-switch-val="contactSwitchVal"
                @new-contact="contactData"
                @edit-contact="editContactData"
                @initalpayload="initalpayload"
                @delete-contact="deleteContactData"
              ></contactComponent>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'interaction'"
              class="cm-accounts-overview-component-container"
            >
              <interactionComponent
                :account-status="accountStatus"
                :mode="accessAllow"
                :data="interactionData"
                :contact-data="contactTableData"
                @initial-payload="initialPayloadInt"
                @new-interaction-payload="receivedInteractionPayload"
                @delete-interaction-index="deleteInteractionPayload"
                @delete-interaction-data="deleteinteraction"
                @edit-interaction="editInteraction"
              ></interactionComponent>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'rules'"
              class="cm-accounts-overview-component-container"
            >
              <!-- <img src="@/assets/images/addAccountContacts.svg" alt=""> -->
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'quotes and orders'"
              class="cm-accounts-quote-component-container"
            >
              <quoteAndOrdersComponent
                :account-status="accountStatus"
                @create-quote="handleQuoteCreation"
              ></quoteAndOrdersComponent>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'approvals'"
              class="cm-accounts-overview-component-container"
            >
              <approvalsComponent
                :account-status="accountStatus"
              ></approvalsComponent>
            </div>
            <div v-if="selectedValue.toLowerCase() === 'billing'" class="">
              <templateComponent
                :account-status="accountStatus"
              ></templateComponent>
            </div>
            <div v-if="selectedValue.toLowerCase() === 'products'" class="">
              <templateComponent
                :account-status="accountStatus"
                :module="'Products'"
              ></templateComponent>
            </div>
            <div v-if="selectedValue.toLowerCase() === 'contracts'" class="">
              <templateComponent
                :account-status="accountStatus"
                :module="'Contracts'"
              ></templateComponent>
            </div>
            <div v-if="selectedValue.toLowerCase() === 'documents'" class="">
              <attachmentsComponent
                :supplier-status="accountStatus"
                :user-details="userDetails"
                :data="attachmentData"
                module="CM"
                @upload-data="receivedUploadData"
                @delete-index="deleteAttachmentRow"
              ></attachmentsComponent>
            </div>
            <div v-if="selectedValue.toLowerCase() === 'notes'" class="">
              <noteComponent
                :supplier-status="accountStatus"
                :data="noteData"
                @added-note="newNote"
                @delete-index="deleteNote"
                @edit-note="editNote"
              ></noteComponent>
            </div>
            <div v-if="selectedValue.toLowerCase() === 'tickets'" class="">
              <TicketsComponent
                :module="'accounts'"
                :company="$route.params.companyName"
                :data="payload.relatedParty"
              ></TicketsComponent>
            </div>
            <div v-if="selectedValue.toLowerCase() === 'location'" class="">
              <locationComponent
                :account-status="accountStatus"
                :data="payload.contactMedium"
                @new-address="receivedNewAddress"
                @update-address="receivedUpdateAddress"
                @initial-payload="initialPayloadLocation"
                @delete-index="deleteAddress"
              ></locationComponent>
            </div>
            <div v-if="selectedValue.toLowerCase() === 'activity log'" class="">
              <activityLogComponent
                :module="'CM'"
                :activity-data="activityLogData"
                :totalfromprop="activitytotal"
                @activityrowpage="activityrowpage1"
                @activitycurrentpage="activitycurrentpage1"
              ></activityLogComponent>
            </div>
          </div>
        </div>
      </section>
      <!-- ---------------------------------------collapsible right panel------------------------------------------ -->
    </main>

    <!-- <div
      class="layout-footer"
      style="
        border-top: 1px solid #d8d8d8;
        padding-right: 40px;
        display: flex;
        align-items: center;
        height: 10vh;
        margin-right: -16px;
        margin-left: -16px;
      "
    >
      <div
        v-if="view"
        class="go-back"
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <hlx-button
          class="primary sm"
          style="margin-left: 20px"
          @click="view = false"
          >Edit</hlx-button
        >
      </div>
      <div
        v-if="!view"
        class="go-back"
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <hlx-button
          class="secondary sm"
          @click="view = true"
          >Cancel</hlx-button
        >
        <hlx-button
          class="primary sm"
          :disabled="payload.name === '' ? true : false"
          style="margin-left: 20px"
          @click="addNewAccount"
          >Save</hlx-button
        >
      </div>
    </div> -->
  </section>
  <hlx-modal
    :modal-active="showAddNewAdrressModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'400px'"
    :width="'650px'"
    :modal="true"
    @close="closeAddNewAdrressModal"
  >
    <template #header>
      <div>Add new address</div>
    </template>
    <template #content>
      <div
        class="modal-content add-contact-modal"
        style="
          padding: 20px;
          padding-bottom: 0;
          grid-template-columns: 38.5% 58.5%;
        "
      >
        <span class="left-section">
          <hlx-input
                  v-if="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            v-model:value="newAddressPayload.streetNumber"
            :pre-val="newAddressPayload.streetNumber"
            :label-value="'Street number'"
            :label-animation="true"
            :required="true"
            :display-error="true"
            :custom-error="isFirstNameEmpty"
            :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
                  v-if="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            v-model:value="newAddressPayload.unitNumber"
            :pre-val="newAddressPayload.unitNumber"
            :label-value="'Unit number'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isLastNameEmpty"
            :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <div class="input-wrapper">
            <hlx-input
              :key="key"
              v-model:value="newAddressPayload.city"
              :pre-val="newAddressPayload.city"
              :options="designationList"
              :required="true"
              prop-value="name"
              :label-value="'City'"
              :placeholder-value="'City'"
              :label-animation="true"
              :clearable="true"
            ></hlx-input>
          </div>
          <div class="input-wrapper">
            <hlx-input
                  v-if="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
              v-model:value="newAddressPayload.postalCode"
              :pre-val="newAddressPayload.postalCode"
              :label-value="'Postal code'"
              :label-animation="true"
              :required="true"
              :display-error="true"
              :custom-error="isLastNameEmpty"
              :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
        </span>
        <span class="right-section">
          <hlx-input
                  v-if="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            v-model:value="newAddressPayload.streetName"
            :pre-val="newAddressPayload.streetName"
            :label-value="'Street name'"
            :label-animation="true"
            :required="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
            :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
                  v-if="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            v-model:value="newAddressPayload.unitName"
            :pre-val="newAddressPayload.unitName"
            :label-value="'Unit name'"
            :label-animation="true"
            :required="false"
            :display-error="true"
            :custom-error="customnameerror"
            :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <div class="input-wrapper">
            <div style="display: flex; gap: 0.5rem">
              <hlx-input
                :key="key"
                v-model:value="newAddressPayload.state"
                :pre-val="newAddressPayload.state"
                :options="preferredList"
                :label-value="'State'"
                prop-value="value"
                :placeholder-value="'State'"
                :label-animation="true"
                :required="true"
                :clearable="true"
              ></hlx-input>
              <hlx-input
                :key="key"
                v-model:value="newAddressPayload.country"
                :pre-val="newAddressPayload.country"
                :options="preferredList"
                prop-value="value"
                :label-value="'Country'"
                :placeholder-value="'Country'"
                :required="true"
                :label-animation="true"
                :clearable="true"
              ></hlx-input>
            </div>
          </div>
          <hlx-input
            v-model:value="newAddressPayload.landmark"
            :pre-val="newAddressPayload.landmark"
            :label-value="'Nearby landmark'"
            :label-animation="true"
          ></hlx-input>
        </span>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="closeAddNewAdrressModal"
        >
        <!-- cancel -->
        <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        :disabled="
          newAddressPayload.city === '' ||
          newAddressPayload.country === '' ||
          newAddressPayload.state === '' ||
          newAddressPayload.postalCode === '' ||
          newAddressPayload.streetNumber === '' ||
          newAddressPayload.streetName === ''
        "
        @click="addNewAddress"
        >
        <!-- Add -->
        <span v-if="staticData">{{ staticData?.add?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
    </template>
  </hlx-modal>

  <discardComponent
    :show="showOverviewDiscardModal"
    :label-data="discardLabelLang"
    @stay="handleStay"
    @discard="handleDiscard"
    @close="showOverviewDiscardModal = false"
  >
  </discardComponent>
</template>

<script>
import {
  MLTAxiosInstance,
  DMNAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";

// import overViewComponent from '@/components/CM/addAccountOverviewComponent.vue';
import hierarchyComponent from "@/components/CM/addAccountHierarchyComponent.vue";
import contactComponent from "@/components/CM/addAccountContactComponent.vue";
import interactionComponent from "@/components/CM/addAccountInteractionComponent.vue";
import quoteAndOrdersComponent from "@/components/CM/addAccountQuoteAndOrderComponent.vue";
import approvalsComponent from "@/components/CM/addAccountApprovalsComponent.vue";
import attachmentsComponent from "@/components/CM/addAccountAttachmentsComponent.vue";
import noteComponent from "@/components/CM/addAccountNotesComponent.vue";
import TicketsComponent from "@/components/CM/addAccountTicketsComponent.vue";
import locationComponent from "@/components/CM/addAccountLocationsComponent.vue";
import addressComponent from "../../../components/CM/autocompleteComponent.vue";
import activityLogComponent from "../../../components/SM/activityLogComponent.vue";
import templateComponent from "@/components/CM/placeholderTableComponent.vue";

import leftPanel from "@/components/leftpannelComponent.vue";

import discardComponent from "@/components/discardComponent.vue";

export default {
  components: {
    templateComponent,
    TicketsComponent,
    // overViewComponent,
    hierarchyComponent,
    contactComponent,
    interactionComponent,
    quoteAndOrdersComponent,
    approvalsComponent,
    attachmentsComponent,
    noteComponent,
    leftPanel,
    addressComponent,
    locationComponent,
    activityLogComponent,
    discardComponent,
  },
  beforeRouteLeave(to, from, next) {
    if (this.discardIt) {
      //this.discardIt will handled on discard modal window discard changes emit
      next(true);
      return;
    }
    // Check for unsaved changes
    /**
     * Param 1 initial paylaod to check against param 2
     * Param 2 updated payload to check against param 1
     */
    const unsavedChanges =
      this.isPayloadChanged(this.initialData, this.updatedData) ||
      this.newProfileAdded; //This isPayloadChanged method was configured in global methods so we can call it any where on vue.It's find a difference between both data and return true if any changes otherwise return false

    // If unsaved changes found then display discard modal
    if (unsavedChanges) {
      this.showOverviewDiscardModal = true;
    }
    // Then route as per validation
    next(!unsavedChanges);
    return;
  },

  data() {
    return {
      loginUserDetails: {},
      staticData:null,
      discardLabelLang:{},
       isExistingName: false,
      isExistingNameErrorMessage: '',
      isExistingEmailErrorMessage: '',
      isExistingEmail: false,
      taxopt:[],
      currentTotal: 0,
      fullTotal: 0,
      marketSegmentList: [],
      initialPayloadInteraction: {},
      tempBack: false,
      initialData: {},
      updatedData: {},
      showOverviewDiscardModal: false,
      discardIt: false,
      accountStatus: false,
      filteredstatus: [],
      profilePicUrl: "",
      addressPreVal: {
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
        },
      },
      userDetails: {
        name: this.$route.params.companyName,
        id: this.$route.params.id,
      },
      taxOptions: [],
      activitytotal: 0,
      exemptFromTax: false,
      notification: {},
      view: true,
      contactTableData: [],
      locationTypeList: [],
      employeeCountList: [],
      preferredMediumList: [],
      industryTypeList: [],
      statusList: [],
      tagsList: [],
      activityrowpage: 10,
      activitycurrentpage: 1,
      initialPayloadLocations: {},
      locationParams: {
        // apiKey: """",
        country: "CA",
        type: "address",
      },
      isRightLayoutOpened: false,
      breadCrumbItems: [
        {
          label: "Accounts",
          link: `/CM/accounts`,
        },
        { label: "", link: "" },
      ],
      breadCrumbItemsView: [
        {
          label: "Accounts",
          link: `/CM/accounts`,
        },
        { label: "", link: "" },
      ],
      customnameerror: false,
      payload: {
        name: "",
        tradingName: "",
        parentCompany: "",
        customName: "",
        status: "",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "Industry",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        relatedParty: [],
        tags: [],
        taxDefinition: [],
        exemptFromTax: false,
        role: "Root admin",
        type: "company",
        password: "",
        email: "",
        attachment: [],
      },
      contactPayload: {
        name: "",
        tradingName: "",
        companyName: "",
        status: "",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "Industry",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        relatedParty: [],
        tags: ["Active"],
        role: "Root admin",
        type: "individual",
        password: "",
        email: "",
      },
      initalpayloads: {},
      selectedValue: "Overview",
      tabItems: [
        { name: "Overview", value: "Overview" },
        { name: "Hierarchy", value: "Hierarchy" },
        {
          name: "Contacts",
          value: "Contacts",
        },
        { name: "Interaction", value: "Interaction" },
        { name: "Rules", value: "Rules" },
        { name: "Quotes and orders", value: "Quotes and orders" },
        { name: "Approvals", value: "Approvals" },
      ],
      recievedContactData: [],
      showAddNewAdrressModal: false,
      locationData: [],
      newAddressPayload: {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      },
      showNewAddressOption: false,
      interactionData: {},
      receivedInteractionData: {},
      hierarchyData: [],
      rawPayload: {},
      leftPanelData: {},
      tempSideBar: {
        main: [
          {
            id: 1,
            name: "Overview",
            icon: "icon-presentation-regular",
            active: true,
            view: true,
          },
          {
            id: 2,
            name: "Hierarchy",
            icon: "icon-box-regular",
            active: false,
            view: true,
          },
          {
            id: 1.1,
            name: "Location",
            icon: "icon-location-pin-regular",
            active: false,
            view: true,
          },
          {
            id: 3,
            name: "Contacts",
            icon: "icon-user-regular",
            active: false,
            view: true,
          },
          {
            id: 4,
            name: "Interaction",
            icon: "icon-phone-office-regular",
            active: false,
            view: true,
          },
          {
            id: 5,
            name: "Products",
            icon: "icon-file-text-regular",
            active: false,
            view: true,
          },
          {
            id: 6,
            name: "Contracts",
            icon: "icon-file-regular",
            active: false,
            view: true,
          },
          {
            id: 7,
            name: "Quotes and orders",
            icon: "icon-menu-left-regular",
            active: false,
            view: true,
          },
          {
            id: 8,
            name: "Billing",
            icon: "icon-file-list-regular",
            active: false,
            view: true,
          },
          {
            id: 12,
            name: "Tickets",
            icon: "icon-coupon-regular",
            active: false,
            view: true,
          },
          {
            id: 9,
            name: "Documents",
            icon: "icon-paperclip-regular",
            active: false,
            view: true,
          },
          {
            id: 10,
            name: "Notes",
            icon: "icon-note-list-regular",
            active: false,
            view: true,
          },
          {
            id: 11,
            name: "Activity log",
            icon: "icon-time-forward-regular",
            active: false,
            view: true,
          },
          // {
          //   id: 10,
          //   name: 'Rules',
          //   icon: 'icon-desktop-code-regular',
          //   active: false,
          //   view: true,
          // },
        ],
      },
      company: "",
      attachmentData: [],
      noteData: [],
      isUrlEmpty: false,
      accExecParty: "",
      executivesList: [],
      newProfileData: null,
      newProfileformData: null,
      rawProfileUrlData: null,
      profileComponentKey: 0,
      newProfileAdded: false,
      activityLogData: [],
      profilePicName: "",
      deleteProfilePic: false,
      accessAllow: "edit",
    };
  },
  computed: {
    getBreadcrumbs() {
      return [
        {
          label: "Accounts",
          link: `/CM/accounts`,
        },
        { label: this.payload.customName, link: "" },
      ];
    },
  },
  watch: {
    staticData: {
      handler(val) {
        val
        if (val) {
          val
        //   this.tempSideBar.main
        //   = [
        //   {
        //     id: 1,
        //     name: "Overview",
        //     icon: "icon-presentation-regular",
        //     active: true,
        //     view: true,
        //     label: this.staticData?.cmAccountLeftPanelOverview?.label[this.loginUserDetails]?.label,
        //   },
        //   {
        //     id: 2,
        //     name: "Hierarchy",
        //     icon: "icon-box-regular",
        //     active: false,
        //     view: true,
        //     label: this.staticData?.cmAccountLeftPanelHierarchy?.label[this.loginUserDetails]?.label,
        //   },
        //   {
        //     id: 1.1,
        //     name: "Location",
        //     icon: "icon-location-pin-regular",
        //     active: false,
        //     view: true,
        //     label: this.staticData?.cmAccountLeftPanelLocation?.label[this.loginUserDetails]?.label,
        //   },
        //   {
        //     id: 3,
        //     name: "Contacts",
        //     icon: "icon-user-regular",
        //     active: false,
        //     view: true,
        //     label: this.staticData?.cmAccountLeftPanelContacts?.label[this.loginUserDetails]?.label,
        //   },
        //   {
        //     id: 4,
        //     name: "Interaction",
        //     icon: "icon-phone-office-regular",
        //     active: false,
        //     view: true,
        //     label: this.staticData?.cmAccountLeftPanelInteraction?.label[this.loginUserDetails]?.label,
        //   },
        //   {
        //     id: 5,
        //     name: "Products",
        //     icon: "icon-file-text-regular",
        //     active: false,
        //     view: true,
        //     label: this.staticData?.cmAccountLeftPanelProducts?.label[this.loginUserDetails]?.label,
        //   },
        //   {
        //     id: 6,
        //     name: "Contracts",
        //     icon: "icon-file-regular",
        //     active: false,
        //     view: true,
        //     label: this.staticData?.cmAccountLeftPanelContracts?.label[this.loginUserDetails]?.label,
        //   },
        //   {
        //     id: 7,
        //     name: "Quotes and orders",
        //     icon: "icon-menu-left-regular",
        //     active: false,
        //     view: true,
        //     label: this.staticData?.cmAccountLeftPanelQuotesAndOrders?.label[this.loginUserDetails]?.label,
        //   },
        //   {
        //     id: 8,
        //     name: "Billing",
        //     icon: "icon-file-list-regular",
        //     active: false,
        //     view: true,
        //     label: this.staticData?.cmAccountLeftPanelBilling?.label[this.loginUserDetails]?.label,
        //   },
        //   {
        //     id: 12,
        //     name: "Tickets",
        //     icon: "icon-coupon-regular",
        //     active: false,
        //     view: true,
        //     label: this.staticData?.cmAccountLeftPanelTickets?.label[this.loginUserDetails]?.label,
        //   },
        //   {
        //     id: 9,
        //     name: "Documents",
        //     icon: "icon-paperclip-regular",
        //     active: false,
        //     view: true,
        //     label: this.staticData?.cmAccountLeftPanelDocuments?.label[this.loginUserDetails]?.label,
        //   },
        //   {
        //     id: 10,
        //     name: "Notes",
        //     icon: "icon-note-list-regular",
        //     active: false,
        //     view: true,
        //     label: this.staticData?.cmAccountLeftPanelNotes?.label[this.loginUserDetails]?.label,
        //   },
        //   {
        //     id: 11,
        //     name: "Activity log",
        //     icon: "icon-time-forward-regular",
        //     active: false,
        //     view: true,
        //     label: this.staticData?.cmAccountLeftPanelActivityLog?.label[this.loginUserDetails]?.label,
        //   },
        //   // {
        //   //   id: 10,
        //   //   name: 'Rules',
        //   //   icon: 'icon-desktop-code-regular',
        //   //   active: false,
        //   //   view: true,
        //   // },
        // ]
          this.discardLabelLang = {
            unsavedChanges : this.staticData?.unsavedChanges?.label[this.loginUserDetails]?.label,
          discardModalMessage: this.staticData?.discardModalMessage?.label[this.loginUserDetails]?.label,
          discard: this.staticData?.discard?.label[this.loginUserDetails]?.label,
          stay: this.staticData?.stay?.label[this.loginUserDetails]?.label,
          }

        }
      },
      immediate: true,
      deep: true,
    },
    statusList: {
      handler(val) {
        console.log(val, "statuswatching000000000000");
        const filteredstatuslist = this.statusList.filter(
          (item) => item.name === "Active" || item.name === "Inactive"
        );
        this.filteredstatus = filteredstatuslist;
        console.log("filtered11111111111111111", this.filteredstatus);
      },
      immediate: true,
      deep: true,
    },
    selectedValue: {
      handler(val) {
        if (val.toLowerCase() !== "overview") {
          this.view = false;
        } else {
          this.view = true;
        }
      },
      immediate: true,
      deep: true,
    },
    payload: {
      handler(val) {
        console.log(val);
        // val.companyName = val.name;
        // val.tradingName = val.name;
        // val.parentCompany = this.company;
        if (val.contactMedium !== undefined) {
          val.email = val?.contactMedium[0].characteristic?.emailAddress;

          for (let i = 0; i < val.contactMedium.length; i++) {
            if (
              val.contactMedium[i].mediumType === val?.preferred?.toLowerCase()
            ) {
              val.contactMedium[i].preferred = true;
            } else {
              val.contactMedium[i].preferred = false;
            }
          }
        }
        //   let accExec = this.executivesList.filter(item => {
        // this.accExecPartyFunc()
        val?.relatedParty?.filter((item) => {
          if (item.role === "Account executive") {
            this.accExecParty = item.name;
          }
        });
        console.log(this.accExecParty);
        //   return item.name === val.accountExecutive ? item : undefined
        // })
        // val.relatedParty.splice(0, 1)
        // val.relatedParty.push(accExec[0])
        // console.log(this.payload);
        console.log("discard check new", this.initialData, this.updatedData);
        this.updatedData = JSON.parse(JSON.stringify(val));
        console.log("discard check", this.initialData, this.updatedData);
      },
      immediate: true,
      deep: true,
    },
    contactPayload: {
      handler(val) {
        val.company = JSON.parse(JSON.stringify(this.payload.tradingName));
        val.companyName = JSON.parse(JSON.stringify(this.payload.tradingName));
        val.tradingName = JSON.parse(JSON.stringify(this.payload.tradingName));
        val.email = val.contactMedium[0].characteristic.emailAddress;
        // console.log(this.payload, this.contactPayload);
      },
      immediate: true,
      deep: true,
    },
    // accExecParty: {
    //   async handler(val) {
    //     // console.log(this.company);
    //     let f = {
    //   company: `${this.company}`,
    //   type: 'filter-search',
    //   module: 'hierarchycontacts',
    //   isPaginated: true,
    //   paginatedQuery: {
    //     rowsPerPage: 10,
    //     currentPage: 1,
    //   },
    //   isNeedToBeSort: true,
    //   sortFieldAndOrder: {},
    //   limit: 2,
    //   filterQuery: {
    //     'role.name': 'Account executive',
    //     name: {
    //       $regex: `${val}`,
    //       $options: 'i',
    //     },
    //   },
    //   searchQuery: '',
    // };
    // let x = await MLTAxiosInstance.post('util/filterData', f);
    // // console.log(x);
    // // let exec = await MLTAxiosInstance.get(
    // //   `/partymanagement/individual/listuser?company=${this.company}`
    // // );
    // // console.log(x);

    // this.executivesList = x.data.data;
    // this.executivesList.forEach((item) => {
    //   item.newRole = item.role[0].name
    // });
    // console.log('exec list from watch', this.executivesList);
    //   },
    //   immediate: true,
    //   deep: true
    // }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventPageReload);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.preventPageReload);
  },
  async mounted() {
    const value = await MLTAxiosInstance.get("partymanagement/individual/auth");

let sampleTheadsd = await MLTAxiosInstance.get(
  `partyRole/get/${value.data.userId}`
);

const mergedPayload = sampleTheadsd.data.permission;

this.permisstionData =
mergedPayload.CM.child.Accounts.sections;

this.leftPanelData = this.mergeSidebarData(
  this.tempSideBar,
  this.permisstionData
);
    await this.$store.dispatch("loginTenantDetails");
    await this.$store.dispatch("loginUserDetails");
    // loginUserDetails
    // await this.$store.dispatch("loginTenantDetails");
    // let r = await this.$store.getters.loginTenantDetails;
    console.log("loginTenantDetails resrfsef:", this.$store.getters.loginTenantDetails);
    console.log("loginUserDetails resrfsef:", this.$store.getters.loginUserDetails);

    this.loginUserDetails = this.$store?.getters?.loginUserDetails?.languageAbility[0]?.code;

    // this.loginUserDetails.languageAbility[0].name
    // this.loginUserDetails.languageAbility[0].code

    try {
      let organisationInfo = await UPCAxiosInstance.get('/multi-language');
      this.staticData = organisationInfo?.data?.filter(itm => itm && itm.modules)?.[0]?.modules;
      console.log("this.staticData:",organisationInfo , this.staticData);
      
    }
    catch (error) {
      console.error("error::", error)
    }


    if (this.$route.query.status === "Inactive") {
      this.accountStatus = true;
      this.accessAllow = "view";
    }
    if (this.$route.params.type === "view") {
      this.view = true;
    } else {
      this.view = false;
    }
    if (this.$route.params.from === "contacts") {
      this.leftPanelData.main.forEach((item) => {
        item.active = false;
        // If the name matches, set active to true
        if (item.name === "Contacts") {
          item.active = true;
        }
      });
      this.selectedValue = "Contacts";
    }
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    this.company = val.data.company;
    this.parentCompany = this.company;
    let payload = await MLTAxiosInstance.get(
      `/partymanagement/organisation/${this.$route.params.id}?company=${this.$route.params.companyName}`
    );
    // console.log(payload);

    this.payload = payload.data;
    console.log(payload, "abc", this.payload.partyCharacteristic.length);
    if (this.payload.partyCharacteristic.length != 3) {
      this.payload.partyCharacteristic[1].name = "Industry";
      let partyCharacteristicObj = {
        name: "market segment",
        value: "",
      };
      let partyCharacteristicArray = this.payload.partyCharacteristic;
      partyCharacteristicArray.push(partyCharacteristicObj);
      this.payload.partyCharacteristic = partyCharacteristicArray;
    }

    if (this.payload.taxDefinition.length != 0) {
      this.payload.exemptFromTax = true;
    }
    const preferredMedium = payload.data.contactMedium.find(
      (item) => item.preferred
    );
    this.payload.preferred = preferredMedium
      ? preferredMedium.mediumType
      : null;

    this.initialData = JSON.parse(JSON.stringify(this.payload));
    this.updatedData = JSON.parse(JSON.stringify(this.payload));
    this.addressPreVal = this.payload.contactMedium[3];

    // console.log(this.payload);
    const profileName = this.payload.attachment.filter((item) => {
      return item["@type"] !== undefined && item["@type"] === "profile"
        ? item
        : false;
    });
    console.log(profileName[0]?.name);
    this.profilePicName = profileName[0]?.name;
    const url = await UPCAxiosInstance.get(
      `/gcpupload/files/${profileName[0]?.name}`,
      { responseType: "arraybuffer" }
    );
    // console.log(new Uint8Array([url.data,"url incoming"]))
    this.rawProfileUrlData = url.data;
    this.profilePicUrl = await this.bufferToDataURL(url.data);
    console.log(this.profilePicUrl, url.data, "url.data");
    // this.profilePicUrl = url.data
    // const arrayBuffer = await url?.data?.arrayBuffer();
    //     const base64String = this.arrayBufferToBase64(arrayBuffer);
    //     const imageSrc = `data:image/jpeg;base64,${base64String}`;
    // this.profilePicUrl = imageSrc
    let f2 = {
      company: `${this.company}`,
      type: "filter-search",
      module: "hierarchycontacts",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {},
      limit: 2,
      filterQuery: {
        "role.name": "Account executive",
        name: {
          $regex: "",
          $options: "i",
        },
      },
      searchQuery: "",
    };
    let x2 = await MLTAxiosInstance.post("util/filterData", f2);
    console.log(x2);

    let obj = {
      specificAccount: true,
      "appliesTo.tradingName": this.payload.name,
      "province.name": this.payload.contactMedium[3]?.characteristic?.state,
      "country.name.common":
        this.payload.contactMedium[5]?.characteristic?.country,
    };
    obj;
    let obj1 = {
      specificMarket: true,
      "appliesTo.name": this.payload?.partyCharacteristic[2]?.value,
      "province.name": this.payload.contactMedium[3]?.characteristic?.state,
      "country.name.common":
        this.payload.contactMedium[5]?.characteristic?.country,
    };
    obj1;
    let obj2 = {
      allAccount: true,
      "province.name": this.payload.contactMedium[3]?.characteristic?.state,
      "country.name.common":
        this.payload.contactMedium[5]?.characteristic?.country,
    };
    obj2;
    let obj3 = {
      allAccount: true,
      province: [],
      "country.name.common":
        this.payload.contactMedium[5]?.characteristic?.country,
    };
    obj3;

    let f2x = {
      company: `${this.company}`,
      type: "filter-search",
      module: "tax",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {},
      limit: 100,
      filterQuery: {},
      searchQuery: "",
    };
    console.log(f2x, "", this.payload);
    f2x.filterQuery = obj;
    let tax1 = await UPCAxiosInstance.post("util/filterData", f2x);
    console.log(tax1.data.data, "tax1");
    if (tax1.data.data.length == 0) {
      f2x.filterQuery = obj1;
      let tax2 = await UPCAxiosInstance.post("util/filterData", f2x);
      console.log(tax2.data.data, "tax2");
      if (tax2.data.data.length == 0) {
        f2x.filterQuery = obj2;
        let tax3 = await UPCAxiosInstance.post("util/filterData", f2x);
        console.log(tax3.data.data, "tax3");
        if (tax3.data.data.length == 0) {
          f2x.filterQuery = obj3;
          let tax4 = await UPCAxiosInstance.post("util/filterData", f2x);
          console.log(tax4.data.data, "tax4");
          if (tax4.data.data.length != 0) {
            this.taxOptions = tax4.data.data;
          }
        } else {
          this.taxOptions = tax3.data.data[0].tax;
        }
      } else {
        this.taxOptions = tax2.data.data[0].tax;
      }
    } else {
      this.taxOptions = tax1.data.data[0].tax;
    }

    // let exec = await MLTAxiosInstance.get(
    //   `/partymanagement/individual/listuser?company=${this.company}`
    // );
    console.log(x2,'taxee');
    if(Object.keys(this.payload.taxDefinition).length != 0){
      this.payload.taxDefinition.forEach((item1) => {
        if (item1.checked) {
          this.taxOptions.forEach((item2) => {
            if (item1.taxname === item2.taxname) {
              item2.checked = true;
            }
          });
        }else{
          this.taxOptions.forEach((item2) => {
            item2.checked = false
          });
          console.log('ieie',this.taxOptions)
          this.payload.taxDefinition = this.taxOptions
        }
      });
    }else{
      let arr = this.taxOptions
      arr.forEach((item)=>{
        item.checked = false
      })
      console.log(arr,'jdfno')
      this.payload.taxDefinition = arr
    }

    this.executivesList = x2.data.data;
    this.executivesList.forEach((item) => {
      item.newRole = item.role[0].name;
    });
    // let statusList = await DMNAxiosInstance.get(`mapRef/collection/statuslist`);
    this.statusList =  await this.getReferanceData('statuslist');
    // let preferredList = await DMNAxiosInstance.get(
    //   `mapRef/collection/cmAccountPreferredContactMedium`
    // );
    // let marketSegmentList = await DMNAxiosInstance.get(
    //   `mapRef/collection/market`
    // );
    this.marketSegmentList =  await this.getReferanceData('market');
    this.preferredMediumList =  await this.getReferanceData('cmAccountPreferredContactMedium');
    // let industryTypeList = await DMNAxiosInstance.get(
    //   `mapRef/collection/cmAccountIndustryType`
    // );
    this.industryTypeList =  await this.getReferanceData('cmAccountIndustryType');
    // let employeeCountList = await DMNAxiosInstance.get(
    //   `mapRef/collection/cmAccountEmployeeCount`
    // );
    this.employeeCountList =  await this.getReferanceData('cmAccountEmployeeCount');
    // let locationTypeList = await DMNAxiosInstance.get(
    //   `mapRef/collection/cmAccountLocationType`
    // );
    this.locationTypeList =  await this.getReferanceData('cmAccountLocationType');
    let tagsListData =await this.getReferanceData('tagsList')
    this.tagsList =  tagsListData.filter((item) => {
      return item.module === "cm";
    });
    let f = {
      company: this.$route.params.companyName,
      type: "filter-search",
      module: "hierarchycontacts",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {},
      limit: 2,
      filterQuery: {
        "@baseType": {
          $ne: "supplier",
        },
        company: this.$route.params.companyName,
      },
    };
    let x = await MLTAxiosInstance.post("util/filterData", f);
    // console.log(x);
    let contacts = x.data;
    contacts.data.forEach((e) => {
      const preferredMedium = e.contactMedium.find((item) => item.preferred);
      e.preferred = preferredMedium ? preferredMedium.mediumType : null;
    });
    this.fullTotal = x.data.total;
    this.currentTotal = x.data.total;
    this.contactTableData = contacts.data;
    console.log(this.contactTableData, "contacts data");

    console.log(this.profilePicUrl, "testtt");
    this.attachmentData = this.payload?.attachment?.filter((item) => {
      return item["@type"] !== "profile" ? item : false;
    });
    this.noteData = this.payload?.notes;
    this.rawPayload = JSON.parse(JSON.stringify(this.payload));
    let interaction = await UPCAxiosInstance.get(
      `/partyInteraction/?company=${this.$route.params.companyName}`
    );
    // console.log(interaction);
    this.interactionData = interaction?.data;
    let hierarchy;
    if (this.payload.rootName === this.company) {
      hierarchy = await MLTAxiosInstance.get(
        `/hierarchy?company=${this.payload.name}`
      );
    } else {
      hierarchy = await MLTAxiosInstance.get(
        `/hierarchy?company=${this.payload.rootName}`
      );
    }
    // console.log(hierarchy);
    this.hierarchyData.push(hierarchy.data);
    // let exec = await MLTAxiosInstance.get(
    //   `/partymanagement/individual/listuser?company=${this.company}`
    // );
    // this.executivesList = exec.data;

    const logpayload = {
      type: "filter",
      module: "log",
      lastUpdated: new Date().toJSON(),
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {},
      limit: 10,
      filterQuery: {
        category: {
          $in: ["accounts"],
        },
        ref: this.$route.params.id,
      },
      searchQuery: "",
    };
    const logaccess = await MLTAxiosInstance.post(
      `/util/filterData`,
      logpayload
    );
    this.activityLogData = logaccess.data.data;
    this.activitytotal = logaccess.data.total;
    console.log(logaccess.data.data);
    // let productSpecActivity = await UPCAxiosInstance.get(
    //   `activityLog/moduleBasedLog/productSpecification`
    // );
    // // console.log(productSpecActivity.data,"productSpecActivity.data");
    // this.logData=productSpecActivity.data;
    this.rawPayload = JSON.parse(JSON.stringify(this.payload));

  },
  methods: {
    async contactSwitchVal(val) {
      if (val === "" || val === "all") {
        let f = {
          company: this.$route.params.companyName,
          type: "filter-search",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: {
            "@baseType": {
              $ne: "supplier",
            },
            company: this.$route.params.companyName,
          },
        };
        let x = await MLTAxiosInstance.post("util/filterData", f);
        // console.log(x);
        let contacts = x.data;
        contacts.data.forEach((e) => {
          const preferredMedium = e.contactMedium.find(
            (item) => item.preferred
          );
          e.preferred = preferredMedium ? preferredMedium.mediumType : null;
        });
        this.contactTableData = contacts.data;
        this.fullTotal = x.data.total;
        this.currentTotal = x.data.total;

        console.log(this.contactTableData, "contacts data");
      } else if (val === "pending") {
        let f = {
          company: this.$route.params.companyName,
          type: "filter-search",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: {
            "@baseType": {
              $ne: "supplier",
            },
            company: this.$route.params.companyName,
            status: {
              $in: ["Pending"],
            },
          },
        };
        let x = await MLTAxiosInstance.post("util/filterData", f);
        // console.log(x);
        // this.fullTotal = x.data.total;
        this.currentTotal = x.data.total;
        let contacts = x.data;
        contacts.data.forEach((e) => {
          const preferredMedium = e.contactMedium.find(
            (item) => item.preferred
          );
          e.preferred = preferredMedium ? preferredMedium.mediumType : null;
        });
        this.contactTableData = contacts.data;
        console.log(this.contactTableData, "contacts data");
      } else if (val === "active") {
        let f = {
          company: this.$route.params.companyName,
          type: "filter-search",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: {
            "@baseType": {
              $ne: "supplier",
            },
            company: this.$route.params.companyName,
            status: {
              $in: ["Active"],
            },
          },
        };
        let x = await MLTAxiosInstance.post("util/filterData", f);
        // console.log(x);
        // this.fullTotal = x.data.total;
        this.currentTotal = x.data.total;
        let contacts = x.data;
        contacts.data.forEach((e) => {
          const preferredMedium = e.contactMedium.find(
            (item) => item.preferred
          );
          e.preferred = preferredMedium ? preferredMedium.mediumType : null;
        });
        this.contactTableData = contacts.data;
        console.log(this.contactTableData, "contacts data");
      } else if (val === "inactive") {
        let f = {
          company: this.$route.params.companyName,
          type: "filter-search",
          module: "hierarchycontacts",
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          sortFieldAndOrder: {},
          limit: 2,
          filterQuery: {
            "@baseType": {
              $ne: "supplier",
            },
            company: this.$route.params.companyName,
            status: {
              $in: ["Inactive"],
            },
          },
        };
        let x = await MLTAxiosInstance.post("util/filterData", f);
        // console.log(x);
        // this.fullTotal = x.data.total;
        this.currentTotal = x.data.total;
        let contacts = x.data;
        contacts.data.forEach((e) => {
          const preferredMedium = e.contactMedium.find(
            (item) => item.preferred
          );
          e.preferred = preferredMedium ? preferredMedium.mediumType : null;
        });
        this.contactTableData = contacts.data;
        console.log(this.contactTableData, "contacts data");
      }
    },

    taxPrevalOptions(val){
      console.log(val,'testtt')
      let arr = []
      let arr1 = []
     val.forEach((item)=>{
      console.log(item,'testtt')
       item?.tax?.forEach((tax) =>{
        console.log(tax.taxname,'testtt')
        if(arr1.includes(tax.taxname) != true){
           arr1.push(tax.taxname)
          let obj = JSON.parse(JSON.stringify(tax))
          // obj.name = tax.taxName
          arr.push(obj)
          console.log(arr,'testtt')
        }
       })
     })
     return val

    },
    assignMarket(val){
      // if(this.payload.partyCharacteristic[2] &&  this.payload?.partyCharacteristic[2]?.value){
      console.log(val, "test", this.payload);
      this.payload.partyCharacteristic[2].value = val;
      // }
    },
    exemptTax(val) {
      this.payload.taxDefinition = JSON.parse(JSON.stringify(val));
      console.log(this.payload, "tax5");
    },
    initialPayloadLocation(val) {
      this.initialPayloadLocations = JSON.parse(JSON.stringify(val));
    },
    preventPageReload(event) {
      this.showOverviewDiscardModal = false;
      const unsavedChanges = this.isPayloadChanged(
        this.initialData,
        this.updatedData
      );
      if (!unsavedChanges) return;

      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
      return;
    },
    handleStay() {
      // //handle Stay funtionality to hide discard component
      this.showOverviewDiscardModal = !this.showOverviewDiscardModal;
      this.tempBack = false;
    },
    handleDiscard() {
      //Handle discard functionalities like hide discard component,set discardIt as true, and push the routes where ever you want.
      console.log("store", this.$store.state.tempActiveSideBarItem);
      if (this.tempBack) {
        this.$router.back();
      } else {
        this.$router.push(this.$store.state.tempActiveSideBarItem.link);
      }
      this.showOverviewDiscardModal = !this.showOverviewDiscardModal;
      this.discardIt = true;
    },
    validatePhoneNumber(val) {
      return val.valid;
    },
    validateEmailNew() {
      console.log('hleo');
      if (this.initialData.contactMedium[0].characteristic.emailAddress !== this.payload.contactMedium[0].characteristic.emailAddress) {
        this.isExistingEmail = false
        this.isExistingEmailErrorMessage = ''
      }
    },
    validateNameNew() {
      console.log('hleo');
        this.isExistingName = false
        this.isExistingNameErrorMessage = ''
    },
    validateEmail(val) {
      const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (val.length > 0) {
        if (!reg.test(val)) {
          return false; // URL is invalid
        } else {
          return true;
        }
      }
    },
    async deleteProfilePicture() {
      console.log(this.payload.attachment, this.profilePicName, "ppp");
      this.deleteProfilePic = true;
    },
    emitStatus1(val1) {
      this.payload.status = val1["value"];
    },
    cancelEditAccount() {
      this.view = true;
      this.profileComponentKey += 1;
      this.payload = JSON.parse(JSON.stringify(this.initialData));
      console.log(this.rawPayload, 'this.rawPayload');
      
      if (this.payload?.contactMedium !== undefined && this.payload?.contactMedium[3] !== undefined) {
        this.addressPreVal = this.payload?.contactMedium[3];  
      }
      this.bufferToDataURL(this.rawProfileUrlData);
      this.newProfileAdded = false;
      this.deleteProfilePic = false;
    },
    async profileUpload(event) {
      console.log(event);
      this.newProfileAdded = true;
      this.newProfileData = event.target.files[0];
      this.newProfileformData = new FormData();

      // Append all selected files to FormData object
      this.newProfileformData.append("files", this.newProfileData);
    },
    async buffzerToDataURL(buffer) {
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = "";
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },
    handleQuoteCreation() {
      this.$router.push({
        name: "CPQDefaultTemplate",
        query: { client: this.payload.tradingName, id: this.payload._id },
      });
    },
    websiteRegex(val) {
      const websiteRegex =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

      if (val.length >= 1) {
        if (!websiteRegex.test(val)) {
          this.isUrlEmpty = true; // URL is invalid
        } else {
          this.isUrlEmpty = false;
        }
      } else {
        this.isUrlEmpty = false;
      }
    },
    async deleteAddress(index) {
      // console.log(this.payload.contactMedium[index]);
      let changeDefault = false;
      const type = this.payload.contactMedium[index].mediumType;
      console.log(this.payload.contactMedium[index], "bnmml");
      const addedProductLocationLogPayload = {
        message: `${this.payload.contactMedium[index].characteristic.locationName} Location has been deleted`,
        detailedAction: [],
        action: "Delete",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "Accounts-location",
        category: ["accounts"],
      };
      this.initLog(addedProductLocationLogPayload);
      let p = [];
      if (this.payload.contactMedium[index].characteristic.default === true) {
        changeDefault = true;
      }
      this.payload.contactMedium.splice(index, 1);
      p = this.payload.contactMedium.filter((item) => {
        return item.mediumType === type;
      });
      if (p[0] !== undefined && changeDefault === true) {
        p[0].default = true;
        p[0].characteristic.default = true;
      }
      // console.log(p[0]);
      await this.addNewAccount();
      this.parentCompany = this.company;
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$route.params.id}?company=${this.$route.params.companyName}`
      );
      // console.log(payload);
      let contacts = await MLTAxiosInstance.get(
        `/partymanagement/individual/listuser?company=${this.$route.params.companyName}`
      );
      contacts.data.forEach((e) => {
        const preferredMedium = e.contactMedium.find((item) => item.preferred);
        e.preferred = preferredMedium ? preferredMedium.mediumType : null;
      });
      this.contactTableData = contacts.data;
      // console.log(this.contactTableData);
      const preferredMedium = payload.data.contactMedium.find(
        (item) => item.preferred
      );
      // console.log(preferredMedium);
      this.payload = payload.data;
      this.payload.preferred = preferredMedium
        ? preferredMedium.mediumType
        : null;
      // console.log(this.payload);

      // console.log(p);
      this.attachmentData = this.payload.attachment.filter((item) => {
        return item["@type"] !== "profile" ? item : false;
      });
      this.noteData = this.payload.notes;
      this.rawPayload = JSON.parse(JSON.stringify(this.payload));
      this.initialData = JSON.parse(JSON.stringify(this.payload));
      this.updatedData = JSON.parse(JSON.stringify(this.payload));
    },
    async receivedUpdateAddress(val) {
      this.payload.contactMedium[val.index] = val.data;

      console.log(
        this.initialPayloadLocations,
        this.payload.contactMedium[val.index],
        "activitylog payload data"
      );

      let CM_LocationLog = {
        message: `${this.payload?.customName} Location has been updated`,
        detailedAction: [],
        "@type": "Accounts-location",
        category: ["accounts"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: [this.$route.params.id],
        action: "UPDATE",
        status: "active",
      };

      let nameActionLog = this.buildBasicLogByDifferences(
        this.initialPayloadLocations?.characteristic,
        this.payload.contactMedium[val.index].characteristic,
        "locationName",
        "Name"
      );
      console.log(nameActionLog, CM_LocationLog, "nameActionLog");

      let descriptionActionLog = this.buildBasicLogByDifferences(
        this.initialPayloadLocations?.characteristic,
        this.payload.contactMedium[val?.index]?.characteristic,
        "description",
        "Address"
      );
      console.log(descriptionActionLog, "nameActionLog");

      // email differences
      let emailaddressActionLog = this.buildBasicLogByDifferences(
        this.initialPayloadLocations?.characteristic?.email,
        this.payload.contactMedium[val?.index]?.characteristic?.email,
        "emailAddress",
        "Email"
      );
      console.log(emailaddressActionLog, "emailaddressActionLog");

      // phoneNumber differences
      let phoneNumberActionLog = this.buildBasicLogByDifferences(
        this.initialPayloadLocations?.characteristic?.phone?.phoneNumber,
        this.payload?.contactMedium[val?.index]?.characteristic?.phone
          ?.phoneNumber,
        "value",
        "phoneNumber"
      );

      console.log(phoneNumberActionLog, "phoneNumberActionLog");
      CM_LocationLog.detailedAction = CM_LocationLog.detailedAction.concat(
        phoneNumberActionLog,
        emailaddressActionLog,
        descriptionActionLog,
        nameActionLog
      );

      this.initLog(CM_LocationLog);
      await this.addNewAccount();
      this.parentCompany = this.company;
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$route.params.id}?company=${this.$route.params.companyName}`
      );
      // console.log(payload);
      let contacts = await MLTAxiosInstance.get(
        `/partymanagement/individual/listuser?company=${this.$route.params.companyName}`
      );
      contacts.data.forEach((e) => {
        const preferredMedium = e.contactMedium.find((item) => item.preferred);
        e.preferred = preferredMedium ? preferredMedium.mediumType : null;
      });
      this.contactTableData = contacts.data;
      // console.log(this.contactTableData);
      const preferredMedium = payload.data.contactMedium.find(
        (item) => item.preferred
      );
      // console.log(preferredMedium);
      this.payload = payload.data;
      this.payload.preferred = preferredMedium
        ? preferredMedium.mediumType
        : null;
      // console.log(this.payload);
      this.attachmentData = this.payload.attachment.filter((item) => {
        return item["@type"] !== "profile" ? item : false;
      });
      this.noteData = this.payload.notes;
      this.rawPayload = JSON.parse(JSON.stringify(this.payload));
      this.initialData = JSON.parse(JSON.stringify(this.payload));
      this.updatedData = JSON.parse(JSON.stringify(this.payload));
    },
    async receivedNewAddress(val) {
      const addedProductLocationLogPayload = {
        message: `${val.characteristic.locationName} Location has been added`,
        detailedAction: [],
        action: "ADDED",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "Accounts-location",
        category: ["accounts"],
      };
      this.initLog(addedProductLocationLogPayload);
      console.log(
        addedProductLocationLogPayload,
        "addedProductLocationLogPayload"
      );
      this.payload.contactMedium.push(val);
      await this.addNewAccount();
      this.parentCompany = this.company;
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$route.params.id}?company=${this.$route.params.companyName}`
      );
      // console.log(payload);
      let contacts = await MLTAxiosInstance.get(
        `/partymanagement/individual/listuser?company=${this.$route.params.companyName}`
      );
      contacts.data.forEach((e) => {
        const preferredMedium = e.contactMedium.find((item) => item.preferred);
        e.preferred = preferredMedium ? preferredMedium.mediumType : null;
      });
      this.contactTableData = contacts.data;
      // console.log(this.contactTableData);
      const preferredMedium = payload.data.contactMedium.find(
        (item) => item.preferred
      );
      // console.log(preferredMedium);
      this.payload = payload.data;
      this.payload.preferred = preferredMedium
        ? preferredMedium.mediumType
        : null;
      // console.log(this.payload);
      this.attachmentData = this.payload.attachment.filter((item) => {
        return item["@type"] !== "profile" ? item : false;
      });
      this.noteData = this.payload.notes;
      this.rawPayload = JSON.parse(JSON.stringify(this.payload));
      this.initialData = JSON.parse(JSON.stringify(this.payload));
      this.updatedData = JSON.parse(JSON.stringify(this.payload));
    },
    receivedAddressData(val) {
      if (val.characteristic.country !== "") {
        this.payload.contactMedium[1].characteristic.phoneNumber
        .country.name = val.characteristic.country
        this.payload.contactMedium[1].characteristic.phoneNumber
        .country.flags = ""
        this.payload.contactMedium[1].characteristic.phoneNumber
        .country.short = ""
      }
      let address1 = JSON.parse(JSON.stringify(val));
      // let address2 = JSON.parse(JSON.stringify(val))
      this.payload.contactMedium[3] = JSON.parse(JSON.stringify(address1));
      // address2.mediumType = 'billingLocation'
      // this.newAccPayload.contactMedium.push(address2);
    },
    async editNote(val) {
      console.log(val, 'valedit');
      
      let oldValue = JSON.parse(JSON.stringify(this.payload.notes[val.index]));
      this.payload.notes[val.index] = val.val;
      this.payload.id = this.payload._id;
      delete this.payload._id;
      this.payload.lastUpdate = new Date();
      let notes = await MLTAxiosInstance.patch(
        `/partymanagement/organisation/?company=${this.$route.params.companyName}`,
        this.payload
      );
      if (notes.data) {
        let notesLogPayload = {
          message: `${this.payload?.customName} notes has been updated`,
          detailedAction: [],
          action: "UPDATED",
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          createdBy: this.$cookies.get("user"),
          ref: [this.$route.params.id],
          "@type": "Notes",
          category: ["accounts"],
        };

        // Checks notes changes
        if (val.val !== oldValue) {
          notesLogPayload.detailedAction.push({
            item: {
              old: oldValue,
              new: val.val,
            },
            action: "UPDATED",
            property: "Notes",
          });
        } else if (!val.val && oldValue) {
          notesLogPayload.detailedAction.push({
            item: oldValue,
            action: "REMOVED",
            property: "Notes",
          });
        } else if (val.val && !oldValue) {
          notesLogPayload.detailedAction.push({
            item: val.val,
            action: "ADDED",
            property: "Notes",
          });
        }
        this.initLog(notesLogPayload);
      }
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$route.params.id}?company=${this.$route.params.companyName}`
      );

      this.noteData = payload.data.notes;
      this.initialData = JSON.parse(JSON.stringify(this.payload));
      this.updatedData = JSON.parse(JSON.stringify(this.payload));
    },
    async newNote(val) {
      console.log(this.payload.notes, 'fkkk');
      val.id = this.payload.length
      this.payload.notes.push(val);
      console.log(this.payload.notes, 'fkkk');
      this.payload.id = this.payload._id;
      delete this.payload._id;
      this.payload.lastUpdate = new Date();
      let notes = await MLTAxiosInstance.patch(
        `/partymanagement/organisation/?company=${this.$route.params.companyName}`,
        this.payload
      );
      if (notes.data) {
        const notesLogPayload = {
          message: `${this.payload?.customName} notes has been added`,
          detailedAction: [{ item: val, action: "ADDED", property: "Notes" }],
          action: "ADDED",
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          createdBy: this.$cookies.get("user"),
          ref: [this.$route.params.id],
          "@type": "Notes",
          category: ["accounts"],
        };
        this.initLog(notesLogPayload);
      }
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$route.params.id}/?company=${this.$route.params.companyName}`
      );
      this.payload = payload.data;
      this.noteData = payload.data.notes;
      console.log(this.noteData, 'fkkk');
      this.initialData = JSON.parse(JSON.stringify(this.payload));
      this.updatedData = JSON.parse(JSON.stringify(this.payload));
    },
    async deleteNote(val) {
      // console.log(val);
      let notesTable = this.payload.notes[val].note;
      this.payload.notes.splice(val, 1);
      this.payload.id = this.payload._id;
      delete this.payload._id;
      this.payload.lastUpdate = new Date();
      await MLTAxiosInstance.patch(
        `/partymanagement/organisation/?company=${this.$route.params.companyName}`,
        this.payload
      );
      const deteledNotesLogPayload = {
        message: `${this.payload?.customName} note has been deleted`,
        detailedAction: [
          {
            item: notesTable,
            action: "REMOVED",
            property: "Note",
          },
        ],
        action: "DELETED",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "note",
        category: ["accounts"],
      };
      this.initLog(deteledNotesLogPayload);
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$route.params.id}?company=${this.$route.params.companyName}`
      );
      this.payload = payload.data;
      this.noteData = payload.data.notes;
      this.initialData = JSON.parse(JSON.stringify(this.payload));
      this.updatedData = JSON.parse(JSON.stringify(this.payload));
    },
    async deleteAttachmentRow(val) {
      console.log(val);
      let delete_name = this.payload.attachment[val].name;
      this.payload.attachment.splice(val - 1, 1);
      this.payload.id = this.payload._id;
      delete this.payload._id;
      this.payload.lastUpdate = new Date();
      let attachmentLogPayload = {
        message: `${this.payload?.name} attachment has been deleted`,
        detailedAction: [
          { item: delete_name, action: "REMOVED", property: "Attachment" },
        ],
        action: "DELETED",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "Attachment",
        category: ["accounts"],
      };
      this.initLog(attachmentLogPayload);

      await MLTAxiosInstance.patch(
        `/partymanagement/organisation/?company=${this.$route.params.companyName}`,
        this.payload
      );
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$route.params.id}/?company=${this.$route.params.companyName}`
      );
      this.attachmentData = payload?.data?.attachment?.filter((item) => {
        return item["@type"] !== "profile" ? item : false;
      });
      this.initialData = JSON.parse(JSON.stringify(this.payload));
      this.updatedData = JSON.parse(JSON.stringify(this.payload));
    },
    async receivedUploadData(val) {
      let data = await UPCAxiosInstance.post(`/gcpMultiple/v2/upload/`, val);
      console.log(data);
      let currentPayload = data?.data?.map((item) => {
        let obj = {};
        obj.name = item.filename;
        obj.size = item.data.attachment.size;
        obj.url = item.url;
        obj.id = item.data._id;

        // Get the current date and time in milliseconds
        const currentTimestamp = new Date().toJSON();

        // Format the current timestamp in the same format as '1705574420667'
        console.log(currentTimestamp);
        obj.lastUpdated = currentTimestamp;
        this.payload.attachment.push(obj);
        return obj;
      });
      let attachmentLogPayload = {
        message: `${this.payload?.customName} attachment has been added`,
        detailedAction: [],
        action: "ADDED",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "Attachment",
        category: ["accounts"],
      };
      let attachmentLogData = this.buildLogBasedOnDifferencesForArrayOfObjects(
        [],
        currentPayload,
        "name",
        "attachment"
      );
      attachmentLogPayload.detailedAction = attachmentLogData;
      if (attachmentLogPayload.detailedAction.length > 0) {
        this.initLog(attachmentLogPayload);
      }
      console.log(this.payload, "attachment payload");
      await this.addNewAccount();
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$route.params.id}/?company=${this.$route.params.companyName}`
      );
      if (payload) {
        this.notification = {
          type: "success",
          message:  this.staticData?.cmOverviewNotiMessage1?.label[this.loginUserDetails]?.label || `Nice work! Your new file is now in the list!`,
        };
      }
      console.log("attachment payload", payload.data);

      this.attachmentData = payload?.data?.attachment?.filter((item) => {
        return item["@type"] !== "profile" ? item : false;
      });

      this.initialData = JSON.parse(JSON.stringify(this.payload));
      this.updatedData = JSON.parse(JSON.stringify(this.payload));
    },
    updatedHierarchy(val) {
      this.hierarchyData = val;
    },
    async activitycurrentpage1(val) {
      this.activitycurrentpage = val;
      const logpayload = {
        type: "filter",
        module: "log",
        lastUpdated: new Date().toJSON(),
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: this.activityrowpage,
          currentPage: val,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          category: {
            $in: ["accounts"],
          },
          ref: this.$route.params.id,
        },
        searchQuery: "",
      };
      const logaccess = await MLTAxiosInstance.post(
        `/util/filterData`,
        logpayload
      );
      this.activityLogData = logaccess.data.data;
      this.activitytotal = logaccess.data.total;
    },
    async activityrowpage1(val) {
      this.activityrowpage = val;
      const logpayload = {
        type: "filter",
        module: "log",
        lastUpdated: new Date().toJSON(),
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: val,
          currentPage: this.activitycurrentpage,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          category: {
            $in: ["accounts"],
          },
          ref: this.$route.params.id,
        },
        searchQuery: "",
      };
      const logaccess = await MLTAxiosInstance.post(
        `/util/filterData`,
        logpayload
      );
      this.activityLogData = logaccess.data.data;
      this.activitytotal = logaccess.data.total;
    },
    async selectedValueFunc(val) {
      this.selectedValue = val;
      if (
        this.selectedValue.toLowerCase() !== "overview" &&
        this.view === false
      ) {
        this.payload = JSON.parse(JSON.stringify(this.initialData));
      }
      if (this.selectedValue.toLowerCase() === "activity log") {
        const logpayload = {
          type: "filter",
          module: "log",
          lastUpdated: new Date().toJSON(),
          isPaginated: true,
          paginatedQuery: {
            rowsPerPage: 10,
            currentPage: 1,
          },
          isNeedToBeSort: true,
          sortFieldAndOrder: {},
          limit: 10,
          filterQuery: {
            category: {
              $in: ["accounts"],
            },
            ref: this.$route.params.id,
          },
          searchQuery: "",
        };
        const logaccess = await MLTAxiosInstance.post(
          `/util/filterData`,
          logpayload
        );
        this.activityLogData = logaccess.data.data;
        this.activitytotal = logaccess.data.total;
        logaccess.data.data;
      }
    },
    async newValFromDropDown(e, val) {
      let obj = {};
      obj.name = e;
      if (val === "status") {
        await DMNAxiosInstance.post(`mapRef/cmAccountStatus`, obj);
        // let statusList = await DMNAxiosInstance.get(
        //   `mapRef/collection/statuslist`
        // );
        this.statusList =  await this.getReferanceData('statuslist');
      } else if (val === "industry") {
        await DMNAxiosInstance.post(`mapRef/industryType`, obj);
        // let industryTypeList = await DMNAxiosInstance.get(
        //   `mapRef/collection/industryType`
        // );
        this.industryTypeList =  await this.getReferanceData('industryType');
      } else if (val === "employeeCount") {
        await DMNAxiosInstance.post(`mapRef/cmAccountEmployeeCount`, obj);
        // let employeeCountList = await DMNAxiosInstance.get(
        //   `mapRef/collection/cmAccountEmployeeCount`
        // );
        this.employeeCountList =  await this.getReferanceData('cmAccountEmployeeCount');
      } else if (val === "locationType") {
        await DMNAxiosInstance.post(`mapRef/locationType`, obj);
        // let locationTypeList = await DMNAxiosInstance.get(
        //   `mapRef/collection/cmAccountLocationType`
        // );
        this.locationTypeList =  await this.getReferanceData('cmAccountLocationType');
      }
    },
    selectedExecutive(val) {
      let index = this.payload.relatedParty.findIndex(
        (obj) => obj.role === "Account executive"
      );
      console.log(index);
      if (index === -1) {
        let obj = {};
        obj.name = val.name;
        obj.id = val._id;
        obj.role = "Account executive";
        this.payload.relatedParty.push(obj);
      } else {
        let obj = {};
        obj.name = val.name;
        obj.id = val._id;
        obj.role = "Account executive";
        this.payload.relatedParty[index] = obj;
      }
      // console.log( this.payload.relatedParty);
    },
    async getLocations() {
      const input = this.payload?.contactMedium[3]?.characteristic.description;
      let result = [];

      try {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        const response = await MLTAxiosInstance.get(`util/${input}`);
        // console.log(response);
        result = response.data;
      } catch (error) {
        console.error("Error fetching autocomplete data:", error.message);
      }
      // console.log(result);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.locationData = result;
      if (this.locationData.length === 0 && input !== "") {
        this.showNewAddressOption = true;
      } else {
        this.showNewAddressOption = false;
      }
      return result;
    },
    async addNewAddress() {
      this.showNewAddressOption = false;
      this.newAddressPayload.description =
        this.newAddressPayload.streetNumber +
        ", " +
        this.newAddressPayload.streetName +
        ", " +
        this.newAddressPayload.city +
        ", " +
        this.newAddressPayload.state +
        ", " +
        this.newAddressPayload.country +
        ", " +
        this.newAddressPayload.postalCode +
        ".";
      // console.log(this.newAddressPayload);
      let newAddress = await DMNAxiosInstance.post(
        `mapRef/geoAddress`,
        this.newAddressPayload
      );
      if (newAddress.data.ok === 1) {
        // console.log(this.newAddressPayload.description);
      }
      if (this.payload.contactMedium !== undefined && this.payload.contactMedium[3] !== undefined) {
        this.payload.contactMedium[3].characteristic.description =
          this.newAddressPayload.description;
      }
      this.showAddNewAdrressModal = false;
      const x = {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      };
      this.newAddressPayload = JSON.parse(JSON.stringify(x));
    },
    addNewLocation(val) {
      // console.log(val);
      val;
      this.showAddNewAdrressModal = true;
      this.showNewAddressOption = false;
    },
    closeAddNewAdrressModal() {
      this.showAddNewAdrressModal = false;
    },
    receivedTags(e) {
      console.log(
        "received",
        e,
        this.payload.tags.filter((item) => item !== e[0]?.name)
      );

      this.payload.tags = e.reduce((tags, ele) => {
        if (ele.checked) {
          tags.push(ele.name);
        } else {
          tags = tags.filter((item) => item !== ele.name);
        }
        return tags;
      }, []);
    },
    async addTagList(e) {
      let obj = {};
      obj.name = e.name;
      obj.module = "cm";
      await DMNAxiosInstance.post(`mapRef/tagsList`, obj);
      let tagsListData =await this.getReferanceData('tagsList')
      let arr = this.payload.tags;
      arr.push(e.name);
      this.payload.tags = arr;
      this.tagsList =  tagsListData?.filter((item) => {
        return item.module === "cm";
      });
      // console.log(e);
      // this.tagsList.push(e);
    },
    async contactData(e) {
      // this.recievedContactData = [...val];
      console.log(e);
      // e.id = e._id;
      e.familyName = e.name;
      e.givenName = e.name;
      e.email = e.contactMedium[0].characteristic.emailAddress;
      e.company = JSON.parse(JSON.stringify(this.payload.tradingName));
      e.companyName = JSON.parse(JSON.stringify(this.payload.tradingName));
      e.tradingName = JSON.parse(JSON.stringify(this.payload.tradingName));
      e.parentCompany = JSON.parse(JSON.stringify(this.company));
      e.parentCompany = JSON.parse(JSON.stringify(this.company));
      e.rootName = JSON.parse(JSON.stringify(this.company));
      e.role = "customer-contact";
      e.relatedParty.push({
        role: e.aristocraticTitle,
        organization: this.payload.customName,
        "@type": "multiorganization",
      });
      console.log(e);
      let result
      this.isExistingName = false;
          this.isExistingNameErrorMessage = "";
          this.isExistingEmail = false;
          this.isExistingEmailErrorMessage = "";
      try {
        result = await MLTAxiosInstance.post(
          `/partymanagement/individual/account/signup`,
          e
        );
      } catch (error) {
        console.log(error,error.response.data.message.includes('db already exists with different case'), result);
        if(error.response.data.message.includes('db already exists with different case')) {
          this.isExistingName = true;
          this.isExistingNameErrorMessage = "Account already exists with different case";
          return;
        } else if(error.response.data.message.includes('This email was already registered')) {
          this.isExistingEmail = true;
          // this.isExistingEmailErrorMessage = "This email is already in use";
          this.isExistingEmailErrorMessage =
            this.staticData?.emailAlreadyUse?.label[this.loginUserDetails]?.label;
          return;
        } else {
          this.isExistingName = false;
          this.isExistingNameErrorMessage = "";
          this.isExistingEmail = false;
          this.isExistingEmailErrorMessage = "";
      }
      return
      }
      if (result.data.message == 'This email was already registered') {
        this.isExistingEmail = true;
        // this.isExistingEmailErrorMessage = "This email is already registered";
        this.isExistingEmailErrorMessage =
          this.staticData?.emailAlreadyRegistered?.label[this.loginUserDetails]?.label;
        return;
      }
      console.log('new result', result);
      
      const addedHierarchyLogPayload = {
        message: `${e.name} contact has been added`,
        detailedAction: [],
        action: "Added",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "Accounts-location",
        category: ["accounts"],
      };
      this.initLog(addedHierarchyLogPayload);
      this.notification = {
        type: "success",
        message: `"${this.staticData?.cmOverviewNotiMessage2?.label[this.loginUserDetails]?.label}"  " ${e.name} " "${this.staticData?.cmOverviewNotiMessage3?.label[this.loginUserDetails]?.label}"` || `Nice work! Your new contact  "${e.name}"  is now in the list!`,
      };
      // console.log(this.recievedContactData);
      let f = {
        company: this.$route.params.companyName,
        type: "filter",
        module: "hierarchycontacts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 2,
        filterQuery: {
          "@baseType": {
            $ne: "supplier",
          },
          company: this.$route.params.companyName,
        },
        searchQuery: "",
      };
      let x = await MLTAxiosInstance.post("util/filterData", f);
      console.log(x);
      let contacts = x.data;
      contacts.data.forEach((e) => {
        const preferredMedium = e.contactMedium.find((item) => item.preferred);
        e.preferred = preferredMedium ? preferredMedium.mediumType : null;
      });
      this.contactTableData = contacts.data;
    },
    initalpayload(val) {
      this.initalpayloads = val;
    },
    async editContactData(e) {
      // this.recievedContactData = [...val];
      // firstName log
      let CM_ContactLog = {
        message: `${e.name} Contact has been updated`,
        detailedAction: [],
        "@type": "Accounts-overview",
        category: ["accounts"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: [this.$route.params.id],
        action: "UPDATE",
        status: "active",
      };
      if (e.firstName) {
        var nameActionLog = this.buildBasicLogByDifferences(
          this.initalpayloads,
          e,
          "firstName",
          "firstName"
        );
        console.log(nameActionLog, "nameActionLog");
        // lastName log
      }
      if (e.lastName) {
        var lastNameActionLog = this.buildBasicLogByDifferences(
          this.initalpayloads,
          e,
          "lastName",
          "lastName"
        );
        console.log(lastNameActionLog, "lastNameActionLog");
      }
      // aristocraticTitle log
      if (e.aristocraticTitle) {
        var aristocraticTitleActionLog = this.buildBasicLogByDifferences(
          this.initalpayloads,
          e,
          "aristocraticTitle",
          "Designation"
        );
      }
      console.log(aristocraticTitleActionLog, "aristocraticTitleActionLog");

      // statusActionLog log
      if (e.status) {
        var statusActionLog = this.buildBasicLogByDifferences(
          this.initalpayloads,
          e,
          "status",
          "status"
        );
        console.log(statusActionLog, "statusActionLog");
      }
      // externalReference log
      if (e.externalReference) {
        var externalReferenceActionLog =
          this.buildLogBasedOnDifferencesForArrayOfObjects(
            this?.initalpayloads.externalReference, 
            e?.externalReference,
            "name",
            "Linked in"
          );
        console.log(externalReferenceActionLog, "externalReferenceActionLog");
      }
      // Address differences
      if (e.contactMedium[2].characteristic) {
        var addressActionLog = this.buildBasicLogByDifferences(
          this.initalpayloads.contactMedium[2].characteristic,
          e.contactMedium[2].characteristic,
          "description",
          "Address"
        );
        console.log(addressActionLog, "addressActionLog");
      }
      // email differences
      if (e.contactMedium[0].characteristic) {
        var emailaddressActionLog = this.buildBasicLogByDifferences(
          this.initalpayloads.contactMedium[0].characteristic,
          e.contactMedium[0].characteristic,
          "emailAddress",
          "Email"
        );
      }
      console.log(emailaddressActionLog, "emailaddressActionLog");
      if (e.contactMedium[1].characteristic.phoneNumber) {
        // phoneNumber differences
        var phoneNumberActionLog = this.buildBasicLogByDifferences(
          this.initalpayloads.contactMedium[1].characteristic.phoneNumber,
          e.contactMedium[1].characteristic.phoneNumber,
          "value",
          "phoneNumber"
        );
        console.log(phoneNumberActionLog, "phoneNumberActionLog");
      }
      CM_ContactLog.detailedAction = CM_ContactLog.detailedAction.concat(
        lastNameActionLog,
        aristocraticTitleActionLog,
        // externalReferenceActionLog,
        nameActionLog,
        statusActionLog
        // addressActionLog,
        // emailaddressActionLog,
        // phoneNumberActionLog
      );

      e.id = e._id;
      e.familyName = e.name;
      e.givenName = e.name;
      e.email = e.contactMedium[0].characteristic.emailAddress;
      e.company = JSON.parse(JSON.stringify(this.payload.tradingName));
      e.companyName = JSON.parse(JSON.stringify(this.payload.tradingName));
      e.tradingName = JSON.parse(JSON.stringify(this.payload.tradingName));
      e.parentCompany = JSON.parse(JSON.stringify(this.company));
      // e.role = 'Upc admin';
      // console.log(e);
      console.log(e);
      e.lastUpdate = new Date();
      let result = await MLTAxiosInstance.patch(
        `/partymanagement/individual/?company=${this.payload.tradingName}`,
        e
      );
      result;
      this.notification = {
        type: "success",
        message:  this.staticData?.cmOverviewNotiMessage4?.label[this.loginUserDetails]?.label || `All set! Your changes have been saved successfully!`,
      };
      this.initLog(CM_ContactLog);
      let f = {
        company: this.$route.params.companyName,
        type: "filter",
        module: "hierarchycontacts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 2,
        filterQuery: {
          "@baseType": {
            $ne: "supplier",
          },
          company: this.$route.params.companyName,
        },
        searchQuery: "",
      };
      let x = await MLTAxiosInstance.post("util/filterData", f);
      console.log(x);
      let contacts = x.data;
      contacts.data.forEach((e) => {
        const preferredMedium = e.contactMedium.find((item) => item.preferred);
        e.preferred = preferredMedium ? preferredMedium.mediumType : null;
      });
      this.contactTableData = contacts.data;
      this.contactSwitchVal()
      // console.log(this.recievedContactData);
    },
    async addNewAccount() {
      let CM_OverviewLog = {
        message: `${this.payload?.customName} Overview has been updated`,
        detailedAction: [],
        "@type": "Accounts-overview",
        category: ["accounts"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: [this.$route.params.id],
        action: "UPDATE",
        status: "active",
      };
      console.log("🚀 ~ saveSpec ~ CM_OverviewLog:", CM_OverviewLog);

      // Name log
      let nameActionLog = this.buildBasicLogByDifferences(
        this.initialData,
        this.updatedData,
        "customName",
        "Name"
      );
      console.log(nameActionLog, "nameActionLog");

      // Description log
      let descriptionActionLog = this.buildBasicLogByDifferences(
        this.initialData,
        this.updatedData,
        "description",
        "Description"
      );
      console.log(descriptionActionLog, "descriptionActionLog");

      // Status log
      let statusActionLog = this.buildBasicLogByDifferences(
        this.initialData,
        this.updatedData,
        "status",
        "Status"
      );
      console.log(statusActionLog, "statusActionLog");

      // Address differences
      let addressActionLog = this.buildBasicLogByDifferences(
        this.initialData.contactMedium[3].characteristic,
        this.updatedData.contactMedium[3].characteristic,
        "description",
        "Address"
      );
      console.log(addressActionLog, "addressActionLog");

      // email differences
      let emailaddressActionLog = this.buildBasicLogByDifferences(
        this.initialData.contactMedium[0].characteristic,
        this.updatedData.contactMedium[0].characteristic,
        "emailAddress",
        "Email"
      );
      console.log(emailaddressActionLog, "emailaddressActionLog");

      // phoneNumber differences
      let phoneNumberActionLog = this.buildBasicLogByDifferences(
        this.initialData.contactMedium[1].characteristic.phoneNumber,
        this.updatedData.contactMedium[1].characteristic.phoneNumber,
        "value",
        "phoneNumber"
      );
      console.log(phoneNumberActionLog, "phoneNumberActionLog");
      // faxNumber differences
      let faxNumberActionLog = this.buildBasicLogByDifferences(
        this.initialData.contactMedium[2].characteristic,
        this.updatedData.contactMedium[2].characteristic,
        "faxNumber",
        "fax"
      );
      console.log(faxNumberActionLog, "faxNumberActionLog");
      //webside
      let websideActionLog = this.buildLogBasedOnDifferencesForArrayOfObjects(
        this.initialData.externalReference,
        this.updatedData.externalReference,
        "name",
        "webside"
      );
      console.log(websideActionLog, "websideActionLog");
      //tags
      let tagsActionLog = this.findArrayOfStringDifferences(
        this.initialData.tags,
        this.updatedData.tags,
        "tags"
      );
      console.log(tagsActionLog, "tagsActionLog");

      //industry
      let industryActionLog = this.buildBasicLogByDifferences(
        this.initialData?.partyCharacteristic[1],
        this.updatedData?.partyCharacteristic[1],
        "value",
        "industry"
      );
      console.log(industryActionLog, "industryActionLog");

      //employees
      let employeesActionLog = this.buildBasicLogByDifferences(
        this.initialData.partyCharacteristic[0],
        this.updatedData.partyCharacteristic[0],
        "value",
        "employees"
      );
      console.log(
        this.initialData.partyCharacteristic[0],
        employeesActionLog,
        "employeesActionLog"
      );

      //location Type
      let locationTypeActionLog = this.buildBasicLogByDifferences(
        this.initialData.contactMedium[3].characteristic,
        this.updatedData.contactMedium[3].characteristic,
        "@type",
        "Location type"
      );
      console.log(locationTypeActionLog, "locationTypeActionLog");

      CM_OverviewLog.detailedAction = CM_OverviewLog.detailedAction.concat(
        locationTypeActionLog,
        employeesActionLog,
        industryActionLog,
        tagsActionLog,
        websideActionLog,
        faxNumberActionLog,
        nameActionLog,
        descriptionActionLog,
        statusActionLog,
        addressActionLog,
        emailaddressActionLog,
        phoneNumberActionLog
      );

      if (this.newProfileAdded) {
        let data = await UPCAxiosInstance.post(
          `/gcpMultiple/v2/upload/`,
          this.newProfileformData
        );
        console.log(data);

        let obj = {};
        obj.name = data.data[0].filename;
        obj.size = data.data[0].data.attachment.size;
        obj.url = data.data[0].url;
        obj.id = data.data[0].data._id;
        obj["@type"] = "profile";
        // Get the current date and time in milliseconds
        const currentTimestamp = new Date().toJSON();

        // Format the current timestamp in the same format as '1705574420667'
        console.log(currentTimestamp);
        obj.lastUpdated = currentTimestamp;
        // Find the index of the 'profile' object
        let index = this.payload.attachment.findIndex(
          (obj) => obj["@type"] === "profile"
        );
        this.profilePicName = obj.name;
        const url = await UPCAxiosInstance.get(`/gcpupload/files/${obj.name}`, {
          responseType: "arraybuffer",
        });
        this.rawProfileUrlData = url.data;
        this.profilePicUrl = await this.bufferToDataURL(url.data);

        // Replace the 'profile' object with the new object if found
        if (index !== -1) {
          this.payload.attachment[index] = obj;
        } else {
          this.payload.attachment.push(obj);
        }

        this.payload.companyName = this.payload.name;
        this.payload.parentCompany = this.company;
      }
      if (this.deleteProfilePic === true && this.newProfileAdded === false) {
        await UPCAxiosInstance.delete(
          `/gcpupload/files/${this.profilePicName}`
        );
        let index = this.payload.attachment.findIndex(
          (obj) => obj["@type"] === "profile"
        );
        if (index !== -1) {
          this.payload.attachment.splice(index, 1);
          this.rawProfileUrlData = null;
          this.profilePicUrl = "";
          this.profilePicName = "";
        }
        this.payload.companyName = this.payload.name;
        this.payload.parentCompany = this.company;
      }

      this.newProfileAdded = false;
      this.deleteProfilePic = false;
      console.log(this.payload);
      // console.log(this.recievedContactData);
      this.payload.companyName = this.payload.name;
      this.payload.parentCompany = this.company;

      this.payload.id = this.payload._id;
      delete this.payload._id;
      this.payload.lastUpdate = new Date();
      let result
      try {
        await MLTAxiosInstance.patch(
        `/partymanagement/organisation/?company=${this.$route.params.companyName}`,
        this.payload
      );
      } catch (error) {
        console.log(error,error.response.data.message.includes('db already exists with different case'), result);
        if(error.response.data.message.includes('db already exists with different case')) {
          this.isExistingName = true;
          this.isExistingNameErrorMessage = "Account already exists with different case";
          return;
        } else if(error.response.data.message.includes('email_1 dup key')) {
          this.isExistingEmail = true;
          // this.isExistingEmailErrorMessage = "This email is already in use";
          this.isExistingEmailErrorMessage =
          this.staticData?.emailAlreadyUse?.label[this.loginUserDetails]?.label
          return;
        } else {
          this.isExistingName = false;
          this.isExistingNameErrorMessage = "";
          this.isExistingEmail = false;
          this.isExistingEmailErrorMessage = "";
      }
    }
      console.log(result, "result");
      if (this.payload.status === "Inactive") {
        this.accountStatus = true;
        // let ii = {
        //   filterQuery: {
        //     "company":{"$exists":true, "$eq": this.payload.tradingName}
        //   },
        //   updatePayload: {
        //     status: "Inactive",
        //   },
        // };
        // await MLTAxiosInstance.patch(
        //   `/partymanagement/individual/batch?company=${this.payload.tradingName}`,
        //   ii
        // );
      }

      this.initialData = JSON.parse(JSON.stringify(result.data));
      this.updatedData = JSON.parse(JSON.stringify(result.data));
      this.view = true;
      if (result.data.tradingName) {
        this.notification = {
          type: "success",
          message:  this.staticData?.cmOverviewNotiMessage4?.label[this.loginUserDetails]?.label || "All set! Your changes have been saved successfully!",
        };

        this.view = true;
        if (CM_OverviewLog.detailedAction.length > 0) {
          this.initLog(CM_OverviewLog);
        }
        return;
      } else {
        // this.notification = {
        //   type: "danger",
        //   message: "Failed to add a new organization. Please try again.",
        // };
      }
      // this.recievedContactData.forEach(async (e) => {
      //   e.id = e._id;
      //   e.familyName = e.name;
      //   e.givenName = e.name;
      //   e.email = e.contactMedium[0].emailAddress;
      //   e.company = JSON.parse(JSON.stringify(this.payload.tradingName));
      //   e.companyName = JSON.parse(JSON.stringify(this.payload.tradingName));
      //   e.tradingName = JSON.parse(JSON.stringify(this.payload.tradingName));
      //   // console.log(e);
      //   let result = await MLTAxiosInstance.patch(
      //     `/partyManagement/individual/${this.$route.params.companyName}`,
      //     e
      //   );

      //   // console.log(result);
      //   if (result.data.token) {
      //     this.notification = {
      //       type: 'success',
      //       message: `New contact ${e.name} added successfully`,
      //     };
      //   } else {
      //     // this.notification = {
      //     //   type: "danger",
      //     //   message: `Failed to add contact for ${e.name}. Please try again.`,
      //     // };
      //   }
      // });
      // console.log(this.receivedInteractionData);
      // if (this.receivedInteractionData._id !== undefined) {
      //   this.receivedInteractionData.id = this.receivedInteractionData._id;
      //   let updatedInteraction = await UPCAxiosInstance.patch(
      //     `/partyInteraction/${this.$route.params.companyName}`,
      //     this.receivedInteractionData
      //   );
      //   updatedInteraction;
      //   // console.log(updatedInteraction);
      // } else {
      //   let updatedInteraction = await UPCAxiosInstance.post(
      //     `/partyInteraction/${this.$route.params.companyName}`,
      //     this.receivedInteractionData
      //   );
      //   updatedInteraction;
      //   // console.log(updatedInteraction);
      // }
      // setTimeout(() => {
      // this.goTo();
      // }, 2000);
      this.profileComponentKey += 1;
    },
    goTo() {
      this.$router.push("/CM/accounts");
      this.tempBack = true;
      console.log("ini", this.initialData, this.updatedData);
    },
    initialPayloadInt(val) {
      this.initialPayloadInteraction = val;
    },
    async editInteraction(val) {
      console.log(val, this.initialPayloadInteraction, "editInteraction");
      delete val._id;
      val.lastUpdate = new Date();
      let updatedInteraction = await UPCAxiosInstance.patch(
        `/partyInteraction?company=${this.$route.params.companyName}`,
        val
      );
      let activeval = "";
      if (val.interactionItem[0]["@type"] === "note Log") {
        activeval = `${this.payload?.customName} Interaction note has been updated`;
      } else {
        activeval = `${this.payload?.customName} email Interaction has been updated`;
      }
      let CM_OverviewLog = {
        message: activeval,
        detailedAction: [],
        "@type": "Accounts-overview",
        category: ["accounts"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: [this.$route.params.id],
        action: "UPDATE",
        status: "active",
      };
      console.log("🚀 ~ saveSpec ~ CM_OverviewLog:", CM_OverviewLog);
      let contactActionLog = this.buildBasicLogByDifferences(
        this.initialPayloadInteraction?.interactionItem[0],
        val?.interactionItem[0],
        "contact",
        "contact"
      );
      console.log(contactActionLog, "descriptionActionLog");
      let creationDateActionLog = this.buildBasicLogByDifferences(
        this.initialPayloadInteraction?.interactionItem[0],
        val?.interactionItem[0],
        "creationDate",
        "Date"
      );
      console.log(creationDateActionLog, "descriptionActionLog");
      let directionActionLog = this.buildBasicLogByDifferences(
        this.initialPayloadInteraction?.interactionItem[0],
        val?.interactionItem[0],
        "direction",
        "direction"
      );
      console.log(directionActionLog, "descriptionActionLog");
      let reasonActionLog = this.buildBasicLogByDifferences(
        this.initialPayloadInteraction?.interactionItem[0],
        val?.interactionItem[0],
        "reason",
        "reason"
      );
      console.log(reasonActionLog, "descriptionActionLog");
      let descriptionActionLog = this.buildBasicLogByDifferences(
        this.initialPayloadInteraction?.interactionItem[0],
        val?.interactionItem[0],
        "description",
        "description"
      );
      console.log(descriptionActionLog, "descriptionActionLog");
      CM_OverviewLog.detailedAction = CM_OverviewLog.detailedAction.concat(
        descriptionActionLog,
        reasonActionLog,
        directionActionLog,
        creationDateActionLog,
        contactActionLog
      );
      this.initLog(CM_OverviewLog);

      updatedInteraction;
      this.notification = {
        type: "success",
        message:  this.staticData?.cmOverviewNotiMessage4?.label[this.loginUserDetails]?.label ||  `All set! Your changes have been saved successfully!`,
      };
      let interaction = await UPCAxiosInstance.get(
        `/partyInteraction/?company=${this.$route.params.companyName}`
      );
      console.log(interaction);
      this.interactionData = interaction?.data;
    },
    deleteinteraction(val) {
      console.log(val, "deleteinteraction");
      let activeval = "";
      if (val.interactionItem[0]["@type"] === "note Log") {
        activeval = val.interactionItem[0].description;
      } else {
        activeval = val.interactionItem[0].reason;
      }
      const addedHierarchyLogPayload = {
        message: `${this.payload?.customName} Interaction has been deleted`,
        detailedAction: [
          {
            item: activeval,
            action: "REMOVED",
            property: val.interactionItem[0]["@type"],
          },
        ],
        action: "REMOVED",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "Accounts-Interaction",
        category: ["accounts"],
      };
      this.initLog(addedHierarchyLogPayload);
    },
    async deleteInteractionPayload(val) {
      console.log(val);
      // val.lastUpdate = new Date();
      let updatedInteraction = await UPCAxiosInstance.delete(
        `/partyInteraction/${val}/?company=${this.$route.params.companyName}`
      );

      updatedInteraction;
      this.notification = {
        type: "success",
        message:  this.staticData?.cmOverviewNotiMessage4?.label[this.loginUserDetails]?.label ||  `Your item has been removed from the log!`,
      };
      let interaction = await UPCAxiosInstance.get(
        `/partyInteraction/?company=${this.$route.params.companyName}`
      );
      console.log(interaction);
      this.interactionData = interaction?.data;
    },
    async receivedInteractionPayload(val) {
      console.log(val, "receivedInteractionData");
      this.receivedInteractionData = JSON.parse(JSON.stringify(val));
      let updatedInteraction = await UPCAxiosInstance.post(
        `/partyInteraction/?company=${this.$route.params.companyName}`,
        this.receivedInteractionData
      );
      let activeval = "";
      if (val.interactionItem[0]["@type"] === "note Log") {
        activeval = val.interactionItem[0].description;
      } else {
        activeval = val.interactionItem[0].reason;
      }
      const addedHierarchyLogPayload = {
        message: `${this.payload?.customName} Interaction has been added`,
        detailedAction: [
          {
            item: activeval,
            action: "ADDED",
            property: val.interactionItem[0]["@type"],
          },
        ],
        action: "Added",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "Accounts-Interaction",
        category: ["accounts"],
      };
      this.initLog(addedHierarchyLogPayload);
      updatedInteraction;
      this.notification = {
        type: "success",
        message:  this.staticData?.cmOverviewNotiMessage6?.label[this.loginUserDetails]?.label ||  `Nice work! Your new log is now in the list!`,
      };
      let interaction = await UPCAxiosInstance.get(
        `/partyInteraction/?company=${this.$route.params.companyName}`
      );
      console.log(interaction);
      this.interactionData = interaction?.data;
      // if (this.receivedInteractionData._id !== undefined) {
      //   // this.receivedInteractionData.id = this.receivedInteractionData._id;
      //   // let updatedInteraction = await UPCAxiosInstance.patch(
      //   //   `/partyInteraction/?company=${this.$route.params.companyName}`,
      //   //   this.receivedInteractionData
      //   // );
      //   // updatedInteraction;
      //   // console.log(updatedInteraction);
      // } else {
      //   let updatedInteraction = await UPCAxiosInstance.post(
      //     `/partyInteraction/?company=${this.$route.params.companyName}`,
      //     this.receivedInteractionData
      //   );
      //   updatedInteraction;
      // console.log(updatedInteraction);
      // }
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 770px) {
  .layout-breadcrumb.expanded-side-panel {
    margin-top: 12px !important;
  }
}
@media (min-width: 770px) {
  .expanded-side-panel {
    display: none;
  }
}
</style>
