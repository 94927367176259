<template>
  <hlx-alert-notification
    :show-alert="alertmsg"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-drawer
    :show="show_right_filter"
    :width="300"
    position="right"
    :header="false"
    :footer="false"
    :show-close-icon="true"
    @close="closeRightFilter"
  >
    <template #body>
      <div style="display: flex">
        <FilterComponent
          :filterpanelname="'ticket'"
          :company-name="companyasignee"
          :query-value="query"
          :display-data="filter"
          :module="'ticket'"
          @filtered-data="ticketfilterData"
        ></FilterComponent>
      </div>
    </template>
  </hlx-drawer>
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
  <div class="add-feature-drawer">
    <hlx-drawer
      :show="isModal"
      position="right"
      width="400"
      :footer="true"
      :show-close-icon="true"
      @close="closeDiscount"
    >
      <template #body>
        <div style="height: 90vh !important">
          <div
            style="
              border-bottom: 1px solid #d4d4d4;
              padding-bottom: 5px;
              padding: 13px;
            "
          >
            <b v-if="editMode == false">New ticket</b>
            <b v-if="editMode == true">Edit ticket</b>
          </div>

          <div
            :key="F"
            style="
              display: flex;
              padding: 18px 13px;
              flex-direction: column;
              gap: 18px;
            "
          >
            <span
              v-for="(addFieldsObject, index) in labelFieldsValue"
              :key="index"
            >
              <!-- Dropdown preview -->
              <div
                v-if="addFieldsObject['@type'] == 'Dropdown'"
                class="feature-value-dropdown-preview"
              >
                <span v-if="addFieldsObject.name === 'Requester'">
                  <hlx-select
                    :key="componentkey1R"
                    v-model:value="
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :pre-value="
                      preValFunc(
                        payload.troubleTicketSpecification[0][
                          addFieldsObject.name
                        ],
                        addFieldsObject
                      )
                    "
                    :options="contactPayload"
                    :placeholder-value="addFieldsObject.name"
                    :label-animation="true"
                    :inline-search="true"
                    :required="addFieldsObject.mandatory"
                    :clearable="true"
                    :label="'name'"
                    :prop-value="'name'"
                    @selected-value="RelatedPArtyData"
                  ></hlx-select>
                </span>
                <span v-else-if="addFieldsObject.name === 'Assigned to'">
                  <hlx-select
                    v-model:value="
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :pre-value="
                      preValFunc(
                        payload.troubleTicketSpecification[0][
                          addFieldsObject.name
                        ],
                        addFieldsObject
                      )
                    "
                    :options="assignedToo"
                    :placeholder-value="addFieldsObject.name"
                    :label="'name'"
                    :prop-value="'name'"
                    :label-animation="true"
                    :search-image-tag="true"
                    :sub-title="'company'"
                    :tag-value="'newRole'"
                    :image-link="'link'"
                    :inline-search="true"
                    @selected-value="AssignedData"
                  ></hlx-select>
                </span>
                <span v-else-if="addFieldsObject.name === 'Ticket category'">
                  <hlx-select
                    :key="componentkey1"
                    v-model:value="
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :pre-value="
                      preValFunc(
                        payload.troubleTicketSpecification[0][
                          addFieldsObject.name
                        ],
                        addFieldsObject
                      )
                    "
                    :options="addFieldsObject.characteristicSpecification"
                    :placeholder-value="addFieldsObject.name"
                    :label-animation="true"
                    :inline-search="true"
                    :required="addFieldsObject.mandatory"
                    :clearable="true"
                    :label="'value'"
                    :prop-value="'value'"
                    @selected-value="TicketcategoryData"
                  ></hlx-select>
                </span>
                <span v-else>
                  <hlx-select
                    :key="componentkey1"
                    v-model:value="
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :pre-value="
                      preValFunc(
                        payload.troubleTicketSpecification[0][
                          addFieldsObject.name
                        ],
                        addFieldsObject
                      )
                    "
                    :options="addFieldsObject.characteristicSpecification"
                    :placeholder-value="addFieldsObject.name"
                    :label-animation="true"
                    :required="addFieldsObject.mandatory"
                    :clearable="true"
                    :label="'value'"
                    :prop-value="'value'"
                  ></hlx-select>
                </span>
              </div>

              <!-- Radio Button -->
              <div v-if="addFieldsObject['@type'] == 'Radio button'">
                <div v-if="addFieldsObject['@type'] == 'Radio button'">
                  <b>
                    {{ addFieldsObject.name }}
                    <sup v-if="addFieldsObject.mandatory" style="color: #ff5050"
                      >*</sup
                    ></b
                  >
                  <br />
                </div>
                <br />
                <div
                  v-if="addFieldsObject['@type'] == 'Radio button'"
                  class="feature-value-radio-preview"
                >
                  <hlx-input
                    v-model:value="
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :pre-val="
                      preValFunc(
                        payload.troubleTicketSpecification[0][
                          addFieldsObject.name
                        ],
                        addFieldsObject
                      )
                    "
                    :required="addFieldsObject.mandatory"
                    :options="addFieldsObject.characteristicSpecification"
                    :position="'horizontal'"
                    :prop-value="'value'"
                    :label="'value'"
                    :type="'border-radio-buttons'"
                  />
                </div>
              </div>
              <!--------- Date ----->
              <div
                v-if="addFieldsObject['@type'] == 'Date'"
                class="feature-value-radio-preview"
                :style="[
                  addFieldsObject['@type'] == 'Date'
                    ? 'display:block !important'
                    : 'display:flex !important',
                ]"
              >
                <hlx-input
                  v-model:value="
                    payload.troubleTicketSpecification[0][addFieldsObject.name]
                  "
                  :pre-val="
                    payload.troubleTicketSpecification[0][addFieldsObject.name]
                  "
                  :required="addFieldsObject.mandatory"
                  :date-format="'dd (short month), yyyy'"
                  :label-value="addFieldsObject.name"
                  :label-animation="true"
                  type="date"
                  icon="calendar-regular"
                  icon-position="right"
                />
              </div>
              <!-- Single line Text -->
              <div
                v-if="addFieldsObject['@type'] == 'Single line text'"
                class="feature-value-radio-preview"
                :style="[
                  addFieldsObject['@type'] == 'Single line text'
                    ? 'display:block !important'
                    : 'display:flex !important',
                ]"
              >
                <span
                  v-if="
                    payload.troubleTicketSpecification[0][
                      addFieldsObject.name
                    ] === 'Email id'
                  "
                >
                  <hlx-input
                    v-model:value="
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :pre-val="
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :required="addFieldsObject.mandatory"
                    type="email"
                    :label-animation="true"
                    :label-value="addFieldsObject.name"
                    :display-error="false"
                    :custom-error="isEmailEmpty"
                    :custom-error-message="'Enter a valid email.(eg: user@example.com)'"
                    @at-input="
                      validateEmail(
                        payload.troubleTicketSpecification[0][
                          addFieldsObject.name
                        ],
                        addFieldsObject.name
                      )
                    "
                  />
                </span>
                <span v-else>
                  <hlx-input
                    v-model:value="
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :pre-val="
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    "
                    :required="addFieldsObject.mandatory"
                    :type="'text'"
                    :label-animation="true"
                    :label-value="addFieldsObject.name"
                  />
                </span>
              </div>

              <!-- Multi line Text -->
              <div
                v-if="addFieldsObject['@type'] == 'Multi line text'"
                class="feature-value-radio-preview"
                :style="[
                  addFieldsObject['@type'] == 'Multi line text'
                    ? 'display:block !important'
                    : 'display:flex !important',
                ]"
              >
                <hlx-input
                  v-model:value="
                    payload.troubleTicketSpecification[0][addFieldsObject.name]
                  "
                  :pre-val="
                    payload.troubleTicketSpecification[0][addFieldsObject.name]
                  "
                  :type="'textarea'"
                  :required="addFieldsObject.mandatory"
                  :label-animation="true"
                  :label-value="addFieldsObject.name"
                />
              </div>

              <!-- Multi select -->

              <div
                v-if="addFieldsObject['@type'] == 'Multi select'"
                class="feature-value-radio-preview"
              >
                <hlx-multi-select
                  :key="componentKeyM"
                  :required="addFieldsObject.mandatory"
                  :pre-value="
                    preValFunction(
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ],
                      addFieldsObject
                    )
                  "
                  :options="addFieldsObject.characteristicSpecification"
                  :tooltipposition="'left'"
                  :placeholder="addFieldsObject.name"
                  :label-animation="true"
                  :prop-value="'value'"
                  :label="'value'"
                  :disabled="false"
                  @selected-array="selectedValue($event, addFieldsObject.name)"
                ></hlx-multi-select>
              </div>

              <!-- Number -->
              <div
                v-if="addFieldsObject['@type'] == 'Number'"
                class="feature-value-radio-preview"
                :style="[
                  addFieldsObject['@type'] == 'Number'
                    ? 'display:block !important'
                    : 'display:flex !important',
                ]"
              >
                <hlx-input
                  v-model:value="
                    payload.troubleTicketSpecification[0][addFieldsObject.name]
                  "
                  :pre-val="
                    payload.troubleTicketSpecification[0][addFieldsObject.name]
                  "
                  :required="addFieldsObject.mandatory"
                  :type="'number'"
                  :label-animation="true"
                  :label-value="addFieldsObject.name"
                />
              </div>

              <!-- Decimal -->
              <div
                v-if="addFieldsObject['@type'] == 'Decimal'"
                class="feature-value-radio-preview"
                :style="[
                  addFieldsObject['@type'] == 'Decimal'
                    ? 'display:block !important'
                    : 'display:flex !important',
                ]"
              >
                <hlx-input
                  v-model:value="
                    payload.troubleTicketSpecification[0][addFieldsObject.name]
                  "
                  :pre-val="
                    payload.troubleTicketSpecification[0][addFieldsObject.name]
                  "
                  :type="'number'"
                  :step="0.1"
                  :label-animation="true"
                  :label-value="addFieldsObject.name"
                  :required="addFieldsObject.mandatory"
                />
              </div>

              <!-- Address -->

              <div
                v-if="addFieldsObject['@type'] == 'Address'"
                class="feature-value-radio-preview"
              >
                <!-- {{payload.troubleTicketSpecification[0][addFieldsObject.name]}} -->
                <addressComponent
                  :view="false"
                  :required="addFieldsObject.mandatory"
                  :pre-val="
                    addressPreValue(
                      payload.troubleTicketSpecification[0][
                        addFieldsObject.name
                      ]
                    )
                  "
                  @address-data="
                    receivedAddressData($event, addFieldsObject.name)
                  "
                ></addressComponent>
              </div>
              <!-- Attachment -->
              <div
                v-if="addFieldsObject['@type'] == 'Attachment'"
                class="feature-value-attachment-preview"
              >
                <attachment
                  :key="attachmnetComponetKey"
                  :pre-value="
                    payload.troubleTicketSpecification[0][addFieldsObject.name]
                  "
                  :required="addFieldsObject.mandatory"
                  @file-upload="attachmentEmit($event, addFieldsObject.name)"
                  @removed-data="removedEmit($event, addFieldsObject.name)"
                  @fileuploaddata="convertData"
                ></attachment>
                <!-- :pre-val=" payload?.tro ubleTicketSpecification[0][addFieldsObject?.name]" -->
              </div>
            </span>
          </div>
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 16px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeDiscount"
            >Cancel</hlx-button
          >
          <hlx-button
            v-if="editMode == false"
            class="primary sm"
            style="margin-left: 10px"
            :disabled="!isFormValid"
            @click="addNewticket"
            >Add</hlx-button
          >
          <hlx-button
            v-else
            class="primary sm"
            style="margin-left: 10px"
            :disabled="!isFormValid"
            @click="editedTicket(id, payload)"
            >Save</hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>
  <section class="cm-accounts-landing-layout">
    <section
      v-if="chatappview === false"
      class="cm-accounts-right-panel-container"
    >
      <div
        class="cm-accounts-right-panel-top-section"
        style="overflow-x: hidden; overflow-y: auto"
      >
        <div
          class="cm-accounts-right-panel-top-section-title"
          style="margin-bottom: 8px"
        >
          Tickets
        </div>
        <div class="cm-accounts-right-panel-top-section-description">
          Centralized table for tickets.
        </div>
        <hlx-drawer
          :show="show_right"
          :width="1000"
          position="right"
          :footer="false"
          :show-close-icon="true"
          @close="closeactivitylog"
        >
          <template #body>
            <div class="activitylog_drawer_componet">
              <activityLogComponent
                :key="activtykey"
                :activity-data="activityLogData"
                :totalfromprop="activitytotal"
                :module="'leftPanal'"
                @activityrowpage="activityrowpage1"
                @activitycurrentpage="activitycurrentpage1"
              ></activityLogComponent>
            </div>
          </template>
        </hlx-drawer>
        <div
          v-if="tableData.length > 0 || searchvalue !== ''"
          class="cm-accounts-right-panel-top-section-utility-container"
          style="padding: 0.8rem 0px; gap: 12px !important"
        >
          <hlx-search @search-key="searchKey"></hlx-search>
          <span @click="activityLogDrawer()"
            ><i
              class="icon-time-forward-regular"
              style="font-size: 24px; color: rgb(84, 189, 149)"
            ></i
          ></span>
          <span class="filter-table">
            <hlx-button class="secondary sm add-btn" @click="showDrawerRight">
              <i class="icon-filter-regular"></i>
              <span v-if="filtertaglength != 0" class="filter-count">{{
                filtertaglength
              }}</span>
            </hlx-button>
          </span>

          <!--drawer component put outside the screen-->

          <!--search key-->

          <span
            ><hlx-button
              v-if="isDataLoaded && permisstionData.add "
              class="primary sm empty-table-add-button"
              @click="showNewContactModal"
            >
              <i class="icon-plus-circle-regular"></i>
              Add Tickets
            </hlx-button></span
          >
        </div>
        <div
          v-if="copyOfTicketTableData.length > 0"
          style="height: 78vh; overflow: auto"
          class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
        >
          <hlx-table
            :column-count="tickets_table_theads.length + 2"
            :border="['table', 'header', 'horizontal']"
            :row-hover="true"
            theme="grey"
            :re-arrange-columns="toggleRearrangeModal"
            :re-arrange-heads="tickets_table_theads_cc"
            @close-rearrange="toggleRearrangeModal=false"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
              <hlx-table-head
                :width="60"
                :align="'center'"
                style="padding: 0 15px"
                :fixed="'left'"
                @new-resize-width="resizedWidth"
                >{{ "S.no" }}</hlx-table-head
              >
              <hlx-table-head
                v-for="(i, index) in tickets_table_theads"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
                @new-resize-width="resizedWidth"
              >
                {{ i.label }}</hlx-table-head
              >
              <hlx-table-head v-if="permisstionData.edit || permisstionData.delete" :align="'center'" :width="70" :fixed="'right'" @new-resize-width="resizedWidth"
                ><span class="action-icon">
                  <i
                    class="icon-settings-regular"
                    @click="reAssignOrgTableHeader()"
                  ></i> </span
              ></hlx-table-head>
            </template>
            <template #tbody>
              <tr v-for="(i, index) in paginatedData" id="" :key="index">
                <hlx-table-cell :align="'center'" :fixed="'left'">
                  {{ serialNumber(index) }}
                </hlx-table-cell>
                <hlx-table-cell
                  v-for="(j, col_index) in tickets_table_theads"
                  :key="col_index"
                  :align="j.align"
                  :fixed="j.fixed"
                >
                  <div
                    v-if="j.prop.toLowerCase() === 'name'"
                    style="margin: 0.5rem 0"
                  >
                    {{ i.name }}
                  </div>

                  <div
                    v-if="j.prop?.toLowerCase() === 'displayid'"
                    :id="j.prop + index"
                    style="margin: 0.5rem 0"
                    class="link-name"
                    @click="chatApp(i)"
                  >
                    {{ i.displayID }}
                  </div>

                  <div
                    v-if="j.prop?.toLowerCase() === 'subject'"
                    style="margin: 0.5rem 0"
                  >
                    {{ i.troubleTicketSpecification[0]["Subject"] }}
                  </div>
                  <div
                    v-if="j.prop?.toLowerCase() === 'user'"
                    style="margin: 0.5rem 0"
                  >
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        margin-bottom: 4px;
                      "
                    >
                      <i class="icon-user-alt-regular"></i>
                      {{ i.troubleTicketSpecification[0]["Requester"] }}
                    </div>
                    <div
                      style="display: flex; align-items: center; gap: 0.5rem"
                    >
                      <i class="icon-envelope-regular"></i>
                      {{ i.troubleTicketSpecification[0]["Email id"] }}
                    </div>
                  </div>
                  <div
                    v-if="j.prop?.toLowerCase() === 'type'"
                    style="margin: 0.5rem 0"
                  >
                    {{ i[j.prop] }}
                  </div>
                  <div
                    v-if="j.prop?.toLowerCase() === 'account'"
                    style="margin: 0.5rem 0"
                  >
                    {{ i.relatedParty[0]?.company }}
                  </div>
                  <div
                    v-if="j.prop?.toLowerCase() === 'createdat'"
                    style="margin: 0.5rem 0"
                  >
                    {{ dateAndTimeFormatter(i[j.prop]) }}
                  </div>
                  <div
                    v-if="j.prop?.toLowerCase() === 'accounts'"
                    style="margin: 0.5rem 0"
                  >
                    {{ companyasignee }}
                  </div>
                  <div
                    v-if="
                      j.prop?.toLowerCase() === 'troubleticketspecification'
                    "
                    style="margin: 0.5rem 0"
                  >
                    <span style="display: flex; gap: 6px">
                      <span>{{
                        i.troubleTicketSpecification[0]["Assigned to"]
                      }}</span>
                      <span
                        v-if="i.troubleTicketSpecification[0]['Assigned to']"
                        ><hlx-label class="primary sm">{{
                          "Upc Admin"
                        }}</hlx-label></span
                      >
                    </span>
                  </div>
                  <div
                    v-if="j.prop?.toLowerCase() === 'priority'"
                    style="margin: 0.5rem 0"
                  >
                    <span
                      v-if="i[j.prop]?.toLowerCase() === 'high'"
                      style="display: flex; gap: 2px"
                    >
                      <span
                        style="padding-top: 2px; color: rgba(252, 50, 50, 1)"
                        ><i class="icon-flag-filled"
                      /></span>
                      <span> {{ i.priority }}</span>
                    </span>
                    <span
                      v-if="i[j.prop]?.toLowerCase() === 'medium'"
                      style="display: flex; gap: 2px"
                    >
                      <span
                        style="padding-top: 2px; color: rgba(239, 201, 0, 1)"
                        ><i class="icon-flag-filled"
                      /></span>
                      <span> {{ i.priority }}</span>
                    </span>
                    <span
                      v-if="i[j.prop]?.toLowerCase() === 'low'"
                      style="display: flex; gap: 2px"
                    >
                      <span
                        style="padding-top: 2px; color: rgba(112, 187, 152, 1)"
                        ><i class="icon-flag-filled"
                      /></span>
                      <span> {{ i.priority }}</span>
                    </span>
                  </div>
                  <div
                    v-if="j.prop?.toLowerCase() === 'status'"
                    style="margin: 0.5rem 0"
                  >
                    <span
                      v-if="
                        i.troubleTicketSpecification[0].Status?.toLowerCase() ===
                        'open'
                      "
                      style="display: flex; gap: 2px"
                    >
                      <HlxStatus
                        :type="'custom'"
                        :point-color="'rgba(252, 50, 50, 1)'"
                        :status-value="i.troubleTicketSpecification[0].Status"
                        >{{ i[j.prop] }}</HlxStatus
                      ></span
                    >
                    <span
                      v-if="
                        i.troubleTicketSpecification[0].Status?.toLowerCase() ===
                        'close'
                      "
                      style="display: flex; gap: 2px"
                    >
                      <HlxStatus
                        :type="'custom'"
                        :point-color="'rgba(2, 181, 92, 1)'"
                        :status-value="i.troubleTicketSpecification[0].Status"
                        >{{ i[j.prop] }}</HlxStatus
                      >
                    </span>
                    <span
                      v-if="
                        i.troubleTicketSpecification[0].Status?.toLowerCase() ===
                        'in progress'
                      "
                      style="display: flex; gap: 2px"
                    >
                      <HlxStatus
                        :type="'custom'"
                        :point-color="'#F9A645'"
                        :status-value="i.troubleTicketSpecification[0].Status"
                        >{{ i[j.prop] }}</HlxStatus
                      >
                    </span>
                  </div>

                  <!-- <div v-else>{{ i[j.prop] }}</div> -->
                </hlx-table-cell>
                <hlx-table-cell v-if="permisstionData.edit || permisstionData.delete" :align="'center'" :width="120" :fixed="'right'">
                  <span :id="'table-context' + index" class="action-icon">
                    <i
                      style="position: relative"
                      class="icon-more-vertical-filled"
                      :class="i.context === true ? 'active-action' : ''"
                      @click="crudContextMenu($event, index)"
                    >
                      <hlx-context-menu
                        :top="containertop"
                        :left="containerleft"
                        :conditionvalue="150"
                        :conditiondown="2"
                        :conditionup="-38"
                        :options="computedTableAction(i)"
                        :data="index.toString()"
                        :show="i.context == true"
                        @chosen="closeCrudContextMenu($event, i, index)"
                      />
                    </i>
                  </span>
                </hlx-table-cell>
              </tr>
            </template>
          </hlx-table>
        </div>

        <div
          v-if="copyOfTicketTableData.length === 0 && searchvalue !== ''"
          style="height: 78vh; overflow: auto"
          class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
        >
          <hlx-table
            :column-count="tickets_table_theads.length + 2"
            :border="['table', 'header']"
            :row-hover="true"
            theme="grey"
            :re-arrange-columns="toggleRearrangeModal"
            :re-arrange-heads="tickets_table_theads_cc"
            @close-rearrange="toggleRearrangeModal=false"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
              <hlx-table-head
                :width="60"
                :align="'center'"
                style="padding: 0 15px"
                :fixed="'left'"
                @new-resize-width="resizedWidth"
                >{{ "S.no" }}</hlx-table-head
              >
              <hlx-table-head
                v-for="(i, index) in tickets_table_theads"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
                @new-resize-width="resizedWidth"
              >
                {{ i.label }}</hlx-table-head
              >
              <hlx-table-head v-if="permisstionData.edit || permisstionData.delete" :align="'center'" :width="70" :fixed="'right'" @new-resize-width="resizedWidth"
                ><span class="action-icon">
                  <i
                    class="icon-settings-regular"
                    @click="reAssignOrgTableHeader()"
                  ></i> </span
              ></hlx-table-head>
            </template>

            <template #tbody>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell :colspan="tickets_table_theads.length + 2">{{
                  "No data"
                }}</hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
            </template>
          </hlx-table>
        </div>

        <div
          v-if="tableData?.length <= 0"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 40px;
            height: 70vh;
          "
        >
          <img
            src="@/assets/images/svg/ticketIllustration.svg"
            alt=""
            width="500px"
            height="500px"
          />
          <span
            ><hlx-button
              v-if="isDataLoaded && permisstionData.add "
              class="primary sm empty-table-add-button"
              @click="showNewContactModal"
            >
              <i class="icon-plus-circle-regular"></i>
              Add Tickets
            </hlx-button></span
          >
        </div>
      </div>
      <div
        v-if="tableData?.length > 10"
        class="cm-accounts-right-panel-pagination-container"
      >
        <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
          :total="tableData?.length"
          rows-per-page
          enable-rows-per-page
          :rows-per-page-list="[10, 15, 20]"
          :options="{
            attributes: {
              rowsPerPageTitle: true,
              rowsPerPageResult: true,
              rowsPerPageDropdown: {
                position: {
                  top: 0,
                  right: 1,
                },
              },
            },
          }"
          @current-page="currentPage"
          @updated:rows-per-page="changeRowsPerPage"
        ></hlx-pagination>
      </div>
    </section>
  </section>
</template>

<script>
import FilterComponent from "@/components/filterPanelComponent.vue";
import { UPCAxiosInstance, MLTAxiosInstance } from "@/config/axiosConfig";
import attachment from "@/components/singleEmailComponent.vue";
import activityLogComponent from "@/components/SM/activityLogComponent.vue";

import addressComponent from "@/components/CM/autocompleteComponent.vue";
// import chatTicketVue from "@/components/Ticket/chatTicket.vue";
export default {
  components: {
    attachment,
    activityLogComponent,
    // chatTicketVue,
    addressComponent,
    FilterComponent,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    mode: {
      type: String,
      default: "edit",
    },
    supplierStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      permisstionData:{},
      tableHeaderOrg:[],
      tableHeaderOrgcc:[],
      assiginEmail:"",
      assiginname:"",
      activtykey: 0,
      initialChargeval: {},
      show_right: false,
      activityLogData: [],
      filtertagvalue: [],
      chatappData: {},
      chatappview: false,
      contactPayload: [],
      query: {
        Status: ["status"],
        Priority: ["priority"],
        Name: ["name"],
        Account: ["relatedParty.company"],
        "Assigned to": ["troubleTicketSpecification.Assigned to"],
      },
      currentYear: new Date().getFullYear(),
      assignedToo: [],
      labelFieldsValue: [],
      duplicateLabelValues: [],
      searchvalue: "",
      deleteobj: {},
      isModal: false,
      addressPreVal: {
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
        },
      },
      payload: {
        name: "",
        status: "",
        "@type": "TroubleTicket",
        priority: "",
        troubleTicketSpecification: [],
        relatedParty: [
          {
            id: "",
            name: "",
            role: "",
            company: "",
          },
        ],
      },
      contracttype: [
        { name: "Service Agreement" },
        { name: "Purchase Agreement" },
        { name: " Non-Disclosure Agreement (NDA)" },
        { name: "Partnership Agreement" },
      ],
      editMode: false,
      statusList: [],
      // statusList: [
      //   {
      //     name: "Inprogress",
      //     value: "inProgress",
      //     color: "#2879b7",
      //   },
      //   {
      //     name: "Hold",
      //     value: "hold",
      //     color: "#f8a523",
      //   },
      //   {
      //     name: "Acknowledged",
      //     value: "acknowledged",
      //     color: "#86a523",
      //   },
      //   {
      //     name: "Completed",
      //     value: "completed",
      //     color: "#007247",
      //   },
      //   {
      //     name: "Rejected",
      //     value: "rejected",
      //     color: "#ff5050",
      //   },
      // ],
      instanceAddress: {},
      isEmailEmpty: false,
      tablehead: "Tickets",
      updateKey: 0,
      externalRowsPerPage: 10,
      companyasignee: "",
      tableData: [],
      notification: [],
      filterQuery: {
        type: "filter",
        module: "ticket",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {
          // "@baseType":"supplier"
        },
        searchQuery: "",
        company: "",
      },
      componentKeyM: 0,
      tickets_table_theads_cc: [
      {
          checked:true,
          name:"ID",
          sortable: false,
          resizable: true,
          disabled:true,
          width: null,
          label: "ID",
          prop: "displayID",
          align: "left",
        },
        {
          checked:true,
          name:"Subject",
          sortable: false,
          resizable: true,
          disabled:false,
          width: 200,
          label: "Subject",
          prop: "subject",
          align: "left",
        },
        {
          checked:true,
          name:"Status",
          sortable: false,
          resizable: true,
          disabled:true,
          width: 100,
          label: "Status",
          prop: "status",
          align: "left",
        },
        {
          checked:true,
          name:"Priority",
          sortable: false,
          resizable: true,
          disabled:false,
          width: null,
          label: "Priority",
          prop: "priority",
          align: "left",
        },
        {
          checked:true,
          name:"Category",
          sortable: false,
          resizable: true,
          disabled:false,
          width: 200,
          label: "Category",
          prop: "name",
          align: "left",
        },
        {
          checked:true,
          name:"Assigned To",
          sortable: false,
          resizable: true,
          disabled:false,
          width: 200,
          label: "Assigned To",
          prop: "troubleTicketSpecification",
          align: "left",
        },
        {
          checked:true,
          name:"Requester contact",
          sortable: false,
          resizable: true,
          disabled:true,
          width: 240,
          label: "Requester contact",
          prop: "user",
          align: "left",
        },
        {
          checked:true,
          name:"Account",
          sortable: false,
          resizable: true,
          disabled:false,
          width: 150,
          label: "Account",
          prop: "account",
          align: "left",
        },
        {
          checked:true,
          name:"Created on",
          disabled:false,
          sortable: false,
          resizable: true,
          width: 180,
          label: "Created on",
          prop: "createdAt",
          align: "left",
        },
      ],
      tickets_table_theads: [
        {
          checked:true,
          name:"ID",
          sortable: false,
          resizable: true,
          disabled:true,
          width: null,
          label: "ID",
          prop: "displayID",
          align: "left",
        },
        {
          checked:true,
          name:"Subject",
          sortable: false,
          resizable: true,
          disabled:false,
          width: 200,
          label: "Subject",
          prop: "subject",
          align: "left",
        },
        {
          checked:true,
          name:"Status",
          sortable: false,
          resizable: true,
          disabled:true,
          width: 100,
          label: "Status",
          prop: "status",
          align: "left",
        },
        {
          checked:true,
          name:"Priority",
          sortable: false,
          resizable: true,
          disabled:false,
          width: null,
          label: "Priority",
          prop: "priority",
          align: "left",
        },
        {
          checked:true,
          name:"Category",
          sortable: false,
          resizable: true,
          disabled:false,
          width: 200,
          label: "Category",
          prop: "name",
          align: "left",
        },
        {
          checked:true,
          name:"Assigned To",
          sortable: false,
          resizable: true,
          disabled:false,
          width: 200,
          label: "Assigned To",
          prop: "troubleTicketSpecification",
          align: "left",
        },
        {
          checked:true,
          name:"Requester contact",
          sortable: false,
          resizable: true,
          disabled:true,
          width: 240,
          label: "Requester contact",
          prop: "user",
          align: "left",
        },
        {
          checked:true,
          name:"Account",
          sortable: false,
          resizable: true,
          disabled:false,
          width: 150,
          label: "Account",
          prop: "account",
          align: "left",
        },
        {
          checked:true,
          name:"Created on",
          disabled:false,
          sortable: false,
          resizable: true,
          width: 180,
          label: "Created on",
          prop: "createdAt",
          align: "left",
        },
      ],
      attachmnetComponetKey: 0,
      componentkey1R: 0,
      paylaodpre: "",
      name: "",
      filter: [],
      copyOfTicketTableData: [],
      duplicateTheads: [],
      toggleRearrangeModal: false,
      currPage: 1,
      rowsPerPage: 10,
      contractData: [],
      filterPanelName: "productSpecification",
      modal_active: false,
      delete_modal_active: false,
      templatePayload: [],
      copytemplatePayload: [],
      SpecificationcurrPage: 1,
      SpecificationrowsPerPage: 10,
      show_right_filter: false,
      isDataLoaded: false,
      filtertaglength: 0,
    };
  },
  computed: {
    paginatedData() {
      return this.tableData;
    },
    mandatoryFields() {
      const q = this.labelFieldsValue
        .filter((field) => field.mandatory)
        .map((field) => field.name);
      console.log(
        "loooog mandatoryFields",
        q,
        this.payload.troubleTicketSpecification[0]
      );

      return q;
    },
    areMandatoryFieldsFilled() {
      const r = this.mandatoryFields.every(
        (field) => this.payload.troubleTicketSpecification[0][field] !== ""
      );
      console.log(
        "loooog areMandatoryFieldsFilled",
        r,
        this.payload.troubleTicketSpecification[0]
      );

      return r;
    },
    isEmailValid() {
      if (
        this.payload &&
        this.payload.troubleTicketSpecification[0] &&
        this.payload.troubleTicketSpecification[0]["Email id"]
      ) {
        const email = this.payload.troubleTicketSpecification[0]["Email id"];
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      } else {
        return true;
      }
    },
    // Validate the entire form
    isFormValid() {
      return this.areMandatoryFieldsFilled && this.isEmailValid;
    },
  },
  watch: {
    tableData: {
      handler(val) {
        this.copyOfTicketTableData = JSON.parse(JSON.stringify(val));
      },
      immediate: true,
      deep: true,
    },
    payload: {
      handler(val) {
        // this.copyOfTicketTableData = JSON.parse(JSON.stringify(val));
        console.log(val, "payload");
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    // this.recalltable()
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");

let sampleTheadsd = await MLTAxiosInstance.get(
  `partyRole/get/${val.data.userId}`
);

const mergedPayload = sampleTheadsd.data.permission;


this.permisstionData =mergedPayload["Ticket management"];
    let ticketTableHeads = await MLTAxiosInstance.get(`/settings`);

if (ticketTableHeads?.data?.entityRef?.["ticket"]) {
  let ticketTableHeadModal = ticketTableHeads.data.entityRef["ticket"];
  let ticketTableHeadsOrg = ticketTableHeads.data.entityRef["ticket"].filter(ele => ele.checked);
  console.log(ticketTableHeadsOrg,"ticketTableHeadModal",ticketTableHeadModal)

  if (
    ticketTableHeadModal.length === this.tickets_table_theads_cc.length 
// &&
//     ticketTableHeadModal.every((item, index) => item.name === this.tickets_table_theads_cc[index].name)
  ) {
    console.log(ticketTableHeadsOrg,"ticketTableHeadModal dup",ticketTableHeadModal)
    this.tickets_table_theads_cc = JSON.parse(JSON.stringify(ticketTableHeadModal));
      this.tickets_table_theads = JSON.parse(JSON.stringify(ticketTableHeadsOrg));
      this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.tickets_table_theads_cc ))
      this.tableHeaderOrg = JSON.parse(JSON.stringify(this.tickets_table_theads))
  }
}
    try {
      await this.$store.dispatch("ticketFilterData");
      this.filter = this.$store.getters.ticketFilterData;
      await this.ticketdataCalling(
        this.SpecificationcurrPage,
        this.SpecificationrowsPerPage
      );

      const requests = [
        UPCAxiosInstance.post("util/filterData", this.filterQuery),
        UPCAxiosInstance.get(`/ticketSpec`),
        MLTAxiosInstance.get(
          `/partymanagement/individual/listuser?company=${this.$cookies.get(
            "company"
          )}`
        ),
        MLTAxiosInstance.get("partymanagement/individual/auth"),
        MLTAxiosInstance.get(
          `/partymanagement/organisation/${this.$cookies.get(
            "userId"
          )}?company=${this.$cookies.get("company")}`
        ),
        MLTAxiosInstance.post(
          `/util/filterData?company=${this.$cookies.get("company")}`,
          {
            company: `${this.$cookies.get("company")}`,
            type: "filter-search",
            module: "hierarchycontacts",
            isPaginated: true,
            paginatedQuery: {
              rowsPerPage: 100,
              currentPage: 1,
            },
            isNeedToBeSort: true,
            sortFieldAndOrder: {},
            limit: 2,
            filterQuery: {
              "role.name": "Upc admin",
            },
            searchQuery: "",
          }
        ),
        UPCAxiosInstance.get(`/templateTicket`),
      ];

      const [
        resoponstable,
        ticketSpecResponse,
        contactResponse,
        asigneeResponse,
        instanceResponse,
        fullTableResponse,
        templateResponse,
      ] = await Promise.all(requests);
      console.log(
        resoponstable,
        ticketSpecResponse,
        contactResponse,
        asigneeResponse,
        instanceResponse,
        fullTableResponse,
        templateResponse,
        "hngbfd"
      );

      this.isDataLoaded = true;
      this.tableData = resoponstable.data.data;

      const sortedData = ticketSpecResponse.data.sort(
        (a, b) => a.priority - b.priority
      );
      this.copytemplatePayload = sortedData;
      this.labelFieldsValue = sortedData;

      let obj = {};
      this.labelFieldsValue.forEach((item) => {
        obj[item.name] = "";
      });

      if (this.payload) {
        this.payload.troubleTicketSpecification = [];
      }
      this.payload.troubleTicketSpecification[0] = obj;

      this.duplicateLabelValues = JSON.parse(
        JSON.stringify(this.labelFieldsValue)
      );

      this.contactPayload = contactResponse.data
        .filter((item) => item["@baseType"] === "customer")
        .sort((a, b) => a.name.localeCompare(b.name));

      this.user = asigneeResponse.data;
      this.companyasignee = asigneeResponse.data.company;

      this.instanceData = instanceResponse.data;

      this.assignedToo = fullTableResponse.data.data;
      this.assignedToo.forEach((item) => {
        item.newRole = item.role[0].name;
      });

      this.payload.troubleTicketSpecification[0]["Assigned to"] =
        this.instanceData.name;
this.assiginEmail= this.instanceData.email
this.assiginname= this.instanceData.name

      this.templatePayload = templateResponse.data;
      // const instance = await MLTAxiosInstance.get(
      //   `/partymanagement/organisation/${this.$cookies.get(
      //     "user"
      //   )}?company=${this.$cookies.get("company")}`
      // );
      // this.instanceData = instance.data;
      this.instanceAddress = this.instanceData.contactMedium.find(
        (item) => item.mediumType === "postalAddress"
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  methods: {
    reAssignOrgTableHeader(){
            // If there is change in the table header then ressign to the Orginal data
      if((this.tickets_table_theads_cc != this.tableHeaderOrgcc) && this.tableHeaderOrgcc.length > 0)
    {
      this.tickets_table_theads_cc = JSON.parse(JSON.stringify(this.tableHeaderOrgcc))
    }
    if((this.tickets_table_theads != this.tableHeaderOrg) && this.tableHeaderOrg.length > 0)
    {
      this.tickets_table_theads = JSON.parse(JSON.stringify(this.tableHeaderOrg))
    }
    this.toggleRearrangeModal =true
    },
    resizedWidth(val){
      console.log(val,"resizedWidth",val)

      const updateWidth = (array) => {
    let item = array.find(i => i?.prop === val?.prop);
    if (item) item.width = val.width;
  };

  updateWidth(this.tickets_table_theads);
  updateWidth(this.tickets_table_theads_cc);
console.log(this.tickets_table_theads,"resizedWidth loop",this.tickets_table_theads_cc)
this.applyColumns(this.tickets_table_theads_cc)
    },
    addressPreValue(val) {
      let addressPreVal = {};
      if (val) {
        addressPreVal = val;
      } else {
        addressPreVal = {
          mediumType: "",
          characteristic: {
            city: "",
            country: "",
            postCode: "",
            stateOrProvince: "",
            street1: "",
            street2: "",
            description: "",
            address1: "",
            default: false,
            "@type": "",
            preferred: false,
            postalCode: "",
            unit: "",
            state: "",
          },
        };
      }
      return addressPreVal;
    },
    receivedAddressData(val, name) {
      //console.log(val, name, "val, name");
      try {
        // Ensure the target property is an array

        // If val is not empty, add it to the array
        if (val) {
          this.payload.troubleTicketSpecification[0][name] = val;
        }

        //console.log(
        //   val,
        //   "attachmentEmit",
        //   this.payload.troubleTicketSpecification[0][name]
        // );
      } catch (error) {
        console.error("An error occurred in attachmentEmit:", error);
        // You can add further error handling logic here, like setting a fallback value or notifying the user
      }
    },
    async convertData(event) {
      console.log(event, "convertData");
      let formData = new FormData();
      for (let i = 0; i < event.length; i++) {
        formData.append("files[]", event[i]);
      }
      let data = await UPCAxiosInstance.post(
        `/gcpMultiple/v2/upload/`,
        formData
      );
      console.log(data, "formDataconvert");
    },
    attachmentEmit(val, name) {
      try {
        // Ensure the target property is an array
        if (
          typeof this.payload.troubleTicketSpecification[0][name] === "string"
        ) {
          this.payload.troubleTicketSpecification[0][name] = [
            this.payload.troubleTicketSpecification[0][name],
          ];
        } else if (
          !Array.isArray(this.payload.troubleTicketSpecification[0][name])
        ) {
          this.payload.troubleTicketSpecification[0][name] = [];
        }

        // If val is not empty, add it to the array
        if (val) {
          this.payload.troubleTicketSpecification[0][name].push(val);
        }

        // Remove any empty values from the array
        this.payload.troubleTicketSpecification[0][name] =
          this.payload.troubleTicketSpecification[0][name].filter(
            (item) => item
          );

        //console.log(
        //   val,
        //   "attachmentEmit",
        //   this.payload.troubleTicketSpecification[0][name]
        // );
      } catch (error) {
        console.error("An error occurred in attachmentEmit:", error);
        // You can add further error handling logic here, like setting a fallback value or notifying the user
      }
    },
    closeChat() {
      this.chatappview = false;
    },
    validateEmail(val, name) {
      if (name === "Email id") {
        const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (val.length > 0) {
          if (!reg.test(val)) {
            this.isEmailEmpty = false;
          } else {
            this.isEmailEmpty = true;
          }
        }
      }
    },
    showDrawerRight() {
      this.show_right_filter = true;
    },
    closeRightFilter() {
      this.show_right_filter = false;
    },
    async ticketfilterData(filterSelectedvalue, filterQuery) {
      this.filtertagvalue = filterSelectedvalue;
      this.filtertaglength = filterSelectedvalue.length;
      //console.log(this.filtertaglength, "this.filtertaglength");

      filterQuery.company = this.companyasignee;
      //  filterQuery['filterQuery']['"@baseType"'] = 'supplier'
      if (this.filtertagvalue.length != 0) {
        this.filterQuery = filterQuery;
        this.filterQuery.type = "filter";
        if (this.searchvalue != "") {
          this.filterQuery["searchQuery"] = this.searchvalue;
          this.filterQuery["type"] = "filter-search";
        }
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          this.filterQuery
        );

        this.tableData = filterResultData.data.data;
        this.totalPageNo = filterResultData.data.total;
      } else {
        this.currPage = 1;
        this.rowsPerPage = 10;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
      }
    },
    removedEmit(val, payload) {
      this.payload.troubleTicketSpecification[0][payload] = val;
      console.log(
        this.payload.troubleTicketSpecification[0][payload],
        this.payload.troubleTicketSpecification[0]
      );
    },
    closeactivitylog() {
      this.show_right = false;
    },
    async activiylogcall() {
      const logpayload = {
        type: "filter",
        module: "log",
        lastUpdated: new Date().toJSON(),
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          category: {
            $in: ["tickets"],
          },
        },
        searchQuery: "",
      };
      const logaccess = await MLTAxiosInstance.post(
        `/util/filterData`,
        logpayload
      );
      this.activityLogData = logaccess.data.data;
      this.activitytotal = logaccess.data.total;
      console.log(logaccess.data.data);
    },
    activityLogDrawer() {
      this.activiylogcall();
      this.show_right = true;
      this.activtykey += 1;
    },
    chatApp(ele) {
      this.$router.push({
        path: `/ticket/chatApp`,
        query: { id: `${ele.id}` },
      });
      // this.chatappData = JSON.parse(JSON.stringify(i));
      // this.chatappview = true;
      // //console.log(this.chatappview, "this.chatappview ");
    },
    RelatedPArtyData(val) {
      console.log(val, "RelatedPArtyData");
      if (!this.payload.troubleTicketSpecification) {
        this.payload.troubleTicketSpecification = [];
      }
      if (!this.payload.troubleTicketSpecification[0]) {
        this.payload.troubleTicketSpecification[0] = {};
      }
      if (!this.payload.relatedParty) {
        this.payload.relatedParty = [];
      }

      // Ensure there's an object at the 0th index
      if (!this.payload.relatedParty[0]) {
        this.payload.relatedParty[0] = {};
      }

      this.payload.troubleTicketSpecification[0]["Email id"] =
        val?.contactMedium[0]?.characteristic?.emailAddress;
      // Now, safely update the properties
      this.payload.relatedParty[0].name = val.name;
      this.payload.relatedParty[0].id = val._id;
      this.payload.relatedParty[0].displayID = val.displayID;
      this.payload.relatedParty[0].role = val.aristocraticTitle;
      this.payload.relatedParty[0].company = val.company;
      this.payload.relatedParty[0].createdBy = this.$cookies.get("company");
      console.log(this.payload, " this.payload this.payload");
    },
    addCancel() {
      this.modal_active = false;
    },
    productSearch(val) {
      //console.log("productSearch", val);
      this.searchvalue = val;
      if (val !== "") {
        let searchData = this.searchNestedObjects(
          this.tableData,
          val?.toLowerCase()
        );
        this.copyOfTicketTableData = JSON.parse(JSON.stringify(searchData));
      } else {
        this.copyOfTicketTableData = JSON.parse(JSON.stringify(this.tableData));
      }
      //console.log(this.copyOfTicketTableData, "productSearch end", val);
    },
    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            return v?.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },
    async refeshContract() {},
    addNewAccount() {
      //
      this.modal_active = true;
    },
    selectedValue($event, name) {
      const selectedValue = $event.map((item) => item.value);
      this.payload.troubleTicketSpecification[0][name] = selectedValue;
    },
    closeDiscount() {
      this.isModal = false;
      this.delete_modal_active = false;
    },

    initializeTroubleTicketSpecification() {
      if (!this.payload.troubleTicketSpecification) {
        this.payload.troubleTicketSpecification = [];
      }
    },

    // Function to match ticket category and update payload
    async TicketcategoryData(val) {
      console.log(val, "TicketcategoryData select data");

      let found = false;
      let payloadData = JSON.parse(
        JSON.stringify(this.payload.troubleTicketSpecification[0])
      );

      this.templatePayload.some((item) => {
        if (found) return true;
        if (
          val.value === item.category[0]?.name &&
          item.lifecycleState === "Active"
        ) {
          this.labelFieldsValue = item.fields;
          this.labelFieldsValue.sort((a, b) => a.priority - b.priority);
          found = true;
          try {
            this.initializeTroubleTicketSpecification();
            this.payload.troubleTicketSpecification[0] =
              this.createTicketSpecification(
                this.labelFieldsValue,
                val.value,
                payloadData
              );
            console.log("troubleTicketSpecification", this.payload);
          } catch (error) {
            console.error("Error fetching autocomplete data:", error);
          }
          return true;
        } else {
          this.labelFieldsValue = this.copytemplatePayload.sort(
            (a, b) => a.priority - b.priority
          );
          console.log(
            this.labelFieldsValue,
            "this.labelFieldsValue after sort else condtiton"
          );
          try {
            this.initializeTroubleTicketSpecification();
            this.payload.troubleTicketSpecification[0] =
              this.createTicketSpecification(
                this.labelFieldsValue,
                val.value,
                payloadData
              );
            console.log("troubleTicketSpecification", this.payload);
          } catch (error) {
            console.error("Error fetching autocomplete data:", error);
          }
        }
        return false;
      });
    },

    // Function to create ticket specification
    // createTicketSpecification(labelFieldsValue, ticketCategory) {
    //   const obj = {};
    //   labelFieldsValue.forEach((item) => {
    //     switch (item["@type"]) {
    //       case "Dropdown":
    //       case "Radio button":
    //         item.characteristicSpecification.forEach((itemch) => {
    //           if (itemch.default) {
    //             obj[item.name] = itemch.value;
    //           }
    //         });
    //         break;
    //       case "Multi select":
    //         const value = item.characteristicSpecification.filter(itemch => itemch.default).map(itemch => itemch.value);
    //         obj[item.name] = value.length > 0 ? value : [];
    //         break;
    //       default:
    //         obj[item.name] = "";
    //         break;
    //     }
    //   });
    //   obj["Ticket category"] = ticketCategory;
    //   return obj;
    // },

    //     createTicketSpecification(labelFieldsValue, ticketCategory, payload = {}) {
    //   let obj = {}; // New object created every time

    //   labelFieldsValue.forEach((item) => {
    //     if (item["@type"] === "Dropdown" || item["@type"] === "Radio button") {
    //       item.characteristicSpecification.forEach((itemch) => {
    //         if (itemch.default === true) {
    //           // Set value in obj from the item's characteristicSpecification
    //           obj[item.name] = itemch.value;
    //         }
    //       });
    //     } else if (item["@type"] === "Multi select") {
    //       let value = [];
    //       item.characteristicSpecification.forEach((itemch) => {
    //         if (itemch.default === true) {
    //           value.push(itemch.value);
    //         }
    //       });
    //       obj[item.name] = value; // Assign the array to obj
    //     }

    //     // Check if the current key in obj matches a key in payload
    //     if (obj[item.name] === payload[item.name]) {
    //       console.log(`Key: ${item.name} | Value: ${payload[item.name]}`);
    //     }
    //   });

    //   // Ensure TicketCategory is set
    //   obj["Ticket category"] = payload["Ticket category"] || ticketCategory;
    //   obj["Assigned to"] = payload["Assigned to"] || this.instanceData.name;

    //   return obj;
    // }
    // ,

    createTicketSpecification(labelFieldsValue, ticketCategory, payload) {
      console.log(
        labelFieldsValue,
        ticketCategory,
        "createTicketSpecification",
        payload
      );

      // Ensure labelFieldsValue is an array and payload is an object
      if (!labelFieldsValue || !Array.isArray(labelFieldsValue)) {
        console.error("labelFieldsValue is not an array or is undefined");
        return {};
      }

      if (!payload || typeof payload !== "object") {
        console.error("payload is not an object or is undefined");
        return {};
      }

      let obj = {};

      labelFieldsValue.forEach((item) => {
        // Check if payload has a pre-existing value for the current item
        let preValue = payload[item.name] || "";

        if (item["@type"] === "Dropdown" || item["@type"] === "Radio button") {
          let found = false;
          item.characteristicSpecification.forEach((itemch) => {
            if (itemch.default === true) {
              obj[item.name] = preValue !== "" ? preValue : itemch.value;
              found = true;
            }
          });
          if (!found) {
            obj[item.name] = preValue !== "" ? preValue : "";
          }
        } else if (item["@type"] === "Multi select") {
          let value = [];
          item.characteristicSpecification.forEach((itemch) => {
            if (itemch.default === true) {
              value.push(itemch.value);
            }
          });
          obj[item.name] =
            preValue !== "" ? preValue : value.length > 0 ? value : [];
        } else {
          // If type is not Dropdown, Radio button, or Multi select, use pre-existing value if available
          obj[item.name] = preValue !== "" ? preValue : "";
        }
      });

      // Ensure TicketCategory is set
      obj["Ticket category"] = ticketCategory;

      // Ensure "Assigned to" is set safely
      obj["Assigned to"] = this.instanceData?.name;

      return obj;
    },

    findDefaultValueForTicketCategory(payload) {
      try {
        if (!Array.isArray(payload)) throw new Error("Payload is not an array");
        const ticketCategoryItem = payload.find(
          (item) => item.name === "Ticket category"
        );
        if (!ticketCategoryItem)
          throw new Error('"Ticket category" item not found');
        if (!Array.isArray(ticketCategoryItem.characteristicSpecification))
          throw new Error("characteristicSpecification is not an array");
        const defaultSpecification =
          ticketCategoryItem.characteristicSpecification.find(
            (spec) => spec.default === true
          );
        if (!defaultSpecification)
          throw new Error("Default specification not found");
        return defaultSpecification.value;
      } catch (error) {
        console.error(
          "Error finding default value for Ticket category:",
          error
        );
        return null;
      }
    },

    // Function to handle payload and characteristic specification
    preValFunc(payload, spechfiled) {
      if (payload && payload.length > 0) {
        return payload;
      } else {
        spechfiled.characteristicSpecification.forEach((item) => {
          if (item.default) payload = item.value;
        });
        return payload;
      }
    },

    preValFunction(payload, spechfiled) {
      let value = [];
      spechfiled.characteristicSpecification.forEach((item) => {
        if (item.default) value.push(item.value);
      });
      return value.length > 0 ? value : [];
    },

    // Handle edited ticket logic
    async editedTicket(id, payload) {
      this.initializeTroubleTicketSpecification();
      if (this.payload.troubleTicketSpecification[0]["Priority level"]) {
        this.payload["priority"] =
          this.payload.troubleTicketSpecification[0]["Priority level"];
      }
      if (this.payload.troubleTicketSpecification[0]["Status"]) {
        this.payload["status"] =
          this.payload.troubleTicketSpecification[0]["Status"];
      }
      if (this.payload.troubleTicketSpecification[0]["Ticket category"]) {
        this.payload["name"] =
          this.payload.troubleTicketSpecification[0]["Ticket category"];
      }

      const patchTicket = await UPCAxiosInstance.patch(
        "/troubleTicket",
        payload
      );
      let updatedAdditionalChargeLogPayload = {
        message: `${this.payload.troubleTicketSpecification[0]["Ticket category"]} ticket has been updated`,
        "@type": "tickets",
        detailedAction: [],
        category: ["tickets"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        action: "UPDATED",
      };

      // Object.keys(this.payload.troubleTicketSpecification[0]).forEach((key) => {
      //   updatedAdditionalChargeLogPayload.detailedAction = [
      //     ...updatedAdditionalChargeLogPayload.detailedAction,
      //     ...this.buildBasicLogByDifferences(
      //       this.initialChargeval.troubleTicketSpecification[0],
      //       this.payload.troubleTicketSpecification[0],
      //       key,
      //       key
      //     ),
      //   ];
      // });

      Object.keys(this.payload.troubleTicketSpecification[0]).forEach((key) => {
        const currentValue = this.payload.troubleTicketSpecification[0][key];
        const initialValue =
          this.initialChargeval.troubleTicketSpecification[0][key];

        if (Array.isArray(currentValue)) {
          if (typeof currentValue[0] === "object") {
            // Case: Array of objects
            updatedAdditionalChargeLogPayload.detailedAction = [
              ...updatedAdditionalChargeLogPayload.detailedAction,
              ...this.buildLogBasedOnDifferencesForArrayOfObjects(
                initialValue,
                currentValue,
                "name",
                key
              ),
            ];
          } else if (typeof currentValue[0] === "string") {
            // Case: Array of strings
            updatedAdditionalChargeLogPayload.detailedAction = [
              ...updatedAdditionalChargeLogPayload.detailedAction,
              ...this.findArrayOfStringDifferences(
                initialValue,
                currentValue,
                key
              ),
            ];
          }
        } else {
          // Default case: Handle basic log for non-array types
          updatedAdditionalChargeLogPayload.detailedAction = [
            ...updatedAdditionalChargeLogPayload.detailedAction,
            ...this.buildBasicLogByDifferences(
              this.initialChargeval.troubleTicketSpecification[0],
              this.payload.troubleTicketSpecification[0],
              key,
              key
            ),
          ];
        }
      });

      if (patchTicket) {
        this.notification = {
          type: "success",
          message: `Nice work! Your ticket has been updated!`,
        };
        this.initLog(updatedAdditionalChargeLogPayload);
      }
      this.recalltable();
      this.isModal = false;
      this.editMode = false;
    },
    async sendEmail(i) {
      console.log(i, "sendEmail");
      // const prod = process.env.VUE_APP_BASE_URL;
      // prod;
      // const dev = "http://localhost:8080";
      // dev;

      // console.log(generatedToken, "mail send sucessfully");
      let mail = {
        to: [i?.troubleTicketSpecification[0]["Email id"]],

        subject: `Confirmation of Your Support Ticket: ${i.displayID}`,
        template: `
      <!--
 -->
<!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <!--[if gte mso 9]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="format-detection" content="date=no" />
    <meta name="format-detection" content="address=no" />
    <meta name="format-detection" content="telephone=no" />
    <meta name="x-apple-disable-message-reformatting" />
    <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900" rel="stylesheet">
    <link href='https://fonts.googleapis.com/css?family=Open+Sans' rel='stylesheet'>
    <link href='https://fonts.googleapis.com/css?family=Quicksand&display=swap' rel='stylesheet'>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <style type="text/css" media="screen">
        body {
            padding: 0 !important;
            margin: 0 !important;
            display: flex !important;
            justify-content: center !important;
            min-width: 100% !important;
            width: 100% !important;
            background: #f5f5f5;
            -webkit-text-size-adjust: none;
        }
        .mobile-shell {
            width: 100%;
        }
        .td {
            width: 100%;
        }
        .wrapper {
            width: 100%;
            table-layout: fixed;
        }
        .p35-15 {
            padding: 35px 17px;
        }
        .headline {
            text-align: center;
        }
        .hero-title-1 {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            padding-top: 15px;
            line-height: 28px;
            text-align: center;
            color: #191A15;
        }
        .hero-content {
            padding-top: 25px;
            padding-left: 100px;
            padding-right: 100px;
            color: black;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 25px;
            font-weight: normal;
            text-align: center;
            letter-spacing: .01em;
        }
        .btn {
            text-align: center;
            padding-top: 25px;
        }
        .btn a {
            background-color: #54BD95;
            border-radius: 6px;
            color: #ffffff;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            text-align: center;
            text-decoration: none;
            width: 218.18px;
            height: 38px;
            -webkit-text-size-adjust: none;
            padding: 10px 12px;
            line-height: 15px;
        }
        .disclaimer2 {
            padding: 40px 77px;
            color: #FFFFFF;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 20px;
            font-weight: normal;
            text-align: center;
            letter-spacing: .01em;
        }
        .footer-link a {
            color: #FFFFFF;
            font-size: 14px;
        }
        .address {
            padding-left: 17px;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            color: #FFFFFF;
            margin-top: 15px;
            margin-bottom: 0px;
            letter-spacing: .01em;
            line-height: 20px;
        }
        .social-icons {
            float: right;
            margin-right: -50px;
            padding-top: 20px;
        }
        .social-icons a {
            padding-left: 5px;
            padding-right: 5px;
            color: #FFFFFF;
        }
    </style>
</head>

<body class="body" style="
 display: grid;
    justify-items: center;
    background-color: #f5f5f5;
">
    <!-- MOBILE PREHEADER PUSH ANY CONTENT AFTER THE PREHEADER FROM SHOWING UP INSERT UNDER BODY TAG -->
    <table width="612px" border="0" cellspacing="0" cellpadding="0" bgcolor="#f5f5f5 " style="margin: 0 auto">
        <tr>
            <td align="center" valign="top">
                <table border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
                    <tr>
                        <td class="td">
                            <table border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                    <td  height: 501px;">
                                        <!-- Header -->
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                                            <tr>
                                                <td>
                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" class="wrapper">
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="10" cellspacing="0" width="77%" class="wrapper" bgcolor="#f5f5f5">
                                                                    <tr>
                                                                        <!-- Add logo here if needed -->
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" class="wrapper" bgcolor="#ffffff">
                                                                    <tr>
                                                                        <!-- Add logo here if needed -->
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- END Header -->
                                        <!-- Start Content -->
                                        <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" height="501" cellpadding="0">
                                            <tr>
                                                <td bgcolor="#FFFFFF" class="p35-15">
                                                    <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" cellpadding="0">
                                                         <div class="container">
        <h2>Dear ${i?.troubleTicketSpecification[0].Requester},</h2>
        <p>Thank you for contacting our Company. This email is to acknowledge that we have received your support ticket, <strong>${
          i.displayID
        }</strong>, regarding <strong>${i.name}</strong>.</p>
        
        <p>Our team is currently reviewing your request and will provide a detailed response within <strong>2 Days</strong>. If any further information is needed, we will reach out to you directly.</p>

        <h3>Ticket Details:</h3>
        <ul>
            <li><strong>Ticket ID:</strong> ${i.displayID}</li>
            <li><strong>Submitted on:</strong> ${this.dateAndTimeFormatter(
              i?.updatedAt
            )}</li>
            <li><strong>Issue Summary:</strong> ${
              i?.troubleTicketSpecification[0]["Subject"]
            }</li>
        </ul>

        <p>We appreciate your patience as we work to resolve this matter. In the meantime, if you have any additional details or questions, please reply to this email.</p>

         <p>Thank you for choosing <strong>${this.$cookies.get(
           "userName"
         )}</strong>.</p>
    </div>                                                       
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- HERO Images-->
                                    </td>
                                </tr>
                            </table>
                            <!-- END Content -->
                            <!-- Footer -->
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" style="padding: 0 15px;" bgcolor="#ffffff">
                                <tr>
                                    <td bgcolor="#161C2B" class="disclaimer2">
                                        <span class="footer-link"><a href="https://halleyx.com">T&C</a><a style="padding-left:40px; padding-right: 40px;" href="https://halleyx.com">Privacy</a><a href="https://halleyx.com">Cookies</a></span><br/>
                                        <br/>
                                        © ${
                                          this.currentYear
                                        } ${this.$cookies.get(
          "company"
        )}. Copyright and rights reserved
                                        <p class="address">${
                                          this.instanceAddress.characteristic
                                            .description
                                        }</p>
                                        <span style="float:left; margin-left: -60px; padding-top:20px;"><img width="100" src=""/></span>
                                        <span class="social-icons"><a href="https://www.linkedin.com/company/halleyx/"><i class="fa fa-linkedin fa-lg"></i></a>
                                            <a href="https://halleyx.com/"><i class="fa fa-facebook fa-lg"></i></a>
                                            <a href="https://www.instagram.com/halleyx_inc/"><i class="fa fa-instagram fa-lg"></i></a></span>
                                    </td>
                                </tr>
                            </table>
                            <!-- END Footer -->
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
    <img src="images?q=tbn:ANd9GcRYx7vG1ermb_muIP457oVO48JixkaC0RvhAA&usqp=CAU" width="1" height="1">
</body>

</html>

`,
      };
      // const sendEmail =
      await MLTAxiosInstance.post(`/email`, mail);
    },
    async assigineEmail(i) {
      console.log(i, "sendEmail");
      // const prod = process.env.VUE_APP_BASE_URL;
      // prod;
      // const dev = "http://localhost:8080";
      // dev;

      // console.log(generatedToken, "mail send sucessfully");
      let mail = {
        to: [this.assiginEmail],

        subject: ` Ticket ${i.displayID} Assigned to You `,
        template: `
      <!--
 -->
<!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
    <!--[if gte mso 9]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="format-detection" content="date=no" />
    <meta name="format-detection" content="address=no" />
    <meta name="format-detection" content="telephone=no" />
    <meta name="x-apple-disable-message-reformatting" />
    <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900" rel="stylesheet">
    <link href='https://fonts.googleapis.com/css?family=Open+Sans' rel='stylesheet'>
    <link href='https://fonts.googleapis.com/css?family=Quicksand&display=swap' rel='stylesheet'>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <style type="text/css" media="screen">
        body {
            padding: 0 !important;
            margin: 0 !important;
            display: flex !important;
            justify-content: center !important;
            min-width: 100% !important;
            width: 100% !important;
            background: #f5f5f5;
            -webkit-text-size-adjust: none;
        }
        .mobile-shell {
            width: 100%;
        }
        .td {
            width: 100%;
        }
        .wrapper {
            width: 100%;
            table-layout: fixed;
        }
        .p35-15 {
            padding: 35px 17px;
        }
        .headline {
            text-align: center;
        }
        .hero-title-1 {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            padding-top: 15px;
            line-height: 28px;
            text-align: center;
            color: #191A15;
        }
        .hero-content {
            padding-top: 25px;
            padding-left: 100px;
            padding-right: 100px;
            color: black;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 25px;
            font-weight: normal;
            text-align: center;
            letter-spacing: .01em;
        }
        .btn {
            text-align: center;
            padding-top: 25px;
        }
        .btn a {
            background-color: #54BD95;
            border-radius: 6px;
            color: #ffffff;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            text-align: center;
            text-decoration: none;
            width: 218.18px;
            height: 38px;
            -webkit-text-size-adjust: none;
            padding: 10px 12px;
            line-height: 15px;
        }
        .disclaimer2 {
            padding: 40px 77px;
            color: #FFFFFF;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            line-height: 20px;
            font-weight: normal;
            text-align: center;
            letter-spacing: .01em;
        }
        .footer-link a {
            color: #FFFFFF;
            font-size: 14px;
        }
        .address {
            padding-left: 17px;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            color: #FFFFFF;
            margin-top: 15px;
            margin-bottom: 0px;
            letter-spacing: .01em;
            line-height: 20px;
        }
        .social-icons {
            float: right;
            margin-right: -50px;
            padding-top: 20px;
        }
        .social-icons a {
            padding-left: 5px;
            padding-right: 5px;
            color: #FFFFFF;
        }
    </style>
</head>

<body class="body" style="
 display: grid;
    justify-items: center;
    background-color: #f5f5f5;
">
    <!-- MOBILE PREHEADER PUSH ANY CONTENT AFTER THE PREHEADER FROM SHOWING UP INSERT UNDER BODY TAG -->
    <table width="612px" border="0" cellspacing="0" cellpadding="0" bgcolor="#f5f5f5 " style="margin: 0 auto">
        <tr>
            <td align="center" valign="top">
                <table border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
                    <tr>
                        <td class="td">
                            <table border="0" cellspacing="0" cellpadding="0">
                                <tr>
                                    <td  height: 501px;">
                                        <!-- Header -->
                                        <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#ffffff">
                                            <tr>
                                                <td>
                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" class="wrapper">
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="10" cellspacing="0" width="77%" class="wrapper" bgcolor="#f5f5f5">
                                                                    <tr>
                                                                        <!-- Add logo here if needed -->
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" class="wrapper" bgcolor="#ffffff">
                                                                    <tr>
                                                                        <!-- Add logo here if needed -->
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- END Header -->
                                        <!-- Start Content -->
                                        <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" height="501" cellpadding="0">
                                            <tr>
                                                <td bgcolor="#FFFFFF" class="p35-15">
                                                    <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" cellpadding="0">
                                                         <div class="container">
<h2>Dear ${this.assiginname},</h2>
        <p>We wanted to inform you that the following ticket has been assigned to you for review and resolution:</p>

        <h3>Ticket Details:</h3>
        <ul>
            <li><strong>Ticket ID:</strong> ${i.displayID}</li>
            <li><strong>Assigned on:</strong> ${this.dateAndTimeFormatter(
              i?.updatedAt
            )}</li>
            <li><strong>Issue Summary:</strong> ${
              i?.troubleTicketSpecification[0]["Subject"]
            }</li>
            <li><strong>Priority:</strong>${i.priority}</li>
        </ul>

        <p>Please ensure that you review and address this issue as soon as possible. If you require further information or assistance, feel free to reach out to the team or contact the ticket sender directly.</p>

        <p>For any updates, please log into the system or reply to this email.</p>

        <p>Thank you for your prompt attention to this matter.</p>                                                 
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                        <!-- HERO Images-->
                                    </td>
                                </tr>
                            </table>
                            <!-- END Content -->
                            <!-- Footer -->
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" style="padding: 0 15px;" bgcolor="#ffffff">
                                <tr>
                                    <td bgcolor="#161C2B" class="disclaimer2">
                                        <span class="footer-link"><a href="https://halleyx.com">T&C</a><a style="padding-left:40px; padding-right: 40px;" href="https://halleyx.com">Privacy</a><a href="https://halleyx.com">Cookies</a></span><br/>
                                        <br/>
                                        © ${
                                          this.currentYear
                                        } ${this.$cookies.get(
          "company"
        )}. Copyright and rights reserved
                                        <p class="address">${
                                          this.instanceAddress.characteristic
                                            .description
                                        }</p>
                                        <span style="float:left; margin-left: -60px; padding-top:20px;"><img width="100" src=""/></span>
                                        <span class="social-icons"><a href="https://www.linkedin.com/company/halleyx/"><i class="fa fa-linkedin fa-lg"></i></a>
                                            <a href="https://halleyx.com/"><i class="fa fa-facebook fa-lg"></i></a>
                                            <a href="https://www.instagram.com/halleyx_inc/"><i class="fa fa-instagram fa-lg"></i></a></span>
                                    </td>
                                </tr>
                            </table>
                            <!-- END Footer -->
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
    <img src="images?q=tbn:ANd9GcRYx7vG1ermb_muIP457oVO48JixkaC0RvhAA&usqp=CAU" width="1" height="1">
</body>

</html>

`,
      };
      // const sendEmail =
      await MLTAxiosInstance.post(`/email`, mail);
    },
    // Add a new ticket
    async addNewticket() {
      this.initializeTroubleTicketSpecification();
      if (this.payload.troubleTicketSpecification[0]["Priority level"]) {
        this.payload["priority"] =
          this.payload.troubleTicketSpecification[0]["Priority level"];
      }
      if (this.payload.troubleTicketSpecification[0]["Status"]) {
        this.payload["status"] =
          this.payload.troubleTicketSpecification[0]["Status"];
      }
      if (this.payload.troubleTicketSpecification[0]["Ticket category"]) {
        this.payload["name"] =
          this.payload.troubleTicketSpecification[0]["Ticket category"];
      }

      let additionalChargeLogPayload = {
        message: `${this.payload.troubleTicketSpecification[0]["Ticket category"]} Ticket has been added`,
        "@type": "Ticket",
        detailedAction: [],
        category: ["tickets"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        action: "ADDED",
      };

      this.initLog(additionalChargeLogPayload);
      const response = await UPCAxiosInstance.post(
        `/troubleTicket`,
        this.payload
      );
      if (response) {
        this.notification = {
          type: "success",
          message: `Nice work! Your new ticket is now in the list!`,
        };
      }
      this.sendEmail(response.data);
      this.assigineEmail(response.data)
      this.closeDiscount();
      this.ticketdataCalling();
      this.recalltable();
    },

    // Fetch and refresh the table
    async recalltable() {
      const resoponstable = await UPCAxiosInstance.post(
        "util/filterData",
        this.filterQuery
      );
      this.tableData = resoponstable.data.data;
    },

    // Modal to create a new contact
    showNewContactModal() {
      this.payload = {
        name: "",
        status: "",
        "@type": "TroubleTicket",
        priority: "",
        troubleTicketSpecification: [],
      };

      this.paylaodpre = this.findDefaultValueForTicketCategory(
        this.labelFieldsValue
      );
      let found = false;
      this.templatePayload.some((item) => {
        if (found) return true;
        if (
          this.paylaodpre === item.category[0]?.name &&
          item.lifecycleState === "Active"
        ) {
          this.labelFieldsValue = item.fields;
          this.labelFieldsValue.sort((a, b) => a.priority - b.priority);
          found = true;
        } else {
          this.labelFieldsValue = this.copytemplatePayload.sort(
            (a, b) => a.priority - b.priority
          );
        }
        return false;
      });

      try {
        let payload = {};
        let obj = this.createTicketSpecification(
          this.labelFieldsValue,
          this.paylaodpre,
          payload
        );
        console.log(obj, "createTicketSpecification ");

        this.initializeTroubleTicketSpecification();
        this.payload.troubleTicketSpecification[0] = obj;
        console.log("troubleTicketSpecification", this.payload);
      } catch (error) {
        console.error("Error fetching autocomplete data:", error);
      }

      this.payload.troubleTicketSpecification[0]["Assigned to"] =
        this.instanceData.name;
      this.componentKeyM += 1;
      this.attachmnetComponetKey += 1;
      this.componentkey1R += 1;
      this.isModal = true;
      this.editMode = false;
      this.updateKey++;
    },

    async ticketdataCalling(currentPage, rowsPerPage) {
      const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
      this.companyasignee = val.data.company;
      this.filterQuery["company"] = val.data.company;
      this.filterQuery["paginatedQuery"]["currentPage"] = currentPage;
      this.filterQuery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.filterQuery
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        this.totalPageNo = filterResultData.data.total;
        this.paginatedData;
      }
    },
    sorting_Data(data, data1) {
      //
      //console.log(data, data1);
      this.Supplierfilterquery.type = "filter";
      if (data === "icon-angle-up-small-filled sorting-icon") {
        this.Supplierfilterquery.isNeedToBeSort = true;
        let obj = {};
        obj[data1] = 1;
        this.Supplierfilterquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled sorting-icon") {
        this.Supplierfilterquery.isNeedToBeSort = true;
        let obj = {};
        obj[data1] = -1;
        this.Supplierfilterquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-up-small-filled") {
        this.Supplierfilterquery.isNeedToBeSort = false;
        let obj = {};
        this.Supplierfilterquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled") {
        this.Supplierfilterquery.isNeedToBeSort = false;
        let obj = {};
        this.Supplierfilterquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
      }
    },
    async searchKey(val) {
      this.searchvalue = val;
      this.filterQuery["company"] = this.companyasignee;
      this.filterQuery["type"] = "search";
      this.filterQuery["searchQuery"] = this.searchvalue;
      this.filterQuery.searchList = [
        {
          offerType: { $regex: val, $options: "i" },
        },
        {
          marketSegment: { $regex: val, $options: "i" },
        },
        {
          priority: {
            $regex: val,
            $options: "i",
          },
        },
        {
          status: {
            $regex: val,
            $options: "i",
          },
        },
        {
          "relatedParty.name": {
            $regex: val,
            $options: "i",
          },
        },
        {
          "relatedParty.company": {
            $regex: val,
            $options: "i",
          },
        },
        {
          "troubleTicketSpecification.Email id": {
            $regex: val,
            $options: "i",
          },
        },
      ];
      this.currPage = 1;
      this.rowsPerPage = 10;
      if (this.filtertagvalue.length != 0) {
        this.filterQuery["type"] = "filter-search";
      }

      this.ticketdataCalling(this.currPage, this.rowsPerPage);
    },
    async changeRowsPerPage(count) {
      //
      if (this.companyasignee != "") {
        this.rowsPerPage = count;
        this.ticketdataCalling(
          this.SpecificationcurrPage,
          this.SpecificationrowsPerPage
        );
        this.recalltable();
      }
    },
    async currentPage(val) {
      if (this.companyasignee != "") {
        this.currPage = val;
        this.ticketdataCalling(
          this.SpecificationcurrPage,
          this.SpecificationrowsPerPage
        );
        this.recalltable();
      }
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    contracttyprfunc(val) {
      this.payload.type = val.name;
    },
    emitStatus(val) {
      this.payload.status = val.name;
      //console.log("Discount status", val);
    },

    crudContextMenu($event, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.map((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.map((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },

    async closeCrudContextMenu($event, ele, i) {
      if ($event?.toLowerCase() === "edit") {
        this.isModal = true;
        this.editMode = true;
        this.componentKeyM += 1;

        let found = false;
        this.templatePayload?.map((item) => {
          if (found) return;
          if (
            ele?.troubleTicketSpecification[0]["Ticket category"] ===
              item.category[0]?.name &&
            item.lifecycleState === "Active"
          ) {
            // this.labelFieldsValue = item?.fields;
            this.labelFieldsValue = item?.fields.sort(
            (a, b) => a.priority - b.priority
          );
            found = true;
          } else {
            // this.labelFieldsValue = this.copytemplatePayload;
            this.labelFieldsValue = this.copytemplatePayload.sort(
            (a, b) => a.priority - b.priority
          );
          }
        });

        this.payload = JSON.parse(JSON.stringify(ele));
        this.initialChargeval = JSON.parse(JSON.stringify(ele));
      } else if ($event?.toLowerCase() == "delete") {
        this.delete_modal_active = true;
        this.deleteobj = ele;
        this.delete_name = ele.name;
        this.deleterowindex = i;
      }
    },

    computedTableAction() {
      if (this.permisstionData.delete && this.permisstionData.edit) {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }  else if (this.permisstionData.delete ) {
        return [
          
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }  else {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
        ];
      }
      // return [
      //   {
      //     label: "Edit",
      //     icon: "icon-file-text-edit-regular",
      //   },
      //   {
      //     label: "Delete",
      //     icon: "icon-trash-regular",
      //   },
      // ];
    },
    async deleteExecRow() {
      this.delete_modal_active = false;
      let i = this.deleteobj;
      const response = await UPCAxiosInstance.delete(`troubleTicket/${i._id}`);

      const deleteAdditionalChargeLogPayload = {
        message: `${this.deleteobj.troubleTicketSpecification[0]["Ticket category"]} ticket has been removed`,
        "@type": "Tickets",
        detailedAction: [],
        category: ["tickets"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        action: "DELETED",
      };
      if (response) {
        this.initLog(deleteAdditionalChargeLogPayload);

        this.notification = {
          type: "success",
          message: `Done! Your ticket has been removed.`,
        };
      }
      this.recalltable();
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    arrange(val) {
      this.toggleRearrangeModal = val;
      // this.updateTheads();
    },
    arrangeColumns(val) {
      this.toggleRearrangeModal = val;
      // this.updateTheads();
    },
    updateTheads() {
      this.tickets_table_theads_cc = this.tickets_table_theads_cc.map(
        (thead) => {
          // Check if the current thead has a matching 'prop' in duplicateTheads
          const isMatching = this.tickets_table_theads.some(
            (duplicateThead) => duplicateThead.prop === thead.prop
          );

          // Set the 'checked' property based on whether it's matching
          return { ...thead, checked: isMatching };
        }
      );
    },
  async  applyColumns(val) {
// Func to rearrange table header
let sampleTheadTicket = await MLTAxiosInstance.get(`/settings`);
let concatedEntity = sampleTheadTicket?.data?.entityRef || {};

concatedEntity.ticket = val;

let reArrangeTableHeadPayload = { entityRef: concatedEntity };

const changeTableHeader = await MLTAxiosInstance.patch(`/settings`,reArrangeTableHeadPayload);
console.log(changeTableHeader,"reArrangeTableHeadPayload",reArrangeTableHeadPayload)
let sampleTheads = await MLTAxiosInstance.get(`/settings`);

console.log("sampleTheads",sampleTheads?.data)
console.log("sampleTheads mountes",sampleTheads?.data?.entityRef)
this.tickets_table_theads_cc = sampleTheads?.data?.entityRef["ticket"]
this.tickets_table_theads = sampleTheads?.data?.entityRef["ticket"].filter(ele=>ele.checked)
this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.tickets_table_theads_cc ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.tickets_table_theads))
    },

    AssignedData(val) {
this.assiginEmail= val.email
this.assiginname= val.name

      console.log(
        val,
        "AssignedData"
      );
      //console.log();
    },
    // ticketFileds(val) {
    //   //console.log(
    //   //   this.payload?.troubleTicketSpecification[0]["Ticket category"],
    //   //   "fxcgvhbjknl;'"
    //   // );

    //   val?.map((item) => {
    //     if (item.default == true) {
    //       //console.log("defalt lofg--- log");
    //       this.paylaodpre = item.value;
    //     }
    //   });
    //   let found = false;

    //   this.templatePayload.map((item) => {
    //     //console.log(item, "item");

    //     if (found) return;

    //     if (
    //       this.paylaodpre === item.category[0]?.name &&
    //       item.lifecycleState === "Active"
    //     ) {
    //       this.labelFieldsValue = item.fields;
    //       //console.log(this.labelFieldsValue, index, "this.labelFieldsValue");
    //       found = true; // Set flag to true to simulate stopping the loop
    //     } else if (this.paylaodpre != item.category[0]?.name) {
    //       this.labelFieldsValue = this.copytemplatePayload;
    //       //console.log("else condition", this.labelFieldsValue);
    //     }
    //   });
    // },
  },
};
</script>
<style lang="scss" scoped>
.header-add-supplier {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  .tablehead-add-supplier {
    font-weight: bold;
  }
  .search-add-supplier {
    display: flex;
    gap: 10px;
  }
}
.contacts-username-supplier {
  display: flex;
  align-items: center;
  .right-head-supplier {
    display: flex;
    flex-direction: column;
  }
  .head-supplier-left {
    display: flex;
    justify-content: center;
    width: 44px;
    align-items: center;
    font-size: 45px;
    margin-right: 10px;
  }
  .head-supplier-right {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    .head-supplier-name {
      margin-right: 10px;
    }
  }
  .head-supplier-address {
    font-size: 10px;
    color: #929191;
    margin-top: 3px;
  }
}
.ticket-drawer-res {
  // background-color: #eee;
  // width: 200px;
  height: 88vh;
  // border: 1px dotted black;
  overflow-y: scroll; /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ticket-drawer-res::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ticket-drawer-res {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
