<template>
  <div class="accordion" style="padding-left: 52px; padding-top: 20px">
    <!-- Main Sections -->
    <div
      style="
        display: flex;
        align-items: center;
        font-family: 'Opensans';
        font-size: 14px;
        font-weight: 600;
        line-height: 19.07px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      "
    >
      <div style="width: 23.3%">Modules</div>
      <div
        style="
          width: 70%;
          display: flex;
          gap: 19%;
          align-items: center;
          flex-direction: row;
        "
      >
        <div>All</div>
        <div>Add</div>
        <div>Edit</div>
        <div>View</div>
        <div>Delete</div>
      </div>
    </div>
    <div v-for="(section, index) in sections" :key="index">
      <button
        class="accordion-btn"
        :class="{ active: section.isOpen }"
        @click="toggleSection(index)"
      >
        <div style="width: 23%">
          <i
            :style="{
              visibility:
                section?.child?.length ||
                section?.section?.length ||
                section?.sections?.length
                  ? 'visible'
                  : 'hidden',
            }"
            :class="
              section.isOpen
                ? 'icon-angle-down-regular'
                : 'icon-angle-right-regular'
            "
            class="accordion-icon"
          ></i>
          {{ section.customName || section.name }}
        </div>
        <div style="display: flex; width: 76%; gap: 9.3%">
          <hlx-input
            v-model:value="section.all"
            :checked="section.all"
            :type="'single-partial-checkbox'"
            :partially-checked="false"
            :disabled="section.disabled"
            @partially-value="partiallyValcheckbox()"
            @selected="updateAll($event, section, section)"
          />
          <hlx-input
            v-model:value="section.add"
            :checked="section.add"
            :type="'single-partial-checkbox'"
            :partially-checked="section.partially"
            :disabled="section.disabled"
            @selected="updateAdd($event, section, section)"
            @partially-value="partiallyValcheckbox($event, section)"
          />
          <hlx-input
            v-model:value="section.edit"
            :checked="section.edit"
            :type="'single-partial-checkbox'"
            :partially-checked="section.partially"
            :disabled="section.disabled"
            @selected="updateEdit($event, section, section)"
            @partially-value="partiallyValcheckbox($event, section)"
          />
          <hlx-input
            v-model:value="section.view"
            :checked="section.view"
            :type="'single-partial-checkbox'"
            :partially-checked="section.partially"
            :disabled="section.disabled"
            @selected="updateView($event, section, section)"
            @partially-value="partiallyValcheckbox($event, section)"
          />

          <hlx-input
            v-model:value="section.delete"
            :checked="section.delete"
            :type="'single-partial-checkbox'"
            :partially-checked="section.partially"
            :disabled="section.disabled"
            @selected="updateDelete($event, section, section)"
            @partially-value="partiallyValcheckbox($event, section)"
          />
        </div>
      </button>
      <div class="panel" :class="{ show: section.isOpen }">
        <!-- <p class="pannelFirstLayer">{{ section.content }}</p> -->

        <!-- Inner Accordion Buttons -->
        <div
          v-for="(inner, innerIndex) in section.child ||
          section.section ||
          section.sections"
          :key="innerIndex"
        >
          <button
            v-if="!inner.title"
            class="accordion-btn inner-accord"
            :class="{ active: inner.isOpen }"
            @click="toggleInnerSection(index, innerIndex)"
          >
            <div style="width: 21.6%">
              <i
                :style="{
                  visibility:
                    inner?.child?.length ||
                    inner?.section?.length ||
                    inner?.sections?.length
                      ? 'visible'
                      : 'hidden',
                }"
                :class="
                  inner.isOpen
                    ? 'icon-angle-down-regular'
                    : 'icon-angle-right-regular'
                "
                class="accordion-icon"
              ></i>
              {{ inner.customName || inner.name }}
            </div>
            <div style="display: flex; width: 80%; gap: 9.2%">
              <hlx-input
                v-model:value="inner.all"
                :checked="inner.all"
                :type="'single-partial-checkbox'"
                :partially-checked="false"
                @partially-value="partiallyValcheckbox()"
                @selected="updateAll($event, inner, section)"
              />
              <hlx-input
                v-model:value="inner.add"
                :checked="inner.add"
                :type="'single-partial-checkbox'"
                :partially-checked="inner.partially"
                @partially-value="partiallyValcheckbox($event, inner)"
                @selected="updateAdd($event, inner, section)"
              />
              <hlx-input
                v-model:value="inner.edit"
                :checked="inner.edit"
                :type="'single-partial-checkbox'"
                :partially-checked="inner.partially"
                @partially-value="partiallyValcheckbox($event, inner)"
                @selected="updateEdit($event, inner, section)"
              />
              <hlx-input
                v-model:value="inner.view"
                :checked="inner.view"
                :type="'single-partial-checkbox'"
                :partially-checked="inner.partially"
                @partially-value="partiallyValcheckbox($event, inner)"
                @selected="updateView($event, inner, section)"
              />

              <hlx-input
                v-model:value="inner.delete"
                :checked="inner.delete"
                :type="'single-partial-checkbox'"
                :partially-checked="inner.partially"
                @partially-value="partiallyValcheckbox($event, inner)"
                @selected="updateDelete($event, inner, section)"
              />
            </div>
          </button>
          <div class="panel" :class="{ show: inner.isOpen }">
            <!-- <p class="pannelsecoundLayer">{{ inner.content }}</p> -->

            <!-- Third Layer Accordion -->
            <div
              v-for="(third, thirdIndex) in inner.child ||
              inner.section ||
              inner.sections"
              :key="thirdIndex"
            >
              <button
                v-if="!third.title"
                class="accordion-btn inner-accord third-accord"
                :class="{ active: third.isOpen }"
                @click="toggleThirdLayer(index, innerIndex, thirdIndex)"
              >
                <div style="width: 19.9%">
                  <i
                    :style="{
                      visibility:
                        third?.child?.length ||
                        third?.section?.length ||
                        third?.sections?.length
                          ? 'visible'
                          : 'hidden',
                    }"
                    :class="
                      third.isOpen
                        ? 'icon-angle-down-regular'
                        : 'icon-angle-right-regular'
                    "
                    class="accordion-icon"
                  ></i>
                  {{ third.customName || third.name }}
                </div>
                <div style="display: flex; width: 78.7%; gap: 9.4%">
                  <hlx-input
                    v-model:value="third.all"
                    :checked="third.all"
                    :type="'single-partial-checkbox'"
                    :partially-checked="false"
                    @partially-value="partiallyValcheckbox()"
                    @selected="updateAll($event, third, section, inner)"
                  />
                  <hlx-input
                    v-model:value="third.add"
                    :checked="third.add"
                    :type="'single-partial-checkbox'"
                    :partially-checked="third.partially"
                    @partially-value="
                      partiallyValcheckbox($event, third.partiallyAdd)
                    "
                    @selected="updateAdd($event, third, section, inner)"
                  />
                  <hlx-input
                    v-model:value="third.edit"
                    :checked="third.edit"
                    :type="'single-partial-checkbox'"
                    :partially-checked="third.partially"
                    @partially-value="
                      partiallyValcheckbox($event, third.partiallyEdit)
                    "
                    @selected="updateEdit($event, third, section, inner)"
                  />
                  <hlx-input
                    v-model:value="third.view"
                    :checked="third.view"
                    :type="'single-partial-checkbox'"
                    :partially-checked="third.partially"
                    @partially-value="
                      partiallyValcheckbox($event, third.partiallyView)
                    "
                    @selected="updateView($event, third, section, inner)"
                  />

                  <hlx-input
                    v-model:value="third.delete"
                    :checked="third.delete"
                    :type="'single-partial-checkbox'"
                    :partially-checked="third.partially"
                    @partially-value="
                      partiallyValcheckbox($event, third.partiallyDelete)
                    "
                    @selected="updateDelete($event, third, section, inner)"
                  />
                </div>
              </button>
              <div class="panel" :class="{ show: third.isOpen }">
                <!-- <p class="pannelTiredLayer">{{ third.content }}</p> -->

                <!-- Fourth Layer Accordion -->
                <div
                  v-for="(forth, forthIndex) in third.child ||
                  third.section ||
                  third.sections"
                  :key="forthIndex"
                >
                  <button
                    v-if="!forth.title"
                    class="accordion-btn inner-accord fourth-accord"
                    :class="{ active: forth.isOpen }"
                    @click="
                      toggleForthLayer(
                        index,
                        innerIndex,
                        thirdIndex,
                        forthIndex
                      )
                    "
                  >
                    <div style="width: 19.8%">
                      <i
                        :style="{
                          visibility:
                            forth?.child?.length ||
                            forth?.section?.length ||
                            forth?.sections?.length
                              ? 'visible'
                              : 'hidden',
                        }"
                        :class="
                          forth.isOpen
                            ? 'icon-angle-down-regular'
                            : 'icon-angle-right-regular'
                        "
                        class="accordion-icon"
                      ></i>
                      {{ forth.customName || forth.name }}
                    </div>
                    <div style="display: flex; width: 75.6%; gap: 9.8%">
                      <hlx-input
                        v-model:value="forth.all"
                        :checked="forth.all"
                        :type="'single-partial-checkbox'"
                        :partially-checked="false"
                        @partially-value="partiallyValcheckbox()"
                        @selected="
                          updateAll($event, forth, section, inner, third)
                        "
                      />
                      <hlx-input
                        v-model:value="forth.add"
                        :checked="forth.add"
                        :type="'single-partial-checkbox'"
                        :partially-checked="forth.partially"
                        @partially-value="
                          partiallyValcheckbox($event, forth.partiallyAdd)
                        "
                        @selected="
                          updateAdd($event, forth, section, inner, third)
                        "
                      />
                      <hlx-input
                        v-model:value="forth.edit"
                        :checked="forth.edit"
                        :type="'single-partial-checkbox'"
                        :partially-checked="forth.partially"
                        @partially-value="
                          partiallyValcheckbox($event, forth.partiallyEdit)
                        "
                        @selected="
                          updateEdit($event, forth, section, inner, third)
                        "
                      />
                      <hlx-input
                        v-model:value="forth.view"
                        :checked="forth.view"
                        :type="'single-partial-checkbox'"
                        :partially-checked="forth.partially"
                        @partially-value="
                          partiallyValcheckbox($event, forth.partiallyView)
                        "
                        @selected="
                          updateView($event, forth, section, inner, third)
                        "
                      />

                      <hlx-input
                        v-model:value="forth.delete"
                        :checked="forth.delete"
                        :type="'single-partial-checkbox'"
                        :partially-checked="forth.partially"
                        @partially-value="
                          partiallyValcheckbox($event, forth.partiallyDelete)
                        "
                        @selected="
                          updateDelete($event, forth, section, inner, third)
                        "
                      />
                    </div>
                  </button>
                  <div class="panel" :class="{ show: forth.isOpen }">
                    <!-- <p class="pannelForthLayer">{{ forth.content }}</p> -->
                  </div>
                </div>
                <!-- End Fourth Layer Accordion -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  // DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
export default {
  emits: ["permission-value"],
  data() {
    return {
      sections: [],
      IntialPayload: [],
      updatedPayload: [],
    };
  },

  async mounted() {
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");

    this.company = await val.data.company;
    let sampleTheads = await MLTAxiosInstance.get(
      `/partyRole/${this.$route.query.id}`
    );
    const mergedPayload = sampleTheads.data.permission;
    // this.payload = sampleTheads.data;
    const transformOutputData = this.transformOutput(mergedPayload);

    const defaultSettings = await UPCAxiosInstance.get(`/customHierarchy`);

    const mergedArray = [
      ...defaultSettings?.data[0]?.module[0].main,
      ...defaultSettings?.data[0]?.module[0].footer,
    ];

    const sectionData = mergedArray;

    const currentData = this.updateMyDataWithBossData(
      sectionData,
      transformOutputData
    );

    this.IntialPayload = JSON.parse(JSON.stringify(currentData));

    this.sections = currentData;
    console.log(this.sections, "this.sectionthis.sectionthis.section");
  },
  methods: {
    updateMyDataWithBossData(myData, bossData) {
      // If myData is null, return bossData
      if (!myData) {
        return bossData || [];
      }

      // Helper function to find matching boss item by name or label
      function findMatchingBossItem(myItem, bossItems) {
        return bossItems.find(
          (bossItem) => bossItem?.customName?.toLowerCase() === myItem?.customName?.toLowerCase()
        );
      }

      // Recursive function to update myData item
      function updateItem(myItem, bossItem) {
        // Handle specific cases for "Log out" and "Help"
        if (myItem.customName === "Log out" || myItem.customName === "Help") {
          myItem.view = true;
          myItem.edit = true;
          myItem.delete = true;
          myItem.add = true;
          myItem.all = true;
          myItem.action = false;
          myItem.disabled = true;
          myItem.partially = false;
          return;
        }

        if (bossItem) {
          // Update myItem with values from bossItem
          myItem.view = bossItem.view;
          myItem.edit = bossItem.edit;
          myItem.delete = bossItem.delete;
          myItem.add = bossItem.add;
          myItem.all = bossItem.all;
          myItem.disabled = false;
          myItem.action = bossItem.action;
          myItem.partially = bossItem.partially;
        } else {
          // If bossItem is null, set all properties in myItem to false
          myItem.view = false;
          myItem.edit = false;
          myItem.delete = false;
          myItem.add = false;
          myItem.all = false;
          myItem.action = false;
          myItem.disabled = false;
          myItem.partially = false;
        }

        // If there are child items, update them recursively
        if (myItem.child) {
          for (let i = 0; i < myItem.child.length; i++) {
            const matchingBossChild = bossItem?.child
              ? findMatchingBossItem(myItem.child[i], bossItem.child)
              : null;
            updateItem(myItem.child[i], matchingBossChild);
          }
        }

        // If there are sections, update them recursively
        if (myItem.sections) {
          for (let i = 0; i < myItem.sections.length; i++) {
            const matchingBossSection = bossItem?.sections
              ? findMatchingBossItem(myItem.sections[i], bossItem.sections)
              : null;
            updateItem(myItem.sections[i], matchingBossSection);
          }
        }
      }

      // Iterate through each item in myData and find matching in bossData
      for (let i = 0; i < myData.length; i++) {
        const matchingBossItem = bossData
          ? findMatchingBossItem(myData[i], bossData)
          : null;
        updateItem(myData[i], matchingBossItem);
      }

      return myData;
    },
    mergePayload(base, update) {
      // Create a map of base items by their 'id' for easier lookup
      const baseMap = new Map(base.map((item) => [item.id, item]));

      // Iterate through the update payload
      update.map((updateItem) => {
        if (baseMap.has(updateItem.id)) {
          const baseItem = baseMap.get(updateItem.id);

          // Merge the fields from the update item into the base item
          for (let key in updateItem) {
            if (Array.isArray(updateItem[key])) {
              // If it's an array, recursively merge arrays (use mergePayload for nested arrays)
              baseItem[key] = this.mergePayload(
                baseItem[key] || [],
                updateItem[key]
              );
            } else if (
              typeof updateItem[key] === "object" &&
              updateItem[key] !== null
            ) {
              // If it's an object, merge the object properties
              baseItem[key] = this.mergePayload(
                [baseItem[key] || {}],
                [updateItem[key]]
              )[0];
            } else if (key in baseItem) {
              // If it's a simple value and exists in the base, update it
              baseItem[key] = updateItem[key];
            }
          }
        } else {
          // If no base item exists for this update item, add it (this will maintain base structure)
          base.push(updateItem);
        }
      });

      return base;
    },

    mergePayloads(firstPayload, secondPayload) {
      const mainFirst = firstPayload;
      const mainSecond = secondPayload.main;

      // Iterate through the first payload to merge or add items
      mainFirst.map((item) => {
        const matchingItem = mainSecond.find(
          (secondItem) => secondItem.id === item.id
        );

        if (matchingItem) {
          // Merge properties recursively
          Object.assign(matchingItem, {
            ...item,
            child: this.mergeChildren(item.child, matchingItem.child),
            sections: this.mergeSections(item.sections, matchingItem.sections),
          });
        } else {
          // Add new item to the second payload
          mainSecond.push(item);
        }
      });

      return secondPayload;
    },

    mergeChildren(firstChildren = [], secondChildren = []) {
      firstChildren.map((child) => {
        const matchingChild = secondChildren.find(
          (secondChild) => secondChild.id === child.id
        );

        if (matchingChild) {
          Object.assign(matchingChild, {
            ...child,
            child: this.mergeChildren(child.child, matchingChild.child),
            sections: this.mergeSections(
              child.sections,
              matchingChild.sections
            ),
          });
        } else {
          secondChildren.push(child);
        }
      });

      return secondChildren;
    },

    mergeSections(firstSections = [], secondSections = []) {
      firstSections.map((section) => {
        const matchingSection = secondSections.find(
          (secondSection) => secondSection.name === section.name
        );

        if (matchingSection) {
          Object.assign(matchingSection, section);
        } else {
          secondSections.push(section);
        }
      });

      return secondSections;
    },
    checkPermissions(payload) {
      // Function to check if any of the permissions are true
      const hasTruePermission = (item) => {
        return item.view || item.edit || item.delete || item.add || item.all;
      };

      // Recursively check the permissions in the payload
      const checkChildPermissions = (children) => {
        if (!children) return false;

        for (const child of children) {
          if (hasTruePermission(child)) return true;

          // Check nested sections if they exist
          if (child.sections) {
            for (const section of child.sections) {
              if (hasTruePermission(section)) return true;
            }
          }

          // Recursively check the child's children if 'child' exists
          if (child.child && checkChildPermissions(child.child)) {
            return true;
          }

          // If 'child' is not present, check 'sections'
          if (!child.child && child.sections) {
            for (const section of child.sections) {
              if (hasTruePermission(section)) return true;
            }
          }
        }

        return false;
      };

      // Start the check from the top-level child
      return checkChildPermissions(payload.child);
    },
    partiallyValcheckbox(event, partiallyvalue) {
      // console.log(event, partiallyvalue, "partiallyValcheckbox");
      event;
      partiallyvalue;
    },
    selectedValcheckbox(
      event,
      currentSection,
      firstParent,
      secondParent,
      thirdParent,
      forthParent
    ) {
      console.log(
        event,
        currentSection,
        firstParent,
        secondParent,
        thirdParent,
        forthParent,
        "event,currentSection , firstParent"
      );
      if (firstParent && event) {
        firstParent.partially = true;
      } else if (firstParent && event === false) {
        console.log("na uncheck aitan");
        firstParent.partially = this.checkPermissions(firstParent);
      }
      if (firstParent && event && secondParent) {
        firstParent.partially = true;
        secondParent.partially = true;
      } else if (firstParent && event === false && secondParent) {
        console.log("na uncheck aitan");
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
      }
      if (firstParent && event && secondParent && thirdParent) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
      } else if (
        firstParent &&
        event === false &&
        secondParent &&
        thirdParent
      ) {
        console.log("na uncheck aitan");
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
      }
      if (firstParent && event && secondParent && thirdParent && forthParent) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
        forthParent.partially = true;
      } else if (
        firstParent &&
        event === false &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        console.log("na uncheck aitan");
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
        forthParent.partially = this.checkPermissions(secondParent);
      }

      const mergedPayload = this.transformData(this.sections);

      console.log(mergedPayload, "gbfdsvcdgbfdsvcdgbfdsvcd");

      this.$emit("permission-value", mergedPayload);
      console.log(this.sections, "this.sections");
    },
    transformData(data) {
      const result = {};

      data.map((item) => {
        const {
          name,
          customName,
          view,
          edit,
          delete: del,
          add,
          all,
          partially,
          child,
          sections,
        } = item;

        const transformed = {
          view: !!view,
          edit: !!edit,
          delete: !!del,
          add: !!add,
          all: !!all,
          partially: !!partially,
        };

        if (child) {
          transformed.child = {};
          child.map((subItem) => {
            const childResult = this.transformData([subItem]);
            const childKey = Object.keys(childResult)[0];
            transformed.child[childKey] = childResult[childKey];
          });
        }

        if (sections) {
          transformed.sections = {};
          sections.map((section) => {
            transformed.sections[section.value] = {
              view: !!section.view,
              edit: !!section.edit,
              delete: !!section.delete,
              add: !!section.add,
              all: !!section.all,
              partially: !!section.partially,
            };
          });
        }
        name;
        result[customName] = transformed;
      });

      return result;
    },
    transformOutput(data) {
      if (!data || Object.keys(data).length === 0) {
        return null; // Return null if data is empty or not valid
      }
      return Object.entries(data).map(([key, value]) => {
        const transformed = {
          // Generate a unique ID if none exists
          name: key,
          label: key.toLowerCase(),
          view: value.view || false,
          edit: value.edit || false,
          delete: value.delete || false,
          add: value.add || false,
          all: value.all || false,
          active: false, // Default to false unless otherwise specified
          description: "", // Optional, add meaningful content if available
          customName: key,
          partially: value.partially || false,
        };

        if (value.child) {
          transformed.child = this.transformOutput(value.child);
        }

        if (value.sections) {
          transformed.sections = Object.entries(value.sections).map(
            ([sectionKey, sectionValue]) => ({
              name: sectionKey,
              value: sectionKey.toLowerCase(),
              view: sectionValue.view || false,
              edit: sectionValue.edit || false,
              delete: sectionValue.delete || false,
              add: sectionValue.add || false,
              all: sectionValue.all || false,
              customName: sectionKey,
              icon: "icon-placeholder", // Add relevant icons as needed
              partially: sectionValue.partially || false,
            })
          );
        }

        return transformed;
      });
    },
    updateAll(
      setTrue,
      data,
      firstParent,
      secondParent,
      thirdParent,
      forthParent
    ) {
      console.log(setTrue, data);
      const setTrueval = setTrue.checked;
      const setPermissions = (item, setTrueval) => {
        item.active = false;

        item.view = setTrueval;
        item.edit = setTrueval;
        item.delete = setTrueval;
        item.add = setTrueval;
        item.all = setTrueval;
        if (setTrueval === false) {
          item.partially = false;
        }
        if (item.child) {
          item.child.map((child) => setPermissions(child, setTrueval));
        }

        if (item.sections) {
          item.sections.map((section) => setPermissions(section, setTrueval));
        }
      };
      console.log(setTrue, "setTrue setTrue");

      setPermissions(data, setTrueval);
      if (firstParent && setTrueval) {
        firstParent.partially = true;
      } else if (firstParent && setTrueval === false) {
        console.log("na uncheck aitan");
        firstParent.partially = this.checkPermissions(firstParent);
      }
      if (firstParent && setTrueval && secondParent) {
        firstParent.partially = true;
        secondParent.partially = true;
      } else if (firstParent && setTrueval === false && secondParent) {
        console.log("na uncheck aitan");
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
      }
      if (firstParent && setTrueval && secondParent && thirdParent) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
      } else if (
        firstParent &&
        setTrueval === false &&
        secondParent &&
        thirdParent
      ) {
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
      }
      if (
        firstParent &&
        setTrueval &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
        forthParent.partially = true;
      } else if (
        firstParent &&
        setTrueval === false &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        console.log("na uncheck aitan");
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
        forthParent.partially = this.checkPermissions(secondParent);
      }

      const mergedPayload = this.transformData(this.sections);

      console.log(mergedPayload, "gbfdsvcdgbfdsvcdgbfdsvcd");

      this.$emit("permission-value", mergedPayload);
      console.log(this.sections, "this.sections");

      return data;
    },
    updateAdd(
      setTrue,
      data,
      firstParent,
      secondParent,
      thirdParent,
      forthParent
    ) {
      console.log(setTrue, data);
      const setTrueval = setTrue.checked;
      var setPermissions = (item, setTrueval) => {
        item.active = false;

        item.add = setTrueval;
        if (setTrueval === false) {
          item.all = setTrueval;
        }
        // item.all = setTrueval;

        if (item.child) {
          item.child.map((child) => setPermissions(child, setTrueval));
        }

        if (item.sections) {
          item.sections.map((section) => setPermissions(section, setTrueval));
        }
      };
      // if (data.add && data.edit & data.delete & data.view) {
      //   data.all = true;
      //    setPermissions = (item, setTrueval) => {
      //   item.active = false;

      //   item.all = true;
      //   // item.all = setTrueval;

      //   if (item.child) {
      //     item.child.map((child) => setPermissions(child, setTrueval));
      //   }

      //   if (item.sections) {
      //     item.sections.map((section) =>
      //       setPermissions(section, setTrueval)
      //     );
      //   }
      // };
      // }
      console.log(setTrue, "setTrue setTrue");

      setPermissions(data, setTrueval);
      if (firstParent && setTrueval) {
        firstParent.partially = true;
      } else if (firstParent && setTrueval === false) {
        console.log("na uncheck aitan");
        firstParent.partially = this.checkPermissions(firstParent);
      }
      if (firstParent && setTrueval && secondParent) {
        firstParent.partially = true;
        secondParent.partially = true;
      } else if (firstParent && setTrueval === false && secondParent) {
        console.log("na uncheck aitan");
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
      }
      if (firstParent && setTrueval && secondParent && thirdParent) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
      } else if (
        firstParent &&
        setTrueval === false &&
        secondParent &&
        thirdParent
      ) {
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
      }
      if (
        firstParent &&
        setTrueval &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
        forthParent.partially = true;
      } else if (
        firstParent &&
        setTrueval === false &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        console.log("na uncheck aitan");
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
        forthParent.partially = this.checkPermissions(secondParent);
      }

      const mergedPayload = this.transformData(this.sections);

      console.log(mergedPayload, "gbfdsvcdgbfdsvcdgbfdsvcd");

      this.$emit("permission-value", mergedPayload);
      console.log(this.sections, "this.sections");

      return data;
    },
    updateEdit(
      setTrue,
      data,
      firstParent,
      secondParent,
      thirdParent,
      forthParent
    ) {
      console.log(setTrue, data, firstParent);
      const setTrueval = setTrue.checked;
      const setPermissions = (item, setTrueval) => {
        item.active = false;

        item.edit = setTrueval;
        // item.all = setTrueval;
        if (setTrueval === false) {
          item.all = setTrueval;
        }

        if (item.child) {
          item.child.map((child) => setPermissions(child, setTrueval));
        }

        if (item.sections) {
          item.sections.map((section) => setPermissions(section, setTrueval));
        }
      };
      console.log(setTrue, "setTrue setTrue");

      setPermissions(data, setTrueval);
      if (firstParent && setTrueval) {
        firstParent.partially = true;
      } else if (firstParent && setTrueval === false) {
        console.log("na uncheck aitan");
        firstParent.partially = this.checkPermissions(firstParent);
      }
      if (firstParent && setTrueval && secondParent) {
        firstParent.partially = true;
        secondParent.partially = true;
      } else if (firstParent && setTrueval === false && secondParent) {
        console.log("na uncheck aitan");
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
      }
      if (firstParent && setTrueval && secondParent && thirdParent) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
      } else if (
        firstParent &&
        setTrueval === false &&
        secondParent &&
        thirdParent
      ) {
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
      }
      if (
        firstParent &&
        setTrueval &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
        forthParent.partially = true;
      } else if (
        firstParent &&
        setTrueval === false &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        console.log("na uncheck aitan");
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
        forthParent.partially = this.checkPermissions(secondParent);
      }

      const mergedPayload = this.transformData(this.sections);

      console.log(mergedPayload, "gbfdsvcdgbfdsvcdgbfdsvcd");

      this.$emit("permission-value", mergedPayload);
      console.log(this.sections, "this.sections");

      return data;
    },
    updateView(
      setTrue,
      data,
      firstParent,
      secondParent,
      thirdParent,
      forthParent
    ) {
      console.log(setTrue, data);
      const setTrueval = setTrue.checked;
      const setPermissions = (item, setTrueval) => {
        item.active = false;

        item.view = setTrueval;
        if (setTrueval === false) {
          item.all = setTrueval;
        }
        // item.all = setTrueval;

        if (item.child) {
          item.child.map((child) => setPermissions(child, setTrueval));
        }

        if (item.sections) {
          item.sections.map((section) => setPermissions(section, setTrueval));
        }
      };
      console.log(setTrue, "setTrue setTrue");

      setPermissions(data, setTrueval);
      if (firstParent && setTrueval) {
        firstParent.partially = true;
      } else if (firstParent && setTrueval === false) {
        console.log("na uncheck aitan");
        firstParent.partially = this.checkPermissions(firstParent);
      }
      if (firstParent && setTrueval && secondParent) {
        firstParent.partially = true;
        secondParent.partially = true;
      } else if (firstParent && setTrueval === false && secondParent) {
        console.log("na uncheck aitan");
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
      }
      if (firstParent && setTrueval && secondParent && thirdParent) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
      } else if (
        firstParent &&
        setTrueval === false &&
        secondParent &&
        thirdParent
      ) {
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
      }
      if (
        firstParent &&
        setTrueval &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
        forthParent.partially = true;
      } else if (
        firstParent &&
        setTrueval === false &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        console.log("na uncheck aitan");
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
        forthParent.partially = this.checkPermissions(secondParent);
      }

      const mergedPayload = this.transformData(this.sections);

      console.log(mergedPayload, "gbfdsvcdgbfdsvcdgbfdsvcd");

      this.$emit("permission-value", mergedPayload);
      console.log(this.sections, "this.sections");

      return data;
    },
    updateDelete(
      setTrue,
      data,
      firstParent,
      secondParent,
      thirdParent,
      forthParent
    ) {
      console.log(setTrue, data);
      const setTrueval = setTrue.checked;
      const setPermissions = (item, setTrueval) => {
        item.active = false;

        item.delete = setTrueval;
        // item.all = setTrueval;
        if (setTrueval === false) {
          item.all = setTrueval;
        }

        if (item.child) {
          item.child.map((child) => setPermissions(child, setTrueval));
        }

        if (item.sections) {
          item.sections.map((section) => setPermissions(section, setTrueval));
        }
      };
      console.log(setTrue, "setTrue setTrue");

      setPermissions(data, setTrueval);
      if (firstParent && setTrueval) {
        firstParent.partially = true;
      } else if (firstParent && setTrueval === false) {
        console.log("na uncheck aitan");
        firstParent.partially = this.checkPermissions(firstParent);
      }
      if (firstParent && setTrueval && secondParent) {
        firstParent.partially = true;
        secondParent.partially = true;
      } else if (firstParent && setTrueval === false && secondParent) {
        console.log("na uncheck aitan");
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
      }
      if (firstParent && setTrueval && secondParent && thirdParent) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
      } else if (
        firstParent &&
        setTrueval === false &&
        secondParent &&
        thirdParent
      ) {
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
      }
      if (
        firstParent &&
        setTrueval &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        firstParent.partially = true;
        secondParent.partially = true;
        thirdParent.partially = true;
        forthParent.partially = true;
      } else if (
        firstParent &&
        setTrueval === false &&
        secondParent &&
        thirdParent &&
        forthParent
      ) {
        console.log("na uncheck aitan");
        firstParent.partially = this.checkPermissions(firstParent);
        secondParent.partially = this.checkPermissions(secondParent);
        thirdParent.partially = this.checkPermissions(secondParent);
        forthParent.partially = this.checkPermissions(secondParent);
      }

      const mergedPayload = this.transformData(this.sections);

      console.log(mergedPayload, "gbfdsvcdgbfdsvcdgbfdsvcd");

      this.$emit("permission-value", mergedPayload);
      console.log(this.sections, "this.sections");

      return data;
    },

    toggleSection(index) {
      this.sections[index].isOpen = !this.sections[index].isOpen;
    },
    toggleInnerSection(sectionIndex, innerIndex) {
      this.sections[sectionIndex].child[innerIndex].isOpen =
        !this.sections[sectionIndex].child[innerIndex].isOpen;
    },
    toggleThirdLayer(sectionIndex, innerIndex, thirdIndex) {
      this.sections[sectionIndex].child[innerIndex].child[thirdIndex].isOpen =
        !this.sections[sectionIndex].child[innerIndex].child[thirdIndex].isOpen;
    },
    toggleForthLayer(sectionIndex, innerIndex, thirdIndex, forthIndex) {
      this.sections[sectionIndex].child[innerIndex].child[thirdIndex].sections[
        forthIndex
      ].isOpen =
        !this.sections[sectionIndex].child[innerIndex].child[thirdIndex]
          .sections[forthIndex].isOpen;
    },
  },
};
</script>

<style scoped>
.accordion {
  overflow-y: auto; /* Enable vertical scrolling */
  scrollbar-width: none;
}
.accordion::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}
.pannelFirstLayer {
  font-family: "Opensans";
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  padding-left: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.pannelsecoundLayer {
  font-family: "Opensans";
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  padding-left: 58px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.pannelTiredLayer {
  font-family: "Opensans";
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  padding-left: 78px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.pannelForthLayer {
  font-family: "Opensans";
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  padding-left: 54px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
/* Accordion styles */
.fourth-accord {
  padding-left: 54px !important;

  /* background-color: #7f8c8d; */
}
/*.fourth-accord:hover,
.fourth-accord.active {
  /* background-color: #95a5a6; 
}*/
.accordion-btn {
  position: relative;
  /* background-color: #2c3e50; */
  display: flex;
  align-items: center;
  font-family: "Opensans";
  font-size: 16px;
  font-weight: 600;
  line-height: 19.07px;
  cursor: pointer;
  padding: 14px 0;
  width: 100%;
  text-align: left;
  border: none;
  transition: 0.3s;
}
.accordion-icon {
  float: left;
  margin-right: 10px;
  color: black;
  transition: transform 0.3s;
}
.accordion-btn.active .accordion-icon {
  color: balck;
  /* transform: rotate(90deg);  Rotate when active */
}
.panel {
  /* background-color: black; */
  max-height: 0;
  /* overflow: hidden; */
  height: auto;
  transition: max-height 0.3s ease, opacity 0.3s;
  opacity: 0;
}
.panel.show {
  opacity: 1;
  max-height: 100%; /* Adjust to control expansion height */
}
.inner-accord {
  /* background-color: #5b80a4; */
  font-family: "Opensans";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding-left: 30px;

  /* padding: 1em 1.75em; */
}
/*.inner-accord:hover,
.inner-accord.active {
   background-color: #7b99b7; 
}*/
.third-accord {
  padding-left: 52px;

  /* background-color: #3498db; */
  /* padding: 0.8em 2.5em; */
}
/*.third-accord:hover,
.third-accord.active {
   background-color: #4ca3e6; 
}*/
</style>
