<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <!-- <hlx-loader load-type="spinner" v-if="this.addeditdatapagespinner === true"/> -->
  <div class="app-layout">
    <section class="right-panel">
      <!-- {{tableData}} -->
      <div class="layout-basic-no-breadcrumb" style="display: block">
        <div class="layout-breadcrumb">
        <hlx-breadcrumb :items="items" @path="path" />
      </div>

        <div class="layout-body business-rules" style="padding: 0px">
          <div class="layout-body-schema-table-header" style="display: flex">
            <span
              class="add-edit-reference-data"
              style="
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
              "
            >
              <span class="add-edit-reference-data-left">
                <p
                  v-if="referencedataname != undefined"
                  style="font-weight: bold"
                >
                  {{
                    referencedataname.charAt(0).toUpperCase() +
                    referencedataname.slice(1)
                  }}
                </p>
              </span>
              <span
                class="add-edit-reference-data-right"
                style="display: flex; gap: 8px"
              >
                <hlx-button
                  v-if="bulkdeletebutton === true"
                  class="primary add-edit-filter-button sm"
                  @click="bulkdeletedata"
                  >Delete</hlx-button
                >
                <div class="util-items search">
                  <hlx-search
                    :search_style="'style1'"
                    class="addeditsearch"
                    @search-key="search"
                  />
                </div>
                <!-- <hlx-button class="secondary-success add-edit-filter-button sm"
                ><i class="icon-filter-regular"></i>Filter</hlx-button
              > -->
                <hlx-button
                  v-if="nestedshow == true"
                  class="secondary-success add-edit-add-button sm"
                  @click="importDataModal = true"
                  >Import</hlx-button
                >
                <hlx-button
                  v-if="nestedshow == true"
                  class="primary add-edit-add-button sm"
                  @click="adddata"
                  ><i class="icon-plus-circle-regular"></i>Add</hlx-button
                >
              </span>
            </span>
          </div>
          <hlx-table
            v-if="
              duplicateTheads != undefined &&
              tableData != undefined &&
              tableData.length > 0
            "
            :column-count="theads.length + 2"
            :border="['table', 'header', 'horizontal']"
            :bold-headers="false"
            :row-hover="true"
            theme="grey"
            :striped-rows="false"
            :re-arrange-columns="re_arrange"
            :re-arrange-heads="theads"
            @close-rearrange="arrange"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
              <hlx-table-head :width="80" :fixed="'left'">
                <div style="display: flex; align-items: center">
                  <label v-if="nestedshow == true" class="check-container">
                    <i
                      v-if="partially_checked == true"
                      class="icon-minus-regular partial-check"
                    ></i>
                    <input
                      type="checkbox"
                      :class="'select-all' + unique"
                      :checked="false"
                      @click="selectAll"
                    />
                    <span
                      :id="'select-all-checkmark' + unique"
                      class="checkmark"
                    ></span>
                  </label>
                  <span style="margin-left: 5px">{{ "S.no" }}</span>
                </div>
              </hlx-table-head>
              <!-- <hlx-table-head :width="70" :align="'center'">{{ "S.no" }}</hlx-table-head> -->
              <hlx-table-head
                v-for="(i, index) in duplicateTheads"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                @sorting_func="sorting_Data"
                @resizewidth="resizewidthdata"
              >
                {{ i.label.charAt(0).toUpperCase() + i.label.slice(1) }}
              </hlx-table-head>
              <hlx-table-head
                v-if="nestedshow == true"
                :align="'center'"
                :width="80"
                :fixed="'right'"
                ><span class="action-icon"
                  ><i
                    class="icon-settings-regular"
                    @click="arrange(true)"
                  ></i></span
              ></hlx-table-head>
              <!-- <hlx-table-head
                v-show="editMode"
                :align="'center'"
                :fixed="'right'"
                ><span class="action-gear">{{ 'Action' }}</span></hlx-table-head
              > -->
            </template>
            <template #tbody>
              <tr v-for="(i, index) in paginatedData" id="" :key="index">
                <hlx-table-cell :fixed="'left'" :width="80">
                  <div style="display: flex; align-items: center">
                    <label v-if="nestedshow == true" class="check-container">
                      <input
                        :id="'check-drag' + unique + index"
                        type="checkbox"
                        :checked="i.checked"
                        :disabled="i.disabled"
                        :class="'check-drag' + unique"
                        @click="checkItem(i, $event)"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <span style="margin-left: 15px">{{
                      serialNumber(index)
                    }}</span>
                  </div>
                </hlx-table-cell>
                <!-- <hlx-table-cell :align="'center'" :fixed="'left'" style="padding-right:30px"></hlx-table-cell> -->
                <hlx-table-cell
                  v-for="(j, col_index) in duplicateTheads"
                  :key="col_index"
                  :align="j.align"
                >
                <!-- {{ "j.type"+j.type }} -->
                  <div
                    v-if="j.type === 'String'"
                    style="
                      width: 100%;
                      max-width: min-content;
                      cursor: pointer;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{
                      i[j.prop] != undefined && i[j.prop] != undefined
                        ? i[j.prop]
                        : "-"
                    }}
                  </div>

                  <div v-else-if="j.type === 'Array'">
                    
                <!-- {{  "i => obj" + JSON.stringify(i[j.prop]) }} -->
                    <span
                      v-if="i[j.prop] != undefined"
                      class="link-name"
                      @click="nesteddata(j.type, j.ref, j.prop, index, true)"
                      >{{ i[j.prop][0]?.length }}</span
                    >
                    <!-- {{
                    i[j.prop][0].length
                        }} -->
                  </div>
                  <div
                    v-else-if="j.type === 'Number'"
                    style="
                      width: 100%;
                      max-width: min-content;
                      cursor: pointer;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{
                      i[j.prop] != undefined && i[j.prop] != undefined
                        ? i[j.prop]
                        : "-"
                    }}
                  </div>
                  <div
                    v-else-if="j.type === 'Object'"
                    style="
                      width: 100%;
                      max-width: min-content;
                      cursor: pointer;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                  <!-- {{ i[j.prop] }} -->
                  <!-- {{
                  i?.[j.prop] 
                    ? (
                      i?.[j.prop]?.[0]?.[0]?.[j.refshowoptions] !== undefined
                        ? i[j.prop][0][0][j.refshowoptions]
                        : i?.[j.prop]?.[j.refshowoptions] !== undefined
                          ? i[j.prop][j.refshowoptions]
                          : "-"
                    )
                    : "-"
                }} -->
                {{
  i?.[j.prop] 
    ? i[j.prop] 
    : i?.[j.prop]?.[0]?.[0]?.[j.refshowoptions] 
      ? i[j.prop][0][0][j.refshowoptions] 
      : i?.[j.prop]?.[j.refshowoptions] 
        ? i[j.prop][j.refshowoptions] 
        : "-"
}}


                  </div>
                  <div
                    v-else-if="j.type === 'Date'"
                    style="
                      width: 100%;
                      max-width: min-content;
                      cursor: pointer;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{
                      i[j.prop] != undefined && i[j.prop] != undefined
                        ? convertdateformat(i[j.prop])
                        : "-"
                    }}
                  </div>
                  <div
                    v-else-if="j.type === 'Boolean'"
                    style="
                      width: 100%;
                      max-width: min-content;
                      cursor: pointer;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{
                      i[j.prop] != undefined && i[j.prop] != undefined
                        ? i[j.prop]
                        : "-"
                    }}
                  </div>
                  <div
                    v-else
                    style="
                      width: 100%;
                      max-width: min-content;
                      cursor: pointer;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{
                      i[j.prop] != undefined && i[j.prop] != undefined
                        ? i[j.prop]
                        : "-"
                    }}
                  </div>
                </hlx-table-cell>
                <hlx-table-cell
                  v-if="nestedshow == true"
                  v-show="editMode == true"
                  :fixed="'right'"
                >
                  <span class="add-edit-actions">
                    <i class="icon-edit-filled" @click="editdata(i, index)"></i>
                    <i class="icon-trash-filled" @click="deletedata(index)"></i>
                  </span>
                </hlx-table-cell>
              </tr>
            </template>
          </hlx-table>
          <hlx-table
            v-else
            :column-count="theads.length + 2"
            :border="['table', 'header']"
            :bold-headers="false"
            :row-hover="true"
            theme="grey"
            :striped-rows="false"
            :re-arrange-columns="re_arrange"
            :re-arrange-heads="theads"
            @close-rearrange="arrange"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
              <hlx-table-head :width="60" :align="'left'">{{
                "S.no"
              }}</hlx-table-head>
              <hlx-table-head
                v-for="(i, index) in theads"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                style="position: sticky; top: 0px"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
              >
                {{ i.label }}
              </hlx-table-head>
              <hlx-table-head :width="100">
                <span class="action-icon"
                  ><i
                    class="icon-settings-regular"
                    @click="arrange(true)"
                  ></i></span
              ></hlx-table-head>
            </template>
            <template #tbody>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell :colspan="theads.length + 2">{{
                  "No data"
                }}</hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
            </template>
          </hlx-table>
        </div>

        <div class="layout-footer-fixed">
          <hlx-pagination
            v-model:rows-per-page="externalRowsPerPage"
            :total="totalPageNo"
            enable-rows-per-page
            :rows-per-page-list="[10, 15, 20]"
            :options="{
              attributes: {
                rowsPerPageTitle: true,
                rowsPerPageResult: true,
                rowsPerPageDropdown: {
                  position: {
                    top: -95,
                    right: 1,
                  },
                },
              },
            }"
            @current-page="currentPage"
            @updated:rows-per-page="changeRowsPerPage"
          />
        </div>
      </div>

      <hlx-modal
        v-if="modal_active == true"
        :modal-active="modal_active"
        :height="'550px'"
        :width="'700px'"
        :modal="true"
        @close="modal_active = false"
      >
        <template #header>
          <div>Add data</div>
        </template>
        <template #content>
          <!-- {{adddataobj}} -->
          <div class="modal-content1">
            <div class="add-data-modal">
              <span
                v-for="(i, index) in theads"
                :key="index"
                class="add-data-model-container"
              >
                <span
                  v-if="
                    i.type === 'Number' ||
                    i.type === 'String' ||
                    i.type === 'Date' ||
                    i.type === 'Boolean' ||
                    i.type === 'Array' ||
                    i.type === 'Object'
                  "
                  class="add-data-model-container-left"
                >
                  <span v-if="i.required === true">
                    {{ i.label.charAt(0).toUpperCase() + i.label.slice(1) }}
                    <span style="color: #ff5050">*</span>
                  </span>
                  <span v-else>
                    {{ i.label.charAt(0).toUpperCase() + i.label.slice(1) }}
                  </span></span
                >

                <span
                  v-if="i.type === 'String'"
                  class="add-data-model-container-right"
                >
                  <!-- {{this.adddataobj[i.prop]}} -->
                  <hlx-input
                    v-model:value="adddataobj[i.prop]"
                    :label-animation="false"
                    type="text"
                    :pre-val="adddataobj[i.prop]"
                    :display-error="errorvalidation[i.prop]"
                    :custom-error="errorvalidation[i.prop]"
                    :custom-error-message="'Please fill the field'"
                /></span>
                <span
                  v-if="i.type === 'Number'"
                  class="add-data-model-container-right"
                >
                  <!-- {{i.prop}} -->
                  <hlx-input
                    v-model:value="adddataobj[i.prop]"
                    :label-animation="false"
                    type="number"
                    label-value=""
                    :clearable="false"
                    :pre-val="adddataobj[i.prop]"
                    :display-error="errorvalidation[i.prop]"
                    :custom-error="errorvalidation[i.prop]"
                    :custom-error-message="'Please fill the field'"
                  />
                </span>
                <span
                  v-if="i.type === 'Date'"
                  class="add-data-model-container-right"
                >
                  <hlx-input
                    v-model:value="adddataobj[i.prop]"
                    :pre-val="adddataobj[i.prop]"
                    :label-value="i.prop"
                    :label-animation="true"
                    type="date"
                    icon="calendar-regular"
                    icon-position="right"
                    :display-error="errorvalidation[i.prop]"
                    :custom-error="errorvalidation[i.prop]"
                    :custom-error-message="'Please fill the field'"
                  ></hlx-input>
                </span>
                <span
                  v-if="i.type === 'Boolean'"
                  class="add-data-model-container-right-radio"
                >
                  <hlx-input
                    v-model:value="adddataobj[i.prop]"
                    :checked="adddataobj[i.prop]"
                    :type="'switch'"
                    :display-error="errorvalidation[i.prop]"
                    :custom-error="errorvalidation[i.prop]"
                    :custom-error-message="'Please fill the field'"
                  />
                </span>
                <span
                  v-if="i.type === 'Array'"
                  class="add-data-model-container-right"
                >
                  <!-- {{ this.adddataobj[i.prop] }} -->
                  <hlx-multi-select
                    :options="i.options.filter(ele=>ele[i.refshowoptions])"
                    :label="i.refshowoptions"
                    :prop-value="i.refshowoptions"
                    :label-animation="false"
                    :placeholder="''"
                    @selected-array="
                      selectedvalue($event, i.prop, i.refshowoptions)
                    "
                  >
                  </hlx-multi-select>
                </span>
                <span
                  v-if="i.type === 'Object'"
                  class="add-data-model-container-right"
                >
                  <!-- {{ this.adddataobj[i.prop] }} -->
                  <hlx-select
                    :options="i.options.filter(ele=>ele[i.refshowoptions])"
                    :placeholder-value="''"
                    :label="i.refshowoptions"
                    :prop-value="i.refshowoptions"
                    :label-animation="false"
                    :inline-search="true"
                    @selected-value="selectedObject($event, i.prop)"
                  ></hlx-select
                ></span>
              </span>
            </div>
          </div>
        </template>
        <template #footer>
          <span class="footer-button">
            <hlx-button
              class="secondary-success sm clear-button"
              @click="clearsavedata"
              >Clear</hlx-button
            >
            <hlx-button class="primary sm" @click="saveadddata">Add</hlx-button>
          </span>
        </template>
      </hlx-modal>

      <hlx-modal
        v-if="modal_active1 == true"
        :modal-active="modal_active1"
        :height="'550px'"
        :width="'700px'"
        :modal="true"
        @close="editclose"
      >
        <template #header>
          <div>Edit data</div>
        </template>
        <template #content>
          <div class="modal-content1">
            <div class="add-data-modal">
              <span
                v-for="(i, index) in theads"
                :key="index"
                class="add-data-model-container"
              >
                <span
                  v-if="
                    i.type === 'Number' ||
                    i.type === 'String' ||
                    i.type === 'Date' ||
                    i.type === 'Boolean' ||
                    i.type === 'Array' ||
                    i.type === 'Object'
                  "
                  class="add-data-model-container-left"
                >
                  <span v-if="i.required === true">
                    {{ i.label.charAt(0).toUpperCase() + i.label.slice(1) }}
                    <span style="color: #ff5050">*</span>
                  </span>
                  <span v-else>
                    {{ i.label.charAt(0).toUpperCase() + i.label.slice(1) }}
                  </span></span
                >
                <!-- {{i?.[i.prop]?.[0]?.[0]?.[i.refshowoptions] + "editdataobj[i.prop]" + JSON.stringify( editdataobj[i.prop] )}} -->
                <span
                  v-if="i.type === 'String'"
                  class="add-data-model-container-right"
                >
                  <hlx-input
                    v-model:value="editdataobj[i.prop]"
                    :label-animation="true"
                    label-value=""
                    type="text"
                    :pre-val="editdataobj[i.prop]"
                    :display-error="errorvalidation[i.prop]"
                    :custom-error="errorvalidation[i.prop]"
                    :custom-error-message="'Please fill the field'"
                  />
                </span>
                <span
                  v-if="i.type === 'Number'"
                  class="add-data-model-container-right"
                >
                  <hlx-input
                    v-model:value="editdataobj[i.prop]"
                    :label-animation="false"
                    type="number"
                    label-value=""
                    :clearable="false"
                    :pre-val="editdataobj[i.prop]"
                    :display-error="errorvalidation[i.prop]"
                    :custom-error="errorvalidation[i.prop]"
                    :custom-error-message="'Please fill the field'"
                  />
                </span>
                <span
                  v-if="i.type === 'Date'"
                  class="add-data-model-container-right"
                >
                  <hlx-input
                    v-model:value="editdataobj[i.prop]"
                    :pre-val="editdataobj[i.prop]"
                    :label-value="i.prop"
                    :label-animation="true"
                    type="date"
                    icon="calendar-regular"
                    icon-position="right"
                    :display-error="errorvalidation[i.prop]"
                    :custom-error="errorvalidation[i.prop]"
                    :custom-error-message="'Please fill the field'"
                  ></hlx-input>
                </span>
                <span
                  v-if="i.type === 'Boolean'"
                  class="add-data-model-container-right-radio"
                >
                  <hlx-input
                    v-model:value="editdataobj[i.prop]"
                    :pre-val="editdataobj[i.prop]"
                    :checked="editdataobj[i.prop]"
                    :type="'switch'"
                    :display-error="errorvalidation[i.prop]"
                    :custom-error="errorvalidation[i.prop]"
                    :custom-error-message="'Please fill the field'"
                  />
                </span>
                <span
                  v-if="i.type === 'Array'"
                  class="add-data-model-container-right"
                >
                  <!-- {{i}} -->
                  <!-- {{editdataobj}}
                  {{editdataobj[i.prop]}}
                  {{i.refshowoptions}} -->
                  <!-- {{ editdataobj[i.prop]?.map(item => item[i.refshowoptions]) }} -->
                  <hlx-multi-select
                    :pre-value="
                      i.refshowoptions
                        ? editdataobj[i.prop]?.map(
                            (item) => item[i.refshowoptions]
                          )
                        : editdataobj[i.prop]?.map((item) => {
                            return { name: item };
                          })
                    "
                    :options="i.options"
                    :label="i.refshowoptions ? i.refshowoptions : 'name'"
                    :prop-value="i.refshowoptions ? i.refshowoptions : 'name'"
                    :label-animation="false"
                    :placeholder="''"
                    @selected-array="
                      selectedvalue1($event, i.prop, i.refshowoptions)
                    "
                  >
                  </hlx-multi-select>
                </span>
                <span
                  v-if="i.type === 'Object'"
                  class="add-data-model-container-right"
                >
                  <hlx-select
                    v-model:value="editdataobj[i.prop][i.refshowoptions]"
                    :pre-value="editdataobj[i.prop][i.refshowoptions]"
                    :options="i.options"
                    :placeholder-value="''"
                    :label="i.refshowoptions"
                    :prop-value="i.refshowoptions"
                    :label-animation="false"
                    :inline-search="true"
                    @selected-value="selectedObject1($event, i.prop)"
                  ></hlx-select>
                </span>
              </span>
            </div>
          </div>
        </template>
        <template #footer>
          <span class="footer-button">
            <hlx-button
              class="secondary-success sm clear-button"
              @click="cleareditdata"
              >Clear</hlx-button
            >
            <hlx-button class="primary sm" @click="saveeditdata"
              >Save</hlx-button
            >
          </span>
        </template>
      </hlx-modal>

      <hlx-modal
        :modal-active="deletedialog"
        :height="'200px'"
        :width="'450px'"
        :modal="true"
        @close="deletedialog = false"
      >
        <template #header>
          <div>Delete</div>
        </template>
        <template #content>
          <div class="modal-content">
            <p>
              Are you sure you want to delete the data?
            </p>
          </div>
        </template>
        <template #footer>
          <hlx-button class="secondary-success sm" @click="deletedialog = false"
            >Cancel</hlx-button
          >
          <hlx-button class="primary sm deletedialogok" @click="deleteokmodal"
            >Delete</hlx-button
          >
        </template>
      </hlx-modal>
      <hlx-modal
        :modal-active="importDataModal"
        :height="'335px'"
        :width="'450px'"
        :modal="true"
        @close="importDataModal = false"
      >
        <template #header>
          <div>Import</div>
        </template>
        <template #content>
          <div class="modal-content">
            <!-- <hlx-file-upload
              :file-type="'.xlsx,.csv,.json'"
              :file-size-limit="'20mb'"
              :custom-icon="icon"
              :type="'regular'"
              :content="'Import'"
              @file-data="abc"
            /> -->
            <!-- <hlx-file-upload
              :file-type="'.jpg,.jpeg,.png,.json,.xlsx,.doc,.pdf,.txt'"
              :file-size-limit="'1gb'"
              :custom-icon="icon"
              :type="'regular'"
              :style-background="backgrounStyleupload"
              :icon="'icon-arrow-down-to-line-filled'"
              :content="'Import'"
              @file-data="imgDataPrimary"
              @delete-image="funcdelete"
              @download-image="func1"
            /> -->
            <!-- <hlx-file-upload
                  :file-type="'.jpg,.jpeg,.png,.json,.xlsx,.doc,.pdf,.txt'"
        :file-size-limit="'1gb'"
        :custom-icon="icon"
        :type="'upc-regular'"
        :icon="'icon-arrow-down-to-line-filled'"
        :content="'Import'"
        @delete-image="func"
        @download-image="func1"
        @file-data="imgDataPrimary"
      /> -->
      <hlx-file-upload
            :key="updateFileUploadKey"
        :file-type="'.xlsx,.png,.json'"
        :file-size-limit="'20mb'"
        :custom-icon="icon"
        :type="'regular'"
        @file-data="imgDataPrimary"
        @delete-image="funcdelete"
        @download-image="func1"
        @native-data= "newEmit"
        @singleuploded-files="newEmit2"
        @uploded-files="newEmit3"
      />
          </div>
        </template>
        <template #footer>
          <hlx-button
            class="secondary-success sm"
            @click="closeImportFileModal"
            >Cancel</hlx-button
          >
          <hlx-button
            class="primary sm deletedialogok"
            @click="importdatamodal1"
            >Import</hlx-button
          >
        </template>
      </hlx-modal>
    </section>
  </div>
</template>

<script>
import { MLTAxiosInstance, DMNAxiosInstance } from "@/config/axiosConfig";

export default {
  emits: ["change"],
  data() {
    return {
      languages:[
    { code: "ab", name: "Abkhazian" },
    { code: "ace", name: "Achinese" },
    { code: "ach", name: "Acoli" },
    { code: "af", name: "Afrikaans" },
    { code: "ak", name: "Akan" },
    { code: "alz", name: "Alur" },
    { code: "am", name: "Amharic" },
    { code: "ar", name: "Arabic" },
    { code: "as", name: "Assamese" },
    { code: "awa", name: "Awadhi" },
    { code: "ay", name: "Aymara" },
    { code: "az", name: "Azerbaijani" },
    { code: "ba", name: "Bashkir" },
    { code: "ban", name: "Balinese" },
    { code: "bbc", name: "Batak Toba" },
    { code: "be", name: "Belarusian" },
    { code: "bem", name: "Bemba" },
    { code: "bew", name: "Betawi" },
    { code: "bg", name: "Bulgarian" },
    { code: "bho", name: "Bhojpuri" },
    { code: "bik", name: "Bikol" },
    { code: "bm", name: "Bambara" },
    { code: "bn", name: "Bengali" },
    { code: "br", name: "Breton" },
    { code: "bs", name: "Bosnian" },
    { code: "bts", name: "Batak Simalungun" },
    { code: "btx", name: "Batak Karo" },
    { code: "bua", name: "Buryat" },
    { code: "ca", name: "Catalan" },
    { code: "ceb", name: "Cebuano" },
    { code: "cgg", name: "Chiga" },
    { code: "chm", name: "Mari" },
    { code: "ckb", name: "Sorani Kurdish" },
    { code: "cnh", name: "Chin" },
    { code: "co", name: "Corsican" },
    { code: "crh", name: "Crimean Tatar" },
    { code: "crs", name: "Seselwa Creole French" },
    { code: "cs", name: "Czech" },
    { code: "cv", name: "Chuvash" },
    { code: "cy", name: "Welsh" },
    { code: "da", name: "Danish" },
    { code: "de", name: "German" },
    { code: "din", name: "Dinka" },
    { code: "doi", name: "Dogri" },
    { code: "dov", name: "Dombe" },
    { code: "dv", name: "Dhivehi" },
    { code: "dz", name: "Dzongkha" },
    { code: "ee", name: "Ewe" },
    { code: "el", name: "Greek" },
    { code: "en", name: "English" },
    { code: "eo", name: "Esperanto" },
    { code: "es", name: "Spanish" },
    { code: "et", name: "Estonian" },
    { code: "eu", name: "Basque" },
    { code: "fa", name: "Persian" },
    { code: "ff", name: "Fula" },
    { code: "fi", name: "Finnish" },
    { code: "fj", name: "Fijian" },
    { code: "fr", name: "French" },
    { code: "fy", name: "Western Frisian" },
    { code: "ga", name: "Irish" },
    { code: "gaa", name: "Ga" },
    { code: "gd", name: "Scottish Gaelic" },
    { code: "gl", name: "Galician" },
    { code: "gn", name: "Guarani" },
    { code: "gom", name: "Konkani" },
    { code: "gu", name: "Gujarati" },
    { code: "ha", name: "Hausa" },
    { code: "haw", name: "Hawaiian" },
    { code: "he", name: "Hebrew" },
    { code: "hi", name: "Hindi" },
    { code: "hil", name: "Hiligaynon" },
    { code: "hmn", name: "Hmong" },
    { code: "hr", name: "Croatian" },
    { code: "hrx", name: "Hunsrik" },
    { code: "ht", name: "Haitian Creole" },
    { code: "hu", name: "Hungarian" },
    { code: "hy", name: "Armenian" },
    { code: "id", name: "Indonesian" },
    { code: "ig", name: "Igbo" },
    { code: "ilo", name: "Ilocano" },
    { code: "is", name: "Icelandic" },
    { code: "it", name: "Italian" },
    { code: "iw", name: "Hebrew (traditional code 'iw')" },
    { code: "ja", name: "Japanese" },
    { code: "jv", name: "Javanese" },
    { code: "jw", name: "Javanese (different script)" },
    { code: "ka", name: "Georgian" },
    { code: "kk", name: "Kazakh" },
    { code: "km", name: "Khmer" },
    { code: "kn", name: "Kannada" },
    { code: "ko", name: "Korean" },
    { code: "kri", name: "Krio" },
    { code: "ktu", name: "Kanuri" },
    { code: "ku", name: "Kurdish" },
    { code: "ky", name: "Kyrgyz" },
    { code: "la", name: "Latin" },
    { code: "lb", name: "Luxembourgish" },
    { code: "lg", name: "Luganda" },
    { code: "li", name: "Limburgish" },
    { code: "lij", name: "Ligurian" },
    { code: "lmo", name: "Lombard" },
    { code: "ln", name: "Lingala" },
    { code: "lo", name: "Lao" },
    { code: "lt", name: "Lithuanian" },
    { code: "ltg", name: "Latgalian" },
    { code: "luo", name: "Luo" },
    { code: "lus", name: "Mizo" },
    { code: "lv", name: "Latvian" },
    { code: "mai", name: "Maithili" },
    { code: "mak", name: "Makassarese" },
    { code: "mg", name: "Malagasy" },
    { code: "mi", name: "Maori" },
    { code: "min", name: "Minangkabau" },
    { code: "mk", name: "Macedonian" },
    { code: "ml", name: "Malayalam" },
    { code: "mn", name: "Mongolian" },
    { code: "mni-Mtei", name: "Manipuri (Meitei Mayek)" },
    { code: "mr", name: "Marathi" },
    { code: "ms", name: "Malay" },
    { code: "ms-Arab", name: "Malay (Arabic script)" },
    { code: "mt", name: "Maltese" },
    { code: "my", name: "Burmese" },
    { code: "ne", name: "Nepali" },
    { code: "new", name: "Newari" },
    { code: "nl", name: "Dutch" },
    { code: "no", name: "Norwegian" },
    { code: "nr", name: "Ndebele" },
    { code: "nso", name: "Northern Sotho" },
    { code: "nus", name: "Nuer" },
    { code: "ny", name: "Nyanja" },
    { code: "oc", name: "Occitan" },
    { code: "om", name: "Oromo" },
    { code: "or", name: "Odia" },
    { code: "pa", name: "Punjabi" },
    { code: "pa-Arab", name: "Punjabi (Arabic script)" },
    { code: "pag", name: "Pangasinan" },
    { code: "pam", name: "Pampangan" },
    { code: "pap", name: "Papiamento" },
    { code: "pl", name: "Polish" },
    { code: "ps", name: "Pashto" },
    { code: "pt", name: "Portuguese" },
    { code: "qu", name: "Quechua" },
    { code: "rn", name: "Rundi" },
    { code: "ro", name: "Romanian" },
    { code: "rom", name: "Romani" },
    { code: "ru", name: "Russian" },
    { code: "rw", name: "Kinyarwanda" },
    { code: "sa", name: "Sanskrit" },
    { code: "sc", name: "Sicilian" },
    { code: "sd", name: "Sindhi" },
    { code: "sg", name: "Sango" },
    { code: "sh", name: "Shan" },
    { code: "si", name: "Sinhala" },
    { code: "sk", name: "Slovak" },
    { code: "sl", name: "Slovenian" },
    { code: "sm", name: "Samoan" },
    { code: "sn", name: "Shona" },
    { code: "so", name: "Somali" },
    { code: "sq", name: "Albanian" },
    { code: "sr", name: "Serbian" },
    { code: "ss", name: "Swati" },
    { code: "st", name: "Southern Sotho" },
    { code: "su", name: "Sundanese" },
    { code: "sv", name: "Swedish" },
    { code: "sw", name: "Swahili" },
    { code: "szl", name: "Silesian" },
    { code: "ta", name: "Tamil" },
    { code: "te", name: "Telugu" },
    { code: "tet", name: "Tetum" },
    { code: "tg", name: "Tajik" },
    { code: "th", name: "Thai" },
    { code: "ti", name: "Tigrinya" },
    { code: "tk", name: "Turkmen" },
    { code: "tl", name: "Tagalog" },
    { code: "tn", name: "Tswana" },
    { code: "tr", name: "Turkish" },
    { code: "ts", name: "Tsonga" },
    { code: "tt", name: "Tatar" },
    { code: "ug", name: "Uyghur" },
    { code: "uk", name: "Ukrainian" },
    { code: "ur", name: "Urdu" },
    { code: "uz", name: "Uzbek" },
    { code: "vi", name: "Vietnamese" },
    { code: "xh", name: "Xhosa" },
    { code: "yi", name: "Yiddish" },
    { code: "yo", name: "Yoruba" },
    { code: "yua", name: "Yucatec Maya" },
    { code: "yue", name: "Yue Chinese (Cantonese)" },
    { code: "zh", name: "Chinese (generic)" },
    { code: "zh-Hans", name: "Chinese (Simplified)" },
    { code: "zh-Hant", name: "Chinese (Traditional)" },
    { code: "zu", name: "Zulu" }
],
      updateFileUploadKey:0,
      backgrounStyleupload: {
        backgroundColor: "",
        bacgroundtTextColor: "#A8A8A8",
        border: false,
        borderColor: "#A8A8A8",
        TextfontSize: 34,
      },
      totalPageNo: 0,
      collectionDataManagementquery: {
        type: "filter",
        module: "referencedata",
        collection: "",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      },
      externalRowsPerPage: 10,
      duplicatettheads: [],
      nestedcount: 0,
      parentdata: "",
      duplicateTheads: [],
      data12: "",
      token: this.$route.params.token,
      editMode: true,
      importDataModal: false,
      editPermission: [],
      viewPermission: [],
      formData: {},
      nestedshow: false,
      searchtabledata: [],
      options3: [
        { name: "True", value: true, checked: false, disabled: false, id: 1 },
        { name: "False", value: false, checked: false, disabled: false, id: 2 },
      ],
      // addeditdatapagespinner:true,
      // addeditpagetable:false,
      deletename: "",
      duplicate_table_data: [],
      searchvalue: "",
      unique: Math.floor(Math.random() * 1000 + 1),
      partially_checked: false,
      originaldata: {},
      referencedataid: "",
      adddataname: "",
      adddatadescription: "",
      addnotification: false,
      editnotification: false,
      deletenotification: false,
      type: "Success",
      deletedialog: false,
      referencedataname: "",
      items: [{ label: "Data management" }],
      theads: [],
      tableData: [],
      currPage: 1,
      rowsPerPage: 10,
      adddataobj: {},
      editdataobj: {},
      modal_active: false,
      modal_active1: false,
      editmodaldata: {},
      editmodalindex: "",
      deletedataindex: "",
      bulkdeletebutton: false,
      success: false,
      success_msg: "",
      notification: {},
      id1: "tenant1",
      name1: "myself",
      thead: [],
      theads1: [],
      importdata: [],
      errorvalidation: {},
      re_arrange: false,
    };
  },
  computed: {
    paginatedData() {
      // const start = (this.currPage - 1) * this.rowsPerPage;
      // const end = start + this.rowsPerPage;
      return this.tableData;
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.tableData.length;
    },
  },
  async mounted() {
    this.nestedshow = true;
    const val = await MLTAxiosInstance.get(`partymanagement/individual/auth`);
    this.company = val.data.company;
    this.userId = val.data.company;
    this.referencedataname = this.$route.params.schema;
    this.collectionDataManagementquery.collection = this.referencedataname;
    this.parentdata = this.$route.params.schema;

    console.log(this.referencedataname, "darraa");
    let schemaRes = await DMNAxiosInstance.get(
      `mapRef/getbyname/${this.referencedataname}`
    );
    console.log(schemaRes, "data");

    if (schemaRes.data.ok == 0) {
      this.theads = [];
    } else {
      // console.log(Object.keys(schemaRes.data.data),'fl');
      this.theads = Object.keys(schemaRes.data.data).map(async (e) => {
        //
        // console.log(schemaRes.data.data[e]);
        let obj = {};
        obj.name = e;
        obj.prop = e;
        obj.label = e;
        obj.required = schemaRes.data?.data?.[e]?.required;
        obj.type = schemaRes.data?.data?.[e]?.type;
        if (
          schemaRes.data.data[e]?.type === "Array" ||
          schemaRes.data.data[e]?.type === "Object"
        ) {
          if (schemaRes.data.data[e].refConfig != undefined) {
            obj.ref = schemaRes.data.data[e].refConfig.name;
            if (schemaRes.data.data[e].refConfig.fields?.length >= 0) { // !=0 => >0
              console.log(schemaRes.data.data[e].refConfig.fields?.length,"length",schemaRes.data.data[e].refConfig.fields,"refshowoptions",schemaRes.data.data[e].refConfig)
              obj.refshowoptions = schemaRes.data.data[e].refConfig?.fields[0];
            } else {
              obj.refshowoptions = await this.refoptions(
                schemaRes.data.data[e].refConfig.name
              );
              // console.log('msdoe',this.refoptions(schemaRes.data.data[e].refConfig.name));
              // console.log(optionss,);
            }
          }
        }
        obj.sortable = true;
        obj.checked = true;
        obj.disabled = false;
        obj.align = "left";
        return obj;
      });
      // console.log(await Promise.all(this.theads));
      this.theads = await Promise.all(this.theads);
      this.duplicateTheads = this.theads;
      this.duplicatettheads = this.theads;
      // console.log(this.duplicateTheads,'pop');
    }

    this.theads.map(async (e) => {
      if (e.ref != undefined && e.ref != "") {
        // console.log(e.ref);
        let refdata = await DMNAxiosInstance.get(
          "mapRef/reference_data/" + e.ref
        );
        console.log(refdata, "eksa");
        e.options = refdata.data.data;
        // console.log(typeof(e.refshowoptions),e.refshowoptions);
        if (typeof e.refshowoptions == "object") {
          if (e.options != undefined && e.options.length != 0) {
            let data = e.options[0];
            let data1 = Object.keys(data);
            let data2 = data1[0];
            // console.log(data2,'dafta');
            e.refshowoptions = data2;
          }
        }
      }
    });

    // const data2 = await DMNAxiosInstance.get('mapRef/collection/batproduct')
    // data2
    // console.log(data2,'pop');

    // const access = await MLTAxiosInstance.get(
    //   `partyRole/get/${val.data.userId}`
    // );

    // let arr = access.data;

    // if (arr) {
    //   this.editPermission = [];
    //   if (arr.permission.User_settings.edit == false) {
    //     this.editPermission.push("setting");
    //   }
    //   if (arr.permission.User_settings.view == false) {
    //     this.viewPermission.push("setting");
    //   }
    //   if (arr.permission.Schema.edit == false) {
    //     this.editPermission.push("schema");
    //   }
    //   if (arr.permission.Schema.view == false) {
    //     this.viewPermission.push("Schema");
    //   }
    //   if (arr.permission.Test_data.view == false) {
    //     this.viewPermission.push("Database", "Execute");
    //   }
    //   if (arr.permission.Test_data.edit == false) {
    //     this.editPermission.push("testdata");
    //   }
    //   if (arr.permission.Rules.view == false) {
    //     this.viewPermission.push("Business rule");
    //   }
    //   if (arr.permission.Rules.edit == false) {
    //     this.editPermission.push("Business rule");
    //   }
    // }
    // if (this.editPermission.includes("testdata") == true) {
    //   this.editMode = false;
    // }
    this.headvalue = this.theads;

    this.$store.commit("token", this.$route.params.token);
    //
    //

    // await DMNAxiosInstance.get(
    //   `mapRef/collection/${this.referencedataname}`
    // ).then((res) => {

    //   //   this.originaldata = res.data
    //   this.tableData = res.data.data;
    //   // console.log(this.tableData,'ftghkjb');
    //   this.duplicate_table_data = this.tableData;
    //   this.searchtabledata = this.tableData;
    //   this.tableData.forEach((e) => {
    //     e.checked = false;
    //     e.disabled = false;
    //   });
    //   // console.log(this.tableData,'data');
    //   let obj = {};
    //   obj.label =
    //     this.referencedataname.charAt(0).toUpperCase() +
    //     this.referencedataname.slice(1);
    //   obj.link = '';
    //   this.items.push(obj);
    // });
    this.collectionDataManagementCalling(this.currPage, this.rowsPerPage);
    let obj = {};
    obj.label =
      this.referencedataname?.charAt(0).toUpperCase() +
      this.referencedataname.slice(1);
    obj.link = "";
    this.items.push(obj);
  },
  methods: {
    generateLanguageList(params) {
  const result = Object.entries(params)
    .map(([, name]) => {  // Use an empty slot to ignore the first value
      const language = this.languages.find(
        (lang) => lang.name.toLowerCase() === name.toLowerCase()
      );

      // If language is found, return its code and name
      return language ? { code: language.code, name: language.name } : null;
    })
    .filter((item) => item !== null);

  return result;
},
    func1(val){
      console.log("func1",val)
    },
    async callLangRef(obj) {
      // let responses24 = await MLTAxiosInstance.get(`/settings`);
      let responses24 = await MLTAxiosInstance.get(
      `/partymanagement/organisation/${this.$cookies.get(
        "user"
      )}?company=${this.$cookies.get("company")}`
    );

      let lang = responses24?.data?.languageAbility;
      // let requiredLanguages = this.generateLanguageList(lang);
      let requiredLanguages = lang;

      console.log("changed lang code", requiredLanguages);

    // let requiredLanguages = lang;
    console.log("reqLAng___", requiredLanguages, obj);

    if (Object.prototype.hasOwnProperty.call(obj, "name")) {
        const nameValue = obj["name"];

        // Use map to create an array of promises for translation
        const translationPromises = requiredLanguages.map(async (language) => {
            const langCode = language.code;
            const langName = language.name;
            const translatedValue = await this.languageTransfer(nameValue, langCode);
          console.log("ooooooooooooooooo", translatedValue, langName.toLowerCase(),
          obj[langName.toLowerCase()]);

          // Dynamically set the property based on the language code
          if (!obj[langName?.toLowerCase()]) { 
            obj[langName.toLowerCase()] = translatedValue;
          }            
        });

        // Wait for all translation promises to resolve
        await Promise.all(translationPromises);
    }

    console.log("obj_obj", obj);
    return obj;
}
,

    imgDataPrimary(event){
      // console.log(event,"eventevent");
      // let formData = new FormData();
      // for(let i = 0;i<event.length;i++){
      //   formData.append("files[]", event[i])
      // }
      console.log(  "formData", event );
      console.log( event.data,"formData", event );
    const jsonString = JSON.stringify(event.data, null, 2);
    console.log("eventDataInString",jsonString)
    const asciiArray = Array.from(jsonString).map(char => char.charCodeAt(0));
 
const result = {
  file:[{
  data: {
    type: "Buffer",
    data: asciiArray
  },
  filename:event.properties.name,
  encoding: "7bit",
  mimetype: event.properties.type,
  limit: false
}]
}
console.log(JSON.stringify(result, null, 2));
this.importedFileData = result
    },
    funcdelete(val){
      console.log("funcdelete",val)
    },
    newEmit(event){
      // console.log(event,"eventevent");
      // let formData = new FormData();
      // for(let i = 0;i<event.length;i++){
      //   formData.append("files[]", event[i])
      // }
      console.log(  "formData", event );
    },
    newEmit2(val){
      console.log("newEmit2",val)
    },
    newEmit3(val){
      console.log("newEmit3",val)
    },
    closeImportFileModal(){
      this.updateFileUploadKey+=1
      this.importDataModal = false
    },
    async collectionDataManagementCalling(CurrentPage, rowsPerPage) {
      this.collectionDataManagementquery["paginatedQuery"]["currentPage"] =
        CurrentPage;
      this.collectionDataManagementquery["paginatedQuery"]["rowsPerPage"] =
        rowsPerPage;
      this.collectionDataManagementquery["company"] = this.company;
      let filterResultData = await DMNAxiosInstance.post(
        `util/filterData`,
        this.collectionDataManagementquery
      );
      if (filterResultData.data.ok == 1) {
        filterResultData = filterResultData.data;
        console.log(
          "🚀 ~ collectionDataManagementCalling ~ filterResultData:",
          filterResultData
        );
        this.totalPageNo = filterResultData.data.total;
        this.tableData = filterResultData.data.data;
        this.duplicate_table_data = this.tableData;
      }
    },

    async nesteddata(data, data1, data2, index, add) {
      // console.log(data,'type',data1,'ref',data2,'kd',this.duplicate_table_data);
      let obje1 = {};
      obje1.label = data2;
      obje1.type = data;
      obje1.ref = data1;
      obje1.index = index;
      this.items.push(obje1);
      // console.log(this.parentdata,data1,'fkd');

      if (this.parentdata == data1) {
        this.nestedshow = true;
        this.referencedataname = this.$route.params.schema;
        this.parentdata = this.$route.params.schema;
        let schemaRes = await DMNAxiosInstance.get(
          `mapRef/getbyname/${this.referencedataname}`
        );
        // console.log(schemaRes,'data');

        if (schemaRes.data.ok == 0) {
          this.theads = [];
        } else {
          // console.log(Object.keys(schemaRes.data.data),'fl');
          this.theads = Object.keys(schemaRes.data.data).map(async (e) => {
            //
            // console.log(schemaRes.data.data[e]);
            let obj = {};
            obj.name = e;
            obj.prop = e;
            obj.label = e;
            obj.required = schemaRes.data.data[e].required;
            obj.type = schemaRes.data.data[e]?.type;
            if (
              schemaRes.data.data[e]?.type === "Array" ||
              schemaRes.data.data[e]?.type === "Object"
            ) {
              if (schemaRes.data.data[e].refConfig != undefined) {
                obj.ref = schemaRes.data.data[e].refConfig.name;
                if (schemaRes.data.data[e].refConfig.fields.length != 0) {
                  obj.refshowoptions =
                    schemaRes.data.data[e].refConfig?.fields[0];
                } else {
                  obj.refshowoptions = await this.refoptions(
                    schemaRes.data.data[e].refConfig.name
                  );
                  // console.log('msdoe',this.refoptions(schemaRes.data.data[e].refConfig.name));
                  // console.log(optionss,);
                }
              }
            }
            obj.sortable = true;
            obj.checked = true;
            obj.disabled = false;
            obj.align = "left";
            return obj;
          });
          // console.log(await Promise.all(this.theads));
          this.theads = await Promise.all(this.theads);
          this.duplicateTheads = this.theads;
          // console.log(this.duplicateTheads,'pop');
        }

        this.theads.map(async (e) => {
          if (e.ref != undefined && e.ref != "") {
            // console.log(e.ref);
            let refdata = await DMNAxiosInstance.get(
              "mapRef/reference_data/" + e.ref
            );
            // console.log(refdata,'eksa');
            e.options = refdata.data.data;
            // console.log(typeof(e.refshowoptions),e.refshowoptions);
            if (typeof e.refshowoptions == "object") {
              if (e.options != undefined && e.options.length != 0) {
                let data = e.options[0];
                let data1 = Object.keys(data);
                let data2 = data1[0];
                // console.log(data2,'dafta');
                e.refshowoptions = data2;
              }
            }
          }
        });

        await DMNAxiosInstance.get(
          `mapRef/collection/${this.referencedataname}`
        ).then((res) => {
          //   this.originaldata = res.data
          this.tableData = res.data.data;
          // console.log(this.tableData,'ftghkjb');
          this.duplicate_table_data = this.tableData;
          this.searchtabledata = this.tableData;
          this.tableData.forEach((e) => {
            e.checked = false;
            e.disabled = false;
          });
          // console.log(this.tableData,'data');
        });
      } else {
        this.nestedshow = false;
        // console.log(data,data1);
        this.referencedataname = data2;
        let schemaRes = await DMNAxiosInstance.get(`mapRef/getbyname/${data1}`);
        if (schemaRes.data.ok == 0) {
          this.theads = [];
        } else {
          this.theads = Object.keys(schemaRes.data.data).map(async (e) => {
            let obj = {};
            obj.name = e;
            obj.prop = e;
            obj.label = e;
            obj.required = schemaRes.data.data[e].required;
            obj.type = schemaRes.data.data[e]?.type;
            if (
              schemaRes.data.data[e]?.type === "Array" ||
              schemaRes.data.data[e]?.type === "Object"
            ) {
              if (schemaRes.data.data[e].refConfig != undefined) {
                obj.ref = schemaRes.data.data[e].refConfig.name;
                if (schemaRes.data.data[e].refConfig.fields.length != 0) {
                  obj.refshowoptions =
                    schemaRes.data.data[e].refConfig.fields[0];
                } else {
                  obj.refshowoptions = await this.refoptions(
                    schemaRes.data.data[e].refConfig.name
                  );
                }
              }
            }
            obj.sortable = true;
            obj.checked = true;
            obj.disabled = false;
            obj.align = "left";
            return obj;
          });
          // console.log(await Promise.all(this.theads));
          this.theads = await Promise.all(this.theads);
          this.duplicateTheads = this.theads;
          // console.log(this.duplicateTheads,'pop');
        }

        this.theads.map(async (e) => {
          if (e.ref != undefined && e.ref != "") {
            let refdata = await DMNAxiosInstance.get(
              "mapRef/reference_data/" + e.ref
            );
            // console.log(refdata,'eksa');
            e.options = refdata.data.data;
            // console.log(typeof(e.refshowoptions),e.refshowoptions);
            if (typeof e.refshowoptions == "object") {
              if (e.options != undefined && e.options.length != 0) {
                let data = e.options[0];
                let data1 = Object.keys(data);
                let data2 = data1[0];
                // console.log(data2,'dafta');
                e.refshowoptions = data2;
              }
            }
          }
        });
        if (add == true) {
          // console.log(this.tableData[index][data2][0]);
          this.nestedcount = this.nestedcount + 1;
          this.tableData = this.tableData[index][data2][0];
        } else {
          this.nestedcount = this.nestedcount - 1;
          let nesteddata1 = [];
          if (this.nestedcount != 0) {
            this.duplicate_table_data.forEach((e, dataindex) => {
              this.items.forEach((ele, breadcrumbindex) => {
                if (breadcrumbindex != 0) {
                  if (dataindex == ele.index) {
                    nesteddata1 = e[ele.label][0];
                  }
                }
              });
            });
            this.tableData = nesteddata1;
          } else {
            this.theads = this.duplicatettheads;
            this.duplicateTheads = this.duplicatettheads;
            this.tableData = this.duplicate_table_data;
          }
        }
        // this.searchtabledata = this.tableData;
        // this.tableData.forEach((e) => {
        //   e.checked = false;
        //   e.disabled = false;
        // });
      }
    },
    async refoptions(val) {
      let res = await DMNAxiosInstance.get(`mapRef/getbyname/${val}`);
      // console.log(res,'data');
      let data = res.data.data;
      let count = 0;
      let data1 = "";
      Object.keys(data).forEach((e) => {
        // console.log(data[e].type);
        if (data[e].type == "String" || data[e].type == "Number") {
          if (count == 0) {
            count = count + 1;
            data1 = e;
          }
        }
      });
      if (count == 0) {
        // console.log('ks');
        data1 = "_id";
      }
      // console.log(data1);
      return data1;
    },
    checkadddata($event, data) {
      this.adddataobj[data.prop] = $event.name;
    },
    selectedObject($event, prop) {
      let obj = {};
      obj = JSON.parse(JSON.stringify($event));
      delete obj["checked"];
      delete obj["disabled"];
      this.adddataobj[prop] = obj;
      //  console.log(this.adddataobj,'laola');
    },
    selectedObject1($event, prop) {
      console.log("🚀 ~ selectedObject1 ~ $event, prop:", $event, prop);
      console.log($event, "ks");
      let obj = {};
      obj = JSON.parse(JSON.stringify($event));
      delete obj["checked"];
      delete obj["disabled"];
      this.editdataobj[prop] = obj;
      //  console.log(this.adddataobj,'laola');
    },
    selectedvalue($event, prop, value) {
      // console.log($event,'aaa');
      value;
      let arr1 = JSON.parse(JSON.stringify([$event]));
      // let arr = []
      arr1.forEach((e) => {
        delete e["checked"];
        delete e["disabled"];
      });
      //  console.log(arr1,'aaae1qw');
      this.adddataobj[prop] = arr1;
      //  console.log(this.adddataobj,'aaa');
    },
    selectedvalue1($event, prop, value) {
      console.log(
        "🚀 ~ selectedvalue1 ~ event, prop, value:",
        $event,
        prop,
        value
      );
      // let arr = [];
      value;
      // $event.forEach((e) => {
      //   arr.push(e[value]);
      // });
      let arr1 = JSON.parse(JSON.stringify($event));
      // let arr = []
      arr1.forEach((e) => {
        delete e["checked"];
        delete e["disabled"];
      });
      this.editdataobj[prop] = arr1;

      //  console.log(this.adddataobj,'data222');
    },
    applyColumns(val) {
      // //
      // this.headvalue = [];
      this.duplicateTheads = [];
      val.forEach((e) => {
        if (e.checked === true) {
          // this.headvalue.push(e);
          this.duplicateTheads.push(e);
        }
      });
      this.updateTheads();
    },
    updateTheads() {
      // Use the map function to update the 'checked' property
      this.theads = this.theads.map((thead) => {
        // Check if the current thead has a matching 'prop' in duplicateTheads
        const isMatching = this.duplicateTheads.some(
          (duplicateThead) => duplicateThead.prop === thead.prop
        );

        // Set the 'checked' property based on whether it's matching
        return { ...thead, checked: isMatching };
      });
    },
    arrange(val) {
      this.updateTheads();

      // //
      this.re_arrange = val;
    },
    // booleanvalue(val) {
    //
    // },
    // async uploadFile(event) {
    //   // log
    //   event.preventDefault();
    //   const file = this.$refs.fileInput.files[0];
    //   const formData = new FormData();
    //   formData.append("file", file);
    //
    //   await axios.post(`http://localhost:5000/upload`, formData).then((res) => {
    //
    //     // this.tableData.push(res.data.data)
    //   });
    // },
    async abc(val) {
      // let filereader = new FileReader();
      if (val.properties.type === "text/csv") {
        const formData = new FormData();
        formData.append("file", val.properties);

        this.formData = formData;

        // filereader.readAsText(val.properties);
        // filereader.onload = async (e) => {
        //   const content = e.target.result;
        //   const jsonData = await csv().fromString(content);

        //   // Use the converted JSON data as needed
        //
        // };
      } else if (val.properties.type === "application/json") {
        const formData = new FormData();
        formData.append("file", val.properties);

        this.formData = formData;
      } else {
        //           const formData = new FormData();
        // formData.append("name", val.properties);
        //
        const formData = new FormData();
        formData.append("file", val.properties);

        //                await axios
        // .post(`http://localhost:5000/upload`,formData).then((res)=>{
        //
        //     // this.tableData.push(res.data.data)
        //   })

        // filereader.readAsBinaryString(val.properties);
        // this.importdata = [];
        // filereader.onload = (event) => {
        //   let data = event.target.result;
        //   let workbook = read(data, { type: "binary" });
        //   //
        //   workbook.SheetNames.forEach((sheet) => {
        //     let rowObject = XLSX.utils.sheet_to_row_object_array(
        //       workbook.Sheets[sheet]
        //     );
        //     rowObject.forEach((e) => {
        //       this.importdata.push(e);
        //     });

        //
        //   });

        //
        // };
        this.importbutton = true;
        // setTimeout(() => {
        //
        // }, 250);
        this.importDataModal = true;
        this.formData = formData;
      }
    },
    async importdatamodal1() {
      await DMNAxiosInstance.post(
        `mapRef/upload/${this.$route.params.schema}`,
        this.formData
      ).then((res) => {
        this.importdata = res.data;
        if (res.data.ok == 1) {
          this.tableData = [...this.tableData, ...(res.data.data || [])];
        }
      });
//       let response = await DMNAxiosInstance.post(`mapRef/import/${this.$route.params.schema}`,
//         this.importedFileData
//       )
          
// console.log("response",response)
      this.importDataModal = false;
      this.updateFileUploadKey+=1
      // this.tableData.push(res.data.data)
      // }); await DMNAxiosInstance.post(`util/upload`, formData).then((res) => {
      //   this.importdata = res.data;
      //
      // });
      // await DMNAxiosInstance.post(
      //   `mapRef/addMany/${this.referencedataname}`,
      //   this.importdata
      // ).then((res) => {
      //
      //   // this.tableData.push(res.data.data)
      // });
      // await DMNAxiosInstance.get(
      //   `mapRef/collection/${this.referencedataname}`
      // ).then((res) => {
      //
      //   //   this.originaldata = res.data
      //   this.tableData = res.data.data;
      // });
    },
    //   applyColumns (val) {
    //   this.headvalue = []
    //   val.forEach((e) => {
    //     if (e.checked === true) {
    //       this.headvalue.push(e)
    //     }
    //   })
    // },
    // arrange (val) {
    //   //
    //   this.re_arrange = val
    // },
    path(val) {
      //
      console.log("valPath",val)
      if (val.toLowerCase() === "back") {
        this.$router.push(`/reference_data`);
      } else {
        this.items.forEach((e, index) => {
          if (e.label == val.label) {
            let label = this.items[index].label;
            let type = this.items[index].type;
            let ref = this.items[index].ref;
            let index1 = this.items[index].index;
            this.nesteddata(type, ref, label, index1, false);
            this.items.splice(index + 1);
          }
        });
      }
      //  this.$router.push({ path: `/DMN/reference_data/` })

      //  window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/reference_data `;
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    changeRowsPerPage(count) {
      if (this.company != "") {
        this.rowsPerPage = count;
        this.collectionDataManagementCalling(this.currPage, this.rowsPerPage);
      }
    },
    currentPage(val) {
      if (this.company != "") {
        this.currPage = val;
        this.collectionDataManagementCalling(this.currPage, this.rowsPerPage);
      }
    },
    adddata() {
      console.log('aklaaaad',this.adddataobj, this.theads);
      this.adddataobj = JSON.parse(JSON.stringify(this.adddataobj));
      this.adddataobj = {};
      this.errorvalidation = {};
      this.theads.forEach((e) => {
        if (["Boolean"].includes(e.type) == false) {
          this.adddataobj[e.prop] = "";
          this.errorvalidation[e.prop] = false;
        }
        // else{
        //   this.adddataobj[e.prop] = false;
        //   this.errorvalidation[e.prop] = false
        // }
      });
      console.log(this.adddataobj,'lki');
      this.modal_active = true;
    },
    async saveadddata() {
      console.log(JSON.stringify(this.adddataobj), "data1",this.adddataobj);
      // var count = 0
      // var count1 = 0
      // this.theads.forEach((e) => {
      //   if (e.required == true) {
      //     count1 = count1 + 1
      //   }
      // })
      let isAllPass = true;
      for (let i = 0; i < this.theads.length; i++) {
        //
        console.log("🚀 ~ saveadddata ~ this.theads[i]:", this.theads[i]);
        console.log(
          "🚀 ~ saveadddata ~ this.adddataobj[this.theads[i].prop]:",
          this.adddataobj[this.theads[i].prop]
        );
        if (
          this.theads[i].required == true &&
          this.theads[i].type != "Boolean"
        ) {
          if (
            this.adddataobj[this.theads[i].prop] == undefined ||
            this.adddataobj[this.theads[i].prop] == ""
          ) {
            isAllPass = false;
            console.log("🚀 ~ saveadddata ~ isAllPass:", isAllPass);
            this.errorvalidation[this.theads[i].prop] = true;
          } else {
            this.errorvalidation[this.theads[i].prop] = false;
          }
        } else if (
          this.theads[i].required == true &&
          this.theads[i].type == "Boolean"
        ) {
          console.log(
            "🚀 ~ saveadddata ~ this.adddataobj[this.theads[i].prop]: boolean",
            this.adddataobj[this.theads[i].prop]
          );
          if (this.adddataobj[this.theads[i].prop] == undefined) {
            isAllPass = false;
            this.errorvalidation[this.theads[i].prop] = true;
          } else {
            // this.adddataobj[this.theads[i].prop] = new Date(
            //   this.adddataobj[this.theads[i].prop]
            // ).toISOString();

            this.errorvalidation[this.theads[i].prop] = false;
          }
        }
        if (this.theads[i].prop == "Date") {
          this.adddataobj[this.theads[i].prop] = new Date(
            this.adddataobj[this.theads[i].prop]
          ).toISOString();
        }
      }

      if (isAllPass) {
        console.log("0987654321", JSON.parse(JSON.stringify(this.adddataobj)));
        let rem = JSON.parse(JSON.stringify(this.adddataobj));
        let realRef = await this.callLangRef(rem);
        console.log("ttttttttttt", realRef);
        
        await DMNAxiosInstance.post(
          `mapRef/${this.referencedataname}`,
          realRef
        );
        console.log(" this.adddataobj", this.adddataobj)
        this.collectionDataManagementCalling(this.currPage, this.rowsPerPage);
        this.duplicate_table_data = this.tableData;
        this.modal_active = false;

        this.theads.forEach((e) => {
          //         if(e.type === 'Array')
          // {
          //   console.log(e);
          //   e.options.forEach((ele)=>{
          //     ele.checked = false
          //   })
          // }
          if (["Boolean"].includes(e.type) == false) {
            delete this.adddataobj[e.prop];
            // this.adddataobj[e.prop] = ''
          }
        });
        //
        // this.adddataobj = {}
        this.addnotification = true;
        this.modal_active = false;
      }
    },
    editdata(data, index) {
      console.log(data);
      this.referencedataid = this.tableData[index]._id;
      // this.editdataobj = {}
      // this.editdataobj = JSON.parse(JSON.stringify(this.editdataobj))
      //
      this.theads.forEach((e) => {
        if (
          e.type == "String" ||
          e.type == "Number" ||
          e.type == "Date" ||
          e.type == "Boolean" ||
          e.type == "Object"
        ) {
          this.editdataobj[e.prop] = data[e.prop];
          // this.editdataobj = data
          // this.editmodaldata = data
        } else if (e.type == "Array") {
          console.log("🚀 ~ arr1 ~ data[e.prop]:", data[e.prop]);
          let arr1 = data[e.prop]?.map((i) => {
            return i[0];
          });
          this.editdataobj[e.prop] = arr1;
          // this.editdataobj = data
          // this.editmodaldata = data
        }
        // else if(e.type ==)
      });
      // console.log(this.editdataobj,'edit');
      //
      this.modal_active1 = true;
      this.editmodalindex = index;
    },
    async saveeditdata() {
      let isAllPass = true;
      for (let i = 0; i < this.theads.length; i++) {
        //
        console.log("🚀 ~ saveadddata ~ this.theads[i]:", this.theads[i]);
        console.log(
          "🚀 ~ saveadddata ~ this.adddataobj[this.theads[i].prop]:",
          this.editdataobj[this.theads[i].prop]
        );
        if (
          this.theads[i].required == true &&
          this.theads[i].type != "Boolean"
        ) {
          if (
            this.editdataobj[this.theads[i].prop] == undefined ||
            this.editdataobj[this.theads[i].prop] == ""
          ) {
            isAllPass = false;
            console.log("🚀 ~ saveadddata ~ isAllPass:", isAllPass);
            this.errorvalidation[this.theads[i].prop] = true;
          } else {
            this.errorvalidation[this.theads[i].prop] = false;
          }
        } else if (
          this.theads[i].required == true &&
          this.theads[i].type == "Boolean"
        ) {
          console.log(
            "🚀 ~ saveadddata ~ this.editdataobj[this.theads[i].prop]: boolean",
            this.editdataobj[this.theads[i].prop]
          );
          if (this.editdataobj[this.theads[i].prop] == undefined) {
            isAllPass = false;
            this.errorvalidation[this.theads[i].prop] = true;
          } else {
            this.errorvalidation[this.theads[i].prop] = false;
          }
        }
        if (this.theads[i].prop == "Date") {
          this.editdataobj[this.theads[i].prop] = new Date(
            this.editdataobj[this.theads[i].prop]
          ).toISOString();
        }
      }
      if (!isAllPass) return;
    let patchReferenceData = await DMNAxiosInstance.patch(
        `mapRef/updateCollection/${this.referencedataname}/${this.referencedataid}`,
        this.editdataobj
      );
      if(patchReferenceData){
        
      this.notification = { type: 'success', message: "Data edited successfully" };
      }
      this.collectionDataManagementCalling(this.currPage, this.rowsPerPage);
      this.modal_active1 = false;
      this.duplicate_table_data = this.tableData;
      this.searchtabledata = this.tableData;
      this.editnotification = true;
    },
    editclose() {
      this.modal_active1 = false;
      this.editdataobj = {};
      this.editmodaldata = {};
    },
    deletedata(index) {
      this.deletedataindex = index;
      this.deletename = this.tableData[index].category;
      this.referencedataid = this.tableData[index]._id;
      this.deletedialog = true;
    },
    async deleteokmodal() {
      await DMNAxiosInstance.delete(
        `mapRef/remove/${this.referencedataname}/${this.referencedataid}`
      );
      this.collectionDataManagementCalling(this.currPage, this.rowsPerPage);
      this.modal_active1 = false;

      this.duplicate_table_data = this.tableData;
      this.deletenotification = true;
      this.deletedialog = false;
      this.success = true;
      this.success_msg = "Data deleted successfully";
      this.notification = { type: "success", message: this.success_msg };
    },
    selectAll($event) {
      if (this.bulkdeletebutton === true) {
        this.bulkdeletebutton = false;
      } else {
        this.bulkdeletebutton = true;
      }
      this.select_all = $event.target.checked;
      if (this.select_all === true) {
        this.partially_checked = false;
        document
          .querySelectorAll("[id^=check-drag" + this.unique + "]")
          .forEach((ele) => {
            if (ele.disabled === false) {
              ele.checked = true;
            }
          });
        this.tableData.forEach((ele) => {
          if (ele.disabled === false) {
            ele.checked = true;
          }
        });

        document
          .getElementById("select-all-checkmark" + this.unique)
          .classList.remove("grey");
        document
          .getElementById("select-all-checkmark" + this.unique)
          .classList.add("theme");
      } else {
        this.partially_checked = false;
        document
          .querySelectorAll("[id^=check-drag" + this.unique + "]")
          .forEach((ele) => {
            if (ele.disabled === false) {
              ele.checked = false;
            }
            if (ele.checked === true) {
              this.partially_checked = true;
              document
                .getElementById("select-all-checkmark" + this.unique)
                .classList.add("theme");
            }
          });
        this.tableData.forEach((ele) => {
          if (ele.disabled === false) {
            ele.checked = false;
          }
        });
        if (this.partially_checked === false) {
          //
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.remove("theme");
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.add("grey");
        } else if (this.partially_checked === true) {
          //
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.remove("grey");
          document
            .getElementById("select-all-checkmark" + this.unique)
            .classList.add("theme");
        }
      }
      this.$emit("change", this.tableData);
    },
    checkItem(val, $event) {
      this.atleastOne = false;
      this.all_checked = true;
      //
      val.checked = $event.target.checked;
      if ($event.target.checked === false) {
        document.querySelector(".select-all" + this.unique).checked = false;
      }
      // this.$emit('change', this.list)
      //
      let count = 0;
      this.tableData.forEach((ele) => {
        if (ele.checked === false) {
          this.all_checked = false;
        }
        if (ele.checked === true) {
          this.atleastOne = true;
          count++;
        }
      });

      if (count > 0) {
        this.bulkdeletebutton = true;
      } else {
        this.bulkdeletebutton = false;
      }
      if (this.all_checked === true) {
        document.querySelector(".select-all" + this.unique).checked = true;
        document
          .getElementById("select-all-checkmark" + this.unique)
          .classList.add("theme");
      } else if (
        document.getElementById("select-all-checkmark" + this.unique)
      ) {
        this.partially_checked = false;
        document
          .getElementById("select-all-checkmark" + this.unique)
          .classList.add("grey");
      }
      if (this.atleastOne === true && this.all_checked === false) {
        this.partially_checked = true;
        document
          .getElementById("select-all-checkmark" + this.unique)
          .classList.remove("grey");
        document
          .getElementById("select-all-checkmark" + this.unique)
          .classList.add("theme");
      } else if (this.all_checked === true) {
        this.partially_checked = false;
      }
    },
    async bulkdeletedata() {
      this.originaldata = this.tableData;
      // this.tableData = this.tableData.filter((e) => e.checked != true);
      this.bulkdeletebutton = false;
      this.partially_checked = false;

      document
        .getElementById("select-all-checkmark" + this.unique)
        .classList.remove("theme");
      this.originaldata.forEach((e) => {
        if (e.checked == true) {
          DMNAxiosInstance.delete(
            `mapRef/remove/${this.referencedataname}/${e._id}`
          );
        }
      });
      this.collectionDataManagementCalling(this.currPage, this.rowsPerPage);

      this.duplicate_table_data = this.tableData;
    },
    search(val) {
      this.searchvalue = val;
      console.log("Search",val)
      // var arr = []
      if (val != "") {
        this.collectionDataManagementquery["type"] = "search";
      } else {
        this.collectionDataManagementquery["type"] = "filter";
      }
      this.collectionDataManagementquery["searchQuery"] = this.searchvalue;
      this.currPage = 1;
      // this.rowsPerPage = 10;
      this.collectionDataManagementCalling(this.currPage, this.rowsPerPage);
    },
    cleareditdata() {
      this.modal_active1 = false
      // this.theads.forEach((e) => {
      //   Object.keys(this.editdataobj).forEach((ele) => {
      //     //
      //     if (e.prop == ele) {
      //       this.editdataobj[e.prop] = "";
      //     }
      //   });
      // });
    },
    clearsavedata() {
      this.theads.forEach((e) => {
        Object.keys(this.adddataobj).forEach((ele) => {
          //
          if (e.prop == ele) {
            this.adddataobj[e.prop] = "";
          }
        });
      });
    },
    sorting_Data(data, data1) {
      //
      console.log(data, data1);
      if (data === "icon-angle-up-small-filled sorting-icon") {
        this.collectionDataManagementquery.type = "filter";
        this.collectionDataManagementquery.isNeedToBeSort = true;
        console.log(data1, "data1");
        if (data1 === "name") {
          this.collectionDataManagementquery.sortFieldAndOrder = { name: 1 };
        } else if (data1 == "description") {
          this.collectionDataManagementquery.sortFieldAndOrder = {
            description: 1,
          };
        } else if (data1 == "updatedAt") {
          this.collectionDataManagementquery.sortFieldAndOrder = {
            updatedAt: 1,
          };
        }
        this.currPage = 1;
        this.collectionDataManagementCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled sorting-icon") {
        this.collectionDataManagementquery.type = "filter";
        this.collectionDataManagementquery.isNeedToBeSort = true;
        if (data1 === "name") {
          this.collectionDataManagementquery.sortFieldAndOrder = { name: -1 };
        } else if (data1 == "description") {
          this.collectionDataManagementquery.sortFieldAndOrder = {
            description: -1,
          };
        } else if (data1 == "updatedAt") {
          this.collectionDataManagementquery.sortFieldAndOrder = {
            updatedAt: -1,
          };
        }
        this.currPage = 1;
        this.collectionDataManagementCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-up-small-filled") {
        this.collectionDataManagementquery.type = "filter";
        this.collectionDataManagementquery.isNeedToBeSort = false;
        this.collectionDataManagementquery.sortFieldAndOrder = {};
        this.currPage = 1;
        this.collectionDataManagementCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled") {
        this.collectionDataManagementquery.type = "filter";
        this.collectionDataManagementquery.isNeedToBeSort = false;
        this.collectionDataManagementquery.sortFieldAndOrder = {};
        this.currPage = 1;
        this.collectionDataManagementCalling(this.currPage, this.rowsPerPage);
      }
    },
  },
};
</script>

<style></style>
