<template>
  <div>
    <GMapMap
      :center="mapCenter1"
      :zoom="zoom"
      style="width: 100%; height: 180px"
      @click="handleMapClick"
    >
      <GMapMarker 
      v-if="mapCenter1 && polygoncheck" 
      :position="mapCenter1"
      :draggable="isDraggable"
      @dragend="handleDragEnd( $event)"
         />
      <GMapPolygon
        v-for="(polygon, index) in polygonPaths"
        :key="index"
        :paths="polygon"
        :options="{ fillColor: '#FF0000', fillOpacity: 0.1, strokeWeight: 1, strokeColor:'#FF0000' }"
      />
      
      <GMapCircle
                    :center="mapCenter1"
                    :radius="radiuscircle"
                    :options="circleOptions"
                  />
    </GMapMap>
  </div>
</template>

<script>
import { GMapMap, GMapMarker, GMapPolygon, GMapCircle } from "@fawmi/vue-google-maps";
import axios from "axios";

export default {
  components: {
    GMapMap,
    GMapMarker,
    GMapPolygon,
    GMapCircle
  },
  props: {
    searchQuery: {
      type: String,
      required: true,
      default:"nepal"
    },

    
    radiuscircle1: {
      type: Number,
    },

    
    selectedShape: {
      type: String,
    },
    
    mapCenter: {  
      type: Object,
      required: true,
      default(){
        return {lat:10,lng:79}
      }
    },
  },
  emits:[
    "polygonPaths","mapCenter"
  ],
  data() {
    return {
      radiuscircle:0,
      mapCenter1: { lat: 37.7749, lng: -122.4194 }, // Default to San Francisco
      zoom: 5,
      autocomplete: null,
      isLoading: true,
      polygonPaths: [],
      polygoncheck: true
    };
  },

  computed:{
    
    circleOptions() {
      return {
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      };
    },

    isDraggable() {
      return this.selectedShape !== 'Point of interest';
    }

  },

  watch:{
    mapCenter: {
      async handler(val) {
        console.log(val, "mapcentre watching");
        if(val.lat && val.lng){
        this.mapCenter1 = val;
      }
      },
      immediate: true,
      deep: true,
    },
    radiuscircle1: {
      async handler(val) {
        console.log(val, "radiuscircle watching..........");
        // if(val>0){
          this.radiuscircle = val;
          if(val>0){
            this.polygonPaths = []
          }
        // }
        // if(val.lat && val.lng){
        // this.mapCenter1 = val;
      // }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {

    this.$watch(
      'searchQuery',
      async (newQuery) => {
        if (newQuery) {
          console.log("runnning",newQuery);
          if(this.selectedShape){
              if(this.selectedShape === 'Point of interest'){
              await this.fetchCoordinates(newQuery);
            }            
          }


        }
      },
      { immediate: true }
    );
  },

  methods: {

    handleMapClick(event){

      if(this.selectedShape){
           if(this.selectedShape !== 'Point of interest'){

              const lat = event.latLng.lat()
              const lng = event.latLng.lng()
              console.log("event",event,lat,lng);
              this.mapCenter1 = {lat:lat,lng:lng};

              this.$emit("mapCenter",this.mapCenter1)

            }            
          }

    
    },

    handleDragEnd(event) {
      console.log("event........",event);
      
      if(this.selectedShape){
        if(this.selectedShape !== 'Point of interest'){
      this.mapCenter1 = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };

      this.$emit("mapCenter",this.mapCenter1)
    }
  }
    },

    async fetchCoordinates(query) {
      this.polygonPaths = [];
      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/search.php?q=${query}&polygon_geojson=1&format=jsonv2`
        );
        const data = response.data;
        console.log("Fetched data:", data);
        this.processCoordinates(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    processCoordinates(data) {
      if (data[0]) {
        let arrayOfArray = [];
        const coordinates = data[0].geojson.coordinates;
        let areaType = '';

        // Single coordinate pair
        if (
          coordinates.length === 2 &&
          typeof coordinates[0] === "number" &&
          typeof coordinates[1] === "number"
        ) {
          arrayOfArray.push([{ lat: coordinates[1], lng: coordinates[0] }]);
          console.log("Single coordinate:", coordinates);
          this.polygoncheck = true;
          areaType = 'Single';
        }
        // Array of coordinate pairs
        else if (
          Array.isArray(coordinates) &&
          coordinates.every((coord) => Array.isArray(coord) && coord.length === 2)
        ) {
          arrayOfArray.push(coordinates.map(([lng, lat]) => ({ lat, lng })));
          console.log("Array of coordinate pairs:", coordinates);
          this.polygoncheck = true;
          areaType = 'Array';
        }
        // Nested array of coordinate pairs
        else if (
          Array.isArray(coordinates) &&
          Array.isArray(coordinates[0]) &&
          coordinates[0].every((coord) => Array.isArray(coord) && coord.length === 2)
        ) {
          coordinates.forEach((nestedCoords) => {
            arrayOfArray.push(nestedCoords.map(([lng, lat]) => ({ lat, lng })));
          });
          console.log("Nested array of coordinate pairs:", coordinates);
            this.polygoncheck = false;
            areaType = 'Nested';
        }
        // Multi-level nested array
        else {
          coordinates.forEach((nestedCoords) => {
            arrayOfArray.push(nestedCoords[0].map(([lng, lat]) => ({ lat, lng })));
          });
          console.log("Multi-level nested array:", coordinates);
            this.polygoncheck = false;
            areaType = 'MultiNested';
        }

        console.log("areaType",areaType);
        console.log("Processed polygon paths:", arrayOfArray);
        this.polygonPaths = arrayOfArray;
        this.$emit("polygonPaths", this.polygonPaths,areaType);
      }
    },
  },
};
</script>