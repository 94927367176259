<template>
    <div style="padding: 30px">
      <div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
          "
        >
          <div style="display: flex; align-items: center; gap: 16px">
            <span style="">
              <hlx-file-upload
                :file-type="'.jpg,.jpeg,.png'"
                :file-size-limit="'20mb'"
                :uploadtype="'Rectangle'"
                :style-background="backgrounStyleupload"
                :height="50"
                :width="50"
                :read-only="true"
                :custom-icon="icon"
                :type="'profile-upload'"
                :name-display="payload.customName"
                :content="'Import'"
                :profile-pic-preview="profilePicUrl"
              />
            </span>
  
            <div style="display: flex; flex-direction: column; gap: 8px">
              <div style="display: flex; align-items: center; gap: 8px">
                <span style="font-weight: bold">{{ payload.customName }}</span>
                <hlx-label
                  v-if="payload.status.length > 0 && payload.status === 'Active'"
                  class="primary sm"
                  >{{ payload.status }}</hlx-label
                >
                <hlx-label
                  v-if="
                    payload.status.length > 0 && payload.status === 'Inactive'
                  "
                  class="error sm"
                  >{{ payload.status }}</hlx-label
                >
              </div>
              <div style="color: #a6a6a6; font-size: 12px; width: max-content">
                {{ payload.contactMedium[3].characteristic.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
v-if="discountData1.length < 0"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 40px;
          height: 80vh;
        "
      >
        <img src="@/assets/images/suppliercontract.svg" alt="" />
      </div>

      <div
      v-if="discountData1.length > 0"
    class="header-add-supplier"
  >
    <div class="tablehead-add-supplier">{{ 'Discounts' }}</div>
    <div class="search-add-supplier">
      <span><hlx-search @search-key="productSearch"></hlx-search></span>
      <!-- <span
        ><hlx-button class="secondary sm"
          ><i class="icon-filter-regular"></i></hlx-button
      ></span> -->
      <!-- <span v-show="supplierStatus == false"
        ><hlx-button class="primary sm" @click="discountdrawer">
          <i class="icon-plus-circle-regular"></i>
          Add Discount
        </hlx-button></span -->
      
    </div>






  </div>



 <!-- {{ discountData.length }}  -->
  <div
      v-if="discountData.length > 0"
      style="height: 78vh; overflow: auto"
      class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
    >
    <!-- {{ paginatedData.length }} -->
      <hlx-table
        :column-count="accounts_table_theads.length + 2"
        :border="['table', 'header', 'horizontal']"
        :row-hover="true"
        theme="grey"
        :re-arrange-columns="re_arrange"
        :re-arrange-heads="accounts_table_theads_cc"
        @close-rearrange="arrangeColumns"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
            >{{ "S.no" }}</hlx-table-head
          >
          <hlx-table-head
            v-for="(i, index) in accounts_table_theads"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            :width="i.width !== undefined ? i.width : ''"
            :align="i.align !== undefined ? i.align : ''"
            @sorting_func="sorting_Data"
          >
            <span v-if="['MSRP', 'stockedProduct'].includes(i?.prop)">
              {{ i.label }} ({{
                $store?.getters?.getDefaultSettings?.currency?.unit
              }})
            </span>
            <span v-else>
              {{ i.label }}
            </span></hlx-table-head
          >
          <hlx-table-head
            v-show="supplierStatus == false"
            :align="'center'"
            :width="70"
            :fixed="'right'"
            ><span class="action-icon">
              <i
                class="icon-settings-regular"
                @click="arrange(true)"
              ></i> </span
          ></hlx-table-head>
        </template>
        <template #tbody>
          <tr v-for="(i, index) in paginatedData" id="" :key="index">
            <hlx-table-cell :align="'center'" :fixed="'left'">
              {{ serialNumber(index) }}
            </hlx-table-cell>
            <hlx-table-cell
              v-for="(j, col_index) in accounts_table_theads"
              :key="col_index"
              :align="j.align"
              :fixed="j.fixed"
            >
              <div v-if="j.prop.toLowerCase() === 'validfor'">
                {{ formatTime(i.validFor.startDate) }}
              </div>

              <div v-else-if="j.prop.toLowerCase() === 'enddate'">
                {{ formatTime(i.validFor.endDate) }}
              </div>

              <div v-else-if="j.prop?.toLowerCase() === 'lifecyclestate'">
                <hlx-status :type="i[j.prop]"></hlx-status>
              </div>
              <div v-else-if="j.prop?.toLowerCase() === 'appliesto'">
                <hlx-tagcount
                  :arrayfile="i[j.prop]"
                  :item-key="'name'"
                  :type="'label'"
                ></hlx-tagcount>
              </div>
              <div
                v-else-if="j.prop?.toLowerCase() === 'maximumdiscountrules'"
                style="margin-left: 14px"
              >
                {{ i[j.prop][0]?.value }} %
              </div>
              <div v-else-if="j.prop?.toLowerCase() === 'agreementref'">
                {{ i[j.prop][0]?.name }}
              </div>

              <div v-else>
                {{ i[j.prop] }}
              </div>
            </hlx-table-cell>
            <hlx-table-cell
              v-show="supplierStatus == false"
              :align="'center'"
              :width="120"
              :fixed="'right'"
            >
              <span
                :id="'table-context' + serialNumber(index - 1)"
                class="action-icon"
              >
                <i
                  style="position: relative"
                  class="icon-more-vertical-filled"
                  :class="i.context === true ? 'active-action' : ''"
                  @click="crudContextMenu($event, serialNumber(index - 1))"
                >
                  <hlx-context-menu
                    :top="containertop"
                    :left="containerleft"
                    :conditionvalue="150"
                    :conditiondown="2"
                    :conditionup="-38"
                    :options="computedTableAction(i)"
                    :data="index.toString()"
                    :show="i.context == true"
                    @chosen="closeCrudContextMenu($event, i, index)"
                  />
                </i>
              </span>
            </hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
    </div>






    <div
      v-if="discountData.length === 0 && searchvalue !== ''"
      style="height: 78vh; overflow: auto"
      class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
    >
    <!-- {{ paginatedData.length }} -->
      <hlx-table
        :column-count="accounts_table_theads.length + 2"
        :border="['table', 'header']"
        :row-hover="true"
        theme="grey"
        :re-arrange-columns="re_arrange"
        :re-arrange-heads="accounts_table_theads_cc"
        @close-rearrange="arrangeColumns"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
            >{{ "S.no" }}</hlx-table-head
          >
          <hlx-table-head
            v-for="(i, index) in accounts_table_theads"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            :width="i.width !== undefined ? i.width : ''"
            :align="i.align !== undefined ? i.align : ''"
            @sorting_func="sorting_Data"
          >
            <span v-if="['MSRP', 'stockedProduct'].includes(i?.prop)">
              {{ i.label }} ({{
                $store?.getters?.getDefaultSettings?.currency?.unit
              }})
            </span>
            <span v-else>
              {{ i.label }}
            </span></hlx-table-head
          >
          <hlx-table-head
            v-show="supplierStatus == false"
            :align="'center'"
            :width="70"
            :fixed="'right'"
            ><span class="action-icon">
              <i
                class="icon-settings-regular"
                @click="arrange(true)"
              ></i> </span
          ></hlx-table-head>
        </template>
        
        <template #tbody>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell :colspan="accounts_table_theads.length + 2">{{
              "No data"
            }}</hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
        </template>

      </hlx-table>
    </div>







    <!-- {{ company }}
    {{ currPage }}
    {{ rowsPerPage }} -->
      <div v-if="discountData?.length > 10">
    <hlx-pagination
      v-model:rows-per-page="externalRowsPerPage"
      :total="discountData.length"
      rows-per-page
      enable-rows-per-page
      :rows-per-page-list="[10, 15, 20]"
      :options="{
        attributes: {
          rowsPerPageTitle: true,
          rowsPerPageResult: true,
          rowsPerPageDropdown: {
            position: {
              top: 0,
              right: 1,
            },
          },
        },
      }"
      @current-page="currentPage"
      @updated:rows-per-page="changeRowsPerPage"
    ></hlx-pagination>
  </div>

<div
      v-if="discountData.length <= 0 "
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
        height: 70vh;
      "
    >
      <img
        class="temp-Product-illustration"
        src="@/assets/images/addDiscount.svg"
        alt="Product image"
        srcset=""
      />
     
    </div>


    </div>
  </template>
  
  <script>
  import { MLTAxiosInstance,UPCAxiosInstance } from "@/config/axiosConfig";
  
  export default {
    data() {
      return {
        discountserarch:[],
      currPage: 1,
      rowsPerPage: 10,

      searchvalue:'',
      discountData1:[],
      discountData2:[],


        accounts_table_theads: [
        {
          sortable: false,
          resizable: false,
          width: null,
          label: "Name",
          prop: "name",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 80,
          label: "Status",
          prop: "lifecycleState",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 250,
          label: "Supplier contract",
          prop: "agreementRef",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 110,
          label: "Start date",
          prop: "validFor",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 110,
          label: "End date",
          prop: "endDate",
          align: "left",
        },

        {
          sortable: false,
          resizable: false,
          width: 100,
          label: "Discount",
          prop: "maximumDiscountRules",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 250,
          label: "Offers",
          prop: "appliesTo",
          align: "left",
        },
      ],

        discountData:[],
        profilePicUrl: "",
        company: "",
        userId: "",
        payload: {
          attachment: [],
          name: "",
          role: "",
          password: "",
          email: "",
          phone: "",
          tags: [],
          notes: [],
          companyName: "",
          tradingName: "",
          status: "",
          type: "",
          "@baseType": "",
          paymentTerms: [
            {
              name: "",
              id: "",
            },
          ],
          externalReference: [
            {
              name: "",
              externalIdentifierType: "internetSite",
            },
          ],
          contactMedium: [
            {
              preferred: true,
              mediumType: "email",
              characteristic: {
                emailAddress: "",
              },
            },
            {
              mediumType: "phone",
              characteristic: {
                phoneNumber: {
                  country: {
                    name: "Canada",
                    flags: "https://flagcdn.com/ca.svg",
                    short: "CA",
                    dial_code: "+1",
                  },
                  value: "",
                },
              },
            },
            {
              mediumType: "fax",
              characteristic: {
                faxNumber: "",
              },
            },
            {
              mediumType: "postalAddress",
              characteristic: {
                city: "",
                country: "",
                postCode: "",
                stateOrProvince: "",
                street1: "",
                street2: "",
                description: "",
                "@type": "",
              },
            },
          ],
        },
      };
    },
    
    
    
    computed: {
        paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      console.log("this.discountData.length",this.discountData.length);
      return this.discountData.slice(start, end);
    },
  },
  
    async mounted() {
        
        
        const val = await MLTAxiosInstance.get(`partymanagement/individual/auth`);
        this.company = val.data.company;
        this.userId = val.data.userId;
        const access = await MLTAxiosInstance.get(
            `partyRole/get/${val.data.userId}`
        );
        
        const payload = await MLTAxiosInstance.get(
            `partymanagement/organisation/${this.userId}?company=${this.company}`
      );
      this.payload = payload.data;
      console.log(access, val.data.company, val.data.company, val, "<>");
      const profileName = this.payload.attachment.filter((item) => {
        return item["@type"] !== undefined && item["@type"] === "profile"
          ? item
          : false;
      });
      console.log(profileName[0]?.name);
      this.profilePicName = profileName[0]?.name;
      const url = await UPCAxiosInstance.get(
          `/gcpupload/files/${profileName[0]?.name}`,
          { responseType: "arraybuffer" }
        );
        // console.log(new Uint8Array([url.data,"url incoming"]))
        this.rawProfileUrlData = url.data;
        this.profilePicUrl = await this.bufferToDataURL(url.data);



        
        this.refeshDiscount();
    },



      methods: {


        productSearch(val) {
      this.searchvalue = val;
      console.log("search is on")
      if (val !== "") {
        let searchData = this.searchNestedObjects(
          this.discountData1,
          val.toLowerCase()
        );
        this.discountData = JSON.parse(JSON.stringify(searchData));
        console.log(this.discountData, "this.discountData");
      } else {
        this.discountData = JSON.parse(JSON.stringify(this.discountserarch));
      }
    },


    
    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },




        formatTime(inputTime) {
      // Parse the input time string
      const date = new Date(inputTime);
 
      // Create an array of month names
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
 
      // Extract the components of the date
      const year = date.getFullYear();
      const month = monthNames[date.getMonth()];
      const day = date.getDate();
     
      // const seconds = date.getSeconds();
 
      // Format the time string
      const formattedTime = `${month} ${day},${year} `;
 
      return formattedTime;
    },

        async refeshDiscount() {
      const getDiscount = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/supplierView?company=${this.company}`
      );
      this.discountData = getDiscount.data;
      this.discountData1 = getDiscount.data;
      this.discountData2 = getDiscount.data;
      this.discountserarch = getDiscount.data;
      console.log(this.discountData, "this.discountData");
    },


        
    async changeRowsPerPage(count) {
      if (this.company != "") {
        this.rowsPerPage = count;
        this.supplierdataCalling(
          this.SpecificationcurrPage,
          this.SpecificationrowsPerPage
        );
      }
    },
    async currentPage(val) {
      if (this.company != "") {
        this.currPage = val;
        this.supplierdataCalling(
          this.SpecificationcurrPage,
          this.SpecificationrowsPerPage
        );
      }
      this.table_height = this.paginatedData.length * 40 + 43;
    },




    
    crudContextMenu($event, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.discountData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.discountData[index].context = true;
      } else {
        this.discountData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    async closeCrudContextMenu($event, ele, i) {
      if ($event.toLowerCase() === "edit") {
        this.isModal = true;
        this.mainInfoNewDiscount.forEach((val) => {
          val.checked = false;
        });
        this.discountNewDataEmit = JSON.parse(
          JSON.stringify(this.copyOfDiscountTableData[i])
        );
        this.rawdata = JSON.parse(
          JSON.stringify(this.copyOfDiscountTableData[i])
        );
        ele?.appliesTo.forEach((checkedItem) => {
          const matchingItem = this.mainInfoNewDiscount.find(
            (item) => item._id === checkedItem._id
          );
          if (matchingItem) {
            this.editMode = true;
            matchingItem.checked = true;
          }
        });
        console.log(this.mainInfoNewDiscount, "this.mainInfoNewDiscount");
        //
        console.log(
          $event,
          ele,
          i,
          this.discountNewDataEmit,
          "edit closeCrudContextMenu"
        );
      } else if ($event.toLowerCase() == "delete") {
        this.delete_modal_active = true;
        this.deleteobj = ele;
        this.delete_name = ele.name;
        this.deleterowindex = i;
      }
    },
    computedTableAction(data) {
      console.log(data);
      return [
        {
          label: "Edit",
          icon: "icon-file-text-edit-regular",
        },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ];
    },






    
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },


      async bufferToDataURL(buffer) {
        // Convert the buffer to a Uint8Array
        const uint8Array = new Uint8Array(buffer);
  
        // Convert Uint8Array to a binary string
        let binary = "";
        uint8Array.forEach((byte) => {
          binary += String.fromCharCode(byte);
        });
  
        // Base64 encode the binary string
        const base64String = window.btoa(binary);
        this.profilePicUrl = `data:image/png;base64,${base64String}`;
        // Construct and return the data URL
        return `data:image/png;base64,${base64String}`;
      },}
  };
  </script>
  
  <style></style>
  