<template>

<hlx-modal
      style="z-index: 999999999"
      :modal-active="delete_modal_active"
      :height="'220px'"
      :width="'400px'"
      :modal="true"
      @close="delete_modal_active = false"
    >
      <template #header>
        <div style="margin-left: 0px">Delete data</div>
      </template>
      <template #content>
        <div class="modal-content">
          <p>
            Are you sure you want to delete <b>{{ delete_name }}</b
            >?
          </p>
        </div>
      </template>
      <template #footer>
        <hlx-button class="secondary sm" @click="delete_modal_active = false"
          >Cancel</hlx-button
        >
        <hlx-button
          class="primary sm"
          style="margin-left: 16px"
          @click="deleteExecRow"
          >Delete</hlx-button
        >
      </template>
    </hlx-modal>


    
<section :key="updateComponentKey" >
  <hlx-drawer
        :show="isDrawer"
        position="right"
        width="400"
        :footer="true"
        :title="'Add attachment'"
        :show-close-icon="true"
        @close="closeModal"
      >
      <template #body>
        <div
              class="header-area-contact-drawer"
              style="
                border-bottom: 1px solid #d4d4d4;
                padding-bottom: 5px;
                padding: 4px 0 13px 0;
              "
            >
              <b>Add discount</b>
            </div>
            <section style="margin-top: 12px">
              <hlx-multi-select
                    :pre-value="value"
                    :required="false"
                    :options="regioncode_dropdown_data"
                    :placeholder="'Region code'"
                    :label-animation="true"
                    :prop-value="'value'"
                    :label="'regioncode'"
                    @selected-array="selectedValue"
                  ></hlx-multi-select>
            </section>
      </template>
      
      <template #footer>
          <!-- For showing discount icons -->
          <div
            style="
              display: flex;
              justify-content: flex-end;
              padding: 19px;
              border-top: 1px solid #d8d8d8;
              gap:12px;
            "
          >
            <hlx-button class="secondary sm" @click="closeModal"
              >Cancel</hlx-button
            >
            <hlx-button class="primary sm" @click="addRegionInTable" >Add</hlx-button>
          </div>
        </template>
</hlx-drawer>
  
</section>












<div v-if="tableDataInit?.length > 0" >

<section class="top-bar-cover" style="display:flex">
    <span class="header" style="margin-bottom:0px !important">
    <h4>Available regions</h4>
  </span>

    <section class="available-region" style="display: flex; gap: 6px;">
    <span class="util-items search">
      <hlx-search
              v-model="searchvalue"
              @search-key="pricingSearchTrigger"
            />
    </span>
    <span class="filter-table">
            <!-- <hlx-button class="secondary sm add-btn" @click="showDrawerRight">
              <i class="icon-filter-regular"></i>
              <span v-if="filtertaglength != 0" class="filter-count">{{
                filtertaglength
              }}</span>
            </hlx-button> -->
          </span>
          <span v-if="data === 0">
            <hlx-button class="primary sm" @click="openAddRegionDrawer()">
            <i class="icon-plus-circle-regular"></i> Add region
          </hlx-button>
        </span>
  </section>
</section>

<section
v-if="tableData?.length>0"
class="discount-table-available-region" style="width: 100%;
    margin: 0 auto;
    margin-top: 12px !important">
 
    <hlx-table
      :column-count="tableHeader.length"
      :border="['table', 'header', 'horizontal']"
      :bold-headers="false"
      :row-hover="true"
      theme="grey"
      :striped-rows="false"
    >
      <template #thead>
        <hlx-table-head
          :align="'center'"
          style="padding: 0 15px"
          :fixed="'left'"
          :width="100"
        >
          {{ "S.no" }}</hlx-table-head
        >

        <hlx-table-head
          v-for="(header, index) in tableHeader"
          :key="index"
          :width="header.width"
          :sortable="header.sortable"
          :resizable="header.resizable"
          >{{ header.label }}
        </hlx-table-head>
        <hlx-table-head
        :width="60"
          :fixed="'right'"
          ><span class="action-gear">
            <i class="icon-settings-regular"></i></span
        ></hlx-table-head>
      </template>
      <!-- <template #tbody>
        <tr v-for="(data,index) in tableData" :key="index">
            <hlx-table-cell :align="'center'" :fixed="'left'">
            {{ serialNumber(index) }}
            </hlx-table-cell>
        </tr>
   </template> -->
      <template #tbody>
        <tr v-for="(i, index) in tableDataOnSelectedRows" id="" :key="index">
          <hlx-table-cell :align="'center'" :fixed="'left'" :width="60">
            {{  serialNumberAlter(index)}}
          </hlx-table-cell>
          <hlx-table-cell
            v-for="(j, col_index) in tableHeader"
            :key="col_index"
            :align="j.align"
            :fixed="j.fixed"
            style="text-align:center"
            :width="j.width"
          >
          <div v-if="j.prop === 'characteristicData'" >
              <!-- {{ i[j.prop] }} -->
                <div v-for="itm in i?.characteristicData" :key="itm">
                  <span> {{capitalizeFirstLetter(itm.name)}} : </span>
                <span> {{ itm.value }} </span>
                </div>
            </div>
            <div v-else>
              {{i[j.prop]}}
            </div>
          </hlx-table-cell>
          <hlx-table-cell
            :align="'center'"
            :fixed="'right'"
            :width="60"
          >
            <span :id="'table-context' + serialNumberAlter(index-1)" class="action-icon">
              <i
                style="position: relative"
                class="icon-more-vertical-filled"
                :class="i.context === true ? 'active-action' : ''"
                @click="crudContextMenu($event, serialNumberAlter(index - 1))"
              >
                <hlx-context-menu
                  :top="containertop"
                  :left="containerleft"
                  :conditionvalue="150"
                  :conditiondown="2"
                  :conditionup="-38"
                  :options="computedTableAction(i)"
                  :data="index.toString()"
                  :show="i.context == true"
                  @chosen="closeCrudContextMenu($event, i, index)"
                />
              </i>
            </span>
          </hlx-table-cell>
        </tr>
      </template>
    </hlx-table>
  </section>
  <!-- no data -->
  <section
  v-else
class="discount-table-available-region" style="width: 100%;
    margin: 0 auto;
    margin-top: 12px !important">
    <hlx-table
          :column-count="tableHeader.length + 2"
          :border="['table', 'header']"
          :bold-headers="false"
          :row-hover="true"
          theme="grey"
          :striped-rows="false"
          :re-arrange-columns="re_arrange"
          :re-arrange-heads="theads"
          @close-rearrange="arrange"
          @apply-rearranged-columns="applyColumns"
        >
          <template #thead>
            <hlx-table-head :width="60" :align="'left'">{{
              "S.no"
            }}</hlx-table-head>
            <hlx-table-head
              v-for="(i, index) in tableHeader"
              :key="index"
              :prop="i.prop"
              :sortable="i.sortable"
              :resizable="i.resizable"
              style="position: sticky; top: 0px"
              :width="i.width !== undefined ? i.width : ''"
              :align="i.align !== undefined ? i.align : ''"
              @sorting_func="sorting_Data"
            >
              {{ i.label }}
            </hlx-table-head>
            <hlx-table-head v-show="supplierStatus == false" :width="100">
              <span class="action-icon"
                ><i
                  class="icon-settings-regular"
                  @click="arrange(true)"
                ></i></span
            ></hlx-table-head>
          </template>
          <template #tbody>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell :colspan="tableHeader.length + 2">{{
                "No data"
              }}</hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
          </template>
        </hlx-table>
  </section>

  <!-- pagination -->

  <div
              v-if="tableData.length > 0"
              class="discount-pagination-container"
              style="padding: 1rem 0; z-index: 251;"
            >
              <hlx-pagination
                v-model:rows-per-page="externalRowsPerPage"
                :total="tableData.length"
                enable-rows-per-page
                :rows-per-page-list="[10, 15, 20]"
                :options="{
                  attributes: {
                    rowsPerPageTitle: true,
                    rowsPerPageResult: true,
                    rowsPerPageDropdown: {
                      position: {
                        top: 0,
                        right: 1,
                      },
                    },
                  },
                }"
                @current-page="currentPage"
                @updated:rows-per-page="changeRowsPerPage"
              ></hlx-pagination>
  </div>
</div>

<div
v-else
style="
          margin-top: 7.2rem;
          display: flex;
          flex-direction: column;
          gap: 1.2rem;
          align-items: center;
        ">
  <img
          src="@/assets/images/AvailableRegionsIllustration.svg"
          alt=""
          style="width: 500px"
        />
</div>




</template>

<script>
export default {
  
  props: {
    regiondata1: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  
  emits: ["regiondelete"],

data(){
    return{
      tableDataInit:[],
      searchvalue:'',
      delete_modal_active: false,
        updateComponentKey:0,
        isDrawer:false,
        currPage: 1,
        rowsPerPage: 10,
        externalRowsPerPage: 10,
        offeringcurrPage: 1,
        offeringrowsPerPage: 10,
        prevalue: ["IN-AN", "IN-AP","IN-TN","IN-AZ","IN-PN","Php","Css"],
        regioncode_dropdown_data:[
            {regioncode:"IN-AN",value:"IN-AN"},
            {regioncode:"IN-AP",value:"IN-AN"},
            {regioncode:"IN-TN",value:"IN-AN"},
            {regioncode:"IN-PN",value:"IN-AN"},
            {regioncode:"IN-AN",value:"IN-AN"},
            {regioncode:"IN-AZ",value:"IN-AN"},
            {regioncode:"IN-AN",value:"IN-AN"},
            {regioncode:"IN-AP",value:"IN-AN"},
            {regioncode:"IN-TN",value:"IN-AN"},
            {regioncode:"IN-PN",value:"IN-AN"},
            {regioncode:"IN-AN",value:"IN-AN"},
            {regioncode:"IN-AZ",value:"IN-AN"},
            {regioncode:"IN-AN",value:"IN-AN"},
            {regioncode:"IN-AP",value:"IN-AN"},
            {regioncode:"IN-TN",value:"IN-AN"},
            {regioncode:"IN-PN",value:"IN-AN"},
            {regioncode:"IN-AN",value:"IN-AN"},
            {regioncode:"IN-AZ",value:"IN-AN"},
            {regioncode:"IN-AN",value:"IN-AN"},
            {regioncode:"IN-AP",value:"IN-AN"},
            {regioncode:"IN-TN",value:"IN-AN"},
            {regioncode:"IN-PN",value:"IN-AN"},
            {regioncode:"IN-AN",value:"IN-AN"},
            {regioncode:"IN-AZ",value:"IN-AN"},
        ],
        tableData:[
            {regioncode:"IN-AN"},
            {regioncode:"IN-AP"},
            {regioncode:"IN-TN"},
            {regioncode:"IN-PN"},
            {regioncode:"IN-AN"},
            {regioncode:"IN-AZ"},
            {regioncode:"IN-AN"},
            {regioncode:"IN-AP"},
            {regioncode:"IN-TN"},
            {regioncode:"IN-PN"},
            {regioncode:"IN-AN"},
            {regioncode:"IN-AZ"},
            {regioncode:"IN-AN"},
            {regioncode:"IN-AP"},
            {regioncode:"IN-TN"},
            {regioncode:"IN-PN"},
            {regioncode:"IN-AN"},
            {regioncode:"IN-AZ"},
            {regioncode:"IN-AN"},
            {regioncode:"IN-AP"},
            {regioncode:"IN-TN"},
            {regioncode:"IN-PN"},
            {regioncode:"IN-AN"},
            {regioncode:"IN-AZ"},
        ],
        tableHeader: [
        {
          label: "Region name",
          prop: "regionname",
          sortable: false,
          resizable: true,
          // width: 400,
          align: "left",
        },
        
        // {
        //   label: "Region code",
        //   prop: "regioncode",
        //   sortable: false,
        //   resizable: true,
        //   width: 400,
        //   align: "left",
        // },
        
        // {
        //   label: "Features",
        //   prop: "characteristicData",
        //   sortable: false,
        //   resizable: true,
        //   width: 600,
        //   align: "left",
        // }
      ]
    }
},
computed: {
    tableDataOnSelectedRows(){
        const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
},

watch:{
  regiondata1:{
    handler(val){
      console.log("regiondata is watching in component",val);
      this.tableDataInit = this.getRegionData(val?.place).reverse();
      this.tableData =  this.getRegionData(val?.place).reverse();
      console.log("this.tableData",this.tableData);
      this.offername = val?.name;
      this.offerid = val?._id;
    },
    deep: true,
    immediate: true,
  },
},
methods:{
    pricingSearchTrigger(val) {
      this.searchvalue =val
      console.log("onsearch value", val);
      if (val) {
        
        const result = this.searchInArray(val, this.tableDataInit);
        console.log("🚀 ~ AVregSearchTrigger ~ result:", result);
        this.tableData = result;
      } else {
        
        console.log("🚀 ~ mediaSearchTrigger ~ this.search:");
        this.tableData = this.tableDataInit;
      }
    },

    
    searchInArray(searchTerm, dataArray) {
      console.log("searchTerm is empty before", searchTerm, dataArray);
      if (searchTerm) {
        const searchTermLower = searchTerm?.toLowerCase();

        const result = dataArray.filter((obj) => {
          return Object.values(obj).some((value) => {
            if (typeof value === "string") {
              return value?.toLowerCase().includes(searchTermLower);
            }
            return false;
          });
        });

        return result;
      } else {
        console.log("searchTerm is empty", dataArray);
        return dataArray;
      }
    },
  
  capitalizeFirstLetter(string) {
      return string.replace(/\b\w/g, (match) => match.toUpperCase());
    },
  
  async deleteExecRow() {
      this.delete_modal_active = false;
      console.log("this.deleteobj",this.deleteobj);
      
      this.$emit("regiondelete", this.deleteobj);

      //for offer


        // const deleteresponse = await UPCAxiosInstance.delete(
        //   `/geographicAddress/${this.deleteobj.id}`
        // );
        // console.log(deleteresponse, "deleteteeee");
      },
  // getRegionData(inputArray) {
  //   return inputArray.map(inputString => {
  //       const words = inputString.split(' ');
  //       let regionCode;

  //       if (words.length === 1) {
  //           regionCode = words[0].substring(0, 3).toUpperCase();
  //       } else {
  //           regionCode = words.map(word => word[0]).join('').toUpperCase();
  //       }

  //       return {
  //           regionname: inputString,
  //           regioncode: regionCode
  //       };
  //   });
// },
getRegionData(inputArray = []) {
  if (!Array.isArray(inputArray)) {
    throw new TypeError('Expected an array as input');
  }

  return inputArray.map(item => {
    const regionName = item.region;
    const words = regionName.split(' ');
    let regionCode;

    if (words.length === 1) {
      regionCode = words[0].substring(0, 3).toUpperCase();
    } else {
      regionCode = words.map(word => word[0]).join('').toUpperCase();
    }

    const characteristics = item?.characteristicData?.map(data => {
      return { name: data?.name, value: data?.value };
    });

    return {
      regionname: regionName,
      regioncode: regionCode,
      characteristicData: characteristics
    };
  });
},

//   convertArrayToObjectArray(arr) {
//   return arr.map(item => ({ regioncode: item }));
// },
closeCrudContextMenu($event, ele, i) {
      if ($event.toLowerCase() === "edit") {
        this.editMode = true;
        const payload = JSON.parse(JSON.stringify(this.tableData[i]));
        this.openuploaddrawer1(payload, i);
        this.editpayload = payload;
        console.log(payload, i, "jjjjjjjjjjjjjjjjjjjjjj");
      } else if ($event.toLowerCase() === "delete") {
        this.delete_modal_active = true;
        this.deleteobj = ele;
        console.log("qqqqqqqqqqqqq", ele, this.deleteobj._id);
        this.delete_name = ele.regionname;
      }
    },



  openAddRegionDrawer(){
    this.updateComponentKey++;
    this.isDrawer = true;
  },
  closeModal(){
    this.isDrawer = false;
  },
  addRegionInTable(){
    this.isDrawer = false;
  },
    serialNumberAlter(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    
    currentPage(val){
        this.currPage = val;
    },
    changeRowsPerPage(count) {
      this.rowsPerPage = count;
    },
    computedTableAction(data) {
      let options = [
        // {
        //   label: "Edit",
        //   icon: "icon-file-text-edit-regular",
        // },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ];
      return options.filter((option) => {
        if (
          data &&
          data.status &&
          data.status.toLowerCase() == "draft" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "active" &&
          ["Draft", "Mark as active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "inactive" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else {
          return true;
        }
      });
    },
    crudContextMenu($event, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
}
}
</script>

<style lang="scss">
.top-bar-cover{
    display: flex;
    width: 100%;
    justify-content: space-between;

}
.discount-table-available-region
{

    margin-top: 12px;
    margin-bottom: 24px;
    .table-head {
    display: flex;
    // justify-content: center;
    
    justify-content: space-around;

    }
}
</style>