<template>
  <div class="filter-panel-layout1">
    <div v-if="filtertagvalue.length > 0" class="applied-filter-section">
      <div class="applied-filter-section-header">
        <p>Filters Applied</p>
        <p class="clear-all-button" @click="clearAllFilter">Clear All</p>
      </div>

      <!--Tag value-->
      <div class="applied-filter-section-area">
        <span v-for="(filtervalue, arrindex) in filtertagvalue" :key="arrindex">
          <hlx-tag
            v-if="filtervalue.name != undefined"
            class="tag-color1"
            @tag-close="removedFilterData(filtervalue)"
            >{{ insertSpaces(filtervalue.name) }}</hlx-tag
          >
          <hlx-tag
            v-else-if="filtervalue.name == undefined"
            class="tag-color1"
            @tag-close="removedFilterData(filtervalue)"
            >{{ filtervalue.key + ":" + " Others" }}</hlx-tag
          >
        </span>
      </div>
    </div>

    <div v-if="filterdata != undefined" class="select-filter-option">
      <!--Selected count section-->
      <span class="select-filter-option-header">
        <p class="select-filter-option-count-filter-section">Filters</p>
        <span>
          <p class="select-filter-option-count">
            {{ filtertagvalue.length }} selected
          </p>
        </span>
      </span>

      <!--Accordion area-->

      <span class="select-filter-option-section">
        <span v-for="(i, index) in Object.keys(filterdata)" :key="index">
          <hlx-accordion :multiple="true">
            <template #item-title>
              <span
                v-if="i != undefined && i!= 'productOfferingPrice'"
                class="select-filter-option-section-title"
              >
                <!--It display the header value in each section-->

                <span class="select-filter-option-section-title-name">
                  {{ insertSpaces(i) }}
                </span>
                <!--It display the selected count value in each section-->
                <span
                  v-if="filterselectedcount[i] != undefined"
                  class="select-filter-option-section-title-count"
                >
                  {{ filterselectedcount[i] }}
                </span>
              </span>
              <span
                v-if="i != undefined && i== 'productOfferingPrice'"
                class="select-filter-option-section-title"
              >
                <!--It display the header value in each section-->

                <span class="select-filter-option-section-title-name">
                  {{ insertSpaces(i) }}
                </span>
              </span>
            </template>
            <template #item-body>
              <div
                v-if="i != 'Product offering price'"
                class="select-filter-body-section"
              >
                <span>
                  <div
                    v-for="(j, col_index) in filterdata[i]"
                    :key="col_index"
                    class="select-filter-body-section-value-container"
                  >
                    <span
                      v-if="
                        j &&
                        j.name &&
                        typeof j == 'object' &&
                        j.name != undefined
                      "
                      class="select-filter-body-section-value"
                    >
                      <span
                        v-if="typeof j == 'object'"
                        class="select-filter-body-section-value-details"
                      >
                        {{ insertSpaces(j.name) }}
                        <span
                          class="select-filter-body-section-value-details-count"
                          >{{ j.count }}</span
                        >
                        <!-- {{ j.name }} -->
                      </span>
                    </span>
                    <span
                      v-else-if="j.name == undefined"
                      class="select-filter-body-section-value"
                    >
                      {{ "Others" }}
                      <span
                        class="select-filter-body-section-value-details-count"
                        >{{ j.count }}</span
                      >
                    </span>
                    <!-- Checkbox area -->

                    <!--value area-->

                    <span
                      v-if="
                        j &&
                        j.name &&
                        typeof j == 'object' &&
                        j.name != '' &&
                        j.name != undefined
                      "
                      class="select-filter-body-section-value-checkbox"
                    >
                      <hlx-input
                        v-model:value="j.checked"
                        :checked="j.checked"
                        :type="'single-checkbox'"
                        @selected="filterCheckedValue(i, j)"
                      />
                    </span>

                    <!--undefined area-->

                    <span
                      v-if="j.name == undefined"
                      class="select-filter-body-section-value-checkbox"
                    >
                      <hlx-input
                        v-model:value="j.checked"
                        :checked="j.checked"
                        :type="'single-checkbox'"
                        @selected="filterCheckedValue(i, j)"
                      />
                    </span>
                  </div>
                </span>
              </div> 
              <div v-else-if="i && i == 'Product offering price'" style="width:100%">
                   <hlx-min-max-slider
                    v-model:min-value="sliderMin"
                    v-model:max-value="sliderMax"
                    :icon="symbol"
                    :min="minValue"
                    :max="maxValue"
                    @slidervalue="sliderval"
                  />
              </div>
              <div v-else class="filter-slide-container">
                <span style="width: 100%">
                  <hlx-select
                    v-model="filterprice.units"
                    :inline-search="true"
                    :options="currencyList"
                    :clearable="true"
                    :placeholder-value="'Currency code'"
                    label="char"
                    prop-value="char"
                    @selected-value="currencychange"
                  ></hlx-select>

                  <hlx-min-max-slider
                    v-model:min-value="sliderMin"
                    v-model:max-value="sliderMax"
                    :min="filterdata[i][0].minPrice"
                    :max="filterdata[i][0].maxPrice"
                    @slidervalue="sliderval"
                  />
                </span>
              </div>
            </template>
          </hlx-accordion>
        </span>
      </span>
    </div>
  </div>
</template>
<script>
import { MLTAxiosInstance } from "@/config/axiosConfig";

// import { filter } from 'core-js/core/array';

export default {
  props: {
    clearFiltersTrigger: {
      type: Boolean,
      default: false,
    },
    filterpanelname: {
      type: String,
      default: "",
    },
    module: {
      type: String,
      default: "",
    },
    displayData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    queryValue: {
      type: Object,
      default: () => {
        return {};
      },
    },
    companyName: {
      type: String,
      default: "",
    },
    preValue: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ["filteredData", "selectedData"],
  data() {
    return {
      prePreValue:{},
      symbol:'',
      sliderMax:0,
      sliderMin:0,
      minValue:0,
      maxValue:0,
      relatedParty: [],
      filterPrevalue: {},
      filtertagvalue: [],
      filterdata: {},
      filterselectedcount: {},
      filterdatacount: {},
      offeringprice: [],
      offeringfiltercondition: [],
      company: "",
      filterquery: {
        type: "filter",
        module: "",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {
          // name: -1
        },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      },
      filteredobj: { $in: [] },
      filteredkey: { $in: [] },
      filteredkeyarray: [],
      filteredarray: [],
    };
  },
  watch: {

    clearFiltersTrigger(newVal) {
      if (newVal) {
        this.clearAllFilter();
      }
    },
    
    filterdata:{
      handler(val) {
        console.log("filterdata in filterrrrrrrrrrrrrrr",val)
      },
      immediate: true,
      deep: true,

    },

    displayData: {
      handler(val) {
        console.log("filter123 filter query in filter",val);
        
        if (val) {
          this.filterdata = JSON.parse(JSON.stringify(val));
          console.log(this.filterdata,'miin')
          if(this.filterdata['Product offering price'] && Object.keys(this.filterdata).length != 0){
            this.sliderMin = Math.floor(this.filterdata['Product offering price'][0].minPrice)
            this.sliderMax = Math.round(this.filterdata['Product offering price'][0].maxPrice)
            this.minValue = Math.floor(this.filterdata['Product offering price'][0].minPrice)
            this.maxValue = Math.round(this.filterdata['Product offering price'][0].maxPrice)
          }
          Object.keys(this.filterdata).forEach((e) => {
            let arr = [];
            let arr1 = [];
            if (e != "_id") {
              this.filterdata[e]?.forEach((ele) => {
                if (typeof ele == "object") {
                  if (!ele) return;
                  if (typeof ele.name != "object") {
                    ele.checked = false;
                    ele.key = e;
                    if (arr.includes(ele.name) == false) {
                      arr.push(ele.name);
                      arr1.push(ele);
                    }
                  }
                }
              });
              // console.log(arr1,'kdl',e);
              arr1.forEach((e, index) => {
                let obj = {};
                if (e.name == undefined) {
                  obj = e;
                  arr1.splice(index, 1);
                  let length = arr1.length;
                  arr1[length] = obj;
                  //  arr1.push()
                }
              });
              this.filterdata[e] = arr1;
            }
          });
          console.log(this.filterdata, "filterdata");
        }
        // console.log(this.updatePayloads(this.filterdata, val),'updatepayload')
        if (Object.keys(this.prePreValue).length != 0) {
                    this.filtertagvalue = []
                    this.filterselectedcount= {}
                    this.offeringfiltercondition = []
          this.filterquery['filterQuery'] = {}
          this.updatePayloads(this.filterdata, this.prePreValue)
          // Object.keys(this.filterdata).forEach((e) => {
          //   Object.keys(this.preValue).forEach((ele) => {
          //     if (e.toLowerCase() == ele.toLowerCase()) {
          //       let value = this.preValue[ele][0].toLowerCase();
          //       // console.log(value,'prevll')
          //       this.filterdata[e]?.forEach((element) => {
          //         if (element.name != undefined) {
          //           let data = element.name.toLowerCase();
          //           // console.log(data);
          //           if (data == value) {
          //             element.checked = true;
          //             // console.log(e,element,'prevll')
          //             this.filterCheckedValue(e, element);
          //           }
          //         }
          //       });
          //       console.log(value, "valueeeee");
          //     }
          //   });
          // });
        }


      },
      immediate: true,
      deep: true,
    },
    preValue: {
      handler(val) {
        val;
        console.log(val,this.prePreValue,"aeeeeeeeeeeeeeeeeeeeeeeed.........................")

        // Update local statusList only if the incoming value is different
        if (!this.deepEqual(val, this.prePreValue))  {
          console.log(val,"aeeeeeeeeeeeeeeeeeeeeeeed new.........................")
          this.prePreValue = JSON.parse(JSON.stringify(val));
        


        
        // console.log(this.filterdata, val, this.updatePayloads(this.filterdata, val), "lolool");
        if(Object.keys(val).length != 0)
        {
          this.filterquery['filterQuery'] = {}
          this.filterselectedcount= {}
          this.filtertagvalue = []
          this.offeringfiltercondition = []
          this.updatePayloads(this.filterdata, val)
          // Object.keys(this.filterdata).forEach((e) => {
          //   Object.keys(val).forEach((ele) => {
          //     if (e.toLowerCase() == ele.toLowerCase()) {
          //       let value = val[ele][0].toLowerCase();
          //       console.log(value,'preval')
          //       this.filterdata[e]?.forEach((element) => {
          //         if (element.name != undefined) {
          //           let data = element.name.toLowerCase();
          //           // console.log(data);
          //           if (data == value) {
          //             element.checked = true;
          //             console.log(e,element,'prevll')
          //             this.filterCheckedValue(e, element);
          //           }
          //         }
          //       });
          //       console.log(value, "valueeeee");
          //     }
          //   });
          // });
        }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    const defaultSettings = await MLTAxiosInstance.get(`/settings`);
      console.log(defaultSettings, "mounted");
      this.symbol = defaultSettings.data.currency.symbol
    this.filterquery["company"] = this.companyName;
    this.filterquery["module"] = this.module;
  },
  methods: {

    deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true;

    // Check if both are objects or arrays
    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
      return false;
    }

    // Check if they have the same number of keys
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    // Compare each key recursively
    for (let key of keys1) {
      if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  },
    sliderval(){
      console.log('slider',this.sliderMin,this.sliderMax)
      // let obj = { $in: [] };
      let obj1 = {};
      obj1["$gt"] = this.sliderMin
          obj1["$lt"] = this.sliderMax
          let querykey = this.filterquery["filterQuery"];
          querykey;
          // console.log(this.queryValue[key]);
          querykey["productOfferingPrice.tiers.price.value"] = obj1;
          let obj = {}
          let val = this.symbol +' '+ this.sliderMin+' - '+ this.symbol +' '+this.sliderMax
          if(this.filtertagvalue.length != 0){
            this.filtertagvalue.forEach((ele)=>{
              console.log("ele Silide top",ele)
              if(ele.type == 'slider'){
                ele['name'] = val
                ele['value'] = val
              }
            })
          }else{
            obj['name'] = val
            obj['value'] = val
            obj['type'] = 'slider'
            this.filtertagvalue.push(obj)
          }
          console.log("calllllllllllllll sliderval");
          
          this.$emit("filteredData", this.filtertagvalue, this.filterquery);  


    },
    updatePayloads(mainPayload, selectedPayload) {
      console.log("updatePayloads...",mainPayload, selectedPayload);
      
  // Iterate over the keys in the selectedPayload
  Object.keys(mainPayload).forEach(key => {
    
    // let value = key.toLowerCase()
    let value = key
    console.log("key...",key,selectedPayload,value,selectedPayload[value]);
    if (selectedPayload[value]) {
      console.log("selectedPayload[value]...",selectedPayload[value]);
      
      console.log(mainPayload,selectedPayload,'newPrevalue',value)
      // Iterate over each item in the array corresponding to the key
      mainPayload[key].forEach(item => {
        console.log(selectedPayload[value],item, item.name, 'newPrevalue1')
        // Check if the name in the main payload matches any in the selected payload
        
        if (selectedPayload[value].includes(item.name)) {
          // Add the "checked" property if there's a match
          item.checked = true;
          console.log(key,item,'prevalues');
          this.filterCheckedValue(key, item);
        }else if(this.module === 'quotes' || this.module === 'customerbill'){
          const matchingItem = selectedPayload[value].find(selItem => selItem.name === item.name);

if (matchingItem) {
  // Add the "checked" property if there's a match
  item.checked = matchingItem.checked;
  console.log(key, item, 'prevalues');
  this.filterCheckedValue(key, item);  // Assuming this function exists in your context
}
        }
      });
    }
  });

  return mainPayload;
},
    insertSpaces(string) {
      if (typeof string != "string") return;
      let str = string
        ?.replace(/(_|-)/g, " ")
        .trim()
        .replace(/\w\S*/g, function (str) {
          return str.charAt(0).toUpperCase() + str.substr(1);
        })
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
      return str;
    },
    async filterCheckedValue(key, value) {
      console.log(key, value, "keyValue");
      if (value.checked == true) {
        this.filtertagvalue.push(value)
        if (this.offeringfiltercondition.includes(key) != true) {
          this.offeringfiltercondition.push(key);
          let obj = { $in: [] };
          let arr = [];
          arr.push(value.name);
          obj["$in"] = arr;
          let querykey = this.filterquery["filterQuery"];
          querykey;
          console.log(this.queryValue[key]);
          querykey[this.queryValue[key]] = obj;
          console.log(this.filterquery, "filterkey");
          //  if (key == "Status")
          // {
          //   this.offeringfilterquery["filterQuery"]["status"] = obj;
          // } else if(key == "Contact medium") {
          //   this.offeringfilterquery["filterQuery"]["contactMedium.mediumType"] = obj;
          // }
          console.log("calllllllllllllll filtercheckedvalue");
          this.$emit("filteredData", this.filtertagvalue, this.filterquery);  
          this.$emit("selectedData", this.filtertagvalue);
        } else {
          let arr =
            this.filterquery["filterQuery"][this.queryValue[key]]["$in"];
          arr.push(value.name);
          // if (key == "Status") {
          //   let arr =
          //     this.offeringfilterquery["filterQuery"]["status"][
          //       "$in"
          //     ];
          //   arr.push(value.name);
          //   this.offeringfilterquery["filterQuery"]["status"][
          //     "$in"
          //   ] = arr;
          // } else if(key == "Contact medium") {
          //   let arr =
          //     this.offeringfilterquery["filterQuery"]["contactMedium.mediumType"][
          //       "$in"
          //     ];
          //   arr.push(value.name);
          //   this.offeringfilterquery["filterQuery"]["contactMedium.mediumType"][
          //     "$in"
          //   ] = arr;
          // }
          console.log("calllllllllllllll filtercheckedvalue 222");
          this.$emit("filteredData", this.filtertagvalue, this.filterquery);
          this.$emit("selectedData", this.filtertagvalue);
        }
      } else if (value.checked == false) {
        // ;
        this.filtertagvalue.forEach((e, index) => {
          if (e.name == value.name) {
            e.checked = false;
            this.filtertagvalue.splice(index, 1);
          }
        });





        // Access the filter query safely
let arr, arr1;

try {
    // Check if filterQuery and the necessary keys exist
    if (
        this.filterquery &&
        this.filterquery["filterQuery"] &&
        this.queryValue[key] &&
        this.filterquery["filterQuery"][this.queryValue[key]] &&
        this.filterquery["filterQuery"][this.queryValue[key]]["$in"]
    ) {
        arr = this.filterquery["filterQuery"][this.queryValue[key]]["$in"];
        arr1 = this.filterquery["filterQuery"][this.queryValue[key]]["$in"];
    } else {
        // Handle the case where the data is not structured as expected
        console.warn(`Data structure is not valid for key: ${this.queryValue[key]}`);
        arr = []; // Or some default value
        arr1 = []; // Or some default value
    }
} catch (error) {
    console.error("An error occurred while accessing the filter query:", error);
    // Optionally set arr and arr1 to a default value
    arr = [];
    arr1 = [];
}





        arr.forEach((ele, col_index) => {
          if (value.name == ele) {
            arr1.splice(col_index, 1);
          }
        });
        if (arr1.length != 0) {
          this.filterquery["filterQuery"][this.queryValue[key]]["$in"] = arr1;
        } else {
          delete this.filterquery["filterQuery"][this.queryValue[key]];
          this.offeringfiltercondition.forEach((e, offeringindex) => {
            if (e == key) {
              this.offeringfiltercondition.splice(offeringindex, 1);
            }
          });
        }

        //    if (key == "Status") {
        //     let arr =
        //       this.offeringfilterquery["filterQuery"]["status"][
        //         "$in"
        //       ];
        //     let arr1 =
        //       this.offeringfilterquery["filterQuery"]["status"][
        //         "$in"
        //       ];
        //     arr.forEach((ele, col_index) => {
        //       if (value.name == ele) {
        //         arr1.splice(col_index, 1);
        //       }
        //     });
        //     if (arr1.length != 0) {
        //       this.offeringfilterquery["filterQuery"]["status"][
        //         "$in"
        //       ] = arr1;
        //     } else {
        //       delete this.offeringfilterquery["filterQuery"][
        //         "status"
        //       ];
        //       this.offeringfiltercondition.forEach((e, offeringindex) => {
        //         if (e == key) {
        //           this.offeringfiltercondition.splice(offeringindex, 1);
        //         }
        //       });
        //     }
        //   } else if (key == "Contact medium") {
        //      let arr =
        //       this.offeringfilterquery["filterQuery"]["contactMedium.mediumType"][
        //         "$in"
        //       ];
        //     let arr1 =
        //       this.offeringfilterquery["filterQuery"]["contactMedium.mediumType"][
        //         "$in"
        //       ];
        //     arr.forEach((ele, col_index) => {
        //       if (value.name == ele) {
        //         arr1.splice(col_index, 1);
        //       }
        //     });
        //     if (arr1.length != 0) {
        //       this.offeringfilterquery["filterQuery"]["contactMedium.mediumType"][
        //         "$in"
        //       ] = arr1;
        //     } else {
        //       delete this.offeringfilterquery["filterQuery"][
        //         "contactMedium.mediumType"
        //       ];
        //       this.offeringfiltercondition.forEach((e, offeringindex) => {
        //         if (e == key) {
        //           this.offeringfiltercondition.splice(offeringindex, 1);
        //         }
        //       });
        //     }
        //   }
          console.log("calllllllllllllll filtercheckedvalue 333");
        this.$emit("filteredData", this.filtertagvalue, this.filterquery);
        this.$emit("selectedData", this.filtertagvalue);
      }
      // ;
      if (value.checked == true) {
        if (this.filterselectedcount[value.key] == undefined) {
          this.filterselectedcount[value.key] = 1;
        } else {
          let countvalue = this.filterselectedcount[value.key];
          countvalue = countvalue + 1;
          this.filterselectedcount[value.key] = countvalue;
        }
      } else {
        let countvalue = this.filterselectedcount[value.key];
        countvalue = countvalue - 1;
        if (countvalue == 0) {
          delete this.filterselectedcount[value.key];
        } else {
          this.filterselectedcount[value.key] = countvalue;
        }
      }
    },
    async removedFilterData(value) {
      if(value.type == 'slider'){
        this.filtertagvalue.forEach((e, index) => {
          if(e.type == 'slider'){
            this.filtertagvalue.splice(index, 1);
          }
        })
        delete this.filterquery["filterQuery"]["productOfferingPrice.tiers.price.value"]
        if(this.filterdata['Product offering price'] && Object.keys(this.filterdata).length != 0){
          this.sliderMin =   Math.floor(this.filterdata['Product offering price'][0].minPrice)
            this.sliderMax = Math.round(this.filterdata['Product offering price'][0].maxPrice)
            this.minValue = Math.floor(this.filterdata['Product offering price'][0].minPrice)
            this.maxValue = Math.round(this.filterdata['Product offering price'][0].maxPrice)
          }
      }else{
      this.filtertagvalue.forEach((e, index) => {
        if (e.name == value.name && e.key == value.key) {
          e.checked = false;
          this.filtertagvalue.splice(index, 1);
          let arr =
            this.filterquery["filterQuery"][this.queryValue[value.key]]["$in"];
          let arr1 =
            this.filterquery["filterQuery"][this.queryValue[value.key]]["$in"];

          arr.forEach((ele, col_index) => {
            if (e.name == ele) {
              arr1.splice(col_index, 1);
            }
          });

          if (arr1.length != 0) {
            this.filterquery["filterQuery"][this.queryValue[value.key]]["$in"] =
              arr1;
          } else {
            delete this.filterquery["filterQuery"][this.queryValue[value.key]];
            this.offeringfiltercondition.forEach((e, offeringindex) => {
              if (e == value.key) {
                this.offeringfiltercondition.splice(offeringindex, 1);
              }
            });
          }
        }
      });
    }
    console.log("calllllllllllllll removedfilterdata");
      this.$emit("filteredData", this.filtertagvalue, this.filterquery);
      this.$emit("selectedData", this.filtertagvalue);
      let countvalue = this.filterselectedcount[value.key];
      countvalue = countvalue - 1;
      if (countvalue == 0) {
        delete this.filterselectedcount[value.key];
      } else {
        this.filterselectedcount[value.key] = countvalue;
      }
    },
    clearAllFilter() {
      Object.keys(this.filterdata).forEach((e) => {
        this.filterdata[e]?.forEach((ele) => {
          if (typeof ele == "object") {
            if (!ele) return;

            ele.key = e;
            ele.checked = false;
          }
        });
      });
      if(this.filterdata['Product offering price'] && Object.keys(this.filterdata).length != 0){
            this.sliderMin = Math.floor(this.filterdata['Product offering price'][0].minPrice)
            this.sliderMax = Math.round(this.filterdata['Product offering price'][0].maxPrice)
            this.minValue = Math.floor(this.filterdata['Product offering price'][0].minPrice)
            this.maxValue = Math.round(this.filterdata['Product offering price'][0].maxPrice)
          }
      this.filtertagvalue = [];
      this.offeringfiltercondition = [];
      this.filterquery["filterQuery"] = {};
      this.filterselectedcount = {};
      console.log("calllllllllllllll claerallfilter");
      this.$emit("filteredData", this.filtertagvalue, this.filterquery);
    },
  },
};
</script>
