<template>
    <div class="cm-accounts-overview-component">
        <img src="@/assets/images/addAccountOverview.svg" alt="" style="width: 400px">
    </div>
</template>

<script>

export default {
    props: {

    },
    data() {
        return {
            
        }
    }
}
</script>