<template>
  <!-- {{ currPage }} {{ rowsPerPage }} -->
  <hlx-alert-notification
    :show-alert="alertmsg"
    :notify="notification"
    :auto-close="true"
  />
  <div class="add-supplier-table">
    <hlx-modal
      :key="modalcomponetkey" 
      :modal-active="modal_active"
      :height="'640px'"
      :width="'750px'"
      :modal="true"
      :close-icon="true"
      :outer-layer-close-option="false"
      @close="closeAddNewSupplierModal"
    >
      <template #header>
        <div>Add Suppiler</div>
      </template>
      <template #content>
        <div
          class="modal-content"
          style="display: flex; gap: 5%; height: 370px !important"
        >
          <div style="display: flex; flex-direction: column; width: 50%">
            <div
              style="
                display: flex;
                justify-content: space-between;
                gap: 10px;
                margin-bottom: 20px;
              "
            >
              <span style="">
                <hlx-file-upload
                  :file-type="'.jpg,.jpeg,.png'"
                  :file-size-limit="'20mb'"
                  :uploadtype="'Rectangle'"
                  :style-background="backgrounStyle"
                  :height="100"
                  :width="100"
                  :read-only="false"
                  :custom-icon="icon"
                  :type="'profile-upload'"
                  :name-display="newSupplierPayload.customName"
                  :content="'Import'"
                  @native-data="profileUpload"
                />
              </span>
              <span style="width: 100%; height: 114px">
                <div class="decision-name-wrapper">
                  <!-- :style="{ 'margin-bottom': customnameerror ? '-7.6%' : 0 }" -->
                  <hlx-input
                    v-model:value="newSupplierPayload.customName"
                    :pre-val="newSupplierPayload.customName"
                    :label-value="'Name'"
                    :label-animation="true"
                    :required="true"
                    :display-error="true"
                    :custom-error="isNameEmpty"
                    :custom-error-message="'Please fill the field'"
                    @focus-out="focusOutName"
                    @at-input="validateName"
                  ></hlx-input>
                </div>
                <!-- <div class="decision-name-wrapper"> -->
                <!-- <hlx-select
                  v-model="newSupplierPayload.status"
                  :pre-value="newSupplierPayload.status"
                  :options="statusList"
                  :label="'name'"
                  :prop-value="'name'"
                  :placeholder-value="'Status'"
                  :label-animation="true"
                  :clearable="false"
                  @selected-value="statusFunc"
                ></hlx-select> -->

                <div style="width: 100%">
                  <hlx-status
                    :type="'select-status'"
                    :status-option="filteredstatus"
                    :prevalue="newSupplierPayload.status"
                    @emit-select="emitStatus1"
                  >
                  </hlx-status>
                </div>

                <!-- </div> -->
              </span>
            </div>

            <div class="decision-name-wrapper" style="margin-bottom: 20px">
              <addressComponent
                :required="true"
                :key="addressKey"
                @address-data="receivedAddressData"
              ></addressComponent>
            </div>

            <div class="decision-name-wrapper">
              <hlx-input
                v-model:value="newSupplierPayload.externalReference[1].leadTime"
                :label-animation="true"
                label-value="Lead time"
                type="mixed-number"
                :display-error="true"
                :custom-error="isNameEmpty"
                :custom-error-message="'Please fill the field'"
                :suffix="false"
                :prefix="false"
                :suffixselect="true"
                @focus-out="focusOutName"
                @at-input="validateName"
              >
                <template #suffix-select>
                  <hlx-select
                    v-model:value="
                      newSupplierPayload.externalReference[1].leadPeriod
                    "
                    :pre-value="'Days'"
                    :options="leadTimeOption"
                    :inline-search="true"
                    :border="false"
                    :placeholder-value="'Days'"
                    :label-animation="false"
                    :label="'Name'"
                    :prop-value="'Name'"
                    :background-color="'#f9fafb'"
                    @inline-select-value="suffixed"
                  >
                  </hlx-select>
                </template>
              </hlx-input>
            </div>
            <div class="decision-name-wrapper">
              <hlx-select
                v-model:value="newSupplierPayload.paymentTerms[0].name"
                :pre-value="newSupplierPayload.paymentTerms[0].name"
                :options="paymentList"
                prop-value="name"
                :placeholder-value="'Payment terms'"
                :label-animation="true"
                :clearable="true"
              ></hlx-select>
            </div>
          </div>
          <div style="display: flex; flex-direction: column; width: 50%">
            <div class="decision-name-wrapper" style="margin-bottom: 25px">
              <hlx-input
                v-model:value="newSupplierPayload.description"
                :pre-val="newSupplierPayload.description"
                type="textarea"
                :label-value="'Description'"
                :label-animation="true"
                :rows="11"
                :required="false"
                :display-error="false"
                :custom-error="customnameerror"
                :custom-error-message="''"
                @focus-out="focusOutName"
              ></hlx-input>
            </div>
            <div class="decision-name-wrapper">
              <hlx-input
                v-model:value="
                  newSupplierPayload.contactMedium[0].characteristic
                    .emailAddress
                "
                :pre-val="
                  newSupplierPayload.contactMedium[0].characteristic
                    .emailAddress
                "
                :label-value="'Email id'"
                :label-animation="true"
                type="email"
                :required="true"
                :display-error="true"
                :custom-error="isEmailEmpty"
                :custom-error-message="'Enter a valid email.(eg: user@example.com)'"
              ></hlx-input>
            </div>
            <div class="decision-name-wrapper">
              <hlx-input
                v-model:value="
                  newSupplierPayload.contactMedium[1].characteristic.phoneNumber
                "
                :pre-val="
                  newSupplierPayload.contactMedium[1].characteristic.phoneNumber
                    .value
                "
                type="phonenumber"
                :country-code="
                  newSupplierPayload.contactMedium[1].characteristic.phoneNumber
                    .country
                "
                :label-value="'Phone no.'"
                :label-animation="true"
                :display-error="true"
                :custom-error="customnameerror"
                :custom-error-message="'Please fill the field'"
                @focus-out="focusOutName"
                @at-input="validatePhoneName"
              ></hlx-input>
            </div>
            <div class="decision-name-wrapper">
              <hlx-input
                v-model:value="newSupplierPayload.externalReference[0].name"
                :pre-val="newSupplierPayload.externalReference[0].name"
                :label-animation="true"
                label-value="Website URL"
                type="text"
                :clearable="true"
                name="username"
                :display-error="true"
                :custom-error="isUrlEmpty"
                :custom-error-message="'Enter a valid webside.(eg: www.example.com)'"
                @focus-out="websiteRegex"
                @blur="validateInput"
              >
              </hlx-input>
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                gap: 25%;
                height: 155px;
              "
            >
              <div class="decision-name-wrapper">
                <hlx-multi-select
                  :key="tagcomponetkey"
                  v-model="newSupplierPayload.tags"
                  :pre-value="newSupplierPayload.tags"
                  :options="tagsList"
                  prop-value="name"
                  :multi-select="true"
                  :placeholder="'Tags'"
                  :tooltipposition="'left'"
                  :label-animation="true"
                  :clearable="true"
                  @add-data="addTagList"
                  @selected-array="receivedTags"
                ></hlx-multi-select>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div>
          <hlx-button
            class="secondary-success sm"
            style="padding: 10px 38px; margin-right: 15px"
            @click="closeAddNewSupplierModal"
            >Cancel</hlx-button
          >
          <hlx-button
            class="primary sm"
            style="padding: 10px 38px"
            :disabled="!canAdd"
            @click="AddNewSupplierModal()"
            >Add</hlx-button
          >
        </div>
      </template>
    </hlx-modal>
  </div>
  <section class="cm-accounts-landing-layout">
    <section class="cm-accounts-right-panel-container">
      <div
        class="cm-accounts-right-panel-top-section"
        style="overflow-x: hidden; overflow-y: auto"
      >
        <div
          class="cm-accounts-right-panel-top-section-title"
          style="margin-bottom: 8px"
        >
          Supplier
        </div>
        <div class="cm-accounts-right-panel-top-section-description">
          Collaborative network, diverse suppliers, ensuring agility, scale, and
          competitive solutions.
        </div>
        <div
          v-if="tableData.length > 0 || searchvalue !== ''"
          class="cm-accounts-right-panel-top-section-utility-container"
          style="padding: 0.8rem 0px; gap: 12px !important"
        >
          <hlx-search @search-key="searchKey"></hlx-search>
          <span class="filter-table">
            <hlx-button class="secondary sm add-btn" @click="showDrawerRight">
              <i class="icon-filter-regular"></i>
              <span v-if="filtertaglength != 0" class="filter-count">{{
                filtertaglength
              }}</span>
            </hlx-button>
          </span>

          <!--drawer component put outside the screen-->
          <hlx-drawer
            :show="show_right_filter"
            :width="300"
            position="right"
            :header="false"
            :footer="false"
            :show-close-icon="true"
            @close="closeRightFilter"
          >
            <template #body>
              <div style="display: flex">
                <FilterComponent
                  :filterpanelname="'supplier'"
                  :company-name="company"
                  :query-value="query"
                  :display-data="filter"
                  :module="'suppliers'"
                  @filtered-data="supplierfilterData"
                ></FilterComponent>
              </div>
            </template>
          </hlx-drawer>

          <!--search key-->

          <hlx-button v-if="permisstionData.add " class="primary sm" @click="addNewAccount">
            <i class="icon-plus-circle-regular"></i> Add supplier
          </hlx-button>
        </div>
        <div v-if="loaderApi === true">
          <hlx-loader load-type="dotted-jumper" />
        </div>
        <div v-if="loaderApi === false">
          <div
            v-if="tableData.length > 0"
            class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
          >
            <hlx-table
              :column-count="accounts_table_theads.length + 2"
              :border="['table', 'header', 'horizontal']"
              :row-hover="true"
              theme="grey"
              :re-arrange-columns="toggleRearrangeModal"
              :re-arrange-heads="accounts_table_theads_cc"
              @close-rearrange="toggleRearrangeModal =false"
              @apply-rearranged-columns="applyColumns"
            >
              <template #thead>
                <hlx-table-head
                  :width="60"
                  :align="'center'"
                  style="padding: 0 0"
                  :fixed="'left'"
                    @new-resize-width="resizedWidth"
                  >{{ "S.no" }}</hlx-table-head
                >
                <hlx-table-head
                  v-for="(i, index) in accounts_table_theads"
                  :key="index"
                  :prop="i.prop"
                  :sortable="i.sortable"
                  :resizable="i.resizable"
                  :width="i.width !== undefined ? i.width : ''"
                  :align="i.align !== undefined ? i.align : ''"
                  @sorting_func="sorting_Data"
                  @new-resize-width="resizedWidth"
                >
                  {{ i.label }}</hlx-table-head
                >
                <hlx-table-head
                v-if="permisstionData.edit || permisstionData.delete"
                  v-show="editMode == true"
                  :align="'center'"
                  :width="70"
                  :fixed="'right'"
                  @new-resize-width="resizedWidth"
                  ><span class="action-icon">
                    <i
                    class="icon-settings-regular"
                    style="cursor:pointer"
                    @click="reAssignOrgTableHeader()"
                    ></i> </span
                ></hlx-table-head>
              </template>
              <template #tbody>
                <tr v-for="(i, index) in paginatedData" id="" :key="index">
                  <hlx-table-cell :align="'center'" :fixed="'left'">
                    {{ serialNumber(index) }}
                  </hlx-table-cell>
                  <hlx-table-cell
                    v-for="(j, col_index) in accounts_table_theads"
                    :key="col_index"
                    :align="j.align"
                    :fixed="j.fixed"
                  >
                    <div
                      v-if="j.prop.toLowerCase() === '_id'"
                      :id="j.prop + index"
                      class="link-name"
                      @click="viewPage(i)"
                    >
                      {{ i[j.prop] }}
                    </div>

                    <div
                      v-if="j.prop.toLowerCase() === 'tradingname'"
                      :id="j.prop + index"
                      class="link-name"
                      @click="viewPage(i)"
                    >
                      <span v-if="i.customName">
                        {{ i.customName }}
                      </span>
                      <span v-else>
                        {{ i[j.prop] }}
                      </span>
                    </div>
                    <div v-else-if="j.prop.toLowerCase() === 'status'">
                      <span v-if="i[j.prop] != ''">
                        <div>
                          <hlx-status :type="i[j.prop]"></hlx-status>
                        </div>
                      </span>
                      <span v-else>
                        {{ "-" }}
                      </span>
                    </div>
                    <div
                      v-if="
                        j.prop.toLowerCase() === 'address' && i['contactMedium']
                      "
                    >
                      <span v-if="i['contactMedium'][3]">
                        <span
                          v-if="
                            i['contactMedium'][3].characteristic?.description !=
                            ''
                          "
                        >
                          {{
                            i["contactMedium"][3].characteristic?.description
                          }}
                        </span>
                        <span v-else>
                          {{ "-" }}
                        </span>
                      </span>
                    </div>
                    <div
                      v-else-if="j.prop.toLowerCase() === 'relatedparty'"
                      style="
                        margin: 0.5rem 0;
                        text-align: center;
                        margin-left: -30px;
                      "
                    >
                      <span v-if="i[j.prop] != undefined">
                        {{ i[j.prop].length }}
                      </span>
                    </div>
                    <div
                      v-else-if="j.prop.toLowerCase() === 'products'"
                      style="
                        margin: 0.5rem 0;
                        text-align: center;
                        margin-left: -30px;
                      "
                    >
                      {{ "-" }}
                    </div>
                    <!-- <div v-else>{{ i[j.prop] }}</div> -->
                  </hlx-table-cell>
                  <hlx-table-cell
                  v-if="permisstionData.edit || permisstionData.delete"
                    v-show="editMode === true"
                    :align="'center'"
                    :width="120"
                    :fixed="'right'"
                  >
                    <span  :id="'table-context' + index" class="action-icon">
                      <i
                        style="position: relative"
                        class="icon-more-vertical-filled"
                        :class="i.context === true ? 'active-action' : ''"
                        @click="crudContextMenu($event, index)"
                      >
                        <hlx-context-menu
                          :top="containertop"
                          :left="containerleft"
                          :conditionvalue="150"
                          :conditiondown="2"
                          :conditionup="-38"
                          :options="computedTableAction(i)"
                          :data="index.toString()"
                          :show="i.context == true"
                          @chosen="closeCrudContextMenu($event, i, index)"
                        />
                      </i>
                    </span>
                  </hlx-table-cell>
                </tr>
              </template>
            </hlx-table>
          </div>
          <div
            v-if="tableData.length === 0 && searchvalue !== ''"
            class="cm-accounts-right-panel-top-section-table-wrap"
          >
            <hlx-table
              :column-count="accounts_table_theads.length + 2"
              :border="['table', 'header']"
              :bold-headers="false"
              :row-hover="true"
              theme="grey"
              :striped-rows="false"
              :re-arrange-columns="toggleRearrangeModal"
              :re-arrange-heads="theads"
              @close-rearrange="toggleRearrangeModal=false"
              @apply-rearranged-columns="applyColumns"
            >
              <template #thead>
                <hlx-table-head :width="60" :align="'left'" @new-resize-width="resizedWidth">{{
                  "S.no"
                }}</hlx-table-head>
                <hlx-table-head
                  v-for="(i, index) in accounts_table_theads"
                  :key="index"
                  :prop="i.prop"
                  :sortable="i.sortable"
                  :resizable="i.resizable"
                  style="position: sticky; top: 0px"
                  :width="i.width !== undefined ? i.width : ''"
                  :align="i.align !== undefined ? i.align : ''"
                  @sorting_func="sorting_Data"
                  @new-resize-width="resizedWidth"
                >
                  {{ i.label }}
                </hlx-table-head>
                <hlx-table-head v-if="permisstionData.edit || permisstionData.delete" :width="100" @new-resize-width="resizedWidth">
                  <span class="action-icon"
                    ><i
                      class="icon-settings-regular"
                     @click="reAssignOrgTableHeader()"
                    ></i></span
                ></hlx-table-head>
              </template>
              <template #tbody>
                <tr>
                  <hlx-table-cell></hlx-table-cell>
                </tr>
                <tr>
                  <hlx-table-cell></hlx-table-cell>
                </tr>
                <tr>
                  <hlx-table-cell></hlx-table-cell>
                </tr>
                <tr>
                  <hlx-table-cell></hlx-table-cell>
                </tr>
                <tr>
                  <hlx-table-cell :colspan="accounts_table_theads.length + 2">{{
                    "No data"
                  }}</hlx-table-cell>
                </tr>
                <tr>
                  <hlx-table-cell></hlx-table-cell>
                </tr>
                <tr>
                  <hlx-table-cell></hlx-table-cell>
                </tr>
                <tr>
                  <hlx-table-cell></hlx-table-cell>
                </tr>
                <tr>
                  <hlx-table-cell></hlx-table-cell>
                </tr>
                <tr>
                  <hlx-table-cell></hlx-table-cell>
                </tr>
              </template>
            </hlx-table>
          </div>
          <div
            v-if="tableData.length <= 0 && searchvalue === ''"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              height: 90vh;
              flex-direction: column;
              gap: 40px;
            "
          >
            <img
              class="temp-Product-illustration"
              src="@/assets/images/suppliermaintable.svg"
              alt="Product image"
              srcset=""
            />
            <span
              ><hlx-button class="primary sm" @click="addNewAccount">
                <i class="icon-plus-circle-regular"></i>
                Add Supplier
              </hlx-button></span
            >
          </div>
        </div>
      </div>
      <div
        v-if="tableData.length > 0"
        class="cm-accounts-right-panel-pagination-container"
      >
        <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
          :total="totalPageNo"
          rows-per-page
          enable-rows-per-page
          :rows-per-page-list="[10, 15, 20]"
          :options="{
            attributes: {
              rowsPerPageTitle: true,
              rowsPerPageResult: true,
              rowsPerPageDropdown: {
                position: {
                  top: 0,
                  right: 1,
                },
              },
            },
          }"
          @current-page="currentPage"
          @updated:rows-per-page="changeRowsPerPage"
        ></hlx-pagination>
      </div>
    </section>
  </section>

  <hlx-modal
    :modal-active="showAddNewAdrressModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'400px'"
    :width="'650px'"
    :modal="true"
    @close="closeAddNewAdrressModal"
  >
    <template #header>
      <div>Add new address</div>
    </template>
    <template #content>
      <div
        class="modal-content add-contact-modal"
        style="
          padding: 20px;
          padding-bottom: 0;
          grid-template-columns: 38.5% 58.5%;
        "
      >
        <span class="left-section">
          <hlx-input
            v-model:value="newAddressPayload.streetNumber"
            :pre-val="newAddressPayload.streetNumber"
            :label-value="'Street number'"
            :label-animation="true"
            :required="true"
            :display-error="true"
            :custom-error="isFirstNameEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            v-model:value="newAddressPayload.unitNumber"
            :pre-val="newAddressPayload.unitNumber"
            :label-value="'Unit number'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isLastNameEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <div class="input-wrapper">
            <hlx-input
              :key="key"
              v-model:value="newAddressPayload.city"
              :pre-val="newAddressPayload.city"
              :options="designationList"
              :required="true"
              prop-value="name"
              :label-value="'City'"
              :placeholder-value="'City'"
              :label-animation="true"
              :clearable="true"
            ></hlx-input>
          </div>
          <div class="input-wrapper">
            <hlx-input
              v-model:value="newAddressPayload.postalCode"
              :pre-val="newAddressPayload.postalCode"
              :label-value="'Postal code'"
              :label-animation="true"
              :required="true"
              :display-error="true"
              :custom-error="isLastNameEmpty"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
        </span>
        <span class="right-section">
          <hlx-input
            v-model:value="newAddressPayload.streetName"
            :pre-val="newAddressPayload.streetName"
            :label-value="'Street name'"
            :label-animation="true"
            :required="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            v-model:value="newAddressPayload.unitName"
            :pre-val="newAddressPayload.unitName"
            :label-value="'Unit name'"
            :label-animation="true"
            :required="false"
            :display-error="true"
            :custom-error="customnameerror"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <div class="input-wrapper">
            <div style="display: flex; gap: 0.5rem">
              <hlx-input
                :key="key"
                v-model:value="newAddressPayload.state"
                :pre-val="newAddressPayload.state"
                :options="preferredList"
                :label-value="'State'"
                prop-value="value"
                :placeholder-value="'State'"
                :label-animation="true"
                :required="true"
                :clearable="true"
              ></hlx-input>
              <hlx-input
                :key="key"
                v-model:value="newAddressPayload.country"
                :pre-val="newAddressPayload.country"
                :options="preferredList"
                prop-value="value"
                :label-value="'Country'"
                :placeholder-value="'Country'"
                :required="true"
                :label-animation="true"
                :clearable="true"
              ></hlx-input>
            </div>
          </div>
          <hlx-input
            v-model:value="newAddressPayload.landmark"
            :pre-val="newAddressPayload.landmark"
            :label-value="'Nearby landmark'"
            :label-animation="true"
          ></hlx-input>
        </span>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="closeAddNewAdrressModal"
        >cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        :disabled="
          newAddressPayload.city === '' ||
          newAddressPayload.country === '' ||
          newAddressPayload.state === '' ||
          newAddressPayload.postalCode === '' ||
          newAddressPayload.streetNumber === '' ||
          newAddressPayload.streetName === ''
        "
        @click="addNewAddress"
        >Add</hlx-button
      >
    </template>
  </hlx-modal>
</template>

<script>
import {
  DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
// import paymentViewVue from '../paymentView.vue';
//   import FilterComponent from '../../../components/filterComponent.vue';
// import { mapGetters } from "vuex";
import addressComponent from "@/components/CM/autocompleteComponent.vue";
import FilterComponent from "@/components/filterPanelComponent.vue";
export default {
  components: {
    FilterComponent,
    addressComponent,
  },
  data() {
    return {
      permisstionData:{},

      tableHeaderOrg:[],
      tableHeaderOrgcc:[],
      // searchvalue:'',
      modalcomponetkey: 0,
      query: {
        Status: ["status"],
        "Contact medium": ["contactMedium.mediumType"],
      },
      filtertaglength: 0,
      filter: {},

      filtertagvalue: [],
      // company:'',
      filterQuery: {
        type: "filter",
        module: "suppliers",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {
          // "@baseType":"supplier"
        },
        searchQuery: "",
        company: "",
      },
      filteredstatus: [],
      addressKey: 0,
      notification: {},
      alertmsg: "",
      backgrounStyle: {
        backgroundColor: "",
        bacgroundtTextColor: "#d8d8d8",
        border: false,
        borderColor: "#d8d8d8",
        TextfontSize: 34,
      },
      loaderApi: true,
      dropdown_data: [],
      tagsList: [],
      modalKey: 0,
      newSupplierPayload: {
        attachment: [],
        tradingName: "",
        customName: "",
        name: "",
        status: "Active",
        preferred: "",
        shippingMethod: [
          {
            name: "",
          },
        ],
        paymentTerms: [
          {
            name: "",
            id: "",
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
          {
            "@type": "leadTime",
            leadTime: 1,
            leadPeriod: "Days",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              address1: "",
              default: true,
              "@type": "",
            },
          },
        ],

        description: "",
        tags: [],

        notes: [],
        role: "Root admin",
        type: "company",
        password: "",
        email: "",
        familyName: "",
        givenName: "",
        companyName: "",
        parentCompany: "",
        rootName: "",
        "@baseType": "supplier",
      },
      canAdd: false,
      newAddressPayload: {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      },
      showAddNewAdrressModal: false,
      SupplierStatusList: [],
      ShipingList: [],
      paymentList: [],
      totalPageNo: 0,
      SpecificationcurrPage: 1,
      SpecificationrowsPerPage: 10,
      Supplierfilterquery: {
        type: "search",
        module: "supplierManagement",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      },
      userInfo: this.$store.state.userInfo,
      searchvalue: "",
      status: "Active",
      isNameEmpty: false,
      isEmailEmpty: false,
      isUrlEmpty: false,
      delete_modal_active: false,
      externalRowsPerPage: 10,
      locationApi: "https://appbackend-ghubgwxrcq-pd.a.run.app/autocomplete",
      tableData: [
        // {
        //   id: "732158",
        //   name: "GlobalTech Supplies",
        //   address: "123 Main Street Suite 456 Toronto,ON M5V 2B8, Canada.",
        //   contacts: 25,
        //   products: 5,
        // },
        // {
        //   id: "495673",
        //   name: "EcoSolutions Distributors",
        //   address: "456 Maple Avenue Apt 789 Vancouver,BC V6E 1X5, Canada.",
        //   contacts: 15,
        //   products: 54,
        // },
        // {
        //   id: "821049",
        //   name: "Precision Parts Ltd.",
        //   address: "789 Oak Street Unit 101 Montreal,QC H3A 2R6 Canada.",
        //   contacts: 6,
        //   products: 37,
        // },
      ],
      statusList: [],
      accounts_table_theads: [
        // {
        //   sortable: false,
        //   resizable: true,
        //   width: 100,
        //   label: "ID",
        //   prop: "_id",
        //   align: "left",
        // //},
        {
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: null,
          label: "Name",
          name:"Name",
          prop: "tradingName",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          disabled:false,
          width: 100,
          name:"Status",
          label: "Status",
          prop: "status",
          align: "left",
        },

        {
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: 820,
          name:"Address",
          label: "Address",
          prop: "address",
          align: "left",
        },
        // {
        //   sortable: false,
        //   resizable: true,
        //   width: 100,
        //   label: "Products",
        //   prop: "products",
        //   align: "center",
        // },
        {
          checked:true,
          sortable: false,
          resizable: true,
          disabled:false,
          width: 100,
          name:"Contacts",
          label: "Contacts",
          prop: "relatedParty",
          align: "center",
        },
      ],
      show_right_filter: false,
      showNewAddressOption: false,
      accounts_table_theads_cc: [
      {
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: null,
          label: "Name",
          name:"Name",
          prop: "tradingName",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          disabled:false,
          width: 100,
          name:"Status",
          label: "Status",
          prop: "status",
          align: "left",
        },

        {
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: 820,
          name:"Address",
          label: "Address",
          prop: "address",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          disabled:false,
          width: 100,
          name:"Contacts",
          label: "Contacts",
          prop: "relatedParty",
          align: "center",
        },
      ],
      leadTimeOption: [],
      locationData: [],
      duplicateTheads: [],
      toggleRearrangeModal: false,
      currPage: 1,
      rowsPerPage: 10,
      editMode: true,
      filterPanelName: "productSpecification",
      modal_active: false,
      company: "",
      userId: "",
      disableButtonAdd: true,
      tagcomponetkey: 0,
    };
  },
  computed: {
    paginatedData() {
      return this.tableData;
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.tableData.length;
    },
    searchData() {
      return this.data_list.filter((item) =>
        item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
  },
  watch: {
    "$store.getters.defaultSetting": {
      handler(val) {
        this.newSupplierPayload.contactMedium[1].characteristic.phoneNumber.country.name =
          val?.country;
        this.newSupplierPayload.contactMedium[1].characteristic.phoneNumber.country.short =
          val?.cc2;
        this.newSupplierPayload.contactMedium[1].characteristic.phoneNumber.country.flags =
          val?.flags?.svg;
        console.log(val, "defaultSetting");
      },
      immediate: true,
      deep: true,
    },
    statusList: {
      handler(val) {
        console.log(val, "statuswatching000000000000");
        const filteredstatuslist = this.statusList.filter(
          (item) => item.name === "Active" || item.name === "Inactive"
        );
        this.filteredstatus = filteredstatuslist;
        console.log("filtered11111111111111111", this.filteredstatus);
      },
      immediate: true,
      deep: true,
    },
    isEmailEmpty: {
      handler(val) {
        console.log(val), "jodkvn";
      },
      immediate: true,
      deep: true,
    },
    loaderApi: {
      handler(val) {
        console.log(val);
      },
      immediate: true,
      deep: true,
    },
    newSupplierPayload: {
      handler(val) {
        console.log(val.customName, "customName");

        val.tradingName = this.sanitizeDatabaseName(val.customName);
        val.familyName = this.sanitizeDatabaseName(val.customName);
        val.givenName = this.sanitizeDatabaseName(val.customName);
        val.companyName = this.sanitizeDatabaseName(val.customName);
        val.name = this.sanitizeDatabaseName(val.customName);
        val.email = val.contactMedium[0].characteristic.emailAddress;
        val.parentCompany = this.company;
        val.rootName = this.company;
        this.websiteRegex(val.externalReference[0].name);
        // console.log(val.name.length,this.validateEmail(val.contactMedium[0].characteristic.emailAddress),this.validatePhoneNumber(val.contactMedium[1].characteristic.phoneNumber),!this.isUrlEmpty,"!this.isUrlEmpty!this.isUrlEmpty!this.isUrlEmpty");
        if (
          val.name !== undefined &&
          val.name.length > 0 &&
          this.validateAddress(this.newSupplierPayload.contactMedium[3]) &&
          this.validateEmail(
            val.contactMedium[0].characteristic.emailAddress
          ) &&
          this.validatePhoneNumber(
            val.contactMedium[1].characteristic.phoneNumber
          ) &&
          !this.isUrlEmpty
        ) {
          this.canAdd = true;
        } else {
          this.canAdd = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");

let sampleTheadsd = await MLTAxiosInstance.get(
  `partyRole/get/${val.data.userId}`
);

const mergedPayload = sampleTheadsd.data.permission;
console.log(mergedPayload,"mergedPayload");


this.permisstionData =
  mergedPayload.SM;


    // Tog get table header
    let accountTableHeads = await MLTAxiosInstance.get(`/settings`);

if (accountTableHeads?.data?.entityRef?.["supplier"]) {
  let accountTableHeadModal = accountTableHeads.data.entityRef["supplier"];
  let accountTableHeadsOrg = accountTableHeads.data.entityRef["supplier"].filter(ele => ele.checked);
  console.log(accountTableHeadsOrg,"accountTableHeadModal",accountTableHeadModal)

  if (
    accountTableHeadModal.length === this.accounts_table_theads_cc.length 
  ) {
    console.log(accountTableHeadsOrg,"accountTableHeadModal dup",accountTableHeadModal)
    this.accounts_table_theads_cc = JSON.parse(JSON.stringify(accountTableHeadModal));
      this.accounts_table_theads = JSON.parse(JSON.stringify(accountTableHeadsOrg));
      this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.accounts_table_theads_cc ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.accounts_table_theads))
  }
}
    // const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    // console.log(val,"acces");
    this.company = val.data.company;
    this.userId = val.data.userId;
    const filterData = {
      company: this.company,
      type: "filter",
      module: "suppliers",
      isPaginated: true,
      lastUpdated: new Date().toJSON(),
      paginatedQuery: {
        rowsPerPage: this.rowsPerPage,
        currentPage: this.currPage,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {},
      limit: 2,
      filterQuery: {},
      searchQuery: "fam",
    };

    const access = await MLTAxiosInstance.post(
      `util/filterData?company=${this.company}`,
      filterData
    );
    console.log(access);
    this.tableData = await access.data.data;
    this.totalPageNo = await access.data.total;
    this.newSupplierPayload.parentCompany = val.data.company;
    if (access) {
      this.loaderApi = false;
    }
    console.log(this.loaderApi, "this.loaderApi");
    console.log(access, this.loaderApi, "backend data sm");
    // const rootName = this.$cookies.get("rootName");
    // const tagsListurl =
    //     rootName === "Halleyx"
    //       ? "mapRef/collection/tagsList"
    //       : `mapRef/collection/tagsList?company=${this.$cookies.get("rootName")}`;

    let tagsList = await this.getReferanceData('tagsList')
    this.tagsList =  await tagsList?.filter((item) => {
      return item.module === "sm";
    });
    this.supplierdataCalling(
      this.SpecificationcurrPage,
      this.SpecificationrowsPerPage
    );

    await this.$store.dispatch("supplierViewFilterData");
    this.filter = this.$store.getters.supplierViewFilterData;
    console.log(this.filter, "this.filter");
    // const paymentTermsurl =
    //     rootName === "Halleyx"
    //       ? "mapRef/collection/paymentTerms"
    //       : `mapRef/collection/paymentTerms?company=${this.$cookies.get("rootName")}`;

    // let paymentList = await DMNAxiosInstance.get(paymentTermsurl
    // );
    this.paymentList =  await this.getReferanceData('paymentTerms');
    // const statuslisturl =
    //     rootName === "Halleyx"
    //       ? "mapRef/collection/statuslist"
    //       : `mapRef/collection/statuslist?company=${this.$cookies.get("rootName")}`;

    // let statusList = await DMNAxiosInstance.get(statuslisturl);
    this.statusList =  await this.getReferanceData('statuslist');
    // const leadTimeurl =
    //     rootName === "Halleyx"
    //       ? "mapRef/collection/leadTime"
    //       : `mapRef/collection/leadTime?company=${this.$cookies.get("rootName")}`;

    // let leadTimeOption = await DMNAxiosInstance.get(leadTimeurl
    // );
    this.leadTimeOption =  await this.getReferanceData('leadTime');
  },

  methods: {
    reAssignOrgTableHeader(){
           // If there is change in the table header then ressign to the Orginal data
           if((this.accounts_table_theads_cc != this.tableHeaderOrgcc) && this.tableHeaderOrgcc.length > 0)
    {
      this.accounts_table_theads_cc = JSON.parse(JSON.stringify(this.tableHeaderOrgcc))
    }
    if((this.accounts_table_theads != this.tableHeaderOrg) && this.tableHeaderOrg.length > 0)
    {
      this.accounts_table_theads = JSON.parse(JSON.stringify(this.tableHeaderOrg))
    }
      this.toggleRearrangeModal=true
    },
    resizedWidth(val){
      console.log(val,"resizedWidth",val)

      const updateWidth = (array) => {
    let item = array.find(i => i?.prop === val?.prop);
    if (item) item.width = val.width;
  };

  updateWidth(this.accounts_table_theads);
  updateWidth(this.accounts_table_theads_cc);
console.log(this.accounts_table_theads,"resizedWidth loop",this.accounts_table_theads_cc)
this.applyColumns(this.accounts_table_theads_cc)
    },
    validatePhoneNumber(val) {
      return val.valid;
    },
    showDrawerRight() {
      this.show_right_filter = true;
    },
    closeRightFilter() {
      this.show_right_filter = false;
    },
    validatePhoneName(val) {
      console.log(val, "validatePhoneName");
    },
    emitStatus1(val1) {
      this.newSupplierPayload.status = val1["value"];
    },
    sanitizeDatabaseName(databaseName) {
      // Replace dots with an underscore or any other symbol you want
      const sanitized = databaseName?.replace(/\./g, "_");

      // Validate the constraints for MongoDB database names
      const validDatabaseName = sanitized?.replace(/[^a-zA-Z0-9_]/g, "");

      return validDatabaseName;
    },
    async profileUpload(event) {
      console.log(event);
      let selectedFiles = event.target.files[0];
      let formData = new FormData();

      // Append all selected files to FormData object
      formData.append("files", selectedFiles);
      let data = await UPCAxiosInstance.post(
        `/gcpMultiple/v2/upload/`,
        formData
      );
      console.log(data);
      let obj = {};
      obj.name = data.data[0].filename;
      obj.size = data.data[0].data.attachment.size;
      obj.url = data.data[0].url;
      obj.id = data.data[0].data._id;
      obj["@type"] = "profile";
      const currentTimestamp = new Date().toJSON();
      console.log(currentTimestamp);
      obj.lastUpdated = currentTimestamp;
      this.newSupplierPayload.attachment = [];
      this.newSupplierPayload.attachment.push(obj);
      console.log(this.newSupplierPayload);
    },
    websiteRegex(val) {
      const websiteRegex =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

      if (val.length >= 1) {
        if (!websiteRegex.test(val)) {
          this.isUrlEmpty = true; // URL is invalid
        } else {
          this.isUrlEmpty = false;
        }
      }
    },
    validateEmail(val) {
      const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (val.length > 0) {
        if (!reg.test(val)) {
          return false; // URL is invalid
        } else {
          return true;
        }
      }
    },
    async addTagList(e) {
      let obj = {};
      obj.name = e.name;
      obj.module = "sm";
      await DMNAxiosInstance.post(`mapRef/tagsList`, obj);
    // const rootName = this.$cookies.get("rootName");
    //   const tagsListurl =
    //     rootName === "Halleyx"
    //       ? "mapRef/collection/tagsListurl"
    //       : `mapRef/collection/tagsListurl?company=${this.$cookies.get("rootName")}`;

    //   let tagsList = await DMNAxiosInstance.get(tagsListurl);
      let arr = this.newSupplierPayload.tags;
      arr.push(e.name);
      this.newSupplierPayload.tags = arr;
      this.tagsList =  await this.getReferanceData('tagsList').filter((item) => {
        return item.module === "sm";
      });
    },

    selectValue(val) {
      if (val && Array.isArray(val)) {
        this.newSupplierPayload.tags = val
          .map((item) => {
            if (item.checked) {
              return item._id;
            }
          })
          .filter((item) => item);
      }
    },
    async refreshTable() {
      const filterData = {
        company: this.company,
        type: "filter",
        module: "suppliers",
        isPaginated: true,
        lastUpdated: new Date().toJSON(),
        paginatedQuery: {
          rowsPerPage: this.rowsPerPage,
          currentPage: this.currPage,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 2,
        filterQuery: {},
        searchQuery: "fam",
      };
      await this.$store.dispatch("supplierViewFilterData");
      this.filter = this.$store.getters.supplierViewFilterData;

      const access = await MLTAxiosInstance.post(`util/filterData`, filterData);
      console.log(access);
      this.tableData = await access.data.data;
      this.totalPageNo = await access.data.total;
    },
    validateAddress(val) {
      console.log(val);
      if (val.characteristic.address1 !== "") {
        return true;
      } else {
        return false;
      }
    },
    closeAddNewSupplierModal() {
      this.canAdd = false;
      this.addressKey += 1;

      let x = {
        tradingName: "",
        name: "",
        status: "Active",
        preferred: "",
        shippingMethod: [
          {
            name: "",
          },
        ],
        paymentTerms: [
          {
            name: "",
            id: "",
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
          {
            "@type": "leadTime",
            leadTime: 1,
            leadPeriod: "Days",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              address1: "",
              default: true,
              "@type": "",
            },
          },
        ],

        description: "",
        tags: [""],

        notes: [],
        role: "Root admin",
        type: "company",
        password: "",
        email: "",
        familyName: "",
        givenName: "",
        companyName: "",
        parentCompany: "",
        rootName: "",
        "@baseType": "supplier",
      };
      this.isUrlEmpty = false;
      this.isNameEmpty = false;
      this.isEmailEmpty = false;
      this.modalKey += 1;
      this.newSupplierPayload = JSON.parse(JSON.stringify(x));
      this.modal_active = false;
    },
    receivedAddressData(val) {
      console.log(val);
      let address1 = JSON.parse(JSON.stringify(val));
      this.newSupplierPayload.contactMedium[3] = JSON.parse(
        JSON.stringify(address1)
      );
      if (val.characteristic.country !== "") {
        this.newSupplierPayload.contactMedium[1].characteristic.phoneNumber
        .country.name = val.characteristic.country
        this.newSupplierPayload.contactMedium[1].characteristic.phoneNumber
        .country.flags = ""
        this.newSupplierPayload.contactMedium[1].characteristic.phoneNumber
        .country.short = ""
      }
      console.log(this.newSupplierPayload.contactMedium[3]);
    },
    receivedTags(e) {
      let arr = [];
      e.filter((item) => {
        if (item.checked == true) {
          // console.log('received',item.name);
          arr.push(item.name);
        }
      });
      this.newSupplierPayload.tags = arr;
      // console.log(
      //   "received",
      //   e,
      //   this.newSupplierPayload.tags.filter((item) => item !== e[0]?.name)
      // );
      // // this.newSupplierPayload.tags = [];
      // // e.forEach((ele) => {
      // //   if (ele.checked === true) {
      // //     this.newSupplierPayload.tags.push(ele.name);
      // //   } else {
      // //     this.newSupplierPayload.tags = this.newSupplierPayload.tags.filter(
      // //       (item) => item !== ele.name
      // //     );
      // // //   }
      // // });
      // this.newSupplierPayload.tags = e.reduce((tags, ele) => {
      //   if (ele.checked) {
      //     tags.push(ele.name);
      //   } else {
      //     tags = tags.filter((item) => item !== ele.name);
      //   }
      //   return tags;
      // }, []);
    },
    async addNewAddress() {
      this.showNewAddressOption = false;
      this.newAddressPayload.description =
        this.newAddressPayload.streetNumber +
        ", " +
        this.newAddressPayload.streetName +
        ", " +
        this.newAddressPayload.city +
        ", " +
        this.newAddressPayload.state +
        ", " +
        this.newAddressPayload.country +
        ", " +
        this.newAddressPayload.postalCode +
        ".";
      // console.log(this.newAddressPayload);
      let newAddress = await DMNAxiosInstance.post(
        `mapRef/geoAddress`,
        this.newAddressPayload
      );
      if (newAddress.data.ok === 1) {
        // console.log(this.newAddressPayload.description);
      }
      this.newSupplierPayload.contactMedium[3].characteristic.description =
        this.newAddressPayload.description;
      this.showAddNewAdrressModal = false;
      const x = {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      };
      this.newAddressPayload = JSON.parse(JSON.stringify(x));
    },
    addNewLocation(val) {
      // console.log(val);
      val;
      this.showAddNewAdrressModal = true;
      this.showNewAddressOption = false;
    },
    async getLocations() {
      const input =
        this.newSupplierPayload.contactMedium[3].characteristic.description;
      let result = [];
      console.log(
        this.newSupplierPayload.contactMedium[3].characteristic.description,
        "lovation"
      );
      try {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        const response = await UPCAxiosInstance.get(
          `/suggestionlist/?input=${input}`
        );
        // console.log(response);
        result = response.data;
      } catch (error) {
        console.error("Error fetching autocomplete data:", error.message);
      }
      // console.log(result);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.locationData = result;
      if (this.locationData.length === 0 && input !== "") {
        this.showNewAddressOption = true;
      } else {
        this.showNewAddressOption = false;
      }
      return result;
    },
    closeAddNewAdrressModal() {
      this.showAddNewAdrressModal = false;
    },
    async changeRowsPerPage(count) {
      //
      if (this.company != "") {
        this.rowsPerPage = count;
        this.supplierdataCalling(
          this.SpecificationcurrPage,
          this.SpecificationrowsPerPage
        );
        this.refreshTable();
      }
    },
    async currentPage(val) {
      if (this.company != "") {
        this.currPage = val;
        this.supplierdataCalling(
          this.SpecificationcurrPage,
          this.SpecificationrowsPerPage
        );
        this.refreshTable();
      }
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    async searchKey(val) {
      this.searchvalue = val;
      this.filterQuery["company"] = this.company;
      this.filterQuery["type"] = "search";
      this.filterQuery["searchQuery"] = this.searchvalue;
      this.currPage = 1;
      this.rowsPerPage = 10;
      if (this.filtertagvalue.length != 0) {
        this.filterQuery["type"] = "filter-search";
      }
      this.supplierdataCalling(this.currPage, this.rowsPerPage);
    },
    async supplierfilterData(filterSelectedvalue, filterQuery) {
      this.filtertagvalue = filterSelectedvalue;
      this.filtertaglength = filterSelectedvalue.length;
      filterQuery.company = this.company;
      //  filterQuery['filterQuery']['"@baseType"'] = 'supplier'
      if (this.filtertagvalue.length != 0) {
        this.filterQuery = filterQuery;
        this.filterQuery.type = "filter";
        if (this.searchvalue != "") {
          this.filterQuery["searchQuery"] = this.searchvalue;
          this.filterQuery["type"] = "filter-search";
        }
        const filterResultData = await MLTAxiosInstance.post(
          `util/filterData`,
          this.filterQuery
        );

        this.tableData = filterResultData.data.data;
        this.totalPageNo = filterResultData.data.total;
      } else {
        this.currPage = 1;
        this.rowsPerPage = 10;
        this.supplierdataCalling(this.currPage, this.rowsPerPage);
      }
    },

    sorting_Data(data, data1) {
      //
      console.log(data, data1);
      this.Supplierfilterquery.type = "filter";
      if (data === "icon-angle-up-small-filled sorting-icon") {
        this.Supplierfilterquery.isNeedToBeSort = true;
        let obj = {};
        obj[data1] = 1;
        this.Supplierfilterquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.supplierdataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled sorting-icon") {
        this.Supplierfilterquery.isNeedToBeSort = true;
        let obj = {};
        obj[data1] = -1;
        this.Supplierfilterquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.supplierdataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-up-small-filled") {
        this.Supplierfilterquery.isNeedToBeSort = false;
        let obj = {};
        this.Supplierfilterquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.supplierdataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled") {
        this.Supplierfilterquery.isNeedToBeSort = false;
        let obj = {};
        this.Supplierfilterquery.sortFieldAndOrder = obj;
        this.currPage = 1;
        this.supplierdataCalling(this.currPage, this.rowsPerPage);
      }
    },
    // async supplierdataCalling(SpecificationcurrPage, SpecificationrowsPerPage) {
    //   this.Supplierfilterquery["paginatedQuery"]["currentPage"] =
    //     SpecificationcurrPage;
    //   this.Supplierfilterquery["paginatedQuery"]["rowsPerPage"] =
    //     SpecificationrowsPerPage;
    //   // const filterResultData = await UPCAxiosInstance.post(
    //   //       `util/filterData/${this.company}`,
    //   //       this.Supplierfilterquery
    //   //     );
    //   //     this.totalPageNo = filterResultData.data.total
    //   //     this.tableData = filterResultData.data.data
    //   //     console.log(this.tableData,'data');
    // },

    async supplierdataCalling(currentPage, rowsPerPage) {
      const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
      this.company = val.data.company;
      this.filterQuery["company"] = this.company;
      this.filterQuery["paginatedQuery"]["currentPage"] = currentPage;
      this.filterQuery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
      const filterResultData = await MLTAxiosInstance.post(
        `util/filterData`,
        this.filterQuery
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        this.totalPageNo = filterResultData.data.total;
        this.paginatedData;
      }
    },

    // async searchKey(key) {
    //   const filterData = {
    //     company: this.company,
    //     type: "search",
    //     module: "suppliers",
    //     isPaginated: true,
    //     lastUpdated: new Date().toJSON(),
    //     paginatedQuery: {
    //       rowsPerPage: this.rowsPerPage,
    //       currentPage: this.currPage,
    //     },
    //     isNeedToBeSort: true,
    //     sortFieldAndOrder: {},
    //     limit: 2,
    //     filterQuery: {},
    //     searchQuery: `${key}`,
    //   };

    //   const access = await MLTAxiosInstance.post(
    //     `utility/filterData`,
    //     filterData
    //   );
    //   this.tableData = access.data;
    // },
    statusFunc(val) {
      this.newSupplierPayload.status = val.name;
    },

    async AddNewSupplierModal() {
      this.canAdd = false;
      console.log(this.isEmailEmpty, this.isNameEmpty, "error");
      let payload = JSON.parse(JSON.stringify(this.newSupplierPayload));
      if (payload.contactMedium[3].characteristic.description !== "") {
        let billingAddress = JSON.parse(
          JSON.stringify(payload.contactMedium[3])
        );
        let siteAddress = JSON.parse(JSON.stringify(payload.contactMedium[3]));
        billingAddress.mediumType = "billingLocation";
        billingAddress.characteristic.phone =
          payload.contactMedium[1].characteristic;
        billingAddress.characteristic.email =
          payload.contactMedium[0].characteristic;
        billingAddress.characteristic.locationName = payload.name;
        billingAddress.characteristic.default = true;

        siteAddress.mediumType = "inventoryLocation";
        siteAddress.characteristic.phone =
          payload.contactMedium[1].characteristic;
        siteAddress.characteristic.email =
          payload.contactMedium[0].characteristic;
        siteAddress.characteristic.locationName = payload.name;
        siteAddress.characteristic.default = true;

        payload.contactMedium.push(billingAddress);
        payload.contactMedium.push(siteAddress);
      }
      try {
        let result = await MLTAxiosInstance.post(
          `/partymanagement/individual/account/signup`,
          payload
        );
        console.log(result, "result");
        console.log(result.data.message, "result.data.tradingName");
        if (result.data.ok === 1) {
          this.notification = {
            type: "success",
            message: `Nice work! Your new organization "${this.newSupplierPayload.name}" is now in the list!`,
          };
        } else {
          this.notification = {
            type: "warning",
            message: `This organization name already exit  `,
          };
        }
      } catch (error) {
        if (error.response) {
          console.log("Error k data:", error.response.data);
          console.log("Error status:", error.response.status);
          console.log("Error headers:", error.response.headers);
        } else if (error.request) {
          this.notification = {
            type: "warning",
            message: `This email was already registered`,
          };
          console.log("Error request:", error.request);
        } else {
          console.log("Error message:", error.message);
        }
        this.notification = {
          type: "warning",
          message: `This email was already registered`,
        };
        console.log("Error config:", error.config);
      }

      this.addressKey += 1;

      this.supplierdataCalling();
      this.closeAddNewSupplierModal();
      this.refreshTable();
    },

    notificationcheck() {
      this.notification = {
        type: "success",
        message: `Nice work! Your new organization "${this.newSupplierPayload.name}" is now in the list!`,
      };
    },
    viewPage(ele) {
      this.$router.push({
                 path: `/SM/supplier/edit/${ele.name}/${ele._id}`,
          query: { status: `${ele.status}` , customName:`${ele.customName}`},
      });
    },

    addNewAccount() {
      this.modal_active = true;

      this.tagcomponetkey += 1;
    },

    crudContextMenu($event, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 159;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    async closeCrudContextMenu($event, ele) {
      console.log($event, "$event");
      if ($event.toLowerCase() === "edit") {
        this.$router.push({
          path: `/SM/supplier/edit/${ele.name}/${ele._id}`,
          query: { status: `${ele.status}` , customName:`${ele.customName}`},
        });
      } else if ($event.toLowerCase() == "mark as in-active") {
        console.log(ele, "sdfghjk");
        let i = {
          id: ele._id,
          tradingName: ele.tradingName,
          status: "Inactive",
        };
        let ii = {
          filterQuery: {
            company: { $exists: true, $eq: ele.tradingName },
          },
          updatePayload: {
            status: "Inactive",
          },
        };
        const addSupplier = await MLTAxiosInstance.patch(
          `/partymanagement/organisation/?company=${ele.name}`,
          i
        );
        await MLTAxiosInstance.patch(
          `/partymanagement/individual/batch?company=${ele.tradingName}`,
          ii
        );
        //         {{MLTBaseURL}}/partymanagement/individual/batch?company=XOPremiummobiles
        // {
        //     "filterQuery": {
        //         "tradingName": "XOPremiummobiles"
        //     },
        //     "updatePayload":{
        //         "title":"XOPremiummobiles premier user"
        //     }
        // }
        this.refreshTable();
        if (addSupplier.data.email) {
          this.alertmsg = "success";
          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };
        } else {
          this.alertmsg = "danger";
          this.notification = {
            type: "danger",
            message: `Data could not be inserted.`,
          };
        }

        console.log(addSupplier);
        this.refreshTable();
        addSupplier;
      } else if ($event.toLowerCase() == "mark as active") {
        let i = {
          id: ele._id,
          status: "Active",
        };
        let ii = {
          filterQuery: {
            company: { $exists: true, $eq: ele.tradingName },
          },
          updatePayload: {
            status: "Pending",
          },
        };
        const addSupplier = await MLTAxiosInstance.patch(
          `/partymanagement/organisation/?company=${ele.name}`,
          i
        );
        await MLTAxiosInstance.patch(
          `/partymanagement/individual/batch?company=${ele.tradingName}`,
          ii
        );
        if (addSupplier.data.email) {
          this.alertmsg = "success";
          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };
        } else {
          this.alertmsg = "danger";
          this.notification = {
            type: "danger",
            message: `Data could not be inserted.`,
          };
        }
        console.log(addSupplier);
        this.refreshTable();
        addSupplier;
      }
    },

    computedTableAction(data) {
     

     if (this.permisstionData.delete && this.permisstionData.edit ) {
       var options = [
       {
         label: "Edit",
         display: this.staticData?.edit?.label[this.loginUserDetails]?.label || "Edit",
         icon: "icon-file-text-edit-regular",
       },
      //  {
      //    label: "Impersonate",
      //    display: this.staticData?.iconmpersonate?.label[this.loginUserDetails]?.label || "Impersonate",
      //    icon: "icon-user-change-regular",
      //  },
       {
         label: "Mark as In-active",
         display: this.staticData?.markAsInactive?.label[this.loginUserDetails]?.label || "Mark as In-active",
         icon: "icon-trash-regular",
       },
       {
         label: "Mark as Active",
         display: this.staticData?.markAsActive?.label[this.loginUserDetails]?.label || "Mark as Active",
         icon: "icon-trash-regular",
       },
     ]
     } if (this.permisstionData.delete){
       options =[ {
         label: "Mark as In-active",
         icon: "icon-trash-regular",
       },
       {
         label: "Mark as Active",
         icon: "icon-trash-regular",
       },
     ]
     }else  {
        options = [
       {
         label: "Edit",
         icon: "icon-file-text-edit-regular",
       },
      //  {
      //    label: "Impersonate",
      //    icon: "icon-user-change-regular",
      //  }
     ];
     } 

     return options?.filter((option) => {
       if (
         data &&
         data.status &&
         data.status.toLowerCase() == "active" &&
         ["Mark as Active"].includes(option.label)
       ) {
         return false;
       } else if (
         data &&
         data.status &&
         data.status.toLowerCase() == "inactive" &&
         ["Mark as In-active"].includes(option.label)
       ) {
         return false;
       } else {
         return true;
       }
     });
   },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    arrange(val) {
      this.toggleRearrangeModal = val;
      // this.updateTheads();
    },
    arrangeColumns(val) {
      this.toggleRearrangeModal = val;
      // this.updateTheads();
    },
    updateTheads() {
      this.accounts_table_theads_cc = this.accounts_table_theads_cc.map(
        (thead) => {
          // Check if the current thead has a matching 'prop' in duplicateTheads
          const isMatching = this.accounts_table_theads.some(
            (duplicateThead) => duplicateThead.prop === thead.prop
          );

          // Set the 'checked' property based on whether it's matching
          return { ...thead, checked: isMatching };
        }
      );
    },
   async applyColumns(val) {
// Func to rearrange table header
console.log("applyColumns",val)
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
let concatedEntity = sampleTheads?.data?.entityRef || {};
concatedEntity.supplier = val;

let reArrangeTableHeadPayload = { entityRef: concatedEntity };
 
const changeTableHeader = await MLTAxiosInstance.patch(`/settings`, reArrangeTableHeadPayload);
console.log(changeTableHeader, "reArrangeTableHeadPayload", reArrangeTableHeadPayload);

let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
console.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
this.accounts_table_theads_cc = entityRef["supplier"]
this.accounts_table_theads =this.accounts_table_theads_cc.filter(ele=>ele.checked)
this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.accounts_table_theads_cc ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.accounts_table_theads))
    },
  },
};
</script>
<style lang="scss">
.decision-name-wrapper {
  .description-suppliermanager {
    height: 177px;
    margin-top: 20px;
    .input-field.small textarea {
      height: 131px;
    }
  }
}
.activetime-supplier {
  font-size: 32px;
  color: #54bd95;
}
.profile-add-supplier {
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 65px;
  top: -7px;
}
.full-supplier-model-content {
  display: flex;
  gap: 28px;

  .supplier-left-model-content {
    .input-container {
      .input-field.small textarea {
        height: 131px;
      }
    }
  }
  .supplier-left-model-content,
  .supplier-right-model-content {
    display: flex;
    flex-direction: column;

    width: 50%;
    .spacingconvenstion-supplier {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}
</style>
