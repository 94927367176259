<template>
  <hlx-alert-notification :notify="notification" :auto-close="true" />


  
  <!-- add region modal -->
  <addRegionComponent
  :key="addregionKey"
  :open-modal="showAddNewRegionModal"
  @add-region="addRegion"
  @close-modal="closeAddRegionModal"
  ></addRegionComponent>



<!-- delete -->
<hlx-modal
        style="z-index: 999999999"
        :modal-active="delete_modal_active"
        :height="'220px'"
        :width="'400px'"
        :modal="true"
        @close="delete_modal_active = false"
      >
        <template #header>
          <div style="margin-left: 0px">Delete region</div>
        </template>
        <template #content>
          <div class="modal-content">
            <p>
              Are you sure you want to delete <b>{{ delete_name }}</b
              >?
            </p>
          </div>
        </template>
        <template #footer>
          <hlx-button class="secondary sm" @click="delete_modal_active = false"
            >Cancel</hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 16px"
            @click="deleteExecRow"
            >Delete</hlx-button
          >
        </template>
      </hlx-modal>




   <section style="margin: 10px 15px">

    
  <!-- header -->
  <section
      v-if="tableData && tableData?.length > 0 || searchvalue !== ''"
      class="right-view-body-feature">
      <span class="spec-feature --table-tool-bar" style="margin: 10px 0">
        <div class="--table-tool-bar-wrapper">

        <!-- search -->
          <span class="util-items search"
            ><hlx-search
              v-model="searchvalue"
              @search-key="searchKey"
            ></hlx-search
          ></span>

          <!-- Filter -->
             <hlx-button class="secondary sm add-btn" @click="showDrawerRight">
              <i class="icon-filter-regular"></i>
            </hlx-button>


          <hlx-button
              class="primary sm"
              @click="openAddNewRegionModal"
            >
              <i class="icon-plus-circle-regular"></i> Add region</hlx-button
            >

        </div>
      </span>
    </section>






      <!-- table -->
  <section v-if="tableData && tableData?.length > 0">
<hlx-table
  :key="refreshTable1"
  :column-count="tableHeads + 2"
  :border="['table', 'header', 'horizontal']"
  :theme="'grey'"
  :bold-headers="false"
  :row-hover="true"
  :striped-rows="false"
  :re-arrange-columns="re_arrange"
  :re-arrange-heads="theads"
  @close-rearrange="arrange"
  @apply-rearranged-columns="applyColumns"
>
  <template #thead>
    <hlx-table-head
      :width="60"
      :align="'left'"
      style="padding: 0 15px !important; max-width: 60px !important"
      :fixed="'left'"
      >{{ "S.no" }}</hlx-table-head
    >
    <hlx-table-head
      v-for="(i, index) in tableHeads"
      :key="index"
      :prop="i.prop"
      :width="i.width"
      :align="'left'"
      :resizable="true"
    >
      {{ i.label }}
    </hlx-table-head>
    <hlx-table-head :align="'center'" :width="60" :fixed="'right'"
      ><span class="action-icon">
        <i
          class="icon-settings-regular"
          style="cursor: default"
        ></i> </span
    ></hlx-table-head>
  </template>
  <template #tbody>
    <tr v-for="(i, index) in tableDataOnSelectedRows" :key="index">
      <hlx-table-cell :width="60" fixed="left">
        {{ serialNumber(index) }}
      </hlx-table-cell>
      <hlx-table-cell
        v-for="(j, col_index) in tableHeads"
        :key="col_index"
        :align="'left'"
        :width="i.width"
      >


      <div v-if="j.prop === 'regionName'">
                  <div
                    v-for="(addressLine, index1) in i.streetAddressLine"
                    :key="index1"
                  >
                    <span
                      v-if="addressLine.characteristic.regionName"
                      style="cursor: pointer; color: #2879b7"
                      onmouseover="this.style.textDecoration='underline';"
                      onmouseout="this.style.textDecoration='none';"
                      @click="callOverviewRoute(i?.id)"
                      >{{ addressLine.characteristic.regionName }}</span
                    >
                  </div>
                </div>
                <div v-else-if="j.prop === 'addressdescription'">
                  <div
                    v-for="(addressLine, index) in i.streetAddressLine"
                    :key="index"
                  >
                    <span v-if="addressLine.characteristic.description">{{
                      removeFirstComma(addressLine?.characteristic?.description)
                    }}</span>
                  </div>
                </div>
                <div v-else-if="j.prop === 'servicestatus'">
                  <div
                    v-for="(addressLine, index) in i.streetAddressLine"
                    :key="index"
                  >
                    <div>
                      <hlx-status
                        :type="addressLine.characteristic.servicestatus"
                      ></hlx-status>
                    </div>
                  </div>
                </div>
                <div v-else-if="j.prop === 'type'">
                  <div
                    v-for="(addressLine, index) in i.streetAddressLine"
                    :key="index"
                  >
                    <div>
                      <!-- <hlx-status
                        :type="addressLine.characteristic.servicestatus"
                      ></hlx-status> -->
                      {{ addressLine.characteristic.type }}
                    </div>
                  </div>
                </div>
                <div v-else-if="j.prop === 'createdat'">
                  <div
                    v-for="(addressLine, index) in i.streetAddressLine"
                    :key="index"
                  >
                    <div v-if="addressLine?.characteristic?.createdat">
                      <!-- <hlx-status
                        :type="addressLine.characteristic.servicestatus"
                      ></hlx-status> -->
                      {{ formatDate(addressLine?.characteristic?.createdat) }}
                    </div>
                  </div>
                </div>
                <div v-else-if="j.prop === 'updatedat'">
                  <div
                    v-for="(addressLine, index) in i.streetAddressLine"
                    :key="index"
                  >
                    <div v-if="addressLine?.characteristic?.updatedat">
                      <!-- <hlx-status
                        :type="addressLine.characteristic.servicestatus"
                      ></hlx-status> -->
                      {{ formatDate(addressLine?.characteristic?.updatedat) }}
                    </div>
                  </div>
                </div>


      </hlx-table-cell>
      <hlx-table-cell :align="'center'" :width="120" :fixed="'right'">
        <span :id="'table-context' + index" class="action-icon">
          <i
            style="position: relative"
            class="icon-more-vertical-filled"
            :class="i.context === true ? 'active-action' : ''"
            @click="crudContextMenu($event, i, index)"
          >
            <hlx-context-menu
              :top="containertop"
              :left="containerleft"
              :conditionvalue="150"
              :conditiondown="2"
              :conditionup="-38"
              :options="computedTableAction(i)"
              :data="index.toString()"
              :show="i.context == true"
              @chosen="closeCrudContextMenu($event, i, index)"
            />
          </i>
        </span>
      </hlx-table-cell>
    </tr>
  </template>
</hlx-table>
</section>


<!-- no data -->
<section v-if="tableData.length === 0 && searchvalue !== ''">
<hlx-table
    :column-count="tableHeads.length + 2"
    :border="['table', 'header']"
    :bold-headers="false"
    :row-hover="true"
    theme="grey"
    :striped-rows="false"
    :re-arrange-columns="toggleRearrangeModal"
    :re-arrange-heads="tableHeadsRearranging"
    @close-rearrange="toggleRearrangeModal = false"
    @apply-rearranged-columns="applyColumns"
  >
    <template #thead>
      <hlx-table-head :width="60" :align="'left'"   @new-resize-width="resizedWidth">{{
        "S.no"
      }}</hlx-table-head>
      <hlx-table-head
        v-for="(i, index) in tableHeads"
        :key="index"
        :prop="i.prop"
        :sortable="i.sortable"
        :resizable="i.resizable"
        style="position: sticky; top: 0px"
        :width="i.width !== undefined ? i.width : ''"
        :align="i.align !== undefined ? i.align : ''"
        @sorting_func="sorting_Data"
          @new-resize-width="resizedWidth"
      >
        {{ i.label }}
      </hlx-table-head>
      <hlx-table-head v-show="supplierStatus == false" :width="100">
        <span class="action-icon"
          ><i
            class="icon-settings-regular"
            @click="toggleRearrangeModal = true"
              @new-resize-width="resizedWidth"
          ></i></span
      ></hlx-table-head>
    </template>
    <template #tbody>
      <tr>
        <hlx-table-cell></hlx-table-cell>
      </tr>
      <tr>
        <hlx-table-cell></hlx-table-cell>
      </tr>
      <tr>
        <hlx-table-cell></hlx-table-cell>
      </tr>
      <tr>
        <hlx-table-cell></hlx-table-cell>
      </tr>
      <tr>
        <hlx-table-cell :colspan="tableHeads?.length + 2">{{
          "No data"
        }}</hlx-table-cell>
      </tr>
      <tr>
        <hlx-table-cell></hlx-table-cell>
      </tr>
      <tr>
        <hlx-table-cell></hlx-table-cell>
      </tr>
      <tr>
        <hlx-table-cell></hlx-table-cell>
      </tr>
      <tr>
        <hlx-table-cell></hlx-table-cell>
      </tr>
      <tr>
        <hlx-table-cell></hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
</section>


        <!-- image -->
        <section
         v-if="tableData && tableData?.length == 0 && searchvalue === ''"
        style="
          margin-top: 1.2rem;
          display: flex;
          flex-direction: column;
          gap: 1.2rem;
          align-items: center;
        "
      >

      <span>
          <img
            src="@/assets/images/svg/SQregion.svg"
            alt=""
            style="width: 500px; height: 500px"
          />
        </span>
        <span class="util-items util-items-add">
          <hlx-button
            class="primary media add-btn"
              @click="openAddNewRegionModal"
            ><i class="icon-plus-circle-regular" style="margin-right: 5px"></i
            >Add region</hlx-button
          >
        </span>
        
    
    </section>






  </section>
</template>

<script>
import addRegionComponent from "@/components/Product/ServiceQualification/addRegionModalComponent.vue"
import {
//   DMNAxiosInstance,
  // MLTAxiosInstance,
  UPCAxiosInstance,
  // UPCAxiosInstance
} from "@/config/axiosConfig";
export default {
  components: {
    addRegionComponent
  },
    data() {
    return {

      addregionKey: 0,

          
      tableData:[],


            tableHeads: [
        {
          sortable: false,
          resizable: true,
          width: 100,
          name: "Region name",
          disabled: true,
          checked: true,
          label: "Region name",
          prop: "regionName",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 154,
          name: "Status",
          disabled: false,
          checked: true,
          label: "Status",
          prop: "servicestatus",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 154,
          name: "Type",
          disabled: false,
          checked: true,
          label: "Type",
          prop: "type",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 200,
          checked: true,
          disabled: true,
          name: "Address",
          label: "Address",
          prop: "addressdescription",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 154,
          name: "Created date",
          disabled: false,
          checked: true,
          label: "Created date",
          prop: "createdat",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 154,
          name: "Last updated",
          disabled: false,
          checked: true,
          label: "Last updated",
          prop: "updatedat",
          align: "left",
        },
        // {
        //   sortable: false,
        //   resizable: true,
        //   width: 250,
        //   name: "Region shape",
        //   checked: true,
        //   disabled: false,
        //   label: "Region shape",
        //   prop: "regionshape",
        //   align: "left",
        // },
       
      ],



      notification: "",


      
            
      filtertaglength: 0,

            
filterQueryforBill: {
        type: "filter",
        module: "address",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: this.rowsPerPage,
          currentPage: this.currPage,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {},
        limit: 10,
                filterQuery: {
            "streetAddressLine.characteristic.category": {
                "$in": [
                    "parent"
                ]
            }
        },
        searchQuery: "",
        company: this.$cookies.get("userName"),
      },

      
currPage: 1,
rowsPerPage: 10,
      externalRowsPerPage: 10,

      searchvalue: "",

      delete_modal_active: false,
      
      deleteobj: {},

      delete_name:'',

      showAddNewRegionModal:false,

        }
  },

  computed: {
   
    tableDataOnSelectedRows() {
      return this.tableData;
    },

    
  }
  ,

  mounted() {
      
    this.ticketdataCalling(this.currPage, this.rowsPerPage);


  },

  methods: {

    crudContextMenu($event, i, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 102;

        this.containertop =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().top + 10;  
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },


    
    closeCrudContextMenu($event, payload, i) {
      payload, i
      if ($event.toLowerCase() === "edit") {
        // this.editMode = true;
        // const payload = JSON.parse(JSON.stringify(this.tableData[i]));
        // this.intialval = JSON.parse(JSON.stringify(this.tableData[i]));
        // this.initialServiceQualificationProduct = payload.productRelationship;
        // this.openuploaddrawer1(payload, i);
        // this.editpayload = payload;
        // console.log(this.editpayload, payload, i, "jjjjjjjjjjjjjjjjjjjjjj");
      } else if ($event.toLowerCase() === "delete") {
        this.delete_modal_active = true;
        this.deleteobj = payload;
        console.log("qqqqqqqqqqqqq", payload, this.deleteobj?._id);
        // this.delete_name = payload?.name;
      }
    },
    computedTableAction() {      
      return [
        // {
        //   label: "Edit",
        //   icon: "icon-file-text-edit-regular",
        // },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ];
    },


    async deleteExecRow() { 
      
      this.delete_modal_active = false;

      const deleteresponse = await UPCAxiosInstance.delete(
          `/geographicAddress/${this.deleteobj.id}`
      );

      if (deleteresponse) {
        this.notification = {
            type: "success",
            message: `Address deleted for Service Qualification successfully!`,
          };
      }
      this.ticketdataCalling(this.currPage, this.rowsPerPage);
    },


    openAddNewRegionModal() { 
      this.addregionKey += 1;
      this.showAddNewRegionModal = true;
    },

    addRegion(val) {
      console.log("addRegion::",val);
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
    },

    closeAddRegionModal() {  
    },
    

    callOverviewRoute(id) {
      id
      console.log("callOverviewRoute::",id);

      this.$router.push({
          name: "regionOverview",
          query: { id: id, mode: "edit" },
        });
      
     },

    
    removeFirstComma(input) {
      return input.replace(",", "");
    },

    formatDate(isoDate) {
    const date = new Date(isoDate);
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
},
    
    async ticketdataCalling(currentPage, rowsPerPage) {
  currentPage, rowsPerPage
  console.log("ticketdataCalling...123",currentPage, rowsPerPage);
      this.filterQueryforBill["company"] = 'developerHalleyx';
        // this.$cookies.get('company');
      this.filterQueryforBill["paginatedQuery"]["currentPage"] = currentPage;
      this.filterQueryforBill["paginatedQuery"]["rowsPerPage"] = rowsPerPage;


      this.filterQueryforBill.filterQuery = {

        "streetAddressLine.characteristic.category": {
                "$in": [
                    "parent"
                ]
        }
            
    // "streetAddressLine.characteristic.category": {
    //     "$in": [
    //         "child"
    //     ]
    // },


    //       "streetAddressLine.characteristic.parentId": {
    //     "$in": [
    //         `${this.$route.query.id}`
    //     ]
    // }
            }





      this.filterQueryforBill.module = 'address';
      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.filterQueryforBill
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data?.total;
        console.log("ticketdataCalling...",this.tableData ,filterResultData.data.data);
        
        // this.totalvalue = filterResultData.data.total;      
      }
    },



    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
        },


        
        async searchKey(val) {
      console.log("searchKey",val);
      
      this.searchvalue = val;
      this.filterQueryforBill["company"] = this.$cookies.get("company");
      this.filterQueryforBill["type"] = "filter-search"
 || "search";
      this.filterQueryforBill["searchQuery"] = val;
      this.filterQueryforBill.searchList =
       [
      {
          "streetAddressLine.characteristic.type": {
            $regex: val,
            $options: "i",
          },
          },
        
      {
          "streetAddressLine.characteristic.description": {
            $regex: val,
            $options: "i",
          },
        },
    //     // {
    //     //   "state": {
    //     //     $regex: val.toLowerCase() === "on hold" ? "onhold" : val,
    //     //     $options: "i",
    //     //   },
    //     // },
    //     {
    //       "definition.name": {
    //         $regex: val,
    //         $options: "i",
    //       },
    //     },
    //     {
    //       "tag": {
    //         $regex: val,
    //         $options: "i",
    //       },
    //     },
      ];
      // this.currPage = 1;
      // this.rowsPerPage = 10;
      if (this.filtertagvalue?.length != 0) {
        this.filterQueryforBill["type"] = "filter-search";
      }
      this.ticketdataCalling(this.currPage, this.rowsPerPage);
    },

    

    
    async quotesfilterData(filterSelectedvalue, filterQuery) {
      console.log("quotesfilterData::))", filterSelectedvalue, filterQuery);
      this.filtertaglength = filterSelectedvalue?.length;
      this.filterQueryforBill = filterQuery;

      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.filterQueryforBill
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data?.total;
        console.log("ticketdataCalling...",this.tableData ,filterResultData.data.data);        
        // this.totalvalue = filterResultData.data.total;      
      }      
        },


         
    async currentPage(val) {
      if (this.companyasignee != "") {
        this.currPage = val;
        this.ticketdataCalling(
          this.currPage,
        this.rowsPerPage
        );
        this.refreshTable();
      }
    },


  }

}
</script>

<style>

</style>