<template>
  



  <!-- header -->
  <div class="common_single_header_selfservice">
        <div class="head_selfservice">Service qualification</div>
        <div class="body_selfservice">
          Services are accessible at the location.
        </div>
      </div>


      <!-- switch -->
      <div
        class="util-bar"
        style="
          display: flex;
          justify-content: space-between;
          margin: 20px 15px 15px 15px;
        "
      >
      <span class="switch-container">
          <hlx-switch
            v-model="selectedSwitch"
            :switch-items="switchOptions"
            :read-only="switchOff"
            value-prop="value"
            display-prop="name"
            @change="chosenSwitch"
          />
        </span>
    </div>





    <!-- region section -->
    <regioncomponent
    v-if="selectedSwitch === 'Address'">

    </regioncomponent>
    



    <!-- product section -->
    <productcomponent
    v-if="selectedSwitch === 'Products'">

    </productcomponent>
      




</template>

<script>
import regioncomponent from "@/components/Product/ServiceQualification/sqRegion.vue"
import productcomponent from "@/components/Product/ServiceQualification/sqProduct.vue"
export default {
  components: {
    regioncomponent,
    productcomponent
  },
  data() {
    return {      
      selectedSwitch: "Address",
      switchOptions: [
        {
          name: "Region",
          value: "Address",
        },
        {
          name: "Products",
          value: "Products",
        },
      ],
    }
  },
  methods: {
    chosenSwitch(val) {
      console.log("chosenSwitch::", val);
      if (val.value === 'address') {
        this.selectedSwitch = val.value;
      } else if (val.value === 'products') {
        this.selectedSwitch = val.value;
      }     
    }
  }

}
</script>

<style>

</style>