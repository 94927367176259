<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <div>
    <hlx-modal
      class="upc"
      :modal-active="add_model"
      :height="'330px'"
      :width="'550px'"
      :modal="true"
      @close="add_model = false"
    >
      <template #header>
        <div style="margin-left: 0px">Create order</div>
      </template>
      <template #content>
        <div class="modal-content">
          <div class="modal-input-v2">
            <div class="input-section-v2">
              <hlx-input
                :key="componentkey"
                v-model:value="payloadSpec.name"
                :label-animation="true"
                label-value="Name"
                type="text"
                required="true"
                :clearable="true"
                @at-Input="inputTrue(payloadSpec.name)"
              />
            </div>
            <div class="input-section-v2">
              <hlx-cascader
                :options="categoryOptions"
                :required="true"
                :placeholder-value="'Category'"
                :label-animation="true"
                :label="'label'"
                :prop-value="'value'"
                @selected-array="returnSelectedObject"
              >
              </hlx-cascader>
            </div>
            <div class="input-section-v2">
              <hlx-select
                :key="componentkey"
                v-model:value="payloadSpec.brand"
                :options="Brand"
                :placeholder-value="'Brand '"
                :prop-value="'name'"
                :label="'name'"
                :label-animation="true"
                :clearable="true"
              ></hlx-select>
            </div>
            <div class="input-section-v2">
              <hlx-select
                :key="componentkey"
                v-model:value="relatedParty"
                :options="Manufacturer"
                :placeholder-value="'Manufacturer '"
                :prop-value="'name'"
                :label="'name'"
                :label-animation="true"
                :clearable="true"
              ></hlx-select>
            </div>

            <div class="input-section-v2">
              <hlx-input
                :key="componentkey"
                v-model:value="payloadSpec.validFor.endOfLife"
                :label-value="'End of life'"
                :label-animation="true"
                type="date"
                icon="calendar-regular"
                icon-position="right"
              ></hlx-input>
            </div>
            <div class="input-section-v2">
              <hlx-input
                v-model:value="payloadSpec.validFor.endOfDate"
                :pre-val="payloadSpec?.validFor?.endOfDate"
                :label-value="'End of support'"
                :label-animation="true"
                :custom-error="error"
                :display-error="error"
                :custom-error-message="errorMessage"
                type="date"
                icon="calendar-regular"
                icon-position="right"
              ></hlx-input>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <hlx-button class="secondary sm" @click="add_model = false"
          >Cancel</hlx-button
        >
        <hlx-button
          v-if="enableButton == true"
          class="primary sm"
          style="margin-left: 16px"
          @click="addProductSpec()"
          >Add</hlx-button
        >
        <hlx-button
          v-else
          class="primary sm"
          style="margin-left: 16px"
          :disabled="true"
          >Add</hlx-button
        >
      </template>
    </hlx-modal>
  </div>
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'200px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
  <hlx-drawer
    :show="show"
    :width="'300'"
    position="right"
    :footer="true"
    :header="false"
    :show-close-icon="true"
    @close="closeAddHierarchyModal"
  >
    <template #body>
      <FilterComponent
        :filterpanelname="'productSpecification'"
        @filtered-data="productSpecificationFilter"
      ></FilterComponent>
    </template>
    <template #footer></template>
  </hlx-drawer>

  <div class="app-layout catalog specification">
    <section class="new-right-panel upc">
      <div class="new-right-panel-parent-container no-filter-panel">
        <div id="mySidepanel" class="new-right-panel-left-container"></div>
        <div class="new-right-panel-right-container">
          <section class="new-layout-basic-no-breadcrumb">
            <div class="new-layout-header">
              <span class="new-layout-header-container">
                <span class="schema-header-title"> Resource order</span>
                <!-- <span class="subtitle"> Configured pricing quotes </span> -->
              </span>
            </div>

            <div
              v-if="tableData?.length > 0 || searchvalue"
              class="new-layout-body catalog-v2"
            >
              <!-- search and filter  -->
              <div
                v-if="datalength != 0"
                class="header-utils-and-switch-container"
                style="width: 100%"
              >
                <span class="table-header-utils">
                  <span class="util-items --search"
                    ><hlx-search
                      v-model="searchvalue"
                      @search-key="searchKey"
                    ></hlx-search
                  ></span>

                  <span class="filter-table">
                    <!-- @click="showDrawerRight()" -->
                    <hlx-button class="secondary sm add-btn filterbutton"
                      ><i class="icon-filter-regular"></i>
                      <span v-if="filtertaglength != 0" class="filter-count">{{
                        filtertaglength
                      }}</span>
                    </hlx-button>
                    <hlx-drawer
                      :show="show_right_filter"
                      :width="300"
                      position="right"
                      :header="false"
                      :footer="false"
                      :show-close-icon="true"
                      @close="closeRightFilter"
                    >
                      <template #body>
                        <div style="display: flex">
                          <FilterComponent
                            :filterpanelname="'productSpecification'"
                            @filtered-data="productSpecificationFilter"
                          ></FilterComponent>
                        </div>
                      </template>
                    </hlx-drawer>
                  </span>

                  <span
                    v-if="view === 'Product specifications'"
                    class="util-items util-items-add"
                    ><hlx-button
                      class="primary sm add-btn"
                      style="display: flex; align-items: center; gap: 0.5rem"
                      @click.stop="routeToProductCreationFlow()"
                      ><i
                        class="icon-plus-circle-regular"
                        style="margin-right: 5px"
                      ></i
                      >Create order</hlx-button
                    >
                    <hlx-context-menu
                      style="z-index: 10000"
                      :options="add_context_options"
                      :show="show_add_menu"
                      :style="{ marginTop: '5px', marginRight: '20px' }"
                      @chosen="fromAddContextMenu"
                    ></hlx-context-menu>
                  </span>
                </span>
              </div>

              <!-- TableData -->
              <div class="table-wrap">
                <orderTableComponent
                  order-type="resourceorder"
                  :search-key="searchvalue"
                  @request-change="handleRequestStatus"
                ></orderTableComponent>
              </div>
            </div>

            <!-- no data  -->

            <div
              v-else-if="tableData?.length <= 0"
              class="new-layout-body"
              style="
                display: flex !important;
                flex-direction: column;
                align-items: center !important;
                padding: 5%;
              "
            >
              <section class="--illustration-container">
                <img
                  src="@/assets/images/svg/EmptyQuoteTableIllustration.svg"
                />
                <hlx-button
                  class="primary sm add-btn"
                  style="display: flex; align-items: center; gap: 0.5rem"
                  @click.stop="routeToProductCreationFlow()"
                  ><i
                    class="icon-plus-circle-regular"
                    style="margin-top: 5px"
                  ></i
                  >Create order</hlx-button
                >
              </section>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  MLTAxiosInstance,
  // DMNAxiosInstance,
  // MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
import FilterComponent from "@/components/filterComponent.vue";
// import quoteTable from "@/components/UPC/CPQ/quoteTableComponent.vue";
// order table
import orderTableComponent from "@/components/UPC/Order management/productOrder/orderTableComponent.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    FilterComponent,
    orderTableComponent,
  },
  props: {
    value: {
      type: String,
      default: "All",
    },
  },
  data() {
    return {
      emailTemp: "",
      Brand: [],
      error: false,
      errorMessage: "",
      componentkey: 0,
      datalength: 0,
      enableButton: false,
      payloadSpec: {
        name: "",
        category: "",
        validFor: {
          endOfLife: "",
          endOfDate: "",
        },
        relatedParty: [],
        unitOfMeasure: {
          name: "",
        },
      },
      categoryOptions: [],
      add_model: false,
      show: false,
      externalRowsPerPage: 10,
      show_right_filter: false,
      currencylist: [],
      filterPanelName: "",
      recurringChargePeriodType: {},
      priceobj: {},
      company: this.$cookies.get("company"),
      showpricedata: {},
      showPriceValue: false,
      offeringfiltercondition: [],
      offeringprice: [],
      filteredarray: [],
      filteredobj: { $in: [] },
      productofferingfilteredobj: { $in: [] },
      productofferingfilter: [],
      // quoteFilterOption: {
      //   type: "search",
      //   module: "productSpecification",
      //   isPaginated: true,
      //   paginatedQuery: {
      //     rowsPerPage: 10,
      //     currentPage: 1,
      //   },
      //   isNeedToBeSort: false,
      //   sortFieldAndOrder: {
      //     // "name":-1
      //   },
      //   limit: 10,
      //   filterQuery: {
      //     // "productSpecCharacteristic.productSpecCharacteristicValue.value": "",
      //   },
      //   searchQuery: "",
      // },
      offeringfilterquery: {
        type: "filter",
        module: "productOffering",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      },
      filterData: [],
      currentvalue: "ALL",
      sliderfilter: false,
      sliderMin: 0,
      sliderMax: 0,
      filterprice: {
        units: "ALL",
        min: 0,
        max: 0,
        key: "productOfferingPrice",
      },
      totalpageno: 0,
      containerleft: 0,
      containertop: 0,
      userInfo: this.$store.state.userInfo,
      filtermin: 0,
      filtermax: 0,
      filterloop: {},
      slidedata: 0,
      min: 0,
      max: 0,
      searchfilterdata: {},
      originaldata: {},
      filterdata: {},
      duplicatefilterdata: {},
      filterdat: {},
      filtered_options: {},
      filter_theads: [
        {
          prop: "place",
          label: "Place",
        },
        {
          prop: "channel",
          label: "Channel",
        },
        {
          prop: "category",
          label: "Category",
        },
        {
          prop: "productOfferingTerm",
          label: "Product offering term",
        },
        {
          prop: "marketSegment",
          label: "Market segment",
        },
        {
          prop: "productOfferingPrice",
          label: "Product offering price",
        },
      ],

      quoteThead: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: false,
          prop: "description",
          label: "Name",
          sortable: false,
          resizable: false,
          width: "auto",
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 1,
          disabled: false,
          prop: "state",
          label: "Status",
          sortable: false,
          resizable: false,
          width: 115,
          align: "center",
        },
        {
          name: "category",
          checked: true,
          id: 1,
          disabled: false,
          prop: "category",
          label: "Category",
          sortable: false,
          resizable: false,
          width: "auto",
          align: "left",
        },
        {
          name: "Item",
          checked: true,
          id: 1,
          disabled: false,
          prop: "quoteItem",
          label: "Item",
          sortable: false,
          resizable: false,
          width: 60,
          align: "left",
        },
        {
          name: "Quote date",
          checked: true,
          id: 1,
          disabled: false,
          prop: "quoteDate",
          label: "Quote date",
          sortable: false,
          resizable: false,
          width: 250,
          align: "left",
        },
        {
          name: "Price",
          checked: true,
          id: 1,
          disabled: false,
          prop: "quoteTotalPrice",
          label: "Price",
          sortable: false,
          resizable: false,
          width: "auto",
          align: "left",
        },
        {
          name: "Version",
          checked: true,
          id: 1,
          disabled: false,
          prop: "version",
          label: "Version",
          sortable: false,
          resizable: false,
          width: "auto",
          align: "left",
        },
      ],
      quoteFilterOption: {
        type: "filter",
        module: "resourceorder",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {},
        searchQuery: "1",
      },
      quoteTheadC: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: false,
          prop: "description",
          label: "Name",
          sortable: false,
          resizable: false,
          width: null,
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 1,
          disabled: false,
          prop: "state",
          label: "Status",
          sortable: false,
          resizable: false,
          width: null,
          align: "left",
        },
        {
          name: "category",
          checked: true,
          id: 1,
          disabled: false,
          prop: "category",
          label: "Category",
          sortable: false,
          resizable: false,
          width: "auto",
          align: "left",
        },
        {
          name: "Item",
          checked: true,
          id: 1,
          disabled: false,
          prop: "quoteItem",
          label: "Item",
          sortable: false,
          resizable: false,
          width: "auto",
          align: "left",
        },
        {
          name: "Quote date",
          checked: true,
          id: 1,
          disabled: false,
          prop: "quoteDate",
          label: "Quote date",
          sortable: false,
          resizable: false,
          width: 250,
          align: "left",
        },
        {
          name: "Price",
          checked: true,
          id: 1,
          disabled: false,
          prop: "quoteTotalPrice",
          label: "Price",
          sortable: false,
          resizable: false,
          width: 250,
          align: "left",
        },
        {
          name: "Version",
          checked: true,
          id: 1,
          disabled: false,
          prop: "version",
          label: "Version",
          sortable: false,
          resizable: false,
          width: 250,
          align: "left",
        },
      ],

      modal_active6: false,
      dropdown_data: [
        { name: "AND", value: "AND" },
        { name: "Between", value: "Between" },
        { name: "OR", value: "OR" },
      ],
      show_right: false,
      token: this.$route.params.token,
      editMode: true,
      editPermission: [],
      viewPermission: [],
      unique: Math.floor(Math.random() * 1000 + 1),
      delete_modal_active: false,
      searchtabledata: [],
      // businessrulepagespinner: true,
      // businessrulepagetable: false,
      duplicatetabledata: [],
      config_nav_items: [],
      delete_name: "",
      datum: {},
      success: false,
      success_msg: "",
      notification: {},
      expressions: [],
      view: "Product specifications",
      schema_type_switch: [
        { name: "Product specifications", value: "Product specifications" },
      ],
      selectedValue: "",
      // selectedValue: "Product offerings",
      show_add_menu: false,
      show_crud_menu: false,
      theads: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: false,
          prop: "name",
          label: "Name",
          sortable: false,
          resizable: false,
          width: 80,
          align: "left",
          fixed: "left",
        },
        {
          name: "Type",
          checked: true,
          id: 5,
          disabled: false,
          prop: "type",
          label: "Type",
          sortable: false,
          resizable: false,
          width: 80,
          align: "left",
        },
        {
          name: "Category",
          checked: true,
          id: 2,
          disabled: false,
          prop: "category",
          label: "Category",
          sortable: false,
          resizable: false,
          width: 90,
          align: "left",
        },
        {
          name: "Channel",
          checked: true,
          id: 2,
          disabled: false,
          prop: "channel",
          label: "Channel",
          sortable: false,
          resizable: false,
          width: 90,
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 4,
          disabled: false,
          prop: "status",
          label: "Status",
          sortable: false,
          resizable: false,
          width: 70,
          align: "left",
        },
        {
          name: "Market",
          checked: true,
          id: 3,
          disabled: false,
          prop: "market",
          label: "Market",
          sortable: false,
          resizable: false,
          width: 90,
          align: "left",
        },
        {
          name: "Place",
          checked: true,
          id: 5,
          disabled: false,
          prop: "place",
          label: "Place",
          sortable: false,
          resizable: false,
          width: 90,
          align: "left",
        },
        {
          name: "Validity",
          checked: true,
          id: 5,
          disabled: false,
          prop: "validity",
          label: "Validity",
          sortable: false,
          resizable: false,
          width: 190,
          align: "left",
        },
        {
          name: "Last modified",
          checked: true,
          id: 5,
          disabled: false,
          prop: "last_updated_at",
          label: "Last modified",
          sortable: false,
          resizable: false,
          width: 170,
          align: "left",
        },
      ],
      theads1: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: false,
          prop: "name",
          label: "Name",
          sortable: false,
          resizable: false,
          width: 80,
          align: "left",
        },
        {
          name: "Type",
          checked: true,
          id: 5,
          disabled: false,
          prop: "type",
          label: "Type",
          sortable: false,
          resizable: false,
          width: 80,
          align: "left",
        },
        {
          name: "Category",
          checked: true,
          id: 2,
          disabled: false,
          prop: "category",
          label: "Category",
          sortable: false,
          resizable: false,
          width: null,
          align: "left",
        },
        {
          name: "Channel",
          checked: true,
          id: 2,
          disabled: false,
          prop: "channel",
          label: "Channel",
          sortable: false,
          resizable: false,
          width: null,
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 4,
          disabled: false,
          prop: "status",
          label: "Status",
          sortable: false,
          resizable: false,
          width: 90,
          align: "left",
        },
        {
          name: "Market",
          checked: true,
          id: 3,
          disabled: false,
          prop: "market",
          label: "Market",
          sortable: false,
          resizable: false,
          width: 90,
          align: "center",
        },
        {
          name: "Place",
          checked: true,
          id: 5,
          disabled: false,
          prop: "place",
          label: "Place",
          sortable: false,
          resizable: false,
          width: 90,
          align: "center",
        },
        {
          name: "Validity",
          checked: true,
          id: 5,
          disabled: false,
          prop: "validity",
          label: "Validity",
          sortable: false,
          resizable: false,
          width: 120,
          align: "left",
        },
        {
          name: "Last modified",
          checked: true,
          id: 5,
          disabled: false,
          prop: "last_updated_at",
          label: "Last modified",
          sortable: false,
          resizable: false,
          width: 175,
          align: "left",
        },
      ],
      filtertabledata: [
        {
          type: "Product Offering",
          relatedParty: [],
          _id: "64e5bc614ab1048d37e74e59",
          name: "My Quick BB Offer",
          bundledProductOffering: [],
          isBundle: false,
          isSellable: true,
          lastUpdate: "2023-08-23T07:56:23.284Z",
          lifecycleStatus: "In-Active",
          place: [],
          channel: [],
          attachment: [],
          productOfferingTerm: [],
          marketSegment: [],
          productOfferingPrice: [],
          agreement: [],
          prodSpecCharValueUse: [],
          __v: 0,
        },
        {
          _id: "64eb64eba4c164e0f5e55d0a",
          name: "check",
          bundledProductOffering: [],
          description: "check product offerings",
          priceType: "Recuring",
          isBundle: false,
          isSellable: true,
          lastUpdate: "2023-08-27T14:54:59.656Z",
          lifecycleStatus: "Active",
          place: [{ name: "Canada" }],
          channel: [{ name: "Sales channel" }],
          attachment: [],
          category: [{ name: "Bike", id: "64d19db97c1374c67da7f2e4" }],
          productOfferingTerm: [],
          marketSegment: [{ name: "France" }],
          productOfferingPrice: [],
          agreement: [],
          prodSpecCharValueUse: [
            {
              name: "CPE Model",
              productSpecCharacteristicValue: [
                { isDefault: false, value: "Advanced" },
              ],
            },
            {
              name: "CPE Model",
              productSpecCharacteristicValue: [
                { isDefault: false, value: "Premium" },
              ],
            },
          ],
          type: "Product Offering",
          __v: 0,
          relatedParty: [{ name: "John" }],
          productSpecification: {
            name: "new name",
            id: "64ec05451c352016b39dfc06",
          },
        },
        {
          _id: "64ebfceca868bd9c44a7104a",
          name: "BB offer1",
          bundledProductOffering: [],
          description: "offer type exaample",
          priceType: "Recuring",
          isBundle: false,
          isSellable: true,
          lastUpdate: "2023-08-28T01:48:28.059Z",
          lifecycleStatus: "Active",
          place: [{ name: "Canada" }],
          channel: [
            { name: "Sales channel" },
            { name: "Distribution channel" },
          ],
          attachment: [],
          category: [{ name: "Bike", id: "64d19db97c1374c67da7f2e4" }],
          productOfferingTerm: [],
          marketSegment: [{ name: "France" }],
          productOfferingPrice: [],
          agreement: [],
          prodSpecCharValueUse: [
            {
              name: "CPE Model",
              productSpecCharacteristicValue: [
                { isDefault: false, value: "Advanced" },
              ],
            },
            {
              name: "CPE Model",
              productSpecCharacteristicValue: [
                { isDefault: false, value: "Premium" },
              ],
            },
          ],
          type: "Product Offering",
          relatedParty: [{ name: "Alex" }],
          __v: 0,
        },
        {
          _id: "64ec49c17c2c382dacaac6ae",
          name: "testOffering",
          bundledProductOffering: [],
          description: "",
          priceType: "",
          isBundle: false,
          isSellable: true,
          lastUpdate: "2023-08-28T07:05:12.105Z",
          lifecycleStatus: "Active",
          place: [""],
          channel: [],
          attachment: [],
          category: [],
          productOfferingTerm: [],
          marketSegment: [],
          productOfferingPrice: [],
          agreement: [],
          prodSpecCharValueUse: [
            {
              name: "CPE Model",
              productSpecCharacteristicValue: [
                { isDefault: false, value: "Advanced" },
              ],
            },
            {
              name: "CPE Model",
              productSpecCharacteristicValue: [
                { isDefault: false, value: "Premium" },
              ],
            },
          ],
          type: "Product Offering",
          relatedParty: [{ name: "John doe" }],
          __v: 0,
        },
      ],
      tableData: [],
      logData: [],
      re_arrange: false,
      OfferingcurrPage: 1,
      OfferingrowsPerPage: 10,
      SpecificationcurrPage: 1,
      SpecificationrowsPerPage: 10,
      currPagePS: 1,
      rowsPerPagePS: 10,
      filtercurrPage: 1,
      filterrowsPerPage: 10,
      // duplicateTableData: [],
      duplicateTheads: [],
      duplicateTheads1: [],
      searchvalue: "",
      id: "tenant1",
      name: "myself",
      deleteobj: {},
      filterobj: {},
      filtervalueobj: {},
      searchfiltervalue: "",
      filtertagvalue: [],
      filtertaglength: 0,
      filteredtabledata: [],
      fdata: [],
      parentelement: "",
      parentdata: {},
      parentarray: [],
      Manufacturer: [],
      unitOfMeasure: [],
      unit: "",
      relatedParty: "",
      endOfDate: "",
      onRequest: true,
    };
  },
  computed: {
    ...mapGetters(["currencyList"]),
    // records(){
    //   return this.$store.getters.allRule
    // },
    filteroptions() {
      return this.filterobj;
    },
    paginatedData() {
      if (this.view === "Product offerings") {
        // const start = (this.currPagePO - 1) * this.rowsPerPagePO;
        // const end = start + this.rowsPerPagePO;
        return this.tableData;
      } else if (this.view === "Product specifications") {
        // const start = (this.currPagePS - 1) * this.rowsPerPagePS;
        // const end = start + this.rowsPerPagePS;
        // ;
        return this.tableData;
        // .slice(start, end);
      } else {
        return this.tableData;
      }
    },
    showingFrom() {
      if (this.view === "Product offerings") {
        return (this.currPagePO - 1) * this.rowsPerPagePO + 1;
      } else {
        return (this.currPagePS - 1) * this.rowsPerPagePS + 1;
      }
    },
    showingTo() {
      if (this.view === "Product offerings") {
        const lastItem = this.showingFrom + this.rowsPerPagePO - 1;
        return lastItem > this.totalRows ? this.totalRows : lastItem;
      } else {
        const lastItem = this.showingFrom + this.rowsPerPagePS - 1;
        return lastItem > this.totalRows ? this.totalRows : lastItem;
      }
    },
    totalRows() {
      return this.tableData.length;
    },
  },
  watch: {
    // filtertagvalue: {
    //   handler(val) {
    //     if (val.length === 0) {
    //       val;
    //       //  this.datacalling()
    //     }
    //   },
    //   immediate: true,
    //   deep: true
    // },
    "$route.query.for": {
      handler(query) {
        if (!query) return;
        // console.log(query.trim());
        if (query.trim() == "offering") {
          this.selectedValue = "Product offerings";
          this.handleCurrentChange(this.selectedValue);
        } else if (query.trim() == "specification") {
          this.selectedValue = "Product specifications";
          this.handleCurrentChange(this.selectedValue);
          // this.$nextTick(() => {
          //   console.log(this.selectedValue,'jerry');
          //   this.handleCurrentChange(this.selectedValue);
          // });
        }
      },
      deep: true,
      // immediate:true
    },
    // totalpageno: {
    //   handler(data) {

    //   },
    //   immediate: true,
    //   deep: true
    // },
    tableData: {
      handler() {
        // ;
        //         data?.forEach((e) => {
        //   // ;
        //   // if (e.context !== undefined) {
        //     e.context = false;
        //   // }
        // })
        // ;
        // if(data.length>1)
        // {
        //   this.tableData = data.sort(
        //     (a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate)
        //   );
        // }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    const filterResultData = await UPCAxiosInstance.post(
      `util/filterData`,
      this.quoteFilterOption
    );
    console.log("🚀 ~ mounted ~ filterResultData:", filterResultData);
    this.datalength = filterResultData.data.total;
    this.tableData = filterResultData.data.data;
    this.totalpageno = filterResultData.data.total;
    this.paginatedData;

    // this.duplicateTheads = JSON.parse(JSON.stringify(this.theads));
    // this.duplicateTheads1 = JSON.parse(JSON.stringify(this.theads1));

    // const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    // this.company = val.data.company;
    // this.userId = val.data.company;
    // const access = await MLTAxiosInstance.get(
    //   `partyRole/get/${val.data.userId}`
    // );

    // let arr = access.data;

    //  const categorydata = await UPCAxiosInstance.get(
    //   `catalogManagement/category/dropdown`
    // );
    // this.categoryOptions = await categorydata.data?.filter((item) => item);
    // const unitOfmesure = await DMNAxiosInstance.get(
    //   `mapRef/collection/unitOfMeasure`
    // );
    // this.unitOfMeasure = unitOfmesure.data.data;
    // const manufacturer = await DMNAxiosInstance.get(
    //   `mapRef/collection/manufacturers`
    // );
    // console.log(manufacturer.data.data, "manufac");
    // this.Manufacturer = manufacturer.data.data;

    // const brand = await DMNAxiosInstance.get(`mapRef/collection/Brand`);
    // console.log(brand.data.data, "manu");
    // this.Brand = brand.data.data;
    // this.editPermission = [];
    // if (arr && Object.keys(arr).length > 0) {
    //   if (arr.permission.User_settings.edit == false) {
    //     this.editPermission.push("setting");
    //   }
    //   if (arr.permission.User_settings.view == false) {
    //     this.viewPermission.push("setting");
    //   }
    //   if (arr.permission.Schema.edit == false) {
    //     this.editPermission.push("schema");
    //   }
    //   if (arr.permission.Schema.view == false) {
    //     this.viewPermission.push("Schema");
    //   }
    //   if (arr.permission.Test_data.view == false) {
    //     this.viewPermission.push("Database", "Execute");
    //   }
    //   if (arr.permission.Test_data.edit == false) {
    //     this.editPermission.push("testdata");
    //   }
    //   if (arr.permission.Rules.view == false) {
    //     this.viewPermission.push("Business rule");
    //   }
    //   if (arr.permission.Rules.edit == false) {
    //     this.editPermission.push("Business rule");
    //   }
    //   if (this.editPermission.includes("Business rule") == true) {
    //     this.editMode = false;
    //   }
    // }

    // document.addEventListener("click", (event) => {
    //   // console.log(this.tableData,"zsdxfghjk");
    //   if (!event.target.classList.contains("icon-more-vertical-filled")) {
    //     // ;
    //     // console.log(this.tableData,"zsdxfghjk");
    //     this.tableData?.forEach((e) => {
    //       // ;
    //       // console.log(e.context,"zsdxfghjk");
    //       if (e.context !== undefined) {
    //         e.context = false;
    //       }
    //     });
    //   }
    // });

    // this.$store.commit("token", this.$route.params.token);
    // // this.headvalue =

    // // await this.$store.dispatch('fetchall')
    // // await this.$store.dispatch('fetchdecisions')

    // // this.view = "All";
    // // //

    // // this.tableData= this.$store.getters.allRule
    // // this.refreshTable();

    // document.addEventListener("click", (event) => {
    //   if (!event.target.classList.contains("add-btn")) {
    //     this.show_add_menu = false;
    //   }
    //   if (!event.target.classList.contains("icon-more-vertical-filled")) {
    //     // const elements = document.querySelectorAll("[class*=crud]");
    //     // for (const element of elements) {
    //     //   element.style.display = "none";
    //     // }
    //     // this.tableData.forEach((e) => {
    //     //   if (e.context !== undefined) {
    //     //     e.context.show = false
    //     //   }else{
    //     //     e.context.show = false
    //     //   }
    //     // })
    //   }
    // });
    // await this.$store.dispatch("initPermissionAnalayzer");

    //  //
  },

  methods: {
    handleRequestStatus(value) {
      console.log("🚀 ~ handleRequestStatus ~ value:", value);
      this.onRequest = value;
    },
    categoryLeaf(text) {
      if (text) {
        let lastWord = text.substring(text.lastIndexOf("/") + 1);
        if (lastWord) {
          let title = lastWord.charAt(0).toUpperCase() + lastWord.slice(1);
          return title;
        } else {
          let title = text.charAt(0).toUpperCase() + text.slice(1);
          return title;
        }
      }
    },
    async capitalizeFirstWord(val) {
      let str = val;
      console.log(str, "testtt str");
      return await Promise.all(str.charAt(0).toUpperCase() + str.slice(1));
    },
    inputTrued($event, val) {
      val;
      if (this.payloadSpec.name) {
        this.enableButton = true;
      } else {
        this.enableButton = false;
      }
    },
    inputTrue(val) {
      if (val) {
        if (this.payloadSpec.name) {
          this.enableButton = true;
        } else {
          this.enableButton = false;
        }
      } else {
        this.enableButton = false;
      }
    },

    async addProductSpec() {
      if (
        this.payloadSpec.validFor.endOfLife != "" &&
        this.payloadSpec.validFor.endOfDate
      ) {
        const endOfLifeDate = new Date(this.payloadSpec.validFor.endOfLife);
        const endOfSupportDate = new Date(this.payloadSpec.validFor.endOfDate);
        if (endOfLifeDate <= endOfSupportDate) {
          if (this.payloadSpec.category) {
            this.payloadSpec.category = this.payloadSpec.category
              .map((item) => item.toLowerCase())
              .join("/");
          }
          this.payloadSpec.unitOfMeasure = { name: this.unit };
          this.payloadSpec.relatedParty = [{ name: this.relatedParty }];
          const addSpecification = await UPCAxiosInstance.post(
            `/catalogManagement/productSpecification`,
            this.payloadSpec
          );
          if (addSpecification.data) {
            this.datalength += 1;
            this.notification = {
              type: "success",
              message: `Nice work! Your new product specification ${addSpecification.data.name}  is now in the list!`,
            };
            this.add_model = false;
            this.payloadSpec = {
              name: "",
              category: "",
              validFor: {
                endOfLife: "",
                endOfDate: "",
              },
              relatedParty: [],
              unitOfMeasure: {
                name: "",
              },
            };
            this.refreshTable();
          } else {
            this.notification = {
              type: "success",
              message: `Nice work! Your product specification ${this.payloadSpec.name}  is already in the list!`,
            };
            this.add_model = false;
            this.payloadSpec = {
              name: "",
              category: "",
              validFor: {
                endOfLife: "",
                endOfDate: "",
              },
              relatedParty: [],
              unitOfMeasure: {
                name: "",
              },
            };
            this.refreshTable();
          }
        } else {
          this.error = true;
          this.errorMessage = "End of support is less than end of life.";
        }
      } else {
        if (this.payloadSpec.category) {
          this.payloadSpec.category = this.payloadSpec.category
            .map((item) => item.toLowerCase())
            .join("/");
        }
        this.payloadSpec.unitOfMeasure = { name: this.unit };
        this.payloadSpec.relatedParty = [{ name: this.relatedParty }];
        const addSpecification = await UPCAxiosInstance.post(
          `/catalogManagement/productSpecification`,
          this.payloadSpec
        );
        if (addSpecification.data) {
          this.datalength += 1;
          this.notification = {
            type: "success",
            message: `Nice work! Your new product specification ${addSpecification.data.name}  is now in the list!`,
          };
          this.add_model = false;
          this.payloadSpec = {
            name: "",
            category: "",
            validFor: {
              endOfLife: "",
              endOfDate: "",
            },
            relatedParty: [],
            unitOfMeasure: {
              name: "",
            },
          };
          this.refreshTable();
        } else {
          this.notification = {
            type: "success",
            message: `Nice work! Your product specification ${this.payloadSpec.name}  is already in the list!`,
          };
          this.add_model = false;
          this.payloadSpec = {
            name: "",
            category: "",
            validFor: {
              endOfLife: "",
              endOfDate: "",
            },
            relatedParty: [],
            unitOfMeasure: {
              name: "",
            },
          };
          this.refreshTable();
        }
      }
    },

    async specificationdataCalling(currentPage, rowsPerPage) {
      // ;
      if (this.filtertagvalue.length === 0 && this.searchvalue == "") {
        // var totalData = await UPCAxiosInstance.get(`catalogManagement/productSpecification`)
        // this.totalpageno = totalData.data.length
        // var paginatedTableData = await UPCAxiosInstance.get(
        //   `catalogManagement/productSpecification?pageNo=${currentPage}&size=${rowsPerPage}`
        // );
        // this.tableData = paginatedTableData.data;
        this.quoteFilterOption.type = "filter";
        this.quoteFilterOption["paginatedQuery"]["currentPage"] = currentPage;
        this.quoteFilterOption["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          this.quoteFilterOption
        );

        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data.total;
        this.paginatedData;
      } else if (this.quoteFilterOption.type == "filter") {
        if (this.filtertagvalue.length != 0) {
          this.quoteFilterOption["paginatedQuery"]["currentPage"] = currentPage;
          this.quoteFilterOption["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
          const filterResultData = await UPCAxiosInstance.post(
            `util/filterData`,
            this.quoteFilterOption
          );

          this.tableData = filterResultData.data.data;
          this.totalpageno = filterResultData.data.total;
          this.tableData?.map((e) => {
            // ;
            if (e.context == undefined) {
              e.context = false;
            }
          });
        } else {
          this.quoteFilterOption.type = "search";
          this.quoteFilterOption["paginatedQuery"]["currentPage"] = currentPage;
          this.quoteFilterOption["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
          const filterResultData = await UPCAxiosInstance.post(
            `util/filterData`,
            this.quoteFilterOption
          );

          this.tableData = filterResultData.data.data;
          this.totalpageno = filterResultData.data.total;
          this.tableData?.map((e) => {
            // ;
            if (e.context == undefined) {
              e.context = false;
            }
          });
        }
      } else if (this.quoteFilterOption.type == "search") {
        if (this.searchvalue != "") {
          this.quoteFilterOption["paginatedQuery"]["currentPage"] = currentPage;
          this.quoteFilterOption["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
          const filterResultData = await UPCAxiosInstance.post(
            `util/filterData`,
            this.quoteFilterOption
          );

          this.tableData = filterResultData.data.data;
          this.totalpageno = filterResultData.data.total;
          this.tableData?.map((e) => {
            // ;
            if (e.context == undefined) {
              e.context = false;
            }
          });
        } else {
          this.quoteFilterOption.type = "filter";
          this.quoteFilterOption["paginatedQuery"]["currentPage"] = currentPage;
          this.quoteFilterOption["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
          const filterResultData = await UPCAxiosInstance.post(
            `util/filterData`,
            this.quoteFilterOption
          );

          this.tableData = filterResultData.data.data;
          this.totalpageno = filterResultData.data.total;
          this.tableData?.map((e) => {
            // ;
            if (e.context == undefined) {
              e.context = false;
            }
          });
        }
      }
    },
    async productSpecificationFilter(filterSelectedvalue, filterQuery) {
      // ;
      this.filtertagvalue = filterSelectedvalue;
      this.filtertaglength = filterSelectedvalue.length;
      if (this.filtertagvalue.length != 0) {
        // this.totalpageno = spectotalpageno
        // this.tableData = spectabledata
        this.quoteFilterOption = filterQuery;
        this.quoteFilterOption.type = "filter";
        if (this.searchvalue != "") {
          this.quoteFilterOption["searchQuery"] = this.searchvalue;
        }
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          this.quoteFilterOption
        );
        // console.log(filterResultData.data.data,'la');

        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data.total;
        // ;
      } else {
        this.SpecificationcurrPage = 1;
        this.SpecificationrowsPerPage = 10;
        this.specificationdataCalling(
          this.SpecificationcurrPage,
          this.SpecificationrowsPerPage
        );
      }
    },
    showDrawerRight() {
      this.show_right_filter = true;
    },
    closeRightFilter() {
      this.show_right_filter = false;
    },

    async sliderval(val1, val2) {
      val1;
      val2;
      this.filterprice.checked = true;
      this.filterprice.value =
        this.filterprice.min + "-" + this.filterprice.max;
      // let obj = {};
      // obj.filter = this.filtertagvalue;
      if (this.sliderfilter == false) {
        this.filtertagvalue.push(this.filterprice);
        this.sliderfilter = true;
      }
      let obj = {};
      obj.filter = this.filtertagvalue;
      obj.currentpage = this.filtercurrPage;
      obj.rowsperpage = this.filterrowsPerPage;
      const filtertabledata = await UPCAxiosInstance.post(
        `catalogManagement/productOffering/filter`,
        obj
      );
      this.totalpageno = await filtertabledata.data.totallength;
      this.tableData = filtertabledata.data.data;
      this.tableData?.map((e) => {
        if (e.context == undefined) {
          e.context = false;
        }
      });
    },
    tooltipover(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis(data1, index1);
    },
    tooltipover1(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis1(data1, index1);
    },
    tooltipleave(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses(data1, index1);
    },
    tooltipleave1(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses1(data1, index1);
    },
    ellipsis(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              let height = tooltip.offsetHeight;
              let top = element1.getBoundingClientRect().top - height;
              let left = element1.getBoundingClientRect().left;
              tooltip.style.display = "block";
              tooltip.style.top = top + "px";
              tooltip.style.left = left + "px";
              return true;
            }
          }
          return false;
        }
      });
    },
    ellipsis1(data, index) {
      this.$nextTick(() => {
        // if(event.target.tagName != 'SPAN')
        // {
        // console.log(data,index,event.target.tagName);
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let height = tooltip.offsetHeight;
              let top = element1.getBoundingClientRect().top - 30;
              top = Math.abs(top);
              let left = element1.getBoundingClientRect().left + 200;
              tooltip.style.display = "block";
              tooltip.style.top = top + "px";
              tooltip.style.left = left + "px";
              return true;
            }
          }
          return false;
        }
        // }
      });
    },
    ellipses(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              tooltip.style.display = "none";
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },
    ellipses1(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              tooltip.style.display = "none";
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },
    async filterdatafunction() {
      // ;
      if (this.selectedvalue == "Product specifications") {
        await this.$store.dispatch("productSpecFilterData");

        this.filterdata = this.$store.getters.productSpecificationFilterData;
        // const alldata = await UPCAxiosInstance.get(`catalogManagement/productSpecification/filterlist/v1`);
        // this.filterdata = alldata.data
        // ;
      } else {
        await this.$store.dispatch("productOfferFilterData");

        this.filterdata = this.$store.getters.productOfferingFilterData;
        Object.keys(this.filterdata).forEach((e) => {
          // ;
          if (e != "_id") {
            this.filterdata[e]?.forEach((ele) => {
              if (typeof ele == "object") {
                if (!ele) return;

                ele.key = e;
                ele.checked = false;
              }
            });
          }
          if (e == "Product offering price") {
            this.filterdata[e]?.forEach((ele) => {
              if (ele.price.amount != "") {
                if (this.offeringprice.includes(ele.price.amount) != true) {
                  this.offeringprice.push(ele.price.amount);
                }
              }
            });
          }
        });

        // this.datacalling()

        // const alldata = await UPCAxiosInstance.get(
        //   `catalogManagement/productOffering/filterlist`
        // );
        // this.filterdata = alldata.data;
        // ;
        // let pricedata = this.filterdata['productOfferingPrice'][this.currentvalue]
        // if(pricedata.length > 1)
        // {
        //   this.filterprice.min = pricedata[0]-1
        // }else if(pricedata.length == 1){
        //    this.filterprice.min = 0
        // }
        // this.filterprice.max = pricedata[pricedata.length - 1]+1
        // this.sliderMin = this.filterprice.min
        // this.sliderMax = this.filterprice.max
        // this.filterprice.min = this.filterdata['productOfferingPrice'][0].min
        // this.sliderMin = this.filterprice.min - 1
        // this.filterprice.max = this.filterdata['productOfferingPrice'][0].max
        // this.sliderMax = this.filterprice.max - 1
        // ,typeof(this.filterprice.max));
      }
    },
    async switchfilterdatafunction(val) {
      if (val == "Product specifications") {
        await this.$store.dispatch("productSpecFilterData");

        this.filterdata = this.$store.getters.productSpecificationFilterData;
      } else {
        await this.$store.dispatch("productOfferFilterData");

        this.filterdata = this.$store.getters.productOfferingFilterData;
        Object.keys(this.filterdata).forEach((e) => {
          // ;
          if (e != "_id") {
            this.filterdata[e]?.forEach((ele) => {
              if (typeof ele == "object") {
                if (!ele) return;

                ele.checked = false;
              }
            });
          }
          if (e == "Product offering price") {
            this.filterdata[e]?.forEach((ele) => {
              if (ele.price.amount != "") {
                if (this.offeringprice.includes(ele.price.amount) != true) {
                  this.offeringprice.push(ele.price.amount);
                  // );
                }
              }
            });
          }
        });
      }
    },
    insertSpaces(string) {
      let str = string
        .replace(/(_|-)/g, " ")
        .trim()
        .replace(/\w\S*/g, function (str) {
          return str.charAt(0).toUpperCase() + str.substr(1);
        })
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
      return str;
    },
    mindata(val) {
      this.min = val;
    },
    maxdata(val) {
      this.max = val;
    },
    openNav() {
      //  document.getElementById("mySidepanel").style.width = "250px";
      document.getElementById("mySidepanel").style.visibility = "visible";
      console.log("filter side pannala");
    },
    closeNav() {
      document.getElementById("mySidepanel").style.visibility = "hidden";
    },
    addOffer() {
      // this.$router.push("/UPC/product-catalog-table/product_offering/add");
    },
    routeToProductCreationFlow() {
      // this.add_model = true;
      // this.componentkey += 1;
      // this.payloadSpec = {
      //   name: "",
      //   category: "",
      //   validFor: {
      //     endOfLife: "",
      //     endOfDate: "",
      //   },
      //   relatedParty: [],
      //   unitOfMeasure: {
      //     name: "",
      //   },
      // };
      // this.$router.push({ name: "CPQDefaultTemplate" });
    },
    change(val) {
      if (this.filtertagvalue.length == 0) {
        let obj = {};
        obj.value = val;
        this.filtertagvalue.push(obj);
      }
    },
    searchfilter($event, key) {
      this.searchfilterdata[key] = $event;
      this.searchfiltervalue = $event;
      if (
        this.searchfiltervalue &&
        this.searchfiltervalue != "" &&
        this.originaldata != undefined
      ) {
        // ;
        this.filterdat[key] = this.originaldata[key].filter((item) => {
          return item.value
            .toLowerCase()
            .toString()
            .includes(this.searchfiltervalue.toLowerCase());
        });
      } else {
        this.filterdat = JSON.parse(JSON.stringify(this.originaldata));
      }
    },
    selectedvalue($event, data) {
      let finaldata = [];
      //
      //
      let arr_data = $event;

      let arr = [];
      arr_data.forEach((e) => {
        if (e.checked == true) {
          //
          arr.push(e.value);
        }
      });
      this.filtervalueobj[data] = arr;

      Object.keys(this.filtervalueobj).forEach((e) => {
        finaldata = [];
        this.filtervalueobj[e].forEach((ele) => {
          this.tableData?.forEach((element) => {
            if (element[e] === ele) {
              finaldata.push(element);
            } else if (element[e] === null) {
              finaldata.push(element);
            }
          });
        });
      });
      this.tableData = finaldata;
      //
      if (Object.keys(this.filtervalueobj).length <= 1) {
        this.filterFunction();
        //
      } else {
        let x = 0;
        let y = 0;
        while (x < Object.keys(this.filterobj).length) {
          while (y < Object.keys(this.filtervalueobj).length) {
            if (
              Object.keys(this.filterobj)[x] !=
              Object.keys(this.filtervalueobj)[y]
            ) {
              var arr5 = [];
              let arr6 = [];
              let arr7 = [];
              finaldata.forEach((element) => {
                arr5.push(element[Object.keys(this.filterobj)[x]]);
              });
              arr5.forEach((element) => {
                if (!arr6.includes(element)) {
                  arr6.push(element);
                }
              });
              arr6.forEach((element) => {
                let obj = {};
                //
                // if(element != null)
                // {
                obj["value"] = element;
                obj["name"] = element;
                obj.checked = true;
                obj.disabled = false;
                arr7.push(obj);
              });
              this.filterobj[Object.keys(this.filterobj)] = arr7;
            }
            y++;
          }
          x++;
        }
      }
      this.filterobj[data] = $event;
    },
    closeRight() {
      this.show_right = false;
    },
    computedTableAction(data) {
      console.log("🚀 ~ computedTableAction ~ data:", data);
      let options = [
        {
          label: "Edit",
          icon: "icon-file-text-edit-regular",
        },
        {
          label: "Clone",
          icon: "icon-copy-alt-regular",
        },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
        // {
        //   label: "Send an email",
        //   icon: "icon-envelope-check-regular",
        // },
        {
          label: "Preview",
          icon: "icon-caret-left-circle-regular",
        },
      ];
      let updatedOptions = options.filter((option) => {
        if (
          data &&
          data.status &&
          data.status.toLowerCase() == "draft" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "active" &&
          ["Draft", "Mark as active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.state &&
          !["inprogress"].includes(data.state.toLowerCase()) &&
          ["Edit"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "inactive" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else {
          return true;
        }
      });
      console.log("🚀 ~ updatedOptions ~ updatedOptions:", updatedOptions);
      return updatedOptions;
    },
    getWidth(id) {
      const descriptionDiv = document.getElementById(id);
      if (descriptionDiv) {
        // //
        return descriptionDiv.offsetWidth + "px";
      }
    },
    applyColumns(val) {
      // ;
      this.duplicateTheads = [];
      val.forEach((e) => {
        if (e.checked === true) {
          this.duplicateTheads.push(e);
        }
      });
      if (this.view === "Product offerings") {
        this.product_offerings_theads = [...this.duplicateTheads];
      } else if (this.view === "Product specifications") {
        this.quoteThead = [...this.duplicateTheads];
      }
    },
    updateTheads() {
      if (this.view === "Product offerings") {
        // Use the map function to update the 'checked' property
        this.product_offerings_theads_cc = this.product_offerings_theads_cc.map(
          (thead) => {
            // Check if the current thead has a matching 'prop' in duplicateTheads
            const isMatching = this.product_offerings_theads.some(
              (duplicateThead) => duplicateThead.prop === thead.prop
            );

            // Set the 'checked' property based on whether it's matching
            return { ...thead, checked: isMatching };
          }
        );
      } else if (this.view === "Product specifications") {
        this.quoteTheadC = this.quoteTheadC.map((thead) => {
          // Check if the current thead has a matching 'prop' in duplicateTheads
          const isMatching = this.quoteThead.some(
            (duplicateThead) => duplicateThead.prop === thead.prop
          );

          // Set the 'checked' property based on whether it's matching
          return { ...thead, checked: isMatching };
        });
      }
    },
    arrange(val) {
      // ;
      this.re_arrange = val;
      this.updateTheads();
    },
    convertdateformat(date1) {
      if (date1 != null) {
        let objectDate = new Date(date1);
        if (isNaN(objectDate) == false) {
          let date = objectDate.toLocaleString("default", { day: "numeric" });
          let month = "";
          let format = this.$store.state.defaultSetting.dateFormat;
          if (
            format == "Month DD, YYYY" ||
            format == "mmmm dd, yyyy" ||
            format == "mmmm dd, yyyy"
          ) {
            month = objectDate.toLocaleString("default", { month: "long" });
          } else {
            month = objectDate.toLocaleString("default", { month: "short" });
          }
          let year = objectDate.getFullYear();
          let dateFormat = this.dateFormat(date, month, year, format);
          // let dateformat = month + " " + date + ", " + year;
          return dateFormat;
        }
      }
    },
    async refreshTable() {
      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.quoteFilterOption
      );

      this.tableData = filterResultData.data.data;
      this.totalpageno = filterResultData.data.total;
      this.paginatedData;
    },
    async closeCrudContextMenu($event, ele, i) {
      // //

      if ($event.toLowerCase() === "edit") {
        this.$router.push({
          name: "CPQDefaultTemplate",
          query: { quote_id: ele._id, mode: "edit" },
        });
      } else if ($event.toLowerCase() === "clone") {
        delete ele._id;
        const clonedata = await UPCAxiosInstance.post(
          `/catalogManagement/productSpecification/clone`,
          ele
        );
        this.refreshTable();
        console.log(clonedata);
        if (clonedata.status == 201) {
          this.notification = {
            type: "success",
            message: `Nice work! Your product specification ${ele.name} is cloned!`,
          };
        }
      } else if ($event.toLowerCase() == "delete") {
        this.delete_modal_active = true;
        this.deleteobj = ele;
        this.delete_name = ele.name;
        this.deleterowindex = i;
      } else if ($event && $event.toLowerCase() == "send an email") {
        let quoteInfo = ele;
        console.log("🚀 ~ closeCrudContextMenu ~ quoteInfo:", quoteInfo);
        let sender = quoteInfo.relatedParty.find(
          (item) => item.role == "Seller"
        );
        let sellerEmail = quoteInfo.contactMedium.find(
          (item) => item.mediumType == "sellerEmail"
        );
        let recipient = quoteInfo.relatedParty.find(
          (item) => item.role == "Buyer"
        );
        let buyerEmail = quoteInfo.contactMedium.find(
          (item) => item.mediumType == "buyerEmail"
        );

        let validToDate = Math.round(
          (new Date(quoteInfo.requestedQuoteCompletionDate).getTime() -
            new Date().getTime()) /
            (1000 * 60 * 60 * 24)
        );
        console.log("🚀 ~ closeCrudContextMenu ~ validToDate:", validToDate);
        let tokenPayload = {
          user: {
            email: buyerEmail.characteristic.emailAddress,
            company: recipient.name,
          },
          validTo: validToDate,
        };
        let generatedToken = await MLTAxiosInstance.post(
          "/partymanagement/individual/generateToken",
          tokenPayload
        );
        console.log(
          "🚀 ~ closeCrudContextMenu ~ generatedToken:",
          generatedToken
        );
        generatedToken = generatedToken.data;
        let quoteInvitationEmailContent = `

        <!DOCTYPE html
            PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
        <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
            xmlns:o="urn:schemas-microsoft-com:office:office">
        <head>
            <!--[if gte mso 9]>  <xml>   <o:OfficeDocumentSettings>   <o:AllowPNG/>   <o:PixelsPerInch>96</o:PixelsPerInch>   </o:OfficeDocumentSettings>  </xml>  <![endif]-->
            <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="format-detection" content="date=no" />
            <meta name="format-detection" content="address=no" />
            <meta name="format-detection" content="telephone=no" />
            <meta name="x-apple-disable-message-reformatting" />
            <style type="text/css" media="screen">
                <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900" rel="stylesheet"><link href='https://fonts.googleapis.com/css?family=Open Sans' rel='stylesheet'><link href='https://fonts.googleapis.com/css?family=Quicksand&display=swap' rel='stylesheet'><link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
            </style>
        </head>
        <body class="body"
            style="padding:0 !important; margin:0 !important; display:block !important; min-width:100% !important; width:100% !important; background:#f5f5f5; -webkit-text-size-adjust:none;">
            <!-- MOBILE PREHEADER PUSH ANY CONTENT AFTER THE PREHEADER FROM SHOWING UP INSERT UNDER BODY TAG -->
            <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#f5f5f5">
                <tr>
                    <td align="center" valign="top">
                        <table border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
                            <tr>
                                <td class="td">
                                    <table border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                            <td style="width: 612px;height: 501px;">
                                                <!-- Header -->
                                                <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                    bgcolor="#ffffff">
                                                    <tr>
                                                        <td>
                                                            <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                                class="wrapper">
                                                                <tr>
                                                                    <td>
                                                                        <table border="0" cellpadding="10" cellspacing="0"
                                                                            width="100%" class="wrapper" bgcolor="#f5f5f5">
                                                                            <tr>
                                                                                <!-- <td width="104"><a href="https://halleyx.com"><img src="https://srv451-files.hstgr.io/9e33e5905bbb9f5d/files/public_html/img/halleyx-logo-line-black.05c516d1.svg" height="50" style="display:block; border:none;padding-top: 20px;" alt="logo"></a></td> -->
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <table border="0" cellpadding="0" cellspacing="0"
                                                                            style="border:none;border-collapse:collapse;"
                                                                            width="100%" class="wrapper" bgcolor="#ffffff">
                                                                            <tr>
                                                                                <td width="104"><a
                                                                                        href="https://halleyx.com"><img
                                                                                            src="https://halleyx.com/img/halleyx-logo-line-black.42985758.svg"
                                                                                            height="50"
                                                                                            style="display:block; border:none;padding-top: 20px; padding-left: 30px;"
                                                                                            alt="logo"></a></td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!-- END Header -->
                                                <!-- Start Content -->
                                                <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" height="501"
                                                    cellpadding="0">
                                                    <tr>
                                                        <td bgcolor="#FFFFFF" class="p35-15">
                                                            <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0"
                                                                cellpadding="0">
                                                                <!-- headline -->
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="headline"
                                                                        style="text-align:center;">
                                                                        <img style="width: 15%;
                                                                        object-fit: contain;
                                                                        clip-path: circle();
                                                                        " src="https://srv451-files.hstgr.io/9e33e5905bbb9f5d/files/public_html/img/halleyx-logo-line-black.05c516d1.svg"
                                                                            alt="" srcset="">
                                                                    </td>
                                                                </tr>
                                                                <!-- #headline -->
                                                                <!--  hero-title-1 -->
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="hero-title-1" style="font-family: 'Quicksand';
                                                                        font-style: normal;
                                                                        font-weight: 600;
                                                                        font-size: 22px;
                                                                        padding-top:15px;
                                                                        line-height: 28px;
                                                                        text-align: center;
                                                                        color: #191A15;">
                                                                        ${recipient.name}
                                                                        <br>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="hero-title-1"
                                                                        style="padding-top:25px;padding-left:100px;padding-right:100px;color:black;  font-family: Open Sans; font-size:14px; line-height:25px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                                        Thank you for providing the quote for <a
                                                                            href="mailto:someone@example.com"
                                                                            style="text-decoration: none;color:#54BD95">${sender.name}</a>.
                                                                        After careful consideration, we are pleased to accept
                                                                        your offer.
                                                                        <br>
                                                                        We are confident that your expertise and professionalism
                                                                        will contribute greatly to our project/goals. Please
                                                                        proceed
                                                                        with the necessary arrangements, and feel free to reach
                                                                        out if any further information is required from our end.
                                                                        Looking forward to a successful collaboration.
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="hero-title-1" valign="top"
                                                                        style="color:black; font-family: Open Sans;padding-top: 15px;  font-size:14px; line-height:25px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                                        <div class="btn"
                                                                            style="text-align:center;padding-top: 25px;  ">
                                                                            <!--[if mso]>                                                                     <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://www.fitbit.com/campaign/shop/versa?color=black&utm_source=ET&utm_medium=EM&utm_campaign=20191117_PreBFV2_US_FM_M_SU_SW_32_NA&utm_content=HeroCTALink" style="font-family: Arial, Helvetica, sans-serif;height:36px;v-text-anchor:middle;width:150px;" arcsize="152%" stroke="f" fillcolor="#002A3A">                                                                     <w:anchorlock/>                                                                     <center>                                                                     <![endif]-->
                                                                            <a href='http://localhost:8080/UPC/Quotes/signerPreview?client=${recipient.name}&clientId=${recipient.id}&quote=${quoteInfo._id}&access=${generatedToken.token}'
                                                                                class="mob-btn" target="_blank"
                                                                                style="background-color:#54BD95;border-radius:6px;color:#ffffff;font-family:Open Sans;font-size:14px; text-align:center;text-decoration:none;width: 218.18px;
                                                                            height: 38px;-webkit-text-size-adjust:none; padding: 10px 12px; line-height: 15px;">Accept
                                                                                Quote
                                                                            </a>
                                                                            <!--[if mso]>                                                                     </center>                                                                     </v:roundrect>                                                                     <![endif]-->
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="hero-title-1" style="padding-top:40px;padding-left:80px;padding-right:80px;font-family: 'Quicksand';
                                                                        font-style: normal;
                                                                        font-weight: 400;
                                                                        font-size: 14px;
                                                                        line-height: 18px;
                                                                        text-align: center;
                                                                        color: #A6A6A6;">
                                                                        Hiccups might happen. Your feedback and requests are
                                                                        very welcomed.
                                                                        <a href="mailto:${sellerEmail?.characteristic?.emailAddress}"
                                                                            style="text-decoration: none;color:#54BD95">${sender.name}</a>
                                                                    </td>
                                                                </tr>
                                                        </td>
                                                    </tr>
                                                    <!-- END HERO -->
                                                </table>
                                                <!-- HERO Images-->
                                            </td>
                                        </tr>
                                    </table>
                                    <!-- END Content -->
                                    <!--Features-->
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                            <td>
                                                <!--Start Content-->
                                                <!--Desktop RTBs-->
                                                <!--End Desktop RTBs-->
                                                <!--Mobile RTBs-->
                                                <!--[if !mso]><!-->
                                                <!--End Mobile RTBs-->
                                                <!--<![endif]-->
                                                <!--END Features-->
                                                <!-- Footer -->
                                                <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                    style="padding: 0 15px;" bgcolor="#ffffff">
                                                    <tr>
                                                        <td bgcolor="#161C2B" class="disclaimer2"
                                                            style="padding: 40px 77px 40px 77px;color:#FFFFFF; font-family: Open Sans; font-size:14px; line-height:20px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                            <span><a href="https://halleyx.com"
                                                                    style="color: #FFFFFF;font-size: 14px;"><u>T&C</u><u
                                                                        style="padding-left:40px;padding-right: 40px;">Privacy</u><u>Cookies</u></a></span><br />
                                                            <br />
                                                            © 2023 Halleyx Inc. Copyright and rights reserved <p
                                                                style="padding-left: 17px !important; font-family:Open Sans; font-size: 14px; color: #FFFFFF;margin-top: 15px;  margin-bottom: 0px; letter-spacing: .01em;line-height: 20px;">
                                                                18 King St E #1400, Toronto, ON M5C 1C4
                                                            </p>
                                                            <span style="float:left;margin-left: -60px;padding-top:20px;"><img
                                                                    width="100"
                                                                    src="https://halleyx.com/img/halleyx-logo-line-white.c09fdc43.svg" /></span>
                                                            <span
                                                                style="float: right;margin-right:-50px;padding-top:20px;color: #FFFFFF;"><a
                                                                    href="https://www.linkedin.com/company/halleyx/"><i
                                                                        class="fa fa-linkedin fa-lg"
                                                                        style="color: #ffffff;"></i></a>
                                                                <a href="https://halleyx.com/"
                                                                    style="padding-left:5px;padding-right: 5px;"><i
                                                                        class="fa fa-facebook fa-lg"
                                                                        style="color: #ffffff;"></i>
                                                                </a>
                                                                <a href="https://www.instagram.com/halleyx_inc/"><i
                                                                        class="fa fa-instagram fa-lg"
                                                                        style="color: #ffffff;"></i></a></span>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!-- Footer -->
                                                <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                    bgcolor="#ffffff">
                                                    <tr>
                                                        <td>
                                                            <style>
                                                                .nowrap {
                                                                    word-break: keep-all;
                                                                    -moz-hyphens: none;
                                                                    -ms-hyphens: none;
                                                                    -webkit-hyphens: none;
                                                                    hyphens: none;
                                                                }
                                                                a[x-apple-data-detectors] {
                                                                    color: inherit !important;
                                                                    text-decoration: none !important;
                                                                    font-size: inherit !important;
                                                                    font-family: inherit !important;
                                                                    font-weight: inherit !important;
                                                                    line-height: inherit !important;
                                                                }
                                                            </style>
                                                            <table border="0" cellpadding="0" cellspacing="0"
                                                                style="border:none;border-collapse:collapse; background-color:#ffffff;"
                                                                width="100%" class="wrapper mobileContainer">
                                                                <tr>
                                                                    <td class="mobileContainer" align="center"
                                                                        style="font-family:Helvetica, Arial, sans-serif; color:#939c9e; font-size:0px; padding-top:24px; font-weight:100; background-color:#ffffff; width:100%; ">
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!-- END Footer -->
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
            <img src="https://srv451-files.hstgr.io/9e33e5905bbb9f5d/files/public_html/img/halleyx-logo-line-white.1535d8d0.svg"
                width="1" height="1"></custom>
        </body>
        </html>`;
        this.emailTemp = quoteInvitationEmailContent;
        console.log(
          "🚀 ~ closeCrudContextMenu ~ quoteInvitationEmailContent:",
          quoteInvitationEmailContent
        );
      } else if ($event && $event.toLowerCase() == "preview") {
        let quoteInfo = ele;
        console.log("🚀 ~ closeCrudContextMenu ~ quoteInfo:", quoteInfo);
        this.$router.push({
          name: "previewTemplate",
          query: { quote_id: quoteInfo._id },
        });
      }
    },
    viewPage(i) {
      if (!i || (i && i.state?.toLowerCase() !== "inprogress")) return;
      this.$router.push({
        name: "CPQDefaultTemplate",
        query: { quote_id: i._id, mode: "edit" },
      });
      return;
    },
    async closeCrudContext(i) {
      // //
      if (i.type.toLowerCase() == "rule") {
        this.$router.push(`/DMN/rule_studio/logical_rule/${i["_id"]}`);

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/rule/${i["_id"]}`;

        // this.$router.push({ name: 'rule-edit', params: { id: i["_id"] } })
      } else if (i.type.toLowerCase() == "expression") {
        this.$router.push(`/DMN/rule_studio/expression/${i["_id"]}`);

        // this.$router.push({ name: 'expression-edit', params: { id: i["_id"] } })
        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/expression/${i["_id"]}`;
      } else if (i.type.toLowerCase() == "decision") {
        // this.decision_name = i.name;
        // const alldata = await DMNAxiosInstance.get(
        //   `rule_engine/rule?id=${this.id}&name=${this.name}&type=decision`,
        //   {
        //     auth: {
        //       username: "testuser@test.com",
        //       password: " ",
        //     },
        //   }
        // );
        // //
        // let obj = await alldata.data.data.find(
        //   (item) => item.name === this.decision_name
        // );
        //
        // this.$router.push({ name: 'decision-edit', params:{ id: obj["_id"],token:$}})

        this.$router.push(
          `/DMN/rule_studio/decision_table/edit/${i.decisionId}`
        );

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/decision_table/${obj["_id"]}`;
      }
    },

    async deleteExecRow() {
      this.delete_modal_active = false;
      let i = this.deleteobj;
      const deleteOffering = await UPCAxiosInstance.delete(
        `catalogManagement/productSpecification/${i._id}`
      );
      deleteOffering;
      // if (deleteOffering.data.name == i.name) {
      // var offer3 = await UPCAxiosInstance.get(`catalogManagement/productSpecification`)
      // this.totalpageno = offer3.data.length
      // this.tableData = [];
      // var offer = await UPCAxiosInstance.get(
      //   `catalogManagement/productSpecification?pageNo=${this.currPagePS}&size=${this.rowsPerPagePS}`
      // );
      // this.tableData = offer.data;
      this.SpecificationcurrPage = 1;
      this.specificationdataCalling(
        this.SpecificationcurrPage,
        this.SpecificationrowsPerPage
      );

      let logupc = {
        userName: this.userInfo.name,
        userId: this.userInfo._id,
        name: `${i.name} product specification edited`,
        description: `${i.name} product specification edited`,
      };
      // const activity =
      await UPCAxiosInstance.post(`/activityLog`, logupc);

      this.notification = {
        type: "success",
        message: `Done! ${i.name} has been removed.`,
      };
      // }
      // this.activatemodalactive(i);
      i.context = false;
      // this.refreshTable();
    },
    crudContextMenu($event, index) {
      // ;
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;

        //
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    async handleCurrentChange(val) {
      //
      // this.filterpanelname == val.name;
      switch (val) {
        case "Product specifications":
          // this.SpecificationcurrPage = 1;
          // this.SpecificationrowsPerPage = 10;
          this.tableData = [];
          this.specificationdataCalling(
            this.SpecificationcurrPage,
            this.SpecificationrowsPerPage
          );
          this.filterPanelName = "productSpecification";
          this.view = val;

          break;
        case "Product offerings":
          this.tableData = [];
          // this.OfferingcurrPage = 1;
          // this.OfferingrowsPerPage = 10;
          this.offeringdataCalling(
            this.OfferingcurrPage,
            this.OfferingrowsPerPage
          );
          this.filterPanelName = "productOffering";
          this.view = val;

          break;
      }
      this.duplicatetabledata = this.tableData;
      this.searchtabledata = this.tableData;
      // //
    },
    serialNumber(index) {
      return (
        (this.SpecificationcurrPage - 1) * this.SpecificationrowsPerPage +
        index +
        1
      );
    },
    async currentPage(val) {
      this.SpecificationcurrPage = val;
      this.specificationdataCalling(
        this.SpecificationcurrPage,
        this.SpecificationrowsPerPage
      );
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    async changeRowsPerPage(count) {
      this.SpecificationrowsPerPage = count;
      this.specificationdataCalling(
        this.SpecificationcurrPage,
        this.SpecificationrowsPerPage
      );
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    resizewidthdata(data1, data2) {
      data1;
      data2;
      //
    },
    sorting_Data(data, data1) {
      //
      if (data === "icon-angle-up-small-filled sorting-icon") {
        if (this.view == "Product offerings") {
          //  console.log('fjd',this.offeringfilterquery);
          this.offeringfilterquery.type = "filter";
          this.offeringfilterquery.isNeedToBeSort = true;
          if (data1 === "name") {
            this.offeringfilterquery.sortFieldAndOrder = { name: 1 };
          } else if (data1 == "description") {
            this.offeringfilterquery.sortFieldAndOrder = { description: 1 };
          } else if (data1 == "lifecycleStatus") {
            this.offeringfilterquery.sortFieldAndOrder = { lifecycleStatus: 1 };
          } else if (data1 == "offerType") {
            this.offeringfilterquery.sortFieldAndOrder = { offerType: 1 };
          } else if (data1 == "lastUpdate") {
            this.offeringfilterquery.sortFieldAndOrder = { lastUpdate: 1 };
          } else if (data1 == "place") {
            this.offeringfilterquery.sortFieldAndOrder = { "place.name": 1 };
          } else if (data1 == "channel") {
            this.offeringfilterquery.sortFieldAndOrder = { "channel.name": 1 };
          } else if (data1 == "category") {
            this.offeringfilterquery.sortFieldAndOrder = { "category.name": 1 };
          } else if (data1 == "marketSegment") {
            this.offeringfilterquery.sortFieldAndOrder = {
              "marketSegment.name": 1,
            };
          }
          this.OfferingcurrPage = 1;
          this.offeringdataCalling(
            this.OfferingcurrPage,
            this.OfferingrowsPerPage
          );
        } else if (this.view == "Product specifications") {
          this.quoteFilterOption.type = "filter";
          this.quoteFilterOption.isNeedToBeSort = true;
          if (data1 === "name") {
            this.quoteFilterOption.sortFieldAndOrder = { name: 1 };
          } else if (data1 === "description") {
            this.quoteFilterOption.sortFieldAndOrder = { description: 1 };
          } else if (data1 == "lifecycleStatus") {
            this.quoteFilterOption.sortFieldAndOrder = { lifecycleStatus: 1 };
          } else if (data1 == "lastUpdate") {
            this.quoteFilterOption.sortFieldAndOrder = { lastUpdate: 1 };
          } else if (data1 == "resourceSpecification") {
            this.quoteFilterOption.sortFieldAndOrder = {
              "resourceSpecification.name": 1,
            };
          } else if (data1 == "serviceSpecification") {
            this.quoteFilterOption.sortFieldAndOrder = {
              "serviceSpecification.name": 1,
            };
          } else if (data1 == "relatedParty") {
            this.quoteFilterOption.sortFieldAndOrder = {
              "relatedParty.name": 1,
            };
          }

          this.SpecificationcurrPage = 1;
          this.specificationdataCalling(
            this.SpecificationcurrPage,
            this.SpecificationrowsPerPage
          );
        }
      } else if (data === "icon-angle-down-small-filled sorting-icon") {
        // console.log('sort-down',data1);
        if (this.view == "Product offerings") {
          //  console.log('fjd',this.offeringfilterquery);
          this.offeringfilterquery.type = "filter";
          this.offeringfilterquery.isNeedToBeSort = true;
          if (data1 === "name") {
            this.offeringfilterquery.sortFieldAndOrder = { name: -1 };
          } else if (data1 == "description") {
            this.offeringfilterquery.sortFieldAndOrder = { description: -1 };
          } else if (data1 == "lifecycleStatus") {
            this.offeringfilterquery.sortFieldAndOrder = {
              lifecycleStatus: -1,
            };
          } else if (data1 == "offerType") {
            this.offeringfilterquery.sortFieldAndOrder = { offerType: -1 };
          } else if (data1 == "lastUpdate") {
            this.offeringfilterquery.sortFieldAndOrder = { lastUpdate: -1 };
          } else if (data1 == "place") {
            this.offeringfilterquery.sortFieldAndOrder = { "place.name": -1 };
          } else if (data1 == "channel") {
            this.offeringfilterquery.sortFieldAndOrder = { "channel.name": -1 };
          } else if (data1 == "category") {
            this.offeringfilterquery.sortFieldAndOrder = {
              "category.name": -1,
            };
          } else if (data1 == "marketSegment") {
            this.offeringfilterquery.sortFieldAndOrder = {
              "marketSegment.name": -1,
            };
          }
          this.OfferingcurrPage = 1;
          this.offeringdataCalling(
            this.OfferingcurrPage,
            this.OfferingrowsPerPage
          );
        } else if (this.view == "Product specifications") {
          this.quoteFilterOption.type = "filter";
          this.quoteFilterOption.isNeedToBeSort = true;
          if (data1 === "name") {
            this.quoteFilterOption.sortFieldAndOrder = { name: -1 };
          } else if (data1 === "description") {
            this.quoteFilterOption.sortFieldAndOrder = { description: -1 };
          } else if (data1 == "lifecycleStatus") {
            this.quoteFilterOption.sortFieldAndOrder = { lifecycleStatus: -1 };
          } else if (data1 == "lastUpdate") {
            this.quoteFilterOption.sortFieldAndOrder = { lastUpdate: -1 };
          } else if (data1 == "resourceSpecification") {
            this.quoteFilterOption.sortFieldAndOrder = {
              "resourceSpecification.name": -1,
            };
          } else if (data1 == "serviceSpecification") {
            this.quoteFilterOption.sortFieldAndOrder = {
              "serviceSpecification.name": -1,
            };
          } else if (data1 == "relatedParty") {
            this.quoteFilterOption.sortFieldAndOrder = {
              "relatedParty.name": -1,
            };
          }

          this.SpecificationcurrPage = 1;
          this.specificationdataCalling(
            this.SpecificationcurrPage,
            this.SpecificationrowsPerPage
          );
        }
      } else if (data === "icon-angle-up-small-filled") {
        if (this.view == "Product offerings") {
          //  console.log('fjd',this.offeringfilterquery);
          this.offeringfilterquery.type = "filter";
          this.offeringfilterquery.isNeedToBeSort = false;
          this.offeringfilterquery.sortFieldAndOrder = {};
          this.OfferingcurrPage = 1;
          this.offeringdataCalling(
            this.OfferingcurrPage,
            this.OfferingrowsPerPage
          );
        } else if (this.view == "Product specifications") {
          this.quoteFilterOption.type = "filter";
          this.quoteFilterOption.isNeedToBeSort = false;
          this.quoteFilterOption.sortFieldAndOrder = {};
          this.SpecificationcurrPage = 1;
          this.specificationdataCalling(
            this.SpecificationcurrPage,
            this.SpecificationrowsPerPage
          );
        }
      } else if (data === "icon-angle-down-small-filled") {
        if (this.view == "Product offerings") {
          //  console.log('fjd',this.offeringfilterquery);
          this.offeringfilterquery.type = "filter";
          this.offeringfilterquery.isNeedToBeSort = false;
          this.offeringfilterquery.sortFieldAndOrder = {};
          this.OfferingcurrPage = 1;
          this.offeringdataCalling(
            this.OfferingcurrPage,
            this.OfferingrowsPerPage
          );
        } else if (this.view == "Product specifications") {
          this.quoteFilterOption.type = "filter";
          this.quoteFilterOption.isNeedToBeSort = false;
          this.quoteFilterOption.sortFieldAndOrder = {};
          this.SpecificationcurrPage = 1;
          this.specificationdataCalling(
            this.SpecificationcurrPage,
            this.SpecificationrowsPerPage
          );
        }
      }
    },
    searchKey(val) {
      this.searchvalue = val;
      this.quoteFilterOption["type"] = "search";
      this.quoteFilterOption["searchQuery"] = this.searchvalue;
      this.SpecificationcurrPage = 1;
      this.SpecificationrowsPerPage = 10;
      this.specificationdataCalling(
        this.SpecificationcurrPage,
        this.SpecificationrowsPerPage
      );
    },
    fromAddContextMenu(val) {
      if (val === "Decision tables") {
        this.$router.push("/DMN/rule_studio/decision_table/add");

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/decision_table/add`;
      } else if (val === "Expression") {
        this.$router.push("/DMN/rule_studio/expression");

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/expression`;
      } else if (val === "Logical rule") {
        this.$router.push("/DMN/rule_studio/logical_rule");

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/rule`;
      }
    },
  },
};
</script>
