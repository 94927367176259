<template>
  <div class="dashbord-panel">
    <div v-show="impersonite == true"><banner @exit="exitView()" /></div>
    <section class="right-panel">
      <div class="dashboard-title">
        <!-- <p class="dashboard-search-container">
          <hlx-search
            :search-style="'style1'"
            @search-key="search"
          />
        </p> -->
        <p class="text-container">Settings</p>
      </div>
      <div class="card-holder" style="overflow: visible">
        <div
class="cards-container"
        style="    height: 80%;
    padding: 20px 0 20px 0;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));"
        >
          <hlx-card
            v-for="(card, index) in card_data"
            :key="index"
            class="custom-card"
            :seperator="false"
            :card-title="false"
            :card-footer="false"
            :hover="true"
            height="210"
            :class="{
              '--disable-hover':
                card.card_name && card.card_name.toLowerCase() == 'api',
            }"
            @click="moveTo(card.route)"
          >
            <template #card-body>
              <div
                style="padding: 0 15px"
                :class="{
                  '--disable-hover':
                    card.card_name && card.card_name.toLowerCase() == 'api',
                }"
              >
              <span v-if="card?.icon">
                <div class="circleInCard">
                <i :class=card.icon></i>
              </div>
              </span>
              <span v-else>
                <img :src="getImgUrl(card.card_body)" :alt="card.card_body" />
              </span>
              
                <p class="card-head">
                  <b>{{ card.card_name }}</b>
                </p>
                <p
                  class="card-desc copy-click"
                  data-tooltip-text="Click to copy"
                  data-tooltip-text-copied="✔ Copied to clipboard"
                >
                  {{ card.card_desc }}
                </p>
                <!-- <hlx-input
                  :disabled="false"
                  :label_animation="true"
                  label_value="Multi"
                  type="mixed"
                  :clearable="true"
                  name="username"
                  :suffix="true"
                  >
                  <template #suffix>
                    <i
                    class="icon-arrows-repeat-regular"
                    @click.stop="refreshAPIKey"
                    ></i>
                  </template>
                </hlx-input> -->
                <div
                  v-if="card.card_name && card.card_name.toLowerCase() == 'api'"
                  class="api-key-container"
                >
                  <input
                    ref="copy-click"
                    v-model="apiKey"
                    class="api-key-holder"
                    data-copy-string="#ITN12345"
                    data-tooltip-text="Click to copy"
                    data-tooltip-text-copied="✔ Copied to clipboard"
                    @click.stop="copyIntraction"
                  />
                  <span class="c-to-c">Click to copy</span>
                  <span class="c-to-cd">✔ Copied to clipboard</span>
                  <span class="refresh-icon" @click.stop="refreshAPIKey">
                    <i class="icon-arrows-repeat-regular"> </i>
                  </span>
                </div>
              </div>
            </template>
            <template #card-footer>
              {{ card.card_footer }}
            </template>
          </hlx-card>
        </div> 
      </div>
    </section>
  </div>
</template>

<script>
import banner from "../components/bannerComponent.vue";
import { MLTAxiosInstance } from "@/config/axiosConfig";
export default {
  name: "SettingView",
  components: { banner },
  data() {
    return {
      impersonite: false,
      fet: process.env.VUE_APP_HOST_PROTOCOL + "://" + process.env.VUE_APP_HOST,
      token: localStorage.token,
      apiKey: "",
      example_data: [
        {
          title: "Complete account setup",
          description: "",
          subtitle: "",
          active: false,
        },
        {
          title: "Choose a plan",
          description: "",
          subtitle: "",
          active: false,
        },
        {
          title: "Choose payment method",
          description: "",
          subtitle: "",
          active: false,
        },
      ],
      example_data2: [
        {
          title: "Complete account setup",
          description: "",
          subtitle: "",
          active: true,
        },
        {
          title: "Choose a plan",
          description: "",
          subtitle: "",
          active: false,
        },
        {
          title: "Choose payment method",
          description: "",
          subtitle: "",
          active: false,
        },
      ],
      example_data3: [
        {
          title: "Complete account setup",
          description: "",
          subtitle: "",
          active: true,
        },
        {
          title: "Choose a plan",
          description: "",
          subtitle: "",
          active: true,
        },
        {
          title: "Choose payment method",
          description: "",
          subtitle: "",
          active: true,
        },
      ],
      switch_items: [{ name: "Monthly billing" }, { name: "Annual billing" }],
      card_data: [],
      user_card: [
        {
          card_body: "general.svg",
          route: "organization",
          card_name: "Organization",
          card_desc: "Get access to your organisation details",
        },
        {
          card_body: "users.svg",
          route: "users",
          card_name: "Users",
          card_desc: "Manage users",
        },
        {
          card_body: "users.svg",
          route: "loginDetails",
          card_name: "User profile",
          card_desc: "Get access to user profile details",
          icon: "icon-user-regular"
        },
        // {
        //   card_body: "bill.svg",
        //   route: "bill",
        //   card_name: "Billing",
        //   card_desc: "Get access to your billing, plan and payment details",
        // },
        {
          card_body: "key.svg",
          route: "",
          card_name: "API",
          card_desc: "My API key",
        },
        {
          card_body: "roles.svg",
          route: "roles",
          card_name: "Roles and permissions",
          card_desc: "Manage assigned roles and their permissions",
        },
        // {
        //   card_body: 'code.svg',
        //   route: 'API',
        //   card_name: 'API collection',
        //   card_desc: 'Manage API tasks',
        // },
        // {
        //   card_body: 'mail2.svg',
        //   route: 'email',
        //   card_name: 'Email collection',
        //   card_desc: 'Manage email tasks',
        // },
        // {                                         // by asif
        //   card_body: "audit.svg",
        //   route: "defaultSetting",
        //   card_name: "Default settings",
        //   card_desc: "Track organisation activity",
        // },
        {
          card_body: "bill.svg",
          route: "tax",
          card_name: "Tax",
          card_desc: "Add the tax",
        },
        // {
        //   card_body: "audit.svg",
        //   route: "ticket",
        //   card_name: "Ticket",
        //   card_desc: "Add ticket fields",
        // },
    
        {
          card_body: "audit.svg",
          route: "automation",
          card_name: "Automation",
          card_desc: "Real-time tracking",
          icon: "icon-line-chart-dots-regular"
        },
        {
          card_body: "customFields.svg",
          route: "custom-field",
          card_name: "Fields",
          card_desc: "Personalized Input Options",
        },
        {
          card_body: "customFields.svg",
          route: "emailTemplate",
          card_name: "Email template",
          card_desc: "Create email template",
        },
        {
          card_body: "customFields.svg",
          route: "importTemplate",
          card_name: "Import template",
          card_desc: "Create import template",
        },
        {
          card_body: "audit.svg",
          route: "IdSettings",
          card_name: "ID Settings",
          card_desc: "Format the ID based on user convenience",
        },
        // {
        //   card_body: "customFields.svg",
        //   route: "staticData",
        //   card_name: "Static Data",
        //   card_desc: "Get access to your static data",
        // }
      ],
      tenant_card: [
        {
          card_body: "general.svg",
          route: "general",
          card_name: "General",
          card_desc: "Get access to your profile details",
        },
        {
          card_body: "users.svg",
          route: "tenantTable",
          card_name: "Tenants",
          card_desc: "Manage tenants",
        },
        {
          card_body: "users.svg",
          route: "users",
          card_name: "Users",
          card_desc: "Manage users",
        },
        {
          card_body: "roles.svg",
          route: "roles",
          card_name: "Roles and permissions",
          card_desc: "Manage assigned roles and their permissions",
        },
        // {
        //   card_body: "bill.svg",
        //   route: "bill",
        //   card_name: "Billing",
        //   card_desc: "Get access to your billing, plan and payment details",
        // },
        {
          card_body: "key.svg",
          route: "apiUsage",
          card_name: "API",
          card_desc: "My API key",
        },
        {
          card_body: "bill.svg",
          route: "tax",
          card_name: "Tax",
          card_desc: "Add the tax",
        },
        {
          card_body: "customFields.svg",
          route: "emailTemplate",
          card_name: "Email template",
          card_desc: "Create email template",
        },
        {
          card_body: "customFields.svg",
          route: "importTemplate",
          card_name: "Import template",
          card_desc: "Create import template",
        },
      ],
      user: {},
      card_data2: [
        {
          name: "Basic",
          price: "12",
          description: "Basic plan",
          currency: "usd",
          features: [
            "faster",
            "light",
            "Advanced",
            "faster",
            "light",
            "Advanced",
            "faster",
            "light",
            "Advanced",
          ],
        },
        {
          name: "Basic",
          price: "12",
          description: "Basic plan",
          currency: "usd",
          features: [
            "faster",
            "light",
            "Advanced",
            "faster",
            "light",
            "Advanced",
            "faster",
            "light",
            "Advanced",
          ],
        },
      ],
      company: "",
      impersonitor: "",
      name: "",
      userId: "",
      editPermission: [],
      viewPermission: [],
    };
  },
  async mounted() {
    let userAuth = await MLTAxiosInstance.get(
      `partymanagement/individual/auth`
    );
    this.user = userAuth.data;
    this.company = userAuth.data.company;
    this.userId = userAuth.data.userId;

    // Get API key
    let apiKeyRes = await MLTAxiosInstance.get(
      `partymanagement/individual/getAPIKey`
    );

    if (apiKeyRes.data.ok == 0) {
      return;
    }
    this.apiKey = apiKeyRes.data.data;

    // Get role of the user
    const access =await MLTAxiosInstance.get(
        `partyRole/get/${userAuth.data.userId}`
      );
    
    console.log(access,"access role")
    if (this.company == "Halleyx" && access.data.role == "Root admin") {
      this.card_data = this.tenant_card;
    } else {
      this.card_data = this.user_card;
    }

    // let arr = access.data;
    // if (arr && Object.keys(arr) > 0) {
    //   //
    //   this.editPermission = [];
    //   this.viewPermission = [];
    //   if (arr.permission.User_settings.edit == false) {
    //     this.editPermission.push("setting");
    //   }
    //   if (arr.permission.User_settings.view == false) {
    //     this.viewPermission.push("setting");
    //   }
    //   if (arr.permission.Schema.edit == false) {
    //     this.editPermission.push("schema");
    //   }
    //   if (arr.permission.Schema.view == false) {
    //     this.viewPermission.push("Schema");
    //   }
    //   if (arr.permission.Test_data.view == false) {
    //     this.viewPermission.push("Database", "Execute");
    //   }
    //   if (arr.permission.Test_data.edit == false) {
    //     this.editPermission.push("testdata");
    //   }
    //   if (arr.permission.Rules.view == false) {
    //     this.viewPermission.push("Business rule");
    //   }
    //   if (arr.permission.Rules.edit == false) {
    //     this.editPermission.push("rules");
    //   }
    // }
    const userInfo = await MLTAxiosInstance.get(
      `partymanagement/individual/${this.userId}`
    );
    if (userInfo.data) {
      this.impersonitor = userInfo.data.impersinater;
      this.name = userInfo.data.name;
      this.userId = userInfo.data._id;
      this.impersonite = userInfo.data.impersonation;
    }
  },

  methods: {
    async refreshAPIKey() {
      let apiKeyRes = await MLTAxiosInstance.post(
        `partymanagement/individual/generateAPIKey`,
        this.user
      );
      if (apiKeyRes.ok == 0) {
        return;
      }

      this.apiKey = apiKeyRes.data.data;
    },
    copyIntraction() {
      let input = this.$refs["copy-click"][0];
      if (input) {
        setTimeout(() => {
          input.classList.add("copied");
        }, 700);
        setTimeout(() => {
          input.classList.remove("copied");
          input.select();
          document.execCommand("copy");
        }, 2000);
      }
    },
    getImgUrl(pet) {
      //
      // var images = require.context("../assets/", false, /\.svg$/);
      //
      return require("../assets/images/" + pet);
    },
    moveTo(route) {
      this.$router.push(`/settings/${route}`);
    },
  },
};
</script>

<style></style>
