<!-- eslint-disable vue/no-dupe-keys -->
<!-- eslint-disable no-case-declarations -->
<template>
    <div class="upc-dashboard">
      <section class="header-section">
        <div class="title">
          <h3 class="heading1">Welcome {{ name }} !</h3>
          <p class="heading2">
            Keep tabs on your ISP's performance with insights from the past 30 days, all on one dashboard.
          </p>
        </div>
        <div class="card-section">
          <div id="card1" class="card first" @click="handleStatus('card1', $event)">
            <h4 class="heading">Total Customers</h4>
            <section class="total">
              <div class="part2">
                <h3 v-if="bssDashboarddata" class="sub-heading">
                  {{ bssDashboarddata[0]?.totalCustomers?.value }}
                </h3>
                <div class="imageview">
                  <div class="arrowbox">
                    <img
                      v-if="Math.sign( bssDashboarddata[0]?.totalCustomers?.percentage) == 1 || Math.sign( bssDashboarddata[0]?.totalCustomers?.percentage) == 0
                      " src="../../assets/images/greenarrow.svg" />
                    <img
                    v-else src="../../assets/images/redarrow.svg" />
                    <h5
  :class="{
                      success: Math.sign( bssDashboarddata[0]?.totalCustomers?.percentage) == 1 || Math.sign( bssDashboarddata[0]?.totalCustomers?.percentage) == 0,
                      danger: Math.sign( bssDashboarddata[0]?.totalCustomers?.percentage) == -1
                    }">
                      {{ bssDashboarddata[0]?.totalCustomers?.percentage }}%
                    </h5>
                  </div>
                </div>
              </div>
              <div class="curve">
                <img
  v-if="Math.sign( bssDashboarddata[0]?.totalCustomers?.percentage) == 1 || Math.sign( bssDashboarddata[0]?.totalCustomers?.percentage) == 0
                  " src="../../assets/images/gc.svg" />
                <img
  v-else src="../../assets/images/redline.svg" />
              </div>
            </section>
          </div>
          <div id="card2" class="card" @click="handleStatus('card2', $event)">
            <h4 class="heading">Monthly Recurring Revenue</h4>
            <section class="total">
              <div class="part2">
                <h3 class="sub-heading">
                  {{ $store?.getters?.getDefaultSettings?.currency
                                ?.symbol }}  {{ bssDashboarddata[0]?.MRR?.value }}
                </h3>
                <div class="imageview">
                  <div class="arrowbox">
                    <!-- <img src="../../assets/images/redarrow.svg" /> -->
                    <img
  v-if=" Math.sign( bssDashboarddata[0]?.MRR?.percentage) == 1|| Math.sign( bssDashboarddata[0]?.MRR?.percentage) == 0
                      " src="../../assets/images/greenarrow.svg" />
                    <img
  v-else src="../../assets/images/redarrow.svg" />
                    <h5
  :class="{
                      success:  Math.sign( bssDashboarddata[0]?.MRR?.percentage) == 1 || Math.sign( bssDashboarddata[0]?.MRR?.percentage) == 0,
                      danger:  Math.sign( bssDashboarddata[0]?.MRR?.percentage) == -1
                    }">
                      {{ Math.sign( bssDashboarddata[0]?.MRR?.percentage) }}%
                    </h5>
                  </div>
                </div>
              </div>
              <div class="curve">
                <!-- <img src="../../assets/images/redline.svg" /> -->
                <img
  v-if="Math.sign( bssDashboarddata[0]?.MRR?.percentage) == 1|| Math.sign( bssDashboarddata[0]?.MRR?.percentage) == 0
                  " src="../../assets/images/gc.svg" />
                <img
  v-else src="../../assets/images/redline.svg" />
              </div>
            </section>
          </div>
          <div id="card3" class="card" @click="handleStatus('card3', $event)">
            <h4 class="heading">Avg Monthly Orders</h4>
            <section class="total">
              <div class="part2">
                <h3 class="sub-heading">{{ bssDashboarddata[0]?.averagePeriodOrders?.value}}</h3>
                <div class="imageview">
                  <div class="arrowbox">
                    <!-- <img src="../../assets/images/greenarrow.svg" />
                    <h5 class="success">{{statusMetrics["Active"]?.percent?.value}}%</h5> -->
                    <img
  v-if="Math.sign( bssDashboarddata[0]?.averagePeriodOrders?.percentage) == 1|| Math.sign( bssDashboarddata[0]?.averagePeriodOrders?.percentage) == 0
                      " src="../../assets/images/greenarrow.svg" />
                    <img
  v-else src="../../assets/images/redarrow.svg" />
                    <h5
  :class="{
                      success: Math.sign( bssDashboarddata[0]?.averagePeriodOrders?.percentage) == 1 || Math.sign( bssDashboarddata[0]?.averagePeriodOrders?.percentage) == 0,
                      danger: Math.sign( bssDashboarddata[0]?.averagePeriodOrders?.percentage) == -1
                    }">
                      {{ bssDashboarddata[0]?.averagePeriodOrders?.percentage }}%
                    </h5>
                  </div>
                </div>
              </div>
              <div class="curve">
                <!-- <img src="../../assets/images/gc.svg" /> -->
                <img
  v-if="Math.sign( bssDashboarddata[0]?.averagePeriodOrders?.percentage) == 1|| Math.sign( bssDashboarddata[0]?.averagePeriodOrders?.percentage) == 0
                  " src="../../assets/images/gc.svg" />
                <img
  v-else src="../../assets/images/redline.svg" />
              </div>
            </section>
          </div>
          <div id="card4" class="card" @click="handleStatus('card4', $event)">
            <h4 class="heading">Churn rate</h4>
            <section class="total">
              <div class="part2">
                <h3 class="sub-heading">
                  {{ bssDashboarddata[0]?.churnRate?.value }}
                </h3>
                <div class="imageview">
                  <div class="arrowbox">
                    <!-- <img src="../../assets/images/greenarrow.svg" />
                    <h5 class="success">{{statusMetrics["Launched"]?.percent?.value}}%</h5> -->
                    <img
  v-if="Math.sign( bssDashboarddata[0]?.churnRate?.percentage) == -1 || Math.sign( bssDashboarddata[0]?.churnRate?.percentage) == 0
                      " src="../../assets/images/greenarrow.svg" />
                    <img
  v-else src="../../assets/images/redarrow.svg" />
                    <h5
  :class="{
                      success: Math.sign( bssDashboarddata[0]?.churnRate?.percentage) == -1 || Math.sign( bssDashboarddata[0]?.churnRate?.percentage) == 0,
                      danger: Math.sign( bssDashboarddata[0]?.churnRate?.percentage) == 1
                    }">
                      {{ bssDashboarddata[0]?.churnRate?.value }}%
                    </h5>
                  </div>
                </div>
              </div>
              <div class="curve">
                <!-- <img src="../../assets/images/gc.svg" /> -->
                <img
  v-if="Math.sign( bssDashboarddata[0]?.churnRate?.percentage) == -1 || Math.sign( bssDashboarddata[0]?.churnRate?.percentage) == 0" src="../../assets/images/gc.svg" />
                <img
  v-else src="../../assets/images/redline.svg" />
              </div>
            </section>
          </div>
          <div id="card5" class="card" @click="handleStatus('card5', $event)">
            <h4 class="heading">Tickets</h4>
            <section class="total">
              <div class="part2">
                <h3 class="sub-heading">{{ bssDashboarddata[0]?.ticketCount?.value }}</h3>
                <div class="imageview">
                  <div class="arrowbox">
                    <!-- <img src="../../assets/images/redarrow.svg" />
                    <h5 class="danger">{{statusMetrics["Retired"]?.percent?.value}}%</h5> -->
                    <img
  v-if="Math.sign( bssDashboarddata[0]?.ticketCount?.percentage) == 1 || Math.sign( bssDashboarddata[0]?.ticketCount?.percentage) == 0
                      " src="../../assets/images/greenarrow.svg" />
                    <img
  v-else src="../../assets/images/redarrow.svg" />
                    <h5
  :class="{
                      success: Math.sign( bssDashboarddata[0]?.ticketCount?.percentage) == 1 || Math.sign( bssDashboarddata[0]?.ticketCount?.percentage) == 0,
                      danger: Math.sign( bssDashboarddata[0]?.ticketCount?.percentage) == -1 
                    }">
                      {{ bssDashboarddata[0]?.ticketCount?.percentage }}%
                    </h5>
                  </div>
                </div>
              </div>
              <div class="curve">
                <!-- <img src="../../assets/images/redline.svg" /> -->
                <img
                v-if="Math.sign( bssDashboarddata[0]?.ticketCount?.percentage) == 1 || Math.sign( bssDashboarddata[0]?.ticketCount?.percentage) == 0"
                 src="../../assets/images/gc.svg" />
                <img
  v-else src="../../assets/images/redline.svg" />
              </div>
            </section>
          </div>
          <div id="card6" class="card" @click="handleStatus('card6', $event)">
            <h4 class="heading">Avg Ticket Resolution </h4>
            <section class="total">
              <div class="part2">
                <h3 class="sub-heading">
                  {{ "24hrs" }}
                </h3>
                <div class="imageview">
                  <div class="arrowbox">
                    <img
  v-if="['increase', '-'].includes(
                      isDownOrIncrease(statusMetrics['Obsolete']?.percent)
                    )
                      " src="../../assets/images/greenarrow.svg" />
                    <img
  v-else-if="['decrease'].includes(
                      isDownOrIncrease(statusMetrics['Obsolete']?.percent)
                    )
                      " src="../../assets/images/redarrow.svg" />
                    <h5
  :class="{
                      success: ['increase', '-'].includes(
                        isDownOrIncrease(statusMetrics['Obsolete']?.percent)
                      ),
                      danger: ['decrease'].includes(
                        isDownOrIncrease(statusMetrics['Obsolete']?.percent)
                      )
                    }">
                      {{ statusMetrics["Obsolete"]?.percent?.value }}%
                    </h5>
                  </div>
                </div>
              </div>
              <div class="curve">
                <img
  v-if="['increase', '-'].includes(
                  isDownOrIncrease(statusMetrics['Obsolete']?.percent)
                )
                  " src="../../assets/images/gc.svg" />
                <img
  v-else-if="['decrease'].includes(
                  isDownOrIncrease(statusMetrics['Obsolete']?.percent)
                )
                  " src="../../assets/images/redline.svg" />
              </div>
            </section>
          </div>
        </div>
      </section>
      <section class="footer-section">
        <div class="upc-left">
          <section class="tab-container">
            <p style="padding:10px;font-weight: bold">Average Revenue Per User (ARPU) by Product</p>
            <!-- <hlx-switch
  v-model="selectedTab" :switch-items="UPCDashboardBarSwitchItems" :tab-view="true" value-prop="value"
              display-prop="label" @change="handleBarChatData" /> -->
  
            <div class="upc-custom-bar-chart">
              <VueApexCharts
  width="100%" height="150px" type="bar" :options="options" :series="series"
                @click="handleBarChart($event, selectedTab)"></VueApexCharts>
            </div>
          </section>
          <section class="layout-container">
              <div class="container">
                <section class="headers">
                  <section class="header">
                  <hlx-switch
  v-model="selectedTab" :switch-items="UPCDashboardBarSwitchItems" :tab-view="true" value-prop="value"
              display-prop="label" @change="handleBarChatData" />  
                  </section>   
                <div class="horizontal-bar" style="margin-left: -4%">
                <VueApexCharts
            :type="placeMetrics.chart.type" height="100%" width="100%" :options="placeMetrics"
                  :series="placeMetrics.series" @click="handleChartClick"></VueApexCharts>
              </div>
            </section>
            </div>

            <div class="place-container" style="padding:0px;overflow: hidden;">
              <section class="headers" style="height: 230px;border:none !important;padding:0px">
                  <h4 style="border:1px solid #ece9f1;padding:0.5rem">Customers per region</h4>
                <div
class="horizontal-bar" style="position: relative;
    height: calc(100% - 40px);
    width: initial;padding:0.5rem 0.5rem 0.5rem 0.5rem" >
                  <!-- <img
style="   height: 100%;
    /* width: fit-content; */
    position: relative;" src="~@/assets/svg/unitedkingdom.svg" width="100%" /> -->
    
      <!-- <graphMap
      :map-height="'230px'"
      :polygon-paths="polygonPath"
      :map-center="mapCenter"
      >  
    </graphMap> -->
    <chartMap3></chartMap3>

                </div>
              </section>
            </div>
            <div class="contact-container">
              <section class="headers">
                <h4>Top Selling Products</h4>
                <h4>Orders</h4>
              </section>
              <section class="holder">
                <span v-for="(party,index) in bssDashboarddata[0]?.topSellingProducts" :key="index">
                <div v-if="index<3" class="members first">
                  <p class="name" @click="handleRelatedParty(party?.party?.name)">{{ party?.product?.name }}</p>
                  <p class="count" @click="handleRelatedParty(party?.party?.name)">{{ party?.totalCount }}</p>
                </div>
              </span>
              </section>
            </div>
          </section>
        </div>
        <div class="upc-right">
          <p style="font-weight: 600">Recent activity</p>
          <hlx-loader v-if="$store.state.activityLog == undefined" load-type="dotted-jumper" />
  
          <ActivityLogComponent v-else :module="'CM'"></ActivityLogComponent>
        </div>
      </section>
    </div>
  </template>
  <script>
  import VueApexCharts from "vue3-apexcharts";
  
  // import deepdiff from "deepDiff"
  
  import ActivityLogComponent from "@/components/UPC/ActivityLogComponent.vue";
  import { MLTAxiosInstance, UPCAxiosInstance } from "@/config/axiosConfig";
  // import graphMap from "@/components/Product/graphMap.vue"
  import chartMap3 from "@/components/Product/chartMap3.vue"
  // import deepDiff from 'deep-diff';
  // import LZString from 'lz-string';
  export default {
    name: "UpcDashboardView",
    components: { VueApexCharts, ActivityLogComponent, 
      // graphMap
      chartMap3,

     },
    data() {
      return {
        mapCenter:{ lat: 65.9678315, lng: -101.9522398 },
        polygonPath:[],
        ticketTotal:'',
        currency:'',
        quotes:{},
        bssDashboarddata: [],
        bssbarChartdata:[],
        filterobj: {},
        productCompleteNess: {
          series: [],
          category: []
        },
        selectedTab: "Quotes",
        arrowUp: "greenarrow.svg",
        UPCDashboardBarSwitchItems: [
          {
            label: "Quotes",
  
            value: "Quotes"
          },
  
          {
            label: "Orders",
  
            value: "Orders"
          },
  
          {
            label: "Invoices",
  
            value: "Invoices"
          },
          {
            label: "Tickets",
  
            value: "Tickets"
          }
        ],
  
        categoryXData: [],
  
        marketXData: [],
  
        channelXData: [],
  
        // categoryYData: [
        //   {
        //     x: "Voice Services",
  
        //     y: 70
        //   },
  
        //   {
        //     x: "Television Services",
  
        //     y: 80
        //   },
  
        //   {
        //     x: "Business Solutions",
  
        //     y: 65
        //   },
  
        //   {
        //     x: "IoT Connectivity ",
  
        //     y: 45
        //   },
  
        //   {
        //     x: "Network Equipment",
  
        //     y: 50
        //   },
  
        //   {
        //     x: "Telecom Software",
  
        //     y: 60
        //   },
  
        //   {
        //     x: "Telecom Infrastructure",
  
        //     y: 80
        //   },
  
        //   {
        //     x: "Telecom Testing",
  
        //     y: 50
        //   }
        // ],
        categoryYData: [],
  
        marketYData: [],
  
        channelYData: [],
        seriesT: [
          {
            name: "Inflation",
            data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2]
          }
        ],
        chartOptions: {
          chart: {
            height: 350,
            type: "bar"
          },
          plotOptions: {
            bar: {
              borderRadius: 6,
              borderRadiusApplication: "top",
              horizontal: false,
              columnWidth: "30%",
              endingShape: "rounded",
              dataLabels: {
                // position: 'top', // top, center, bottom
              }
            }
          },
          dataLabels: {
            enabled: false,
            formatter: function (val) {
              return val + "%";
            },
            offsetY: -20,
            style: {
              fontSize: "12px",
              colors: ["#000000"]
            }
          },
  
          xaxis: {
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec"
            ],
            position: "bottom",
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: "gradient",
                gradient: {
                  colorFrom: "#D8E3F0",
                  colorTo: "#BED1E6",
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5
                }
              }
            },
            tooltip: {
              enabled: true
            }
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val + "%";
              }
            }
          },
          title: {
            text: "Monthly Inflation in Argentina, 2002",
            floating: true,
            offsetY: 330,
            align: "center",
            style: {
              color: "#444"
            }
          }
        },
        // series: [{
        //   name: 'series-1',
        //   data: [30, 40, 45, 50, 49, 60, 70, 91]
        // }]
        options: {
          chart: { toolbar: { show: false }, width: "100%", type: "bar" },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 4,
              borderRadiusApplication: "top",
              // borderRadiusWhenStacked: "last",
              columnWidth: "31%",
              // barHeight: "70%",
              endingShape: "rounded"
              // dataLabels: {
              //   position: "top",
              //   maxItems: 100,
              //   hideOverflowingLabels: true,
              // }
            }
          },
          dataLabels: { enabled: false },
          stroke: { show: false, width: 2, colors: ["transparent"] },
          xaxis: {
            categories: [],
            tickAmount: undefined,
            tickPlacement: "between",
            min: undefined,
            max: undefined,
            range: undefined,
            floating: false,
            decimalsInFloat: undefined,
            overwriteCategories: undefined,
            position: "bottom",
            labels: {
              show: true,
              rotate: 0,
              rotateAlways: false,
              hideOverlappingLabels: false,
              showDuplicates: false,
              trim: true,
              minHeight: undefined,
              maxHeight: 120,
              style: {
                colors: [],
                fontSize: "12px",
                fontFamily: "Opensans",
                fontWeight: 400,
                cssClass: "apexcharts-xaxis-label"
              },
              offsetX: 0,
              offsetY: 0,
              format: undefined,
              formatter: undefined,
              datetimeUTC: true,
              datetimeFormatter: {
                year: "yyyy",
                month: "MMM 'yy",
                day: "dd MMM",
                hour: "HH:mm"
              }
            },
            axisBorder: {
              show: false,
              color: "#78909C",
              height: 1,
              width: "100%",
              offsetX: 0,
              offsetY: 0
            },
            axisTicks: {
              show: false,
              borderType: "transparent",
              color: "#78909C",
              height: 6,
              offsetX: 0,
              offsetY: 0
            },
  
            title: {
              text: undefined,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-xaxis-title"
              }
            },
            crosshairs: {
              show: true,
              width: 1,
              position: "back",
              opacity: 0.9,
              stroke: {
                color: "#b6b6b6",
                width: 0,
                dashArray: 0
              },
              fill: {
                type: "solid",
                color: "#B1B9C4",
                gradient: {
                  colorFrom: "#D8E3F0",
                  colorTo: "#BED1E6",
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5
                }
              },
              dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 1,
                opacity: 0.4
              }
            },
            tooltip: {
              enabled: false,
              formatter: undefined,
              offsetY: 0,
              style: {
                fontSize: 0,
                fontFamily: 0
              }
            }
            // tooltip: false,
            // convertedCatToNumeric: false
          },
          yaxis: [
            {
              // axisTicks: { show: true },
              // axisBorder: { show: true, color: "" },
              // labels: { style: { colors: "" } },
              title: {
                text: "Revenue"
                // style: { color: "rgb(0, 143, 251)" }
              },
              labels: {
               formatter: function (number) {
    // Check if the number is less than a thousand
    if (number < 1000) {
      // let value = number.toString()
        return  number.toString();
    }
    // Convert the number to a string with "K" for thousands
    else if (number >= 1000 && number < 1000000) {
        return (number / 1000).toFixed(0) + 'K';
    }
    // Convert the number to a string with "M" for millions
    else if (number >= 1000000 && number < 1000000000) {
        return (number / 1000000).toFixed(1) + 'M';
    }
    // Convert the number to a string with "B" for billions
    else if (number >= 1000000000) {
        return (number / 1000000000).toFixed(1) + 'B';
    }
}
              },
              tooltip: { enabled: false }
            }
            // {
            //   seriesName: "Execution count",
            //   opposite: true,
            //   axisTicks: { show: false },
            //   axisBorder: { show: true, color: "" },
            //   labels: { style: { colors: "" } },
            //   title: { text: "Execution count", style: { color: "#54bd95" } }
            // }
          ],
  
          responsive: [{ breakpoint: 480, options: { legend: { show: false } } }],
          // legend: { show: false },
          grid: { borderColor: "#f7f7f7" },
          // fill: { opacity: 1 },
          tooltip: {
            enabled: true
          },
          colors: ["#54bd95", "rgb(0, 143, 251)"]
        },
        totalProduct: 0,
        productCompletenessChartOptions: {
          chart: { toolbar: { show: false }, width: "100%" },
          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 4,
              borderRadiusApplication: "Top",
              borderRadiusWhenStacked: "all",
              columnWidth: "20%",
              barHeight: "30%",
              distributed: false,
              rangeBarOverlap: true,
              rangeBarGroupRows: false,
              hideZeroBarsWhenGrouped: false,
              isDumbbell: false,
              dumbbellColors: undefined,
              isFunnel: false,
              isFunnel3d: true,
              colors: {
                ranges: [
                  {
                    from: 0,
                    to: 0,
                    color: undefined
                  }
                ],
                backgroundBarColors: [],
                backgroundBarOpacity: 1,
                backgroundBarRadius: 0
              }
            }
          },
          dataLabels: { enabled: false },
          stroke: { show: false, width: 2, colors: ["transparent"] },
          xaxis: {
            categories: []
  
            // axisTicks: {
            //   show: true
            // },
  
            // axisBorder: {
            //   show: true
  
            //   // color: "#54bd95"
            // },
  
            // labels: {
            //   style: {
            //     // colors: "#54bd95",
            //   }
            // }
          },
          yaxis: {
            // data: [2,4,6,8,10,12,14],
            // axis,,Ticks: { show: true },
            // axisBorder: { show: true, color: "" },
            // labels: { style: { colors: "" } },
            title: {
              // text: "Number of product"
              // style: { color: "rgb(0, 143, 251)" }
            },
            // min: 0,
            // max: 20,
            tickAmount: 1,
  
            tooltip: { enabled: false }
          },
          grid: { borderColor: "#f7f7f7" },
          tooltip: {
            enabled: true,
            // enabledOnSeries: ['image','description','price']
            onDatasetHover: {
              highlightDataSeries: false
            }
          },
          colors: ["#54bd95", "rgb(0, 143, 251)"]
        },
  
        series: [
          {
            name: "Revenue",
            type: "column",
            data: []
          }
        ],
        productCompletenessSeries: [{ data: [] }],
        progress: 1,
        company: this.$cookies.get("company"),
        placeMetrics: {
          series: [],
          chart: {
            // height: "300px",
            // width: "300px",
            height: "100%",
            type: "donut",
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            pie: {
              customScale: 0.8,
              size: 100
            },
            background: "transparent"
          },
          colors: [
            "rgba(49, 132, 101, 1)",
            "rgba(84, 189, 149, 1)",
            "rgba(182, 233, 213, 1)"
          ],
          labels: [],
          dataLabels: {
            enabled: false
          },
  
          // responsive: [
          //   {
          //     breakpoint: 480,
          //     options: {
          //       chart: {
          //         width: "300px",
          //         height: "300px"
          //       },
          //       legend: {
          //         show: false
          //       }
          //     }
          //   }
          // ],
          legend: {
            position: "right",
            offsetY: 0,
            offsetX: 2,
            height: 100,
            // width:100,
            show: true,
            verticalAlign: "center",
            horizontalAlign: "center"
            // floating: true,
          },
          content: {
            title: "Total sales",
            total: "$85K"
          }
        },
        partyWithProductCount: [],
        statusMetrics: {},
        userInfo: this.$store.state.userInfo
      };
    },

    computed: {
    // countryList() {
    //   let storedCountryList = localStorage.getItem("countryList");
    //   // let countryList = JSON.parse(LZString.decompress(storedCountryList));
    //   console.log("storedCountryList watching",countryList);
      
    //   return countryList || []; // Default to an empty array if null
    // },
  },

    watch: {
    // countryList: {
    //   handler(newList) {
    //     // console.log(newList, "Country list has changed:");
    //      if(newList && newList?.mapCenter && newList?.polygonPath){
    //       this.polygonPath = newList?.polygonPath;
    //       this.mapCenter = newList?.mapCenter;
    //      }

    //   },
    //   deep: true,
    //   immediate: true,
    // },

      currency:{
        handler(val){
          if(val){
           this.options = {
          chart: { toolbar: { show: false }, width: "100%", type: "bar" },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 4,
              borderRadiusApplication: "top",
              // borderRadiusWhenStacked: "last",
              columnWidth: "31%",
              // barHeight: "70%",
              endingShape: "rounded"
              // dataLabels: {
              //   position: "top",
              //   maxItems: 100,
              //   hideOverflowingLabels: true,
              // }
            }
          },
          dataLabels: { enabled: false },
          stroke: { show: false, width: 2, colors: ["transparent"] },
          xaxis: {
            categories: [],
            tickAmount: undefined,
            tickPlacement: "between",
            min: undefined,
            max: undefined,
            range: undefined,
            floating: false,
            decimalsInFloat: undefined,
            overwriteCategories: undefined,
            position: "bottom",
            labels: {
              show: true,
              rotate: 0,
              rotateAlways: false,
              hideOverlappingLabels: false,
              showDuplicates: false,
              trim: true,
              minHeight: undefined,
              maxHeight: 120,
              style: {
                colors: [],
                fontSize: "12px",
                fontFamily: "Opensans",
                fontWeight: 400,
                cssClass: "apexcharts-xaxis-label"
              },
              offsetX: 0,
              offsetY: 0,
              format: undefined,
              formatter: undefined,
              datetimeUTC: true,
              datetimeFormatter: {
                year: "yyyy",
                month: "MMM 'yy",
                day: "dd MMM",
                hour: "HH:mm"
              }
            },
            axisBorder: {
              show: false,
              color: "#78909C",
              height: 1,
              width: "100%",
              offsetX: 0,
              offsetY: 0
            },
            axisTicks: {
              show: false,
              borderType: "transparent",
              color: "#78909C",
              height: 6,
              offsetX: 0,
              offsetY: 0
            },
  
            title: {
              text: undefined,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: undefined,
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                cssClass: "apexcharts-xaxis-title"
              }
            },
            crosshairs: {
              show: true,
              width: 1,
              position: "back",
              opacity: 0.9,
              stroke: {
                color: "#b6b6b6",
                width: 0,
                dashArray: 0
              },
              fill: {
                type: "solid",
                color: "#B1B9C4",
                gradient: {
                  colorFrom: "#D8E3F0",
                  colorTo: "#BED1E6",
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5
                }
              },
              dropShadow: {
                enabled: false,
                top: 0,
                left: 0,
                blur: 1,
                opacity: 0.4
              }
            },
            tooltip: {
              enabled: false,
              formatter: undefined,
              offsetY: 0,
              style: {
                fontSize: 0,
                fontFamily: 0
              }
            }
            // tooltip: false,
            // convertedCatToNumeric: false
          },
          yaxis: [
            {
              // axisTicks: { show: true },
              // axisBorder: { show: true, color: "" },
              // labels: { style: { colors: "" } },
              title: {
                text: "Revenue"
                // style: { color: "rgb(0, 143, 251)" }
              },
              labels: {
               formatter: function (number) {
    // Check if the number is less than a thousand
    if (number < 1000) {
      // let value = number.toString()
        return val + number.toString();
    }
    // Convert the number to a string with "K" for thousands
    else if (number >= 1000 && number < 1000000) {
        return val + (number / 1000).toFixed(0) + 'K';
    }
    // Convert the number to a string with "M" for millions
    else if (number >= 1000000 && number < 1000000000) {
        return val + (number / 1000000).toFixed(1) + 'M';
    }
    // Convert the number to a string with "B" for billions
    else if (number >= 1000000000) {
        return val + (number / 1000000000).toFixed(1) + 'B';
    }
}
              },
              tooltip: { enabled: false }
            }
            // {
            //   seriesName: "Execution count",
            //   opposite: true,
            //   axisTicks: { show: false },
            //   axisBorder: { show: true, color: "" },
            //   labels: { style: { colors: "" } },
            //   title: { text: "Execution count", style: { color: "#54bd95" } }
            // }
          ],
  
          responsive: [{ breakpoint: 480, options: { legend: { show: false } } }],
          // legend: { show: false },
          grid: { borderColor: "#f7f7f7" },
          // fill: { opacity: 1 },
          tooltip: {
            enabled: true
          },
          colors: ["#54bd95", "rgb(0, 143, 251)"]
        }
          }
        },
        immediate:true,
        deep:true
      },
      statusMetrics: {
        handler(data) {
          // console.log(
          //   "🚀 ~ file: upcDashboardView.vue:841 ~ handler ~ data:",
          //   data
          // );
          // console.log(
          //   "🚀 ~ file: upcDashboardView.vue:843 ~ handler ~ data && Object.keys(data).length == 0:",
          //   data && Object.keys(data).length > 0
          // );
          if (data && Object.keys(data)?.length > 0) {
            this.totalProduct = data["Total product"];
            // this.optionsH.yaxis.min = 0;
            // this.optionsH.yaxis.max = this.totalProduct;
            // console.log("🚀 ~ file: upcDashboardView.vue:845 ~ handler ~ this.optionsH:", this.optionsH)
            let a, b, c;
            a = this.totalProduct - 2;
            console.log("🚀 ~ file: upcDashboardView.vue:849 ~ handler ~ a:", a);
            b = a - 2;
            console.log("🚀 ~ file: upcDashboardView.vue:851 ~ handler ~ b :", b);
            c = b - 1;
            console.log("🚀 ~ file: upcDashboardView.vue:853 ~ handler ~  c:", c);
            // this.seriesH[0].data[0] = a;
            // this.seriesH[0].data[1] = b;
            // this.seriesH[0].data[2] = c;
            // setTimeout(() => {
  
            // }, 3000);
          }
        },
        deep: true,
        immediate: true
      },
      // selectedTab: {
      //   handler(tabValue) {
      //     // console.log(
      //     //   "🚀 ~ file: upcDashboardView.vue:903 ~ handler ~ tabValue:",
      //     //   tabValue
      //     // );
      //     if (!tabValue) return;
  
      //     let key = tabValue;
  
      //     switch (key) {
      //       case "market":
      //         this.series[0].data = this.marketYData;
      //         // this.series[0].data = [55,65,75,85,89];
      //         // this.options.xaxis.categories = this.marketXData
      //         break;
  
      //       case "channel":
      //         this.series[0].data = this.channelYData;
      //         // this.options.xaxis.categories = this.channelXData
      //         break;
  
      //       default:
      //         this.series[0].data = this.categoryYData;
      //         // this.options.xaxis.categories = this.categoryXData
      //         break;
      //     }
      //   },
      //   immediate: true,
      //   deep: true
      // }
    },
  
    async mounted() {
     let query= {
        type: "filter",
        module: "ticket",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        filterQuery: {},
        searchQuery: "",
      }
      const resoponstable = await UPCAxiosInstance.post(
        "util/filterData",
        query
      );
this.ticketTotal=resoponstable.data.total
      await this.$store.dispatch("bssBarchartData")
      this.bssbarChartdata = this.$store.getters.bssBarchartData
      this.series[0].data = this.bssbarChartdata
      await this.$store.dispatch("bssPiechartQuotes")
            this.quotes = {}
            this.quotes = this.$store.getters.bssPiechartQuotes
            this.placeMetrics.series = this.quotes.series
            this.placeMetrics = {
          ...this.placeMetrics,
          labels: this.quotes.labels
        };
            // this.placeMetrics.labels = this.quotes.labels
      console.log(this.$store.getters.bssPiechartOrder,'post',this.quotes.series,this.quotes.labels,this.placeMetrics);
      // this.bssDashboarddata = this.$store.getters.bssDashboardData
      await this.$store.dispatch("bssDashboardData")
      this.bssDashboarddata = this.$store.getters.bssDashboardData
      console.log(this.bssDashboarddata,'bssDashboard')
      await this.$store.dispatch("contactFilterData");
      this.filter = this.$store.getters.contactsFilterData;
      let individualRes = await MLTAxiosInstance.get(
        `partymanagement/individual/auth`
      );
      this.company = individualRes.data.company;
      this.userId = individualRes.data.company;
      let userRes = await MLTAxiosInstance.get(
        `partymanagement/individual/${individualRes.data.userId}?company=${this.company}`
      );
      console.log("🚀 ~ mounted ~ userRes:", userRes)
      this.name = userRes.data.name;
      this.$cookies.set("userName", this.name);
  
      // For product compeleteness
  
      let productCompleteNess = await UPCAxiosInstance.get(
        `/dashboard/product_completeness`
      );
      console.log("🚀 ~ mounted ~ productCompleteNess:", productCompleteNess)
      if (productCompleteNess.data) {
        this.productCompletenessSeries[0].data = productCompleteNess.data.series;
  
        this.productCompletenessChartOptions = {
          xaxis: { categories: productCompleteNess.data.category },
          yaxis: {
            tooltip: { enabledOnSeries: productCompleteNess.data.category }
          }
          // tooltip: {
          //   enabled: true,
          //   enabledOnSeries: productCompleteNess.data.category
          // },
        };
      }
  
      // For place metrics
  
      // let placeMetrics = await UPCAxiosInstance.get(
      //   `/dashboard/place_metric`
      // );
      // console.log("🚀 ~ mounted ~ placeMetrics:", placeMetrics)
      // if (placeMetrics.data && Object.keys(placeMetrics.data).length > 0) {
      //   this.placeMetrics.series = placeMetrics.data.total;
      //   this.placeMetrics = {
      //     ...this.placeMetrics,
      //     labels: placeMetrics.data.places
      //   };
      // }
      // For party with product count metrics
  
      let partyWithProductCount = await UPCAxiosInstance.get(
        `/dashboard/party_with_product_count`
      );
      console.log("🚀 ~ mounted ~ partyWithProductCount:", partyWithProductCount)
      if (partyWithProductCount.data) {
        partyWithProductCount.data.forEach((e,index)=>{
          if(index<5){
            this.partyWithProductCount.push(e)
          }
        })
      }
  
      let statusMetrics = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/dashboard_status_data`
      );
      console.log(
        "🚀 ~ file: upcDashboardView.vue:1079 ~ mounted ~ statusMetrics:",
        statusMetrics
      );
      if (statusMetrics.data && statusMetrics.data.ok == 0) {
        this.statusMetrics = []
      } else {
        this.statusMetrics = statusMetrics.data;
      }
  
      let barChartData = await UPCAxiosInstance.get(
        `/catalogManagement/productOffering/dashboard_chart`
      );
      console.log("🚀 ~ mounted ~ barChartData:", barChartData)
      if (barChartData && barChartData.data) {
        barChartData = barChartData.data;
  
        this.categoryXData = barChartData.XData?.category;
        this.marketXData = barChartData.XData?.market;
        this.channelXData = barChartData.XData?.channel;
  
        this.categoryYData = barChartData.YData?.category;
        this.marketYData = barChartData.YData?.market;
        this.channelYData = barChartData.YData?.channel;
        // console.log(categorydata.data,category.data,"🎉");
        // this.series[0].data = this.categoryYData;
      }
  
      await this.$store.dispatch('loadLifeCycleStatus')
      // this.handleBarChatData({value:'category'})
      let interval = setInterval(() => {
        if (this.progress < 75) {
          this.progress += 2;
        } else {
          this.progress -= 5;
        }
      }, 200);
      setTimeout(() => {
        // this.progress = 100;
        clearInterval(interval);
      }, 15000);

      this.currency = this.$store?.getters?.getDefaultSettings?.currency
      ?.symbol
      console.log(this.placeMetrics,this.placeMetrics.series,'post',this.bssDashboarddata);
      // this.options.xaxis.categories = this.categoryXData
    },
    methods: {
      handleRelatedParty(value) {
        let arr = []
        arr.push(value)
        console.log(value, 'kkdkd');
        this.filterobj['relatedParty'] = arr
        this.$store.commit('filterPreval', this.filterobj)
        this.$store.commit('filterPreval', this.filterobj)
        this.$router.push("product-catalog-table/offering")
      },
      handleStatus(id, $event) {
        $event
        let data = document.querySelector('#' + id).children[0].innerText
        let arr = []
        this.filterobj = {}
        if (data == 'In study') {
          arr.push('In study')
          this.filterobj['Status'] = arr
          this.$store.commit('filterPreval', this.filterobj)
          this.$router.push({ name: 'offeringview', query: { name: this.filterobj } });
          // this.$router.push("product-catalog-table/offering")
          console.log(this.filterobj, 'filterobj');
        }
        else if (data == 'Active') {
          arr.push('Active')
          this.filterobj['Status'] = arr
          this.$store.commit('filterPreval', this.filterobj)
          this.$router.push({ name: 'offeringview', query: { name: this.filterobj } });
          // this.$router.push("product-catalog-table/offering")
          console.log(this.filterobj, 'filterobj');
        }
        else if (data == 'Launched') {
          arr.push('Launched')
          this.filterobj['Status'] = arr
          this.$store.commit('filterPreval', this.filterobj)
          this.$router.push({ name: 'offeringview', query: { name: this.filterobj } });
          // this.$router.push("product-catalog-table/offering")
          console.log(this.filterobj, 'filterobj');
        }
        else if (data == 'Retired') {
          arr.push('Retired')
          this.filterobj['Status'] = arr
          this.$store.commit('filterPreval', this.filterobj)
          this.$router.push({ name: 'offeringview', query: { name: this.filterobj } });
          // this.$router.push("product-catalog-table/offering")
          console.log(this.filterobj, 'filterobj');
        }
        else if (data == 'Obsolete') {
          arr.push('Obsolete')
          this.filterobj['Status'] = arr
          this.$store.commit('filterPreval', this.filterobj)
          this.$router.push({ name: 'offeringview', query: { name: this.filterobj } });
          // this.$router.push("product-catalog-table/offering")
          console.log(this.filterobj, 'filterobj');
        }
        else if (data == 'Total Customers') {
          // arr = ['In study', 'Active', 'Launched', 'Retired', 'Obsolete']
          // this.filterobj['Status'] = arr
          // this.$store.commit('filterPreval',this.filterobj)
          this.$router.push("/CM/accounts")
          // console.log(this.filterobj,'filterobj');
        }
        console.log(data);
      },
      handleChartClick(event, chartContext, config) {
        this.filterobj = {}
        const clickedValue = config;
        clickedValue
        const eve = event
        const charcontext = chartContext
        charcontext
        console.log(eve?.target?.parentElement?.getAttribute('seriesName'),'lsk');
        let data = []
        let value = eve?.target?.parentElement?.getAttribute('seriesName')?.toString()
        let result = '';
        for (let i = 0; i < value.length; i++) {
          if (value[i] === 'x' && i > 0 && value[i-1] >= 'A' && value[i-1] <= 'Z') {
              result += ' ';
          } else {
              result += value[i];
          }
      }
        // console.log(result,'jdkdkd');
        data.push(result)
        this.filterobj['Place'] = data
        this.$store.commit('filterPreval', this.filterobj)
        // this.$router.push("product-catalog-table/offering")
        console.log(this.filterobj, 'filterobj');
      },
      handleBarChart($event, keyValue) {
        this.filterobj = {}
        if ($event.target.tagName == 'path') {   // Barchart graph
          let id = $event.target.getAttribute('id')
          var count = 0
          document.querySelectorAll('.apexcharts-bar-area').forEach((e, index) => {
            let compareid = e.getAttribute('id')
            if (compareid == id) {
              count = index
            }
          })
          let data = []
          if (keyValue != 'productCompleteness') {
            data.push(this.series[0]['data'][count]['x'])
            let key = ''
            if (keyValue.toLowerCase() == 'market') {
              key = 'market segment'
            }
            else if (keyValue.toLowerCase() == 'category') {
              key = 'category'
            }
            else if (keyValue.toLowerCase() == 'channel') {
              key = 'channel'
            }
            this.filterobj[key] = data
          } else {
            // let productcompletenesslength = count - this.series[0]['data']?.length 
            let productcompletenesslength = 2
            data.push(this.productCompletenessChartOptions['xaxis']['categories'][productcompletenesslength])
            this.filterobj[keyValue] = data
          }
          console.log(this.filterobj, 'filterobj');
        }
        else if ($event.target.tagName == 'tspan') //Barchart heading
        {
          let data = []
          let val = $event?.target?.nextElementSibling?.innerHTML
          if (keyValue != 'productCompleteness') {
            this.series[0]['data'].forEach((e) => {
              if (val == e.x) {
                data.push(e.x)
              }
            })
            let key = ''
            if (keyValue.toLowerCase() == 'market') {
              key = 'market segment'
            }
            else if (keyValue.toLowerCase() == 'category') {
              key = 'category'
            }
            else if (keyValue.toLowerCase() == 'channel') {
              key = 'channel'
            }
            this.filterobj[key] = data
          }
          else {
            this.productCompletenessChartOptions['xaxis']['categories'].forEach((e) => {
              if (val == e) {
                data.push(e)
              }
            })
            this.filterobj[keyValue] = data
          }
          console.log(this.filterobj, 'filterobj');
        }
        this.$store.commit('filterPreval', this.filterobj)
        this.$router.push({ name: 'offeringview', query: { name: this.filterobj }})
      },
      isDownOrIncrease(metric) {
        // console.log(
        //   "🚀 ~ file: upcDashboardView.vue:840 ~ isDownOrIncrease ~ metric:",
        //   metric
        // );
        if (!metric) return "-";
        return metric.type;
      },
      getImageUrl(name) {
        return new URL(`../../assets/images/${name}`, import.meta.url).href;
      },
      async handleBarChatData(tabValue) {
        // console.log(
        //   "🚀 ~ file: upcDashboardView.vue:1024 ~ handleBarChatData ~ tabValue:",
        //   tabValue
        // );
        if (!tabValue) return;
  
        let key = tabValue.value;
  
        switch (key) {
          case "Quotes":
          await this.$store.dispatch("bssPiechartQuotes")
            this.quotes = {}
            this.quotes = this.$store.getters.bssPiechartQuotes
            this.placeMetrics.series = this.quotes.series
            this.placeMetrics = {
          ...this.placeMetrics,
          labels: this.quotes.labels
        };
            break;
  
          case "Orders":
            await this.$store.dispatch("bssPiechartOrder")
            this.quotes = {}
            this.quotes = this.$store.getters.bssPiechartOrder
            this.placeMetrics.series = this.quotes.series
            this.placeMetrics = {
          ...this.placeMetrics,
          labels: this.quotes.labels
        };
            break;
  
          default:
            // eslint-disable-next-line no-case-declarations
            // let category = await UPCAxiosInstance.get(`/catalogManagement/productOffering/status/category`)
            // this.categoryXData = await category.data
            // if(this.categoryXData && this.categoryXData.length>0){
            //   this.options.plotOptions.bar.columnWidth = (3*this.categoryXData.length) + "%"
  
            // }
            // // eslint-disable-next-line no-case-declarations
            // let categorydata = await UPCAxiosInstance.get(`/catalogManagement/productOffering/dashboard_chart/category`)
            // this.categoryYData = await categorydata.data
            // console.log("🚀 ~ file: upcDashboardView.vue:900 ~ handleBarChatData ~ this.categoryYData:", this.categoryYData)
            // if(this.categor)
            // this.series[0].data = this.categoryYData;
            // this.options.xaxis.categories = this.categoryXData
            break;
        }
      }
      // deep: true,
      // immediate: true
    }
  };
  </script>
  