<template>
  <hlx-modal
    :modal-active="show"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'210px'"
    :width="'400px'"
    :modal="true"
    @close="$emit('close')"
  >
    <template #header>
      <div>
        <!-- Unsaved changes -->
        <span v-if="staticData">{{ labelData?.unsavedChanges }}</span>
      </div>
    </template>
    <template #content>
      <div class="modal-content">
        <!-- Do you want to save your changes before navigating away? -->
        <span v-if="staticData">{{ labelData?.discardModalMessage }}</span>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="discardAllChanges"
        >
        <!-- Discard -->
        <span v-if="staticData">{{ labelData?.discard }}</span>
        </hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        @click="stay"        
        >
        <!-- Stay -->
        <span v-if="staticData">{{ labelData?.stay }}</span>
        </hlx-button
      >
    </template>
  </hlx-modal>
</template>
<script>
// import hlxButton from "../components/ButtonComponent.vue";
import {
  // MLTAxiosInstance,
  // DMNAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
export default {
  name: "HlxTextEditor",
  components: {

  },
  props: {
    show:{
      type:Boolean,
      default:false 
    },
    labelData: {
      type: Object,
      default: function () {
        return {
          unsavedChanges : "Unsaved changes",
          discardModalMessage: "Do you want to save your changes before navigating away?",
          discard: "Discard",
          stay: "Stay",
        };
      },
    },
    message:{
      type:String,
      default:'Do you want to save your changes before navigating away?' 
    },
    primaryButtonValue:{
      type:String,
      default:'Stay' 
    },
    secondaryButtonValue:{
      type:String,
      default:'Discard' 
    },
  },
  emits: ["stay", "discard",'close'],
  data() {
    return {
      loginUserDetails: {},
      staticData:null,
    };
  },
  async mounted() {
    await this.$store.dispatch("loginTenantDetails");
    await this.$store.dispatch("loginUserDetails");
    // loginUserDetails
    // await this.$store.dispatch("loginTenantDetails");
    // let r = await this.$store.getters.loginTenantDetails;
    console.log("loginTenantDetails resrfsef:", this.$store.getters.loginTenantDetails);
    console.log("loginUserDetails resrfsef:", this.$store.getters.loginUserDetails);

    this.loginUserDetails = this.$store?.getters?.loginUserDetails?.languageAbility[0]?.code;

    // this.loginUserDetails.languageAbility[0].name
    // this.loginUserDetails.languageAbility[0].code

    try {
      let organisationInfo = await UPCAxiosInstance.get('/multi-language');
      this.staticData = organisationInfo?.data?.filter(itm => itm && itm.modules)?.[0]?.modules;
      console.log("this.staticData:",organisationInfo , this.staticData);
      
    }
    catch (error) {
      console.error("error::", error)
    }
  },
  methods: {
    discardAllChanges()
    {
      this.$emit('discard','')
    },
    stay()
    {
      console.log("🚀 ~ stay:", 'stay')
      this.$emit('stay','')
    }
    

  },
};
</script>