<template>
<hlx-alert-notification :notify="notification" :auto-close="true" />








    <!-- {{ regioncode }} -->
    <div>
      <hlx-modal
        style="z-index: 999999999"
        :modal-active="delete_modal_active"
        :height="'220px'"
        :width="'400px'"
        :modal="true"
        @close="delete_modal_active = false"
      >
        <template #header>
          <div style="margin-left: 0px">Delete data</div>
        </template>
        <template #content>
          <div class="modal-content">
            <p>
              Are you sure you want to delete the Excluded region?
            </p>
          </div>
        </template>
        <template #footer>
          <hlx-button class="secondary sm" @click="delete_modal_active = false"
            >Cancel</hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 16px"
            @click="deleteExecRow"
            >Delete</hlx-button
          >
        </template>
      </hlx-modal>
    </div>














  <!-- add exclisive drawer -->
  <div class="add-contact-drawer">
    <hlx-drawer
    :key="componentkey"
      :show="showDrawer"
      position="right"
      width="400"
      :footer="true"
      :title="'Edit notes'"
      :show-close-icon="true"
      @close="closeDrawer"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          
          <b v-if="editMode == false" >
             Add exclusion
            </b>
          <b v-else>
            Edit exclusion              
            </b>
        </div>
        <div class="body-area-contact-drawer">
        


  
            <div class="decision-name-wrapper" style="margin-bottom:20px;">
              <hlx-select
                v-model:value="type"
                :required="true"
                :pre-value="type"
                :options="dropdown_data_type"
                :placeholder-value="'Exclude type'"
                :label-animation="true"
                :label="'value'"
                :prop-value="'value'"
                :clearable="true"
              @selected-value="emitExcludeType"
              />
            </div>


            <div v-if="type ==='Partial address'" class="decision-name-wrapper" style="margin-bottom:20px;">
              <hlx-select
                v-model:value="criteria"
                :required="true"
                :pre-value="criteria"
                :options="dropdown_data_criteria"
                :placeholder-value="'Choose criteria'"
                :label-animation="true"
                :label="'value'"
                :prop-value="'value'"
                :clearable="true"
              @selected-value="criteriaEmit"
              />
            </div>
  

            
            <!-- :new-coordinate="newCoordinate" -->
            <addressComponent
            :key="addressKey"
            :type="type"
            :criteria="criteria"
            :module="'exclude'"
            :parent-info="parentinfo"
                :pre-val="addressPreVal"
              @address-data="receivedAddressData"
                @geopoint="geopoint"
            ></addressComponent>



                  
                  
                  
                 <div style="margin-bottom:20px;">
                  <hlx-select
                  :key="resonKey"
                      v-model="reason"
                :pre-value="reason"
                      :options="reasonList"
                      :label-animation="true"
                :placeholder-value="'Reason'"
                      :label="'value'"
                      :prop-value="'value'"
                      :clearable="true"
                      @selected-value="reasonListEmit"
                    ></hlx-select>
                  </div>




                  <hlx-input
                v-model:value="validationEng"
                :pre-val="validationEng"
                type="textarea"
                :label-value="'Validation message - English'"
                :required="true"
                :label-animation="true"
                :display-error="true"
                :rows="4"
                :custom-error-message="customErrorMessage"
                @focus-out="focusOutName"
                @at-input="validateName"
              ></hlx-input>

              <hlx-input
                v-model:value="validationFre"
                :pre-val="validationFre"
                type="textarea"
                :label-value="'Validation message - French'"
                :required="true"
                :label-animation="true"
                :display-error="true"
                :rows="4"
                :custom-error-message="customErrorMessage"
                @focus-out="focusOutName"
                @at-input="validateName"
              ></hlx-input>









        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeDrawer"
            >
            Cancel
            </hlx-button
          >
          <hlx-button
            v-if="editMode == false"
            class="primary sm"
            style="margin-left: 10px"
          :disabled="!enableAdd"
            @click="addExcluded"
            >
            Add
            </hlx-button
          >
          <hlx-button
            v-else
            class="primary sm"
            style="margin-left: 10px"
            @click="addExcluded"
            >
            Save
            </hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>







  
  <section
  v-if="tableData && tableData?.length > 0 || searchvalue !== ''" 
  style="margin: 10px 15px">

    
  <!-- header -->
  <section class="right-view-body-feature">
      <span class="spec-feature --table-tool-bar" style="margin: 10px 0">
        <p
          class="--upcv2-body-title"
        >
        Exclude region
        </p>
        <div
          class="--table-tool-bar-wrapper"
        >
          <span class="util-items search"
            ><hlx-search
              v-model="searchvalue"
              @search-key="searchKey"
            ></hlx-search
          ></span>
          <!-- Filter -->

          <span class="util-items util-items-add"
            ><hlx-button 
              class="primary sm add-btn"
              @click="openDrawer"
              ><i class="icon-plus-circle-regular" style="margin-right: 5px"></i
              >
              Exclude region
              </hlx-button
            >
          </span>
        </div>
      </span>
    </section>
  </section>













  <!-- table -->
  <section v-if="tableData && tableData?.length > 0" style="margin: 10px 15px">










      <hlx-table
        :key="refreshTable1"
        :column-count="tableHeads + 2"
        :border="['table', 'header', 'horizontal']"
        :theme="'grey'"
        :bold-headers="false"
        :row-hover="true"
        :striped-rows="false"
        :re-arrange-columns="re_arrange"
        :re-arrange-heads="theads"
        @close-rearrange="arrange"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head
            :width="60"
            :align="'left'"
            style="padding: 0 15px !important; max-width: 60px !important"
            :fixed="'left'"
            >{{ "S.no" }}</hlx-table-head
          >
          <hlx-table-head
            v-for="(i, index) in tableHeads"
            :key="index"
            :prop="i.prop"
            :width="i.width"
            :align="'left'"
            :resizable="true"
          >
            {{ i.label }}
          </hlx-table-head>
          <hlx-table-head :align="'center'" :width="60" :fixed="'right'"
            ><span class="action-icon">
              <i
                class="icon-settings-regular"
                style="cursor: default"
              ></i> </span
          ></hlx-table-head>
        </template>
        <template #tbody>
          <tr v-for="(i, index) in tableDataOnSelectedRows" :key="index">
            <hlx-table-cell :width="60" fixed="left">
              {{ serialNumber(index) }}
            </hlx-table-cell>
            <hlx-table-cell
              v-for="(j, col_index) in tableHeads"
              :key="col_index"
              :align="'left'"
              :width="i.width"
            >
              <div v-if="j.prop === 'Exclude type'">
                <div
                    v-for="(addressLine, index1) in i.streetAddressLine"
                    :key="index1"
                  >
                    <span v-if="addressLine.characteristic.type">{{
                      addressLine?.characteristic?.type
                    }}</span>
                  </div>               
              </div>


              <div v-else-if="j.prop === 'Location'" style="width:250px">
                  <div
                    v-for="(addressLine, index1) in i.streetAddressLine"
                    :key="index1" 
                  >
                    <span v-if="addressLine.characteristic.criteria">
                      <!-- {{
                      // removeFirstComma(addressLine?.characteristic?.description)
                    }} -->
                      <span v-if="addressLine.characteristic.criteria === 'Street name + Prefix'">
                        <div class="flex_gap_box_location">
                          <span>{{ addressLine.characteristic.street }}</span>
                          <hlx-label
                        class="info sm"
                        >
                      <span>{{ 'Street name' }}</span>
                      </hlx-label>
                    </div>

                    <div class="flex_gap_box_location">
                      <span>{{ addressLine.characteristic.postalCodePrefix }}</span>
                          <hlx-label
                        class="info sm"
                        >
                      {{ 'FSA' }}
                      </hlx-label>
                    </div>
                      </span>

                      <!-- Street name + Postal code -->
                      <span v-if="addressLine.characteristic.criteria === 'Street name + Postal code'">
                        <div class="flex_gap_box_location">
                          <span>{{ addressLine.characteristic.street }}</span>
                          <hlx-label
                        class="info sm"
                        >
                      {{ 'Street name' }}
                      </hlx-label>
                    </div>

                    <div class="flex_gap_box_location">
                      <span>{{ addressLine.characteristic.postalCode }}</span>
                          <hlx-label
                        class="info sm"
                        >
                      {{ 'Postal code' }}
                      </hlx-label>
                    </div>
                      </span>

                      <!-- City + Postal code -->
                      <span v-if="addressLine.characteristic.criteria === 'City + Postal code'">
                        <div class="flex_gap_box_location">
                          <span>{{ addressLine.characteristic.city }}</span>
                          <hlx-label
                        class="info sm"
                        >
                      {{ 'City' }}
                      </hlx-label>
                    </div>

                    <div class="flex_gap_box_location">
                      <span>{{ addressLine.characteristic.postalCode }}</span>
                          <hlx-label
                        class="info sm"
                        >
                      {{ 'Postal code' }}
                      </hlx-label>
                    </div>
                      </span>

                      <!-- City + Prefix -->
                      <span v-if="addressLine.characteristic.criteria === 'City + Prefix'">
                        <div class="flex_gap_box_location">
                          <span>{{ addressLine.characteristic.city }}</span>
                          <hlx-label
                        class="info sm"
                        >
                      {{ 'City' }}
                      </hlx-label>
                    </div>

                    <div class="flex_gap_box_location">
                      <span>{{ addressLine.characteristic.postalCodePrefix }}</span>
                          <hlx-label
                        class="info sm"
                        >
                      {{ 'FSA' }}
                      </hlx-label>
                    </div>
                      </span>

                    </span>

                    <!-- desc -->
                     <span v-else>
                      {{ this.removeFirstComma(addressLine.characteristic.description) }}
                     </span>
                  </div>
                </div>



                <div v-else-if="j.prop === 'Reason'" style="width:165px">
                <div
                    v-for="(addressLine, index1) in i.streetAddressLine"
                    :key="index1"
                  >
                    <span v-if="addressLine.characteristic.reason">{{
                      addressLine?.characteristic?.reason
                    }}</span>
                  </div>               
              </div>




              <div v-else-if="j.prop === 'Created date'" style="width:127px">
                <div
                    v-for="(addressLine, index1) in i.streetAddressLine"
                    :key="index1"
                  >
                    <span v-if="addressLine.characteristic.createdat">{{
                      formatDate(addressLine?.characteristic?.createdat)
                    }}</span>
                  </div>               
              </div>





              <div v-else-if="j.prop === 'Last updated'" style="width:127px">
                <div
                    v-for="(addressLine, index1) in i.streetAddressLine"
                    :key="index1"
                  >
                    <span v-if="addressLine.characteristic.updatedat">{{
                      formatDate(addressLine?.characteristic?.updatedat)
                    }}</span>
                  </div>               
              </div>

              
              <!-- <div v-else-if="j.prop == 'description'" style="width: 250px">
                {{ i?.definition?.name }}
              </div> 

              <div v-else-if="j.prop == 'tags'" style="width: 200px">
                <hlx-tagcount
                        :arrayfile="convertToNamedObjects(i.tag)"
                        :item-key="'name'"
                        :type="'label'"
                      ></hlx-tagcount>
              </div>
              
              <div v-else-if="j.prop == 'createdBy'" style="width:162px">
                  {{ $cookies.get("company") }}
              </div> 

              <div v-else-if="j.prop == 'enableRule'"  style="width: 150px">
                <hlx-input
                v-model:value="showDraggableFunction"
                :checked="i[j.prop]"
                :disabled="false"
                :type="'switch'"
              />
              </div>  -->

              <span v-else> 
                {{ i[j.prop] }}                 
              </span>
            </hlx-table-cell>
            <hlx-table-cell :align="'center'" :width="120" :fixed="'right'">
              <span :id="'table-context' + index" class="action-icon">
                <i
                  style="position: relative"
                  class="icon-more-vertical-filled"
                  :class="i.context === true ? 'active-action' : ''"
                  @click="crudContextMenu($event, i, index)"
                >
                  <hlx-context-menu
                    :top="containertop"
                    :left="containerleft"
                    :conditionvalue="150"
                    :conditiondown="2"
                    :conditionup="-38"
                    :options="computedTableAction(i)"
                    :data="index.toString()"
                    :show="i.context == true"
                    @chosen="closeCrudContextMenu($event, i, index)"
                  />
                </i>
              </span>
            </hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
    </section>
    <!-- no data -->
    <section v-if="tableData.length === 0 && searchvalue !== ''" style="margin: 10px 15px">
      <hlx-table
          :column-count="tableHeads.length + 2"
          :border="['table', 'header']"
          :bold-headers="false"
          :row-hover="true"
          theme="grey"
          :striped-rows="false"
          :re-arrange-columns="toggleRearrangeModal"
          :re-arrange-heads="tableHeadsRearranging"
          @close-rearrange="toggleRearrangeModal = false"
          @apply-rearranged-columns="applyColumns"
        >
          <template #thead>
            <hlx-table-head :width="60" :align="'left'"   @new-resize-width="resizedWidth">{{
              "S.no"
            }}</hlx-table-head>
            <hlx-table-head
              v-for="(i, index) in tableHeads"
              :key="index"
              :prop="i.prop"
              :sortable="i.sortable"
              :resizable="i.resizable"
              style="position: sticky; top: 0px"
              :width="i.width !== undefined ? i.width : ''"
              :align="i.align !== undefined ? i.align : ''"
              @sorting_func="sorting_Data"
                @new-resize-width="resizedWidth"
            >
              {{ i.label }}
            </hlx-table-head>
            <hlx-table-head v-show="supplierStatus == false" :width="100">
              <span class="action-icon"
                ><i
                  class="icon-settings-regular"
                  @click="toggleRearrangeModal = true"
                    @new-resize-width="resizedWidth"
                ></i></span
            ></hlx-table-head>
          </template>
          <template #tbody>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell :colspan="tableHeads?.length + 2">{{
                "No data"
              }}</hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
          </template>
        </hlx-table>


    </section>



    
        <!-- image -->
        <section
         v-if="tableData && tableData?.length == 0 && searchvalue === ''"
        style="
          margin-top: 1.2rem;
          display: flex;
          flex-direction: column;
          gap: 1.2rem;
          align-items: center;
        "
      >

      <span>
          <img
            src="@/assets/images/svg/ExcludeRegion.svg"
            alt=""
            style="width: 500px; height: 500px"
          />
        </span>
        <span class="util-items util-items-add">
          <hlx-button
            class="primary media add-btn"
            @click="openDrawer"
            ><i class="icon-plus-circle-regular" style="margin-right: 5px"></i
            >Exclude region</hlx-button
          >
        </span>
        
    
    </section>







</template>

<script>
import {
//   DMNAxiosInstance,
  // MLTAxiosInstance,
  UPCAxiosInstance,
  // UPCAxiosInstance
} from "@/config/axiosConfig";

import addressComponent from "@/components/Product/ServiceQualification/dynamicAddress.vue"
export default {
    components: {
    
        addressComponent,
},


  props: {
    

    parentinfo: {
        type: Object,
        default: null
          },

  },


    data() {
      return {

        addressKey: 1,
        resonKey: 1,

        addressPreVal:{
          mediumType: 'postalAddress',
          characteristic: {
          city: '',
          unit: '',
          country: '',
          state: '',
          postalCode: '',
          stateOrProvince: '',
          street1: '',
          street2: '',
          description: '',
          street: '',
              default: true,
              postalCodePrefix:'',
            '@type': '',
            lat: null,
            lng:null,
          }
        },

        delete_modal_active: false,
        deleteobj:{},

        reasonList: [
          {value:'Address is outside the serviceable area'},
          { value: 'Infrastructure upgrade required' },
          { value: 'Underground fiber installation required' },
          { value: 'Fiber installation requires additional permits' },
          { value: 'Temporarily unavailable due to maintenance' },
          { value: 'No permission to lay fiber cables in the area' },
        ],

        validationEng:"",
        validationFre:"",


            
      tableData:[],


            tableHeads: [
        {
          sortable: false,
          resizable: true,
          label: "Exclude type",
          prop: "Exclude type",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          label: "Location",
          prop: "Location",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          label: "Reason",
          prop: "Reason",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          label: "Created date",
          prop: "Created date",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          label: "Last updated",
          prop: "Last updated",
          align: "left",
        },
      ],



            notification:"",




            showDrawer: false,

        editMode: false,
        editPayload: null,


            dropdown_data_type: [
        { value: "Full address" },
        { value: "Partial address" },
        { value: "Street" },
        { value: "City" },
        { value: "Province" },
        { value: "Postal code" },
        { value: "Prefix (FSA)" }
        ],


        criteria:'',



        dropdown_data_criteria: [
        { value: "Street name + Prefix" },
        { value: 'Street name + Postal code' },
        { value: "City + Postal code" },
        { value: "City + Prefix" },
        // { value: "Postal code" },
        // { value: "Partial address" },
        // { value: "Prefix (FSA)" }
            ],



            
            filteredstatus: [],


            type: '',

            reason: '',

            payload: {},


            componentkey: 0,

            
            filtertaglength: 0,

            
      filterQueryforBill: {
        type: "filter",
        module: "address",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
          filterQuery:          {
    "streetAddressLine.characteristic.category": {
        "$in": [
            "child"
        ]
    }
},
 
        searchQuery: "",
      },

            
      currPage: 1,
      rowsPerPage: 10,
            externalRowsPerPage: 10,

            searchvalue:"",



            enableAdd:false,

            
        }
  },


    
  computed: {
    tableDataOnSelectedRows(){
      // const start = (this.currPage - 1) * this.rowsPerPage;
      // const end = start + this.rowsPerPage;
      return this.tableData;
    }
  },
  watch: {

    parentinfo: {
      handler(val) {

        console.log("dropdown_data_type: {:::", this.dropdown_data_type, this.parentinfo);


        if (val && this.parentinfo && this.parentinfo.type) {

          // let vals = this.parentinfo.type.toLowerCase();



          switch (this.parentinfo.type) {
            case "province":
              this.dropdown_data_type = [
                { value: "Full address" },
                { value: "Partial address" },
                // { value: "Street" },
                { value: "City" },
                { value: "Postal code" },
                { value: "Prefix (FSA)" },
              ];
              break;
            case "city":
              this.dropdown_data_type = [
                // { value: "Street" },
                { value: "Partial address" },
                // { value: "Province" },
                { value: "Postal code" },
                { value: "Prefix (FSA)" },
                { value: "Full address" },
              ];
              break;
            // case "street":
            //   this.dropdown_data_type = [
            //     { value: "Postal code" },
            //     { value: "Prefix (FSA)" },
            //   ];
            //   break;
            // case "prefix (fsa)":
            //   this.dropdown_data_type = [
            //     { value: "Postal code" },
            //     { value: "Prefix (FSA)" },
            //   ];
            //   break;
            case "postal code":
              this.dropdown_data_type = [
              { value: "Full address" },
              ];
              break;
            // case "country":
            //   this.dropdown_data_type = [
            //     { value: "Full address" },
            //     { value: "Partial address" },
            //     { value: "Street" },
            //     { value: "City" },
            //     { value: "Postal code" },
            //     { value: "Prefix (FSA)" },
            //     { value: "Province" },
            //   ];
            //   break;
            // Add cases for other types as needed
            // default:
            //     this.dropdown_data_type = this.dropdown_data_type;
          }


        }
      },
      immediate: true,
      deep: true,
    },



    type: {
      async handler(val) {
        val
        if (this.type && this.validationEng && this.validationFre) {
          this.enableAdd = true;
        } else {
          this.enableAdd = false;
        }



        
        // let fans = await this.fetchCoordinatesPlugin('texas')
        // console.log("fans::123",fans);
      },
      immediate: true,
      deep: true
    },
    validationEng: {
      handler(val) {
        val
        if (this.type && this.validationEng && this.validationFre) {
          this.enableAdd = true;
        } else {
          this.enableAdd = false;
        }
      },
      immediate: true,
      deep: true
    },
    validationFre: {
      handler(val) {
        val
        if (this.type && this.validationEng && this.validationFre) {
          this.enableAdd = true;
        } else {
          this.enableAdd = false;
        }
      },
      immediate: true,
      deep: true
    },
  
  

  },
  


  


    async mounted() { 



        
    let status = await this.getReferanceData('statuslist');
      console.log("status::", status);
      this.filteredstatus = status.filter((item) =>
        ["Active", "Inactive", "Pending"].includes(item.name)
        );




        
    this.ticketdataCalling(this.currPage, this.rowsPerPage);

    },





  methods: {

    loadEditPayload(payload) {
      payload;
      this.editpayload = payload;
      this.editMode = true;
      console.log("loadEditPayload))::", payload);
      payload;

      this.type = payload.streetAddressLine[0].characteristic.type
      this.reason = payload.streetAddressLine[0].characteristic.reason
      this.criteria = payload.streetAddressLine[0].characteristic.criteria
      this.validationEng = payload.streetAddressLine[0].characteristic.validation.en.label
      this.validationFre = payload.streetAddressLine[0].characteristic.validation.fr.label

      // let obj = {

      // }

      this.addressPreVal = {
  characteristic: {
    city: payload.streetAddressLine[0].characteristic.city || '',
    unit: payload.streetAddressLine[0].characteristic.unit || '',
    country: payload.streetAddressLine[0].characteristic.country || '',
    state: payload.streetAddressLine[0].characteristic.state || '',
    postalCode: payload.streetAddressLine[0].characteristic.postalCode || '',
    stateOrProvince: payload.streetAddressLine[0].characteristic.stateOrProvince || '',
    street1: payload.streetAddressLine[0].characteristic.street1 || '',
    street2: payload.streetAddressLine[0].characteristic.street2 || '',
    description: payload.streetAddressLine[0].characteristic.description || '',
    street: payload.streetAddressLine[0].characteristic.street || '',
    default: payload.streetAddressLine[0].characteristic.default || false,
    postalCodePrefix: payload.streetAddressLine[0].characteristic.postalCodePrefix || '',
    '@type': payload.streetAddressLine[0].characteristic['@type'] || '',
    lat: payload.streetAddressLine[0].characteristic.lat || null,
    lng: payload.streetAddressLine[0].characteristic.lng || null,
  }
};
      



      this.componentkey += 1;
      
      this.showDrawer = true;
     },




    async deleteExecRow() {




      const deleteresponse = await UPCAxiosInstance.delete(
          `/geographicAddress/${this.deleteobj.id}`
        );


      if (deleteresponse) {
        this.notification = {
            type: "success",
            message: `Excluded region deleted successfully!`,
          };
        this.ticketdataCalling(this.currPage, this.rowsPerPage);
        this.delete_modal_active = false;
        }


    },

    clearDrawer() { 

      this.type = ''
      this.reason = ''
      this.validationFre = ''
      this.validationEng = ''
      this.criteria = ''

      let addressPreee = {
        mediumType: 'postalAddress',
        characteristic: {
          city: '',
          unit: '',
          country: '',
          state: '',
          postalCode: '',
          stateOrProvince: '',
          street1: '',
          street2: '',
          description: '',
          street: '',
          default: true,
          postalCodePrefix: '',
          '@type': '',
          lat: null,
          lng:null,
        }
      }; 

      this.addressPreVal = addressPreee;
      this.addressKey += 1;
      this.resonKey += 1;
      this.editMode = false;


    },


    criteriaEmit(val) {
      console.log("criteriaEmit:::", val);
      this.criteria = val.value;

          this.reason = ''
          this.validationFre = ''
          this.validationEng = ''

      let addressPreee = {
        mediumType: 'postalAddress',
        characteristic: {
          city: '',
          unit: '',
          country: '',
          state: '',
          postalCode: '',
          stateOrProvince: '',
          street1: '',
          street2: '',
          description: '',
          street: '',
          default: true,
          postalCodePrefix: '',
          '@type': '',
          lat: null,
          lng:null,
        }
      }; 
      this.addressPreVal = addressPreee;
      this.addressKey += 1;
      this.resonKey += 1;


     },
      

    async reasonListEmit(val) {
      this.reason = val.value;
      console.log("reasonListEmit::", this.reason);
      this.validationEng = this.reason
      this.validationFre = await this.languageTransfer(this.reason, 'fr')
    },



        
    computedTableAction() {
      return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        }
      ];
        },


        



        crudContextMenu($event, i, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 102;

        this.containertop =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().top + 10;  
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },




    async closeCrudContextMenu($event, payload, i) {
      if ($event.toLowerCase() === "edit") {
        $event, payload, i;

        let editPayload = JSON.parse(JSON.stringify(this.tableData[i]));
        // this.editPayload;
        this.loadEditPayload(editPayload);

      // this.$router.push({
      //     name: "invoiceTemplate",
      //     query: { quote_id: payload?._id, mode: "edit" },
      //   });
      } else if ($event.toLowerCase() === "delete") {
        $event, payload, i;
        // this.deleteobj = payload;
        this.delete_modal_active = true;
        this.deleteobj.id = payload.id;
      }
    },


    removeFirstComma(input) {
      return input.replace(",", "");
    },


        serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
        },


        
        async searchKey(val) {
      console.log("searchKey",val);
      
      this.searchvalue = val;
      this.filterQueryforBill["company"] = this.$cookies.get("company");
      this.filterQueryforBill["type"] = "filter-search"
 || "search";
      this.filterQueryforBill["searchQuery"] = val;
      this.filterQueryforBill.searchList =
       [
      {
          "streetAddressLine.characteristic.type": {
            $regex: val,
            $options: "i",
          },
          },
        
      {
          "streetAddressLine.characteristic.description": {
            $regex: val,
            $options: "i",
          },
        },
    //     // {
    //     //   "state": {
    //     //     $regex: val.toLowerCase() === "on hold" ? "onhold" : val,
    //     //     $options: "i",
    //     //   },
    //     // },
    //     {
    //       "definition.name": {
    //         $regex: val,
    //         $options: "i",
    //       },
    //     },
    //     {
    //       "tag": {
    //         $regex: val,
    //         $options: "i",
    //       },
    //     },
      ];
      // this.currPage = 1;
      // this.rowsPerPage = 10;
      if (this.filtertagvalue?.length != 0) {
        this.filterQueryforBill["type"] = "filter-search";
      }

      this.ticketdataCalling(this.currPage, this.rowsPerPage);
        },


        


        async ticketdataCalling(currentPage, rowsPerPage) {
  currentPage, rowsPerPage
  console.log("ticketdataCalling...123",currentPage, rowsPerPage);
      this.filterQueryforBill["company"] = 'developerHalleyx';
        // this.$cookies.get('company');
      this.filterQueryforBill["paginatedQuery"]["currentPage"] = currentPage;
      this.filterQueryforBill["paginatedQuery"]["rowsPerPage"] = rowsPerPage;


      this.filterQueryforBill.filterQuery = {
    "streetAddressLine.characteristic.category": {
        "$in": [
            "child"
        ]
    },


          "streetAddressLine.characteristic.parentId": {
        "$in": [
            `${this.$route.query.id}`
        ]
    }
            }





      this.filterQueryforBill.module = 'address';
      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.filterQueryforBill
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data?.total;
        console.log("ticketdataCalling...",this.tableData ,filterResultData.data.data);
        
        // this.totalvalue = filterResultData.data.total;      
      }
    },



        
    async currentPage(val) {
      if (this.companyasignee != "") {
        this.currPage = val;
        this.ticketdataCalling(
          this.currPage,
        this.rowsPerPage
        );
        this.refreshTable();
      }
    },

    async quotesfilterData(filterSelectedvalue, filterQuery) {
      console.log("quotesfilterData::))", filterSelectedvalue, filterQuery);
      this.filtertaglength = filterSelectedvalue?.length;
      this.filterQueryforBill = filterQuery;

      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.filterQueryforBill
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data?.total;
        console.log("ticketdataCalling...",this.tableData ,filterResultData.data.data);
        
        // this.totalvalue = filterResultData.data.total;      
      }
      
        },







        
        openDrawer() {
            this.showDrawer = true;
            this.componentkey += 1;
        },



        closeDrawer() { 
            this.showDrawer = false;
          this.componentkey += 1;
          this.clearDrawer();

        },

        emitExcludeType(val) {
            this.type = val.value;

          this.reason = ''
          this.validationFre = ''
          this.validationEng = ''


            let addressPreee = {
        mediumType: 'postalAddress',
        characteristic: {
          city: '',
          unit: '',
          country: '',
          state: '',
          postalCode: '',
          stateOrProvince: '',
          street1: '',
          street2: '',
          description: '',
          street: '',
          default: true,
          postalCodePrefix: '',
          '@type': '',
          lat:null,
          lng:null,
        }
      }; 
      this.addressPreVal = addressPreee;
      this.addressKey += 1;
      this.resonKey += 1;


        },



        async addExcluded() { 

            let obj = this.payload;
            console.log(obj, "ooooobb");
            

            obj.type = this.type
            obj.reason = this.reason
            obj.parentId = this.$route.query.id
            // obj.updatedat = `${this.formatCurrentTime()}`
            // obj.createdat = `${this.formatCurrentTime()}`
          obj.category = 'child';
          obj.validation = {
            en: { label: this.validationEng },
            fr: { label: this.validationFre }
          };
          obj.criteria = this.criteria;





            let obj1 = {
                streetAddressLine: [
          {
            mediumType: "geographicAddress",
            characteristic: obj,
          },
        ],                
            }


          
          if (this.editMode == false) {
            obj1.streetAddressLine[0].characteristic.updatedat = `${this.formatCurrentTime()}`
            obj1.streetAddressLine[0].characteristic.createdat = `${this.formatCurrentTime()}`
            let postresponse = await UPCAxiosInstance.post(
          `/geographicAddress`,
          obj1
            );  

            if (postresponse) {
              this.notification = {
                    type: "success",
                    message: `Excluded region added successfully!`,
            };  
            }
            
                
          } 
          // edit mode
          else {
            obj1.streetAddressLine[0].characteristic.updatedat = `${this.formatCurrentTime()}`

            let patchresponse = await UPCAxiosInstance.patch(
            `/geographicAddress`,
            {
              id: this.editpayload.id,
              ...obj1,
            }
            );  
            if (patchresponse) {
              this.notification = {
                    type: "success",
                    message: `Excluded region details updated successfully!`,
            };  
            }
            }
            

                this.clearDrawer();
                this.showDrawer = false;
                this.componentkey += 1;

                this.ticketdataCalling(this.currPage, this.rowsPerPage);





        },



        receivedAddressData(val) {
            console.log("receivedAddressData:::regg::", val);



            let obj = {
        city: val.characteristic.city,
          unit: val.characteristic.unit,
            country: val.characteristic.country,
              state: val.characteristic.state,
                postalCode: val.characteristic.postalCode,
                  stateOrProvince: val.characteristic.stateOrProvince,
                    street1: val.characteristic.street1,
                      street2: val.characteristic.street2,
                        description: val.characteristic.description,
                          street: val.characteristic.street,
              default: val.characteristic.default,
    postalCodePrefix: val.characteristic.postalCodePrefix,
              lat: val.characteristic.lat,
              lng: val.characteristic.lng,
    '@type': val.characteristic['@type'],
            }

            this.payload = obj;



            
        },



        formatDate(isoDate) {
    const date = new Date(isoDate);
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
},
    }

}
</script>

<style>
.flex_gap_box_location{
  display: flex;
  column-gap: 7px;
  margin: 7px;
}

</style>