<template>
  <div class="complete-account">
    <section class="right-container">
      <p class="container-header"><b>Complete account setup</b></p>
      <div class="body-content">
        <section class="timeline">
          <hlx-timeline
            :timeline-data="example_data"
            :view="'vertical'"
            :type="'numbered'"
          />
        </section>
        <section class="form-container">
          <div class="form-input-wrapper">
            <p class="input-header">Create password</p>
            <div class="form-input">
              <hlx-input
                :label-animation="false"
                type="password"
                :password-condition="false"
                label-value="Password"
                :clearable="false"
              />
            </div>
          </div>
          <div class="form-input-wrapper">
            <p class="input-header">Confirm password</p>
            <div class="form-input">
              <hlx-input
                v-model:value="password"
                :label-animation="false"
                type="confirmpassword"
                :password-condition="true"
                label-value="Confirm password"
                :clearable="false"
              />
            </div>
          </div>
          <div class="form-input-wrapper">
            <p class="input-header">Organisation name</p>
            <div class="form-input">
              <hlx-input
                :label-animation="false"
                type="text"
                :clearable="false"
                :pre-val="company"
              />
            </div>
          </div>
          <div class="form-input-wrapper">
            <p class="input-header">Choose a sub-domain for your business</p>
            <div class="form-input">
              <hlx-input
                :label-animation="false"
                label-value="Multi"
                type="mixed"
                :pre-val="company"
                :clearable="false"
                :disabled="true"
                name="username"
                :suffix="true"
              >
                <template #prefix> www. </template>
                <template #suffix> .halleyx.com </template>
              </hlx-input>
            </div>
          </div>
        </section>
      </div>
      <div class="footer-container">
        <hlx-button
          class="primary sm"
          @click="updateUser()"
          >Next</hlx-button
        >
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AccountSetup',
  data() {
    return {
      token: this.$route.params.token,
      fet: process.env.VUE_APP_HOST_PROTOCOL + '://' + process.env.VUE_APP_HOST,
      userId: '',
      company: this.$cookies.get("company"),
      password: '',
      example_data: [
        {
          title: '',
          description: '',
          subtitle: 'Complete account setup',
          active: true,
          visited: true,
        },
        {
          title: '',
          description: '',
          subtitle: 'Choose a plan',
          active: false,
          visited: false,
        },
        {
          title: '',
          description: '',
          subtitle: 'Payment information',
          active: false,
          visited: false,
        },
      ],
    };
  },
  mounted() {
    let token = {
      headers: {
        Authorization: 'Bearer  ' + this.token,
      },
    };
    axios
      .get(this.fet + `/partyManagement/individual/auth`, token)
      .then((data) => {
        (this.company = data.data.company), (this.userId = data.data.userId);
        
      });
  },
  methods: {
    async updateUser() {
      let tokens = {
        headers: {
          Authorization: 'Bearer  ' + this.token,
        },
      };
      let update = {
        id: this.userId,
        password: this.password,
        status: 'Active',
      };
      // const updated = 
      await axios.patch(
        this.fet + `/partyManagement/individual/${this.company}`,
        update,
        tokens
      );
      
      this.$router.push(`/planView`);
    },
  },
};
</script>

<style></style>
