<template>
  <div class="notification-top-bar">
    <p>
      Impersonation mode on
      <small><a @click="$emit('exit')">Exit view</a></small>
    </p>
  </div>
</template>

<script>
export default {
  emits: ['exit'],
};
</script>

<style></style>
