<template>
  <div
    style="
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
      width: 100%;
      grid-gap: 2rem;
    "
  >
    <span
      v-for="doc in data"
      :key="doc"
      style="
        display: flex;
        justify-content: space-between;
        gap: 10px;
        border: 1px solid #d8d8d8;
        border-radius: 5px;
        padding: 10px;
        align-items: center;
      "
      ><span style="display: flex; align-items: center; gap: 10px">
        <span
          ><div style="display: flex; gap: 8px; align-items: center">
            <span v-if="getFileExtension(doc.name) === 'txt'"
              ><img
                class="file-icon"
                src="@/assets/svg/multiUpload/text.svg"
                alt=""
            /></span>
            <span v-if="getFileExtension(doc.name) === 'docx'"
              ><img
                class="file-icon"
                src="@/assets/svg/multiUpload/word.svg"
                alt=""
            /></span>
            <span v-if="getFileExtension(doc.name) === 'png'"
              ><img
                class="file-icon"
                src="@/assets/svg/multiUpload/png.svg"
                alt=""
            /></span>
            <span v-if="getFileExtension(doc.name) === 'pdf'"
              ><img
                class="file-icon"
                src="@/assets/svg/multiUpload/pdf.svg"
                alt=""
            /></span>
            <span v-if="getFileExtension(doc.name) === 'json'"
              ><img
                class="file-icon"
                src="@/assets/svg/multiUpload/json.svg"
                alt=""
            /></span>
            <span v-if="getFileExtension(doc.name) === 'jpg'"
              ><img
                class="file-icon"
                src="@/assets/svg/multiUpload/jpg.svg"
                alt=""
            /></span>
            <span v-if="getFileExtension(doc.name) === 'jpeg'"
              ><img
                class="file-icon"
                src="@/assets/svg/multiUpload/jpeg.svg"
                alt=""
            /></span>
            <span v-if="getFileExtension(doc.name) === 'xlsx'"
              ><img
                class="file-icon"
                src="@/assets/svg/multiUpload/excel.svg"
                alt=""
            /></span>
          </div>
        </span>
        <span style="display: flex; flex-direction: column">
          <span
            style="
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 150px;
            "
            >{{ doc.name }}</span
          >
          <span>{{ formatFileSize(doc.size) }}</span>
        </span>
      </span>
      <span>
        <i
          class="icon-download-regular"
          @click="download(doc.url, doc.name)"
        ></i>
      </span>
    </span>
  </div>
</template>

<script>
import { UPCAxiosInstance } from '@/config/axiosConfig';
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    formatFileSize(bytes) {
      console.log(bytes);
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    },
    getFileExtension(filename) {
      const parts = filename.split('.');
      const fileExtension = parts[parts.length - 1];
      return fileExtension;
    },
    async download(url, name) {
      const url1 = await UPCAxiosInstance.get(`/gcpupload/files/${name}`, {
        responseType: 'arraybuffer',
      });
      url1;
      // console.log(ele.name, url.data[0]);

      this.downloadFromGCP(url1.data, name);
    },
    async downloadFromGCP(urlFetch, name) {
      console.log(urlFetch, name);
      const type = this.getFileTypeFromMimeType(name);
      const blob = new Blob([urlFetch], { type: type });
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element
      const a = document.createElement('a');
      a.href = url;
      a.download = name; // Set the filename

      // Append the anchor element to the DOM
      document.body.appendChild(a);

      // Trigger the download
      a.click();
      // const response = await fetch(urlFetch);
      // const fileData = await response.blob(); // Assuming the file is in binary format

      // const blob = new Blob([fileData]);

      // const url = URL.createObjectURL(blob);

      // const link = document.createElement("a");
      // link.href = url;
      // link.download = name;

      // document.body.appendChild(link);
      // link.click();

      // URL.revokeObjectURL(url);
      // document.body.removeChild(link);
    },
    getFileTypeFromMimeType(mimeType) {
      // Map MIME types to file types
      const mimeTypeMap = {
        'image/jpeg': 'JPEG',
        'image/png': 'PNG',
        'application/pdf': 'PDF',
        'text/plain': 'TXT',
        // Add more MIME types as needed
      };

      // Lookup the file type based on the MIME type
      const fileType = mimeTypeMap[mimeType] || 'Unknown';

      // Convert the file type back to MIME type format if found, otherwise return the original MIME type
      return fileType !== 'Unknown'
        ? `application/${fileType.toLowerCase()}`
        : mimeType;
    },
  },
};
</script>
