<template>
  <div class="signature-pad-whole-container">
    <span
      v-if="mode == 'approver'"
      style="
        font-size: 12pt;
        font-weight: bold;
        text-align: center;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
          sans-serif !important;
      "
      class="signature-pad-title-section"
    >
      Approver signature
    </span>
    <span
      v-if="mode == 'customer'"
      style="
        font-size: 12pt;
        font-weight: bold;
        text-align: center;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
          sans-serif !important;
      "
      class="signature-pad-title-section"
    >
      Client signature
    </span>
    <hlx-button
v-if="signatureData == null && editable == true"
      style="         font-size: 12pt;
       font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
          sans-serif !important;"
      class="text sm add-signature --bottom-only"
      @click="addSignature"
      >Sign here</hlx-button
    >
    <p
v-if="signatureData == null && editable == false"
      style="        font-size: 12pt;text-align:center;
font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
          sans-serif !important;"
      class="text sm add-signature --bottom-only defaultSign"
    >
      Sign here
    </p>
    <div
      v-if="signatureData"
      style="display: flex"
      class="already-signature-pad"
    >
      <span style="height: 50px; width: 50px">
        <img :src="signatureData" alt="Signature" />
      </span>
      <i
        v-if="
          managerValue.state != 'approved' && clientValue.state == 'pending'
        "
        class="icon-edit-regular"
        @click="addSignature"
      ></i>
      <i
        v-if="
          managerValue.state != 'approved' && clientValue.state == 'pending'
        "
        class="icon-trash-regular"
        @click="DeleteSignature"
      ></i>
      <i
        v-if="clientValue.state == 'approved' && clientValue.state == 'pending'"
        class="icon-edit-regular"
        @click="addSignature"
      ></i>
      <i
        v-if="clientValue.state == 'approved' && clientValue.state == 'pending'"
        class="icon-trash-regular"
        @click="DeleteSignature"
      ></i>
    </div>
    <section
      v-if="sender != ''"
      class="signature-name-section"
      style="
        font-size: 12pt;
        padding: 0.8rem;
        text-align: center;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
          sans-serif !important;
      "
    >
      {{ sender }}
    </section>
    <section
      v-if="recipient != ''"
      class="signature-name-section"
      style="
        font-size: 12pt;
        padding: 0.8rem;
        text-align: center;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
          sans-serif !important;
      "
    >
      {{ recipient }}
    </section>
    <section class="signature-date-section">
      <!-- <hlx-input
      v-model:value="dateValue" 
      :pre-val="dateValue"
      :single-border="true"
      :label-animation="false"
      :clearable="false"
      type="date"                   
      icon="calendar-regular"
      icon-position="right"></hlx-input> -->
    </section>
  </div>
  <hlx-modal
    :modal-active="modal_active"
    :height="'450px'"
    :width="'650px'"
    :close-icon="true"
    :outer-layer-close-option="true"
    :header-content="true"
    @close="modal_active = false"
  >
    <template #header>
      <h4>Signature pad</h4>
    </template>
    <template #content>
      <div class="signature-pad">
        <div class="signature-pad-container">
          <canvas
            :id="'signatureCanvas' + unique"
            width="629"
            height="300"
            @mousedown="startDrawing"
            @mouseup="stopDrawing"
            @mousemove="draw"
          ></canvas>
        </div>
        <div class="signature-pad-upload-button">
          <p class="clear-button" @click="clearSignature">Clear</p>
          <div>
            <hlx-button class="secondary sm Cancel" @click="closeModal"
              >Cancel</hlx-button
            >
            <hlx-button class="primary sm Upload" @click="getSignature"
              >Sign</hlx-button
            >
          </div>
        </div>
      </div>
    </template>
  </hlx-modal>
</template>

<script>
export default {
  props: {
    editable: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: "approver",
    },
    managerpreValue: {
      type: Object,
      default: () => {
        return {};
      },
    },
    clientpreValue: {
      type: Object,
      default: () => {
        return {};
      },
    },
    sender: {
      type: String,
      default: "",
    },
    recipient: {
      type: String,
      default: "",
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["signature-data"],
  data() {
    return {
      signatureData: null,
      isDrawing: false,
      ctx: null,
      modal_active: false,
      add: false,
      unique: Math.floor(Math.random() * 1000 + 1),
      managerValue: {},
      clientValue: {},
      dateValue: new Date().toJSON(),
      duplicateSignatureData: null,
    };
  },
  watch: {
    managerpreValue: {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          console.log("🚀  ~ handler ~ val man:", val);
          this.managerValue = JSON.parse(JSON.stringify(val));
          this.dateValue = val.givenDate;
          if (val.signatureRepresentation != "") {
            this.signatureData = val.signatureRepresentation;
          } else {
            this.signatureData = null;
          }
        }
      },
      immediate: true,
      deep: true,
    },
    clientpreValue: {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          this.clientValue = val;
          this.clientValue = JSON.parse(JSON.stringify(val));
          if (val.givenDate && val.givenDate.trim()) {
            this.dateValue = val.givenDate;
          } else {
            this.dateValue = new Date().toJSON();
          }
          console.log("🚀 ~ handler ~ val client:", val);
          console.log();
          if (val.signatureRepresentation != "") {
            this.signatureData = val.signatureRepresentation;
          } else {
            this.signatureData = null;
          }
        }
      },
      immediate: true,
      deep: true,
    },
    dateValue: {
      handler(val) {
        if (val && val != "") {
          this.dateValue = val;
        } else if (val == "") {
          this.dateValue = new Date().toJSON();
        }
      },
    },
  },
  mounted() {},
  methods: {
    addSignature() {
      console.log(
        this.managerValue.state == "approved" &&
          this.clientValue.state == "pending",
        "data",
        this.managerValue.state,
        this.clientValue.state
      );
      if (this.managerValue.name == "Quote manager approval") {
        this.modal_active = true;
      }
      if (this.clientValue.state == "pending") {
        this.modal_active = true;
      }
    },
    closeModal() {
      console.log("sllslssl", this.signatureData, this.duplicateSignatureData);
      this.modal_active = false;
      // this.clearSignature()
      this.duplicateSignatureData = JSON.parse(
        JSON.stringify(this.signatureData)
      );
    },
    startDrawing(event) {
      this.isDrawing = true;
      const canvas = document.getElementById("signatureCanvas" + this.unique);
      this.ctx = canvas.getContext("2d");
      const x = event.clientX - canvas.getBoundingClientRect().left;
      const y = event.clientY - canvas.getBoundingClientRect().top;
      this.ctx.beginPath();
      this.ctx.moveTo(x, y);
    },
    stopDrawing() {
      this.isDrawing = false;
      this.ctx.closePath();
    },
    draw(event) {
      if (!this.isDrawing) return;
      const canvas = document.getElementById("signatureCanvas" + this.unique);
      const x = event.clientX - canvas.getBoundingClientRect().left;
      const y = event.clientY - canvas.getBoundingClientRect().top;
      this.ctx.lineTo(x, y);
      this.ctx.stroke();
    },
    clearSignature() {
      const canvas = document.getElementById("signatureCanvas" + this.unique);
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);
      this.duplicateSignatureData = null;
    },
    DeleteSignature() {
      const canvas = document.getElementById("signatureCanvas" + this.unique);
      this.ctx.clearRect(0, 0, canvas.width, canvas.height);
      this.duplicateSignatureData = null;
      this.signatureData = null;
    },
    getSignature() {
      const canvas = document.getElementById("signatureCanvas" + this.unique);
      const dataURL = canvas.toDataURL();
      this.duplicateSignatureData = JSON.parse(JSON.stringify(dataURL));
      this.signatureData = JSON.parse(JSON.stringify(dataURL));
      this.$emit("signature-data", this.signatureData);
      console.log(this.signatureData, "data");
      this.modal_active = false;
    },
    dateFunc() {
      let value = "";
      if (this.managerApproval.givenDate != "") {
        value = this.managerApproval.givenDate;
      } else {
        value = new Date().toJSON();
      }
      if (this.clientApproval.givenDate != "") {
        value = this.managerApproval.givenDate;
      } else {
        value = new Date().toJSON();
      }
      return value;
    },
  },
};
</script>

<style lang="scss">
/* Add your custom styles here */
</style>
