<!-- eslint-disable vue/no-template-shadow -->
<template>
<div class="quote-workflow-sidebar">
        
    <div style="overflow-y: auto;">
        <hlx-timeline
          v-for="(i, index) in options"
          :key="index"
          :timeline-data="example_data1"
          :view="'vertical'"
          :type="'slot'"
          :options="options"
          :curr-index="index"
          :total-length="options.length"
          :data-length="options.length"
          @accordion-open="open(index)"
          @accordion-close="close(index)"
        >
        <template #icon>
  <section :style="{ backgroundColor: i?.color }" style="width: 15px; height: 15px;    border-radius: 50%;"></section>
</template>
          <template #first-slot>
            <div style="font-weight: bolder;">
              {{ i?.name }}
          </div>
          <div
style="
font-size: 12px;
margin-top: 6px;
font-weight: 400;
color:#7A7A7A;">
            {{ i?.description}}

          </div>
          </template>
          <!-- <template #title>
            {{ i?.description}}
          </template> -->
          <template #footer>
            <div v-if="i?.filesArray?.length > 0" class="container-box">
              <div class="uplod_inside_email">
                <div :id="'wrapper'+index" ref="containerBox" class="content-wrapper ">
  
                  <div
            v-for="(i, index) in i?.filesArray"
            :key="index"
            class="outerMainContainer"
          >
            <div class="main1">
              <img
                style="height: 40px; width: 40px; object-fit: contain"
                src="@/assets/images/Placeholder image.svg"
                alt="product image preview"
              />
            </div>
            <div class="main2">
              <div class="div1">
                <span class="span1" style="font-size: 14px;">{{ i.name }}</span>
                <span class="span2"
                  ><hlx-label class="primary sm">{{ i.role }}</hlx-label></span
                >
              </div>
              <div class="div2" style="font-size: 10px;">{{ i.mail }}</div>
            </div>
            <div style="width: 20px;" @click="closeTheBox(i)">
              <span  v-if="i?.status.toLowerCase() === 'approved'"  data-tooltip-location="left"  :data-tooltip="'Approved'">
                <i class="icon-check-circle-filled" style="color:#54BD95; font-size: 18px;"></i>
              </span>
              <span v-else-if="i?.status.toLowerCase() === 'pending'"  data-tooltip-location="left" :data-tooltip="'Pending'">
                <i class="icon-check-circle-filled" style="color:#d4d4d4; font-size: 18px;" ></i>
              </span>
              <span v-else-if="i?.status.toLowerCase() === 'rejected'"  data-tooltip-location="left"  :data-tooltip="'Rejected'">
                <i class="icon-times-circle-filled" style="color:#ff5050; font-size: 18px;" ></i>
              </span>
            </div>
          </div>
                  </div>
                <p 
                  v-if="i['overflowCount'] > 0"
                  :id="'overflow-count' + index"
                  class="overflow-count"
                  @click="overflowCountHidden(index)"
                >
                  + {{ i["overflowCount"] }}
                </p>
              </div>
            </div>
          </template>
        </hlx-timeline>
      </div>
          </div>

</template>

<script>
export default {
data(){
return{
    showQuoteWorkFlow:false,
    // workflowHeader:[
    //     {name:"Draft",color:"#0065B2"},
    //     {name:"Awaiting approval",color:"#897E06"},
    //     {name:"Approved",color:"#F9A645"},
    //     {name:"Sent",color:"#FEDC2A"},
    //     {name:"Viewed",color:"#9A44A9"},
    //     {name:"Completed",color:"#47B972"},
    //     {name:"Expired",color:"#FA9886"},
    //     {name:"Declined",color:"#FC3232"},
    // ],
    options: [
        {
          name:"Draft",color:"#0065B2",description:"Document has been created",
        },
        {
          name:"Awaiting approval",color:"#897E06",description:"Has not yet been approved."
        },
        {
          name:"Approved",color:"#F9A645",description:"Require document's approval before it can be sent to recipients",
          filesArray: [
            {  name:"John doe",role:"Admin", mail:"abc@gmail.com",status:"Approved" },
            {  name:"John",role:"Admin", mail:"abc@gmail.com" ,status:"Approved"},
            {  name:"John doe",role:"Admin", mail:"abc@gmail.com",status:"Pending" },
            {  name:"John",role:"Admin", mail:"abc@gmail.com" ,status:"Approved"},
            {  name:"John doe",role:"Admin", mail:"abc@gmail.com",status: "Rejected"},
            {  name:"John",role:"Admin", mail:"abc@gmail.com" ,status:"Rejected"},
            {  name:"John doe",role:"Admin", mail:"abc@gmail.com",status: "Approved"},
            {  name:"John",role:"Admin", mail:"abc@gmail.com" ,status:"Pending"},
            {  name:"John doe",role:"Admin", mail:"abc@gmail.com",status: "Rejected"},
            {  name:"John",role:"Admin", mail:"abc@gmail.com" ,status:"Pending"},
            {  name:"John doe",role:"Admin", mail:"abc@gmail.com",status: "Approved"},
            {  name:"John",role:"Admin", mail:"abc@gmail.com" ,status:"Approved"},

            // {  name:"John David",role:"Admin", mail:"abc@gmail.com" },
            // {  name:"John Wick",role:"Admin", mail:"abc@gmail.com" },
            // {  name:"John doe",role:"Admin", mail:"abc@gmail.com" },
          ],
        },
        {
          name:"Sent",color:"#FEDC2A",description:"The document has been sent to recipients",
        },
        // {
        //   name:"Viewed",color:"#9A44A9",description:"Recipient has viewed the document"
        // },
        {
          name:"Compeleted",color:"#47B972",description:"The document has been accepted or signed by all recipients"
        },
        {
           name:"Expired",color:"#FA9886",description:"No longer valid and doesn’t need a signature certificate"
        },
        {
          name:"Declined",color:"#FC3232",description:"Client didn’t sign the proposal and the deal is lost."
        },
      ],
}
},
    mounted(){
        this.showQuoteWorkFlow=true;
        console.log("hi iam from mounted")
    },
    methods:{
      closeTheBox(val){
        console.log("closeTheBox",val)
      }
    }
}
</script>

<style lang="scss">
.quote-workflow-sidebar{
 .timeline-parent-container .timeline-single-container-content-side-second-slot-with-accordion{
  display: none;
 } 
 .timeline-parent-container .timeline-single-container-icon-side {
  width: 20px;
 }
 .timeline-parent-container .timeline-single-container-icon-side-icon-container {
    height: 10px;
    position: absolute;
    left: 22px;
 }
 .timeline-parent-container .timeline-single-container-content-side-first-slot {
margin-left: 18px;
 }
 .timeline-parent-container .timeline-single-container-icon-side-vertical-line-container{
  min-height: 84px;
  height: 100%;
 }
 .container-box{
  border: none;
  padding-left: 12px;
    padding-top: 6px;
 }
 .content-wrapper, .uplod_inside_email{
  flex-direction: column;
  max-height: fit-content;
 }
 .timeline-parent-container .timeline-single-container-content-side {
  width:100%
 }
 .outerMainContainer {
  width:100%;
 }
}
</style>