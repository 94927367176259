<template>
    <div>
      <hlx-input
        v-model:value="payload.characteristic.street"
        :read-only="view"
        :pre-val="payload.characteristic.street"
        type="location"
        :label-value="'Street'"
        :label-animation="true"
        :display-error="true"
        :custom-error-message="'Please fill the field'"
        :location-data-prop="locationData1"
        @address-data="addressData"
        @at-input="getLocations(payload.characteristic.street)"
      ></hlx-input>
      <hlx-input
        v-model:value="payload.characteristic.unit"
        :read-only="view"
        :pre-val="payload.characteristic.unit"
        type="text"
        :label-value="'Unit/Appartment'"
        :label-animation="true"
        :display-error="true"
        :custom-error-message="'Please fill the field'"
        @at-input="canEmitTrigger"
      ></hlx-input>
      <div
        class="new-address-fields"
        style="display: flex; justify-content: space-between; gap: 12px; margin-bottom: 20px"
      >
      <span style="width: 50%;">
        <hlx-input
          v-model:value="payload.characteristic.city"
          :read-only="view"
          :pre-val="payload.characteristic.city"
          type="location"
          :label-value="'City'"
          :label-animation="true"
          :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="'Please fill the field'"
        :location-data-prop="locationData2"
        @address-data="addressData"
          @at-input="getLocations(payload.characteristic.city)"
        />
        <!-- @at-input="canEmitTrigger" -->
        </span>
        <span style="width: 50%;">
        <hlx-input
          v-model:value="payload.characteristic.state"
          :read-only="view"
          :pre-val="payload.characteristic.state"
          type="location"
          :label-value="'State'"
          :label-animation="true"
          :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="'Please fill the field'"
        :location-data-prop="locationData3"
        @address-data="addressData"
          @at-input="getLocations(payload.characteristic.state)"
          />
          <!-- @at-input="canEmitTrigger" -->
        </span>
      </div>
      <div
        class="new-address-fields"
        style="display: flex; justify-content: space-between; gap: 12px"
      >
      <span style="width: 50%;">
        <hlx-input
          v-model:value="payload.characteristic.country"
          :read-only="view"
          :pre-val="payload.characteristic.country"
          type="location"
          :label-value="'Country'"
          :label-animation="true"
          :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="'Please fill the field'"
        :location-data-prop="locationData4"
        @address-data="addressData"
          @at-input="getLocations(payload.characteristic.country)"
          />
          <!-- @at-input="canEmitTrigger" -->
        </span>
        <span style="width: 50%;">
        <hlx-input
          v-model:value="payload.characteristic.postalCode"
          :read-only="view"
          :pre-val="payload.characteristic.postalCode"
          :label-value="'Postal code'"
          :label-animation="true"
          :display-error="customnameerror"
          :custom-error="customnameerror"
          :custom-error-message="'Please fill the field'"
          @at-input="canEmitTrigger"
        />
        </span>
      </div>
      <!-- <input
        ref="streetField"
        placeholder="Address line 1"
      />
      <br />
      <input
        ref="address2Field"
        placeholder="Address line 2"
      />
      <input
        ref="unitField"
        placeholder="Unit number"
      />
      <input
        ref="cityField"
        placeholder="City"
      />
      <input
        ref="stateOrProvinceField"
        placeholder="State or Province"
      />
      <input
        ref="countryField"
        placeholder="Country"
      />
      <input
        ref="postalField"
        placeholder="Postal Code"
      /> -->
      <!-- Add other form fields as needed -->
    </div>
  </template>
  
  <script>
  import {
    // DMNAxiosInstance,
    // MLTAxiosInstance,
    UPCAxiosInstance,
  } from '@/config/axiosConfig';
  // import axios from 'axios';
  
// import { loadGoogleMapsApi } from '../ServiceQualification/laodApi.js';
  
  export default {
    props: {
      view: {
        type: Boolean,
        default: false
      },
      
      apis: {
        type: String,
      },
      preVal: {
        type: Object,
        default: () => {
          return {
            mediumType: 'postalAddress',
            characteristic:{
            city: '',
            unit: '',
            country: '',
            state: '',
            postalCode: '',
            stateOrProvince: '',
            street1: '',
            street2: '',
            description: '',
            street: '',
            default: true,
            '@type': '',
            }
          };
        },
      },
    },
    emits: ['addressData','geopoint','mapname','description'],
    data() {
      return {
        input:{},
        canEmit1: false,
        locationData: [],
        // added by ragavi
        addressSuggestion:[],
        payload: {
          mediumType: 'postalAddress',
          characteristic: {
          city: '',
          unit: '',
          country: '',
          state: '',
          postalCode: '',
          stateOrProvince: '',
          street1: '',
          street2: '',
          description: '',
          street: '',
          default: true,
          '@type': '',
          }
        },
        rawPayload: {}
      };
    },
    watch: {
      preVal: {
        handler(val) {
          this.rawPayload = JSON.parse(JSON.stringify(val));
          this.payload = JSON.parse(JSON.stringify(val));
          this.canEmit1 = false
          console.log('qawsdcfvgbhnj',val);
        },
        immediate: true,
        deep: true,
      },


      // city:{
      //   handler(val){
      //     console.log(val);
      //   },
      //   immediate: true,
      //   deep: true,
      // },


      payload: {
        handler(val) {console.log('assume',this.payload,val);


        // ifval?.characteristic?.city


          this.payload.characteristic.description =
            `${val.characteristic.street !== '' && val.characteristic.street !== undefined ? val.characteristic.street : ''}`+
            `${val.characteristic.unit !== '' && val.characteristic.unit !== undefined ? ', ' + val.characteristic.unit : ''}`+
            `${val.characteristic.city !== '' && val.characteristic.city !== undefined ? ', ' + val.characteristic.city: ''}`+
            `${val.characteristic.state !== '' && val.characteristic.state !== undefined ? ', ' + val.characteristic.state : ''}` +
           `${val.characteristic.country !== '' && val.characteristic.country !== undefined ? ', ' + val.characteristic.country : ''}` +
            `${val.characteristic.postalCode !== '' && val.characteristic.postalCode !== undefined ? ', ' + val.characteristic.postalCode : ''}`;
          if (this.canEmit1) {




            setTimeout(() => {
              this.$emit('addressData', val);
            }, 1500);





            
          }
        },
        immediate: true,
        deep: true,
      },
    },
    mounted() {
    console.log("started bro00123",this.apis);
    // loadGoogleMapsApi(this.apis)
    //   .then((googleMaps) => {
    //     this.initializeAutocomplete(googleMaps);
    //     console.log(this.apis,googleMaps,"working.....load");
    //   })
    //   .catch((error) => {
    //     console.error('Error loading Google Maps API:', error);
    //   });





  // this.$gmapApiPromiseLazy()
  //   .then(() => {
  //       console.log("running");
  //     if (window.google && window.google.maps && window.google.maps.places) {
  //       this.initializeAutocomplete();
  //       console.log("running");
  //     } else {
  //       console.log("Google Maps API not loaded properly.");
  //     }
  //   })
  //   .catch((error) => {
  //     console.error('Error loading Google Maps API:', error);
  //   });

        
    },
    methods: {
        
  // initializeAutocomplete() {
  //   console.log("3333333333333333333333333333333333333333333333333333333333333333333333333333");
  //   this.autocompleteService = new window.google.maps.places.AutocompleteService();
  //   console.log('this.autocompleteService', this.autocompleteService);

  //   this.autocompleteService.getPlacePredictions(
  //     { input: 'india' },
  //     (predictions, status) => {
  //       if (status === window.google.maps.places.PlacesServiceStatus.OK) {
  //         console.log("Autocomplete suggestions:", predictions,status,"-------------------------------------------------");
  //       } else {
  //         console.error("Autocomplete service status:", status);
  //       }
  //     }
  //   );
  // },

      canEmitTrigger() {
        this.canEmit1 = true
      },
      deepObjectCompare(obj1, obj2) {
        // Check if both inputs are objects
        if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
          return obj1 !== obj2;
        }
  
        // Check if both inputs are arrays
        if (Array.isArray(obj1) && Array.isArray(obj2)) {
          if (obj1.length !== obj2.length) {
            return true;
          }
  
          for (let i = 0; i < obj1.length; i++) {
            if (this.deepObjectCompare(obj1[i], obj2[i])) {
              return true;
            }
          }
  
          return false;
        }
  
        // Check if both inputs have the same keys
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
  
        if (keys1.length !== keys2.length) {
          return true;
        }
  
        // Check each key-value pair recursively
        for (const key of keys1) {
          if (
            !keys2.includes(key) ||
            this.deepObjectCompare(obj1[key], obj2[key])
          ) {
            return true;
          }
        }
  
        return false;
      },
      async getLocations(val) {
        this.canEmit1 = true

        
      this.autocompleteService = new window.google.maps.places.AutocompleteService();
      this.sessionToken = new window.google.maps.places.AutocompleteSessionToken();
      console.log("tokeeeeeeeeeeeeeen",this.autocompleteService,this.sessionToken);


        console.log("input",val);
        // const input ={}
        if(this.payload.characteristic.street === val){
          this.input = this.payload.characteristic.street;
        }else if(this.payload.characteristic.city === val){
          this.input = this.payload.characteristic.city;
        }else if(this.payload.characteristic.state === val){
          this.input = this.payload.characteristic.state;
        }else{
          this.input = this.payload.characteristic.country;
        }
        let result = [];
        try {
          // eslint-disable-next-line vue/no-async-in-computed-properties
  //         const response = await UPCAxiosInstance.get(
  // `/address/suggestionlist?input=${input}`
  //         );
  //         result = response.data;
  console.log(this.input);

  if(this.payload.characteristic.street === val){

    const response = await UPCAxiosInstance.get(
  `/address/suggestionlist?input=${this.input}`
          );

          result = response?.data;
          this.locationData1 = result;


  }



    //       if(this.autocompleteService){
    //         this.autocompleteService = new window.google.maps.places.AutocompleteService();
    // console.log('this.autocompleteService', this.autocompleteService);

    // this.autocompleteService.getPlacePredictions(
    //   { input: this.input },
    //   (predictions, status) => {
    //     if (status === window.google.maps.places.PlacesServiceStatus.OK) {
    //       console.log("Autocomplete suggestions:", predictions,status);
    //       this.locationData = predictions; 
    //     } else {
    //       console.log("Autocomplete service status:", status);
    //     }
    //   }
    // );
    //       }


    if (this.input.length > 0) {
        this.autocompleteService.getPlacePredictions(
          {
            input: this.input,
            sessionToken: this.sessionToken
          },
          (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              console.log("suggestion list:",predictions);
              // this.locationData = predictions; 
        //       const countryPredictions = predictions.filter(prediction => {
        //   return prediction.types.includes('country');
        // });

        //       if(this.payload.characteristic.street === val){
        //   // this.input = this.payload.characteristic.street;
        //   this.locationData1 = predictions;
        //   console.log("pendrive 111111111111");
        // }else 
        if(this.payload.characteristic.city === val){
          // this.input = this.payload.characteristic.city;

        //   this.locationData2 = predictions.filter(prediction => {
        //   return prediction.types.includes('city');
        // });console.log(this.locationData2);
        const r =[];
          predictions.forEach((item)=>{
            item?.types.forEach((itm)=>{
              
              if(itm.toLowerCase() === "locality"){
                r.push(item);
              }
              console.log("r",r);
            })
          })
          this.locationData2 = r;

          console.log("pendrive 22222222222");
        }else if(this.payload.characteristic.state === val){
          // this.input = this.payload.characteristic.state;
        //   this.locationData3 = predictions.filter(prediction => {
        //   return prediction.types.includes('state');
        // });console.log(this.locationData3);
        const r =[];
          predictions.forEach((item)=>{
            item?.types.forEach((itm)=>{
              
              if(itm.toLowerCase() === "administrative_area_level_1"){
                r.push(item);
              }
              console.log("r",r);
            })
          })
          this.locationData3 = r;

          console.log("pendrive 333333333333");
        }else if(this.payload.characteristic.country === val){
          // this.input = this.payload.characteristic.country;
          // this.locationData4 = predictions.filter(item =>{
          //   console.log('item',item?.types);
          //   // return 'item';
          const r =[];
          predictions.forEach((item)=>{
            item?.types.forEach((itm)=>{
              
              if(itm.toLowerCase() === "country"){
                r.push(item);
              }
              console.log("r",r);
            })
          })
          this.locationData4 = r;
          // });
          console.log(this.locationData4);

          console.log("pendrive 44444444444");
        }


              
            } else {
              console.log('No predictions available or status not OK', status);
            }
          }
        );
      } else {
        console.log('Input is empty');
      }












          //added by ragavi
          // this.addressSuggestion = result
          result = this.locationData;
          console.log(result);
        } catch (error) {
          console.error('Error fetching autocomplete data:', error.message);
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.locationData = result;
        return result;
      },
      async addressData(val) {
        this.canEmit1 = true
        console.log('val',val);
        let address_components = await UPCAxiosInstance.get(
          `/address/details?place_id=${val.place_id}`
        );
        this.$emit('geopoint',address_components.data.result.geometry.location.lat,address_components.data.result.geometry.location.lng);
        console.log(address_components,"emiting",address_components.data.result.name);
        this.$emit('mapname',address_components.data.result.name);
        this.$emit('description',address_components.data.result.formatted_address);

        
        
  
        console.log(address_components);
        let street = '';
        let postcode = '';
        let country = '';
        let unit = '';
        let city = '';
        let state_or_province = '';
        for (const component of address_components.data.result
          .address_components) {
          const componentType = component.types[0];
  
          switch (componentType) {
            case 'street_number': {
              street = `${component.long_name} ${street}`;
              break;
            }
            case 'route': {
              street += component.short_name;
              break;
            }
            case 'postal_code': {
              postcode = `${component.long_name}${postcode}`;
              break;
            }
            case 'postal_code_suffix': {
              postcode = `${postcode}-${component.long_name}`;
              break;
            }
            case 'postal_code_prefix': {
              postcode = `${component.long_name}${
                postcode !== '' ? '-' + postcode : ''
              }`;
              break;
            }
            case 'country': {
              country = `${component.long_name}`;
              break;
            }
            case 'administrative_area_level_1': {
              state_or_province = `${component.long_name}`;
              break;
            }
            case 'administrative_area_level_2': {
              city = `${component.long_name}`;
              break;
            }
            case 'administrative_area_level_3': {
              city = `${city !== '' ? city + ', ' : ''}${component.long_name}`;
              break;
            }
            case 'sublocality_level_1': {
              city = `${component.long_name}`;
              break;
            }
            case 'subpremise': {
              unit = `${component.long_name}`;
              break;
            }
            // Add more cases for other address components as needed
          }
        }
  
        this.payload.characteristic.street = street;
        this.payload.characteristic.postalCode = postcode;
        this.payload.characteristic.unit = unit;
        this.payload.characteristic.city = city;
        this.payload.characteristic.state = state_or_province;
        this.payload.characteristic.country = country;
      },
      initAutocomplete() {
        const streetField = this.$refs.streetField;
        const address2Field = this.$refs.address2Field;
        const cityField = this.$refs.cityField;
        const unitField = this.$refs.unitField;
        const stateOrProvinceField = this.$refs.stateOrProvinceField;
        const countryField = this.$refs.countryField;
        const postalField = this.$refs.postalField;
        address2Field;
        postalField;
        countryField;
        cityField;
        unitField;
        stateOrProvinceField;
        this.autocomplete = new window.google.maps.places.Autocomplete(
          streetField,
          {
            componentRestrictions: { country: ['us', 'ca', 'in'] },
            fields: ['address_components', 'geometry'],
            types: ['address'],
          }
        );
  
        streetField.focus();
  
        this.autocomplete.addListener('place_changed', this.fillInAddress);
      },
      fillInAddress() {
        const place = this.autocomplete.getPlace();
        let street = '';
        let postcode = '';
        let country = '';
        // let unit = '';
        let city = '';
        let state_or_province = '';
        console.log(place.address_components);
        for (const component of place.address_components) {
          const componentType = component.types[0];
  
          switch (componentType) {
            case 'street_number': {
              street = `${component.long_name} ${street}`;
              break;
            }
            case 'route': {
              street += component.short_name;
              break;
            }
            case 'postal_code': {
              postcode = `${component.long_name}${postcode}`;
              break;
            }
            case 'postal_code_suffix': {
              postcode = `${postcode}-${component.long_name}`;
              break;
            }
            case 'postal_code_prefix': {
              postcode = `${component.long_name}-${postcode}`;
              break;
            }
            case 'country': {
              country = `${component.long_name}`;
              break;
            }
            case 'administrative_area_level_1': {
              state_or_province = `${component.long_name}`;
              break;
            }
            case 'administrative_area_level_2': {
              city = `${component.long_name}`;
              break;
            }
            case 'administrative_area_level_3': {
              city = `${city !== '' ? city + ', ' : ''}${component.long_name}`;
              break;
            }
            case 'sublocality_level_1': {
              city = `${component.long_name}`;
              break;
            }
            // case 'subpremise': {
            //   unit = `${component.long_name}`;
            //   break;
            // }
            // Add more cases for other address components as needed
          }
        }
  
        this.payload.characteristic.description = street;
        this.payload.characteristic.postalCode = postcode;
        // this.$refs.unitField.value = unit;
        this.payload.characteristic.city = city;
        this.payload.characteristic.state = state_or_province;
        this.payload.characteristic.country = country;
        // this.$refs.address2Field.focus();
      },
    },
  };
  </script>
  