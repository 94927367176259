<!-- new change -->
<template>
  <div
    v-if="
      filterpanelname == 'productOffering' ||
      filterpanelname == 'productSpecification' ||
      filterpanelname == 'resourceSpecification' ||
      filterpanelname == 'serviceSpecification' ||
      filterpanelname == 'accounts' ||
      filterpanelname == 'contacts'
    "
    class="filter-panel-layout1"
  >
    <div v-if="filtertagvalue.length > 0" class="applied-filter-section">
      <div class="applied-filter-section-header">
        <p>{{labelValue.Filters_Applied}}</p>
        <p class="clear-all-button" @click="clearAllFilter">{{labelValue.Clear_All}}</p>
      </div>
      <div class="applied-filter-section-area">
        <span v-for="(filtervalue, arrindex) in filtertagvalue" :key="arrindex">
          <hlx-tag
            v-if="
              (filterpanelname == 'productOffering' ||
                filterpanelname == 'accounts' ||
                filterpanelname == 'contacts') &&
              filtervalue.name != undefined
            "
            class="tag-color1"
            @tag-close="removedFilterData(filtervalue)"
            >{{ filtervalue.name }}</hlx-tag
          >
          <hlx-tag
            v-else-if="
              (filterpanelname == 'productOffering' ||
                filterpanelname == 'accounts' ||
                filterpanelname == 'contacts') &&
              filtervalue.name == undefined
            "
            class="tag-color1"
            @tag-close="removedFilterData(filtervalue)"
            >{{ filtervalue.key + ":" + " Others" }}</hlx-tag
          >
          <span
            v-if="
              filterpanelname == 'productSpecification' ||
              filterpanelname == 'resourceSpecification' ||
              filterpanelname == 'serviceSpecification'
            "
          >
            <hlx-tag
              v-if="filtervalue.value != ''"
              class="tag-color1"
              @tag-close="removedFilterData(filtervalue)"
              >{{ filtervalue.value }}</hlx-tag
            >
            <hlx-tag
              v-else
              class="tag-color1"
              @tag-close="removedFilterData(filtervalue)"
            >
              {{ filtervalue.key + " : " + "Others" }}
            </hlx-tag>
          </span>
        </span>
      </div>
    </div>
    <div v-if="filterdata != undefined" class="select-filter-option">
      <span class="select-filter-option-header">
        <p class="select-filter-option-count-filter-section">{{labelValue.Filters}}</p>
        <span>
          <p class="select-filter-option-count">
            {{ filtertagvalue.length }} {{labelValue.selected}}
          </p>
        </span>
      </span>
      <span class="select-filter-option-section">
        <span v-for="(i, index) in Object.keys(filterdata)" :key="index">
          <hlx-accordion
            v-if="
              i != 'Product offering price' &&
              i != '_id' &&
              i != 'Product offering term'
            "
            :multiple="true"
          >
            <template #item-title>
              <span
                v-if="i != undefined"
                class="select-filter-option-section-title"
              >
                <span class="select-filter-option-section-title-name">
                  <!-- {{ i.charAt(0).toUpperCase() + i.slice(1) }} -->
                  {{ insertSpaces(i) }}
                </span>
                <span
                  v-if="
                    i != 'productOfferingPrice' &&
                    (filterpanelname == 'productOffering' ||
                      filterpanelname == 'accounts' ||
                      filterpanelname == 'contacts') &&
                    filterselectedcount[i] != undefined
                  "
                  class="select-filter-option-section-title-count"
                >
                  {{ filterselectedcount[i] }}
                  <!-- {{ filterdatacount[i] }} -->
                </span>
                <span
                  v-if="
                    filterpanelname == 'productSpecification' ||
                    filterpanelname == 'resourceSpecification' ||
                    filterpanelname == 'serviceSpecification'
                  "
                  class="select-filter-option-section-title-count"
                >
                  {{ filterselectedcount[i] }}
                  <!-- {{  filterdata[i].length  }} -->
                </span>
              </span>
            </template>
            <template #item-body>
              <div
                v-if="i != 'productOfferingPrice'"
                class="select-filter-body-section"
              >
                <span
                  v-if="
                    filterpanelname == 'productOffering' ||
                    filterpanelname == 'accounts' ||
                    filterpanelname == 'contacts'
                  "
                >
                  <div
                    v-for="(j, col_index) in filterdata[i]"
                    :key="col_index"
                    class="select-filter-body-section-value-container"
                  >
                    <!-- {{j}} -->
                    <span
                      v-if="
                        j &&
                        j.name &&
                        typeof j == 'object' &&
                        j.name != undefined &&
                        (filterpanelname == 'productOffering' ||
                          filterpanelname == 'accounts' ||
                          filterpanelname == 'contacts')
                      "
                      class="select-filter-body-section-value"
                    >
                      <span
                        v-if="typeof j == 'object'"
                        class="select-filter-body-section-value-details"
                      >
                        {{ insertSpaces(j.name) }}
                        <span
                          class="select-filter-body-section-value-details-count"
                          >{{ j.count }}</span
                        >
                        <!-- {{ j.name }} -->
                      </span>
                    </span>
                    <span
                      v-else-if="
                        j.name == undefined &&
                        (filterpanelname == 'productOffering' ||
                          filterpanelname == 'accounts' ||
                          filterpanelname == 'contacts')
                      "
                      class="select-filter-body-section-value"
                    >
                      {{ "Others" }}
                      <span
                        class="select-filter-body-section-value-details-count"
                        >{{ j.count }}</span
                      >
                    </span>
                    <span
                      v-if="
                        filterpanelname == 'productSpecification' ||
                        filterpanelname == 'resourceSpecification' ||
                        filterpanelname == 'serviceSpecification'
                      "
                      class="select-filter-body-section-value"
                    >
                      <span
                        v-if="
                          filterpanelname == 'productSpecification' ||
                          filterpanelname == 'resourceSpecification' ||
                          filterpanelname == 'serviceSpecification'
                        "
                        class="select-filter-body-section-value-details"
                      >
                        <span v-if="j.value != ''">
                          <span v-if="j.type == 'date'">{{
                            convertdateformat(j.value)
                          }}</span>
                          <span v-if="j.type == 'string'">
                            {{ insertSpaces(j.value) }}</span
                          >
                          <span v-if="j.type == 'boolean'">
                            <span v-if="j.value == true">{{ "True" }}</span>
                            <span v-if="j.value == false">{{ "False" }}</span>
                          </span>
                          <span v-if="j.type == 'number'">{{ j.value }}</span>
                          <span v-if="j.type == 'array'">{{ j.value }}</span>
                        </span>
                        <span v-else>
                          {{ "Others" }}
                        </span>

                        <!-- <span v-else>{{j.value}}</span> -->
                        <!-- {{j}} -->
                        <!-- {{j.value}} -->
                      </span>
                    </span>
                    <!-- {{ j }} -->
                    <span
                      v-if="
                        j &&
                        j.name &&
                        typeof j == 'object' &&
                        j.name != '' &&
                        j.name != undefined &&
                        (filterpanelname == 'productOffering' ||
                          filterpanelname == 'accounts' ||
                          filterpanelname == 'contacts')
                      "
                      class="select-filter-body-section-value-checkbox"
                    >
                      <hlx-input
                        v-model:value="j.checked"
                        :checked="j.checked"
                        :type="'single-checkbox'"
                        @selected="filterCheckedValue(i, j)"
                      />
                    </span>
                    <span
                      v-if="
                        j.name == undefined &&
                        (filterpanelname == 'productOffering' ||
                          filterpanelname == 'accounts' ||
                          filterpanelname == 'contacts')
                      "
                      class="select-filter-body-section-value-checkbox"
                    >
                      <hlx-input
                        v-model:value="j.checked"
                        :checked="j.checked"
                        :type="'single-checkbox'"
                        @selected="filterCheckedValue(i, j)"
                      />
                    </span>
                    <span
                      v-if="
                        filterpanelname == 'productSpecification' ||
                        filterpanelname == 'resourceSpecification' ||
                        filterpanelname == 'serviceSpecification'
                      "
                      class="select-filter-body-section-value-checkbox"
                    >
                      <hlx-input
                        v-model:value="j.checked"
                        :checked="j.checked"
                        :type="'single-checkbox'"
                        @selected="filterCheckedValue(i, j)"
                      />
                    </span>
                  </div>
                </span>
                <span
                  v-if="
                    filterpanelname == 'productSpecification' ||
                    filterpanelname == 'resourceSpecification' ||
                    filterpanelname == 'serviceSpecification'
                  "
                >
                  <div
                    v-for="(j, col_index) in filterdata[i]"
                    :key="col_index"
                    class="select-filter-body-section-value-container"
                  >
                    <!-- {{j}} -->
                    <span
                      v-if="
                        j &&
                        j.name &&
                        typeof j == 'object' &&
                        j.name != undefined &&
                        (filterpanelname == 'productOffering' ||
                          filterpanelname == 'accounts' ||
                          filterpanelname == 'contacts')
                      "
                      class="select-filter-body-section-value"
                    >
                      <span
                        v-if="typeof j == 'object'"
                        class="select-filter-body-section-value-details"
                      >
                        {{ insertSpaces(j.name) }}
                        <span
                          class="select-filter-body-section-value-details-count"
                          >{{ j.count }}</span
                        >
                        <!-- {{ j.name }} -->
                      </span>
                    </span>
                    <span
                      v-else-if="
                        j.name == undefined &&
                        (filterpanelname == 'productOffering' ||
                          filterpanelname == 'accounts' ||
                          filterpanelname == 'contacts')
                      "
                      class="select-filter-body-section-value"
                    >
                      {{ "Others" }}
                      <span
                        class="select-filter-body-section-value-details-count"
                        >{{ j.count }}</span
                      >
                    </span>
                    <span
                      v-if="
                        filterpanelname == 'productSpecification' ||
                        filterpanelname == 'resourceSpecification' ||
                        filterpanelname == 'serviceSpecification'
                      "
                      class="select-filter-body-section-value"
                    >
                      <span
                        v-if="
                          filterpanelname == 'productSpecification' ||
                          filterpanelname == 'resourceSpecification' ||
                          filterpanelname == 'serviceSpecification'
                        "
                        class="select-filter-body-section-value-details"
                      >
                        <span v-if="j.value.value != ''">
                          <span v-if="j.value.type == 'date'">{{
                            convertdateformat(j.value.value)
                          }}</span>
                          <span
                            v-if="
                              (j.value.type == 'string' && j.value.type) ||
                              typeof j.value.value == 'string'
                            "
                            >{{ insertSpaces(j.value.value) }}</span
                          >
                          <span v-if="j.value.type == 'boolean'">
                            <span v-if="j.value.value == true">{{
                              "True"
                            }}</span>
                            <span v-if="j.value.value == false">{{
                              "False"
                            }}</span>
                          </span>
                          <span v-if="j.value.type == 'number'">{{
                            j.value.value
                          }}</span>
                          <span v-if="j.value.type == 'array'">{{
                            j.value.value
                          }}</span>
                        </span>
                        <span v-else>
                          {{ "Others" }}
                        </span>
                        <span
                          class="select-filter-body-section-value-details-count"
                          >{{ j.count }}</span
                        >

                        <!-- <span v-else>{{j.value}}</span> -->
                        <!-- {{j}} -->
                        <!-- {{j.value}} -->
                      </span>
                    </span>
                    <!-- {{ j }} -->
                    <span
                      v-if="
                        j &&
                        j.name &&
                        typeof j == 'object' &&
                        j.name != '' &&
                        j.name != undefined &&
                        (filterpanelname == 'productOffering' ||
                          filterpanelname == 'accounts' ||
                          filterpanelname == 'contacts')
                      "
                      class="select-filter-body-section-value-checkbox"
                    >
                      <hlx-input
                        v-model:value="j.checked"
                        :checked="j.checked"
                        :type="'single-checkbox'"
                        @selected="filterCheckedValue(i, j)"
                      />
                    </span>
                    <span
                      v-if="
                        j.name == undefined &&
                        (filterpanelname == 'productOffering' ||
                          filterpanelname == 'accounts' ||
                          filterpanelname == 'contacts')
                      "
                      class="select-filter-body-section-value-checkbox"
                    >
                      <hlx-input
                        v-model:value="j.checked"
                        :checked="j.checked"
                        :type="'single-checkbox'"
                        @selected="filterCheckedValue(i, j)"
                      />
                    </span>
                    <span
                      v-if="
                        filterpanelname == 'productSpecification' ||
                        filterpanelname == 'resourceSpecification' ||
                        filterpanelname == 'serviceSpecification'
                      "
                      class="select-filter-body-section-value-checkbox"
                    >
                      <!-- {{'jaka'+i+j.value}} -->
                      <hlx-input
                        v-model:value="j.value.checked"
                        :checked="j.value.checked"
                        :type="'single-checkbox'"
                        @selected="filterCheckedValue(i, j.value)"
                      />
                    </span>
                  </div>
                </span>
              </div>
              <div v-else class="filter-slide-container">
                <span style="width: 100%">
                  <hlx-select
                    v-model="filterprice.units"
                    :inline-search="true"
                    :options="currencyList"
                    :clearable="true"
                    :placeholder-value="'Currency code'"
                    label="char"
                    prop-value="char"
                    @selected-value="currencychange"
                  ></hlx-select>

                  <hlx-min-max-slider
                    v-model:min-value="filterprice.min"
                    v-model:max-value="filterprice.max"
                    :min="sliderMin"
                    :max="sliderMax"
                    @slidervalue="sliderval"
                  />
                </span>
              </div>
            </template>
          </hlx-accordion>
        </span>

        <!-- <hlx-accordion :multiple="true" >
        <template #item-title>
          <span class="select-filter-option-section-title-name ">
            {{ 'Last modified' }}
          </span>
          </template>
          <template #item-body>
            <span class="datepicker-catalog">
              <hlx-date-picker
                from-key="startDateTime"
                to-key="endDateTime"
                :label-animation="false"
                :label-value="'Validity'"
                type="date"
                icon="calendar-regular"
                icon-position="right"
                required="true"
                @selected-range="handleCurrentDate"
                @datepicker-open="overlayIssues"
              ></hlx-date-picker>
            </span>
          </template>
        </hlx-accordion> -->
      </span>
    </div>
  </div>
</template>

<script>
// import { UPCAxiosInstance, MLTAxiosInstance } from '@/config/axiosConfig';
export default {
  props: {
    labelValue: {
      type: Object,
      default: function () {
        return {
          Filters : "Filters",
          Filters_Applied: "Filters Applied",
          Clear_All: "Clear All",
          selected: "selected",
         
        };
      },
    },
    filterpanelname: {
      type: String,
      default: "",
    },
  },
  emits: ["filteredData", "selectedData"],
  data() {
    return {
      productcompleteness: [
        { name: "No image", checked: false, key: "productcompleteness" },
        { name: "No description", checked: false, key: "productcompleteness" },
        { name: "No price", checked: false, key: "productcompleteness" },
      ],
      relatedParty: [],
      filterPrevalue: {},
      filtertagvalue: [],
      filterdata: {},
      filterselectedcount: {},
      filterdatacount: {},
      offeringprice: [],
      offeringfiltercondition: [],
      company: "",
      accountsfilterquery: {
        type: "filter",
        module: "productOffering",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {
          // name: -1
        },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      },
      contactsfilterquery: {
        type: "filter",
        module: "productOffering",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {
          // name: -1
        },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      },
      offeringfilterquery: {
        type: "filter",
        module: "productOffering",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {
          // name: -1
        },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      },
      filteredobj: { $in: [] },
      filteredkey: { $in: [] },
      filteredkeyarray: [],
      filteredarray: [],
      filterquery: {
        type: "filter",
        module: "productSpecification",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {
          // name: -1
        },
        limit: 10,
        filterQuery: {
          // "productSpecCharacteristic.productSpecCharacteristicValue.value": "",
        },
        searchQuery: "",
      },
    };
  },
  watch: {
    //   filterpanelname:{
    //    handler(val)
    //    {
    //       if(val == 'productOffering')
    //       {
    //         ;
    //          this.productOfferingFilterData()
    //       }
    //    }
    //   },
  },
  async mounted() {
    // const val = await MLTAxiosInstance.get(`partymanagement/individual/auth`);
    // this.company = val.data.company;
    if (this.filterpanelname == "productOffering") {
      this.filterPrevalue = this.$store.state.filterPrevalue;
      // console.log(this.$store.state.filterPrevalue,'filterprevalue');
      this.productOfferingFilterData();
      this.$store.commit("filterPreval", {});
      // console.log(this.$store.state.filterPrevalue,'filterpr');
    } else if (this.filterpanelname == "resourceSpecification") {
      this.resourceFilterData();
      this.filterquery = {
        type: "filter",
        module: "resourceSpecification",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {
          // name: -1
        },
        limit: 10,
        filterQuery: {
          // "productSpecCharacteristic.productSpecCharacteristicValue.value": "",
        },
        searchQuery: "",
      };
    } else if (this.filterpanelname == "serviceSpecification") {
      this.serviceFilterData();
      this.filterquery = {
        type: "filter",
        module: "serviceSpecification",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {
          // name: -1
        },
        limit: 10,
        filterQuery: {
          // "productSpecCharacteristic.productSpecCharacteristicValue.value": "",
        },
        searchQuery: "",
      };
    } else if (this.filterpanelname == "accounts") {
      this.accountsFilterData();
      let loginUser = await this.$store.dispatch("loginUserdata");
      this.offeringfilterquery = {
        type: "filter",
        module: "hierarchyaccounts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          "@baseType": "customer",
        },
        searchQuery: "",
        company: loginUser,
      };
    } else if (this.filterpanelname == "contacts") {
      this.contactsFilterData();
      let loginUser = await this.$store.dispatch("loginUserdata");
      this.offeringfilterquery = {
        type: "filter",
        module: "contacts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          "@baseType": "customer",
        },
        searchQuery: "",
        company: loginUser,
      };
    } else {
      this.productSpecFilterData();
    }
  },
  methods: {
    convertdateformat(date1) {
      if (date1 != null) {
        let objectDate = new Date(date1);
        if (isNaN(objectDate) == false) {
          let date = objectDate.toLocaleString("default", { day: "numeric" });
          let month = "";
          let format = this.$store.state.defaultSetting.dateFormat;
          if (
            format == "Month DD, YYYY" ||
            format == "mmmm dd, yyyy" ||
            format == "mmmm dd, yyyy"
          ) {
            month = objectDate.toLocaleString("default", { month: "long" });
          } else {
            month = objectDate.toLocaleString("default", { month: "short" });
          }
          let year = objectDate.getFullYear();
          let dateFormat = this.dateFormat(date, month, year, format);
          // let dateformat = month + " " + date + ", " + year;
          return dateFormat;
        }
      }
    },
    async accountsFilterData() {
      await this.$store.dispatch("accountFilterData");
      this.filterdata = this.$store.getters.accountsFilterData;
      // this.filterdata['productCompleteness'] = this.productcompleteness
      console.log(this.filterdata, "filterdata");
      Object.keys(this.filterdata).forEach((e) => {
        let arr = [];
        let arr1 = [];
        if (e != "_id") {
          this.filterdata[e]?.forEach((ele) => {
            if (typeof ele == "object") {
              if (!ele) return;
              if (typeof ele.name != "object") {
                ele.checked = false;
                ele.key = e;
                if (arr.includes(ele.name) == false) {
                  arr.push(ele.name);
                  arr1.push(ele);
                }
              }
            }
          });
          // console.log(arr1,'kdl',e);
          arr1.forEach((e, index) => {
            let obj = {};
            if (e.name == undefined) {
              obj = e;
              arr1.splice(index, 1);
              let length = arr1.length;
              arr1[length] = obj;
              //  arr1.push()
            }
          });
          this.filterdata[e] = arr1;
        }
      });
    },
    async contactsFilterData() {
      console.log("akaka");
      await this.$store.dispatch("contactFilterData");
      this.filterdata = this.$store.getters.contactsFilterData;
      console.log(this.filterdata, "cccccccccccccccccccco");
      // this.filterdata['productCompleteness'] = this.productcompleteness
      console.log(this.filterdata, "filterdata");
      Object.keys(this.filterdata).forEach((e) => {
        let arr = [];
        let arr1 = [];
        if (e != "_id") {
          this.filterdata[e]?.forEach((ele) => {
            if (typeof ele == "object") {
              if (!ele) return;
              if (typeof ele.name != "object") {
                ele.checked = false;
                ele.key = e;
                if (arr.includes(ele.name) == false) {
                  arr.push(ele.name);
                  arr1.push(ele);
                }
              }
            }
          });
          // console.log(arr1,'kdl',e);
          arr1.forEach((e, index) => {
            let obj = {};
            if (e.name == undefined) {
              obj = e;
              arr1.splice(index, 1);
              let length = arr1.length;
              arr1[length] = obj;
              //  arr1.push()
            }
          });
          this.filterdata[e] = arr1;
        }
      });
    },
    async serviceFilterData() {
      await this.$store.dispatch("serviceSpecFilterData");
      this.filterdata = this.$store.getters.serviceSpecificationFilterData;
      Object.keys(this.filterdata).forEach((e) => {
        if (e != "_id") {
          if (this.filterdata[e] != undefined) {
            this.filterdata[e]?.forEach((ele) => {
              if (typeof ele == "object") {
                if (!ele) return;

                ele.value.key = e;
                ele.value.checked = false;
              }
            });
          }
        }
      });
    },
    async resourceFilterData() {
      await this.$store.dispatch("resourceSpecFilterData");
      this.filterdata = this.$store.getters.resourceSpecificationFilterData;
      Object.keys(this.filterdata).forEach((e) => {
        if (e != "_id") {
          if (this.filterdata[e] != undefined) {
            this.filterdata[e]?.forEach((ele) => {
              if (typeof ele == "object") {
                if (!ele) return;

                ele.value.key = e;
                ele.value.checked = false;
              }
            });
          }
        }
      });
    },
    async productOfferingFilterData() {
      await this.$store.dispatch("productOfferFilterData");
      this.filterdata = this.$store.getters.productOfferingFilterData;
      this.relatedParty = this.$store.state.relatedParty;
      this.filterdata["relatedParty"] = this.relatedParty;
      // this.filterdata['productCompleteness'] = this.productcompleteness
      console.log(this.filterdata, "filterdata");
      Object.keys(this.filterdata).forEach((e) => {
        let arr = [];
        let arr1 = [];
        if (e != "_id") {
          this.filterdata[e]?.forEach((ele) => {
            if (typeof ele == "object") {
              if (!ele) return;
              if (typeof ele.name != "object") {
                ele.checked = false;
                ele.key = e;
                if (arr.includes(ele.name) == false) {
                  arr.push(ele.name);
                  arr1.push(ele);
                }
              }
            }
          });
          // console.log(arr1,'kdl',e);
          arr1.forEach((e, index) => {
            let obj = {};
            if (e.name == undefined) {
              obj = e;
              arr1.splice(index, 1);
              let length = arr1.length;
              arr1[length] = obj;
              //  arr1.push()
            }
          });
          this.filterdata[e] = arr1;
        }
      });
      // console.log(this.filterdatacount, "count");
      console.log(this.filterPrevalue,'filterPreValue')
      if (Object.keys(this.filterPrevalue).length != 0) {
        Object.keys(this.filterdata).forEach((e) => {
          Object.keys(this.filterPrevalue).forEach((ele) => {
            if (e.toLowerCase() == ele.toLowerCase()) {
              let value = this.filterPrevalue[ele][0].toLowerCase();
              this.filterdata[e]?.forEach((element) => {
                if (element.name != undefined) {
                  let data = element.name.toLowerCase();
                  // console.log(data);
                  if (data == value) {
                    element.checked = true;
                    this.filterCheckedValue(e, element);
                  }
                }
              });
              console.log(value, "valueeeee");
            }
          });
        });
      }
    },
    async productSpecFilterData() {
      await this.$store.dispatch("productSpecFilterData");
      this.filterdata = this.$store.getters.productSpecificationFilterData;
      console.log("this.filterdataa", this.filterdata);
      Object.keys(this.filterdata).forEach((e) => {
        e;
        // console.log(e);
        //console.log(this.filterdata[e],'kd');
      });
      Object.keys(this.filterdata).forEach((e) => {
        if (e != "_id") {
          // console.log(this.filterdata[e.value],'kd');
          if (this.filterdata[e] != undefined) {
            // console.log();
            this.filterdata[e]?.forEach((ele) => {
              if (typeof ele.value == "object") {
                if (!ele.value) return;
                //  console.log(e,'laaaaaaaaaaa');
                ele.value.key = e;
                ele.value.checked = false;
              }
            });
          }
        }
      });
      console.log(this.filterdata);
    },
    async filterCheckedValue(key, value) {
      console.log(key, value);
      if (this.filterpanelname == "productOffering") {
        if (value.checked == true) {
          this.filtertagvalue.push(value);
          if (this.offeringfiltercondition.includes(key) != true) {
            this.offeringfiltercondition.push(key);
            let obj = { $in: [] };
            let arr = [];
            arr.push(value.name);
            obj["$in"] = arr;
            if (key == "Place") {
              this.offeringfilterquery["filterQuery"]["place.name"] = obj;
            } else if (key == "Category") {
              this.offeringfilterquery["filterQuery"]["category.name"] = obj;
            } else if (key == "Channel") {
              this.offeringfilterquery["filterQuery"]["channel.name"] = obj;
            } else if (key == "Market segment") {
              this.offeringfilterquery["filterQuery"]["marketSegment.name"] =
                obj;
            } else if (key == "Status") {
              this.offeringfilterquery["filterQuery"]["lifecycleStatus"] = obj;
            } else if (key == "relatedParty") {
              this.offeringfilterquery["filterQuery"]["relatedParty.name"] =
                obj;
            }
            this.$emit(
              "filteredData",
              this.filtertagvalue,
              this.offeringfilterquery
            );
            this.$emit("selectedData", this.filtertagvalue);
          } else {
            if (key == "Place") {
              let arr =
                this.offeringfilterquery["filterQuery"]["place.name"]["$in"];
              arr.push(value.name);
              this.offeringfilterquery["filterQuery"]["place.name"]["$in"] =
                arr;
            } else if (key == "Category") {
              let arr =
                this.offeringfilterquery["filterQuery"]["category.name"]["$in"];
              arr.push(value.name);
              this.offeringfilterquery["filterQuery"]["category.name"]["$in"] =
                arr;
            } else if (key == "Channel") {
              let arr =
                this.offeringfilterquery["filterQuery"]["channel.name"]["$in"];
              arr.push(value.name);
              this.offeringfilterquery["filterQuery"]["channel.name"]["$in"] =
                arr;
            } else if (key == "Market segment") {
              let arr =
                this.offeringfilterquery["filterQuery"]["marketSegment.name"][
                  "$in"
                ];
              arr.push(value.name);
              this.offeringfilterquery["filterQuery"]["marketSegment.name"][
                "$in"
              ] = arr;
            } else if (key == "Status") {
              let arr =
                this.offeringfilterquery["filterQuery"]["lifecycleStatus"][
                  "$in"
                ];
              arr.push(value.name);
              this.offeringfilterquery["filterQuery"]["lifecycleStatus"][
                "$in"
              ] = arr;
            } else if (key == "relatedParty") {
              let arr =
                this.offeringfilterquery["filterQuery"]["relatedParty.name"][
                  "$in"
                ];
              arr.push(value.name);
              this.offeringfilterquery["filterQuery"]["relatedParty.name"][
                "$in"
              ] = arr;
            }
            // const filterResultData = await UPCAxiosInstance.post(
            //   `util/filterData/${this.company}`,
            //   this.offeringfilterquery
            // );
            // ;
            // let tableData = filterResultData.data.data;
            // let totalpageno = filterResultData.data.total;
            this.$emit(
              "filteredData",
              this.filtertagvalue,
              this.offeringfilterquery
            );
            this.$emit("selectedData", this.filtertagvalue);
          }
        } else if (value.checked == false) {
          // ;
          this.filtertagvalue.forEach((e, index) => {
            if (e.name == value.name) {
              e.checked = false;
              this.filtertagvalue.splice(index, 1);
            }
          });
          if (key == "Place") {
            let arr =
              this.offeringfilterquery["filterQuery"]["place.name"]["$in"];
            let arr1 =
              this.offeringfilterquery["filterQuery"]["place.name"]["$in"];
            arr.forEach((ele, col_index) => {
              if (value.name == ele) {
                arr1.splice(col_index, 1);
              }
            });
            if (arr1.length != 0) {
              this.offeringfilterquery["filterQuery"]["place.name"]["$in"] =
                arr1;
            } else {
              //   ;
              delete this.offeringfilterquery["filterQuery"]["place.name"];
              this.offeringfiltercondition.forEach((e, offeringindex) => {
                if (e == key) {
                  this.offeringfiltercondition.splice(offeringindex, 1);
                }
              });
            }
          } else if (key == "Category") {
            // this.offeringfilterquery['filterQuery']['category.name'] = obj
            let arr =
              this.offeringfilterquery["filterQuery"]["category.name"]["$in"];
            let arr1 =
              this.offeringfilterquery["filterQuery"]["category.name"]["$in"];
            arr.forEach((ele, col_index) => {
              if (value.name == ele) {
                arr1.splice(col_index, 1);
              }
            });
            if (arr1.length != 0) {
              this.offeringfilterquery["filterQuery"]["category.name"]["$in"] =
                arr1;
            } else {
              delete this.offeringfilterquery["filterQuery"]["category.name"];
              this.offeringfiltercondition.forEach((e, offeringindex) => {
                if (e == key) {
                  this.offeringfiltercondition.splice(offeringindex, 1);
                }
              });
            }
            // this.offeringfilterquery['filterQuery']['category.name']['$in']= arr1
          } else if (key == "Channel") {
            // this.offeringfilterquery['filterQuery']['channel.name'] = obj
            let arr =
              this.offeringfilterquery["filterQuery"]["channel.name"]["$in"];
            let arr1 =
              this.offeringfilterquery["filterQuery"]["channel.name"]["$in"];
            arr.forEach((ele, col_index) => {
              if (value.name == ele) {
                arr1.splice(col_index, 1);
              }
            });
            if (arr1.length != 0) {
              this.offeringfilterquery["filterQuery"]["channel.name"]["$in"] =
                arr1;
            } else {
              delete this.offeringfilterquery["filterQuery"]["channel.name"];
              this.offeringfiltercondition.forEach((e, offeringindex) => {
                if (e == key) {
                  this.offeringfiltercondition.splice(offeringindex, 1);
                }
              });
            }
            // this.offeringfilterquery['filterQuery']['channel.name']['$in'] = arr1
          } else if (key == "Market segment") {
            // this.offeringfilterquery['filterQuery']['marketSegment.name'] = obj
            let arr =
              this.offeringfilterquery["filterQuery"]["marketSegment.name"][
                "$in"
              ];
            let arr1 =
              this.offeringfilterquery["filterQuery"]["marketSegment.name"][
                "$in"
              ];
            arr.forEach((ele, col_index) => {
              if (value.name == ele) {
                arr1.splice(col_index, 1);
              }
            });
            if (arr1.length != 0) {
              this.offeringfilterquery["filterQuery"]["marketSegment.name"][
                "$in"
              ] = arr1;
            } else {
              delete this.offeringfilterquery["filterQuery"][
                "marketSegment.name"
              ];
              this.offeringfiltercondition.forEach((e, offeringindex) => {
                if (e == key) {
                  this.offeringfiltercondition.splice(offeringindex, 1);
                }
              });
            }
            // this.offeringfilterquery['filterQuery']['marketSegment.name']['$in'] = arr1
          } else if (key == "Status") {
            // this.offeringfilterquery['filterQuery']['marketSegment.name'] = obj
            let arr =
              this.offeringfilterquery["filterQuery"]["lifecycleStatus"]["$in"];
            let arr1 =
              this.offeringfilterquery["filterQuery"]["lifecycleStatus"]["$in"];
            arr.forEach((ele, col_index) => {
              if (value.name == ele) {
                arr1.splice(col_index, 1);
              }
            });
            if (arr1.length != 0) {
              this.offeringfilterquery["filterQuery"]["lifecycleStatus"][
                "$in"
              ] = arr1;
            } else {
              delete this.offeringfilterquery["filterQuery"]["lifecycleStatus"];
              this.offeringfiltercondition.forEach((e, offeringindex) => {
                if (e == key) {
                  this.offeringfiltercondition.splice(offeringindex, 1);
                }
              });
            }
            // this.offeringfilterquery['filterQuery']['marketSegment.name']['$in'] = arr1
          } else if (key == "relatedParty") {
            let arr =
              this.offeringfilterquery["filterQuery"]["relatedParty.name"][
                "$in"
              ];
            let arr1 =
              this.offeringfilterquery["filterQuery"]["relatedParty.name"][
                "$in"
              ];
            arr.forEach((ele, col_index) => {
              if (value.name == ele) {
                arr1.splice(col_index, 1);
              }
            });
            if (arr1.length != 0) {
              this.offeringfilterquery["filterQuery"]["relatedParty.name"][
                "$in"
              ] = arr1;
            } else {
              delete this.offeringfilterquery["filterQuery"][
                "relatedParty.name"
              ];
              this.offeringfiltercondition.forEach((e, offeringindex) => {
                if (e == key) {
                  this.offeringfiltercondition.splice(offeringindex, 1);
                }
              });
            }
          }
          this.$emit(
            "filteredData",
            this.filtertagvalue,
            this.offeringfilterquery
          );
          this.$emit("selectedData", this.filtertagvalue);
        }
        // ;
        if (value.checked == true) {
          if (this.filterselectedcount[value.key] == undefined) {
            this.filterselectedcount[value.key] = 1;
          } else {
            let countvalue = this.filterselectedcount[value.key];
            countvalue = countvalue + 1;
            this.filterselectedcount[value.key] = countvalue;
          }
        } else {
          let countvalue = this.filterselectedcount[value.key];
          countvalue = countvalue - 1;
          if (countvalue == 0) {
            delete this.filterselectedcount[value.key];
          } else {
            this.filterselectedcount[value.key] = countvalue;
          }
        }
      } else if (this.filterpanelname == "accounts") {
        if (value.checked == true) {
          this.filtertagvalue.push(value);
          if (this.offeringfiltercondition.includes(key) != true) {
            this.offeringfiltercondition.push(key);
            let obj = { $in: [] };
            let arr = [];
            arr.push(value.name);
            obj["$in"] = arr;
            if (key == "Status") {
              this.offeringfilterquery["filterQuery"]["status"] = obj;
            } else if (key == "Contact medium") {
              this.offeringfilterquery["filterQuery"][
                "contactMedium.mediumType"
              ] = obj;
            }
            this.$emit(
              "filteredData",
              this.filtertagvalue,
              this.offeringfilterquery
            );
            this.$emit("selectedData", this.filtertagvalue);
          } else {
            if (key == "Status") {
              let arr =
                this.offeringfilterquery["filterQuery"]["status"]["$in"];
              arr.push(value.name);
              this.offeringfilterquery["filterQuery"]["status"]["$in"] = arr;
            } else if (key == "Contact medium") {
              let arr =
                this.offeringfilterquery["filterQuery"][
                  "contactMedium.mediumType"
                ]["$in"];
              arr.push(value.name);
              this.offeringfilterquery["filterQuery"][
                "contactMedium.mediumType"
              ]["$in"] = arr;
            }
            // const filterResultData = await UPCAxiosInstance.post(
            //   `util/filterData/${this.company}`,
            //   this.offeringfilterquery
            // );
            // ;
            // let tableData = filterResultData.data.data;
            // let totalpageno = filterResultData.data.total;
            this.$emit(
              "filteredData",
              this.filtertagvalue,
              this.offeringfilterquery
            );
            this.$emit("selectedData", this.filtertagvalue);
          }
        } else if (value.checked == false) {
          // ;
          this.filtertagvalue.forEach((e, index) => {
            if (e.name == value.name) {
              e.checked = false;
              this.filtertagvalue.splice(index, 1);
            }
          });
          if (key == "Status") {
            // this.offeringfilterquery['filterQuery']['marketSegment.name'] = obj
            let arr = this.offeringfilterquery["filterQuery"]["status"]["$in"];
            let arr1 = this.offeringfilterquery["filterQuery"]["status"]["$in"];
            arr.forEach((ele, col_index) => {
              if (value.name == ele) {
                arr1.splice(col_index, 1);
              }
            });
            if (arr1.length != 0) {
              this.offeringfilterquery["filterQuery"]["status"]["$in"] = arr1;
            } else {
              delete this.offeringfilterquery["filterQuery"]["status"];
              this.offeringfiltercondition.forEach((e, offeringindex) => {
                if (e == key) {
                  this.offeringfiltercondition.splice(offeringindex, 1);
                }
              });
            }
            // this.offeringfilterquery['filterQuery']['marketSegment.name']['$in'] = arr1
          } else if (key == "Contact medium") {
            let arr =
              this.offeringfilterquery["filterQuery"][
                "contactMedium.mediumType"
              ]["$in"];
            let arr1 =
              this.offeringfilterquery["filterQuery"][
                "contactMedium.mediumType"
              ]["$in"];
            arr.forEach((ele, col_index) => {
              if (value.name == ele) {
                arr1.splice(col_index, 1);
              }
            });
            if (arr1.length != 0) {
              this.offeringfilterquery["filterQuery"][
                "contactMedium.mediumType"
              ]["$in"] = arr1;
            } else {
              delete this.offeringfilterquery["filterQuery"][
                "contactMedium.mediumType"
              ];
              this.offeringfiltercondition.forEach((e, offeringindex) => {
                if (e == key) {
                  this.offeringfiltercondition.splice(offeringindex, 1);
                }
              });
            }
          }
          this.$emit(
            "filteredData",
            this.filtertagvalue,
            this.offeringfilterquery
          );
          this.$emit("selectedData", this.filtertagvalue);
        }
        // ;
        if (value.checked == true) {
          if (this.filterselectedcount[value.key] == undefined) {
            this.filterselectedcount[value.key] = 1;
          } else {
            let countvalue = this.filterselectedcount[value.key];
            countvalue = countvalue + 1;
            this.filterselectedcount[value.key] = countvalue;
          }
        } else {
          let countvalue = this.filterselectedcount[value.key];
          countvalue = countvalue - 1;
          if (countvalue == 0) {
            delete this.filterselectedcount[value.key];
          } else {
            this.filterselectedcount[value.key] = countvalue;
          }
        }
      } else if (this.filterpanelname == "contacts") {
        if (value.checked == true) {
          this.filtertagvalue.push(value);
          if (this.offeringfiltercondition.includes(key) != true) {
            this.offeringfiltercondition.push(key);
            let obj = { $in: [] };
            let arr = [];
            arr.push(value.name);
            obj["$in"] = arr;
            if (key == "Status") {
              this.offeringfilterquery["filterQuery"]["status"] = obj;
            } else if (key == "Contact medium") {
              this.offeringfilterquery["filterQuery"][
                "contactMedium.mediumType"
              ] = obj;
            }
            this.$emit(
              "filteredData",
              this.filtertagvalue,
              this.offeringfilterquery
            );
            this.$emit("selectedData", this.filtertagvalue);
          } else {
            if (key == "Status") {
              let arr =
                this.offeringfilterquery["filterQuery"]["status"]["$in"];
              arr.push(value.name);
              this.offeringfilterquery["filterQuery"]["status"]["$in"] = arr;
            } else if (key == "Contact medium") {
              let arr =
                this.offeringfilterquery["filterQuery"][
                  "contactMedium.mediumType"
                ]["$in"];
              arr.push(value.name);
              this.offeringfilterquery["filterQuery"][
                "contactMedium.mediumType"
              ]["$in"] = arr;
            }
            // const filterResultData = await UPCAxiosInstance.post(
            //   `util/filterData/${this.company}`,
            //   this.offeringfilterquery
            // );
            // ;
            // let tableData = filterResultData.data.data;
            // let totalpageno = filterResultData.data.total;
            this.$emit(
              "filteredData",
              this.filtertagvalue,
              this.offeringfilterquery
            );
            this.$emit("selectedData", this.filtertagvalue);
          }
        } else if (value.checked == false) {
          // ;
          this.filtertagvalue.forEach((e, index) => {
            if (e.name == value.name) {
              e.checked = false;
              this.filtertagvalue.splice(index, 1);
            }
          });
          if (key == "Status") {
            // this.offeringfilterquery['filterQuery']['marketSegment.name'] = obj
            let arr = this.offeringfilterquery["filterQuery"]["status"]["$in"];
            let arr1 = this.offeringfilterquery["filterQuery"]["status"]["$in"];
            arr.forEach((ele, col_index) => {
              if (value.name == ele) {
                arr1.splice(col_index, 1);
              }
            });
            if (arr1.length != 0) {
              this.offeringfilterquery["filterQuery"]["status"]["$in"] = arr1;
            } else {
              delete this.offeringfilterquery["filterQuery"]["status"];
              this.offeringfiltercondition.forEach((e, offeringindex) => {
                if (e == key) {
                  this.offeringfiltercondition.splice(offeringindex, 1);
                }
              });
            }
            // this.offeringfilterquery['filterQuery']['marketSegment.name']['$in'] = arr1
          } else if (key == "Contact medium") {
            let arr =
              this.offeringfilterquery["filterQuery"][
                "contactMedium.mediumType"
              ]["$in"];
            let arr1 =
              this.offeringfilterquery["filterQuery"][
                "contactMedium.mediumType"
              ]["$in"];
            arr.forEach((ele, col_index) => {
              if (value.name == ele) {
                arr1.splice(col_index, 1);
              }
            });
            if (arr1.length != 0) {
              this.offeringfilterquery["filterQuery"][
                "contactMedium.mediumType"
              ]["$in"] = arr1;
            } else {
              delete this.offeringfilterquery["filterQuery"][
                "contactMedium.mediumType"
              ];
              this.offeringfiltercondition.forEach((e, offeringindex) => {
                if (e == key) {
                  this.offeringfiltercondition.splice(offeringindex, 1);
                }
              });
            }
          }
          this.$emit(
            "filteredData",
            this.filtertagvalue,
            this.offeringfilterquery
          );
          this.$emit("selectedData", this.filtertagvalue);
        }
        // ;
        if (value.checked == true) {
          if (this.filterselectedcount[value.key] == undefined) {
            this.filterselectedcount[value.key] = 1;
          } else {
            let countvalue = this.filterselectedcount[value.key];
            countvalue = countvalue + 1;
            this.filterselectedcount[value.key] = countvalue;
          }
        } else {
          let countvalue = this.filterselectedcount[value.key];
          countvalue = countvalue - 1;
          if (countvalue == 0) {
            delete this.filterselectedcount[value.key];
          } else {
            this.filterselectedcount[value.key] = countvalue;
          }
        }
      } else if (this.filterpanelname == "productSpecification") {
        if (value.checked == true) {
          this.filtertagvalue.push(value);
          console.log(this.filteredarray);
          this.filteredarray.push(value.value);
          this.filteredobj["$in"] = this.filteredarray;
          this.filterquery["filterQuery"][
            "productSpecCharacteristic.productSpecCharacteristicValue.value"
          ] = this.filteredobj;
          let obj = {};
          obj.key = key;
          obj.value = value.value;
          this.filteredkeyarray.push(obj);
          let keyarray = [];
          this.filteredkeyarray.forEach((e) => {
            keyarray.push(e.key);
          });
          this.filteredkey["$in"] = keyarray;
          this.filterquery["filterQuery"]["productSpecCharacteristic.name"] =
            this.filteredkey;
          // const filterResultData = await  UPCAxiosInstance.post(`util/filterData/${this.company}`,this.filterquery)
          // let tableData = filterResultData.data.data
          // let totalpageno = filterResultData.data.total
          this.$emit("filteredData", this.filtertagvalue, this.filterquery);
        } else if (value.checked == false) {
          this.filtertagvalue.forEach((e, index) => {
            if (e.value == value.value) {
              this.filtertagvalue.splice(index, 1);
            }
          });
          let filterqueryarray = [];
          filterqueryarray =
            this.filterquery["filterQuery"][
              "productSpecCharacteristic.productSpecCharacteristicValue.value"
            ]["$in"];
          filterqueryarray.forEach((e, index) => {
            if (value.value == e) {
              filterqueryarray.splice(index, 1);
            }
          });
          filterqueryarray = this.filteredarray;
          let filterquerykey = [];
          this.filteredkeyarray.forEach((e, index) => {
            if (value.value == e.value && key == e.key) {
              this.filteredkeyarray.splice(index, 1);
            }
          });
          this.filteredkeyarray.forEach((e) => {
            filterquerykey.push(e.key);
          });
          // filterquerykey = this.filteredkeyarray;
          if (this.filteredarray.length != 0) {
            this.filterquery["filterQuery"][
              "productSpecCharacteristic.productSpecCharacteristicValue.value"
            ]["$in"] = filterqueryarray;
            this.filterquery["filterQuery"]["productSpecCharacteristic.name"][
              "$in"
            ] = filterquerykey;
          } else {
            this.filterquery["filterQuery"] = {};
          }
          // const filterResultData = await  UPCAxiosInstance.post(`util/filterData/${this.company}`,this.filterquery)
          //   let tableData = filterResultData.data.data
          //   let totalpageno = filterResultData.data.total
          this.$emit("filteredData", this.filtertagvalue, this.filterquery);
        }

        if (value.checked == true) {
          if (this.filterselectedcount[value.key] == undefined) {
            this.filterselectedcount[value.key] = 1;
          } else {
            let countvalue = this.filterselectedcount[value.key];
            countvalue = countvalue + 1;
            this.filterselectedcount[value.key] = countvalue;
          }
        } else {
          let countvalue = this.filterselectedcount[value.key];
          countvalue = countvalue - 1;
          if (countvalue == 0) {
            delete this.filterselectedcount[value.key];
          } else {
            this.filterselectedcount[value.key] = countvalue;
          }
        }
      } else if (this.filterpanelname == "resourceSpecification") {
        if (value.checked == true) {
          this.filtertagvalue.push(value);
          this.filteredarray.push(value.value);
          this.filteredobj["$in"] = this.filteredarray;
          this.filterquery["filterQuery"][
            "resourceSpecCharacteristic.resourceSpecCharacteristicValue.value"
          ] = this.filteredobj;
          let obj = {};
          obj.key = key;
          obj.value = value.value;
          this.filteredkeyarray.push(obj);
          let keyarray = [];
          this.filteredkeyarray.forEach((e) => {
            keyarray.push(e.key);
          });
          this.filteredkey["$in"] = keyarray;
          this.filterquery["filterQuery"]["resourceSpecCharacteristic.name"] =
            this.filteredkey;
          // const filterResultData = await  UPCAxiosInstance.post(`util/filterData/${this.company}`,this.filterquery)
          // let tableData = filterResultData.data.data
          // let totalpageno = filterResultData.data.total
          this.$emit("filteredData", this.filtertagvalue, this.filterquery);
        } else if (value.checked == false) {
          this.filtertagvalue.forEach((e, index) => {
            if (e.value == value.value) {
              this.filtertagvalue.splice(index, 1);
            }
          });
          let filterqueryarray = [];
          filterqueryarray =
            this.filterquery["filterQuery"][
              "resourceSpecCharacteristic.resourceSpecCharacteristicValue.value"
            ]["$in"];
          filterqueryarray.forEach((e, index) => {
            if (value.value == e) {
              filterqueryarray.splice(index, 1);
            }
          });
          filterqueryarray = this.filteredarray;
          let filterquerykey = [];
          this.filteredkeyarray.forEach((e, index) => {
            if (value.value == e.value && key == e.key) {
              this.filteredkeyarray.splice(index, 1);
            }
          });
          this.filteredkeyarray.forEach((e) => {
            filterquerykey.push(e.key);
          });
          if (this.filteredarray.length != 0) {
            this.filterquery["filterQuery"][
              "resourceSpecCharacteristic.resourceSpecCharacteristicValue.value"
            ]["$in"] = filterqueryarray;
          } else {
            this.filterquery["filterQuery"] = {};
          }
          // const filterResultData = await  UPCAxiosInstance.post(`util/filterData/${this.company}`,this.filterquery)
          //   let tableData = filterResultData.data.data
          //   let totalpageno = filterResultData.data.total
          this.$emit("filteredData", this.filtertagvalue, this.filterquery);
        }

        if (value.checked == true) {
          if (this.filterselectedcount[value.key] == undefined) {
            this.filterselectedcount[value.key] = 1;
          } else {
            let countvalue = this.filterselectedcount[value.key];
            countvalue = countvalue + 1;
            this.filterselectedcount[value.key] = countvalue;
          }
        } else {
          let countvalue = this.filterselectedcount[value.key];
          countvalue = countvalue - 1;
          if (countvalue == 0) {
            delete this.filterselectedcount[value.key];
          } else {
            this.filterselectedcount[value.key] = countvalue;
          }
        }
      } else if (this.filterpanelname == "serviceSpecification") {
        if (value.checked == true) {
          this.filtertagvalue.push(value);
          this.filteredarray.push(value.value);
          this.filteredobj["$in"] = this.filteredarray;
          this.filterquery["filterQuery"][
            "serviceSpecCharacteristic.serviceSpecCharacteristicValue.value"
          ] = this.filteredobj;
          let obj = {};
          obj.key = key;
          obj.value = value.value;
          this.filteredkeyarray.push(obj);
          let keyarray = [];
          this.filteredkeyarray.forEach((e) => {
            keyarray.push(e.key);
          });
          this.filteredkey["$in"] = keyarray;
          this.filterquery["filterQuery"]["serviceSpecCharacteristic.name"] =
            this.filteredkey;
          // const filterResultData = await  UPCAxiosInstance.post(`util/filterData/${this.company}`,this.filterquery)
          // let tableData = filterResultData.data.data
          // let totalpageno = filterResultData.data.total
          this.$emit("filteredData", this.filtertagvalue, this.filterquery);
        } else if (value.checked == false) {
          this.filtertagvalue.forEach((e, index) => {
            if (e.value == value.value) {
              this.filtertagvalue.splice(index, 1);
            }
          });
          let filterqueryarray = [];
          filterqueryarray =
            this.filterquery["filterQuery"][
              "serviceSpecCharacteristic.serviceSpecCharacteristicValue.value"
            ]["$in"];
          filterqueryarray.forEach((e, index) => {
            if (value.value == e) {
              filterqueryarray.splice(index, 1);
            }
          });
          filterqueryarray = this.filteredarray;
          let filterquerykey = [];
          this.filteredkeyarray.forEach((e, index) => {
            if (value.value == e.value && key == e.key) {
              this.filteredkeyarray.splice(index, 1);
            }
          });
          this.filteredkeyarray.forEach((e) => {
            filterquerykey.push(e.key);
          });
          if (this.filteredarray.length != 0) {
            this.filterquery["filterQuery"][
              "serviceSpecCharacteristic.serviceSpecCharacteristicValue.value"
            ]["$in"] = filterqueryarray;
          } else {
            this.filterquery["filterQuery"] = {};
          }
          // const filterResultData = await  UPCAxiosInstance.post(`util/filterData/${this.company}`,this.filterquery)
          //   let tableData = filterResultData.data.data
          //   let totalpageno = filterResultData.data.total
          this.$emit("filteredData", this.filtertagvalue, this.filterquery);
        }
        if (value.checked == true) {
          if (this.filterselectedcount[value.key] == undefined) {
            this.filterselectedcount[value.key] = 1;
          } else {
            let countvalue = this.filterselectedcount[value.key];
            countvalue = countvalue + 1;
            this.filterselectedcount[value.key] = countvalue;
          }
        } else {
          let countvalue = this.filterselectedcount[value.key];
          countvalue = countvalue - 1;
          if (countvalue == 0) {
            delete this.filterselectedcount[value.key];
          } else {
            this.filterselectedcount[value.key] = countvalue;
          }
        }
      }
      // ;
    },
    async removedFilterData(value) {
      if (this.filterpanelname == "productOffering") {
        // ;
        this.filtertagvalue.forEach((e, index) => {
          if (e.name == value.name && e.key == value.key) {
            e.checked = false;
            this.filtertagvalue.splice(index, 1);
            if (e.key == "Place") {
              // ;
              let arr =
                this.offeringfilterquery["filterQuery"]["place.name"]["$in"];
              let arr1 =
                this.offeringfilterquery["filterQuery"]["place.name"]["$in"];
              arr.forEach((ele, col_index) => {
                if (e.name == ele) {
                  arr1.splice(col_index, 1);
                }
              });
              if (arr1.length != 0) {
                this.offeringfilterquery["filterQuery"]["place.name"]["$in"] =
                  arr1;
              } else {
                delete this.offeringfilterquery["filterQuery"]["place.name"];
                // ;
                this.offeringfiltercondition.forEach((e, offeringindex) => {
                  if (e == value.key) {
                    this.offeringfiltercondition.splice(offeringindex, 1);
                  }
                });
              }
            } else if (e.key == "Category") {
              // this.offeringfilterquery['filterQuery']['category.name'] = obj
              let arr =
                this.offeringfilterquery["filterQuery"]["category.name"]["$in"];
              let arr1 =
                this.offeringfilterquery["filterQuery"]["category.name"]["$in"];
              arr.forEach((ele, col_index) => {
                if (e.name == ele) {
                  arr1.splice(col_index, 1);
                }
              });
              if (arr1.length != 0) {
                this.offeringfilterquery["filterQuery"]["category.name"][
                  "$in"
                ] = arr1;
              } else {
                delete this.offeringfilterquery["filterQuery"]["category.name"];
                this.offeringfiltercondition.forEach((e, offeringindex) => {
                  if (e == value.key) {
                    this.offeringfiltercondition.splice(offeringindex, 1);
                  }
                });
              }
              // this.offeringfilterquery['filterQuery']['category.name']['$in']= arr1
            } else if (e.key == "Channel") {
              // this.offeringfilterquery['filterQuery']['channel.name'] = obj
              let arr =
                this.offeringfilterquery["filterQuery"]["channel.name"]["$in"];
              let arr1 =
                this.offeringfilterquery["filterQuery"]["channel.name"]["$in"];
              arr.forEach((ele, col_index) => {
                if (e.name == ele) {
                  arr1.splice(col_index, 1);
                }
              });
              if (arr1.length != 0) {
                this.offeringfilterquery["filterQuery"]["channel.name"]["$in"] =
                  arr1;
              } else {
                delete this.offeringfilterquery["filterQuery"]["channel.name"];
                this.offeringfiltercondition.forEach((e, offeringindex) => {
                  if (e == value.key) {
                    this.offeringfiltercondition.splice(offeringindex, 1);
                  }
                });
              }
              // this.offeringfilterquery['filterQuery']['channel.name']['$in'] = arr1
            } else if (e.key == "Market segment") {
              // this.offeringfilterquery['filterQuery']['marketSegment.name'] = obj
              let arr =
                this.offeringfilterquery["filterQuery"]["marketSegment.name"][
                  "$in"
                ];
              let arr1 =
                this.offeringfilterquery["filterQuery"]["marketSegment.name"][
                  "$in"
                ];
              arr.forEach((ele, col_index) => {
                if (e.name == ele) {
                  arr1.splice(col_index, 1);
                }
              });
              if (arr1.length != 0) {
                this.offeringfilterquery["filterQuery"]["marketSegment.name"][
                  "$in"
                ] = arr1;
              } else {
                delete this.offeringfilterquery["filterQuery"][
                  "marketSegment.name"
                ];
                this.offeringfiltercondition.forEach((e, offeringindex) => {
                  if (e == value.key) {
                    this.offeringfiltercondition.splice(offeringindex, 1);
                  }
                });
              }
              // this.offeringfilterquery['filterQuery']['marketSegment.name']['$in'] = arr1
            } else if (e.key == "relatedParty") {
              // this.offeringfilterquery['filterQuery']['marketSegment.name'] = obj
              let arr =
                this.offeringfilterquery["filterQuery"]["relatedParty.name"][
                  "$in"
                ];
              let arr1 =
                this.offeringfilterquery["filterQuery"]["relatedParty.name"][
                  "$in"
                ];
              arr.forEach((ele, col_index) => {
                if (e.name == ele) {
                  arr1.splice(col_index, 1);
                }
              });
              if (arr1.length != 0) {
                this.offeringfilterquery["filterQuery"]["relatedParty.name"][
                  "$in"
                ] = arr1;
              } else {
                delete this.offeringfilterquery["filterQuery"][
                  "relatedParty.name"
                ];
                this.offeringfiltercondition.forEach((e, offeringindex) => {
                  if (e == value.key) {
                    this.offeringfiltercondition.splice(offeringindex, 1);
                  }
                });
              }
            } else if (e.key == "Status") {
              // this.offeringfilterquery['filterQuery']['marketSegment.name'] = obj
              let arr =
                this.offeringfilterquery["filterQuery"]["lifecycleStatus"][
                  "$in"
                ];
              let arr1 =
                this.offeringfilterquery["filterQuery"]["lifecycleStatus"][
                  "$in"
                ];
              arr.forEach((ele, col_index) => {
                if (e.name == ele) {
                  arr1.splice(col_index, 1);
                }
              });
              if (arr1.length != 0) {
                this.offeringfilterquery["filterQuery"]["lifecycleStatus"][
                  "$in"
                ] = arr1;
              } else {
                delete this.offeringfilterquery["filterQuery"][
                  "lifecycleStatus"
                ];
                this.offeringfiltercondition.forEach((e, offeringindex) => {
                  if (e == value.key) {
                    this.offeringfiltercondition.splice(offeringindex, 1);
                  }
                });
              }
            }
          }
        });
        if (value.key == "productOfferingPrice") {
          this.sliderfilter = false;
        }
        // ;
        //  const filterResultData = await  UPCAxiosInstance.post(`util/filterData/${this.company}`,this.offeringfilterquery)
        //   // ;
        //   let tableData = filterResultData.data.data;
        //   let totalpageno = filterResultData.data.total;
        this.$emit(
          "filteredData",
          this.filtertagvalue,
          this.offeringfilterquery
        );
        this.$emit("selectedData", this.filtertagvalue);
        let countvalue = this.filterselectedcount[value.key];
        countvalue = countvalue - 1;
        if (countvalue == 0) {
          delete this.filterselectedcount[value.key];
        } else {
          this.filterselectedcount[value.key] = countvalue;
        }
        // ;
      } else if (this.filterpanelname == "productSpecification") {
        this.filtertagvalue.forEach((e, index) => {
          if (e.value == value.value) {
            e.checked = false;
            this.filtertagvalue.splice(index, 1);
          }
        });
        let filterqueryarray = [];
        filterqueryarray =
          this.filterquery["filterQuery"][
            "productSpecCharacteristic.productSpecCharacteristicValue.value"
          ]["$in"];
        filterqueryarray.forEach((e, index) => {
          if (value.value == e) {
            filterqueryarray.splice(index, 1);
          }
        });
        filterqueryarray = this.filteredarray;
        let filterquerykey = [];
        this.filteredkeyarray.forEach((e, index) => {
          if (value.value == e.value) {
            this.filteredkeyarray.splice(index, 1);
          }
        });
        this.filteredkeyarray.forEach((e) => {
          filterquerykey.push(e.key);
        });
        if (this.filteredarray.length != 0) {
          this.filterquery["filterQuery"][
            "productSpecCharacteristic.productSpecCharacteristicValue.value"
          ]["$in"] = filterqueryarray;
          this.filterquery["filterQuery"]["productSpecCharacteristic.name"][
            "$in"
          ] = filterquerykey;
        } else {
          this.filterquery["filterQuery"] = {};
        }
        // this.filterquery['filterQuery']['productSpecCharacteristic.productSpecCharacteristicValue.value']["$in"] = filterqueryarray
        // const filterResultData = await  UPCAxiosInstance.post(`util/filterData/${this.company}`,this.filterquery)
        // let tableData = filterResultData.data.data
        // let totalpageno = filterResultData.data.total
        this.$emit("filteredData", this.filtertagvalue, this.filterquery);
        let countvalue = this.filterselectedcount[value.key];
        countvalue = countvalue - 1;
        if (countvalue == 0) {
          delete this.filterselectedcount[value.key];
        } else {
          this.filterselectedcount[value.key] = countvalue;
        }
      } else if (this.filterpanelname == "resourceSpecification") {
        this.filtertagvalue.forEach((e, index) => {
          if (e.value == value.value) {
            e.checked = false;
            this.filtertagvalue.splice(index, 1);
          }
        });
        let filterqueryarray = [];
        filterqueryarray =
          this.filterquery["filterQuery"][
            "resourceSpecCharacteristic.resourceSpecCharacteristicValue.value"
          ]["$in"];
        filterqueryarray.forEach((e, index) => {
          if (value.value == e) {
            filterqueryarray.splice(index, 1);
          }
        });
        filterqueryarray = this.filteredarray;
        if (this.filteredarray.length != 0) {
          this.filterquery["filterQuery"][
            "resourceSpecCharacteristic.resourceSpecCharacteristicValue.value"
          ]["$in"] = filterqueryarray;
        } else {
          this.filterquery["filterQuery"] = {};
        }
        // this.filterquery['filterQuery']['productSpecCharacteristic.productSpecCharacteristicValue.value']["$in"] = filterqueryarray
        // const filterResultData = await  UPCAxiosInstance.post(`util/filterData/${this.company}`,this.filterquery)
        // let tableData = filterResultData.data.data
        // let totalpageno = filterResultData.data.total
        this.$emit("filteredData", this.filtertagvalue, this.filterquery);

        let countvalue = this.filterselectedcount[value.key];
        countvalue = countvalue - 1;
        if (countvalue == 0) {
          delete this.filterselectedcount[value.key];
        } else {
          this.filterselectedcount[value.key] = countvalue;
        }
      } else if (this.filterpanelname == "serviceSpecification") {
        this.filtertagvalue.forEach((e, index) => {
          if (e.value == value.value) {
            e.checked = false;
            this.filtertagvalue.splice(index, 1);
          }
        });
        let filterqueryarray = [];
        filterqueryarray =
          this.filterquery["filterQuery"][
            "serviceSpecCharacteristic.serviceSpecCharacteristicValue.value"
          ]["$in"];
        filterqueryarray.forEach((e, index) => {
          if (value.value == e) {
            filterqueryarray.splice(index, 1);
          }
        });
        filterqueryarray = this.filteredarray;
        if (this.filteredarray.length != 0) {
          this.filterquery["filterQuery"][
            "serviceSpecCharacteristic.serviceSpecCharacteristicValue.value"
          ]["$in"] = filterqueryarray;
        } else {
          this.filterquery["filterQuery"] = {};
        }
        // this.filterquery['filterQuery']['productSpecCharacteristic.productSpecCharacteristicValue.value']["$in"] = filterqueryarray
        // const filterResultData = await  UPCAxiosInstance.post(`util/filterData/${this.company}`,this.filterquery)
        // let tableData = filterResultData.data.data
        // let totalpageno = filterResultData.data.total
        this.$emit("filteredData", this.filtertagvalue, this.filterquery);

        let countvalue = this.filterselectedcount[value.key];
        countvalue = countvalue - 1;
        if (countvalue == 0) {
          delete this.filterselectedcount[value.key];
        } else {
          this.filterselectedcount[value.key] = countvalue;
        }
      } else if (this.filterpanelname == "accounts") {
        // ;
        this.filtertagvalue.forEach((e, index) => {
          if (e.name == value.name && e.key == value.key) {
            e.checked = false;
            this.filtertagvalue.splice(index, 1);
            if (e.key == "Status") {
              // this.offeringfilterquery['filterQuery']['marketSegment.name'] = obj
              let arr =
                this.offeringfilterquery["filterQuery"]["status"]["$in"];
              let arr1 =
                this.offeringfilterquery["filterQuery"]["status"]["$in"];
              arr.forEach((ele, col_index) => {
                if (e.name == ele) {
                  arr1.splice(col_index, 1);
                }
              });
              if (arr1.length != 0) {
                this.offeringfilterquery["filterQuery"]["status"]["$in"] = arr1;
              } else {
                delete this.offeringfilterquery["filterQuery"]["status"];
                this.offeringfiltercondition.forEach((e, offeringindex) => {
                  if (e == value.key) {
                    this.offeringfiltercondition.splice(offeringindex, 1);
                  }
                });
              }
            } else if (e.key == "Contact medium") {
              // this.offeringfilterquery['filterQuery']['marketSegment.name'] = obj
              let arr =
                this.offeringfilterquery["filterQuery"][
                  "contactMedium.mediumType"
                ]["$in"];
              let arr1 =
                this.offeringfilterquery["filterQuery"][
                  "contactMedium.mediumType"
                ]["$in"];
              arr.forEach((ele, col_index) => {
                if (e.name == ele) {
                  arr1.splice(col_index, 1);
                }
              });
              if (arr1.length != 0) {
                this.offeringfilterquery["filterQuery"][
                  "contactMedium.mediumType"
                ]["$in"] = arr1;
              } else {
                delete this.offeringfilterquery["filterQuery"][
                  "contactMedium.mediumType"
                ];
                this.offeringfiltercondition.forEach((e, offeringindex) => {
                  if (e == value.key) {
                    this.offeringfiltercondition.splice(offeringindex, 1);
                  }
                });
              }
            }
          }
        });
        if (value.key == "productOfferingPrice") {
          this.sliderfilter = false;
        }
        // ;
        //  const filterResultData = await  UPCAxiosInstance.post(`util/filterData/${this.company}`,this.offeringfilterquery)
        //   // ;
        //   let tableData = filterResultData.data.data;
        //   let totalpageno = filterResultData.data.total;
        this.$emit(
          "filteredData",
          this.filtertagvalue,
          this.offeringfilterquery
        );
        this.$emit("selectedData", this.filtertagvalue);
        let countvalue = this.filterselectedcount[value.key];
        countvalue = countvalue - 1;
        if (countvalue == 0) {
          delete this.filterselectedcount[value.key];
        } else {
          this.filterselectedcount[value.key] = countvalue;
        }
        // ;
      } else if (this.filterpanelname == "contacts") {
        // ;
        this.filtertagvalue.forEach((e, index) => {
          if (e.name == value.name && e.key == value.key) {
            e.checked = false;
            this.filtertagvalue.splice(index, 1);
            if (e.key == "Status") {
              // this.offeringfilterquery['filterQuery']['marketSegment.name'] = obj
              let arr =
                this.offeringfilterquery["filterQuery"]["status"]["$in"];
              let arr1 =
                this.offeringfilterquery["filterQuery"]["status"]["$in"];
              arr.forEach((ele, col_index) => {
                if (e.name == ele) {
                  arr1.splice(col_index, 1);
                }
              });
              if (arr1.length != 0) {
                this.offeringfilterquery["filterQuery"]["status"]["$in"] = arr1;
              } else {
                delete this.offeringfilterquery["filterQuery"]["status"];
                this.offeringfiltercondition.forEach((e, offeringindex) => {
                  if (e == value.key) {
                    this.offeringfiltercondition.splice(offeringindex, 1);
                  }
                });
              }
            } else if (e.key == "Contact medium") {
              // this.offeringfilterquery['filterQuery']['marketSegment.name'] = obj
              let arr =
                this.offeringfilterquery["filterQuery"][
                  "contactMedium.mediumType"
                ]["$in"];
              let arr1 =
                this.offeringfilterquery["filterQuery"][
                  "contactMedium.mediumType"
                ]["$in"];
              arr.forEach((ele, col_index) => {
                if (e.name == ele) {
                  arr1.splice(col_index, 1);
                }
              });
              if (arr1.length != 0) {
                this.offeringfilterquery["filterQuery"][
                  "contactMedium.mediumType"
                ]["$in"] = arr1;
              } else {
                delete this.offeringfilterquery["filterQuery"][
                  "contactMedium.mediumType"
                ];
                this.offeringfiltercondition.forEach((e, offeringindex) => {
                  if (e == value.key) {
                    this.offeringfiltercondition.splice(offeringindex, 1);
                  }
                });
              }
            }
          }
        });
        if (value.key == "productOfferingPrice") {
          this.sliderfilter = false;
        }
        // ;
        //  const filterResultData = await  UPCAxiosInstance.post(`util/filterData/${this.company}`,this.offeringfilterquery)
        //   // ;
        //   let tableData = filterResultData.data.data;
        //   let totalpageno = filterResultData.data.total;
        this.$emit(
          "filteredData",
          this.filtertagvalue,
          this.offeringfilterquery
        );
        this.$emit("selectedData", this.filtertagvalue);
        let countvalue = this.filterselectedcount[value.key];
        countvalue = countvalue - 1;
        if (countvalue == 0) {
          delete this.filterselectedcount[value.key];
        } else {
          this.filterselectedcount[value.key] = countvalue;
        }
        // ;
      }
    },
    clearAllFilter() {
      if (this.filterpanelname == "productOffering") {
        Object.keys(this.filterdata).forEach((e) => {
          // ;
          if (e != "_id") {
            this.filterdata[e]?.forEach((ele) => {
              if (typeof ele == "object") {
                if (!ele) return;

                ele.key = e;
                ele.checked = false;
              }
            });
          }
        });
        this.filtertagvalue = [];
        this.offeringfiltercondition = [];
        this.offeringfilterquery["filterQuery"] = {};
        this.sliderfilter = false;
        this.filterselectedcount = {};
        // let tableData = []
        // let totalpageno = 0
        this.$emit(
          "filteredData",
          this.filtertagvalue,
          this.offeringfilterquery
        );
      } else if (this.filterpanelname == "productSpecification") {
        Object.keys(this.filterdata).forEach((e) => {
          if (e != "_id") {
            this.filterdata[e]?.forEach((ele) => {
              if (typeof ele == "object") {
                if (!ele) return;

                ele.value.checked = false;
              }
            });
          }
        });
        this.filtertagvalue = [];
        // let tableData = []
        // let totalpageno = 0
        this.filterquery["filterQuery"] = {};
        this.filterselectedcount = {};
        this.filteredarray = [];
        this.$emit("filteredData", this.filtertagvalue, this.filterquery);
      } else if (this.filterpanelname == "resourceSpecification") {
        Object.keys(this.filterdata).forEach((e) => {
          if (e != "_id") {
            this.filterdata[e]?.forEach((ele) => {
              if (typeof ele.value == "object") {
                if (!ele) return;

                ele.value.checked = false;
              }
            });
          }
        });
        this.filtertagvalue = [];
        // let tableData = []
        // let totalpageno = 0
        this.filterquery["filterQuery"] = {};
        this.filterselectedcount = {};
        this.filteredarray = [];
        this.$emit("filteredData", this.filtertagvalue, this.filterquery);
      } else if (this.filterpanelname == "serviceSpecification") {
        Object.keys(this.filterdata).forEach((e) => {
          if (e != "_id") {
            this.filterdata[e]?.forEach((ele) => {
              if (typeof ele.value == "object") {
                if (!ele) return;

                ele.value.checked = false;
              }
            });
          }
        });
        this.filtertagvalue = [];
        // let tableData = []
        // let totalpageno = 0
        this.filterquery["filterQuery"] = {};
        this.filterselectedcount = {};
        this.filteredarray = [];
        this.$emit("filteredData", this.filtertagvalue, this.filterquery);
      } else if (this.filterpanelname == "accounts") {
        Object.keys(this.filterdata).forEach((e) => {
          // ;
          if (e != "_id") {
            this.filterdata[e]?.forEach((ele) => {
              if (typeof ele == "object") {
                if (!ele) return;

                ele.key = e;
                ele.checked = false;
              }
            });
          }
        });
        this.filtertagvalue = [];
        this.offeringfiltercondition = [];
        this.offeringfilterquery["filterQuery"] = {};
        this.sliderfilter = false;
        this.filterselectedcount = {};
        // let tableData = []
        // let totalpageno = 0
        this.$emit(
          "filteredData",
          this.filtertagvalue,
          this.offeringfilterquery
        );
      } else if (this.filterpanelname == "contacts") {
        Object.keys(this.filterdata).forEach((e) => {
          if (e != "_id") {
            this.filterdata[e]?.forEach((ele) => {
              if (typeof ele == "object") {
                if (!ele) return;

                ele.key = e;
                ele.checked = false;
              }
            });
          }
        });
        this.filtertagvalue = [];
        this.offeringfiltercondition = [];
        this.offeringfilterquery["filterQuery"] = {};
        this.sliderfilter = false;
        this.filterselectedcount = {};
        this.$emit(
          "filteredData",
          this.filtertagvalue,
          this.offeringfilterquery
        );
      }
    },
    insertSpaces(string) {
      if (typeof string != "string") return;
      let str = string
        ?.replace(/(_|-)/g, " ")
        .trim()
        .replace(/\w\S*/g, function (str) {
          return str.charAt(0).toUpperCase() + str.substr(1);
        })
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
      return str;
    },
  },
};
</script>

<style></style>
