<template>
    <div class="signature-placeholder" style="display:flex;flex-direction:column;gap:0.7rem">
          <span v-if="signatureList.length > 0" style="display:flex;flex-direction:column;gap:0.7rem">
          <span v-for="(i,index) in signatureList" :id="'signature-placeholder-section'+unique+index" :key="index" @mouseover="hoverFunctionality(index)" @mouseout="removeHoverFunctionality(index)">
          <!-- <span :style="{background:convertRgbToRgba(assignedColors[i?.name]), border: '1px solid' + assignedColors[i?.name]}"> -->
           <hlx-button  :style="{...backgroundColor(i?.name)}">
            <span style="display:flex;align-items: flex-end;">
            <i class="icon-pen-tool-regular"></i>
            <span style="padding-left: 4px">{{ i?.name }}</span>
            </span> 
            <span :id="'signature-placeholder-edit'+unique+index" class="signature-placeholder-edit">
             <span @click="editPlaceholder(i.name)"> 
              <i class="icon-file-text-edit-regular"></i>
              <p>Edit</p>
            </span>
            <span @click="deletePlaceholder(i.name)"> 
              <i class="icon-trash-regular"></i>
              <p>Delete</p>
            </span>
            </span>
          </hlx-button>
          </span>
          </span>
          <span v-if="show==true" :id="'multiple-signature-button'+unique" class="secondary multiple-signature-button" @click.stop="openModal">
            <i class="icon-pen-tool-regular"></i>
            <span>Add signature</span>
            <span :id="'multiple-signature-button-modal-box'+unique" class="multiple-signature-button-modal-box">
              <!--header-->
              <span style="display:flex;justify-content:space-between">
                <span>
                  <b style="color:black">Add signature</b>
                </span>
                <span>
                  <i class="icon-times-regular" style="color:#A6A6A6" @click.stop="closeModal"></i>
                </span>              
              </span>
              <span>
                 <hlx-select
                      :key = "componentKey"
                      :pre-value="editValue"
                      :clearable="false"
                      :options="duplicateRecipientLists"
                      :placeholder-value="'Choose recipient'"
                      :label-animation="true"
                      :label="'name'"
                      :prop-value="'name'"
                      :footer-icon="'icon-plus-circle-regular'"
                      :footer-content="'Add recipient'"
                      :footer="true"
                      @footer-click="callFooterEvent"
                      @selected-value="chooseSignature"
                 ></hlx-select>
              </span>
              <span style="display:flex;justify-content:flex-end">
                  <hlx-button class="primary sm" @click.stop="addSignature">Add</hlx-button>
                </span>
            </span>
          </span>
        </div>
</template>


<script>
// import {
//   MLTAxiosInstance,
// } from '@/config/axiosConfig';
export default {
  props:{
    accountName:{
      type: String,
      default:(()=>{
        return ''
      })
    },
    recipientLists:{
      type: Array,
      default:(()=>{
        return []
      })
    },
     preValue:{
      type: Array,
      default:(()=>{
        return []
      })
     },
     assignDataColor:{
      type: Object,
      default:(()=>{
        return {}
      })
     }
  },
  emits: ["assign-data-color-object","emit-data","add-recipient"],
data() {
    return{
      duplicateRecipientLists:[],
      show:true,
      editValue:'',
      searchString:'',
      colorArray:[
          'rgb(45, 45, 45)',    // Dark Gray
          'rgb(25, 25, 112)',   // Midnight Blue
          'rgb(0, 51, 102)',    // Dark Blue
          'rgb(47, 79, 79)',    // Dark Slate Gray
          'rgb(85, 107, 47)',   // Dark Olive Green
          'rgb(0, 100, 0)',     // Dark Green
          'rgb(139, 0, 0)',     // Dark Red
          'rgb(75, 0, 130)',    // Indigo
          'rgb(63, 81, 181)',   // Dark Indigo
          'rgb(72, 61, 139)'    // Dark Slate Blue
        ],
        assignedColors: {},
        unique: Math.floor(Math.random() * 1000 + 1),
        getRecipientListFromProps:[],
        signatureList:[],
        duplicateSignature:{},
        componentKey:0,
    }
},
watch:{
  accountName:{
    handler(val){
      if(val != ''){
       console.log(val)
      }
    },
    immediate:true,
    deep:true
  },
  recipientLists:{
    handler(val){
      if(val){
        val = val.map((ele) => {
      if (ele.checked === true) {
        ele.checked = false;
      }
      return ele; 
    });
        console.log(val,"<=val","this.signatureList=>",this.signatureList,"this.propsForMultiSignComponent from component",val)
        this.getRecipientListFromProps=val
        this.duplicateRecipientLists = val
        this.signatureList = this.signatureList.filter(itemA => val.some(itemB => itemA.id === itemB.id));

console.log("this.signatureList down",this.signatureList);
      }
    },
    immediate:true,
    deep:true
  },
  getRecipientListFromProps:{
    handler(val){
      if(val.length === this.signatureList.length){
          this.show = false
        }else{
          this.show = true  
        }
    },
    immediate:true,
    deep:true
  },
  duplicateRecipientLists:{
    handler(val){
      val
      // if(val.length === this.signatureList.length){
      //     this.show = false
      //   }else{
      //     this.show = true  
      //   }
    },
    immediate:true,
    deep:true
  },
  assignDataColor:{
    handler(val){
      if(Object.keys(val).length != 0){
          this.assignedColors = JSON.parse(JSON.stringify(val)) 
      }
    },
    immediate:true,
    deep:true
  },
  preValue:{
    handler(val){
    if(Object.keys(val).length != 0){
      console.log(val,'lololo')
      // this.duplicateSignature = JSON.parse(JSON.stringify(val))
      this.signatureList = JSON.parse(JSON.stringify(val)) 
      } 
    },
    immediate:true,
    deep:true
  },
  signatureList:{
    handler(val){
      console.log(val,this.recipientLists,'signature=>',this.signatureList)
      if(val.length !=0){
        if(val.length === this.recipientLists.length){
          this.show = false
        }else{
          this.show = true
        }
      }
    },
    immediate:true,
    deep:true
  }
  // assignedColors:{
  //   handler(val){
  //      this.$emit('assign-data-color-object',val)
  //   },
  //   immediate:true,
  //   deep:true
  // }
},
async mounted(){
    // let recipientListOptions = {
    //   company: this.company,
    //   type: 'filter-search',
    //   module: 'hierarchyaccounts',
    //   isPaginated: true,
    //   paginatedQuery: {
    //     rowsPerPage: 10,
    //     currentPage: 1,
    //   },
    //   isNeedToBeSort: true,
    //   sortFieldAndOrder: {
    //     name: 1,
    //   },
    //   limit: 2,
    //   filterQuery: {
    //     status: { $regex: 'active', $options: 'i' },
    //     '@baseType': 'customer',
    //   },
    //   searchQuery: '',
    // };
    // let recipientLists = await MLTAxiosInstance.post(
    //   '/util/filterData',
    //   recipientListOptions
    // );

    // this.recipientLists = recipientLists.data.data

},
methods:{
  editPlaceholder(value){
    if(value){
    // document.getElementById('multiple-signature-button-modal-box'+this.unique).style.display = 'flex'
    this.editValue = value
    }
    else{
      console.log("error from editPlaceholder")
    }
  },
  deletePlaceholder(value){
    this.signatureList = this.signatureList.filter(obj => obj.name !== value)
    this.duplicateRecipientLists = this.signatureList
    this.$emit('emit-data',this.signatureList)
  },
  // async contactList(){
    // let value = this.accountName.trim()
  //   let recipientContactOptions = {
  //       company:this.accountName,
  //       type: "filter-search",
  //       module: "contacts",
  //       isPaginated: true,
  //       paginatedQuery: {
  //         rowsPerPage: 10,
  //         currentPage: 1,
  //       },
  //       isNeedToBeSort: true,
  //       sortFieldAndOrder: {
  //         name: 1,
  //       },
  //       limit: 2,
  //       filterQuery: {
  //         status: { $regex: "active", $options: "i" },
  //         "@baseType": "customer",
  //       },
  //       searchQuery: '',
  //     };

  //   let recipientlists = await MLTAxiosInstance.post(
  //       "/util/filterData",
  //       recipientContactOptions
  //     );
  //     this.recipientLists = recipientlists.data.data;
  // },
  removeHoverFunctionality(index){
    document.getElementById('signature-placeholder-edit'+this.unique+index).style.display = 'none'

  },
  hoverFunctionality(index){
    console.log('oqpqoqpq')
    document.getElementById('signature-placeholder-edit'+this.unique+index).style.display = 'flex'

  },
  backgroundColor(value){
    let background = this.convertRgbToRgba(this.assignedColors[value])
    let border = this.assignedColors[value]
    let borderColor = '1px solid ' + border
    console.log(borderColor,this.assignedColors,'testt')
    const style={
      border: borderColor,
      width:'160px',
      background:background
    }
    return style
  },
  assignColor(value) {
    // const dropdown = document.getElementById('color-dropdown');
    const selectedValue = value;

    if (selectedValue) {
      // If the selected value already has a color, use it; otherwise assign a new random color
      if (!this.assignedColors[selectedValue]) {
        const randomIndex = Math.floor(Math.random() * this.colorArray.length);
        this.assignedColors[selectedValue] = this.colorArray[randomIndex]; // Assign a random color
      }

      // Apply the assigned color to the element
      // document.getElementById('color-box').style.backgroundColor = this.assignedColors[selectedValue];
      console.log(this.assignedColors,this.duplicateSignature,'laadu')
    }
  },
   convertRgbToRgba(rgbString) {
    if(rgbString && rgbString != ''){
  // Extract the RGB values using a regular expression
  const rgbValues = rgbString.match(/\d+/g);

  // Format the extracted values to the new RGB format with 30% opacity
  return `rgb(${rgbValues[0]} ${rgbValues[1]} ${rgbValues[2]} / 30%)`;
    }
},
    openModal(){
        document.getElementById('multiple-signature-button-modal-box'+this.unique).style.display = 'flex'
    },
    closeModal(){
      this.componentKey += 1
    if(document.getElementById('multiple-signature-button-modal-box'+this.unique).style.display == 'flex')
    {
        // console.log('poe',document.getElementById('multiple-signature-button-modal-box'+this.unique))
        document.getElementById('multiple-signature-button-modal-box'+this.unique).style.display = 'none'
        // console.log('poe',document.getElementById('multiple-signature-button-modal-box'+this.unique))

    }
    },
    addSignature(){
        this.componentKey += 1
        if(this.editValue != ''){
          this.signatureList = this.signatureList.filter(obj => obj.name !== this.editValue)
        }
        document.getElementById('multiple-signature-button-modal-box'+this.unique).style.display = 'none'
        const includesString =  this.signatureList.some(obj => 
            obj.name && obj.name.includes(this.searchString)
          );
        if(includesString != true){

          this.signatureList.push(JSON.parse(JSON.stringify(this.duplicateSignature)))
          this.duplicateRecipientLists = this.duplicateRecipientLists.filter(item=> item.name != this.duplicateSignature.name)
        }
        this.$emit('assign-data-color-object',JSON.parse(JSON.stringify(this.assignedColors)))
        this.$emit('emit-data',this.signatureList)
        this.editValue = ''
    },
    chooseSignature(val){
        console.log(val,'lolo')
        this.assignColor(val.name)
        this.searchString = val.name
        let value = val
        value['color'] = this.assignedColors[val.name]
        value['sign'] = false
        this.duplicateSignature = value
    },
    callFooterEvent(){
      this.$emit('add-recipient')

    },

}
}
</script>