<template>
  <div class="app-layout">
    <section class="right-panel">
        <hlx-drawer
    :title="'Dependency tree'" 
    :show="show_top" 
    :width="700" 
    position="right" 
    :footer="false"
    :show-close-icon="true"
    @close="closeTop" 
    >
    <template #body>
     <depTree
            :rule-id="audit_id"
            :rule-name="$route.params.name"
            :type="'audit'"
     ></depTree>
    </template>
  </hlx-drawer>
      <div class="layout-basic-no-breadcrumb" style="display:block">
        <!-- <div class="layout-breadcrumb">
        <hlx-breadcrumb :items="items" @path="pathTo" />
    </div>  -->

        <div class="layout-header">
          <span class="audit-header">
          <p>Execution logs</p>
          <span class="reference-subtitle">
          Gain visibility into performance of individual rule executions for
          effective troubleshooting.
        </span></span></div>
    

        <div class="layout-body" style="overflow:visible">
          <div class="layout-body-audit-container">
            <div class="cards-container-audit">
              <div class="one">
                <div class="audit-icon-graph-section">
                  <span class="audit-icon-container-1"
                    ><i class="icon-clock-regular"></i
                  ></span>
                  <div class="audit-icon-section">
                    <div class="audit-graph-section-value">
                      {{ averageExecDuration }} ms
                    </div>
                    <span class="audit-icon-title">Average duration</span>
                  </div>
                </div>
              </div>
              <div class="two">
                <div class="audit-icon-graph-section">
                  <span class="audit-icon-container-2"
                    ><i class="icon-clock-alt-regular"></i
                  ></span>
                  <div class="audit-icon-section">
                    <div class="audit-graph-section-value">
                      {{ minimumExecDuration }} ms
                    </div>
                    <span class="audit-icon-title">Minimum duration</span>
                  </div>
                </div>
              </div>
              <div class="three">
                <div class="audit-icon-graph-section">
                  <span class="audit-icon-container-3"
                    ><i class="icon-stopwatch-times-regular"></i
                  ></span>
                  <div class="audit-icon-section">
                    <div class="audit-graph-section-value">
                      {{ maximumExecDuration }} ms
                    </div>
                    <span class="audit-icon-title">Maximum duration</span>
                  </div>
                </div>
              </div>
              <div>
                <div class="pie">
                  <div class="pie-chart">
                    <p class="apex-header"><b>Execution metrics</b></p>
                    <div class="chart-holder">
                      <VueApexCharts
                        :type="piechart.chart.type"
                        height="100%"
                        width="100%"
                        :options="piechart"
                        :series="piechart.series"
                      ></VueApexCharts>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <hlx-card
            :seperator="false"
            :padding="true"
            v-for="(i, index) in this.card_data"
            :key="index"
            >
            <template #card-body>
                <div v-if="i.card === 'card1'">
                   <div class="audit-icon-graph-section">
                        <span class="audit-icon-container-1"><i class="icon-clock-regular"></i></span>
                        <div class="audit-icon-section">
                        <div class="audit-graph-section-value">{{this.averageExecDuration}} ms</div>
                         <span class="audit-icon-title">Average duration</span>
                       </div>
                   </div>

                </div>
                <div v-if="i.card === 'card2'">
                   <div class="audit-icon-graph-section">
                        <span class="audit-icon-container-1"><i class="icon-clock-alt-regular"></i></span>
                        <div class="audit-icon-section">
                        <div class="audit-graph-section-value">{{ this.minimumExecDuration }} ms</div>
                         <span class="audit-icon-title">Minimum duration</span>
                       </div>
                   </div>

                </div>
                <div v-if="i.card === 'card3'">
                   <div class="audit-icon-graph-section">
                        <span class="audit-icon-container-1"><i class="icon-stopwatch-times-regular"></i></span>
                        <div class="audit-icon-section">
                        <div class="audit-graph-section-value">{{ this.maximumExecDuration }} ms</div>
                         <span class="audit-icon-title">Maximum duration</span>
                       </div>
                   </div>

                </div>
                <div v-if="i.card === 'card4'">
            <div class="pie">
              <div class="pie-chart">
                <p class="apex-header"><b>Execution metrics</b></p>
                <div class="chart-holder">
                  <VueApexCharts
                    :type="this.piechart.chart.type"
                    height="100%"
                    width="100%"
                    :options="this.piechart"
                    :series="this.piechart.series"
                  ></VueApexCharts>
                </div>
              </div>
            </div>
                </div>
            </template>
            </hlx-card> -->
            </div>
            <div class="table-audit" style="padding-bottom: 6rem;">
              <div class="table-audit-search-container">
                <span class="table-audit-search-container-left">
                  <p>History</p>
                </span>
                <span class="table-audit-search-container-right util-items search">
                  <hlx-search
                    :search-style="'style1'"
                    @search-key="search"
                  />
                </span>
              </div>
              <div class="table-audit-container">
                <hlx-table
                  v-if="tableData.length > 0"
                  :id="'table-1'"
                  :column-count="theads"
                  :border="['table', 'header', 'horizontal']"
                  :bold-headers="false"
                  :row-hover="false"
                  theme="grey"
                  :striped-rows="false"
                  :re-arrange-columns="re_arrange"
                  :re-arrange-heads="theads"
                  @close-rearrange="arrange"
                  @apply-rearranged-columns="applyColumns"
                >
                  <template #thead>
                    <hlx-table-head
                      :align="'center'"
                      :width="70"
                      >{{ 'S.no' }}</hlx-table-head
                    >
                    <hlx-table-head
                      v-for="(i, index) in theads"
                      :key="index"
                      :prop="i.prop"
                      :sortable="i.sortable"
                      :resizable="i.resizable"
                      :width="185"
                      @sorting_func="sorting_Data"
                      @resizewidth="resizewidthdata"
                    >
                      {{ i.label }}
                    </hlx-table-head>
                  </template>
                  <template #tbody>
                    <tr
                      v-for="(i, index) in paginatedData"
                      id=""
                      :key="index"
                    >
                      <hlx-table-cell :align="'left'"
                        ><span style="margin-left: 15px">{{
                          serialNumber(index)
                        }}</span></hlx-table-cell
                      >
                      <hlx-table-cell
                        v-for="(j, col_index) in theads"
                        :key="col_index"
                        :align="j.align"
                      >
                        <div v-if="j.prop.toLowerCase() == 'updatedat'">
                          {{ formatDate(i[j.prop]) }}
                        </div>
                        <div v-else-if="j.prop.toLowerCase() == 'name'">
                           <div class="link-name" @click="executiondiagram(i._id)">
                            {{i[j.prop]}}
                            </div> 
                        </div>
                        <div v-else-if="j.prop.toLowerCase() == 'status'">
                          <hlx-label
                            v-if="i[j.prop].toLowerCase() === 'success'"
                            class="success sm"
                          >
                            Success
                          </hlx-label>
                          <hlx-label
                            v-else
                            class="error sm"
                          >
                            Error
                          </hlx-label>
                        </div>
                        <div v-else-if="j.prop == 'executedDuration'">
                          {{ i[j.prop] + ' ms' }}
                        </div>
                        <div v-else>
                          {{ i[j.prop] || '-' }}
                        </div>
                      </hlx-table-cell>
                    </tr>
                  </template>
                </hlx-table>
                <hlx-table
                  v-else
                  :column-count="theads.length + 2"
                  :border="['table', 'header']"
                  :bold-headers="false"
                  :row-hover="true"
                  theme="grey"
                  :striped-rows="false"
                  :re-arrange-columns="re_arrange"
                  :re-arrange-heads="theads"
                  :height="10 * 40 + 42"
                  @close-rearrange="arrange"
                  @apply-rearranged-columns="applyColumns"
                >
                  <template #thead>
                    <hlx-table-head
                      :width="60"
                      :align="'left'"
                      >{{ 'S.no' }}</hlx-table-head
                    >
                    <hlx-table-head
                      v-for="(i, index) in theads"
                      :key="index"
                      :prop="i.prop"
                      :sortable="i.sortable"
                      :resizable="i.resizable"
                      style="position: sticky; top: 0px"
                      :width="i.width !== undefined ? i.width : ''"
                      :align="i.align !== undefined ? i.align : ''"
                      @sorting_func="sorting_Data"
                    >
                      {{ i.label }}
                    </hlx-table-head>
                    <!-- <hlx-table-head :width="100">
                      <span class="action-icon"
                        ><i
                          class="icon-settings-regular"
                          @click="arrange(true)"
                        ></i></span
                    ></hlx-table-head> -->
                  </template>
                  <template #tbody>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell :colspan="theads.length + 2">{{
                        'No data'
                      }}</hlx-table-cell>
                    </tr>
                  </template>
                </hlx-table>
              </div>
            </div>
          </div>
          <!-- <hlx-table
                        v-else
       :columnCount="this.theads.length + 2"
       :border="['table', 'header']"
       :boldHeaders="false"
       :rowHover="true"
       theme="grey"
       :stripedRows="false"
       :reArrangeColumns="this.re_arrange"
       :reArrangeHeads="this.theads"
       @close-rearrange="arrange"
       @apply-rearranged-columns="applyColumns"
       :height="(10 * 40) + 42"
     >
       <template #thead>
         <hlx-table-head :width="60" :align="'left'">{{ "S.no" }}</hlx-table-head>
         <hlx-table-head
           v-for="(i, index) in this.duplicateTheads"
           :key="index"
           :prop="i.prop"
           :sortable="i.sortable"
           :resizable="i.resizable"
           @sorting_func="sorting_Data"
           style="position: sticky; top: 0px"
           :width="i.width !== undefined ? i.width : ''"
           :align="i.align !== undefined ? i.align : ''"
           >
           {{ i.label }}
           </hlx-table-head
         >
         <hlx-table-head :width="100">
           <span class="action-icon"
             ><i class="icon-settings-regular" @click="arrange(true)"></i></span
         ></hlx-table-head>
       </template>
       <template #tbody>
         <tr><hlx-table-cell></hlx-table-cell></tr>
         <tr><hlx-table-cell></hlx-table-cell></tr>
         <tr><hlx-table-cell></hlx-table-cell></tr>
         <tr><hlx-table-cell></hlx-table-cell></tr>
         <tr>
           <hlx-table-cell :colspan="this.theads.length + 2">{{ 'No data' }}</hlx-table-cell>
         </tr>
         </template>
               </hlx-table> -->
        </div>

        <div class="layout-footer-fixed" style="background-color:white;height:7%">
          <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
            :total="tableData.length"
            rows-per-page
            enable-rows-per-page
            :rows-per-page-list="[5, 10, 15, 20]"
            :options="{
              attributes: {
                rowsPerPageTitle: true,
                rowsPerPageResult: true,
                rowsPerPageDropdown: {
                  position: {
                    top: -95,
                    right: 1,
                  },
                },
              },
            }"
            @current-page="currentPage"
            @updated:rows-per-page="changeRowsPerPage"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { DMNAxiosInstance } from '@/config/axiosConfig';
import VueApexCharts from 'vue3-apexcharts';
import depTree from '../../components/dependencyTreeComponent.vue'


export default {
  components: { VueApexCharts, depTree },
  data() {
    return {
      showDetails:false,
      externalRowsPerPage:10,
      audit_id:'',
      show_top: false,
      re_arrange: false,
      currPage: 1,
      rowsPerPage: 5,
      barchart: {
        series: [
          {
            name: 'Metric1',
            data: [44, 55, 41, 67, 15, 49, 43, 50],
          },
          {
            name: 'Metric2',
            data: [13, 23, 20, 8, 39, 12, 23, 34],
          },
        ],
        grid: {
          borderColor: '#f7f7f7',
          // padding:{
          //   top:-20,

          // },
        },

        chart: {
          type: 'bar',
          height: '100%',
          width: '100%',
          stacked: true,
          stackType: '10%',
          toolbar: {
            show: false,
          },
        },
        colors: ['#54bd95', '#D8D8D8'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '35%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
        fill: {
          opacity: 1,
        },
        legend: { show: false },
      },
      card_data: [
        { card: 'card1' },
        { card: 'card2' },
        { card: 'card3' },
        { card: 'card4' },
      ],
      items: [
        { label: 'Settings' },
        { label: 'Rules' },
        { label: 'Execution logs' },
      ],
      nav_items: [
        {
          name: 'Home',
          label: 'Home',
          icon: 'icon-grid-web-7-regular',
          description_en: 'Home',
          description_fn: '*Home',
        },
        {
          name: 'Business rule',
          label: 'Business rule ',
          icon: 'icon-code-regular',
          description_en: 'Residential business market ',
          description_fr: '*Residential business market ',
        },
        {
          name: 'Schema',
          label: 'Schema',
          icon: 'icon-desktop-code-regular',
          description_en: 'Small business markets',
          description_fr: '*Small business markets',
        },
        {
          name: 'Database',
          label: 'Database',
          icon: 'icon-database-regular',
          description_en: 'Small business markets',
          description_fr: '*Small business markets',
        },
        {
          name: 'Execute',
          label: 'Execute',
          icon: 'icon-file-settings-regular',
          description_en: 'Small business markets',
          description_fr: '*Small business markets',
        },
        {
          name: 'Audit',
          label: 'Audit',
          icon: 'icon-notebook-regular',
          description_en: 'Audit log',
          description_fr: '*Audit log',
          active: true,
        },

        {
          name: 'Help',
          label: 'Help',
          icon: 'icon-question-circle-regular',
          description_en: 'Setting',
          description_fr: '*Setting',
          separator: true,
        },
        {
          name: 'settings',
          label: 'settings',
          icon: 'icon-settings-regular',
          description_en: 'Setting',
          description_fr: '*Setting',
        },
        {
          name: 'logout',
          label: 'Logout',
          icon: 'icon-log-out-regular',
          description_en: 'Logout',
          description_fr: '*Logout',
        },
      ],
      piechart: {
        series: [100, 0],
        chart: {
          width: '100%',
          height: '40%',
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        colors: ['#54bd95', '#f8a523'],
        labels: ['Rules passed', 'Rules failed'],
        dataLabels: {
          enabled: false,
        },

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 20,
                height: 'auto',
              },
              legend: {
                show: true,
              },
            },
          },
        ],
        legend: {
          position: 'bottom',
          offsetY: -0,
          height: 40,
          show: true,
          verticalAlign: 'center',
        },
        content: {
          title: 'Total sales',
          total: '$85K',
        },
      },
      theads: [
        {
          name: 'name',
          checked: true,
          id: 1,
          disabled: false,
          prop: 'name',
          label: 'Rule name',
          sortable: false,
          resizable: false,
          align: 'left',
        },
        {
          name: 'Timestamp',
          checked: true,
          id: 1,
          disabled: false,
          prop: 'updatedAt',
          label: 'Timestamp',
          sortable: false,
          resizable: false,
          align: 'left',
        },
        {
          name: 'Duration',
          checked: true,
          id: 2,
          disabled: false,
          prop: 'executedDuration',
          label: 'Duration',
          sortable: false,
          resizable: false,
          align: 'left',
        },
        {
          name: 'Status',
          checked: true,
          id: 3,
          disabled: false,
          prop: 'status',
          label: 'Status',
          sortable: false,
          resizable: false,
          align: 'center',
        },
        {
          name: 'Message',
          checked: true,
          id: 3,
          disabled: false,
          prop: 'message',
          label: 'Message',
          sortable: false,
          resizable: false,
          align: 'left',
        },
      ],
      headvalue: [
        { prop: 'name', label: 'Name', sortable: true, resizable: true },
        { prop: 'cartoon', label: 'Cartoon', sortable: true, resizable: true },
        { prop: 'status', label: 'Status', sortable: true, resizable: true },
      ],
      tableData: [],
      minimumExecDuration: 0,
      averageExecDuration: 0,
      maximumExecDuration: 0,
      duplicatetabledata: [],
      searchtabledata: [],
      searchvalue: '',
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.tableData.length;
    },
  },
  async mounted() {
    await DMNAxiosInstance.get(`rule_engine/metrics/rules_count`).then(
      (res) => {
        
        this.minimumExecDuration = res.data.executionDuration.minimumDuration;
        this.maximumExecDuration = res.data.executionDuration.maximumDuration;
        this.averageExecDuration = res.data.executionDuration.averageDuration;
        this.piechart.series = [];
        const strings = Object.values(res.data.executionStatus);
        const numbers = strings.map((str) => parseFloat(str));
        this.piechart.series = numbers;
      }
    );

    await DMNAxiosInstance.get(`execution_logs`).then((res) => {
      
      this.tableData = res.data.data;
      // 
      // this.audit_id = res.data.data._id

    });
    this.tableData = this.tableData.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );
    this.searchtabledata = this.tableData;
    this.duplicatetabledata = this.tableData;
  },
  methods: {
    closeTop()
    {
       this.show_top = false
    },
    async executiondiagram(id)
    {
      console.log("executiondiagram 696",id)
      this.audit_id = id
      this.show_top = true
      this.showDetails=true
        
    },
    search(val) {
      this.searchvalue = val;
      // 
      this.searchvalue = val;
      if (this.searchvalue && this.searchvalue != '') {
        this.tableData = this.searchtabledata.filter((item) => {
          if (
            item.updatedAt != undefined &&
            item.executedDuration != undefined &&
            item.name != undefined &&
            item.status != undefined &&
            this.searchvalue != undefined &&
            item.message != undefined
          ) {
            return (
              item.name
                .toLowerCase()
                .toString()
                .includes(this.searchvalue.toLowerCase()) ||
              item.updatedAt
                .toLowerCase()
                .toString()
                .includes(this.searchvalue.toLowerCase()) ||
              item.executedDuration
                .toString()
                .includes(this.searchvalue.toLowerCase()) ||
              item.status
                .toLowerCase()
                .toString()
                .includes(this.searchvalue.toLowerCase()) ||
              item.message
                .toLowerCase()
                .toString()
                .includes(this.searchvalue.toLowerCase())
            );
          }
        });
        this.duplicatetabledata = this.tableData;
      } else {
        this.tableData = this.searchtabledata;
        this.duplicatetabledata = this.searchtabledata;
      }
    },
    arrange(val) {
      
      this.re_arrange = val;
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    currentPage(val) {
      this.currPage = val;
    },
    changeRowsPerPage(count) {
      
      this.rowsPerPage = count;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };
      return new Intl.DateTimeFormat('en-US', options)
        .format(date)
        .replace(',', '');
    },
    navTo(active) {
      this.$store.commit('token', this.$route.params.token);
      // 
      if (active.name == 'Home') {
        this.navHome();
      } else if (active.name == 'logout') {
        this.logout();
      } else if (active.name == 'settings') {
        this.setting();
      } else if (active.name == 'Schema') {
        this.schema();
      } else if (active.name == 'Business rule') {
        this.ruled();
      } else if (active.name === 'Database') {
        this.execute();
      } else if (active.name == 'Help') {
        this.help();
      } else if (active.name == 'Execute') {
        this.exe();
      } else {
        // 
      }
    },
    ruled() {
      this.$router.push(`/DMN/business_rules`);
    },
    exe() {
      this.$router.push(`/DMN/rule_validation`);
    },
    execute() {
      this.$router.push(`/DMN/reference_data`);
    },
    schema() {
      this.$router.push(`/DMN/schemas`);
    },

    // logout() {
    //   this.$router.push('/')

    // },
    setting() {
      this.$router.push(`/settings`);
    },
    async navHome() {
      this.$router.push(`/DMN/dashboard`);
    },
  },
};
</script>

<style></style>
