<template>
  <hlx-alert-notification :notify="notification" :auto-close="true" />




  
<!-- delete -->
<hlx-modal
        style="z-index: 999999999"
        :modal-active="delete_modal_active"
        :height="'220px'"
        :width="'400px'"
        :modal="true"
        @close="delete_modal_active = false"
      >
        <template #header>
          <div style="margin-left: 0px">Delete region</div>
        </template>
        <template #content>
          <div class="modal-content">
            <p>
              Are you sure you want to delete <b>{{ delete_name }}</b
              >?
            </p>
          </div>
        </template>
        <template #footer>
          <hlx-button class="secondary sm" @click="delete_modal_active = false"
            >Cancel</hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 16px"
            @click="deleteExecRow"
            >Delete</hlx-button
          >
        </template>
      </hlx-modal>








  <section style="margin: 10px 15px">







    
  <!-- add exclisive drawer -->
  <div class="add-contact-drawer">
    <hlx-drawer
    :key="componentkey"
      :show="showDrawer"
      position="right"
      width="400"
      :footer="true"
      :title="'Edit notes'"
      :show-close-icon="true"
      @close="closeDrawer"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          
          <b v-if="editMode == false">
             Add product
            </b>
          <b v-else>
            Edit product
            </b>
        </div>
        <div class="body-area-contact-drawer">
  
            <div class="decision-name-wrapper" style="margin-bottom:20px;">
              <hlx-select
            v-model:value="regionProduct"
            :required="true"
            :options="dropdown_data_regioncode"
            :pre-value="regionProduct"
            :placeholder-value="'Region name'"
            :label-animation="true"
            :label="'name'"
            :prop-value="'name'"
            :clearable="true"
            @selected-value="emitedregiongroup"
          >
          </hlx-select>
            </div>


            <div class="decision-name-wrapper" style="margin-bottom:20px;">
              <searchfilterComponent
            :key="updateKey"
            :dropdown-width="408"
            :module="'productoffering'"
            :filter-search-options="mainInfoServiceQualification"
            :filter-data="searchFilterData"
            :filter-checked-data="layout"
            :key-value-from-query-array="'label'"
            :filter-value-object-name="'name'"
            @removed-value="getRemovedValue"
            @searched-value="getSearchedValueNewDiscount"
            @checked-value="getcheckedValueNewDiscount"
          >
          </searchfilterComponent>
            </div>

        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeDrawer"
            >
            Cancel
            </hlx-button
          >
          <hlx-button
            v-if="editMode == false"
            class="primary sm"
            style="margin-left: 10px"
            @click="addExcluded"
            >
            <!-- :disabled="!enableAdd" -->
            Add
            </hlx-button
          >
          <hlx-button
            v-else
            class="primary sm"
            style="margin-left: 10px"
            @click="addExcluded"
            >
            Save
            </hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>






   
 <!-- header -->
 <section
     v-if="tableData && tableData?.length > 0 || searchvalue !== ''"
     class="right-view-body-feature">
     <span class="spec-feature --table-tool-bar" style="margin: 10px 0">
       <div class="--table-tool-bar-wrapper">

       <!-- search -->
         <span class="util-items search"
           ><hlx-search
             v-model="searchvalue"
             @search-key="searchKey"
           ></hlx-search
         ></span>

         <!-- Filter -->
            <hlx-button class="secondary sm add-btn" @click="showDrawerRight">
             <i class="icon-filter-regular"></i>
           </hlx-button>


           <span>
             <checkavailability></checkavailability>
           </span>


         <hlx-button
             class="primary sm"
             @click="openDrawer"
           >
             <i class="icon-plus-circle-regular"></i> Add product</hlx-button
           >

       </div>
     </span>
   </section>






     <!-- table -->
 <section v-if="tableData && tableData?.length > 0">
<hlx-table
 :key="refreshTable1"
 :column-count="tableHeads + 2"
 :border="['table', 'header', 'horizontal']"
 :theme="'grey'"
 :bold-headers="false"
 :row-hover="true"
 :striped-rows="false"
 :re-arrange-columns="re_arrange"
 :re-arrange-heads="theads"
 @close-rearrange="arrange"
 @apply-rearranged-columns="applyColumns"
>
 <template #thead>
   <hlx-table-head
     :width="60"
     :align="'left'"
     style="padding: 0 15px !important; max-width: 60px !important"
     :fixed="'left'"
     >{{ "S.no" }}</hlx-table-head
   >
   <hlx-table-head
     v-for="(i, index) in tableHeads"
     :key="index"
     :prop="i.prop"
     :width="i.width"
     :align="'left'"
     :resizable="true"
   >
     {{ i.label }}
   </hlx-table-head>
   <hlx-table-head :align="'center'" :width="60" :fixed="'right'"
     ><span class="action-icon">
       <i
         class="icon-settings-regular"
         style="cursor: default"
       ></i> </span
   ></hlx-table-head>
 </template>
 <template #tbody>
   <tr v-for="(i, index) in tableDataOnSelectedRows" :key="index">
     <hlx-table-cell :width="60" fixed="left">
       {{ serialNumber(index) }}
     </hlx-table-cell>
     <hlx-table-cell
       v-for="(j, col_index) in tableHeads"
       :key="col_index"
       :align="'left'"
       :width="i.width"
     >

     <div
                  v-if="j.prop === 'availableOffer'"
                  style="width: 400px"
                >
                  <div style="width: 310px">
                    <hlx-tagcount
                      :arrayfile="i?.productRelationship"
                      :item-key="'name'"
                      :type="'label'"
                    ></hlx-tagcount>
                  </div>
                </div>

                <div
                  v-else-if="j.prop === 'region'"
                  style="padding-left: 1px; cursor: pointer; color: #2879b7"
                  onmouseover="this.style.textDecoration='underline';"
                  onmouseout="this.style.textDecoration='none';"
                  @click="callDirectEdit(i, index)"
                >
                  <!-- {{ i[j.prop] }} -->
                  <div v-for="(iv, ind) in i?.place" :key="ind">
                    {{ iv?.regionName }}
                  </div>

                  <div>

                  </div>
                </div>



     </hlx-table-cell>
     <hlx-table-cell :align="'center'" :width="120" :fixed="'right'">
       <span :id="'table-context' + index" class="action-icon">
         <i
           style="position: relative"
           class="icon-more-vertical-filled"
           :class="i.context === true ? 'active-action' : ''"
           @click="crudContextMenu($event, i, index)"
         >
           <hlx-context-menu
             :top="containertop"
             :left="containerleft"
             :conditionvalue="150"
             :conditiondown="2"
             :conditionup="-38"
             :options="computedTableAction(i)"
             :data="index.toString()"
             :show="i.context == true"
             @chosen="closeCrudContextMenu($event, i, index)"
           />
         </i>
       </span>
     </hlx-table-cell>
   </tr>
 </template>
</hlx-table>
</section>


<!-- no data -->
<section v-if="tableData.length === 0 && searchvalue !== ''">
<hlx-table
   :column-count="tableHeads.length + 2"
   :border="['table', 'header']"
   :bold-headers="false"
   :row-hover="true"
   theme="grey"
   :striped-rows="false"
   :re-arrange-columns="toggleRearrangeModal"
   :re-arrange-heads="tableHeadsRearranging"
   @close-rearrange="toggleRearrangeModal = false"
   @apply-rearranged-columns="applyColumns"
 >
   <template #thead>
     <hlx-table-head :width="60" :align="'left'"   @new-resize-width="resizedWidth">{{
       "S.no"
     }}</hlx-table-head>
     <hlx-table-head
       v-for="(i, index) in tableHeads"
       :key="index"
       :prop="i.prop"
       :sortable="i.sortable"
       :resizable="i.resizable"
       style="position: sticky; top: 0px"
       :width="i.width !== undefined ? i.width : ''"
       :align="i.align !== undefined ? i.align : ''"
       @sorting_func="sorting_Data"
         @new-resize-width="resizedWidth"
     >
       {{ i.label }}
     </hlx-table-head>
     <hlx-table-head v-show="supplierStatus == false" :width="100">
       <span class="action-icon"
         ><i
           class="icon-settings-regular"
           @click="toggleRearrangeModal = true"
             @new-resize-width="resizedWidth"
         ></i></span
     ></hlx-table-head>
   </template>
   <template #tbody>
     <tr>
       <hlx-table-cell></hlx-table-cell>
     </tr>
     <tr>
       <hlx-table-cell></hlx-table-cell>
     </tr>
     <tr>
       <hlx-table-cell></hlx-table-cell>
     </tr>
     <tr>
       <hlx-table-cell></hlx-table-cell>
     </tr>
     <tr>
       <hlx-table-cell :colspan="tableHeads?.length + 2">{{
         "No data"
       }}</hlx-table-cell>
     </tr>
     <tr>
       <hlx-table-cell></hlx-table-cell>
     </tr>
     <tr>
       <hlx-table-cell></hlx-table-cell>
     </tr>
     <tr>
       <hlx-table-cell></hlx-table-cell>
     </tr>
     <tr>
       <hlx-table-cell></hlx-table-cell>
     </tr>
     <tr>
       <hlx-table-cell></hlx-table-cell>
     </tr>
   </template>
 </hlx-table>
</section>


       <!-- image -->
       <section
        v-if="tableData && tableData?.length == 0 && searchvalue === ''"
       style="
         margin-top: 1.2rem;
         display: flex;
         flex-direction: column;
         gap: 1.2rem;
         align-items: center;
       "
     >

     <span>
         <img
           src="@/assets/images/svg/rafiki.svg"
           alt=""
           style="width: 500px; height: 500px"
         />
       </span>
       <span class="util-items util-items-add">
         <hlx-button
           class="primary media add-btn"
             @click="openDrawer"
           ><i class="icon-plus-circle-regular" style="margin-right: 5px"></i
           >Add product</hlx-button
         >
       </span>
       
   
   </section>






 </section>
</template>

<script>
import checkavailability from "@/components/Product/ServiceQualification/checkAvailability.vue"
import searchfilterComponent from "@/components/SearchAndFilterComponent/searchAndFilter.vue";
import {
//   DMNAxiosInstance,
 // MLTAxiosInstance,
 UPCAxiosInstance,
 // UPCAxiosInstance
} from "@/config/axiosConfig";
export default {
  components: {
    searchfilterComponent,
    checkavailability
  },
   data() {
   return {


    
    editPayload: null,

         
     tableData:[],

     
     tableHeads: [
        {
          name: "Region",
          checked: true,
          sortable: false,
          resizable: true,
          disabled: true,
          // width: 850,
          label: "Region",
          prop: "region",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          name: "Products",
          checked: true,
          disabled: true,
          // width: 400,
          label: "Products",
          prop: "availableOffer",
          align: "left",
        },
      ],



     notification: "",


     
           
     filtertaglength: 0,

           
filterQueryforBill: {
       type: "filter",
       module: "productQualification",
       isPaginated: true,
       paginatedQuery: {
         rowsPerPage: this.rowsPerPage,
         currentPage: this.currPage,
       },
       isNeedToBeSort: false,
       sortFieldAndOrder: {},
       limit: 10,
               filterQuery: {},
       searchQuery: "",
       company: this.$cookies.get("userName"),
     },

     
currPage: 1,
rowsPerPage: 10,
     externalRowsPerPage: 10,

     searchvalue: "",

     componentkey: 0,

     showDrawer: false,

     editMode: false,
     
     regionProduct: '',
     
     mainInfoServiceQualification: [],
     
     searchFilterData: {},

     layout: [
        {
          prop: "lifecycleStatus",
          label: "Status",
          type: "String",
          fieldToFilter: "lifecycleStatus",
          fieldToSearch: "lifecycleStatus",
        },
        {
          prop: "category",
          label: "Category",
          type: "Array",
          fieldToFilter: "category",
        },
        {
          prop: "productOfferingPrice",
          label: "price",
          type: "Array",
          fieldToFilter: "productOfferingPrice.name",
          fieldToSearch:
            "productOfferingPrice[0].productofferPriceAlteration[0].productprice.taxAmount.Amount",
        },
     ],

     productData: [],

     regionData: {},

     delete_modal_active: false,

     deleteobj: {},



       }
  },

   
  computed: {
    tableDataOnSelectedRows(){
      // const start = (this.currPage - 1) * this.rowsPerPage;
      // const end = start + this.rowsPerPage;
      return this.tableData;
    }
  },



 async mounted() {
     
   this.ticketdataCalling(this.currPage, this.rowsPerPage);

   this.dropdown_data_regioncode = await this.parentRegionList();

   const [searchFilterData] = await Promise.all([this.supplierFilterData()]);

      this.searchFilterData = searchFilterData;


 },

  methods: {

    crudContextMenu($event, i, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 102;

        this.containertop =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().top + 10;  
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },


    leaf(text) {
      console.log("leaf category text full", text);
      console.log("leaf category text length", text?.length);
      console.log("leaf category text typeof", typeof text);
      let lengthOfText = text?.length;
      if (typeof text === "string") {
        return text;
      }
      if (lengthOfText === 1) {
        console.log("leaf category text length final o/p ", text);
        return (text = text.toString());
      }
      if (text && lengthOfText > 1) {
        const total = text?.length;
        console.log(
          text,
          "leaf category text length final o/p",
          text[total - 1]
        );

        return text[total - 1];
      }
    },

    getMinMax(arr) {
      if (!arr || arr.length === 0) {
        return this.currencyFormatter(0, {
          currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
        });
      }

      let values = arr.map((item) => item?.value);
      let min = Math.min(...values);
      let max = Math.max(...values);
      min = min !== undefined && min !== null && !isNaN(min) ? min : 0;
      max = max !== undefined && max !== null && !isNaN(max) ? max : 0;
      if (min === max) {
        if (min == 0) {
          return this.currencyFormatter(0, {
            currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          });
        }
        return this.currencyFormatter(min, { currency: arr[0]?.unit });
        // return `  ${min} ${arr[0]?.unit}`;
      } else {
        if (min == 0) {
          return `${this.currencyFormatter(0, {
            currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          })} - ${this.currencyFormatter(max, { currency: arr[0]?.unit })}`;
        }
        if (max == 0) {
          return `${this.currencyFormatter(min, {
            currency: arr[0]?.unit,
          })}-${this.currencyFormatter(0, {
            currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          })}`;
        }
        return `${this.currencyFormatter(min, {
          currency: arr[0]?.unit,
        })} - ${this.currencyFormatter(max, { currency: arr[0]?.unit })}`;
      }
    },

    async getSearchedValueNewDiscount(value) {
      console.log("value form offer", value);
      try {
        const filterPostQuery = await UPCAxiosInstance.post(
          `util/filterData`,
          value
        );

        const responseData = filterPostQuery.data.data;
        // console.log(responseData,"responseData")
        // console.log(responseData,"responseData 2")
        this.mainInfoServiceQualification = responseData.map(
          ({
            name,
            productOfferingPrice,
            prodSpecCharValue,
            id,
            category,
            productSpecification,
          }) => {
            const productType = productSpecification["@baseType"];
            const categoryType = this.leaf(category ? category : "");
            let price = "0";
            let unit;

            if (productOfferingPrice?.length > 0) {
              // const productPrice = productOfferingPrice[0]?.tiers;
              const updatedProductPrice = productOfferingPrice;
              if (updatedProductPrice) {
                let priceRange = updatedProductPrice
                  ?.map((e) => e?.tiers?.map((tier) => tier?.price))
                  .flat(Infinity);
                price = this.getMinMax(priceRange);
                unit = priceRange[0]?.unit;
              }
              price =
                price !== undefined || price !== null || !isNaN(price)
                  ? price
                  : 0;

              unit = unit !== undefined || unit !== null ? unit : unit;
              console.log(price, "prodSpecCharValue", unit);
              return {
                name: name,
                price:
                  prodSpecCharValue[0]?.billingPeriod !== undefined &&
                  prodSpecCharValue[0]?.billingPeriod !== null
                    ? productType === "Tangible"
                      ? price
                      : price + ` / ${prodSpecCharValue[0]?.billingPeriod}`
                    : price + ` / ${""}`,
                _id: id,
                category: categoryType,
              };
            } else {
              return {
                name: name,
                price:
                  prodSpecCharValue[0]?.billingPeriod !== undefined &&
                  prodSpecCharValue[0]?.billingPeriod !== null
                    ? productType === "Tangible"
                      ? this.currencyFormatter(0)
                      : this.currencyFormatter(0) +
                        ` / ${prodSpecCharValue[0]?.billingPeriod}`
                    : this.currencyFormatter(0) + ` / ${""}`,
                _id: id,
                category: categoryType,
              };
            }
          }
        );
      } catch {
        console.log("error from getsearchedvalue");
      }
    },

    async getcheckedValueNewDiscount(value) {
      this.productData = [];
      console.log("getcheckedValueNewDiscount", value);
      this.productData = value;
    },

    callDirectEdit(i, index) {
      console.log("i, index", i, index, this.selectedSwitch, this.editMode);

      this.regionProduct = i.place[0].regionName;

      this.regionData = i.place[0];
      this.productData = i.productRelationship;

      i?.productRelationship?.forEach((checkedItem) => {
            const matchingItem = this.mainInfoServiceQualification?.find(
              (item) => item?._id === checkedItem?._id
            );
            if (matchingItem) {
              matchingItem.checked = true;
            }
          });





      this.editMode = true;
      this.editpayload = i;
      this.openDrawer(i, index);
    },

      
    computedTableAction() {
      return [
        // {
        //   label: "Edit",
        //   icon: "icon-file-text-edit-regular",
        // },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        }
      ];
    },



    
    closeCrudContextMenu($event, payload, i) {
      payload, i
      if ($event.toLowerCase() === "edit") {
        // this.editMode = true;
        // const payload = JSON.parse(JSON.stringify(this.tableData[i]));
        // this.intialval = JSON.parse(JSON.stringify(this.tableData[i]));
        // this.initialServiceQualificationProduct = payload.productRelationship;
        // this.openuploaddrawer1(payload, i);
        // this.editpayload = payload;
        // console.log(this.editpayload, payload, i, "jjjjjjjjjjjjjjjjjjjjjj");
      } else if ($event.toLowerCase() === "delete") {
        this.delete_modal_active = true;
        this.deleteobj = payload;
        // this.delete_modal_active = true;
        // this.deleteobj = payload;
        // console.log("qqqqqqqqqqqqq", payload, this.deleteobj?._id);
        // this.delete_name = payload?.name;
      }
    },


    
    async deleteExecRow() { 
      
      this.delete_modal_active = false;

      const deleteresponse = await UPCAxiosInstance.delete(
          `/productQualification/${this.deleteobj.id}`
      );

      if (deleteresponse) {
        this.notification = {
            type: "success",
            message: `Product deleted for Service Qualification successfully!`,
          };
      }
      this.ticketdataCalling(this.currPage, this.rowsPerPage);
    },





    emitedregiongroup(val) {
      console.log('emitedregiongroup:', val);
      this.regionProduct = val.name;
      val.value;

      let obj = {
        '@type': 'regionData',
        id: val.id,
        regionName: val.name,
        characteristic: val.value
      }
      this.regionData = obj;
     },



    async addExcluded() {
      this.productData
      this.regionData

      let val = {
        productRelationship: this.productData,
        place: this.regionData
      }
       
      if (this.editMode) {
        const offerpost = await UPCAxiosInstance.patch(
        `/productQualification`,
        {
          id: this.editpayload?.id,
          ...val,
        }
      );
        if (offerpost) {
          this.notification = {
          type: "success",
          message: `Product edited for Service Qualification successfully!`,
          };
          this.closeDrawer()
      }
      }

      else {
        const offerpost = await UPCAxiosInstance.post(
        `productQualification`,
        val
        );
        if (offerpost) {
          this.notification = {
          type: "success",
          message: `Product added for Service Qualification successfully!`,
          };
          this.closeDrawer()
      }
      }

      this.ticketdataCalling(this.currPage, this.rowsPerPage);

    },



    openDrawer() {
            this.showDrawer = true;
            this.componentkey += 1;
    },

    closeDrawer() { 
      this.editMode = false;
            this.showDrawer = false;
          this.componentkey += 1;
          this.clearDrawer();

    },

    clearDrawer() {

      this.mainInfoServiceQualification.forEach((val) => {
        val.checked = false;
      });

      this.regionProduct = '';
      this.productData = null
      this.regionData = null

    },


   
   async ticketdataCalling(currentPage, rowsPerPage) {
 currentPage, rowsPerPage
 console.log("ticketdataCalling...123",currentPage, rowsPerPage);
     this.filterQueryforBill["company"] = 'developerHalleyx';
       // this.$cookies.get('company');
     this.filterQueryforBill["paginatedQuery"]["currentPage"] = currentPage;
     this.filterQueryforBill["paginatedQuery"]["rowsPerPage"] = rowsPerPage;




     this.filterQueryforBill.module = 'productQualification';
     const filterResultData = await UPCAxiosInstance.post(
       `util/filterData`,
       this.filterQueryforBill
     );
     if (
       filterResultData.data.data != undefined &&
       filterResultData.data.total != 0
     ) {
       this.tableData = filterResultData.data.data;
       this.totalpageno = filterResultData.data?.total;
       console.log("ticketdataCalling...",this.tableData ,filterResultData.data.data);
       
       // this.totalvalue = filterResultData.data.total;      
     }
   },



   serialNumber(index) {
     return (this.currPage - 1) * this.rowsPerPage + index + 1;
       },


       
       async searchKey(val) {
     console.log("searchKey",val);
     
     this.searchvalue = val;
     this.filterQueryforBill["company"] = this.$cookies.get("company");
     this.filterQueryforBill["type"] = "filter-search"
|| "search";
     this.filterQueryforBill["searchQuery"] = val;
     this.filterQueryforBill.searchList =
      [
     {
         "streetAddressLine.characteristic.type": {
           $regex: val,
           $options: "i",
         },
         },
       
     {
         "streetAddressLine.characteristic.description": {
           $regex: val,
           $options: "i",
         },
       },
   //     // {
   //     //   "state": {
   //     //     $regex: val.toLowerCase() === "on hold" ? "onhold" : val,
   //     //     $options: "i",
   //     //   },
   //     // },
   //     {
   //       "definition.name": {
   //         $regex: val,
   //         $options: "i",
   //       },
   //     },
   //     {
   //       "tag": {
   //         $regex: val,
   //         $options: "i",
   //       },
   //     },
     ];
     // this.currPage = 1;
     // this.rowsPerPage = 10;
     if (this.filtertagvalue?.length != 0) {
       this.filterQueryforBill["type"] = "filter-search";
     }
     this.ticketdataCalling(this.currPage, this.rowsPerPage);
   },

   

   
   async quotesfilterData(filterSelectedvalue, filterQuery) {
     console.log("quotesfilterData::))", filterSelectedvalue, filterQuery);
     this.filtertaglength = filterSelectedvalue?.length;
     this.filterQueryforBill = filterQuery;

     const filterResultData = await UPCAxiosInstance.post(
       `util/filterData`,
       this.filterQueryforBill
     );
     if (
       filterResultData.data.data != undefined &&
       filterResultData.data.total != 0
     ) {
       this.tableData = filterResultData.data.data;
       this.totalpageno = filterResultData.data?.total;
       console.log("ticketdataCalling...",this.tableData ,filterResultData.data.data);
       
       // this.totalvalue = filterResultData.data.total;      
     }
     
       },


        
   async currentPage(val) {
     if (this.companyasignee != "") {
       this.currPage = val;
       this.ticketdataCalling(
         this.currPage,
       this.rowsPerPage
       );
       this.refreshTable();
     }
   },


 }

}
</script>

<style>

</style>