<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />

  <section style="display: flex; flex-direction: column; height: 100vh">
    <main
      class="decision-add-container --layout-3-way"
      :style="
        isRightLayoutOpened
          ? 'display: grid;grid-template-columns: 200px calc(100% - 600px) 300px'
          : ''
      "
      style="height: 100vh"
    >
      <!-- --------------------------------------------------------------------Left pannel-------------------------------------------------- -->
      <section
        class="cm-fields-left-panel responsiveSM"
        style="width: 220px; background-color: #fff; overflow: hidden"
      >
        <!-- Breadcrumb -->
        <div class="layout-breadcrumb">
          <hlx-breadcrumb
            v-if="view === true"
            :items="getBreadcrumbs"
            @path="goTo()"
          />
          <hlx-breadcrumb
            v-if="view === false"
            :items="getBreadcrumbs"
            @path="goTo()"
          />
        </div>
        <div style="margin-top: 25px">
          <leftpannelComponent
            :side-bar-data="sideBarData"
            @value-name="valueleft"
          ></leftpannelComponent>
        </div>
      </section>
      <!-- <hlx-drawer
        :title="'Filter panel'"
        :show="show_left"
        :width="300"
        position="left"
        :footer="false"
        :show-close-icon="true"
        @close="show_left = false"
      >
        <template #body>
          <leftpannelComponent
            style="margin-top: 19px; margin-left: 80px"
            :side-bar-data="sideBarData"
            @value-name="valueleft"
          ></leftpannelComponent>
        </template>
      </hlx-drawer> -->
      <section
        class="--layout-inner-right-portion --RL70 responsiveSM-right-portion"
        style="overflow: hidden"
        :style="'width:100%'"
      >
        <div class="responsive-breadcrumbs-sm">
          <hlx-breadcrumb :items="getBreadcrumbs" @path="goTo()" />
        </div>
        <div>
          <div v-if="leftpannel == 'Overview' || leftpannel == ''">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
              "
            >
              <div style="display: flex; align-items: center; gap: 16px">
                <span class="leaftpanelForresponsive-sm">
                  <leftpannelComponent
                    :side-bar-data="sideBarData"
                    @value-name="valueleft"
                  ></leftpannelComponent>
                </span>
                <!-- <span class="responsive-span-sm"
                  ><i
                    class="icon-menu-regular responsive-menuicon-sm"
                    @click="show_left = true"
                  ></i
                ></span> -->
                <span style="">
                  <hlx-file-upload
                    :file-type="'.jpg,.jpeg,.png'"
                    :file-size-limit="'20mb'"
                    :uploadtype="'Rectangle'"
                    :style-background="backgrounStyleupload"
                    :height="50"
                    :width="50"
                    :read-only="defalt"
                    :custom-icon="icon"
                    :type="'profile-upload-preview'"
                    :name-display="payload.customName"
                    :content="'Import'"
                    :profile-pic-preview="profilePicUrl"
                  />
                </span>

                <div style="display: flex; flex-direction: column; gap: 8px">
                  <div style="display: flex; align-items: center; gap: 8px">
                    <span style="font-weight: bold">{{
                      payload.customName
                    }}</span>
                    <hlx-label
                      v-if="
                        payload.status.length > 0 && payload.status === 'Active'
                      "
                      class="primary sm"
                      >{{ payload.status }}</hlx-label
                    >
                    <hlx-label
                      v-if="
                        payload.status.length > 0 &&
                        payload.status === 'Inactive'
                      "
                      class="error sm"
                      >{{ payload.status }}</hlx-label
                    >
                  </div>
                  <div class="address-content-sm">
                    {{ payload.contactMedium[3].characteristic.description }}
                  </div>
                </div>
              </div>

              <div
                v-if="view && selectedValue.toLowerCase() === 'overview'"
                class="go-back"
                style="
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                "
              >
                <hlx-button
                  v-show="supplierStatus == false"
                  class="primary sm"
                  style="margin-left: 20px"
                  @click="
                    view = false;
                    editsupplierbutton = false;
                  "
                  >Edit</hlx-button
                >
              </div>
              <div
                v-if="!view && selectedValue.toLowerCase() === 'overview'"
                class="go-back"
                style="
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                "
              >
                <hlx-button class="secondary sm" @click="cancelEditsupplier"
                  >Cancel</hlx-button
                >
                <!-- {{ editsupplierbutton }} -->
                <hlx-button
                  class="primary sm"
                  style="margin-left: 20px"
                  :disabled="
                    payload.customName !== '' &&
                    validateEmail(
                      payload.contactMedium[0].characteristic.emailAddress
                    ) === true &&
                    validatePhoneNumber(
                      payload.contactMedium[1].characteristic.phoneNumber
                    ) &&
                    !isUrlEmpty
                      ? false
                      : true
                  "
                  @click="addNewSupplier"
                  >Save</hlx-button
                >
              </div>
            </div>
          </div>

          <div
            v-if="leftpannel != 'Overview' || leftpannel != ''"
            :style="{
              display:
                leftpannel !== '' && leftpannel !== 'Overview'
                  ? 'block'
                  : 'none',
            }"
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
              "
            >
            
              <div style="display: flex; align-items: center; gap: 16px">
                <span class="leaftpanelForresponsive-sm">
                  <leftpannelComponent
                    :side-bar-data="sideBarData"
                    @value-name="valueleft"
                  ></leftpannelComponent>
                </span>
                <!-- <span class="responsive-span-sm" @click="show_left = true"
                  ><i class="icon-menu-regular responsive-menuicon-sm"></i
                ></span> -->
                     <span style="">
                <hlx-file-upload
                  :key="profileComponentKey"
                  :file-type="'.jpg,.jpeg,.png'"
                  :file-size-limit="'20mb'"
                  :uploadtype="'Rectangle'"
                  :style-background="backgrounStyleupload"
                  :height="50"
                  :width="50"
                  :read-only="defalt"
                  :custom-icon="icon"
                  :type="'profile-upload-preview'"
                  :name-display="payload.customName"
                  :content="'Import'"
                  :profile-pic-preview="profilePicUrl"
                />
              </span>

                <div style="display: flex; flex-direction: column; gap: 8px">
                  <div style="display: flex; align-items: center; gap: 8px">
                    <span style="font-weight: bold">{{
                      payload.customName
                    }}</span>
                    <hlx-label
                      v-if="
                        payload.status.length > 0 && payload.status === 'Active'
                      "
                      class="primary sm"
                      >{{ payload.status }}</hlx-label
                    >
                    <hlx-label
                      v-if="
                        payload.status.length > 0 &&
                        payload.status === 'Inactive'
                      "
                      class="error sm"
                      >{{ payload.status }}</hlx-label
                    >
                  </div>
                  <div class="address-content-sm">
                    {{ payload.contactMedium[3].characteristic.description }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="decision-info-right-pannel" style="overflow: hidden">
          <div v-if="leftpannel == 'Overview' || leftpannel == ''">
            <div style="display: flex; gap: 5%; padding-top: 22px">
              <div style="display: flex; flex-direction: column; width: 50%">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;
                  "
                >
                  <span style="">
                    <hlx-file-upload
                      :key="profileComponentKey"
                      :file-type="'.jpg,.jpeg,.png'"
                      :file-size-limit="'20mb'"
                      :uploadtype="'Rectangle'"
                      :style-background="backgrounStyle"
                      :height="100"
                      :width="100"
                      :read-only="view"
                      :custom-icon="icon"
                      :type="'profile-upload'"
                      :name-display="payload.customName"
                      :content="'Import'"
                      :profile-pic-preview="profilePicUrl"
                      @native-data="profileUpload"
                      @delete-image="deleteProfilePicture"
                    />
                  </span>
                  <span style="width: 100%">
                    <div
                      class="decision-name-wrapper"
                      :style="{
                        'margin-bottom': customnameerror ? '-7.6%' : 0,
                      }"
                    >
                      <hlx-input
                        v-model:value="payload.customName"
                        :pre-val="payload.customName"
                        :label-value="'Name'"
                        :label-animation="true"
                        :required="true"
                        :read-only="view"
                        :display-error="true"
                        :custom-error="isFirstNameEmpty"
                        :custom-error-message="'Please fill the field'"
                        @focus-out="focusOutName"
                        @at-input="validateName"
                      ></hlx-input>
                    </div>
                    <!-- <div class="decision-name-wrapper"> -->
                    <!-- <hlx-select
                      :key="key"
                      :options="statusList"
                      :pre-value="payload.status"
                      :disabled="view"
                      :label="'name'"
                      :prop-value="'name'"
                      :placeholder-value="'Status'"
                      :label-animation="true"
                      :clearable="false"
                      @selected-value="statusFunc"
                    ></hlx-select> -->

                    <div style="width: 100%">
                      <hlx-status
                        :type="'select-status'"
                        :status-option="filteredstatus"
                        :prevalue="payload.status"
                        :readonly="view"
                        @emit-select="emitStatus1"
                      >
                      </hlx-status>
                    </div>

                    <!-- </div> -->
                  </span>
                </div>
                <!-- <div
                  class="decision-name-wrapper"
                  style="margin: 15px 0 29px 0"
                >
                  <hlx-input
                    v-model:value="payload.description"
                    :pre-val="payload.description"
                    type="textarea"
                    :label-value="'Description'"
                    :read-only="view"
                    :label-animation="true"
                    :required="false"
                    :display-error="false"
                    :rows="5"
                    @focus-out="focusOutName"
                  ></hlx-input>
                </div> -->

                <div
                  class="decision-name-wrapper"
                  style="margin: 28px 0 27px 0px"
                >
                  <addressComponent
                    :pre-val="addressPreVal"
                    :view="view"
                    @address-data="receivedAddressData"
                  ></addressComponent>
                </div>

                <div class="decision-name-wrapper">
                  <hlx-input
                    v-model:value="payload.externalReference[1].leadTime"
                    :pre-val="payload?.externalReference[1]?.leadTime"
                    :label-animation="true"
                    label-value="Lead time"
                    type="mixed-number"
                    :required="true"
                    :read-only="view"
                    :display-error="true"
                    :custom-error="isNameEmpty"
                    :custom-error-message="'Please fill the field'"
                    :suffix="false"
                    :prefix="false"
                    :suffixselect="true"
                    @focus-out="focusOutName"
                    @at-input="validateName"
                  >
                    <template #suffix-select>
                      <hlx-select
                        v-model:value="payload.externalReference[1].leadPeriod"
                        :pre-value="payload?.externalReference[1]?.leadPeriod"
                        :options="leadTimeOption"
                        :read-only="view"
                        :inline-search="true"
                        :border="false"
                        :placeholder-value="'days'"
                        :label-animation="false"
                        :label="'Name'"
                        :prop-value="'Name'"
                        :background-color="'#f9fafb'"
                        @inline-select-value="suffixed"
                      >
                      </hlx-select>
                    </template>
                  </hlx-input>
                </div>

                <div class="decision-name-wrapper">
                  <hlx-select
                    :key="key"
                    v-model:value="payload.paymentTerms[0].name"
                    :pre-value="payload.paymentTerms[0].name"
                    :disabled="view"
                    :inline-search="true"
                    :options="paymentList"
                    prop-value="name"
                    :placeholder-value="'Payment terms'"
                    :label-animation="true"
                    :clearable="true"
                  ></hlx-select>
                </div>
              </div>
              <div style="display: flex; flex-direction: column; width: 50%">
                <div class="decision-name-wrapper" style="margin: 0 0 25px 0">
                  <hlx-input
                    v-model:value="payload.description"
                    :pre-val="payload.description"
                    type="textarea"
                    :label-value="'Description'"
                    :read-only="view"
                    :label-animation="true"
                    :required="false"
                    :display-error="false"
                    :rows="11"
                    @focus-out="focusOutName"
                  ></hlx-input>
                </div>
                <div class="decision-name-wrapper">
                  <hlx-input
                    v-model:value="
                      payload.contactMedium[0].characteristic.emailAddress
                    "
                    :pre-val="
                      payload.contactMedium[0].characteristic.emailAddress
                    "
                    :label-value="'Email id'"
                    :label-animation="true"
                    type="email"
                    :read-only="view"
                    :required="true"
                    :display-error="true"
                    :custom-error="isEmailEmpty"
                    :custom-error-message="'Enter a valid email.(eg: user@example.com)'"
                    @at-input="validateEmail"
                  ></hlx-input>
                </div>
                <div class="decision-name-wrapper">
                  <hlx-input
                    v-model:value="
                      payload.contactMedium[1].characteristic.phoneNumber
                    "
                    :pre-val="
                      payload.contactMedium[1].characteristic.phoneNumber.value
                    "
                    :country-code="
                      payload.contactMedium[1].characteristic.phoneNumber
                        .country
                    "
                    type="phonenumber"
                    :read-only="view"
                    :label-value="'Phone no.'"
                    :label-animation="true"
                    :display-error="true"
                    :custom-error="customnameerror"
                    :custom-error-message="'Please fill the field'"
                    @focus-out="focusOutName"
                    @at-input="validateName"
                  ></hlx-input>
                </div>
                <div class="decision-name-wrapper" style="">
                  <hlx-input
                    v-model:value="payload.externalReference[0].name"
                    :pre-val="payload.externalReference[0].name"
                    :label-value="'Website URL'"
                    :label-animation="true"
                    type="text"
                    :prefix="true"
                    :read-only="view"
                    :display-error="true"
                    :custom-error="isUrlEmpty"
                    :custom-error-message="'Please enter a valid URL in the format: www.abc.com'"
                    @focus-out="websiteRegex"
                    @at-input="websiteRegex"
                  >
                  </hlx-input>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;

                    height: 160px;
                  "
                >
                  <!-- <div
                    class="decision-name-wrapper"
                    style="margin-bottom: 37px"
                  >
                    <hlx-select
                      :key="key"
                      v-model:value="payload.paymentTerms[0].name"
                      :pre-value="payload.paymentTerms[0].name"
                      :disabled="view" 
                      :options="paymentList"
                      prop-value="name"
                      :placeholder-value="'Payment terms'"
                      :label-animation="true"
                      :clearable="true"
                    ></hlx-select>
                  </div> -->

                  <div class="decision-name-wrapper">
                    <hlx-multi-select
                      :pre-value="payload.tags"
                      :options="tagsList"
                      prop-value="name"
                      label="name"
                      :placeholder="'Tags'"
                      :disabled="view"
                      :label-animation="true"
                      :clearable="true"
                      @add-data="addTagList"
                      @selected-array="receivedTags"
                    ></hlx-multi-select>
                  </div>
                </div>
                <!-- <uploadFileComponent></uploadFileComponent> -->
              </div>
            </div>
          </div>
        </div>
        <div v-if="leftpannel == 'Contacts'">
          <ContactsComponent
            :data="productpayload"
            :datacontact="contactTableData"
            :supplier-status="supplierStatus"
            @new-contact="contactData"
            @edit-contact="editContactData"
            @delete-contact="deleteContactData"
          ></ContactsComponent>
        </div>
        <div v-if="leftpannel == 'Contracts'">
          <ContractsComponent
            :data="productpayload"
            :supplier-status="supplierStatus"
          ></ContractsComponent>
        </div>
        <div v-if="leftpannel == 'Purchase Order'">
          <PurchaseOderComponent
            :data="productpayload"
            :supplier-status="supplierStatus"
          ></PurchaseOderComponent>
        </div>
        <div v-if="leftpannel == 'Products'">
          <ProductsComponent
            :data="productpayload"
            :supplier-status="supplierStatus"
          ></ProductsComponent>
        </div>
        <div v-if="leftpannel == 'Discount'">
          <discountComponent
            :supplier-status="supplierStatus"
          ></discountComponent>
        </div>
        <div v-if="leftpannel == 'Document'">
          <attachmentComponent
            :supplier-status="supplierStatus"
            :user-details="userDetails"
            :data="attachmentData"
            module="SM"
            @upload-data="receivedUploadData"
            @delete-index="deleteAttachmentRow"
          ></attachmentComponent>
        </div>
        <div v-if="leftpannel == 'Notes'">
          <noteComponent
            :supplier-status="supplierStatus"
            :data="noteData"
            @added-note="newNote"
            @delete-index="deleteNote"
            @edit-note="editNote"
          ></noteComponent>
        </div>
        <div v-if="leftpannel == 'Activity log'">
          <ActivityLogComponent
            :activity-data="logData"
            :totalfromprop="activitytotal"
            @activityrowpage="activityrowpage1"
            @activitycurrentpage="activitycurrentpage1"
          ></ActivityLogComponent>
        </div>
        <div v-if="leftpannel == 'Location'" class="">
          <locationComponent
            :supplier-status="supplierStatus"
            :data="payload.contactMedium"
            @new-address="receivedNewAddress"
            @initial-payload="initialPayloadLocation"
            @update-address="receivedUpdateAddress"
            @delete-index="deleteAddress"
          ></locationComponent>
        </div>
      </section>
    </main>
  </section>
  <discardComponent
    :show="showSupplierDiscardModal"
    @stay="handleStay"
    @discard="handleDiscard"
    @close="showSupplierDiscardModal = false"
  />
</template>

<script>
// import deepDiff from "deep-diff";
import {
  MLTAxiosInstance,
  DMNAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
import leftpannelComponent from "@/components/leftpannelComponent.vue";
import ContactsComponent from "@/components/SM/contactsComponent.vue";
import ContractsComponent from "@/components/SM/contractsComponent.vue";
import PurchaseOderComponent from "@/components/SM/PurchaseOderComponent.vue";
import ProductsComponent from "@/components/SM/productComponent.vue";
import attachmentComponent from "@/components/CM/addAccountAttachmentsComponent.vue";
import noteComponent from "@/components/CM/addAccountNotesComponent.vue";
import ActivityLogComponent from "@/components/SM/activityLogComponent.vue";
import addressComponent from "@/components/CM/autocompleteComponent.vue";
import locationComponent from "@/components/SM/addSupplierLocationComponent.vue";
import discountComponent from "@/components/SM/discountComponet.vue";
import discardComponent from "@/components/discardComponent.vue";

export default {
  components: {
    // OverviewComponent ,
    // uploadFileComponent,
    discardComponent,
    discountComponent,
    locationComponent,
    addressComponent,
    leftpannelComponent,
    ContactsComponent,
    ContractsComponent,
    PurchaseOderComponent,
    ProductsComponent,
    attachmentComponent,
    noteComponent,
    ActivityLogComponent,
  },
  beforeRouteLeave(to, from, next) {
    if (this.discardIt) {
      //this.discardIt will handled on discard modal window discard changes emit
      next(true);
      return;
    }
    // Check for unsaved changes
    /**
     * Param 1 initial paylaod to check against param 2
     * Param 2 updated payload to check against param 1
     */
    const unsavedChanges = this.isPayloadChanged(
      this.initialData,
      this.updatedData
    ); //This isPayloadChanged method was configured in global methods so we can call it any where on vue.It's find a difference between both data and return true if any changes otherwise return false
    console.log(unsavedChanges, "unsavedChanges");
    // If unsaved changes found then display discard modal
    if (unsavedChanges) {
      this.showSupplierDiscardModal = true;
    }
    // Then route as per validation
    next(!unsavedChanges);
    return;
  },
  data() {
    return {
      sideBarData:{},
      tempSideBar :{main: [
          {
            id: 1,
            name: "Overview",
            icon: "icon-presentation-regular",
            active: true,
            view: true,
          },
          {
            id: 1.1,
            name: "Location",
            icon: "icon-location-pin-regular",
            active: false,
            view: true,
          },
          {
            id: 2,
            name: "Products",
            icon: "icon-box-regular",
            active: false,
            view: true,
          },

          {
            id: 3,
            name: "Contacts",
            icon: "icon-user-regular",
            active: false,
            view: true,
          },
          {
            id: 4,
            name: "Contracts",
            icon: "icon-file-regular",
            active: false,
            view: true,
          },
          {
            id: 2.1,
            name: "Discount",
            icon: "icon-badge-discount-alt-regular",
            active: false,
            view: true,
          },
          {
            id: 5,
            name: "Purchase Order",
            icon: "icon-file-text-regular",
            active: false,
            view: true,
          },
          {
            id: 6,
            name: "Document",
            icon: "icon-paperclip-regular",
            active: false,
            view: true,
          },
          {
            id: 7,
            name: "Notes",
            icon: "icon-menu-left-regular",
            active: false,
            view: true,
          },
          {
            id: 8,
            name: "Activity log",
            icon: "icon-clock-regular",
            active: false,
            view: true,
          },
        ]},
      activityrowpage: 0,
      activitycurrentpage: 0,
      activitytotal: 0,
 
      offerActivity: {
        type: "filter",

        lastUpdated: new Date().toJSON(),

        module: "log",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: this.activityrowpage,
          currentPage: this.activitycurrentpage,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          category: "supplier",
          ref: `${this.$route.params.id}`,
        },
        searchQuery: "",
      },

      show_left: false,

      filteredstatus: [],
      addressPreVal: {
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
        },
      },
      logData: [],
      profilePicUrl: "",
      differences: [],
      isUrlEmpty: false,
      showSupplierDiscardModal: false,
      noteData: [],
      attachmentData: [],
      userDetails: {
        name: this.$route.params.companyName,
        id: this.$route.params.id,
      },
      showNewAddressOption: false,
      backgrounStyle: {
        backgroundColor: "",
        bacgroundtTextColor: "#A8A8A8",
        border: false,
        borderColor: "#A8A8A8",
        TextfontSize: 34,
      },
      backgrounStyleupload: {
        backgroundColor: "",
        bacgroundtTextColor: "#A8A8A8",
        border: false,
        borderColor: "#A8A8A8",
        TextfontSize: 16,
      },
      newProfileData: null,
      newProfileformData: null,
      rawProfileUrlData: null,
      profileComponentKey: 0,
      newProfileAdded: false,
      updatedData: {},
      initialData: {},
      rawPayload: {},
      contactTableData: [],
      username: "GlobalTech Supplies",
      tagName: "Active",
      addressName: "123 Main Street Suite 456 Toronto, ON M5V 2B8, Canada",
      tablehead: "Attachment",
      view: true,
      defalt: true,
      name: "",
      description: "",
      email: "",
      phone: "",
      location: "",
      url: "",
      id: this.$route.params.id,
      overview: {},
      // sideBarData: {
        
      // },
      productpayload: {},
      notification: {},
      locationTypeList: [
        {
          name: "Headquarters",
        },
        {
          name: "Warehouse",
        },
      ],
      employeeCountList: [
        {
          name: "1 - 10",
        },
        {
          name: "10 - 50",
        },
        {
          name: "50 - 100",
        },
        {
          name: ">100",
        },
      ],
      preferredMediumList: [
        {
          name: "Email",
          value: "email",
        },
        {
          name: "Phone number",
          value: "phone",
        },
        {
          name: "Fax",
          value: "fax",
        },
      ],
      industryTypeList: [
        {
          name: "Corporate/Management",
        },
        {
          name: "Logistics/Supply Chain",
        },
        {
          name: "Manufacturing",
        },
        {
          name: "Information Technology",
        },
        {
          name: "Retail",
        },
      ],
      statusList: [],
      paymentList: [],
      ShipingList: [],
      tagsList: [],
      locationApi: "https://appbackend-ghubgwxrcq-pd.a.run.app/autocomplete",
      isRightLayoutOpened: false,
      breadCrumbItems: [
        {
          label: "Supplier",
          link: `/SM/supplier`,
        },
        { label: this.payload?.customName, link: "" },
      ],
      tempBack: false,
      customnameerror: false,
      leadTimeOption: [],
      contactPayload: {
        name: "",
        tradingName: "",
        companyName: "",
        status: "",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            emailAddress: "",
          },
          {
            mediumType: "phone",
            phoneNumber: {
              country: {
                name: this.$store.getters.defaultSetting?.country,
                short: this.$store.getters.defaultSetting?.cca2,
                flags: this.$store.getters.defaultSetting?.flags?.svg,
              },
              value: "",
              valid: true,
            },
          },
          {
            mediumType: "fax",
            faxNumber: "",
          },
          {
            mediumType: "postalAddress",
            city: "",
            country: "",
            postCode: "",
            stateOrProvince: "",
            street1: "",
            street2: "",
            description: "",
            "@type": "",
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
          {
            "@type": "leadTime",
            leadTime: 1,
            leadPeriod: "Days",
          },
        ],
        relatedParty: [
          {
            role: "director",
            partyOrPartyRole: {
              id: "42",
              name: "Feroz Khan",
              "@referredType": "Individual",
            },
          },
        ],
        tags: ["Active"],
        role: "Root admin",
        type: "individual",
        password: "",
        email: "",
        userId: "",
        company: "",
      },
      selectedValue: "Overview",
      tabItems: [
        { name: "Overview", value: "Overview" },
        { name: "Hierarchy", value: "Hierarchy" },
        {
          name: "Contacts",
          value: "Contacts",
        },
        { name: "Interaction", value: "Interaction" },
        { name: "Rules", value: "Rules" },
        { name: "Quotes and orders", value: "Quotes and orders" },
        { name: "Approvals", value: "Approvals" },
      ],
      recievedContactData: [],
      showAddNewAdrressModal: false,
      newAddressPayload: {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      },
      locationData: [],

      ProductTabledata: {},
      leftpannel: "",
      initialSupplierPayload: {
        attachment: [],
        name: "",
        role: "",
        password: "",
        email: "",
        phone: "",
        tags: [],
        notes: [],
        companyName: "",
        tradingName: "",
        status: "",
        type: "",
        "@baseType": "",
        paymentTerms: [
          {
            name: "",
            id: "",
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
          {
            "@type": "leadTime",
            leadTime: 1,
            leadPeriod: "Days",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              "@type": "",
            },
          },
        ],
      },
      activityPayload: {
        action: "",
        createdBy: {},
        message: "ReferenceData added successfully",

        updatedAt: {
          $date: "",
        },
        detailedAction: [
          {
            field: "stock qty",
            changefrom: "",
            changeTo: "",
          },
        ],
      },
      detailedAction: [
        {
          field: "stock qty",
          changefrom: "",
          changeTo: "",
        },
      ],
      initialPayloadLocations: {},
      payload: {
        attachment: [],
        name: "",
        role: "",
        password: "",
        email: "",
        phone: "",
        tags: [],
        notes: [],
        companyName: "",
        tradingName: "",
        customName: "",
        status: "",
        type: "",
        "@baseType": "",
        paymentTerms: [
          {
            name: "",
            id: "",
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
          {
            "@type": "leadTime",
            leadTime: 1,
            leadPeriod: "Days",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              "@type": "",
            },
          },
        ],
      },
      discardIt: false,
      profilePicName: "",
      deleteProfilePic: false,
      editsupplierbutton: false,
      supplierStatus: false,
    };
  },
  computed: {
    customsvalue() {
      if (this.payload.customName) {
        return this.payload.customName;
      } else {
        return this.payload.tradingName;
      }
    },
    getBreadcrumbs() {
      return [
        {
          label: "Supplier",
          link: `/SM/supplier`,
        },
        { label: this.customsvalue, link: "" },
      ];
    },
  },
  watch: {
    statusList: {
      handler(val) {
        console.log(val, "statuswatching000000000000");
        const filteredstatuslist = this.statusList?.filter(
          (item) => item?.name === "Active" || item?.name === "Inactive"
        );
        this.filteredstatus = filteredstatuslist;
        console.log("filtered11111111111111111", this.filteredstatus);
      },
      immediate: true,
      deep: true,
    },
    initialSupplierPayload: {
      handler(value) {
        console.log("🚀 ~ handler ~ value:", value);
      },
      deep: true,
      immediate: true,
    },
    payload: {
      handler(val) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (
          val &&
          val.contactMedium[0].characteristic.emailAddress &&
          emailRegex.test(val.contactMedium[0].characteristic.emailAddress)
        ) {
          this.editsupplierbutton = false;
          console.log(this.editsupplierbutton, "this.editsupplierbutton");
        } else {
          this.editsupplierbutton = true;
          console.log(this.editsupplierbutton, "this.editsupplierbutton");
        }
        (this.updatedData.name = val?.customName),
          (this.updatedData.status = val?.status),
          (this.updatedData.leadTime = val?.externalReference[1]?.leadTime),
          (this.updatedData.leadPeriod = val?.externalReference[1]?.leadPeriod),
          (this.updatedData.paymentTerms = val?.paymentTerms[0]?.name),
          (this.updatedData.description = val?.description),
          (this.updatedData.emailAddress =
            val?.contactMedium[0]?.characteristic.emailAddress),
          (this.updatedData.phoneNumber =
            val?.contactMedium[1]?.characteristic.phoneNumber),
          (this.updatedData.Website = val?.externalReference[0]?.name),
          (this.updatedData.tags = val?.tags),
          (this.updatedData.address = val?.contactMedium[3]);
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    const value = await MLTAxiosInstance.get("partymanagement/individual/auth");

let sampleTheadsd = await MLTAxiosInstance.get(
  `partyRole/get/${value.data.userId}`
);

const mergedPayload = sampleTheadsd.data.permission;

this.permisstionData =
mergedPayload.SM.sections;

this.sideBarData = this.mergeSidebarData(
  this.tempSideBar,
  this.permisstionData
);
    console.log(this.sideBarData,"sideBarData");
    
    if (this.$route.query.status === "Inactive") {
      this.supplierStatus = true;
    }
 
    console.log(
      this.$route.query.status,
      this.$route.query.customName,
      this.supplierStatus,
      "this.supplierStatus"
    );
    const access = await MLTAxiosInstance.get(
      `partymanagement/organisation/${this.$route.params.id}?company=${this.$route.params.companyName}`
    );
    this.payload = access.data;
    this.rawPayload = JSON.parse(JSON.stringify(access.data));
    this.initialData = {
      name: this.rawPayload?.customName,
      status: this.rawPayload?.status,
      leadTime: this.rawPayload?.externalReference[1]?.leadTime,
      leadPeriod: this.rawPayload?.externalReference[1]?.leadPeriod,
      paymentTerms: this.rawPayload?.paymentTerms[0]?.name,
      description: this.rawPayload?.description,
      emailAddress:
        this.rawPayload?.contactMedium[0]?.characteristic.emailAddress,
      phoneNumber:
        this.rawPayload?.contactMedium[1]?.characteristic.phoneNumber,
      Website: this.rawPayload?.externalReference[0]?.name,
      tags: this.rawPayload?.tags,
      address: this.rawPayload?.contactMedium[3],
    };

    this.updatedData = {
      name: this.payload?.customName,
      status: this.payload?.status,
      leadTime: this.payload?.externalReference[1]?.leadTime,
      leadPeriod: this.payload?.externalReference[1]?.leadPeriod,
      paymentTerms: this.payload?.paymentTerms[0]?.name,
      description: this.payload?.description,
      emailAddress: this.payload?.contactMedium[0]?.characteristic.emailAddress,
      phoneNumber: this.payload?.contactMedium[1]?.characteristic.phoneNumber,
      Website: this.payload?.externalReference[0]?.name,
      tags: this.payload?.tags,
      address: this.payload?.contactMedium[3],
    };
    console.log(this.payload, "mouted data payload ");
    this.addressPreVal = this.payload.contactMedium[3];
    // const logpayload = {
    //   company: `${this.$route.params.companyName}`,
    //   type: "filter",
    //   module: "log",
    //   lastUpdated: new Date().toJSON(),
    //   isPaginated: true,
    //   paginatedQuery: {
    //     rowsPerPage: 10,
    //     currentPage: 1,
    //   },
    //   isNeedToBeSort: true,
    //   sortFieldAndOrder: {},
    //   limit: 10,
    //   filterQuery: {
    //     category: "supplier",
    //   },
    //   searchQuery: "",
    // };
    // const logaccess = await MLTAxiosInstance.post(
    //   `/util/filterData`,
    //   logpayload
    // );
    // this.logData = logaccess.data.data;

    // console.log(logaccess.data.data);

    let activitylogdata = await MLTAxiosInstance.post(
      `util/filterData`,
      this.offerActivity
    );
    console.log(activitylogdata, "activity log response data  in offer1");

    this.logData = activitylogdata.data.data;
    this.activitytotal = activitylogdata.data.total;
    console.log(this.activitytotal, "total in overview1");

    let tagsList =  await this.getReferanceData('tagsList')
    this.tagsList = tagsList?.filter((item) => {
      return item?.module === "sm";
    });
    // this.contactTableData = tabledata.data.data;

    
    this.paymentList = await this.getReferanceData('paymentTerms');
    // let statusList = await DMNAxiosInstance.get(`mapRef/collection/statuslist`);
    this.statusList =  await this.getReferanceData('statuslist');
    // let leadTimeOption = await DMNAxiosInstance.get(
    //   `mapRef/collection/leadTime`
    // );
    this.leadTimeOption =  await this.getReferanceData('leadTime');
    // this.compareObjects();

    this.attachmentData = this.payload.attachment.filter((item) => {
      return item["@type"] !== "profile" ? item : false;
    });
    this.noteData = this.payload.notes;
    console.log(this.attachmentData);
    this.productpayload = access.data;
    console.log(this.payload, "gfdzdt");

    if (access.data) {
      this.name = access.data.name;
      this.initialSupplierPayload = JSON.parse(
        JSON.stringify({ ...this.initialSupplierPayload, ...access.data })
      );
    }

    const profileName = this.payload.attachment.filter((item) => {
      return item["@type"] !== undefined && item["@type"] === "profile"
        ? item
        : false;
    });
    console.log(profileName[0]?.name);
    this.profilePicName = profileName[0]?.name;
    if (profileName[0]?.name.length > 0) {
      const url = await UPCAxiosInstance.get(
        `/gcpupload/files/${profileName[0]?.name}`,
        { responseType: "arraybuffer" }
      );
      this.rawProfileUrlData = url.data;
      this.profilePicUrl = await this.bufferToDataURL(url.data);
    }

    // console.log(new Uint8Array([url.data,"url incoming"]))
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventPageReload);
  },

  beforeUnmount() {
    window.removeEventListener("beforeunload", this.preventPageReload);
  },
  methods: {
    async activitycurrentpage1(val) {
      console.log(val, "activitycurrentpage1..........");
      this.activitycurrentpage = val;
      console.log(val, "current");
      this.offerActivity.paginatedQuery.currentPage = val;
      let activitylogdata = await MLTAxiosInstance.post(
        `util/filterData`,
        this.offerActivity
      );
      console.log(
        activitylogdata.data.data,
        "123 activity log response data in offer2 current"
      );
      this.logData = activitylogdata.data.data;
      this.activitytotal = activitylogdata.data.total;
    },

    async activityrowpage1(val) {
      console.log(val, "activityrowpage1..........");
      this.activityrowpage = val;
      console.log(val, "row");
      this.offerActivity.paginatedQuery.rowsPerPage = val;
      let activitylogdata = await MLTAxiosInstance.post(
        `util/filterData`,
        this.offerActivity
      );
      console.log(
        activitylogdata.data.data,
        "123 activity log response data in offer2 row"
      );
      this.logData = activitylogdata.data.data;
      console.log(this.logData, this.logData.length, "row page again");
      this.activitytotal = activitylogdata.data.total;
    },

    websiteRegex(val) {
      const websiteRegex =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

      if (val.length >= 1) {
        if (!websiteRegex.test(val)) {
          console.log(val, this.isUrlEmpty, "this.isUrlEmpty");
          this.isUrlEmpty = true; // URL is invalid
        } else {
          this.isUrlEmpty = false;

          console.log(val, this.isUrlEmpty, "this.isUrlEmpty");
        }
      }
    },
    validatePhoneNumber(val) {
      return val.valid;
    },
    validateEmail(val) {
      const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (val.length > 0) {
        if (!reg.test(val)) {
          return false;
        } else {
          return true;
        }
      }
    },
    async deleteProfilePicture() {
      console.log(this.payload.attachment, this.profilePicName, "ppp");
      this.deleteProfilePic = true;
    },
    emitStatus1(val1) {
      this.payload.status = val1["value"];
    },
    cancelEditsupplier() {
      this.view = true;
      this.profileComponentKey += 1;
      this.payload = JSON.parse(JSON.stringify(this.rawPayload));
      this.addressPreVal = this.payload.contactMedium[3];
      this.bufferToDataURL(this.rawProfileUrlData);
      this.newProfileAdded = false;
      this.deleteProfilePic = false;
      // this.addressPreVal = this.payload.contactMedium[3];
    },
    async bufferToDataURL(buffer) {
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = "";
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },
    async profileUpload(event) {
      console.log(event);
      this.newProfileAdded = true;
      this.newProfileData = event.target.files[0];
      this.newProfileformData = new FormData();
      this.newProfileformData.append("files", this.newProfileData);
    },
    // compareObjects() {
    //   this.differences = deepDiff(this.rawPayload, this.payload);
    //   this.detailedAction = this.differences.map((item) => {
    //     //   var validator = {
    //     //   set (target, key, value) {
    //     //     console.log(target);
    //     //     console.log(key);
    //     //     console.log(value);

    //     //     return true
    //     //   }
    //     // }

    //     // var proxy = new Proxy(item, validator)
    //     // console.log(proxy.path);
    //     //  if (typeof item === 'object' && Array.isArray(item.path)) { // Check if item is an object and path is an array
    //     //       console.log(item);
    //     //       console.log(item.path); // Log the path array

    //     //       // If you need to log each element of the path array
    //     //       item.path.forEach((pathElement) => {
    //     //           console.log(pathElement);
    //     //       });
    //     //   } else {
    //     //       console.error('Item is not an object or path is not an array:', item);
    //     //   }
    //     return {
    //       changefrom: item.lhr,
    //       changeTo: item.rhs,
    //     };
    //   });

    //   this.detailedAction = this.differences.map((item) => ({
    //     changefrom: item.lhs,
    //     changeTo: item.rhs,
    //   }));
    //   // console.log(this.differences[0].path, this.detailedAction);
    // },
    receivedAddressData(val) {
      let address1 = JSON.parse(JSON.stringify(val));
      this.payload.contactMedium[3] = JSON.parse(JSON.stringify(address1));
    },
    handleStay() {
      this.showSupplierDiscardModal = !this.showSupplierDiscardModal;
      this.tempBack = false;
    },
    handleDiscard() {
      //Set tempBack = true when Back is clicked from breadcrumbs
      if (this.tempBack) {
        this.$router.back();
      } else {
        this.$router.push(this.$store.state.tempActiveSideBarItem.link);
      }

      this.showSupplierDiscardModal = !this.showSupplierDiscardModal;

      this.discardIt = true;
    },
    preventPageReload(event) {
      this.showSupplierDiscardModal = false;
      const unsavedChanges = this.isPayloadChanged(
        this.initialData,
        this.updatedData
      );
      if (!unsavedChanges) return;

      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
      return;
    },
    async deleteAddress(index) { 
      console.log(this.payload.contactMedium[index]);
      let changeDefault = false;
      const addedProductLocationLogPayload = {
        message: `${this.payload.contactMedium[index].characteristic.locationName} Location has been deleted`,
        detailedAction: [],
        action: "Delete",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "supplier-location",
        category: ["supplier"],
      };
      this.initLog(addedProductLocationLogPayload);
      const type = this.payload.contactMedium[index].mediumType;
      let p = [];
      if (this.payload.contactMedium[index].characteristic.default === true) {
        changeDefault = true;
      }
      this.payload.contactMedium.splice(index, 1);
      p = this.payload.contactMedium.filter((item) => {
        return item.mediumType === type;
      });
      if (p[0] !== undefined && changeDefault === true) {
        p[0].default = true;
      }
      console.log(p[0]);
      await this.addNewSupplier();

      this.parentCompany = this.company;
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$route.params.id}?company=${this.$route.params.companyName}`
      );
      console.log(payload);
      // let contacts = await MLTAxiosInstance.get(
      //   `/partymanagement/individual/listuser?company=${this.$route.params.companyName}`
      // );
      // contacts.data.forEach((e) => {
      //   const preferredMedium = e.contactMedium.find((item) => item.preferred);
      //   e.preferred = preferredMedium ? preferredMedium.mediumType : null;
      // });
      // this.contactTableData = contacts.data;
      console.log(this.contactTableData);
      const preferredMedium = payload.data.contactMedium.find(
        (item) => item.preferred
      );
      // console.log(preferredMedium);
      this.payload = payload.data;
      this.payload.preferred = preferredMedium
        ? preferredMedium.mediumType
        : null;
      console.log(this.payload);

      console.log(p);
      this.attachmentData = this.payload.attachment.filter((item) => {
        return item["@type"] !== "profile" ? item : false;
      });
      this.noteData = this.payload.notes;
      this.rawPayload = JSON.parse(JSON.stringify(this.payload));
    },
    initialPayloadLocation(val) {
      this.initialPayloadLocations = JSON.parse(JSON.stringify(val));
    },
    async receivedUpdateAddress(val) {
      this.payload.contactMedium[val.index] = val.data;
      await this.addNewSupplier();
      this.parentCompany = this.company;
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$route.params.id}?company=${this.$route.params.companyName}`
      );
      console.log(payload);
      let contacts = await MLTAxiosInstance.get(
        `/partymanagement/individual/listuser?company=${this.$route.params.companyName}`
      );
      let CM_LocationLog = {
        message: `${this.payload?.customName} Location has been updated`,
        detailedAction: [],
        "@type": "supplier-location",
        category: ["supplier"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: [this.$route.params.id],
        action: "UPDATE",
        status: "active",
      };

      let nameActionLog = this.buildBasicLogByDifferences(
        this.initialPayloadLocations?.characteristic,
        this.payload?.contactMedium[val?.index]?.characteristic,
        "locationName",
        "Name"
      );
      console.log(nameActionLog, CM_LocationLog, "nameActionLog");

      let descriptionActionLog = this.buildBasicLogByDifferences(
        this.initialPayloadLocations?.characteristic,
        this.payload?.contactMedium[val?.index]?.characteristic,
        "description",
        "Address"
      );
      console.log(descriptionActionLog, "nameActionLog");

      // email differences
      let emailaddressActionLog = this.buildBasicLogByDifferences(
        this.initialPayloadLocations?.characteristic?.email,
        this.payload?.contactMedium[val?.index]?.characteristic?.email,
        "emailAddress",
        "Email"
      );
      console.log(emailaddressActionLog, "emailaddressActionLog");

      // phoneNumber differences
      let phoneNumberActionLog = this.buildBasicLogByDifferences(
        this.initialPayloadLocations?.characteristic?.phone?.phoneNumber,
        this.payload?.contactMedium[val?.index].characteristic?.phone?.phoneNumber,
        "value",
        "phoneNumber"
      );

      console.log(phoneNumberActionLog, "phoneNumberActionLog");
      CM_LocationLog.detailedAction = CM_LocationLog.detailedAction.concat(
        phoneNumberActionLog,
        emailaddressActionLog,
        descriptionActionLog,
        nameActionLog
      );

      this.initLog(CM_LocationLog);
      contacts.data.forEach((e) => {
        const preferredMedium = e.contactMedium.find((item) => item.preferred);
        e.preferred = preferredMedium ? preferredMedium.mediumType : null;
      });
      this.contactTableData = contacts.data;
      console.log(this.contactTableData);
      const preferredMedium = payload.data.contactMedium.find(
        (item) => item.preferred
      );
      // console.log(preferredMedium);
      this.payload = payload.data;
      this.payload.preferred = preferredMedium
        ? preferredMedium.mediumType
        : null;
      console.log(this.payload);
      this.attachmentData = this.payload.attachment.filter((item) => {
        return item["@type"] !== "profile" ? item : false;
      });
      this.noteData = this.payload.notes;
      this.rawPayload = JSON.parse(JSON.stringify(this.payload));
    },
    async receivedNewAddress(val) {
      const addedProductLocationLogPayload = {
        message: `${val.characteristic.locationName} Location has been added`,
        detailedAction: [],
        action: "ADDED",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "supplier-location",
        category: ["supplier"],
      };
      this.initLog(addedProductLocationLogPayload);
      this.payload.contactMedium.push(val);
      await this.addNewSupplier();
      this.parentCompany = this.company;
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$route.params.id}?company=${this.$route.params.companyName}`
      );
      console.log(payload);
      let contacts = await MLTAxiosInstance.get(
        `/partymanagement/individual/listuser?company=${this.$route.params.companyName}`
      );
      contacts.data.forEach((e) => {
        const preferredMedium = e.contactMedium.find((item) => item.preferred);
        e.preferred = preferredMedium ? preferredMedium.mediumType : null;
      });
      this.contactTableData = contacts.data;
      console.log(this.contactTableData);
      const preferredMedium = payload.data.contactMedium.find(
        (item) => item.preferred
      );
      // console.log(preferredMedium);
      this.payload = payload.data;
      this.payload.preferred = preferredMedium
        ? preferredMedium.mediumType
        : null;
      console.log(this.payload);
      this.attachmentData = this.payload.attachment.filter((item) => {
        return item["@type"] !== "profile" ? item : false;
      });
      this.noteData = this.payload.notes;
      this.rawPayload = JSON.parse(JSON.stringify(this.payload));
    },
    async deleteAttachmentRow(val) {
      console.log(val);
      this.payload.attachment.splice(val - 1, 1);
      this.payload.id = this.payload._id;
      delete this.payload._id;
      await MLTAxiosInstance.patch(
        `/partymanagement/organisation/?company=${this.$route.params.companyName}`,
        this.payload
      );
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$route.params.id}/?company=${this.$route.params.companyName}`
      );
      this.attachmentData = payload?.data?.attachment?.filter((item) => {
        return item["@type"] !== "profile" ? item : false;
      });
    }, 
    async receivedUploadData(val) {
      let data = await UPCAxiosInstance.post(`/gcpMultiple/v2/upload/`, val);
      console.log(data);
      data?.data?.forEach((item) => {
        let obj = {};
        obj.name = item.filename;
        obj.size = item.data.attachment.size;
        obj.url = item.url;
        obj.id = item.data._id;
        // Get the current date and time in milliseconds
        const currentTimestamp = new Date().toJSON();

        // Format the current timestamp in the same format as '1705574420667'
        console.log(currentTimestamp);
        obj.lastUpdated = currentTimestamp;
        this.payload.attachment.unshift(obj);
      });
      console.log(this.payload);
      await this.addNewSupplier();
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$route.params.id}/?company=${this.$route.params.companyName}`
      );
      if(payload){
            this.notification = {
          type: "success",
          message: `Nice work! Your new file is now in the list!`,
        };
      }
      this.attachmentData = payload?.data?.attachment?.filter((item) => {
        return item["@type"] !== "profile" ? item : false;
      });
    },
    async editNote(val) {
      this.payload.notes[val.index] = val.val;
      this.payload.id = this.payload._id;
      delete this.payload._id;
      await MLTAxiosInstance.patch(
        `/partymanagement/organisation/?company=${this.$route.params.companyName}`,
        this.payload
      );
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$route.params.id}?company=${this.$route.params.companyName}`
      );
      this.noteData = payload.data.notes;
    },
    async newNote(val) {
      console.log(val);
      this.payload.notes.unshift(val);
      this.payload.id = this.payload._id;
      delete this.payload._id;
      await MLTAxiosInstance.patch(
        `/partymanagement/organisation/?company=${this.$route.params.companyName}`,
        this.payload
      );
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$route.params.id}/?company=${this.$route.params.companyName}`
      );
      this.noteData = payload.data.notes;
    },
    async deleteNote(val) {
      this.payload.notes.splice(val, 1);
      this.payload.id = this.payload._id;
      delete this.payload._id;
      await MLTAxiosInstance.patch(
        `/partymanagement/organisation/?company=${this.$route.params.companyName}`,
        this.payload
      );
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$route.params.id}?company=${this.$route.params.companyName}`
      );
      this.noteData = payload.data.notes;
    },

    statusFunc(val) {
      this.payload.status = val.name;
      // console.log(val.name);
    },
    async addNewSupplier() {
      let SM_OverviewLog = {
        message: `${this.payload?.customName} Overview has been updated`,
        detailedAction: [],
        "@type": "Supplier-overview",
        category: ["supplier"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: [this.$route.params.id],
        action: "UPDATE",
        status: "active",
      };
      console.log("🚀 ~ saveSpec ~ CM_OverviewLog:", SM_OverviewLog);

      // Name log
      let nameActionLog = this.buildBasicLogByDifferences(
        this.initialData,
        this.updatedData,
        "name",
        "Name"
      );
      console.log(nameActionLog, "nameActionLog");

      // Description log
      let descriptionActionLog = this.buildBasicLogByDifferences(
        this.initialData,
        this.updatedData,
        "description",
        "Description"
      );
      console.log(descriptionActionLog, "descriptionActionLog");

      // Status log
      let statusActionLog = this.buildBasicLogByDifferences(
        this.initialData,
        this.updatedData,
        "status",
        "Status"
      );
      console.log(
        statusActionLog,
        this.initialData,
        this.updatedData,
        "statusActionLog"
      );

      // Address differences
      let addressActionLog = this.buildBasicLogByDifferences(
        this.initialData?.address.characteristic,
        this.updatedData?.address.characteristic,
        "description",
        "Address"
      );
      console.log(addressActionLog, "addressActionLog");

      // email differences
      let emailaddressActionLog = this.buildBasicLogByDifferences(
        this.initialData,
        this.updatedData,
        "emailAddress",
        "Email"
      );
      console.log(emailaddressActionLog, "emailaddressActionLog");

      // phoneNumber differences
      let phoneNumberActionLog = this.buildBasicLogByDifferences(
        this.initialData.phoneNumber,
        this.updatedData.phoneNumber,
        "value",
        "phoneNumber"
      );
      console.log(phoneNumberActionLog, "phoneNumberActionLog");

      let websideActionLog = this.buildBasicLogByDifferences(
        this.initialData,
        this.updatedData,
        "Website",
        "webside"
      );
      console.log(websideActionLog, "websideActionLog");

      let paymentTermsActionLog = this.buildBasicLogByDifferences(
        this.initialData,
        this.updatedData,
        "paymentTerms",
        "paymentTerms"
      );
      console.log(paymentTermsActionLog, "paymentTermsActionLog");
      let leadTimeActionLog = this.buildBasicLogByDifferences(
        this.initialData,
        this.updatedData,
        "leadTime",
        "leadTime"
      );
      console.log(leadTimeActionLog, "leadTimeActionLog");
      let leadPeriodActionLog = this.buildBasicLogByDifferences(
        this.initialData,
        this.updatedData,
        "leadPeriod",
        "leadPeriod"
      );
      console.log(leadPeriodActionLog, "websideActionLog");

      //tags
      let tagsActionLog = this.findArrayOfStringDifferences(
        this.initialData.tags,
        this.updatedData.tags,
        "tags"
      );
      console.log(tagsActionLog, "tagsActionLog");

      SM_OverviewLog.detailedAction = SM_OverviewLog.detailedAction.concat(
        tagsActionLog,
        leadPeriodActionLog,
        leadTimeActionLog,
        paymentTermsActionLog,
        websideActionLog,
        phoneNumberActionLog,
        emailaddressActionLog,
        addressActionLog,
        statusActionLog,
        descriptionActionLog,
        nameActionLog
      );
      this.initLog(SM_OverviewLog);

      this.editsupplierbutton = true;
      if (this.newProfileAdded) {
        let data = await UPCAxiosInstance.post(
          `/gcpMultiple/v2/upload/`,
          this.newProfileformData
        );
        console.log(data);

        let obj = {};
        obj.name = data.data[0].filename;
        obj.size = data.data[0].data.attachment.size;
        obj.url = data.data[0].url;
        obj.id = data.data[0].data._id;
        obj["@type"] = "profile";
        // Get the current date and time in milliseconds
        const currentTimestamp = new Date().toJSON();

        // Format the current timestamp in the same format as '1705574420667'
        console.log(currentTimestamp);
        obj.lastUpdated = currentTimestamp;
        // Find the index of the 'profile' object
        let index = this.payload.attachment.findIndex(
          (obj) => obj["@type"] === "profile"
        );
        if (obj.name.length > 0) {
          this.profilePicName = obj.name;
          const url = await UPCAxiosInstance.get(
            `/gcpupload/files/${obj.name}`,
            {
              responseType: "arraybuffer",
            }
          );
          this.rawProfileUrlData = url.data;
          this.profilePicUrl = await this.bufferToDataURL(url.data);
        }
        // Replace the 'profile' object with the new object if found
        if (index !== -1) {
          this.payload.attachment[index] = obj;
        } else {
          this.payload.attachment.push(obj);
        }
      }
      if (this.deleteProfilePic === true && this.newProfileAdded === false) {
        await UPCAxiosInstance.delete(
          `/gcpupload/files/${this.profilePicName}`
        );
        let index = this.payload.attachment.findIndex(
          (obj) => obj["@type"] === "profile"
        );
        if (index !== -1) {
          this.payload.attachment.splice(index, 1);
          this.rawProfileUrlData = null;
          this.profilePicUrl = "";
          this.profilePicName = "";
        }
      }
      this.newProfileAdded = false;
      this.deleteProfilePic = false;
      this.payload.id = this.payload._id;
      delete this.payload._id;
      const addSupplier = await MLTAxiosInstance.patch(
        `/partymanagement/organisation?company=${this.$route.params.companyName}`,
        this.payload
      );
      if (this.payload.status === "Inactive") {
        this.supplierStatus = true;
        // let ii = {
        //   filterQuery: {
        //     company: { $exists: true, $eq: this.payload.tradingName },
        //   },
        //   updatePayload: {
        //     status: "Inactive",
        //   },
        // };
        // await MLTAxiosInstance.patch(
        //   `/partymanagement/individual/batch?company=${this.payload.tradingName}`,
        //   ii
        // );
      }
      console.log(addSupplier, "test");
      if (addSupplier.data.tradingName) {
        this.notification = {
          type: "success",
          message: "All set! Your changes have been saved successfully!",
        };
        this.view = true;
      }
      const access = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.$route.params.id}?company=${this.$route.params.companyName}`
      );

      this.payload = await access.data;
      this.rawPayload = JSON.parse(JSON.stringify(addSupplier.data));
      this.initialData = {
        name: this.rawPayload?.customName,
        status: this.rawPayload?.status,
        leadTime: this.rawPayload?.externalReference[1]?.leadTime,
        leadPeriod: this.rawPayload?.externalReference[1]?.leadPeriod,
        paymentTerms: this.rawPayload?.paymentTerms[0]?.name,
        description: this.rawPayload?.description,
        emailAddress:
          this.rawPayload?.contactMedium[0]?.characteristic.emailAddress,
        phoneNumber:
          this.rawPayload?.contactMedium[1]?.characteristic.phoneNumber,
        Website: this.rawPayload?.externalReference[0]?.name,
        tags: this.rawPayload?.tags,
        address: this.rawPayload?.contactMedium[3],
      };
      await this.productUpdate();
      this.profileComponentKey += 1;
    },
    async productUpdate() {
      const productData = await UPCAxiosInstance.get(
        `/productStock?company=${this.$route.params.companyName}`
      );
      console.log(productData.data, "productData");
      if (Array.isArray(productData.data)) {
        const uniqueExternalIDs = Array.from(
          new Set(productData.data.map((product) => product.externalID))
        );
        const productSpecPromises = uniqueExternalIDs.map(
          async (externalID) => {
            try {
              const productSpec = await UPCAxiosInstance.get(
                `/catalogManagement/productOffering/${externalID}`
              );
              console.log(productSpec.data, "productSpec.data");
              const productSpecRelated = productSpec.data.relatedParty.map(
                (items) => {
                  productSpecRelated;
                  if (items.name === this.$route.params.companyName) {
                    console.log(items, "productSpecRelated");
                    items.customName = this.payload.customName;
                    items.leadtime = `${this.payload?.externalReference[1]?.leadTime} ${this.payload?.externalReference[1]?.leadPeriod}`;
                  }
                }
              );
              console.log(productSpec.data, " productSpecRelated;");
              const dataexpline = await UPCAxiosInstance.patch(
                `/catalogManagement/productOffering`,
                {
                  ...productSpec.data,
                  id: productSpec.data._id,
                }
              );
              console.log(dataexpline, "dataexplinedataexpline");
            } catch (error) {
              console.error(
                `Error fetching product spec for externalID ${externalID}:`,
                error
              );
              return null;
            }
          }
        );

        console.log(
          uniqueExternalIDs,
          productSpecPromises,
          "uniqueExternalIDs and productSpecs"
        );
      }
    },

    async valueleft(val) {
      this.leftpannel = val;
      this.show_left = false;
      if (val === "Activity log") {
        // const logpayload = {
        //   company: `${this.$route.params.companyName}`,
        //   type: "filter",
        //   module: "log",
        //   lastUpdated: new Date().toJSON(),
        //   isPaginated: true,
        //   paginatedQuery: {
        //     rowsPerPage: 10,
        //     currentPage: 1,
        //   },
        //   isNeedToBeSort: true,
        //   sortFieldAndOrder: {},
        //   limit: 10,
        //   filterQuery: {
        //     category: "supplier",
        //   },
        //   searchQuery: "",
        // };
        // const logaccess = await MLTAxiosInstance.post(
        //   `/util/filterData`,
        //   logpayload
        // );
        // this.logData = logaccess.data.data;

        let activitylogdata = await MLTAxiosInstance.post(
          `util/filterData`,
          this.offerActivity
        );
        console.log(activitylogdata, "activity log response data in offer2");

        this.logData = activitylogdata.data.data;
        this.activitytotal = activitylogdata.data.total;
        console.log(this.activitytotal, "total in overview2");

        console.log("activitylog");
      }
      console.log(val, this.logData, "this.logData");
    },
    editsupplier() {
      this.view = false;
    },
    // eslint-disable-next-line vue/no-async-in-computed-properties
    async getLocations() {
      const input = this.payload.contactMedium[3].location;
      let result = [];
      console.log(this.payload.contactMedium[3].location, "lovation");
      try {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        const response = await UPCAxiosInstance.get(
          `/suggestionlist/?input=${input}`
        );
        // console.log(response);
        result = response.data;
      } catch (error) {
        console.error("Error fetching autocomplete data:", error.message);
      }
      // console.log(result);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.locationData = result;
      if (this.locationData.length === 0 && input !== "") {
        this.showNewAddressOption = true;
      } else {
        this.showNewAddressOption = false;
      }
      return result;
    },
    async addNewAddress() {
      this.showNewAddressOption = false;
      this.newAddressPayload.description =
        this.newAddressPayload.streetNumber +
        ", " +
        this.newAddressPayload.streetName +
        ", " +
        this.newAddressPayload.city +
        ", " +
        this.newAddressPayload.state +
        ", " +
        this.newAddressPayload.country +
        ", " +
        this.newAddressPayload.postalCode +
        ".";
      // console.log(this.newAddressPayload);
      let newAddress = await DMNAxiosInstance.post(
        `mapRef/geoAddress`,
        this.newAddressPayload
      );
      if (newAddress.data.ok === 1) {
        // console.log(this.newAddressPayload.description);
      }
      this.payload.contactMedium[3].description =
        this.newAddressPayload.description;
      this.showAddNewAdrressModal = false;
      const x = {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      };
      this.newAddressPayload = JSON.parse(JSON.stringify(x));
    },
    addNewLocation(val) {
      // console.log(val);
      val;
      this.showAddNewAdrressModal = true;
      this.showNewAddressOption = false;
    },
    closeAddNewAdrressModal() {
      this.showAddNewAdrressModal = false;
    },
    receivedTags(e) {
      let arr = [];
      e.filter((item) => {
        if (item.checked == true) {
          arr.push(item.name);
        }
      });
      this.payload.tags = arr;
    },
    async addTagList(e) {
      let obj = {};
      obj.name = e.name;
      obj.module = "sm";
      await DMNAxiosInstance.post(`mapRef/tagsList`, obj);
      let tagsList =  await this.getReferanceData('tagsList');
      let arr = this.payload.tags;
      arr.push(e.name);
      this.payload.tags = arr;
      this.tagsList = tagsList.filter((item) => {
        return item.module === "sm";
      });
    },

    goTo() {
      this.tempBack = true;
      // this.$router.back(); 
      this.$router.push("/SM/supplier");

    },
  },
};
</script>

<style lang="scss">
.upload-supplier {
  .main-content-body-upload .upload-panel {
    height: 140px !important;
  }
}

.address-content-sm {
  color: #a6a6a6;
  font-size: 12px;
  width: max-content;
}
.responsive-menuicon-sm {
  display: none;
}
.responsive-span-sm {
  display: none;
}
.responsiveSM-right-portion {
  padding: 1.8em 20px;
}
.responsive-breadcrumbs-sm {
  display: none;
}
.leaftpanelForresponsive-sm {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .leaftpanelForresponsive-sm {
    display: block !important;
  }
  .responsive-breadcrumbs-sm {
    display: block;
  }
  .responsiveSM {
    display: none !important;
  }
  .responsiveSM-right-portion {
    padding: 0 20px 1.8em 20px !important;
    // padding-top: none !important;
    min-width: 100% !important;
  }
  .address-content-sm {
    width: 400px;
  }
  .responsive-menuicon-sm {
    display: block;
    font-size: 24px !important;
  }
  .responsive-span-sm {
    display: block;
  }
}
</style>
