<template>
  <hlx-alert-notification :notify="notification" :auto-close="true" />
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'204px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deletQuote"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
  <main class="quote-table-container">
    <!-- No data without search -->
    <!-- {{ totalpageno }} -->

    <!-- TableData -->
    <div class="table-wrap">
      <!-- v-if="tableData?.length > 0" -->
      <hlx-table
        :column-count="quoteThead?.length + 2"
        :border="
          tableData?.length == 0
            ? ['table', 'header']
            : ['table', 'header', 'horizontal']
        "
        :bold-headers="false"
        :row-hover="true"
        theme="grey"
        :striped-rows="false"
        :re-arrange-columns="toggleRearrangeModal"
        :re-arrange-heads="quoteTheadC"
        @sorting_func="sorting_Data"
        @close-rearrange="toggleRearrangeModal = false"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
            @new-resize-width="resizedWidth"
            >{{ "S.no" }}</hlx-table-head
          >

          <hlx-table-head
            v-for="(i, index) in quoteThead"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            :fixed="i.fixed"
            :width="i.width ? i.width : ''"
            :align="i.align ? i.align : ''"
            @sorting_func="sorting_Data"
            @new-resize-width="resizedWidth"
          >
            <!-- {{ "resizable "+i.resizable }} -->
            {{ i.label }}
          </hlx-table-head>
          <hlx-table-head
          v-if="permisstionData.edit || permisstionData.delete"
            :align="'center'"
            :width="70"
            :fixed="'right'"
            @new-resize-width="resizedWidth"
            ><span class="action-icon">
              <i
                class="icon-settings-regular"
                @click="reAssignOrgTableHeader()"
              ></i> </span
          ></hlx-table-head>
        </template>
        <template v-if="tableData?.length > 0" #tbody>
          <tr v-for="(i, index) in paginatedData" id="" :key="index">
            <hlx-table-cell :align="'center'" :fixed="'left'">
              {{ serialNumber(index) }}
            </hlx-table-cell>
            <hlx-table-cell
              v-for="(j, col_index) in quoteThead"
              :key="col_index"
              :align="j.align"
              :fixed="j.fixed"
            >
              <!-- {{i}}
                        {{j.prop}} -->
              <div
                v-if="
                  [
                    'type',
                    'name',
                    'displayID',
                    'updatedAt',
                    'executionCount',
                    'averageDuration',
                    'validFor',
                    'unitOfMeasure',
                  ].includes(j.prop) === true
                "
              >
                <!-- Display ID -->
                <div
                  v-if="j.prop === 'displayID'"
                  :class="{
                    'link-name':
                      module == 'quoteHistory'
                        ? $route.query.version == i.version &&
                          [
                            'inprogress',
                            'pending',
                            'accepted',
                            'draft',
                            'approved',
                          ].includes(i?.state?.toLowerCase())
                        : [
                            'inprogress',
                            'pending',
                            'draft',
                            'accepted',
                            'approved',
                          ].includes(i?.state?.toLowerCase()),
                  }"
                  style="width: 100px"
                  @click="viewPage(i)"
                >
                  {{ i[j.prop] + " V" + i.version }}
                </div>
              </div>

              <!-- Description -->
              <div v-else-if="j.prop == 'description'">
                <div
                  :id="j.prop + index"
                  class="ellipsis"
                  @mouseover="tooltipover(j.prop + index, index)"
                  @mouseleave="tooltipleave(j.prop + index, index)"
                >
                  {{ i[j.prop] }}
                </div>
                <div :class="index" class="tooltip" style="display: none">
                  {{ i[j.prop] }}
                </div>
              </div>

              <!-- For status -->
              <div v-else-if="j.prop == 'state'" class="tr-tc-quote-state">
                <!-- <hlx-label
                  class="sm"
                  :class="{
                    error: i['authorization'][0]?.state == 'rejected',
                    info: i['authorization'][0]?.state == 'inProgress',
                    warning: i['authorization'][0]?.state == 'pending',
                    success: i['authorization'][0]?.state == 'accepted',
                    primary: i['authorization'][0]?.state == 'approved',
                    disabled: i['authorization'][0]?.state == 'cancelled',
                  }"
                >
                  <span v-if="i['authorization'][0]?.state == 'pending'">
                    {{ convertTextTo("Waiting for customer", "pascalcase") }}
                  </span>
                  <span v-else>
                    {{ convertTextTo(i[j.prop], "pascalcase") }}
                  </span>
                </hlx-label> -->
                <!-- <hlx-status :type="i[j.prop]"></hlx-status> -->
                <!-- {{ i['authorization'] }} -->
                <!-- {{ i.state }} -->
                <!-- {{ computedQuoteState(i.state, i['authorization']) }}{{ '---' }}{{ i.state }}{{"1----1"}} -->
                <!-- {{ statusVmodel(computedQuoteState(i.state, i['authorization'])) }} -->
                <hlx-status
                  :type="'custom'"
                  :status-value="
                    statusVmodel(
                      computedQuoteState(i.state, i['authorization'])
                    )
                  "
                  :point-color="
                    computedQuoteState(i.state, i.authorization) == 'Draft'
                      ? '#0065B2'
                      : computedQuoteState(i.state, i.authorization) ==
                        'Awaiting approval'
                      ? '#897E06'
                      : computedQuoteState(i.state, i.authorization) ==
                        'Approved'
                      ? '#F9A645'
                      : computedQuoteState(i.state, i.authorization) ==
                        'Rejected'
                      ? '#FC3232'
                      : computedQuoteState(i.state, i.authorization) == 'Sent'
                      ? '#FEDC2A'
                      : computedQuoteState(i.state, i.authorization) ==
                        'Completed'
                      ? '#47B972'
                      : computedQuoteState(i.state, i.authorization) ==
                        'Expired'
                      ? '#FA9886'
                      : computedQuoteState(i.state, i.authorization) ==
                        'Rejected'
                      ? '#FC3232'
                      : '#0065B2'
                  "
                ></hlx-status>
              </div>
              <!-- For category -->
              <div v-else-if="j.prop == 'category'" class="tr-tc-quote-state">
                {{ i[j.prop] }}
              </div>

              <div v-else-if="j.prop == 'quoteItem'" class="tr-tc-quote-state">
                {{ i[j.prop].length }}
              </div>
              <div v-else-if="j.prop == 'quoteDate'" class="tr-tc-quote-state">
                {{ dateAndTimeFormatter(i[j.prop]) }}
              </div>
              <!-- Last update -->
              <!-- <div v-else-if="j.prop == 'lastUpdate'" class="tr-tc-quote-state">
                {{ dateAndTimeFormatter(i[j.prop]) }}
              </div> -->
              <!-- Buyer -->
              <div v-else-if="j.prop == 'Buyer'" class="tr-tc-quote-state">
                {{ findClientCompany(i.relatedParty) }}
              </div>
              <!-- Buyer contact -->
              <div
                v-else-if="j.prop == 'BuyerContact'"
                class="tr-tc-quote-state"
              >
                {{ findClientCompanyContact(i.relatedParty) }}
              </div>
              <!-- Price -->
              <div
                v-else-if="j.prop == 'quoteTotalPrice'"
                class="tr-tc-quote-state"
                style="display: flex; flex-direction: column; gap: 0.5rem"
              >
                <!-- <p
                  v-for="price in computedQuotePrice(i['quoteTotalPrice'])"
                  :key="price"
                  :class="{
                    '--hide-it':
                      (price &&
                        price.quoteTotal &&
                        price.quoteTotal.value &&
                        [0, 0.0, '0', '0.00', 'NaN'].includes(
                          price.quoteTotal.value?.trim()
                        )) ||
                      (price && price.quoteTotal && !price.quoteTotal.value),
                  }"
                  style="
                    display: grid;
                    gap: 0.5rem;
                    grid-template-columns: max-content max-content;
                  "
                >
                  <span style="">{{
                    price.label
                      ? price.label
                      : price?.name.replace("charges", "")
                  }}</span> 
                  <span>{{
                    currencyFormatter(price?.quoteTotal?.value, {
                      currency: price?.quoteTotal?.unit,
                    })
                  }}</span>
                </p> -->
                <!-- <p
                  v-if="computedQuotePrice(i['quoteTotalPrice'])?.length == 0"
                  style="text-align: center"
                >
                  -
                </p> -->
                <p>
                  {{ currencyFormatter(computePrice(i["quoteTotalPrice"])) }}
                </p>
              </div>

              <div v-else>
                {{ i[j.prop] }}
              </div>
            </hlx-table-cell>
            <hlx-table-cell v-if="permisstionData.edit || permisstionData.delete" :align="'center'" :width="120" :fixed="'right'">
              <span :id="'table-context' + index" class="action-icon">
                <!-- <i class="icon-edit-regular"></i> &nbsp;
                          <i class="icon-copy-regular"></i> &nbsp;
                          <i class="icon-trash-regular"></i> -->
                <i
                  style="position: relative"
                  class="icon-more-vertical-filled"
                  :class="i.context === true ? 'active-action' : ''"
                  @click="crudContextMenu($event, index)"
                >
                  <hlx-context-menu
                    v-if="i.version > 1"
                    :top="containertop"
                    :left="containerleft1"
                    :conditionvalue="150"
                    :conditiondown="2"
                    :conditionup="-38"
                    :options="computedTableAction(i,permisstionData)"
                    :data="index.toString()"
                    :show="i.context == true"
                    @chosen="closeCrudContextMenu($event, i, index)"
                  />
                  <hlx-context-menu
                    v-else
                    :top="containertop"
                    :left="containerleft"
                    :conditionvalue="150"
                    :conditiondown="2"
                    :conditionup="-38"
                    :options="computedTableAction(i,permisstionData)"
                    :data="index.toString()"
                    :show="i.context == true"
                    @chosen="closeCrudContextMenu($event, i, index)"
                  />
                </i>
              </span>
            </hlx-table-cell>
          </tr>
        </template>
        <template v-else #tbody>
          <tr v-for="item in 10" id="" :key="item">
            <hlx-table-cell v-if="item == 5" :colspan="quoteThead.length + 2">
              No data
            </hlx-table-cell>
            <hlx-table-cell v-else :align="'center'"> </hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
    </div>
    <!-- Pagination -->
    <div
      v-if="tableData?.length > 0"
      class="pagination-container"
      style="
        padding: 1rem;
        position: fixed;
        bottom: 0px;
        z-index: 251;
        width: -webkit-fill-available;
        background: white;
      "
    >
      <hlx-pagination
        v-model:rows-per-page="externalRowsPerPage"
        :total="totalpageno"
        enable-rows-per-page
        rows-per-page
        :rows-per-page-list="[10, 15, 20]"
        :options="{
          attributes: {
            rowsPerPageTitle: true,
            rowsPerPageResult: true,
            rowsPerPageDropdown: {
              position: {
                top: 0,
                right: 1,
              },
            },
          },
        }"
        @current-page="currentPage"
        @updated:rows-per-page="changeRowsPerPage"
      ></hlx-pagination>
    </div>
  </main>
</template>

<script>
import { MLTAxiosInstance, UPCAxiosInstance } from "@/config/axiosConfig";
import axios from "axios";
console.log("🚀 ~ axios:", axios);
// import FilterComponent from "../../components/filterComponent.vue";
import { mapGetters } from "vuex";
export default {
  name: "QuoteTable",
  components: {
    // FilterComponentt,
  },
  props: {

    searchKey: {
      type: String,
      default: "All",
    },
    filterSelectedvaluedata: {
      type: Object,
      default: () => {
        return {};
      },
    },
    permisstionData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    additionalFilterPipelines: {
      type: Array,
      default: () => {
        return [
          {
            $sort: {
              version: -1,
            },
          },
          {
            $group: {
              _id: "$displayID",
              latestQuote: {
                $first: "$$ROOT",
              },
            },
          },
          {
            $replaceRoot: {
              newRoot: "$latestQuote",
            },
          },
        ];
      },
    },
    filterQuerydata: {
      type: Object,
      default: () => {
        return {};
      },
    },
    module: {
      type: String,
      default: "Quote",
    },
  },
  emits: ["requestChange", "quotesfilterDatas"],
  data() {
    return {
      tableHeaderOrg: [],
      tableHeaderOrgcc: [],
      searchValue: "",
      latestPersistPage: 1,
      emailTemp: "",
      error: false,
      errorMessage: "",
      componentkey: 0,
      datalength: 0,
      enableButton: false,
      show: false,
      externalRowsPerPage: 10,
      show_right_filter: false,
      currencylist: [],
      filterPanelName: "",
      company: this.$cookies.get("company"),
      filterData: [],
      currentvalue: "ALL",
      sliderfilter: false,
      sliderMin: 0,
      sliderMax: 0,
      totalpageno: 0,
      containerleft: 0,
      containerleft1: 0,
      containertop: 0,
      userInfo: this.$store.state.userInfo,
      filtermin: 0,
      filtermax: 0,
      filterloop: {},
      slidedata: 0,
      min: 0,
      max: 0,
      searchfilterdata: {},
      originaldata: {},
      duplicatefilterdata: {},
      filtered_options: {},
      quoteThead: [
        {
          name: "ID",
          checked: true,
          id: 1,
          disabled: true,
          prop: "displayID",
          label: "ID",
          sortable: false,
          resizable: true,
          width: "80",
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 1,
          disabled: true,
          prop: "state",
          label: "Status",
          sortable: false,
          resizable: true,
          width: "160",
          align: "left",
        },
        {
          name: "Date",
          checked: true,
          id: 1,
          disabled: false,
          prop: "quoteDate",
          label: "Date",
          sortable: false,
          resizable: true,
          width: 200,
          align: "left",
        },
        {
          name: "Title",
          checked: true,
          id: 1,
          disabled: true,
          prop: "description",
          label: "Title",
          sortable: false,
          resizable: true,
          width: "auto",
          align: "left",
        },

        // {
        //   name: "category",
        //   checked: true,
        //   id: 1,
        //   disabled: false,
        //   prop: "category",
        //   label: "Category",
        //   sortable: false,
        //   resizable: true,
        //   width: "auto",
        //   align: "left",
        // },
        // {
        //   name: "Item",
        //   checked: true,
        //   id: 1,
        //   disabled: false,
        //   prop: "quoteItem",
        //   label: "Item",
        //   sortable: false,
        //   resizable: true,
        //   width: 60,
        //   align: "right",
        // },

        {
          name: "Account",
          checked: true,
          id: 1,
          disabled: false,
          prop: "Buyer",
          label: "Account",
          sortable: false,
          resizable: true,
          width: 180,
          align: "left",
        },
        {
          name: "Contact",
          checked: true,
          id: 1,
          disabled: false,
          prop: "BuyerContact",
          label: "Contact",
          sortable: false,
          resizable: true,
          width: 120,
          align: "left",
        },
        {
          name: this.$store.getters.getDefaultSettings?.currency?.unit
            ? "Total" +
              " (" +
              this.$store.getters.getDefaultSettings?.currency?.unit +
              ")"
            : "Total",
          checked: true,
          id: 1,
          disabled: true,
          prop: "quoteTotalPrice",
          label: this.$store.getters.getDefaultSettings?.currency?.unit
            ? "Total" +
              " (" +
              this.$store.getters.getDefaultSettings?.currency?.unit +
              ")"
            : "Total",
          sortable: false,
          resizable: true,
          width: 120,
          align: "left",
        },

        // {
        //   name: "Version",
        //   checked: true,
        //   id: 1,
        //   disabled: false,
        //   prop: "version",
        //   label: "Version",
        //   sortable: false,
        //   resizable: true,
        //   width: "auto",
        //   align: "right",
        // },
        // {
        //   name: "Last update",
        //   checked: true,
        //   id: 1,
        //   disabled: false,
        //   prop: "lastUpdate",
        //   label: "Last update",
        //   sortable: false,
        //   resizable: true,
        //   width: "auto",
        //   align: "left",
        // },
      ],

      quoteFilterOption: {
        type: "filter",
        module: "quotes",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        additionalPipelines: this.additionalFilterPipelines,
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {},
        searchQuery: "1",
      },
      quoteTheadC: [
        {
          name: "ID",
          checked: true,
          id: 1,
          disabled: true,
          prop: "displayID",
          label: "ID",
          sortable: false,
          resizable: true,
          width: "80",
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 1,
          disabled: true,
          prop: "state",
          label: "Status",
          sortable: false,
          resizable: true,
          width: "160",
          align: "left",
        },
        {
          name: "Date",
          checked: true,
          id: 1,
          disabled: false,
          prop: "quoteDate",
          label: "Date",
          sortable: false,
          resizable: true,
          width: 200,
          align: "left",
        },
        {
          name: "Title",
          checked: true,
          id: 1,
          disabled: true,
          prop: "description",
          label: "Title",
          sortable: false,
          resizable: true,
          width: "auto",
          align: "left",
        },

        // {
        //   name: "category",
        //   checked: true,
        //   id: 1,
        //   disabled: false,
        //   prop: "category",
        //   label: "Category",
        //   sortable: false,
        //   resizable: true,
        //   width: "auto",
        //   align: "left",
        // },
        // {
        //   name: "Item",
        //   checked: true,
        //   id: 1,
        //   disabled: false,
        //   prop: "quoteItem",
        //   label: "Item",
        //   sortable: false,
        //   resizable: true,
        //   width: 60,
        //   align: "right",
        // },

        {
          name: "Account",
          checked: true,
          id: 1,
          disabled: false,
          prop: "Buyer",
          label: "Account",
          sortable: false,
          resizable: true,
          width: 180,
          align: "left",
        },
        {
          name: "Contact",
          checked: true,
          id: 1,
          disabled: false,
          prop: "BuyerContact",
          label: "Contact",
          sortable: false,
          resizable: true,
          width: 120,
          align: "left",
        },
        {
          name: this.$store.getters.getDefaultSettings?.currency?.unit
            ? "Total" +
              " (" +
              this.$store.getters.getDefaultSettings?.currency?.unit +
              ")"
            : "Total",
          checked: true,
          id: 1,
          disabled: true,
          prop: "quoteTotalPrice",
          label: this.$store.getters.getDefaultSettings?.currency?.unit
            ? "Total" +
              " (" +
              this.$store.getters.getDefaultSettings?.currency?.unit +
              ")"
            : "Total",
          sortable: false,
          resizable: true,
          width: 120,
          align: "left",
        },
        // {
        //   name: "Version",
        //   checked: true,
        //   id: 1,
        //   disabled: false,
        //   prop: "version",
        //   label: "Version",
        //   sortable: false,
        //   resizable: true,
        //   width: "auto",
        //   align: "right",
        // },
        // {
        //   name: "Last update",
        //   checked: true,
        //   id: 1,
        //   disabled: false,
        //   prop: "lastUpdate",
        //   label: "Last update",
        //   sortable: false,
        //   resizable: true,
        //   width: "auto",
        //   align: "left",
        // },
      ],
      modal_active6: false,
      show_right: false,
      token: this.$route.params.token,
      editMode: true,
      editPermission: [],
      viewPermission: [],
      unique: Math.floor(Math.random() * 1000 + 1),
      delete_modal_active: false,
      searchtabledata: [],
      // businessrulepagespinner: true,
      // businessrulepagetable: false,
      duplicatetabledata: [],
      config_nav_items: [],
      delete_name: "",
      datum: {},
      success: false,
      success_msg: "",
      notification: {},
      selectedValue: "",
      // selectedValue: "Product offerings",
      show_add_menu: false,
      show_crud_menu: false,
      tableData: [],
      logData: [],
      toggleRearrangeModal: false,
      duplicateTheads: [],
      duplicateTheads1: [],
      searchvalue: "",
      deleteobj: {},
      filterobj: {},
      filtervalueobj: {},
      searchfiltervalue: "",
      filtertagvalue: [],
      filtertaglength: 0,
      filteredtabledata: [],
      filterpropqu: {},
    };
  },
  computed: {
    ...mapGetters(["currencyList"]),
    // records(){
    //   return this.$store.getters.allRule
    // },
    filteroptions() {
      return this.filterobj;
    },
    paginatedData() {
      return this.tableData;
    },

    totalRows() {
      return this.tableData.length;
    },
  },
  watch: {
    searchKey(value) {
      console.log(
        "zzzzzzzzzzzzzzzzzzzzzzzzzzzz 111 🚀 ~ quoteSearchHandler ~ value:",
        value
      );
      if (value.trim()) {
        this.handleQuoteSearch(value);
      } else {
        this.quoteFilterOption.type = "filter";
        this.quoteFilterOption.searchQuery = "";
        this.loadTable();
      }
    },
    filterQuerydata: {
      handler(val) {
        this.quotesfilterData();
        this.filterpropqu = val;
        console.log(
          "zzzzzzzzzzzzzzzzzzzzzzzzzzzz 333 filter123 filterpropquuuuuuu",
          val
        );
      },
      immediate: true,
      deep: true,
    },
    filterSelectedvaluedata: {
      handler(val) {
        console.log(
          "zzzzzzzzzzzzzzzzzzzzzzzzzzzz 222 filter123 filterSelectedvaluedata",
          val
        );

        console.log(val, this.filterQuerydata);
      },
      immediate: true,
      deep: true,
    },
    module: {
      handler(value) {
        console.log("🚀 ~ handler ~ value: module", value);
        if (!value) return;
        if (value == "accounts") {
          this.quoteThead = this.quoteThead.filter((e) => e.prop != "Buyer");
        } else if (value == "contacts") {
          this.quoteThead = this.quoteThead.filter(
            (e) => e.prop != "BuyerContact"
          );
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    // To get table header
    let accountTableHeads = await MLTAxiosInstance.get(`/settings`);

    if (accountTableHeads?.data?.entityRef?.["quoteMainTable"]) {
      let accountTableHeadModal =
        accountTableHeads.data.entityRef["quoteMainTable"];
      let accountTableHeadsOrg = accountTableHeads.data.entityRef[
        "quoteMainTable"
      ].filter((ele) => ele.checked);
      console.log(
        accountTableHeadsOrg,
        "accountTableHeadModal",
        accountTableHeadModal
      );

      if (
        accountTableHeadModal.length === this.quoteTheadC.length
        // &&
        // accountTableHeadModal.every((item, index) => item.name === this.quoteTheadC[index].name)
      ) {
        console.log(
          accountTableHeadsOrg,
          "accountTableHeadModal dup",
          accountTableHeadModal
        );
        this.quoteTheadC = JSON.parse(JSON.stringify(accountTableHeadModal));
        this.quoteThead = JSON.parse(JSON.stringify(accountTableHeadsOrg));
        this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.quoteTheadC));
        this.tableHeaderOrg = JSON.parse(JSON.stringify(this.quoteThead));
      }
    }

    this.$emit("requestChange", true);
    if (this.module == "accounts") {
      // this.quoteFilterOption.company = this.$route.params.companyName;
      this.quoteFilterOption.filterQuery = {
        ...this.quoteFilterOption.filterQuery,
        "relatedParty.role": "Buyer",
        "relatedParty.id": this.$route.params.id,
      };
    }
    if (this.module == "quoteHistory") {
      this.quoteFilterOption.filterQuery = {
        ...this.quoteFilterOption.filterQuery,
        displayID: this.$route.query.id,
      };
      this.quoteFilterOption.additionalPipelines = [];
      this.quoteFilterOption.sortFieldAndOrder = {
        version: -1,
        lastUpdate: -1,
      };
    }
    if (this.module == "contacts") {
      // this.quoteFilterOption.company =
      //   this.$route.params.from == "contacts"
      //     ? this.$route.query.company
      //     : this.$route.params.name;

      this.quoteFilterOption.filterQuery = {
        ...this.quoteFilterOption.filterQuery,
        "relatedParty.role": "Buyer contact",
        "relatedParty.id": this.$route.params.id,
        //   $and:[
        //     {
        //     },
        // {
        //   "relatedParty.role":"Buyer",
        //   "relatedParty.id":this.$route.params.from == 'accounts'?this.$route.query.companyId:""
        // }
        //   ]
      };
    }
    const filterResultData = await UPCAxiosInstance.post(
      `util/filterData`,
      this.quoteFilterOption
    );

    this.datalength = filterResultData.data?.total;
    this.tableData = filterResultData.data.data;
    console.log(this.tableData, "lolo");
    this.totalpageno = filterResultData.data?.total;
    // this.paginatedData;
    this.$emit("requestChange", false);
    console.log("🚀 ~ mounted ~ filterResultData:", filterResultData);
    console.log("🚀 ~ mounted ~ this.paginatedData:", this.paginatedData);
  },

  methods: {
    reAssignOrgTableHeader() {
      // If there is change in the table header then ressign to the Orginal data
      if (
        this.quoteTheadC != this.tableHeaderOrgcc &&
        this.tableHeaderOrgcc.length > 0
      ) {
        this.quoteTheadC = JSON.parse(JSON.stringify(this.tableHeaderOrgcc));
      }
      if (
        this.quoteThead != this.tableHeaderOrg &&
        this.tableHeaderOrg.length > 0
      ) {
        this.quoteThead = JSON.parse(JSON.stringify(this.tableHeaderOrg));
      }
      this.toggleRearrangeModal = true;
    },
    resizedWidth(val) {
      console.log(val, "resizedWidth", val);

      const updateWidth = (array) => {
        let item = array.find((i) => i?.prop === val?.prop);
        if (item) item.width = val.width;
      };

      updateWidth(this.quoteThead);
      updateWidth(this.quoteTheadC);
      console.log(this.quoteThead, "resizedWidth loop", this.quoteTheadC);
      this.applyColumns(this.quoteTheadC);
    },
    statusVmodel(val) {
      if (!val) return "null";

      const status = val.toLowerCase();

      switch (status) {
        case "inprogress":
          this.statusBool = false;
          return "Draft";
        case "awaiting approval":
          this.statusBool = false;
          return "Awaiting approval";
        case "approved":
          this.statusBool = false;
          return "Approved";
        case "send":
          this.statusBool = false;
          return "Send";
        case "completed":
          this.statusBool = true;
          return "Completed";
        case "expired":
          this.statusBool = true;
          return "Expired";
        case "declined":
          this.statusBool = true;
          return "Declined";
        case "rejected":
          this.statusBool = true;
          return "Rejected";
        case "draft":
          this.statusBool = true;
          return "Draft";
        case "sent":
          this.statusBool = true;
          return "Sent";
        default:
          return status;
      }
    },
    async quotesfilterData() {
      this.$emit("quotesfilterDatas");
      console.log(
        "funcall",
        this.filterSelectedvaluedata,
        this.filterQuerydata
      );
      if (this.filterSelectedvaluedata.length > 0) {
        this.filtertagvalue = this.filterSelectedvaluedata;
        this.filtertaglength = this.filterSelectedvaluedata.length;
        this.filterpropqu.company = this.company;
        //  filterQuery['filterQuery']['"@baseType"'] = 'supplier'
        if (this.filtertagvalue.length != 0) {
          this.filterQuery = this.filterQuerydata;
          this.filterQuery.type = "filter";
          if (this.searchvalue != "") {
            this.filterQuery["searchQuery"] = this.searchvalue;
            this.filterQuery["type"] = "filter-search";
          }
          console.log("filter123 newwww", this.filterQuery);

          const filterResultData = await UPCAxiosInstance.post(
            `util/filterData`,
            this.filterQuery
          );
          this.tableData = filterResultData.data.data;
          this.totalpageno = filterResultData.data.total;
        } else {
          this.currPage = 1;
          this.rowsPerPage = 10;
          this.specificationdataCalling(this.currPage, this.rowsPerPage);
        }
      } else {
        console.log("filter123 oldddd", this.filterQuery);
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          this.filterQuery
        );
        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data.total;
      }
    },
    computedQuoteState(state, authorization) {
      // Find client signature status
      let clientSignature = authorization.find(
        (auth) => auth?.name == "Quote client confirmation"
      );
      return state
        ? state.toLowerCase() == "accepted"
          ? "Completed"
          : state.toLowerCase() == "pending"
          ? "Awaiting approval"
          : state.toLowerCase() == "approved" &&
            clientSignature &&
            clientSignature.state?.toLowerCase() == "inprogress"
          ? // ? "Approved"  change by asif
            "Sent"
          : state.toLowerCase() == "approved" &&
            clientSignature &&
            clientSignature.state?.toLowerCase() == "pending"
          ? "Sent"
          : state.toLowerCase() == "rejected"
          ? "Rejected"
          : state.toLowerCase() == "cancelled"
          ? "Rejected"
          : state.toLowerCase() == "inprogress" &&
            clientSignature &&
            clientSignature.state?.toLowerCase() == "inprogress"
          ? "Draft"
          : state.charAt(0).toUpperCase() + state.slice(1).toLowerCase()
        : "";
    },
    findClientCompany(parties) {
      const isRecipientOrg = parties.find((party) => party.role == "Buyer");
      if (isRecipientOrg && isRecipientOrg.name) {
        return isRecipientOrg.name;
      }
      return "-";
    },
    findClientCompanyContact(parties) {
      const isRecipientOrg = parties.find(
        (party) => party.role == "Buyer contact"
      );
      if (isRecipientOrg && isRecipientOrg.name) {
        return isRecipientOrg.name;
      }
      return "-";
    },
    computedQuotePrice(totalPrices) {
      if (!totalPrices) return this.currencyFormatter(0);
      return totalPrices.filter(
        (price) =>
          price &&
          price.quoteTotal &&
          price.quoteTotal.value &&
          ![0, 0.0, "0", "0.00", "NaN"].includes(price.quoteTotal.value?.trim())
      );
    },
    computePrice(totalPrices) {
      console.log("🚀 ~ computePrice ~ totalPrices:", totalPrices);
      if (!totalPrices) return [];
      // Find totalContractAmount
      let totalContractValue = totalPrices.find(
        (price) =>
          price && price["@type"] && price["@type"] == "totalContractValue"
      );
      if (totalContractValue) {
        return totalContractValue.price.value
          ? totalContractValue.price.value
          : 0;
      }
      let prices = totalPrices.map((price) => {
        console.log("🚀 ~ prices ~ price:", price);
        if (
          price &&
          price.price.dutyFreeAmount &&
          price.price.dutyFreeAmount.value &&
          typeof price.price.dutyFreeAmount.value == "string" &&
          ![0, 0.0, "0", "0.00", "NaN"].includes(
            price.price.dutyFreeAmount.value?.trim()
          )
        ) {
          return parseFloat(price.price.dutyFreeAmount.value?.trim());
        } else if (
          price &&
          price.price.dutyFreeAmount &&
          price.price.dutyFreeAmount.value &&
          typeof price.price.dutyFreeAmount.value == "number" &&
          ![0, 0.0, "0", "0.00", "NaN", NaN].includes(
            price.price.dutyFreeAmount.value
          )
        ) {
          return price.price.dutyFreeAmount.value;
        }
        return 0;
      });
      console.log("🚀 ~ computePrice ~ prices:", prices);
      // console.log("🚀 ~ computePrice ~ prices:", prices)
      let finalPrice = prices.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);
      console.log("🚀 ~ finalPrice ~ finalPrice:", finalPrice);
      return finalPrice;
    },
    showDrawerRight() {
      this.show_right_filter = true;
    },
    closeRightFilter() {
      this.show_right_filter = false;
    },
    async sliderval(val1, val2) {
      val1;
      val2;
      this.filterprice.checked = true;
      this.filterprice.value =
        this.filterprice.min + "-" + this.filterprice.max;
      // let obj = {};
      // obj.filter = this.filtertagvalue;
      if (this.sliderfilter == false) {
        this.filtertagvalue.push(this.filterprice);
        this.sliderfilter = true;
      }
      let obj = {};
      obj.filter = this.filtertagvalue;
      obj.currentpage = this.filtercurrPage;
      obj.rowsperpage = this.filterrowsPerPage;
      this.$emit("requestChange", true);

      const filtertabledata = await UPCAxiosInstance.post(
        `catalogManagement/productOffering/filter`,
        obj
      );
      this.totalpageno = await filtertabledata.data.totallength;
      this.tableData = filtertabledata.data.data;
      this.tableData?.map((e) => {
        if (e.context == undefined) {
          e.context = false;
        }
      });
      this.$emit("requestChange", false);
    },
    tooltipover(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis(data1, index1);
    },
    tooltipover1(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis1(data1, index1);
    },
    tooltipleave(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses(data1, index1);
    },
    tooltipleave1(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses1(data1, index1);
    },
    ellipsis(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              let height = tooltip.offsetHeight;
              let top = element1.getBoundingClientRect().top - height;
              let left = element1.getBoundingClientRect().left;
              tooltip.style.display = "block";
              tooltip.style.top = top + "px";
              tooltip.style.left = left + "px";
              return true;
            }
          }
          return false;
        }
      });
    },
    ellipsis1(data, index) {
      this.$nextTick(() => {
        // if(event.target.tagName != 'SPAN')
        // {
        // console.log(data,index,event.target.tagName);
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let height = tooltip.offsetHeight;
              let top = element1.getBoundingClientRect().top - 30;
              top = Math.abs(top);
              let left = element1.getBoundingClientRect().left + 200;
              tooltip.style.display = "block";
              tooltip.style.top = top + "px";
              tooltip.style.left = left + "px";
              return true;
            }
          }
          return false;
        }
        // }
      });
    },
    ellipses(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              tooltip.style.display = "none";
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },
    ellipses1(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              tooltip.style.display = "none";
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },
    async filterdatafunction() {
      // ;
      if (this.selectedvalue == "Product specifications") {
        await this.$store.dispatch("productSpecFilterData");

        this.filterdata = this.$store.getters.productSpecificationFilterData;
        // const alldata = await UPCAxiosInstance.get(`catalogManagement/productSpecification/filterlist/v1`);
        // this.filterdata = alldata.data
        // ;
      } else {
        await this.$store.dispatch("productOfferFilterData");

        this.filterdata = this.$store.getters.productOfferingFilterData;
        Object.keys(this.filterdata).forEach((e) => {
          // ;
          if (e != "_id") {
            this.filterdata[e]?.forEach((ele) => {
              if (typeof ele == "object") {
                if (!ele) return;

                ele.key = e;
                ele.checked = false;
              }
            });
          }
          if (e == "Product offering price") {
            this.filterdata[e]?.forEach((ele) => {
              if (ele.price.amount != "") {
                if (this.offeringprice.includes(ele.price.amount) != true) {
                  this.offeringprice.push(ele.price.amount);
                }
              }
            });
          }
        });

        // this.datacalling()

        // const alldata = await UPCAxiosInstance.get(
        //   `catalogManagement/productOffering/filterlist`
        // );
        // this.filterdata = alldata.data;
        // ;
        // let pricedata = this.filterdata['productOfferingPrice'][this.currentvalue]
        // if(pricedata.length > 1)
        // {
        //   this.filterprice.min = pricedata[0]-1
        // }else if(pricedata.length == 1){
        //    this.filterprice.min = 0
        // }
        // this.filterprice.max = pricedata[pricedata.length - 1]+1
        // this.sliderMin = this.filterprice.min
        // this.sliderMax = this.filterprice.max
        // this.filterprice.min = this.filterdata['productOfferingPrice'][0].min
        // this.sliderMin = this.filterprice.min - 1
        // this.filterprice.max = this.filterdata['productOfferingPrice'][0].max
        // this.sliderMax = this.filterprice.max - 1
        // ,typeof(this.filterprice.max));
      }
    },
    async switchfilterdatafunction(val) {
      if (val == "Product specifications") {
        await this.$store.dispatch("productSpecFilterData");

        this.filterdata = this.$store.getters.productSpecificationFilterData;
      } else {
        await this.$store.dispatch("productOfferFilterData");

        this.filterdata = this.$store.getters.productOfferingFilterData;
        Object.keys(this.filterdata).forEach((e) => {
          // ;
          if (e != "_id") {
            this.filterdata[e]?.forEach((ele) => {
              if (typeof ele == "object") {
                if (!ele) return;

                ele.checked = false;
              }
            });
          }
          if (e == "Product offering price") {
            this.filterdata[e]?.forEach((ele) => {
              if (ele.price.amount != "") {
                if (this.offeringprice.includes(ele.price.amount) != true) {
                  this.offeringprice.push(ele.price.amount);
                  // );
                }
              }
            });
          }
        });
      }
    },
    insertSpaces(string) {
      let str = string
        .replace(/(_|-)/g, " ")
        .trim()
        .replace(/\w\S*/g, function (str) {
          return str.charAt(0).toUpperCase() + str.substr(1);
        })
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
      return str;
    },
    mindata(val) {
      this.min = val;
    },
    maxdata(val) {
      this.max = val;
    },
    openNav() {
      //  document.getElementById("mySidepanel").style.width = "250px";
      document.getElementById("mySidepanel").style.visibility = "visible";
      console.log("filter side pannala");
    },
    closeNav() {
      document.getElementById("mySidepanel").style.visibility = "hidden";
    },

    change(val) {
      if (this.filtertagvalue.length == 0) {
        let obj = {};
        obj.value = val;
        this.filtertagvalue.push(obj);
      }
    },
    searchfilter($event, key) {
      this.searchfilterdata[key] = $event;
      this.searchfiltervalue = $event;
      if (
        this.searchfiltervalue &&
        this.searchfiltervalue != "" &&
        this.originaldata != undefined
      ) {
        // ;
        this.filterdat[key] = this.originaldata[key].filter((item) => {
          return item.value
            .toLowerCase()
            .toString()
            .includes(this.searchfiltervalue.toLowerCase());
        });
      } else {
        this.filterdat = JSON.parse(JSON.stringify(this.originaldata));
      }
    },
    selectedvalue($event, data) {
      let finaldata = [];
      //
      //
      let arr_data = $event;

      let arr = [];
      arr_data.forEach((e) => {
        if (e.checked == true) {
          //
          arr.push(e.value);
        }
      });
      this.filtervalueobj[data] = arr;

      Object.keys(this.filtervalueobj).forEach((e) => {
        finaldata = [];
        this.filtervalueobj[e].forEach((ele) => {
          this.tableData?.forEach((element) => {
            if (element[e] === ele) {
              finaldata.push(element);
            } else if (element[e] === null) {
              finaldata.push(element);
            }
          });
        });
      });
      this.tableData = finaldata;
      //
      if (Object.keys(this.filtervalueobj).length <= 1) {
        this.filterFunction();
        //
      } else {
        let x = 0;
        let y = 0;
        while (x < Object.keys(this.filterobj).length) {
          while (y < Object.keys(this.filtervalueobj).length) {
            if (
              Object.keys(this.filterobj)[x] !=
              Object.keys(this.filtervalueobj)[y]
            ) {
              var arr5 = [];
              let arr6 = [];
              let arr7 = [];
              finaldata.forEach((element) => {
                arr5.push(element[Object.keys(this.filterobj)[x]]);
              });
              arr5.forEach((element) => {
                if (!arr6.includes(element)) {
                  arr6.push(element);
                }
              });
              arr6.forEach((element) => {
                let obj = {};
                //
                // if(element != null)
                // {
                obj["value"] = element;
                obj["name"] = element;
                obj.checked = true;
                obj.disabled = false;
                arr7.push(obj);
              });
              this.filterobj[Object.keys(this.filterobj)] = arr7;
            }
            y++;
          }
          x++;
        }
      }
      this.filterobj[data] = $event;
    },
    closeRight() {
      this.show_right = false;
    },
    computedTableAction(data, permission) {
  console.log("🚀 ~ computedTableAction ~ data:", data);

  let options = [
    {
      label: "Edit",
      icon: "icon-file-text-edit-regular",
    },
    {
      label: "Clone",
      icon: "icon-copy-alt-regular",
    },
    {
      label: "Create new version",
      icon: "icon-file-plus-regular",
    },
    {
      label: "Delete",
      icon: "icon-trash-regular",
    },
    {
      label: "Preview",
      icon: "icon-caret-left-circle-regular",
    },
    {
      label: "Version history",
      icon: "icon-file-clock-regular",
    },
  ];

  // Filter options based on permissions
  options = options.filter(option => {
    if (permission.all) {
      return true; // Include all options
    }
    if (permission.partially) {
      return ["Preview", "Version history"].includes(option.label); // Include only view-related options
    }
    let allowedOptions = [];
    if (permission.view) {
      allowedOptions.push("Preview", "Version history");
    }
    if (permission.edit) {
      allowedOptions.push("Edit");
    }
    if (permission.delete) {
      allowedOptions.push("Delete");
    }
    if (permission.add) {
      allowedOptions.push("Clone", "Create new version");
    }
    return allowedOptions.includes(option.label);
  });

  // Apply additional filtering based on `data`
  let updatedOptions = options.filter(option => {
    if (
      this.module == "quoteHistory" &&
      ["Create new version", "Version history"].includes(option.label)
    ) {
      return false;
    }
    if (
      this.module == "quoteHistory" &&
      ["Edit"].includes(option.label) &&
      data?.version !== this.$route?.query?.version
    ) {
      return false;
    }
    if (
      data &&
      data.state &&
      !["inprogress"].includes(data.state.toLowerCase()) &&
      ["Edit"].includes(option.label)
    ) {
      return false;
    } else if (
      data &&
      data.state &&
      data.state.toLowerCase() == "accepted" &&
      ["Delete"].includes(option.label)
    ) {
      return false;
    } else if (
      data &&
      data.state &&
      data.state.toLowerCase() != "rejected" &&
      ["Create new version"].includes(option.label)
    ) {
      return false;
    } else if (
      data &&
      data.version &&
      data.version == "1" &&
      ["Version history"].includes(option.label)
    ) {
      return false;
    } else {
      return true;
    }
  });

  console.log("🚀 ~ updatedOptions ~ updatedOptions:", updatedOptions);
  return updatedOptions;
}
,
    getWidth(id) {
      const descriptionDiv = document.getElementById(id);
      if (descriptionDiv) {
        // //
        return descriptionDiv.offsetWidth + "px";
      }
    },
    async applyColumns(val) {
      // Func to rearrange table header
      console.log("applyColumns", val);
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
      let concatedEntity = sampleTheads?.data?.entityRef || {};
      concatedEntity.quoteMainTable = val;

      let reArrangeTableHeadPayload = { entityRef: concatedEntity };

      const changeTableHeader = await MLTAxiosInstance.patch(
        `/settings`,
        reArrangeTableHeadPayload
      );
      console.log(
        changeTableHeader,
        "reArrangeTableHeadPayload",
        reArrangeTableHeadPayload
      );

      let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
      console.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

      let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
      this.quoteTheadC = entityRef["quoteMainTable"];
      this.quoteThead = this.quoteTheadC.filter((ele) => ele.checked);
      this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.quoteTheadC));
      this.tableHeaderOrg = JSON.parse(JSON.stringify(this.quoteThead));
    },
    updateTheads() {
      if (this.view === "Product offerings") {
        // Use the map function to update the 'checked' property
        this.product_offerings_theads_cc = this.product_offerings_theads_cc.map(
          (thead) => {
            // Check if the current thead has a matching 'prop' in duplicateTheads
            const isMatching = this.product_offerings_theads.some(
              (duplicateThead) => duplicateThead.prop === thead.prop
            );

            // Set the 'checked' property based on whether it's matching
            return { ...thead, checked: isMatching };
          }
        );
      } else if (this.view === "Product specifications") {
        this.quoteTheadC = this.quoteTheadC.map((thead) => {
          // Check if the current thead has a matching 'prop' in duplicateTheads
          const isMatching = this.quoteThead.some(
            (duplicateThead) => duplicateThead.prop === thead.prop
          );

          // Set the 'checked' property based on whether it's matching
          return { ...thead, checked: isMatching };
        });
      }
    },
    arrange(val) {
      // ;
      this.toggleRearrangeModal = val;
      // this.updateTheads();
    },
    convertdateformat(date1) {
      if (date1 != null) {
        let objectDate = new Date(date1);
        if (isNaN(objectDate) == false) {
          let date = objectDate.toLocaleString("default", { day: "numeric" });
          let month = "";
          let format = this.$store.state.defaultSetting.dateFormat;
          if (
            format == "Month DD, YYYY" ||
            format == "mmmm dd, yyyy" ||
            format == "mmmm dd, yyyy"
          ) {
            month = objectDate.toLocaleString("default", { month: "long" });
          } else {
            month = objectDate.toLocaleString("default", { month: "short" });
          }
          let year = objectDate.getFullYear();
          let dateFormat = this.dateFormat(date, month, year, format);
          // let dateformat = month + " " + date + ", " + year;
          return dateFormat;
        }
      }
    },
    async refreshTable() {
      this.$emit("requestChange", true);

      if (this.module == "accounts") {
        // this.quoteFilterOption.company = this.$route.params.companyName;
        this.quoteFilterOption.filterQuery = {
          ...this.quoteFilterOption.filterQuery,
          "relatedParty.role": "Buyer",
          "relatedParty.id": this.$route.params.id,
        };
      }
      if (this.module == "quoteHistory") {
        this.quoteFilterOption.filterQuery = {
          ...this.quoteFilterOption.filterQuery,
          displayID: this.$route.query.id,
        };
        this.quoteFilterOption.additionalPipelines = [];
        this.quoteFilterOption.sortFieldAndOrder = {
          version: -1,
          lastUpdate: -1,
        };
      }
      if (this.module == "contacts") {
        // this.quoteFilterOption.company =
        //   this.$route.params.from == "contacts"
        //     ? this.$route.query.company
        //     : this.$route.params.name;
        this.quoteFilterOption.filterQuery = {
          ...this.quoteFilterOption.filterQuery,
          "relatedParty.role": "Buyer contact",
          "relatedParty.id": this.$route.params.id,
        };
      }
      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.quoteFilterOption
      );

      this.tableData = filterResultData.data.data;
      this.totalpageno = filterResultData.data.total;
      this.paginatedData;
      this.$emit("requestChange", false);
    },

    formatCurrentTime() {
      const now = new Date();
      const year = now.getUTCFullYear();
      const month = String(now.getUTCMonth() + 1).padStart(2, "0");
      const day = String(now.getUTCDate()).padStart(2, "0");
      const hours = String(now.getUTCHours()).padStart(2, "0");
      const minutes = String(now.getUTCMinutes()).padStart(2, "0");
      const seconds = String(now.getUTCSeconds()).padStart(2, "0");
      const milliseconds = String(now.getUTCMilliseconds()).padStart(3, "0");

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    },

    async closeCrudContextMenu($event, ele, i) {
      // //
      if ($event.toLowerCase() === "edit") {
        this.$router.push({
          name: "CPQDefaultTemplate",
          query: { quote_id: ele._id, mode: "edit" },
        });
      } else if ($event.toLowerCase() === "clone") {
        // delete ele._id;

        ele.displayID = "";
        ele.id = "";
        ele._id = "";
        ele.state = "inProgress";
        ele.description = ele.description + "_copy";
        ele.lastUpdate = `${this.formatCurrentTime()}`;
        ele.createdAt = `${this.formatCurrentTime()}`;
        ele.updatedAt = `${this.formatCurrentTime()}`;

        // const clonedata = await UPCAxiosInstance.post(
        //   `/catalogManagement/productSpecification/clone`,
        //   ele
        // );
        const createdOrUpdatedQuote = await UPCAxiosInstance.post(
          "/quotemanagement",
          ele
        );
        console.log("cloning quote.....", createdOrUpdatedQuote, ele);
        this.refreshTable();
        if (createdOrUpdatedQuote.status == 201) {
          this.notification = {
            type: "success",
            message: `Nice work! Your Quotation ${ele.displayID} is cloned!`,
          };
        }
      } else if ($event.toLowerCase() == "delete") {
        this.delete_modal_active = true;
        this.deleteobj = ele;
        this.delete_name = ele.description;
        this.deleterowindex = i;
        // await axios.delete("http://localhost:5700/quotemanagement/" + ele._id, {
        //   headers: {
        //     Authorization: this.$cookies.get("token"),
        //   },
        // });
      } else if ($event && $event.toLowerCase() == "send an email") {
        let quoteInfo = ele;
        console.log("🚀 ~ closeCrudContextMenu ~ quoteInfo:", quoteInfo);
        let sender = quoteInfo.relatedParty.find(
          (item) => item.role == "Seller"
        );
        let sellerEmail = quoteInfo.contactMedium.find(
          (item) => item.mediumType == "sellerEmail"
        );
        let recipient = quoteInfo.relatedParty.find(
          (item) => item.role == "Buyer"
        );
        let buyerEmail = quoteInfo.contactMedium.find(
          (item) => item.mediumType == "buyerEmail"
        );

        let validToDate = Math.round(
          (new Date(quoteInfo.requestedQuoteCompletionDate).getTime() -
            new Date().getTime()) /
            (1000 * 60 * 60 * 24)
        );
        console.log("🚀 ~ closeCrudContextMenu ~ validToDate:", validToDate);
        let tokenPayload = {
          user: {
            email: buyerEmail.characteristic.emailAddress,
            company: recipient.name,
          },
          validTo: validToDate,
        };
        let generatedToken = await MLTAxiosInstance.post(
          "/partymanagement/individual/generateToken",
          tokenPayload
        );
        console.log(
          "🚀 ~ closeCrudContextMenu ~ generatedToken:",
          generatedToken
        );
        generatedToken = generatedToken.data;
        let quoteInvitationEmailContent = `

        <!DOCTYPE html
            PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
        <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
            xmlns:o="urn:schemas-microsoft-com:office:office">
        <head>
            <!--[if gte mso 9]>  <xml>   <o:OfficeDocumentSettings>   <o:AllowPNG/>   <o:PixelsPerInch>96</o:PixelsPerInch>   </o:OfficeDocumentSettings>  </xml>  <![endif]-->
            <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="format-detection" content="date=no" />
            <meta name="format-detection" content="address=no" />
            <meta name="format-detection" content="telephone=no" />
            <meta name="x-apple-disable-message-reformatting" />
            <style type="text/css" media="screen">
                <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900" rel="stylesheet"><link href='https://fonts.googleapis.com/css?family=Open Sans' rel='stylesheet'><link href='https://fonts.googleapis.com/css?family=Quicksand&display=swap' rel='stylesheet'><link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
            </style>
        </head>
        <body class="body"
            style="padding:0 !important; margin:0 !important; display:block !important; min-width:100% !important; width:100% !important; background:#f5f5f5; -webkit-text-size-adjust:none;">
            <!-- MOBILE PREHEADER PUSH ANY CONTENT AFTER THE PREHEADER FROM SHOWING UP INSERT UNDER BODY TAG -->
            <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#f5f5f5">
                <tr>
                    <td align="center" valign="top">
                        <table border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
                            <tr>
                                <td class="td">
                                    <table border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                            <td style="width: 612px;height: 501px;">
                                                <!-- Header -->
                                                <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                    bgcolor="#ffffff">
                                                    <tr>
                                                        <td>
                                                            <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                                class="wrapper">
                                                                <tr>
                                                                    <td>
                                                                        <table border="0" cellpadding="10" cellspacing="0"
                                                                            width="100%" class="wrapper" bgcolor="#f5f5f5">
                                                                            <tr>
                                                                                <!-- <td width="104"><a href="https://halleyx.com"><img src="https://srv451-files.hstgr.io/9e33e5905bbb9f5d/files/public_html/img/halleyx-logo-line-black.05c516d1.svg" height="50" style="display:block; border:none;padding-top: 20px;" alt="logo"></a></td> -->
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <table border="0" cellpadding="0" cellspacing="0"
                                                                            style="border:none;border-collapse:collapse;"
                                                                            width="100%" class="wrapper" bgcolor="#ffffff">
                                                                            <tr>
                                                                                <td width="104"><a
                                                                                        href="https://halleyx.com"><img
                                                                                            src="https://halleyx.com/img/halleyx-logo-line-black.42985758.svg"
                                                                                            height="50"
                                                                                            style="display:block; border:none;padding-top: 20px; padding-left: 30px;"
                                                                                            alt="logo"></a></td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!-- END Header -->
                                                <!-- Start Content -->
                                                <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0" height="501"
                                                    cellpadding="0">
                                                    <tr>
                                                        <td bgcolor="#FFFFFF" class="p35-15">
                                                            <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0"
                                                                cellpadding="0">
                                                                <!-- headline -->
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="headline"
                                                                        style="text-align:center;">
                                                                        <img style="width: 15%;
                                                                        object-fit: contain;
                                                                        clip-path: circle();
                                                                        " src="https://srv451-files.hstgr.io/9e33e5905bbb9f5d/files/public_html/img/halleyx-logo-line-black.05c516d1.svg"
                                                                            alt="" srcset="">
                                                                    </td>
                                                                </tr>
                                                                <!-- #headline -->
                                                                <!--  hero-title-1 -->
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="hero-title-1" style="font-family: 'Quicksand';
                                                                        font-style: normal;
                                                                        font-weight: 600;
                                                                        font-size: 22px;
                                                                        padding-top:15px;
                                                                        line-height: 28px;
                                                                        text-align: center;
                                                                        color: #191A15;">
                                                                        ${recipient.name}
                                                                        <br>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="hero-title-1"
                                                                        style="padding-top:25px;padding-left:100px;padding-right:100px;color:black;  font-family: Open Sans; font-size:14px; line-height:25px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                                        Thank you for providing the quote for <a
                                                                            href="mailto:someone@example.com"
                                                                            style="text-decoration: none;color:#54BD95">${sender.name}</a>.
                                                                        After careful consideration, we are pleased to accept
                                                                        your offer.
                                                                        <br>
                                                                        We are confident that your expertise and professionalism
                                                                        will contribute greatly to our project/goals. Please
                                                                        proceed
                                                                        with the necessary arrangements, and feel free to reach
                                                                        out if any further information is required from our end.
                                                                        Looking forward to a successful collaboration.
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="hero-title-1" valign="top"
                                                                        style="color:black; font-family: Open Sans;padding-top: 15px;  font-size:14px; line-height:25px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                                        <div class="btn"
                                                                            style="text-align:center;padding-top: 25px;  ">
                                                                            <!--[if mso]>                                                                     <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://www.fitbit.com/campaign/shop/versa?color=black&utm_source=ET&utm_medium=EM&utm_campaign=20191117_PreBFV2_US_FM_M_SU_SW_32_NA&utm_content=HeroCTALink" style="font-family: Arial, Helvetica, sans-serif;height:36px;v-text-anchor:middle;width:150px;" arcsize="152%" stroke="f" fillcolor="#002A3A">                                                                     <w:anchorlock/>                                                                     <center>                                                                     <![endif]-->
                                                                            <a href='http://localhost:8080/UPC/Quotes/signerPreview?client=${recipient.name}&clientId=${recipient.id}&quote=${quoteInfo._id}&access=${generatedToken.token}'
                                                                                class="mob-btn" target="_blank"
                                                                                style="background-color:#54BD95;border-radius:6px;color:#ffffff;font-family:Open Sans;font-size:14px; text-align:center;text-decoration:none;width: 218.18px;
                                                                            height: 38px;-webkit-text-size-adjust:none; padding: 10px 12px; line-height: 15px;">Accept
                                                                                Quote
                                                                            </a>
                                                                            <!--[if mso]>                                                                     </center>                                                                     </v:roundrect>                                                                     <![endif]-->
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#FFFFFF" class="hero-title-1" style="padding-top:40px;padding-left:80px;padding-right:80px;font-family: 'Quicksand';
                                                                        font-style: normal;
                                                                        font-weight: 400;
                                                                        font-size: 14px;
                                                                        line-height: 18px;
                                                                        text-align: center;
                                                                        color: #A6A6A6;">
                                                                        Hiccups might happen. Your feedback and requests are
                                                                        very welcomed.
                                                                        <a href="mailto:${sellerEmail?.characteristic?.emailAddress}"
                                                                            style="text-decoration: none;color:#54BD95">${sender.name}</a>
                                                                    </td>
                                                                </tr>
                                                        </td>
                                                    </tr>
                                                    <!-- END HERO -->
                                                </table>
                                                <!-- HERO Images-->
                                            </td>
                                        </tr>
                                    </table>
                                    <!-- END Content -->
                                    <!--Features-->
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                            <td>
                                                <!--Start Content-->
                                                <!--Desktop RTBs-->
                                                <!--End Desktop RTBs-->
                                                <!--Mobile RTBs-->
                                                <!--[if !mso]><!-->
                                                <!--End Mobile RTBs-->
                                                <!--<![endif]-->
                                                <!--END Features-->
                                                <!-- Footer -->
                                                <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                    style="padding: 0 15px;" bgcolor="#ffffff">
                                                    <tr>
                                                        <td bgcolor="#161C2B" class="disclaimer2"
                                                            style="padding: 40px 77px 40px 77px;color:#FFFFFF; font-family: Open Sans; font-size:14px; line-height:20px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                            <span><a href="https://halleyx.com"
                                                                    style="color: #FFFFFF;font-size: 14px;"><u>T&C</u><u
                                                                        style="padding-left:40px;padding-right: 40px;">Privacy</u><u>Cookies</u></a></span><br />
                                                            <br />
                                                            © 2023 Halleyx Inc. Copyright and rights reserved <p
                                                                style="padding-left: 17px !important; font-family:Open Sans; font-size: 14px; color: #FFFFFF;margin-top: 15px;  margin-bottom: 0px; letter-spacing: .01em;line-height: 20px;">
                                                                18 King St E #1400, Toronto, ON M5C 1C4
                                                            </p>
                                                            <span style="float:left;margin-left: -60px;padding-top:20px;"><img
                                                                    width="100"
                                                                    src="https://halleyx.com/img/halleyx-logo-line-white.c09fdc43.svg" /></span>
                                                            <span
                                                                style="float: right;margin-right:-50px;padding-top:20px;color: #FFFFFF;"><a
                                                                    href="https://www.linkedin.com/company/halleyx/"><i
                                                                        class="fa fa-linkedin fa-lg"
                                                                        style="color: #ffffff;"></i></a>
                                                                <a href="https://halleyx.com/"
                                                                    style="padding-left:5px;padding-right: 5px;"><i
                                                                        class="fa fa-facebook fa-lg"
                                                                        style="color: #ffffff;"></i>
                                                                </a>
                                                                <a href="https://www.instagram.com/halleyx_inc/"><i
                                                                        class="fa fa-instagram fa-lg"
                                                                        style="color: #ffffff;"></i></a></span>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!-- Footer -->
                                                <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                    bgcolor="#ffffff">
                                                    <tr>
                                                        <td>
                                                            <style>
                                                                .nowrap {
                                                                    word-break: keep-all;
                                                                    -moz-hyphens: none;
                                                                    -ms-hyphens: none;
                                                                    -webkit-hyphens: none;
                                                                    hyphens: none;
                                                                }
                                                                a[x-apple-data-detectors] {
                                                                    color: inherit !important;
                                                                    text-decoration: none !important;
                                                                    font-size: inherit !important;
                                                                    font-family: inherit !important;
                                                                    font-weight: inherit !important;
                                                                    line-height: inherit !important;
                                                                }
                                                            </style>
                                                            <table border="0" cellpadding="0" cellspacing="0"
                                                                style="border:none;border-collapse:collapse; background-color:#ffffff;"
                                                                width="100%" class="wrapper mobileContainer">
                                                                <tr>
                                                                    <td class="mobileContainer" align="center"
                                                                        style="font-family:Helvetica, Arial, sans-serif; color:#939c9e; font-size:0px; padding-top:24px; font-weight:100; background-color:#ffffff; width:100%; ">
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!-- END Footer -->
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
            <img src="https://srv451-files.hstgr.io/9e33e5905bbb9f5d/files/public_html/img/halleyx-logo-line-white.1535d8d0.svg"
                width="1" height="1"></custom>
        </body>
        </html>`;
        this.emailTemp = quoteInvitationEmailContent;
        console.log(
          "🚀 ~ closeCrudContextMenu ~ quoteInvitationEmailContent:",
          quoteInvitationEmailContent
        );
      } else if ($event && $event.toLowerCase() == "preview") {
        let quoteInfo = ele;
        console.log("🚀 ~ closeCrudContextMenu ~ quoteInfo:", quoteInfo);
        const clientInfo = quoteInfo.relatedParty.find(
          (item) => item.role == "Buyer"
        );
        if (!clientInfo) return;
        if (this.module == "accounts") {
          this.$router.push({
            name: "previewTemplate",
            query: {
              quote_id: quoteInfo._id,
              client: clientInfo.name,
              routeFrom: "AccountsQuoteTable",
            },
          });
          return;
        }

        this.$router.push({
          name: "previewTemplate",
          query: {
            quote_id: quoteInfo._id,
            client: clientInfo.name,
            routeFrom: "QuoteTable",
          },
        });
      } else if ($event && $event.toLowerCase() == "version history") {
        this.$router.push({
          name: "quoteHistoryPage",
          query: { id: ele.displayID, version: ele.version },
        });
      } else if ($event && $event.toLowerCase() == "create new version") {
        await this.createNewVersionOfQuote(ele);
      }
    },

    addDatesToGivenDateV1(givenDate = null, toAdd) {
      console.log(
        "🚀 ~ addDatesToGivenDate ~ givendate=null,toAdd:",
        givenDate,
        toAdd
      );
      if (givenDate == null) return;
      Date.prototype.addDays = function (noOfDaysToAdd) {
        // Copy the startDate to avoid mutating the original date
        let currentDate = new Date(this.valueOf());
        // Define a function to check if a given date is a weekend (Saturday or Sunday)
        function isWeekend(date) {
          const dayOfWeek = date.getDay();
          return dayOfWeek === 0 || dayOfWeek === 6; // 0 = Sunday, 6 = Saturday
        }

        // Define a function to add days to a date while skipping weekends
        function addDays(date, days) {
          let result = new Date(date);
          result.setDate(result.getDate() + days);
          return result;
        }

        // Loop through each day to add and skip weekends
        let addedBusinessDays = 0;
        while (addedBusinessDays < noOfDaysToAdd) {
          currentDate = addDays(currentDate, 1);
          if (!isWeekend(currentDate)) {
            addedBusinessDays++;
          }
        }

        return currentDate.toJSON(); // Return the date after adding business days
      };
      var customDate = new Date(givenDate);

      return customDate.addDays(toAdd);
    },
    async createNewVersionOfQuote(quote) {
      quote._id = undefined;
      quote.id = undefined;
      quote.state = "inProgress";
      quote.isVersioning = true;
      quote.quoteDate = new Date().toISOString();
      quote.authorization = quote.authorization.map((auth) => {
        auth.state = "inProgress";
        auth.requestedDate = "";
        auth.signatureRepresentation = "";
        auth.givenDate = new Date().toISOString();
        return auth;
      });

      let validTillDateSetting = quote.note.find(
        (item) => item.name === "validTillDateSetting"
      );

      quote.validFor = {
        endDateTime: quote.validFor.endDateTime
          ? this.addDatesToGivenDateV1(
              quote.validFor.endDateTime,
              validTillDateSetting ? validTillDateSetting.value : 15
            )
          : "",
        startDateTime: new Date().toISOString(),
      };
      quote.requestedQuoteCompletionDate = quote.validFor.endDateTime
        ? this.addDatesToGivenDateV1(
            quote.validFor.endDateTime,
            validTillDateSetting ? validTillDateSetting.value : 15
          )
        : "";
      quote.effectiveQuoteCompletionDate = "";
      let createdQuote = await UPCAxiosInstance.post("/quotemanagement", quote);
      if (createdQuote && createdQuote.data) {
        this.$router.push({
          name: "CPQDefaultTemplate",
          query: { quote_id: createdQuote.data._id, mode: "edit" },
        });
      }
    },
    viewPage(i) {
      console.log("viewPage ", i);
      if (
        i &&
        ["inprogress", "approved", "pending", "draft"].includes(
          i.state?.toLowerCase()
        )
      ) {
        this.$router.push({
          name: "CPQDefaultTemplate",
          query: { quote_id: i._id, mode: "edit" },
        });
        return;
      } else if (i && ["accepted"].includes(i.state?.toLowerCase())) {
        let quoteInfo = i;
        console.log("🚀 ~ closeCrudContextMenu ~ quoteInfo:", quoteInfo);
        if (this.module == "accounts") {
          this.$router.push({
            name: "previewTemplate",
            query: { quote_id: quoteInfo._id, routeFrom: "AccountsQuoteTable" },
          });
          return;
        }
        this.$router.push({
          name: "previewTemplate",
          query: { quote_id: quoteInfo._id, routeFrom: "QuoteTable" },
        });
        return;
      }
    },
    async deletQuote() {
      await UPCAxiosInstance.delete("/quotemanagement/" + this.deleteobj._id);
      this.delete_modal_active = false;
      this.notification = {
        type: "success",
        message:
          'Quotation "' + this.deleteobj.description + '" deleted successfully',
      };
      this.deleteobj = null;
      this.loadTable();
    },
    async closeCrudContext(i) {
      // //
      if (i.type.toLowerCase() == "rule") {
        this.$router.push(`/DMN/rule_studio/logical_rule/${i["_id"]}`);

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/rule/${i["_id"]}`;

        // this.$router.push({ name: 'rule-edit', params: { id: i["_id"] } })
      } else if (i.type.toLowerCase() == "expression") {
        this.$router.push(`/DMN/rule_studio/expression/${i["_id"]}`);

        // this.$router.push({ name: 'expression-edit', params: { id: i["_id"] } })
        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/expression/${i["_id"]}`;
      } else if (i.type.toLowerCase() == "decision") {
        // this.decision_name = i.name;
        // const alldata = await DMNAxiosInstance.get(
        //   `rule_engine/rule?id=${this.id}&name=${this.name}&type=decision`,
        //   {
        //     auth: {
        //       username: "testuser@test.com",
        //       password: " ",
        //     },
        //   }
        // );
        // //
        // let obj = await alldata.data.data.find(
        //   (item) => item.name === this.decision_name
        // );
        //
        // this.$router.push({ name: 'decision-edit', params:{ id: obj["_id"],token:$}})

        this.$router.push(
          `/DMN/rule_studio/decision_table/edit/${i.decisionId}`
        );

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/decision_table/${obj["_id"]}`;
      }
    },

    async deleteExecRow() {
      this.delete_modal_active = false;
      let i = this.deleteobj;
      const deleteOffering = await UPCAxiosInstance.delete(
        `catalogManagement/productSpecification/${i._id}`
      );
      deleteOffering;
      // if (deleteOffering.data.name == i.name) {
      // var offer3 = await UPCAxiosInstance.get(`catalogManagement/productSpecification`)
      // this.totalpageno = offer3.data.length
      // this.tableData = [];
      // var offer = await UPCAxiosInstance.get(
      //   `catalogManagement/productSpecification?pageNo=${this.currPagePS}&size=${this.rowsPerPagePS}`
      // );
      // this.tableData = offer.data;
      this.SpecificationcurrPage = 1;
      this.specificationdataCalling(
        this.SpecificationcurrPage,
        this.SpecificationrowsPerPage
      );

      let logupc = {
        userName: this.userInfo.name,
        userId: this.userInfo._id,
        name: `${i.name} product specification edited`,
        description: `${i.name} product specification edited`,
      };
      // const activity =
      await UPCAxiosInstance.post(`/activityLog`, logupc);

      this.notification = {
        type: "success",
        message: `Done! ${i.name} has been removed.`,
      };
      // }
      // this.activatemodalactive(i);
      i.context = false;
      // this.refreshTable();
    },
    crudContextMenu($event, index) {
      console.log("crudContextMenu($event, index)", $event, index);
      let top = 0;
      this.externalRowsPerPage / 3 < index ? (top = 80) : top;
      // ;
      if ($event.target.className === "icon-more-vertical-filled") {
        let leftMinus = 108;
        ////////
        this.containerleft1 =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 152;
        /////////////////////
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - leftMinus;
        this.containertop =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().top - top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;

        //
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }

      // 111111111111111111

      let r = document.querySelector("#table-context" + index);
      r;
      console.log("rrr...", r);
      /////////////////////////////////////////

      // Check if the "Version history" option exists inside the options-container
      let versionHistoryElement = r.querySelector("span");

      if (versionHistoryElement) {
        console.log(
          "rrr... Version history option exists",
          versionHistoryElement
        );
      } else {
        console.log("rrr... Version history option does not exist");
      }
      ///////////////////////////////////////
    },
    async handleCurrentChange(val) {
      //
      // this.filterpanelname == val.name;
      switch (val) {
        case "Product specifications":
          // this.SpecificationcurrPage = 1;
          // this.SpecificationrowsPerPage = 10;
          this.tableData = [];
          this.specificationdataCalling(
            this.SpecificationcurrPage,
            this.SpecificationrowsPerPage
          );
          this.filterPanelName = "productSpecification";
          this.view = val;

          break;
        case "Product offerings":
          this.tableData = [];
          // this.OfferingcurrPage = 1;
          // this.OfferingrowsPerPage = 10;
          this.offeringdataCalling(
            this.OfferingcurrPage,
            this.OfferingrowsPerPage
          );
          this.filterPanelName = "productOffering";
          this.view = val;

          break;
      }
      this.duplicatetabledata = this.tableData;
      this.searchtabledata = this.tableData;
      // //
    },
    serialNumber(index) {
      console.log(
        "🚀 ~ serialNumber ~ this.quoteFilterOption:",
        this.quoteFilterOption
      );
      return (
        (this.quoteFilterOption.paginatedQuery.currentPage - 1) *
          this.quoteFilterOption.paginatedQuery.rowsPerPage +
        index +
        1
      );
    },
    async currentPage(val) {
      console.log("🚀 ~ currentPage ~ val:", val);
      this.quoteFilterOption.paginatedQuery.currentPage = val;
      if (this.searchValue.trim()) {
        this.quoteFilterOption.searchQuery = this.searchValue;
        this.quoteFilterOption.type = "filter-search";
      }
      this.loadTable();
      //   this.specificationdataCalling(
      //     this.SpecificationcurrPage,
      //     this.SpecificationrowsPerPage
      //   );
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    async changeRowsPerPage(count) {
      this.quoteFilterOption.paginatedQuery.rowsPerPage = count;
      //   this.specificationdataCalling(
      //     this.SpecificationcurrPage,
      //     this.SpecificationrowsPerPage
      //   );
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    resizewidthdata(data1, data2) {
      data1;
      data2;
      //
    },
    sorting_Data(data, data1) {
      //
      if (data === "icon-angle-up-small-filled sorting-icon") {
        if (this.view == "Product offerings") {
          //  console.log('fjd',this.offeringfilterquery);
          this.offeringfilterquery.type = "filter";
          this.offeringfilterquery.isNeedToBeSort = true;
          if (data1 === "name") {
            this.offeringfilterquery.sortFieldAndOrder = { name: 1 };
          } else if (data1 == "description") {
            this.offeringfilterquery.sortFieldAndOrder = { description: 1 };
          } else if (data1 == "lifecycleStatus") {
            this.offeringfilterquery.sortFieldAndOrder = { lifecycleStatus: 1 };
          } else if (data1 == "offerType") {
            this.offeringfilterquery.sortFieldAndOrder = { offerType: 1 };
          } else if (data1 == "lastUpdate") {
            this.offeringfilterquery.sortFieldAndOrder = { lastUpdate: 1 };
          } else if (data1 == "place") {
            this.offeringfilterquery.sortFieldAndOrder = { "place.name": 1 };
          } else if (data1 == "channel") {
            this.offeringfilterquery.sortFieldAndOrder = { "channel.name": 1 };
          } else if (data1 == "category") {
            this.offeringfilterquery.sortFieldAndOrder = { "category.name": 1 };
          } else if (data1 == "marketSegment") {
            this.offeringfilterquery.sortFieldAndOrder = {
              "marketSegment.name": 1,
            };
          }
          this.OfferingcurrPage = 1;
          this.offeringdataCalling(
            this.OfferingcurrPage,
            this.OfferingrowsPerPage
          );
        } else if (this.view == "Product specifications") {
          this.quoteFilterOption.type = "filter";
          this.quoteFilterOption.isNeedToBeSort = true;
          if (data1 === "name") {
            this.quoteFilterOption.sortFieldAndOrder = { name: 1 };
          } else if (data1 === "description") {
            this.quoteFilterOption.sortFieldAndOrder = { description: 1 };
          } else if (data1 == "lifecycleStatus") {
            this.quoteFilterOption.sortFieldAndOrder = { lifecycleStatus: 1 };
          } else if (data1 == "lastUpdate") {
            this.quoteFilterOption.sortFieldAndOrder = { lastUpdate: 1 };
          } else if (data1 == "resourceSpecification") {
            this.quoteFilterOption.sortFieldAndOrder = {
              "resourceSpecification.name": 1,
            };
          } else if (data1 == "serviceSpecification") {
            this.quoteFilterOption.sortFieldAndOrder = {
              "serviceSpecification.name": 1,
            };
          } else if (data1 == "relatedParty") {
            this.quoteFilterOption.sortFieldAndOrder = {
              "relatedParty.name": 1,
            };
          }

          this.SpecificationcurrPage = 1;
          this.specificationdataCalling(
            this.SpecificationcurrPage,
            this.SpecificationrowsPerPage
          );
        }
      } else if (data === "icon-angle-down-small-filled sorting-icon") {
        // console.log('sort-down',data1);
        if (this.view == "Product offerings") {
          //  console.log('fjd',this.offeringfilterquery);
          this.offeringfilterquery.type = "filter";
          this.offeringfilterquery.isNeedToBeSort = true;
          if (data1 === "name") {
            this.offeringfilterquery.sortFieldAndOrder = { name: -1 };
          } else if (data1 == "description") {
            this.offeringfilterquery.sortFieldAndOrder = { description: -1 };
          } else if (data1 == "lifecycleStatus") {
            this.offeringfilterquery.sortFieldAndOrder = {
              lifecycleStatus: -1,
            };
          } else if (data1 == "offerType") {
            this.offeringfilterquery.sortFieldAndOrder = { offerType: -1 };
          } else if (data1 == "lastUpdate") {
            this.offeringfilterquery.sortFieldAndOrder = { lastUpdate: -1 };
          } else if (data1 == "place") {
            this.offeringfilterquery.sortFieldAndOrder = { "place.name": -1 };
          } else if (data1 == "channel") {
            this.offeringfilterquery.sortFieldAndOrder = { "channel.name": -1 };
          } else if (data1 == "category") {
            this.offeringfilterquery.sortFieldAndOrder = {
              "category.name": -1,
            };
          } else if (data1 == "marketSegment") {
            this.offeringfilterquery.sortFieldAndOrder = {
              "marketSegment.name": -1,
            };
          }
          this.OfferingcurrPage = 1;
          this.offeringdataCalling(
            this.OfferingcurrPage,
            this.OfferingrowsPerPage
          );
        } else if (this.view == "Product specifications") {
          this.quoteFilterOption.type = "filter";
          this.quoteFilterOption.isNeedToBeSort = true;
          if (data1 === "name") {
            this.quoteFilterOption.sortFieldAndOrder = { name: -1 };
          } else if (data1 === "description") {
            this.quoteFilterOption.sortFieldAndOrder = { description: -1 };
          } else if (data1 == "lifecycleStatus") {
            this.quoteFilterOption.sortFieldAndOrder = { lifecycleStatus: -1 };
          } else if (data1 == "lastUpdate") {
            this.quoteFilterOption.sortFieldAndOrder = { lastUpdate: -1 };
          } else if (data1 == "resourceSpecification") {
            this.quoteFilterOption.sortFieldAndOrder = {
              "resourceSpecification.name": -1,
            };
          } else if (data1 == "serviceSpecification") {
            this.quoteFilterOption.sortFieldAndOrder = {
              "serviceSpecification.name": -1,
            };
          } else if (data1 == "relatedParty") {
            this.quoteFilterOption.sortFieldAndOrder = {
              "relatedParty.name": -1,
            };
          }

          this.SpecificationcurrPage = 1;
          this.specificationdataCalling(
            this.SpecificationcurrPage,
            this.SpecificationrowsPerPage
          );
        }
      } else if (data === "icon-angle-up-small-filled") {
        if (this.view == "Product offerings") {
          //  console.log('fjd',this.offeringfilterquery);
          this.offeringfilterquery.type = "filter";
          this.offeringfilterquery.isNeedToBeSort = false;
          this.offeringfilterquery.sortFieldAndOrder = {};
          this.OfferingcurrPage = 1;
          this.offeringdataCalling(
            this.OfferingcurrPage,
            this.OfferingrowsPerPage
          );
        } else if (this.view == "Product specifications") {
          this.quoteFilterOption.type = "filter";
          this.quoteFilterOption.isNeedToBeSort = false;
          this.quoteFilterOption.sortFieldAndOrder = {};
          this.SpecificationcurrPage = 1;
          this.specificationdataCalling(
            this.SpecificationcurrPage,
            this.SpecificationrowsPerPage
          );
        }
      } else if (data === "icon-angle-down-small-filled") {
        if (this.view == "Product offerings") {
          //  console.log('fjd',this.offeringfilterquery);
          this.offeringfilterquery.type = "filter";
          this.offeringfilterquery.isNeedToBeSort = false;
          this.offeringfilterquery.sortFieldAndOrder = {};
          this.OfferingcurrPage = 1;
          this.offeringdataCalling(
            this.OfferingcurrPage,
            this.OfferingrowsPerPage
          );
        } else if (this.view == "Product specifications") {
          this.quoteFilterOption.type = "filter";
          this.quoteFilterOption.isNeedToBeSort = false;
          this.quoteFilterOption.sortFieldAndOrder = {};
          this.SpecificationcurrPage = 1;
          this.specificationdataCalling(
            this.SpecificationcurrPage,
            this.SpecificationrowsPerPage
          );
        }
      }
    },
    handleQuoteSearch(val) {
      console.log("🚀 ~ handleQuoteSearch ~ val:", val);
      this.searchvalue = val;
      if (this.module == "quoteHistory") {
        this.quoteFilterOption.filterQuery = {
          ...this.quoteFilterOption.filterQuery,
          displayID: this.$route.query.id,
        };
        this.quoteFilterOption.sortFieldAndOrder = {
          version: -1,
          lastUpdate: -1,
        };
        this.quoteFilterOption.additionalPipelines = [];
        this.quoteFilterOption["type"] = "filter-search";
      } else {
        this.quoteFilterOption["type"] = "search";
      }
      this.quoteFilterOption["searchQuery"] = val;
      this.quoteFilterOption.searchList = [
        { "relatedParty.name": { $regex: val, $options: "i" } },
        {
          $and: [
            {
              "quoteTotalPrice.@type": "totalContractValue",
            },
            {
              "quoteTotalPrice.price.value": { $regex: val, $options: "i" },
            },
          ],
        },
      ];
      this.quoteFilterOption.paginatedQuery.currentPage = 1;
      this.quoteFilterOption.paginatedQuery.rowsPerPage = 10;
      this.loadTable();
    },
    async loadTable() {
      console.log("🚀 ~ loadTable ~ loadTable:");
      this.$emit("requestChange", true);

      if (this.module == "accounts") {
        // this.quoteFilterOption.company = this.$route.params.companyName;
        this.quoteFilterOption.filterQuery = {
          ...this.quoteFilterOption.filterQuery,
          "relatedParty.role": "Buyer",
          "relatedParty.id": this.$route.params.id,
        };
      }
      if (this.module == "quoteHistory") {
        this.quoteFilterOption.filterQuery = {
          ...this.quoteFilterOption.filterQuery,
          displayID: this.$route.query.id,
        };
        this.quoteFilterOption.additionalPipelines = [];
        this.quoteFilterOption.sortFieldAndOrder = {
          version: -1,
          lastUpdate: -1,
        };
      }
      if (this.module == "contacts") {
        // this.quoteFilterOption.company =
        //   this.$route.params.from == "contacts"
        //     ? this.$route.query.company
        //     : this.$route.params.name;

        this.quoteFilterOption.filterQuery = {
          ...this.quoteFilterOption.filterQuery,
          "relatedParty.role": "Buyer contact",
          "relatedParty.id": this.$route.params.id,
        };
      }
      this.quoteFilterOption.filterQuery = this.filterQuery?.filterQuery; // by asif
      let tempIndex = this.quoteFilterOption.paginatedQuery.currentPage;
      console.log("🚀 ~ loadTable ~ tempIndex:", tempIndex);
      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.quoteFilterOption
      );
      console.log("filter123 load", this.quoteFilterOption, filterResultData);

      console.log("🚀 ~ mounted ~ filterResultData:", filterResultData);
      this.quoteFilterOption.paginatedQuery.currentPage = tempIndex;
      this.datalength = filterResultData.data.total || 0;
      this.tableData = filterResultData.data.data;
      this.totalpageno = filterResultData.data.total || 0;
      this.paginatedData;
      console.log(
        "🚀 ~ loadTable ~ this.latestPersistPage:",
        this.latestPersistPage
      );
      this.$emit("requestChange", false);
    },
    fromAddContextMenu(val) {
      if (val === "Decision tables") {
        this.$router.push("/DMN/rule_studio/decision_table/add");

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/decision_table/add`;
      } else if (val === "Expression") {
        this.$router.push("/DMN/rule_studio/expression");

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/expression`;
      } else if (val === "Logical rule") {
        this.$router.push("/DMN/rule_studio/logical_rule");

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/rule`;
      }
    },
  },
};
</script>

<style></style>
