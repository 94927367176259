// eslint-disable-next-line /* eslint-disable */
<template>
  <div class="app-layout">
    <section class="right-panel">
      <div class="layout-basic">
        <div class="layout-breadcrumb">
          <hlx-breadcrumb
            :items="items"
            @path="pathTo"
          />
        </div>
        <div class="layout-header">
          <p>Add Schema</p>
        </div>
        <div class="layout-body">
          <div class="grid">
            <span><p>Source of reference</p></span>

            <span class="grid-select-schema-flow">
              <span
                v-for="(i, index) in tilename"
                :key="index"
                class="grid-select-schema-flow-tile"
                @click="selecttile(i.label)"
              >
                <i :class="i.icon"></i>
                <p>{{ i.label }}</p>
              </span>
            </span>
          </div>
        </div>

        <div class="layout-footer"></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SelectSchema',
  data() {
    return {
      items: [{ label: 'Schemas' }, { label: 'Add schema' }],
      tilename: [
        { label: 'Local', icon: 'icon-edit-regular' },
        { label: 'MongoDB', icon: 'icon-leaf-regular' },
        { label: 'SQL', icon: 'icon-database-regular' },
      ],
    };
  },
  mounted() {
    this.$store.commit('token', this.$route.params.token);
  },
  methods: {
    selecttile(value) {
      if (value === 'Local') {
        this.$router.push({ path: `/DMN/schemas/local` });
      } else if (value === 'MongoDB') {
        this.$router.push({ path: `/DMN/schemas/mongodb` });
      } else if (value === 'SQL') {
        this.$router.push({ path: `/DMN/schemas/local` });
      }
    },
    pathTo() {
      
      
        this.$router.push({ path: `/DMN/schemas` });
      
    },
  },
};
</script>

<style></style>
