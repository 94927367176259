<template>
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete location</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p v-if="deleteMessage">
          <b>{{ deleteName + "," }}</b>
          {{
            " is set to default. Are you sure you want to delete this location?"
          }}
        </p>
        <p v-if="!deleteMessage">
          {{ "Are you sure you want to delete the location "
          }}<b>{{ deleteName + "?" }}</b>
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteAddress"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
  <div v-if="data.length > 0">
    <div
      class="util-bar"
      style="display: flex; justify-content: space-between; margin: 20px 0 0 0"
    >
      <span class="switch-container">
        <hlx-switch
          v-model="selectedSwitch"
          :switch-items="switchOptions"
          value-prop="value"
          display-prop="name"
          @change="chosenSwitch"
        />
      </span>
      <span style="display: flex; gap: 16px">
        <hlx-search @search-key="cardSearch"></hlx-search>
        <hlx-button v-show="supplierStatus == false" class="primary sm" @click="openDrawerFunc"
          >Add location</hlx-button
        >
      </span>
    </div>

    <div
      v-if="data.length === 0"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
        height: 80vh;
      "
    >
      <img src="@/assets/images/supplierPendingContact.svg" alt="" />
    </div>
    <div class="new-cards-container" style="padding: 20px 0px 60px">
      <hlx-card
        v-for="(card, index) in card_data"
        :key="index"
        :hover="false"
        :seperator="false"
        :card-title="false"
        :card-footer="false"
        :padding="true"
      >
        <template #card-body>
          <div
            class="location-card"
            style="padding: 15px !important; position: relative"
          >
            <span
              v-if="card.characteristic.default === true"
              style="
                position: absolute;
                top: 0;
                right: 0;
                background: #54bd9529;
                padding: 4px;
                font-size: 14px !important;
                display: flex;
                gap: 4px;
                align-items: center;
                justify-content: center;
                border-top-right-radius: 5px;
                border-bottom-left-radius: 5px;
              "
            >
              <i
                class="icon-check-circle-filled"
                style="color: #54bd95; font-size: 14px"
              ></i>
              <span style="font-size: 14px">Default</span>
            </span>
            <span
              class="card-tools"
              style="
                display: none;
                position: absolute;
                bottom: 0px;
                right: 0px;
                padding: 8px;
                font-size: 16px !important;
                gap: 8px;
                align-items: center;
                justify-content: center;
              "
            >
              <div v-show="supplierStatus == false" data-tooltip="Edit" data-tooltip-location="bottom">
                <i
                  class="icon-edit-regular"
                  style="color: #54bd95"
                  @click="editCard(card, index)"
                ></i>
              </div>
              <div v-show="supplierStatus == false" v-if=" card_data.length>1"  data-tooltip="Delete" data-tooltip-location="bottom">
                <i
                  class="icon-trash-regular"
                  @click="deleteCard(card, index)"
                ></i>
              </div>
              <div
                v-show="supplierStatus == false"
                data-tooltip="Mark as default"
                data-tooltip-location="bottom"
              >
                <i
                  v-if="!card.characteristic.default"
                  class="icon-receipt-regular"
                  style="color: #0065b2"
                  @click="setDefault(card, index)"
                ></i>
              </div>
            </span>
            <div
              class="title"
              style="
                width: 100%;
                margin-bottom: 10px;
                font-size: 16px !important;
              "
              :style="
                card.characteristic.default ? { 'padding-right': '80px' } : {}
              "
            >
              <b
                style="
                  display: block;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                >{{ card.characteristic.locationName }}</b
              >
            </div>
            <div class="body1">{{ `${card.characteristic.address1}` }}</div>
            <div class="body1">{{ `${card.characteristic.unit}` }}</div>
            <div class="body1">{{ `${card.characteristic.state}` }}</div>
            <div class="body1">{{ `${card.characteristic.country}` }}</div>
            <div class="body1">
              {{ `Postal code: ${card.characteristic.postalCode}` }}
            </div>

            <div
              v-if="card.characteristic?.email?.emailAddress.length > 0"
              class="body1"
              style="
                display: flex;
                gap: 8px;
                align-items: center;
                margin-top: 8px;
              "
            >
              <i class="icon-envelope-regular"></i>
              {{ `${card.characteristic?.email?.emailAddress}` }}
            </div>
            <div
              v-if="card.characteristic?.phone?.phoneNumber?.value.length > 0"
              class="body1"
              style="display: flex; gap: 8px; align-items: center"
            >
              <i class="icon-phone-regular"></i>
              {{ `${card.characteristic?.phone?.phoneNumber?.value}` }}
            </div>
          </div>
        </template>
      </hlx-card>
    </div>
  </div>
  <hlx-drawer
    :show="openDrawer"
    position="right"
    width="400"
    :footer="true"
    :title="'Add attachment'"
    :show-close-icon="true"
    @close="closeDrawer"
  >
    <template #body>
      <div
        class="header-area-contact-drawer"
        style="
          border-bottom: 1px solid #d4d4d4;
          padding-bottom: 5px;
          padding: 4px 0 13px 0;
        "
      >
        <b v-if="editMode">Edit Location</b>
        <b v-if="!editMode">Add Location</b>
      </div>
      <div class="body-area-contact-drawer" style="margin-top: 10px">
        <span style="margin: 0 0 10px 0; display: flex"> Location type </span>
        <span style="display: flex; margin-bottom: 16px">
          <hlx-input
            type="radio-buttons"
            position="horizontal"
            :options="radioOptionsComp"
            @selected="radioChange"
          >
          </hlx-input>
        </span>
        <hlx-input
          v-model:value="payload.characteristic.locationName"
          :pre-val="payload.characteristic.locationName"
          :label-animation="true"
          label-value="Location name"
          required="true"
          :display-error="true"
        ></hlx-input>
        <addressComponent
          :required="true"
          :pre-val="addressPreVal"
          @address-data="addressData"
        ></addressComponent>
        <br />
        <div class="decision-name-wrapper">
          <hlx-input
            :key="componetemail"
            v-model:value="payload.characteristic.email.emailAddress"
            :pre-val="payload.characteristic.email.emailAddress"
            type="email"
            :label-value="'Email id'"
            :required="false"
            :label-animation="true"
            :display-error="true"
            :custom-error-message="'Please fill the field'"
          ></hlx-input>
        </div>
        <div class="decision-name-wrapper">
          
          <hlx-input
            :key="phoneNumberComponentReload"
            v-model:value="payload.characteristic.phone.phoneNumber"
            :pre-val="payload.characteristic.phone.phoneNumber.value"
            type="phonenumber"
            :country-code="payload.characteristic.phone.phoneNumber.country"
            :label-value="'Phone no.'"
            :label-animation="true"
            :display-error="true"
            :custom-error="customnameerror"
            :custom-error-message="'Please fill the field'"
          ></hlx-input>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        style="
          display: flex;
          justify-content: flex-end;
          padding: 10px;
          border-top: 1px solid #d8d8d8;
        "
      >
        <hlx-button class="secondary sm" @click="closeDrawer"
          >Cancel</hlx-button
        >
        <hlx-button
          v-if="!editMode"
          :disabled="
            payload.characteristic.locationName !== '' &&
            payload.characteristic.phone.phoneNumber.valid === true &&
            validateEmail(payload.characteristic.email.emailAddress) &&
            validateAddress(payload)
              ? false
              : true
          "
          class="primary sm"
          style="margin-left: 10px"
          @click="emitNewAddress"
          >Add</hlx-button
        >
        <hlx-button
          v-if="editMode"
          class="primary sm"
          style="margin-left: 10px"
          :disabled="
            payload.characteristic.locationName !== '' &&
            payload.characteristic.phone.phoneNumber.valid === true &&
            validateEmail(payload.characteristic.email.emailAddress) &&
            validateAddress(payload)
              ? false
              : true
          "
          @click="editAddressEmit"
          >Save</hlx-button
        >
      </div>
    </template>
  </hlx-drawer>
</template>

<script>
import addressComponent from "../CM/autocompleteComponent.vue";

export default {
  components: {
    addressComponent,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    module: {
      type: String,
      default: "",
    },
    supplierStatus:{
       type: Boolean,
      default: false,
    }
  },
  emits: ["new-address", "update-address", "delete-index", 'initialPayload'],
  data() {
    return {
      phoneNumberComponentReload: 0,
      componetemail: 0,
      canAdd: false,
      deleteMessage: false,
      deleteName: "",
      delete_modal_active: false,
      deleteIndex: 0,
      editIndex: 0,
      editMode: false,
      payload: {
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
          phone: {
            mediumType: "phone",
            phoneNumber: {
              country: {
                name: this.$store.getters.defaultSetting?.country,
                short: this.$store.getters.defaultSetting?.cca2,
                flags: this.$store.getters.defaultSetting?.flags?.svg,
              },
              value: "",
              valid: true,
            },
            preferred: false,
          },
          email: {
            preferred: false,
            mediumType: "email",
            emailAddress: "",
          },
          locationName: "",
        },
      },
      addressPreVal: {
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
        },
      },
      radioOptions: [
        { name: "Inventory location", checked: false, disabled: false, id: 1 },
        { name: "Billing location", checked: false, disabled: false, id: 2 },
      ],
      openDrawer: false,
      selectedSwitch: "inventory",
      switchOptions: [
        {
          name: "Inventory location",
          value: "inventory",
        },
        {
          name: "Billing location",
          value: "billing",
        },
      ],
      card_data: [],
    };
  },
  computed: {
    radioOptionsComp() {
      let arr = [];
      if (this.selectedSwitch === "inventory") {
        arr = [
          {
            name: "Inventory location",
            value: "inventoryLocation",
            checked: true,
            disabled: false,
            id: 1,
          },
          {
            name: "Billing location",
            value: "billingLocation",
            checked: false,
            disabled: false,
            id: 2,
          },
        ];
      } else {
        arr = [
          {
            name: "Inventory location",
            value: "inventoryLocation",
            checked: false,
            disabled: false,
            id: 1,
          },
          {
            name: "Billing location",
            value: "billingLocation",
            checked: true,
            disabled: false,
            id: 2,
          },
        ];
      }
      return arr;
    },
  },
  watch: {
    data: {
      handler(val) {
        console.log(val);
        this.card_data = JSON.parse(
          JSON.stringify(
            val.filter((item) => {
              return (
                item.mediumType !== "fax" &&
                item.mediumType !== "phone" &&
                item.mediumType !== "email"
              );
            })
          )
        );
        if (this.selectedSwitch === "inventory") {
          this.card_data = this.card_data
            .filter((item) => item.mediumType === "inventoryLocation")
            .sort(
              (a, b) => b.characteristic.default - a.characteristic.default
            );
        } else {
          this.card_data = this.card_data
            .filter((item) => item.mediumType === "billingLocation")
            .sort(
              (a, b) => b.characteristic.default - a.characteristic.default
            );
        }

        console.log(this.card_data, "this.card_data");
      },
      immediate: true,
      deep: true,
    },
    selectedSwitch: {
      handler(val) {
        if (val === "inventory") {
          this.payload.mediumType = "inventoryLocation";
          this.addressPreVal.mediumType = "inventoryLocation";
          this.card_data = JSON.parse(
            JSON.stringify(
              this.data.filter((item) => {
                return (
                  item.mediumType !== "fax" &&
                  item.mediumType !== "phone" &&
                  item.mediumType !== "email" &&
                  item.mediumType === "inventoryLocation"
                );
              })
            )
          );
        } else {
          this.payload.mediumType = "billingLocation";
          this.addressPreVal.mediumType = "billingLocation";
          this.card_data = JSON.parse(
            JSON.stringify(
              this.data.filter((item) => {
                return (
                  item.mediumType !== "fax" &&
                  item.mediumType !== "phone" &&
                  item.mediumType !== "email" &&
                  item.mediumType === "billingLocation"
                );
              })
            )
          );
        }
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
emitNewAddress() {
      if (this.selectedSwitch === "inventory") {
        // this.payload.mediumType = "inventoryLocation";
        this.card_data = this.card_data.filter((item) => {
          return item.mediumType === "inventoryLocation" ? item : false;
        });
      } else {
        // this.payload.mediumType = "billingLocation";
        this.card_data = this.card_data.filter((item) => {
          return item.mediumType === "billingLocation" ? item : false;
        });
      }
      if (this.selectedSwitch === "inventory" && this.payload.mediumType == '') {
        this.payload.mediumType = "inventoryLocation";
        this.card_data = this.card_data.filter((item) => {
          return item.mediumType === "inventoryLocation" ? item : false;
        });
      } else if (this.selectedSwitch === "billing" && this.payload.mediumType == ''){
        this.payload.mediumType = "billingLocation";
        this.card_data = this.card_data.filter((item) => {
          return item.mediumType === "billingLocation" ? item : false;
        });
      }
      if (this.card_data.length === 0) {
        this.payload.characteristic.default = true;
      }
      console.log(this.selectedSwitch);
      console.log(this.payload);
      this.$emit("new-address", this.payload);
      this.openDrawer = false;
    },
    closeDrawer() {
      this.openDrawer = false;
      const x = {
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
          phone: {
            mediumType: "phone",
            phoneNumber: {
              country: {
                name: this.$store.getters.defaultSetting?.country,
                short: this.$store.getters.defaultSetting?.cca2,
                flags: this.$store.getters.defaultSetting?.flags?.svg,
              },
              value: "",
              valid: true,
            },
            preferred: false,
          },
          email: {
            preferred: false,
            mediumType: "email",
            emailAddress: "",
          },
          locationName: "",
        },
      };
      this.payload = JSON.parse(JSON.stringify(x));
      this.phoneNumberComponentReload += 1;
    },
    validateAddress(val) {
      console.log(val);
      if (val.characteristic.address1 !== "") {
        return true;
      } else {
        return false;
      }
    },
    validateEmail(val) {
      const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (val.length > 0) {
        if (!reg.test(val)) {
          return false; // URL is invalid
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    deleteAddress() {
      this.$emit("delete-index", this.deleteIndex);
      this.delete_modal_active = false;
    },
    deleteCard(card) {
      console.log(card, "location");
      this.deleteIndex = this.data.findIndex(
        (obj) =>
          obj.characteristic.locationName ===
            card.characteristic.locationName &&
          obj.mediumType === card.mediumType
      );
      this.deleteName = card.characteristic.locationName;
      if (card.default === true) {
        this.deleteMessage = true;
      } else {
        this.deleteMessage = false;
      }
      this.delete_modal_active = true;
      // this.$emit('delete-index', this.deleteIndex)
    },
    editAddressEmit() {
      this.$emit("update-address", {
        index: this.editIndex,
        data: this.payload,
      });
      this.openDrawer = false;
    },
    editCard(card, index) {
      console.log(card, index);
      this.$emit('initialPayload',card )
      this.payload = JSON.parse(JSON.stringify(card));
      this.addressPreVal = JSON.parse(JSON.stringify(card));
      this.editIndex = this.data.findIndex(
        (obj) =>
          obj.characteristic.locationName ===
            this.payload.characteristic.locationName &&
          obj.mediumType === this.payload.mediumType
      );
      this.openDrawer = true;
      this.editMode = true;
    },
    setDefault(card) {
      const index = this.data.findIndex(
        (obj) =>
          obj.characteristic.locationName ===
            card.characteristic.locationName &&
          obj.mediumType === card.mediumType
      );
      let swi =
        this.selectedSwitch === "inventory"
          ? "inventoryLocation"
          : "billingLocation";
      this.data.filter((item) => {
        if (item.mediumType === swi) {
          item.characteristic.default = false;
        }
      });
      const item = this.data[index];
      item.characteristic.default = true;
      this.$emit("update-address", { index: index, data: item });
    },

    filteredData(val) {
      const searchValue = val.toLowerCase();
      const filterNested = (obj) => {
        for (let key in obj) {
          const value = obj[key];
          if (typeof value === "object") {
            if (filterNested(value)) return true;
          } else if (
            typeof value === "string" &&
            value.toLowerCase().includes(searchValue)
          ) {
            return true;
          }
        }
        return false;
      };

      return this.data.filter((obj) => filterNested(obj.characteristic));
    },
    cardSearch(val) {
      console.log(val);
      if (val !== "") {
        this.card_data = this.filteredData(val);

        this.card_data = this.card_data.filter((item) => {
          return (
            item.mediumType !== "fax" &&
            item.mediumType !== "phone" &&
            item.mediumType !== "email" &&
            item.mediumType !== "postalAddress"
          );
        });

        if (this.selectedSwitch === "inventory") {
          this.card_data = this.card_data
            .filter((item) => item.mediumType === "inventoryLocation")
            .sort(
              (a, b) => b.characteristic.default - a.characteristic.default
            );
        } else {
          this.card_data = this.card_data
            .filter((item) => item.mediumType === "billingLocation")
            .sort(
              (a, b) => b.characteristic.default - a.characteristic.default
            );
        }

        console.log(this.card_data);
      } else {
        this.card_data = JSON.parse(
          JSON.stringify(
            this.data.filter((item) => {
              return (
                item.mediumType !== "fax" &&
                item.mediumType !== "phone" &&
                item.mediumType !== "email"
              );
            })
          )
        );

        this.card_data = this.card_data.filter((item) => {
          return (
            item.mediumType !== "fax" &&
            item.mediumType !== "phone" &&
            item.mediumType !== "email" &&
            item.mediumType !== "postalAddress"
          );
        });

        if (this.selectedSwitch === "inventory") {
          this.card_data = this.card_data
            .filter((item) => item.mediumType === "inventoryLocation")
            .sort(
              (a, b) => b.characteristic.default - a.characteristic.default
            );
        } else {
          this.card_data = this.card_data
            .filter((item) => item.mediumType === "billingLocation")
            .sort(
              (a, b) => b.characteristic.default - a.characteristic.default
            );
        }
      }

      console.log(this.card_data);
    },
    addressData(val) {
      console.log(val);
      let address = {};
      let type = JSON.parse(JSON.stringify(this.payload.mediumType))
      address = JSON.parse(JSON.stringify(val));
      address.characteristic.phone = JSON.parse(
        JSON.stringify(this.payload.characteristic.phone)
      );
      address.characteristic.email = JSON.parse(
        JSON.stringify(this.payload.characteristic.email)
      );
      address.characteristic.locationName = JSON.parse(
        JSON.stringify(this.payload.characteristic.locationName)
      );
      this.payload = JSON.parse(JSON.stringify(address));
      this.payload.mediumType = JSON.parse(JSON.stringify(type));
    },
    radioChange(val) {
      console.log(val);
      this.payload.mediumType = val.value;
    },
    openDrawerFunc() {
      const x = {
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
          phone: {
            mediumType: "phone",
            phoneNumber: {
              country: {
                name: this.$store.getters.defaultSetting?.country,
                short: this.$store.getters.defaultSetting?.cca2,
                flags: this.$store.getters.defaultSetting?.flags?.svg,
              },
              value: "",
              valid: true,
            },
            preferred: false,
          },
          email: {
            preferred: false,
            mediumType: "email",
            emailAddress: "",
          },
          locationName: "",
        },
      };
      this.payload = JSON.parse(JSON.stringify(x));
      const y = {
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
        },
      };
      this.addressPreVal = JSON.parse(JSON.stringify(y));
      this.editMode = false;
      this.openDrawer = true;
    },
    chosenSwitch(val) {
      console.log(val);
    },
  },
};
</script>

<style lang="scss">
.location-card {
  border: 1px solid #d8d8d8 !important;
  border-radius: 5px;
  font-size: 14px !important;
  min-height: 244px;
}

.location-card:hover {
  border: 1px solid #54bd95 !important;
  border-radius: 5px;
  .card-tools {
    display: flex !important;
  }
}

.new-cards-container {
  .card {
    border: none !important;
  }
}
</style>
