<template>
  <div class="header-area-cart">
    <b>{{ 'My cart (3)' }}</b>
  </div>
  <div class="cart-items-container">
    <div class="cart-items">
      <span
        v-for="(item, index) in items"
        :key="index"
        class="item-container"
        :style="
          index === items.length - 1
            ? {
                marginBottom: '0px',
              }
            : {}
        "
      >
        <span
          class="left-container"
          :style="
            index === items.length - 1
              ? {
                  borderBottom: '1px solid transparent',
                }
              : {
                  borderBottom: '1px solid #d8d8d8',
                }
          "
        >
          <span class="img-container">
            <img
              :src="item.img"
              alt=""
            />
          </span>
          <span
            clas="details-container"
            style="
              display: flex;
              flex-direction: column;
              margin-left: 20px;
              justify-content: space-between;
            "
          >
            <span style="display: flex; flex-direction: column; gap: 8px">
              <span
                ><b>{{ item.name }}</b></span
              >
              <span style="display: flex; color: #a6a6a6">
                <span
                  v-for="ch in item.spec"
                  :key="ch"
                  >{{ Object.keys(ch)[0] + ': '
                  }}{{ Object.values(ch)[0] }} &nbsp; &nbsp;
                </span>
              </span>
              <span style="display: flex; color: #a6a6a6">
                seller: {{ item.seller }}
              </span>
            </span>
            <span style="margin-bottom: 20px">
              <button style="color: #fa3434; cursor: pointer">Remove</button>
            </span>
          </span>
        </span>
        <span
          class="right-container"
          :style="
            index === items.length - 1
              ? {
                  borderBottom: '1px solid transparent',
                }
              : {
                  borderBottom: '1px solid #d8d8d8',
                }
          "
        >
          <span style="display: flex; align-items: baseline">
            <b>{{ item.price }}</b> &nbsp;
            <span style="color: #a6a6a6; font-size: 10px">{{ item.unit }}</span>
          </span>
          <span style="display: flex; align-items: center; gap: 20px">
            <span>Qty</span>
            <span style="width: 60px"
              ><hlx-input
                type="number"
                :pre-val="item.quantity"
              ></hlx-input
            ></span>
          </span>
        </span>
      </span>
      <hlx-button
        class="secondary sm"
        style="margin-top: 10px"
      >
        <i class="icon-arrow-left-regular"></i>
        &nbsp; Back to quote
      </hlx-button>
    </div>
    <div
      class="cart-summary"
      style="display: flex; flex-direction: column"
    >
      <span
        v-for="(item, index) in summary.sub"
        :key="index"
        style="display: flex; justify-content: space-between; height: 40px"
      >
        <span>{{ index }}</span>
        <span
          :style="
            index === 'Discount' || index === 'Delivery charge'
              ? { color: '#54bd95' }
              : {}
          "
          >{{ item }}</span
        >
      </span>
      <hr />
      <span
        style="
          display: flex;
          justify-content: space-between;
          height: 40px;
          align-items: center;
          margin-top: 10px;
        "
      >
        <span
          ><b>{{ Object.keys(summary.total)[0] }}</b></span
        >
        <span style="font-size: 16px"
          ><b>{{ Object.values(summary.total)[0] }}</b></span
        >
      </span>
      <hlx-button class="primary sm" style="margin-top: 20px">Checkout</hlx-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          name: 'Apple iPhone 15 Pro Max (256 GB) - Black Titanium',
          img: 'https://www.iplace.com.br/ccstore/v1/images/?source=/file/v8650436604379720430/products/226690.01-apple-iphone-15-pro-max-de-256gb-titanio-preto-mu773be-a.jpg&height=424&width=424&quality=0.9',
          spec: [
            {
              Color: 'Black Titanium',
            },
            {
              RAM: '256 GB',
            },
          ],
          seller: 'Artel Marke',
          price: '$78.99',
          unit: 'CAD',
          quantity: '1',
        },
        {
          name: 'OnePlus Bullets Wireless Z2 Bluetooth Headset (Magico Black, In the Ear)',
          img: 'https://m.media-amazon.com/images/I/51UhwaQXCpL._AC_UF1000,1000_QL80_.jpg',
          spec: [
            {
              Color: 'Magico Black',
            },
          ],
          seller: 'Best factory LLC',
          price: '$39.00',
          unit: 'CAD',
          quantity: '2',
        },
        {
          name: 'boAt 20000 mAh 22.5 W Power Bank Carbon Black.',
          img: 'https://rukminim1.flixcart.com/image/300/300/xif0q/power-bank/x/e/v/-original-imagrzjfvsnyhurr.jpeg',
          spec: [
            {
              Color: 'Carbon Black',
            },
            {
              Backup: '20000 mAH',
            },
          ],
          seller: 'Artel Market',
          price: '$170.50',
          unit: 'CAD',
          quantity: '1',
        },
      ],
      summary: {
        sub: {
          Subtotal: '$1403.97',
          Discount: '- $60.00',
          'Delivery charge': 'Free',
          Tax: '$140',
        },
        total: {
          Total: '$1357.97',
        },
      },
    };
  },
};
</script>

<style lang="scss">
.header-area-cart {
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 20px;
  font-family: 'Quicksand';
  font-size: 18px;
}
.cart-items-container {
  height: auto;
  font-size: 14px;
  height: auto;
  min-height: 300px;
  display: grid;
  grid-template-columns: 68% 30%;
  grid-gap: 2%;
  padding: 0 20px;
  .cart-items {
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    height: auto;
    min-height: 300px;
    padding: 20px;
    width: 100%;
    .item-container {
      display: flex;
      height: auto;
      min-height: 130px;
      margin-bottom: 20px;
      .left-container {
        display: flex;
        width: 70%;
        height: auto;
        .img-container {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #d8d8d8;
          border-radius: 3px;
          width: 110px;
          height: 110px;
          img {
            object-fit: contain;
            height: 100px;
            width: 100px;
          }
        }
      }
      .right-container {
        display: flex;
        flex-direction: column;
        width: 30%;
        height: auto;
        gap: 20px;
        align-items: flex-end;
      }
    }
  }
  .cart-summary {
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    height: fit-content;
    padding: 20px;
    width: 100%;
    box-shadow: 0px 4px 10px 0px #3838381a;
  }
}
</style>
