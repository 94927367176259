<template>
    <section
style="gap: 12px;font-family: OpenSans;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));">
  <section v-for="(i,index) in offersList" :key="index">
    <section style="display: flex;border:1px solid #A6A6A6;border-radius:4px;padding-right: 12px;padding-bottom: 6px;justify-content: space-between; cursor:pointer">
        <section>
            <hlx-file-upload
             :file-type="'.jpg,.jpeg,.png'"
             :file-size-limit="'20mb'"
             :uploadtype="'Rectangle'"
             :style-background="backgrounStyleupload"
             :height="60"
             :width="60"
             :read-only="true"
             :profile-icon="'icon-camera-slash-filled'"
             :type="'profile-upload'"
             :content="'Import'"
             :profile-pic-preview=i?.src
             />
        </section>
        <section
        :data-tooltip="i['name']"
        data-tooltip-location="right"
style="display: flex;
width: 50%;
    flex-direction: column;
        justify-content: center;">
            <span
style="font-family: OpenSans;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
font-size: 14px;
font-weight:bold;">{{ i.name }}</span>
            <span
style="
font-size: 12px;
font-weight:bold;">{{ i.price }}</span>
        </section>
        <span
style="display: flex;
font-size: 12px;
    align-items: flex-end;">{{ "Qty : "+i.qty }}</span>
    </section>
  </section>
</section>
</template>

<script>
import {
  UPCAxiosInstance,
} from "@/config/axiosConfig";
export default {
    props:{
        bundlePreviewOffers:{
        type:Array,
        default:()=>{
            return []
        }
    }
    },
data(){
    return{
        offersList:[
            {name:"OnePlus 9RT 5G OnePlus 9RT 5G OnePlus 9RT 5G",qty:12,price:"$500"},
            {name:"OnePlus 5G",qty:12,price:"$700"},
            {name:"OnePlus NeoG Pro",qty:12,price:"$1500"},
            {name:"OnePlus F2 5G",qty:12,price:"$5000"}
        ],
        backgrounStyleupload: {
            backgroundColor: "",
        bacgroundtTextColor: "#A8A8A8",
        border: false,
        borderColor: "#fff",
        TextfontSize: 16,
      },
    }
},
watch:{
    bundlePreviewOffers:{
        handler(val){
            console.log("bundlePreviewOffers",val)
            this.offersList=val
            this.getImageSource(val)
        },
        immediate: true,
        deep: true,
    } 
},
methods:{
    async getImageSource(values) {
  try {
    for (const val of values) {
      if (val?.prodSpecCharValue?.[0]?.attachment?.[0]) {
        let imageName = val?.prodSpecCharValue[0]?.attachment[0]?.name;
        const response = await UPCAxiosInstance.get(`/gcpupload/files/${imageName}`, {
          responseType: "arraybuffer",
        });
        const uint8Array = new Uint8Array(response.data);
        let binary = "";
        uint8Array.forEach((byte) => {
          binary += String.fromCharCode(byte);
        });
        const base64String = window.btoa(binary);
        val.src = `data:image/png;base64,${base64String}`;
      }
    }
  } catch (error) {
    console.error("Error fetching image:", error);
  }
  return values;
}
}
}
</script>

<style lang="scss">
body{
    font-family: OpenSans;
}
</style>