<template>
  <div style="padding: 30px">
    <div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
        "
      >
        <div style="display: flex; align-items: center; gap: 16px">
          <span style="">
            <hlx-file-upload
              :file-type="'.jpg,.jpeg,.png'"
              :file-size-limit="'20mb'"
              :uploadtype="'Rectangle'"
              :style-background="backgrounStyleupload"
              :height="50"
              :width="50"
              :read-only="true"
              :custom-icon="icon"
              :type="'profile-upload'"
              :name-display="payload.customName"
              :content="'Import'"
              :profile-pic-preview="profilePicUrl"
            />
          </span>





          <div style="display: flex; flex-direction: column; gap: 8px">
            <div style="display: flex; align-items: center; gap: 8px">
              <span style="font-weight: bold">{{ payload.customName }}</span>
              <hlx-label
                v-if="payload.status.length > 0 && payload.status === 'Active'"
                class="primary sm"
                >{{ payload.status }}</hlx-label
              >
              <hlx-label
                v-if="
                  payload.status.length > 0 && payload.status === 'Inactive'
                "
                class="error sm"
                >{{ payload.status }}</hlx-label
              >
            </div>
            <div style="color: #a6a6a6; font-size: 12px; width: max-content">
              {{ payload.contactMedium[3].characteristic.description }}
            </div>
          </div>       
        </div>
      </div>
    </div>






    <div v-if="contractData<0"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
        height: 80vh;
      "
    >
        <img src="@/assets/images/supplierPurchase.svg" alt="" />
    </div>








    <div v-if="contractData?.length >= 1" class="header-add-supplier">
      <div class="tablehead-add-supplier" >{{ tablehead }}</div>
      <div class="search-add-supplier">
          <span><hlx-search v-model="searchvalue" @search-key="searchKey"></hlx-search></span>
      </div>
   </div>









   





       
   <div
            v-if="contractData.length > 0"
            class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
          >
            <hlx-table
              :column-count="accounts_table_theads.length + 2"
              :border="['table', 'header', 'horizontal']"
              :row-hover="true"
              theme="grey"
              :re-arrange-columns="re_arrange"
              :re-arrange-heads="accounts_table_theads_cc"
              @close-rearrange="arrangeColumns"
              @apply-rearranged-columns="applyColumns"
            >
              <template #thead>
                <hlx-table-head
                  :width="60"
                  :align="'center'"
                  style="padding: 0 15px"
                  :fixed="'left'"
                  >{{ "S.no" }}</hlx-table-head
                >
                <hlx-table-head
                  v-for="(i, index) in accounts_table_theads"
                  :key="index"
                  :prop="i.prop"
                  :sortable="i.sortable"
                  :resizable="i.resizable"
                  :width="i.width !== undefined ? i.width : ''"
                  :align="i.align !== undefined ? i.align : ''"
                  @sorting_func="sorting_Data"
                >
                  {{ i.label }}</hlx-table-head
                >
                <hlx-table-head
                  v-show="editMode == true"
                  :align="'center'"
                  :width="70"
                  :fixed="'right'"
                  ><span class="action-icon">
                    <i
                      class="icon-settings-regular"
                      style="cursor: context-menu !important"
                    ></i> </span
                ></hlx-table-head>
              </template>
              <template #tbody>
                <tr v-for="(i, index) in paginatedData" id="" :key="index">
                  <hlx-table-cell :align="'center'" :fixed="'left'">
                    {{ serialNumber(index) }}
                  </hlx-table-cell>
                  <hlx-table-cell
                    v-for="(j, col_index) in accounts_table_theads"
                    :key="col_index"
                    :align="j.align"
                    :fixed="j.fixed"
                  >
                 
                    <div v-if="j.prop.toLowerCase() === 'id'">
                      {{ i[j.prop] }}
                    </div>
                
                    <div v-if="j.prop.toLowerCase() === 'ordernumber'">
                      {{ i[j.prop] }}
                    </div>
                    <div
                      v-else-if="j.prop.toLowerCase() === 'orderdate'"
                      style="margin: 0.5rem 0"
                    >
                      {{ i[j.prop] }}
                    </div>
                    <div
                      v-else-if="j.prop.toLowerCase() === 'customername'"
                      style="margin: 0.5rem 0"
                    >
                      {{ i[j.prop] }}
                    </div>
                    <div v-else-if="j.prop.toLowerCase() === 'status'">
                      <div>
                <hlx-status :type="i[j.prop]"></hlx-status>
              </div>
                    </div>
                   
                    
                    <div
                      v-else-if="j.prop.toLowerCase() === 'amount'"
                      style="margin: 0.5rem 0"
                    >
                      {{ i[j.prop] }}
                    </div>
                   
                    <!-- <div v-else>{{ i[j.prop] }}</div> -->
                  </hlx-table-cell>
                  <hlx-table-cell
                    v-show="editMode === true"
                    :align="'center'"
                    :width="120"
                    :fixed="'right'"
                  >
                    <span :id="'table-context' + index" class="action-icon">
                      <i
                        style="position: relative"
                        class="icon-more-vertical-filled"
                        :class="i.context === true ? 'active-action' : ''"
                        @click="crudContextMenu($event, index)"
                      >
                        <hlx-context-menu
                          :top="containertop"
                          :left="containerleft"
                          :conditionvalue="150"
                          :conditiondown="2"
                          :conditionup="-38"
                          :options="computedTableAction(i)"
                          :data="index.toString()"
                          :show="i.context == true"
                          @chosen="closeCrudContextMenu($event, i, index)"
                        />
                      </i>
                    </span>
                  </hlx-table-cell>
                </tr>
              </template>
            </hlx-table>
          </div>









  </div>
</template>

<script>
import { MLTAxiosInstance,UPCAxiosInstance } from "@/config/axiosConfig";

export default {
  data() {
    return {
      currPage: 1,
      rowsPerPage: 10,

      searchvalue:"",
      contractData: [
           {
            id: "732158",
             ordernumber: "09637 - 63839",
            status: "Active",
            amount: "$35,000.00",
            orderdate: "Jan 10, 2024",
            customername:"Emily Thompson",
           
          },
          {
            id: "732158",
            ordernumber: "09637 - 63839",
            status: "Active",
            amount: "$35,000.00",
            orderdate: "Jan 10, 2024",
            customername:"Emily Thompson",
           
          },
          {
            id: "732158",
            ordernumber: "09637 - 63839",
            status: "Active",
            amount: "$35,000.00",
            orderdate: "Jan 10, 2024",
            customername:"Emily Thompson",
           
          },
        ],


      tableData: [
           {
            id: "732158",
             ordernumber: "09637 - 63839",
            status: "Active",
            amount: "$35,000.00",
            orderdate: "Jan 10, 2024",
            customername:"Emily Thompson",
           
          },
          {
            id: "732158",
            ordernumber: "09637 - 63839",
            status: "Active",
            amount: "$35,000.00",
            orderdate: "Jan 10, 2024",
            customername:"Emily Thompson",
           
          },
          {
            id: "732158",
            ordernumber: "09637 - 63839",
            status: "Active",
            amount: "$35,000.00",
            orderdate: "Jan 10, 2024",
            customername:"Emily Thompson",
           
          },
        ],
        statusList: [
          { name: "Active", value: "Active" },
          { name: "Inactive", value: "Inactive" },
        ],
        accounts_table_theads: [
          {
            sortable: false,
            resizable: false,
            width: 100,
            label: "ID",
            prop: "id",
            align: "left",
          },
          {
            sortable: false,
            resizable: false,
            width: 150,
            label: "Order number",
            prop: "ordernumber",
            align: "left",
          },
          {
            sortable: false,
            resizable: false,
            width: 100,
            label: "Order date",
            prop: "orderdate",
            align: "left",
          },
          {
            sortable: false,
            resizable: false,
            width: null,
            label: "Customer name",
            prop: "customername",
            align: "left",
          },
          {
            sortable: false,
            resizable: false,
            width: 100,
            label: "Status",
            prop: "status",
            align: "left",
          },
          {
            sortable: false,
            resizable: false,
            width: 150,
            label: "Amount (CAD)",
            prop: "amount",
            align: "left",
          },
         
        ],
      tablehead:"Purchase Order",
      profilePicUrl: "",
      company: "",
      userId: "",
      payload: {
        attachment: [],
        name: "",
        role: "",
        password: "",
        email: "",
        phone: "",
        tags: [],
        notes: [],
        companyName: "",
        tradingName: "",
        status: "",
        type: "",
        "@baseType": "",
        paymentTerms: [
          {
            name: "",
            id: "",
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: "Canada",
                  flags: "https://flagcdn.com/ca.svg",
                  short: "CA",
                  dial_code: "+1",
                },
                value: "",
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              "@type": "",
            },
          },
        ],
      },
    };
  },

  
  computed: {
      paginatedData() {
        return this.tableData;
      },
    },



  async mounted() {
    const val = await MLTAxiosInstance.get(`partymanagement/individual/auth`);
    this.company = val.data.company;
    this.userId = val.data.userId;
    const access = await MLTAxiosInstance.get(
      `partyRole/get/${val.data.userId}`
    );

    const payload = await MLTAxiosInstance.get(
      `partymanagement/organisation/${this.userId}?company=${this.company}`
    );
    this.payload = payload.data;
    console.log(access, val.data.company, val.data.company, val, "<>");
    const profileName = this.payload.attachment.filter((item) => {
      return item["@type"] !== undefined && item["@type"] === "profile"
        ? item
        : false;
    });
    console.log(profileName[0]?.name);
    this.profilePicName = profileName[0]?.name;
    const url = await UPCAxiosInstance.get(
      `/gcpupload/files/${profileName[0]?.name}`,
      { responseType: "arraybuffer" }
    );
    // console.log(new Uint8Array([url.data,"url incoming"]))
    this.rawProfileUrlData = url.data;
    this.profilePicUrl = await this.bufferToDataURL(url.data);
  },



    methods: {





      
      searchKey(val) {
      this.searchvalue = val;
      console.log("search is on")
      if (val !== "") {
        let searchData = this.searchNestedObjects(
          this.contractData,
          val.toLowerCase()
        );
        this.tableData = JSON.parse(JSON.stringify(searchData));
        console.log(this.tableData, "this.tableData");
      } else {
        this.tableData = JSON.parse(JSON.stringify(this.contractData));
      }
    },


    
    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },


      
      serialNumber(index) {
        return (this.currPage - 1) * this.rowsPerPage + index + 1;
      },

      
      computedTableAction(data) {
        let options = [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
          
        ];
        return options.filter((option) => {
          if (
            data &&
            data.status &&
            data.status.toLowerCase() == "draft" &&
            ["Draft", "Mark as In-active"].includes(option.label)
          ) {
            return false;
          } else if (
            data &&
            data.status &&
            data.status.toLowerCase() == "active" &&
            ["Draft", "Mark as active"].includes(option.label)
          ) {
            return false;
          } else if (
            data &&
            data.status &&
            data.status.toLowerCase() == "inactive" &&
            ["Draft", "Mark as In-active"].includes(option.label)
          ) {
            return false;
          } else {
            return true;
          }
        });
      },



    async bufferToDataURL(buffer) {
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = "";
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },}
};
</script>

<style></style>
