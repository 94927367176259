<template>
  <div class="accordion" style="padding-left: 52px; padding-top: 20px">
    <!-- Main Sections -->
    <div
      style="
        display: flex;
        align-items: center;
        font-family: 'Opensans';
        font-size: 14px;
        font-weight: 600;
        line-height: 19.07px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      "
    >
      <div style="width: 24%">Modules</div>
      <div
        style="
          display: flex;
          gap: 187px;
          /* width: 117%; */
          justify-content: space-around; /* width: 102%; */ /* justify-content: space-around; */
        "
      >
        <div>All</div>
        <div>Add</div>
        <div>Edit</div>
        <div>View</div>
        <div>Delete</div>
      </div>
    </div>
    <div v-for="(section, index) in sections" :key="index">
      <button
        class="accordion-btn"
        :class="{ active: section.isOpen }"
        @click="toggleSection(index)"
      >
        <div style="width: 25%">
          <i
            :style="{
              visibility:
                section?.child?.length ||
                section?.section?.length ||
                section?.sections?.length
                  ? 'visible'
                  : 'hidden',
            }"
            :class="
              section.isOpen
                ? 'icon-angle-down-regular'
                : 'icon-angle-right-regular'
            "
            class="accordion-icon"
          ></i>
          {{ section.customName || section.name }}
        </div>
        <div
          style="
            display: flex;
            width: 80%;

            justify-content: space-between;
          "
        >
          <hlx-input
            v-model:value="section.all"
            :checked="section.all"
            :type="'single-partial-checkbox'"
            :partially-checked="false"
            @selected="updateAll($event, section)"
          />
          <hlx-input
            v-model:value="section.add"
            :checked="section.add"
            :type="'single-partial-checkbox'"
            :partially-checked="section.partiallyAdd"
            @selected="selectedValcheckbox($event, section.add)"
          />
          <hlx-input
            v-model:value="section.edit"
            :checked="section.edit"
            :type="'single-partial-checkbox'"
            :partially-checked="section.partiallyEdit"
            @selected="selectedValcheckbox($event, section.edit)"
          />
          <hlx-input
            v-model:value="section.view"
            :checked="section.view"
            :type="'single-partial-checkbox'"
            :partially-checked="section.partiallyView"
            @selected="selectedValcheckbox($event, section.view)"
          />

          <hlx-input
            v-model:value="section.delete"
            :checked="section.delete"
            :type="'single-partial-checkbox'"
            :partially-checked="section.partiallyDelete"
            @selected="selectedValcheckbox($event, section.delete)"
          />
        </div>
      </button>
      <div class="panel" :class="{ show: section.isOpen }">
        <!-- <p class="pannelFirstLayer">{{ section.content }}</p> -->

        <!-- Inner Accordion Buttons -->
        <div
          v-for="(inner, innerIndex) in section.child ||
          section.section ||
          section.sections"
          :key="innerIndex"
        >
          <button
            class="accordion-btn inner-accord"
            :class="{ active: inner.isOpen }"
            @click="toggleInnerSection(index, innerIndex)"
          >
            <div style="width: 22%">
              <i
                :style="{
                  visibility:
                    inner?.child?.length ||
                    inner?.section?.length ||
                    inner?.sections?.length
                      ? 'visible'
                      : 'hidden',
                }"
                :class="
                  inner.isOpen
                    ? 'icon-angle-down-regular'
                    : 'icon-angle-right-regular'
                "
                class="accordion-icon"
              ></i>
              {{ inner.customName || inner.name }}
            </div>
            <div
              style="
                display: flex;
                width: 80%;

                justify-content: space-between;
              "
            >
              <hlx-input
                v-model:value="inner.all"
                :checked="inner.all"
                :type="'single-partial-checkbox'"
                :partially-checked="false"
                @selected="updateAll($event, inner)"
              />
              <hlx-input
                v-model:value="inner.add"
                :checked="inner.add"
                :type="'single-partial-checkbox'"
                :partially-checked="inner.partiallyAdd"
                @selected="
                  selectedValcheckbox($event, inner.add, section, inner)
                "
              />
              <hlx-input
                v-model:value="inner.edit"
                :checked="inner.edit"
                :type="'single-partial-checkbox'"
                :partially-checked="inner.partiallyEdit"
                @selected="selectedValcheckbox($event, inner.edit, section)"
              />
              <hlx-input
                v-model:value="inner.view"
                :checked="inner.view"
                :type="'single-partial-checkbox'"
                :partially-checked="inner.partiallyView"
                @selected="
                  selectedValcheckbox($event, inner.view, section, inner)
                "
              />

              <hlx-input
                v-model:value="inner.delete"
                :checked="inner.delete"
                :type="'single-partial-checkbox'"
                :partially-checked="inner.partiallyDelete"
                @selected="
                  selectedValcheckbox($event, inner.delete, section, inner)
                "
              />
            </div>
          </button>
          <div class="panel" :class="{ show: inner.isOpen }">
            <!-- <p class="pannelsecoundLayer">{{ inner.content }}</p> -->

            <!-- Third Layer Accordion -->
            <div
              v-for="(third, thirdIndex) in inner.child ||
              inner.section ||
              inner.sections"
              :key="thirdIndex"
            >
              <button
                class="accordion-btn inner-accord third-accord"
                :class="{ active: third.isOpen }"
                @click="toggleThirdLayer(index, innerIndex, thirdIndex)"
              >
                <div style="width: 20%">
                  <i
                    :style="{
                      visibility:
                        third?.child?.length ||
                        third?.section?.length ||
                        third?.sections?.length
                          ? 'visible'
                          : 'hidden',
                    }"
                    :class="
                      third.isOpen
                        ? 'icon-angle-down-regular'
                        : 'icon-angle-right-regular'
                    "
                    class="accordion-icon"
                  ></i>
                  {{ third.customName || third.name }}
                </div>
                <div
                  style="
                    display: flex;
                    width: 80%;

                    justify-content: space-between;
                  "
                >
                  <hlx-input
                    v-model:value="third.all"
                    :checked="third.all"
                    :type="'single-partial-checkbox'"
                    :partially-checked="false"
                    @selected="updateAll($event, third)"
                  />
                  <hlx-input
                    v-model:value="third.add"
                    :checked="third.add"
                    :type="'single-partial-checkbox'"
                    :partially-checked="third.partiallyAdd"
                    @selected="
                      selectedValcheckbox($event, third.add, inner, third)
                    "
                  />
                  <hlx-input
                    v-model:value="third.edit"
                    :checked="third.edit"
                    :type="'single-partial-checkbox'"
                    :partially-checked="third.partiallyEdit"
                    @selected="
                      selectedValcheckbox($event, third.edit, inner, third)
                    "
                  />
                  <hlx-input
                    v-model:value="third.view"
                    :checked="third.view"
                    :type="'single-partial-checkbox'"
                    :partially-checked="third.partiallyView"
                    @selected="
                      selectedValcheckbox($event, third.view, inner, third)
                    "
                  />

                  <hlx-input
                    v-model:value="third.delete"
                    :checked="third.delete"
                    :type="'single-partial-checkbox'"
                    :partially-checked="third.partiallyDelete"
                    @selected="
                      selectedValcheckbox($event, third.delete, inner, third)
                    "
                  />
                </div>
              </button>
              <div class="panel" :class="{ show: third.isOpen }">
                <!-- <p class="pannelTiredLayer">{{ third.content }}</p> -->

                <!-- Fourth Layer Accordion -->
                <div
                  v-for="(forth, forthIndex) in third.child ||
                  third.section ||
                  third.sections"
                  :key="forthIndex"
                >
                  <button
                    class="accordion-btn inner-accord fourth-accord"
                    :class="{ active: forth.isOpen }"
                    @click="
                      toggleForthLayer(
                        index,
                        innerIndex,
                        thirdIndex,
                        forthIndex
                      )
                    "
                  >
                    <div style="width: 20%">
                      <i
                        :style="{
                          visibility:
                            forth?.child?.length ||
                            forth?.section?.length ||
                            forth?.sections?.length
                              ? 'visible'
                              : 'hidden',
                        }"
                        :class="
                          forth.isOpen
                            ? 'icon-angle-down-regular'
                            : 'icon-angle-right-regular'
                        "
                        class="accordion-icon"
                      ></i>
                      {{ forth.customName || forth.name }}
                    </div>
                    <div
                      style="
                        display: flex;
                        width: 80%;

                        justify-content: space-between;
                      "
                    >
                      <hlx-input
                        v-model:value="forth.all"
                        :checked="forth.all"
                        :type="'single-partial-checkbox'"
                        :partially-checked="false"
                        @selected="updateAll($event, forth)"
                      />
                      <hlx-input
                        v-model:value="forth.add"
                        :checked="forth.add"
                        :type="'single-partial-checkbox'"
                        :partially-checked="forth.partiallyAdd"
                        @selected="
                          selectedValcheckbox($event, forth.add, third, forth)
                        "
                      />
                      <hlx-input
                        v-model:value="forth.edit"
                        :checked="forth.edit"
                        :type="'single-partial-checkbox'"
                        :partially-checked="forth.partiallyEdit"
                        @selected="
                          selectedValcheckbox($event, forth.edit, third, forth)
                        "
                      />
                      <hlx-input
                        v-model:value="forth.view"
                        :checked="forth.view"
                        :type="'single-partial-checkbox'"
                        :partially-checked="forth.partiallyView"
                        @selected="
                          selectedValcheckbox($event, forth.view, third, forth)
                        "
                      />

                      <hlx-input
                        v-model:value="forth.delete"
                        :checked="forth.delete"
                        :type="'single-partial-checkbox'"
                        :partially-checked="forth.partiallyDelete"
                        @selected="
                          selectedValcheckbox(
                            $event,
                            forth.delete,
                            third,
                            forth
                          )
                        "
                      />
                    </div>
                  </button>
                  <div class="panel" :class="{ show: forth.isOpen }">
                    <!-- <p class="pannelForthLayer">{{ forth.content }}</p> -->
                  </div>
                </div>
                <!-- End Fourth Layer Accordion -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  // DMNAxiosInstance,
  // MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
export default {
  data() {
    return {
      sections: [],
    };
  },
  async mounted() {
    const defaultSettings = await UPCAxiosInstance.get(`/customHierarchy`);

    this.sections = defaultSettings?.data[0]?.module[0].main;
    console.log(
      defaultSettings?.data[0]?.module[0].main,
      "defaultSettings",
      this.sections
    );
  },
  methods: {
    updateAll(event, section) {
      const setChildrenValues = (item, value) => {
        item.all = value;
        item.add = value;
        item.edit = value;
        item.view = value;
        item.delete = value;
        item.partiallyChecked = false;
        item.partiallyDelete = false;
        item.partiallyView = false;
        item.partiallyAdd = false;
        item.partiallyEdit = false;

        if (item.child) {
          item.child.map((inner) => setChildrenValues(inner, value));
        }
        if (item.child) {
          item.child.map((third) => setChildrenValues(third, value));
        }
        if (item.section) {
          item.section.map((forth) => setChildrenValues(forth, value));
        }
      };

      // Update children
      setChildrenValues(section, section.all);
    },

    selectedValcheckbox(event, section, key, sectionVal) {
      if (section === true) {
        if (key.all === false) {
          if (key.edit === false) {
            key.partiallyEdit = true;
          } else {
            key.partiallyEdit = false;
          }
          if (key.add === false) {
            key.partiallyAdd = true;
          } else {
            key.partiallyAdd = false;
          }
          if (key.view === false) {
            key.partiallyView = true;
          } else {
            key.partiallyView = false;
          }
          if (key.delete === false) {
            key.partiallyDelete = true;
          } else {
            key.partiallyDelete = false;
          }
        }
      }
      console.log(event, section, key, sectionVal, "event, section, key");
    },
    toggleSection(index) {
      this.sections[index].isOpen = !this.sections[index].isOpen;
    },
    toggleInnerSection(sectionIndex, innerIndex) {
      this.sections[sectionIndex].child[innerIndex].isOpen =
        !this.sections[sectionIndex].child[innerIndex].isOpen;
    },
    toggleThirdLayer(sectionIndex, innerIndex, thirdIndex) {
      this.sections[sectionIndex].child[innerIndex].child[thirdIndex].isOpen =
        !this.sections[sectionIndex].child[innerIndex].child[thirdIndex].isOpen;
    },
    toggleForthLayer(sectionIndex, innerIndex, thirdIndex, forthIndex) {
      this.sections[sectionIndex].child[innerIndex].child[thirdIndex].section[
        forthIndex
      ].isOpen =
        !this.sections[sectionIndex].child[innerIndex].child[thirdIndex]
          .section[forthIndex].isOpen;
    },
  },
};
</script>

<style scoped>
.pannelFirstLayer {
  font-family: "Opensans";
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  padding-left: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.pannelsecoundLayer {
  font-family: "Opensans";
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  padding-left: 58px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.pannelTiredLayer {
  font-family: "Opensans";
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  padding-left: 78px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.pannelForthLayer {
  font-family: "Opensans";
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  padding-left: 54px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
/* Accordion styles */
.fourth-accord {
  padding-left: 54px !important;

  /* background-color: #7f8c8d; */
}
/*.fourth-accord:hover,
.fourth-accord.active {
  /* background-color: #95a5a6; 
}*/
.accordion-btn {
  position: relative;
  /* background-color: #2c3e50; */
  display: flex;
  align-items: center;
  font-family: "Opensans";
  font-size: 16px;
  font-weight: 600;
  line-height: 19.07px;
  cursor: pointer;
  padding: 14px 0;
  width: 100%;
  text-align: left;
  border: none;
  transition: 0.3s;
}
.accordion-icon {
  float: left;
  margin-right: 10px;
  color: black;
  transition: transform 0.3s;
}
.accordion-btn.active .accordion-icon {
  color: balck;
  /* transform: rotate(90deg);  Rotate when active */
}
.panel {
  /* background-color: black; */
  max-height: 0;
  /* overflow: hidden; */
  height: auto;
  transition: max-height 0.3s ease, opacity 0.3s;
  opacity: 0;
}
.panel.show {
  opacity: 1;
  max-height: 100%; /* Adjust to control expansion height */
}
.inner-accord {
  /* background-color: #5b80a4; */
  font-family: "Opensans";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding-left: 30px;

  /* padding: 1em 1.75em; */
}
/*.inner-accord:hover,
.inner-accord.active {
   background-color: #7b99b7; 
}*/
.third-accord {
  padding-left: 52px;

  /* background-color: #3498db; */
  /* padding: 0.8em 2.5em; */
}
/*.third-accord:hover,
.third-accord.active {
   background-color: #4ca3e6; 
}*/
</style>
