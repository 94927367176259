<template>
<ActivityLogComponent></ActivityLogComponent>
</template>

<script>
import ActivityLogComponent from '@/components/SM/activityLogComponent.vue';
export default {
    components: {
        ActivityLogComponent
    },
}
</script>