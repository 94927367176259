<template>
<div class="template-builder-container">
   <div class="template-builder-left-side-container" style="position: relative;" @dragenter="enter" @dragover="over" @dragleave="end" @drop="drop">
     <section class="template-header">
         <p>Halleyx</p>      
     </section>
     <section class="template-content">
        <p class="template-content-title">Sales Quote Template</p>
        <span>

        </span>
     </section>
   </div>
   <div class="template-builder-right-side-container">
    <hlx-switch
        v-model="selectedValue"
        :switch-items="tabsection"
        value-prop="name"
        display-prop="name"
      />

      <div v-if="selectedValue == 'Elements'" class="template-builder-right-side-container-elements-area" >
        <p>Absolute elements</p>
        <div class="template-builder-right-side-container-elements-area-container">
          <div :id="'textfield'+unique" class="template-builder-right-side-container-elements-area-container-each-element" draggable="true" @dragstart="dragstart">
            <p>Textfield</p>
          </div>
        </div>
      </div>
   </div>


</div>
</template>


<script>

export default{
    data()
    {
        return{
            overmouseY:0,
            overmouse:0,
            unique:0,
            offsetX:0,
            offsetY:0,
        tabsection:[
            {
              name:'Elements',
            },
            {
              name:'Style',
            },
            {
              name:'Action',
            },
            {
              name:'Properties',
            }
          ],
          selectedValue:'Elements'

        }
    },
    mounted()
    {
//         this.$nextTick(()=>{

//         document.getElementById('textfield'+this.unique).addEventListener('dragstart', function(event) {
//                 this.offsetX = event.clientX - event.target.getBoundingClientRect().left;
//                 this.offsetY = event.clientY - event.target.getBoundingClientRect().top;
//          console.log(this.offsetX,this.offsetY);
//   });
//   // Event listener for drag
//   document.getElementById('textfield'+this.unique).addEventListener('drag', this.setPosition);

//   // Event listener for dragend
//   document.getElementById('draggableDiv').addEventListener('dragend', this.setPosition);
// })

    },
    methods:{
        setPosition(event) {
    const newPosition = this.getPosition(event);
    const newX = newPosition.x - this.offsetX;
    const newY = newPosition.y - this.offsetY;

    document.getElementById('draggableDiv').style.left = newX + 'px';
    document.getElementById('draggableDiv').style.top = newY + 'px';
  },
  getPosition(event) {
    return {
      x: event.clientX,
      y: event.clientY
    };
  },
  dragstart(event)
  {
    console.log(event.clientX);
  },
  enter(event)
  {
console.log(event.clientX);
  },
  over(event)
  {
    this.overmouse=event.clientX
    this.overmouseY = event.clientY
    console.log(event.clientX);
  },
  end()
  {
    let a = document.createElement("div")
    a.classList.add("myStyle")
    a.style.top = this.overmouse + 'px'
    a.style.left = this.overmouseY + 'px'

    // a.clientX = event.clientX
    // a.clientY = event.clientY
    console.log(a,'add Element');
    document.querySelector('.template-builder-left-side-container').appendChild(a)
    // document.getElementById('textfield'+this.unique).style.position = 'absolute'
    // document.getElementById('textfield'+this.unique).clientX = event.clientX
    // document.getElementById('textfield'+this.unique).clientY = event.clientY
    console.log('end');

    this.unique+=1
  },
  drop(event)
  {
    console.log(event,'drop');

  }
    }
}
</script>