<!-- eslint-disable vue/no-template-shadow -->
<!-- eslint-disable vue/no-v-html -->
<template>
  <hlx-modal
    :key="componentKey"
    :modal-active="noteModal"
    :outer-layer-close-option="false"
    :width="'750px'"
    :modal="true"
    @close="closeNoteModal"
  >
    <template #header>
      <div v-if="!editNoteModal">Add note</div>
      <div v-else>Edit note</div>
    </template>
    <template #content>
      <div class="">
        <hlx-text-editor
          :key="componentKey"
          v-model:modelValue="currentNoteData"
          :height="'460px'"
          :chars="'250'"
          :close="false"
          :border="false"
          @current-change="textEditorValue"
        >
        </hlx-text-editor>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="closeNoteModal"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        @click="saveCurrentNote"
        >Save</hlx-button
      >
    </template>
  </hlx-modal>
  <hlx-modal
    :modal-active="rearrange_columns_modal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="item_wise_discount === false ? '590px' : '650px'"
    :width="'450px'"
    :modal="true"
    @close="closeRearrangeColumnModal"
  >
    <template #header>
      <div>Edit columns</div>
    </template>
    <template #content>
      <div class="modal-content">
        <table id="myTable">
          <tr class="gap-row">
            <th></th>
            <th align="left">Column name</th>
            <th align="left" width="100px">Column type</th>
            <th width="50px"></th>
          </tr>
          <tr class="gap-row">
            <td style="padding-right: 10px"></td>
            <td style="padding-right: 10px">
              <!-- Adjust the value as needed -->
              <hlx-input
                v-model:value="rearrange_first_column_name"
                :pre-val="rearrange_first_column_name"
              ></hlx-input>
            </td>
            <td style="padding-right: 10px">
              <!-- Adjust the value as needed -->
              Text
            </td>
            <td></td>
          </tr>
          <tr
            v-for="(item, index) in pricing_table_theads_rearrange"
            :key="index"
            class="gap-row"
            draggable="true"
            @dragstart="dragRowStart(index)"
            @dragover.prevent
            @dragleave="dragRowLeave(index)"
            @dragend="dragRowEnd2(index)"
          >
            <td style="padding-right: 15px">
              <span
                :id="'rearrange-hover-' + index"
                style="
                  display: flex;
                  background: white;
                  height: 26px;
                  align-items: center;
                  justify-content: center;
                  border-radius: 4px;
                  border: 1px solid #d8d8d8;
                "
                :style="{ cursor: 'grab' }"
              >
                <i
                  class="icon-more-vertical-regular"
                  :style="{ cursor: 'grab' }"
                ></i>
              </span>
            </td>
            <td style="padding-right: 15px">
              <!-- Adjust the value as needed -->
              <hlx-input
                v-model:value="item.label"
                :pre-val="item['label']"
                type="text"
              ></hlx-input>
            </td>
            <td style="padding-right: 15px">
              <!-- Adjust the value as needed -->
              <span
                v-if="
                  item.prop === 'unit_of_measure' ||
                  item.prop === 'prodcut_offering_name' ||
                  item.prop === 'billing_period' ||
                  item.prop === 'discount'
                "
              >
                Text
              </span>
              <span v-else> Number </span>
            </td>
            <td>
              <span
                v-if="item.hide === false"
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <i class="icon-eye-regular" @click="item.hide = true"></i>
                <span>Hide</span>
              </span>
              <span
                v-else
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <i
                  class="icon-eye-slash-regular"
                  @click="item.hide = false"
                ></i>
                <span>Unhide</span>
              </span>
            </td>
          </tr>
          <tr>
            <td style="padding-right: 15px"></td>
            <td style="padding-right: 15px">
              <!-- Adjust the value as needed -->
              <hlx-input
                v-model:value="rearrange_last_column_name"
                :pre-val="rearrange_last_column_name"
              ></hlx-input>
            </td>
            <td style="padding-right: 15px">
              <!-- Adjust the value as needed -->
              Number
            </td>
            <td></td>
          </tr>
        </table>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="closeRearrangeColumnModal"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        @click="saveArrangedColumns"
        >Save</hlx-button
      >
    </template>
  </hlx-modal>
  <div class="pricing-table-container">
    <div class="pricing-table-header">
      <span class="title">
        <!-- Pricing table -->
      </span>
      <span>
        <hlx-button class="secondary sm" @click="openRearrangeColumnModal"
          >Edit columns</hlx-button
        >
      </span>
    </div>
    <hlx-table
      :column-count="pricing_table_theads"
      :border="['table', 'header', 'horizontal']"
      theme="grey"
    >
      <!-- theads** -->
      <template #thead>
        <hlx-table-head
          v-for="(i, index) in pricing_table_theads"
          :key="index"
          :prop="i.prop"
          :width="i.width"
        >
          <b
            v-if="i.prop === 'unit_of_measure'"
            :data-tooltip="'Unit of measure'"
            data-tooltip-location="left"
            >{{ i.label }}</b
          >
          <b v-else>{{ i.label }}</b>
        </hlx-table-head>
      </template>

      <!-- tbody** -->

      <template #tbody>
        <tr
          v-for="(i, index) in pricing_table_data"
          :id="'hover-row-' + index"
          :key="index"
          draggable="true"
          @dragstart="dragRowStart(index)"
          @dragover.prevent
          @dragleave="dragRowLeave(index)"
          @dragend="dragRowEnd(index)"
          @mouseover="hoverRow($event, index)"
          @mouseleave="unHoverRow(index)"
        >
          <hlx-table-cell
            v-for="(j, col_index) in pricing_table_theads"
            :key="col_index"
            :align="
              [
                'product_offering_name',
                'unitOfMeasure',
                'billing_period',
              ].includes(j.prop)
                ? 'left'
                : 'right'
            "
            :colspan="
              j.prop === 'product_offering_name' &&
              i.address === true &&
              i.section === false &&
              i.custom === false
                ? pricing_table_theads.length - 1
                : 0
            "
            :style="
              j.prop === 'product_offering_name' &&
              i.address &&
              !i.section &&
              !i.custom
                ? { backgroundColor: 'rgba(217, 217, 217, 0.4)' }
                : {}
            "
            :class="{
              'last-index-pricinTable':
                i.address === true &&
                col_index === pricing_table_theads.length - 1,
              'none-display-pricingTable':
                i.address === true &&
                col_index !== 0 &&
                col_index !== pricing_table_theads.length - 1,
            }"
          >
            <span
              v-if="
                j.prop === 'product_offering_name' &&
                i.address === true &&
                i.section === false &&
                i.custom === false
              "
              style="
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding: 0 0 0 4px;
              "
            >
              <span style="display: flex; align-items: center">
                <span style="display: flex; align-items: center; gap: 4px">
                  <i
                    class="icon-location-pin-regular"
                    style="height: 15px; font-size: 18px"
                  ></i>
                  <span style="width: 200px"
                    >Shipping address
                    <sup style="color: #ff5050">*</sup> :</span
                  ></span
                >
                <span style="width: 40%">
                  <hlx-select
                    v-model:value="i[j.prop]"
                    :pre-value="i[j.prop]"
                    :options="shippingOptions"
                    :slot-options="true"
                    :single-border="true"
                    :clearable="false"
                    :inline-search="true"
                    :placeholder-value="'Select your address'"
                    :label-animation="false"
                    :label="'description'"
                    :prop-value="'description'"
                  >
                    <template #displayValue="value">
                      <span
                        style="
                          padding-left: 8px;
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                        "
                      >
                        {{ value["displayLabel"].description }}
                      </span>
                    </template>
                    <template #options="option">
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          font-size: 14px;
                          padding: 8px;
                          font-family: 'Opensans';
                        "
                      >
                        <span style="font-size: 14px; font-weight: 400">{{
                          option.optionsData.description
                        }}</span>
                        <span style="padding-left: 10px; width: 20px"
                          ><i class="icon-buildings-alt-regular"></i
                        ></span>
                        <span
                          style="
                            font-size: 11px;
                            font-weight: 400;
                            padding-top: 3px;
                            padding-left: 8px;
                            color: #595959;
                          "
                          >{{ option.optionsData.companyName }}</span
                        >
                      </div>
                    </template>
                  </hlx-select>
                </span>
                <span
                  style="
                    height: 0px;
                    padding-left: 200px;
                    font-size: 10px;
                    color: rgba(133, 133, 133, 1);
                  "
                  >{{
                    "Add or drag the products below this shipping address."
                  }}</span
                >
              </span>

              <!-- <span v-if="i['notes']" style="margin-bottom: 8px">
                <hlx-text-editor
                  :key="componentKey"
                  :height="'170px'"
                  :prevalue="pricing_table_data_duplicate[index]?.description"
                  @current-change="textEditorValue($event, i, index)"
                >
                </hlx-text-editor>
              </span> -->
            </span>

            <span
              v-if="j.prop === 'product_offering_name'"
              :id="'hover-' + index"
              style="
                display: none;
                background: white;
                height: 26px;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                border: 1px solid #d8d8d8;
              "
              :style="{ cursor: 'grab' }"
            >
              <i
                class="icon-more-vertical-regular"
                :style="{ cursor: 'grab' }"
              ></i>
            </span>
            <span
              v-if="
                j.prop === 'rate' && i.section === false && i.address === false
              "
              style="display: flex; flex-direction: column; padding: 0px 4px"
            >
              <!-- {{ this.$store.getters.defaultSetting?.currency?.symbol + i[j.prop]?.toFixed(2) }} -->
              <hlx-input
                v-model:value="i[j.prop]"
                :pre-val="i[j.prop]"
                type="number"
                :symbol="$store?.getters?.getDefaultSettings?.currency?.symbol"
                :single-border="true"
                @at-input="
                  formatPriceValuesV1($event, i, j.prop),
                    triggerPricingRules('rate')
                "
              />
              <span
                v-if="
                  i['discountResponse'] !== undefined &&
                  i['discountResponse']?.productOffering?.appliedDiscount !==
                    undefined
                "
                class="card-price-discount"
                style="
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  /* margin-bottom: -22px; */
                  margin-top: 8px;
                "
                ><del style="margin-left: 12px">{{
                  $store?.getters?.getDefaultSettings?.currency?.symbol +
                  i["discountResponse"]?.productOffering?.price
                }}</del>
                <div class="pricing-details-table">
                  <h5 style="margin-bottom: 10px">Price Details</h5>
                  <div class="price-item">
                    <span>Price</span>
                    <span class="price" style="font-weight: 100">
                      {{
                        $store?.getters?.getDefaultSettings?.currency?.symbol +
                        i["discountResponse"]?.productOffering?.price
                      }}
                      {{
                        item?.billing_period !== "" &&
                        item?.billing_period !== undefined &&
                        item?.offering_type === "intangible"
                          ? "/" + item?.billing_period
                          : ""
                      }}</span
                    >
                  </div>
                  <div class="price-item">
                    <span>Product Discount</span>
                    <span class="discount" style="color: #02b55c">
                      - &nbsp;
                      <p
                        v-if="
                          i['discountResponse']?.productOffering
                            ?.appliedDiscount?.discountType?.name ==
                          'Percentage'
                        "
                      >
                        {{
                          i["discountResponse"]?.productOffering
                            ?.appliedDiscount?.discountType?.value + "%"
                        }}
                      </p>
                      <p v-else>
                        {{
                          $store?.getters?.getDefaultSettings?.currency
                            ?.symbol +
                          i["discountResponse"]?.productOffering
                            ?.appliedDiscount?.discountType?.value
                        }}
                      </p>
                    </span>
                  </div>
                  <hr />
                  <div
                    class="price-item"
                    :style="
                      i['rate'] ==
                      i['discountResponse']?.productOffering?.discountPrice
                        ? { 'font-weight': 'bold' }
                        : { 'font-weight': '100' }
                    "
                  >
                    <span>Selling Price</span>
                    <span
                      class="final-price"
                      :style="
                        i['rate'] ==
                        i['discountResponse']?.productOffering?.discountPrice
                          ? { 'font-weight': 'bold' }
                          : { 'font-weight': '100' }
                      "
                    >
                      {{
                        $store?.getters?.getDefaultSettings?.currency?.symbol +
                        i[
                          "discountResponse"
                        ]?.productOffering?.discountPrice?.toFixed(2)
                      }}</span
                    >
                  </div>
                  <div
                    v-if="
                      i['rate'] !=
                      i['discountResponse']?.productOffering?.discountPrice
                    "
                    class="price-item"
                  >
                    <span style="font-weight: bold">Revised Selling Price</span>
                    <span class="final-price">
                      {{
                        $store?.getters?.getDefaultSettings?.currency?.symbol +
                        i["rate"]
                      }}</span
                    >
                  </div>
                </div>
                <i class="icon-info-circle-regular"></i
              ></span>
            </span>
            <span
              v-else-if="
                j.prop === 'product_offering_name' &&
                i.section === false &&
                i.custom === true &&
                i.address === false
              "
              style="
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding: 0 4px;
              "
            >
              <!-- {{ this.$store.getters.defaultSetting?.currency?.symbol + i[j.prop]?.toFixed(2) }} -->
              <div style="">
                <!-- <hlx-input
                  v-model:value="i[j.prop]"
                  :pre-val="i[j.prop]"
                  type="text"
                  :single-border="true"
                /> -->
                <input
                  v-model="i[j.prop]"
                  type="text"
                  style="border: none; height: 35px"
                />
              </div>
              <!-- <span
                v-if="!i['notes']"
                class="add-new-section-button"
                style="height: 30px; display: flex; align-items: flex-start"
                @click="
                  i['notes'] = true;
                  componentKey += 1;
                "
              >
                {{ '+ Notes' }}
              </span> -->
              <span
                v-if="i['notes'].length == 0"
                class="add-new-section-button"
                style="height: 30px; display: flex; align-items: flex-start"
                @click="showNoteModal(index)"
              >
                {{ "+ Notes" }}
              </span>
              <span
                v-if="i['notes'].length != 0"
                style="
                  margin-bottom: 8px;
                  width: 250px;
                  display: flex;
                  flex-direction: column;
                "
              >
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 4px;
                  "
                >
                  <b>Notes</b
                  ><span
                    ><i class="icon-edit-regular" @click="editNote(index)"></i
                    >&nbsp;<i
                      class="icon-trash-regular"
                      @click="deleteNote(index)"
                    ></i
                  ></span>
                </div>
                <div class="note-parent">
                  <p class="block-ellipsis" v-html="i.notes"></p>
                </div>
                <!-- <hlx-text-editor
                :key="componentKey"
                v-model:modelValue="i.description"
                  :height="'170px'"
                  :close="true"
                  @current-change="textEditorValue($event, i, index)"
                >
                </hlx-text-editor> -->
              </span>
            </span>
            <span
              v-else-if="
                j.prop === 'product_offering_name' &&
                i.section === true &&
                i.custom === false &&
                i.address === false
              "
              style="
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding: 0 4px;
              "
            >
              <span
                style="
                  height: 30px;
                  display: flex;
                  align-items: center;
                  font-weight: bold;
                "
              >
                <span v-if="i.sectionView === true" :id="'new-sectionForPricingTable' + index">
                  <hlx-input                    
                    v-model:value="i[j.prop]"
                    :pre-val="i[j.prop]"
                    type="text"
                    :single-border="true"
                    @focus-out="focusOutSection(i)"
                  />
                </span>
                <span v-else @click="sectionTrue(index,i)">{{ i[j.prop] }}</span>
              </span>
              <!-- <hlx-input
                v-model:value="i[j.prop]"
                :pre-val="i[j.prop]"
                type="text"
                :single-border="true"
              /> -->
              <!-- <span
                v-if="!i['notes']"
                class="add-new-section-button"
                style="height: 30px; display: flex; align-items: flex-start"
                @click="i['notes'] = true"
              >
                {{ '+ Notes' }}
              </span> -->
              <span v-if="i['notes']" style="margin-bottom: 8px">
                <hlx-text-editor
                  :key="componentKey"
                  :height="'170px'"
                  :prevalue="pricing_table_data_duplicate[index]?.description"
                  @current-change="textEditorValue($event, i, index)"
                >
                </hlx-text-editor>
              </span>
            </span>

            <span
              v-else-if="
                j.prop === 'product_offering_name' &&
                i.section === false &&
                i.custom === false &&
                i.address === false
              "
              style="display: flex; flex-direction: column; padding: 0 4px"
            >
              <span
                style="
                  height: auto;
                  display: flex;
                  align-items: center;
                  color: #2879b7;
                  cursor: pointer;
                  margin: 5px 0;
                "
                @click="reconfigureItem(i, index)"
              >
                {{ i[j.prop] }} &nbsp;
                <i
                  v-if="i.offerType === 'Bundle'"
                  class="icon-boxes-regular"
                ></i>
              </span>
              <span
                style="
                  display: flex;
                  flex-direction: column;
                  font-size: 12px;
                  margin-bottom: 8px;
                "
              >
                <span
                  v-for="(item, index) in i.prodSpecCharValueUse"
                  :key="index"
                  style="word-break: break-all; width: 250px; display: flex"
                >
                  <span
                    v-if="
                      item.configurable && item?.default?.value !== undefined
                    "
                  >
                    {{ item.name.trim() + ":" }}
                    {{
                      item?.default?.value + " " + item?.default?.valueSuffix
                    }}
                    <!-- <span v-if="index !== i.prodSpecCharValueUse.length - 1 && item?.default?.value !== undefined">{{ ', ' }}</span> -->
                  </span>
                </span>
              </span>
              <!-- <hlx-input
                v-model:value="i[j.prop]"
                :pre-val="i[j.prop]"
                type="text"
                :single-border="true"
              /> -->
              <span
                v-if="i['notes'].length == 0"
                class="add-new-section-button"
                style="height: 30px; display: flex; align-items: flex-start"
                @click="showNoteModal(index)"
              >
                {{ "+ Notes" }}
              </span>
              <span
                v-if="i['notes'].length != 0"
                style="
                  margin-bottom: 8px;
                  width: 250px;
                  display: flex;
                  flex-direction: column;
                "
              >
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 4px;
                  "
                >
                  <b>Notes</b
                  ><span
                    ><i class="icon-edit-regular" @click="editNote(index)"></i
                    >&nbsp;<i
                      class="icon-trash-regular"
                      @click="deleteNote(index)"
                    ></i
                  ></span>
                </div>
                <div class="note-parent">
                  <p class="block-ellipsis" v-html="i.notes"></p>
                </div>
                <!-- <hlx-text-editor
                :key="componentKey"
                v-model:modelValue="i.description"
                  :height="'170px'"
                  :close="true"
                  @current-change="textEditorValue($event, i, index)"
                >
                </hlx-text-editor> -->
              </span>
            </span>
            <span
              v-else-if="
                j.prop === 'quantity' &&
                i.section === false &&
                i.address === false
              "
            >
              <!-- <hlx-stepper
                :step="1"
                :limit="10"
                @current-step="changeQuantity($event, i)"
              /> -->
              <hlx-input
                v-model:value="i[j.prop]"
                :pre-val="i[j.prop]"
                type="number"
                :single-border="true"
                :min-limit="1"
                :max-limit="i['max_quantity']"
                @at-input="
                  formatQuantity($event, i, j.prop),
                    triggerPricingRules('quantity')
                "
              />
            </span>
            <span
              v-else-if="
                j.prop === 'amount' &&
                i.section === false &&
                i.address === false
              "
              style="display: flex"
            >
              <!-- <hlx-stepper
                :step="1"
                :limit="10"
                @current-step="changeQuantity($event, i)"
              /> -->
              <!-- {{ i[j.prop] }} -->
              <hlx-input
                v-model:value="i[j.prop]"
                :pre-val="i[j.prop]"
                type="number"
                :min-limit="0"
                :symbol="$store?.getters?.getDefaultSettings?.currency?.symbol"
                :single-border="true"
                @at-input="
                  formatPriceValuesV1($event, i, j.prop),
                    triggerPricingRules('amount')
                "
              />
            </span>
            <span
              v-else-if="
                j.prop === 'commitment_length' &&
                i.section === false &&
                i.intangible === true &&
                i.address === false
              "
              style="display: flex; align-items: center; gap: 5px"
            >
              <span v-if="i.billing_period !== 'Onetime'" style="width: 60px">
                <hlx-input
                  v-model:value="i['duration']"
                  :pre-val="i['duration']"
                  type="number"
                  :single-border="true"
                  :min-limit="1"
                  @at-input="triggerPricingRules('rate')"
                ></hlx-input>
              </span>
              <span v-else style="width: 60px">
                {{ "-" }}
              </span>
              <span
                v-if="
                  i['billing_period']?.includes('Month') &&
                  i.billing_period !== 'Onetime'
                "
                style="display: flex"
                >{{ " / Month(s)" }}</span
              >
              <span
                v-if="
                  i['billing_period']?.includes('Annual') &&
                  i.billing_period !== 'Onetime'
                "
                style="display: flex"
                >{{ " / Year(s)" }}</span
              >
              <span
                v-if="
                  i['billing_period']?.includes('Week') &&
                  i.billing_period !== 'Onetime'
                "
                style="display: flex"
                >{{ " / Week(s)" }}</span
              >
            </span>
            <span
              v-else-if="
                j.prop === 'commitment_length' &&
                i.section === false &&
                i.intangible === false &&
                i.address === false
              "
              style="width: 100%; display: flex"
            >
              {{ "-" }}
            </span>
            <span
              v-else-if="
                j.prop === 'billing_period' &&
                i.section === false &&
                i.custom === false &&
                i.intangible === true &&
                i.address === false
              "
            >
              <!-- {{ i['billing_period_options'] }} -->
              <hlx-select
                v-model:value="i['billing_period']"
                placeholder-value="Type"
                :single-border="true"
                :clearable="true"
                :inline-search="true"
                :pre-value="i['billing_period']"
                :options="i['billing_period_options']"
              >
              </hlx-select>
            </span>
            <span
              v-else-if="
                j.prop === 'billing_period' &&
                i.section === false &&
                i.custom === true &&
                i.intangible === true &&
                i.address === false
              "
            >
              <!-- {{ i['billing_period_options'] }} -->
              <hlx-select
                placeholder-value="Type"
                :single-border="true"
                :clearable="true"
                :inline-search="true"
                :prop-value="'value'"
                :label="'name'"
                :pre-value="i['billing_period']"
                :options="customItemBillingOptions"
                @selected-value="changeCustomBillingPeriod($event, index)"
              >
              </hlx-select>
            </span>
            <span
              v-else-if="
                j.prop === 'billing_period' &&
                i.section === false &&
                i.custom === false &&
                i.intangible === false &&
                i.address === false
              "
              style="padding-left: 12px"
            >
              {{ i[j.prop] }}
            </span>
            <span
              v-else-if="
                j.prop === 'billing_period' &&
                i.section === false &&
                i.custom === true &&
                i.intangible === false &&
                i.address === false
              "
            >
              <hlx-select
                placeholder-value="Type"
                :single-border="true"
                :clearable="true"
                :inline-search="true"
                :prop-value="'value'"
                :label="'name'"
                :pre-value="i['billing_period']"
                :options="customItemBillingOptions"
                @selected-value="changeCustomBillingPeriod($event, index)"
              >
              </hlx-select>
            </span>
            <span
              v-else-if="
                j.prop === 'discount' &&
                i.address === false &&
                i.section === false
              "
              style="display: flex; gap: 8px"
            >
              <!-- <input
                v-model="i[j.prop]"
                type="number"
                :single-border="true"
                class="number-input"
              /> -->
              <div class="discount-div">
                <hlx-input
                  v-model:value="i[j.prop]['value']"
                  :pre-val="i[j.prop]['value']"
                  type="number"
                  :min-limit="0"
                  :max-limit="100"
                  :single-border="true"
                  @at-input="triggerPricingRules('rate')"
                >
                </hlx-input>
              </div>
              <div style="width: 70px">
                <hlx-select
                  v-model="i[j.prop]['type']"
                  placeholder-value="Type"
                  :single-border="true"
                  :clearable="true"
                  :pre-value="i[j.prop]['type']"
                  :options="discount_type_options"
                  @selected-value="changeDiscountType($event, i)"
                >
                </hlx-select>
              </div>
            </span>
            <span
              v-else-if="
                j.prop === 'line_total' &&
                i.section === false &&
                i.address === false &&
                calculateLineTotal(i) !== 'NaN'
              "
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <span>
                <!-- {{
                  $store.getters.defaultSetting?.currency?.symbol +
                  calculateLineTotal(i)
                }} -->
                {{
                  $store.getters.defaultSetting?.currency?.symbol +
                  i["lineTotal"]?.toFixed(2)
                }}
              </span>
              <span v-if="i.bundleIndex === undefined" style="display: flex">
                <i
                  class="icon-times-regular"
                  style="font-size: 18px"
                  @click="romoveRow(index)"
                ></i>
              </span>
            </span>
            <span
              v-else-if="
                (j.prop === 'line_total' && i.section === true) ||
                (j.prop === 'line_total' && i.address === true)
              "
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <span> </span>
              <span style="display: flex">
                <i
                  class="icon-times-regular"
                  style="font-size: 18px"
                  @click="romoveRow(index)"
                ></i>
              </span>
            </span>
            <span v-else style="margin: 4px 0">
              <span
                v-if="
                  i[j.prop] !== 'NaN' &&
                  i[j.prop] !== undefined &&
                  i['bundleIndex'] !== undefined &&
                  i[j.prop] !== null &&
                  i['address'] === false &&
                  j.prop !== 'billing_period' &&
                  j.prop !== 'amount' &&
                  j.prop !== 'discount' &&
                  j.prop !== 'commitment_length' &&
                  j.prop !== 'unitOfMeasure'
                "
                style="width: 100%; display: flex; padding-left: 40px"
                :style="bundleIndex !== undefined ? { marginLeft: '20px' } : {}"
              >
                <span>{{ i["product_offering_name"] }}</span>
              </span>
              <!-- <span
                v-else-if="
                  i[j.prop] !== 'NaN' &&
                  i[j.prop] !== undefined &&
                  i[j.prop] !== null &&
                  i['section'] === false &&
                  i['address'] === true &&
                  j.prop !== 'billing_period' &&
                  j.prop !== 'amount' &&
                  j.prop !== 'discount' &&
                  j.prop !== 'commitment_length'
                "
              >
                <span
                  style="
                    width: 943px;
                    background: rgba(217, 217, 217, 0.4);
                    display: flex;
                    align-items: center;
                  "
                >
                {{'address[dvc]'}}
                  <i class="icon-location-pin-regular"></i>
                  <span style="width: 200px;">Shipping address 1 <sup style="color:  #ff5050;">*</sup>:</span>

                  <hlx-select
                    v-model:value="i[j.prop]"
                    :pre-value="i[j.prop]"
                    :options="shippingOptions"
                    :slot-options="true"
                    :single-border="true"
                    :clearable="true"
                    :inline-search="true"
                    :placeholder-value="'Select your address'"
                    :label-animation="false"
                    :label="'description'"
                    :prop-value="'description'"
                  >
                    <template #displayValue="value">
                      <span
                        style="
                          padding-left: 8px;
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                        "
                      >
                        {{ value["displayLabel"].description }}
                      </span>
                    </template>
                    <template #options="option">
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          font-size: 14px;
                          padding-left: 8px;
                          font-family: 'Opensans';
                          height: 31px !important;
                        "
                      >
                        <span style="font-size: 14px; font-weight: 400">{{
                          option.optionsData.description
                        }}</span>
                        <span style="padding-left: 10px; width: 20px"
                          ><i class="icon-buildings-alt-regular"></i
                        ></span>
                        <span
                          style="
                            font-size: 11px;
                            font-weight: 400;
                            padding-top: 3px;
                            padding-left: 8px;
                            color: #595959;
                          "
                          >{{ option.optionsData.companyName }}</span
                        >
                      </div>
                    </template>
                  </hlx-select>
                </span>
              </span> -->

              <span
                v-else-if="
                  i[j.prop] !== 'NaN' &&
                  i[j.prop] !== undefined &&
                  i[j.prop] !== null &&
                  i.custom !== true
                "
                >{{ i["unitOfMeasure"] }}</span
              >
              <span
                v-else-if="
                  i[j.prop] !== 'NaN' &&
                  i[j.prop] !== undefined &&
                  i[j.prop] !== null &&
                  i.custom !== false
                "
              >
                <hlx-select
                  v-model:value="i.unitOfMeasure"
                  :pre-value="i.unitOfMeasure"
                  :options="unitOfMeasureData"
                  :inline-search="true"
                  :border="false"
                  :placeholder-value="'Each'"
                  :label-animation="false"
                  label="name"
                  :prop-value="'name'"
                  :width-container="200"
                >
                </hlx-select>
              </span>
            </span>
          </hlx-table-cell>
        </tr>
        <!-- <tr v-show="add_new_section">
          <hlx-table-cell :colspan="pricing_table_theads.length" align="left">
            <input
              id="new-section"
              v-model="section_name"
              class="section-name-input"
              type="text"
              @keydown.enter="sectionEnter"
            />
          </hlx-table-cell>
        </tr> -->
        <!-- <tr v-show="add_new_address"  >
          <hlx-table-cell
            :colspan="pricing_table_theads.length"
            align="left"
            style="background: rgba(217, 217, 217, 0.4)"
          >
            <span style="display: flex; align-items: center;padding-left: 3px;">
              <span style="display: flex; align-items: center; gap: 4px">
                <i
                  class="icon-location-pin-regular"
                  style="height: 15px; font-size: 18px"
                ></i>
                <span style="width: 200px"
                  >Shipping address 
                  <sup style="color: #ff5050">*</sup> :</span
                ></span
              >
              <span style="width: 40%">
                <hlx-select
                  id="new-address"
                  :key="multiselectComponrtKey"
                  :options="shippingOptions"
                  :slot-options="true"
                  :single-border="true"
                  :clearable="true"
                  :inline-search="true"
                  :placeholder-value="'Select your address'"
                  :label-animation="false"
                  :label="'description'"
                  :prop-value="'description'"
                  @selected-value="handleSelectedShippingAddress"
                >
                  <template #displayValue="value">
                    <span
                      style="
                        padding-left: 8px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                      "
                    >
                      {{ value["displayLabel"].description }}
                    </span>
                  </template>
                  <template #options="option">
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        padding-left: 8px;
                        font-family: 'Opensans';
                        height: 31px !important;
                      "
                    >
                      <span style="font-size: 14px; font-weight: 400">{{
                        option.optionsData.description
                      }}</span>
                      <span style="padding-left: 10px; width: 20px"
                        ><i class="icon-buildings-alt-regular"></i
                      ></span>
                      <span
                        style="
                          font-size: 11px;
                          font-weight: 400;
                          padding-top: 3px;
                          padding-left: 8px;
                          color: #595959;
                        "
                        >{{ option.optionsData.companyName }}</span
                      >
                    </div>
                  </template>
                </hlx-select>
              </span>
              <span
                style="
                  height: 0px;
                  padding-left: 200px;
                  font-size: 10px;
                  color: rgba(133, 133, 133, 1);
                "
                >{{
                  "Add or drag the products below this shipping address."
                }}</span
              >
              <span style="padding-left: 137px;">
                  <i
                  class="icon-times-regular"
                  style="font-size: 18px"
                  @click="romoveRow(index)"
                ></i>
                </span>
            </span>
          </hlx-table-cell>
        </tr> -->
        <tr>
          <hlx-table-cell
            v-for="(j, col_index) in pricing_table_theads"
            :key="col_index"
            :colspan="pricing_table_theads.length - 1"
            :class="{
              'none-display-pricingTable': col_index !== 0,
            }"
            :align="'left'"
          >
            <span class="add-new-section-button" @click="addNewProduct">
              {{ "+ Existing item" }}
            </span>
            &nbsp;
            <span class="add-new-section-button" @click="addNewItem">
              {{ "+ New item" }}
            </span>
            &nbsp;
            <span
              v-show="checkboxShipping"
              class="add-new-section-button"
              @click="addNewShipping"
            >
              {{ "+ Shipping addess" }}
            </span>
            &nbsp;
            <span class="add-new-section-button" @click="addNewSection">
              {{ "+ Section" }}
            </span>
          </hlx-table-cell>
          <!-- <hlx-table-cell
            :colspan="pricing_table_theads.length"
            align="left"
            style="display: flex; gap: 10px; align-items: center"
          >
            <span class="add-new-section-button" @click="addNewProduct">
              {{ "+ Existing item" }}
            </span>
            <span class="add-new-section-button" @click="addNewItem">
              {{ "+ New item" }}
            </span>
            <span
              v-show="checkboxShipping"
              class="add-new-section-button"
              @click="addNewShipping"
            >
              {{ "+ Shipping addess" }}
            </span>
            <span class="add-new-section-button" @click="addNewSection">
              {{ "+ Section" }}
            </span>
          </hlx-table-cell>
          <hlx-table-cell></hlx-table-cell>
          <hlx-table-cell></hlx-table-cell>
          <hlx-table-cell></hlx-table-cell>
          <hlx-table-cell></hlx-table-cell> -->
        </tr>
      </template>
    </hlx-table>

    <div
      v-if="showOnetimeSummary"
      class="total-discount onetime-total"
      style="margin-top: 16px"
    >
      <table class="total-discount-table">
        <tr class="total-discount-row">
          <td class="total-discount-cell">
            <b>Onetime charges</b>
          </td>
          <td class="total-discount-cell-right">{{}}</td>
        </tr>
        <tr class="total-discount-row">
          <td class="total-discount-cell">Sub total</td>
          <td class="total-discount-cell-right">
            {{
              $store.getters.defaultSetting?.currency?.symbol +
              // calculateSubTotal(pricing_table_data, 'onetime')
              Number(quoteSummaryFromRules?.oneTime?.subTotal)?.toFixed(2)
            }}
            <!-- {{
              setTemplateToSummary(
                "onetime",
                "subtotal",
                $store.getters.defaultSetting?.currency?.symbol +
                  calculateSubTotal(pricing_table_data, "onetime")
              )
            }} -->
          </td>
        </tr>
        <tr
          v-for="item in additionalChargesArray"
          :key="item"
          class="total-discount-row"
        >
          <td class="total-discount-cell">{{ item?.name }}</td>
          <td class="total-discount-cell-right">
            <!-- {{
              $store.getters.defaultSetting?.currency?.symbol +
              // calculateSubTotal(pricing_table_data, 'onetime')
              (item?.price ?? 0)?.toFixed(2)
            }} -->
            <div style="width: 100px">
              <hlx-input
                v-model:value="item.price"
                :pre-val="item.price"
                type="number"
                :symbol="$store.getters.defaultSetting?.currency?.symbol"
                @at-input="updateAdditionalCharges"
              >
              </hlx-input>
            </div>
          </td>
        </tr>
        <!-- <tr
          v-if="item_wise_discount_onetime"
          class="total-discount-row"
        >
          <td class="total-discount-cell">Item wise discount</td>
          <td class="total-discount-cell-right">
            <hlx-input
              v-model:value="item_discount_onetime.discount.value"
              :pre-val="item_discount_onetime.discount.value"
              :single-border="true"
              type="number"
              class="number-input"
            />
            <hlx-select
              v-model="item_discount_onetime.discount.type"
              placeholder-value="Type"
              :single-border="true"
              :clearable="true"
              :pre-value="item_discount_onetime.discount.type"
              :options="discount_type_options"
              @selected-value="
                changeDiscountType($event, item_discount_onetime)
              "
            ></hlx-select>
            <i
              class="icon-times-regular"
              style="font-size: 18px"
              @click="removeItemWiseDiscount('onetime')"
            ></i>
          </td>
        </tr> -->
        <tr v-if="item_wise_discount_onetime" class="total-discount-row">
          <td class="total-discount-cell">Amount</td>
          <td class="total-discount-cell-right">
            {{
              $store.getters.defaultSetting?.currency?.symbol +
              quoteSummaryFromRules?.oneTime?.amount?.toFixed(2)
            }}
            {{
              setTemplateToSummary(
                "onetime",
                "amount",
                $store.getters.defaultSetting?.currency?.symbol +
                  calculateTotalAmount(pricing_table_data, "onetime")
              )
            }}
          </td>
        </tr>
        <!-- <tr
          v-if="!item_wise_discount_onetime"
          class="total-discount-row"
        >
          <td
            class="total-discount-cell"
            style="cursor: pointer"
            @click="giveItemWiseDiscount('onetime')"
          >
            <i
              class="icon-tag-regular"
              style="color: #54bd95; font-size: 18px; cursor: pointer"
            ></i>
            Give item wise discount
          </td>
          <td class="total-discount-cell-right">{{}}</td>
        </tr> -->
        <tr class="total-discount-row">
          <!-- ${user_settings.tax_type} -->
          <td class="total-discount-cell">
            <b>{{ `Tax` }}</b>
          </td>
          <td
            v-if="quoteSummaryFromRules?.oneTime?.appliedTax?.length == 0"
            class="total-discount-cell-right"
          >
            <!-- {{
              $store.getters.defaultSetting?.currency?.symbol + calculateTax()
            }} -->
            Included
          </td>
        </tr>
        <tr
          v-for="tax in quoteSummaryFromRules?.oneTime?.appliedTax"
          :key="tax"
          class="total-discount-row"
        >
          <!-- ${user_settings.tax_type} -->
          <td class="total-discount-cell">
            <span>{{ tax?.taxName + " (" + tax?.percentage + "%)" }}</span>
          </td>
          <td class="total-discount-cell-right">
            {{ $store.getters.defaultSetting?.currency?.symbol + tax?.value }}
          </td>
        </tr>
        <tr
          v-for="(item, index) in total_wise_discount_array_onetime"
          :key="index"
          class="total-discount-row"
        >
          <td class="total-discount-cell">
            <hlx-input
              v-model:value="item.name"
              :pre-val="item?.name"
              type="text"
              :single-border="true"
              @at-input="triggerPricingRules('rate')"
            />
          </td>
          <td class="total-discount-cell-right">
            <div class="discount-div">
              <hlx-input
                v-model:value="item.value"
                :pre-val="item.value"
                :single-border="true"
                type="number"
                :min-limit="0"
                :max-limit="100"
                class="number-input"
                @at-input="triggerPricingRules('rate')"
              />
            </div>
            <hlx-select
              v-model="item.type"
              placeholder-value="Type"
              :pre-value="item.type"
              :single-border="true"
              :clearable="true"
              :options="discount_type_options"
              @selected-value="changeDiscountType($event, item)"
            ></hlx-select>
            <i
              class="icon-times-regular"
              style="font-size: 18px"
              @click="removeTotalWiseDiscount(index, 'onetime')"
            ></i>
          </td>
        </tr>
        <tr
          v-for="(item, index) in additional_charges_array"
          :key="index"
          class="total-discount-row"
        >
          <td class="total-discount-cell">
            <hlx-input
              v-model:value="item.name"
              :pre-val="item?.name"
              type="text"
              :single-border="true"
            />
          </td>
          <td class="total-discount-cell-right">
            <hlx-input
              v-model:value="item.discount"
              :pre-val="item.discount"
              :single-border="true"
              :min-limit="0"
              :max-limit="100"
              type="number"
              class="number-input"
            />
            <hlx-select
              v-model="item.discount.type"
              placeholder-value="Type"
              :pre-value="item.discount.type"
              :single-border="true"
              :clearable="true"
              :options="discount_type_options"
              @selected-value="changeDiscountType($event, item)"
            ></hlx-select>
            <i
              class="icon-times-regular"
              style="font-size: 18px"
              @click="removeAdditionalCharges(index)"
            ></i>
          </td>
        </tr>
        <!-- <tr class="total-discount-row">
          <td class="total-discount-cell">Tax</td>
          <td class="total-discount-cell-right">{{ tax_percentage + '%' }}</td>
        </tr>
        <tr class="total-discount-row">
          <td class="total-discount-cell">After tax</td>
          <td class="total-discount-cell-right">
            {{ $store.getters.defaultSetting?.currency?.symbol + after_tax?.toFixed(2) }}
          </td>
        </tr> -->

        <tr v-if="!total_wise_discount" class="total-discount-row">
          <td
            class="total-discount-cell"
            style="cursor: pointer"
            @click="giveTotalWiseDiscount('onetime')"
          >
            <i
              class="icon-plus-square-regular"
              style="color: #54bd95; font-size: 18px; cursor: pointer"
            ></i>
            Give discount on total
          </td>
          <td class="total-discount-cell-right">{{}}</td>
        </tr>
        <!-- <tr
          v-if="!additional_charges"
          class="total-discount-row"
        >
          <td
            class="total-discount-cell"
            style="cursor: pointer"
            @click="giveAdditionalCharges"
          >
            <i
              class="icon-plus-square-regular"
              style="color: #54bd95; font-size: 18px; cursor: pointer"
            ></i>
            Add additional charges
          </td>
          <td class="total-discount-cell-right">
            {{}}
          </td>
        </tr> -->

        <tr class="total-discount-row">
          <td class="total-discount-cell"><b>Total after discount</b></td>
          <td class="total-discount-cell-right">
            <!-- // (
              uncomment
            //   Number(getTotal('onetime')) +
            //   Number(calculateTax()) -
            //   Number(total_wise_discounted_value_onetime)
            // )?.toFixed(2) -->
            <b
              >{{
                $store.getters.defaultSetting?.currency?.symbol +
                quoteSummaryFromRules?.oneTime?.totalAfterDiscount?.toFixed(2)
              }}
              {{
                setTemplateToSummary(
                  "onetime",
                  "total",
                  $store.getters.defaultSetting?.currency?.symbol +
                    (
                      Number(getTotal("onetime")) +
                      Number(calculateTax()) -
                      Number(total_wise_discounted_value_onetime)
                    )?.toFixed(2)
                )
              }}
            </b>
            <!-- <b>{{ $store.getters.defaultSetting?.currency?.symbol + total_wise_discounted_value }}</b> -->
          </td>
        </tr>
        <div
          style="
            width: 100%;
            border: 1px solid rgb(216 216 216 / 43%);
            margin: 16px 0;
          "
        ></div>
        <!-- <tr class="total-discount-row">
          <td class="total-discount-cell">You'll save</td>
          <td class="total-discount-cell-right">
            {{
              $store.getters.defaultSetting?.currency?.symbol +
              (
                calculateSubTotal(pricing_table_data) -
                (getTotal() - total_wise_discounted_value)
              )?.toFixed(2)
            }}
          </td>
        </tr> -->
      </table>
    </div>
    <div
      v-if="showRecurringSummary"
      class="total-discount recurring-total"
      style="margin-top: 16px"
    >
      <table class="total-discount-table">
        <tr class="total-discount-row">
          <td class="total-discount-cell">
            <b>Recurring Charges</b>
          </td>
          <td class="total-discount-cell-right">{{}}</td>
        </tr>
        <tr class="total-discount-row">
          <td class="total-discount-cell">Sub total</td>
          <td class="total-discount-cell-right">
            {{
              $store.getters.defaultSetting?.currency?.symbol +
              // calculateSubTotal(pricing_table_data, 'recurring')
              Number(quoteSummaryFromRules?.recurring?.subTotal)?.toFixed(2)
            }}
            <!-- {{
              setTemplateToSummary(
                "recurring",
                "subtotal",
                $store.getters.defaultSetting?.currency?.symbol +
                  calculateSubTotal(pricing_table_data, "recurring")
              )
            }} -->
          </td>
        </tr>
        <!-- <tr
          v-if="item_wise_discount_recurring"
          class="total-discount-row"
        >
          <td class="total-discount-cell">Item wise discount</td>
          <td class="total-discount-cell-right">
            <hlx-input
              v-model:value="item_discount_recurring.discount.value"
              :pre-val="item_discount_recurring.discount.value"
              :single-border="true"
              type="number"
              class="number-input"
            />
            <hlx-select
              v-model="item_discount_recurring.discount.type"
              placeholder-value="Type"
              :single-border="true"
              :clearable="true"
              :pre-value="item_discount_recurring.discount.type"
              :options="discount_type_options"
              @selected-value="
                changeDiscountType($event, item_discount_recurring)
              "
            ></hlx-select>
            <i
              class="icon-times-regular"
              style="font-size: 18px"
              @click="removeItemWiseDiscount('recurring')"
            ></i>
          </td>
        </tr> -->
        <tr v-if="item_wise_discount_recurring" class="total-discount-row">
          <td class="total-discount-cell">Amount</td>
          <td class="total-discount-cell-right">
            {{
              $store.getters.defaultSetting?.currency?.symbol +
              quoteSummaryFromRules?.recurring?.amount?.toFixed(2)
            }}
            {{
              setTemplateToSummary(
                "recurring",
                "amount",
                $store.getters.defaultSetting?.currency?.symbol +
                  calculateTotalAmount(pricing_table_data, "recurring")
              )
            }}
          </td>
        </tr>
        <!-- <tr
          v-if="!item_wise_discount_recurring"
          class="total-discount-row"
        >
          <td
            class="total-discount-cell"
            style="cursor: pointer"
            @click="giveItemWiseDiscount('recurring')"
          >
            <i
              class="icon-tag-regular"
              style="color: #54bd95; font-size: 18px; cursor: pointer"
            ></i>
            Give item wise discount
          </td>
          <td class="total-discount-cell-right">{{}}</td>
        </tr> -->
        <tr class="total-discount-row">
          <td class="total-discount-cell">
            <!-- ${user_settings.tax_type} -->
            <b>{{ `Tax` }}</b>
          </td>
          <td
            v-if="quoteSummaryFromRules?.recurring?.appliedTax?.length == 0"
            class="total-discount-cell-right"
          >
            <!-- {{
              $store.getters.defaultSetting?.currency?.symbol + calculateTax()
            }} -->
            Included
          </td>
        </tr>
        <tr
          v-for="tax in quoteSummaryFromRules?.recurring?.appliedTax"
          :key="tax"
          class="total-discount-row"
        >
          <!-- ${user_settings.tax_type} -->
          <td class="total-discount-cell">
            <span>{{ tax?.taxName + " (" + tax?.percentage + "%)" }}</span>
          </td>
          <td class="total-discount-cell-right">
            {{ $store.getters.defaultSetting?.currency?.symbol + tax?.value }}
          </td>
        </tr>
        <tr
          v-for="(item, index) in total_wise_discount_array_recurring"
          :key="index"
          class="total-discount-row"
        >
          <td class="total-discount-cell">
            <hlx-input
              v-model:value="item.name"
              :pre-val="item?.name"
              type="text"
              :single-border="true"
              @at-input="triggerPricingRules('rate')"
            />
          </td>
          <td class="total-discount-cell-right">
            <div class="discount-div">
              <hlx-input
                v-model:value="item.value"
                :pre-val="item.value"
                :single-border="true"
                type="number"
                class="number-input"
                :min-limit="0"
                :max-limit="100"
                @at-input="triggerPricingRules('rate')"
              />
            </div>
            <hlx-select
              v-model="item.type"
              placeholder-value="Type"
              :pre-value="item.type"
              :single-border="true"
              :prop-value="'value'"
              :label="'value'"
              :clearable="true"
              :options="discount_type_options"
              @selected-value="changeDiscountType($event, item)"
            ></hlx-select>
            <i
              class="icon-times-regular"
              style="font-size: 18px"
              @click="removeTotalWiseDiscount(index, 'recurring')"
            ></i>
          </td>
        </tr>
        <!-- <tr
          v-for="(item, index) in additional_charges_array"
          :key="index"
          class="total-discount-row"
        >
          <td class="total-discount-cell">
            <hlx-input
              v-model:value="item?.name"
              :pre-val="item?.name"
              type="text"
              :single-border="true"
            />
          </td>
          <td class="total-discount-cell-right">
            <hlx-input
              v-model:value="item.discount"
              :pre-val="item.discount"
              :single-border="true"
              type="number"
              class="number-input"
            />
            <hlx-select
              v-model="item.discount_type"
              placeholder-value="Type"
              :pre-value="item.discount_type"
              :single-border="true"
              :clearable="true"
              :options="discount_type_options"
              @selected-value="changeDiscountType($event, item)"
            ></hlx-select>
            <i
              class="icon-times-regular"
              style="font-size: 18px"
              @click="removeAdditionalCharges(index)"
            ></i>
          </td>
        </tr> -->
        <!-- <tr class="total-discount-row">
          <td class="total-discount-cell">Tax</td>
          <td class="total-discount-cell-right">{{ tax_percentage + '%' }}</td>
        </tr>
        <tr class="total-discount-row">
          <td class="total-discount-cell">After tax</td>
          <td class="total-discount-cell-right">
            {{ $store.getters.defaultSetting?.currency?.symbol + after_tax?.toFixed(2) }}
          </td>
        </tr> -->

        <tr v-if="!total_wise_discount" class="total-discount-row">
          <td
            class="total-discount-cell"
            style="cursor: pointer"
            @click="giveTotalWiseDiscount('recurring')"
          >
            <i
              class="icon-plus-square-regular"
              style="color: #54bd95; font-size: 18px; cursor: pointer"
            ></i>
            Give discount on total
          </td>
          <td class="total-discount-cell-right">{{}}</td>
        </tr>
        <!-- <tr
          v-if="!additional_charges"
          class="total-discount-row"
        >
          <td
            class="total-discount-cell"
            style="cursor: pointer"
            @click="giveAdditionalCharges"
          >
            <i
              class="icon-plus-square-regular"
              style="color: #54bd95; font-size: 18px; cursor: pointer"
            ></i>
            Add additional charges
          </td>
          <td class="total-discount-cell-right">
            {{}}
          </td>
        </tr> -->

        <tr class="total-discount-row">
          <td class="total-discount-cell"><b>Total after discount</b></td>
          <td class="total-discount-cell-right">
            <b>
              {{
                $store.getters.defaultSetting?.currency?.symbol +
                quoteSummaryFromRules?.recurring?.totalAfterDiscount?.toFixed(2)
              }}
              <!-- // (
              //   Number(getTotal('recurring')) +
              //   Number(calculateTax()) -
              //   Number(total_wise_discounted_value_recurring)
              // )?.toFixed(2) -->
              {{
                setTemplateToSummary(
                  "recurring",
                  "total",
                  $store.getters.defaultSetting?.currency?.symbol +
                    (
                      Number(getTotal("recurring")) +
                      Number(calculateTax()) -
                      Number(total_wise_discounted_value_recurring)
                    )?.toFixed(2)
                )
              }}
            </b>
            <!-- <b>{{ $store.getters.defaultSetting?.currency?.symbol + total_wise_discounted_value }}</b> -->
          </td>
        </tr>
        <div
          style="
            width: 100%;
            border: 1px solid rgb(216 216 216 / 43%);
            margin: 16px 0;
          "
        ></div>
        <!-- <tr class="total-discount-row">
          <td class="total-discount-cell">You'll save</td>
          <td class="total-discount-cell-right">
            {{
              $store.getters.defaultSetting?.currency?.symbol +
              (
                calculateSubTotal(pricing_table_data) -
                (getTotal() - total_wise_discounted_value)
              )?.toFixed(2)
            }}
          </td>
        </tr> -->
      </table>
    </div>
    <div
      v-if="showOnetimeSummary || showRecurringSummary"
      class="total-discount"
    >
      <table class="total-discount-table">
        <!-- <tr class="total-discount-row">
          <td class="total-discount-cell">Total</td>
          <td class="total-discount-cell-right">
            {{  }}
          </td>
        </tr> -->
        <!-- <tr class="total-discount-row">
          <td class="total-discount-cell">Sub total</td>
          <td class="total-discount-cell-right">
            {{ $store.getters.defaultSetting?.currency?.symbol + calculateSubTotal(pricing_table_data) }}
          </td>
        </tr> -->
        <!-- <tr
          v-if="item_wise_discount"
          class="total-discount-row"
        >
          <td class="total-discount-cell">Item wise discount</td>
          <td class="total-discount-cell-right">
            <hlx-input
              v-model:value="item_discount.discount"
              :pre-val="item_discount.discount"
              :single-border="true"
              type="number"
              class="number-input"
            />
            <hlx-select
              v-model="item_discount.discount_type"
              placeholder-value="Type"
              :single-border="true"
              :clearable="true"
              :pre-value="item_discount.discount_type"
              :options="discount_type_options"
              @selected-value="changeDiscountType($event, item_discount)"
            ></hlx-select>
            <i
              class="icon-times-regular"
              style="font-size: 18px"
              @click="removeItemWiseDiscount"
            ></i>
          </td>
        </tr>
        <tr
          v-if="item_wise_discount"
          class="total-discount-row"
        >
          <td class="total-discount-cell">Amount</td>
          <td class="total-discount-cell-right">
            {{ $store.getters.defaultSetting?.currency?.symbol + calculateTotalAmount(pricing_table_data) }}
          </td>
        </tr> -->
        <!-- <tr class="total-discount-row">
          <td class="total-discount-cell">
            <b>{{ `Tax (${user_settings.tax_type})` }}</b>
          </td>
          <td class="total-discount-cell-right">
            {{ $store.getters.defaultSetting?.currency?.symbol + calculateTax() }}
          </td>
        </tr> -->
        <!-- <tr
          v-for="(item, index) in total_wise_discount_array"
          :key="index"
          class="total-discount-row"
        >
          <td class="total-discount-cell">
            <hlx-input
              v-model:value="item?.name"
              :pre-val="item?.name"
              type="text"
              :single-border="true"
            />
          </td>
          <td class="total-discount-cell-right">
            <hlx-input
              v-model:value="item.discount"
              :pre-val="item.discount"
              :single-border="true"
              type="number"
              class="number-input"
            />
            <hlx-select
              v-model="item.discount_type"
              placeholder-value="Type"
              :pre-value="item.discount_type"
              :single-border="true"
              :clearable="true"
              :options="discount_type_options"
              @selected-value="changeDiscountType($event, item)"
            ></hlx-select>
            <i
              class="icon-times-regular"
              style="font-size: 18px"
              @click="removeTotalWiseDiscount(index)"
            ></i>
          </td>
        </tr>
        <tr
          v-for="(item, index) in additional_charges_array"
          :key="index"
          class="total-discount-row"
        >
          <td class="total-discount-cell">
            <hlx-input
              v-model:value="item?.name"
              :pre-val="item?.name"
              type="text"
              :single-border="true"
            />
          </td>
          <td class="total-discount-cell-right">
            <hlx-input
              v-model:value="item.discount"
              :pre-val="item.discount"
              :single-border="true"
              type="number"
              class="number-input"
            />
            <hlx-select
              v-model="item.discount_type"
              placeholder-value="Type"
              :pre-value="item.discount_type"
              :single-border="true"
              :clearable="true"
              :options="discount_type_options"
              @selected-value="changeDiscountType($event, item)"
            ></hlx-select>
            <i
              class="icon-times-regular"
              style="font-size: 18px"
              @click="removeAdditionalCharges(index)"
            ></i>
          </td>
        </tr> -->
        <!-- <tr class="total-discount-row">
          <td class="total-discount-cell">Tax</td>
          <td class="total-discount-cell-right">{{ tax_percentage + '%' }}</td>
        </tr>
        <tr class="total-discount-row">
          <td class="total-discount-cell">After tax</td>
          <td class="total-discount-cell-right">
            {{ $store.getters.defaultSetting?.currency?.symbol + after_tax?.toFixed(2) }}
          </td>
        </tr> -->
        <!-- <tr
          v-if="!item_wise_discount"
          class="total-discount-row"
        >
          <td
            class="total-discount-cell"
            style="cursor: pointer"
            @click="giveItemWiseDiscount"
          >
            <i
              class="icon-tag-regular"
              style="color: #54bd95; font-size: 18px; cursor: pointer"
            ></i>
            Give item wise discount
          </td>
          <td class="total-discount-cell-right">{{}}</td>
        </tr>
        <tr
          v-if="!total_wise_discount"
          class="total-discount-row"
        >
          <td
            class="total-discount-cell"
            style="cursor: pointer"
            @click="giveTotalWiseDiscount"
          >
            <i
              class="icon-plus-square-regular"
              style="color: #54bd95; font-size: 18px; cursor: pointer"
            ></i>
            Give discount on total
          </td>
          <td class="total-discount-cell-right">{{}}</td>
        </tr> -->
        <!-- <tr
          v-if="!additional_charges"
          class="total-discount-row"
        >
          <td
            class="total-discount-cell"
            style="cursor: pointer"
            @click="giveAdditionalCharges"
          >
            <i
              class="icon-plus-square-regular"
              style="color: #54bd95; font-size: 18px; cursor: pointer"
            ></i>
            Add additional charges
          </td>
          <td class="total-discount-cell-right">
            {{}}
          </td>
        </tr> -->
        <!-- <div
          style="
            width: 100%;
            border: 1px solid rgb(216 216 216 / 43%);
            margin: 16px 0;
          "
        ></div> -->
        <tr class="total-discount-row">
          <td class="total-discount-cell"><span>Total</span></td>
          <td class="total-discount-cell-right">
            <!-- // (
            //   Number(getTotal('recurring')) +
            //   Number(calculateTax()) -
            //   Number(total_wise_discounted_value_recurring) +
            //   (Number(getTotal('onetime')) +
            //     Number(calculateTax()) -
            //     Number(total_wise_discounted_value_onetime))
            // )?.toFixed(2) -->
            <span
              >{{
                $store.getters.defaultSetting?.currency?.symbol +
                quoteSummaryFromRules?.overAllTotal?.toFixed(2)
              }}
              {{
                setTemplateToSummary(
                  "grandtotal",
                  "total",
                  $store.getters.defaultSetting?.currency?.symbol +
                    (
                      Number(getTotal("recurring")) +
                      Number(calculateTax()) -
                      Number(total_wise_discounted_value_recurring) +
                      (Number(getTotal("onetime")) +
                        Number(calculateTax()) -
                        Number(total_wise_discounted_value_onetime))
                    )?.toFixed(2)
                )
              }}
            </span>
            <!-- <b>{{ $store.getters.defaultSetting?.currency?.symbol + total_wise_discounted_value }}</b> -->
          </td>
        </tr>
        <!-- <tr class="total-discount-row">
          <td class="total-discount-cell card-price-discount"><b>Overall Total (after tax)</b> <i class="icon-info-circle-regular"></i>
          <div class="pricing-details-table" style="left: 110% !important;">
            <h5 style="margin-bottom: 10px">Applied taxes</h5>
            <div v-for="item in quoteSummaryFromRules.appliedTax" :key="item" class="price-item">
              <span>{{ item?.taxName + ' (' + item?.percent + '%)'}}</span>
              <span class="price" style="font-weight: 100;">
                {{
                  $store?.getters?.getDefaultSettings?.currency?.symbol + item?.value
                }}
                </span
              >
            </div>
          </div>
          </td>
          <td class="total-discount-cell-right">
             // (
            //   Number(getTotal('recurring')) +
            //   Number(calculateTax()) -
            //   Number(total_wise_discounted_value_recurring) +
            //   (Number(getTotal('onetime')) +
            //     Number(calculateTax()) -
            //     Number(total_wise_discounted_value_onetime))
            // )?.toFixed(2) 
            <b
              >{{
                $store.getters.defaultSetting?.currency?.symbol +
                Number(quoteSummaryFromRules?.overAllTotalAfterTax)?.toFixed(2)
              }}
              {{
                setTemplateToSummary(
                  'grandtotal',
                  'total',
                  $store.getters.defaultSetting?.currency?.symbol +
                    (
                      Number(getTotal('recurring')) +
                      Number(calculateTax()) -
                      Number(total_wise_discounted_value_recurring) +
                      (Number(getTotal('onetime')) +
                        Number(calculateTax()) -
                        Number(total_wise_discounted_value_onetime))
                    )?.toFixed(2)
                )
              }}
            </b>
             <b>{{ $store.getters.defaultSetting?.currency?.symbol + total_wise_discounted_value }}</b> 
          </td>
        </tr> -->
        <tr class="total-discount-row">
          <td class="total-discount-cell"><span>Total Contract Value</span></td>
          <td class="total-discount-cell-right">
            <!-- // (
            //   Number(getTotal('recurring')) +
            //   Number(calculateTax()) -
            //   Number(total_wise_discounted_value_recurring) +
            //   (Number(getTotal('onetime')) +
            //     Number(calculateTax()) -
            //     Number(total_wise_discounted_value_onetime))
            // )?.toFixed(2) -->
            <span>
              {{
                $store.getters.defaultSetting?.currency?.symbol +
                quoteSummaryFromRules?.totalContractValue?.toFixed(2)
              }}
              {{
                setTemplateToSummary(
                  "grandtotal",
                  "total",
                  $store.getters.defaultSetting?.currency?.symbol +
                    (
                      Number(getTotal("recurring")) +
                      Number(calculateTax()) -
                      Number(total_wise_discounted_value_recurring) +
                      (Number(getTotal("onetime")) +
                        Number(calculateTax()) -
                        Number(total_wise_discounted_value_onetime))
                    )?.toFixed(2)
                )
              }}
            </span>
            <!-- <b>{{ $store.getters.defaultSetting?.currency?.symbol + total_wise_discounted_value }}</b> -->
          </td>
        </tr>
        <tr class="total-discount-row">
          <td class="total-discount-cell"><span>Margin</span></td>
          <td class="total-discount-cell-right">
            <!-- // (
            //   Number(getTotal('recurring')) +
            //   Number(calculateTax()) -
            //   Number(total_wise_discounted_value_recurring) +
            //   (Number(getTotal('onetime')) +
            //     Number(calculateTax()) -
            //     Number(total_wise_discounted_value_onetime))
            // )?.toFixed(2) -->
            <span>
              {{ quoteSummaryFromRules?.quoteMargin + "%" }}
              {{
                setTemplateToSummary(
                  "grandtotal",
                  "total",
                  $store.getters.defaultSetting?.currency?.symbol +
                    (
                      Number(getTotal("recurring")) +
                      Number(calculateTax()) -
                      Number(total_wise_discounted_value_recurring) +
                      (Number(getTotal("onetime")) +
                        Number(calculateTax()) -
                        Number(total_wise_discounted_value_onetime))
                    )?.toFixed(2)
                )
              }}
            </span>
            <!-- <b>{{ $store.getters.defaultSetting?.currency?.symbol + total_wise_discounted_value }}</b> -->
          </td>
        </tr>
        <tr class="total-discount-row">
          <td class="total-discount-cell">You'll save</td>
          <td class="total-discount-cell-right">
            <!-- // (
            //   Number(total_wise_discounted_value_onetime) +
            //   Number(total_wise_discounted_value_recurring)
            // )?.toFixed(2) -->
            {{
              $store.getters.defaultSetting?.currency?.symbol +
              quoteSummaryFromRules?.amountSaved?.toFixed(2)
            }}
            {{
              setTemplateToSummary(
                "grandtotal",
                "savings",
                $store.getters.defaultSetting?.currency?.symbol +
                  (
                    Number(total_wise_discounted_value_onetime) +
                    Number(total_wise_discounted_value_recurring)
                  )?.toFixed(2)
              )
            }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { DMNAxiosInstance } from "@/config/axiosConfig";
export default {
  components: {},
  props: {
    module: {
      type: String,
      default: "Quote",
    },
    shippingOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    checkboxShipping: {
      type: Boolean,
      default: false,
    },
    taxData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    editSummaryData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    editSummaryDataFromRules: {
      type: Array,
      default: () => {
        return [];
      },
    },
    editData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: [
    "add-new-offer",
    "summary",
    "total-summary",
    "summary-from-rules",
    "reconfigure",
  ],
  data() {
    return {
      multiselectComponrtKey: 0,
      editNoteModal: false,
      currentNoteData: "",

      currentNoteId: "",
      noteModal: false,
      customItemBillingOptions: [
        {
          name: "One time",
          value: "One time",
        },
        {
          name: "Monthly",
          value: "Monthly",
        },
        {
          name: "Annually",
          value: "Annually",
        },
      ],
      additionalChargesArray: [],
      quoteSummaryFromRules: {
        showOneTimeSummary: false,
        showRecurringSummary: false,
        oneTime: {
          subTotal: 0,
          tax: 0,
          discount: [],
          total: 0,
          item_wise_discount_onetime: false,
        },
        recurring: {
          subTotal: 0,
          tax: 0,
          discount: [],
          total: 0,
          item_wise_discount_recurring: false,
        },
        tax: this.taxData,
        additionalCharges: this.additionalCharges,
        overAllTotal: 0,
        amountSaved: 0,
      },
      componentKey: 1,
      desc: {},
      user_settings: {
        tax_type: "GST",
        currency_format: this.$store.getters.defaultSetting?.currency?.symbol,
        number_format: "us",
      },
      item_wise_discount_recurring: false,
      item_wise_discount_onetime: false,
      total_wise_discount: false,
      additional_charges: false,
      discount_thead: {
        checked: false,
        id: 4,
        disabled: false,
        prop: "discount",
        label: "Discount",
        sortable: false,
        resizable: false,
        hide: false,
        width: 120,
      },
      pricing_table_theads: [
        {
          checked: false,
          id: 1,
          disabled: false,
          prop: "product_offering_name",
          label: "Item",
          sortable: false,
          resizable: false,
          hide: false,
          width: null,
        },
        {
          checked: false,
          id: 2,
          disabled: false,
          prop: "unitOfMeasure",
          label: "UOM",
          sortable: false,
          resizable: false,
          hide: false,
          width: 70,
        },
        {
          checked: false,
          id: 2,
          disabled: false,
          prop: "rate",
          label: "Unit price",
          sortable: false,
          resizable: false,
          hide: false,
          width: 150,
        },
        {
          checked: false,
          id: 3,
          disabled: false,
          prop: "quantity",
          label: "Quantity",
          sortable: false,
          resizable: false,
          width: 80,
          hide: false,
        },
        {
          checked: false,
          id: 3,
          disabled: false,
          prop: "billing_period",
          label: "Billing",
          sortable: false,
          resizable: false,
          width: 120,
          hide: false,
        },
        {
          checked: false,
          id: 3,
          disabled: false,
          prop: "commitment_length",
          label: "Duration",
          sortable: false,
          resizable: false,
          width: 180,
          hide: false,
        },
        {
          checked: false,
          id: 3,
          disabled: false,
          prop: "amount",
          label: "Amount",
          sortable: false,
          resizable: false,
          width: 120,
          hide: false,
        },
        {
          checked: false,
          id: 5,
          disabled: false,
          prop: "line_total",
          label: "Line total",
          sortable: false,
          resizable: false,
          width: 120,
          hide: false,
        },
      ],
      pricing_table_theads_rearrange_backup: [],
      pricing_table_theads_rearrange: [
        {
          checked: false,
          id: 2,
          disabled: false,
          prop: "unitOfMeasure",
          label: "UOM",
          sortable: false,
          resizable: false,
          hide: false,
          width: 100,
        },
        {
          checked: false,
          id: 2,
          disabled: false,
          prop: "rate",
          label: "Unit price",
          sortable: false,
          resizable: false,
          hide: false,
          width: 150,
        },
        {
          checked: false,
          id: 3,
          disabled: false,
          prop: "quantity",
          label: "Quantity",
          sortable: false,
          resizable: false,
          width: 100,
          hide: false,
        },
        {
          checked: false,
          id: 3,
          disabled: false,
          prop: "billing_period",
          label: "Billing",
          sortable: false,
          resizable: false,
          width: 100,
          hide: false,
        },
        {
          checked: false,
          id: 3,
          disabled: false,
          prop: "commitment_length",
          label: "Duration",
          sortable: false,
          resizable: false,
          width: 180,
          hide: false,
        },
        {
          checked: false,
          id: 3,
          disabled: false,
          prop: "amount",
          label: "Amount",
          sortable: false,
          resizable: false,
          width: 120,
          hide: false,
        },
        {
          checked: false,
          id: 3,
          disabled: false,
          prop: "discount",
          label: "Discount",
          sortable: false,
          resizable: false,
          width: 120,
          hide: false,
        },
      ],
      pricing_table_data: [],
      new_row: {
        product_offering_name: "Table lamp for study",
        unit_of_measure: "Each",
        rate: 800.09,
        quantity: 1,
        discount: {
          value: 0,
          type: "%",
        },
        amount: 0,
        line_total: null,
        section: false,
        tax: 0,
        custom: false,
        duration: 0,
        billing_period: "One time",
        intangible: false,
      },
      new_item: {
        product_offering_name: "",
        unit_of_measure: "Each",
        rate: 1,
        quantity: 1,
        discount: {
          value: 0,
          type: "%",
        },
        amount: 0,
        tax: 0,
        line_total: null,
        section: false,
        custom: true,
        duration: 0,
        billing_period: "One time",
        intangible: true,
      },
      discount_type_options: [
        {
          name: "%",
          value: "%",
        },
        {
          name: this.$store.getters.defaultSetting?.currency?.symbol,
          value: this.$store.getters.defaultSetting?.currency?.symbol,
        },
      ],
      billing_type_options: [
        {
          name: "Monthly",
          value: "Monthly",
        },
        {
          name: "Yearly",
          value: "Yearly",
        },
      ],
      before_discount: 0,
      total_discount: {
        discount: {
          value: 0,
          type: "%",
        },
      },
      item_discount_recurring: {
        discount: {
          value: 0,
          type: "%",
        },
      },
      item_discount_onetime: {
        discount: {
          value: 0,
          type: "%",
        },
      },
      after_discount: 0,
      tax_percentage: 0,
      add_new_section: false,
      add_new_address: false,
      section_name: "",
      total_wise_discount_array_onetime: [],
      total_wise_discount_array_recurring: [],
      total_wise_discounted_value_onetime: 0,
      total_wise_discounted_value_recurring: 0,
      additional_charges_value: 0,
      additional_charges_array: [],
      drag_start: 0,
      drag_leave: 0,
      dragging: false,
      rearrange_columns_modal: false,
      rearrange_first_column_name: "Item",
      rearrange_last_column_name: "Line total",
      qouteSummaryTemplate: {
        recurring: {
          subtotal: "0.00",
          itemdiscount: {
            discount: {
              value: 0,
              type: "%",
            },
          },
          amount: "0.00",
          discount: [
            {
              name: "",
              discount: {
                value: 0,
                type: "%",
              },
            },
          ],
          total: "0.00",
        },
        onetime: {
          subtotal: "0.00",
          itemdiscount: {
            discount: {
              value: 0,
              type: "%",
            },
          },
          amount: "0.00",
          discount: [
            {
              name: "",
              discount: {
                value: 0,
                type: "%",
              },
            },
          ],
          total: "0.00",
        },
        grandtotal: {
          total: "0.00",
          savings: "0.00",
        },
      },
      showRecurringSummary: false,
      showOnetimeSummary: false,
      unitOfMeasureData: [],
    };
  },
  watch: {
    taxData: {
      handler() {
        this.triggerPricingRules("rate");
      },
      immediate: true,
      deep: true,
    },
    checkboxShipping: {
      handler(val) {
        if (val) {
          const hasAddress = this.pricing_table_data.some(
            (item) => item.address === true
          );

          // If no object has address set to true, push an empty object
          if (!hasAddress) {
            this.addNewShipping();
          }
        }

        // Remove objects with "address": true if checkboxShipping is false
        if (!val) {
          this.pricing_table_data = this.pricing_table_data.filter(
            (item) => item.address !== true
          );
        }
      },
      immediate: true,
      deep: true,
    },
    // editSummaryData: { // by asif
    //   handler(val) {
    //     // console.log(val, 'vaal')
    //     let arr1 = [];
    //     val?.quoteTotalPrice[1]?.priceAlteration?.forEach((i) => {
    //       arr1.push({
    //         name: i?.name,
    //         priceType: i?.name.replace("charges", ""),
    //         recurringChargePeriodLength: 1,
    //         discount:
    //           i.price.amount !== null ? i.price.amount : i.price.percentage,
    //         discount_type:
    //           i.price.amount !== null
    //             ? this.$store.getters.defaultSetting?.currency?.symbol
    //             : "%",
    //       });
    //     });
    //     let arr2 = [];
    //     val?.quoteTotalPrice[0]?.priceAlteration?.forEach((i) => {
    //       arr2.push({
    //         name: i?.name,
    //         priceType: i?.name.replace("charges", ""),
    //         recurringChargePeriodLength: 0,
    //         discount:
    //           i.price.amount !== null ? i.price.amount : i.price.percentage,
    //         discount_type:
    //           i.price.amount !== null
    //             ? this.$store.getters.defaultSetting?.currency?.symbol
    //             : "%",
    //       });
    //     });
    //     console.log(arr1, arr2, "arr12");
    //     // setTimeout(() => {
    //     this.total_wise_discount_array_onetime = JSON.parse(
    //       JSON.stringify(arr2)
    //     );
    //     this.total_wise_discount_array_recurring = JSON.parse(
    //       JSON.stringify(arr1)
    //     );
    //     // }, 2000);
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    editSummaryData: {
      handler(val) {
        let arr1 = [];
        let arr2 = [];

        // Dynamically check if amountDue is present and adjust the path accordingly
        const quoteTotalPrice =
          val?.amountDue?.quoteTotalPrice || val?.quoteTotalPrice;

        // Proceed if quoteTotalPrice is defined
        if (quoteTotalPrice?.length > 1) {
          // Handle recurring charges
          quoteTotalPrice[1]?.priceAlteration?.forEach((i) => {
            arr1.push({
              name: i?.name,
              priceType: i?.name.replace("charges", ""),
              recurringChargePeriodLength: 1,
              discount:
                i.price.amount !== null ? i.price.amount : i.price.percentage,
              discount_type:
                i.price.amount !== null
                  ? this.$store.getters.defaultSetting?.currency?.symbol
                  : "%",
            });
          });
        }

        if (quoteTotalPrice?.length > 0) {
          // Handle one-time charges
          quoteTotalPrice[0]?.priceAlteration?.forEach((i) => {
            arr2.push({
              name: i?.name,
              priceType: i?.name.replace("charges", ""),
              recurringChargePeriodLength: 0,
              discount:
                i.price.amount !== null ? i.price.amount : i.price.percentage,
              discount_type:
                i.price.amount !== null
                  ? this.$store.getters.defaultSetting?.currency?.symbol
                  : "%",
            });
          });
        }

        console.log(arr1, arr2, "arr12");

        this.total_wise_discount_array_onetime = JSON.parse(
          JSON.stringify(arr2)
        );
        this.total_wise_discount_array_recurring = JSON.parse(
          JSON.stringify(arr1)
        );
      },
      immediate: true,
      deep: true,
    },

    editSummaryDataFromRules: {
      handler(val) {
        console.log("new edit log", val);
        this.quoteSummaryFromRules = JSON.parse(JSON.stringify(val));
        this.total_wise_discount_array_onetime =
          this.quoteSummaryFromRules?.oneTime?.discount;
        this.total_wise_discount_array_recurring =
          this.quoteSummaryFromRules?.recurring?.discount;
      },
      immediate: true,
      deep: true,
    },
    editData: {
      handler(val) {
        if (val.length !== 0) {
          this.pricing_table_data = this.transformPricingData(val);
          this.pricing_table_data_duplicate = this.transformPricingData(val);
          console.log(this.pricing_table_data_duplicate, "vaal edit");
          const x = this.pricing_table_data.flatMap((item) =>
            item.additionalCharges?.map((charge) => ({
              name: charge.name,
              price: charge.price,
            }))
          );
          this.additionalChargesArray = JSON.parse(
            JSON.stringify(
              x.filter((item) => item !== null && item !== undefined)
            )
          );
          this.triggerPricingRules("rate");
        }
      },
      immediate: true,
      deep: true,
    },
    data: {
      handler(val) {
        console.log("new card", JSON.parse(JSON.stringify(val)));
        // if (this.editData.length === 0) {
        this.pricing_table_data = this.transformPricingData(val);
        console.log("this.pricing_table_data", this.pricing_table_data);
        // Extract additionalCharges from each object
        const x = this.pricing_table_data.flatMap((item) =>
          item.additionalCharges?.map((charge) => ({
            name: charge.name,
            price: charge.price,
          }))
        );
        this.additionalChargesArray = JSON.parse(
          JSON.stringify(
            x.filter((item) => item !== null && item !== undefined)
          )
        );
        console.log("1.1.1g", this.additionalChargesArray);
        // }
      },
      immediate: true,
      deep: true,
    },
    pricing_table_data: {
      async handler(val) {
        // console.log(val, 'vaal 2');
        // val.forEach((e) => {
        //   if (e.section === false) {
        //     e['amount'] = e.quantity * e.rate;
        //   }
        // });
        // let ruleResult = await this.triggerPricingRules()
        // console.log(val, 'new val ');
        // console.log(ruleResult.data.data.quote.quoteItem, 'new val');
        // // this.pricing_table_data = ruleResult.data.data.quote.quoteItem
        // let obj = {};
        // (obj.quantity = 1), (obj.discount = this.total_discount.discount);
        // obj.discount_type = this.total_discount.discount_type;
        // obj.rate = this.before_discount;
        // this.after_discount = this.calculateLineTotal(obj);
        // this.after_tax =
        //   Number(this.after_discount) +
        //   (this.tax_percentage / 100) * Number(this.after_discount);
        // let showRecurringSummary = val.filter((i) => {
        //   return i.intangible === true;
        // });
        // let showOnetimeSummary = val.filter((i) => {
        //   return i.intangible === false;
        // });
        // this.showRecurringSummary =
        //   showRecurringSummary.length > 0 ? true : false;
        // this.showOnetimeSummary = showOnetimeSummary.length > 0 ? true : false;
        console.log(
          "edit log mapToTMF64",
          this.showRecurringSummary,
          this.showOnetimeSummary,
          this.mapToTMF648(val)
        );

        this.$emit("summary", this.mapToTMF648(val));
      },
      immediate: true,
      deep: true,
    },
    item_discount_recurring: {
      handler(val) {
        val;
        // if (val.discount.value !== '') {
        //   if (val.discount.type === '%') {
        //     this.pricing_table_data.filter((e) => {
        //       if (e.section === false && e.intangible === true) {
        //         e.discount.value = val.discount.value;
        //         e.discount.type = val.discount.type;
        //       }
        //     });
        //   } else {
        //     let count = this.pricing_table_data.filter((e) => {
        //       return e.section === false && e.intangible === true;
        //     });
        //     console.log(this.pricing_table_data, 'pooja');

        //     this.pricing_table_data.filter((e) => {
        //       if (e.section === false && e.intangible === true) {
        //         if (val.discount.value === 0) {
        //           e.discount.value = val.discount.value;
        //         } else {
        //           e.discount.value = val.discount.value / count.length;
        //         }
        //         e.discount.type = val.discount.type;
        //       }
        //     });
        //   }
        // }
        // setTimeout(() => {
        //   this.total_wise_discounted_value_recurring = Number(
        //     this.calculateCumulativeDiscount(
        //       this.total_wise_discount_array_recurring,
        //       this.calculateTotalAmount(this.pricing_table_data, 'recurring')
        //     )
        //   );
        // }, 1000);
        // this.setTemplateToSummary('recurring', 'itemdiscount', val);
        this.triggerPricingRules("rate");
      },
      immediate: true,
      deep: true,
    },
    item_discount_onetime: {
      handler(val) {
        val;
        // if (val.discount.value !== '') {
        //   if (val.discount.type === '%') {
        //     this.pricing_table_data.filter((e) => {
        //       if (e.section === false && e.intangible === false) {
        //         e.discount.value = val.discount.value;
        //         e.discount.type = val.discount.type;
        //       }
        //     });
        //   } else {
        //     let count = this.pricing_table_data.filter((e) => {
        //       return e.section === false && e.intangible === false;
        //     });
        //     // console.log(val, count);
        //     this.pricing_table_data.filter((e) => {
        //       if (e.section === false && e.intangible === false) {
        //         if (val.discount.value === 0) {
        //           e.discount.value = val.discount.value;
        //         } else {
        //           e.discount.value = val.discount.value / count.length;
        //         }
        //         e.discount.type = val.discount.type;
        //       }
        //     });
        //   }
        // }
        // setTimeout(() => {
        //   this.total_wise_discounted_value_onetime = Number(
        //     this.calculateCumulativeDiscount(
        //       this.total_wise_discount_array_onetime,
        //       this.calculateTotalAmount(this.pricing_table_data, 'onetime')
        //     )
        //   );
        // }, 1000);
        // this.setTemplateToSummary('onetime', 'itemdiscount', val);
        this.triggerPricingRules("rate");
      },
      immediate: true,
      deep: true,
    },
    total_discount: {
      handler(val) {
        let obj = {};
        (obj.quantity = 1), (obj.discount = val.discount);
        obj.discount_type = val.discount_type;
        obj.rate = this.before_discount;
        this.after_discount = this.calculateLineTotal(obj);
        this.after_tax =
          Number(this.after_discount) +
          (this.tax_percentage / 100) * Number(this.after_discount);
        this.triggerPricingRules("rate");
      },
      immediate: true,
      deep: true,
    },
    total_wise_discount_array_onetime: {
      handler(val) {
        val;
        // this.total_wise_discounted_value_onetime = Number(
        //   this.calculateCumulativeDiscount(
        //     val,
        //     this.calculateTotalAmount(this.pricing_table_data, 'onetime')
        //   )
        // );
        // this.$emit('summary', this.mapToTMF648(this.pricing_table_data));
        // this.setTemplateToSummary('onetime', 'discount', val);
        // this.triggerPricingRules('rate')
      },
      immediate: true,
      deep: true,
    },
    total_wise_discount_array_recurring: {
      handler(val) {
        // this.total_wise_discounted_value_recurring = Number(
        //   this.calculateCumulativeDiscount(
        //     val,
        //     this.calculateTotalAmount(this.pricing_table_data, 'recurring')
        //   )
        // );
        // this.$emit('summary', this.mapToTMF648(this.pricing_table_data));
        // this.setTemplateToSummary('recurring', 'discount', val);
        val;
        // this.triggerPricingRules('rate')
      },
      immediate: true,
      deep: true,
    },
    // additional_charges_array: {
    //   handler(val) {
    //     this.additional_charges_value = Number(
    //       this.calculateCumulativeDiscount(
    //         val,
    //         this.calculateTotalAmount(this.pricing_table_data)
    //       )
    //     );
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },
  async mounted() {
    console.log("mounted");
    this.giveItemWiseDiscount("onetime");
    this.giveItemWiseDiscount("recurring");
    this.triggerPricingRules("rate");
    // const unitOfmesures = await DMNAxiosInstance.get(
    //   `mapRef/collection/unitOfMeasure`
    // );
    // console.log(unitOfmesures, "unitOfmeasure");
    this.unitOfMeasureData =  await this.getReferanceData('unitOfMeasure');
  },
  methods: {
    updateAdditionalCharges() {
      this.triggerPricingRules("rate");
    },
    showNoteModal(i) {
      this.componentKey += 1;
      this.noteModal = true;
      this.editNoteModal = false;
      this.currentNoteId = i;
      this.currentNoteData = this.pricing_table_data[this.currentNoteId].notes;
      console.log(i, "noteId");
    },
    deleteNote(i) {
      this.currentNoteId = i;
      this.pricing_table_data[this.currentNoteId].notes = "";
      console.log(i, "noteId");
    },
    focusOutSection(item) {
      console.log("focusOutSection...",item);
      
      item.sectionView = false;
      if (item.product_offering_name === "") {
        item.product_offering_name = "New Section";
      }
    },
    sectionTrue(index,item) {index,item
      console.log("sectionTrue...", event, item)
      this.pricing_table_data.forEach(itm=>{
        itm.sectionView = false;
      })
      item.sectionView = true;

      setTimeout(() => {
        this.$nextTick(() => {
          // Construct the element ID based on the provided index
      let elementId = "new-sectionForPricingTable" + index;

      // Get the section element by ID
      let sectionElement = document.getElementById(elementId);
      // Check if the section exists
      if (sectionElement) {
          // Use querySelector to find the input inside this section
          let inputElement = sectionElement.querySelector('input[type="text"]');
          // Check if the input exists and focus on it
          if (inputElement) {
              inputElement.focus();
          } else {
              console.error('Input element not found in section:', elementId);
          }
      } else {
          console.error('Section not found with ID:', elementId);
      }
        })
      }, 400);
    },
    editNote(i) {
      this.noteModal = true;
      this.editNoteModal = true;
      this.componentKey += 1;
      this.currentNoteId = i;
      this.currentNoteData = this.pricing_table_data[this.currentNoteId].notes;
      console.log(i, "noteId");
    },
    closeNoteModal() {
      this.noteModal = false;
    },
    saveCurrentNote() {
      this.pricing_table_data[this.currentNoteId].notes = this.currentNoteData;
      this.noteModal = false;
    },
    changeCustomBillingPeriod($event, index) {
      console.log("changeCustomBillingPeriod", $event, index);
      this.pricing_table_data[index].billing_period = $event.value;
      if ($event.value === "One time") {
        console.log(
          this.pricing_table_data[index].intangible,
          "changeCustomBillingPeriod"
        );

        this.pricing_table_data[index].intangible = false;
      } else {
        console.log(
          JSON.parse(JSON.stringify(this.pricing_table_data[index])),
          "changeCustomBillingPeriod"
        );

        this.pricing_table_data[index].intangible = true;
        console.log(
          JSON.parse(JSON.stringify(this.pricing_table_data[index])),
          "changeCustomBillingPeriod"
        );
      }
      this.triggerPricingRules("rate");
    },
    reconfigureItem(item, index) {
      console.log(item, index, "ide");
      const obj = {};
      obj.data = item;
      obj.index = index;
      this.$emit("reconfigure", obj);
      this.addNewProduct();
    },
    formatPriceValuesV1(e, item, prop) {
      // Ensure number is a valid number or convert it to one
      
      e = parseFloat(e);
      // Round the number to 2 decimal places
      const roundedNumber = Math.round(e * 100) / 100;
      
      // Convert the rounded number to a string with 2 decimal places
      const formattedNumber = Number(roundedNumber?.toFixed(2));
      item[prop] = String(formattedNumber.toFixed(2));
      console.log(e, item, prop, formattedNumber,"e, item, prop");
    },
    async triggerPricingRules(prop) {
      const quotePayload = {
        quote: {
          quoteItem: JSON.parse(JSON.stringify(this.pricing_table_data)),
          summary: {
            showOneTimeSummary: false,
            showRecurringSummary: false,
            priceable: true,
            oneTime: {
              subTotal: 0,
              tax: 0,
              discount: this.total_wise_discount_array_onetime,
              item_wise_discount_onetime: this.item_wise_discount_onetime,
            },
            recurring: {
              subTotal: 0,
              tax: 0,
              discount: this.total_wise_discount_array_recurring,
              item_wise_discount_recurring: this.item_wise_discount_recurring,
            },
            tax: this.taxData,
            additionalCharges: this.additionalChargesArray,
            overAllTotal: 0,
            amountSaved: 0,
          },
        },
      };
      let data = {};
      switch (prop) {
        case "rate":
          data = await DMNAxiosInstance.post(
            `/rule_engine/rule/execute/66827e3ac0f9d2676a34c929`,
            { payload: quotePayload }
          );
          break;
        case "quantity":
          data = await DMNAxiosInstance.post(
            `/rule_engine/rule/execute/66827e3ac0f9d2676a34c929`,
            { payload: quotePayload }
          );
          break;
        case "amount":
          data = await DMNAxiosInstance.post(
            `/rule_engine/rule/execute/66828f4ed5f0c403af9e5b00
 `,
            { payload: quotePayload }
          );
          break;
      }
      // let ruleResult = await this.triggerPricingRules()
      // console.log(val, 'new val ');
      // console.log(ruleResult.data.data.quote.quoteItem, 'new val');
      console.log("update rules", data?.data?.data?.quote);
      this.showOnetimeSummary =
        data?.data?.data?.quote?.summary?.showOneTimeSummary;
      this.showRecurringSummary =
        data?.data?.data?.quote?.summary?.showRecurringSummary;
      this.quoteSummaryFromRules = data?.data?.data?.quote?.summary;
      this.pricing_table_data = data?.data?.data?.quote?.quoteItem;
    },
    setTemplateToSummary(key1, key2, value) {
      if (key1 === "recurring") {
        this.qouteSummaryTemplate["recurring"][key2] = value;
      } else if (key1 === "onetime") {
        this.qouteSummaryTemplate["onetime"][key2] = value;
      } else if (key1 === "grandtotal") {
        this.qouteSummaryTemplate["grandtotal"][key2] = value;
      }
      console.log(this.qouteSummaryTemplate, "quotesummary template");
      // Function to convert price to desired format

      // Output the converted data
      let obj = {
        quoteTotalPrice: [],
        quotePrice: {},
      };
      let data = this.transformToTMF648(this.qouteSummaryTemplate);
      obj.quoteTotalPrice.push(data.onetime);
      obj.quoteTotalPrice.push(data.recurring);
      obj.quoteTotalPrice.push(data.totalContractValue);
      obj.quotePrice = data.grandtotal;
      // console.log("One-Time/recurring  Price:", obj);
      this.$emit("total-summary", obj);
    },
    transformToTMF648(summaryData) {
      summaryData;
      const tmfData = {};

      // Transform recurring charges
      tmfData.recurring = {
        name: "Recurring charges",
        priceType: "Recurring",
        // recurringChargePeriod: 'Month',
        // recurringChargePeriodLength: 1,
        price: {
          dutyFreeAmount: {
            unit: this.$store.getters.defaultSetting?.currency?.unit,
            value: this.quoteSummaryFromRules?.recurring?.subTotal,
          },
          taxIncludedAmount: {
            unit: this.$store.getters.defaultSetting?.currency?.unit,
            value: this.quoteSummaryFromRules?.recurring?.amount,
          },
        },
        priceAlteration: this.quoteSummaryFromRules.recurring.discount.map(
          (discount) => {
            console.log("suuuu", discount);
            return {
              name: discount?.name,
              priceType: "recurring",
              // recurringChargePeriod: 'Month',
              // recurringChargePeriodLength: 1,
              price: {
                amount: discount.type === "%" ? null : discount.value,
                percentage: discount.type === "%" ? discount.type : null,
                symbol:
                  discount.type !== "%"
                    ? this.$store.getters.defaultSetting?.currency?.symbol
                    : null,
                value: discount.value,
              },
            };
          }
        ),
        quoteTotal: {
          unit: this.$store.getters.defaultSetting?.currency?.unit,
          value: this.quoteSummaryFromRules?.recurring?.totalAfterDiscount,
        },
      };

      // Transform TCV charges
      tmfData.totalContractValue = {
        name: "Total contract value",
        "@type": "totalContractValue",
        price: {
          unit: this.$store.getters.defaultSetting?.currency?.unit,
          value: this.quoteSummaryFromRules?.totalContractValue,
        },
      };

      // Transform one-time charges
      tmfData.onetime = {
        name: "One-time charges",
        priceType: "Onetime",
        // recurringChargePeriod: 'Month',
        // recurringChargePeriodLength: 0,
        price: {
          dutyFreeAmount: {
            unit: this.$store.getters.defaultSetting?.currency?.unit,
            value: this.quoteSummaryFromRules?.oneTime?.subTotal,
          },
          taxIncludedAmount: {
            unit: this.$store.getters.defaultSetting?.currency?.unit,
            value: this.quoteSummaryFromRules?.oneTime?.amount,
          },
        },
        priceAlteration: this.quoteSummaryFromRules.oneTime.discount.map(
          (discount) => {
            return {
              name: discount?.name,
              priceType: "one-time",
              // recurringChargePeriod: 'Month',
              // recurringChargePeriodLength: 0,
              price: {
                amount: discount.type === "%" ? null : discount.value,
                percentage: discount.type === "%" ? discount.type : null,
                symbol:
                  discount.type !== "%"
                    ? this.$store.getters.defaultSetting?.currency?.symbol
                    : null,
                value: discount.value,
              },
            };
          }
        ),
        quoteTotal: {
          unit: this.$store.getters.defaultSetting?.currency?.unit,
          value: this.quoteSummaryFromRules?.oneTime?.totalAfterDiscount,
        },
      };

      // Transform grand total
      tmfData.grandtotal = {
        price: {
          dutyFreeAmount: {
            unit: this.$store.getters.defaultSetting?.currency?.unit,
            value: this.quoteSummaryFromRules?.overAllTotal,
          },
          taxIncludedAmount: {
            unit: this.$store.getters.defaultSetting?.currency?.unit,
            value: this.quoteSummaryFromRules?.overAllTotal,
          },
        },
        quoteTotal: {
          unit: this.$store.getters.defaultSetting?.currency?.unit,
          value: this.quoteSummaryFromRules?.overAllTotal,
        },
        savings: {
          unit: this.$store.getters.defaultSetting?.currency?.unit,
          value: this.quoteSummaryFromRules?.amountSaved,
        },
        totalContractValue: {
          unit: this.$store.getters.defaultSetting?.currency?.unit,
          value: this.quoteSummaryFromRules?.totalContractValue,
        },
        additionalCharges: this.additionalChargesArray,
        summary: this.quoteSummaryFromRules,
      };

      return tmfData;
    },
    mapToTMF648(data) {
      console.log("mapped data", data);
      let idCounter = 1; // Initialize the ID counter

      const mappedData = data?.map((item) => {
        console.log(item, "irtemmm");

        // Create the tmfObject without id initially
        const tmfObject = {
          offerId: item.offerId,
          offerType: item.offerType,
          bundleIndex: item?.bundleIndex,
          section: item?.section,
          address: item?.address,
          custom: item?.custom,
          notes: item?.notes,
          product_offering_name: item?.product_offering_name,
          description: item.description,
          unitOfMeasure: item.unitOfMeasure,
          additionalCharges: item.additionalCharges ?? [],
          "@type": "QuoteItem",
          productSpecification: {
            ...item.productSpecification,
            "@type": "ProductSpecification",
          },
          productOffering: {
            ...item.productOffering,
            "@type": "ProductOffering",
          },
          productCharacteristic: item.productCharacteristic,
          prodSpecCharValueUse: item.prodSpecCharValueUse,
          prodSpecCharValue: item.prodSpecCharValue,
          discountResponse: item.discountResponse,
          supplierUnitCost: item.supplierUnitCost,
          unitMsrp: item.unitMsrp,
          skuid: item.skuid,
          rebate: item.rebate,
          action: "new",
          quantity: item.quantity,
          duration: item.duration,
          billing_period_options: item.billing_period_options,
          billing_period: item.billing_period,
          priceType: item.priceType,
          discount: JSON.parse(
            JSON.stringify(item?.discount ?? { value: 0, type: "0%" })
          ),
          state: "",
          product: {
            "@type": "product",
            productSpecification: {
              ...item.productSpecification,
              "@type": "ProductSpecification",
            },
            productOffering: {
              ...item.productOffering,
              "@type": "ProductOffering",
            },
            productCharacteristic: item.productCharacteristic,
          },
          quoteItemPrice: [
            {
              description: item.description,
              name: "",
              priceType: item.intangible === true ? "Recurring" : "One time",
              recurringChargePeriodLength:
                item.intangible === true ? item.duration : 0,
              recurringChargePeriod: item.billing_period,
              price: {
                taxRate: 0,
                dutyFreeAmount: {
                  unit: this.$store.getters.defaultSetting?.currency?.unit,
                  value: item.rate,
                },
                taxIncludedAmount: {
                  unit: this.$store.getters.defaultSetting?.currency?.unit,
                  value: item.lineTotal,
                },
              },
            },
          ],
        };

        // Add id only if section is false
        if (!item.section) {
          tmfObject.id = idCounter.toString();
          idCounter++; // Increment the ID counter for the next item
        }

        // Check if discount is present
        if (item?.discount?.value > 0) {
          tmfObject.quoteItemPrice[0].priceAlteration = [
            {
              applicationDuration: 0,
              description: "",
              name: `${item.discount.name}`,
              priceType: item.intangible === true ? "Recurring" : "One time",
              recurringChargePeriodLength: item.intangible === true ? 1 : 0,
              priority: 1,
              recurringChargePeriod: item.billing_period,
              price: {
                taxRate: item.tax,
                percentage:
                  item.discount.type === "%"
                    ? item.discount.value + item.discount.type
                    : this.$store.getters.defaultSetting?.currency?.symbol +
                      item.discount.value,
                dutyFreeAmount: {
                  unit: this.$store.getters.defaultSetting?.currency?.unit,
                  value: item.rate,
                },
                taxIncludedAmount: {
                  unit: this.$store.getters.defaultSetting?.currency?.unit,
                  value: item.lineTotal,
                },
              },
            },
          ];
        }

        return tmfObject;
      });

      // const quoteTotalPrice = [{
      //   price: {
      //     "dutyFreeAmount": {
      //       "unit": "GBP",
      //       "value": beforeTotal
      //     }},
      //     "taxIncludedAmount": {
      //       "unit": "GBP",
      //       "value": tax
      //     },
      //     "priceAlteration": discount,
      //     "quoteTotal": {
      //       "unit": "GBP",
      //       "value": afterTotal
      //     }
      // }]
      const obj = {};
      // obj.quoteTotalPrice = quoteTotalPrice
      obj.quoteItem = mappedData;
      return obj;
    },
    transformPricingData(data2) {
      console.log("customidata", data2);

      let transformedData = [];
      let data = JSON.parse(JSON.stringify(data2));

      data.forEach((originalData2) => {
        const originalData = JSON.parse(JSON.stringify(originalData2));

        let newOptions = originalData?.billing_period_options || [
          { name: "Monthly", value: "Monthly" },
        ];

        if (!("product_offering_name" in originalData)) {
          transformedData.push({
            offerId: originalData.offerId,
            bundleIndex: originalData.bundleIndex,
            productSpecification: originalData.productSpecification,
            productOffering: originalData.productOffering,
            productCharacteristic: originalData.productCharacteristic,
            prodSpecCharValueUse: originalData.prodSpecCharValueUse,
            prodSpecCharValue: originalData.prodSpecCharValue,
            product_offering_name: originalData?.name,
            discountResponse: originalData.discountResponse,
            unit_of_measure: "Each",
            rate: String(
              parseFloat(
                `${originalData?.price?.whole}.${originalData?.price?.fraction}`
              )?.toFixed(2)
            ),
            quantity: originalData.quantity,
            max_quantity: originalData.max_quantity,
            discount: originalData?.discount || { value: 0, type: "%" },
            amount: 0,
            line_total: null,
            unitOfMeasure: originalData2.unitOfMeasure,
            additionalCharges: originalData2.additionalCharges ?? [],
            section: false,
            address: false,
            tax: 0.0,
            skuid: originalData.skuid,
            supplierUnitCost: originalData.supplierUnitCost || 0,
            unitMsrp: originalData.unitMsrp || 0,
            rebate: originalData.rebate || 0,
            custom: originalData.custom,
            offerType: originalData.offerType,
            notes: "",
            description: originalData.description,
            duration: originalData.duration,
            priceType: originalData.priceType,
            billing_period:
              originalData.offering_type === "intangible"
                ? originalData?.billing_period
                : "One time",
            billing_period_options: originalData?.billing_period_options || [
              { name: "Monthly", value: "Monthly" },
              { name: "Annually", value: "Annually" },
            ],
            intangible: originalData.offering_type === "intangible",
          });
        } else {
          transformedData.push({
            ...originalData,
            amount: 0,
            billing_period:
              originalData.intangible === true
                ? originalData?.billing_period || "Monthly"
                : "One time",
            billing_period_options: newOptions,
            priceType: originalData.priceType,
            discount: originalData?.discount || { value: 0, type: "%" },
            discountResponse: originalData.discountResponse,
            supplierUnitCost: originalData.supplierUnitCost,
            unitMsrp: originalData.unitMsrp,
            rebate: originalData.rebate,
          });
        }
      });
      transformedData.forEach((i, index) => {
        if (data2[index].discount !== undefined) {
          i.discount = JSON.parse(JSON.stringify(data2[index].discount));
        } else {
          i.discount = JSON.parse(JSON.stringify({ value: 0, type: "%" }));
        }
      });
      console.log("1.1.2", data2, data, transformedData);
      return transformedData;
    },

    saveArrangedColumns() {
      let first_obj = {
        checked: false,
        id: 1,
        disabled: false,
        prop: "product_offering_name",
        label: "Item",
        sortable: false,
        resizable: false,
        hide: false,
      };
      first_obj.label = this.rearrange_first_column_name;
      let last_obj = {
        checked: false,
        id: 5,
        disabled: false,
        prop: "line_total",
        label: "Line total",
        sortable: false,
        resizable: false,
        width: 120,
        hide: false,
      };
      last_obj.label = this.rearrange_last_column_name;
      let arr = [];
      console.log(
        first_obj,
        last_obj,
        this.rearrange_last_column_name,
        this.rearrange_first_column_name
      );
      arr.push(first_obj);
      let filtered_arr = this.pricing_table_theads_rearrange.filter(
        (i) => i.hide === false
      );
      arr = arr.concat(filtered_arr);
      arr.push(last_obj);
      this.pricing_table_theads = JSON.parse(JSON.stringify(arr));
      this.rearrange_columns_modal = false;
    },
    openRearrangeColumnModal() {
      this.rearrange_columns_modal = true;
      this.pricing_table_theads_rearrange_backup = JSON.parse(
        JSON.stringify(this.pricing_table_theads_rearrange)
      );
    },
    closeRearrangeColumnModal() {
      this.rearrange_columns_modal = false;
      this.rearrange_first_column_name = this.pricing_table_theads[0].label;
      this.rearrange_last_column_name =
        this.pricing_table_theads[this.pricing_table_theads.length - 1].label;
      let arr = this.pricing_table_theads.filter(
        (obj) =>
          obj.prop !== "product_offering_name" && obj.prop !== "line_total"
      );
      this.pricing_table_theads_rearrange = JSON.parse(JSON.stringify(arr));
    },
    romoveRow(i) {
      if (this.pricing_table_data[i].offerType === "Bundle") {
        this.pricing_table_data[i]?.prodSpecCharValue?.forEach((item) => {
          item;
          this.pricing_table_data.splice(i + 1, 1);
        });
        this.pricing_table_data.splice(i, 1);
      } else {
        this.pricing_table_data.splice(i, 1);
      }
      console.log("edit log", this.pricing_table_data);
      if (this.pricing_table_data !== undefined) {
        const oneTimeItems = this.pricing_table_data.filter((i) => {
          return i.intangible === false;
        });
        const recurringItems = this.pricing_table_data.filter((i) => {
          return i.intangible === true;
        });
        if (oneTimeItems.length === 0) {
          this.total_wise_discount_array_onetime = [];
        }
        if (recurringItems.length === 0) {
          this.total_wise_discount_array_recurring = [];
        }
      }
      this.triggerPricingRules("rate");
    },
    dragRowOver() {
      this.dragging = true;
    },
    hoverRow(e, i) {
      this.$nextTick(() => {
        const tr = e.currentTarget;
        const rect = tr.getBoundingClientRect();
        const top = rect.top;
        const height = tr.clientHeight; // Get the height of the tr element
        // console.log('Top value:', top);
        // console.log('Height:', height);
        // console.log(document.getElementById('hover-row-' + i));
        const { left } = document
          .getElementById("hover-row-" + i)
          .parentElement.parentElement.parentElement.getBoundingClientRect();

        if (document.getElementById("hover-" + i) !== null) {
          document.getElementById("hover-" + i).style.display = "flex";
          document.getElementById("hover-" + i).style.position = "fixed";
          document.getElementById("hover-" + i).style.left = left - 8 + "px";
          document.getElementById("hover-" + i).style.top =
            top + (height - 25) / 2 + "px";
        }
      });
    },
    unHoverRow(i) {
      this.$nextTick(() => {
        if (document.getElementById("hover-" + i)) {
          document.getElementById("hover-" + i).style.display = "none";
        }
      });
    },
    addNewProduct() {
      // let row = JSON.parse(JSON.stringify(this.new_row));
      // row.id = Math.random();
      // this.pricing_table_data.push(row);
      console.log("edit data", this.pricing_table_data);
      this.$emit("add-new-offer", this.pricing_table_data);
      this.$emit("summary-from-rules", this.quoteSummaryFromRules);
    },
    swapObjects(index1, index2) {
      // Destructure the objects at the given indices
      const [obj1, obj2] = [
        this.pricing_table_data[index1],
        this.pricing_table_data[index2],
      ];

      // Swap the objects
      this.pricing_table_data[index1] = obj2;
      this.pricing_table_data[index2] = obj1;
    },
    swapObjects2(index1, index2) {
      // Destructure the objects at the given indices
      const [obj1, obj2] = [
        this.pricing_table_theads_rearrange[index1],
        this.pricing_table_theads_rearrange[index2],
      ];

      // Swap the objects
      this.pricing_table_theads_rearrange[index1] = obj2;
      this.pricing_table_theads_rearrange[index2] = obj1;
    },
    dragRowStart(i) {
      this.drag_start = i;
    },
    dragRowLeave(i) {
      this.drag_leave = i;
    },
    dragRowEnd2() {
      this.dragging = false;
      this.swapObjects2(this.drag_start, this.drag_leave);
    },
    dragRowEnd() {
      this.dragging = false;
      this.swapObjects(this.drag_start, this.drag_leave);
    },
    textEditorValue(e) {
      console.log(e, "ganeshy");
      // this.desc[index] = e;
      // val["description"] = e;
      // console.log(this.pricing_table_data, "ganeshy");
    },
    calculateTax() {
      let totalTax = 0;
      this.pricing_table_data?.forEach((item) => {
        if (item.section === false) {
          // Calculate the total amount after discount
          const totalAmount =
            item.quantity * item.rate * (1 - item.discount / 100);

          // Calculate the tax amount based on the tax percentage
          const taxAmount = totalAmount * (item.tax / 100);

          // Add tax amount to the total tax
          totalTax += taxAmount;
        }
      });
      return totalTax?.toFixed(2); // rounding to 2 decimal places
    },
    getTotal(type) {
      return this.calculateTotalAmount(this.pricing_table_data, type);
    },
    calculateSubTotal(val, type) {
      let sub_total = 0;
      val.forEach((e) => {
        console.log("eoi", e);
        if (type !== undefined) {
          if (e.section === false && e.intangible === (type === "recurring")) {
            sub_total = sub_total + e.amount;
          }
        } else {
          if (e.section === false) {
            sub_total = sub_total + e.amount;
          }
        }
      });
      return sub_total?.toFixed(2);
    },
    calculateTotalAmount(val, type) {
      let total = 0;
      if (type !== undefined && type === "recurring") {
        val?.forEach((e) => {
          if (e.section === false && e.intangible === true) {
            total = total + Number(e.line_total);
          }
        });
      } else if (type !== undefined && type === "onetime") {
        val?.forEach((e) => {
          if (e.section === false && e.intangible === false) {
            total = total + Number(e.line_total);
          }
        });
      } else {
        val?.forEach((e) => {
          if (e.section === false) {
            total = total + Number(e.line_total);
          }
        });
      }
      return total?.toFixed(2);
    },
    removeItemWiseDiscount(type) {
      this["item_wise_discount_" + type] = false;
      if (
        this.item_wise_discount_onetime === false &&
        this.item_wise_discount_recurring === false
      ) {
        this.pricing_table_theads = this.pricing_table_theads.filter(
          (obj) => obj.prop !== "discount"
        );
        this.pricing_table_theads_rearrange =
          this.pricing_table_theads_rearrange.filter(
            (obj) => obj.prop !== "discount"
          );
      }
      // this.pricing_table_data.forEach((e) => {
      //   if (e.section === false && e.intangible === (type === "recurring")) {
      //     e.discount.value = 89870;
      //   }
      // });
      this["item_discount_" + type].discount.value = 0;
    },
    giveItemWiseDiscount(type) {
      this.removeItemWiseDiscount(type);
      if (
        this.item_wise_discount_onetime === false &&
        this.item_wise_discount_recurring === false
      ) {
        this.pricing_table_theads.splice(
          this.pricing_table_theads.length - 1,
          0,
          this.discount_thead
        );
        this.pricing_table_theads_rearrange.splice(
          this.pricing_table_theads_rearrange.length,
          0,
          this.discount_thead
        );
      }
      this["item_wise_discount_" + type] = true;
    },
    giveTotalWiseDiscount(type) {
      this["total_wise_discount_array_" + type].push({
        name: "Discount",
        value: 0,
        type: "%",
      });
    },
    removeTotalWiseDiscount(i, type) {
      this["total_wise_discount_array_" + type].splice(i, 1);
        // Output the converted data
            this.triggerPricingRules("rate");
    },
    giveAdditionalCharges() {
      this.additional_charges_array.push({
        name: "Additional charges",
        discount: {
          value: 0,
          type: "%",
        },
      });
    },
    removeAdditionalCharges(i) {
      this.additional_charges_array.splice(i, 1);
    },
    calculateCumulativeDiscount(discounts, totalAmount) {
      discounts, totalAmount;
      // let totalDiscount = 0;
      // let amount = Number(totalAmount) + Number(this.calculateTax());
      // discounts?.forEach((discount) => {
      //   if (discount.discount_type === '%') {
      //     totalDiscount += (discount.discount / 100) * amount;
      //   } else {
      //     totalDiscount += discount.discount;
      //   }
      // });
      // console.log(totalDiscount, 'totalD');
      // return totalDiscount?.toFixed(2);
    },
    formatQuantity(e, item, prop) {
      if (e !== "") {
        const num = Math.floor(e);
        num;
        item[prop] = Number(e?.toFixed(0));
        // console.log(e, item, prop, item[prop], num);
      }
    },
    formatRate(e, item, prop) {
      console.log(e, item, prop, "e, item, prop");
      // Ensure number is a valid number or convert it to one

      e = parseFloat(e);
      // Round the number to 2 decimal places
      const roundedNumber = Math.round(e * 100) / 100;

      // Convert the rounded number to a string with 2 decimal places
      const formattedNumber = Number(roundedNumber?.toFixed(2));
      item[prop] = formattedNumber;
    },
    formatAmount() {
      // Ensure number is a valid number or convert it to one
      // e = parseFloat(e);
      // // Round the number to 2 decimal places
      // const roundedNumber = Math.round(e * 100) / 100;
      // // Convert the rounded number to a string with 2 decimal places
      // const formattedNumber = Number(roundedNumber?.toFixed(2));
      // item[prop] = formattedNumber;
      // item['rate'] = formattedNumber / item['quantity'];
    },
    changeDiscountType($event, val) {
      console.log("ddd", $event, val);
      if (val.discount !== undefined) {
        console.log($event?.value,"val.discount",val?.discount)
        val.discount_type = $event?.value;
      } else {
        val.type = $event?.value;
      }
      this.triggerPricingRules("rate");
    },
    changeQuantity($event, val) {
      val.quantity = $event;
    },
    calculateLineTotal(item) {
      let discountAmount = 0;

      if (item.discount.type === "%") {
        discountAmount = (item.rate * item.quantity * item.discount) / 100;
      } else if (
        item.discount.type ===
        this.$store.getters.defaultSetting?.currency?.symbol
      ) {
        discountAmount = item.discount.value;
      }

      const totalBeforeDiscount = item.rate * item.quantity;
      const lineTotal = totalBeforeDiscount - discountAmount;
      // console.log(lineTotal?.toFixed(2));
      if (lineTotal?.toFixed(2) !== "NaN") {
        item.line_total = lineTotal?.toFixed(2);
        return lineTotal?.toFixed(2);
      } else {
        return 0;
      }
    },
    calculateBeforeDiscount(items) {
      let total = 0;
      items.forEach((item) => {
        // console.log(Number(this.calculateLineTotal(item)), 'cc');
        if (this.calculateLineTotal(item) !== "NaN") {
          total += Number(this.calculateLineTotal(item));
        }
      });
      this.before_discount = total?.toFixed(2);
      return total?.toFixed(2);
    },
    addNewSection() {

      let index = this.pricing_table_data?.length
      console.log("elementId...index",index);

      let obj = {};
      obj.section = true;
      obj.sectionView = true;
      obj.address = false;
      obj.custom = false;
      obj.product_offering_name = "";
      this.pricing_table_data.push(JSON.parse(JSON.stringify(obj)));
      this.section_name = "";
      this.add_new_section = false;

      setTimeout(() => {
        this.$nextTick(() => {
        // document.getElementById("new-section").focus();
        let elementId = "new-sectionForPricingTable" + index;
        console.log("elementId...123",elementId)
        // Get the section element by ID
    let sectionElement = document.getElementById(elementId);

// Check if the section exists
if (sectionElement) {
    // Use querySelector to find the input inside this section
    let inputElement = sectionElement.querySelector('input[type="text"]');

    // Check if the input exists and focus on it
    if (inputElement) {
        inputElement.focus();
    } else {
        console.error('Input element not found in section:', elementId);
    }
} else {
    console.error('Section not found with ID:', elementId);
}

      });
      }, 300);

      console.log(this.pricing_table_data, "new table section");
    },
    addNewShipping() {
      let obj = {};
      obj.address = true;
      obj.section = false;
      obj.custom = false;
      obj.product_offering_name = "";
      this.pricing_table_data.push(JSON.parse(JSON.stringify(obj)));
      this.multiselectComponrtKey += 1;
      this.add_new_address = false;
    },
    addNewItem() {
      const item = {
        product_offering_name: "New item",
        unit_of_measure: "Each",
        unitOfMeasure: "Each",
        rate: "1.00",
        quantity: 1,
        discount: {
          value: 0,
          type: "%",
        },
        amount: 0,
        tax: 0,
        line_total: null,
        lineTotal: 0,
        section: false,
        address: false,
        custom: true,
        notes: "",
        description: "",
        intangible: false,
        duration: 1,
        billing_period: "One time",
      };
      this.pricing_table_data.push(JSON.parse(JSON.stringify(item)));
      // this.$nextTick(() => {
      //   document.getElementById('new-section').focus();
      // });
      this.triggerPricingRules("rate");
    },
    sectionEnter() {
      let obj = {};
      obj.section = true;
      obj.address = false;
      obj.product_offering_name = this.section_name;
      this.pricing_table_data.push(JSON.parse(JSON.stringify(obj)));
      this.section_name = "";
      this.add_new_section = false;

      console.log(this.pricing_table_data, "new table section");
    },
    handleSelectedShippingAddress(event, val) {
      console.log(val, "handleSelectedShippingAddress", event);
      // let obj = {};
      // obj.address = true;
      // obj.section = false;
      // obj.custom = false;
      // obj.product_offering_name = val.description;
      // this.pricing_table_data.push(JSON.parse(JSON.stringify(obj)));
      // this.multiselectComponrtKey += 1;
      // this.add_new_address = false;
    },
  },
};
</script>

<style lang="scss">
.last-index-pricinTable {
  background-color: rgba(217, 217, 217, 0.4);
}

.none-display-pricingTable {
  display: none !important;
}

.pricing-table-header {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "QuickSand";
  .title {
    font-weight: bold;
  }
}

.pricing-table-container {
  .fixed-column- {
    height: 50px !important;
  }

  .number-input {
    text-align: right;
    height: 36px;
    width: 70px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
  }

  .number-input::-webkit-inner-spin-button,
  .number-input::-webkit-outer-spin-button {
    margin-left: 5px; /* Adjust as needed */
  }
}
.add-new-section-button {
  font-weight: bold;
  color: var(--hlx-color-primary);
  cursor: pointer;
}

.total-discount {
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
}

.total-discount-row {
  display: flex;
  height: 40px;
}
.total-discount-cell {
  display: inline-flex;
  width: 250px;
  gap: 8px;
  align-items: center;
}
.total-discount-cell-right {
  display: inline-flex;
  width: 200px;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
}

.section-name-input {
  width: 100%;
  height: 40px;
  border: none;
  font-weight: bold;
  font-family: "OpenSans";
}

#myTable {
  border-collapse: separate;
  border-spacing: 0 10px; /* Adjust the second value to control the gap between rows */
}

.gap-row {
  margin-bottom: 16px; /* Adjust the value to control the gap */
}

.discount-div {
  width: 80px !important;
}
.note-parent {
  display: flex;
  width: 250px;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.block-ellipsis {
  max-width: 100%;
  height: 50px;
  display: -webkit-inline-box;
  // margin: 0 auto;
  font-size: 14px;
  word-break: break-all;
  line-height: 1.15;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pricing-details-table {
  display: none;
  text-align: left;
  position: absolute;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  bottom: -30%;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10101;
  width: 200px;
  left: 130%;
}
.card-price-discount {
  position: relative;
}
.card-price-discount:hover {
  .pricing-details-table {
    display: block;
  }
}
.pricing-details-table {
  display: none;
  text-align: left;
  position: absolute;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  bottom: -170%;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10101;
  width: 200px;
  left: 150%;
}
</style>
