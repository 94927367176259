<template>
  <div v-show="impersonite == true"><banner @exit="exitView()" /></div>
  <div class="role">
    <!-- <section class="left-sidebar">
      <sidebar
        :nav-items="nav_items"
        :lang="'en-US'"
        collapse
        @nav-to="navTo"
        @go-to-home="goToHome"
      ></sidebar>
    </section> -->
    <section class="right-content">
      <div class="breadcrumb">
        <hlx-breadcrumb :items="crumbs" />
      </div>
      <div class="title-container">
        <p class="header"><b>Roles and permissions</b></p>
      </div>
      <div class="main-container">
        <div class="form-input-wrapper">
          <p class="input-header">Role name</p>
          <div class="form-input">
            <hlx-input
              v-model:value="role"
              :label-animation="false"
              type="text"
            />
          </div>
        </div>
        <div class="form-input-wrapper">
          <p class="input-header">Description</p>
          <div class="form-input">
            <hlx-input
              :label-animation="false"
              label-value="Description"
              type="textarea"
              :clearable="true"
            />
          </div>
        </div>
        <div class="form-input-wrapper">
          <div class="holder">
            <p class="input-header">Set as default ?</p>
            <span class="sub-header">Assign to new users by default</span>
          </div>

          <div class="form-input" style="width: 0vw; margin-left: -7%">
            <hlx-input type="switch" checked="true" />
          </div>
        </div>

        <div class="form-input-wrapper">
          <div class="holder">
            <p class="input-header">Permissions</p>
            <span class="sub-header">Choose permissions for role</span>
          </div>
          <div class="column-contains">
            <div class="form-input-new">
              <p>DMN-Rule Builder</p>
              <div class="container">
                <span> View</span>
                <span> Edit</span>
              </div>
            </div>
            <div class="container-2">
              <p class="title">Rules</p>
              <span> <hlx-input :options="ruleEdit" :type="'checkbox'" /></span>
              <span> <hlx-input :options="ruleView" :type="'checkbox'" /></span>
            </div>
            <div class="container-2">
              <p class="title">Schema</p>
              <span>
                <hlx-input :options="schemaEdit" :type="'checkbox'"
              /></span>
              <span>
                <hlx-input :options="schemaView" :type="'checkbox'"
              /></span>
            </div>
            <div class="container-2">
              <p class="title">Test data</p>
              <span> <hlx-input :options="testEdit" :type="'checkbox'" /></span>
              <span> <hlx-input :options="testView" :type="'checkbox'" /></span>
            </div>
            <div class="container-2">
              <p class="title">User settings</p>
              <span>
                <hlx-input :options="settingsEdit" :type="'checkbox'"
              /></span>
              <span>
                <hlx-input :options="settingsView" :type="'checkbox'"
              /></span>
            </div>
            <div class="footer-container">
              <hlx-button class="secondary sm" @click="$router.push(`/roles`)"
                >Cancel</hlx-button
              >
              <hlx-button class="primary sm" @click="addRole()">Add</hlx-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import sidebar from "../components/sidebarComponent.vue";
// import banner from "../components/bannerComponent.vue";
import axios from "axios";
import {
  // DMNAxiosInstance,
  MLTAxiosInstance,
  // UPCAxiosInstance,
} from "@/config/axiosConfig";
export default {
  name: "AddRoleView",
  // components: { sidebar, banner },
  data() {
    return {
      viewPermission: [],
      editPermission: [],
      role: "",
      impersonite: false,
      nav_items: [
        {
          name: "Home",
          label: "Home",
          icon: "icon-grid-web-7-regular",
          description_en: "Home",
          description_fn: "*Home",
        },
        {
          name: "Residential business market ",
          label: "About",
          icon: "icon-code-regular",
          description_en: "Residential business market ",
          description_fr: "*Residential business market ",
        },
        {
          name: "Schema",
          label: "Schema",
          icon: "icon-desktop-code-regular",
          description_en: "Small business markets",
          description_fr: "*Small business markets",
        },
        {
          name: "Database",
          label: "Database",
          icon: "icon-database-regular",
          description_en: "Small business markets",
          description_fr: "*Small business markets",
        },
        {
          name: "help",
          label: "Setting",
          icon: "icon-question-circle-regular",
          description_en: "Setting",
          description_fr: "*Setting",
          separator: true,
        },
        {
          name: "setting",
          label: "settings",
          icon: "icon-settings-regular",
          description_en: "Setting",
          description_fr: "*Setting",
          active: true,
        },
        {
          name: "logout",
          label: "Logout",
          icon: "icon-log-out-regular",
          description_en: "Logout",
          description_fr: "*Logout",
        },
      ],
      ruleEdit: [{ name: "", checked: false, disabled: false }],
      ruleView: [{ name: "", checked: true, disabled: false }],
      schemaEdit: [{ name: "", checked: false, disabled: false }],
      schemaView: [{ name: "", checked: true, disabled: false }],
      testEdit: [{ name: "", checked: false, disabled: false }],
      testView: [{ name: "", checked: true, disabled: false }],
      settingsEdit: [{ name: "", checked: false, disabled: false }],
      settingsView: [{ name: "", checked: true, disabled: false }],

      crumbs: [{ label: "Settings" }, { label: "Roles and permissions" }],
      impersonitor: "",
      fet: process.env.VUE_APP_HOST_PROTOCOL + "://" + process.env.VUE_APP_HOST,
      token: this.$route.params.token,
      company: "",
    };
  },
  computed: {
    computedSidebar() {
      const sidebar = [
        {
          name: "Home",
          label: "Home",
          icon: "icon-grid-web-7-regular",
          description_en: "Home",
          description_fn: "*Home",
          active: true,
        },
        {
          name: "Business rule",
          label: "Business rule ",
          icon: "icon-code-regular",
          description_en: "Residential business market ",
          description_fr: "*Residential business market ",
        },
        {
          name: "Schema",
          label: "Schema",
          icon: "icon-desktop-code-regular",
          description_en: "Small business markets",
          description_fr: "*Small business markets",
        },
        {
          name: "Database",
          label: "Database",
          icon: "icon-database-regular",
          description_en: "Small business markets",
          description_fr: "*Small business markets",
        },
        {
          name: "Execute",
          label: "Execute",
          icon: "icon-file-settings-regular",
          description_en: "Small business markets",
          description_fr: "*Small business markets",
        },
        {
          name: "Audit",
          label: "Audit",
          icon: "icon-notebook-regular",
          description_en: "Audit log",
          description_fr: "*Audit log",
        },
        {
          name: "Help",
          label: "help",
          icon: "icon-question-circle-regular",
          description_en: "Setting",
          description_fr: "*Setting",
          separator: true,
        },
        {
          name: "setting",
          label: "settings",
          icon: "icon-settings-regular",
          description_en: "Setting",
          description_fr: "*Setting",
        },
        {
          name: "logout",
          label: "Logout",
          icon: "icon-log-out-regular",
          description_en: "Logout",
          description_fr: "*Logout",
        },
      ];
      const filteredSidebar = sidebar.filter(
        (item) => !this.viewPermission.includes(item.name)
      );

      return filteredSidebar;
    },
  },
  // beforeMount() {
  //   let token = {
  //     headers: {
  //       Authorization: "Bearer  " + this.token,
  //     },
  //   };

  //   axios
  //     .get(
  //       process.env.VUE_APP_HOST_PROTOCOL +
  //         "://" +
  //         process.env.VUE_APP_HOST +
  //         `/partymanagement/individual/auth`,
  //       token
  //     )
  //     .then((val) => {
  //       this.company = val.data.company;
  //       this.userId = val.data.company;
  //       axios
  //         .get(
  //           process.env.VUE_APP_HOST_PROTOCOL +
  //             "://" +
  //             process.env.VUE_APP_HOST +
  //             `/${val.data.userId}`,
  //           token
  //         )
  //         .then(async (access) => {
  //           let arr = access.data;

  //           if (arr.permission.User_settings.edit == false) {
  //             this.editPermission.push("setting");
  //           }
  //           if (arr.permission.User_settings.view == false) {
  //             this.viewPermission.push("setting");
  //           }
  //           if (arr.permission.Schema.edit == false) {
  //             this.editPermission.push("schema");
  //           }
  //           if (arr.permission.Schema.view == false) {
  //             this.viewPermission.push("Schema");
  //           }
  //           if (arr.permission.Test_data.view == false) {
  //             this.viewPermission.push("Database", "Execute");
  //           }
  //           if (arr.permission.Test_data.edit == false) {
  //             this.editPermission.push("testdata");
  //           }
  //           if (arr.permission.Rules.view == false) {
  //             this.viewPermission.push("Business rule");
  //           }
  //           if (arr.permission.Rules.edit == false) {
  //             this.editPermission.push("rules");
  //           }

  //           this.computedSidebar;
  //         });
  //     });
  // },
  async mounted() {
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");

    this.company = await val.data.company;

    const editToken = await MLTAxiosInstance.get(
      `/partyRole/${this.$route.params.id}`
    );
console.log(editToken,"editToken");

    // this.company = await val.data.company;

    // // const editToken =
    // await axios.get(
    //   this.fet + `/partyRole/${this.$route.params.id}`,
    //   token
    // );
  },
  methods: {
    async addRole() {
      let add = {
        role: this.role,
        permission: {
          Schema: {
            edit: this.schemaEdit[0].checked,
            view: this.schemaView[0].checked,
          },
          Rules: {
            edit: this.ruleEdit[0].checked,
            view: this.ruleView[0].checked,
          },
          Test_data: {
            edit: this.testEdit[0].checked,
            view: this.testView[0].checked,
          },
          User_settings: {
            edit: this.settingsEdit[0].checked,
            view: this.settingsView[0].checked,
          },
        },
      };
      let token = {
        headers: {
          Authorization: "Bearer  " + this.token,
        },
      };

      // const role =
      await axios.post(this.fet + `/partyRole/${this.company}`, add, token);
    },
    async exitView() {
      let update = {
        id: this.userId,
        impersonation: false,
        impersinater: "",
      };
      // const result =
      await axios.patch(this.fet + `/partymanagement/individual`, update);

      let token = {
        token: this.impersonitor,
      };
      axios
        .post(this.fet + `/partymanagement/individual/auth`, token)
        .then(() => {
          // let company = val.data.company;
          // if (company != '') {
          //   let c_name = null;
          //   if (company == 'Halleyx') {
          //     c_name = '';
          //   } else {
          //     c_name = company + '.';

          //   }
          //   // location.replace(window.location.protocol+"//"+`${c_name}`+window.location.hostname+':'+window.location.port +`/DMN/dashboard/${this.impersonitor}`)

          window.location.href =
            window.location.protocol +
            "//" +
            window.location.hostname +
            ":" +
            window.location.port +
            `/DMN/dashboard/${this.impersonitor}`;
          // }
        });
    },
  },
};
</script>

<style></style>
