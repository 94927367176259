<template>
  <div class="mapContainer">
    <span v-show="false" class="cornerDivForMap">
      <span class="div1">
        <span class="circle customer"></span>
        <span>Customer</span>
      </span>
      <span class="div2">
        <span class="circle no-customer"></span>
        <span>No customer</span>
      </span>
      <!-- <span class="div3">
        <span class="circle hover"></span>
        <span>At hover</span>
      </span> -->
    </span>
    <div ref="mapChart" class="mapStyle"></div>
  </div>
</template>


<script>
import * as echarts from 'echarts';
import { MLTAxiosInstance, UPCAxiosInstance } from "@/config/axiosConfig";

export default {
  name: 'CanadaMapChart',
  data() {
    return {
      country: 'canada', // Default country
      filterQuery: {
        type: "filter",
        module: "geojson",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1
        },
        isNeedToBeSort: true,
        limit: 10,
        filterQuery: {
          "country_name": { "$in": ["Canada"] }
        },
        searchQuery: "1"
      }
    };
  },
  async mounted() {
    console.log("Mounted hook: Starting process...");
    try {
      let organisationInfo = await this.fetchOrganisationInfo();
      console.log("Mounted hook: Organisation info fetched:", organisationInfo);
      
      let countryName = this.formatCountryName(organisationInfo);
      console.log("Mounted hook: Formatted country name:", countryName);

      this.country = countryName || this.country;
      console.log("Mounted hook: Country set to:", this.country);

      await this.geoget(); // Fetch and render map data
    } catch (error) {
      console.error('Error in mounted:', error);
    }
  },
  methods: {
    async fetchOrganisationInfo() {
      console.log("fetchOrganisationInfo: Fetching organisation info...");
      try {
        const response = await MLTAxiosInstance.get(
          `/partymanagement/organisation/${this.$cookies.get("user")}?company=${this.$cookies.get("company")}`
        );
        console.log("fetchOrganisationInfo: Organisation info received:", response.data);
        return response.data;
      } catch (error) {
        console.error('fetchOrganisationInfo: Error fetching organisation info:', error);
        throw error;
      }
    },
    formatCountryName(organisationInfo) {
      console.log("formatCountryName: Formatting country name from organisation info...");
      try {
        const country = organisationInfo.contactMedium[1]?.characteristic.country;
        console.log("formatCountryName: Raw country name:", country);
        return country ? country.replace(/\s+/g, '').toLowerCase() : 'canada';
      } catch (error) {
        console.error('formatCountryName: Error formatting country name:', error);
        return 'canada';
      }
    },
    async geoget() {
  console.log("geoget: Fetching GeoJSON data for country:", this.country);
  
  try {
    // Step 1: Prepare filter query
    this.filterQuery.filterQuery = { "country_name": { "$in": [this.country] } };
    console.log("geoget: Filter query updated:", this.filterQuery);
    
    // Step 2: Fetch GeoJSON data
    const response = await UPCAxiosInstance.post('util/filterData', this.filterQuery);
    const geoData1 = response.data.data[0];
    if (!geoData1) {
      throw new Error('GeoJSON data not found');
    }
    
    console.log("geoget: GeoJSON data received:", geoData1);

    // Step 3: Format feature names (add space between concatenated words)
    const addSpaceBetweenWords = name => name.replace(/([a-z])([A-Z])/g, '$1 $2');
    
    const geoData = {
      ...geoData1,
      features: geoData1.features.map(feature => ({
        ...feature,
        properties: {
          ...feature.properties,
          name: addSpaceBetweenWords(feature.properties.name)
        }
      }))
    };
    
    // Step 4: Fetch customers per region data
    const wen = await UPCAxiosInstance.get('BSSSuiteDashboard');
    const customersPerRegion = wen.data[0]?.customersPerRegion || [];
    
    console.log("Fetched customers per region:", customersPerRegion);

    // Step 5: Map customer data to GeoJSON features and create selectedStates
    const selectedStates = {};
    
    geoData.features.forEach(feature => {
      const stateName = feature.properties.name.replace(/\s+/g, '').toLowerCase();
      const customerData = customersPerRegion.find(customer => 
        customer.stateOrProvince.replace(/\s+/g, '').toLowerCase() === stateName
      );
      
      if (customerData) {
        // Append total customers to feature name
        feature.properties.name = `${feature.properties.name}: ${customerData.totalCustomer}`;
        selectedStates[feature.properties.name] = true; // Mark state as selected
      }
    });
    
    // Log the updated GeoJSON data
    console.log("Updated GeoJSON features:", geoData.features);
    console.log("Selected states:", selectedStates);

    // Step 6: Pass geoData and selectedStates to fetchCountryMap
    this.fetchCountryMap(geoData, selectedStates);

  } catch (error) {
    console.error('geoget: Error fetching GeoJSON data:', error);
  }
},
    fetchCountryMap(geoData,selectedStates) {
      console.log("fetchCountryMap: Registering and initializing map for country:", this.country);
      try {
        if (!this.country || !geoData) {
          console.error("fetchCountryMap: Country or GeoJSON data is missing. Aborting.");
          return;
        }

        echarts.registerMap(this.country, geoData);
        console.log("fetchCountryMap: Map registered for country:", this.country);

        this.initChart(selectedStates);
      } catch (error) {
        console.error('fetchCountryMap: Error registering or initializing the map:', error);
      }
    },
    initChart(selectedStates) {
  let chart = echarts.init(this.$refs.mapChart);

  selectedStates

  // Specify the selected states here; ensure names match GeoJSON data
  // const selectedStates = {
  //   'Ontario': true, // Example: set Ontario as selected and highlighted
  // };
  

  // Log the selected states for debugging
  console.log("Selected States:", selectedStates);

  let option = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}', // Shows the name of the region in the tooltip
    },
    series: [
      {
        type: 'map',
        map: this.country.toLowerCase(), // Use the input country as the map
        roam: false, // Disable map panning/zooming
        emphasis: {
          label: {
            show: false, // Don't show label on hover
          },
          itemStyle: {
            areaColor: '#54bd95', // Hover color
            borderWidth: 0, // No border on hover
          },
        },
        select: {
          label: {
            show: false, // Don't show label when selected
          },
          itemStyle: {
            areaColor: 'pink', // Highlight color for the selected state
            borderColor: 'white',
            borderWidth: 1,
          },
        },
        itemStyle: {
          normal: {
            areaColor: '#d8d8d8', // Default area color
            borderColor: 'white',
            borderWidth: 1,
          },
          emphasis: {
            areaColor: 'rgb(49, 132, 101)', // Hover color
            borderWidth: 0,
          },
        },
        data: Object.entries(selectedStates).map(([name, selected]) => ({
          name,
          selected, // Preselect the state
          itemStyle: selected ? { areaColor: '#a8dec9' } : {}, // Apply pink color to preselected state
        })),
        selectedMode: false, // Disable selection by click
      },
    ],
  };

  chart.setOption(option);
}
  }
};
</script>


<style scoped lang="scss">
.mapStyle {
  width: 100%;
  height: 210px;
  // border: 1px solid black;
}


.mapContainer {
  position: relative; /* Position relative for absolute children */
}

.cornerDivForMap {
  position: absolute;
    top: 0px;
    left: 0px;
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    padding: 5px;
    z-index: 10;  
    font-size: 12px;
}

.cornerDivForMap span {
  display: flex;
  align-items: center;
  margin-bottom: -1px; /* Space between items */
}

.cornerDivForMap span:last-child {
  margin-bottom: 0; /* No margin for the last item */
  font-weight: 600;
}

.div1, .div2, .div3 {
  display: flex; /* Flexbox for aligning icon and text */
  align-items: center;
}

.circle {
  width: 10px; /* Reduced width for circle */
  height: 10px; /* Reduced height for circle */
  border-radius: 50%; /* Make it circular */
  margin-right: 5px; /* Space between circle and text */
}

/* Circle colors */
.customer {
  background-color: #a8dec9; /* Green for Customer */
}

.no-customer {
  background-color: #d8d8d8; /* Red for No Customer */
}

.hover {
  background-color: rgb(49, 132, 101); /* Yellow for At Hover */
}
</style>

