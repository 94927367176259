<template>
  <!-- :style="singleColumnLayout == true && (isScreenSizeSmall || rendenredInModalWindow == 'form-container') ? 'grid-template-columns: repeat(3, 1fr)' :'grid-template-columns: repeat(6, 1fr)' " -->
  <!-- isScreenSizeSmall || rendenredInModalWindow
    ? 'form-container'
    : 'form-container-small' -->
  <div :class="getContainerClassName() || form - container">
    <template v-for="(field, key) in schema">
      <!-- Dynamic Field Rendering -->
      <div
        v-if="
          field.show &&
          field?.others?.isVisible &&
          (field.displayType === 'input' ||
            field.displayType === 'password' ||
            field.displayType === 'email' ||
            field.displayType === 'date' ||
            field.displayType === 'number' ||
            field.displayType === 'currency' ||
            field.displayType === 'textarea' ||
            field.displayType === 'dropdown' ||
            field.displayType === 'multiselect' ||
            field.displayType === 'texteditor' ||
            field.displayType === 'header' ||
            field.displayType === 'formula' ||
            field.displayType === 'address' ||
            field.displayType === 'checkbox' ||
            field.displayType === 'profile-image-upload' ||
            field.displayType === 'telephone' ||
            field.displayType === 'phonenumber' ||
            field.displayType === 'radio' ||
            field.displayType == 'status' ||
            field.displayType == 'siteaddress' ||
            field.displayType === 'url' ||
            field.displayType === 'user' ||
            field.displayType === 'lookup' ||
            field.displayType == 'status')
        "
        :key="key"
        :class="getFieldClass(field)"
      >
        <!-- lookup field -->
        <lookupComponent
          v-if="field.displayType === 'lookup'"
          v-model:value="field.value"
          :label-value="getLabel(field, key)"
          :filter-payload="field.others.lookup.filterPayload"
          :url="field.others.lookup.url"
          :method="field.others.lookup.method"
          :type="field.others.lookup.type"
          :pre-val="
            typeof field.value === 'object'
              ? field?.value?.name || ''
              : field?.value || ''
          "
          :module="field.others.lookup.module"
          :min-char-to-search="field.others.lookup.minCharToSearch"
          :max-result-to-display="field.others.lookup.maxResultToDisplay"
          :read-only="field.others.readOnly"
          :required="field.required"
          :instance="field.others.lookup.instance"
          :lookup-prop="field.others.lookup.lookupProp"
          :field-type="field.others.lookup.fieldType"
          @selected-value="
            changeValueForObject(
              field.displayType,
              $event,
              key,
              typeof field.value
            )
          "
        ></lookupComponent>

        <!-- Address component for lead -->
        <!-- :style="singleColumnLayout == true ? 'margin-top:20px': ''" -->
        <span v-if="field.displayType === 'siteaddress'">
          <leadAddressComponent
            v-if="field.displayType == 'siteaddress'"
            :address-label="getLabel(field, key)"
            :type="
              field?.others?.addressAPI?.type
                ? field?.others?.addressAPI?.type
                : 'Canada post API'
            "
            :api-key="
              field?.others?.addressAPI?.key
                ? field?.others?.addressAPI?.key
                : 'DE92-ZE95-YR93-BU78'
            "
            :new-coordinate="{ lat: field?.Latitude, lng: field?.Longitude }"
            :read-only="field.others.readOnly"
            :pre-val="{
              characteristic: characteristicpreval?.[0]?.characteristic,
            }"
            :is-service-qualification-enabled="
              service - qualification - enabled
            "
            @address-data="
              receivedAddressData(
                field.displayType,
                $event,
                key,
                typeof field.value
              )
            "
            @address-selected="onAddressSelected"
            @geopoint="geopoint"
          />
        </span>

        <!-- Profile upload Fields -->
        <div
          v-if="field.displayType === 'profile-image-upload'"
          class="profile-picture-component"
        >
          <hlx-file-upload
            :file-type="'.jpg,.jpeg,.png'"
            :file-size-limit="'20mb'"
            :uploadtype="'square'"
            :style-background="backgrounStyleupload"
            :height="93"
            :width="93"
            :custom-icon="icon"
            :type="'profile-upload'"
            :name-display="field?.others?.profileName || ''"
            :profile-pic-preview="
              profilePicUrlBufferData
                ? profilePicUrlBufferData
                : field?.others?.profilePicUrl || ''
            "
            :read-only="field.others.readOnly"
            :content="'Import'"
            @native-data="profileUpload($event, key)"
          />
        </div>
        <!-- Formula Fields -->

        <hlx-code-editor
          v-if="field.displayType === 'formula'"
          v-model:value="field.value"
          :intellisense-data="intellisenseData"
          :pre-value="field.value || ''"
          :line-number="true"
          :rows="3"
          :read-only="field.others.readOnly"
        >
        </hlx-code-editor>
        <!-- Input Fields -->

        <h3 v-if="field.displayType === 'header'">
          {{ field.labels[userLanguage].label }}
        </h3>
        <hlx-select
          v-if="field.displayType === 'user'"
          :v-model:value="
            typeof field.value === 'object' ? field?.value?.name : field?.value
          "
          :pre-value="
            typeof field.value === 'object' ? field?.value?.name : field?.value
          "
          :options="field.options"
          :placeholder-value="getLabel(field, key)"
          :label="'name'"
          :prop-value="'name'"
          :required="field.required"
          :label-animation="true"
          :search-image-tag="true"
          :sub-title="'company'"
          :disabled="field.others.readOnly"
          :display-error="true"
          :tag-value="'newRole'"
          :inline-search="true"
          @selected-value="
            changeValueForObject(
              field.displayType,
              $event,
              key,
              typeof field.value
            )
          "
        >
          <!-- :image-link="'link'" -->
        </hlx-select>

        <hlx-input
          v-if="field.displayType === 'input'"
          :id="key"
          v-model:value="field.value"
          :required="field.required"
          :pre-val="field.value || ''"
          :type="field.type == 'String' || 'text' ? 'text' : 'number'"
          :label-value="getLabel(field, key)"
          :label-animation="true"
          :display-error="true"
          :read-only="field.others.readOnly"
        />
        <hlx-input
          v-if="field.displayType === 'password'"
          v-model:value="field.value"
          :label-animation="true"
          type="password"
          :required="field.required"
          :password-condition="false"
          :label-value="getLabel(field, key)"
          :clearable="false"
          :display-error="true"
          :custom-error="field?.others.passwordErrorValidation?.display"
          :custom-error-message="field?.others.passwordErrorValidation?.content"
          :read-only="field.others.readOnly"
        />

        <hlx-input
          v-if="field.displayType === 'email'"
          :id="key"
          v-model:value="field.value"
          :required="field.required"
          :pre-val="field.value || ''"
          :type="field.displayType"
          :label-value="getLabel(field, key)"
          :label-animation="true"
          :display-error="true"
          :custom-error="isExistingEmail"
          :custom-error-message="isExistingEmailErrorMessage"
          :read-only="field.others.readOnly"
          @focus-out="validateEmailNew"
          @at-input="validateEmail"
        />

        <!-- <hlx-input
      v-if="field.displayType === 'telephone'"
      :id="key"
      v-model:value="field.value"
      :required="field.required"
      :pre-val="field.value || ''"
      :type="'telephone'"
       :display-error="true"
      :label-value="getLabel(field, key)"
      :label-animation="true"
    /> -->

        <hlx-input
          v-if="field.displayType === 'telephone'"
          :id="key"
          v-model:value="field.value"
          :placeholder-value="'Telephone number'"
          :required="field.required"
          :pre-val="field?.value?.value || ''"
          :display-error="true"
          :country-code="field?.value?.country || country"
          :type="'phonenumber'"
          :label-value="getLabel(field, key)"
          :label-animation="true"
          :country-label-value="
            field.others.countryLabel[selectedLanguageCode].label || 'Country'
          "
          :read-only="field.others.readOnly"
        />

        <hlx-input
          v-if="field.displayType === 'phonenumber'"
          :id="key"
          v-model:value="field.value"
          :required="field.required"
          :pre-val="field?.value?.value || ''"
          :display-error="true"
          :country-code="field?.value?.country || country"
          :type="'phonenumber'"
          :label-value="getLabel(field, key)"
          :label-animation="true"
          :country-label-value="
            field.others.countryLabel[selectedLanguageCode].label || 'Country'
          "
          :read-only="field.others.readOnly"
        />

        <hlx-input
          v-if="field.displayType === 'url'"
          :id="key"
          v-model:value="field.value"
          :required="field.required"
          :display-error="true"
          :pre-val="field.value || ''"
          :type="field.type == 'String' || 'text' ? 'text' : 'number'"
          :label-value="getLabel(field, key)"
          :label-animation="true"
          :custom-error="isUrlInvalid"
          :custom-error-message="'Please enter a valid URL in the format: www.abc.com'"
          :read-only="field.others.readOnly"
          @focus-out="callWebsiteRegex"
        />

        <!-- Input Fields for Date -->
        <hlx-input
          v-if="field.displayType === 'date'"
          :id="key"
          :pre-val="
            prePopulateDate(field.value, field.others.businessDatePreference)
          "
          :type="'date'"
          :label-value="getLabel(field, key)"
          :label-animation="true"
          :single-border="false"
          :date-format="'dd (short month), yyyy'"
          :clearable="false"
          icon="calendar-regular"
          icon-position="right"
          :required="field.required"
          :holiday-list="getHolidayDates(field.others.customDatePreference)"
          :disable-weekend="
            isDateWeekendDisabled(field.others.businessDatePreference)
          "
          :start-year="2000"
          :display-error="true"
          :custom-error="startDateBoolean"
          :custom-error-message="startDateMsg"
          :end-year="2200"
          :read-only="field.others.readOnly"
          @at-input="focusOutDate($event, key)"
          @focus-out="focusOutDate($event, key)"
        />
        <!-- Input Fields -->

        <hlx-input
          v-if="field.displayType === 'number'"
          :id="key"
          v-model:value="field.value"
          :pre-val="field.value || ''"
          :type="'number'"
          :required="field.required"
          :label-value="getLabel(field, key)"
          :label-animation="true"
          :display-error="true"
          :read-only="field.others.readOnly"
        />
        <!-- Input Fields -->
        <hlx-input
          v-if="field.displayType === 'currency'"
          :id="key"
          v-model:value="formData[key]"
          :pre-val="0"
          :required="field.required"
          :type="'number'"
          :symbol="$store?.getters?.getDefaultSettings?.currency?.symbol"
          :label-value="getLabel(field, key)"
          :label-animation="true"
          :read-only="field.others.readOnly"
        />

        <!-- Textarea -->

        <hlx-input
          v-if="field.displayType === 'textarea'"
          :id="key"
          v-model:value="field.value"
          :pre-val="field.value != undefined ? field.value : ''"
          :type="field.dataType || 'textarea'"
          :rows="6"
          :required="field.required"
          :label-value="getLabel(field, key)"
          :label-animation="true"
          :display-error="true"
          :read-only="field.others.readOnly"
        />

        <!-- Dropdown -->
        <hlx-select
          v-if="field.displayType === 'dropdown'"
          :v-model:value="
            typeof field.value === 'object' ? field?.value?.name : field?.value
          "
          :pre-value="
            typeof field.value === 'object' ? field?.value?.name : field?.value
          "
          :inline-search="true"
          :options="field.options || []"
          :clearable="false"
          :placeholder-value="getLabel(field, key)"
          :prop-value="'name'"
          :required="field.required"
          :label="selectedLanguageName"
          :label-animation="true"
          :display-error="true"
          :disabled="field.others.readOnly"
          @selected-value="
            changeValueForObject(
              field.displayType,
              $event,
              key,
              typeof field.value
            )
          "
        ></hlx-select>
        <!-- <hlx-select
          v-if="
            field.displayType === 'dropdown' 
          "
          :v-model:value="field?.value"
          :pre-value="field?.value"
          :inline-search="true"
          :options="field.options || []"
          :required="field.required"
          :clearable="true"
          :placeholder-value="getLabel(field, key)"
          :prop-value="'name'"
          :label="selectedLanguageName"
          :label-animation="true"
          @selected-value="changeValueForString($event, key)"
        ></hlx-select> -->

        <!-- Multi select -->
        <!-- <hlx-select
          v-if="
            field.displayType === 'multiselect' && typeof field.value === 'object'
          "
          :v-model:value="field?.value?.name"
          :pre-value="field?.value?.name"
          :inline-search="true"
          :options="field.options || []"
          :clearable="true"
          :placeholder-value="getLabel(field, key)"
          :prop-value="'name'"
          :multi-select="true"
          :required="field.required"
          :label="'name'"
          :label-animation="true"
          @selected-value="changeValueForObject($event, key)"
        ></hlx-select> -->
        <span
          v-if="field.displayType === 'multiselect'"
          style="padding-bottom: 22px"
        >
          <hlx-multi-select
            v-if="field.displayType === 'multiselect'"
            :key="updateMultiselectKey"
            :pre-value="field.value || ''"
            :options="field.options"
            :prop-value="'name'"
            :placeholder="getLabel(field, key)"
            :label-animation="true"
            :clearable="false"
            :tooltipposition="'left'"
            :required="field.required"
            :disabled="field.others.readOnly"
            :label="'name'"
            :custom-error="true"
            :display-error="true"
            @add-data="addTagList($event, key)"
            @selected-array="changeValueForString($event, key)"
          ></hlx-multi-select>
        </span>

        <!-- Radio Buttons -->
        <hlx-input
          v-if="field.displayType === 'radio'"
          :id="key"
          v-model:value="formData[key]"
          :pre-val="field.value"
          :prop-value="'name'"
          :read-only="field.others.readOnly"
          :type="'radio-buttons'"
          :display-error="true"
          :options="field.options"
        />

        <!-- checkbox Buttons -->
        <hlx-input
          v-if="field.displayType === 'checkbox'"
          :id="key"
          v-model:value="formData[key]"
          :prop-value="'label'"
          :read-only="field.others.readOnly"
          :type="'checkbox'"
          :options="field.options"
          :display-error="true"
        />

        <!-- Address -->
        <!-- <addressComponent
          v-if="field.displayType === 'address'"
        ></addressComponent> -->

        <!-- texteditor -->
        <hlx-text-editor
          v-if="field.displayType === 'texteditor'"
          v-model:modelValue="formData[key]"
          :height="'315px'"
          :read-only="field.others.readOnly"
        />

        <!-- Status component -->
        <div
          v-if="field.displayType === 'status'"
          class="status-component"
        >
          <hlx-status
            :type="'select-status'"
            :prevalue="field?.value"
            :readonly="field.others.readOnly"
            :required="field.required"
            :label="selectedLanguageName.toLowerCase()"
            :placeholder-value="getLabel(field, key)"
            :status-option="field.options || []"
            @emit-select="changeValueForStatus($event, key)"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from '@/config/axiosConfig';
import diff from 'deep-diff';
// import addressComponent from '@/components/CM/autocompleteComponent.vue';
import leadAddressComponent from '@/views/Lead/leadAddressComponent.vue';
import debounce from 'lodash/debounce';
import lookupComponent from '@/components/CustomFields/lookupComponent.vue';

export default {
  components: {
    // addressComponent,
    leadAddressComponent,
    lookupComponent,
  },
  props: {
    updatedSchemaAfterRemap: {
      type: Object,
      default: () => {
        return {};
      },
    },
    serviceQualificationEnabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    layout: {
      type: String,
      default: '2-column',
    },
    mixedColumnLayout: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    schemaPayload: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isModalWindow: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    parentClassName: {
      type: String,
      required: true,
    },
    dataPayload: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isSaveClicked: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    isAddClicked: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    checkValidation: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    statusList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    module: {
      type: Object,
      default: () => {
        return { name: 'saleslead', code: 'mdu' };
      },
    },
  },
  emits: [
    'updated-schema',
    'edited-payload',
    'profile-buffer-data',
    'profile-name',
    'lookup-data',
    'lookup-emit',
  ],
  data() {
    return {
      isRemapData: false,
      dupSchema: null,
      isFuncOver: false,
      dataPayloadForRemap: null,
      temporaryDataPayload: null,
      updateMultiselectKey: 0,
      backgrounStyleupload: {
        backgroundColor: '',
        bacgroundtTextColor: '#A8A8A8',
        border: false,
        borderColor: '#A8A8A8',
        TextfontSize: 16,
      },
      profilePicUrlBufferData: '',
      rendenredInModalWindow: false,
      intellisenseData: {
        schema: [],
        api: [{ label: 'API()' }],
        email: [{ label: 'Email()' }],
        keywords: [
          { label: 'if' },
          { label: 'then' },
          { label: 'else' },
          { label: 'and' },
          { label: 'or' },
          { label: 'function' },
          { label: 'filter' },
          { label: 'reduce' },
          { label: 'map' },
          { label: 'Date' },
          { label: 'Math' },
          { label: 'slice' },
          { label: 'substr' },
          { label: 'concat' },
          { label: 'true' },
          { label: 'false' },
        ],
        methods: {
          array: [
            {
              label: 'filter',
              syntax:
                'array.filter(function(currentValue, index, arr), thisValue)',
            },
            {
              label: 'reduce',
              syntax:
                'array.reduce(function(total, currentValue, currentIndex, arr), initialValue)',
            },
            {
              label: 'map',
              syntax:
                'array.map(function(currentValue, index, arr), thisValue)',
            },
          ],
          date: [
            {
              label: 'getDay',
              syntax: 'getDay()',
            },
            {
              label: 'getDate',
              syntax: 'getDate()',
            },
            {
              label: 'getTime',
              syntax: 'getTime()',
            },
            {
              label: 'getMonth',
              syntax: 'getMonth()',
            },
            {
              label: 'getFullYear',
              syntax: 'getFullYear()',
            },
            {
              label: 'setDate',
              syntax: 'setDate()',
            },
            {
              label: 'setTime',
              syntax: 'setTime()',
            },
            {
              label: 'setMonth',
              syntax: 'setMonth()',
            },
            {
              label: 'setFullYear',
              syntax: 'setFullYear()',
            },
          ],
          integer: [
            {
              label: 'random',
              syntax: 'Math.random()',
            },
            {
              label: 'round',
              syntax: 'Math.round(x)',
            },
          ],
          string: [
            { label: 'charAt', syntax: 'string.charAt(index)' },
            { label: 'slice', syntax: 'string.slice(start, end)' },
          ],
        },
      },
      country: {
        name: this.$store.getters.defaultSetting?.country,
        short: this.$store.getters.defaultSetting?.cca2,
        flags: this.$store.getters.defaultSetting?.flags?.svg,
      },
      addressSelected: false,
      characteristicpreval: [
        {
          mediumType: 'postalAddress',
          characteristic: {
            street: '',
            '@type': '',
            city: '',
            country: '',
            default: false,
            description: '',
            numberOfUnits: '',
            postalCode: '',
            state: '',
            stateOrProvince: '',
            street1: '',
            street2: '',
            unit: '',
            latitude: '',
            longitude: '',
          },
        },
      ],
      isUrlInvalid: false,
      userLanguage: 'en',
      orginalDataPayload: null,
      copyOfPayload: null,
      // selected user language code
      selectedLanguageCode: 'en',
      selectedLanguageName: 'english',

      // Dynamically updated form data
      formData: {},

      // Schema definition
      schema: {},
      // {
      //   name: {
      //     type: 'String',
      //     system: true,
      //     displayType: 'input',
      //     isRef: false,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'Company name',
      //         name: 'English',
      //         tooltip: 'name',
      //       },
      //     },
      //     optionRef: '',
      //     required: true,
      //     section: ['overview'],
      //     show: true,
      //   },
      //   status: {
      //     type: 'String',
      //     system: true,
      //     displayType: 'dropdown',
      //     isRef: false,
      //     isTooltip: false,
      //     labels: {
      //       en: {
      //         label: 'Status',
      //         name: 'English',
      //         tooltip: 'status',
      //       },
      //     },
      //     optionRef: '',
      //     required: true,
      //     section: ['overview'],
      //     show: true,
      //   },
      //   marketingCampaign: {
      //     type: 'Mixed',
      //     system: true,
      //     displayType: 'dropdown',
      //     isRef: true,
      //     isTooltip: false,
      //     labels: {
      //       en: {
      //         label: 'Lead source',
      //         name: 'English',
      //         tooltip: 'LeadSource',
      //       },
      //     },
      //     optionRef: 'LeadSource',
      //     required: false,
      //     section: ['overview'],
      //     show: true,
      //   },
      //   type: {
      //     type: 'String',
      //     system: true,
      //     displayType: 'dropdown',
      //     isRef: true,
      //     isTooltip: false,
      //     labels: {
      //       en: {
      //         label: 'Lead type',
      //         name: 'English',
      //         tooltip: 'type',
      //       },
      //     },
      //     optionRef: 'LeadType',
      //     required: false,
      //     section: ['overview'],
      //     show: true,
      //   },
      //   id: {
      //     type: 'String',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'id',
      //         name: 'English',
      //         tooltip: 'id',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   dwellingtype: {
      //     type: 'String',
      //     system: true,
      //     displayType: 'dropdown',
      //     isRef: false,
      //     isTooltip: false,
      //     labels: {
      //       en: {
      //         label: 'Dwelling type',
      //         name: 'English',
      //         tooltip: 'dwellingtype',
      //       },
      //     },
      //     optionRef: 'LeadDwellingType',
      //     required: true,
      //     section: ['overview'],
      //     show: true,
      //   },

      //   category: {
      //     type: 'Mixed',
      //     system: true,
      //     displayType: 'dropdown',
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'Category',
      //         name: 'English',
      //         tooltip: 'category',
      //       },
      //     },
      //     optionRef: 'LeadCategory',
      //     required: true,
      //     section: ['overview'],
      //     show: true,
      //   },

      //   marketSegment: {
      //     type: 'Mixed',
      //     system: true,
      //     displayType: 'dropdown',
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'Market segment',
      //         name: 'English',
      //         tooltip: 'marketSegment',
      //       },
      //     },
      //     optionRef: 'LeadMarketSegment',
      //     required: true,
      //     section: ['overview'],
      //     show: true,
      //   },

      //   referredDate: {
      //     type: 'Date',
      //     system: true,
      //     displayType: 'date',
      //     isRef: false,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'Onboarding date',
      //         name: 'English',
      //         tooltip: 'onboardingData',
      //       },
      //     },
      //     optionRef: '',
      //     required: false,
      //     section: ['overview'],
      //     show: true,
      //   },
      //   displayID: {
      //     type: 'String',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'displayID',
      //         name: 'English',
      //         tooltip: 'displayID',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   description: {
      //     type: 'String',
      //     system: true,
      //     displayType: 'description',
      //     isRef: false,
      //     isTooltip: false,
      //     labels: {
      //       en: {
      //         label: 'Description',
      //         name: 'English',
      //         tooltip: 'description',
      //       },
      //     },
      //     optionRef: '',
      //     required: false,
      //     section: ['overview'],
      //     show: true,
      //   },
      //   creationDate: {
      //     type: 'Date',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'creationDate',
      //         name: 'English',
      //         tooltip: 'creationDate',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   lastUpdate: {
      //     type: 'Date',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'lastUpdate',
      //         name: 'English',
      //         tooltip: 'lastUpdate',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   estimatedRevenue: {
      //     type: 'Mixed',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'estimatedRevenue',
      //         name: 'English',
      //         tooltip: 'estimatedRevenue',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   href: {
      //     type: 'String',
      //     system: true,
      //     displayType: 'input',
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'Website url',
      //         name: 'English',
      //         tooltip: 'websiteurl',
      //       },
      //     },
      //     optionRef: '',
      //     required: false,
      //     section: ['overview'],
      //     show: true,
      //   },
      //   priority: {
      //     type: 'String',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'priority',
      //         name: 'English',
      //         tooltip: 'priority',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   rating: {
      //     type: 'String',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'rating',
      //         name: 'English',
      //         tooltip: 'rating',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   statusChangeReason: {
      //     type: 'String',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'statusChangeReason',
      //         name: 'English',
      //         tooltip: 'statusChangeReason',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   statusChangeDate: {
      //     type: 'Date',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'statusChangeDate',
      //         name: 'English',
      //         tooltip: 'statusChangeDate',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   channel: {
      //     type: 'Mixed',
      //     system: true,
      //     displayType: 'dropdown',
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'Deal category',
      //         name: 'English',
      //         tooltip: 'leadDealCategory',
      //       },
      //     },
      //     optionRef: 'LeadDealCategory',
      //     required: false,
      //     section: ['overview'],
      //     show: true,
      //   },
      //   validFor: {
      //     type: 'Mixed',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'validFor',
      //         name: 'English',
      //         tooltip: 'validFor',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   contactMedium: {
      //     type: 'Array',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'contactMedium',
      //         name: 'English',
      //         tooltip: 'contactMedium',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   note: {
      //     type: 'Array',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'note',
      //         name: 'English',
      //         tooltip: 'note',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   relatedParty: {
      //     type: 'Array',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'relatedParty',
      //         name: 'English',
      //         tooltip: 'relatedParty',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   productOffering: {
      //     type: 'Mixed',
      //     system: true,
      //     displayType: 'dropdown',
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'Tags',
      //         name: 'English',
      //         tooltip: 'tags',
      //       },
      //     },
      //     optionRef: '',
      //     required: false,
      //     section: ['overview'],
      //     show: true,
      //   },
      //   salesOpportunity: {
      //     type: 'Mixed',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'salesOpportunity',
      //         name: 'English',
      //         tooltip: 'salesOpportunity',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   productSpecification: {
      //     type: 'Mixed',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'productSpecification',
      //         name: 'English',
      //         tooltip: 'productSpecification',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   createdBy: {
      //     type: 'String',
      //     system: true,
      //     displayType: 'dropdown',
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: 'Lead owner',
      //         name: 'English',
      //         tooltip: 'leadOwner',
      //       },
      //     },
      //     optionRef: 'LeadOwner',
      //     required: true,
      //     section: [],
      //     show: true,
      //   },
      //   _id: {
      //     type: 'ObjectID',
      //     system: true,
      //     displayType: null,
      //     isRef: true,
      //     isTooltip: true,
      //     labels: {
      //       en: {
      //         label: '_id',
      //         name: 'English',
      //         tooltip: '_id',
      //       },
      //     },
      //     optionRef: 'referenceDataName',
      //     required: false,
      //     section: [],
      //     show: true,
      //   },
      //   sitesNumber: {},
      //   siteDrop: {},
      //   maxRecursive: {},
      // },
      //   {
      //     firstName: {
      //       type: 'String',
      //       displayType: 'input',
      //       labelEnglish: 'First Name',
      //       show: true,
      //       section: ['overview'],
      //     },
      //     lastName: {
      //       type: 'String',
      //       displayType: 'input',
      //       labelEnglish: 'Last Name',
      //       show: true,
      //       section: ['overview'],
      //     },
      //     description: {
      //       type: 'String',
      //       displayType: 'textarea',
      //       labelEnglish: 'Description',
      //       show: true,
      //       section: ['details'],
      //     },
      //     gender: {
      //       labelEnglish: 'Gender',
      //       type: 'String',
      //       displayType: 'radio',
      //       options: [
      //         { value: 'male', label: 'Male', checked: false },
      //         { value: 'female', label: 'Female', checked: false },
      //         { value: 'other', label: 'Other', checked: false },
      //       ],
      //       show: true,
      //     },
      //     category: {
      //       type: 'Array',
      //       displayType: 'dropdown',
      //       labelEnglish: 'Category',
      //       options: [
      //         { value: 'male', label: 'Male', checked: false },
      //         { value: 'female', label: 'Female', checked: false },
      //         { value: 'other', label: 'Other', checked: false },
      //       ],
      //       show: true,
      //       section: ['details'],
      //     },
      //     gender2: {
      //       labelEnglish: 'Gender 2',
      //       type: 'String',
      //       displayType: 'checkbox',
      //       options: [
      //         { value: 'male', label: 'Male', checked: false },
      //         { value: 'female', label: 'Female', checked: false },
      //         { value: 'other', label: 'Other', checked: false },
      //       ],
      //       show: true,
      //     },
      //     field1: {
      //       type: 'String',
      //       displayType: 'input',
      //       labelEnglish: 'Field 1',
      //       show: false,
      //       section: ['overview'],
      //     },
      //     field3: {
      //       type: 'String',
      //       displayType: 'address',
      //       labelEnglish: 'Field 3',
      //       show: true,
      //       section: ['overview'],
      //     },

      //     field2: {
      //       type: 'String',
      //       displayType: 'input',
      //       labelEnglish: 'String',
      //       show: true,
      //       section: ['overview'],
      //     },
      //     field4: {
      //       type: 'String',
      //       displayType: 'currency',
      //       labelEnglish: 'Currency',
      //       show: true,
      //       symbol: '$',
      //       section: ['overview'],
      //     },
      //     field5: {
      //       type: 'String',
      //       displayType: 'number',
      //       labelEnglish: 'Number',
      //       show: true,
      //       section: ['overview'],
      //     },
      //     field6: {
      //       type: 'String',
      //       displayType: 'texteditor',
      //       labelEnglish: 'Number',
      //       show: true,
      //       section: ['overview'],
      //     },
      //   },
      originalSchema: {},
      updadatedSchema: {},
      emailData: [],
      addressData: [],
    };
  },
  watch: {
    // parentClassName:{
    //   handler(val) {
    //     const parentElement = document.querySelector(`.${val}`);
    //   console.log('this.parentClassName',val, parentElement);
    //   if (parentElement) {
    //     this.isScreenSizeSmall = parentElement.offsetWidth > '500';
    //     console.log('Parent width:', parentElement.offsetWidth);
    //   }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    isModalWindow: {
      handler(val) {
        this.$nextTick(() => {
          this.handleResize();
        });
        this.handleResize();
        this.getParentWidth();
        this.rendenredInModalWindow = val;
        console.log(this.rendenredInModalWindow, 'isModalWindow', val);
      },
      deep: true,
      immediate: true,
    },
    isAddClicked: {
      handler(val) {
        if (val == true) {
          console.log(val);
          // this.mapSchemaToData(this.orginalDataPayload, this.schema);
        }
      },
      deep: true,
      immediate: true,
    },
    updatedSchemaAfterRemap: {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          this.handleResize();
          this.getParentWidth();
          console.log(
            this.orginalDataPayload,
            'updatedSchemaAfterRemap',
            val,
            'this.copyOfPayload ',
            this.copyOfPayload
          );
          this.mapSchemaToData(this.orginalDataPayload, val);
          this.$nextTick(() => {
          this.handleResize();
        });
        }
      },
      deep: true,
      immediate: true,
    },
    isSaveClicked: {
      handler(val) {
        if (val == true) {
          console.log(val);
          // this.mapSchemaToData(this.orginalDataPayload, this.schema);
        }
      },
      deep: true,
      immediate: true,
    },
    schemaPayload: {
      async handler(val) {
        await this.handleResize();
        await this.getParentWidth();
        console.log('dataPayload schema', val);
        if (val && this.isFuncOver == false) {
          this.schema = { ...val };
          this.processFields(val);
          // console.log('this.schema ', this.schema);
        }
        this.$nextTick(() => {
          this.handleResize();
        });
      },
      deep: true,
      immediate: true,
    },
    schema: {
      handler: debounce(async function (oldval, val) {
        try {
          if (val) {
            console.log(oldval, 'oldval', val);
            const data = {
              payload: {
                data: val,
                emailArrayData: this.emailData,
                addressArrayData: this.addressData,
              },
            };
            console.log(
              JSON.parse(JSON.stringify(val)),
              'this.originalSchema',
              this.originalSchema
            );
            this.originalSchema = JSON.parse(JSON.stringify(val));
            const visRule = await DMNAxiosInstance.post(
              'rule_engine/rule/execute/6750634eaab338ec808db381',
              data
            );

            console.log('API Response:', visRule);

            this.updatedSchema = JSON.parse(
              JSON.stringify(visRule.data.data.data)
            );

            let changes = diff(this.originalSchema, this.updatedSchema);
            console.log(this.schema, 'this.updatedSchema', this.updatedSchema);
            console.log('changeschanges', changes);
            // changes = false
            if (changes && changes.length > 0) {
              const schema = this.schema;
              const updatedSchema = this.updatedSchema;

              changes.map(({ path }) => {
                const element = path[0];

                if (element && schema[element] && updatedSchema[element]) {
                  const currentElement = schema[element];
                  let valueOfElement =
                    currentElement.value !== undefined
                      ? currentElement.value
                      : null;

                  schema[element] = updatedSchema[element];

                  if (valueOfElement !== null) {
                    schema[element].value = valueOfElement;
                  }
                }
              });
            }
            // this.schema = JSON.parse(JSON.stringify(this.updatedSchema));
            // this.applyChangesToSchema(this.schema, changes);
            if (this.orginalDataPayload && val) {
              console.log(this.originalSchema,this.orginalDataPayload,"this.mapSchemaToData(",val)
              this.mapSchemaToData(this.orginalDataPayload, this.schema);
            }
          }
        } catch (error) {
          console.log(error, 'Error');
        }
        this.$nextTick(() => {
          this.handleResize();
        });
      }, 1000), // Adjust debounce delay as needed
      deep: true,
      immediate: true,
    },
    dataPayload: {
      handler(val) {
        console.log(this.isRemapData, 'dataPayloaddataPayload', val);
        if (val && this.schema) {
          this.orginalDataPayload = JSON.parse(JSON.stringify(val));
          this.copyOfPayload = JSON.parse(JSON.stringify(val));
        }
        this.$nextTick(() => {
          this.handleResize();
        });
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.isFuncOver = false;
    let emailFilter = {
      type: 'filter',
      module: 'contacts',
      isPaginated: false,
      paginatedQuery: {
        rowsPerPage: 1000,
        currentPage: 1,
      },
      isNeedToBeSort: false,
      sortFieldAndOrder: {},
      limit: 1000,
      filterQuery: {
        '@baseType': 'customer',
      },
      searchQuery: '',
      company: 'developerHalleyx',
    };
    let data = await MLTAxiosInstance.post('util/filterData', emailFilter);
    data = data.data.data;
    console.log(data, 'dataa');

    const emailAddresses = data.map(
      (item) => item.contactMedium[0].characteristic.emailAddress
    );
    this.emailData = emailAddresses;
    const addresses = data.map(
      (item) => item.contactMedium[2].characteristic.description
    );
    this.addressData = addresses;
    this.getParentWidth();
    this.updateMultiselectKey += 1;
    await this.$store.dispatch('loginUserDetails');
    // Initialize form data keys with default values
    Object.keys(this.schema).forEach((key) => {
      this.formData[key] = this.schema[key].default || '';
    });
    window.addEventListener('resize', this.getParentWidth);
    window.addEventListener('click', this.getParentWidth);
    await this.getIntellisenseData();
    console.log(
      this.$store?.getters?.loginUserDetails?.languageAbility[0],
      'lang'
    );
    this.selectedLanguageCode =
      this.$store?.getters?.loginUserDetails?.languageAbility[0]?.code;
    this.selectedLanguageName =
      this.$store?.getters?.loginUserDetails?.languageAbility[0]?.name?.toLowerCase() ||
      'name';
    window.addEventListener('resize', this.handleResize);
    this.$nextTick(() => {
      this.handleResize();
    });
    this.originalSchema = JSON.parse(JSON.stringify(this.schema));
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.getParentWidth);
    window.removeEventListener('click', this.getParentWidth);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    getHolidayDates(pref) {
      if (pref.preference == 'all-days') {
        return [];
      } else if (pref.preference == 'past-dates') {
        return this.generateDates('past-dates', {});
      } else if (pref.preference == 'future-dates') {
        return this.generateDates('future-dates', {});
      } else if (pref.preference == 'range') {
        return this.generateDates('range', pref.range);
      }
    },
    generateDates(mode, range = {}) {
      const oneDay = 24 * 60 * 60 * 1000;
      const dates = [];
      const startYear = 2000;
      const endYear = 2200;

      const startDate2000 = new Date(`${startYear}-01-01T00:00:00.000Z`);
      const endDate2200 = new Date(`${endYear}-12-31T00:00:00.000Z`);
      const today = new Date();

      let startDate, endDate, excludeStart, excludeEnd;

      if (mode === 'past-dates') {
        // Generate dates from tomorrow to the end of 2200
        startDate = new Date(today.getTime() + oneDay);
        endDate = endDate2200;
      } else if (mode === 'future-dates') {
        // Generate dates from yesterday to the start of 2000
        startDate = new Date(today.getTime() - oneDay);
        endDate = startDate2000;
      } else if (mode === 'range') {
        if (!range.from || !range.to) {
          throw new Error(`Invalid range: 'from' and 'to' must be specified`);
        }

        // Parse and adjust exclusion dates to UTC
        excludeStart = new Date(
          Date.UTC(
            new Date(range.from).getUTCFullYear(),
            new Date(range.from).getUTCMonth(),
            new Date(range.from).getUTCDate()
          )
        );
        excludeEnd = new Date(
          Date.UTC(
            new Date(range.to).getUTCFullYear(),
            new Date(range.to).getUTCMonth(),
            new Date(range.to).getUTCDate()
          )
        );

        if (excludeStart > excludeEnd) {
          throw new Error(
            `Invalid range: 'from' date must be earlier than 'to' date`
          );
        }

        startDate = startDate2000;
        endDate = endDate2200;
      } else {
        throw new Error(`Unsupported mode: ${mode}`);
      }

      let currentDate = startDate;

      // Generate dates based on the mode
      while (
        mode === 'past-dates' || mode === 'range'
          ? currentDate <= endDate
          : currentDate >= endDate
      ) {
        const day = String(currentDate.getUTCDate());
        const month = String(currentDate.getUTCMonth() + 1);
        const year = currentDate.getUTCFullYear();
        const formattedDate = `${day}-${month}-${year}`;

        // Exclude dates within the range for 'range' mode
        if (
          mode === 'range' &&
          currentDate >= excludeStart &&
          currentDate <= excludeEnd
        ) {
          currentDate = new Date(currentDate.getTime() + oneDay);
          continue;
        }

        dates.push(formattedDate);

        currentDate = new Date(
          currentDate.getTime() +
            (mode === 'past-dates' || mode === 'range' ? oneDay : -oneDay)
        );
      }

      console.log(dates, 'dates');
      return dates;
    },
    prePopulateDate(value, preference) {
      if (value != '' && value != undefined) {
        return value;
      } 
      else if(value == undefined || value == ''){
        return ''
      }
      else {
        let arr = preference.filter((i) => {
          return i.value == 'default-today';
        });
        if (arr[0].checked == true) {
          const today = new Date();
          const day = String(today.getDate()).padStart(2, '0');
          const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
          const year = today.getFullYear();

          console.log(value, preference, day, month, year, 'newDate');
          const customDate = `${month}-${String(Number(day) + 1)}-${year}`;
          return customDate;
        } else {
          return '';
        }
      }
    },
    isDateWeekendDisabled(preference) {
      let arr = preference.filter((i) => {
        return i.value == 'business-days-only';
      });
      return arr[0].checked;
    },
    async remapLookupData(dataPayload, remapStructure, val) {
      console.log(val, dataPayload, 'remapLookupData', remapStructure);
      if (dataPayload && remapStructure) {
        const dupRemapStructure = remapStructure
          .split(/;/) // Split by semicolon
          .map((line) => line.trim()) // Trim whitespace
          .filter((line) => line !== '') // Remove empty lines
          .map((line) => {
            const [LHS, ...RHSParts] = line.split(/=(.+)/); // Split at the first '=' with remaining content as RHS
            let RHS = RHSParts.join().trim();
            RHS = RHS.replace(/\n/g, ' ');
            RHS = RHS.replace(/,/g, ' ');
            RHS = RHS.replace(/\s+/g, ' '); // Remove  spaces
            RHS = RHS.replace(/\n/g, ' '); // Remove newlines
            RHS = RHS.trim();
            // Rejoin RHS in case of extra splits
            return {
              LHS: LHS ? LHS.trim() : '',
              RHS: RHS ? RHS.trim() : '',
            };
          });

        this.$emit('lookup-data', [
          dataPayload,
          dupRemapStructure,
          this.schema,
        ]);
        // await this.mapSchemaToData(this.orginalDataPayload,this.schema)
        console.log('this.isRemapData', this.isRemapData);
        //     .map(line => {
        //   let [LHS, ...RHSParts] = line.split(/=(.+)/);
        //   return {
        //     LHS: LHS.trim(),
        //     RHS: RHSParts.join("=").trim()
        //   };
        // });

        //   for(let i=0;i<dupRemapStructure.length;i++){
        //    let schemaObjName = dupRemapStructure[i].LHS
        //    let schemaValue =  dupRemapStructure[i].RHS
        //    let changeRhsValue = 'payload'
        //   const firstDotIndex = schemaValue.indexOf('.');
        // console.log(schemaValue,"dataPayloaddataPayload",dataPayload)
        //   if (firstDotIndex !== -1) {
        //     let newRHS = changeRhsValue + schemaValue.slice(firstDotIndex);
        //     console.log(schemaObjName, "newRHS", newRHS);
        //     newRHS
        //    let result =  this.evaluateRHS(newRHS,dataPayload)
        //    console.log("remappped data",result)
        //    if(result)
        //    {
        //     this.schema[schemaObjName].value = result
        //     console.log("this.schema",this.schema)
        //    }
        //   }

        //   // let result = eval(newRHS);
        // // Log the result (assuming companyName is defined in the scope)
        // // console.log(result);
        //     console.log(schemaObjName,"schemaValue",schemaValue)
        //   }
        console.log(
          dupRemapStructure,
          'dupRemapStructure',
          dupRemapStructure.length
        );
      }
    },
    async getUserRoleOptions(role) {
      let filterData = {
        company: 'developerHalleyx',
        type: 'filter-search',
        module: 'hierarchycontacts',
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 1000,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        limit: 2,
        filterQuery: {
          'role.name': {
            $in: role,
          },
          name: {
            $regex: '',
            $options: 'i',
          },
        },
        searchQuery: '',
      };
      let api = await MLTAxiosInstance.post('util/filterData', filterData);
      console.log(api.data.data, role, 'getUserRoleOptions');
      return api.data.data;
    },
    getContainerClassName() {
      // if(this.isModalWindow){
      //   return 'form-container-modal'
      // }
      // else if(this.singleColumnLayout){
      //   return 'form-container-small'
      // }
      // else if(this.mixedColumnLayout){
      //   return 'form-container-mixed'
      // }
      // else{
      //   return 'form-container'
      // }
      switch (this.layout) {
        case '2-column':
          return 'form-container';
        case '1-column':
          return 'form-container-small';
        case 'modal':
          return 'form-container-modal';
        case 'mixed':
          return 'form-container-mixed';
        default:
          break;
      }
    },
    async bufferToDataURL(buffer) {
      console.log('bufferToDataURL', buffer);
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = '';
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },
    async addTagList($event, val) {
      console.log($event, val);
      let obj = {};
      obj.name = $event.name;
      obj.module = 'mdu';
      await DMNAxiosInstance.post(`mapRef/tagsList`, obj);
      let tagsList = await DMNAxiosInstance.get(`mapRef/collection/tagsList`);
      console.log('tagsList', tagsList);
      let arr = this.schema[val]?.value;
      arr.push($event?.name);
      this.schema[val].value = arr;
      this.schema[val].options = tagsList.data.data.filter((item) => {
        return item.module === 'mdu';
      });
    },
    handleResize() {
      const isTablet = window.matchMedia('(max-width: 768px)').matches;
      const div1 = document.querySelector('.gf-2');
      const div2 = document.querySelector('.gf-3');

      if (isTablet) {
        // Tablet mode: Change class names
        if (div1) div1.className = 'form-field gf-2 tablet-grid-field-2';
        if (div2) div2.className = 'form-field gf-3 tablet-grid-field-3';

        // Remove styles applied by syncDivs
        if (div2) {
          div2.style.position = '';
          div2.style.width = '';
          div2.style.height = '';
          div2.style.left = '';
          div2.style.top = '';
        }

        console.log('Entered tablet mode');
      } else {
        // Desktop mode: Revert class names
        if (div1) div1.className = 'form-field gf-2 grid-field-2';
        if (div2) div2.className = 'form-field gf-3 grid-field-3';

        this.syncDivs(); // Reapply sync logic for non-tablet
        console.log('Exited tablet mode');
      }
    },
    syncDivs() {
      this.$nextTick(() => {
        const div1 = document.querySelector('.grid-field-2');
        const div2 = document.querySelector('.grid-field-3');

        if (div1 && div2) {
          const rect = div1.getBoundingClientRect();
          console.log(
            'syncing divs',
            div1,
            div2,
            rect,
            div2.style.width,
            rect.width,
            rect.left
          );
          if (this.isModalWindow) {
            div2.style.setProperty('position', 'absolute', 'important');
            div2.style.setProperty('width', `${rect.width}px`, 'important');
            div2.style.setProperty('height', `${rect.height}px`, 'important');
            div2.style.setProperty('left', `161px`, 'important');
            div2.style.setProperty('top', `135px`, 'important');
          } else {
            div2.style.setProperty('position', 'absolute', 'important');
            div2.style.setProperty('width', `${rect.width}px`, 'important');
            div2.style.setProperty('height', `${rect.height}px`, 'important');
            div2.style.setProperty('left', `${rect.left}px`, 'important');
            div2.style.setProperty('top', `${rect.top + 55}px`, 'important');
          }
        }
      });
    },
    async profileUpload(event, val) {
      // write your logic to handle profile picture along the data
      console.log(event);
      let selectedFiles = event.target.files[0];
      let formData = new FormData();

      // Append all selected files to FormData object
      formData.append('files', selectedFiles);
      let data = await UPCAxiosInstance.post(
        `/gcpMultiple/v2/upload/`,
        formData
      );
      console.log(data);

      let obj = {};
      obj.name = data.data[0].filename;
      obj.size = data.data[0].data.attachment.size;
      obj.url = data.data[0].url;
      obj.id = data.data[0].data._id;
      obj['@type'] = 'profile';
      // Get the current date and time in milliseconds
      const currentTimestamp = new Date().toJSON();

      // Format the current timestamp in the same format as '1705574420667'
      console.log(currentTimestamp, 'val', val);
      obj.lastUpdated = currentTimestamp;
      this.schema[val].value = obj;
      // this.schema[val].others.profilePicUrl=obj.url;
      // this.schema[val].others.profileName = obj.name
      const url = await UPCAxiosInstance.get(`/gcpupload/files/${obj.name}`, {
        responseType: 'arraybuffer',
      });
      this.profilePicUrlBufferData = await this.bufferToDataURL(url.data);
      this.$emit('profile-buffer-data', this.profilePicUrlBufferData);
      this.$emit('profile-name', obj.name);
      console.log(this.schema, 'this.schema');
    },
    async getIntellisenseData() {
      let res = await DMNAxiosInstance.get(
        `/import/intellisense_by_module/?type=${this.module?.name}`
      );

      if (res.data.ok == 1) {
        let schema = res.data.data.schema;
        schema.forEach((i) => {
          i.label = i.name;
          if (i.type == 'Mixed') {
            i.type = 'Object';
          }
          if (i.type == 'ObjectID') {
            i.type = 'String';
          }
          i.type = i.type.toLowerCase();
        });
        this.intellisenseData.schema = schema;
      }
    },
    focusOutDate($event, val) {
      console.log($event, 'focusOutDate', val);
      this.schema[val].value = $event;
    },
    onAddressSelected(val) {
      console.log;
      this.addressSelected = val;
    },
    async receivedAddressData(displayType, $event, val, typeOf) {
      try {
        if (this.addressSelected) {
          if(this.schema[val].value[0]){
          this.schema[val].value[0] = $event;
          }
          else if(this.schema[val].value){
            this.schema[val].value = this.characteristicpreval  
                      this.schema[val].value[0] = $event;
          }
          this.characteristicpreval[0] = $event;
          console.log(
            typeOf,
            $event,
            'receivedAddressData',
            val,
            'this.addressSelected',
            this.addressSelected,
            this.schema
          );
          await this.mapSchemaToData(this.orginalDataPayload, this.schema);
          await this.changeValueForObject(
            displayType,
            $event.characteristic,
            val,
            typeOf
          );
        }
      } catch (error) {
        console.log('Error from receivedAddressData', error);
      }
    },
    getParentWidth() {
      const parentElement = document.querySelector(`.${this.parentClassName}`);
      console.log('this.parentClassName', this.parentClassName, parentElement);
      if (parentElement) {
        this.isScreenSizeSmall = parentElement.offsetWidth > '500';
        console.log('Parent width:', parentElement.offsetWidth);
      }
    },
    callWebsiteRegex(val) {
      this.isUrlInvalid = this.websiteRegex(val);
    },
    changeValueForStatus($event, val) {
      console.log($event, 'changeValueForStatus', val);
      if (this.schema[val]) {
        this.schema[val].value = $event.name;
      } else {
        console.log('changeValueForString');
      }
    },
    changeValueForObject(displayType, $event, val, type) {
      console.log('emited', displayType);
      this.$emit('lookup-emit', $event);
      console.log(
        $event,
        'changeValueForObject',
        val,
        'type',
        type,
        displayType
      );
      if (this.schema[val]?.others?.lookup?.isDataRemapEnabled) {
        this.temporaryDataPayload = JSON.parse(JSON.stringify($event));
        // const getMappingSchema = this.schema.val.others.lookup.remap
        const getMappingSchema = this.schema[val].others.lookup.remapData;
        this.schema[val].others.lookup.lookupData;
        console.log(val, 'getMappingSchema', getMappingSchema);
        this.remapLookupData(this.temporaryDataPayload, getMappingSchema, val);
        console.log($event, 'displayType => ', displayType);
        console.log('temporaryDataPayload', this.temporaryDataPayload);
        // this.$emit("lookup-data",[$event , getMappingSchema])
      } else {
        if (type == 'object') {
          if (this.schema[val]) {
            this.schema[val].value = {
              ...this.schema[val].value,
              name: $event.name,
            };
          } else {
            console.log('change');
          }
        }
        if (type == 'string') {
          if (this.schema[val]) {
            this.schema[val].value = $event.name;
          } else {
            console.log('changeValueForString');
          }
        }
      }
    },
    changeValueForString($event, val) {
      console.log($event, 'changeValueForString', val);
      const selestedTagsString = $event.map((tag) => tag.name);
      if (this.schema[val]) {
        this.schema[val].value = selestedTagsString;
      } else {
        console.log('changeValueForString');
      }
    },
    async mapData(data, schema) {
      if (schema.isRemapData != false) {
        console.log(schema, 'datadata', data);
        const updatedStructure = JSON.parse(JSON.stringify(schema));

        for (const key in updatedStructure) {
          console.log(key, 'updatedStructure', updatedStructure);
          if (
            updatedStructure[key].displayType === 'profile-image-upload' &&
            Array.isArray(data.attachment) &&
            data.attachment.length > 0
          ) {
            const profileInfo = data?.attachment?.find(
              (ele) =>
                ele?.['@type'] === 'profile' ||
                ele?.['attachmentType'] === 'profile'
            );
            console.log(profileInfo, 'schema.displayType', schema.displayType);
            if (profileInfo) {
              updatedStructure[key].others.profilePicUrl =
                profileInfo.url || null;
              updatedStructure[key].others.profileName =
                profileInfo.name || null;
              if (
                profileInfo.name != null &&
                profileInfo.name != undefined &&
                profileInfo.name != ''
              ) {
                const url = await UPCAxiosInstance.get(
                  `/gcpupload/files/${profileInfo.name}`,
                  { responseType: 'arraybuffer' }
                );
                this.profilePicUrlBufferData = await this.bufferToDataURL(
                  url.data
                );
                console.log('obj.url obj.url', url.data);
                this.$emit('profile-buffer-data', this.profilePicUrlBufferData);
                this.$emit('profile-name', profileInfo.name);
                console.log(
                  this.profilePicUrlBufferData,
                  'this.profilePicUrlBufferData'
                );
              }
            }
          }

          const field = updatedStructure[key];
          const labelKey = field?.labels?.en?.label;

          let value = data[key] ?? data[labelKey];

          console.log('valuevalue', value);

          for (const dataKey in data) {
            if (Array.isArray(data[dataKey])) {
              if (key == 'phoneNumber' && data.contactMedium) {
                console.log(key, 'Array.isArray', data[dataKey]);
                const matchedItem = data['contactMedium'].find((item) =>
                  Object.values(item).some(
                    (val) => val?.toString().toLowerCase() === key.toLowerCase()
                  )
                );
                const phoneObject = data[dataKey].find(
                  (item) => item.mediumType === 'phone'
                );
                console.log(
                  phoneObject,
                  'matchedItem',
                  matchedItem,
                  phoneObject?.characteristic?.phoneNumber,
                  phoneObject?.characteristic?.phoneNumber?.value
                );

                value = phoneObject?.characteristic?.phoneNumber?.value || '';
                break;
              }
              if (key == 'email' && data.contactMedium) {
                console.log(key, 'Array.isArray', data[dataKey]);
                const matchedItem = data[dataKey].find((item) =>
                  Object.values(item).some(
                    (val) => val?.toString().toLowerCase() === key.toLowerCase()
                  )
                );
                console.log('matchedItem', matchedItem);
                if (matchedItem) {
                  value = matchedItem?.characteristic?.emailAddress || '';
                  break;
                }
              }
              if (key == 'telephoneNumber' && data.contactMedium) {
                console.log(key, 'Array.isArray', data[dataKey]);
                const matchedItem = data[dataKey]?.find((item) =>
                  Object.values(item).some(
                    (val) => val?.toString().toLowerCase() === key.toLowerCase()
                  )
                );
                const phoneObject = data[dataKey].find(
                  (item) => item.mediumType === 'telephone'
                );
                console.log(phoneObject, 'matchedItem', matchedItem);

                value = phoneObject.characteristic.telephoneNumber.value || '';
                break;
              }
              const matchedItem = data?.[dataKey]?.find((item) => {
                if (item) {
                  console.log(key, 'item to check', item);
                  return Object.values(item).some(
                    (val) => val?.toString().toLowerCase() === key.toLowerCase()
                  );
                }
                return false;
              });
              if (
                matchedItem &&
                (updatedStructure[key].value == '' ||
                  updatedStructure[key].value == null ||
                  updatedStructure[key].value == undefined)
              ) {
                console.log(schema[key], 'matchedItem', matchedItem);
                value =
                  matchedItem.value ||
                  matchedItem.link ||
                  matchedItem.name ||
                  '';
                break;
              }
            }
          }
          console.log('console.log', updatedStructure[key]);
          updatedStructure[key] = {
            ...field,
            value:
              value !== undefined && value !== '' && value !== null
                ? value
                : schema[key].value !== undefined
                ? schema[key].value
                : '',
          };
          if (updatedStructure[key].displayType === 'siteaddress' && updatedStructure[key].value != '') {
            this.characteristicpreval = updatedStructure[key].value;
            console.log(
              updatedStructure[key],
              'this.characteristicpreval',
              this.characteristicpreval
            );
          }
        }
        console.log('this.characteristicprevalval', updatedStructure);
        for (const key in updatedStructure) {
          if (
            !(key in data) &&
            updatedStructure[key].system != true &&
            updatedStructure[key].show &&
            updatedStructure[key].displayType !== null
          ) {
            const field = updatedStructure[key];
            data[key] = field.value || '';
          }
          if (
            updatedStructure[key].value == null ||
            updatedStructure[key].value == '' ||
            updatedStructure[key].value == undefined ||
            (typeof updatedStructure[key].value === 'object' &&
              Object.keys(updatedStructure[key].value).length === 0 &&
              updatedStructure[key].options.length > 0 &&
              updatedStructure[key].options)
          ) {
            const value = (updatedStructure[key].options || []).filter((i) => {
              return i.default == true || i.default == 'true';
            });
            if (value[0]?.value || value[0]?.name) {
              console.log(
                updatedStructure[key],
                'updatedStructure[key]',
                value
              );
              updatedStructure[key].value = value[0]?.value
                ? value[0]?.value
                : value[0]?.name;
            }
            console.log(
              'newLog',
              updatedStructure[key],
              updatedStructure[key].value,
              key
            );
          }
          if (updatedStructure[key]?.displayType == 'multiselect') {
            const multiselectPrevalue = updatedStructure[key]?.value || [];

            if (
              Array.isArray(updatedStructure[key]?.options) &&
              Array.isArray(multiselectPrevalue)
            ) {
              updatedStructure[key].options = updatedStructure[key].options.map(
                (itemA) => {
                  const matchingItem = multiselectPrevalue.find(
                    (itemB) => itemB?.name === itemA?.name
                  );
                  return matchingItem ? matchingItem : itemA;
                }
              );
            } else {
              console.error('Options for multi select', key);
            }
          }
        }
        this.orginalDataPayload = JSON.parse(JSON.stringify(data));
        this.copyOfPayload = JSON.parse(JSON.stringify(data));
        console.log(data, 'updatedStructure for mapData', updatedStructure);
        this.updateMultiselectKey += 1;
        this.schema = JSON.parse(JSON.stringify(updatedStructure));
        this.$emit('updated-schema', this.schema);
        this.updateMultiselectKey += 1;
        console.log(
          data,
          'updatedStructure for if after schema',
          updatedStructure
        );
      } else {
        const updatedStructure = JSON.parse(JSON.stringify(schema));
        for (const key in updatedStructure) {
          if (
            !(key in data) &&
            updatedStructure[key].system != true &&
            updatedStructure[key].show &&
            updatedStructure[key].displayType !== null
          ) {
            const field = updatedStructure[key];
            data[key] = field.value || '';
          }
          if (
            updatedStructure[key].value == null ||
            updatedStructure[key].value == '' ||
            updatedStructure[key].value == undefined ||
            (typeof updatedStructure[key].value === 'object' &&
              Object.keys(updatedStructure[key].value).length === 0 &&
              updatedStructure[key].options)
          ) {
            const value = updatedStructure[key].options.filter((i) => {
              return i.default == true || i.default == 'true';
            });
            if (value[0]?.value || value[0]?.name) {
              console.log(
                updatedStructure[key],
                'updatedStructure[key]',
                value
              );
              updatedStructure[key].value = value[0]?.value
                ? value[0]?.value
                : value[0]?.name;
            }
            console.log(
              'newLog',
              updatedStructure[key],
              updatedStructure[key].value,
              key
            );
          }
          if (updatedStructure[key]?.displayType == 'multiselect') {
            const multiselectPrevalue = updatedStructure[key]?.value || [];

            if (
              Array.isArray(updatedStructure[key]?.options) &&
              Array.isArray(multiselectPrevalue)
            ) {
              updatedStructure[key].options = updatedStructure[key].options.map(
                (itemA) => {
                  const matchingItem = multiselectPrevalue.find(
                    (itemB) => itemB?.name === itemA?.name
                  );
                  return matchingItem ? matchingItem : itemA;
                }
              );
            } else {
              console.error('Options for multi select', key);
            }
          }
        }
        delete schema.isRemapData;
        this.orginalDataPayload = JSON.parse(JSON.stringify(data));
        this.copyOfPayload = JSON.parse(JSON.stringify(data));
        console.log(data, 'updatedStructure else in mapData', updatedStructure);
        this.updateMultiselectKey += 1;
        this.schema = JSON.parse(JSON.stringify(updatedStructure));
        this.updateMultiselectKey += 1;
      }
      this.orginalDataPayload = JSON.parse(JSON.stringify(data));
      this.copyOfPayload = JSON.parse(JSON.stringify(data));
    },
    async processFields(fields) {
      const processedFields = { ...fields };

      const promises = Object.entries(processedFields).map(
        async ([key, field]) => {
          if (key != 'isRemapData') {
            if(field?.options?.length>0 ){
               return
            }
            else if (!field.optionRef || field.optionRef === 'referenceDataName') {
              console.log(processedFields[key], 'isRemapDataisRemapData', key);
              processedFields[key] = { ...field, options: [] };
              return;
            }

            try {
              const { data } = await DMNAxiosInstance.get(
                `mapRef/collection/${field.optionRef}`
              );
              let dropdownData = data?.data || [];

              if (field.displayType === 'status') {
                dropdownData = dropdownData.filter((ele) =>
                  this.statusList.includes(ele.name)
                );
              } else if (field.optionRef === 'tagsList') {
                dropdownData = dropdownData.filter(
                  (ele) => ele.module === this.module.code
                );
              }

              if (field.displayType === 'multiselect') {
                dropdownData.forEach((item) => {
                  item.checked = false;
                });
              }
              if (field.displayType === 'user') {
                console.log('process fields called', field.others.userRole);

                let users = await this.getUserRoleOptions(
                  field.others.userRole
                );
                dropdownData = users;
                dropdownData.map((i) => {
                  i.newRole = i.role[0].name;
                });
              }

              processedFields[key] = {
                ...field,
                options: Array.isArray(dropdownData) ? dropdownData : [],
              };
              // this.isFuncOver = true
            } catch (error) {
              console.error(`Error processing field: ${key}`, error);
              processedFields[key] = { ...field, options: [] };
            }
          }
        }
      );

      await Promise.all(promises);

      console.log(
        this.copyOfPayload,
        'Processed fields:',
        processedFields,
        this.isRemapData
      );

      this.schema = JSON.parse(JSON.stringify(processedFields));

      if (this.orginalDataPayload && this.schema?.isRemapData != false) {
        await this.mapData(this.orginalDataPayload, this.schema);
      }
    },
    async mapSchemaToData(ogData, schema) {
      let data = JSON.parse(JSON.stringify(ogData));
      console.log(schema, 'mapSchemaToData for schema', data);
      if(data && schema){
      try {
        for (let key in schema) {
          const schemaField = schema[key];
          const schemaValue = schemaField.value || '';
          // console.log(data.attachment, 'sakthiv');
          if (schemaField.displayType === 'profile-image-upload') {
            data.attachment =
              data.attachment != '' && data.attachment != undefined
                ? data.attachment
                : [];
            const existingProfile = data?.attachment?.find(
              (ele) =>
                ele?.['@type'] === 'profile' ||
                ele?.['attachmentType'] === 'profile'
            );
            if (existingProfile) {
              Object.assign(existingProfile, schemaField.value);
            } else {
              data.attachment.push(schemaField.value);
            }
            if (data.attachment && Array.isArray(data.attachment)) {
              data.attachment = data?.attachment?.filter(
                (item) => item !== null && item !== '' && item !== undefined
              );
            }
          }
          if (
            key in data ||
            (schemaField.labels?.en?.label &&
              schemaField.labels.en.label in data)
          ) {
            const dataKey = key in data ? key : schemaField.labels.en.label;
            data[dataKey] = schemaValue;
            console.log(schemaField, 'schemaValue', schemaValue);
          } else {
            for (let dataKey in data) {
              if (Array.isArray(data[dataKey])) {
                // const matchingItem = data[dataKey].find((item) =>
                //   Object.values(item).some(
                //     (value) =>
                //       value?.toString().toLowerCase() === key.toLowerCase()
                //   )
                // );
                const matchingItem = data[dataKey]?.find((item) => {
                  if (!item) return false;
                  Object.values(item)?.some((keyName, value) => {
                    if (
                      keyName == 'phone' &&
                      key.toLowerCase() == 'phonenumber'
                    ) {
                      const phoneObject = data[dataKey].find(
                        (item) => item.mediumType === 'phone'
                      );
                      phoneObject.characteristic.phoneNumber.value =
                        schema[key]?.value;
                      console.log('phone if');
                    }
                    if (keyName == 'email' && key.toLowerCase() == 'email') {
                      const phoneObject = data[dataKey].find(
                        (item) => item.mediumType === 'email'
                      );
                      phoneObject.characteristic.emailAddress =
                        schema[key]?.value;
                      console.log('phone if email');
                    }
                    if (
                      keyName == 'telephone' &&
                      key.toLowerCase() == 'telephonenumber'
                    ) {
                      const phoneObject = data[dataKey].find(
                        (item) => item.mediumType === 'telephone'
                      );
                      phoneObject.characteristic.telephoneNumber.value =
                        schema[key].value;
                      console.log('phone if telephone');
                    }
                    if (
                      keyName &&
                      key &&
                      String(keyName).toLowerCase() ===
                        String(key).toLowerCase() &&
                      Array.isArray(data[dataKey])
                    ) {
                      const foundObject = data[dataKey].find((item) =>
                        Object.values(item).some(
                          (value) =>
                            String(value).toLowerCase() ===
                            String(key).toLowerCase()
                        )
                      );
                      if (foundObject) {
                        foundObject.value = schemaValue;
                      }

                      return (
                        value?.toString().toLowerCase() ==
                        key.toString().toLowerCase()
                        // ||
                        // value?.toString().toLowerCase() == schema[key]?.displayType?.toLowerCase() ||
                        // key?.toString().toLowerCase() == schema[key]?.displayType?.toLowerCase()
                      );
                    }
                  });
                  if (matchingItem) {
                    if ('value' in matchingItem) {
                      console.log(
                        schemaValue,
                        "if ('value' in matchingItem)",
                        matchingItem
                      );
                      matchingItem.value = schemaValue;
                    } else {
                      // console.log("matchingItem.mediumType ",matchingItem.mediumType )
                      if (matchingItem.mediumType.trim() == 'email') {
                        matchingItem.characteristic.emailAddress = schemaValue;
                      }
                      if (matchingItem.mediumType.trim() == 'phone') {
                        matchingItem.characteristic.phoneNumber.value =
                          schemaValue;
                      }
                      if (matchingItem.mediumType.trim() == 'telephone') {
                        matchingItem.characteristic.telephoneNumber.value =
                          schemaValue;
                      } else {
                        console.log(schemaValue, 'else', matchingItem);
                        matchingItem.value = schemaValue;
                      }
                    }
                  }
                });
              }
            }
          }
          if (data.attachment && Array.isArray(data.attachment)) {
            data.attachment = data.attachment.filter(
              (item) => item !== null && item !== '' && item !== undefined
            );
          }
        }
      } catch (error) {
        console.log('Error from mapSchemaToData', error);
      }
      if (schema.isRemapData == false) {
        delete schema.isRemapData;
        this.mapData(data, schema);
      }
      this.$emit('edited-payload', data);
      console.log('mapSchemaToData', data);
    }
    },
    async readFile(data) {
      // Convert binary string to Uint8Array
      const binaryString = data.data;
      const byteArray = new Uint8Array(binaryString.length);

      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }

      // Create a Blob with the correct MIME type
      const fileBlob = new Blob([byteArray], { type: data.properties.type });

      // Append to FormData
      let formData = new FormData();
      formData.append('file', fileBlob, data.properties.name);

      // Log the Blob size for debugging
      console.log('Blob size:', fileBlob.size);

      // Send the FormData using Axios
      try {
        let res = await DMNAxiosInstance.post(
          `/import/extract_headers`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        console.log('Response:', res.data);
      } catch (error) {
        console.error(
          'Error uploading file:',
          error.response?.data || error.message
        );
      }
    },
    // Get appropriate label
    getLabel(field, key) {
      return field.labels[this.selectedLanguageCode].label || key;
    },

    // Determine field class based on layout requirements
    getFieldClass(field) {
      if (
        field.displayType === 'textarea' ||
        field.displayType === 'texteditor'
      ) {
        return 'form-field textarea-field';
      }
      if (field.displayType === 'radio' || field.displayType === 'checkbox') {
        return 'form-field radio-field';
      }
      if (field.displayType === 'address') {
        return 'form-field address-field';
      }
      if (field.displayType === 'siteaddress') {
        return 'form-field site-address-field';
      }
      if (field.specialLayout === 'single-column') {
        return 'form-field single-column';
      }
      if (field.displayType === 'header') {
        return 'form-field two-column';
      }
      if (
        field?.others?.gridField !== undefined &&
        field?.others?.gridField !== ''
      ) {
        return `form-field gf-${field.others.gridField.slice(-1)} grid-field-${
          field.others.gridField
        }`;
      }
      return 'form-field';
    },
  },
};
</script>

<style lang="scss">
.data {
  position: relative;
}
.form-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* Two equal columns */
  /* gap: 16px;  */
  /* Space between fields */
  padding: 20px;
  column-gap: 16px;

  .form-field {
    display: flex;
    flex-direction: column;
    grid-column: span 6;
    grid-row: span 2;
  }

  .grid-field-2,
  .grid-field-3 {
    grid-column: span 5;
  }
  .textarea-field {
    grid-row: span 4;
    grid-column: span 6; /* Spans across both columns */
  }
  .grid-field-1 {
    grid-column: span 1;
  }
  .grid-field-2,
  .grid-field-3 {
    grid-column: span 5;
  }
}

.form-field.label {
  margin-bottom: 8px; /* Space between label and input */
  font-weight: bold;
  font-size: 14px;
}

.form-field input,
.form-field textarea,
.form-field select {
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
}

.textarea-field {
  grid-row: span 4;
  // grid-column: span 6; /* Spans across both columns */
}

.address-field {
  grid-row: span 8; /* Spans across both columns */
}
.site-address-field {
  grid-row: span 10; /* Spans across both columns */
}
.single-column {
  grid-column: span 3; /* Spans one column */
}

.tablet-grid-field-2,
.tablet-grid-field-3 {
  grid-column: span 3;
}
.special-field {
  grid-column: span 3; /* Special layout can adjust grid-column */
}
.radio-field {
  grid-column: span 3;
  grid-row: span 2;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.radio-group {
  display: flex;
  flex-direction: column;
}

.radio-option {
  display: flex;
  align-items: center;
  gap: 8px;
}

.two-column {
  grid-column: span 3;
}
/* Tablet and smaller: Single column layout */
// @media (max-width: 768px) {
//   .form-container {
//     grid-template-columns: 1fr; /* Single column */
//   }
//   .two-column,
//   .two-column-field,
//   .textarea-field,
//   .radio-field {
//     grid-column: span 3; /* Full width */
//   }
// }
.form-container-small {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Two equal columns */
  /* gap: 16px;  */
  /* Space between fields */
  padding: 20px;
  column-gap: 16px;

  .form-field {
    display: flex;
    flex-direction: column;
    grid-column: span 3;
    grid-row: span 2;
  }
  .grid-field-1 {
    grid-column: span 1;
  }

  .grid-field-2,
  .grid-field-3 {
    grid-column: span 3;
  }
}

.form-container-modal {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* Two equal columns */
  /* gap: 16px;  */
  /* Space between fields */
  padding: 20px;
  column-gap: 16px;

  .form-field {
    display: flex;
    flex-direction: column;
    grid-column: span 3;
    grid-row: span 2;
  }
  .grid-field-1 {
    grid-column: span 1;
  }

  .grid-field-2,
  .grid-field-3 {
    grid-column: span 2 !important;
  }
  .grid-field-3 {
    grid-row: span 1 !important;
  }
  .textarea-field {
    grid-row: span 4 !important;
  }
  .site-address-field {
    grid-row: span 10;
  }
}
.form-container-mixed {
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* Two equal columns */
  // gap: 20px;
  /* Space between fields */
  padding: 20px;
  column-gap: 16px;

  .status-component,
  .profile-picture-component {
    margin-bottom: 20px;
  }

  .form-field {
    display: flex;
    flex-direction: column;
    grid-column: span 12;
    grid-row: span 2;
  }
  .grid-field-1 {
    grid-column: span 1;
  }

  .grid-field-2,
  .grid-field-3 {
    grid-column: span 11 !important;
  }
  .grid-field-3 {
    grid-row: span 1 !important;
  }
  .site-address-field {
    grid-row: span 12;
  }
}
</style>
