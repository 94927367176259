<template>
    <section class="layout-seperation">
        <section class="layout-left-panel">
            <div class="left-panel-container" @click="backToSettings">
              <i class="icon-arrow-left-regular"></i>
              <p>Back</p>
            </div>
        </section>
        <section class="layout-right-panel"> 
            <section class="right-panel-container">
                <section class="header">
                   <section class="header-left-section">
                       <p class="title">Product offering</p>
                       <p class="sub-title">Map the offering workflow</p>
                   </section>
                </section>
                <section class="content">
                    <div class="card-holder">
        <div class="cards-container">
          <hlx-card
            v-for="(card, index) in card_data"
            :key="index"
            class="custom-card"
            :seperator="false"
            :card-title="false"
            :card-footer="false"
            :hover="true"
            height="210"
            :class="{
              '--disable-hover':
                card.card_name && card.card_name.toLowerCase() == 'api',
            }"
            @click="moveTo(card.route)"
          >
            <template #card-body>
              <div
                style="padding: 0 15px"
                :class="{
                  '--disable-hover':
                    card.card_name && card.card_name.toLowerCase() == 'api',
                }"
              >
              <div class="circleInCard">
                <i :class=card.icon></i>
              </div>
                <!-- <img :src="getImgUrl(card.card_body)" :alt="card.card_body" /> -->
                <p class="card-head">
                  <b>{{ card.card_name }}</b>
                </p> 
                <p
                  class="card-desc copy-click"
                  data-tooltip-text="Click to copy"
                  data-tooltip-text-copied="✔ Copied to clipboard"
                >
                  {{ card.card_desc }}
                </p>
              </div>
            </template>
            <template #card-footer>
              {{ card.card_footer }}
            </template>
          </hlx-card>
        </div>
      </div>     
                </section>
            </section>
        </section>
    </section>
</template>

<script>
export default{
   data(){
    return{
    card_data:[
        {
          card_body: "users.svg",
          route: "dependentProductsTable",
          card_name: "Dependent products",
          card_desc: "Configure your products selection flow",
          icon:"icon-bezier-regular"
        },
        ]
      }
   },
   methods:{
    backToSettings(){
        this.$router.push('/settings')
    },    
    getImgUrl(pet) {
      //
      // var images = require.context("../assets/", false, /\.svg$/);
      //
      return require("../../assets/images/" + pet);
    },
    moveTo(route) {
            this.$router.push(`/settings/automation/${route}`);
    },
   }
}
</script>
<style lang="scss">
.circleInCard {
  width: 70px;
  height: 70px;
  background-color:#D8EDFC;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0065B2;
  font-size: 30px;
}
</style>