<template>
    <div style="padding:20px;overflow-y:scroll">
        <hierarchyComponent :data="hierarchyData" :module="'PCM'" @drag-and-drop="manipulatedPayload"></hierarchyComponent>
    </div>
</template>
<script>
    import hierarchyComponent from '@/components/CM/addAccountHierarchyComponent.vue';
    export default {
    components: {
        hierarchyComponent
    },
    data()
    {
        return{
            module:'PCM',
            // hierarchyData:[],
            hierarchyData:[
                {
                "lastUpdate": "2024-02-19T01:47:16.629Z",
                "type": "",
                "isRoot": true,
                "name": "Mobile",
                "subCategory": [
                {
                "lastUpdate": "2024-02-19T01:47:16.629Z",
                "type": "",
                "isRoot": false,
                "name": "Redmi",
                "subCategory": [
                {
                "lastUpdate": "2024-02-19T01:47:16.629Z",
                "type": "",
                "isRoot": false,
                "name": "Redmi A1",
                "subCategory": [],
                "productOffering": [],
                "pricingType": [],
                "_id": "65d2b6c0c43e6e3975a5af90",
                "__v": 0
                },
                {
                "lastUpdate": "2024-02-19T01:47:16.629Z",
                "type": "",
                "isRoot": false,
                "name": "Redmi A2",
                "subCategory": [],
                "productOffering": [],
                "pricingType": [],
                "_id": "65d2b6c0c43e6e3975a5af10",
                "__v": 0
                  }
                ],
                "productOffering": [],
                "pricingType": [],
                "_id": "65d2b6c0c43e6e3975a5af70",
                "__v": 0
                 },
                 {
                "lastUpdate": "2024-02-19T01:47:16.629Z",
                "type": "",
                "isRoot": false,
                "name": "Apple",
                "subCategory": [],
                "productOffering": [],
                "pricingType": [],
                "_id": "65d2b6c0c43e6e3975a5af71",
                "__v": 0
                 }
                ],
                "productOffering": [],
                "pricingType": [],
                "_id": "65d2b6c0c43e6e3975a5af60",
                "__v": 0
                 },
                 {
                "lastUpdate": "2024-02-19T01:47:16.629Z",
                "type": "",
                "isRoot": true,
                "name": "Laptop",
                "subCategory": [
                {
                "lastUpdate": "2024-02-19T01:47:16.629Z",
                "type": "",
                "isRoot": false,
                "name": "Asus",
                "subCategory": [
                {
                "lastUpdate": "2024-02-19T01:47:16.629Z",
                "type": "",
                "isRoot": false,
                "name": "Rog matrix",
                "subCategory": [],
                "productOffering": [],
                "pricingType": [],
                "_id": "65d2b6c0c43e6e3975a5af64",
                "__v": 0
                },
                {
                "lastUpdate": "2024-02-19T01:47:16.629Z",
                "type": "",
                "isRoot": false,
                "name": "Rog crystal",
                "subCategory": [],
                "productOffering": [],
                "pricingType": [],
                "_id": "65d2b6c0c43e6e3975a5af65",
                "__v": 0
                  }
                ],
                "productOffering": [],
                "pricingType": [],
                "_id": "65d2b6c0c43e6e3975a5af62",
                "__v": 0
                 },
                 {
                "lastUpdate": "2024-02-19T01:47:16.629Z",
                "type": "",
                "isRoot": false,
                "name": "Mac",
                "subCategory": [],
                "productOffering": [],
                "pricingType": [],
                "_id": "65d2b6c0c43e6e3975a5af63",
                "__v": 0
                 }
                ],
                "productOffering": [],
                "pricingType": [],
                "_id": "65d2b6c0c43e6e3975a5af61",
                "__v": 0
            }
        ],

        }
    },
    methods:{
        manipulatedPayload(data) {
        console.log(data,'ddddddddd');
        this.hierarchyData = data
    },
    }
}
</script>