<template>
  <div>
    <hlx-input
      v-model:value="payload.characteristic.address1"
      :read-only="view"
      :pre-val="payload.characteristic.address1"
      type="location"
      :required="required"
      :label-value="'Address'"
      :label-animation="true"
      :display-error="true"
      :custom-error-message="'Please fill the field'"
      :location-data-prop="locationData"
      @address-data="addressData"
      @at-input="getLocations"
    ></hlx-input>
    <hlx-input
      v-model:value="payload.characteristic.unit"
      :read-only="view"
      :pre-val="payload.characteristic.unit"
      type="text"
      :label-value="'Unit/Appartment'"
      :label-animation="true"
      :display-error="true"
      :custom-error-message="'Please fill the field'"
      @at-input="canEmitTrigger"
    ></hlx-input>
    <div
      class="new-address-fields"
      style="display: flex; justify-content: space-between; gap: 12px; margin-bottom: 20px"
    >
    <span style="width: 50%;">
      <hlx-input
        v-model:value="payload.characteristic.city"
        :read-only="view"
        :pre-val="payload.characteristic.city"
        :label-value="'City'"
        :label-animation="true"
        :display-error="customnameerror"
        :custom-error="customnameerror"
        :custom-error-message="'Please fill the field'"
        @at-input="canEmitTrigger"
      />
      </span>
      <span style="width: 50%;">
      <hlx-input
        v-model:value="payload.characteristic.state"
        :read-only="view"
        :pre-val="payload.characteristic.state"
        :label-value="'State'"
        :label-animation="true"
        :display-error="customnameerror"
        :custom-error="customnameerror"
        :custom-error-message="'Please fill the field'"
        @at-input="canEmitTrigger"
      />
      </span>
    </div>
    <div
      class="new-address-fields"
      style="display: flex; justify-content: space-between; gap: 12px"
    >
    <span style="width: 50%;">
      <hlx-input
        v-model:value="payload.characteristic.country"
        :read-only="view"
        :pre-val="payload.characteristic.country"
        :label-value="'Country'"
        :label-animation="true"
        :display-error="customnameerror"
        :custom-error="customnameerror"
        :custom-error-message="'Please fill the field'"
        @at-input="canEmitTrigger"
      />
      </span>
      <span style="width: 50%;">
      <hlx-input
        v-model:value="payload.characteristic.postalCode"
        :read-only="view"
        :pre-val="payload.characteristic.postalCode"
        :label-value="'Postal code'"
        :label-animation="true"
        :display-error="customnameerror"
        :custom-error="customnameerror"
        :custom-error-message="'Please fill the field'"
        @at-input="canEmitTrigger"
      />
      </span>
    </div>
    <!-- <input
      ref="address1Field"
      placeholder="Address line 1"
    />
    <br />
    <input
      ref="address2Field"
      placeholder="Address line 2"
    />
    <input
      ref="unitField"
      placeholder="Unit number"
    />
    <input
      ref="cityField"
      placeholder="City"
    />
    <input
      ref="stateOrProvinceField"
      placeholder="State or Province"
    />
    <input
      ref="countryField"
      placeholder="Country"
    />
    <input
      ref="postalField"
      placeholder="Postal Code"
    /> -->
    <!-- Add other form fields as needed -->
  </div>
</template>

<script>
import {
  // DMNAxiosInstance,
  // MLTAxiosInstance,
  UPCAxiosInstance,
} from '@/config/axiosConfig';
// import axios from 'axios';

export default {
  props: {
    view: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    preVal: {
      type: Object,
      default: () => {
        return {
          mediumType: 'postalAddress',
          characteristic:{
          city: '',
          unit: '',
          country: '',
          state: '',
          postalCode: '',
          stateOrProvince: '',
          street1: '',
          street2: '',
          description: '',
          address1: '',
          default: true,
          '@type': '',
          }
        };
      },
    },
  },
  emits: ['addressData'],
  data() {
    return {
      canEmit: false,
      locationData: [],
      payload: {
        mediumType: 'postalAddress',
        characteristic: {
        city: '',
        unit: '',
        country: '',
        state: '',
        postalCode: '',
        stateOrProvince: '',
        street1: '',
        street2: '',
        description: '',
        address1: '',
        default: true,
        '@type': '',
        }
      },
      rawPayload: {}
    };
  },
  watch: {
    preVal: {
      handler(val) {
        this.rawPayload = JSON.parse(JSON.stringify(val));
        this.payload = JSON.parse(JSON.stringify(val));
        this.canEmit = false
      },
      immediate: true,
      deep: true,
    },
    payload: {
      handler(val) {console.log('assume',this.payload);
        this.payload.characteristic.description =
          `${val.characteristic.address1 !== '' && val.characteristic.address1 !== undefined ? val.characteristic.address1 : ''}`+
          `${val.characteristic.unit !== '' && val.characteristic.unit !== undefined ? ', ' + val.characteristic.unit : ''}`+
          `${val.characteristic.city !== '' && val.characteristic.city !== undefined ? ', ' + val.characteristic.city: ''}`+
          `${val.characteristic.state !== '' && val.characteristic.state !== undefined ? ', ' + val.characteristic.state : ''}` +
         `${val.characteristic.country !== '' && val.characteristic.country !== undefined ? ', ' + val.characteristic.country : ''}` +
          `${val.characteristic.postalCode !== '' && val.characteristic.postalCode !== undefined ? ', ' + val.characteristic.postalCode : ''}`;
        if (this.canEmit) {
          this.$emit('addressData', val);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {},
  methods: {
    canEmitTrigger() {
      this.canEmit = true
    },
    deepObjectCompare(obj1, obj2) {
      // Check if both inputs are objects
      if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return obj1 !== obj2;
      }

      // Check if both inputs are arrays
      if (Array.isArray(obj1) && Array.isArray(obj2)) {
        if (obj1.length !== obj2.length) {
          return true;
        }

        for (let i = 0; i < obj1.length; i++) {
          if (this.deepObjectCompare(obj1[i], obj2[i])) {
            return true;
          }
        }

        return false;
      }

      // Check if both inputs have the same keys
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
        return true;
      }

      // Check each key-value pair recursively
      for (const key of keys1) {
        if (
          !keys2.includes(key) ||
          this.deepObjectCompare(obj1[key], obj2[key])
        ) {
          return true;
        }
      }

      return false;
    },
    async getLocations() {
      this.canEmit = true
      const input = this.payload.characteristic.address1;
      let result = [];
      try {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        const response = await UPCAxiosInstance.get(
          `/address/suggestionlist?input=${input}`
        );
        result = response.data;
        console.log(result);
      } catch (error) {
        console.error('Error fetching autocomplete data:', error.message);
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.locationData = result;
      return result;
    },
    async addressData(val) {
      this.canEmit = true
      let address_components = await UPCAxiosInstance.get(
        `/address/details?place_id=${val.place_id}`
      );
      console.log(address_components);
      let address1 = '';
      let postcode = '';
      let country = '';
      let unit = '';
      let city = '';
      let state_or_province = '';
      for (const component of address_components.data.result
        .address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`;
            break;
          }
          case 'route': {
            address1 += component.short_name;
            break;
          }
          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`;
            break;
          }
          case 'postal_code_suffix': {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }
          case 'postal_code_prefix': {
            postcode = `${component.long_name}${
              postcode !== '' ? '-' + postcode : ''
            }`;
            break;
          }
          case 'country': {
            country = `${component.long_name}`;
            break;
          }
          case 'administrative_area_level_1': {
            state_or_province = `${component.long_name}`;
            break;
          }
          case 'administrative_area_level_2': {
            city = `${component.long_name}`;
            break;
          }
          case 'administrative_area_level_3': {
            city = `${city !== '' ? city + ', ' : ''}${component.long_name}`;
            break;
          }
          case 'sublocality_level_1': {
            city = `${component.long_name}`;
            break;
          }
          case 'subpremise': {
            unit = `${component.long_name}`;
            break;
          }
          // Add more cases for other address components as needed
        }
      }

      this.payload.characteristic.address1 = address1;
      this.payload.characteristic.postalCode = postcode;
      this.payload.characteristic.unit = unit;
      this.payload.characteristic.city = city;
      this.payload.characteristic.state = state_or_province;
      this.payload.characteristic.country = country;
    },
    initAutocomplete() {
      const address1Field = this.$refs.address1Field;
      const address2Field = this.$refs.address2Field;
      const cityField = this.$refs.cityField;
      const unitField = this.$refs.unitField;
      const stateOrProvinceField = this.$refs.stateOrProvinceField;
      const countryField = this.$refs.countryField;
      const postalField = this.$refs.postalField;
      address2Field;
      postalField;
      countryField;
      cityField;
      unitField;
      stateOrProvinceField;
      this.autocomplete = new window.google.maps.places.Autocomplete(
        address1Field,
        {
          componentRestrictions: { country: ['us', 'ca', 'in'] },
          fields: ['address_components', 'geometry'],
          types: ['address'],
        }
      );

      address1Field.focus();

      this.autocomplete.addListener('place_changed', this.fillInAddress);
    },
    fillInAddress() {
      const place = this.autocomplete.getPlace();
      let address1 = '';
      let postcode = '';
      let country = '';
      // let unit = '';
      let city = '';
      let state_or_province = '';
      console.log(place.address_components);
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`;
            break;
          }
          case 'route': {
            address1 += component.short_name;
            break;
          }
          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`;
            break;
          }
          case 'postal_code_suffix': {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }
          case 'postal_code_prefix': {
            postcode = `${component.long_name}-${postcode}`;
            break;
          }
          case 'country': {
            country = `${component.long_name}`;
            break;
          }
          case 'administrative_area_level_1': {
            state_or_province = `${component.long_name}`;
            break;
          }
          case 'administrative_area_level_2': {
            city = `${component.long_name}`;
            break;
          }
          case 'administrative_area_level_3': {
            city = `${city !== '' ? city + ', ' : ''}${component.long_name}`;
            break;
          }
          case 'sublocality_level_1': {
            city = `${component.long_name}`;
            break;
          }
          // case 'subpremise': {
          //   unit = `${component.long_name}`;
          //   break;
          // }
          // Add more cases for other address components as needed
        }
      }

      this.payload.characteristic.description = address1;
      this.payload.characteristic.postalCode = postcode;
      // this.$refs.unitField.value = unit;
      this.payload.characteristic.city = city;
      this.payload.characteristic.state = state_or_province;
      this.payload.characteristic.country = country;
      // this.$refs.address2Field.focus();
    },
  },
};
</script>
