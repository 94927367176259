<!-- eslint-disable vue/no-v-html -->
<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-email
    :key="componentKey"
    :recipient="emailData"
    :type="'individual'"
    :subject-content="AIEmailPayload.subject"
    :body-content="AIEmailPayload.body"
    :show="showEmail"
    :load-attachmnet="loadAttachmnet"
    
    :default-to="defaultTo"
    @close="showEmail = false"
    @upload-data="valuemail"
    @updated-email-content="handleEmailContentUpdate"
    @attachments="emailAttachment"
    @attachment-name="attachmentName"
  >
    <!-- Container for AI generator tool bars -->
    <div id="ai-toolbar" class="ai-toolbar">
      <!-- Regenerate icon -->
      <i
        v-if="AIChoicesForDescription.length > 1"
        class="icon-arrows-repeat-regular --regenerate-icon"
        @click="handleRegenerateAIContent('regenerate')"
      ></i>
      <!-- Tool bar generator button -->
      <!-- Prompt toolbar -->
      <div class="ai-prompt-toolbar">
        <hlx-input
          v-model:value="prompt"
          :label-animation="false"
          :custom-slot-prefix="true"
          :custom-slot-suffix="true"
          label-value="Instruct AI to compose an email"
          @at-enter="generateContentWithAI('generate using prompt', prompt)"
        >
          <template #custom-slot-prefix>
            <i class="icon-sparkle-regular"></i>
          </template>
          <template #custom-slot-suffix>
            <i
              class="icon-send-right-regular"
              :class="{
                '--disable': prompt?.trim() == '' || disableAIToolBarButton,
              }"
              @click.stop="
                generateContentWithAI('generate using prompt', prompt)
              "
            ></i>
          </template>
        </hlx-input>
      </div>
    </div>
  </hlx-email>
  <div
    v-if="interactionPayload?.interactionItem?.length === 0"
    class="cm-accounts-overview-component"
  >
    <img
      src="@/assets/images/addAccountInteraction.svg"
      alt=""
      style="width: 350px"
    />
    <br />
    <hlx-button
      v-if="mode === 'edit'"
      class="primary sm log-add-btn"
      @click="showAddContextMenu"
    >
      <span style="display: flex; align-items: center; gap: 4px">
        <i class="icon-note-text-square-regular"></i>
         <!-- New interaction -->
         <span v-if="staticData">{{ staticData?.newInteraction?.label[loginUserDetails]?.label }}</span>
      </span>
    </hlx-button>
    <hlx-context-menu
      :top="containertop1"
                  :display="'display'"
      :left="containerleft1"
      :conditionvalue="150"
      :conditiondown="2"
      :conditionup="-38"
      :options="add_log_options"
      :show="show_add_log_menu"
      @chosen="fromAddContextMenu"
      @close-context-menu="show_add_log_menu = false"
    ></hlx-context-menu>
  </div>
  <!-- <hlx-modal
    :modal-active="showAddEmailLogModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'340px'"
    :width="'650px'"
    :modal="true"
    @close="closeAddEmailLogModal"
  >
    <template #header>
      <div>Log an Email</div>
    </template>
    <template #content>
      <div class="modal-content add-call-modal">
        <span class="left-section">
          <div class="input-wrapper">
            <hlx-select
              :key="key"
              v-model:value="emailPayload.contact"
              :pre-value="emailPayload.contact"
              :options="contactList"
              prop-value="name"
              :placeholder-value="'Search contact'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <div class="input-wrapper">
            <hlx-select
              :key="key"
              v-model:value="emailPayload.direction"
              :pre-value="emailPayload.direction"
              :options="callTypeOptions"
              prop-value="name"
              :placeholder-value="'Email type'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <hlx-input
            :key="dobKey"
            v-model:value="emailPayload.date"
            :pre-val="emailPayload.date"
            :label-value="'Date and time'"
            :label-animation="true"
            type="date"
            icon="calendar-regular"
            icon-position="right"
            :display-error="true"
          ></hlx-input>
        </span>
        <span class="right-section">
          <hlx-input
            v-model:value="emailPayload.reason"
            :pre-val="emailPayload.reason"
            :label-value="'reason'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            v-model:value="emailPayload.body"
            :pre-val="emailPayload.body"
            :label-value="'Describe the Email...'"
            :label-animation="true"
            type="textarea"
            :required="false"
            :display-error="true"
            :custom-error="customnameerror"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
        </span>
      </div>
    </template>
    <template #footer>
      <hlx-button
        class="secondary sm"
        @click="closeAddEmailLogModal"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        @click="addNewEmailLog"
        >Add</hlx-button
      >
    </template>
  </hlx-modal> -->
  <!-- <hlx-modal
    :modal-active="showEditEmailLogModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'340px'"
    :width="'650px'"
    :modal="true"
    @close="closeEditEmailLogModal"
  >
    <template #header>
      <div>Edit Email Log</div>
    </template>
    <template #content>
      <div class="modal-content add-call-modal">
        <span class="left-section">
          <div class="input-wrapper">
            <hlx-select
              :key="key"
              v-model:value="emailPayload.contact"
              :pre-value="emailPayload.contact"
              :options="contactList"
              prop-value="name"
              :placeholder-value="'Search contact'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <div class="input-wrapper">
            <hlx-select
              :key="key"
              v-model:value="emailPayload.direction"
              :pre-value="emailPayload.direction"
              :options="callTypeOptions"
              prop-value="name"
              :placeholder-value="'Email type'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <hlx-input
            :key="dobKey"
            v-model:value="emailPayload.date"
            :pre-val="emailPayload.date"
            :label-value="'Date and time'"
            :label-animation="true"
            type="date"
            icon="calendar-regular"
            icon-position="right"
            :display-error="true"
          ></hlx-input>
        </span>
        <span class="right-section">
          <hlx-input
            v-model:value="emailPayload.reason"
            :pre-val="emailPayload.reason"
            :label-value="'reason'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            v-model:value="emailPayload.body"
            :pre-val="emailPayload.body"
            :label-value="'Describe the Email...'"
            :label-animation="true"
            type="textarea"
            :required="false"
            :display-error="true"
            :custom-error="customnameerror"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
        </span>
      </div>
    </template>
    <template #footer>
      <hlx-button
        class="secondary sm"
        @click="closeEditEmailLogModal"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        @click="addEditEmailLog"
        >Save</hlx-button
      >
    </template>
  </hlx-modal> -->
  <hlx-modal
    :modal-active="showDeleteEmailLogModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'190px'"
    :width="'380px'"
    :modal="true"
    @close="closeDeleteEmailLogModal"
  >
    <template #header>
      <div>
        <!-- Delete Email Log -->
        <span v-if="staticData">{{ staticData?.deleteEmailLog?.label[loginUserDetails]?.label }}</span>
      </div>
    </template>
    <template #content>
      <div class="modal-content">
        <!-- Are you sure you want to delete this interaction? -->
        <span v-if="staticData">{{ staticData?.cmInteractionModalMessage1?.label[loginUserDetails]?.label }}</span>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="closeDeleteEmailLogModal"
        >
        <!-- Cancel -->
        <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        @click="addDeleteEmailLog"
        >
        <!-- Delete -->
        <span v-if="staticData">{{ staticData?.delete?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
    </template>
  </hlx-modal>
  <hlx-modal
    :modal-active="showDeleteCallLogModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'190px'"
    :width="'380px'"
    :modal="true"
    @close="closeDeleteCallLogModal"
  >
    <template #header>
      <div>
        <!-- Delete Call Log -->
        <span v-if="staticData">{{ staticData?.deleteCallLog?.label[loginUserDetails]?.label }}</span>
      </div>
    </template>
    <template #content>
      <div class="modal-content">
        <!-- Are you sure you want to delete this interaction? -->
        <span v-if="staticData">{{ staticData?.cmInteractionModalMessage1?.label[loginUserDetails]?.label }}</span>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="closeDeleteCallLogModal"
        >
        <!-- Cancel -->
        <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        @click="addDeleteCallLog"
        >
        <!-- Delete -->
        <span v-if="staticData">{{ staticData?.delete?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
    </template>
  </hlx-modal>
  <hlx-modal
    :modal-active="showDeleteNoteLogModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'190px'"
    :width="'380px'"
    :modal="true"
    @close="closeDeleteNoteLogModal"
  >
    <template #header>
      <div>
        <!-- Delete Note -->
        <span v-if="staticData">{{ staticData?.deletNote?.label[loginUserDetails]?.label }}</span>
      </div>
    </template>
    <template #content>
      <div class="modal-content">
        <!-- Are you sure you want to delete this interaction? -->
        <span v-if="staticData">{{ staticData?.cmInteractionModalMessage1?.label[loginUserDetails]?.label }}</span>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="closeDeleteNoteLogModal"
        >
        <!-- Cancel -->
        <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        @click="addDeleteNoteLog"
        >
        <!-- Delete -->
        <span v-if="staticData">{{ staticData?.delete?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
    </template>
  </hlx-modal>
  <!-- <hlx-modal
    :modal-active="showAddCallLogModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'400px'"
    :width="'650px'"
    :modal="true"
    @close="closeAddCallLogModal"
  >
    <template #header>
      <div>Log a Call</div>
    </template>
    <template #content>
      <div class="modal-content add-call-modal">
        <span class="left-section">
          <div class="input-wrapper">
            <hlx-select
              :key="key"
              v-model:value="callPayload.contact"
              :pre-value="callPayload.contact"
              :options="contactList"
              prop-value="name"
              :placeholder-value="'Search contact'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <div class="input-wrapper">
            <hlx-select
              :key="key"
              v-model:value="callPayload.direction"
              :pre-value="callPayload.direction"
              :options="callTypeOptions"
              prop-value="name"
              :placeholder-value="'Call type'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <hlx-input
            v-model:value="callPayload.duration"
            :pre-val="callPayload.duration"
            :label-value="'Call duration'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            :key="dobKey"
            v-model:value="callPayload.date"
            :pre-val="callPayload.date"
            :label-value="'Date and time'"
            :label-animation="true"
            type="date"
            icon="calendar-regular"
            icon-position="right"
            :display-error="true"
          ></hlx-input>
        </span>
        <span class="right-section">
          <hlx-input
            v-model:value="callPayload.reason"
            :pre-val="callPayload.reason"
            :label-value="'reason'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            v-model:value="callPayload.body"
            :pre-val="callPayload.body"
            :label-value="'Describe the Call...'"
            :label-animation="true"
            type="textarea"
            :rows="7"
            :custom-error="customnameerror"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
        </span>
      </div>
    </template>
    <template #footer>
      <hlx-button
        class="secondary sm"
        @click="closeAddCallLogModal"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        @click="addNewCallLog"
        >Add</hlx-button
      >
    </template>
  </hlx-modal> -->
  <!-- <hlx-modal
    :modal-active="showEditCallLogModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'400px'"
    :width="'650px'"
    :modal="true"
    @close="closeEditCallLogModal"
  >
    <template #header>
      <div>Edit Call Log</div>
    </template>
    <template #content>
      <div class="modal-content add-call-modal">
        <span class="left-section">
          <div class="input-wrapper">
            <hlx-select
              :key="key"
              v-model:value="callPayload.contact"
              :pre-value="callPayload.contact"
              :options="contactList"
              prop-value="name"
              :placeholder-value="'Search contact'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <div class="input-wrapper">
            <hlx-select
              :key="key"
              v-model:value="callPayload.direction"
              :pre-value="callPayload.direction"
              :options="callTypeOptions"
              prop-value="name"
              :placeholder-value="'Call type'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <hlx-input
            v-model:value="callPayload.duration"
            :pre-val="callPayload.duration"
            :label-value="'Call duration'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            :key="dobKey"
            v-model:value="callPayload.date"
            :pre-val="callPayload.date"
            :label-value="'Date and time'"
            :label-animation="true"
            type="date"
            icon="calendar-regular"
            icon-position="right"
            :display-error="true"
          ></hlx-input>
        </span>
        <span class="right-section">
          <hlx-input
            v-model:value="callPayload.reason"
            :pre-val="callPayload.reason"
            :label-value="'reason'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            v-model:value="callPayload.body"
            :pre-val="callPayload.body"
            :label-value="'Describe the Call...'"
            :label-animation="true"
            type="textarea"
            :rows="7"
            :custom-error="customnameerror"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
        </span>
      </div>
    </template>
    <template #footer>
      <hlx-button
        class="secondary sm"
        @click="closeEditCallLogModal"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        @click="addEditCallLog"
        >Save</hlx-button
      >
    </template>
  </hlx-modal> -->

  <div v-if="interactionPayload.length > 0" style="height: auto">
    <div
      class="util-area"
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
      "
    >
      <span class="left">
        <hlx-switch
        v-if="staticData"
          v-model="selectedSwitch"
          :switch-items="switchOptions"
          value-prop="value"
          display-prop="label"
          @change="chosenSwitch"
        />
      </span>
      <span
        class="right"
        style="display: flex; gap: 0.5rem; align-items: center"
      >
        <hlx-search
        :label-value=staticData?.search?.label[loginUserDetails]?.label
         @search-key="searchKey"></hlx-search>
        <!-- <hlx-button
          v-if="mode === 'edit'"
          class="secondary sm log-add-btn"
          @click="showAddContextMenu"
        >
          <span style="display: flex; align-items: center; gap: 4px">
            <i class="icon-note-text-square-regular"></i> Add Log
          </span>
        </hlx-button>
        <hlx-context-menu
          :top="containertop1"
          :left="containerleft1"
          :conditionvalue="150"
          :conditionup="55"
          :options="add_log_options"
          :show="show_add_log_menu"
          @chosen="fromAddContextMenu"
          @close-context-menu="show_add_log_menu = false"
        ></hlx-context-menu>
        <hlx-button
          v-if="mode === 'edit'"
          class="primary sm new-add-btn"
          @click="showAddNewContextMenu"
        >
          <span style="display: flex; align-items: center; gap: 4px">
            <i class="icon-note-text-square-regular"></i> New
          </span>
        </hlx-button>
        <hlx-context-menu
          :top="containertop1"
          :left="containerleft1"
          :conditionvalue="150"
          :conditionup="55"
          :options="add_new_options"
          :show="show_add_new_menu"
          @chosen="fromAddNewMenu"
          @close-context-menu="show_add_new_menu = false"
        ></hlx-context-menu> -->
        <hlx-button
          v-if="mode === 'edit'"
          class="primary sm log-add-btn"
          @click="showAddContextMenu"
        >
          <span style="display: flex; align-items: center; gap: 4px">
            <i class="icon-note-text-square-regular"></i> 
            <!-- New interaction -->
            <span v-if="staticData">{{ staticData?.newInteraction?.label[loginUserDetails]?.label }}</span>
          </span>
        </hlx-button>
        <hlx-context-menu
          :top="containertop1"
                  :display="'display'"
          :left="containerleft1"
          :conditionvalue="150"
          :conditiondown="2"
          :conditionup="-38"
          :options="add_log_options"
          :show="show_add_log_menu"
          @chosen="fromAddContextMenu"
          @close-context-menu="show_add_log_menu = false"
        ></hlx-context-menu>
      </span>
    </div>
    <div
      class="interaction-component"
      style="margin-top: 2rem; height: 100%; font-size: 14px"
    >
      <hlx-timeline
        v-for="(i, index) in copyOfInteractionPayload"
        :key="index"
        :view="'vertical'"
        :type="'slot'"
        :curr-index="index"
        :total-length="copyOfInteractionPayload.length"
        :data-length="copyOfInteractionPayload.length"
        @accordion-open="open(i, index)"
        @accordion-close="close(i, index)"
      >
        <template #icon>
          <i
            v-if="getType(i) === 'manual Email Log'"
            class="icon-envelope-plus-regular"
            style="font-size: 22px"
          ></i>
          <i
            v-if="getType(i) === 'auto Email Log'"
            class="icon-envelope-regular"
            style="font-size: 22px"
          ></i>
          <i
            v-if="getType(i) === 'manual Call Log'"
            class="icon-phone-plus-regular"
            style="font-size: 22px"
          ></i>
          <i
            v-if="getType(i) === 'note Log'"
            class="icon-note-regular"
            style="font-size: 22px"
          ></i>
        </template>

        <template #first-slot>
          <div
            v-if="getType(i) === 'auto Email Log'"
            style="display: flex; justify-content: space-between"
          >
            <span class="interaction-hover-container"
              ><span style="color: #2879b7; cursor: pointer"
                >{{ i.interactionItem[0].contact }}
              </span>
              <span>
                {{
                  ", "}}
                <!-- { { "the " }} -->
                <span v-if="staticData">{{ staticData?.the?.label[loginUserDetails]?.label }}</span>
                 {{ 
                  (typeof i.interactionItem[0]?.role === "string"
                    ? i.interactionItem[0].role
                    : i.interactionItem[0]?.role?.name) +
                  ", "}} 
                  <!-- {{"sent an email"}} -->
                  <span v-if="staticData">{{ staticData?.sentAnEmail?.label[loginUserDetails]?.label }}</span>
                  </span
              >
              &nbsp;
              <!-- <span class="interaction-hover">
                <i
                  v-if="mode === 'edit' && getType(i) === 'auto Email Log'"
                  style="color: #54bd95; margin-top: 1px; cursor: pointer"
                  class="icon-arrow-reply-regular"
                ></i
                >&nbsp; -->
              <!-- <i
                    @click="showEditEmailLogModalFunc(i, index)"
                  v-if="getType(i) === 'manual Email Log'"
                  style="margin-top: 1px; cursor: pointer"
                  class="icon-trash-regular"
                  @click="showDeleteEmailLogModalFunc(i._id)"
                ></i
              > -->
              <!-- </span> -->
            </span>

            <span>{{
              dateAndTimeFormatter(i.interactionItem[0].creationDate)
            }}</span>
          </div>
          <div
            v-if="getType(i) === 'manual Email Log'"
            style="display: flex; justify-content: space-between"
          >
            <span class="interaction-hover-container"
              ><span style="color: #2879b7; cursor: pointer"
                >{{ i.interactionItem[0].contact }}
              </span>
              <span>
                {{
                  ", "}}
                   <!-- {{ "the "}}  -->
                   <span v-if="staticData">{{ staticData?.the?.label[loginUserDetails]?.label }}</span>
                  {{  
                  (typeof i.interactionItem[0]?.role === "string"
                    ? i.interactionItem[0].role
                    : i.interactionItem[0]?.role?.name) +
                    ", "}} 
                    <!-- {{ "logged an email"}} -->
                    <span v-if="staticData">{{ staticData?.loggedAnEmail?.label[loginUserDetails]?.label }}</span>
                    </span
              >
              &nbsp;
              <span class="interaction-hover">
                <i
                  v-if="mode === 'edit' && getType(i) === 'manual Email Log'"
                  style="color: #54bd95; margin-top: 1px; cursor: pointer"
                  class="icon-edit-regular"
                  @click="showEditEmailLogModalFunc(i, index)"
                ></i
                >&nbsp;
                <i
                  v-if="mode === 'edit' && getType(i) === 'manual Email Log'"
                  style="margin-top: 1px; cursor: pointer"
                  class="icon-trash-regular"
                  @click="showDeleteEmailLogModalFunc(i._id, i)"
                ></i
              ></span>
            </span>

            <span>{{
              dateAndTimeFormatter(i.interactionItem[0].creationDate)
            }}</span>
          </div>
          <div
            v-if="getType(i) === 'manual Call Log'"
            style="display: flex; justify-content: space-between"
          >
            <span class="interaction-hover-container"
              ><span style="color: #2879b7; cursor: pointer"
                >{{ i.interactionItem[0].contact }}
              </span>
              <span>
                {{
                  ", "}}
                   <!-- {{ "the "}}  -->
                   <span v-if="staticData">{{ staticData?.the?.label[loginUserDetails]?.label }}</span>
                  {{ 
                  (typeof i.interactionItem[0]?.role === "string"
                    ? i.interactionItem[0].role
                    : i.interactionItem[0]?.role?.name) +
                  ", "}} 
                    <!-- {{ "logged a call"}} -->
                    <span v-if="staticData">{{ staticData?.loggedACall?.label[loginUserDetails]?.label }}</span>
                </span
              >
              &nbsp;
              <span class="interaction-hover">
                <i
                  v-if="mode === 'edit' && getType(i) === 'manual Call Log'"
                  style="color: #54bd95; margin-top: 1px; cursor: pointer"
                  class="icon-edit-regular"
                  @click="showEditCallLogModalFunc(i, index)"
                ></i
                >&nbsp;
                <i
                  v-if="mode === 'edit' && getType(i) === 'manual Call Log'"
                  style="margin-top: 1px; cursor: pointer"
                  class="icon-trash-regular"
                  @click="showDeleteCallLogModalFunc(i._id)"
                ></i>
              </span>
            </span>
            <span>{{
              dateAndTimeFormatter(i.interactionItem[0].creationDate)
            }}</span>
          </div>
          <div
            v-if="getType(i) === 'note Log'"
            style="display: flex; justify-content: space-between"
          >
            <span class="interaction-hover-container"
              ><span style="color: #2879b7; cursor: pointer"
                >{{ i.interactionItem[0].contact }}
              </span>
              <span>
                {{
                  ", "}}
                   <!-- {{ "the "}}  -->
                   <span v-if="staticData">{{ staticData?.the?.label[loginUserDetails]?.label }}</span>
                  {{ 
                  (typeof i.interactionItem[0]?.role === "string"
                    ? i.interactionItem[0].role
                    : i.interactionItem[0]?.role?.name) +
                  ", "}} 
                    <!-- {{ "added a note"}} -->
                    <span v-if="staticData">{{ staticData?.addedANote?.label[loginUserDetails]?.label }}</span>
                </span
              >
              &nbsp;
              <span class="interaction-hover">
                <i
                  v-if="mode === 'edit' && getType(i) === 'note Log'"
                  style="color: #54bd95; margin-top: 1px; cursor: pointer"
                  class="icon-edit-regular"
                  @click="showEditNoteLogModalFunc(i, index)"
                ></i
                >&nbsp;
                <i
                  v-if="mode === 'edit' && getType(i) === 'note Log'"
                  style="margin-top: 1px; cursor: pointer"
                  class="icon-trash-regular"
                  @click="showDeleteNoteLogModalFunc(i._id, i)"
                ></i>
              </span>
            </span>
            <span>{{
              dateAndTimeFormatter(i.interactionItem[0].creationDate)
            }}</span>
          </div>
        </template>
        <template #title>
          <p
            v-if="getType(i) === 'note Log'"
            :id="'accordion' + index"
            class="accordionAdd"
          >
            {{ i.interactionItem[0]?.description }}
          </p>
          <p v-else>{{ i.interactionItem[0]?.reason }}</p>
        </template>
        <template #content>
          <div v-if="getType(i) === 'auto Email Log'">
            <br />
            <p v-html="i.interactionItem[0]?.description"></p>
            <br />
          </div>
          <p
            v-else-if="getType(i) !== 'note Log'"
            style="margin-bottom: 30px; margin-top: 10px"
          >
            <span> {{ i.interactionItem[0]?.description }} </span>
          </p>
        </template>
      </hlx-timeline>
    </div>
  </div>

  <!-- <hlx-modal
    :modal-active="showNewNoteModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'460px'"
    :width="'650px'"
    :modal="true"
    @close="closeNewNoteModal"
  >
    <template #header>
      <div>Add Note</div>
    </template>
    <template #content>
      <div class="modal-content">
        <hlx-input
          v-model:value="newNotePayload.reason"
          :pre-val="newNotePayload.reason"
          :label-value="'reason'"
          :label-animation="true"
          :display-error="true"
          :custom-error="isEmailEmpty"
          :custom-error-message="'Please fill the field'"
          @focus-out="focusOutName"
          @at-input="validateName"
        ></hlx-input>
        <hlx-input
          v-model:value="newNotePayload.description"
          :pre-val="newNotePayload.description"
          :label-value="'Note'"
          :label-animation="true"
          type="textarea"
          :rows="10"
          :custom-error="customnameerror"
          :custom-error-message="'Please fill the field'"
          @focus-out="focusOutName"
          @at-input="validateName"
        ></hlx-input>
      </div>
    </template>
    <template #footer>
      <hlx-button
        class="secondary sm"
        @click="closeNewNoteModal"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        @click="addNewNote"
        >Add</hlx-button
      >
    </template>
  </hlx-modal> -->
  <!-- <hlx-modal
    :modal-active="showEditNoteModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'460px'"
    :width="'650px'"
    :modal="true"
    @close="closeEditNoteModal"
  >
    <template #header>
      <div>Edit Note</div>
    </template>
    <template #content>
      <div class="modal-content">
        <hlx-input
          v-model:value="newNotePayload.reason"
          :pre-val="newNotePayload.reason"
          :label-value="'reason'"
          :label-animation="true"
          :display-error="true"
          :custom-error="isEmailEmpty"
          :custom-error-message="'Please fill the field'"
          @focus-out="focusOutName"
          @at-input="validateName"
        ></hlx-input>
        <hlx-input
          v-model:value="newNotePayload.description"
          :pre-val="newNotePayload.description"
          :label-value="'Note'"
          :label-animation="true"
          type="textarea"
          :rows="10"
          :custom-error="customnameerror"
          :custom-error-message="'Please fill the field'"
          @focus-out="focusOutName"
          @at-input="validateName"
        ></hlx-input>
      </div>
    </template>
    <template #footer>
      <hlx-button
        class="secondary sm"
        @click="closeEditNoteModal"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        @click="addEditNote"
        >Add</hlx-button
      >
    </template>
  </hlx-modal> -->

  <!-- Drawer -->

  <div class="add-contact-drawer">
    <hlx-drawer
      :show="showEditNoteModal"
      position="right"
      width="400"
      :footer="true"
      :title="staticData?.addContact?.label[loginUserDetails]?.label || 'Add contact'"
      :show-close-icon="true"
      @close="closeEditNoteModal"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          <!-- <span
            style="
              display: flex;
              width: 25px;
              height: 25px;
              position: fixed;
              right: 400px;
              background: white;
              z-index: 1000;
              top: 0;
              align-items: center;
              justify-content: center;
              border-top-left-radius: 3px;
              border-bottom-left-radius: 3px;
              box-shadow: -2px 0px 6px 0px #b9b6b6;
              cursor: pointer;
            "
            @click="closeAddCallLogModal"
          >
            <i class="icon-times-regular"></i>
          </span> -->
          <b>
            <!-- Edit Note -->
            <span v-if="staticData">{{ staticData?.editNote?.label[loginUserDetails]?.label }}</span>
          </b>
        </div>
        <div class="body-area-contact-drawer">
          <!-- <hlx-input
            v-model:value="newNotePayload.reason"
            :pre-val="newNotePayload.reason"
            :label-value="'Reason'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input> -->
          <hlx-input
          v-if="staticData"
            v-model:value="newNotePayload.description"
            :pre-val="newNotePayload.description"
            :label-value="'Note'"
            :label-animation="true"
            type="textarea"
            :rows="10"
            :custom-error="customnameerror"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeEditNoteModal"
            >
            <!-- Cancel -->
            <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 10px"
            @click="addEditNote"
            >
            <!-- Save -->
            <span v-if="staticData">{{ staticData?.save?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>
  <div class="add-contact-drawer">
    <hlx-drawer
      :show="showNewNoteModal"
      position="right"
      width="400"
      :footer="true"
      :title="staticData?.addContact?.label[loginUserDetails]?.label || 'Add contact'"
      :show-close-icon="true"
      @close="closeNewNoteModal"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          <!-- <span
            style="
              display: flex;
              width: 25px;
              height: 25px;
              position: fixed;
              right: 400px;
              background: white;
              z-index: 1000;
              top: 0;
              align-items: center;
              justify-content: center;
              border-top-left-radius: 3px;
              border-bottom-left-radius: 3px;
              box-shadow: -2px 0px 6px 0px #b9b6b6;
              cursor: pointer;
            "
            @click="closeAddCallLogModal"
          >
            <i class="icon-times-regular"></i>
          </span> -->
          <b>
            <!-- Add a Note -->
            <span v-if="staticData">{{ staticData?.addANote?.label[loginUserDetails]?.label }}</span>
          </b>
        </div>
        <div class="body-area-contact-drawer">
          <!-- <hlx-input
            v-model:value="newNotePayload.reason"
            :pre-val="newNotePayload.reason"
            :label-value="'Reason'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input> -->
          <hlx-input
          v-if="staticData"
            v-model:value="newNotePayload.description"
            :pre-val="newNotePayload.description"
            :label-value="'Note'"
            :label-animation="true"
            type="textarea"
            :rows="10"
            :custom-error="customnameerror"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeNewNoteModal"
            >
            <!-- Cancel -->
            <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 10px"
            @click="addNewNote"
            >
            <!-- Add -->
            <span v-if="staticData">{{ staticData?.add?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>
  <div class="add-contact-drawer">
    <hlx-drawer
      :show="showAddCallLogModal"
      position="right"
      width="400"
      :footer="true"
      :title="staticData?.addContact?.label[loginUserDetails]?.label || 'Add contact'"
      :show-close-icon="true"
      @close="closeAddCallLogModal"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          <!-- <span
            style="
              display: flex;
              width: 25px;
              height: 25px;
              position: fixed;
              right: 400px;
              background: white;
              z-index: 1000;
              top: 0;
              align-items: center;
              justify-content: center;
              border-top-left-radius: 3px;
              border-bottom-left-radius: 3px;
              box-shadow: -2px 0px 6px 0px #b9b6b6;
              cursor: pointer;
            "
            @click="closeAddCallLogModal"
          >
            <i class="icon-times-regular"></i>
          </span> -->
          <b>
            <!-- Log a Call -->
            <span v-if="staticData">{{ staticData?.logACall?.label[loginUserDetails]?.label }}</span>
          </b>
        </div>
        <div class="body-area-contact-drawer">
          <div class="input-wrapper" style="margin-bottom: 20px">
            <hlx-select
              :key="key"
              v-model:value="callPayload.contact"
              :pre-value="callPayload.contact"
              :options="contactList"
              prop-value="name"
              :placeholder-value="'Search contact'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <div class="input-wrapper" style="margin-bottom: 20px">
            <hlx-select
              :key="key"
              v-model:value="callPayload.direction"
              :pre-value="callPayload.direction"
              :options="callTypeOptions"
              prop-value="name"
              :placeholder-value="'Call type'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <hlx-input
          v-if="staticData"
            v-model:value="callPayload.duration"
            :pre-val="callPayload.duration"
            :label-value="'Call duration'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            :key="dobKey"
            v-model:value="callPayload.date"
            :pre-val="callPayload.date"
            :label-value="'Interaction date'"
            :label-animation="true"
            type="date"
            icon="calendar-regular"
            icon-position="right"
            :display-error="true"
          ></hlx-input>
          <hlx-input
          v-if="staticData"
            v-model:value="callPayload.reason"
            :pre-val="callPayload.reason"
            :label-value="'Reason'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
          v-if="staticData"
            v-model:value="callPayload.body"
            :pre-val="callPayload.body"
            :label-value="'Describe the Call...'"
            :label-animation="true"
            type="textarea"
            :rows="7"
            :custom-error="customnameerror"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeAddCallLogModal"
            >
            <!-- Cancel -->
            <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 10px"
            @click="addNewCallLog"
            >
            <!-- Add -->
            <span v-if="staticData">{{ staticData?.add?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>
  <div class="add-contact-drawer">
    <hlx-drawer
      :show="showEditCallLogModal"
      position="right"
      width="400"
      :footer="true"
      :title="staticData?.addContact?.label[loginUserDetails]?.label || 'Add contact'"
      :show-close-icon="true"
      @close="closeEditCallLogModal"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          <!-- <span
            style="
              display: flex;
              width: 25px;
              height: 25px;
              position: fixed;
              right: 400px;
              background: white;
              z-index: 1000;
              top: 0;
              align-items: center;
              justify-content: center;
              border-top-left-radius: 3px;
              border-bottom-left-radius: 3px;
              box-shadow: -2px 0px 6px 0px #b9b6b6;
              cursor: pointer;
            "
            @click="closeEditCallLogModal"
          >
            <i class="icon-times-regular"></i>
          </span> -->
          <b>
            <!-- Edit Call Log -->
            <span v-if="staticData">{{ staticData?.editCallLog?.label[loginUserDetails]?.label }}</span>
          </b>
        </div>
        <div class="body-area-contact-drawer">
          <div class="input-wrapper" style="margin-bottom: 20px">
            <hlx-select
              :key="key"
              v-model:value="callPayload.contact"
              :pre-value="callPayload.contact"
              :options="contactList"
              prop-value="name"
              :placeholder-value="'Search contact'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <div class="input-wrapper" style="margin-bottom: 20px">
            <hlx-select
              :key="key"
              v-model:value="callPayload.direction"
              :pre-value="callPayload.direction"
              :options="callTypeOptions"
              prop-value="name"
              :placeholder-value="'Call type'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <hlx-input
          v-if="staticData"
            v-model:value="callPayload.duration"
            :pre-val="callPayload.duration"
            :label-value="'Call duration'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            :key="dobKey"
            v-model:value="callPayload.date"
            :pre-val="callPayload.date"
            :label-value="'Date and time'"
            :label-animation="true"
            type="date"
            icon="calendar-regular"
            icon-position="right"
            :display-error="true"
          ></hlx-input>
          <hlx-input
          v-if="staticData"
            v-model:value="callPayload.reason"
            :pre-val="callPayload.reason"
            :label-value="'Reason'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
          v-if="staticData"
            v-model:value="callPayload.body"
            :pre-val="callPayload.body"
            :label-value="'Describe the Call...'"
            :label-animation="true"
            type="textarea"
            :rows="7"
            :custom-error="customnameerror"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeEditCallLogModal"
            >
            <!-- Cancel -->
            <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 10px"
            @click="addEditCallLog"
            >
            <!-- Save -->
            <span v-if="staticData">{{ staticData?.save?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>
  <div class="add-contact-drawer">
    <hlx-drawer
      :show="showAddEmailLogModal"
      position="right"
      width="400"
      :footer="true"
      :title="staticData?.addContact?.label[loginUserDetails]?.label || 'Add contact'"
      :show-close-icon="true"
      @close="closeAddEmailLogModal"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          <!-- <span
            style="
              display: flex;
              width: 25px;
              height: 25px;
              position: fixed;
              right: 400px;
              background: white;
              z-index: 1000;
              top: 0;
              align-items: center;
              justify-content: center;
              border-top-left-radius: 3px;
              border-bottom-left-radius: 3px;
              box-shadow: -2px 0px 6px 0px #b9b6b6;
              cursor: pointer;
            "
            @click="closeAddEmailLogModal"
          >
            <i class="icon-times-regular"></i>
          </span> -->
          <b>
            <!-- Log an Email -->
            <span v-if="staticData">{{ staticData?.logAnEmail?.label[loginUserDetails]?.label }}</span>
          </b>
        </div>
        <div class="body-area-contact-drawer">
          <div
            v-if="module === 'accounts'"
            class="input-wrapper"
            style="margin-bottom: 20px"
          >
            <hlx-select
              :key="key"
              v-model:value="emailPayload.contact"
              :pre-value="emailPayload.contact"
              :options="contactList"
              prop-value="name"
              :inline-search="true"
              :placeholder-value="'Search contact'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <div class="input-wrapper" style="margin-bottom: 20px">
            <hlx-select
              :key="key"
              v-model:value="emailPayload.direction"
              :pre-value="emailPayload.direction"
              :options="callTypeOptions"
              prop-value="name"
              :placeholder-value="'Email type'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <hlx-input
            :key="dobKey"
            v-model:value="emailPayload.date"
            :pre-val="emailPayload.date"
            :label-value="'Interaction date'"
            :label-animation="true"
            type="date"
            icon="calendar-regular"
            icon-position="right"
            :display-error="true"
          ></hlx-input>
          <hlx-input
          v-if="staticData"
            v-model:value="emailPayload.reason"
            :pre-val="emailPayload.reason"
            :label-value="'Reason'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
          v-if="staticData"
            v-model:value="emailPayload.body"
            :pre-val="emailPayload.body"
            :label-value="'Describe the Email...'"
            :label-animation="true"
            type="textarea"
            :required="false"
            :display-error="true"
            :custom-error="customnameerror"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeAddEmailLogModal"
            >
            <!-- Cancel -->
            <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 10px"
            @click="addNewEmailLog"
            >
            <!-- Add -->
            <span v-if="staticData">{{ staticData?.add?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>
  <div class="add-contact-drawer">
    <hlx-drawer
      :show="showEditEmailLogModal"
      position="right"
      width="400"
      :footer="true"
      :title="staticData?.addContact?.label[loginUserDetails]?.label || 'Add contact'"
      :show-close-icon="true"
      @close="closeEditEmailLogModal"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          <!-- <span
            style="
              display: flex;
              width: 25px;
              height: 25px;
              position: fixed;
              right: 400px;
              background: white;
              z-index: 1000;
              top: 0;
              align-items: center;
              justify-content: center;
              border-top-left-radius: 3px;
              border-bottom-left-radius: 3px;
              box-shadow: -2px 0px 6px 0px #b9b6b6;
              cursor: pointer;
            "
            @click="closeEditEmailLogModal"
          >
            <i class="icon-times-regular"></i>
          </span> -->
          <b>
            <!-- Edit Email Log -->
            <span v-if="staticData">{{ staticData?.editEmailLog?.label[loginUserDetails]?.label }}</span>
          </b>
        </div>
        <div class="body-area-contact-drawer">
          <div
            v-if="module === 'accounts'"
            class="input-wrapper"
            style="margin-bottom: 20px"
          >
            <hlx-select
              :key="key"
              v-model:value="emailPayload.contact"
              :pre-value="emailPayload.contact"
              :options="contactList"
              prop-value="name"
              :placeholder-value="'Search contact'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <div class="input-wrapper" style="margin-bottom: 20px">
            <hlx-select
              :key="key"
              v-model:value="emailPayload.direction"
              :pre-value="emailPayload.direction"
              :options="callTypeOptions"
              prop-value="name"
              :placeholder-value="'Email type'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <hlx-input
            :key="dobKey"
            v-model:value="emailPayload.date"
            :pre-val="emailPayload.date"
            :label-value="'Date and time'"
            :label-animation="true"
            type="date"
            icon="calendar-regular"
            icon-position="right"
            :display-error="true"
          ></hlx-input>
          <hlx-input          
          v-if="staticData"
            v-model:value="emailPayload.reason"
            :pre-val="emailPayload.reason"
            :label-value="'reason'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input          
          v-if="staticData"
            v-model:value="emailPayload.body"
            :pre-val="emailPayload.body"
            :label-value="'Describe the Email...'"
            :label-animation="true"
            type="textarea"
            :required="false"
            :display-error="true"
            :custom-error="customnameerror"
                        :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeEditEmailLogModal"
            >
            <!-- Cancel -->
            <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 10px"
            @click="addEditEmailLog"
            >
            <!-- Save -->
            <span v-if="staticData">{{ staticData?.save?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>
</template>

<script>
import {
  DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
// import email from '@/components/emailComponent.vue';

export default {
  components: {
    // email,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    contactData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    mode: {
      type: String,
      default: "edit",
    },
    module: {
      type: String,
      default: "accounts",
    },
    defaultTo: {
      type: String,
      default: "",
    },
  },
  emits: [
    "new-interaction-payload",
    "delete-interaction-index",
    "edit-interaction",
    "delete-interaction-data",
    "initialPayload",
  ],
  data() {
    return {
      loginUserDetails: {},
      staticData:null,
      buffers: [],
      deleteEmail: {},
      AIEmailPayload: {
        subject: "",
        body: "",
      },
      prompt: "",
      isBasedOnFeature: true,
      isBasedOnPrompt: false,
      disableAIToolBarButton: false,
      AIChoicesForDescription: [],
      aiContextMenuPositionTop: 0,
      aiContextMenuPositionLeft: 0,
      AIContextMenuOptions: [
        {
          label: "Using prompt",
          icon: "icon-star-magic-regular",
          action: "prompt",
        },
        {
          label: "Basic template",
          icon: "icon-cabinet-filing-regular",
          action: "feature",
        },
      ],
      isAIPromptContextMenuVisible: false,
      AIPromptChoicesForDescription: [],
      aiPromptContextMenuPositionTop: 0,
      aiPromptContextMenuPositionLeft: 0,
      AIPromptContextMenuOptions: [
        {
          label: "Basic template",
          icon: "icon-cabinet-filing-regular",
          action: "feature",
        },
        {
          label: "Close",
          icon: "icon-times-regular",
          action: "close",
        },
      ],
      isAIContextMenuVisible: false,
      componentKey: 0,
      notification: {},
      editId: "",
      showEditNoteModal: false,
      showEditCallLogModal: false,
      showDeleteCallLogModal: false,
      showDeleteEmailLogModal: false,
      showEditEmailLogModal: false,
      showDeleteNoteLogModal: false,
      currUserRole: "",
      copyOfInteractionPayload: [],
      interactionPayload: {
        "@type": "PartyInteraction",
        interactionDate: {
          startDateTime: "2024-01-11T00:00:00.000Z",
          // endDateTime: '2024-01-11T00:00:00.000Z',
        },
        description: "",
        reason: "",
        direction: "Inbound",
        relatedParty: [
          {
            role: "user",
            "@type": "RelatedPartyOrPartyRole",
            partyOrPartyRole: {
              id: "",
              href: "",
              name: "",
              "@type": "PartyRef",
              "@referredType": "Individual",
            },
          },
        ],
        interactionItem: [],
        relatedChannel: [
          // {
          //   role: '',
          //   '@type': 'RelatedChannel',
          //   channel: {
          //     id: '',
          //     name: '',
          //     '@type': '',
          //   },
          // },
        ],
        attachment: [
          // {
          //   id: '',
          //   href: '',
          //   name: '',
          //   '@type': 'AttachmentRef',
          //   description: '',
          //   attachmentType: '',
          //   mimeType: 'application/pdf',
          //   size: {
          //     amount: '',
          //     units: '',
          //   },
          //   URL: '',
          // },
        ],
        note: [
          // {
          //   id: '',
          //   text: '',
          //   author: '',
          //   date: '',
          //   '@type': 'Note ',
          // },
        ],
        interactionRelationship: [
          // {
          //   id: '',
          //   href: '',
          //   '@type': '',
          //   relationshipType: '',
          // },
        ],
      },
      newInteractionPayload: {
        "@type": "PartyInteraction",
        interactionDate: {
          startDateTime: "2024-01-11T00:00:00.000Z",
          // endDateTime: '2024-01-11T00:00:00.000Z',
        },
        description: "",
        reason: "",
        direction: "Outbound",
        relatedParty: [],
        interactionItem: [],
        relatedChannel: [
          // {
          //   role: '',
          //   '@type': 'RelatedChannel',
          //   channel: {
          //     id: '',
          //     name: '',
          //     '@type': '',
          //   },
          // },
        ],
        attachment: [
          // {
          //   id: '',
          //   href: '',
          //   name: '',
          //   '@type': 'AttachmentRef',
          //   description: '',
          //   attachmentType: '',
          //   mimeType: 'application/pdf',
          //   size: {
          //     amount: '',
          //     units: '',
          //   },
          //   URL: '',
          // },
        ],
        note: [
          // {
          //   id: '',
          //   text: '',
          //   author: '',
          //   date: '',
          //   '@type': 'Note ',
          // },
        ],
        interactionRelationship: [
          // {
          //   id: '',
          //   href: '',
          //   '@type': '',
          //   relationshipType: '',
          // },
        ],
      },
      contactList: [],
      loadAttachmnet: true,
      add_log_options: [
        {
          label: "Send email",
          value: "sendEmail",
          icon: "icon-envelope-edit-regular",
        },
        {
          label: "Log an email",
          value: "email",
          icon: "icon-envelope-plus-regular",
        },
        {
          label: "Add a note",
          value: "note",
          icon: "icon-note-regular",
        },
      ],
      add_new_options: [
        {
          label: "Call",
          value: "call",
          icon: "icon-phone-regular",
        },
        {
          label: "Email",
          value: "email",
          icon: "icon-envelope-regular",
        },
        {
          label: "Note",
          value: "Note",
          icon: "icon-note-regular",
        },
      ],
      show_add_log: false,
      containerleft1: 0,
      containertop1: 0,
      show_add_log_menu: false,
      showAddEmailLogModal: false,
      showAddCallLogModal: false,
      emailPayload: {
        contact: "",
        date: "",
        reason: "",
        body: "",
        direction: "",
      },
      callPayload: {
        contact: "",
        direction: "",
        date: "",
        reason: "",
        duration: "",
        body: "",
      },
      callTypeOptions: [
        {
          name: "Inbound",
        },
        {
          name: "Outbound",
        },
      ],
      interactionData: [],
      switchOptions: [
        {
          name: "All",
          value: "all",
        },
        // {
        //   name: 'Call',
        //   value: 'call',
        // },
        {
          name: "Email",
          value: "email",
        },
        {
          name: "Note",
          value: "note",
        },
      ],
      selectedSwitch: "all",
      show_add_new_menu: false,
      showNewEmailLogModal: false,
      showNewCallLogModal: false,
      showNewNoteModal: false,
      // loadAttachmnet: false,
      newNotePayload: {
        reason: "",
        description: "",
      },
      key: 0,
      showEmail: false,
      emailData: [],
      company: "",
      emailContent: {
        to: [],
        isHavingAttachment: false,
      },
      currentUserInfo: null,
    };
  },
  watch: {
    staticData: {
      handler(val) {
        val
        if (val) {
          val
          this.switchOptions =
          [
        {
          name: "All",
          value: "all",
          label: this.staticData?.all?.label[this.loginUserDetails]?.label
        },
        // {
        //   name: 'Call',
        //   value: 'call',
        // },
        {
          name: "Email",
          value: "email",
          label: this.staticData?.email?.label[this.loginUserDetails]?.label
        },
        {
          name: "Note",
          value: "note",
          label: this.staticData?.note?.label[this.loginUserDetails]?.label
        },
      ]


      this.add_log_options = [
        {
          display: this.staticData?.sendEmail?.label[this.loginUserDetails]?.label || "Send email",
          label: "Send email",
          value: "sendEmail",
          icon: "icon-envelope-edit-regular",
        },
        {
          display: this.staticData?.logAnEmail?.label[this.loginUserDetails]?.label || "Log an email",
          label: "Log an email",
          value: "email",
          icon: "icon-envelope-plus-regular",
        },
        {
          display: this.staticData?.addANote?.label[this.loginUserDetails]?.label || "Add a note",
          label: "Add a note",
          value: "note",
          icon: "icon-note-regular",
        },
      ]
        }
      },
      immediate: true,
      deep: true,
    },

    contactData: {
      handler(val) {
        this.contactList = val;
        // if (Object.keys(val).length !== 0) {
        this.emailData = val;
        // .filter((item) => Object.prototype.hasOwnProperty.call(item, "email"))
        // .map((item) => item.email);
        console.log(this.emailData, "email data new");
        // }
      },
      immediate: true,
      deep: true,
    },
    data: {
      handler(val) {
        console.log("interaction data", val);
        if (Object.keys(val).length !== 0) {
          this.interactionPayload = this.sortInteractionsByCreationDate(
            JSON.parse(JSON.stringify(val))
          );
        }
      },
      immediate: true,
      deep: true,
    },
    interactionPayload: {
      handler(val) {
        if (this.selectedSwitch === "all") {
          this.copyOfInteractionPayload = JSON.parse(JSON.stringify(val));
        } else if (this.selectedSwitch === "email") {
          this.copyOfInteractionPayload = val
            .filter((interaction) => {
              return interaction.interactionItem.some(
                (item) =>
                  item["@type"] === "manual Email Log" ||
                  item["@type"] === "auto Email Log"
              );
            })
            .map((interaction) => {
              return {
                ...interaction,
                interactionItem: interaction.interactionItem.filter(
                  (item) =>
                    item["@type"] === "manual Email Log" ||
                    item["@type"] === "auto Email Log"
                ),
              };
            });
        } else if (this.selectedSwitch === "note") {
          this.copyOfInteractionPayload = val
            .filter((interaction) => {
              return interaction.interactionItem.some(
                (item) =>
                  item["@type"] === "note Log" || item["@type"] === "note Log"
              );
            })
            .map((interaction) => {
              return {
                ...interaction,
                interactionItem: interaction.interactionItem.filter(
                  (item) =>
                    item["@type"] === "note Log" || item["@type"] === "note Log"
                ),
              };
            });
        } else {
          this.copyOfInteractionPayload = val
            .filter((interaction) => {
              return interaction.interactionItem.some(
                (item) =>
                  item["@type"] ===
                  `manual ${val.charAt(0).toUpperCase() + val.slice(1)} Log`
              );
            })
            .map((interaction) => {
              return {
                ...interaction,
                interactionItem: interaction.interactionItem.filter(
                  (item) =>
                    item["@type"] ===
                    `manual ${val.charAt(0).toUpperCase() + val.slice(1)} Log`
                ),
              };
            });
        }
        console.log(
          this.selectedSwitch,
          this.copyOfInteractionPayload,
          "this.selectedSwitch"
        );

        // console.log("interaction payload", val);
        // // this.sortInteractionItems(val);
        // if (Object.keys(val).length !== 0) {
        //   this.copyOfInteractionPayload = JSON.parse(JSON.stringify(val));
        //   // this.copyOfInteractionPayload = this.sortInteractionsByCreationDate(this.copyOfInteractionPayload)
        // }
        // // console.log(this.sortInteractionsByCreationDate(this.copyOfInteractionPayload));
      },
      immediate: true,
      deep: true,
    },
    selectedSwitch: {
      handler(val) {
        if (val === "all") {
          this.copyOfInteractionPayload = JSON.parse(
            JSON.stringify(this.interactionPayload)
          );
        } else if (val === "email") {
          this.copyOfInteractionPayload = this.interactionPayload
            .filter((interaction) => {
              return interaction.interactionItem.some(
                (item) =>
                  item["@type"] === "manual Email Log" ||
                  item["@type"] === "auto Email Log"
              );
            })
            .map((interaction) => {
              return {
                ...interaction,
                interactionItem: interaction.interactionItem.filter(
                  (item) =>
                    item["@type"] === "manual Email Log" ||
                    item["@type"] === "auto Email Log"
                ),
              };
            });
        } else if (val === "note") {
          this.copyOfInteractionPayload = this.interactionPayload
            .filter((interaction) => {
              return interaction.interactionItem.some(
                (item) =>
                  item["@type"] === "note Log" || item["@type"] === "note Log"
              );
            })
            .map((interaction) => {
              return {
                ...interaction,
                interactionItem: interaction.interactionItem.filter(
                  (item) =>
                    item["@type"] === "note Log" || item["@type"] === "note Log"
                ),
              };
            });
        } else {
          this.copyOfInteractionPayload = this.interactionPayload
            .filter((interaction) => {
              return interaction.interactionItem.some(
                (item) =>
                  item["@type"] ===
                  `manual ${val.charAt(0).toUpperCase() + val.slice(1)} Log`
              );
            })
            .map((interaction) => {
              return {
                ...interaction,
                interactionItem: interaction.interactionItem.filter(
                  (item) =>
                    item["@type"] ===
                    `manual ${val.charAt(0).toUpperCase() + val.slice(1)} Log`
                ),
              };
            });
        }
      },
      immediate: true,
      deep: true,
    },
   
    loadAttachmnet: {
      handler(val) {
        console.log(val);
      },
      immediate: true,
      deep: true,
    },
  
  },
  async mounted() {
    await this.$store.dispatch("loginTenantDetails");
    await this.$store.dispatch("loginUserDetails");
    // loginUserDetails
    // await this.$store.dispatch("loginTenantDetails");
    // let r = await this.$store.getters.loginTenantDetails;
    console.log("loginTenantDetails resrfsef:", this.$store.getters.loginTenantDetails);
    console.log("loginUserDetails resrfsef:", this.$store.getters.loginUserDetails);

    this.loginUserDetails = this.$store?.getters?.loginUserDetails?.languageAbility[0]?.code;

    // this.loginUserDetails.languageAbility[0].name
    // this.loginUserDetails.languageAbility[0].code

    try {
      let organisationInfo = await UPCAxiosInstance.get('/multi-language');
      this.staticData = organisationInfo?.data?.filter(itm => itm && itm.modules)?.[0]?.modules;
      console.log("this.staticData:",organisationInfo , this.staticData);
      
    }
    catch (error) {
      console.error("error::", error)
    }


    
    const val1 = await MLTAxiosInstance.get("partymanagement/individual/auth");
    this.company = val1.data.company;
    // const userInfo = this.$store.state.userInfo;
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");

    const access = await MLTAxiosInstance.get(
      `partyRole/get/${val.data.userId}`
    );
    //
    this.currUserRole = access.data.role;
    this.currentUserInfo = await this.currentUser();
    if (this.currentUserInfo) {
      this.currentUserInfo = {
        name: this.currentUserInfo?.name,
        firstname: this.currentUserInfo?.firstname,
        lastname: this.currentUserInfo?.lastname,
        email: this.currentUserInfo?.email,
        contactMedium: this.currentUserInfo?.contactMedium,
      };
    }
    // console.log(access);
    //     let x = {
    //   "type": "filter",
    //   "module": "hierarchycontacts",
    //   "isPaginated": true,
    //   "paginatedQuery": {
    //     "rowsPerPage": 10,
    //     "currentPage": 1
    //   },
    //   "isNeedToBeSort": false,
    //   "sortFieldAndOrder": {},
    //   "limit": 10,
    //   "filterQuery": {
    //     "@baseType": "customer"
    //   },
    //   "searchQuery": "",
    //   "company": ""
    // }
    // let data = await MLTAxiosInstance.post(
    //           `util/filterData`,
    //           x
    //         );
    // this.contactList = data.data.data
    // console.log(this.contactList);
  },
  
  methods: {
    handleEmailContentUpdate(emailContent) {
      console.log(
        "🚀 ~ handleEmailContentUpdate ~ emailContent:",
        emailContent
      );
      this.emailContent.to = emailContent.to;
      this.emailContent.isHavingAttachment = emailContent.attachments
        ? emailContent.attachments.length > 0
        : false;
    },
    async generateContentWithAI(type, prompt = "") {
      console.log("🚀 ~ generateContentWithAI ~ prompt:", prompt);
      if (!prompt) return "Please enter a prompt";
      this.disableAIToolBarButton = true;
      // if (
      //   [
      //     "product",
      //     "offer",
      //     "this",
      //     "feature",
      //     "characteristic",
      //     "spec",
      //   ].some((substring) => prompt.includes(substring))
      // ) {
      //   prompt += "PRODUCT - " + productName + ",FEATURES - " + features;
      // }
      let emailPayload = {
        recipients: this.emailContent.to,
        sender: this.currentUserInfo,
      };
      let chatCompletionPayload = {
        prompt: [
          {
            role: "system",
            content:
              // "Your Name: [Your Name Here], Title: [Your Job Title Here], Recipient Name: [Recipient Name Here] (if known), Recipient Title: [Recipient Job Title Here] (if known), Purpose: [Brief reason for email], Tone: [Formal/Informal etc.] | Instruction: GPT creates email with Subject reflecting purpose & Body: Salutation ([Recipient Name Here]) + Intro (reminding recipient & context) + Body (clear purpose & details) + CTA (optional) + Closing & Signature (optional). | Example: Alice (Software Engineer) to David (Project Manager) - Request info on project requirement discussed in meeting (Purpose). Tone: Professional & Informative.Be business friendly.And Style the result using HTML tags and separate a keys like subject and body with JSON format.Prompt is not fullfilling the requirement, please provide the required information.",
              `You are a professional email assistant with expertise in creating clear, concise, and polite business communication. You excel at tailoring messages to fit different business contexts, ensuring that each email is appropriately formal, friendly, and effective.If prompt is incomplete to generate a result message it clearlyWrite a business-friendly email responding to [insert context here]. Make sure the tone is professional yet friendly, and the message is clear and concise. Include a subject line and a proper closing.Example context:Requesting a meeting to discuss a new project.Following up on an invoice that has not been paid.Responding to a customer inquiry about a product or service.Confirming attendance at an upcoming conference or event.Addressing a performance issue with a team member.Sending a thank you note after a business meeting.Additional details:[Insert any specific details or requirements here, such as the recipient's name, any deadlines, key points to be included, etc.].Format as JSON with subject and body as HTML style. Keep greetings, body, and signature on new lines.Prepare an email based required fields of {EMAIL_DATA}, if EMAIL_DATA is not have the required fields use placeholder instead.EMAIL_DATA:${JSON.stringify(
                emailPayload,
                null,
                2
              )}`,
          },
          {
            role: "user",
            content: prompt,
          },
        ],
        numberOfChoices: 10,
      };
      if (Object.keys(chatCompletionPayload).length == 0) return;
      let AIChatResponse = await DMNAxiosInstance.post(
        `/GenAI/chat`,
        chatCompletionPayload
      );

      this.AIChoicesForDescription = AIChatResponse.data;
      // this.payloadSpec.description = AIChatResponse.data[0].message?.content;
      this.disableAIToolBarButton = false;
      if (AIChatResponse.data && AIChatResponse.data?.length) {
        this.AIChoicesForDescription = AIChatResponse.data
          .map((item) => {
            // let obj = {
            //   isValid:true
            // }
            // console.log("🚀 ~ generateContentWithAI ~ obj:", obj)
            try {
              return { data: JSON.parse(item.message.content), isValid: true };
            } catch (error) {
              console.log("🚀 ~ generateContentWithAI ~ error:", error);
              return {
                isValid: false,
                data: JSON.parse(JSON.stringify(item.message.content)),
              };
            }
          })
          .filter(
            (item) =>
              item?.isValid &&
              item?.data?.error == undefined &&
              item?.data?.Error == undefined
          );
        console.log(
          "🚀 ~ generateContentWithAI ~ this.AIChoicesForDescription:",
          this.AIChoicesForDescription
        );
        if (this.AIChoicesForDescription.length) {
          this.AIEmailPayload.body = this.AIChoicesForDescription[0].data.Body
            ? this.AIChoicesForDescription[0].data.Body
            : this.AIChoicesForDescription[0].data.body;
          this.AIEmailPayload.subject = this.AIChoicesForDescription[0].data
            .Subject
            ? this.AIChoicesForDescription[0].data.Subject
            : this.AIChoicesForDescription[0].data.subject;
        }
      }
      console.log(
        "🚀 ~ generateContentWithAI ~ AIChatResponse:",
        AIChatResponse
        // JSON.parse(AIChatResponse.data[0].message.content,(key,value) => {
        //   console.log("🚀 ~ JSON.parse ~ key,value:", key,value)
        // 	})
      );
    },
    handleRegenerateAIContent() {
      //  Find email body index
      let emailBodyIndex = this.AIChoicesForDescription.findIndex(
        (item) =>
          this.AIEmailPayload.body == item.data?.Body ||
          this.AIEmailPayload.body == item.data?.body
      );
      console.log(
        "🚀 ~ handleRegenerateAIContent ~ emailBodyIndex:",
        emailBodyIndex
      );
      if (
        emailBodyIndex != -1 &&
        emailBodyIndex < this.AIChoicesForDescription.length - 1
      ) {
        this.AIEmailPayload = {
          subject: this.AIChoicesForDescription[emailBodyIndex + 1].data.Subject
            ? this.AIChoicesForDescription[emailBodyIndex + 1].data.Subject
            : this.AIChoicesForDescription[emailBodyIndex + 1].data.subject,
          body: this.AIChoicesForDescription[emailBodyIndex + 1].data.Body
            ? this.AIChoicesForDescription[emailBodyIndex + 1].data.Body
            : this.AIChoicesForDescription[emailBodyIndex + 1].data.body,
        };
      } else if (
        emailBodyIndex == -1 ||
        emailBodyIndex == this.AIChoicesForDescription.length - 1
      ) {
        this.AIEmailPayload = {
          subject: this.AIChoicesForDescription[0].data.Subject
            ? this.AIChoicesForDescription[0].data.Subject
            : this.AIChoicesForDescription[0].data.subject,
          body: this.AIChoicesForDescription[0].data.Body
            ? this.AIChoicesForDescription[0].data.Body
            : this.AIChoicesForDescription[0].data.body,
        };
      }
    },
    handleGeneratorAction(from) {
      if (from && from == "from prompt") {
        this.$nextTick(() => {
          this.aiPromptContextMenuPositionLeft =
            document
              .getElementById("prompt-action-menu")
              ?.getBoundingClientRect().right + 10;
          this.aiPromptContextMenuPositionTop =
            document
              .getElementById("prompt-action-menu")
              ?.getBoundingClientRect().top - 40;
          this.isAIPromptContextMenuVisible =
            !this.isAIPromptContextMenuVisible;
          console.log(
            "🚀 ~ this.$nextTick ~  document.getElementById(prompt-action-menu)?.getBoundingClientRect():",
            document
              .getElementById("prompt-action-menu")
              ?.getBoundingClientRect()
          );
        });
        return;
      }
      this.$nextTick(() => {
        this.aiContextMenuPositionLeft =
          document.querySelector(".--generator-button")?.getBoundingClientRect()
            .left + 25;
        this.aiContextMenuPositionTop =
          document.querySelector(".--generator-button")?.getBoundingClientRect()
            .top - 80;
        this.isAIContextMenuVisible = !this.isAIContextMenuVisible;
        console.log(
          "🚀 ~ this.$nextTick ~  document.querySelector(.--generator-button)?.getBoundingClientRect():",
          document.querySelector(".--generator-button")?.getBoundingClientRect()
        );
      });
    },
    open(value, index) {
      if (this.getType(value) === "note Log") {
        console.log("open");
        document
          .getElementById("accordion" + index)
          .classList.remove("accordionAdd");
        document
          .getElementById("accordion" + index)
          .classList.add("accordionOpen");
      }
    },
    close(value, index) {
      if (this.getType(value) === "note Log") {
        console.log("close");
        document
          .getElementById("accordion" + index)
          .classList.add("accordionAdd");
        document
          .getElementById("accordion" + index)
          .classList.remove("accordionOpen");
      }
    },
    sortInteractionsByCreationDate(interactions) {
      console.log("sort", typeof interactions, interactions);
      // Sort the interactions array based on the latest creationDate across all interactionItem
      interactions?.sort((a, b) => {
        // Get the latest creationDate from all interactionItem of each interaction
        const latestDateA = Math.max(
          ...a.interactionItem.map((item) => new Date(item.creationDate))
        );
        const latestDateB = Math.max(
          ...b.interactionItem.map((item) => new Date(item.creationDate))
        );

        // Compare the dates
        return latestDateB - latestDateA; // Sorting in descending order
      });

      // Return sorted interactions
      return interactions;
    },
    getType(val) {
      const v = val.interactionItem[0];
      if (v !== undefined) {
        return v["@type"];
      }
    },
    async handleFilesChange(event) {


      // Create the table structure programmatically
      const tableStructure = event
        .map((i, index) => {
          let rows = "";
console.log(i.name,",dmdkmkdm");

          // Add header for the first attachment
          if (index === 0) {
            rows += `
          <tr>
            <th style="
              font-size: 12pt;
              color: black !important;
              border: none !important;
              font-family: system-ui, -apple-system, BlinkMacSystemFont,
                'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'OpenSans',
                'Helvetica Neue', sans-serif;">Attachments</th>
          </tr>
        `;
          }

          // Add each attachment row
          if (i.name) {
            rows += `
          <tr style="font-size: 12pt; border: none !important;">
            <td style="
              font-size: 12pt;
              border: none !important;
              color: #0069c2;
              cursor: pointer;
              text-decoration: underline;
              font-family: system-ui, -apple-system, BlinkMacSystemFont,
                'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'OpenSans',
                'Helvetica Neue', sans-serif;">
              <a href="https://upc-halleyx-ghubgwxrcq-uc.a.run.app/UPC/Quotes/${i.name}">
                ${i.name}
              </a>
            </td>
          </tr>
        `;
          }

          return `
        <table style="margin-top: 0px; padding: 0 20px;">
          ${rows}
        </table>
      `;
        })
        .join("");

      return tableStructure;
    },
    attachmentName(event) {
      const url = this.$router.resolve({ path: `/UPC/Quotes/${event}` });
      window.open(url.href, "_blank");
    },
    async emailAttachment(event) {
      console.log(event,"emailAttachment");
      
      // this.loadAttachmnet = false;
      let formData = new FormData();
      for (let i = 0; i < event.length; i++) {
        formData.append("files[]", event[i]);
      }

      let data = await UPCAxiosInstance.post(
        `/gcpMultiple/v2/upload/`,
        formData
      );
      console.log(data.data, "UPCAxiosInstance handleFilesChange");

      if (data.data.length === 0) {
        return ""; // Return empty string if there are no attachments
      }
      if (data.data) {
        setTimeout(() => {
        this.loadAttachmnet = false;
      }, 2000);
      this.loadAttachmnet = true;
      }
    },
    async valuemail(val) {
      // Await the result of handleFilesChange to resolve the Promise

      var attachmentTable = await this.handleFilesChange(val.Attachments);

      // Log the attachments to verify their structure
      console.log("Final Attachments:", val.Attachments);

      // Filter the contact list as per your logic
      const filteredArray = this.contactList.filter((obj) =>
        val.to.includes(obj.contactMedium[0].characteristic.emailAddress)
      );
      filteredArray;
      var bodyTemp = "";
      var subjectTemp = "";
      if (val.template != "") {
        bodyTemp = val.template;
      } else {
        bodyTemp = this.AIEmailPayload.body;
      }
      if (val.subject != "") {
        subjectTemp = val.subject;
      } else {
        subjectTemp = this.AIEmailPayload.subject;
      }
      // Construct the final payload
      const finalPayload = {
        to: val.to,
        cc: val.cc,
        subject: subjectTemp,
        template: `${bodyTemp}<div>${attachmentTable}</div>`, // Insert attachment table into the template
        // attachments: val.Attachments, // Uncomment if needed
      };

      // Log the final payload to verify structure before sending
      console.log("Final Payload to Send:", finalPayload);

      // Send the email request
      try {
        const response = await MLTAxiosInstance.post("/email", finalPayload);
        console.log("Email sent successfully:", response);
      } catch (error) {
        console.error("Error sending email:", error);
      }
       bodyTemp = "";
       subjectTemp = "";
       this.AIEmailPayload ={subject: "",
       body: ""};
      let payload = {
        id: "",
        reason: "",
        "@type": "",
        itemDate: {
          endDateTime: "",
          startDateTime: "",
        },
        resolution: "",
        creationDate: "",
        lastUpdateDate: "",
        item: {
          role: "",
          "@type": "",
          entity: {
            id: "",
            href: "",
            name: "",
            "@referredType": "",
            "@type": "",
          },
        },
      };
      payload.role = this.currUserRole;
      payload["@type"] = "auto Email Log";
      payload.contact = this.company;
      this.newInteractionPayload.interactionDate.startDateTime =
        this.getCurrentDateTime();
      payload.creationDate = this.getCurrentDateTime();
      payload.reason = val.subject;
      payload.description = val.template;
      payload.direction = "Outbound";
      this.newInteractionPayload.relatedParty = filteredArray;
      // let contact = this.contactList.filter((item) => {
      //   return item['name'] === this.emailPayload.contact ? item : '';
      // });
      // payload.role = contact[0]?.role;
      // this.newInteractionPayload.interactionItem[0] = JSON.parse(
      //   JSON.stringify(contact[0])
      // );
      this.newInteractionPayload.interactionItem[0] = JSON.parse(
        JSON.stringify(payload)
      );

      // this.interactionPayload.reason = this.emailPayload.reason;
      // this.interactionPayload.description = this.emailPayload.body;
      // this.interactionPayload.direction = this.emailPayload.direction;
      // this.interactionPayload.interactionDate.startDateTime = this.emailPayload.date;
      // this.interactionPayload.relatedParty[0] = contact[0];
      let template = {
        contact: "",
        date: "",
        reason: "",
        body: "",
        direction: "",
      };
      this.emailPayload = JSON.parse(JSON.stringify(template));
      this.key += 1;
      console.log(this.newInteractionPayload);
      this.$emit("new-interaction-payload", this.newInteractionPayload);
      this.closeAddEmailLogModal();
    },

    searchKey(val) {
      if (val !== "") {
        this.copyOfInteractionPayload = this.searchInteractionItems(
          this.interactionPayload,
          val
        );
      } else {
        this.copyOfInteractionPayload = this.interactionPayload;
      }
    },
    searchInteractionItems(interactions, searchString) {
      const filteredInteractions = interactions.filter((interaction) => {
        // Convert the interaction object to an array of values
        const values = Object.values(interaction).flatMap((value) => {
          if (typeof value === "object") {
            return Object.values(value).flatMap((subValue) => {
              if (typeof subValue === "object") {
                return Object.values(subValue);
              }
              return subValue;
            });
          }
          return value;
        });

        // Check if any of the values contain the search string
        return values.some((val) => {
          if (
            typeof val === "string" &&
            val?.toLowerCase().includes(searchString?.toLowerCase())
          ) {
            return true;
          }
          return false;
        });
      });

      return filteredInteractions;
    },
    checkNestedObject(obj, searchKey) {
      // Recursive function to check if any values in nested objects (lowercased) match the searchKey
      for (const key in obj) {
        if (typeof obj[key] === "object") {
          // Recursive call for nested objectsA
          if (this.checkNestedObject(obj[key], searchKey)) {
            return true;
          }
        } else if (obj[key].toString()?.toLowerCase().includes(searchKey)) {
          // Check if the lowercased value includes the lowercased searchKey
          return true;
        }
      }

      return false;
    },
    showAddContextMenu() {
      this.containerleft1 =
        document.querySelector(".log-add-btn").getBoundingClientRect().left + 7;
      this.containertop1 =
        document.querySelector(".log-add-btn").getBoundingClientRect().top + 40;
      this.show_add_log_menu = !this.show_add_log_menu;
    },
    showAddNewContextMenu() {
      this.containerleft1 =
        document.querySelector(".new-add-btn").getBoundingClientRect().left - 5;
      this.containertop1 =
        document.querySelector(".new-add-btn").getBoundingClientRect().top + 40;
      this.show_add_new_menu = !this.show_add_new_menu;
    },
    fromAddContextMenu(val) {
      console.log(val);
      this.show_add_log_menu = false;
      if (val === "Log an email") {
        this.showAddEmailLogModal = true;
      } else if (val === "Add a note") {
        this.showNewNoteModal = true;
      } else if (val === "Send email") {
        this.showEmail = true;
        this.componentKey += 1;
      this.key += 1;
    this.AIEmailPayload ={subject: "",
       body: ""};
   
      }
    },
    fromAddNewMenu(val) {
      this.show_add_new_menu = false;
      if (val === "Email") {
        console.log("hi");
        this.showEmail = true;
        // this.showNewEmailLogModal = true
      } else if (val === "Call") {
        // this.showNewCallLogModal = true
      } else if (val === "Note") {
        this.showNewNoteModal = true;
      }
    },
    closeAddEmailLogModal() {
      this.showAddEmailLogModal = false;
      let payload = {
        id: "",
        reason: "",
        "@type": "",
        itemDate: {
          endDateTime: "",
          startDateTime: "",
        },
        resolution: "",
        creationDate: "",
        lastUpdateDate: "",
        item: {
          role: "",
          "@type": "",
          entity: {
            id: "",
            href: "",
            name: "",
            "@referredType": "",
            "@type": "",
          },
        },
      };
      payload["@type"] = "manual Email Log";
      payload.contact = this.emailPayload.contact;
      payload.creationDate = this.emailPayload.date;
      payload.reason = this.emailPayload.reason;
      payload.description = this.emailPayload.body;
      payload.direction = this.emailPayload.direction;
      let contact = this.contactList.filter((item) => {
        return item["name"] === this.emailPayload.contact ? item : "";
      });
      if (contact[0] !== undefined) {
        payload.role = contact[0]?.role;
      }

      let template = {
        contact: "",
        date: "",
        reason: "",
        body: "",
        direction: "",
      };
      this.emailPayload = JSON.parse(JSON.stringify(template));
      this.key += 1;
      this.showAddEmailLogModal = false;
    },
    showEditEmailLogModalFunc(val, index) {
      this.$emit("initialPayload", val);
      let valCopy = JSON.parse(JSON.stringify(val.interactionItem[0]));
      this.emailPayload = valCopy;
      this.emailPayload.reason = valCopy.reason;
      this.emailPayload.body = valCopy.description;
      this.emailPayload.date = valCopy.creationDate;
      this.editEmailIndex = index;
      this.showEditEmailLogModal = true;
      console.log(this.interactionPval, this.showEditEmailLogModal, index);
    },
    showEditCallLogModalFunc(val, index) {
      // console.log(val);
      this.$emit("initialPayload", val);
      let valCopy = JSON.parse(JSON.stringify(val.interactionItem[0]));
      this.editId = val._id;
      this.callPayload = valCopy;
      this.callPayload.type = valCopy.direction;
      this.callPayload.body = valCopy.description;
      this.callPayload.date = valCopy.creationDate;
      this.editCallIndex = index;
      this.showEditCallLogModal = true;
    },
    showDeleteEmailLogModalFunc(index, payload) {
      this.deleteEmailIndex = index;
      this.deleteEmail = payload;

      this.showDeleteEmailLogModal = true;
    },
    showDeleteCallLogModalFunc(index) {
      this.deleteCallIndex = index;
      this.showDeleteCallLogModal = true;
    },
    showDeleteNoteLogModalFunc(index, paylaod) {
      this.deleteNoteIndex = index;
      this.deleteNote = paylaod;
      this.showDeleteNoteLogModal = true;
    },
    addEditEmailLog() {
      let payload = {
        id: "",
        reason: "",
        "@type": "",
        itemDate: {
          endDateTime: "",
          startDateTime: "",
        },
        resolution: "",
        creationDate: "",
        lastUpdateDate: "",
        item: {
          role: "",
          "@type": "",
          entity: {
            id: "",
            href: "",
            name: "",
            "@referredType": "",
            "@type": "",
          },
        },
      };
      payload["@type"] = "manual Email Log";
      payload.contact = this.emailPayload.contact;
      payload.creationDate = this.emailPayload.date;
      payload.reason = this.emailPayload.reason;
      payload.description = this.emailPayload.body;
      payload.direction = this.emailPayload.direction;
      let contact = this.contactList.filter((item) => {
        return item["name"] === this.emailPayload.contact ? item : "";
      });
      if (contact[0] !== undefined) {
        payload.role = contact[0]?.role;
        this.interactionPayload[this.editEmailIndex].relatedParty.push(
          JSON.parse(JSON.stringify(contact[0]))
        );
      }
      this.interactionPayload[this.editEmailIndex].interactionItem[0] = payload;
      console.log(this.interactionPayload[this.editEmailIndex]);
      this.$emit(
        "edit-interaction",
        this.interactionPayload[this.editEmailIndex]
      );
      let template = {
        contact: "",
        date: "",
        reason: "",
        body: "",
        direction: "",
      };
      this.emailPayload = JSON.parse(JSON.stringify(template));
      this.key += 1;
      this.closeEditEmailLogModal();
    },
    addEditCallLog() {
      let payload = {
        id: "",
        reason: "",
        "@type": "",
        itemDate: {
          endDateTime: "",
          startDateTime: "",
        },
        resolution: "",
        creationDate: "",
        lastUpdateDate: "",
        item: {
          role: "",
          "@type": "",
          entity: {
            id: "",
            href: "",
            name: "",
            "@referredType": "",
            "@type": "",
          },
        },
      };
      payload["@type"] = "manual Call Log";
      payload.contact = this.callPayload.contact;
      payload.creationDate = this.callPayload.date;
      payload.reason = this.callPayload.reason;
      payload.description = this.callPayload.body;
      payload.direction = this.callPayload.direction;
      payload.duration = this.callPayload.duration;
      let contact = this.contactList.filter((item) => {
        return item["name"] === this.callPayload.contact ? item : "";
      });
      payload.role = contact[0]?.role;
      this.newInteractionPayload.relatedParty[0] = JSON.parse(
        JSON.stringify(contact[0])
      );
      this.newInteractionPayload.interactionItem[0] = payload;
      // this.newInteractionPayload.id = this.editId;
      console.log(this.newInteractionPayload);
      this.$emit("edit-interaction", this.newInteractionPayload);
      let template = {
        contact: "",
        date: "",
        reason: "",
        body: "",
        direction: "",
      };
      this.callPayload = JSON.parse(JSON.stringify(template));
      this.key += 1;
      this.closeEditCallLogModal();
    },
    addDeleteEmailLog() {
      // this.interactionPayload.interactionItem.splice(this.deleteEmailIndex, 1);
      this.$emit("delete-interaction-data", this.deleteEmail);
      this.$emit("delete-interaction-index", this.deleteEmailIndex);
      this.closeDeleteEmailLogModal();
    },
    addDeleteCallLog() {
      // this.interactionPayload.interactionItem.splice(this.deleteCallIndex, 1);
      this.$emit("delete-interaction-index", this.deleteCallIndex);

      this.closeDeleteCallLogModal();
    },
    closeEditEmailLogModal() {
      this.showEditEmailLogModal = false;
      let payload = {
        id: "",
        reason: "",
        "@type": "",
        itemDate: {
          endDateTime: "",
          startDateTime: "",
        },
        resolution: "",
        creationDate: "",
        lastUpdateDate: "",
        item: {
          role: "",
          "@type": "",
          entity: {
            id: "",
            href: "",
            name: "",
            "@referredType": "",
            "@type": "",
          },
        },
      };
      payload["@type"] = "manual Email Log";
      payload.contact = this.emailPayload.contact;
      payload.creationDate = this.emailPayload.date;
      payload.reason = this.emailPayload.reason;
      payload.description = this.emailPayload.body;
      payload.direction = this.emailPayload.direction;
      let contact = this.contactList.filter((item) => {
        return item["name"] === this.emailPayload.contact ? item : "";
      });
      payload.role = contact[0]?.role;

      let template = {
        contact: "",
        date: "",
        reason: "",
        body: "",
        direction: "",
      };
      this.emailPayload = JSON.parse(JSON.stringify(template));
      this.key += 1;
    },
    closeDeleteEmailLogModal() {
      this.showDeleteEmailLogModal = false;
    },
    closeDeleteCallLogModal() {
      this.showDeleteCallLogModal = false;
    },
    closeDeleteNoteLogModal() {
      this.showDeleteNoteLogModal = false;
    },
    addDeleteNoteLog() {
      // let note = {
      //   reason: '',
      //   description: '',
      //   contact: this.$store.state.userInfo,
      //   role: '',
      // };
      // note.role = this.currUserRole;
      // note.reason = this.newNotePayload.reason;
      // note.description = this.newNotePayload.description;
      // note.creationDate = this.getCurrentDateTime();
      // note['@type'] = 'note Log';
      // note.contact = note.contact.company;
      // this.interactionPayload.note.splice(this.deleteNoteIndex, 1);
      // this.interactionPayload.interactionItem.splice(this.deleteNoteIndex, 1);
      this.$emit("delete-interaction-index", this.deleteNoteIndex);
      this.$emit("delete-interaction-data", this.deleteNote);

      let template = {
        reason: "",
        description: "",
        contact: this.$store.state.userInfo,
        role: "",
      };
      this.newNotePayload = JSON.parse(JSON.stringify(template));
      this.closeDeleteNoteLogModal();
    },
    closeAddCallLogModal() {
      this.showAddCallLogModal = false;
      let payload = {
        id: "",
        reason: "",
        "@type": "",
        itemDate: {
          endDateTime: "",
          startDateTime: "",
        },
        resolution: "",
        creationDate: "",
        lastUpdateDate: "",
        item: {
          role: "",
          "@type": "",
          entity: {
            id: "",
            href: "",
            name: "",
            "@referredType": "",
            "@type": "",
          },
        },
      };
      payload["@type"] = "manual Call Log";
      payload.contact = this.callPayload.contact;
      payload.creationDate = this.callPayload.date;
      payload.reason = this.callPayload.reason;
      payload.description = this.callPayload.body;
      payload.direction = this.callPayload.direction;
      payload.duration = this.callPayload.duration;
      let contact = this.contactList.filter((item) => {
        return item["name"] === this.callPayload.contact ? item : "";
      });
      payload.role = contact[0]?.role;

      let template = {
        contact: "",
        date: "",
        reason: "",
        body: "",
        direction: "",
      };
      this.callPayload = JSON.parse(JSON.stringify(template));
      this.key += 1;
      this.showAddCallLogModal = false;
    },
    closeEditCallLogModal() {
      this.showEditCallLogModal = false;
      let payload = {
        id: "",
        reason: "",
        "@type": "",
        itemDate: {
          endDateTime: "",
          startDateTime: "",
        },
        resolution: "",
        creationDate: "",
        lastUpdateDate: "",
        item: {
          role: "",
          "@type": "",
          entity: {
            id: "",
            href: "",
            name: "",
            "@referredType": "",
            "@type": "",
          },
        },
      };
      payload["@type"] = "manual Call Log";
      payload.contact = this.callPayload.contact;
      payload.creationDate = this.callPayload.date;
      payload.reason = this.callPayload.reason;
      payload.description = this.callPayload.body;
      payload.direction = this.callPayload.direction;
      payload.duration = this.callPayload.duration;
      let contact = this.contactList.filter((item) => {
        return item["name"] === this.callPayload.contact ? item : "";
      });
      payload.role = contact[0]?.role;

      let template = {
        contact: "",
        date: "",
        reason: "",
        body: "",
        direction: "",
      };
      this.callPayload = JSON.parse(JSON.stringify(template));
      this.key += 1;
    },
    closeNewNoteModal() {
      this.showNewNoteModal = false;
      this.showNewNoteModal = false;
      let note = {
        reason: "",
        description: "",
        contact: this.$store.state.userInfo,
        role: "",
      };
      note.role = this.currUserRole;
      note.reason = this.newNotePayload.reason;
      note.description = this.newNotePayload.description;
      note.creationDate = this.getCurrentDateTime();
      note["@type"] = "note Log";
      note.contact = note.contact.company;

      let template = {
        reason: "",
        description: "",
        contact: this.$store.state.userInfo,
        role: "",
      };
      this.newNotePayload = JSON.parse(JSON.stringify(template));
    },
    addNewEmailLog() {
      let payload = {
        id: "",
        reason: "",
        "@type": "",
        itemDate: {
          endDateTime: "",
          startDateTime: "",
        },
        resolution: "",
        creationDate: "",
        lastUpdateDate: "",
        item: {
          role: "",
          "@type": "",
          entity: {
            id: "",
            href: "",
            name: "",
            "@referredType": "",
            "@type": "",
          },
        },
      };
      payload["@type"] = "manual Email Log";
      payload.contact = this.company;
      this.newInteractionPayload.interactionDate.startDateTime =
        this.emailPayload.date;
      payload.creationDate = this.emailPayload.date;
      payload.reason = this.emailPayload.reason;
      payload.description = this.emailPayload.body;
      payload.direction = this.emailPayload.direction;
      let contact = this.contactList.filter((item) => {
        return item["name"] === this.emailPayload.contact ? item : "";
      });
      console.log(contact);
      if (contact[0] !== undefined) {
        // payload.role = contact[0]?.role[0].name;
        // this.newInteractionPayload.relatedParty[0] = JSON.parse(
        //   JSON.stringify(contact[0])
        // );
      }
      payload.role = this.currUserRole;
      this.newInteractionPayload.interactionItem[0] = JSON.parse(
        JSON.stringify(payload)
      );

      // this.interactionPayload.reason = this.emailPayload.reason;
      // this.interactionPayload.description = this.emailPayload.body;
      // this.interactionPayload.direction = this.emailPayload.direction;
      // this.interactionPayload.interactionDate.startDateTime = this.emailPayload.date;
      // this.interactionPayload.relatedParty[0] = contact[0];
      let template = {
        contact: "",
        date: "",
        reason: "",
        body: "",
        direction: "",
      };
      this.emailPayload = JSON.parse(JSON.stringify(template));
      this.key += 1;
      console.log(this.newInteractionPayload);
      this.$emit("new-interaction-payload", this.newInteractionPayload);
      this.closeAddEmailLogModal();
    },
    addNewCallLog() {
      let payload = {
        id: "",
        reason: "",
        "@type": "",
        itemDate: {
          endDateTime: "",
          startDateTime: "",
        },
        resolution: "",
        creationDate: "",
        lastUpdateDate: "",
        item: {
          role: "",
          "@type": "",
          entity: {
            id: "",
            href: "",
            name: "",
            "@referredType": "",
            "@type": "",
          },
        },
      };
      payload["@type"] = "manual Call Log";
      payload.contact = this.callPayload.contact;
      payload.creationDate = this.callPayload.date;
      this.newInteractionPayload.interactionDate.startDateTime =
        this.callPayload.date;
      payload.reason = this.callPayload.reason;
      payload.description = this.callPayload.body;
      payload.direction = this.callPayload.direction;
      payload.duration = this.callPayload.duration;
      let contact = this.contactList.filter((item) => {
        return item["name"] === this.callPayload.contact ? item : "";
      });
      payload.role = contact[0]?.role;
      this.newInteractionPayload.relatedParty[0] = JSON.parse(
        JSON.stringify(contact[0])
      );
      this.newInteractionPayload.interactionItem[0] = JSON.parse(
        JSON.stringify(payload)
      );
      let template = {
        contact: "",
        date: "",
        reason: "",
        body: "",
        direction: "",
      };
      this.callPayload = JSON.parse(JSON.stringify(template));
      this.key += 1;
      this.$emit("new-interaction-payload", this.newInteractionPayload);

      this.closeAddCallLogModal();
    },
    addEditNote() {
      let note = {
        reason: "",
        description: "",
        contact: this.$store.state.userInfo,
        role: "",
      };
      note.role = this.currUserRole;
      note.reason = this.newNotePayload.reason;
      note.description = this.newNotePayload.description;
      note.creationDate = this.getCurrentDateTime();
      note["@type"] = "note Log";
      note.contact = this.company;
      console.log(this.interactionPayload, "this.interactionPayload");
      this.interactionPayload[this.editNoteIndex].note = note;
      this.interactionPayload[this.editNoteIndex].interactionItem[0] = note;
      this.$emit(
        "edit-interaction",
        this.interactionPayload[this.editNoteIndex]
      );
      let template = {
        reason: "",
        description: "",
        contact: this.$store.state.userInfo,
        role: "",
      };
      this.newNotePayload = JSON.parse(JSON.stringify(template));
      this.closeEditNoteModal();
    },
    showEditNoteLogModalFunc(val, index) {
      this.$emit("initialPayload", val);
      this.newNotePayload = JSON.parse(JSON.stringify(val.interactionItem[0]));
      this.editNoteIndex = index;
      this.showEditNoteModal = true;
    },
    closeEditNoteModal() {
      this.showEditNoteModal = false;
      let note = {
        reason: "",
        description: "",
        contact: this.$store.state.userInfo,
        role: "",
      };
      note.role = this.currUserRole;
      note.reason = this.newNotePayload.reason;
      note.description = this.newNotePayload.description;
      note.creationDate = this.getCurrentDateTime();
      note["@type"] = "note Log";
      note.contact = note.contact.company;

      let template = {
        reason: "",
        description: "",
        contact: this.$store.state.userInfo,
        role: "",
      };
      this.newNotePayload = JSON.parse(JSON.stringify(template));
    },
    async addNewNote() {
      let note = {
        reason: "",
        description: "",
        contact: this.$store.state.userInfo,
        role: "",
      };
      note.role = this.currUserRole;
      note.reason = this.newNotePayload.reason;
      note.description = this.newNotePayload.description;
      note.creationDate = this.getCurrentDateTime();
      note["@type"] = "note Log";
      note.contact = this.company;
      // this.interactionPayload.note.push(note);
      this.newInteractionPayload.interactionItem[0] = note;
      this.$emit("new-interaction-payload", this.newInteractionPayload);
      let template = {
        reason: "",
        description: "",
        contact: this.$store.state.userInfo,
        role: "",
      };
      this.newNotePayload = JSON.parse(JSON.stringify(template));
      this.closeNewNoteModal();
    },
    getCurrentDateTime() {
      const currentDate = new Date();

      // Format the date as 'YYYY-MM-DDTHH:mm:ss.SSSZ'
      const formattedDate = currentDate.toISOString();
      return formattedDate;
    },
    sortInteractionItems(payload) {
      // Sort the interactionItem array based on the creationDate in descending order
      // payload.interactionItem.sort((a, b) => {
      //   const dateA = new Date(a.creationDate).getTime();
      //   const dateB = new Date(b.creationDate).getTime();

      //   return dateB - dateA;
      // });

      // return payload;

      return payload.sort((a, b) => {
        const dateA = new Date(a.interactionItem[0].creationDate);
        const dateB = new Date(b.interactionItem[0].creationDate);
        return dateB - dateA;
      });
    },
  },
};
</script>
<style>
.accordionAdd {
  white-space: nowrap;
  width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.accordionOpen {
  word-break: break-word;
  /* margin-bottom: 20px; */
}
</style>
