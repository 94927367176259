<template>
  <div class="users">
    <section class="sidebar">
      <sidebar />
    </section>
    <section class="right-view">
      <div class="breadcrumb">
        <hlx-breadcrumb
          :seperator="'/'"
          :crumbs="crumbs"
          @selected="selected"
        />
      </div>
      <div class="title-container">
        <p class="header"><b>Users</b></p>

        <div class="content">
          <div>
            <hlx-search
              :search-style="'style1'"
              @search-key="search"
            />
          </div>
          <hlx-button class="secondary sm"
            ><i class="icon-filter" />&nbsp;&nbsp;Filter</hlx-button
          >
          <hlx-button class="secondary sm"
            ><i class="icon-download" />&nbsp;&nbsp;Import</hlx-button
          >

          <hlx-button
            class="primary sm"
            @click="modal_active = true"
            ><i class="icon-add-ot" />&nbsp;&nbsp;Invite user</hlx-button
          >
        </div>
      </div>
      <div class="container">
        <div class="tabs-section">
          <hlx-tabs border="top">
            <hlx-tab-pane title="All">
              <hlx-table
                :column-count="theads"
                :border="['table', 'header', 'vertical', 'horizontal']"
                :bold-headers="false"
                :row-hover="false"
                theme="grey"
                :striped-rows="false"
                :height="545"
              >
                <template #thead>
                  <hlx-table-head
                    :align="'left'"
                    :width="73"
                    >S.no</hlx-table-head
                  >
                  <hlx-table-head
                    v-for="(i, index) in headvalue"
                    :key="index"
                    :prop="i.prop"
                    :sortable="i.sortable"
                    :resizable="i.resizable"
                    :align="'left'"
                    :width="145"
                    @sorting_func="sorting_Data"
                    >{{ i.label }}</hlx-table-head
                  >
                  <hlx-table-head></hlx-table-head>
                </template>
                <template #tbody>
                  <tr
                    v-for="(i, index) in paginatedData"
                    id=""
                    :key="index"
                  >
                    <hlx-table-cell
                      :align="'left'"
                      :width="100"
                    >
                      {{ serialNumber(index) }}
                    </hlx-table-cell>
                    <hlx-table-cell
                      v-for="(j, col_index) in headvalue"
                      :key="col_index"
                      :align="'left'"
                      :width="300"
                    >
                      <div v-if="j.prop == 'Status'">
                        <span v-if="i[j.prop] === 'Active'"
                          ><hlx-label class="success sm">Active </hlx-label>
                        </span>
                        <span v-else-if="i[j.prop] === 'Pending'"
                          ><hlx-label class="warning sm">Pending </hlx-label>
                        </span>
                        <span v-else
                          ><hlx-label class="error sm">Inactive</hlx-label>
                        </span>
                      </div>
                      <div v-else>
                        {{ i[j.prop] }}
                      </div>
                    </hlx-table-cell>
                    <hlx-table-cell
                      ><i
                        class="icon-vertical-dots"
                        @click="Contextmenu(index)"
                      >
                        <hlx-context-menu
                          :options="options"
                          :data="index.toString()"
                          :show="i.context['show'] == true"
                          @chosen="closecontextmenu"
                        />
                      </i>
                    </hlx-table-cell>
                  </tr>
                </template>
              </hlx-table>
              <div class="pagination-container">
                <span
                  >Showing {{ showingFrom }} - {{ showingTo }} of
                  {{ totalRows }} rows</span
                >
                <hlx-pagination
                v-model:rows-per-page="externalRowsPerPage"
                  :total="tableData.length"
                  rows-per-page
                  enable-rows-per-page
                  :rows-per-page-list="[10, 20, 30]"
                  @current-page="currentPage"
                  @updated:rows-per-page="changeRowsPerPage"
                ></hlx-pagination>
              </div>
            </hlx-tab-pane>
            <hlx-tab-pane title="Active"> Content for tab 2 </hlx-tab-pane>
            <hlx-tab-pane title="Inactive"> Content for tab 3 </hlx-tab-pane>
            <hlx-tab-pane title="Pending"> Content for tab 4 </hlx-tab-pane>
          </hlx-tabs>
        </div>
      </div>
    </section>
    <div class="invite-user">
      <hlx-modal
        :modal-active="modal_active"
        :height="'300px'"
        :width="'600px'"
        :modal="true"
        @close="modal_active = false"
      >
        <template #header>
          <div>Invite user</div>
        </template>
        <template #content>
          <div class="modal-content">
            <div class="textfield">
              <div class="form-input-wrapper">
                <p class="input-header">Work email</p>
                <div class="form-input">
                  <hlx-input
                    v-model="email"
                    :label-animation="false"
                    label-value="Email"
                    type="email"
                  />
                </div>
              </div>
              <div class="form-input-wrapper secondary">
                <p class="input-header">Role</p>
                <div class="form-input">
                  <hlx-select
                    v-model:value="selectvalue"
                    :options="dropdown_data"
                    :placeholder-value="'Role'"
                    :label-animation="false"
                    :clearable="true"
                    @custom-change="selectValue"
                  ></hlx-select>
                </div>
              </div>
              <span class="icon1"><i class="icon-add-bg"></i></span>
              <!-- <span class="icon2"><i class="icon-trash-regular"></i></span> -->
            </div>
          </div>
        </template>
        <template #footer>
          <div class="button-area">
            <hlx-button
              class="secondary sm"
              @click="modal_active = false"
              >Cancel</hlx-button
            >

            <hlx-button
              class="primary sm"
              @click="modal_active = false"
              >Send</hlx-button
            >
          </div>
        </template>
      </hlx-modal>
    </div>

    <div class="invite-user">
      <hlx-modal
        :modal-active="edit"
        :height="'460px'"
        :width="'360px'"
        :modal="true"
        @close="edit = false"
      >
        <template #header>
          <div>Edit user</div>
        </template>
        <template #content>
          <div class="edit-user-form">
            <div class="form-input-wrapper">
              <p class="input-header">Name</p>
              <div class="form-input">
                <hlx-input
                  :label-animation="false"
                  label-value="johndoe"
                  type="text"
                  :clearable="true"
                />
              </div>
            </div>
            <div class="form-input-wrapper">
              <p class="input-header">Work email</p>
              <div class="form-input">
                <hlx-input
                  :label-animation="false"
                  label-value="johndoe@gmail.com"
                  type="email"
                  :clearable="true"
                />
              </div>
            </div>
            <div class="form-input-wrapper">
              <p class="input-header">Role</p>
              <div class="form-input">
                <hlx-select
                  v-model:value="selectvalue"
                  :options="dropdown_data"
                  :placeholder-value="'Role'"
                  :label-animation="false"
                  :clearable="true"
                  @custom-change="selectValue"
                ></hlx-select>
              </div>
            </div>
            <div class="form-input-wrapper">
              <p class="input-header">Status</p>
              <div class="form-input">
                <hlx-select
                  v-model:value="selected"
                  :options="status_data"
                  :placeholder-value="'Status'"
                  :label-animation="false"
                  :clearable="true"
                  @custom-change="selectValue"
                ></hlx-select>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="footer-form-edit">
            <div><p class="insider">Reset password</p></div>
            <div class="button-area">
              <hlx-button
                class="secondary sm"
                @click="edit = false"
                >Cancel</hlx-button
              >

              <hlx-button
                class="primary sm"
                @click="edit = false"
                >Send</hlx-button
              >
            </div>
          </div>
        </template>
      </hlx-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserView',
  data() {
    return {
      externalRowsPerPage:10,
      dropdown_data: [
        { name: 'Admin', checked: false, disabled: false, id: 32 },
        { name: 'Member', checked: false, disabled: false, id: 33 },
      ],
      status_data: [
        { name: 'Active', checked: false, disabled: false, id: 32 },
        { name: 'Inactive', checked: false, disabled: false, id: 33 },
      ],
      modal_active: false,
      edit: false,
      crumbs: [
        { name: 'Settings', icon: '' },
        { name: 'Users', icon: '' },
      ],
      theads: [
        { name: 'ID', checked: true, id: 1 },
        { name: 'Name', checked: true, id: 2 },
        { name: 'Email', checked: true, id: 3 },
        { name: 'Role', checked: true, id: 4 },
        { name: 'Created on', checked: true, id: 5 },
        { name: 'Last visit', checked: true, id: 6 },
        { name: 'Status', checked: true, id: 7 },
      ],
      headvalue: [
        {
          prop: 'ID',
          label: 'ID',
          sortable: false,
          resizable: true,
          width: 145,
        },
        {
          prop: 'Name',
          label: 'Name',
          sortable: false,
          resizable: true,
          width: 143,
        },
        {
          prop: 'Email',
          label: 'Email',
          sortable: false,
          resizable: true,
          width: 143,
        },
        {
          prop: 'Role',
          label: 'Role',
          sortable: false,
          resizable: true,
          width: 133,
        },
        {
          prop: 'Created_on',
          label: 'Created on',
          sortable: false,
          resizable: true,
          width: 143,
        },
        {
          prop: 'Last_visit',
          label: 'Last visit',
          sortable: false,
          resizable: true,
          width: 143,
        },
        {
          prop: 'Status',
          label: 'Status',
          sortable: false,
          resizable: true,
          width: 90,
        },
      ],
      tableData: [
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID02',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Member',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Inactive',
          context: { show: false },
        },
        {
          ID: 'ID03',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID04',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Pending',
          context: { show: false },
        },
        {
          ID: 'ID05',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Inactive',
          context: { show: false },
        },
        {
          ID: 'ID04',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID03',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Pending',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
        {
          ID: 'ID01',
          Name: 'John Doe',
          Email: 'john@gmail.com',
          Role: 'Admin',
          Created_on: '19-01-2023 11 AM',
          Last_visit: '19-01-2023 11 AM',
          Status: 'Active',
          context: { show: false },
        },
      ],
      currPage: 1,
      rowsPerPage: 10,
      options: [
        {
          label: 'Add',
          icon: 'icon-add',
        },
        {
          label: 'Impersonite',
          icon: 'icon-users-regular',
        },
        {
          label: 'Edit',
          icon: 'icon-edit-regular',
        },
        {
          label: 'Delete',
          icon: 'icon-trash-regular',
        },
        {
          label: 'Deactivate',
          icon: 'icon-toggle-on-alt-regular',
        },
        {
          label: 'Reset',
          icon: 'icon-arrow-forward-circle-alt-regular',
        },
      ],
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.tableData.length;
    },
  },
  methods: {
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    currentPage(val) {
      this.currPage = val;
    },
    changeRowsPerPage(count) {
      this.rowsPerPage = count;
    },
    sorting_Data(data, data1) {
      if (data === 'icon-filled-arrow-up sorting-icon') {
        const x = [...this.tableData].sort(function (a, b) {
          
          if (typeof a === 'object') {
            
            if (typeof a[data1] === 'string') {
              return String(b[data1])
                .toLowerCase()
                .localeCompare(String(a[data1]).toLowerCase());
            } else if (typeof a[data1] === 'number') {
              return b[data1] - a[data1];
            }
          } else if (typeof a === 'string') {
            return String(b)
              .toLowerCase()
              .localeCompare(String(a).toLowerCase());
          } else if (typeof b === 'number') {
            return b - a;
          } else {
            return 0;
          }
          return 0;
        });
        this.tableData = x;
      } else if (data === 'icon-filled-arrow-down sorting-icon') {
        const x = [...this.tableData].sort(function (a, b) {
          if (typeof a === 'object') {
            if (typeof a[data1] === 'string') {
              return String(a[data1])
                .toLowerCase()
                .localeCompare(String(b[data1]).toLowerCase());
            } else if (typeof a[data1] === 'number') {
              return a[data1] - b[data1];
            }
          } else if (typeof a === 'string') {
            return String(a)
              .toLowerCase()
              .localeCompare(String(b).toLowerCase());
          } else if (typeof a === 'number') {
            return a - b;
          } else {
            return 0;
          }
          return 0;
        });
        this.tableData = x;
      } else if (data === 'icon-filled-arrow-up') {
        this.tableData = this.sortData;
      } else if (data === 'icon-filled-arrow-down') {
        this.tableData = this.sortData;
      }
    },
    Contextmenu(index) {
      if (event.target.className == 'icon-vertical-dots') {
        this.tableData[index].context.show = true;
      } else {
        // this.clicked = false;
        this.tableData.forEach((e) => {
          if (e.context != undefined) {
            e.context.show = false;
          }
        });
      }
    },
    closecontextmenu(data1, data2) {
      // let indexvalue = 
      parseInt(data2);
      
      if (data1 == 'Edit') {
        this.edit = true;
      } //(select contextmenuvalue, dataindex value)
    },
  },
};
</script>

<style></style>
