<template>
  <div style="padding: 30px">
    <div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
        "
      >
        <div style="display: flex; align-items: center; gap: 16px">
          <span style="">
            <hlx-file-upload
              :file-type="'.jpg,.jpeg,.png'"
              :file-size-limit="'20mb'"
              :uploadtype="'Rectangle'"
              :style-background="backgrounStyleupload"
              :height="50"
              :width="50"
              :read-only="true"
              :custom-icon="icon"
              :type="'profile-upload'"
              :name-display="payload.customName"
              :content="'Import'"
              :profile-pic-preview="profilePicUrl"
            />
          </span>

          <div style="display: flex; flex-direction: column; gap: 8px">
            <div style="display: flex; align-items: center; gap: 8px">
              <span style="font-weight: bold">{{ payload.customName }}</span>
              <hlx-label
                v-if="payload.status.length > 0 && payload.status === 'Active'"
                class="primary sm"
                >{{ payload.status }}</hlx-label
              >
              <hlx-label
                v-if="
                  payload.status.length > 0 && payload.status === 'Inactive'
                "
                class="error sm"
                >{{ payload.status }}</hlx-label
              >
            </div>
            <div style="color: #a6a6a6; font-size: 12px; width: max-content">
              {{ payload.contactMedium[3].characteristic.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- {{ tableData.length }}{{ paginatedData?.length }} -->
    <div
v-if="contractData?.length<0"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
        height: 80vh;
      "
    >
      <img src="@/assets/images/suppliercontract.svg" alt="" />
    </div>



    <div v-if="contractData?.length > 0" class="header-add-supplier">
    <div class="tablehead-add-supplier">{{ "Contracts" }}</div>
    <div class="search-add-supplier">
      <span
        ><hlx-search v-model="searchvalue" @search-key="productSearch"></hlx-search
      ></span>

      <span v-show="supplierStatus == false"
        ><hlx-button class="primary sm" @click="showNewContactModal">
          <i class="icon-plus-circle-regular"></i>
          Add contracts
        </hlx-button></span
      >
    </div>
  </div>


  <div
  v-if="tableData.length > 0"
      style="height: 78vh; overflow: auto"
      class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
    >
      <hlx-table
        :column-count="accounts_table_theads.length + 2"
        :border="['table', 'header', 'horizontal']"
        :row-hover="true"
        theme="grey"
        :re-arrange-columns="re_arrange"
        :re-arrange-heads="accounts_table_theads_cc"
        @close-rearrange="arrangeColumns"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
            >{{ "S.no" }}</hlx-table-head
          >
          <hlx-table-head
            v-for="(i, index) in accounts_table_theads"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            :width="i.width !== undefined ? i.width : ''"
            :align="i.align !== undefined ? i.align : ''"
            @sorting_func="sorting_Data"
          >
            {{ i.label }}</hlx-table-head
          >
          <hlx-table-head
            v-show="supplierStatus == false"
            :align="'center'"
            :width="70"
            :fixed="'right'"
            ><span class="action-icon">
              <i
                class="icon-settings-regular"
                @click="arrange(true)"
              ></i> </span
          ></hlx-table-head>
        </template>
        <template #tbody>
          <tr v-for="(i, index) in tableData" id="" :key="index">
            <hlx-table-cell :align="'center'" :fixed="'left'">
              {{ serialNumber(index) }}
            </hlx-table-cell>
            <hlx-table-cell
              v-for="(j, col_index) in accounts_table_theads"
              :key="col_index"
              :align="j.align"
              :fixed="j.fixed"
            >
              <div v-if="j.prop.toLowerCase() === 'documentnumber'">
                {{ i[j.prop] }}
              </div>

              <div v-if="j.prop.toLowerCase() === 'name'">
                {{ i[j.prop] }}
              </div>
              <div v-else-if="j.prop.toLowerCase() === 'status'">
                <div>
                  <hlx-status :type="i[j.prop]"></hlx-status>
                </div>
              </div>
              <div
                v-else-if="j?.prop.toLowerCase() === 'type'"
                style="margin: 0.5rem 0"
              >
                {{ i[j.prop] }}
              </div>
              <div
                v-else-if="j.prop.toLowerCase() === 'startdatetime'"
                style="margin: 0.5rem 0"
              >
                {{ formatTime(i?.validFor?.startDateTime) }}
              </div>
              <div
                v-else-if="j.prop.toLowerCase() === 'enddatetime'"
                style="margin: 0.5rem 0"
              >
                {{ formatTime(i?.validFor?.endDateTime) }}
              </div>
              <div
                v-else-if="j.prop.toLowerCase() === 'attachment'"
                style="margin: 0.5rem 0"
              >
                <span style="display: flex; align-items: center; gap: 9px">
                  <span
                    ><img
                      class="file-icon"
                      src="@/assets/svg/multiUpload/pdf.svg"
                      alt=""
                  /></span>

                  <span> {{ i[j.prop][0]?.name }}</span>
                </span>
              </div>
              <div
                v-else-if="j.prop.toLowerCase() === 'validfor'"
                style="margin: 0.5rem 0"
              >
                <hlx-input :checked="i[j.prop].autoRenewal" :type="'switch'" />
              </div>
              <!-- <div v-else>{{ i[j.prop] }}</div> -->
            </hlx-table-cell>
            <hlx-table-cell
              v-show="supplierStatus == false"
              :align="'center'"
              :width="120"
              :fixed="'right'"
            >
              <span :id="'table-context' + index" class="action-icon">
                <i
                  style="position: relative"
                  class="icon-more-vertical-filled"
                  :class="i.context === true ? 'active-action' : ''"
                  @click="crudContextMenu($event, index)"
                >
                  <hlx-context-menu
                    :top="containertop"
                    :left="containerleft"
                    :conditionvalue="150"
                    :conditiondown="2"
                    :conditionup="-38"
                    :options="computedTableAction(i)"
                    :data="index.toString()"
                    :show="i.context == true"
                    @chosen="closeCrudContextMenu($event, i, index)"
                  />
                </i>
              </span>
            </hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
    </div>









    <div
    v-if="tableData.length === 0 && searchvalue !== ''"
      style="height: 78vh; overflow: auto"
      class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
    >
      <hlx-table
        :column-count="accounts_table_theads.length + 2"
        :border="['table', 'header']"
        :row-hover="true"
        theme="grey"
        :re-arrange-columns="re_arrange"
        :re-arrange-heads="accounts_table_theads_cc"
        @close-rearrange="arrangeColumns"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
            >{{ "S.no" }}</hlx-table-head
          >
          <hlx-table-head
            v-for="(i, index) in accounts_table_theads"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            :width="i.width !== undefined ? i.width : ''"
            :align="i.align !== undefined ? i.align : ''"
            @sorting_func="sorting_Data"
          >
            {{ i.label }}</hlx-table-head
          >
          <hlx-table-head
            v-show="supplierStatus == false"
            :align="'center'"
            :width="70"
            :fixed="'right'"
            ><span class="action-icon">
              <i
                class="icon-settings-regular"
                @click="arrange(true)"
              ></i> </span
          ></hlx-table-head>
        </template>

        <template #tbody>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell :colspan="accounts_table_theads.length + 2">{{
              "No data"
            }}</hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
        </template>

      </hlx-table>
    </div>











    <div
    v-if="tableData?.length > 10"
    class="cm-accounts-right-panel-pagination-container"
  >
    <hlx-pagination
      v-model:rows-per-page="externalRowsPerPage"
      :total="tableData?.length"
      rows-per-page
      enable-rows-per-page
      :rows-per-page-list="[10, 15, 20]"
      :options="{
        attributes: {
          rowsPerPageTitle: true,
          rowsPerPageResult: true,
          rowsPerPageDropdown: {
            position: {
              top: 0,
              right: 1,
            },
          },
        },
      }"
      @current-page="currentPage"
      @updated:rows-per-page="changeRowsPerPage"
    ></hlx-pagination>
  </div>


<div
      v-if="tableData?.length <= 0"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
        height: 70vh;
      "
    >
      <img src="@/assets/images/suppliercontract.svg" alt="" />
   
    </div>



  </div>
</template>

<script>
import { MLTAxiosInstance,UPCAxiosInstance } from "@/config/axiosConfig";

export default {
  data() {
    return {
      searchvalue:'',
      contractData:[],
      profilePicUrl: "",
      company: "",
      userId: "",
      payload: {
        attachment: [],
        name: "",
        role: "",
        password: "",
        email: "",
        phone: "",
        tags: [],
        notes: [],
        companyName: "",
        tradingName: "",
        status: "",
        type: "",
        "@baseType": "",
        paymentTerms: [
          {
            name: "",
            id: "",
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: "Canada",
                  flags: "https://flagcdn.com/ca.svg",
                  short: "CA",
                  dial_code: "+1",
                },
                value: "",
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              "@type": "",
            },
          },
        ],
      },



      tableData:[
            // {regioncode:"IN-AN"},
            // {regioncode:"IN-AP"},
            // {regioncode:"IN-TN"},
            // {regioncode:"IN-PN"},
            // {regioncode:"IN-AN"},
            // {regioncode:"IN-AZ"},
            // {regioncode:"IN-AN"},
            // {regioncode:"IN-AP"},
            // {regioncode:"IN-TN"},
        ],



        accounts_table_theads: [
        {
          sortable: false,
          resizable: false,
          width: 150,
          label: "Contract number",
          prop: "documentNumber",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 300,
          label: "Contract name",
          prop: "name",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 100,
          label: "Status",
          prop: "status",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 200,
          label: "Contract type",
          prop: "type",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 150,
          label: "Start date",
          prop: "startDateTime",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 150,
          label: "End date",
          prop: "endDateTime",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 350,
          label: "Documents",
          prop: "attachment",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 150,
          label: "Auto-renewal",
          prop: "validFor",
          align: "left",
        },
      ],





      currPage: 1,
      rowsPerPage: 10,
    };
  },

  
  computed: {
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
  },

  async mounted() {
    const val = await MLTAxiosInstance.get(`partymanagement/individual/auth`);
    this.company = val.data.company;
    this.userId = val.data.userId;
    const access = await MLTAxiosInstance.get(
      `partyRole/get/${val.data.userId}`
    );

    const payload = await MLTAxiosInstance.get(
      `partymanagement/organisation/${this.userId}?company=${this.company}`
    );
    this.payload = payload.data;
    console.log(access, val.data.company, val.data.company, val, "<>");
    const profileName = this.payload.attachment.filter((item) => {
      return item["@type"] !== undefined && item["@type"] === "profile"
        ? item
        : false;
    });
    console.log(profileName[0]?.name);
    this.profilePicName = profileName[0]?.name;
    const url = await UPCAxiosInstance.get(
      `/gcpupload/files/${profileName[0]?.name}`,
      { responseType: "arraybuffer" }
    );
    // console.log(new Uint8Array([url.data,"url incoming"]))
    this.rawProfileUrlData = url.data;
    this.profilePicUrl = await this.bufferToDataURL(url.data);

    this.refeshContract();
  },



    methods: {


      productSearch(val) {
      this.searchvalue = val;
      console.log("search is on")
      if (val !== "") {
        let searchData = this.searchNestedObjects(
          this.contractData,
          val.toLowerCase()
        );
        this.tableData = JSON.parse(JSON.stringify(searchData));
        console.log(this.tableData, "this.tableData");
      } else {
        this.tableData = JSON.parse(JSON.stringify(this.contractData));
      }
    },


    
    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },

      
     async changeRowsPerPage(count) {
      if (this.company != "") {
        this.rowsPerPage = count;
      
      }
    },
    async currentPage(val) {
      if (this.company != "") {
        this.currPage = val;
     
      }
      this.table_height = this.paginatedData.length * 40 + 43;
    },



      formatTime(inputTime) {
      // Parse the input time string
      const date = new Date(inputTime);
 
      // Create an array of month names
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
 
      // Extract the components of the date
      const year = date.getFullYear();
      const month = monthNames[date.getMonth()];
      const day = date.getDate();
     
      // const seconds = date.getSeconds();
 
      // Format the time string
      const formattedTime = `${month} ${day},${year} `;
 
      return formattedTime;
    },



      computedTableAction(data) {
      let options = [
        {
          label: "Edit",
          icon: "icon-file-text-edit-regular",
        },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ];
      return options.filter((option) => {
        if (
          data &&
          data.status &&
          data.status.toLowerCase() == "draft" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "active" &&
          ["Draft", "Mark as active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "inactive" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else {
          return true;
        }
      });
    },


      
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
      
    async refeshContract() {
      let tableData = await UPCAxiosInstance.get(
        `agreementManagement?company=${this.company}`
      );
      console.log(tableData.data, "contract........");
      this.tableData = await tableData.data;
      this.contractData = await tableData.data;
      console.log(tableData.data.length, "length");
    },

    async bufferToDataURL(buffer) {
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = "";
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },}
};
</script>

<style></style>
