<template>
  <div class="dep-flow-breadcrumbs">
    <span>
      <span
        v-for="(crumb, crumbIndex) in breadcrumbItems"
        :key="crumbIndex"
        class="breadcrumb-items"
        :style="crumbIndex == currentFlow + 1 ? { color: '#54bd95' } : {}"
        @click="changeCurrentFlow(crumbIndex - 1)"
      >
        {{ crumb }} &nbsp;
        <i
          v-if="crumbIndex != breadcrumbItems.length - 1"
          style="color: black; top: 2px; position: relative"
          class="icon-angle-right-regular"
        ></i>
        &nbsp;
      </span>
    </span>
  </div>
  <div v-if="currentFlow == -1">
    <config
      :offer-id="offerId == '' ? $route.params.id : offerId"
      :is-dep-flow="true"
      :from-cpq="fromCpq"
      @next-dep-flow="nextDepFlow"
      @close="closePreview"
    ></config>
  </div>
  <div v-else>
    <div
      v-for="(flows, flowIndex) in workOrder"
      :key="flowIndex"
    >
      <!-- <div
        v-if="
          getEntityRef(flows.subsections[2].entityRef) ==
            'Choose specific products' && currentFlow == flowIndex
        "
      >
        offer
      </div> -->
      <div v-if="currentFlow == flowIndex">
        <div
          v-if="flows.showConfig.show == false"
          class="dep-flow-navigation"
        >
          <span
            style="
              position: absolute;
              left: 20px;
              font-weight: 100;
              font-size: 14px;
              cursor: pointer;
            "
            @click="closePreview"
          >
            <i class="icon-arrow-left-regular"></i> &nbsp; Back
          </span>
        </div>
        <div
          v-if="flows.showConfig.show == false"
          class="dep-flow-body-titles"
        >
          <span class="main-heading">{{ flows.subsections[0].value }}</span>
          <span class="sub-heading">{{ flows.subsections[1].value }}</span>
        </div>
        <div
          v-if="flows.showConfig.show == false"
          class="dep-flow-body-cards"
        >
          <relatedProducts
            :data="flows.offerList"
            :only-emit="true"
            @show-config="showConfig($event, flows)"
          ></relatedProducts>
        </div>
        <div v-if="flows.showConfig.show == true">
          <config
            :offer-id="flows.showConfig._id"
            :is-dep-flow="flowIndex == workOrder.length - 1 ? false : true"
            :re-route="false"
            :over-written-dep-price="flows.overWrittenDepPrice"
            @next-dep-flow="nextDepFlow"
            @selected-configuration="finalOffer"
            @close="closePreview"
          ></config>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  //   DMNAxiosInstance,
  // MLTAxiosInstance,
  UPCAxiosInstance,
} from '@/config/axiosConfig';

import config from '@/views/CPQ_Test/configurationTestView.vue';
import relatedProducts from '@/components/UPC/productOffering/relatedProductsListing.vue';

export default {
  components: {
    config,
    relatedProducts,
  },
  props: {
    offerId: {
      type: String,
      default: ''
    },
    dependencyId: {
      type: String,
      default: ''
    },
    fromCpq: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'selected-configuration-from-dep', 'close-dep'],
  data() {
    return {
      workOrder: [],
      currentFlow: -1,
      breadcrumbItems: [],
      offers: []
    };
  },
  async mounted() {
    try {
      let depFlow
      if (this.dependencyId == '') {
        depFlow = await UPCAxiosInstance.get(
          `dependentFlow/${this.$route.params.depId}`
        );
      } else {
        depFlow = await UPCAxiosInstance.get(
          `dependentFlow/${this.dependencyId}`
        );
      }
      console.log(depFlow.data.workOrder, 'depFlow');

      this.workOrder = depFlow.data.workOrder;
      this.breadcrumbItems = [];
      this.breadcrumbItems.push('Configure offer');
      // Use a for...of loop instead of forEach
      for (let item of this.workOrder) {
        console.log(item.subsections[2], 'fk');

        item.showConfig = { _id: '', show: false };
        this.breadcrumbItems.push(item.subsections[0].value);
        if (
          this.getEntityRef(item.subsections[2].entityRef) !==
          'Choose specific products'
        ) {
          if (item.subsections[2].modifyPrice == true) {
            item.overWrittenDepPrice = {};

            item.overWrittenDepPrice['modifiedPricePercent'] =
              item.subsections[2].productOfferingRef[0].modifiedPricePercent;
          }
          // Await the asynchronous function inside the loop
          item.offerList = await this.getListing(item.subsections[2]);
        } else {
          if (item.subsections[2].modifyPrice == true) {
            item.overWrittenDepPrice = {};
            item.overWrittenDepPrice['modifiedPrice'] = true
            item.subsections[2].productOfferingRef[0].prodSpecCharValue.forEach(
              (price) => {
                item.overWrittenDepPrice[price.SKU] = price.tiers[0].price;
                console.log('fk', item.overWrittenDepPrice);
              }
            );
          }
          item.offerList = [];
          for (let offer of item.subsections[2].productOfferingRef) {
            let data = await UPCAxiosInstance.get(
              `/catalogManagement/productOffering/${offer._id}`
            );
            console.log(offer, data, 'sakthi');
            item.offerList.push(data.data);
          }
        }
      }
    } catch (error) {
      console.error(
        'Error fetching dependent flow or processing work order:',
        error
      );
    }
    console.log(this.workOrder, 'fk0');
  },
  methods: {
    closePreview() {
      console.log('clsoe preview called');
      this.$emit('close-dep', true)
    },
    changeCurrentFlow(index) {
      this.currentFlow = index
      if (this.workOrder[index] !== undefined) {
        this.workOrder[index].showConfig.show = false
      }
    },
    nextDepFlow(val) {
      this.flowStarted = true;
      this.currentFlow += 1;
      this.offers.push(val)
      console.log(this.offers, 'mid offer');
      
    },
    finalOffer(val) {
      this.offers.push(val)
      const uniqueOffers = this.offers.filter((offer, index, self) =>
        index === self.findIndex((o) => o.offerId === offer.offerId)
      );
      console.log(this.offers, JSON.parse(JSON.stringify(uniqueOffers)), 'final offer');
      this.$emit('selected-configuration-from-dep', JSON.parse(JSON.stringify(uniqueOffers)),)
    },
    getEntityRef(val) {
      let item = val.filter((obj) => {
        return obj.checked == true;
      });
      console.log(val, item, item[0].value, 'item[0].value');

      return item[0].value;
    },
    async getListing(val) {
      let query = {
        type: 'filter',
        module: 'productOffering',
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 100,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: this.getCategoryQuery(val.productOfferingRef),
        searchQuery: '',
        company: 'developerHalleyx',
      };
      let list = await UPCAxiosInstance.post('/util/filterData', query);
      console.log(list.data.data, 'flowk');
      if (list.data.data != undefined) {
        return list?.data?.data;
      }
    },
    getCategoryQuery(dataArray) {
      // Filter only the items that are checked and not disabled
      const categories = dataArray
        .filter((item) => item.checked && !item.disabled)
        .map((item) => item.name);

      return {
        category: {
          $in: categories,
        },
      };
    },
    showConfig(val, flow) {
      flow.showConfig = JSON.parse(JSON.stringify(val));
    },
  },
};
</script>

<style lang="scss">
.dep-flow-navigation {
  height: 70px;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 20px;
  position: sticky;
  top: 0px;
  background: white;
  z-index: 101010;
}

.dep-flow-body-titles {
  padding: 0 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  .main-heading {
    font-size: 20px;
    font-family: 'QuickSand';
    font-weight: bold;
  }
  .sub-heading {
    margin-top: 10px;
    font-size: 12px;
  }
}
.dep-flow-body-cards {
  padding: 0 20px;
}

.dep-flow-breadcrumbs {
  position: absolute;
  width: 90%;
  display: flex;
  z-index: 10000000;
  align-items: center;
  justify-content: center;
  top: 25px;
  left: 10%;
  .breadcrumb-items {
    cursor: pointer;
  }
}
</style>
