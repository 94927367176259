<template>
    <section class="layout-seperation">
        <!-- back button -->
    <div style="" class="backButtonInApprovalTableView" @click="backToSettings">
      <span style="font-size: 20px"
        ><i class="icon-arrow-left-regular"></i
      ></span>
      <span style="padding: 2px 0px 0px 10px;font-size: 15px;">Back</span>
    </div>
        <section class="layout-right-panel"> 
            <section class="right-panel-container">
                <section class="header">
                   <section class="header-left-section">
                       <p class="title">Automation</p>
                       <p class="sub-title">Mapping Out the Workflow Process</p>
                   </section>
                </section>
                <section class="content">
                    <div class="card-holder">
        <div class="cards-container">
          <hlx-card
            v-for="(card, index) in card_data"
            :key="index"
            class="custom-card"
            :seperator="false"
            :card-title="false"
            :card-footer="false"
            :hover="true"
            height="210"
            :class="{
              '--disable-hover':
                card.card_name && card.card_name.toLowerCase() == 'api',
            }"
            @click="moveTo(card.route)"
          >
            <template #card-body>
              <div
                style="padding: 0 15px"
                :class="{
                  '--disable-hover':
                    card.card_name && card.card_name.toLowerCase() == 'api',
                }"
              >
              <div class="circleInCard">
                <i :class=card.icon></i>
              </div>
                <!-- <img :src="getImgUrl(card.card_body)" :alt="card.card_body" /> -->
                <p class="card-head">
                  <b>{{ card.card_name }}</b>
                </p> 
                <p
                  class="card-desc copy-click"
                  data-tooltip-text="Click to copy"
                  data-tooltip-text-copied="✔ Copied to clipboard"
                >
                  {{ card.card_desc }}
                </p>
              </div>
            </template>
            <template #card-footer>
              {{ card.card_footer }}
            </template>
          </hlx-card>
        </div>
      </div>     
                </section>
            </section>
        </section>
    </section>
</template>

<script>
export default{
   data(){
    return{
    card_data:[
        {
          card_body: "users.svg",
          route: "quoting",
          card_name: "Quoting",
          card_desc: "Configure your quote workflow",
          icon:"icon-papers-text-regular"
        },
        {
          card_body: "general.svg",
          route: "productoffering",
          card_name: "Product offering",
          card_desc: "Map the offering workflow",
          icon:"icon-grid-web-4-regular"
        },
        {
          card_body: "general.svg",
          route: "accounts",
          card_name: "Accounts",
          card_desc: "Configure customer lifecycle",
          icon:"icon-user-settings-regular"
        },
        {
          card_body: "general.svg",
          route: "orders",
          card_name: "Orders",
          card_desc: "Configure MACD orders",
          icon:"icon-user-settings-regular"
        },
        {
          card_body: "general.svg",
          route: "inventory",
          card_name: "Inventory",
          card_desc: "Map the inventory workflow",
          icon:"icon-warehouse-regular"
        },
        {
          card_body: "general.svg",
          route: "supplier",
          card_name: "Supplier",
          card_desc: "Map the supplier workflow",
          icon:"icon-boxes-regular"
        },
        {
          card_body: "general.svg",
          route: "newtemplate",
          card_name: "New template",
          card_desc: "Configure the rule",
          icon:"icon-layout-web-12-regular"
        },
        ]
      }
   },
   methods:{
    backToSettings(){
        this.$router.push('/settings')
    },    
    getImgUrl(pet) {
      //
      // var images = require.context("../assets/", false, /\.svg$/);
      //
      return require("../../assets/images/" + pet);
    },
    moveTo(route) {
            this.$router.push(`/settings/automation/${route}`);
    },
   }
}
</script>
<style lang="scss">
.circleInCard {
  width: 70px;
  height: 70px;
  background-color:#D8EDFC;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0065B2;
  font-size: 30px;
}
</style>