<template>
  <div class="plan-setup">
    <section class="right-container">
      <p class="container-header"><b>Choose plan</b></p>
      <div class="body-content">
        <section class="timeline">
          <hlx-timeline
            :timeline-data="example_data"
            :view="'vertical'"
            :type="'numbered'"
          />
        </section>
        <section class="form-container">
          <div class="switch">
            <hlx-switch
              :switch-items="switch_items"
              @chosen="setPeriod"
            />
          </div>
          <div class="cards-container">
            <hlx-card
              v-for="(card, index) in computedPlans"
              :key="index"
              class="custom-card"
              :seperator="true"
              :card-title="true"
              :card-footer="true"
              :hover="false"
              :active="index == activeCardIndex ? true : false"
              @click="
                setActive(
                  index,
                  card.stripe_price.data,
                  card.stripe_price.data.id
                )
              "
            >
              <template #card-title>
                <section class="plan-header-container">
                  <div class="header-section1">
                    <p class="header-name-left">
                      <b>{{ card.name }}</b>
                    </p>
                    <p class="header-desc-left">{{ card.description }}</p>
                  </div>
                  <div class="header-section2">
                    <p class="header-bill-right">
                      <span class="dollar-symbol"
                        >${{
                          card.stripe_price.data['unit_amount'] / 100
                        }}</span
                      >
                    </p>
                    <p class="header-currency-right">(CAD)per month</p>
                  </div>
                </section>
              </template>
              <template #card-body>
                <div style="padding: 0 15px">
                  <p class="body-head">Features</p>
                  <ul class="body-ul">
                    <li
                      v-for="body in card.prodSpecCharValueUse"
                      :key="body"
                    >
                      {{ body.name }}
                    </li>
                  </ul>
                </div>
              </template>
              <template #card-footer>
                <p class="footer-plan">Choose plan</p>
              </template>
            </hlx-card>
          </div>
        </section>
      </div>
      <div class="footer-container">
        <hlx-button
          class="secondary sm"
          @click="$router.push(`/accountView`)"
          >Back</hlx-button
        >
        <hlx-button
          class="primary sm"
          @click="goToPayment"
          >Next</hlx-button
        >
      </div>
    </section>
  </div>
</template>

<script>
//   import sha256 from 'crypto-js/sha256';
// import hmacSHA512 from 'crypto-js/hmac-sha512';
// import Base64 from 'crypto-js/enc-base64';
// import Crypto from 'crypto-js'
import axios from 'axios';
import cryptoJs from 'crypto-js';
export default {
  name: 'PlanSetup',
  data() {
    return {
      activeCardIndex: 0,
      token: this.$route.params.token,
      userId: '',
      company: '',
      plans: {
        period: 'month',
        month: [],
        year: [],
        all: [],
        selected: '',
      },
      nav_items: [
        {
          name: 'Home',
          label: 'Home',
          icon: 'icon-grid-web-7-regular',
          description_en: 'Home',
          description_fn: '*Home',
        },
        {
          name: 'Residential business market ',
          label: 'About',
          icon: 'icon-code-regular',
          description_en: 'Residential business market ',
          description_fr: '*Residential business market ',
        },
        {
          name: 'Schema',
          label: 'Schema',
          icon: 'icon-desktop-code-regular',
          description_en: 'Small business markets',
          description_fr: '*Small business markets',
        },
        {
          name: 'Database',
          label: 'Database',
          icon: 'icon-database-regular',
          description_en: 'Small business markets',
          description_fr: '*Small business markets',
        },
        {
          name: 'help',
          label: 'Setting',
          icon: 'icon-question-circle-regular',
          description_en: 'Setting',
          description_fr: '*Setting',
          separator: true,
        },
        {
          name: 'setting',
          label: 'settings',
          icon: 'icon-settings-regular',
          description_en: 'Setting',
          description_fr: '*Setting',
          active: true,
        },
        {
          name: 'logout',
          label: 'Logout',
          icon: 'icon-log-out-regular',
          description_en: 'Logout',
          description_fr: '*Logout',
        },
      ],
      card_data2: [
        {
          name: 'Basic',
          price: '12',
          description: 'Basic plan',
          currency: 'usd',
          features: [
            'Content management',
            'Workflow & automation',
            'Customer experience',
            'Makerting',
            'Limited users',
            'B2B capabilities',
            'Configure user setting',
            'Inventory & Order mangement',
          ],
        },
        {
          name: 'Basic',
          price: '12',
          description: 'Basic plan',
          currency: 'usd',
          features: [
            'Content management',
            'Workflow & automation',
            'Customer experience',
            'Makerting',
            'Limited users',
            'B2B capabilities',
            'Configure user setting',
            'Inventory & Order mangement',
          ],
        },
      ],
      example_data: [
        {
          title: '',
          description: '',
          subtitle: 'Complete account setup',
          active: false,
          visited: true,
        },
        {
          title: '',
          description: '',
          subtitle: 'Choose a plan',
          active: true,
          visited: true,
        },
        {
          title: '',
          description: '',
          subtitle: 'Payment information',
          active: false,
          visited: false,
        },
      ],
      switch_items: [
        { name: 'Monthly billing', checked: true },
        { name: 'Annual billing' },
      ],
      fet: process.env.VUE_APP_HOST_PROTOCOL + '://' + process.env.VUE_APP_HOST,
    };
  },
  computed: {
    computedPlans() {
      return this.plans.all.filter((item) => {
        return item.stripe_price.data.recurring.interval == this.plans.period;
      });
    },
  },
  async mounted() {
    let token = {
      headers: {
        Authorization: 'Bearer ' + this.token,
      },
    };
    axios
      .get(this.fet + `/partymanagement/individual/auth`, token)
      .then((val) => {
        
        this.company = val.data.company;
        this.userId = val.data.userId;
      });
    let planRes = await axios({
      method: 'GET',
      url: 'http://localhost:5151/product/offering',
      headers: { Authorization: 'Bearer ' + this.token },
    });
    
    if (planRes.data.data.data) {
      this.plans.all = planRes.data.data.data.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    } else {
      console.error('Something went wrong', planRes);
    }
    this.plans.selected = this.plans.all[0].stripe_price.data.id;
  },
  methods: {
    async setActive(i, data, priceId) {
      if (!priceId) {
        
        return;
      }
      this.activeCardIndex = i;
      this.plans.selected = priceId;
      
      await axios.patch(
        `http://localhost:5090/partymanagement/organisation/${this.company}`,
        { plan: await data.lookup_key }
      );

      
    },
    setPeriod(period) {
      if (period) {
        this.plans.period = period == 'Annual billing' ? 'year' : 'month';
      }
    },
    async goToPayment() {
      let stripeCustomer = await this.CreateUser();
      let { client_secret, subscription } = await this.createSubscription(
        stripeCustomer,
        this.plans.selected
      );
      let encrypted = cryptoJs.AES.encrypt(client_secret, 'myhash').toString();
      this.$router.push({
        name: 'paymentView',
        query: { plan: encrypted, sub: subscription },
        hash: '#pay',
      });
    },
    async createSubscription(customerId, priceId) {
      try {
        let subscription = await axios(
          'http://localhost:5151/subscription/create',
          {
            method: 'POST',
            data: {
              customerId: customerId,
              priceId: priceId,
            },
            headers: {
              Authorization: 'Bearer ' + this.token,
            },
          }
        );
        // if (!subscription.data) {
          
        // }
        return subscription.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async CreateUser() {
      // const posted={
      //   userId:this.userId,
      //   company:this.company
      // }
      // let userInfo = await axios.post(`http://localhost:5151/user/register`,posted)
      
      let userInfo = await axios({
        method: 'POST',
        url: `http://localhost:5151/user/register?userId=${this.userId}&company=${this.company}`,
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      });
      

      if (!userInfo.data) {
        console.error('something went wrong ' + userInfo);
      }
      userInfo = userInfo.data.data;
      return userInfo.stripe_user;
    },
  },
};
</script>

<style></style>
