import axios from 'axios';
import he from 'he';

// Function to perform language translation
export async function languageTransfer(data, lang) {
  try {
    const response = await axios.post(
      `https://translation.googleapis.com/language/translate/v2?key=${process.env.VUE_APP_TRANSLATE_KEY}`,
      { q: data, target: lang },
      { headers: { "Content-Type": "application/json" } }
    );

    const translations = response.data.data.translations.map(
      (t) => t.translatedText
    );

    return he.decode(translations[0] || "");
  } catch (error) {
    console.error("Translation error:", error);
    return "";
  }
}

// Function to update schema labels
export async function updateSchemaLabels(schemaData, preferredLanguages) {
  // Change schemaData._id to id
  if (schemaData._id) {
    schemaData.id = schemaData._id;
    delete schemaData._id;
  }

  const schema = schemaData.schema;

  for (const fieldKey in schema) {
    if (Object.prototype.hasOwnProperty.call(schema, fieldKey)) {
      const field = schema[fieldKey];

      // Add default fields if not present
      const defaultFields = {
        displayType: null,
        type: null,
        required: false,
        show: true,
        tooltipEnglish: '',
        isTooltip: true,
        section: [],
        optionRef: 'referenceDataName',
        isRef: true,
        tooltip: false,
        System: false,
        others: {},
      };
      Object.assign(field, defaultFields, field);

      // Check if 'labels' exists, if not, create it
      if (!field.labels) {
        field.labels = {};
      }

      // Check if English label exists, if not, add it
      if (!field.labels.en) {
        field.labels.en = {
          label: fieldKey,
          name: "English",
          tooltip: fieldKey,
        };
      }

      // Iterate over preferred languages
      for (const language of preferredLanguages) {
        const langCode = language.code;

        // If the preferred language does not exist, translate and add it
        if (!field.labels[langCode]) {
          const translatedLabel = await languageTransfer(
            fieldKey,
            langCode
          );
        //   console.log("translatedLabel:", translatedLabel);

          // Add translated label, name, and tooltip
          field.labels[langCode] = {
            label: translatedLabel || fieldKey,
            name: language.name,
            tooltip: translatedLabel || fieldKey,
          };
        }
      }
    }
  }

  return schemaData;
}
