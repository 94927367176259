<template>
     <iframe v-if="pdfURL" :src="pdfURL" width="100%" height="1000px"></iframe>
</template>

<script>
import axios from "axios"
// import {  UPCAxiosInstance } from "@/config/axiosConfig";
export default{
    data(){
        return{
            pdfURL:''
        }
    },
    async mounted(){
        try {
            const apiKey = '4651efd8d5060bfb670cf710e76ce562:de9717fa1b4b6a11058a74a1678e9fcc078f1e3137057ae5ce229f4cea2035ac5c577c2caea4edfc4fdfd3980be485646e608b81d812fdbed8d60dc60e92da73';
const apiUrl = `https://tmf-upc-ghubgwxrcq-pd.a.run.app/gcpupload/files/${this.$route.params.fileName}`;

// Making a GET request with an API key and expecting a blob response
const urlFetchResponse = await axios.get(apiUrl, {
  responseType: 'blob', // Ensure the response is a blob
  headers: {
    'hlx-api-key': apiKey // Custom header for API key
  }
});


        // Fetch the URL from your API
        // const urlFetchResponse = await UPCAxiosInstance.get(`/gcpupload/files/${this.$route.params.fileName}`, {
        //     responseType: 'blob' // Ensure the response is a blob
        // });
        const fileData = urlFetchResponse.data; // The file data as a blob
        console.log(fileData, "fileData");

        // Create a URL for the Blob
        const url = URL.createObjectURL(fileData);
        this.pdfURL = URL.createObjectURL(fileData)

        // Create a link element and trigger the download
        // const link = document.createElement("a");
        // link.href = url;
        // link.download = this.$route.params.fileName;
        // console.log(link, "link");

        // document.body.appendChild(link);
        // link.click();

        // Clean up
        URL.revokeObjectURL(url);
        // document.body.removeChild(link);
    } catch (error) {
        console.error("Error downloading attachment:", error);
    }
    },
    methods:{
        downloadWithAuth(filename) {
          const url = `https://tmf-upc-ghubgwxrcq-pd.a.run.app/gcpupload/files/${filename}`;

          fetch(url, {
              method: 'GET',
              headers: {
                  'hlx-api-key':"4651efd8d5060bfb670cf710e76ce562:de9717fa1b4b6a11058a74a1678e9fcc078f1e3137057ae5ce229f4cea2035ac5c577c2caea4edfc4fdfd3980be485646e608b81d812fdbed8d60dc60e92da73",
                  'Content-Type': 'application/json'
              }
          })
          .then(response => {
              if (response.ok) {
                  return response.blob();  // Assuming the response is a PDF file
              } else {
                  throw new Error('Network response was not ok');
              }
          })
          .then(blob => {
              const fileURL = URL.createObjectURL(blob);
              window.open(fileURL);  // Open the PDF in a new tab
          })
          .catch(error => {
              console.error('There was a problem with the fetch operation:', error);
          });
      },
    }
}
</script>