<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <div :key="componentkey" class="app-layout">
    <section class="new-right-panel">
      <!-- <hlx-switch
        v-model="selectedValue"
        :switch-items="groupValues"
        :tab-view="true"
        value-prop="value"
        display-prop="name"
        @change="handleCurrentChange"
      /> -->
      <div class="new-right-panel-parent-container no-filter-panel">
        <div class="new-right-panel-left-container"></div>
        <div class="new-right-panel-right-container">
          <section class="new-layout-basic-no-breadcrumb">
            <div class="new-layout-header">
              <span class="new-layout-header-container">
                <span class="schema-header-title">
                  {{staticData.leftPanel.unifiedProductCatalog.category.title.value}}</span>
                <span class="subtitle">
                  {{ staticData.leftPanel.unifiedProductCatalog.category.subTitle.value }}.
                </span>
              </span>
            </div>
            <div class="new-layout-body">
              <hierarchyComponent
                :data="hierarchyData"
                :module="'PCM'"
                @payload="receivedHierarchy"
                @category="addCategoryPayload"
                @delete="deleteCategory"
                @edit-category="editPayload"
                @initial-payload="initialPayload"
                @drag-child="dragChilddata"
                @drag-sibilng="dragSibilingdata"
                @emit-search="emitSearchValue"
              ></hierarchyComponent>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>

  <hlx-drawer
    :show="show_right"
    :width="1000"
    position="right"
    :footer="false"
    :show-close-icon="true"
    @close="closeactivitylog"
  >
    <template #body>
      <div class="activitylog_drawer_componet">
        <activityLogComponent
        :key="activtykey"
          :activity-data="activityLogData"
          :module="'leftPanal'"
          :totalfromprop="activitytotal"
          @activityrowpage="activityrowpage1"
          @activitycurrentpage="activitycurrentpage1"
        ></activityLogComponent>
      </div>
    </template>
  </hlx-drawer>
</template>

<script>
import { UPCAxiosInstance, MLTAxiosInstance } from "@/config/axiosConfig";
import hierarchyComponent from "@/components/CM/addAccountHierarchyComponent.vue";
export default {
  components: {
    hierarchyComponent,
  },
  data() {
    return {
      company:'',
      activityLogData:[],
      module: "PCM",
      hierarchyData: [],
      componentkey: 0,
      notification: {},
      activtykey:0,
      initialPayloads:{},
      categoryquery: {
        type: "filter",
        module: "productcategory",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {
        },
        searchQuery: "",
        company: "",
      },
    };
  },
  computed: {
    staticData() {
      const storedData = JSON.parse(localStorage.getItem('staticDataLanguage'));
      console.log("language 111 staticData",storedData);
      
      if(storedData){
        return storedData
      }else{
        return require(`@/language/defaultLanguage.js`).default.leftPanel.unifiedProductCatalog.category;
      }
    },
  },
  watch: {
    hierarchyData: {
      handler(val) {
        val;
        console.log(val, "llllokdsijf");
      },
    },
    immediate: true,
    deep: true,
  },
  async mounted() {
    const value = await MLTAxiosInstance.get("partymanagement/individual/auth");
    this.company = value.data.company;
    this.categoryquery['company'] = this.company
    let data = await UPCAxiosInstance.post(
      `/catalogManagement/category/search`,this.categoryquery
    );
    console.log('sssssh',data.data)
    // let Hdata = this.removeNullAndEmptyChildren(data?.data);
    // console.log(Hdata, "dataaa");
    this.hierarchyData = data.data;
  },
  methods: {
    emitSearchValue(value){
       this.hierarchyData = value
    },
    initialPayload(val){
      this.initialPayloads=val
    },
    async refreshTable() {
      let data = await UPCAxiosInstance.get(
        `/catalogManagement/category/Allhierarchy`
      );
      if (data?.data.length != 0) {
        let Hdata = this.removeNullAndEmptyChildren(data?.data);
        console.log(data, "refresh data");
        this.hierarchyData = Hdata;
      } else {
        this.hierarchyData = [];
        this.componentkey += 1;
      }
    },
    async categoryDataCalling() {
      let data = await UPCAxiosInstance.get(
        `/catalogManagement/category/Allhierarchy`
      );
      console.log(data, "dataaaka");

      let Hdata = this.removeNullAndEmptyChildren(data?.data);
      console.log(data, "dataaa");
      this.hierarchyData = Hdata;
    },
    async receivedHierarchy(payload, id, type, currPayload) {
      console.log(id, "lalalala");
  
      if (type == "Add parent") {
       
        let parentId = await UPCAxiosInstance.post(
          `/catalogManagement/category`,
          payload
        );
        parentId;
        let updatechild = await UPCAxiosInstance.patch(
          `/catalogManagement/category`,
          currPayload
        );
        updatechild;
        
        this.refreshTable();
        // let parentAdd = await UPCAxiosInstance.post(`/catalogManagement/category/parent/${id}`,{parentId:parentId._id})

        
        if (updatechild.data) {
          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };
        }
      } else if (type == "Add child") {
        console.log("adding child ++");
        let addpayload = payload.child;
        addpayload["isRoot"] = false;
        addpayload.parentId = currPayload._id;
        console.log(addpayload, "payload from child", currPayload);
        let data = await UPCAxiosInstance.post(
          `/catalogManagement/category`,
          addpayload
        );
        let additionalChargeLogPayload = {
          message: `${addpayload.name} category has been added as a child of ${currPayload.name}`,
          "@type": "category",
          detailedAction: [],
          category: ["category"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
    
          action: "ADDED",
        };
        this.initLog(additionalChargeLogPayload);
        console.log(data.data, "ressss");
        if (data.data == "Category already exists" && data.status == 201) {
          this.notification = {
            type: "danger",
            message: `The ${addpayload.name} category already exists`,
          };
        } else {
          let res = data.data;
          let data1 = currPayload?.subCategory;
          if (data1 == undefined) {
            data1 = [];
          }
          res.id = res._id;
          console.log(res, "data res", currPayload.subCategory);

          data1.push(res);

          console.log(data1, "lalal data");
          currPayload.id = currPayload._id;
          currPayload.subCategory = data1;
          delete currPayload._id;
          if (data.status == 201) {
            let data2 = await UPCAxiosInstance.patch(
              `/catalogManagement/category`,
              currPayload
            );
            data2;
            this.notification = {
              type: "success",
              message: `Done! Nice work! Your new category is now in the list!`,
            };
            this.refreshTable();
          }
        }
      }
      //   this.categoryDataCalling()
    },
    async addCategoryPayload(val) {
      val;
      console.log(val, "dataaaaaaaaaa");
      let data = await UPCAxiosInstance.post(
        `/catalogManagement/category`,
        val
      );
      data;

      if (data.data == "Category already exists" && data.status == 201) {
        console.log(data.data, "lolll");
        this.notification = {
          type: "danger",
          message: `The ${val.name} category already exists`,
        };
      } else if (data.status == 201 && Object.keys(data.data).length != 0) {
        let additionalChargeLogPayload = {
          message: `${val.name} category has been added`,
          "@type": "category",
          detailedAction: [],
          category: ["category"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
    
          action: "ADDED",
        };
        // console.log(payload, id, currPayload, "inside if");
        this.initLog(additionalChargeLogPayload);
        this.notification = {
          type: "success",
          message: `Done! Nice work! Your new category is now in the list!`,
        };
        let data1 = await UPCAxiosInstance.get(
          `/catalogManagement/category/Allhierarchy`
        );
        console.log(data1, "dataaaka");
        //   let Hdata =  this.removeNullAndEmptyChildren(data?.data)
        console.log(data1, "dataaa");
        this.hierarchyData = data1?.data;
      }
      console.log(data, "data");
      // this.categoryDataCalling()
    },
    async editPayload(payload) {
      //  if(payload._id != undefined)
      //  {
        let updatedAdditionalChargeLogPayload = {
        message: `${payload.name} category has been updated`,
        "@type": "category",
        detailedAction: [],
        category: ["category"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        action: "UPDATED",
      };
      updatedAdditionalChargeLogPayload;
      let nameActionLog = this.buildBasicLogByDifferences(
        this.initialPayloads,
        payload,
        "name",
        "Name"
      );
      let descriptionActionLog = this.buildBasicLogByDifferences(
        this.initialPayloads,
        payload,
        "description",
        "Description"
      );
      let typeActionLog = this.buildBasicLogByDifferences(
        this.initialPayloads,
        payload,
        "type",
        "Type"
      );
      let identifierActionLog = this.buildBasicLogByDifferences(
        this.initialPayloads,
        payload,
        "identifier",
        "Identifier" 
      );
      let isSellabeActionLog = this.buildBasicLogyesOrno(
        this.initialPayloads,
        payload,
        "isSellabe",
        "Cannot be sold alone" ,
        "True",
        "False"
      );
      let pricingTypeActionLog = this.findArrayOfStringDifferences(
        this.initialPayloads.pricingType,
        payload.pricingType,
        "pricingType",
        "Pricing Type"
      );
      updatedAdditionalChargeLogPayload.detailedAction =
        updatedAdditionalChargeLogPayload.detailedAction.concat(
          pricingTypeActionLog,
          identifierActionLog,
          typeActionLog,
          nameActionLog,
          isSellabeActionLog,
          descriptionActionLog
        )

        this.initLog(updatedAdditionalChargeLogPayload);
      payload.id = payload._id;
      delete payload._id;
      //  }
      let editPayload = payload;
      console.log(payload, "edited");
      let data = await UPCAxiosInstance.patch(
        `/catalogManagement/category`,
        editPayload
      );
      console.log(data, "edittt");
      if (data.data == "Category already exists" && data.status == 200) {
        console.log(data.data, "lolll");
        this.notification = {
          type: "danger",
          message: `The ${editPayload.name} category already exists`,
        };
      } else if (data.status === 200 && Object.keys(data.data).length != 0) {
        this.notification = {
          type: "success",
          message: `All set! Your changes have been saved successfully!`,
        };
        this.refreshTable();
      }
    },
    async deleteCategory(id) {
      console.log(id.name, "name");
      let data = await UPCAxiosInstance.delete(
        `/catalogManagement/category/remove/${id.name}`
      );
      const deleteAdditionalChargeLogPayload = {
        message: `${id.name} category has been removed`,
        "@type": "category",
        detailedAction: [],
        category: ["category"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        action: "DELETED",
      };
      if (data.status === 200) {
        this.initLog(deleteAdditionalChargeLogPayload);
        this.notification = {
          type: "success",
          message: `Done! Your item has been removed.`,
        };
        this.refreshTable();
      }
      // this.refreshTable()
      // data;
    },
    async dragChilddata(targetid, obj, type, dragdata, targetdata) {
      console.log(dragdata.parentId, targetdata.id, "drag dataaa");
      let dragobj = {};
      dragobj["from"] = dragdata;
      let id = targetdata._id;
      dragobj["to"] = targetdata;
      dragobj["to"]["id"] = id;
      delete dragobj["to"]["_id"];
      console.log(dragobj);
      let data = await UPCAxiosInstance.patch(
        `/catalogManagement/category/dragLevel`,
        dragobj
      );
      const deleteAdditionalChargeLogPayload = {
        message: `${dragobj.from.name} is mapped as a child of  ${dragobj.to.name}`,
        "@type": "category",
        detailedAction: [],
        category: ["category"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        action: "drag",
      };
      data;
      this.initLog(deleteAdditionalChargeLogPayload);
      this.refreshTable();
    },
    async dragSibilingdata(targetid, obj, type, dragdata, targetdata) {
      console.log(targetid, obj, type, dragdata, targetdata, "sibilingdataaaa");
      let dragobj = {};
      let dragid = dragdata._id;
      dragobj["from"] = dragdata;
      dragobj["from"]["id"] = dragid;
      delete dragobj["from"]["_id"];
      let id = targetdata._id;
      dragobj["to"] = targetdata;
      dragobj["to"]["id"] = id;
      delete dragobj["to"]["_id"];
      console.log(dragobj);
      let data = await UPCAxiosInstance.patch(
        `/catalogManagement/category/sibiling`,
        dragobj
      );
      const deleteAdditionalChargeLogPayload = {
        message: `${dragobj.from.name} is mapped as a independent category`,
        "@type": "category",
        detailedAction: [],
        category: ["category"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        action: "drag",
      };
      
      this.initLog(deleteAdditionalChargeLogPayload);
      data;
      this.refreshTable();
    },
    removeNullAndEmptyChildren(payload) {
      console.log(payload, "remove null");
      if (Object.keys(payload).length != 0) {
        // Recursive function to check and remove null children
        const removeNullChildrenRecursive = (node) => {
          if (node && Array.isArray(node.subCategory)) {
            node.subCategory = node.subCategory.filter(
              (subCategory) =>
                subCategory !== null && removeNullChildrenRecursive(subCategory)
            );

            if (node.subCategory.length === 0) {
              delete node.subCategory; // Remove 'subCategory' attribute if empty
            }
          }
          return node; // Returning the modified node
        };

        // Loop through the payload and remove null and empty children
        return payload.map((item) => removeNullChildrenRecursive(item));
      }
    },
  },
};
</script>
