<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">
        <!-- Delete data -->
        <span v-if="staticData">{{ staticData?.deleteData?.label[loginUserDetails]?.label }}</span>
      </div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          <!-- Are you sure you want to delete  -->
          <span v-if="staticData">{{ staticData?.cmAttachmentModalMessage1?.label[loginUserDetails]?.label }}</span>
          <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >
        <!-- Cancel -->
        <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >
        <!-- Delete -->
        <span v-if="staticData">{{ staticData?.delete?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
    </template>
  </hlx-modal>
  <div class="upcv2-overview">
    <section class="right-view-body-feature">
      <span
        class="spec-feature --table-tool-bar"
        style="display: flex; flex-direction: column; align-items: stretch"
      >
        <div
          class="--table-tool-bar-wrapper"
          style="
            display: flex;
            align-items: center;
            margin-bottom: 1%;
            margin-left: 0 !important;
            margin-top: 1%;
          "
        >
          <p
            v-if="attachmentData.length > 0 || searchattach !== ''"
            class="--upcv2-body-title"
          >
            <!-- Documents -->
            <span v-if="staticData">{{ staticData?.cmAccountLeftPanelDocuments?.label[loginUserDetails]?.label }}</span>
          </p>
          <div
            v-if="attachmentData.length > 0 || searchattach !== ''"
            style="display: flex; margin-left: auto; gap: 2%"
          >
            <span class="util-items search"
              ><hlx-search
              v-model="searchattach"
              :label-value=staticData?.search?.label[loginUserDetails]?.label
                @search-key="attachmentSearch"
              ></hlx-search
            ></span>
            <!-- Filter -->

            <span class="util-items util-items-add">
              <!-- <hlx-button
                class="primary sm add-btn"
                @click.stop="modal_active = true"
                ><i
                  class="icon-plus-circle-regular"
                  style="margin-right: 5px"
                ></i
                >Add attachment</hlx-button
              > -->
              <span v-show="supplierStatus == false">
              <hlx-multi-upload
                :file-type="'.jpg,.jpeg,.png,.json,.xlsx,.doc,.pdf,.txt'"
                :file-size-limit="'20 Mb'"
                :upload-button-name="'Document'"
                @native-data="getvaluearr"
              >
              </hlx-multi-upload>
              </span>
            </span>
          </div>
        </div>
        <div
          v-if="attachmentData.length > 0"
          style="overflow-y: auto; max-height: 76vh; min-height: 76vh"
        >
          <hlx-table
            :label-value="tableModalLang"
            :column-count="attachment_table.length + 2"
            :border="['table', 'header', 'horizontal']"
            :row-hover="true"
            theme="grey"
          >
            <template #thead>
              <hlx-table-head
                :width="60"
                :align="'center'"
                style="padding: 0 15px"
                :fixed="'left'"
                >
                <!-- {{ "S.no" }} -->
                  {{ loadStaticData('LANG-0046') }}
                </hlx-table-head
              >
              <hlx-table-head
                v-for="(i, index) in attachment_table"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
              >
                {{ i.label }}</hlx-table-head
              >
              <hlx-table-head v-show="supplierStatus == false" :align="'center'" :width="70" :fixed="'right'"
                ><span class="action-icon">
                  <i
                    class="icon-settings-regular"
                    style="cursor: context-menu !important"
                  ></i> </span
              ></hlx-table-head>
            </template>
            <template #tbody>
              <tr v-for="(i, index) in attachedPaginated" id="" :key="index">
                <hlx-table-cell :align="'center'" :fixed="'left'">
                  {{ serialNumber(index) }}
                </hlx-table-cell>
                <hlx-table-cell
                  v-for="(j, col_index) in attachment_table"
                  :key="col_index"
                  :align="j.align"
                  :fixed="j.fixed"
                >
                  <div v-if="j.prop.toLowerCase() === 'name'">
                    <div style="display: flex; gap: 8px; align-items: center">
                      <span v-if="getFileExtension(i[j.prop]) === 'txt'"
                        ><img
                          class="file-icon"
                          src="@/assets/svg/multiUpload/text.svg"
                          alt=""
                      /></span>
                      <span v-if="getFileExtension(i[j.prop]) === 'docx'"
                        ><img
                          class="file-icon"
                          src="@/assets/svg/multiUpload/word.svg"
                          alt=""
                      /></span>
                      <span v-if="getFileExtension(i[j.prop]) === 'png'"
                        ><img
                          class="file-icon"
                          src="@/assets/svg/multiUpload/png.svg"
                          alt=""
                      /></span>
                      <span v-if="getFileExtension(i[j.prop]) === 'pdf'"
                        ><img
                          class="file-icon"
                          src="@/assets/svg/multiUpload/pdf.svg"
                          alt=""
                      /></span>
                      <span v-if="getFileExtension(i[j.prop]) === 'json'"
                        ><img
                          class="file-icon"
                          src="@/assets/svg/multiUpload/json.svg"
                          alt=""
                      /></span>
                      <span v-if="getFileExtension(i[j.prop]) === 'jpg'"
                        ><img
                        style=" width:20px;margin: 0 5px 0 6px;"
                          class="file-icon"
                          src="@/assets/svg/multiUpload/jpg.svg"
                          alt=""
                      /></span>
                      <span v-if="getFileExtension(i[j.prop]) === 'jpeg'"
                        ><img
                          class="file-icon"
                          src="@/assets/svg/multiUpload/jpeg.svg"
                          alt=""
                      /></span>
                      <span v-if="getFileExtension(i[j.prop]) === 'xlsx'"
                        ><img
                          class="file-icon"
                          src="@/assets/svg/multiUpload/excel.svg"
                          alt=""
                      /></span>

                      <span>{{ i[j.prop] }}</span>
                    </div>
                  </div>

                  <div v-if="j.prop.toLowerCase() === 'attachment'">
                    {{ formatFileSize(i["size"]) }}
                  </div>

                  <div v-if="j.prop.toLowerCase() === 'lastupdated'">
                    {{ dateAndTimeFormatter(i[j.prop]) }}
                  </div>

                  <div
                    v-else-if="j.prop.toLowerCase() === 'uploaded'"
                    style="margin: 0.5rem 0"
                  >
                    {{ $route.params.companyName }}
                  </div>

                  <!-- <div v-else>{{ i[j.prop] }}</div> -->
                </hlx-table-cell>
                <hlx-table-cell v-show="supplierStatus == false" :align="'center'" :width="120" :fixed="'right'">
                  <span
                    :id="'table-context' + serialNumber(index - 1)"
                    class="action-icon"
                  >
                    <i
                      style="position: relative"
                      class="icon-more-vertical-filled"
                      :class="i.context === true ? 'active-action' : ''"
                      @click="
                        attachmentContextMenu($event, serialNumber(index - 1))
                      "
                    >
                      <hlx-context-menu
                        :top="containertop"
                        :display="'display'"
                        :left="containerleft"
                        :conditionvalue="150"
                        :conditiondown="2"
                        :conditionup="-38"
                        :options="attachmentTableAction(i)"
                        :data="index.toString()"
                        :show="i.context == true"
                        @chosen="attachmentCrudContextMenu($event, i, index)"
                      />
                    </i>
                  </span>
                </hlx-table-cell>
              </tr>
            </template>
          </hlx-table>
        </div>
        <div
          v-if="attachmentData.length === 0 && searchattach !== ''"
          style="overflow-y: auto; max-height: 76vh; min-height: 76vh"
        >
          <hlx-table
            :column-count="attachment_table.length + 2"
            :border="['table', 'header']"
            :row-hover="true"
            theme="grey"
          >
            <template #thead>
              <hlx-table-head
                :width="60"
                :align="'center'"
                style="padding: 0 15px"
                :fixed="'left'"
                >
                <!-- {{ "S.no" }} -->
                  {{loadStaticData('LANG-0046')}}
                </hlx-table-head
              >
              <hlx-table-head
                v-for="(i, index) in attachment_table"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
              >
                {{ i.label }}</hlx-table-head
              >
              <hlx-table-head v-show="supplierStatus == false" :align="'center'" :width="70" :fixed="'right'"
                ><span class="action-icon">
                  <i
                    class="icon-settings-regular"
                    style="cursor: context-menu !important"
                  ></i> </span
              ></hlx-table-head>
            </template>
            <template #tbody>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell :colspan="5">
                  <!-- {{ "No data" }} -->
                    {{ loadStaticData('LANG-0050') }}
                </hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
              <tr>
                <hlx-table-cell></hlx-table-cell>
              </tr>
            </template>
          </hlx-table>
        </div>
      </span>
    </section>
    <div v-if="attachmentData.length > 0" class="pagination-container">
      <hlx-pagination
        v-model:rows-per-page="externalRowsPerPage"
          :label-value=paginationLang
        :total="attachmentData.length"
        enable-rows-per-page
        :rows-per-page-list="[10, 15, 20]"
        :options="{
          attributes: {
            rowsPerPageTitle: true,
            rowsPerPageResult: true,
            rowsPerPageDropdown: {
              position: {
                top: 0,
                right: 1,
              },
            },
          },
        }"
        @current-page="currentPage"
        @updated:rows-per-page="changeRowsPerPage"
      ></hlx-pagination>
    </div>
    <section
      v-if="attachmentData.length <= 0 && searchattach === ''"
      style="
        margin-top: 3.2rem;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        align-items: center;
      "
    >
      <img
        src="@/assets/images/addAccountAttachments.svg"
        alt=""
        style="width: 500px"
      />

      <span v-show="supplierStatus == false" class="util-items util-items-add">
        <!-- <hlx-button
          class="primary sm add-btn"
          @click="modal_active = true"
          ><i
            class="icon-plus-circle-regular"
            style="margin-right: 5px"
          ></i
          >Add Attachment</hlx-button
        > -->
        <hlx-multi-upload
          :file-type="'.jpg,.jpeg,.png,.json,.xlsx,.doc,.pdf,.txt'"
          :file-size-limit="'20 Mb'"
          :upload-button-name="'Document'"
          :label-value="uploadDataLang"
          @native-data="getvaluearr"
        >
        </hlx-multi-upload>
      </span>
    </section>
    <!-- <AttachmentsComponent :data="productpayload"></AttachmentsComponent> -->
  </div>

  <!-- Attachment panel -->
  <hlx-drawer
    :show="modal_active"
    position="right"
    width="400"
    :footer="true"
    :title="'Add attachment'"
    :show-close-icon="true"
    @close="modal_active = false"
  >
    <template #body>
      <div
        class="header-area-contact-drawer"
        style="
          border-bottom: 1px solid #d4d4d4;
          padding-bottom: 5px;
          padding: 4px 0 13px 0;
        "
      >
        <b>
          <!-- Add attachment -->
          <span v-if="staticData">{{ staticData?.addAttachment?.label[loginUserDetails]?.label }}</span>
        </b>
      </div>
      <div class="body-area-contact-drawer" style="margin-top: 50px">
        <hlx-file-upload
          :file-type="'.jpg,.jpeg,.png,.json,.xlsx,.doc,.pdf,.txt'"
          :file-size-limit="'1gb'"
          :custom-icon="icon"
          :type="'upc-regular'"
          :style-background="backgrounStyleupload"
          :icon="'icon-arrow-down-to-line-filled'"
          :content="'Import'"
          @file-data="imgDataPrimary($event)"
          @delete-image="funcdelete"
          @download-image="func1"
        />
      </div>
    </template>
    <template #footer>
      <div
        style="
          display: flex;
          justify-content: flex-end;
          padding: 10px;
          border-top: 1px solid #d8d8d8;
        "
      >
        <hlx-button class="secondary sm" @click="modal_active = false"
          >
          <!-- Cancel -->
          <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
          </hlx-button
        >
        <hlx-button
          class="primary sm"
          style="margin-left: 10px"
          @click="refreshUpload"
          >
          <!-- Add -->
          <span v-if="staticData">{{ staticData?.add?.label[loginUserDetails]?.label }}</span>
          </hlx-button
        >
      </div>
    </template>
  </hlx-drawer>
</template>

<script>
import {
  //   DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";

export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    module: {
      type: String,
      default: "CM",
    },
    userDetails: {
      type: Object,
      default: () => {
        return {
          name: "developerHalleyx",
          id: "65d34902237a9575f79cf80e", // change default value after settings page is fixed
        };
      },
    },
       supplierStatus:{
       type: Boolean,
      default: false,
    }
  },
  emits: ["upload-data", "delete-index"],
  data() {
    return {
      loginUserDetails: {},
      staticData:null,
      searchattach: "",
      delete_modal_active: false,
      delete_name: "",
      deleterowindex: "",
      modal_active: false,
      backgrounStyleupload: {
        backgroundColor: "",
        bacgroundtTextColor: "#A8A8A8",
        border: false,
        borderColor: "#A8A8A8",
        TextfontSize: 16,
      },
      notification: {},
      attachmentData: [],
      ogAttachmentData: [],
      currPage: 1,
      rowsPerPage: 10,
      externalRowsPerPage: 10,
      attachment_table: [
        {
          sortable: false,
          resizable: false,
          width: null,
          label: this.loadStaticData('LANG-0074')|| "Filename",
          prop: "name",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 150,
          label: this.loadStaticData('LANG-0075')|| "File size",
          prop: "attachment",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 250,
          label: this.loadStaticData('LANG-0045')|| "Last updated",
          prop: "lastUpdated",
          align: "left",
        },
      ],
    };
  },
  computed: {
    
    uploadDataLang() { 
      return {
          Add: this.loadStaticData('LANG-0010') || "Add",
          Select_files:this.loadStaticData('LANG-0076') || "Select files",
          or_drag_and_drop:this.loadStaticData('LANG-0077') ||  "or drag and drop",
          Max:this.loadStaticData('LANG-0078') ||  "Max",
          Cancel:this.loadStaticData('LANG-0020') ||  "Cancel"
        }
    },

    tableModalLang() { 
      return {
          re_arrange_columns : this.loadStaticData('LANG-0026'),
          Cancel:this.loadStaticData('LANG-0020'),
          Apply: this.loadStaticData('LANG-0027'),
          SelectAll: this.loadStaticData('LANG-0028')
        }
    },
    paginationLang() {     
      return {
          Rows_per_page : this.loadStaticData("LANG-0012"),
          Showing: this.loadStaticData("LANG-0013"),
          of: this.loadStaticData("LANG-0014"),
          results: this.loadStaticData("LANG-0015"),
          Go_to: this.loadStaticData("LANG-0016")
        }
      
    },
    attachedPaginated() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.attachmentData.slice(start, end);
    },

    // attachmentData() {
    //   // Sort the array based on your criteria
    //   return this.data.slice().sort((a, b) => b.id - a.id); // Example sorting based on ID
    // },
  },
  watch: {
    data: {
      handler(val) {
        console.log(val, "data attachment");

        this.attachmentData = JSON.parse(JSON.stringify(val));
        this.attachmentData = this.attachmentData.filter((i)=> {
          return i['@type'] !== 'profile'
        })
        this.attachmentData.sort(
          (a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated)
        );
        this.ogAttachmentData = JSON.parse(JSON.stringify(val));
       
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    await this.$store.dispatch("loginTenantDetails");
    await this.$store.dispatch("loginUserDetails");
    // loginUserDetails
    // await this.$store.dispatch("loginTenantDetails");
    // let r = await this.$store.getters.loginTenantDetails;
    console.log("loginTenantDetails resrfsef:", this.$store.getters.loginTenantDetails);
    console.log("loginUserDetails resrfsef:", this.$store.getters.loginUserDetails);

    this.loginUserDetails = this.$store?.getters?.loginUserDetails?.languageAbility[0]?.code;

    // this.loginUserDetails.languageAbility[0].name
    // this.loginUserDetails.languageAbility[0].code

    try {
      let organisationInfo = await UPCAxiosInstance.get('/multi-language');
      this.staticData = organisationInfo?.data?.filter(itm => itm && itm.modules)?.[0]?.modules;
      console.log("this.staticData:",organisationInfo , this.staticData);
      
    }
    catch (error) {
      console.error("error::", error)
    }


    // let payload = {};
    // if (this.module === 'CM') {
    //   payload = await MLTAxiosInstance.get(
    //     `/partyManagement/organisation/${this.userDetails.name}/${this.userDetails.id}`
    //   );
    // } else {
    //   payload = await UPCAxiosInstance.get(`document/${this.userDetails.name}`);
    // }
    // this.attachmentData = await payload.data.attachment;
  },
  methods: {
    async handleFileUpload(event) {
      // Update selectedFile when a file is selected
      let selectedFiles = event.target.files;
      let formData = new FormData();

      // Append all selected files to FormData object
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("files[]", selectedFiles[i]);
      }

      // You can now send this formData to your server using AJAX or Axios
      // For simplicity, let's just log it here
      console.log("Uploaded File:", formData.get("file"));
      await UPCAxiosInstance.post(`/gcpMultiple/v2/upload/`, formData);
    },
    async getvaluearr(event) {
      console.log(event,"eventevent");
      let formData = new FormData();
      for(let i = 0;i<event.length;i++){
        formData.append("files[]", event[i])
      }
      console.log(  "formData", formData.getAll("files[]") );
      this.$emit("upload-data",formData)
    },
    async attachmentSearch(key) {
      const result = this.searchInArray(key, this.attachmentData);
      this.attachmentData = result;
    },
    searchInArray(searchTerm, dataArray) {
      const searchTermLower = searchTerm.toLowerCase();

      if (searchTermLower !== "") {
        const result = dataArray.filter((obj) => {
          return Object.values(obj).some((value) => {
            if (typeof value === "string") {
              return value.toLowerCase().includes(searchTermLower);
            }
            return false;
          });
        });

        return result;
      } else {
        this.attachmentData = JSON.parse(JSON.stringify(this.ogAttachmentData));
        return this.attachmentData;
      }
    },
    attachmentContextMenu($event, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 117;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.attachmentData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.attachmentData[index].context = true;
      } else {
        this.attachmentData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    detectFileType(base64Data) {
      // Remove the data URL prefix (e.g., 'data:image/jpeg;base64,')
      const data = base64Data.split(",")[1];

      // Get the first few bytes of the decoded base64 data
      const fileSignature = data.substring(0, 16);

      // Determine the file type based on the signature
      if (fileSignature.startsWith("/9j/")) {
        return "JPEG";
      } else if (fileSignature.startsWith("iVBORw0KGgo=")) {
        return "PNG";
      } else if (fileSignature.startsWith("JVBERi0xLj")) {
        return "PDF";
      } else if (fileSignature.startsWith("data:text/plain")) {
        return "TXT";
      } else {
        return "Unknown";
      }
    },

    getFileTypeFromMimeType(mimeType) {
      // Map MIME types to file types
      const mimeTypeMap = {
        "image/jpeg": "JPEG",
        "image/png": "PNG",
        "application/pdf": "PDF",
        "text/plain": "TXT",
        // Add more MIME types as needed
      };

      // Lookup the file type based on the MIME type
      const fileType = mimeTypeMap[mimeType] || "Unknown";

      // Convert the file type back to MIME type format if found, otherwise return the original MIME type
      return fileType !== "Unknown"
        ? `application/${fileType.toLowerCase()}`
        : mimeType;
    },
    async downloadFromGCP(urlFetch, name) {
      console.log(urlFetch, name);
      const type = this.getFileTypeFromMimeType(name);
      const blob = new Blob([urlFetch], { type: type });
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element
      const a = document.createElement("a");
      a.href = url;
      a.download = name; // Set the filename

      // Append the anchor element to the DOM
      document.body.appendChild(a);

      // Trigger the download
      a.click();
      // const response = await fetch(urlFetch);
      // const fileData = await response.blob(); // Assuming the file is in binary format

      // const blob = new Blob([fileData]);

      // const url = URL.createObjectURL(blob);

      // const link = document.createElement("a");
      // link.href = url;
      // link.download = name;

      // document.body.appendChild(link);
      // link.click();

      // URL.revokeObjectURL(url);
      // document.body.removeChild(link);
    },
    async bufferToDataURL(buffer) {
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = "";
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },
    async attachmentCrudContextMenu($event, ele, i) {
      if ($event.toLowerCase() === "download") {
        const url = await UPCAxiosInstance.get(`/gcpupload/files/${ele.name}`, {
          responseType: "arraybuffer",
        });
        // console.log(ele.name, url.data[0]);

        this.downloadFromGCP(url.data, ele.name);
      } else if ($event.toLowerCase() === "delete") {
        this.delete_modal_active = true;
        this.deleteobj = ele;
        this.delete_name = ele.name;
        this.deleterowindex = this.serialNumber(i);
      }
    },
    attachmentTableAction(data) {
      let options = [
        {
          display: this.staticData?.download?.label[this.loginUserDetails]?.label || "Download",
          label: "Download",
          icon: "icon-download-regular",
        },
        {
          display: this.staticData?.delete?.label[this.loginUserDetails]?.label || "Delete",
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ];
      return options.filter((option) => {
        if (
          data &&
          data.status &&
          data.status.toLowerCase() == "draft" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "active" &&
          ["Draft", "Mark as active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "inactive" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else {
          return true;
        }
      });
    },
    async deleteExecRow() {
      this.$emit("delete-index", this.deleterowindex);
      this.delete_modal_active = false;
      this.notification = {
        type: "success",
        message: this.loadStaticData('LANG-0073') || `Done! Your item has been removed.`,
      };
      // const image = await UPCAxiosInstance.delete(
      //   `gcpupload/files/${this.userDetails.name}/${this.delete_name}`
      // );
      // console.log(image);
      // if (image) {
      // const table = await UPCAxiosInstance.get(`document/${this.userDetails.name}`);
      // this.attachmentData = table.data;
      // this.refreshUpload();
      // }
    },
    formatFileSize(bytes) {
      console.log(bytes);
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    },
    getFileExtension(filename) {
      const parts = filename.split(".");
      const fileExtension = parts[parts.length - 1];
      return fileExtension;
    },
    convertdateformat(date1) {
      if (date1 != null) {
        let objectDate = new Date(date1);
        if (isNaN(objectDate) == false) {
          let date = objectDate.toLocaleString("default", { day: "numeric" });
          let month = objectDate.toLocaleString("default", { month: "short" });
          let year = objectDate.getFullYear();
          let dateformat = month + " " + date + ", " + year;
          return dateformat;
        }
      }
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    currentPage(val) {
      this.currPage = val;
    },
    changeRowsPerPage(count) {
      this.rowsPerPage = count;
    },
    async refreshUpload() {
      let payload = {};
      if (this.module === "CM") {
        payload = await MLTAxiosInstance.get(
          `/partyManagement/organisation/${this.userDetails.name}/${this.userDetails.id}`
        );
      } else if (this.module == "PS") {
        payload = await UPCAxiosInstance.get(
          `/catalogManagement/productSpecification/${this.userDetails.name}/${this.userDetails.id}`
        );
      } else {
        payload = await UPCAxiosInstance.get(
          `document/${this.userDetails.name}`
        );
      }
      if (payload) {
        this.modal_active = false;
        console.log(payload,"toster for all the attachment");
        
        this.notification = {
          type: "success",
          message: this.loadStaticData('LANG-0032') || `Nice work! Your new file is now in the list!`,
        };
      }
      console.log(payload, "payload");
      this.attachmentData = await payload.data.attachment;
    },
    async imgDataPrimary(pic) {
      this.imgPrimary = pic;
      const formData = new FormData();
      formData.append("file", pic.properties);
      let data = await UPCAxiosInstance.post(
        `/gcpMultiple/upload/${this.userDetails.id}`,
        formData
      );
      console.log(data, { response: data.data, data: pic });
      if (data.data.message.includes("successfully")) {
        this.$emit("upload-data", { response: data.data, data: pic });
        this.modal_active = false;
        this.notification = {
          type: "success",
          message:  this.loadStaticData('LANG-0032') || `Nice work! Your new file is now in the list!`,
        };
        // setTimeout(() => {
        //   this.refreshUpload();
        // }, 2000);
      }
    },
  },
};
</script>
