<template>
  <div class="email_link" style="color: rgba(84, 189, 149, 1);text-align: right;" @click="err_msg=''">
    <i
      style="font-size: 14px"
      class="icon-paperclip-regular"
      @click="triggerFileInput"
    ></i>
    <span
      style="font-family: 'OpenSans' !important; font-size: 14px;cursor: pointer;"
      @click="triggerFileInput"
    >
      Attachment  
      <sup v-if="required" style="color:  #ff5050;">*</sup>
    </span>
    
    <input
      id="fileUpload"
      ref="fileInput"
      autocomplete="off"
      type="file"
      :accept="fileType"
      name="fileUpload[]"
      style="display: none"
      multiple
      @change="chooseFiles"
    />
    <br />
    <!-- Display error message if present -->
    <span v-if="err_msg" class="error-message">{{ err_msg }}</span>
  </div>

  <div class="gfvdsdas">
    <div class="box-attachment-supplier-parent">
      <!-- Render uploaded files -->
      <div
        v-for="(box, index) in filesArrayGet"
        :key="index"
        class="box-attachment-supplier"
      >
        <span class="closeIconeSupplier" @click="handleClose(box, index)">
          <i class="icon-times-regular"></i>
        </span>
        <div>
          <span v-if="getFileExtension(box.name) === 'txt'" class="upload_component_icon">
            <img class="file-icon" src="../assets/svg/multiUpload/text.svg" alt="" />
          </span>
          <!-- Repeat for other file types -->
          <!-- Add your icons based on file extensions -->
          <div>
          <span
            v-if="getFileExtension(box.name) === 'txt'"
            class="upload_component_icon"
            ><img
              class="file-icon"
              src="../assets/svg/multiUpload/text.svg"
              alt=""
          /></span>
          <span
            v-if="getFileExtension(box.name) === 'docx'"
            class="upload_component_icon"
            ><img
              class="file-icon"
              src="../assets/svg/multiUpload/word.svg"
              alt=""
          /></span>
          <span
            v-if="getFileExtension(box.name) === 'png'"
            class="upload_component_icon"
            ><img
              class="file-icon"
              src="../assets/svg/multiUpload/png.svg"
              alt=""
          /></span>
          <span
            v-if="getFileExtension(box.name) === 'pdf'"
            class="upload_component_icon"
            ><img
              class="file-icon"
              src="../assets/svg/multiUpload/pdf.svg"
              alt=""
          /></span>
          <span
            v-if="getFileExtension(box.name) === 'json'"
            class="upload_component_icon"
            ><img
              class="file-icon"
              src="../assets/svg/multiUpload/json.svg"
              alt=""
          /></span>
          <span
            v-if="getFileExtension(box.name) === 'jpg'"
            class="upload_component_icon"
            ><img
              class="file-icon"
              src="../assets/svg/multiUpload/jpg.svg"
              alt=""
          /></span>
          <span
            v-if="getFileExtension(box.name) === 'jpeg'"
            class="upload_component_icon"
            ><img
              class="file-icon"
              src="../assets/svg/multiUpload/jpeg.svg"
              alt=""
          /></span>
          <span
            v-if="getFileExtension(box.name) === 'xlsx'"
            class="upload_component_icon"
            ><img
              class="file-icon"
              src="../assets/svg/multiUpload/excel.svg"
              alt=""
          /></span>
        </div>
        </div>
        <div style="display: flex; flex-direction: column">
          <span
            style="
              font-weight: 700;
              font-size: 14px;
              color: #575361;
              white-space: nowrap;
              width: 260px;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            {{ box.name }}
          </span>
          <span
            style="
              font-weight: 550;
              font-size: 12px;
              font-family: 'Opensans';
              color: #857e95;
            "
          >
            {{ formatFileSize(box.size) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    preValue: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false
    },
  },
  emits: ['fileUpload', 'fileuploaddata',"removedData"],
  data() {
    return {
      dummy: [], 
      filesArrayGet: [], 
      filesArray: [],
      fileType: '.json,.xlsx,.doc,.pdf,.txt,.jpg,.jpeg,.png', 
      fileSizeLimit: '25MB', 
      err_msg: '', 
    };
  },
  watch: {
    filesArray: {
      handler(val) {
        this.filesArrayGet = val; 
      },
      immediate: true,
      deep: true,
    },
    preValue: {
      handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          this.filesArrayGet = val; 
          // this.dummy = val; 
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    formatFileSize(bytes) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    },
    handleClose(val, index) {

      this.filesArrayGet.splice(index, 1);
      this.filesArray.splice(index, 1);
      this.dummy.splice(index, 1);
      this.$emit('removedData', this.filesArrayGet); 
      console.log(this.dummy,"this.dummy");
      
    },
    getFileExtension(filename) {
      const parts = filename.split('.');
      return parts[parts.length - 1].toLowerCase();
    },
    isValidFileType(file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const allowedExtensions = this.fileType.replace(/\./g, '').split(',');
      return allowedExtensions.includes(fileExtension);
    },
    isValidFileSize(file) {
      const fileSize = file.size;
      const maxSizeInBytes = this.fileSizeLimitInBytes();
      return fileSize <= maxSizeInBytes;
    },
    triggerFileInput() {
      this.err_msg = '';
      this.$refs.fileInput.click();
    },
    chooseFiles(event) {
      const files = event.target.files;
      let errorMessages = [];
      const currentTime = new Date();

      // Calculate the current total size of files already in filesArray
      let totalSize = this.filesArray.reduce((acc, file) => acc + file.size, 0);

      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          // Validate file type
          if (!this.isValidFileType(file)) {
            errorMessages.push(`Invalid file type: ${file.name}. Allowed types: ${this.fileType}.`);
            continue; // Skip invalid file type
          }

          // Validate file size and total size limit
          if (totalSize + file.size > this.fileSizeLimitInBytes()) {
            errorMessages.push(
              `Adding file '${file.name}' exceeds total size limit of ${this.fileSizeLimit}.`
            );
            break; // Stop processing further files
          }

          // Add valid file to the filesArray and update total size
          this.filesArray.push(file);
          totalSize += file.size;

          // Emit updated filesArray
          this.$emit('fileuploaddata', this.filesArray);

          // Process and read file
          this.processFile(file, currentTime);
        }

        // Show error messages (if any)
        if (errorMessages.length > 0) {
          this.err_msg = errorMessages.join(' ');
        }

        // Clear file input
        event.target.value = '';
      }
    },
    processFile(file, currentTime) {
      const reader = new FileReader();
      reader.onload = () => {
        const fileInfo = {
          name: file.name,
          size: file.size,
          lastUploaded: currentTime,
          '@type': 'attachment',
        };

        // Add to dummy array
        this.dummy.push(fileInfo);

        // Emit the file upload event
        this.$emit('fileUpload', fileInfo);
      };

      reader.readAsText(file);
    },
    fileSizeLimitInBytes() {
      const size = parseInt(this.fileSizeLimit);
      const unit = this.fileSizeLimit.replace(/[0-9]/g, '').toUpperCase();

      switch (unit) {
        case 'KB':
          return size * 1024;
        case 'MB':
          return size * 1024 * 1024;
        case 'GB':
          return size * 1024 * 1024 * 1024;
        default:
          return size; // Assume bytes if no unit is provided
      }
    },
  },
};
</script>

<style  lang="scss">
.gfvdsdas {
  .box-attachment-supplier-parent {
    overflow-y: auto;
    .box-attachment-supplier {
      position: relative;
      display: flex;
      border: 1px solid #d4d4d4;
      border-radius: 6px;
      padding: 6px 8px;
      margin: 10px 0;
      align-items: center;
      gap: 8px;
      .closeIconeSupplier {
        position: absolute;
        left: 340px;
        color: #a6a6a6;
        cursor: pointer;
      }
      .upload_component_icon {
        display: flex;
        .file-icon {
          height: 28px;
          width: 32px;
        }
      }
    }
  }
}

.error-message {
  color: #ff5050;
  font-size: 12px;
}
</style>
