<template>
  <div
    style="
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
      width: 100%;
      gap: 2rem;
    "
  >
    <span
      v-for="doc in alteredData"
      :key="doc"
      style="
        display: flex;
        justify-content: space-between;
        gap: 10px;
        padding: 30px;
        flex-direction: column;
        border: 1px solid #d8d8d8;
        border-radius: 5px;
      "
    >
      <span
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          cursor: pointer;
        "
        @click="handleClick(doc?._id)"
      >
        <span
          style="display: flex; flex-direction: column; align-items: center"
        >
          <hlx-file-upload
            :file-type="'.jpg,.jpeg,.png'"
            :file-size-limit="'20mb'"
            :uploadtype="'Rectangle'"
            :style-background="backgrounStyleupload"
            :height="150"
            :width="150"
            :read-only="true"
            :custom-icon="icon"
            :type="'profile-upload'"
            :name-display="nameDisplay"
            :content="'Import'"
            :profile-pic-preview="doc?.data"
          />
        </span>
      </span>
      <span
        style="
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-top: 10px;
        "
      >
        <span>
          {{ doc.name }}
        </span>
        <span>
          <b>{{ doc?.previewPrice !== undefined ? doc?.previewPrice : currencyFormatter(
                doc.productOfferingPrice[0]?.tiers[0]?.price?.value,
                { currency: $store?.getters?.getDefaultSettings?.currency?.unit }
              ) }}</b> 
          <span v-if="doc.type !== 'Tangible' && doc.billingPeroid?.includes('Annual')">{{ ' / Year' }}</span>
          <span v-if="doc.type !== 'Tangible' && doc.billingPeroid?.includes('Month')">{{ ' / Month' }}</span>
          <span v-if="doc.type !== 'Tangible' && doc.billingPeroid?.includes('Week')">{{ ' / Week' }}</span>
        </span>
      </span>
    </span>
  </div>
</template>

<script>
import { UPCAxiosInstance } from '@/config/axiosConfig';

export default {
  components: {},
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    onlyEmit: {
      type: Boolean,
      default: false
    }
  },
  emits: ['show-config'],
  data() {
    return{
        alteredData: [],
    }
  },
    watch: {
  data: {
    async handler(val) {
        this.alteredData = []
        this.alteredData = JSON.parse(JSON.stringify(val))
        if (this.alteredData.length > 0) {
            for (const item of this.alteredData) {
              try {
                if (item.defaultImageName !== undefined) {
                  const url1 = await UPCAxiosInstance.get(`/gcpupload/files/${item.defaultImageName}`, {
                    responseType: 'arraybuffer',
                  });
                  // Do something with the response if needed
                  item.data = await this.bufferToDataURL(url1.data)
                  console.log(url1);
                } else {
                  const url1 = await UPCAxiosInstance.get(`/gcpupload/files/${item.prodSpecCharValue[0].attachment[0].name}`, {
                    responseType: 'arraybuffer',
                  });
                  // Do something with the response if needed
                  item.data = await this.bufferToDataURL(url1.data)
                  console.log(url1);
                }
              } catch (error) {
                // Handle error
                console.error(`Error fetching file ${item.name}:`, error);
              }
            }
        }
      console.log(this.alteredData,val,  'alteredData');
    },
    immediate: true,
    deep: true,
  },
},
  methods: {
    handleClick(id) {
      if (this.onlyEmit) {
        this.$emit('show-config', {
          _id: id,
          show: true
        })
      } else {
        this.$router.push(`/UPC/product-catalog-table/offering/Edit%20Atomic%20offer/${id}`);
      }
    },

    async bufferToDataURL(buffer) {
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = "";
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },
    formatFileSize(bytes) {
      console.log(bytes);
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    },
    getFileExtension(filename) {
      const parts = filename.split('.');
      const fileExtension = parts[parts.length - 1];
      return fileExtension;
    },
    async download(url, name) {
      const url1 = await UPCAxiosInstance.get(`/gcpupload/files/${name}`, {
        responseType: 'arraybuffer',
      });
      url1;
      // console.log(ele.name, url.data[0]);

      this.downloadFromGCP(url1.data, name);
    },
    async downloadFromGCP(urlFetch, name) {
      console.log(urlFetch, name);
      const type = this.getFileTypeFromMimeType(name);
      const blob = new Blob([urlFetch], { type: type });
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element
      const a = document.createElement('a');
      a.href = url;
      a.download = name; // Set the filename

      // Append the anchor element to the DOM
      document.body.appendChild(a);

      // Trigger the download
      a.click();
      // const response = await fetch(urlFetch);
      // const fileData = await response.blob(); // Assuming the file is in binary format

      // const blob = new Blob([fileData]);

      // const url = URL.createObjectURL(blob);

      // const link = document.createElement("a");
      // link.href = url;
      // link.download = name;

      // document.body.appendChild(link);
      // link.click();

      // URL.revokeObjectURL(url);
      // document.body.removeChild(link);
    },
    getFileTypeFromMimeType(mimeType) {
      // Map MIME types to file types
      const mimeTypeMap = {
        'image/jpeg': 'JPEG',
        'image/png': 'PNG',
        'application/pdf': 'PDF',
        'text/plain': 'TXT',
        // Add more MIME types as needed
      };

      // Lookup the file type based on the MIME type
      const fileType = mimeTypeMap[mimeType] || 'Unknown';

      // Convert the file type back to MIME type format if found, otherwise return the original MIME type
      return fileType !== 'Unknown'
        ? `application/${fileType.toLowerCase()}`
        : mimeType;
    },
  },
};
</script>
