<template>
  <hlx-alert-notification :notify="notification" :auto-close="true" />

  <section class="section_accordtion_supplier_note" style="margin-right: 30px">
    <div class="notepad_supplier_accordion_note bundle">
      <span v-if="payloadSpec1">
        <hlx-accordion
          v-for="(
            productOfferingPrice, index
          ) in payloadSpec1?.productOfferingPrice || []"
          :key="index"
          :multiple="false"
          :header-border="true"
          :direction="'left'"
        >
          <!-- Title -->
          <template #item-title>
            <div class="noter_fullhead_supplier_accordion_note">
              <div
                class="noter_head_supplier_accordion_note"
                style="justify-content: space-between"
              >
                <span
                  class="note_username_supplier_note"
                  style="font-weight: 400"
                >
                  <span v-if="productOfferingPrice">{{
                    formatDateRange(productOfferingPrice?.validFor)
                  }}</span>
                </span>
                <div style="width: 200px; margin: 0px 0px 0px 0px">
                  <span v-if="productOfferingPrice"
                    ><hlx-status
                      :single-border="true"
                      :type="productOfferingPrice?.lifecycleStatus"
                    ></hlx-status
                  ></span>
                </div>
                <span
                  class="note_date_time_supplier_note"
                  style="margin-left: 0px"
                >
                  <span class="note_date_time_supplier_value">
                    <span v-if="productOfferingPrice">
                      {{
                        currencyFormatter(
                          productOfferingPrice?.totalPrice?.value ||
                            productOfferingPrice.tiers[0].price.value ||
                            0,
                          {
                            currency:
                              this.$store?.getters?.getDefaultSettings?.currency
                                ?.unit,
                          }
                        )
                      }}
                      <span
                        v-show="
                          payloadSpec1.productSpecification['@baseType'] !=
                          'Tangible'
                        "
                      >
                        /
                        {{
                          makeWordsSingular(productOfferingPrice?.displayValue)
                        }}</span
                      >
                    </span>
                  </span>
                </span>

                <div class="supplier-delete-action" style="height: 42px">
                  <div
                    v-show="this.payloadSpec1?.productOfferingPrice.length > 1"
                  >
                    <i
                      class="icon-trash-regular"
                      style="color: #dd2025"
                      @click.stop="deleteNoteModal(index)"
                    ></i>
                  </div>
                  <div>
                    <i
                      class="icon-plus-circle-regular"
                      style="color: #70bb98"
                      @click.stop="addAccord(index)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- Body -->
          <template #item-body>
            <div style="display: flex; flex-direction: column; width: 100%">
              <div class="body_activelog_inside">
                <hlx-table
                  :column-count="tableHeader.length + 2"
                  :border="['table', 'header', 'horizontal']"
                  :bold-headers="false"
                  :row-hover="true"
                  theme="grey"
                  :striped-rows="false"
                >
                  <template #thead>
                    <hlx-table-head
                      :width="60"
                      :align="'left'"
                      style="
                        padding: 0 15px !important;
                        max-width: 60px !important;
                      "
                      :fixed="'left'"
                      >{{ "S.no" }}</hlx-table-head
                    >
                    <!-- {{computedTheads}} -->

                    <hlx-table-head
                      v-for="(i, index) in tableHeader"
                      :key="index"
                      :prop="i.prop"
                      :sortable="i.sortable"
                      :resizable="i.resizable"
                      :width="i.width"
                      @sorting_func="sorting_Data"
                      @resizewidth="resizewidthdata"
                    >
                      <span
                        v-if="
                          ['price', 'pricePerUnit', 'totalPrice'].includes(
                            i.prop
                          ) ||
                          (i.prop === 'tiers' &&
                            !['Min', 'Max'].includes(i.label))
                        "
                      >
                        {{ i.label }} ({{
                          $store?.getters?.getDefaultSettings?.currency?.unit
                        }})
                      </span>
                      <span v-else>
                        {{ i.label }}
                      </span>
                    </hlx-table-head>
                  </template>
                  <template #tbody>
                    <tr v-for="(i, indexx) in bundleTableData" id="" :key="indexx">
                      <hlx-table-cell
                        :align="'center'"
                        style="padding: 0 15px"
                        :fixed="'left'"
                        >{{ serialNumber(indexx) }}</hlx-table-cell
                      >
                      <hlx-table-cell
                        v-for="(j, col_index) in tableHeader"
                        :key="col_index"
                        :align="
                          j.prop?.toLowerCase() === 'updatedat' ? 'left' : ''
                        "
                      >
                        <div v-if="j.prop == 'displayID'">
                          {{ i[j.prop] }}
                        </div>
                        <div
                          v-else-if="j.label === 'Min'"
                          style="
                            display: flex;
                            flex-direction: column;
                            gap: 12px;
                          "
                        >
                          <div
                            v-for="item in i.tiers"
                            :key="item"
                            class="bg-default border"
                          >
                            {{ item.min }}
                          </div>
                        </div>
                        <div
                          v-else-if="j.label === 'Max'"
                          style="
                            display: flex;
                            flex-direction: column;
                            gap: 12px;
                          "
                        >
                          <div
                            v-for="item in i.tiers"
                            :key="item"
                            class="bg-default border"
                          >
                            {{ item.max }}
                          </div>
                        </div>
                        <div
                          v-else-if="j.prop === 'tiers'"
                          style="
                            display: flex;
                            flex-direction: column;
                            gap: 12px;
                          "
                        >
                          <div
                            v-for="item in i.tiers"
                            :key="item"
                            class="bg-default border"
                          >
                            <!-- v-for="item in i.tiers" -->
                            <div
                              v-if="
                                payloadSpec1?.productSpecification[
                                  '@baseType'
                                ] == 'Tangible'
                              "
                            >
                              {{
                                currencyFormatter(item?.price?.value || 0, {
                                  currency: item?.price?.unit,
                                })
                              }}
                            </div>
                            <div v-else>
                              {{
                                currencyFormatter(item?.price?.value || 0, {
                                  currency:
                                    this.$store?.getters?.getDefaultSettings
                                      ?.currency?.unit,
                                })
                              }}
                              /
                              {{ makeWordsSingular(i?.displayValue) }}
                            </div>
                            <!-- {{ "£" + item.price.value }} -->
                          </div>
                        </div>

                        <div
                          v-else-if="j.prop === 'pricePerUnit'"
                          style="
                            display: flex;
                            flex-direction: column;
                            gap: 12px;
                          "
                        >
                          <p
                            v-for="item in i?.tiers"
                            :key="item"
                            class="bg-default border"
                            :data-tooltip="i['description']"
                            data-tooltip-location="top"
                          >
                            <!-- {{ "£" + item.price.value }} -->
                            {{
                              currencyFormatter(item?.price?.value || 0, {
                                currency:
                                  this.$store?.getters?.getDefaultSettings
                                    ?.currency?.unit,
                              })
                            }}
                            /
                            {{ makeWordsSingular(i?.displayValue) }}
                          </p>
                        </div>
                        <div
                          v-else-if="j.prop === 'quantity'"
                          style="width: 50%"
                        >
                          <hlx-input
                            :key="componentkey"
                            v-model:value="productOfferingPrice.quantity"
                            :pre-val="i[j.prop] || 1"
                            :min-limit="1"
                            :single-border="true"
                            :label-animation="false"
                            type="number"
                            @at-input="quantityValue($event, i, index,indexx)"
                          />
                        </div>
                        <div
                          v-else-if="j.prop === 'totalPrice'"
                          style="
                            display: flex;
                            flex-direction: column;
                            gap: 12px;
                          "
                        >
                          <div
                            v-for="item in i.tiers"
                            :key="item"
                            class="bg-default border"
                          >
                            <!-- v-for="item in i.tiers" -->
                            <div
                              v-if="
                                payloadSpec1?.productSpecification[
                                  '@baseType'
                                ] == 'Tangible'
                              "
                            >
                              {{
                                currencyFormatter(
                                  item?.totalPrice?.value ||
                                    item?.price?.value ||
                                    0,
                                  {
                                    currency:
                                      this.$store?.getters?.getDefaultSettings
                                        ?.currency?.unit,
                                  }
                                )
                              }}
                            </div>
                            <div v-else>
                              {{
                                currencyFormatter(
                                  item?.totalPrice?.value ||
                                    item?.price?.value ||
                                    0,
                                  {
                                    currency:
                                      this.$store?.getters?.getDefaultSettings
                                        ?.currency?.unit,
                                  }
                                )
                              }}
                              /
                              {{ makeWordsSingular(i?.displayValue) }}
                            </div>
                            <!-- {{ "£" + item.price.value }} -->
                          </div>
                        </div>

                        <div v-else>
                          <!-- <span v-if="i[j.prop] == null">-</span>
                            <span v-else>
                              {{
                                i[j?.prop]?.charAt(0).toUpperCase() +
                                i[j?.prop]?.slice(1)
                              }}
                              </span
                            > -->
                          <span v-if="i[j.prop] == '' || undefined">-</span>
                          <span v-else> {{ i[j.prop] }}</span>
                        </div>
                      </hlx-table-cell>
                    </tr>
                  </template>
                </hlx-table>
              </div>

              <!-- price section for bundle -->

              <section class="specialPricingForBundleOffer" style="">
                <!-- Billing period -->
                <section
                  v-show="
                    payloadSpec1?.productSpecification['@baseType'] ==
                    'Intangible'
                  "
                  style="
                    display: flex;
                    height: 36px;
                    align-items: flex-end;
                    margin-bottom: 2%;
                  "
                >
                  <div style="width: 60%; display: flex; align-items: flex-end">
                    <p>Billing period:</p>
                  </div>
                  <div>
                    <hlx-select
                      v-model:value="productOfferingPrice.billingPeriod"
                      :options="billingPeriodOptions"
                      :pre-value="productOfferingPrice?.billingPeriod"
                      :placeholder-value="'Billing period'"
                      :label="'name'"
                      :prop-value="'name'"
                      :label-animation="false"
                      :inline-search="true"
                      :required="true"
                      :error="show"
                      :single-border="true"
                      :custom-error="productOfferingPrice.errorvalue"
                      @custom-error-emit-value="
                        productOfferingPrice.selecttheValueorNot
                      "
                      @selected-value="bundleBillingPeriod($event, index)"
                    ></hlx-select>
                  </div>
                </section>
                <!-- Commitment period -->
                <section
                  v-show="
                    payloadSpec1?.productSpecification['@baseType'] ==
                    'Intangible'
                  "
                  style="display: flex; height: 36px; align-items: center"
                >
                  <div style="width: 60%; display: flex; align-items: center">
                    <p>Commitment length</p>
                  </div>
                  <div
                    style="
                      display: flex;
                      gap: 6%;
                      width: 40%;
                      align-items: center;
                    "
                  >
                    <div style="width: 50%">
                      <hlx-input
                        :key="componentkey"
                        v-model:value="productOfferingPrice.commitmentLength"
                        :pre-val="productOfferingPrice?.commitmentLength || 1"
                        :min-limit="1"
                        :single-border="true"
                        :label-animation="false"
                        type="number"
                        :display-error="true"
                        :custom-error="
                          productOfferingPrice.isCommitmentLengthValid
                        "
                        :custom-error-message="
                          productOfferingPrice.commitmentMessage
                        "
                        @at-input="commitmentValue($event, index)"
                      />
                    </div>
                    <div
                      v-if="
                        productOfferingPrice &&
                        productOfferingPrice?.displayValue &&
                        productOfferingPrice?.commitmentLength
                      "
                      style="width: 50%"
                    >
                      {{
                        singular(
                          productOfferingPrice?.displayValue,
                          productOfferingPrice?.commitmentLength
                        )
                      }}
                    </div>
                  </div>
                </section>
                <!-- subtotatl -->
                <section
                  style="
                    display: flex;
                    height: 36px;
                    align-items: flex-end;
                    margin-top: -3%;
                  "
                >
                  <div style="width: 60%; display: flex; align-items: flex-end">
                    <p>Subtotal</p>
                    <span
                      class="currency-field"
                      style="font-size: 12px; margin-left: 5px"
                      >({{
                        $store?.getters?.getDefaultSettings?.currency?.unit
                      }}):</span
                    >
                  </div>
                  <div>
                    <span v-if="payloadSpec1">
                      <span v-if="payloadSpec1?.prodSpecCharValue">
                        <span
                          v-if="
                            payloadSpec1.productSpecification['@baseType'] ==
                            'Tangible'
                          "
                          style="margin-left: 58px; width: 15%"
                        >
                          {{
                            this.currencyFormatter(
                              productOfferingPrice.tiers[0]?.price?.value,
                              {
                                currency:
                                  this.$store?.getters?.getDefaultSettings
                                    ?.currency?.unit,
                              }
                            )
                          }}
                        </span>
                        <span v-else style="margin-left: auto; width: auto">
                          {{
                            this.currencyFormatter(
                              productOfferingPrice.tiers[0]?.price?.value,
                              {
                                currency:
                                  this.$store?.getters?.getDefaultSettings
                                    ?.currency?.unit,
                              }
                            )
                          }}
                          /
                          {{
                            makeWordsSingular(
                              productOfferingPrice?.displayValue
                            )
                          }}</span
                        >
                        <!-- {{ calculateSum(payloadSpec1?.prodSpecCharValue,index) }} -->
                      </span>
                    </span>
                  </div>
                </section>

                <!-- discount -->
                <section style="display: flex; height: 36px">
                  <div style="width: 60%; display: flex; align-items: flex-end">
                    <p>Discount:</p>
                  </div>
                  <div
                    style="
                      display: flex;
                      gap: 6%;
                      width: 40%;
                      align-items: center;
                    "
                  >
                    <div style="width: 60%">
                      <hlx-input
                        :key="componentkey"
                        v-model:value="
                          productOfferingPrice.bundleAlteration.discount
                        "
                        :pre-val="
                          productOfferingPrice?.bundleAlteration?.discount || 0
                        "
                        :min-limit="0"
                        :single-border="true"
                        :label-animation="false"
                        type="number"
                        @at-input="discountValue($event, index)"
                      />
                    </div>
                    <div style="width: 40%">
                      <hlx-select
                        :key="componentkey"
                        v-model="
                          productOfferingPrice.bundleAlteration.discountType
                            .value
                        "
                        :pre-value="
                          productOfferingPrice?.bundleAlteration?.discount
                            ?.discountType?.value || '%'
                        "
                        :single-border="true"
                        :inline-search="true"
                        :options="bundleDiscountOpts"
                        :label="'value'"
                        :prop-value="'value'"
                        :required="false"
                        :label-animation="false"
                        :clearable="true"
                        @selected-value="handleDiscounts($event, index)"
                      ></hlx-select>
                    </div>
                  </div>
                </section>

                <!-- price per bundle -->
                <section
                  style="
                    display: flex;
                    height: 36px;
                    align-items: flex-end;
                    margin-bottom: 2%;
                  "
                >
                  <div style="width: 60%; display: flex; align-items: flex-end">
                    <p>{{ "Price per bundle   " }}</p>
                    <span
                      class="currency-field"
                      style="font-size: 12px; margin-left: 5px"
                      >({{
                        $store?.getters?.getDefaultSettings?.currency?.unit
                      }}):</span
                    >
                  </div>
                  <p style="margin-left: auto; width: auto">
                    {{
                      this.currencyFormatter(
                        productOfferingPrice.totalPrice.value ||
                          productOfferingPrice.tiers[0]?.price?.value ||
                          0,
                        {
                          currency:
                            this.$store?.getters?.getDefaultSettings?.currency
                              ?.unit,
                        }
                      )
                    }}
                    <span
                      v-show="
                        payloadSpec1.productSpecification['@baseType'] !=
                        'Tangible'
                      "
                    >
                      /
                      {{
                        makeWordsSingular(productOfferingPrice?.displayValue)
                      }}</span
                    >
                  </p>
                </section>

                <!-- start date -->
                <section
                  style="
                    display: flex;
                    height: 36px;
                    align-items: center;
                    margin-top: 2%;
                    margin-bottom: 1%;
                  "
                >
                  <div style="width: 60%; display: flex; align-items: center">
                    <p>Start Date:</p>
                  </div>
                  <div>
                    <hlx-input
                      v-model:value="productOfferingPrice.validFor.startDate"
                      :key="componentkey"
                      :pre-val="productOfferingPrice?.validFor?.startDate"
                      :label-animation="false"
                      :single-border="true"
                      :label-value="'Start date'"
                      :date-format="'dd (short month), yyyy'"
                      type="date"
                      :clearable="false"
                      icon="calendar-regular"
                      icon-position="right"
                      :disable-weekend="false"
                      :start-year="2000"
                      :end-year="9999"
                      :display-error="true"
                      :custom-error="productOfferingPrice.startDateBoolean"
                      :custom-error-message="productOfferingPrice.startDateMsg"
                      @iso-date="bundleValueStartDate($event, index)"
                      @focus-out="bundleValueStartDate($event, index)"
                    />
                    <!-- :display-error="true" -->
                  </div>
                </section>

                <!-- end date -->
                <section
                  style="
                    display: flex;
                    height: 36px;
                    align-items: center;
                    margin-top: 2%;
                  "
                >
                  <div style="width: 60%; display: flex; align-items: center">
                    <p>End Date:</p>
                  </div>
                  <div>
                    <!-- v-model:value="productOfferingPrice.validFor.endtDate" -->
                    <hlx-input
                      :key="componentkey"
                      :pre-val="productOfferingPrice?.validFor?.endDate"
                      :label-animation="false"
                      :single-border="true"
                      :label-value="'End date'"
                      :date-format="'dd (short month), yyyy'"
                      type="date"
                      :clearable="false"
                      icon="calendar-regular"
                      icon-position="right"
                      :disable-weekend="false"
                      :start-year="2000"
                      :display-error="true"
                      :custom-error="productOfferingPrice.endDateBoolean"
                      :custom-error-message="productOfferingPrice.endDateMsg"
                      :end-year="9999"
                      @iso-date="bundleValueEndDate($event, index)"
                      @focus-out="bundleValueEndDate($event, index)"
                    />
                  </div>
                  <!-- :display-error="true" -->
                </section>

                <!-- status -->
                <!-- <section style="display: flex; height: 36px">
                <div style="width: 240px; align-content: center">
                  <p>{{ "Status:" }}</p>
                </div>
                <div style="width: 100%">
                  <hlx-status
                    :type="'select-status'"
                    :status-option="filteredstatus"
                    :prevalue="productOfferingPrice?.lifecycleStatus"
                    :single-border="true"
                    @emit-select="emitStatus1($event, index)"
                  >
                  </hlx-status>
                </div>
              </section> -->
              </section>
            </div>
          </template>
        </hlx-accordion>
      </span>
    </div>
  </section>
</template>

<script>
import { DMNAxiosInstance } from "@/config/axiosConfig";
import { v4 as uuidv4 } from "uuid";
export default {
  props: {
    payloadSpec: Object,

    tableData: Array,
    tableHeader: Array,
    componentkey: Number,

    calculateSum2: String,
    totalPrice1: Array,
    discounttotal1: Array,
  },
  emits: [
    "quantityValue1",

    "bundleBillingPeriod1",
    "commitmentValue1",

    "calculateSum1",

    "handleDiscount1",
    "discountValue1",

    "bundleValueStartDate1",
    "bundleValueEndDate1",

    "payloadSpec1",
    "addPricing",
    "deletePricing",'billingPeriodLog', 'quantityLog', 'discountLog', 'StartDateLog', 'EndDateLog', 'discountTypeLog','commitmentlengthLog',
  ],
  data() {
    return {
      domUpdated: false,
      endDateBoolean: false,
      endDateMsg: "",
      notification: {},
      index: 0,
      loading: true,
      filteredstatus: [],
      bundleTableData:[],
      copyBundleTableData:[],
      startDateMsg: "",
      startDateBoolean: false,
      billingPeriodOptions: [
        {
          name: "Weekly",
          value: "Weeks",
        },
        {
          name: "Biweekly",
          value: "Weeks",
        },
        {
          name: "BiMonthly",
          value: "Months",
        },
        {
          name: "Monthly",
          value: "Months",
        },
        {
          name: "Quarterly",
          value: "Months",
        },
        {
          name: "Annually",
          value: "Years",
        },
        {
          name: "Semi Annually",
          value: "Months",
        },
      ],
      bundleTotalcheck1: {
        id: uuidv4(),
        quantity: 1,
        priceType: "One time",
        totalPrice: {
          unit: this.$store?.getters?.getDefaultSettings?.currency?.symbol,
          value: 0,
        },
        tiers: [
          {
            min: 1,
            max: 99,
            price: {
              unit: this.$store?.getters?.getDefaultSettings?.currency?.symbol,
              value: 0,
            },
          },
        ],
        type: "Bundle",
        MSRP: {
          unit: this.$store?.getters?.getDefaultSettings?.currency?.symbol,
          value: 0,
        },
        validFor: {
          startDate: new Date().toISOString(),
          endDate: "9999-12-31T00:00:00Z",
        },
        lifecycleStatus: "Active",
        commitmentLength: 1,
        billingPeriod: "Monthly",
        displayValue: "Months",
        endOfCommitment: {
          autoPay: true,
          expireTerm: false,
        },
      },

      payloadSpec1: {
        description: "",
        unitOfMeasure: "",
        lifecycleStatus: "In study",
        prodSpecCharValueUse: [],
        prodSpecCharValue: [],
        bundledProductOffering: [{ name: "" }],
        productOfferingPrice: [],
        marketSegment: [],
        channel: [],
        productSpecification: { name: "" },
        relatedParty: [{ name: "", role: "Product owner" }],
        status: "",
        name: "",
        tags: [],
        notes: [],
        attachment: [{ media: [] }],
      },
      CopypayloadSpec1: {
        description: "",
        unitOfMeasure: "",
        lifecycleStatus: "In study",
        prodSpecCharValueUse: [],
        prodSpecCharValue: [],
        bundledProductOffering: [{ name: "" }],
        productOfferingPrice: [

        ],
        marketSegment: [],
        channel: [],
        
        productSpecification: { name: "" },
        relatedParty: [{ name: "", role: "Product owner" }],
        status: "",
        name: "",
        tags: [],
        notes: [],
        attachment: [{ media: [] }],
      },

      currPage: 1,
      rowsPerPage: 10,
      bundleAlteration: {
        discount: 0,
        discountType: {
          value: "%",
          name: "Percentage",
        },
      },
      bundleDiscountOpts: [
        {
          name: "Percentage",
          value: "%",
        },
        {
          name: "Fixed",
          value: this.$store?.getters?.getDefaultSettings?.currency?.symbol,
        },
      ],
    };
  },

  watch: {
    payloadSpec: {
      handler(val) {
        console.log("watching..123 payloadSpec..123", val);
        if (this.loading) {
          this.CopypayloadSpec1 = JSON.parse(JSON.stringify(this.payloadSpec));
          this.payloadSpec1 = JSON.parse(JSON.stringify(this.payloadSpec));
          this.loading = false;
          console.log("watching..123 this.loading", this.loading);
        }
      },
      immediate: true,
      deep: true,
    },
    payloadSpec1: {
      handler(val) {
        console.log("watching..123 payloadSpec..123 payloadSpec1..", val);
        this.payloadSpec1 = val;
        this.$emit("payloadSpec1", val);
      },
      immediate: true,
      deep: true,
    },

    // discounttotal1: {
    //   handler(val) {
    //      console.log("watching..123 discounttotal1...",val);
    //   },
    //   immediate: true,
    //   deep: true,
    // },

    tableData: {
      handler(val) {
         console.log("watching..123 totalPrice1...",val);
         this.bundleTableData= JSON.parse(JSON.stringify(val))
         this.copyBundleTableData= JSON.parse(JSON.stringify(val))
      },
      immediate: true,
      deep: true,
    },

    // calculateSum2: {
    //   handler(val) {
    //      console.log("watching..123 calculateSum2...",val);
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },
  async mounted() {
    const statusListpricing = await DMNAxiosInstance.get(
      `mapRef/collection/statuslist`
    );
    this.filteredstatus = statusListpricing?.data?.data?.filter(
      (item) =>
        item.name === "Active" ||
        item.name === "Inactive" ||
        item.name === "Draft"
    );
  },
  methods: {
    // getIndex(index) {
    //   console.log("getIndex...",index);
    //   this.index=index;
    // },
    commitmentValue(value, index) {
      // this.bundleTotalcheck.commitmentLength = value;
      // this.commitmentLength = value;

      if (
        this.payloadSpec1.productOfferingPrice[index].billingPeriod ==
        "Biweekly"
      ) {
        if (value % 2 != 0) {
          this.payloadSpec1.productOfferingPrice[
            index
          ].isCommitmentLengthValid = true;
          this.disableSave = true;
          this.payloadSpec1.productOfferingPrice[index].commitmentMessage =
            "Even period";
        } else {
          this.payloadSpec1.productOfferingPrice[
            index
          ].isCommitmentLengthValid = false;
          this.disableSave = false;
          this.payloadSpec1.productOfferingPrice[index].commitmentMessage = "";
        }
      } else if (
        this.payloadSpec1.productOfferingPrice[index].billingPeriod ==
        "BiMonthly"
      ) {
        if (value % 2 != 0) {
          this.payloadSpec1.productOfferingPrice[
            index
          ].isCommitmentLengthValid = true;
          this.disableSave = true;
          this.payloadSpec1.productOfferingPrice[index].commitmentMessage =
            "Even period";
        } else {
          this.payloadSpec1.productOfferingPrice[
            index
          ].isCommitmentLengthValid = false;
          this.disableSave = false;
          this.payloadSpec1.productOfferingPrice[index].commitmentMessage = "";
        }
      } else if (
        this.payloadSpec1.productOfferingPrice[index].billingPeriod ==
        "Quarterly"
      ) {
        if (value % 3 != 0) {
          this.payloadSpec1.productOfferingPrice[
            index
          ].isCommitmentLengthValid = true;
          this.disableSave = true;
          this.payloadSpec1.productOfferingPrice[index].commitmentMessage =
            "Odd period";
        } else {
          this.payloadSpec1.productOfferingPrice[
            index
          ].isCommitmentLengthValid = false;
          this.disableSave = false;
          this.payloadSpec1.productOfferingPrice[index].commitmentMessage = "";
        }
      } else if (
        this.payloadSpec1.productOfferingPrice[index].billingPeriod ==
        "Semi Annually"
      ) {
        if (value % 6 != 0) {
          this.payloadSpec1.productOfferingPrice[
            index
          ].isCommitmentLengthValid = true;
          this.disableSave = true;
          this.payloadSpec1.productOfferingPrice[index].commitmentMessage =
            "Length should be multiple of 6";
        } else {
          this.payloadSpec1.productOfferingPrice[
            index
          ].isCommitmentLengthValid = false;
          this.disableSave = false;
          this.payloadSpec1.productOfferingPrice[index].commitmentMessage = "";
        }
      }
      this.$emit("commitmentlengthLog",this.CopypayloadSpec1.productOfferingPrice[index].commitmentLength,value)

this.CopypayloadSpec1.productOfferingPrice[index].commitmentLength = value
      this.singular(
        this.payloadSpec1.productOfferingPrice[index].displayValue,
        this.payloadSpec1.productOfferingPrice[index].commitmentLength
      );
    },
    bundleStartDate(date) {
      console.log(date, " date incc");
      if (!date) {
        this.startDateBoolean = true;
        this.startDateMsg = "Please enter the date";
      }
    },

    async quantityValue(event, i, inx,indexx) {
      console.log(
        inx,
        this.copyBundleTableData[indexx],
        this.payloadSpec1.productOfferingPrice[this.index],
        "quantityValue...",
        event,
        i
      );
      this.$emit("quantityLog",this.copyBundleTableData[indexx].quantity,event,this.copyBundleTableData[indexx].name)
      this.copyBundleTableData[indexx].quantity =event
      this.index = inx;
      const value = event;
      i.totalPrice = {
        unit: this.$store?.getters?.getDefaultSettings?.currency?.symbol,
        value: i.tiers[0].price.value,
      };
      i.tiers[0].totalPrice = {
        unit: this.$store?.getters?.getDefaultSettings?.currency?.symbol,
        value: i.tiers[0].price.value,
      };
      const index = this.payloadSpec1.prodSpecCharValue.findIndex(
        (item) => item.name == i.name
      );
      if (index !== -1) {
        i.quantity = value;
        i.tiers[0].totalPrice.value = i.tiers[0].price.value * value;
        i.tiers.forEach((tier) => {
          tier.totalPrice = {
            unit: this.$store?.getters?.getDefaultSettings?.currency?.symbol,
            value: tier.price.value * value, // Modify this as per your calculation
          };
          // tier.totalPrice.value = tier.price.value * value;
        });
        i.totalPrice.value = i.tiers[0].price.value * value;
      }
      this.payloadSpec1.prodSpecCharValue[index] = i;
      console.log(
        this.payloadSpec1.prodSpecCharValue[index],
        "quantityValue.index.."
      );
      let products = this.payloadSpec1?.prodSpecCharValue;
      const totalPrice = products?.reduce(
        (sum, product) => {
          let price =
            product?.tiers?.[0]?.totalPrice?.value ||
            product?.tiers?.[0]?.price?.value ||
            0;

          return sum + price;
        },
        0 // Initial sum value
      );

      this.payloadSpec1.productOfferingPrice[this.index].tiers[0].price.value =
        totalPrice;

      this.totalPrice =
        this.payloadSpec1.productOfferingPrice[
          this.index
        ]?.tiers[0]?.price?.value;
      if (
        this.payloadSpec1.productOfferingPrice[this.index]?.bundleAlteration
          ?.discountType?.name == "Percentage"
      ) {
        this.totalPrice -=
          this.totalPrice *
          (this.payloadSpec1.productOfferingPrice[this.index]?.bundleAlteration
            ?.discount /
            100);
        this.payloadSpec1.productOfferingPrice[this.index].totalPrice.value =
          this.totalPrice;
      } else {
        this.totalPrice -=
          this.payloadSpec1.productOfferingPrice[
            this.index
          ]?.bundleAlteration?.discount;
        this.payloadSpec1.productOfferingPrice[this.index].totalPrice.value =
          this.totalPrice;
      }
    },

    emitStatus1(val, index) {
      console.log("emitStatus1....", val, index);
      if (val && val?.name) {
        val.name;
        this.payloadSpec1.productOfferingPrice[index].lifecycleStatus =
          val.name;
      }
    },

    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },

    deleteNoteModal(index) {
      console.log(
        "deleteNoteModal...",
        index,
        this.payloadSpec1?.productOfferingPrice.length
      );

      if (this.payloadSpec1?.productOfferingPrice.length > 1) {
        this.payloadSpec1?.productOfferingPrice.splice(index, 1);

        console.log(
          `this.payloadSpec1.productOfferingPrice...`,
          this.payloadSpec1?.productOfferingPrice
        );
      } else {
        this.notification = {
          type: "Warning",
          message: `We're unable to delete all pricing at this time.`,
        };
        console.log(`Item not found in the list`);
      }
      this.$emit("deletePricing");
    },
    deleteNoteModal1(val) {
      val;
    },
    addAccord1(val) {
      val;
    },

    calculateSum(val, index) {
      console.log("calculateSum...", val, index);

      this.$emit("calculateSum1", val, index);
      return this.calculateSum2 || "null";
    },

    singular(prop, valu) {
      console.log("prop singular", prop, valu);
      if (valu == 1 || valu == 0) {
        return this.makeWordsSingular(prop);
      } else {
        return prop;
      }
    },
    makeWordsSingular(value) {
      if (value == "Years") {
        return "Year";
      } else if (value == "Months") {
        return "Month";
      } else if (value == "Weeks") {
        return "Week";
      } else {
        return value;
      }
    },
    bundleBillingPeriod(selectedPeriod, index) {
      if (!selectedPeriod) return;
      this.$emit("billingPeriodLog",this.CopypayloadSpec1.productOfferingPrice[index].billingPeriod,selectedPeriod.name)

this.CopypayloadSpec1.productOfferingPrice[index].billingPeriod = selectedPeriod.name
      this.payloadSpec1.productOfferingPrice[index].billingPeriod =
        selectedPeriod.name;
      this.payloadSpec1.productOfferingPrice[index].displayValue =
        selectedPeriod.value;
      this.payloadSpec1.productOfferingPrice[
        index
      ].isCommitmentLengthValid = false;

      if (selectedPeriod.name == "Biweekly") {
        this.payloadSpec1.productOfferingPrice[index].commitmentLength = 2;
      } else if (selectedPeriod.name == "BiMonthly") {
        this.payloadSpec1.productOfferingPrice[index].commitmentLength = 2;
      } else if (selectedPeriod.name == "Quarterly") {
        this.payloadSpec1.productOfferingPrice[index].commitmentLength = 3;
      } else if (selectedPeriod.name == "Semi Annually") {
        this.payloadSpec1.productOfferingPrice[index].commitmentLength = 6;
      } else {
        this.payloadSpec1.productOfferingPrice[index].commitmentLength = 1;
      }
    },
    discountValue(data, index) {
      // this.index = index;
      console.log(
          data,
          "bundlediscountValue....",
          this.CopypayloadSpec1?.productOfferingPrice[index]?.bundleAlteration?.discount,"CopypayloadSpec1......Date",
          this.payloadSpec1?.productOfferingPrice[index]?.bundleAlteration?.discount
        );
      this.$emit("discountLog",this.CopypayloadSpec1?.productOfferingPrice[index]?.bundleAlteration?.discount,data)
 
      if (this.CopypayloadSpec1.productOfferingPrice[index]) {
    // Initialize bundleAlteration as an empty object if undefined
    this.CopypayloadSpec1.productOfferingPrice[index].bundleAlteration = 
        this.CopypayloadSpec1.productOfferingPrice[index].bundleAlteration || {};

    // Now safely assign to the discount property
    this.CopypayloadSpec1.productOfferingPrice[index].bundleAlteration.discount = data;
} else {
    console.warn(`Index ${index} does not exist in productOfferingPrice array.`);
}
      this.bundleAlteration.discount =
        data ||
        this.payloadSpec1.productOfferingPrice[index].bundleAlteration.discount;
      console.log(
        "discountValue...",
        data,
        index,
        this.payloadSpec1.productOfferingPrice[index]?.bundleAlteration
          ?.discount
      );

      this.payloadSpec1.productOfferingPrice[index].bundleAlteration.discount =
        this.bundleAlteration.discount;

      this.totalPrice =
        this.payloadSpec1.productOfferingPrice[index].tiers[0].price.value;
      if (
        this.payloadSpec1.productOfferingPrice[index].bundleAlteration
          .discountType.name == "Percentage"
      ) {
        console.log("Percentage if");
        this.totalPrice -=
          this.totalPrice *
          (this.payloadSpec1.productOfferingPrice[index].bundleAlteration
            ?.discount /
            100);
        console.log(
          "Percentage if kkk",
          this.totalPrice,
          this.payloadSpec1.productOfferingPrice[index].bundleAlteration
            ?.discount
        );
      } else {
        this.totalPrice -=
          this.payloadSpec1.productOfferingPrice[
            index
          ].bundleAlteration?.discount;
      }
      this.payloadSpec1.productOfferingPrice[index].totalPrice.value =
        this.totalPrice;
    },
    handleDiscounts(data, index) {
      this.$emit("discountTypeLog", this.CopypayloadSpec1.productOfferingPrice[index].bundleAlteration.discountType.value , data.value)

      console.log(  this.payloadSpec1.productOfferingPrice[
        this.index
      ].bundleAlteration.discountType,
      this.CopypayloadSpec1.productOfferingPrice[index].bundleAlteration.discountType,
      "discountType....",
      data
    );
    this.CopypayloadSpec1.productOfferingPrice[index].bundleAlteration.discountType = data
      this.index = index;

      this.bundleAlteration.discountType = data;
      this.payloadSpec1.productOfferingPrice[
        this.index
      ].bundleAlteration.discountType = data;
      // let data=this.bundleAlteration.discount ;
      this.totalPrice =
        this.payloadSpec1.productOfferingPrice[index].tiers[0].price.value;
      if (data.name == "Percentage") {
        console.log("Percentage if");
        this.totalPrice -=
          this.totalPrice *
          (this.payloadSpec1.productOfferingPrice[index].bundleAlteration
            ?.discount /
            100);
      } else {
        this.totalPrice -=
          this.payloadSpec1.productOfferingPrice[
            index
          ].bundleAlteration?.discount;
      }
      this.payloadSpec1.productOfferingPrice[this.index].totalPrice.value =
        this.totalPrice;
    },
    bundleValueStartDate(data, index) {
      this.$emit("StartDateLog", this.CopypayloadSpec1.productOfferingPrice[index].validFor.startDate , data)
      console.log(data, "his.payloadSpec1.productOfferingPrice[index].validFor.startDat,",this.CopypayloadSpec1.productOfferingPrice[index].validFor.startDate,this.payloadSpec1.productOfferingPrice[index].validFor.startDate,"data inc", this.isISODateString(data));
      // let pricingdiscount = {
      //   action: "UPDATED",
      //   from:
      //     this.payloadSpec1.productOfferingPrice[index].validFor.startDate || 0,
      //   to: data,
      //   property: "startDate",
      // };
    
      // this.CopypayloadSpec1.productOfferingPrice[index].validFor.startDate = data
      this.CopypayloadSpec1.productOfferingPrice[index].validFor.startDate = data
      if (this.isISODateString(data)) {
        console.log("lifecycleStatus..end date", data, index);

        const endOfLifeDate = data;
        const endOfSupportDate =
          this.payloadSpec1.productOfferingPrice[index].validFor.endDate ||
          "9999-12-31T00:00:00Z";

        if (endOfLifeDate <= endOfSupportDate) {
          console.log("endOfLifeDate test", endOfLifeDate);
          this.payloadSpec1.productOfferingPrice[
            index
          ].startDateBoolean = false;
          this.payloadSpec1.productOfferingPrice[index].startDateMsg = "";
          let overlap = this.isDateInRange(
            this.payloadSpec1.productOfferingPrice,
            data,
            index
          );
          console.log("overlap...", overlap);
          if (!overlap) {
            console.log(
              endOfLifeDate <= endOfSupportDate,
              "lifecycleStatus..end date.",
              endOfSupportDate,
              endOfLifeDate
            );
            const today = new Date().toISOString();

            // Check if the current SKU has any item with active lifecycle status
            const anyActive = this.payloadSpec1.productOfferingPrice.some(
              (item, idx) =>
                idx !== index &&
                item.SKU ===
                  this.payloadSpec1.productOfferingPrice[index].SKU &&
                item.lifecycleStatus == "Active"
            );
            const validStartDate = data;
            const validEndDate =
              this.payloadSpec1.productOfferingPrice[index].validFor.endDate;
            // Determine lifecycle status
            console.log(
              !anyActive,
              today >= validStartDate && today <= validEndDate,
              "lifecycleStatus..end date...",
              today
            );
            if (
              !anyActive &&
              today >= validStartDate &&
              today <= validEndDate
            ) {
              this.payloadSpec1.productOfferingPrice[index].lifecycleStatus =
                "Active";
            } else {
              this.payloadSpec1.productOfferingPrice[index].lifecycleStatus =
                "Draft";
            }
          } else {
            this.payloadSpec1.productOfferingPrice[index].lifecycleStatus =
              "Inactive";

            this.payloadSpec1.productOfferingPrice[
              index
            ].startDateBoolean = true;
            this.payloadSpec1.productOfferingPrice[index].startDateMsg =
              "Resolve date overlap.";
          }
        } else {
          this.disableSave = true;
          this.payloadSpec1.productOfferingPrice[index].startDateBoolean = true;
          this.payloadSpec1.productOfferingPrice[index].startDateMsg =
            "On or before End Date.";
          this.payloadSpec1.productOfferingPrice[index].lifecycleStatus =
            "Inactive";
        }
      }
    },

    isDateInRange(dataArray, checkDate, targetIndex) {
      const check = new Date(checkDate);

      return dataArray.some((item, index) => {
        if (index != targetIndex) {
          const startDate = new Date(item.validFor.startDate);
          const endDate = new Date(item.validFor.endDate);

          return check >= startDate && check <= endDate;
        }
        return false;
      });
    },
    bundleValueEndDate(data, index) {
      this.$emit("EndDateLog", this.CopypayloadSpec1.productOfferingPrice[index].validFor.endDate , data)

      if (this.isISODateString(data)) {
        console.log(
          data,
          "bundleValueEndDate....",
          this.CopypayloadSpec1.productOfferingPrice[index].validFor.endDate,"CopypayloadSpec1......Date",
          this.payloadSpec1.productOfferingPrice[index].validFor.endDate
        );
        this.CopypayloadSpec1.productOfferingPrice[index].validFor.endDate = data
        let pricingdiscount = {
          action: "UPDATED",
          from:
            this.payloadSpec1.productOfferingPrice[index].validFor.endDate || 0,
          to: data,
          property: "endDate",
        };
        let pricingLogPayload = {
          message: `${this.payloadSpec?.name} pricing has been updated`,
          detailedAction: [],
          "@type": "Offering-pricing",
          category: ["productOffering"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          ref: [this.payloadSpec?.id],
          action: "UPDATED",
          status: "active",
        };
        pricingLogPayload.detailedAction =
          pricingLogPayload.detailedAction.concat(pricingdiscount);
        console.log(pricingLogPayload, "pricingLogPayload");

        const endOfLifeDate =
          this.payloadSpec1.productOfferingPrice[index].validFor.startDate;
        const endOfSupportDate = data;

        const today = new Date().toISOString();
        console.log(
          endOfLifeDate,
          endOfSupportDate,
          "lifecycleStatus..end date.",
          endOfLifeDate <= endOfSupportDate
        );
        if (endOfLifeDate <= endOfSupportDate) {
          this.disableSave = false;
          this.payloadSpec1.productOfferingPrice[index].validFor.endDate = data;

          const validStartDate =
            this.payloadSpec1.productOfferingPrice[index].validFor.startDate;
          const validEndDate = data;
          this.payloadSpec1.productOfferingPrice[index].endDateBoolean = false;
          this.payloadSpec1.productOfferingPrice[index].endDateMsg = "";
          let overlap = this.isDateInRange(
            this.payloadSpec1.productOfferingPrice,
            data,
            index
          );
          console.log(
            "overlap...",
            overlap,
            today >= validStartDate && today <= validEndDate
          );
          if (!overlap) {
            const anyActive = this.payloadSpec1.productOfferingPrice.some(
              (item, idx) =>
                idx !== index &&
                item.SKU ===
                  this.payloadSpec1.productOfferingPrice[index].SKU &&
                item.lifecycleStatus == "Active"
            );
            if (
              !anyActive &&
              today >= validStartDate &&
              today <= validEndDate
            ) {
              this.payloadSpec1.productOfferingPrice[index].lifecycleStatus =
                "Active";
            } else {
              this.payloadSpec1.productOfferingPrice[index].lifecycleStatus =
                "Draft";
            }
          } else {
            this.payloadSpec1.productOfferingPrice[index].lifecycleStatus =
              "Inactive";
            this.payloadSpec1.productOfferingPrice[index].endDateBoolean = true;
            this.payloadSpec1.productOfferingPrice[index].endDateMsg =
              "Resolve date overlap.";
          }
        } else {
          this.payloadSpec1.productOfferingPrice[index].endDateBoolean = true;
          this.payloadSpec1.productOfferingPrice[index].endDateMsg =
            "Beyond start date.";
          this.payloadSpec1.productOfferingPrice[index].lifecycleStatus =
            "Inactive";
        }
      }
    },
    isISODateString(dateString) {
      const date = new Date(dateString);
      return (
        date instanceof Date &&
        !isNaN(date) &&
        date.toISOString() === dateString
      );
    },
    addAccord(index) {
      console.log("addAccord...", index);
      const prodOfferPrice = JSON.parse(
        JSON.stringify({ ...this.payloadSpec1?.productOfferingPrice[index] })
      ); // Create a shallow copy of the object
      const endDate = new Date(prodOfferPrice.validFor.endDate);

      const newStartDate = new Date(endDate);
      newStartDate.setDate(endDate.getDate() + 1); // Increment the date by 1 day

      prodOfferPrice.validFor.startDate = newStartDate.toISOString();
      prodOfferPrice.validFor.endDate = "9999-12-31T00:00:00Z";
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (
        today >= prodOfferPrice.validFor.startDate &&
        today <= prodOfferPrice.validFor.endDate
      ) {
        prodOfferPrice.lifecycleStatus = "Active";
      } else {
        prodOfferPrice.lifecycleStatus = "Draft";
      }

      // prodOfferPrice.id = uuidv4(); // Add a new id to the copied object
      this.payloadSpec1.productOfferingPrice.push(prodOfferPrice); // Push the copied object with the new id
      console.log("addAccord...", this.payloadSpec1.productOfferingPrice);
      this.$emit("addPricing");
    },

    formatDateRange(val) {
      if (val) {
        const startDate = val?.startDate;
        const endDate = val?.endDate;

        const options = { day: "2-digit", month: "short", year: "numeric" };

        const formatDate = (dateString) => {
          const date = new Date(dateString);
          return date.toLocaleDateString("en-GB", options).replace(/,/, "");
        };

        let formattedStartDate = formatDate(startDate);
        let formattedEndDate =
          endDate === "9999-08-30T00:00:00.000Z"
            ? "30 Dec , 9999"
            : formatDate(endDate);

        return `${formattedStartDate} - ${formattedEndDate}`;
      } else {
        return null;
      }
    },
    // commitmentValue(value, index) {
    //   console.log("CommitmentValue...", value, index);
    //   this.$emit("commitmentValue1", value, index);
    // },

    handleDiscount(value, index) {
      console.log("handleDiscount...", value, index);
      this.$emit("handleDiscount1", value, index);
    },

    selecttheValueorNot() {
      console.log("Check billing period selected");
    },
  },
};
</script>

<style lang="scss">
/* Add any custom styles here */
.specialPricingForBundleOffer {
  width: 292px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2%;
  margin-right: 50px;
}
.bundle {
  .accordion-content {
    padding-left: 18px !important;
  }

  .accordion-item {
    margin-bottom: 10px !important;
  }
}
.notepad_supplier_accordion_note.bundle > .accordion-content {
  background: red !important  ;
  padding: 0 !important;
}
// .notepad_supplier_accordion_note.bundle  {
//   background-color: #24b073 !important;
//   padding-left:0 !important;
//   border-radius: 6px !important;
//   border-bottom: 0 !important;
//   text-align: left;
//   padding-left: 18px !important;
// }
</style>
