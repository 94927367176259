<template>
  <div class="app-layout">
    <section class="right-panel">
      <section class="layout-basic">
        <div class="layout-breadcrumb">
          <hlx-breadcrumb
            :items="breadcrumb_items"
            @path="pathTo"
          />
        </div>
        <div
          class="layout-header"
          style="padding-top: 0"
        >
          <span
            class="header-title"
            style="font-weight: bold"
            >View dependency tree</span
          >
        </div>
        <div
          class="layout-body exec-data-upload"
          style="margin-top: 0"
        >
          <depTree
            :node="$route.params.node"
            type="node"
          ></depTree>
        </div>
        <span
          class="layout-footer"
          style="
            border-top: 1px solid #d8d8d8;
            padding-right: 40px;
            margin-right: -16px;
            margin-left: -16px;
          "
        >
          <span
            class="go-back"
            style="
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            <hlx-button
              class="secondary sm"
              @click="$router.back()"
              >Back</hlx-button
            >
          </span>
        </span>
      </section>
    </section>
  </div>
</template>

<script>
import { DMNAxiosInstance } from '@/config/axiosConfig';
import depTree from '../components/dependencyTreeComponent.vue';

export default {
  components: { depTree },
  data() {
    return {
      viewPermission: [],
      editPermission: [],
      company: 'Halleyx',
      breadcrumb_items: [
        { label: 'Schemas', link: '/business-rules' },
        { label: 'Dependency tree', link: '' },
      ],
      add_payload: [
        {
          name: '',
          schemaName: '',
          description: '',
          data: [],
          fileData: '',
          inputMode: 'upload',
          createdBy: '639af00f9f515f57074584ef',
        },
      ],
      schemaOptions: [],
      selectedSchema: {},
      file_name: '',
      file_data: '',
      show_preview: false,
      skeletonJson: {},
      uploadedJson: {},
      isMatch: false,
    };
  },

  mounted() {
    this.$store.commit('token', this.$route.params.token);
    //  const id = "your-id"; // Replace with your actual id
    //  const name = "your-name"; // Replace with your actual name

    DMNAxiosInstance.get(`/rule_engine/schema`)
      .then((response) => {
        // Handle the response here
        this.schemaOptions = response.data.data;
      })
      .catch((error) => {
        // Handle the error here
        console.error(error);
      });
  },
  methods: {
    checkJsonMatch(skeletonJson, dataJson) {
      // 
      const skeletonKeys = Object.keys(skeletonJson);
      const dataKeys = Object.keys(dataJson);

      if (skeletonKeys.length !== dataKeys.length) {
        return false;
      }

      for (let i = 0; i < skeletonKeys.length; i++) {
        const key = skeletonKeys[i];
        //  const dataKey = dataKeys.find((dataKey) => dataKey === key);
        const skeletonValue = skeletonJson[key];

        // If key is an object, recursively call this function
        if (typeof skeletonValue === 'object') {
          // If key is an array, compare length
          if (Array.isArray(skeletonValue)) {
            if (
              !Array.isArray(dataJson[key]) ||
              skeletonValue.length !== dataJson[key].length
            ) {
              return false;
            }

            // Loop through each object in the array
            for (let j = 0; j < skeletonValue.length; j++) {
              const isMatch = this.checkJsonMatch(
                skeletonValue[j],
                dataJson[key][j]
              );
              if (!isMatch) {
                return false;
              }
            }
          }
          // If key is an object, recursively call this function
          else {
            const isMatch = this.checkJsonMatch(skeletonValue, dataJson[key]);
            if (!isMatch) {
              return false;
            }
          }
        }
      }

      return true;
    },
    chooseSchema(schema) {
      let selectedSchema = schema.filter((obj) => obj.checked === true);
      selectedSchema = JSON.parse(JSON.stringify(selectedSchema))[0];
      const schemaName = selectedSchema.name;
      this.add_payload[0]['schemaName'] = schemaName;
      const obj = {};
      obj[schemaName] = this.addListItems(selectedSchema.schema);
      this.selectedSchema = obj;
      if (
        this.uploadedJson !== {} &&
        this.uploadedJson !== undefined &&
        this.selectedSchema !== {} &&
        this.selectedSchema !== undefined
      ) {
        this.isMatch = this.checkJsonMatch(
          this.selectedSchema,
          this.uploadedJson
        );
        if (this.isMatch) {
          this.add_payload[0].data = this.uploadedJson;
          
        }
      }
    },
    addListItems(obj) {
      for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          if (obj[key].type === 'Array') {
            obj[key].list_items = [];
            const newObj = {};
            newObj.type = 'Object';
            for (const items in obj[key]) {
              // 
              if (
                items !== 'list_items' &&
                items !== 'type' &&
                items !== 'href' &&
                items !== 'ref'
              ) {
                newObj[items] = obj[key][items];
                delete obj[key][items];
              }
            }
            obj[key].list_items.push(newObj);
            // 
          }
          this.addListItems(obj[key]);
        }
      }

      return this.generateJSONFromSchema(obj);
    },
    generateJSONFromSchema(schema) {
      const result = {};

      for (const key in schema) {
        if (Object.hasOwnProperty.call(schema, key)) {
          const value = schema[key];
          const valueType = value.type;

          switch (valueType) {
            case 'String':
              result[key] = '(empty)';
              break;
            case 'Date':
              result[key] = '(empty)';
              break;
            case 'Boolean':
              result[key] = 'false';
              break;
            case 'Integer':
              result[key] = 0;
              break;
            case 'Number':
              result[key] = 0;
              break;
            case 'Object':
              result[key] = this.generateJSONFromSchema(value);
              break;
            case 'Array':
              if (value.list_items) {
                const arrayLength = value.list_items.length;
                const arrayResult = [];

                for (let i = 0; i < arrayLength; i++) {
                  arrayResult.push(
                    this.generateJSONFromSchema(value.list_items[i])
                  );
                }

                result[key] = arrayResult;
              } else {
                result[key] = [];
              }
              break;
            default:
              break;
          }
        }
      }
      return result;
    },
    pathTo() {
      
      this.$router.back();
    },
    previewUploadedFile() {
      this.show_preview = true;
      // 
      // 
      // 
      setTimeout(() => {
        const scrollableDiv = document.querySelector('.layout-body');
        const lastChild = scrollableDiv.lastElementChild;
        // 
        lastChild.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    },
    cancelUploadData() {
      this.add_payload[0] = {
        name: '',
        schemaName: '',
        description: '',
        fileData: '',
        data: [],
        inputMode: 'upload',
        createdBy: '639af00f9f515f57074584ef',
      };
      this.$router.back();
    },
    saveUploadData() {
      if (this.isMatch) {
        //  const id = 'your-id'; // Replace with your actual id
        //  const name = 'your-name'; // Replace with your actual name

        DMNAxiosInstance.post(
          `/rule_engine/execution_data/add/`,
          this.add_payload
        )
          .then((response) => {
            // Handle the response here
            // this.schemaOptions = response.data
            if (response.data.message === undefined) {
              
              this.$router.back();
            } 
          })
          .catch((error) => {
            // Handle the error here
            console.error(error);
            
          });
      } 
    },
    fileDataReceived($event) {
      // 
      this.add_payload[0].fileData = $event.data;
      this.uploadedJson = JSON.parse($event.data);
      // 

      if (
        this.uploadedJson !== {} &&
        this.uploadedJson !== undefined &&
        this.selectedSchema !== {} &&
        this.selectedSchema !== undefined
      ) {
        this.isMatch = this.checkJsonMatch(
          this.selectedSchema,
          this.uploadedJson
        );
        if (this.isMatch) {
          this.add_payload[0].data = this.uploadedJson;
          // 
        }
      }
    },
  },
};
</script>
