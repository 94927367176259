<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'220px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <div class="app-layout">
    <section class="new-right-panel">
      <!-- <hlx-switch
        v-model="selectedValue"
        :switch-items="groupValues"
        :tab-view="true"
        value-prop="value"
        display-prop="name"
        @change="handleCurrentChange"
      /> -->
      <div class="new-right-panel-parent-container no-filter-panel">
        <div class="new-right-panel-left-container"></div>
        <div class="new-right-panel-right-container">
          <section class="new-layout-basic-no-breadcrumb">
            <div class="new-layout-header">
              <span class="new-layout-header-container">
                <span class="schema-header-title"
                  >Schema<i class="icon-clock"></i>
                </span>
                <span class="subtitle">
                  Create and manage all your schemas and variables.
                </span>
              </span>
            </div>
            <div class="new-layout-body">
              <div
                class="header-utils-and-switch-container"
                style="width: 100%"
              >
                <span style="width: 70%">
                  <!-- <hlx-switch
                  :switch_items="switch_items"
                  :type="'tab'"
                  :position="'bottom'"
                  @chosen="switchmenu"
                /> -->
                  <!-- <hlx-switch
                    v-model="selectedValue"
                    :switch-items="switch_items"
                    :tab-view="true"
                    value-prop="value"
                    display-prop="name"
                    @change="handleCurrentChange"
                  /> -->
                </span>
                <span class="schema-header-utils">
                  <div class="util-items search">
                    <hlx-search
                      class="search"
                      :search_style="'style1'"
                      @search-key="searchKey"
                    />
                  </div>
                  <!-- <hlx-button class="secondary-success sm filter-button"

                  ><i class="icon-filter-regular" style="margin-right: 5px"></i
                  >Filter</hlx-button
                > -->
                  <span class="util-items util-items-add">
                    <hlx-button
                     v-if="permisstionData.add "
                      class="primary sm add-button"
                      @click.stop="addbutton"
                      ><i
                        class="icon-plus-circle-regular"
                        style="margin-right: 5px"
                      ></i
                      >Add
                      <hlx-context-menu
                        :options="schemaoptions"
                        :show="clicked"
                        style="margin-top: 15px; margin-right: 20px"
                        @chosen="chooseschemaoptions"
                      />
                    </hlx-button>
                  </span>
                </span>
              </div>
              <div v-if="tableData.length > 0" class="table-wrap">
                <hlx-table
                  :column-count="theads.length + 2"
                  :border="['table', 'header', 'horizontal']"
                  :bold-headers="false"
                  :row-hover="true"
                  theme="grey"
                  :striped-rows="false"
                  :re-arrange-columns="re_arrange"
                  :re-arrange-heads="theadsC"
                  @close-rearrange="re_arrange = false"
                  @apply-rearranged-columns="applyColumns"
                >
                  <template #thead>
                    <hlx-table-head
                      :width="60"
                      :align="'left'"
                      style="
                        padding: 0 15px !important;
                        max-width: 60px !important;
                      "
                      :fixed="'left'"
                      @new-resize-width="resizedWidth"
                      >{{ "S.no" }}</hlx-table-head
                    >
                    <hlx-table-head
                      v-for="(i, index) in theads"
                      :key="index"
                      :prop="i.prop"
                      :sortable="i.sortable"
                      :resizable="i.resizable"
                      :width="i.width"
                      @sorting_func="sorting_Data"
                      @resizewidth="resizewidthdata"
                      @new-resize-width="resizedWidth"
                    >
                      {{ i.label }}
                    </hlx-table-head>
                    <hlx-table-head
                      v-show="editMode == true"
                      :width="60"
                      :fixed="'right'"
                      style="max-width: 60px !important"
                      @new-resize-width="resizedWidth"
                      ><span class="action-gear"
                        ><i class="icon-settings-regular" @click="reAssignOrgTableHeader()"></i></span
                    ></hlx-table-head>
                  </template>
                  <template #tbody>
                    <tr v-for="(i, index) in paginatedData" id="" :key="index">
                      <hlx-table-cell
                        :align="'center'"
                        style="padding: 0 15px"
                        :fixed="'left'"
                        >{{ serialNumber(index) }}</hlx-table-cell
                      >
                      <hlx-table-cell
                        v-for="(j, col_index) in theads"
                        :key="col_index"
                        :align="
                          j.prop.toLowerCase() === 'updatedat' ? 'left' : ''
                        "
                      >
                        <div
                          v-if="j.prop === 'name'"
                          :id="j.prop + index"
                          class="link-name"
                          style="
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          "
                          :style="{
                            width: '150px',
                          }"
                          @click="editdata(i._id)"
                          @mouseover="
                            tooltipover(
                              j.prop + index,
                              'tooltip-text' + j.prop + index
                            )
                          "
                          @mouseleave="
                            tooltipleave(
                              j.prop + index,
                              'tooltip-text' + j.prop + index
                            )
                          "
                        >
                          {{
                            i[j.prop].charAt(0).toUpperCase() +
                            i[j.prop].slice(1)
                          }}
                          <p
                            id="tooltip-text"
                            :class="'tooltip-text' + j.prop + index"
                          >
                            {{ i[j?.prop] }}
                          </p>
                        </div>
                        <div v-else-if="j.prop.toLowerCase() === 'updatedat'">
                          {{ convertdateformat(i[j.prop]) }}
                        </div>
                        <div
                          v-else-if="
                            j.prop.toLowerCase() === 'description' &&
                            typeof i[j.prop] === 'string'
                          "
                          :id="j.prop + index"
                          style="
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          "
                          :style="
                            j.width === null
                              ? {
                                  width: '280px',
                                }
                              : ''
                          "
                          @mouseover="
                            tooltipover(
                              j.prop + index,
                              'tooltip-text' + j.prop + index
                            )
                          "
                          @mouseleave="
                            tooltipleave(
                              j.prop + index,
                              'tooltip-text' + j.prop + index
                            )
                          "
                        >
                          {{ i[j.prop] }}
                          <p
                            id="tooltip-text"
                            :class="'tooltip-text' + j.prop + index"
                          >
                            {{ i[j?.prop] }}
                          </p>
                        </div>
                        <div v-else :align="'center'">
                          {{ i[j.prop] }}
                        </div>
                      </hlx-table-cell>
                      <hlx-table-cell
                      
                    v-if="permisstionData.edit || permisstionData.delete"
                        v-show="editMode == true"
                        :align="'center'"
                        :width="60"
                        :fixed="'right'"
                      >
                        <span :id="'table-context' + index" class="action-icon">
                          <i
                            style="position: relative"
                            class="icon-more-vertical-filled"
                            :class="i.context === true ? 'active-action' : ''"
                            @click="crudContextMenu($event, index)"
                          >
                            <hlx-context-menu
                              :top="containertop"
                              :left="containerleft"
                              :conditionvalue="150"
                              :conditiondown="2"
                              :conditionup="-38"
                              :options="computedTableAction()"
                              :data="index.toString()"
                              :show="i.context == true"
                              @chosen="contextmenuclose($event, i, index)"
                            />
                            <!-- <span v-else style="position: absolute">
                              <hlx-context-menu
                                style="
                                  z-index: 9000;
                                  margin-right: 90px !important;
                                  margin-top: -52px;
                                  box-shadow: 0 0 20px 0 #d8d8d8;
                                "
                                :options="crudoptions"
                                :data="index.toString()"
                                :show="i.context == true"
                                @chosen="
                                  contextmenuclose(
                                    $event,
                                    i,
                                    serialNumber(index)
                                  )
                                "
                              />
                            </span> -->
                          </i>
                          <!-- <div class="custom-context-menu-container" :id="'custom-context-menu-container'+serialNumber(index)+this.unique"> -->
                          <!-- <div class="custom-context-menu-container" v-for="(i,index) in this.optionsD" :key="index"> -->
                          <!-- <i :class="this.optionsD[index].icon" id="icon-value"></i> -->
                          <!-- <p class="hlx-select-option-value">{{this.optionsD[index][this.Name]}}</p> -->
                          <!-- </div> -->
                          <!-- </div> -->
                        </span>
                      </hlx-table-cell>
                      <!-- <hlx-table-cell :fixed="'right'"
              ><i class="icon-more-vertical-regular" @click="display(i,index)">
                <hlx-context-menu
                      :options="this.crudoptions"
                      :data="index.toString()"
                      @chosen="contextmenuclose($event,i,serialNumber(index))"
                      :show="i.context['show'] == true"
                    />
              </i>
            </hlx-table-cell> -->
                    </tr>
                  </template>
                </hlx-table>
              </div>
              <div v-else class="table-wrap">
                <hlx-table
                  :column-count="theads.length + 2"
                  :border="['table', 'header']"
                  :bold-headers="false"
                  :row-hover="true"
                  theme="grey"
                  :striped-rows="false"
                  :re-arrange-columns="re_arrange"
                  :re-arrange-heads="theadsC"
                  @close-rearrange="re_arrange = false"
                  @apply-rearranged-columns="applyColumns"
                >
                  <template #thead>
                    <hlx-table-head :width="60" :align="'left'" @new-resize-width="resizedWidth">{{
                      "S.no"
                    }}</hlx-table-head>
                    <hlx-table-head
                      v-for="(i, index) in theads"
                      :key="index"
                      :prop="i.prop"
                      :sortable="i.sortable"
                      :resizable="i.resizable"
                      style="position: sticky; top: 0px"
                      :width="i.width !== undefined ? i.width : ''"
                      :align="i.align !== undefined ? i.align : ''"
                      @sorting_func="sorting_Data"
                      @new-resize-width="resizedWidth"
                    >
                      {{ i.label }}
                    </hlx-table-head>
                    <hlx-table-head 
                    v-if="permisstionData.edit || permisstionData.delete" :width="100" @new-resize-width="resizedWidth">
                      <span class="action-icon"
                        ><i
                          class="icon-settings-regular"
                          @click="reAssignOrgTableHeader()"
                        ></i></span
                    ></hlx-table-head>
                  </template>
                  <template #tbody>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell :colspan="theads.length + 2">{{
                        "No data"
                      }}</hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                  </template>
                </hlx-table>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="new-layout-footer">
        <div class="pagination-container">
          <hlx-pagination
            v-model:rows-per-page="externalRowsPerPage"
            :total="totalPageNo"
            enable-rows-per-page
            :rows-per-page-list="[10, 15, 20]"
            :options="{
              attributes: {
                rowsPerPageTitle: true,
                rowsPerPageResult: true,
                rowsPerPageDropdown: {
                  position: {
                    top: 0,
                    right: 1,
                  },
                },
              },
            }"
            @current-page="currentPage"
            @updated:rows-per-page="changeRowsPerPage"
          ></hlx-pagination>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { DMNAxiosInstance, MLTAxiosInstance } from "@/config/axiosConfig";

export default {
  name: "LocalView",

  data() {
    return {
      permisstionData:{},
      tableHeaderOrg:[],
      tableHeaderOrgcc:[],
      re_arrange:false,
      totalPageNo: 0,
      schemaquery: {
        type: "filter",
        module: "schema",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      },
      externalRowsPerPage: 10,
      containerleft: 0,
      containertop: 0,
      editPermission: [],
      viewPermission: [],
      token: this.$route.params.token,
      editMode: true,
      company: "",
      duplicateTheads: [],
      delete_modal_active: false,
      deleteobj: {},
      searchtabledata: [],
      searchvalue: "",
      duplicatetabledata: [],
      notification: {},
      success: false,
      success_msg: "",
      view: "",
      crudoptions: [
        {
          label: "Edit",
          icon: "icon-edit-regular",
        },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ],
      schemaoptions: [
        {
          label: "Schema",
          icon: "icon-database-regular",
        },
        {
          label: "Custom variable",
          icon: "icon-file-edit-regular",
        },
      ],
      swited: "Schema",
      switch_items: [
        { name: "Schema", value: "schema" },
        // { name: "Custom variable", value: "custom variable" }
      ],
      selectedValue: "schema",
      theads: [
      {
          name: "Name",
          checked: true,
          id: 1,
          disabled: true,
          prop: "name",
          label: "Name",
          sortable: true,
          resizable: true,
          width: null,
        },
        {
          name: "Description",
          checked: true,
          id: 2,
          disabled: false,
          prop: "description",
          label: "Description",
          sortable: true,
          resizable: true,
          width: 350,
        },
        {
          name: "Last modified",
          checked: true,
          id: 3,
          disabled: false,
          prop: "updatedAt",
          label: "Last modified",
          sortable: true,
          resizable: true,
          width: 250,
        },
      ],
      theadsC: [
      {
          name: "Name",
          checked: true,
          id: 1,
          disabled: true,
          prop: "name",
          label: "Name",
          sortable: true,
          resizable: true,
          width: null,
        },
        {
          name: "Description",
          checked: true,
          id: 2,
          disabled: false,
          prop: "description",
          label: "Description",
          sortable: true,
          resizable: true,
          width: 350,
        },
        {
          name: "Last modified",
          checked: true,
          id: 3,
          disabled: false,
          prop: "updatedAt",
          label: "Last modified",
          sortable: true,
          resizable: true,
          width: 250,
        },
      ],

      schemaheads: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: false,
          prop: "name",
          label: "Name",
          sortable: true,
          resizable: false,
          width: 350,
        },
        {
          name: "Description",
          checked: true,
          id: 2,
          disabled: false,
          prop: "description",
          label: "Description",
          sortable: true,
          resizable: false,
          width: null,
        },
        {
          name: "Last modified",
          checked: true,
          id: 3,
          disabled: false,
          prop: "updatedAt",
          label: "Last modified",
          sortable: true,
          resizable: false,
          width: 250,
        },
      ],
      customvariableheads: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: false,
          prop: "name",
          label: "Name",
          sortable: true,
          resizable: false,
          width: 350,
        },

        {
          name: "Type",
          checked: true,
          id: 2,
          disabled: false,
          prop: "type",
          label: "Type",
          sortable: true,
          resizable: false,
          width: 350,
        },
        {
          name: "Last modified",
          checked: true,
          id: 3,
          disabled: false,
          prop: "lastvisited",
          label: "Last modified",
          sortable: true,
          resizable: false,
          width: 350,
        },
      ],
      tableData: [],
      schemadata: [],
      customvariabledata: [],

      currPage: 1,
      rowsPerPage: 10,
      clicked: false,
    };
  },
  computed: {
    paginatedData() {
      // const start = (this.currPage - 1) * this.rowsPerPage;
      // const end = start + this.rowsPerPage;
      // return this.tableData.slice(start, end);
      return this.tableData;
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.tableData.length;
    },
  },
  watch: {
    tableData: {
      handler(data) {
        console.log(data);
        // data?.forEach((e,index) => {
        //     e.context = false;
        //     e.index = index
        // })
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");

let sampleTheadsd = await MLTAxiosInstance.get(
  `partyRole/get/${val.data.userId}`
);

const mergedPayload = sampleTheadsd.data.permission;


this.permisstionData =
mergedPayload.DMN.child["Schema"];


    // To get table header
    let sampleTheads = await MLTAxiosInstance.get(`/settings`);

if (sampleTheads?.data?.entityRef?.["schemaTable"]) {
  let offerTableHeadModal = sampleTheads.data.entityRef["schemaTable"];
  let offerTableHeadOrg = sampleTheads.data.entityRef["schemaTable"].filter(ele => ele.checked);
  console.log(offerTableHeadOrg,"offerTableHeadModal",offerTableHeadModal)

  if (
    offerTableHeadModal.length ===  this.theadsC.length 
    // &&
    // offerTableHeadModal.every((item, index) => item.name ===  this.theadsC[index].name)
  ) {
     this.theadsC = JSON.parse(JSON.stringify(offerTableHeadModal));
       this.theads = JSON.parse(JSON.stringify(offerTableHeadOrg));
       this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.theadsC ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.theads))
  }
}
    // this.theads = JSON.parse(JSON.stringify(this.schemaheads));
    // const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    this.company = val.data.company;
    // const val = await MLTAxiosInstance.get(
    //     `partymanagement/individual/auth`
    // );
    // this.company = val.data.company;
    // this.userId = val.data.company;
    // const access = await MLTAxiosInstance.get(
    //     `partyRole/get/${val.data.company}/${val.data.userId}`
    // );
    // let arr = access.data;
    // if(arr){
    //   this.editPermission = [];
    //   if (arr.permission.User_settings.edit == false) {
    //     this.editPermission.push("setting");
    //   }
    //   if (arr.permission.User_settings.view == false) {
    //     this.viewPermission.push("setting");
    //   }
    //   if (arr.permission.Schema.edit == false) {
    //   this.editPermission.push("schema");
    // }
    // if (arr.permission.Schema.view == false) {
    //   this.viewPermission.push("Schema");
    // }
    // if (arr.permission.Test_data.view == false) {
    //   this.viewPermission.push("Database", "Execute");
    // }
    // if (arr.permission.Test_data.edit == false) {
    //   this.editPermission.push("testdata");
    // }
    // if (arr.permission.Rules.view == false) {
    //   this.viewPermission.push("Business rule");
    // }
    // if (arr.permission.Rules.edit == false) {
    //   this.editPermission.push("Business rule");
    // }
    // if (this.editPermission.includes("Business rule") == true) {
    //
    //     this.editPermission.includes("schema"),
    //     this.editMode,
    //     this.editPermission,
    //     "done"
    //     );
    //     this.editMode = false;
    //   }
    // }
    document.addEventListener("click", (event) => {
      if (!event.target.classList.contains("add-button")) {
        this.clicked = false;
      }
      if (!event.target.classList.contains("icon-more-vertical-filled")) {
        this.tableData.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    });

    // this.$store.commit("token", this.$route.params.token);

    // const alldata = await DMNAxiosInstance.get(
    //   `https://dmnbackend-ghubgwxrcq-uc.a.run.app/rule_engine/schema`,
    //   {
    //     auth: {
    //       username: "testuser@test.com",
    //       password: " ",
    //     },
    //   }
    // );

    // const alldata = await DMNAxiosInstance.get(`rule_engine/schema`);
    this.schemaDataCalling(this.currPage, this.rowsPerPage);

    // const schemadata = await alldata.data.data.map((val, index) => {
    //   val.index = index;
    //   val.context = false;
    //   return val;
    // });
    // this.alldata = schemadata;
    // this.tableData = await this.alldata;

    // this.tableData = this.tableData.sort(
    //   (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    // );
    this.duplicateTheads = this.schemaheads;
    // this.duplicatetabledata = await this.tableData;
    // this.searchtabledata = await this.tableData;
  },
  methods: {
    computedTableAction() {
      if (this.permisstionData.delete && this.permisstionData.edit) {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }  else if (this.permisstionData.delete ) {
        return [
          
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }  else {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
        ];
      }
      // return [
      //   {
      //     label: "Edit",
      //     icon: "icon-file-text-edit-regular",
      //   },
      //   {
      //     label: "Delete",
      //     icon: "icon-trash-regular",
      //   },
      // ];
    },
    reAssignOrgTableHeader(){
            // If there is change in the table header then ressign to the Orginal data
      if((this.theadsC != this.tableHeaderOrgcc) && this.tableHeaderOrgcc.length > 0)
    {
      this.theadsC = JSON.parse(JSON.stringify(this.tableHeaderOrgcc))
    }
    if((this.theads != this.tableHeaderOrg) && this.tableHeaderOrg.length > 0)
    {
      this.theads = JSON.parse(JSON.stringify(this.tableHeaderOrg))
    }
      this.re_arrange = true
    },
    resizedWidth(val){
      console.log(val,"resizedWidth",val)

      const updateWidth = (array) => {
    let item = array.find(i => i?.prop === val?.prop);
    if (item) item.width = val.width;
  };

  updateWidth(this.theads);
  updateWidth(this.theadsC);
console.log(this.theads,"resizedWidth loop",this.theadsC)
this.applyColumns(this.theadsC)
    },
   async applyColumns(val){
      console.log("applyColumns",val)
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
let concatedEntity = sampleTheads?.data?.entityRef || {};

concatedEntity.schemaTable = val;

let reArrangeTableHeadPayload = { entityRef: concatedEntity };
 
const changeTableHeader = await MLTAxiosInstance.patch(`/settings`, reArrangeTableHeadPayload);
console.log(changeTableHeader, "reArrangeTableHeadPayload", reArrangeTableHeadPayload);

let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
console.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
this.theadsC = entityRef["schemaTable"];
this.theads = this.theadsC.filter(ele => ele.checked);
this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.theadsC ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.theads))
    },
    async schemaDataCalling(schemaCurrentPage, schemaRowsPerPage) {
      this.schemaquery["paginatedQuery"]["currentPage"] = schemaCurrentPage;
      this.schemaquery["paginatedQuery"]["rowsPerPage"] = schemaRowsPerPage;
      this.schemaquery["company"] = this.company;

      let filterResultData = await DMNAxiosInstance.post(
        `util/filterData`,
        this.schemaquery
      );
      if (filterResultData.data.ok == 1) {
        filterResultData = filterResultData.data;
        this.totalPageNo = filterResultData.data.total;
        this.tableData = filterResultData.data.data;
        this.duplicate_table_data = this.tableData;
      }
      console.log(this.tableData, "data");
    },
    convertdateformat(date1) {
      if (date1 != null) {
        let objectDate = new Date(date1);
        if (isNaN(objectDate) == false) {
          let date = objectDate.toLocaleString("default", { day: "numeric" });
          let month = objectDate.toLocaleString("default", { month: "short" });
          let year = objectDate.getFullYear();
          let dateformat = month + " " + date + ", " + year;
          return dateformat;
        }
      }
    },
    tooltipover(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis(data1, index1);
    },
    tooltipleave(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses(data1, index1);
    },
    ellipsis(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              let height = tooltip.offsetHeight;
              let top = element1.getBoundingClientRect().top - height;
              let left = element1.getBoundingClientRect().left;
              tooltip.style.display = "block";
              tooltip.style.top = top + "px";
              tooltip.style.left = left + "px";
              return true;
            }
          }
          return false;
        }
      });
    },
    ellipses(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              tooltip.style.display = "none";
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },
    changeRowsPerPage(count) {
      //
      if (this.company != "") {
        this.rowsPerPage = count;
        this.schemaDataCalling(this.currPage, this.rowsPerPage);
      }
    },
    crudContextMenu($event, index) {
      // this.$nextTick(()=>{
      if (document.querySelector("#table-context" + index)) {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
      }
      // })
      if ($event.target.className === "icon-more-vertical-filled") {
        if (this.tableData[index].context != undefined) {
          if (this.tableData[index].context == false) {
            this.tableData[index].context = true;
          } else {
            this.tableData[index].context = false;
          }
          console.log(this.tableData[index].context);
        } else {
          this.tableData.forEach((e) => {
            e.context = false;
          });
          this.tableData[index].context = true;
        }
      }
    },
    async customGet() {
      const alldata = await DMNAxiosInstance.get(`customVariable/all`);

      if (alldata.data.data != undefined) {
        const schemadata = await alldata.data.data.map((val) => {
          val.context = { show: false };
          return val;
        });
        //
        this.tableData = schemadata;
        this.tableData = this.tableData.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        this.duplicatetabledata = this.tableData;
        this.searchtabledata = this.tableData;
      }
    },
    async schemaGet() {
      // const alldata = await DMNAxiosInstance.get(`rule_engine/schema`);
      // const schemadata = await alldata.data.data.map((val, index) => {
      //   val.index = index;
      //   val.context = false;
      //   return val;
      // });
      // this.alldata = schemadata;
      // this.tableData = await this.alldata;
      // this.tableData = this.tableData.sort(
      //   (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      // );
      // this.duplicatetabledata = this.tableData;
      // this.searchtabledata = this.tableData;
      // this.theads = await this.schemaheads;
    },
    navTo(active) {
      this.$store.commit("token", this.$route.params.token);

      if (active.name == "Home") {
        this.navHome();
      } else if (active.name == "logout") {
        this.logout();
      } else if (active.name == "settings") {
        this.setting();
      } else if (active.name == "Schema") {
        this.schema();
      } else if (active.name == "Business rule") {
        this.ruled();
      } else if (active.name === "Database") {
        this.execute();
      } else if (active.name == "Help") {
        this.help();
      } else if (active.name == "Execute") {
        this.exe();
      } else {
        this.audit();
        //
      }
    },
    audit() {
      this.$router.push(`/DMN/audit`);
    },
    help() {
      window.open("https://documentation.halleyx.com");
    },
    ruled() {
      this.$router.push(`/DMN/business_rules`);
    },
    exe() {
      this.$router.push(`/DMN/rule_validation`);
    },
    execute() {
      this.$router.push(`/DMN/reference_data`);
    },
    schema() {
      this.$router.push(`/schemas`);
    },

    logOut() {
      const add =
        window.location.protocol +
        "//" +
        window.location.hostname +
        ":" +
        window.location.port;
      const urlObject = new URL(add);
      const hostName = urlObject.hostname;
      window.location.href =
        window.location.protocol + "//" + hostName + ":" + window.location.port;
    },
    setting() {
      this.$router.push(`/settings`);
    },
    async navHome() {
      this.$router.push(`/DMN/dashboard`);
    },

    display(ele, index) {
      this.closeindex = index;
      if (
        event.target.classList.contains("icon-more-vertical-regular") == true
      ) {
        if (ele.context["show"] == true) {
          ele.context["show"] = false;
        } else {
          ele.context["show"] = true;
        }
      } else {
        ele.context["show"] = false;
      }
    },
    async contextmenuclose(e, ele, index) {
      //  ele.context['show'] = false
      //
      let id = await ele._id;

      if (e === "Edit") {
        // if(this.swited=="Custom variables")
        // {
        //   window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/custom_edit/${id} `;

        // }
        // else{

        //   window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/schemas_edit/${id} `;
        // }
        if (this.swited == "Custom variables") {
          this.$router.push(`/DMN/custom_edit/${id}`);

          // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/custom_edit/${val} `;
        } else {
          this.$router.push(`/schemas/edit/${id}`);

          // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/schemas_edit/${val} `;
        }
      } else if (e === "Delete") {
        this.deleteobj = ele;
        this.delete_name = ele.name;
        this.delete_modal_active = true;
        this.deleterowindex = index;
      }
    },
    async deleteExecRow() {
      this.delete_modal_active = false;
      let id = await this.deleteobj._id;
      // if (this.swited == "Custom variables") {
      //   this.success_msg = "Custom variable deleted successfully";
      //   this.notification = { type: "success", message: this.success_msg };
      //   this.customGet();
      // } else {
      await DMNAxiosInstance.delete(`rule_engine/schema/${id}`);

      this.success = true;
      this.success_msg = "Schema deleted successfully";
      this.notification = { type: "success", message: this.success_msg };
      // this.currPage = 1
      this.schemaDataCalling(this.currPage, this.rowsPerPage);
      // }
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    currentPage(val) {
      if (this.company != "") {
        this.currPage = val;
        this.schemaDataCalling(this.currPage, this.rowsPerPage);
        console.log(this.tableData);
      }
    },
    handleCurrentChange(val) {
      this.swited = val.value;

      if (val.value === "schema") {
        this.currPage = 1;
        this.schemaGet();
        this.theads = this.schemaheads;
        this.tableData = this.schemadata;
        this.searchtabledata = this.tableData;
      } else if (val.value === "custom variable") {
        this.currPage = 1;
        this.customGet();
        this.theads = this.customvariableheads;
        this.tableData = this.customvariabledata;
        this.searchtabledata = this.tableData;
      } else if (val.value === "All") {
        this.theads = this.allheads;
        this.tableData = this.alldata;
        this.searchtabledata = this.tableData;
      }
    },
    addbutton() {
      this.$router.push(`/schemas/local`);

      // this.clicked = true;
    },
    chooseschemaoptions(val) {
      if (val === "Schema") {
        this.clicked = false;
        // this.$router.push({ path: `/DMN/schemas/selected_schema_flow` })
        this.$router.push(`/schemas/local`);

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/schemas/selected_schema_flow `;
      } else {
        this.clicked = false;
        // this.$router.push({ path: `/DMN/schemas/custom-variable/add` })
        this.$router.push(`/DMN/schemas/custom-variable/add`);

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/schemas/custom-variable/add `;
      }
    },
    editdata(val) {
      if (this.swited == "Custom variables") {
        this.$router.push(`/DMN/custom_edit/${val}`);

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/custom_edit/${val} `;
      } else {
        this.$router.push(`/schemas/view/${val}`);

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/schemas_edit/${val} `;
      }

      //  this.$router.push({ path: `/DMN/schemas_edit/${val}` })
      //  window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/schema_edit/${val} `;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return new Intl.DateTimeFormat("en-US", options)
        .format(date)
        .replace(",", "");
    },
    searchKey(val) {
      this.searchvalue = val;
      if (val != "") {
        this.schemaquery["type"] = "search";
      } else {
        this.schemaquery["type"] = "filter";
      }
      this.schemaquery["searchQuery"] = this.searchvalue;
      this.currPage = 1;
      // this.rowsPerPage = 10;
      this.schemaDataCalling(this.currPage, this.rowsPerPage);

      // this.searchvalue = val;
      // if (this.searchvalue && this.searchvalue != "") {
      //   this.tableData = this.searchtabledata.filter((item) => {
      //     if (
      //       item.name != undefined &&
      //       item.description != undefined &&
      //       this.searchvalue != undefined
      //     ) {
      //       return (
      //         item.name
      //           .toLowerCase()
      //           .toString()
      //           .includes(this.searchvalue.toLowerCase()) ||
      //         item.description
      //           .toLowerCase()
      //           .toString()
      //           .includes(this.searchvalue.toLowerCase())
      //       );
      //     }
      //   });
      //   this.duplicatetabledata = this.tableData;
      // } else {
      //   this.tableData = this.searchtabledata;
      //   this.duplicatetabledata = this.searchtabledata;
      // }
    },
    sorting_Data(data, data1) {
      //
      if (data === "icon-angle-up-small-filled sorting-icon") {
        this.schemaquery.type = "filter";
        this.schemaquery.isNeedToBeSort = true;
        console.log(data1, "data1");
        if (data1 === "name") {
          this.schemaquery.sortFieldAndOrder = { name: 1 };
        } else if (data1 == "description") {
          this.schemaquery.sortFieldAndOrder = { description: 1 };
        } else if (data1 == "updatedAt") {
          this.schemaquery.sortFieldAndOrder = { updatedAt: 1 };
        }
        this.currPage = 1;
        this.schemaDataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled sorting-icon") {
        this.schemaquery.type = "filter";
        this.schemaquery.isNeedToBeSort = true;
        if (data1 === "name") {
          this.schemaquery.sortFieldAndOrder = { name: -1 };
        } else if (data1 == "description") {
          this.schemaquery.sortFieldAndOrder = { description: -1 };
        } else if (data1 == "updatedAt") {
          this.schemaquery.sortFieldAndOrder = { updatedAt: -1 };
        }
        this.currPage = 1;
        this.schemaDataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-up-small-filled") {
        this.schemaquery.type = "filter";
        this.schemaquery.isNeedToBeSort = false;
        this.schemaquery.sortFieldAndOrder = {};
        this.currPage = 1;
        this.schemaDataCalling(this.currPage, this.rowsPerPage);
      } else if (data === "icon-angle-down-small-filled") {
        this.schemaquery.type = "filter";
        this.schemaquery.isNeedToBeSort = false;
        this.schemaquery.sortFieldAndOrder = {};
        this.currPage = 1;
        this.schemaDataCalling(this.currPage, this.rowsPerPage);
      }
    },
  },
};
</script>

<style></style>
