<template>
  <div class="app-layout">
    <section class="right-panel">
      <hlx-alert-notification
        :notify="notification"
        :auto-close="true"
      />
      <main class="--right-layout-pad rule-execution-container">
        <div class="layout-breadcrumb">
          <hlx-breadcrumb
            :items="breadcrumb_items"
            @path="pathTo"
          />
        </div>
        <div class="execution-separator-container">
          <section class="execution-attributes-container">
            <div class="execute-data-title">
              <span class="--title"
                >Supply input values to test the rule execution</span
              >
              <hlx-button
                class="primary sm"
                style=""
                @click="testRuleExecution"
              >
                Test
              </hlx-button>
            </div>
            <!-- <div>Execute rule</div>
                <span class="info"
                  >Manage test data for rule validation and testing</span
                > -->
            <div class="custom-object-viewer">
              <span
                class="manual-data-content"
                style="height: 100%"
              >
                <hlx-object-viewer
                  :json-data="input"
                  :show="true"
                  :type="'add-data-from-schema'"
                  @updated-json="recievedJson($event)"
                />
              </span>
              <span
                v-if="showValidationError"
                style="color: #fe4141; font-size: 12px"
                >Please supply the data to test the rule</span
              >
            </div>
          </section>
          <section class="result-container">
            <div class="result-metric-panel">
              <span class="--title">Output</span>
              <span class="--title"
                >Executed duration:&nbsp;
                <span :style="['color:#54BD95;']">
                  {{ average_time + ' ms' }}</span
                ></span
              >
              <span class="--title"
                >Status:&nbsp;
                <span>
                  <hlx-label
                    v-if="isExecuted == true"
                    class="sm"
                    :class="{ success: true, danger: false }"
                    >Success</hlx-label
                  >
                  <span v-else> -- </span>
                </span>
              </span>
            </div>
            <div
              v-if="isExecuted == false"
              class="manual-data-content"
            >
              <hlx-object-viewer
                :json-data="output"
                :show="true"
                :type="'read-only'"
                @updated-json="recievedJson($event)"
              />
            </div>
            <div
              v-else
              class="manual-data-content"
              style="height: 100%"
            >
              <hlx-object-viewer
                :json-data="boundResult"
                :show="true"
                type="read-only"
              >
              </hlx-object-viewer>
            </div>
          </section>
        </div>
      </main>
    </section>
  </div>
</template>
<script>
import { DMNAxiosInstance } from '@/config/axiosConfig';
export default {
  data() {
    return {
      input: {},
      dummyInput: {},
      output: {},
      average_time: 0,
      isExecuted: false,
      successful_execution_rate: '',
      unsuccessful_execution_rate: 0,
      notification: {},
      breadcrumb_items: [{ label: 'Execute rule', link: '' }],
      add_payload: [
        {
          name: '',
          schemaName: '',
          description: '',
          inputMode: 'manual',
          data: [],
          createdBy: '639af00f9f515f57074584ef',
        },
      ],
      schemaOptions: [],
      selectedSchema: {},
      executionResult: false,
      boundResult: {},
      rulename: '',
      validation: false,
      showValidationError: false,
    };
  },
  watch: {
    add_payload: {
      handler(newv, val) {
        if (newv === val) {
          this.validation = newv === val;
          this.showValidationError = false;
          document.querySelector('.manual-data-content').style.border =
            '1px solid #d8d8d8';
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    this.$store.commit('token', this.$route.params.token);
    //  const id = 'your-id'; // Replace with your actual id
    //  const name = 'your-name'; // Replace with your actual name
    await DMNAxiosInstance.get(`/rule_engine/rule/${this.$route.params.id}`)
    .then((response) => {
      // Handle the response here
      this.rulename = response.data.data.name;
        //
        let obj = {};
    obj.label = this.rulename;
    this.breadcrumb_items.push(obj);
        this.input = response.data.data.variableSchema.input;
        this.dummyInput = response.data.data.variableSchema.input;
        // console.log(
        //   '🚀 ~ file: ExecuteRuleViewV1.vue:149 ~ .then ~ this.dummyInput:',
        //   this.dummyInput
        // );
        this.input = { 'Input variables': this.addListItems(this.input) };
        // console.log(
        //   '🚀 ~ file: ExecuteRuleViewV1.vue:150 ~ .then ~ this.input:',
        //   this.input
        // );
    this.dummyInput = this.addListItems(this.dummyInput);
    this.output = response.data.data.variableSchema.output;
    console.log(this.output, 'raw output');
    this.output = { Result: this.addListItems(this.output) };
    console.log(this.output, 'ov output');
    //
  })
      .catch((error) => {
        // Handle the error here
        console.error(error);
      });
    
  },
  methods: {
    async testRuleExecution() {
      // console.log(this.input['Input variables']);
      //   const id = 'your-id'; // Replace with your actual id
      //  const name = 'your-name'; // Replace with your actual name
      if (this.validation) {
        this.showValidationError = false;
        document.querySelector('.manual-data-content').style.border =
          '1px solid #d8d8d8';
        await DMNAxiosInstance.post(
          `/rule_engine/rule/execute/${this.$route.params.id}`,
          {payload:this.input['Input variables']}
        ).then((response) => {
          this.isExecuted = true;
          if (response.data.ok === 1) {
            this.successful_execution_rate = 'Success';
            this.unsuccessful_execution_rate = 0;

          //   this.boundResult = this.bindValuesToTemplate(
          //   this.output['Result'],
          //   response.data.data
          // );
          // this.boundResult = { Result: this.boundResult };
          // console.log(this.addListItems(response.data.data));
          this.boundResult = { Result: response.data.data };
          this.executionResult = true;
          } else {
            this.successful_execution_rate = 'Failed';
            this.unsuccessful_execution_rate = 100;
            this.boundResult = { Result: response.data.message };
          this.executionResult = true;
          }
          //
        });
        await DMNAxiosInstance.get(
          `/execution_logs/average_duration/${this.$route.params.id}/Rule`
        ).then((response) => {
          const at = response.data.data.averageDuration;
          this.average_time = at.toFixed(2);
        });
      } else {
        this.showValidationError = true;
        document.querySelector('.manual-data-content').style.border =
          '1px solid #fe4141';
      }
    },
    navTo(active) {
      this.$store.commit('token', this.$route.params.token);
      //
      if (active.name == 'Home') {
        this.navHome();
      } else if (active.name == 'logout') {
        this.logout();
      } else if (active.name == 'settings') {
        this.setting();
      } else if (active.name == 'Schema') {
        this.schema();
      } else if (active.name == 'Business rule') {
        this.ruled();
      } else if (active.name === 'Database') {
        this.execute();
      } else if (active.name == 'Help') {
        this.help();
      } else if (active.name == 'Execute') {
        this.exe();
      } else {
        this.audit();
        //
      }
    },
    audit() {
      this.$router.push(`/DMN/audit`);
    },
    help() {
      window.open(`https://documentation.halleyx.com/`);
    },
    ruled() {
      this.$router.push(`/DMN/business_rules`);
    },
    exe() {
      this.$router.push(`/DMN/rule_validation`);
    },
    execute() {
      this.$router.push(`/DMN/reference_data`);
    },
    schema() {
      this.$router.push(`/DMN/schemas`);
    },
    logout() {
      this.$router.push('/');
    },
    setting() {
      this.$router.push(`/settings`);
    },
    async navHome() {
      this.$router.push(`/DMN/dashboard`);
    },
    recievedJson($event) {
      this.add_payload[0].data = $event;
    },
    bindValuesToTemplate(template, payload) {
      console.log(template, payload);
      const bindValues = (template, payload) => {
        for (const key in template) {
          if (Object.prototype.hasOwnProperty.call(template, key)) {
            if (typeof template[key] === 'object') {
              if (Object.prototype.hasOwnProperty.call(payload, key)) {
                bindValues(template[key], payload[key]);
              }
            } else {
              if (Object.prototype.hasOwnProperty.call(payload, key)) {
                template[key] = payload[key];
              }
            }
          }
        }
      };
      const boundTemplate = JSON.parse(JSON.stringify(template));
      bindValues(boundTemplate, payload);
      return boundTemplate;
    },
    addListItems(obj) {
      console.log(obj, 'addListItems');
      for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          if (obj[key].type === 'Array') {
            obj[key].list_items = [];
            const newObj = {};
            newObj.type = 'Object';
            for (const items in obj[key]) {
              // //
              if (
                items !== 'list_items' &&
                items !== 'type' &&
                items !== 'href' &&
                items !== 'ref'
              ) {
                newObj[items] = obj[key][items];
                delete obj[key][items];
              }
            }
            obj[key].list_items.push(newObj);
            // //
          }
          this.addListItems(obj[key]);
        }
      }
      return this.generateJSONFromSchema(obj);
    },
    generateJSONFromSchema(schema) {
      const result = {};
      for (const key in schema) {
        if (Object.hasOwnProperty.call(schema, key)) {
          const value = schema[key];
          const valueType = value.type;
          switch (valueType) {
            case 'String':
              result[key] = '(empty)';
              break;
            case 'Date':
              result[key] = '(empty)';
              break;
            case 'Boolean':
              result[key] = 'false';
              break;
            case 'Number':
              result[key] = 0;
              break;
            case 'Integer':
              result[key] = 0;
              break;
            case 'Object':
              result[key] = this.generateJSONFromSchema(value);
              break;
            case 'Array':
              if (value.list_items) {
                const arrayLength = value.list_items.length;
                const arrayResult = [];
                for (let i = 0; i < arrayLength; i++) {
                  arrayResult.push(
                    this.generateJSONFromSchema(value.list_items[i])
                  );
                }
                result[key] = arrayResult;
              } else {
                result[key] = [];
              }
              break;
            default:
              break;
          }
        }
      }
      //
      return result;
    },
    pathTo() {
      //
      this.$router.back();
    },
    cancelApiAdd() {
      this.add_payload[0] = {
        name: '',
        schemaName: '',
        inputMode: 'manual',
        description: '',
        data: [],
        createdBy: '639af00f9f515f57074584ef',
      };
      this.$router.back();
    },
  },
};
</script>