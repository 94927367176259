<template>
  <!-- notofication -->
  <hlx-alert-notification :notify="notification" :auto-close="true" />


    <!-- Filter drawer -->
    <hlx-drawer
                    :show="show_right_filter"
                    :width="300"
                    position="right"
                    :header="false"
                    :footer="false"
                    :show-close-icon="true"
                    @close="closeRightFilter"
                  >
                    <template #body>
                      <div style="display: flex">
                        <FilterComponent
                        :key="filterkey"
                          :filterpanelname="'customerbill'"
                          :company-name="company"
                          :pre-value="prePreValue"
                          :query-value="query"
                          :display-data="filter"
                          :module="'customerbill'"
                          @filtered-data="quotesfilterData"
                        ></FilterComponent>
                      </div>
                    </template>
                  </hlx-drawer>




  <!-- modal window -->
  <div>
      <hlx-modal
        style="z-index: 999999999"
        :modal-active="delete_modal_active"
        :height="'220px'"
        :width="'400px'"
        :modal="true"
        @close="delete_modal_active = false"
      >
        <template #header>
          <div style="margin-left: 0px">Delete data</div>
        </template>
        <template #content>
          <div class="modal-content">
            <p>
              Are you sure you want to delete <b>{{ deleteobj?.billNo }}</b
              >?
            </p>
          </div>
        </template>
        <template #footer>
          <hlx-button class="secondary sm" @click="delete_modal_active = false"
            >Cancel</hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 16px"
            @click="deleteExecRow"
            >Delete</hlx-button
          >
        </template>
      </hlx-modal>
    </div>


    <!-- page -->
  <div style="display: flex; margin: 20px; flex-direction: column">
    <div style="margin-bottom: 10px"><b>Billing</b></div>
    <span style="font-size: 12px"
      >Easily track your invoices, payment history, and current billing
      status.</span
    >

    <div class="QuoteLPtileDiv">
      <div class="halleyxNeonPArent">
        <!-- {{isClickedIndex}} -->
        <div
          v-for="(item, index) in countsofcard"
          :key="item"
          style="cursor: pointer"
        >
          <!-- 'firstvalueitem': index === 0,'active-divOneForDashboardQuote': index === isClickedIndex, 'active-borderDashboardQuote': isClicked === index  -->
          <div
            :class="[
              'mainpagedivisions',
              { 'firstvalueitem': isClickedIndex.some(item => item?.value === index) },
            ]"
            @click="disableAtClick ? '' : callTileFilter(item, index)"
          >
            <div class="newstatus">{{ item?.state }}</div>
            <div class="newprice">
              <!-- {{'£'}} -->
              <span v-if="item?.price">
                {{ item?.price }}
              </span>
              <span v-else>
                {{ 0 }}
              </span>
              <!-- {{item?.coontractValue}} -->
            </div>
            <div class="newvalue">{{ item?.value }}{{ " Invoice" }}</div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="toolbar"
      style="
        display: flex;
        align-items: center;
        margin-top: 1%;
        justify-content: space-between;
      "
    >
      <span>
        <hlx-switch
          v-if="showSwitch"
          v-model="selectedTab"
          :switch-items="tabData"
          value-prop="name"
          display-prop="name"
        />
      </span>
      <span
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
      <hlx-search
              v-model="searchvalue"
              @search-key="searchKey"
            />
        &nbsp; &nbsp; &nbsp;
        <hlx-button
                      class="secondary sm add-btn"
                      @click="showDrawerRight"
                    >
                      <i class="icon-filter-regular"></i>
                      <span v-if="filtertaglength != 0" class="filter-count">{{
                        filtertaglength
                      }}</span>
                    </hlx-button>
        &nbsp; &nbsp; &nbsp;
        <hlx-button class="primary sm add-btn" @click.stop="callAddInvoice"
          ><i class="icon-plus-circle-regular" style="margin-right: 5px"></i>Add
          Invoice</hlx-button
        >
      </span>
    </div>

    <!-- when it have tabledata -->
    <span v-if="tableData?.length > 0">
      <hlx-table
        :key="refreshTable1"
        :column-count="tableHeads + 2"
        :border="['table', 'header', 'horizontal']"
        :theme="'grey'"
        :bold-headers="false"
        :row-hover="true"
        :striped-rows="false"
        :re-arrange-columns="re_arrange"
        :re-arrange-heads="theads"
        @close-rearrange="arrange"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head
            :width="60"
            :align="'left'"
            style="padding: 0 15px !important; max-width: 60px !important"
            :fixed="'left'"
            >{{ "S.no" }}</hlx-table-head
          >
          <hlx-table-head
            v-for="(i, index) in tableHeads"
            :key="index"
            :prop="i.prop"
            :width="i.width"
            :align="'left'"
            :resizable="true"
          >
            {{ i.label }}
          </hlx-table-head>
          <hlx-table-head :align="'center'" :width="60" :fixed="'right'"
            ><span class="action-icon">
              <i
                class="icon-settings-regular"
                style="cursor: default"
              ></i> </span
          ></hlx-table-head>
        </template>
        <template #tbody>
          <tr v-for="(i, index) in tableDataOnSelectedRows" :key="index">
            <hlx-table-cell :width="60" fixed="left">
              {{ serialNumber(index) }}
            </hlx-table-cell>
            <hlx-table-cell
              v-for="(j, col_index) in tableHeads"
              :key="col_index"
              :align="'left'"
              :width="i.width"
            >
              <span v-if="j.prop === 'state'">
                <!-- {{ i[j.prop] }} -->
                <span v-if="i[j.prop] == 'settled'">
                  <hlx-status
                    :type="'custom'"
                    :point-color="'rgba(2, 181, 92, 1)'"
                    :status-value="statusMapping(i[j.prop])"
                  ></hlx-status>
                </span>
                <span v-else-if="i[j.prop] == 'onHold'">
                  <hlx-status
                    :type="'custom'"
                    :point-color="'rgba(38, 94, 75, 1)'"
                    :status-value="statusMapping(i[j.prop])"
                  ></hlx-status>
                </span>
                <span v-else-if="i[j.prop] == 'partiallyPaid'">
                  <hlx-status
                    :type="'custom'"
                    :point-color="'rgba(249, 166, 69, 1)'"
                    :status-value="statusMapping(i[j.prop])"
                  ></hlx-status>
                </span>
                <span v-else-if="i[j.prop] == 'validated'">
                  <hlx-status
                    :type="'custom'"
                    :point-color="'#663300'"
                    :status-value="statusMapping(i[j.prop])"
                  ></hlx-status>
                </span>
                <span v-else-if="i[j.prop] == 'sent'">
                  <hlx-status
                    :type="'custom'"
                    :point-color="'#E4E456'"
                    :status-value="statusMapping(i[j.prop])"
                  ></hlx-status>
                </span>
                <span v-else>
                  <!-- <hlx-status
                    :point-color="'rgba(249, 166, 69, 1)'"
                    :type="i[j.prop]"
                  ></hlx-status> -->
                </span>
              </span>

              <span v-else-if="j.prop == 'category'">
              <span v-if="i && j.prop">
              {{ leaf(i[j.prop]) }}
              </span>
              </span>
              <span v-else-if="j.prop == 'amountDue' && i?.amountDue?.quoteTotalPrice">
                <!-- {{
                currencyFormatter(i[j.prop].value, {
                  currency: $store?.getters?.getDefaultSettings?.currency?.unit,
                })
                
              }} -->
              {{ currencyFormatter(computePrice(i?.amountDue?.quoteTotalPrice)) }}
            </span>
              <span v-else-if="j.prop == 'nextBillDate'">{{
                dateAndTimeFormatter(i[j.prop])
              }}</span>
              <span v-else-if="j.prop == 'relatedParty'">{{
                roleInvoice(i[j.prop])
              }}</span>
             <!-- <span v-else-if="j.prop == 'category'">
                <span v-if="i[j.prop]">{{ leaf(i[j.prop]) }}</span>
              </span> -->
              <span v-else-if="j.prop == 'displayID'">
                <span>{{ i?.billNo }}</span>
              </span> 
              <span v-else>
                {{ i[j.prop] }}
              </span>
            </hlx-table-cell>
            <hlx-table-cell :align="'center'" :width="120" :fixed="'right'">
              <span :id="'table-context' + index" class="action-icon">
                <i
                  style="position: relative"
                  class="icon-more-vertical-filled"
                  :class="i.context === true ? 'active-action' : ''"
                  @click="crudContextMenu($event, i, index)"
                >
                  <hlx-context-menu
                    :top="containertop"
                    :left="containerleft"
                    :conditionvalue="150"
                    :conditiondown="2"
                    :conditionup="-38"
                    :options="computedTableAction(i)"
                    :data="index.toString()"
                    :show="i.context == true"
                    @chosen="closeCrudContextMenu($event, i, index)"
                  />
                </i>
              </span>
            </hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
      <!-- white space -->
      <span
style="height: 85px;
    display: flex;"></span>
    </span>

    <!-- no tabledata -->
    <span v-if="tableData?.length === 0">
      <hlx-table
        :column-count="tableHeads.length + 2"
        :border="['table', 'header']"
        :bold-headers="false"
        :row-hover="true"
        theme="grey"
        :striped-rows="false"
        :re-arrange-columns="re_arrange"
        :re-arrange-heads="theads"
        @close-rearrange="arrange"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head :width="60" :align="'left'">{{
            "S.no"
          }}</hlx-table-head>
          <hlx-table-head
            v-for="(i, index) in tableHeads"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            style="position: sticky; top: 0px"
            :width="i.width !== undefined ? i.width : ''"
            :align="i.align !== undefined ? i.align : ''"
            @sorting_func="sorting_Data"
          >
            {{ i.label }}
          </hlx-table-head>
          <hlx-table-head v-show="supplierStatus == false" :width="100">
            <span class="action-icon"
              ><i
                class="icon-settings-regular"
                @click="arrange(true)"
              ></i></span
          ></hlx-table-head>
        </template>
        <template #tbody>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell :colspan="tableHeads.length + 2">{{
              "No data"
            }}</hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
      <!-- white space -->
      <span
style="height: 85px;
    display: flex;"></span>
    </span>

<!-- pagination -->
 <span class="newsssssssssssssss"> 
<hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
          :total="totalpageno"
          enable-rows-per-page
          :rows-per-page-list="[10, 15, 20]"
          :options="{
            attributes: {
              rowsPerPageTitle: true,
              rowsPerPageResult: true,
              rowsPerPageDropdown: {
                position: {
                  top: 0,
                  right: 1,
                },
              },
            },
          }"
          @current-page="currentPage"
          @updated:rows-per-page="changeRowsPerPage"
        ></hlx-pagination>
      </span>

  </div>
</template>

<script>
import FilterComponent from "@/components/filterPanelComponent.vue";
import { UPCAxiosInstance } from "@/config/axiosConfig";
export default {
  components: {
    FilterComponent,
  },
  props: {
    module: {
      type: String,
      default: "Billing",
    },
  },
  data() {
    return {
      totalpageno: 0,
      filtertagvalue: [],
      filtertaglength:0,
      company: this.$cookies.get("company"),
      prePreValue: {Status:[]},
      show_right_filter: false,
      query:{
        Status:["state"],
        Account:["relatedParty.name"],
        Contact:["relatedParty.name"]
      },
      filter:{},
      filterkey:0,
      currPage: 1,
      rowsPerPage: 10,
      externalRowsPerPage: 10,
      notification: {},
      deleteobj:null,
      delete_modal_active:false,
      refreshTable1: 0,
      tableDataInit: [],
      searchvalue: "",
      filterQueryforBill: {
        type: "filter",
        module: "customerbill",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {},
        searchQuery: "1",
      },

      isClickedIndex:[{name:"all",value:0}],
      disableAtClick: false,
      countsofcard: [
        { state: "All", price: "£19,830", value: "84" },
        { state: "On Hold", price: "£10,030", value: "54" },
        { state: "Partially Paid", price: "£1,130", value: "10" },
        { state: "Sent", price: "£0", value: "0" },
        { state: "Settled", price: "£0", value: "16" },
        { state: "Validated", price: "£1,530", value: "4" }
      ],

      openNestedRow: false,
      showSwitch: true,
      selectedTab: "Invoices",
      tabData: [
        {
          name: "Invoices",
          value: "Invoices",
        },
        {
          name: "Transactions",
          value: "Transactions",
        },
      ],
      tableHeads: [],
      tableHeads1: [
        {
          name: "ID",
          checked: true,
          id: 1,
          disabled: false,
          prop: "displayID",
          label: "ID",
          sortable: false,
          resizable: false,
          width: "120",
          align: "left",
        },

        {
          name: "Status",
          checked: true,
          id: 1,
          disabled: false,
          prop: "state",
          label: "Status",
          sortable: false,
          resizable: false,
          width: 170,
          align: "left",
        },
        // {
        //   name: "category",
        //   checked: true,
        //   id: 1,
        //   disabled: false,
        //   prop: "category",
        //   label: "Category",
        //   sortable: false,
        //   resizable: false,
        //   width: "150",
        //   align: "left",
        // },
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: false,
          prop: "description",
          label: "Name",
          sortable: false,
          resizable: false,
          width: null,
          align: "left",
        },
        {
          name: "Next bill",
          checked: true,
          id: 1,
          disabled: false,
          prop: "nextBillDate",
          label: "Next bill",
          sortable: false,
          resizable: false,
          width: 250,
          align: "left",
        },
        {
          name: "Price",
          checked: true,
          id: 1,
          disabled: false,
          prop: "amountDue",
          label: "Price",
          sortable: false,
          resizable: false,
          width: 180,
          align: "left",
        },
        // {
        //   name: "Run type",
        //   checked: true,
        //   id: 1,
        //   disabled: false,
        //   prop: "runType",
        //   label: "Run type",
        //   sortable: false,
        //   resizable: false,
        //   width: "130",
        //   align: "left",
        // },
        {
          name: "Contact",
          checked: true,
          id: 1,
          disabled: false,
          prop: "relatedParty",
          label: "Contact",
          sortable: false,
          resizable: false,
          width: 200,
          align: "left",
        },
      ],
      tableData: [],
    };
  },

  
  computed:{
    tableDataOnSelectedRows(){
      // const start = (this.currPage - 1) * this.rowsPerPage;
      // const end = start + this.rowsPerPage;
      return this.tableData;
    }
  },


  watch: {
    selectedTab: {
      handler(val) {
        val;
        this.switchMethod(val); 
      },
      immediate: true,
      deep: true,
    },
  },

  async mounted() {
    this.updateTile();
    await this.$store.dispatch("billFilterData");
    const response = this.$store.getters.billFilterData;
    this.filter = response;
    console.log("11111111111111",response,this.filter);

  },


  methods: {
    
    async searchKey(val) {
      console.log("searchKey",val);
      
      this.searchvalue = val;
      this.filterQueryforBill["company"] = this.$cookies.get("company");
      this.filterQueryforBill["type"] = "search";
      this.filterQueryforBill["searchQuery"] = this.searchvalue;
      this.filterQueryforBill.searchList =
       [
      {
          "billNo": {
            $regex: val,
            $options: "i",
          },
        },
        {
          "state": {
            $regex: val.toLowerCase() === "on hold" ? "onhold" : val,
            $options: "i",
          },
        },
        {
          "nextBillDate": {
            $regex: val,
            $options: "i",
          },
        },
        {
          "description": {
            $regex: val,
            $options: "i",
          },
        },
        {
          "relatedParty.name": {
            $regex: val,
            $options: "i",
          },
        },
        {
          "amountDue.quotePrice.summary.overAllTotal": {
            $regex: val,
            $options: "i",
          },
        },
      ];
      this.currPage = 1;
      this.rowsPerPage = 10;
      if (this.filtertagvalue?.length != 0) {
        this.filterQueryforBill["type"] = "filter-search";
      }

      this.ticketdataCalling(this.currPage, this.rowsPerPage);
    },
    
    
    showDrawerRight() {
      this.show_right_filter = true;
    },
    closeRightFilter() {
      this.show_right_filter = false;
    },

    quotesfilterData(filterSelectedvalue, filterQuery){
  console.log("filter123-------------------- 11111111111",filterSelectedvalue, filterQuery);
   this.filtertaglength = filterSelectedvalue?.length;
   this.filterQueryforBill = filterQuery;
   if(this.filtertaglength >0){
    this.isClickedIndex
    console.log("first",this.filtertaglength,filterSelectedvalue);
    
    if(filterSelectedvalue){
      console.log("second",filterSelectedvalue);
      // const newInput =  filterSelectedvalue;





      let status = ["all", "onHold", "partiallyPaid", "sent", "settled", "validated"];



//       newInput.forEach(item => {
//   let existingItem = this.isClickedIndex.find(s => s.name === item.state);

//   if (existingItem) {
//     // Update existing entry
//     existingItem.value = status.indexOf(item.state);
//   } else {
//     // Add new entry
//     this.isClickedIndex.push({
//       name: item.state,
//       value: status.indexOf(item.state)
//     });
//   }
// });

this.isClickedIndex = this.filtertaglength 
    ? filterSelectedvalue.map(item => ({
        name: item.name,
        value: status.indexOf(item.name)
      }))
    : [{ name: "all", value: 0 }];

  this.filterSelectedvaluedata = filterSelectedvalue;

  // this.quotesfilterDatas();

  if (!Object.keys(filterQuery?.filterQuery || {}).length) {
    this.isClickedIndex = [{ name: "all", value: 0 }];
  }

console.log("final bboth run",this.isClickedIndex);




    }
   }else{
    this.isClickedIndex = [{name:"all",value:0}]
   }
console.log("filter123--------------------",filterSelectedvalue, filterQuery);
////////////////////////////////
if(filterQuery?.filterQuery == {}){filterQuery
  this.isClickedIndex = [{name:"all",value:0}]
}

this.ticketdataCalling(1,10)
},

async ticketdataCalling(currentPage, rowsPerPage) {
  currentPage, rowsPerPage
  console.log("ticketdataCalling...123",currentPage, rowsPerPage);
      this.filterQueryforBill["company"] = this.$cookies.get('company');
      this.filterQueryforBill["paginatedQuery"]["currentPage"] = currentPage;
      this.filterQueryforBill["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
      this.filterQueryforBill.module = 'customerbill';
      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.filterQueryforBill
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data?.total;
        console.log("ticketdataCalling...",this.tableData ,filterResultData.data.data);
        
        // this.totalvalue = filterResultData.data.total;      
      }
      // this.refreshTable += 1;
},


    formatCurrentTime() {
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0');
    const day = String(now.getUTCDate()).padStart(2, '0');
    const hours = String(now.getUTCHours()).padStart(2, '0');
    const minutes = String(now.getUTCMinutes()).padStart(2, '0');
    const seconds = String(now.getUTCSeconds()).padStart(2, '0');
    const milliseconds = String(now.getUTCMilliseconds()).padStart(3, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
},

    statusMapping(status) {
    switch (status) {
      case 'settled':
        return 'Settled';
      case 'partiallyPaid':
        return 'Partially paid';
      case 'sent':
        return 'Sent';
      case 'validated':
        return 'Validated';
      case 'onHold':
        return 'On hold';
      default:
        return status; // in case the status doesn't match
    }
  },
    
    computePrice(totalPrices) {
      console.log("🚀 ~ computePrice ~ totalPrices:", totalPrices);
      if (!totalPrices) return [];
      // Find totalContractAmount
      let totalContractValue = totalPrices.find(
        (price) =>
          price && price["@type"] && price["@type"] == "totalContractValue"
      );
      if (totalContractValue) {
        return totalContractValue.price.value
          ? totalContractValue.price.value
          : 0;
      }
      let prices = totalPrices.map((price) => {
        console.log("🚀 ~ prices ~ price:", price);
        if (
          price &&
          price.price.dutyFreeAmount &&
          price.price.dutyFreeAmount.value &&
          typeof price.price.dutyFreeAmount.value == "string" &&
          ![0, 0.0, "0", "0.00", "NaN"].includes(
            price.price.dutyFreeAmount.value?.trim()
          )
        ) {
          return parseFloat(price.price.dutyFreeAmount.value?.trim());
        } else if (
          price &&
          price.price.dutyFreeAmount &&
          price.price.dutyFreeAmount.value &&
          typeof price.price.dutyFreeAmount.value == "number" &&
          ![0, 0.0, "0", "0.00", "NaN", NaN].includes(
            price.price.dutyFreeAmount.value
          )
        ) {
          return price.price.dutyFreeAmount.value;
        }
        return 0;
      });
      console.log("🚀 ~ computePrice ~ prices:", prices);
      // console.log("🚀 ~ computePrice ~ prices:", prices)
      let finalPrice = prices.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0);
      console.log("🚀 ~ finalPrice ~ finalPrice:", finalPrice);
      return finalPrice;
    },

    async changeRowsPerPage(count) {
      //
      if (this.companyasignee != "") {
        this.rowsPerPage = count;
        this.ticketdataCalling(
          this.currPage,
        this.rowsPerPage
        );
        this.refreshTable();
      }
    },
    async currentPage(val) {
      if (this.companyasignee != "") {
        this.currPage = val;
        this.ticketdataCalling(
          this.currPage,
        this.rowsPerPage
        );
        this.refreshTable();
      }
    },

    
    async refreshTable() {
      this.filterQueryforBill.module = 'customerbill'
      const response = await UPCAxiosInstance.post(
        `util/filterData`,
        this.filterQueryforBill
      );
      // const response = await UPCAxiosInstance.get(`/templateTicket`);

      this.tableData = response.data.data;
      this.totalpageno = response.data?.total;
      this.totalvalue = response.data.total;
    },
    



    async cloneInvoice(val){


      
      // Generate quote number
      const quoteIDRes = await UPCAxiosInstance.post("/genericID", {
        module: "product",
        subModule: "invoice",
        prefix: "INV",
        // isBasedOnCollection: true,
        // collection: "customerbill",
      });
      if (quoteIDRes.data && quoteIDRes.data.ok && quoteIDRes.data.ID) {
        val.billNo =
          quoteIDRes.data.ID;
      }
   




      val.id = "";
      val._id = "";
      // val.state = "onHold";
      val.description = val.description + "_copy";
      val.lastUpdate = `${this.formatCurrentTime()}`;
      val.createdAt = `${this.formatCurrentTime()}`;
      val.updatedAt = `${this.formatCurrentTime()}`;
      const response  = await UPCAxiosInstance.post(`customerBill`,val);
      if(response){
        this.notification = {
            type: "success",
            message: `Nice work! Your Invoice ${val.billNo} is cloned!`,
          };
      }
      this.switchMethod('Invoices');
      this.updateTile();
    },


    switchMethod(val){
      val;
      this.refreshTable1 += 1;
        this.tableHeads = [];
        this.tableData = [];
        if (val === "Invoices") {
          this.loadInvoiceData();
          this.tableHeads = JSON.parse(JSON.stringify(this.tableHeads1));
        } else if (val === "Transactions") {
          this.tableHeads = [
            {
              label: "Transaction date",
              prop: "Transaction date",
              width: 300,
            },
            {
              label: "Account name",
              prop: "Account name",
              width: 250,
            },
            {
              label: "Type",
              prop: "Type",
              width: 250,
            },
            {
              label: "Status",
              prop: "Status",
              width: 250,
            },
            {
              label: "Price (GBP)",
              prop: "Price (GBP)",
              width: null,
            },
          ];

          this.tableData = [
            {
              "S no.": 1,
              "Transaction date": "Apr 20, 2024",
              Type: "Debit",
              Status: "Paid",
              "Price (GBP)": "£27.99",
              "Account name": "Shoppers Drugmart",
            },
            {
              "S no.": 2,
              "Transaction date": "Mar 20, 2024",
              Type: "Debit",
              Status: "Paid",
              "Price (GBP)": "£27.99",
              "Account name": "Shoppers Drugmart",
            },
            {
              "S no.": 3,
              "Transaction date": "Feb 20, 2024",
              Type: "Debit",
              Status: "Paid",
              "Price (GBP)": "£27.99",
              "Account name": "Shoppers Drugmart",
            },
            {
              "S no.": 4,
              "Transaction date": "Jan 20, 2024",
              Type: "Debit",
              Status: "Paid",
              "Price (GBP)": "£27.99",
              "Account name": "George Weston Limited",
            },
            {
              "S no.": 5,
              "Transaction date": "Dec 20, 2023",
              Type: "Debit",
              Status: "Paid",
              "Price (GBP)": "£27.99",
              "Account name": "George Weston Limited",
            },
            {
              "S no.": 6,
              "Transaction date": "Nov 20, 2023",
              Type: "Debit",
              Status: "Paid",
              "Price (GBP)": "£27.99",
              "Account name": "George Weston Limited",
            },
          ];
        }
    },


    async deleteExecRow(){
      this.delete_modal_active = false;
        const response  = await UPCAxiosInstance.delete(`customerBill/${this.deleteobj?.id}`)
        if (response) {
          this.notification = {
        type: "success",
        message:
          'Invoice "' + this.deleteobj?.billNo + '" deleted successfully',
      };
        }
        this.switchMethod('Invoices');
        this.updateTile();
    },


    async updateTile() {
  try {
    // Fetching the customer bill status
    const response = await UPCAxiosInstance.get("customerBill/customerBillStatus");

    // Accessing response data
    const data = response.data;

    // Validate if the response data is an array and contains the expected data
    if (!Array.isArray(data) || data.length === 0) {
      throw new Error("Invalid or empty response data");
    }

    const totalCount = data.reduce((sum, item) => sum + item.count, 0);
    // Define the mapping with all prices set to £0 and state counts based on the response data
    this.countsofcard = [
      { state: "All", price: "£0", value: totalCount }, // Predefined value for "All"
      {
        state: "On Hold",
        price: "£0",
        value: data.find((item) => item.state === "onHold")?.count || 0,
      },
      {
        state: "Validated",
        price: "£0",
        value: data.find((item) => item.state === "validated")?.count || 0,
      },
      {
        state: "Settled",
        price: "£0",
        value: data.find((item) => item.state === "settled")?.count || 0,
      },
      {
        state: "Partially Paid",
        price: "£0",
        value: data.find((item) => item.state === "partiallyPaid")?.count || 0,
      },
      {
        state: "Sent",
        price: "£0",
        value: data.find((item) => item.state === "sent")?.count || 0,
      },
    ];
    this.countsofcard.sort((a, b) => a.state.localeCompare(b.state));

    console.log("this.countsofcard...............",this.countsofcard);
  } catch (error) {
    // Handle network and API errors
    if (error.response) {
      // Server responded with a status outside of 2xx
      console.error("API Error:", error.response.data.message || error.response.statusText);
    } else if (error.request) {
      // Request made but no response received
      console.error("No response from server:", error.request);
    } else {
      // Other errors like invalid data structure or JavaScript issues
      console.error("Error:", error.message);
    }
  }
},

    async closeCrudContextMenu($event, payload, i) {
      if ($event.toLowerCase() === "edit") {
        $event, payload, i;
      //   try{
      //     let response = await UPCAxiosInstance.get(
      //   `/customerBill/${payload?._id}`
      // ); 
      // response
      //   }catch(error){
      //     console.error("error at clicking edit:",error)
      //   }
      this.$router.push({
          name: "invoiceTemplate",
          query: { quote_id: payload?._id, mode: "edit" },
        });
      } else if ($event.toLowerCase() === "delete") {
        $event, payload, i;
        this.deleteobj = payload;
        this.delete_modal_active = true;
      } else if ($event.toLowerCase() === "clone") {
        $event, payload, i;
        this.cloneInvoice(payload);
      } else if ($event.toLowerCase() === "preview") {
        $event, payload, i;
        this.$router.push({
            name: "previewInvoicepage",
            query: {
              quote_id: payload?._id,
            },
          });
      }
    },

    pricingSearchTrigger(val) {
      this.searchvalue = val;
      console.log("onsearch value", val);
      if (val) {
        const result = this.searchInArray(val, this.tableDataInit);
        console.log("🚀 ~ AVregSearchTrigger ~ result:", result);
        this.tableData = result;
      } else {
        console.log("🚀 ~ mediaSearchTrigger ~ this.search:");
        this.tableData = this.tableDataInit;
      }
    },

    searchInArray(searchTerm, dataArray) {
      console.log("searchTerm is empty before", searchTerm, dataArray);
      if (searchTerm) {
        const searchTermLower = searchTerm?.toLowerCase();

        const result = dataArray.filter((obj) => {
          return Object.values(obj).some((value) => {
            if (typeof value === "string") {
              return value?.toLowerCase().includes(searchTermLower);
            }
            return false;
          });
        });

        return result;
      } else {
        console.log("searchTerm is empty", dataArray);
        return dataArray;
      }
    },

    callAddInvoice() {
      this.$router.push(`/invoice/default`);
    },


//     callTileFilter(item, index) {
//   const status = ["all", "onHold", "validated", "settled", "partiallyPaid", "sent"];

//   console.log("Initial isClickedIndex:", JSON.stringify(this.isClickedIndex));

//   if (index === 0) {
//     // Reset to "all" filter if index is 0
//     this.isClickedIndex = [{ name: "all", value: 0 }];
//     this.filtertaglength = 0;
//     this.filterQueryforBill = {
//       type: "filter",
//       module: "customerbill",
//       isPaginated: true,
//       paginatedQuery: { rowsPerPage: 10, currentPage: 1 },
//       isNeedToBeSort: true,
//       sortFieldAndOrder: {},
//       limit: 10,
//       filterQuery: {},
//       searchQuery: "",
//       company: "developerHalleyx"
//     };
//     this.prePreValue = { Status: [] };
//     this.filterkey += 1;

//     setTimeout(() => { this.clearFilters = true; }, 500);
//     this.clearFilters = false;
//   } else {
//     // Remove "all" if another filter is selected
//     this.isClickedIndex = this.isClickedIndex.filter(i => i.value !== 0);
//     console.log("After removing 'all':", JSON.stringify(this.isClickedIndex), index);

//     const exists = this.isClickedIndex.some(i => i.value === index);
//     console.log("exists...", exists, this.isClickedIndex);
    
//     if (exists) {
//       // If the filter exists, remove it
//       this.isClickedIndex = this.isClickedIndex.filter(i => i.value !== index);
//     } else {
//       // Verify the status and index before adding a new filter
//       const name = status[index];
//       if (name && index >= 0) {
//         this.isClickedIndex.push({ name: name, value: index });
//       } else {
//         console.error("Invalid status or index:", name, index);
//       }
//     }
//   }

//   console.log("Final isClickedIndex:", JSON.stringify(this.isClickedIndex));

//   const filterData = this.callFilter(this.isClickedIndex);
//   if (JSON.stringify(this.filter) !== JSON.stringify(filterData)) {
//     this.prePreValue = { ...filterData };
//   }
// }


    // ,


    callTileFilter(item, index){
      // Define the status array
  let status = ["all", "onHold", "partiallyPaid", "sent", "settled", "validated"];status
  
  // If the index is 0, set isClickedIndex to 'all'
  if (index === 0) {
    this.isClickedIndex = [{ name: "all", value: 0 }];
    this.filtertaglength = 0;
    this.filterQueryforBill = {
      type: "filter",
      module: "customerbill",
      isPaginated: true,
      paginatedQuery: { rowsPerPage: 10, currentPage: 1 },
      isNeedToBeSort: true,
      sortFieldAndOrder: {},
      limit: 10,
      filterQuery: {},
      searchQuery: "",
      company: "developerHalleyx"
    };
    this.prePreValue = { Status: [] };
    this.ticketdataCalling(1,10)
    this.filtertaglength = 0;
    setTimeout(() => {
      this.clearFilters = true;
    }, 500);
    this.clearFilters = false;
    this.filterkey += 1;
  } else {
    if (index !== 0) {
  this.isClickedIndex = this.isClickedIndex.filter(i => i.value !== 0);
}

console.log("After removing 'all' if needed:", this.isClickedIndex,index);

// Check if the item with the current index exists
const exists = this.isClickedIndex.some(i => i.value === index);
console.log("exists.......", exists, this.isClickedIndex);

if (exists) {
  // If it exists, remove the item with the matching index value
  this.isClickedIndex = this.isClickedIndex.filter(i => i.value !== index);
} else {
  // If it does not exist, add a new item with name and value properties
  this.isClickedIndex.push({ 
    name: status[index], 
    value: index 
  });
}

//     this.isClickedIndex = this.isClickedIndex.filter(i => i.value !== 0);

//     console.log("else.......",this.isClickedIndex);
    
//     const exists = this.isClickedIndex.some(i => i.value === index);
    
//     console.log("exists.......",exists,this.isClickedIndex);

//     if (exists) {
//   // If it exists, remove the item with the matching index value
//   this.isClickedIndex = this.isClickedIndex.filter(i => i.value !== index);
// } else {
//   // If it does not exist, add a new item with name and value properties
//   this.isClickedIndex.push({ 
//     name: status[index], 
//     value: index 
//   });
// }

      console.log("after...",this.isClickedIndex)
    if (!this.isClickedIndex.length){
      this.filterQueryforBill = {
      type: "filter",
      module: "customerbill",
      isPaginated: true,
      paginatedQuery: { rowsPerPage: 10, currentPage: 1 },
      isNeedToBeSort: true,
      sortFieldAndOrder: {},
      limit: 10,
      filterQuery: {},
      searchQuery: "",
      company: "developerHalleyx"
    };
      this.prePreValue = { Status: [] };
      this.filtertaglength = 0;
      this.isClickedIndex = [{ name: "all", value: 0 }];
      this.ticketdataCalling(1,10)
      setTimeout(() => {
      this.clearFilters = true;
    }, 500);
    this.clearFilters = false;
      this.filterkey += 1;
    } 
  }
    //call filter
    const filterData = this.callFilter(this.isClickedIndex);
  if (JSON.stringify(this.filter) !== JSON.stringify(filterData)) {
    this.prePreValue = { ...filterData };
    console.log("send prop", filterData);
  } else {
    console.log("don't send prop");
  }
    console.log("this.prePreValue......",this.prePreValue,this.isClickedIndex);
    },



// Function to filter preValue based on val
callFilter(clickedIndex) {
    const filteredStatus = this.filter.Status
        .filter(status => clickedIndex.some(index => index.name === status.name))
        .map(status => ({
            ...status,
            checked: true,
            key: 'Status'
        }));

    return {
        Status: filteredStatus
    };
},




    async callTileFilter1(item, index) {
      this.selectedTab = "Invoices";
      if (item && item?.state) {
        console.log("billing123... @callTileFilter", item, index);

        //highlight tile
        this.isClickedIndex = index;

        //filter
        if (item?.state === "All") {
  this.filterQueryforBill.filterQuery = {}; // Set to an empty object
} else {
  this.filterQueryforBill.filterQuery = {
    state: { $in: [item?.state.replace(/\s+/g, '')] },
  }; // Normal execution for other states
}
        try {
          const response = await UPCAxiosInstance.post(
            "util/filterData",
            this.filterQueryforBill
          );
          this.tableData = JSON.parse(JSON.stringify(response?.data?.data)).reverse();
          this.totalpageno = response.data?.total;
          this.tableDataInit = JSON.parse(JSON.stringify(response?.data?.data)).reverse();
          console.log(
            "billing123 tableData",
            response,
            this.filterQueryforBill
          );
        } catch (error) {
          console.error("error in callTileFilter function:", error);
        }
      }
    },

    roleInvoice(data) {
      const buyerContact = data.find((party) => party.role === "Buyer contact");

      const buyerName = buyerContact ? buyerContact.name : null;
      return buyerName;
    },
    leaf(text) {
      let lengthOfText = text?.length;
      if (typeof text === "string") {
        return text;
      }
      if (lengthOfText === 1) {
        console.log("leaf category text length final o/p ", text);
        let string = text.toString();
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
      if (text && lengthOfText > 1) {
        const total = text?.length;
        console.log(
          text,
          "leaf category text length final o/p",
          text[total - 1]
        );
        let string = text[total - 1];
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    },
    async loadInvoiceData() {
      this.selectedTab = "Invoices";
      let FilterOption = {
        type: "filter",
        module: "customerbill",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {},
        searchQuery: "",
        company: "developerHalleyx",
      };
      FilterOption
      // const response = await UPCAxiosInstance.get(
      //   `customerBill`
      // );
      // console.log("wadwadd",response);
      
      // this.tableData = JSON.parse(JSON.stringify(response?.data));
      // this.countsofcard[0].value = this.tableData.length
      // this.tableDataInit = JSON.parse(JSON.stringify(response?.data));


      this.ticketdataCalling(this.currPage, this.rowsPerPage);
    },

    crudContextMenu($event, i, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 102;

        this.containertop =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().top + 10;  
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },

    computedTableAction() {
      return [
        {
          label: "Edit",
          icon: "icon-file-text-edit-regular",
        },
        {
          label: "Clone",
          icon: "icon-copy-alt-regular",
        },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
        {
          label: "Preview",
          icon: "icon-caret-left-circle-regular",
        },
      ];
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
  },
};
</script>
<style lang="scss">
.newsssssssssssssss{
  position: fixed;
    bottom: 25px;
    left: 93px;
    display: flex;
    width: calc(100% - 100px);
    margin: 0px 0px 0px 0px;
    background: white;
    z-index: 339;
    height: 50px;
}
</style>