<template>
  <div class="app-layout">
    <section class="right-panel">
      <div class="layout-basic">
        <div class="layout-breadcrumb">
          <hlx-breadcrumb
            :items="items"
            @path="pathTo"
          />
        </div>

        <div class="layout-header"><p>Schema</p></div>

        <div class="layout-body">
          <div class="grid">
            <span class="grid-mongodb-schema-left">
              <span class="grid-mongodb-schema-left-title"
                >Source of reference</span
              >
              <span class="grid-mongodb-schema-left-sub-title"
                >Schema is the overall description of the database</span
              >
              <span>Reference data</span>
            </span>

            <span class="grid-mongodb-schema-right">
              <div class="grid-mongodb-schema-right-container">
                <span
                  class="grid-mongodb-schema-right-container-input-connection-string"
                >
                  <hlx-input
                    :label-animation="true"
                    label-value="Connection string"
                    type="text"
                    :clearable="true"
                  />
                </span>
                <span class="grid-mongodb-schema-right-container-tab">
                  <hlx-switch
                    :switch-items="tab_switch_items"
                    :type="'tab'"
                    :position="'bottom'"
                    @chosen="tabswitch"
                  />
                </span>
                <span
                  v-if="authentication === true"
                  class="grid-mongodb-schema-right-container-switch"
                >
                  <hlx-switch
                    :switch-items="normal_switch_items"
                    :type="'regular'"
                    :position="'bottom'"
                    @chosen="normalswitch"
                  />
                </span>
                <span
                  v-if="unpwd === true && authentication === true"
                  class="grid-mongodb-schema-right-container-switch-un-pwd"
                >
                  <span>
                    <hlx-input
                      :label-animation="true"
                      label-value="Username"
                      type="text"
                      :clearable="true"
                    />
                  </span>
                  <span>
                    <hlx-input
                      :label-animation="true"
                      type="confirmpassword"
                      :password-condition="true"
                      label-value="Confirm password"
                      :clearable="false"
                    />
                  </span>
                </span>
                <span
                  v-if="aws === true && authentication === true"
                  class="grid-mongodb-schema-right-container-switch-aws"
                >
                  <hlx-input
                    :label-animation="true"
                    label-value="AWS access Key ID"
                    type="text"
                    :clearable="true"
                  />
                </span>
                <span
                  v-if="kerberos === true && authentication === true"
                  class="grid-mongodb-schema-right-container-switch-kerberos"
                >
                  <hlx-input
                    :label-animation="true"
                    label-value="Pricipal"
                    type="text"
                    :clearable="true"
                  />
                  <p>Canonicalize host name</p>
                  <hlx-input
                    :options="radio"
                    :type="'radio-buttons'"
                    :position="'horizontal'"
                  />
                </span>
                <span
                  v-if="tls === true"
                  class="grid-mongodb-schema-right-container-tab-tls"
                >
                  <span
                    class="grid-mongodb-schema-right-container-tab-tls-tab-switch"
                  >
                    <hlx-switch
                      :switch-items="onoffitems"
                      :type="'regular'"
                      :position="'bottom'"
                      @chosen="onoffswitch"
                    />
                  </span>
                  <span
                    v-for="(i, index) in tlsdata"
                    :key="index"
                    class="grid-mongodb-schema-right-container-tab-tls-left"
                  >
                    <div
                      class="grid-mongodb-schema-right-container-tab-tls-left-checkbox"
                    >
                      <input
                        v-model="agreement"
                        type="checkbox"
                        name="agreement"
                      />
                    </div>
                    <div
                      class="grid-mongodb-schema-right-container-tab-tls-right"
                    >
                      <p
                        class="grid-mongodb-schema-right-container-tab-tls-right-title"
                      >
                        {{ i.title }}
                      </p>
                      <p
                        class="grid-mongodb-schema-right-container-tab-tls-right-sub-title"
                      >
                        {{ i.subtitle }}
                      </p>
                    </div>
                  </span>
                </span>
                <span
                  v-if="proxy === true"
                  class="grid-mongodb-schema-right-container-tab-proxy"
                >
                  <span
                    class="grid-mongodb-schema-right-container-tab-proxy-tab-switch"
                  >
                    <hlx-switch
                      :switch-items="sshwithpassword"
                      :type="'regular'"
                      :position="'bottom'"
                      @chosen="ssh"
                    />
                  </span>
                  <span
                    class="grid-mongodb-schema-right-container-tab-proxy-host"
                  >
                    <span
                      class="grid-mongodb-schema-right-container-tab-proxy-host-input"
                    >
                      <hlx-input
                        :label-animation="true"
                        label_value="SSH Hostname"
                        type="text"
                        :clearable="false"
                      />
                    </span>
                    <hlx-input
                      :label-animation="true"
                      label-value="SSH Port"
                      type="text"
                      :clearable="false"
                    />
                  </span>
                  <span
                    class="grid-mongodb-schema-right-container-tab-proxy-host"
                  >
                    <span
                      class="grid-mongodb-schema-right-container-tab-proxy-host-input"
                    >
                      <hlx-input
                        :label-animation="true"
                        label-value="SSH Username"
                        type="text"
                        :clearable="true"
                      />
                    </span>
                    <hlx-input
                      :label-animation="true"
                      label-value="SSH Password"
                      type="text"
                      :clearable="true"
                    />
                  </span>
                </span>
              </div>
            </span>
          </div>
        </div>

        <div class="layout-footer">
          <span class="layout-footer-local-add">
            <hlx-button class="secondary-success sm cancel-button"
              >Cancel</hlx-button
            >
            <hlx-button class="primary sm">Connect</hlx-button>
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'MongoView',

  data() {
    return {
      tlsdata: [
        {
          title: 'tlsInsecure',
          subtitle:
            'This includes tlsAllowInvalidHostnames and tlsAllowInvalidCertificates',
        },
        {
          title: 'tlsAllowInvalidHostnames',
          subtitle:
            'Disable the validation of the hostnames in the certificate presented by the mongod/mongos instance',
        },
        {
          title: 'tlsAllowInvalidCertificates',
          subtitle: 'Disable the validation of the server certificates',
        },
      ],
      radio: [
        { name: 'None', checked: false, disabled: false, id: 1 },
        { name: 'Forward', checked: false, disabled: false, id: 2 },
        { name: 'Forward and reverse', checked: false, disabled: false, id: 3 },
      ],
      onoffitems: [
        { name: 'On', checked: true },
        { name: 'Off', checked: false },
      ],
      sshwithpassword: [
        { name: 'SSH with password', checked: true },
        { name: 'None', checked: false },
      ],

      tls: false,
      proxy: false,
      authentication: true,
      unpwd: true,
      aws: false,
      kerberos: false,
      items: [{ label: 'Schemas' }, { label: 'Add schema' }],
      normal_switch_items: [
        { name: 'Un/Pwd', checked: true },
        { name: 'AWS/IAM' },
        { name: 'Kerberos' },
      ],
      tab_switch_items: [
        { name: 'Authentication', checked: true },
        { name: 'TLS/SSL' },
        { name: 'Proxy/SSH' },
      ],
    };
  },
  mounted() {
    this.$store.commit('token', this.$route.params.token);
  },
  methods: {
    pathTo() {
      
 
        this.$router.push(`/DMN/schemas`);

        // this.$router.push({ path: `/DMN/schemas` })
        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/schemas `;
      
    },
    normalswitch(val) {
      
      if (val === 'AWS/IAM') {
        this.unpwd = false;
        this.kerberos = false;
        this.aws = true;
      } else if (val === 'Kerberos') {
        this.unpwd = false;
        this.aws = false;
        this.kerberos = true;
      } else if (val === 'Un/Pwd') {
        this.kerberos = false;
        this.aws = false;
        this.unpwd = true;
      }
    },
    tabswitch(val) {
      if (val === 'TLS/SSL') {
        this.authentication = false;
        this.proxy = false;
        this.tls = true;
      } else if (val === 'Proxy/SSH') {
        this.authentication = false;
        this.tls = false;
        this.proxy = true;
      } else if (val === 'Authentication') {
        this.proxy = false;
        this.tls = false;
        this.authentication = true;
        this.unpwd = true;
      }
    },
  },
};
</script>

<style></style>
