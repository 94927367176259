<template>
  <div v-show="impersonite == true"><banner @exit="exitView()" /></div>

  <div class="api-usage">
    <section class="right-view">
      <div class="breadcrumb">
        <hlx-breadcrumb
          :seperator="'/'"
          :crumbs="crumbs"
          @selected="selected"
        />
      </div>
      <div class="title-container">
        <p class="header"><b>API usage</b></p>
      </div>
      <div class="main-container">
        <section class="zone-1">
          <div class="card-container">
            <div class="zones">
              <section class="one">
                <p class="header"><b>API calls</b></p>
                <p class="sub-header">Number of API calls in last 24 hrs</p>
              </section>
              <section class="two"><b>42</b></section>
            </div>
            <div class="zones">
              <section class="one">
                <p class="header"><b>Error rate</b></p>
                <p class="sub-header">Percentage of errors in last 24 hrs</p>
              </section>
              <section class="two"><b>4.8%</b></section>
            </div>
            <div class="zones">
              <section class="one">
                <p class="header"><b>Latency</b></p>
                <p class="sub-header">Average latency in last 24 hrs</p>
              </section>
              <section class="two"><b>340.48 ms</b></section>
            </div>
          </div>
          <div class="chart-container">
            <p class="title"><b>Latency</b></p>
            <VueApexChart
              :type="linechart.chart.type"
              height="100%"
              width="100%"
              :options="linechart"
              :series="linechart.series"
            />
          </div>
        </section>
        <section class="zone-2">
          <div class="title">
            <p><b>API usage audit</b></p>
          </div>
          <div class="content">
            <div>
              <hlx-search
                :search-style="'style1'"
                @search-key="search"
              />
            </div>
            <div>
              <hlx-button class="secondary sm"
                ><i class="icon-filter" />&nbsp;&nbsp;Filter</hlx-button
              >
            </div>
            <div>
              <hlx-button class="primary sm"
                ><i class="icon-download" />&nbsp;&nbsp;Download</hlx-button
              >
            </div>
          </div>
        </section>
        <section class="zone-3">
          <hlx-table
            :theads="theads"
            :border="['table', 'header', 'vertical', 'horizontal']"
            :bold-headers="false"
            :row-hover="false"
            theme="grey"
            :striped-rows="false"
            :height="310"
          >
            <template #thead>
              <hlx-table-head
                v-for="(i, index) in headvalue"
                :key="index"
                :sortable="i.sortable"
                :resizable="i.resizable"
                @sorting_func="sorting_Data"
                >{{ i.label }}</hlx-table-head
              >
            </template>
            <template #tbody>
              <tr
                v-for="(i, index) in paginatedData"
                id=""
                :key="index"
              >
                <hlx-table-cell
                  v-for="(j, col_index) in headvalue"
                  :key="col_index"
                >
                  <div>
                    {{ i[j.prop] }}
                  </div>
                </hlx-table-cell>
              </tr>
            </template>
          </hlx-table>

          <div class="pagination-container">
            <span
              >Showing {{ showingFrom }} - {{ showingTo }} of
              {{ totalRows }} rows</span
            >
            <hlx-pagination
            v-model:rows-per-page="externalRowsPerPage"
              :total="tableData.length"
              rows-per-page
              enable-rows-per-page
              :rows-per-page-list="[5, 10, 15]"
              @current-page="currentPage"
              @updated:rows-per-page="changeRowsPerPage"
            ></hlx-pagination>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import VueApexChart from 'vue3-apexcharts';
import axios from 'axios';
import banner from '../components/bannerComponent.vue';

export default {
  name: 'ApiView',
  components: { VueApexChart, banner },
  data() {
    return {
      externalRowsPerPage:10,
      impersonitor: '',
      fet: process.env.VUE_APP_HOST_PROTOCOL + '://' + process.env.VUE_APP_HOST,
      impersonite: false,
      theads: [
        { name: 'Time', checked: true, id: 1 },
        { name: 'API name', checked: true, id: 2 },
        { name: 'Total API calls', checked: true, id: 3 },
        { name: 'Total errors', checked: true, id: 4 },
        { name: 'Total successful', checked: true, id: 5 },
        { name: 'Average latency', checked: true, id: 6 },
        { name: 'Average received', checked: true, id: 7 },
        { name: 'Average sent', checked: true, id: 8 },
      ],
      headvalue: [
        { prop: 'Time', label: 'Time', sortable: false, resizable: true },
        {
          prop: 'API_name',
          label: 'API name',
          sortable: false,
          resizable: true,
        },
        {
          prop: 'Total_API_calls',
          label: 'Total API calls',
          sortable: false,
          resizable: true,
        },
        {
          prop: 'Total_errors',
          label: 'Total errors',
          sortable: false,
          resizable: true,
        },
        {
          prop: 'Total_successful',
          label: 'Total successful',
          sortable: false,
          resizable: true,
        },
        {
          prop: 'Average_latency',
          label: 'Average latency(ms)',
          sortable: false,
          resizable: true,
        },
        {
          prop: 'Average_received',
          label: 'Average received',
          sortable: false,
          resizable: true,
        },
        {
          prop: 'Average_sent',
          label: 'Average sent(B)',
          sortable: false,
          resizable: true,
        },
      ],
      tableData: [
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '8.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 1,
          Total_successful: 0,
          Average_latency: 400,
          Average_received: 1,
          Average_sent: 200,
        },
        {
          Time: '10.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '11.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 500,
          Average_received: 4,
          Average_sent: 300,
        },
        {
          Time: '9.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 800,
          Average_received: 3,
          Average_sent: 200,
        },
        {
          Time: '11.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '10.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 200,
          Average_received: 5,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
      ],
      sortData: [
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '8.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 1,
          Total_successful: 0,
          Average_latency: 400,
          Average_received: 1,
          Average_sent: 200,
        },
        {
          Time: '10.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '11.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 500,
          Average_received: 4,
          Average_sent: 300,
        },
        {
          Time: '9.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 800,
          Average_received: 3,
          Average_sent: 200,
        },
        {
          Time: '11.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '10.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 200,
          Average_received: 5,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
        {
          Time: '5.00 AM',
          API_name: '/server/userdata',
          Total_API_calls: 'Success',
          Total_errors: 0,
          Total_successful: 1,
          Average_latency: 600,
          Average_received: 2,
          Average_sent: 100,
        },
      ],
      currPage: 1,
      rowsPerPage: 5,
      options: [
        {
          label: 'Add',
          icon: 'icon-add',
        },
        {
          label: 'View',
          icon: 'icon-eye',
        },
        {
          label: 'Edit',
          icon: 'icon-edit-regular',
        },
        {
          label: 'Delete',
          icon: 'icon-trash-regular',
        },
      ],
      crumbs: [
        { name: 'Settings', icon: '' },
        { name: 'API usage', icon: '' },
      ],
      linechart: {
        grid: {
          borderColor: 'white',
          padding: {
            top: -20,
          },
        },
        series: [
          {
            name: 'series1',
            data: [31, 40, 28, 51, 42, 109, 100],
          },
        ],

        chart: {
          height: '70%',
          width: '100%',
          type: 'area',
          toolbar: {
            show: false,
          },
        },
        colors: ['#FF9898'],
        dataLabels: {
          enabled: false,
        },
        fill: {
          opacity: 0,
        },
        stroke: {
          curve: 'straight',
          width: 2,
        },

        xaxis: {
          lines: {
            show: false,
          },
          show: false,
          type: 'datetime',
          categories: [
            '2018-09-16',
            '2018-09-17',
            '2018-09-18',
            '2018-09-19',
            '2018-09-20',
            '2018-09-21',
            '2018-09-22',
          ],
        },
        yaxis: {
          borderColor: '#ffffff',
          show: false,
        },
      },
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.tableData.length;
    },
  },
  methods: {
    async exitView() {
      
      let update = {
        id: this.userId,
        impersonation: false,
        impersinater: '',
      };
      // const result = 
      await axios.patch(
        this.fet + `/partymanagement/individual`,
        update
      );

      
      let token = {
        token: this.impersonitor,
      };
      axios
        .post(this.fet + `/partymanagement/individual/auth`, token)
        .then((val) => {
          
          let company = val.data.company;
          if (company != '') {
            // let c_name = null;
            // if (company == 'Halleyx') {
            //   c_name = '';
            // } else {
            //   c_name = company + '.';
              
            // }
            // location.replace(window.location.protocol+"//"+`${c_name}`+window.location.hostname+':'+window.location.port +`/DMN/dashboard/${this.impersonitor}`)

            window.location.href =
              window.location.protocol +
              '//' +
              window.location.hostname +
              ':' +
              window.location.port +
              `/DMN/dashboard/${this.impersonitor}`;
          }
        });
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    currentPage(val) {
      this.currPage = val;
    },
    changeRowsPerPage(count) {
      this.rowsPerPage = count;
    },
    sorting_Data(data, data1) {
      if (data === 'icon-filled-arrow-up sorting-icon') {
        const x = [...this.tableData].sort(function (a, b) {
          
          if (typeof a === 'object') {
            
            if (typeof a[data1] === 'string') {
              return String(b[data1])
                .toLowerCase()
                .localeCompare(String(a[data1]).toLowerCase());
            } else if (typeof a[data1] === 'number') {
              return b[data1] - a[data1];
            }
          } else if (typeof a === 'string') {
            return String(b)
              .toLowerCase()
              .localeCompare(String(a).toLowerCase());
          } else if (typeof b === 'number') {
            return b - a;
          } else {
            return 0;
          }
          return 0;
        });
        this.tableData = x;
      } else if (data === 'icon-filled-arrow-down sorting-icon') {
        const x = [...this.tableData].sort(function (a, b) {
          if (typeof a === 'object') {
            if (typeof a[data1] === 'string') {
              return String(a[data1])
                .toLowerCase()
                .localeCompare(String(b[data1]).toLowerCase());
            } else if (typeof a[data1] === 'number') {
              return a[data1] - b[data1];
            }
          } else if (typeof a === 'string') {
            return String(a)
              .toLowerCase()
              .localeCompare(String(b).toLowerCase());
          } else if (typeof a === 'number') {
            return a - b;
          } else {
            return 0;
          }
          return 0;
        });
        this.tableData = x;
      } else if (data === 'icon-filled-arrow-up') {
        this.tableData = this.sortData;
      } else if (data === 'icon-filled-arrow-down') {
        this.tableData = this.sortData;
      }
    },
    Contextmenu(index) {
      if (event.target.className == 'icon-vertical-dots') {
        this.tableData[index].context.show = true;
      } else {
        // this.clicked = false;
        this.tableData.forEach((e) => {
          if (e.context != undefined) {
            e.context.show = false;
          }
        });
      }
    },
    closecontextmenu(data1, data2) {
      // let indexvalue = 
      parseInt(data2);
      
    },
  },
};
</script>

<style></style>
