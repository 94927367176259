<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <div>
    <hlx-modal
      class="upc"
      :modal-active="add_model"
      :height="'330px'"
      :width="'550px'"
      :modal="true"
      @close="add_model = false"
    >
      <template #header>
        <div style="margin-left: 0px">Create quote</div>
      </template>
      <template #content>
        <div class="modal-content">
          <div class="modal-input-v2">
            <div class="input-section-v2">
              <hlx-input
                :key="componentkey"
                v-model:value="payloadSpec.name"
                :label-animation="true"
                label-value="Name"
                type="text"
                required="true"
                :clearable="true"
                @at-Input="inputTrue(payloadSpec.name)"
              />
            </div>
            <div class="input-section-v2">
              <hlx-cascader
                :options="categoryOptions"
                :required="true"
                :placeholder-value="'Category'"
                :label-animation="true"
                :label="'label'"
                :prop-value="'value'"
                @selected-array="returnSelectedObject"
              >
              </hlx-cascader>
            </div>
            <div class="input-section-v2">
              <hlx-select
                :key="componentkey"
                v-model:value="payloadSpec.brand"
                :options="Brand"
                :placeholder-value="'Brand '"
                :prop-value="'name'"
                :label="'name'"
                :label-animation="true"
                :clearable="true"
              ></hlx-select>
            </div>
            <div class="input-section-v2">
              <hlx-select
                :key="componentkey"
                v-model:value="relatedParty"
                :options="Manufacturer"
                :placeholder-value="'Manufacturer '"
                :prop-value="'name'"
                :label="'name'"
                :label-animation="true"
                :clearable="true"
              ></hlx-select>
            </div>

            <div class="input-section-v2">
              <hlx-input
                :key="componentkey"
                v-model:value="payloadSpec.validFor.endOfLife"
                :label-value="'End of life'"
                :label-animation="true"
                type="date"
                icon="calendar-regular"
                icon-position="right"
              ></hlx-input>
            </div>
            <div class="input-section-v2">
              <hlx-input
                v-model:value="payloadSpec.validFor.endOfDate"
                :pre-val="payloadSpec?.validFor?.endOfDate"
                :label-value="'End of support'"
                :label-animation="true"
                :custom-error="error"
                :display-error="error"
                :custom-error-message="errorMessage"
                type="date"
                icon="calendar-regular"
                icon-position="right"
              ></hlx-input>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <hlx-button class="secondary sm" @click="add_model = false"
          >Cancel</hlx-button
        >
        <hlx-button
          v-if="enableButton == true"
          class="primary sm"
          style="margin-left: 16px"
          @click="addProductSpec()"
          >Add</hlx-button
        >
        <hlx-button
          v-else
          class="primary sm"
          style="margin-left: 16px"
          :disabled="true"
          >Add</hlx-button
        >
      </template>
    </hlx-modal>
  </div>
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'200px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ delete_name }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
  <!-- <hlx-drawer
    :show="show"
    :width="'300'"
    position="right"
    :footer="true"
    :header="false"
    :show-close-icon="true"
    @close="closeAddHierarchyModal"
  >
    <template #body>
      <FilterComponent
        :filterpanelname="'productSpecification'"
        @filtered-data="productSpecificationFilter"
      ></FilterComponent>
    </template>
    <template #footer></template>
  </hlx-drawer> -->

  <div class="app-layout catalog specification">
    <section class="new-right-panel upc">
      <div class="new-right-panel-parent-container no-filter-panel">
        <div id="mySidepanel" class="new-right-panel-left-container"></div>
        <div class="new-right-panel-right-container">
          <section class="new-layout-basic-no-breadcrumb">
            <div class="new-layout-header">
              <span class="new-layout-header-container">
                <span class="schema-header-title"> CPQ</span>
                <span class="subtitle"> Configured pricing quotes </span>
              </span>
            </div>











<div class="QuoteLPtileDiv" >
  <div class="halleyxNeonPArent">
     <!-- {{ isClickedIndex }} -->
    <div
    v-for="(item,index) in countsofcard" :key="item" style="cursor: pointer;"
    >
    <div
:class="['mainpagedivisions', { 'firstvalueitem': isClickedIndex.some(item => item.value === index) }]" 
    @click="disableAtClick? '' : callSecondFilter(item, index)" >
                            <div class="newstatus">{{item?.status}}</div>
                            <div class="newprice">
                              <!-- {{'£'}} -->
                               <span v-if="item && item?.price && loadPrice">
                               {{currencyFormatter(item?.price)}}
                              </span>
                              <span v-else >
                                {{ 0 }}
                            </span>
                               <!-- {{item?.coontractValue}} -->
                              </div>
                            <div class="newvalue">{{item?.value}}{{" quotes"}}</div>

                          </div>
  </div>

  </div>
</div>







            <div
              v-if="tableData?.length > 0 || searchvalue"
              class="new-layout-body catalog-v2" style="margin:0 0 4.0rem 0;"
            >
              <!-- search and filter  -->
              <div
                v-if="datalength != 0"
                class="header-utils-and-switch-container"
                style="width: 100%"
              >
              
                <span class="table-header-utils"  style="gap: 0px;">
                  
                  <span class="util-items --search"
                    ><hlx-search
                      v-model="searchvalue"
                      @search-key="searchKey"
                    ></hlx-search
                  ></span>

                  <span style="margin-left: 14px;" class="filter-table">
                    <hlx-button
                      class="secondary sm add-btn"
                      @click="showDrawerRight"
                    >
                      <i class="icon-filter-regular"></i>
                      <span v-if="filtertaglength != 0" class="filter-count">{{
                        filtertaglength
                      }}</span>
                    </hlx-button>
                  </span>

                  <span style="margin-left: 16px;">
              <hlx-button
              v-if="permisstionData.add"
                      class="primary sm add-btn"
                      style="display: flex; align-items: center; gap: 0.5rem"
                      @click.stop="addSpec()"
                      ><i
                        class="icon-plus-circle-regular"
                        style="margin-right: 5px"
                      ></i
                      >Create quotation</hlx-button
                    ></span>

                  <!--drawer component put outside the screen-->
                  <hlx-drawer
                    :show="show_right_filter"
                    :width="300"
                    position="right"
                    :header="false"
                    :footer="false"
                    :show-close-icon="true"
                    @close="closeRightFilter"
                  >
                    <template #body>
                      <div style="display: flex">
                        <FilterComponent
                        :key="filterkey"
                         :clear-filters-trigger="clearFilters"
                          :filterpanelname="'quotes'"
                          :company-name="company"
                          :pre-value="prePreValue"
                          :query-value="query"
                          :display-data="filter"
                          :module="'quotes'"
                          @filtered-data="quotesfilterData"
                        ></FilterComponent>
                      </div>
                    </template>
                  </hlx-drawer>

                  <span
                    v-if="view === 'Product specifications'"
                    class="util-items util-items-add"
                    >
                    <hlx-context-menu
                      style="z-index: 10000"
                      :options="add_context_options"
                      :show="show_add_menu"
                      :style="{ marginTop: '5px', marginRight: '20px' }"
                      @chosen="fromAddContextMenu"
                    ></hlx-context-menu>
                  </span>
                </span>
              </div>

              <!-- TableData -->
              <div class="table-wrap">
                <quote-table
                :key="tableKey"
                  :search-key="searchvalue"
                  :permisstion-data="permisstionData"
                  :filter-selectedvaluedata="filterSelectedvaluedata"
                  :filter-querydata="filterQuerydata"
                  @request-change="handleRequestStatus"
                  @quotesfilter-datas="quotesfilterDatas"
                ></quote-table>
              </div>
            </div>

            <!-- no data  -->

            <div
              v-else-if="tableData?.length <= 0"
              class="new-layout-body"
              style="
                display: flex !important;
                flex-direction: column;
                align-items: center !important;
                padding: 5%;
              "
            >
              <section class="--illustration-container">
                <img
                  src="@/assets/images/svg/EmptyQuoteTableIllustration.svg"
                />
                <hlx-button
                v-if="permisstionData.add"
                  class="primary sm add-btn"
                  style="display: flex; align-items: center; gap: 0.5rem"
                  @click.stop="addSpec()"
                  ><i
                    class="icon-plus-circle-regular"
                    style="margin-top: 5px"
                  ></i
                  >Create quotation</hlx-button
                >
              </section>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  // MLTAxiosInstance,
  // DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
import FilterComponent from "@/components/filterPanelComponent.vue";
import quoteTable from "@/components/UPC/CPQ/quoteTableComponent.vue";
import { mapGetters } from "vuex";


export default {
  components: {
    FilterComponent,
    quoteTable,
  },
  props: {
    value: {
      type: String,
      default: "All",
    },
  },

  data() {
    return {
      permisstionData:{},
      clearFilters:false,
      loadPrice:false,
      prePreValue:{Status:[]},
      startPre:false,
      preValue:{Status:[]},
      selectedStatuses:[],
//       preValue:{
//     "Status": [
//         {
//             "count": 189,
//             "name": "approved",
//             "checked": true,
//             "key": "Status"
//         },
//         {
//             "count": 144,
//             "name": "draft",
//             "checked": false,
//             "key": "Status"
//         },
//         {
//             "count": 200,
//             "name": "inProgress",
//             "checked": false,
//             "key": "Status"
//         },
//         {
//             "count": 1,
//             "name": "Draft",
//             "checked": false,
//             "key": "Status"
//         },
//         {
//             "count": 128,
//             "name": "accepted",
//             "checked": false,
//             "key": "Status"
//         }
//     ],
//     "Account": [
//         {
//             "name": "",
//             "id": "",
//             "count": 1,
//             "checked": false,
//             "key": "Account"
//         },
//         {
//             "name": "Actfibre",
//             "id": "66f2292b74056edc7cfa9f36",
//             "count": 10,
//             "checked": false,
//             "key": "Account"
//         },
//         {
//             "name": "Alphabet",
//             "id": "66ee925f199e07a26f0c967a",
//             "count": 549,
//             "checked": false,
//             "key": "Account"
//         },
//         {
//             "name": "Firewall",
//             "id": "66f1c24de043bb69550caa78",
//             "count": 6,
//             "checked": false,
//             "key": "Account"
//         },
//         {
//             "name": "Loblows",
//             "id": "66f168d6f1374ad23c3ed1e2",
//             "count": 1,
//             "checked": false,
//             "key": "Account"
//         },
//         {
//             "name": "Rentmyspace",
//             "id": "66f6defb2481387d009f2fab",
//             "count": 6,
//             "checked": false,
//             "key": "Account"
//         },
//         {
//             "name": "SkyFiber",
//             "id": "66eac895698e7d24e039ebf1",
//             "count": 81,
//             "checked": false,
//             "key": "Account"
//         },
//         {
//             "name": "VodafoneGroupplc",
//             "id": "66eabf46698e7d24e039e190",
//             "count": 8,
//             "checked": false,
//             "key": "Account"
//         }
//     ],
//     "Contact": [
//         {
//             "name": "Ays",
//             "id": "66f19929f1374ad23c3efd6c",
//             "count": 4,
//             "checked": false,
//             "key": "Contact"
//         },
//         {
//             "name": "Benjamin franklin",
//             "id": "66f2297874056edc7cfa9f61",
//             "count": 8,
//             "checked": false,
//             "key": "Contact"
//         },
//         {
//             "name": "Chris  Hemsworth",
//             "id": "66f55fb4185c6b130a02ff85",
//             "count": 43,
//             "checked": false,
//             "key": "Contact"
//         },
//         {
//             "name": "Chris Jack",
//             "id": "66ee98dbd0d82ce5240cdcc5",
//             "count": 306,
//             "checked": false,
//             "key": "Contact"
//         },
//         {
//             "name": "Christopher Nolan",
//             "id": "66f5601c185c6b130a030034",
//             "count": 3,
//             "checked": false,
//             "key": "Contact"
//         },
//         {
//             "name": "Emily Cooper",
//             "id": "66ec418df548a68692508a15",
//             "count": 11,
//             "checked": false,
//             "key": "Contact"
//         },
//         {
//             "name": "Emily Watson",
//             "id": "66ee9ac9d0d82ce5240cdd34",
//             "count": 132,
//             "checked": false,
//             "key": "Contact"
//         },
//         {
//             "name": "EmilyCooper",
//             "id": "66ec418df548a68692508a15",
//             "count": 9,
//             "checked": false,
//             "key": "Contact"
//         },
//         {
//             "name": "EmilyWatson",
//             "id": "66ee9ac9d0d82ce5240cdd34",
//             "count": 88,
//             "checked": false,
//             "key": "Contact"
//         },
//         {
//             "name": "Emma Smith",
//             "id": "66eac25c698e7d24e039e3bd",
//             "count": 2,
//             "checked": false,
//             "key": "Contact"
//         },
//         {
//             "name": "EmmaSmith",
//             "id": "66eac25c698e7d24e039e3bd",
//             "count": 5,
//             "checked": false,
//             "key": "Contact"
//         },
//         {
//             "name": "Henry Cavilrine",
//             "id": "66f4cd7ebdf7cbf131ba3431",
//             "count": 5,
//             "checked": false,
//             "key": "Contact"
//         },
//         {
//             "name": "Henry Cavin",
//             "id": "66f12c25fd4c17563e56a04a",
//             "count": 4,
//             "checked": false,
//             "key": "Contact"
//         },
//         {
//             "name": "Jammy Wrogon",
//             "id": "66eac779698e7d24e039e987",
//             "count": 1,
//             "checked": false,
//             "key": "Contact"
//         },
//         {
//             "name": "John Lark",
//             "id": "66f11d71fd4c17563e5691c7",
//             "count": 5,
//             "checked": false,
//             "key": "Contact"
//         },
//         {
//             "name": "Kane William",
//             "id": "66eb1512b232a8de4f4fbb64",
//             "count": 1,
//             "checked": false,
//             "key": "Contact"
//         },
//         {
//             "name": "KaneWilliam",
//             "id": "66eb1512b232a8de4f4fbb64",
//             "count": 8,
//             "checked": false,
//             "key": "Contact"
//         },
//         {
//             "name": "Pandian",
//             "count": 6,
//             "checked": false,
//             "key": "Contact"
//         },
//         {
//             "name": "SahilBen",
//             "id": "66f169a5f1374ad23c3ed215",
//             "count": 1,
//             "checked": false,
//             "key": "Contact"
//         },
//         {
//             "name": "Tommy Range",
//             "id": "66f6f2612481387d009f4498",
//             "count": 6,
//             "checked": false,
//             "key": "Contact"
//         }
//     ]
// },
      filterkey:0,
      disableAtClick:true,
      tableKey:0,
      filterQueryforDB : {
    "type": "filter",
    "module": "quotes",
    "isPaginated": true,
    "paginatedQuery": {
        "rowsPerPage": 10,
        "currentPage": 1
    },
    "isNeedToBeSort": true,
    "sortFieldAndOrder": {},
    "limit": 10,
    "filterQuery": {},
    "searchQuery": "",
    "company": "developerHalleyx"
},
      isClicked:null,
      isClickedIndex:[{name:"all",value:0}],
      filterQuerydata:{},
      filterSelectedvaluedata:{},
       query:{
        Status:["state"],
        Account:["relatedParty.name"],
        Contact:["relatedParty.name"]
      },
      filter: {},
      emailTemp: "",
      Brand: [],
      error: false,
      errorMessage: "",
      componentkey: 0,
      datalength: 0,
      enableButton: false,
      payloadSpec: {
        name: "",
        category: "",
        validFor: {
          endOfLife: "",
          endOfDate: "",
        },
        relatedParty: [],
        unitOfMeasure: {
          name: "",
        },
      },
      categoryOptions: [],
      add_model: false,
      show: false,
      externalRowsPerPage: 10,
      show_right_filter: false,
      currencylist: [],
      filterPanelName: "",
      recurringChargePeriodType: {},
      priceobj: {},
      company: this.$cookies.get("company"),
      showpricedata: {},
      showPriceValue: false,
      offeringfiltercondition: [],
      offeringprice: [],
      filteredarray: [],
      filteredobj: { $in: [] },
      productofferingfilteredobj: { $in: [] },
      productofferingfilter: [],
      // quoteFilterOption: {
      //   type: "search",
      //   module: "productSpecification",
      //   isPaginated: true,
      //   paginatedQuery: {
      //     rowsPerPage: 10,
      //     currentPage: 1,
      //   },
      //   isNeedToBeSort: false,
      //   sortFieldAndOrder: {
      //     // "name":-1
      //   },
      //   limit: 10,
      //   filterQuery: {
      //     // "productSpecCharacteristic.productSpecCharacteristicValue.value": "",
      //   },
      //   searchQuery: "",
      // },
      offeringfilterquery: {
        type: "filter",
        module: "productOffering",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      },
      filterData: [],
      currentvalue: "ALL",
      sliderfilter: false,
      sliderMin: 0,
      sliderMax: 0,
      filterprice: {
        units: "ALL",
        min: 0,
        max: 0,
        key: "productOfferingPrice",
      },
      totalpageno: 0,
      containerleft: 0,
      containertop: 0,
      userInfo: this.$store.state.userInfo,
      filtermin: 0,
      filtermax: 0,
      filterloop: {},
      slidedata: 0,
      min: 0,
      max: 0,
      searchfilterdata: {},
      originaldata: {},
      filterdata: {},
      duplicatefilterdata: {},
      filterdat: {},
      filtered_options: {},
      filter_theads: [
        {
          prop: "place",
          label: "Place",
        },
        {
          prop: "channel",
          label: "Channel",
        },
        {
          prop: "category",
          label: "Category",
        },
        {
          prop: "productOfferingTerm",
          label: "Product offering term",
        },
        {
          prop: "marketSegment",
          label: "Market segment",
        },
        {
          prop: "productOfferingPrice",
          label: "Product offering price",
        },
      ],

      quoteThead: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: false,
          prop: "description",
          label: "Name",
          sortable: false,
          resizable: false,
          width: "auto",
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 1,
          disabled: false,
          prop: "state",
          label: "Status",
          sortable: false,
          resizable: false,
          width: 115,
          align: "center",
        },
        {
          name: "category",
          checked: true,
          id: 1,
          disabled: false,
          prop: "category",
          label: "Category",
          sortable: false,
          resizable: false,
          width: "auto",
          align: "left",
        },
        {
          name: "Item",
          checked: true,
          id: 1,
          disabled: false,
          prop: "quoteItem",
          label: "Item",
          sortable: false,
          resizable: false,
          width: 60,
          align: "left",
        },
        {
          name: "Quote date",
          checked: true,
          id: 1,
          disabled: false,
          prop: "quoteDate",
          label: "Quote date",
          sortable: false,
          resizable: false,
          width: 250,
          align: "left",
        },
        {
          name: "Price",
          checked: true,
          id: 1,
          disabled: false,
          prop: "quoteTotalPrice",
          label: "Price",
          sortable: false,
          resizable: false,
          width: "auto",
          align: "left",
        },
        {
          name: "Version",
          checked: true,
          id: 1,
          disabled: false,
          prop: "version",
          label: "Version",
          sortable: false,
          resizable: false,
          width: "auto",
          align: "left",
        },
      ],
      countsofcard: [
    {status: "All", price: "£12,830", value: "84 quotes"},
    {status: "Draft", price: "£10,030", value: "54 quotes"},
    {status: "Send", price: "£1,530", value: "6 quotes"},
    {status: "Completed", price: "£0", value: "16 quotes"},
    {status: "Approved", price: "£1,130", value: "12 quotes"},
    {status: "Expired", price: "£0", value: "0 quotes"}
],
      quoteFilterOption: {
        type: "filter",
        module: "quotes",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {},
        searchQuery: "1",
      },
      quoteTheadC: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: false,
          prop: "description",
          label: "Name",
          sortable: false,
          resizable: false,
          width: null,
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 1,
          disabled: false,
          prop: "state",
          label: "Status",
          sortable: false,
          resizable: false,
          width: null,
          align: "left",
        },
        {
          name: "category",
          checked: true,
          id: 1,
          disabled: false,
          prop: "category",
          label: "Category",
          sortable: false,
          resizable: false,
          width: "auto",
          align: "left",
        },
        {
          name: "Item",
          checked: true,
          id: 1,
          disabled: false,
          prop: "quoteItem",
          label: "Item",
          sortable: false,
          resizable: false,
          width: "auto",
          align: "left",
        },
        {
          name: "Quote date",
          checked: true,
          id: 1,
          disabled: false,
          prop: "quoteDate",
          label: "Quote date",
          sortable: false,
          resizable: false,
          width: 250,
          align: "left",
        },
        {
          name: "Price",
          checked: true,
          id: 1,
          disabled: false,
          prop: "quoteTotalPrice",
          label: "Price",
          sortable: false,
          resizable: false,
          width: 250,
          align: "left",
        },
        {
          name: "Version",
          checked: true,
          id: 1,
          disabled: false,
          prop: "version",
          label: "Version",
          sortable: false,
          resizable: false,
          width: 250,
          align: "left",
        },
      ],

      modal_active6: false,
      dropdown_data: [
        { name: "AND", value: "AND" },
        { name: "Between", value: "Between" },
        { name: "OR", value: "OR" },
      ],
      show_right: false,
      token: this.$route.params.token,
      editMode: true,
      editPermission: [],
      viewPermission: [],
      unique: Math.floor(Math.random() * 1000 + 1),
      delete_modal_active: false,
      searchtabledata: [],
      // businessrulepagespinner: true,
      // businessrulepagetable: false,
      duplicatetabledata: [],
      config_nav_items: [],
      delete_name: "",
      datum: {},
      success: false,
      success_msg: "",
      notification: {},
      expressions: [],
      view: "Product specifications",
      schema_type_switch: [
        { name: "Product specifications", value: "Product specifications" },
      ],
      selectedValue: "",
      // selectedValue: "Product offerings",
      show_add_menu: false,
      show_crud_menu: false,
      theads: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: false,
          prop: "name",
          label: "Name",
          sortable: false,
          resizable: false,
          width: 80,
          align: "left",
          fixed: "left",
        },
        {
          name: "Type",
          checked: true,
          id: 5,
          disabled: false,
          prop: "type",
          label: "Type",
          sortable: false,
          resizable: false,
          width: 80,
          align: "left",
        },
        {
          name: "Category",
          checked: true,
          id: 2,
          disabled: false,
          prop: "category",
          label: "Category",
          sortable: false,
          resizable: false,
          width: 90,
          align: "left",
        },
        {
          name: "Channel",
          checked: true,
          id: 2,
          disabled: false,
          prop: "channel",
          label: "Channel",
          sortable: false,
          resizable: false,
          width: 90,
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 4,
          disabled: false,
          prop: "status",
          label: "Status",
          sortable: false,
          resizable: false,
          width: 70,
          align: "left",
        },
        {
          name: "Market",
          checked: true,
          id: 3,
          disabled: false,
          prop: "market",
          label: "Market",
          sortable: false,
          resizable: false,
          width: 90,
          align: "left",
        },
        {
          name: "Place",
          checked: true,
          id: 5,
          disabled: false,
          prop: "place",
          label: "Place",
          sortable: false,
          resizable: false,
          width: 90,
          align: "left",
        },
        {
          name: "Validity",
          checked: true,
          id: 5,
          disabled: false,
          prop: "validity",
          label: "Validity",
          sortable: false,
          resizable: false,
          width: 190,
          align: "left",
        },
        {
          name: "Last modified",
          checked: true,
          id: 5,
          disabled: false,
          prop: "last_updated_at",
          label: "Last modified",
          sortable: false,
          resizable: false,
          width: 170,
          align: "left",
        },
      ],
      theads1: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: false,
          prop: "name",
          label: "Name",
          sortable: false,
          resizable: false,
          width: 80,
          align: "left",
        },
        {
          name: "Type",
          checked: true,
          id: 5,
          disabled: false,
          prop: "type",
          label: "Type",
          sortable: false,
          resizable: false,
          width: 80,
          align: "left",
        },
        {
          name: "Category",
          checked: true,
          id: 2,
          disabled: false,
          prop: "category",
          label: "Category",
          sortable: false,
          resizable: false,
          width: null,
          align: "left",
        },
        {
          name: "Channel",
          checked: true,
          id: 2,
          disabled: false,
          prop: "channel",
          label: "Channel",
          sortable: false,
          resizable: false,
          width: null,
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 4,
          disabled: false,
          prop: "status",
          label: "Status",
          sortable: false,
          resizable: false,
          width: 90,
          align: "left",
        },
        {
          name: "Market",
          checked: true,
          id: 3,
          disabled: false,
          prop: "market",
          label: "Market",
          sortable: false,
          resizable: false,
          width: 90,
          align: "center",
        },
        {
          name: "Place",
          checked: true,
          id: 5,
          disabled: false,
          prop: "place",
          label: "Place",
          sortable: false,
          resizable: false,
          width: 90,
          align: "center",
        },
        {
          name: "Validity",
          checked: true,
          id: 5,
          disabled: false,
          prop: "validity",
          label: "Validity",
          sortable: false,
          resizable: false,
          width: 120,
          align: "left",
        },
        {
          name: "Last modified",
          checked: true,
          id: 5,
          disabled: false,
          prop: "last_updated_at",
          label: "Last modified",
          sortable: false,
          resizable: false,
          width: 175,
          align: "left",
        },
      ],
      filtertabledata: [
        {
          type: "Product Offering",
          relatedParty: [],
          _id: "64e5bc614ab1048d37e74e59",
          name: "My Quick BB Offer",
          bundledProductOffering: [],
          isBundle: false,
          isSellable: true,
          lastUpdate: "2023-08-23T07:56:23.284Z",
          lifecycleStatus: "In-Active",
          place: [],
          channel: [],
          attachment: [],
          productOfferingTerm: [],
          marketSegment: [],
          productOfferingPrice: [],
          agreement: [],
          prodSpecCharValueUse: [],
          __v: 0,
        },
        {
          _id: "64eb64eba4c164e0f5e55d0a",
          name: "check",
          bundledProductOffering: [],
          description: "check product offerings",
          priceType: "Recuring",
          isBundle: false,
          isSellable: true,
          lastUpdate: "2023-08-27T14:54:59.656Z",
          lifecycleStatus: "Active",
          place: [{ name: "Canada" }],
          channel: [{ name: "Sales channel" }],
          attachment: [],
          category: [{ name: "Bike", id: "64d19db97c1374c67da7f2e4" }],
          productOfferingTerm: [],
          marketSegment: [{ name: "France" }],
          productOfferingPrice: [],
          agreement: [],
          prodSpecCharValueUse: [
            {
              name: "CPE Model",
              productSpecCharacteristicValue: [
                { isDefault: false, value: "Advanced" },
              ],
            },
            {
              name: "CPE Model",
              productSpecCharacteristicValue: [
                { isDefault: false, value: "Premium" },
              ],
            },
          ],
          type: "Product Offering",
          __v: 0,
          relatedParty: [{ name: "John" }],
          productSpecification: {
            name: "new name",
            id: "64ec05451c352016b39dfc06",
          },
        },
        {
          _id: "64ebfceca868bd9c44a7104a",
          name: "BB offer1",
          bundledProductOffering: [],
          description: "offer type exaample",
          priceType: "Recuring",
          isBundle: false,
          isSellable: true,
          lastUpdate: "2023-08-28T01:48:28.059Z",
          lifecycleStatus: "Active",
          place: [{ name: "Canada" }],
          channel: [
            { name: "Sales channel" },
            { name: "Distribution channel" },
          ],
          attachment: [],
          category: [{ name: "Bike", id: "64d19db97c1374c67da7f2e4" }],
          productOfferingTerm: [],
          marketSegment: [{ name: "France" }],
          productOfferingPrice: [],
          agreement: [],
          prodSpecCharValueUse: [
            {
              name: "CPE Model",
              productSpecCharacteristicValue: [
                { isDefault: false, value: "Advanced" },
              ],
            },
            {
              name: "CPE Model",
              productSpecCharacteristicValue: [
                { isDefault: false, value: "Premium" },
              ],
            },
          ],
          type: "Product Offering",
          relatedParty: [{ name: "Alex" }],
          __v: 0,
        },
        {
          _id: "64ec49c17c2c382dacaac6ae",
          name: "testOffering",
          bundledProductOffering: [],
          description: "",
          priceType: "",
          isBundle: false,
          isSellable: true,
          lastUpdate: "2023-08-28T07:05:12.105Z",
          lifecycleStatus: "Active",
          place: [""],
          channel: [],
          attachment: [],
          category: [],
          productOfferingTerm: [],
          marketSegment: [],
          productOfferingPrice: [],
          agreement: [],
          prodSpecCharValueUse: [
            {
              name: "CPE Model",
              productSpecCharacteristicValue: [
                { isDefault: false, value: "Advanced" },
              ],
            },
            {
              name: "CPE Model",
              productSpecCharacteristicValue: [
                { isDefault: false, value: "Premium" },
              ],
            },
          ],
          type: "Product Offering",
          relatedParty: [{ name: "John doe" }],
          __v: 0,
        },
      ],
      tableData: [],
      logData: [],
      re_arrange: false,
      OfferingcurrPage: 1,
      OfferingrowsPerPage: 10,
      SpecificationcurrPage: 1,
      SpecificationrowsPerPage: 10,
      currPagePS: 1,
      rowsPerPagePS: 10,
      filtercurrPage: 1,
      filterrowsPerPage: 10,
      // duplicateTableData: [],
      duplicateTheads: [],
      duplicateTheads1: [],
      searchvalue: "",
      id: "tenant1",
      name: "myself",
      deleteobj: {},
      filterobj: {},
      filtervalueobj: {},
      searchfiltervalue: "",
      filtertagvalue: [],
      filtertaglength: 0,
      filteredtabledata: [],
      fdata: [],
      parentelement: "",
      parentdata: {},
      parentarray: [],
      Manufacturer: [],
      unitOfMeasure: [],
      unit: "",
      relatedParty: "",
      endOfDate: "",
      onRequest: true,
    };
  },
  computed: {
    ...mapGetters(["currencyList"]),
    // records(){
    //   return this.$store.getters.allRule
    // },
    filteroptions() {
      return this.filterobj;
    },
    paginatedData() {
      if (this.view === "Product offerings") {
        // const start = (this.currPagePO - 1) * this.rowsPerPagePO;
        // const end = start + this.rowsPerPagePO;
        return this.tableData;
      } else if (this.view === "Product specifications") {
        // const start = (this.currPagePS - 1) * this.rowsPerPagePS;
        // const end = start + this.rowsPerPagePS;
        // ;
        return this.tableData;
        // .slice(start, end);
      } else {
        return this.tableData;
      }
    },
    showingFrom() {
      if (this.view === "Product offerings") {
        return (this.currPagePO - 1) * this.rowsPerPagePO + 1;
      } else {
        return (this.currPagePS - 1) * this.rowsPerPagePS + 1;
      }
    },
    showingTo() {
      if (this.view === "Product offerings") {
        const lastItem = this.showingFrom + this.rowsPerPagePO - 1;
        return lastItem > this.totalRows ? this.totalRows : lastItem;
      } else {
        const lastItem = this.showingFrom + this.rowsPerPagePS - 1;
        return lastItem > this.totalRows ? this.totalRows : lastItem;
      }
    },
    totalRows() {
      return this.tableData.length;
    },
  },
  watch: {
    // preValue: {
    //   handler(val) {
    // // console.log("preValue..........",val,this.startPre)
    // // if(this.startPre){
    // //   setTimeout(() => {
    // //     console.log("runnnnnnnnnnnnnnnnnnnnnnnnnnnnnn");
        
    // //     // this.prePreValue = val  
    // //   }, 1000);
      
    // // }
    //   },
    //   immediate: true,
    //   deep: true
    // },
    
    // isClickedIndex: {
    //   async handler(val) {
    // console.log("isClickedIndex..........",val,this.startPre)
    // const vale = await MLTAxiosInstance.get("partymanagement/individual/auth");
    // // this.company = val.data.company;
    // console.log("-------------------------------------------",vale);
    
    //   },
    //   immediate: true,
    //   deep: true
    // },
    // startPre: {
    //   handler(val) {
    // console.log("startPre..........",val,this.preValue)
    //  // Update local statusList only if the incoming value is different
    //  if (JSON.stringify(val) !== JSON.stringify(this.prePreValue)) {

    //   if(this.startPre){
    //   setTimeout(() => {
    //     console.log("runnnnnnnnnnnnnnnnnnnnnnnnnnnnnn offffffffffffffffffffffffffff");
        
    //     this.prePreValue = JSON.parse(JSON.stringify(this.preValue))  
    //   }, 500);
      
    // }


    //  }
    
    //   },
    //   immediate: true,
    //   deep: true
    // },
    "$route.query.for": {
      handler(query) {
        if (!query) return;
        // console.log(query.trim());
        if (query.trim() == "offering") {
          this.selectedValue = "Product offerings";
          this.handleCurrentChange(this.selectedValue);
        } else if (query.trim() == "specification") {
          this.selectedValue = "Product specifications";
          this.handleCurrentChange(this.selectedValue);
          // this.$nextTick(() => {
          //   console.log(this.selectedValue,'jerry');
          //   this.handleCurrentChange(this.selectedValue);
          // });
        }
      },
      deep: true,
      // immediate:true
    },
    // totalpageno: {
    //   handler(data) {

    //   },
    //   immediate: true,
    //   deep: true
    // },
    tableData: {
      handler() {
        // ;
        //         data?.forEach((e) => {
        //   // ;
        //   // if (e.context !== undefined) {
        //     e.context = false;
        //   // }
        // })
        // ;
        // if(data.length>1)
        // {
        //   this.tableData = data.sort(
        //     (a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate)
        //   );
        // }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");

let sampleTheadsd = await MLTAxiosInstance.get(
  `partyRole/get/${val.data.userId}`
);

const mergedPayload = sampleTheadsd.data.permission;

console.log(mergedPayload,"mergedPayloadmergedPayload");
 
this.permisstionData =
  mergedPayload.Quotes.child.Quotes;

  console.log(this.permisstionData,"this.permisstionData")
    this.dashboardFunction();
    const filterResultData = await UPCAxiosInstance.post(
      `util/filterData`,
      this.quoteFilterOption
    );
    console.log("🚀 ~ mounted ~ filterResultData:", filterResultData);
    this.datalength = filterResultData.data.total;
    this.tableData = filterResultData.data.data;
    this.totalpageno = filterResultData.data.total;
    this.paginatedData;

    await this.$store.dispatch("quoteFilterData");
    const response = this.$store.getters.quoteFilterData;
    console.log("11111111111111",response);

    // Define the status names you want to keep
const statusToKeep = [ "inProgress", "approved" ,"accepted","sent","expired"];

    
// Filter the Status array
let StatusOnly = response.Status.filter(status => statusToKeep.includes(status.name));

// Create a mapping of old status names to new names
const statusMapping = {
  "inProgress": "draft",
  "accepted": "completed",
  "approved": "sent"
};

// Update the name values based on the mapping
StatusOnly = StatusOnly.map(status => {
  if (statusMapping[status.name]) {
    status.name = statusMapping[status.name];
  }
  return status;
});

response.Status = StatusOnly;
this.filter = response;
console.log("111111111111111122222222222222222222222222222222222222223333333 this.filter",response);
    //  this.filter
    //

//     if(this.filter){
//       this.filter = Object.keys(this.filter).reduce((acc, key) => {
//     if (key !== 'Status') {
//       acc[key] = this.filter[key];
//     }
//     return acc;
//   }, {});
// }
    console.log("filter123... filter",this.filter);

    // this.duplicateTheads = JSON.parse(JSON.stringify(this.theads));
    // this.duplicateTheads1 = JSON.parse(JSON.stringify(this.theads1));

    // const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    // this.company = val.data.company;
    // this.userId = val.data.company;
    // const access = await MLTAxiosInstance.get(
    //   `partyRole/get/${val.data.userId}`
    // );

    // let arr = access.data;

    //  const categorydata = await UPCAxiosInstance.get(
    //   `catalogManagement/category/dropdown`
    // );
    // this.categoryOptions = await categorydata.data?.filter((item) => item);
    // const unitOfmesure = await DMNAxiosInstance.get(
    //   `mapRef/collection/unitOfMeasure`
    // );
    // this.unitOfMeasure = unitOfmesure.data.data;
    // const manufacturer = await DMNAxiosInstance.get(
    //   `mapRef/collection/manufacturers`
    // );
    // console.log(manufacturer.data.data, "manufac");
    // this.Manufacturer = manufacturer.data.data;

    // const brand = await DMNAxiosInstance.get(`mapRef/collection/Brand`);
    // console.log(brand.data.data, "manu");
    // this.Brand = brand.data.data;
    // this.editPermission = [];
    // if (arr && Object.keys(arr).length > 0) {
    //   if (arr.permission.User_settings.edit == false) {
    //     this.editPermission.push("setting");
    //   }
    //   if (arr.permission.User_settings.view == false) {
    //     this.viewPermission.push("setting");
    //   }
    //   if (arr.permission.Schema.edit == false) {
    //     this.editPermission.push("schema");
    //   }
    //   if (arr.permission.Schema.view == false) {
    //     this.viewPermission.push("Schema");
    //   }
    //   if (arr.permission.Test_data.view == false) {
    //     this.viewPermission.push("Database", "Execute");
    //   }
    //   if (arr.permission.Test_data.edit == false) {
    //     this.editPermission.push("testdata");
    //   }
    //   if (arr.permission.Rules.view == false) {
    //     this.viewPermission.push("Business rule");
    //   }
    //   if (arr.permission.Rules.edit == false) {
    //     this.editPermission.push("Business rule");
    //   }
    //   if (this.editPermission.includes("Business rule") == true) {
    //     this.editMode = false;
    //   }
    // }

    // document.addEventListener("click", (event) => {
    //   // console.log(this.tableData,"zsdxfghjk");
    //   if (!event.target.classList.contains("icon-more-vertical-filled")) {
    //     // ;
    //     // console.log(this.tableData,"zsdxfghjk");
    //     this.tableData?.forEach((e) => {
    //       // ;
    //       // console.log(e.context,"zsdxfghjk");
    //       if (e.context !== undefined) {
    //         e.context = false;
    //       }
    //     });
    //   }
    // });

    // this.$store.commit("token", this.$route.params.token);
    // // this.headvalue =

    // // await this.$store.dispatch('fetchall')
    // // await this.$store.dispatch('fetchdecisions')

    // // this.view = "All";
    // // //

    // // this.tableData= this.$store.getters.allRule
    // // this.refreshTable();

    // document.addEventListener("click", (event) => {
    //   if (!event.target.classList.contains("add-btn")) {
    //     this.show_add_menu = false;
    //   }
    //   if (!event.target.classList.contains("icon-more-vertical-filled")) {
    //     // const elements = document.querySelectorAll("[class*=crud]");
    //     // for (const element of elements) {
    //     //   element.style.display = "none";
    //     // }
    //     // this.tableData.forEach((e) => {
    //     //   if (e.context !== undefined) {
    //     //     e.context.show = false
    //     //   }else{
    //     //     e.context.show = false
    //     //   }
    //     // })
    //   }
    // });
    // await this.$store.dispatch("initPermissionAnalayzer");

    //  //
  },

  methods: {


    updateFilterState(filterQuery) {
    // Check if 'state' exists in filterQuery
    if (filterQuery.filterQuery && filterQuery.filterQuery.state && filterQuery.filterQuery.state.$in) {
        // Map through the state array and replace the values
        filterQuery.filterQuery.state.$in = filterQuery.filterQuery.state.$in.map(state => {
            if (state === "draft") return "inprogress";
            if (state === "sent") return "approved";
            if (state === "completed") return "accepted";
            return state; // Return other states unchanged if present
        });
    }
    return filterQuery;
},


callSecondFilter(item, index) {
  let status = ["all", "draft", "sent", "completed", "expired"];
  
  if (index === 0) {
    this.isClickedIndex = [{ name: "all", value: 0 }];
    this.filtertaglength = 0;
    this.filterQuerydata = {
      type: "filter",
      module: "quotes",
      isPaginated: true,
      paginatedQuery: { rowsPerPage: 10, currentPage: 1 },
      isNeedToBeSort: true,
      sortFieldAndOrder: {},
      limit: 10,
      filterQuery: {},
      searchQuery: "",
      company: "developerHalleyx"
    };
    this.prePreValue = { Status: [] };
    this.filtertaglength = 0;
    setTimeout(() => {
      this.clearFilters = true;
    }, 500);
    this.clearFilters = false;
    this.filterkey += 0;
  } else {
    this.isClickedIndex = this.isClickedIndex.filter(i => i.value !== 0);
    const exists = this.isClickedIndex.some(i => i.value === index);
    exists 
      ? this.isClickedIndex = this.isClickedIndex.filter(i => i.value !== index) 
      : this.isClickedIndex.push({ name: status[index], value: index });
    if (!this.isClickedIndex.length){
      this.prePreValue = { Status: [] };
      this.filtertaglength = 0;
      this.isClickedIndex = [{ name: "all", value: 0 }];
      setTimeout(() => {
      this.clearFilters = true;
    }, 500);
    this.clearFilters = false;
      this.filterkey += 0;
    } 
  }

  const filterData = this.callFilter(this.isClickedIndex);
  if (JSON.stringify(this.filter) !== JSON.stringify(filterData)) {
    this.prePreValue = { ...filterData };
    console.log("send prop", filterData);
  } else {
    console.log("don't send prop");
  }
}

,

// Function to filter preValue based on val
callFilter(clickedIndex) {
    const filteredStatus = this.filter.Status
        .filter(status => clickedIndex.some(index => index.name === status.name))
        .map(status => ({
            ...status,
            checked: true,
            key: 'Status'
        }));

    return {
        Status: filteredStatus
    };
},



async dashboardFunction() {
  try {
    // Fetching the dashboard data
    const response = await UPCAxiosInstance.get(`/quotemanagement/dashboard`);
    console.log("11111111111111111111111111",response);
    
    const cardData = response?.data?.states || [];
    const cardData2 = response?.data?.all || [];

    // Validate if the response data is an array and contains the expected data
    if (!Array.isArray(cardData) || cardData.length === 0) {
      throw new Error("Invalid or empty response data");
    }

    // Initialize countsofcard based on the required states
    this.countsofcard = [
      {
        status: "All",
        price: cardData2.find(item => item.state === "All")?.totalAmount || "0",  // Using coontractValue for "All"
        value: cardData2.find(item => item.state === "All")?.total || 0,
        hidden:""
      },
      {
        status: "Draft",
        price: cardData.find(item => item.state === "Draft")?.coontractValue || "0",  // Using coontractValue for "All"
        value: cardData.find(item => item.state === "Draft")?.total - 1 || 0,
        hidden:"inProgress"
      },
      {
        status: "Sent",
        price: cardData.find(item => item.state === "Sent")?.coontractValue || "0",  // Using coontractValue for "All"
        value: cardData.find(item => item.state === "Sent")?.total || 0,
        hidden:"approved"
      },
      {
        status: "Completed",
        price: cardData.find(item => item.state === "Completed")?.coontractValue || "0",  // Using coontractValue for "All"
        value: cardData.find(item => item.state === "Completed")?.total || 0,
        hidden:"accepted"
      },
      {
        status: "Approved",
        price: cardData.find(item => item.state === "Approved")?.coontractValue || "0",  // Using coontractValue for "All"
        value: cardData.find(item => item.state === "Approved")?.total || 0,
        hidden:"approved"
      },
      {
        status: "Expired",
        price: cardData.find(item => item.state === "Expired")?.coontractValue || "0",  // Using coontractValue for "All"
        value: cardData.find(item => item.state === "Expired")?.total || 0,
        hidden:"expired"
      },
    ];

    console.log(this.countsofcard,"+++++++++++++++++++++++++++");
  } catch (error) {
    // Handle network and API errors
    if (error.response) {
      console.error("API Error:", error.response.data.message || error.response.statusText);
    } else if (error.request) {
      console.error("No response from server:", error.request);
    } else {
      console.error("Error:", error.message);
    }
  }
  setTimeout(() => {
    this.loadPrice  = true;
  }, 1000);
}
,


quotesfilterData(filterSelectedvalue, filterQuery) {
  console.log("filter123-------------------- 11111111111", filterSelectedvalue, filterQuery);

  this.filterQuerydata = this.updateFilterState(filterQuery);
  this.filtertaglength = filterSelectedvalue?.length || 0;

  let status = ["all", "draft", "sent", "completed", "expired"];
  
  this.isClickedIndex = this.filtertaglength 
    ? filterSelectedvalue.map(item => ({
        name: item.name,
        value: status.indexOf(item.name)
      }))
    : [{ name: "all", value: 0 }];

  this.filterSelectedvaluedata = filterSelectedvalue;

  this.quotesfilterDatas();

  if (!Object.keys(filterQuery?.filterQuery || {}).length) {
    this.isClickedIndex = [{ name: "all", value: 0 }];
  }

  console.log("filter123--------------------", filterSelectedvalue, filterQuery);
}
,




 quotesfilterDatas(){
  console.log("call")
 },    handleRequestStatus(value) {
      console.log("🚀 ~ handleRequestStatus ~ value:", value);
      this.disableAtClick = value;
      this.onRequest = value;
    },
    categoryLeaf(text) {
      if (text) {
        let lastWord = text.substring(text.lastIndexOf("/") + 1);
        if (lastWord) {
          let title = lastWord.charAt(0).toUpperCase() + lastWord.slice(1);
          return title;
        } else {
          let title = text.charAt(0).toUpperCase() + text.slice(1);
          return title;
        }
      }
    },
    async capitalizeFirstWord(val) {
      let str = val;
      console.log(str, "testtt str");
      return await Promise.all(str.charAt(0).toUpperCase() + str.slice(1));
    },
    inputTrued($event, val) {
      val;
      if (this.payloadSpec.name) {
        this.enableButton = true;
      } else {
        this.enableButton = false;
      }
    },
    inputTrue(val) {
      if (val) {
        if (this.payloadSpec.name) {
          this.enableButton = true;
        } else {
          this.enableButton = false;
        }
      } else {
        this.enableButton = false;
      }
    },

    async addProductSpec() {
      if (
        this.payloadSpec.validFor.endOfLife != "" &&
        this.payloadSpec.validFor.endOfDate
      ) {
        const endOfLifeDate = new Date(this.payloadSpec.validFor.endOfLife);
        const endOfSupportDate = new Date(this.payloadSpec.validFor.endOfDate);
        if (endOfLifeDate <= endOfSupportDate) {
          if (this.payloadSpec.category) {
            this.payloadSpec.category = this.payloadSpec.category
              .map((item) => item.toLowerCase())
              .join("/");
          }
          this.payloadSpec.unitOfMeasure = { name: this.unit };
          this.payloadSpec.relatedParty = [{ name: this.relatedParty }];
          const addSpecification = await UPCAxiosInstance.post(
            `/catalogManagement/productSpecification`,
            this.payloadSpec
          );
          if (addSpecification.data) {
            this.datalength += 1;
            this.notification = {
              type: "success",
              message: `Nice work! Your new product specification ${addSpecification.data.name}  is now in the list!`,
            };
            this.add_model = false;
            this.payloadSpec = {
              name: "",
              category: "",
              validFor: {
                endOfLife: "",
                endOfDate: "",
              },
              relatedParty: [],
              unitOfMeasure: {
                name: "",
              },
            };
            this.refreshTable();
          } else {
            this.notification = {
              type: "success",
              message: `Nice work! Your product specification ${this.payloadSpec.name}  is already in the list!`,
            };
            this.add_model = false;
            this.payloadSpec = {
              name: "",
              category: "",
              validFor: {
                endOfLife: "",
                endOfDate: "",
              },
              relatedParty: [],
              unitOfMeasure: {
                name: "",
              },
            };
            this.refreshTable();
          }
        } else {
          this.error = true;
          this.errorMessage = "End of support is less than end of life.";
        }
      } else {
        if (this.payloadSpec.category) {
          this.payloadSpec.category = this.payloadSpec.category
            .map((item) => item.toLowerCase())
            .join("/");
        }
        this.payloadSpec.unitOfMeasure = { name: this.unit };
        this.payloadSpec.relatedParty = [{ name: this.relatedParty }];
        const addSpecification = await UPCAxiosInstance.post(
          `/catalogManagement/productSpecification`,
          this.payloadSpec
        );
        if (addSpecification.data) {
          this.datalength += 1;
          this.notification = {
            type: "success",
            message: `Nice work! Your new product specification ${addSpecification.data.name}  is now in the list!`,
          };
          this.add_model = false;
          this.payloadSpec = {
            name: "",
            category: "",
            validFor: {
              endOfLife: "",
              endOfDate: "",
            },
            relatedParty: [],
            unitOfMeasure: {
              name: "",
            },
          };
          this.refreshTable();
        } else {
          this.notification = {
            type: "success",
            message: `Nice work! Your product specification ${this.payloadSpec.name}  is already in the list!`,
          };
          this.add_model = false;
          this.payloadSpec = {
            name: "",
            category: "",
            validFor: {
              endOfLife: "",
              endOfDate: "",
            },
            relatedParty: [],
            unitOfMeasure: {
              name: "",
            },
          };
          this.refreshTable();
        }
      }
    },

    async specificationdataCalling(currentPage, rowsPerPage) {
      // ;
      if (this.filtertagvalue.length === 0 && this.searchvalue == "") {
        // var totalData = await UPCAxiosInstance.get(`catalogManagement/productSpecification`)
        // this.totalpageno = totalData.data.length
        // var paginatedTableData = await UPCAxiosInstance.get(
        //   `catalogManagement/productSpecification?pageNo=${currentPage}&size=${rowsPerPage}`
        // );
        // this.tableData = paginatedTableData.data;
        this.quoteFilterOption.type = "filter";
        this.quoteFilterOption["paginatedQuery"]["currentPage"] = currentPage;
        this.quoteFilterOption["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          this.quoteFilterOption
        );

        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data.total;
        this.paginatedData;
      } else if (this.quoteFilterOption.type == "filter") {
        if (this.filtertagvalue.length != 0) {
          this.quoteFilterOption["paginatedQuery"]["currentPage"] = currentPage;
          this.quoteFilterOption["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
          const filterResultData = await UPCAxiosInstance.post(
            `util/filterData`,
            this.quoteFilterOption
          );

          this.tableData = filterResultData.data.data;
          this.totalpageno = filterResultData.data.total;
          this.tableData?.map((e) => {
            // ;
            if (e.context == undefined) {
              e.context = false;
            }
          });
        } else {
          this.quoteFilterOption.type = "search";
          this.quoteFilterOption["paginatedQuery"]["currentPage"] = currentPage;
          this.quoteFilterOption["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
          const filterResultData = await UPCAxiosInstance.post(
            `util/filterData`,
            this.quoteFilterOption
          );

          this.tableData = filterResultData.data.data;
          this.totalpageno = filterResultData.data.total;
          this.tableData?.map((e) => {
            // ;
            if (e.context == undefined) {
              e.context = false;
            }
          });
        }
      } else if (this.quoteFilterOption.type == "search") {
        if (this.searchvalue != "") {
          this.quoteFilterOption["paginatedQuery"]["currentPage"] = currentPage;
          this.quoteFilterOption["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
          const filterResultData = await UPCAxiosInstance.post(
            `util/filterData`,
            this.quoteFilterOption
          );

          this.tableData = filterResultData.data.data;
          this.totalpageno = filterResultData.data.total;
          this.tableData?.map((e) => {
            // ;
            if (e.context == undefined) {
              e.context = false;
            }
          });
        } else {
          this.quoteFilterOption.type = "filter";
          this.quoteFilterOption["paginatedQuery"]["currentPage"] = currentPage;
          this.quoteFilterOption["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
          const filterResultData = await UPCAxiosInstance.post(
            `util/filterData`,
            this.quoteFilterOption
          );

          this.tableData = filterResultData.data.data;
          this.totalpageno = filterResultData.data.total;
          this.tableData?.map((e) => {
            // ;
            if (e.context == undefined) {
              e.context = false;
            }
          });
        }
      }
    },
    async productSpecificationFilter(filterSelectedvalue, filterQuery) {
      // ;
      this.filtertagvalue = filterSelectedvalue;
      this.filtertaglength = filterSelectedvalue.length;
      if (this.filtertagvalue.length != 0) {
        // this.totalpageno = spectotalpageno
        // this.tableData = spectabledata
        this.quoteFilterOption = filterQuery;
        this.quoteFilterOption.type = "filter";
        if (this.searchvalue != "") {
          this.quoteFilterOption["searchQuery"] = this.searchvalue;
        }
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          this.quoteFilterOption
        );
        // console.log(filterResultData.data.data,'la');

        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data.total;
        // ;
      } else {
        this.SpecificationcurrPage = 1;
        this.SpecificationrowsPerPage = 10;
        this.specificationdataCalling(
          this.SpecificationcurrPage,
          this.SpecificationrowsPerPage
        );
      }
    },
    showDrawerRight() {
      this.show_right_filter = true;
    },
    closeRightFilter() {
      this.show_right_filter = false;
    },

    async sliderval(val1, val2) {
      val1;
      val2;
      this.filterprice.checked = true;
      this.filterprice.value =
        this.filterprice.min + "-" + this.filterprice.max;
      // let obj = {};
      // obj.filter = this.filtertagvalue;
      if (this.sliderfilter == false) {
        this.filtertagvalue.push(this.filterprice);
        this.sliderfilter = true;
      }
      let obj = {};
      obj.filter = this.filtertagvalue;
      obj.currentpage = this.filtercurrPage;
      obj.rowsperpage = this.filterrowsPerPage;
      const filtertabledata = await UPCAxiosInstance.post(
        `catalogManagement/productOffering/filter`,
        obj
      );
      this.totalpageno = await filtertabledata.data.totallength;
      this.tableData = filtertabledata.data.data;
      this.tableData?.map((e) => {
        if (e.context == undefined) {
          e.context = false;
        }
      });
    },
    tooltipover(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis(data1, index1);
    },
    tooltipover1(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis1(data1, index1);
    },
    tooltipleave(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses(data1, index1);
    },
    tooltipleave1(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses1(data1, index1);
    },
    ellipsis(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              let height = tooltip.offsetHeight;
              let top = element1.getBoundingClientRect().top - height;
              let left = element1.getBoundingClientRect().left;
              tooltip.style.display = "block";
              tooltip.style.top = top + "px";
              tooltip.style.left = left + "px";
              return true;
            }
          }
          return false;
        }
      });
    },
    ellipsis1(data, index) {
      this.$nextTick(() => {
        // if(event.target.tagName != 'SPAN')
        // {
        // console.log(data,index,event.target.tagName);
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let height = tooltip.offsetHeight;
              let top = element1.getBoundingClientRect().top - 30;
              top = Math.abs(top);
              let left = element1.getBoundingClientRect().left + 200;
              tooltip.style.display = "block";
              tooltip.style.top = top + "px";
              tooltip.style.left = left + "px";
              return true;
            }
          }
          return false;
        }
        // }
      });
    },
    ellipses(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              tooltip.style.display = "none";
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },
    ellipses1(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              tooltip.style.display = "none";
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },
    async filterdatafunction() {
      // ;
      if (this.selectedvalue == "Product specifications") {
        await this.$store.dispatch("productSpecFilterData");

        this.filterdata = this.$store.getters.productSpecificationFilterData;
        // const alldata = await UPCAxiosInstance.get(`catalogManagement/productSpecification/filterlist/v1`);
        // this.filterdata = alldata.data
        // ;
      } else {
        await this.$store.dispatch("productOfferFilterData");

        this.filterdata = this.$store.getters.productOfferingFilterData;
        Object.keys(this.filterdata).forEach((e) => {
          // ;
          if (e != "_id") {
            this.filterdata[e]?.forEach((ele) => {
              if (typeof ele == "object") {
                if (!ele) return;

                ele.key = e;
                ele.checked = false;
              }
            });
          }
          if (e == "Product offering price") {
            this.filterdata[e]?.forEach((ele) => {
              if (ele.price.amount != "") {
                if (this.offeringprice.includes(ele.price.amount) != true) {
                  this.offeringprice.push(ele.price.amount);
                }
              }
            });
          }
        });

        // this.datacalling()

        // const alldata = await UPCAxiosInstance.get(
        //   `catalogManagement/productOffering/filterlist`
        // );
        // this.filterdata = alldata.data;
        // ;
        // let pricedata = this.filterdata['productOfferingPrice'][this.currentvalue]
        // if(pricedata.length > 1)
        // {
        //   this.filterprice.min = pricedata[0]-1
        // }else if(pricedata.length == 1){
        //    this.filterprice.min = 0
        // }
        // this.filterprice.max = pricedata[pricedata.length - 1]+1
        // this.sliderMin = this.filterprice.min
        // this.sliderMax = this.filterprice.max
        // this.filterprice.min = this.filterdata['productOfferingPrice'][0].min
        // this.sliderMin = this.filterprice.min - 1
        // this.filterprice.max = this.filterdata['productOfferingPrice'][0].max
        // this.sliderMax = this.filterprice.max - 1
        // ,typeof(this.filterprice.max));
      }
    },
    async switchfilterdatafunction(val) {
      if (val == "Product specifications") {
        await this.$store.dispatch("productSpecFilterData");

        this.filterdata = this.$store.getters.productSpecificationFilterData;
      } else {
        await this.$store.dispatch("productOfferFilterData");

        this.filterdata = this.$store.getters.productOfferingFilterData;
        Object.keys(this.filterdata).forEach((e) => {
          // ;
          if (e != "_id") {
            this.filterdata[e]?.forEach((ele) => {
              if (typeof ele == "object") {
                if (!ele) return;

                ele.checked = false;
              }
            });
          }
          if (e == "Product offering price") {
            this.filterdata[e]?.forEach((ele) => {
              if (ele.price.amount != "") {
                if (this.offeringprice.includes(ele.price.amount) != true) {
                  this.offeringprice.push(ele.price.amount);
                  // );
                }
              }
            });
          }
        });
      }
    },
    insertSpaces(string) {
      let str = string
        .replace(/(_|-)/g, " ")
        .trim()
        .replace(/\w\S*/g, function (str) {
          return str.charAt(0).toUpperCase() + str.substr(1);
        })
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
      return str;
    },
    mindata(val) {
      this.min = val;
    },
    maxdata(val) {
      this.max = val;
    },
    openNav() {
      //  document.getElementById("mySidepanel").style.width = "250px";
      document.getElementById("mySidepanel").style.visibility = "visible";
      console.log("filter side pannala");
    },
    closeNav() {
      document.getElementById("mySidepanel").style.visibility = "hidden";
    },
    addOffer() {
      // this.$router.push("/UPC/product-catalog-table/product_offering/add");
    },
    addSpec() {
      // this.add_model = true;
      // this.componentkey += 1;
      // this.payloadSpec = {
      //   name: "",
      //   category: "",
      //   validFor: {
      //     endOfLife: "",
      //     endOfDate: "",
      //   },
      //   relatedParty: [],
      //   unitOfMeasure: {
      //     name: "",
      //   },
      // };
      this.$router.push({ name: "CPQDefaultTemplate" });
    },
    change(val) {
      if (this.filtertagvalue.length == 0) {
        let obj = {};
        obj.value = val;
        this.filtertagvalue.push(obj);
      }
    },
    searchfilter($event, key) {
      this.searchfilterdata[key] = $event;
      this.searchfiltervalue = $event;
      if (
        this.searchfiltervalue &&
        this.searchfiltervalue != "" &&
        this.originaldata != undefined
      ) {
        // ;
        this.filterdat[key] = this.originaldata[key].filter((item) => {
          return item.value
            .toLowerCase()
            .toString()
            .includes(this.searchfiltervalue.toLowerCase());
        });
      } else {
        this.filterdat = JSON.parse(JSON.stringify(this.originaldata));
      }
    },
    selectedvalue($event, data) {
      let finaldata = [];
      //
      //
      let arr_data = $event;

      let arr = [];
      arr_data.forEach((e) => {
        if (e.checked == true) {
          //
          arr.push(e.value);
        }
      });
      this.filtervalueobj[data] = arr;

      Object.keys(this.filtervalueobj).forEach((e) => {
        finaldata = [];
        this.filtervalueobj[e].forEach((ele) => {
          this.tableData?.forEach((element) => {
            if (element[e] === ele) {
              finaldata.push(element);
            } else if (element[e] === null) {
              finaldata.push(element);
            }
          });
        });
      });
      this.tableData = finaldata;
      //
      if (Object.keys(this.filtervalueobj).length <= 1) {
        this.filterFunction();
        //
      } else {
        let x = 0;
        let y = 0;
        while (x < Object.keys(this.filterobj).length) {
          while (y < Object.keys(this.filtervalueobj).length) {
            if (
              Object.keys(this.filterobj)[x] !=
              Object.keys(this.filtervalueobj)[y]
            ) {
              var arr5 = [];
              let arr6 = [];
              let arr7 = [];
              finaldata.forEach((element) => {
                arr5.push(element[Object.keys(this.filterobj)[x]]);
              });
              arr5.forEach((element) => {
                if (!arr6.includes(element)) {
                  arr6.push(element);
                }
              });
              arr6.forEach((element) => {
                let obj = {};
                //
                // if(element != null)
                // {
                obj["value"] = element;
                obj["name"] = element;
                obj.checked = true;
                obj.disabled = false;
                arr7.push(obj);
              });
              this.filterobj[Object.keys(this.filterobj)] = arr7;
            }
            y++;
          }
          x++;
        }
      }
      this.filterobj[data] = $event;
    },
    closeRight() {
      this.show_right = false;
    },
    computedTableAction(data) {
      console.log("🚀 ~ computedTableAction ~ data:", data);
      let options = [
        {
          label: "Edit",
          icon: "icon-file-text-edit-regular",
        },
        {
          label: "Clone",
          icon: "icon-copy-alt-regular",
        },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
        // {
        //   label: "Send an email",
        //   icon: "icon-envelope-check-regular",
        // },
        {
          label: "Preview",
          icon: "icon-caret-left-circle-regular",
        },
      ];
      let updatedOptions = options.filter((option) => {
        if (
          data &&
          data.status &&
          data.status.toLowerCase() == "draft" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "active" &&
          ["Draft", "Mark as active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.state &&
          !["inprogress"].includes(data.state.toLowerCase()) &&
          ["Edit"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "inactive" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else {
          return true;
        }
      });
      console.log("🚀 ~ updatedOptions ~ updatedOptions:", updatedOptions);
      return updatedOptions;
    },
    getWidth(id) {
      const descriptionDiv = document.getElementById(id);
      if (descriptionDiv) {
        // //
        return descriptionDiv.offsetWidth + "px";
      }
    },
    applyColumns(val) {
      // ;
      this.duplicateTheads = [];
      val.forEach((e) => {
        if (e.checked === true) {
          this.duplicateTheads.push(e);
        }
      });
      if (this.view === "Product offerings") {
        this.product_offerings_theads = [...this.duplicateTheads];
      } else if (this.view === "Product specifications") {
        this.quoteThead = [...this.duplicateTheads];
      }
    },
    updateTheads() {
      if (this.view === "Product offerings") {
        // Use the map function to update the 'checked' property
        this.product_offerings_theads_cc = this.product_offerings_theads_cc.map(
          (thead) => {
            // Check if the current thead has a matching 'prop' in duplicateTheads
            const isMatching = this.product_offerings_theads.some(
              (duplicateThead) => duplicateThead.prop === thead.prop
            );

            // Set the 'checked' property based on whether it's matching
            return { ...thead, checked: isMatching };
          }
        );
      } else if (this.view === "Product specifications") {
        this.quoteTheadC = this.quoteTheadC.map((thead) => {
          // Check if the current thead has a matching 'prop' in duplicateTheads
          const isMatching = this.quoteThead.some(
            (duplicateThead) => duplicateThead.prop === thead.prop
          );

          // Set the 'checked' property based on whether it's matching
          return { ...thead, checked: isMatching };
        });
      }
    },
    arrange(val) {
      // ;
      this.re_arrange = val;
      this.updateTheads();
    },
    convertdateformat(date1) {
      if (date1 != null) {
        let objectDate = new Date(date1);
        if (isNaN(objectDate) == false) {
          let date = objectDate.toLocaleString("default", { day: "numeric" });
          let month = "";
          let format = this.$store.state.defaultSetting.dateFormat;
          if (
            format == "Month DD, YYYY" ||
            format == "mmmm dd, yyyy" ||
            format == "mmmm dd, yyyy"
          ) {
            month = objectDate.toLocaleString("default", { month: "long" });
          } else {
            month = objectDate.toLocaleString("default", { month: "short" });
          }
          let year = objectDate.getFullYear();
          let dateFormat = this.dateFormat(date, month, year, format);
          // let dateformat = month + " " + date + ", " + year;
          return dateFormat;
        }
      }
    },
    async refreshTable() {
      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.quoteFilterOption
      );

      this.tableData = filterResultData.data.data;
      this.totalpageno = filterResultData.data.total;
      this.paginatedData;
    },
    async closeCrudContextMenu($event, ele, i) {
      // //

      if ($event.toLowerCase() === "edit") {
        this.$router.push({
          name: "CPQDefaultTemplate",
          query: { quote_id: ele._id, mode: "edit" },
        });
      } else if ($event.toLowerCase() === "clone") {
        delete ele._id;
        const clonedata = await UPCAxiosInstance.post(
          `/catalogManagement/productSpecification/clone`,
          ele
        );
        this.refreshTable();
        console.log(clonedata);
        if (clonedata.status == 201) {
          this.notification = {
            type: "success",
            message: `Nice work! Your product specification ${ele.name} is cloned!`,
          };
        }
      } else if ($event.toLowerCase() == "delete") {
        this.delete_modal_active = true;
        this.deleteobj = ele;
        this.delete_name = ele.name;
        this.deleterowindex = i;
      } else if ($event && $event.toLowerCase() == "preview") {
        let quoteInfo = ele;
        console.log("🚀 ~ closeCrudContextMenu ~ quoteInfo:", quoteInfo);
        this.$router.push({
          name: "previewTemplate",
          query: { quote_id: quoteInfo._id },
        });
      }
    },
    viewPage(i) {
      if (!i || (i && i.state?.toLowerCase() !== "inprogress")) return;
      this.$router.push({
        name: "CPQDefaultTemplate",
        query: { quote_id: i._id, mode: "edit" },
      });
      return;
    },
    async closeCrudContext(i) {
      // //
      if (i.type.toLowerCase() == "rule") {
        this.$router.push(`/DMN/rule_studio/logical_rule/${i["_id"]}`);

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/rule/${i["_id"]}`;

        // this.$router.push({ name: 'rule-edit', params: { id: i["_id"] } })
      } else if (i.type.toLowerCase() == "expression") {
        this.$router.push(`/DMN/rule_studio/expression/${i["_id"]}`);

        // this.$router.push({ name: 'expression-edit', params: { id: i["_id"] } })
        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/expression/${i["_id"]}`;
      } else if (i.type.toLowerCase() == "decision") {
        // this.decision_name = i.name;
        // const alldata = await DMNAxiosInstance.get(
        //   `rule_engine/rule?id=${this.id}&name=${this.name}&type=decision`,
        //   {
        //     auth: {
        //       username: "testuser@test.com",
        //       password: " ",
        //     },
        //   }
        // );
        // //
        // let obj = await alldata.data.data.find(
        //   (item) => item.name === this.decision_name
        // );
        //
        // this.$router.push({ name: 'decision-edit', params:{ id: obj["_id"],token:$}})

        this.$router.push(
          `/DMN/rule_studio/decision_table/edit/${i.decisionId}`
        );

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/decision_table/${obj["_id"]}`;
      }
    },

    async deleteExecRow() {
      this.delete_modal_active = false;
      let i = this.deleteobj;
      const deleteOffering = await UPCAxiosInstance.delete(
        `catalogManagement/productSpecification/${i._id}`
      );
      deleteOffering;
      // if (deleteOffering.data.name == i.name) {
      // var offer3 = await UPCAxiosInstance.get(`catalogManagement/productSpecification`)
      // this.totalpageno = offer3.data.length
      // this.tableData = [];
      // var offer = await UPCAxiosInstance.get(
      //   `catalogManagement/productSpecification?pageNo=${this.currPagePS}&size=${this.rowsPerPagePS}`
      // );
      // this.tableData = offer.data;
      this.SpecificationcurrPage = 1;
      this.specificationdataCalling(
        this.SpecificationcurrPage,
        this.SpecificationrowsPerPage
      );

      let logupc = {
        userName: this.userInfo.name,
        userId: this.userInfo._id,
        name: `${i.name} product specification edited`,
        description: `${i.name} product specification edited`,
      };
      // const activity =
      await UPCAxiosInstance.post(`/activityLog`, logupc);

      this.notification = {
        type: "success",
        message: `Done! ${i.name} has been removed.`,
      };
      // }
      // this.activatemodalactive(i);
      i.context = false;
      // this.refreshTable();
    },
    crudContextMenu($event, index) {
      // ;
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;

        //
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    async handleCurrentChange(val) {
      //
      // this.filterpanelname == val.name;
      switch (val) {
        case "Product specifications":
          // this.SpecificationcurrPage = 1;
          // this.SpecificationrowsPerPage = 10;
          this.tableData = [];
          this.specificationdataCalling(
            this.SpecificationcurrPage,
            this.SpecificationrowsPerPage
          );
          this.filterPanelName = "productSpecification";
          this.view = val;

          break;
        case "Product offerings":
          this.tableData = [];
          // this.OfferingcurrPage = 1;
          // this.OfferingrowsPerPage = 10;
          this.offeringdataCalling(
            this.OfferingcurrPage,
            this.OfferingrowsPerPage
          );
          this.filterPanelName = "productOffering";
          this.view = val;

          break;
      }
      this.duplicatetabledata = this.tableData;
      this.searchtabledata = this.tableData;
      // //
    },
    serialNumber(index) {
      return (
        (this.SpecificationcurrPage - 1) * this.SpecificationrowsPerPage +
        index +
        1
      );
    },
    async currentPage(val) {
      this.SpecificationcurrPage = val;
      this.specificationdataCalling(
        this.SpecificationcurrPage,
        this.SpecificationrowsPerPage
      );
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    async changeRowsPerPage(count) {
      this.SpecificationrowsPerPage = count;
      this.specificationdataCalling(
        this.SpecificationcurrPage,
        this.SpecificationrowsPerPage
      );
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    resizewidthdata(data1, data2) {
      data1;
      data2;
      //
    },
    sorting_Data(data, data1) {
      //
      if (data === "icon-angle-up-small-filled sorting-icon") {
        if (this.view == "Product offerings") {
          //  console.log('fjd',this.offeringfilterquery);
          this.offeringfilterquery.type = "filter";
          this.offeringfilterquery.isNeedToBeSort = true;
          if (data1 === "name") {
            this.offeringfilterquery.sortFieldAndOrder = { name: 1 };
          } else if (data1 == "description") {
            this.offeringfilterquery.sortFieldAndOrder = { description: 1 };
          } else if (data1 == "lifecycleStatus") {
            this.offeringfilterquery.sortFieldAndOrder = { lifecycleStatus: 1 };
          } else if (data1 == "offerType") {
            this.offeringfilterquery.sortFieldAndOrder = { offerType: 1 };
          } else if (data1 == "lastUpdate") {
            this.offeringfilterquery.sortFieldAndOrder = { lastUpdate: 1 };
          } else if (data1 == "place") {
            this.offeringfilterquery.sortFieldAndOrder = { "place.name": 1 };
          } else if (data1 == "channel") {
            this.offeringfilterquery.sortFieldAndOrder = { "channel.name": 1 };
          } else if (data1 == "category") {
            this.offeringfilterquery.sortFieldAndOrder = { "category.name": 1 };
          } else if (data1 == "marketSegment") {
            this.offeringfilterquery.sortFieldAndOrder = {
              "marketSegment.name": 1,
            };
          }
          this.OfferingcurrPage = 1;
          this.offeringdataCalling(
            this.OfferingcurrPage,
            this.OfferingrowsPerPage
          );
        } else if (this.view == "Product specifications") {
          this.quoteFilterOption.type = "filter";
          this.quoteFilterOption.isNeedToBeSort = true;
          if (data1 === "name") {
            this.quoteFilterOption.sortFieldAndOrder = { name: 1 };
          } else if (data1 === "description") {
            this.quoteFilterOption.sortFieldAndOrder = { description: 1 };
          } else if (data1 == "lifecycleStatus") {
            this.quoteFilterOption.sortFieldAndOrder = { lifecycleStatus: 1 };
          } else if (data1 == "lastUpdate") {
            this.quoteFilterOption.sortFieldAndOrder = { lastUpdate: 1 };
          } else if (data1 == "resourceSpecification") {
            this.quoteFilterOption.sortFieldAndOrder = {
              "resourceSpecification.name": 1,
            };
          } else if (data1 == "serviceSpecification") {
            this.quoteFilterOption.sortFieldAndOrder = {
              "serviceSpecification.name": 1,
            };
          } else if (data1 == "relatedParty") {
            this.quoteFilterOption.sortFieldAndOrder = {
              "relatedParty.name": 1,
            };
          }

          this.SpecificationcurrPage = 1;
          this.specificationdataCalling(
            this.SpecificationcurrPage,
            this.SpecificationrowsPerPage
          );
        }
      } else if (data === "icon-angle-down-small-filled sorting-icon") {
        // console.log('sort-down',data1);
        if (this.view == "Product offerings") {
          //  console.log('fjd',this.offeringfilterquery);
          this.offeringfilterquery.type = "filter";
          this.offeringfilterquery.isNeedToBeSort = true;
          if (data1 === "name") {
            this.offeringfilterquery.sortFieldAndOrder = { name: -1 };
          } else if (data1 == "description") {
            this.offeringfilterquery.sortFieldAndOrder = { description: -1 };
          } else if (data1 == "lifecycleStatus") {
            this.offeringfilterquery.sortFieldAndOrder = {
              lifecycleStatus: -1,
            };
          } else if (data1 == "offerType") {
            this.offeringfilterquery.sortFieldAndOrder = { offerType: -1 };
          } else if (data1 == "lastUpdate") {
            this.offeringfilterquery.sortFieldAndOrder = { lastUpdate: -1 };
          } else if (data1 == "place") {
            this.offeringfilterquery.sortFieldAndOrder = { "place.name": -1 };
          } else if (data1 == "channel") {
            this.offeringfilterquery.sortFieldAndOrder = { "channel.name": -1 };
          } else if (data1 == "category") {
            this.offeringfilterquery.sortFieldAndOrder = {
              "category.name": -1,
            };
          } else if (data1 == "marketSegment") {
            this.offeringfilterquery.sortFieldAndOrder = {
              "marketSegment.name": -1,
            };
          }
          this.OfferingcurrPage = 1;
          this.offeringdataCalling(
            this.OfferingcurrPage,
            this.OfferingrowsPerPage
          );
        } else if (this.view == "Product specifications") {
          this.quoteFilterOption.type = "filter";
          this.quoteFilterOption.isNeedToBeSort = true;
          if (data1 === "name") {
            this.quoteFilterOption.sortFieldAndOrder = { name: -1 };
          } else if (data1 === "description") {
            this.quoteFilterOption.sortFieldAndOrder = { description: -1 };
          } else if (data1 == "lifecycleStatus") {
            this.quoteFilterOption.sortFieldAndOrder = { lifecycleStatus: -1 };
          } else if (data1 == "lastUpdate") {
            this.quoteFilterOption.sortFieldAndOrder = { lastUpdate: -1 };
          } else if (data1 == "resourceSpecification") {
            this.quoteFilterOption.sortFieldAndOrder = {
              "resourceSpecification.name": -1,
            };
          } else if (data1 == "serviceSpecification") {
            this.quoteFilterOption.sortFieldAndOrder = {
              "serviceSpecification.name": -1,
            };
          } else if (data1 == "relatedParty") {
            this.quoteFilterOption.sortFieldAndOrder = {
              "relatedParty.name": -1,
            };
          }

          this.SpecificationcurrPage = 1;
          this.specificationdataCalling(
            this.SpecificationcurrPage,
            this.SpecificationrowsPerPage
          );
        }
      } else if (data === "icon-angle-up-small-filled") {
        if (this.view == "Product offerings") {
          //  console.log('fjd',this.offeringfilterquery);
          this.offeringfilterquery.type = "filter";
          this.offeringfilterquery.isNeedToBeSort = false;
          this.offeringfilterquery.sortFieldAndOrder = {};
          this.OfferingcurrPage = 1;
          this.offeringdataCalling(
            this.OfferingcurrPage,
            this.OfferingrowsPerPage
          );
        } else if (this.view == "Product specifications") {
          this.quoteFilterOption.type = "filter";
          this.quoteFilterOption.isNeedToBeSort = false;
          this.quoteFilterOption.sortFieldAndOrder = {};
          this.SpecificationcurrPage = 1;
          this.specificationdataCalling(
            this.SpecificationcurrPage,
            this.SpecificationrowsPerPage
          );
        }
      } else if (data === "icon-angle-down-small-filled") {
        if (this.view == "Product offerings") {
          //  console.log('fjd',this.offeringfilterquery);
          this.offeringfilterquery.type = "filter";
          this.offeringfilterquery.isNeedToBeSort = false;
          this.offeringfilterquery.sortFieldAndOrder = {};
          this.OfferingcurrPage = 1;
          this.offeringdataCalling(
            this.OfferingcurrPage,
            this.OfferingrowsPerPage
          );
        } else if (this.view == "Product specifications") {
          this.quoteFilterOption.type = "filter";
          this.quoteFilterOption.isNeedToBeSort = false;
          this.quoteFilterOption.sortFieldAndOrder = {};
          this.SpecificationcurrPage = 1;
          this.specificationdataCalling(
            this.SpecificationcurrPage,
            this.SpecificationrowsPerPage
          );
        }
      }
    },
    searchKey(val) {
      this.searchvalue = val;
      this.quoteFilterOption["type"] = "search";
      this.quoteFilterOption["searchQuery"] = this.searchvalue;
      this.SpecificationcurrPage = 1;
      this.SpecificationrowsPerPage = 10;
      this.specificationdataCalling(
        this.SpecificationcurrPage,
        this.SpecificationrowsPerPage
      );
    },
    fromAddContextMenu(val) {
      if (val === "Decision tables") {
        this.$router.push("/DMN/rule_studio/decision_table/add");

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/decision_table/add`;
      } else if (val === "Expression") {
        this.$router.push("/DMN/rule_studio/expression");

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/expression`;
      } else if (val === "Logical rule") {
        this.$router.push("/DMN/rule_studio/logical_rule");

        // window.location.href = window.location.protocol+"//"+window.location.hostname +':'+`8081/DMN/business_rules/rule`;
      }
    },
  },
};
</script>
<style lang="scss">
.QuoteLPtileDiv{
  margin: 0px 26px; background: none;

  .QuoteLPtileSpan{
    display: flex;
    width: 100%;
    margin: 13px 0px;
    background: none;
    justify-content: flex-start;
    gap: 25px;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

  }
}

.mainpagedivisions {
  width: 200px;
  border-radius: 5px;
  height: 133px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  background: #F8F8F8;
  transition: box-shadow 0.3s ease;

  .newstatus {
    font-size: 16px;
    font-weight: 600;
    color:#757D8A;
    padding: 1px 14px;
  }

  .newprice {
    font-size: 22px;
    font-weight: 400;
    color:#404D61;
    padding: 1px 13px;
    padding-top: 7px;
  }

  .newvalue {
    font-size: 14px;
    font-weight: 400;
    color:#404D61;
    padding: 1px 15px;
  }
  &.firstvalueitem { 
    background: #54BD95;
    .newstatus {
      color: white;
    }

    .newprice {
      color: white;
    }

    .newvalue {
      color: white;
    }
  }
}

.mainpagedivisions:hover{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.halleyxNeonPArent{
  margin: 13px 0px;
  display:flex;
  justify-content: space-between;
}

@media (max-width: 1265px) {
  .halleyxNeonPArent {
    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 16px;
  }
}

//.active-borderDashboardQuote{
  //border: #54BD95 solid 2px;
  // #757D8A
// }
// .active-divOneForDashboardQuote{
//   border:2px solid #757D8A;
// }
</style>