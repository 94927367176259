<template>
  <div class="nonresponsive-leftpannel">
    <div class="leftpannal-sidebar">
      <div class="sidebar-v2-partitions-holder">
        <div v-for="part in filteredSideBarData" :key="part" class="sidebar-v2-partitions">
          <div v-for="p in part" :key="p">
            <div
              v-if="p.view"
              class="sidebar-v2-partitions-items hover-leftpannel"
              :class="p.active ? 'sidebar-v2-active-main' : ''"
              @click.stop="newGotoFunc(p)"
            >
              <span class="leftpannel-box" style="cursor: pointer">
                <span class="leftpannel-icone" style="cursor: pointer">
                  <i :class="p.icon" :style="{ color: p.active ? '#54bd95' : '' }"></i>
                </span>
  
                <span style="cursor: pointer" :style="{ color: p.active ? '#fff' : '' }">
                  {{ p?.customName || p?.name }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
     <span class="responsive-span-sm"
                    ><i
                      class="icon-menu-regular responsive-menuicon-sm"
                      @click="show_leftpannal_responsive = true"
                    ></i
                  ></span>
     <hlx-drawer
          :title="'Filter panel'"
          :show="show_leftpannal_responsive"
          :width="300"
          position="left"
          :footer="false"
          :click-outside-to-close="true"
          @close="show_leftpannal_responsive = false"
        >
          <template #body>
              <div style="margin-top: 19px; margin-left: 80px" class="leftpannal-sidebar">
      <div class="sidebar-v2-partitions-holder">
        <div v-for="part in filteredSideBarData" :key="part" class="sidebar-v2-partitions">
          <div v-for="p in part" :key="p">
            <div
              v-if="p.view"
              class="sidebar-v2-partitions-items hover-leftpannel"
              :class="p.active ? 'sidebar-v2-active-main' : ''"
              @click.stop="newGotoFunc(p)"
            >
              <span class="leftpannel-box" style="cursor: pointer">
                <span class="leftpannel-icone" style="cursor: pointer">
                  <i :class="p.icon" :style="{ color: p.active ? '#54bd95' : '' }"></i>
                </span>
  
                <span style="cursor: pointer" :style="{ color: p.active ? '#fff' : '' }">
                  {{ p?.customName || p?.name }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
          </template>
        </hlx-drawer>
</template>

<script>
export default {
  props: {
    sideBarData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ["value-name"],
  data() {
    return {
      selectedButton: "",
      show_leftpannal_responsive:false,
      
    };
  },
  computed: {
    filteredSideBarData() {
  // Check if sideBarData.main is available and then filter
  return {
    main: this.sideBarData.main && Array.isArray(this.sideBarData.main)
      ? this.sideBarData.main.filter(item => item.view)
      : []
  };
}

  },
  mounted() {
  this.$nextTick(() => {
    const storedButton = this.$cookies.get("selectedButton");

    // Set up the router hook
    this.$router.beforeEach((to, from, next) => {
      this.clearSelectedButton();
      next();
    });

    // Process the sidebar data when mounted
    this.checkAndProcessSideBarData(storedButton);
  });
},
methods: {
  clearSelectedButton() {
    this.selectedButton = "";
    this.$cookies.set("selectedButton", "");
  },
  checkAndProcessSideBarData(storedButton) {
    const processData = () => {
      if (this.sideBarData && this.sideBarData.main && this.sideBarData.main.length > 0) {
        const isValidButton = this.sideBarData.main.some(
          (item) => item.name === storedButton && item.view
        );

        // Select the first valid item (view: true) if storedButton is invalid
        const buttonToSelect = isValidButton
          ? storedButton
          : this.sideBarData.main.find((item) => item.view)?.name;

        this.processSideBarData(buttonToSelect);
      } else {
        // Retry after a delay if sideBarData is not yet loaded
        setTimeout(processData, 100); // Adjust delay as needed
      }
    };

    processData();
  },

  processSideBarData(selectedButton) {
    if (selectedButton) {
      this.selectedButton = selectedButton;
      this.$emit("value-name", this.selectedButton, this.sideBarData.main.find(item => item.name === this.selectedButton) || {}
      );
      this.sideBarData.main.forEach((item) => {
        item.active = item.name === this.selectedButton;
      });
    }
    console.log(this.sideBarData.main, "Processed SideBarData", selectedButton);
  },

  newGotoFunc(navItem) {
    this.selectedButton = navItem.name;
    this.$cookies.set("selectedButton", navItem.name);
    this.$emit("value-name", this.selectedButton,navItem);
    this.show_leftpannal_responsive = false;
    this.sideBarData.main.forEach((item) => {
      item.active = item.name === this.selectedButton;
    });
  },
}



};
</script>











<style lang="scss" scoped>
  .leftpannal-sidebar {
    .sidebar-v2-active-main,
    .sidebar-v2-active-child {
      background-color: #54bd95;
      border-radius: 5px;
  
      &:hover {
        background: #54bd95 !important;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  
    .hover-leftpannel:hover {
      border-radius: 5px;
      background-color: #a8dec9;
    }
  
    .sidebar-v2-partitions {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  
    .leftpannel-box {
      display: flex;
      gap: 8px;
      justify-content: left;
      align-items: center;
      padding: 8px;
      font-size: 14px;
  
      .leftpannel-icone {
        width: 20px;
        height: 20px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px 2px 2px 2px;
        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2509803922);
        border-radius: 3px;
      }
    }
  }
   .nonresponsive-leftpannel{
      display: block !important;
    }
  @media screen and (max-width: 768px) {
    .nonresponsive-leftpannel{
      display: none !important;
    }
    .responsive-breadcrumbs-sm {
      display: block;
    }
    .responsiveSM {
      display: none !important;
    }
    .responsiveSM-right-portion {
      padding: 0 20px 1.8em 20px !important;
      // padding-top: none !important;
      min-width: 100% !important;
    }
    .address-content-sm {
      width: 400px;
    }
    .responsive-menuicon-sm {
      display: block;
      font-size: 24px !important;
    }
    .responsive-span-sm {
      display: block;
    }
  }
  </style>