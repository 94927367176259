<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <section style="display: flex; flex-direction: column">
    <main
      class="decision-add-container --layout-3-way"
      style="overflow-y: hidden !important"
      :style="
        isRightLayoutOpened
          ? 'display: grid;grid-template-columns: 300px calc(100% - 600px) 300px'
          : ''
      "
    >
      <!-- -------------------------------------------Left pannel-------------------------------------------------- -->
      <section class="cm-fields-left-panel">
        <!-- Breadcrumb -->
        <div class="layout-breadcrumb">
          <hlx-breadcrumb :items="breadCrumbItems" @path="goTo()" />
        </div>
        <div class="decision-info-left-pannel">
          <span
            class="title decision-title section-title"
            style="margin-top: 0.5rem"
            >Add new account</span
          >

          <div
            class="decision-name-wrapper"
            :style="{ 'margin-bottom': customnameerror ? '-7.6%' : 0 }"
          >
            <hlx-input
              v-model:value="payload.name"
              :pre-val="payload.name"
              :label-value="'Account name'"
              :label-animation="true"
              :required="true"
              :display-error="customnameerror"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
          <div class="decision-name-wrapper">
            <hlx-input
              v-model:value="payload.contactMedium[3].description"
              :pre-val="payload.contactMedium[3].description"
              type="location"
              :params="locationApi"
              :label-value="'Address'"
              :label-animation="true"
              :show-new-address-option="showNewAddressOption"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              :location-data-prop="locationData"
              @focus-out="focusOutName"
              @at-input="getLocations"
              @add-new-location="addNewLocation"
            ></hlx-input>
          </div>
          <div class="decision-name-wrapper">
            <!-- <hlx-select
              v-model="payload.status"
              :pre-value="payload.status"
              :options="statusList"
              prop-value="name"
              :inline-search="true"
              :search-image-tag="false"
              :placeholder-value="'Status'"
              :label-animation="true"
              :clearable="true"
              @search-value="newValFromDropDown($event, 'status')"
            ></hlx-select> -->

            <div style="width: 100%">
              <hlx-status
                :type="'select-status'"
                :status-option="filteredstatus"
                :prevalue="payload.status"
                @emit-select="emitStatus1"
              >
              </hlx-status>
            </div>
          </div>
          <div class="decision-name-wrapper">
            <hlx-input
              v-model:value="payload.contactMedium[1].phoneNumber"
              :pre-val="payload.contactMedium[1].phoneNumber"
              type="number"
              :label-value="'Phone no.'"
              :label-animation="true"
              :display-error="customnameerror"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
          <div class="decision-name-wrapper">
            <hlx-input
              v-model:value="payload.contactMedium[0].emailAddress"
              :pre-val="payload.contactMedium[0].emailAddress"
              type="email"
              :label-value="'Email id'"
              :required="true"
              :label-animation="true"
              :display-error="customnameerror"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
          <div class="decision-name-wrapper">
            <hlx-input
              v-model:value="payload.contactMedium[2].faxNumber"
              :pre-val="payload.contactMedium[2].faxNumber"
              :label-value="'Fax'"
              :label-animation="true"
              :display-error="customnameerror"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
          <div class="decision-name-wrapper">
            <hlx-input
              v-model:value="payload.externalReference[0].name"
              :pre-val="payload.externalReference[0].name"
              :label-value="'Website'"
              :label-animation="true"
              :display-error="customnameerror"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
          <div class="decision-name-wrapper">
            <hlx-select
              v-model:native="payload.preferred"
              :pre-value="payload.preferred"
              :options="preferredMediumList"
              prop-value="value"
              :placeholder-value="'Preferred contact medium'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <div class="decision-name-wrapper">
            <hlx-select
              v-model:value="payload.accountExecutive"
              :pre-value="payload.accountExecutive"
              :options="executivesList"
              :placeholder-value="'Account executive'"
              :label="'name'"
              :prop-value="'name'"
              :label-animation="true"
              :search-image-tag="true"
              :sub-title="'company'"
              :tag-value="'role'"
              :image-link="'link'"
              :inline-search="true"
              @selected-value="selectedExecutive"
            >
            </hlx-select>
          </div>
          <div class="decision-name-wrapper">
            <hlx-select
              v-model:value="payload.partyCharacteristic[1].value"
              :pre-value="payload.partyCharacteristic[1].value"
              :options="industryTypeList"
              prop-value="name"
              :placeholder-value="'Industry'"
              :label-animation="true"
              :clearable="true"
              :inline-search="true"
              :search-image-tag="false"
              @search-value="newValFromDropDown($event, 'industry')"
            ></hlx-select>
          </div>
          <div class="decision-name-wrapper">
            <hlx-select
              v-model:value="payload.partyCharacteristic[0].value"
              :pre-value="payload.partyCharacteristic[0].value"
              :options="employeeCountList"
              prop-value="name"
              :placeholder-value="'Employees'"
              :label-animation="true"
              :clearable="true"
              :inline-search="true"
              :search-image-tag="false"
              @search-value="newValFromDropDown($event, 'employeeCount')"
            ></hlx-select>
          </div>
          <div class="decision-name-wrapper">
            <hlx-select
              v-model:value="payload.contactMedium[3]['@type']"
              :pre-value="payload.contactMedium[3]['@type']"
              :options="locationTypeList"
              prop-value="name"
              :placeholder-value="'Location type'"
              :label-animation="true"
              :clearable="true"
              :inline-search="true"
              :search-image-tag="false"
              @search-value="newValFromDropDown($event, 'locationType')"
            ></hlx-select>
          </div>
          <div class="decision-name-wrapper">
            <hlx-multi-select
              v-model:value="payload.tags"
              :pre-value="payload.tags"
              :options="tagsList"
              prop-value="name"
              :multi-select="true"
              :placeholder="'Tags'"
              :label-animation="true"
              :clearable="true"
              @add-data="addTagList"
              @selected-value="receivedTags"
            ></hlx-multi-select>
          </div>
        </div>
      </section>

      <!-- ------------------------------------------Centre pannel------------------------------------------------- -->
      <section
        class="--layout-inner-right-portion --RL70"
        style="padding: 1.8em 20px"
        :style="
          isRightLayoutOpened === false
            ? 'width:calc(100% - 300px)'
            : 'width:100%'
        "
      >
        <div class="decision-info-right-pannel" style="overflow: hidden">
          <hlx-switch
            v-model="selectedValue"
            :switch-items="tabItems"
            :tab-view="true"
            value-prop="value"
            display-prop="name"
            @change="handleCurrentChange"
          />
          <div class="body-container-center">
            <div
              v-if="selectedValue.toLowerCase() === 'overview'"
              class="cm-accounts-overview-component-container"
            >
              <overViewComponent></overViewComponent>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'hierarchy'"
              class="cm-accounts-overview-component-container"
            >
              <hierarchyComponent></hierarchyComponent>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'contacts'"
              class="cm-accounts-overview-component-container"
            >
              <contactComponent
                :data="recievedContactData"
                @contact-data="contactData"
              ></contactComponent>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'interaction'"
              class="cm-accounts-overview-component-container"
            >
              <interactionComponent
                @interaction-payload="receivedInteractionPayload"
              ></interactionComponent>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'rules'"
              class="cm-accounts-overview-component-container"
            >
              <!-- <img src="@/assets/images/addAccountContacts.svg" alt=""> -->
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'quotes and orders'"
              class="cm-accounts-overview-component-container"
            >
              <quoteAndOrdersComponent></quoteAndOrdersComponent>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'approvals'"
              class="cm-accounts-overview-component-container"
            >
              <approvalsComponent></approvalsComponent>
            </div>
          </div>
        </div>
      </section>
      <!-- ---------------------------------------collapsible right panel------------------------------------------ -->
    </main>

    <div
      class="layout-footer"
      style="
        border-top: 1px solid #d8d8d8;
        padding-right: 40px;
        display: flex;
        align-items: center;
        height: 10vh;
        margin-right: -16px;
        margin-left: -16px;
      "
    >
      <div
        class="go-back"
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <hlx-button class="secondary sm" @click="$router.back()"
          >Back</hlx-button
        >
        <hlx-button
          class="primary sm"
          :disabled="payload.name === '' ? true : false"
          style="margin-left: 20px"
          @click="addNewAccount"
          >Add</hlx-button
        >
      </div>
    </div>
  </section>

  <hlx-modal
    :modal-active="showAddNewAdrressModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'400px'"
    :width="'650px'"
    :modal="true"
    @close="closeAddNewAdrressModal"
  >
    <template #header>
      <div>Add new address</div>
    </template>
    <template #content>
      <div
        class="modal-content add-contact-modal"
        style="
          padding: 20px;
          padding-bottom: 0;
          grid-template-columns: 38.5% 58.5%;
        "
      >
        <span class="left-section">
          <hlx-input
            v-model:value="newAddressPayload.streetNumber"
            :pre-val="newAddressPayload.streetNumber"
            :label-value="'Street number'"
            :label-animation="true"
            :required="true"
            :display-error="true"
            :custom-error="isFirstNameEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            v-model:value="newAddressPayload.unitNumber"
            :pre-val="newAddressPayload.unitNumber"
            :label-value="'Unit number'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isLastNameEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <div class="input-wrapper">
            <hlx-input
              :key="key"
              v-model:value="newAddressPayload.city"
              :pre-val="newAddressPayload.city"
              :options="designationList"
              :required="true"
              prop-value="name"
              :label-value="'City'"
              :placeholder-value="'City'"
              :label-animation="true"
              :clearable="true"
            ></hlx-input>
          </div>
          <div class="input-wrapper">
            <hlx-input
              v-model:value="newAddressPayload.postalCode"
              :pre-val="newAddressPayload.postalCode"
              :label-value="'Postal code'"
              :label-animation="true"
              :required="true"
              :display-error="true"
              :custom-error="isLastNameEmpty"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
        </span>
        <span class="right-section">
          <hlx-input
            v-model:value="newAddressPayload.streetName"
            :pre-val="newAddressPayload.streetName"
            :label-value="'Street name'"
            :label-animation="true"
            :required="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            v-model:value="newAddressPayload.unitName"
            :pre-val="newAddressPayload.unitName"
            :label-value="'Unit name'"
            :label-animation="true"
            :required="false"
            :display-error="true"
            :custom-error="customnameerror"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <div class="input-wrapper">
            <div style="display: flex; gap: 0.5rem">
              <hlx-input
                :key="key"
                v-model:value="newAddressPayload.state"
                :pre-val="newAddressPayload.state"
                :options="preferredList"
                :label-value="'State'"
                prop-value="value"
                :placeholder-value="'State'"
                :label-animation="true"
                :required="true"
                :clearable="true"
              ></hlx-input>
              <hlx-input
                :key="key"
                v-model:value="newAddressPayload.country"
                :pre-val="newAddressPayload.country"
                :options="preferredList"
                prop-value="value"
                :label-value="'Country'"
                :placeholder-value="'Country'"
                :required="true"
                :label-animation="true"
                :clearable="true"
              ></hlx-input>
            </div>
          </div>
          <hlx-input
            v-model:value="newAddressPayload.landmark"
            :pre-val="newAddressPayload.landmark"
            :label-value="'Nearby landmark'"
            :label-animation="true"
          ></hlx-input>
        </span>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="closeAddNewAdrressModal"
        >cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        :disabled="
          newAddressPayload.city === '' ||
          newAddressPayload.country === '' ||
          newAddressPayload.state === '' ||
          newAddressPayload.postalCode === '' ||
          newAddressPayload.streetNumber === '' ||
          newAddressPayload.streetName === ''
        "
        @click="addNewAddress"
        >Add</hlx-button
      >
    </template>
  </hlx-modal>
</template>

<script>
import {
  DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";

import overViewComponent from "@/components/CM/addAccountOverviewComponent.vue";
import hierarchyComponent from "@/components/CM/addAccountHierarchyComponent.vue";
import contactComponent from "@/components/CM/addAccountContactComponent.vue";
import interactionComponent from "@/components/CM/addAccountInteractionComponent.vue";
import quoteAndOrdersComponent from "@/components/CM/addAccountQuoteAndOrderComponent.vue";
import approvalsComponent from "@/components/CM/addAccountApprovalsComponent.vue";

export default {
  components: {
    overViewComponent,
    hierarchyComponent,
    contactComponent,
    interactionComponent,
    quoteAndOrdersComponent,
    approvalsComponent,
  },
  data() {
    return {
      filteredstatus: [],
      showNewAddressOption: false,
      interactionData: {},
      notification: {},
      locationTypeList: [],
      employeeCountList: [],
      preferredMediumList: [],
      industryTypeList: [],
      statusList: [],
      tagsList: [],
      locationApi: "https://appbackend-ghubgwxrcq-pd.a.run.app/autocomplete",
      isRightLayoutOpened: false,
      breadCrumbItems: [
        {
          label: "Accounts",
          link: `/CM/accounts`,
        },
        { label: "Add account", link: "" },
      ],
      customnameerror: false,
      payload: {
        name: "",
        tradingName: "",
        status: "",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            emailAddress: "",
          },
          {
            mediumType: "phone",
            phoneNumber: "",
          },
          {
            mediumType: "fax",
            faxNumber: "",
          },
          {
            mediumType: "postalAddress",
            city: "",
            country: "",
            postCode: "",
            stateOrProvince: "",
            street1: "",
            street2: "",
            description: "",
            "@type": "",
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        relatedParty: [
          {
            role: "director",
            partyOrPartyRole: {
              id: "42",
              name: "Feroz Khan",
              "@referredType": "Individual",
            },
          },
        ],
        tags: ["Active"],
        role: "Root admin",
        type: "company",
        password: "",
        email: "",
        organizationParentRelationship: {},
        rootName: "",
      },
      contactPayload: {
        name: "",
        tradingName: "",
        companyName: "",
        status: "",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            emailAddress: "",
          },
          {
            mediumType: "phone",
            phoneNumber: "",
          },
          {
            mediumType: "fax",
            faxNumber: "",
          },
          {
            mediumType: "postalAddress",
            city: "",
            country: "",
            postCode: "",
            stateOrProvince: "",
            street1: "",
            street2: "",
            description: "",
            "@type": "",
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        relatedParty: [
          {
            role: "director",
            partyOrPartyRole: {
              id: "42",
              name: "Feroz Khan",
              "@referredType": "Individual",
            },
          },
        ],
        tags: ["Active"],
        role: "Root admin",
        type: "individual",
        password: "",
        email: "",
      },
      selectedValue: "Overview",
      tabItems: [
        { name: "Overview", value: "Overview" },
        { name: "Hierarchy", value: "Hierarchy" },
        {
          name: "Contacts",
          value: "Contacts",
        },
        { name: "Interaction", value: "Interaction" },
        { name: "Rules", value: "Rules" },
        { name: "Quotes and orders", value: "Quotes and orders" },
        { name: "Approvals", value: "Approvals" },
      ],
      recievedContactData: [],
      showAddNewAdrressModal: false,
      newAddressPayload: {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      },
      locationData: [],
      executivesList: [],
    };
  },
  watch: {
    statusList: {
      handler(val) {
        console.log(val, "statuswatching000000000000");
        const filteredstatuslist = this.statusList.filter(
          (item) => item.name === "Active" || item.name === "Inactive"
        );
        this.filteredstatus = filteredstatuslist;
        console.log("filtered11111111111111111", this.filteredstatus);
      },
      immediate: true,
      deep: true,
    },
    payload: {
      handler(val) {
        val.companyName = val.name;
        val.tradingName = val.name;
        val.rootName = val.name;
        val.email = val.contactMedium[0].emailAddress;
        // console.log('val before watch', this.executivesList);
        // let accExec = this.executivesList.filter((item) => {
        //   return item.name === val.accountExecutive ? item : undefined;
        // });
        // val.relatedParty.splice(0, 1);
        // val.relatedParty.push(accExec[0]);
        // console.log('val after watch', val.relatedParty);
        for (let i = 0; i < val.contactMedium.length; i++) {
          if (val.contactMedium[i].mediumType === val.preferred) {
            val.contactMedium[i].preferred = true;
          } else {
            val.contactMedium[i].preferred = false;
          }
        }
        // console.log(this.payload);
      },
      immediate: true,
      deep: true,
    },
    contactPayload: {
      handler(val) {
        val.company = JSON.parse(JSON.stringify(this.payload.tradingName));
        val.companyName = JSON.parse(JSON.stringify(this.payload.tradingName));
        val.tradingName = JSON.parse(JSON.stringify(this.payload.tradingName));
        val.email = val.contactMedium[0].emailAddress;
        // console.log(this.payload, this.contactPayload);
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    let exec = await MLTAxiosInstance.get(
      `/partyManagement/individual/listuser`
    );
    this.executivesList = exec.data;
    // let statusList = await DMNAxiosInstance.get(`mapRef/collection/statuslist`);
    this.statusList =  await this.getReferanceData('statuslist');
    // let preferredList = await DMNAxiosInstance.get(
    //   `mapRef/collection/preferredContactMedium`
    // );
    this.preferredMediumList =  await this.getReferanceData('preferredContactMedium');
    console.log(this.preferredMediumList);
    // let industryTypeList = await DMNAxiosInstance.get(
    //   `mapRef/collection/industryType`
    // );
    this.industryTypeList =  await this.getReferanceData('industryType');
    // let employeeCountList = await DMNAxiosInstance.get(
    //   `mapRef/collection/employeeCount`
    // );
    this.employeeCountList =  await this.getReferanceData('employeeCount');
    // let locationTypeList = await DMNAxiosInstance.get(
    //   `mapRef/collection/locationType`
    // );
    this.locationTypeList =  await this.getReferanceData('locationType');
    // let tagsList = await DMNAxiosInstance.get(
    //   `mapRef/collection/organizationTags`
    // );
    this.tagsList =  await this.getReferanceData('organizationTags');
  },
  methods: {
    emitStatus1(val1) {
      this.payload.status = val1["value"];
    },
    async newValFromDropDown(e, val) {
      let obj = {};
      obj.name = e;
      if (val === "status") {
        await DMNAxiosInstance.post(`mapRef/organizationStatus`, obj);
        // let statusList = await DMNAxiosInstance.get(
        //   `mapRef/collection/organizationStatus`
        // );
        this.statusList =  await this.getReferanceData('organizationStatus');
      } else if (val === "industry") {
        await DMNAxiosInstance.post(`mapRef/industryType`, obj);
        // let industryTypeList = await DMNAxiosInstance.get(
        //   `mapRef/collection/industryType`
        // );
        this.industryTypeList =  await this.getReferanceData('industryType');
      } else if (val === "employeeCount") {
        await DMNAxiosInstance.post(`mapRef/employeeCount`, obj);
        // let employeeCountList = await DMNAxiosInstance.get(
        //   `mapRef/collection/employeeCount`
        // );
        this.employeeCountList =  await this.getReferanceData('employeeCount');
      } else if (val === "locationType") {
        await DMNAxiosInstance.post(`mapRef/locationType`, obj);
        // let locationTypeList = await DMNAxiosInstance.get(
        //   `mapRef/collection/locationType`
        // );
        this.locationTypeList =  await this.getReferanceData('locationType');
      }
    },
    selectedExecutive(val) {
      this.payload.relatedParty[0] = val;
      // console.log( this.payload.relatedParty);
    },
    receivedInteractionPayload(val) {
      this.interactionData = val;
    },
    // eslint-disable-next-line vue/no-async-in-computed-properties
    async getLocations() {
      const input = this.payload.contactMedium[3].description;
      let result = [];

      try {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        const response = await UPCAxiosInstance.get(
          `/suggestionlist/?input=${input}`
        ); // console.log(response);
        result = response.data;
      } catch (error) {
        console.error("Error fetching autocomplete data:", error.message);
      }
      // console.log(result);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.locationData = result;
      if (this.locationData.length === 0 && input !== "") {
        this.showNewAddressOption = true;
      } else {
        this.showNewAddressOption = false;
      }
      return result;
    },
    async addNewAddress() {
      this.newAddressPayload.description =
        this.newAddressPayload.streetNumber +
        ", " +
        this.newAddressPayload.streetName +
        ", " +
        this.newAddressPayload.city +
        ", " +
        this.newAddressPayload.state +
        ", " +
        this.newAddressPayload.country +
        ", " +
        this.newAddressPayload.postalCode +
        ".";
      // console.log(this.newAddressPayload);
      let newAddress = await DMNAxiosInstance.post(
        `mapRef/geoAddress`,
        this.newAddressPayload
      );
      if (newAddress.data.ok === 1) {
        // console.log(this.newAddressPayload.description);
      }
      this.payload.contactMedium[3].description =
        this.newAddressPayload.description;
      this.showAddNewAdrressModal = false;
      const x = {
        streetNumber: "",
        streetName: "",
        unitNumber: "",
        unitName: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        description: "",
        status: "-",
        landmark: "",
      };
      this.newAddressPayload = JSON.parse(JSON.stringify(x));
    },
    addNewLocation(val) {
      // console.log(val);
      val;
      this.showAddNewAdrressModal = true;
      this.showNewAddressOption = false;
    },
    closeAddNewAdrressModal() {
      this.showAddNewAdrressModal = false;
    },
    receivedTags() {
      // console.log(e);
    },
    addTagList(e) {
      // console.log(e);
      this.tagsList.push(e);
    },
    contactData(val) {
      this.recievedContactData = [...val];
      // console.log(this.recievedContactData);
    },
    async addNewAccount() {
      // console.log(this.payload);
      // console.log(this.recievedContactData);
      let result = await MLTAxiosInstance.post(
        `/partyManagement/individual/account/signup`,
        this.payload
      );
      // console.log(result);
      if (result.data.tradingName) {
        this.notification = {
          type: "success",
          message: "New organisation added successfully",
        };
      } else {
        // this.notification = {
        //   type: "danger",
        //   message: "Failed to add a new organization. Please try again.",
        // };
      }
      if (result.data.tradingName) {
        this.recievedContactData.forEach(async (e) => {
          e.familyName = e.name;
          e.givenName = e.name;
          e.email = e.contactMedium[0].emailAddress;
          e.company = JSON.parse(JSON.stringify(this.payload.tradingName));
          e.companyName = JSON.parse(JSON.stringify(this.payload.tradingName));
          e.tradingName = JSON.parse(JSON.stringify(this.payload.tradingName));
          // console.log(e);
          let result = await MLTAxiosInstance.post(
            `/partyManagement/individual/account/signup`,
            e
          );

          // console.log(result);
          if (result.data.token) {
            this.notification = {
              type: "success",
              message: `New contact ${e.name} added successfully`,
            };
          } else {
            // this.notification = {
            //   type: "danger",
            //   message: `Failed to add contact for ${e.name}. Please try again.`,
            // };
          }
        });
      }
      let result2 = await UPCAxiosInstance.post(
        `/partyInteraction/${this.payload.tradingName}`,
        this.interactionData
      );
      result2;
      // console.log(result2);
      setTimeout(() => {
        this.goTo();
      }, 2000);
    },
    goTo() {
      this.$router.back();
    },
  },
};
</script>
