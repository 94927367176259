<template>
  <div class="app-layout">
    <section class="right-panel">
      <section class="layout-basic">
        <div class="layout-breadcrumb">
          <hlx-breadcrumb
            :items="breadcrumb_items"
            @path="pathTo"
          />
        </div>
        <div
          class="layout-header"
          style="padding-top: 0"
        >
          <span
            class="header-title"
            style="font-weight: bold"
            >Add data from upload</span
          >
        </div>
        <div
          class="layout-body exec-data-upload"
          style="margin-top: 0"
        >
          <div
            class="grid"
            style="margin-top: 0"
          >
            <span class="titles">
              <div>Create new test data</div>
              <span class="info"
                >Add sample data to evaluate the chained execution of
                rules</span
              >
            </span>
            <span class="api-data-schema">
              <hlx-input
                v-model:value="add_payload[0].name"
                label-value="Name"
                :label-animation="true"
              ></hlx-input
              ><br />
              <hlx-input
                v-model:value="add_payload[0].description"
                :label-animation="true"
                label-value="Description"
                type="textarea"
                :clearable="true"
              />
              <br />
              <hlx-select
                :options="schemaOptions"
                :placeholder-value="'Schema'"
                :label-animation="true"
                :clearable="true"
                @custom-change="chooseSchema"
              ></hlx-select>
            </span>
          </div>
          <div
            id="scroll-to"
            class="divider"
          >
            <hr />
          </div>
          <div
            class="grid"
            style="height: 40% !important"
          >
            <span
              class="titles"
              style="height: 100px"
              >Content <br />
              <span
                class="info"
                style="font-size: var(--extra-small-font-size)"
                >Define data in JSON format.</span
              ></span
            >
            <span
              class="upload-data-content"
              style="height: 105% !important"
            >
              <hlx-file-upload
                :file-type="'.xlsx,.png,.json'"
                :file-size-limit="'20mb'"
                :type="'regular'"
                :custom-icon="'icon-eye-regular'"
                @preview-file="previewUploadedFile"
                @file-data="fileDataReceived"
                @delete-file="
                  show_preview = false;
                  isMatch = false;
                  add_payload[0].data = [];
                "
              />
              <span class="info">Maximum file size is 25 MB</span>
            </span>
          </div>
          <div
            v-show="show_preview"
            class="grid"
            style="height: 40% !important"
          >
            <span
              class="titles"
              style="height: 100px; margin-top: 6px"
              >JSON preview</span
            >
            <span
              class="upload-data-content"
              style="min-height: 100px !important"
            >
              <hlx-object-viewer
                :json-data="uploadedJson"
                :show="true"
                :type="'read-only'"
              />
            </span>
          </div>
        </div>
        <span
          class="layout-footer"
          style="
            border-top: 1px solid #d8d8d8;
            padding-right: 40px;
            margin-right: -16px;
            margin-left: -16px;
          "
        >
          <span
            class="go-back"
            style="
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            <hlx-button
              class="secondary sm"
              @click="cancelUploadData"
              >Back</hlx-button
            >
            <hlx-button
              class="primary sm"
              style="margin-left: 20px"
              @click="saveUploadData"
              >Save</hlx-button
            >
          </span>
        </span>
      </section>
    </section>
  </div>
</template>

<script>
import { DMNAxiosInstance } from '@/config/axiosConfig';

export default {
  data() {
    return {
      nav_items: [
        {
          name: 'Home',
          label: 'Home',
          icon: 'icon-grid-web-7-regular',
          description_en: 'Home',
          description_fn: '*Home',
        },
        {
          name: 'Business rule',
          label: 'Business rule ',
          icon: 'icon-code-regular',
          description_en: 'Residential business market ',
          description_fr: '*Residential business market ',
        },
        {
          name: 'Schema',
          label: 'Schema',
          icon: 'icon-desktop-code-regular',
          description_en: 'Small business markets',
          description_fr: '*Small business markets',
        },
        {
          name: 'Database',
          label: 'Database',
          icon: 'icon-database-regular',
          description_en: 'Small business markets',
          description_fr: '*Small business markets',
        },

        {
          name: 'Execute',
          label: 'Execute',
          icon: 'icon-file-settings-regular',
          description_en: 'Small business markets',
          description_fr: '*Small business markets',
          active: true,
        },
        {
          name: 'Audit',
          label: 'Audit',
          icon: 'icon-notebook-regular',
          description_en: 'Audit log',
          description_fr: '*Audit log',
        },
        {
          name: 'Help',
          label: 'Help',
          icon: 'icon-question-circle-regular',
          description_en: 'Setting',
          description_fr: '*Setting',
          separator: true,
        },
        {
          name: 'settings',
          label: 'settings',
          icon: 'icon-settings-regular',
          description_en: 'Setting',
          description_fr: '*Setting',
        },
        {
          name: 'logout',
          label: 'Logout',
          icon: 'icon-log-out-regular',
          description_en: 'Logout',
          description_fr: '*Logout',
        },
      ],
      breadcrumb_items: [
        { label: 'Execution', link: '/rule_validation' },
        { label: 'Add', link: '' },
      ],
      add_payload: [
        {
          name: '',
          schemaName: '',
          description: '',
          data: [],
          fileData: '',
          inputMode: 'upload',
          createdBy: '639af00f9f515f57074584ef',
        },
      ],
      schemaOptions: [],
      selectedSchema: {},
      file_name: '',
      file_data: '',
      show_preview: false,
      skeletonJson: {},
      uploadedJson: {},
      isMatch: false,
    };
  },
  mounted() {
    this.$store.commit('token', this.$route.params.token);
    // const id = "your-id"; // Replace with your actual id
    // const name = "your-name"; // Replace with your actual name

    DMNAxiosInstance.get(`/rule_engine/schema`)
      .then((response) => {
        // Handle the response here
        this.schemaOptions = response.data.data;
      })
      .catch((error) => {
        // Handle the error here
        console.error(error);
      });
  },
  methods: {
    navTo(active) {
      this.$store.commit('token', this.$route.params.token);
      
      if (active.name == 'Home') {
        this.navHome();
      } else if (active.name == 'logout') {
        this.logout();
      } else if (active.name == 'settings') {
        this.setting();
      } else if (active.name == 'Schema') {
        this.schema();
      } else if (active.name == 'Business rule') {
        this.ruled();
      } else if (active.name === 'Database') {
        this.execute();
      } else if (active.name == 'Execute') {
        this.exe();
      } else if (active.name == 'Help') {
        this.help();
      } else {
        this.audit();
        // 
      }
    },
    audit() {
      this.$router.push(`/DMN/audit`);
    },
    help() {
      window.open('https://documentation.halleyx.com');
    },
    ruled() {
      this.$router.push(`/DMN/business_rules`);
    },
    exe() {
      this.$router.push(`/DMN/rule_validation`);
    },
    execute() {
      this.$router.push(`/DMN/reference_data`);
    },
    schema() {
      this.$router.push(`/DMN/schemas`);
    },

    // logout() {
    //   this.$router.push('/')

    // },
    setting() {
      this.$router.push(`/settings`);
    },
    async navHome() {
      this.$router.push(`/DMN/dashboard`);
    },

    checkJsonMatch(skeletonJson, dataJson) {
      // 
      const skeletonKeys = Object.keys(skeletonJson);
      const dataKeys = Object.keys(dataJson);

      if (skeletonKeys.length !== dataKeys.length) {
        return false;
      }

      for (let i = 0; i < skeletonKeys.length; i++) {
        const key = skeletonKeys[i];
        dataKeys.find((dataKey) => dataKey === key);
        const skeletonValue = skeletonJson[key];

        // If key is an object, recursively call this function
        if (typeof skeletonValue === 'object') {
          // If key is an array, compare length
          if (Array.isArray(skeletonValue)) {
            if (
              !Array.isArray(dataJson[key]) ||
              skeletonValue.length !== dataJson[key].length
            ) {
              return false;
            }

            // Loop through each object in the array
            for (let j = 0; j < skeletonValue.length; j++) {
              const isMatch = this.checkJsonMatch(
                skeletonValue[j],
                dataJson[key][j]
              );
              if (!isMatch) {
                return false;
              }
            }
          }
          // If key is an object, recursively call this function
          else {
            const isMatch = this.checkJsonMatch(skeletonValue, dataJson[key]);
            if (!isMatch) {
              return false;
            }
          }
        }
      }

      return true;
    },
    chooseSchema(schema) {
      let selectedSchema = schema.filter((obj) => obj.checked === true);
      selectedSchema = JSON.parse(JSON.stringify(selectedSchema))[0];
      const schemaName = selectedSchema.name;
      this.add_payload[0]['schemaName'] = schemaName;
      const obj = {};
      obj[schemaName] = this.addListItems(selectedSchema.schema);
      this.selectedSchema = obj;
      if (
        this.uploadedJson !== {} &&
        this.uploadedJson !== undefined &&
        this.selectedSchema !== {} &&
        this.selectedSchema !== undefined
      ) {
        this.isMatch = this.checkJsonMatch(
          this.selectedSchema,
          this.uploadedJson
        );
        if (this.isMatch) {
          this.add_payload[0].data = this.uploadedJson;
          
        }
      }
    },
    addListItems(obj) {
      for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          if (obj[key].type === 'Array') {
            obj[key].list_items = [];
            const newObj = {};
            newObj.type = 'Object';
            for (const items in obj[key]) {
              // 
              if (
                items !== 'list_items' &&
                items !== 'type' &&
                items !== 'href' &&
                items !== 'ref'
              ) {
                newObj[items] = obj[key][items];
                delete obj[key][items];
              }
            }
            obj[key].list_items.push(newObj);
            // 
          }
          this.addListItems(obj[key]);
        }
      }

      return this.generateJSONFromSchema(obj);
    },
    generateJSONFromSchema(schema) {
      const result = {};

      for (const key in schema) {
        if (Object.hasOwnProperty.call(schema, key)) {
          const value = schema[key];
          const valueType = value.type;

          switch (valueType) {
            case 'String':
              result[key] = '(empty)';
              break;
            case 'Date':
              result[key] = '(empty)';
              break;
            case 'Boolean':
              result[key] = 'false';
              break;
            case 'Integer':
              result[key] = 0;
              break;
            case 'Number':
              result[key] = 0;
              break;
            case 'Object':
              result[key] = this.generateJSONFromSchema(value);
              break;
            case 'Array':
              if (value.list_items) {
                const arrayLength = value.list_items.length;
                const arrayResult = [];

                for (let i = 0; i < arrayLength; i++) {
                  arrayResult.push(
                    this.generateJSONFromSchema(value.list_items[i])
                  );
                }

                result[key] = arrayResult;
              } else {
                result[key] = [];
              }
              break;
            default:
              break;
          }
        }
      }
      return result;
    },
    pathTo() {
      
      this.$router.back();
    },
    previewUploadedFile() {
      this.show_preview = true;
      // 
      // 
      // 
      setTimeout(() => {
        const scrollableDiv = document.querySelector('.layout-body');
        const lastChild = scrollableDiv.lastElementChild;
        // 
        lastChild.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    },
    cancelUploadData() {
      this.add_payload[0] = {
        name: '',
        schemaName: '',
        description: '',
        fileData: '',
        data: [],
        inputMode: 'upload',
        createdBy: '639af00f9f515f57074584ef',
      };
      this.$router.back();
    },
    saveUploadData() {
      if (this.isMatch) {
        // const id = 'your-id'; // Replace with your actual id
        // const name = 'your-name'; // Replace with your actual name

        DMNAxiosInstance.post(
          `/rule_engine/execution_data/add/`,
          this.add_payload
        )
          .then((response) => {
            // Handle the response here
            // this.schemaOptions = response.data
            if (response.data.message === undefined) {
              
              this.$router.back();
            } 
          })
          .catch((error) => {
            // Handle the error here
            console.error(error);
            
          });
      } 
    },
    fileDataReceived($event) {
      // 
      this.add_payload[0].fileData = $event.data;
      this.uploadedJson = JSON.parse($event.data);
      // 

      if (
        this.uploadedJson !== {} &&
        this.uploadedJson !== undefined &&
        this.selectedSchema !== {} &&
        this.selectedSchema !== undefined
      ) {
        this.isMatch = this.checkJsonMatch(
          this.selectedSchema,
          this.uploadedJson
        );
        if (this.isMatch) {
          this.add_payload[0].data = this.uploadedJson;
          // 
        }
      }
    },
  },
};
</script>
