<template>
  <div style="display: flex; margin: 20px; flex-direction: column">
    <div
      style="margin-bottom: 10px  ;   font-size: 18px;
    font-weight: bold;
    font-family: Quicksand"
    >
      <b>Inventory</b>
    </div>
    <span style="font-size: 12px"
      >Efficiently track and manage your stock levels.</span
    >
    <placeholderTableComponent
      :module="'inventory'"
    ></placeholderTableComponent>
  </div>
</template>

<script>
import placeholderTableComponent from "@/components/CM/placeholderTableComponent.vue";

export default {
  components: {
    placeholderTableComponent,
  },
};
</script>
