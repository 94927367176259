<template>
  <div class="configuration-container1">
    <div class="filter-container">
      <span style="padding: 0 15px; display: flex">
        <hlx-breadcrumb
          :items="getBreadcrumbs"
          @path="goTo()"
        />
      </span>
      <span>
        <FilterComponent
          :filterpanelname="'accounts'"
          @filtered-data="accountsFilter"
        ></FilterComponent>
      </span>
    </div>
    <div
      class="offer-container"
      style="height: 95vh; overflow: auto; padding: 0 20px 0 20px"
    >
      <div
        class="switch-container"
        style="
          height: 40px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        "
      >
        <hlx-switch
          v-model="selectedValue"
          :switch-items="switchItems"
          :tab-view="true"
          @change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { UPCAxiosInstance, MLTAxiosInstance } from '@/config/axiosConfig';
import FilterComponent from '../../components/filterComponent.vue';

export default {
  components: {
    FilterComponent,
  },
  data() {
    return {
      initialSelectedValue: '128 GB',
      optionRectangle: [
        { name: '128 GB', value: '128 GB' },
        { name: '256 GB', value: '256 GB' },
        { name: '512 GB', value: '512 GB' },
      ],
      swatchData: [
        { name: 'Teal', value: 'Teal', Color: '#02b55c' },
        { name: 'Product red', value: 'Product red', Color: '#ff5050' },
        { name: 'Yale blue', value: 'Yale blue', Color: '#2879b7' },
        { name: 'Starlight', value: 'Starlight', Color: '#f8a523' },
      ],
      preselectedColor: 'Yale blue',
      hoveredSwatch: null,
      headerName: null,
      pricingTypeOptions: [
        { name: 'Onetime', value: 'Onetime' },
        { name: 'Recurring', value: 'Recurring' },
        { name: 'Rental', value: 'Rental' },
        { name: 'Pay per use', value: 'Pay per use' },
      ],
      offerDetails: {
        name: 'Iphone 15',
        rating: '4',
        brand: 'Apple',
        supplier: 'iSupplyTech',
        pricingType: 'Onetime',
        price: '$ 1699',
        description: `Experience the iPhone 15 – your dynamic companion. Dynamic Island ensures you stay connected, bubbling up alerts seamlessly
while you're busy. Its durable design features infused glass and aerospace-grade aluminum, making it dependable and resistant 
to water and dust. Capture life with precision using the 48 MP Main Camera, perfect for any shot. Powered by the A16 Bionic
Processor, it excels in computational photography and more, all while conserving battery life. Plus, it's USB-C compatible, 
simplifying your charging needs. Elevate your tech game with the iPhone 15 – innovation at your fingertips. Goodbye cable clutter,
hello convenience.`,
        specification: [
          {
            name: 'Video play back',
            value: '20hrs',
          },
          {
            name: 'Height',
            value: '147.6 mm (5.81 inches)',
          },
          {
            name: 'Display size',
            value: '6.1"Inchs',
          },
          {
            name: 'Battery Life',
            value: '10hrs OST',
          },
          {
            name: 'Available warranty',
            value: '1 Year',
          },
        ],
      },
      externalRowsPerPage: 15,
      currentPage: 1,
      search_offering: '',
      card_data: [],
      switchItems: [{ name: 'hi', value: 'hi' }],
      selectedValue: 'hi',
    };
  },
  computed: {
    displayHeaderName() {
      return this.hoveredSwatch ? this.hoveredSwatch.name : this.headerName;
    },
    getBreadcrumbs() {
      return [
        {
          label: 'Quote',
          link: ``,
        },
        { label: 'Mobile phones', link: '' },
      ];
    },
  },
  watch: {
    preselectedColor(newVal) {
      this.headerName = newVal;
    },
  },
  created() {
    this.headerName = this.preselectedColor;
  },
  mounted() {
    this.getOfferData();
  },
  methods: {
    async accountsFilter(filterSelectedvalue, filterQuery) {
      // ;
      this.filtertagvalue = filterSelectedvalue;
      this.filtertaglength = filterSelectedvalue.length;
      filterQuery.company = this.company;
      if (this.filtertagvalue.length != 0) {
        // this.totalpageno = spectotalpageno
        // this.tableData = spectabledata
        this.accountsquery = filterQuery;
        this.accountsquery.type = 'filter';
        if (this.searchvalue != '') {
          this.accountsquery['searchQuery'] = this.searchvalue;
        }
        const filterResultData = await MLTAxiosInstance.post(
          `util/filterData`,
          this.accountsquery
        );
        // console.log(filterResultData.data.data,'la');

        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data.total;
        // ;
      } else {
        this.currPage = 1;
        this.rowsPerPage = 10;
        await this.accountsDataCalling(this.currPage, this.rowsPerPage);
      }
    },
    async getOfferData() {
      console.log('get offer');
      let Specfilterquery = {
        type: 'search',
        module: 'productOffering',
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: this.externalRowsPerPage,
          currentPage: this.currentPage,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {
          // "productSpecCharacteristic.productSpecCharacteristicValue.value": "",
        },
        searchQuery: this.search_offering,
      };

      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        Specfilterquery
      );
      // this.offering_data = filterResultData.data.data
      console.log('raw offer', filterResultData);
      console.log(
        'Offering data',
        (this.card_data = this.transformData(filterResultData.data.data))
      );
    },
    transformData(originalData) {
      console.log(originalData, 'og data');
      return originalData.map((item) => {
        const {
          name,
          description,
          lifecycleStatus,
          category,
          productSpecification,
          _id,
          productOfferingPrice,
        } = item;

        const lastCategory =
          category.length > 0 ? category[category.length - 1].name : '';

        const pricing = this.getPricing(productOfferingPrice);
        const oneTimePrice = this.calculateOneTimePrice(pricing);

        return {
          img_url: '',
          currency: 'GBP',
          name: name || '',
          description: description || '',
          status: lifecycleStatus || '',
          category: lastCategory || '',
          brand: productSpecification?.brand || '',
          id: _id || '',
          offering_type:
            productSpecification?.['@baseType']?.toLowerCase() || '',
          pricing: pricing,
          price: oneTimePrice
            ? {
                whole: oneTimePrice.whole || '0',
                fraction: oneTimePrice.fraction || '00',
              }
            : { whole: '0', fraction: '00' },
        };
      });
    },

    getPricing(prices) {
      if (!prices || prices.length === 0) return [];

      const priceMap = {};
      prices.forEach((price) => {
        const { pricingType, productofferPriceAlteration } = price;
        if (
          productofferPriceAlteration &&
          productofferPriceAlteration.length > 0
        ) {
          console.log('kulla if');
          productofferPriceAlteration.forEach((alteration) => {
            if (alteration.priority === '1') {
              if (
                !priceMap[pricingType] ||
                priceMap[pricingType].priority !== '1'
              ) {
                priceMap[pricingType] = {
                  name: price.name || '',
                  pricingType: pricingType || '',
                  amount: alteration.productPrice.taxAmount?.Amount
                    ? alteration?.productPrice?.taxAmount?.Amount?.toString()?.replace(
                        '£',
                        ''
                      )
                    : '',
                  priority: alteration?.priority || '',
                };
              }
            }
          });
        } else {
          console.log('kulla veliya if', price.productPrice.dutyFreeAmount);
          priceMap[pricingType] = {
            name: price.name || '',
            pricingType: pricingType || '',
            amount: price?.productPrice?.dutyFreeAmount
              ? price?.productPrice?.dutyFreeAmount
                  ?.toString()
                  ?.replace('£', '')
              : '',
            priority: price?.productPrice?.priority || '',
          };
        }
      });

      return Object.values(priceMap).map((price) => ({
        ...price,
        amount: price.amount || '',
      }));
    },

    calculateOneTimePrice(pricing) {
      console.log('pricing', pricing);
      const oneTimePrice = pricing.find(
        (price) =>
          price.name === 'One time' ||
          price.name === 'Recurring' ||
          price.name === 'Pay per use price'
      );
      if (oneTimePrice) {
        const amount = oneTimePrice.amount;
        const [whole, fraction] = amount.split('.');
        return { whole, fraction };
      }
      return null;
    },
    selected(val) {
      this.initialSelectedValue = val.value;
    },
    handleSelectedColor(color) {
      console.log('Selected Color:', color);
      this.headerName = color.name;
      this.hoveredSwatch = color;
    },

    handlehoverColor(color) {
      this.hoveredSwatch = color;
      console.log('hovered Color:', color);
    },
  },
};
</script>

<style lang="scss">
.configuration-container1 {
  display: grid;
  grid-template-columns: 280px auto;
  grid-gap: 2%;
  padding: 20px 0 20px 20px;
  // .filter-container {
  //   // background: rgb(224, 163, 163);
  // }
  .offer-container {
    background: rgb(144, 104, 104);
  }
  .image-preview-container {
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    margin-bottom: 20px;
    min-height: 500px;
  }
  .spec-box {
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    min-height: 400px;
  }
}
</style>
