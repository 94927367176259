<template>
      <hlx-alert-notification :notify="notification" :auto-close="true" />
  <div>
    <!-- <section class="media-search-row">
      <span class="header" style="margin-bottom: 0px !important">
        <h3>Media</h3>
      </span>
    </section>

    <section class="media-search-row">
      <span class="header" style="margin-bottom: 0px !important">
        <div style="font-size: 14; font-family: Open Sans; font-weight: 300">
          Extra costs beyond the base price.
        </div>
      </span>
    </section> -->

    <section class="cm-accounts-landing-layout">
      <section class="cm-accounts-right-panel-container">
        <div :style="{padding:editable?'1rem':0}" class="top-section">
          <section   :style="{display:editable?'block':'block'}">
            <span class="header" style="margin-bottom: 0px !important;">
              <h4> Additional charges</h4>
              <span v-if="editable" style="font-size: 12px; font-family: OpenSans; margin-top: 2px;">
                 Includes Any Applicable Fees and Taxes. 
                </span></span><!--v-if--></section>
          <section
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            "
          >
            <!-- <div style="font-weight:bolder">
              Additional charges
            </div>
            <div class="cm-accounts-right-panel-top-section-description">
              Includes Any Applicable Fees and Taxes
        </div> -->
            <hlx-drawer
              :show="show_right"
              :width="1000"
              position="right"
              :footer="false"
              :show-close-icon="true"
              @close="closeactivitylog"
            >
              <template #body>
                <div class="activitylog_drawer_componet">
                  <activityLogComponent
                  :key="activtykey"
                    :activity-data="activityLogData"
                    :module="'leftPanal'"
                    :totalfromprop="activitytotal"
                    @activityrowpage="activityrowpage1"
                    @activitycurrentpage="activitycurrentpage1"
                  ></activityLogComponent>
                </div>
              </template>
            </hlx-drawer>
            <div
style="display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.5rem 0;">
              <span  v-if="searchData.length > 0" class="search-container">
                <hlx-search @search-key="searchKey"></hlx-search>
              </span>
              <span v-if="editable" @click="activityLogDrawer()"
                ><i
                  class="icon-time-forward-regular"
                  style="
                    font-size: 24px;
                    color: rgb(84, 189, 149);
                    margin-left: 14px;
                  "
                ></i
              ></span>

              <!-- <span v-if="editable" class="filter-table">
                <hlx-button
                  class="secondary sm add-btn"
                  @click="showDrawerRight"
                >
                  <i class="icon-filter-regular"></i>
                  <span v-if="filtertaglength != 0" class="filter-count">{{
                    filtertaglength
                  }}</span>
                </hlx-button>
              </span> -->
              <hlx-button
               v-show="permisstionData.add "
                v-if="editable && tableData.length > 0"
                class="primary sm"
                @click="openuploaddrawer"
              >
                <i class="icon-plus-circle-regular"></i> Add additional charges
              </hlx-button>
            </div>
          </section>
        </div>
      </section>
    </section>

    <hlx-modal
      :modal-active="delete_modal_active"
      :height="'220px'"
      :width="'400px'"
      :modal="true"
      @close="delete_modal_active = false"
    >
      <template #header>
        <div style="margin-left: 0px">Delete data</div>
      </template>
      <template #content>
        <div class="modal-content" style="margin-top: 4%">
          <p>
            Are you sure you want to delete <b>{{ delete_name }}</b
            >?
          </p>
        </div>
      </template>
      <template #footer>
        <hlx-button class="secondary media" @click="cancelDeleteModal"
          >Cancel</hlx-button
        >
        <hlx-button
          class="primary media"
          style="margin-left: 16px"
          @click="deleteExecRow"
          >Delete</hlx-button
        >
      </template>
    </hlx-modal>
    <!-- hlx-drawer -->
    <div class="add-contact-drawer">
      <hlx-drawer
        :key="updateKey"
        :title="'upload drawer'"
        :show="show_upload_drawer"
        :width="427"
        position="right"
        :footer="true"
        :show-close-icon="true"
        @close="closeuploaddrawer"
      >
        <template #body>
          <div style="padding: 10px">
            <div
              v-if="editMode == false"
              style="
                padding-left: 5px;
                padding-bottom: 15px;
                padding-top: 3px;
                border-bottom: solid 1px rgba(216, 216, 216, 1);
              "
            >
              <span><b style="font-size: 16px">Add additional charge</b></span>
            </div>
            <div
              v-else
              style="
                padding-left: 5px;
                padding-bottom: 15px;
                padding-top: 3px;
                border-bottom: solid 1px rgba(216, 216, 216, 1);
              "
            >
              <span><b style="font-size: 16px">Edit additional charge</b></span>
            </div>
            <div class="upload_image_header" style="padding: 5px">
              <!--  name -->
              <div class="schema-name-wrapper" style="margin-top: 9px">
                <hlx-input
                  :key="updateKey"
                  v-model:value="additionalChargesDataQuery.name"
                  :pre-val="additionalChargesDataQuery.name"
                  :label-value="'Name'"
                  :label-animation="true"
                  :required="false"
                  :display-error="customnameerror"
                  :custom-error="customnameerror"
                  :custom-error-message="'Please fill the field'"
                ></hlx-input>
              </div>

              <!--  description -->
              <div
                class="schema-description-wrapper"
                style="margin-top: 15px; height: 96px"
              >
                <hlx-input
                  :key="updateKey"
                  v-model:value="additionalChargesDataQuery.description"
                  :pre-val="additionalChargesDataQuery.description"
                  :label-animation="true"
                  label-value="Description"
                  type="textarea"
                  :clearable="true"
                />
              </div>

              <div style="margin-top: 20px">
                <!-- <hlx-input
                  :key="updateKey"
                  v-model:value="additionalChargesDataQuery.price"
                  :label-animation="false"
                  label-value="Charge"
                  type="mixed-number"
                  :pre-val="additionalChargesDataQuery.price"
                  :clearable="false"
                  name="fee"
                  :min-limit="0"
                  :symbol=" $store?.getters?.getDefaultSettings?.currency?.symbol "
                   :symbol="
                        $store?.getters?.getDefaultSettings?.currency?.symbol
                      "
                >
                  
                </hlx-input> -->
                <hlx-input
                      v-model:value="additionalChargesDataQuery.price"
                      
                      :label-animation="true"
                      type="mixed-number"
                      label-value="Charge"
                      :placeholder-value="'Charge'"
                         :min-limit="0"
                      :clearable="false"
                      :display-error="false"
                      :read-only="false"
                      :custom-error-message="'Please set a default value'"
                      :custom-error="false"
                      :pre-val="additionalChargesDataQuery.price"
                      :suffix="true"
                      :prefix="false"
                        :symbol=" $store?.getters?.getDefaultSettings?.currency?.symbol "
                     
                    >
                    <template #suffix>
                      {{
                            $store?.getters?.getDefaultSettings?.currency?.unit
                          }}</template>
                    </hlx-input>
              </div>

              <section class="productDiscountSection" style="margin-top: 15px">
                <h5>Taxable</h5>
              </section>
              <div>
                <hlx-input
                  v-model:value="additionalChargesDataQuery.isTaxable"
                  :pre-val="additionalChargesDataQuery.isTaxable"
                  :options="options5"
                  :type="'radio-buttons'"
                  :position="'horizontal'"
                  @selected="isTaxable"
                />
              </div>
              <section class="productDiscountSection" style="margin-top: 15px">
                <h5>Charge type</h5>
              </section>
              <div>
                <hlx-input
                  v-model:value="additionalChargesDataQuery['@type']"
                  :pre-val="additionalChargesDataQuery['@type']"
                  :options="chargeType"
                  :type="'radio-buttons'"
                  :position="'horizontal'"
                  @selected="isChargetype"
                />
              </div>
              <section class="productDiscountSection" style="margin-top: 15px">
                <h5>Applies to order type</h5>
                <span class="showAddHierarchyModal-side-panel-each-section">
                  <span>
                    <hlx-input
                      :position="'horizontal'"
                      :options="checkboxOptions"
                      :type="'checkbox'"
                      @selected="emitCheckbox"
                    />
                  </span>
                </span>
              </section>
              <span>
                <!-- yes/no -->
                <!-- {{2}}
          {{additionalChargesDataQuery.taxable}} -->
                <!-- true or false -->
                <!-- {{3}}
          {{additionalChargeTaxable}} -->
              </span>
              <section class="productDiscountSection" style="margin-top: 15px">
                <h5>Apply to</h5>
              </section>

              <div
                class="change-searchfilter-height"
                style="display: flex; flex-direction: row; max-height: 250px"
              >
                <span style="width: 100%">
                  <searchfilterComponent
                    :key="updateKey"
                    :dropdown-width="'395'"
                    :module="'productoffering'"
                    :pre-value-items="preValOffers"
                    :filter-search-options="mainInfo"
                    :filter-data="searchFilterData"
                    :filter-checked-data="layout"
                    :key-value-from-query-array="'label'"
                    :filter-value-object-name="'name'"
                    @searched-value="getSearchedValue"
                    @checked-value="getcheckedValue"
                  >
                  </searchfilterComponent>
                </span>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div
            style="
              display: flex;
              justify-content: flex-end;
              padding: 9px;
              border-top: 1px solid #d8d8d8;
            "
          >
            <hlx-button class="secondary media sm" @click="closeuploaddrawer"
              >Cancel</hlx-button
            >
            <hlx-button
              v-if="!editMode && additionalChargesDataQuery.name.trim()"
              class="primary media sm"
              style="margin-left: 10px"
              @click="addAdditionalChargesInfo()"
              >Add</hlx-button
            >
            <hlx-button
              v-if="!editMode && !additionalChargesDataQuery.name.trim()"
              class="primary media sm"
              style="margin-left: 10px"
              :disabled="true"
              >Add</hlx-button
            >
            <hlx-button
              v-if="editMode && additionalChargesDataQuery.name.trim()"
              class="primary media sm"
              style="margin-left: 10px"
              @click="editedAdditionalChargesInfo(i)"
              >Save</hlx-button
            >
            <hlx-button
              v-else-if="editMode && !additionalChargesDataQuery.name.trim()"
              class="primary media sm"
              style="margin-left: 10px"
              :disabled="true"
              >Save</hlx-button
            >
          </div>
        </template>
      </hlx-drawer>
    </div>

    <section v-if="tableData.length > 0">
      <!-- table -->
      <section>
        <div :style="{'paddingLeft':editable?'15px' :'0px','paddingRight':editable?'15px' :'0px'}">
          <hlx-table
            :column-count="tableHeader.length"
            :border="['table', 'header', 'horizontal']"
            :bold-headers="false"
            :row-hover="true"
            theme="grey"
            :striped-rows="false"
            :re-arrange-columns="re_arrange"
           :re-arrange-heads="tableHeader_cc"
           @sorting_func="sorting_Data"
          @close-rearrange="re_arrange =false"
                 @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
              <hlx-table-head
                :width="50"
                :min-width="50"
                :align="'center'"
                style="padding: 0 15px; min-width: 50px; max-width: 50px"
                :fixed="'left'"
                @new-resize-width="resizedWidth"
              >
                {{ "S.no" }}</hlx-table-head
              >

              <hlx-table-head
                v-for="(header, index) in tableHeader"
                :key="index"
                :width="header.width"
                 :prop="header.prop"
                :sortable="header.sortable"
                :resizable="header.resizable"
                  @new-resize-width="resizedWidth"
              >
                <span v-if="['price'].includes(header.prop)">
                  {{ header.label }} ({{
                    $store?.getters?.getDefaultSettings?.currency?.unit
                  }})
                </span>
                <span v-else>
                  {{ header.label }}
                </span>
              </hlx-table-head>
              <hlx-table-head
              v-if="permisstionData.edit|| permisstionData.delete"
                :width="50"
                :fixed="'right'"
                style="max-width: 60px !important"
                @new-resize-width="resizedWidth"
                ><span class="action-icon">
                  <i class="icon-settings-regular" @click="reAssignOrgTableHeader()"></i></span
              ></hlx-table-head>
            </template>

            <template #tbody>
              <tr
                v-for="(i, index) in tableDataOnSelectedRows"
                id=""
                :key="index"
              >
                <hlx-table-cell :align="'center'" :fixed="'left'">
                  {{ serialNumberAdditionalCharges(index) }}
                </hlx-table-cell>
                <hlx-table-cell
                  v-for="(j, col_index) in tableHeader"
                  :key="col_index"
                  :align="j.align"
                  :fixed="j.fixed"
                >
                  <div v-if="j.prop === 'name'">
                    <div style="padding-top: 5px; padding-left: 10px">
                      {{ i[j.prop] }}
                    </div>
                  </div>
                  <div v-else-if="j.prop === 'chargeType'">
                    <div style="padding-top: 5px; padding-left: 10px">
                      <hlx-tagcount
                        :arrayfile="i[j.prop]"
                        :item-key="'name'"
                        :type="'label'"
                      ></hlx-tagcount>
                    </div>
                  </div>
                  <div v-else-if="j.prop == 'isTaxable'">
                    <span v-if="i[j.prop] === true">
                      {{ "Yes" }}
                    </span>
                    <span v-if="i[j.prop] === false">
                      {{ "No" }}
                    </span>
                  </div>
                  <div v-else-if="j.prop == 'price'">
                    {{ computedProductPriceAdditionalCharges(i[j.prop]) }}
                  </div>
                  <div v-else-if="j.prop == 'charges'">
                    {{ i["@type"] || "-" }}
                  </div>
                  <!-- <div v-else-if="j.prop.toLowerCase() === 'status'">
              <div>
                <hlx-status :type="i[j.prop]"></hlx-status>
              </div>
            </div> -->
                  <!-- {{ i }} -->
                  <div v-else>
                    {{ i[j.prop] }}
                  </div>
                </hlx-table-cell>
                <hlx-table-cell
                v-show="permisstionData.edit || permisstionData.delete"
                  v-if="editable"
                  :align="'center'"
                  :width="120"
                  :fixed="'right'"
                >
                  <span
                    :id="
                      'table-context' + serialNumberAdditionalCharges(index - 1)
                    "
                    class="action-icon"
                  >
                    <i
                      style="position: relative"
                      class="icon-more-vertical-filled"
                      :class="i.context === true ? 'active-action' : ''"
                      @click="
                        crudAdditionalChargesContextMenu(
                          $event,
                          serialNumberAdditionalCharges(index - 1)
                        )
                      "
                    >
                      <hlx-context-menu
                        :top="containertop"
                        :left="containerleft"
                        :conditionvalue="150"
                        :conditiondown="2"
                        :conditionup="-38"
                        :options="computedAdditionalTableAction(i)"
                        :data="index.toString()"
                        :show="i.context == true"
                        @chosen="
                          closeAdditionalCrudContextMenu(
                            $event,
                            i,
                            serialNumberAdditionalCharges(index - 1)
                          )
                        "
                      />
                    </i>
                  </span>
                </hlx-table-cell>
                <hlx-table-cell
                v-show="permisstionData.edit || permisstionData.delete"
                  v-if="!editable"
                  :align="'center'"
                  :width="120"
                  :fixed="'right'"
                >
                  <span
                    :id="
                      'table-context' + serialNumberAdditionalCharges(index - 1)
                    "
                    class="action-icon"
                  >
                    <i
                      style="position: relative"
                      class="icon-more-vertical-filled"
                      :class="i.context === true ? 'active-action' : ''"
                      @click="
                        crudAdditionalChargesContextMenu(
                          $event,
                          serialNumberAdditionalCharges(index - 1)
                        )
                      "
                    >
                      <hlx-context-menu
                        :top="containertop"
                        :left="containerleft"
                        :conditionvalue="150"
                        :conditiondown="2"
                        :conditionup="-38"
                        :options="computedAdditionalReadOnlyTableAction(i)"
                        :data="index.toString()"
                        :show="i.context == true"
                        @chosen="
                          closeAdditionalCrudContextMenu(
                            $event,
                            i,
                            serialNumberAdditionalCharges(index - 1)
                          )
                        "
                      />
                    </i>
                  </span>
                </hlx-table-cell>
              </tr>
            </template>
          </hlx-table>
        </div>
      </section>
      <!-- Pagination -->
      <div
        v-if="tableData.length > 0"
        class="additional-charges-pagination-container"
        :style="{ padding: '1rem 0', width: additionalChargePaginationWidth }"
      >
        <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
          :total="tableData.length"
          enable-rows-per-page
          :rows-per-page-list="[10, 15, 20]"
          :options="{
            attributes: {
              rowsPerPageTitle: true,
              rowsPerPageResult: true,
              rowsPerPageDropdown: {
                position: {
                  top: 0,
                  right: 1,
                },
              },
            },
          }"
          @current-page="currentPage"
          @updated:rows-per-page="changeRowsPerPage"
        ></hlx-pagination>
      </div>
    </section>
    <section>
      <div
        v-if="tableData.length === 0 && searchvalue !== ''"
        class="cm-accounts-right-panel-top-section-table-wrap"
       :style="{ 'padding': editable ? '0 15px 0 15px' : '0'}"
      >
        <hlx-table
          :column-count="tableHeader.length"
          :border="['table', 'header']"
          :bold-headers="false"
          :row-hover="true"
          theme="grey"
          :striped-rows="false"
          :re-arrange-columns="re_arrange"
           :re-arrange-heads="tableHeader_cc"
           @sorting_func="sorting_Data"
          @close-rearrange="re_arrange =false"
            @apply-rearranged-columns="applyColumns"
        >
          <template #thead>
            <hlx-table-head
              :width="50"
              :min-width="50"
              :align="'center'"
              style="padding: 0 15px; min-width: 50px; max-width: 50px"
              :fixed="'left'"
                @new-resize-width="resizedWidth"
            >
              {{ "S.no" }}</hlx-table-head
            >

            <hlx-table-head
              v-for="(header, index) in tableHeader"
              :key="index"
              :width="header.width"
              :sortable="header.sortable"
              :resizable="header.resizable"
                @new-resize-width="resizedWidth"
            >
              <span v-if="['price'].includes(header.prop)">
                {{ header.label }} ({{
                  $store?.getters?.getDefaultSettings?.currency?.unit
                }})
              </span>
              <span v-else>
                {{ header.label }}
              </span>
            </hlx-table-head>
            <hlx-table-head
            v-show="permisstionData.edit || permisstionData.delete"
              :width="50"
              :fixed="'right'"
              style="max-width: 60px !important"
                 @new-resize-width="resizedWidth"
              ><span class="action-icon">
                <i class="icon-settings-regular" ></i></span
            ></hlx-table-head>
          </template>
          <template #tbody>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell :colspan="tableHeader.length + 2">{{
                "No data"
              }}</hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
          </template>
        </hlx-table>
      </div>
    </section>
    <!-- without tabledata -->
    <section
      v-if="tableData.length <= 0 && searchvalue == 0"
      style="
        margin-top: 3.2rem;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        align-items: center;
      "
    >
      <img
        src="@/assets/images/addAccountAttachments.svg"
        alt=""
        style="width: 500px"
      />

      <span class="util-items util-items-add">
        <hlx-button
          v-if="editable"
          v-show="permisstionData.add " 
          class="primary media add-btn"
          @click="openuploaddrawer"
          ><i   class="icon-plus-circle-regular" style="margin-right: 5px"></i>Add
          additional charges</hlx-button
        >
      </span>
    </section>
  </div>
</template>

<script>
import {
  // DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
import searchfilterComponent from "@/components/SearchAndFilterComponent/searchAndFilter.vue";
import activityLogComponent from "@/components/SM/activityLogComponent.vue";

export default {
  components: {
    searchfilterComponent,
    activityLogComponent,
  },
  props: {
    showContextMenu:{
      type:Boolean,
      default:true,
    },
    permisstionData: {
      type: Object,
      default: function () {
        return {
          add : true,
          edit: true,
          view: true,
          delete: true,
         
        };
      },
    },
    editable: {
      type: Boolean,
      default: true,
    },
    tableName: {
      type: String,
      default: "530",
    },
    additionalChargePaginationWidth: {
      type: String,
      default: "100%",
    },
    tableCharges: {
      type: String,
      default: "150",
    },
    tableTaxable: {
      type: String,
      default: "230",
    },
    // tableCharge:{
    //   type: String,
    //   default: "530",
    // },
    // type: {
    //   type: String,
    //   required: true,
    //   default: "editable",
    // },
    additionalChargesData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: [
    "edit-button",
    "delete-button",
    "additional-charge-new-data",
    "atclick-edit-button",
    "atclick-edit-button",
  ],
  data() {
    return {
      notification:{},
      isContextMenuVisible:true,
      tableHeaderOrg:[],
      tableHeaderOrgcc:[],
      re_arrange:false,
      logpayload:{
        type: "filter",
        module: "log",
        lastUpdated: new Date().toJSON(),
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          category: {
            $in: ["additionalCharges"],
          },
        },
        searchQuery: "",
      },
      preValOffers: [],
      activityLogData: [],

      additionalChargesDataQuery: {
        name: "",
        description: "",
        appliesTo: [],
        chargeType: [],
        isTaxable: false,
        // type:"Onetime",
        price: 0,
      },
      searchData: [],
      updateDrawerKey: 0,
      updateKey: 0,
      layout: [
        {
          prop: "lifecycleStatus",
          label: "Status",
          type: "String",
          fieldToFilter: "lifecycleStatus",
          fieldToSearch: "lifecycleStatus",
        },
        {
          prop: "category",
          label: "Category",
          type: "Array",
          fieldToFilter: "category",
        },
        {
          prop: "productOfferingPrice",
          label: "price",
          type: "Array",
          fieldToFilter: "productOfferingPrice.name",
          fieldToSearch:
            "productOfferingPrice[0].productofferPriceAlteration[0].productprice.taxAmount.Amount",
        },
      ],
      activtykey:0,
      mainInfo: [],
      additionalChargeName: "",
      additionalChargePrice: 0,
      additionalChargeDescription: "",
      additionalChargeTaxable: false,
      filtertaglength: 0,
      checkboxChecked: false,
      editMode: false,
      show_upload_drawer: false,
      selectedButton: 2,
      updateComponentKey: 0,
      isModal: false,
      isNewDiscount: true,
      isContratualDiscount: false,
      isFooterDiscount: false,
      isDiscountIcons: true,
      isCustomerDiscount: false,
      isCardBasedDiscount: false,
      isProductDiscount: false,
      isPaymentMethodBasedDiscount: false,
      isRoleBasedDiscount: false,
      isRecurringDiscount: false,
      currPage: 1,
      rowsPerPage: 10,
      externalRowsPerPage: 10,
      searchFilterData: {},
      filterChecked: [
        {
          key: "Multi device feature",
          fieldtoFilter: "multidevicefeature.mediumtype",
        },
        {
          key: "Environment",
          fieldtoFilter: "environment.mediumtype",
        },
        {
          key: "color",
          fieldtoFilter: "color.mediumtype",
        },
        {
          key: "Ports",
          fieldtoFilter: "ports.mediumtype",
        },
      ],

      dropdownData: [
        { name: "Racheal" },
        { name: "Joey" },
        { name: "Ross" },
        { name: "Phoebe" },
        { name: "Monica" },
        { name: "Gunther" },
        { name: "cena" },
        { name: "taker" },
        { name: "ajstyle" },
        { name: "roman" },
        { name: "ambrose" },
        { name: "seth" },
        { name: "tripelh" },
        { name: "bigshow" },
        { name: "Mark" },
        { name: "Antony" },
        { name: "kane" },
      ],

      options3: [
        { name: "New quote", checked: true, disabled: false, id: 1 },
        { name: "MACD quote", checked: false, disabled: false, id: 2 },
      ],
      options4: [
        { name: "Mandatory", checked: true, disabled: false, id: 3 },
        { name: "Optional", checked: false, disabled: false, id: 4 },
      ],
      options5: [
        { name: "Yes", value: true, checked: false, disabled: false, id: 5 },
        { name: "No", value: false, checked: true, disabled: false, id: 6 },
      ],
      chargeType: [
        {
          name: "Onetime",
          value: "Onetime",
          checked: true,
          disabled: false,
          id: 9,
        },
        {
          name: "Recurring",
          value: "Recurring",
          checked: false,
          disabled: false,
          id: 10,
        },
      ],
      options6: [
        { name: "Move", checked: true, disabled: false, id: 7 },
        { name: "Add", checked: false, disabled: false, id: 8 },
        { name: "Change", checked: false, disabled: false, id: 9 },
        { name: "Delete", checked: false, disabled: false, id: 10 },
      ],

      tableData: [],
      searchvalue: "",
      tableHeader: [
        {
          checked:true,
          name:"Name",
          label: "Name",
          prop: "name",
          sortable: false,
          disabled:true,
          resizable: true,
          width: null,
          align: "left",
        },
        {
          checked:true,
          name:"Taxable",
          label: "Taxable",
          prop: "isTaxable",
          sortable: false,
          resizable: true,
          width: this.tableTaxable,
          align: "left",
        },
        {
          name:"Charges",
          label: "Charge type",
          prop: "charges",
          sortable: false,
          resizable: true,
          disabled:true,
          width: 150,
          align: "left",
          checked:true,
        },
        {
          checked:true,
          name:"Order type",
          label: "Order type",
          prop: "chargeType",
          sortable: false,
          resizable: true,
          width: 250,
          align: "left",
        },
        {
          checked:true,
          name:"Charge",
          label: "Charge",
          prop: "price",
          disabled:true,
          sortable: false,
          resizable: true,
          width: this.tableCharges,
          align: "left",
        },
      ],
      tableHeader_cc:[
        {
          checked:true,
          name:"Name",
          label: "Name",
          prop: "name",
          sortable: false,
          disabled:true,
          resizable: true,
          width: null,
          align: "left",
        },
        {
          checked:true,
          name:"Taxable",
          label: "Taxable",
          prop: "isTaxable",
          sortable: false,
          resizable: true,
          width: this.tableTaxable,
          align: "left",
        },
        {
          name:"Charges",
          label: "Charge type",
          prop: "charges",
          disabled:true,
          sortable: false,
          resizable: true,
          width: 150,
          align: "left",
          checked:true
        },
        {
          checked:true,
          name:"Order type",
          label: "Order type",
          prop: "chargeType",
          sortable: false,
          resizable: true,
          width: 250,
          align: "left",
        },
        {
          checked:true,
          name:"Charge",
          label: "Charge",
          disabled:true,
          prop: "price",
          sortable: false,
          resizable: true,
          width: this.tableCharges,
          align: "left",
        },
      ],
      // tableHeader1: [
      //   {
      //     label: "Name",
      //     prop: "name",
      //     sortable: false,
      //     resizable: false,
      //     width: 440,
      //     align: "left",
      //   },
      //   {
      //     label: "Taxable",
      //     prop: "taxable",
      //     sortable: false,
      //     resizable: false,
      //     width: 440,
      //     align: "left",
      //   },
      //   {
      //     label: "Charge (CAD)",
      //     prop: "fee",
      //     sortable: false,
      //     resizable: false,
      //     width: 100,
      //     align: "left",
      //   },
      // ],
      filterLayout: {
        // "@baseType": "supplier",
        layoutFor: "productoffering",
        filterQuery: {
          id: {
            $nin: [],
          },
        },
        layout: [
          {
            prop: "lifecycleStatus",
            label: "Status",
            type: "String",
            fieldToFilter: "lifecycleStatus",
          },
          {
            prop: "category",
            label: "Category",
            type: "Array",
            // fieldToFilter: "name"
          },
          {
            prop: "productOfferingPrice",
            label: "price",
            type: "Array",
            fieldToFilter: "name",
          },
        ],
      },
      // layout: [
      //     {
      //       prop: "lifecycleStatus",
      //       label: "Status",
      //       type: "String",
      //       fieldToFilter: "lifecycleStatus",
      //     },
      //     {
      //       prop: "category",
      //       label: "Category",
      //       type: "Array",
      //       // fieldToFilter: "name"
      //     },
      //     {
      //       prop: "productOfferingPrice",
      //       label: "price",
      //       type: "Array",
      //       fieldToFilter: "name",
      //     },
      //   ],
      offeringcurrPage: 1,
      offeringrowsPerPage: 10,
      delete_modal_active: false,
      deleterowindex: 0,
      delete_name: "",
      show_right: false,
      deleteobj: "",
      checkboxOptions: [
        { name: "Move", checked: false, disabled: false, id: 2 },
        { name: "Add", checked: false, disabled: false, id: 2 },
        { name: "Change", checked: false, disabled: false, id: 2 },
        { name: "Delete", checked: false, disabled: false, id: 2 },
        { name: "New", checked: true, disabled: false, id: 2 },
      ],
    };
  },
  computed: {
    tableDataOnSelectedRows() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
  },
  watch: {
    showContextMenu:{
      handler(val) {
        this.isContextMenuVisible = val
      },
      immediate: true,
      deep: true,
    },
    additionalChargesData: {
      handler(val) {
        this.searchData = JSON.parse(JSON.stringify(val));
        this.tableData = val;
        console.log(this.tableData, "additionalChargesData", this.searchData);
      },
      immediate: true,
      deep: true,
    },
    additionalChargeName: {
      handler(val) {
        this.additionalChargeName = val;
      },
      immediate: true,
      deep: true,
    },
    additionalChargePrice: {
      handler(val) {
        this.additionalChargePrice = val;
      },
      immediate: true,
      deep: true,
    },
    'additionalChargesDataQuery.price': function(newVal) {
    if (newVal < 0) {
      this.additionalChargesDataQuery.price = 0; 
    }
  }
    // mainInfo: {
    //   handler(val) {
    //     console.log("mainInfo",val)
    //     this.mainInfo = val;
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },
  async mounted() {

    // To get table header
    let sampleTheads = await MLTAxiosInstance.get(`/settings`);

if (sampleTheads?.data?.entityRef?.["additionalCharge"]) {
  let offerTableHeadModal = sampleTheads.data.entityRef["additionalCharge"];
  let offerTableHeadOrg = sampleTheads.data.entityRef["additionalCharge"].filter(ele => ele.checked);
  console.log(offerTableHeadOrg,"offerTableHeadModal",offerTableHeadModal)

  if (
    offerTableHeadModal.length ===  this.tableHeader_cc.length 
    // &&
    // offerTableHeadModal.every((item, index) => item.name ===  this.tableHeader_cc[index].name)
  ) {
    this.tableHeader_cc = JSON.parse(JSON.stringify(offerTableHeadModal));
    this.tableHeader = JSON.parse(JSON.stringify(offerTableHeadOrg));
    this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.tableHeader_cc ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.tableHeader))
  }

}

    this.supplierFilterData();
    console.log("Additional charges mounted");
    this.searchFilterData = await this.supplierFilterData();
    this.activiylogcall();
  },
  methods: {
    reAssignOrgTableHeader(){
              // If there is change in the table header then ressign to the Orginal data
              if((this.tableHeader_cc != this.tableHeaderOrgcc) && this.tableHeaderOrgcc.length > 0)
    {
      this.tableHeader_cc = JSON.parse(JSON.stringify(this.tableHeaderOrgcc))
    }
    if((this.tableHeader != this.tableHeaderOrg) && this.tableHeaderOrg.length > 0)
    {
      this.tableHeader = JSON.parse(JSON.stringify(this.tableHeaderOrg))
    }
      this.re_arrange=true
    },
    resizedWidth(val){
      // when table width is resized
      console.log(val.element,"resizedWidth",val)

      const updateWidth = (array) => {
    let item = array.find(i => i?.prop === val?.prop);
    if (item) item.width = val.width;
  };

  updateWidth(this.tableHeader);
  updateWidth(this.tableHeader_cc);
console.log(this.tableHeader,"resizedWidth loop",this.tableHeader_cc)
this.applyColumns(this.tableHeader_cc)
    },

    async applyColumns(val){
      // Rearrange table header
      console.log("applyColumns",val)
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
let concatedEntity = sampleTheads?.data?.entityRef
concatedEntity.additionalCharge=val

let reArrangeTableHeadPayload = { entityRef: concatedEntity };
const changeTableHeader = await MLTAxiosInstance.patch(`/settings`,reArrangeTableHeadPayload);
console.log(changeTableHeader,"reArrangeTableHeadPayload",reArrangeTableHeadPayload)
let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
console.log("sampleTheadsRefresh",sampleTheadsRefresh?.data)
console.log("sampleTheadsRefresh mountes",sampleTheadsRefresh?.data?.entityRef)
this.tableHeader_cc = sampleTheadsRefresh?.data?.entityRef["additionalCharge"]
this.tableHeader = sampleTheadsRefresh?.data?.entityRef["additionalCharge"].filter(ele=>ele.checked)
this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.tableHeader_cc ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.tableHeader))
    },

    async activitycurrentpage1(val) {
      console.log(val, "activitycurrentpage1..........");
      this.activitycurrentpage = val;
      console.log(val, "current");
      this.logpayload.paginatedQuery.currentPage = val;
      let activitylogdata = await MLTAxiosInstance.post(
        `util/filterData`,
        this.logpayload
      );
      console.log(
        activitylogdata.data.data,
        "123 activity log response data in offer2 current"
      );

      this.activityLogData = activitylogdata.data.data;
      this.activitytotal = activitylogdata.data.total;
    },
    async activityrowpage1(val) {

      this.activityrowpage = val;
      
      this.logpayload.paginatedQuery.rowsPerPage = val;
      let activitylogdata = await MLTAxiosInstance.post(
        `util/filterData`,
        this.logpayload
      );
      this.activityLogData = activitylogdata.data.data;
    
      this.activitytotal = activitylogdata.data.total;
    },
    async activiylogcall() {
      
      const logaccess = await MLTAxiosInstance.post(
        `/util/filterData`,
        this.logpayload
      );
      this.activityLogData = logaccess.data.data;
      this.activitytotal = logaccess.data.total;
      console.log(logaccess.data.data);
    },
    closeactivitylog() {
      this.show_right = false;
    },
    emitCheckbox(val) {
      if (val) {
        if (this.additionalChargesDataQuery.chargeType) {
          if (val.checked === true) {
            this.additionalChargesDataQuery.chargeType.push({ name: val.name });
          } else {
            this.additionalChargesDataQuery.chargeType =
              this.additionalChargesDataQuery.chargeType.filter(
                (item) => item?.name !== val.name
              );
          }
        }
      }
    },

    serialNumberAdditionalCharges(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    computedProductPriceAdditionalCharges(offer) {
      if (offer) {
        return this.currencyFormatter(offer, {
          currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
        });
      }

      return this.currencyFormatter(0);
    },
    searchKey(val) {
      this.searchvalue = val;
      console.log(this.searchData)
      if (val) {
        this.search = true;
        const result = this.searchNestedObjects(this.searchData,val.toLowerCase());
        this.tableData = result;
      } else {
        this.search = false;
        this.tableData = this.searchData;
      }
    },
    searchNestedObjects(objects, key) {
  return objects.reduce((acc, obj) => {
    const foundInObject = Object.entries(obj).some(([k, v]) => {
      if (typeof v === "object" && v !== null) {
        if (Array.isArray(v)) {
          return v.some(item => {
            console.log("v form rec",v,typeof key)
            return this.searchNestedObjects([item], key).length > 0;
          });
        }
        return this.searchNestedObjects([v], key).length > 0;
      } else if (typeof v === "string" && k !== "password") {
        console.log("v form string",v,typeof key)
        return v.toLowerCase().includes(key.toLowerCase());
      } else if (typeof v === "number") {
        console.log("v form number",v,typeof key)
        return v.toString() == key.toString()
      }
      return false;
    });

    if (foundInObject) {
      acc.push(obj);
    }

    return acc;
  }, []);
},
    cancelDeleteModal() {
      this.delete_modal_active = false;
    },
    leaf(text) {
      let lengthOfText = text?.length;
      if (typeof text === "string") {
        return text;
      }
      if (lengthOfText === 1) {
        return (text = text.toString());
      }
      if (text && lengthOfText > 1) {
        const total = text?.length;

        return text[total - 1];
      }
    },
    getMinMax(arr) {
      if (!arr || arr.length === 0) {
        return this.currencyFormatter(0, {
          currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
        });
      }

      let values = arr.map((item) => item?.value);
      let min = Math.min(...values);
      let max = Math.max(...values);
      min = min !== undefined && min !== null && !isNaN(min) ? min : 0;
      max = max !== undefined && max !== null && !isNaN(max) ? max : 0;
      if (min === max) {
        if (min == 0) {
          return this.currencyFormatter(0, {
            currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          });
        }
        return this.currencyFormatter(min, { currency: arr[0]?.unit });
        // return `  ${min} ${arr[0]?.unit}`;
      } else {
        if (min == 0) {
          return `${this.currencyFormatter(0, {
            currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          })} - ${this.currencyFormatter(max, { currency: arr[0]?.unit })}`;
        }
        if (max == 0) {
          return `${this.currencyFormatter(min, {
            currency: arr[0]?.unit,
          })}-${this.currencyFormatter(0, {
            currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          })}`;
        }
        return `${this.currencyFormatter(min, {
          currency: arr[0]?.unit,
        })} - ${this.currencyFormatter(max, { currency: arr[0]?.unit })}`;
      }
    },
    async getSearchedValue(value) {
      try {
        const filterPostQuery = await UPCAxiosInstance.post(
          `util/filterData`,
          value
        );

        const responseData = filterPostQuery.data.data;
        // console.log(responseData,"responseData")
        // console.log(responseData,"responseData 2")
        this.mainInfo = responseData.map(
          ({
            name,
            productOfferingPrice,
            prodSpecCharValue,
            id,
            category,
            productSpecification,
          }) => {
            const productType = productSpecification["@baseType"];
            const categoryType = this.leaf(category ? category : "");
            let price = "0";
            let unit;

            if (productOfferingPrice?.length > 0) {
              // const productPrice = productOfferingPrice[0]?.tiers;
              const updatedProductPrice = productOfferingPrice;
              if (updatedProductPrice) {
                let priceRange = updatedProductPrice
                  ?.map((e) => e?.tiers?.map((tier) => tier?.price))
                  .flat(Infinity);
                price = this.getMinMax(priceRange);
                unit = priceRange[0]?.unit;
              }

              price =
                price !== undefined || price !== null || !isNaN(price)
                  ? price
                  : 0;

              unit = unit !== undefined || unit !== null ? unit : unit;
              console.log(price, "prodSpecCharValue", unit);
              return {
                name: name,
                price:
                  prodSpecCharValue[0]?.billingPeriod !== undefined &&
                  prodSpecCharValue[0]?.billingPeriod !== null
                    ? productType === "Tangible"
                      ? price
                      : price + ` / ${prodSpecCharValue[0]?.billingPeriod}`
                    : price + ` / ${""}`,
                _id: id,
                category: categoryType,
              };
            } else {
              return {
                name: name,
                price:
                  prodSpecCharValue[0]?.billingPeriod !== undefined &&
                  prodSpecCharValue[0]?.billingPeriod !== null
                    ? productType === "Tangible"
                      ? this.currencyFormatter(0)
                      : this.currencyFormatter(0) +
                        ` / ${prodSpecCharValue[0]?.billingPeriod}`
                    : this.currencyFormatter(0) + ` / ${""}`,
                _id: id,
                category: categoryType,
              };
            }
          }
        );

        // console.log(this.productName,"productName")
        // console.log(this.relatedProducts,"this.relatedProducts")
        // console.log(this.mainInfo,"this.relatedProducts 1")
      } catch (error) {
        console.log(error, "error from getsearchedvalue");
      }
    },
    async supplierFilterData() {
      try {
        console.log("kjhfj");
        const supplierFilterRes = await UPCAxiosInstance.post(
          "util/filterLayout",
          this.filterLayout
        );
        // state.suppliersFilterData = supplierFilterRes.data;
        console.log(
          "productRecommed 3.0 supplierfilterdata ",
          supplierFilterRes
        );
        console.log("productRecommed 3.0 2", supplierFilterRes.data);

        return await supplierFilterRes.data;
      } catch (error) {
        console.error(error);
      }
    },

    getcheckedValue(value) {
      console.log(value, "Additional Charges top search and filter");
      // this.additionalChargesDataQuery.appliesTo = [];
      // this.additionalChargesDataQuery.appliesTo;
      console.log("Additional Charges top search and filter", value);
      // this.additionalChargesDataQuery.appliesTo=this.additionalChargesDataQuery.appliesTo.concat(value);
      // this.additionalChargesDataQuery.appliesTo=this.additionalChargesDataQuery.appliesTo.concat(value)
      this.additionalChargesDataQuery.appliesTo = value;
      console.log("Additional Charges top search and filter", value);
      console.log(
        "Additional Charges top query",
        this.additionalChargesDataQuery
      );
    },
    isTaxable(val) {
      console.log("Additional Charges last", val);

      if (val.name.toLowerCase() === "yes") {
        this.additionalChargesDataQuery.isTaxable = true;
      } else {
        this.additionalChargesDataQuery.isTaxable = false;
      }

      console.log("Additional Charges ", this.additionalChargeTaxable);
    },
    activityLogDrawer() {
      this.activiylogcall();
      this.show_right = true;
      this.activtykey+=1
    },
    isChargetype(val) {
      console.log("Additional Charges last", val);

      if (val.name.toLowerCase() === "onetime") {
        this.additionalChargesDataQuery["@type"] = "Onetime";
      } else {
        this.additionalChargesDataQuery["@type"] = "Recurring";
      }

      console.log("Additional Charges ", this.additionalChargeTaxable);
    },
    addAdditionalChargesInfo() {
      if (!("@type" in this.additionalChargesDataQuery)) {
        console.log(
          "Additional Charges last if",
          this.additionalChargesDataQuery
        );
        this.additionalChargesDataQuery["@type"] = "Onetime";
      } else {
        console.log("else");
      }
      console.log("Additional Charges last ", this.additionalChargesDataQuery);
      this.$emit("additional-charge-new-data", this.additionalChargesDataQuery);

      this.updateKey++;
      this.updateDrawerKey++;
      this.show_upload_drawer = false;
      this.editMode = false;
    },
    deleteExecRow() {
      console.log("delete roz", this.deleteobj);
      this.$emit("delete-button", this.deleteobj);
      this.delete_modal_active = false;
      this.isModal = false;
    },

    closeuploaddrawer() {
      this.additionalChargesDataQuery.chargeType = [{ name: "New" }];
      this.show_upload_drawer = false;
      this.editMode = false;
      this.updateKey++;
      this.updateDrawerKey++;
    },
    openuploaddrawer() {
      this.preValOffers = [];
      this.checkboxOptions = [
        { name: "Move", checked: false, disabled: false, id: 2 },
        { name: "Add", checked: false, disabled: false, id: 2 },
        { name: "Change", checked: false, disabled: false, id: 2 },
        { name: "Delete", checked: false, disabled: false, id: 2 },
        { name: "New", checked: true, disabled: false, id: 2 },
      ];
      // this.additionalChargesDataQuery.name = "";
      //   this.additionalChargesDataQuery.price = "";
      //   this.additionalChargesDataQuery.description ="";
      //   this.additionalChargesDataQuery.taxable=false
      this.mainInfo.forEach((ele) => (ele.checked = false));

      (this.additionalChargesDataQuery = {
        name: "",
        description: "",
        appliesTo: [],
        chargeType: [{ name: "New" }],
        isTaxable: false,
        price: 0,
      }),
        (this.show_upload_drawer = true);
      this.checkboxChecked = false;
      this.updateKey++;
      this.updateDrawerKey++;
    },
    openuploaddraweredit(ele) {
      this.checkboxOptions = [
        { name: "Move", checked: false, disabled: false, id: 2 },
        { name: "Add", checked: false, disabled: false, id: 2 },
        { name: "Change", checked: false, disabled: false, id: 2 },
        { name: "Delete", checked: false, disabled: false, id: 2 },
        { name: "New", checked: false, disabled: false, id: 2 },
      ];

      this.checkboxOptions = this.checkboxOptions.map((option) => {
        if (ele.chargeType.some((charge) => charge.name === option.name)) {
          return { ...option, checked: true };
        }
        return option;
      });

      console.log("openuploaddraweredit", ele);
      this.$emit("atclick-edit-button", ele);
      this.mainInfo.forEach((val) => {
        val.checked = false;
      });
      console.log(this.mainInfo);
      let checkedArray = [];
      console.log(checkedArray);
      this.editMode = true;
      this.show_upload_drawer = true;
      this.additionalChargesDataQuery = ele;
      checkedArray = ele.appliesTo;
      console.log(ele.appliesTo);
      ele.appliesTo.forEach((checkedItem) => {
        const matchingItem = this.mainInfo.find(
          (item) => item._id === checkedItem._id
        );
        if (matchingItem) {
          matchingItem.checked = true;
        } else {
          this.mainInfo.unshift(checkedItem);
        }
      });
      // this.preValOffers= ele.appliesTo
      // console.log(this.mainInfo);
      this.updateKey++;
      this.updateDrawerKey++;
    },
    editedAdditionalChargesInfo() {
      this.additionalChargesDataQuery.context = false
      console.log("Additional Charges last ", this.additionalChargesDataQuery);
      this.$emit("edit-button", this.additionalChargesDataQuery);
      this.show_upload_drawer = false;
      this.editMode = false;
      this.updateKey++;
      this.updateDrawerKey++;
    },
    currentPage(val) {
      this.currPage = val;
    },
    changeRowsPerPage(count) {
      this.rowsPerPage = count;
    },
    selectButton(buttonIndex) {
      this.selectedButton = buttonIndex;
      this.isNewDiscount = buttonIndex === 2;
      this.isContratualDiscount = buttonIndex === 1;
    },

    goToPreviousDrawer() {
      this.updateComponentKey += 1;
      this.isDiscountIcons = true;
      this.isProductDiscount = false;
      this.isCardBasedDiscount = false;
      this.isCustomerDiscount = false;
      this.isRoleBasedDiscount = false;
      this.isRecurringDiscount = false;
      this.isPaymentMethodBasedDiscount = false;
    },
    async closeAdditionalCrudContextMenu($event, ele, i) {
      if ($event.toLowerCase() === "edit") {
        this.editMode = true;
        this.show_upload_drawer = true;

        this.openuploaddraweredit(ele);

        console.log("openuploaddraweredit", ele);

        this.additionalChargesDataQuery = JSON.parse(
          JSON.stringify({ ...ele })
        );
      } else if ($event.toLowerCase() === "delete") {
        this.delete_modal_active = true;
        console.log("Delete Edit emit ", $event, "event", "ele", ele, "i", i);

        this.deleteobj = ele;
        this.delete_name = ele.name;
        // this.deleterowindex = this.serialNumber(i);
      }
    },
    crudAdditionalChargesContextMenu($event, index) {
      console.log("Form additional charges");
      if(this.isContextMenuVisible){
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    }
    else{
      this.notification = {
        type: "warning",
        message: `Heads up! You don't have permission to perform this action!`,
      };
    }
    },
    closeModal() {
      this.isModal = false;
    },
    serialNumber(index) {
      return (this.offeringcurrPage - 1) * this.offeringrowsPerPage + index + 1;
    },

    computedAdditionalTableAction(data) {
      data;
   if (this.permisstionData.delete && this.permisstionData.edit) {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      } if (this.permisstionData.delete){
        return [
        
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }else {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
        ];
      }
      // let options = [
      //   {
      //     label: "Edit",
      //     icon: "icon-file-text-edit-regular",
      //   },
      //   {
      //     label: "Delete",
      //     icon: "icon-trash-regular",
      //   },
      // ];
      // return options.filter((option) => {
      //   if (
      //     data &&
      //     data.status &&
      //     data.status.toLowerCase() == "draft" &&
      //     ["Draft", "Mark as In-active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else if (
      //     data &&
      //     data.status &&
      //     data.status.toLowerCase() == "active" &&
      //     ["Draft", "Mark as active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else if (
      //     data &&
      //     data.status &&
      //     data.status.toLowerCase() == "inactive" &&
      //     ["Draft", "Mark as In-active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // });
   
   
    },
    computedAdditionalReadOnlyTableAction(data) {
      let options = [
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ];
      return options.filter((option) => {
        if (
          data &&
          data.status &&
          data.status.toLowerCase() == "draft" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "active" &&
          ["Draft", "Mark as active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "inactive" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else {
          return true;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.additional-charges-pagination-container {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-left: 86px !important;
  padding-right: 24px !important;
  z-index: 9;
  background-color: white;
}

.blue_link:hover {
  text-decoration: underline;
}

.change-searchfilter-height {
  .search-drop-down {
    max-height: 250px;
  }
}
</style>
