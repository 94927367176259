<template>
    <section class="layout-seperation">
      <section class="layout-left-panel">
        <div
          class="left-panel-container"
          @click="previousLevel"
        >
          <i class="icon-arrow-left-regular"></i>
          <p>Back</p>
        </div>
      </section>
      <section class="layout-right-panel">
        <section class="right-panel-container">
          <section class="header">
            <section class="header-left-section">
              <p class="title">Import template</p>
              <p class="sub-title">Configure import template.</p>
            </section>
          </section>
          <section class="content">
            <div class="card-holder">
              <div class="cards-container">
                <hlx-card
                  v-for="(card, index) in card_data"
                  :key="index"
                  class="custom-card"
                  :seperator="false"
                  :card-title="false"
                  :card-footer="false"
                  :hover="true"
                  height="210"
                  @click="nextLevel(card)"
                >
                  <template #card-body>
                    <div style="padding: 0 15px">
                      <div class="circleInCard">
                        <i :class="card.icon"></i>
                      </div>
                      <p class="card-head">
                        <b>{{ card.name }}</b>
                      </p>
                      <p class="card-desc copy-click importTemplatesCardDescription">
                        {{ card.description }}
                      </p>
                    </div>
                  </template>
                  <template #card-footer>
                    {{ card.card_footer }}
                  </template>
                </hlx-card>
              </div>
            </div>
          </section>
        </section>
      </section>
    </section>
  </template>
  
  <script>
  import { UPCAxiosInstance } from '@/config/axiosConfig';
  
  export default {
    data() {
      return {
        card_data: [], // Current display data for cards
        navigationHistory: [], // Stack to store the navigation history
        originalCardData: [], // Initial data from the API
      };
    },
    async mounted() {
      let navigationData = await UPCAxiosInstance.get('/customHierarchy');
      this.originalCardData = JSON.parse(
        JSON.stringify(navigationData.data[0].module[0].main)
      );
      let filteredOriginalData = this.originalCardData.filter((item) => {
        return !item.title;
      });
      this.card_data = JSON.parse(JSON.stringify(filteredOriginalData));
    },
    methods: {
      previousLevel() {
        // Check if there is any previous level in history
        if (this.navigationHistory.length > 0) {
          this.card_data = this.navigationHistory.pop(); // Restore the last level
        } else {
          this.$router.back();
        }
      },
      nextLevel(card) {
        console.log(card,"card")
        if (card.child !== undefined) {
          // Save the current level to the history stack
          this.navigationHistory.push(JSON.parse(JSON.stringify(this.card_data)));
  
          // Filter and set the next level data
          let filteredChildData = card.child.filter((item) => {
            return !item.title;
          });
          this.card_data = JSON.parse(JSON.stringify(filteredChildData));
        } else {
          this.$router.push(`importTemplate/definition/${card.customFieldModule}`)
        }
      },
    },
  };
  </script>
  
  <style lang="scss">
  .importTemplatesCardDescription {
    max-width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  </style>
  