<template>
  
  <!-- //alert -->
  <hlx-alert-notification :notify="notification" :auto-close="true" />

<!-- delete modal window`` -->
 
<hlx-modal
        style="z-index: 999999999"
        :modal-active="delete_modal_active"
        :height="'220px'"
        :width="'400px'"
        :modal="true"
        @close="delete_modal_active = false"
      >
        <template #header>
          <div style="margin-left: 0px">Delete data</div>
        </template>
        <template #content>
          <div class="modal-content">
            <p>
              Are you sure you want to delete <b>{{ delete_name }}</b
              >?
            </p>
          </div>
        </template>
        <template #footer>
          <hlx-button class="secondary sm" @click="delete_modal_active = false"
            >Cancel</hlx-button
          >
          <hlx-button
            class="primary sm"
            style="margin-left: 16px"
            @click="deleteExecRow"
            >Delete</hlx-button
          >
        </template>
      </hlx-modal>
      <!-- modal window delete end -->


      <!-- Filter drawer -->
    <hlx-drawer
                    :show="show_right_filter"
                    :width="300"
                    position="right"
                    :header="false"
                    :footer="false"
                    :show-close-icon="true"
                    @close="closeRightFilter"
                  >
                    <template #body>
                      <div style="display: flex">
                        <FilterComponent
                        :key="filterkey"
                          :filterpanelname="'workflow'"
                          :company-name="company"
                          :query-value="query"
                          :display-data="filter"
                          :module="'workflow'"
                          @filtered-data="quotesfilterData"
                        ></FilterComponent>
                      </div>
                    </template>
                  </hlx-drawer>




  <div>
    <!-- back button -->
    <div style="" class="backButtonInApprovalTableView" @click="backToSettings">
      <span style="font-size: 20px"
        ><i class="icon-arrow-left-regular"></i
      ></span>
      <span style="padding: 2px 0px 0px 10px;font-size: 15px;">Back</span>
    </div>

    <!-- head -->
    <div class="util-bar headerForApprovalTableView">
      <!-- left -->
      <span class="switch-container">
        <div class="common_single_header_selfservice">
          <div class="head_selfservice">Approval</div>
          <div class="body_selfservice">Configure your quote workflow</div>
        </div>
      </span>

      <!-- right -->
      <span class="headerForApprovalTableViewRightSide">
        <div style="width: 100%; padding-left: 10px">
          <hlx-search
            v-model="searchvalue"
            @search-key="searchKey"
          />
        </div>

        <span class="filter-table">
          <hlx-button
          class="secondary sm add-btn"
            @click="showDrawerRight">
            <i class="icon-filter-regular"></i>
            <span v-if="filtertaglength != 0" class="filter-count">{{
                        filtertaglength
                      }}</span>
          </hlx-button>
        </span>
        <span>
          <hlx-button class="primary sm" @click="createNewRule">
            <i class="icon-plus-circle-regular"></i> <span style="padding-left: 6px;">New rule</span></hlx-button
          >
        </span>
      </span>
    </div>

    <!-- table -->
    <section v-if="tableData && tableData?.length > 0" style="margin: 10px 15px">
      <hlx-table
        :key="refreshTable1"
        :column-count="tableHeads + 2"
        :border="['table', 'header', 'horizontal']"
        :theme="'grey'"
        :bold-headers="false"
        :row-hover="true"
        :striped-rows="false"
        :re-arrange-columns="re_arrange"
        :re-arrange-heads="theads"
        @close-rearrange="arrange"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head
            :width="60"
            :align="'left'"
            style="padding: 0 15px !important; max-width: 60px !important"
            :fixed="'left'"
            >{{ "S.no" }}</hlx-table-head
          >
          <hlx-table-head
            v-for="(i, index) in tableHeads"
            :key="index"
            :prop="i.prop"
            :width="i.width"
            :align="'left'"
            :resizable="true"
          >
            {{ i.label }}
          </hlx-table-head>
          <hlx-table-head :align="'center'" :width="60" :fixed="'right'"
            ><span class="action-icon">
              <i
                class="icon-settings-regular"
                style="cursor: default"
              ></i> </span
          ></hlx-table-head>
        </template>
        <template #tbody>
          <tr v-for="(i, index) in tableDataOnSelectedRows" :key="index">
            <hlx-table-cell :width="60" fixed="left">
              {{ serialNumber(index) }}
            </hlx-table-cell>
            <hlx-table-cell
              v-for="(j, col_index) in tableHeads"
              :key="col_index"
              :align="'left'"
              :width="i.width"
            >
              <span v-if="j.prop === 'name'">
                {{ i[j.prop] }}                
              </span>

              
              <div v-else-if="j.prop == 'description'" style="width: 250px">
                {{ i?.definition?.name }}
              </div> 

              <div v-else-if="j.prop == 'tags'" style="width: 200px">
                <hlx-tagcount
                        :arrayfile="convertToNamedObjects(i.tag)"
                        :item-key="'name'"
                        :type="'label'"
                      ></hlx-tagcount>
              </div>
              
              <div v-else-if="j.prop == 'createdBy'" style="width:162px">
                  {{ $cookies.get("company") }}
              </div> 

              <div v-else-if="j.prop == 'enableRule'"  style="width: 150px">
                <hlx-input
                v-model:value="showDraggableFunction"
                :checked="i[j.prop]"
                :disabled="false"
                :type="'switch'"
              />
              </div> 

              <span v-else> 
                {{ i[j.prop] }}                 
              </span>
            </hlx-table-cell>
            <hlx-table-cell :align="'center'" :width="120" :fixed="'right'">
              <span :id="'table-context' + index" class="action-icon">
                <i
                  style="position: relative"
                  class="icon-more-vertical-filled"
                  :class="i.context === true ? 'active-action' : ''"
                  @click="crudContextMenu($event, i, index)"
                >
                  <hlx-context-menu
                    :top="containertop"
                    :left="containerleft"
                    :conditionvalue="150"
                    :conditiondown="2"
                    :conditionup="-38"
                    :options="computedTableAction(i)"
                    :data="index.toString()"
                    :show="i.context == true"
                    @chosen="closeCrudContextMenu($event, i, index)"
                  />
                </i>
              </span>
            </hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
    </section>
    <!-- no data -->
    <section v-else style="margin: 10px 15px">
      <hlx-table
          :column-count="tableHeads.length + 2"
          :border="['table', 'header']"
          :bold-headers="false"
          :row-hover="true"
          theme="grey"
          :striped-rows="false"
          :re-arrange-columns="toggleRearrangeModal"
          :re-arrange-heads="tableHeadsRearranging"
          @close-rearrange="toggleRearrangeModal = false"
          @apply-rearranged-columns="applyColumns"
        >
          <template #thead>
            <hlx-table-head :width="60" :align="'left'"   @new-resize-width="resizedWidth">{{
              "S.no"
            }}</hlx-table-head>
            <hlx-table-head
              v-for="(i, index) in tableHeads"
              :key="index"
              :prop="i.prop"
              :sortable="i.sortable"
              :resizable="i.resizable"
              style="position: sticky; top: 0px"
              :width="i.width !== undefined ? i.width : ''"
              :align="i.align !== undefined ? i.align : ''"
              @sorting_func="sorting_Data"
                @new-resize-width="resizedWidth"
            >
              {{ i.label }}
            </hlx-table-head>
            <hlx-table-head v-show="supplierStatus == false" :width="100">
              <span class="action-icon"
                ><i
                  class="icon-settings-regular"
                  @click="toggleRearrangeModal = true"
                    @new-resize-width="resizedWidth"
                ></i></span
            ></hlx-table-head>
          </template>
          <template #tbody>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell :colspan="tableHeads?.length + 2">{{
                "No data"
              }}</hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
          </template>
        </hlx-table>
    </section>

    <!-- gap between table and pagination -->
    <div style="height: 90px"></div>

    <!-- Pagination -->
    <div
      v-if="tableData && tableData?.length > 0"
      class="media-pagination-container"
      style="padding: 1rem 0; z-index: 899"
    >
      <span class="row2" style="margin-left: 92px">
        <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
          :total="totalpageno"
          enable-rows-per-page
          :rows-per-page-list="[10, 15, 20]"
          :options="{
            attributes: {
              rowsPerPageTitle: true,
              rowsPerPageResult: true,
              rowsPerPageDropdown: {
                position: {
                  top: 0,
                  right: 1,
                },
              },
            },
          }"
          @current-page="currentPage"
          @updated:rows-per-page="changeRowsPerPage"
        ></hlx-pagination>
      </span>
    </div>
  </div>
</template>

<script>
import { BPMNAxiosInstance } from "@/config/axiosConfig";
import FilterComponent from "@/components/filterPanelComponent.vue";
export default {
  components: {
    FilterComponent,
  },
  data() {
    return {
      filtertaglength:0,
      show_right_filter:false,
      company: this.$cookies.get("company"),
      filterkey: 0,
      query:{
        'Status':["name"],
        "Contact medium":["contactMedium"]
      },
      filter:{},
      well:
      {
    "_id": "673b161ec388239b3a993d81",
    "name": "Quote discount approval process V1",
    "definition": {
        "id": "quoteSequentialApprovalProcess",
        "name": "Quote Sequential Approval Process",
        "tasks": [
            {
                "id": "sendApprovalEmailTask1",
                "type": "serviceTask",
                "name": "Send Approval Email1",
                "serviceType": "Email",
                "serviceOperation": "sendEmail",
                "templateId": "quoteApprovalEmailTemplate",
                "to": {
                    "dataFrom": "context",
                    "valueType": "expression",
                    "expression": "context.approver[0].email"
                },
                "subject": {
                    "dataFrom": "context",
                    "valueType": "expression",
                    "expression": "'Document Approval Request - '+quoteData.description"
                },
                "body": {
                    "dataFrom": "context",
                    "valueType": "expression",
                    "expression": "const approverName = context.approver[0].name;const documentTitle = quoteData.description; const documentID = quoteData.displayId;const creatorName = quoteData.authorization.find(auth=>auth.name == 'sellerContact')?.name; const companyName = quoteData.authorization.find(auth=>auth.name == 'seller')?.name; const reviewLink = 'https://upc-halleyx-ghubgwxrcq-uc.a.run.app/UPC/Quotes'; const emailContent = `<p>Hello ${approverName},</p> <p>You have a new document awaiting your approval.</p> <p> Document Title: ${documentTitle}<br> Document ID: ${documentID}<br> Created by: ${creatorName} </p> <p>Click <a href=${reviewLink}>here</a> to review the document.</p> <p>Best,<br>${companyName}</p> `; emailContent"
                }
            },
            {
                "id": "QuoteReviewUserTask1",
                "type": "userTask",
                "name": "Quote review and approval",
                "boundaryEvents": [
                    {
                        "id": "QuoteReviewReminder",
                        "type": "timerBoundaryEvent",
                        "cancelActivity": false,
                        "attachedToRef": "QuoteReviewUserTask1",
                        "eventDefinition": {
                            "type": "timer",
                            "timerDefinition": {
                                "type": "duration",
                                "value": "P02M"
                            }
                        }
                    },
                    {
                        "id": "QuoteReviewCancel",
                        "type": "timerBoundaryEvent",
                        "cancelActivity": true,
                        "attachedToRef": "QuoteReviewUserTask1",
                        "eventDefinition": {
                            "type": "timer",
                            "timerDefinition": {
                                "type": "duration",
                                "value": "P03M"
                            }
                        }
                    }
                ]
            },
            {
                "id": "SendReminderEmail",
                "type": "serviceTask",
                "name": "Send reminder email",
                "serviceType": "Email",
                "serviceOperation": "sendEmail",
                "templateId": "quoteReminderEmailTemplate",
                "to": {
                    "dataFrom": "context",
                    "valueType": "expression",
                    "expression": "context.approver[0].email"
                },
                "subject": {
                    "dataFrom": "context",
                    "valueType": "expression",
                    "expression": "`Reminder: Document Approval Request - +${quoteData.description}`"
                },
                "body": {
                    "dataFrom": "context",
                    "valueType": "expression",
                    "expression": "const approverName = context.approver[0].name; const documentTitle = quoteData.description; const reviewLink = 'https://upc-halleyx-ghubgwxrcq-uc.a.run.app/UPC/Quotes'; const emailContent = `<p>Hello ${approverName},</p> <p>This is a reminder for the document awaiting your approval.</p> <p> Document Title: ${documentTitle} </p> <p>Click <a href=${reviewLink}>here</a> to review the document.</p> `; emailContent"
                }
            },
            {
                "id": "SendReminderEmail2",
                "type": "serviceTask",
                "name": "Send reminder email",
                "serviceType": "Email",
                "serviceOperation": "sendEmail",
                "templateId": "quoteReminderEmailTemplate",
                "to": {
                    "dataFrom": "context",
                    "valueType": "expression",
                    "expression": "context.approver[0].email"
                },
                "subject": {
                    "dataFrom": "context",
                    "valueType": "expression",
                    "expression": "\"Reminder 2 person: Document Approval Request - \"+quoteData.description"
                },
                "body": {
                    "dataFrom": "context",
                    "valueType": "expression",
                    "expression": "const approverName = context.approver[0].name; const documentTitle = quoteData.description; const reviewLink = 'https://upc-halleyx-ghubgwxrcq-uc.a.run.app/UPC/Quotes'; const emailContent = `<p>Hello ${approverName},</p> <p>This is a reminder for the document awaiting your approval.</p> <p> Document Title: ${documentTitle} </p> <p>Click <a href=${reviewLink}>here</a> to review the document.</p> `; emailContent"
                }
            },
            {
                "id": "sendApprovalEmailTask2",
                "type": "serviceTask",
                "name": "Send Approval Email2",
                "serviceType": "Email",
                "serviceOperation": "sendEmail",
                "to": {
                    "dataFrom": "context",
                    "expression": "context.approver[0].email"
                },
                "subject": {
                    "dataFrom": "context",
                    "expression": "Document Approval Request - +quoteData.description"
                },
                "body": {
                    "dataFrom": "context",
                    "expression": "const approverName = context.approver[0].name;const documentTitle = quoteData.description; const documentID = quoteData.displayId;const creatorName = quoteData.authorization.find(auth=>auth.name == 'sellerContact')?.name; const companyName = quoteData.authorization.find(auth=>auth.name == 'seller')?.name; const reviewLink = 'https://upc-halleyx-ghubgwxrcq-uc.a.run.app/UPC/Quotes'; const emailContent = `<p>Hello ${approverName},</p> <p>You have a new document awaiting your approval.</p> <p> Document Title: ${documentTitle}<br> Document ID: ${documentID}<br> Created by: ${creatorName} </p> <p>Click <a href=${reviewLink}>here</a> to review the document.</p> <p>Best,<br>${companyName}</p> `; emailContent"
                }
            },
            {
                "id": "updateQuoteStateTask",
                "type": "serviceTask",
                "name": "Update Quote State",
                "serviceType": "API",
                "serviceOperation": "updateQuoteState",
                "apiConfig": {
                    "url": "https://tmf-upc-ghubgwxrcq-pd.a.run.app/quotemanagement",
                    "method": "PATCH",
                    "headers": {
                        "Content-Type": "application/json",
                        "hlx-api-key": "8bff818c672b5cdf9c397cee1e85578b:7f5daa9e0b0b4956cfe92e85da6e36631b888fe0a79946cdfc07d3fc8858350616f6aacadcaaa98ee2f6998e7da0a4747b5b593067e260d9353d4509d134ea23fd3e7efdd99c98e1b08c902a0799a450322487e1477a87c886806bbc70dc01ec00f3531fb5fa4873a33426739f4ff93ec981e8ed1458a0f836e5c5fb33706c33"
                    },
                    "params": {
                        "company": "developerHalleyx"
                    },
                    "body": {
                        "id": "66fd396981912a2d45ce2399",
                        "state": "accepted"
                    }
                }
            },
            {
                "id": "QuoteReviewUserTask2",
                "type": "userTask",
                "name": "Quote review and approval2",
                "boundaryEvents": [
                    {
                        "id": "QuoteReviewReminder2",
                        "type": "timerBoundaryEvent",
                        "cancelActivity": false,
                        "attachedToRef": "QuoteReviewUserTask2",
                        "eventDefinition": {
                            "type": "timer",
                            "timerDefinition": {
                                "type": "duration",
                                "value": "P02M"
                            }
                        }
                    },
                    {
                        "id": "QuoteReviewCancel2",
                        "type": "timerBoundaryEvent",
                        "cancelActivity": true,
                        "attachedToRef": "QuoteReviewUserTask2",
                        "eventDefinition": {
                            "type": "timer",
                            "timerDefinition": {
                                "type": "duration",
                                "value": "P03M"
                            }
                        }
                    }
                ]
            }
        ],
        "events": [
            {
                "id": "start_1",
                "type": "startEvent",
                "name": "Process Start"
            },
            {
                "id": "end_1",
                "type": "endEvent",
                "name": "Process End",
                "terminal": true
            },
            {
                "id": "restart",
                "type": "restartEvent",
                "name": "Process restart"
            },
            {
                "id": "reminded",
                "type": "endEvent",
                "name": "reminder email ended"
            },
            {
                "id": "reviewCancelled",
                "type": "endEvent",
                "name": "Cancel the review task"
            },
            {
                "id": "reminded2",
                "type": "endEvent",
                "name": "reminder 2 email ended"
            },
            {
                "id": "reviewCancelled2",
                "type": "endEvent",
                "name": "Cancel the 2 review task"
            }
        ],
        "gateways": [
            {
                "id": "isValidProcessGateway",
                "type": "exclusiveGateway",
                "name": "Process validator gateway"
            },
            {
                "id": "isValidApprovalGateway1",
                "type": "exclusiveGateway",
                "name": "Approval validator gateway1"
            },
            {
                "id": "isValidApprovalGateway2",
                "type": "exclusiveGateway",
                "name": "Approval validator gateway2"
            }
        ],
        "sequenceFlows": [
            {
                "sourceRef": "start_1",
                "targetRef": "isValidProcessGateway"
            },
            {
                "sourceRef": "isValidProcessGateway",
                "targetRef": "sendApprovalEmailTask1",
                "conditionType": "Expression",
                "conditionExpression": "quoteData.overAllDiscount>=5",
                "condition": true,
                "inputType": "context"
            },
            {
                "sourceRef": "isValidProcessGateway",
                "targetRef": "restart",
                "conditionType": "Expression",
                "conditionExpression": "quoteData.overAllDiscount>=5",
                "condition": false,
                "inputType": "context",
                "isDefault": true
            },
            {
                "sourceRef": "sendApprovalEmailTask1",
                "targetRef": "QuoteReviewUserTask1"
            },
            {
                "sourceRef": "QuoteReviewReminder",
                "targetRef": "SendReminderEmail"
            },
            {
                "sourceRef": "QuoteReviewReminder2",
                "targetRef": "SendReminderEmail2"
            },
            {
                "sourceRef": "SendReminderEmail",
                "targetRef": "reminded"
            },
            {
                "sourceRef": "SendReminderEmail2",
                "targetRef": "reminded2"
            },
            {
                "sourceRef": "QuoteReviewCancel2",
                "targetRef": "reviewCancelled2"
            },
            {
                "sourceRef": "QuoteReviewCancel",
                "targetRef": "reviewCancelled"
            },
            {
                "sourceRef": "QuoteReviewUserTask1",
                "targetRef": "isValidApprovalGateway1"
            },
            {
                "sourceRef": "isValidApprovalGateway1",
                "targetRef": "sendApprovalEmailTask2",
                "inputType": "context",
                "conditionType": "Script",
                "conditionExpression": "let isNotAuthorized = quoteData.authorization?.find(auth => auth.role == 'Quote manager approval' && auth && auth.approver[0] && auth.approver[0].id == '65d34902237a9575f79cf80e' && auth.state != 'accepted');  if(!isNotAuthorized) {  true; } else {  false; }",
                "condition": true
            },
            {
                "sourceRef": "isValidApprovalGateway1",
                "targetRef": "sendApprovalEmailTask2",
                "inputType": "context",
                "conditionType": "Script",
                "conditionExpression": "let isNotAuthorized = quoteData.authorization?.find(auth => auth.role == 'Quote manager approval' && auth && auth.approver[0] && auth.approver[0].id == '65d34902237a9575f79cf80e' && auth.state != 'accepted');  if(!isNotAuthorized) {  true; } else {  false; }",
                "condition": false
            },
            {
                "sourceRef": "sendApprovalEmailTask2",
                "targetRef": "QuoteReviewUserTask2"
            },
            {
                "sourceRef": "QuoteReviewUserTask2",
                "targetRef": "isValidApprovalGateway2"
            },
            {
                "sourceRef": "isValidApprovalGateway2",
                "targetRef": "updateQuoteStateTask",
                "conditionExpression": "let isNotAuthorized = quoteData.authorization?.find(auth => auth.role == 'Quote manager approval' && auth && auth.approver[0] && auth.approver[0].id == '65d34902237a9575f79cf80f' && auth.state != 'accepted');  if(!isNotAuthorized) {  true; } else {  false; }",
                "condition": true
            },
            {
                "sourceRef": "isValidApprovalGateway2",
                "targetRef": "end_1",
                "conditionExpression": "let isNotAuthorized = quoteData.authorization?.find(auth => auth.role == 'Quote manager approval' && auth && auth.approver[0] && auth.approver[0].id == '65d34902237a9575f79cf80f' && auth.state != 'accepted');  if(!isNotAuthorized) { return true; } else { return false; }",
                "condition": false
            },
            {
                "sourceRef": "updateQuoteStateTask",
                "targetRef": "end_1"
            }
        ]
    },
    "tag": [],
    "module": "general",
    "triggerPoint": [],
    "workflow": {
        "isOrder": true,
        "approver": [
            {
                "email": "pcfor62@gmail.com",
                "id": "65d34902237a9575f79cf80e",
                "name": "vishnu"
            },
            {
                "email": "pcfor62@gmail.com",
                "id": "65d34902237a9575f79cf80f",
                "name": "vishnu"
            }
        ]
    },
    "createdAt": "2024-11-18T10:25:34.138Z",
    "updatedAt": "2024-11-18T10:25:34.138Z",
    "__v": 0
      }
      ,
      filterQueryforBill: {
        type: "filter",
        module: "workflow",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      },
      totalpageno: 0,
      refreshTable1: 0,
      notification: {},
      delete_modal_active:false,
      backgrounStyleuploadQuoteRecipient: {
        backgroundColor: "",
        bacgroundtTextColor: "#000",
        borderColor: "#A8A8A8",
        TextfontSize: 14,
      },
      currPage: 1,
      rowsPerPage: 10,
      externalRowsPerPage: 10,
      tableHeads: [
        {
          sortable: false,
          resizable: true,
          label: "Name",
          prop: "name",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          label: "Enable rule",
          prop: "enableRule",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          label: "Description",
          prop: "description",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          label: "Tags",
          prop: "tags",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          label: "Created by",
          prop: "createdBy",
          align: "left",
        },
      ],
      tableData:[],
      tableData_1: [
        {
          name: "Project Alpha",
          enableRule: true,
          description:
            "Initial phase of the project focused on research and development.",
          tags: [{ name: "R&D" }, { name: "Alpha" }, { name: "2024" }],
          createdBy: "John Doe",
        },
        {
          name: "Website Redesign",
          enableRule: false,
          description:
            "Redesigning the company website for better user experience.",
          tags: [{ name: "UI/UX" }, { name: "Frontend" }, { name: "Design" }],
          createdBy: "Jane Smith",
        },
        {
          name: "Marketing Campaign",
          enableRule: true,
          description:
            "Running a global marketing campaign to increase brand awareness.",
          tags: [{ name: "Marketing" }, { name: "Global" }, { name: "2024" }],
          createdBy: "Michael Lee",
        },
        {
          name: "Cloud Migration",
          enableRule: false,
          description:
            "Migrating all infrastructure to the cloud for scalability.",
          tags: [
            { name: "Cloud" },
            { name: "Infrastructure" },
            { name: "Migration" },
          ],
          createdBy: "Sarah Connor",
        },
      ],
    };
  },
  computed: {
    tableDataOnSelectedRows(){
      // const start = (this.currPage - 1) * this.rowsPerPage;
      // const end = start + this.rowsPerPage;
      return this.tableData;
    }
  },

  async mounted(){
    this.ticketdataCalling(this.currPage, this.rowsPerPage);



    
    await this.$store.dispatch("workflowFilterData");
    const response = this.$store.getters.workflowFilterData;
    this.filter = response;




    // let Arr = localStorage.getItem('SettingsAutomationQuoteApprover');
    // Arr = JSON.parse(Arr);
    // this.tableData = JSON.parse(JSON.stringify(Arr));
    // console.log("this.tableData...",this.tableData,Arr);
    
    
  },

  methods: {

    async currentPage(val) {
      if (this.companyasignee != "") {
        this.currPage = val;
        this.ticketdataCalling(
          this.currPage,
        this.rowsPerPage
        );
        this.refreshTable();
      }
    },

    async quotesfilterData(filterSelectedvalue, filterQuery) {
      console.log("quotesfilterData::))", filterSelectedvalue, filterQuery);
      this.filtertaglength = filterSelectedvalue?.length;
      this.filterQueryforBill = filterQuery;

      const filterResultData = await BPMNAxiosInstance.post(
        `utils/filterData`,
        this.filterQueryforBill
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data?.total;
        console.log("ticketdataCalling...",this.tableData ,filterResultData.data.data);
        
        // this.totalvalue = filterResultData.data.total;      
      }
      
     },

    showDrawerRight() {
      this.show_right_filter = true;
    },

    closeRightFilter() {
      this.show_right_filter = false;
    },

    convertToNamedObjects(stringArray) {
    return stringArray?.map(item => ({ name: item }));
},

    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },

    async searchKey(val) {
      console.log("searchKey",val);
      
      this.searchvalue = val;
      this.filterQueryforBill["company"] = this.$cookies.get("company");
      this.filterQueryforBill["type"] = "search";
      this.filterQueryforBill["searchQuery"] = val;
      this.filterQueryforBill.searchList =
       [
      {
          "name": {
            $regex: val,
            $options: "i",
          },
        },
        // {
        //   "state": {
        //     $regex: val.toLowerCase() === "on hold" ? "onhold" : val,
        //     $options: "i",
        //   },
        // },
        {
          "definition.name": {
            $regex: val,
            $options: "i",
          },
        },
        {
          "tag": {
            $regex: val,
            $options: "i",
          },
        },
      ];
      // this.currPage = 1;
      // this.rowsPerPage = 10;
      if (this.filtertagvalue?.length != 0) {
        this.filterQueryforBill["type"] = "filter-search";
      }

      this.ticketdataCalling(this.currPage, this.rowsPerPage);
    },

    async ticketdataCalling(currentPage, rowsPerPage) {
  currentPage, rowsPerPage
  console.log("ticketdataCalling...123",currentPage, rowsPerPage);
      this.filterQueryforBill["company"] = 'developerHalleyx';
        // this.$cookies.get('company');
      this.filterQueryforBill["paginatedQuery"]["currentPage"] = currentPage;
      this.filterQueryforBill["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
      this.filterQueryforBill.module = 'workflow';
      const filterResultData = await BPMNAxiosInstance.post(
        `utils/filterData`,
        this.filterQueryforBill
      );
      if (
        filterResultData.data.data != undefined &&
        filterResultData.data.total != 0
      ) {
        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data?.total;
        console.log("ticketdataCalling...",this.tableData ,filterResultData.data.data);
        
        // this.totalvalue = filterResultData.data.total;      
      }
    },

    async refreshTable() {
      this.filterQueryforBill.module = 'workflow'
      const response = await BPMNAxiosInstance.post(
        `utils/filterData`,
        this.filterQueryforBill
      );
      // const response = await BPMNAxiosInstance.get(`/templateTicket`);

      this.tableData = response.data.data;
      this.totalpageno = response.data?.total;
      this.totalvalue = response.data.total;
    },

    backToSettings() {
      this.$router.push("/settings/automation");
    },

    createNewRule() {
      this.$router.push("/settings/automation/quoting/defaultApproval");
    },

    async changeRowsPerPage(count) {
      //
      if (this.companyasignee != "") {
        this.rowsPerPage = count;
        this.ticketdataCalling(
          this.currPage,
        this.rowsPerPage
        );
        this.refreshTable();
      }
    },

    serialNumberAlter(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    crudContextMenu($event, i, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 102;

        this.containertop =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().top + 10;  
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    async closeCrudContextMenu($event, payload, i) {
      if ($event.toLowerCase() === "edit") {
        $event, payload, i;
      // this.$router.push({
      //     name: "invoiceTemplate",
      //     query: { quote_id: payload?._id, mode: "edit" },
      //   });
      } else if ($event.toLowerCase() === "delete") {
        $event, payload, i;
        // this.deleteobj = payload;
        // this.delete_modal_active = true;
      }
    },
    
    async deleteExecRow() {
      this.delete_modal_active = false;
      this.deleteobj.id
      const newTableData = JSON.parse(JSON.stringify(this.tableData))
      this.tableData = newTableData.filter(item=>item?.id !== this.deleteobj.id)
      localStorage.setItem('SettingsAutomationQuoteApprover', JSON.stringify(this.tableData));
      this.notification = {
            type: "success",
            message: `Approval rule is deleted successfully!`,
          };
    },

    computedTableAction() {
      return [
        {
          label: "Edit",
          icon: "icon-file-text-edit-regular",
        },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        }
      ];
    },
  },
};
</script>

<style lang="scss">
.headerForApprovalTableView {
  display: flex;
  justify-content: space-between;
  margin: 2px 15px 15px 15px;
  height: 70px;
}
.backButtonInApprovalTableView {
  margin: 16px 1px 1px 19px;
  display: flex;
  cursor: pointer;
}
.headerForApprovalTableViewRightSide {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: end;
}
</style>
