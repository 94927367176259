<template>
  <br />
  <!-- <div style="padding: 20px">
    <newAddressComponent
    :type="'Canada post API'"
    :api-key="'DE92-ZE95-YR93-BU78'"
    ></newAddressComponent>
  </div> -->
  <fr :schema-payload="schema"></fr>
  <br />
</template>

<script>
// import newAddressComponent from '@/views/Lead/leadAddressComponent.vue';
import fr from '@/components/FieldRenderer.vue';

export default {
  components: {
    // newAddressComponent,
    fr,
  },
  data() {
    return {
      data: {},
      schema: {
        defaultLanguage: {
          type: 'Mixed',
          system: true,
          displayType: 'lookup',
          labels: {
            en: {
              label: 'Default language',
              name: 'English',
              tooltip: 'Default language',
            },
            hi: {
              label: 'डिफ़ॉल्ट भाषा',
              name: 'Hindi',
              tooltip: 'डिफ़ॉल्ट भाषा',
            },
            ta: { label: 'இயல்பு மொழி', name: 'Tamil', tooltip: 'இயல்பு மொழி' },
            fr: {
              label: 'Langue par défaut',
              name: 'french',
              tooltip: 'Langue par défaut',
            },
          },
          isRef: true,
          isTooltip: true,
          optionRef: '',
          required: false,
          section: ['overview'],
          others: {
            isVisibilityRuleEnabled: false,
            ruleIdReference: '',
            ruleData: '',
            isVisible: true,
            displayOrder: 4,
            visibilityRuleCondition: 'All',
            visibilityRuleAction: 'show',
            visibilityRulePayload: [],
            lookup: {
              method: 'GET',
              filterPayload: {},
              type: 'internal',
              fieldType: 'dropdown',
              lookupProp: 'languages',
              instance: 'MLTAxiosInstance',
              module: '',
              url: '/settings',
              isDataRemapEnabled: true,
              remapData: '',
            },
          },
          show: true,
        },
        contactMedium: {
          type: 'Array',
          system: true,
          displayType: 'siteaddress',
          isRef: true,
          isTooltip: true,
          labels: {
            en: {
              label: 'Address',
              name: 'English',
              tooltip: 'Contact medium',
            },
          },
          optionRef: 'referenceDataName',
          required: false,
          section: ['overview'],
          show: true,
          others: {
            isVisibilityRuleEnabled: false,
            ruleIdReference: '',
            ruleData: '',
            isVisible: true,
            displayOrder: 5,
            addressAPI: { type: 'Google maps API' },
          },
        },
        testDate: {
          fieldName: 'testDate',
          type: 'String',
          system: false,
          isRef: false,
          required: false,
          hasTooltip: false,
          tooltipEnglish: '',
          tooltipFrench: '',
          show: true,
          labels: {
            en: {
              label: 'Date field',
              name: 'English',
              tooltip: '',
            },
            hi: {
              label: 'परीक्षण दिनांक',
              name: 'Hindi',
              tooltip: 'परीक्षण दिनांक',
            },
            ta: {
              label: 'சோதனை தேதி',
              name: 'Tamil',
              tooltip: 'சோதனை தேதி',
            },
            fr: {
              label: 'Date du test',
              name: 'french',
              tooltip: 'Date du test',
            },
          },
          others: {
            businessDatePreference: [
              {
                name: 'Allow only business days',
                value: 'business-days-only',
                checked: true,
              },
              {
                name: "Default to today's date",
                value: 'default-today',
                checked: true,
              },
            ],
            customDatePreference: {
              preference: 'future-dates',
              range: {
                from: '2024-12-02T18:30:00.000Z',
                to: '2025-01-07T18:30:00.000Z',
              },
            },
            userRole: [],
            readOnly: false,
            lookup: {
              minCharToSearch: 1,
              maxResultToDisplay: 5,
              module: '',
              instance: '',
              fieldType: 'input',
              url: '',
              lookupProp: '',
              isDataRemapEnabled: false,
              remapData: '',
            },
            addressAPI: {
              authType: '',
              token: '',
              userName: '',
              password: '',
              type: 'canada post',
              key: '',
            },
            isVisibilityRuleEnabled: false,
            ruleIdReference: '',
            ruleData: '',
            isVisible: true,
            visibilityRulePayload: [
              {
                fieldName: '',
                values: [],
                value: '',
                operator: '',
                options: [],
                displayType: '',
                optionRef: '',
                showType: '',
              },
            ],
            visibilityRuleAction: 'show',
            visibilityRuleCondition: 'All',
          },
          displayType: 'date',
          id: 0,
          section: ['interaction'],
          isTooltip: true,
          optionRef: 'referenceDataName',
          tooltip: false,
          System: false,
        },
        statusField: {
          type: 'String',
          system: false,
          isRef: false,
          required: true,
          hasTooltip: false,
          tooltipEnglish: '',
          tooltipFrench: '',
          show: true,
          labels: {
            en: {
              label: 'Status field',
              name: 'English',
              tooltip: '',
            },
            hi: {
              label: 'स्थिति फ़ील्ड',
              name: 'Hindi',
              tooltip: 'स्थिति फ़ील्ड',
            },
            ta: {
              label: 'நிலை களம்',
              name: 'Tamil',
              tooltip: 'நிலை களம்',
            },
            fr: {
              label: "Champ d'état",
              name: 'french',
              tooltip: "Champ d'état",
            },
          },
          others: {
            businessDatePreference: [
              {
                name: 'Allow only business days',
                value: 'business-days-only',
                checked: true,
              },
              {
                name: "Default to today's date",
                value: 'default-today',
                checked: false,
              },
            ],
            customDatePreference: {
              preference: '',
              range: {
                from: '',
                to: '',
              },
            },
            userRole: [],
            readOnly: false,
            lookup: {
              minCharToSearch: 1,
              maxResultToDisplay: 5,
              module: '',
              instance: '',
              fieldType: 'input',
              url: '',
              lookupProp: '',
              isDataRemapEnabled: false,
              remapData: '',
            },
            addressAPI: {
              authType: '',
              token: '',
              userName: '',
              password: '',
              type: 'canada post',
              key: '',
            },
            isVisibilityRuleEnabled: false,
            ruleIdReference: '',
            ruleData: '',
            isVisible: true,
            visibilityRulePayload: [
              {
                fieldName: '',
                values: [],
                value: '',
                operator: '',
                options: [],
                displayType: '',
                optionRef: '',
                showType: '',
              },
            ],
            visibilityRuleAction: 'show',
            visibilityRuleCondition: 'All',
          },
          displayType: 'status',
          fieldName: 'statusField',
          id: 2,
          optionRef: 'statusField',
          section: ['interaction'],
          isTooltip: true,
          tooltip: false,
          System: false,
        },
      },
      preval1: 'SONY Inc',
      preval2: 'Pooja D',
      filterPayload: {
        type: 'search',
        module: 'saleslead',
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {},
        searchList: [],
        searchQuery: '',
      },
      filterPayload2: {
        company: 'developerHalleyx',
        type: 'filter-search',
        module: 'hierarchycontacts',
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 2,
        filterQuery: {
          'role.name': 'Account executive',
          name: {
            $regex: '',
            $options: 'i',
          },
        },
        searchQuery: '',
      },
      url: '/util/filterData',
      type: 'internal',
      module: 'UPCAxiosInstance',
      module2: 'MLTAxiosInstance',
    };
  },
  async mounted() {},
  methods: {},
};
</script>

<style></style>
