<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />

  <hlx-drawer
    :show="show_right_filter"
    :width="300"
    position="right"
    :header="false"
    :footer="false"
    :show-close-icon="true"
    @close="closeRightFilter"
  >
    <template #body>
      <div style="display: flex">
        <FilterComponent
          :label-value=filterLang
          :filterpanelname="'emailtemplate'"
          :company-name="company"
          :query-value="query"
          :display-data="filter"
          :module="'emailtemplate'"
          @filtered-data="emailFilter"
        ></FilterComponent>
      </div>
    </template>
  </hlx-drawer>

  <!-- Modal window -->
  <hlx-modal
        :modal-active="modal_open"
        :height="'470px'"
        :width="'450px'"
        :modal="true"
        @close="modal_open = false"
      >
        <template #header>
          <div>New email template</div>
        </template>
        <template #content>
          <div class="modal-body">
          <!-- Template Name -->
           <span             id="templateName"
           >
          <hlx-input
            v-model:value="templateName"
            :label-animation="true"
            :required="true"
            type="text"
            :label-value="'Template name'"
          />
        </span>

          <!-- Description -->
           <span id="templateDescription"
           >
          <hlx-input
          v-model:value="description"
          type="textarea"
          :label-animation="true"
          :required="false"
          :label-value="'Description'"
          ></hlx-input>
          </span>

          <!-- Choose Template -->
           <div class="choose-template">
          <label class="heading">Choose template <span class="astreik-color">*</span></label>
          <div class="template-options">
            <button
              :class="{
                selected: selectedTemplate === 'builder',
              }"
              @click="selectTemplate('builder')"
            >
             <i class="code-square-regular"></i>
               <span class="header"> Use template builder</span>
              <span class="subheader">A visual and code-free builder</span>
            </button>
            <button
              :class="{
                selected: selectedTemplate === 'editor',
              }"
              @click="selectTemplate('editor')"
            >
            <i class="layout-web-1"></i>
              <span class="header">Use HTML Editor</span>
              <span class="subheader">An advanced coding editor</span>
            </button>
          </div>
          </div>
        </div>
        </template>
        <template #footer>
          <div class="modal-footer12">
          <hlx-button class="secondary sm" @click="modal_open = false">Cancel</hlx-button>
          <hlx-button class="primary sm" @click="addTemplate"
            >Add</hlx-button
          >
        </div>
        </template>
      </hlx-modal>

  <div class="add-feature-drawer">
    <hlx-drawer
      :show="showTaxDrawer"
      :width="'350'"
      :title="'Tax'"
      position="right"
      :footer="true"
      :header="true"
      :show-close-icon="true"
      @close="closeTaxDrawer"
    >
      <template #body>
        <div class="tax-container" style="gap: 0px !important">
          <div
            style="
              border-bottom: 1px solid #d4d4d4;
              padding-bottom: 5px;
              padding: 13px;
            "
          >
            <b v-if="edit == false">Add template</b>
            <b v-else>Edit tax</b>
          </div>
          <div
            class="ticket-drawer-res"
            style="display: flex; padding: 18px 13px; flex-direction: column"
          >
            <span style="margin-bottom: 20px">
              <hlx-input
                v-model:value="payload.taxcode"
                :required="true"
                :pre-val="payload.taxcode"
                :label-animation="true"
                label-value="Tax code"
                type="text"
                :clearable="true"
              >
              </hlx-input>
            </span>
            <span style="margin-bottom: 20px">
              <hlx-select
                :key="componentKey"
                :inline-search="true"
                :pre-value="payload?.country?.name?.common"
                :label-animation="true"
                :required="true"
                :placeholder-value="'Country'"
                :options="countryPayload"
                :label="'value'"
                :prop-value="'value'"
                @selected-value="countryValue"
              >
              </hlx-select>
            </span>
            <span style="margin-bottom: 20px">
              <hlx-multi-select
                :key="componentKey1"
                :pre-value="provincePreval(payload.province)"
                :required="false"
                :options="provincePayload"
                :placeholder="'Choose province'"
                :label-animation="true"
                :prop-value="'name'"
                :label="'name'"
                @selected-array="provinceValue"
              ></hlx-multi-select>
            </span>
            <span class="name-and-rate" style="margin-bottom: 20px">
              <span
                v-for="(i, index) in addNameRate"
                :key="index"
                :style="[index > 0 ? 'padding-top:20px' : '']"
              >
                <span class="taxAndPercent">
                  <hlx-input
                    v-model:value="i.taxname"
                    :pre-val="i.taxname"
                    :label-animation="true"
                    label-value="Tax name"
                  >
                  </hlx-input>
                  <hlx-input
                    v-model:value="i.rate"
                    :label-animation="true"
                    label-value="Tax rate"
                    type="mixed"
                    :clearable="true"
                    name="Taxrate"
                    :suffix="true"
                    :pre-val="i.rate"
                  >
                    <template #suffix> % </template>
                  </hlx-input>
                </span>
                <i
                  class="icon-plus-circle-regular"
                  @click="addtaxNameRate()"
                ></i>
                <i
                  v-if="addNameRate.length > 1"
                  class="icon-trash-regular"
                  @click="deletetaxNameRate(index)"
                ></i>
              </span>
            </span>
            <span style="margin-bottom: 20px">
              <hlx-input
                v-model:value="payload.notes"
                :pre-val="payload.notes[0]"
                :label-animation="true"
                :display-error="false"
                type="textarea"
                label-value="Notes"
                :clearable="false"
              />
            </span>
            <b style="margin-bottom: 12px">Tax calculation</b>
            <span style="margin-bottom: 16px">
              <hlx-input
                :options="taxCalcOptions"
                :type="'radio-buttons'"
                @selected="taxCalcValue"
              />
            </span>
            <b style="margin-bottom: 12px">Apply To</b>
            <span
              :style="payload.allAccount == true ? '' : 'margin-bottom:12px'"
            >
              <hlx-input
                :options="taxapplyOptions"
                :type="'radio-buttons'"
                @selected="applyToValue"
              />
            </span>
            <span
              :style="payload.allAccount == true ? '' : 'margin-bottom:12px'"
            >
              <hlx-multi-select
                v-if="specificMarketOption == true"
                :pre-value="marketPreValue"
                :options="marketOptions"
                :placeholder="'Market'"
                :label-animation="true"
                :prop-value="'name'"
                :label="'name'"
                :disabled="false"
                :filter-select="false"
                @selected-array="marketValues"
              ></hlx-multi-select>
            </span>
            <span
              :style="payload.allAccount == true ? '' : 'margin-bottom:12px'"
            >
              <searchfilterComponent
                v-if="specificAccountOption == true"
                :key="updateSearchAndFilterKey"
                :show-category="false"
                :module="'hierarchyaccounts'"
                :filter-search-options="mainInfo"
                :filter-data="searchFilterData"
                :filter-checked-data="layout"
                :category-data="searchFilterCategoryData"
                :key-value-from-query-array="'label'"
                :filter-value-object-name="'name'"
                @searched-value="getSearchedValue"
                @checked-value="getcheckedValue"
                @removed-value="getRemovedValue"
              >
              </searchfilterComponent>
            </span>
            <hlx-input
              :key="componentKey"
              v-model:value="payload.pricable"
              :pre-val="payload.pricable"
              :checked="payload.pricable"
              :type="'single-checkbox'"
              :label-value="'Included in price'"
            />
          </div>
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 16px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button
            class="secondary sm"
            style="margin-right: 10px"
            @click="showTaxDrawer = false"
            >Cancel</hlx-button
          >
          <hlx-button
            v-if="edit != true"
            :disabled="payload.country != '' ? false : true"
            class="primary sm addButton"
            @click="sendPayload"
            >Add</hlx-button
          >
          <hlx-button
            v-else
            class="primary sm addButton"
            :disabled="payload.country != '' ? false : true"
            @click="editPayload"
            >Save</hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>

  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'230px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>Are you sure you want to delete the template?</p>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteExecRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>

  <div class="app-layout catalog specification">
    <section class="new-right-panel upc">
      <div class="new-right-panel-parent-container no-filter-panel">
        <div id="mySidepanel" class="new-right-panel-left-container"></div>
        <div class="new-right-panel-right-container">
          <section class="new-layout-basic-no-breadcrumb">
            <div style="padding-left: 1.4rem;">
              <hlx-breadcrumb :items="items" :separator="separator" @path="pathTo" />
            </div>
            <!-- <div class="new-layout-header">
              <span class="new-layout-header-container">
                <span class="schema-header-title">Email template</span>
                <span class="subtitle">
                  Centralized table for email template.
                </span>
              </span>
            </div> -->

            <div
              v-if="view === 'Product specifications' && datalength == 0"
              class="new-layout-body catalog-v2"
            >
              <!-- search and filter  -->
              <div
                class="header-utils-and-switch-container"
                style="width: 100%"
              >
              <span class="new-layout-header-container" style="width:50%">
                <span class="schema-header-title">Email template</span>
                <span class="subtitle">
                  Centralized table for email template.
                </span>
              </span>

                <span class="table-header-utils" style="align-items: center">
                  <!-- <b>Calculate tax based on:</b> -->
                  <span style="width: 150px">
                    <!-- <hlx-select
                        v-model:value="tax_based_on"
                        :pre-value="tax_based_on"
                        :options="tax_based_options"
                        :inline-search="true"
                        :clearable="false"
                        :border="false"
                        :placeholder-value="''"
                        :label-animation="false"
                        :label="'name'"
                        :prop-value="'value'"
                        @custom-change	="taxBased"
                      >
                      </hlx-select> -->
                  </span>
                  <span class="util-items --search"
                    ><hlx-search
                      v-model="searchvalue"
                      @search-key="searchKey"
                    ></hlx-search
                  ></span>

                  <span class="filter-table">
                    <hlx-button
                      class="secondary sm add-btn filterbutton"
                      @click="showDrawerRight()"
                      ><i class="icon-filter-regular"></i>
                      <span v-if="filtertaglength != 0" class="filter-count">{{
                        filtertaglength
                      }}</span>
                    </hlx-button>
                  </span>

                  <span
                    v-if="view === 'Product specifications'"
                    class="util-items util-items-add"
                    ><hlx-button
                      class="primary sm add-btn"
                      @click.stop=" openModal
                      "
                      ><i
                        class="icon-plus-circle-regular"
                        style="margin-right: 5px"
                      ></i
                      >Add template</hlx-button
                    >
                  </span>
                </span>
              </div>

              <!-- TableData -->
              <div v-if="tableData?.length > 0" class="table-wrap">
                <hlx-table
                  :column-count="product_specifications_theads.length + 2"
                  :border="['table', 'header', 'horizontal']"
                  :bold-headers="false"
                  :row-hover="true"
                  theme="grey"
                  :striped-rows="false"
                  :re-arrange-columns="re_arrange"
                  :re-arrange-heads="product_specifications_theads_cc"
                  @sorting_func="sorting_Data"
                  @close-rearrange="re_arrange = false"
                  @apply-rearranged-columns="applyColumns"
                >
                  <template #thead>
                    <hlx-table-head
                      :width="60"
                      :align="'center'"
                      style="padding: 0 15px"
                      :fixed="'left'"
                      >{{ "S.no" }}</hlx-table-head
                    >
                    <hlx-table-head
                      v-for="(i, index) in product_specifications_theads"
                      :key="index"
                      :prop="i.prop"
                      :sortable="i.sortable"
                      :resizable="i.resizable"
                      :fixed="i.fixed"
                      :width="i.width !== undefined ? i.width : ''"
                      :align="i.align !== undefined ? i.align : ''"
                      @sorting_func="sorting_Data"
                      @new-resize-width="resizedWidth"
                    >
                      {{ i.label }}</hlx-table-head
                    >

                    <hlx-table-head
                      v-show="editMode == true"
                      :align="'center'"
                      :width="70"
                      :fixed="'right'"
                      ><span class="action-icon">
                        <i
                          class="icon-settings-regular"
                          @click="reAssignOrgTableHeader()"
                        ></i> </span
                    ></hlx-table-head>
                  </template>
                  <template #tbody>
                    <tr v-for="(i, index) in paginatedData" id="" :key="index">
                      <hlx-table-cell :align="'center'" :fixed="'left'">
                        {{ serialNumber(index) }}
                      </hlx-table-cell>
                      <hlx-table-cell
                        v-for="(j, col_index) in product_specifications_theads"
                        :key="col_index"
                        :align="j.align"
                        :fixed="j.fixed"
                      >
                        <div v-if="j?.prop == 'language'">
                          <hlx-tagcount
                            :arrayfile="languageManipulation(i)"
                            :item-key="'lang'"
                            :type="'label'"
                          ></hlx-tagcount>
                        </div>
                        <div
                          v-else-if="j?.prop == 'name'"
                          :id="j.prop + index"
                          class="link-name"
                          @click="viewPage(i)"
                        >
                          {{ i[j?.prop] }}
                        </div>
                        <div v-else-if="j.prop.toLowerCase() === 'status'">
                          <div>
                            <hlx-status :type="i[j.prop]"></hlx-status>
                          </div>
                        </div>
                        <div v-if="j.prop.toLowerCase() === 'lastupdate'">
                          {{ dateAndTimeFormatter(i["updatedAt"]) }}
                        </div>
                        <div v-if="j.prop.toLowerCase() === 'createdby'">
                          {{ $cookies.get("userName") }}
                        </div>
                        <div v-else></div>
                        <!-- For status -->
                      </hlx-table-cell>
                      <hlx-table-cell
                        v-show="editMode == true"
                        :align="'center'"
                        :width="120"
                        :fixed="'right'"
                      >
                        <span
                          :id="'table-context' + serialNumber(index - 1)"
                          class="action-icon"
                        >
                          <!-- <i class="icon-edit-regular"></i> &nbsp;
                      <i class="icon-copy-regular"></i> &nbsp;
                      <i class="icon-trash-regular"></i> -->
                          <i
                            style="position: relative"
                            class="icon-more-vertical-filled"
                            :class="i.context === true ? 'active-action' : ''"
                            @click="
                              crudContextMenu($event, +serialNumber(index - 1))
                            "
                          >
                            <hlx-context-menu
                              :top="containertop"
                              :left="containerleft"
                              :conditionvalue="150"
                              :conditiondown="2"
                              :conditionup="-38"
                              :options="computedTableAction(i)"
                              :data="index.toString()"
                              :show="i.context == true"
                              @chosen="
                                closeCrudContextMenu(
                                  $event,
                                  i,
                                  +serialNumber(index - 1)
                                )
                              "
                            />
                            <!-- <span
                          v-if="index < rowsPerPagePS / 2"
                          style="position: absolute"
                        >
                          <hlx-context-menu
                            style="
                              z-index: 9000;
                              margin-right: 100px !important;
                              margin-top: -10px;
                              box-shadow: 0 0 20px 0 #d8d8d8;
                            "
                            :options="computedTableAction(i)"
                            :data="index.toString()"
                            :show="i.context == true"
                            @chosen="closeCrudContextMenu($event, i)"
                          />
                        </span>
                        <span v-else style="position: absolute">
                          <hlx-context-menu
                            style="
                              z-index: 9000;
                              margin-right: 100px !important;
                              margin-top: -125px;
                              box-shadow: 0 0 20px 0 #d8d8d8;
                            "
                            :options="computedTableAction(i)"
                            :data="index.toString()"
                            :show="i.context == true"
                            @chosen="closeCrudContextMenu($event, i)"
                          />
                        </span> -->
                          </i>
                        </span>
                      </hlx-table-cell>
                    </tr>
                  </template>
                </hlx-table>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div
        v-if="tableData?.length > 0 && view === 'Product specifications'"
        class="pagination-container"
        style="padding: 2%"
      >
        <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
          :total="totalpageno"
          enable-rows-per-page
          rows-per-page
          :rows-per-page-list="[10, 15, 20]"
          :options="{
            attributes: {
              rowsPerPageTitle: true,
              rowsPerPageResult: true,
              rowsPerPageDropdown: {
                position: {
                  top: 0,
                  right: 1,
                },
              },
            },
          }"
          @current-page="currentPage"
          @updated:rows-per-page="changeRowsPerPage"
        ></hlx-pagination>
      </div>
    </section>
  </div>
</template>

<script>
import {
  UPCAxiosInstance,
  // DMNAxiosInstance,
  MLTAxiosInstance,
} from "@/config/axiosConfig";
// import multiSelect from "@/components/SearchAndFilterComponent/multiSelectComponent.vue";
// import searchfilterComponent from "@/components/SearchAndFilterComponent/searchAndFilter.vue";
import FilterComponent from "../../../components/filterPanelComponent.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    FilterComponent
    // searchfilterComponent,
  },
  props: {
    value: {
      type: String,
      default: "All",
    },
  },
  data() {
    return {
      items: [{ label: "Back", link: "/", icon: "icon-arrow-left-regular" }],
      query: {
        Name: ["name"],
      },
      filter: {},
      templatePayload:[],
      languageOptions:[],
      filterQuery: {
                                type: 'filter',
                                module: 'emailtemplate',
                                isPaginated: true,
                                paginatedQuery: {
                                rowsPerPage: 10,
                                currentPage: 1,
                                },
                                isNeedToBeSort: false,
                                sortFieldAndOrder: {
                                "name":1
                                },
                                limit: 10,
                                filterQuery: {
                                 },
                                searchQuery: '',
                                company: '',
                            },
      description:'',
      templateName:'',
      selectedTemplate:'',
      modal_open:false,
      emailTemplateTableData: [],
      tableHeaderOrg: [],
      tableHeaderOrgcc: [],
      componentKey1: 0,
      specificMarketOption: false,
      specificAccountOption: false,
      mainInfo: [],
      provincePayload: [],
      countryPayload: [],
      filterLayout: {
        layoutFor: "hierarchyaccounts",
        // filterQuery:{'partyCharacteristic.name': {$in: ["market segment"]}},
        layout: [
          {
            prop: "partyCharacteristic",
            label: "Industry",
            type: "String",
            fieldToFilter: "partyCharacteristic",
          },
        ],
      },
      marketOptions: [],
      marketPreValue: [],
      tax_based_on: "Billing address",
      tax_based_options: [
        { name: "Billing address", value: "billingAddress" },
        { name: "Site address", value: "siteAddress" },
      ],
      edit: false,
      componentKey: 0,
      payload: {
        country: "",
        province: [],
        pricable: false,
        taxType: "Cumulative",
        notes: "",
        tax: [],
        appliesTo: [
          { name: "All accounts", checked: true },
          { name: "Specific market", checked: false },
          { name: "Specific accounts", checked: false },
        ],
        allAccount: true,
        specificAccount: false,
        specificMarket: false,
      },
      taxCalcOptions: [
        { name: "Cumulative", checked: true },
        { name: "Compound", checked: false },
      ],
      taxapplyOptions: [
        { name: "All accounts", checked: true },
        { name: "Specific market", checked: false },
        { name: "Specific accounts", checked: false },
      ],
      addNameRate: [
        {
          taxname: "",
          rate: "",
        },
      ],
      duplicateaddNameRate: {
        taxname: "",
        rate: "",
      },
      showTaxDrawer: false,
      Brand: [],
      error: false,
      errorMessage: "",
      componentkey: 0,
      datalength: 0,
      enableButton: false,
      payloadSpec: {
        name: "",
        brand: "",
        category: "",
        validFor: {
          endOfLife: "",
          endOfDate: "",
        },
        relatedParty: [],
        unitOfMeasure: {
          name: "",
        },
      },
      categoryOptions: [],
      add_model: false,
      show: false,
      externalRowsPerPage: 10,
      show_right_filter: false,
      currencylist: [],
      filterPanelName: "",
      recurringChargePeriodType: {},
      priceobj: {},
      company: this.$cookies.get("company"),
      showpricedata: {},
      showPriceValue: false,
      offeringfiltercondition: [],
      offeringprice: [],
      filteredarray: [],
      filteredobj: { $in: [] },
      productofferingfilteredobj: { $in: [] },
      productofferingfilter: [],
      Specfilterquery: {
        type: "search",
        module: "productSpecification",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {
          // "productSpecCharacteristic.productSpecCharacteristicValue.value": "",
        },
        searchQuery: "",
      },
      offeringfilterquery: {
        type: "filter",
        module: "productOffering",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      },
      filterData: [],
      currentvalue: "ALL",
      sliderfilter: false,
      sliderMin: 0,
      sliderMax: 0,
      filterprice: {
        units: "ALL",
        min: 0,
        max: 0,
        key: "productOfferingPrice",
      },
      totalpageno: 0,
      containerleft: 0,
      containertop: 0,
      userInfo: this.$store.state.userInfo,
      filtermin: 0,
      filtermax: 0,
      filterloop: {},
      slidedata: 0,
      min: 0,
      max: 0,
      searchfilterdata: {},
      originaldata: {},
      filterdata: {},
      duplicatefilterdata: {},
      filterdat: {},
      filtered_options: {},
      filter_theads: [
        {
          prop: "place",
          label: "Place",
        },
        {
          prop: "channel",
          label: "Channel",
        },
        {
          prop: "category",
          label: "Category",
        },
        {
          prop: "productOfferingTerm",
          label: "Product offering term",
        },
        {
          prop: "marketSegment",
          label: "Market segment",
        },
        {
          prop: "productOfferingPrice",
          label: "Product offering price",
        },
      ],

      product_specifications_theads: [
        {
          name: "Name",
          checked: true,
          width: null,
          id: 1,
          disabled: true,
          prop: "name",
          label: "Name",
          sortable: false,
          resizable: true,
          align: "left",
        },
        {
          name: "Language",
          checked: true,
          width: 300,
          id: 1,
          disabled: true,
          prop: "language",
          label: "Language",
          sortable: false,
          resizable: true,
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          disabled: false,
          width: 200,
          checked: true,
          name: "Created by",
          label: "Created by",
          prop: "createdby",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          disabled: false,
          width: 200,
          checked: true,
          name: "Last Updated",
          label: "Last Updated",
          prop: "lastUpdate",
          align: "left",
        },
      ],
      product_specifications_theads_cc: [
      {
          name: "Name",
          checked: true,
          width: null,
          id: 1,
          disabled: true,
          prop: "name",
          label: "Name",
          sortable: false,
          resizable: true,
          align: "left",
        },
        {
          name: "Language",
          checked: true,
          width: 300,
          id: 1,
          disabled: true,
          prop: "language",
          label: "Language",
          sortable: false,
          resizable: true,
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          disabled: false,
          width: 200,
          checked: true,
          name: "Created by",
          label: "Created by",
          prop: "createdby",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          disabled: false,
          width: 200,
          checked: true,
          name: "Last Updated",
          label: "Last Updated",
          prop: "lastUpdate",
          align: "left",
        },
      ],
      modal_active6: false,
      dropdown_data: [
        { name: "AND", value: "AND" },
        { name: "Between", value: "Between" },
        { name: "OR", value: "OR" },
      ],
      show_right: false,
      token: this.$route.params.token,
      editMode: true,
      editPermission: [],
      viewPermission: [],
      unique: Math.floor(Math.random() * 1000 + 1),
      delete_modal_active: false,
      searchtabledata: [],
      // businessrulepagespinner: true,
      // businessrulepagetable: false,
      duplicatetabledata: [],
      config_nav_items: [],
      delete_name: "",
      datum: {},
      success: false,
      success_msg: "",
      notification: {},
      expressions: [],
      view: "Product specifications",
      schema_type_switch: [
        { name: "Product specifications", value: "Product specifications" },
      ],
      selectedValue: "",
      // selectedValue: "Product offerings",
      show_add_menu: false,
      show_crud_menu: false,
      theads: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: false,
          prop: "name",
          label: "Name",
          sortable: false,
          resizable: false,
          width: 80,
          align: "left",
          fixed: "left",
        },
        {
          name: "Type",
          checked: true,
          id: 5,
          disabled: false,
          prop: "type",
          label: "Type",
          sortable: false,
          resizable: false,
          width: 80,
          align: "left",
        },
        {
          name: "Category",
          checked: true,
          id: 2,
          disabled: false,
          prop: "category",
          label: "Category",
          sortable: false,
          resizable: false,
          width: 90,
          align: "left",
        },
        {
          name: "Channel",
          checked: true,
          id: 2,
          disabled: false,
          prop: "channel",
          label: "Channel",
          sortable: false,
          resizable: false,
          width: 90,
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 4,
          disabled: false,
          prop: "status",
          label: "Status",
          sortable: false,
          resizable: false,
          width: 70,
          align: "left",
        },
        {
          name: "Market",
          checked: true,
          id: 3,
          disabled: false,
          prop: "market",
          label: "Market",
          sortable: false,
          resizable: false,
          width: 90,
          align: "left",
        },
        {
          name: "Place",
          checked: true,
          id: 5,
          disabled: false,
          prop: "place",
          label: "Place",
          sortable: false,
          resizable: false,
          width: 90,
          align: "left",
        },
        {
          name: "Validity",
          checked: true,
          id: 5,
          disabled: false,
          prop: "validity",
          label: "Validity",
          sortable: false,
          resizable: false,
          width: 190,
          align: "left",
        },
        {
          name: "Last modified",
          checked: true,
          id: 5,
          disabled: false,
          prop: "last_updated_at",
          label: "Last modified",
          sortable: false,
          resizable: false,
          width: 170,
          align: "left",
        },
      ],
      theads1: [
        {
          name: "Name",
          checked: true,
          id: 1,
          disabled: false,
          prop: "name",
          label: "Name",
          sortable: false,
          resizable: false,
          width: 80,
          align: "left",
        },
        {
          name: "Type",
          checked: true,
          id: 5,
          disabled: false,
          prop: "type",
          label: "Type",
          sortable: false,
          resizable: false,
          width: 80,
          align: "left",
        },
        {
          name: "Category",
          checked: true,
          id: 2,
          disabled: false,
          prop: "category",
          label: "Category",
          sortable: false,
          resizable: false,
          width: null,
          align: "left",
        },
        {
          name: "Channel",
          checked: true,
          id: 2,
          disabled: false,
          prop: "channel",
          label: "Channel",
          sortable: false,
          resizable: false,
          width: null,
          align: "left",
        },
        {
          name: "Status",
          checked: true,
          id: 4,
          disabled: false,
          prop: "status",
          label: "Status",
          sortable: false,
          resizable: false,
          width: 90,
          align: "left",
        },
        {
          name: "Market",
          checked: true,
          id: 3,
          disabled: false,
          prop: "market",
          label: "Market",
          sortable: false,
          resizable: false,
          width: 90,
          align: "center",
        },
        {
          name: "Place",
          checked: true,
          id: 5,
          disabled: false,
          prop: "place",
          label: "Place",
          sortable: false,
          resizable: false,
          width: 90,
          align: "center",
        },
        {
          name: "Validity",
          checked: true,
          id: 5,
          disabled: false,
          prop: "validity",
          label: "Validity",
          sortable: false,
          resizable: false,
          width: 120,
          align: "left",
        },
        {
          name: "Last modified",
          checked: true,
          id: 5,
          disabled: false,
          prop: "last_updated_at",
          label: "Last modified",
          sortable: false,
          resizable: false,
          width: 175,
          align: "left",
        },
      ],
      tableData: [],
      logData: [],
      re_arrange: false,
      currPage: 1,
      rowsPerPage: 10,
      SpecificationcurrPage: 1,
      SpecificationrowsPerPage: 10,
      currPagePS: 1,
      rowsPerPagePS: 10,
      filtercurrPage: 1,
      filterrowsPerPage: 10,
      // duplicateTableData: [],
      duplicateTheads: [],
      duplicateTheads1: [],
      searchvalue: "",
      id: "tenant1",
      name: "myself",
      deleteobj: {},
      filterobj: {},
      filtervalueobj: {},
      searchfiltervalue: "",
      filtertagvalue: [],
      filtertaglength: 0,
      filteredtabledata: [],
      fdata: [],
      parentelement: "",
      parentdata: {},
      parentarray: [],
      Manufacturer: [],
      unitOfMeasure: [],
      unit: "",
      relatedParty: "",
      endOfDate: "",
    };
  },
  computed: {
    ...mapGetters(["currencyList"]),
    // records(){
    //   return this.$store.getters.allRule
    // },
    filteroptions() {
      return this.filterobj;
    },
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
    showingFrom() {
      if (this.view === "Product offerings") {
        return (this.currPagePO - 1) * this.rowsPerPagePO + 1;
      } else {
        return (this.currPagePS - 1) * this.rowsPerPagePS + 1;
      }
    },
    showingTo() {
      if (this.view === "Product offerings") {
        const lastItem = this.showingFrom + this.rowsPerPagePO - 1;
        return lastItem > this.totalRows ? this.totalRows : lastItem;
      } else {
        const lastItem = this.showingFrom + this.rowsPerPagePS - 1;
        return lastItem > this.totalRows ? this.totalRows : lastItem;
      }
    },
    totalRows() {
      return this.tableData.length;
    },
  },
  watch: {
    // filtertagvalue: {
    //   handler(val) {
    //     if (val.length === 0) {
    //       val;
    //       //  this.datacalling()
    //     }
    //   },
    //   immediate: true,
    //   deep: true
    // },
    "$route.query.for": {
      handler(query) {
        if (!query) return;
        // console.log(query.trim());
        if (query.trim() == "offering") {
          this.selectedValue = "Product offerings";
          this.handleCurrentChange(this.selectedValue);
        } else if (query.trim() == "specification") {
          this.selectedValue = "Product specifications";
          this.handleCurrentChange(this.selectedValue);
          // this.$nextTick(() => {
          //   console.log(this.selectedValue,'jerry');
          //   this.handleCurrentChange(this.selectedValue);
          // });
        }
      },
      deep: true,
      // immediate:true
    },
    // totalpageno: {
    //   handler(data) {

    //   },
    //   immediate: true,
    //   deep: true
    // },
    tableData: {
      handler() {
        
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    await this.$store.dispatch("emailTemplateFilterData");
    this.filter = this.$store.getters.emailTemplateFilterData;
    console.log(this.filter,'skj')
    this.dataCalling(this.currPage, this.rowsPerPage);
      
    // let value = await UPCAxiosInstance.get("emailTemplate");
    // this.tableData = value.data;
    // this.emailTemplateTableData = JSON.parse(JSON.stringify(value.data));
    // this.totalpageno = this.tableData.length
    // this.tableData = this.tableData.sort(
    //   (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    // );
    await this.$store.dispatch("loginTenantDetails");
    this.languageOptions = this.$store?.getters?.loginTenantDetails?.languageAbility
    
    // To get table header
    //   let sampleTheads = await MLTAxiosInstance.get(`/settings`);

    //   if (sampleTheads?.data?.entityRef?.["settingsTax"]) {
    //     let offerTableHeadModal = sampleTheads.data.entityRef["settingsTax"];
    //     let offerTableHeadOrg = sampleTheads.data.entityRef["settingsTax"].filter(ele => ele.checked);
    //     console.log(offerTableHeadOrg,"offerTableHeadModal",offerTableHeadModal)

    //     if (
    //       offerTableHeadModal.length ===  this.product_specifications_theads_cc.length
    //       // &&
    //       // offerTableHeadModal.every((item, index) => item.name ===  this.product_specifications_theads_cc[index].name)
    //     ) {
    //        this.product_specifications_theads_cc = JSON.parse(JSON.stringify(offerTableHeadModal));
    //        this.product_specifications_theads = JSON.parse(JSON.stringify(offerTableHeadOrg));
    //        this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.product_specifications_theads_cc ))
    //          this.tableHeaderOrg = JSON.parse(JSON.stringify(this.product_specifications_theads))
    //     }
    //   }
    //       this.categoryData();
    //       const addressType =  await MLTAxiosInstance.get(`/settings`);
    //       this.tax_based_on = addressType.data.taxAddressType
    //       let payload = await MLTAxiosInstance.get(`/countryInfo?fieldToFilter=name`);
    //       payload.data.forEach((item) => {
    //         if (item.name) {
    //           item.value = item.name.common;
    //           this.countryPayload.push(item);
    //         }
    //       });
    //       console.log(this.countryPayload, "country");
    //       // const industryTypeList = await DMNAxiosInstance.get(
    //       //   `/mapRef/collection/market`
    //       // );
    //       this.marketOptions =  await this.getReferanceData('market');
    //       const filterResultData = await UPCAxiosInstance.post(
    //         `util/filterData`,
    //         this.filterQuery
    //       );
    //       this.datalength = filterResultData.data.total;
    //       this.tableData = filterResultData.data.data;
    //       this.totalpageno = filterResultData.data.total;
    //       this.taxdataCalling(this.currPage, this.rowsPerPage);
    //       console.log("test", this.tableData);
    //       // this.componentKey +=1
    //       this.duplicateTheads = JSON.parse(JSON.stringify(this.theads));
    //       this.duplicateTheads1 = JSON.parse(JSON.stringify(this.theads1));
    //       let tableData = await MLTAxiosInstance.get(
    //         `/partymanagement/organisation?company=Halleyx`
    //       );
    //       this.mainInfo = tableData.data;
    //       console.log(tableData.data, "data");
    // To get table header
    let sampleTheads = await MLTAxiosInstance.get(`/settings`);

    if (sampleTheads?.data?.entityRef?.["settingsEmail"]) {
      let offerTableHeadModal = sampleTheads.data.entityRef["settingsEmail"];
      let offerTableHeadOrg = sampleTheads.data.entityRef[
        "settingsEmail"
      ].filter((ele) => ele.checked);
      console.log(
        offerTableHeadOrg,
        "offerTableHeadModal",
        offerTableHeadModal
      );

      if (
        offerTableHeadModal.length === this.product_specifications_theads_cc.length
        // &&
        // offerTableHeadModal.every((item, index) => item.name ===  this.product_specifications_theads_cc[index].name)
      ) {
        this.product_specifications_theads_cc = JSON.parse(
          JSON.stringify(offerTableHeadModal)
        );
        this.product_specifications_theads = JSON.parse(
          JSON.stringify(offerTableHeadOrg)
        );
        this.tableHeaderOrgcc = JSON.parse(
          JSON.stringify(this.product_specifications_theads_cc)
        );
        this.tableHeaderOrg = JSON.parse(
          JSON.stringify(this.product_specifications_theads)
        );
      }
    }
    this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
  },
  methods: {
    pathTo() {
        // this.tempBack = true;
        this.$router.push(`/settings`);

        // this.showOverviewDiscardModal = true
    },
    languageManipulation(value){
      return value?.templateValue.filter(item => item.templateString !== "");
      // return [{name:'sj'}]
    },
    showDrawerRight() {
      this.show_right_filter = true;
    },
    closeRightFilter() {
      this.show_right_filter = false;
    },
    async emailFilter(filterSelectedvalue, filterQuery) {
      // ;
      console.log(filterQuery, "filterQuerrry");
      this.filtertagvalue = filterSelectedvalue;
      this.filtertaglength = filterSelectedvalue.length;
      filterQuery.company = this.company;
      if (this.filtertagvalue.length != 0) {
        // this.totalpageno = spectotalpageno
        // this.tableData = spectabledata
        this.filterQuery = filterQuery;
        this.filterQuery.type = "filter";
        if (this.searchvalue != "") {
          this.filterQuery["searchQuery"] = this.searchvalue;
          this.filterQuery["type"] = "filter-search";
        }
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          this.filterQuery
        );
        // console.log(filterResultData.data.data,'la');

        this.tableData = filterResultData.data.data;
        this.totalpageno = filterResultData.data.total;
        // ;
      } else {
        this.currPage = 1;
        this.rowsPerPage = 10;
        this.dataCalling(this.currPage, this.rowsPerPage);
      }
    },
    openModal(){
      this.modal_open= true
      this.languageOptions.forEach((item)=>{
       let payLoad = {
        lang:'',
        templateString:'',
       }
       payLoad['lang'] = item.name
       this.templatePayload.push(payLoad)
    })
    },
    async addTemplate(){
      try {
          let obj = {
            name: this.templateName,
            description:this.description,
            templateValue: this.templatePayload
          };
          const value = await UPCAxiosInstance.post("emailTemplate", obj);
          this.notification = {
            type: "success",
            message: `Nice work! Your template is saved!`,
          };
         console.log(this.selectedTemplate,'value',value)
         if(value.status == 201){
          sessionStorage.setItem("template_id", value.data._id);
          console.log('value1',value.data._id,this.selectedTemplate)
          console.log('value2',value.data._id,this.selectedTemplate)
           if(this.selectedTemplate == 'editor'){
            const savedtemplateId = sessionStorage.getItem("template_id");
            console.log(savedtemplateId,'test')
            this.$router.replace({
        name: 'htmlEmailTemplate',
        query: { id: savedtemplateId },
      });
      sessionStorage.removeItem("template_id");
            //  this.$router.push(`/settings/emailTemplate/editor`);
           }else if(this.selectedTemplate == 'builder'){ 
            

             this.$router.push(`/settings/emailTemplate/create`);
           }
         }

          console.log(value, "testing");
        } catch (error) {
          console.log(error);
        }
    },
    selectTemplate(template) {
      this.selectedTemplate = template;
    },
    reAssignOrgTableHeader() {
      // If there is change in the table header then ressign to the Orginal data
      if (
        this.product_specifications_theads_cc != this.tableHeaderOrgcc &&
        this.tableHeaderOrgcc.length > 0
      ) {
        this.product_specifications_theads_cc = JSON.parse(
          JSON.stringify(this.tableHeaderOrgcc)
        );
      }
      if (
        this.product_specifications_theads != this.tableHeaderOrg &&
        this.tableHeaderOrg.length > 0
      ) {
        this.product_specifications_theads = JSON.parse(
          JSON.stringify(this.tableHeaderOrg)
        );
      }
      this.re_arrange = true;
    },
    resizedWidth(val) {
      console.log(val, "resizedWidth", val);

      const updateWidth = (array) => {
        let item = array.find((i) => i?.prop === val?.prop);
        if (item) item.width = val.width;
      };

      updateWidth(this.product_specifications_theads);
      updateWidth(this.product_specifications_theads_cc);
      console.log(
        this.product_specifications_theads,
        "resizedWidth loop",
        this.product_specifications_theads_cc
      );
      this.applyColumns(this.product_specifications_theads_cc);
    },
    async applyColumns(val) {
      console.log("applyColumns", val);
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
      let concatedEntity = sampleTheads?.data?.entityRef || {};

      concatedEntity.settingsEmail = val;

      let reArrangeTableHeadPayload = { entityRef: concatedEntity };

      const changeTableHeader = await MLTAxiosInstance.patch(
        `/settings`,
        reArrangeTableHeadPayload
      );
      console.log(
        changeTableHeader,
        "reArrangeTableHeadPayload",
        reArrangeTableHeadPayload
      );

      let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
      console.log("sampleTheadsRefresh", sampleTheadsRefresh?.data);

      let entityRef = sampleTheadsRefresh?.data?.entityRef || {};
      this.product_specifications_theads_cc = entityRef["settingsEmail"];
      this.product_specifications_theads =
        this.product_specifications_theads_cc.filter((ele) => ele.checked);
      this.tableHeaderOrgcc = JSON.parse(
        JSON.stringify(this.product_specifications_theads_cc)
      );
      this.tableHeaderOrg = JSON.parse(
        JSON.stringify(this.product_specifications_theads)
      );
    },
    async taxBased(val) {
      console.log(val, "taxbased");
      val.forEach((item) => {
        if (item.checked == true) {
          this.tax_based_on = item.value;
        }
      });
      // this.filterQuery['filterQuery'].locationRef = this.tax_based_on
      // console.log(this.filterQuery['filterQuery'])
      //  this.filterQuery.filterQuery.locationRef = this.tax_based_on
      //  const filterResultData = await UPCAxiosInstance.post(
      //     `util/filterData`,
      //     this.filterQuery
      //   );
      //   if (
      //     filterResultData.data.data != undefined &&
      //     filterResultData.data.total != 0
      //   ) {
      //     this.tableData = filterResultData.data.data;
      //     this.totalpageno = filterResultData.data.total;
      //     this.paginatedData;
      //   }
    },
    provincePreval(val) {
      let arr = [];
      val.forEach((item) => {
        arr.push(item.name);
      });
      return arr;
    },
    async categoryData() {
      try {
        console.log("kjhfj");
        const supplierFilterRes = await UPCAxiosInstance.post(
          "util/filterLayout",
          this.filterLayout
        );
        // state.suppliersFilterData = supplierFilterRes.data;
        console.log("productRecommed 3.0 2 category", supplierFilterRes.data);

        this.searchFilterData = supplierFilterRes.data;
      } catch (error) {
        console.error(error);
      }
    },
    addTax() {
      this.edit = false;
      this.addNameRate = [
        {
          taxname: "",
          rate: "",
        },
      ];
      this.taxCalcOptions = [
        { name: "Cumulative", checked: true },
        { name: "Compound", checked: false },
      ];
      this.taxapplyOptions = [
        { name: "All accounts", checked: true },
        { name: "Specific market", checked: false },
        { name: "Specific accounts", checked: false },
      ];
      this.payload = {
        taxcode: "",
        country: "",
        province: [],
        pricable: false,
        taxType: "Cumulative",
        notes: "",
        tax: [],
        appliesTo: [
          { name: "All accounts", checked: true },
          { name: "Specific market", checked: false },
          { name: "Specific accounts", checked: false },
        ],
        allAccount: true,
        specificAccount: false,
        specificMarket: false,
      };
      this.specificAccountOption = false;
      this.specificMarketOption = false;
      this.provincePayload = [];
      this.componentKey += 1;
      this.showTaxDrawer = true;
      this.componentKey1 += 1;
    },
    // cancelFunc(){
    //   this.showTaxDrawer = false
    //     this.edit = false
    //     this.addNameRate=[{
    //     taxname:'',
    //     rate:''
    //   }]
    //   this.taxCalcOptions=[
    //     {name:'Cumulative', checked:true},
    //     {name:'Compound',checked:false}
    //   ]
    //   this.taxapplyOptions=[
    //     {name:'All accounts', checked:true},
    //     {name:'Specific market',checked:false},
    //     {name:'Specific accounts',checked:false}
    //   ]
    //   this.payload={
    //     country:'',
    //     province:[],
    //     pricable:false,
    //     taxType:'Cumulative',
    //     notes:'',
    //     tax:[],
    //     appliesTo:[
    //     {name:'All accounts', checked:true},
    //     {name:'Specific market',checked:false},
    //     {name:'Specific accounts',checked:false}
    //   ],
    //     allAccount: false,
    //     specificAccount: false,
    //     specificMarket: false,
    //   }
    // },
    closeTaxDrawer() {
      this.showTaxDrawer = false;
      this.edit = false;
      this.addNameRate = [
        {
          taxname: "",
          rate: "",
        },
      ];
      this.taxCalcOptions = [
        { name: "Cumulative", checked: true },
        { name: "Compound", checked: false },
      ];
      this.taxapplyOptions = [
        { name: "All accounts", checked: true },
        { name: "Specific market", checked: false },
        { name: "Specific accounts", checked: false },
      ];
      this.payload = {
        taxcode: "",
        country: "",
        province: [],
        pricable: false,
        taxType: "Cumulative",
        notes: "",
        tax: [],
        appliesTo: [
          { name: "All accounts", checked: true },
          { name: "Specific market", checked: false },
          { name: "Specific accounts", checked: false },
        ],
        allAccount: true,
        specificAccount: false,
        specificMarket: false,
      };
    },
    addtaxNameRate() {
      this.addNameRate.push(this.duplicateaddNameRate);
    },
    deletetaxNameRate(index) {
      this.addNameRate.splice(index, 1);
    },
    async countryValue(val) {
      console.log(val, "country");
      this.payload.country = val;
      let payload = await MLTAxiosInstance.get(
        `/countryInfo/basedOnQuery?_id=${val._id}`
      );
      this.componentKey1 += 1;
      this.provincePayload = payload.data[0].states;
      console.log(payload.data[0].states, "countrypayload");
    },
    provinceValue(val) {
      // let arr = []
      // val.forEach((element) => {
      //   if(element.checked == true){
      //      arr.push(element.name)
      //   }
      // });
      this.payload.province = val;
      console.log(val, "province");
    },
    async applyToValue(val) {
      console.log(val, "name");
      if (val.name == "All accounts") {
        this.specificAccountOption = false;
        this.specificMarketOption = false;
        this.payload.allAccount = true;
        this.payload.specificAccount = false;
        this.payload.specificMarket = false;
      } else if (val.name == "Specific accounts") {
        // this.filterQuery1.company = this.company
        // let obj1 = {}
        // if(this.payload.country.name.common != '' && this.payload.province.length != 0){
        //   obj1 = {
        //     "@baseType": "customer",
        //     "contactMedium[1].characteristic.country": this.payload.country.name.common,
        //     "contactMedium[1].characteristic.state" : this.payload.province.name
        //   }
        //   console.log(this.payload.country.name.common != '' && this.payload.province.length != 0,'test')
        // }else if(this.payload.country.name.common != ''){
        //   obj1 = {
        //   "@baseType": "customer",
        //   "contactMedium[1].characteristic.country": this.payload.country.name.common,
        // }
        // }else{
        //   obj1 = {
        //   "@baseType": "customer",
        // }
        // }
        // this.filterQuery1.filterQuery= obj1
        // const data = await MLTAxiosInstance.post(
        // `util/filterData`,
        // this.filterQuery1)
        // this.mainInfo = data.data
        this.specificAccountOption = true;
        this.specificMarketOption = false;
        this.payload.allAccount = false;
        this.payload.specificAccount = true;
        this.payload.specificMarket = false;
      } else if (val.name == "Specific market") {
        this.specificAccountOption = false;
        this.specificMarketOption = true;
        this.payload.allAccount = false;
        this.payload.specificAccount = false;
        this.payload.specificMarket = true;
      }
      // this.payload.taxType = val.name
    },
    taxCalcValue(val) {
      this.payload.taxType = val.name;
    },

    marketValues(val) {
      this.payload.appliesTo = val;
    },
    getcheckedValue(val) {
      this.payload.appliesTo = val;
    },
    async sendPayload() {
      this.payload.tax = this.addNameRate;
      let obj = {};
      obj["locationRef"] = this.tax_based_on;
      this.payload.locationRef = this.tax_based_on;
      console.log(this.payload, "payload");
      let addPayload = JSON.parse(JSON.stringify(this.payload));
      addPayload.pricable = !addPayload.pricable;
      const taxPayload = await UPCAxiosInstance.post(`tax`, addPayload);
      this.notification = {
        type: "success",
        message: `Nice work! Your new tax is now in the list!`,
      };
      // this.taxdataCalling(this.currPage, this.rowsPerPage);
      this.addNameRate = [
        {
          taxname: "",
          rate: "",
        },
      ];
      this.taxCalcOptions = [
        { name: "Cumulative", checked: false },
        { name: "Compound", checked: false },
      ];
      this.payload = {
        taxcode: "",
        country: "",
        province: [],
        pricable: false,
        taxType: "",
        notes: "",
        tax: [],
        appliesTo: [],
        allAccount: true,
        specificAccount: false,
        specificMarket: false,
      };
      this.componentKey1 += 1;
      this.showTaxDrawer = false;
      console.log(taxPayload, "payload");
    },
    // async taxdataCalling(currentPage, rowsPerPage) {
    //   const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    //   this.company = val.data.company;
    //   this.filterQuery["company"] = this.company;
    //   this.filterQuery["paginatedQuery"]["currentPage"] = currentPage;
    //   this.filterQuery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
    //   const filterResultData = await UPCAxiosInstance.post(
    //     `util/filterData`,
    //     this.filterQuery
    //   );
    //   if (
    //     filterResultData.data.data != undefined &&
    //     filterResultData.data.total != 0
    //   ) {
    //     this.tableData = filterResultData.data.data;
    //     this.totalpageno = filterResultData.data.total;
    //     this.paginatedData;
    //   }
    // },
    async currentPage(val) {
      this.currPage = val;
      // this.taxdataCalling(this.currPage, this.rowsPerPage);
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    async changeRowsPerPage(count) {
      this.rowsPerPage = count;
      // this.taxdataCalling(this.currPage, this.rowsPerPage);
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    computedTableAction(data) {
      let options = [
        {
          label: "Edit",
          icon: "icon-file-text-edit-regular",
        },
        {
          label: "Clone",
          icon: "icon-copy-alt-regular",
        },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ];
      return options.filter((option) => {
        if (
          data &&
          data.status &&
          data.status?.toLowerCase() == "draft" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status?.toLowerCase() == "active" &&
          ["Draft", "Mark as active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status?.toLowerCase() == "inactive" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else {
          return true;
        }
      });
    },
    // changeForTagOption(data){
    //   let arr=[]
    //   data.forEach((element) => {
    //     let obj = {}
    //     obj.name = element
    //     arr.push(obj)
    //   });
    //   return arr
    // },
    crudContextMenu($event, index) {
      // ;
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;

        //
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    async closeCrudContextMenu($event, ele, i) {
      // //

      if ($event?.toLowerCase() === "edit") {
        console.log(i, ele, "valueee");
        // this.$router.push({
        //   name: "createEmailTemplate",
        //   query: { template: ele._id },
        // });
        this.$router.replace({
        name: 'htmlEmailTemplate',
        query: { id: ele._id },
      });
      } else if ($event?.toLowerCase() == "delete") {
        this.delete_modal_active = true;
        this.deleteobj = ele;
        this.delete_name = ele.name;
        this.deleterowindex = i;
      } else if ($event?.toLowerCase() == "clone") {
        console.log(ele,i)
        let data = JSON.parse(JSON.stringify(ele))
        delete data.id
        delete data._id
        data.name = data.name + '_copy'
        const value = await UPCAxiosInstance.post("emailTemplate", data);
        value
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
        this.dataCalling(this.currPage, this.rowsPerPage);  
          this.notification = {
            type: "success",
            message: `Nice work! Your template is cloned!`,
          };
      }
    },
    viewPage(ele){
      // this.$router.push({
      //     name: "createEmailTemplate",
      //     query: { template: ele._id },
      //   });
        this.$router.replace({
        name: 'htmlEmailTemplate',
        query: { id: ele._id  },
      });
    },
    async editPayload() {
      this.payload.id = this.payload._id;
      this.payload.tax = this.addNameRate;
      delete this.payload._id;
      delete this.payload.context;
      delete this.payload.createdAt;
      delete this.payload.__v;
      delete this.payload.updatedAt;
      let obj = {};
      obj["locationRef"] = this.tax_based_on;
      this.payload.locationRef = this.tax_based_o;
      const taxPayload = await UPCAxiosInstance.patch(`tax`, this.payload);
      this.notification = {
        type: "success",
        message: `All set! Your changes have been saved successfully!`,
      };
      this.showTaxDrawer = false;
      // this.taxdataCalling(this.currPage, this.rowsPerPage);
      console.log(this.payload, "editpayloadd", taxPayload);
    },
    async deleteExecRow() {
      await UPCAxiosInstance.delete(`emailTemplate/${this.deleteobj._id}`);
      this.showTaxDrawer = false;
      // this.taxdataCalling(this.currPage, this.rowsPerPage);
      this.delete_modal_active = false;
      this.notification = {
        type: "success",
        message: `Done! Your item has been removed.`,
      };
      this.dataCalling(this.currPage, this.rowsPerPage);  

      // let value = await UPCAxiosInstance.get("emailTemplate");
      // this.tableData = value.data;
      // this.tableData = this.tableData.sort(
      //   (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      // );
    },
    async searchKey(val) {
      this.searchvalue = val;
      this.filterQuery["company"] = this.company;
      this.filterQuery["type"] = "search";
      this.filterQuery["searchQuery"] = this.searchvalue;
            this.currPage = 1;
      this.rowsPerPage = 10;
      // this.searchvalue
      if(this.searchvalue == ''){
        this.filterQuery["type"] = "filter";
        let value = await UPCAxiosInstance.get("emailTemplate");
    this.tableData = value.data;
    this.emailTemplateTableData = JSON.parse(JSON.stringify(value.data));
    this.totalpageno = this.tableData.length
    this.tableData = this.tableData.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );

      }else{
        this.dataCalling(this.currPage, this.rowsPerPage);  
      }
    },
    async dataCalling(currentPage,rowsPerPage){
                                const val = await MLTAxiosInstance.get('partymanagement/individual/auth');
                                this.company = val.data.company;
                                this.filterQuery['company'] = this.company
                                this.filterQuery['paginatedQuery']['currentPage'] = currentPage
                                this.filterQuery['paginatedQuery']['rowsPerPage'] = rowsPerPage
                                const filterResultData = await UPCAxiosInstance.post(
                                `util/filterData`,
                                this.filterQuery
                                );
                            // if(filterResultData.data.data != undefined && filterResultData.data.total != 0)
                            // {
                            this.tableData = filterResultData.data.data;
                          
                            this.tableData = this.tableData.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
                            this.totalpageno = filterResultData.data.total;
                            this.paginatedData;
                            this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
                            // }
    },
    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },
  },
};
</script>
<style lang="scss"></style>
