<template>
  <div class="app-layout">
    <section class="right-panel">
      <hlx-alert-notification :notify="notification" :auto-close="true" />
      <main class="--right-layout-pad rule-execution-container">
        <div class="layout-breadcrumb">
          <hlx-breadcrumb :items="breadcrumb_items" @path="pathTo" />
        </div>
        <div class="execution-separator-container">
          <section class="execution-attributes-container">
            <div class="execute-data-title">
              <span class="--title">Payload to test the rule execution</span>
              <hlx-button
                class="primary sm"
                style=""
                @click="testDataExecution"
              >
                Test
              </hlx-button>
            </div>

            <!-- <div>Execute rule</div>
                <span class="info"
                  >Manage test data for rule validation and testing</span
                > -->
            <div class="custom-object-viewer">
              <span class="manual-data-content" style="height: 100%">
                <hlx-object-viewer
                  :json-data="testData"
                  :show="true"
                  :type="'read-only'"
                  @updated-json="recievedJson($event)"
                />
              </span>
              <span
                v-if="showValidationError"
                style="color: #fe4141; font-size: 12px"
                >Please supply the data to test the rule</span
              >
            </div>
          </section>
          <section class="result-container">
            <div class="result-metric-panel">
              <span class="--title">Output</span>
              <span class="--title"
                >Executed duration:&nbsp;
                <span :style="['color:#54BD95;']">
                  {{ average_time + " ms" }}</span
                ></span
              >
              <span class="--title"
                >Status:&nbsp;
                <span>
                  <hlx-label
                    v-if="isExecuted"
                    class="sm"
                    :class="{ success: true, danger: false }"
                    >Success</hlx-label
                  >
                  <span v-else>--</span>
                </span></span
              >
            </div>
            <div class="manual-data-content">
              <hlx-object-viewer
                :json-data="result"
                :show="true"
              ></hlx-object-viewer>
            </div>
          </section>
        </div>
      </main>
    </section>
  </div>
</template>

<script>
import { DMNAxiosInstance } from "@/config/axiosConfig";
export default {
  data() {
    return {
      breadcrumb_items: [
        { label: "Rule validation", link: "/rule_validation" },
        { label: `${this.$route.query.schema}`, link: "" }
      ],
      id: this.$route.query.id,
      testData: {},
      data: {
        _id: "63db4e739ecaee3075043158",
        name: "EIOP",
        data: {
          item: {
            type: "Number"
          }
        },
        createdBy: "639af00f9f515f57074584ef",
        lastModifiedBy: null,
        __v: 0,
        createdAt: "2023-02-02T05:47:31.988Z",
        updatedAt: "2023-02-02T05:47:31.988Z"
      },
      result: {
        payload: []
      },
      isExecuted: false,
      average_time: 0,
      successful_execution_rate: 0,
      unsuccessful_execution_rate: 0,
      forAverageTime: []
    };
  },
  async mounted() {
    //  const id = 'your-id'; // Replace with your actual id
    //  const name = 'your-name'; // Replace with your actual name
    //  const startTime = 0
    await DMNAxiosInstance.get(
      `/rule_engine/execution_data/${this.$route.query.id}`
    ).then((response) => {
      
      this.testData = response.data.data;
    });
    this.$store.commit("token", this.$route.params.token);
  },
  methods: {
    async testDataExecution() {
      await DMNAxiosInstance.get(
        `/rule_engine/execution_data/execute/${this.$route.query.id}`
      )
        .then((response) => {
          
          // Handle the response here
          // this.schemaOptions = response.data
          // // 
          // const responseTime = Date.now() - startTime;
          // 
          const responseTime = response.data.executedDuration;
          //  // 
          // 
          if (response.data.ok == 1) {
            this.isExecuted=true
            const data = JSON.parse(JSON.stringify(response.data.data));
            this.forAverageTime = data;
            
            this.result.payload = response.data.data;
            
            this.processPayload(this.result);
            // 
            const total = data.length;
            let success = 0;
            let failure = 0;

            data.forEach((item) => {
              if (item.ok === 1) {
                success++;
              } else {
                failure++;
              }
            });

            const successRate = ((success / total) * 100).toFixed(0);
            const failureRate = ((failure / total) * 100).toFixed(0);

            //  // 
            //  // 

            this.successful_execution_rate = successRate;
            this.unsuccessful_execution_rate = failureRate;

            if (
              this.successful_execution_rate &&
              this.unsuccessful_execution_rate === 0
            ) {
              this.average_time = 0;
            } else {
              this.average_time = responseTime;
            }
            //  // 
          }
        })
        .catch((error) => {
          // Handle the error here
          console.error(error);
        });
      let arr = [];
      this.forAverageTime.forEach((e) => {
        arr.push(e.executedDuration);
      });
      var total = 0;
      var count = arr.length;
      arr.forEach((e) => {
        total += e;
      });
      var avg = total / count;
      // 
      const execData = await DMNAxiosInstance.get(
        `/rule_engine/execution_data/${this.$route.query.id}`
      );
      execData.data.executionCount += 1;
      execData.data.averageDuration = `${avg.toFixed(2)} ms`;
      
      // let patData = 
      await DMNAxiosInstance.patch(
        `/rule_engine/execution_data/${this.$route.query.id}`,
        execData.data
      );
      
    },
    processPayload(payload) {
      // Iterate over each item in the payload
      for (let item of payload.payload) {
        // Remove double quotes from keys
        const keys = Object.keys(item);
        for (let key of keys) {
          const value = item[key];
          const newValue = value === null ? "null" : value;
          delete item[key];
          item[key.replace(/"/g, "")] = newValue;
        }
      }

      return payload;
    },
    navTo(active) {
      this.$store.commit("token", this.$route.params.token);
      // 
      if (active.name == "Home") {
        this.navHome();
      } else if (active.name == "logout") {
        this.logout();
      } else if (active.name == "settings") {
        this.setting();
      } else if (active.name == "Schema") {
        this.schema();
      } else if (active.name == "Business rule") {
        this.ruled();
      } else if (active.name === "Database") {
        this.execute();
      } else if (active.name == "Help") {
        this.help();
      } else if (active.name == "Execute") {
        this.exe();
      } else {
        this.audit();
        // 
      }
    },
    audit() {
      this.$router.push(`/DMN/audit`);
    },
    help() {
      window.open("https://documentation.halleyx.com");
    },
    ruled() {
      this.$router.push(`/DMN/business_rules`);
    },
    exe() {
      this.$router.push(`/DMN/rule_validation`);
    },
    execute() {
      this.$router.push(`/DMN/reference_data`);
    },
    schema() {
      this.$router.push(`/DMN/schemas`);
    },

    logout() {
      this.$router.push("/");
    },
    setting() {
      this.$router.push(`/settings`);
    },
    async navHome() {
      this.$router.push(`/DMN/dashboard`);
    },
    pathTo(val) {
      // 
      val;
      this.$router.back();
    },
    goBack(val) {
      // 
      val;
      this.$router.back();
    }
  }
};
</script>
