<template>


          <!--lead filter drawer-->
          <hlx-drawer
            :show="show_filter"
            :width="300"
            position="right"
            :header="false"
            :footer="false"
            :show-close-icon="true"
            @close="show_filter = false"
          >
            <template #body>
              <div style="display: flex">
                <FilterComponent
                  :filterpanelname="'saleslead'"
                  :company-name="companyName"
                  :query-value="query"
                  :display-data="filter"
                  :module="'saleslead'"
                  @filtered-data="supplierfilterData"
                ></FilterComponent>
              </div>
            </template>
          </hlx-drawer>

    <hlx-alert-notification
    :show-alert="alertmsg"
    :notify="notification"
    :auto-close="true"
  />

<!-- Modal window for adding new lead -->
  <hlx-modal
    :key="modalKey"
    :modal-active="showAddNewLeadModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'700px'"
    :width="'800px'"
    :modal="true"
    @close="closeAddNewLeadModal()"
  >
    <template #header>
      <div>
        <!-- Add lead -->
        {{ loadStaticData("LANG-0005") }}
        <!-- add lead -->
      </div>
    </template>
    <template #content>
      <!-- New lead modal-->
      <div v-if="showAddNewLeadModal && !showAllSitesModal && !showSiteInformationModal" class="modal-content">
          <section class="lead-modal-top">
            <section style="display: flex">
              <span  class="lead-modal-top-num">
                1
              </span>
              <span class ="lead-modal-top-name">
                <!-- Account information -->
                {{ loadStaticData("LANG-0024") }}
              </span>
            </section>
            <span class="lead-modal-top-line"></span>
            <section style="display: flex">
              <span class="lead-modal-top-num" style="background:#AAAAAA;">
                2
              </span>
              <span class ="lead-modal-top-name" style="color:#AAAAAA;">
                <!-- Site information -->
                {{ loadStaticData("LANG-0023") }}
              </span>
            </section>
          </section>
          <!-- Add new lead modal window content -->
          <section class="lead-modal-content">
          <!-- <span class="left-section" style="display:none">
            <div style="display: flex; justify-content: space-between;margin-bottom: 20px">
            <hlx-file-upload
              :key="profileUploadComponent"            
              :file-type="'.xlsx, .csv'"
              :file-size-limit="'20mb'"
              :uploadtype="'Rectangle'"
              :style-background="backgrounStyle"
              :height="100"
              :width="100"
              :read-only="false"
              :custom-icon="icon"
              :type ="'profile-upload'"
              :content="'Import'"
              @native-data="profileUpload"
            />
            <span style="width: 200%">
              <div
                :style="{ 'margin-bottom': customnameerror ? '-7.6%' : 0 ,'width': '100%','display':'flex','align-items': 'baseline','gap':'4px'}"
                >
                <span style="width:100%">
                <hlx-input
                   v-model:value="leadPayload.name"
                  :pre-val="leadPayload.name"
                  :label-value="'Company name'"
                  :label-animation="true"
                  :required="true"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
              </span>
                <i style="color: #A6A6A6;" class="icon-info-circle-regular"></i>
              </div>

              <div style="width: 100%">
                <hlx-status
                  :type="'select-status'"
                  :prevalue="leadPayload.status"
                  :status-option="filteredstatus"
                  @emit-select="leadStatus"
                >
                </hlx-status>
              </div>
            </span>
          </div>
          <section class="left-section-select">
            <div>
          <hlx-select
             :key="componentKey"
              v-model:value="leadPayload.relatedParty[1].value"
              :pre-value="leadPayload.relatedParty[1].value"
              :inline-search="true"
              :options="leadSourceOptions"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Lead source'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :display-error="true"
              :clearable="true"
              @selected-value="getLeadSource"
            ></hlx-select>
          </div>
        <div>
            <hlx-select
            :key="componentKey"
                 v-model:value="leadPayload.type"
              :pre-value="leadPayload.type"
              :inline-search="true"
              :options="leadTypeOptions"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Lead type'"
              :prop-value="'name'"
              :display-error="true"
              :label="'name'"
              :label-animation="true"
              :clearable="true"
              @selected-value="getLeadType"
            ></hlx-select>
          </div>
        <div>
            <hlx-select
            :key="componentKey"
                 v-model:value="leadPayload.dwellingtype"
              :pre-value="leadPayload.dwellingtype"
              :inline-search="true"
              :display-error="true"
              :options="dwellingTypeOptions"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Dwelling type'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :clearable="true"
              @selected-value="getLeadDwellingType"
            ></hlx-select>
          </div>
        <div>
            <hlx-select
            :key="componentKey"
                 v-model:value="leadPayload.category.name"
              :pre-value="leadPayload.category.name"
              :inline-search="true"
              :options="leadCategoryOptions"
              :required="true"
              :display-error="true"
              :slot-options="false"
              :placeholder-value="'Category'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :clearable="true"
              @selected-value="getLeadCategory"
            ></hlx-select>
          </div>
              </section>
        </span>
        <span class="right-section" style="display:none">
          <div style="margin-bottom:12px">
              <hlx-input
                v-model:value="leadPayload.description"
                :pre-val="leadPayload.description"
                type="textarea"
                :label-value="'Description'"
                :label-animation="true"
                :rows="8"
                :required="false"
                :display-error="false"
                :custom-error="customnameerror"
                :custom-error-message="''"
                @focus-out="focusOutName"
              ></hlx-input>
            </div>
            <div style="display:flex">
              <div style="width:94%">
            <hlx-select
              :key="componentKey"
              v-model:value="leadPayload.relatedParty[0].value"
              :pre-value="leadPayload.relatedParty[0].value"
              :inline-search="true"
              :options="leadOwnerOptions"
              :required="true"
              :placeholder-value="'Lead owner'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :clearable="true"
              :display-error="true"
              @selected-value="getLeadOwner"
            ></hlx-select>
              </div>
            <div style="margin: auto;padding-bottom: 24px;">
                <i style="color: #A6A6A6;"  class="icon-info-circle-regular"></i>
              </div>
          </div>
          <div style="display:flex">
            <div style="width:94%">
            <hlx-input 
                  :key="drawerKey"
                   v-model:value="leadPayload.externalReference[1].value"
                  :pre-val="leadPayload?.externalReference[1]?.value"
                   type="number"
                  :label-value="'Number of sites'"
                  :label-animation="true"
                  :required="true"
                  :custom-error="customnameerror"
                  :display-error="true"
                  :custom-error-message="'Please fill the field'"
                  @focus-out="focusOutName"
                  @at-input="validateName"
                ></hlx-input>
              </div> 
            <div style="margin: auto;padding-bottom: 24px;">
                <i style="color: #A6A6A6;"  class="icon-info-circle-regular"></i>
              </div>
          </div>
          <div style="display:flex">
            <div style="width:94%">
            <hlx-select
              :key="componentKey"
              v-model:value="leadPayload.dealCategory.name"
              :pre-value="leadPayload.dealCategory.name"
              :inline-search="true"
              :options="leadDealCategoryOptions"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Deal category'"
              :prop-value="'name'"
              :label="'name'"
              :display-error="true"
              :label-animation="true"
              :clearable="true"
              @selected-value="getLeadDealCategory"
            ></hlx-select>
            </div>
            <div style="margin: auto;padding-bottom: 24px;">
                <i style="color: #A6A6A6;"  class="icon-info-circle-regular"></i>
              </div>
          </div> 
          <div>
            <hlx-input
               v-model:value="leadPayload.externalReference[0].value"
              :pre-val="leadPayload.externalReference[0].value"
              :label-value="'Website URL'"
              type="text"
              :prefix="true"
              :label-animation="true"
              :display-error="true"
              :custom-error="isUrlEmpty"
              :custom-error-message="'Please enter a valid URL in the format: www.abc.com'"
              @focus-out="websiteRegex"
              @at-input="validateName"
            >
            </hlx-input>
          </div>
               <div>
                  <hlx-multi-select
                :key="componentKey"
                :options="tagsOptions"
                :pre-value="leadPayload.tags"
                :placeholder="'Tags'"
                :label-animation="true"
                :prop-value="'name'"
                :label="'name'"
                :disabled="false"
                :filter-select="false"
                :tooltipposition="'left'"
                @add-data="addTagList"
                @custom-change="receivedTags"
              ></hlx-multi-select>
              </div> 
        </span> -->
        <div class="parent-container-for-lead-maintable-overview">
        <fieldRenderer
              :key="updateFieldRenderKeyForLead"
              :schema-payload = "leadSchema"
              :layout="'modal'"
              :data-payload = "leadPayloadEmpty"
              :is-save-clicked = "isSaveButtonClickedForLead"
              :is-modal-window = "modalPropsForFieldRender"
              :updated-schema-after-remap = "updatedLeadSchema"
              :status-list="[
                'Open',
                'Network approved',
                // 'In Discussion',
                'Qualified',
                'Network rejected',
                'Unqualified',
                'Awaiting network approval',
                'Converted',
                'Closed Lost',
              ]"
              :parent-class-name="'parent-container-for-lead-maintable-overview'"
              @edited-payload="getLeadDataForOverview"
              @lookup-data="getExistedLeadData"
            ></fieldRenderer>
      </div>
      </section>
            <!-- Lead contact details -->
            <section style="margin-bottom:12px;font-family: 'Quicksand';font-size: 14px;font-weight: bolder;">
          <i class="icon-user-regular"></i>
          <span>
            <!-- Contact details -->
            {{ loadStaticData("LANG-0022") }}
          </span>
        </section>
        <section class="lead-modal-content">
        <!-- <span class="left-section" style="display:none">
          <div>
          <hlx-select
              :key="componentKey"
              :pre-value="leadContactDetails?.title"
              :inline-search="true"
              :options="titleData"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Title'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :display-error="true"
              :clearable="true"
              @selected-value="getTitleForContact"
            ></hlx-select>
          </div>
            <div>
              <hlx-input
                 :pre-val="leadContactDetails.firstName"
                  :label-value="'First name'"
                  :label-animation="true"
                  :required="true"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
            </div>
            <div>
              <hlx-input
             :pre-val="leadContactDetails.lastName"
                  :label-value="'Last name'"
                  :label-animation="true"
                  :required="true"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
            </div>
            <div>

          <hlx-select
          :key="componentKey"
              :pre-value="leadContactDetails.contactType"
              :inline-search="true"
              :options="leadContactType"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Type'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :display-error="true"
              :clearable="true"
              @selected-value="getTypeOfContact"
            ></hlx-select>
          </div>
        </span> -->
        <!-- <span class="right-section" style="display:none">
          <div>
            <hlx-input
             :pre-val="leadContactDetails.contactMedium[0].characteristic.emailAddress"
              type="email"
              :label-value="'Email id'"
              :required="true"
              :label-animation="true"
              :display-error="true"
              :custom-error="isExistingEmail"
              :custom-error-message="isExistingEmailErrorMessage"
               @focus-out="validateEmailNew"
              @at-input="validateEmail"
              ></hlx-input>
            </div>
              <div>
              <hlx-input
             :pre-val="leadContactDetails.contactMedium[2].characteristic.telephoneNumber.value.value"
                  :label-value="'Telephone number'"
                  :label-animation="true"
                  :required="true"
                  type="number"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
              </div>
              <div>
                <hlx-input
             :pre-val="leadContactDetails.contactMedium[1].characteristic.phoneNumber.value"
              type="phonenumber"
              :label-value="'Phone number'"
              :label-animation="true"
              :display-error="true"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
              ></hlx-input>
              </div>
              <div>
          <hlx-select
          :key="componentKey"
              :pre-value="leadContactDetails.preferredLanguage"
              :inline-search="true"
              :options="specdata"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Preferred language'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :display-error="true"
              :clearable="true"
              @selected-value="handleSpecChanges"
            ></hlx-select>
          </div>
        </span> -->
        <div class="parent-container-for-lead-maintable-contact">
        <fieldRenderer
              :key="updateFieldRenderKeyForContact"
              :layout="'modal'"
              :schema-payload = "contactSchema"
              :data-payload = "leadContactDetails"
              :is-add-clicked = "isAddButtonClicked"
              :updated-schema-after-remap = "updatedContactSchemaForRemap"
              :is-save-clicked = "isSaveButtonClickedForContact"
              :is-modal-window = "modalPropsForFieldRender"
              :status-list="[
                'Open',
                // 'In Discussion',
                'Qualified',
                'Unqualified',
                'Network approved',
                'Network rejected',
                'Awaiting network approval',
                'Converted',
                'Closed Lost',
              ]"
              :parent-class-name="'parent-container-for-lead-maintable-contact'"
              @edited-payload="getLeadData"
              @updated-schema="getContactUpdatedSchema"
            ></fieldRenderer>
      </div>
      </section>
      </div>

      <!-- Modal for showing all sites -->
      <div v-if="showAddNewLeadModal && showAllSitesModal && !showSiteInformationModal" class="modal-content">
        <section class="lead-modal-top">
            <section style="display: flex">
              <span  class="lead-modal-top-num">
                1
              </span>
              <span class ="lead-modal-top-name">
                <!-- Account information -->
                {{ loadStaticData("LANG-0024") }}
              </span>
            </section>
            <span class="lead-modal-top-line"></span>
            <section style="display: flex">
              <span class="lead-modal-top-num">
                2
              </span>
              <span class ="lead-modal-top-name">
                <!-- Site information -->
                {{ loadStaticData("LANG-0023") }}
              </span>
            </section>
          </section>
        <section v-for="(i,index) in temporaryleadPayload?.externalReference[1].value ? temporaryleadPayload?.externalReference[1].value : 1" :key="index">
          <section class="lead-dropdowm-site-cover" style="cursor:pointer" @click="openSiteDetails(i,index)">
            <div class="lead-dropdowm-site-num">
              {{siteDetailsEmpty[index]?.siteName?siteDetailsEmpty[index]?.siteName:'Site -' + (index + 1) +' details'}}
            </div>
            <div class="lead-dropdowm-site-icon">
              <i style="color: #A6A6A6;"  class="icon-angle-down-filled"></i>
            </div>
          </section>
        </section>
      </div>

      <!-- Modal for showing specific sites info -->

      <div v-if="showAddNewLeadModal && showAllSitesModal && showSiteInformationModal" class="modal-content">
        <section class="lead-modal-top">
            <section style="display: flex">
              <span  class="lead-modal-top-num">
                1
              </span>
              <span class ="lead-modal-top-name">
                <!-- Account information -->
                {{ loadStaticData("LANG-0024") }}
              </span>
            </section>
            <span class="lead-modal-top-line"></span>
            <section style="display: flex">
              <span class="lead-modal-top-num">
                2
              </span>
              <span class ="lead-modal-top-name">
                <!-- Site information -->
                {{ loadStaticData("LANG-0023") }}
              </span>
            </section>
          </section>
        <section class="lead-dropdowm-site-cover" @click="closeSiteDetails(chosenSiteIndex)">
            <div class="lead-dropdowm-site-num">
              {{ siteDetailsEmpty[chosenSiteIndex].siteName ? siteDetailsEmpty[chosenSiteIndex].siteName : 'Site -' + (chosenSiteIndex+1) +' details'}}
            </div>
            <div class="lead-dropdowm-site-icon">
              <i style="color: #A6A6A6;"  class="icon-angle-up-filled"></i>
            </div>
          </section>
          <!-- Add new lead modal window content -->
          <section class="lead-modal-content">
          <!-- <span class="left-section" style="display:none">
            <div>
              <hlx-input
                   v-model:value="siteDetails[chosenSiteIndex].name"
                  :pre-val="siteDetails[chosenSiteIndex].name"
                  :label-value="'Site name'"
                  :label-animation="true"
                  :required="true"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
            </div>
            <div style="display:flex">
              <div style="width:94%">
              <hlx-input
                  v-model:value = "siteDetails[chosenSiteIndex].networkId"
                  :pre-val="siteDetails[chosenSiteIndex].networkId"
                  :label-value="'Network id'"
                  :label-animation="true"
                  :required="true"
                  type="number"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
              </div>
            <div style="margin: auto;padding-bottom: 24px;">
                <i style="color: #A6A6A6;"  class="icon-info-circle-regular"></i>
              </div>
          </div>
          <div style="display:flex">
              <div style="width:94%">
              <hlx-input
                  v-model:value = "siteDetails[chosenSiteIndex].sdfcId"
                  :pre-val="siteDetails[chosenSiteIndex]?.sdfcId"
                  :label-value="'SDFC site id'"
                  :label-animation="true"
                  :required="true"
                  type="number"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
              </div>
            <div style="margin: auto;padding-bottom: 24px;">
                <i style="color: #A6A6A6;"  class="icon-info-circle-regular"></i>
              </div>
          </div>
          <div style="display:flex">
              <div style="width:94%">
              <hlx-input
                  v-model:value = "siteDetails[chosenSiteIndex].numberOfUnits"
                  :pre-val="siteDetails[chosenSiteIndex]?.numberOfUnits"
                  :label-value="'Number of units'"
                  :label-animation="true"
                  :required="true"
                  type="number"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
              </div>
            <div style="margin: auto;padding-bottom: 24px;">
                <i style="color: #A6A6A6;"  class="icon-info-circle-regular"></i>
              </div>
          </div>
          <div>
            <hlx-select
            :key="componentKey"
              :inline-search="true"
              :options="leadMarketSegmentOptions"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Market segment'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :display-error="true"
              :clearable="true"
              @selected-value="getLeadMarketSegment"
            ></hlx-select>
          </div>
        <div>
            <hlx-input
                  :label-animation="true"
                  :single-border="false"
                  :label-value="'Onboarding date'"
                  :date-format="'dd (short month), yyyy'"
                  type="date"
                  :clearable="false"
                  icon="calendar-regular"
                  icon-position="right"
                      required="true"
                  :disable-weekend="false"
                  :start-year="2000"
                  :display-error="true"
                 :custom-error="startDateBoolean"
                  :custom-error-message="startDateMsg"
                  :end-year="9999"
                  @iso-date="getLeadOnboardingDate"
                    @at-input="getLeadOnboardingDate"
                    @focus-out="focusOutStartDate"
                />
              </div>
        </span>
        <span class="right-section" style="display:none">
          <div class="right-section-address" style="margin-bottom: 20px">
              <addressComponent
                      :new-coordinate="{ lat: '23', lng: '72' }"
                      :pre-val="characteristicpreval"
                      @address-data="receivedAddressData"
                      @geopoint="geopoint"
              ></addressComponent>
            </div>
            <div style="display:none;gap:12px">
              <div style="width:50%">
              <hlx-input
                   v-model:value="siteDetails[chosenSiteIndex].contactMedium[0].characteristic.latitude"
                  :pre-val="siteDetails[chosenSiteIndex].contactMedium[0].characteristic.latitude"
                  :label-value="'Latitude'"
                  :label-animation="true"
                  :required="true"
                  type="number"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="getLatitudeValue"
                  @at-input="validateName"
                ></hlx-input>
              </div>
              <div style="width:50%">
              <hlx-input
                    v-model:value="siteDetails[chosenSiteIndex].contactMedium[0].characteristic.longitude"
                  :pre-val="siteDetails[chosenSiteIndex]?.contactMedium[0]?.characteristic?.longitude"
                  :label-value="'Longitude'"
                  :label-animation="true"
                  :required="true"
                  type="number"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
              </div>
            </div>
            <div>
          <hlx-select
          :key="componentKey"
          :pre-value="siteDetails[chosenSiteIndex]?.tags?.name"
              :inline-search="true"
              :options="specdata"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Tags'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :display-error="true"
              :clearable="true"
              @selected-value="setSiteTags"
            ></hlx-select>
          </div>
        </span> -->
        <!-- :updated-schema-after-remap = "updatedSiteSchemaForRemap" -->
        <div class="parent-container-for-lead-maintable-site">
        <fieldRenderer
              :key="updateFieldRenderKey"
              :layout="'modal'"
              :schema-payload = "siteSchema"
              :data-payload = "siteDetailsPayload"
              :is-add-clicked = "isAddButtonClicked"
              :updated-schema-after-remap = "updatedSiteSchemaForRemap"
              :is-save-clicked = "isSaveButtonClicked"
              :is-modal-window = "modalPropsForFieldRender"
              :status-list="[
                'Open',
                // 'In Discussion',
                'Qualified',
                'Unqualified',
                'Awaiting network approval',
                'Network approved',
                'Network rejected',
                'Converted',
                'Closed Lost',
              ]"
              :parent-class-name="'parent-container-for-lead-maintable-site'"
              @edited-payload="getSiteDetails"
              @lookup-data="getExistedLeadData"
              @updated-schema="getSiteUpdatedSchema"
              ></fieldRenderer>
      </div>
      </section>


      </div>

    </template>
    <template #footer>
      <hlx-button v-if="showAddNewLeadModal && !showAllSitesModal && !showSiteInformationModal" class="secondary sm" @click="closeAddNewLeadModal"
        >
        <!-- Cancel -->
        {{ loadStaticData("LANG-0020") }}
        </hlx-button
      >
       <!-- Add button modal window-->
      <hlx-button
        v-if="showAddNewLeadModal && !showAllSitesModal && !showSiteInformationModal"
        :disabled="!isLeadDetailsFilled"
        class="primary sm"
        style="margin-left: 10px"
        @click="showSitesDropDown"
        >
        <!-- Next -->
        {{ loadStaticData("LANG-0021") }}
      </hlx-button>
         <!-- {{ "showAllSitesModal" + showAllSitesModal }}
         {{"showSiteInformationModal" + showSiteInformationModal}}
         {{ "showAddNewLeadModal" + showAddNewLeadModal }} -->
      <hlx-button
        v-if="showAddNewLeadModal && (showAllSitesModal || showSiteInformationModal)"
        class="secondary sm"
        style="margin-left: 10px"
        @click="goToLeadDetails"
        >
        <!-- Back -->
        {{ loadStaticData("LANG-0019") }}
      </hlx-button>

      <hlx-button
      v-if="showAddNewLeadModal && (showAllSitesModal || showSiteInformationModal)"
      :disabled="!isAllSitesDetailsFilled || disableAddButton"
      class="primary sm"
        style="margin-left: 10px"
        @click="getSiteDataAfterSiteButtonClicked"
        >
        <!-- Add button post new lead -->
        {{ loadStaticData("LANG-0010") }}
      </hlx-button>

    </template>
  </hlx-modal>



  
  <!-- Lead main table view -->

  <section class="lead">

    <section class="lead-table-cover">
      <section class="lead-header">
        <!-- Lead -->
        {{ loadStaticData("LANG-0001") }}
        <span class="lead-sub-header">
          {{ loadStaticData("LANG-0002") }}
          <!-- Potential customers interested in your products or services. -->
        </span>
      </section>
      <section v-if="leadOrgData.length > 0" class="lead-add-search">
        <span>
          <hlx-search :label-value="loadStaticData('LANG-0003')" @search-key="searchKey"></hlx-search>
        </span>
        <span>
          <hlx-button class="secondary sm add-btn" @click="openLeadFilter">
            <i class="icon-filter-regular"></i>
            <span v-if="filterTagLength != 0" class="filter-count">{{
                filterTagLength
              }}</span>
          </hlx-button>
        </span>
        <span>
          <hlx-button v-if="permisstionData.add " class="secondary sm add-btn" @click="openImport">
              <i class="icon-download-regular"></i>
            <!-- Import -->
            {{ loadStaticData("LANG-0004") }}   
          </hlx-button>
        </span>
        <span>
          <!-- Add button -->
          <hlx-button  v-if="permisstionData.add " class="primary sm" @click="OpenAddLeadModal">
            <i class="icon-plus-circle-regular"></i>
             <!-- Add lead -->
             {{ loadStaticData("LANG-0005") }}
          </hlx-button>
        </span>
      </section>

      <!-- Table  -->
      <section v-if="tableData.length > 0" class="lead-table" style="margin-bottom:4rem">
        <hlx-table
           :label-value="tableModalLang"
            :column-count="leadTableheaders.length + 2"
            :border="['table', 'header', 'horizontal']"
            :row-hover="true"
            theme="grey"
            :re-arrange-columns="toggleRearrangeModal"
            :re-arrange-heads="leadTableheadersCopy"
            @close-rearrange="toggleRearrangeModal = false"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
                <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
             @new-resize-width="resizedWidth"
            >
            <!-- {{ "S.no" }} -->
            {{ loadStaticData('LANG-0046') }}
          </hlx-table-head>
            
              <hlx-table-head
                v-for="(i, index) in leadTableheaders"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
                 @new-resize-width="resizedWidth"
              >
                {{ i.label }}</hlx-table-head
              >
              <hlx-table-head
               v-if="permisstionData.edit || permisstionData.delete"
                :align="'center'"
                :width="70"
                :fixed="'right'"
                 @new-resize-width="resizedWidth"
                ><span class="action-icon">
                  <i
                  style="cursor: pointer"
                  class="icon-settings-regular"
                @click="reAssignOrgTableHeader()"
                  ></i> </span
              ></hlx-table-head>
            </template>

            <!-- table with data -->
            <template v-if="tableData?.length>0" #tbody>
              
            <tr v-for="(i, index) in paginatedData" id="" :key="index">
                <hlx-table-cell :align="'center'" :fixed="'left'">
                            {{ serialNumber(index) }}
                </hlx-table-cell>
                <hlx-table-cell
                  v-for="(j, col_index) in leadTableheaders"
                  :key="col_index"
                  :align="j.align"
                  :fixed="j.fixed"
                >
                <div
v-if="j.prop.toLowerCase() === 'displayid'" @click="
                      $router.push({path:
                        '/MDU/lead/view/'+
                        i['name'] +
                          '/' +
                          i['_id']
                      }
                      )
                    ">
                    <span class="link-name">
                      {{ i["displayID"] }}
                    </span>
                  </div>
                  <div
                    v-else-if="j.prop.toLowerCase() === 'name'"
                  >
                      {{ i["name"] }}
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'status'">
                      <div v-if="i[j.prop] === 'Unqualified'">
                        <hlx-status :type="'custom'" :point-color="'#999999'" :status-value="'Unqualified'"></hlx-status>
                      </div>
                      <div v-if="i[j.prop] === 'Awaiting network approval'">
                        <hlx-status :type="'custom'" :point-color="'#F9A645'" :status-value="'Awaiting network approval'"></hlx-status>
                      </div>
                      <div v-if="i[j.prop] === 'Converted'">
                        <hlx-status :type="'custom'" :point-color="'#54BD95'" :status-value="'Converted'"></hlx-status>
                      </div>
                      <div v-if="i[j.prop] === 'Network approved'">
                        <hlx-status :type="'custom'" :point-color="'#F9D82C'" :status-value="'Network approved'"></hlx-status>
                      </div>
                      <div v-if="i[j.prop] === 'Network rejected'">
                        <hlx-status :type="'custom'" :point-color="'#F9D82C'" :status-value="'Network rejected'"></hlx-status>
                      </div>
                      <div v-if="i[j.prop] === 'Open'">
                        <hlx-status :type="'custom'" :point-color="'#0065B2'" :status-value="'Open'"></hlx-status>
                      </div>
                      <div v-if="i[j.prop] === 'Closed Lost'">
                        <hlx-status :type="'custom'" :point-color="'#FC3232'" :status-value="'Closed Lost'"></hlx-status>
                      </div>
                      <div v-if="i[j.prop] === 'Qualified'">
                        <hlx-status :type="'custom'" :point-color="'#FEDC2A'" :status-value="'Qualified'"></hlx-status>
                      </div>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'sites'">
                    <span>
                    {{  getNumberOfSiteValueForTable(i.externalReference,"sites") }}
                  </span>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'dwellingtype'">
                      <span>
                        {{ i["dwellingtype"]}}
                      </span>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'createdat'">
                      <span>
                        {{ dateAndTimeFormatter(i["createdAt"])}}
                      </span>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'lastupdated'">
                      <span>
                        {{ dateAndTimeFormatter(i["lastUpdate"])}}
                      </span>
                  </div>
                  <div v-else>{{ i[j.prop] }}</div>
                </hlx-table-cell>
                <hlx-table-cell
                 v-if="permisstionData.edit || permisstionData.delete"
                  :align="'center'"
                  :width="120"
                  :fixed="'right'"
                >
                  <span :id="'table-context' + serialNumber(index - 1)" class="action-icon">
                    <i
                      style="position: relative"
                      class="icon-more-vertical-filled"
                      :class="i.context === true ? 'active-action' : ''"
                      @click="crudContextMenu($event,  serialNumber(index - 1) )"
                    >
                      <hlx-context-menu
                        :top="containertop"
                        :display="'display'"
                        :left="containerleft - 20"
                        :conditionvalue="150"
                        :conditiondown="2"
                        :conditionup="-38"
                        :options="computedTableAction(i)"
                        :data="index.toString()"
                        :show="i.context == true"
                        @chosen="closeCrudContextMenu($event, i, serialNumber(index - 1))"
                      />
                    </i>
                  </span>
                </hlx-table-cell>
              </tr>
            </template>
          </hlx-table>
      </section>

<!-- Table for no data -->
<section v-if="( tableData.length <= 0 && searchvalue !== '')" class="lead-table">
        <hlx-table
            :column-count="leadTableheaders.length + 2"
            :border="['table', 'header']"
            :row-hover="true"
            theme="grey"
            :re-arrange-columns="toggleRearrangeModal"
            :re-arrange-heads="leadTableheadersCopy"
            @close-rearrange="toggleRearrangeModal = false"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
                <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
             @new-resize-width="resizedWidth"
            >
            {{ loadStaticData('LANG-0046') }}
            <!-- {{ "S.no" }} -->
          </hlx-table-head>
            
              <hlx-table-head
                v-for="(i, index) in leadTableheaders"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
                 @new-resize-width="resizedWidth"
              >
                {{ i.label }}</hlx-table-head
              >
              <hlx-table-head
                :align="'center'"
                :width="70"
                :fixed="'right'"
                 @new-resize-width="resizedWidth"
                ><span class="action-icon">
                  <i
                  style="cursor: pointer"
                  class="icon-settings-regular"
                @click="reAssignOrgTableHeader()"
                  ></i> </span
              ></hlx-table-head>
            </template>

                        
            <!-- table without data )-->
            <template  #tbody>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell :colspan="leadTableheaders.length + 2">{{
                        "No data"
                      }}</hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
        </template>

          </hlx-table>
      </section>
      

    </section>
    
    <!-- table data image - illustration -->
    <section
      v-if="tableData.length <= 0 && searchvalue == ''"
      style="
        margin-top: 3.2rem;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        align-items: center;
      "
    >
      <img
        src="@/assets/images/leadLandingTable.svg"
        alt=""
        style="width: 600px"
      />

      <span>
          <!-- Add button -->
          <hlx-button  v-if="permisstionData.add " class="primary sm" @click="OpenAddLeadModal">
            <i class="icon-plus-circle-regular"></i> 
            <!-- Add 
            lead -->
            <!-- Add lead -->          
            {{ loadStaticData("LANG-0005") }}          

          </hlx-button>
        </span>
    </section>
  </section>



        <!-- Pagination -->
        <div
         v-if="tableData.length > 0"
        class="lead-contact-pagination-container"
        :style="{ padding: '1rem 0',width:'100%'}"
      >
      <!-- :total="tableData.length" -->
        <hlx-pagination
        v-model:rows-per-page="externalRowsPerPage"
          :label-value=paginationLang
          :total="totalPageNo"
          enable-rows-per-page
          :rows-per-page-list="[10, 15, 20]"
          :options="{
            attributes: {
              rowsPerPageTitle: true,
              rowsPerPageResult: true,
              rowsPerPageDropdown: {
                position: {
                  top: 0,
                  right: 1,
                },
              },
            },
          }"
          @current-page="currentPage"
          @updated:rows-per-page="changeRowsPerPage"
        ></hlx-pagination>
      </div>

<div class="to-style-modal">
  <hlx-modal
        :modal-active="importDataModal"
        :height="'335px'"
        :width="'450px'"
        :modal="true"
        @close="importDataModal = false"
      >
        <template #header>
          <div>Import</div>
        </template>
        <template #content>
          <div class="modal-content">

            <div class="new-layout-header" style="display: flex; justify-content: space-between; margin-bottom: 16px">
        <span class="schema-header-title" style="font-size:14px; margin-bottom: 8px; width: 30%; margin-right: 16px">Choose template</span>
        <span class="subtitle" style="width:70%"> <hlx-select
                    v-model:value="template"
                    :inline-search="true"
                    :pre-value="template"
                    :label-animation="false"
                    :placeholder-value="'Template'"
                    :options="templateList"
                    :label="'value'"
                    :prop-value="'value'"
                    @selected-value="getTemplate($event)"
                  >
                  </hlx-select></span>
      </div>
            <!-- <hlx-file-upload
              :file-type="'.xlsx,.csv,.json'"
              :file-size-limit="'20mb'"
              :custom-icon="icon"
              :type="'regular'"
              :content="'Import'"
              @file-data="abc"
            /> -->
            <!-- <hlx-file-upload
              :file-type="'.jpg,.jpeg,.png,.json,.xlsx,.doc,.pdf,.txt'"
              :file-size-limit="'1gb'"
              :custom-icon="icon"
              :type="'regular'"
              :style-background="backgrounStyleupload"
              :icon="'icon-arrow-down-to-line-filled'"
              :content="'Import'"
              @file-data="imgDataPrimary"
              @delete-image="funcdelete"
              @download-image="func1"
            /> -->
            <!-- <hlx-file-upload
                  :file-type="'.jpg,.jpeg,.png,.json,.xlsx,.doc,.pdf,.txt'"
        :file-size-limit="'1gb'"
        :custom-icon="icon"
        :type="'upc-regular'"
        :icon="'icon-arrow-down-to-line-filled'"
        :content="'Import'"
        @delete-image="func"
        @download-image="func1"
        @file-data="imgDataPrimary"
      /> -->
      <hlx-file-upload
      :key="importDataFileUploadKey"  
      :file-type="'.xlsx, .csv'"
      :file-size-limit="'20mb'"
      :custom-icon="icon"
      :type="'regular'"
        @native-data= "newEmit"
      />
    
      <div v-if="importData.length > 0 || insights.length > 0" class="new-layout-header" >
        <div class="subtitle" style="font-size:14px; margin-bottom: 8px">
          <span>You are importing {{ insights.length + importData.length}} records</span>
          <span v-if="insights.length>0" style="color:#FC3232">, with {{ insights.length}} records having the following discrepancies</span>
          <hlx-table
        :column-count="2"
        :border="['table', 'header', 'horizontal']"
        :bold-headers="false"
        :row-hover="true"
        theme="grey"
        :striped-rows="false"
      >

      <template #thead>
          <hlx-table-head
            v-for="(i, index) in errorTableHeaders"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            :fixed="i.fixed"
            :width="i.width !== undefined ? i.width : ''"
            :align="i.align !== undefined ? i.align : ''"
          >
            {{ i.label }}</hlx-table-head
          >

          <hlx-table-head :align="'center'" :width="70"
            ><span></span>
          </hlx-table-head>
        </template>

        <template #tbody>
          <tr v-for="(i, index) in errorTableData" id="" :key="index">
            <hlx-table-cell v-for="(j, col_index) in errorTableHeaders"
              :key="col_index"
              :align="j.align"
              :fixed="j.fixed">
            <div v-if="j?.prop == 'errorPosition'">
                <span>{{ i.errorPosition }}</span>
              </div>
            <div v-else-if="j?.prop == 'error'">
                <span>{{ i.error }}</span>
              </div>
            </hlx-table-cell>
          </tr>
          </template>


      </hlx-table>
        </div>
     
      </div>
          </div>
        </template>
        <template #footer>
          <hlx-button
            class="secondary-success sm"
            @click="importDataModal = false"
            >
            <!-- Cancel -->
             {{ loadStaticData('LANG-0020') }}
            </hlx-button
          >
          <hlx-button
            class="primary sm deletedialogok"
            @click="importdatamodal1"
            >Import</hlx-button
          >
        </template>
      </hlx-modal>
    </div>
</template>

<script>
// import addressComponent from './leadAddressComponent.vue'
import fieldRenderer from "@/components/FieldRenderer.vue";
import {
  DMNAxiosInstance,
  UPCAxiosInstance,
  MLTAxiosInstance,
} from "@/config/axiosConfig";
import FilterComponent from "@/components/filterPanelComponent.vue";

export default {
  components: {
    fieldRenderer,
    FilterComponent,

  },
    //vue add data
    data(){
      return { 
        currentSiteObject:null,
        contactSchemaWithValue:null,
        siteSchemaWithValue:null,
        updatedContactSchemaForRemap:null,
        updatedSiteSchema:null,
        updatedLeadSchema:null,
        totalSites:null,
        exitedLeadContactDetails:null,
        reassignSiteDetailsPayload:{
          siteName:"",
          networkId:"",
          createdAt:'',
          networkManager:'',
          lastUpdate:'',
          sfdcId:"",
          numberOfUnits:"",
          tags:[],
          firstOccupancyDate:"",
          onboardingDate:'',
          marketSegment:{},
          siteAddress:[
          {
            mediumType: "postalAddress",
            characteristic: {
              "@type": "",
          street: "",
          city: "",
          country: "",
          default: false,
          description: "",
          postalCode: "",
          state: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          unit: "",
              latitude:"",
              longitude:"",
            },
          },
          ]
        },
        reassignLeadContactDetails:{
          '@type':'individual',
            title:"",
            createdAt:'',
            lastUpdate:'',
            firstName:"",
            lastName:"",
            contactType:"",
            preferredLanguage:"",
            contactMedium:[
            {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
            },
              {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
            {
            mediumType: "telephone",
            characteristic: {
              telephoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
            ]
          },
        reassingnLeadPayloadEmpty: {
      name: "",
      type:"",
        description:"",
        dwellingtype:"",
        externalReference:[
          {
            type:'websiteUrl',
             value:""
          },
          {
            type:'numberOfSites',
             value:1
          },
          {
            'type':'leadSource',
            value:""
          }
        ],
        category:{},
        status: "Open",
        relatedParty: [
          {
            '@type':'leadOwner',
             value:""
          },
        ],
       },
        temporaryleadSiteDetails:[{
          siteName:"",
          networkId:"",
          createdAt:'',
          networkManager:'',
          lastUpdate:'',
          sfdcId:"",
          numberOfUnits:"",
          tags:[],
          firstOccupancyDate:"",
          onboardingDate:'',
          marketSegment:{},
          siteAddress:[
          {
            mediumType: "postalAddress",
            characteristic: {
              "@type": "",
          street: "",
          city: "",
          country: "",
          default: false,
          description: "",
          postalCode: "",
          state: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          unit: "",
              latitude:"",
              longitude:"",
            },
          },
          ]
        }],
        temporaryleadPayload:{
      name: "",
      type:"",
        description:"",
        dwellingtype:"",
        externalReference:[
          {
            type:'websiteUrl',
             value:""
          },
          {
            type:'numberOfSites',
             value:1
          },
          {
            'type':'leadSource',
            value:""
          }
        ],
        category:{},
        status: "Open",
        relatedParty: [
          {
            '@type':'leadOwner',
             value:""
          },
        ],
       },
        temporaryleadContactPayload:{
          '@type':'individual',
            title:"",
            createdAt:'',
            lastUpdate:'',
            firstName:"",
            lastName:"",
            contactType:"",
            preferredLanguage:"",
            contactMedium:[
            {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
            },
              {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
            {
            mediumType: "telephone",
            characteristic: {
              telephoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
            ]
          },
        filterTagLength:0,
        totalPageNo:0,
        filtertagvalue: [],
        filterQuery: {
        type: "filter",
        module: "saleslead",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          lastUpdate:-1
        },
        limit: 10,
        filterQuery: {
        },
        searchQuery: "",
        company: "",
      },
        query: {
        "Status": ["status"],
        "Dwelling type":["dwellingtype"]
      },
          show_filter:false,
        insights:[],
        errorTableData:[],
        templateList:[],
        templateId:"",
          loginUserLanguage:null,
          companyName:"",
          disableAddButton:false,
          searchvalue:"",
          currPage: 1,
          rowsPerPage: 10,
        siteDetailsPayload:{
          siteName:"",
          networkId:"",
          networkManager:'',
          createdAt:'',
          lastUpdate:'',
          sfdcId:"",
          numberOfUnits:"",
          tags:[],
          firstOccupancyDate:"",
          onboardingDate:'',
          marketSegment:{},
          siteAddress:[
          {
            mediumType: "postalAddress",
            characteristic: {
              "@type": "",
          street: "",
          city: "",
          country: "",
          default: false,
          description: "",
          postalCode: "",
          state: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          unit: "",
              latitude:"",
              longitude:"",
            },
          },
          ]
        },
          isSaveButtonClickedForContact:false,
          isSaveButtonClicked:false,
          isSaveButtonClickedForLead:false,
          dupSiteDetailsForMapping:[],
          siteDetailsEmpty:[{
          siteName:"",
          networkId:"",
          networkManager:'',
          createdAt:'',
          lastUpdate:'',
          sfdcId:"",
          numberOfUnits:"",
          tags:[],
          firstOccupancyDate:"",
          onboardingDate:'',
          marketSegment:{},
          siteAddress:[
          {
            mediumType: "postalAddress",
            characteristic: {
              "@type": "",
          street: "",
          city: "",
          country: "",
          default: false,
          description: "",
          postalCode: "",
          state: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          unit: "",
              latitude:"",
              longitude:"",
            },
          },
          ]
        }],
          modalPropsForFieldRender:true,
          customFieldLeadSchema:{},
          leadSchema:null,
          contactSchema:null,
          filteredSite:null,
          profileUploadComponent:0,
          importDataFileUploadKey:0,
          permisstionData:{},
          notification: [],
          importData:[],
          importDataModal: false,
          leadContactDetails:{
          '@type':'individual',
            title:"",
            createdAt:'',
            lastUpdate:'',
            firstName:"",
            lastName:"",
            contactType:"",
            preferredLanguage:"",
            contactMedium:[
            {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
            },
              {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
            {
            mediumType: "telephone",
            characteristic: {
              telephoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
            ]
          },
          leadContactDetailsEmpty:{
            title:"",
            createdAt:'',
            lastUpdate:'',
            firstName:"",
            lastName:"",
            contactType:"",
            preferredLanguage:"",
            contactMedium:[
            {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
            },
              {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
            {
            mediumType: "telephone",
            characteristic: {
              telephoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
            ]
          },
          tableData: [],
          leadOrgData:[],
          modalKey:0,
          titleData:[{name:'Mr'},{name:"Mrs"}],
          isUrlEmpty:false,
          siteDetails:[],
          chosenSiteIndex:"",
          componentKey:0,
          statusList:[],
          filteredstatus: [],
          newCoordinate:{},
          characteristicpreval: {
        characteristic: {
          "@type": "",
          street: "",
          city: "",
          country: "",
          default: false,
          description: "",
          postalCode: "",
          state: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          unit: "",
          latitude:"",
          longitude:"",
        },
      },
    leadContactType:[],
    leadDealCategoryOptions:[],
    leadOwnerOptions:[],
    leadMarketSegmentOptions:[],
    leadCategoryOptions:[],
    leadSourceOptions:[],  
    leadTypeOptions:[],
    dwellingTypeOptions:[],
    leadPayloadEmpty: {
      name: "",
      type:"",
        description:"",
        dwellingtype:"",
        externalReference:[
          {
            type:'websiteUrl',
             value:""
          },
          {
            type:'numberOfSites',
             value:1
          },
          {
            'type':'leadSource',
            value:""
          }
        ],
        category:{},
        status: "Open",
        relatedParty: [
          {
            '@type':'leadOwner',
             value:""
          },
        ],
       },
    leadPayload:{
      name: "",
        description:"",
        dwellingtype:"",
        externalReference:[
          {
            type:'websiteUrl',
             value:""
          },
          {
            type:'numberOfsites',
             value:1
          },
          {
            'type':'leadSource',
            value:""
          },
        ],
        category:{},
        status: "Open",
        relatedParty: [
          {
            '@type':'leadOwner',
             value:""
          },
        ],
        // contactMedium: [
        //   {
        //     preferred: true,
        //     mediumType: "email",
        //     characteristic: {
        //       emailAddress: "",
        //     },
        //   },
        //   {
        //     mediumType: "phone",
        //     characteristic: {
        //       phoneNumber: {
        //         country: {
        //           name: this.$store.getters.defaultSetting?.country,
        //           short: this.$store.getters.defaultSetting?.cca2,
        //           flags: this.$store.getters.defaultSetting?.flags?.svg,
        //         },
        //         value: "",
        //         valid: true,
        //       },
        //     },
        //   },
        //   {
        //     mediumType: "fax",
        //     characteristic: {
        //       faxNumber: "",
        //     },
        //   },
        //   {
        //     mediumType: "postalAddress",
        //     characteristic: {
        //       city: "",
        //       country: "",
        //       postCode: "",
        //       stateOrProvince: "",
        //       street1: "",
        //       street2: "",
        //       description: "",
        //       address1: "",
        //       default: true,
        //       "@type": "",
        //     },
        //   },
        // ],
       },
        showSiteInformationModal:false,
        showAllSitesModal:false,
    showAddNewLeadModal:false,
    errorTableHeaders: [
        {
          name: "Error position",
          checked: true,
          id: 1,
          disabled: true,
          prop: "errorPosition",
          label: "Error position",
          sortable: false,
          resizable: false,
          width: 100,
          align: "left",
        },
        {
          name: "Error",
          checked: true,
          id: 2,
          disabled: true,
          prop: "error",
          label: "Error",
          sortable: false,
          resizable: false,
          width: 300,
          align: "left",
        }],
    leadTableheaders:[
        {
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: 100,
          label: this.loadStaticData('LANG-0039') || "ID",
          name:"ID",
          prop: "displayID",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: null,
          name:"Name",
          label: this.loadStaticData('LANG-0040') || "Company name",
          prop: "name",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 180,
          disabled:true,
          name:"type",
          label: this.loadStaticData('LANG-0041') || "Dwelling Type",
          prop: "dwellingtype",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 160,
          name:"Status",
          label: this.loadStaticData('LANG-0042') || "Status",
          prop: "status",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 180,
          name:"sites",
          label: this.loadStaticData('LANG-0043') || "Number of sites",
          prop: "sites",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 180,
          name:"Created at",
          label: this.loadStaticData('LANG-0044') || "Created at",
          prop: "createdat",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 180,
          name:"Last updated",
          label: this.loadStaticData('LANG-0045') || "Last updated",
          prop: "lastUpdated",
          align: "left",
        },
    ],
    leadTableheadersCopy:[
        {
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: 100,
          label: "ID",
          name:"ID",
          prop: "displayID",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: 250,
          name:"Name",
          label: "Company name",
          prop: "name",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: null,
          disabled:true,
          name:"type",
          label: "Dwelling Type",
          prop: "type",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 200,
          name:"Status",
          label: "Status",
          prop: "status",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 200,
          name:"sites",
          label: "Number of sites",
          prop: "sites",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 170,
          name:"Created at",
          label: "Created at",
          prop: "createdat",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 170,
          name:"Last updated",
          label: "Last updated",
          prop: "lastUpdated",
          align: "left",
        },
            ]
        }
      },
  computed: {
    
    tableModalLang() { 
      return {
          re_arrange_columns : this.loadStaticData('LANG-0026'),
          Cancel:this.loadStaticData('LANG-0020'),
          Apply: this.loadStaticData('LANG-0027'),
          SelectAll: this.loadStaticData('LANG-0028')
        }
    }
    ,    
    paginationLang() {     
      return {
          Rows_per_page : this.loadStaticData("LANG-0012"),
          Showing: this.loadStaticData("LANG-0013"),
          of: this.loadStaticData("LANG-0014"),
          results: this.loadStaticData("LANG-0015"),
          Go_to: this.loadStaticData("LANG-0016")
        }
      
    },

        isLeadDetailsFilled(){
            return this.temporaryleadPayload.name && 
            this.temporaryleadPayload?.externalReference[1].value>0 &&
             this.temporaryleadPayload?.relatedParty[0]?.value 
            // && this.temporaryleadPayload?.relatedParty[1]?.value
            && this.temporaryleadContactPayload.firstName && 
            this.temporaryleadContactPayload.lastName && 
            this.temporaryleadContactPayload.contactMedium[0].characteristic.emailAddress
            // && this.leadPayloadEmpty.leadSource.name && this.leadPayloadEmpty.leadType.name &&
            //        this.leadPayloadEmpty.dwellingtype && this.leadPayloadEmpty.category.name && this.leadPayloadEmpty.marketSegment.name
            //        && this.leadPayloadEmpty.onboardingDate && 
          },
          isAllSitesDetailsFilled() {
    console.log(this.siteDetails.length, "isAllSitesDetailsFilled", this.siteDetails);

    // Retrieve the numberOfSites value
    const numberOfSitesValue = this.temporaryleadPayload.externalReference.find(
        (item) => item.type.toLowerCase() === "numberofsites"
    )?.value;

    // If there are no site details or the count doesn't match numberOfSitesValue, return false
    if (
        this.siteDetailsEmpty.length < 0 ||
        this.siteDetailsEmpty.length !== numberOfSitesValue
    ) {
        console.log(this.siteDetailsEmpty.length,"Site details missing or count mismatch",numberOfSitesValue);
        return false;
    }
console.log("this.temporaryleadSiteDetails",this.temporaryleadSiteDetails)
    // Check if every site object is filled
    const areAllSitesFilled = this.temporaryleadSiteDetails.every((site) => {
        const siteUnits = site.numberOfUnits ? Number(site.numberOfUnits) : null;

        const isAddressFilled =
            site.siteAddress &&
            site.siteAddress[0]?.characteristic &&
            site.siteAddress[0].characteristic.street !== "" &&
            site.siteAddress[0].characteristic.city !== "" &&
            site.siteAddress[0].characteristic.postalCode !== "";

        const isSiteNameFilled = site.siteName && site.siteName.trim() !== "";
        const isUnitsValid = siteUnits !== null && siteUnits >= 1;

        return isAddressFilled && isSiteNameFilled && isUnitsValid;
    });

    console.log("Are all sites filled:", areAllSitesFilled);
    return areAllSitesFilled;
},
      paginatedData() {
      // const start = (this.currPage - 1) * this.rowsPerPage;
      // const end = start + this.rowsPerPage;
      // console.log(this.tableData?.length,"paginatedTableData",this.tableData.slice(start, end))
      // return this.tableData.slice(start, end);
      return this.tableData;
    },

      },
    watch:{
      statusList: {
      handler(val) {
        if(val){
        const filteredstatuslist = this.statusList.filter(
          (item) =>
            item.name === "Open" || item.name === 'Network approved' || item.name === 'Network rejected' ||
            // item.name === "In Discussion" ||
            item.name === "Qualified" || item.name === "Unqualified" || item.name === "Awaiting network approval" ||
            item.name === "Converted" ||
            item.name === "Closed Lost"
        );
        this.filteredstatus = filteredstatuslist;
        console.log("filteredstatuslist", this.filteredstatus);
        }
      },
      immediate: true,
      deep: true,
    },
    },
   async mounted(){
    await this.$store.dispatch("leadMainFilterData");
    this.filter = this.$store.getters.leadMainFilterData;
    console.log("leadMainFilterData",this.filter)
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");

this.companyName = await val.data.company;
let sampleTheadsd = await MLTAxiosInstance.get(
  `partyRole/get/${val.data.userId}`
);

const mergedPayload = sampleTheadsd.data.permission;
console.log(
  mergedPayload["Multi dwelling unit"].child.Lead,
  "mergedPayload",mergedPayload
);
this.permisstionData =
mergedPayload["Multi dwelling unit"].child.Lead;

  //  Get all leads data - table data
  try {
  // const response = await UPCAxiosInstance.get(`/salesLead`);
  const filterData = {
      company: this.companyName,
      type: "filter",
      module: "saleslead",
      isPaginated: true,
      // lastUpdated: new Date().toJSON(),
      paginatedQuery: {
        rowsPerPage: this.rowsPerPage,
        currentPage: this.currPage,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: { lastUpdate: -1 },
      limit: 10,
      filterQuery: {},
      searchQuery: "",
    };

    const response = await UPCAxiosInstance.post(
      `util/filterData?company=${this.companyName}`,
      filterData
    );
    this.tableData = await response.data.data;
    this.totalPageNo = await response.data.total;
  // this.tableData = response?.data || [];
  // this.tableData = this.tableData?.filter(ele=>ele?.externalReference?.length>0 || ele.numberOfUnits)
  this.leadOrgData = JSON.parse(JSON.stringify(this.tableData))
  console.log(response,"this.tableData",this.tableData)
  } catch (error) {
    console.log("Error fetching salesLead data:", error);
    this.tableData = [];
  }

      // To get lead schema for custom field
      const schema = await UPCAxiosInstance.get(
      `/customSchema/getSchema?module=${"saleslead"}`
    );

    console.log(schema, "schema");
    this.customFieldLeadSchema = schema?.data?.schema;
  this.filteredCustomFieldSchema()
  // this.loadGoogleMapsScript().then(() => {
  //      // Initialize Google Maps components here
  //      const autocompleteService = new window.google.maps.places.AutocompleteService();
  //      console.log('AutocompleteService Loaded from autoaddress:', autocompleteService);
  //      this.isLeadAddressMounted = true
  //    });

     let settings = await UPCAxiosInstance.get("/importTemplate");
     this.templateList = settings.data.filter(
      (i) => i.module.type == "saleslead"
    );
    this.templateList.map((i) => {i.value = i.module.name; i.name = i.value})
      // To get dropdowm options from refernce data
  //     this.leadDealCategoryOptions = await this.getReferanceData('LeadDealCategory')
  //  this.leadOwnerOptions =  await this.getReferanceData('LeadOwner')
  //  this.leadMarketSegmentOptions = await this.getReferanceData('LeadMarketSegment')
  //  this.leadCategoryOptions = await this.getReferanceData('LeadCategory')
  //  this.leadSourceOptions = await this.getReferanceData('LeadSource')
  //  this.leadTypeOptions = await this.getReferanceData('LeadType')
  //  this.dwellingTypeOptions = await this.getReferanceData('LeadDwellingType')
  //  this.leadContactType =  await this.getReferanceData('LeadContactType')
  //  let statusList = await DMNAxiosInstance.get(`mapRef/collection/statuslist`);
  //  this.statusList = statusList.data.data;


  //     console.log(this.statusList,"leadDealCategoryOptions",this.leadDealCategoryOptions)
    },
    methods:{
      getNumberOfSiteValueForTable(val, show) {
    if (show === "sites") {
        console.log("getNumberOfSiteValueForTable", val);
        const siteData = val.find((ele) => ele.type.toLowerCase() === "numberofsites");
        return siteData ? siteData.value : "-";
    } else if (show === "leadSource") {
        console.log("getNumberOfSiteValueForTable", val);
        const leadSourceData = val.find((ele) => ele.type.toLowerCase() === "leadsource");
        return leadSourceData ? leadSourceData.value : "-";
    }
    return "-";
},
      getContactUpdatedSchema(val){
        this.contactSchemaWithValue = { ...val}
        console.log("contactSchemaWithValue",val)
      },
      getSiteUpdatedSchema(val){
        this.siteSchemaWithValue = { ...val}
        console.log("siteSchemaWithValue",val)
      },
      async getExistedLeadData( [ dataPayload , schemaToBeMapped , schema]){

        if(dataPayload && schemaToBeMapped){
          console.log(schema,dataPayload , "getExistedLeadData" , schemaToBeMapped)

          for(let i=0;i<schemaToBeMapped.length;i++){
   let schemaObjName = schemaToBeMapped[i].LHS 
   let schemaValue =  schemaToBeMapped[i].RHS
   let changeRhsValue = 'payload'
  const firstDotIndex = schemaValue.indexOf('.'); 
console.log(schemaValue,"dataPayloaddataPayload",dataPayload)
  if (firstDotIndex !== -1) {
    let newRHS = changeRhsValue + schemaValue.slice(firstDotIndex);
    console.log(schemaObjName, "newRHS", newRHS);
    newRHS
   let result =  this.evaluateRHS(newRHS,dataPayload)
   console.log("remappped data",result)
   if(schema['name'] != undefined && schema['name'].value != undefined){
   schema['name'].value = dataPayload.name
   }

   if(result == '' && schemaObjName == 'numberOfUnits')
{
        console.log("this.leadSchema[schemaObjName].value 3 ",this.siteSchemaWithValue[schemaObjName])
    this.siteSchemaWithValue[schemaObjName].value = '' 
    this.siteSchemaWithValue.isRemapData = false
    // this.siteSchema.isRemapData = false
  }

   if(result)
   {
    console.log("this.leadSchema[schemaObjName].value",schemaObjName)
    if(schema[schemaObjName])
   {
    console.log("this.leadSchema[schemaObjName].value 1 ",schema[schemaObjName])
    schema[schemaObjName].value = result
    schema.isRemapData = false
   }
   else if(this.contactSchemaWithValue[schemaObjName]){
        console.log("this.leadSchema[schemaObjName].value 2 ",this.contactSchemaWithValue[schemaObjName])
        this.contactSchemaWithValue[schemaObjName].value = result
        this.contactSchemaWithValue.isRemapData = false
   }
   else if(this.siteSchema[schemaObjName]){
        console.log("this.leadSchema[schemaObjName].value 3 ",this.siteSchemaWithValue[schemaObjName])
    this.siteSchemaWithValue[schemaObjName].value = result 
    this.siteSchemaWithValue.isRemapData = false
    // this.siteSchema.isRemapData = false
   }
    // delete schema['name']
    // delete schema[schemaObjName]
    // console.log(schema,"this.schema",this.customFieldLeadSchema)
    // return
  }
}
// this.customFieldLeadSchema[schemaObjName].value = schema[schemaObjName]?.value ? schema[schemaObjName]?.value : this.customFieldLeadSchema[schemaObjName].value 
  // let result = eval(newRHS);
  // Log the result (assuming companyName is defined in the scope)
// console.log(result);
    console.log(schemaObjName,"schemaValue",schemaValue,"schemaObjName",schemaObjName)
  }

  console.log(this.siteSchema,"schemaschema",this.contactSchema,this.leadSchema)
  if(this?.contactSchemaWithValue?.isRemapData == false){
  this.leadContactDetails = JSON.parse(JSON.stringify(this.temporaryleadContactPayload ))
  this.updatedContactSchemaForRemap = JSON.parse(JSON.stringify(this.contactSchemaWithValue))
  }
  //   this.customFieldLeadSchema = { ...this.customFieldLeadSchema, ...schema };
  //     let removeDuplicates = {}
  //     Object.keys(this.customFieldLeadSchema).forEach(key => {
    //   removeDuplicates[key] = this.customFieldLeadSchema[key];
    // });
    // this.customFieldLeadSchema = JSON.parse(JSON.stringify(removeDuplicates))
    
    // this. filteredCustomFieldSchema()
    if(this?.siteSchemaWithValue?.isRemapData == false){
console.log(this.temporaryleadSiteDetails,"this.siteSchemaWithValue",this.siteSchemaWithValue)
    this.updatedSiteSchemaForRemap = JSON.parse(JSON.stringify(this.siteSchemaWithValue))
    this.siteDetailsPayload = JSON.parse(JSON.stringify(this.currentSiteObject))
    }

    if(schema?.isRemapData == false){
    this.leadPayloadEmpty  = JSON.parse(JSON.stringify(this.temporaryleadPayload ))
this.updatedLeadSchema = JSON.parse(JSON.stringify(schema))
    }
    if(this?.contactSchemaWithValue?.isRemapData == false){
this.updatedContactSchemaForRemap = JSON.parse(JSON.stringify(this.contactSchemaWithValue))
console.log(this.leadSchema,"this.updatedContactSchemaForRemap",this.updatedContactSchemaForRemap)
    }
        }

      //   console.log("getExistedLeadData",val)
      //   const duplicatedExitingLeadData = {...val}
      //   let leadSourceAndOwnerDetails = duplicatedExitingLeadData.relatedParty.filter(party => party["@type"] === "leadOwner" || party["@type"] === "leadSource")

      //   let leadContactAndSiteDetails = duplicatedExitingLeadData.relatedParty.filter(party => party["@type"] != "leadOwner" && party["@type"] !== "leadSource" && party["@type"] !== "site")
      //   duplicatedExitingLeadData.relatedParty = leadSourceAndOwnerDetails
      // this.leadPayloadEmpty = {
      // name: duplicatedExitingLeadData?.name || "",
      // type:duplicatedExitingLeadData?.type || "",
      //   description:duplicatedExitingLeadData?.description || "",
      //   dwellingtype:duplicatedExitingLeadData?.dwellingtype || "",
      //   externalReference: duplicatedExitingLeadData?.externalReference || [
      //     {
      //       type:'websiteUrl',
      //        value:""
      //     },
      //     {
      //       type:'numberOfSites',
      //        value:1
      //     }
      //   ],
      //   category:duplicatedExitingLeadData?.category || {},
      //   status: duplicatedExitingLeadData?.status || "Open",
      //   relatedParty:duplicatedExitingLeadData?.relatedParty || [
      //     {
      //       '@type':'leadOwner',
      //        value:""
      //     },
      //     {
      //       '@type':'leadSource',
      //       value:""
      //     }
      //   ],
      //  },
      //   this.exitedLeadContactDetails = (leadContactAndSiteDetails.filter(contact => contact['@type'] == 'individual'))[0] || 
      //  console.log("exitedLeadContactDetails",this.exitedLeadContactDetails)
      //  this.filteredCustomFieldSchema()
      //  this.leadPayloadEmpty
      //   console.log(duplicatedExitingLeadData,"duplicatedExitingLeadData",leadContactAndSiteDetails,"leadSourceAndOwnerDetails",leadSourceAndOwnerDetails,"this.leadPayloadEmpty",this.leadPayloadEmpty)

      },

      evaluateRHS(RHS, dataPayload) {
      this.dataPayloadForRemap = dataPayload
      try {
        const cleanedRHS = RHS.replace(/payload/g, 'dataPayloadForRemap').replace(/\n/g, ' ').trim();
        console.log(this.dataPayloadForRemap,cleanedRHS,"dataPayloadForRemap",dataPayload)
        // Use Function constructor to evaluate the expression safely
        // eslint-disable-next-line no-new-func
        const result = new Function('dataPayloadForRemap', `return ${cleanedRHS};`)(this.dataPayloadForRemap);
        return result;
    } catch (error) {
        console.error('Error evaluating RHS:', error);
        return null;
    }
  },

      async supplierfilterData(filterSelectedvalue, filterQuery) {
      this.filtertagvalue = filterSelectedvalue;
      this.filterTagLength = filterSelectedvalue.length;
      filterQuery.company = this.company;
      //  filterQuery['filterQuery']['"@baseType"'] = 'supplier'
      if (this.filtertagvalue.length != 0) {
        this.filterQuery = filterQuery;
        this.filterQuery.type = "filter";
        if (this.searchvalue != "") {
          this.filterQuery["searchQuery"] = this.searchvalue;
          this.filterQuery["type"] = "filter-search";
        }
        const filterResultData = await UPCAxiosInstance.post(
          `util/filterData`,
          this.filterQuery
        );

        this.tableData = filterResultData.data.data;
        this.totalPageNo = filterResultData.data.total;
      } else {
        this.currPage = 1;
        this.rowsPerPage = 10;
        this.getLeadTableData(this.currPage, this.rowsPerPage);
      }
    },
    async getLeadTableData(currentPage, rowsPerPage) {
      console.log("getLeadTableData",currentPage,rowsPerPage)
      this.filterQuery["company"] = this.companyName;
      this.filterQuery["paginatedQuery"]["currentPage"] = currentPage;
      this.filterQuery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
    //   const filterData = {
    //   company: this.companyName,
    //   type: "filter",
    //   module: "saleslead",
    //   isPaginated: true,
    //   lastUpdated: new Date().toJSON(),
    //   paginatedQuery: {
    //     rowsPerPage: rowsPerPage,
    //     currentPage: currentPage,
    //   },
    //   isNeedToBeSort: true,
    //   sortFieldAndOrder: {},
    //   limit: 10,
    //   filterQuery: {},
    //   searchQuery: "",
    // };

    const response = await UPCAxiosInstance.post(
      `util/filterData?company=${this.companyName}`,
      this.filterQuery
    );
    if(response.data.data !=undefined){
    this.tableData = await response.data.data;
    this.totalPageNo = await response.data.total;
    this.leadOrgData = JSON.parse(JSON.stringify(this.tableData))
    this.paginatedData;
    }
    },
      openLeadFilter(){
        // to show filter drawer
        this.show_filter = true
      },

      getTemplate(val){
        this.templateId = val._id
      },
      loadGoogleMapsScript() {
    // const mapKey = process.env.VUE_APP_MAP_KEY;
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAP_KEY}&libraries=places`;
    script.async = true;
    document.head.appendChild(script);
  
    return new Promise((resolve) => {
      script.onload = () => {
        resolve();
      };
    });
  },
      async closeAddNewLeadModal(){
                      // To get lead schema for custom field
      const schema = await UPCAxiosInstance.get(
      `/customSchema/getSchema?module=${"saleslead"}`
    );

    console.log(schema, "schema");
    this.customFieldLeadSchema = schema?.data?.schema;
    await this.filteredCustomFieldSchema()
        this.showAddNewLeadModal = false     
        this.showSiteInformationModal = false
  this.showAllSitesModal = false
  this.siteDetailsPayload =  JSON.parse(JSON.stringify(this.reassignSiteDetailsPayload)) 
  this.leadContactDetails = { ...this.reassignLeadContactDetails }
  this.leadPayloadEmpty = { ...this.reassingnLeadPayloadEmpty }
  console.log( this.siteDetailsPayload ," this.siteDetailsPayload ",this.reassignSiteDetailsPayload)
      },
      serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
 async changeRowsPerPage(count) {
     this.rowsPerPage = count;
     await this.getLeadTableData(this.currPage, this.rowsPerPage);
 },
 filteredCustomFieldSchema() {
  console.log("this.customFieldLeadSchema", this.customFieldLeadSchema);

  const filteredOverview = Object.entries(this.customFieldLeadSchema).filter(
    ([, value]) => value.section && value.section.includes("overview")
  );

  const filteredContact = Object.entries(this.customFieldLeadSchema).filter(
    ([, value]) => value.section && value.section.includes("contacts")
  );

  const filteredSite = Object.entries(this.customFieldLeadSchema).filter(
    ([, value]) => value.section && value.section.includes("sites")
  );

  const fieldSchemaSite = Object.fromEntries(filteredSite);
  const fieldSchemaLead = Object.fromEntries(filteredOverview);
  const fieldSchemaContact = Object.fromEntries(filteredContact);

   // Convert the object into an array of entries [key, value]
        const entries = Object.entries(fieldSchemaLead);
        const entriesForContact = Object.entries(fieldSchemaContact);
        const entriesForSite = Object.entries(fieldSchemaSite);
        // Sort based on `others.displayOrder` if it exists
        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB; // Sort ascending
        });
        entriesForContact.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB; // Sort ascending
        });
        entriesForSite.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
            // Handle custom placement logic for fields like 'siteAddress' and 'firstOccupancyDate'
  if (a[0] === 'siteAddress') {
    // Ensure 'siteAddress' is treated as occupying multiple places (e.g., positions 5, 7, 9, 11)
    if (a[1].others.displayOrder === 5) return -1;  // Move to the desired position
    if (a[1].others.displayOrder === 7) return -1;
    if (a[1].others.displayOrder === 9) return -1;
    if (a[1].others.displayOrder === 11) return -1;
  }
          return orderA - orderB; // Sort ascending
        });

        // Convert back to an object
        const sortedfieldSchemaLead = Object.fromEntries(entries);
        const sortedfieldContactLead = Object.fromEntries(entriesForContact);
        const sortedfieldSiteLead = Object.fromEntries(entriesForSite);
        console.log(sortedfieldContactLead,"sortedfieldSchemaLead",sortedfieldSchemaLead)
  this.leadSchema = JSON.parse(JSON.stringify(sortedfieldSchemaLead));
  this.contactSchema = JSON.parse(JSON.stringify(sortedfieldContactLead))
  this.siteSchema = JSON.parse(JSON.stringify(sortedfieldSiteLead))
},
      async importdatamodal1(){
        await UPCAxiosInstance.post(`salesLead`, this.importData);
        this.notification = {
          type: "success",
          message: this.loadStaticData('LANG-0025') || "Leads are imported successfully!.",
        };
        this.importDataModal = false
        try {
  const response = await UPCAxiosInstance.get(`/salesLead`);
  this.tableData = response?.data || [];
  this.tableData = this.tableData?.filter(ele=>ele?.externalReference?.length>0 || ele.numberOfUnits)
  // console.log(response,"this.tableData",this.tableData)
  } catch (error) {
    console.error("Error fetching salesLead data:", error);
    this.tableData = [];
  }
      },
      async newEmit(event) {
      const file = event.target.files[0]; // Get the uploaded file from the input
      if (!file) {
        console.error("No file selected.");
        return;
      }

      const formData = new FormData();
      formData.append("file", file, file.name);
      try {
        let res = await DMNAxiosInstance.post(
          `import/map_data/?type=saleslead&id=${this.templateId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"            },
          }
        );
        if(res.data.ok == 1){
            this.importData = res.data.data.map(i => i.saleslead)
            this.insights = res.data.insights
             this.insights.map((i)=>{
              i.missingFields.map((j)=>{
                let obj = {}
                obj["errorPosition"] = i.recordIndex
                if(j.csvHeader == null){

                  obj["error"] = `${j.systemField} => ${j.message}`
                }else{
                  obj["error"] = `${j.systemField}-${j.csvHeader} => ${j.message}`

                }
                this.errorTableData.push(obj)
              })
              console.log(this.errorTableData,"this.errorTableData")
            })
          }

      } catch (error) {
        console.error(
          "Error uploading file:",
          error.response?.data || error.message
        );
      }

    },
    openImport(){
      // this.importDataModal = true
      this.$router.push({
          path: `/MDU/lead/import`,
        });

    },
      async closeCrudContextMenu($event, ele, i) {
  
      if ($event.toLowerCase() === "edit") {
        this.$router.push({
          path: `/MDU/lead/edit/${ele.name}/${ele._id}`,
        });
      } 
      else if ($event.toLowerCase() == "mark as closed lost")
       {
        ele.context = false
        try {
          const response = await UPCAxiosInstance.patch('/salesLead', { 
            ...ele, 
            status: 'Closed Lost' 
          });
          const getUpdatedData = await UPCAxiosInstance.get(`/salesLead`);
          this.tableData = getUpdatedData.data;
      this.leadOrgData = getUpdatedData.data;
      if(getUpdatedData.data){
      this.notification = {
          type: "success",
          message: "Lead status updated successfully!",
        };
      }
          console.log("Patch successful:", response.data);
        } catch (error) {
          console.error(i,"Error updating salesLead:", error);
        }
        }
    },
      crudContextMenu($event, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 128;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
      computedTableAction(data) {
        data;
        if (this.permisstionData.delete && this.permisstionData.edit) {
        return  [
          {
            display: this.loadStaticData('LANG-0009'),
          label: "Edit",
          icon: "icon-file-text-edit-regular",
        },
          {
            // display: this.loadStaticData('LANG-0051'),
            display:"Create quote",
          label: "Create quote",
          icon: "icon-file-text-regular",
        },
      ];
      } if (this.permisstionData.delete){
        return [
          {
            display:"Create quote",
            // display: this.loadStaticData('LANG-0051'),
          label: "Create quote",
          icon: "icon-file-text-regular",
        },
        ];
      }else {
        return [
          {
            display: this.loadStaticData('LANG-0009'),
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
        ];
      }
      // let options = [
      //   {
      //     label: "Edit",
      //     icon: "icon-file-text-edit-regular",
      //   },
      //   {
      //     label: "Mark as closed lost",
      //     icon: "icon-times-circle-regular",
      //   },
      // ];
      // return options.filter((option) => {
      //   if (
      //     data &&
      //     data.status &&
      //     data.status.toLowerCase() == "active" &&
      //     ["Mark as Active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else if (
      //     data &&
      //     data.status &&
      //     data.status.toLowerCase() == "inactive" &&
      //     ["Mark as In-active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // });


    },
      leadStatus(val){
        this.leadPayload.status = val?.name
      },
     async searchKey(val) {
      this.searchvalue = val;
      this.filterQuery["type"] = "filter-search";
      this.filterQuery["searchQuery"] = this.searchvalue;
      this.currPage = 1;
      this.rowsPerPage = 10;
    //   const filterData = {
    //   company: this.companyName,
    //   type: "filter-search",
    //   module: "saleslead",
    //   isPaginated: true,
    //   lastUpdated: new Date().toJSON(),
    //   paginatedQuery: {
    //     rowsPerPage: this.rowsPerPage,
    //     currentPage: this.currPage,
    //   },
    //   isNeedToBeSort: true,
    //   sortFieldAndOrder: {},
    //   limit: 10,
    //   filterQuery: {},
    //   searchQuery: val,
    // };

    const response = await UPCAxiosInstance.post(
      `util/filterData?company=${this.companyName}`,
      this.filterQuery
    );
    if(response.data.data !=undefined){
    this.tableData = await response.data.data;
    this.totalPageNo = await response.data.total;
    this.paginatedData;
    }
    },
    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },
    async currentPage(val) {
      this.currPage = val;
      await this.getLeadTableData(this.currPage, this.rowsPerPage);
    },
      OpenAddLeadModal(){
        this.isLeadDetailsFilled = false
        this.importDataModal = false
        this.leadPayload = JSON.parse(JSON.stringify(this.leadPayloadEmpty))
        this.siteDetails=[] 
        this.showAllSitesModal = false;
        this.showSiteInformationModal = false
        this.showAddNewLeadModal = true;
      },
      getTypeOfContact(val){
        console.log("getTypeOfContact",val)
        this.leadContactDetails.contactType = val?.name
        console.log("this.leadContactDetails",this.leadContactDetails)
      },
     async getSiteDataAfterSiteButtonClicked(){
      this.siteDetailsEmpty = JSON.parse(JSON.stringify(this.temporaryleadSiteDetails))
       await this.addNewLeadAndSite()
      },
      async addNewLeadAndSite(){
        let currentDate = new Date().toJSON();
        this.leadContactDetails.createdAt = currentDate
        this.leadContactDetails.preferred = true
        this.leadContactDetails.lastUpdate = currentDate


        this.leadPayloadEmpty.createdAt = currentDate
        this.leadPayloadEmpty.lastUpdate = currentDate


        this.disableAddButton = true
        this.dupSiteDetailsForMapping = JSON.parse(JSON.stringify(this.siteDetailsEmpty))
        console.log(this.leadPayloadEmpty,"leadContactDetails",this.leadContactDetails)
        console.log("this.siteDetailsEmpty",this.siteDetailsEmpty)
        console.log("this.dupSiteDetailsForMapping",this.dupSiteDetailsForMapping)
        this.siteDetailsEmpty = this.siteDetailsEmpty.map((detail) => {
  let updatedDetail = { ...detail };
  if (updatedDetail.siteName) {
    updatedDetail.name = updatedDetail.siteName;
    delete updatedDetail.siteName;
  }
  if (updatedDetail.siteAddress) {
    updatedDetail.contactMedium = updatedDetail.siteAddress;
    delete updatedDetail.siteAddress;
  }
  updatedDetail.createdAt = currentDate;
  updatedDetail.lastUpdate = currentDate;

  return updatedDetail;
});
let dupSiteDetails = JSON.parse(JSON.stringify(this.siteDetailsEmpty))
        this.leadPayloadEmpty.relatedParty.push(this.leadContactDetails)
        // this.leadPayload.relatedParty[this.chosenSiteIndex + 2].name = this.siteDetails[this.chosenSiteIndex].name
        // To add the new lead
        console.log("this.siteDetails",this.siteDetailsEmpty)
        console.log(dupSiteDetails ,"this.leadPayload",this.leadPayloadEmpty , "dupSiteDetailsForMapping",this.dupSiteDetailsForMapping)
  try {
    const patchRequests = this.siteDetailsEmpty.map(async site => {
      const response = await UPCAxiosInstance.post(`geoSite`, site);
      console.log("Response for site:", site, response.data);
      if (response) {
                const existingSite = this.leadPayloadEmpty.relatedParty.find(
                    (party) => party.name == response.data.name && party.id == response.data.id
                );

                if (!existingSite) {
                    this.leadPayloadEmpty.relatedParty.push({
                        name: response.data.name,
                        id: response.data.id,
                        '@type': 'site',
                    });
                }
            }
        });
    
    const responses = await Promise.all(patchRequests); 
    console.log("All responses:", responses); 
    if(responses){
      const createLead = await UPCAxiosInstance.post(`salesLead`, this.leadPayloadEmpty);
      console.log(createLead,"Lead created response")
    }
   await this.getLeadTableData();
if(responses){
  // this.tableData = await response?.data || [];
  // this.tableData = await this.tableData?.filter(ele=>ele?.externalReference?.length > 0 || ele.numberOfUnits)
  // this.leadOrgData = JSON.parse(JSON.stringify(this.tableData))
  // console.log(this.tableData,"this.tableData",response.data)
  this.showSiteInformationModal = false
  this.showAllSitesModal = false
  this.showAddNewLeadModal = false
  this.disableAddButton = false
  this.notification = {
          type: "success",
          message: this.loadStaticData('LANG-0037') ||`New lead created successfully!.`,
        };
        this.siteDetailsPayload =  JSON.parse(JSON.stringify(this.reassignSiteDetailsPayload)) 
  this.leadContactDetails = { ...this.reassignLeadContactDetails }
  this.leadPayloadEmpty = { ...this.reassingnLeadPayloadEmpty }
  await this.$store.dispatch("leadMainFilterData");
  this.filter = this.$store.getters.leadMainFilterData;
  }
 }
  catch (error) {
    console.error("Error patching site details:", error);
  }
//   try {

// }
//   // console.log(response,"this.tableData",this.tableData)
//   } catch (error) {
//     console.error("Error fetching salesLead data:", error);
//     this.tableData = [];
//   }

      },
      getTitleForContact(val){
        console.log("getTitleForContact",val)
        this.leadContactDetails.title = val?.name
      },
      websiteRegex(val) {
      const websiteRegex =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

      if (val?.length >= 1) {
        if (!websiteRegex.test(val)) {
          this.isUrlEmpty = true; 
        } else {
          this.isUrlEmpty = false;
        }
      } else {
        this.isUrlEmpty = false;
      }
    },
      goToLeadDetails(){
        console.log("this.customFieldLeadSchema", this.customFieldLeadSchema);
        // this.componentKey+=1
        console.log(this.contactSchema,"goToLeadDetails contact",this.leadContactDetails)
        console.log(this.leadSchema,"goToLeadDetails lead",this.leadPayloadEmpty)
        this.showAllSitesModal = false
        this.showSiteInformationModal = false
        this.showAddNewLeadModal = true
//         this.leadSchema 
// this.contactSchema
// this.leadContactDetails 
// this.leadPayloadEmpty
this.siteDetailsEmpty = JSON.parse(JSON.stringify(this.temporaryleadSiteDetails))
      },
      getLeadDealCategory(val){
        this.leadPayload.dealCategory = {name:val.name}
      },
      getLeadOwner(val){
        console.log("getLeadSource",val)
        this.leadPayload.relatedParty[0].value = val.name
        console.log("this.leadPayload",this.leadPayload)
      },
      getLeadSource(val){
        // to get choosen lead source

        console.log("getLeadSource",val)
        this.leadPayload.relatedParty[1].value = val.name
        console.log("this.leadPayload",this.leadPayload)
      },
      getLeadType(val){
        console.log("getLeadType",val)
        this.leadPayload.type =val?.name
      },
      getLeadDwellingType(val){
        this.leadPayload.dwellingtype = val.name
      },
      getLeadCategory(val){
        this.leadPayload.category = {name:val.name}
      },
      getLeadMarketSegment(val){
        this.siteDetails[this.chosenSiteIndex].marketSegment = {name:val.name}
        console.log("this.siteDetails",this.siteDetails)
      },
      getLeadOnboardingDate(val){
        console.log("getLeadOnboardingDate",val)
        this.siteDetails.onboardingDate = val
      },
      showSitesDropDown(){
        // To create an lead
        // this.isSaveButtonClickedForLead = true
        // this.isSaveButtonClickedForContact = true
        this.leadContactDetails = JSON.parse(JSON.stringify( this.temporaryleadContactPayload ))
        this.leadPayloadEmpty = JSON.parse(JSON.stringify(this.temporaryleadPayload))
          this.showAllSitesModal=true  
        this.isSaveButtonClickedForLead = false
        this.isSaveButtonClickedForContact = false
      },
      getLeadDataForOverview(val){
        if(val){
          console.log("getLeadDataForOverview",val)
        // this.leadPayloadEmpty = JSON.parse(JSON.stringify(val))
        this.temporaryleadPayload = JSON.parse(JSON.stringify(val))
        this.totalSites = this.temporaryleadPayload.externalReference[1].value
        }
      },
      getLeadData(Val){
        if(Val){
        // this.leadContactDetails = JSON.parse(JSON.stringify(Val))
        this.temporaryleadContactPayload = JSON.parse(JSON.stringify(Val))
        console.log("getLeadData",Val) 
       
        }
        // this.showAllSitesModal=true  
        // this.isSaveButtonClickedForLead = false
        // this.isSaveButtonClickedForContact = false
      },

      openSiteDetails(i,index){
        console.log(index,"openSiteDetails",i)
        this.chosenSiteIndex = i-1
        this.showSiteInformationModal = true
        this.siteDetailsEmpty[this.chosenSiteIndex] ? this.siteDetailsEmpty[this.chosenSiteIndex]:this.siteDetailsEmpty[this.chosenSiteIndex] ={
          siteName:"",
          networkId:"",
          networkManager:'',
          createdAt:'',
          lastUpdate:'',
          sfdcId:"",
          numberOfUnits:"",
          tags:[],
          firstOccupancyDate:"",
          onboardingDate:'',
          marketSegment:{},
          siteAddress:[
          {
            mediumType: "postalAddress",
            characteristic: {
              "@type": "",
          street: "",
          city: "",
          country: "",
          default: false,
          description: "",
          postalCode: "",
          state: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          unit: "",
              latitude:"",
              longitude:"",
            },
          },
          ]
        }
        this.siteDetailsPayload =  this.siteDetailsEmpty[this.chosenSiteIndex]
        // this.leadPayloadEmpty.relatedParty [this.chosenSiteIndex + 2] ? this.leadPayloadEmpty.relatedParty[this.chosenSiteIndex + 2]  : this.leadPayloadEmpty.relatedParty[this.chosenSiteIndex + 2] = {
        //   name:this.siteDetailsEmpty?.[this.chosenSiteIndex]?.siteName || '',
        //   id:"",
        //   '@type':'site',
        // }
        console.log(this.siteSchema,"adnsfba",this.siteDetailsPayload,this.leadPayloadEmpty)
        this.siteSchema
        this.siteDetailsEmpty 
        // this.characteristicpreval.characteristic = this.siteDetails[this.chosenSiteIndex].contactMedium[0].characteristic
        // console.log(this.characteristicpreval.characteristic,"Site Details",index,"leadPayload?.relatedParty[chosenSiteIndex]?.name")
        // console.log( this.siteDetails,"chosenSiteIndex",this.leadPayload?.relatedParty)
      },
      setSiteTags(val){
        this.siteDetails[this.chosenSiteIndex].tags = {name:val?.name}
      },
      closeSiteDetails(i){
        console.log(i)
        this.isSaveButtonClicked = true
        // setTimeout(() => {
        // if (this.showSiteInformationModal) {
            console.log('Forcing showSiteInformationModal to false');
            this.showSiteInformationModal = false;
            this.showAllSitesModal=true 
    //     }
    // }, 50);
    this.siteDetailsEmpty = JSON.parse(JSON.stringify(this.temporaryleadSiteDetails))
      },
     async getSiteDetails(val){
        if(val){
          this.currentSiteObject = {...val}
          // this.temporaryleadSiteDetails = { ...val}
          this.temporaryleadSiteDetails.splice(this.chosenSiteIndex, 1,val)
          console.log(this.temporaryleadSiteDetails,"this.temporaryleadSiteDetails",val)
      //   console.log(this.siteDetailsEmpty,"getSiteDetails",val)
      //  await this.siteDetailsEmpty.splice(this.chosenSiteIndex, 1,val)
      //   console.log("this.siteDetailsEmpty")
      //   this.dupSiteDetailsForMapping = JSON.parse(JSON.stringify(this.siteDetailsEmpty))
      //   // this.leadPayloadEmpty.relatedParty[this.chosenSiteIndex + 2].name = this.siteDetailsEmpty?.[this.chosenSiteIndex]?.siteName
      //   console.log(this.siteDetailsEmpty,"chosenSiteIndex",this.leadPayloadEmpty)
      //   this.showSiteInformationModal = false
      //   this.showAllSitesModal=true 
      //   this.isSaveButtonClicked = false
        }
        console.log(this.siteDetailsEmpty,"chosenSiteIndex",this.temporaryleadSiteDetails)
      },
      receivedAddressData(val){
        this.siteDetails[this.chosenSiteIndex].contactMedium[0].characteristic = val.characteristic
        console.log(this.siteDetails[this.chosenSiteIndex].contactMedium,"receivedAddressData",val)
      },
      geopoint(lat,log){
        console.log(lat,"geopoint",log) 
        this.siteDetails[this.chosenSiteIndex].contactMedium[0].characteristic.latitude = lat
        this.siteDetails[this.chosenSiteIndex].contactMedium[0].characteristic.longitude  = log
      }
    }
};
</script>

<style lang = "scss">
.to-style-modal{
  .modal-backdrop1{
    .hlx-modal-container{
      height: 600px !important;
      overflow-y: auto;
    }
  }
}
.parent-container-for-lead-maintable-overview{
  .form-container{
    padding-bottom:0px;
  }
}
</style>
