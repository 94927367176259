<template>
      <div class="invite-user">
      <hlx-modal
        :modal-active="edit"
        :height="'420px'"
        :width="'400px'"
        :modal="true"
        @close="edit = false"
      >
        <template #header>
          <div>Edit user</div>
        </template>
        <template #content>
          <div class="edit-user-form">
            <div class="form-input-wrapper">
              <p class="input-header">Name</p>
              <div class="form-input">
                <hlx-input
                  v-model:value="editUser.name"
                  :label-animation="false"
                  type="text"
                  :pre-val="editUser.name"
                  :clearable="true"
                />
              </div>
            </div>
            <div class="form-input-wrapper">
              <p class="input-header">Work email</p>
              <div class="form-input">
                <hlx-input
                  v-model:value="editUser.email"
                  :label-animation="false"
                  :pre-val="editUser.email"
                  type="email"
                  :clearable="true"
                />
              </div>
            </div>
            <div class="form-input-wrapper">
              <p class="input-header">Plan</p>
              <div class="form-input">
                <hlx-select
                  v-model:value="editUser.Plan"
                  :options="dropdown_data"
                  :pre-value="editUser.Plan"
                  :label-animation="false"
                  :clearable="true"
                  @custom-change="selectValue"
                ></hlx-select>
              </div>
            </div>
            <div class="form-input-wrapper">
              <p class="input-header">Status</p>
              <div class="form-input">
                <hlx-select
                  v-model:value="editUser.status"
                  :options="status_data"
                  :pre-value="editUser.status"
                  :label-animation="false"
                  :clearable="true"
                  @custom-change="selectValue"
                ></hlx-select>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="footer-form-edit">
            <span></span>
            <!-- <div><p class="insider">Reset password</p></div> -->
            <div class="button-area">
              <hlx-button class="secondary sm" @click="edit = false"
                >Cancel</hlx-button
              >

              <hlx-button class="primary sm" @click="patchList()"
                >Save</hlx-button
              >
            </div>
          </div>
        </template>
      </hlx-modal>
    </div>
    <div class="app-layout">
    <section class="right-panel">
      <div class="layout-basic">
          <div class="layout-breadcrumb">
          <hlx-breadcrumb :items="items" @path="pathTo" />
        </div>

        <div class="layout-header">
          <p>Tenant</p>
        </div> 

       <div class="layout-body" style="margin: 0 !important">
             <div
            class="header-utils-and-switch-container1"
            style="
              display: flex;
              justify-content: space-between;
              margin-bottom: 16px;
            "
          >
            <!-- <hlx-switch
              :type="'tab'"
              :position="'bottom'"
              :switch-items="users_type_switch"
              @chosen="usersSelected"
            /> -->
            <span style="width: 70%">
              <hlx-switch
                v-model="currentSwitchvalue"
                :switch-items="users_type_switch"
                :tab-view="true"
                value-prop="value"
                display-prop="name"
                @change="usersSelected"
              />
            </span>
            <span class="users-header-utils">
              <span class="util-items search"
                ><hlx-search
                  class="search"
                  :search-style="'style1'"
                  @search-key="search"
                ></hlx-search
              ></span>
              <span class="util-items"
                ><hlx-button class="secondary sm"
                  >Filter</hlx-button
                ></span
              >
              <span class="util-items"
                ><hlx-button
                class="primary sm add-btn"
                @click="$router.push(`/settings/tenant/addTenant`)"
                ><i class="icon-plus-circle-regular"></i> Add tenants
              </hlx-button></span
              >
              <span class="util-items util-items-add"
                ><hlx-button
                  v-show="inviteUser == true"
                  class="primary sm add-btn"
                  ><i class="icon-plus-circle-regular"></i> Invite
                  user</hlx-button
                >
              </span>
            </span>
          </div>
        <div class="table-wrap">
          <hlx-table
            :column-count="theads"
            :border="['table', 'header', 'horizontal']"
            :bold-headers="false"
            :row-hover="false"
            theme="grey"
            :striped-rows="false"
          >
            <template #thead>
              <hlx-table-head :align="'left'" :fixed="'left'" :width="50">S.no</hlx-table-head>
              <hlx-table-head
                v-for="(i, index) in headvalue"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :align="'left'"
                :width="i.width"
                @sorting_func="sorting_Data"
                >{{ i.label }}</hlx-table-head
              >
              <hlx-table-head :width="80" :fixed="'right'">
                <span class="action-icon"
                    ><i
                      class="icon-settings-regular"
                      @click="arrange(true)"
                    ></i></span
                >
              </hlx-table-head>
            </template>
            <template #tbody>
              <tr v-for="(i, index) in paginatedData" id="" :key="index">
                <hlx-table-cell :align="'center'" :fixed="'left'" :width="50">
                  {{ serialNumber(index) }}
                </hlx-table-cell>
                <hlx-table-cell
                  v-for="(j, col_index) in headvalue"
                  :key="col_index"
                  :align="'left'"
                  :width="j.width"
                >
                  <div v-if="j.prop == 'status'">
                    <div>
                <hlx-status :type="i[j.prop]"></hlx-status>
              </div>
                  </div>
                  <div v-else-if="j.prop == 'last_visit'">
                      {{ formatDate(i[j.prop]) }}
                    </div>
                    <div v-else-if="j.prop == 'created_on'">
                      {{ formatDate(i[j.prop]) }}
                    </div>
                  <div v-else>
                    {{ i[j.prop] }}
                  </div>
                </hlx-table-cell>
                <hlx-table-cell :width="50" :fixed="'right'"
                  ><i class="icon-more-vertical-filled" style="cursor:pointer" @click="menu($event,index)">
                        <span
                          v-if="index < rowsPerPage / 2"
                          style="position: absolute"
                        >
                          <hlx-context-menu
                            style="
                              z-index: 9000;
                              margin-right: 100px !important;
                              margin-top: -10px;
                              box-shadow: 0 0 20px 0 #d8d8d8;
                            "
                            :options="options"
                            :data="index.toString()"
                            :show="i.context == true"
                            @chosen="closecontextmenu"
                          />
                        </span>
                        <span v-else style="position: absolute">
                          <hlx-context-menu
                            style="
                              z-index: 9000;
                              margin-right: 100px !important;
                              margin-top: -52px;
                              box-shadow: 0 0 20px 0 #d8d8d8;
                            "
                            :options="crudoptions"
                            :data="index.toString()"
                            :show="i.context == true"
                            @chosen="closecontextmenu"
                          />
                        </span>
                  </i>
                </hlx-table-cell>
              </tr>
            </template>
          </hlx-table>
        </div>
       </div>

               <div class="layout-footer">
          <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
            :total="tableData.length"
            rows-per-page
            enable-rows-per-page
            :rows-per-page-list="[10, 15, 20]"
            @current-page="currentPage"
            @updated:rows-per-page="changeRowsPerPage"
          ></hlx-pagination>
        </div>
      </div>
    </section>
    </div>
</template>

<script>
import { MLTAxiosInstance } from '@/config/axiosConfig';

// import sidebar from "../components/sidebarComponent.vue";
import axios from "axios";

export default {
  // components: { sidebar },
  name: "UserView",
  data() {
    return {
      externalRowsPerPage:10,
      currentSwitchvalue:'All',
      viewPermission: [],
      editPermission: [],
      table_height: Number,
      editUser: {},
      dropdown_data: [
        { name: "Admin", value: "Admin"},
        { name: "Member",value: "Member"},
      ],
      status_data: [
        { name: "Active",value: "Active" },
        { name: "Inactive",value: "Inactive"},
      ],
      edit: false,
      token: this.$cookies.get("token"),
      view: "All",
      items: [
        { label: "Settings", link: "/" },
        { label: "Tenants", link: "/tenants" },
      ],
      users_type_switch: [
        { name: 'All', value:'All'},
        { name: 'Active', value:'Active' },
        { name: 'Inactive', value:'Inactive' },
        { name: 'Pending' , value:'Pending'},
      ],
      selectedValue:{ name: 'All', value:'All'},
      options: [],
      options3: [
        {
          label: "Edit",
          icon: "icon-edit-regular",
        },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },

        {
          label: "Send activation email",
          icon: "icon-envelope-regular",
        },
      ],
      options2: [
        {
          label: "Edit",
          icon: "icon-edit-regular",
        },
        {
          label: "Impersonate",
          icon: "icon-users-regular",
        },
        {
          label: "View billing",
          icon: "icon-dollar-receive-square-regular ",
        },
        {
          label: "Activity log",
          icon: "icon-clock-regular",
        },
        {
          label: "Activate",
          icon: "icon-toggle-on-alt-regular",
        },
      ],
      tableData: [],
      theads: [
        { name: "ID", checked: true, id: 1 },
        { name: "Name", checked: true, id: 2 },
        { name: "Email", checked: true, id: 3 },
        { name: "Plan", checked: true, id: 4 },
        { name: "Total users", checked: true, id: 5 },
        { name: "Created on", checked: true, id: 6 },
        { name: "Last visit", checked: true, id: 7 },
        { name: "Status", checked: true, id: 8 },
      ],
      headvalue: [
        {
          prop: "_id",
          label: "ID",
          sortable: false,
          resizable: true,
          width: 153,
        },
        {
          prop: "name",
          label: "Name",
          sortable: false,
          resizable: true,
          width: 103,
        },

        {
          prop: "plan",
          label: "Plan",
          sortable: false,
          resizable: true,
          width: 255,
        },
        {
          prop: "count",
          label: "Count",
          sortable: false,
          resizable: true,
          width: 53,
        },
        {
          prop: "created_on",
          label: "Created on",
          sortable: false,
          resizable: true,
          width: 203,
        },
        {
          prop: "last_visit",
          label: "Last visited",
          sortable: false,
          resizable: true,
          width: 203,
        },
        {
          prop: "status",
          label: "Status",
          sortable: false,
          resizable: true,
          width: 90,
        },
      ],
      listView: {},
      currPage: 1,
      rowsPerPage: 10,
      options1: [
        {
          label: "Edit",
          icon: "icon-edit-regular",
        },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
        {
          label: "Impersonate",
          icon: "icon-users-regular",
        },
        {
          label: "Activity log",
          icon: "icon-clock-regular",
        },
      ],
      company: this.$cookies.get("company"),
      impersonitor: "",
      fet: process.env.VUE_APP_HOST_PROTOCOL + "://" + process.env.VUE_APP_HOST,
      searchtabledata: [],
      duplicatetabledata: [],
      searchvalue:'',
    };
  },
  computed: {
    computedSidebar() {
      const sidebar = [
        {
          name: "Home",
          label: "Home",
          icon: "icon-grid-web-7-regular",
          description_en: "Home",
          description_fn: "*Home",
          active: true,
        },
        {
          name: "Business rule",
          label: "Business rule ",
          icon: "icon-code-regular",
          description_en: "Residential business market ",
          description_fr: "*Residential business market ",
        },
        {
          name: "Schema",
          label: "Schema",
          icon: "icon-desktop-code-regular",
          description_en: "Small business markets",
          description_fr: "*Small business markets",
        },
        {
          name: "Database",
          label: "Database",
          icon: "icon-database-regular",
          description_en: "Small business markets",
          description_fr: "*Small business markets",
        },
        {
          name: "Execute",
          label: "Execute",
          icon: "icon-file-settings-regular",
          description_en: "Small business markets",
          description_fr: "*Small business markets",
        },
        {
          name: "Audit",
          label: "Audit",
          icon: "icon-notebook-regular",
          description_en: "Audit log",
          description_fr: "*Audit log",
        },
        {
          name: "Help",
          label: "help",
          icon: "icon-question-circle-regular",
          description_en: "Setting",
          description_fr: "*Setting",
          separator: true,
        },
        {
          name: "setting",
          label: "settings",
          icon: "icon-settings-regular",
          description_en: "Setting",
          description_fr: "*Setting",
        },
        {
          name: "logout",
          label: "Logout",
          icon: "icon-log-out-regular",
          description_en: "Logout",
          description_fr: "*Logout",
        },
      ];
      const filteredSidebar = sidebar.filter(
        (item) => !this.viewPermission.includes(item.name)
      );
      
      return filteredSidebar;
    },
    paginatedData() {
      
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      // eslint-disable-next-line
      this.table_height = this.tableData.slice(start, end).length * 40 + 43;
      return this.tableData.slice(start, end);
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.tableData.length;
    },
  },
  mounted() {
    
    let token = {
      headers: {
        Authorization: "Bearer " + this.token,
      },
    };
    
    axios
      .get(this.fet + `/partymanagement/individual/auth`, token)
      .then((val) => {
        
        this.company = val.data.company;
        axios
          .get(
            this.fet +
              `/partymanagement/individual/${val.data.userId}`,
            token
          )
          .then((user_info) => {
            
            this.impersonitor = user_info.data.token;
          });
        axios
          .get(
            this.fet + `/partymanagement/organisation/${this.company}`,
            token
          )
          .then((data) => {
            
            const tableDatam = data.data;
            tableDatam.map((val) => {
              val.context = false;
              return val;
            });
            this.tableData = tableDatam;
            this.duplicatetabledata = this.tableData
            this.searchtabledata = this.tableData
          });
      });
    

        document.addEventListener("click", (event) => {
      if (!event.target.classList.contains("add-button")) {
        this.clicked = false;
      }
      if (!event.target.classList.contains("icon-more-vertical-filled")) {
        this.tableData.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    });

    // document.addEventListener("click", (event) => {
    //   if (!event.target.classList.contains("add-btn")) {
    //     this.show_add_menu = false;
    //   }
    //   if (!event.target.classList.contains("icon-more-vertical-regular")) {
    //     const elements = document.querySelectorAll("[class*=crud]");
    //     for (const element of elements) {
    //       element.style.display = "none";
    //     }
    //   }
    // });
  },
  methods: {
        search(val) {
      this.searchvalue = val;
      if (this.searchvalue && this.searchvalue != "") {
        this.tableData = this.searchtabledata.filter((item) => {
          
          if (
            item.name != undefined &&
            this.searchvalue != undefined &&
            item._id != undefined &&
            item.plan != undefined &&
            item.count != undefined &&
            item.created_on != undefined &&
            item.last_visit != undefined &&
            item.status != undefined
          ) {
            return (
              item.name
                .toLowerCase()
                .toString()
                .includes(this.searchvalue.toLowerCase()) ||
              item.plan
                .toLowerCase()
                .toString()
                .includes(this.searchvalue.toLowerCase()) ||
              item._id
                .toLowerCase()
                .toString()
                .includes(this.searchvalue.toLowerCase()) ||
              item.count
                .toString()
                .includes(this.searchvalue.toLowerCase()) ||
              item.created_on
                .toLowerCase()
                .toString()
                .includes(this.searchvalue.toLowerCase()) ||
              item.last_visit
                .toLowerCase()
                .toString()
                .includes(this.searchvalue.toLowerCase()) ||
              item.status
                .toLowerCase()
                .toString()
                .includes(this.searchvalue.toLowerCase())
            );
          }
        });
        this.duplicatetabledata = this.tableData;
      } else {
        this.tableData = this.searchtabledata;
        this.duplicatetabledata = this.searchtabledata;
      }
    },
        formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return new Intl.DateTimeFormat("en-US", options)
        .format(date)
        .replace(",", "");
    },
    async pathTo(){
      // if(val.label=="Settings"){
        this.$router.push(`/settings`)
      // }
    },
    async menu($event,index) {
      const user = await this.tableData[index];
      if (user.status == "Active") {
        this.options = this.options1;
      }
      // else if (user.status == "Activate") {
      //   this.options = this.options3;
      // }
      else {
        this.options = this.options2;
      }
      if ($event.target.className === "icon-more-vertical-filled") {
        this.tableData.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
        this.tableData[index].context = true;
        // 
      } else {
        this.tableData.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    Contextmenu1(index) {
      if (event.target.className === "icon-more-vertical-regular") {
        this.tableData[index].context.show = true;
      } else {
        // this.clicked = false;
        this.tableData.forEach((e) => {
          if (e.context !== undefined) {
            e.context.show = false;
          }
        });
      }
      // 
    },
   async usersSelected(val) {
      this.view = val.name;
      if(this.view=="Active") {
      const data=  
    await  MLTAxiosInstance.get(
      this.fet + `/partymanagement/organisation/${this.company}`          )
          let table = await data.data;
          let tableDatam =await table.filter((val)=>{
            if(val.status == "Active") {
              return val
            }
          })
          
             if(tableDatam[0]!=undefined){
               tableDatam.map((val) => {
                 val.context = { show: false };
                 return val;
                });
                this.tableData = await tableDatam;
                
                this.tableData = this.tableData.sort(
                  (a, b) => new Date(b.last_visit) - new Date(a.last_visit))
                }
                else{
        this.tableData = []
      }
    }
      else if(this.view=="Inactive"){
        const data=  
    await  MLTAxiosInstance.get(
      this.fet + `/partymanagement/organisation/${this.company}`          )
            let table = await data.data;
            
            let tableDatam =await table.filter((val)=>{
              if(val.status == "Inactive") {
                return val
              }
            })
            
            if(tableDatam[0]!=undefined){
             tableDatam.map((val) => {
               val.context = { show: false };
              return val;
            });
            this.tableData = await tableDatam;
                      this.tableData = this.tableData.sort(
      (a, b) => new Date(b.last_visit) - new Date(a.last_visit))
      this.paginatedData()
      }
      else{
        this.tableData = []
      }
      }
      else if(this.view=="Pending"){
        const data=  
    await  MLTAxiosInstance.get(
      this.fet + `/partymanagement/organisation/${this.company}`          )
          let table = await data.data;
          
             let tableDatam =await table.filter((val)=>{
              if(val.status == "Pending") {
                return val
              }
             })
             
             if(tableDatam[0]!=undefined){

             tableDatam.map((val) => {
               val.context = { show: false };
              return val;
            });
            this.tableData = tableDatam;
                      this.tableData = this.tableData.sort(
      (a, b) => new Date(b.last_visit) - new Date(a.last_visit))    } 
      else{
        this.tableData = []
      } }
      else {
       const data = await MLTAxiosInstance.get(
        this.fet + `/partymanagement/organisation/${this.company}`          )
            const tableDatam = data.data;

            tableDatam.map((val) => {
              val.context = { show: false };
              return val;
            });

            this.tableData = tableDatam;
                      this.tableData = this.tableData.sort(
      (a, b) => new Date(b.last_visit) - new Date(a.last_visit)
    );
    this.paginatedData()
  }    },
    applyColumns(val) {
      this.headvalue = [];
      val.forEach((e) => {
        if (e.checked === true) {
          this.headvalue.push(e);
        }
      });
    },
    arrange(val) {
      
      this.re_arrange = val;
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    currentPage(val) {
      this.currPage = val;
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    changeRowsPerPage(count) {
      
      this.rowsPerPage = count;
    },
    sorting_Data(data, data1) {
      
      if (data === "icon-filled-arrow-up sorting-icon") {
        const x = [...this.tableData].sort(function (a, b) {
          
          if (typeof a === "object") {
            
            if (typeof a[data1] === "string") {
              return String(b[data1])
                .toLowerCase()
                .localeCompare(String(a[data1]).toLowerCase());
            } else if (typeof a[data1] === "number") {
              return b[data1] - a[data1];
            }
          } else if (typeof a === "string") {
            return String(b)
              .toLowerCase()
              .localeCompare(String(a).toLowerCase());
          } else if (typeof b === "number") {
            return b - a;
          } else {
            return 0;
          }
          return 0;
        });
        this.tableData = x;
      } else if (data === "icon-filled-arrow-down sorting-icon") {
        const x = [...this.tableData].sort(function (a, b) {
          if (typeof a === "object") {
            if (typeof a[data1] === "string") {
              return String(a[data1])
                .toLowerCase()
                .localeCompare(String(b[data1]).toLowerCase());
            } else if (typeof a[data1] === "number") {
              return a[data1] - b[data1];
            }
          } else if (typeof a === "string") {
            return String(a)
              .toLowerCase()
              .localeCompare(String(b).toLowerCase());
          } else if (typeof a === "number") {
            return a - b;
          } else {
            return 0;
          }
          return 0;
        });
        this.tableData = x;
      } else if (data === "icon-filled-arrow-up") {
        this.tableData = this.sortData;
      } else if (data === "icon-filled-arrow-down") {
        this.tableData = this.sortData;
      }
    },
    Contextmenu(index) {
      if (event.target.className == "icon-more-vertical-regular") {
        this.tableData[index].context.show = true;
      } else {
        // this.clicked = false;
        this.tableData.forEach((e) => {
          if (e.context != undefined) {
            e.context.show = false;
          }
        });
      }
      // 
    },
    closecontextmenu(data1, data2) {
      const indexvalue = parseInt(data2);
      
      if (data1 == "Delete") {
        this.deleteUser(indexvalue);
      } else if (data1 == "Deactivate") {
        this.deactivateUser(indexvalue);
      } else if (data1 == "Impersonate") {
        this.impersinateUser(indexvalue);
      } else if (data1 == "Activity log") {
        this.activityLog();
      } else if (data1 == "Edit") {
        this.editList(indexvalue);
      } else if (data1 == "Activate") {
        this.activateUser(indexvalue);
      } else if (data1 == "Send activation email") {
        this.sendActivation(indexvalue);
      } else {
        this.resetPassword(indexvalue);
      }
    },

    async viewBill(indexValue) {
      // const user = 
      this.tableData[indexValue];
      
    },
    async activityLog(){
      this.$router.push('/settings/activityLog')
    },
    async activateUser(indexValue) {
      const user = this.tableData[indexValue];
      
      const update_delete = {
        status: "Active",
      };
      let tokens = {
        headers: {
          Authorization: "Bearer  " + this.token,
        },
      };
      const deleteId = await axios.patch(
        this.fet + `/partymanagement/organisation/${user.name}`,
        tokens,
        update_delete
      );
      
      if (deleteId.data._id != undefined) {
        this.resfreshTable();
      }
    },
    async resetPassword(indexValue) {
      // const user = 
      this.tableData[indexValue];
      
    },
    async editList(indexValue) {
      const user = this.tableData[indexValue];
      this.listView = await user;

      this.editUser = user;

      
      this.edit = true;
    },
    async patchList() {
      this.edit = false
    },

    async impersinateUser(indexValue) {
      const user = await this.tableData[indexValue];
      
      let tokens = {
        headers: {
          Authorization: "Bearer  " + this.token,
        },
      };
      const update = {
        email: user.email,
        company: user.name,
        impersonation: true,
        impersinater: this.impersonitor,
      };

      // 
      const change = await axios.patch(
        this.fet + `/partymanagement/individual/impersinate`,
        update,
        tokens
      );
      
      let company = await change.data.company;
      const token = await change.data.token;

      if (company != "") {
        
        let c_name = null;
        if (company == "Halleyx") {
          c_name = "";
        } else {
          c_name = company + ".";
        }
        
        // location.replace(window.location.protocol+"//"+`${c_name}`+window.location.hostname+':'+window.location.port +`/DMN/dashboard/${token}`)
        const add =
          window.location.protocol +
          "//" +
          window.location.hostname +
          ":" +
          window.location.port;
        const urlObject = new URL(add);
        const hostName = urlObject.hostname;
        // let domainName = hostName.replace(/^[^.]+\./g, '');
        window.location.href =
          window.location.protocol +
          "//" +
          `${c_name}` +
          hostName +
          ":" +
          window.location.port +
          `/DMN/dashboard/${token}`;
      }
    },
    async deleteUser(indexValue) {
      const user = this.tableData[indexValue];

      const update_delete = {
        status: "Inactive",
      };
      let tokens = {
        headers: {
          Authorization: "Bearer  " + this.token,
        },
      };
      const deleteId = await axios.patch(
        this.fet + `/partymanagement/organisation/${user.name}`,
        tokens,
        update_delete
      );
      
      if (deleteId.data._id != undefined) {
        this.resfreshTable();
      }
    },
    resfreshTable() {
      let tokens = {
        headers: {
          Authorization: "Bearer  " + this.token,
        },
      };
      axios
        .get(this.fet + `/partymanagement/organisation/${this.company}`, tokens)
        .then((data) => {
          
          const tableDatam = data.data;
          tableDatam.map((val) => {
            val.context = { show: false };
            return val;
          });
          this.tableData = tableDatam;
        });
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
    updatedRowsPerPage(val) {
      this.rowsPerPage = val;
      
    },
    // paginateData(data, pageNumber, rowsPerPage) {
    //   const startIndex = (pageNumber - 1) * rowsPerPage;
    //   const endIndex = startIndex + rowsPerPage;
    //   return data.slice(startIndex, endIndex);
    // },
    changePageNumber(pg) {
      this.pageNumber = pg;
    },
    searchKey(val) {
      this.searchTerm = val;
    },
    gotoDataExecuteView(id) {
      this.$router.push({
        name: "execute-data",
        query: { id: id },
      });
    },
    showAddContextMenu() {
      this.show_add_menu = !this.show_add_menu;
    },
    fromAddContextMenu(val) {
      if (val === "users") {
        this.$router.push({
          name: "users-add",
        });
      } else if (val === "Active") {
        this.$router.push({
          name: "users-custom-variable",
        });
      }
    },
  },
};
</script>

<style></style>
