<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-drawer
    :show="show_right_filter"
    :width="300"
    position="right"
    :header="false"
    :footer="false"
    :show-close-icon="true"
    @close="closeRightFilter"
  >
    <template #body>
      <div style="display: flex">
        <FilterComponent
          :display-alternate-values="displayAlternate"
          :filterpanelname="'productcategory'"
          :company-name="company"
          :query-value="query"
          :display-data="displayFilterData"
          :module="'productOffering'"
          @filtered-data="offeringFilterData"
        ></FilterComponent>
      </div>
    </template>
  </hlx-drawer>
  <div v-if="tableData.length === 0" class="cm-accounts-overview-component">
    <span
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      "
    >
      <img src="@/assets/images/addAccountHierarchy.svg" alt="" />
      <span v-if="module == 'PCM'">
        <hlx-button class="primary sm hierarchybutton" @click="addCategory"
          ><i class="icon-plus-circle-regular"></i>
          <!-- Add -->
          <span v-if="staticData">{{ staticData?.add?.label[loginUserDetails]?.label }}</span>
          </hlx-button
        ></span
      >
    </span>
  </div>
  <div
    v-if="tableData.length > 0"
    class="util-area"
    style="
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
    "
  >
    <span v-if="module != 'PCM'" class="left">
      <span style="display: flex; margin-bottom: 12px; font-weight: bold">
        <!-- {{"Hierarchy"      }} -->
        <span v-if="staticData">{{ staticData?.hierarchy?.label[loginUserDetails]?.label }}</span>
      </span>
    </span>
    <span v-if="module == 'PCM'"> </span>
    <span
      class="right"
      style="display: flex; gap: 1rem; align-items: center; margin-bottom: 12px"
    >
      <span class="search-container" style="width: 250px">
        <!-- <hlx-search @search-key="searchKey"></hlx-search
      > -->
      </span>
      <span v-if="module == 'PCM'">
        <span style="display: flex; align-items: center; gap: 12px">
          <span class="util-items --search"
                    ><hlx-search
                      v-model="searchvalue"
                      @search-key="searchKey"
                    ></hlx-search
                  ></span>

                  <span class="filter-table">
                    <hlx-button
                      class="secondary sm add-btn filterbutton"
                      @click="showDrawerRight()"
                      ><i class="icon-filter-regular"></i>
                      <span v-if="filtertaglength != 0" class="filter-count">{{
                        filtertaglength
                      }}</span>
                    </hlx-button>
                  </span>
          <span @click="activityLogDrawer()"
            ><i
              class="icon-time-forward-regular"
              style="
                font-size: 24px;
                color: rgb(84, 189, 149);
                margin-left: 14px;
              "
            ></i></span
          ><hlx-button class="primary sm hierarchybutton" @click="addCategory"
            ><i class="icon-plus-circle-regular"></i>
            <!-- Add -->
            <span v-if="staticData">{{ staticData?.add?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          ></span
        >
      </span>
      <hlx-switch
        v-if="module == 'CM' && staticData"
        v-model="selectedSwitch"
        :switch-items="switchOptions"
        value-prop="value"
        display-prop="label"
        @change="chosenSwitch"
      />
    </span>
  </div>
  <!-- {{switchvalue+"sitch"  }}{{ tableData.length+"leanth" }} -->
  <div
    v-if="
      tableData.length > 0 &&
      (switchvalue === undefined || switchvalue === 'Table')
    "
    style="overflow: auto"
    class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
  >
    <hlx-table
      v-if="module == 'CM'"
      :label-value="tableModalLang"
      :column-count="accounts_table_theads.length + 2"
      :border="['table', 'header', 'horizontal']"
      :row-hover="true"
      theme="grey"
      :re-arrange-columns="re_arrange"
      :re-arrange-heads="accounts_table_theads_cc"
      @close-rearrange="re_arrange =false"
      @apply-rearranged-columns="applyColumns"
    >
      <template #thead>
        <hlx-table-head
          :width="60"
          :align="'center'"
          style="padding: 0 15px"
          :fixed="'left'"
          @new-resize-width="resizedWidth"
          >
          <!-- {{ "S.no" }} -->
          <span v-if="staticData">{{ staticData?.sNo?.label[loginUserDetails]?.label }}</span>
          </hlx-table-head
        >
        <hlx-table-head
          v-for="(i, index) in accounts_table_theads"
          :key="index"
          :prop="i.prop"
          :sortable="i.sortable"
          :resizable="i.resizable"
          :width="i.width !== undefined ? i.width : ''"
          :align="i.align !== undefined ? i.align : ''"
          @sorting_func="sorting_Data"
          @new-resize-width="resizedWidth"
        >
          {{ i.label }}</hlx-table-head
        >
        <hlx-table-head
          v-show="mode === 'edit'"
          :align="'center'"
          :width="70"
          :fixed="'right'"
           @new-resize-width="resizedWidth"
          ><span class="action-icon">
            <i class="icon-settings-regular" style="cursor:pointer" @click="re_arrange = true"></i> </span
        ></hlx-table-head>
        <!-- @click="arrange(true)" -->
      </template>
      <template #tbody>
        <tr
          v-for="(i, index) in paginatedData"
          id=""
          :key="index"
          draggable="true"
          @dragstart="rowDragStart(index)"
          @dragenter="rowDragEnter($event, index)"
          @dragend="rowDragEnd(index)"
        >
          <hlx-table-cell :align="'center'" :fixed="'left'">
            {{ serialNumber(index) }}
          </hlx-table-cell>
          <hlx-table-cell
            v-for="(j, col_index) in accounts_table_theads"
            :key="col_index"
            :align="j.align"
            :fixed="j.fixed"
          >
            <div
              v-if="j.prop.toLowerCase() === 'name'"
              style="width: max-content"
            >
              <span
                style="width: calc(max-content + 60px)"
                :style="{
                  marginLeft:
                    (i['level'].match(/\./g) || []).length * 20 + 'px',
                  marginRight:
                    (i['level'].match(/\./g) || []).length * 20 + 'px',
                }"
              >
                <i
                  v-if="i.expand === true && i.noChild !== true"
                  class="icon-angle-down-regular"
                  style="cursor: pointer"
                  @click="collapseHierarchyRow(i.level)"
                ></i>
                <i
                  v-if="i.expand === false && i.noChild === false"
                  class="icon-angle-right-regular"
                  style="cursor: pointer"
                  @click="expandHierarchyRow(i.level, index)"
                ></i>
                {{ i["customName"] }}</span
              >
              <span
                :id="'category-line-area' + index"
                :style="{
                  marginLeft:
                    (i['level'].match(/\./g) || []).length * 20 + 'px',
                  marginRight:
                    (i['level'].match(/\./g) || []).length * 20 + 'px',
                }"
                class="category-line-area"
              >
                <span
                  class="category-line-sibiling"
                  @dragenter="sibilingArea($event, index)"
                ></span>
                <span
                  class="category-line-child"
                  @dragenter="childArea($event, index)"
                ></span>
              </span>
            </div>
            <div v-else-if="j.prop.toLowerCase() === '@type'">
              {{ i["contactMedium"][3].characteristic["@type"] }}
            </div>
            <div
              v-else-if="j.prop.toLowerCase() === 'address'"
              :id="j.prop + index"
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              :style="{
                width: j.width + 'px',
              }"
              @mouseover="
                tooltipover(j.prop + index, 'tooltip-text' + j.prop + index)
              "
              @mouseleave="
                tooltipleave(j.prop + index, 'tooltip-text' + j.prop + index)
              "
            >
              {{
                i["contactMedium"][3].characteristic["description"] !== "" &&
                i["contactMedium"][3].characteristic["description"] !==
                  undefined
                  ? i["contactMedium"][3].characteristic["description"]
                  : "-"
              }}
              <p id="tooltip-text" :class="'tooltip-text' + j.prop + index">
                {{
                  i["contactMedium"][3]["description"] !== "" &&
                  i["contactMedium"][3]["description"] !== undefined
                    ? i["contactMedium"][3]["description"]
                    : "-"
                }}
              </p>
            </div>
            <div v-else-if="j.prop.toLowerCase() === 'status'">
              <div>
                <hlx-status :type="i[j.prop]"></hlx-status>
              </div>
            </div>
            <div v-else-if="j.prop === 'relatedParty'">
              {{ getNamesOfAccountExecutives(i, j.prop) }}
              <!-- {{ i['relatedParty'].map(obj => obj.role === 'Account executive') }} -->
            </div>
            <div v-else-if="j.prop === 'contacts'">
              {{ getContactsCount(i, "relatedParty") }}
              <!-- {{ i['relatedParty'].map(obj => obj.role === 'Account executive') }} -->
            </div>
            <!-- PCM -->
            <div v-else-if="j.prop === 'productOffering'">
              {{ i[j.prop].length }}
            </div>
            <div v-else-if="j.prop == 'pricingType'">
              <span v-if="i[j.prop].length != 0">{{
                pricingtypeFunction(i[j.prop])
              }}</span>
              <span v-else>{{ "-" }}</span>
            </div>
            <div v-else-if="j.prop === 'identifier'">
              <span v-if="i[j.prop]">{{ i[j.prop] }}</span>
              <span v-else>{{ "-" }}</span>
            </div>
            <div v-else-if="j.prop === 'type'">
              <span v-if="i[j.prop]">{{ i[j.prop] }}</span>
              <span v-else>{{ "-" }}</span>
            </div>
            <div v-else>{{ i[j.prop] }}</div>
          </hlx-table-cell>
          <hlx-table-cell
            v-show="mode === 'edit'"
            :align="'center'"
            :width="120"
            :fixed="'right'"
          >
            <span :id="'table-context' + index" class="action-icon">
              <i
                style="position: relative"
                class="icon-more-vertical-filled"
                :class="i.context === true ? 'active-action' : ''"
                @click="crudContextMenu($event, index, i)"
              >
                <hlx-context-menu
                        :display="'display'"
                  :top="containertop"
                  :left="containerleft"
                  :conditionvalue="150"
                  :conditiondown="2"
                  :conditionup="-38"
                  :options="computedTableAction(i, index)"
                  :data="index.toString()"
                  :show="i.context == true"
                  @chosen="closeCrudContextMenu($event, i, index)"
                />
              </i>
            </span>
          </hlx-table-cell>
        </tr>
      </template>
    </hlx-table>
    <hlx-table
      v-if="module == 'PCM' || module == 'Role'"
            :label-value="tableModalLang"
      :column-count="accounts_table_theads.length + 2"
      :border="['table', 'header', 'horizontal']"
      :row-hover="true"
      theme="grey"
      :re-arrange-columns="re_arrange"
      :re-arrange-heads="accounts_table_theads_cc"
      @close-rearrange="re_arrange =false"
      @apply-rearranged-columns="applyColumns"
    >
      <template #thead>
        <!-- <hlx-table-head
          :width="60"
          :align="'center'"
          style="padding: 0 15px"
          :fixed="'left'"
          >{{ 'S.no' }}</hlx-table-head
        > -->
        <hlx-table-head
          v-for="(i, index) in accounts_table_theads"
          :key="index"
          :prop="i.prop"
          :sortable="i.sortable"
          :resizable="i.resizable"
          :width="i.width !== undefined ? i.width : ''"
          :align="i.align !== undefined ? i.align : ''"
          @sorting_func="sorting_Data"
          @new-resize-width="resizedWidth"
        >
          {{ i.label }}</hlx-table-head
        >
        <hlx-table-head
          v-if="module == 'PCM'"
          v-show="mode === 'edit'"
          :align="'center'"
          :width="70"
          :fixed="'right'"
           @new-resize-width="resizedWidth"
          ><span class="action-icon">
            <i class="icon-settings-regular" style="cursor: pointer" @click="re_arrange = true"></i> </span
        ></hlx-table-head>
        <!-- @click="arrange(true)" -->
      </template>
      <template #tbody>
        <tr
          v-for="(i, index) in paginatedData"
          :id="'drag-row' + index"
          :key="index"
          :draggable="module === 'PCM'"
          :style="[isDraggable == true ? 'cursor:grab' : 'cursor:pointer']"
          style="position: relative"
          @dragstart="rowDragStart(index)"
          @dragenter="rowDragEnter($event, index)"
          @dragend="rowDragEnd(index)"
        >
          <!-- <hlx-table-cell
            :align="'center'"
            :fixed="'left'"
          >
            {{ serialNumber(index) }}
            
          </hlx-table-cell> -->
          <hlx-table-cell
            v-for="(j, col_index) in accounts_table_theads"
            :key="col_index"
            :align="j.align"
            :fixed="j.fixed"
          >
            <div
              v-if="j.prop.toLowerCase() === 'name'"
              style="width: max-content; position: relative"
            >
              <span
                style="width: calc(max-content + 60px)"
                :style="{
                  marginLeft:
                    (i['level'].match(/\./g) || []).length * 20 + 'px',
                  marginRight:
                    (i['level'].match(/\./g) || []).length * 20 + 'px',
                }"
              >
                <i
                  v-if="i.expand === true && i.noChild !== true"
                  class="icon-angle-down-regular"
                  style="cursor: pointer"
                  @click="collapseHierarchyRow(i.level)"
                ></i>
                <i
                  v-if="i.expand === false && i.noChild === false"
                  class="icon-angle-right-regular"
                  style="cursor: pointer"
                  @click="expandHierarchyRow(i.level, index)"
                ></i>

                <span v-if="module == 'PCM'"
                  >{{ i["displayID"] + " " + i[j.prop] }}
                </span>
                <span v-if="module == 'Role'">{{ i[j.prop] }} </span>
              </span>
              <!-- <span class="category-line-container"> -->
              <span
                :id="'category-line-area' + index"
                :style="{
                  marginLeft:
                    (i['level'].match(/\./g) || []).length * 20 + 'px',
                  marginRight:
                    (i['level'].match(/\./g) || []).length * 20 + 'px',
                }"
                class="category-line-area"
              >
                <span
                  class="category-line-sibiling"
                  @dragenter="sibilingArea($event, index)"
                ></span>
                <span
                  class="category-line-child"
                  @dragenter="childArea($event, index)"
                ></span>
              </span>
              <!-- </span> -->
            </div>
            <div v-else-if="j.prop.toLowerCase() === '@type'">
              {{ i["contactMedium"][3]["@type"] }}
            </div>
            <div
              v-else-if="j.prop.toLowerCase() === 'address'"
              :id="j.prop + index"
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              :style="{
                width: j.width + 'px',
              }"
              @mouseover="
                tooltipover(j.prop + index, 'tooltip-text' + j.prop + index)
              "
              @mouseleave="
                tooltipleave(j.prop + index, 'tooltip-text' + j.prop + index)
              "
            >
              {{ i["contactMedium"][3]["description"] }}
              <p id="tooltip-text" :class="'tooltip-text' + j.prop + index">
                {{ i["contactMedium"][3]["description"] }}
              </p>
            </div>
            <div v-else-if="j.prop.toLowerCase() === 'status'">
              <div>
                <hlx-status :type="i[j.prop]"></hlx-status>
              </div>
            </div>
            <div v-else-if="j.prop === 'contactMedium'">
              {{ i["relatedParty"][0]["name"] }}
            </div>
            <!-- PCM -->
            <div v-else-if="j.prop === 'productOffering' && i[j.prop]">
              {{ i[j.prop] }}
            </div>
            <div v-else-if="j.prop == 'pricingType'">
              <span v-if="i[j.prop]">{{ pricingtypeFunction(i[j.prop]) }}</span>
              <span v-else>{{ "-" }}</span>
            </div>
            <div v-else-if="j.prop === 'identifier'">
              <span v-if="i[j.prop]">{{ i[j.prop] }}</span>
              <span v-else>{{ "-" }}</span>
            </div>
            <div v-else-if="j.prop === 'type'">
              <span v-if="i[j.prop]">{{ i[j.prop] }}</span>
              <span v-else>{{ "-" }}</span>
            </div>
            <div v-else-if="module == 'PCM'">
              <span v-if="i[j.prop]">{{ i[j.prop] }}</span>
              <span v-else>{{ "-" }}</span>
            </div>
            <div v-else-if="module == 'Role'">
              <hlx-input v-model:value="i[j.prop]" :checked="i[j.prop]" :type="'single-checkbox'" @selected="selectedValcheckbox($event,i[j.prop])" />
            </div>
          </hlx-table-cell>
          <hlx-table-cell
            v-if="module == 'PCM'"
            v-show="mode === 'edit'"
            :align="'center'"
            :width="120"
            :fixed="'right'"
          >
            <span :id="'table-context' + index" class="action-icon">
              <i
                style="position: relative"
                class="icon-more-vertical-filled"
                :class="i.context === true ? 'active-action' : ''"
                @click="crudContextMenu($event, index, i)"
              >
                <hlx-context-menu
                        :display="'display'"
                  :top="containertop"
                  :left="containerleft"
                  :conditionvalue="150"
                  :conditiondown="2"
                  :conditionup="-38"
                  :options="computedTableAction(i, index)"
                  :data="index.toString()"
                  :show="i.context == true"
                  @chosen="closeCrudContextMenu($event, i, index)"
                />
              </i>
            </span>
          </hlx-table-cell>
          <!-- <span class="category-line-container"> -->

          <!-- </span> -->
        </tr>
      </template>
    </hlx-table>
  </div>
  <div
    v-if="tableData.length > 0 && switchvalue === 'Tree'"
    style="height: 72vh"
  >
    <hlx-tree-v2
    v-if="staticData"
    :labelvalue="treeLangData"
    :optiondata="treeList"
    :displaydata="'display'"
      :nodes="treedata"
      :direction="tree_direction"
      :zoomable="true"
      :zoom="zoom"
      :dropdown2data1="organizationList"
      :type="typeTree"
      :is-draggable="true"
      @updated-data="dataReceived"
      @card-node="CardData"
      @currentdata="addchild"
      @specification="setvalue"
      @currenteditdata="editCardHierarchy"
      @context="fromAddContextMenuHierarchy"
    >
    </hlx-tree-v2>
  </div>
  <!-- <hlx-modal
    v-if="module != 'PCM'"
    :modal-active="showAddHierarchyModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'210px'"
    :width="'340px'"
    :modal="true"
    @close="closeAddHierarchyModal"
  >
    <template #header>
      <div v-if="addParent === true">Add parent</div>
      <div v-if="addChild === true">Add child</div>
    </template>
    <template #content>
      <div class="modal-content">
        <hlx-select
          :key="selectKey"
          v-model="selectedHierarchyAccount"
          :options="organizationList"
          :search-image-tag="false"
          prop-value="name"
          :inline-search="true"
          :placeholder-value="'Choose an account'"
          :label-animation="true"
          :clearable="true"
          @selected-value="selectedHierarchyAccountDetails"
        ></hlx-select>
      </div>
    </template>
    <template #footer>
      <hlx-button
        class="secondary sm"
        @click="closeAddHierarchyModal"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        @click="updateHierarchy"
        >Add</hlx-button
      >
    </template>
  </hlx-modal> -->

  <hlx-modal
    :modal-active="showRemoveHierarchyModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'210px'"
    :width="'340px'"
    :modal="true"
    @close="closeRemoveHierarchyModal"
  >
    <template #header>
      <div>
        <!-- Remove from Hierarchy -->
        <span v-if="staticData">{{ staticData?.removeFromHierarchy?.label[loginUserDetails]?.label }}</span>
      </div>
    </template>
    <template #content>
      <div v-if="module == 'CM'" class="modal-content">
        <!-- Are you sure you want to remove this organization from hierarchy? -->
        <span v-if="staticData">{{ staticData?.cmHierarchyModalMessage1?.label[loginUserDetails]?.label }}</span>
      </div>
      <div v-else-if="module == 'PCM'" class="modal-content">
        <!-- Are you sure you want to remove this category from hierarchy? -->
        <span v-if="staticData">{{ staticData?.cmHierarchyModalMessage2?.label[loginUserDetails]?.label }}</span>
      </div>
    </template>
    <template #footer>
      <hlx-button class="secondary sm" @click="closeRemoveHierarchyModal"
        >
        <!-- Cancel -->
        <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
      <hlx-button
        v-if="module == 'CM'"
        class="primary sm"
        style="margin-left: 10px"
        @click="removeHierarchy"
        >
        <!-- Remove -->
        <span v-if="staticData">{{ staticData?.remove?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
      <hlx-button
        v-else-if="module == 'PCM'"
        class="primary sm"
        style="margin-left: 10px"
        @click="removeHierarchy"
        >
        <!-- Delete -->
        <span v-if="staticData">{{ staticData?.delete?.label[loginUserDetails]?.label }}</span>
        </hlx-button
      >
    </template>
  </hlx-modal>
  <div class="add-category-drawer">
    <hlx-drawer
      v-if="module == 'PCM'"
      :key="drawerKey"
      :show="showAddHierarchyModal"
      :width="'400'"
      position="right"
      :footer="true"
      :header="true"
      :show-close-icon="true"
      @close="closeAddHierarchyModal"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          <b v-if="addParent == true">
            <!-- Add parent category -->
            <span v-if="staticData">{{ staticData?.addParentCategory?.label[loginUserDetails]?.label }}</span>
          </b>
          <b v-if="addChild == true">
            <!-- Add child category -->
            <span v-if="staticData">{{ staticData?.addChildCategory?.label[loginUserDetails]?.label }}</span>
          </b>
          <b v-if="addSibiling == true">
            <!-- Add category -->
            <span v-if="staticData">{{ staticData?.addCategory?.label[loginUserDetails]?.label }}</span>
          </b>
          <b v-if="editCategory == true">
            <!-- Edit category -->
            <span v-if="staticData">{{ staticData?.editCategory?.label[loginUserDetails]?.label }}</span>
          </b>
        </div>
        <div class="showAddHierarchyModal-side-panel">
          <!-- {{ addSibiling }} -->
          <span class="showAddHierarchyModal-side-panel-each-section">
            <!-- {{ categoryDropdown }} -->

            <!-- <hlx-select
        :options="categoryDropdown"
        :placeholder-value="'Category name'"
        :label="'name'"
        :prop-value="'_id'"
        :label-animation="true"
        :search-image-tag="false"
        :inline-search="true"
        @selected-value="emitParentcategory"
      >
      </hlx-select> -->
            <span v-if="addSibiling == true" class="hierarchy-sidebar-details">
              <span>
                <!-- Create the category -->
                <span v-if="staticData">{{ staticData?.createTheCategory?.label[loginUserDetails]?.label }}</span>
              </span>
            </span>
            <span v-if="addParent == true" class="hierarchy-sidebar-details">
              <span>
                <!-- Create the parent category for, -->
                <span v-if="staticData">{{ staticData?.cmHierarchyDrawerMessage1?.label[loginUserDetails]?.label }}</span>
              </span>
              <span class="hierarchy-sidebar-details-link-color">{{
                dataName
              }}</span>
            </span>
            <span v-if="addChild == true" class="hierarchy-sidebar-details">
              <span>
                <!-- Create the child category for, -->
                <span v-if="staticData">{{ staticData?.cmHierarchyDrawerMessage2?.label[loginUserDetails]?.label }}</span>
              </span>
              <span class="hierarchy-sidebar-details-link-color">{{
                dataName
              }}</span>
            </span>
            <span v-if="editCategory == true" class="hierarchy-sidebar-details">
              <span>
                <!-- Edit the category for, -->
                <span v-if="staticData">{{ staticData?.cmHierarchyDrawerMessage3?.label[loginUserDetails]?.label }}</span>
              </span>
              <span class="hierarchy-sidebar-details-link-color">{{
                dataName
              }}</span>
            </span>
          </span>
          <span class="showAddHierarchyModal-side-panel-each-section">
            <hlx-input
              v-if="addParent == true && staticData?.pleaseFillField?.label[loginUserDetails]?.label"
              v-model:value="payload.name"
              :required="true"
              :label-animation="true"
              :display-error="false"
              :custom-error="nameError"
              :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
              label-value="Name"
              type="text"
              :clearable="false"
            />
            <hlx-input
              v-if="addChild == true && staticData?.pleaseFillField?.label[loginUserDetails]?.label"
              v-model:value="childPayload.child.name"
              :required="true"
              :display-error="false"
              :custom-error="nameError"
              :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
              :label-animation="true"
              label-value="Name"
              type="text"
              :clearable="false"
            />
            <hlx-input
              v-if="addSibiling == true && staticData?.pleaseFillField?.label[loginUserDetails]?.label"
              v-model:value="addPayload.name"
              :label-animation="true"
              :required="true"
              :display-error="false"
              :custom-error="nameError"
              :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
              label-value="Name"
              type="text"
              :clearable="false"
            />
            <hlx-input
              v-if="editCategory == true && staticData?.pleaseFillField?.label[loginUserDetails]?.label"
              v-model:value="editPayload.name"
              :required="true"
              :display-error="false"
              :custom-error="nameError"
              :custom-error-message="staticData?.pleaseFillField?.label[loginUserDetails]?.label"
              :pre-val="editPayload.name"
              :label-animation="true"
              label-value="Name"
              type="text"
              :clearable="false"
            />
          </span>
          <span class="showAddHierarchyModal-side-panel-each-section">
            <hlx-select
              v-if="addParent == true"
              v-model:value="payload.identifier"
              :pre-value="payload.identifier"
              :options="identifier"
              :placeholder-value="'Identifier'"
              :label="'name'"
              :prop-value="'name'"
              :label-animation="true"
              :search-image-tag="false"
              :clearable="true"
              :inline-search="true"
            >
            </hlx-select>
            <hlx-select
              v-else-if="addSibiling == true"
              v-model:value="addPayload.identifier"
              :pre-value="addPayload.identifier"
              :options="identifier"
              :placeholder-value="'Identifier'"
              :label="'name'"
              :prop-value="'name'"
              :label-animation="true"
              :search-image-tag="false"
              :clearable="true"
              :inline-search="true"
            >
            </hlx-select>
            <hlx-select
              v-else-if="editCategory == true"
              v-model:value="editPayload.identifier"
              :pre-value="editPayload.identifier"
              :options="identifier"
              :placeholder-value="'Identifier'"
              :label="'name'"
              :prop-value="'name'"
              :label-animation="true"
              :search-image-tag="false"
              :clearable="true"
              :inline-search="true"
            >
            </hlx-select>
            <hlx-select
              v-else
              v-model:value="childPayload.child.identifier"
              :pre-value="childPayload.child.identifier"
              :options="identifier"
              :placeholder-value="'Identifier'"
              :label="'name'"
              :prop-value="'name'"
              :label-animation="true"
              :search-image-tag="false"
              :clearable="true"
              :inline-search="true"
            >
            </hlx-select>
          </span>
          <span class="showAddHierarchyModal-side-panel-each-section">
            <hlx-input
              v-if="addParent == true"
              v-model:value="payload.description"
              :label-animation="true"
              label-value="Description"
              type="textarea"
              :clearable="true"
            />
            <hlx-input
              v-else-if="addSibiling == true"
              v-model:value="addPayload.description"
              :label-animation="true"
              label-value="Description"
              type="textarea"
              :clearable="true"
            />
            <hlx-input
              v-else-if="editCategory == true"
              v-model:value="editPayload.description"
              :pre-val="editPayload.description"
              :label-animation="true"
              label-value="Description"
              type="textarea"
              :clearable="true"
            />
            <hlx-input
              v-else
              v-model:value="childPayload.child.description"
              :label-animation="true"
              label-value="Description"
              type="textarea"
              :clearable="true"
            />
          </span>
          <span class="showAddHierarchyModal-side-panel-each-section">
            <p class="title">
              <!-- Type -->
              <span v-if="staticData">{{ staticData?.type?.label[loginUserDetails]?.label }}</span>
            </p>
            <hlx-input
              v-if="addParent == true"
              :position="'horizontal'"
              :options="radioOptions"
              :type="'radio-buttons'"
              @selected="emitRadio"
            />
            <hlx-input
              v-else-if="addSibiling == true && editCategory == true"
              :key="drawerkey"
              :position="'horizontal'"
              :options="radioOptions"
              :type="'radio-buttons'"
              @selected="emitRadio"
            />
            <hlx-input
              v-else
              :position="'horizontal'"
              :options="radioOptions"
              :type="'radio-buttons'"
              @selected="emitRadio"
            />
          </span>
          <span class="showAddHierarchyModal-side-panel-each-section">
            <p class="title">
              <!-- Pricing type -->
              <span v-if="staticData">{{ staticData?.pricingType?.label[loginUserDetails]?.label }}</span>
              <sup>*</sup></p>
            <hlx-input
              v-if="addParent == true"
              :position="'horizontal'"
              :options="checkboxOptions"
              :type="'checkbox'"
              @selected="emitCheckbox"
            />
            <span v-else-if="addSibiling == true && editCategory == true">
              <hlx-input
                :position="'horizontal'"
                :options="checkboxOptions"
                :type="'checkbox'"
                @selected="emitCheckbox"
              />
            </span>
            <span v-else style="display: flex; flex-direction: column">
              <hlx-input
                :position="'horizontal'"
                :options="checkboxOptions"
                :type="'checkbox'"
                @selected="emitCheckbox"
              />
              <p
                v-if="pricingtypeError == true"
                class="red"
                style="color: rgb(255, 80, 80)"
              >
                <!-- Please select the atleast one pricing type -->
                <span v-if="staticData">{{ staticData?.cmHierarchyMessage1?.label[loginUserDetails]?.label }}</span>
              </p>
            </span>
          </span>
          <span class="showAddHierarchyModal-side-panel-each-section">
            <div
              class="showAddHierarchyModal-side-panel-each-section-last-section"
            >
              <p class="title">
                <!-- Cannot be sold alone -->
                <span v-if="staticData">{{ staticData?.cmHierarchyMessage2?.label[loginUserDetails]?.label }}</span>
              </p>
              <hlx-input v-model:value="sellable" :checked="sellable" :type="'switch'" />
            </div>
          </span>
        </div>
      </template>
      <template #footer>
        <div class="category-apply-button">
          <hlx-button class="primary" @click="emitPayload">
            <!-- Apply -->
            <span v-if="staticData">{{ staticData?.apply?.label[loginUserDetails]?.label }}</span>
          </hlx-button>
        </div>
      </template>
    </hlx-drawer>
  </div>

  <div class="add-contact-drawer">
    <hlx-drawer
      v-if="module == 'CM'"
      :show="showAddHierarchyModal"
      position="right"
      width="400"
      :footer="true"
      :show-close-icon="true"
      @close="closeAddHierarchyModal"
    >
      <template #body>
        <div class="header-area-contact-drawer">
          <!-- <span
            style="
              display: flex;
              width: 25px;
              height: 25px;
              position: fixed;
              right: 400px;
              background: white;
              z-index: 1000;
              top: 0;
              align-items: center;
              justify-content: center;
              border-top-left-radius: 3px;
              border-bottom-left-radius: 3px;
              box-shadow: -2px 0px 6px 0px #b9b6b6;
              cursor: pointer;
            "
            @click="closeAddHierarchyModal"
          >
            <i class="icon-times-regular"></i>
          </span> -->
          <b>
            <!-- Link a child -->
            <span v-if="staticData">{{ staticData?.linkChild?.label[loginUserDetails]?.label }}</span>
          </b>
        </div>
        <div class="body-area-contact-drawer">
          <div
            style="
              padding: 10px;
              background: #d9d9d93d;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              flex-wrap: wrap;
              margin-bottom: 20px;
            "
          >
            <span>
               <!-- Map a child organization for  -->
               <span v-if="staticData">{{ staticData?.cmHierarchyMessage3?.label[loginUserDetails]?.label }}</span>
              </span>
            <span style="color: #265e4b; margin-top: 4px">{{
              hierarchyPayload.parent.name
            }}</span>
          </div>
          <hlx-select
            v-if="!createAccountAndLink && staticData"
            :key="refreshSelect"
            v-model:value="selectedHierarchyAccount"
            :pre-value="selectedHierarchyAccount"
            :custom-search="true"
            :options="organizationList"
            :search-image-tag="false"
            :prop-value="'customName'"
            :label="'customName'"
            :inline-search="true"
            :placeholder-value=" staticData?.searchChildAccount?.label[loginUserDetails]?.label || 'Search child account'"
            :label-animation="true"
            :clearable="true"
            :footer-icon="'icon-plus-circle-regular'"
            :footer-content=" staticData?.addNewAccount?.label[loginUserDetails]?.label || 'Add new account'"
            :footer="true"
            @search-value="searchHAccount"
            @dropdownopen="searchHAccount"
            @selected-value="selectedHierarchyAccountDetails"
            @footer-click="createAccountAndLinkFunc"
          ></hlx-select>
          <div v-else>
            <div class="decision-info-left-pannel" style="gap: 0">
              <span
                class="title decision-title section-title"
                style="margin-top: 0.5rem"
                >
                <!-- Add new account -->
                <span v-if="staticData">{{ staticData?.addNewAccount?.label[loginUserDetails]?.label }}</span>
                </span
              >

              <div
                class="decision-name-wrapper"
                :style="{ 'margin-bottom': customnameerror ? '-7.6%' : 0 }"
                style="margin-bottom: 1rem"
              >
                <hlx-input
                  v-model:value="Hpayload.customName"
                  :pre-val="Hpayload.customName"
                  :label-value="'Account name'"
                  :label-animation="true"
                  :required="true"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
              </div>
              <div class="decision-name-wrapper" style="margin-bottom: 1rem">
                <!-- <hlx-select
                  :key="drawerKey"
                  v-model="Hpayload.status"
                  :pre-value="Hpayload.status"
                  :options="statusList"
                  prop-value="name"
                  :inline-search="true"
                  :search-image-tag="false"
                  :placeholder-value="'Status'"
                  :label-animation="true"
                  :clearable="true"
                  @search-value="newValFromDropDown($event, 'status')"
                ></hlx-select> -->

                <div style="width: 100%">
                  <hlx-status
                    :type="'select-status'"
                    :status-option="filteredstatus"
                    :prevalue="Hpayload.status"
                    @emit-select="emitStatus1"
                  >
                  </hlx-status>
                </div>
              </div>
              <div class="decision-name-wrapper" style="margin-bottom: 1rem">
                <!-- <hlx-input
                  :key="drawerKey"
                  v-model:value="Hpayload.contactMedium[3].description"
                  :pre-val="Hpayload.contactMedium[3].description"
                  type="location"
                  :params="locationApi"
                  :label-value="'Address'"
                  :label-animation="true"
                  :show-new-address-option="showNewAddressOption"
                  :custom-error="customnameerror"
                  :custom-error-message="'Please fill the field'"
                  :location-data-prop="locationData"
                  @focus-out="focusOutName"
                  @at-input="getLocations"
                  @add-new-location="addNewLocation"
                ></hlx-input> -->
                <addressComponent
                  :pre-val="addressPreVal"
                  @address-data="addressData"
                ></addressComponent>
              </div>

              <!-- <div class="decision-name-wrapper">
                <hlx-input
                  :key="drawerKey"
                  v-model:value="Hpayload.contactMedium[1].phoneNumber"
                  :pre-val="Hpayload.contactMedium[1].phoneNumber"
                  type="number"
                  :label-value="'Phone no.'"
                  :label-animation="true"
                  :display-error="customnameerror"
                  :custom-error="customnameerror"
                  :custom-error-message="'Please fill the field'"
                  @focus-out="focusOutName"
                  @at-input="validateName"
                ></hlx-input>
              </div> -->
              <div class="decision-name-wrapper">
                <hlx-input
            v-if="staticData"
                  v-model:value="
                    Hpayload.contactMedium[0].characteristic.emailAddress
                  "
               :default-mail-error="staticData?.pleaseEnterValidUrl?.label[loginUserDetails]?.label"
                  :pre-val="
                    Hpayload.contactMedium[0].characteristic.emailAddress
                  "
                  type="email"
                  :label-value="'Email id'"
                  :required="true"
                  :label-animation="true"
                  :display-error="true"
                  :custom-error="isExistingEmail"
                  :custom-error-message="isExistingEmailErrorMessage"
                  @focus-out="validateEmailNew"
                  @at-input="validateName"
                ></hlx-input>
              </div>
              <!-- <div class="decision-name-wrapper">
                <hlx-input
                  :key="drawerKey"
                  v-model:value="Hpayload.contactMedium[2].faxNumber"
                  :pre-val="Hpayload.contactMedium[2].faxNumber"
                  :label-value="'Fax'"
                  :label-animation="true"
                  :display-error="customnameerror"
                  :custom-error="customnameerror"
                  :custom-error-message="'Please fill the field'"
                  @focus-out="focusOutName"
                  @at-input="validateName"
                ></hlx-input>
              </div> -->
              <!-- <div class="decision-name-wrapper">
                <hlx-input
                  :key="drawerKey"
                  v-model:value="Hpayload.externalReference[0].name"
                  :pre-val="Hpayload.externalReference[0].name"
                  :label-value="'Website'"
                  :label-animation="true"
                  :display-error="customnameerror"
                  :custom-error="customnameerror"
                  :custom-error-message="'Please fill the field'"
                  @focus-out="focusOutName"
                  @at-input="validateName"
                ></hlx-input>
              </div> -->
              <!-- <div class="decision-name-wrapper">
                <hlx-select
                  :key="drawerKey"
                  v-model:value="Hpayload.preferred"
                  :pre-value="Hpayload.preferred"
                  :options="preferredMediumList"
                  prop-value="value"
                  :placeholder-value="'Preferred contact medium'"
                  :label-animation="true"
                  :clearable="true"
                ></hlx-select>
              </div> -->
              <div class="decision-name-wrapper" style="margin-bottom: 1rem">
                <hlx-select
                  v-model:value="
                    Hpayload.contactMedium[3].characteristic['@type']
                  "
                  :pre-value="Hpayload.contactMedium[3].characteristic['@type']"
                  :options="locationTypeList"
                  prop-value="name"
                  :placeholder-value="'Location type'"
                  :label-animation="true"
                  :clearable="true"
                  :inline-search="true"
                  :search-image-tag="false"
                  @search-value="newValFromDropDown($event, 'locationType')"
                ></hlx-select>
              </div>
              <div class="decision-name-wrapper">
                <hlx-select
                  :key="drawerKey"
                  v-model:value="accExecParty"
                  :pre-value="accExecParty"
                  :options="executivesList"
                  :placeholder-value="'Account executive'"
                  :label="'name'"
                  :prop-value="'name'"
                  :label-animation="true"
                  :search-image-tag="true"
                  :sub-title="'company'"
                  :tag-value="'newRole'"
                  :image-link="'link'"
                  :inline-search="true"
                  @selected-value="selectedExecutive"
                >
                </hlx-select>
              </div>
              <!-- <div class="decision-name-wrapper">
                <hlx-select
                  :key="drawerKey"
                  v-model="Hpayload.partyCharacteristic[1].value"
                  :pre-value="Hpayload.partyCharacteristic[1].value"
                  :options="industryTypeList"
                  prop-value="name"
                  :placeholder-value="'Industry'"
                  :label-animation="true"
                  :clearable="true"
                  :inline-search="true"
                  :search-image-tag="false"
                  @search-value="newValFromDropDown($event, 'industry')"
                ></hlx-select>
              </div> -->
              <!-- <div class="decision-name-wrapper">
                <hlx-select
                  :key="drawerKey"
                  v-model="Hpayload.partyCharacteristic[0].value"
                  :pre-value="Hpayload.partyCharacteristic[0].value"
                  :options="employeeCountList"
                  prop-value="name"
                  :placeholder-value="'Employees'"
                  :label-animation="true"
                  :clearable="true"
                  :inline-search="true"
                  :search-image-tag="false"
                  @search-value="newValFromDropDown($event, 'employeeCount')"
                ></hlx-select>
              </div> -->

              <!-- <div class="decision-name-wrapper">
                <hlx-multi-select
                  :key="drawerKey"
                  v-model:value="Hpayload.tags"
                  :pre-value="Hpayload.tags"
                  :options="tagsList"
                  prop-value="name"
                  :multi-select="true"
                  :placeholder="'Tags'"
                  :label-animation="true"
                  :clearable="true"
                  @add-data="addTagList"
                  @selected-value="receivedTags"
                ></hlx-multi-select>
              </div> -->
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeAddHierarchyModal"
            >
            <!-- Cancel -->
            <span v-if="staticData">{{ staticData?.cancel?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
          <hlx-button
            v-if="!createAccountAndLink"
            class="primary sm"
            style="margin-left: 10px"
            :disabled="selectedHierarchyAccount !== '' ? false : true"
            @click="updateHierarchy"
            >
            <!-- Link -->
            <span v-if="staticData">{{ staticData?.link?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
          <hlx-button
            v-if="createAccountAndLink"
            class="primary sm"
            style="margin-left: 10px"
            :disabled="!canAdd"
            @click="createAccountAndUpdateHierarchy"
            >
            <!-- Add and Link -->
            <span v-if="staticData">{{ staticData?.addAndLink?.label[loginUserDetails]?.label }}</span>
            </hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>
  <hlx-drawer
    :show="show_right"
    :width="1000"
    position="right"
    :footer="false"
    :show-close-icon="true"
    @close="closeactivitylog"
  >
    <template #body>
      <div class="activitylog_drawer_componet">
        <activityLogComponent
          :key="activtykey"
          :activity-data="activityLogData"
          :totalfromprop="activitytotal"
          :module="'leftPanal'"
          @activityrowpage="activityrowpage1"
          @activitycurrentpage="activitycurrentpage1"
        ></activityLogComponent>
      </div>
    </template>
  </hlx-drawer>
</template>

<script>
import FilterComponent from "../../components/filterPanelComponent.vue";
import { DMNAxiosInstance, MLTAxiosInstance, UPCAxiosInstance } from "@/config/axiosConfig";
import activityLogComponent from "../SM/activityLogComponent.vue";
import addressComponent from "../CM/autocompleteComponent.vue";
export default {
  components: {
    addressComponent,
    activityLogComponent,
    FilterComponent
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    module: {
      type: String,
      default: "CM",
    },
    mode: {
      type: String,
      default: "edit",
    },
    accountStatus: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "updated-hierarchy",
    "payload",
    "category",
    "delete",
    "edit-category",
    "drag-and-drop",
    "drag-child",
    "drag-sibilng",
    "initialPayload",
    "emit-search"
  ],
  data() {
    return {
      treeList:{
          Link_a_child:"Link a child",
          Remove: "Remove",
          Delete: "Delete",
          Add: "Add",
          Edit: "Edit"
        },
      treeLangData:{
          Add_Specification:"Add Specification",
          Edit_Specification: "Edit Specification",
          Specification:"Specification",
          Delete_data : "Delete data",
          content: "Are you sure you want to delete",
          Cancel: "Cancel",
          Delete: "Delete",
          Save: "Save",
          Add: "Add"
        },
      loginUserDetails: {},
      staticData:null,
      displayFilterData: {},
      show_right_filter:false,
      filtertaglength:0,
      searchvalue:'',
      sellable:false,
      isExistingName: false,
      isExistingNameErrorMessage: "",
      isExistingEmailErrorMessage: "",
      isExistingEmail: false,
      initialData: [],
      updatedData: [],
      statusList: [],
      filteredstatus: [],
      activityLogData: [],
      isDraggable: false,
      findDrag: 0,
      findDragIndexLevel: 0,
      // prevdata
      editPayload: {},
      nameError: false,
      typeError: false,
      pricingtypeError: false,
      company: "",
      categoryquery: {
        type: "filter",
        module: "productcategory",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: false,
        sortFieldAndOrder: {
          // "name":-1
        },
        limit: 10,
        filterQuery: {
        },
        searchQuery: "",
        searchList: [
        {
            name: {
                $regex: "Tangible","$options":"i"
            }
        },
        {
            type: {
                $regex: "Tangible","$options":"i"
            }
        }
    ],
        company: "",
      },
      manipulationType: "",
      // removedItems:{},
      hierarchyData: [],
      startIndex: 0,
      enterIndex: -1,
      leaveIndex: 0,
      identifier: [],
      indexPayload: {},
      refreshSelect: 0,
      editCategory: false,
      componentkey: 0,
      dataName: "",
      dataType: "",
      dataID: "",
      childPayload: {
        child: {
          name: "",
          identifier: "",
          description: "",
          type: "",
          pricingType: [],
        },
      },
      payload: {
        isRoot: true,
        name: "",
        identifier: "",
        description: "",
        type: "Tangible",
        pricingType: [],
        subCategory: [],
        isSellabe: false,
      },
      addPayload: {
        isRoot: true,
        name: "",
        identifier: "",
        description: "",
        type: "Tangible",
        pricingType: [],
        isSellabe: false,
      },
      addSibiling: false,
      categoryDropdown: [],
      radioOptions: [
        { name: "Tangible", checked: true, disabled: false, id: 1 },
        { name: "Intangible", checked: false, disabled: false, id: 2 },
      ],
      activtykey: 0,
      Hpayload: {
        name: "",
        customName: "",
        tradingName: "",
        status: "Active",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        relatedParty: [],
        tags: ["Active"],
        role: "Root admin",
        type: "company",
        password: "",
        email: "",
        organizationParentRelationship: {},
        rootName: "",
      },
      createAccountAndLink: false,
      // componentkey: 0,
      // dataType: '',
      // dataID: '',
      // childPayload: {
      //   child: {
      //     id: '',
      //     name: '',
      //     identifier: '',
      //     description: '',
      //     type: '',
      //     pricingType: [],
      //   },
      // },
      // payload: {
      //   name: '',
      //   identifier: '',
      //   description: '',
      //   type: '',
      //   pricingType: [],
      // },
      // addPayload: {
      //   name: '',
      //   identifier: '',
      //   description: '',
      //   type: '',
      //   pricingType: [],
      // },
      // addSibiling: false,
      // categoryDropdown: [],
      // radioOptions: [
      //   { name: 'Tangible', checked: false, disabled: false, id: 1 },
      //   { name: 'Intangible', checked: false, disabled: false, id: 2 },
      // ],
      checkboxOpt: [
        "Recurring",
        "One time",
        "Installment",
        "Rental",
        "Pay per use",
      ],
      checkboxOptions: [
        { name: "One time", checked: false, disabled: false, id: 3 },
        { name: "Installment", checked: false, disabled: false, id: 2 },
        { name: "Rental", checked: false, disabled: false, id: 2 },
      ],

      pricingTypeId: null,

      notification: {},
      typeTree: "Hierarchy",
      selectedSwitch: "table",
      switchOptions: [
        {
          name: "Table",
          value: "table",
        },
        {
          name: "Tree",
          value: "tree",
        },
      ],
      levelCounter: {},
      tableData: [],
      accounts_table_theads: [],
      accounts_table_theads_cc: [],
      duplicateTheads: [],
      re_arrange: false,
      currPage: 1,
      rowsPerPage: 10,
      actualTableData: [],
      rawData: [],
      organizationList: [],
      showAddHierarchyModal: false,
      showRemoveHierarchyModal: false,
      addChild: false,
      addParent: false,
      selectedHierarchyAccount: "",
      hierarchyPayload: {
        parent: {
          id: "",
          name: "",
        },
        child: {
          id: "",
          name: "",
        },
        rootName: "",
        rootIndex: false,
        selectKey: 0,
        removeHierarchyIndex: 0,
        switchvalue: "",
      },
      hierarchyTree: {
        parent: {
          id: "",
          name: "",
        },
        child: [
          {
            id: "",
            name: "",
          },
        ],
        rootName: "",
        rootIndex: false,
        selectKey: 0,
        removeHierarchyIndex: 0,
        switchvalue: "",
        locationData: [],
        showNewAddressOption: false,
        executivesList: [],
        preferredMediumList: [],
        industryTypeList: [],
        employeeCountList: [],
        locationTypeList: [],
        tagsList: [],
        drawerKey: 0,
      },
      excludedAccounts: [],
      accExecParty: "",
      canAdd: false,
      show_right: false,
      activityrowpage: 10,
      activitycurrentpage: 1,
      activitytotal: 0,
      logpayload: {
        type: "filter",
        module: "log",
        lastUpdated: new Date().toJSON(),
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          category: {
            $in: ["category"],
          },
        },
        searchQuery: "",
      },
    };
  },
  computed: {
    tableModalLang() { 
      if (this.staticData) {      
      return {
          re_arrange_columns : this.staticData?.rearrangeColumns?.label[this.loginUserDetails]?.label,
          Cancel: this.staticData?.cancel?.label[this.loginUserDetails]?.label,
          Apply: this.staticData?.apply?.label[this.loginUserDetails]?.label,
          SelectAll: this.staticData?.selectAll?.label[this.loginUserDetails]?.label,
        }
      } else {
        return {
          re_arrange_columns : "Re-arrange columns",
          Cancel: "Cancel",
          Apply: "Apply",
          SelectAll: "Select all",
        }
      }
    },
    paginatedData() {
      return this.tableData;
    },
    pricingTypeData: {},
  },
  watch: {
    staticData: {
      handler(val) {
        console.log(val, "staticData watching000000000000");
        if (val) {
          this.switchOptions =
            [
        {
          name: "Table",
                value: "table",
    label: this.staticData?.table?.label[this.loginUserDetails]?.label      
        },
        {
          name: "Tree",
          value: "tree",
          label: this.staticData?.tree?.label[this.loginUserDetails]?.label
        }
            ]   

          this.treeLangData = {
            Add_Specification: this.staticData?.addSpecification?.label[this.loginUserDetails]?.label || "Add Specification",

            Edit_Specification: this.staticData?.editSpecification?.label[this.loginUserDetails]?.label || "Edit Specification",

            Specification: this.staticData?.specification?.label[this.loginUserDetails]?.label || "Specification",

            Delete_data: this.staticData?.deleteData?.label[this.loginUserDetails]?.label || "Delete data",

            content: this.staticData?.cmAttachmentModalMessage1?.label[this.loginUserDetails]?.label || "Are you sure you want to delete",

            Cancel: this.staticData?.cancel?.label[this.loginUserDetails]?.label || "Cancel",

            Delete: this.staticData?.delete?.label[this.loginUserDetails]?.label || "Delete",

            Save: this.staticData?.save?.label[this.loginUserDetails]?.label || "Save",

            Add: this.staticData?.add?.label[this.loginUserDetails]?.label || "Add"
          };

          this.treeList =

          {

            Link_a_child: this.staticData?.linkChild?.label[this.loginUserDetails]?.label || "Link a child",
          
            Remove: this.staticData?.remove?.label[this.loginUserDetails]?.label || "Remove",
          
            Delete: this.staticData?.delete?.label[this.loginUserDetails]?.label || "Delete",
          
            Add: this.staticData?.add?.label[this.loginUserDetails]?.label || "Add",
          
            Edit: this.staticData?.edit?.label[this.loginUserDetails]?.label || "Edit"
          
        }
        }
      },
      immediate: true,
      deep: true,
    },
    statusList: {
      handler(val) {
        console.log(val, "statuswatching000000000000");
        const filteredstatuslist = this.statusList.filter(
          (item) => item.name === "Active" || item.name === "Inactive"
        );
        this.filteredstatus = filteredstatuslist;
        console.log("filtered11111111111111111", this.filteredstatus);
      },
      immediate: true,
      deep: true,
    },
    tableData: {
      async handler(val) {
        this.excludedAccounts = val.map((obj) => obj.name);
        if (this.excludedAccounts.length > 0) {
          const f = {
            company: this.company,
            type: "filter-search",
            module: "hierarchyaccounts",
            isPaginated: true,
            paginatedQuery: {
              rowsPerPage: 5,
              currentPage: 1,
            },
            isNeedToBeSort: true,
            sortFieldAndOrder: {},
            limit: 2,
            filterQuery: {
              name: {
                $nin: [this.company],
              },
              tradingName: {
                $nin: this.excludedAccounts,
              },
              "@baseType": {
                $ne: "supplier",
              },
            },
            searchQuery: "",
          };
          let data = await MLTAxiosInstance.post(`util/filterData`, f);
          console.log("dropdown data", data);
          this.organizationList = data.data.data;
          console.log(this.excludedAccounts);
        }
      },
      immediate: true,
      deep: true,
    },
    paginatedData: {
       handler(val) {
       console.log(val,"tableData value",this.data);

      },
      immediate: true,
      deep: true,
    },
    Hpayload: {
      handler(val) {
        console.log(val);
        val["@baseType"] = "customer";
        val.name = this.sanitizeDatabaseName(val.customName);
        val.companyName = this.sanitizeDatabaseName(val.customName);
        val.tradingName = this.sanitizeDatabaseName(val.customName);
        val.rootName = this.company;
        val.parentCompany = this.company;
        val.email = val.contactMedium[0].characteristic.emailAddress;
        // console.log('val before watch', this.executivesList);
        // let accExec = this.executivesList.filter((item) => {
        //   return item.name === val.accountExecutive ? item : undefined;
        // });
        // val.relatedParty.splice(0, 1);
        // val.relatedParty.push(accExec[0]);
        // console.log('val after watch', val.relatedParty);
        for (let i = 0; i < val.contactMedium.length; i++) {
          if (val.contactMedium[i].mediumType === val.preferred) {
            val.contactMedium[i].preferred = true;
          } else {
            val.contactMedium[i].preferred = false;
          }
        }
        val.relatedParty.filter((item) => {
          if (item.role === "Account executive") {
            this.accExecParty = item.name;
          }
        });

        if (
          val.name !== undefined &&
          val.name.length > 0 &&
          this.validateEmail(val.contactMedium[0].characteristic.emailAddress)
        ) {
          this.canAdd = true;
        } else {
          this.canAdd = false;
        }
        // console.log(this.payload);
      },
      immediate: true,
      deep: true,
    },
    tree_direction: function () {
      this.tree_direction;
    },
    selectedHierarchyAccount: {
      handler(v) {
        this.selectedHierarchyAccountFunc(v);
      },
      immediate: true,
      deep: true,
    },
    data: {
      handler(val) {
        console.log(val, "dkddk");
        if (val.length > 0) {
          this.rawData = JSON.parse(JSON.stringify(val));
          this.addLevelToStructure(this.rawData);
          this.tableData = this.flattenAndIdentifyChildren(val);
          this.tableData?.forEach((e) => {
            if (e.context != undefined) {
              e.context = false;
            } else {
              e.context = false;
            }
          });
          this.actualTableData = JSON.parse(JSON.stringify(this.tableData));
          this.treedata = this.rawData[0];
        }
      },
      immediate: true,
      deep: true,
    },
    checkboxOptions: {
      handler(val) {
        console.log(val, "inputt");
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    await this.$store.dispatch("loginTenantDetails");
    await this.$store.dispatch("loginUserDetails");
    // loginUserDetails
    // await this.$store.dispatch("loginTenantDetails");
    // let r = await this.$store.getters.loginTenantDetails;
    console.log("loginTenantDetails resrfsef:", this.$store.getters.loginTenantDetails);
    console.log("loginUserDetails resrfsef:", this.$store.getters.loginUserDetails);

    this.loginUserDetails = this.$store?.getters?.loginUserDetails?.languageAbility[0]?.code;

    // this.loginUserDetails.languageAbility[0].name
    // this.loginUserDetails.languageAbility[0].code

    try {
      let organisationInfo = await UPCAxiosInstance.get('/multi-language');
      this.staticData = organisationInfo?.data?.filter(itm => itm && itm.modules)?.[0]?.modules;
      console.log("this.staticData:",organisationInfo , this.staticData);
      
    }
    catch (error) {
      console.error("error::", error)
    }


    const value = await MLTAxiosInstance.get("partymanagement/individual/auth");
    this.company = value.data.company;
    let sampleTheads = await MLTAxiosInstance.get(`/settings`);
    if (this.module == "CM") {
      this.accounts_table_theads = [
        {
          sortable: false,
          resizable: true,
          disabled:true,
          name:"Account name",
          checked:true,
          width: 200,
          label: "Account name",
          prop: "name",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 150,
          checked:true,
          name:"Location type",
          label: "Location type",
          prop: "@type",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 450,
          checked:true,
          disabled:true,
          name:"Address",
          label: "Address",
          prop: "address",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 100,
          checked:true,
          name:"Status",
          label: "Status",
          prop: "status",
          align: "left",
        },
        // {
        //   sortable: false,
        //   resizable: true,
        //   width: 200,
        //   label: 'Account Executive',
        //   prop: 'relatedParty',
        //   align: 'left',
        // },
        // {
        //   sortable: false,
        //   resizable: true,
        //   width: 100,
        //   label: 'Contacts',
        //   prop: 'contacts',
        //   align: 'left',
        // },
      ];
      this.accounts_table_theads_cc = JSON.parse(JSON.stringify(this.accounts_table_theads))
      if(sampleTheads?.data?.entityRef?.["hierarchyCM"]){
        this.accounts_table_theads_cc = sampleTheads?.data?.entityRef["hierarchyCM"];
        this.accounts_table_theads = this.accounts_table_theads_cc.filter(ele => ele.checked);
      }
      const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
      this.company = val.data.company;
      let f2 = {
        company: `${this.company}`,
        type: "filter-search",
        module: "hierarchycontacts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 2,
        filterQuery: {
          "role.name": "Account executive",
          name: {
            $regex: "",
            $options: "i",
          },
        },
        searchQuery: "",
      };
      let x2 = await MLTAxiosInstance.post("util/filterData", f2);
      console.log(x2);

      // let exec = await MLTAxiosInstance.get(
      //   `/partymanagement/individual/listuser?company=${this.company}`
      // );
      console.log(x2);

      this.executivesList = x2.data.data;
      this.executivesList.forEach((item) => {
        item.newRole = item.role[0].name;
      });
      let statusList = await DMNAxiosInstance.get(
        `mapRef/collection/statuslist`
      );
      this.statusList = statusList.data.data;
      // let preferredList = await DMNAxiosInstance.get(
      //   `mapRef/collection/cmAccountPreferredContactMedium`
      // );
      this.preferredMediumList = await this.getReferanceData('cmAccountPreferredContactMedium');
      console.log(this.preferredMediumList);
      // let industryTypeList = await DMNAxiosInstance.get(
      //   `mapRef/collection/industryType`
      // );
      this.industryTypeList = await this.getReferanceData('industryType');
      // let employeeCountList = await DMNAxiosInstance.get(
      //   `mapRef/collection/cmAccountEmployeeCount`
      // );
      this.employeeCountList = await this.getReferanceData('cmAccountEmployeeCount');
      // let locationTypeList = await DMNAxiosInstance.get(
      //   `mapRef/collection/cmAccountLocationType`
      // );
      this.locationTypeList =  await this.getReferanceData('cmAccountLocationType');
      let tagsList = await DMNAxiosInstance.get(`mapRef/collection/tagsList`);
      this.tagsList = tagsList.data.data;
    } else if (this.module == "PCM") {
      this.accounts_table_theads = [
        {
          sortable: false,
          resizable: true,
          width: null,
          checked:true,
          disabled:true,
          name:"Name",
          label: "Name",
          prop: "name",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 300,
          checked:true,
          name:"Description",
          label: "Description",
          prop: "description",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 300,
          checked:true,
          disabled:true,
          name:"Pricing type",
          label: "Pricing type",
          prop: "pricingType",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 200,
          checked:true,
          name:"Identifier",
          label: "Identifier",
          prop: "identifier",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 300,
          checked:true,
          disabled:true,
          name:"Category type",
          label: "Category type",
          prop: "type",
          align: "left",
        },
      ];
      this.accounts_table_theads_cc = JSON.parse(JSON.stringify(this.accounts_table_theads))
      if(sampleTheads?.data?.entityRef?.["hierarchyPCM"]){
        this.accounts_table_theads_cc = sampleTheads?.data?.entityRef["hierarchyPCM"];
        this.accounts_table_theads = this.accounts_table_theads_cc.filter(ele => ele.checked);
      }
      await this.$store.dispatch("Identifier");
      this.identifier = this.$store.getters.Identifier;
      console.log(this.identifier, "lll");
      // console.log(this.identifier,'valal');
      // let dropdowndata = await UPCAxiosInstance.get(
      //   `/catalogManagement/category/dropdown`
      // );
      // this.categoryDropdown = await dropdowndata.data;
      // console.log(this.categoryDropdown,"from cm")
    } else if (this.module === "Role") {
      this.accounts_table_theads = [
        {
          sortable: false,
          resizable: true,
          width: null,
          name:"Module",
          checked:true,
          label: "Module",
          prop: "name",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 300,
          name:"View",
          checked:true,
          label: "View",
          prop: "view",
          align: "left",
        },
        {
          sortable: false,
          resizable: true,
          width: 300,
          name:"Edit",
          checked:true,
          label: "Edit",
          prop: "edit",
          align: "left",
        },
      ];
      this.accounts_table_theads_cc = JSON.parse(JSON.stringify(this.accounts_table_theads))
      if(sampleTheads?.data?.entityRef?.["hierarchyRole"]){
        this.accounts_table_theads_cc = sampleTheads?.data?.entityRef["hierarchyRole"];
        this.accounts_table_theads = this.accounts_table_theads_cc.filter(ele => ele.checked);
      }
    }
    await this.$store.dispatch("productOfferFilterData");
    this.displayFilterData = this.$store.getters.categoryFilterData;
    console.log(this.displayFilterData, "this.filter");
    // let data = await MLTAxiosInstance.get(
    //   `/partyManagement/organisation/inlineSearch?q=&size=5`
    // );
    // this.organizationList = data.data;
    // console.log(this.organizationList);
  },
  methods: {
    showDrawerRight() {
      this.show_right_filter = true;
    },
    closeRightFilter() {
      this.show_right_filter = false;
    },
    async searchKey(val){
      this.searchvalue = val
      this.categoryquery["searchList"][0]['name']['$regex'] = val
      this.categoryquery["searchList"][1]['type']['$regex'] = val
      this.categoryquery['type'] = 'search'
      this.categoryquery["company"] = this.company;
      // this.accountsquery["paginatedQuery"]["currentPage"] = currentPage;
      // this.accountsquery["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
      const filterResultData = await UPCAxiosInstance.post(
        `/catalogManagement/category/search`,
        this.categoryquery
      );
      this.$emit('emit-search',filterResultData.data)
      console.log(filterResultData,'sklf')
    },
    resizedWidth(val){
      // Update resized table header width func
      console.log(val,"resizedWidth",val)

      const updateWidth = (array) => {
    let item = array.find(i => i?.prop === val?.prop);
    if (item) item.width = val.width;
  };

  updateWidth(this.accounts_table_theads);
  updateWidth(this.accounts_table_theads_cc);
console.log(this.accounts_table_theads,"resizedWidth loop",this.accounts_table_theads_cc)
this.applyColumns(this.accounts_table_theads_cc)
    },
    selectedValcheckbox(event,val){
      console.log(event,val,"event,val");
      
    },
    async activitycurrentpage1(val) {
      console.log(val, "activitycurrentpage1..........");
      this.activitycurrentpage = val;
      console.log(val, "current");
      this.logpayload.paginatedQuery.currentPage = val;
      let activitylogdata = await MLTAxiosInstance.post(
        `util/filterData`,
        this.logpayload
      );
      console.log(
        activitylogdata.data.data,
        "123 activity log response data in offer2 current"
      );

      this.activityLogData = activitylogdata.data.data;
      this.activitytotal = activitylogdata.data.total;
    },
    async activityrowpage1(val) {
      this.activityrowpage = val;

      this.logpayload.paginatedQuery.rowsPerPage = val;
      let activitylogdata = await MLTAxiosInstance.post(
        `util/filterData`,
        this.logpayload
      );
      this.activityLogData = activitylogdata.data.data;

      this.activitytotal = activitylogdata.data.total;
    },
    async activiylogcall() {
      const logaccess = await MLTAxiosInstance.post(
        `/util/filterData`,
        this.logpayload
      );
      this.activityLogData = logaccess.data.data;
      this.activitytotal = logaccess.data.total;
      console.log(logaccess.data.data);
    },
    closeactivitylog() {
      this.show_right = false;
    },
    searchHAccount(val) {
      console.log(val, "searchHAccount");
      this.selectedHierarchyAccount = "";
    },
    emitStatus1(val1) {
      this.Hpayload.status = val1["value"];
    },
    sanitizeDatabaseName(databaseName) {
      // Replace dots with an underscore or any other symbol you want
      const sanitized = databaseName?.replace(/\./g, "_");

      // Validate the constraints for MongoDB database names
      const validDatabaseName = sanitized?.replace(/[^a-zA-Z0-9_]/g, "");

      return validDatabaseName;
    },
    validateEmail(val) {
      const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (val.length > 0) {
        if (!reg.test(val)) {
          return false; // URL is invalid
        } else {
          return true;
        }
      }
    },
    validateEmailNew() {
      console.log('hleo');
      this.isExistingEmail = false
      this.isExistingEmailErrorMessage = ''
    },
    validateNameNew() {
      console.log('hleo');
      this.isExistingName = false
      this.isExistingNameErrorMessage = ''
    },
    computedPricingType(id) {
      this.checkboxOptions = [
        { name: "Recurring", checked: false, disabled: false, id: 1 },
        { name: "One time", checked: false, disabled: false, id: 3 },
        { name: "Installment", checked: false, disabled: false, id: 2 },
        { name: "Rental", checked: false, disabled: false, id: 2 },
        { name: "Pay per use", checked: false, disabled: false, id: 2 },
      ];
      let data = this.checkboxOptions.filter((item) => {
        return id == item.id;
      });
      data.push({ name: "One time", checked: false, disabled: false, id: 3 });
      this.checkboxOptions = data;
    },
    selectedExecutive(val) {
      let obj = {};
      obj.name = val.name;
      obj.id = val._id;
      obj.role = "Account executive";
      this.Hpayload.relatedParty.push(obj);
    },
    getContactsCount(i, j) {
      let count = i[j].length;
      i[j].filter((item) => {
        if (item.role === "Account executive") {
          count = count - 1;
        }
      });
      return count;
    },
    getNamesOfAccountExecutives(i, j) {
      let name = "";
      i[j].filter((item) => {
        if (item.role === "Account executive") {
          name = item.name;
        } else {
          name = "";
        }
      });
      return name;
    },
    addressData(val) {
      let address1 = JSON.parse(JSON.stringify(val));
      // let address2 = JSON.parse(JSON.stringify(val))
      this.Hpayload.contactMedium[3] = JSON.parse(JSON.stringify(address1));
      // address2.mediumType = 'billingLocation'
      // this.newAccPayload.contactMedium.push(address2);
    },
    rowDragStart(index) {
      this.isDraggable = true;
      this.startIndex = index;
      console.log(index, "start", this.tableData[index]);
    },
    rowDragEnter($event, index) {
      this.isDraggable = true;
      console.log($event, index);
      document.querySelectorAll(".category-line-area").forEach((e) => {
        e.style.display = "none";
      });
      //  console.log(this.tableData[index], this.tableData[this.startIndex]);
      console.log(
        this.checkLevelRegex(
          this.tableData[index],
          this.tableData[this.startIndex].level
        ),
        (this.findDrag = this.tableData[index].level),
        (this.findDragIndexLevel = this.tableData[this.startIndex].level)
      );
      let regex = this.checkLevelRegex(
        this.tableData[index],
        this.tableData[this.startIndex].level
      );
      if (index != this.startIndex && regex != true) {
        document.querySelector("#category-line-area" + index).style.display =
          "block";
        //  console.log($event.target.className);
      }
      // console.log(index,'enter');
    },
    checkLevelRegex(obj, pattern) {
      const levelRegex = new RegExp(`(^|\\D)${pattern}(\\D|$)`);
      return levelRegex.test(obj.level);
    },
    sibilingArea($event, index) {
      this.enterIndex = index;
      this.manipulationType = "sibling";
      if ($event.target.className == "category-line-sibiling") {
        // index
        if (this.module == "CM") {
          $event.target.nextElementSibling.classList.remove("child-color");
        }
        if (this.module == "PCM") {
          $event.target.nextElementSibling.classList.remove("child-color");
        }
        $event.target.classList.add("sibilng-color");
      }
      //  console.log($event,index,'sibiling');
    },
    childArea($event, index) {
      this.enterIndex = index;
      this.manipulationType = "child";
      //  $event,index
      //  console.log($event,index,'child');

      if ($event.target.className == "category-line-child") {
        $event.target.previousElementSibling.classList.remove("sibilng-color");
        $event.target.classList.add("child-color");
      }
    },
    async rowDragEnd(index) {
      document.querySelectorAll(".category-line-area").forEach((e) => {
        e.style.display = "none";
      });
      index;
      console.log("category", this.manipulationType);
      // console.log(index, this.enterIndex, this.startIndex);
      if (this.module == "PCM") {
        if (this.manipulationType == "child") {
          this.isDraggable = false;
          console.log(this.enterIndex, this.startIndex, "indesxxxxxx");
          let targetdata = this.tableData[this.enterIndex];
          let dragdata = this.tableData[this.startIndex];
          console.log(targetdata._id, dragdata.parentId, "data");
          if (
            targetdata &&
            dragdata &&
            targetdata._id != dragdata._id &&
            this.findDrag != this.findDragIndexLevel &&
            targetdata._id != dragdata.parentId
          )
            this.$emit(
              "drag-child",
              targetdata._id,
              dragdata,
              this.manipulationType,
              dragdata,
              targetdata
            );
        } else if (this.manipulationType == "sibling") {
          // this.isDraggable = false
          // console.log(this.enterIndex,this.startIndex,'indesxxxxxx');
          let targetdata = this.tableData[this.enterIndex];
          let dragdata = this.tableData[this.startIndex];
          console.log(targetdata._id, dragdata.parentId, "sibilingdata");
          this.$emit(
            "drag-sibilng",
            targetdata._id,
            dragdata,
            this.manipulationType,
            dragdata,
            targetdata
          );
          // if(targetdata && dragdata && targetdata._id != dragdata._id && this.findDrag != this.findDragIndexLevel && targetdata._id != dragdata.parentId)
        }
      } else {
        if (this.manipulationType === "child") {
          let obj = {
            parent: {},
            child: {},
          };
          obj.child.name = this.tableData[index].tradingName;
          obj.parent.name = this.tableData[this.enterIndex].tradingName;
          console.log(
            this.manipulationType,
            this.tableData[index],
            this.tableData[this.enterIndex],
            obj
          );
          let result = await MLTAxiosInstance.patch(`/hierarchy/dragTree`, obj);
          console.log(result);
          let hierarchy = await MLTAxiosInstance.get(
            `/hierarchy?company=${this.tableData[0].name}`
          );
          let arr = [];
          arr.push(hierarchy.data);
          // console.log(arr);
          this.rawData = JSON.parse(JSON.stringify(arr));
          this.addLevelToStructure(this.rawData);
          this.tableData = this.flattenAndIdentifyChildren(arr);
          this.actualTableData = JSON.parse(JSON.stringify(this.tableData));
          this.closeAddHierarchyModal();
          this.$emit("updated-hierarchy", this.rawData);
          this.refreshSelect += 1;
        } else {
          let obj = {
            parent: {},
            child: {},
          };
          obj.child.name = this.tableData[index].tradingName;
          obj.parent.name =
            this.tableData[
              this.enterIndex
            ]?.organizationParentRelationship?.name;
          console.log(obj);
          let result = await MLTAxiosInstance.patch(`/hierarchy/dragTree`, obj);
          console.log(result);
          let hierarchy = await MLTAxiosInstance.get(
            `/hierarchy?company=${this.tableData[0].name}`
          );
          let arr = [];
          arr.push(hierarchy.data);
          // console.log(arr);
          this.rawData = JSON.parse(JSON.stringify(arr));
          this.addLevelToStructure(this.rawData);
          this.tableData = this.flattenAndIdentifyChildren(arr);
          this.actualTableData = JSON.parse(JSON.stringify(this.tableData));
          this.closeAddHierarchyModal();
          this.$emit("updated-hierarchy", this.rawData);
          this.refreshSelect += 1;
        }
      }
    },
    manipulateHierarchy(data, targetId, type, newObject) {
      // console.log(data,targetId,type, newObject);
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        // console.log(node,'node');

        // console.log(node._id === targetId,type,node._id,targetId,'id');
        if (node._id === targetId) {
          if (type === "sibling") {
            // Add newObject as a sibling above the targetId
            data.splice(i, 0, newObject);
            // console.log(data,'sibling');
          } else if (type === "child") {
            // Add newObject as a child within the targetId's subCategory
            if (!node.subCategory) {
              node.subCategory = [];
            }
            node.isRoot = false;
            node.subCategory.push(newObject);
          }

          return;
        }

        if (node.subCategory && node.subCategory.length > 0) {
          // Recursively traverse subcategories
          this.manipulateHierarchy(node.subCategory, targetId, type, newObject);
        }
      }
    },
    removeItemById(data, targetId, removedItems = []) {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];

        if (node._id === targetId) {
          // Remove the object if the ID matches and store it in removedItems
          removedItems.push(data.splice(i, 1)[0]);
          return;
        }

        if (node.subCategory && node.subCategory.length > 0) {
          // Recursively traverse subcategories and remove the object
          this.removeItemById(node.subCategory, targetId, removedItems);

          // If subcategory becomes empty after removal, remove the subcategory
          if (node.subCategory.length === 0) {
            delete node.subCategory;
          }
        }
      }
    },
    tooltipover(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipsis(data1, index1);
    },
    tooltipleave(data, index) {
      let data1 = data;
      let index1 = index;
      this.ellipses(data1, index1);
    },
    ellipsis(data, index) {
      console.log(data, index);
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              let height = tooltip.offsetHeight;
              let top = element1.getBoundingClientRect().top - height;
              let left = element1.getBoundingClientRect().left;
              tooltip.style.display = "block";
              tooltip.style.top = top + "px";
              tooltip.style.left = left + "px";
              return true;
            }
          }
          return false;
        }
      });
    },
    ellipses(data, index) {
      this.$nextTick(() => {
        const element1 = document.querySelector("#" + data);
        if (element1 != null) {
          if (element1.clientWidth < element1.scrollWidth) {
            var style =
              element1.currentStyle || window.getComputedStyle(element1);
            if (style.textOverflow === "ellipsis") {
              const tooltip = document.querySelector("." + index);
              // let top = element1.getBoundingClientRect().top - 40
              tooltip.style.display = "none";
              //  tooltip.style.top = top+'px'
              return true;
            }
          }
          return false;
        }
      });
    },
    async getLocations() {
      const input = this.Hpayload.contactMedium[3].description;
      console.log(input);
      let result = [];

      try {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        const response = await MLTAxiosInstance.get(`util/${input}`);
        // console.log(response);
        result = response.data;
      } catch (error) {
        console.error("Error fetching autocomplete data:", error.message);
      }
      // console.log(result);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.locationData = result;
      if (this.locationData.length === 0 && input !== "") {
        this.showNewAddressOption = true;
      } else {
        this.showNewAddressOption = false;
      }
      return result;
    },
    createAccountAndLinkFunc() {
      console.log("footer clicked");
      this.createAccountAndLink = true;
    },
    pricingtypeFunction(val) {
      return val.join(", ");
    },
    addCategory() {
      this.nameError = false;
      this.pricingtypeError = false;
      this.radioOptions = [
        { name: "Tangible", checked: true, disabled: false, id: 1 },
        { name: "Intangible", checked: false, disabled: false, id: 2 },
      ];
      this.checkboxOptions = [
        { name: "One time", checked: false, disabled: false, id: 3 },
        { name: "Installment", checked: false, disabled: false, id: 2 },
        { name: "Rental", checked: false, disabled: false, id: 2 },
      ];
      this.addPayload = {
        name: "",
        identifier: "",
        description: "",
        type: "Tangible",
        pricingType: [],
        isRoot: true,
        isSellabe: false,
      };
      // console.log(this.addPayload);
      this.addSibiling = true;
      this.showAddHierarchyModal = true;
    },
    emitParentcategory(val) {
      if (this.addParent == true) {
        this.payload.parentId = val._id;
        this.payload.name = val.name;
      } else if (this.addChild == true) {
        this.childPayload.child.id = val._id;
        this.childPayload.child.name = val.name;
      }
    },
    emitPayload() {
      if (this.addParent == true) {
        // console.log('payload', this.payload, this.dataID, this.dataType);
        this.$emit(
          "payload",
          this.payload,
          this.dataID,
          this.dataType,
          this.indexPayload
        );
      } else if (this.addChild == true) {
        console.log(this.childPayload, "lllodjo");
        let count = 0;
        if (this.childPayload.child.name == "") {
          this.nameError = true;
          count += 1;
        } else {
          this.nameError = false;
        }
        if (this.childPayload.child.pricingType.length == 0) {
          this.pricingtypeError = true;
          count += 1;
        } else {
          this.pricingtypeError = false;
        }
        if (count == 0) {
          if(this.sellable == true){
            this.childPayload.child.isSellabe = true
            
          }else{
            this.childPayload.child.isSellabe = false
          }
          this.$emit(
            "payload",
            this.childPayload,
            this.dataID,
            this.dataType,
            this.indexPayload
          );
          this.closeAddHierarchyModal();
        }
        // this.$emit(
        //   'payload',
        //   this.childPayload,
        //   this.dataID,
        //   this.dataType,
        //   this.indexPayload
        // );
      } else if (this.addSibiling == true) {
        console.log("testttttt", this.addPayload, this.addPayload.name);
        let count = 0;
        if (this.addPayload.name == "") {
          this.nameError = true;
          count += 1;
        } else {
          this.nameError = false;
        }
        if (this.addPayload.pricingType.length == 0) {
          this.pricingtypeError = true;
          count += 1;
        } else {
          this.pricingtypeError = false;
        }
        if (count == 0) {
          if(this.sellable == true){
            this.addPayload.isSellabe = true
          }else{
            this.addPayload.isSellabe = false
          }
          this.$emit("category", this.addPayload);
          this.closeAddHierarchyModal();
        }
        // if(this.addPayload.name)
        // this.$emit('category', this.addPayload);
      } else if (this.editCategory == true) {
        let count = 0;
        if (this.editPayload.name == "") {
          this.nameError = true;
          count += 1;
        } else {
          this.nameError = false;
        }
        if (this.editPayload.pricingType.length == 0) {
          this.pricingtypeError = true;
          count += 1;
        } else {
          this.pricingtypeError = false;
        }
        if (count == 0) {
          if(this.sellable == true){
            this.editPayload.isSellabe = true
          }else{
            this.editPayload.isSellabe = false
          }
          this.$emit("edit-category", this.editPayload);
          this.closeAddHierarchyModal();
        }
      }
      this.sellable = false
      // this.closeAddHierarchyModal();
    },
    emitRadio(val) {
      console.log(val, "tangiable");
      if (val.name == "Tangible") {
        let data = [
          { name: "One time", checked: false, disabled: false, id: 3 },
          { name: "Installment", checked: false, disabled: false, id: 2 },
          { name: "Rental", checked: false, disabled: false, id: 2 },
        ];
        this.checkboxOptions = data;
      } else {
        let data = [
          { name: "One time", checked: false, disabled: false, id: 3 },
          { name: "Recurring", checked: false, disabled: false, id: 2 },
          { name: "Pay per use", checked: false, disabled: false, id: 2 },
        ];
        this.checkboxOptions = data;
      }
      if (this.addParent == true) {
        if (this.payload.type != val.name) {
          this.payload.pricingType = [];
        }
        this.payload.type = val.name;
      } else if (this.addChild == true) {
        if (this.childPayload.child.type != val.name) {
          this.childPayload.child.pricingType = [];
        }
        this.childPayload.child.type = val.name;
      } else if (this.addSibiling == true) {
        if (this.addPayload.type != val.name) {
          this.addPayload.pricingType = [];
        }
        this.addPayload.type = val.name;
      } else if (this.editCategory == true) {
        if (this.editPayload.type != val.name) {
          this.editPayload.pricingType = [];
        }
        this.editPayload.type = val.name;
      }
      // console.log(val,'raio');
      // let data = val.filter((e)=>{ return e.checked == true})
      // console.log(data,'radio');
    },
    emitCheckbox(val) {
      console.log(val, "llll");
      if (this.addParent == true) {
        let data = this.payload.pricingType;
        console.log(val.checked == true && data.includes(val.name) == false);
        if (val.checked == true && data.includes(val.name) == false) {
          data.push(val.name);
        } else if (val.checked == false) {
          let arr = data;
          arr.forEach((e, index) => {
            if (e == val.name) {
              data.splice(index, 1);
            }
          });
        }
        this.payload.pricingType = data;
      } else if (this.addChild == true) {
        let data = this.childPayload.child.pricingType;
        console.log(val.checked == true && data.includes(val.name) == false);
        if (val.checked == true && data.includes(val.name) == false) {
          data.push(val.name);
        } else if (val.checked == false) {
          let arr = data;
          arr.forEach((e, index) => {
            if (e == val.name) {
              data.splice(index, 1);
            }
          });
        }
        this.childPayload.child.pricingType = data;
      } else if (this.addSibiling == true) {
        let data = this.addPayload.pricingType;
        console.log(val.checked == true && data.includes(val.name) == false);
        if (val.checked == true && data.includes(val.name) == false) {
          data.push(val.name);
        } else if (val.checked == false) {
          let arr = data;
          arr.forEach((e, index) => {
            if (e == val.name) {
              data.splice(index, 1);
            }
          });
        }
        this.addPayload.pricingType = data;
      } else if (this.editCategory == true) {
        let data = this.editPayload.pricingType;
        console.log(val.checked == true && data.includes(val.name) == false);
        if (val.checked == true && data.includes(val.name) == false) {
          data.push(val.name);
        } else if (val.checked == false) {
          let arr = data;
          arr.forEach((e, index) => {
            if (e == val.name) {
              data.splice(index, 1);
            }
          });
        }
        this.editPayload.pricingType = data;
      }
    },
    editCardHierarchy(node, spech) {
      console.log(node, spech, "fghjio");
    },
    setvalue(node) {
      // this.$emit("card-specification", node);
      console.log(node, "spech value ");
    },
    CardData(payload) {
      const ans = payload;
      console.log("payload value of data", payload, ans);
    },
    chosenSwitch(val) {
      this.switchvalue = val.name;
      console.log(val.name, "switch");
    },
    zoomin() {
      this.zoom += 10;
    },
    zoomout() {
      this.zoom -= 10;
    },
    closeRemoveHierarchyModal() {
      this.showRemoveHierarchyModal = false;
      this.$emit("updated-hierarchy");
    },
    async addchild(payload, spech, data) {
      // const ans = payload;
      console.log("add child", spech, payload, data);
      //if (data ==='child') {
      // await MLTAxiosInstance.patch(
      //   `/hierarchy/updateTree/${this.hierarchyPayload.parent.id}`,
      //   this.hierarchyPayload
      // );
      // }
      const parent = {
        parent: {
          id: spech._id,
          name: spech.name,
        },
        child: [
          {
            id: payload._id,
            name: payload.name,
          },
        ],
        rootName: this.treedata.name,
      };
      const child = {
        parent: {
          id: payload._id,
          name: payload.name,
        },
        child: [
          {
            id: spech._id,
            name: spech.name,
          },
        ],
        rootName: this.treedata.name,
      };
      console.log(child, parent, data, "value");
      const addedHierarchyLogPayload = {
        message: `${spech.name} Hierarchy has been added`,
        detailedAction: [],
        action: "Added",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "Accounts-location",
        category: ["accounts"],
      };
      this.initLog(addedHierarchyLogPayload);
      if (data === "child") {
        await MLTAxiosInstance.patch(
          `/hierarchy/updateTree/${child.parent.id}`,
          child
        );
      }
    },
    async createAccountAndUpdateHierarchy() {
      this.canAdd = false;
      let payload = JSON.parse(JSON.stringify(this.Hpayload));
      let billingAddress = JSON.parse(JSON.stringify(payload.contactMedium[3]));
      let siteAddress = JSON.parse(JSON.stringify(payload.contactMedium[3]));
      billingAddress.mediumType = "billingLocation";
      billingAddress.characteristic.phone =
        payload.contactMedium[1].characteristic;
      billingAddress.characteristic.email =
        payload.contactMedium[0].characteristic;
      billingAddress.characteristic.locationName = payload.name;
      billingAddress.characteristic.default = true;

      siteAddress.mediumType = "siteLocation";
      siteAddress.characteristic.phone =
        payload.contactMedium[1].characteristic;
      siteAddress.characteristic.email =
        payload.contactMedium[0].characteristic;
      siteAddress.characteristic.locationName = payload.name;
      siteAddress.characteristic.default = true;

      payload.contactMedium.push(billingAddress);
      payload.contactMedium.push(siteAddress);
      console.log(this.Hpayload);
      let result;
      this.isExistingName = false;
      this.isExistingNameErrorMessage = "";
      this.isExistingEmail = false;
      this.isExistingEmailErrorMessage = "";
      try {
        result = await MLTAxiosInstance.post(
          `/partymanagement/individual/account/signup`,
          this.Hpayload
        );
        if (result.data.message == "Company already exist") {
          this.isExistingName = true;
          this.isExistingNameErrorMessage = "Company name already exists";
          return;
        }
        console.log("new result", result);

        console.log(result);
        // console.log(this.addChild);
        // console.log(result);
        let data = await MLTAxiosInstance.get(
          `/partymanagement/organisation/${result.data.data._id}?company=${result.data.data.name}`
        );
        // let data = await MLTAxiosInstance.get(
        //   `/partymanagement/organisation/search?tradingName=${result.data.tradingName}`
        // );
        console.log("data", data);
        let obj = {};
        obj.id = JSON.parse(JSON.stringify(data.data._id));
        obj.name = JSON.parse(JSON.stringify(data.data.tradingName));
        this.hierarchyPayload.child = JSON.parse(JSON.stringify(obj));
        // this.hierarchyPayload.child.splice(0, 1);
        // console.log(result, data, this.hierarchyPayload);

        // console.log(this.hierarchyPayload);
        // result = await MLTAxiosInstance.patch(
        //   `/hierarchy/updateTree/${this.hierarchyPayload.parent.id}`,
        //   this.hierarchyPayload
        // );
        await this.updateHierarchy();
      } catch (error) {
        console.log(
          error,
          error.response.data.message.includes(
            "db already exists with different case"
          ),
          result
        );
        if (
          error.response.data.message.includes(
            "db already exists with different case"
          )
        ) {
          this.isExistingName = true;
          this.isExistingNameErrorMessage =
            "Account already exists with different case";
          return;
        } else if (error.response.data.message.includes("email_1 dup key")) {
          this.isExistingEmail = true;
          // this.isExistingEmailErrorMessage = "This email is already in use";
          this.isExistingEmailErrorMessage = this.staticData?.emailAlreadyUse?.label[this.loginUserDetails]?.label;
          return;
        } else {
          this.isExistingName = false;
          this.isExistingNameErrorMessage = "";
          this.isExistingEmail = false;
          this.isExistingEmailErrorMessage = "";
        }
        return;
      }
    },
    async updateHierarchy() {
      console.log(this.hierarchyPayload, "add child");
      let result;
      if (this.addChild) {
        this.hierarchyPayload.rootName = this.hierarchyPayload.parent.name;
        console.log(this.hierarchyPayload.rootName);
        result = await MLTAxiosInstance.patch(
          `/hierarchy/updateTree/?company=${this.hierarchyPayload.parent.name}`,
          this.hierarchyPayload
        );
      }
      const addedHierarchyLogPayload = {
        message: `${this.hierarchyPayload.child.name} Hierarchy has been added`,
        detailedAction: [],
        action: "Added",
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        createdBy: this.$cookies.get("user"),
        ref: [this.$route.params.id],
        "@type": "Accounts-location",
        category: ["accounts"],
      };
      this.initLog(addedHierarchyLogPayload);
      if (this.addParent) {
        if (this.rootIndex) {
          this.hierarchyPayload.rootName = this.hierarchyPayload.parent.name;
        }
        result = await MLTAxiosInstance.patch(
          `/hierarchy/updateParent/${this.hierarchyPayload.child[0].id}`,
          this.hierarchyPayload
        );
      }
      console.log(result);
      if (result.data === "Updation successfull") {
        this.notification = {
          type: "success",
          message: this.staticData?.cmHierarchyNotiMessage1?.label[this.loginUserDetails]?.label || "All set! Your changes have been saved successfully!",
        };
      }
      let hierarchy = await MLTAxiosInstance.get(
        `/hierarchy?company=${this.tableData[0].name}`
      );
      let arr = [];
      arr.push(hierarchy.data);
      // console.log(arr);
      this.rawData = JSON.parse(JSON.stringify(arr));
      this.addLevelToStructure(this.rawData);
      this.tableData = this.flattenAndIdentifyChildren(arr);
      this.actualTableData = JSON.parse(JSON.stringify(this.tableData));
      this.closeAddHierarchyModal();
      this.$emit("updated-hierarchy", this.rawData);
      this.refreshSelect += 1;
      // this.drawerKey += 1;
      let x = {
        name: "",
        tradingName: "",
        status: "Active",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              "@type": "",
            },
          },
        ],
        removeHierarchyPayload: {},
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        relatedParty: [],
        tags: ["Active"],
        role: "Root admin",
        type: "company",
        password: "",
        email: "",
        organizationParentRelationship: {},
        rootName: "",
      };
      this.accExecParty = "";
      this.Hpayload = JSON.parse(JSON.stringify(x));
      this.createAccountAndLink = false;
    },
    async removeHierarchy() {
      if (this.module == "CM") {
        console.log(this.removeHierarchyPayload, "aristocraticTitle");
        const addedProductLocationLogPayload = {
          message: `${this.removeHierarchyPayload?.customName} Hierarchy has been deleted`,
          detailedAction: [],
          action: "Delete",
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          createdBy: this.$cookies.get("user"),
          ref: [this.$route.params.id],
          "@type": "Accounts-location",
          category: ["accounts"],
        };
        this.initLog(addedProductLocationLogPayload);
        await MLTAxiosInstance.delete(
          `/hierarchy/remove/${this.removeHierarchyIndex}`
        );
        let hierarchy = await MLTAxiosInstance.get(
          `/hierarchy?company=${this.tableData[0].rootName}`
        );
        this.treedata = hierarchy.data;
        let arr = [];
        arr.push(hierarchy.data);
        // console.log(arr);
        this.rawData = JSON.parse(JSON.stringify(arr));
        this.addLevelToStructure(this.rawData);
        this.tableData = this.flattenAndIdentifyChildren(arr);
        this.$emit("updated-hierarchy", this.rawData);
        this.notification = {
          type: "success",
          message: this.staticData?.cmHierarchyNotiMessage2?.label[this.loginUserDetails]?.label || "Done! Your item has been removed.",
        };
      } else if (this.module == "PCM") {
        // console.log(this.tableData[this.removeHierarchyIndex],'delll');
        this.$emit("delete", this.tableData[this.removeHierarchyIndex]);
      }
      this.showRemoveHierarchyModal = false;
    },
    selectedHierarchyAccountDetails(val) {
      console.log(this.addChild, this.addParent, val, this.hierarchyPayload);
      if (this.addChild) {
        this.hierarchyPayload.child.id = val._id;
        this.hierarchyPayload.child.name = val.name;
      }
      if (this.addParent) {
        this.hierarchyPayload.parent.id = val._id;
        this.hierarchyPayload.parent.name = val.name;
      }
      // console.log(this.hierarchyPayload);
    },
    async selectedHierarchyAccountFunc(val) {
      const f = {
        company: this.company,
        type: "filter-search",
        module: "hierarchyaccounts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 5,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 2,
        filterQuery: {
          name: {
            $nin: [this.company],
          },
          tradingName: {
            $nin: this.excludedAccounts,
          },
          "@baseType": {
            $ne: "supplier",
          },
        },
        searchQuery: val,
      };
      let data = await MLTAxiosInstance.post(`util/filterData`, f);
      console.log("dropdown data", data);
      this.organizationList = data.data.data;
      console.log(this.excludedAccounts);
    },
    closeAddHierarchyModal() {
      console.log("hierarchy");
      this.showAddHierarchyModal = false;
      this.addChild = false;
      this.addParent = false;
      this.addSibiling = false;
      this.editCategory = false;
      this.selectedHierarchyAccount = "";
      this.selectKey += 1;
      this.componentkey += 1;
      this.createAccountAndLink = false;
      let x = {
        name: "",
        tradingName: "",
        status: "Active",
        partyCharacteristic: [
          {
            name: "number of employees",
            value: "",
          },
          {
            name: "market segment",
            value: "",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                value: "",
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              "@type": "",
            },
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        relatedParty: [],
        tags: ["Active"],
        role: "Root admin",
        type: "company",
        password: "",
        email: "",
        organizationParentRelationship: {},
        rootName: "",
      };
      this.accExecParty = "";
      this.Hpayload = JSON.parse(JSON.stringify(x));
    },
    flattenAndIdentifyChildren(payload, currentLevel = []) {
      let flattenedData = [];

      for (let i = 0; i < payload.length; i++) {
        const org = payload[i];

        // Flatten the organization
        const flattenedOrg = { ...org, expand: true };

        // Add level to the organization
        const level = [...currentLevel, i + 1];
        flattenedOrg.level = level.join(".");
        if (this.module == "CM") {
          // Add "noChild" property based on the presence of children
          flattenedOrg.noChild = !org.child || org.child.length === 0;

          // Add flattened organization to the result
          flattenedData.push(flattenedOrg);

          // Process child if present
          if (org.child && org.child.length > 0) {
            // Recursively flatten and identify child
            const childData = this.flattenAndIdentifyChildren(org.child, level);

            // Add flattened children to the result
            flattenedData = flattenedData.concat(childData);
          }
        } else if (this.module == "PCM" || this.module === "Role") {
          // Add "noChild" property based on the presence of children
          flattenedOrg.noChild =
            !org.subCategory || org.subCategory.length === 0;

          // Add flattened organization to the result
          flattenedData.push(flattenedOrg);

          // Process child if present
          if (org.subCategory && org.subCategory.length > 0) {
            // Recursively flatten and identify child
            const childData = this.flattenAndIdentifyChildren(
              org.subCategory,
              level
            );

            // Add flattened children to the result
            flattenedData = flattenedData.concat(childData);
          }
        }
      }

      return flattenedData;
    },
    activityLogDrawer() {
      this.activiylogcall();
      this.show_right = true;
      this.activtykey += 1;
    },
    crudContextMenu($event, index, i) {
      if ($event.target.className === "icon-more-vertical-filled") {
        if (this.computedTableAction(i, index).length === 1) {
          this.containerleft =
            document
              .querySelector("#table-context" + index)
              .parentElement.getBoundingClientRect().left - 123;
        } else {
          this.containerleft =
            document
              .querySelector("#table-context" + index)
              .parentElement.getBoundingClientRect().left - 123;
        }

        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          } else {
            e.context = false;
          }
        });
        (this.payload = {
          isRoot: true,
          name: "",
          identifier: "",
          description: "",
          type: "",
          pricingType: [],
        }),
          (this.childPayload = {
            child: {
              id: "",
              name: "",
              identifier: "",
              description: "",
              type: "",
              pricingType: [],
            },
          });
        this.componentkey += 1;

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    fromAddContextMenuHierarchy(val, node) {
      console.log(val, "contxt");

      if (val === "Add parent") {
        // console.log(row.rootName);
        this.hierarchyPayload.child.id = node._id;
        this.hierarchyPayload.child.name = node.name;
        this.hierarchyPayload.rootName = node.rootName;

        this.addParent = true;
        this.showAddHierarchyModal = true;
      } else if (val == "Link a child") {
        this.rootIndex = false;
        this.hierarchyPayload.parent.id = node._id;
        this.hierarchyPayload.parent.name = node.name;
        this.hierarchyPayload.rootName = node.rootName;
        this.addChild = true;
        this.showAddHierarchyModal = true;
      } else if (val == "Remove") {
        this.removeHierarchyPayload = node;
        this.showRemoveHierarchyModal = true;
        this.removeHierarchyIndex = node._id;
      }
    },
    async closeCrudContextMenu($event, row, index) {
      console.log($event, row, index);
      this.drawerKey += 1;
      this.nameError = false;
      this.pricingtypeError = false;
      this.radioOptions = [
        { name: "Tangible", checked: true, disabled: false, id: 1 },
        { name: "Intangible", checked: false, disabled: false, id: 2 },
      ];
      this.checkboxOptions = [
        { name: "Recurring", checked: false, disabled: false, id: 1 },
        { name: "One time", checked: false, disabled: false, id: 2 },
        { name: "Installment", checked: false, disabled: false, id: 2 },
      ];
      if ($event.toLowerCase() === "add parent") {
        // console.log(row.rootName);
        if (this.module == "CM") {
          this.hierarchyPayload.child.id = row._id;
          this.hierarchyPayload.child.name = row.name;
          // this.hierarchyPayload.rootName = row.rootName;
          if (index === 0) {
            this.rootIndex = true;
          } else {
            this.rootIndex = false;
          }
        } else if (this.module == "PCM") {
          console.log($event, row, index, "kdkd");
          this.dataID = row._id;
          this.dataType = $event;
          this.dataName = row.name;
          let data = {};
          let data1 = {};
          data = JSON.parse(JSON.stringify(this.tableData[index]));
          data1 = JSON.parse(JSON.stringify(this.tableData[index]));
          data.isRoot = false;
          data1.isRoot = false;
          data.id = data._id;
          delete data._id;
          this.indexPayload = data;
          let arr = [];
          arr.push(data1);
          this.payload.subCategory = arr;
          // this.tableData[index].isRoot = false
          //  console.log(this.tableData[index],'los');
          // this.payload['parentId'] = row._id
        }
        this.addParent = true;
        this.showAddHierarchyModal = true;
      } else if ($event.toLowerCase() == "link a child") {
        if (this.module == "CM") {
          this.rootIndex = false;
          this.hierarchyPayload.parent.id = row._id;
          this.hierarchyPayload.parent.name = row.name;
          this.hierarchyPayload.rootName = row.rootName;
        } else if (this.module == "PCM") {
          console.log($event, row, index, "kdkd", row.pricingType);
          if (row.type) {
            this.radioOptions.forEach((e) => {
              if (e.name == row.type) {
                e.checked = true;
              }
            });
          }
          if (row.pricingType && row.pricingType.length != 0) {
            let pricingType = row.pricingType;
            pricingType.forEach((e) => {
              if (this.checkboxOpt.includes(e) == true) {
                this.checkboxOptions.forEach((ele) => {
                  if (ele.name == e) {
                    ele.checked = true;
                  }
                });
              }
            });
          }
          this.dataID = row._id;
          this.dataType = $event;
          this.dataName = row.name;
          // this.childPayload.child.id = '';
        }
        this.addChild = true;
        this.showAddHierarchyModal = true;
      } else if ($event.toLowerCase() == "add child") {
        if (this.module == "PCM") {
          console.log($event, row, index, "kdkd", row.pricingType);
          if(row.isSellabe == true){
            this.sellable = true
          }else{
            this.sellable = false
          }
          if (row.type) {
            let typeValue = "";
            this.radioOptions.forEach((e) => {
              if (e.name == row.type) {
                e.checked = true;
                typeValue = e.name;
              }
            });
            this.childPayload.child.type = typeValue;
            if (typeValue == "Tangible") {
              let data = [
                { name: "One time", checked: false, disabled: false, id: 3 },
                { name: "Installment", checked: false, disabled: false, id: 2 },
                { name: "Rental", checked: false, disabled: false, id: 2 },
              ];
              this.checkboxOptions = data;
            } else {
              let data = [
                { name: "One time", checked: false, disabled: false, id: 3 },
                { name: "Recurring", checked: false, disabled: false, id: 2 },
                { name: "Pay per use", checked: false, disabled: false, id: 2 },
              ];
              this.checkboxOptions = data;
            }
          }
          let arr = [];
          if (row.pricingType && row.pricingType.length != 0) {
            let pricingType = row.pricingType;
            pricingType.forEach((e) => {
              if (this.checkboxOpt.includes(e) == true) {
                this.checkboxOptions.forEach((ele) => {
                  if (ele.name == e) {
                    ele.checked = true;
                    arr.push(ele.name);
                  }
                });
              }
            });
          }
          this.childPayload.child.pricingType = arr;
          this.dataID = row._id;
          this.dataType = $event;
          this.dataName = row.name;
          this.indexPayload = this.tableData[index];
          // this.childPayload.child.id = '';
        }
        this.addChild = true;
        this.showAddHierarchyModal = true;
      } else if ($event.toLowerCase() == "remove") {
        this.showRemoveHierarchyModal = true;
        this.removeHierarchyIndex = row.tradingName;
        this.removeHierarchyPayload = row;
      } else if ($event.toLowerCase() == "delete") {
        console.log("delete", this.tableData[index - 1], this.tableData[index]);
        this.showRemoveHierarchyModal = true;
        this.removeHierarchyIndex = index;
      } else if ($event.toLowerCase() == "edit") {
        this.editPayload = {};
        this.editPayload = JSON.parse(JSON.stringify(this.tableData[index]));
        this.$emit(
          "initialPayload",
          JSON.parse(JSON.stringify(this.tableData[index]))
        );
        let typeValue = "";
        let arr = [];
        if(this.editPayload.isSellabe == true){
          this.sellable = true
        }else{
          this.sellable = false
        }
        if (this.editPayload.type) {
          this.radioOptions.forEach((e) => {
            if (e.name == this.editPayload.type) {
              e.checked = true;
              typeValue = e.name;
            }
          });
          let radio = this.radioOptions;
          this.radioOptions = radio;
        }
        if (typeValue == "Tangible") {
          let data = [
            { name: "One time", checked: false, disabled: false, id: 3 },
            { name: "Installment", checked: false, disabled: false, id: 2 },
            { name: "Rental", checked: false, disabled: false, id: 2 },
          ];
          this.checkboxOptions = data;
        } else {
          let data = [
            { name: "One time", checked: false, disabled: false, id: 3 },
            { name: "Recurring", checked: false, disabled: false, id: 2 },
            { name: "Pay per use", checked: false, disabled: false, id: 2 },
          ];
          this.checkboxOptions = data;
        }
        if (row.pricingType && row.pricingType.length != 0) {
          let pricingType = row.pricingType;
          pricingType.forEach((e) => {
            if (this.checkboxOpt.includes(e) == true) {
              this.checkboxOptions.forEach((ele) => {
                if (ele.name == e) {
                  ele.checked = true;
                  arr.push(ele.name);
                }
              });
            }
          });
        }
        console.log(this.editPayload, "edit");
        this.editPayload.type = typeValue;
        this.editPayload.pricingType = arr;
        this.addPayload.identifier = row.identifier;
        this.addPayload.name = row.name;
        this.addPayload.description = row.description;
        this.addPayload.id = row._id;
        this.addPayload.parentId = this.dataID;
        this.dataID = row._id;
        this.dataType = $event;
        this.dataName = row.name;
        this.editCategory = true;
        this.showAddHierarchyModal = true;
      }
    },
    computedTableAction(data, index) {
      let options = [];
      if (this.module == "CM") {
        if (index === 0) {
          options = [
            {
              label: "Link a child",
              display: this.staticData?.linkChild?.label[this.loginUserDetails]?.label || "Link a child",
              icon: "icon-grid-web-2-regular",
            },
          ];
        } else {
          options = [
            {
              label: "Link a child",
              display: this.staticData?.linkChild?.label[this.loginUserDetails]?.label || "Link a child",
              icon: "icon-grid-web-2-regular",
            },
            {
              label: "Remove",
              display: this.staticData?.remove?.label[this.loginUserDetails]?.label || "Remove",
              icon: "icon-trash-regular",
            },
          ];
        }
      } else if (this.module == "PCM") {
        options = [
          {
            label: "Add child",
            display: this.staticData?.addChild?.label[this.loginUserDetails]?.label || "Add child",
            icon: "icon-grid-web-2-regular",
          },
          {
            label: "Edit",
            display: this.staticData?.edit?.label[this.loginUserDetails]?.label || "Edit",
            icon: "icon-grid-web-2-regular",
          },
          {
            label: "Delete",
            display: this.staticData?.delete?.label[this.loginUserDetails]?.label || "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }

      return options.filter((option) => {
        if (
          data &&
          data.status &&
          data.status.toLowerCase() == "draft" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "active" &&
          ["Draft", "Mark as active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "inactive" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else {
          return true;
        }
      });
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    arrange(val) {
      this.re_arrange = val;
      // this.updateTheads();
    },
    arrangeColumns(val) {
      this.re_arrange = val;
      // this.updateTheads();
    },
    updateTheads() {
      this.accounts_table_theads_cc = this.accounts_table_theads_cc.map(
        (thead) => {
          // Check if the current thead has a matching 'prop' in duplicateTheads
          const isMatching = this.accounts_table_theads.some(
            (duplicateThead) => duplicateThead.prop === thead.prop
          );

          // Set the 'checked' property based on whether it's matching
          return { ...thead, checked: isMatching };
        }
      );
    },
   async applyColumns(val) {
    console.log("applyColumns",val)
// Function to rearrange table header
let sampleTheads = await MLTAxiosInstance.get(`/settings`);
    let concatedEntity = sampleTheads?.data?.entityRef || {};

    if (this.module == "CM") {
        // Update the hierarchyCM in entityRef
        concatedEntity.hierarchyCM = val;

        let reArrangeTableHeadPayload = { entityRef: concatedEntity };

        const changeTableHeader = await MLTAxiosInstance.patch(`/settings`, reArrangeTableHeadPayload);
        console.log(changeTableHeader, "reArrangeTableHeadPayload", reArrangeTableHeadPayload);
        
    } else if (this.module == "PCM") {
        // Update the hierarchyPCM in entityRef
        concatedEntity.hierarchyPCM = val;

        let reArrangeTableHeadPayload = { entityRef: concatedEntity };

        const changeTableHeader = await MLTAxiosInstance.patch(`/settings`, reArrangeTableHeadPayload);
        console.log(changeTableHeader, "reArrangeTableHeadPayload", reArrangeTableHeadPayload);
    }
    else if (this.module === "Role") {
        // Update the hierarchyRole in entityRef
        concatedEntity.hierarchyRole = val;

        let reArrangeTableHeadPayload = { entityRef: concatedEntity };

        const changeTableHeader = await MLTAxiosInstance.patch(`/settings`, reArrangeTableHeadPayload);
        console.log(changeTableHeader, "reArrangeTableHeadPayload", reArrangeTableHeadPayload);
    }

    // Refresh the table header
    let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
    console.log("sampleTheads", sampleTheadsRefresh?.data);
    console.log("sampleTheads mounted", sampleTheadsRefresh?.data?.entityRef);

    if (this.module == "CM") {
        this.accounts_table_theads_cc = sampleTheadsRefresh?.data?.entityRef["hierarchyCM"];
        this.accounts_table_theads = this.accounts_table_theads_cc.filter(ele => ele.checked);
    } else if (this.module == "PCM") {
        this.accounts_table_theads_cc = sampleTheadsRefresh?.data?.entityRef["hierarchyPCM"];
        this.accounts_table_theads = this.accounts_table_theads_cc.filter(ele => ele.checked);
    }
    else if (this.module === "Role") {
        this.accounts_table_theads_cc = sampleTheadsRefresh?.data?.entityRef["hierarchyRole"];
        this.accounts_table_theads = this.accounts_table_theads_cc.filter(ele => ele.checked);
    }
    },
    convertdateformat(date1) {
      if (date1 != null) {
        let objectDate = new Date(date1);
        if (isNaN(objectDate) == false) {
          let date = objectDate.toLocaleString("default", { day: "numeric" });
          let month = "";
          let format = this.$store.state.defaultSetting.dateFormat;
          if (
            format == "Month DD, YYYY" ||
            format == "mmmm dd, yyyy" ||
            format == "mmmm dd, yyyy"
          ) {
            month = objectDate.toLocaleString("default", { month: "long" });
          } else {
            month = objectDate.toLocaleString("default", { month: "short" });
          }
          let year = objectDate.getFullYear();
          let dateFormat = this.dateFormat(date, month, year, format);
          // let dateformat = month + " " + date + ", " + year;
          return dateFormat;
        }
      }
    },
    collapseHierarchyRow(level) {
      this.tableData.filter((item) => {
        if (item.level === level) {
          item.expand = false;
        }
      });
      const filteredPayload = this.tableData.filter((item) => {
        // Check if the item's level is equal to or is a parent of the level to remove
        return !item.level.startsWith(level + ".");
      });

      // console.log(filteredPayload);
      this.tableData = filteredPayload;
    },
    expandHierarchyRow(level, index) {
      this.tableData.filter((item) => {
        if (item.level === level) {
          item.expand = true;
        }
      });
      let startingIndexLevel = this.tableData[index].level;
      let endingIndexLevel = this.tableData[index + 1]?.level;
      this.actualTableData.filter((item, i) => {
        if (item.level === startingIndexLevel) {
          // console.log(i);
          startingIndexLevel = i;
        }
        if (item.level === endingIndexLevel) {
          // console.log(i);
          endingIndexLevel = i;
        }
      });
      // console.log(startingIndexLevel, endingIndexLevel);
      let copyOfActualTableData = JSON.parse(
        JSON.stringify(this.actualTableData)
      );
      let trimmedData = copyOfActualTableData.slice(
        startingIndexLevel + 1,
        endingIndexLevel
      );
      // console.log(trimmedData);
      this.tableData.splice(startingIndexLevel + 1, 0, ...trimmedData);
    },
    insertObjectsBetweenIndexes(startIdx, endIdx, payload1, payload2) {
      // Validate input indices
      if (startIdx < 0 || endIdx >= payload1.length || startIdx > endIdx) {
        // console.error('Invalid indices provided.');
        return;
      }

      // Extract the objects from payload2 between the specified indices
      const objectsToInsert = payload2.slice(startIdx, endIdx + 1);

      // Insert the objects from payload2 into payload1 after the start index
      payload1.splice(startIdx + 1, 0, ...objectsToInsert);
      // console.log(payload1);
    },
    addLevelToStructure(payload, currentLevel = []) {
      // console.log(payload, 'from function');
      for (let i = 0; i < payload.length; i++) {
        const org = payload[i];
        // console.log(org.subCategory,payload,currentLevel,this.tableData, 'ooooooooooo');
        // Add level to the organization
        const level = [...currentLevel, i + 1];
        org.level = level.join(".");

        // Process children if present
        if (this.module != "PCM") {
          if (org.child && org.child.length > 0) {
            // Recursively add level to child
            this.addLevelToStructure(org.child, level);
          }
        } else if (this.module == "PCM") {
          if (org.subCategory && org.subCategory.length > 0) {
            // Recursively add level to child
            this.addLevelToStructure(org.subCategory, level);
          }
        }
      }
    },
    findObjectByLevel(payload, level) {
      let resultObject = null;

      function recursiveSearch(data, level) {
        for (const org of data) {
          if (org.level === level) {
            resultObject = org;
            return;
          }

          if (org.children && org.children.length > 0) {
            recursiveSearch(org.children, level);
            if (resultObject) return;
          }
        }
      }

      recursiveSearch(payload, level);
      return resultObject;
    },
  },
};
</script>

<style lang="scss">
.hlx-btn-switch-group {
  width: auto !important;
}
</style>
