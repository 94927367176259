<template>
    <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
    <section class="lead-contact-modal">
        <div class="add-contact-drawer">
    <hlx-drawer
      :key="updateModalWindow"
      :show="isOpenContactDrawer"
      position="right"
      width="400"
      :footer="true"
      :title="'Add contact'"
      :show-close-icon="true"
      @close="closeContactModal"
    >
      <template #body>
        <div v-if="isOpenContactDrawer && !isEditContactDrawer" class="header-area-contact-drawer">
          <b>
            <!-- Add contact -->
                {{ loadStaticData("LANG-0047") }}
          </b>
        </div>
        <div v-if="isOpenContactDrawer && isEditContactDrawer" class="header-area-contact-drawer">
          <b>
            <!-- Edit contact -->
                {{ loadStaticData("LANG-0048") }}
          </b>
        </div>
        <!-- <section style="padding: 0px 14px 0 14px;display:none">
          <div style="margin-top: 24px">
            <hlx-select
              :key="componentKey"
              v-model:value="leadContactDetails.title"
              :pre-value="leadContactDetails.title"
              :inline-search="true"
              :options="titleData"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Title'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :display-error="true"
              :clearable="true"
              @selected-value="getTitleForContact"
            ></hlx-select>
          </div>
            <div>
              <hlx-input
                  v-model:value="leadContactDetails.firstName"
                 :pre-val="leadContactDetails.firstName"
                  :label-value="'First name'"
                  :label-animation="true"
                  :required="true"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
            </div>
            <div>
              <hlx-input
              v-model:value="leadContactDetails.lastName"
             :pre-val="leadContactDetails.lastName"
                  :label-value="'Last name'"
                  :label-animation="true"
                  :required="true"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
            </div>
            <div>
              <hlx-select
          :key="componentKey"
          v-model:value="leadContactDetails.type"
              :pre-value="leadContactDetails.type"
              :inline-search="true"
              :options="leadContactType"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Type'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :display-error="true"
              :clearable="true"
              @selected-value="getTypeOfContact"
            ></hlx-select>
          </div>
          <div>
            <hlx-input
              v-model:value="leadContactDetails.contactMedium[0].characteristic.emailAddress"
             :pre-val="leadContactDetails.contactMedium[0].characteristic.emailAddress"
              type="email"
              :label-value="'Email id'"
              :required="true"
              :label-animation="true"
              :display-error="true"
              :custom-error="isExistingEmail"
              :custom-error-message="isExistingEmailErrorMessage"
               @focus-out="validateEmailNew"
              @at-input="validateEmail"
              ></hlx-input>
            </div>
              <div>
                <hlx-input
              v-model:value="leadContactDetails.contactMedium[2].characteristic.telephoneNumber.value"
             :pre-val="leadContactDetails.contactMedium[2].characteristic.telephoneNumber.value"
                  :label-value="'Telephone number'"
                  :label-animation="true"
                  :required="true"
                  type="number"
                  :display-error="true"
                  :custom-error="isExistingName"
                  :custom-error-message="isExistingNameErrorMessage"
                  @focus-out="validateNameNew"
                  @at-input="validateName"
                ></hlx-input>
              </div>
              <div>
                {{leadContactDetails.contactMedium[1].characteristic.phoneNumber.value}}
                <hlx-input
              v-model:value="leadContactDetails.contactMedium[1].characteristic.phoneNumber.value"
             :pre-val="leadContactDetails.contactMedium[1].characteristic.phoneNumber.value"
              type="phonenumber"
              :label-value="'Phone number'"
              :label-animation="true"
              :display-error="true"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
              ></hlx-input>
              </div>
              <div>
                <hlx-select
          :key="componentKey"
            v-model:value="leadContactDetails.preferredLanguage"
              :pre-value="leadContactDetails.preferredLanguage"
              :inline-search="true"
              :options="specdata"
              :required="true"
              :slot-options="false"
              :placeholder-value="'Preferred language'"
              :prop-value="'name'"
              :label="'name'"
              :label-animation="true"
              :display-error="true"
              :clearable="true"
              @selected-value="handleSpecChanges"
            ></hlx-select>
          </div>
      </section> -->
      <div class="parent-container-for-lead-contact">
        <fieldRenderer
              :key="updateFieldRenderKey"
              :schema-payload = "customFieldLeadContactSchema"
              :data-payload = "schemaProp"
              :layout="'1-column'"
              :is-add-clicked = "isAddButtonClicked"
              :is-save-clicked="isSaveButtonClicked"
              :status-list="[
                'Open',
                'In Discussion',
                'Qualified',
                'Unqualified',
                'Converted',
                'Closed Lost',
              ]"
              :parent-class-name="'parent-container-for-lead-contact'"
              @edited-payload="patchEditedLead"
               @updated-payload="leadWholePayload"
            ></fieldRenderer>
      </div>
      </template>
      <template #footer>
        <div
          style="
            display: flex;
            justify-content: flex-end;
            padding: 19px;
            border-top: 1px solid #d8d8d8;
          "
        >
          <hlx-button class="secondary sm" @click="closeContactModal"
            >
            <!-- Cancel -->
                {{ loadStaticData("LANG-0020") }}
            </hlx-button
          >
          <hlx-button
           v-if="!isEditContactDrawer && isOpenContactDrawer"
            class="primary sm"
            style="margin-left: 10px"
            @click="addNewContact"
            >
            <!-- Add -->
                {{ loadStaticData("LANG-0010") }}
            </hlx-button
          >
          <hlx-button
           v-if="isEditContactDrawer && isOpenContactDrawer"
            class="primary sm"
            style="margin-left: 10px"
            @click="saveEditedContact"
            >
            <!-- Save -->
                {{ loadStaticData("LANG-0030") }}
            </hlx-button
          >
        </div>
      </template>
    </hlx-drawer>
  </div>
    </section>

    <!-- lead contact top -->
  <section class="lead-top">
    <span class="lead-sub-header">
      <!-- Contacts -->
                {{ loadStaticData("LANG-0049") }}
    </span>
    <section v-if="contactOrgData.length > 0" class="lead-search-top">

              <span class="search-container">
                <hlx-search
                :label-value="loadStaticData('LANG-0003')"
                 @search-key="searchKey"></hlx-search>
              </span>
              <!-- <span class="filter-table">
                    <hlx-button
                      class="secondary sm add-btn"
                      @click="showDrawerRight"
                    >
                      <i class="icon-filter-regular"></i>
                       <span v-if="filtertaglength != 0" class="filter-count">{{
                        filtertaglength
                      }}</span>
                    </hlx-button>
                  </span> -->
              <hlx-button
                class="primary sm"
                @click="openAddContactDrawer"
              >
                <i class="icon-plus-circle-regular"></i> 
                <!-- Add contact -->
                {{ loadStaticData("LANG-0047") }}
              </hlx-button>
            </section>
  </section>

      <!-- Table  -->
      <section v-if="tableData.length > 0" class="lead-table" style="margin-bottom: 4rem">
        <hlx-table
            :label-value="tableModalLang"
            :column-count="leadContactTableheaders.length + 2"
            :border="['table', 'header', 'horizontal']"
            :row-hover="true"
            theme="grey"
            :re-arrange-columns="toggleRearrangeModal"
            :re-arrange-heads="leadTableheadersCopy"
            @close-rearrange="toggleRearrangeModal = false"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
                <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
             @new-resize-width="resizedWidth"
            >
            <!-- {{ "S.no" }} -->
                {{ loadStaticData("LANG-0046") }}
          </hlx-table-head>
            
              <hlx-table-head
                v-for="(i, index) in leadContactTableheaders"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
                 @new-resize-width="resizedWidth"
              >
                {{ i.label }}</hlx-table-head
              >
              <hlx-table-head
                :align="'center'"
                :width="70"
                :fixed="'right'"
                 @new-resize-width="resizedWidth"
                ><span class="action-icon">
                  <i
                  style="cursor: pointer"
                  class="icon-settings-regular"
                @click="reAssignOrgTableHeader()"
                  ></i> </span
              ></hlx-table-head>
            </template>

            <!-- table with data -->
            <template v-if="tableData?.length>0" #tbody>
              
            <tr v-for="(i, index) in paginatedData" id="" :key="index">
                <hlx-table-cell :align="'center'" :fixed="'left'">
                    {{  serialNumber(index) }}
                </hlx-table-cell>
                <hlx-table-cell
                  v-for="(j, col_index) in leadContactTableheaders"
                  :key="col_index"
                  :align="j.align"
                  :fixed="j.fixed"
                >
                  <div
                  v-if="j.prop.toLowerCase() === 'name'"
                  style="display: flex"
                    @click="editContactDrawer(index)"
                  >
                    <span class="link-name">
                      {{ i['title'] ? i['title'] + '.' + i["firstName"] +" "+ i["lastName"] :  i["firstName"] +" "+ i["lastName"] || ''}}
                    </span>
                    <span v-if="i.preferred" style="align-items: center; display: flex">
                      <i class="icon-check-circle-filled" style="color: #54bd95; font-size: 14px"
                       ></i>
                     </span> 
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'type'">
                    <span>
                      {{ i?.["contactType"] }}
                    </span>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'contactmedium'" style="margin: 6px 0 6px 0;">
                    <div style="display: flex;align-items: center;gap:0.5rem ;margin-bottom:2px;">
                      <i class="icon-envelope-regular"></i> 
                      <span>
                        {{ i?.['contactMedium'][0]?.characteristic?.emailAddress }}
                      </span>
                    </div>
                    <div style="display: flex;align-items: center;gap:0.5rem; margin-bottom:2px;">
                      <i class="icon-phone-regular"></i> 
                      <span>
                        {{ i?.['contactMedium'][1]?.characteristic?.phoneNumber?.value?.value }}
                        </span>
                    </div>
                    <div style="display: flex;align-items: center;gap:0.5rem; margin-bottom:2px;">
                      <i class="icon-phone-office-regular"></i> 
                      <span>
                        {{ i?.['contactMedium'][2]?.characteristic?.telephoneNumber?.value?.value  }}
                        </span>
                    </div>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'status'">
                    <span v-if="i[j.prop] != ''">
                      <div>
                        <hlx-status :type="i['status']"></hlx-status>
                      </div>
                    </span>
                    <span v-else>
                      {{ "-" }}
                    </span>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'sites'">
                      <span>
                        {{ i["numberOfSites"] || '-'}}
                      </span>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'createdat'">
                      <span>
                        {{ dateAndTimeFormatter(i["createdAt"])}}
                      </span>
                  </div>
                  <div v-else-if="j.prop.toLowerCase() === 'lastupdated'">
                      <span>
                        {{ dateAndTimeFormatter(i["lastUpdate"])}}
                      </span>
                  </div>
                  <div v-else>{{ i[j.prop] }}</div>
                </hlx-table-cell>
                <hlx-table-cell
                  :align="'center'"
                  :width="120"
                  :fixed="'right'"
                >
                  <span :id="'table-context' + serialNumber(index - 1)" class="action-icon">
                    <i
                      style="position: relative"
                      class="icon-more-vertical-filled"
                      :class="i.context === true ? 'active-action' : ''"
                      @click="crudContextMenu($event,  serialNumber(index - 1))"
                    >
                      <hlx-context-menu
                        :top="containertop"
                        :display="'display'"
                        :left="containerleft - 20"
                        :conditionvalue="150"
                        :conditiondown="2"
                        :conditionup="-38"
                        :options="computedTableAction(i)"
                        :data="index.toString()"
                        :show="i.context == true"
                        @chosen="closeCrudContextMenu($event, i,   serialNumber(index - 1))"
                      />
                    </i>
                  </span>
                </hlx-table-cell>
              </tr>
            </template>
          </hlx-table>
      </section>

      <!-- Table for no data -->
      <section v-if="( tableData.length <= 0 && searchvalue !== '')"  class="lead-table">
        <hlx-table
            :label-value="tableModalLang"
            :column-count="leadContactTableheaders.length + 2"
            :border="['table', 'header']"
            :row-hover="true"
            theme="grey"
            :re-arrange-columns="toggleRearrangeModal"
            :re-arrange-heads="leadTableheadersCopy"
            @close-rearrange="toggleRearrangeModal = false"
            @apply-rearranged-columns="applyColumns"
          >
            <template #thead>
                <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
             @new-resize-width="resizedWidth"
            >
            <!-- {{ "S.no" }} -->
                {{ loadStaticData("LANG-0046") }}
          </hlx-table-head>
            
              <hlx-table-head
                v-for="(i, index) in leadContactTableheaders"
                :key="index"
                :prop="i.prop"
                :sortable="i.sortable"
                :resizable="i.resizable"
                :width="i.width !== undefined ? i.width : ''"
                :align="i.align !== undefined ? i.align : ''"
                @sorting_func="sorting_Data"
                 @new-resize-width="resizedWidth"
              >
                {{ i.label }}</hlx-table-head
              >
              <hlx-table-head
                :align="'center'"
                :width="70"
                :fixed="'right'"
                 @new-resize-width="resizedWidth"
                ><span class="action-icon">
                  <i
                  style="cursor: pointer"
                  class="icon-settings-regular"
                @click="reAssignOrgTableHeader()"
                  ></i> </span
              ></hlx-table-head>
            </template>

            <!-- table without data )-->
            <template  #tbody>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell :colspan="leadContactTableheaders.length + 2">
                        <!-- {{
                        "No data"
                      }} -->
                {{ loadStaticData("LANG-0050") }}
                      </hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
                    <tr>
                      <hlx-table-cell></hlx-table-cell>
                    </tr>
        </template>
          </hlx-table>
      </section>


      <!-- Pagination -->
      <div
         v-if="tableData.length > 0"
        class="lead-contact-pagination-container"
        :style="{ padding: '1rem 0',width:'calc(100% - 220px)'}"
      >
        <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
          :label-value=paginationLang
          :total="tableData.length"
          enable-rows-per-page
          :rows-per-page-list="[10, 15, 20]"
          :options="{
            attributes: {
              rowsPerPageTitle: true,
              rowsPerPageResult: true,
              rowsPerPageDropdown: {
                position: {
                  top: 0,
                  right: 1,
                },
              },
            },
          }"
          @current-page="currentPage"
          @updated:rows-per-page="changeRowsPerPage"
        ></hlx-pagination>
      </div>
    
    <!-- table data image - illustration -->
    <section
      v-if="tableData.length <= 0 && searchvalue == ''"
      style="
        margin-top: 3.2rem;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        align-items: center;
      "
    >
      <img
        src="@/assets/images/leadcontactillustration.svg"
        alt=""
        style="width: 400px"
      />

      <span>
          <!-- Add button -->
          <hlx-button class="primary sm" @click="openAddContactDrawer">
            <i class="icon-plus-circle-regular"></i>
             <!-- Add contact -->
             {{ loadStaticData("LANG-0047") }}
          </hlx-button>
        </span>
    </section>


</template>

<script>
import fieldRenderer from "@/components/FieldRenderer.vue";
export default {
  components: {
    fieldRenderer,
  },
  props:{
    schemaForDrawer:{
      type:Object,
      default: ()=>{
        return {}
      }
    },
    contactData:{
      type:Array,
      default: ()=>{
        return []
      }
    },
    schemaForContactModal:{
      type:Object,
      default: ()=>{
        return {}
      }
    },
    dataForContactModal:{
      type:Object,
      default: ()=>{
        return {}
      }
    },
    isSaveClicked: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    payload:{
      type:Object,
      default: ()=>{
        return {}
      }
    }
  },
emits:["updated-payload",'delete-contact','new-contact'],
data(){
    return{
      notification:{},
      customFieldLeadContactSchemaForDrawer:{},
      temporaryLeadContactPayload:null,
      currPage: 1,
      rowsPerPage: 10,
      updateModalWindow:0,
      editIndex:null,
      isEditContactDrawer:false,
      leadWholePayload:null,
      updateFieldRenderKey:1,
      customFieldLeadContactSchema:{},
      schemaProp:{},
      isSaveButtonClicked:false,
      isAddButtonClicked:false,
      leadContactDetailsEmpty:{
            title:"",
            createdAt:'',
            lastUpdate:'',
            firstName:"",
            lastName:"",
            contactType:"",
            preferredLanguage:"",
            contactMedium:[
            {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
            },
              {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
            {
            mediumType: "telephone",
            characteristic: {
              telephoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
            ]
          },
      leadContactDetails:{
            title:"",
            firstName:"",
            createdAt:'',
            lastUpdate:'',
            lastName:"",
            contactType:"",
            preferredLanguage:"",
            contactMedium:[
            {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
            },
              {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
            {
            mediumType: "telephone",
            characteristic: {
              telephoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
            ]
          },
      leadContactTableheaders:[
        {
          checked:true,
          sortable: false,
          resizable: true,
          disabled:true,
          width: null,
          name:"Name",
          label: this.loadStaticData('LANG-0053') || "Name",
          prop: "name",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 180,
          disabled:true,
          name:"type",
          label: this.loadStaticData('LANG-0054') || "Type",
          prop: "type",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 180,
          name:"Preferred language",
          label: this.loadStaticData('LANG-0055') || "Preferred language",
          prop: "preferredLanguage",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 160,
          name:"contact",
          label: this.loadStaticData('LANG-0056') || "Contact medium",
          prop: "contactmedium",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 180,
          name:"Created at",
          label: this.loadStaticData('LANG-0044') || "Created at",
          prop: "createdat",
          align: "left",
        },
        {
          checked:true,
          sortable: false,
          resizable: true,
          width: 180,
          name:"Last updated",
          label: this.loadStaticData('LANG-0045') || "Last updated",
          prop: "lastUpdated",
          align: "left",
        },
      ],
      searchvalue:"",
      tableData:[],
      contactOrgData:[],
//       payload:{
//     "_id": "673dbaaba19dbcf668406d86",
//     "name": "saleslead",
//     "schema": {
//         "id": {
//             "type": "String",
//             "system": true
//         },
//         "dwellingtype": {
//             "type": "String",
//             "system": true
//         },
//         "displayID": {
//             "type": "String",
//             "system": true
//         },
//         "description": {
//             "type": "String",
//             "system": true
//         },
//         "creationDate": {
//             "type": "Date",
//             "system": true
//         },
//         "lastUpdate": {
//             "type": "Date",
//             "system": true
//         },
//         "estimatedRevenue": {
//             "type": "Mixed",
//             "system": true
//         },
//         "href": {
//             "type": "String",
//             "system": true
//         },
//         "name": {
//             "type": "String",
//             "system": true
//         },
//         "priority": {
//             "type": "String",
//             "system": true
//         },
//         "rating": {
//             "type": "String",
//             "system": true
//         },
//         "status": {
//             "type": "String",
//             "system": true
//         },
//         "statusChangeReason": {
//             "type": "String",
//             "system": true
//         },
//         "statusChangeDate": {
//             "type": "Date",
//             "system": true
//         },
//         "referredDate": {
//             "type": "Date",
//             "system": true
//         },
//         "channel": {
//             "type": "Mixed",
//             "system": true
//         },
//         "type": {
//             "type": "String",
//             "system": true
//         },
//         "category": {
//             "type": "Mixed",
//             "system": true
//         },
//         "validFor": {
//             "type": "Mixed",
//             "system": true
//         },
//         "contactMedium": {
//             "type": "Array",
//             "system": true
//         },
//         "note": {
//             "type": "Array",
//             "system": true
//         },
//         "relatedParty": {
//             "type": "Array",
//             "system": true
//         },
//         "productOffering": {
//             "type": "Mixed",
//             "system": true
//         },
//         "salesOpportunity": {
//             "type": "Mixed",
//             "system": true
//         },
//         "marketingCampaign": {
//             "type": "Mixed",
//             "system": true
//         },
//         "productSpecification": {
//             "type": "Mixed",
//             "system": true
//         },
//         "marketSegment": {
//             "type": "Mixed",
//             "system": true
//         },
//         "createdBy": {
//             "type": "String",
//             "system": true
//         },
//         "_id": {
//             "type": "ObjectID",
//             "system": true
//         }
//     },
//     "type": "manual",
//     "isReference": false,
//     "dataCount": 0,
//     "lastModifiedBy": null,
//     "references": [],
//     "createdAt": "2024-11-20T10:32:11.915Z",
//     "updatedAt": "2024-11-20T10:32:11.915Z",
//     "__v": 0
// },

        isOpenContactDrawer:false,
    }
},
computed:{
  tableModalLang() { 
      return {
          re_arrange_columns : this.loadStaticData('LANG-0026'),
          Cancel:this.loadStaticData('LANG-0020'),
          Apply: this.loadStaticData('LANG-0027'),
          SelectAll: this.loadStaticData('LANG-0028')
        }
    }
    ,    
    paginationLang() {     
      return {
          Rows_per_page : this.loadStaticData("LANG-0012"),
          Showing: this.loadStaticData("LANG-0013"),
          of: this.loadStaticData("LANG-0014"),
          results: this.loadStaticData("LANG-0015"),
          Go_to: this.loadStaticData("LANG-0016")
        }
      
  },
    
  paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
},
  watch:{
    schemaForDrawer:{
      handler(val){
        if(val){
          this.customFieldLeadContactSchemaForDrawer =  JSON.parse(JSON.stringify(val))
          console.log("this.customFieldLeadContactSchemaForDrawer",this.customFieldLeadContactSchemaForDrawer)
        }
      },
      immediate:true,
      deep:true
    },
    contactData:{
      handler(val){
        if(val){
          this.contactOrgData = JSON.parse(JSON.stringify(val))
          this.tableData =  JSON.parse(JSON.stringify(val))
          console.log("this.tableData contact",this.tableData)
        }
      },
      immediate:true,
      deep:true
    },
    payload:{
      handler(val){
        if(val){
          console.log("this.payload",val)
          this.leadWholePayload = JSON.parse(JSON.stringify(val))
        }
      },
      immediate:true,
      deep:true
    },
    schemaForContactModal:{
      handler(val){
        if(val){
          this.customFieldLeadContactSchema =  JSON.parse(JSON.stringify(val))
          console.log("this.customFieldLeadContactSchema",this.customFieldLeadContactSchema)
        }
      },
      immediate:true,
      deep:true
    },
    dataForContactModal:{
      handler(val){
        if(val){
          // this.schemaProp =  JSON.parse(JSON.stringify(val))
          this.schemaProp = JSON.parse(JSON.stringify(this.leadContactDetailsEmpty))
          console.log("this.schemaProp",this.schemaProp,val)
        }
      },
      immediate:true,
      deep:true
    },
  },
mounted(){

},
methods:{
  searchKey(val) {
      this.searchvalue = val;
      if (val !== "") {
        let searchData = this.searchNestedObjects(
          this.contactOrgData,
          val.toLowerCase()
        );
        this.tableData = JSON.parse(JSON.stringify(searchData));
      } else {
        this.tableData = JSON.parse(JSON.stringify(this.contactOrgData));
      }
    },
    searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },
  async closeCrudContextMenu($event, ele, i) {
    console.log("Delete Edit emit ", $event, "event", "ele", ele, "i", i);
      if ($event.toLowerCase() === "mark as primary") {
        this.tableData = this.tableData.map((obj) => {
    delete obj.preferred; 
    return obj;
});
if (this.tableData[i]) {
  this.tableData[i].context = false;
    this.tableData[i].preferred = true;
    this.tableData[i].lastUpdate = new Date().toJSON()
}
this.leadWholePayload.relatedParty=  this.leadWholePayload.relatedParty.filter(ele=>ele['@type'] != 'individual')
const notAnContact = JSON.parse(JSON.stringify(this.leadWholePayload.relatedParty))
this.leadWholePayload.relatedParty=[]
this.leadWholePayload.relatedParty  = JSON.parse(JSON.stringify(notAnContact.concat(this.tableData)))
    console.log(this.tableData,"leadWholePayload",this.leadWholePayload,"notAnContact",notAnContact)
    this.$emit("updated-payload",this.leadWholePayload)
console.log("this.tableData",this.tableData)
// this.saveEditedContact()
          console.log(ele,"mark as primary",i)
      } else if ($event.toLowerCase() == "edit") {
        console.log(i)
        this.editContactDrawer(i)
        // this.openEditLeadContactDrawer(i)
      }
      else if ($event.toLowerCase() == "delete") {
        if(this.tableData.length == 1){
          return  this.notification = {
          type: "warning",
          message: `You can't delete all contact!`,
        };
        }
        else{
        console.log("Delete Edit emit ", $event, "event", "ele", ele, "i", i);
        this.$emit("delete-contact",ele)  
        } 
      }
  },
  serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
  changeRowsPerPage(count) {
     this.rowsPerPage = count;
 },
 crudContextMenu($event, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 143;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
  currentPage(val) {
 
     this.currPage = val;

   this.table_height = this.paginatedData.length * 40 + 43;
 },
  openEditLeadContactDrawer(){
    this.isEditContactDrawer = true
    this.isOpenContactDrawer=true
  },
  addNewContact(){
    this.temporaryLeadContactPayload.context = false
    this.isAddButtonClicked = true;
    this.temporaryLeadContactPayload.createdAt = new Date().toJSON()
    this.temporaryLeadContactPayload.lastUpdate = new Date().toJSON()
    const updatedVal = { ...this.temporaryLeadContactPayload, '@type': 'individual' };
    console.log("Updated lead:", updatedVal);
    if( this.leadWholePayload && updatedVal){
    this.leadWholePayload.relatedParty.push(updatedVal)
    this.$emit("new-contact",this.leadWholePayload)
    this.isOpenContactDrawer = false
    }
  },
  saveEditedContact(){
    this.temporaryLeadContactPayload.context = false
    this.isSaveButtonClicked = true
    this.temporaryLeadContactPayload.lastUpdate = new Date().toJSON()
    console.log(this.temporaryLeadContactPayload,"this.leadWholePayload.relatedParty",this.leadWholePayload.relatedParty)
      this.leadWholePayload.relatedParty=  this.leadWholePayload.relatedParty.filter(ele=>ele['@type'] != 'individual')
      const notAnContact = JSON.parse(JSON.stringify(this.leadWholePayload.relatedParty))
      this.tableData = [
    ...this.tableData.slice(0, this.editIndex),
    this.temporaryLeadContactPayload,
    ...this.tableData.slice(this.editIndex + 1)
];
this.leadWholePayload.relatedParty=[]
this.leadWholePayload.relatedParty  = JSON.parse(JSON.stringify(notAnContact.concat(this.tableData)))
    console.log(this.tableData,"leadWholePayload",this.leadWholePayload,"notAnContact",notAnContact)
    this.isSaveButtonClicked = false
    this.$emit("updated-payload",this.leadWholePayload)
    this.isOpenContactDrawer = false
  },
  patchEditedLead(val){
    // val.context=false
    // if(this.isSaveButtonClicked){
    //   console.log(val,"this.leadWholePayload.relatedParty",this.leadWholePayload.relatedParty)
    //   this.leadWholePayload.relatedParty=  this.leadWholePayload.relatedParty.filter(ele=>ele['@type'] != 'individual')
    //   const notAnContact = JSON.parse(JSON.stringify(this.leadWholePayload.relatedParty))
    //   this.tableData = [
    // ...this.tableData.slice(0, this.editIndex),
    // val,
    // ...this.tableData.slice(this.editIndex + 1)
// ];
// this.leadWholePayload.relatedParty=[]
// this.leadWholePayload.relatedParty  = JSON.parse(JSON.stringify(notAnContact.concat(this.tableData)))
//     console.log(this.tableData,"leadWholePayload",this.leadWholePayload,"notAnContact",notAnContact)
    this.temporaryLeadContactPayload = val
    // this.$emit("updated-payload",this.leadWholePayload)
    // this.isSaveButtonClicked = false
    // }
// if(this.isAddButtonClicked){
    // console.log("patchEditedLead", val);
    //   this.isAddButtonClicked = false;
    //   const updatedVal = { ...val, '@type': 'individual' };
    // console.log("Updated lead:", updatedVal);
    // if( this.leadWholePayload && updatedVal){
    // this.leadWholePayload.relatedParty.push(updatedVal)
    // this.temporaryLeadAddContactPayload = this.leadWholePayload
    // console.log("leadWholePayload",this.leadWholePayload)
    // // this.$emit("new-contact",this.leadWholePayload)
    // }
    // }
  },
  closeContactModal(){
    this.updateModalWindow +=1 
    this.updateFieldRenderKey += 1
    this.isOpenContactDrawer = false
    this.customFieldLeadContactSchema = JSON.parse(JSON.stringify(this.schemaForContactModal)) 
    this.schemaProp = JSON.parse(JSON.stringify(this.leadContactDetailsEmpty))
  },
    openAddContactDrawer(){
      this.isEditContactDrawer = false
      this.updateFieldRenderKey += 1
        this.isOpenContactDrawer = true
        this.customFieldLeadContactSchema = JSON.parse(JSON.stringify(this.customFieldLeadContactSchemaForDrawer)) 
        this.schemaProp = JSON.parse(JSON.stringify(this.leadContactDetailsEmpty))
   },
    editContactDrawer(index){
      this.isOpenContactDrawer = true
      this.editIndex = index
      this.customFieldLeadContactSchema = JSON.parse(JSON.stringify(this.customFieldLeadContactSchemaForDrawer)) 
    this.leadContactDetails =  this.tableData[index]
    this.schemaProp = JSON.parse(JSON.stringify(this.tableData[index]))
    console.log(this.schemaProp,"this.tableData[index]", this.tableData[index])
    this.openEditLeadContactDrawer(index)
    },
    computedTableAction(data) {
      let options = [
        {
          display: this.loadStaticData('LANG-0052'),
          label: "Mark as primary",
          icon: "icon-check-circle-regular",
        },
        {
          display: this.loadStaticData('LANG-0009'),
          label: "Edit",
          icon: "icon-file-text-edit-regular",
        },
        {
          display: this.loadStaticData('LANG-0011'),
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ];
      return options.filter((option) => {
        if (
          data &&
          data.status &&
          data.status.toLowerCase() == "active" &&
          ["Mark as Active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "inactive" &&
          ["Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else {
          return true;
        }
      });
    },
}
}
</script>

<style>
.lead-contact-pagination-container{
  position: absolute;
    bottom: 0;
    right: 0;
    padding-left: 86px !important;
    padding-right: 24px !important;
    z-index: 990;
    background-color: white;
}
 @media screen and (max-width: 768px) {
.lead-contact-pagination-container{
  position: absolute;
    bottom: 0;
    right: 0;
    padding-left: 86px !important;
    padding-right: 24px !important;
    z-index: 999;
    background-color: white;
    width:100% !important;
}
 }
</style>