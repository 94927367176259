<template>
  <div
    v-if="type === 'rule'"
    class="rules-parent"
  >
    <!-- <div class="breadcrumb-container"><hlx-breadcrumb :seperator="'/'" :crumbs="this.crumbs" @selected="selectedCrumb"/></div> -->

    <!-- <div class="header">Rules</div>
      <div class="sub-header">Available rules</div> -->
    <section class="dep-tree-container">
      <div class="utils">
        <div class="rule-details">
          <span style="font-size: 14px">{{
            'Rule name: ' + ruleName
          }}</span>
        </div>
        <div class="control-container">
          <hlx-button
            ref="graphCenterButton"
            class="primary sm centre-graph"
            @click="graphToCenter()"
            >Centre</hlx-button
          >
          <hlx-button
            class="primary sm"
            @click="$refs.graph?.fitToContents()"
            >Fit</hlx-button
          >
          <hlx-button
            class="primary sm"
            @click="$refs.graph?.zoomIn()"
            ><i
              class="icon-search-plus-regular"
              style="font-size: 18px"
            ></i
          ></hlx-button>
          <hlx-button
            class="primary sm"
            @click="$refs.graph?.zoomOut()"
            ><i
              class="icon-search-minus-regular"
              style="font-size: 18px"
            ></i
          ></hlx-button>
        </div>
      </div>

      <!-- class="graph" -->
      <v-network-graph
        ref="graph"
        class="graph"
        :event-handlers="eventHandlers"
        :nodes="nodes"
        :edges="edges"
        :paths="paths"
        :layouts="layouts"
        :configs="configs"
        @node:click="call"
      >
        <template
          #override-node-label="{
            nodeId,
            scale,
            text,
            x,
            y,
            config,
            textAnchor,
            dominantBaseline,
          }"
        >
          <text
            x="0"
            y="0"
            :font-size="9 * scale"
            text-anchor="middle"
            dominant-baseline="central"
            fill="black"
            >{{ nodes[nodeId].label }}</text
          >
          <text
            x="0"
            y="0"
            :font-size="config.fontSize * scale"
            :text-anchor="textAnchor"
            :dominant-baseline="dominantBaseline"
            :fill="'#000'"
            :transform="`translate(${x} ${y})`"
            >{{ text }}
          </text>
        </template>
        <!-- 
          <defs>
        <component :is="'style'">
          @font-face { font-family: 'Material Icons'; font-style: normal; font-weight:
          400; src:
          url(https://fonts.gstatic.com/s/materialicons/v97/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2)
          format('woff2'); }
        </component>
      </defs> -->

        <!-- Replace the node component -->
        <!-- <template #override-node="{ nodeId, scale, config, ...slotProps }">
        <circle :r="config.radius * scale" :fill="config.color" v-bind="slotProps" />
        <text
          font-family="Material Icons"
          :font-size="22 * scale"
          fill="#161c28"
          text-anchor="middle"
          dominant-baseline="central"
          style="pointer-events: none"
          v-html="nodes[nodeId].icon"
        />
      </template> -->
      </v-network-graph>
    </section>
    <hlx-modal
      :modal-active="isRuleContainer"
      :height="'400px'"
      :width="'600px'"
      :modal="true"
      @close="isRuleContainer = false"
    >
      <template #header>
        <div>{{ ruleData?.name || "-" }}</div>
      </template>
      <template #content>
        <div class="modal-content">
          <section class="rule-code-block">
            <p>{{ ruleData.ruleScript }}</p>
            <a href="" @click="pushRouteToRule">View rule</a>
          </section>
        </div>
      </template>
      <!-- <template v-slot:footer>
            <hlxbutton class="primary sm" @click="this.isRuleContainer = false"
              >Save</hlxbutton
            >
          </template> -->
    </hlx-modal>
  </div>
  <div
    v-if="type === 'node'"
    class="rules-parent"
  >
    <!-- <div class="breadcrumb-container"><hlx-breadcrumb :seperator="'/'" :crumbs="this.crumbs" @selected="selectedCrumb"/></div> -->

    <!-- <div class="header">Rules</div>
      <div class="sub-header">Available rules</div> -->
    <section class="dep-tree-container">
      <div class="utils">
        <div class="rule-details">
          <span style="font-size: 14px">{{ 'Node: ' + node }}</span>
        </div>
        <div class="control-container">
          <hlx-button
            ref="graphCenterButton"
            class="primary sm centre-graph"
            @click="graphToCenter()"
            >Centre</hlx-button
          >
          <hlx-button
            class="primary sm"
            @click="$refs.graph?.fitToContents()"
            >Fit</hlx-button
          >
          <hlx-button
            class="primary sm"
            @click="$refs.graph?.zoomIn()"
            ><i
              class="icon-search-plus-regular"
              style="font-size: 18px"
            ></i
          ></hlx-button>
          <hlx-button
            class="primary sm"
            @click="$refs.graph?.zoomOut()"
            ><i
              class="icon-search-minus-regular"
              style="font-size: 18px"
            ></i
          ></hlx-button>
        </div>
      </div>

      <!-- class="graph" -->
      <v-network-graph
        ref="graph"
        class="graph"
        :event-handlers="eventHandlers"
        :nodes="nodes"
        :edges="edges"
        :paths="paths"
        :layouts="layouts"
        :configs="configs"
        @node:click="call"
      >
        <template
          #override-node-label="{
            nodeId,
            scale,
            text,
            x,
            y,
            config,
            textAnchor,
            dominantBaseline,
          }"
        >
          <text
            x="0"
            y="0"
            :font-size="9 * scale"
            text-anchor="middle"
            dominant-baseline="central"
            fill="black"
            >{{ nodes[nodeId].label }}</text
          >
          <text
            x="0"
            y="0"
            :font-size="config.fontSize * scale"
            :text-anchor="textAnchor"
            :dominant-baseline="dominantBaseline"
            :fill="'#000'"
            :transform="`translate(${x} ${y})`"
            >{{ text }}
          </text>
        </template>
        <!-- 
          <defs>
        <component :is="'style'">
          @font-face { font-family: 'Material Icons'; font-style: normal; font-weight:
          400; src:
          url(https://fonts.gstatic.com/s/materialicons/v97/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2)
          format('woff2'); }
        </component>
      </defs> -->

        <!-- Replace the node component -->
        <!-- <template #override-node="{ nodeId, scale, config, ...slotProps }">
        <circle :r="config.radius * scale" :fill="config.color" v-bind="slotProps" />
        <text
          font-family="Material Icons"
          :font-size="22 * scale"
          fill="#161c28"
          text-anchor="middle"
          dominant-baseline="central"
          style="pointer-events: none"
          v-html="nodes[nodeId].icon"
        />
      </template> -->
      </v-network-graph>
    </section>
    <hlx-modal
      :modal-active="isRuleContainer"
      :height="'400px'"
      :width="'600px'"
      :modal="true"
      @close="isRuleContainer = false"
    >
      <template #header>
        <div>{{ ruleData?.name }}</div>
      </template>
      <template #content>
        <div class="modal-content">
          <section class="rule-code-block">
            <p>{{ ruleData.ruleScript }}</p>
            <a href="" @click="pushRouteToRule">View rule</a>
          </section>
        </div>
      </template>
      <!-- <template v-slot:footer>
            <hlxbutton class="primary sm" @click="this.isRuleContainer = false"
              >Save</hlxbutton
            >
          </template> -->
    </hlx-modal>
  </div>
    <div
    v-if="type === 'audit'"
    class="rules-parent"
  >
    <!-- <div class="breadcrumb-container"><hlx-breadcrumb :seperator="'/'" :crumbs="this.crumbs" @selected="selectedCrumb"/></div> -->

    <!-- <div class="header">Rules</div>
      <div class="sub-header">Available rules</div> -->
    <section class="dep-tree-container">
      <div class="utils">
        <div class="rule-details">
          <span style="font-size: 14px">{{
            'Rule name: ' + ruleName
          }}</span>
        </div>
        <div class="control-container">
          <hlx-button
            ref="graphCenterButton"
            class="primary sm centre-graph"
            @click="graphToCenter()"
            >Centre</hlx-button
          >
          <hlx-button
            class="primary sm"
            @click="$refs.graph?.fitToContents()"
            >Fit</hlx-button
          >
          <hlx-button
            class="primary sm"
            @click="$refs.graph?.zoomIn()"
            ><i
              class="icon-search-plus-regular"
              style="font-size: 18px"
            ></i
          ></hlx-button>
          <hlx-button
            class="primary sm"
            @click="$refs.graph?.zoomOut()"
            ><i
              class="icon-search-minus-regular"
              style="font-size: 18px"
            ></i
          ></hlx-button>
        </div>
      </div>

      <!-- class="graph" -->
      <v-network-graph
        ref="graph"
        class="graph"
        :event-handlers="eventHandlers"
        :nodes="nodes"
        :edges="edges"
        :paths="paths"
        :layouts="layouts"
        :configs="configs"
        @node:click="call"
      >
        <template
          #override-node-label="{
            nodeId,
            scale,
            text,
            x,
            y,
            config,
            textAnchor,
            dominantBaseline,
          }"
        >
          <text
            x="0"
            y="0"
            :font-size="9 * scale"
            text-anchor="middle"
            dominant-baseline="central"
            fill="black"
            >{{ nodes[nodeId].label }}</text
          >
          <text
            x="0"
            y="0"
            :font-size="config.fontSize * scale"
            :text-anchor="textAnchor"
            :dominant-baseline="dominantBaseline"
            :fill="'#000'"
            :transform="`translate(${x} ${y})`"
            >{{ text }}
          </text>
        </template>
        <!-- 
          <defs>
        <component :is="'style'">
          @font-face { font-family: 'Material Icons'; font-style: normal; font-weight:
          400; src:
          url(https://fonts.gstatic.com/s/materialicons/v97/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2)
          format('woff2'); }
        </component>
      </defs> -->

        <!-- Replace the node component -->
        <!-- <template #override-node="{ nodeId, scale, config, ...slotProps }">
        <circle :r="config.radius * scale" :fill="config.color" v-bind="slotProps" />
        <text
          font-family="Material Icons"
          :font-size="22 * scale"
          fill="#161c28"
          text-anchor="middle"
          dominant-baseline="central"
          style="pointer-events: none"
          v-html="nodes[nodeId].icon"
        />
      </template> -->
      </v-network-graph>
    </section>
    <hlx-modal
      :modal-active="isRuleContainer"
      :height="'400px'"
      :width="'600px'"
      :modal="true"
      @close="isRuleContainer = false"
    >
      <template #header>
        <div>{{ ruleData.name || "-" }}</div>
      </template>
      <template #content>
        <div class="modal-content">
          <section class="rule-code-block">
            <p>{{ ruleData.ruleScript }}</p>
            <a href="" @click="pushRouteToRule">View rule</a>
          </section>
        </div>
      </template>
      <!-- <template v-slot:footer>
            <hlxbutton class="primary sm" @click="this.isRuleContainer = false"
              >Save</hlxbutton
            >
          </template> -->
    </hlx-modal>
  </div>
</template>

<script>
// import { ref } from "vue"
import { DMNAxiosInstance } from '@/config/axiosConfig';
import { reactive } from 'vue';
import dagre from 'dagre/dist/dagre.min.js';
import * as vNG from 'v-network-graph';
const nodeSize = 40;

// const eventHandlers = {
//   "node:click": (node) => {

//     // toggle
//     // nodes[node].active = !nodes[node].active
//     
//   },
// }
export default {
  name: 'App',
  components: {},
  props: {
    ruleId: {
      type: String,
      default: ''
    },
    ruleName: {
      type: String,
      default: ''
    },
    node: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      company: 'Halleyx',
      protocol: 'http',
      // http:process.env.VUE_APP_PROTOCOL,
      port: '5080',
      host: 'localhost',
      id: 'tenant1',
      name: 'myself',

      crumbs: [
        { name: 'Home', icon: 'icon-home' },
        { name: 'Rules', icon: '' },
      ],
      ruleData: {
        _id: '63bcdaa55fb63b1c08a37956',
        name: 'QuotePrice',
        ruleScript:
          'quote.quoteTotalPrice = quote.quoteItem.reduce (function(a, b) { return a.price + b.price })',
        ruleExpression: {
          '=': [
            'quote.quoteTotalPrice',
            {
              'quote.quoteItem.reduce (function(a, b) { return a.price + b.price })':
                [],
            },
          ],
        },
        description: 'About sample',
        _isExistBypass: false,
        ruleSet: [],
        createdBy: '639af00f9f515f57074584ef',
        lastModifiedBy: null,
        createdAt: '2023-01-10T03:25:25.939Z',
        updatedAt: '2023-01-10T03:25:25.939Z',
        __v: 0,
      },
      isRuleContainer: false,
      eventHandlers: {
        'node:click': async ({ node }) => {
          // toggle
          // nodes[node].active = !nodes[node].active

          await this.getRuleData(node);
          
        },
        // "node:dblclick": (node) => {
        //   // toggle
        //   // nodes[node].active = !nodes[node].active
        //   
        // },
      },
      paths: {
        path1: {
          edges: ['E1', 'E2', 'E3', 'E4'],
          color: '#ff00ff66', // #rrggbbaa <- with alpha
        },
        // path2: {
        //   edges: ["edge2", "edge4", "edge6", "edge10"],
        //   color: "#00aa0066", // #rrggbbaa <- with alpha
        // },
      },
      layouts: {
        nodes: {
          node1: { x: 0, y: 0 },
          node2: { x: -50, y: 50 },
          node3: { x: 50, y: 70 },
          node4: { x: 150, y: 150 },
        },
      },
      // graph:vNG,
      configs: reactive(
        vNG.defineConfigs({
          path: {
            visible: false,
            end: 'edgeOfNode',
            normal: {
              width: 10,
              color: (p) => p.color,
            },
            transition: 'entering',
          },
          node: {
            //  selectable: true,
            normal: {
              radius: nodeSize / 2,
              color: this.calculatedNodeBG,
              type: (node) => (node.type == 'rule' ? 'circle' : 'rect'),
              // label: { direction: "south", color: "#191a15" ,text:"name"},
              strokeWidth: 2,
              strokeColor: this.calculatedStrokeBG,
              // strokeColor:node => node.status ==undefined ?"#30946d":node.status == "failed"?"#fc3232":"#00954a",
            },
            hover: {
              // color:node => node.status ==undefined ?"#54bd95":node.status == "failed"?"#ff5050":"#02b55c"
              color: this.calculatedHoverBG,
            },
            label: {
              visible: true,
              fontSize: 11,
              lineHeight: 1.1,
              color: '#191a15',
              margin: 4,
              direction: 'south',
              text: 'name',
              directionAutoAdjustment: true,
            },
            draggable: false,
            focusring: {
              color: '#ff00dd30', // alpha
            },
          },
          edge: {
            normal: {
              color: this.calculatedEdgeBG,
              width: 3,
              linecap: 'butt',
            },
            hover: {
              // default: {
              color: this.calculatedEdgeHover,

              // }
            },
            margin: 4,
            marker: {
              target: {
                type: 'arrow',
                width: 4,
                height: 4,
              },
            },
          },

          view: {
            scalingObjects: true,
            minZoomLevel: 0.1,
            maxZoomLevel: 16,
          },
        })
      ),
      nodes: {},
      edges: {},
    };
  },
  computed: {
    f() {
      return '#54bd95';
    },
  },
  watch: {
    ruleId: {
      async handler() {
        
        if (this.type === 'audit' && this.ruleId !== '') {
          
      //     let depTreeRes = await DMNAxiosInstance.get(
      //       `util/detailed_execution_log/${this.ruleId}`
      // );
          let depTreeRes = await DMNAxiosInstance.get(
            `util/detailed_execution_log/${this.ruleId}`
      );
        
      // dep tree
      depTreeRes.data = depTreeRes.data.data;
      this.nodes = depTreeRes.data.nodes;
      this.edges = depTreeRes.data.edges;
      // complete tree
      // depTreeRes.data = depTreeRes.data.data
      // this.nodes = depTreeRes.data.nodes
      // this.edges = depTreeRes.data.edges
      this.$nextTick(() => {
        // function layout(direction: "TB" | "LR") {
        if (
          Object.keys(this.nodes).length <= 1 ||
          Object.keys(this.edges).length == 0
        ) {
          return;
        }

        // convert graph
        // ref: https://github.com/dagrejs/dagre/wiki
        const g = new dagre.graphlib.Graph();
        // Set an object for the graph label
        g.setGraph({
          rankdir: 'TB',
          nodesep: nodeSize * 3,
          edgesep: nodeSize,
          ranksep: nodeSize * 1.5,
        });
        // Default to assigning a new object as a label for each new edge.
        g.setDefaultEdgeLabel(() => ({}));

        // Add nodes to the graph. The first argument is the node id. The second is
        // metadata about the node. In this case we're going to add labels to each of
        // our nodes.
        Object.entries(this.nodes).forEach(([nodeId, node]) => {
          g.setNode(nodeId, {
            label: node.name,
            width: nodeSize,
            height: nodeSize,
          });
        });

        // Add edges to the graph.
        Object.values(this.edges).forEach((edge) => {
          g.setEdge(edge.source, edge.target);
        });

        dagre.layout(g);

        const box = {};
        g.nodes().forEach((nodeId) => {
          // update node position
          const x = g.node(nodeId).x;
          const y = g.node(nodeId).y;
          this.layouts.nodes[nodeId] = { x, y };

          // calculate bounding box size
          box.top = box.top ? Math.min(box.top, y) : y;
          box.bottom = box.bottom ? Math.max(box.bottom, y) : y;
          box.left = box.left ? Math.min(box.left, x) : x;
          box.right = box.right ? Math.max(box.right, x) : x;
        });

        // const graphMargin = nodeSize * 2;
        // const viewBox = 
        // {
        //   top: (box.top ?? 0) - graphMargin,
        //   bottom: (box.bottom ?? 0) + graphMargin,
        //   left: (box.left ?? 0) - graphMargin,
        //   right: (box.right ?? 0) + graphMargin,
        // };
        
        // this.$refs.graph.value.setViewBox(viewBox)
        // }
      });
      // if(this.$refs.graph){
      //   
      // }
      // this.$refs.graph?.fitToContents()
    }
      },
      immediate: true,
      deep: true
    }
  },
  async mounted() {
    
    // 
    // let encoded_user = window.btoa("testuser@test.com: ").toString("base64");
    if (this.type === 'rule') {
      let depTreeRes = await DMNAxiosInstance.get(
        `util/dependency_tree/${this.ruleId}`
      );
      
      // dep tree
      depTreeRes.data = depTreeRes.data.data;
      this.nodes = depTreeRes?.data?.nodes;
      this.edges = depTreeRes?.data?.edges;
      // complete tree
      // depTreeRes.data = depTreeRes.data.data
      // this.nodes = depTreeRes.data.nodes
      // this.edges = depTreeRes.data.edges
      if(this.nodes){
      this.$nextTick(() => {
        // function layout(direction: "TB" | "LR") {
        if (
          Object.keys(this.nodes).length <= 1 ||
          Object.keys(this.edges).length == 0
        ) {
          return;
        }

        // convert graph
        // ref: https://github.com/dagrejs/dagre/wiki
        const g = new dagre.graphlib.Graph();
        // Set an object for the graph label
        g.setGraph({
          rankdir: 'TB',
          nodesep: nodeSize * 3,
          edgesep: nodeSize,
          ranksep: nodeSize * 1.5,
        });
        // Default to assigning a new object as a label for each new edge.
        g.setDefaultEdgeLabel(() => ({}));

        // Add nodes to the graph. The first argument is the node id. The second is
        // metadata about the node. In this case we're going to add labels to each of
        // our nodes.
        Object.entries(this.nodes).forEach(([nodeId, node]) => {
          g.setNode(nodeId, {
            label: node.name,
            width: nodeSize,
            height: nodeSize,
          });
        });

        // Add edges to the graph.
        Object.values(this.edges).forEach((edge) => {
          g.setEdge(edge.source, edge.target);
        });

        dagre.layout(g);

        const box = {};
        g.nodes().forEach((nodeId) => {
          // update node position
          const x = g.node(nodeId).x;
          const y = g.node(nodeId).y;
          this.layouts.nodes[nodeId] = { x, y };

          // calculate bounding box size
          box.top = box.top ? Math.min(box.top, y) : y;
          box.bottom = box.bottom ? Math.max(box.bottom, y) : y;
          box.left = box.left ? Math.min(box.left, x) : x;
          box.right = box.right ? Math.max(box.right, x) : x;
        });

        // const graphMargin = nodeSize * 2;
        // const viewBox = {
        //   top: (box.top ?? 0) - graphMargin,
        //   bottom: (box.bottom ?? 0) + graphMargin,
        //   left: (box.left ?? 0) - graphMargin,
        //   right: (box.right ?? 0) + graphMargin,
        // };
        
        // this.$refs.graph.value.setViewBox(viewBox)
        // }
      });
    }
      // if(this.$refs.graph){
      //   
      // }
      // this.$refs.graph?.fitToContents()
    } else if (this.type === 'node') {
      let depTreeRes = await DMNAxiosInstance.get(
        `util/dependency_tree/${this.node}`
      );
      
      // dep tree
      depTreeRes.data = depTreeRes.data.data;
      this.nodes = depTreeRes.data.nodes;
      this.edges = depTreeRes.data.edges;
      // complete tree
      // depTreeRes.data = depTreeRes.data.data
      // this.nodes = depTreeRes.data.nodes
      // this.edges = depTreeRes.data.edges
      this.$nextTick(() => {
        // function layout(direction: "TB" | "LR") {
        if (
          Object.keys(this.nodes).length <= 1 ||
          Object.keys(this.edges).length == 0
        ) {
          return;
        }

        // convert graph
        // ref: https://github.com/dagrejs/dagre/wiki
        const g = new dagre.graphlib.Graph();
        // Set an object for the graph label
        g.setGraph({
          rankdir: 'TB',
          nodesep: nodeSize * 3,
          edgesep: nodeSize,
          ranksep: nodeSize * 1.5,
        });
        // Default to assigning a new object as a label for each new edge.
        g.setDefaultEdgeLabel(() => ({}));

        // Add nodes to the graph. The first argument is the node id. The second is
        // metadata about the node. In this case we're going to add labels to each of
        // our nodes.
        Object.entries(this.nodes).forEach(([nodeId, node]) => {
          g.setNode(nodeId, {
            label: node.name,
            width: nodeSize,
            height: nodeSize,
          });
        });

        // Add edges to the graph.
        Object.values(this.edges).forEach((edge) => {
          g.setEdge(edge.source, edge.target);
        });

        dagre.layout(g);

        const box = {};
        g.nodes().forEach((nodeId) => {
          // update node position
          const x = g.node(nodeId).x;
          const y = g.node(nodeId).y;
          this.layouts.nodes[nodeId] = { x, y };

          // calculate bounding box size
          box.top = box.top ? Math.min(box.top, y) : y;
          box.bottom = box.bottom ? Math.max(box.bottom, y) : y;
          box.left = box.left ? Math.min(box.left, x) : x;
          box.right = box.right ? Math.max(box.right, x) : x;
        });

        // const graphMargin = nodeSize * 2;
        // const viewBox = {
        //   top: (box.top ?? 0) - graphMargin,
        //   bottom: (box.bottom ?? 0) + graphMargin,
        //   left: (box.left ?? 0) - graphMargin,
        //   right: (box.right ?? 0) + graphMargin,
        // };
        
        // this.$refs.graph.value.setViewBox(viewBox)
        // }
      });
      // if(this.$refs.graph){
      //   
      // }
      // this.$refs.graph?.fitToContents()
    } 
    // else if (this.type === 'audit') {
    //   

    //   let depTreeRes = await DMNAxiosInstance.get(
    //     `util/detailed_execution_log/${this.ruleId}`
    //   );
    //   // dep tree
    //   depTreeRes.data = depTreeRes.data.data;
    //   this.nodes = depTreeRes.data.nodes;
    //   this.edges = depTreeRes.data.edges;
    //   // complete tree
    //   // depTreeRes.data = depTreeRes.data.data
    //   // this.nodes = depTreeRes.data.nodes
    //   // this.edges = depTreeRes.data.edges
    //   this.$nextTick(() => {
    //     // function layout(direction: "TB" | "LR") {
    //     if (
    //       Object.keys(this.nodes).length <= 1 ||
    //       Object.keys(this.edges).length == 0
    //     ) {
    //       return;
    //     }

    //     // convert graph
    //     // ref: https://github.com/dagrejs/dagre/wiki
    //     const g = new dagre.graphlib.Graph();
    //     // Set an object for the graph label
    //     g.setGraph({
    //       rankdir: 'TB',
    //       nodesep: nodeSize * 3,
    //       edgesep: nodeSize,
    //       ranksep: nodeSize * 1.5,
    //     });
    //     // Default to assigning a new object as a label for each new edge.
    //     g.setDefaultEdgeLabel(() => ({}));

    //     // Add nodes to the graph. The first argument is the node id. The second is
    //     // metadata about the node. In this case we're going to add labels to each of
    //     // our nodes.
    //     Object.entries(this.nodes).forEach(([nodeId, node]) => {
    //       g.setNode(nodeId, {
    //         label: node.name,
    //         width: nodeSize,
    //         height: nodeSize,
    //       });
    //     });

    //     // Add edges to the graph.
    //     Object.values(this.edges).forEach((edge) => {
    //       g.setEdge(edge.source, edge.target);
    //     });

    //     dagre.layout(g);

    //     const box = {};
    //     g.nodes().forEach((nodeId) => {
    //       // update node position
    //       const x = g.node(nodeId).x;
    //       const y = g.node(nodeId).y;
    //       this.layouts.nodes[nodeId] = { x, y };

    //       // calculate bounding box size
    //       box.top = box.top ? Math.min(box.top, y) : y;
    //       box.bottom = box.bottom ? Math.max(box.bottom, y) : y;
    //       box.left = box.left ? Math.min(box.left, x) : x;
    //       box.right = box.right ? Math.max(box.right, x) : x;
    //     });

    //     const graphMargin = nodeSize * 2;
    //     const viewBox = {
    //       top: (box.top ?? 0) - graphMargin,
    //       bottom: (box.bottom ?? 0) + graphMargin,
    //       left: (box.left ?? 0) - graphMargin,
    //       right: (box.right ?? 0) + graphMargin,
    //     };
    //     
    //     // this.$refs.graph.value.setViewBox(viewBox)
    //     // }
    //   });
    //   // if(this.$refs.graph){
    //   //   
    //   // }
    //   // this.$refs.graph?.fitToContents()
    // }
    setTimeout(() => {
      this.$refs.graph?.fitToContents();
      this.$refs.graph?.panToCenter();
    }, 100);
  },
  methods: {
    calculatedEdgeBG(edge) {
      switch (edge.status) {
        case 'success':
          return '#02b55c';
        case 'failed':
          return '#ff5050';
        case 'pending':
          return '#a6a6a6';
        default:
          return '#54bd95';
      }
    },
    calculatedEdgeHover(node) {
      switch (node.status) {
        case 'success':
          return '#00954a';
        case 'failed':
          return '#fc3232';
        case 'pending':
          return '#a6a6a6';
        default:
          return '#30946d';
      }
    },
    calculatedNodeBG(node) {
      switch (node.status) {
        case 'success':
          return '#dcf9ea';
        case 'failed':
          return '#ffe8e8';
        case 'pending':
          return '#ebebeb';
        default:
          return '#a8dec9';
      }
    },

    calculatedStrokeBG(node) {
      switch (node.status) {
        case 'success':
          return '#00954a';
        case 'failed':
          return '#fc3232';
        case 'pending':
          return '#a6a6a6';
        default:
          return '#30946d';
      }
    },
    calculatedHoverBG(node) {
      switch (node.status) {
        case 'success':
          return '#02b55c';
        case 'failed':
          return '#ff5050';
        case 'pending':
          return '#d0c9d6';
        default:
          return '#54bd95';
      }
    },
    graphToCenter() {
      // 
      this.$refs.graph?.panToCenter();
    },
    async getRuleData(ruleId) {
      try {
        // let encoded_user = window
        //   .btoa("testuser@test.com: ")
        //   .toString("base64");
        let rules = await DMNAxiosInstance.get(`/rule_engine/rule/${ruleId}`);
        this.ruleData = rules?.data?.data;
        this.isRuleContainer = !this.isRuleContainer;
        console.log("getRuleData",this.ruleData)
        
      } catch (error) {
        console.error(error);
      }
    },
    pushRouteToRule(){
      console.log("push route to rule Table",this.ruleData);
      if (this.ruleData.type.toLowerCase() == "rule") {

        this.$router.push({ name: "rule-view", params: { id: this.ruleData["_id"] } });

      } else if (this.ruleData.type.toLowerCase() == "expression") {

        this.$router.push({
          name: "expression-view",
          params: { id: this.ruleData["_id"] },
        });

      }
      
      else if (this.ruleData.type.toLowerCase() == "decisionmatrix") {

        this.$router.push({
          name: "decision-view",
          params: { id: this.ruleData["_id"] },
        });

      }
      
      
      else if (this.ruleData.type.toLowerCase() == "decision") {
        this.$router.push({
          name: "decision-view",
          params: { id: this.ruleData["decisionId"] },
        });

      }
    },
  },
};
</script>

<style lang="scss">
.dep-tree-container {
  .utils {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  .control-container {
    display: flex;
    gap: 10px;
  }
}
.graph {
  width: auto;
  height: 385px;
  border: 1px solid #000;
  border-radius: 10px;
}
.rule-code-block {
  display: flex;
  flex-direction: column;
  padding: 10px;
  a {
    text-decoration: none;
    color: var(--hlx-color-link);
    cursor: pointer;
    align-self: flex-end;
    &:hover {
      color: rgba(var(--hlx-color-link-rgb), 0.6);
    }
  }
}
</style>
