<template>
 <br/>
  <div
    class="toolbar"
    style="display: flex; align-items: center; justify-content: space-between"
  >
    <span>
      <hlx-switch
        v-if="showSwitch && staticData"
        v-model="selectedTab"
        :switch-items="tabData"
        value-prop="name"
        display-prop="label"
      />
      <span v-else>
        <b v-if="module === 'Products'">
          <!-- {{ "Products" }} -->
          <span v-if="staticData">{{ staticData?.cmAccountLeftPanelProducts?.label[loginUserDetails]?.label }}</span>
        </b>
        <b v-if="module === 'Contracts'">
          <!-- {{ "Contracts" }} -->
          <span v-if="staticData">{{ staticData?.cmAccountLeftPanelContracts?.label[loginUserDetails]?.label }}</span>
        </b>
        <!-- <b v-if="module === 'inventory'">{{ "Inventory" }}</b> -->
      </span>
    </span>
    <span
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <hlx-search
      :label-value=staticData?.search?.label[loginUserDetails]?.label
      ></hlx-search>
      &nbsp; &nbsp; &nbsp;
      <hlx-button class="secondary sm">
        <i class="icon-filter-regular"></i>
      </hlx-button>
      <span v-if="module === 'Quotes and Orders'">
        <hlx-button
          class="primary sm"
          style="margin-left: 16px"
          @click="noteDelete"
          >
          <!-- Create -->
          <span v-if="staticData">{{ staticData?.create?.label[loginUserDetails]?.label }}</span>
           {{ selectedTab }}</hlx-button
        >
      </span>
      <span v-if="module === 'inventory'">
        <hlx-button
          class="primary sm"
          style="margin-left: 16px"
          @click="noteDelete"
          >
          <!-- Add item -->
          <span v-if="staticData">{{ staticData?.addItem?.label[loginUserDetails]?.label }}</span>
          </hlx-button
        >
      </span>
    </span>
  </div>

  <!-- //----- -->
  <span v-if="module !== 'inventory'">
  <br />
</span>
  <hlx-table
    :column-count="tableHeads + 2"
    :border="['table', 'header', 'horizontal']"
    :theme="'grey'"
  >
    <template #thead>
      <hlx-table-head
        :width="60"
        :align="'left'"
        style="padding: 0 15px !important; max-width: 60px !important"
        :fixed="'left'"
        >{{ "S.no" }}</hlx-table-head
      >
      <hlx-table-head
        v-for="(i, index) in tableHeads"
        :key="index"
        :prop="i.prop"
        :width="i.width"
        :align="'left'"
        :resizable="true"
      >
        {{ i.label }}
      </hlx-table-head>
      <hlx-table-head :align="'center'" :width="70" :fixed="'right'"
        ><span class="action-icon">
          <i class="icon-settings-regular" style="cursor: default"></i> </span
      ></hlx-table-head>
    </template>
    <template #tbody>
      <tr v-for="(i, index) in tbody" :key="index">
        <hlx-table-cell :width="60" fixed="left">
          {{ serialNumber(index) }}
        </hlx-table-cell>
        <hlx-table-cell
          v-for="(j, col_index) in tableHeads"
          :key="col_index"
          :align="'left'"
          :width="i.width"
        >
          <span v-if="j.prop === 'Status'">
            <span v-if="i[j.prop] == 'In use'">
              <hlx-status
                :type="'custom'"
                :point-color="'rgba(2, 181, 92, 1)'"
                :status-value="i[j.prop]"
              ></hlx-status>
            </span>
            <span v-if="i[j.prop] == 'Assigned'">
              <hlx-status
                :type="'custom'"
                :point-color="'rgba(38, 94, 75, 1)'"
                :status-value="i[j.prop]"
              ></hlx-status>
            </span>
            <span v-if="i[j.prop] == 'Available'">
              <hlx-status
                :type="'custom'"
                :point-color="'rgba(249, 166, 69, 1)'"
                :status-value="i[j.prop]"
              ></hlx-status>
            </span>
            <span v-else>
              <hlx-status :type="i[j.prop]"></hlx-status>
            </span>
            <div
              v-if="
                openNestedRow && index === 0 && selectedTab === 'Transactions'
              "
              style="height: 150px; width: 100%"
            ></div>
          </span>
          <span
            v-else-if="j.prop === 'Name' && selectedTab === 'Internet'"
            style="display: flex; flex-direction: column; padding: 10px 0"
          >
            {{ i[j.prop] }}
            <span style="font-size: 12px">{{
              `\nData: 20GB
Voice: Unlimited
Texts : Unlimited
Contract validity: 24 Month
`
            }}</span>
          </span>
          <span
            v-else-if="
              j.prop === 'Service address' && selectedTab === 'Equipment'
            "
            style="display: flex; flex-direction: column; padding: 10px 0"
          >
            {{ i[j.prop] }}
          </span>
          <span
            v-else-if="selectedTab === 'Transactions'"
            style="display: flex; flex-direction: column; padding: 10px 0"
          >
            <div style="display: flex">
              <span
                ><i
                  v-if="j.prop === 'Transaction date'"
                  style="margin-right: 10px"
                  :class="
                    openNestedRow
                      ? 'icon-angle-down-regular'
                      : 'icon-angle-right-regular'
                  "
                  @click="openNestedRow = !openNestedRow"
                ></i
              ></span>
              <span>{{ i[j.prop] }}</span>
            </div>
            <div
              v-if="
                openNestedRow && index === 0 && j.prop === 'Transaction date'
              "
              style="
                margin-top: 20px;
                height: 130px;
                width: 100%;
                font-size: 12px;
              "
            >
              <span style="display: flex; justify-content: center; gap: 16px"
                ><span style="width: 90px">Plan name</span>
                <span style="width: 200px"
                  >V4 Full Fibre - 40</span
                ></span
              >
              <br />
              <span style="display: flex; justify-content: center; gap: 16px"
                ><span style="width: 90px">Invoice</span>
                <span style="width: 200px"> #0014 </span></span
              >
              <br />
              <span style="display: flex; justify-content: center; gap: 16px"
                ><span style="width: 90px">Payment</span>
                <span style="width: 200px">
                  Credit card (Master card) (8002)
                </span></span
              >
              <br />
              <span style="display: flex; justify-content: center; gap: 16px"
                ><span style="width: 90px">Transaction</span>
                <span style="width: 200px">
                  uf_gspr_2024/10 tpes tjrs
                </span></span
              >
            </div>
            <div
              v-if="openNestedRow && index === 0 && j.prop === 'Type'"
              style="
                margin-top: 20px;
                height: 130px;
                width: 100%;
                font-size: 12px;
              "
            ></div>
            <div
              v-if="openNestedRow && index === 0 && j.prop === 'Price (GBP)'"
              style="
                margin-top: 20px;
                height: 130px;
                width: 100%;
                font-size: 12px;
              "
            ></div>
          </span>
          <span v-else-if="j.prop === 'Auto-renew'">
            <hlx-input type="switch" :checked="i[j.prop]"></hlx-input>
          </span>
          <span v-else>
            {{ i[j.prop] }}
          </span>
        </hlx-table-cell>
        <hlx-table-cell :align="'center'" :width="120" :fixed="'right'">
          <span :id="'table-context' + index" class="action-icon">
            <i
              style="position: relative"
              class="icon-more-vertical-filled"
              :class="i.context === true ? 'active-action' : ''"
              @click="crudContextMenu($event, i, index)"
            >
              <hlx-context-menu
                :top="containertop"
                :display="'display'"
                :left="containerleft"
                :conditionvalue="150"
                :conditiondown="2"
                :conditionup="-38"
                :options="computedTableAction(i)"
                :data="index.toString()"
                :show="i.context == true"
                @chosen="closeCrudContextMenu($event, i, index)"
              />
            </i>
          </span>
        </hlx-table-cell>
      </tr>
    </template>
  </hlx-table>
</template>

<script>
import {
  // DMNAxiosInstance,
  UPCAxiosInstance,
  // MLTAxiosInstance,
} from "@/config/axiosConfig";
export default {
  props: {
    module: {
      type: String,
      default: "Billing",
    },
  },
  data() {
    return {
      loginUserDetails: {},
      staticData:null,
      openNestedRow: false,
      showSwitch: false,
      selectedTab: "Transactions",
      tabData: [
        {
          name: "Transactions",
          value: "Transactions",
        },
        {
          name: "Invoices",
          value: "Invoices",
        },
      ],
      tableHeads: [],
      tbody: [],
    };
  },
  watch: {
    staticData: {
      handler(val) {
        val
        if (val) {
          val

          if (this.module === "Billing") {
          this.showSwitch = true;
          this.selectedTab = "Transactions";
          this.tabData = [
            {
              name: "Transactions",
              value: "Transactions",
              label: this.staticData?.transactions?.label[this.loginUserDetails]?.label
            },
            {
              name: "Invoices",
              value: "Invoices",
              label: this.staticData?.invoices?.label[this.loginUserDetails]?.label
            },
          ];
        } else if (this.module === "Billing2") {
          this.showSwitch = true;
          this.selectedTab = "Transactions";
          this.tabData = [
            {
              name: "Transactions",
              value: "Transactions",
              label: this.staticData?.transactions?.label[this.loginUserDetails]?.label
            },
            {
              name: "Invoices",
              value: "Invoices",
              label: this.staticData?.invoices?.label[this.loginUserDetails]?.label
            },
          ];
        } else if (this.module === "Quotes and Orders") {
          this.showSwitch = true;
          this.selectedTab = "Quotes";
          this.tabData = [
            {
              name: "Quotes",
              value: "Quotes",
              label: this.staticData?.quotes?.label[this.loginUserDetails]?.label
            },
            {
              name: "Orders",
              value: "Orders",
              label: this.staticData?.orders?.label[this.loginUserDetails]?.label
            },
          ];
        } else if (this.module === "Contracts") {
          //
          this.selectedTab = "";
          this.tabData = [];
          this.showSwitch = false;
        } else if (this.module === "inventory") {
          //
          this.selectedTab = "";
          this.tabData = [];
          this.showSwitch = false;
        } else if (this.module === "Products") {
          this.selectedTab = "Internet";
          this.tabData = [
            {
              name: "Internet",
              value: "Internet",
              label: this.staticData?.internet?.label[this.loginUserDetails]?.label
            },
            {
              name: "Equipment",
              value: "Equipment",
              label: this.staticData?.equipment?.label[this.loginUserDetails]?.label
            },
          ];
          this.showSwitch = true;
        }
       
         
        }
      },
      immediate: true,
      deep: true,
    },
    module: {
      handler(val) {
        if (val === "Billing") {
          this.showSwitch = true;
          this.selectedTab = "Transactions";
          this.tabData = [
            {
              name: "Transactions",
              value: "Transactions",
            },
            {
              name: "Invoices",
              value: "Invoices",
            },
          ];
        } else if (val === "Billing2") {
          this.showSwitch = true;
          this.selectedTab = "Transactions";
          this.tabData = [
            {
              name: "Transactions",
              value: "Transactions",
            },
            {
              name: "Invoices",
              value: "Invoices",
            },
          ];
        } else if (val === "Quotes and Orders") {
          this.showSwitch = true;
          this.selectedTab = "Quotes";
          this.tabData = [
            {
              name: "Quotes",
              value: "Quotes",
            },
            {
              name: "Orders",
              value: "Orders",
            },
          ];
        } else if (val === "Contracts") {
          //
          this.selectedTab = "";
          this.tabData = [];
          this.showSwitch = false;
        } else if (val === "inventory") {
          //
          this.selectedTab = "";
          this.tabData = [];
          this.showSwitch = false;
        } else if (val === "Products") {
          this.selectedTab = "Internet";
          this.tabData = [
            {
              name: "Internet",
              value: "Internet",
            },
            {
              name: "Equipment",
              value: "Equipment",
            },
          ];
          this.showSwitch = true;
        }
      },
      immediate: true,
      deep: true,
    },
    selectedTab: {
      handler(val) {
        if (val === "Invoices" && this.module === "Billing") {
          this.tableHeads = [
            {
              label: "Invoice id",
              prop: "Invoice id",
            },
            {
              label: "Plan name",
              prop: "Plan name",
            },
            {
              label: "Billing date",
              prop: "Billing date",
            },
            {
              label: "Status",
              prop: "Status",
            },
            {
              label: "Price (GBP)",
              prop: "Price (GBP)",
            },
          ];

          this.tbody = [
            {
              "Invoice id": "Invoice #0014 - Apr 2024",
              "Plan name": "V4 Full Fibre - 40",
              "Billing date": "Apr 20, 2024",
              Status: "Paid",
              "Price (GBP)": "£27.99",
            },
            {
              "Invoice id": "Invoice #0026 - Feb 2024",
              "Plan name": "V4 Full Fibre - 40",
              "Billing date": "Mar 20, 2024",
              Status: "Paid",
              "Price (GBP)": "£27.99",
            },
            {
              "Invoice id": "Invoice #0013 - Mar 2024",
              "Plan name": "V4 Full Fibre - 40",
              "Billing date": "Feb 20, 2024",
              Status: "Paid",
              "Price (GBP)": "£27.99",
            },
            {
              "Invoice id": "Invoice #0025 - Mar 2024",
              "Plan name": "V4 Full Fibre - 40",
              "Billing date": "Jan 20, 2024",
              Status: "Paid",
              "Price (GBP)": "£27.99",
            },
            {
              "Invoice id": "Invoice #0012 - Feb 2024",
              "Plan name": "V4 Full Fibre - 40",
              "Billing date": "Dec 20, 2023",
              Status: "Paid",
              "Price (GBP)": "£27.99",
            },
            {
              "Invoice id": "Invoice #0024 - Feb 2024",
              "Plan name": "V4 Full Fibre - 40",
              "Billing date": "Nov 20, 2023",
              Status: "Paid",
              "Price (GBP)": "£27.99",
            },
          ];
        } else if (val === "Invoices" && this.module === "Billing2") {
          this.tableHeads = [
            {
              label: "Invoice id",
              prop: "Invoice id",
            },
            {
              label: "Account name",
              prop: "Account name",
              width: 250,
            },
            {
              label: "Plan name",
              prop: "Plan name",
            },
            {
              label: "Billing date",
              prop: "Billing date",
            },
            {
              label: "Status",
              prop: "Status",
            },
            {
              label: "Price (GBP)",
              prop: "Price (GBP)",
            },
          ];

          this.tbody = [
            {
              "Invoice id": "Invoice #0014 - Apr 2024",
              "Plan name": "V4 Full Fibre - 40",
              "Billing date": "Apr 20, 2024",
              Status: "Paid",
              "Price (GBP)": "£27.99",
              "Account name": "Shoppers Drugmart",
            },
            {
              "Invoice id": "Invoice #0026 - Feb 2024",
              "Plan name": "V4 Full Fibre - 40",
              "Billing date": "Mar 20, 2024",
              Status: "Paid",
              "Price (GBP)": "£27.99",
              "Account name": "Shoppers Drugmart",
            },
            {
              "Invoice id": "Invoice #0013 - Mar 2024",
              "Plan name": "V4 Full Fibre - 40",
              "Billing date": "Feb 20, 2024",
              Status: "Paid",
              "Price (GBP)": "£27.99",
              "Account name": "Shoppers Drugmart",
            },
            {
              "Invoice id": "Invoice #0025 - Mar 2024",
              "Plan name": "V4 Full Fibre - 40",
              "Billing date": "Jan 20, 2024",
              Status: "Paid",
              "Price (GBP)": "£27.99",
              "Account name": "George Weston Limited",
            },
            {
              "Invoice id": "Invoice #0012 - Feb 2024",
              "Plan name": "V4 Full Fibre - 40",
              "Billing date": "Dec 20, 2023",
              Status: "Paid",
              "Price (GBP)": "£27.99",
              "Account name": "George Weston Limited",
            },
            {
              "Invoice id": "Invoice #0024 - Feb 2024",
              "Plan name": "V4 Full Fibre - 40",
              "Billing date": "Nov 20, 2023",
              Status: "Paid",
              "Price (GBP)": "£27.99",
              "Account name": "George Weston Limited",
            },
          ];
        } else if (val === "Transactions" && this.module === "Billing2") {
          this.tableHeads = [
            {
              label: "Transaction date",
              prop: "Transaction date",
              width: 350,
            },
            {
              label: "Account name",
              prop: "Account name",
              width: 250,
            },
            {
              label: "Type",
              prop: "Type",
              width: 250,
            },
            {
              label: "Status",
              prop: "Status",
              width: 250,
            },
            {
              label: "Price (GBP)",
              prop: "Price (GBP)",
              width: null,
            },
          ];

          this.tbody = [
            {
              "S no.": 1,
              "Transaction date": "Apr 20, 2024",
              Type: "Debit",
              Status: "Paid",
              "Price (GBP)": "£27.99",
              "Account name": "Shoppers Drugmart",
            },
            {
              "S no.": 2,
              "Transaction date": "Mar 20, 2024",
              Type: "Debit",
              Status: "Paid",
              "Price (GBP)": "£27.99",
              "Account name": "Shoppers Drugmart",
            },
            {
              "S no.": 3,
              "Transaction date": "Feb 20, 2024",
              Type: "Debit",
              Status: "Paid",
              "Price (GBP)": "£27.99",
              "Account name": "Shoppers Drugmart",
            },
            {
              "S no.": 4,
              "Transaction date": "Jan 20, 2024",
              Type: "Debit",
              Status: "Paid",
              "Price (GBP)": "£27.99",
              "Account name": "George Weston Limited",
            },
            {
              "S no.": 5,
              "Transaction date": "Dec 20, 2023",
              Type: "Debit",
              Status: "Paid",
              "Price (GBP)": "£27.99",
              "Account name": "George Weston Limited",
            },
            {
              "S no.": 6,
              "Transaction date": "Nov 20, 2023",
              Type: "Debit",
              Status: "Paid",
              "Price (GBP)": "£27.99",
              "Account name": "George Weston Limited",
            },
          ];
        } else if (val === "Transactions" && this.module === "Billing") {
          this.tableHeads = [
            {
              label: "Transaction date",
              prop: "Transaction date",
              width: 350,
            },
            {
              label: "Type",
              prop: "Type",
              width: 250,
            },
            {
              label: "Status",
              prop: "Status",
              width: 250,
            },
            {
              label: "Price (GBP)",
              prop: "Price (GBP)",
              width: null,
            },
          ];

          this.tbody = [
            {
              "S no.": 1,
              "Transaction date": "Apr 20, 2024",
              Type: "Debit",
              Status: "Paid",
              "Price (GBP)": "£27.99",
            },
            {
              "S no.": 2,
              "Transaction date": "Mar 20, 2024",
              Type: "Debit",
              Status: "Paid",
              "Price (GBP)": "£27.99",
            },
            {
              "S no.": 3,
              "Transaction date": "Feb 20, 2024",
              Type: "Debit",
              Status: "Paid",
              "Price (GBP)": "£27.99",
            },
            {
              "S no.": 4,
              "Transaction date": "Jan 20, 2024",
              Type: "Debit",
              Status: "Paid",
              "Price (GBP)": "£27.99",
            },
            {
              "S no.": 5,
              "Transaction date": "Dec 20, 2023",
              Type: "Debit",
              Status: "Paid",
              "Price (GBP)": "£27.99",
            },
            {
              "S no.": 6,
              "Transaction date": "Nov 20, 2023",
              Type: "Debit",
              Status: "Paid",
              "Price (GBP)": "£27.99",
            },
          ];
        } else if (val === "Internet" && this.module === "Products") {
          this.tableHeads = [
            {
              label: "Id",
              prop: "Id",
              width: 70,
            },
            {
              label: "Name",
              prop: "Name",
              width: 250,
            },
            {
              label: "Status",
              prop: "Status",
              width: 150,
            },
            {
              label: "Price (GBP)",
              prop: "Price (GBP)",
              width: 150,
            },
            {
              label: "Contract duration",
              prop: "Contract duration",
              width: 250,
            },
            {
              label: "Remaining contract period",
              prop: "Remaining contract period",
              width: null,
            },
          ];

          this.tbody = [
            {
              Id: "74937",
              Name: "V4 Full Fibre - 40",
              Status: "Active",
              "Price (GBP)": "£27.99 / Month",
              "Contract duration": "Apr 02, 2024 - Apr 02, 2026",
              "Remaining contract period": "22 months",
            },
          ];
        } else if (val === "Equipment" && this.module === "Products") {
          this.tableHeads = [
            {
              label: "Equipment type",
              prop: "Equipment type",
              width: 170,
            },
            {
              label: "Name",
              prop: "Name",
              width: 200,
            },
            {
              label: "Mac address",
              prop: "Mac address",
              width: 150,
            },
            {
              label: "Status",
              prop: "Status",
              width: 150,
            },
            {
              label: "Service address",
              prop: "Service address",
              width: 250,
            },
            {
              label: "Installation date",
              prop: "Installation date",
              width: null,
            },
          ];

          this.tbody = [
            {
              "Equipment type": "Router",
              Name: "Tp link Archer VR1210v",
              Status: "Active",
              "Mac address": "00:1A:2B:3C:4D:5E",
              "Service address":
                "697 Main Street, Manchester, M1 1AB, United Kingdom",
              "Installation date": "April 20, 2024",
            },
          ];
        } else if (val === "" && this.module === "Contracts") {
          this.tableHeads = [
            {
              label: "ID",
              prop: "ID",
              width: 100,
            },
            {
              label: "Contract name",
              prop: "Contract name",
              width: null,
            },
            {
              label: "Status",
              prop: "Status",
              width: 150,
            },
            {
              label: "Contract type",
              prop: "Contract type",
              width: 200,
            },
            {
              label: "Start date",
              prop: "Start date",
              width: 150,
            },
            {
              label: "Auto-renew",
              prop: "Auto-renew",
              width: 150,
            },
          ];

          this.tbody = [
            {
              ID: "732158",
              "Contract name": "Consulting Services Agreement",
              Status: "Active",
              "Contract type": "Service Agreement",
              "Start date": "Jan 10, 2024",
              "Auto-renew": true,
            },
            {
              ID: "495673",
              "Contract name": "Sales and Purchase Agreement",
              Status: "Active",
              "Contract type": "Purchase Agreement",
              "Start date": "Jan 13, 2024",
              "Auto-renew": true,
            },
            {
              ID: "821049",
              "Contract name": "Employee Confidentiality Agreement",
              Status: "Active",
              "Contract type": "Non-Disclosure Agreement (NDA)",
              "Start date": "Jan 16, 2024",
              "Auto-renew": false,
            },
            {
              ID: "364502",
              "Contract name": "Supply Chain Partnership Agreement",
              Status: "Active",
              "Contract type": "Partnership Agreement",
              "Start date": "Jan 17, 2024",
              "Auto-renew": false,
            },
          ];
        } else if (val === "" && this.module === "inventory") {
          this.tableHeads = [
            {
              label: "Item ID",
              prop: "ID",
              width: 100,
            },
            {
              label: "Serial Number",
              prop: "Serial",
              width: null,
            },
            {
              label: "Model Number",
              prop: "Model",
              width: 150,
            },
            {
              label: "Manufacturer",
              prop: "Manufacturer",
              width: 200,
            },
            {
              label: "Status",
              prop: "Status",
              width: 150,
            },
            {
              label: "Location type",
              prop: "Location",
              width: 150,
            },
            {
              label: "Address",
              prop: "Address",
              width: 150,
            },
            {
              label: "Assigned technician",
              prop: "Assigned",
              width: 150,
            },
            {
              label: "Purchase date",
              prop: "Purchase",
              width: 150,
            },
            {
              label: "Order id",
              prop: "Order",
              width: 150,
            },
            {
              label: "Condition",
              prop: "Condition",
              width: 150,
            },
          ];

          this.tbody = [
            {
              ID: "INV-00001",
              Serial: "M123456789",
              Model: "TM1602A",
              Manufacturer: "Arris",
              Status: "Available",
              Location: "Warehouse",
              Address: "123 King Street West, Toronto, Ontario, M5X 1A9",
              Assigned: "Mr.Benjamin Harris",
              Purchase: "10 Jul,2024",
              Order: "OR6486289",
              Condition: "New",
            },
            {
              ID: "INV-00002",
              Serial: "R987654321",
              Model: "RT-AC68U",
              Manufacturer: "Asus",
              Status: "Assigned",
              Location: "On-site",
              Address:
                "456 Granville Street, Vancouver,British Columbia, V6C 1T1",
              Assigned: "Mr.Daniel Martinez",
              Purchase: "09 Jul,2024",
              Order: "OR7388297",
              Condition: "Used",
            },
            {
              ID: "INV-00003",
              Serial: "R987654322",
              Model: "RT-AC68Y",
              Manufacturer: "Asus",
              Status: "In use",
              Location: "Subscriber",
              Address: "789 Rue Sainte-Catherine, Montreal, Quebec, H3B 1E3",
              Assigned: "Mr.Peter john",
              Purchase: "12 Jul,2024",
              Order: "OR7284998",
              Condition: "New",
            },
          ];
        } else if (val === "Quotes") {
          this.tableHeads = [
            {
              label: "ID",
              prop: "ID",
              width: 100,
            },
            {
              label: "Accounts",
              prop: "Accounts",
              width: 200,
            },
            {
              label: "Title",
              prop: "Title",
              width: 300,
            },
            {
              label: "Total (GBP)",
              prop: "Total (GBP)",
              width: 150,
            },
            {
              label: "Status",
              prop: "Status",
              width: 150,
            },
            {
              label: "Date",
              prop: "Date",
              width: 150,
            },
          ];

          this.tbody = [
            {
              ID: "129635",
              Status: "Active",
              Date: "Apr 10, 2024",
              Title: "Lightburn Quote",
              Accounts: "Lightburn Medical Centre",
              "Total (GBP)": "£ 35 / Month",
            },
            {
              ID: "129634",
              Status: "Active",
              Date: "Apr 12, 2024",
              Title: "Square apartments Quote",
              Accounts: "Norway SMB",
              "Total (GBP)": "£ 100 / Month",
            },
            {
              ID: "129633",
              Status: "Active",
              Date: "Apr 15, 2024",
              Title: "Manchester haven Quote",
              Accounts: "Acme",
              "Total (GBP)": "£ 400 / Month",
            },
            {
              ID: "129632",
              Status: "Inactive",
              Date: "Apr 18, 2024",
              Title: "Ridge apartments Quote",
              Accounts: "Acme",
              "Total (GBP)": "£ 330 / Month",
            },
          ];
        } else if (val === "Orders") {
          this.tableHeads = [
            {
              label: "ID",
              prop: "ID",
              width: 70,
            },
            {
              label: "Account",
              prop: "Account",
              width: null,
            },
            {
              label: "Created",
              prop: "Created",
              width: 150,
            },
            {
              label: "Amount (GBP)",
              prop: "Amount (GBP)",
              width: 150,
            },
            {
              label: "Status",
              prop: "Status",
              width: 200,
            },
            {
              label: "Last updated",
              prop: "Last updated",
              width: 250,
            },
          ];

          this.tbody = [
            {
              ID: "732158",
              Account: "Sarah Johnson",
              Created: "Apr 19, 2024",
              Status: "Completed",
              "Amount (GBP)": "£ 35 / Month",
              "Last updated": "Apr 13, 2024 8:45 PM",
            },
            {
              ID: "495673",
              Account: "John Doe",
              Created: "Apr 18, 2024",
              Status: "Completed",
              "Amount (GBP)": "£ 35 / Month",
              "Last updated": "Apr 17, 2024 8:45 PM",
            },
            {
              ID: "821049",
              Account: "Emily Davis",
              Created: "Apr 14, 2024",
              Status: "Completed",
              "Amount (GBP)": "£ 35 / Month",
              "Last updated": "Apr 24, 2024 8:45 PM",
            },
            {
              ID: "364502",
              Account: "Alex Brown",
              Created: "Apr 10, 2024",
              Status: "Completed",
              "Amount (GBP)": "£ 35 / Month",
              "Last updated": "Apr 27, 2024 8:45 PM",
            },
          ];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() { 
    await this.$store.dispatch("loginTenantDetails");
    await this.$store.dispatch("loginUserDetails");
    // loginUserDetails
    // await this.$store.dispatch("loginTenantDetails");
    // let r = await this.$store.getters.loginTenantDetails;
    console.log("loginTenantDetails resrfsef:", this.$store.getters.loginTenantDetails);
    console.log("loginUserDetails resrfsef:", this.$store.getters.loginUserDetails);

    this.loginUserDetails = this.$store?.getters?.loginUserDetails?.languageAbility[0]?.code;

    // this.loginUserDetails.languageAbility[0].name
    // this.loginUserDetails.languageAbility[0].code

    try {
      let organisationInfo = await UPCAxiosInstance.get('/multi-language');
      this.staticData = organisationInfo?.data?.filter(itm => itm && itm.modules)?.[0]?.modules;
      console.log("this.staticData:",organisationInfo , this.staticData);
      
    }
    catch (error) {
      console.error("error::", error)
    }

    
  },
  methods: {
    crudContextMenu($event, i, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 94;

        this.containertop =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().top + 25;
        this.tbody?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tbody[index].context = true;
      } else {
        this.tbody?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },

    computedTableAction() {
      return [
        {
          display:this.staticData?.edit?.label[this.loginUserDetails]?.label,
          label: "Edit",
          icon: "icon-file-text-edit-regular",
        },
        {
          display:this.staticData?.delete?.label[this.loginUserDetails]?.label,
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ];
    },
    serialNumber(index) {
      return (1 - 1) * 1 + index + 1;
    },
  }
};
</script>
