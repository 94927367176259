<template>
    <discardComponent
    :show="showOverviewDiscardModal" 
    @close="showOverviewDiscardModal = false"
    @stay="handleStay"
    @discard="handleDiscard">
  </discardComponent>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <hlx-modal
    :modal-active="delete_modal_active"
    :height="'200px'"
    :width="'400px'"
    :modal="true"
    @close="delete_modal_active = false"
  >
    <template #header>
      <div style="margin-left: 0px">Delete data</div>
    </template>
    <template #content>
      <div class="modal-content">
        <p>
          Are you sure you want to delete <b>{{ deleteObj?.name ? deleteObj?.name : deleteObj?.firstName +  " " +deleteObj?.lastName  }}</b
          >?
        </p>
      </div>
    </template>
    <template #footer>
      <hlx-button
        class="secondary sm"
        @click="delete_modal_active = false"
        >Cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 16px"
        @click="deleteContactRow"
        >Delete</hlx-button
      >
    </template>
  </hlx-modal>
  <section style="display: flex">
    <section class="lead-overview-left-panel responsiveSM">
      <div class="layout-breadcrumb">
        <hlx-breadcrumb
            :items="getBreadcrumbs"
            :label-value="loadStaticData('LANG-0019')"
             @path="goTo()" />
        <!-- <hlx-breadcrumb
          v-if="view === false"
          :items="getBreadcrumbs"
          @path="goTo()"
        /> -->
      </div>
      <span style="margin-top: 25px">
        <leftPanel
          :side-bar-data="leftPanelData"
          @value-name="selectedValueFunc"
        ></leftPanel>
      </span>
    </section>
    <section
      class="--layout-inner-right-portion --RL70 responsiveSM-right-portion"
      style="
        padding: 1.8em 35px;
        width: calc(100% - 220px);
        padding-left: 0px;
      "
    >
      <!-- :style="
          isRightLayoutOpened === false
            ? 'width:calc(100% - 220px)'
            : 'width:100%'
        " -->
      <div class="decision-info-right-pannel">
        <div class="layout-breadcrumb expanded-side-panel">
          <hlx-breadcrumb
            v-if="view === true"
            :label-value="loadStaticData('LANG-0019')"
            :items="getBreadcrumbs"
            @path="goTo()"
          />
          <hlx-breadcrumb
            v-if="view === false"
            :label-value="loadStaticData('LANG-0019')"
            :items="getBreadcrumbs"
            @path="goTo()"
          />
        </div>
        <div
          class="body-container-center"
          style="height: 100%"
        >
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span
              class="expanded-side-panel"
              style="margin-right: 25px"
            >
              <leftPanel
                :side-bar-data="leftPanelData"
                @value-name="selectedValueFunc"
              ></leftPanel>
            </span>
            <div style="display: flex; align-items: center; gap: 15px">
              <span>
                <hlx-file-upload
                  :key="profileComponentKey"
                  :file-type="'.jpg,.jpeg,.png'"
                  :file-size-limit="'20mb'"
                  :uploadtype="'Rectangle'"
                  :style-background="backgrounStyle"
                  :height="50"
                  :width="50"
                  :read-only="true"
                  :custom-icon="icon"
                  :type="'profile-upload'"
                  :profile-pic-preview="profilePicUrl"
                  :content="'Import'"
                  @native-data="profileUpload"
                />
              </span>

              <div
                style="
                  display: flex;
                  flex-direction: column;
                  gap: 4px;
                  font-family: 'Quicksand';
                "
              >
                <div style="display: flex; align-items: center; gap: 12px">
                  <span style="font-weight: bold;width: max-content;">
                    {{ leadData.name ? leadData.name : '-' }}
                  </span>
                  <hlx-label
                    v-if="leadData.status"
                    class="sm"
                    :class="
                      leadData.status === 'Closed lost' ? 'error' : 'primary'
                    "
                  >
                    {{ leadData?.status }}
                  </hlx-label>
                </div>
                <div
                  style="color: #a6a6a6; font-size: 12px; width: max-content"
                >
                  {{ leadData?.description }}
                </div>
              </div>
            </div>
            <div
              v-if="view && selectedValue.toLowerCase() === 'overview'"
              class="go-back"
              style="
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
              "
            >
              <hlx-button
                v-show="accountStatus == false"
                class="primary sm"
                style="margin-left: 20px"
                @click="view = false"
                >
                <!-- Edit -->
                {{ loadStaticData("LANG-0009") }}
                </hlx-button
              >
            </div>
            <div
              v-if="view && selectedValue.toLowerCase() === 'overview'"
              class="go-back"
              style="
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
              "
            >
            <!-- Account link -->
              <!-- <hlx-button class="secondary sm" @click="cancelEditAccount"
                >
                {{ loadStaticData("LANG-0029") }}
                </hlx-button
            > -->

            </div>
            <div v-if="selectedValue.toLowerCase() === 'overview'" style="display:flex">
              <div class="button-container-lead-overview">
                <hlx-button
                  class="secondary sm"
                 style="display: flex; align-items: center; gap: 0.5rem"
                  @click="showDropdown = !showDropdown"
                  >
                  <!-- Edit -->
                  {{ "Actions" }}
                  <i 
                  v-if = "showDropdown"
                  style="margin-right: 5px"
                  class="icon-angle-up-regular"
                        ></i>
                        <i 
                  v-if = "!showDropdown"
                  style="margin-right: 5px"
                  class="icon-angle-down-regular"
                        ></i>
                  </hlx-button
                >
                <div v-if="showDropdown" class="lead-overview-dropdown">
                <div v-if="leadData.status !== 'Completed'" class="dropdown-item" style="margin-bottom:6px">
                  <i class="icon-user-settings-regular"></i>
                  Convert to account
                </div>

                <div v-if="leadData.status === 'Completed'" class="dropdown-item" style="margin-bottom:6px">
                  <i class="icon-user-settings-regular"></i>
                  Account link
                </div>

                <!-- Always show Create quote -->
                <div class="dropdown-item">
                  <i class="icon-file-text-regular"></i>
                  Create quote
                </div>
              </div>
              </div>
              <hlx-button
                v-if="!view"
                class="primary sm"
                style="margin-left: 20px"
                @click="view = true"
                >
                <!-- Edit -->                 
                {{ loadStaticData("LANG-0009") }}
                </hlx-button
              >
              <hlx-button
                          v-if="view && selectedValue.toLowerCase() === 'overview'"
                class="secondary sm"
                style="margin-left: 20px"
                @click="view  = false"
                >
                <!-- Save -->
                {{"Cancel" }}
                </hlx-button
              >
              <hlx-button
                            v-if="view && selectedValue.toLowerCase() === 'overview'"
                class="primary sm"
                style="margin-left: 20px"
                @click="saveEditedLead"
                >
                <!-- Save -->
                {{ loadStaticData("LANG-0030") }}
                </hlx-button
              >
            </div>
          </div>
          <div>
            <div
              v-if="selectedValue.toLowerCase() === 'overview'"
              class="parent-container-for-lead-overview"
            >
              <fieldRenderer
                :schema-payload="filteredCustomFieldSchema"
                :data-payload="schemaProp"
                :is-save-clicked="isSaveButtonClicked"
                :status-list="[
                 'Open',
                'Network approved',
                // 'In Discussion',
                'Qualified',
                'Network rejected',
                'Unqualified',
                'Awaiting network approval',
                'Converted',
                'Closed Lost',
                ]"
                :parent-class-name="'parent-container-for-lead-overview'"
                @edited-payload="patchEditedLead"
                @profile-buffer-data="getProfileImageBufferData"
                @profile-name="getProfileImageName"
              ></fieldRenderer>
            </div>
          </div>
          <!-- <div
            v-if="selectedValue.toLowerCase() === 'overview'"
            class="lead-flow-component-cover"
            style="display: none; gap: 5%; padding-top: 20px"
          >
            <div
              class="decision-info-left-pannel"
              style="width: 50%; gap: 20px"
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  gap: 10px;
                  margin-bottom: 24px;
                "
              >
                <span>
                  <hlx-file-upload
                    :key="profileComponentKey"
                    :file-type="'.jpg,.jpeg,.png'"
                    :file-size-limit="'20mb'"
                    :uploadtype="'Rectangle'"
                    :style-background="backgrounStyle"
                    :height="93"
                    :width="93"
                    :read-only="view"
                    :custom-icon="icon"
                    :profile-pic-preview="profilePicUrl"
                    :type="'profile-upload'"
                    :content="'Import'"
                    @native-data="profileUpload"
                    @delete-image="deleteProfilePicture"
                  />
                </span>
                <span style="width: 100%">
                  <div
                    class="decision-name-wrapper"
                    :style="{
                      'margin-bottom': customnameerror ? '-7.6%' : 0,
                    }"
                    style="margin-bottom: 20px"
                  >
                    <hlx-input
                      v-model:value="leadData.name"
                      :pre-val="leadData.name"
                      :label-value="'Company name'"
                      :label-animation="true"
                      :required="true"
                      :display-error="true"
                      :custom-error="isExistingName"
                      :custom-error-message="isExistingNameErrorMessage"
                      @focus-out="validateNameNew"
                      @at-input="validateName"
                    ></hlx-input>
                  </div>
                  <div class="decision-name-wrapper">
                    <div style="width: 100%">
                      <hlx-status
                        :type="'select-status'"
                        :prevalue="leadData.status"
                        :status-option="filteredstatus"
                        @emit-select="leadStatus"
                      >
                      </hlx-status>
                    </div>
                  </div>
                </span>
              </div>
              <section class="left-section-select">
                <div>
                  <hlx-select
                    :key="componentKey"
                    :pre-value="leadData?.leadSource?.name"
                    :inline-search="true"
                    :options="leadSourceOptions"
                    :required="true"
                    :slot-options="false"
                    :placeholder-value="'Lead source'"
                    :prop-value="'name'"
                    :label="'name'"
                    :label-animation="true"
                    :display-error="true"
                    :clearable="true"
                    @selected-value="getLeadSource"
                  ></hlx-select>
                </div>
                <div>
                  <hlx-select
                    :key="componentKey"
                    :pre-value="leadData?.leadType?.name"
                    :inline-search="true"
                    :options="leadTypeOptions"
                    :required="true"
                    :slot-options="false"
                    :placeholder-value="'Lead type'"
                    :prop-value="'name'"
                    :display-error="true"
                    :label="'name'"
                    :label-animation="true"
                    :clearable="true"
                    @selected-value="getLeadType"
                  ></hlx-select>
                </div>
                <div>
                  <hlx-select
                    :key="componentKey"
                    :pre-value="leadData?.dwellingtype"
                    :inline-search="true"
                    :display-error="true"
                    :options="dwellingTypeOptions"
                    :required="true"
                    :slot-options="false"
                    :placeholder-value="'Dwelling type'"
                    :prop-value="'name'"
                    :label="'name'"
                    :label-animation="true"
                    :clearable="true"
                    @selected-value="getLeadDwellingType"
                  ></hlx-select>
                </div>
                <div>
                  <hlx-select
                    :key="componentKey"
                    :pre-value="leadData?.category?.name"
                    :inline-search="true"
                    :options="leadCategoryOptions"
                    :required="true"
                    :display-error="true"
                    :slot-options="false"
                    :placeholder-value="'Category'"
                    :prop-value="'name'"
                    :label="'name'"
                    :label-animation="true"
                    :clearable="true"
                    @selected-value="getLeadCategory"
                  ></hlx-select>
                </div>
                <div>
                  <hlx-select
                    :key="componentKey"
                    :pre-value="leadData?.marketSegment?.name"
                    :inline-search="true"
                    :options="leadMarketSegmentOptions"
                    :required="true"
                    :slot-options="false"
                    :placeholder-value="'Market segment'"
                    :prop-value="'name'"
                    :label="'name'"
                    :label-animation="true"
                    :display-error="true"
                    :clearable="true"
                    @selected-value="getLeadMarketSegment"
                  ></hlx-select>
                </div>
                <div>
                  <hlx-input
                    v-model:value="leadData.onboardingDate"
                    :pre-val="leadData.onboardingDate"
                    :label-animation="true"
                    :single-border="false"
                    :label-value="'Onboarding date'"
                    :date-format="'dd (short month), yyyy'"
                    type="date"
                    :clearable="false"
                    icon="calendar-regular"
                    icon-position="right"
                    required="true"
                    :disable-weekend="false"
                    :start-year="2000"
                    :display-error="true"
                    :custom-error="startDateBoolean"
                    :custom-error-message="startDateMsg"
                    :end-year="9999"
                    @iso-date="getLeadOnboardingDate"
                    @at-input="getLeadOnboardingDate"
                    @focus-out="focusOutStartDate"
                  />
                </div>
              </section>
            </div>
            <span
              class="right-section"
              style="width: 50%"
            >
              <div style="margin-bottom: 12px">
                <hlx-input
                  v-model:value="leadData.description"
                  :pre-val="leadData.description"
                  type="textarea"
                  :label-value="'Description'"
                  :label-animation="true"
                  :rows="8"
                  :required="false"
                  :display-error="false"
                  :custom-error="customnameerror"
                  :custom-error-message="''"
                  @focus-out="focusOutName"
                ></hlx-input>
              </div>
              <div style="display: flex">
                <div style="width: 94%">
                  <hlx-select
                    :key="componentKey"
                    :pre-value="leadData?.leadOwner?.name"
                    :inline-search="true"
                    :options="leadOwnerOptions"
                    :required="true"
                    :placeholder-value="'Lead owner'"
                    :prop-value="'name'"
                    :label="'name'"
                    :label-animation="true"
                    :clearable="true"
                    :display-error="true"
                    @selected-value="getLeadOwner"
                  ></hlx-select>
                </div>
                <div style="margin: auto; padding-bottom: 24px">
                  <i
                    style="color: #a6a6a6"
                    class="icon-info-circle-regular"
                  ></i>
                </div>
              </div>
              <div style="display: flex">
                <div style="width: 94%">
                  <hlx-input
                    :key="drawerKey"
                    v-model:value="leadData.numberOfSites"
                    :pre-val="leadData.numberOfSites"
                    type="number"
                    :label-value="'Number of sites'"
                    :label-animation="true"
                    :required="true"
                    :custom-error="customnameerror"
                    :display-error="true"
                    :custom-error-message="'Please fill the field'"
                    @focus-out="focusOutName"
                    @at-input="validateName"
                  ></hlx-input>
                </div>
                <div style="margin: auto; padding-bottom: 24px">
                  <i
                    style="color: #a6a6a6"
                    class="icon-info-circle-regular"
                  ></i>
                </div>
              </div>
              <div style="display: flex">
                <div style="width: 94%">
                  <hlx-select
                    :key="componentKey"
                    :pre-value="leadData?.dealCategory?.name"
                    :inline-search="true"
                    :options="leadDealCategoryOptions"
                    :required="true"
                    :slot-options="false"
                    :placeholder-value="'Deal category'"
                    :prop-value="'name'"
                    :label="'name'"
                    :display-error="true"
                    :label-animation="true"
                    :clearable="true"
                    @selected-value="getLeadDealCategory"
                  ></hlx-select>
                </div>
                <div style="margin: auto; padding-bottom: 24px">
                  <i
                    style="color: #a6a6a6"
                    class="icon-info-circle-regular"
                  ></i>
                </div>
              </div>
              <div>
                <hlx-input
                  v-model:value="leadData.websiteUrl"
                  :pre-val="leadData?.websiteUrl"
                  :label-value="'Website URL'"
                  type="text"
                  :prefix="true"
                  :label-animation="true"
                  :display-error="true"
                  :custom-error="isUrlEmpty"
                  :custom-error-message="'Please enter a valid URL in the format: www.abc.com'"
                  @focus-out="websiteRegex"
                  @at-input="validateName"
                >
                </hlx-input>
              </div>
              <div>
                <hlx-multi-select
                  :key="componentKey"
                  :options="tagsOptions"
                  :pre-value="leadData.tags"
                  :placeholder="'Tags'"
                  :label-animation="true"
                  :prop-value="'name'"
                  :label="'name'"
                  :disabled="false"
                  :filter-select="false"
                  :tooltipposition="'left'"
                  @add-data="addTagList"
                  @custom-change="receivedTags"
                ></hlx-multi-select>
              </div>
            </span>
            <overViewComponent></overViewComponent> 
          </div> -->
          <div
            v-if="selectedValue.toLowerCase() === 'hierarchy'"
            class="lead-flow-component-cover"
          >
            <hierarchyComponent
              :account-status="accountStatus"
              :data="hierarchyData"
              module="CM"
              @updated-hierarchy="updatedHierarchy"
            ></hierarchyComponent>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'contacts'"
            class=""
          >
            <leadContact
              :schema-for-contact-modal="filteredCustomFieldSchema"
              :schema-for-drawer="filteredCustomFieldSchemaForDrawer"
              :data-for-contact-modal="schemaPropForContacts"
              :is-save-clicked="isSaveButtonClicked"
              :payload="leadData"
              :lead-status="accountStatus"
              :contact-data="contactsTableData"
              :full-total="fullTotal"
              :current-total="currentTotal"
              @contact-switch-val="contactSwitchVal"
              @new-contact="addNewContact"
              @edit-contact="editContactData"
              @initalpayload="initalpayload"
              @delete-contact="deleteContactData"
              @updated-payload="patchUpdatedPayloadForContact"
              @updated-schema="getContactUpdatedSchema"
            ></leadContact>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'interaction'"
            class="lead-flow-component-cover"
            style="justify-content: center !important"
          >
            <interactionComponent
              :account-status="accountStatus"
              :data="interactionData"
              :contact-data="contactTableData"
              @initial-payload="initialPayloadInt"
              @new-interaction-payload="receivedInteractionPayload"
              @delete-interaction-index="deleteInteractionPayload"
              @delete-interaction-data="deleteinteraction"
              @edit-interaction="editInteraction"
            ></interactionComponent>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'orders'"
          >
            <leadOrderComponent
              :account-status="accountStatus"
              @create-quote="handleQuoteCreation"
            ></leadOrderComponent>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'sites'"
            class=""
          >
            <leadSite
              :schema-for-site-modal="filteredCustomFieldSchema"
               :schema-for-drawer="filteredCustomFieldSchemaForDrawer"
              :data-for-site-modal="schemaPropForContacts"
              :is-save-clicked="isSaveButtonClicked"
              :payload="leadData"
              :lead-status="accountStatus"
              :site-data="sitesTableData"
              :full-total="fullTotal"
              :current-total="currentTotal"
              @contact-switch-val="contactSwitchVal"
              @new-contact="contactData"
              @edit-contact="editContactData"
              @initalpayload="initalpayload"
              @drawer-open="schemaForDrawer"
              @delete-site="deleteSiteData"
              @updated-payload="patchUpdatedPayloadForSite"
              @add-payload="addNewSitePayload"
            ></leadSite>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'billing'"
            class=""
          >
            <templateComponent
              :account-status="accountStatus"
            ></templateComponent>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'products'"
            class=""
          >
            <templateComponent
              :account-status="accountStatus"
              :module="'Products'"
            ></templateComponent>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'contracts'"
            class=""
          >
            <templateComponent
              :account-status="accountStatus"
              :module="'Contracts'"
            ></templateComponent>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'documents'"
            class=""
          >
            <attachmentsComponent
              :supplier-status="accountStatus"
              :user-details="leadDetails"
              :data="attachmentData"
              module="LEAD"
              @upload-data="receivedUploadData"
              @delete-index="deleteAttachmentRow"
            ></attachmentsComponent>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'notes'"
            class=""
          >
            <noteComponent
              :supplier-status="ViewMode"
              :data="notesTableData"
              @added-note="addNote"
              @delete-index="deleteNote"
              @edit-note="editNote"
            ></noteComponent>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'tickets'"
            class=""
          >
            <TicketsComponent
              :module="'accounts'"
              :company="$route.params.companyName"
            ></TicketsComponent>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'location'"
            class=""
          >
            <locationComponent
              :account-status="accountStatus"
              :data="payload.contactMedium"
              @new-address="receivedNewAddress"
              @update-address="receivedUpdateAddress"
              @initial-payload="initialPayloadLocation"
              @delete-index="deleteAddress"
            ></locationComponent>
          </div>
          <div
            v-if="selectedValue.toLowerCase() === 'activity log'"
            class=""
          >
            <activityLogComponent
              :module="'CM'"
              :activity-data="activityLogData"
              :totalfromprop="activitytotal"
              @activityrowpage="activityrowpage1"
              @activitycurrentpage="activitycurrentpage1"
            ></activityLogComponent>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import leadSite from './leadSite.vue';
import leftPanel from '@/components/leftpannelComponent.vue';
import leadContact from './leadContact.vue';
import hierarchyComponent from '@/components/CM/addAccountHierarchyComponent.vue';
import interactionComponent from '@/components/CM/addAccountInteractionComponent.vue';
import leadOrderComponent from './leadOrder.vue';
import attachmentsComponent from '@/components/CM/addAccountAttachmentsComponent.vue';
import noteComponent from '@/components/CM/addAccountNotesComponent.vue';
import TicketsComponent from '@/components/CM/addAccountTicketsComponent.vue';
import locationComponent from '@/components/CM/addAccountLocationsComponent.vue';
import fieldRenderer from '@/components/FieldRenderer.vue';
import discardComponent from '@/components/discardComponent.vue';
import {
  // DMNAxiosInstance,
  UPCAxiosInstance,
  MLTAxiosInstance,
} from '@/config/axiosConfig';
export default {
  components: {
    leftPanel,
    fieldRenderer,
    leadContact,
    leadSite,
    discardComponent,
    TicketsComponent,
    // overViewComponent,
    hierarchyComponent,
    interactionComponent,
    leadOrderComponent,
    attachmentsComponent,
    noteComponent,
    locationComponent,
  },
  beforeRouteLeave(to, from, next) {
  if(this.discardIt) { //this.discardIt will handled on discard modal window discard changes emit
    next(true)
    return
  }
  const dupSchemaProp = { ...this.schemaProp}
        delete dupSchemaProp.updatedAt
        delete dupSchemaProp.createdAt
        delete dupSchemaProp.lastUpdate
  if(this.temporaryLeadOverviewPayload && this.schemaProp && this.selectedValue.toLowerCase() == "overview"){
  let removeUnwantedData  =  this.temporaryLeadOverviewPayload
  removeUnwantedData.relatedParty = removeUnwantedData.relatedParty.filter(
          party => party["@type"] === "leadOwner" || party["@type"] === "leadSource"
        );
        delete removeUnwantedData.updatedAt
        delete removeUnwantedData.createdAt
        delete removeUnwantedData.lastUpdate
  const unsavedChanges = this.isPayloadChanged(dupSchemaProp,removeUnwantedData) 
  console.log(this.dupliactePayload,this.payload,'unsavedChanges',unsavedChanges)
if(unsavedChanges){
  this.showOverviewDiscardModal = true
}
next(!unsavedChanges)
return
  }
  else {
    next(true)
    return
  }
},
  // vue add data
  data() {
    return {
      showDropdown:false,
      updatedContactSchema:null,
      tempBack: false,
      discardIt: false,
      showOverviewDiscardModal:false, 
      isDrawerSchema:false,
      temporaryLeadOverviewPayload:null,
      profilePicUrl:'',
      notesTableData:[],
      attachmentData:[],
      leadDetails:{
        name: "",
        id: "",
      },
      containerleft: 0,
      containertop: 0,
      isActionDropdownOpen: false,
      notification: {},
      delete_modal_active: false,
      deleteObj: {},
      contactsTableData: [],
      sitesTableData: [],
      isSaveButtonClicked: false,
      customFieldLeadSchema: {},
      view: '',
      leadData: {},
      schemaProp: {},
      schemaPropForContacts: {},
      leadOrginalData: {},
      leadDealCategoryOptions: [],
      leadOwnerOptions: [],
      leadMarketSegmentOptions: [],
      categoryOptions: [],
      leadSourceOptions: [],
      leadTypeOptions: [],
      dwellingTypeOptions: [],
      leadContactType: [],
      leadCategoryOptions: [],
      permisstionData: {},
      selectedValue: '',
      leftPanelData:{}, // To get selected value from the left panel
      tempSideBar: {
        main: [
          {
            id: 1,
            name: "Overview",
            icon: "icon-presentation-regular",
            active: true,
            view: true,
          },
          {
            id: 2,
            name: "Contacts",
            icon: "icon-user-regular",
            active: false,
            view: true,
          },
          {
            id: 3,
            name: "Sites",
            icon: "icon-buildings-alt-regular",
            active: false,
            view: true,
          },
          {
            id: 4,
            name: "Interaction",
            icon: "icon-phone-office-regular",
            active: false,
            view: true,
          },
          {
            id: 5,
            name: "Orders",
            icon: "icon-shopping-cart-regular",
            active: false,
            view: true,
          },
          {
            id: 6,
            name: "Notes",
            icon: "icon-note-list-regular",
            active: false,
            view: true,
          },
          {
            id: 7,
            name: "Documents",
            icon: "icon-paperclip-regular",
            active: false,
            view: true,
          },
          {
            id: 8,
            name: "Activity log",
            icon: "icon-time-forward-regular",
            active: false,
            view: true,
          },
        ],
      },
    };
  },
  computed: {
    attachedPaginated() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.attachmentData.slice(start, end);
    },
    getBreadcrumbs() {
      return [
        {
          label: 'Lead',
          link: `/lead`,
        },
      ];
    },
    filteredCustomFieldSchema() {
      console.log('this.customFieldLeadSchema', this.customFieldLeadSchema);
      if (this.customFieldLeadSchema) {
        const filtered = Object.entries(this.customFieldLeadSchema).filter(
          ([, value]) =>
            value.section &&
            value.section.includes(this.selectedValue.toLowerCase())
        );
        const fieldSchema = Object.fromEntries(filtered);
        console.log(this.selectedValue, 'fieldSchema', fieldSchema);

        // Convert the object into an array of entries [key, value]
        const entries = Object.entries(fieldSchema);

        // Sort based on `others.displayOrder` if it exists
        entries.sort((a, b) => {
          const orderA = a[1].others?.displayOrder ?? Infinity; // Use Infinity if displayOrder is not present
          const orderB = b[1].others?.displayOrder ?? Infinity;
          return orderA - orderB; // Sort ascending
        });

        // Convert back to an object
        const sortedData = Object.fromEntries(entries);
        if(this.selectedValue.toLowerCase() == 'overview' && this.view == false ){
        Object.keys(sortedData).forEach((key) => {
          if (sortedData[key]?.others) {
            sortedData[key].others.readOnly = true;
          }
        });
      }
      if(this.selectedValue.toLowerCase() == 'overview' && this.view == true ){
        Object.keys(sortedData).forEach((key) => {
          if (sortedData[key]?.others) {
            sortedData[key].others.readOnly = false;
          }
        });
      }
      console.log(this.view,"sortedData",sortedData)
      return sortedData
        // else{
        // return sortedData;
        // }
      }
      else if(this.isDrawerSchema){
        console.log("this.isDrawerSchema",this.isDrawerSchema)
        return
      } else {
        return {};
      }
    },
    filteredCustomFieldSchemaForDrawer() {
  console.log('this.customFieldLeadSchema', this.customFieldLeadSchema);
  if (this.customFieldLeadSchema) {
    const filtered = Object.entries(this.customFieldLeadSchema).filter(
      ([, value]) =>
        value.section &&
        value.section.includes(this.selectedValue.toLowerCase())
    );
    const fieldSchema = Object.fromEntries(filtered);
    console.log(this.selectedValue, 'fieldSchema', fieldSchema);

    // Convert the object into an array of entries [key, value]
    const entries = Object.entries(fieldSchema);

    // Sort based on odd numbers first, then even numbers in `others.displayOrder`
    entries.sort((a, b) => {
      const orderA = a[1].others?.displayOrder ?? Infinity;
      const orderB = b[1].others?.displayOrder ?? Infinity;

      // Check for odd/even priority
      const isOddA = orderA % 2 !== 0;
      const isOddB = orderB % 2 !== 0;

      if (isOddA && !isOddB) return -1; // A is odd, B is even
      if (!isOddA && isOddB) return 1;  // A is even, B is odd

      // If both are odd or even, sort numerically
      return orderA - orderB;
    });

    // Convert back to an object
    const sortedData = Object.fromEntries(entries);

    return sortedData;
  } else if (this.isDrawerSchema) {
    console.log("this.isDrawerSchema", this.isDrawerSchema);
    return;
  } else {
    return {};
  }
},
  },
  watch: {
    leadOrginalData: {
      async handler(val) {
        if (val) {

          const relatedPartyCopy = [...(val.relatedParty || [])];

        this.leadOrgRelatedParty = relatedPartyCopy.filter(
          party => party["@type"] != "leadOwner" || party["@type"] != "leadSource"
        );

        const filteredRelatedParty = relatedPartyCopy.filter(
          party => party["@type"] === "leadOwner" || party["@type"] === "leadSource"
        );

        this.schemaProp = {
          ...JSON.parse(JSON.stringify(val)),
          relatedParty: filteredRelatedParty
        };
          console.log('this.schemaProp ', this.schemaProp);
          if (Array.isArray(val.relatedParty)) {
            this.schemaPropForContacts = val.relatedParty.filter(
              (ele) => ele['@type'] === 'individual'
            );
            console.log(
              'this.schemaPropForContacts',
              this.schemaPropForContacts
            );
            this.contactsTableData = val.relatedParty.filter(
              (ele) => ele['@type'] == 'individual'
            );
            console.log('contactsTableData', this.contactsTableData);
            let sitesDataInPayload = val.relatedParty.filter(
              (ele) => ele['@type'] == 'site' || ele['@referredType'] == 'site'
            );
            console.log('sitesDataInPayload', sitesDataInPayload);
            let siteIds = sitesDataInPayload.map((site) => site.id ? site.id : site.siteId);
            let getAllSites = await UPCAxiosInstance.get('/geoSite');
            if (getAllSites) {
              this.sitesTableData = getAllSites.data.filter((ele) =>
                siteIds.includes(ele.id)
              );
              console.log(getAllSites, 'sitesTableData', this.sitesTableData);
            }
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
beforeMount() {
    window.addEventListener("beforeunload", this.preventPageReload);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.preventPageReload);
  },
  async mounted() {
    // Get left panel data
    const value = await MLTAxiosInstance.get("partymanagement/individual/auth");

    this.company = await value.data.company;
    let sampleTheadsd = await MLTAxiosInstance.get(
      `partyRole/get/${value.data.userId}`
    );

    const mergedPayload = sampleTheadsd.data.permission;

    this.permisstionData =
    mergedPayload["Multi dwelling unit"].child.Lead.sections;

    this.leftPanelData = this.mergeSidebarData(
      this.tempSideBar,
      this.permisstionData
    );
    //   console.log(this.permisstionData,
    //   "mergedPayload"
    // );

    // console.log(this.mergeSidebarData(this.sideBarData,this.permisstionData),"this.mergeSidebarData(this.sideBarData,this.permisstionData)");
    // this.sideBarData = this.mergeSidebarData(this.sideBarData,this.permisstionData)
    const leftPanel = await UPCAxiosInstance.get(`/customHierarchy`);

    console.log('leftPanel.data', leftPanel?.data[0]?.module[0].main);
    const multiDwellingObjLeftPanel =
      leftPanel?.data[0]?.module[0]?.main?.filter(
        (ele) => ele?.name == 'Multi dwelling unit'
      );
    const leadData = multiDwellingObjLeftPanel[0]?.child?.filter(
      (ele) => ele?.name == 'Lead'
    );
    console.log(
      leadData[0]?.sections,
      'multiDwellingObjLeftPanel',
      this.leftPanelData
    );
    
     const leftPanelSelectedVal = this.$cookies.get("selectedButton");
     leftPanelSelectedVal ?  this.selectedValue = leftPanelSelectedVal : this.selectedValue='overview'
    console.log(this.selectedValue,"this.selectedValue")
    let translateLang = await this.alternateNamesForLeft(leadData[0].sections)

console.log("refdeseeeeeee 22", translateLang);

translateLang = translateLang.filter((item)=>item.value!= 'interaction')
    this.leftPanelData = { main: translateLang };

if (this.selectedValue != '' && this.selectedValue != null && this.selectedValue != undefined) {
    this.leftPanelData.main = this.leftPanelData.main.map(item => {
        if (item.value !== '' && item.value != null && item.value != undefined) {
            return {
                ...item,
                active: item.value.toLowerCase() === this.selectedValue.toLowerCase()
            };
        }
        return item;
    });
}


    this.leftPanelData.main = this.leftPanelData.main.filter(
      (item) => item.value !== 'interaction'
    );
    console.log('this.leftPanelData', this.leftPanelData);
    // To get lead schema for custom field
    const schema = await UPCAxiosInstance.get(
      `/customSchema/getSchema?module=${'saleslead'}`
    );
    this.customFieldLeadSchema = schema?.data?.schema;
    console.log(schema, 'schema', this.customFieldLeadSchema);

    // To get dropdowm options from refernce data
    // this.leadDealCategoryOptions = await this.getReferanceData(
      //   "LeadDealCategory"
    // );
    // this.leadOwnerOptions = await this.getReferanceData("LeadOwner");
    // this.leadMarketSegmentOptions = await this.getReferanceData(
    //   "LeadMarketSegment"
    // );
    // this.leadCategoryOptions = await this.getReferanceData("LeadCategory");
    // this.leadSourceOptions = await this.getReferanceData("LeadSource");
    // this.leadTypeOptions = await this.getReferanceData("LeadType");
    // this.dwellingTypeOptions = await this.getReferanceData("LeadDwellingType");
    // this.leadContactType = await this.getReferanceData("LeadContactType");
    // let statusList = await DMNAxiosInstance.get(`mapRef/collection/statuslist`);
    // this.statusList = statusList.data.data;

    const response = await UPCAxiosInstance.get(
      `/salesLead/${this.$route.params.id}`
    );
    if (this.$route.params.type === 'edit') {
      this.view = true;
    } else {
      this.view = false;
    }
    this.leadOrginalData = response.data;
    this.leadData = JSON.parse(JSON.stringify(this.leadOrginalData));
    this.leadDetails = {
      name: this.$route.params.leadName,
      id: this.$route.params.id,
    };
    this.attachmentData =  this.leadData?.attachment ? this.leadData?.attachment  : []
    this.notesTableData =  this.leadData?.notes ? this.leadData?.notes  : []
    console.log(this.attachmentData," this.leadDetails", this.leadDetails)
    if( Array.isArray(this.attachmentData) && this.attachmentData.length > 0)
    {
      const profileInfo = this.attachmentData?.find(
            (ele) => ele?.['@type'] === 'profile' || ele?.['attachmentType'] === 'profile'
          );
            if (
              profileInfo.name != null &&
              profileInfo.name != undefined &&
              profileInfo.name != ''
            ) {
              const url = await UPCAxiosInstance.get(
                `/gcpupload/files/${profileInfo.name}`,
                { responseType: 'arraybuffer' }
              );
              this.profilePicUrl = await this.bufferToDataURL(
                url.data
              );
              console.log("obj.url obj.url",url.data)
              console.log(
                this.profilePicUrl,
                'this.profilePicUrlBufferData'
              );
          }
    }
  },
  methods: {
    getContactUpdatedSchema(val){
      this.updatedContactSchema = { ...val}
    },
    preventPageReload(event) {
      this.showOverviewDiscardModal = false;
      console.log(this.schemaProp,"this.temporaryLeadOverviewPayload",this.temporaryLeadOverviewPayload)
        const dupSchemaProp = { ...this.schemaProp}
        delete dupSchemaProp.updatedAt
        delete dupSchemaProp.createdAt
        delete dupSchemaProp.lastUpdate
      if(this.temporaryLeadOverviewPayload && this.schemaProp && this.selectedValue.toLowerCase() == "overview"){
      let removeUnwantedData  =  this.temporaryLeadOverviewPayload
  removeUnwantedData.relatedParty = removeUnwantedData.relatedParty.filter(
          party => party["@type"] === "leadOwner" || party["@type"] === "leadSource"
        );
        delete removeUnwantedData.updatedAt
        delete removeUnwantedData.createdAt
        delete removeUnwantedData.lastUpdate
  const unsavedChanges = this.isPayloadChanged(dupSchemaProp,removeUnwantedData) 
  console.log(this.temporaryLeadOverviewPayload,"removeUnwantedData",removeUnwantedData)
      if (!unsavedChanges) return;
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
      return;
      }
      else{
        return 
      }
    },
    handleStay() {
      // //handle Stay funtionality to hide discard component
      this.showOverviewDiscardModal = !this.showOverviewDiscardModal;
      this.tempBack = false;
    },
    handleDiscard() {
      //Handle discard functionalities like hide discard component,set discardIt as true, and push the routes where ever you want.
      console.log("store", this.$store.state.tempActiveSideBarItem);
      if (this.tempBack) {
        this.$router.back();
      } else {
        this.$router.push(this.$store.state.tempActiveSideBarItem.link);
      }
      this.showOverviewDiscardModal = !this.showOverviewDiscardModal;
      this.discardIt = true;
    },
    async bufferToDataURL(buffer) {
      console.log("bufferToDataURL",buffer)
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = '';
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },
    schemaForDrawer(val){
      console.log("schemaForDrawer",val)
      this.isDrawerSchema = val
    },
    async alternateNamesForLeft(data) {
    try {
        // Retrieve the language setting from localStorage
        let languageAbility = JSON.parse(localStorage.getItem('userLanguageFromSetting'));
        
        // Extract the language code, default to 'en' if not available
        const lang = languageAbility?.[0]?.code || 'en';

        // Use a regular for loop instead of for...of
        for (let i = 0; i < data.length; i++) {
            let item = data[i];
            if (item.customName) {
                // Update the customName field with the translated value
                item.customName = await this.languageTransfer(item.customName, lang);
            }
        }
        console.log("refdeseeeeeee", data);
        

        // Return the modified data
        return data;

    } catch (error) {
        console.error('Error updating names:', error);
        return data; // In case of an error, return the original data
    }
}


,


    getProfileImageBufferData(val){
      this.profilePicUrl = val
      console.log("getProfileImageBufferData",val)
    },
    getProfileImageName(val){
      console.log("getProfileImageName",val)
    },
    async deleteNote(val) {
      console.log(val,"deletednotes");

      this.leadData.notes.splice(val, 1);
      let editNotes =  await UPCAxiosInstance.patch(
        `/salesLead`,
        this.leadData
      );
      if(editNotes){
        const getUpdatedData = await UPCAxiosInstance.get(
        `/salesLead/${this.$route.params.id}`
      );
      this.leadOrginalData = getUpdatedData.data;
      this.notesTableData = this.leadOrginalData.notes;
    }
    },


    async editNote(val) {
      console.log(val, "editnotes");

      this.leadData.notes[val.index] = val.val;
      let editNotes =  await UPCAxiosInstance.patch(
        `/salesLead`,
        this.leadData
      );
      if(editNotes){
        const getUpdatedData = await UPCAxiosInstance.get(
        `/salesLead/${this.$route.params.id}`
      );
      this.leadOrginalData = getUpdatedData.data;
      this.notesTableData = this.leadOrginalData.notes;
    }
    console.log(this.notesTableData , "notes table data");
    
    },
    async addNote(val) {
      console.log(val, "Notes value");
      this.leadData.notes = this.leadData.notes ? this.leadData.notes : [];

      this.leadData.notes.unshift(val);

      let notes =  await UPCAxiosInstance.patch(
        `/salesLead`,
        this.leadData
      );
      if(notes){
      const getUpdatedData = await UPCAxiosInstance.get(
        `/salesLead/${this.$route.params.id}`
      );
      this.leadOrginalData = getUpdatedData.data;
      this.notesTableData = this.leadOrginalData.notes;
      }
    },

    async deleteAttachmentRow(val) {
      this.delete_name = this.attachmentData[val - 1].name;
      this.attachmentData.splice(val - 1, 1);

      this.leadData.attachment = await this.attachmentData;
      const deleteAttachment = await UPCAxiosInstance.patch(
        `/salesLead`,
        this.leadData
      );
      const image = await UPCAxiosInstance.delete(
        `gcpupload/files/${this.delete_name}`
      );
      if (deleteAttachment && image) {
        const getUpdatedData = await UPCAxiosInstance.get(
        `/salesLead/${this.$route.params.id}`
      );
      this.leadOrginalData = getUpdatedData.data;
      this.attachmentData = this.leadOrginalData.attachment;
      }
    },
    async receivedUploadData(val) {
      let data = await UPCAxiosInstance.post(`/gcpMultiple/v2/upload/`, val);
      console.log(data);
      data?.data?.forEach((item) => {
        let obj = {};
        obj.name = item.filename;
        obj.size = item.data.attachment.size;
        obj.url = item.url;
        obj.id = item.data._id;
        // Get the current date and time in milliseconds
        const currentTimestamp = new Date().toJSON();

        // Format the current timestamp in the same format as '1705574420667'
        console.log(currentTimestamp);
        obj.lastUpdated = currentTimestamp;
        this.leadData.attachment = this.leadData.attachment ? this.leadData.attachment : [];
        this.leadData.attachment.unshift(obj);

      });
      const response = await UPCAxiosInstance.patch(
        `/salesLead`,
        this.leadData
      );
      const getUpdatedData = await UPCAxiosInstance.get(
        `/salesLead/${this.$route.params.id}`
      );
      this.leadOrginalData = getUpdatedData.data;
      if(response){
            this.notification = {
          type: "success",
          message: this.loadStaticData('LANG-0032') ||  `Nice work! Your new file is now in the list!`,
        };
      }
      this.attachmentData = this.leadOrginalData.attachment;

    },
    display(id) {
      console.log(id, 'containerleft');
      this.containerleft =
        document.getElementById(id).getBoundingClientRect().left + 5;
      this.containertop =
        document.getElementById(id).getBoundingClientRect().top + 50;
      console.log(this.containertop, 'containerleft', this.containerleft);
    },
    computedTableAction() {
      let options = [
        {
          label: 'Mark as primary',
          icon: 'icon-check-circle-regular',
        },
        {
          label: 'Edit',
          icon: 'icon-file-text-edit-regular',
        },
        {
          label: 'Delete',
          icon: 'icon-trash-regular',
        },
      ];
      return options;
    },
    async deleteContactData(val) {
      this.deleteObj = JSON.parse(JSON.stringify(val));
      this.delete_modal_active = true;
    },
    async deleteSiteData(val){
      this.deleteObj = JSON.parse(JSON.stringify(val));
      this.delete_modal_active = true;
    },
    async deleteContactRow() {
      if(this.selectedValue.toLowerCase() == 'sites'){
        console.log(this.deleteObj,"sites")
        this.leadData.relatedParty = this.leadData.relatedParty.filter(
  (item) => item?.id !== this.deleteObj?._id
);
const externalReference = this.leadData?.externalReference?.find(
  (ref) => ref.type.toLowerCase() == "numberofsites"
);

if (externalReference && externalReference.value) {
  externalReference.value = Number(externalReference.value) - 1
}
        let deleteSite = await UPCAxiosInstance.delete(`/geoSite/${this.deleteObj._id}`);
        if(deleteSite){
          const response = await UPCAxiosInstance.patch(
        `/salesLead`,
        this.leadData
      );
      console.log('response', response);
      const getUpdatedData = await UPCAxiosInstance.get(
        `/salesLead/${this.$route.params.id}`
      );
      if (getUpdatedData) {
        this.delete_modal_active = false;
        this.notification = {
          type: 'success',
          message: this.loadStaticData('LANG-0033') || `Site deleted successfully`,
        };
      }
      this.leadOrginalData = getUpdatedData.data;
        }

      }
      if(this.selectedValue.toLowerCase() == 'contacts'){
        this.leadData.relatedParty =
        this.leadData.relatedParty.filter(
          (item) =>
            item?.firstName?.toLowerCase() !==
            this.deleteObj?.firstName?.toLowerCase() // Replace 'key' with a unique identifier.
        );
      console.log(this.leadData, 'deleteContactData', this.deleteObj);
      const response = await UPCAxiosInstance.patch(
        `/salesLead`,
        this.leadData
      );
      console.log('response', response);
      const getUpdatedData = await UPCAxiosInstance.get(
        `/salesLead/${this.$route.params.id}`
      );
      if (getUpdatedData) {
        this.delete_modal_active = false;
        this.notification = {
          type: 'success',
          message: this.loadStaticData('LANG-0031') || `Contact deleted successfully`,
        };
      }
      this.leadOrginalData = getUpdatedData.data;
    }
    },
    async patchUpdatedPayloadForContact(val) {
      console.log('patchUpdatedPayloadForContact', val);
      const response = await UPCAxiosInstance.patch(`/salesLead`, val);
      console.log('response', response);
      const getUpdatedData = await UPCAxiosInstance.get(
        `/salesLead/${this.$route.params.id}`
      );
      if (getUpdatedData) {
        this.notification = {
          type: 'success',
          message: this.loadStaticData('LANG-0034') || `Contact updated successfully`,
        };
      }
      this.leadOrginalData = getUpdatedData.data;
    },
    async addNewContact(val){
      console.log('addNewContact', val);
      const response = await UPCAxiosInstance.patch(`/salesLead`, val);
      console.log('response', response);
      const getUpdatedData = await UPCAxiosInstance.get(
        `/salesLead/${this.$route.params.id}`
      );
      if (getUpdatedData) {
        this.notification = {
          type: 'success',
          message:`Contact added successfully`,
        };
      }
      this.leadOrginalData = getUpdatedData.data;
    },
    async patchUpdatedPayloadForSite(leadData, siteData) {
      //  patch the site data
      // console.log(val2,"patchUpdatedPayloadForSite",val)
      siteData.context=false
      const response = await UPCAxiosInstance.patch(`geoSite`, siteData);
      console.log('Response for site:', response.data);
      const relatedSite = leadData.relatedParty.find(
        (obj) =>
          (obj.name === response.data.siteName ||
            obj.name === response.data.name) &&
          obj['@type'] === 'site'
      );
      if (relatedSite) {
        console.log('relatedSite', relatedSite);
        relatedSite.name = response.data.siteName || response.data.name;
        relatedSite.id = response.data.id;
      }
      if (!relatedSite) {
        leadData.relatedParty.push({
          name: response.data.siteName || response.data.name,
          id: response.data.id,
          '@type': 'site',
        });
      }
      leadData.id = leadData._id;
      if (response) {
        const patchLead = await UPCAxiosInstance.patch(`salesLead`, leadData);
        if (patchLead) {
        this.notification = {
          type: 'success',
          message:this.loadStaticData('LANG-0035') ||  `Site updated successfully`,
        };
      }
        console.log(patchLead, 'Lead patched response');
        this.leadOrginalData = patchLead.data;
      }
    },
    async addNewSitePayload(leadData, siteData) {
      // Add new site
      console.log(siteData, 'addNewSitePayload', leadData);
      try {
        const response = await UPCAxiosInstance.post(`geoSite`, siteData);
        console.log('Response for site:', response.data);
        const relatedSite = leadData.relatedParty.find(
          (obj) =>
            (obj.name === response.data.siteName ||
              obj.name === response.data.name) &&
            obj['@type'] === 'site'
        );
        if (relatedSite) {
          console.log('relatedSite', relatedSite);
          relatedSite.name = response.data.siteName || response.data.name;
          relatedSite.id = response.data.id;
        }
        if (!relatedSite) {
          leadData.relatedParty.push({
            name: response.data.siteName || response.data.name,
            id: response.data.id,
            '@type': 'site',
          });
        }
        const updatedLeadData = {
  ...leadData,
  externalReference: leadData.externalReference.map((ref) => {
    if (ref.type.toLowerCase() === "numberofsites") {
      return {
        ...ref,
        value: Number(ref.value || 0) + 1,
      };
    }
    return ref;
  }),
};
console.log("updatedLeadData",updatedLeadData)
       delete updatedLeadData._id
        updatedLeadData.id = leadData._id;
        if (response) {
          const patchLead = await UPCAxiosInstance.patch(`salesLead`, updatedLeadData);
          console.log(patchLead, 'Lead patched response');
          if(patchLead){
            this.notification = {
          type: 'success',
          message: this.loadStaticData('LANG-0036') || `Site added successfully`,
        };
          }
          this.leadOrginalData = patchLead.data;
        }
      } catch (error) {
        console.error('Error patching site details:', error);
      }
    },
    async patchEditedLead(val) {
      // to concat and remove duplicates if it existed
      val.relatedParty = [
  ...new Map(
    [...val.relatedParty, ...this.leadOrgRelatedParty].map(item => [item.id || JSON.stringify(item), item])
  ).values()
];

      console.log('patchEditedLead', val);
      this.isSaveButtonClicked = false;
      this.temporaryLeadOverviewPayload = JSON.parse(JSON.stringify(val))
      // const response = await UPCAxiosInstance.patch(`/salesLead`, val);
      // console.log('response', response);
      // const getUpdatedData = await UPCAxiosInstance.get(
      //   `/salesLead/${this.$route.params.id}`
      // );
      // this.leadOrginalData = getUpdatedData.data;
    },
   async saveEditedLead() {
    let currentDate =  new Date().toJSON()
    this.temporaryLeadOverviewPayload.lastUpdate = currentDate
    console.log(currentDate,"this.temporaryLeadOverviewPayload",this.temporaryLeadOverviewPayload.lastUpdate )
       const response = await UPCAxiosInstance.patch(`/salesLead`, this.temporaryLeadOverviewPayload);
      console.log('response', response);
      const getUpdatedData = await UPCAxiosInstance.get(
        `/salesLead/${this.$route.params.id}`
      );
      if(getUpdatedData.data){
      this.notification = {
          type: "success",
          message: "Lead details updated successfully!",
        };
      }
      this.leadOrginalData = getUpdatedData.data;
      this.leadData = getUpdatedData.data;
      this.isSaveButtonClicked = true;
      this.view = false
    },
    leadStatus(val) {
      this.leadPayload.status = val?.name;
    },
    selectedValueFunc(val) {
      this.selectedValue = val;
    },
    goTo() {
      this.$router.push('/MDU/lead');
    },
  },
};
</script>

<style>
.button-container-lead-overview{
  position: relative;
  display: inline-block;
}
.lead-overview-dropdown {
  border-radius:4px;
  font-family:Opensans;
  width: max-content;
  font-size: 14px;
  margin-top:12px;
  display: block;
  position: absolute;
  top: 100%; 
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
}
</style>
