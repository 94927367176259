<template>
  <hlx-alert-notification :notify="notification" auto-close />
  <div v-show="impersonite == true"><banner @exit="exitView()" /></div>
  <div class="add-supplier-table">
    <hlx-modal
      :key="modalcomponetkey"
      :modal-active="modal_active_user"
      :height="tenantPreferredLanguagesList.length > 0 ? '586px' : '535px'"
      :width="'750px'"
      :modal="true"
      :close-icon="true"
      :outer-layer-close-option="false"
      @close="closeAddNewSupplierModal"
    >
      <template #header>
        <!-- <div>Add User</div> -->
        <span v-if="editOrganisation === false">Add Tenant</span>
        <span v-if="editOrganisation === true">Edit Tenant</span>
        <!-- <span v-else>Add Tenant</span> -->
      </template>
      <template #content>
        <div
          class="modal-content"
          style="display: flex; gap: 5%; height: 370px !important"
        >
          <div style="display: flex; flex-direction: column; width: 50%">
            <div
              style="
                display: flex;
                justify-content: space-between;
                gap: 10px;
                margin-bottom: 24px;
              "
            >
              <span style="">
                <hlx-file-upload
                  :key="profileComponentKey"
                  :file-type="'.jpg,.jpeg,.png'"
                  :file-size-limit="'20mb'"
                  :uploadtype="'Rectangle'"
                  :style-background="backgrounStyle"
                  :height="100"
                  :width="100"
                  :read-only="false"
                  :custom-icon="icon"
                  :type="'profile-upload'"
                  :name-display="newSupplierPayload.customName"
                  :content="'Import'"
                  :profile-pic-preview="profilePicUrl"
                  @native-data="profileUpload"
                  @delete-image="deleteProfilePicture"
                />
              </span>
              <span style="width: 100%; height: 114px">
                <div class="decision-name-wrapper">
                  <!-- :style="{ 'margin-bottom': customnameerror ? '-7.6%' : 0 }" -->
                  <hlx-input
                    v-model:value="newSupplierPayload.customName"
                    :pre-val="newSupplierPayload.customName"
                    :label-value="'Organisation name'"
                    :label-animation="true"
                    :required="true"
                    :display-error="true"
                    :custom-error="isNameEmpty"
                    :custom-error-message="'Please fill the field'"
                    @focus-out="focusOutName"
                    @at-input="validateName"
                  ></hlx-input>
                </div>
                <!-- <div class="decision-name-wrapper"> -->
                <!-- <hlx-select
                    v-model="newSupplierPayload.status"
                    :pre-value="newSupplierPayload.status"
                    :options="statusList"
                    :label="'name'"
                    :prop-value="'name'"
                    :placeholder-value="'Status'"
                    :label-animation="true"
                    :clearable="false"
                    @selected-value="statusFunc"
                  ></hlx-select> -->

                <div style="width: 100%">
                  <hlx-status
                    :type="'select-status'"
                    :status-option="filteredstatus"
                    :prevalue="newSupplierPayload.status"
                    @emit-select="emitStatus1"
                  >
                  </hlx-status>
                </div>

                <!-- </div> -->
              </span>
            </div>

            <div class="decision-name-wrapper" style="margin-bottom: 20px">
              <addressComponent
                :key="addressKey"
                :pre-val="addressPreVal"
                :required="true"
                @address-data="receivedAddressData"
              ></addressComponent>
            </div>
            <section v-if="tenantPreferredLanguagesList.length>0" style="display:flex;gap:12px;padding-bottom:12px;"> 
            <span class="input-wrapper" style="width:100%">
                        <hlx-multi-select
                  :key="tagcomponetkey"
                  :options="tenantPreferredLanguagesList"
                  prop-value="name"
                  :multi-select="true"
                  :placeholder="'Preferred languages'"
                  :tooltipposition="'left'"
                  :label-animation="true"
                  :clearable="false"
                  @custom-change="getPreferredLanguage2"
                    @add-data="getPreferredLanguage"
                  @selected-array="getPreferredLanguage"
                ></hlx-multi-select>
                      </span>
                    </section>
            <!-- <div class="decision-name-wrapper">
                <hlx-select
                  v-model:value="newSupplierPayload.paymentTerms[0].name"
                  :pre-value="newSupplierPayload.paymentTerms[0].name"
                  :options="paymentList"
                  prop-value="name"
                  :placeholder-value="'Payment terms'"
                  :label-animation="true"
                  :clearable="true"
                ></hlx-select>
              </div> -->
          </div>
          <div style="display: flex; flex-direction: column; width: 50%">
            <div class="decision-name-wrapper" style="margin-bottom: 25px">
              <hlx-input
                v-model:value="newSupplierPayload.description"
                :pre-val="newSupplierPayload.description"
                type="textarea"
                :label-value="'Description'"
                :label-animation="true"
                :rows="filteredLanguageOne.length>0?11:8"
                :required="false"
                :display-error="false"
                :custom-error="customnameerror"
                :custom-error-message="''"
                @focus-out="focusOutName"
              ></hlx-input>
            </div>
            <div class="decision-name-wrapper">
              <hlx-input
                v-model:value="
                  newSupplierPayload.contactMedium[0].characteristic
                    .emailAddress
                "
                :pre-val="
                  newSupplierPayload.contactMedium[0].characteristic
                    .emailAddress
                "
                :label-value="'Email id'"
                :label-animation="true"
                type="email"
                :required="true"
                :display-error="true"
                :custom-error="isEmailEmpty"
                :custom-error-message="'Enter a valid email.(eg: user@example.com)'"
              ></hlx-input>
            </div>
            <div class="decision-name-wrapper">
              <hlx-input
                v-model:value="
                  newSupplierPayload.contactMedium[1].characteristic.phoneNumber
                "
                :pre-val="
                  newSupplierPayload.contactMedium[1].characteristic.phoneNumber
                    .value
                "
                type="phonenumber"
                :country-code="
                  newSupplierPayload.contactMedium[1].characteristic.phoneNumber
                    .country
                "
                :label-value="'Phone no.'"
                :label-animation="true"
                :display-error="true"
                :custom-error="customnameerror"
                :custom-error-message="'Please fill the field'"
                @focus-out="focusOutName"
                @at-input="validatePhoneName"
              ></hlx-input>
            </div>
            <div class="decision-name-wrapper">
              <hlx-input
                v-model:value="newSupplierPayload.externalReference[0].name"
                :pre-val="newSupplierPayload.externalReference[0].name"
                :label-animation="true"
                label-value="Website URL"
                type="text"
                :clearable="true"
                name="username"
                :display-error="true"
                :custom-error="isUrlEmpty"
                :custom-error-message="'Enter a valid webside.(eg: www.example.com)'"
                @focus-out="websiteRegex"
                @blur="validateInput"
              >
              </hlx-input>
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                gap: 25%;
                height: 155px;
              "
            >
              <!-- <div class="decision-name-wrapper">
                  <hlx-multi-select
                    :key="tagcomponetkey"
                    v-model="newSupplierPayload.tags"
                    :pre-value="newSupplierPayload.tags"
                    :options="tagsList"
                    prop-value="name"
                    :multi-select="true"
                    :placeholder="'Tags'"
                    :tooltipposition="'left'"
                    :label-animation="true"
                    :clearable="true"
                    @add-data="addTagList"
                    @selected-array="receivedTags"
                  ></hlx-multi-select>
                </div> -->
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div>
          <hlx-button
            class="secondary-success sm"
            style="padding: 10px 38px; margin-right: 15px"
            @click="closeAddNewSupplierModal"
            >Cancel</hlx-button
          >
          <hlx-button
            v-if="editOrganisation === false"
            class="primary sm"
            style="padding: 10px 38px"
            :disabled="!canAdd"
            @click="AddNewSupplierModal()"
            >Add</hlx-button
          >

          <hlx-button
            v-if="editOrganisation === true"
            class="primary sm"
            style="padding: 10px 38px"
            :disabled="!canAdd"
            @click="editSupplier()"
            >Save</hlx-button
          >
        </div>
      </template>
    </hlx-modal>
  </div>
  <!-- Reset password -->
  <div class="reset">
    <hlx-modal
      :modal-active="modal_active"
      :height="'320px'"
      :width="'360px'"
      :modal="true"
      @close="modal_active = false"
    >
      <template #header>
        <div id="header">Reset password</div>
      </template>
      <template #content>
        <div class="modal-content">
          <div class="custom-modal">
            <div class="modal-form-input-wrapper">
              <p class="modal-input-header">Create password</p>
              <div class="form-input">
                <hlx-input
                  v-model:value="s_password"
                  :label-animation="false"
                  type="password"
                  :password-condition="false"
                  label-value="Password"
                  :clearable="false"
                />
              </div>
            </div>
            <br />
            <div class="form-input-wrapper">
              <p class="modal-input-header">Confirm password</p>
              <div class="modal-form-input">
                <hlx-input
                  v-model:value="password"
                  :label-animation="false"
                  type="password"
                  :password-condition="false"
                  label-value="Password"
                  :clearable="false"
                />
              </div>
            </div>
            <br />
          </div>
        </div>
      </template>
      <template #footer>
        <hlx-button class="primary sm" @click="updatePassword(confirmUser)"
          >Reset</hlx-button
        >
      </template>
    </hlx-modal>
  </div>
  <div class="user-info-edit-modal">
    <hlx-modal
      :modal-active="edit"
      :height="'390px'"
      :width="'470px'"
      :modal="true"
      @close="edit = false"
    >
      <template #header>
        <div>Edit user</div>
      </template>
      <template #content>
        <div class="edit-user-form">
          <div class="form-input-wrapper">
            <p class="input-header">Name</p>
            <div class="form-input">
              <hlx-input
                v-model:value="editUser.name"
                :pre-val="editUser.name"
                :label-animation="false"
                type="text"
                :clearable="true"
              />
            </div>
          </div>
          <div class="form-input-wrapper">
            <p class="input-header">Work email</p>
            <div class="form-input">
              <hlx-input
                v-model:value="editUser.email"
                :pre-val="editUser.email"
                :label-animation="false"
                type="email"
                :clearable="true"
              />
            </div>
          </div>
          <div class="form-input-wrapper">
            <p class="input-header">Role</p>
            <div class="form-input">
              <hlx-select
                v-model:value="editUser.role"
                :options="roleOptions"
                :pre-value="editUser.role"
                label="role"
                prop-value="role"
                :label-animation="false"
                :clearable="true"
                @custom-change="selectValue"
              ></hlx-select>
            </div>
          </div>
          <div class="form-input-wrapper">
            <p class="input-header">Status</p>
            <div class="form-input">
              <!-- <hlx-select
                  v-model:value="editUser.status"
                  :options="computedUserStatusOptions(editUser.status)"
                  :pre-value="editUser.status"
                  :label-animation="false"
                  :clearable="true"
                  @custom-change="selectValue"
                ></hlx-select> -->
              <hlx-status
                :type="'select-status'"
                :status-option="computedUserStatusOptions(editUser.status)"
                :prevalue="editUser.status"
                :label-animation="false"
                @emit-select="handleUserStatusChange"
              >
              </hlx-status>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="footer-form-edit">
          <div><p class="insider"></p></div>
          <div class="button-area">
            <hlx-button class="secondary sm" @click="edit = false"
              >Cancel</hlx-button
            >

            <hlx-button class="primary sm" @click="saveUser()">Save</hlx-button>
          </div>
        </div>
      </template>
    </hlx-modal>
  </div>
  <!-- Password reset modal -->
  <div class="reset-modal">
    <hlx-modal
      :modal-active="resetModal"
      :height="'220px'"
      :width="'370px'"
      :modal="true"
      @close="resetModal = false"
    >
      <template #header>
        <div>Reset password</div>
      </template>
      <template #content>
        <p
          style="
            text-align: left;
            padding-left: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-right: 20px;
          "
        >
          Are you sure about to changing password
          <span style="color: var(--hlx-color-primary)">
            {{ confirmUser.name }}
          </span>
          permanently ?
        </p>
      </template>
      <template #footer>
        <div class="footer-form-delte">
          <div class="button-area">
            <hlx-button class="secondary sm" @click="resetModal = false"
              >No</hlx-button
            >

            <hlx-button class="primary sm" @click="updatePassword(confirmUser)"
              >Yes</hlx-button
            >
          </div>
        </div>
      </template>
    </hlx-modal>
  </div>
  <!-- User delete -->
  <div class="confirm-delete-modal">
    <hlx-modal
      :modal-active="deleteModal"
      :height="'220px'"
      :width="'370px'"
      :modal="true"
      @close="deleteModal = false"
    >
      <template #header>
        <div>Delete user</div>
      </template>
      <template #content>
        <p
          style="
            text-align: left;
            padding-left: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
          "
        >
          Are you sure about to deleting
          <span style="color: var(--hlx-color-primary)">
            {{ confirmUser.name }}
          </span>
          permanently ?
        </p>
      </template>
      <template #footer>
        <div class="footer-form-delte">
          <div class="button-area">
            <hlx-button class="secondary sm" @click="deleteModal = false"
              >No</hlx-button
            >

            <hlx-button class="primary sm" @click="confirmDelete(confirmUser)"
              >Yes</hlx-button
            >
          </div>
        </div>
      </template>
    </hlx-modal>
  </div>

  <div class="app-layout">
    <section class="new-right-panel">
      <div class="new-right-panel-parent-container no-filter-panel">
        <div class="new-right-panel-left-container"></div>
        <div class="new-right-panel-right-container">
          <div class="new-layout-basic-no-breadcrumb">
            <div class="new-layout-breadcrumb">
              <hlx-breadcrumb :items="items" @path="pathTo" />
            </div>

            <div class="new-layout-header">
              <span class="schema-header-title">Tenant</span>
              <!-- <span v-elseclass="schema-header-title">Tenants</span> -->
            </div>

            <div class="new-layout-body">
              <div
                class="header-utils-and-switch-container"
                style="width: 100%"
              >
                <span style="width: 70%">
                  <hlx-switch
                    v-model="current_switch_value"
                    :switch-items="users_type_switch"
                    :tab-view="true"
                    value-prop="value"
                    display-prop="name"
                    @change="usersSelected"
                  />
                </span>
                <span class="schema-header-utils">
                  <span class="util-items search"
                    ><hlx-search
                      class="search"
                      :search-style="'style1'"
                      @search-key="handleUserSearch"
                    ></hlx-search
                  ></span>
                  <hlx-button class="primary sm" @click="addNewUser">
                    <i class="icon-plus-circle-regular"></i>
                    <span> Add Tenant</span>
                    <!-- <span v-else>Add Tenants</span> -->
                  </hlx-button>
                  <!-- <span
                      v-if="inviteUser == true"
                      class="util-items util-items-add"
                      ><hlx-button
                        v-show="inviteUser == true"
                        class="primary sm add-btn"
                        ><i class="icon-plus-circle-regular"></i> Invite
                        user</hlx-button
                      >
                    </span> -->
                </span>
              </div>

              <!-- Table -->
              <div class="table-wrap">
                <hlx-table
                  :column-count="headvalue.length + 2"
                  :border="
                    tableData.length == 0
                      ? ['table', 'header']
                      : ['table', 'header', 'horizontal']
                  "
                  :bold-headers="false"
                  :row-hover="true"
                  theme="grey"
                  :striped-rows="false"
                  :re-arrange-columns="re_arrange"
                  :re-arrange-heads="theadsC"
                  @sorting_func="sorting_Data"
                  @close-rearrange="arrange"
                  @apply-rearranged-columns="applyColumns"
                >
                  <template #thead>
                    <hlx-table-head
                      :width="60"
                      :align="'center'"
                      style="padding: 0 15px"
                      :fixed="'left'"
                      >{{ "S.no" }}</hlx-table-head
                    >

                    <hlx-table-head
                      v-for="(i, index) in headvalue"
                      :key="index"
                      :prop="i.prop"
                      :sortable="i.sortable"
                      :resizable="i.resizable"
                      :fixed="i.fixed"
                      :width="i.width ? i.width : ''"
                      :align="i.align ? i.align : ''"
                      @sorting_func="sorting_Data"
                    >
                      {{ i.label }}
                    </hlx-table-head>
                    <hlx-table-head
                      :align="'center'"
                      :width="70"
                      :fixed="'right'"
                      ><span class="action-icon">
                        <i
                          class="icon-settings-regular"
                          @click="arrange(true)"
                        ></i> </span
                    ></hlx-table-head>
                  </template>
                  <template v-if="tableData.length > 0" #tbody>
                    <tr v-for="(i, index) in paginatedData" id="" :key="index">
                      <hlx-table-cell :align="'center'" :fixed="'left'">
                        {{ serialNumber(index) }}
                      </hlx-table-cell>
                      <hlx-table-cell
                        v-for="(j, col_index) in headvalue"
                        :key="col_index"
                        :align="j.align"
                        :fixed="j.fixed"
                      >
                        <!-- Role -->
                        <div
                      v-if="j.prop.toLowerCase() === 'customname'"
                      :id="j.prop + index"
                      class="link-name"
                      @click="handleRelatedParty(i)"
                    >
                      
                        {{ i.name }}
                    
                    </div>
                        <div
                          v-if="
                            j.prop?.trim() == 'role' &&
                            i[j.prop] &&
                            i[j.prop].length > 0 &&
                            i[j.prop][0]?.name
                          "
                          class="user-role"
                        >
                          <hlx-label class="primary sm">
                            {{ i[j.prop][0]?.name }}
                          </hlx-label>
                        </div>
                        <!-- No role -->
                        <div
                          v-else-if="
                            j.prop?.trim() == 'role' &&
                            i[j.prop] &&
                            i[j.prop].length == 0
                          "
                          class="user-role"
                        >
                          -
                        </div>
                        <!-- Status -->
                        <div
                          v-else-if="j.prop?.trim() == 'status'"
                          class="user-status"
                        >
                          <hlx-status :type="i[j.prop]" />
                        </div>
                        <!-- <div
                          v-else-if="j.prop?.trim() == 'count'"
                          class="user-status"
                        >
                          {{ i.relatedParty.length }}
                        </div> -->
                        <!-- Last visit and created at -->
                        <div
                          v-else-if="
                            ['lastVisit', 'createdAt'].includes(j.prop)
                          "
                          class="user-created-last-visit"
                        >
                          {{ dateAndTimeFormatter(i[j.prop]) }}
                        </div>
                        <div v-else-if="j.prop.toLowerCase() === 'email'">
              {{ i[j.prop] }}
            </div>
                      </hlx-table-cell>
                      <hlx-table-cell
                        :align="'center'"
                        :width="120"
                        :fixed="'right'"
                      >
                        <span :id="'table-context' + index" class="action-icon">
                          <!-- <i class="icon-edit-regular"></i> &nbsp;
                            <i class="icon-copy-regular"></i> &nbsp;
                            <i class="icon-trash-regular"></i> -->
                          <i
                            style="position: relative"
                            class="icon-more-vertical-filled"
                            :class="i.context === true ? 'active-action' : ''"
                            @click="crudContextMenu($event, i, index)"
                          >
                            <hlx-context-menu
                              :top="containertop"
                              :left="containerleft"
                              :conditionvalue="150"
                              :conditiondown="2"
                              :conditionup="-38"
                              :options="computedTableAction(i)"
                              :data="index.toString()"
                              :show="i.context == true"
                              @chosen="closeCrudContextMenu($event, i, index)"
                            />
                          </i>
                        </span>
                      </hlx-table-cell>
                    </tr>
                  </template>
                  <template v-else #tbody>
                    <tr v-for="item in 10" id="" :key="item">
                      <hlx-table-cell
                        v-if="item == 5"
                        :colspan="theads.length + 2"
                      >
                        No data
                      </hlx-table-cell>
                      <hlx-table-cell v-else :align="'center'">
                      </hlx-table-cell>
                    </tr>
                  </template>
                </hlx-table>
              </div>

              <!-- Pagination -->
              <div
                v-if="tableData?.length > 0"
                class="pagination-container"
                style="
                  padding: 1rem;
                  position: fixed;
                  bottom: 0px;
                  width: -webkit-fill-available;
                "
              >
                <hlx-pagination
                  v-model:rows-per-page="externalRowsPerPage"
                  :total="totalPageNo"
                  enable-rows-per-page
                  rows-per-page
                  :rows-per-page-list="[10, 15, 20]"
                  :options="{
                    attributes: {
                      rowsPerPageTitle: true,
                      rowsPerPageResult: true,
                      rowsPerPageDropdown: {
                        position: {
                          top: 0,
                          right: 1,
                        },
                      },
                    },
                  }"
                  @current-page="currentPage"
                  @updated:rows-per-page="changeRowsPerPage"
                ></hlx-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import addressComponent from "@/components/CM/autocompleteComponent.vue";

import banner from "../components/bannerComponent.vue";
import { DMNAxiosInstance, MLTAxiosInstance, UPCAxiosInstance } from "@/config/axiosConfig";
import { mapGetters } from "vuex";
export default {
  name: "UserView",
  components: { banner, addressComponent },
  data() {
    return {
      tenantPreferredLanguagesList:[],
      filteredLanguageOne:[],
      filteredLanguageTwo:[],
      selectedLanguageOne: null,
      selectedLanguageTwo: null,
      preferredLanguages:null,
      choosenLanguages:[],
      preferredLanguageTwo:[],
      updateLanguageSelect:0,
    defaultLanguage:null,
    languages:[
  { code: "ab", name: "Abkhazian" },
  { code: "ace", name: "Achinese" },
  { code: "ach", name: "Acoli" },
  { code: "af", name: "Afrikaans" },
  { code: "ak", name: "Akan" },
  { code: "alz", name: "Alur" },
  { code: "am", name: "Amharic" },
  { code: "ar", name: "Arabic" },
  { code: "as", name: "Assamese" },
  { code: "awa", name: "Awadhi" },
  { code: "ay", name: "Aymara" },
  { code: "az", name: "Azerbaijani" },
  { code: "ba", name: "Bashkir" },
  { code: "ban", name: "Balinese" },
  { code: "bbc", name: "Batak Toba" },
  { code: "be", name: "Belarusian" },
  { code: "bem", name: "Bemba" },
  { code: "bew", name: "Betawi" },
  { code: "bg", name: "Bulgarian" },
  { code: "bho", name: "Bhojpuri" },
  { code: "bik", name: "Bikol" },
  { code: "bm", name: "Bambara" },
  { code: "bn", name: "Bengali" },
  { code: "br", name: "Breton" },
  { code: "bs", name: "Bosnian" },
  { code: "bts", name: "Batak Simalungun" },
  { code: "btx", name: "Batak Karo" },
  { code: "bua", name: "Buryat" },
  { code: "ca", name: "Catalan" },
  { code: "ceb", name: "Cebuano" },
  { code: "cgg", name: "Chiga" },
  { code: "chm", name: "Mari" },
  { code: "ckb", name: "Sorani Kurdish" },
  { code: "cnh", name: "Chin" },
  { code: "co", name: "Corsican" },
  { code: "crh", name: "Crimean Tatar" },
  { code: "crs", name: "Seselwa Creole French" },
  { code: "cs", name: "Czech" },
  { code: "cv", name: "Chuvash" },
  { code: "cy", name: "Welsh" },
  { code: "da", name: "Danish" },
  { code: "de", name: "German" },
  { code: "din", name: "Dinka" },
  { code: "doi", name: "Dogri" },
  { code: "dov", name: "Dombe" },
  { code: "dv", name: "Dhivehi" },
  { code: "dz", name: "Dzongkha" },
  { code: "ee", name: "Ewe" },
  { code: "el", name: "Greek" },
  { code: "en", name: "English" },
  { code: "eo", name: "Esperanto" },
  { code: "es", name: "Spanish" },
  { code: "et", name: "Estonian" },
  { code: "eu", name: "Basque" },
  { code: "fa", name: "Persian" },
  { code: "ff", name: "Fula" },
  { code: "fi", name: "Finnish" },
  { code: "fj", name: "Fijian" },
  { code: "fr", name: "French" },
  { code: "fy", name: "Western Frisian" },
  { code: "ga", name: "Irish" },
  { code: "gaa", name: "Ga" },
  { code: "gd", name: "Scottish Gaelic" },
  { code: "gl", name: "Galician" },
  { code: "gn", name: "Guarani" },
  { code: "gom", name: "Konkani" },
  { code: "gu", name: "Gujarati" },
  { code: "ha", name: "Hausa" },
  { code: "haw", name: "Hawaiian" },
  { code: "he", name: "Hebrew" },
  { code: "hi", name: "Hindi" },
  { code: "hil", name: "Hiligaynon" },
  { code: "hmn", name: "Hmong" },
  { code: "hr", name: "Croatian" },
  { code: "hrx", name: "Hunsrik" },
  { code: "ht", name: "Haitian Creole" },
  { code: "hu", name: "Hungarian" },
  { code: "hy", name: "Armenian" },
  { code: "id", name: "Indonesian" },
  { code: "ig", name: "Igbo" },
  { code: "ilo", name: "Ilocano" },
  { code: "is", name: "Icelandic" },
  { code: "it", name: "Italian" },
  { code: "iw", name: "Hebrew (traditional code 'iw')" },
  { code: "ja", name: "Japanese" },
  { code: "jv", name: "Javanese" },
  { code: "jw", name: "Javanese (different script)" },
  { code: "ka", name: "Georgian" },
  { code: "kk", name: "Kazakh" },
  { code: "km", name: "Khmer" },
  { code: "kn", name: "Kannada" },
  { code: "ko", name: "Korean" },
  { code: "kri", name: "Krio" },
  { code: "ktu", name: "Kanuri" },
  { code: "ku", name: "Kurdish" },
  { code: "ky", name: "Kyrgyz" },
  { code: "la", name: "Latin" },
  { code: "lb", name: "Luxembourgish" },
  { code: "lg", name: "Luganda" },
  { code: "li", name: "Limburgish" },
  { code: "lij", name: "Ligurian" },
  { code: "lmo", name: "Lombard" },
  { code: "ln", name: "Lingala" },
  { code: "lo", name: "Lao" },
  { code: "lt", name: "Lithuanian" },
  { code: "ltg", name: "Latgalian" },
  { code: "luo", name: "Luo" },
  { code: "lus", name: "Mizo" },
  { code: "lv", name: "Latvian" },
  { code: "mai", name: "Maithili" },
  { code: "mak", name: "Makassarese" },
  { code: "mg", name: "Malagasy" },
  { code: "mi", name: "Maori" },
  { code: "min", name: "Minangkabau" },
  { code: "mk", name: "Macedonian" },
  { code: "ml", name: "Malayalam" },
  { code: "mn", name: "Mongolian" },
  { code: "mni-Mtei", name: "Manipuri (Meitei Mayek)" },
  { code: "mr", name: "Marathi" },
  { code: "ms", name: "Malay" },
  { code: "ms-Arab", name: "Malay (Arabic script)" },
  { code: "mt", name: "Maltese" },
  { code: "my", name: "Burmese" },
  { code: "ne", name: "Nepali" },
  { code: "new", name: "Newari" },
  { code: "nl", name: "Dutch" },
  { code: "no", name: "Norwegian" },
  { code: "nr", name: "Ndebele" },
  { code: "nso", name: "Northern Sotho" },
  { code: "nus", name: "Nuer" },
  { code: "ny", name: "Nyanja" },
  { code: "oc", name: "Occitan" },
  { code: "om", name: "Oromo" },
  { code: "or", name: "Odia" },
  { code: "pa", name: "Punjabi" },
  { code: "pa-Arab", name: "Punjabi (Arabic script)" },
  { code: "pag", name: "Pangasinan" },
  { code: "pam", name: "Pampangan" },
  { code: "pap", name: "Papiamento" },
  { code: "pl", name: "Polish" },
  { code: "ps", name: "Pashto" },
  { code: "pt", name: "Portuguese" },
  { code: "qu", name: "Quechua" },
  { code: "rn", name: "Rundi" },
  { code: "ro", name: "Romanian" },
  { code: "rom", name: "Romani" },
  { code: "ru", name: "Russian" },
  { code: "rw", name: "Kinyarwanda" },
  { code: "sa", name: "Sanskrit" },
  { code: "sc", name: "Sicilian" },
  { code: "sd", name: "Sindhi" },
  { code: "sg", name: "Sango" },
  { code: "sh", name: "Shan" },
  { code: "si", name: "Sinhala" },
  { code: "sk", name: "Slovak" },
  { code: "sl", name: "Slovenian" },
  { code: "sm", name: "Samoan" },
  { code: "sn", name: "Shona" },
  { code: "so", name: "Somali" },
  { code: "sq", name: "Albanian" },
  { code: "sr", name: "Serbian" },
  { code: "ss", name: "Swati" },
  { code: "st", name: "Southern Sotho" },
  { code: "su", name: "Sundanese" },
  { code: "sv", name: "Swedish" },
  { code: "sw", name: "Swahili" },
  { code: "szl", name: "Silesian" },
  { code: "ta", name: "Tamil" },
  { code: "te", name: "Telugu" },
  { code: "tet", name: "Tetum" },
  { code: "tg", name: "Tajik" },
  { code: "th", name: "Thai" },
  { code: "ti", name: "Tigrinya" },
  { code: "tk", name: "Turkmen" },
  { code: "tl", name: "Tagalog" },
  { code: "tn", name: "Tswana" },
  { code: "tr", name: "Turkish" },
  { code: "ts", name: "Tsonga" },
  { code: "tt", name: "Tatar" },
  { code: "ug", name: "Uyghur" },
  { code: "uk", name: "Ukrainian" },
  { code: "ur", name: "Urdu" },
  { code: "uz", name: "Uzbek" },
  { code: "vi", name: "Vietnamese" },
  { code: "xh", name: "Xhosa" },
  { code: "yi", name: "Yiddish" },
  { code: "yo", name: "Yoruba" },
  { code: "yua", name: "Yucatec Maya" },
  { code: "yue", name: "Yue Chinese (Cantonese)" },
  { code: "zh", name: "Chinese (generic)" },
  { code: "zh-Hans", name: "Chinese (Simplified)" },
  { code: "zh-Hant", name: "Chinese (Traditional)" },
  { code: "zu", name: "Zulu" }
],
      filterobj: {},
      newProfileData: null,
      newProfileformData: null,
      rawProfileUrlData: null,
      profileComponentKey: 0,
      newProfileAdded: false,
      profilePicName: "",
      deleteProfilePic: false,
      profilePicUrl: "",
      canAdd: false,
      editOrganisation: false,
      access: "",
      addressKey: 0,
      roleSelectKey: 0,
      fileuploadKey: 0,
      isUrlEmpty: false,
      newSupplierPayload: {
        attachment: [],
        tradingName: "",
        customName: "",
        name: "",
        status: "Active",
        preferred: "",
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              address1: "",
              default: true,
              "@type": "",
            },
          },
        ],

        description: "",
        tags: [],

        notes: [],
        role: "",
        type: "company",
        password: "",
        email: "",
        familyName: "",
        givenName: "",
        companyName: "",
        parentCompany: "",
        rootName: "",
        "@baseType": "organisation",
      },
      modal_active_user: false,
      // searchValue:"",
      externalRowsPerPage: 10,
      containerleft: 0,
      totalPageNo: 0,
      containertop: 0,
      current_switch_value: "All",
      s_password: "",
      password: "",
      resetModal: false,
      modal_active: false,
      modal_active3: false,
      searchValue: "",
      // inviteUser: false,
      editMode: true,
      viewPermission: [],
      editPermission: [],
      token: this.$cookies.get("token"),
      impersonite: false,
      deleteModal: false,
      editUser: {},
      roleOptions: [
        { name: "Business admin", value: "Business admin" },
        { name: "Business user", value: "Business user" },
        { name: "Read only", value: "Read only" },
      ],
      status_data: [
        { name: "Active", value: "Active" },
        { name: "Inactive", value: "Inactive" },
      ],
      edit: false,
      view: "All",
      items: [
        { label: "Settings", link: "/settings" },
        { label: "Users", link: "/" },
      ],
      users_type_switch: [
        { name: "All", value: "All" },
        { name: "Active", value: "Active" },
        { name: "Inactive", value: "Inactive" },
        //   { name: "Pending", value: "Pending" },
      ],
      selectedValue: { name: "All", value: "All" },
      options: [],
      options3: [
        {
          label: "Edit",
          icon: "icon-edit-regular",
        },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },

        {
          label: "Send activation email",
          icon: "icon-envelope-regular",
        },
      ],
      options2: [
        {
          label: "Edit",
          icon: "icon-edit-regular",
        },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
        {
          label: "Activate",
          icon: "icon-toggle-on-alt-regular",
        },
        {
          label: "Activity log",
          icon: "icon-clock-regular",
        },
      ],
      tableData: [],
      theads: [
        { name: "Name", checked: true, id: 2, width: 100 },
        { name: "Email", checked: true, id: 3, width: null },
        { name: "Role", checked: true, id: 4, width: 100 },
        { name: "Created on", checked: true, id: 5, width: 100 },
        { name: "Last visited", checked: true, id: 6, width: 100 },
        { name: "Status", checked: true, id: 7, width: 100 },
      ],
      theadsC: [
        { name: "Name", checked: true, id: 2, width: 100 },
        { name: "Email", checked: true, id: 3, width: null },
        { name: "Role", checked: true, id: 4, width: 100 },
        { name: "Created on", checked: true, id: 5, width: 100 },
        { name: "Last visited", checked: true, id: 6, width: 100 },
        { name: "Status", checked: true, id: 7, width: 100 },
      ],
      headvalue: [
        {
          prop: "customname",
          label: "Name",
          sortable: false,
          resizable: true,
          align: "left",
          width: 200,
        },
        {
          prop: "email",
          label: "Email",
          sortable: false,
          align: "left",

          resizable: true,
          width: null,
        },
        {
          prop: "status",
          label: "Status",
          sortable: false,
          align: "left",

          resizable: true,
          width: 93,
        },
        {
          prop: "count",
          label: "Count",
          sortable: false,
          align: "left",

          resizable: true,
          width: 93,
        },
        {
          prop: "createdAt",
          label: "Created on",
          sortable: false,
          align: "left",

          resizable: true,
          width: 203,
        },
      ],
      listView: {},
      searchtabledata: [],
      duplicatetabledata: [],
      currPage: 1,
      rowsPerPage: 10,
      addressPreVal: {
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
        },
      },
      options1: [
        {
          label: "Edit",
          icon: "icon-edit-regular",
        },
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
        {
          label: "Deactivate",
          icon: "icon-toggle-off-alt-regular",
        },
        {
          label: "Reset password",
          icon: "icon-arrow-forward-circle-alt-regular",
        },
        {
          label: "Activity log",
          icon: "icon-clock-regular",
        },
      ],
      confirmUser: {},
      company: this.$cookies.get("company"),
      impersonitor: "",
      name: "",
      userId: "",
      fet: process.env.VUE_APP_HOST_PROTOCOL + "://" + process.env.VUE_APP_HOST,
      userFilterOptions: {
        type: "filter",
        company: this.$cookies.get("company"),
        module: "hierarchyaccounts",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          "@baseType": "organisation",
        },
        searchQuery: "",
      },
    };
  },
  computed: {
    ...mapGetters(["statusList"]),
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.tableData.length;
    },
  },
  watch: {
    "$store.getters.defaultSetting": {
      handler(val) {
        this.newSupplierPayload.contactMedium[1].characteristic.phoneNumber.country.name =
          val?.country;
        this.newSupplierPayload.contactMedium[1].characteristic.phoneNumber.country.short =
          val?.cc2;
        this.newSupplierPayload.contactMedium[1].characteristic.phoneNumber.country.flags =
          val?.flags?.svg;
          this.defaultLanguage = val.languages
          if(this.defaultLanguage){
            this.updateLanguageSelect+=1;
            console.log(this.defaultLanguage, "defaultSetting");
          //  this.preferredLanguages = this.tenantPreferredLanguagesList()
          //  console.log("this.preferredLanguages",this.preferredLanguages)
//           this.defaultLanguage =  Object.fromEntries(
//   Object.entries(this.defaultLanguage).filter(([key, value]) => value !== "English" || key !== 'eng')
// );
          }
      },
      immediate: true,
      deep: true,
    },
    //   statusList: {
    //     handler(val) {
    //       console.log(val, "statuswatching000000000000");
    //       const filteredstatuslist = this.statusList.filter(
    //         (item) =>
    //           item.name === "Active" ||
    //           item.name === "Inactive" ||
    //           item.name === "Pending"
    //       );
    //       this.filteredstatus = filteredstatuslist;
    //       console.log("filtered11111111111111111", this.filteredstatus);
    //     },
    //     immediate: true,
    //     deep: true,
    //   },
    isEmailEmpty: {
      handler(val) {
        console.log(val), "jodkvn";
      },
      immediate: true,
      deep: true,
    },
    loaderApi: {
      handler(val) {
        console.log(val);
      },
      immediate: true,
      deep: true,
    },
    newSupplierPayload: {
      handler(val) {
        console.log(val.role, "customName");

        val.tradingName = this.sanitizeDatabaseName(val.customName);
        val.familyName = this.sanitizeDatabaseName(val.customName);
        val.givenName = this.sanitizeDatabaseName(val.customName);
        val.companyName = this.sanitizeDatabaseName(val.customName);
        val.name = this.sanitizeDatabaseName(val.customName);
        val.email = val.contactMedium[0].characteristic.emailAddress;
        val.parentCompany = this.company;
        val.rootName = this.company;
        this.websiteRegex(val.externalReference[0].name);
        // console.log(val.name.length,this.validateEmail(val.contactMedium[0].characteristic.emailAddress),this.validatePhoneNumber(val.contactMedium[1].characteristic.phoneNumber),!this.isUrlEmpty,"!this.isUrlEmpty!this.isUrlEmpty!this.isUrlEmpty");
        if (
          val.name !== undefined &&
          val.name.length > 0 &&
          this.validateAddress(this.newSupplierPayload.contactMedium[3]) &&
          this.validateEmail(
            val.contactMedium[0].characteristic.emailAddress
          ) &&
          this.validatePhoneNumber(
            val.contactMedium[1].characteristic.phoneNumber
          ) &&
          !this.isUrlEmpty
        ) {
          this.canAdd = true;
        } else {
          this.canAdd = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    // console.log("tenantPreferredLanguagesList",this.tenantPreferredLanguagesList)
    let userInfo = await MLTAxiosInstance.get(
      "/partymanagement/individual/myinfo/" +
        this.$cookies.get("user") +
        "?company=" +
        this.$cookies.get("company")
    );
    console.log("🚀 ~ userInfo:", userInfo);
    if (!userInfo.data) return;
    userInfo = userInfo.data;
    // let arr = access.data;
    if (userInfo.role.name != "Business admin") {
      this.editMode = false;
    }
    // const rootName = this.$cookies.get("rootName");

    // const tagsListurl =
    //     rootName === "Halleyx"
    //       ? "mapRef/collection/tagsList"
    //       : `mapRef/collection/tagsList?company=${this.$cookies.get("rootName")}`;

    // let tagsList = await DMNAxiosInstance.get(tagsListurl);
    // this.tagsList = tagsList.data.data.filter((item) => {
    //   return item.module === "sm";
    // });
    this.filteredstatus = [
      {
        name: "Active",
        value: "Active",
        color: "#007247",
      },
      {
        name: "Inactive",
        value: "Inactive",
        color: "#ff5050",
      },
    ];

    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    const asscss = await MLTAxiosInstance.get(
      `partyRole/get/${val.data.userId}`
    );
    this.access = asscss.data.role;
    const instance = await MLTAxiosInstance.get(
      `/partymanagement/organisation/${val.data.userId}?company=${val.data.company}`
    );

    this.instanceData = instance.data;
    this.instanceAddress = this.instanceData.contactMedium.find(
      (item) => item.mediumType === "postalAddress"
    );

    console.log(
      this.instanceData,
      this.instanceAddress?.characteristic?.description,
      "this.instanceData"
    );
    this.impersonite = userInfo.impersonation;
    this.impersonitor = userInfo.impersinater;
    this.name = userInfo.name;
    this.userId = userInfo._id;
    this.company = userInfo.company;
    // Load roles
    const roles = await MLTAxiosInstance.get("/partyRole");
    this.roleOptions = roles.data;

    const filterResultData = await MLTAxiosInstance.post(
      `util/filterData`,
      this.userFilterOptions
    );
    // this.userFilterOptions.filterQuery.ref='CON-0001'
    // const filterResultDatarefPayload = await MLTAxiosInstance.post(
    //   `util/filterData`,
    //   this.userFilterOptions
    // );
    console.log("🚀 ~ filterResultData:", filterResultData);
    this.tableData = filterResultData.data.data;
    this.totalPageNo = filterResultData.data.total;
    console.log(this.newSupplierPayload ,"tenantPreferredLanguagesList",this.tenantPreferredLanguagesList)
  },

  methods: {
    getPreferredLanguage(val){
console.log("getPreferredLanguage",val)
// this.choosenLanguages = val
    },
    getPreferredLanguage2(val){
console.log("getPreferredLanguage2",val)
this.choosenLanguages = val
    },
    handleRelatedParty(value) {
      // let arr = []
      // arr.push(value)
      // console.log(value, 'kkdkd',this.filterobj);
      // this.filterobj['comany'] = value
      // this.$store.commit('userPreval', this.filterobj)
      // this.$store.commit('userPreval', this.filterobj)
      this.$router.push({ name: 'userView', query: { contact: value.tradingName } });

      // this.$router.push("product-catalog-table/offering")
    },
    async bufferToDataURL(buffer) {
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = "";
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },

 
    async refreshTable() {
      const filterResultData = await MLTAxiosInstance.post(
        `util/filterData`,
        this.userFilterOptions
      );
      console.log("🚀 ~ filterResultData:", filterResultData);
      this.tableData = filterResultData.data.data;
      this.totalPageNo = filterResultData.data.total;
    },
    async addLanguageForAllReference(result1) {
      console.log("t3t3t3 addLanguageForAllReference",result1);
      let language = result1.languageAbility; 
      let company = result1.name;
      const results = language.reduce((acc, lang) => {
    acc[lang.name] = { "type": "String", "required": false };
        return acc;    
      }, {});      
      language = results;
      let payload1 = {
    "type": "filter",
    "module": "schema",
    "isPaginated": false,
    "paginatedQuery": {
        "rowsPerPage": 10,
        "currentPage": 1
    },
    "isNeedToBeSort": false,
    "sortFieldAndOrder": {},
    "limit": 10,
    "filterQuery": {},
    "searchQuery": "",
    "company": company
}
console.log("t3t3t3 payload",payload1);
      let response = await DMNAxiosInstance.post(
        `util/filterData`,
        payload1
      );
      let result2 = response?.data?.data?.data;
        console.log("t3t3t3 response", response, result2);
        for (let i = 0; i < result2?.length; i++) {
          let singleObj = result2[i];
          console.log("t3t3t3 each",singleObj);
          
          // if (singleObj && singleObj?.name && singleObj?.name === "testStatus") {
            let getSingleSchema = await this.getSingleSchema(singleObj?._id);
          console.log("t3t3t3 getSingleSchema", getSingleSchema);
          if (getSingleSchema) {
            let patchSchema = await this.patchSchema(getSingleSchema.name, getSingleSchema.schema, getSingleSchema.description, singleObj?._id, language);
            patchSchema
            console.log("t3t3t3 patchSchema",patchSchema);  
          } else {
              console.log("t3t3t3 getSingleSchema is null");
              
            }
            
          // }
        // console.log(`Processing item ${i + 1}: ${this.items[i]}`);
        // Add additional processing here
      }
      console.log("t3t3t3 time end");
    },
    async AddNewSupplierModal() {
      if(this.choosenLanguages.length>0){
this.newSupplierPayload.languageAbility = this.choosenLanguages;
      }else{
        this.newSupplierPayload.languageAbility = this.tenantPreferredLanguagesList 
      }
      this.canAdd = false;
      console.log(this.isEmailEmpty, this.isNameEmpty, "error");
      let payload = JSON.parse(JSON.stringify(this.newSupplierPayload));

      try {
        let result = await MLTAxiosInstance.post(
          `/partymanagement/individual/account/signup`,
          payload
        );
        console.log(result, "t3t3t3 result");
        console.log(result.data.message, "result.data.tradingName");
        setTimeout(() => {
          if (result) {            
            // this.addLanguageForAllReference(result.data.data);
          }          
        }, 7000);
        if (result.data.ok === 1) {
          this.notification = {
            type: "success",
            message: `Nice work! Your new organization "${this.newSupplierPayload.name}" is now in the list!`,
          };
        } else {
          this.notification = {
            type: "warning",
            message: `This organization name already exit  `,
          };
        }
      } catch (error) {
        console.log(this.newSupplierPayload,"error from saving data",error)
        // this.addLanguageForAllReference(data);
        if (error.response) {
          console.log("Error k data:", error.response.data);
          console.log("Error status:", error.response.status);
          console.log("Error headers:", error.response.headers);
        } else if (error.request) {
          this.notification = {
            type: "warning",
            message: `This email was already registered`,
          };
          console.log("Error request:", error.request);
        } else {
          console.log("Error message:", error.message);
        }
        this.notification = {
          type: "warning",
          message: `This email was already registered`,
        };
        console.log("Error config:", error.config);
      }

      this.addressKey += 1;

      // this.supplierdataCalling();
      this.closeAddNewSupplierModal();
      this.refreshTable();
      console.log("t3t3t3 function ended");
      
    },
    async getSingleSchema(id){
      console.log("t3t3t3 getSingleSchema", id);
      let response = await DMNAxiosInstance.get(
          `/rule_engine/schema/${id}`
      );
      console.log("t3t3t3 response of getSingleSchema", response);
      if (response && response?.data && response?.data?.name) {
        let obj = {
          name: response.data.name,
          schema: response.data.schema,
          description: response.data.description
        }
        return obj
      } else {
        return null; 
      }
       
      
                
    },
    async patchSchema(name, schema, description, id, language) {        
      console.log("t3t3t3 patchSchema", name, schema, description, id, language);
      let payload = {
        name: name,
        description: description,
        schema: schema,
        type: "manual"
      };
      payload = { ...language, ...payload.schema }
      console.log("t3t3t3 payload of patchSchema",payload);  
      let response = await MLTAxiosInstance.patch(
          `/rule_engine/schema/${id}`,
          payload
      );        
      console.log("t3t3t3 response of patchSchema", response);
      return response
    },
   async receivedAddressData(val) {
      console.log(val);
      let address1 = JSON.parse(JSON.stringify(val));
      this.newSupplierPayload.contactMedium[3] = JSON.parse(
        JSON.stringify(address1)
      );
      this.newSupplierPayload.contactMedium[3].mediumType = "postalLetters"
      this.newSupplierPayload.country = val?.characteristic?.country
      const countryName = val.characteristic.country
      if(countryName){
     const response =  await fetch(`https://restcountries.com/v3.1/name/${countryName}`)
     console.log("response",response)
     const countryData = await response.json(); // Parse JSON data
     console.log("Country data:", countryData);
     const languageData = countryData
  .filter((ele) => ele?.name?.common?.toLowerCase() === countryName?.toLowerCase())
  .map((ele) => ele?.languages);
  this.updateLanguageSelect+=1;
  this.defaultLanguage = languageData[0]
  console.log("this.defaultLanguage",this.defaultLanguage)
//   this.defaultLanguage =  Object?.fromEntries(
//   Object?.entries(this.defaultLanguage)?.filter(([key, value]) => value !== "English" || key !== 'eng')
// );
this.defaultLanguage = this.languages.filter(lang =>
      Object.keys(this.defaultLanguage).includes(lang.code) || 
      Object.values(this.defaultLanguage).includes(lang.name)
    );
    this.defaultLanguage =  this.defaultLanguage.map(lang => {
        const isEnglish = lang.code === "en" || lang.name === "English";
        return {
          ...lang,
          checked: isEnglish ? true : false,  
          disabled: isEnglish ? true : false, 
        };
      });
      const hasEnglish = this.defaultLanguage.some(
      lang => lang.code === "en" && lang.name === "English"
    );

    if (!hasEnglish) {
      this.defaultLanguage.push({
        code: "en",
        name: "English",
        checked: true,
        disabled: true
      });
    }
   this.tenantPreferredLanguagesList = JSON.parse(JSON.stringify(this.defaultLanguage))
  console.log(this.defaultLanguage,"languageData",languageData)
      }
      // if (val.characteristic.country !== "") {
      //   this.newSupplierPayload.contactMedium[1].characteristic.phoneNumber
      //   .country.name = val.characteristic.country
      //   this.newSupplierPayload.contactMedium[1].characteristic.phoneNumber
      //   .country.flags = ""
      //   this.newSupplierPayload.contactMedium[1].characteristic.phoneNumber
      //   .country.short = ""
      // }
      console.log(this.newSupplierPayload.contactMedium[3]);
    },
    validateAddress(val) {
      console.log(val);
      if (val.characteristic.address1 !== "") {
        return true;
      } else {
        return false;
      }
    },
    validatePhoneNumber(val) {
      return val.valid;
    },

    async profileUpload(event) {
      console.log(event);
      if (this.editOrganisation === false) {
        let selectedFiles = event.target.files[0];
        let formData = new FormData();

        // Append all selected files to FormData object
        formData.append("files", selectedFiles);
        let data = await UPCAxiosInstance.post(
          `/gcpMultiple/v2/upload/`,
          formData
        );
        console.log(data);
        let obj = {};
        obj.name = data.data[0].filename;
        obj.size = data.data[0].data.attachment.size;
        obj.url = data.data[0].url;
        obj.id = data.data[0].data._id;
        obj["@type"] = "profile";
        const currentTimestamp = new Date().toJSON();
        console.log(currentTimestamp);
        obj.lastUpdated = currentTimestamp;
        this.newSupplierPayload.attachment = [];
        this.newSupplierPayload.attachment.push(obj);
        console.log(this.newSupplierPayload);
      } else {
        this.newProfileAdded = true;
        this.newProfileData = event.target.files[0];
        this.newProfileformData = new FormData();
        this.newProfileformData.append("files", this.newProfileData);
      }
    },
    websiteRegex(val) {
      const websiteRegex =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

      if (val.length >= 1) {
        if (!websiteRegex.test(val)) {
          this.isUrlEmpty = true; // URL is invalid
        } else {
          this.isUrlEmpty = false;
        }
      }
    },
    validateEmail(val) {
      const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (val.length > 0) {
        if (!reg.test(val)) {
          return false; // URL is invalid
        } else {
          return true;
        }
      }
    },
    handleUserStatusChange(status) {
      console.log("🚀 ~ status:", status);
      this.editUser.status = status.value;
    },
    computedUserStatusOptions(status) {
      console.log(
        "🚀 ~ computedUserStatusOptions ~ this.$store.state.getters.statusList:",
        this.$store.getters.statusList
      );
      // return this.statusList? this.statusList.filter(sts => {
      //   if(['Active','Inactive'].includes(status) && ['Active','Inactive'].includes(sts.value)) {
      //     return true
      //   }
      // }):[]
      return [
        {
          name: "Draft",
          value: "Draft",
          color: "#2879b7",
        },
        {
          name: "Pending",
          value: "Pending",
          color: "#f8a523",
        },
        {
          name: "Active",
          value: "Active",
          color: "#007247",
        },
        {
          name: "Inactive",
          value: "Inactive",
          color: "#ff5050",
        },
      ].filter((sts) => {
        if (
          ["Active", "Inactive"].includes(status) &&
          ["Active", "Inactive"].includes(sts.name)
        ) {
          return true;
        }
      });
    },
    closeAddNewSupplierModal() {
      this.canAdd = false;
      this.addressKey += 1;
      this.editOrganisation = false;
      this.newProfileAdded = false;
      this.roleSelectKey += 1;
      this.fileuploadKey += 1;
      let x = {
        attachment: [],
        tradingName: "",
        customName: "",
        name: "",
        status: "Active",
        preferred: "",
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: this.$store.getters.defaultSetting?.country,
                  short: this.$store.getters.defaultSetting?.cca2,
                  flags: this.$store.getters.defaultSetting?.flags?.svg,
                },
                value: "",
                valid: true,
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              address1: "",
              default: true,
              "@type": "",
            },
          },
        ],

        description: "",
        tags: [],

        notes: [],
        role: "",
        type: "company",
        password: "",
        email: "",
        familyName: "",
        givenName: "",
        companyName: "",
        parentCompany: "",
        rootName: "",
        "@baseType": "organisation",
      };
      this.isUrlEmpty = false;
      this.isNameEmpty = false;
      this.isEmailEmpty = false;
      this.modalKey += 1;
      this.newSupplierPayload = JSON.parse(JSON.stringify(x));
      this.modal_active_user = false;
    },
    sanitizeDatabaseName(databaseName) {
      // Replace dots with an underscore or any other symbol you want
      const sanitized = databaseName?.replace(/\./g, "_");

      // Validate the constraints for MongoDB database names
      const validDatabaseName = sanitized?.replace(/[^a-zA-Z0-9_]/g, "");

      return validDatabaseName;
    },
    async loadTable() {
      let tempIndex = this.userFilterOptions.paginatedQuery.currentPage;
      const filterResultData = await MLTAxiosInstance.post(
        `util/filterData`,
        this.userFilterOptions
      );
      this.userFilterOptions.paginatedQuery.currentPage = tempIndex;
      this.datalength = filterResultData.data.total;
      this.tableData = filterResultData.data.data;
      this.totalPageNo = filterResultData.data.total;
      this.paginatedData;
      console.log(
        "🚀 ~ loadTable ~ this.latestPersistPage:",
        this.latestPersistPage
      );
    },
    async currentPage(val) {
      console.log("🚀 ~ currentPage ~ val:", val);
      this.userFilterOptions.paginatedQuery.currentPage = val;
      if (this.searchValue.trim()) {
        this.userFilterOptions.searchQuery = this.searchValue;
        this.userFilterOptions.type = "filter-search";
      }
      this.loadTable();
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    async changeRowsPerPage(count) {
      this.userFilterOptions.paginatedQuery.rowsPerPage = count;
      this.table_height = this.paginatedData.length * 40 + 43;
    },
    async closeCrudContext(i) {
      console.log("🚀 ~ closeCrudContext ~ i:", i);
    },
    async editSupplier() {
      const cleanedArray = this.tenantPreferredLanguagesList
if (!cleanedArray.find(obj => obj.code === "en" && obj.name === "English")) {
  cleanedArray.push({ code: "en", name: "English",checked:true,disabled:true });
}
this.choosenLanguages = cleanedArray;
this.newSupplierPayload.languageAbility = this.choosenLanguages;
      this.editOrganisation = true;
      if (this.newProfileAdded) {
        let data = await UPCAxiosInstance.post(
          `/gcpMultiple/v2/upload/`,
          this.newProfileformData
        );
        console.log(data);

        let obj = {};
        obj.name = data.data[0].filename;
        obj.size = data.data[0].data.attachment.size;
        obj.url = data.data[0].url;
        obj.id = data.data[0].data._id;
        obj["@type"] = "profile";
        // Get the current date and time in milliseconds
        const currentTimestamp = new Date().toJSON();

        // Format the current timestamp in the same format as '1705574420667'
        console.log(currentTimestamp);
        obj.lastUpdated = currentTimestamp;
        // Find the index of the 'profile' object
        let index = this.payload.attachment.findIndex(
          (obj) => obj["@type"] === "profile"
        );
        if (obj.name.length > 0) {
          this.profilePicName = obj.name;
          const url = await UPCAxiosInstance.get(
            `/gcpupload/files/${obj.name}`,
            {
              responseType: "arraybuffer",
            }
          );
          this.rawProfileUrlData = url.data;
          this.profilePicUrl = await this.bufferToDataURL(url.data);
        }
        // Replace the 'profile' object with the new object if found
        if (index !== -1) {
          this.payload.attachment[index] = obj;
        } else {
          this.payload.attachment.push(obj);
        }
      }
      if (this.deleteProfilePic === true && this.newProfileAdded === false) {
        await UPCAxiosInstance.delete(
          `/gcpupload/files/${this.profilePicName}`
        );
        let index = this.payload.attachment.findIndex(
          (obj) => obj["@type"] === "profile"
        );
        if (index !== -1) {
          this.payload.attachment.splice(index, 1);
          this.rawProfileUrlData = null;
          this.profilePicUrl = "";
          this.profilePicName = "";
        }
      }
      this.newProfileAdded = false;
      this.deleteProfilePic = false;
      this.newSupplierPayload.id = this.newSupplierPayload._id;
      delete this.newSupplierPayload._id;
     
      const addSupplier = await MLTAxiosInstance.patch(
        `/partymanagement/organisation?company=${this.newSupplierPayload.tradingName}`,
        this.newSupplierPayload
      );
 
      console.log(addSupplier, "test");
      if (addSupplier.data.tradingName) {
        this.notification = {
          type: "success",
          message: "All set! Your changes have been saved successfully!",
        };
        this.view = true;
      }
      
   this.closeAddNewSupplierModal();
this.refreshTable();
      this.profileComponentKey += 1;
    },
    crudContextMenu($event, i, index) {
      if ($event.target.className === "icon-more-vertical-filled") {
        if (this.computedTableAction(i)[0]?.label === "Mark as Active") {
          this.containerleft =
            document
              .querySelector("#table-context" + index)
              .parentElement.getBoundingClientRect().left - 185;
        } else if (
          this.computedTableAction(i)[1]?.label === "Mark as In-active"
        ) {
          this.containerleft =
            document
              .querySelector("#table-context" + index)
              .parentElement.getBoundingClientRect().left - 160;
        } else {
          this.containerleft =
            document
              .querySelector("#table-context" + index)
              .parentElement.getBoundingClientRect().left - 120;
        }
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });

        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },
    async deleteProfilePicture() {
      console.log(this.payload.attachment, this.profilePicName, "ppp");
      this.deleteProfilePic = true;
    },
    async closeCrudContextMenu($event, ele, i) {
      console.log("🚀 ~ closeCrudContextMenu ~ i:", i);
      console.log("🚀 ~ closeCrudContextMenu ~ ele:", ele);
      // //
      this.editUser = ele;
      this.listView = ele;
      this.editUser.role =
        ele.role && ele.role.length > 0 ? ele.role[0]?.name : "";
      if ($event.toLowerCase() === "edit") {
        this.editOrganisation = true;
        this.modal_active_user = true;
        this.newSupplierPayload = JSON.parse(JSON.stringify(ele));
        // this.tenantPreferredLanguagesList = this.defaultLanguage
        this.addressPreVal = this.newSupplierPayload.contactMedium[3];
        const profileName = this.newSupplierPayload.attachment.filter(
          (item) => {
            return item["@type"] !== undefined && item["@type"] === "profile"
              ? item
              : false;
          }
        );
        console.log(profileName[0]?.name);
        this.profilePicName = profileName[0]?.name;
        if (profileName[0]?.name.length > 0) {
          const url = await UPCAxiosInstance.get(
            `/gcpupload/files/${profileName[0]?.name}`,
            { responseType: "arraybuffer" }
          );
          this.rawProfileUrlData = url.data;
          this.profilePicUrl = await this.bufferToDataURL(url.data);
        }
        console.log(
          this.newSupplierPayload,
          "edit mode this.newSupplierPayload"
        );
        if(this.newSupplierPayload.languageAbility.length>0){
//         this.defaultLanguage= this.newSupplierPayload.languageAbility.reduce((acc, item) => {
//     acc[item.code] = item.name;
//     return acc;
// }, {});
this.tenantPreferredLanguagesList = JSON.parse(JSON.stringify(this.newSupplierPayload.languageAbility))
console.log( this.tenantPreferredLanguagesList,"this.newSupplierPayload",this.newSupplierPayload.languageAbility)
        }
      } else if ($event.toLowerCase() == "mark as in-active") {
        let i = {
          id: ele._id,
          tradingName: ele.tradingName,
          status: "Inactive",
        };
        let ii = {
          filterQuery: {
            company: { $exists: true, $eq: ele.tradingName },
          },
          updatePayload: {
            status: "Inactive",
          },
        };
        const addSupplier = await MLTAxiosInstance.patch(
          `/partymanagement/organisation/?company=${ele.name}`,
          i
        );

        await MLTAxiosInstance.patch(
          `/partymanagement/individual/batch?company=${ele.tradingName}`,
          ii
        );
        if (addSupplier.data.email) {
          this.alertmsg = "success";
          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };
        } else {
          this.alertmsg = "danger";
          this.notification = {
            type: "danger",
            message: `Data could not be inserted.`,
          };
        }
        this.loadTable();
      } else if ($event.toLowerCase() == "mark as active") {
        let i = {
          id: ele._id,
          status: "Active",
        };
        let ii = {
          filterQuery: {
            company: { $exists: true, $eq: ele.tradingName },
          },
          updatePayload: {
            status: "Pending",
          },
        };
        const addSupplier = await MLTAxiosInstance.patch(
          `/partymanagement/organisation/?company=${ele.name}`,
          i
        );
        await MLTAxiosInstance.patch(
          `/partymanagement/individual/batch?company=${ele.tradingName}`,
          ii
        );
        if (addSupplier.data.email) {
          this.alertmsg = "success";
          this.notification = {
            type: "success",
            message: `All set! Your changes have been saved successfully!`,
          };
        } else {
          this.alertmsg = "danger";
          this.notification = {
            type: "danger",
            message: `Data could not be inserted.`,
          };
        }
        this.loadTable();
      } else if ($event.toLowerCase() === "send invite") {
        console.log(i);
        this.inviteUser(ele);
      }
      // else if ($event.toLowerCase() == "delete") {
      //   this.delete_modal_active = true;
      //   this.deleteobj = ele;
      //   this.delete_name = ele.description;
      //   this.deleterowindex = i;
      //   // await axios.delete("http://localhost:5700/quotemanagement/" + ele._id, {
      //   //   headers: {
      //   //     Authorization: this.$cookies.get("token"),
      //   //   },
      //   // });
      // }
    },
    computedTableAction(data) {
      if (data.status && data.status.toLowerCase() == "active") {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            label: "Mark as In-active",
            icon: "icon-times-square-regular",
          },
        ];
      } else if (data.status && data.status.toLowerCase() == "pending") {
        return [
          {
            label: "Send invite",
            icon: "icon-send-right-regular",
          },
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
        ];
      } else if (data.status && data.status.toLowerCase() == "inactive") {
        return [
          {
            label: "Mark as Active",
            icon: "icon-check-square-regular",
          },
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }
      // return options.filter((option) => {
      //   if (
      //     data &&
      //     data.status &&
      //     data.status.toLowerCase() == "active" &&
      //     ["Mark as Active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else if (
      //     data &&
      //   //  data.status &&
      //     data.status.toLowerCase() == "inactive" &&
      //     ["Mark as In-active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else if (
      //     data &&
      //     data.status &&
      //     data.status.toLowerCase() == "pending" &&
      //     ["Mark as In-active", "Mark as Active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return new Intl.DateTimeFormat("en-US", options)
        .format(date)
        .replace(",", "");
    },
    async pathTo() {
      // if (val.label == "Settings") {
      this.$router.push(`/settings`);
      // }
    },
    async exitView() {
      //
      let update = {
        id: this.userId,
        impersonation: false,
        impersinater: "",
      };
      let tokens = {
        headers: {
          Authorization: "Bearer  " + this.token,
        },
      };
      // const result =
      await MLTAxiosInstance.patch(
        this.fet + `/partymanagement/individual`,
        update,
        tokens
      );

      let token = {
        headers: {
          Authorization: "Bearer  " + this.impersonitor,
        },
      };
      //
      MLTAxiosInstance.get(
        this.fet + `/partymanagement/individual/auth`,
        token
      ).then((val) => {
        let company = val.data.company;
        if (company != "") {
          let c_name = null;
          if (company == "Halleyx") {
            c_name = "";
          } else {
            c_name = company + ".";
          }
          // location.replace(window.location.protocol+"//"+`${c_name}`+window.location.hostname+':'+window.location.port +`/DMN/dashboard/${this.impersonitor}`)
          const add =
            window.location.protocol +
            "//" +
            window.location.hostname +
            ":" +
            window.location.port;
          const urlObject = new URL(add);
          const hostName = urlObject.hostname;
          // let domainName = hostName.replace(/^[^.]+\./g, '');
          window.location.href =
            window.location.protocol +
            "//" +
            `${c_name}` +
            hostName +
            ":" +
            window.location.port +
            `/DMN/dashboard/${this.impersonitor}`;
        }
      });
    },

    async menu($event, index) {
      const user = await this.tableData[index];
      if (user.status == "Active") {
        this.options = this.options1;
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 154;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
      } else if (user.status == "Activate") {
        this.options = this.options3;
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 122;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
      } else {
        this.options = this.options2;
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 122;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
      }
      if ($event.target.className === "icon-more-vertical-filled") {
        this.tableData.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
        this.tableData[index].context = true;
        //
      } else {
        this.tableData.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    },

    Contextmenu1(index) {
      if (event.target.className === "icon-more-vertical-regular") {
        this.tableData[index].context.show = true;
      } else {
        // this.clicked = false;
        this.tableData.forEach((e) => {
          if (e.context !== undefined) {
            e.context.show = false;
          }
        });
      }
      //
    },
    async usersSelected(val) {
      this.view = await val.name;
      if (this.view == "Active") {
        this.userFilterOptions.filterQuery.status = "Active";
      } else if (this.view == "Inactive") {
        this.userFilterOptions.filterQuery.status = "Inactive";
      } else if (this.view == "Pending") {
        this.userFilterOptions.filterQuery.status = "Pending";
      } else {
        this.userFilterOptions.filterQuery.status = undefined;
      }
      const filterResultData = await MLTAxiosInstance.post(
        `util/filterData`,
        this.userFilterOptions
      );
      this.tableData = filterResultData.data.data;
      this.totalPageNo = filterResultData.data.total;

      console.log("🚀 ~ filterResultData:", filterResultData);
    },
    applyColumns(val) {
      this.headvalue = [];
      val.forEach((e) => {
        if (e.checked === true) {
          this.headvalue.push(e);
        }
      });
    },
    arrange(val) {
      this.re_arrange = val;
    },
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    sorting_Data(data, data1) {
      if (data === "icon-filled-arrow-up sorting-icon") {
        const x = [...this.tableData].sort(function (a, b) {
          if (typeof a === "object") {
            if (typeof a[data1] === "string") {
              return String(b[data1])
                .toLowerCase()
                .localeCompare(String(a[data1]).toLowerCase());
            } else if (typeof a[data1] === "number") {
              return b[data1] - a[data1];
            }
          } else if (typeof a === "string") {
            return String(b)
              .toLowerCase()
              .localeCompare(String(a).toLowerCase());
          } else if (typeof b === "number") {
            return b - a;
          } else {
            return 0;
          }
          return 0;
        });
        this.tableData = x;
      } else if (data === "icon-filled-arrow-down sorting-icon") {
        const x = [...this.tableData].sort(function (a, b) {
          if (typeof a === "object") {
            if (typeof a[data1] === "string") {
              return String(a[data1])
                .toLowerCase()
                .localeCompare(String(b[data1]).toLowerCase());
            } else if (typeof a[data1] === "number") {
              return a[data1] - b[data1];
            }
          } else if (typeof a === "string") {
            return String(a)
              .toLowerCase()
              .localeCompare(String(b).toLowerCase());
          } else if (typeof a === "number") {
            return a - b;
          } else {
            return 0;
          }
          return 0;
        });
        this.tableData = x;
      } else if (data === "icon-filled-arrow-up") {
        this.tableData = this.sortData;
      } else if (data === "icon-filled-arrow-down") {
        this.tableData = this.sortData;
      }
    },
    Contextmenu(index) {
      if (event.target.className == "icon-more-vertical-regular") {
        this.tableData[index].context.show = true;
      } else {
        // this.clicked = false;
        this.tableData.forEach((e) => {
          if (e.context != undefined) {
            e.context.show = false;
          }
        });
      }
      //
    },
    closecontextmenu(data1, data2) {
      const indexvalue = parseInt(data2);

      if (data1 == "Delete") {
        this.deleteUser(indexvalue);
      } else if (data1 == "Deactivate") {
        this.deactivateUser(indexvalue);
      } else if (data1 == "Impersonite") {
        this.impersinateUser(indexvalue);
      } else if (data1 == "Edit") {
        this.editList(indexvalue);
      } else if (data1 == "Activate") {
        this.activateUser(indexvalue);
      } else if (data1 == "Send activation email") {
        this.sendActivation(indexvalue);
      } else if (data1 == "Activity log") {
        this.activityLog();
      } else {
        this.resetPassword(indexvalue);
      }
    },
    async activityLog() {
      this.$router.push("/settings/activityLog");
    },
    addNewUser() {
     this.addressPreVal={
        mediumType: "",
        characteristic: {
          city: "",
          country: "",
          postCode: "",
          stateOrProvince: "",
          street1: "",
          street2: "",
          description: "",
          address1: "",
          default: false,
          "@type": "",
          preferred: false,
          postalCode: "",
          unit: "",
          state: "",
        },
      },
      this.tenantPreferredLanguagesList=[]
      this.modal_active_user = true;
      this.addressKey+=1
      this.tagcomponetkey += 1;
      // this.defaultLanguage = this.$store.getters.defaultSetting?.languages
//       if(this.defaultLanguage){
//           this.defaultLanguage =  Object.fromEntries(
//   Object.entries(this.defaultLanguage).filter(([key, value]) => value !== "English" || key !== 'eng')
// );
//           }
    },
    async sendActivation(indexValue) {
      const user = this.tableData[indexValue];

      let mail = {
        to: user.email,
        company: user.company,
        subject: "Halleyx-Confirm the account",
        template: `
            <!-- 
   -->
  
  <!DOCTYPE html
      PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
      xmlns:o="urn:schemas-microsoft-com:office:office">
  
  <head>
      <!--[if gte mso 9]>  <xml>   <o:OfficeDocumentSettings>   <o:AllowPNG/>   <o:PixelsPerInch>96</o:PixelsPerInch>   </o:OfficeDocumentSettings>  </xml>  <![endif]-->
      <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="format-detection" content="date=no" />
      <meta name="format-detection" content="address=no" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="x-apple-disable-message-reformatting" />
      <!--[if gte mso 9]>  <style type="text/css" media="all">  sup { font-size: 100% !important; }   table, th, td, div, p, strong, span { font-family: Arial,sans-serif !important; } .intro-title-3, .hero-title-2, .hero-title-3 {font-weight:bold;} </style>  <![endif]-->
      <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900" rel="stylesheet">
      <link href='https://fonts.googleapis.com/css?family=Open Sans' rel='stylesheet'>
      <link href='https://fonts.googleapis.com/css?family=Quicksand&display=swap' rel='stylesheet'>
      <style type="text/css" media="screen">
          @font-face {
              font-family: 'Proxima Nova Regular';
              src: url('https://marketing.fitbit.com/fonts/proxima-nova-regular-852046040da1526ac5ac9cdbee2e3723.woff') format('woff');
              font-weight: normal;
              font-style: normal;
          }
  
          @font-face {
              font-family: 'Proxima Nova Semibold';
              src: url('https://marketing.fitbit.com/fonts/proxima-nova-semibold-3163eca2ebe9b39d7aaf4ba594ce39d7.woff') format('woff');
              font-weight: normal;
              font-style: normal;
          }
  
          @font-face {
              font-family: 'Proxima Nova Bold';
              src: url('https://marketing.fitbit.com/fonts/proxima-nova-bold-f73f22aa93a861006ae7f54f9968fec0.woff') format('woff');
              font-weight: bold;
              font-style: normal;
          }
  
          @font-face {
              font-family: 'Proxima Nova Black';
              src: url('https://marketing.fitbit.com/fonts/proxima-nova-black-b68819090cb3f466a9dd7d473d9e12a8.woff') format('woff');
              font-style: normal;
          }
  
          @font-face {
              font-family: 'Proxima Nova Extrabold';
              src: url('https://marketing.fitbit.com/fonts/proxima-nova-xbold-5d8ad01e573a280ed04306c1bfe5ef83.woff') format('woff');
              font-style: normal;
          }
  
          @font-face {
              font-family: 'Proxima Nova Light';
              font-style: normal;
              font-weight: normal;
              src: url("https://marketing.fitbit.com/fonts/proxima-nova-light-25b45c331498ff377abe22554f05943d.eot") format("embedded-opentype"), url("https://marketing.fitbit.com/fonts/proxima-nova-light-b08aa39dfd53fb455693786505ed211e.woff") format("woff"), url("https://marketing.fitbit.com/fonts/proxima-nova-light-982154eacb03c17d9cf072a97208af46.ttf") format("truetype"), url("https://marketing.fitbit.com/fonts/proxima-nova-light-918e11e776f56b8c6097ae4af49461e6.svg") format("svg");
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
          }
  
          /* Linked Styles */
          body {
              padding: 0 !important;
              margin: 0 !important;
              display: block !important;
              min-width: 100% !important;
              width: 100% !important;
              background: #f5f5f5;
              -webkit-text-size-adjust: none
          }
  
          a {
              color: #002a3a;
              text-decoration: none
          }
  
          p {
              padding: 0;
              margin: 0
          }
  
          img {
              -ms-interpolation-mode: bicubic;
              /* Allow smoother rendering of resized image in Internet Explorer */
          }
  
          .mcnPreviewText {
              display: none !important;
          }
  
          .header-text a {
              color: #939c9e;
              text-decoration: underline
          }
  
  
  
  
          .btn-1 a,
          .intro-title-1 a,
          .intro-title-3 a,
          .intro-title-4 a {
              color: #ffffff;
              text-decoration: none
          }
  
          /* Mobile styles */
          @media only screen and (max-device-width: 414px),
          only screen and (max-width: 500px) {
              .mobile-shell {
                  width: 414px !important;
                  min-width: 414px !important;
              }
  
              .m-center {
                  text-align: center !important;
              }
  
              .center {
                  margin: 0 auto !important;
              }
  
              .td {
                  width: 100% !important;
                  min-width: 100% !important;
              }
  
              .m-br-15 {
                  height: 15px !important;
              }
  
              .m-td,
              .hidden,
              .m-hide {
                  display: none !important;
                  width: 0 !important;
                  height: 0 !important;
                  font-size: 0 !important;
                  line-height: 0 !important;
                  min-height: 0 !important;
              }
  
              .m-block {
                  display: block !important;
              }
  
              .hauto {
                  height: auto !important;
              }
  
              .mw-30 img {
                  width: 30px !important;
                  height: auto !important;
              }
  
              .mw-200 img {
                  width: 100% !important;
                  height: auto !important;
              }
  
              .fluid-img img {
                  width: 100% !important;
                  max-width: 100% !important;
                  height: auto !important;
              }
  
              .column {
                  float: left !important;
                  width: 100% !important;
                  display: block !important;
              }
  
              .content-spacing {
                  width: 15px !important;
              }
  
              /* Padding */
              .p-0 {
                  padding: 0 !important;
              }
  
              .p-15 {
                  padding: 15px !important;
              }
  
              .pb-5 {
                  padding-bottom: 5px !important;
              }
  
              .pb-10 {
                  padding-bottom: 10px !important;
              }
  
              .pb-15 {
                  padding-bottom: 15px !important;
              }
  
              .pb-20 {
                  padding-bottom: 20px !important;
              }
  
              .pb-25 {
                  padding-bottom: 25px !important;
              }
  
              .pb-30 {
                  padding-bottom: 30px !important;
              }
  
              .p0-10 {
                  padding: 0 10px !important;
              }
  
              .p0-15 {
                  padding: 0 15px !important;
              }
  
              .p20-15 {
                  padding: 20px 15px !important;
              }
  
              .p25-15 {
                  padding: 25px 15px !important;
              }
  
              .p30-15 {
                  padding: 30px 15px !important;
              }
  
              /*   .p35-15 { padding: 35px 15px !important; }*/
  
              .p0-15-15 {
                  padding: 0 15px 0px !important;
              }
  
              .p0-15-20 {
                  padding: 0 15px 20px !important;
              }
  
              .p0-15-30 {
                  padding: 0 15px 30px !important;
              }
  
              .p25-0-40 {
                  padding: 15px 0 30px !important;
              }
  
              /* Intro */
              .headline {
                  font-size: 32px !important;
                  line-height: 39px !important;
                  padding: 50px 51px 15px 51px !important;
              }
  
              .hero-title-1 {
                  font-size: 18px !important;
                  line-height: 22px !important;
                  padding: 0px 51px 30px 51px !important;
              }
  
              .hero-title-2 {
                  font-size: 36px !important;
                  line-height: 42px !important;
                  padding: 0px 25px 0px 25px !important;
              }
  
              .hero-title-3 {
  
                  font-size: 34px !important;
                  line-height: 27px !important;
              }
  
              .intro-title-1 {
                  font-size: 32px !important;
                  line-height: 34px !important;
                  padding: 46px 53px 15px 53px !important;
                  letter-spacing: 0.32px !important;
              }
  
              .intro-title-2 {
                  font-size: 18px !important;
                  line-height: 22px !important;
                  padding: 0px 53px 0px 53px !important;
              }
  
              .intro-title-3 {
                  font-size: 20px !important;
                  line-height: 24px !important;
                  padding-bottom: 30px !important;
              }
  
              .intro-title-4 {
                  font-size: 24px !important;
                  line-height: 29px !important;
                  padding: 50px 53px 15px 53px !important;
                  letter-spacing: 1.2px !important;
              }
  
              .disclaimer {
                  font-size: 18px !important;
                  line-height: 22px !important;
                  padding: 15px 53px 15px !important;
              }
  
              .disclaimer2 {
                  font-size: 18px !important;
                  line-height: 22px !important;
                  padding: 40px 51px 40px !important;
              }
  
              .intro-title-5 {
                  font-size: 20px !important;
                  line-height: 24px !important;
                  padding: 0px 53px 40px 53px !important;
              }
  
              .intro-title-6 {
                  font-size: 14px !important;
                  line-height: 15px !important;
                  padding: 7px 41px !important;
              }
  
              /*Standard*/
              .no-mobile {
                  text-indent: -99999em;
                  display: none !important;
              }
  
              .mobile-only {
                  text-indent: 0 !important;
                  display: block !important;
                  /*width: 480px !important;*/
                  width: 100% !important;
                  height: auto !important;
                  visibility: visible !important;
                  overflow: visible !important;
                  max-height: none !important;
              }
  
              .mobile-headline {
                  padding: 45px 50px 20px 50px !important;
                  font-size: 24px !important;
                  line-height: 29px !important;
              }
  
              /* Titles */
              .title-1 {
                  font-size: 15px !important;
                  line-height: 19px !important;
              }
  
              /* Button */
              .btn-1 {
                  padding: 0 !important;
              }
  
              .btn-1 a {
                  display: inline-block;
                  padding: 6px 22px !important;
              }
  
              /* Table */
              .table-text-1 {
                  font-size: 14px !important;
                  line-height: 18px !important;
              }
  
              .hide-for-desktop {
                  display: block !important;
                  max-width: 100% !important;
                  max-height: 100% !important;
                  visibility: visible !important;
                  opacity: 1 !important;
                  width: auto !important;
                  height: auto !important;
              }
  
              .hero-mobile {
                  display: block !important;
              }
  
              .table-title-c {
                  padding: 24px !important;
              }
  
          }
      </style>
  </head>
  
  <body class="body"
      style="padding:0 !important; margin:0 !important; display:block !important; min-width:100% !important; width:100% !important; background:#f5f5f5; -webkit-text-size-adjust:none;">
      <!-- MOBILE PREHEADER PUSH ANY CONTENT AFTER THE PREHEADER FROM SHOWING UP INSERT UNDER BODY TAG -->
  
  
  
      
      
      <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#f5f5f5">
          <tr>
              <td align="center" valign="top">
                  <table width="600" border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
                      <tr>
                          <td class="td" style="width:600px; min-width:600px; padding-top:12px; margin:0; font-weight:normal;">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                  <tr>
                                      <td>
                                          <!-- Header -->
                                          <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                              bgcolor="#ffffff">
                                              <tr>
                                                  <td>
                                                      <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                          class="wrapper">
                                                          <tr>
                                                              <td>
                                                                  <table border="0" cellpadding="10" cellspacing="0"
                                                                      width="100%" class="wrapper" bgcolor="#f5f5f5">
  
                                                                  </table>
                                                              </td>
                                                          </tr>
                                                          <tr>
                                                              <td>
                                                                  <table border="0" cellpadding="0" cellspacing="0"
                                                                      style="border:none;border-collapse:collapse;"
                                                                      width="100%" class="wrapper" bgcolor="#ffffff">
                                                                      <tr>
                                                                          <td width="104"><a href="https://halleyx.com"><img src="https://halleyx.com/img/logo-dark.9e286c66.png" height="50" style="display:block; border:none;padding-top: 20px;" alt="logo"></a></td>
                                                                          
  
  
  
                                                                      </tr>
                                                                  </table>
                                                              </td>
                                                          </tr>
                                                      </table>
                                                  </td>
                                              </tr>
                                          </table>
                                          <!-- END Header -->
                                          <!-- Start Content -->
                                          <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0"
                                              cellpadding="0">
                                              <tr>
                                                  <td bgcolor="#FFFFFF" class="p35-15">
                                                      <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0"
                                                          cellpadding="0">
  
                                                          <!-- headline -->
                                                          <tr>
                                                              <td bgcolor="#FFFFFF" class="headline"
                                                                  style="padding-left: 30px; color:black; font-family: Quicksand; font-size:18px; line-height:56px; font-weight: Bold; text-align:left; letter-spacing: .01em">
                                                                  Confirm your email address</td>
                                                          </tr>
                                                          <!-- #headline -->
  
                                                          <!--  hero-title-1 -->
                                                          <tr>
                                                              <td bgcolor="#FFFFFF" class="hero-title-1"
                                                                  style="padding-left: 30px;color:black;  font-family: Open Sans; font-size:14px; line-height:20px; font-weight: normal; text-align:left; letter-spacing: .01em">
                                                                  Hi ${user.name}👋</td>
                                                                 
                                                          </tr>
                                                          <tr>
                                                              <td bgcolor="#FFFFFF" class="hero-title-1"
                                                                  style="padding-left: 30px;padding-top:15px;color:black;  font-family: Open Sans; font-size:14px; line-height:20px; font-weight: normal; text-align:left; letter-spacing: .01em">
                                                                  You are almost there! </td>
                                                                 
                                                          </tr>
                                                          <tr>
                                                              <td bgcolor="#FFFFFF" class="hero-title-1"
                                                              style="padding-left: 30px;padding-top:15px;color:black;  font-family: Open Sans; font-size:14px; line-height:20px; font-weight: normal; text-align:left; letter-spacing: .01em">
                                                              Click the link to verify your
                                                              email address. </td>
                                                          </tr>
                                                          <tr>
                                                              <td bgcolor="#FFFFFF" class="hero-title-1"
                                                              style="padding-left: 30px;padding-top:15px;color:black;  font-family: Open Sans; font-size:14px; line-height:20px; font-weight: normal; text-align:left; letter-spacing: .01em">
                                                            We are so excited for you!🎉 </td>
                                                          </tr>
                                                          <!-- #hero-title-1 -->
  
                                                          <!-- Button -->
                                                          <tr>
                                                              <td bgcolor="#FFFFFF" align="left"
                                                                  class="fallback-font btn-pb"
                                                                  style="padding-left: 30px;padding-top:40px;padding-bottom: 45px">
                                                                  <div class="btn" style="text-align:left;">
                                                                      <!--[if mso]>                                                                     <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://www.fitbit.com/campaign/shop/versa?color=black&utm_source=ET&utm_medium=EM&utm_campaign=20191117_PreBFV2_US_FM_M_SU_SW_32_NA&utm_content=HeroCTALink" style="font-family: Arial, Helvetica, sans-serif;height:36px;v-text-anchor:middle;width:150px;" arcsize="152%" stroke="f" fillcolor="#002A3A">                                                                     <w:anchorlock/>                                                                     <center>                                                                     <![endif]-->
                                                                      <a href="http://window.location.hostname/accountView/${user._id}"
                                                                          class="mob-btn" target="_blank"
                                                                          style="background-color:#54BD95;border-radius:6px;color:#ffffff;font-family:Open Sans;font-size:14px; text-align:center;text-decoration:none;width: 218.18px;
                                                                          height: 38px;-webkit-text-size-adjust:none; padding: 10px 12px; line-height: 15px;">Confirm your account
                                                                          </a>
                                                                      <!--[if mso]>                                                                     </center>                                                                     </v:roundrect>                                                                     <![endif]-->
                                                                  </div>
                                                              </td>
                                                          </tr>
                                                  </td>
                                              </tr>
                                              <!-- END HERO -->
                                          </table>
                                          <!-- HERO Images-->
                                         
                                      </td>
                                  </tr>
                              </table>
                              <!-- END Content -->
                              <!--Features-->
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                  <tr>
                                      <td>
                                          <!--Start Content-->
                                          <!--Desktop RTBs-->
                                         
                                          <!--End Desktop RTBs-->
                                          <!--Mobile RTBs-->
                                          <!--[if !mso]><!-->
                                         
                                          <!--End Mobile RTBs-->
                                          <!--<![endif]-->
                                          <!--END Features-->
                                          <!-- Footer -->
                                          <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                              bgcolor="#ffffff">
                                              <tr>
                                                  
                                                  <td bgcolor="#161C2B" class="disclaimer2"
                                                      style="padding: 40px 77px 40px 77px;color:#FFFFFF; font-family: Open Sans; font-size:14px; line-height:20px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                      <span><a href="https://halleyx.com" style="color: #FFFFFF;font-size: 14px;"><u>T&C</u><u style="padding-left:40px;padding-right: 40px;">Privacy</u><u>Cookies</u></a></span><br/>
                                                      <br/>
                                                      © 2023 Halleyx Inc. Copyright and rights reserved                                                    <p
                                                          style="padding-left: 17px !important; font-family:Open Sans; font-size: 14px; color: #FFFFFF;margin-top: 15px;  margin-bottom: 0px; letter-spacing: .01em;line-height: 20px;">
                                                          18 King St E #1400, Toronto, ON M5C 1C4
  
                                                      </p>
                                                      <span style="float:left;margin-left: -60px;padding-top:20px;"><img width="100" src="https://halleyx.com/img/logo-light.28f29458.png"/></span>
                                                      <span style="float: right;margin-right:-50px;padding-top:20px;color: #FFFFFF;"><a href="https://www.linkedin.com/company/halleyx/"><img src="https://halleyx.com/img/linkedIn.svg"/></a>
                                                          <a href="https://halleyx.com/" style="padding-left:3px;padding-right: 3px;"><img src="https://halleyx.com//img/facebook.svg" /></a>
                                                          <a href="https://www.instagram.com/halleyx_inc/"><img src="https://halleyx.com/img/insta.svg"/></a></span>
                                                  </td>
                                                 
                                              </tr>
                                          </table>
                                          <!-- Footer -->
                                          <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                              bgcolor="#ffffff">
                                              <tr>
                                                  <td>
                                                      <style>
                                                          .nowrap {
                                                              word-break: keep-all;
                                                              -moz-hyphens: none;
                                                              -ms-hyphens: none;
                                                              -webkit-hyphens: none;
                                                              hyphens: none;
                                                          }
  
                                                          a[x-apple-data-detectors] {
                                                              color: inherit !important;
                                                              text-decoration: none !important;
                                                              font-size: inherit !important;
                                                              font-family: inherit !important;
                                                              font-weight: inherit !important;
                                                              line-height: inherit !important;
                                                          }
                                                      </style>
  
                                                      <table border="0" cellpadding="0" cellspacing="0"
                                                          style="border:none;border-collapse:collapse; background-color:#ffffff;"
                                                          width="100%" class="wrapper mobileContainer">
  
                                                          <tr>
                                                              <td class="mobileContainer" align="center"
                                                                  style="font-family:Helvetica, Arial, sans-serif; color:#939c9e; font-size:0px; padding-top:24px; font-weight:100; background-color:#ffffff; width:100%; ">
                                                              </td>
                                                          </tr>
  
  
  
  
                                                      </table>
                                                  </td>
                                              </tr>
                                          </table>
                                          <!-- END Footer -->
                                      </td>
                                  </tr>
                              </table>
                          </td>
                      </tr>
                  </table>
              </td>
          </tr>
      </table>
  
  </body>
  
  </html>
    `,
      };
      // const sendEmail =
      await axios.post(
        `https://appbackend-ghubgwxrcq-uc.a.run.app/email`,
        mail
      );
    },
    async resetPassword(indexValue) {
      const user = this.tableData[indexValue];
      this.confirmUser = user;
      this.resetModal = true;
    },
    async reset(val) {
      let mail = {
        to: val.email,
        subject: "Halleyx forget password",
        template: `<!-- 
   -->
  
  <!DOCTYPE html
      PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
      xmlns:o="urn:schemas-microsoft-com:office:office">
  
  <head>
      <!--[if gte mso 9]>  <xml>   <o:OfficeDocumentSettings>   <o:AllowPNG/>   <o:PixelsPerInch>96</o:PixelsPerInch>   </o:OfficeDocumentSettings>  </xml>  <![endif]-->
      <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="format-detection" content="date=no" />
      <meta name="format-detection" content="address=no" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="x-apple-disable-message-reformatting" />
      <!--[if gte mso 9]>  <style type="text/css" media="all">  sup { font-size: 100% !important; }   table, th, td, div, p, strong, span { font-family: Arial,sans-serif !important; } .intro-title-3, .hero-title-2, .hero-title-3 {font-weight:bold;} </style>  <![endif]-->
      <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900" rel="stylesheet">
      <link href='https://fonts.googleapis.com/css?family=Open Sans' rel='stylesheet'>
      <link href='https://fonts.googleapis.com/css?family=Quicksand&display=swap' rel='stylesheet'>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
      <style type="text/css" media="screen">
          @font-face {
              font-family: 'Proxima Nova Regular';
              src: url('https://marketing.fitbit.com/fonts/proxima-nova-regular-852046040da1526ac5ac9cdbee2e3723.woff') format('woff');
              font-weight: normal;
              font-style: normal;
          }
  
          @font-face {
              font-family: 'Proxima Nova Semibold';
              src: url('https://marketing.fitbit.com/fonts/proxima-nova-semibold-3163eca2ebe9b39d7aaf4ba594ce39d7.woff') format('woff');
              font-weight: normal;
              font-style: normal;
          }
  
          @font-face {
              font-family: 'Proxima Nova Bold';
              src: url('https://marketing.fitbit.com/fonts/proxima-nova-bold-f73f22aa93a861006ae7f54f9968fec0.woff') format('woff');
              font-weight: bold;
              font-style: normal;
          }
  
          @font-face {
              font-family: 'Proxima Nova Black';
              src: url('https://marketing.fitbit.com/fonts/proxima-nova-black-b68819090cb3f466a9dd7d473d9e12a8.woff') format('woff');
              font-style: normal;
          }
  
          @font-face {
              font-family: 'Proxima Nova Extrabold';
              src: url('https://marketing.fitbit.com/fonts/proxima-nova-xbold-5d8ad01e573a280ed04306c1bfe5ef83.woff') format('woff');
              font-style: normal;
          }
  
          @font-face {
              font-family: 'Proxima Nova Light';
              font-style: normal;
              font-weight: normal;
              src: url("https://marketing.fitbit.com/fonts/proxima-nova-light-25b45c331498ff377abe22554f05943d.eot") format("embedded-opentype"), url("https://marketing.fitbit.com/fonts/proxima-nova-light-b08aa39dfd53fb455693786505ed211e.woff") format("woff"), url("https://marketing.fitbit.com/fonts/proxima-nova-light-982154eacb03c17d9cf072a97208af46.ttf") format("truetype"), url("https://marketing.fitbit.com/fonts/proxima-nova-light-918e11e776f56b8c6097ae4af49461e6.svg") format("svg");
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
          }
  
          /* Linked Styles */
          body {
              padding: 0 !important;
              margin: 0 !important;
              display: block !important;
              min-width: 100% !important;
              width: 100% !important;
              background: #f5f5f5;
              -webkit-text-size-adjust: none
          }
  
          a {
              color: #002a3a;
              text-decoration: none
          }
  
          p {
              padding: 0;
              margin: 0
          }
  
          img {
              -ms-interpolation-mode: bicubic;
              /* Allow smoother rendering of resized image in Internet Explorer */
          }
  
          .mcnPreviewText {
              display: none !important;
          }
  
          .header-text a {
              color: #939c9e;
              text-decoration: underline
          }
  
  
  
  
          .btn-1 a,
          .intro-title-1 a,
          .intro-title-3 a,
          .intro-title-4 a {
              color: #ffffff;
              text-decoration: none
          }
  
          /* Mobile styles */
          @media only screen and (max-device-width: 414px),
          only screen and (max-width: 500px) {
              .mobile-shell {
                  width: 414px !important;
                  min-width: 414px !important;
              }
  
              .m-center {
                  text-align: center !important;
              }
  
              .center {
                  margin: 0 auto !important;
              }
  
              .td {
                  width: 100% !important;
                  min-width: 100% !important;
              }
  
              .m-br-15 {
                  height: 15px !important;
              }
  
              .m-td,
              .hidden,
              .m-hide {
                  display: none !important;
                  width: 0 !important;
                  height: 0 !important;
                  font-size: 0 !important;
                  line-height: 0 !important;
                  min-height: 0 !important;
              }
  
              .m-block {
                  display: block !important;
              }
  
              .hauto {
                  height: auto !important;
              }
  
              .mw-30 img {
                  width: 30px !important;
                  height: auto !important;
              }
  
              .mw-200 img {
                  width: 100% !important;
                  height: auto !important;
              }
  
              .fluid-img img {
                  width: 100% !important;
                  max-width: 100% !important;
                  height: auto !important;
              }
  
              .column {
                  float: left !important;
                  width: 100% !important;
                  display: block !important;
              }
  
              .content-spacing {
                  width: 15px !important;
              }
  
              /* Padding */
              .p-0 {
                  padding: 0 !important;
              }
  
              .p-15 {
                  padding: 15px !important;
              }
  
              .pb-5 {
                  padding-bottom: 5px !important;
              }
  
              .pb-10 {
                  padding-bottom: 10px !important;
              }
  
              .pb-15 {
                  padding-bottom: 15px !important;
              }
  
              .pb-20 {
                  padding-bottom: 20px !important;
              }
  
              .pb-25 {
                  padding-bottom: 25px !important;
              }
  
              .pb-30 {
                  padding-bottom: 30px !important;
              }
  
              .p0-10 {
                  padding: 0 10px !important;
              }
  
              .p0-15 {
                  padding: 0 15px !important;
              }
  
              .p20-15 {
                  padding: 20px 15px !important;
              }
  
              .p25-15 {
                  padding: 25px 15px !important;
              }
  
              .p30-15 {
                  padding: 30px 15px !important;
              }
  
              /*   .p35-15 { padding: 35px 15px !important; }*/
  
              .p0-15-15 {
                  padding: 0 15px 0px !important;
              }
  
              .p0-15-20 {
                  padding: 0 15px 20px !important;
              }
  
              .p0-15-30 {
                  padding: 0 15px 30px !important;
              }
  
              .p25-0-40 {
                  padding: 15px 0 30px !important;
              }
  
              /* Intro */
              .headline {
                  font-size: 32px !important;
                  line-height: 39px !important;
                  padding: 50px 51px 15px 51px !important;
              }
  
              .hero-title-1 {
                  font-size: 18px !important;
                  line-height: 22px !important;
                  padding: 0px 51px 30px 51px !important;
              }
  
              .hero-title-2 {
                  font-size: 36px !important;
                  line-height: 42px !important;
                  padding: 0px 25px 0px 25px !important;
              }
  
              .hero-title-3 {
  
                  font-size: 34px !important;
                  line-height: 27px !important;
              }
  
              .intro-title-1 {
                  font-size: 32px !important;
                  line-height: 34px !important;
                  padding: 46px 53px 15px 53px !important;
                  letter-spacing: 0.32px !important;
              }
  
              .intro-title-2 {
                  font-size: 18px !important;
                  line-height: 22px !important;
                  padding: 0px 53px 0px 53px !important;
              }
  
              .intro-title-3 {
                  font-size: 20px !important;
                  line-height: 24px !important;
                  padding-bottom: 30px !important;
              }
  
              .intro-title-4 {
                  font-size: 24px !important;
                  line-height: 29px !important;
                  padding: 50px 53px 15px 53px !important;
                  letter-spacing: 1.2px !important;
              }
  
              .disclaimer {
                  font-size: 18px !important;
                  line-height: 22px !important;
                  padding: 15px 53px 15px !important;
              }
  
              .disclaimer2 {
                  font-size: 18px !important;
                  line-height: 22px !important;
                  padding: 40px 51px 40px !important;
              }
  
              .intro-title-5 {
                  font-size: 20px !important;
                  line-height: 24px !important;
                  padding: 0px 53px 40px 53px !important;
              }
  
              .intro-title-6 {
                  font-size: 14px !important;
                  line-height: 15px !important;
                  padding: 7px 41px !important;
              }
  
              /*Standard*/
              .no-mobile {
                  text-indent: -99999em;
                  display: none !important;
              }
  
              .mobile-only {
                  text-indent: 0 !important;
                  display: block !important;
                  /*width: 480px !important;*/
                  width: 100% !important;
                  height: auto !important;
                  visibility: visible !important;
                  overflow: visible !important;
                  max-height: none !important;
              }
  
              .mobile-headline {
                  padding: 45px 50px 20px 50px !important;
                  font-size: 24px !important;
                  line-height: 29px !important;
              }
  
              /* Titles */
              .title-1 {
                  font-size: 15px !important;
                  line-height: 19px !important;
              }
  
              /* Button */
              .btn-1 {
                  padding: 0 !important;
              }
  
              .btn-1 a {
                  display: inline-block;
                  padding: 6px 22px !important;
              }
  
              /* Table */
              .table-text-1 {
                  font-size: 14px !important;
                  line-height: 18px !important;
              }
  
              .hide-for-desktop {
                  display: block !important;
                  max-width: 100% !important;
                  max-height: 100% !important;
                  visibility: visible !important;
                  opacity: 1 !important;
                  width: auto !important;
                  height: auto !important;
              }
  
              .hero-mobile {
                  display: block !important;
              }
  
              .table-title-c {
                  padding: 24px !important;
              }
  
          }
      </style>
  </head>
  
  <body class="body"
      style="padding:0 !important; margin:0 !important; display:block !important; min-width:100% !important; width:100% !important; background:#f5f5f5; -webkit-text-size-adjust:none;">
      <!-- MOBILE PREHEADER PUSH ANY CONTENT AFTER THE PREHEADER FROM SHOWING UP INSERT UNDER BODY TAG -->
  
  
  
  
  
      <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#f5f5f5">
          <tr>
              <td align="center" valign="top">
                  <table width="600" border="0" cellspacing="0" cellpadding="0" class="mobile-shell">
                      <tr>
                          <td class="td"
                              style="width:600px; min-width:600px; padding-top:12px; margin:0; font-weight:normal;">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                  <tr>
                                      <td>
                                          <!-- Header -->
                                          <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                              bgcolor="#ffffff">
                                              <tr>
                                                  <td>
                                                      <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                                          class="wrapper">
                                                          <tr>
                                                              <td>
                                                                  <table border="0" cellpadding="10" cellspacing="0"
                                                                      width="100%" class="wrapper" bgcolor="#f5f5f5">
  
                                                                  </table>
                                                              </td>
                                                          </tr>
                                                          <tr>
                                                              <td>
                                                                  <table border="0" cellpadding="0" cellspacing="0"
                                                                      style="border:none;border-collapse:collapse;"
                                                                      width="100%" class="wrapper" bgcolor="#ffffff">
                                                                      <tr>
                                                                          <td height="22" width="104"><a
                                                                                  href="https://halleyx.com"><img
                                                                                      src="https://halleyx.com/img/halleyx-logo-line-black.42985758.svg"
                                                                                      height="35"
                                                                                      style="display:block; border:none;padding-top: 20px; padding-left: 30px;"
                                                                                      alt="logo"></a></td>
  
  
  
  
                                                                      </tr>
                                                                  </table>
                                                              </td>
                                                          </tr>
                                                      </table>
                                                  </td>
                                              </tr>
                                          </table>
                                          <!-- END Header -->
                                          <!-- Start Content -->
                                          <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0"
                                              cellpadding="0">
                                              <tr>
                                                  <td bgcolor="#FFFFFF" class="p35-15">
                                                      <table bgcolor="#FFFFFF" width="100%" border="0" cellspacing="0"
                                                          cellpadding="0">
  
                                                          <!-- headline -->
                                                          <tr>
                                                              <td bgcolor="#FFFFFF" class="headline"
                                                                  style="padding-left: 30px; color:#191A15; font-family: Quicksand; font-size:22px; line-height:56px; font-weight: Bold; text-align:left; letter-spacing: .01em">
                                                                  Forgot your password ?</td>
                                                          </tr>
                                                          <!-- #headline -->
  
                                                          <!--  hero-title-1 -->
                                                          <tr>
                                                              <td bgcolor="#FFFFFF" class="hero-title-1"
                                                                  style="padding-left: 30px;color:#191A15;  font-family: 'Quicksand'; font-size:16px; line-height:20px; font-weight: normal; text-align:left; letter-spacing: .01em">
                                                                  That’s okay, it happens !</td>
  
                                                          </tr>
                                                          <tr>
                                                              <td bgcolor="#FFFFFF" class="hero-title-1"
                                                                  style="padding-left: 30px;padding-top:15px;color:#191A15;  font-family: 'Quicksand'; font-size:16px; line-height:20px; font-weight: normal; text-align:left; letter-spacing: .01em">
  
                                                                  Click on the button to reset your password </td>
  
                                                          </tr>
  
                                                          <!-- Button -->
                                                          <tr>
                                                              <td bgcolor="#FFFFFF" align="left"
                                                                  class="fallback-font btn-pb"
                                                                  style="padding-left: 30px;padding-top:40px;padding-bottom: 45px">
                                                                  <div class="btn" style="text-align:left;">
                                                                      <!--[if mso]>                                                                     <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="https://www.fitbit.com/campaign/shop/versa?color=black&utm_source=ET&utm_medium=EM&utm_campaign=20191117_PreBFV2_US_FM_M_SU_SW_32_NA&utm_content=HeroCTALink" style="font-family: Arial, Helvetica, sans-serif;height:36px;v-text-anchor:middle;width:150px;" arcsize="152%" stroke="f" fillcolor="#002A3A">                                                                     <w:anchorlock/>                                                                     <center>                                                                     <![endif]-->
                                                                      <a href="https://halleyx-dmnuat-ghubgwxrcq-uc.a.run.app/invite/${val.token}" class="mob-btn"
                                                                          target="_blank"
                                                                          style="background-color:#54BD95;border-radius:6px;color:#ffffff;font-family:Open Sans;font-size:14px; text-align:center;text-decoration:none;width: 218.18px;
                                                                          height: 38px;-webkit-text-size-adjust:none; padding: 10px 12px; line-height: 15px;">Reset
                                                                          password
                                                                      </a>
                                                                      <!--[if mso]>                                                                     </center>                                                                     </v:roundrect>                                                                     <![endif]-->
                                                                  </div>
                                                              </td>
                                                          </tr>
                                                  </td>
                                              </tr>
                                              <!-- END HERO -->
                                          </table>
                                          <!-- HERO Images-->
  
                                      </td>
                                  </tr>
                              </table>
                              <!-- END Content -->
                              <!--Features-->
                              <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                  <tr>
                                      <td>
                                          <!--Start Content-->
                                          <!--Desktop RTBs-->
  
                                          <!--End Desktop RTBs-->
                                          <!--Mobile RTBs-->
                                          <!--[if !mso]><!-->
  
                                          <!--End Mobile RTBs-->
                                          <!--<![endif]-->
                                          <!--END Features-->
                                          <!-- Footer -->
                                          <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                              bgcolor="#ffffff">
                                              <tr>
  
                                                  <td bgcolor="#161C2B" class="disclaimer2"
                                                      style="padding: 40px 77px 40px 77px;color:#FFFFFF; font-family: 'Quicksand'; font-size:14px; line-height:20px; font-weight: normal; text-align:center; letter-spacing: .01em">
                                                      <span><a href="https://halleyx.com"
                                                              style="color: #FFFFFF;font-size: 14px;"><u>T&C</u><u
                                                                  style="padding-left:40px;padding-right: 40px;">Privacy</u><u>Cookies</u></a></span><br />
                                                      <br />
                                                      © 2023 Halleyx Inc. Copyright and rights reserved <p
                                                          style="padding-left: 17px !important; font-family:Open Sans; font-size: 14px; color: #FFFFFF;margin-top: 15px;  margin-bottom: 0px; letter-spacing: .01em;line-height: 20px;">
                                                          18 King St E #1400, Toronto, ON M5C 1C4
  
                                                      </p>
                                                      <span style="float:left;margin-left: -60px;padding-top:20px;"><img width="100" src="https://halleyx.com/img/halleyx-logo-line-white.c09fdc43.svg"/></span>
                                                      <span style="float: right;margin-right:-50px;padding-top:20px;color: #FFFFFF;"><a href="https://www.linkedin.com/company/halleyx/"><i class="fa fa-linkedin fa-lg" style="color: #ffffff;" ></i></a>
                                                          <a href="https://halleyx.com/" style="padding-left:5px;padding-right: 5px;"><i class="fa fa-facebook fa-lg" style="color: #ffffff;"></i>
                                                          </a>
                                                          <a href="https://www.instagram.com/halleyx_inc/"><i class="fa fa-instagram fa-lg" style="color: #ffffff;"></i></a></span>
                                                  </td>
  
                                              </tr>
                                          </table>
                                          <!-- Footer -->
                                          <table width="100%" border="0" cellspacing="0" cellpadding="0"
                                              bgcolor="#ffffff">
                                              <tr>
                                                  <td>
                                                      <style>
                                                          .nowrap {
                                                              word-break: keep-all;
                                                              -moz-hyphens: none;
                                                              -ms-hyphens: none;
                                                              -webkit-hyphens: none;
                                                              hyphens: none;
                                                          }
  
                                                          a[x-apple-data-detectors] {
                                                              color: inherit !important;
                                                              text-decoration: none !important;
                                                              font-size: inherit !important;
                                                              font-family: inherit !important;
                                                              font-weight: inherit !important;
                                                              line-height: inherit !important;
                                                          }
                                                      </style>
  
                                                      <table border="0" cellpadding="0" cellspacing="0"
                                                          style="border:none;border-collapse:collapse; background-color:#ffffff;"
                                                          width="100%" class="wrapper mobileContainer">
  
                                                          <tr>
                                                              <td class="mobileContainer" align="center"
                                                                  style="font-family:Helvetica, Arial, sans-serif; color:#939c9e; font-size:0px; padding-top:24px; font-weight:100; background-color:#ffffff; width:100%; ">
                                                              </td>
                                                          </tr>
  
  
  
  
                                                      </table>
                                                  </td>
                                              </tr>
                                          </table>
                                          <!-- END Footer -->
                                      </td>
                                  </tr>
                              </table>
                          </td>
                      </tr>
                  </table>
              </td>
          </tr>
      </table>
      <img src="http://click.e.fitbit.com/open.aspx?ffcb10-feb81d72726d0378-fe2a167670650678701679-fe87137275620d7472-ff6a177275-fe201574706d07797d1d74-ff3117747761"
          width="1" height="1"></custom>
  </body>
  
  </html>`,
      };
      const sentEmail = await MLTAxiosInstance.post(`email`, mail);
      if (sentEmail.data != undefined) {
        this.modal_active1 = true;
      }
    },
    async updatePassword(val) {
      this.model_active = false;
      let update = {
        id: val._id,
        password: "secret",
      };
      let token = {
        headers: {
          Authorization: "Bearer  " + this.token,
        },
      };
      const updated = await axios.patch(
        this.fet + `/partyManagement/individual`,
        update,
        token
      );
      //
      if (updated.data != undefined) {
        this.resetModal = false;
        this.notification = {
          type: "success",
          message: "Password reset email sent",
        };
        this.reset(val);
      }
    },
    async editList(indexValue) {
      const user = this.tableData[indexValue];
      this.listView = await user;

      this.editUser = await user;

      //
      this.edit = true;
    },
    async saveUser() {
      this.edit = false;
      let list_update = {
        id: this.editUser._id,
        name: this.editUser.name,
        email: this.editUser.email,
        status: this.editUser.status,
      };
      const updated = await MLTAxiosInstance.patch(
        `/partymanagement/individual`,
        list_update
      );
      console.log("🚀 ~ saveUser ~ updated:", updated);

      let role = {
        name: this.editUser.role,
      };

      if (this.editUser.role != this.listView.role) {
        // const roleUpdate =
        await MLTAxiosInstance.patch(
          `/partymanagement/individual/role/${this.editUser._id}`,
          role
        );
      }
      let email = {
        email: this.editUser.email,
      };
      if (this.editUser.email != this.listView.email) {
        // const roleUpdate =
        await MLTAxiosInstance.patch(
          `/partymanagement/individual/catalog/${this.editUser._id}`,
          email
        );
      }

      // await this.resfreshTable();
      this.loadTable();
    },
    async activateUser(indexValue) {
      const user = this.tableData[indexValue];

      let update = {
        id: user._id,
        status: "Active",
      };
      let token = {
        headers: {
          Authorization: "Bearer  " + this.token,
        },
      };
      // const updated =
      await axios.patch(
        this.fet + `/partyManagement/individual`,
        update,
        token
      );

      this.resfreshTable();
    },
    async deactivateUser(indexValue) {
      const user = this.tableData[indexValue];

      let update = {
        id: user._id,
        status: "Deactivated",
      };
      let token = {
        headers: {
          Authorization: "Bearer  " + this.token,
        },
      };
      const updated = await axios.patch(
        this.fet + `/partyManagement/individual`,
        update,
        token
      );

      if (updated.data._id != undefined) {
        this.resfreshTable();
      }
    },
    async impersinateUser(indexValue) {
      const user = await this.tableData[indexValue];

      const update = {
        email: user.email,
        company: user.company,
        impersonation: true,
        impersinater: this.token,
      };
      let tokens = {
        headers: {
          Authorization: "Bearer  " + this.token,
        },
      };

      const change = await axios.patch(
        this.fet + `/partymanagement/individual/impersinate`,
        update,
        tokens
      );

      let company = await change.data.company;
      const token = await change.data.token;

      if (company != "") {
        company = company.toLowerCase();
        let c_name = null;
        if (company == "Halleyx") {
          c_name = "";
        } else {
          c_name = company + ".";
        }

        // location.replace(window.location.protocol+"//"+`${c_name}`+window.location.hostname +':'+window.location.port+`/DMN/dashboard/${token}`)
        const add =
          window.location.protocol +
          "//" +
          window.location.hostname +
          ":" +
          window.location.port;
        const urlObject = new URL(add);
        const hostName = urlObject.hostname;
        let domainName = hostName.replace(/^[^.]+\./g, "");
        window.location.href =
          window.location.protocol +
          "//" +
          `${c_name}` +
          domainName +
          ":" +
          window.location.port +
          `/DMN/dashboard/${token}`;
      }
    },
    async deleteUser(indexValue) {
      const user = this.tableData[indexValue];
      this.deleteModal = true;
      this.confirmUser = user;
    },
    async confirmDelete(user) {
      let token = {
        headers: {
          Authorization: "Bearer  " + this.token,
        },
      };
      const deleteId = await axios.delete(
        this.fet + `/partyManagement/individual/${user._id}`,
        token
      );

      if (deleteId.data._id != undefined) {
        this.notification = {
          type: "success",
          message: "User deleted successfully",
        };
        this.resfreshTable();
      }
      this.deleteModal = false;
    },
    resfreshTable() {
      // let token = {
      //   headers: {
      //     Authorization: 'Bearer  ' + this.token,
      //   },
      // };
      MLTAxiosInstance.get(`partyManagement/individual/check`).then((data) => {
        const tableDatam = data.data;
        tableDatam.map((val) => {
          val.context = { show: false };
          return val;
        });
        this.tableData = tableDatam;
      });
    },
    showCode(val) {
      document.getElementById(val + "-icon").classList.toggle("active-icon");
      if (document.getElementById(val).style.display === "none") {
        document.getElementById(val).style.display = "block";
      } else if (document.getElementById(val).style.display === "block") {
        document.getElementById(val).style.display = "none";
      }
    },
    updatedRowsPerPage(val) {
      this.rowsPerPage = val;
    },
    // paginateData(data, pageNumber, rowsPerPage) {
    //   const startIndex = (pageNumber - 1) * rowsPerPage;
    //   const endIndex = startIndex + rowsPerPage;
    //   return data.slice(startIndex, endIndex);
    // },
    changePageNumber(pg) {
      this.pageNumber = pg;
    },
    async handleUserSearch(val) {
      console.log("🚀 ~ handleUserSearch ~ val:", val);
      //
      this.searchValue = val;
      // this.searchValue = val;
      if (val && val != "") {
        this.userFilterOptions.type = "filter-search";
        this.userFilterOptions.searchQuery = val;
        const filterResultData = await MLTAxiosInstance.post(
          `util/filterData`,
          this.userFilterOptions
        );
        console.log("🚀 ~ filterResultData:", filterResultData);
        this.tableData = filterResultData.data.data;
        this.totalPageNo = filterResultData.data.total;
      } else {
        this.userFilterOptions.type = "filter";
        this.userFilterOptions.searchQuery == "";
        const filterResultData = await MLTAxiosInstance.post(
          `util/filterData`,
          this.userFilterOptions
        );
        console.log("🚀 ~ filterResultData:", filterResultData);
        this.tableData = filterResultData.data.data;
        this.totalPageNo = filterResultData.data.total;
      }
      //
      // this.tableData = this.searchtabledata.filter((item) => {
      //     if (
      //       item.name != undefined &&
      //       this.searchValue != undefined &&
      //       item._id != undefined &&
      //       item.email != undefined &&
      //       item.role != undefined &&
      //       item.created_on != undefined &&
      //       item.last_visit != undefined &&
      //       item.status != undefined
      //     ) {
      //       return (
      //         item.name
      //           .toLowerCase()
      //           .toString()
      //           .includes(this.searchValue.toLowerCase()) ||
      //         item.email
      //           .toLowerCase()
      //           .toString()
      //           .includes(this.searchValue.toLowerCase()) ||
      //         item._id
      //           .toLowerCase()
      //           .toString()
      //           .includes(this.searchValue.toLowerCase()) ||
      //         item.role
      //           .toLowerCase()
      //           .toString()
      //           .includes(this.searchValue.toLowerCase()) ||
      //         item.created_on
      //           .toLowerCase()
      //           .toString()
      //           .includes(this.searchValue.toLowerCase()) ||
      //         item.last_visit
      //           .toLowerCase()
      //           .toString()
      //           .includes(this.searchValue.toLowerCase()) ||
      //         item.status
      //           .toLowerCase()
      //           .toString()
      //           .includes(this.searchValue.toLowerCase())
      //       );
      //     }
      //   });
      //   this.duplicatetabledata = this.tableData;
      // } else {
      //   this.tableData = this.searchtabledata;
      //   this.duplicatetabledata = this.searchtabledata;
      // }
    },
    gotoDataExecuteView(id) {
      this.$router.push({
        name: "execute-data",
        query: { id: id },
      });
    },
    showAddContextMenu() {
      this.show_add_menu = !this.show_add_menu;
    },
    fromAddContextMenu(val) {
      if (val === "users") {
        this.$router.push({
          name: "users-add",
        });
      } else if (val === "Active") {
        this.$router.push({
          name: "users-custom-variable",
        });
      }
    },
  },
};
</script>

<style></style>
