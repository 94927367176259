<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
    <hlx-alert-notification :notify="notification" :auto-close="true" />
      <section
      v-show="isDiscountEditable"
      :key="updateComponentKey"
      class="discountflow-drawer-cover"
    >
      <!-- hlx-drawer -->
      <hlx-drawer
        :show="isModal"
        position="right"
        width="400" 
        :footer="true"
        :title="'Add attachment'"
        :show-close-icon="true"
        @close="isModal=false"
      >
        <!-- For showing discount icons -->
        <template #body>
          <section v-show="isDiscountConcurrency" style="font-style: OpenSans">
            <div
              class="header-area-contact-drawer"
              style="
                border-bottom: 1px solid #d4d4d4;
                padding-bottom: 5px;
                padding: 4px 0 13px 0;
                margin-bottom: 8px;
              "
            >
              <b>Discount concurrency</b>
            </div>
            <span
              class="concurrencyDiscountSection"
              style="margin-top: 12px; margin-bottom: 12px"
            >
              <hlx-input
                class="checkboxAndRadioStyle"
                :options="concurrencyDiscountRadioExclusive"
                :type="'radio-buttons'"
                @selected="emitConcurrencyDiscount"
              />
              <span
                style="
                  color: #858585;
                  font-size: 12px;
                  font-weight: 400;
                  margin-bottom: 12px;
                "
              >
                Only the discount with the higher priority or greater
                percentage(in this case, the 20% product-level discount) would
                be applied.The site-level 15% discount would be ignored in favor
                of the more significant discount at the product level.
              </span>
            </span>
            <span class="concurrencyDiscountSection" style="margin-top: 12px">
              <hlx-input
                class="checkboxAndRadioStyle"
                :options="concurrencyDiscountRadioBest"
                :type="'radio-buttons'"
                @selected="emitConcurrencyDiscount"
              />
              <span
                style="
                  color: #858585;
                  font-size: 12px;
                  font-weight: 400;
                  margin-bottom: 12px;
                "
              >
                Both discounts would be applied, and the system would calculate
                the overall best price for the customer. In this case, if a
                product has a 20% discount and there's a site-wide 15% discount,
                the customer would receive the combined discount for a total
                discount of 32.5%.
              </span>
            </span>
            <span class="concurrencyDiscountSection" style="margin-top: 12px">
              <hlx-input
                class="checkboxAndRadioStyle"
                :options="concurrencyDiscountRadioCompounded"
                :type="'radio-buttons'"
                @selected="emitConcurrencyDiscount"
              />
              <span
                style="
                  color: #858585;
                  font-size: 12px;
                  font-weight: 400;
                  margin-bottom: 12px;
                "
              >
                Both discounts would be applied in a cumulative manner. With a
                20% discount at the product level and a 15% discount at the site
                level, the customer would receive a total discount of 32.5%.
              </span>
            </span>
          </section>

          <div v-show="isDiscountIcons">
            <div
              class="header-area-contact-drawer"
              style="
                border-bottom: 1px solid #d4d4d4;
                padding-bottom: 5px;
                padding: 4px 0 13px 0;
              "
            >
              <b>Add discount</b>
            </div>
            <div class="discount-flow-drawer" style="margin-top: 12px;">
              <section
                class="discount-drawer"
                @click="openProductDiscountDrawer()"
              >
                <i class="icon-box-regular discountflow-icon"></i>
                <h5 class="discount-drawer-header">Product discount</h5>
                <p class="discount-drawer-para">
                  Purchase with reduced prices.
                </p>
              </section>
              <section
                class="discount-drawer"
                @click="openCardBasedDiscountDrawer()"
              >
                <i class="icon-shopping-cart-regular discountflow-icon"></i>
                <h5 class="discount-drawer-header">Cart based discount</h5>
                <p class="discount-drawer-para">Discounts on Cart Contents.</p>
              </section>
              <section
                class="discount-drawer"
                @click="openPaymentMethodBasedDiscountDrawer()"
              >
                <i class="icon-badge-dollar-regular discountflow-icon"></i>
                <h5 class="discount-drawer-header">Payment method discount</h5>
                <p class="discount-drawer-para">Applied to cart total.</p>
              </section>
              <section
                class="discount-drawer"
                @click="openCustomerDiscountDrawer()"
              >
                <i class="icon-money-convert-regular discountflow-icon"></i>
                <h5 class="discount-drawer-header">Customer discount</h5>
                <p class="discount-drawer-para">
                  Discounts for set of Customers.
                </p>
              </section>
              <section
                class="discount-drawer"
                @click="openRecurringDiscountDrawer()"
              >
                <i class="icon-money-convert-regular discountflow-icon"></i>
                <h5 class="discount-drawer-header">Recurring discount</h5>
                <p class="discount-drawer-para">Discounts for Subscribers.</p>
              </section>
              <section
                class="discount-drawer"
                @click="openRoleBasedDiscountDrawer()"
              >
                <i class="icon-user-viewfinder-regular discountflow-icon"></i>
                <h5 class="discount-drawer-header">Role based discount</h5>
                <p class="discount-drawer-para">Based on designation.</p>
              </section>
            </div>
          </div>

          <!-- For showing product discount -->
          <!-- style="height: 130vh !important" -->
          <div  v-show="isProductDiscount" style="padding-right: 6px;">
            <div
              class="header-area-contact-drawer"
              style="
                border-bottom: 1px solid #d4d4d4;
                padding-bottom: 5px;
                padding: 4px 0 13px 0;
              "
            >
              <b>Product discount</b>
            </div>

            <section>
              <section class="productDiscountSection" style="margin-top: 12px">
                <hlx-input
                  v-model:value="discountNewDataEmit.name"
                  :pre-val="discountNewDataEmit.name"
                  :label-animation="true"
                  label-value="Name"
                  type="text"
                  :clearable="true"
                  required="true"
                />
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.description"
                  :pre-val="discountNewDataEmit.description"
                  :label-animation="true"
                  type="textarea"
                  label-value="Description"
                  :clearable="false"
                />
              </section>
              <section class="productDiscountSection">
                <HlxStatus
                  :type="'select-status'"
                  :status-option="statusData"
                  :prevalue="discountNewDataEmit.lifecycleState"
                  @emit-select="emitStatus"
                />
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.validFor.startDate"       
                  :pre-val="discountNewDataEmit.validFor?.startDate"
                  :label-animation="true"
                  :single-border="false"
                  :label-value="'Start date'"
                  :date-format="'dd (short month), yyyy'"
                  type="date"
                  :clearable="false"
                  icon="calendar-regular"
                  icon-position="right"
                      required="true"
                  :disable-weekend="false"
                  :start-year="2000"
                  :display-error="true"
                 :custom-error="startDateBoolean"
                  :custom-error-message="startDateMsg"
                  :end-year="9999"
                  @iso-date="calenderValueStartDate"
                    @at-input="calenderValueStartDate"
                    @focus-out="focusOutStartDate"
                />
                <!-- @formattedDate="calenderValueStartDate" -->
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.validFor.endDate"
                  :label-animation="true"
                  :single-border="false"
                  :label-value="'End date'"
                  :date-format="'dd (short month), yyyy'"
                  type="date"
                  :clearable="false"
                  icon="calendar-regular"
                  :pre-val="discountNewDataEmit.validFor?.endDate"
                  icon-position="right"
                  required="true"
                  :disable-weekend="false"
                  :start-year="2000"
                  :display-error="true"
                  :custom-error="endDateBoolean"
                          :custom-error-message="endDateMsg"
                          :end-year="9999"
                          @iso-date="calenderValueEndDate"
                          @at-input="calenderValueEndDate"
                          @focus-out="focusOutEndDate"
                />
                <!-- @formattedDate="calenderValueEndDate" -->
              </section>
              <section class="productDiscountSection">
                <h5>Maximum discount rules</h5>
                <span
                  style="text-wrap: pretty; font-size: 14px; text-align: start"
                >
                  <hlx-input
                    v-model:value="checkedvalue"
                    :options="ProductDiscountCheckbox"
                    :type="'checkbox'"
                    @selected="emitCheckbox"
                  />
                  <span style="display: block; width: 40%; margin-left: 30px">
                    <hlx-input
                      v-if="
                        isCheckboxInput &&
                        discountNewDataEmit.maximumDiscountRules.length === 2
                      "
                      v-model:value="
                        discountNewDataEmit.maximumDiscountRules[1].value
                      "
                      :pre-val="
                        discountNewDataEmit.maximumDiscountRules[1].value
                      "
                      :label-animation="true"
                      type="number"
                      :min-limit="0"
                      :clearable="true"
                    />
                    <hlx-input
                      v-if="
                        isCheckboxInput &&
                        discountNewDataEmit.maximumDiscountRules.length === 3
                      "
                      v-model:value="
                        discountNewDataEmit.maximumDiscountRules[2].value
                      "
                      :pre-val="
                        discountNewDataEmit.maximumDiscountRules[2].value
                      "
                      :label-animation="true"
                      type="number"
                         :min-limit="0"
                      :clearable="true"
                    />
                  </span>
                </span>
              </section>
              <section class="productDiscountSection">
                <h5>Minimum quantity of the products</h5>
                <span
                  style="text-wrap: pretty; font-size: 14px; text-align: start"
                >
                  <!-- style="text-wrap: pretty;
    font-size: 14px;
    text-align: start;" -->

                  <hlx-input
                    v-model:value="radiovalue"
                    :options="ProductDiscountRadiobutton"
                    :type="'radio-buttons'"
                    @selected="emitRadioProductBased"
                  />

                  <span style="display: block; width: 40%; margin-left: 30px">
                    <hlx-input
                      v-if="
                        isRadiobuttonProductBasedInput &&
                        discountNewDataEmit.minimumDiscountRules.length === 2
                      "
                      v-model:value="
                        discountNewDataEmit.minimumDiscountRules[1].value
                      "
                      :pre-val="
                        discountNewDataEmit.minimumDiscountRules[1].value
                      "
                      :label-animation="true"
                      type="number"
                         :min-limit="0"
                      :clearable="true"
                    />
                  </span>
                </span>
              </section>
              <section class="productDiscountSection">
                <h5>Discount type</h5>
                <span class="select-width-half">
                  <span style="width: 50%">
                    <hlx-select
                      v-model:value="discountNewDataEmit.discountType.name"
                      :options="dropdown_data"
                      :pre-value="discountNewDataEmit.discountType.name"
                      :label="'name'"
                      :prop-value="'name'"
                      :label-animation="true"
                      :inline-search="true"
                      @selected-value="productDiscountType"
                    />
                  </span>
                  <span v-if="discountTypeFixedProduct" style="width: 50%">
                    <hlx-input
                      v-model:value="discountNewDataEmit.discountType.value"
                      :label-animation="false"
                      type="number"
                      :min-limit="0"
                      :clearable="false"
                      :display-error="false"
                      :read-only="false"
                      :custom-error-message="'Please set a default value'"
                      :custom-error="false"
                      :pre-val="discountNewDataEmit.discountType.value"
                      :symbol="
                        $store?.getters?.getDefaultSettings?.currency?.symbol
                      "
                    />
                  </span>
                  <span v-if="discountTypePercentageProduct" style="width: 50%">
                    <hlx-input
                      v-model:value="discountNewDataEmit.discountType.value"
                      :label-animation="true"
                      type="mixed-number"
                              :min-limit="0"
                      :display-error="false"
                      :read-only="false"
                      :custom-error-message="'Please set a default value'"
                      :custom-error="false"
                      :disabled="false"
                      :clearable="true"
                      :pre-val="discountNewDataEmit.discountType.value"
                      :suffix="true"
                      :prefix="false"
                    >
                      <template #suffix> %</template>
                    </hlx-input>
                  </span>
                </span>
              </section>
              <section class="productDiscountSection" style="margin-bottom:28px !important">
                <span style="display:flex">
                  <h5>Apply to</h5>
                  <span style="color: rgb(255, 80, 80);font-size: 12px;    padding-left: 2px; padding-top: 8px;">*</span>
                </span>
                <span class="required">
                  <searchfilterComponent
                    :key="updateKeyInfo"
                    :pre-value-items="preValOffers"
                    :dropdown-width="369"
                    :update="updateKeyInfo"
                    :module="'productoffering'"
                    :filter-search-options="mainInfoProduct"
                    :filter-data="searchFilterData"
                    :filter-checked-data="layout"
                    :key-value-from-query-array="'label'"
                    :filter-value-object-name="'name'"
                    @searched-value="getSearchedValueProductDiscount"
                    @checked-value="getcheckedValueProductDiscount"
                  >
                  </searchfilterComponent>
                </span>
              </section>
            </section>
          </div>

          <!-- For showing Cart based discount -->

          <div v-show="isCardBasedDiscount" style="padding-right: 6px;">
            <div
              class="header-area-contact-drawer"
              style="
                border-bottom: 1px solid #d4d4d4;
                padding-bottom: 5px;
                padding: 4px 0 13px 0;
              "
            >
              <b>Cart based discount</b>
            </div>

            <!-- height: 107vh !important" -->
            <section style="overflow-y: auto;">
              <section class="productDiscountSection" style="margin-top: 12px">
                <hlx-input
                  v-model:value="discountNewDataEmit.name"
                  :pre-val="discountNewDataEmit.name"
                  :label-animation="true"
                  label-value="Name"
                  type="text"
                  :clearable="true"
                  required="true"
                />
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.description"
                  :pre-val="discountNewDataEmit.description"
                  :label-animation="true"
                  type="textarea"
                  label-value="Description"
                  :clearable="false"
                />
              </section>
              <section class="productDiscountSection">
                <HlxStatus
                  :type="'select-status'"
                  :status-option="statusData"
                  :prevalue="discountNewDataEmit.lifecycleState"
                  @emit-select="emitStatus"
                />
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.validFor.startDate"
                  :pre-val="discountNewDataEmit.validFor?.startDate"
                  :label-animation="true"
                  :single-border="false"
                  :label-value="'Start date'"
                  :date-format="'dd (short month), yyyy'"
                  type="date"
                        required="true"
                  :clearable="false"
                  icon="calendar-regular"
                  icon-position="right"
                  :disable-weekend="false"
                  :start-year="2000"
                  :display-error="true"
                 :custom-error="startDateBoolean"
                  :custom-error-message="startDateMsg"
                  :end-year="9999"
                  :holiday-list="holidays"
                  @iso-date="calenderValueStartDate"
                    @at-input="calenderValueStartDate"
                    @focus-out="focusOutStartDate"
                />
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.validFor.endDate"
                  :label-animation="true"
                  :single-border="false"
                  :label-value="'End date'"
                  :date-format="'dd (short month), yyyy'"
                  type="date"
                  required="true"
                  :clearable="false"
                  icon="calendar-regular"
                  :pre-val="discountNewDataEmit.validFor?.endDate"
                  icon-position="right"
                  :disable-weekend="false"
                  :start-year="2000"
                  :display-error="true"
                  :end-year="9999"
                  :custom-error="endDateBoolean"
                          :custom-error-message="endDateMsg"
                          @iso-date="calenderValueEndDate"
                          @at-input="calenderValueEndDate"
                          @focus-out="focusOutEndDate"
                />
              </section>
              <section class="productDiscountSection">
                <h5>Maximum discount rules</h5>
                <span
                  style="text-wrap: pretty; font-size: 14px; text-align: start"
                >
                  <hlx-input
                    v-model:value="checkedvalue"
                    :options="ProductDiscountCheckbox"
                    :type="'checkbox'"
                    @selected="emitCheckbox"
                  />
                  <span style="display: block; width: 40%; margin-left: 30px">
                    <hlx-input
                      v-if="
                        isCheckboxInput &&
                        discountNewDataEmit.maximumDiscountRules.length === 2
                      "
                      v-model:value="
                        discountNewDataEmit.maximumDiscountRules[1].value
                      "
                      :pre-val="
                        discountNewDataEmit.maximumDiscountRules[1].value
                      "
                      :label-animation="true"
                      type="number"
                         :min-limit="0"
                      :clearable="true"
                    />
                    <hlx-input
                      v-if="
                        isCheckboxInput &&
                        discountNewDataEmit.maximumDiscountRules.length === 3
                      "
                      v-model:value="
                        discountNewDataEmit.maximumDiscountRules[2].value
                      "
                      :pre-val="
                        discountNewDataEmit.maximumDiscountRules[2].value
                      "
                      :label-animation="true"
                      type="number"
                         :min-limit="0"
                      :clearable="true"
                    />
                  </span>
                </span>
              </section>
              <section class="productDiscountSection">
                <h5>Minimum quantity of the products</h5>
                <hlx-input
                  class="checkboxAndRadioStyle"
                  :options="cardBasedDiscountRadiobutton1"
                  :type="'radio-buttons'"
                  @selected="emitRadioCardBased"
                />
                <span style="display: block; width: 40%; margin-left: 30px">
                  <hlx-input
                    v-if="
                      isRadiobuttonCardBasedInput &&
                      discountNewDataEmit.minimumDiscountRules.length === 2
                    "
                    v-model:value="
                      discountNewDataEmit.minimumDiscountRules[1].value
                    "
                    :pre-val="discountNewDataEmit.minimumDiscountRules[1].value"
                    :label-animation="true"
                    type="number"
                       :min-limit="0"
                    :clearable="true"
                  />
                </span>
              </section>
              <section class="productDiscountSection">
                <h5>Discount type</h5>
                <span class="select-width-half">
                  <span style="width: 50%">
                    <hlx-select
                      v-model:value="discountNewDataEmit.discountType.name"
                      :options="dropdown_data"
                      :pre-value="discountNewDataEmit.discountType.name"
                      :label="'name'"
                      :prop-value="'name'"
                      :label-animation="true"
                      :inline-search="true"
                      @selected-value="productDiscountType"
                    />
                  </span>
                  <span v-if="discountTypeFixedProduct" style="width: 50%">
                    <hlx-input
                      v-model:value="discountNewDataEmit.discountType.value"
                      :label-animation="false"
                      type="number"
                      :min-limit="0"
                      :clearable="false"
                      :display-error="false"
                      :read-only="false"
                      :custom-error-message="'Please set a default value'"
                      :custom-error="false"
                      :pre-val="discountNewDataEmit.discountType.value"
                      :symbol="
                        $store?.getters?.getDefaultSettings?.currency?.symbol
                      "
                    />
                  </span>
                  <span v-if="discountTypePercentageProduct" style="width: 50%">
                    <hlx-input
                      v-model:value="discountNewDataEmit.discountType.value"
                      :label-animation="true"
                      type="mixed-number"
                      :min-limit="0"
                      :display-error="false"
                      :read-only="false"
                      :custom-error-message="'Please set a default value'"
                      :custom-error="false"
                      :disabled="false"
                      :clearable="true"
                      :pre-val="discountNewDataEmit.discountType.value"
                      :suffix="true"
                      :prefix="false"
                    >
                      <template #suffix> %</template>
                    </hlx-input>
                  </span>
                </span>
              </section>
              <section class="productDiscountSection">
                <h5>Apply to</h5>
                <hlx-input
                  class="checkboxAndRadioStyle"
                  :options="cardBasedDiscountRadiobutton2"
                  :type="'radio-buttons'"
                  @selected="emitCardBased"
                />
              </section>
            </section>
          </div>

          <!-- For showing payment method based discount -->

          <div v-show="isPaymentMethodBasedDiscount" style="padding-right: 6px;">
            <div
              class="header-area-contact-drawer"
              style="
                border-bottom: 1px solid #d4d4d4;
                padding-bottom: 5px;
                padding: 4px 0 13px 0;
              "
            >
              <b>Payment method discount</b>
            </div>

            <section style="overflow-y: auto">
              <section class="productDiscountSection" style="margin-top: 12px">
                <hlx-input
                  v-model:value="discountNewDataEmit.name"
                  :pre-val="discountNewDataEmit.name"
                  :label-animation="true"
                  label-value="Name"
                  type="text"
                  :clearable="true"
                  required="true"
                />
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.description"
                  :pre-val="discountNewDataEmit.description"
                  :label-animation="true"
                  type="textarea"
                  label-value="Description"
                  :clearable="false"
                />
              </section>
              <section class="productDiscountSection">
                <HlxStatus
                  :type="'select-status'"
                  :status-option="statusData"
                  :prevalue="discountNewDataEmit.lifecycleState"
                  @emit-select="emitStatus"
                />
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.validFor.startDate"
                  :label-animation="true"
                  :single-border="false"
                  :label-value="'Start date'"
                  :date-format="'dd (short month), yyyy'"
                  type="date"
                  :clearable="false"
                  icon="calendar-regular"
                  :pre-val="discountNewDataEmit.validFor?.startDate"
                  icon-position="right"
                  :disable-weekend="false"
                  :start-year="2000"
                  :display-error="true"
                 :custom-error="startDateBoolean"
                  :custom-error-message="startDateMsg"
                  :end-year="9999"
                  :holiday-list="holidays"
                  @iso-date="calenderValueStartDate"
                    @at-input="calenderValueStartDate"
                    @focus-out="focusOutStartDate"
                />
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.validFor.endDate"
                  :label-animation="true"
                  :single-border="false"
                  :label-value="'End date'"
                  :date-format="'dd (short month), yyyy'"
                  type="date"
                  :clearable="false"
                  required="true"
                  icon="calendar-regular"
                  :pre-val="discountNewDataEmit.validFor?.endDate"
                  icon-position="right"
                  :disable-weekend="false"
                  :start-year="2000"
                  :display-error="true"
                 :custom-error="endDateBoolean"
                  :custom-error-message="endDateMsg"
                  :end-year="9999"
                  @iso-date="calenderValueEndDate"
                          @at-input="calenderValueEndDate"
                          @focus-out="focusOutEndDate"
                />
              </section>
              <section class="productDiscountSection">
                <h5>Maximum discount rules</h5>
                <hlx-input
                  class="checkboxAndRadioStyle"
                  :options="paymentDiscountCheckbox"
                  :type="'radio-buttons'"
                  @selected="emitCheckboxPaymentDiscount"
                />
                <span v-if="isCheckboxPaymentDiscount" style="margin-top: 8px">
                  <hlx-select
                    v-model:value="discountNewDataEmit.minimumDiscountRules"
                    :pre-value="discountNewDataEmit.discountType"
                    :options="paymentMethodTypes"
                    :label="'name'"
                    :prop-value="'name'"
                    :label-animation="true"
                    :inline-search="true"
                    @selected-value="paymentDiscountType"
                  />
                </span>
              </section>
              <section class="productDiscountSection">
                <h5>Discount type</h5>
                <span class="select-width-half">
                  <span style="width: 50%">
                    <hlx-select
                      v-model:value="discountNewDataEmit.discountType.name"
                      :options="dropdown_data"
                      :pre-value="discountNewDataEmit.discountType.name"
                      :label="'name'"
                      :prop-value="'name'"
                      :label-animation="true"
                      :inline-search="true"
                      @selected-value="productDiscountType"
                    />
                  </span>
                  <span v-if="discountTypeFixedProduct" style="width: 50%">
                    <hlx-input
                      v-model:value="discountNewDataEmit.discountType.value"
                      :label-animation="false"
                      type="number"
                         :min-limit="0"
                      :clearable="false"
                      :display-error="false"
                      :read-only="false"
                      :custom-error-message="'Please set a default value'"
                      :custom-error="false"
                      :pre-val="discountNewDataEmit.discountType.value"
                      :symbol="
                        $store?.getters?.getDefaultSettings?.currency?.symbol
                      "
                    />
                  </span>
                  <span v-if="discountTypePercentageProduct" style="width: 50%">
                    <hlx-input
                      v-model:value="discountNewDataEmit.discountType.value"
                      :label-animation="true"
                      type="mixed-number"
                         :min-limit="0"
                      :display-error="false"
                      :read-only="false"
                      :custom-error-message="'Please set a default value'"
                      :custom-error="false"
                      :disabled="false"
                      :clearable="true"
                      :pre-val="discountNewDataEmit.discountType.value"
                      :suffix="true"
                      :prefix="false"
                    >
                      <template #suffix> %</template>
                    </hlx-input>
                  </span>
                </span>
              </section>
              <section class="productDiscountSection">
                <p>The discount will be applied to the cart subtotal.</p>
              </section>
            </section>
          </div>

          <!-- For showing customer discount -->

          <div v-show="isRoleBasedDiscount" style="padding-right: 6px;">
            <div
              class="header-area-contact-drawer"
              style="
                border-bottom: 1px solid #d4d4d4;
                padding-bottom: 5px;
                padding: 4px 0 13px 0;
              "
            >
              <b>Role based discount</b>
            </div>

            <section style="overflow-y: auto">
              <section class="productDiscountSection" style="margin-top: 12px">
                <hlx-input
                  v-model:value="discountNewDataEmit.name"
                  :pre-val="discountNewDataEmit.name"
                  :label-animation="true"
                  label-value="Name"
                  type="text"
                  :clearable="true"
                  required="true"
                />
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.description"
                  :pre-val="discountNewDataEmit.description"
                  :label-animation="false"
                  type="textarea"
                  label-value="Description"
                  :clearable="false"
                />
              </section>
              <section class="productDiscountSection">
                <HlxStatus
                  :type="'select-status'"
                  :status-option="statusData"
                  :prevalue="discountNewDataEmit.lifecycleState"
                  @emit-select="emitStatus"
                />
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.validFor.startDate"
                  :label-animation="true"
                  :single-border="false"
                  :label-value="'Start date'"
                  :date-format="'dd (short month), yyyy'"
                  type="date"
                  :clearable="false"
                  required="true"
                  icon="calendar-regular"
                  :pre-val="discountNewDataEmit.validFor?.startDate"
                  icon-position="right"
                  :disable-weekend="false"
                  :start-year="2000"
                  :display-error="true"
                  :custom-error="startDateBoolean"
                          :custom-error-message="startDateMsg"
                          :end-year="9999"
                          @iso-date="calenderValueStartDate"
                    @at-input="calenderValueStartDate"
                    @focus-out="focusOutStartDate"
                />
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.validFor.endDate"
                  :label-animation="true"
                  :single-border="false"
                  :label-value="'End date'"
                  :date-format="'dd (short month), yyyy'"
                  type="date"
                  required="true"
                  :clearable="false"
                  icon="calendar-regular"
                  :pre-val="discountNewDataEmit.validFor?.endDate"
                  icon-position="right"
                  :disable-weekend="false"
                  :start-year="2000"
                  :display-error="true"
                  :custom-error="endDateBoolean"
                          :custom-error-message="endDateMsg"
                          :end-year="9999"
                          @iso-date="calenderValueEndDate"
                          @at-input="calenderValueEndDate"
                          @focus-out="focusOutEndDate"
                />
                <!-- @formattedDate="calenderValueEndDate" -->
              </section>
              <section class="productDiscountSection">
                <h5>Apply</h5>
                <span class="multi-select-width" style="margin-bottom: 8px">
                  <hlx-select
                    v-model="discountNewDataEmit.relatedParty"
                    :required="false"
                    :options="role_data"
                    :placeholder-value="'Choose role'"
                    :label-animation="true"
                    :prop-value="'value'"
                    :label="'name'"
                    @selected-value="selectedValueRoleBasedDisocunt"
                  ></hlx-select>
                </span>
                <span class="select-width-half" style="margin-top: 12px">
                  <span style="width: 75%">
                    <hlx-select
                      v-model="discountNewDataEmit.maximumDiscountRules"
                      :options="rolePercentageDropdown"
                      :label-animation="true"
                      :label="'name'"
                      :prop-value="'value'"
                      :clearable="true"
                      @selected-value="RoleBasedDisocuntType"
                    >
                    </hlx-select>
                  </span>
                  <span v-if="discountTypeFixedRole" style="width: 25%">
                    <hlx-input
                      v-model:value="discountNewDataEmit.discountType.value"
                      :label-animation="false"
                      type="number"
                         :min-limit="0"
                      :pre-val="discountNewDataEmit.discountType.value"
                      :symbol="
                        $store?.getters?.getDefaultSettings?.currency?.symbol
                      "
                    />
                  </span>
                  <span v-if="discountTypePercentageRole" style="width: 25%">
                    <hlx-input
                      v-model:value="discountNewDataEmit.discountType.value"
                      :label-animation="false"
                      type="number"
                         :min-limit="0"
                      :clearable="false"
                      :display-error="false"
                      :custom-error-message="'Please set a default value'"
                      :custom-error="false"
                      :pre-val="discountNewDataEmit.discountType.value"
                      :symbol="'%'"
                    />
                  </span>
                </span>
              </section>
            </section>
          </div>

          <!-- Contractual discount / customer discount -->

          <div v-show="isCustomerDiscount" style="padding-right: 6px;">
            <div
              class="header-area-contact-drawer"
              style="
                border-bottom: 1px solid #d4d4d4;
                padding-bottom: 5px;
                padding: 4px 0 13px 0;
              "
            >
              <b>Customer discount</b>
            </div>
            <section class="productDiscountSection" style="margin-top: 12px">
              <div class="button-container">
                <span class="customer-discount-button-cover">
                  <button
                    class="customer-discount-button1"
                    :class="{ selected: selectedButton === 2 }"
                    @click="selectButton(2)"
                  >
                    New discount
                  </button>
                  <button
                    class="customer-discount-button2"
                    :class="{ selected: selectedButton === 1 }"
                    @click="selectButton(1)"
                  >
                    Contratual discount
                  </button>
                </span>
              </div>
            </section>

            <!-- height: 130vh !important; -->
            <section
              v-if="isNewDiscount || isCustomerDiscountNewEdit"
              style="
                overflow-y: auto;
                overflow-x: hidden;
              "
            >
              <section class="productDiscountSection" style="margin-top: 12px">
                <hlx-input
                  v-model:value="discountNewDataEmit.name"
                  :pre-val="discountNewDataEmit.name"
                  :label-animation="true"
                  label-value="Name"
                  type="text"
                  :clearable="true"
                  required="true"
                />
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.description"
                  :pre-val="discountNewDataEmit.description"
                  :label-animation="false"
                  type="textarea"
                  label-value="Description"
                  :clearable="false"
                />
              </section>
              <section class="productDiscountSection">
                <HlxStatus
                  :type="'select-status'"
                  :status-option="statusData"
                  :prevalue="discountNewDataEmit.lifecycleState"
                  @emit-select="emitStatus"
                />
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.validFor.startDate"
                  :label-animation="true"
                  :single-border="false"
                  :label-value="'Start date'"
                  :placeholder-value="'Start date'"
                  :date-format="'dd (short month), yyyy'"
                  type="date"
                  :clearable="false"
                  icon="calendar-regular"
                  :pre-val="discountNewDataEmit.validFor?.startDate"
                  icon-position="right"
                             required="true"
                  :disable-weekend="false"
                  :start-year="2000"
                  :display-error="true"
                 :custom-error="startDateBoolean"
                  :custom-error-message="startDateMsg"
                  :end-year="9999"
                  @iso-date="calenderValueStartDate"
                    @at-input="calenderValueStartDate"
                    @focus-out="focusOutStartDate"
                />
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.validFor.endDate"
                  :label-animation="true"
                  :single-border="false"
                  :placeholder-value="'End date'"
                  :label-value="'End date'"
                  :date-format="'dd (short month), yyyy'"
                  type="date"
                  required="true"
                  :clearable="false"
                  icon="calendar-regular"
                  :pre-val="discountNewDataEmit.validFor?.endDate"
                  icon-position="right"
                  :disable-weekend="false"
                  :start-year="2000"
                  :display-error="true"
                  :custom-error="endDateBoolean"
                          :custom-error-message="endDateMsg"
                          :end-year="9999"
                          @iso-date="calenderValueEndDate"
                          @at-input="calenderValueEndDate"
                          @focus-out="focusOutEndDate"
                />
              </section>
              <section class="productDiscountSection">
                <h5>Account details</h5>
                <hlx-multi-select
                  :pre-value="account_pre_value"
                  :required="false"
                  :options="account_data"
                  :placeholder="'Choose account'"
                  :label-animation="true"
                  :prop-value="'value'"
                  :label="'name'"
                  @selected-array="selectedValueContraDiscount"
                ></hlx-multi-select>
                <section
                  class="productDiscountSection"
                  style="margin-top: 12px"
                >
                  <hlx-multi-select
                    :pre-value="prevalue"
                    :required="false"
                    :options="dropdown_data_contract"
                    :placeholder="'Choose contract'"
                    :label-animation="true"
                    :prop-value="'value'"
                    :label="'name'"
                    @selected-array="selectedValueContraDiscount2"
                  ></hlx-multi-select>
                </section>
              </section>
              <section class="productDiscountSection">
                <h5>Discount type</h5>
                <span class="select-width-half">
                  <span style="width: 39%">
                    <hlx-select
                      v-model:value="discountNewDataEmit.discountType.name"
                      :options="dropdown_data"
                      :pre-value="discountNewDataEmit.discountType.name"
                      :label="'name'"
                      :prop-value="'name'"
                      :label-animation="true"
                      :inline-search="true"
                      @selected-value="contractualDiscountType"
                    />
                  </span>

                  <span v-if="discountTypeFixed" style="width: 39%">
                    <hlx-input
                      v-model:value="discountNewDataEmit.discountType.value"
                      :label-animation="false"
                      type="number"
                         :min-limit="0"
                      :clearable="false"
                      :display-error="false"
                      :read-only="false"
                      :custom-error-message="'Please set a default value'"
                      :custom-error="false"
                      :pre-val="discountNewDataEmit.discountType.value"
                      :symbol="
                        $store?.getters?.getDefaultSettings?.currency?.symbol
                      "
                    />
                  </span>
                  <span v-if="discountTypePercentage" style="width: 39%">
                    <hlx-input
                      v-model:value="discountNewDataEmit.discountType.value"
                      :label-animation="true"
                      type="mixed-number"
                         :min-limit="0"
                      :display-error="false"
                      :read-only="false"
                      :custom-error-message="'Please set a default value'"
                      :custom-error="false"
                      :disabled="false"
                      :clearable="true"
                      :pre-val="discountNewDataEmit.discountType.value"
                      :suffix="true"
                      :prefix="false"
                    >
                      <template #suffix> %</template>
                    </hlx-input>
                  </span>
                  <h3>On price</h3>
                </span>
              </section>

              <section class="productDiscountSection" style="margin-bottom:28px !important">
                <span style="display:flex">
                  <h5>Apply to</h5>
                  <span style="color: rgb(255, 80, 80);font-size: 12px;    padding-left: 2px; padding-top: 8px;">*</span>
                </span>
                <span>
                  <searchfilterComponent
                    :key="updateKeyContra"
                    :pre-value-items="preValOffers"
                    :dropdown-width="369"
                    :update="updateKeyContra"
                    :module="'productoffering'"
                    :filter-search-options="mainInfoContractual"
                    :filter-data="searchFilterData"
                    :filter-checked-data="layout"
                    :key-value-from-query-array="'label'"
                    :filter-value-object-name="'name'"
                    @searched-value="getSearchedValueContractualDiscount"
                    @checked-value="getcheckedValueContractualDiscount"
                  >
                  </searchfilterComponent>
                </span>
              </section>
            </section>

            <!-- height: 130vh !important; -->
            <section
              v-if="isContratualDiscount || isCustomerDiscountEdit"
              style="
                overflow-y: auto;
                overflow-x: hidden;
              "
            >
              <!-- <section class="productDiscountSection" style="margin-top: 12px">
                <div class="button-container">
                  <span class="customer-discount-button-cover">
                    <button
                      class="customer-discount-button1"
                      :class="{ selected: selectedButton === 1 }"
                      @click="selectButton(1)"
                    >
                      New discount
                    </button>
                    <button
                      class="customer-discount-button2"
                      :class="{ selected: selectedButton === 2 }"
                      @click="selectButton(2)"
                    >
                      Contratual discount
                    </button>
                  </span>
                </div>
              </section> -->

              <section class="productDiscountSection" style="margin-top: 12px">
                <hlx-input
                  v-model:value="discountNewDataEmit.name"
                  :pre-val="discountNewDataEmit.name"
                  :label-animation="true"
                  label-value="Name"
                  type="text"
                  :clearable="true"
                  required="true"
                />
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.description"
                  :pre-val="discountNewDataEmit.description"
                  :label-animation="false"
                  type="textarea"
                  label-value="Description"
                  :clearable="false"
                />
              </section>

              <section class="productDiscountSection">
                <HlxStatus
                  :type="'select-status'"
                  :status-option="statusData"
                  :prevalue="discountNewDataEmit.lifecycleState"
                  @emit-select="emitStatus"
                />
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.validFor.startDate"
                  :label-animation="true"
                  :placeholder-value="'Start date'"
                  :single-border="false"
                  :label-value="'Start date'"
                  :date-format="'dd (short month), yyyy'"
                  type="date"
                  :clearable="false"  
                   required="true"
                  icon="calendar-regular"
                  :pre-val="discountNewDataEmit.validFor?.startDate"
                  icon-position="right"
                  :disable-weekend="false"
                  :start-year="2000"
                  :end-year="9999"
                  :display-error="true"
                 :custom-error="startDateBoolean"
                  :custom-error-message="startDateMsg"
                  @iso-date="calenderValueStartDate"
                    @at-input="calenderValueStartDate"
                    @focus-out="focusOutStartDate"
                />
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.validFor.endDate"
                  :label-animation="true"
                  :placeholder-value="'End date'"
                  :single-border="false"
                  :label-value="'End date'"
                  :date-format="'dd (short month), yyyy'"
                  type="date"
                  required="true"
                  :clearable="false"
                  icon="calendar-regular"
                  :pre-val="discountNewDataEmit.validFor?.endDate"
                  icon-position="right"
                  :disable-weekend="false"
                  :start-year="2000"
                  :display-error="true"
                  :custom-error="endDateBoolean"
                          :custom-error-message="endDateMsg"
                          :end-year="9999"
                          @iso-date="calenderValueEndDate"
                          @at-input="calenderValueEndDate"
                          @focus-out="focusOutEndDate"
                />
              </section>
              <section class="productDiscountSection">
                <h5>Discount type</h5>
                <span class="select-width-half">
                  <span style="width: 39%">
                    <hlx-select
                      v-model:value="discountNewDataEmit.discountType.name"
                      :options="dropdown_data"
                      :pre-value="discountNewDataEmit.discountType.name"
                      :label="'name'"
                      :prop-value="'name'"
                      :label-animation="true"
                      :inline-search="true"
                      @selected-value="newDiscountType"
                    />
                  </span>

                  <!-- <span style="width: 39%" >
                    <hlx-input
                    v-model:value="discountNewDataEmit.discountType.value"
                    :pre-val="discountNewDataEmit.discountType.value"
                      :label-animation="true"
                      label-value="%"
                      type="text"
                      :clearable="true"
                    />
                  </span> -->
                  <span v-if="discountTypeFixed" style="width: 39%">
                    <hlx-input
                      v-model:value="discountNewDataEmit.discountType.value"
                      :label-animation="false"
                      type="number"
                         :min-limit="0"
                      :clearable="false"
                      :display-error="false"
                      :read-only="false"
                      :custom-error-message="'Please set a default value'"
                      :custom-error="false"
                      :pre-val="discountNewDataEmit.discountType.value"
                      :symbol="
                        $store?.getters?.getDefaultSettings?.currency?.symbol
                      "
                    />
                  </span>
                  <span v-if="discountTypePercentage" style="width: 39%">
                    <hlx-input
                      v-model:value="discountNewDataEmit.discountType.value"
                      :label-animation="true"
                      type="mixed-number"
                         :min-limit="0"
                      :display-error="false"
                      :read-only="false"
                      :custom-error-message="'Please set a default value'"
                      :custom-error="false"
                      :disabled="false"
                      :clearable="true"
                      :pre-val="discountNewDataEmit.discountType.value"
                      :suffix="true"
                      :prefix="false"
                    >
                      <template #suffix> %</template>
                    </hlx-input>
                  </span>
                  <h3>On price</h3>
                </span>
              </section>

              <section class="productDiscountSection">
                <h5>Apply to</h5>
                <hlx-input
                  v-model:value="radiovalueNewDiscount"
                  class="checkboxAndRadioStyle"
                  :options="newDiscountRadiobutton"
                  :type="'radio-buttons'"
                  @selected="emitRadioNewDiscount"
                />
                <span v-show="isRadiobuttonNewDiscountMultiselect">
                  <hlx-multi-select
                    :pre-value="account_pre_value_new"
                    :required="false"
                    :options="account_data_new"
                    :placeholder="'Choose account'"
                    :label-animation="true"
                    :prop-value="'value'"
                    :label="'name'"
                    @selected-array="selectedValueNewDiscount"
                  ></hlx-multi-select>
                </span>
              </section>

              <section class="productDiscountSection" style="margin-bottom:28px !important">
                <span style="display:flex">
                  <h5>Select product</h5>
                  <span style="color: rgb(255, 80, 80);font-size: 12px;    padding-left: 2px; padding-top: 8px;">*</span>
                </span>
                <span>
                  <searchfilterComponent
                    :key="updateKeyNew"
                    :pre-value-items="preValOffers"
                    :dropdown-width="369"
                    :update="updateKeyNew"
                    :module="'productoffering'"
                    :filter-search-options="mainInfoNewDiscount"
                    :filter-data="searchFilterData"
                    :filter-checked-data="layout"
                    :key-value-from-query-array="'label'"
                    :filter-value-object-name="'name'"
                    @searched-value="getSearchedValueNewDiscount"
                    @checked-value="getcheckedValueNewDiscount"
                  >
                  </searchfilterComponent>
                </span>
              </section>
            </section>
          </div>

          <!-- RecurringDiscount-->

          <!-- height: 130vh !important" -->
          <div
            v-show="isRecurringDiscount"
            style="padding-right: 6px;"
          >
            <div
              class="header-area-contact-drawer"
              style="
                border-bottom: 1px solid #d4d4d4;
                padding-bottom: 5px;
                padding: 4px 0 13px 0;
              "
            >
              <b>Recurring discount</b>
            </div>

            <section>
              <section class="productDiscountSection" style="margin-top: 12px">
                <hlx-input
                  v-model:value="discountNewDataEmit.name"
                  :pre-val="discountNewDataEmit.name"
                  :label-animation="true"
                  label-value="Name"
                  type="text"
                  :clearable="true"
                  required="true"
                />
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.description"
                  :pre-val="discountNewDataEmit.description"
                  :label-animation="true"
                  type="textarea"
                  label-value="Description"
                  :clearable="false"
                />
              </section>
              <section class="productDiscountSection">
                <HlxStatus
                  :type="'select-status'"
                  :status-option="statusData"
                  :prevalue="discountNewDataEmit.lifecycleState"
                  @emit-select="emitStatus"
                />
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.validFor.startDate"
                  :label-animation="true"
                  :single-border="false"
                  :label-value="'Start date'"
                  :placeholder-value="'Start date'"
                  :date-format="'dd (short month), yyyy'"
                  type="date"
                    required="true"
                  :clearable="false"
                  icon="calendar-regular"
                  :pre-val="discountNewDataEmit.validFor?.startDate"
                  icon-position="right"
                  :disable-weekend="false"
                  :start-year="2000"
                  :display-error="true"
                 :custom-error="startDateBoolean"
                  :custom-error-message="startDateMsg"
                  :end-year="9999"
                  @iso-date="calenderValueStartDate"
                    @at-input="calenderValueStartDate"
                    @focus-out="focusOutStartDate"
                />
              </section>
              <section class="productDiscountSection">
                <hlx-input
                  v-model:value="discountNewDataEmit.validFor.endDate"
                  :label-animation="true"
                  :single-border="false"
                  :placeholder-value="'End date'"
                  :label-value="'End date'"
                  :date-format="'dd (short month), yyyy'"
                  type="date"
                  required="true"
                  :clearable="false"
                  icon="calendar-regular"
                  :pre-val="discountNewDataEmit.validFor?.endDate"
                  icon-position="right"
                 :disable-weekend="false"
                  :start-year="2000"
                  :display-error="true"
                  :custom-error="endDateBoolean"
                          :custom-error-message="endDateMsg"
                          :end-year="9999"
                          @iso-date="calenderValueEndDate"
                          @at-input="calenderValueEndDate"
                          @focus-out="focusOutEndDate"
                />
              </section>
              <section class="productDiscountSection">
                <h5>Maximum discount rules</h5>
                <span
                  style="text-wrap: pretty; font-size: 14px; text-align: start"
                >
                  <hlx-input
                    v-model:value="checkedvalue"
                    :options="ProductDiscountCheckbox"
                    :type="'checkbox'"
                    @selected="emitCheckboxRecurring"
                  />
                  <span style="display: block; width: 40%; margin-left: 30px">
                    <hlx-input
                      v-if="
                        isCheckboxInput &&
                        discountNewDataEmit.maximumDiscountRules.length === 2
                      "
                      v-model:value="
                        discountNewDataEmit.maximumDiscountRules[1].value
                      "
                      :pre-val="
                        discountNewDataEmit.maximumDiscountRules[1].value
                      "
                      :label-animation="true"
                      type="number"
                         :min-limit="0"
                      :clearable="true"
                    />
                    <hlx-input
                      v-if="
                        isCheckboxInput &&
                        discountNewDataEmit.maximumDiscountRules.length === 3
                      "
                      v-model:value="
                        discountNewDataEmit.maximumDiscountRules[2].value
                      "
                      :pre-val="
                        discountNewDataEmit.maximumDiscountRules[2].value
                      "
                      :label-animation="true"
                         :min-limit="0"
                      type="number"
                      :clearable="true"
                    />
                  </span>
                </span>
              </section>

              <section class="productDiscountSection">
                <h5>Choose offer</h5>
                <!-- {{mainRecurringProduct}} -->
                <span>
                  <!-- <searchfilterComponent
                    :update="updateKeyRecurr"
                    :key="updateKeyRecurr"
                    :module="'productoffering'"
                    :filter-search-options="  "
                    :filter-data="searchFilterData"
                    :filter-checked-data="layout"
                    :key-value-from-query-array="'label'"
                    :filter-value-object-name="'name'"
                    @searched-value="getSearchedValueRecurringDiscount"
                    @checked-value="getcheckedValueRecurringDiscount"
                  >
                                  v-model:value="offerPayload.relatedParty[0].name"
                  </searchfilterComponent> -->

                  <!-- @selected-value="getcheckedValueRecurringDiscount" -->
                  <!-- {{ "1"+discountNewDataEmit.appliesTo[0]?.name }}
                 {{"1.1"+JSON.stringify(mainRecurringProduct[0])}}
                 {{"2"+typeof discountNewDataEmit.appliesTo}}
                 {{"2.2"+ JSON.stringify(discountNewDataEmit.appliesTo)}}
                 {{"3"+value}} -->
                  <hlx-select
                    :pre-value="discountNewDataEmit.appliesTo[0]?.name"
                    :inline-search="true"
                    :options="mainRecurringProduct"
                    :slot-options="false"
                    :placeholder-value="'Search catalogue'"
                    :prop-value="'name'"
                    :label="'name'"
                    :required="true"
                    :custom-search="false"
                    :label-animation="false"
                    :clearable="false"
                    @selected-value="getcheckedValueRecurringDiscount"
                    @key-press="getTypedVal"
                  ></hlx-select>
                  <!-- <hlx-select
        :pre-value="discountNewDataEmit.appliesTo[0]?.name"
        :options="mainRecurringProduct"
        :placeholder-value="'Language'"
        :label="'name'"
        :prop-value="'value'"
        :sub-title="'subtitle'"
        :tag-value="'labelvalue'"
        :image-link="'link'"
        :label-animation="true"
        :inline-search="true"
        :search-image-tag="false"
        :single-border="false"
        :custom-search="false"
        :clearable="true"
        :required="true"
        :footer="false"
        :error="false"
        :display-error="false"
        @key-press="sa"
        @inline-select-value="getcheckedValueRecurringDiscount"
      >
      </hlx-select> -->
                </span>
              </section>
              <section v-if="isRecurringTable">
                <span class="recurring-table">
                  <hlx-table
                    :column-count="recurringTable?.length"
                    :border="['table', 'header', 'horizontal']"
                    theme="grey"
                  >
                    <template #thead>
                      <hlx-table-head
                        v-for="(i, index) in recurringTable"
                        :key="index"
                        :width="i?.width"
                        :style="{
                          padding: '0 15px !important',
                          minWidth: i.width + 'px !important',
                          maxWidth: i.width + 'px !important',
                        }"
                        :fixed="'left'"
                        >{{ i.label }}
                      </hlx-table-head>
                    </template>
                    <template #tbody>
                      <tr
                        v-for="(i, index) in recurringTableData"
                        id=""
                        :key="index"
                      >
                        <hlx-table-cell
                          v-for="(j, col_index) in recurringTable"
                          :key="col_index"
                          :align="j.align"
                          :fixed="j.fixed"
                        >
                          <div v-if="j.prop === 'billingtype'">
                            {{ i[j.prop] }}
                          </div>
                          <span
                            v-else-if="j.prop === 'billingperiod'"
                            style="display: flex; align-items: center"
                          >
                            <span style="width: 40%">
                              <hlx-input
                                v-model:value="i[j.prop]"
                                :pre-val="i[j.prop]"
                                type="number"
                                   :min-limit="0"
                                :single-border="true"
                                style="width: 28px"
                              />
                            </span>
                            <span v-if="Number(i[j.prop]) > 1">
                              {{ i?.words }}
                            </span>
                            <span v-else>
                              {{ i?.words?.slice(0, -1) }}
                            </span>
                          </span>
                          <span
                            v-else-if="j.prop === 'discount'"
                            style="display: flex; align-items: center"
                          >
                            <span style="width: 70%">
                              <hlx-input
                                v-model:value="i[j.prop]"
                                :pre-val="i[j.prop]"
                                type="number"
                                   :min-limit="0"
                                :single-border="true"
                              />
                            </span>
                            <span style="margin-right: 4px">
                              {{ " %" }}
                            </span></span
                          >
                        </hlx-table-cell>
                      </tr>
                    </template>
                  </hlx-table>
                </span>
              </section>
            </section>
          </div>
        </template>

        <template #footer>
          <!-- Discount concurrency -->
          <div
            v-show="isDiscountConcurrency"
            style="
              width: -webkit-fill-available;
              position: fixed;
              background-color: white;
              display: flex;
              justify-content: flex-end;
              padding: 19px;
              border-top: 1px solid #d8d8d8;
            "
          >
            <span style="margin-right: 12px">
              <hlx-button class="secondary sm" @click="isModal=!isModal"
                >Back</hlx-button
              >
              <span style="margin-left: 12px">
                <hlx-button class="primary sm" @click="isModal=!isModal"
                  >Save</hlx-button
                >
              </span></span
            >
          </div>
          <!-- For showing discount icons -->
          <div
            v-show="isDiscountIcons"
            style="
              width: -webkit-fill-available;
              position: fixed;
              background-color: white;
              display: flex;
              justify-content: flex-end;
              padding: 19px;
              border-top: 1px solid #d8d8d8;
            "
          >
            <hlx-button class="secondary sm" @click="isModal=!isModal"
              >Cancel</hlx-button
            >
          </div>

          <!-- For showing all discount footer buttons -->
          <div
            v-if="isFooterDiscount"
            style="
              width: -webkit-fill-available;
              background: white;
              position: fixed;
              display: flex;
              justify-content: flex-end;
              padding: 19px;
              border-top: 1px solid #d8d8d8;
            "
          >
            <hlx-button class="secondary sm back" @click="goToPreviousDrawer"
              >Back</hlx-button
            >
            <div
              v-show="
                isProductDiscount &&
                discountNewDataEmit.validFor.endDate &&
                discountNewDataEmit.name &&
                discountNewDataEmit.appliesTo.length > 0 &&
                !endDateBoolean && !startDateBoolean
              "
            >
              <hlx-button
                v-if="editMode == false"
                class="primary sm"
                @click="addProductDiscount"
                >Add</hlx-button
              >
              <hlx-button v-else class="primary sm" @click="editProductDiscount"
                >Save</hlx-button
              >
            </div>
            <div
              v-show="
                isProductDiscount &&
                (!discountNewDataEmit.validFor.endDate ||
                  !discountNewDataEmit.name ||
                  discountNewDataEmit.appliesTo.length === 0  || endDateBoolean || startDateBoolean)
              "
            >
              <hlx-button
                v-if="editMode == false"
                class="primary sm"
                :disabled="true"
                >Add</hlx-button
              >
              <hlx-button v-else class="primary sm" :disabled="true"
                >Save</hlx-button
              >
            </div>
            <div
              v-show="
                isContratualDiscount &&
                discountNewDataEmit.validFor.endDate &&
                discountNewDataEmit.name &&
                discountNewDataEmit.appliesTo.length > 0 && !endDateBoolean && !startDateBoolean
              "
            >
              <!-- {{"contra"}} -->
              <hlx-button
                v-if="editMode == false"
                class="primary sm"
                @click="addNewDiscount"
                >Add</hlx-button
              >
              <hlx-button
                v-if="editMode == true"
                class="primary sm"
                @click="editNewDiscount"
                >Save</hlx-button
              >
              <!-- <hlx-button v-show="isRoleBasedDiscount"  class="primary sm">Add</hlx-button> -->
            </div>
            <div
              v-show="
                isContratualDiscount &&
                (!discountNewDataEmit.validFor.endDate ||
                  !discountNewDataEmit.name ||
                  discountNewDataEmit.appliesTo.length === 0 || endDateBoolean || startDateBoolean)
              "
            >
              <!-- {{"contra"}} -->
              <hlx-button
                v-if="editMode == false"
                class="primary sm"
                :disabled="true"
                >Add</hlx-button
              >
              <hlx-button
                v-if="editMode == true"
                class="primary sm"
                :disabled="true"
                >Save</hlx-button
              >
              <!-- <hlx-button v-show="isRoleBasedDiscount"  class="primary sm">Add</hlx-button> -->
            </div>

            <div
              v-show="
                isNewDiscount &&
                discountNewDataEmit.validFor.endDate &&
                discountNewDataEmit.name &&
                discountNewDataEmit.appliesTo.length > 0 && !endDateBoolean && !startDateBoolean
              "
            >
              <!--           -->
              <hlx-button
                v-if="editMode == false"
                class="primary sm"
                @click="addContratualDiscount"
                >Add</hlx-button
              >
              <hlx-button
                v-else
                class="primary sm"
                @click="editContratualDiscount"
                >Save</hlx-button
              >
            </div>

            <div
              v-show="
                isNewDiscount &&
                (!discountNewDataEmit.validFor.endDate ||
                  !discountNewDataEmit.name ||
                  discountNewDataEmit.appliesTo.length === 0 || endDateBoolean || startDateBoolean)
              "
            >
              <!--           -->
              <hlx-button
                v-if="editMode == false"
                class="primary sm"
                :disabled="true"
                >Add</hlx-button
              >
              <hlx-button v-else class="primary sm" :disabled="true"
                >Save</hlx-button
              >
            </div>

            <div
              v-show="
                isCardBasedDiscount &&
                discountNewDataEmit.validFor.endDate &&
                discountNewDataEmit.name && !endDateBoolean && !startDateBoolean
              "
            >
              <hlx-button
                v-if="editMode == false"
                class="primary sm"
                @click="addCardBasedDiscount"
                >Add</hlx-button
              >
              <hlx-button
                v-else
                class="primary sm"
                @click="editCardBasedDiscount"
                >Save</hlx-button
              >
            </div>
            <div
              v-show="
                isCardBasedDiscount &&
                (!discountNewDataEmit.validFor.endDate ||
                  !discountNewDataEmit.name || endDateBoolean || startDateBoolean)
              "
            >
              <hlx-button
                v-if="editMode == false"
                class="primary sm"
                :disabled="true"
                >Add</hlx-button
              >
              <hlx-button v-else class="primary sm" :disabled="true"
                >Save</hlx-button
              >
            </div>
            <div
              v-show="
                isPaymentMethodBasedDiscount &&
                discountNewDataEmit.validFor.endDate &&
                discountNewDataEmit.name && !endDateBoolean && !startDateBoolean
              "
            >
              <hlx-button
                v-if="editMode == false"
                class="primary sm"
                @click="addPaymentMethodDiscount"
                >Add</hlx-button
              >
              <hlx-button
                v-else
                class="primary sm"
                @click="editPaymentMethodDiscount"
                >Save</hlx-button
              >
            </div>
            <div
              v-show="
                isPaymentMethodBasedDiscount &&
                (!discountNewDataEmit.validFor.endDate ||
                  !discountNewDataEmit.name || endDateBoolean || startDateBoolean)
              "
            >
              <hlx-button
                v-if="editMode == false"
                class="primary sm"
                :disabled="true"
                >Add</hlx-button
              >
              <hlx-button v-else class="primary sm" :disabled="true"
                >Save</hlx-button
              >
            </div>
            <div
              v-show="
                isRoleBasedDiscount &&
                discountNewDataEmit.validFor.endDate &&
                discountNewDataEmit.name && !endDateBoolean && !startDateBoolean
              "
            >
              <hlx-button
                v-if="editMode == false"
                class="primary sm"
                @click="addRoleBasedDiscount"
                >Add</hlx-button
              >
              <hlx-button
                v-else
                class="primary sm"
                @click="editRoleBasedDiscount"
                >Save</hlx-button
              >
            </div>
            <div
              v-show="
                isRoleBasedDiscount &&
                (!discountNewDataEmit.validFor.endDate ||
                  !discountNewDataEmit.name || endDateBoolean || startDateBoolean)
              "
            >
              <hlx-button
                v-if="editMode == false"
                class="primary sm"
                :disabled="true"
                >Add</hlx-button
              >
              <hlx-button v-else class="primary sm" :disabled="true"
                >Save</hlx-button
              >
            </div>
            <div
              v-show="
                isRecurringDiscount &&
                discountNewDataEmit.validFor.endDate &&
                discountNewDataEmit.name && 
                discountNewDataEmit.appliesTo.length>0 && !endDateBoolean && !startDateBoolean
              "
            >
              <hlx-button
                v-if="editMode == false"
                class="primary sm"
                @click="addRecurringDiscount"
                >Add</hlx-button
              >
              <hlx-button
                v-else
                class="primary sm"
                @click="editRecurringDiscount"
                >Save</hlx-button
              >
            </div>
            <div
              v-show="
                isRecurringDiscount &&
                (!discountNewDataEmit.validFor.endDate ||
                  !discountNewDataEmit.name || !discountNewDataEmit.appliesTo.length>0 || endDateBoolean || startDateBoolean)
              "
            >
              <hlx-button
                v-if="editMode == false"
                class="primary sm"
                :disabled="true"
                >Add</hlx-button
              >
              <hlx-button v-else class="primary sm" :disabled="true"
                >Save</hlx-button
              >
            </div>
            <!-- <hlx-button v-show="isCustomerDiscount"  class="primary sm">Add</hlx-button> -->
          </div>
        </template>
      </hlx-drawer>
    </section>
  <section>
    <section :style="{display: isDiscountEditable?'flex':'block'}">
      <span class="header" style="margin-bottom: 0px !important">
        <h4>Discounts</h4>
        <span
          v-if="isDiscountEditable"
          style="font-size: 12px; font-family: OpenSans; margin-top: 2px"
        >
          Centralized table for discounts.
        </span>
      </span> 

      <section
        v-if="!isDiscountEditable && tableSearchData.length>0"
        class="search-row"
      >
        <span v-if="isDiscountEditable" class="util-items search">
         <hlx-search :search-style="'style1'" @search-key="searchKey" />
        </span>
        <span class="util-items search">
         <hlx-search   :search-style="'style1'" @search-key="searchKey" />
        </span>
      </section>
    </section>
    <hlx-drawer
              :show="show_right"
              :width="1000"
              position="right"
              :footer="false"
               :show-close-icon="true"
              @close="closeactivitylog"
            >
              <template #body>
                <div class="activitylog_drawer_componet">
                <activityLogComponent
                :key="activtykey"
                  :activity-data="activityLogData"
                  :module="'leftPanal'"
                  :totalfromprop="activitytotal"
                  @activityrowpage="activityrowpage1"
                  @activitycurrentpage="activitycurrentpage1"
                ></activityLogComponent>
              </div>
              </template>
            </hlx-drawer>
    <section v-if="isDiscountEditable" class="search-row">
      <span v-if="isDiscountEditable && tableSearchData.length>0" class="util-items search">
     <hlx-search :search-style="'style1'" @search-key="searchKey" />
      </span>
      <span  v-if="isDiscountEditable && tableSearchData.length>0"  @click="activityLogDrawer()"
        ><i
          class="icon-time-forward-regular"
          style="font-size: 24px;
    color: rgb(84, 189, 149);
    margin-left: 14px;
"
        ></i
      ></span>
      <span   v-if="isDiscountEditable && tableSearchData.length>0" class="filter-table"
        ><button
          id="hlx-button-0009"
          type="button"
          name="hlx-button"
          class="hlx-button secondary sm add-btn filterbutton"
          aria-label="Button"
          value="Hlx click :)"
          @click="openConcurrencyDiscount()"
        >
          <i class="icon-tool-regular"></i>
        </button>
      </span>
      <span  v-if="isDiscountEditable && tableSearchData.length>0" class="filter-table"
        ><button
          id="hlx-button-0009"
          type="button"
          name="hlx-button"
          class="hlx-button secondary sm add-btn filterbutton"
          aria-label="Button"
          value="Hlx click :)"
        >
          <i class="icon-filter-regular"></i></button
      ></span>

      <!-- v-if="isDiscountEditable && tableData.length > 0" -->
      <button
      v-show="permisstionData.add "
      v-if="isDiscountEditable && tableSearchData.length > 0"
        id="hlx-button-0009"
        type="button"
        name="hlx-button"
        class="hlx-button primary sm add-btn"
        aria-label="Button"
        value="Hlx click :)"
        @click="openAddDiscountDrawer()"
      >
        <i class="icon-plus-circle-regular" style="margin-right: 5px"></i>Add
        discount
      </button>
    </section>
    <!-- Illustration for discount flow -->
    <section
      v-if="tableSearchData.length <= 0 && searchValue === ''"
      class="--illustration-container"
    >
      <img
        style="width: 508px; margin-top: 102px"
        src="@\assets\images\svg\Discounts illustraton.svg"
      />
      <hlx-button
        v-if="isDiscountEditable"
        v-show="permisstionData.add "
        class="primary sm add-btn"
        style="display: flex; align-items: center; gap: 0.5rem; margin-top: 4%"
        @click="openAddDiscountDrawer()"
        ><i class="icon-plus-circle-regular" style="margin-top: 5px"></i>Add
        discount</hlx-button
      >
    </section>
    <!-- table -->
    <div v-if="tableDataOnSelectedRows.length > 0">
      <section class="discount-table">
        <hlx-table
          :column-count="tableHeader?.length"
          :border="['table', 'header', 'horizontal']"
          :bold-headers="false"
          :row-hover="true"
          theme="grey"
          :striped-rows="false"
          :re-arrange-columns="re_arrange"
           :re-arrange-heads="tableHeader_cc"
           @sorting_func="sorting_Data"
          @close-rearrange="re_arrange =false"
            @apply-rearranged-columns="applyColumns"
        >
          <template #thead>
            <hlx-table-head
              :width="60"
              :align="'center'"
              style="
                padding: 0px 15px !important;
                min-width: 60px !important;
                max-width: 60px !important;
              "
              :fixed="'left'"
              @new-resize-width="resizedWidth"
            >
              {{ "S.no" }}</hlx-table-head
            >

            <hlx-table-head
              v-for="(i, index) in tableHeader"
              :key="index"
              :width="i.width"
              :sortable="i.sortable"
              :resizable="i.resizable"
              @new-resize-width="resizedWidth"
              >{{ i.label }}
              <!-- {{"hi"}} -->
            </hlx-table-head>
            <hlx-table-head
              v-if="isDiscountEditable && permisstionData.edit || permisstionData.delete"
              
              :width="60"
              :fixed="'right'"
              style="
                padding: 0 15px;
                min-width: 60px !important;
                max-width: 60px !important;
                "
              @new-resize-width="resizedWidth"
              ><span class="action-gear">
                <i class="icon-settings-regular" @click="reAssignOrgTableHeader()"></i></span
            ></hlx-table-head>
          </template>
          <!-- <template #tbody>
        <tr v-for="(data,index) in tableData" :key="index">
            <hlx-table-cell :align="'center'" :fixed="'left'">
            {{ serialNumber(index) }}
            </hlx-table-cell>
        </tr>
   </template> -->
          <template #tbody>
            <tr
              v-for="(i, index) in tableDataOnSelectedRows"
              id=""
              :key="index"
            >
              <hlx-table-cell :align="'center'" :fixed="'left'">
                {{ serialNumberAlter(index) }}
              </hlx-table-cell>
              <hlx-table-cell
                v-for="(j, col_index) in tableHeader"
                :key="col_index"
                :align="j.align"
                :fixed="j.fixed"
              >
                <div v-if="j.prop === 'id'">
                  {{ i[j.prop] }}
                </div>
                <div v-if="j.prop === 'name'">
                  {{ i[j.prop] ? i[j.prop].charAt(0).toUpperCase() + i[j.prop].slice(1) : '' }}
                </div>

                <!--  class="link-name" clicklable -->
                <!-- <div v-else-if="j.prop === 'description'" @click="viewPage(i)">
                {{ i[j.prop].charAt(0).toUpperCase() + i[j.prop].slice(1) }}
              </div> -->

                <div v-else-if="j.prop.toLowerCase() === 'type'">
                  <span v-if="i[j.prop] && i[j.prop].includes(':')">
                    {{ "Customer discount" }}
                  </span>
                  <div v-else>
                    {{ i[j.prop] }}
                  </div>
                </div>

                <div v-else-if="j.prop.toLowerCase() === 'lifecyclestate'">
                  <div>
                    <hlx-status :type="i[j.prop]"></hlx-status>
                  </div>
                </div>

                <div v-else-if="j.label.toLowerCase() === 'start date'">
                  <div>
                    {{ formatDate(i[j.prop]?.startDate) }}
                  </div>
                </div>

                <div v-else-if="j.label.toLowerCase() === 'end date'">
                  <div>
                    {{ formatDate(i[j.prop]?.endDate) }}
                  </div>
                </div>
                <!-- Check for the more specific condition first -->
                <div
                  v-else-if="
                    j.prop.toLowerCase() === 'discounttype' &&
                    i.type === 'Role discount'
                  "
                >
                  <div
                    v-if="
                      i.maximumDiscountRules[0]?.name ===
                      'Percentage applicable to total quote'
                    "
                  >
                    {{ i[j.prop]?.value + "%" }}
                  </div>
                  <div
                    v-if="
                      i.maximumDiscountRules[0]?.name ===
                      'Maximum Discount value'
                    "
                  >
                    {{
                      computedProductPriceAdditionalCharges(i[j.prop]?.value)
                    }}
                  </div>
                </div>

                <!-- Check for the more general condition next -->
                <div v-else-if="j.prop.toLowerCase() === 'discounttype'">
                  <div v-if="i[j.prop]?.name === 'Percentage'">
                    {{ i[j.prop]?.value + "%" }}
                  </div>
                  <div v-if="i[j.prop]?.name === 'Fixed'">
                    {{
                      computedProductPriceAdditionalCharges(i[j.prop]?.value)
                    }}
                  </div>
                  <div v-if="i['type'].toLowerCase() == 'recurring discount'">
                    <section v-for="(val , ind) in i?.recurringChargePeriod" :key="ind">
                        <span style="display: flex;margin-top: 8px;margin-bottom: 6px;">
                          {{val?.discount + "%"}}
                          <hlx-label class="primary sm">  {{ val?.billingperiod }} 
  {{ val?.billingperiod <= 1 
      ? (val?.words?.toLowerCase() === "months" ? "Month" 
      : val?.words?.toLowerCase() === "years" ? "Year" 
      : val?.words?.toLowerCase() === "weeks" ? "Week" 
      : "")
      : val?.words
  }}</hlx-label>
                        </span>
                    </section>
                  </div>
                </div>

                <!-- <div v-else-if="j.prop.toLowerCase() === 'enddate'">
                <div>
                  {{ convertdateformat(i[j.prop]) }}
                </div>
              </div> -->
                <!-- <div v-else>
                {{ i[j.prop] }}
              </div> -->
              </hlx-table-cell>
              <hlx-table-cell
                v-if="isDiscountEditable"
                v-show="permisstionData.edit || permisstionData.delete"
                :align="'center'"
                :width="120"
                :fixed="'right'"
              >
                <span
                  :id="'table-context' + serialNumberAlter(index - 1)"
                  class="action-icon"
                >
                  <i
                    style="position: relative"
                    class="icon-more-vertical-filled"
                    :class="i.context === true ? 'active-action' : ''"
                    @click="
                      crudContextMenu($event, serialNumberAlter(index - 1))
                    "
                  >
                    <hlx-context-menu
                      :top="containertop"
                      :left="containerleft"
                      :conditionvalue="150"
                      :conditiondown="2"
                      :conditionup="-38"
                      :options="computedTableAction(i)"
                      :data="index.toString()"
                      :show="i.context == true"
                      @chosen="closeCrudContextMenu($event, i, index)"
                    />
                  </i>
                </span>
              </hlx-table-cell>
              <!-- <hlx-table-cell
                v-if="!isDiscountEditable"
                :align="'center'"
                :width="120"
                :fixed="'right'"
              >
                <span
                  :id="'table-context' + serialNumberAlter(index - 1)"
                  class="action-icon"
                >
                  <i
                    style="position: relative"
                    class="icon-more-vertical-filled"
                    :class="i.context === true ? 'active-action' : ''"
                    @click="
                      crudContextMenu($event, serialNumberAlter(index - 1))
                    "
                  >
                    <hlx-context-menu
                      :top="containertop"
                      :left="containerleft"
                      :conditionvalue="150"
                      :conditiondown="2"
                      :conditionup="-38"
                      :options="computedTableActionNotEditable(i)"
                      :data="index.toString()"
                      :show="i.context == true"
                      @chosen="closeCrudContextMenu($event, i, index)"
                    />
                  </i>
                </span>
              </hlx-table-cell> -->
            </tr>
          </template>
        </hlx-table>
      </section>
    </div>

      <div
        v-if="tableDataOnSelectedRows.length === 0 && searchValue !== ''"
        class="cm-accounts-right-panel-top-section-table-wrap"
      >
        <hlx-table
          :column-count="tableHeader?.length"
          :border="['table', 'header']"
          :bold-headers="false"
          :row-hover="true"
          theme="grey"
          :striped-rows="false"
          :re-arrange-columns="re_arrange"
           :re-arrange-heads="tableHeader_cc"
           @sorting_func="sorting_Data"
          @close-rearrange="re_arrange =false"
            @apply-rearranged-columns="applyColumns"
        >
          <template #thead>
            <hlx-table-head :width="60" :align="'left'"  @new-resize-width="resizedWidth">{{
              "S.no"
            }}</hlx-table-head>
            <hlx-table-head
              v-for="(i, index) in tableHeader"
              :key="index"
              :width="i.width"
              :sortable="i.sortable"
              :resizable="i.resizable"
               @new-resize-width="resizedWidth"
              >{{ i.label }}</hlx-table-head
            >
            <hlx-table-head v-if="permisstionData.edit || permisstionData.delete" :width="100">
              <span
v-if="permisstionData.edit || permisstionData.delete"
              class="action-icon" ><i
                  class="icon-settings-regular"
                ></i></span
            ></hlx-table-head>
          </template>
          <template #tbody>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell :colspan="tableHeader?.length + 2">{{
                "No data"
              }}</hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
            <tr>
              <hlx-table-cell></hlx-table-cell>
            </tr>
          </template>
        </hlx-table>
      </div>
  </section>
  <!-- Pagination -->
  <div
    v-if="tableData?.length > 0"
    class="discount-pagination-container"
    style="padding: 1rem 0"
  >
    <hlx-pagination
      v-model:rows-per-page="externalRowsPerPage"
      :total="tableData?.length"
      enable-rows-per-page
      :rows-per-page-list="[10, 15, 20]"
      :options="{
        attributes: {
          rowsPerPageTitle: true,
          rowsPerPageResult: true,
          rowsPerPageDropdown: {
            position: {
              top: 0,
              right: 1,
            },
          },
        },
      }"
      @current-page="currentPage"
      @updated:rows-per-page="changeRowsPerPage"
    ></hlx-pagination>
  </div>
</template>

<script>
import {
  // DMNAxiosInstance,
  MLTAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";
import searchfilterComponent from "@/components/SearchAndFilterComponent/searchAndFilter.vue";
import activityLogComponent from "@/components/SM/activityLogComponent.vue";

export default {
  components: {
    searchfilterComponent,
    activityLogComponent
  },
  props: {
    showContextMenu:{
      type:Boolean,
      default:true,
    },
    permisstionData: {
      type: Object,
      default: function () {
        return {
          add : true,
          edit: true,
          view: true,
          delete: true,
         
        };
      },
    },
    isDiscountEditable: {
      type: Boolean,
      default: false,
    },
    discountHeight: {
      type: String,
      default: "76vh",
    },
    discountData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: [
    "edit-discount-data",
    "atclick-edit-button",
    "new-discount-data",
    "delete-discount",
  ],
  data() {
    return {
      notification: {},
      isContextMenuVisible:true,
      tableHeaderOrg:[],
      tableHeaderOrgcc:[],
      logpayload:{
        type: "filter",
        module: "log",
        lastUpdated: new Date().toJSON(),
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {
          category: {
            $in: ["discount"],
          },
        },
        searchQuery: "",
      },
      re_arrange:false,
      tableSearchData:[],
      openEditMode:false,
      preValOffers:[],
      disableSaveButton:false,
      startDateBoolean:false,
      endDateBoolean:false,
      endDateMsg:"",
      startDateMsg:"",
      updateKeyInfo: 1,
      updateKeyContra: 2,
      updateKeyNew: 3,
      updateKeyRecurr: 4,
      currency: this.$store?.getters?.getDefaultSettings?.currency?.symbol,
      editMode: false,
      checkedvalue: {},
      activtykey:0,
      // mainInfo: [],
      accountPreValueNew: [],
      accountPreValue: [],
      mainInfoProduct: [],
      mainRecurringProduct: [],
      mainInfoNewDiscount: [],
      mainInfoContractual: [],
      company: "",
      role_data: [
        {
          name: "Upc admin",
          value: "Upc admin",
          type: "partyRole",
          id: "65d34902237a9575f79cf80e",
          checked: false,
          disabled: false,
        },
        {
          name: "Business admin",
          value: "Business admin",
          id: "65d34902237a9575f79cf90e",
          type: "partyRole",
          checked: false,
          disabled: false,
        },
      ],
      role_data_Duplicate: [
        {
          name: "Upc admin",
          value: "Upc admin",
          type: "partyRole",
          id: "65d34902237a9575f79cf80e",
          checked: false,
          disabled: false,
        },
        {
          name: "Business admin",
          value: "Business admin",
          id: "65d34902237a9575f79cf90e",
          type: "partyRole",
          checked: false,
          disabled: false,
        },
      ],
      rolePercentageDropdown: [
        {
          name: "Maximum Discount value",
          value: "Maximum Discount value",
          checked: false,
          disabled: false,
        },
        {
          name: "Percentage applicable to total quote",
          value: "Percentage applicable to total quote",
          checked: false,
          disabled: false,
        },
      ],
      rolePercentageDropdownDuplicate: [
        {
          name: "Maximum Discount value",
          value: "Maximum Discount value",
          checked: false,
          disabled: false,
        },
        {
          name: "Percentage applicable to total quote",
          value: "Percentage applicable to total quote",
          checked: false,
          disabled: false,
        },
      ],
      concurrencyDiscountRadioExclusive: [
        { name: "Exclusive", checked: true, disabled: false },
      ],
      concurrencyDiscountRadioBest: [
        { name: "Best price", checked: false, disabled: false },
      ],
      concurrencyDiscountRadioCompounded: [
        { name: "Compounded", checked: false, disabled: false },
      ],
      filterLayout: {
        // "@baseType": "supplier",
        layoutFor: "productoffering",
        filterQuery: {
          id: {
            $nin: [],
          },
        },
        layout: [
          {
            prop: "lifecycleStatus",
            label: "Status",
            type: "String",
            fieldToFilter: "lifecycleStatus",
          },
          {
            prop: "category",
            label: "Category",
            type: "Array",
            // fieldToFilter: "name"
          },
          {
            prop: "productOfferingPrice",
            label: "price",
            type: "Array",
            fieldToFilter: "name",
          },
        ],
      },
      layout: [
        {
          prop: "lifecycleStatus",
          label: "Status",
          type: "String",
          fieldToFilter: "lifecycleStatus",
          fieldToSearch: "lifecycleStatus",
        },
        {
          prop: "category",
          label: "Category",
          type: "Array",
          fieldToFilter: "category",
        },
        {
          prop: "productOfferingPrice",
          label: "price",
          type: "Array",
          fieldToFilter: "productOfferingPrice.name",
          fieldToSearch:
            "productOfferingPrice[0].productofferPriceAlteration[0].productprice.taxAmount.Amount",
        },
      ],
      discountNewDataEmit: {
        // type:"",
        //  name:"",
        // description:"",
        // discountStatus:"",
        // discountStartDate:"",
        // discountEndDate:"",
        // discountMaxRules:"",
        // discountType:"",
        // discountAppliesTo:[],
        // discountApplyTo:"",
        // discountAccountDetails:{
        //   "Account":[],
        //   "Contract":[]
        // }
        recurringChargePeriod: [],
        relatedParty: [],
        description: "",
        type: "",
        name: "",
        lifecycleState: "Draft",
        validFor: {
          startDate: new Date(),
          endDate: "9999-12-31T00:00:00Z",
        },
        maximumDiscountRules: [],
        minimumDiscountRules: [],
        discountType: { name: "", value: "" },
        extarctedType: "",
        appliesTo: [],
        applyTo: [],
        isSpecified: false,
        specifiedAccounts: [],
      },
      discountNullData: {
        recurringChargePeriod: [],
        relatedParty: [],
        description: "",
        type: "",
        name: "",
        lifecycleState: "Draft",
        validFor: { startDate: new Date(), endDate: "9999-12-31T00:00:00Z" },
        maximumDiscountRules: [],
        minimumDiscountRules: [],
        discountType: { name: "", value: "" },
        extarctedType: "",
        appliesTo: [],
        applyTo: [],
        isSpecified: false,
        specifiedAccounts: [],
      },
      filterChecked: [
        {
          key: "Multi device feature",
          fieldtoFilter: "multidevicefeature.mediumtype",
        },
        {
          key: "Environment",
          fieldtoFilter: "environment.mediumtype",
        },
        {
          key: "color",
          fieldtoFilter: "color.mediumtype",
        },
        {
          key: "Ports",
          fieldtoFilter: "ports.mediumtype",
        },
      ],
      isRecurringTable: false,
      discountValue: 0,
      isCheckboxInput: false,
      // selectedButton: 2,
      componentKey: 0,
      updateComponentKey: 0,
      isModal: false,
      isNewDiscount: false,
      isContratualDiscount: false,
      isFooterDiscount: false,
      isDiscountIcons: true,
      isCustomerDiscount: false,
      isCardBasedDiscount: false,
      isProductDiscount: false,
      isPaymentMethodBasedDiscount: false,
      isRoleBasedDiscount: false,
      isRecurringDiscount: false,
      isDiscountConcurrency: false,
      currPage: 1,
      rowsPerPage: 10,
      externalRowsPerPage: 10,
      newDiscountRadiobutton: [
        { name: "All accounts", checked: false, disabled: false },
        { name: "Specific accounts", checked: false, disabled: false },
      ],
      newDiscountRadiobuttonEdit: [
        { name: "All accounts", checked: false, disabled: false },
        { name: "Specific accounts", checked: false, disabled: false },
      ],
      newDiscountRadiobuttonDuplicate: [
        { name: "All accounts", checked: false, disabled: false },
        { name: "Specific accounts", checked: false, disabled: false },
      ],
      account_data: [],
      account_data_new: [],
      account_dataEdit: [],
      account_dataDummy: [],
      pre_value: ["Fixed", "Percentage"],
      dropdown_data: [
        { name: "Fixed", value: "Fixed", id: 1 },
        { name: "Percentage", value: "Percentage", id: 2 },
      ],
      dropdown_data_contract: [
        {
          name: "Consulting Services Agreement",
          value: "Consulting Services Agreement",
          checked: false,
        },
        {
          name: "Sales and Purchase Agreement",
          value: "Sales and Purchase Agreement",
          checked: false,
        },
        {
          name: "Employee Confidentiality Agreement",
          value: "Employee Confidentiality Agreement",
          checked: false,
        },
        {
          name: "Supply Chain Partnership Agreement",
          value: "	Supply Chain Partnership Agreement",
          checked: false,
        },
      ],
      dropdown_data_contractEdit: [
        {
          name: "Consulting Services Agreement",
          value: "Consulting Services Agreement",
          checked: false,
        },
        {
          name: "Sales and Purchase Agreement",
          value: "Sales and Purchase Agreement",
          checked: false,
        },
        {
          name: "Employee Confidentiality Agreement",
          value: "Employee Confidentiality Agreement",
          checked: false,
        },
        {
          name: "Supply Chain Partnership Agreement",
          value: "	Supply Chain Partnership Agreement",
          checked: false,
        },
      ],
      dropdown_data_contractDummy: [
        { name: "Fixed", value: "Fixed", checked: false },
        { name: "Percentage", value: "Percentage", checked: false },
        { name: "Fixed", value: "Fixed", checked: false },
        { name: "Percentage", value: "Percentage", checked: false },
      ],
      dropdown_dataEdit: [
        { name: "Fixed", value: "Fixed", id: 1 },
        { name: "Percentage", value: "Percentage", id: 2 },
      ],
      dropdown_dataDuplicate: [
        { name: "Fixed", value: "Fixed", id: 1 },
        { name: "Percentage", value: "Percentage", id: 2 },
      ],
      paymentMethodTypes: [
        { name: "Cash on delivery", value: "Cash on delivery", id: 3 },
        { name: "Credit card", value: "Credit card", id: 4 },
      ],
      paymentMethodTypesDuplicate: [
        {
          name: "Cash on delivery",
          value: "Cash on delivery",
          id: 1,
          checked: false,
          disabled: false,
        },
        {
          name: "Credit card",
          value: "Credit card",
          id: 2,
          checked: false,
          disabled: false,
        },
      ],
      paymentDiscountCheckbox: [
        { name: "All payment type", checked: false, disabled: false },
        { name: "Specific payment type", checked: false, disabled: false },
      ],
      paymentDiscountCheckboxDuplicate: [
        { name: "All payment type", checked: false, disabled: false },
        { name: "Specific payment type", checked: false, disabled: false },
      ],
      ProductDiscountCheckbox: [
        {
          name: "Limit to one use per customer",
          checked: false,
          disabled: false,
        },
        {
          name: "Limit number of times this discount can be used in total",
          checked: false,
          disabled: false,
        },
      ],
      ProductDiscountCheckboxDuplicate: [
        {
          name: "Limit to one use per customer",
          checked: false,
          disabled: false,
        },
        {
          name: "Limit number of times this discount can be used in total",
          checked: false,
          disabled: false,
        },
      ],
      activityLogData:[],
      cardBasedDiscountRadiobutton1: [
        { name: "No minimum requirement", checked: false, disabled: false },
        { name: "Minimum cart subtotal", checked: false, disabled: false },
      ],
      cardBasedDiscountRadiobutton1Duplicate: [
        { name: "No minimum requirement", checked: false, disabled: false },
        { name: "Minimum cart subtotal", checked: false, disabled: false },
      ],
      cardBasedDiscountRadiobutton2: [
        { name: "Apply on cart subtotal", checked: false, disabled: false },
        {
          name: "Apply on lowest price product in the cart",
          checked: false,
          disabled: false,
        },
        {
          name: "Apply to highest priced product in the cart",
          checked: false,
          disabled: false,
        },
      ],
      cardBasedDiscountRadiobutton2Duplicate: [
        { name: "Apply on cart subtotal", checked: false, disabled: false },
        {
          name: "Apply on lowest price product in the cart",
          checked: false,
          disabled: false,
        },
        {
          name: "Apply to highest priced product in the cart",
          checked: false,
          disabled: false,
        },
      ],
      ProductDiscountRadiobutton: [
        { name: "No minimum quantity", checked: false, disabled: false },
        { name: "Set minimum quantity", checked: false, disabled: false },
      ],
      ProductDiscountRadiobuttonDuplicates: [
        { name: "No minimum quantity", checked: false, disabled: false },
        { name: "Set minimum quantity", checked: false, disabled: false },
      ],
      statusData: [
        {
          name: "Active",
          value: "Active",
          color: "#007247",
        },
        {
          name: "Inactive",
          value: "Inactive",
          color: "#ff5050",
        },
        {
          name: "Pending",
          value: "Pending",
          color: "#f8a523",
        },
        {
          name: "Draft",
          value: "Draft",
          color: "#2879b7",
        },
      ],
      searchValue: "",
      monthBilling: "",
      weekBilling: "",
      monthDiscount: "",
      weekDiscount: "",
      recurringTableData: [
        {
          billingtype: "Monthly",
          billingperiod: "0",
          discount: "0",
          words: "Months",
        },
        {
          billingtype: "Weekly",
          billingperiod: "0",
          discount: "0",
          words: "Weeks",
        },
      ],
      recurringTableDataDuplicate: [
        {
          billingtype: "Monthly",
          billingperiod: "0",
          discount: "0",
          words: "Months",
        },
        {
          billingtype: "Weekly",
          billingperiod: "0",
          discount: "0",
          words: "Weeks",
        },
      ],
      recurringTable: [
        {
          label: "Billing period",
          prop: "billingtype",
          sortable: false,
          resizable: false,
          width: 120,
          align: "left",
        },
        {
          label: "Commitment length",
          prop: "billingperiod",
          sortable: false,
          resizable: false,
          width: 160,
          align: "left",
        },
        {
          label: "Discount",
          prop: "discount",
          sortable: false,
          resizable: false,
          width: 100,
          align: "left",
        },
      ],
      tableHeader_cc:[
      {
          checked:true,
          name:"Name",
          label: "Name",
          prop: "name",
          sortable: false,
          disabled:true,
          resizable: false,
          width: null,
          align: "left",
        },

        {
          checked:true,
          name:"Type",
          label: "Type",
          prop: "type",
          sortable: false,
          resizable: false,
          width: 220,
          align: "left",
        },
        {
          checked:true,
          name:"Status",
          label: "Status",
          prop: "lifecycleState",
          disabled:true,
          sortable: false,
          resizable: false,
          width: 120,
          align: "left",
        },
        {
          checked:true,
          name:"Start date",
          label: "Start date",
          prop: "validFor",
          sortable: false,
          resizable: false,
          width: 220,
          align: "left",
        },
        {
          checked:true,
          name:"End date",
          label: "End date",
          prop: "validFor",
          sortable: false,
          resizable: false,
          width: 220,
          align: "left",
        },
        {
          checked:true,
          name:"Discount value",
          label: "Discount value",
          prop: "discountType",
          sortable: false,
          disabled:true,
          resizable: false,
          width: 120,
          align: "left",
        },
      ],
      tableHeader: [
        {
          checked:true,
          name:"Name",
          label: "Name",
          prop: "name",
          sortable: false,
          disabled:true,
          resizable: false,
          width: null,
          align: "left",
        },

        {
          checked:true,
          name:"Type",
          label: "Type",
          prop: "type",
          sortable: false,
          resizable: false,
          width: 220,
          align: "left",
        },
        {
          checked:true,
          name:"Status",
          label: "Status",
          prop: "lifecycleState",
          disabled:true,
          sortable: false,
          resizable: false,
          width: 120,
          align: "left",
        },
        {
          checked:true,
          name:"Start date",
          label: "Start date",
          prop: "validFor",
          sortable: false,
          resizable: false,
          width: 220,
          align: "left",
        },
        {
          checked:true,
          name:"End date",
          label: "End date",
          prop: "validFor",
          sortable: false,
          resizable: false,
          width: 220,
          align: "left",
        },
        {
          checked:true,
          name:"Discount value",
          label: "Discount value",
          prop: "discountType",
          sortable: false,
          disabled:true,
          resizable: false,
          width: 120,
          align: "left",
        },
      ],
      show_right:false,
      isCustomerDiscountNewEdit: false,
      isCustomerDiscountEdit: false,
      discountTypePercentageRole: true,
      discountTypeFixedRole: false,
      discountTypeFixedProduct: false,
      discountTypePercentageProduct: true,
      discountTypeFixed: false,
      discountTypePercentage: true,
      radiovalue: {},
      searchFilterData: {},
      productDiscountInput: "",
      productDiscountDescription: "",
      offeringcurrPage: 1,
      offeringrowsPerPage: 10,
      tableData: [],
      checkboxInputValue: 0,
      RadiobuttonProductBasedInput: 0,
      RadiobuttonCardBasedInput: 0,
      isCheckboxPaymentDiscount: false,
      isRadiobuttonNewDiscountMultiselect: false,
      isRadiobuttonProductBasedInput: false,
      isRadiobuttonCardBasedInput: false,
      checkboxEditArray: [],
    };
  },
  computed: {
    tableDataOnSelectedRows() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
  },
  watch: {
    showContextMenu:{
      handler(val) {
        this.isContextMenuVisible = val
      },
      immediate: true,
      deep: true,
    },
    "discountNewDataEmit.discountType.value":function(newVal) {
      if (newVal < 0) {
      this.discountNewDataEmit.discountType.value = 0; 
    }
    },
    discountData: {
      handler(val) {
        console.log(val, "discoiunt data ");
        this.tableData = val;
        this.tableSearchData=val
      },
      immediate: true,
      deep: true,
    },
    // mainInfo: {
    //   handler(val) {
    //     this.mainInfo = val;
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    mainInfoProduct: {
      handler(val) {
        this.mainInfoProduct = val;
      },
      immediate: true,
      deep: true,
    },
    mainRecurringProduct: {
      handler(val) {
        console.log("val mainRecurringProduct", val);
        this.mainRecurringProduct = val;
      },
      immediate: true,
      deep: true,
    },
    mainInfoNewDiscount: {
      handler(val) {
        this.mainInfoNewDiscount = val;
      },
      immediate: true,
      deep: true,
    },
    mainInfoContractual: {
      handler(val) {
        this.mainInfoContractual = val;
      },
      immediate: true,
      deep: true,
    },
    checkedvalue: {
      handler(val) {
        this.checkboxEditArray = [];
        this.discountNewDataEmit.maximumDiscountRules = [];
        let checkboxArray = val;
        this.checkboxEditArray = val;
        console.log("checkboxArray", checkboxArray);
        console.log("checkboxArray", typeof checkboxArray);
        if (Array.isArray(checkboxArray)) {
          for (const item of checkboxArray) {
            if (item.checked === true) {
              console.log("checkboxArray for", item);
              this.discountNewDataEmit.maximumDiscountRules.push(item);
              console.log("checkboxArray for", this.discountNewDataEmit);
              // if(item.name==="Limit number of times this discount can be used in total"){
              //   this.isCheckboxInput = true;
              // }
            }
          }
        } else {
          console.log("checkboxArray is not an array:", checkboxArray);
        }
        this.discountNewDataEmit.maximumDiscountRules.push({ value: 0 });
        this.isCheckboxInput =
          this.discountNewDataEmit.maximumDiscountRules.some(
            (e) =>
              e.name ===
              "Limit number of times this discount can be used in total"
          );
      },
      immediate: true,
      deep: true,
    },
    radiovalue: {
      handler(val) {
        console.log("radioValue", val);
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
// To get table header
    let sampleTheads = await MLTAxiosInstance.get(`/settings`);

if (sampleTheads?.data?.entityRef?.["productDiscountComp"]) {
  let offerTableHeadModal = sampleTheads.data.entityRef["productDiscountComp"];
  let offerTableHeadOrg = sampleTheads.data.entityRef["productDiscountComp"].filter(ele => ele.checked);
  console.log(offerTableHeadOrg,"offerTableHeadModal",offerTableHeadModal)

  if (
    offerTableHeadModal.length ===  this.tableHeader_cc.length 
    // &&
    // offerTableHeadModal.every((item, index) => item.name ===  this.tableHeader_cc[index].name)
  ) {
    this.tableHeader_cc = JSON.parse(JSON.stringify(offerTableHeadModal));
    this.tableHeader = JSON.parse(JSON.stringify(offerTableHeadOrg));
    this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.tableHeader_cc ))
    this.tableHeaderOrg = JSON.parse(JSON.stringify(this.tableHeader))
  }

}

    this.supplierFilterData();
    console.log("Additional charges mounted");
    this.searchFilterData = await this.supplierFilterData();
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");
    this.user = val.data;
    this.company = val.data.company;
    console.log("fulltabledata", this.company);
    this.selectButton(2);
    this.selectButton(1);
    this.selectButton(2);
    const fulltableData = {
      company: this.company,
      type: "filter",
      module: "hierarchycontacts",
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      lastUpdated: new Date().toJSON(),
      sortFieldAndOrder: {},
      limit: 2,
      filterQuery: {
        "@baseType": {
          $ne: "supplier",
        },
      },
      searchQuery: "Active",
    };
    const fulltabledata = await MLTAxiosInstance.post(
      `/util/filterData?company=${this.company}`,
      fulltableData
    );
    console.log("fulltabledata", fulltabledata);
    this.account_data = fulltabledata.data.data.map((e) => ({
      id: e.id,
      name: e.familyName,
      checked: false,
      value: e.familyName,
    }));
    this.getSearchedValueRecurringDiscount();
    this.account_data_new = JSON.parse(JSON.stringify(this.account_data));
    this.account_dataEdit = JSON.parse(JSON.stringify(this.account_data));
    this.account_dataDummy = JSON.parse(JSON.stringify(this.account_data));
    this.accountPreValue = this.account_dataEdit.map((e) => e.name);
    this.accountPreValueNew = this.account_dataEdit.map((e) => e.name);
    console.log("fulltabledata", this.account_data);
    console.log("fulltabledata", this.account_data_new);
    console.log("fulltabledata 3", this.accountPreValue);
    this.activiylogcall();
  },
  methods: {
    reAssignOrgTableHeader(){
              // If there is change in the table header then ressign to the Orginal data
              if((this.tableHeader_cc != this.tableHeaderOrgcc) && this.tableHeaderOrgcc.length > 0)
    {
      this.tableHeader_cc = JSON.parse(JSON.stringify(this.tableHeaderOrgcc))
    }
    if((this.tableHeader != this.tableHeaderOrg) && this.tableHeaderOrg.length > 0)
    {
      this.tableHeader = JSON.parse(JSON.stringify(this.tableHeaderOrg))
    }
      this.re_arrange=true
    },
    resizedWidth(val){
      console.log(val,"resizedWidth",val)

      const updateWidth = (array) => {
    let item = array.find(i => i?.prop === val?.prop);
    if (item) item.width = val.width;
  };

  updateWidth(this.tableHeader);
  updateWidth(this.tableHeader_cc);
console.log(this.tableHeader,"resizedWidth loop",this.tableHeader_cc)
this.applyColumns(this.tableHeader_cc)
    },
   async applyColumns(val){
      console.log("applyColumns",val)
      let sampleTheads = await MLTAxiosInstance.get(`/settings`);
let concatedEntity = sampleTheads?.data?.entityRef
concatedEntity.productDiscountComp=val
      // }
      let reArrangeTableHeadPayload = { entityRef: concatedEntity };
      
const changeTableHeader = await MLTAxiosInstance.patch(`/settings`,reArrangeTableHeadPayload);
console.log(changeTableHeader,"reArrangeTableHeadPayload",reArrangeTableHeadPayload)
let sampleTheadsRefresh = await MLTAxiosInstance.get(`/settings`);
console.log("sampleTheadsRefresh",sampleTheadsRefresh?.data)
console.log("sampleTheadsRefresh mountes",sampleTheadsRefresh?.data?.entityRef)
this.tableHeader_cc = sampleTheadsRefresh?.data?.entityRef["productDiscountComp"]
this.tableHeader = sampleTheadsRefresh?.data?.entityRef["productDiscountComp"].filter(ele=>ele.checked)
this.tableHeaderOrgcc = JSON.parse(JSON.stringify(this.tableHeader_cc ))
this.tableHeaderOrg = JSON.parse(JSON.stringify(this.tableHeader))
    },
    formatDate(val){
      if(val){
        const date = new Date(val);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
      }
    },
    focusOutStartDate(date) {
      console.log(date,"product discount");
      if (!date) {
        this.startDateBoolean = true;
        this.startDateMsg = "Please enter the date";
      }

      this.dateValidation();
    },
    dateValidation(){
      if (
          !this.endDateBoolean &&
          !this.startDateBoolean &&
          this.discountNewDataEmit.validFor?.startDate &&
          this.discountNewDataEmit.validFor?.startDate
        ) {
          console.log("yes");
          this.disableSaveButton = false;
        } else {
          console.log("no else");

          this.disableSaveButton = true;
        }
    },
    // extractNumber(val){
    //   if (typeof val !== 'string') {
    //     console.error('Value is not a string');
    //     return val;
    // }

    // const numbers = val.match(/\d+(\.\d+)?/g);
    // return numbers ? numbers.join('') : '';
    // },
    closeactivitylog() {
      this.show_right = false;
    },
    async activitycurrentpage1(val) {
      console.log(val, "activitycurrentpage1..........");
      this.activitycurrentpage = val;
      console.log(val, "current");
      this.logpayload.paginatedQuery.currentPage = val;
      let activitylogdata = await MLTAxiosInstance.post(
        `util/filterData`,
        this.logpayload
      );
      console.log(
        activitylogdata.data.data,
        "123 activity log response data in offer2 current"
      );

      this.activityLogData = activitylogdata.data.data;
      this.activitytotal = activitylogdata.data.total;
    },
    async activityrowpage1(val) {

      this.activityrowpage = val;
      
      this.logpayload.paginatedQuery.rowsPerPage = val;
      let activitylogdata = await MLTAxiosInstance.post(
        `util/filterData`,
        this.logpayload
      );
      this.activityLogData = activitylogdata.data.data;
    
      this.activitytotal = activitylogdata.data.total;
    },
    async activiylogcall() {

      const logaccess = await MLTAxiosInstance.post( 
        `/util/filterData`,
        this.logpayload
      );
      this.activityLogData = logaccess.data.data;
      this.activitytotal = logaccess.data.total;
      console.log(logaccess.data.data);
    },
    activityLogDrawer() { 
      this.activiylogcall();
      this.show_right = true;
      this.activtykey+=1
    },
    getTypedVal(event) {
      console.log("getTypedVal", event);
    },
    emits(val) {
      console.log("emits", val);
    },
    async search(val) {
      console.log("search...............................................", val);
      this.searchValue = val;
      const searchResponse = await UPCAxiosInstance.get(
        `/discount/inlineSearch?search=${val}`
      );
      this.tableData = searchResponse.data;
    },
    searchKey(val) {
      this.searchValue = val;
      if (val) {
        this.search = true;
        const result = this.searchNestedObjects(this.tableSearchData,val.toLowerCase());
        this.tableData = result;
      } else {
        this.search = false;
        this.tableData = this.tableSearchData;
      }
    },
    searchNestedObjects(objects, key) {
  return objects.reduce((acc, obj) => {
    const foundInObject = Object.entries(obj).some(([k, v]) => {
      if (typeof v === "object" && v !== null) {
        return this.searchNestedObjects([v], key).length > 0;
      } 
      if (typeof v === "string" && k !== "password") {
        if (Date.parse(v)) {
          const formattedDate = this.formatDate(v).toLowerCase();
          return formattedDate.includes(key);
        }
        return v.toLowerCase().includes(key);
      }

      if (typeof v === "number") {
        return v.toString().includes(key);
      }

      return false;
    });

    if (foundInObject) {
      acc.push(obj);
    }

    return acc;
  }, []);
},
    computedProductPriceAdditionalCharges(offer) {
      if (offer) {
        return this.currencyFormatter(offer, {
          currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
        });
      }

      return this.currencyFormatter(0);
    },
    openConcurrencyDiscount() {
      this.isModal = true;
      this.isDiscountConcurrency = true;
      this.isDiscountIcons = false;
      this.isRadiobuttonNewDiscountMultiselect = false;
      (this.isCardBasedDiscount = false),
        (this.isProductDiscount = false),
        (this.isPaymentMethodBasedDiscount = false),
        (this.isRoleBasedDiscount = false),
        (this.isRecurringDiscount = false);
      this.isCustomerDiscount = false;
    },
    // concatDiscount() {
    //   const discountName = this.discountNewDataEmit.discountType.name;
    //   const discountValue = this.discountNewDataEmit.discountType.value;
    //   console.log("discountValue",discountValue)
    //   console.log("discountValue",discountName)
    //   if (discountName === "Percentage") {
    //     return this.discountNewDataEmit.discountType.value=discountValue+"%"
    //   }
    //   if(discountName==="Fixed"){
    //     this.discountNewDataEmit.discountType.value = this.discountNewDataEmit.discountType.value !== undefined && this.discountNewDataEmit.discountType.value !== null ? this.discountNewDataEmit.discountType.value : 0;

    //     return this.discountNewDataEmit.discountType.value= this.currencyFormatter(
    //       this.discountNewDataEmit.discountType.value,
    //         { currency: this.$store?.getters?.getDefaultSettings?.currency
    //                   ?.unit }
    //       );
    //   }

    //   console.log("discountValue",discountValue)
    // },

    getcheckedValueProductDiscount(value) {
      this.discountNewDataEmit.appliesTo = value;
    },
    removecheckedValueRecurringDiscount(value) {
      console.log("value", value);
      this.discountNewDataEmit.appliesTo = [];
    },
    getcheckedValueRecurringDiscount(value) {
      this.discountNewDataEmit.appliesTo = [];
      console.log("value", value);
      this.discountNewDataEmit.appliesTo.push(value);

      this.isRecurringTable = value.checked === true;
      console.log("value", this.discountNewDataEmit);
    },
    getcheckedValueContractualDiscount(value) {
      this.discountNewDataEmit.appliesTo = value;
    },
    getcheckedValueNewDiscount(value) {
      this.discountNewDataEmit.appliesTo = value;
    },
    newDiscountType(val) {
      console.log("ContractualDiscountType", val);
      this.discountTypeFixed = val.name === "Fixed" ? true : false;
      this.discountTypePercentage = val.name !== "Fixed" ? true : false;
    },
    contractualDiscountType(val) {
      console.log("ContractualDiscountType", val);
      this.discountTypeFixed = val.name === "Fixed" ? true : false;
      this.discountTypePercentage = val.name !== "Fixed" ? true : false;
    },
    productDiscountType(val) {
      console.log("ContractualDiscountType", val);
      this.discountTypeFixedProduct = val.name === "Fixed" ? true : false;
      this.discountTypePercentageProduct = val.name !== "Fixed" ? true : false;
    },

    leaf(text) {
      let lengthOfText = text?.length;
      if (typeof text === "string") {
        return text;
      }
      if (lengthOfText === 1) {
        return (text = text.toString());
      }
      if (text && lengthOfText > 1) {
        const total = text?.length;
        // console.log(
        //   text,
        //   "leaf category text length final o/p",
        //   text[total - 1]
        // );

        return text[total - 1];
      }
    },
    // async getSearchedValue(value) {
    //   try {
    //     const filterPostQuery = await UPCAxiosInstance.post(
    //       `util/filterData`,
    //       value
    //     );

    //     const responseData = filterPostQuery.data.data;
    //     // console.log(responseData,"responseData")
    //     // console.log(responseData,"responseData 2")
    //     this.mainInfo = responseData.map(
    //       ({ name, productOfferingPrice, id, category }) => {
    //         const categoryType = this.leaf(category ? category : "");
    //         let price = "0";
    //         let unit;

    //         if (productOfferingPrice?.length > 0) {
    //           const productPrice = productOfferingPrice[0].productPrice;
    //           if (productPrice) {
    //             price = `${productPrice.dutyFreeAmount || "0"} ${
    //               productPrice.unit || ""
    //             }`;
    //             unit = productPrice.unit || "";
    //           }
    //           price = price !== undefined ? price : 0;
    //           unit = unit !== undefined ? unit : unit;
    //           return {
    //             name: name,
    //             price: this.currencyFormatter(price, { currency: unit }),
    //             _id: id,
    //             category: categoryType,
    //           };
    //         } else {
    //           return {
    //             name: name,
    //             price: this.currencyFormatter(0),
    //             _id: id,
    //             category: categoryType,
    //           };
    //         }
    //       }
    //     );

    //     // console.log(this.productName,"productName")
    //     // console.log(this.relatedProducts,"this.relatedProducts")
    //     // console.log(this.mainInfo,"this.relatedProducts 1")
    //   } catch {
    //     console.log("error from getsearchedvalue");
    //   }
    // },
    getMinMax(arr) {
      if (!arr || arr.length === 0) {
        return this.currencyFormatter(0, {
          currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
        });
      }

      let values = arr.map((item) => item?.value);
      let min = Math.min(...values);
      let max = Math.max(...values);
      min = min !== undefined && min !== null && !isNaN(min) ? min : 0;
      max = max !== undefined && max !== null && !isNaN(max) ? max : 0;
      if (min === max) {
        if (min == 0) {
          return this.currencyFormatter(0, {
            currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          });
        }
        return this.currencyFormatter(min, { currency: arr[0]?.unit });
        // return `  ${min} ${arr[0]?.unit}`;
      } else {
        if (min == 0) {
          return `${this.currencyFormatter(0, {
            currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          })} - ${this.currencyFormatter(max, { currency: arr[0]?.unit })}`;
        }
        if (max == 0) {
          return `${this.currencyFormatter(min, {
            currency: arr[0]?.unit,
          })}-${this.currencyFormatter(0, {
            currency: this.$store?.getters?.getDefaultSettings?.currency?.unit,
          })}`;
        }
        return `${this.currencyFormatter(min, {
          currency: arr[0]?.unit,
        })} - ${this.currencyFormatter(max, { currency: arr[0]?.unit })}`;
      }
    },
    async getSearchedValueContractualDiscount(value) {
      console.log("getSearchedValueRecurringDiscount", value);
      try {
        const filterPostQuery = await UPCAxiosInstance.post(
          `util/filterData`,
          value
        );

        const responseData = filterPostQuery.data.data;
        // console.log(responseData,"responseData")
        // console.log(responseData,"responseData 2")
        this.mainInfoContractual = responseData.map(
          ({
            name,
            productSpecification,
            productOfferingPrice,
            prodSpecCharValue,
            id,
            category,
          }) => {
            const productType = productSpecification["@baseType"];
            const categoryType = this.leaf(category ? category : "");
            let price = "0";
            let unit;

            if (productOfferingPrice?.length > 0) {
              // const productPrice = productOfferingPrice[0]?.tiers;
              const updatedProductPrice = productOfferingPrice;
              if (updatedProductPrice) {
                let priceRange = updatedProductPrice
                  ?.map((e) => e?.tiers?.map((tier) => tier?.price))
                  .flat(Infinity);
                price = this.getMinMax(priceRange);
                unit = priceRange[0]?.unit;
              }
              price =
                price !== undefined || price !== null || !isNaN(price)
                  ? price
                  : 0;

              unit = unit !== undefined || unit !== null ? unit : unit;
              console.log(price, "prodSpecCharValue", unit);
              return {
                name: name,
                price:
                  prodSpecCharValue[0]?.billingPeriod !== undefined &&
                  prodSpecCharValue[0]?.billingPeriod !== null
                    ? productType === "Tangible"
                      ? price
                      : price + ` / ${prodSpecCharValue[0]?.billingPeriod}`
                    : price + ` / ${""}`,
                _id: id,
                category: categoryType,
              };
            } else {
              return {
                name: name,
                price:
                  prodSpecCharValue[0]?.billingPeriod !== undefined &&
                  prodSpecCharValue[0]?.billingPeriod !== null
                    ? productType === "Tangible"
                      ? this.currencyFormatter(0)
                      : this.currencyFormatter(0) +
                        ` / ${prodSpecCharValue[0]?.billingPeriod}`
                    : this.currencyFormatter(0) + ` / ${""}`,
                _id: id,
                category: categoryType,
              };
            }
          }
        );

        // console.log(this.productName,"productName")
        // console.log(this.relatedProducts,"this.relatedProducts")
        // console.log(this.mainInfo,"this.relatedProducts 1")
      } catch {
        console.log("error from getsearchedvalue");
      }
    },
    async getSearchedValueRecurringDiscount() {
      const val = {
        type: "filter-search",
        module: "productoffering",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {},
        searchQuery: "",
        company: this.company,
      };
      try {
        const filterPostQuery = await UPCAxiosInstance.post(
          `util/filterData`,
          val
        );

        const responseData = filterPostQuery.data.data;
        // console.log(responseData,"responseData")
        // console.log(responseData,"responseData 2")
        this.mainRecurringProduct = responseData.map(
          ({ name, productOfferingPrice, id, category }) => {
            const categoryType = this.leaf(category ? category : "");
            let price = "0";
            let unit;

            if (productOfferingPrice?.length > 0) {
              // const productPrice = productOfferingPrice[0]?.tiers;
              const updatedProductPrice = productOfferingPrice;
              if (updatedProductPrice) {
                let priceRange = updatedProductPrice
                  ?.map((e) => e?.tiers?.map((tier) => tier?.price))
                  .flat(Infinity);
                price = this.getMinMax(priceRange);
                unit = priceRange[0]?.unit;
              }
              price =
                price !== undefined || price !== null || !isNaN(price)
                  ? price
                  : 0;

              unit = unit !== undefined || unit !== null ? unit : unit;
              console.log(price, "prodSpecCharValue", unit);
              return {
                name: name,
                price: price,
                _id: id,
                category: categoryType,
              };
            } else {
              return {
                name: name,
                price: this.currencyFormatter(0),
                _id: id,
                category: categoryType,
              };
            }
          }
        );

        // console.log(this.productName,"productName")
        // console.log(this.relatedProducts,"this.relatedProducts")
        // console.log(this.mainInfo,"this.relatedProducts 1")
      } catch {
        console.log("error from getsearchedvalue");
      }
    },
    async getSearchedValueProductDiscount(value) {
      try {
        const filterPostQuery = await UPCAxiosInstance.post(
          `util/filterData`,
          value
        );
        console.log(
          filterPostQuery.data.data,
          "responseData",
          filterPostQuery.data.data.length
        );
        const responseData = filterPostQuery.data.data.filter((e) => {
          return e.productSpecification["@baseType"] === "Tangible";
        });

        // responseData = filterPostQuery.data.data;
        console.log(responseData, "responseData", responseData.length);
        // console.log(responseData,"responseData 2")
        this.mainInfoProduct = responseData.map(
          ({
            name,
            productOfferingPrice,
            productSpecification,
            id,
            category,
          }) => {
            const categoryType = this.leaf(category ? category : "");
            let price = "0";
            let unit;
            const productType = productSpecification["@baseType"];
            console.log("productType", productType);
            if (productOfferingPrice?.length > 0) {
              // const productPrice = productOfferingPrice[0]?.tiers;
              // const updatedProductPrice =productOfferingPrice[0]?.tiers;
              const updatedProductPrice = productOfferingPrice;
              if (updatedProductPrice) {
                let priceRange = updatedProductPrice
                  ?.map((e) => e?.tiers?.map((tier) => tier?.price))
                  .flat(Infinity);
                price = this.getMinMax(priceRange);
                unit = priceRange[0]?.unit;
              }

              price =
                price !== undefined || price !== null || !isNaN(price)
                  ? price
                  : 0;

              unit = unit !== undefined || unit !== null ? unit : unit;
              console.log(price, "prodSpecCharValue", unit);
              return {
                name: name,
                price: price,
                _id: id,
                category: categoryType,
              };
            } else {
              return {
                name: name,
                price: this.currencyFormatter(0),
                _id: id,
                category: categoryType,
              };
            }
          }
        );

        // console.log(this.productName,"productName")
        // console.log(this.relatedProducts,"this.relatedProducts")
        // console.log(this.mainInfo,"this.relatedProducts 1")
      } catch (err) {
        console.log(err, "error from getsearchedvalue");
      }
    },
    async getSearchedValueNewDiscount(value) {
      try {
        const filterPostQuery = await UPCAxiosInstance.post(
          `util/filterData`,
          value
        );

        const responseData = filterPostQuery.data.data;
        // console.log(responseData,"responseData")
        // console.log(responseData,"responseData 2")
        this.mainInfoNewDiscount = responseData.map(
          ({
            name,
            productSpecification,
            prodSpecCharValue,
            productOfferingPrice,
            id,
            category,
          }) => {
            const productType = productSpecification["@baseType"];
            const categoryType = this.leaf(category ? category : "");
            let price = "0";
            let unit;

            if (productOfferingPrice?.length > 0) {
              // const productPrice = productOfferingPrice[0]?.tiers;
              // const updatedProductPrice =productOfferingPrice[0]?.tiers;
              const updatedProductPrice = productOfferingPrice;
              if (updatedProductPrice) {
                let priceRange = updatedProductPrice
                  ?.map((e) => e?.tiers?.map((tier) => tier?.price))
                  .flat(Infinity);
                price = this.getMinMax(priceRange);
                unit = priceRange[0]?.unit;
              }
              price =
                price !== undefined || price !== null || !isNaN(price)
                  ? price
                  : 0;

              unit = unit !== undefined || unit !== null ? unit : unit;
              console.log(price, "prodSpecCharValue", unit);
              return {
                name: name,
                price:
                  prodSpecCharValue[0]?.billingPeriod !== undefined &&
                  prodSpecCharValue[0]?.billingPeriod !== null
                    ? productType === "Tangible"
                      ? price
                      : price + ` / ${prodSpecCharValue[0]?.billingPeriod}`
                    : price + ` / ${""}`,
                _id: id,
                category: categoryType,
              };
            } else {
              return {
                name: name,
                price:
                  prodSpecCharValue[0]?.billingPeriod !== undefined &&
                  prodSpecCharValue[0]?.billingPeriod !== null
                    ? productType === "Tangible"
                      ? this.currencyFormatter(0)
                      : this.currencyFormatter(0) +
                        ` / ${prodSpecCharValue[0]?.billingPeriod}`
                    : this.currencyFormatter(0) + ` / ${""}`,
                _id: id,
                category: categoryType,
              };
            }
          }
        );

        // console.log(this.productName,"productName")
        // console.log(this.relatedProducts,"this.relatedProducts")
        // console.log(this.mainInfo,"this.relatedProducts 1")
      } catch {
        console.log("error from getsearchedvalue");
      }
    },
    async supplierFilterData() {
      try {
        console.log("kjhfj");
        const supplierFilterRes = await UPCAxiosInstance.post(
          "util/filterLayout",
          this.filterLayout
        );
        // state.suppliersFilterData = supplierFilterRes.data;
        console.log(
          "productRecommed 3.0 supplierfilterdata ",
          supplierFilterRes
        );
        console.log("productRecommed 3.0 2", supplierFilterRes.data);

        return await supplierFilterRes.data;
      } catch (error) {
        console.error(error);
      }
    },
    async closeCrudContextMenu($event, ele, i) {
      if ($event.toLowerCase() === "edit") {
        this.editMode = true;
        this.show_upload_drawer = true;
        let orginalCopy = await JSON.parse(JSON.stringify({ ...ele }));
        console.log("openuploaddraweredit - originalCopy", orginalCopy);
        this.$emit("atclick-edit-button", orginalCopy);
        console.log("openuploaddraweredit - initial ele", ele);

        const clonedEle = JSON.parse(JSON.stringify(ele));
        console.log("openuploaddraweredit - cloned ele", clonedEle);

        this.additionalChargesDataQuery = clonedEle;
        console.log(
          "openuploaddraweredit - additionalChargesDataQuery",
          this.additionalChargesDataQuery
        );

        this.opendiscountedit(await clonedEle);
      } else if ($event.toLowerCase() === "delete") {
        this.delete_modal_active = true;
        console.log("Delete Edit emit ", $event, "event", "ele", ele, "i", i);

        // this.deleteobj = ele;
        this.$emit("delete-discount", ele);
        // this.delete_name = ele.name;
        // this.deleterowindex = this.serialNumber(i);
      }
    },
    opendiscountedit(ele) {
      this.openEditMode=true
      console.log(
        "this.ProductDiscountRadiobutton",
        this.ProductDiscountRadiobutton
      );
      console.log("openuploaddraweredit ele ", ele);
      this.isCheckboxInput = ele.maximumDiscountRules.some(
        (rule) =>
          rule.name ===
          "Limit number of times this discount can be used in total"
      );
      this.isRadiobuttonProductBasedInput = ele.minimumDiscountRules.some(
        (rule) => rule.name === "Set minimum quantity"
      );
      this.isRadiobuttonCardBasedInput = ele.minimumDiscountRules.some(
        (rule) => rule.name === "Minimum cart subtotal"
      );
      console.log(
        "this.ProductDiscountRadiobutton",
        this.ProductDiscountRadiobutton
      );
      switch (`${ele.type}:${ele.extarctedType}`) {
        case "Product offering:":
          this.discountNewDataEmit = ele;
          this.isNewDiscount = false;
          this.isCardBasedDiscount = false;
          this.isPaymentMethodBasedDiscount = false;
          this.isCustomerDiscount = false;
          this.isRoleBasedDiscount = false;
          this.isRecurringDiscount = false;
          console.log(
            "error form minimumDiscountRules 1 Product offering",
            ele.type
          );
          this.openProductDiscount(ele);
          break;
        case "Cart based discount:":
          this.discountNewDataEmit = ele;
          this.isNewDiscount = false;
          this.isProductDiscount = false;
          this.isPaymentMethodBasedDiscount = false;
          this.isCustomerDiscount = false;
          this.isRoleBasedDiscount = false;
          this.isRecurringDiscount = false;
          console.log(
            "error form minimumDiscountRules 2 Cart based discount",
            ele.type
          );
          this.openCardBasedDiscountEdit(ele);
          break;
        case "Customer discount:New discount":
          console.log(
            "error form minimumDiscountRules 2 new discount",
            ele.type
          );
          this.discountNewDataEmit = ele;
          this.isCardBasedDiscount = false;
          this.isRoleBasedDiscount = false;
          this.isRecurringDiscount = false;
          this.isPaymentMethodBasedDiscount = false;
          this.isProductDiscount = false;
          this.openCustomerNewDiscountEdit(ele);
          break;
        case "Customer discount:Contractual discount":
          console.log(
            "error form minimumDiscountRules 2 contra discount",
            ele.type
          );
          this.discountNewDataEmit = ele;
          this.isCardBasedDiscount = false;
          this.isRoleBasedDiscount = false;
          this.isRecurringDiscount = false;
          this.isPaymentMethodBasedDiscount = false;
          this.isProductDiscount = false;
          this.openCustomerContractualDiscountEdit(ele);
          break;
        case "Role discount:":
          console.log(
            "error form minimumDiscountRules 2 new discount",
            ele.type
          );
          this.discountNewDataEmit = ele;
          this.isProductDiscount = false;
          this.isCardBasedDiscount = false;
          this.isRecurringDiscount = false;
          this.isPaymentMethodBasedDiscount = false;
          this.openRoleBasedDiscountEdit(ele);
          break;
        case "Payment discount:":
          console.log(
            "error form minimumDiscountRules 2 new discount",
            ele.type
          );
          this.discountNewDataEmit = ele;
          this.isProductDiscount = false;
          this.isCardBasedDiscount = false;
          this.isRecurringDiscount = false;
          this.isRoleBasedDiscount = false;
          this.openPaymentMethodDiscountEdit(ele);
          break;
        case "Recurring discount:":
          console.log(
            "error form minimumDiscountRules 2 new discount",
            ele.type
          );
          this.discountNewDataEmit = ele;
          this.componentKey += 1;
          this.isProductDiscount = false;
          this.isCardBasedDiscount = false;
          this.isPaymentMethodBasedDiscount = false;
          this.isRoleBasedDiscount = false;
          this.openRecurrringDiscountEdit(ele);
          break;
      }
    },
    async openRecurrringDiscountEdit(ele) {
      this.ProductDiscountCheckbox = await JSON.parse(
        JSON.stringify(this.ProductDiscountCheckboxDuplicate)
      );
      // this.mainRecurringProduct.forEach((val) => {
      //   val.checked = false;
      // });
      this.isRecurringTable = ele.recurringChargePeriod.length > 0;
      this.recurringTableData = ele.recurringChargePeriod;

      this.ProductDiscountCheckbox.forEach((element) => {
        ele?.maximumDiscountRules.forEach((e) => {
          if (element.name === e.name) {
            element.checked = true;
          }
        });
      });

      // ele?.appliesTo.forEach((checkedItem) => {
      //   const matchingItem = this.mainRecurringProduct.find(
      //     (item) => item._id === checkedItem._id
      //   );
      //   if (matchingItem) {
      //     matchingItem.checked = true;
      //   }
      // });
      this.preValOffers= ele.appliesTo
      this.isDiscountConcurrency = false;
      this.isModal = true;
      this.isFooterDiscount = true;
      this.isProductDiscount = false;
      this.isNewDiscount = false;
      this.isContratualDiscount = false;
      this.isDiscountIcons = false;
      this.isCustomerDiscount = false;
      this.isRecurringDiscount = true;
    },
    openPaymentMethodDiscountEdit(ele) {
      this.paymentMethodTypes.forEach((item) => {
        ele?.minimumDiscountRules.forEach((e) => {
          if (item.name === e.name) {
            item.checked = true;
            item.disabled = true;
          } else {
            item.checked = false;
          }
        });
      });

      this.paymentDiscountCheckbox.forEach((element) => {
        ele?.maximumDiscountRules.forEach((e) => {
          if (element.name === e.name) {
            element.checked = true;
            if (e.name === "Specific payment type") {
              this.isCheckboxPaymentDiscount = true;
            }
          } else {
            element.checked = false;
          }
        });
      });
      console.log("paymentMethodTypes", this.paymentMethodTypes);

      this.isModal = true;
      this.isFooterDiscount = true;
      this.isProductDiscount = false;
      this.isNewDiscount = false;
      this.isContratualDiscount = false;
      this.isPaymentMethodBasedDiscount = true;
      this.isDiscountIcons = false;
      this.isCustomerDiscount = false;
      this.isDiscountConcurrency = false;
    },
    openRoleBasedDiscountEdit(ele) {
      console.log(ele, "openRoleBasedDiscountEdit");
      this.role_data.forEach((element) => {
        ele?.relatedParty.forEach((e) => {
          if (element.name === e.name) {
            element.checked = true;
          }
        });
      });

      this.rolePercentageDropdown.forEach((element) => {
        ele?.maximumDiscountRules.forEach((e) => {
          if (element.name === e.name) {
            element.checked = true;
          }
        });
      });
      this.discountTypeFixedRole =
        ele.maximumDiscountRules[0].name === "Maximum Discount value"
          ? true
          : false;
      this.discountTypePercentageRole =
        ele.maximumDiscountRules[0].name !== "Maximum Discount value"
          ? true
          : false;

      console.log(
        "ProductDiscountCheckbox",
        this.ProductDiscountCheckbox,
        this.dropdown_data
      );
      // console.log("Product offering", ele, this.mainInfo);
      // this.discountNewDataEmit=ele
      console.log("Product offering", this.discountNewDataEmit);
      this.isModal = true;
      this.isFooterDiscount = true;
      this.isNewDiscount = false;
      this.isProductDiscount = false;
      this.isContratualDiscount = false;
      this.isRoleBasedDiscount = true;
      this.isDiscountIcons = false;
      this.isDiscountConcurrency = false;
      this.isCustomerDiscount = false;
      console.log("Product offering", ele.type);
      console.log("discountNewDataEmit", this.discountNewDataEmit);
    },
    openCustomerNewDiscountEdit(ele) {
      this.selectButton(2);
      this.selectButton(1);
      this.selectButton(2);
      this.newDiscountRadiobutton = JSON.parse(
        JSON.stringify(this.newDiscountRadiobuttonDuplicate)
      );
      this.account_data_new = JSON.parse(JSON.stringify(this.account_dataEdit));
      this.mainInfoNewDiscount.forEach((val) => {
        val.checked = false;
      });
      // ele?.appliesTo.forEach((checkedItem) => {
      //   const matchingItem = this.mainInfoNewDiscount.find(
      //     (item) => item._id === checkedItem._id
      //   );
      //   if (matchingItem) {
      //     console.log("yes it matched");
      //     matchingItem.checked = true;
      //   }
      // });
      this.preValOffers= ele?.appliesTo
      this.newDiscountRadiobutton.forEach((element) => {
        if (ele.isSpecified) {
          if (element.name === "Specific accounts") {
            element.checked = true;
          }
        } else {
          if (element.name === "All accounts") {
            element.checked = true;
          }
        }
        if (ele.isSpecified) {
          this.account_data_new = this.account_data_new.map((item) => ({
            ...item,
            checked: false,
          }));

          this.account_data_new = this.account_data_new.map((item) => {
            const selectedItem = ele.relatedParty.find(
              (selected) => selected.id === item.id
            );
            if (selectedItem) {
              this.isRadiobuttonNewDiscountMultiselect = true;
              console.log(selectedItem, "selectedItem");
              item.checked = true;
            }
            return item;
          });
        }
        if (!ele.isSpecified) {
          this.account_data_new = JSON.parse(
            JSON.stringify(this.account_dataEdit)
          );
          this.isRadiobuttonNewDiscountMultiselect = false;
          ele.relatedParty = [];
        }

        console.log("match", this.account_data_new);

        this.dropdown_data.forEach((item) => {
          if (this.discountNewDataEmit.discountType.name.includes(item.name)) {
            item.checked = true;
          }
        });
      });
      this.discountTypeFixed = ele.discountType.name === "Fixed" ? true : false;
      this.discountTypePercentage =
        ele.discountType.name !== "Fixed" ? true : false;
      // console.log("Product offering", ele, this.mainInfo);
      console.log("Product offering", this.discountNewDataEmit);
      this.isModal = true;
      this.isCustomerDiscount = true;
      this.isFooterDiscount = true;
      this.isContratualDiscount = true;
      this.isCustomerDiscountEdit = true;
      this.isDiscountIcons = false;
      this.isDiscountConcurrency = false;
      this.isNewDiscount = false;
      this.isCustomerDiscountNewEdit = false;
    },
    openCustomerContractualDiscountEdit(ele) {
      this.selectButton(1);
      this.dropdown_data_contract = JSON.parse(
        JSON.stringify(this.dropdown_data_contractEdit)
      );
      console.log("yes it matched", ele);
      this.account_data = JSON.parse(JSON.stringify(this.account_dataEdit));
      this.mainInfoContractual.forEach((val) => {
        val.checked = false;
      });
      // ele?.appliesTo.forEach((checkedItem) => {
      //   const matchingItem = this.mainInfoContractual.find(
      //     (item) => item._id === checkedItem._id
      //   );
      //   if (matchingItem) {
      //     console.log("yes it matched");
      //     matchingItem.checked = true;
      //   }
      // });
      this.preValOffers= ele.appliesTo
      this.account_data.forEach((element) => {
        ele?.relatedParty.forEach((e) => {
          if (element.name === e.name) {
            element.checked = true;
          }
        });
      });

      this.dropdown_data_contract.forEach((element) => {
        ele?.minimumDiscountRules.forEach((e) => {
          if (element.name === e.name) {
            element.checked = true;
          }
        });
      });

      console.log("match", this.account_data);

      this.dropdown_data.forEach((item) => {
        if (this.discountNewDataEmit.discountType.name.includes(item.name)) {
          item.checked = true;
        }
      });

      this.discountTypeFixed = ele.discountType.name === "Fixed" ? true : false;
      this.discountTypePercentage =
        ele.discountType.name !== "Fixed" ? true : false;
      console.log("Product offering", ele, this.mainInfoContractual);
      console.log("Product offering", this.discountNewDataEmit);
      this.isModal = true;
      this.isCustomerDiscount = true;
      this.isFooterDiscount = true;
      this.isContratualDiscount = false;
      this.isNewDiscount = true;
      this.isCustomerDiscountEdit = false;
      this.isCustomerDiscountNewEdit = true;
      this.isDiscountIcons = false;
      this.isDiscountConcurrency = false;
    },
    async openProductDiscount(ele) {
      console.log(
        "this.ProductDiscountRadiobutton",
        this.ProductDiscountRadiobuttonDuplicates
      );
      this.ProductDiscountRadiobutton = await JSON.parse(
        JSON.stringify(this.ProductDiscountRadiobuttonDuplicates)
      );
      this.ProductDiscountCheckbox = await JSON.parse(
        JSON.stringify(this.ProductDiscountCheckboxDuplicate)
      );
      console.log(
        "this.ProductDiscountRadiobutton",
        this.ProductDiscountRadiobutton
      );
      this.mainInfoProduct.forEach((val) => {
        val.checked = false;
      });
      this.isNewDiscount = false;
      this.isContratualDiscount = false;
      // ele?.appliesTo.forEach((checkedItem) => {
      //   const matchingItem = this.mainInfoProduct.find(
      //     (item) => item._id === checkedItem._id
      //   );
      //   if (matchingItem) {
      //     matchingItem.checked = true;
      //   }
      // });
      this.preValOffers= ele?.appliesTo
      this.ProductDiscountCheckbox.forEach((element) => {
        ele?.maximumDiscountRules.forEach((e) => {
          if (element.name === e.name) {
            element.checked = true;
          }
        });
      });

      this.ProductDiscountRadiobutton.forEach((element) => {
        ele?.minimumDiscountRules.forEach((e) => {
          if (element.name === e.name) {
            console.log("e", e);
            element.checked = true;
          }
        });
      });
      console.log(
           this.preValOffers,"ProductDiscountRadiobutton",
        this.ProductDiscountRadiobutton
      );
      this.dropdown_data.forEach((item) => {
        if (this.discountNewDataEmit.discountType.name.includes(item.name)) {
          item.checked = true;
        }
      });
      this.discountTypeFixed = ele.discountType.name === "Fixed" ? true : false;
      this.discountTypePercentage =
        ele.discountType.name !== "Fixed" ? true : false;
      console.log(
        "ProductDiscountCheckbox",
        this.ProductDiscountCheckbox,
        this.dropdown_data
      );
      // console.log("Product offering", ele, this.mainInfo);
      // this.discountNewDataEmit=ele
      console.log("Product offering", this.discountNewDataEmit);
      this.isModal = true;
      this.isFooterDiscount = true;
      this.isProductDiscount = true;
      this.isDiscountConcurrency = false;
      this.isDiscountIcons = false;
      console.log("Product offering", ele.type);
      console.log("discountNewDataEmit", this.discountNewDataEmit);
    },
    async openCardBasedDiscountEdit(ele) {
      console.log(
        "this.ProductDiscountRadiobutton",
        this.ProductDiscountCheckboxDuplicate
      );
      this.ProductDiscountCheckbox = await JSON.parse(
        JSON.stringify(this.ProductDiscountCheckboxDuplicate)
      );
      this.cardBasedDiscountRadiobutton1 = await JSON.parse(
        JSON.stringify(this.cardBasedDiscountRadiobutton1Duplicate)
      );
      this.cardBasedDiscountRadiobutton2 = await JSON.parse(
        JSON.stringify(this.cardBasedDiscountRadiobutton2Duplicate)
      );
      console.log(
        "this.ProductDiscountRadiobutton",
        this.ProductDiscountCheckbox
      );
      this.isNewDiscount = false;
      this.isContratualDiscount = false;

      this.ProductDiscountCheckbox.forEach((element) => {
        ele?.maximumDiscountRules.forEach((e) => {
          if (element.name === e.name) {
            element.checked = true;
          }
        });
      });
      this.cardBasedDiscountRadiobutton2.forEach((element) => {
        ele?.appliesTo.forEach((e) => {
          if (element.name === e.name) {
            element.checked = true;
          }
        });
      });
      this.cardBasedDiscountRadiobutton1.forEach((element) => {
        ele?.minimumDiscountRules.forEach((e) => {
          if (element.name === e.name) {
            element.checked = true;
          }
        });
      });

      this.dropdown_data.forEach((item) => {
        if (this.discountNewDataEmit.discountType.name.includes(item.name)) {
          item.checked = true;
        }
      });
      this.discountTypeFixed = ele.discountType.name === "Fixed" ? true : false;
      this.discountTypePercentage =
        ele.discountType.name !== "Fixed" ? true : false;
      console.log(
        "ProductDiscountCheckbox",
        this.ProductDiscountCheckbox,
        this.dropdown_data
      );
      // console.log("Product offering", ele, this.mainInfo);
      // this.discountNewDataEmit=ele
      console.log("Product offering", this.discountNewDataEmit);
      this.isModal = true;
      this.isFooterDiscount = true;
      this.isCardBasedDiscount = true;
      this.isDiscountConcurrency = false;
      this.isDiscountIcons = false;
      console.log("Product offering", ele.type);
      console.log("discountNewDataEmit", this.discountNewDataEmit);
    },
    editProductDiscount() {
      // this.concatDiscount()
      this.$emit(
        "edit-discount-data",
        JSON.parse(JSON.stringify(this.discountNewDataEmit))
      );
      this.isModal = false;
    },
    editRecurringDiscount() {
      this.$emit(
        "edit-discount-data",
        JSON.parse(JSON.stringify(this.discountNewDataEmit))
      );
      this.isModal = false;
    },
    editCardBasedDiscount() {
      this.$emit(
        "edit-discount-data",
        JSON.parse(JSON.stringify(this.discountNewDataEmit))
      );
      this.isModal = false;
    },
    editPaymentMethodDiscount() {
      this.$emit(
        "edit-discount-data",
        JSON.parse(JSON.stringify(this.discountNewDataEmit))
      );
      this.isModal = false;
    },
    editRoleBasedDiscount() {
      // this.concatDiscount()
      this.$emit(
        "edit-discount-data",
        JSON.parse(JSON.stringify(this.discountNewDataEmit))
      );
      this.isModal = false;
    },
    editNewDiscount() {
      // this.concatDiscount()
      this.$emit(
        "edit-discount-data",
        JSON.parse(JSON.stringify(this.discountNewDataEmit))
      );
      this.isModal = false;
    },
    editContratualDiscount() {
      this.$emit(
        "edit-discount-data",
        JSON.parse(JSON.stringify(this.discountNewDataEmit))
      );
      this.isModal = false;
    },
    emitRadioCardBased(val) {
      if (val.name === "Minimum cart subtotal") {
        this.isRadiobuttonCardBasedInput = true;
        this.discountNewDataEmit.minimumDiscountRules = [];
        this.discountNewDataEmit.minimumDiscountRules.push(val);
        console.log(
          "this.discountNewDataEmit value",
          this.RadiobuttonCardBasedInput
        );
        this.discountNewDataEmit.minimumDiscountRules.push({ value: 0 });
        console.log(
          "Discount status",
          this.discountNewDataEmit.minimumDiscountRules
        );
        return;
      } else {
        this.discountNewDataEmit.minimumDiscountRules = [];
        this.RadiobuttonCardBasedInput = "";
        this.isRadiobuttonCardBasedInput = false;
        this.discountNewDataEmit.minimumDiscountRules.push(val);
        this.discountNewDataEmit.minimumDiscountRules.push({ value: 0 });
      }
    },
    selectedValueNewDiscount(val) {
      this.discountNewDataEmit.relatedParty = [];
      val.forEach((obj) => {
        this.discountNewDataEmit.relatedParty.push(obj);
      });
      console.log("discountNewDataEmit new", this.discountNewDataEmit);
    },
    selectedValueContraDiscount(val) {
      this.discountNewDataEmit.relatedParty = [];
      val.forEach((obj) => {
        this.discountNewDataEmit.relatedParty.push(obj);
      });
      console.log("discountNewDataEmit new", this.discountNewDataEmit);
    },
    selectedValueContraDiscount2(val) {
      this.discountNewDataEmit.minimumDiscountRules = [];

      val.forEach((obj) => {
        this.discountNewDataEmit.minimumDiscountRules.push(obj);
      });
      console.log("discountNewDataEmit new", this.discountNewDataEmit);
    },
    paymentDiscountType(val) {
      console.log("paymentDiscountType", val);
      this.discountNewDataEmit.minimumDiscountRules = [];
      this.discountNewDataEmit.minimumDiscountRules.push(val);
      console.log("discountNewDataEmit new", this.discountNewDataEmit);
    },
    selectedValueRoleBasedDisocunt(val) {
      console.log("selectedValueRoleBasedDisocunt", val);
      (this.discountNewDataEmit.relatedParty = []),
        this.discountNewDataEmit.relatedParty.push(val);
      console.log("discountNewDataEmit new", this.discountNewDataEmit);
    },
    RoleBasedDisocuntType(val) {
      if (val.name === "Maximum Discount value") {
        this.discountTypeFixedRole = true;
        this.discountTypePercentageRole = false;
      } else {
        this.discountTypePercentageRole = true;
        this.discountTypeFixedRole = false;
      }

      console.log("selectedValueRoleBasedDisocunt", val);
      (this.discountNewDataEmit.maximumDiscountRules = []),
        this.discountNewDataEmit.maximumDiscountRules.push(val);
    },
    emitRadioNewDiscount(val) {
      if (val.name === "Specific accounts") {
        this.isRadiobuttonNewDiscountMultiselect = true;
        this.discountNewDataEmit.isSpecified = true;

        console.log("discountNewDataEmit", this.discountNewDataEmit);
        return;
      } else {
        // this.discountNewDataEmit.applyTo=[]
        this.isRadiobuttonNewDiscountMultiselect = false;
        this.discountNewDataEmit.isSpecified = false;
        // this.discountNewDataEmit.applyTo.push(val)
        console.log("discountNewDataEmit", this.discountNewDataEmit);
      }
    },
    emitCardBased(val) {
      this.discountNewDataEmit.appliesTo = [];
      this.discountNewDataEmit.appliesTo.push(val);
    },
    emitRadioProductBased(val) {
      console.log("emitRadioProductBased", val);
      if (val.name === "Set minimum quantity") {
        this.discountNewDataEmit.minimumDiscountRules = [];
        this.isRadiobuttonProductBasedInput = true;
        this.discountNewDataEmit.minimumDiscountRules.push(val);
        this.discountNewDataEmit.minimumDiscountRules.push({ value: 0 });
        console.log(
          "this.discountNewDataEmit value",
          this.RadiobuttonProductBasedInput
        );
        return;
      } else {
        this.discountNewDataEmit.minimumDiscountRules = [];
        this.RadiobuttonProductBasedInput = "";
        this.isRadiobuttonProductBasedInput = false;
        this.discountNewDataEmit.minimumDiscountRules.push(val);
        this.discountNewDataEmit.minimumDiscountRules.push({ value: 0 });
      }
    },
    emitCheckboxPaymentDiscount(val) {
      this.discountNewDataEmit.maximumDiscountRules = [];
      if (val.name === "Specific payment type") {
        this.discountNewDataEmit.maximumDiscountRules.push(val);
        this.isCheckboxPaymentDiscount = true;
        console.log("discountNewDataEmit", this.discountNewDataEmit);
        return;
      } else {
        this.discountNewDataEmit.maximumDiscountRules.push(val);
        this.isCheckboxPaymentDiscount = false;
        console.log("discountNewDataEmit", this.discountNewDataEmit);
        return;
      }
    },
    emitCheckboxRecurring(val) {
      if (
        val.name === "Limit number of times this discount can be used in total"
      ) {
        this.isCheckboxInput = true;
        // this.discountNewDataEmit.maximumDiscountRules.name=val.name
        console.log(
          "Discount status",
          this.discountNewDataEmit.maximumDiscountRules
        );
        return;
      } else {
        // this.discountNewDataEmit.maximumDiscountRules=val.name
        console.log(
          "Discount status",
          this.discountNewDataEmit.maximumDiscountRules
        );
      }
    },
    emitCheckbox(val) {
      console.log("Discount status", val);
      if (
        val.name === "Limit number of times this discount can be used in total"
      ) {
        this.isCheckboxInput = true;
        // this.discountNewDataEmit.maximumDiscountRules.name=val.name
        console.log(
          "Discount status",
          this.discountNewDataEmit.maximumDiscountRules
        );
        return;
      } else {
        this.isCheckboxInput = false;
        // this.discountNewDataEmit.maximumDiscountRules=val.name
        console.log(
          "Discount status",
          this.discountNewDataEmit.maximumDiscountRules
        );
      }
    },
    calenderValueEndDate(val) {
      const endOfLifeDate = new Date(this.discountNewDataEmit.validFor.endDate) .toISOString()
      .split("T")[0];
      const endOfSupportDate = new Date(
        this.discountNewDataEmit.validFor.startDate
      ) .toISOString()
      .split("T")[0];
      if (endOfLifeDate >= endOfSupportDate) {
        this.endDateBoolean = false;
        this.disableSaveButton = false;
        this.startDateBoolean = false;
        console.log(val, "incoming data");
      } else {
        this.disableSaveButton = true;

        this.endDateBoolean = true;
        this.endDateMsg = "End Date cannot be earlier than Start Date.";
        console.log(this.endDateMsg, "this.endDateMsg");
      }
      // this.discountNewDataEmit.validFor.endDate = val;
      console.log("Discount status", val);
      this.dateValidation()
    },
    calenderValueStartDate(val) {
      const endOfLifeDate = new Date(this.discountNewDataEmit.validFor.endDate) .toISOString()
      .split("T")[0];
      const endOfSupportDate = new Date(
        this.discountNewDataEmit.validFor.startDate 
      ) .toISOString()
      .split("T")[0];
      if (endOfSupportDate <= endOfLifeDate) {
        this.startDateBoolean = false;
        this.disableSaveButton = false;
        this.endDateBoolean = false;
        console.log(val, "incoming data if");
      } else {
        this.startDateBoolean = true;
        this.startDateMsg = "Start Date must be before or equal to End Date.";
        console.log(this.startDateMsg, "this.endDateMsg");
      }
      // this.discountNewDataEmit.validFor.startDate = val;
      this.dateValidation()
      console.log("Discount status", val);
    },
    addProductDiscount() {
      // this.concatDiscount()
      console.log("this.discountNewDataEmit.name", this.discountNewDataEmit);
      console.log("this.discountNewDataEmit", this.productDiscountInput);
      this.$emit("new-discount-data", this.discountNewDataEmit);
      this.isModal = false;
    },
    addCardBasedDiscount() {
      console.log("this.discountNewDataEmit.name", this.discountNewDataEmit);
      console.log("this.discountNewDataEmit", this.productDiscountInput);
      this.$emit("new-discount-data", this.discountNewDataEmit);
      this.isModal = false;
    },
    addRecurringDiscount() {
      this.discountNewDataEmit.type = "Recurring discount";
      this.discountNewDataEmit.recurringChargePeriod = this.recurringTableData;
      console.log("this.discountNewDataEmit", this.productDiscountInput);
      this.$emit("new-discount-data", this.discountNewDataEmit);
      this.isModal = false;
    },
    addContratualDiscount() {
      // this.concatDiscount()
      this.discountNewDataEmit.type = "Customer discount";
      this.discountNewDataEmit.extarctedType = "Contractual discount";
      console.log("this.discountNewDataEmit.name", this.discountNewDataEmit);
      console.log("this.discountNewDataEmit", this.productDiscountInput);
      this.$emit("new-discount-data", this.discountNewDataEmit);
      this.isModal = false;
    },
    addNewDiscount() {
      // this.concatDiscount()
      this.discountNewDataEmit.type = "Customer discount";
      this.discountNewDataEmit.extarctedType = "New discount";
      console.log("this.discountNewDataEmit.name", this.discountNewDataEmit);
      console.log("this.discountNewDataEmit", this.productDiscountInput);
      this.$emit("new-discount-data", this.discountNewDataEmit);
      this.isModal = false;
    },
    addPaymentMethodDiscount() {
      this.discountNewDataEmit.type = "Payment discount";
      console.log("this.discountNewDataEmit.name", this.discountNewDataEmit);
      this.$emit("new-discount-data", this.discountNewDataEmit);
      this.isModal = false;
    },
    addRoleBasedDiscount() {
      // this.concatDiscount()
      this.discountNewDataEmit.type = "Role discount";
      this.discountNewDataEmit.extarctedType = "";
      console.log("this.discountNewDataEmit.name", this.discountNewDataEmit);
      console.log("this.discountNewDataEmit", this.productDiscountInput);
      this.$emit("new-discount-data", this.discountNewDataEmit);
      this.isModal = false;
    },
    emitStatus(val) {
      this.discountNewDataEmit.lifecycleState = val.name;
      console.log("Discount status", val);
    },

    serialNumberAlter(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },

    currentPage(val) {
      this.currPage = val;
    },
    changeRowsPerPage(count) {
      this.rowsPerPage = count;
    },
    selectButton(buttonIndex) {
      // this.discountNewDataEmit.validFor.endDate = "9999-12-31T00:00:00Z",
      // this.endDateMsg =""
      // this.startDateMsg = ""
      // this.startDateBoolean = false;
      // this.endDateBoolean = false;
      this.selectedButton = buttonIndex;
      this.isNewDiscount = buttonIndex === 1;
      this.isContratualDiscount = buttonIndex === 2;
    },

    goToPreviousDrawer() {
      this.endDateMsg =""
      this.startDateMsg = ""
      this.startDateBoolean = false;
      this.endDateBoolean = false;
      this.openEditMode=false
      // this.mainInfo.forEach((val) => {
      //   val.checked = false;
      // });
      this.mainInfoProduct.forEach((val) => {
        val.checked = false;
      });
      // this.mainRecurringProduct.forEach((val) => {
      //   val.checked = false;
      // });
      this.mainInfoNewDiscount.forEach((val) => {
        val.checked = false;
      });
      this.mainInfoContractual.forEach((val) => {
        val.checked = false;
      });
      this.editMode = false;
      this.newDiscountRadiobutton = this.newDiscountRadiobuttonDuplicate;
      this.ProductDiscountRadiobutton = JSON.parse(
        JSON.stringify(this.ProductDiscountRadiobuttonDuplicates)
      );
      this.cardBasedDiscountRadiobutton1 =
        this.cardBasedDiscountRadiobutton1Duplicate;
      this.cardBasedDiscountRadiobutton2 =
        this.cardBasedDiscountRadiobutton2Duplicate;
      this.ProductDiscountCheckbox = this.ProductDiscountCheckboxDuplicate;
      // this.ProductDiscountCheckbox = [...this.ProductDiscountCheckboxDuplicate];
      this.paymentMethodTypes = JSON.parse(
        JSON.stringify(this.paymentMethodTypesDuplicate)
      );
      this.paymentDiscountCheckbox = JSON.parse(
        JSON.stringify(this.paymentDiscountCheckboxDuplicate)
      );
      this.dropdown_data_contract = JSON.parse(
        JSON.stringify(this.dropdown_data_contractEdit)
      );
      this.discountNewDataEmit = JSON.parse(
        JSON.stringify(this.discountNullData)
      );
      this.recurringTableData = JSON.parse(
        JSON.stringify(this.recurringTableDataDuplicate)
      );
      this.account_data_new = JSON.parse(JSON.stringify(this.account_dataEdit));
      this.account_data = JSON.parse(JSON.stringify(this.account_dataEdit));
      this.dropdown_data = JSON.parse(
        JSON.stringify(this.dropdown_dataDuplicate)
      );
      this.role_data = JSON.parse(JSON.stringify(this.role_data_Duplicate));
      this.rolePercentageDropdown = JSON.parse(
        JSON.stringify(this.rolePercentageDropdownDuplicate)
      );
      // this.discountNewDataEmit.type="";
      if(this.openEditMode)
      {
      this.isContratualDiscount = false;
      this.isNewDiscount = false;
      this.isRecurringTable = false;
      this.isProductDiscount = false;
      this.updateComponentKey += 1;
      this.selectButton(2);
      this.isCustomerDiscountEdit = false;
      this.isCustomerDiscountNewEdit = false;
      this.isDiscountIcons = false;
      this.isDiscountConcurrency = false;
      this.isFooterDiscount = false;
      this.isProductDiscount = false;
      this.isCardBasedDiscount = false;
      this.isCustomerDiscount = false;
      this.isRoleBasedDiscount = false;
      this.isRecurringDiscount = false;
      this.isRadiobuttonNewDiscountMultiselect = false;
      this.isPaymentMethodBasedDiscount = false;
      this.isCheckboxInput = false;
      this.isModal = false;
      }
      else{ 
      this.isContratualDiscount = false;
      this.isNewDiscount = false;
      this.isRecurringTable = false;
      this.isProductDiscount = false;
      this.updateComponentKey += 1;
      this.selectButton(2);
      this.isCustomerDiscountEdit = false;
      this.isCustomerDiscountNewEdit = false;
      this.isDiscountIcons = true;
      this.isDiscountConcurrency = false;
      this.isFooterDiscount = false;
      this.isProductDiscount = false;
      this.isCardBasedDiscount = false;
      this.isCustomerDiscount = false;
      this.isRoleBasedDiscount = false;
      this.isRecurringDiscount = false;
      this.isRadiobuttonNewDiscountMultiselect = false;
      this.isPaymentMethodBasedDiscount = false;
      this.isCheckboxInput = false;
      }
    },
    openCustomerDiscountDrawer() {
      this.isNewDiscount = false;
      this.isContratualDiscount = true;
      this.isDiscountIcons = false;
      this.isDiscountConcurrency = false;
      this.isCustomerDiscount = true;
      this.isCustomerDiscountEdit = false;
      this.isCustomerDiscountNewEdit = false;
      this.isFooterDiscount = true;
    },
    openPaymentMethodBasedDiscountDrawer() {
      this.isContratualDiscount = false;
      this.isNewDiscount = false;
      this.discountNewDataEmit.type = "Payment method discount";
      this.isDiscountIcons = false;
      this.isDiscountConcurrency = false;
      this.isPaymentMethodBasedDiscount = true;
      this.isFooterDiscount = true;
      this.isCustomerDiscount = false;
      console.log("this.discountNewDataEmit", this.discountNewDataEmit);
      console.log("this.discountNewDataEmit");
    },
    openRoleBasedDiscountDrawer() {
      this.isContratualDiscount = false;
      this.isNewDiscount = false;
      this.isDiscountIcons = false;
      this.isRoleBasedDiscount = true;
      this.isCustomerDiscount = false;
      this.isFooterDiscount = true;
      this.isDiscountConcurrency = false;
    },

    openCardBasedDiscountDrawer() {
      this.isContratualDiscount = false;
      this.isNewDiscount = false;
      this.discountNewDataEmit.type = "Cart based discount";
      this.isDiscountIcons = false;
      this.isCardBasedDiscount = true;
      this.isCustomerDiscount = false;
      this.isDiscountConcurrency = false;
      this.isFooterDiscount = true;
    },
    openRecurringDiscountDrawer() {
      this.isContratualDiscount = false;
      this.isNewDiscount = false;
      this.isDiscountIcons = false;
      this.isCustomerDiscount = false;
      this.isRecurringTable = false;
      this.isRecurringDiscount = true;
      this.isDiscountConcurrency = false;
      this.isFooterDiscount = true;
    },
    openProductDiscountDrawer() {
      this.discountNewDataEmit.type = "Product offering";
      this.isContratualDiscount = false;
      this.isNewDiscount = false;
      this.isCustomerDiscount = false;
      this.isDiscountIcons = false;
      this.isDiscountConcurrency = false;
      this.isProductDiscount = true;
      this.isFooterDiscount = true;
    },
    crudContextMenu($event, index) {
      if(this.isContextMenuVisible){
      if ($event.target.className === "icon-more-vertical-filled") {
        this.containerleft =
          document
            .querySelector("#table-context" + index)
            .parentElement.getBoundingClientRect().left - 93;
        this.containertop = document
          .querySelector("#table-context" + index)
          .parentElement.getBoundingClientRect().top;
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
        this.tableData[index].context = true;
      } else {
        this.tableData?.forEach((e) => {
          if (e.context !== undefined) {
            e.context = false;
          }
        });
      }
    }
    else{
      
      this.notification = {
        type: "warning",
        message: `Heads up! You don't have permission to perform this action!`,
      };
    }
    },
    closeModal() {
      this.isModal = false;
    },
    openAddDiscountDrawer() {
      this.endDateMsg =""
      this.startDateMsg = ""
      this.startDateBoolean = false;
      this.endDateBoolean = false;
      this.openEditMode=false
      this.preValOffers=[]
      this.discountNewDataEmit = JSON.parse(
        JSON.stringify(this.discountNullData)
      );
      // this.mainInfo.forEach((val) => {
      //   val.checked = false;
      // });
      this.mainInfoProduct.forEach((val) => {
        val.checked = false;
      });
      // this.mainRecurringProduct.forEach((val) => {
      //   val.checked = false;
      // });
      this.account_data_new = JSON.parse(JSON.stringify(this.account_dataEdit));
      this.account_data = JSON.parse(JSON.stringify(this.account_dataEdit));
      this.mainInfoNewDiscount.forEach((val) => {
        val.checked = false;
      });
      this.mainInfoContractual.forEach((val) => {
        val.checked = false;
      });
      this.recurringTableData = JSON.parse(
        JSON.stringify(this.recurringTableDataDuplicate)
      );
      this.paymentMethodTypes = JSON.parse(
        JSON.stringify(this.paymentMethodTypesDuplicate)
      );
      this.dropdown_data_contract = JSON.parse(
        JSON.stringify(this.dropdown_data_contractEdit)
      );
      this.paymentDiscountCheckbox = JSON.parse(
        JSON.stringify(this.paymentDiscountCheckboxDuplicate)
      );
      this.rolePercentageDropdown = JSON.parse(
        JSON.stringify(this.rolePercentageDropdownDuplicate)
      );
      this.role_data = JSON.parse(JSON.stringify(this.role_data_Duplicate));
      this.newDiscountRadiobutton = JSON.parse(
        JSON.stringify(this.newDiscountRadiobuttonDuplicate)
      );
      this.ProductDiscountRadiobutton = JSON.parse(
        JSON.stringify(this.ProductDiscountRadiobuttonDuplicates)
      );
      this.cardBasedDiscountRadiobutton1 = JSON.parse(
        JSON.stringify(this.cardBasedDiscountRadiobutton1Duplicate)
      );

      this.cardBasedDiscountRadiobutton2 = JSON.parse(
        JSON.stringify(this.cardBasedDiscountRadiobutton2Duplicate)
      );
      // this.ProductDiscountCheckbox=this.ProductDiscountCheckboxDuplicate;
      this.dropdown_data = JSON.parse(
        JSON.stringify(this.dropdown_dataDuplicate)
      );
      this.ProductDiscountCheckbox = JSON.parse(
        JSON.stringify(this.ProductDiscountCheckboxDuplicate)
      );
      this.updateComponentKey += 1;
      this.editMode = false;
      this.isModal = true;
      this.isDiscountConcurrency = false;
      this.isDiscountIcons = true;
      this.isRecurringTable = false;
      this.isRadiobuttonNewDiscountMultiselect = false;
      (this.isCardBasedDiscount = false),
        (this.isProductDiscount = false),
        (this.isPaymentMethodBasedDiscount = false),
        (this.isRoleBasedDiscount = false),
        (this.isRecurringDiscount = false);
      this.isCustomerDiscount = false;
      this.isCustomerDiscountEdit = false;
      this.isCustomerDiscountNewEdit = false;
    },

    computedTableAction(data) {
      data;
      if (this.permisstionData.delete && this.permisstionData.edit) {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      } if (this.permisstionData.delete){
        return [
        
          {
            label: "Delete",
            icon: "icon-trash-regular",
          },
        ];
      }else {
        return [
          {
            label: "Edit",
            icon: "icon-file-text-edit-regular",
          },
        ];
      }
      // let options = [
      //   {
      //     label: "Edit",
      //     icon: "icon-file-text-edit-regular",
      //   },
      //   {
      //     label: "Delete",
      //     icon: "icon-trash-regular",
      //   },
      // ];
      // // TODO fix this
      // return options.filter((option) => {
      //   if (
      //     data &&
      //     data.status &&
      //     data.status.toLowerCase() == "draft" &&
      //     ["Draft", "Mark as In-active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else if (
      //     data &&
      //     data.status &&
      //     data.status.toLowerCase() == "active" &&
      //     ["Draft", "Mark as active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else if (
      //     data &&
      //     data.status &&
      //     data.status.toLowerCase() == "inactive" &&
      //     ["Draft", "Mark as In-active"].includes(option.label)
      //   ) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // });

    },
    computedTableActionNotEditable(data) {
      let options = [
        {
          label: "Delete",
          icon: "icon-trash-regular",
        },
      ];
      // TODO fix this
      return options.filter((option) => {
        if (
          data &&
          data.status &&
          data.status.toLowerCase() == "draft" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "active" &&
          ["Draft", "Mark as active"].includes(option.label)
        ) {
          return false;
        } else if (
          data &&
          data.status &&
          data.status.toLowerCase() == "inactive" &&
          ["Draft", "Mark as In-active"].includes(option.label)
        ) {
          return false;
        } else {
          return true;
        }
      });
    },
  },
};
</script>

<style lang="scss">
// .decision-add-container .decision-info-right-pannel {
//   overflow-y: auto !important;
// }
.search-row {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 0.875rem;
  justify-content: flex-end;
  margin-bottom: 8px;
}
.discount-table {
  margin-top: 12px;
  margin-bottom: 42px !important;
  padding-bottom: 28px;
}
.discountflow-drawer-cover {
  .sidenav-right {
    font-size: 16px;
    padding-right: 0px !important;
  }
  .body-panel-right, .body-panel-left{
    overflow-y: auto;
    height: 89vh !important;
  }
  .discount-flow-drawer {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
    justify-content: start;
    .discount-drawer {
      border: 1px solid #b7b7b7b2;
      border-radius: 2px;
      padding: 6px;
      .discountflow-icon {
        font-size: 48px;
      }
      .discount-drawer-header {
        font-family: OpenSans;
        font-size: 14px;
        font-weight: 600;
        line-height: 19.07px;
        text-align: left;
        margin-top: 8px;
        margin-bottom: 12px;
      }
      .discount-drawer-para {
        font-family: OpenSans;
        color: #929191;
        font-size: 12px;
        font-weight: 400;
        line-height: 16.34px;
        text-align: left;
      }
      .center-align {
        justify-content: center;
      }
    }
  }
}
.concurrencyDiscountSection {
  .radio-container {
    margin-top: 12px;
  }
}
.productDiscountSection {
  margin-bottom: 16px;

  h5,
  h3 {
    font-family: OpenSans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
    margin-bottom: 16px;
    margin-top: 8px;
  }
  h3 {
    font-size: 12px;
  }
  .check-container,
  .radio-container {
    font-family: OpenSans;
    line-height: 19.07px;
    // text-wrap: pretty;
    font-size: 14px;
    text-align: start;
  }
  // p {
  // font-family: OpenSans;
  // font-size: 12px;
  // font-weight: 400;
  // line-height: 19.07px;
  // color: #565656;
  // }
  .select-width-half {
    width: 100%;
    display: flex;
    gap: 12px;
  }
  .multi-select-width {
    .inline-multi-select {
      margin-bottom: 16px;
    }
  }
  .search-drop-down {
    height: 292px !important;
  }
}

.back {
  margin-right: 16px;
}

.button-container {
  display: flex;
  justify-content: center;
  .customer-discount-button-cover {
    border: 1px solid #d4d4d4;
    border-radius: 4px;

    button {
      font-family: OpenSans;
      font-size: 13px;
      font-weight: 400;
      line-height: 19.07px;
      padding: 8px 16px 8px 16px;
      cursor: pointer;
    }
    button:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    button:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .selected {
      background-color: var(--hlx-color-primary);
      color: white;
    }
  }
}
.discount-pagination-container {
  position: absolute;
  bottom: 0;
  //     // left: 0;
  //     right:0;
  // width: 100%;
  //   padding-left: 86px !important;
  //   padding-right: 24px !important;
  z-index: 990;
  background-color: white;
  // position: fixed;
  padding: 1rem 0px 1rem 0px;
  width: -webkit-fill-available;
  padding-right: 2rem !important;
}
.recurring-table {
  .tableBorder {
    overflow-x: hidden;
  }
}
</style>
