<template>
  <section class="signup-page">
    <div class="left-container"><signupComponenet></signupComponenet></div>
    <div class="right-container">
      <span class="title">
        "Transform complex decisions <br />
        into seamless actions."
      </span>
      <div class="bar-1"></div>
      <div class="bar-2"></div>
      <div class="image-container">
        <img
          src="../assets/images/rule-engine-section-2-dashboard.svg"
          alt="dashboard"
        />
      </div>
    </div>
  </section>
</template>

<script>
import signupComponenet from '@/components/signupComponenet.vue';
export default {
  name: 'SignupView',
  components: {
    signupComponenet,
  },
  data() {
    return {};
  },
};
</script>
