<template>
  <div>
    <form @submit.prevent="addMarker">
      <label>
        Latitude:
        <input v-model.number="newMarkerLat" required>
      </label>
      <label>
        Longitude:
        <input v-model.number="newMarkerLng" required>
      </label>
      <button type="submit">Add Marker</button>
    </form>

    <GMapMap
      :center="mapCenter"
      :zoom="5"
      style="width: 100%; height: 500px"
    >
      <GMapMarker
        v-for="(marker, index) in markers"
        :key="index"
        :position="marker.position"
        :draggable="true"
        @dragend="handleDragEnd(index, $event)"
      />
      <GMapPolygon
        :paths="polygonPaths"
        :options="polygonOptions"
      />
    </GMapMap>
  </div>
</template>

<script>
import { GMapMap, GMapMarker, GMapPolygon } from '@fawmi/vue-google-maps';

export default {
  name: 'MapComponent',
  components: {
    GMapMap,
    GMapMarker,
    GMapPolygon
  },
  data() {
    return {
      mapCenter: { lat: -25.344, lng: 131.036 },
      markers: [
        { position: { lat: -25.344, lng: 131.036 } },
        { position: { lat: -26.344, lng: 132.036 } },
        { position: { lat: -27.344, lng: 133.036 } },
        { position: { lat: -28.344, lng: 134.036 } },
      ],
      newMarkerLat: null,
      newMarkerLng: null,
      polygonOptions: {
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        clickable: false,
        editable: false,
        draggable: false,
      },
    };
  },
  computed: {
    polygonPaths() {
      return this.markers.map(marker => marker.position);
    }
  },
  methods: {
    handleDragEnd(index, event) {
      this.markers[index].position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
    },
    addMarker() {
      this.markers.push({
        position: { lat: this.newMarkerLat, lng: this.newMarkerLng }
      });
      this.newMarkerLat = null;
      this.newMarkerLng = null;
    }
  }
};
</script>

<style>
#map {
  height: 500px;
  width: 100%;
}
</style>
