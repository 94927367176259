<template>
  <div class="billing">
    <section class="right-view">
      <div class="breadcrumb">
        <hlx-breadcrumb
          :seperator="'/'"
          :crumbs="crumbs"
          @selected="selected"
        />
      </div>
      <div class="title-container">
        <p class="header"><b>My billing</b></p>
      </div>
      <div class="main-container">
        <div class="container-1">
          <div class="top-1">
            <div class="left-zone">
              <div class="zone-1">
                <p class="text">Enterprise plan</p>
                <hlx-label class="info sm">Annual</hlx-label>
              </div>
              <p class="sub-header">Our most popular plan for small teams</p>
            </div>
            <div class="right-zone">
              <p class="zone">
                <b>$10</b><span class="sub-zone">per month</span>
              </p>
            </div>
          </div>

          <div class="progress">
            <p class="text">16 of 30 users</p>
            <hlx-import-progress
              progress="50"
              stroke-width="10"
            />
          </div>
          <div class="end-container">
            <div class="button-section">
              <hlx-button class="text">Upgrade</hlx-button>
            </div>
          </div>
        </div>
        <div class="container-2">
          <div class="top-1">
            <div class="left-zone">
              <div class="zone-1">
                <p class="text">Payment method</p>
              </div>
              <p class="sub-header">Change how you pay for your plan</p>
            </div>
            <div class="right-zone">
              <hlx-button
                class="secondary sm"
                @click="edit_payment = true"
                >Edit</hlx-button
              >
            </div>
          </div>
          <div class="box">
            <div class="content">
              <section class="image">
                <img src="../assets/images/visa.svg" />
              </section>
              <section class="text">
                <p class="text1">Visa ending in 1234</p>
                <p class="sub-text">Expires on 06/12/2023</p>
                <p class="sub-text">
                  <i class="icon-rocket"></i>user123@org.com
                </p>
              </section>
            </div>
          </div>
        </div>
      </div>
      <section class="zone-2">
        <div class="title">
          <p><b>Payment history</b></p>
        </div>
        <div class="content">
          <div>
            <hlx-search
              :search-style="'style1'"
              @search-key="search"
            />
          </div>
          <div>
            <hlx-button class="secondary sm"
              ><i class="icon-filter" />&nbsp;&nbsp;Filter</hlx-button
            >
          </div>
          <div>
            <hlx-button class="primary sm"
              ><i class="icon-download" />&nbsp;&nbsp;Download</hlx-button
            >
          </div>
        </div>
      </section>
      <section class="zone-3">
        <hlx-table
          :column-count="theads"
          :border="['table', 'header', 'vertical', 'horizontal']"
          :bold-headers="false"
          :row-hover="false"
          theme="grey"
          :striped-rows="false"
          :height="310"
        >
          <template #thead>
            <hlx-table-head
              v-for="(i, index) in headvalue"
              :key="index"
              :sortable="i.sortable"
              :resizable="i.resizable"
              :align="'left'"
              @sorting_func="sorting_Data"
              >{{ i.label }}</hlx-table-head
            >
          </template>
          <template #tbody>
            <tr
              v-for="(i, index) in paginatedData"
              id=""
              :key="index"
            >
              <hlx-table-cell
                v-for="(j, col_index) in headvalue"
                :key="col_index"
                :align="'left'"
              >
                <div v-if="j.prop == 'Status'">
                  <span v-if="i[j.prop] === 'Success'"
                    ><hlx-label class="success sm"> Success </hlx-label>
                  </span>
                  <span v-else
                    ><hlx-label class="error sm"> Failure </hlx-label>
                  </span>
                </div>
                <div v-else>
                  {{ i[j.prop] }}
                </div>
              </hlx-table-cell>
            </tr>
          </template>
        </hlx-table>

        <div class="pagination-container">
          <span
            >Showing {{ showingFrom }} - {{ showingTo }} of
            {{ totalRows }} rows</span
          >
          <hlx-pagination
          v-model:rows-per-page="externalRowsPerPage"
            :total="tableData.length"
            rows-per-page
            enable-rows-per-page
            :rows-per-page-list="[5, 10, 15]"
            @current-page="currentPage"
            @updated:rows-per-page="changeRowsPerPage"
          ></hlx-pagination>
        </div>
      </section>
    </section>
    <div class="edit-payment">
      <hlx-modal
        :modal-active="edit_payment"
        :height="'250px'"
        :width="'400px'"
        :modal="true"
        @close="edit_payment = false"
      >
        <template #header>
          <div>Edit payment method</div>
        </template>
        <template #content>
          <div class="model-content">
            <p class="payment-text">Payment method</p>
            <hlx-input
              :options="options3"
              :type="'radio-buttons'"
              :position="'horizontal'"
            />
            <p class="sub-text">Add new card</p>
          </div>
        </template>
        <template #footer>
          <div class="button-area">
            <hlx-button
              class="secondary sm"
              @click="edit_payment = false"
              >Cancel</hlx-button
            >

            <hlx-button
              class="primary sm"
              @click="edit_payment = false"
              >Save</hlx-button
            >
          </div>
        </template>
      </hlx-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BillingView',
  data() {
    return {
      externalRowsPerPage:10,
      options3: [
        { name: 'Credit card', checked: false, disabled: false, id: 1 },
        { name: 'NEFT Transfer', checked: false, disabled: false, id: 2 },
      ],
      edit_payment: false,
      crumbs: [
        { name: 'Settings', icon: '' },
        { name: 'My billing', icon: '' },
      ],
      theads: [
        { name: 'Invoice', checked: true, id: 1 },
        { name: 'Process time', checked: true, id: 2 },
        { name: 'Edition', checked: true, id: 3 },
        { name: 'Gateway', checked: true, id: 4 },
        { name: 'Amount', checked: true, id: 5 },
        { name: 'Period', checked: true, id: 6 },
        { name: 'Status', checked: true, id: 7 },
        { name: 'Payment Id', checked: true, id: 8 },
      ],
      headvalue: [
        { prop: 'Invoice', label: 'Invoice', sortable: false, resizable: true },
        {
          prop: 'Process_time',
          label: 'Process time',
          sortable: false,
          resizable: true,
        },
        {
          prop: 'Edition',
          label: 'Total API calls',
          sortable: false,
          resizable: true,
        },
        { prop: 'Gateway', label: 'Gateway', sortable: false, resizable: true },
        {
          prop: 'Amount',
          label: 'Amount',
          sortable: false,
          resizable: true,
        },
        {
          prop: 'Period',
          label: 'Period',
          sortable: false,
          resizable: true,
        },
        {
          prop: 'Status',
          label: 'Status',
          sortable: false,
          resizable: true,
        },
        {
          prop: 'Payment_id',
          label: 'Payment Id',
          sortable: false,
          resizable: true,
        },
      ],
      tableData: [
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Failure',
          Payment_id: 200,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 4,
          Payment_id: 300,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '20-11-2022 to 21-11-2022',
          Status: 'Failure',
          Payment_id: 200,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: 200,
          Status: 5,
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
        {
          Invoice: 'Invoice.pdf',
          Process_time: '2days',
          Edition: '-',
          Gateway: 'Stripe',
          Amount: '$10',
          Period: '19-10-2022 to 20-10-2022',
          Status: 'Success',
          Payment_id: 100,
        },
      ],
      currPage: 1,
      rowsPerPage: 5,
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
    showingFrom() {
      return (this.currPage - 1) * this.rowsPerPage + 1;
    },
    showingTo() {
      const lastItem = this.showingFrom + this.rowsPerPage - 1;
      return lastItem > this.totalRows ? this.totalRows : lastItem;
    },
    totalRows() {
      return this.tableData.length;
    },
  },
  methods: {
    serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    currentPage(val) {
      this.currPage = val;
    },
    changeRowsPerPage(count) {
      this.rowsPerPage = count;
    },
    sorting_Data(data, data1) {
      if (data === 'icon-filled-arrow-up sorting-icon') {
        const x = [...this.tableData].sort(function (a, b) {
          
          if (typeof a === 'object') {
            
            if (typeof a[data1] === 'string') {
              return String(b[data1])
                .toLowerCase()
                .localeCompare(String(a[data1]).toLowerCase());
            } else if (typeof a[data1] === 'number') {
              return b[data1] - a[data1];
            }
          } else if (typeof a === 'string') {
            return String(b)
              .toLowerCase()
              .localeCompare(String(a).toLowerCase());
          } else if (typeof b === 'number') {
            return b - a;
          } else {
            return 0;
          }
          return 0;
        });
        this.tableData = x;
      } else if (data === 'icon-filled-arrow-down sorting-icon') {
        const x = [...this.tableData].sort(function (a, b) {
          if (typeof a === 'object') {
            if (typeof a[data1] === 'string') {
              return String(a[data1])
                .toLowerCase()
                .localeCompare(String(b[data1]).toLowerCase());
            } else if (typeof a[data1] === 'number') {
              return a[data1] - b[data1];
            }
          } else if (typeof a === 'string') {
            return String(a)
              .toLowerCase()
              .localeCompare(String(b).toLowerCase());
          } else if (typeof a === 'number') {
            return a - b;
          } else {
            return 0;
          }
          return 0;
        });
        this.tableData = x;
      } else if (data === 'icon-filled-arrow-up') {
        this.tableData = this.sortData;
      } else if (data === 'icon-filled-arrow-down') {
        this.tableData = this.sortData;
      }
    },
    Contextmenu(index) {
      if (event.target.className == 'icon-vertical-dots') {
        this.tableData[index].context.show = true;
      } else {
        // this.clicked = false;
        this.tableData.forEach((e) => {
          if (e.context != undefined) {
            e.context.show = false;
          }
        });
      }
    },
    closecontextmenu(data1, data2) {
      // let indexvalue = 
      parseInt(data2);
      
    },
  },
};
</script>

<style></style>
