<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <section style="display: flex; flex-direction: column">
    <main
      class="decision-add-container --layout-3-way"
      style="overflow-y: hidden !important"
      :style="
        isRightLayoutOpened
          ? 'display: grid;grid-template-columns: 300px calc(100% - 600px) 300px'
          : ''
      "
    >
      <!-- -------------------------------------------Left pannel-------------------------------------------------- -->
      <section class="cm-fields-left-panel">
        <!-- Breadcrumb -->
        <div class="layout-breadcrumb">
          <hlx-breadcrumb
            :items="breadCrumbItems"
            @path="goTo()"
          />
        </div>
        <div class="decision-info-left-pannel">
          <span
            class="title decision-title section-title"
            style="margin-top: 0.5rem"
            >Edit account</span
          >

          <div
            class="decision-name-wrapper"
            :style="{ 'margin-bottom': customnameerror ? '-7.6%' : 0 }"
          >
            <hlx-input
              v-model:value="payload.name"
              :pre-val="payload.name"
              :label-value="'Account name'"
              :label-animation="true"
              :required="true"
              :disabled="true"
              :display-error="customnameerror"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
          <div class="decision-name-wrapper">
            <hlx-input
              v-model:value="payload.contactMedium[3].description"
              :pre-val="payload.contactMedium[3].description"
              type="location"
              :params="locationApi"
              :label-value="'Address'"
              :label-animation="true"
              :display-error="customnameerror"
              :custom-error="customnameerror"
              :show-new-address-option="showNewAddressOption"
              :custom-error-message="'Please fill the field'"
              :location-data-prop="locationData"
              @focus-out="focusOutName"
              @at-input="getLocations"
              @add-new-location="addNewLocation"
            ></hlx-input>
          </div>
          <div class="decision-name-wrapper">
            <!-- <hlx-select
              v-model="payload.status"
              :pre-value="payload.status"
              :options="statusList"
              prop-value="name"
              :placeholder-value="'Status'"
              :label-animation="true"
              :clearable="true"
              :inline-search="true"
              :search-image-tag="false"
              @search-value="newValFromDropDown($event, 'status')"
            ></hlx-select> -->

            <div style="width: 100%">
              <hlx-status
                :type="'select-status'"
                :status-option="filteredstatus"
                :prevalue="payload.status"
                @emit-select="emitStatus1"
              >
              </hlx-status>
            </div>
          </div>
          <div class="decision-name-wrapper">
            <hlx-input
              v-model:value="payload.contactMedium[1].phoneNumber"
              :pre-val="payload.contactMedium[1].phoneNumber"
              type="number"
              :label-value="'Phone no.'"
              :label-animation="true"
              :display-error="customnameerror"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
          <div class="decision-name-wrapper">
            <hlx-input
              v-model:value="payload.contactMedium[0].emailAddress"
              :pre-val="payload.contactMedium[0].emailAddress"
              type="email"
              :label-value="'Email id'"
              :label-animation="true"
              :required="true"
              :display-error="customnameerror"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
          <div class="decision-name-wrapper">
            <hlx-input
              v-model:value="payload.contactMedium[2].faxNumber"
              :pre-val="payload.contactMedium[2].faxNumber"
              :label-value="'Fax'"
              :label-animation="true"
              :display-error="customnameerror"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
          <div class="decision-name-wrapper">
            <hlx-input
              v-model:value="payload.externalReference[0].name"
              :pre-val="payload.externalReference[0].name"
              :label-value="'Website'"
              :label-animation="true"
              :display-error="customnameerror"
              :custom-error="customnameerror"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
          <div class="decision-name-wrapper">
            <hlx-select
            
              v-model:value="payload.preferred"
              :pre-value="payload.preferred"
              :options="preferredMediumList"
              prop-value="value"
              :placeholder-value="'Preferred contact medium'"
              :label-animation="true"
              :clearable="true"
            ></hlx-select>
          </div>
          <div class="decision-name-wrapper">
            <hlx-select
              v-model="payload.relatedParty[0].name"
              :pre-value="payload.relatedParty[0].name"
              :options="executivesList"
              :placeholder-value="'Account executive'"
              :label="'name'"
              :prop-value="'name'"
              :label-animation="true"
              :search-image-tag="true"
              :sub-title="'company'"
              :tag-value="'role'"
              :image-link="'link'"
              :inline-search="true"
              @selected-value="selectedExecutive"
            >
            </hlx-select>
          </div>
          <div class="decision-name-wrapper">
            <hlx-select
              v-model="payload.partyCharacteristic[1].value"
              :pre-value="payload.partyCharacteristic[1].value"
              :options="industryTypeList"
              prop-value="name"
              :placeholder-value="'Industry'"
              :label-animation="true"
              :clearable="true"
              :inline-search="true"
              :search-image-tag="false"
              @search-value="newValFromDropDown($event, 'industry')"
            ></hlx-select>
          </div>
          <div class="decision-name-wrapper">
            <hlx-select
              v-model="payload.partyCharacteristic[0].value"
              :pre-value="payload.partyCharacteristic[0].value"
              :options="employeeCountList"
              prop-value="name"
              :placeholder-value="'Employees'"
              :label-animation="true"
              :clearable="true"
              :inline-search="true"
              :search-image-tag="false"
              @search-value="newValFromDropDown($event, 'employeeCount')"
            ></hlx-select>
          </div>
          <div class="decision-name-wrapper">
            <hlx-select
              v-model="payload.contactMedium[3]['@type']"
              :pre-value="payload.contactMedium[3]['@type']"
              :options="locationTypeList"
              prop-value="name"
              :placeholder-value="'Location type'"
              :label-animation="true"
              :clearable="true"
              :inline-search="true"
              :search-image-tag="false"
              @search-value="newValFromDropDown($event, 'locationType')"
            ></hlx-select>
          </div>
          <div class="decision-name-wrapper">
            <hlx-multi-select
              v-model:value="payload.tags"
              :pre-value="payload.tags"
              :options="tagsList"
              prop-value="name"
              :multi-select="true"
              :placeholder="'Tags'"
              :label-animation="true"
              :clearable="true"
              @add-data="addTagList"
              @selected-value="receivedTags"
            ></hlx-multi-select>
          </div>
        </div>
      </section>

      <!-- ------------------------------------------Centre pannel------------------------------------------------- -->
      <section
        class="--layout-inner-right-portion --RL70"
        style="padding: 1.8em 20px"
        :style="
          isRightLayoutOpened === false
            ? 'width:calc(100% - 300px)'
            : 'width:100%'
        "
      >
        <div
          class="decision-info-right-pannel"
          style="overflow: hidden"
        >
          <hlx-switch
            v-model="selectedValue"
            :switch-items="tabItems"
            :tab-view="true"
            value-prop="value"
            display-prop="name"
            @change="handleCurrentChange"
          />
          <div class="body-container-center">
            <div
              v-if="selectedValue.toLowerCase() === 'overview'"
              class="cm-accounts-overview-component-container"
            >
              <overViewComponent></overViewComponent>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'hierarchy'"
              class="cm-accounts-overview-component-container"
            >
              <hierarchyComponent
                :data="hierarchyData"
                @updated-hierarchy="receivedHierarchy"
              ></hierarchyComponent>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'contacts'"
              class="cm-accounts-overview-component-container"
            >
              <contactComponent
                :data="contactTableData"
                @contact-data="contactData"
              ></contactComponent>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'interaction'"
              class="cm-accounts-overview-component-container"
            >
              <interactionComponent
                :data="interactionData"
                @interaction-payload="receivedInteractionPayload"
              ></interactionComponent>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'rules'"
              class="cm-accounts-overview-component-container"
            >
              <!-- <img src="@/assets/images/addAccountContacts.svg" alt=""> -->
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'quotes and orders'"
              class="cm-accounts-overview-component-container"
            >
              <quoteAndOrdersComponent></quoteAndOrdersComponent>
            </div>
            <div
              v-if="selectedValue.toLowerCase() === 'approvals'"
              class="cm-accounts-overview-component-container"
            >
              <approvalsComponent></approvalsComponent>
            </div>
          </div>
        </div>
      </section>
      <!-- ---------------------------------------collapsible right panel------------------------------------------ -->
    </main>

    <div
      class="layout-footer"
      style="
        border-top: 1px solid #d8d8d8;
        padding-right: 40px;
        display: flex;
        align-items: center;
        height: 10vh;
        margin-right: -16px;
        margin-left: -16px;
      "
    >
      <div
        class="go-back"
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <hlx-button
          class="secondary sm"
          @click="$router.back()"
          >Cancel</hlx-button
        >
        <hlx-button
          class="primary sm"
          :disabled="payload.name === '' ? true : false"
          style="margin-left: 20px"
          @click="addNewAccount"
          >Save</hlx-button
        >
      </div>
    </div>
  </section>
  <hlx-modal
    :modal-active="showAddNewAdrressModal"
    :close-icon="true"
    :outer-layer-close-option="false"
    :height="'400px'"
    :width="'650px'"
    :modal="true"
    @close="closeAddNewAdrressModal"
  >
    <template #header>
      <div>Add new address</div>
    </template>
    <template #content>
      <div
        class="modal-content add-contact-modal"
        style="
          padding: 20px;
          padding-bottom: 0;
          grid-template-columns: 38.5% 58.5%;
        "
      >
        <span class="left-section">
          <hlx-input
            v-model:value="newAddressPayload.streetNumber"
            :pre-val="newAddressPayload.streetNumber"
            :label-value="'Street number'"
            :label-animation="true"
            :required="true"
            :display-error="true"
            :custom-error="isFirstNameEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            v-model:value="newAddressPayload.unitNumber"
            :pre-val="newAddressPayload.unitNumber"
            :label-value="'Unit number'"
            :label-animation="true"
            :display-error="true"
            :custom-error="isLastNameEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <div class="input-wrapper">
            <hlx-input
              :key="key"
              v-model:value="newAddressPayload.city"
              :pre-val="newAddressPayload.city"
              :options="designationList"
              :required="true"
              prop-value="name"
              :label-value="'City'"
              :placeholder-value="'City'"
              :label-animation="true"
              :clearable="true"
            ></hlx-input>
          </div>
          <div class="input-wrapper">
            <hlx-input
              v-model:value="newAddressPayload.postalCode"
              :pre-val="newAddressPayload.postalCode"
              :label-value="'Postal code'"
              :label-animation="true"
              :required="true"
              :display-error="true"
              :custom-error="isLastNameEmpty"
              :custom-error-message="'Please fill the field'"
              @focus-out="focusOutName"
              @at-input="validateName"
            ></hlx-input>
          </div>
        </span>
        <span class="right-section">
          <hlx-input
            v-model:value="newAddressPayload.streetName"
            :pre-val="newAddressPayload.streetName"
            :label-value="'Street name'"
            :label-animation="true"
            :required="true"
            :display-error="true"
            :custom-error="isEmailEmpty"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <hlx-input
            v-model:value="newAddressPayload.unitName"
            :pre-val="newAddressPayload.unitName"
            :label-value="'Unit name'"
            :label-animation="true"
            :required="false"
            :display-error="true"
            :custom-error="customnameerror"
            :custom-error-message="'Please fill the field'"
            @focus-out="focusOutName"
            @at-input="validateName"
          ></hlx-input>
          <div class="input-wrapper">
            <div style="display: flex; gap: 0.5rem">
              <hlx-input
                :key="key"
                v-model:value="newAddressPayload.state"
                :pre-val="newAddressPayload.state"
                :options="preferredList"
                :label-value="'State'"
                prop-value="value"
                :placeholder-value="'State'"
                :label-animation="true"
                :required="true"
                :clearable="true"
              ></hlx-input>
              <hlx-input
                :key="key"
                v-model:value="newAddressPayload.country"
                :pre-val="newAddressPayload.country"
                :options="preferredList"
                prop-value="value"
                :label-value="'Country'"
                :placeholder-value="'Country'"
                :required="true"
                :label-animation="true"
                :clearable="true"
              ></hlx-input>
            </div>
          </div>
          <hlx-input
            v-model:value="newAddressPayload.landmark"
            :pre-val="newAddressPayload.landmark"
            :label-value="'Nearby landmark'"
            :label-animation="true"
          ></hlx-input>
        </span>
      </div>
    </template>
    <template #footer>
      <hlx-button
        class="secondary sm"
        @click="closeAddNewAdrressModal"
        >cancel</hlx-button
      >
      <hlx-button
        class="primary sm"
        style="margin-left: 10px"
        :disabled="
          newAddressPayload.city === '' ||
          newAddressPayload.country === '' ||
          newAddressPayload.state === '' ||
          newAddressPayload.postalCode === '' ||
          newAddressPayload.streetNumber === '' ||
          newAddressPayload.streetName === ''
        "
        @click="addNewAddress"
        >Add</hlx-button
      >
    </template>
  </hlx-modal>
</template>

<script>
import {
  MLTAxiosInstance,
  DMNAxiosInstance,
  UPCAxiosInstance,
} from '@/config/axiosConfig';

import overViewComponent from '@/components/CM/addAccountOverviewComponent.vue';
import hierarchyComponent from '@/components/CM/addAccountHierarchyComponent.vue';
import contactComponent from '@/components/CM/addAccountContactComponent.vue';
import interactionComponent from '@/components/CM/addAccountInteractionComponent.vue';
import quoteAndOrdersComponent from '@/components/CM/addAccountQuoteAndOrderComponent.vue';
import approvalsComponent from '@/components/CM/addAccountApprovalsComponent.vue';

export default {
  components: {
    overViewComponent,
    hierarchyComponent,
    contactComponent,
    interactionComponent,
    quoteAndOrdersComponent,
    approvalsComponent,
  },
  data() {
    return {
      filteredstatus: [],
      notification: {},
      contactTableData: [],
      locationTypeList: [],
      employeeCountList: [],
      preferredMediumList: [],
      industryTypeList: [],
      statusList: [],
      tagsList: [
        {
          name: 'SaaS',
          checked: true,
          disabled: false,
        },
      ],
      locationParams: {
        // apiKey: """",
        country: 'CA',
        type: 'address',
      },
      isRightLayoutOpened: false,
      breadCrumbItems: [
        {
          label: 'Accounts',
          link: `/CM/accounts`,
        },
        { label: 'Edit account', link: '' },
      ],
      customnameerror: false,
      payload: {
        name: '',
        tradingName: '',
        status: '',
        partyCharacteristic: [
          {
            name: 'number of employees',
            value: '',
          },
          {
            name: 'market segment',
            value: '',
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: 'email',
            emailAddress: '',
          },
          {
            mediumType: 'phone',
            phoneNumber: '',
          },
          {
            mediumType: 'fax',
            faxNumber: '',
          },
          {
            mediumType: 'postalAddress',
            city: '',
            country: '',
            postCode: '',
            stateOrProvince: '',
            street1: '',
            street2: '',
            description: '',
            '@type': '',
          },
        ],
        externalReference: [
          {
            name: '',
            externalIdentifierType: 'internetSite',
          },
        ],
        relatedParty: [
          {
            role: 'director',
            partyOrPartyRole: {
              id: '42',
              name: 'Feroz Khan',
              '@referredType': 'Individual',
            },
          },
        ],
        tags: ['Active'],
        role: 'Root admin',
        type: 'company',
        password: '',
        email: '',
      },
      contactPayload: {
        name: '',
        tradingName: '',
        companyName: '',
        status: '',
        partyCharacteristic: [
          {
            name: 'number of employees',
            value: '',
          },
          {
            name: 'market segment',
            value: '',
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: 'email',
            emailAddress: '',
          },
          {
            mediumType: 'phone',
            phoneNumber: '',
          },
          {
            mediumType: 'fax',
            faxNumber: '',
          },
          {
            mediumType: 'postalAddress',
            city: '',
            country: '',
            postCode: '',
            stateOrProvince: '',
            street1: '',
            street2: '',
            description: '',
            '@type': '',
          },
        ],
        externalReference: [
          {
            name: '',
            externalIdentifierType: 'internetSite',
          },
        ],
        relatedParty: [
          {
            role: 'director',
            partyOrPartyRole: {
              id: '42',
              name: 'Feroz Khan',
              '@referredType': 'Individual',
            },
          },
        ],
        tags: ['Active'],
        role: 'Root admin',
        type: 'individual',
        password: '',
        email: '',
      },
      selectedValue: 'Overview',
      tabItems: [
        { name: 'Overview', value: 'Overview' },
        { name: 'Hierarchy', value: 'Hierarchy' },
        {
          name: 'Contacts',
          value: 'Contacts',
        },
        { name: 'Interaction', value: 'Interaction' },
        { name: 'Rules', value: 'Rules' },
        { name: 'Quotes and orders', value: 'Quotes and orders' },
        { name: 'Approvals', value: 'Approvals' },
      ],
      recievedContactData: [],
      showAddNewAdrressModal: false,
      locationData: [],
      newAddressPayload: {
        streetNumber: '',
        streetName: '',
        unitNumber: '',
        unitName: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        description: '',
        status: '-',
        landmark: '',
      },
      showNewAddressOption: false,
      interactionData: {},
      receivedInteractionData: {},
      hierarchyData: [],
      executivesList: [],
    };
  },
  watch: {
    statusList: {
      handler(val) {
        console.log(val, 'statuswatching000000000000');
        const filteredstatuslist = this.statusList.filter(
          (item) => item.name === 'Active' || item.name === 'Inactive'
        );
        this.filteredstatus = filteredstatuslist;
        console.log('filtered11111111111111111', this.filteredstatus);
      },
      immediate: true,
      deep: true,
    },
    payload: {
      handler(val) {
        val.companyName = val.name;
        val.tradingName = val.name;
        if (val.contactMedium !== undefined) {
          val.email = val?.contactMedium[0]?.emailAddress;
          for (let i = 0; i < val.contactMedium.length; i++) {
            if (val.contactMedium[i].mediumType === val.preferred) {
              val.contactMedium[i].preferred = true;
            } else {
              val.contactMedium[i].preferred = false;
            }
          }
        }
        //   let accExec = this.executivesList.filter(item => {
        //   return item.name === val.accountExecutive ? item : undefined
        // })
        // val.relatedParty.splice(0, 1)
        // val.relatedParty.push(accExec[0])
        // console.log(this.payload);
      },
      immediate: true,
      deep: true,
    },
    contactPayload: {
      handler(val) {
        val.company = JSON.parse(JSON.stringify(this.payload.tradingName));
        val.companyName = JSON.parse(JSON.stringify(this.payload.tradingName));
        val.tradingName = JSON.parse(JSON.stringify(this.payload.tradingName));
        val.email = val.contactMedium[0].emailAddress;
        // console.log(this.payload, this.contactPayload);
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    let payload = await MLTAxiosInstance.get(
      `/partyManagement/organisation/${this.$route.params.companyName}/${this.$route.params.id}`
    );
    let contacts = await MLTAxiosInstance.get(
      `/partyManagement/individual/listuser`
    );
    contacts.data.forEach((e) => {
      // console.log(e);
      const preferredMedium = e.contactMedium.find((item) => item.preferred);
      e.preferred = preferredMedium ? preferredMedium.mediumType : '';
    });
    this.contactTableData = contacts.data;
    const preferredMedium = payload.data.contactMedium.find(
      (item) => item.preferred
    );
    // console.log(preferredMedium);
    this.payload = payload.data;

    this.payload.preferred = preferredMedium ? preferredMedium.mediumType : '';

    let interaction = await UPCAxiosInstance.get(
      `/partyInteraction/${this.$route.params.companyName}`
    );
    this.interactionData = interaction?.data[0];

    let hierarchy = await MLTAxiosInstance.get(
      `/hierarchy/${this.payload.rootName}`
    );
    // console.log(hierarchy);
    this.hierarchyData.push(hierarchy.data);
    let exec = await MLTAxiosInstance.get(
      `/partyManagement/individual/listuser`
    );
    this.executivesList = exec.data;

    // let statusList = await DMNAxiosInstance.get(`mapRef/collection/statuslist`);
    this.statusList =  await this.getReferanceData('statuslist');
    // let preferredList = await DMNAxiosInstance.get(
    //   `mapRef/collection/preferredContactMedium`
    // );
    this.preferredMediumList =  await this.getReferanceData('preferredContactMedium');
    // let industryTypeList = await DMNAxiosInstance.get(
    //   `mapRef/collection/industryType`
    // );
    this.industryTypeList =  await this.getReferanceData('industryType');
    // let employeeCountList = await DMNAxiosInstance.get(
    //   `mapRef/collection/employeeCount`
    // );
    this.employeeCountList =  await this.getReferanceData('employeeCount');
    // let locationTypeList = await DMNAxiosInstance.get(
    //   `mapRef/collection/locationType`
    // );
    this.locationTypeList =  await this.getReferanceData('locationType');
  },
  methods: {
    emitStatus1(val1) {
      this.payload.status = val1['value'];
    },
    async newValFromDropDown(e, val) {
      let obj = {};
      obj.name = e;
      if (val === 'status') {
        await DMNAxiosInstance.post(`mapRef/organizationStatus`, obj);
        // let statusList = await DMNAxiosInstance.get(
        //   `mapRef/collection/statuslist`
        // );
        this.statusList =  await this.getReferanceData('statuslist');
      } else if (val === 'industry') {
        await DMNAxiosInstance.post(`mapRef/industryType`, obj);
        // let industryTypeList = await DMNAxiosInstance.get(
        //   `mapRef/collection/industryType`
        // );
        this.industryTypeList =  await this.getReferanceData('industryType');
      } else if (val === 'employeeCount') {
        await DMNAxiosInstance.post(`mapRef/employeeCount`, obj);
        // let employeeCountList = await DMNAxiosInstance.get(
        //   `mapRef/collection/employeeCount`
        // );
        this.employeeCountList =  await this.getReferanceData('employeeCount');
      } else if (val === 'locationType') {
        await DMNAxiosInstance.post(`mapRef/locationType`, obj);
        // let locationTypeList = await DMNAxiosInstance.get(
        //   `mapRef/collection/locationType`
        // );
        this.locationTypeList =  await this.getReferanceData('locationType');
      }
    },
    selectedExecutive(val) {
      this.payload.relatedParty[0] = val;
      // console.log( this.payload.relatedParty);
    },
    receivedHierarchy(val) {
      this.hierarchyData = val;
    },
    async getLocations() {
      const input = this.payload.contactMedium[3].description;
      let result = [];

      try {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        const response = await MLTAxiosInstance.get(`util/${input}`);
        // console.log(response);
        result = response.data;
      } catch (error) {
        console.error('Error fetching autocomplete data:', error.message);
      }
      // console.log(result);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.locationData = result;
      if (this.locationData.length === 0 && input !== '') {
        this.showNewAddressOption = true;
      } else {
        this.showNewAddressOption = false;
      }
      return result;
    },
    async addNewAddress() {
      this.newAddressPayload.description =
        this.newAddressPayload.streetNumber +
        ', ' +
        this.newAddressPayload.streetName +
        ', ' +
        this.newAddressPayload.city +
        ', ' +
        this.newAddressPayload.state +
        ', ' +
        this.newAddressPayload.country +
        ', ' +
        this.newAddressPayload.postalCode +
        '.';
      // console.log(this.newAddressPayload);
      let newAddress = await DMNAxiosInstance.post(
        `mapRef/geoAddress`,
        this.newAddressPayload
      );
      if (newAddress.data.ok === 1) {
        // console.log(this.newAddressPayload.description);
      }
      this.payload.contactMedium[3].description =
        this.newAddressPayload.description;
      this.showAddNewAdrressModal = false;
      const x = {
        streetNumber: '',
        streetName: '',
        unitNumber: '',
        unitName: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        description: '',
        status: '-',
        landmark: '',
      };
      this.newAddressPayload = JSON.parse(JSON.stringify(x));
    },
    addNewLocation(val) {
      // console.log(val);
      val;
      this.showAddNewAdrressModal = true;
      this.showNewAddressOption = false;
    },
    closeAddNewAdrressModal() {
      this.showAddNewAdrressModal = false;
    },
    receivedTags() {
      // console.log(e);
    },
    addTagList(e) {
      // console.log(e);
      this.tagsList.push(e);
    },
    contactData(val) {
      this.recievedContactData = [...val];
      // console.log(this.recievedContactData);
    },
    async addNewAccount() {
      // console.log(this.payload);
      // console.log(this.recievedContactData);
      this.payload.id = this.payload._id;
      delete this.payload._id;
      let result = await MLTAxiosInstance.patch(
        `/partyManagement/organisation/${this.$route.params.companyName}`,
        this.payload
      );
      // console.log(result);
      if (result.data.tradingName) {
        this.notification = {
          type: 'success',
          message: 'Organisation edited successfully',
        };
      } else {
        // this.notification = {
        //   type: "danger",
        //   message: "Failed to edit organization. Please try again.",
        // };
      }
      this.recievedContactData.forEach(async (e) => {
        // console.log(e);
        e.id = e._id;
        e.familyName = e.name;
        e.givenName = e.name;
        e.email = e.contactMedium[0].emailAddress;
        e.company = JSON.parse(JSON.stringify(this.payload.tradingName));
        e.companyName = JSON.parse(JSON.stringify(this.payload.tradingName));
        e.tradingName = JSON.parse(JSON.stringify(this.payload.tradingName));
        // console.log(e);
        let result = {};
        if (e._id === undefined) {
          result = await MLTAxiosInstance.post(
            `/partyManagement/individual/account/signup`,
            e
          );
        } else {
          result = await MLTAxiosInstance.patch(
            `/partyManagement/individual`,
            e
          );
        }

        // console.log(result);
        if (result.data.token) {
          this.notification = {
            type: 'success',
            message: `Contact ${e.name} edited successfully`,
          };
        } else {
          // this.notification = {
          //   type: "danger",
          //   message: `Failed to edit contact for ${e.name}. Please try again.`,
          // };
        }
      });
      // console.log(this.receivedInteractionData._id);
      if (this.receivedInteractionData._id !== undefined) {
        this.receivedInteractionData.id = this.receivedInteractionData._id;
        let updatedInteraction = await UPCAxiosInstance.patch(
          `/partyInteraction/${this.$route.params.companyName}`,
          this.receivedInteractionData
        );
        updatedInteraction;
        // console.log(updatedInteraction);
      } else {
        let updatedInteraction = await UPCAxiosInstance.post(
          `/partyInteraction/${this.$route.params.companyName}`,
          this.receivedInteractionData
        );
        updatedInteraction;
        // console.log(updatedInteraction);
      }
      setTimeout(() => {
        this.goTo();
      }, 2000);
    },
    goTo() {
      this.$router.back();
    },
    receivedInteractionPayload(val) {
      this.receivedInteractionData = JSON.parse(JSON.stringify(val));
    },
  },
};
</script>
