<template>
  <hlx-alert-notification
    :show-alert="success"
    :notify="notification"
    :auto-close="true"
  />
  <section>
    <additionalCharges
      :additional-charges-data="additionalChargeData"
      :permisstion-data="permisstionData"
      @additional-charge-new-data="addAdditionalCharges"
      @edit-button="editAdditionalCharge"
      @delete-button="deleteAdditionalCharge"
      @atclick-edit-button="initialCharge"
    ></additionalCharges>
  </section>
</template>

<script>
import additionalCharges from "@/components/Product/AdditionalCharges.vue";
import { UPCAxiosInstance,MLTAxiosInstance } from "@/config/axiosConfig";

export default {
  components: {
    additionalCharges,
  },
  data() {
    return {
      permisstionData:{},
      notification: {},
      additionalChargeData: [],
      initialChargeval: {},
    };
  },
  async mounted() {
    const val = await MLTAxiosInstance.get("partymanagement/individual/auth");

// this.company = await val.data.company;
let sampleTheadsd = await MLTAxiosInstance.get(
  `partyRole/get/${val.data.userId}`
);

const mergedPayload = sampleTheadsd.data.permission;
console.log(
  mergedPayload.UPC.child["Product catalogue"].child["Additional charges"],
  "mergedPayload"
);
this.permisstionData =
  mergedPayload.UPC.child["Product catalogue"].child["Additional charges"];
    const addditionalData = await UPCAxiosInstance.get(
      `/productCatalog/productOffering`
    );
    this.additionalChargeData = await addditionalData.data;
  },
  methods: {
    initialCharge(val) {
      this.initialChargeval = val;
    }, 
    async addAdditionalCharges(value) {
      console.log(value, "from the patch items");
      const addAdditionalCharge = await UPCAxiosInstance.post(
        `/productCatalog/productOffering`,
        value
      );
      console.log(addAdditionalCharge.data, "from the patch items");

      if (addAdditionalCharge.data.appliesTo) {
        let additionalChargeLogPayload = {
          message: `${value.name} additional charge has been added`,
          "@type": "Offering-additional-charge",
          detailedAction: [],
          category: ["productOffering", "additionalCharges"],
          createdBy: this.$cookies.get("user"),
          user: this.$cookies.get("userName"),
          lastUpdate: new Date().toISOString(),
          ref: addAdditionalCharge.data.appliesTo.map((item) => item?._id),
          action: "ADDED",
        };
        this.initLog(additionalChargeLogPayload);

        addAdditionalCharge.data.appliesTo.map(async (item) => {
          item.additionalChargeId = addAdditionalCharge.data._id;
          let items = await { ...item, ...value };
          console.log(item._id, "from the patch items final123", items);
          const getProductOffering = await UPCAxiosInstance.get(
            `/catalogManagement/productOffering/${item._id}`
          );
          // items = await items.concat(getProductOffering.data.additionalCharges)
          let charges = getProductOffering.data.additionalCharges.concat(items);

          const patchAdditionalCharge = await UPCAxiosInstance.patch(
            `/catalogManagement/productOffering`,
            { id: item._id, additionalCharges: charges }
          );
          console.log(
            patchAdditionalCharge.data,
            "from the patch final",
            items
          );
          patchAdditionalCharge;
        });
      }
      if (addAdditionalCharge.status === 201) {
        this.notification = {
          type: "success",
          message: `All set! Your changes have been saved successfully!`,
        };
        this.refreshTable();
      }
      console.log(value, "from the additional charges");
    },
    async editAdditionalCharge(values) {
      let updatedAdditionalChargeLogPayload = {
        message: `${values.name} additional charge has been updated`,
        "@type": "Offering-additional-charge",
        detailedAction: [],
        category: [ "additionalCharges","productOffering"],
        createdBy: this.$cookies.get("user"),
        ref: values.appliesTo.map((item) => item?._id),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        
        action: "UPDATED",
      };
      // Name log
      let nameActionLog = this.buildBasicLogByDifferences(
        this.initialChargeval,
        values,
        "name",
        "Name"
      );
      console.log(nameActionLog, "nameActionLog");

      // Description log
      let descriptionActionLog = this.buildBasicLogByDifferences(
        this.initialChargeval,
        values,
        "description",
        "Description"
      );
      console.log(descriptionActionLog, "descriptionActionLog");

      // price log
      let priceActionLog = this.buildBasicLogByDifferences(
        this.initialChargeval,
        values,
        "price",
        "price"
      );
      console.log(priceActionLog, "descriptionActionLog");

      // appliesToActionLog log
      let appliesToActionLog = this.buildLogBasedOnDifferencesForArrayOfObjects(
        this.initialChargeval.appliesTo,
        values.appliesTo,
        "name",
        "Apply to"
      );
      console.log(appliesToActionLog, "descriptionActionLog");

      // chargeType log
      let chargeTypeActionLog =
        this.buildLogBasedOnDifferencesForArrayOfObjects(
          this.initialChargeval.chargeType,
          values.chargeType,
          "name",
          "Applies to order type"
        );
      console.log(chargeTypeActionLog, "descriptionActionLog");

      // chargeType log
      let typechargeTypeActionLog = this.buildBasicLogByDifferences(
        this.initialChargeval,
        values,
        "@type",
        "chargeType"
      );
      console.log(typechargeTypeActionLog, "descriptionActionLog");

      let TaxableActionLog = this.buildBasicLogyesOrno(
        this.initialChargeval,
        values,
        "isTaxable",
        "Taxable",
        "yes",
        "No"
      );
      console.log(typechargeTypeActionLog, "descriptionActionLog");

      updatedAdditionalChargeLogPayload.detailedAction =
        updatedAdditionalChargeLogPayload.detailedAction.concat(
          typechargeTypeActionLog,
          chargeTypeActionLog,
          appliesToActionLog,
          priceActionLog,
          descriptionActionLog,
          nameActionLog,
          TaxableActionLog
        );

      console.log(values, "edit data");
      values.id = values._id;
      delete values._id;
      const updateAdditionalCharge = await UPCAxiosInstance.patch(
        `/productCatalog/productOffering`,
        values
      );

      // let updatedAdditionalChargeAddLogPayload = {
      //   message: `${values.name} additional charge has been added`,
      //   "@type": "Offering-additional-charge",
      //   detailedAction: [],
      //   category: ["productOffering"],
      //   createdBy: this.$cookies.get("user"),
      //   user: this.$cookies.get("userName"),
      //   ref: values.appliesTo.map((item) => item?._id),
      //   lastUpdate: new Date().toISOString(),
        
      //   action: "ADDED",
      // };
      this.initLog(updatedAdditionalChargeLogPayload);
      // this.initLog(updatedAdditionalChargeAddLogPayload);
      console.log(updateAdditionalCharge.data, "edit data");
      let value = await updateAdditionalCharge.data;
      if (value.appliesTo) {
        value.appliesTo.map(async (item) => {
          console.log(item, "from the patch items");
          // console.log(item)
          console.log(value);
          let productSpec = await UPCAxiosInstance.get(
            `/catalogManagement/productOffering/${item._id}`
          );
          console.log("additionalCharges edited", productSpec);
          let additionalCharges = productSpec.data?.additionalCharges;
          let items = { ...item, ...value };
          console.log("additionalCharges edited", items);
          const index = additionalCharges.findIndex(
            (e) =>
              e?.additionalChargeId === value?._id ||
              e?._id === value?._id ||
              e?.additionalChargeId === value?.id ||
              e?._id === value?.id
          );

          if (index !== -1) {
            console.log("Change edited data", index);

            additionalCharges[index] = {
              ...additionalCharges[index],
              name: items.name,
              price: items.price,
              description: items.description,
              isTaxable: items.isTaxable,
              "@type": items["@type"],
              chargeType: items.chargeType,
              context:items.context
            };

            console.log("additionalCharges if end", additionalCharges);
          } else {
            // Add new object since ID was not found
            additionalCharges = additionalCharges.concat(items);
            console.log("ID not found");
          }

          console.log("final additionalCharges", additionalCharges);

          // additionalCharge = [...new Set(additiona lCharges)];

          console.log("additionalCharges after", additionalCharges);
          let removeDuplicates = await additionalCharges.reduce(
            (acc, current) => {
              console.log(acc, "acc");
              const x = acc.find((item) => item._id === current._id);
              if (!x) {
                acc.push(current);
              }
              return acc;
            },
            []
          );

          // console.log(removeDuplicates, "from the patch items ids");
          const patchAdditionalCharge = await UPCAxiosInstance.patch(
            `/catalogManagement/productOffering`,
            { id: item._id, additionalCharges: removeDuplicates }
          );
          console.log(
            await patchAdditionalCharge.data,
            { id: item._id, additionalCharges: item },
            "from the patch updation"
          );
          patchAdditionalCharge;
        });
      }
      if (updateAdditionalCharge.status === 200) {
        this.notification = {
          type: "success",
          message: `All set! Your changes have been saved successfully!`,
        };
        this.refreshTable();
      }
    },
    async refreshTable() {
      const addditionalData = await UPCAxiosInstance.get(
        `/productCatalog/productOffering`
      );
      this.additionalChargeData = await addditionalData.data;
    },
    async deleteAdditionalCharge(value) {
      console.log(value,"from the delete");

//       let updateAdditionalCharges = await UPCAxiosInstance.patch(
//         `/catalogManagement/productOffering`,
//         {
//           additionalCharges: updatedArray,
//           id: this.payloadSpec._id,
//         }
//       );

const updateAdditionalCharges = await value?.appliesTo.map(async(item)=>{
     const findOffer = await UPCAxiosInstance.get(
      `/catalogManagement/productOffering/${item._id}`
    );
    const updatedArray = await findOffer?.data?.additionalCharges?.filter(
        (item) => item.additionalChargeId !== value._id
      );
      console.log(await updatedArray, "updatedArray");
      let deleteAdditionalCharge = await UPCAxiosInstance.patch(
        `/catalogManagement/productOffering`,
        {
          additionalCharges: updatedArray,
          id: await findOffer.data._id,
        }
      );
      deleteAdditionalCharge;

})
console.log(updateAdditionalCharges,"update in additional charges")
      const deleteAdditionalCharge1 = await UPCAxiosInstance.delete(
        `/productCatalog/productOffering/${value._id}`
      );

      const deleteAdditionalChargeLogPayload = {
        message: `${value.name} additional charge has been removed`,
        "@type": "Offering-additional-charge",
        detailedAction: [],
        category: ["productOffering", "additionalCharges"],
        createdBy: this.$cookies.get("user"),
        user: this.$cookies.get("userName"),
        lastUpdate: new Date().toISOString(),
        ref: value.appliesTo.map((item) => item?._id),
        action: "DELETED",
      };

      if (deleteAdditionalCharge1.status === 204) {
        this.notification = {
          type: "success",
          message: `Done! Your item has been removed.`,
        };
        this.initLog(deleteAdditionalChargeLogPayload);
        this.refreshTable();
      }
    },
  },
};
</script>

<style></style>
