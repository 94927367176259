<template>
  <div style="padding: 30px">
    <div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
        "
      >
        <div style="display: flex; align-items: center; gap: 16px">
          <span style="">
            <hlx-file-upload
              :file-type="'.jpg,.jpeg,.png'"
              :file-size-limit="'20mb'"
              :uploadtype="'Rectangle'"
              :style-background="backgrounStyleupload"
              :height="50"
              :width="50"
              :read-only="true"
              :custom-icon="icon"
              :type="'profile-upload'"
              :name-display="payload.customName"
              :content="'Import'"
              :profile-pic-preview="profilePicUrl"
            />
          </span>

          <div style="display: flex; flex-direction: column; gap: 8px">
            <div style="display: flex; align-items: center; gap: 8px">
              <span style="font-weight: bold">{{ payload.customName }}</span>
              <hlx-label
                v-if="payload.status.length > 0 && payload.status === 'Active'"
                class="primary sm"
                >{{ payload.status }}</hlx-label
              >
              <hlx-label
                v-if="
                  payload.status.length > 0 && payload.status === 'Inactive'
                "
                class="error sm"
                >{{ payload.status }}</hlx-label
              >
            </div>
            <div style="color: #a6a6a6; font-size: 12px; width: max-content">
              {{ payload.contactMedium[3].characteristic.description }}
            </div>
          </div>
        </div>
      </div>
    </div>

      <div
    v-if="tableData.length > 0 || searchvalue !== ''"
    class="header-add-supplier"
  >
    <div class="tablehead-add-supplier">{{ 'Products' }}</div>
    <div class="search-add-supplier">
      <span><hlx-search @search-key="productSearch"></hlx-search></span>
      <!-- <span
        ><hlx-button class="secondary sm"
          ><i class="icon-filter-regular"></i></hlx-button
      ></span> -->
    
    </div>
  </div>
  <div>
   

    <div
      v-if="tableData.length > 0"
      style="height: 78vh; overflow: auto"
      class="cm-accounts-right-panel-top-section-table-wrap table-wrap"
    >
      <hlx-table
        :column-count="accounts_table_theads.length + 2"
        :border="['table', 'header', 'horizontal']"
        :row-hover="true"
        theme="grey"
        :re-arrange-columns="re_arrange"
        :re-arrange-heads="accounts_table_theads_cc"
        @close-rearrange="arrangeColumns"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head
            :width="60"
            :align="'center'"
            style="padding: 0 15px"
            :fixed="'left'"
            >{{ "S.no" }}</hlx-table-head
          >
          <hlx-table-head
            v-for="(i, index) in accounts_table_theads"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            :width="i.width !== undefined ? i.width : ''"
            :align="i.align !== undefined ? i.align : ''"
            @sorting_func="sorting_Data"
          >
            <span v-if="['MSRP', 'stockedProduct'].includes(i?.prop)">
              {{ i.label }} ({{
                $store?.getters?.getDefaultSettings?.currency?.unit
              }})
            </span>
            <span v-else>
              {{ i.label }}
            </span></hlx-table-head
          >
         
        </template>
        <template #tbody>
          <tr v-for="(i, index) in paginatedData" id="" :key="index">
            <hlx-table-cell :align="'center'" :fixed="'left'">
              {{ serialNumber(index) }}
            </hlx-table-cell>
            <hlx-table-cell
              v-for="(j, col_index) in accounts_table_theads"
              :key="col_index"
              :align="j.align"
              :fixed="j.fixed"
            >
              <div
                v-if="j.prop.toLowerCase() === '_id'"
                :id="j.prop + index"
                class="link-name"
                @click="viewPage(i)"
              >
                {{ i[j.prop] }}
              </div>

              <div v-else-if="j.prop.toLowerCase() === 'sku'">
                <span v-if="i?.stockedProduct?.SKU">
                  {{ i?.stockedProduct?.SKU }}
                </span>
                <span v-if="i?.stockedProduct?.sku">
                  {{ i?.stockedProduct?.sku }}
                </span>
              </div>
              <div v-else-if="j.prop.toLowerCase() === 'msrp'">
                <span style="margin: 0.5rem 10px">
                  <span
                    v-if="
                      i?.stockedProduct?.MSRP &&
                      i.stockedProduct['@baseType'] !== 'Intangible'
                    "
                  >
                    {{
                      currencyFormatter(i?.stockedProduct?.MSRP?.value, {
                        currency: i?.stockedProduct?.MSRP?.unit,
                      })
                    }}
                  </span>
                  <span v-if="i?.stockedProduct?.msrp">
                    {{
                      currencyFormatter(i?.stockedProduct?.msrp?.value, {
                        currency: i?.stockedProduct?.msrp?.unit,
                      })
                    }}
                  </span>
                  <span
                    v-if="
                      i?.stockedProduct?.tiers &&
                      i.stockedProduct['@baseType'] === 'Intangible'
                    "
                  >
                    <span
                      v-for="(item, iindex) in i?.stockedProduct?.tiers"
                      :key="iindex"
                    >
                      <span v-if="iindex === 0 && item?.price?.value">
                        {{
                          currencyFormatter(item?.price?.value, {
                            currency: item?.price?.unit,
                          })
                        }}
                      </span>
                      <span v-else>
                        {{
                          currencyFormatter(0, {
                            currency: item?.price?.unit,
                          })
                        }}
                      </span>
                    </span>
                  </span>
                </span>
              </div>

              <div
                v-else-if="j.prop.toLowerCase() === 'name'"
                style="margin: 0.5rem 0"
              >
                {{ i[j.prop] }}
              </div>
              <div
                v-else-if="j.prop.toLowerCase() === 'place'"
                style="margin: 0.5rem 0"
              >
                <span v-if="i[j.prop]?.description">
                  {{ i[j.prop]?.description }}</span
                >
                <span v-else> NA</span>
              </div>

              <div
                v-else-if="j.prop.toLowerCase() === 'unitofmeasure'"
                style="margin: 0.5rem 0"
              >
                {{ i?.stockedProduct?.unitOfMeasure }}
              </div>
              <div
                v-else-if="j.prop.toLowerCase() === 'productstocklevel'"
                style="margin: 0.5rem 10px"
              >
                <div v-if="i[j.prop]?.unit">{{ i[j.prop]?.unit }}</div>
                <div v-else>NA</div>
              </div>
            </hlx-table-cell>
            
          </tr>
        </template>
      </hlx-table>
    </div>
    <div
      v-if="tableData.length <= 0 && searchvalue === ''"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
        height: 70vh;
      "
    >
      <img
        class="temp-Product-illustration"
        src="@/assets/images/product.svg"
        alt="Product image"
        srcset=""
      />
     
    </div>
    <div
      v-if="tableData.length === 0 && searchvalue !== ''"
      class="cm-accounts-right-panel-top-section-table-wrap"
    >
      <hlx-table
        :column-count="accounts_table_theads.length + 2"
        :border="['table', 'header']"
        :bold-headers="false"
        :row-hover="true"
        theme="grey"
        :striped-rows="false"
        :re-arrange-columns="re_arrange"
        :re-arrange-heads="theads"
        @close-rearrange="arrange"
        @apply-rearranged-columns="applyColumns"
      >
        <template #thead>
          <hlx-table-head :width="60" :align="'left'">{{
            "S.no"
          }}</hlx-table-head>
          <hlx-table-head
            v-for="(i, index) in accounts_table_theads"
            :key="index"
            :prop="i.prop"
            :sortable="i.sortable"
            :resizable="i.resizable"
            style="position: sticky; top: 0px"
            :width="i.width !== undefined ? i.width : ''"
            :align="i.align !== undefined ? i.align : ''"
            @sorting_func="sorting_Data"
          >
            {{ i.label }}
          </hlx-table-head>
          <hlx-table-head :width="100">
            <span class="action-icon"
              ><i
                class="icon-settings-regular"
                @click="arrange(true)"
              ></i></span
          ></hlx-table-head>
        </template>
        <template #tbody>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell :colspan="accounts_table_theads.length + 2">{{
              "No data"
            }}</hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
          <tr>
            <hlx-table-cell></hlx-table-cell>
          </tr>
        </template>
      </hlx-table>
    </div>
  </div>
  <div v-if="tableData?.length > 0">
    <hlx-pagination
      v-model:rows-per-page="externalRowsPerPage"
      :total="tableData.length"
      rows-per-page
      enable-rows-per-page
      :rows-per-page-list="[10, 15, 20]"
      :options="{
        attributes: {
          rowsPerPageTitle: true,
          rowsPerPageResult: true,
          rowsPerPageDropdown: {
            position: {
              top: 0,
              right: 1,
            },
          },
        },
      }"
      @current-page="currentPage"
      @updated:rows-per-page="changeRowsPerPage"
    ></hlx-pagination>
  </div>
    
  </div>
</template>

<script>
import { MLTAxiosInstance,UPCAxiosInstance } from "@/config/axiosConfig";

export default {
  data() {
    return {
      searchvalue:"",
      
      currPage: 1,
      rowsPerPage: 10,

      externalRowsPerPage:10,

      accounts_table_theads: [
        {
          sortable: false,
          resizable: false,
          width: 180,
          label: "Offer",
          prop: "name",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 100,
          label: "SKU",
          prop: "SKU",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 150,
          label: "Unit of measure",
          prop: "unitOfMeasure",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 110,
          label: "Stock quantity",
          prop: "productStockLevel",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 380,
          label: "Location",
          prop: "place",
          align: "left",
        },

        {
          sortable: false,
          resizable: false,
          width: 100,
          label: `Price`,
          prop: "MSRP",
          align: "left",
        },
        {
          sortable: false,
          resizable: false,
          width: 120,
          label: "Cost / Unit",
          prop: "stockedProduct",
          align: "left",
        },
      ],
      productdata:[],
      tableData:[],
      profilePicUrl: "",
      company: "",
      userId: "",
      payload: {
        attachment: [],
        name: "",
        role: "",
        password: "",
        email: "",
        phone: "",
        tags: [],
        notes: [],
        companyName: "",
        tradingName: "",
        status: "",
        type: "",
        "@baseType": "",
        paymentTerms: [
          {
            name: "",
            id: "",
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: "Canada",
                  flags: "https://flagcdn.com/ca.svg",
                  short: "CA",
                  dial_code: "+1",
                },
                value: "",
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              "@type": "",
            },
          },
        ],
      },
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.tableData.slice(start, end);
    },
  },
   watch: {
    
    productdata: {
      handler(val) {
        this.tableData = val;
        console.log(val, val.length, "producttable");
      },
      immediate: true,
      deep: true,
    },},
  async mounted() {
    const val = await MLTAxiosInstance.get(`partymanagement/individual/auth`);
    this.company = val.data.company;
    this.userId = val.data.userId;
    const access = await MLTAxiosInstance.get(
      `partyRole/get/${val.data.userId}`
    );

    const payload = await MLTAxiosInstance.get(
      `partymanagement/organisation/${this.userId}?company=${this.company}`
    );
    this.payload = payload.data;
      const tableData = await UPCAxiosInstance.get(
      `/productStock?company=${this.payload.tradingName}`
    );
    this.productdata = tableData.data;
    this.productdatafull = tableData.data;
    console.log(access, val.data.company, val.data.company, val, "<>");
    const profileName = this.payload.attachment.filter((item) => {
      return item["@type"] !== undefined && item["@type"] === "profile"
        ? item
        : false;
    });
    console.log(profileName[0]?.name);
    this.profilePicName = profileName[0]?.name;
    const url = await UPCAxiosInstance.get(
      `/gcpupload/files/${profileName[0]?.name}`,
      { responseType: "arraybuffer" }
    );
    // console.log(new Uint8Array([url.data,"url incoming"]))
    this.rawProfileUrlData = url.data;
    this.profilePicUrl = await this.bufferToDataURL(url.data);
  },
    methods: {
      
    currentPage(val) {
      this.currPage = val;
    },
    changeRowsPerPage(count) {
      this.rowsPerPage = count;
    },

       searchNestedObjects(objects, key) {
      return objects.reduce((acc, obj) => {
        const foundInObject = Object.entries(obj).some(([k, v]) => {
          if (typeof v === "object" && v !== null) {
            return this.searchNestedObjects([v], key).length > 0;
          } else if (typeof v === "string" && k !== "password") {
            return v.toLowerCase().includes(key);
          }
          return false;
        });

        if (foundInObject) {
          acc.push(obj);
        }

        return acc;
      }, []);
    },
      productSearch(val) {
      this.searchvalue = val;
      if (val !== "") {
        let searchData = this.searchNestedObjects(
          this.productdata,
          val.toLowerCase()
        );
        this.tableData = JSON.parse(JSON.stringify(searchData));
      } else {
        this.tableData = JSON.parse(JSON.stringify(this.productdata));
      }
    },
     serialNumber(index) {
      return (this.currPage - 1) * this.rowsPerPage + index + 1;
    },
    async bufferToDataURL(buffer) {
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = "";
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },}
};
</script>

<style></style>
