<template>
  <div class="container">
    <!-- Input Section -->
    <div class="input-section">
      <input
        v-model="newEntry"
        type="text"
        placeholder="Type something and press Enter"
        @input="searchKey2"
        @keyup.enter="addToList"
        class="input-box"
      />
      <p v-if="inputError" class="error-message">{{ inputError }}</p>
    </div>

    <!-- Suggestions Section -->
    <div class="suggestions-section" v-if="suggestions && suggestions.length > 0">
      <ul class="suggestions-list">
        <li v-for="(suggestion, index) in suggestions" :key="index" class="suggestion-item">
          <strong>{{ suggestion?.en?.label }}</strong> - {{ suggestion?.uniqueID }}
        </li>
      </ul>
    </div>

    <!-- List Section -->
    <div class="list-section">
      <h3>List of Items</h3>
      <ul>
        <li v-for="(item, index) in list" :key="index">
          <strong>{{ item?.en?.label }}</strong> - {{ item?.en?.name }}
        </li>
      </ul>
      <p v-if="list.length === 0" class="empty-message">No items in the list yet. Add some!</p>
    </div>

    <!-- Buttons Section -->
    <div class="button-section">
      <button class="btn primary" @click="patchData">Patch</button>
      <button class="btn secondary" @click="openSearchModal">Search</button>
      <p v-if="apiError" class="error-message">{{ apiError }}</p>
      <p v-if="apiSuccess" class="success-message">{{ apiSuccess }}</p>
    </div>

    <!-- Table Section -->
    <div v-if="patchedData.length > 0" class="table-section">
      <h3>Patched Data</h3>
      <table class="data-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>ID</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in patchedData" :key="index">
            <td>{{ item.name }}</td>
            <td>{{ item.id }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Search Modal -->
    <div v-if="showSearchModal" class="modal-overlay">
      <div class="modal">
        <h3>Search Item</h3>
        <hlx-search v-model="searchvalue" @search-key="searchKey" />
        <ul>
          <li v-for="result in searchResults" :key="result.id">
            {{ result?.en?.label }} - {{ result?.uniqueID }}
          </li>
          <li v-if="searchResults?.length === 0">No results found</li>
        </ul>
        <button class="btn close" @click="closeSearchModal">Close</button>
      </div>
    </div>
  </div>
</template>


<script>
import {
  // MLTAxiosInstance, DMNAxiosInstance,
  UPCAxiosInstance,
} from "@/config/axiosConfig";

export default {
  data() {
    return {
      suggestions: [],
      searchvalue:'',
      filterQueryforBill: {
        type: "search",
        module: "language",
        isPaginated: true,
        paginatedQuery: {
          rowsPerPage: 10,
          currentPage: 1,
        },
        isNeedToBeSort: true,
        sortFieldAndOrder: {},
        limit: 10,
        filterQuery: {},
        searchQuery: "",
      },
      newEntry: "",
      inputError: "",
      apiError: "",
      apiSuccess: "",
      list: [],
      patchedData: [],
      showSearchModal: false,
      searchQuery: "",
      searchResults: [],
      // cachedRems: JSON.parse(localStorage.getItem("remsCache")) || [],
    };
  },
  mounted() {
    // this.cachedRems = JSON.parse(localStorage.getItem("remsCache"));
    // this.searchKey("add");
  },
  methods: {
    addToList() {
      this.inputError = "";
      if (this.newEntry.trim() === "") {
        this.inputError = "Input cannot be empty.";
        return;
      }
      if (this.newEntry.length > 100) {
        this.inputError = "Input cannot exceed 100 characters.";
        return;
      }
      this.list.push({
        en: { label: this.newEntry, name: "english" },
      });
      this.newEntry = "";
    },

    async patchData() {
      console.log("bukf patcher☠️,☠️,☠️,☠️::", this.list);

      // Reset error and success messages
      this.apiError = "";
      this.apiSuccess = "";

      let arr = [];

      try {
        // Loop through the list and make API calls one by one
        for (let i = 0; i < this.list.length; i++) {
          let item = this.list[i]; // Access the current item
          let res = await this.patchSingleObjectwithID(item); // Wait for the async operation to complete

          if (res) {
            arr.push(res); // Push the result to the array
          }

          if (res === true) {
            console.log("Operation successful for:", item);
          } else {
            console.log("Operation failed for:", item);
            // You can choose to break the loop if needed:
            // break;
          }
        }

        // Return collected results
        this.patchedData = arr;
        console.log("finally;))))))))$$$$$$$$$", arr, this.patchedData);
      this.list = [];
      console.log("this.list = [];",this.list);

        return arr;
      } catch (error) {
        // Handle unexpected errors
        this.apiError = `An error occurred: ${error.message}`;
        console.error("Error in patchData:", error);
      }
      
    },
    openSearchModal() {
      this.showSearchModal = true;
      this.searchQuery = "";
      this.searchResults = [];
    },
    closeSearchModal() {
      this.showSearchModal = false;
    },
    // filterSearchResults() {
    //   const query = this.searchQuery.toLowerCase();

    //   this.searchResults = this.cachedRems.filter((item) =>
    //     item?.en?.label?.toLowerCase().includes(query)
    //   );
    //   console.log(
    //     "const query:):):)",
    //     query,
    //     this.cachedRems,
    //     this.searchResults
    //   );
    // },

    async patchSingleObjectwithID(itm) {
      // Fetch multi-language data
      let organisationInfo = await UPCAxiosInstance.get("/multi-language");
      let response = organisationInfo?.data;
      console.log("dfghujgfhjk___", response);

      if (!response) {
        console.error("No data received from multi-language endpoint");
        return;
      }

      // Generate unique ID if data is available
      let uniqueID = await this.generateNextUniqueID(response);
      console.log("IDIDIDIDID came done:):):)", uniqueID);

      if (uniqueID) {
        itm.uniqueID = uniqueID;
        console.log("Successfully patched my data broooo:::", itm);

        // Send data via POST request
        const postResponse = await UPCAxiosInstance.post(
          "/multi-language",
          itm
        );
        console.log(
          "Successfully patched my data broooo______:::",
          postResponse?.data
        );

        if (postResponse?.data) {
          return {
            name: postResponse?.data?.en?.label,
            id: postResponse?.data?.uniqueID,
          };
        }
      } else {
        console.error("Failed to generate a unique ID.");
      }
    },


    async searchKey(val) {
      console.log("searchKey",val);
      
      this.searchvalue = val;
      this.filterQueryforBill["type"] = "search";
      this.filterQueryforBill["searchQuery"] = this.searchvalue;
      this.filterQueryforBill.searchList =
        [
          {
            "en.label": {
              "$regex": this.searchvalue, "$options": "i"
            }
          }
        ];
      this.currPage = 1;
      this.rowsPerPage = 10;
      if (this.filtertagvalue?.length != 0) {
        this.filterQueryforBill["type"] = "filter-search";
      }

      // this.ticketdataCalling(this.currPage, this.rowsPerPage);


      let currentPage = 1;
      let rowsPerPage = 10;

      
      currentPage, rowsPerPage
  console.log("ticketdataCalling...123",currentPage, rowsPerPage);
      this.filterQueryforBill["company"] = this.$cookies.get('company');
      this.filterQueryforBill["paginatedQuery"]["currentPage"] = currentPage;
      this.filterQueryforBill["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
      this.filterQueryforBill.module = 'language';
      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.filterQueryforBill
      );
      if (
        filterResultData?.data?.data && filterResultData?.data && filterResultData
      ) {
        // this.tableData = filterResultData.data.data;
        this.searchResults = filterResultData.data.data;
        // this.totalpageno = filterResultData.data?.total;
        console.log("ticketdataCalling...",
          // this.tableData, filterResultData.data.data,
          this.searchResults);
        
        // this.totalvalue = filterResultData.data.total;      
      }
    },

    async searchKey2() {
      console.log("searchKey");

      if (this.newEntry == '') {
        this.suggestions = []
      }
      
      this.searchvalue = this.newEntry;
      this.filterQueryforBill["type"] = "search";
      this.filterQueryforBill["searchQuery"] = this.searchvalue;
      this.filterQueryforBill.searchList =
        [
          {
            "en.label": {
              "$regex": this.searchvalue, "$options": "i"
            }
          }
        ];
      this.currPage = 1;
      this.rowsPerPage = 10;
      if (this.filtertagvalue?.length != 0) {
        this.filterQueryforBill["type"] = "filter-search";
      }

      // this.ticketdataCalling(this.currPage, this.rowsPerPage);


      let currentPage = 1;
      let rowsPerPage = 10;

      
      currentPage, rowsPerPage
  console.log("ticketdataCalling...123",currentPage, rowsPerPage);
      this.filterQueryforBill["company"] = this.$cookies.get('company');
      this.filterQueryforBill["paginatedQuery"]["currentPage"] = currentPage;
      this.filterQueryforBill["paginatedQuery"]["rowsPerPage"] = rowsPerPage;
      this.filterQueryforBill.module = 'language';
      const filterResultData = await UPCAxiosInstance.post(
        `util/filterData`,
        this.filterQueryforBill
      );
      if (
        filterResultData?.data?.data && filterResultData?.data && filterResultData
      ) {
        // this.tableData = filterResultData.data.data;
        this.suggestions = filterResultData.data.data;
        // this.totalpageno = filterResultData.data?.total;
        console.log("ticketdataCalling...",
          // this.tableData, filterResultData.data.data,
          this.suggestions);

          if (this.newEntry == '') {
        this.suggestions = []
      }
        
        // this.totalvalue = filterResultData.data.total;      
      }
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.input-section {
  margin-bottom: 20px;
}

.input-box {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.suggestions-section {
  margin-bottom: 20px;
}

.suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.suggestion-item {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 8px;
  background-color: #fff;
}

.list-section h3 {
  margin-bottom: 10px;
}

.empty-message {
  color: #666;
  font-style: italic;
}

.button-section {
  margin: 20px 0;
  display: flex;
  gap: 10px;
}

.btn {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn.primary {
  background-color: #007bff;
  color: white;
}

.btn.primary:hover {
  background-color: #0056b3;
}

.btn.secondary {
  background-color: #6c757d;
  color: white;
}

.btn.secondary:hover {
  background-color: #5a6268;
}

.btn.close {
  background-color: #dc3545;
  color: white;
}

.btn.close:hover {
  background-color: #bd2130;
}

.table-section {
  margin-top: 20px;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.data-table th {
  background-color: #007bff;
  color: white;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.success-message {
  color: green;
  font-size: 14px;
  margin-top: 10px;
}
</style>
