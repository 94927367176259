<template>
  <div style="padding: 30px">
    <div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
        "
      >
        <div style="display: flex; align-items: center; gap: 16px">
          <span style="">
            <hlx-file-upload
              :file-type="'.jpg,.jpeg,.png'"
              :file-size-limit="'20mb'"
              :uploadtype="'Rectangle'"
              :style-background="backgrounStyleupload"
              :height="50"
              :width="50"
              :read-only="true"
              :custom-icon="icon"
              :type="'profile-upload'"
              :name-display="payloadActivity.tradingName"
              :content="'Import'"
              :profile-pic-preview="profilePicUrl"
            />
          </span>

          <div style="display: flex; flex-direction: column; gap: 8px">
            <div style="display: flex; align-items: center; gap: 8px">
              <span style="font-weight: bold">{{ payloadActivity.name }}</span>
              <hlx-label
                v-if="
                  payloadActivity.status.length > 0 &&
                  payloadActivity.status === 'Active'
                "
                class="primary sm"
                >{{ payloadActivity.status }}</hlx-label
              >
              <hlx-label
                v-if="
                  payloadActivity.status.length > 0 &&
                  payloadActivity.status === 'Inactive'
                "
                class="error sm"
                >{{ payloadActivity.status }}</hlx-label
              >
            </div>
            <div style="color: #a6a6a6; font-size: 12px; width: max-content">
              {{ payloadActivity.contactMedium[3].characteristic.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <ActivityLogComponent :data="productpayload" :activity-data="logData"></ActivityLogComponent>
  </div>
</template>

<script>
// import addressComponent from "@/components/CM/autocompleteComponent.vue";
import { MLTAxiosInstance,UPCAxiosInstance } from "@/config/axiosConfig";
import ActivityLogComponent from "@/components/SM/activityLogComponent.vue";

export default {
  components: {
    // addressComponent,
    ActivityLogComponent,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    module: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      logData:[],
      profilePicUrl: "",
        company:"",
        userId:"",
      payloadActivity: {
        attachment: [],
        name: "",
        role: "",
        password: "",
        email: "",
        phone: "",
        tags: [],
        notes: [],
        companyName: "",
        tradingName: "",
        status: "",
        type: "",
        "@baseType": "",
        paymentTerms: [
          {
            name: "",
            id: "",
          },
        ],
        externalReference: [
          {
            name: "",
            externalIdentifierType: "internetSite",
          },
        ],
        contactMedium: [
          {
            preferred: true,
            mediumType: "email",
            characteristic: {
              emailAddress: "",
            },
          },
          {
            mediumType: "phone",
            characteristic: {
              phoneNumber: {
                country: {
                  name: "Canada",
                  flags: "https://flagcdn.com/ca.svg",
                  short: "CA",
                  dial_code: "+1",
                },
                value: "",
              },
            },
          },
          {
            mediumType: "fax",
            characteristic: {
              faxNumber: "",
            },
          },
          {
            mediumType: "postalAddress",
            characteristic: {
              city: "",
              country: "",
              postCode: "",
              stateOrProvince: "",
              street1: "",
              street2: "",
              description: "",
              "@type": "",
            },
          },
        ],
      },
    };
  },

  async mounted() {
    const val = await MLTAxiosInstance.get(`partymanagement/individual/auth`);
    this.company = val.data.company;
    this.userId = val.data.userId;
    const access = await MLTAxiosInstance.get(
      `partyRole/get/${val.data.userId}`
    );

    const payload = await MLTAxiosInstance.get(
      `partymanagement/organisation/${this.userId}?company=${this.company}`
    );
    this.payloadActivity = payload.data;
    console.log(access, val.data.company, val.data.company, val, "<>");
   const profileName = this.payloadActivity.attachment.filter((item) => {
      return item["@type"] !== undefined && item["@type"] === "profile"
        ? item
        : false;
    });
    console.log(profileName[0]?.name);
    this.profilePicName = profileName[0]?.name;
    const url = await UPCAxiosInstance.get(
      `/gcpupload/files/${profileName[0]?.name}`,
      { responseType: "arraybuffer" }
    );
    // console.log(new Uint8Array([url.data,"url incoming"]))
    this.rawProfileUrlData = url.data;
    this.profilePicUrl = await this.bufferToDataURL(url.data);


   
    const logpayload = {
      company: `${this.payloadActivity.tradingName}`,
      type: "filter",
      module: "log",
      lastUpdated: new Date().toJSON(),
      isPaginated: true,
      paginatedQuery: {
        rowsPerPage: 10,
        currentPage: 1,
      },
      isNeedToBeSort: true,
      sortFieldAndOrder: {},
      limit: 10,
      filterQuery: {
        category: "supplier",
      },
      searchQuery: "",
    };
    const logaccess = await MLTAxiosInstance.post(
      `/util/filterData`,
      logpayload
    );
    this.logData = logaccess.data.data;

    console.log(logaccess.data.data);
  },
  methods: {
     async bufferToDataURL(buffer) {
      // Convert the buffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Convert Uint8Array to a binary string
      let binary = "";
      uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });

      // Base64 encode the binary string
      const base64String = window.btoa(binary);
      this.profilePicUrl = `data:image/png;base64,${base64String}`;
      // Construct and return the data URL
      return `data:image/png;base64,${base64String}`;
    },
    async deleteAddress(index) {
      console.log(this.payload.contactMedium[index]);
      let changeDefault = false;
      const type = this.payload.contactMedium[index].mediumType;
      let p = [];
      if (this.payload.contactMedium[index].characteristic.default === true) {
        changeDefault = true;
      }
      this.payload.contactMedium.splice(index, 1);
      p = this.payload.contactMedium.filter((item) => {
        return item.mediumType === type;
      });
      if (p[0] !== undefined && changeDefault === true) {
        p[0].default = true;
      }
      console.log(p[0]);
      await this.addNewSupplier();
      this.parentCompany = this.company;
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.userId}?company=${this.company}`
      );
      console.log(payload);
      let contacts = await MLTAxiosInstance.get(
        `/partymanagement/individual/listuser?company=${this.company}`
      );
      contacts.data.forEach((e) => {
        const preferredMedium = e.contactMedium.find((item) => item.preferred);
        e.preferred = preferredMedium ? preferredMedium.mediumType : null;
      });
      this.contactTableData = contacts.data;
      console.log(this.contactTableData);
      const preferredMedium = payload.data.contactMedium.find(
        (item) => item.preferred
      );
      // console.log(preferredMedium);
      this.payload = payload.data;
      this.payload.preferred = preferredMedium
        ? preferredMedium.mediumType
        : null;
      console.log(this.payload);

      console.log(p);
      this.attachmentData = this.payload.attachment;
      this.noteData = this.payload.notes;
      this.rawPayload = JSON.parse(JSON.stringify(this.payload));
    },
    async receivedUpdateAddress(val) {
      this.payload.contactMedium[val.index] = val.data;
      await this.addNewSupplier();
      this.parentCompany = this.company;
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.userId}?company=${this.company}`
      );
      console.log(payload);
      let contacts = await MLTAxiosInstance.get(
        `/partymanagement/individual/listuser?company=${this.company}`
      );
      contacts.data.forEach((e) => {
        const preferredMedium = e.contactMedium.find((item) => item.preferred);
        e.preferred = preferredMedium ? preferredMedium.mediumType : null;
      });
      this.contactTableData = contacts.data;
      console.log(this.contactTableData);
      const preferredMedium = payload.data.contactMedium.find(
        (item) => item.preferred
      );
      // console.log(preferredMedium);
      this.payload = payload.data;
      this.payload.preferred = preferredMedium
        ? preferredMedium.mediumType
        : null;
      console.log(this.payload);
      this.attachmentData = this.payload.attachment;
      this.noteData = this.payload.notes;
      this.rawPayload = JSON.parse(JSON.stringify(this.payload));
    },
    async receivedNewAddress(val) {
      this.payload.contactMedium.push(val);
      await this.addNewSupplier();
      this.parentCompany = this.company;
      let payload = await MLTAxiosInstance.get(
        `/partymanagement/organisation/${this.userId}?company=${this.company}`
      );
      console.log(payload);
      let contacts = await MLTAxiosInstance.get(
        `/partymanagement/individual/listuser?company=${this.company}`
      );
      contacts.data.forEach((e) => {
        const preferredMedium = e.contactMedium.find((item) => item.preferred);
        e.preferred = preferredMedium ? preferredMedium.mediumType : null;
      });
      this.contactTableData = contacts.data;
      console.log(this.contactTableData);
      const preferredMedium = payload.data.contactMedium.find(
        (item) => item.preferred
      );
      // console.log(preferredMedium);
      this.payload = payload.data;
      this.payload.preferred = preferredMedium
        ? preferredMedium.mediumType
        : null;
      console.log(this.payload);
      this.attachmentData = this.payload.attachment;
      this.noteData = this.payload.notes;
      this.rawPayload = JSON.parse(JSON.stringify(this.payload));
    },
  },
};
</script>
