<template>
    <div  class="outer-overlay" @click="emitfunction">
      <div  :id="'container-overlay'+unique" class="container-overlay" style="position:fixed" :style="['width:' + width + 'px', 'top:' + containertop+ 'px', 'left:'+left+'px']" >
      <slot name="overlay"></slot>
      </div>
    </div>
  </template>
  
  <script>
  // import {ref,watch} from 'vue';
  export default {
      props:{
        show:{
           type:Boolean,
           default:false
        },
        height:{
          type:Number,
          default:0
        },
        top:{
          type:Number,
          default:0
        },
        width:{
          type:Number,
          default:0
        },
        left:{
          type:Number,
          default:0
        },
        conditionvalue:{
          type:Number,
          default:0
        },
        conditionup:{
          type:Number,
          default:0
        },
        conditiondown:{
          type:Number,
          default:0
        },
        search:{
          type:String,
          default:''
        }
  
      },
      emits:['close-overlay'],
    
    data()
    {
      return{
        unique: Math.floor(Math.random() * 1000 + 1),
        containertop:0,
        containerwidth:0,
        containerleft:0,
        containerheight:0,
      }
    },
      watch :{
        search:{
           handler(val)
           {
          if(this.show == true)
        {
          val
                      this.$nextTick(()=>{
  
        this.containerheight = document.querySelector('#container-overlay'+this.unique).offsetHeight
        this.containertop = this.top
        this.containerleft = this.left
        this.containerwidth = this.width
        var innerheight = window.innerHeight - this.containertop
        //conditionvalue = 250
             if(innerheight < this.conditionvalue)
              {
                //conditionup = 10
                this.containertop = this.containertop - (this.containerheight+this.conditionup)  
                // console.log(this.containertop); 
                    }
              else{
                //conditiondown = 45
                this.containertop = this.containertop + this.conditiondown
              }
              })
        }
        else{
              this.containertop = 0
              this.containerleft = 0
              this.containerwidth = 0
              this.containerheight = 0  
            }
           }
        },
        show:{
           handler(val)
           {
          if(val == true)
        {
          // console.log('aaaaaaaaa',this.top,this.left,this.width);
                      this.$nextTick(()=>{
  
        this.containerheight = document.querySelector('#container-overlay'+this.unique).offsetHeight
        this.containertop = this.top
        this.containerleft = this.left
        this.containerwidth = this.width
        var innerheight = window.innerHeight - this.containertop
        //conditionvalue = 250
             if(innerheight < this.conditionvalue)
              {
                //conditionup = 10
                this.containertop = this.containertop - (this.containerheight+this.conditionup)  
                // console.log(this.containertop); 
                    }
              else{
                //conditiondown = 45
                this.containertop = this.containertop + this.conditiondown
              }
              })
        }
        else{
              this.containertop = 0
              this.containerleft = 0
              this.containerwidth = 0
              this.containerheight = 0  
            }
           }
        },
        top:{
          handler(val)
          {
            this.containertop = val
                    if(this.show == true)
        {
          // console.log('aaaaaaaaa',this.top,this.left,this.width);
                      this.$nextTick(()=>{
  
        this.containerheight = document.querySelector('#container-overlay'+this.unique).offsetHeight
        this.containertop = this.top
        this.containerleft = this.left
        this.containerwidth = this.width
        var innerheight = window.innerHeight - this.containertop
        //conditionvalue = 250
             if(innerheight < this.conditionvalue)
              {
                //conditionup = 10
                this.containertop = this.containertop - (this.containerheight+this.conditionup)  
                // console.log(this.containertop); 
                    }
              else{
                //conditiondown = 45
                this.containertop = this.containertop + this.conditiondown
              }
              })
        }
        else{
              this.containertop = 0
              this.containerleft = 0
              this.containerwidth = 0
              this.containerheight = 0  
            }
            // console.log(val,'oo');
          },
          immediate:true,
          deep:true
        },
        left:{
          handler(val)
          {
            this.containerleft = val
          },
          immediate:true,
          deep:true
        },
        width:{
          handler(val)
          {
             this.containerwidth = val
          },
          immediate:true,
          deep:true
        },
      },
      methods:
      {
        emitfunction(event)
        {
          console.log(event);
          this.$emit('close-overlay',event)
        }
      },
  }
  </script>
  
  <style>
  
  </style>